import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('CallForwardModel');

export class CallForwardModel extends BaseModel {

  public enabled: boolean = false;
  public timeZone: string = null;
  public fromTime: string = null;
  public toTime: string = null;
  public numberType: string = null;
  public number: string = null;

  constructor(callForward?: any) {
    super();
    this.mapProperties(callForward);
  }

  get status(): string {
    return this.enabled ? 'Enabled' : 'Disabled';
  }
}
