import { PLATFORM } from 'aurelia-pal';
import {RouteConfig} from 'aurelia-router';

export class AppRoutes {

  constructor() {
  }

  public static get routes(): RouteConfig[] {
    return this.loginRoutes().concat(this.registrationRoutes(), this.appRoutes())
  }

  private static loginRoutes(): RouteConfig[] {
    return [
      {
        route: ['', 'login'],
        name: 'login',
        moduleId: PLATFORM.moduleName('features/user/passport/login/login'),
        nav: false,
        title: 'Sign In',
        auth: false
      },
      {
        route: 'password',
        name: 'password',
        moduleId: PLATFORM.moduleName('features/user/passport/password/password'),
        nav: false,
        title: 'Password',
        auth: false
      },
      {
        route: 'reset/password/:passportId',
        name: 'resetpassword',
        moduleId: PLATFORM.moduleName('features/user/passport/password/resetpassword/reset-password'),
        nav: false,
        title: 'Reset Password',
        auth: false
      },
      {route: 'logout', name: 'logout', moduleId: PLATFORM.moduleName('logout/logout'), nav: false, title: 'Log out', auth: true},
      {
        route: 'view-interaction/:interactionId',
        name: 'viewinteraction',
        moduleId: PLATFORM.moduleName('features/interaction/call/interactionlog/list/view-interaction'),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Interaction Log',
        auth: false
      }
    ];
  }

  private static registrationRoutes(): RouteConfig[] {
    return [
      {
        route: 'join',
        name: 'join',
        moduleId: PLATFORM.moduleName('features/user/registration/join/join'),
        nav: false,
        title: 'Join',
        auth: false
      },
      {
        route: 'emailsubmitted',
        name: 'emailsubmitted',
        moduleId: PLATFORM.moduleName('features/user/registration/join/email-submitted'),
        nav: false,
        title: 'Email Submitted',
        auth: false
      },
      {
        route: 'completeregistration',
        name: 'completeregistration',
        moduleId: PLATFORM.moduleName('features/user/registration/completeregistration/complete-registration'),
        nav: false,
        title: 'Complete Registration',
        auth: false
      },
      {
        route: 'verify',
        name: 'verify',
        moduleId: PLATFORM.moduleName('features/user/registration/completeregistration/verify-complete-registration'),
        nav: false,
        title: 'Verification',
        auth: false
      },
      {
        route: 'acceptinvitation',
        name: 'acceptinvitation',
        moduleId: PLATFORM.moduleName('features/user/registration/acceptinvitation/accept-invitation'),
        nav: false,
        title: 'Accept Invitation',
        auth: false
      },
      {
        route: 'invitationerror',
        name: 'invitationerror',
        moduleId: PLATFORM.moduleName('features/user/registration/acceptinvitation/accept-invitation-error'),
        nav: false,
        title: 'Invitation Error',
        auth: false
      },
      {
        route: 'registrationerror',
        name: 'registrationerror',
        moduleId: PLATFORM.moduleName('features/user/registration/completeregistration/complete-registration-error'),
        nav: false,
        title: 'Registration Error',
        auth: false
      }
    ];
  }

  private static appRoutes(): RouteConfig[] {
    return [
      {route: 'error/:code', name: 'error', moduleId: PLATFORM.moduleName('error/error'), nav: false, title: 'Error', auth: false},
      {route: 'redirect', name: 'redirect', moduleId: PLATFORM.moduleName('redirect/redirect'), nav: false, title: 'Redirect', auth: false},
      {route: 'hud', name: 'hud', moduleId: PLATFORM.moduleName('hud/hud'), nav: false, title: 'HUD', auth: true}
    ];
  }

}