import { SearchableVariableModel } from "../searchable-variable-model";

export class SearchableVariablesListModel {

  public name: string;
  public ready: boolean = false;
  public addState: boolean;
  public isValid: boolean;
  public isValidChoice: boolean;
  public searchableVariables: SearchableVariableModel[];

  public disableAddState(): void {
    this.addState = false;
    this.name = null;
  }
}

interface IOutcome {
  name: string;
  isSelected?: boolean;
}