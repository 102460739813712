// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import {
  validateTrigger,
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';

import { BootstrapFormRenderer } from 'zailab.common';
import { WebchatService } from '../webchat-service';

interface ValidationRule {
  field: string;
  displayName: string;
  required?: boolean;
  unique?: boolean;
  maxLength?: number;
  allowedCharacters?: RegExp;
  disabled?: boolean;
}

const logger = LogManager.getLogger('WebchatConfigurationDialog');

@autoinject()
export class WebchatConfigurationDialog {
  public loading = false;
  public error: string;
  public tabs: any[];
  public changeTab: (index: number) => void;

  public webchatConfigurationNames: string[] = [];
  public webchatWidgetId: string;
  public webchatWidgetCurrentName: string;
  public webchatConfig: string;

  private validation: ValidationController;
  private tab1Rules: ValidationRule[] = [
    {
      field: 'name',
      displayName: 'Name',
      required: true,
      unique: true,
      maxLength: 50,
      allowedCharacters: /^[a-zA-Z0-9-_ ]+$/,
    },
  ];
  private tab2Rules: ValidationRule[] = [
    {
      field: 'bannerName',
      displayName: 'Banner Name',
      required: true,
      maxLength: 50,
      allowedCharacters: /^[a-zA-Z0-9-_ ]+$/,
    },
    { field: 'bannerColor', displayName: 'Banner Color', required: true },
  ];

  constructor(
    private dialogController: DialogController,
    private webchatService: WebchatService,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public async activate(data: {
    webchatConfigurationNames: string[];
    webchatConfiguration?: any;
  }): Promise<void> {
    this.webchatConfigurationNames = data.webchatConfigurationNames;
    if (data.webchatConfiguration) {
      this.webchatWidgetId = data.webchatConfiguration.webChatWidgetId;
      this.webchatWidgetCurrentName = data.webchatConfiguration.name;
      await this.getWebchatWidgetConfiguration();
    }
    this.renderTabs();
  }

  private async getWebchatWidgetConfiguration(): Promise<void> {
    try {
      this.webchatConfig = await this.webchatService.retrieveWebChatConfig(
        this.webchatWidgetId
      );
    } catch (e) {
      logger.warn('Failed to retrieve webchat configs due to', { e });
    }
  }

  private renderTabs(): void {
    this.tabs = [
      {
        id: '1',
        name: 'General Settings',
        // @ts-ignore
        viewModel: PLATFORM.moduleName(
          'features/organisation/webchat/add-dialog/tabs/tab1-general-settings'
        ),
        icon: 'split',
        data: {
          webchatConfiguration: this.webchatConfig || {},
        },
      },
      {
        id: '2',
        name: 'Advanced Settings',
        // @ts-ignore
        viewModel: PLATFORM.moduleName(
          'features/organisation/webchat/add-dialog/tabs/tab2-advanced-settings'
        ),
        icon: 'split',
        data: {
          webchatConfiguration: this.webchatConfig || {
            collectClientLocation: false,
            collectClientData: false,
          },
        },
      },
    ];
  }

  public close(): void {
    this.dialogController.cancel();
  }

  public async save(): Promise<void> {
    const tab1Data: any = this.tabs[0].data;
    const tab2Data: any = this.tabs[1].data;

    tab1Data.validation = null;
    tab2Data.validation = null;

    const tab1Errors = await this.validateData(
      tab1Data.webchatConfiguration,
      this.tab1Rules
    );
    if (Object.keys(tab1Errors).length > 0) {
      tab1Data.validation = tab1Errors;
      this.changeTab(0);
      return;
    }

    if (!this.webchatWidgetId) {
      this.tab2Rules.forEach((rule) => (rule.disabled = true));
    }
    const tab2Errors = await this.validateData(
      tab2Data.webchatConfiguration,
      this.tab2Rules
    );
    if (Object.keys(tab2Errors).length > 0) {
      tab2Data.validation = tab2Errors;
      this.changeTab(1);
      return;
    }
    this.loading = true;

    const payload = {
      name: tab1Data.webchatConfiguration.name,
      bannerName: tab2Data.webchatConfiguration.bannerName,
      bannerColor: tab2Data.webchatConfiguration.bannerColor,
      customerTimeout: tab2Data.webchatConfiguration.customerTimeout,
      inactivityWarningMessage: '&nbsp;',
      collectClientLocation:
        tab2Data.webchatConfiguration.collectClientLocation,
      collectClientData: tab2Data.webchatConfiguration.collectClientData,
    };
    if (this.webchatWidgetId) {
      this.webchatService
        .updateWebchatConfig(
          this.webchatWidgetId,
          payload.name,
          payload.bannerName,
          payload.bannerColor,
          payload.customerTimeout,
          payload.collectClientLocation,
          payload.collectClientData,
          '&nbsp;',
          tab2Data.webchatConfiguration.file
        )
        .then((response) =>
          this.successful(this.webchatWidgetId, {
            ...payload,
            apiToken: response,
          })
        )
        .catch((e) =>
          this.failed(' > Failed to update webchat widget config due to:', e)
        );
    } else {
      this.webchatService
        .createWebchatConfig(
          payload.name,
          payload.bannerName,
          payload.bannerColor,
          payload.customerTimeout,
          payload.collectClientLocation,
          payload.collectClientData,
          '&nbsp;',
          tab2Data.webchatConfiguration.file
        )
        .then((webchatWidgetId) => this.successful(webchatWidgetId, payload))
        .catch((e) =>
          this.failed(' > Failed to create webchat widget config due to:', e)
        );
    }
  }
  private async validateData(
    data: any,
    rules: ValidationRule[]
  ): Promise<{ [key: string]: string }> {
    const errors: { [key: string]: string } = {};

    for (const rule of rules) {
      const fieldValue = data[rule.field];
      const trimmedValue =
        typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;

      if (
        !rule.disabled &&
        rule.required &&
        (!trimmedValue || trimmedValue.length === 0)
      ) {
        errors[rule.field] = `Please enter a ${rule.displayName}.`;
        continue;
      }

      if (
        !rule.disabled &&
        rule.required &&
        rule.unique &&
        this.webchatConfigurationNames.includes(trimmedValue)
      ) {
        if (this.webchatWidgetCurrentName !== trimmedValue) {
          errors[rule.field] = `Please enter a unique ${rule.displayName}.`;
          continue;
        }
      }

      if (
        !rule.disabled &&
        rule.maxLength &&
        trimmedValue.length > rule.maxLength
      ) {
        errors[
          rule.field
        ] = `${rule.displayName} must be at most ${rule.maxLength} characters long.`;
        continue;
      }

      if (
        !rule.disabled &&
        rule.allowedCharacters &&
        !rule.allowedCharacters.test(trimmedValue)
      ) {
        errors[
          rule.field
        ] = `${rule.displayName} can only contain letters, numbers, - (dash), _ (underscore), ' (apostrophe), and whitespace.`;
        continue;
      }
    }
    return errors;
  }

  private successful(
    webChatWidgetId: string,
    data: { [key: string]: any }
  ): void {
    this.dialogController.ok({
      webChatWidgetId,
      ...data,
    });
  }

  private failed(error: string, e: Error): void {
    this.loading = false;
    logger.warn(error, e);
  }
}
