import { OrganisationSessionModel } from './../../../../../_common/stores/sessionmodels/organisation-session-model';
import {autoinject, LogManager, computedFrom, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';

import {SessionStore, FormatTools, MESSAGE_EVENTS} from 'zailab.common';
import {AbstractList, List} from 'zailab.abstract';
import {IDispositionFilterProperty, ZIOplog} from '../../../../../../typings/zai/zai.common';
import {DispositionCodesService} from '../disposition-codes-service';
import {DispositionModel} from "../disposition-codes-model";
import {ChannelsService} from '../../channels/channels-service';
import {PlaceholderService} from "../../../../../_common/services/placeholder-service";
import {ChannelModel} from '../../channels/channel-model';
import {ServicesService} from '../../services/services-service';
import {DisplayServiceModel} from '../../services/service-model';
import { BulkUploadDialog } from './bulk-upload/bulk-upload-dialog';
import { DialogService } from 'aurelia-dialog';

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('DispositionCodesList');

@autoinject()
export class DispositionCodesList extends AbstractList {

  protected container: HTMLElement;
  protected oplog: ZIOplog;
  protected itemList: List;
  protected placeholderService: PlaceholderService;

  private editAction = {
    action: 'edit',
    label: 'edit'
  };
  private bulkUploadAction = {
    action: 'bulkupload',
    label: 'Bulk Upload',
    icon: 'cloud-upload',
    disabled: false
  };
  public actions = [];
  private showFilter: boolean = false;
  private showSearch: boolean = false;
  private defaultServices: Array<DisplayServiceModel> = [];
  private defaultChannels: Array<any> = [];
  private defaultDirections: Array<string> = ['', 'Inbound', 'Outbound'];
  private enabledOptions: Array<string> = ['', 'Enabled', 'Disabled'];
  private servicesOplog: ZIOplog;

  @observable private dispositionListName: string = '';
  private selectedService: string = '';
  private selectedChannel: string = '';
  private selectedDirection: string = '';
  private selectedEnabledOption: string = '';
  private filterData: IDispositionFilterProperty;
  private enableSubscription: Subscription;

  constructor(
    private dispositionCodesService: DispositionCodesService,
    private router: Router,
    protected eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private channelService: ChannelsService,
    private servicesService: ServicesService,
    private dialogService: DialogService
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.retrieveList();
    this.initialiseOplog(organisationId);
    this.retrieveChannels();
    this.initialiseSelectedServicesOplog();
    this.retrieveSelectedServices();
  }

  // override
  protected retrieveList(): void {
    super.retrieveList();
    this.dispositionCodesService.retrieveDispositions().then(
      dispositionList => {
        this.setupListConfig(dispositionList);
        this.setupPlaceholders();
      }).catch(_error => {
        this.hideLoader();
      });
  }

  protected setupListConfig(dispositionCodes: Array<DispositionModel>): void {
    this.setupActions(dispositionCodes);
    super.setupListConfig(dispositionCodes);
    this.itemList = List.Builder()
      .items(dispositionCodes)
      .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  private setupActions(dispositionCodes: Array<DispositionModel>): void {
    dispositionCodes.forEach(dispo => {
      let bulkUploadAction = {
        ...this.bulkUploadAction,
        disabled: dispo.enabled
      }
      dispo.actions = [
        { ...this.editAction },
        bulkUploadAction
      ]
    })
  }

  private retrieveChannels(): void {
    this.channelService.displayChannels().then(channels => {
      this.defaultChannels = this.formatChannelList(channels);
    });
  }

  private formatChannelList(channels: Array<ChannelModel>): Array<ChannelModel> {
    let formattedList = [new ChannelModel()]; // create all selector

    channels.forEach(channel => {
      if (channel.channelName.includes('CALL')) {
        channel.channelName = FormatTools.toTitleCase(channel.channelName.replace('_', ' '))
      }
      if (channel.channelName.includes('Website') || channel.channelName.includes('Kiosk')) {
      } else {
        formattedList.push(channel);
      }
    });
    return formattedList;
  }

  private initialiseSelectedServicesOplog(): void {
    this.servicesService.initialiseServicesOplog().then(oplog => {
      this.servicesOplog = oplog;
      this.servicesOplog.on('insert', () => this.retrieveSelectedServices());
      this.servicesOplog.on('update', () => this.retrieveSelectedServices());
    });
  }

  private retrieveSelectedServices(): void {
    this.servicesService.retrieveOrganisationSelectedServices().then(services => {
      this.defaultServices = services.sort((a,b) => (a.serviceName && b.serviceName && a.serviceName.toLowerCase() > b.serviceName.toLowerCase()) ? 1 : ((a.serviceName && b.serviceName && b.serviceName.toLowerCase() > a.serviceName.toLowerCase()) ? -1 : 0));

      this.defaultServices.unshift(new DisplayServiceModel());
    });
  }

  private dispositionListNameChanged(newValue: string): void {
    if(this.itemList && this.itemList.items) {
      this.filter();
    }
  }

  private toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  protected toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  private hideSearchFilter(): void {
    this.showFilter = false;
  }

  private resetFilter(shouldClose?: boolean): void {
    this.dispositionListName = '';
    this.selectedService = '';
    this.selectedChannel = '';
    this.selectedDirection = '';
    this.selectedEnabledOption = '';
    this.filterData = null;
    this.showFilter = shouldClose ? false : true;
  }

  private filter(shouldClose?: boolean): void {
    let enabled = this.selectedEnabledOption === 'Enabled' ? true : (this.selectedEnabledOption === 'Disabled' ? false : undefined);

    this.filterData = {
      name: this.dispositionListName,
      service: { serviceName: this.selectedService },
      channel: this.selectedChannel,
      direction: this.selectedDirection,
      enabled
    };

    if(shouldClose) {
      this.toggleFilter();
    }
  }

  private setupPlaceholders(count?: number): void {
    this.placeholderService = new PlaceholderService(this.container, count || this.itemList.items.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  protected showAddItem(_item: DispositionModel): void {
    this.router.navigate('add-list');
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
    for(let item of this.itemList.items) {
      item.isDeleting = false;
    }
  }

  private editItem(dispositionList: DispositionModel): void {
    if (dispositionList.tiered) {
      this.router.navigate(`edit-tier1-list/${dispositionList.id}`);
    } else {
      this.router.navigate(`edit-list/${dispositionList.id}`);
    }
  }
  
  public bulkUpload(dispositionList: DispositionModel): void {
    
    this.dialogService
      .open({ viewModel: BulkUploadDialog, model: dispositionList })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let dispositionListId = dispositionList.id;
          this.attemptBulkUpload(dispositionListId, response.output)
        }
      });
  }

  private attemptBulkUpload(dispositionListId: string, file: any): void {
    this.dispositionCodesService
      .bulkUploadDispositionList(this.organisationId, dispositionListId, uuidv4(), file)
      .then(() => {
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Thank you! <br><br> Dispositions are being processed and uploaded.');
      })
      .catch(() => {})
  }

  private initialiseOplog(organisationId: string): void {
    this.dispositionCodesService.initialiseOplog(organisationId).then(oplog => this.subscribeToOplogChanges(oplog));
  }

  private subscribeToOplogChanges(oplog: ZIOplog):void {
    this.oplog = oplog;
    this.oplog.on('insert', () => this.retrieveList());
    this.oplog.on('update', () => this.retrieveList());

    this.dispositionCodesService.subscribeToDispositionListDeletedEvent((data: { state: DispositionModel }) => {
      this.updateRemovedDispositionList(data.state);
    });
  }

  private updateRemovedDispositionList(data: DispositionModel): void {
    for(let item of this.itemList.items) {
      if(item.id === data.dispositionListId) {
        this.itemList.items.splice(this.itemList.items.indexOf(item), 1);
      }
    }
    this.setupPlaceholders();
  }

  public enabledChanged(item: DispositionModel): void {
    item.enabled = !item.enabled;
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.subscribeToUnableToEnableValidationError(() => {
      item.enabled = false;
    });
    let bulkUploadAction = {
      ...this.bulkUploadAction,
      disabled: item.enabled
    }
    item.actions = [
      { ...this.editAction },
      bulkUploadAction
    ]
    this.dispositionCodesService
      .toggleDispositionListEnabled(organisationId, item.id, item.enabled)
      .catch(() => {
        let bulkUploadAction = {
          ...this.bulkUploadAction,
          disabled: !item.enabled
        }
        item.actions = [
          { ...this.editAction },
          bulkUploadAction
        ]
      });
  }

  private subscribeToUnableToEnableValidationError(callback: () => void): void {
    this.enableSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', (error: any) => {
      this.enableSubscription.dispose();
      if(error.state.globalError.code === 'DispositionList.enabled.not.unique') {
        callback();
      }
    });
  }

  protected selectItem(item: DispositionModel, event?: Event): void {
    //@ts-ignore
    if (item.isPlaceholder || item.showLoader || event.target.className.includes('o-switch-checkbox')) {
      return;
    }
    if (this.isDeleting) {
      if(item.enabled) {
        return;
      }
      //@ts-ignore
      item.isDeleting = !item.isDeleting;
    } else {
      this.editItem(item);
    }
  }

  protected deleteItems(): void {
    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        this.deleteDispositionList(item);
      }
    }
    this.toggleDelete();
  }

  private deleteDispositionList(dispositionList: DispositionModel): void {
    if(dispositionList.enabled) {
      return;
    }
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.dispositionCodesService.deleteDispositionList(organisationId, dispositionList.id);
  }

  @computedFrom('defaultChannels')
  private get channels(): Array<ChannelModel> {
    return this.defaultChannels;
  }

  @computedFrom('defaultServices')
  private get services(): Array<DisplayServiceModel> {
    return this.defaultServices;
  }

  @computedFrom('selectedChannel', 'defaultDirections')
  private get directions(): Array<string> {
    return this.defaultDirections.filter(item => {
      if(this.selectedChannel) {
        if(this.selectedChannel === 'Inbound Call' && (item === '' || item === 'Outbound')) {
          this.selectedDirection = 'Inbound';
          return false;
        } else if(this.selectedChannel === 'Outbound Call' && (item === '' || item === 'Inbound')) {
          this.selectedDirection = 'Outbound';
          return false;
        }
      }
      return true;
    });
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if(!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }

  protected detached(): void {
    this.oplog && this.oplog.unsubscribe();
    this.servicesOplog && this.servicesOplog.unsubscribe();
    this.enableSubscription && this.enableSubscription.dispose();
    this.dispositionCodesService.unsubscribeFromDispositionListDeletedEvent();
  }
}