import { LogManager, autoinject } from 'aurelia-framework';
import {LdapSyncModel} from "../../models/LdapSyncModel";
import {AbstractList} from "../../../../../zailab.abstract";
import {Router} from "aurelia-router";
import {EventAggregator} from "aurelia-event-aggregator";
import {SessionStore} from "../../../../../_common/stores/session-store";
import {List} from "../../../../../abstract/list/list-builder";
import {LdapGroupModel} from "../../models/ldap-group-model";
import {LdapGroupService} from "../ldap-groups-service";
import {DialogService} from "aurelia-dialog";
/**/
const logger = LogManager.getLogger('LdapGroups');

@autoinject
export class LdapGroups extends AbstractList {
  private ldap: LdapSyncModel;

  constructor(private element: Element, private dialogService: DialogService, private router: Router, private sessionStore: SessionStore, private ldapGroupService: LdapGroupService, protected eventAggregator: EventAggregator) {
    super(eventAggregator)
  }
  public activate(params: any): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.ldapGroupService.retrieveLdapGroups().then((groups) => {
      this.setupListConfig(groups);
    }, (error) => {
      logger.error('Failed to retrieve your organisations LDAP groups > error = ', error);
    });
  }

  protected subscribeToOplog(): void {
    this.ldapGroupService.initialiseLdapGroupsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        let newItems = this.itemList.items;
        newItems.push(response);

        this.setupListConfig(this.ldapGroupService._model(newItems));
      });
      this.oplog.on('delete', (response) => {

        let newItems = this.itemList.items.filter(item => item.ldapGroupId !== response.ldapGroupId);

        this.setupListConfig(this.ldapGroupService._model(newItems));
      });
    });
  }

  protected setupListConfig(groups: LdapGroupModel[]): void {
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .items(groups)
      .icon('icon')
      .uniqueIdentifier('ldapGroupId')
      .displayId('role')
      .description('ldapPath')
      .withProportion('medium')
      .tilesPerRow(6)
      .build();


    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected showAddItem(): void {
    super.showAddItem();
    this.router.navigate('create');
  }

  protected deleteItems(items: LdapGroupModel[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} Groups deleted.` : `Group for <b>${items[0].ldapGroupId}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(item: LdapGroupModel): void {
    super.delete(item);
    this.ldapGroupService.deleteGroup(item);
  }

}