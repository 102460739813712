import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { ArrayTools, MESSAGE_EVENTS, SessionStore } from 'zailab.common';
import { QAReportService } from '../qa-report-service';
import { QA_REPORT_GRID_STATE } from './qa-team-agents-grid-state';
import { DataGrid } from '../../../../../../components/templates/data-grid/data-grid';
import { TeamsService } from '../../../../../organisation/member/teams/teams-service';
import { BusinessPartnerService } from '../../../../../organisation/businesspartner/business-partner-service';
import { ExportReportDialog } from '../../export-dialog/export-dialog';
import { WorkTypesService } from '../../../../../organisation/conversation/worktypes/work-types-service';
import { WaitingRoomService } from '../../../../../organisation/performancemanagement/waiting-room/waiting-room-service';

import './qa-team-agents-grid.scss';

import moment from 'moment';

const logger = LogManager.getLogger('ReportQATeams');

@autoinject()
export class ReportQATeamAgents {
  
  private teamId: string;
  private businessPartnerId: string;

  public cdDataGrid: DataGrid;
  public filterBar;
  public date: string = 'Today';
  public dateOptions = this.generateDateOptions();
  public selectedService: string = 'All Services';
  public serviceOptions = [];
  public selectedWorkType: string = 'All Worktypes';
  public workTypeOptions = [];
  private reportData: {
    name: string;
    id: string;
    handledInteractions: number;
    interactionsAssessed: number;
    assessmentsPerformed: number;
    assessmentsPassed: number;
    assessmentsFailed: number;
  }[];

  private reportTypes = { team: 'Team', bp: 'Business Partner' };
  public reportType: string;

  public teams: { name: string; id: string }[];
  public selectedTeam: string;
  public businessPartners: { name: string; id: string }[];
  public selectedBusinessPartner: string;

  private businessPartnerReport = false;

  public lastRequestTimestamp: any;
  public currentRequestTimer = 30;

  public groupedSettings = {
    groupedColumns: ['Group'],
    captionFormat: '<span style="display:flex!important;border-bottom: 1px solid rgba(var(--theme-border-primary-accent-shade-35-rgb),var(--theme-border-opac-8)); " class="sticky flex--align-center"></span>'
  };

  constructor(
    private QAReportService: QAReportService,
    private router: Router,
    private teamsService: TeamsService,
    private sessionStore: SessionStore,
    private businessPartnerService: BusinessPartnerService,
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private workTypesService: WorkTypesService,
    private waitingRoomService: WaitingRoomService
  ) {}

  public async activate(params): Promise<void> {
    this.teamId = params.teamId;
    this.businessPartnerId = params.businessPartnerId;
    if (params.date) {
      this.date = params.date;
    }

    if (this.teamId) {
      this.reportType = this.reportTypes.team;
      this.getAllTeams();
    } else if (this.businessPartnerId) {
      this.reportType = this.reportTypes.bp;
      this.getAllBPs();
    }
    this.getAllServices();
    this.getAllWorktypes();

    let mappings = await QA_REPORT_GRID_STATE.mappings;
    this.cdDataGrid = new DataGrid(mappings);
    this.cdDataGrid.dataSource = [];
    this.setupFilter();
  }

  public setupFilter() {
    const generateFilterExpandCollapse = this.generateFilterExpandCollapse;
    const toggleFilter = this.toggleFilter;
    const getGrid = this.getGrid;
    this.filterBar = {
      input: {
        create: function (args: any): any {
          const columnName = args.column.field;
          let form = document.createElement('form');
          form.setAttribute('autocomplete', 'off');

          let input = document.createElement('input');
          input.setAttribute('autocomplete', 'off');
          input.setAttribute('placeholder', 'Enter value here');
          input.setAttribute('title', 'Filter name');
          input.style.color = 'var(--theme-text-contrast)';
          input.style.width = '100%';
          input.style.border = '0px';
          input.style.outline = 'none';
          input.style['border-radius'] = '2px';

          function keyUpHandler(): void {

            let gridObj = getGrid();
            gridObj.filterColumn(columnName, 'contains', '<span class="">' + this.value);
            generateFilterExpandCollapse(toggleFilter);
          }

          input.onkeyup = keyUpHandler;

          form.append(input);
          return form;
        },
        write: function (args: any): any {
          const columnName = args.column.field;
          // @ts-ignore
          var data = ej.DataManager(window.gridData).executeLocal(new ej.Query().select(columnName));
          // @ts-ignore
          args.element.ejAutocomplete({ width: "100%", dataSource: data, enableDistinct: true, focusOut: ej.proxy(args.column.filterBarTemplate.read, this, args) });
        }
      }
    };
  }

  private generateDateOptions(): any {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const dateOptions = [
        { display: 'Today', value: 'TODAY' },
        { display: 'This Week', value: 'WEEK' }
    ];
    
    let monthList = [];
    for (let year = 2023; year <= currentYear; year++) {
      const startMonth = (year === 2023) ? 7 : 1; // Start from July in 2023, else start from January
      const endMonth = (year === currentYear) ? currentMonth : 12;

      for (let month = startMonth; month <= endMonth; month++) {
        const monthString = new Date(year, month - 1, 1).toLocaleDateString('en-US', { month: 'long' });
        const yearShort = year.toString().slice(-2);
        const option = { 
          display: `${monthString}-${year}`, 
          value: `${monthString.toUpperCase()}-${yearShort}`
        };
        monthList.push(option);
      }
    }
    return dateOptions.concat(monthList.reverse());
  }

  private getAllTeams(): void {
    
    this.teamsService
      .retrieveOrganisationTeams(this.sessionStore.get.organisation.organisationId)
      .then(teams => {
        const teamsList = teams.map(team => {
          return {
            name: team.name,
            id: team.id
          }
        });
        this.teams = ArrayTools.sort(teamsList, 'name');
        this.selectedTeam = this.teams.find(team => team.id === this.teamId).name;
      });
  }

  private getAllBPs(): void {
    this.businessPartnerService
      .findAll()
      .then(businessPartners => {
        let businessPartnersList = businessPartners || [];
        this.businessPartners = ArrayTools.sort(businessPartnersList, 'name');
        this.selectedBusinessPartner = this.businessPartners.find(bp => bp.id === this.businessPartnerId).name;
      })
      .catch(e => console.warn(' > Faile to retieve business partners due to: ', e))
  }

  private getAllServices(): void {
    this.waitingRoomService
      .retrieveServicesByOrgId()
      .then((services: any[]) => {
        services = ArrayTools.sort(services, 'serviceName');
        this.serviceOptions = [{ serviceName: 'All Services' }].concat(services);
      })
  }

  private getAllWorktypes(): void {
      this.workTypesService
      .retrieveWorkTypes()
      .then(workTypes => {
        if(workTypes.length > 0) {
          workTypes = ArrayTools.sort(workTypes, 'templateName');
          this.workTypeOptions = [{ templateName: 'All Worktypes' }].concat(workTypes);
        }
      })
      .catch(e => {
        logger.warn(' > Failed to get worktypes due to ', e);
      });
  }

  public attached(): void {
    if (this.teamId) {
      this.getReportData(this.teamId, this.date, this.selectedService, this.selectedWorkType);
    } else if (this.businessPartnerId) {
      this.getReportData(this.businessPartnerId, this.date, this.selectedService, this.selectedWorkType);
    }
  }

  private async getReportData(teamData: string, date: any, selectedService: any, selectedWorktype: any): Promise<void> {
    this.cdDataGrid.ready = false;
    const dateValue = this.dateOptions.find(item => item.display === date).value;
    
    this.cdDataGrid.dataSource = [];
    this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer + 0.9);
    let setLastTimestamp = setInterval(() => {
      this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer + 0.9);
    }, 500);

    let serviceName = selectedService === 'All Services' ? null : selectedService;
    let taskTemplateName = selectedWorktype === 'All Worktypes' ? null : selectedWorktype;

    if (this.teamId) {
      let teamReport = await this.QAReportService.getQATeamAgentReport(dateValue, this.teamId, serviceName, taskTemplateName);
      this.reportData = ArrayTools.sort(teamReport.data, 'name');
    } else if (this.businessPartnerId) {
      let bpReport = await this.QAReportService.getQABPAgentReport(dateValue, this.businessPartnerId, serviceName, taskTemplateName);
      this.reportData = ArrayTools.sort(bpReport.data, 'name');
    }
    
    this.reportData.forEach(team => {
      this.cdDataGrid.addData(team, this.cdDataGrid.dataSource);
    });

    let grid = $("#Grid").data("ejGrid");
    grid.filterColumnCollection = [];
    grid.model.filterSettings.filteredColumns = [];

    grid.refreshContent();
    this.setReadyState();
    window.clearInterval(setLastTimestamp);

    setTimeout(() => {
      this.generateFilterExpandCollapse(this.toggleFilter);
    }, 500);
  }

  public async export(): Promise<void> {
    this.dialogService
      .open({
        viewModel: ExportReportDialog,
        model: {
          reportData: this.reportData,
          fileName: 'QA Team Agents Report - ' + (this.selectedTeam || this.selectedBusinessPartner) + ' - ' + this.date,
          view: 'agents',
          headers: ['Agent', 'Interactions Handled', 'Interactions Assessed', 'Assessments Passed', 'Assessments Failed']
        }
      }).whenClosed(response => {
        if (response.wasCancelled) {
          return;
        }
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Successfully downloaded team agents QA report.');
      });
  }
  
  public teamChanged(teamName: string, date: string): void {
    this.teamId = this.teams.find(team => team.name === teamName).id;
    this.getReportData(this.teamId, date, this.selectedService, this.selectedWorkType);
    this.router.navigate('teams/' + this.teamId)
  }

  public bpChanged(bpName: string, date: string): void {
    this.businessPartnerId = this.businessPartners.find(bp => bp.name === bpName).id;
    this.getReportData(this.teamId, date, this.selectedService, this.selectedWorkType);
    this.router.navigate('business-partner/' + this.businessPartnerId)
  }

  public actionComplete(event: any): void {
    if (event.requestType === 'sorting') {
      this.generateFilterExpandCollapse(this.toggleFilter);
    } else if (event.requestType === 'refresh') {
      this.generateFilterExpandCollapse(this.toggleFilter);
    }
  }

  private generateFilterExpandCollapse = (toggleFilter: () => void, counter?: number): void => {
    if (!counter) {
      counter = 0;
    } else if (counter > 20) {
      return;
    }

    let expandCollapseFilter: HTMLElement = document.querySelector('.e-grouptopleftcell.e-lastgrouptopleftcell > .e-headercelldiv.e-emptyCell');
    if (!expandCollapseFilter) {
      counter++;
      setTimeout(() => {
        this.generateFilterExpandCollapse(toggleFilter, counter);
      }, 100);
      return;
    }
    let shevron = document.createElement('section');

    shevron.className = `u-flex u-size-1 u-padding--0 o-z-icon--filter hoverable`;
    shevron.addEventListener('click', () => toggleFilter());
    expandCollapseFilter.innerHTML = '';
    expandCollapseFilter.append(shevron);

    let nameColumns: any = document.querySelectorAll('.e-columnheader > .e-headercell');
    this.removeDragEvents(nameColumns[0]);
    this.removeDragEvents(nameColumns[1]);
  }

  private removeDragEvents(nameColumn: HTMLElement): void {
    nameColumn.classList.remove('e-draggable');
    nameColumn.classList.remove('e-droppable');

    // the following disabled drag and drop for the name column
    let keys = Object.keys(nameColumn);
    keys.forEach(key => {
      if (key && nameColumn[key] && key.indexOf('jQuery') >= 0) {
        if (nameColumn[key].ejWidgets) {
          delete nameColumn[key].ejWidgets;
        }
        if (nameColumn[key].events) {
          delete nameColumn[key].events.mousedown;
        }
        if (nameColumn[key].ejDraggable) {
          delete nameColumn[key].ejDraggable;
        }
        if (nameColumn[key].ejDroppable) {
          delete nameColumn[key].ejDroppable;
        }
      }
    });
  }

  private setReadyState(): void {
    this.cdDataGrid.ready = true;
  }

  private toggleFilter(): void {
    let filterBar = document.querySelector('.e-filterbar');
    let filterEnabled = !filterBar.classList.contains('open');
    if (filterEnabled) {
      filterBar.classList.add('open');
    } else {
      filterBar.classList.remove('open');
    }
  }

  private getGrid(): any {
    let grid = $('#Grid')[0], gridObj;
    let keys = Object.keys(grid);
    keys.forEach(key => {
      if (key && grid[key] && key.indexOf('jQuery') >= 0) {
        if (grid[key].ejGrid) {
          gridObj = grid[key].ejGrid;
        }
      }
    })
    return gridObj;
  }

  public viewAgentReport(event: any): void {
    event && event.stopPropagation();
    if (this.teamId) {
      this.router.navigate('teams/' + this.teamId + '/member/' + event.detail.id + '?date=' + this.date);
    } else if (this.businessPartnerId) {
      this.router.navigate('business-partner/' + this.businessPartnerId + '/member/' + event.detail.id + '?date=' + this.date);
    }
  }

  public back(): void {
    if (this.teamId) {
      this.router.navigate('teams?date=' + this.date);
      
    } else if (this.businessPartnerId) {
      this.router.navigate('business-partner?date=' + this.date);
    }
  }
}
