import {computedFrom, LogManager} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('AvailableNumberModel');

export class AvailableNumberModel extends BaseModel {

  country = null;
  externalReference = null;
  numberId = null;
  numberType = null;
  provider = null;
  telephoneNumber = null;
  pricePerMonth = null;

  constructor(number) {
    super();
    this.mapProperties(number);
    this.formatNumberType(number.numberType);
  }

  formatNumberType(numberType) {
    this.numberType = numberType.replace('_', '-');
  }

  get numberIcon() {
    return 'numbers'
  }

  @computedFrom('numberType')
  get description() {
    return this.numberType.toLowerCase();
  }

  @computedFrom('pricePerMonth')
  get descriptionTwo() {

    if(!this.pricePerMonth) {
      return;
    }

    return `${this.pricePerMonth} p/m`;
  }

  @computedFrom('numberId')
  get uniqueIdentifier() {
    return this.numberId
  }

  @computedFrom('telephoneNumber')
  get displayId() {
    return this.telephoneNumber
  }
}
