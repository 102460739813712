import { autoinject, LogManager } from "aurelia-framework";
import { WorkTypesService } from "features/organisation/conversation/worktypes/work-types-service";
import { AgentScriptAdminConfigModel } from "./agent-script-admin-config-model";
import { AgentScriptAdminConfigService } from "./agent-script-admin-config-service";

const logger = LogManager.getLogger('AgentScriptAdminConfigState');

@autoinject
export class AgentScriptAdminConfigState {

  public workTypes = [];
  public availableWorkTypes = [];
  public selectedWorkTypes = [];
  public addedWorkTypes = [];
  public removedWorkTypes = [];
  public agentScriptAdminConfigList = [];

  constructor(
    private workTypesService: WorkTypesService,
    private agentScriptAdminConfigService: AgentScriptAdminConfigService,
  ) { }

  public clear(): void {
    this.addedWorkTypes.forEach((workType) => this.removeSelectedWorkType(workType));
    this.removedWorkTypes.forEach((workType) => this.addSelectedWorkType(workType));
  }

  public flush(): void {
    this.addedWorkTypes = [];
    this.removedWorkTypes = [];
  }

  public async deleteAll(ids: string[]): Promise<void> {
    if (!ids || !ids.length) {
      return;
    }
    return Promise.all(ids.map((id) => this.agentScriptAdminConfigService.delete(id))).then(() => {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.loadState().then(() => resolve());
        }, 1000);
      });
    });
  }

  public addSelectedWorkType(workType: any): void {
    this.addedWorkTypes.push(workType);
    this.selectedWorkTypes.push(workType);
    this.setAvailableWorkTypes();
  }

  public removeSelectedWorkType(workType: any): void {
    this.removedWorkTypes.push(workType);
    const index = this.selectedWorkTypes.indexOf(workType);
    this.selectedWorkTypes.splice(index, 1);
    this.setAvailableWorkTypes();
  }

  public async addAgentScriptAdminConfig(agentScriptAdminConfig: AgentScriptAdminConfigModel): Promise<void> {
    return this.agentScriptAdminConfigService.create(agentScriptAdminConfig).then(() => {
      this.agentScriptAdminConfigList.push(agentScriptAdminConfig);
      return;
    });
  }

  public removeAgentScriptAdminConfig(agentScriptAdminConfig: AgentScriptAdminConfigModel): void {
    const index = this.agentScriptAdminConfigList.indexOf(agentScriptAdminConfig);
    this.agentScriptAdminConfigList.splice(index, 1);
  }

  public async editAgentScriptAdminConfig(agentScriptAdminConfig: AgentScriptAdminConfigModel): Promise<void> {
    const existingAgentScriptAdminConfig = this.agentScriptAdminConfigList.find((item) => item.id === agentScriptAdminConfig.id);
    if (!existingAgentScriptAdminConfig) {
      return;
    }
    return this.agentScriptAdminConfigService.update(agentScriptAdminConfig).then(() => {
      existingAgentScriptAdminConfig.name = agentScriptAdminConfig.name;
      existingAgentScriptAdminConfig.workTypeIds = agentScriptAdminConfig.workTypeIds;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.loadState().then(() => resolve());
        }, 1000);
      });
    });
  }

  public async loadState(): Promise<void> {
    await this.retrieveWorkTypes();
    await this.retrieveAgentScripts();
  }

  private async retrieveAgentScripts(): Promise<void> {
    return this.agentScriptAdminConfigService.retrieveAll().then((data) => {
      this.agentScriptAdminConfigList = data;
      this.setSelectedWorkTypes();
      this.setAvailableWorkTypes();
    }).catch((error) => {
      logger.error(error);
    });
  }

  private async retrieveWorkTypes(): Promise<void> {
    return this.workTypesService.retrieveWorkTypes().then((workTypes) => {
      this.workTypes = workTypes;
      this.setAvailableWorkTypes();
    }).catch((error) => {
      logger.error(error);
    });
  }

  private setSelectedWorkTypes(): void {
    let selectedWorkTypeIds = [];
    for (const item of this.agentScriptAdminConfigList) {
      selectedWorkTypeIds = selectedWorkTypeIds.concat(item.workTypeIds);
    }
    this.selectedWorkTypes = this.workTypes.filter((workType) => {
      return selectedWorkTypeIds.indexOf(workType.templateId) >= 0;
    });
  }

  private setAvailableWorkTypes(): void {
    if (!this.selectedWorkTypes || !this.selectedWorkTypes.length) {
      this.availableWorkTypes = this.workTypes;
    } else {
      this.availableWorkTypes = this.workTypes.filter((workType) => {
        return this.selectedWorkTypes.indexOf(workType) < 0;
      });
    }
  }
}