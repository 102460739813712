import { inject, LogManager, customElement, containerless, observable, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/*
 */
import { Event, SessionStore } from 'zailab.common'
/*
 */
const logger = LogManager.getLogger('ZEnlargedList');
/*
 */
@containerless()
@inject(Element, Router, SessionStore)
@customElement('z-custom-list')
export default class {

  @bindable @observable list;
  @bindable scrollloader;
  @bindable ready;

  constructor(element, router, sessionStore) {
    this.element = element;
    this.router = router;
    this.sessionStore = sessionStore;
  }

  listChanged(newValue) {
    if (newValue && newValue.items) {
      this.ready = true;
    }
  }

  selectItem(item) {
    if (this.list.select) {
      this.list.select(item, this.list.items, this.list.required, this.element);
      new Event(this.element, 'select', { list: this.list.items, item: item });
    }
  }

  scrollEndReached() {
    new Event(this.element, 'scrollend', {});
  }
}

