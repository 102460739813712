import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { OplogService, WebSocket } from 'zailab.common';
import { Oplog } from '../../../_common/services/database-service';
import CampaignModel from './campaign-model';

const logger = LogManager.getLogger('CampaignService');

@inject(HttpClient, OplogService, WebSocket, Oplog)
export default class CampaignService {
  constructor(httpClient, oplogService, webSocket, oplog) {
    this.httpClient = httpClient;
    this.oplog = oplogService;
    this.webSocket = webSocket;
    this.oplogEvents = oplog;
  }

  retrieveCampaigns() {
    return new Promise((resolve, reject) => {
      const url = 'v1/campaign/campaigns';
      this.httpClient
        .createRequest(url)
        .asGet()
        .send()
        .then(
          (response) => {
            if (response.statusCode === 204) {
              response.numberOfTasksRemaining = 0;
            }

            response.campaigns = _modelCollection(response.campaigns || []);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  retrieveReportCampaigns() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/campaign/campaigns/prospectlists')
        .asGet()
        .send()
        .then(
          (response) => {
            if (
              response &&
              response.campaignItems &&
              response.campaignItems.length > 0
            ) {
              const model = _modelCollection(response.campaignItems);
              resolve(model);
            } else {
              resolve([]);
            }
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  subscribeToActiveCampaigns(organisationId) {
    return new Promise((resolve) => {
      resolve(
        this.oplog
          .subscribeOn('_id', organisationId)
          .in('campaign-projector.campaignListView')
      );
    });
  }

  create(campaign) {
    this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaign.campaignId}/create`)
      .asPost()
      .withContent(campaign)
      .send()
      .then(
        (response) => {},
        (error) => {
          logger.info('Could not successfully create campaign', {
            campaign: campaign,
            error: error,
          });
        }
      );
  }

  retrieveUploadSchedule(campaignId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/campaign/campaigns/${campaignId}/prospectlists/schedule`
        )
        .asGet()
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  setSchedule(campaign) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/campaign/campaigns/${campaign.campaignId}/prospectlists/schedule`
        )
        .asPut()
        .withContent(campaign)
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  configureWebhook(campaignId, webhookData) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/campaign/campaigns/${campaignId}/webhooks`)
        .asPut()
        .withContent(webhookData)
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  removeSchedule(campaignId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/campaign/campaigns/${campaignId}/prospectlists/schedule`
        )
        .asDelete()
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  retrieveDncSchedule(campaignId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/campaign/campaigns/${campaignId}/kill-lists/schedule`
        )
        .asGet()
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  setDncSchedule(campaignId, dncSchedule) {
    const oplogSubscription = this.oplogEvents
      .subscribe()
      .withNameSpace('campaign-projector.killListScheduleConfigurationView')
      .withKeyField('_id')
      .withKeyValue(campaignId);

    this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaignId}/kill-lists/schedule`)
      .asPut()
      .withContent(dncSchedule)
      .send();

    return oplogSubscription.when('insert').then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  removeDncSchedule(campaignId) {
    const oplogSubscription = this.oplogEvents
      .subscribe()
      .withNameSpace('campaign-projector.killListScheduleConfigurationView')
      .withKeyField('_id')
      .withKeyValue(campaignId);

    this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaignId}/kill-lists/schedule`)
      .asDelete()
      .send();

    return oplogSubscription.when('delete').then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  archiveCampaign(campaignId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/campaign/campaigns/${campaignId}`)
        .asPut()
        .withContent({ archive: true })
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  setPaused(campaignId, paused) {
    return this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaignId}`)
      .asPut()
      .withContent({ paused: paused })
      .send()
      .catch((response) => Promise.reject(JSON.parse(response.response)));
  }

  setDiallingRules(campaignId, diallingRules) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/campaign/campaigns/${campaignId}/diallingRules`)
        .asPut()
        .withContent(diallingRules)
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  setOperatingHours(campaignId, operatingHours) {
    return this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaignId}/operating-hours`)
      .asPut()
      .withContent({ operatingHours })
      .send();
  }

  edit(
    campaignId,
    taskTemplateId,
    autoAcceptTaskEnabled,
    dialerType,
    diallerOptions,
    preferredAgentReschedulingEnabled,
    preferredAgentReschedulingTimeout,
    ignoreDoNotContactList
  ) {
    return this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaignId}`)
      .asPut()
      .withContent({
        autoAcceptTaskEnabled,
        taskTemplateId,
        dialerType,
        diallerOptions,
        preferredAgentReschedulingEnabled,
        preferredAgentReschedulingTimeout,
        ignoreDoNotContactList
      })
      .send();
  }

  retrieveProspects(
    campaignId,
    prospectListId,
    pageNumber,
    fromDate,
    toDate,
    firstNameOrSurname,
    numberOrEmail,
    prospectStatuses,
    customFields
  ) {
    const params = {
      pageNumber,
      pageSize: 100,
      fromDate,
      toDate,
      firstNameOrSurname,
      numberOrEmail,
    };
    let url = `v1/campaign/campaigns/${campaignId}/prospectlists/${prospectListId}/prospects/search?`;
    for (let key in prospectStatuses) {
      url += `&prospectStatuses=${prospectStatuses[key]}`;
    }
    customFields.forEach((field) => {
      url += `&customFields.${field.description}=${field.value}`;
    });

    return this.httpClient.createRequest(url).asGet().withParams(params).send();
  }

  retrieveProspectCustomHeaders(campaignId, prospectListId) {
    return this.httpClient
      .createRequest(
        `v1/campaign/campaigns/${campaignId}/prospectlists/${prospectListId}/prospects/custom_headers`
      )
      .asGet()
      .send();
  }

  retrieveAudit(page) {
    return this.httpClient
      .createRequest('v1/campaign/campaigns/audit')
      .asGet()
      .withParams({ page, size: 40 })
      .send();
  }

  changeTaskTemplate(campaignId, taskTemplateData) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/campaign/campaigns/${campaignId}/webhook-prospect-lists/task-templates`
        )
        .asPut()
        .withContent(taskTemplateData)
        .send()
        .then(resolve, (error) => {
          reject(new Error(error));
        });
    });
  }

  retrieveLiveDashboard() {
    return this.httpClient
      .createRequest('v1/campaign/campaigns/live-dashboard')
      .asGet()
      .send();
  }

  downloadCampaignProspects(
    campaignId,
    campaignName,
    notServed,
    requeued,
    completed,
    stale,
    fromDate,
    toDate,
    prospectListId,
    action
  ) {
    const params = {
      campaignName,
      notServed,
      requeued,
      completed,
      stale,
      fromDate: fromDate,
      toDate: toDate,
      prospectListId: prospectListId || '',
      action: action || '',
    };
    return this.httpClient
      .createRequest(`v1/campaign/campaigns/${campaignId}/export`)
      .asGet()
      .withParams(params)
      .withResponseType('blob')
      .send()
      .then((response) => Promise.resolve(response.response));
  }
}

function _modelCollection(response) {
  let list = [];
  response.forEach((item) => {
    list.push(_modelEntity(item));
  });
  return list;
}

function _modelEntity(item) {
  return new CampaignModel(item);
}
