import {inject, LogManager, computedFrom} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {AudioService, PushNotificationService} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('IncomingTaskDialog');
/*
 * */
@inject(DialogController, AudioService, PushNotificationService)
export class IncomingTaskDialog {

  interaction;

  constructor(dialogController, audioService, pushNotificationService) {
    this.dialogController = dialogController;
    this.dialogController.rejectOnCancel = false;
    this.audioService = audioService;
    this.pushNotificationService = pushNotificationService;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param interaction
   * */
  activate(interaction) {
    this.interaction = interaction;
    this.pushNotificationService.notify(this.dialogHeader, {body: interaction.fullName});
  }

  @computedFrom('interaction.channel')
  get icon() {
    if(this.interaction.channel === 'campaign') {
      return 'campaign';
    }
    return 'call-back';
  }


  @computedFrom('interaction.channel', 'interaction.campaignName')
  get dialogHeader() {

    if(this.interaction.channel === 'campaign') {
      return `Campaign: ${this.interaction.campaignName}`;
    }
    return `Incoming Callback`;
  }

  /**
   * Closes the Aurelia dialog
   */
  accept() {
    this.pushNotificationService.close();
    this.dialogController.ok({ accepted: true });
  }

  cancel() {
    this.pushNotificationService.close();
    this.dialogController.cancel({ rejected: true }); // not using cancel as cancel is overwritten
  }

  detached() {
    this.audioService.stop();
  }
}
