import {computedFrom, inject, LogManager, NewInstance} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';

import moment from 'moment-timezone';

import {SessionStore} from 'zailab.common';
import {AbstractReportView} from "./abstract-report-view";
import {ReportBuilder} from "../report-builder";
import {ReportDownloader} from "../report-downloader";

const logger = LogManager.getLogger('AgentSurveyRatingMonthly');

@inject(NewInstance.of(ReportBuilder), ValidationControllerFactory, SessionStore)
export class AgentSurveyRatingMonthly extends AbstractReportView {

  protected dateFormat: string = 'MMM YYYY';

  constructor(private reportBuilder: ReportBuilder, protected validationControllerFactory: ValidationControllerFactory, protected sessionStore: SessionStore) {
    super(validationControllerFactory);
    this.reportName = 'agent-survey-rating-monthly';
  }

  private attached(): void {
    this.setupDayInMonthValidation();
    this.setDate();
  }

  protected setDate(): void {
    this.previousDate = moment.utc().format(this.dateFormat);
    this.selectedDate = moment(this.previousDate).format(this.dateFormat);
  }

  @computedFrom('selectedDate')
  protected get fileName(): string {
    let _fileName = this.reportName + '_';
    if(this.selectedDate) {
      _fileName = `${this.selectedDate} Agent Survey Rating.zip`;
      return _fileName.replace(/ /g, '-');
    }
  }

  private setupDayInMonthValidation(): void {
    ValidationRules.customRule('dateInPast', (value: any) => {
      let selectedDateMoment = moment(value).format(this.dateFormat);
      return !moment(this.previousDate).isBefore(selectedDateMoment);
    }, 'Please enter a past date.');

    ValidationRules.customRule('validDateValue', (value: any) => {
      return moment(value).isValid();
    }, 'The date value is incorrect.');

    ValidationRules
      .ensure('selectedDate')
      .required()
      .withMessage('Please enter a date.')
      .satisfiesRule('dateInPast')
      .satisfiesRule('validDateValue')
      .on(this);
  }

  private downloadReport(): void {
    const startDate = moment(this.selectedDate).startOf('month').format('ddd, DD MMM YYYY [00:00:00 GMT]');
    const endDate = moment(this.selectedDate).endOf('month').format('ddd, DD MMM YYYY [00:00:00 GMT]');
    this.downloading = true;
    let reportDownloader: ReportDownloader = this.reportBuilder
      .withUrl(`v3/performance-management/reports/agent-survey`)
      .withParams('startDate', startDate)
      .withParams('endDate', endDate)
      .withParams('timezone', 'Africa/Johannesburg')
      .withFileName(this.fileName)
      .build();
    reportDownloader.download().then(
      () => {
        this.downloading = false;
        this.downloadStatusMessage = 'Successful';
      },
      (e) => {
        this.downloading = false;
        if (e.statusCode === 429) {
          this.downloadStatusMessage = 'Our reporting service is at capacity. Please try again later.';
        } else {
          this.downloadStatusMessage = 'Download Unsuccessful';
        }
      }
    );
  }
}
