import { inject, customElement, bindable, LogManager, containerless } from 'aurelia-framework';
/*
 */
import { Event, SessionStore } from 'zailab.common';
import { List } from 'zailab.abstract';
import { SitesService } from '../sites-service';
/*
 */
let logger = LogManager.getLogger('CondensedSitesList');
/*
 */
@containerless()
@customElement('z-condensed-sites-list')
@inject(SitesService, SessionStore, Element)
export default class {

  @bindable required;
  @bindable multiselect;
  @bindable selected;
  @bindable canedit;
  @bindable nodefaultselected;
  @bindable({ attribute: 'items-per-row' }) itemsperrow;
  list;

  constructor(sitesService, sessionStore, element) {
    this.sitesService = sitesService;
    this.sessionStore = sessionStore;
    this.element = element;
  }

  attached() {
    this.sitesService.retrieveSites().then((response) => {
      this._setupConfig(response);
    }, (error) => {
      logger.info('sites query failed >> ', error);
    });
  }

  _setupConfig(sites) {

    let defaultSelectionStrategy = (this.nodefaultselected) ? null : this._defaultSelectionStrategy;

    this.list = List.Builder() //
      .required(this.required) //
      .customEventElement(this.element)
      .selectionType(this.multiselect ? List.MULTIPLE_SELECTION_TYPE : List.SINGLE_SELECTION_TYPE) //
      .title(this.title) //
      .items(sites) //
      .selectedItems(this.selected) //
      .icon('sites') //
      .uniqueIdentifier('id') //
      .displayId('name') //
      .selectionStrategy(defaultSelectionStrategy) //
      .enableReadOnly(this.canedit)
      .build();
  }

  select(event) {
    new Event(this.element, 'select', event);
  }

  get title() {
    return 'Select Site' + (this.multiselect ? 's' : '');
  }

  get itemsPerRow() {
    return this.itemsperrow || 6;
  }

  _defaultSelectionStrategy(item) {
    if (item.name.toLowerCase() === 'home') {
      return true;
    }
    return false;
  }

  updateItems(data) {
    new Event(this.element, 'updateitems', data);
  }
}


