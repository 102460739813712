import { Factory, inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';

import { CommandFactory, UrlFactory, OplogService, SessionStore } from 'zailab.common';
import { RoutingStatusModel } from './routing-status-model';
import { RoutingStatusActivityModel } from './routing-status-activity-model';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
import { ApplicationProperties } from '../../../../_config/application.properties';

const logger = LogManager.getLogger('RoutingStatusService');

@inject(HttpClient, UrlFactory, EventAggregator, Factory.of(CommandFactory), OplogService, ApplicationProperties, SessionStore)
export class RoutingStatusService {
  constructor(private httpClient: HttpClient, private urlFactory: UrlFactory, private eventAggregator: EventAggregator, private commandFactory: CommandFactory, private oplog: OplogService, private applicationProperties: ApplicationProperties, private sessionStore: SessionStore) {
  }

  public subscribeToMemberActivityChange(memberId: string): Promise<ZIOplog> {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', memberId).in('member-projector.activityRoutabilityView'));
    });
  }

  public retrieveRoutingStatus(memberId: string): Promise<RoutingStatusModel> {
    let uri = this.urlFactory.build(`v1/organisation/members/${memberId}/routing-status`);
    return new Promise((resolve, reject) => {
      this.httpClient.get(uri).then(
        response => {
          let routingStatusView = new RoutingStatusModel(response);
          this.eventAggregator.publish('routing.status.updated', routingStatusView);
          resolve(routingStatusView);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public retrieveMemberStatusActivity(memberId: string): Promise<RoutingStatusActivityModel> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/members/${memberId}/activity-routability`)
        .asGet()
        .send()
        .then(
          response => {
            let activityRoutability = new RoutingStatusActivityModel(response);
            resolve(activityRoutability);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public setRoutingStatusToReady(memberId: string): void {

    let routingStatus = 'READY';

    this.httpClient.createRequest(`v1/organisation/members/${memberId}/change-routing-status`)
      .asPost()
      .withContent({ routingStatus })
      .send()
      .then(response => { }, error => { });

  }

  public setRoutingStatusToNotReady(memberId: string): void {

    let routingStatus = 'NOT_READY';

    this.httpClient.createRequest(`v1/organisation/members/${memberId}/change-routing-status`)
      .asPost()
      .withContent({ routingStatus })
      .send()
      .then(response => { }, error => { });
  }

  public initialiseRoutingStatusOplog(memberId: string): Promise<ZIOplog> {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', memberId).in('member-projector.displayRoutingStatusView'));
    });
  }

  public markMemberAsResponsive(memberId: string): void {

    this.httpClient.createRequest(this.applicationProperties.apiQueryEndpoint + `waitingroom/global/members/${memberId}/mark-as-responsive`)
      .asPut()
      .send()
      .then(response => { }, error => { });
  }
}