import { inject, computedFrom, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { TrialAccountStatusService } from './trial-account-status-service';
import { SessionStore, SESSION_EVENTS } from 'zailab.common';
import TrialMoreInfo from '../../../_manual/trialinfo/trial-info';
import { TrialAccountStatusModel } from './trial-account-status-model';
import { AccountDetailDialog } from "../account-details-dialog/account-detail-dialog";
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';

const logger = LogManager.getLogger('Trial Account Status');

@inject(EventAggregator, Router, DialogService, TrialAccountStatusService, SessionStore, FeatureFlagService)
export class TrialAccountStatus {

  accountStatusCheck = 0;
  updateSubscription;
  displayBalance = false;
  accountDisabled = false;

  constructor(eventAggregator, router, dialogService, trialAccountStatusService, sessionStore, featureFlagService) {
    this.eventAggregator = eventAggregator;
    this.router = router;
    this.dialogService = dialogService;
    this.trialAccountStatusService = trialAccountStatusService;
    this.sessionStore = sessionStore;
    this.featureFlagService = featureFlagService;
  }

  activate(_position) {
    if (_position) {
      this.hudPosition = _position;
    }
  }

  attached() {
    this.featureFlagService.isEnabled('AccountDisabled').then((enabled) => {
      this.accountDisabled = enabled;
    });
    this.featureFlagService.isEnabled('fixed-pricing').then((enabled) => {
      if (!enabled) {
        this.displayBalance = true;
        this.subscribe();
        this.retrieveData();
      }
    });
  }

  retrieveData() {
    if (!this.updateSubscription) {
      this.updateSubscription = this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.ORGANISATION, () => {
        this.updateSubscription.dispose();
        this.retrieveData();
      });
    }

    if (this.organisation && this.organisation.organisationId) {
      this.subscribe();
      this.retrieveAccountStatus().then((_result) => {
        this.eventAggregator.publish(SESSION_EVENTS.ACCOUNT.EVENT, _result);
      });
      return;
    }

    setTimeout(() => {
      this.retrieveData();
    }, 500);
  }

  retrieveAccountStatus() {
    return new Promise((resolve, reject) => {
      this.trialAccountStatusService.retrieveAccountStatus().then((response) => {
        resolve(response);
      }, (error) => {
        if (!this.account && this.accountStatusCheck < 5) {
          this.accountStatusCheck++;
          setTimeout(() => {
            this.retrieveAccountStatus();
          }, (1000 * this.accountStatusCheck));
        }
      });
    });
  };

  handleCompanyInfo(response) {
    if (!response.companyInfo) {
      this.dialogService.open({ viewModel: AccountDetailDialog }).whenClosed(response => {
      });
    }
  }

  subscribe() {
    if (this.organisation) {
      const subscription = this.trialAccountStatusService.subscribeToOplogUpdates(this.organisation.organisationId);
      if (subscription && subscription.then) {
        subscription.then(oplog => {
          this.oplog = oplog;
          this.oplog.on('insert', updatedStatus => this.accountStatusUpdated(updatedStatus));
          this.oplog.on('update', updatedStatus => this.accountStatusUpdated(updatedStatus));
          this.oplog.on('delete', updatedStatus => this.accountStatusUpdated(updatedStatus));
        });
      }
    }

    this.eventAggregator.subscribe('update.account.status', updatedStatus => this.accountStatusUpdated(updatedStatus));
  }

  accountStatusUpdated(updatedStatus) {
    let accountStatus;
    this.accountStatusCheck = 0;
    if (updatedStatus) {
      this.eventAggregator.publish(SESSION_EVENTS.ACCOUNT.EVENT, new TrialAccountStatusModel(updatedStatus));
      this.subscribe();
    } else {
      this.retrieveAccountStatus();
      this.subscribe();
    }
  }

  navigateToBuyCreditTab() {
    if (this.router.currentInstruction.fragment === 'account' || this.router.currentInstruction.fragment === 'account?tab=upgrade') {
      this.eventAggregator.publish('account.tab.navigation', 'upgrade');
    } else {
      this.router.navigate('account?tab=upgrade');
    }
  }

  showMoreInfoManual() {
    this.dialogService.open({
      viewModel: TrialMoreInfo,
      model: {}
    }).whenClosed(response => {
    });
  }

  deactivate() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  /* Getters and setters */

  @computedFrom('account.daysRemaining')
  get trialText() {
    if (this.account) {
      return this.account.daysRemaining > 0 ? `Trial Active: ${this.account.daysRemaining} Days Remaining` : `Your trial has expired.`
    }
    return '';
  }

  @computedFrom('sessionStore.get.account')
  get account() {
    return this.sessionStore.get.account;
  }

  @computedFrom('sessionStore.get.organisation')
  get organisation() {
    return this.sessionStore.get.organisation;
  }
}
