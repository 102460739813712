import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { WebhookService } from '../webhook-service';
import { TableInputModel } from '../../../../../components/custom/table-input';
import { ApplicationProperties } from '../../../../../_config/application.properties';

const logger = LogManager.getLogger('Webhooks');

@autoinject()
export class Webhooks {

  private interactionFlowId: string;

  public live: boolean;
  public webhooks: any[] = [];
  public selectedWebhook: any;
  
  public tableData = [];
  public tableColumns = ['Header Name', 'Header Mapping'];
  public columnsRegex = [null, /^(?:(?![+{}()\\]).)*$/];
  public updatedDableData;


  constructor(
    private webhookService: WebhookService,
    private dialogController: DialogController,
    public applicationProperties: ApplicationProperties
  ) {}

  public activate(data: { data: { interactionFlowId: string; live: boolean } }): void {
    this.interactionFlowId = data.data.interactionFlowId;
    this.live = data.data.live;
    
    this.webhookService
      .getAssignedWebhooks(this.interactionFlowId, this.live)
      .then(response => {
        this.webhooks = response;
        this.selectDefault();
      })
      .catch(error => this.handleError(error));
  }

  public selectDefault(): void {
    if (this.webhooks.length > 0) {
      this.viewWebhook(this.webhooks[0]);
    } else {
      this.viewWebhook();
    }
  }

  public removeWebhookFromInteractionFlow(index: number, webhook: any, event: Event): void {
    event.stopPropagation();

    this.webhookService
      .removeWebhookFromFlow(this.interactionFlowId, webhook.webhookId)
      .then(() => this.handleWebhookRemoved(webhook.webhookId))
      .catch(error => this.handleError(error));
  }

  private handleWebhookRemoved(webhookId: string): void {
    this.webhooks = this.webhooks
                      .filter(item => item.webhookId !== webhookId);
    this.selectDefault();
  }

  private handleError(error: string): void {
    logger.warn(' > Error', error);
  }

  public viewWebhook(webhook?: any): void {
    this.selectedWebhook = webhook;
    this.updatedDableData = webhook.responseTypeValues;
    this.tableData = Object.entries(webhook.responseTypeValues).map(([key, value]) => [key, value]);
    this.webhooks.forEach(wh => wh.isSelected = false);
    if (webhook) {
      webhook.isSelected = true;
    }
    
  }

  public valueChanged(tableData: any): void {
    const reversedObject = {};

    tableData.forEach(([key, value]) => {
      reversedObject[key] = value;
    });
    this.updatedDableData = reversedObject;
  }

  public selectJSON(): void {
    this.selectedWebhook.responseType = 'JSON';
  }

  public selectXML(): void {
    this.selectedWebhook.responseType = 'XML';
  }

  public updateWebhook(): void {
    if (!this.selectedWebhook || !this.selectedWebhook.webhookId) {
      return this.dialogController.ok();
    }


    this.webhookService
      .updateWebhook(
        this.interactionFlowId,
        this.selectedWebhook.webhookId,
        this.selectedWebhook.responseType,
        this.updatedDableData
      )
      .then(() => this.dialogController.ok());
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
