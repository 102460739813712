import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {InteractionDetailModel} from './interaction-detail-model';
import {NotificationModel} from './notification-model';
import {ChannelModel} from './channel-model';
import {computedFrom} from 'aurelia-binding';

import moment from 'moment';

const logger = LogManager.getLogger('AgentModel');

interface CardStates {
  showActivity: boolean,
  showPresence: boolean,
  showStats: boolean,
  showConversing: boolean
}

export class AgentModel extends BaseModel {

  public memberId: string = null;

  public userId: string = null;
  public personId: string = null;
  public passportId: string = null;
  public ready: boolean = null;
  public whisperLocked: boolean = null;
  public spyLocked: boolean = null;
  public fullName: string = null;
  public email: string = null;
  public currentRoutingStatus: string = null;
  public currentActivityStatus: string = null;
  public currentActivityStatusStartTime: number = null;
  public formattedDurationSpentInActivityStatus: string = null;
  public currentPresence: string = null;
  public currentPresenceStartTime: number = null;
  public extension: number = null;
  public interactionDetails: Array<InteractionDetailModel> = null;
  public totalCallsMissedForCurrentDay: number = null;
  public totalCallsTransferredForCurrentDay: number = null;
  public totalInboundFlowCallsReceivedForCurrentDay: number = null;
  public totalOutboundFlowCallsMadeForCurrentDay: number = null;
  public totalEmailsReceivedForCurrentDay: number = null;
  public totalSMSesReceivedForCurrentDay: number = null;
  public averageSurveyScore: number = null;
  public callId: string = null;
  public onWrapUp: boolean = null;
  public isHovered: boolean = null;
  public notify: NotificationModel = null;
  public channels: Array<ChannelModel> = [];
  public showMenu: boolean = false;
  public actions: any = [];
  public teamName: string = null;
  public onlineOffline: string = null;
  public webphoneStatus: string = null;
  public webphoneStatusTimestamp: string = null;

  @computedFrom('spyLocked', 'whisperLocked')
  get snooping(): boolean {
    return this.spyLocked || this.whisperLocked;
  }

  @computedFrom('currentActivityStatus')
  get isConversing(): boolean {
    return this.currentActivityStatus === 'Conversing';
  }

  @computedFrom('currentPresence')
  get isOffline(): boolean {
    return this.currentPresence === 'Offline' || this.currentPresence === 'OFFLINE';
  }

  @computedFrom('onWrapUp')
  get conversationStatusDescription(): string {
    return this.onWrapUp ? 'Wrap Up' : 'Conversing'
  }

  @computedFrom('currentPresenceStartTime')
  get currentPresenceStart(): number {
    return this.currentPresenceStartTime;
  }

  @computedFrom('currentActivityStatusStartTime')
  get currentActivityStatusStart():number {
    return this.currentActivityStatusStartTime
  }

  get currentTimeStamp(): Date {
    return new Date;
  }

  @computedFrom('ready','isConversing','isOffline')
  get cardState(): CardStates {
    return {
      showActivity: this.ready && !this.isConversing,
      showPresence: !this.ready && !this.isOffline && !this.isConversing,
      showStats: !this.isConversing,
      showConversing: this.isConversing
    };

  }

  @computedFrom('currentRoutingStatus')
  get formattedCurrentRoutingStatus(): string {
    if (this.currentRoutingStatus === 'Ready') {
      return 'On Duty';
    }
    return 'Off Duty';
  }

  @computedFrom('currentPresence')
  get formattedCurrentPresence(): string {
    if (!this.currentPresence) {
      return 'On Duty';
    }
    return this.currentPresence;
  }

  @computedFrom('channels')
  get callInteractionCount(): string {
    const channel = this.getAgentChannelFor('Call');
    return channel ? channel.interactionCount : '0';
  }

  @computedFrom('channels')
  get imInteractionCount(): string {
    const channel = this.getAgentChannelFor('Instant Message');
    return channel ? channel.interactionCount : '0';
  }

  @computedFrom('channels')
  get chatInteractionCount(): string {
    const channel = this.getAgentChannelFor('CHAT');
    return channel ? channel.interactionCount : '0';
  }

  @computedFrom('channels')
  get smsInteractionCount(): string {
    const channel = this.getAgentChannelFor('SMS');
    return channel ? channel.interactionCount : '0';
  }

  @computedFrom('channels')
  get emailInteractionCount(): string {
    const channel = this.getAgentChannelFor('EMAIL');
    return channel ? channel.interactionCount : '0';
  }

  @computedFrom('channels')
  get campaignInteractionCount(): string {
    const channel = this.getAgentChannelFor('Campaign Task');
    return channel ? channel.interactionCount : '0';
  }

  @computedFrom('webphoneStatusTimestamp')
  get formattedWebphoneTime(): string {
    if (this.webphoneStatusTimestamp) {
      return moment(this.webphoneStatusTimestamp).format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
  }

  constructor(agent: AgentModel) {

    super();
    this.mapProperties(agent);
    if (this.currentActivityStatusStartTime) {
      this.formattedDurationSpentInActivityStatus = '';
    }
  }

  public getAgentChannelFor(channelName: string): ChannelModel {
    return this.channels.find((channel: ChannelModel) => {
      return channel.channel === channelName;
    });
  }

  public mapProperties(agent: AgentModel): void {

    super.mapProperties(agent);

    if(agent.currentActivityStatus) {
      this.currentActivityStatus = this.determineSubStatus(agent.currentActivityStatus);
    }

    if (agent.interactionDetails) {

      this.setCallIdAndWrapUpStatus(agent.interactionDetails);
      this.extractChannels(agent.interactionDetails);
    }
    this.notify = new NotificationModel(this);
    let placeholderCount = 4;

    this.generateChannelPlaceholders(placeholderCount);
  }

  private setCallIdAndWrapUpStatus(interactionDetails: Array<InteractionDetailModel>): void {

    if (interactionDetails) {
      for (let interactionDetail of interactionDetails) {
        if (this.isCallInteraction(interactionDetail)) {
          this.callId = this.findFirstValidCallId(interactionDetail);
          this.onWrapUp = interactionDetail.latestInteractionState === 'Wrap Up';
        }
      }
    } else {
      this.onWrapUp = false;
    }
  }

  private isCallInteraction(interactionDetail: InteractionDetailModel): boolean {
    return interactionDetail.currentKnownInteractions
      && interactionDetail.interactionType === 'Call';
  }

  private findFirstValidCallId(interactionDetail: InteractionDetailModel): string {
    for (let interaction of interactionDetail.currentKnownInteractions) {
      if (interaction.channelStatus !== 'Missed') {
        return interaction.interactionId;
      }
    }
  }

  private determineSubStatus(subStatus: string): string {

    return subStatus === 'Not Answering' ? 'Not Responding' : subStatus;
  }

  private extractChannels(interactions: Array<InteractionDetailModel>): Array<ChannelModel> {
    let channels: Array<ChannelModel> = [];

    interactions.forEach(interaction => {
      let channel: ChannelModel = new ChannelModel(interaction);
      channels.push(channel);
    });

    this.channels = channels;
  }

  private generateChannelPlaceholders(totalItems: number): void {

    let remainingItems: number = (totalItems - this.channels.length);
    let iteration = 0;

    while (remainingItems > iteration) {

      iteration++;

      this.channels.push(new ChannelModel({isPlaceholder: true}));
    }
  }

}