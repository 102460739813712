import {TelephoneNumberModel} from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import {ContactModel} from '../contact-model';

export class SearchContactModel {
  public number: TelephoneNumberModel = null;
  public firstName: string = '';
  public surname: string = '';
  public email: string = '';
  public page: number = 0;
  public size: number = 12;
  public selectedContact: ContactModel;
  public searchText: string = '';
  public unknownContactDetails: string = '';

  constructor() {
    this.number = new TelephoneNumberModel('', '', '');
  }
}
