import { LogManager, computedFrom } from 'aurelia-framework';
/**/
import { BaseModel } from 'zailab.abstract';
import { IExpectedData, IMetadata } from './interfaces';
/**/
import moment from 'moment-timezone';
/**/
const logger = LogManager.getLogger('ReminderModel');
/**/
export class ReminderModel extends BaseModel {
  public dueTimestamp: string = null;
  public type: string = 'reminder';
  public memberId: string = null;
  public message: string = null;
  public reminderId: string = null;
  public metadata: IMetadata = null;
  public processing: boolean = false;
  private radix: number = 10;

  constructor(data: IExpectedData) {
    super();
    super.mapProperties(data);
  }

  @computedFrom('metadata')
  get fullName(): string {
    if (this.metadata) {
      const firstName = this.metadata.contactFirstName || '';
      const surname = this.metadata.contactSurname || '';

      if (firstName || surname) {
        return firstName + ' ' + surname;
      }
      return 'No Name';
    }
    return null;
  }

  @computedFrom('dueTimestamp')
  get dueDate(): string {
    return moment(parseInt(this.dueTimestamp, this.radix))._d;
  }

  @computedFrom('dueTimestamp')
  get dueTime(): string {
    return moment(parseInt(this.dueTimestamp, this.radix)).format('HH:mm');
  }
}
