import {LogManager, inject, customElement, bindable} from 'aurelia-framework';

const logger = LogManager.getLogger('ZWidget');

@customElement('z-widget')
@inject(Element)
export class ZWidget {

  @bindable ischild;
  @bindable iscomingsoon;
  @bindable isbutton;
  @bindable icon;
  @bindable header;
  @bindable actions;
  @bindable initial;
  @bindable flexsize;
  @bindable isLoading = false;
  @bindable({attribute: 'has-settings'}) hasSettings = false;
  @bindable({attribute: 'show-settings'}) showSettings = false;
  @bindable({attribute: 'aspect-ratio'}) aspectRatio;
  @bindable({attribute: 'settings-label'}) settingsLabel = '';

  infoHasHover;
  contentHasHover;
  showWidgetSetup;

  showMenu = false;
  autoCloseTimer = 0;
  deferAutoClose = false;

  constructor(element) {
    this.element = element;
  }

  get formattedActions() {
    let _actions = [];
    for (let item of this.actions) {
      _actions.push({
        event: item,
        name: _case.first(item),
        icon: item.replace(' ', '')
      });
    }
    return _actions;
  }

  get visibilityComponent() {
    return this.header.replace(' ', '-').toLowerCase();
  }

  dispatchEvent(eventName) {
    this.closeMenu();
    this.element.dispatchEvent(
      new CustomEvent(eventName.replace(' ', ''), {
        bubbles: true,
        detail: {}
      })
    );
  }

  startAutoCloseMenu() {
    if (this.showMenu && this.autoCloseTimer === 0) {
      this.autoCloseTimer = window.setInterval(() => {
        this.closeMenu();
      }, 500);
    }
  }

  closeMenu() {
    if (!this.deferAutoClose) {
      this.showMenu = false;
      window.clearInterval(this.autoCloseTimer);
      this.autoCloseTimer = 0;
    }
  }

  preventAutoClose() {
    this.deferAutoClose = true;
  }

  allowAutoClose() {
    this.deferAutoClose = false;
  }

  toggle() {
    this.showMenu = !this.showMenu;
  }

  showContentHover() {
    if (this.showWidgetSetup) {
      return
    }
    this.contentHasHover = true;
  }

  hideContentHover() {
    if (this.showWidgetSetup) {
      return
    }
    this.contentHasHover = false;
  }

  showInfo() {
    this.infoHasHover = true;
  }

  hideInfo() {
    this.infoHasHover = false;
  }
}

let _case = {
  first: (string) => {
    let array = string.split(' ');
    let newString = '';

    for (let item of array) {
      newString += item.charAt(0).toUpperCase() + item.slice(1) + ' ';
    }
    return newString;
  }
};
