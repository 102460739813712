import { HttpClient } from 'aurelia-http-client';
import { inject, LogManager } from 'aurelia-framework';

import { OplogService } from 'zailab.common';
import { OverviewBalanceModel } from './overview-balance-model';

const logger = LogManager.getLogger('OverviewService');

@inject(HttpClient, OplogService)
export class OverviewService {
  constructor(httpClient, oplogService) {
    this.httpClient = httpClient;
    this.oplog = oplogService;
  }

  initialiseOplog(accountId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', accountId).in('account-projector.displayOverviewView'));
    });
  }

  retrieveAccountOverviewInformation() {
    return new Promise((resolve, reject) => {
      this._createRequest(
        'v1/finance/accounts/me/overview',
        response => {
          resolve(new OverviewBalanceModel(response));
        },
        error => {
          reject(error);
        }
      );
    });
  }

  _createRequest(uri, callback, errorCallback) {
    this.httpClient
      .createRequest(uri)
      .asGet()
      .send()
      .then(
        response => {
          callback(response);
        },
        error => {
          errorCallback(error);
        }
      );
  }
}
