import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/**/
import {SessionStore, OplogService, WebSocket} from 'zailab.common';
import {SearchableVariableModel} from './searchable-variable-model';
import { ZIOplog } from '../../../../../../typings/zai/zai.common';
/**/
const logger = LogManager.getLogger('DispositionCodesService');

@autoinject
export class SearchableVariablesService {

  constructor(private httpClient: HttpClient, private oplogService: OplogService, private sessionStore: SessionStore, private webSocket: WebSocket) {
  }

  public retrieveSearchableVariables(): Promise<SearchableVariableModel[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/organisation/me/searchable-interaction-variables`)
        .asGet().send()
        .then(response => resolve(this.modelCollection(response, SearchableVariableModel)), error => reject(error));
    });
  }

  public setSearchableVariables(searchableVariables: String[]): Promise<any> {
    if (!searchableVariables) {
      searchableVariables = [];
    }
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/organisation/me/searchable-interaction-variables`)
        .asPut()
        .withContent(searchableVariables)
        .send().then(success => resolve(success), error => reject(error))
    });
  }

  public modelCollection(items: any, Model: any): any {
    if (!items) {
      return [];
    }
    if (items instanceof Array) {
      let mappedList: Array<any> = [];
      for (let item of items) {
        mappedList.push(new Model({
          name: item,
          deleted: false
        }))
      }
      return mappedList;
    }
    return new Model(items);
  }
 
  public initialiseOplog(organisationId: string): Promise<ZIOplog> {
    return new Promise(resolve => {
      resolve(this.oplogService.subscribeOn('_id', organisationId).in('organisation-projector.organisationSearchableInteractionVariables'));
    });
  }
}