import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SearchTools } from '../../../../_common/tools/search-tools';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenterService } from '../../contactcenter/contact-center-service';
import { DisplayTeamsModel } from '../../member/teams/teams-model';
import { TeamsService } from '../../member/teams/teams-service';
import { TransferList } from '../transfer-list-model';
import { TransferListService } from '../transfer-list-service';

const logger = LogManager.getLogger('TransferListTeam');

@autoinject()
export class TransferListTeam {

  public transferList: TransferList;
  public businessPartner: string;
  public contactCenter: string;
  public teams: DisplayTeamsModel[];
  public filteredTeams: DisplayTeamsModel[];
  public ready: boolean = false;
  public showSearch: boolean = false;
  public searchTerm: string = '';
  public currentSelectedTeamFilter: string = 'ALL';

  constructor(
    private transferListService: TransferListService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private teamsService: TeamsService,
    private router: Router
  ) { }

  public activate(params: { id: string }): Promise<any> {
    return this.transferListService.findById(params.id)
      .then((transferList: TransferList) => {
        this.transferList = transferList;
        if (transferList.businessPartner) {
          this.businessPartnerService.findById(transferList.businessPartner)
            .then((businessPartner) => this.businessPartner = businessPartner.name);
        }
        if (transferList.contactCenter) {
          this.contactCenterService.findById(transferList.contactCenter)
            .then((contactCenter) => this.contactCenter = contactCenter.name);
        }
        this.teamsService.retrieveOrganisationTeams()
          .then((teams) => this.filteredTeams = this.teams = teams.map((team) => {
            team.selected = transferList.transferTeams ? transferList.transferTeams.indexOf(team.id) !== -1 : false;
            return team;
          }));
        this.ready = true;
      });
  }

  public toggleTeamSelection(team: any): void {
    if (team.inactive) {
      return;
    }
    team.inactive = true;
    if (team.selected) {
      this.transferListService.removeTransferTeam(this.transferList.id, team.id)
        .then(() => {
          team.selected = false;
          team.inactive = false;
        });
    } else {
      this.transferListService.addTransferTeam(this.transferList.id, team.id)
        .then(() => {
          team.selected = true;
          team.inactive = false;
        });
    }
    team.selected = !team.selected;
    this.updateTeamList();
  }

  public get heading(): string {
    let heading = `Teams: ${this.transferList.name}`;
    if (this.businessPartner) {
      heading += ` - Business Partner: ${this.businessPartner}`;
    }
    if (this.contactCenter) {
      heading += ` - Contact Center: ${this.contactCenter}`;
    }
    return heading;
  }

  public navigateBack(): void {
    this.router.navigate('list');
  }

  public navigateContacts(): void {
    this.router.navigate(`edit/${this.transferList.id}`);
  }

  public navigateMembers(): void {
    this.router.navigate(`member/${this.transferList.id}`);
  }

  public toggleSearch(): void {
    this.showSearch = !this.showSearch;
    this.searchTerm = '';
  }

  public updateTeamList(): void {
    if (this.currentSelectedTeamFilter === 'ALL') {
      this.filteredTeams = this.teams;
    }
    if (this.currentSelectedTeamFilter === 'ENABLED') {
      this.filteredTeams = this.teams.filter((team: any) => team.selected);
    }
    if (this.currentSelectedTeamFilter === 'DISABLED') {
      this.filteredTeams = this.teams.filter((team: any) => !team.selected);
    }
  }

  public partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}