import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { List } from 'zailab.abstract';
import { EventAggregator } from 'aurelia-event-aggregator';

import { CannedResponseModel } from "../canned-responses-model";
import { CannedResponseService } from "../canned-responses-service";

const logger = LogManager.getLogger('CannedResponsesList');

@autoinject()
export class CannedResponsesList {

  private itemList: List;
  private cannedResponses: CannedResponseModel[];
  private selectedFilter: string = 'All';
  public channelFilters: Array<string> = ['All', 'Email', 'SMS'];

  constructor(private cannedResponseService: CannedResponseService, private router: Router, protected eventAggregator: EventAggregator) {
  }

  public attached(): void {
    this.retrieveList();
  }

  private retrieveList(): void {
    this.eventAggregator.publish('app:loader:show');
    this.cannedResponseService
      .findResponseTemplates()
      .then(cannedResponseList => {
        this.cannedResponses = cannedResponseList;
        this.setupListConfig(cannedResponseList);
      })
      .catch(error => this.handleFailure(error));
  }

  private setupListConfig(cannedResponses: CannedResponseModel[]): void {
    this.itemList = List.Builder()
      .items(cannedResponses)
      .icon('icon')
      .displayId('name')
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .build();
    this.itemList.select = true;
    this.itemList.autoSelect = false;
    this.eventAggregator.publish('app:loader:hide');
  }

  private handleFailure(error: Error): void {
    logger.error('retrieve canned response list > error = ', error);
  }

  public addNewCannedResponse(): void {
    this.router.navigate('add');
  }

  public selectCannedResponse(event: any): void {
    this.router.navigate(`edit/${event.detail.item.id}`);
  }

  public deleteCannedResponse(event: any): void {
    let deletesLeft: number = event.detail.length;
    event.detail.forEach((cannedResponse: CannedResponseModel) => {
      this.cannedResponseService.deleteCannedResponse(cannedResponse.toPayload())
        .then(() => this.handleCannedResponseDeleted(cannedResponse, --deletesLeft))
        .catch((error) => logger.error(`deleteCannedResponse :: ${cannedResponse.id} :: error=`, error));
    });
  }

  public handleRadioFilterChange(event: any): void {
    this.updateCurrentFilter(event);
    let cannedResponseList;
    if(event.detail === this.channelFilters[0]) {
      cannedResponseList = this.cannedResponses;
    } else {
      cannedResponseList = this.cannedResponses.filter(item => item.channel === event.detail.toUpperCase());
    }
    this.setupListConfig(cannedResponseList);
  }

  private updateCurrentFilter(event: { detail: string }): void {
    if(event.detail) {
      this.selectedFilter = event.detail;
    }
  }

  private handleCannedResponseDeleted(cannedResponse: CannedResponseModel, deletesLeft: number): void {
    this.cannedResponses.splice(this.cannedResponses.indexOf(cannedResponse), 1);
    if (deletesLeft === 0) {
      this.setupListConfig(this.cannedResponses);
    }
    this.handleRadioFilterChange({ detail: this.selectedFilter });
  }

  public getStarted(): void {
    this.router.navigate('add');
  }
}