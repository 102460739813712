import { DialogController } from 'aurelia-dialog';
import './alert-sound-config-dialog.scss';
import { LogManager, autoinject } from 'aurelia-framework';
import { AlertSoundService } from '../alert-sound-config/alert-sound-service';

const logger = LogManager.getLogger('AlertSoundConfigDialog');

@autoinject
export class AlertSoundConfigDialog {

  public soundConfig = '';
  public selectedAudio = '';

  constructor(
    private dialogController: DialogController,
    private alertSoundService: AlertSoundService
  ) {}

  public activate(): void {
    const data = this.alertSoundService.retrieve();
    this.soundConfig = data.soundConfig;
    this.selectedAudio = data.selectedAudio;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public save(): void {
    this.alertSoundService.update(this.soundConfig, this.selectedAudio);
    this.dialogController.ok();
  }

  public submitSelectedAudio(): void {
    this.alertSoundService.testSoundAlert(this.selectedAudio);
  }
}
