import { autoinject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DisplayMessageService } from '../../../_common/services/display.message.service';
import { MESSAGE_EVENTS } from '../../../_common/services/display.message.service.events';

@autoinject
export class BazDialog {

  constructor(
    private readonly dialogController: DialogController,
    private readonly eventAggregator: EventAggregator,
  ) { }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public test(): void {
    let errorMessage = '<b>Dialler Error</b><br/>TEST ' + new Date();
    this.eventAggregator.publish(MESSAGE_EVENTS.ERROR_IMPORTANT, errorMessage);
  }
}