import { FormatTools } from './../../../../../_common/tools/format-tools';
import { computedFrom } from 'aurelia-binding';
import { BaseModel } from 'zailab.abstract';

//@ts-ignore
import moment from 'moment';

export class ProspectListContentModel extends BaseModel {

  public prospectListName: string = null;
  public businessPartnerId?: any = null;
  public contactCentreId?: any = null;
  public lineNumber: number = null;
  public formattedDate: string = null;
  public title: string = null;
  public firstName: string = null;
  public surname: string = null;
  public emails: string[] = [];
  public additionalProperties?: any = null;
  public webhookProspect: boolean = null;
  public status: string = null;
  
  private contactNumbers: IContactNumber[] = [];
  private dateAdded: number = null;
  
  constructor(data: IProspectListContent) {
    super();
    super.mapProperties(data);

    this.formatStatus(data.status);
    this.mapCustomFields(data.customFields);
    this.formattedDate = moment(parseInt(data.dateAdded)).format('D MMM YY HH:mm:ss');
  }

  private formatStatus(status: string): void {
    this.status = FormatTools.toTitleCase(status.replace('_', ' '));
  }

  private mapCustomFields(customFields: ICustomFields): void {
    if (customFields) {
      Object.keys(customFields)
        .forEach(field => {
          this[field] = customFields[field];
        })
    }
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberOne(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 1) {
      return null;
    }
    return this.contactNumbers[0].number;
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberTwo(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 2) {
      return null;
    }
    return this.contactNumbers[1].number;
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberThree(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 3) {
      return null;
    }
    return this.contactNumbers[2].number;
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberFour(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 4) {
      return null;
    }
    return this.contactNumbers[3].number;
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberFive(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 5) {
      return null;
    }
    return this.contactNumbers[4].number;
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberSix(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 6) {
      return null;
    }
    return this.contactNumbers[5].number;
  }

  @computedFrom('contactNumbers', 'contactNumbers.length')
  public get mobileNumberSeven(): string {
    if (!this.contactNumbers || this.contactNumbers.length < 7) {
      return null;
    }
    return this.contactNumbers[6].number;
  }
}