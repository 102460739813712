import {LogManager, autoinject} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
import {BootstrapFormRenderer} from 'zailab.common';
/**/
const logger = LogManager.getLogger('Name');
/**/
@autoinject
export class Name {

  private mailboxName: string = null;
  private wizard: object;
  private validation: ValidationController;
  private mailboxes: ZIMailbox[];

  constructor(validationControllerFactory: ValidationControllerFactory) {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

  }

  public activate(wizard: object): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.initValidation();
  }

  public attached(): void {
    this.wizard.step.incomplete();
  }

  private initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {

        for (let item of this.mailboxes) {
          if (item.mailboxName.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .ensure('mailboxName')
      .required().withMessage('Please enter a mailbox name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .on(this);
  }

  private initWizard(wizard: object): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {

    this.mailboxes = this.wizard.data;
    this.mailboxName = this.wizard.data.mailboxName;
  }

  private updateWizardData(): void {
    this.wizard.data.mailboxName = this.mailboxName;
  }

  private validate(): void {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        this.wizard.step.incomplete();
        return;
      }
      this.wizard.step.complete({name: this.mailboxName});
    }, error => this.wizard.step.incomplete());

  }

  private nextStep(): void {

    this.wizard.continue();

  }

}