import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import moment from 'moment';
import { AudioFileModel, FetchAudioService, MailboxService } from 'zailab.common';
import { EmptyStateAbstract } from '../../../interaction/interactionFlow/interaction-designer/dialogs/abstracts/empty-state-abstract';
import { AudioSearchFilter } from '../../../interaction/interactionFlow/interaction-designer/dialogs/audio-search-filter';
/**/
const logger = LogManager.getLogger('Message');
/**/
@autoinject
export class Message extends EmptyStateAbstract {

  private audioMessagesSearchResults: AudioFileModel[] = [];
  private selectedAudioFile: AudioFileModel;
  private initialAudioFileId: string;
  private mailboxExists: boolean = false;
  private mailboxId: string;
  private isSearching: boolean;
  private audioFileEAInstance: { dispose: () => void };
  private wizard: object;

  public emptyStateConfig:ZIEmptyState = {
    icon: 'empty-state-audio.svg',
    heading: `Your organisation does not yet have any audio messages.`,
    description: `You can create an audio message by uploading a WAV file or creating a text-to-speech audio message.`,
    buttonText: `Create Audio`,
    hasButton: true,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName('features/media/media/audiofiles/addaudio/add-audio-dialog'),
    hasTip: true,
    tipText: `Clicking this button will show the audio content creator.`
  };

  constructor(
    private fetchAudioService: FetchAudioService, 
    private eventAggregator: EventAggregator, 
    private dialogController: DialogController, 
    private mailboxService: MailboxService, 
    private element:Element, 
    protected dialogService:DialogService,
    public audioSearchFilter: AudioSearchFilter,
  ) {
    super(dialogService);
  }

  public activate(wizard: object): void {
    this.initWizard(wizard);
    this.extractWizardData();
  }

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  private initWizard(wizard: object): void {
    this.wizard = wizard;

    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.mailboxId = this.wizard.data.mailboxId || null;
    if (this.mailboxId) {
      this.mailboxExists = true;
    }

    this.initialAudioFileId = this.wizard.data.audio ? this.wizard.data.audio.audioId : '';

    this.retrieveAudioFiles();
  }

  protected handleContentCreated(_content:any):void {
    super.handleContentCreated(_content);
    this.retrieveAudioFiles('');
  }

  private updateWizardData(): void {
    this.wizard.data.audio = this.selectedAudioFile;
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  private retrieveAudioFiles(searchText: string = ''): void {
    this.fetchAudioService.searchAudioFiles(searchText)
      .then(audioMessages => this.audioFilesRetrieved(audioMessages), this.reportError);
  }

  private audioFilesRetrieved(audioFiles: AudioFileModel[]): void {
    this.audioSearchFilter.setAllAudioList(audioFiles);
    this.audioSearchFilter.onConfirmed(() => {
      this.audioMessagesSearchResults = this.audioSearchFilter.filter();
    });
    this.audioMessagesSearchResults = audioFiles;
    this.hasEntries = !!this.audioMessagesSearchResults.length;
    this.nodeDialogInitialised = true;
    this.audioMessagesSearchResults.forEach(audio => {
      audio.audioId = audio.id;
      audio.readableTimeStamp = moment(audio.timestamp).calendar();
      if (audio.audioId === this.initialAudioFileId) {
        audio.isSelected = true;
        this.selectedAudioFile = audio;
      }
    });
    this.validate();
  }

  private reportError(msg: any): void {
    logger.warn('Error: ', msg);
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
  }

  private startsWithMatch(searchExpression: string, value: AudioFileModel): boolean {
    if (!searchExpression) {
      return false;
    }
    return (value.filename.toLowerCase().startsWith(searchExpression.toLowerCase()));
  }

  private selectExistingAudioMessage(audioMessage: AudioFileModel): void {
    this.audioMessagesSearchResults.forEach(file => {
      file.isSelected = false;
    });
    this.selectedAudioFile = audioMessage;
    this.selectedAudioFile.audioId = audioMessage.id;
    audioMessage.isSelected = true;
    this.validate();
  }

  private validate(): void {
    if (!this.selectedAudioFile) {
      this.wizard.step.incomplete();
      return;
    }

    if (this.initialAudioFileId && this.initialAudioFileId !== this.selectedAudioFile.id) {
      this.triggerAddAudioCommand();
    }

    this.wizard.step.complete({audio: this.selectedAudioFile});
  }

  private triggerAddAudioCommand(): void {
    if (!this.mailboxExists) {
      return
    }
    let _addPayload: ZIMailboxAudioPayload = {
      audioId: this.selectedAudioFile.id,
      mailboxId: this.mailboxId
    };
    let _removePayload: ZIMailboxAudioPayload = {
      audioId: this.initialAudioFileId,
      mailboxId: this.mailboxId
    };
    this.mailboxService.removeAssociatedAudio(_removePayload).then(_result => {
      this.mailboxService.addAssociatedAudio(_addPayload).then(_result => {
        this.initialAudioFileId = this.selectedAudioFile.id;
      });
    });
  }
}