import { observable } from 'aurelia-binding';
import { autoinject, LogManager } from 'aurelia-framework';
import { ZaiForm } from 'zailab.common';

const logger = LogManager.getLogger('Menu Options | Message');

@autoinject
export class Message {
  public model;

  public form: any[][];
  @observable public formData: {
    name: string;
    variableName: string;
    valid: boolean;
  };

  public activate(bindingContext: { data: any }): void {
    this.initWizard(bindingContext);
    this.setupForm();
  }

  private initWizard(bindingContext: any): void {
    this.model = bindingContext;
    bindingContext.beforeNextStep(() => {
      this.model.continue();
    });

    if (this.model.data.message) {
      this.model.step.complete({});
    }
  }

  private setupForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput('textarea')
      .withFocus()
      .withIdentifier('message')
      .withTitle('Message', '160px')
      .withPlaceholder('Please enter your message here.')
      .withValue(this.model.data.message)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        {
          validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
          value: 500,
        },
      ])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public formDataChanged(newValue: any): void {
    if (newValue.valid) {
      this.model.step.complete(newValue);
      this.model.data.message = newValue.message;
    } else {
      this.model.step.incomplete();
    }
  }
}
