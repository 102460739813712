import {computedFrom, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
* */
const logger = LogManager.getLogger('SkillGroupModel');
/*
 * */
export class SkillGroupModel extends BaseModel {

  id = null;
  name = null;
  skills = null;
  hasSkills = null;

  constructor(skillGroup) {
    super();
    this.mapProperties(skillGroup);
  }

  @computedFrom('skills')
  get canDelete() {
    return !this.skills || this.skills.length === 0;
  }

  @computedFrom('skills')
  get description() {
    if(this.skills && this.skills.length) {
      return !this.skills.length ? 'No skills assigned' : `${this.skills.length} skill${this.skills.length !== 1 ? 's' : ''}`;
    }
  }

  get icon() {
    return 'skill-groups'
  }

  mapProperties(object) {
    if (object && object.skillGroupId) {
      object.id = object.skillGroupId;
      object.name = object.skillGroupName;
    }
    super.mapProperties(object);
  }
 }