import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/* */
import { UserRegistrationSettings } from '../user-registration-settings';
/* */
const logger = LogManager.getLogger('AcceptInvitationService');
/* */
@inject(HttpClient)
export class AcceptInvitationService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  getInvitationStatus(invitationId) {
    return this.httpClient
      .createRequest(`v1/user/invitations/${invitationId}/status`)
      .asGet()
      .withParams({ invitationId })
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }

  postInvitationAccept(invitationId, token) {
    return this.httpClient
      .createRequest(`v1/user/invitations/${invitationId}/accept`)
      .asPost()
      .withContent({ invitationId, token })
      .withHeader('Content-Type', 'application/json')
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }

  getRegistrationStatus(email) {
    return this.httpClient
      .createRequest('v1/user/user-registrations/status')
      .asGet()
      .withParams({ email })
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }

  getRegistrationCompletable(userRegistrationId) {
    return this.httpClient
      .createRequest(
        `v1/user/user-registrations/${userRegistrationId}/completable`
      )
      .asGet()
      .withParams({ userRegistrationId, token: '' })
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }
}
