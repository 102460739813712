import {inject, LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {CurrencyTools} from 'zailab.common';

import moment from 'moment-timezone';
import numeral from 'numeral';

const logger = LogManager.getLogger('UsageModel');

export class UsageModel extends BaseModel {
  category = null;
  charge = {};
  chargeId = null;
  date = null;
  description = null;
  itemId = null;
  metadata = {};
  quantity = null;
  rate = {};
  unit = null;
  emptyPlaceholder = '--';
  zeroCharge = null;
  unitConversionMap = {
    s: "seconds",
    min: "minutes",
    email: "email",
    sms: "sms",
    number: "number"
  };

  constructor(usage, index, currencySymbol) {
    super();
    this.setUpProperties(usage, index, currencySymbol);
    this.zeroCharge = currencySymbol+'0.0000';
  }

  setUpProperties(item, index, currencySymbol) {
    this.chargeId = item.chargeId ? item.chargeId : this.emptyPlaceholder;
    this.itemId = item.itemId ? item.itemId : '';
    this.date = item.date ? moment.utc(`${item.date} ${item.metadata.started}`).local().format('YYYY-MM-DD') : this.emptyPlaceholder;
    this.unit = item.components[index].quantity ? this._convertUnit(item.components[index].quantity.unit) : this.emptyPlaceholder;
    this.startTime = item.metadata.started ? moment.utc(item.metadata.started, 'HH:mm:ss').local().format('HH:mm:ss') : this.emptyPlaceholder;
    this.duration = item.metadata.duration ? item.metadata.duration : this.emptyPlaceholder;
    this.category = item.components[index].category ? item.components[index].category : this.emptyPlaceholder;
    this.direction = item.metadata.direction ? item.metadata.direction : this.emptyPlaceholder;
    this.source = item.metadata.source ? item.metadata.source : this.emptyPlaceholder;
    this.destination = item.metadata.destination ? item.metadata.destination : this.emptyPlaceholder;
    this.numberOfUnits = item.components[index].quantity ? item.components[index].quantity.value : this.emptyPlaceholder;
    this.charge = item.components[index].charge ? this._convertToCurrency(item.components[index].charge.amount, currencySymbol) : this.zeroCharge;
    this.originalCharge = item.components[index].charge ? `${currencySymbol} ${item.components[index].charge.amount}` : this.zeroCharge;
    this.rate = item.components[index].rate ? this._convertToCurrency(item.components[index].rate.amount, currencySymbol) + this._createChargeUnit(item.components[index].unit, item.components[index].quantity.unit) : this.emptyPlaceholder;
    this.item = item.components[index].description ? item.components[index].description : this.emptyPlaceholder;
    this.zeroCharge = currencySymbol + '0.00';
    this._overwriteDiscountedItems(index);
  }

  _createChargeUnit(rateUnit, unit) {

    let chargeUnit = '';

    if(rateUnit === 'minute' && unit === 's') {
      chargeUnit = ' / min';
    }

    return chargeUnit
  }

  _convertUnit(value) {
    return this.unitConversionMap[value] || value;
  }

  _convertToCurrency(value, currencyUnit) {
    let currencyFormat = '0,0.[0000000]';
    let formattedValue = numeral(value).format(currencyFormat);
    formattedValue = formattedValue.replace(/\.0+$/, '');
  
    if (value < 0) {
      return `- ${currencyUnit} ${formattedValue}`;
    }
    return `${currencyUnit} ${formattedValue}`;
  }

  _overwriteDiscountedItems(index) {
    if (index > 0) {
      this.direction = this.emptyPlaceholder;
      this.source = this.emptyPlaceholder;
      this.duration = this.emptyPlaceholder;
      this.destination = this.emptyPlaceholder;
    }
  }
}
