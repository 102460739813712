import { computedFrom, LogManager } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';
const logger = LogManager.getLogger('OrganisationSessionModel');

export class OrganisationSessionModel extends BaseModel {

  public organisationId: string = null;
  public organisationName: string = null;
  public integrationToken: string = null;
  public organisationProfilePicture: string = null;
  public activated: boolean = null;
  public country: ICountry = null;
  public isNew: boolean = null;

  constructor(data: OrganisationSessionModel) {
    super();
    this.mapEntity(data);
  }

  public mapEntity(data: OrganisationSessionModel): void {
    super.mapProperties(data);
  }
}

interface ICountry {
  name: string;
  code: string;
}