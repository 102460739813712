import { autoinject } from 'aurelia-dependency-injection';
import { HttpClient } from 'aurelia-http-client';

import { SessionStore } from 'zailab.common';

@autoinject()
export class InHouseWFMService {
  constructor(
    private httpClient: HttpClient,
    private sessionStore: SessionStore
  ) {}

  public retrieveConfig(): Promise<any> {
    return this.httpClient
      .createRequest(`v1/workforcemenagement/staffing/config`)
      .asGet()
      .send();
  }

  public setConfig(
    workingDays: string[],
    enablePublicHolidayShifts: boolean,
    enablePendingLeaveOverride: boolean,
    enableConfirmedLeaveOverride: boolean
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/workforcemenagement/staffing/config`)
      .asPost()
      .withContent({
        workingDays,
        enablePublicHolidayShifts,
        enablePendingLeaveOverride,
        enableConfirmedLeaveOverride,
      })
      .send();
  }

  public updateConfig(
    workingDays: string[],
    enablePublicHolidayShifts: boolean,
    enablePendingLeaveOverride: boolean,
    enableConfirmedLeaveOverride: boolean
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/workforcemenagement/staffing/config`)
      .asPut()
      .withContent({
        workingDays,
        enablePublicHolidayShifts,
        enablePendingLeaveOverride,
        enableConfirmedLeaveOverride,
      })
      .send();
  }

  public retrieveShiftTemplates(): Promise<any> {
    return this.httpClient
      .createRequest(`v1/workforcemenagement/staffing/shifttemplates/findAll`)
      .asGet()
      .send();
  }

  public retrieveShiftTemplate(shiftTemplateId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/workforcemenagement/staffing/shifttemplates`)
      .asGet()
      .withParams({ shiftTemplateId })
      .send();
  }

  public createShiftTemplate(
    shiftTemplateId: string,
    name: string,
    startTimeOfDay: string,
    shiftDurationInMin: number,
    breaks: {
      name: string;
      maxDurationInMin: number;
      minStartTimeOfDay: number;
      maxStartTimeOfDay: number;
      presence: string;
    }[],
    offDutyWork: { [key: string]: number }
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/workforcemenagement/staffing/shifttemplates/${shiftTemplateId}`
      )
      .asPost()
      .withContent({
        name,
        startTimeOfDay,
        shiftDurationInMin,
        breaks,
        offDutyWork,
      })
      .send();
  }

  public updateShiftTemplate(
    shiftTemplateId: string,
    name: string,
    startTimeOfDay: string,
    shiftDurationInMin: number,
    breaks: {
      name: string;
      maxDurationInMin: number;
      minStartTimeOfDay: number;
      maxStartTimeOfDay: number;
      presence: string;
    }[],
    offDutyWork: { [key: string]: number }
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/workforcemenagement/staffing/shifttemplates/${shiftTemplateId}`
      )
      .asPut()
      .withContent({
        name,
        startTimeOfDay,
        shiftDurationInMin,
        breaks,
        offDutyWork,
      })
      .send();
  }

  public deleteShiftTemplates(shiftTemplateId: string): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/workforcemenagement/staffing/shifttemplates/${shiftTemplateId}`
      )
      .asDelete()
      .send();
  }

  public createShift(
    teamIds: string[],
    memberIds: string[],
    fromDate: string,
    toDate: string,
    shiftTemplateId: string,
    taskTemplateIds: string[]
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/workforcemenagement/staffing/shifts`)
      .asPost()
      .withContent({
        teamIds,
        memberIds,
        fromDate,
        toDate,
        shiftTemplateId,
        taskTemplateIds,
      })
      .send();
  }

  public getShifts(
    teamIds: string[],
    memberIds: string[],
    startDate: string,
    endDate: string
  ): Promise<any> {
    let url = `v1/workforcemenagement/staffing/shifts?`;

    for (let teamId of teamIds) {
      url += `teamIds=${teamId}&`;
    }
    for (let memberId of memberIds) {
      url += `memberIds=${memberId}&`;
    }

    return this.httpClient
      .createRequest(url)
      .asGet()
      .withParams({
        startDate,
        endDate
      })
      .send();
  }

  public updateShift(
    memberId,
    shiftIds: string[],
    startTimeOfDay: string,
    shiftDurationInMin: number,
    breaks: {
      name: string;
      maxDurationInMin: number;
      minStartTimeOfDay: number;
      maxStartTimeOfDay: number;
      presence: string;
    }[],
    offDutyWork: { [key: string]: number },
    taskTemplateIds: string[]
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/workforcemenagement/staffing/shifts`
      )
      .asPut()
      .withContent({
        memberId,
        shiftIds,
        startTimeOfDay,
        shiftDurationInMin,
        breaks,
        offDutyWork,
        taskTemplateIds
      })
      .send();
  }

  public getShift(
    startDate: string,
    endDate: string
  ): Promise<any> {
    let url = `v1/workforcemenagement/staffing/shifts/me`;
    return this.httpClient
      .createRequest(url)
      .asGet()
      .withParams({
        startDate,
        endDate
      })
      .send();
  }

  public removeShifts(
    memberId: string,
    shiftIds: string[]
  ): Promise<any> {
    let url = `v1/workforcemenagement/staffing/shifts`;
    return this.httpClient
      .createRequest(url)
      .asDelete()
      .withContent({
        memberId,
        shiftIds
      })
      .send();
  }
}
