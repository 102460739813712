import {LogManager, autoinject, PLATFORM} from 'aurelia-framework';
import {ZIWizard} from '../../../../../../../../../typings/zai/zai.common';
/**/
const logger = LogManager.getLogger('Keypad');

/**/
@autoinject
export class Keypad {

  private wizard: ZIWizard;
  private keypadTabs: Array<object>;
  private digitType: string = null;
  private digits: any = null;

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.setupTabs();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
  }

  private extractWizardData(): void {
    this.digitType = this.wizard.data.digitType;
    this.digits = this.wizard.data.digits;
  }

  private setupTabs():void {
    this.keypadTabs = [
      {
        id: 'range',
        name: 'Keypad input',
        viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/pattern-menu/steps/keypad/tabs/range-of-digits-tab'),
        icon: 'numberhash',
        selected: this.digitType === 'range',
        data: {
          inputMaxLength: this.wizard.data.inputMaxLength,
          endsWith: this.wizard.data.endsWith,
          timeoutDestination: this.wizard.data.timeoutDestination,
          timeoutDuration: this.wizard.data.timeoutDuration,
          version: this.wizard.data.version
        }
      },
    ];
  }

  private handleTabData(_event: any): void {
    if (_event.detail.valid) {
      this.wizard.data.digitType = _event.detail.id;
      this.wizard.data.digits = [];
      this.wizard.data.endsWith = _event.detail.value.endsWith;
      this.wizard.data.inputMaxLength = _event.detail.value.inputMaxLength;
      this.wizard.data.multiInput = true;
      this.wizard.data.timeoutDestination = _event.detail.value.timeoutDestination;
      this.wizard.data.timeoutDuration = _event.detail.value.timeoutDuration;
      // Signal wizard that we are ready to continue
      this.wizard.step.complete();
    } else {
      this.wizard.step.incomplete();
    }
  }

  private nextStep(): void {
    this.wizard.continue();
  }

}
