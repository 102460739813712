import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('InteractionConfigModel');

declare const PLATFORM;

export class InteractionNodes {
  public static TEMPLATE = {
    icon: null,
    image: null,
    disableInConnector: null,
    defaultExtensions: null,
    name: null,
    description: null,
    type: null,
    inputConnectors: null,
    outputConnectors: null,
    defaultProperties: null,
  };

  public static useStart(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Start')
      .withType('startEvent')
      .withDialogTemplate(null)
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        isDefined: true,
        name: '',
        callback: '',
      })
      .disableInputConnector();
    return model;
  }

  public static useCriteria(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Assign Work Type')
      .withDescription(
        'Select the work item that must be created. The properties of the work item will be used to match to the agent who must handle it.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/work-type/work-type-dialog'
        )
      )
      .withIcon('briefcase')
      .withImage('briefcase')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useRecordVoiceClip(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Record Voice Clip')
      .withDescription(
        'Record a clip from the caller which can be used later in the flow.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/record-voice-clip/record-voice-clip-dialog'
        )
      )
      .withIcon('headphones')
      .withImage('headphones')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useApiCall(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('API Call')
      .withDescription(
        'Access an external resource by means of a REST call. Returned values can be used later on in the flow.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/api-call/api-call-dialog'
        )
      )
      .withIcon('cloud')
      .withImage('cloud')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }, { name: 'error' }]);
    return model;
  }

  public static useOfficeHours(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Office Hours')
      .withDescription(
        'Office hours allow you to route calls according to specific times and days of the week.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/office-hours/office-hours-dialog'
        )
      )
      .withIcon('officehours')
      .withImage('timer')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'yes' }, { name: 'no' }])
      .withDefaultProperties({
        isDefined: true,
        daysOfWeek: {
          Monday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: false,
          },
          Tuesday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: false,
          },
          Wednesday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: false,
          },
          Thursday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: false,
          },
          Friday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: false,
          },
          Saturday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: true,
          },
          Sunday: {
            startingTime: '090000',
            endingTime: '170000',
            closed: true,
          },
        },
      });
    return model;
  }

  public static useInputDialog(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Input Dialog')
      .withDescription('Request input from the Agent.')
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/input-dialog/input-dialog'
        )
      )
      .withIcon('comments-square')
      .withImage('comments-square')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }])
      .withDefaultProperties({
        timeout: 15,
        type: 'TEXTBOX',
      });
    return model;
  }

  public static useQueueingStrategy(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Waiting Room')
      .withDescription(
        "Define what your callers will hear while they're waiting for an agent to answer."
      )
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/waiting-room-dialog'
        )
      )
      .withIcon('timer')
      .withImage('timer')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }])
      .withDefaultProperties({
        name: 'Waiting Room',
        isDefined: true,
        agentsInQueue: '0',
        digits: [],
        audioMessages: {},
        waitTime: '0',
        autoExit: true,
        keyPressExit: false,
        hasExitDestination: false,
        queueAgentRingDuration: '12',
        queueMediaType: 'MOH',
        queueName: 'Waiting Room',
        queueStrategy: 'ROUND_ROBIN',
        notRespondingEnabled: true,
        showIncomingCallNotification: true,
        showConversationCard: true,
      });
    return model;
  }

  public static usePublicHolidays(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Public Holidays')
      .withDescription(
        'Public holidays allow you to route calls according to specific dates of the week.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/public-holidays/public-holidays-dialog'
        )
      )
      .withIcon('calendar')
      .withImage('calendar')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'yes' }, { name: 'no' }])
      .withDefaultProperties({
        isDefined: false,
        publicHolidays: [],
      });
    return model;
  }

  public static useCustomTimeRoutes(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Custom Time Routes')
      .withDescription(
        'Custom time routes allow you to route calls according to specific dates, times and days of the week.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/custom-time-routes/custom-time-routes-dialog'
        )
      )
      .withIcon('clock')
      .withImage('clock')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'yes' }, { name: 'no' }])
      .withDefaultProperties({
        isDefined: false,
        type: 'dow',
        name: '',
      });
    return model;
  }

  public static usePlayMessage(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Play Message')
      .withDescription(
        'Create a message for clients via text-to-speech or an uploaded audio file.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/play-message/play-message-dialog'
        )
      )
      .withIcon('play')
      .withImage('play')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useEmergencyAnnouncement(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Emergency Announcement')
      .withDescription('Set up emergency messages.')
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/emergency-announcement/emergency-announcement-dialog'
        )
      )
      .withIcon('exclamation')
      .withImage('exclamation')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useLoadBalancer(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Load Balancer')
      .withDescription('Setup criteria by which to split the load.')
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/load-balancer/load-balancer-dialog'
        )
      )
      .withIcon('split')
      .withImage('split')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useSelectionMenu(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('IVR')
      .withDescription(
        'Set up an IVR whereby your clients respond to custom questions and prompts using their keypad.'
      )
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/menu-options/menu-options-dialog'
        )
      )
      .withIcon('selectionmenu')
      .withImage('keypad')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        speechLanguage: {},
        digits: [],
        audioMessages: {},
        endsWith: '',
        inputMaxLength: 0,
        multiInput: false,
      });
    return model;
  }

  public static useTextIVR(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Text IVR')
      .withDescription(
        'Set up an IVR whereby your clients respond to custom questions and prompts.'
      )
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/text-ivr/text-ivr-dialog'
        )
      )
      .withIcon('selectionmenu')
      .withImage('keypad')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        name: '',
        variableName: '',
        message: '',
        output: [],
        timeoutDestination: false,
        timeoutDuration: 300,
        true: false,
      });
    return model;
  }

  public static usePatternMenu(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Pattern Menu')
      .withDescription(
        'Set up a Pattern Menu whereby your clients respond to custom questions and prompts using their keypad.'
      )
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/pattern-menu/pattern-menu-dialog'
        )
      )
      .withIcon('selectionmenu')
      .withImage('keypad')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        speechLanguage: {},
        digits: [],
        audioMessages: {},
        endsWith: '',
        inputMaxLength: 0,
        multiInput: false,
      });
    return model;
  }

  public static useInputCapture(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Input Capture')
      .withDescription(
        'Set up an Input Capture whereby your clients respond to custom questions and prompts using their keypad.'
      )
      .withType('advancedIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/input-capture/input-capture-dialog'
        )
      )
      .withIcon('selectionmenu')
      .withImage('keypad')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({});
    return model;
  }

  public static useOnInteraction(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('On Call')
      .withDescription(
        'Indicate the point at which your client is in conversation with an agent.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/on-call/on-call-dialog'
        )
      )
      .withIcon('call')
      .withImage('call')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        wrapUpEnabled: false,
        autoEndWrapUpEnabled: false,
        wrapUpDuration: 0,
        isDefined: true,
      });
    return model;
  }

  public static useScreenCall(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Screen calls')
      .withDescription(
        'Route specific callers based on the number they are calling from.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/screen-call/screen-call-dialog'
        )
      )
      .withIcon('screencall')
      .withImage('screen-call')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'yes' }, { name: 'no' }])
      .withDefaultProperties({ isDefined: false });
    return model;
  }

  public static useDecision(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Decision')
      .withDescription('Route to a specific path based on a variable.')
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/decision/decision-dialog'
        )
      )
      .withIcon('decision')
      .withImage('decision')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }])
      .withDefaultProperties({
        isDefined: false,
        name: '',
        variableName: '',
        values: [],
        defaultOutcomeEnabled: true,
      });
    return model;
  }

  public static useSendEmail(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Send Email')
      .withDescription('Send email allows you to send emails from your flow.')
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/send-email/send-email'
        )
      )
      .withIcon('envelope_out')
      .withIconClass('wide')
      .withImage('envelope_out')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }])
      .withDefaultProperties({
        isDefined: false,
      });
    return model;
  }

  public static useSendSMS(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Send SMS')
      .withDescription('Send SMS allows you to send SMSs from your flow.')
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/send-sms/send-sms'
        )
      )
      .withIcon('comments_out')
      .withIconClass('wide')
      .withImage('comments_out')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }])
      .withDefaultProperties({
        isDefined: false,
      });
    return model;
  }

  public static useSubFlow(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Subflow')
      .withDescription('Select a subflow to substitute in this flow.')
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/subflow/subflow-dialog'
        )
      )
      .withIcon('decision')
      .withImage('decision')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'default' }])
      .withDefaultProperties({
        isDefined: false,
        defaultOutcomeEnabled: false,
      });
    return model;
  }

  public static usePlayTextMessage(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Play Text Message')
      .withDescription('Play back a text message to the caller.')
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/play-text-message/play-text-message-dialog'
        )
      )
      .withIcon('play')
      .withImage('play')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        isDefined: false,
        name: '',
        callback: '',
      });
    return model;
  }

  public static useStartCallRecording(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Start Call Recording')
      .withDescription(
        'Start recording can only be placed before On Call to record a voice interaction.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(null)
      .withIcon('dot')
      .withImage('dot')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({ isDefined: true });
    return model;
  }

  public static useStopCallRecording(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Stop Call Recording')
      .withDescription(
        'End recording can only be placed after On Call to record a voice interaction.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(null)
      .withIcon('stopcallrecording')
      .withImage('stop-recording')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({ isDefined: true });
    return model;
  }

  public static useMailbox(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Mailbox')
      .withDescription(
        'Set up voicemail for designated members of your organisation.'
      )
      .withType('stopEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/mailbox/mailbox-dialog'
        )
      )
      .withIcon('voicemail')
      .withImage('voicemail')
      .withInputConnectors([{ name: 'in' }])
      .disableOutputConnector();
    return model;
  }

  public static useSurvey(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Survey')
      .withDescription(
        'Set up a survey whereby your clients respond to custom questions and prompts using their keypad.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/survey/survey-dialog'
        )
      )
      .withIcon('surveys')
      .withImage('file-checklist')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }])
      .withDefaultProperties({
        surveyId: null,
        questions: [],
        type: null,
        name: null,
      });
    return model;
  }

  public static useCallForward(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Call Forward')
      .withDescription(
        'Call Forwarding allow you to forward a call to a number/extension.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/call-forward/call-forward-dialog'
        )
      )
      .withIcon('call-outbound')
      .withImage('call-outbound')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useCallback(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Callback')
      .withDescription('Specify whether you want to schedule a callback.')
      .withType('stopEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/callback/callback-dialog'
        )
      )
      .withIcon('call-back')
      .withImage('call-back')
      .withInputConnectors([{ name: 'in' }])
      .withDefaultProperties({ notRespondingEnabled: true })
      .disableOutputConnector();
    return model;
  }

  public static useSetVariables(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Set Variables')
      .withDescription(
        'Set Variables allows you to add custom additional data to your flow.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/set-variables/set-variables-dialog'
        )
      )
      .withIcon('variable')
      .withImage('variable')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'always' }]);
    return model;
  }

  public static useAssistant(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('Assistant')
      .withDescription(
        'Assistant allows you to add configure an AI Assistant.'
      )
      .withType('basicIntermediateEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/assistant/assistant-dialog'
        )
      )
      .withIcon('person-question-mark')
      .withImage('person-question-mark')
      .withInputConnectors([{ name: 'in' }])
      .withOutputConnectors([{ name: 'Chat to agent' }, { name: 'Timeout' }]);
    return model;
  }

  public static useEnd(): ConfigModel {
    const model = new ConfigModel(this.TEMPLATE)
      .withName('End')
      .withDescription('Indicate the termination of a flow.')
      .withType('stopEvent')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/endcall/endcall-dialog'
        )
      )
      .withIcon('endcall')
      .withImage('stop')
      .withInputConnectors([{ name: 'in' }])
      .withDefaultProperties({
        isDefined: true,
        name: 'End',
      })
      .disableOutputConnector();
    return model;
  }
}

class ConfigModel {
  public icon: string = null;
  public iconClass: string = null;
  public image: string = null;
  public dialogTemplate?: string = null;
  public defaultExtensions: Array<string> = [];
  public disableInConnector: boolean = null;
  public disableOutConnector: boolean = null;
  public name: string = null;
  public description: string = null;
  public type: string = null;
  public inputConnectors: Array<{ name: string }> = [];
  public outputConnectors: Array<{ name: string }> = [];
  public defaultProperties: { [key: string]: any } = {};

  constructor(data: any) {
    this.icon = data.icon || this.icon;
    this.image = data.image || this.image;
    this.dialogTemplate = data.dialogTemplate || this.dialogTemplate;
    this.defaultExtensions = data.defaultExtensions || this.defaultExtensions;
    this.disableInConnector =
      data.disableInConnector || this.disableInConnector;
    this.name = data.name || this.name;
    this.description = data.description || this.description;
    this.type = data.type || this.type;
    this.inputConnectors = data.inputConnectors || this.inputConnectors;
    this.outputConnectors = data.outputConnectors || this.outputConnectors;
    this.defaultProperties = data.defaultProperties || this.defaultProperties;
  }

  public withName(data: string): ConfigModel {
    this.name = data;
    return this;
  }

  public withDescription(data: string): ConfigModel {
    this.description = data;
    return this;
  }

  public withType(data: string): ConfigModel {
    this.type = data;
    return this;
  }

  public withIcon(data: string): ConfigModel {
    this.icon = data;
    return this;
  }

  public withIconClass(data: string): ConfigModel {
    this.iconClass = data;
    return this;
  }

  public withImage(data: string): ConfigModel {
    this.image = data;
    return this;
  }

  public withDialogTemplate(data: string): ConfigModel {
    this.dialogTemplate = data;
    return this;
  }

  public withInputConnectors(data: Array<{ name: string }>): ConfigModel {
    this.inputConnectors = data;
    return this;
  }

  public withOutputConnectors(data: Array<{ name: string }>): ConfigModel {
    this.outputConnectors = data;
    return this;
  }

  public withDefaultProperties(data: { [key: string]: any }): ConfigModel {
    this.defaultProperties = data;
    return this;
  }

  public extendsTo(extensions: Array<string>): ConfigModel {
    this.defaultExtensions = extensions;
    return this;
  }

  public disableInputConnector(): ConfigModel {
    this.disableInConnector = true;
    return this;
  }

  public disableOutputConnector(): ConfigModel {
    this.disableOutConnector = true;
    return this;
  }
}
