import {LogManager} from "aurelia-framework";

const logger = LogManager.getLogger('UtteranceModel');

export class UtteranceModel {
  public speaker: string;
  public words: string;
  public startTime: number;


  constructor(speaker: string, words: string, startTime: number) {
    this.speaker = speaker;
    this.words = words;
    this.startTime = startTime;
  }
}