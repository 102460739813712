import {inject, LogManager, Containerless} from 'aurelia-framework';
/*
 * */
const logger = LogManager.getLogger('WidgetsListView');
/*
 * */
@inject(Element)
export class WidgetsListView {

  constructor(element) {
    this.element = element;
  }

  activate(wizard) {
    this._initWizard(wizard);
  }

  selectChannel(data) {
    this.wizard.data.channels = data.list;
  }

  selectCos(data) {
    this.wizard.data.classOfService = data.list;
  }

  selectService(data) {
    this.wizard.data.services = data.list;
  }

  selectSite(data) {
    this.wizard.data.sites = data.list;
  }

  selectSkill(skillGroups) {

    for(let skillGroup of skillGroups) {
      for(let skill of skillGroup.skills) {

        if(skill.isSelected) {
          this._addSkillToSkillGroup(skill, skillGroup);
        } else {
          this._removeSkillFromSkillGroup(skill, skillGroup);
        }
      }
    }
  }

  _addSkillToSkillGroup(skill, skillGroup) {
    let skillGroupFound = false;
    for(let _skillGroup of this.wizard.data.skills) {
      if(_skillGroup.skillGroupId === skillGroup.skillGroupId) {
        skillGroupFound = true;
        _skillGroup.skills.push(skill);
      }
    }
    if(!skillGroupFound) {
      this.wizard.data.skills.push(skillGroup);
    }
  }

  _removeSkillFromSkillGroup({skillId}, {skillGroupId}) {
    for(let skillGroup of this.wizard.data.skills) {
      if(skillGroup.skillGroupId === skillGroupId) {

        let skills = skillGroup.skills;
        for(let index = skills.length - 1; index >= 0; index--) {

          if(skills[index].skillId === skillId) {
            skills.splice(index, 1);
          }
        }
      }
    }
  }

  _getSelected(items) {
    let _items = [];
    for(let item of items) {
      if(item.isSelected) {
        _items.push(item);
      }
    }
    return _items;
  }

  _initWizard(wizard) {
    wizard.data.skills = wizard.data.skills || [];
    this.wizard = wizard;
    this._validateSelected();
    this.wizard.step.complete();

    wizard.beforeNextStep(() => {
      this.wizard.continue();
    });
  }

  _validateSelected() {
    this._validateChannels();
    this._validateCos();
    this._validateServices();
    this._validateSites();
  }

  _validateChannels() {
    let channels = this.wizard.data.channels;
    this.wizard.data.channels = this._getSelected(channels);
  }

  _validateCos() {
    let cos = this.wizard.data.classesOfService;
    this.wizard.data.classOfService = this._getSelected(cos);
  }

  _validateServices() {
    let services = this.wizard.data.services;
    this.wizard.data.services = this._getSelected(services);
  }
  _validateSites() {

    let sites = this.wizard.data.sites;
    this.wizard.data.sites = this._getSelected(sites);
  }

  _getSelected(list) {
    if(!list) {
      return list;
    }
    let _list = [];
    for(let item of list) {
      if(item.isSelected) {
        _list.push(item);
      }
    }
    return _list;
  }
}