import { HttpResponseMessage, HttpClient, RequestBuilder } from 'aurelia-http-client';
import { LogManager, autoinject } from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';

import { SessionStore } from 'zailab.common';

import { CannedResponseModel, CannedResponse } from './canned-responses-model';
import { Oplog } from '../../_common/services/database-service';

const logger = LogManager.getLogger('CannedResponseService');

@autoinject()
export class CannedResponseService {

  constructor(private http: HttpClient, private sessionStore: SessionStore, private oplog: Oplog) { }

  public findResponseTemplates(channel?: string): Promise<Array<CannedResponseModel>> {
    return this.getRequest()
      .withParams({ channel })
      .asGet()
      .send()
      .then((response: ResponseTemplatesResponse) => this.mapResponseTemplatesResponse(response));
  }

  public findCannedResponseById(id: string): Promise<CannedResponseModel> {
    return this.getRequest(id)
      .asGet()
      .send()
      .then((response: HttpResponseMessage) => Object.assign(new CannedResponseModel(), response));
  }

  public addCannedResponse(data: CannedResponse): Promise<boolean> {
    data.id = uuidv4();
    data.organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise<boolean>((resolve, reject) => {
      this.onCannedResponseDatabaseOperation(data.id, 'insert')
        .then(() => resolve());
      this.postCannedResponse(data)
        .catch((error) => reject(error));
    });
  }

  public editCannedResponse(data: CannedResponse): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.onCannedResponseDatabaseOperation(data.id, 'update')
        .then(() => resolve());
      this.putCannedResponse(data)
        .catch((error) => reject(error));
    });
  }

  public deleteCannedResponse(data: CannedResponse): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.onCannedResponseDatabaseOperation(data.id, 'delete')
        .then(() => resolve());
      this.getRequest(data.id).withContent(data).asDelete().send()
        .catch((error) => reject(error));
    });
  }

  private onCannedResponseDatabaseOperation(cannedResponseId: string, op: string): Promise<any> {
    const oplogSub = this.oplog.subscribe()
      .withKeyField('_id')
      .withKeyValue(cannedResponseId)
      .withNameSpace('response-management-projector.cannedResponseListView');
    return oplogSub.when(op).then(() => oplogSub.unsubscribe());
  }

  private postCannedResponse(data: CannedResponse): Promise<any> {
    return this.createRequest(RequestMethod.POST, data);
  }

  private putCannedResponse(data: CannedResponse): Promise<any> {
    return this.createRequest(RequestMethod.PUT, data);
  }

  private createRequest(method?: RequestMethod, data?: CannedResponse): Promise<any> {
    const request = this.getRequest(method.isPut() ? data.id : null);
    if (data) {
      request.withContent(data);
    }
    if (method) {
      if (method.isPost()) {
        request.asPost();
      }
      if (method.isPut()) {
        request.asPut();
      }
    }
    return request.send();
  }

  private getRequest(id?: string): RequestBuilder {
    return this.http.createRequest(`/v1/communication/response-management/canned-responses${id ? `/${id}` : ''}`);
  }

  private mapResponseTemplatesResponse(response: ResponseTemplatesResponse): Array<CannedResponseModel> {
    return response.cannedResponses.map((cannedResponse: CannedResponse) => CannedResponseModel.from(cannedResponse));
  }
}

class RequestMethod {

  static POST: RequestMethod = new RequestMethod('POST');
  static PUT: RequestMethod = new RequestMethod('PUT');

  constructor(private method: string) { }

  public isPost(): boolean {
    return this.method === 'POST';
  }

  public isPut(): boolean {
    return this.method === 'PUT';
  }
}

class ResponseTemplatesResponse extends HttpResponseMessage {

  public cannedResponses: Array<CannedResponse>;
}
