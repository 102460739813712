import {LogManager, autoinject, bindable} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {computedFrom} from "aurelia-binding";
/**/
import { BootstrapFormRenderer } from 'zailab.common';
import { Validation } from 'zailab.validation';
import {NodeModel} from "../../../../../../components/organisms/node/models/node-model";
import {ConnectorModel} from "../../../../../../components/organisms/connector/models/connector-model";
import {InteractionFlow} from "../../interaction-flow";
/**/
const logger = LogManager.getLogger('OnCallDialog');
/**/
@autoinject
export class OnCallDialog {
  @bindable wrapUpEnabled : boolean = false;
  @bindable autoEndWrapUpEnabled : boolean = false;
  @bindable wrapUpDuration : number = 0;
  @bindable screenPopUrl : string;

  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private nodeConfiguration: ZNodeConfig;
  private values: Array<string> = [];
  private isOutboundCCFlow : boolean;
  private canSave: boolean = false;
  private isAddingContent: boolean = false;


  constructor(
    private dialogController: DialogController,
    private interactionFlow: InteractionFlow
  ) {
    this.isOutboundCCFlow = this.interactionFlow.interactionFlowChannel === 'Outbound Call' && this.interactionFlow.interactionFlowType === 'Contact Centre Flow';
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.init();
    this.validate();

  }

  private init(): void {
    this.wrapUpEnabled = this.nodeData.properties["wrapUpEnabled"];
    this.autoEndWrapUpEnabled = this.nodeData.properties["autoEndWrapUpEnabled"];
    this.wrapUpDuration = this.nodeData.properties["wrapUpDuration"] ? this.nodeData.properties["wrapUpDuration"] : 0;
    this.screenPopUrl = this.nodeData.properties["screenPopUrl"] ? this.nodeData.properties["screenPopUrl"] : null;
  }

  public attached(): void {
  }

  // Class-specific
  private cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.nodeData.properties["wrapUpEnabled"] = this.wrapUpEnabled;
    this.nodeData.properties["autoEndWrapUpEnabled"] = this.autoEndWrapUpEnabled;
    this.nodeData.properties["wrapUpDuration"] = Number(this.wrapUpDuration);
    this.nodeData.properties["screenPopUrl"] = this.screenPopUrl;
    this.validate();
    this.dialogController.ok(this.nodeData);
  }

  private toggleWrapUpEnabled() : void {
    this.wrapUpEnabled = !this.wrapUpEnabled;
    this.validate()
  }

  private toggleAutoEndWrapUpEnabled() : void {
    this.autoEndWrapUpEnabled = !this.autoEndWrapUpEnabled;
    this.validate();
  }

  private validate(): void {
    if (this.wrapUpEnabled && this.autoEndWrapUpEnabled && (!this.wrapUpDuration || this.wrapUpDuration <= 0)) {
      this.nodeData.properties.isDefined = false;
      return;
    }
    this.nodeData.properties.isDefined = true;
  }

  public mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: {connectorIndex: 0, nodeID: this.nodeData.id},
        customExtensions: null
      })
    ];
    return _connections;
  }

  @computedFrom('wrapUpEnabled','autoEndWrapUpEnabled','wrapUpDuration')
  get isValid(): boolean {
    return !(this.wrapUpEnabled && this.autoEndWrapUpEnabled && (!this.wrapUpDuration || this.wrapUpDuration <= 0));
  }
}