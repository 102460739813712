import {LogManager, autoinject} from 'aurelia-framework';
import timezone from 'countries-and-timezones';
/**/
import {SessionStore} from 'zailab.common';

const logger = LogManager.getLogger('TimezoneService');

@autoinject
export class TimezoneService {

  constructor(private sessionStore: SessionStore) {}

  /*
   * Retrieves and formats all the timezones for the logged-in organisation. */
  public getTimezones(): any {

    let countryCode = this.sessionStore.get.organisation.country.code;
    let timezones = timezone.getTimezonesForCountry(countryCode);
    timezones.map(zone => zone.description = `(UTC ${zone.dstOffsetStr}) ${this.stripTimezoneName(zone.name)}`);
    return timezones;
  }

  private stripTimezoneName(name: string): string {

    let startIndex = name.indexOf('/');

    name = name.replace('_', ' ');
    name = name.replace(/\//g, ', ');
    return name.substring(startIndex + 1, name.length);
  };

  public getSelectedTimezone(timezone: string): ZITimezone {

    let timezones: ZITimezone[] = this.getTimezones();

    if(!timezone) {
      timezones[0];
    }

    return timezones.filter(_timezone => _timezone.name === timezone)[0];
  }

}
