/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPageFooter');
/*
 */
@customElement('z-page-footer')
export class ZPageFooter {


}
