import { autoinject } from "aurelia-dependency-injection";
import { DoNotContactType } from "./donotcontact-model";
import { DoNotContactService } from "./donotcontact-service";

@autoinject
export class DoNotContactState {

  constructor(
    private dncService: DoNotContactService
  ) {}

  public async isDoNotContact(type: DoNotContactType, value: string): Promise<boolean> {
    const dnc = await this.dncService.retrieve(type, value);
    if (!dnc) {
      return false;
    }
    return true;
  }

  public async isDoNotContactEmail(value: string): Promise<boolean> {
    return await this.isDoNotContact(DoNotContactType.EMAIL, value);
  }

  public async isDoNotContactNumber(value: string): Promise<boolean> {
    return await this.isDoNotContact(DoNotContactType.NUMBER, value);
  }
}