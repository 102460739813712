import { inject, bindable, customElement, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { BusinessPartner } from '../business-partner-model';
import { BusinessPartnerService } from '../business-partner-service';

const logger = LogManager.getLogger('BusinessPartnerForm');

@customElement('business-partner-form')
@inject(Router, Element, BusinessPartnerService, CurrentScopeValidation)
export class BusinessPartnerForm {

  @bindable({ attribute: 'business-partner' }) public businessPartner: BusinessPartner;
  @bindable({ attribute: 'page-header' }) public pageHeader: string;
  @bindable({ attribute: 'is-processing' }) public isProcessing: boolean = false;

  private validation: ValidationController;

  constructor(
    private router: Router,
    private element: Element,
    private businessPartnerService: BusinessPartnerService,
    public validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public attached(): void {
    this.initValidationRules();
  }

  public cancel(): void {
    this.router.navigate('list');
  }

  public save(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      this.element.dispatchEvent(new CustomEvent('save', { bubbles: true, detail: this.businessPartner }));
    }
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((businessPartner: BusinessPartner) => businessPartner.name).required().maxLength(30).then().satisfies((name) => {
        return this.businessPartnerService.findAll()
          .then((list) => {
            list = list || [];
            const existing = list.find((businessPartner) => businessPartner.name === name && businessPartner.id !== this.businessPartner.id);
            return !existing;
          })
          .catch((error) => {
            logger.error('retrieve list > error = ', error);
          });
      })
      .on(this.businessPartner);
  }
}