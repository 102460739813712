/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZLabel');
/*
 */
@customElement('z-label')
export class ZLabel {

  @bindable required;
  @bindable label;

}
