import {LogManager, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import { DashboardWidget } from './../../organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('WebchatWidget');

@autoinject()
export class WebchatWidget extends DashboardWidget {


  constructor(public router: Router) {
    super();
  }

}
