import { computedFrom } from 'aurelia-binding';
import { LogManager } from 'aurelia-framework';
import { HttpResponseMessage } from "aurelia-http-client";

const logger = LogManager.getLogger('AlertModel');

export class Alert {

  public alertId: string;
  public organisationId: string;
  public name: string;
  public message: string;
  public trigger: AlertTrigger;
  public audienceType: AlertAudienceType;
  public audience: string;
  public priority: AlertPriority;
  public active: boolean;
  public icon?: string = 'audio';
  public canDelete?: boolean = true;

  // additionals not published
  public id?: string;

  public get description(): string {
    if (this.trigger === AlertTrigger.ONCE_OFF) {
      return 'SENT';
    }
    return `${this.active ? 'ACTIVE' : 'INACTIVE'}`;
  }

  @computedFrom('trigger', 'priority', 'audienceType')
  public get descriptionTwo(): string {
    const labels = [
      AlertTriggerSelection.labelFor(this.trigger),
      AlertPrioritySelection.labelFor(this.priority),
      AlertAudienceTypeSelection.labelFor(this.audienceType)
    ].filter((label) => label);

    return labels.join(', ');
  }
}

export class AlertsResponse extends HttpResponseMessage {

  public organisationId: string;
  public alerts: Alert[];
}

export class AlertMessage {

  public alertId: string;
  public message: string;
  public name: string;
  public priority: string;
  public timezone: string;
  public time: string;
  public date: string;
}

export class AlertsMessagesResponse extends HttpResponseMessage {

  public memberId: string;
  public messages: AlertMessage[];
}

export class AlertResponse extends HttpResponseMessage {

  public alertId: string;
  public organisationId: string;
  public name: string;
  public message: string;
  public trigger: AlertTrigger;
  public audienceType: AlertAudienceType;
  public audience: string;
  public priority: AlertPriority;
  public active: boolean;
}

export interface Option {

  value: string;
  label: string;
}

export class AlertTriggerSelection {

  public static get options(): Option[] {
    return [
      { value: AlertTrigger.ONCE_OFF, label: 'Once Off' },
      { value: AlertTrigger.QA_SCORE, label: 'QA Score' },
      { value: AlertTrigger.SURVEY_SCORE, label: 'Survey Score' }
    ];
  }

  public static labelFor(value: AlertTrigger): string {
    return this.options.find((option) => option.value === value).label;
  }
}

export class AlertPrioritySelection {

  public static get options(): Option[] {
    return [
      { value: AlertPriority.LOW, label: 'Low' },
      { value: AlertPriority.MEDIUM, label: 'Medium' },
      { value: AlertPriority.HIGH, label: 'High' }
    ];
  }

  public static labelFor(value: AlertPriority): string {
    return this.options.find((option) => option.value === value).label;
  }
}

export class AlertAudienceTypeSelection {

  public static get options(): Option[] {
    return [
      { value: AlertAudienceType.ORGANISATION, label: 'Organisation' },
      { value: AlertAudienceType.BUSINESS_PARTNER, label: 'Business Partner' },
      { value: AlertAudienceType.CONTACT_CENTER, label: 'Contact Center' },
      { value: AlertAudienceType.TEAM, label: 'Team' },
      { value: AlertAudienceType.MEMBER, label: 'Member' },
    ];
  }

  public static get leaderOptions(): Option[] {
    return [
      { value: AlertAudienceType.TEAM, label: 'Team' },
      { value: AlertAudienceType.MEMBER, label: 'Member' },
    ];
  }

  public static labelFor(value: AlertAudienceType): string {
    return this.options.find((option) => option.value === value)?.label;
  }
}

export enum AlertAudienceType {

  ORGANISATION = 'ORGANISATION', //
  BUSINESS_PARTNER = 'BUSINESS_PARTNER', //
  CONTACT_CENTER = 'CONTACT_CENTER', //
  TEAM = 'TEAM', //
  MEMBER = 'MEMBER'
}

export enum AlertPriority {

  HIGH = 'HIGH', //
  MEDIUM = 'MEDIUM', //
  LOW = 'LOW'
}

export enum AlertTrigger {

  ONCE_OFF = 'ONCE_OFF', //
  QA_SCORE = 'QA_SCORE', //
  SURVEY_SCORE = 'SURVEY_SCORE'
}
