
import { Container } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';
// @ts-ignore
import moment from 'moment';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { TimeAgoValueConverter } from '../../../../../../converters/time-ago';
import { ArrayTools } from '../../../../../../zailab.common';
import { PresenceService } from '../../../../../user/passport/presence/presence-service';
import { ColourConfig } from '../../overview/colour-config';

const logger = LogManager.getLogger('ConfigMapper');

export class ConfigMapper {
  private timeAgoValueConverter: TimeAgoValueConverter = new TimeAgoValueConverter();
  private timers: any = {};
  public presences: { presenceCodeName: string; color: string; }[] = [];
  private presenceService: PresenceService = Container.instance.get(PresenceService);

  constructor() {
    this.retrievePresences();
  }

  private async retrievePresences(): Promise<void> {
    try {
      let presences: { presenceCodeName: string; color: string; }[] = await this.presenceService.retrievePresences();
      this.presences = ArrayTools.sort(presences, 'presenceCodeName');
    } catch(error) {
      logger.warn(`Could not retrieve presences >>`, error);
    }
  }

  public clearTimers(): void {
    const keys = Object.keys(this.timers);
    keys.forEach(key => {
      window.clearInterval(this.timers[key]);
    });
    this.timers = [];
  }
  
  // POPULATING DATA
  public getActivityStatus(c: any): string {
    if (c.currentActivity.name === 'Conversing') {
      return c.conversationStatusDescription;
    }
    return c.currentActivity.name;
  }

  public getActivityStatusColour(c: any): string {
    let activityStatus = this.getActivityStatus(c);
    if (c.conversationStatusDescription === 'Wrap Up' && c.currentActivity.name === 'Conversing') {
      return 'neutral-text';
    } else if (activityStatus === 'Not Responding') {
      return 'bad-text-light';
    } else if (c.currentActivity.name === 'Conversing') {
      return 'good-text-light';
    } else if (c.currentActivity.name === 'Waiting') {
    let timeDiff = moment().diff(moment(c.currentActivity.startTimestamp));
    return timeDiff > 2 * 60 * 1000 ? 'bad-text-light' : '';
    }
  }

  public durationPassedLimit(c: any): boolean {
    if (c.currentActivity.name === 'Conversing') {
      let timeDiff = moment().diff(moment(c.currentActivity.startTimestamp));
      return timeDiff > 60 * 60 * 1000;
    } else if (c.currentActivity.name === 'Not Responding') {
      return true;
    } else if (c.currentActivity.name === 'Waiting') {
      let timeDiff = moment().diff(moment(c.currentActivity.startTimestamp));
      return timeDiff > 2 * 60 * 1000;
     }
    return false;
  }
  
  public createTimer(c: any, key: string, ignoreValidation?: boolean): string {
    const id = uuidv4();
    const getTimerValue = value => {
      if (value) {
        if (value === '--') {
          return value;
        }
        value = this.timeAgoValueConverter.toView(value, new Date, ':');
        return value === '00:00:00' ? '--' : value;
      }
      return '--'
    }
    const calculateValue = () => getTimerValue(c[key]);
    let value = calculateValue();
    let rule;
    if (!ignoreValidation) {
      rule = this.setActivityRoutingStatusRules(value);
    }

    const incrementFunction = () => {
      value = calculateValue();

      if (value && value !== '--') {
        this.timers[id] && window.clearInterval(this.timers[id])
        this.timers[id] = null;
        this.timers[id] = setInterval(() => {
          let newValue = calculateValue();
          let element = document.querySelector(`#js-${id}`);
          if (element) {
            element.innerHTML = newValue;
            if (!ignoreValidation) {
              rule = this.setActivityRoutingStatusRules(value);
            }
            element.className = rule ? rule + ' square-component' : '';
          }
        }, 300);
      }
    };

    return `<div id="js-${id}" class="${rule ? rule + ' square-component' : ''}" onload="${incrementFunction()}">${value}</div>`
  }

  public formatToDayTime(value: string): string {
    if (value && value !== '--') {
      return moment(Date.parse(new Date(value).toUTCString())).utc().format('HH:mm:ss')
    }
    return '--';
  }

  private setActivityRoutingStatusRules(value: string): string {
    if (value && value !== '--') {
      const segments = value.split(':');
      const hr = parseInt(segments[0]);
      const min = parseInt(segments[1]);

      if (hr >= 1 || min >= 3) {
        return 'bad-light';
      } else if (min < 3 && min >= 1) {
        return 'neutral';
      } else if (hr === 0 && min === 0) {
        return '';
      }
    }
    return null;
  }
  
  public get time(): any {
    return {
      ago: (timeFromView: any) => {
        let currentTimeStamp = this.currentTimeStamp;
        let customTimeSeparator = ':';

        if(currentTimeStamp) {

          timeFromView = (timeFromView === -1) ? currentTimeStamp : timeFromView;

          let converted = moment.preciseDiff(timeFromView, currentTimeStamp, true);

          if(customTimeSeparator) {
            let customConverted = moment.preciseDiff(timeFromView, currentTimeStamp, true);
            let h = customConverted.hours ? `${customConverted.hours}${customTimeSeparator}` : '00:';
            let m = customConverted.minutes ? `${customConverted.minutes}${customTimeSeparator}` : '00:';
            let s = customConverted.seconds ? `${customConverted.seconds}` : '00';

            if(customConverted.hours) {
              h = this.convertToDoubleDigits(h);
            }
            if(customConverted.minutes) {
              m = this.convertToDoubleDigits(m);
            }
            if(customConverted.seconds) {
              s = this.convertToDoubleDigits(s);
            }

            return h + m + s;
          } else {
            let h = converted.hours ? `${converted.hours}h ` : '';
            let m = converted.minutes ? `${converted.minutes}m ` : '';
            let s = `${converted.seconds}s`;

            return h + m + s;
          }
        }

        return moment(timeFromView).fromNow();
      }
    };
  }

  get currentTimeStamp(): Date {
    return new Date;
  }

  private convertToDoubleDigits(x: string): string {
    let num = parseInt(x);

    if(num < 10) {
      x = `0${x}`;
    }
    return x;
  }

  public convertStringCasingToNameCase(value: string): string {
    let split = value.toLowerCase().split('_');
    for (var i = 0; i < split.length; i++) {
      split[i] = split[i].charAt(0).toUpperCase() + split[i].substring(1);
    }
    return (split.join(' ')).replace('_', ' ');
  }

  // COLOR RULES

  public getColourConfig(title: string, value: any): string {
    if (!value || value === '--') {
      return;
    }
    const colourScheme = ColourConfig.getColourScheme(ColourConfig.VIEWS.CONTACT_CENTER_MONITORING);

    if (colourScheme[title]) {
      const config = colourScheme[title].settings;
      for (let index = 0; index < config.length; index++) {
        let setting = config[index];
    
        if (value >= setting.value) {
            return 'background: ' + setting.color + '!important';
        }
    }
    } else {
      return '';
    }
  }
}
