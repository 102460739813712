import { autoinject, bindable, customElement, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ChannelModel } from './channel-model';

import { SessionStore } from 'zailab.common';
import { EmailChannelModel } from './email-channel-model';
import { CallChannelModel } from './call-channel-model';
import { SmsChannelModel } from './sms-channel-model';
import { NoteChannelModel } from './note-channel-model';
import { ChannelService } from './channel-service';
import { FlowsModel } from '../../contact/contactcontroller/flows-model';
import { ContactModel } from '../../contact/contact-model';
import { ConversationModel } from "../conversation-model";
import { CONVERSATION_ACTIONS, CONTROLLER_ACTIONS } from "../../contact/contactcontroller/contact-controller-actions";
import { ReminderChannelModel } from './reminder-channel-model';

const logger = LogManager.getLogger('Channel');

@customElement('z-channel')
@autoinject()
export class Channel {
  @bindable private contact: ContactModel;
  @bindable public flows: FlowsModel;
  @bindable private onCall: boolean;

  @bindable({ attribute: 'has-outcome' }) hasOutcome: boolean;
  @bindable({ attribute: 'on-wrapup' }) onWrapup: boolean;
  private channelPanelIsActive: boolean = false;
  private channels: ChannelModel[] = [];
  private channelPanelTitle: string = 'Make A Call';
  private channelView: string = null;
  private selectConversationSubscription: any;
  private forwardEmailSubscription: any;
  private sendEmailSubscription: any;
  private isCurrentlyOnLiveChat: boolean = false;

  public email: { message: string, subject: string, to: string, attachmentDetails:[], ccs: string[], bccs: string[], replyToAll: boolean };

  constructor(private channelService: ChannelService, private eventAggregator: EventAggregator, private sessionStore: SessionStore) { }

  public attached(): void {
    this.buildChannelOutline();
    this.selectCurrentChannel();
    this.subscribeToConversationChanged();
  }

  private subscribeToConversationChanged(): void {
    this.selectConversationSubscription = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.CONTACT_CONVERSATIONS_CHANGED, () => {
      this.selectCurrentChannel();
    });
    this.forwardEmailSubscription = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.INTERACTIONS_EMAIL_FORWARD, (email) => {
      this.email = email;
      this.selectChannel('email');
      this.reset();
      this.expand();
    });
    this.forwardEmailSubscription = this.eventAggregator.subscribe(CONVERSATION_ACTIONS.INTERACTIONS_EMAIL_REPLY_ALL, (email) => {
      email.replyToAll = true;
      this.email = email;
      this.selectChannel('call');
      // this timeout is to reset the channel state
      // Issue:
      // 1. When already in the email channel
      // 2. You select either reply to all or forward
      // 3. The email channel view does not get populated correctly
      setTimeout(() => {
        this.selectChannel('email');
      }, 50)
      this.reset();
      this.expand();
      this.expand();
    });
    this.sendEmailSubscription = this.eventAggregator.subscribe(CONTROLLER_ACTIONS.SEND_EMAIL, () => this.email = null);
  }

  private buildChannelOutline(): void {
    this.channels.push(new CallChannelModel());
    this.channels.push(new EmailChannelModel());
    this.channels.push(new SmsChannelModel());
  }

  private selectCurrentChannel(): void {
    if (this.hasOutcome) {
      this.selectChannel('call');
      return;
    }
    this.contact.openConversations.forEach((conversation: ConversationModel) => {
      if (conversation.isSelected) {
        this.selectChannelForConversation(conversation);
      }
    });
    this.contact.closedConversations.forEach((conversation: ConversationModel) => {
      if (conversation.isSelected) {
        this.selectChannelForConversation(conversation);
      }
    });
    if (!this.contact.contactId && this.contact.servedInteractions.length > 0) {
      this.selectChannel(this.contact.servedInteractions[0].channel);
    }
  }

  private selectChannelForConversation(conversation: ConversationModel): void {
    if (conversation.selectedChannelTab !== null) {
      this.selectChannel(conversation.selectedChannelTab);
    } else {
      this.selectChannel();
    }
  }

  private expand(): void {
    this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
  }

  private reset(): void {
    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
  }

  private collapse(): void {
    this.eventAggregator.publish('change.channel.size.collapse', this.contact.contactId);
  }

  private selectChannel(channelName: string = 'call'): void {
    let channel: ChannelModel = null;
    switch (channelName.toLowerCase()) {
      case 'sms':
        channel = new SmsChannelModel();
        break;
      case 'email':
        channel = new EmailChannelModel();
        break;
        case 'note':
          channel = new NoteChannelModel();
          break;
        case 'reminder':
          channel = new ReminderChannelModel();
          break;
      default:
        channel = new CallChannelModel();
        if (this.contact.channelViewStrategy.size !== 'small') {
          this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
        }
    }
    
    if (channel.name === 'email' && this.contact.channelViewStrategy.channel === 'email') {
      return;
    }

    this.channels.forEach(eachChannel => {
      eachChannel.deselect();
    });

    channel.select();
    this.eventAggregator.publish('change.channel.view', { contactId: this.contact.contactId, channel: channel.name });
    this.channelView = channel.name;
    this.channelPanelIsActive = true;
    if (channel.name === 'email') {
      this.channelPanelTitle = 'Compose Email';
      return;
    }
    if (channel.name === 'sms') {
      this.channelPanelTitle = 'Compose SMS';
      return;
    }
    if (channel.name === 'note') {
      this.channelPanelTitle = 'Compose Note';
      return;
    }
    if (channel.name === 'reminder') {
      this.channelPanelTitle = 'Create a Reminder';
      return;
    }
    this.channelPanelTitle = 'Make A Call';
  }

  @computedFrom('channels', 'contact.contactId', 'contact.selectedConversation')
  private get enabledChannels(): Array<ChannelModel> {
    let defaultChannels = [];
    this.channels.forEach(channel => defaultChannels.push(channel));
    if (this.contact.contactId && this.contact.selectedConversation) {
      defaultChannels.push(new NoteChannelModel());
      defaultChannels.push(new ReminderChannelModel());
    }
    return defaultChannels;
  }

  public detached(): void {
    this.eventAggregator.publish('change.channel.size.small');
    this.channels = [];
    this.channelPanelIsActive = false;
    this.selectConversationSubscription && this.selectConversationSubscription.dispose();
    this.forwardEmailSubscription && this.forwardEmailSubscription.dispose();
    this.sendEmailSubscription && this.sendEmailSubscription.dispose();
  }

}