import {LogManager, autoinject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

import { APIMonitoringService } from './monitoring-service';

// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('APIMonitoring');

@autoinject()
export class APIMonitoring {

  private stateStorageKey = 'zai_state';
  private defaultRequestTimer = 30;
  private currentRequestTimer;
  public timerDropdownVisible = false;
  public gridDataSource: any;
  
  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
    { value: 30 },
    { value: 35 },
    { value: 40 },
    { value: 45 },
    { value: 50 }
  ];
  private lastRequestTimestamp: number;
  private signallerInterval: number;

  public monitoringData: any = [];

  constructor(
    private bindingSignaler: BindingSignaler,
    private APIMonitoringService: APIMonitoringService
  ) {
    this.getStoredTimerCount();
  }

  private getStoredTimerCount(): void {
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        const parsedState = JSON.parse(state);
        this.currentRequestTimer = parsedState.requestTimer || this.defaultRequestTimer;
      } catch(e) {}
    } else {
      this.currentRequestTimer = this.defaultRequestTimer;
    }
  }

  public attached(): void {
    this.getAPIData();
  }

  public async getAPIData(): Promise<void> {
    
    this.APIMonitoringService.getAPILookup()
      .then(response => {
        if (response) {
          this.monitoringData = response;
        }
      });

    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.setupSignaller();
  }

  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: number): void {
    this.currentRequestTimer = value;
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        parsedState.requestTimer = value;
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ requestTimer: value }));
    }
    this.timerDropdownVisible = false;
  }

  public refresh(): void {
    this.getAPIData();
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        this.getAPIData();
      }
    }, signal);
  }

  public headerTemplate(serviceName: string): string {
    return `<span title="${serviceName}">${serviceName}</span>`;
  }

  public detached(): void {
    clearInterval(this.signallerInterval);
  }
}