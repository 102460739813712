/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPresence');
/*
 */
@customElement('z-presence')
export class ZPresence {

  @bindable presence;
  @bindable color;
  @bindable padding;

}
