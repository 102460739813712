import { autoinject, bindable, customElement } from "aurelia-framework";
import { Event } from "zailab.common";

@customElement('z-interaction-card-header')
@autoinject
export class InteractionCardHeader {

  @bindable
  public interaction: any;

  @bindable
  public contact: any;

  @bindable
  public unlinked: any;

  @bindable
  public conversations: any;

  constructor(
    private element: Element
  ) { }

  public colorChanged(data: { timeInSeconds: number, color: string }): void {

    new Event(this.element, 'interaction-color-change', {
      contactId: this.contact.contactId,
      correlationId: this.contact.correlationId || this.interaction.correlationId,
      color: data.color,
      timeInSeconds: data.timeInSeconds,
      interactionId: this.interaction.interactionId
    });
  }
}