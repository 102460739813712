import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';
/**/
import { SearchTools } from 'zailab.common';
import { CallbackService } from './callback-service';
import { WorkTypeModel } from './models/work-type-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { EmptyStateAbstract } from '../abstracts/empty-state-abstract';
import { computedFrom } from 'aurelia-binding';
import {bindable} from "aurelia-templating";
/**/
const logger = LogManager.getLogger('Callback Dialog');

/**/
@autoinject
export class CallbackDialog extends EmptyStateAbstract {

  private nodeData: NodeModel;
  private searchCriteria: string;
  private workTypes: Array<WorkTypeModel> = [];
  private selectedWorkType: WorkTypeModel = null;
  private isSearching: boolean;
  private searchTerm: string;

  @bindable
  notRespondingEnabled : boolean = true;

  protected emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-work-type.svg',
    heading: `No work type to display.`,
    description: `You haven't created any outbound work types yet or all your outbound work types are already assigned to a flow.`,
    hasButton: false,
    hasTip: true,
    tipText: `To make a work type eligible for use in a callback node, assign that work type to an outbound flow.`,
    hasExternalContentCreator: false
  };

  constructor(public dialogController: DialogController, private callbackService: CallbackService, protected dialogService: DialogService) {
    super(dialogService);
  }

  // Lifecycle methods

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.init();
  }

  private init(): void {
    this.notRespondingEnabled = ((typeof(this.nodeData.properties["notRespondingEnabled"]) === 'undefined') || (this.nodeData.properties["notRespondingEnabled"] === null)) ? true : this.nodeData.properties["notRespondingEnabled"];
  }

  public attached(): void {
    this.retrieveWorkTypes();
  }

  // Base methods

  private retrieveWorkTypes(): boolean {
    this.callbackService.retrieveWorkTypes(this.searchCriteria)
      .then((workTypes: WorkTypeModel[]) => this.workTypesRetrieved(workTypes))
      .catch((error) => this.reportError(error));
    return true;
  }

  private workTypesRetrieved(workTypes: WorkTypeModel[]): void {
    this.hasEntries = !!workTypes.length;
    this.nodeDialogInitialised = true;
    this.workTypes = workTypes;
    this.setSelectedWorkType();
  }

  private setSelectedWorkType(): void {

    let _selectedWorkType: WorkTypeModel;
    let workTypeFound: boolean;

    // Find the worktype that was assigned, if any

    if (this.nodeData.properties.taskTemplates && this.nodeData.properties.taskTemplates.length > 0) {
      _selectedWorkType = this.nodeData.properties.taskTemplates[0];
    }

    // Check if that worktype exists in the list

    this.workTypes.forEach(_workType => {
      if (_selectedWorkType && _selectedWorkType.id === _workType.id) {
        _workType.isSelected = true;
        this.selectedWorkType = _workType;
        workTypeFound = true;
      }
    });

    // Because the backend doesn't return the currently selected node, we have to insert the selected node manually

    if (_selectedWorkType && !workTypeFound) {
      this.selectedWorkType = _selectedWorkType;
      this.workTypes.unshift(this.selectedWorkType);
    }
  }


  // Called from view

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  private toggleNotRespondingEnabled() : void {
    this.notRespondingEnabled = !this.notRespondingEnabled;
  }


  private selectWorkType(selectedWorkType: WorkTypeModel): void {

    if (selectedWorkType.isPlaceholder) {
      return;
    }

    this.workTypes.forEach(workType => {
      workType.isSelected = false;
    });

    selectedWorkType.isSelected = true;
    this.selectedWorkType = selectedWorkType;
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.nodeData.properties.isDefined = true;
    this.nodeData.properties.name = this.selectedWorkType.name;
    this.nodeData.properties.businessValue = this.selectedWorkType.businessValue.toString();
    this.nodeData.properties.roles = this.selectedWorkType.roles;
    this.nodeData.properties.services = [this.selectedWorkType.service];
    this.nodeData.properties.sites = this.selectedWorkType.sites;
    this.nodeData.properties.skillGroups = this.selectedWorkType.skillGroups;
    this.nodeData.properties.taskTemplates = [this.selectedWorkType];
    this.nodeData.properties.notRespondingEnabled = this.notRespondingEnabled;
    this.nodeData.properties.sla = '';
    this.nodeData.properties.taskDataSLAs = [
      {
        waitTime: this.selectedWorkType.waitTime.toString(),
        answeredCalls: this.selectedWorkType.targetResponse.toString()
      }
    ];
    this.nodeData.properties.businessPartnerId = this.selectedWorkType.businessPartner === null ? null : this.selectedWorkType.businessPartner.businessPartnerId;
    this.nodeData.properties.contactCenterId = this.selectedWorkType.contactCentre === null ? null : this.selectedWorkType.contactCentre.contactCentreId;

    this.dialogController.ok(this.nodeData);
  }

  public doNothing(): void {}

  // Utilities

  private reportError(msg: any): void {

    logger.warn('Error: ', msg);
    this.workTypesRetrieved([]);
  }

  public startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  // Getters
  @computedFrom('hasEntries', 'selectedWorkType')
  get isValid(): boolean {
    return (this.hasEntries && this.selectedWorkType !== null)
  }

}
