import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('EmailSignatureModel');

export class PersonalVoicemailModel {

  constructor(
    private enabled: boolean,
    public audioId: string
  ) { }

  get status(): string {
    return !this.enabled ? 'Disabled' : 'Enabled';
  }
}
