import {inject, customElement, bindable, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 */
let logger = LogManager.getLogger('Wizard');
/*
 */
@customElement('z-wizard')
@inject(EventAggregator, Element)
export class Wizard {

  @bindable steps;
  @bindable processing;
  @bindable({attribute: 'column-width'}) columnwidth;
  currentStep;
  subscriptions = {
    wizardNextStep: null,
    validation: null,
    stepComplete: null,
    stepIncomplete: null,
    reset: null
  };
  /**
   * used to setup composed view as a wizard step
   * contains the wizard step data
   * @type object {{beforeNextStep: ((p1?:*)), step: {complete: ((p1?:*))}, data: {}}}
   */
  wizard = {
    beforeNextStep: (callback) => {
      if (this.subscriptions.wizardNextStep) {
        this.subscriptions.wizardNextStep.dispose();
      }
      this.subscriptions.wizardNextStep = this.eventAggregator.subscribe('wizard.next.step', callback)
    },
    step: {
      complete: (data) => {
        this.eventAggregator.publish('wizard.step.complete', data);
      },
      incomplete: (data) => {
        this.eventAggregator.publish('wizard.step.incomplete', data);
      }
    },
    resetCompletionStates: (currentStep) => {
      this.eventAggregator.publish('wizard.steps.completion.reset', currentStep);
    },
    continue: (data) => {
      this.eventAggregator.publish('wizard.step.validation.true', data);
    },
    data: {}
  };

  constructor(eventAggregator, element) {
    this.eventAggregator = eventAggregator;
    this.element = element;
  }

  bind() {
    this._initSteps();
  }

  unbind() {
    for(let property in  this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(property)) {
        this.subscriptions[property].dispose();
      }
    }
  }

  get colWidth() {
    return this.columnwidth || 2;
  }

  /**
   * subscribes to validation events in wizard
   * @returns {boolean}
   * @private
   */
  _initSteps() {

    this.currentStep = this.steps[0];
    this.wizard.data = this.steps[0].data || {};
    this.steps[this.steps.length - 1].lastStep = true;

    if (this.subscriptions.validation) {
      this.subscriptions.validation.dispose();
    }
    this.subscriptions.validation = this.eventAggregator.subscribe('wizard.step.validation.true', (data) => {
      this._nextStep();
      if (data) {
        this._setData(data);
      }
    });
    if (this.subscriptions.stepComplete) {
      this.subscriptions.stepComplete.dispose();
    }
    this.subscriptions.stepComplete = this.eventAggregator.subscribe('wizard.step.complete', (data) => {
      this._setData(data);
      this._setComplete();
    });
    if (this.subscriptions.stepIncomplete) {
      this.subscriptions.stepIncomplete.dispose();
    }
    this.subscriptions.stepIncomplete = this.eventAggregator.subscribe('wizard.step.incomplete', (data) => {
      this._setData(data);
      this._setIncomplete();
    });
    if (this.subscriptions.reset) {
      this.subscriptions.reset.dispose();
    }
    this.subscriptions.reset = this.eventAggregator.subscribe('wizard.steps.completion.reset', (currentStep) => {
      this._resetCompletionStates(currentStep);
    });
  }
  /**
   * save all saved step data
   * @param data
   * @private
   */
  _setData(data) {
    if(!data) {
      return;
    }
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        this.wizard.data[key] = data[key];
      }
    }
  }
  /**
   * save all saved step data
   * @private
   */
  _setComplete() {
    for (let step of this.steps) {
      if(step.active) {
        step.completed = true;
      }
    }
  }
  /**
   * save all saved step data
   * @private
   */
  _setIncomplete() {
    for (let step of this.steps) {
      if(step.active) {
        step.completed = false;
      }
    }
  }
  /**
   * published next.step to compose vm
   */
  next(finish) {
    this.finish = finish;
    this.eventAggregator.publish('wizard.next.step');
  }
  /**
   * handles compose vm event to continue to next step after successful validation
   * @private
   * this gets called when the cross at the top right is selected.
   * @fires complete CustomEvent triggers a complete dom event on the wizard
   */
  _nextStep() {
    if(this.finish) {
      this._finish();
      return;
    }
    for (let step of this.steps) {
      if (step.active) {
        this._findCompleted();
        return;
      }
    }
  }
  /**
   * find the last completed step
   * @private
   */
  _findCompleted() {
    let lastCompletedStepIndex;
    for (let i = 0; i < this.steps.length; i++) {
      let step = this.steps[i];
      let prevStep = this.steps[i - 1];
      if (step.active && (!prevStep || (prevStep && prevStep.completed))) {
        this._activateStep(i);
        return;
      }
      if (step.completed) {
        lastCompletedStepIndex = i;
      }
    }
    this._activateStep(lastCompletedStepIndex ? lastCompletedStepIndex : 0);
  }
  /**
   * set the next active step
   * @param i
   * @private
   */
  _activateStep(i) {
    if (!this.steps[i]) return;
    this.steps[i].completed = true;
    this.steps[i].active = false;
    this.steps[i + 1].active = true;
    this.currentStep= this.steps[i + 1];
  }
  /**
   * goes back to previous wizard step
   */
  previous() {
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].active) {
        this.steps[i].active = false;
        this.steps[i - 1].active = true;
        this.currentStep= this.steps[i - 1];
        return;
      }
    }
  }
  /**
   * completes the wizard
   * fires CustomEvent complete
   */
  cancel() {

    this.element.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        detail: {}
      })
    );
  }
  /**
   * @param currentStep {number}
   * @private
   */
  _resetCompletionStates(currentStep) {

    for (let index in this.steps) {
      if(index > currentStep) {
        this.steps[index].completed = false;
        continue;
      }
      this.steps[index].completed = true;
    }
  }
  /**
   * completes the wizard
   * fires CustomEvent complete
   */
  _finish() {

    this.element.dispatchEvent(
      new CustomEvent('complete', {
        bubbles: true,
        detail: this.wizard.data
      })
    );
  }
  /**
   * show/hide the wizard`s finish button
   * @returns {boolean}
   */
  get canComplete() {
    if (this.steps[this.steps.length - 1].active) {
      return true;
    }
    for (let step of this.steps) {
      if (!step.completed) {
        return false;
      }
    }
    return true;
  }
}
