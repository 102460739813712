import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenterService } from '../../contactcenter/contact-center-service';
import { TransferList, TransferRecipient } from '../transfer-list-model';
import { TransferListService } from '../transfer-list-service';

const logger = LogManager.getLogger('TransferListEdit');

@autoinject()
export class TransferListEdit {

  public transferList: TransferList;
  public transferRecipient: TransferRecipient = new TransferRecipient();
  public contactNumber: TelephoneNumberModel = new TelephoneNumberModel('', '', '', true);
  public businessPartner: string;
  public contactCenter: string;
  public ready: boolean = false;

  private validation: ValidationController;

  constructor(
    private events: EventAggregator,
    private transferListService: TransferListService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private router: Router,
    validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public activate(params: { id: string }): Promise<any> {
    this.initValidationRules();
    return this.transferListService.findById(params.id)
      .then((transferList: TransferList) => {
        this.transferList = transferList;
        if (transferList.businessPartner) {
          this.businessPartnerService.findById(transferList.businessPartner)
            .then((businessPartner) => this.businessPartner = businessPartner.name);
        }
        if (transferList.contactCenter) {
          this.contactCenterService.findById(transferList.contactCenter)
            .then((contactCenter) => this.contactCenter = contactCenter.name);
        }
        this.ready = true;
      });
  }

  public cancel(): void {
    this.transferRecipient = new TransferRecipient();
    this.contactNumber = new TelephoneNumberModel('', '', '', true);
  }

  public save(): void {
    this.events.publish('PHONE.NUMBER.VALIDATE');
    this.validation.validate().then((results) => {
      if (results.valid && this.contactNumber.isNumberValid) {
        this.persist();
      }
    });
  }

  public remove(transferRecipient: TransferRecipient): void {
    this.ready = false;
    this.transferListService.removeTransferRecipient(this.transferList.id, transferRecipient)
      .then(() => this.handleRemoveSuccess(transferRecipient))
      .catch((error: Error) => this.handleError(error));
  }

  public get heading(): string {
    let heading = 'Contacts';
    if (this.businessPartner) {
      heading += ` - Business Partner: ${this.businessPartner}`;
    }
    if (this.contactCenter) {
      heading += ` - Contact Center: ${this.contactCenter}`;
    }
    return heading;
  }

  private persist(): void {

    this.transferRecipient.contactNumber = this.contactNumber.number;

    this.ready = false;
    this.transferListService.addTransferRecipient(this.transferList.id, this.transferRecipient)
      .then(() => this.handleSaveSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  private handleRemoveSuccess(transferRecipient: TransferRecipient): void {
    this.ready = true;
    const index = this.transferList.transferRecipients.indexOf(transferRecipient);
    this.transferList.transferRecipients.splice(index, 1);
    this.smoothScrollToBottom();
  }

  private handleSaveSuccess(): void {
    this.ready = true;
    this.transferList.transferRecipients.push(this.transferRecipient);
    this.cancel();
    this.smoothScrollToBottom();
  }

  public navigateBack(): void {
    this.router.navigate('list');
  }

  public navigateTeams(): void {
    this.router.navigate(`team/${this.transferList.id}`);
  }

  public navigateMembers(): void {
    this.router.navigate(`member/${this.transferList.id}`);
  }

  private handleError(error: Error): void {
    this.ready = true;
    logger.error('save :: error=', error)
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((transferRecipient: TransferRecipient) => transferRecipient.name).required()
      .ensure((transferRecipient: TransferRecipient) => transferRecipient.surname).required()
      .on(this.transferRecipient);
  }
}