import {inject, customElement, LogManager, computedFrom} from 'aurelia-framework';

import {Event, SessionStore, SESSION_EVENTS, ArrayTools} from 'zailab.common';
import UserRegistrationService from '../../../../features/user/registration/user-registration-service';
import OrganisationService from '../../../../features/organisation/organisation/organisation-service';
import {OrganisationModel} from './organisation-model';
import {UserModel} from './user-model';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
import {BootstrapFormRenderer} from 'zailab.common';
import {EventAggregator} from 'aurelia-event-aggregator';
import {UserSessionModel} from './../../../../_common/stores/sessionmodels/user-session-model';
import {ApplicationProperties} from '../../../../_config/application.properties';
import {FeatureFlagService} from '../../../../features/featureflags/feature-flag-service';

const logger = LogManager.getLogger('Welcome');

@inject(Element, SessionStore, OrganisationService, UserRegistrationService, ValidationControllerFactory, EventAggregator, ApplicationProperties, FeatureFlagService)
@customElement('z-welcome')
export class UserCreateorganisation {
  private organisationName: string = 'your new organisation';
  private userFirstName: string = '';
  private selectedCountry: any;
  private selectedTimezone: any;
  private submitting: boolean = false;
  private error: string;
  private agentEmail: string = '';
  private validation: any;
  private europeanCountries: string[] = ["at", "be", "ee", "fi", "fr", "de", "gr", "ie", "it", "lv", "lt", "lu", "mt", "nl", "pt", "sk", "si", "es"];
  private preferredCountries = [];
  public isAllowed: boolean = false;

  constructor(private element: Element, private sessionStore: SessionStore, private organisationService: OrganisationService, private userRegistrationService: UserRegistrationService, private validationControllerFactory: ValidationControllerFactory, private eventAggregator: EventAggregator, private applicationProperties: ApplicationProperties, private featureFlagService: FeatureFlagService) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  private async activate(): Promise<void> {
    ValidationRules.ensure('agentEmail')
      .required()
      .when(() => this.agentEmail !== '')
      .email()
      .withMessage('Please enter a valid email.')
      .on(this);

    this.userFirstName = this.user.firstName;
    this.organisationService.getOrgNameByEmail(this.user.email)
      .then(_orgDetails => {
        this.organisationName = _orgDetails.organisationName;
        this.isAllowed = true;
      })
      .catch((error) => {
        this.isAllowed = false;
      });

    await this.userRegistrationService.retrieveConfiguredCountries().then(countries =>{
      this.preferredCountries = countries;
      }
    );
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  private submitRegistration(): void {

    if (this.submitting) {
      return;
    }
    this.submitting = true;

    this.validation.validate().then(validation => {
      if (!validation.valid) {
        this.submitting = false;
        return;
      }

      let loggedInUserInformation = this.user;
      let creatorInformation = new UserModel(loggedInUserInformation);
      let organisationInformation = new OrganisationModel(loggedInUserInformation);

      organisationInformation.creator = creatorInformation;
      organisationInformation.organisationName = this.organisationName;
      organisationInformation.initialAgentEmail = this.agentEmail;
      organisationInformation.timezone = this.selectedTimezone.name;
      organisationInformation.country = {name: this.selectedCountry.name, code: this.selectedCountry.id};

      this.userRegistrationService.registerOrganisation(organisationInformation).then(
        _organisation => {
          this.eventAggregator.publish(SESSION_EVENTS.ORGANISATION.EVENT, _organisation.state);
          this.eventAggregator.publish('created.organisation');
        },
        error => {
          this.error = 'An error occurred. Unable to save your location.';
          this.submitting = false;
        }
      );
    });
  }
}
