import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { InvitationsService } from '../invitations-service';
import { SessionStore } from 'zailab.common';
import { FeatureFlagService } from '../../../../featureflags/feature-flag-service';

const logger = LogManager.getLogger('Invitations Widget');

@inject(Router, InvitationsService, SessionStore, FeatureFlagService)
export class InvitationsDashboardWidget {

  actions = ['view', 'add'];
  count;
  pricingModel;
  invitations = [];
  pendingInvitations = [];
  ready = false;
  ldapSyncEnabled = false;

  constructor(router, invitationsService, sessionStore, featureFlagService) {
    this.router = router;
    this.invitationsService = invitationsService;
    this.sessionStore = sessionStore;
    this.featureFlagService = featureFlagService;
  }

  activate() {

  }

  attached() {

    this.featureFlagService.isEnabled('fixed-pricing').then((enabled) => {
      if (!enabled) {

        this.ready = false;
        this.retrievePendingInvitationsStats();
        this.invitationsService.initialiseStatsOplog().then((oplog) => {
          this.oplog = oplog;
          this.oplog.on('insert', (response) => {
            if (response) {
              this.retrievePendingInvitationsStats();
            }
          });
          this.oplog.on('update', (response) => {
            if (response) {
              this.retrievePendingInvitationsStats();
            }
          });
        });
      }
    });
  }

  detached() {
    this.oplog.unsubscribe();
  }

  retrievePendingInvitationsStats() {

    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.retrievePendingInvitationsStats();
      }, 500);
      return;
    }

    this.invitationsService.retrieveOrganisationInfo().then(response => {
      
      if (response && response.ldapConnectorInformation) {
        this.ldapSyncEnabled = response.ldapConnectorInformation.enabled;
      }

      this.invitationsService.retrievePendingInvitationsStats().then((response) => {
        this.count = response.count;
        let _list = [];

        for (let i = 0; i < 3; i++) {
          if (response && response.invitations && response.invitations.length > 0) {
            _list.push(response.invitations[i]);
          } else {
            _list.push(null);
          }
        }
        this.pendingInvitations = _list;
        this.ready = true;
        this.pricingModel = response.pricingModel;

        if (this.pricingModel === 'FIXED') {
          this.actions = [];
        } else {
          this.actions = ['view', 'add'];
        }

      }, (error) => {
        this._initialiseEmptyWidget();
      });
    });
  }

  _initialiseEmptyWidget() {
    let _list = [];
    this.count = 0;
    for (let i = 0; i < 3; i++) {
      _list.push(null);
    }
    this.pendingInvitations = _list;
  }

  view() {
    if (!this.ldapSyncEnabled) {
      this.router.navigate('invitations');
    }
  }

  add() {
    if (!this.ldapSyncEnabled) {
      this.router.navigate('invitations/invite');
    }
  }

  unbind() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  doNothing() {}
}