import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('TeamModel');
/**/
export class TeamModel extends BaseModel {

    public teamId: string = null;
    public teamName: string = null;

    constructor(agent: object) {

        super();
        super.mapProperties(agent);
    }
}