import { autoinject, bindable, observable, customElement, LogManager } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { InteractionModel } from '../interaction-model';
import { INTERACTION_ACTIONS } from '../../contact/contactcontroller/contact-controller-actions';
import { TicketModel } from '../interaction/ticket-model';
import { BaseModel } from '../../../../abstract/base-model';
import { ConversationModel } from '../conversation-model';

const logger = LogManager.getLogger('InteractionTimeline');

@customElement('z-interaction-timeline')
@autoinject()
export class InteractionTimeline {
  @bindable private conversation: ConversationModel;
  @bindable @observable public interactions: (InteractionModel | TicketModel) [];

  private isAttached: boolean = false;

  public height: string = '100%';

  constructor(private eventAggregator: EventAggregator) {}

  public attached(): void {
    this.isAttached = true;
  }

  public interactionsChanged(newValue: any): void {
    if (newValue && newValue.length > 0) {
      const contactCardBodyElement: any = document.querySelector('.c-contact-card-body');
      this.height = `${contactCardBodyElement.offsetHeight - 30}px`;
    }
  }

  public selectInteraction(interaction: InteractionModel): void {
    this.eventAggregator.publish(INTERACTION_ACTIONS.SELECT, { interaction: JSON.parse(JSON.stringify(interaction)) });
  }

  public jumpToInteraction(interactionId?: string): void {
    if (interactionId) {
      const element = document.getElementById('js-interaction-timeline-' + interactionId);
      
      if (element && this.isAttached) {
        element.parentElement.parentElement.scrollTop = element.offsetTop;
      } else {
        setTimeout(() => {
          this.jumpToInteraction(interactionId);
        }, 200)
      }
    }
  }
}