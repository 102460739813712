import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
/**/
import { BootstrapFormRenderer } from 'zailab.common';
import { Validation } from 'zailab.validation';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
/**/
const logger = new LogManager.getLogger('ApiCallDialog');
/**/
@autoinject
export class ApiCallDialog {

  public name: string = '';
  public type: string;
  private validation: any;

  public isValid: boolean = false;
  public isAddingContent: boolean = false;

  private nodeData: NodeModel;
  private nodeConfiguration: ZNodeConfig;

  constructor(
    private dialogController: DialogController,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition));
    this.name = this.nodeData.properties.name || '';
    this.type = this.nodeData.name || '';
    this.validate();
  }

  public attached(): void {
    this.initialiseValidation();
  }

  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public validate(): void {
    if (this.name.length === 0) {
      this.isValid = false;
      return;
    }
    this.isValid = true;
  }

  public ok(): void {
    this.name = this.name.trim();
    if (!this.name) {
      return;
    }
    let properties: any = { name: this.name};
    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    this.dialogController.ok(updatedNode);
  }

  private mapNodeProperties(properties: any): NodeModel {
    this.nodeData.properties.name = properties.name;

    this.nodeData.properties.isDefined = this.isDefined(properties);
    return new NodeModel(this.nodeData);
  }

  private isDefined(properties: any): boolean {
    if (!properties.name) {
      return false;
    }
    return true;
  }
}
