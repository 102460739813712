import { LogManager, inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';

import { MESSAGE_EVENTS, SessionStore, OplogService } from 'zailab.common';
import { BuyCreditModel } from './buycredit-model';

const logger = LogManager.getLogger('BuyCreditService');

@inject(HttpClient, EventAggregator, SessionStore, OplogService)
export class BuyCreditService {

  constructor(httpClient, eventAggregator, sessionStore, oplog) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.oplog = oplog;
  }

  subscribe(accountId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', accountId).in('account-projector.accountCardInformationView'));
    });
  }

  retrieveCards() {
    return this.httpClient
      .createRequest('v1/finance/accounts/me/cards')
      .asGet()
      .send()
      .then((response) => new BuyCreditModel(response));
  }

  _createRequest(uri, callback, errorCallback) {
    this.httpClient
      .createRequest(uri)
      .asGet()
      .send()
      .then(
        response => {
          callback(response);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  removeCard(token) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`payments/cards/${encodeURIComponent(token)}`)
        .asDelete()
        .send()
        .then(success => {
          resolve(token);
        }, error => {
          reject(error);
        });
    });
  }

  addCard(payload) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('payments/cards')
        .asPost()
        .withContent(payload)
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  securePayment(payload) {

    return new Promise((resolve, reject) => {

      this.httpClient
        .createRequest('payments/charges')
        .asPost()
        .withContent(payload)
        .send().then(success => resolve(success), failure => {

          let errMsg = 'Payment unsuccessful. Please try again.';
          let parsedResponse = typeof failure.response === 'string' ? JSON.parse(failure.response) : failure.response; // JE 2018/03/06 - this needs to happen in the HttpInterceptor

          if (parsedResponse.message) {
            errMsg = parsedResponse.message;
          }

          this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, errMsg);

          reject(failure);
        });

    });

  }

  subscribeToPaymentStatus(paymentId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', paymentId).in('account-projector.displayPaymentView'));
    });
  }

  retrievePersonalInfo() {
    return new Promise((resolve, reject) => {
      let personId = this.sessionStore.get.user.personId;
      this.httpClient.createRequest(`/v1/user/persons/profile-information/${personId}`)
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(response.person);
          },
          (error) => {
            reject(error);
          }
        ).catch((error) => {
          reject(error);
        }
        );
    });
  }
}
