import { ContactService } from './../contact-service';
import { CONTACT_ACTIONS } from './../../conversation/interaction/interaction-actions';
import { OplogManager } from './oplog-manager';
import { ConversationService } from './../../conversation/conversation-service';
import { ContactCardTools } from './contact-card-tools';
import { TicketModel } from './../../conversation/interaction/ticket-model';
import { LogManager, Container } from 'aurelia-framework';
import { ContactModel } from './../contact-model';
import { ContactState } from './contact-state';
import { InteractionModel } from './../../conversation/interaction-model';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from "aurelia-dependency-injection";
import { computedFrom } from 'aurelia-binding';
// @ts-ignore
import moment from 'moment';

//  known contact
//  unknown contact

//  - is served
//  - not served

//  - - new card
//  - - existing card

//  - - - known conversation
//  - - - unknown conversation

const logger = LogManager.getLogger('ContactCardFlow');

@autoinject
export class ContactCardFlowService {

  private contactCardTools: ContactCardTools = Container.instance.get(ContactCardTools);
  private oplogManager: OplogManager = Container.instance.get(OplogManager);
  private eventAggregator: EventAggregator = Container.instance.get(EventAggregator);
  private contactService: ContactService = Container.instance.get(ContactService);
  private contactState: ContactState;

  public setContactState(contactState: ContactState): ContactCardFlowService {
    this.contactState = contactState;
    return this;
  }

  public async startContactCardFlow(interaction: InteractionModel | TicketModel): Promise<void> {
    if (!this.contactState) {
      return logger.error('We have detected that `this.contactState` is not declared.');
    }
    logger.debug(this.getCurrentFormattedTime() + ' - CC | startContactCardFlow ', interaction);
    try {
      let data = await this.findContactCardToUse(interaction);
      await this.checkIfInteractionAlreadyHandled(data.contactCard, interaction);
      this.selectInteraction(data.contactCard, interaction);
      this.initialiseOplogs(interaction);
      interaction.contactId || interaction.channel === 'callback' ?
        this.handleInteractionForKnownContact(data.contactCard, interaction) :
        this.populateUnknownContact(data.contactCard, interaction)
    } catch(error: any) {
      logger.error('Failed to start contact card flow to use due to', error);
    }
  }

  private async findContactCardToUse(interaction: any): Promise<{ contactCard: ContactModel, oplogsAlreadyInitialised: boolean }> {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | findContactCardToUse ');
    let contactCard = this.contactCardTools.findOrCreateContactCard(interaction);

    const isExistingContactCard = await this.isExistingContactCard(interaction);
    logger.debug(this.getCurrentFormattedTime() + ' - CC | check if existing contactCard ', isExistingContactCard);
    if (!isExistingContactCard) {
      logger.debug(this.getCurrentFormattedTime() + ' - CC | non existing contactCard ');
      this.contactState.contactTabs.push(contactCard);
    } else {
      logger.debug(this.getCurrentFormattedTime() + ' - CC | is existing contactCard ');
      contactCard = isExistingContactCard;
    }
    this.contactState.selectContactCard(contactCard);
    this.contactState.checkTabsLength();
    return {
      contactCard,
      oplogsAlreadyInitialised: isExistingContactCard && isExistingContactCard.contactId ? true : false
    };
  }

  private selectInteraction(contactCard: ContactModel, interaction: any): void {
    contactCard.addServedInteraction(interaction);
  }

  private async initialiseOplogs(interaction: any): Promise<void> {
    this.oplogManager.subscribeToInteractionChanges(interaction.interactionId);

    if (interaction.contactId) {
      this.oplogManager.subscribeToContactInfoChanges(interaction.contactId);
      this.oplogManager.subscribeToUnlinkedInteractionChanges(interaction.contactId);
      this.oplogManager.subscribeToConversationListChanges(interaction.contactId);
    }
    if (interaction.conversationId) {
      this.oplogManager.subscribeToConversationChanges(interaction.conversationId);
    }
  }

  private async checkIfInteractionAlreadyHandled(contactCard: ContactModel, interaction: any): Promise<void> {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | checkIfInteractionAlreadyHandled ');
    if (contactCard.getServedInteractionWithId(interaction.interactionId)) {
      logger.debug('Interaction has already been handled. Ignoring interaction with ID ' + interaction.interactionId);
    }
  }

  // Known Contact methods //
  private handleInteractionForKnownContact(contactCard: ContactModel, interaction: any): void {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | handleInteractionForKnownContact ');
    try {
      contactCard.contactId = interaction.contactId;
      interaction.isServed || interaction.resumeLinking ?
        this.linkInteraction(contactCard, interaction) :
        this.populateKnownContact(contactCard, false)
    } catch(error: any) {
      throw new Error(error);
    }
  }

  private async linkInteraction(contactCard: ContactModel, interaction: InteractionModel): Promise<void> {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | linkInteraction -> ' + interaction.channel, {
      contactId: contactCard.contactId || interaction.contactId,
      conversationId: interaction.conversationId
    });
    try {
      if (interaction.channel === 'callback') {
        await this.handleCallbackLinking(contactCard, interaction);
      } else if (interaction.channel === 'chat') {
        await this.handleChatLinking(contactCard, interaction);
      } else {
        await this.linkInteractionToContact(interaction);
      }
      if (interaction.conversationId) {
        await this.linkToConversation(interaction, contactCard);
      }
      if (interaction.channel !== 'callback' && interaction.channel !== 'chat') {
        this.populateKnownContact(contactCard, true);
      }

    } catch(error: any) {
      throw new Error(error);
    }
  }

  private async populateKnownContact(contactCard: ContactModel, enforeLinking: boolean): Promise<void> {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | populateKnownContact ');
    contactCard.populateContactCard(true, !enforeLinking);
  }
  // handleInteractionForKnownContact methods end //

  // handleInteractionForUnknownContact //
  private async populateUnknownContact(contactCard: ContactModel, interaction: any): Promise<void> {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | populateUnknownContact ');
    try {
      interaction.select();
      await contactCard.addServedUnlinkedInteraction(interaction);
      contactCard.retrieveOrganisationWorkTypes();
      contactCard.populateSearchParams(interaction);
      contactCard.retrieveOrganisationMemberTransferList();
      contactCard.retrieveOrganisationWorktypeColorConfig();
      contactCard.retrieveSMSFromNumbers();
      this.finaliseUnknownContactCard(contactCard);

    } catch(error: any) {
      throw new Error(error);
    }
  }

  private async finaliseUnknownContactCard(contactCard: ContactModel): Promise<void> {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | finaliseUnknownContactCard ');
    contactCard.changeViewStrategy('searchContactUnknownContact');
  }
  // handleInteractionForUnknownContact end //

  private async isExistingContactCard(interaction: any): Promise<ContactModel> {
    return await this.contactCardTools.isExistingContactCard(interaction.contactId, interaction.correlationId);
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }

  private handleCallbackLinking(contactCard: ContactModel, interaction: InteractionModel): Promise<void> {
    return new Promise(resolve => {
        let selectContactSubscription = this.eventAggregator.subscribe(CONTACT_ACTIONS.SELECT_SEARCH, async (contact: ContactModel) => {
          logger.debug(this.getCurrentFormattedTime() + ' - CC | handleInteractionForKnownContact | contact selected ', contact);
          selectContactSubscription.dispose();
          selectContactSubscription = null;
          interaction.contactId = contact.contactId;
          contactCard.populateContactCard(false, true);
          resolve();
      });
      contactCard.populateSearchParams(interaction);
    })
  }

  private handleChatLinking(contactCard: ContactModel, interaction: InteractionModel): Promise<void> {
    return new Promise(resolve => {
      this.eventAggregator.publish(CONTACT_ACTIONS.SELECT_SEARCH, { contactId: interaction.contactId });
      setTimeout(() => {
        logger.debug(this.getCurrentFormattedTime() + ' - CC | handleInteractionForKnownContact | contact selected ', contactCard, interaction);
        contactCard.removeAllUnlinkedInteraction(interaction.interactionId);
        interaction.contactId = interaction.contactId || contactCard.contactId;
        contactCard.populateContactCard(false, true);
        resolve();
      }, 1000);
    })
  }

  private linkInteractionToContact(interaction: InteractionModel): Promise<void> {
    return new Promise(async resolve => {
      await interaction.linkToContact(interaction.contactId);
      resolve();
    })
  }

  private linkToConversation(interaction: InteractionModel, contactCard: ContactModel): Promise<void> {
    return new Promise(resolve => {
      this.contactService.subscribeToInteractionLinkedToConversationEvent(() => {
        logger.debug(this.getCurrentFormattedTime() + ' - CC | linkedToConversation ');
        contactCard.removeServedUnlinkedInteraction(interaction.interactionId);
        if (interaction instanceof InteractionModel && interaction.isDiallerCall) {
          contactCard.removeUnlinkedInteraction(interaction.interactionId);
        }
        resolve();
      });
      setTimeout(() => {
        interaction.linkToConversation(interaction.conversationId);
      }, 200);
    });
  }
}