import { LogManager, customElement, bindable } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZNumberInput');

/*
 */
@customElement('z-number-input')
export class ZNumberInput {

  @bindable maxvalue;
  @bindable minvalue;
  @bindable initialvalue;
  @bindable stepsize;
  @bindable uniqueidentifier;
  @bindable({ attribute: 'simple-number' }) simpleNumber: boolean = false;
  @bindable({ attribute: 'input-value' }) inputValue;
  @bindable({ attribute: 'converter-type' }) converterType;
  @bindable({ attribute: 'converter-padding' }) converterPadding;
  @bindable({ attribute: 'block-number-lapse' }) blockNumberLapse: boolean = false;
  @bindable({ attribute: 'dynamic-width' }) dynamicWidth: boolean = false;

  private _maxValue: number;
  private _minValue: number;
  private _initialValue: number;
  private _stepSize: number;

  attached(): void {
    if (this.maxvalue) { this._maxValue = parseFloat(this.maxvalue); }
    this._minValue = parseFloat(this.minvalue);
    this._initialValue = parseFloat(this.initialvalue);
    this._stepSize = parseFloat(this.stepsize) || 1;
  }

  get _converterType(): string {
    return this.converterType || '';
  }

  get _converterPadding(): string {
    return this.converterPadding || '';
  }

  increasevalue(): void {
    
    if (this._maxValue && this._maxValue > 0) {
      if ((this.inputValue * 1 + this._stepSize) <= this._maxValue) {
        this.inputValue = this.inputValue * 1 + this._stepSize;
      } else if (!this.blockNumberLapse) {
        this.inputValue = this._minValue;
      }
    }
    else {
      this.inputValue = (1 * this.inputValue) + (1 * this._stepSize);
    }
  }

  decreasevalue(): void {
    if (this.inputValue - this._stepSize >= this._minValue) {
      this.inputValue = this.inputValue -= this._stepSize;
    } else if (!this.blockNumberLapse) {
      this.inputValue = this._maxValue;
    }
  }
}