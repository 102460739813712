import { EventAggregator } from 'aurelia-event-aggregator';
import { inject, LogManager } from 'aurelia-framework';
import CampaignModel from '../campaign-model';
import CampaignService from '../campaign-service';

const logger = LogManager.getLogger('AgentCampaignStats');

@inject(EventAggregator, CampaignService)
export class AgentCampaignStats {

  public selectedCampaign: CampaignModel;
  public loading: boolean = false;

  private campaigns: CampaignModel[];
  private intervalId: number;
  private interval: number = 10000;

  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly campaignService: CampaignService
  ) { }

  public activate(): void {
    this.eventAggregator.subscribe('AGENT.CAMPAIGN.SELECTED', (selectedCampaign: { campaignId: string }) => {
      if (selectedCampaign.campaignId) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
        this.intervalId = setInterval(() => this.retrieveData(selectedCampaign.campaignId), this.interval);
        this.retrieveData(selectedCampaign.campaignId);
      } else {
        this.selectedCampaign = null;
        clearInterval(this.intervalId);
      }
    });
  }

  private retrieveData(selectedCampaignId: string): void {
    this.loading = true;
    this.campaignService.retrieveCampaigns()
      .then((campaigns) => {
        this.campaigns = campaigns.campaigns;
        this.computeSelectedCampaign(selectedCampaignId);
      });
  }

  private computeSelectedCampaign(campaignId: string): void {
    this.selectedCampaign = this.campaigns.find((campaign) => campaign.campaignId === campaignId);
    this.loading = false;
  }
}
