import { EventAggregator } from 'aurelia-event-aggregator';
import { PLATFORM, customElement } from 'aurelia-framework';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';

import { FeatureFlagService, SessionStore } from 'zailab.common';
import { ContactService } from '../../../../../features/organisation/contact/contact-service';

import './interaction-links.scss';

@customElement('interaction-link')
@autoinject
export class InteractionLinks {
  private readonly storageKey = 'zai-iframeTabs';

  public iframeTabs: any = [];
  public scrollContainer: HTMLElement;
  public leftArrow: HTMLElement;
  public rightArrow: HTMLElement;

  private scrollTimeout: any;
  public embeddedWebphoneEnabled = null;

  constructor(
    private router: Router,
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    private contactService: ContactService,
    private featureFlagService: FeatureFlagService
  ) {}

  public activate(): void {
    this.loadTabsFromLocalStorage();

    this.eventAggregator.subscribe(
      'interaction-link-open',
      (data: {
        remote: string;
        url: string;
        interactionId: string;
        firstName?: string;
        surname?: string;
      }) => {
        this.router.navigate('web-content');
        let tabToUpdate = this.iframeTabs.find(tab => tab.url === data.url && tab.interactionId === data.interactionId);

        if (tabToUpdate) {
          let interactionTabs = this.iframeTabs.filter(tab => tab.interactionId === data.interactionId);

          interactionTabs.forEach(tab => {
            tab.name = this.determineTabName(data);
          });

          const index = this.iframeTabs.indexOf(tabToUpdate);
          this.selectTab(this.iframeTabs[index]);
          this.scrollTabIntoView(index);
        } else {
          const tab = { ...data, name: this.determineTabName(data) };
          if (this.iframeTabs.length >= 5) {
            this.iframeTabs.shift();
          }
          this.iframeTabs.push(tab);

          const index = this.iframeTabs.length - 1;
          this.selectTab(this.iframeTabs[this.iframeTabs.length - 1]);
          this.scrollTabIntoView(index);
        }
        this.saveTabsToLocalStorage()
        this.updateScrollButtons();
      }
    );
    this.getFeatureFlags();
  }

  private getFeatureFlags(): void {
    this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
      .then(async (embeddedWebphoneEnabled) => {
        this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
      });
  }

  private scrollTabIntoView(index: number): void {
    setTimeout(() => {
      if (index > 2) {
        this.scrollRight();
      } else {
        this.scrollLeft();
      }
    }, 1000)
  }

  private determineTabName(data): string {
    let name = '';
    if (data.firstName) {
      name += data.firstName;
      if (data.surname) {
        name += ' ';
      } else {
        name += ' - ';
      }
    }
    if (data.surname) {
      name += data.surname + ' - ';
    }

    if (!data.firstName && !data.surname) {
      name += 'Unknown Contact - ';
    }

    if (data.remote) {
      name += data.remote;
    }
    return name;
  }
  
  public selectTab(tab: any) {
    this.iframeTabs.forEach(tab => tab.selected = false);
    tab.selected = true;
  }

  private get memberId(): string {
    return this.sessionStore.get.user?.memberId;
  }

  public closeTab(index: number): void {
    const selectNewTab = this.iframeTabs[index];

    this.iframeTabs.splice(index, 1);
    if (selectNewTab.selected && this.iframeTabs.length > 0) {
      this.selectTab(this.iframeTabs[0]);
    }
    this.saveTabsToLocalStorage();
    this.updateScrollButtons();
  }

  public scrollLeft(): void {
    this.scrollContainer.scrollLeft -= 400;
    this.updateScrollButtons();
  }

  public scrollRight(): void {
    this.scrollContainer.scrollLeft += 400;
    this.updateScrollButtons();
  }
  
  public updateScrollButtons(): void {
    if (this.scrollTimeout) {
      window.clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      if (!this.scrollContainer) {
        return;
      }
      const scrollLeft = this.scrollContainer.scrollLeft;
      const scrollWidth = this.scrollContainer.scrollWidth;
      const containerWidth = this.scrollContainer.clientWidth;

      if (scrollLeft === 0) {
        this.leftArrow.style.display = 'none';
      } else {
        this.leftArrow.style.display = 'flex';
      }

      if (scrollLeft + containerWidth >= scrollWidth) {
        this.rightArrow.style.display = 'none';
      } else {
        this.rightArrow.style.display = 'flex';
      }
    }, 500);
  }

  private saveTabsToLocalStorage(): void {
    localStorage.setItem(this.storageKey, JSON.stringify(this.iframeTabs));
  }

  private loadTabsFromLocalStorage(): void {
    const storedTabs = localStorage.getItem(this.storageKey);
    if (storedTabs) {
      this.iframeTabs = JSON.parse(storedTabs);
    }
  }
}
