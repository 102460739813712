import { autoinject, customElement, LogManager } from "aurelia-framework";
import { Event } from "zailab.common";

const logger = LogManager.getLogger('ZGridNodeMenu');

customElement('z-grid-node-menu')
@autoinject
export class ZGridNodeMenu {

  public showMenu: boolean;

  constructor(
    private element: Element
  ) { }

  public toggleNodeMenu(): void {
    this.showMenu = !this.showMenu;
  }

  public editNode(): void {
    new Event(this.element, 'edit', {});
    this.showMenu = false;
  }

  public deleteNode(): void {
    new Event(this.element, 'delete', {});
    this.showMenu = false;
  }
}