import { DataTools } from '../../../../../_common/tools/data-tools';

export class AssistantFileModel {
  public readableSize: string;
  public formattedStatus: string;
  public icon: string;
  public color: string;
  
  constructor(
    public name: string,
    size: number,
    status: string
  ) {
    this.readableSize = DataTools.formatBytes(size);
    this.formattedStatus = status ? status.charAt(0).toUpperCase() + status.slice(1) : 'Pending';

    if (status === 'successful') {
      this.icon = 'check';
      this.color = 'green';
    } else if (status === 'error') {
      this.icon = 'times';
      this.color = 'red';
    } else if (status === 'pending') {
      this.icon = 'refresh';
      this.color = 'blue';
    }
  }
}
