
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";
import { BindingSignaler } from "aurelia-templating-resources";

import CampaignService from "../campaign-service";
import { DataGrid, DataRefresher, NavigatingBreadcrumb } from "../../../../components/templates/data-grid/data-grid";
import { LiveDashboardService } from "../../../interaction/dashboard/live-dashboard/live-dashboard-service";
import { ColourConfig } from "features/interaction/dashboard/live-dashboard/overview/colour-config";
import { PROSPECT_LIST_LIVE_DASHBOARD_STATE, ProspectListLiveDashboardState } from "./prospect-list-live-dashboard-state";
import { DataGridEvents } from './../../../../components/templates/data-grid/data-grid';

// todo: remove this interface ------------------------------------------------------------
interface MinimalViableLiveDashboardConfig {
  title: string;
  settings: {
    value: number,
    color: string,
  }[];
  visible?: boolean;
}
interface IStateChannels {
  call: boolean;
  callback: boolean;
  chat: boolean;
  email: boolean;
  im: boolean;
  sms: boolean;
  ticket: boolean;
};
interface IStateStorage {
  requestTimer: number,
  contactCenterMonitoring: {
    grid: {
      columnHeaders: Array<string>,
      channels: IStateChannels,
      teams: Array<string>
    },
    requestTimer: number
  }
};

import { v4 as uuidv4 } from 'uuid';

const DEFAULT_CONFIG: MinimalViableLiveDashboardConfig[] = [
  { title: 'Remaining [Ready]', settings: [{ value: 0, color: '' }, { value: 1, color: 'var(--successful)' }] },
  { title: 'On Duty Agents', settings: [{ value: 0, color: '' }, { value: 1, color: 'var(--successful)' }] },
  { title: 'Conversing Agents', settings: [{ value: 0, color: '' }, { value: 1, color: 'var(--successful)' }] },
  { title: 'Not Responding Agents', settings: [{ value: 0, color: '' }, { value: 1, color: 'var(--unsuccessful)' }] },
  { title: 'Off Duty Agents', settings: [{ value: 0, color: '' }, { value: 1, color: 'var(--unsuccessful)' }] },
  { title: '% In SLA (30min)', settings: [{ value: 0, color: 'var(--unsuccessful)' }, { value: 86, color: '' }] },
  
];


const logger = LogManager.getLogger('ProspectListDashboard');

@autoinject
export class ProspectListDashboard {

  private refreshIntervalHolder: any;
  private campaignId: string;

  public refresher: DataRefresher = new DataRefresher(300);
  public breadcrumbs: NavigatingBreadcrumb[];
  public cdDataGrid: DataGrid;
  
  private prospectListLiveDashboardState: ProspectListLiveDashboardState = new ProspectListLiveDashboardState();
  private mappings: any;
  public gridEnabled: boolean = false;

  constructor(
    private readonly router: Router,
    private dataGridEvents: DataGridEvents,
    private readonly bindingSignaler: BindingSignaler,
    private readonly campaignService: CampaignService,
    private liveDashboardService: LiveDashboardService
  ) { }

  private async init(): Promise<void> {
    this.dataGridEvents.onDragStart(() => {
      this.refresher.enableSelectors = false;
      this.refresher.paused = true;
    });
    this.dataGridEvents.onDragEnd(() => {
      this.refresher.enableSelectors = true;
      this.refresher.paused = false;
    });

    const colorScheme: any = await this.retrieveColorConfig();
    let config = await colorScheme && colorScheme.colorFormats && Object.keys(colorScheme.colorFormats).length > 0 ? this.transformData(colorScheme.colorFormats) : DEFAULT_CONFIG;
    ColourConfig.setColourScheme(ColourConfig.VIEWS.PROSPECT_LIST_LIVE_DASHBOARD, config);

    this.mappings = await this.prospectListLiveDashboardState.getMappings(config);
    this.cdDataGrid = new DataGrid(this.mappings);
    this.cdDataGrid.dataSource = [];

    this.refresher.currentRequestTimer = PROSPECT_LIST_LIVE_DASHBOARD_STATE.refreshTime;
    this.startRefresh();
  }
  
  private async retrieveColorConfig(): Promise<void> {
    try {
      let config: any = await this.liveDashboardService.retrieveColorConfig('PROSPECT_LIST_LIVE_DASHBOARD');
      if (!config) {
        const configId = uuidv4();
        let payload: any = {
          id: configId,
          type: 'PROSPECT_LIST_LIVE_DASHBOARD',
          colorFormats: {}
        };

        this.liveDashboardService.updateColorConfig(payload.id, payload);
      }
      return config;
    } catch(e) {
      logger.warn('Failed to retrieve live dashboard color config ', e);
    }
  }

  private transformData(inputData) {
    let transformedData = [];

    for (let key in inputData) {
      if (inputData.hasOwnProperty(key)) {
        let transformedItem = {
          title: key,
          settings: inputData[key]
        };
        transformedData.push(transformedItem);
      }
    }
    return transformedData;
  }

  private startRefresh(): void {
    this.refresh();
    if (this.refreshIntervalHolder) {
      clearInterval(this.refreshIntervalHolder);
    }
    this.refreshIntervalHolder = setInterval(() => {
      if (this.refresher.paused) {
        return;
      }
      this.bindingSignaler.signal('myTimeToSignal');
      if (this.refresher.isDone()) {
        this.refresh();
      }
    }, 1000 / 3);
  }

  private refresh(): void {
    this.refresher.enableSelectors = false;
    this.refresher.paused = true;
    let tempList = [];
    this.campaignService.retrieveLiveDashboard()
      .then((res) => {
        this.cdDataGrid.ready = true;
        if (res.campaigns) {
          const campaign = res.campaigns.find((c) => c.id === this.campaignId);
          if (!this.breadcrumbs || !this.breadcrumbs.length) {
            this.breadcrumbs = [new NavigatingBreadcrumb('', 'Campaigns Live Dashboard', false)];
            this.breadcrumbs.push(new NavigatingBreadcrumb('', campaign.name, true));
          }
          campaign.campaignId = campaign.id;
          campaign.campaignName = campaign.name;
          campaign.prospectListName = 'ALL';
          campaign.worktype = 'ALL';
          this.cdDataGrid.addData(campaign, tempList);
          const prospectLists = res.prospectLists.filter((prospectList) => prospectList.campaignId === campaign.campaignId);
          prospectLists.sort((o1,o2) => {
            if (o1.name > o2.name) {
              return 1;
            }
            if (o1.name < o2.name) {
              return -1;
            }
            return 0;
          });
          for (const prospectList of prospectLists) {
            prospectList.campaignName = campaign.name;
            prospectList.prospectListId = prospectList.id;
            prospectList.prospectListName = prospectList.name;
            this.cdDataGrid.addData(prospectList, tempList);
          }
        }
        this.cdDataGrid.dataSource = tempList;
        this.setReadyState();
      })
      .catch((err) => {
        logger.warn('init :: err=', err);
      });
  }

  private setReadyState(): void {
    this.refresher.enableSelectors = true;
    this.refresher.paused = false;
    this.cdDataGrid.ready = true;
    this.gridEnabled = true;
    this.refresher.reset();
  }

  public activate(params: any): void {
    this.campaignId = params.campaignId;
    this.init();
  }

  public detached(): void {
    clearInterval(this.refreshIntervalHolder);
  }

  public navigateBack(): void {
    this.router.navigateBack();
  }

  public settings(): void {
    this.router.navigate(`campaignlivedashboardsettings/${this.campaignId}`);
  }

  public save(): void {
    this.refresher.enableSelectors = false;
    this.cdDataGrid.getColumnOrderAndWidths()
      .then((columnOrderAndWidths) => {
        this.prospectListLiveDashboardState.saveSettings(columnOrderAndWidths);
        this.prospectListLiveDashboardState.setRefreshTime(this.refresher.currentRequestTimer);
        this.refresher.enableSelectors = true;
      });
  }
}
