import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import moment from 'moment';

import { SessionStore, MESSAGE_EVENTS, SearchTools } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { InteractionsService } from '../interaction-flow-service';
import { AssignNumberDialog } from '../assign-number/assign-number-dialog';
import { FlowVersionDialog } from '../flow-versions/flow-version-dialog';
import { AssignEndpointDialog } from '../assign-endpoint/assign-endpoint-dialog';
import { AssignEmailDialog } from '../assign-email/assign-email-dialog';
import { AssignCallerIdDialog } from '../assign-caller-id/assign-caller-id-dialog';
import { PlaceholderService } from "../../../../_common/services/placeholder-service";
import { InteractionFlowModel } from "../interaction-designer/models/interaction-flow-model";
import { AssignWebhookDialog } from '../assign-webhook/assign-webhook-dialog';
import { InfiniteScrollService } from '../../../../_common/services/infinitescroll-service';
import { FlowsAssignedToSubflowDialog } from '../view-flows-assigned-to-subflow/view-flows-assigned-to-subflow';
import { FeatureFlagService } from './../../../featureflags/feature-flag-service';
import { AssignChatWidgetDialog } from '../assign-chat-widget/assign-chat-widget-dialog';
import { ConfigureAdditionalDataDialog } from '../configure-additional-data/configure-additional-data-dialog';

const logger = LogManager.getLogger('InteractionFlowListView');

@inject(Element, InteractionsService, Router, DialogService, EventAggregator, SessionStore, FeatureFlagService)
export class InteractionFlowListView extends AbstractList {

  protected placeholders = 0;
  protected container: HTMLElement;
  private defaultHeader = 'Work Flows';
  private header = this.defaultHeader;
  protected isDeleting: boolean = false;
  protected placeholderService: PlaceholderService;
  private infiniteScroll: InfiniteScrollService;
  private page = 0;

  constructor(
    protected element: Element,
    private interactionsService: InteractionsService,
    private router: Router,
    private dialogService: DialogService,
    protected eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private featureFlagService: FeatureFlagService
  ) {
    super(eventAggregator);
  }

  protected activate(params?: any): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected deactivate(): void {
    super.deactivate();
  }

  protected subscribeToOplog(): void {
    this.interactionsService.initialiseInteractionFlowsOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('update', response => {
        this.setupListConfig(this.interactionsService._interactionListFactory(response.interactionFlows));
        if (this.placeholderService) {
          this.placeholderService.generatePlaceholders(response.interactionFlows.length);
        }
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.interactionsService
      .retrieveOrganisationInteractionFlows().then(
      interactionsList => {
        this.setupListConfig(interactionsList);
        this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 2, (placeholders) => {
          this.placeholders = placeholders;
        });
      }).catch(_error => {
        logger.info('retrieve interaction flows > error = ', _error);
        this.hideLoader();
      });
  }

  protected async setupListConfig(_flows: any[]): Promise<void> {
    super.setupListConfig(_flows);
    this.itemList = List.Builder()
      .items(_flows)
      .build();

      
    this.createActionsList(this.itemList.items);

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  private createActionsList(items: any[]): void {
    const _defaultActions = { action: 'edit', label: 'edit flow' };

    for (let item of items) {
      
      if (item.channel === 'Ticket') {
        item.actions = [_defaultActions, {
          action: 'assignwebhook',
          label: 'assign webhook',
          icon: 'channel-ticket'
        }, {
          action: 'assignlivewebhook',
          label: 'assign live webhook',
          icon: 'channel-ticket'
        }, {
          action: 'configureadditionaldata',
          label: 'additional data',
          icon: 'clipboard'
        }];
      } else if (item.channel === 'Email') {
        item.actions = [_defaultActions, {
          action: 'assignemails',
          label: 'assign emails',
          icon: 'channel-email'
        }, {
          action: 'assignliveemails',
          label: 'assign live emails',
          icon: 'channel-email'
        }, {
          action: 'configureadditionaldata',
          label: 'additional data',
          icon: 'clipboard'
        }];
      }
      else if (item.channel === 'Inbound Call') {
        item.actions = item.actions = [_defaultActions];
        if(!item.subflow){
          item.actions.push({
            action: 'assignnumbers',
            label: 'assign numbers',
            icon: 'channel-incoming'
          });
          item.actions.push({
            action: 'assignlivenumbers',
            label: 'assign live numbers',
            icon: 'channel-incoming'
          });

          if (item.flowType === 'Contact Centre Flow') {
            item.actions.push({
              action: 'configureadditionaldata',
              label: 'additional data',
              icon: 'clipboard'
            });
          }
        }
        item.actions.push({ action: 'flowaudio', label: 'flow audio', icon: 'audio'});
      }
      else if (item.channel === 'SMS') {
        item.actions = [_defaultActions, {
          action: 'assignnumbers',
          label: 'assign numbers',
          icon: 'channel-sms'
        }, {
          action: 'assignlivenumbers',
          label: 'assign live numbers',
          icon: 'channel-incoming'
        }, {
          action: 'configureadditionaldata',
          label: 'additional data',
          icon: 'clipboard'
        }];
      }
      else if (item.channel === 'Chat') {
        item.actions = [_defaultActions, {
          action: 'assign-chat-widget',
          label: 'Assign Chat Widget',
          icon: 'channel-ticket'
        }, {
          action: 'assign-live-chat-widget',
          label: 'Assign Live Chat Widget',
          icon: 'channel-ticket'
        }, {
          action: 'copy-flow-id',
          label: 'copy flow ID',
          icon: 'clipboard'
        }, {
          action: 'configureadditionaldata',
          label: 'additional data',
          icon: 'clipboard'
        }];
      }
      else if (item.channel === 'Instant Message') {
        item.actions = [_defaultActions, {
          action: 'assignendpoints',
          label: 'assign numbers',
          icon: 'channel-instant-message'
        }, {
          action: 'assignendliveendpoints',
          label: 'assign live numbers',
          icon: 'channel-instant-message'
        }];
      }
      else if (item.channel === 'Outbound Call') {
        item.actions = [_defaultActions, {
          action: 'assignnumbers',
          label: 'assign caller iDs',
          icon: 'channel-outgoing'
        }];
      }
      if (item.channel === 'Outbound Call' && item.flowType === 'Contact Centre Flow') {
        if (!item.actions) {
          item.actions = [_defaultActions];
        }
        item.actions.push({
          action: 'configureadditionaldata',
          label: 'additional data',
          icon: 'clipboard'
        });
      }
      if (!item.actions) {
        item.actions = [_defaultActions];
      }
      item.actions.push({ action: 'flowversions', label: 'flow versions', icon: 'copy'});
      if (item.subflow) {
        item.actions.push({ action: 'viewassignedflows', label: 'View Flows', icon: 'split'});
      }
    }
  }

  protected delete(interactionFlow: any): void {
    this.interactionsService.removeInteractionFlow(interactionFlow);
  }

  protected edit(_item: any): void {
    let flowId = _item.interactionFlowId;
    this.router.navigate(`interactiondesigner/${flowId}`);
  }

  public selectFlow(_item: any): void {
    _item.isDeleting = !_item.isDeleting;
  }

  protected addInteractionFlow(): void {
    this.router.navigate('create');
  }

  public viewAudit(): void {
    this.router.navigate('audit');
  }

  @computedFrom('sessionStore.get.account.isTrialAccount')
  get isTrialAccount(): boolean {
    return this.sessionStore.get.account.isTrialAccount;
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
  }

  protected toggleSearch(): void {
    super.toggleSearch();
  }

  public deleteFlows(): void {

    let listToDelete = [];

    this.itemList.items.forEach(_flow => {
      if (_flow.isDeleting) {
        listToDelete.push(_flow);
      }
    });

    this.deleteItems(listToDelete);
    this.hideLoader();

    this.itemList.items.forEach(item => {
      item.isDeleting = false;
    });
    this.toggleDelete();
  }

  protected startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: any, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  public assignChatWidget(interactionFlow: InteractionFlowModel, live?: boolean): void {
    this.dialogService.open({
      viewModel: AssignChatWidgetDialog,
      model: {
        interactionFlow,
        live
      }
    });
  }

  public copyFlowId(item: InteractionFlowModel): void {
    let flowIdField: HTMLInputElement = document.getElementById(`js-copy-flow-id-${item.interactionFlowId}`) as HTMLInputElement;
    flowIdField.select();
    document.execCommand('Copy');
    this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Flow ID Copied`);
  }


  public displayNotice(interactionFlow: any): void {
    if (interactionFlow.channel === 'Outbound Call' && this.isTrialAccount) {
      interactionFlow.hasHover = !interactionFlow.hasHover;
    }
  }

  public assignNumbers(interactionFlow: any): void {
    let selectedTelephoneInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId
    };
    this.dialogService.open({
      viewModel: AssignNumberDialog,
      model: selectedTelephoneInteractionFlowModel
    }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public assignLiveNumbers(interactionFlow: any): void {
    let selectedTelephoneInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId,
      liveNumbers: true
    };
    this.dialogService.open({
      viewModel: AssignNumberDialog,
      model: selectedTelephoneInteractionFlowModel
    }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public viewFlowVersions(interactionFlow: any): void {
    let selectedInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId
    };
    this.dialogService.open({
      viewModel: FlowVersionDialog,
      model: selectedInteractionFlowModel
    }).whenClosed((response: any) => {
      if (!response.wasCancelled) {}
      if (response.output && response.output.version) {
        this.router.navigate(`interactiondesigner/${response.output.interactionFlowId}?version=` + response.output.version);
      }
    });
  }

  public viewAssignedFlows(interactionFlow: any): void {
    let selectedInteractionFlowModel = {
      interactionFlow: interactionFlow,
      flowName: interactionFlow.flowName,
      organisationId: this.sessionStore.get.organisation.organisationId
    };
    this.dialogService.open({
      viewModel: FlowsAssignedToSubflowDialog,
      model: selectedInteractionFlowModel
    }).whenClosed((response: any) => {
      if (!response.wasCancelled) {}
      if (response.output && response.output.id) {
        this.router.navigate(`interactiondesigner/${response.output.id}` + (response.output.version ? `?version=` + response.output.version : ''));
      }
    });
  }

  public assignEndpoints(interactionFlow: any): void {
    let selectedTelephoneInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId,
      liveNumbers: true
    };
    this.dialogService.open({
      viewModel: AssignEndpointDialog,
      model: selectedTelephoneInteractionFlowModel
    }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }
  
  public assignLiveEndpoints(interactionFlow: any): void {
    let selectedTelephoneInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId,
      liveNumbers: true
    };
    this.dialogService.open({
      viewModel: AssignEndpointDialog,
      model: selectedTelephoneInteractionFlowModel
    }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public assignEmails(interactionFlow: any): void {
    let selectedEmailInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId
    };
    this.dialogService.open({ viewModel: AssignEmailDialog, model: selectedEmailInteractionFlowModel }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public assignLiveEmails(interactionFlow: any): void {
    let selectedEmailInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId,
      liveNumbers: true
    };
    this.dialogService.open({ viewModel: AssignEmailDialog, model: selectedEmailInteractionFlowModel }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public assignWebhook(interactionFlow: any, live: boolean): void {
    this.dialogService
      .open({
        viewModel: AssignWebhookDialog,
        model: {
          interactionFlowId: interactionFlow.interactionFlowId,
          live
        }
      }).whenClosed(response => {
        if (!response.wasCancelled) {}
      });
  }

  public assignCallerId(interactionFlow: any): void {
    let selectedOutboundInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId
    };
    this.dialogService.open({
      viewModel: AssignCallerIdDialog,
      model: selectedOutboundInteractionFlowModel
    }).whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public configureAdditionalData(interactionFlow: any): void {
    let selectedInteractionFlowModel = {
      interactionFlow: interactionFlow,
      organisationId: this.sessionStore.get.organisation.organisationId
    };
    this.dialogService.open({
      viewModel: ConfigureAdditionalDataDialog,
      model: selectedInteractionFlowModel
    });
  }

  public viewFlowAudio(item: any): void {
    this.router.navigate(`/hud/audio-list?workflowId=${item.interactionFlowId}`);
  }
}
