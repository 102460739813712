import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 * */
import { TeamsService } from '../teams-service';
import { SessionStore, BootstrapFormRenderer } from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('TeamsListView');
/*
 * */
@inject(TeamsService, SessionStore, DialogController, ValidationControllerFactory)
export class AddTeamDialog {

  teamList = [];
  teamName = '';
  teamFormGroup;
  dialogHeader = "Add Team";

  constructor(teamsService, sessionStore, dialogController, validationControllerFactory) {
    this.teamsService = teamsService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param object
   * */
  activate(teamList) {
    this.teamList = teamList;
    this.initValidation();
  }

  initValidation() {

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let item of this.teamList) {
          if (item.name.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .ensure('teamName')
      .required().withMessage('Please enter a team name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.teamFormGroup.reset();
    this.dialogController.cancel();
  }

  createTeam() {
    this.validation.validate().then(validation => {
      if (!validation.valid) {
        return;
      }
      this.dialogController.ok(this.teamName);
    }, error => {
      logger.info('addTeam > validation failure', error);
    });
  }
}