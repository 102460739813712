import { LogManager, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('Assist');

export class Assist {

  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: ['', 'list'],
        name: 'Members',
        moduleId: PLATFORM.moduleName('./list/assist-list'),
        title: 'List'
      }
    ]);
    this.router = router;
  }
}