import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import moment from 'moment';

import { InAppAlertsService } from '../in-app-alerts-service';
import { ViewCampaignAuditDialog } from '../../../campaigns/campaign/audit/view-audit';

const logger = LogManager.getLogger('WorktypesAudit');
@autoinject
export class WorktypesAudit {
  public header: string = 'Dashboard Alerts Audit Trail';
  public searchTerm: string = '';
  public ready: boolean = false;
  public auditTrail: any[] = [];
  public total: number | string;
  public isSearching: boolean = false;
  public tableContainer: Element;
  private pageSize: number = 100;
  private pageNumber: number = 0;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private inAppAlertsService: InAppAlertsService
  ) {}

  public activate(): void {
    this.retrieveAuditTrail();
  }

  public retrieveAuditTrail(): void {
    this.inAppAlertsService
      .retrieveAuditTrail(this.pageSize, this.pageNumber)
      .then((response) => {
        if (response && Array.isArray(response.payload)) {
          response.payload.forEach((item) => {
            item.formattedTimestamp = moment(parseInt(item.timestamp)).format(
              'D MMM YY HH:mm:ss'
            );
          });
          this.auditTrail = this.auditTrail.concat(response.payload);
          this.total = response.totalElements;
          this.ready = true;
        }
      })
      .catch((error) => {
        logger.warn(' > failed to get campaign audits ', error);
        this.total = '--';
        this.ready = true;
      });
  }

  public viewWorkTypes(): void {
    this.router.navigate('');
  }

  public toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  public partialMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression.length === 0) {
      return true;
    }
    searchExpression = searchExpression.toLowerCase();

    let formattedTimestamp = value.formattedTimestamp
      ? value.formattedTimestamp.toLowerCase()
      : '';
    let alertName = value.alertName ? value.alertName.toLowerCase() : '';
    let firstName = value.firstName ? value.firstName.toLowerCase() : '';
    let surname = value.surname ? value.surname.toLowerCase() : '';
    let email = value.email ? value.email.toLowerCase() : '';
    let action = value.action ? value.action.toLowerCase() : '';
    let taskTemplateName = value.taskTemplateName
      ? value.taskTemplateName.toLowerCase()
      : '';
    return (
      formattedTimestamp.includes(searchExpression) ||
      alertName.includes(searchExpression) ||
      firstName.includes(searchExpression) ||
      surname.includes(searchExpression) ||
      email.includes(searchExpression) ||
      action.includes(searchExpression) ||
      taskTemplateName.includes(searchExpression)
    );
  }

  public viewChange(auditRecord: any): void {
    let model = {
      size: 'medium',
    };
    if (auditRecord.changes) {
      model = {
        ...auditRecord.changes,
        ...model,
      };
    }
    this.dialogService
      .open({
        viewModel: ViewCampaignAuditDialog,
        model,
      })
      .whenClosed(() => {});
  }

  public navigateToDashboardAlerts(): void {
    this.router.navigate('dashboard-alerts');
  }
}
