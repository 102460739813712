import Quill from 'quill';
import { QUILL_IMAGE_UPLOAD_CONSTANTS } from './quill-image-upload-constants';

const Image = Quill.import('formats/image');

export class QuillImageUploadPlaceholder extends Image {

  static blotName = 'imageUpload';
  static className = QUILL_IMAGE_UPLOAD_CONSTANTS.IMAGE_UPLOAD_PLACEHOLDER_CLASS_NAME;

  static create(value) {
    let id;
    let src;

    const arr = value.split(QUILL_IMAGE_UPLOAD_CONSTANTS.ID_SPLIT_FLAG);
    if (arr.length > 1) {
      id = arr[0];
      src = arr[1];
    } else {
      src = value;
    }

    let node = super.create(src);
    if (typeof src === 'string') {
      node.setAttribute('src', this.sanitize(src));
    }

    if (id) {
      node.setAttribute('id', id);
    }
    return node;
  }
}

export function initPlaceHolder() {
  Quill.register({
    'formats/imageUploadPlaceholder': QuillImageUploadPlaceholder
  });
}

