import { UserSessionModel } from './../../../../_common/stores/sessionmodels/user-session-model';
import {HttpClient} from 'aurelia-http-client';
import {autoinject, LogManager} from 'aurelia-framework';

import { SessionStore } from 'zailab.common';
import { LiveDashboardModel } from './models/live-dashboard-model';
import { ServiceWithTaskTemplatesModel } from './models/service-with-task-template-model';
import {TotalInteractionsModel} from '../../../../components/organisms/charts/models/total-interactions-model';
import {WaitingInteractionsModel} from './waitinginteractions/waiting-interactions-model';
import {MemberModel} from "../../../organisation/conversation/member-model";

const logger = LogManager.getLogger('LiveDashboardService');

@autoinject()
export class LiveDashboardService {

  constructor(
    private httpClient: HttpClient,
    private oplogService: any,
    private sessionStore: SessionStore
  ) {}

  public subscribeToServiceLiveDashboardChanges(dashboardId: string): Promise<any> {
    return new Promise(resolve => {
      resolve(this.oplogService.subscribeOn('_id', dashboardId).in('dashboard-projector.serviceLiveDashboardView'));
    });
  }

  public subscribeToTaskTemplateLiveDashboardChanges(dashboardId: string): Promise<any> {
    return new Promise(resolve => {
      resolve(this.oplogService.subscribeOn('_id', dashboardId).in('dashboard-projector.taskTemplateLiveDashboardView'));
    });
  }

  public retrieveLiveDashboard(serviceId: string, taskTemplateId?: string, grid?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      let uri = `v1/interaction/live-dashboard/services/${serviceId}`;
      let params = {};

      if (taskTemplateId) {
        uri += `/task-templates/${taskTemplateId}`;
      }
      if (grid) {
        params = {
          grid: true
        };
      }

      this.httpClient
        .createRequest(uri)
        .asGet()
        .withParams(params)
        .send()
        .then(
          response => {
            resolve(new LiveDashboardModel(response, this.user.hasAgentRole));
          },
          error => {
            logger.info('Encountered error =>', error);
            reject(error);
          }
        );
    });
  }

  public retrieveNewLiveDashboard(serviceId: string, taskTemplateId?: string, grid?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      let uri = `v1/interaction/live-dashboard/services_new/${serviceId}`;
      // let uri = `v1/interaction/live-dashboard/services/${serviceId}`;
      let params = {};

      if (taskTemplateId) {
        uri += `/task-templates/${taskTemplateId}`;
      }
      if (grid) {
        params = {
          grid: true
        };
      }

      this.httpClient
        .createRequest(uri)
        .asGet()
        .withParams(params)
        .send()
        .then(
          (response:any) => {
            if (response && Array.isArray(response.worktypeDashboards)) {
              response.worktypeDashboards.forEach(item => item.serviceName = response.service.name)
            }
            resolve(response);
          },
          error => {
            logger.info('Encountered error =>', error);
            reject(error);
          }
        );
    });
  }

  public retrieveGraphData(selectedGraphUri: string,serviceId: string, taskTemplateId: string, startTime: string, endTime: string, timeZone: string, groupBy: string, filterOption: string): Promise<TotalInteractionsModel> {
    let uri = `v3/performance-management/dashboards/${selectedGraphUri}?serviceId=${serviceId}&taskTemplateId=${taskTemplateId}&startTimestamp=${startTime}&endTimestamp=${endTime}&timezone=${timeZone}&groupBy=${groupBy}&filterBy=${filterOption}`;
    return this.httpClient
      .createRequest(uri)
      .asGet()
      .send()
      .then(
        response => {
          try {
            return new TotalInteractionsModel(response);
          } catch (e) {
            throw new Error(e);
          }
        })
      .catch((error) => {
          logger.info('Encountered error =>', error);
          throw new Error(error);
        }
      );
  }

  public retrieveAvailableServicesWithTaskTemplates(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/organisations/me/services-with-task-templates')
        .asGet()
        .send()
        .then(this.fulfillServices(resolve), () => reject());
    });
  }

  public retrieveMyServices(memberId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/organisations/me/services-with-task-templates/members/${memberId}`)
        .asGet()
        .send()
        .then(this.fulfillServices(resolve), () => reject());
    });
  }

  public retrieveMyDashboardColourConfig(dashboard: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('/v1/interaction/live-dashboard/configuration/' + dashboard)
        .asGet()
        .send()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  public updateMyDashboardColourConfig(payload: any, dashboard: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('/v1/interaction/live-dashboard/configuration/' + dashboard)
        .asPut()
        .withContent(payload)
        .send()
        .then(response => setTimeout(() => resolve(response), 1000))
        .catch(error => reject(error));
    });
  }

  private fulfillServices(resolve: (value?: (PromiseLike<any> | any)) => void): PromiseLike<any> | any {
    return (response: any) => {
      let serviceWithTaskTemplateList = [];

      if (response && response.services) {
        response.services.forEach(service => {
          serviceWithTaskTemplateList.push(new ServiceWithTaskTemplatesModel(service));
        });
      }

      resolve(serviceWithTaskTemplateList);
    };
  }

  public async retrieveWaitingInteractions(serviceId: string, workTypeId: string, selectedChannel: string, selectedSLA: any, selectedCallback: boolean): Promise<any> {

    return new Promise((resolve, reject) => {
      return this.httpClient.createRequest(`/v1/interaction/live-dashboard/interactions?serviceId=${serviceId}&taskTemplateId=${workTypeId || ''}&channel=${selectedChannel || ''}&outOfSla=${selectedSLA}&callback=${selectedCallback}`)
        .asGet()
        .send()
        .then((response: any) => {
          if (response && response.interactions && response.interactions.length > 0) {
            resolve(this.formatData(response.interactions));
          } else {
            resolve([]);
          }
        }, (error) => {
          reject([]);
        });
    });
  }

  public async subscribeToWaitingInteractionsOplog(serviceId: string): Promise<any> {
    return this.oplogService.subscribeOn('serviceId', serviceId).in('dashboard-projector.taskTemplateWaitingRoomLiveReportView');
  }

  public retrieveOrganisationMembers(name: string, pageNumber: number, pageSize: number): Promise<Array<MemberModel>> {
    const memberSearchParams = new MemberSearchParams("Agent", name, undefined, pageNumber, pageSize);
    return this.httpClient
      .createRequest(`v1/organisation/members/search2${memberSearchParams.requestParams}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (response && response.payload) {
          return this.modelMembers(response.payload);
        }
      })
      .catch(error => {
        return [];
      });
  }

  public sendDirectRoutingToAgent(interactionId: string, memberId: string): Promise<any> {
    return this.httpClient
      .createRequest(`waitingroom/global/interactions/${interactionId}/match-to/${memberId}`)
      .asPut()
      .send()
      .then(
        response => {
          return true;
        })
      .catch(error => {
      });
  }

  public validateAndRemoveInteractions(memberId: string, organisationId: string): Promise<any> {
    return this.httpClient
      .createRequest(`waitingroom/global/members/${memberId}/validateandremove`)
      .asDelete()
      .withParams({ organisationId })
      .send();
  }

  public removeEmailFromWaitingRoom(interactionId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/messages/clearInteraction?interactionId=${interactionId}`)
      .asPost()
      .send();
  }

  public removeAllEmailsFromWaitingRoom(interactionIds: string[]): Promise<any> {
    return this.httpClient
      .createRequest(`v1/messages/clearInteraction/all`)
      .asDelete()
      .withContent({interactionIds})
      .send();
  }
  

  public retrieveColorConfig(type) {
    return this.httpClient
      .createRequest(`v1/organisation/me/color-format-config`)
      .asGet()
      .withParams({
        type
      })
      .send();
  }

  public addColorConfig(configId, payload) {
    delete payload.id;
    return this.httpClient
      .createRequest(`v1/organisation/me/color-format-config/${configId}`)
      .asPost()
      .withContent(payload)
      .send();
  }

  public updateColorConfig(configId, payload) {
    return this.httpClient
      .createRequest(`v1/organisation/me/color-format-config/${configId}`)
      .asPut()
      .withContent(payload)
      .send();
  }

  public modelMembers(members: MemberModel[]): MemberModel[] {
    let list = [];
    members.forEach(member => {
      list.push(new MemberModel(member));
    });
    return list;
  }

  private formatData(waitingInteractions: any[]): any {
    let formattedInteractions = [];
    waitingInteractions.forEach((interaction) => {
      formattedInteractions.push(new WaitingInteractionsModel(interaction))
    });
    return formattedInteractions;
  }

  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }
}

export class MemberSearchParams {

  public requestParams: string;

  constructor(role: string, name: string, email: string, pageNumber: number, pageSize: number) {
    const params = [];
    if (role) {
      params.push(`role=${role}`);
    }
    if (name) {
      params.push(`name=${encodeURIComponent(name)}`);
    }
    if (email) {
      params.push(`email=${encodeURIComponent(email)}`);
    }
    if (Number.isInteger(pageNumber)) {
      params.push(`pageNumber=${pageNumber}`);
    }
    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }
    if (params.length > 0) {
      this.requestParams = '?' + params.join('&');
    } else {
      this.requestParams = '';
    }
  }
}
