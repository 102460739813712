export interface AgentScriptExecution {

  id: string;
  organisationId: string;
  memberId: string;
  interactionId: string;
  agentScriptId: string;
  status: AgentScriptExecutionStatus;
  selectedConnectionIds: string[];
  currentNodeId: string;
  definition: AgentScriptDefinition;
}

export interface AgentScriptDefinition {

  agentScriptId: string;
  startNodeId: string;
  nodes: AgentScriptDefinitionNode[];
  connections: AgentScriptDefinitionConnection[];
}

export interface AgentScriptDefinitionNode {

  id: string;
  name: string;
  text: string;
  connectionIds: string[];
  coordinates: { x: number, y: number };
}

export interface AgentScriptDefinitionConnection {

  id: string;
  name: string;
  sourceNodeId: string;
  destinationNodeId: string;
}

export enum AgentScriptExecutionStatus {
  IN_PROGRESS = 'IN_PROGRESS', ENDED_BY_USER = 'ENDED_BY_USER', ENDED_BY_SYSTEM = 'ENDED_BY_SYSTEM'
}

export function mapAgentScriptExecution(data: any): AgentScriptExecution {
  return {
    id: data.id,
    organisationId: data.organisationId,
    memberId: data.memberId,
    interactionId: data.interactionId,
    agentScriptId: data.agentScriptId,
    status: data.status,
    selectedConnectionIds: data.selectedConnectionIds,
    currentNodeId: data.currentNodeId,
    definition: data.definition
  };
}
