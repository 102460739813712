import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';
/**/
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { EmptyStateAbstract } from '../abstracts/empty-state-abstract';
import { SearchTools } from 'zailab.common';
import {InteractionFlowModel} from "../../../interaction-flow-model";
import {InteractionFlowService} from "../../interaction-flow-service";
import {InteractionsService} from "../../../interaction-flow-service";
import {SubFlowFlowVersionDialog} from "./flow-versions/flow-version-dialog";
/**/
const logger = LogManager.getLogger('SubflowDialog');

/**/
@autoinject
export class SubflowDialog extends EmptyStateAbstract {

  private existingProperties: any = {};
  private existingConnections: Array<ConnectorModel> = [];
  private nodeData: NodeModel;
  private searchCriteria: string;
  private interactionFlows: Array<InteractionFlowModel> = [];
  private selectedInteractionFlow: any;
  private selectedInteractionFlowId: string;
  private selectedVersion: number;
  private isSearching: boolean;
  private oplog: any;
  private searchTerm: string;
  private isValid: boolean;
  private endNodeNames: Array<string>;

  protected nodeConfiguration: ZNodeConfig;

  constructor(public dialogController: DialogController, private interactionFlowService: InteractionFlowService, private interactionsService: InteractionsService, protected dialogService: DialogService) {
    super(dialogService);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeConfiguration.mode = 'embedded';
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.existingProperties = this.nodeData.properties;
    this.selectedInteractionFlowId = this.nodeData.properties.interactionFlowId;
    this.selectedVersion = this.nodeData.properties.version;
  }

  public attached(): void {
    this.retrieveInteractionFlows();
  }

  private retrieveInteractionFlows(): void {
    this.nodeDialogInitialised = false;
    setTimeout(() => {
      this.interactionsService.retrieveOrganisationInteractionFlows().then(interactionFlows => this.handleInteractionFlowsRetrieved(interactionFlows), this.reportError);
    }, 1000);
  }

  private handleInteractionFlowsRetrieved(interactionFlows: Array<InteractionFlowModel>): void {
    this.nodeDialogInitialised = true;
    this.interactionFlows = [];

    if(interactionFlows && interactionFlows.length > 0){
      interactionFlows.forEach(interactionFlow => {
        if(interactionFlow.subflow){
          this.interactionFlows.push(interactionFlow);
        }
      })
    }

    this.hasEntries = !!this.interactionFlows.length;

    if (this.interactionFlows.length === 1) {
      this.selectedInteractionFlow = this.interactionFlows[0];
      this.selectedInteractionFlow.isSelected = true;
    } else {
      this.preselectInteractionFlowFromNodeData();
    }
  }

  private preselectInteractionFlowFromNodeData(): void {
    if (this.nodeData.properties && this.nodeData.properties.interactionFlowId) {
      let interactionFlowId = this.nodeData.properties.interactionFlowId;

      this.interactionFlows.forEach(interactionFlow => {
        if (interactionFlow.interactionFlowId === interactionFlowId) {
          this.selectedInteractionFlow = interactionFlow;
          interactionFlow.isSelected = true;
        }
      });
    }
  }

  private reportError(msg: any): void {
    logger.warn('Error retrieving Interaction Flows', msg);
  }

  public selectInteractionFlow(selectedInteractionFlow) {
    this.interactionFlows.forEach(interactionFlow => {
      interactionFlow.isSelected = false;
    });

    this.selectedInteractionFlow = selectedInteractionFlow;
    this.selectedInteractionFlow.isSelected = true;
  }

  public viewInteractionFlowClicked(selectedInteractionFlow: InteractionFlowModel): void {
    this.selectInteractionFlow(selectedInteractionFlow);
    const selectedVersion = this.selectedInteractionFlowId === selectedInteractionFlow.interactionFlowId ? this.selectedVersion : null;

    this.dialogService.open({
      viewModel: SubFlowFlowVersionDialog,
      model: {
        flow: this.selectedInteractionFlow,
        selectedVersion,
        isVersioned: this.nodeData.version
      }
    }).whenClosed((response: any) => {
      if (response.wasCancelled) {
        let interactionFlow = this.interactionFlows.find(interactionFlow => interactionFlow.interactionFlowId === this.selectedInteractionFlowId);
        this.selectInteractionFlow(interactionFlow);
      }
      if (response.output && response.output.version !== undefined) {
        this.selectedInteractionFlowId = response.output.interactionFlowId;
        this.selectedVersion = response.output.version;
        this.nodeData.properties.version = response.output.version;
        if(this.selectedVersion <= 0){
          this.selectedVersion = null;
        }
        this.interactionFlowService
          .retrieveInteractionFlow(this.selectedInteractionFlow.interactionFlowId, this.selectedVersion)
          .then(interactionFlow => {
            if(interactionFlow.endNodeNames && interactionFlow.endNodeNames.length > 0) {
              this.endNodeNames = interactionFlow.endNodeNames;
              this.isValid = true
            }
          }, this.reportError)
      }
    });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    if (!this.nodeData.properties) {
      this.nodeData.properties = this.existingProperties;
    }

    this.nodeData.properties.name = 'V' + this.selectedVersion;
    this.nodeData.properties.interactionFlowId = this.selectedInteractionFlow.interactionFlowId;
    this.nodeData.properties.interactionFlowName = this.selectedInteractionFlow.flowName;
    this.nodeData.properties.version = this.selectedVersion;
    this.nodeData.properties.isDefined = true;

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.dialogController.ok(this.nodeData);
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  // Utility Functions

  private mapConnectors(existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let connections: Array<ConnectorModel> = []
    let index = 0;
    this.endNodeNames.forEach(name => {
       let existingConnection = null;

       existingConnections.forEach(_existingConnection => {
         if(_existingConnection.name === name){
           existingConnection = _existingConnection;
         }
       });

       if(!existingConnection){
         connections.push(new ConnectorModel({
           name: name,
           dest: null,
           source: { connectorIndex: index, nodeID: this.nodeData.id },
           customExtensions: null
         }))
       } else {
         existingConnection.source.connectorIndex = index;
         connections.push(existingConnection)
       }
       index++;
    });
    return connections;
  }

  protected startsWithMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value.name.toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

}
