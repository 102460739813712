import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { ZaiForm } from 'zailab.common';
import { WaitingRoomConfigService } from '../waiting-room-configuration-service';
import { AureliaConfiguration } from 'aurelia-configuration';

const logger = LogManager.getLogger('EditAgentSampling');

@autoinject()
export class EditAgentSampling {

  public dialogHeader = "Edit Agent Sampling Config";
  public form: any[][];
  public formData;
  public defaultConfig = {
    enabled: false,
    globalAgentThreshold: 100,
    globalAgentSampleSize: 20,
    globalTaskThreshold: 100,
    globalTaskSampleSize: 20
  };
  public submitting: boolean = false;

  constructor(
    private dialogController: DialogController,
    private waitingRoomConfigService: WaitingRoomConfigService,
    private aureliaConfiguration: AureliaConfiguration
  ) {}


  public async bind(): Promise<void> {
    let agentConfig: {
      enabled: boolean;
      globalAgentThreshold: number;
      globalAgentSampleSize: number;
      globalTaskThreshold: number;
      globalTaskSampleSize: number;
    };
    try {
      agentConfig = await this.waitingRoomConfigService.retrieveAgentSamplingConfig();
    } catch(e) {
      console.warn(e);
    }
    this.initialiseForm(agentConfig);
  }

  private initialiseForm(agentConfig: {
      enabled: boolean;
      globalAgentThreshold: number;
      globalAgentSampleSize: number
      globalTaskThreshold: number;
      globalTaskSampleSize: number
    }): void {
    // @ts-ignore
    const environment = this.aureliaConfiguration.environment;

    let form = new ZaiForm()
      .newField()
      .asSliderToggle()
      .withIdentifier('enabled')
      .withTitle('Enabled', '200px')
      .addNegativeOption('Disabled')
      .addPositiveOption('Enabled')
      .withValue(agentConfig?.enabled || this.defaultConfig.enabled)
      .insertField()

      .newRow()
      .newField()
      .asNumberInput()
      .withIdentifier('globalAgentThreshold')
      .withTitle('Global Agent Threshold', '200px');

    if (environment !== 'prod') {
      form = form.withMinValue(5);
    } else {
      form = form.withMinValue(100);
    }

    form = form.withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.NUMBER }])
      .withValue(agentConfig?.globalAgentThreshold || this.defaultConfig.globalAgentThreshold)
      .insertField()

      .newRow()
      .newField()
      .asNumberInput()
      .withIdentifier('globalAgentSampleSize')
      .withTitle('Global Agent Sample Size', '200px')
      .withMinValue(1)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.NUMBER },
        { validationType: ZaiForm.VALIDATION_TYPES.CUSTOM_SMALLER_THAN_SIBLING, field: 'globalAgentThreshold' }
      ])
      .withValue(agentConfig?.globalAgentSampleSize || this.defaultConfig.globalAgentSampleSize)
      .insertField()

      .newRow()
      .newField()
      .asNumberInput()
      .withIdentifier('globalTaskThreshold')
      .withTitle('Global Task Threshold', '200px');

      if (environment !== 'prod') {
        form = form.withMinValue(5);
      } else {
        form = form.withMinValue(100);
      }

      form.withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.NUMBER }])
        .withValue(agentConfig?.globalTaskThreshold || this.defaultConfig.globalTaskThreshold)
        .insertField()

        .newRow()
        .newField()
        .asNumberInput()
        .withIdentifier('globalTaskSampleSize')
        .withTitle('Global Task Sample Size', '200px')
        .withMinValue(1)
        .withValidation([
          { validationType: ZaiForm.VALIDATION_TYPES.NUMBER },
          { validationType: ZaiForm.VALIDATION_TYPES.CUSTOM_SMALLER_THAN_SIBLING, field: 'globalTaskThreshold' }
        ])
        .withValue(agentConfig?.globalTaskSampleSize || this.defaultConfig.globalTaskSampleSize)
        .insertField()
      
      .finaliseForm(form => {
        this.form = form
      });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    if (this.formData.valid) {
      this.submitting = true;

      this.waitingRoomConfigService
        .updateAgentSamplingConfig(
          this.formData.enabled,
          this.formData.globalAgentThreshold,
          this.formData.globalAgentSampleSize,
          this.formData.globalTaskThreshold,
          this.formData.globalTaskSampleSize
        )
        .then(() => this.dialogController.ok())
        .catch(e => {
          console.warn('Failed to update Agent Sampling Config due to:', e);
          this.submitting = false;
        })
    }
  }

}

