import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('WaitingRoomWidgetModel');

export class WaitingRoomWidgetModel {
  public serviceId: string = null;
  public serviceName: string = null;
  public organisationId: string = null;

  public waitingCalls: any = {};
  public waitingEmails: any = {};
  public waitingSmss: any = {};
  public waitingWebchats: any = {};
  public waitingIMs: any = {};
  public waitingCallbacks: any = {};
  public waitingTickets: any = {};
  public outOfSlaCalls: any = {};
  public outOfSlaEmails: any = {};
  public outOfSlaSmss: any = {};
  public outOfSlaWebchats: any = {};
  public outOfSlaIMs: any = {};
  public outOfSlaCallbacks: any = {};
  public outOfSlaTickets: any = {};
  public longestWaitingCallTimestamp: number = null;
  public longestWaitingCallbackTimestamp: number = null;
  public longestWaitingEmailTimestamp: number = null;
  public longestWaitingSmsTimestamp: number = null;
  public longestWaitingWebchatTimestamp: number = null;
  public longestWaitingIMTimestamp: number = null;
  public longestWaitingTicketTimestamp: number = null;

  constructor(properties: WaitingRoomWidgetModel) {
    Object.assign(this, properties);
  }
}
