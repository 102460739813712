import { LogManager, customElement, bindable } from 'aurelia-framework';
/**/
const logger = LogManager.getLogger('ZConnector');

/**/
@customElement('z-connector')
export class ZConnector {

  @bindable id: string = null;
  @bindable name: string = null;
  @bindable position: string = null;
  @bindable hover: boolean = false;
  @bindable reference: HTMLElement = null;
  @bindable({ attribute: 'hide-name' }) hideName: boolean = true;

  public get displayName(): string {
    if (!!this.name && this.name.length > 12) {
      return `${this.name.substring(0, 12)}...`;
    }
    return this.name;
  }
}