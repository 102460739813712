import { computedFrom, inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
import { AudioService, PushNotificationService } from 'zailab.common';
import {ObserverLocator} from "aurelia-binding";
import {MESSAGE_EVENTS, BootstrapFormRenderer} from 'zailab.common';

const logger = LogManager.getLogger('InputDialog');

@inject(DialogController, EventAggregator, PushNotificationService, ValidationControllerFactory)
export class InputDialog {

  dialogHeader = 'Input';
  inputDialogMessage;
  value;

  constructor(dialogController, EventAggregator, pushNotificationService, validationControllerFactory) {
    this.dialogController = dialogController;
    this.eventAggregator = EventAggregator;
    this.pushNotificationService = pushNotificationService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(inputDialogMessage) {
    this.inputDialogMessage = inputDialogMessage;
    this.pushNotificationService.notify(this.dialogHeader, { body: 'Input Required' });

    this._initValidation();
  }

  _initValidation() {

    ValidationRules
      .customRule('validInput', (value) => {
        if(this.inputDialogMessage.type === 'TEXTBOX'
          && this.inputDialogMessage.validationRegex
          && this.inputDialogMessage.validationRegex.length > 0
          && this.value
          && this.value.length > 0){
          let match = this.value.match(this.inputDialogMessage.validationRegex);
          if(match && match.length > 0){
            return match.indexOf(this.value) >= 0;
          } else {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .ensure('value')
      .satisfiesRule('validInput').withMessage('Your input does not match the required pattern.')
      .on(this);
  }

  accept(value) {
    this.pushNotificationService.close();
    if(value){
      this.value = value;
    }
    this.validation.validate().then(validation => {
      if (!validation.valid) {
        return;
      }
      this.inputDialogMessage.value = this.value;
      this.dialogController.ok({
        accepted: true,
        inputDialogMessage: this.inputDialogMessage
      });
    }, error => {
      logger.info('input Dialog > validation failure', error);
    });

  }

  reject() {
    this.pushNotificationService.close();
    this.dialogController.cancel({
      rejected: true,
      inputDialogMessage: this.inputDialogMessage
    });
  }

  @computedFrom('value')
  get canAccept() {
    return this.value !== undefined && this.value.length > 0
  }

}
