import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 */
import {BootstrapFormRenderer} from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('ViewSearchDialog');
/*
 */
@inject(DialogController, ValidationControllerFactory)
export class ViewSearchDialog {

  fromDate = '';
  toDate = '';
  formName = '';
  firstName = '';
  surname = '';

  constructor(dialogController, validationControllerFactory) {
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(searchParams) {

    if (searchParams) {

      this.fromDate = searchParams.fromDateInput;
      this.toDate = searchParams.toDateInput;
      this.formName = searchParams.formName;
      this.firstName = searchParams.firstName;
      this.surname = searchParams.surname;
    }

    ValidationRules
      .ensure('toDate')
      .satisfies(() => {
        if(this.fromDate && this.toDate) {
          return parseInt(this.fromDate.split('-').join('')) <= parseInt(this.toDate.split('-').join(''))
        }
        return true;
      }).withMessage('The `From Date` cannot be after the `To Date`.')
      .on(this);
  }

  /**
   * closes the aurelia dialog
   * @returns criteria
   */
  search() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }

      let fromDate = '',
        toDate = '';

      if(this.fromDate) {

        fromDate = this.fromDate.split('-');
        fromDate = new Date(parseInt(fromDate[0], 10), parseInt(fromDate[1], 10) - 1, parseInt(fromDate[2]), 10, 0, 0, 0);
        fromDate.setHours(0, 0, 0, 0);
        fromDate = fromDate.getTime();
      }

      if(this.toDate) {

        toDate = this.toDate.split('-');
        toDate = new Date(parseInt(toDate[0], 10), parseInt(toDate[1], 10) - 1, parseInt(toDate[2]), 10, 0, 0, 0);
        toDate.setHours(23, 59, 59, 0);
        toDate = toDate.getTime();
      }

      let criteria = {
        fromDateInput: this.fromDate,
        toDateInput: this.toDate,
        fromDate: fromDate,
        toDate: toDate,
        channel: '',
        formName: this.formName,
        firstName: this.firstName,
        surname: this.surname,
      };

      this.dialogController.ok(criteria);

    }, () => {
    });
  }

  /**
   * closes the aurelia dialog
   */
  cancel() {
    this.dialogController.cancel();
  }
}