import { LogManager } from "aurelia-framework";


const logger = LogManager.getLogger('ZaiFileLoader');

export class ZaiFileLoader {
  // this is needed to inject all images for build
  // @ts-ignore
  public docs = this.importAll(require.context('../docs', false, /\.(pdf)$/));
  // @ts-ignore
  public rootImages = this.importAll(require.context('../img', false, /\.(png|jpe?g|gif|svg)$/));
  // @ts-ignore
  public svgImages = this.importAll(require.context('../img/svg', false, /\.(png|jpe?g|gif|svg)$/));
  
  private imageKeys: string[] = [];

  private importAll(r: any) {
    let images = {};
    r.keys().forEach((key) => (images[key] = r(key)));
    return images;
  }

  public getFile(fileName: string): string {
    if (fileName) {
      fileName = fileName.replace('.svg', '');
    }
    if (this.imageKeys.length === 0) {
      this.imageKeys = [
        ...Object.keys(this.docs),
        ...Object.keys(this.rootImages),
        ...Object.keys(this.svgImages)
      ];
    }

    let matchedImage = this.imageKeys.find(key => {
      return key.indexOf('/' + fileName + '.svg') >= 0 ||
      key.indexOf('/' + fileName + '.png') >= 0 ||
      key.indexOf('/' + fileName + '.jpg') >= 0 ||
      key.indexOf('/' + fileName + '.pdf') >= 0
    });
    if (matchedImage) {
      if (this.rootImages[matchedImage]) {
        return this.rootImages[matchedImage];
      } else if (this.svgImages[matchedImage]) {
        return this.svgImages[matchedImage];
      } else if (this.docs[matchedImage]) {
        return this.docs[matchedImage].default;
      }
    } else {
      logger.warn(' > Failed to load image ', fileName);
    }
  }

  public log(): void {
    console.log(' ::>> check my images >>>>> ', {
      rootImages: this.rootImages,
      svgImages: this.svgImages,
      docs: this.docs
    });
  }
}
