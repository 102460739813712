import { LogManager } from "aurelia-framework";
import { LineModel } from "components/atoms/line/models/line-model";

const logger = LogManager.getLogger('ZGridBasicModel');

export class ZGridBasicModel {

  private numberOfColumns = 13;
  private numberOfRows = 5;
  public cellSize = 147;
  public state: string;
  public nodes: ZGridNodesItem[];
  public columns: ZGridNodesColumn[];

  public loadGrid(gridConnections?: ZGridNodesItemConnector[]): void {
    this.makeConnectors(gridConnections);
    this.makeGrid();
  }

  private makeConnectors(gridConnections: ZGridNodesItemConnector[]) {
    if (gridConnections && gridConnections.length) {
      for (const node of this.nodes) {
        node.connectors = this.mapNodeConnectors(node.id, gridConnections);
      }
    }
  }

  private getDimensions() {
    let maxX = this.numberOfColumns;
    let maxY = this.numberOfRows;
    for (const node of this.nodes) {
      if (node.posX + 1 > maxX) {
        maxX = node.posX + 1;
      }
      const length = node.connectors.length ? node.connectors.length : 1;
      if (node.posY + length > maxY) {
        maxY = node.posY + length;
      }
    }
    return {
      columns: maxX, rows: maxY
    };
  }

  private makeGrid(): void {
    const dims = this.getDimensions();
    this.columns = [];
    for (let x = 0; x <= dims.columns; x++) {
      const cells = [];
      for (let y = 0; y <= dims.rows; y++) {
        let node = this.getNodeAt(x, y);
        if (!node) {
          node = new ZGridNodesPlaceholder(x, y);
        }
        cells.push(node);
      }
      this.columns.push({ cells });
    }
  }

  private mapNodeConnectors(nodeId: string, gridConnections: ZGridNodesItemConnector[]) {
    const connectors = gridConnections.filter((conn) => conn.sourceNodeId === nodeId).sort((c1, c2) => c1.index - c2.index);
    const nodeConnections = connectors.map((conn) => {
      const id = conn.id;
      const name = conn.name;
      const index = conn.index;
      const sourceNodeId = conn.sourceNodeId;
      const targetNodeId = conn.targetNodeId;
      const coordinates = this.makeCoordinates(index, sourceNodeId, targetNodeId);
      return new ZGridNodesConnection(id, name, index, sourceNodeId, targetNodeId, coordinates);
    });
    return nodeConnections;
  }

  private makeCoordinates(index: number, sourceNodeId: string, targetNodeId: string): LineModel {
    const width = this.cellSize;
    const sourceNode = this.getNode(sourceNodeId);
    const targetNode = this.getNode(targetNodeId);
    const coordinates = new LineModel();
    coordinates.startX = width - 20;
    coordinates.startY = (index * width) + (width / 2) - 5;
    coordinates.endX = !targetNode ? coordinates.startX + 5 : ((targetNode.posX - sourceNode.posX) * width) + 15;
    coordinates.endY = !targetNode ? coordinates.startY : ((targetNode.posY - sourceNode.posY) * width) + (width / 2) - 5;
    return coordinates;
  }

  public getNode(id: string): ZGridNodesItem {
    if (!this.nodes || !this.nodes.length) {
      return null;
    }
    return this.nodes.find((node) => node.id === id);
  }

  public getSourceConnectors(id: string): ZGridNodesItemConnector[] {
    const sourceConnectors = [];
    for (const node of this.nodes) {
      if (!node.connectors || !node.connectors.length) {
        continue;
      }
      for (const conn of node.connectors) {
        if (conn.targetNodeId === id) {
          sourceConnectors.push(conn);
        }
      }
    }
    return sourceConnectors;
  }

  public getNodeAt(x: number, y: number): ZGridNodesItem {
    if (!this.nodes || !this.nodes.length) {
      return null;
    }
    return this.nodes.find((node) => node.posX === x && node.posY === y);
  }

  public removeNodeAt(x: number, y: number): void {
    if (!this.nodes || !this.nodes.length) {
      return null;
    }
    const nodeIndex = this.nodes.findIndex((node) => node.posX === x && node.posY === y);
    this.nodes.splice(nodeIndex, 1);
  }
}

class ZGridNodesConnection implements ZGridNodesItemConnector {

  public connected: boolean;
  public selected: boolean;
  public dragging: boolean;

  constructor(
    public id: string,
    public name: string,
    public index: number,
    public sourceNodeId: string,
    public targetNodeId: string,
    public coordinates: LineModel
  ) {
    this.connected = targetNodeId ? true : false;
  }
}

class ZGridNodesPlaceholder implements ZGridNodesItem {

  visible: boolean;
  defined: boolean;
  startNode: boolean;
  connectors: ZGridNodesItemConnector[];
  name: string;
  id: string;
  dragging: boolean;
  draggingConnector: boolean;
  draggingConnectorIndex: number;

  constructor(
    public posX: number,
    public posY: number
  ) { }
}

export interface ZGridNodesItem {

  posX: number;
  posY: number;
  visible: boolean;
  defined: boolean;
  startNode: boolean;
  connectors: ZGridNodesItemConnector[];
  name: string;
  id: string;
  dragging: boolean;
  draggingConnector: boolean;
  draggingConnectorIndex: number;
}

export interface ZGridNodesItemConnector {

  coordinates: LineModel;
  name: string;
  id: string;
  index: number;
  connected: boolean;
  selected: boolean;
  dragging: boolean;
  sourceNodeId: string;
  targetNodeId: string;
}

export interface ZGridNodesColumn {

  cells: ZGridNodesItem[];
}
