import {autoinject, LogManager, observable} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

import { SearchTools } from 'zailab.common';
import { TeamsService } from '../../../../hud/dashboard/qualityassessor/teams/teams.service';
import MemberPropertiesModel from '../../member/members/member-properties-model';
import { ReminderService } from '../../conversation/reminder/reminder-service';

const logger = LogManager.getLogger('SelectAgentDialog');

@autoinject
export class SelectAgentDialog {

  private autoCloseTimer: any;
  private jsSearchInput: HTMLElement;
  private displaySearchResults: boolean = false;
  private members: any[] = [];
  private teamId: string = null;
  private reminderId: string = null;
  private selectedMember: any;
  private searchCriteria: string;

  constructor(
    private dialogController: DialogController,
    private teamsService: TeamsService,
    private reminderService: ReminderService
  ) {}

  activate(data: { teamId: string, reminderId: string }): void {
    this.teamId = data.teamId;
    this.reminderId = data.reminderId;
    this.retrieveMembers()
  }

  cancel(): void {
    this.dialogController.cancel();
  }

  public retrieveMembers(): void {
    if (!this.teamId) {
      return;
    }

    this.teamsService
      .retrieveMembers(this.teamId, "AGENT", undefined)
      .then(members => {
        this.members = members;
      })
      .catch(error => {
        logger.info(' Failed to retrieve your team`s members > error = ', error);
      });
  }

  private done(): void {
    if(!this.selectedMember || !this.selectedMember.memberId || this.selectedMember.memberId === ''){
      return
    }
    this.forwardToAgent(this.selectedMember.memberId);
  }

  public forwardToAgent(memberId: string): void {
    this.reminderService
      .sendReminderToAgent(this.reminderId, memberId).then(
      members => {
        this.dialogController.ok(this.selectedMember);
      },
      error => {
        logger.info(' Failed to send routing request > error = ', error);
      }
    );
  }

  private startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = window.setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      this.displaySearchResults = false;
    }, _delay);
  }

  private preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  private toggleSearchResults(event: Event): void {
    event.stopPropagation();
    this.displaySearchResults = !this.displaySearchResults;
  }

  private selectMember(event: Event, member: any): void {
    event.stopPropagation();
    this.searchCriteria = member.fullName;
    this.displaySearchResults = false;
    this.selectedMember = member
  }

  protected partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

}

