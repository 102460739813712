import { autoinject, LogManager, customElement, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore } from 'zailab.common';
import { ChannelService } from '../../channel-service';
import { InteractionModel } from '../../../interaction-model';
import { v4 as uuidv4 } from 'uuid';
import {CONTROLLER_ACTIONS, LOADER_ACTIONS} from '../../../../contact/contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('Note');

@customElement('z-note')
@autoinject()
export class Note {

  @bindable private contactid;
  @bindable private contact;
  @bindable private conversationid;
  private message: string;
  private confirmDelete: boolean = false;

  constructor(private eventAggregator: EventAggregator, private channelService: ChannelService, private sessionStore: SessionStore) {
    this.eventAggregator = eventAggregator;
    this.channelService = channelService;
    this.sessionStore = sessionStore;
  }

  private showMediumChannel(): void {
    if (this.contact.channelViewStrategy.size === 'small') {
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    }
  }

  private discardNote(): void {
    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
    this.resetValues();
    this.confirmDelete = !this.confirmDelete;
  }

  private saveNote(): void {

    let conversation = this.contact.getSelectedConversation();
    if(conversation && conversation.status.toLowerCase() !== 'pending' && conversation.status.toLowerCase() !== 'new') {
      conversation = null;
    }

    const user = this.sessionStore.get.user;
    let note = new InteractionModel({
      contactId: this.contact.contactId,
      noteId: uuidv4(),
      conversationId: conversation ? conversation.conversationId : null,
      channel: 'NOTE',
      message: this.message,
      memberId: user.memberId,
      firstName: user.firstName,
      surname: user.surname
    });

    let noteDeepCopy = JSON.parse(JSON.stringify(note));

    this.eventAggregator.publish(CONTROLLER_ACTIONS.ADD_NOTE, noteDeepCopy);
    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
    this.eventAggregator.publish(LOADER_ACTIONS.CHANGE_LOADER_INTERACTION_LIST, { contactId: this.contact.contactId, status: true });

    this.resetValues();
  }

  private toggleConfirmDelete(): void {
    this.confirmDelete = !this.confirmDelete;
  }

  private resetValues(): void {
    this.message = '';
  }

  public detached(): void {
  }
}
