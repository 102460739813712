import { inject, LogManager } from 'aurelia-framework';
/*
 * */
const logger = LogManager.getLogger('ScrollToBottomCustomAttribute');
/*
 * */
@inject(Element)
export class ScrollToBottomCustomAttribute {
  constructor(element) {
    this.element = element;
  }

  valueChanged(value) {
    if (value) {
      this.scroll();
    }
  }

  scroll() {
    this.element.scrollTop = this.element.scrollHeight;

    if (!this.isAtBottom()) {
      setTimeout(() => {
        this.scroll();
      }, 50);
    }
  }

  isAtBottom() {
    return this.element.scrollTop + this.element.clientHeight === this.element.scrollHeight;
  }
}
