import { LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('Sites Widget');

export class DashboardWidget {

  private $parent: any;
  public router: Router
  public showMenu: boolean;
  public autoCloseTimer: number;
  public deferAutoClose: boolean;
  public infoHasHover: boolean;
  public showWidgetSetup: boolean;
  public contentHasHover: boolean;

  protected navigate(event: string, route: string): void {
    this[event] && this[event](route);
  }
  
  protected view(route: string): void {
    this.$parent.router.navigate(route);
  }

  protected add(route: string): void {
    this.$parent.router.navigate(`${route}?add=true`);
  }

  protected startAutoCloseMenu(): void {
    if (this.showMenu && this.autoCloseTimer === 0) {
      this.autoCloseTimer = window.setInterval(() => {
        this.closeMenu();
      }, 500);
    }
  }

  protected closeMenu(): void {
    if (!this.deferAutoClose) {
      this.showMenu = false;
      window.clearInterval(this.autoCloseTimer);
      this.autoCloseTimer = 0;
    }
  }

  protected preventAutoClose(): void {
    this.deferAutoClose = true;
  }

  protected  allowAutoClose(): void {
    this.deferAutoClose = false;
  }

  protected toggle(): void {
    this.showMenu = !this.showMenu;
  }

  protected showContentHover(): void {
    if (this.showWidgetSetup) {
      return;
    }
    this.contentHasHover = true;
  }

  protected hideContentHover(): void {
    if (this.showWidgetSetup) {
      return;
    }
    this.contentHasHover = false;
  }

  protected showInfo(): void {
    this.infoHasHover = true;
  }

  protected hideInfo(): void {
    this.infoHasHover = false;
  }
}