import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { List } from '../../../../abstract/list/list-builder';
import { BusinessPartner } from '../../businesspartner/business-partner-model';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenter } from '../../contactcenter/contact-center-model';
import { ContactCenterService } from '../../contactcenter/contact-center-service';
import { TransferList } from '../transfer-list-model';
import { TransferListService } from '../transfer-list-service';

const logger = LogManager.getLogger('TransferListList');

@autoinject()
export class TransferListList {

  private itemList: List;
  private list: TransferList[];
  private businessPartners: BusinessPartner[];
  private contactCenters: ContactCenter[];

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator,
    private transferListService: TransferListService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService
  ) { }

  public attached(): void {
    this.retrieveList();
  }

  private retrieveList(): void {
    this.eventAggregator.publish('app:loader:show');
    this.businessPartnerService.findAll().then((businessPartners) => this.businessPartners = businessPartners)
      .then(() => {

        this.contactCenterService.findAll().then((contactCenters) => this.contactCenters = contactCenters)
          .then(() => {

            this.transferListService.findAll()
              .then((list) => {
                this.list = list || [];
                this.list.forEach((item) => {
                  if (item.businessPartner) {
                    const businessPartner = this.businessPartners.find((bp) => bp.id === item.businessPartner);
                    item.descriptionTwo = businessPartner.name;
                  }
                  if (item.contactCenter) {
                    const contactCenter = this.contactCenters.find((cc) => cc.id === item.contactCenter);
                    item.descriptionTwo += `, ${contactCenter.name}`;
                  }
                })
                this.setupListConfig();
              })
              .catch(error => this.handleFailure(error));
          });
      });
  }

  private setupListConfig(): void {
    this.itemList = List.Builder()
      .items(this.list)
      .icon('icon')
      .displayId('name')
      .description('description')
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .build();
    this.itemList.select = true;
    this.itemList.autoSelect = false;
    this.eventAggregator.publish('app:loader:hide');
  }

  private handleFailure(error: any): void {
    logger.error('retrieve list > error = ', error);
  }

  public add(): void {
    this.router.navigate('add');
  }

  public select(event: any): void {
    this.router.navigate(`edit/${event.detail.item.id}`);
  }

  public delete(event: any): void {
    let deletesLeft: number = event.detail.length;
    event.detail.forEach((item: TransferList) => {
      this.transferListService.delete(item.id)
        .then(() => this.handleDeleted(item, --deletesLeft))
        .catch((error) => logger.error(`delete :: ${item.id} :: error=`, error));
    });
  }

  private handleDeleted(item: TransferList, deletesLeft: number): void {
    this.list.splice(this.list.indexOf(item), 1);
    if (deletesLeft === 0) {
      this.setupListConfig();
    }
  }
}