/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZCardBody');
/*
 */
@customElement('z-card-body')
export class ZCardBody {
  @bindable hashover = false;
  @bindable short = false;
  @bindable padding;
  @bindable({attribute: 'below-subheader'}) belowSubheader;
}
