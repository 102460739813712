import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { AlertMessage } from '../alert-model';
import { AlertService } from '../alert-service';
import { AlertDialog } from '../dialog/alert-dialog';

const logger = LogManager.getLogger('AlertMessages');

@autoinject()
export class AlertMessages {

  public list: AlertMessage[];

  constructor(
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private alertService: AlertService
  ) { }

  public attached(): void {
    this.alertService.onAlertMessage(() => this.retrieveList());
    this.retrieveList();
  }

  private retrieveList(): void {
    this.eventAggregator.publish('app:loader:show');
    this.alertService.findAllMessagesForToday()
      .then((list) => {
        this.list = list || [];
        this.eventAggregator.publish('app:loader:hide');
      })
      .catch(error => this.handleFailure(error));
  }

  private handleFailure(error: any): void {
    logger.error('retrieve list > error = ', error);
  }

  public select(event: any): void {
    this.dialogService.open({ viewModel: AlertDialog, model: event.detail.item }).whenClosed(dialog => {
      if (dialog.wasCancelled) {
        return;
      }
      this.retrieveList();
    });
  }
}