import {autoinject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {SessionStore} from 'zailab.common';

import {LiveDashboardService} from '../live-dashboard-service';
import {AbstractLiveDashboardDrillDown} from '../abstract-live-dashboard-drilldown';

const logger = LogManager.getLogger('TotalNotHandled');

@autoinject
export class NotHandled extends AbstractLiveDashboardDrillDown {

  constructor(protected liveDashboardService: LiveDashboardService, protected eventAggregator: EventAggregator, protected sessionStore: SessionStore) {
    super(eventAggregator, liveDashboardService, sessionStore);
    this.filters = ['Callbacks Requested', 'Abandoned', 'Voicemail', 'Forwarded', 'Missed', 'Cleared'];
    this.resource = 'total-not-handled';
  }
}