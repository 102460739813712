import { ConfirmDialog } from './../../../../organisation/conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';

import { SortTools, ArrayTools, SessionStore } from 'zailab.common';
import { LiveDashboardService } from '../live-dashboard-service';
import { WaitingInteractionsModel } from './waiting-interactions-model';
import { ServiceWithTaskTemplatesModel } from '../models/service-with-task-template-model';
import { TaskTemplateModel } from '../models/task-template-model';
import { SelectMemberDialog } from './selectMember/select-member-dialog';
import { FeatureFlagService } from '../../../../featureflags/feature-flag-service';

// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('Waiting');

interface IParams {
  serviceId: string;
  workTypeId: string;
  outOfSLA?: string;
  longestWaiting?: string;
}

interface IStateStorage {
  waitingInteractions: {
    requestTimer: number;
  };
}

@autoinject
export class Waiting {
  public channels = [
    { type: '', name: 'All Channels', disabled: false, isSelected: true },
    { type: 'Inbound Call', name: 'Call', disabled: false },
    { type: 'Email', name: 'Email', disabled: false },
    { type: 'SMS', name: 'SMS', disabled: false },
    { type: 'Chat', name: 'Chat', disabled: false },
    { type: 'Instant Message', name: 'Instant Message', disabled: false },
    { type: 'Ticket', name: 'Ticket', disabled: false },
    { type: 'Callback', name: 'Callback', disabled: false },
  ];

  public slaValues = [
    { type: '', name: 'All Interactions', isSelected: true },
    { type: true, name: 'Out Of SLA' },
    { type: false, name: 'In SLA' },
  ];

  private defaultFilterParams: any = {
    channel: '',
    outOfSLA: '',
  };
  public searchTermInput: HTMLInputElement;
  protected isSearching: boolean = false;
  protected searchTerm: string = '';

  protected serviceList: ServiceWithTaskTemplatesModel[] = [];
  protected selectedService: any = null;
  protected selectedServiceId: string = '';
  protected workTypesList: TaskTemplateModel[] = [];
  protected selectedWorkType: TaskTemplateModel = null;
  protected selectedWorkTypeId: string = '';

  private selectedChannel: { type: string; name: string } = this.channels[0];
  private selectedSLAValue:
    | { type: string; name: string }
    | { type: boolean; name: string } = this.slaValues[0];
  private previousSelectedChannel: { type: string; name: string };
  private previousSelectedSLAValue:
    | { type: string; name: string }
    | { type: boolean; name: string };
  private selectedCallback: boolean = false;
  private subjectFilterAlphabetically: any = true;

  protected serviceDropdownVisible: boolean = false;
  protected workTypeDropdownVisible: boolean = false;
  protected channelDropdownVisible: boolean = false;
  protected slaDropdownVisible: boolean = false;
  private isDirectRoutingEnabled: boolean = false;

  private waitingInteractions: Array<WaitingInteractionsModel> = [];

  private stateStorageKey = 'zai_state';
  private defaultRequestTimer = 30;
  private currentRequestTimer;
  public timerDropdownVisible: boolean = false;
  private signallerInterval: number;
  public lastRequestTimestamp: number;
  public requestTimerValues: { value: number; selected?: boolean }[] = [
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
    { value: 30 },
    { value: 35 },
    { value: 40 },
    { value: 45 },
    { value: 50 },
    { value: 120 },
  ];
  public enableSelectors: boolean = false;

  public tableHeaders: any = [
    { property: '', description: '' },
    { property: 'channel', description: 'Channel', iconOnly: true },
    { property: 'source', description: 'From', iconOnly: true },
    { property: 'subject', description: 'Subject', filterable: true },
    { property: 'contactName', description: 'Contact', iconOnly: true },
    { property: 'workType', description: 'Work Type' },
    {
      property: 'formattedDuration',
      description: 'Wait Time',
      filterable: true,
    },
    { property: 'businessValue', description: 'Business Value' },
    { property: 'outOfSLA', description: 'Out of SLA' },
    { property: 'routing', description: 'Route To Agent' },
  ];

  public filterableHeader = {
    formattedDuration: false,
    subject: null,
  };

  constructor(
    protected liveDashboardService: LiveDashboardService,
    protected eventAggregator: EventAggregator,
    private bindingSignaler: BindingSignaler,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService,
    private sessionStore: SessionStore
  ) {}

  protected activate(params: IParams): void {
    this.selectedServiceId = params.serviceId;
    this.selectedWorkTypeId = params.workTypeId;
    this.retrieveServicesWithWorkTypes();
    this.checkIfDirectRoutingIsEnabled();
    const serviceId: string = this.selectedService
      ? this.selectedService.serviceId
      : null;
    const workTypeId: string = this.selectedWorkType
      ? this.selectedWorkType.id
      : null;
    this.retrieveWaitingInteractions(serviceId, workTypeId);

    const state = this.getStateFromStorage();
    if (state && state.waitingInteractions) {
      this.currentRequestTimer =
        state.waitingInteractions.requestTimer || this.defaultRequestTimer;
    } else {
      this.currentRequestTimer = this.defaultRequestTimer;
    }
    this.setupSignaller();
  }

  private retrieveWaitingInteractions(serviceId: any, workTypeId: any): void {
    this.eventAggregator.publish('app:loader:show');

    this.previousSelectedChannel = this.selectedChannel;
    this.previousSelectedSLAValue = this.selectedSLAValue;
    this.defaultFilterParams.channel = this.selectedChannel;
    this.defaultFilterParams.outOfSLA = this.selectedSLAValue;
    this.selectedCallback = this.defaultFilterParams.channel === 'Callback';

    if (this.selectedCallback) {
      this.defaultFilterParams.channel = '';
    }

    this.lastRequestTimestamp = new moment().add(
      'seconds',
      this.currentRequestTimer
    );

    const interactionIds = this.waitingInteractions
      .filter((interaction: any) => {
        return interaction.enableForDeletion === true;
      })
      .map((interaction: any) => {
        return interaction.interactionId;
      });

    this.liveDashboardService
      .retrieveWaitingInteractions(
        serviceId,
        workTypeId,
        this.selectedChannel.type,
        this.selectedSLAValue.type,
        this.selectedCallback
      )
      .then(
        (response) => {
          this.lastRequestTimestamp = new moment().add(
            'seconds',
            this.currentRequestTimer
          );
          response = response.sort(SortTools.compareBy('startTimestamp'));
          interactionIds.forEach((interactionId) => {
            const interaction = response.find(
              (interaction) => interaction.interactionId === interactionId
            );
            if (interaction) {
              interaction.enableForDeletion = true;
            }
          });
          this.waitingInteractions = [...response];
          this.eventAggregator.publish('app:loader:hide');
          this.enableSelectors = true;
          this.setupSignaller();
        },
        (error) => {
          this.waitingInteractions = [];
          this.eventAggregator.publish('app:loader:hide');
          this.enableSelectors = true;
        }
      );
    this.bindingSignaler.signal('waiting-interactions');
  }

  private retrieveServicesWithWorkTypes(): void {
    this.eventAggregator.publish('app:loader:show');
    this.liveDashboardService.retrieveMyServices(this.memberId).then(
      (response) => {
        this.eventAggregator.publish('app:loader:hide');
        this.serviceList = response.sort(SortTools.compareBy('serviceName'));

        if (this.selectedServiceId) {
          this.setupDefaultSelectedService(this.serviceList);
          return;
        }
        this.selectService(this.serviceList[0]);
      },
      (error) => {
        this.eventAggregator.publish('app:loader:hide');
        this.serviceList = [];
      }
    );
  }

  protected setupDefaultSelectedService(
    serviceList: ServiceWithTaskTemplatesModel[]
  ): void {
    this.selectedService = serviceList.find((service) => {
      return service.serviceId === this.selectedServiceId;
    });
    this.selectService(this.selectedService);
  }

  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: number): void {
    this.currentRequestTimer = value;
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        parsedState.waitingInteractions.requestTimer = value;
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch (e) {}
    } else {
      localStorage.setItem(
        this.stateStorageKey,
        JSON.stringify({ waitingInteractions: { requestTimer: value } })
      );
    }
    this.timerDropdownVisible = false;

    const serviceId = this.selectedService
      ? this.selectedService.serviceId
      : '';
    const workTypeId = this.selectedWorkType ? this.selectedWorkType.id : '';
    this.retrieveWaitingInteractions(serviceId, workTypeId);
  }

  public refresh(): void {
    this.setupSignaller();
    const serviceId = this.selectedService
      ? this.selectedService.serviceId
      : '';
    const workTypeId = this.selectedWorkType ? this.selectedWorkType.id : '';
    this.retrieveWaitingInteractions(serviceId, workTypeId);
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (
        moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0
      ) {
        this.lastRequestTimestamp = new moment().add(
          'seconds',
          this.currentRequestTimer
        );

        const serviceId = this.selectedService
          ? this.selectedService.serviceId
          : '';
        const workTypeId = this.selectedWorkType
          ? this.selectedWorkType.id
          : '';
        this.retrieveWaitingInteractions(serviceId, workTypeId);
      }
    }, signal);
  }

  private getStateFromStorage(): IStateStorage {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);
      return parsedState;
    } catch (e) {
      return null;
    }
  }

  public selectChannel(channel: any): void {
    this.channels.forEach((_channel) => (_channel.isSelected = false));
    channel.isSelected = true;
    this.selectedChannel = channel;
    this.channelDropdownVisible = false;

    this.waitingInteractions = [];

    const serviceId = this.selectedService
      ? this.selectedService.serviceId
      : '';
    const workTypeId = this.selectedWorkType ? this.selectedWorkType.id : '';
    this.retrieveWaitingInteractions(serviceId, workTypeId);
  }

  public selectSLA(slaOption: any): void {
    this.slaValues.forEach((sla) => (sla.isSelected = false));
    slaOption.isSelected = true;
    this.selectedSLAValue = slaOption;
    this.slaDropdownVisible = false;

    this.waitingInteractions = [];

    const serviceId = this.selectedService
      ? this.selectedService.serviceId
      : '';
    const workTypeId = this.selectedWorkType ? this.selectedWorkType.id : '';
    this.retrieveWaitingInteractions(serviceId, workTypeId);
  }

  public selectService(service: any): void {
    this.selectedService = {
      serviceId: service.serviceId,
      serviceName: service.serviceName,
      taskTemplates:
        service.taskTemplates && service.taskTemplates.length > 0
          ? service.taskTemplates
          : [],
    };

    for (let service of this.serviceList) {
      service.isSelected = false;
      service.isAdded = false;
      service.isSelected = service.serviceId === this.selectedService.serviceId;
    }

    this.setupWorkTypes(this.selectedService);
    this.serviceDropdownVisible = false;
    this.workTypeDropdownVisible = false;
  }

  private selectWorkType(workType: any): void {
    if (!workType) {
      return;
    }
    this.selectedWorkType = { ...workType };
    for (let workType of this.workTypesList) {
      workType.isSelected = this.selectedWorkType
        ? workType.id === this.selectedWorkType.id
        : false;
    }
    this.retrieveWaitingInteractions(
      this.selectedService.serviceId,
      this.selectedWorkType ? this.selectedWorkType.id : null
    );
    this.serviceDropdownVisible = false;
    this.workTypeDropdownVisible = false;
  }

  protected setupWorkTypes(
    selectedService: ServiceWithTaskTemplatesModel
  ): void {
    if (selectedService.taskTemplates.length === 0) {
      if (this.selectedWorkType) {
        this.selectedWorkType.id = null;
        this.selectedWorkType.name = null;
        this.selectedWorkType.isSelected = false;
      }
      this.workTypesList = [];
      this.retrieveWaitingInteractions(this.selectedService.serviceId, null);
    } else {
      this.workTypesList = selectedService.taskTemplates.sort(
        SortTools.compareBy('name')
      );

      let defaultTaskTemplate = {
        id: null,
        name: 'All Work Types',
        isSelected: false,
      };

      let allWorkTypes: TaskTemplateModel = new TaskTemplateModel(
        defaultTaskTemplate
      );

      this.workTypesList.forEach((workType, index) => {
        workType.isSelected = false;
        if (!workType.id && workType.name === 'All Work Types') {
          this.workTypesList.splice(index, 1);
        }
      });

      this.workTypesList.unshift(allWorkTypes);
      if (!this.selectedWorkTypeId) {
        this.selectWorkType(selectedService.taskTemplates[0]);
        return;
      }

      this.selectedWorkType = this.workTypesList.filter((workType) => {
        if (workType.id === this.selectedWorkTypeId) {
          workType.isSelected = true;
          return true;
        }
        return false;
      })[0];
      this.retrieveWaitingInteractions(
        this.selectedService.serviceId,
        this.selectedWorkType.id || null
      );
    }
  }

  public showChannelDropdown(): void {
    this.channelDropdownVisible = !this.channelDropdownVisible;
  }

  public showSLADropdown(): void {
    this.slaDropdownVisible = !this.slaDropdownVisible;
  }

  public showServiceDropdown(): void {
    this.serviceDropdownVisible = !this.serviceDropdownVisible;
  }

  public showWorkTypeDropdown(): void {
    this.workTypeDropdownVisible = !this.workTypeDropdownVisible;
  }

  protected toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (this.isSearching) {
      this.focusOnSearchInput();
    } else {
      this.searchTerm = '';
    }
  }

  private focusOnSearchInput(): void {
    const element = document.getElementById('searchTermInput');
    if (element) {
      element.focus();
    } else {
      setTimeout(() => {
        this.focusOnSearchInput();
      }, 5);
    }
  }

  public filter(header: { property: string; filterable: boolean }): void {
    if (!header || !header.filterable) {
      return;
    }
    const formatKey =
      header.property === 'formattedDuration'
        ? 'startTimestamp'
        : header.property;
    const config = this.filterableHeader;
    const interactions =
      formatKey === 'subject'
        ? this.sort(this.waitingInteractions, formatKey)
        : ArrayTools.sort(this.waitingInteractions, formatKey);

    if (config[header.property]) {
      this.waitingInteractions = [...interactions];
    } else {
      const reversedInteractionsList = [...interactions].reverse();
      this.waitingInteractions = [...reversedInteractionsList];
    }
    Object.keys(this.filterableHeader).forEach(
      (key) =>
        (this.filterableHeader[key] =
          key === header.property ? !this.filterableHeader[key] : null)
    );
  }

  private sort(list: any[], key: string): any {
    return list.sort((a: string, b: string): any => {
      return (a[key] || '|||')
        .toUpperCase()
        .localeCompare((b[key] || '|||').toUpperCase());
    });
  }

  private resetSearchFilter(): void {
    this.selectedChannel = null;
    this.selectedSLAValue = null;
  }

  public selectMember(interactionId: string): void {
    this.dialogService.open({
      viewModel: SelectMemberDialog,
      model: interactionId,
    });
  }

  public confirmDelete(): void {
    const interactions = this.waitingInteractions.filter((interaction: any) => {
      return (
        interaction.enableForDeletion === true &&
        this.filterContent(this.searchTerm, interaction)
      );
    });

    let message;
    if (interactions.length === 0) {
      return;
    } else if (interactions.length === 1) {
      message = `
      This will remove the email from
      <span class="label medium blue-highlight-darkest">
        ${interactions[0].source}
      </span><br/>
      on worktype
      <span class="label medium blue-highlight-darkest">
        ${interactions[0].workType}.
      </span>`;
    } else {
      message = `There are ${interactions.length} email(s) in your selection. Are you sure you want to delete these emails?`;
    }

    this.dialogService
      .open({ viewModel: ConfirmDialog, model: { message } })
      .whenClosed((dialog) => {
        if (!dialog.wasCancelled) {
          const interactionIds = interactions.map((interaction: any) => {
            return interaction.interactionId;
          });
          this.removeEmailFromWaitingRoom(interactionIds);
        }
      });
  }

  public selectAll(): void {
    this.waitingInteractions.forEach((interaction: any) => {
      interaction.enableForDeletion = true;
    });
  }

  public clearSelection(): void {
    this.waitingInteractions.forEach((interaction: any) => {
      interaction.enableForDeletion = false;
    });
  }

  private removeEmailFromWaitingRoom(interactionIds: string[]): void {
    this.liveDashboardService
      .removeAllEmailsFromWaitingRoom(interactionIds)
      .then(() => this.clearSelection())
      .catch((e) => {
        logger.warn('Failed to remove email from waiting room.');
      });
  }

  async checkIfDirectRoutingIsEnabled(): Promise<void> {
    this.isDirectRoutingEnabled = await this.featureFlagService.isEnabled(
      'directRouting'
    );
  }

  private get memberId(): string {
    return this.sessionStore.get.user.memberId;
  }
  public filterContent(searchExpression: string, value): boolean {
    if (!searchExpression) {
      return true;
    }
    if (!value) {
      return false;
    }

    const match1 =
      (value.source || '')
        .toLowerCase()
        .indexOf(searchExpression.toLowerCase()) >= 0;
    if (match1) {
      return true;
    }
    const match2 =
      (value.subject || '')
        .toLowerCase()
        .indexOf(searchExpression.toLowerCase()) >= 0;
    if (match2) {
      return true;
    }
    const match3 =
      (value.contactName || '')
        .toLowerCase()
        .indexOf(searchExpression.toLowerCase()) >= 0;
    if (match3) {
      return true;
    }
    return false;
  }
}
