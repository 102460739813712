import {inject, LogManager, observable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
import {ObserverLocator} from 'aurelia-binding';
/*
 * */
import {MESSAGE_EVENTS, BootstrapFormRenderer} from 'zailab.common';
import {AssignEmailDialog} from '../assign-email-dialog';
/*
 * */
const logger = LogManager.getLogger('InteractionFlowAddCustomEmail');
/*
 * */
@inject(EventAggregator, ValidationControllerFactory, AssignEmailDialog, ObserverLocator)
export class InteractionFlowAddCustomEmail {

  @observable name = '';
  @observable domain = '';
  customEmailAddress;
  subscription;

  constructor(eventAggregator, validationControllerFactory, assignEmailDialog, observerLocator) {
    this.eventAggregator = eventAggregator;
    this.assignEmailDialog = assignEmailDialog;
    this.observerLocator = observerLocator;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate() {
    ValidationRules
      .customRule('validEmail', () => {
        let re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(this.fullEmail);
      });

    ValidationRules
      .ensure('name')
      .satisfiesRule('validEmail').withMessage('Please enter a valid email.')
      .on(this);
  }

  attached() {

    this.subscription = this.observerLocator
      .getObserver(this, 'name')
      .subscribe((newValue, oldValue) => {
        this.name = newValue.replace(/@/g, '');

        if (newValue.indexOf('@') !== -1) {
          this._focus('#js-domain');
        }
      });
  }

  nameChanged() {
    this._validateEmails();
  }

  domainChanged() {
    this._validateEmails();
  }

  _validateEmails() {
    this.validation.validate().then((validation) => {
    }, (error) => {});
  }

  deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  addEmailAddressToFlow() {
    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }
      this.customEmailAddress = this.name + '@' + this.domain.toLowerCase();
      this._checkIfEmailAddressExists(this.customEmailAddress);
    }, (error) => {});
  }

  _focus(string) {

    let element = document.querySelector(string);

    if(!element) {
      setTimeout(() => {
        this._focus(string);
      }, 10);
      return;
    }

    element.select();

    if (element !== document.activeElement) {

      setTimeout(() => {
        this._focus(string);
      }, 10);
    }
  }

  _checkIfEmailAddressExists(customEmailAddress) {
    let emailAddresses = this.assignEmailDialog.emailAddresses;
    let emailAddressFound;
    emailAddresses.forEach(email => {
      if (email === customEmailAddress) {
        emailAddressFound = true;
      }
    });

    if (emailAddressFound) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, `Duplicate emails cannot be assigned to flow.`);
    } else {
      this.assignEmailDialog.addEmailToInteractionFlow(customEmailAddress);
    }
  }

  get fullEmail() {
    return this.name + '@' + this.domain;
  }
}
