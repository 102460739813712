import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZCardSubHeader');
/*
 */
@customElement('z-card-sub-header')
export class ZCardSubHeader {

  @bindable capitals = '';
  @bindable color;

  textTransform() {

    switch (this.capitals) {

      case 'all': this.capitals = 'uppercase';
        break;

      case 'true': this.capitals = 'uppercase';
        break;

      case 'first': this.capitals = 'capitalize'
        break;

      default: 'none';
    }

  }

}
