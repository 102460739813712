import {LogManager} from 'aurelia-framework';

import {ChannelModel} from './channel-model';

const logger = LogManager.getLogger('EmailChannelModel');

export class EmailChannelModel extends ChannelModel {

  constructor(size?: string) {
    super('email', 'envelope-open');
    this.size = size ? size : 'small';
  }
}
