import {LogManager, inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/**/
import {observable} from "aurelia-binding";
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/**/
import {BootstrapFormRenderer} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
/**/
const logger = LogManager.getLogger('ScreenCallDialog');
const DEFAULT_CODE: string = "+27";

/**/
@inject(DialogController, ValidationControllerFactory)
export class ScreenCallDialog {

  @observable private codeValue: string = DEFAULT_CODE;
  @observable private numberValue: string = "";
  private dialogHeader = 'Screen Calls';
  private screenCallVC: ValidationController;
  private nodeData: NodeModel;
  private numberErrors: string = "";
  private validNumber: boolean = true;
  private canSave: boolean = false;
  private existingConnections: Array<ConnectorModel> = [];
  private screenCallNumbers: Array<TelephoneNumber> = [];
  private callScreenName: string = "";
  private nodeConfiguration: ZNodeConfig;

  constructor(private dialogController: DialogController, private validationControllerFactory: ValidationControllerFactory) {
    this.screenCallVC = this.validationControllerFactory.createForCurrentScope();
    this.screenCallVC.addRenderer(new BootstrapFormRenderer());
    this.screenCallVC.validateTrigger = validateTrigger.changeOrBlur;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    if (this.nodeData.properties["screenCallNumbers"]) {
      this.screenCallNumbers = this.nodeData.properties["screenCallNumbers"] as Array<TelephoneNumber>;
      this.callScreenName = this.nodeData.properties["callScreenName"];
      this.canSave = true;
    }
    this.existingConnections = this.nodeData.connections;
    this.setupValidation();
  }

  private setupValidation(): void {
    ValidationRules
      .ensure((_scope: any) => _scope.callScreenName).required()
      .on(this)
  }

  private codeValueChanged(): void {
    this.validNumber = this.checkValidNumber();
  }

  private numberValueChanged(): void {
    this.validNumber = this.checkValidNumber();
  }

  private checkValidNumber(): boolean {
    if (!this.codeValue) {
      return false
    }
    return (this.codeValue.match(/^[+]?[0-9]*$/) !== null || this.numberValue.match(/^[1-9][0-9]*$/) !== null);
  }

  private setTel(): void {
    let numberRaw = this.codeValue + this.numberValue;
    let possibleDuplicate = this.screenCallNumbers.filter(_value => {
      return _value.telephoneNumber.countryCode + _value.telephoneNumber.number === numberRaw;
    });
    if (!possibleDuplicate.length) {
      let newTelephoneNumber: TelephoneNumber = {telephoneNumber: {countryCode: this.codeValue, number: this.numberValue}};
      this.screenCallNumbers.push(newTelephoneNumber);
      this.resetUI();
      this.checkValid();
    } else {
      // Number already exists in array
      this.numberErrors = "Selected number already exists."
    }
  }

  private checkValid(): void {
    this.screenCallVC.validate().then(result => {
      this.canSave = result.valid && !!this.screenCallNumbers.length;
    })
  }

  private resetUI(): void {
    this.codeValue = DEFAULT_CODE;
    this.numberValue = "";
    this.numberErrors = "";
  }

  private removeNumberFromInteractionFlow(index: number): void {
    this.screenCallNumbers.splice(index, 1);
    this.checkValid();
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.properties['name'] = this.callScreenName;
    this.nodeData.properties['isDefined'] = !!this.screenCallNumbers.length;
    this.nodeData.properties['callScreenName'] = this.callScreenName;
    this.nodeData.properties['screenCallNumbers'] = this.screenCallNumbers;
    this.dialogController.ok(this.nodeData);
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'yes',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: {connectorIndex: 0, nodeID: this.nodeData.id},
        customExtensions: null
      }),
      new ConnectorModel({
        name: 'no',
        dest: typeof _existingConnections[1] !== 'undefined' ? _existingConnections[1].dest : null,
        source: {connectorIndex: 1, nodeID: this.nodeData.id},
        customExtensions: null
      })
    ];

    return _connections;
  }

}

interface TelephoneNumber {
  telephoneNumber: {
    countryCode: string;
    number: string;
  }
}