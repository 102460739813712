import { LogManager, bindable, customElement } from "aurelia-framework";
import { SearchTools } from "zailab.common";

const logger = LogManager.getLogger('DropdownSearch');

@customElement('z-dropdown-search')
export class DropdownSearch {

  @bindable public search: Search<any> = new Search();

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchParam) {
      return SearchTools.partialMatch(value, searchExpression);
    }
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}

export class Search<T> {

  public placeholder = '';
  public value = '';
  public showOptions = false;
  public displayParam = null;
  public options: Array<T> = new Array();
  public onchange: (option: T) => void;

  public select(option: T): void {
    this.value = this.displayValue(option);
    this.onchange(option);
    this.showOptions = false;
  }

  public show(): void {
    this.showOptions = true;
  }

  public hide(): void {
    setTimeout(() => this.showOptions = false, 250);
  }

  public displayValue(option: T): string {
    return !this.displayParam ? option : option[this.displayParam];
  }
}