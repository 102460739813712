/*
 */
import { LogManager, customElement, bindable } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPageBody');
/*
 */
@customElement('z-page-body')
export class ZPageBody {

  @bindable header;
  @bindable utility;
  @bindable footer;
  @bindable attributes;
  @bindable scrolling;

  get bodyHeight() {
    let bodyHeight = '';
    let headerAndFooterAndUtility = (this.header && this.utility && this.footer);
    let headerAndFooterAndAttributes = (this.header && this.attributes && this.footer);
    let headerAndFooter = (this.header && this.footer);
    let headerAndUtility = (this.header && this.utility);

    if (headerAndFooterAndUtility) {
      bodyHeight = 'o-page-body--header-utility-footer';
    } else if (headerAndFooterAndAttributes) {
      bodyHeight = 'o-page-body--header-attributes-footer';
    } else if (headerAndUtility) {
      bodyHeight = 'o-page-body--header-utility';
    } else if (headerAndFooter) {
      bodyHeight = 'o-page-body--header-footer';
    } else if (this.header) {
      bodyHeight = 'o-page-body--header';
    }
    if (this.scrolling) {
      bodyHeight += ' o-page-body--scrolling u-scroll-bar';
    }
    return bodyHeight;
  }
}



