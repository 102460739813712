import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import { computedFrom } from 'aurelia-binding';

import { SessionStore, ArrayTools } from 'zailab.common';
import { AddShiftDialog } from './add-shift-dialog/add-shift-dialog';
import { InHouseWFMService } from '../in-house-wfm-service';
import { TeamsService } from '../../../member/teams/teams-service';
import { MembersService } from '../../../member/members/members-service';
import { EditShiftDialog } from '../edit-shift-dialog/edit-shift-dialog';
import { ConfirmDialog } from '../../../../../components_v2/confirm-dialog/confirm-dialog';

import moment from 'moment';

import './shifts.scss';

const logger = LogManager.getLogger('InHouseWFMList');

@autoinject()
export class InHouseWFMList {
  public types = ['Team', 'Member'];
  public selectedType: string;
  public selectedTeams: { value: string, label: string }[] = [];
  public selectedMembers: { value: string, label: string }[] = [];

  public teams: { value: string, label: string, icon?: string, disabled?: boolean }[];
  public members: { value: string, label: string, icon?: string, disabled?: boolean }[];
  public searchOptions: { value: string, label: string, icon?: string }[];
  public shifts: any[];

  public weeks: string[];
  public currentWeekIndex: number;
  public currentWeek: any;
  public selectedWeek: { start: string, end: string };

  public selectedRange = "Week";
  public cols = [];
  
  private pageNumber = 0;
  private pageSize = 100;
  public showMoreEnabled = true;
  public noMoreMembers = false;
  public searchText = '';

  public selectedShifts: { [key: string]: any[] } = {};
  public bulkSelectionEnabled = false;

  public loading = false;
  public initialised = false;
  public selectToday: () => void = () => {};

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private inHouseWFMService: InHouseWFMService,
    private teamService: TeamsService,
    private memberService: MembersService,
    private sessionStore: SessionStore
  ) {}
  
  public async bind(): Promise<void> {
    this.generateWeeks();
    this.selectType('Team');
    this.setupMembers();
  }

  public attached(): void {
    setTimeout(() => this.initialised = true, 50);
  }

  private async setupTeams(): Promise<void> {
    const teams = await this.teamService.retrieveOrganisationTeams(
      this.sessionStore.get.organisation.organisationId
    ).catch(e => console.warn(' > Failed to retrieve teams due to ', e));

    this.teams = ArrayTools.sort(teams.map(team => {
      return {
        value: team.teamId,
        label: team.teamName,
        type: 'team'
      };
    }), 'label');
  }

  private async setupMembers(searchText?: string): Promise<void> {
    let members = await this.memberService
      .retrieveOrganisationMembers(
        null,
        searchText || null,
        null,
        this.pageNumber,
        this.pageSize,
      )
      .catch((e) => console.warn(' > Failed to get members due to', e));

    if (!members) {
      members = [];
    } else if (members.length < this.pageSize) {
      this.showMoreEnabled = false;
    }
    const mappedMembers = ArrayTools.sort(members.map((member: any) => {
      return {
        value: member.id,
        label: member.fullName,
        type: 'member'
      };
    }), 'label');

    if (this.pageNumber === 0) {
      this.members = mappedMembers;
    } else {
      this.members = [].concat(this.members, mappedMembers);
    }
  }

  private async getShifts(week: { start: string, end: string }): Promise<void> {
    this.loading = true;
    const shifts = await this.inHouseWFMService.getShifts(
      this.selectedTeams.map(team => team.value),
      this.selectedMembers.map(member => member.value),
      moment(week.start).format('DD-MM-YYYY'),
      moment(week.end).format('DD-MM-YYYY')
    ).catch(e => console.warn(' > Failed to get shifts due to ', e));
    if (!shifts) {
      this.loading = false;
      return;
    }

    const members = Object.values(shifts.members).map((member: any) => {
      const memberShifts = this.selectedShifts[member.memberId];
      return {
        ...member,
        fullName: member.memberFirstName + ' ' + member.memberSurname,
        shiftsMap: member.shifts.reduce((acc, shift) => {
          shift.duration = this.formatDuration(shift.durationInMin);
          
          if (memberShifts) {
            shift.selected = !!memberShifts.find(_shift => _shift.id === shift.id);
          }
          acc[shift.date] = shift;
          return acc;
        }, {}),
      };
    })
    this.shifts = ArrayTools.sort(members, 'fullName');
    this.loading = false;
  }

  private formatDuration(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes === 0
      ? `${hours} hours`
      : `${hours} hours ${remainingMinutes} minutes`;
  }
  

  private generateWeeks(): void {
    const today = moment();
    const start = today.clone().subtract(2, 'weeks').startOf('week');
    const end = today.clone().add(1, 'month').endOf('week');
    
    const weeks = [];
    let current = start.clone();

    while (current <= end) {
      const weekStart = current.clone();
      const weekEnd = current.clone().endOf('week');
      const isCurrentWeek = today.isBetween(weekStart, weekEnd, null, '[]');

      weeks.push({
        label: `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D, YYYY')}`,
        start: weekStart.format('YYYY-MM-DD'),
        end: weekEnd.format('YYYY-MM-DD'),
        isCurrentWeek: isCurrentWeek
      });

      current.add(1, 'week');
    }

    this.weeks = weeks;
    this.currentWeekIndex = weeks.findIndex(week => week.isCurrentWeek);
    this.currentWeek = weeks[this.currentWeekIndex];
    
    this.generateHeaders(this.currentWeek);
  }

  public async selectType(type: string): Promise<void> {
    if (this.selectedType && this.selectedType !== type) {
      this.clearSelectedSearchParams();
    }
    this.selectedType = type;
    this.searchText = '';
    this.pageNumber = 0;

    if (type === 'Team') {
      if (this.teams) {
        this.searchOptions = this.teams;
      } else {
        await this.setupTeams();
        this.searchOptions = this.teams;
      }
    } else if (type === 'Member') {
      if (this.members) {
        this.searchOptions = this.members;
      } else {
        await this.setupMembers();
        this.searchOptions = this.members;
      }
    }
  }

  public selectFilter(item: { value: string, type: string, label: string }): void {
    if (!item) {
      return;
    }

    if (item.type === 'team') {
      let selectedTeam = this.teams.find(team => team.value === item.value);
      selectedTeam.disabled = true;
      this.selectedTeams.push(item);
    } else if (item.type === 'member') {
      let selectedMember = this.members.find(member => member.value === item.value);
      selectedMember.disabled = true;
      this.selectedMembers.push(item);
    }
    this.getShifts(this.selectedWeek || this.currentWeek);
  }

  public removeListItem(item: { value: string, type: string, label: string }, index: number): void {
    if (this.selectedType === 'Team') {
      let selectedTeam = this.teams.find(team => team.value === item.value);
      selectedTeam.disabled = false;
      this.selectedTeams.splice(index, 1);
    } else if (this.selectedType === 'Member') {
      let selectedMember = this.members.find(member => member.value === item.value);
      selectedMember.disabled = false;
      this.selectedMembers.splice(index, 1);
    }
    if (this.selectedTeams.length === 0 && this.selectedMembers.length === 0) {
      this.shifts = null;
    }
    this.getShifts(this.selectedWeek);
  }

  public clearSelectedSearchParams(): void {
    this.selectedTeams = [];
    this.selectedMembers = [];
    this.shifts = null;

    this.teams.forEach(team => team.disabled = false);
    this.members.find(member => member.disabled = false);
  }
  
  public selectedRangeChanged(week: { start: string, end: string }): void {
    this.selectedWeek = week;
    this.generateHeaders(week);
    if (
      this.selectedTeams.length === 0 &&
      this.selectedMembers.length === 0
    ) {
      return;
    }
    this.getShifts(week);
  }

  public showMore(): void {
    this.pageNumber += 1;
    this.setupMembers();
  }

  public enableBulkSelection(): void {
    this.bulkSelectionEnabled = true;
  }

  public disableBulkSelection(): void {
    this.bulkSelectionEnabled = false;
  }

  public cancelBulkSelection(): void {
    this.disableBulkSelection();
    // todo: clear selectedValues

    const memberIds = Object.keys(this.selectedShifts);
    memberIds.forEach(memberId => {
      let selectedMember = this.shifts.find(item => item.memberId === memberId);
      if (!selectedMember) {
        return;
      }

      this.selectedShifts[memberId].forEach(_shift => {
        let selectedShift = selectedMember.shifts.find(shift => shift.id === _shift.id);
        if (selectedShift) {
          selectedShift.selected = false;
        }
      });
    });
    this.selectedShifts = {};
  }

  public toggleSelect(memberId: string, shift: any): void {
    if (!this.bulkSelectionEnabled || !shift) {
      return;
    }
    shift.selected = !shift.selected;
    if (shift.selected) {
      if (!this.selectedShifts[memberId]) {
        this.selectedShifts[memberId] = [];
      }
      this.selectedShifts[memberId].push(shift);
    } else {
      this.selectedShifts[memberId].splice(this.selectedShifts[memberId].indexOf(shift), 1);
      if (this.selectedShifts[memberId].length === 0) {
        delete this.selectedShifts[memberId];
      }
    }
  }

  public get hasSelectedBulkShifts(): boolean {
    return Object.keys(this.selectedShifts).length > 0;
  }

  public openShiftDialog(shift?: any): void {
    this.dialogService
      .open({
        viewModel: AddShiftDialog,
        model: {
          shift,
          existingNames: [],
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return;
        }
      });
  }

  public navigateToInHouseWFM(): void {
    this.router.navigate('');
  }

  private generateHeaders(week: { start: string, end: string }): void {
    const { start, end } = week;
    const startDate = moment(start);
    const endDate = moment(end);
    const today = moment();
    const days = [];
    let current = startDate.clone();

    while (current <= endDate) {
      days.push({
        dayName: current.format('dddd'),
        dayOfMonth: current.format('D'),
        monthName: current.format('MMMM'),
        isToday: current.isSame(today, 'day'),
        date: current.format('YYYY-MM-DD')
      });
      current.add(1, 'day');
    }
    this.cols = days;
  }

  public openEditShiftDialog(data: { memberId: string, shifts: [] }[]): void {
    this.dialogService
      .open({
        viewModel: EditShiftDialog,
        model: data,
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return;
        }
        setTimeout(() => this.getShifts(this.selectedWeek || this.currentWeek), 1000);
        this.cancelBulkSelection();
      });
  }

  public openBulkEdit(): void {
    const memberIds = Object.keys(this.selectedShifts);
    const shifts = [];
    memberIds.forEach(memberId => {
      shifts.push({ memberId, shifts: this.selectedShifts[memberId] });
    });
    this.openEditShiftDialog(shifts);
  }

  public confirmBulkDelete(): void {
    let text = `This will remove the selected shifts.`;
    this.dialogService
      .open({
        viewModel: ConfirmDialog,
        model: {
          header: 'Are you sure?',
          text
        }
      })
      .whenClosed(dialog => {
        if (dialog.wasCancelled) {
          return;
        }
        const memberIds = Object.keys(this.selectedShifts);
        memberIds.forEach(memberId => {
          const shiftIds = this.selectedShifts[memberId].map(shift => shift.id);
          this.removeShift(memberId, shiftIds);
        });
        this.cancelBulkSelection();
    });
  }

  public openConfirmRemoveShiftDialog(memberId: string, memberName: string, memberSurname: string, date: string, shift: any): void {
    let text = `This will remove the shift for ${memberName} ${memberSurname} for ${date}?`;
    this.dialogService
      .open({
        viewModel: ConfirmDialog,
        model: {
          header: 'Are you sure?',
          text
        }
      })
      .whenClosed(dialog => {
        if (dialog.wasCancelled) {
          return;
        }
        this.removeShift(memberId, [shift.id]);
    });
  }

  private async removeShift(memberId: string, shiftIds: string[]): Promise<void> {
    await this.inHouseWFMService.removeShifts(memberId, shiftIds)
      .catch(e => console.warn(' > Failed to remove shifts due to ', e));

    let member = this.shifts.find(shift => shift.memberId === memberId);
    if (!member) {
      return;
    }

    shiftIds.forEach((id: string) => {
      member.shifts = member.shifts.filter(shift => {
        return shift.id !== id;
      });
      member.shiftsMap = member.shifts.reduce((acc, shift) => {
        shift.duration = this.formatDuration(shift.durationInMin)
        acc[shift.date] = shift;
        return acc;
      }, {});
    })
  }
}
