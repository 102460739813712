import { autoinject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SearchTools } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { PlaceholderService } from './../../../../../_common/services/placeholder-service';
import { AddRoutingGroupDialog } from './add/add-routing-groups-dialog';
import { EditRoutingGroupDialog } from './edit/edit-routing-groups-dialog';
import { RoutingGroupsService } from './routing-groups-service';

import './routing-groups.scss';

import moment, { Moment } from 'moment';
import { BindingSignaler } from 'aurelia-templating-resources';

let logger = LogManager.getLogger('RoutingGroupList');

@autoinject
export class RoutingGroupList extends AbstractList {

  private stateStorageKey = 'zai_state';
  protected isDeleting: boolean = false;
  public isLoading: boolean = false;
  private defaultRequestTimer: number = 30;
  private currentRequestTimer: number = 30;
  private lastRequestTimestamp: Moment;
  private signallerInterval: any;
  public timerDropdownVisible = false;
  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }, { value: 55 }, { value: 60 }
  ];

  private actions = [{
    action: 'edit',
    label: 'Edit',
    icon: 'edit'
  }];

  constructor(
    protected element: any,
    private dialogService: DialogService,
    protected eventAggregator: EventAggregator,
    private routingGroupsService: RoutingGroupsService,
    private bindingSignaler: BindingSignaler
  ) {
    super(eventAggregator);
    
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        const parsedState = JSON.parse(state);
        let routingGroupConfig = parsedState.routingGroups;
        this.currentRequestTimer = routingGroupConfig.requestTimer > 0 ? routingGroupConfig.requestTimer : this.defaultRequestTimer;
      } catch(e) {
        this.currentRequestTimer = this.defaultRequestTimer;
      }
    } else {
      this.currentRequestTimer = this.defaultRequestTimer;
    }
    this.setupSignaller();
  }

  protected activate(): void {
    super.activate();
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
    this.itemList.items.forEach(item => {
      item.isDeleting = false;
    });

  }

  public deleteRoutingGroups(): void {

    let listToDelete = [];

    // @ts-ignore
    this.itemList.items.forEach(item => {
      if (item.isDeleting) {
        listToDelete.push(item);
      }
    });

    this.deleteItems(listToDelete);
    this.hideLoader();

    // @ts-ignore
    this.itemList.items.forEach(item => item.isDeleting = false);
    this.toggleDelete();
  }

  public select(_item: any): void {
    _item.isDeleting = !_item.isDeleting;
  }

  protected retrieveList(): void {
    if (this.isLoading) {
      return;
    }
    super.retrieveList();
    this.isLoading = true;
    this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer);

    this.routingGroupsService
      .getRoutingGroups()
      .then((data: any) => {
        this.setupListConfig(data.routingGroupMinimizedViewList);
        this.setupPlaceholders(data.routingGroupMinimizedViewList);
        super.hideLoader();
        this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer);
        this.isLoading = false;
      })
      .catch(error => {
        logger.info(' > Failed to get data due to', error);
        this.setupPlaceholders([]);
        super.hideLoader();
        setTimeout(() => {
          this.isLoading = false;
        }, 500)
        this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer);
      });
  }

  protected setupListConfig(routingGroups: any[]): void {
    super.setupListConfig(routingGroups);
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .items(routingGroups)
      .icon('icon')
      .uniqueIdentifier('routingGroupId')
      .displayId('name')
      .description('')
      .withProportion('medium')
      .hasRollover(true)
      .tilesPerRow(6)
      .build();

    this.createActionsList();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }
  
  private setupPlaceholders(routingGroups: any[]): void {
    this.placeholderService = new PlaceholderService(this.container, routingGroups.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  private createActionsList(): void {
    this.itemList.items.forEach(item => {
      item.actions = this.actions;
    });
  }

  public openCreateDialog(): void {
    this.showLoader();
    this.dialogService
      .open({
        viewModel: AddRoutingGroupDialog,
        model: {
          name: undefined,
          existingRoutingGroupNames: this.itemList.items.map(item => item.name)
        }
      })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          this.retrieveList();
        }
        this.hideLoader();
      });
  }

  public edit(routingGroup: any): void {
    this.showLoader();
    this.dialogService
      .open({
        viewModel: EditRoutingGroupDialog,
        model: routingGroup
      })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          routingGroup.name = response.output.name;
        }
        this.hideLoader();
      });
  }

  protected toggleSearch():void {
    super.toggleSearch();
  }

  public delete(routingGroup: { routingGroupId: string }): void {

    this.routingGroupsService
      .deleteRoutingGroup(routingGroup.routingGroupId)
      .then(() => {
        this.itemList.items = this.itemList.items.filter(item => item.routingGroupId !== routingGroup.routingGroupId);
        this.setupPlaceholders(this.itemList.items);
        this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer);
      })
      .catch(e => {
        console.warn(' > Failed to delete routingGroup - ' + routingGroup.routingGroupId + ' due to cause:', e);
      })
  }
      
  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(moment(), 'seconds') <= 0) {
        if (this.isLoading) {
          return;
        }
        this.retrieveList();
      }
    }, signal);
  }
  
  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: number): void {
    this.timerDropdownVisible = false;
    this.currentRequestTimer = value;
    
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        if (!parsedState.routingGroups) {
          parsedState.routingGroups = {};
        }
        parsedState.routingGroups.requestTimer = value;
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ routingGroups: { requestTimer: value }}));
    }
    this.retrieveList();
  }

  // Override methods
  protected startsWithMatch(searchExpression: string, value: any, searchParam: string): any {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: any, searchParam: string): any {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
