import { MemberModel } from './../../../conversation/member-model';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MembersService } from '../members-service';
import { FeatureFlagService } from '../../../../featureflags/feature-flag-service';

const logger = LogManager.getLogger('ViewMember');

@autoinject()
export default class {
  public tabs: any[];
  public memberId: String;
  public member: MemberModel;
  public ready: boolean = false;
  public autoLogoutConfig: boolean;
  public routingGroupsEnabled: boolean;

  constructor(
    private router: Router,
    private membersService: MembersService,
    private featureFlagService: FeatureFlagService
  ) {}

  public activate(data: { memberId: string }): void {
    this.memberId = data.memberId;
    this.featureFlagService
      .isEnabled('autoLogoutConfig')
      .then((autoLogoutConfig) => this.autoLogoutConfig = autoLogoutConfig);
      
    this.featureFlagService
      .isEnabled('RoutingGroups')
      .then(routingGroupsEnabled => this.routingGroupsEnabled = routingGroupsEnabled);
  }

  public attached(): void {
    this.retrieveMember();
  }

  public retrieveMember(): void {

    this.membersService.retrieveMember(this.memberId).then((response) => {
      this.member = response;
      this.ready = true;
      this.setUpTabs();
    }, error => {
      this.ready = true;
      logger.info('retrieveMember > failure > ', error);
    });
  }

  private setUpTabs(): void {
    this.tabs = [
      { id: '1', name: 'Attributes', viewModel: PLATFORM.moduleName('features/organisation/member/members/view/steps/attributes/view-member-attributes'), icon: 'filter', data: { member: this.member }, selected: true },
      { id: '2', name: 'Permissions', viewModel: PLATFORM.moduleName('features/organisation/member/members/view/steps/permissions/view-member-permissions'), icon: 'timer', data: { member: this.member } }
    ]
  }

  public back(): void {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }

  public unbind(): void {
    this.tabs = null;
  }
}
