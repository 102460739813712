import { LogManager, customElement, bindable } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZSwitchCheckbox');
/*
 */
@customElement('z-switch-checkbox')
export class ZSwitchCheckbox {
  @bindable label: string;
  @bindable selected: boolean;
  @bindable active: boolean = true;
  @bindable({ attribute: 'enabled-text' }) enabledText: string = 'Enabled';
  @bindable({ attribute: 'disabled-text' }) disabledText: string = 'Disabled';

  checkInput: HTMLElement;

  public bind(): void {
    this.checkInput.disabled = !this.active;
  }
  
  public activeChanged(): void {
    if (this.checkInput) {
      this.checkInput.disabled = !this.active;
    }
  }
}
