import {SegmentModel} from "./segment-model";
import {StatsConfig} from "./stats-config";

export class StatsBuilder {
  private statsConfig: StatsConfig;

  constructor() {
    this.statsConfig = new StatsConfig();
  }

  public withSegment(segment: SegmentModel) : StatsBuilder {
    this.statsConfig.addSegment(segment);
    return this;
  }

  public withSegmentsLabel(segmentsLabel: string) : StatsBuilder {
    this.statsConfig.segmentsLabel = segmentsLabel;
    return this;
  }

  public withTitle(title: string): StatsBuilder {
    this.statsConfig.title = title;
    return this;
  }

  public withIsLive(isLive: boolean): StatsBuilder {
    this.statsConfig.isLive = isLive;
    return this;
  }

  public withId(id: string): StatsBuilder {
    this.statsConfig.id = id;
    return this;
  }

  public routeToView(view: string): StatsBuilder {
    this.statsConfig.view = view;
    return this;
  }

  public withParamData(...params: string[]): StatsBuilder {
    this.statsConfig.setupParams(params);
    return this;
  }

  public withHiddenState(isHidden: boolean): StatsBuilder {
    this.statsConfig.isHidden = isHidden;
    return this;
  }

  public build(): StatsConfig {
    return this.statsConfig;
  }
}