import {HttpClient} from 'aurelia-http-client';
import {autoinject, LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('APIMonitoringService');

@autoinject()
export class APIMonitoringService {


  constructor(
    protected httpClient: HttpClient
  ) { }

  public getAPILookup(): Promise<any> {
    return this.httpClient
        .createRequest('v3/performance-management/api-monitor')
        .asGet()
        .send()
        .catch((error: string) => {
          logger.info(' > API monitor error ', error);
        });
  }
}
