import {AbstractService} from '../../_abstract/abstract-service';
import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {OplogService, SessionStore} from 'zailab.common';
import {SkillModel} from './skill-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('SkillsService');
/*
 * */
@inject(HttpClient, EventAggregator, OplogService)
export class SkillsService extends AbstractService {

  private selectDeselectSubscription: any;

  constructor(protected httpClient: HttpClient, protected eventAggregator: EventAggregator, protected oplogService: OplogService) {
    super(httpClient, oplogService, eventAggregator);
  }

  public retrieveSkills(skillGroupId:string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fetchDataFromAPI({
        url: `v1/organisation/me/skill-groups/${skillGroupId}/skills`,
        tag: `retrieve-skills`
      }).then(_result => {
        resolve({
          skills: _result.map(_skill => {
            return new SkillModel(_skill);
          }),
          skillGroupName: _result.skillGroupName
        });
      }).catch(_error => {
        reject(_error)
      })
    })
  }

  public retrieveSkillGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fetchDataFromAPI({
        url: `v1/organisation/me/skill-groups`,
        tag: `retrieve-skill-groups`
      }).then(_result => {
        resolve(_result.map(_skillGroup => {
          return {
            skillGroupName: _skillGroup.name,
            skillGroupId: _skillGroup.id,
            skills: _skillGroup.skills.map(_skill => {
              return new SkillModel(_skill)
            })
          }
        }));
      }).catch(_error => {
        reject(_error)
      })
    })
  }

  public retrieveSkillsStats(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fetchDataFromAPI({
        url: `v1/organisation/me/skill-groups`,
        tag: `retrieve-skill-stats`
      }).then(_result => {
        resolve(_result.length || 0);
      }).catch(_error => {
        reject(_error)
      })
    })
  }

  public removeSkill(organisationId: string, skillGroupId:string, skill:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sendDeleteCommandToAPI({
        url: `v1/organisation/${organisationId}/skill-groups/${skillGroupId}/skills/${skill.id}`,
        tag: `delete-skill`
      }).then(_result => {
        resolve(_result);
      }).catch(_error => {
        reject(_error);
      })
    });
  }

  public addSkill(organisationId:string, skillGroupId:string, skillName:string): Promise<any> {
    let skillId = uuidv4();
    let commandPayload = {id: skillId, name: skillName};
    return new Promise((resolve, reject) => {
      this.sendPostCommandToAPI({
        url: `v1/organisation/${organisationId}/skill-groups/${skillGroupId}/skills`,
        tag: `create-new-skill`,
        content: commandPayload
      }).then(_result => {
        resolve(_result);
      }).catch(_error => {
        reject(_error);
      })
    });
  }
}
