import {computedFrom, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('DisplayTeamModel');

/*
 * */
export class DisplayTeamsModel extends BaseModel {

  id = null;
  name = null;
  disabled = false;

  teamId = null;
  teamName = null;
  memberCount = 0;
  isAdded;

  constructor(team) {
    super();
    this.mapProperties(team);
  }

  get icon() {
    return 'members'
  }

  @computedFrom('memberCount')
  get description() {
    return `Members: ${this.memberCount}`
  }

  @computedFrom('memberCount')
  get canDelete() {
    return this.memberCount === 0
  }

  mapProperties(team) {
    if (team.teamId) {
      team.id = team.teamId;
      team.name = team.teamName;
    } else {
      team.teamId = team.id;
      team.teamName = team.name;
    }
    super.mapProperties(team)
  }
}
