import {autoinject, LogManager, customElement, bindable} from 'aurelia-framework';
import {InteractionModel} from '../../interaction-model';

const logger = LogManager.getLogger('NoteInteractionCard');

@customElement('z-note-interaction-card')
@autoinject
export class NoteInteractionCard {
  @bindable private interaction: InteractionModel;
}
