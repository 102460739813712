import {LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController} from 'aurelia-dialog';

import { WorkTypesService } from '../work-types-service';
import { autoinject } from 'aurelia-dependency-injection';

const logger = LogManager.getLogger('WorkTypesVersionDialog');

interface IVersion {
  version: string;
  live: boolean;
  actions?: any[];
}

@autoinject()
export class WorkTypeVersionDialog {

  public dialogHeader: string = 'Work Type Versions';
  private taskTemplateId: string;
  private versions: IVersion[];
  private defaultActions = [{
    action: 'view',
    label: 'view'
  }, {
    action: 'setlive',
    label: 'set live',
    icon: 'live'
  }];

  constructor(
    private dialogController: DialogController,
    private workTypesService: WorkTypesService
  ) {}

  activate(model: { templateId: string; }): void {
    this.taskTemplateId = model.templateId;
    this.retrieveWorkTypeVersions();
  }

  private retrieveWorkTypeVersions(): void {
    this.workTypesService
      .getWorkTypeVersions(this.taskTemplateId)
      .then(data => this.mapActions(data.versions))
      .catch(error => {
        logger.error(error);
        this.versions = [];
      });
  }

  private mapActions(versions: IVersion[]): void {
    let mappedVersions = [];
    if (versions) {
      const keys = Object.keys(versions);

      keys.forEach(key => {
        let version = {
          ...versions[key],
          actions: this.defaultActions
        };
        mappedVersions.push(version);
      });
    }
    this.versions = mappedVersions;
  }

  public view(version: number): void {
    this.dialogController.ok({ version });
  }

  public setLive(version: string): void {
    this.workTypesService
      .setLive(this.taskTemplateId, version)
      .then(() => this.mockLiveVersion(version));
  }

  private mockLiveVersion(version: string): void {
    this.versions.forEach(_version => {
      _version.live = _version.version === version;
    });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public done(): void {
    this.dialogController.ok();
  }

}
