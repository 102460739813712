import { LogManager, autoinject } from 'aurelia-framework';

import { SessionStore, FeatureFlagService } from 'zailab.common';
import { InHouseWFMService } from '../in-house-wfm-service';

import moment from 'moment';

import './shift.scss';

const logger = LogManager.getLogger('Shift');

@autoinject()
export class Shift {
  public shifts: any[];
  public monthView: any[][];

  public cols = [];
  
  public showMoreEnabled = true;
  public noMoreMembers = false;
  public searchText = '';
  public currentYear = moment().year();
  public selectedYear = moment().year();
  public currentMonth = moment().month();
  public selectedMonth = moment().month();

  public loading = false;
  public initialised = false;
  public weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public embeddedWebphoneEnabled: boolean;

  constructor(
    private inHouseWFMService: InHouseWFMService,
    private sessionStore: SessionStore,
    private featureFlagService: FeatureFlagService

  ) {}
  
  public async bind(): Promise<void> {
    this.getFeatureFlags();
    this.generateMonths();
  }
  private async getFeatureFlags(): Promise<void> {
    this.embeddedWebphoneEnabled = await this.featureFlagService.isEnabled('embeddedWebphoneEnabled');
  }


  public attached(): void {
    setTimeout(() => this.initialised = true, 50);
  }

  private async getShifts(startDay: string, endDay: string): Promise<void> {
    this.loading = true;
    const data = await this.inHouseWFMService.getShift(
      startDay,
      endDay
    ).catch(e => console.warn(' > Failed to get shifts due to ', e));
    if (!data) {
      this.loading = false;
      return;
    }

    if (data.members && data.members[this.sessionStore.get.user.memberId]) {
      this.shifts = data.members[this.sessionStore.get.user.memberId].shifts.reduce((acc, shift) => {
        shift.duration = this.formatDuration(shift.durationInMin)
        acc[shift.date] = shift;
        return acc;
      }, {});
    } else {
      this.shifts = [];
    }

    this.loading = false;
  }

  public selectToday(): void {
    this.selectedMonth = this.currentMonth;
    this.selectedYear = this.currentYear;
    this.generateMonths();
  }

  public previousMonth(): void {
    this.selectedMonth--;
    if (this.selectedMonth < 0) {
      this.selectedMonth = 11;
      this.selectedYear--;
    }
    this.generateMonths();
  }

  public nextMonth(): void {
    this.selectedMonth++;
    if (this.selectedMonth > 11) {
      this.selectedMonth = 0;
      this.selectedYear++;
    }
    this.generateMonths();
  }

  private formatDuration(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes === 0
      ? `${hours} hours`
      : `${hours} hours ${remainingMinutes} minutes`;
  }

  private generateMonths(): void {
    const referenceToday = moment().startOf('day');
    const day = moment().year(this.selectedYear).month(this.selectedMonth);
    const monthStart = day.clone().startOf('month').startOf('week');
    const monthEnd = day.clone().endOf('month').endOf('week');
    const monthView = [];
    let currentWeek = [];

    for (let day = monthStart.clone(); day.isSameOrBefore(monthEnd); day.add(1, 'days')) {
      const dayObject = {
        dayShortName: day.format('ddd'),
        day: day.format('D'),
        date: day.format('YYYY-MM-DD'),
        shift: {},
        isToday: day.isSame(referenceToday, 'day'),
        isSelectedMonth: day.month() === this.selectedMonth,
        monthShortName: day.format('MMM'),
      };

      currentWeek.push(dayObject);

      if (currentWeek.length === 7 || day.isSame(monthEnd, 'day')) {
        monthView.push(currentWeek);
        currentWeek = [];
      }
    }

    this.monthView = monthView;
    
    this.getShifts(
      monthStart.format('DD-MM-YYYY'),
      monthEnd.format('DD-MM-YYYY')
    );
  }

  public refresh(): void {
    const firstDate = this.monthView[0][0];
    const lastDate = this.monthView[this.monthView.length - 1][this.monthView[this.monthView.length - 1].length - 1];

    this.getShifts(
      moment(firstDate.date).format('DD-MM-YYYY'),
      moment(lastDate.date).format('DD-MM-YYYY')
    );
  }
}
