import moment from 'moment-timezone';
import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('DisplayTimeValueConverter');
export class DisplayTimeValueConverter {

  displayTime;
  isSameDay;
  time;
  date;
  display;

  toView(localTime, customTimeFormat, divider) {

    this.isSameDay = moment().calendar(localTime, 'sameDay');
    if (this.isSameDay.substring(0, 5) === 'Today') {
      this.displayTime = this.isSameDay.substring(0, 5);
    } else {
      this.displayTime = moment(localTime).format('dddd');
    }

    this.time = moment(localTime).format('HH:mm');
    this.date = moment(localTime).format(customTimeFormat || 'Do MMM YYYY');
    this.display = this.displayTime + ', ' + this.date + ' at ' + this.time;

    if(customTimeFormat) {
      this.display = this.date + (divider || ' at ') + this.time;
    }

    return this.display
  }
}
