import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('InviteMemberCommandModel');
/*
 * */
export default class extends BaseModel {

  _channels = [];
  _classOfService = null;
  _role = null;
  _services = [];
  _site = null;
  _skills = null;

  constructor(invitation) {
    super();
    this.channels = invitation.channels;
    this.classOfService = invitation.classOfService || [];
    this.services = invitation.services || [];
    this.site = invitation.sites || [];
    this.skills = invitation.skills;
  }

  set channels(channels) {
    channels.forEach((item) => {
      if(item.isSelected) {
        this._channels.push(item.displayName);
      }
    });
  }

  @computedFrom('_channels')
  get channels() {
    return this._channels;
  }

  set classOfService(classesOfService) {
    for(let item of classesOfService) {
      if(item.isSelected) {
        this._classOfService = item.displayName;
        return;
      }
    }
  }

  @computedFrom('_classOfService')
  get classOfService() {
    return this._classOfService;
  }

  set services(services) {
    let _services = [];
    for(let service of services) {
      if(service.isSelected) {
        service.id = service.serviceId;
        service.name = service.serviceName;
        _services.push(service);
      }
    }
    this._services = _services;
  }

  @computedFrom('_services')
  get services() {
    return this._services;
  }

  set site(sites) {
    for(let site of sites) {
      if(site.isSelected) {
        this._site = site;
        return;
      }
    }
  }

  @computedFrom('_sites')
  get site() {
    return this._site;
  }

  set skills(skillGroups) {
    let _skills = [];
    for(let {skills} of skillGroups) {
      _skills = _skills.concat(skills);
    }
    this._skills = _skills;
  }

  get skills() {
    return this._skills;
  }
}