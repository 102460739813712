import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/**/
import { WaitingRoomDialogService } from './waiting-room-dialog-service';
import { AudioFileModel } from 'zailab.common';
import {
  CALLBACK,
  CRITERIA,
  CRITERIA_PABX,
  MAILBOX,
  PLAY_MESSAGE,
  SURVEY,
  CALL_FORWARD,
  PLAY_TEXT_MESSAGE,
  ON_CHAT,
  SET_VARIABLES,
  DECISION,
  ON_EMAIL,
} from '../../interaction-config-strategy';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { ZIWizard } from '../../../../../../../typings/zai/zai.common';
import { InteractionFlow } from '../../interaction-flow';

declare const PLATFORM;

const logger = LogManager.getLogger('WaitingRoomDialog');

@autoinject
export class WaitingRoomDialog {
  public static STEP_CONFIG: { [key: string]: any } = {
    waitSound: {},
    announcements: {},
    numberOfRings: {},
    conversations: {},
    smartRouting: {},
    exitDestination: {},
  };
  private nodeData: NodeModel;
  private steps: object[];
  private isAddingContent: boolean = false;
  private nodeConfiguration: ZNodeConfig;

  constructor(
    private dialogController: DialogController,
    private waitingRoomDialogService: WaitingRoomDialogService,
    private eventAggregator: EventAggregator,
    private interactionFlow: InteractionFlow
  ) {}

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(
      JSON.stringify(this.nodeConfiguration.nodeDefinition)
    );
    this.nodeData.properties.channel = this.nodeConfiguration.flowChannel;
    this.initSteps();
  }

  private initSteps(): void {
    const data = {
      ...this.nodeData.properties,
      version: this.nodeData.version,
    };

    WaitingRoomDialog.STEP_CONFIG.waitSound = {
      name: 'Wait Sound',
      description: '',
      view: PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/wait-sound/wait-sound'
      ),
      active: true,
      hasAttributes: true,
      completed: true,
      data,
    };
    WaitingRoomDialog.STEP_CONFIG.announcements = {
      name: 'Announcements',
      description: '',
      view: PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/announcements/announcements'
      ),
      hasAttributes: true,
      completed: true,
      data,
    };
    WaitingRoomDialog.STEP_CONFIG.numberOfRings = {
      name: 'Number Of Rings',
      description: '',
      view: PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/number-of-rings/number-of-rings'
      ),
      hasAttributes: true,
      completed: true,
      data,
    };
    WaitingRoomDialog.STEP_CONFIG.conversations = {
      name: 'Conversations',
      description: '',
      view: PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/conversations/conversations'
      ),
      hasAttributes: true,
      completed: true,
      data,
    };
    WaitingRoomDialog.STEP_CONFIG.smartRouting = {
      name: 'Smart Routing',
      description: '',
      view: PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/smart-routing/smart-routing'
      ),
      hasAttributes: true,
      completed: true,
      data,
    };
    WaitingRoomDialog.STEP_CONFIG.exitDestination = {
      name: 'Exit Destination',
      description: '',
      view: PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/exit-destination/exit-destination'
      ),
      hasAttributes: true,
      completed: true,
      data,
    };

    if (this.interactionFlow.interactionFlowType === 'Contact Centre Flow') {
      if (this.nodeConfiguration.flowChannel === 'Chat') {
        WaitingRoomDialog.STEP_CONFIG.announcements.active = true;
        this.steps = [
          WaitingRoomDialog.STEP_CONFIG.announcements,
          WaitingRoomDialog.STEP_CONFIG.numberOfRings,
          WaitingRoomDialog.STEP_CONFIG.conversations,
          WaitingRoomDialog.STEP_CONFIG.exitDestination,
        ];
      } else if (
        this.nodeConfiguration.flowChannel === 'Instant Message' ||
        this.nodeConfiguration.flowChannel === 'Ticket'
      ) {
        WaitingRoomDialog.STEP_CONFIG.announcements.active = true;
        this.steps = [
          WaitingRoomDialog.STEP_CONFIG.announcements,
          WaitingRoomDialog.STEP_CONFIG.conversations,
          WaitingRoomDialog.STEP_CONFIG.exitDestination,
        ];
      } else {
        this.steps = [
          WaitingRoomDialog.STEP_CONFIG.waitSound,
          WaitingRoomDialog.STEP_CONFIG.announcements,
          WaitingRoomDialog.STEP_CONFIG.numberOfRings,
          WaitingRoomDialog.STEP_CONFIG.conversations,
          WaitingRoomDialog.STEP_CONFIG.smartRouting,
          WaitingRoomDialog.STEP_CONFIG.exitDestination,
        ];
      }
    } else {
      this.steps = [
        WaitingRoomDialog.STEP_CONFIG.waitSound,
        WaitingRoomDialog.STEP_CONFIG.numberOfRings,
        WaitingRoomDialog.STEP_CONFIG.exitDestination,
      ];
    }
  }

  private activateContentCreator(): void {
    this.isAddingContent = true;
  }

  private cancelContentCreator(): void {
    this.isAddingContent = false;
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private complete(wizardData: any): void {
    let properties: any = wizardData.detail;
    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    this.dialogController.ok(updatedNode);
  }

  private mapNodeProperties(properties: any): NodeModel {
    let hasExitDestination: boolean = properties.hasExitDestination;
    let autoExit: boolean = properties.autoExit;
    let updatedConnections: ConnectorModel[] = this.mapDigitsToConnections(
      properties.digits,
      this.nodeData.connections,
      hasExitDestination,
      autoExit
    );

    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    this.nodeData.properties = properties;
    this.nodeData.properties.isDefined = true;
    return new NodeModel(this.nodeData);
  }

  private mapDigitsToConnections(
    digits: string[],
    existingConnections: ConnectorModel[],
    hasExitDestination: boolean,
    autoExit: boolean
  ): ConnectorModel[] {
    let nodeID: string = this.nodeData.id;
    let connectorIndex: number = 0;
    let defaultConnection: ConnectorModel = new ConnectorModel({
      name: 'default',
      source: { connectorIndex, nodeID },
    });
    let exitConnection: ConnectorModel;
    let connections: ConnectorModel[] = [defaultConnection];
    let criteria: string =
      this.interactionFlow.interactionFlowType === 'Office Flow'
        ? CRITERIA_PABX
        : CRITERIA;
    let customExtensions: string[] = [
      MAILBOX,
      CALLBACK,
      SURVEY,
      PLAY_MESSAGE,
      criteria,
      CALL_FORWARD,
    ];

    if (!hasExitDestination) {
      return this.mapExistingConnections(connections, existingConnections);
    }

    digits.forEach((digit) => {
      if (digit !== '#') {
        connectorIndex++;

        let connection: ConnectorModel = new ConnectorModel({
          name: digit,
          source: { connectorIndex, nodeID },
          customExtensions,
        });

        connections.push(connection);
      }
    });

    connectorIndex++;

    // Add the exit connection
    let callback: string =
      this.interactionFlow.interactionFlowType === 'Office Flow'
        ? ''
        : CALLBACK;
    let exitExtensions: string[] = [
      MAILBOX,
      PLAY_MESSAGE,
      callback,
      CALL_FORWARD,
    ];

    if (this.nodeConfiguration.flowChannel === 'Chat') {
      exitExtensions = [PLAY_TEXT_MESSAGE, ON_CHAT, SET_VARIABLES, DECISION];
    } else if (this.nodeConfiguration.flowChannel === 'Instant Message') {
      exitExtensions = [ON_CHAT, SET_VARIABLES, DECISION];
    }

    exitConnection = new ConnectorModel({
      name: 'exit',
      source: { connectorIndex, nodeID },
      customExtensions: exitExtensions,
    });
    connections.push(exitConnection);

    connections = this.mapExistingConnections(connections, existingConnections);

    return connections;
  }

  private mapExistingConnections(
    updatedConnections: ConnectorModel[],
    existingConnections: ConnectorModel[]
  ): ConnectorModel[] {
    let connectorIndex: number = 0;

    updatedConnections.forEach((connection) => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest =
          existingConnections[connectorIndex].dest;
      }

      connectorIndex++;
    });

    return updatedConnections;
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }
}
