export class DiallerState {

  public events: DiallerEvent[] = [];

  public logSuccess(text: string): void {
    this.events.unshift(DiallerEvent.success(this.flatten(text)));
  }

  public logError(text: string): void {
    this.events.unshift(DiallerEvent.error(this.flatten(text)));
  }

  public logInfo(text: string): void {
    this.events.unshift(DiallerEvent.info(this.flatten(text)));
  }

  private flatten(text: string): string {
    text = text.replace('<b>Dialler Error</b>', '');
    text = text.replace('<b>Dialler Message</b>', '');
    return text.replace('<br/>', '');
  }
}

export class DiallerEvent {

  public time: string;

  constructor(public text: string, public type: DiallerEventType) {
    const split = (new Date() + '').split(' ');
    const ttime = [];
    for (let i = 0; i < 5; i++) {
      ttime.push(split[i]);
    }
    this.time = ttime.join(' ');
  }

  static success(text: string): DiallerEvent {
    return new DiallerEvent(text, DiallerEventType.SUCCESS);
  }

  static error(text: string): DiallerEvent {
    return new DiallerEvent(text, DiallerEventType.ERROR);
  }

  static info(text: string): DiallerEvent {
    return new DiallerEvent(text, DiallerEventType.INFO);
  }
}

enum DiallerEventType {
  SUCCESS = 'SUCCESS', ERROR = 'ERROR', INFO = 'INFO'
}