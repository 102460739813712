import {inject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
* */
const logger = LogManager.getLogger('InstantMessaging');
/*
* */
@inject(Router)
export default class {

  constructor(router) {

    this.router = router;
  }

  configureRouter(config, router) {

    config.map([
      {route: ['','list'],  name: 'instant-messaging-list', moduleId: PLATFORM.moduleName('features/im/list/im'), nav: false, title: 'Instant Messaging Configuration', settings: {access: ['Administrator']}},
      {route: 'add',  name: 'instant-messaging-add', moduleId: PLATFORM.moduleName('features/im/add/add-im-dialog'), nav: false, title: 'Add Instant Messaging Configuration', settings: {access: ['Administrator']}}
    ]);

    this.router = router;
  }
}