import { autoinject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SearchTools, WebSocket } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { LoadBalancerService } from '../load-balancer-service';
import { PlaceholderService } from '../../../../_common/services/placeholder-service';
import { LoadBalancerDialog } from '../add/add-load-balancer-dialog';
import { LOAD_BALANCER_ACTIONS } from '../actions';
import { ViewLoadBalancerDialog } from '../view/view-load-balancer-dialog';
import { ZIOplog } from '../../../../../typings/zai/zai.common';

let logger = LogManager.getLogger('LoadBalancerListView');

@autoinject
export class LoadBalancerList extends AbstractList {

  protected isDeleting: boolean = false;
  protected oplog: ZIOplog;

  private actions = [{
    action: 'view',
    label: 'view'
  }, {
    action: 'addfuturedatedratio',
    label: 'Add Future Dated Default Ratio',
    icon: 'add'
  }, {
    action: 'addtemporaryratio',
    label: 'Add Temporary Ratio',
    icon: 'add'
  }];

  constructor(
    protected element: any,
    private dialogService: DialogService,
    private loadBalancerService: LoadBalancerService,
    protected eventAggregator: EventAggregator,
    private webSocket: WebSocket
  ) {
    super(eventAggregator);
  }

  protected activate(): void {
    super.activate();
  }

  protected attached(): void {
    super.attached();
  }
  
  protected deactivate (): void {
    super.deactivate();
    this.deregisterToDeletedEvents();
  }
  
  protected detached(): void {
    super.detached();
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
    this.itemList.items.forEach(item => {
      item.isDeleting = false;
    });
  }

  protected subscribeToOplog(): void {
    this.loadBalancerService.initialiseOplog().then(oplog => {
      this.oplog = oplog;

      this.oplog.on('insert', () => this.retrieveList());
      this.oplog.on('update', () => this.retrieveList());
      this.oplog.on('delete', () => this.retrieveList());
    });
  }

  public deleteLoadBalancers(): void {

    let listToDelete = [];

    this.itemList.items.forEach(_loadBalancer => {
      if (_loadBalancer.isDeleting) {
        listToDelete.push(_loadBalancer);
      }
    });

    this.deleteItems(listToDelete);
    this.hideLoader();
    this.toggleDelete();
  }

  public selectLoadBalancer(_item: any): void {
    _item.isDeleting = !_item.isDeleting;
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.loadBalancerService
      .retrieveLoadBalancers()
      .then((data: { loadBalancers: ZLoadBalancer[] }) => {
        this.setupListConfig(data.loadBalancers);
        this.setupPlaceholders(data.loadBalancers);
        super.hideLoader();
      })
      .catch(error => {
        logger.info('WARN >  Could not retrieve and list mailboxes > error =', error);
        super.hideLoader();
      }
    );
  }

  protected setupListConfig(loadBalancers: ZLoadBalancer[]): void {
    super.setupListConfig(loadBalancers);
    this.itemList = List.Builder()
      .items(loadBalancers)
      .build();
    
    this.createActionsList();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }
  
  private setupPlaceholders(loadBalancers: ZLoadBalancer[]): void {
    this.placeholderService = new PlaceholderService(this.container, loadBalancers.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  private createActionsList(): void {
    this.itemList.items.forEach(item => {
      item.actions = this.actions;
      item.canDelete = item.assignedFlowCount === 0;
    });
  }

  private addNewLoadBalancer(): void {
    this.openDialog(LOAD_BALANCER_ACTIONS.CREATE);
  };

  private viewLoadBalancer(loadBalancer: ZLoadBalancer): void {
    this.dialogService.open({viewModel: ViewLoadBalancerDialog, model: loadBalancer}).whenClosed((response: any) => {
      if (!response.wasCancelled) {}
    });
  }

  private addTemporaryRatio(loadBalancer: ZLoadBalancer): void {
    this.openDialog(LOAD_BALANCER_ACTIONS.ADD_TEMP_RATIO, loadBalancer);
  }

  private addFutureDatedRatio(loadBalancer: ZLoadBalancer): void {
    this.openDialog(LOAD_BALANCER_ACTIONS.ADD_FUTURE_DATED_RATIO, loadBalancer);
  }

  private openDialog(action: string, loadBalancer?: ZLoadBalancer): void {
    const model = {
      loadBalancers: this.itemList.items,
      loadBalancer,
      action
    };
    this.showLoader();
    this.dialogService.open({viewModel: LoadBalancerDialog, model}).whenClosed((response: any) => {
      this.hideLoader();
      if (!response.wasCancelled) {
        this.retrieveList();
      }
    });
  }

  protected toggleSearch():void {
    super.toggleSearch();
  }

  public delete(loadBalancer: ZLoadBalancer): void {
    this.loadBalancerService
      .deleteLoadBalancer(loadBalancer.id)
      .then(() => {
        this.registerToDeletedEvents();
        this.setupPlaceholders(this.itemList.items);
        logger.info(`LoadBalancer with id ${loadBalancer.id} has been deleted`)
      });
  }

  private registerToDeletedEvents(): void {
    this.webSocket.subscribe({
      name: 'com.zailab.interaction.loadbalancer.api.events.LoadBalancerDeletedEvent',
      callback: (payload: { state: { loadBalancerId: string; } }) => {
        this.itemList.items = this.itemList.items.filter((item:ZLoadBalancer) => item.id !== payload.state.loadBalancerId);
      }
    });
  }

  private deregisterToDeletedEvents(): void {
    this.webSocket.unSubscribe('com.zailab.interaction.loadbalancer.api.events.LoadBalancerDeletedEvent');
  }

  // Override methods

  protected startsWithMatch(searchExpression: string, value: any, searchParam: string): any {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: any, searchParam: string): any {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
