import { computedFrom, LogManager } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('ApplicationSessionModel');

export class ApplicationSessionModel extends BaseModel {

  public appId: string = null;

  constructor(data: ApplicationSessionModel) {
    super();
    this.mapEntity(data);
  }

  public mapEntity(data: ApplicationSessionModel): void {
    super.mapProperties(data);
  }
}