
import { ArrayTools, SessionStore, Event } from 'zailab.common';
import { LogManager, inject, bindable, computedFrom } from 'aurelia-framework';
import { WorkTypesService } from '../../../../../conversation/worktypes/work-types-service';
import { DispositionCodesService } from './../../../../dispositioncodes/disposition-codes-service';
import { DispositionModel } from './../../../../dispositioncodes/disposition-codes-model';
//@ts-zignore
import toastr from 'toastr';

const logger = LogManager.getLogger('PostInteractionProcessingFilters');

const filterVariableOperatorsMap = {
  IS: 'is',
  IS_NOT: 'is not',
  IS_ONE_OF: 'is one of',
  IS_NOT_ONE_OF: 'is not one of',
  IS_LESSER_THAN: 'is lesser than',
  IS_GREATER_THAN: 'is greater than',
  EXISTS: 'exists',
  DOES_NOT_EXIST: 'does not exist',
};

const filterVariableOperatorsReverseMap = {
  'is': 'IS',
  'is not': 'IS_NOT',
  'is one of': 'IS_ONE_OF',
  'is not one of': 'IS_NOT_ONE_OF',
  'is lesser than': 'IS_LESSER_THAN',
  'is greater than': 'IS_GREATER_THAN',
  'exists': 'EXISTS',
  'does not exist': 'DOES_NOT_EXIST',
};


@inject(Element, SessionStore, WorkTypesService, DispositionCodesService)
export class PostInteractionProcessingFilters {

  @bindable private model: any;

  public interactionTypes: string[] = ['Contact Centre Flows'];
  public interactionOutcomeGroups: string[] = ['None', 'All', 'Custom'];
  public interactionOutcomes: { [key: string]: string[] } = {
    Custom: ['Answered', 'Abandoned', 'Missed', 'Voicemail', 'Callback', 'Attempted', 'Forwarded']
  };
  public workTypeGroups: any[] = [{templateName: 'None'}, {templateName: 'All'}, {templateName: 'Custom'}];
  public workTypes: { [key: string]: any[] } = { Custom: [] };
  public dispositionGroups: any[] = [{name: 'NA'}, {name: 'All'}, {name: 'Custom'}];
  public dispositionList: { [key: string]: any[] } = { Custom: null };
  public dispositionCodes: any[] = [];
  public dispositionCategories: any[] = [];
  public dispositionSubCategories: any[] = [];

  public selectedInteractionOutcome: string;
  public _selectedDispositionList: any; // component binding
  private selectedDispositionList: any;
  public selectedDispositionCode: any;
  public selectedDispositionCategory: any;
  public selectedDispositionSubCategory: any;
  public oldFilterVariables: [{
    variableName: string,
    variableOperator: string,
    variableValue: string,
  }];
  public filterVariable: {
    variableName: string,
    variableOperator: string,
    variableValue: string,
  };
  public filterVariableOperators = [
    "is",
    "is not",
    "is one of",
    "is not one of",
    "is lesser than",
    "is greater than",
    "exists",
    "does not exist"
  ];

  public isEditing = {
    outcomes: false,
    workTypes: false,
    dispositions: false,
    filterVariables: false,
  };
  
  public tableData = [];
  public tableColumns = ['Name', 'Operation', 'Value'];
  public columnsRegex = [/^(?:(?![+()]).)*$/, /^(?:(?![+()]).)*$/, /^(?:(?![+()]).)*$/];
  public tableTypes = [
    {
      type: 'text',
      validation: 'required',
    },
    {
      type: 'dropdown',
      placeholder: 'Operation',
      validation: 'required',
      options: this.filterVariableOperators,
      direction: 'bottom',
    },
    {
      type: 'text',
      validation: 'required',
    },
  ];

  constructor(
    private element: Element,
    private sessionStore: SessionStore,
    private workTypesService: WorkTypesService,
    private dispositionCodesService: DispositionCodesService
  ) {}

  public activate(bindingContext: any): void {
    this.model = bindingContext;
    this.model.data = {
      interactionPostProcessorId: bindingContext.data.interactionPostProcessorId || undefined,
      selectedInteractionType: bindingContext.data.selectedInteractionType || this.interactionTypes[0],
      selectedInteractionOutcomes: bindingContext.data.selectedInteractionOutcomes || [],
      selectedWorktypes: bindingContext.data.selectedWorktypes || [],
      selectedDispositions: bindingContext.data.selectedDispositions ||
        (
          bindingContext.data.dispositions ? JSON.parse(JSON.stringify(bindingContext.data.dispositions)).map(d => {
            return {
              dispositionCodeIds: d.dispositionCodes && d.dispositionCodes[0] ? d.dispositionCodes[0].ids : undefined,
              name: d.description || (d.dispositionCodes && d.dispositionCodes[0] ? d.dispositionCodes[0].name : undefined),
              category: d.category,
              subCategory: d.subCategory ? {
                name: d.subCategory
              } : undefined
            };
          }) : [{name: 'NA'}]
        ),

      workTypes: bindingContext.data.workTypes,
      filterVariables: bindingContext.data.filterVariables || [],
    };
    bindingContext.data.dispositions = undefined;
    this.tableData = this.model.data.filterVariables
      ? this.model.data.filterVariables.map(item => [
        item.variableName,
        filterVariableOperatorsMap[item.variableOperator],
        item.variableValue
      ])
      : [];
    this.retrieveWorktyes();
    this.retrieveDispositions();
  }

  private retrieveWorktyes(): void {
    this.workTypesService
      .retrieveWorkTypes()
      .then(workTypes => {
        if(workTypes.length > 0) {
          workTypes = ArrayTools.sort(workTypes, 'templateName');

          for(let w of workTypes) {
            const it = {
              "id": w.id,
              "name": w.templateName,
              "templateId": w.templateId,
              "templateName": w.templateName
            }
            this.workTypes.Custom.push(it);

            if (this.model.data.workTypes) {
              let wt = this.model.data.workTypes.indexOf(w.templateId);
              if (wt >= 0) {
                let swt = this.model.data.selectedWorktypes.indexOf(wt.templateId);
                if (swt && !this.model.data.selectedWorktypes.find(swt => swt.templateId === it.templateId)) {
                  this.model.data.selectedWorktypes.push(it);
                }
              }
            }
          }
        }
      })
      .catch(() => {});
  }

  private retrieveDispositions(): void {
    this.dispositionCodesService
      .retrieveDispositions()
      .then((dispositionList) => {
        dispositionList = ArrayTools.sort(dispositionList, 'name');
        this.dispositionList.Custom = dispositionList;
      });
  }

  private retrieveDispositionCategories(dispositionListId: string):void {
    const organisationId = this.sessionStore.get.organisation.organisationId;

    this.dispositionCodesService
      .retrieveDispositionListByIdAndOrganisationId(dispositionListId,organisationId)
      .then((dispositionCategories) => {
        this.dispositionCategories = dispositionCategories.categories;
      });   
  }

  public interactionOutcomeSelected(item: string): void {
    if (!item) {
      return;
    }
    switch(item) {
      case 'None':
        this.model.data.selectedInteractionOutcomes = [];
        return;
      case 'All':
        this.model.data.selectedInteractionOutcomes = this.interactionOutcomes.Custom;
        return;
      default:
        break;
    }
    if (this.model.data.selectedInteractionOutcomes.indexOf(item) < 0) {
      this.model.data.selectedInteractionOutcomes.push(item);
    }
  }

  public worktypeSelected(item: any): void {
    if (!item) {
      return;
    }
    switch(item.templateName) {
      case 'None':
        this.model.data.selectedWorktypes = [];
        return;
      case 'All':
        this.model.data.selectedWorktypes = [...this.workTypes.Custom];
        return;
      default:
        break;
    }
    if (this.model.data.selectedWorktypes.indexOf(item) < 0) {
      this.model.data.selectedWorktypes.push(item);
    }
  }

  public dispositionListSelected(dispositionList: DispositionModel): void {
    if (!dispositionList) {
      return;
    }
    this.selectedDispositionCode = null;
    this.selectedDispositionCategory = null;
    this.selectedDispositionSubCategory = null;

    switch(dispositionList.name) {
      case 'NA':
        setTimeout(() => this._selectedDispositionList = null, 50);
        this.selectedDispositionList = null;
        this.model.data.selectedDispositions = [{
          ...dispositionList,
          id: 'NA'
        }];
        return;
      case 'All':
        setTimeout(() => this._selectedDispositionList = null, 50);
        this.selectedDispositionList = null;
        this.model.data.selectedDispositions = [{
          ...dispositionList,
          id: 'All'
        }];
        return;
      default:
        break;
    }
    
    this.selectedDispositionList = dispositionList;
    if (dispositionList.tiered) {
      this.retrieveDispositionCategories(dispositionList.id);
    } else {
      this.dispositionCodes = dispositionList.dispositionCodes || [];
    }
  }

  public dispositionCategorySelected(dispositionCategory: any): void {
    if (!dispositionCategory) {
      return;
    }
    this.model.data.selectedDispositionSubCategory = null;
    this.dispositionSubCategories = dispositionCategory.subcategories.length > 0 ?  dispositionCategory.subcategories: [];
  }

  private addDisposition(item: { dispositionCodeIds?: string[], name?: string, category?: string, subCategories?: string[] }): void {
    let all = this.model.data.selectedDispositions.find(sd => sd.id === 'All' || sd.name === 'All' || sd.name === 'NA');

    if (all) {
      this.model.data.selectedDispositions = [item]
    } else {
      this.model.data.selectedDispositions.push(item);
    }
    this.resetDispositionDropdowns();
  }

  private resetDispositionDropdowns(): void {
    setTimeout(() => {
      this._selectedDispositionList = null;
      this.selectedDispositionList = null;
      this.selectedDispositionCode = null;
      this.selectedDispositionCategory = null;
      this.selectedDispositionSubCategory = null;
    }, 50);
  }

  // ACTIONS
  public addDispositionCode(dispositionCode: any): void {
    if (!dispositionCode) {
      return;
    }
    let payload = {
      dispositionCodeIds: [dispositionCode.dispositionCodeId],
      name: dispositionCode.description
    };
    this.addDisposition(payload);
  }

  public addDispositionSubCategory(subCategory: any): void {
    let payload = {
      category: this.selectedDispositionCategory,
      subCategory: subCategory
    };
    this.addDisposition(payload);
  }

  public removeInteractionOutcome(outcome: string): void {
    this.model.data.selectedInteractionOutcomes.splice(this.model.data.selectedInteractionOutcomes.indexOf(outcome), 1);
  }

  public removeWorktype(worktype: any): void {
    this.model.data.selectedWorktypes.splice(this.model.data.selectedWorktypes.indexOf(worktype), 1);
  }

  public removeDisposition(disposition: any): void {
    this.model.data.selectedDispositions.splice(this.model.data.selectedDispositions.indexOf(disposition), 1);
    if (this.model.data.selectedDispositions.length === 0) {
      this.model.data.selectedDispositions = [{name: 'NA'}];
    }
  }

  // Edit methods
  // Outcomes
  private previousOutcomes = [];
  public editOutcomes(): void {
    this.toggleEditOutcomes(true);
    this.previousOutcomes = [...this.model.data.selectedInteractionOutcomes];
  }

  public updateInteractionOutcomes(): void {
    if (this.model.data.selectedInteractionOutcomes.length === 0) {
      return toastr.error(`Please select at least 1 outcome.`);
    }

    new Event(this.element, 'update-outcomes', { outcomes: this.model.data.selectedInteractionOutcomes });
    this.toggleEditOutcomes(false);
  }

  public cancelInteractionOutcomesUpdate(): void {
    this.model.data.selectedInteractionOutcomes = [...this.previousOutcomes];
    this.previousOutcomes = [];
    this.toggleEditOutcomes(false);
  }

  private toggleEditOutcomes(value: boolean): void {
    this.isEditing.outcomes = value;
  }

  // WorkTypes
  private previousWorkTypes = [];
  public editWorkTypes(): void {
    this.toggleEditWorkTypes(true);
    this.previousWorkTypes = [...this.model.data.selectedWorktypes];
  }

  public updateWorkTypes(): void {
    if (this.model.data.selectedWorktypes.length === 0) {
      return toastr.error(`Please select at least 1 worktype.`);
    }
  
    new Event(this.element, 'update-worktypes', { workTypes: this.model.data.selectedWorktypes });
    this.toggleEditWorkTypes(false);
  }

  public cancelWorkTypesUpdate(): void {
    this.model.data.selectedWorktypes = [...this.previousWorkTypes];
    this.previousWorkTypes = [];
    this.toggleEditWorkTypes(false);
  }

  private toggleEditWorkTypes(value: boolean): void {
    this.isEditing.workTypes = value;
  }

  // Dispositions
  private previousDispositions = [];
  public editDispositions(): void {
    this.toggleEditDispositions(true);
    this.previousDispositions = [...this.model.data.selectedDispositions];
  }

  public updateDispositions(): void {
    new Event(this.element, 'update-dispositions', { dispositions: this.model.data.selectedDispositions });
    this.toggleEditDispositions(false);
  }

  public cancelDispositionsUpdate(): void {
    this.model.data.selectedDispositions = [...this.previousDispositions];
    this.previousDispositions = [];
    this.toggleEditDispositions(false);
  }

  private toggleEditDispositions(value: boolean): void {
    this.isEditing.dispositions = value;
  }

  @computedFrom('model')
  public get hasProcessor(): boolean {
    if (!this.model) {
      return
    }
    return typeof this.model.data.interactionPostProcessorId === 'string';
  }

  public valueChanged(tableData: any): void {
    const mappedData = [];
    tableData.forEach((value) => {
      mappedData.push({
        variableName: value[0],
        variableOperator: filterVariableOperatorsReverseMap[value[1]],
        variableValue: value[2]
      });
    });
    this.model.data.filterVariables = mappedData;
    new Event(this.element, 'update-filter-variables', { filterVariables: this.model.data.filterVariables });
  }
}
