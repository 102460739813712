import { bindable, inject } from 'aurelia-framework';
import { bindingMode } from 'aurelia-binding';

import { Event } from 'zailab.common';

import './basic-dropdown.scss';

@inject(Element)
export class BasicDropdown {
  @bindable({
    attribute: 'selected-value',
    defaultBindingMode: bindingMode.twoWay,
  })
  public selectedValue: string;
  @bindable({ attribute: 'no-selected-value-message' })
  public noSelectedValueMessage?: string;
  @bindable() public list: any = [];
  @bindable({ attribute: 'display-field' })
  public displayField?: string;
  @bindable() public direction: boolean;
  @bindable public width: string;
  @bindable public height: string;
  @bindable public rounded: boolean;

  public enabled = false;
  public ulStyle = '';
  private autoHideTimer: any = null;
  private parentRect: DOMRect;

  constructor(private element: Element) {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  public toggleMenu(): void {
    this.enabled = !this.enabled;

    if (this.enabled) {
      this.cancelAutoHide();
      this.setDropdownPosition();
    }
  }

  public cancelAutoHide(): void {
    if (this.autoHideTimer) {
      clearTimeout(this.autoHideTimer);
      this.autoHideTimer = null;
    }
  }

  public startAutoHide(): void {
    this.autoHideTimer = setTimeout(() => {
      this.enabled = false;
    }, 300);
  }

  private setDropdownPosition(): void {
    this.parentRect = this.element.getBoundingClientRect();

    const top = this.parentRect.bottom + window.scrollY;
    const left = this.parentRect.left + window.scrollX;
    const width = this.parentRect.width;

    this.ulStyle = `top: ${top}px; left: ${left}px; width: ${width}px;`;
  }

  public select(item: any): void {
    this.selectedValue = this.displayField ? item[this.displayField] : item;
    this.enabled = false;
    new Event(this.element, 'change', this.selectedValue);
  }

  private onScroll(): void {
    this.enabled = false;
  }
}
