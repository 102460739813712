export const CREATE_ACTIONS = {

  FETCH_FORMATTED_SECTIONS: 'quality.assessor.forms.fetch.formatted.sections',
  CLEAR_STATE : 'quality.assessor.forms.clear.state',
  RETRIEVE_PERSONAL_INFO : 'quality.assessor.forms.retrieve.personal.info',
  SHOW_SUCCESS : 'quality.assessor.forms.show.success',
  CALCULATE_SCORE : 'quality.assessor.forms.calculate.score',
  ADD_QUESTION : 'quality.assessor.forms.add.question',
  REMOVE_QUESTION : 'quality.assessor.forms.remove.question',
  TOGGLE_EDIT_QUESTION : 'quality.assessor.forms.toggle.edit.question',
  BLANK_QUESTION_VALIDATION : 'quality.assessor.forms.blank.question.validation',
  INVALID_QUESTION_VALIDATION : 'quality.assessor.forms.invalid.question.validation',
  BLANK_YES_WEIGHT : 'quality.assessor.forms.blank.yes.weight.validation',
  BLANK_NO_WEIGHT : 'quality.assessor.forms.blank.no.weight.validation',
  NOT_NUMERIC_YES_WEIGHT : 'quality.assessor.forms.yes.weight.not.numeric',
  NOT_NUMERIC_NO_WEIGHT : 'quality.assessor.forms.no.weight.not.numeric',
  EQUAL_WEIGHT : 'quality.assessor.forms.equal.weight',
  NEGATIVE_NO_WEIGHT : 'quality.assessor.forms.negative.no.weight',
  NO_WEIGHT_NOT_WHOLE_NUMBER : 'quality.assessor.forms.not.whole.number.no',
  YES_WEIGHT_NOT_WHOLE_NUMBER : 'quality.assessor.forms.not.whole.number.yes',
  NEGATIVE_YES_WEIGHT : 'quality.assessor.forms.negative.yes.weight',
  CLEAR_VALIDATION : 'quality.assessor.forms.clear.validation',
  SECTION_VALIDATION : 'quality.assessor.forms.section.validation.reason',
  STORE_FORM : 'quality.assessor.forms.store'
};
