import {LogManager, autoinject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
/**/
import { Validation } from 'zailab.validation';
import {SessionStore, BootstrapFormRenderer} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
import {TelephoneNumberModel} from '../../../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { TableInputModel } from '../../../../../../components/custom/table-input';
/**/
const logger = LogManager.getLogger('Call Forward Dialog');

/**/
interface INumberType {
  description: string;
  value: string;
}

@autoinject
export class CallForwardDialog {

  public name: string = '';
  private validation: any;
  public nodeData: NodeModel;
  private extension: string;
  public existingConnections: Array<ConnectorModel> = [];
  public dialogHeader: string = 'Call Forward';
  private nodeConfiguration: ZNodeConfig;
  private numberTypes: INumberType[] = [];
  private selectedNumberType: INumberType;
  private extensionErrors: string;
  private telephoneNumber: TelephoneNumberModel;
  private countryCode: string = 'us';
  public tableInputHeaders: TableInputModel;
  public isValid: boolean = false;
  private preserveCLI;
  public tableData;
  public tableColumns = ['Variable Name', 'Variable Mapping'];

  constructor(
    public dialogController: DialogController,
    private sessionStore: SessionStore,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    this.numberTypes = [{description: 'Number', value: 'NUMBER'}, {description: 'Extension', value: 'EXT'}];
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    if (this.sessionStore.get && this.sessionStore.get.organisation && this.sessionStore.get.organisation.country && this.sessionStore.get.organisation.country.code) {
      this.countryCode = this.sessionStore.get.organisation.country.code;
    }
    this.extractNumber();
    this.initTableInputHeaders();
    this.name = this.nodeData.properties.name || '';
    this.validate();
  }

  private extractNumber(): void {
    let telephoneNumber: string = '';
    let isNumberValid: boolean;

    let nodeProperties = this.nodeData.properties;

    if (nodeProperties.numberType === 'EXT') {
      this.extension = nodeProperties.number;
      this.selectedNumberType = {value: 'EXT', description: 'Extension'};
    } else {
      this.selectedNumberType = {value: 'NUMBER', description: 'Number'};
      isNumberValid = nodeProperties.number ? true : false;
      telephoneNumber = nodeProperties.number;
    }

    this.telephoneNumber = new TelephoneNumberModel(telephoneNumber, '', this.countryCode, true, isNumberValid);
    this.preserveCLI = nodeProperties.preserveCLI;
  }

  private initTableInputHeaders(): void {
    if (this.nodeData.properties.sipHeaders) {
      this.tableData = Object.entries(this.nodeData.properties.sipHeaders);
    }
  }

  public valueChanged(tableData: any): void {
    const reversedObject = {};

    tableData.forEach(([key, value]) => {
      reversedObject[key] = value;
    });
    this.nodeData.properties.sipHeaders = reversedObject;
  }

  public rowAdded(): void {
    let scrollContainer = document.querySelector('#scroll-container');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }

  public validate(): void {
    if (this.name.length === 0) {
      this.isValid = false;
      return;
    }
    this.isValid = true;
  }

  get formattedNumber(): string {

    if (this.selectedNumberType.value === 'EXT') {
      return this.extension;
    } else {
      return this.telephoneNumber.number;
    }

  }

  get isValidNumber(): boolean {

    if (!this.selectedNumberType) {
      return;
    }

    if (this.selectedNumberType.value === 'EXT') {
      return this.validateExtension(true);
    } else {
      return this.telephoneNumber.telephoneNumber && this.telephoneNumber.isNumberValid ? true : false;
    }

  }

  private validateExtension(isMessageDisabled?: boolean): boolean {

    let isValid: boolean = true;
    let validationMessage: string;

    if (!this.extension) {
      validationMessage = 'Please enter a valid extension number.';
      isValid = false;
    }

    if (this.extension && this.extension.length < 5) {
      validationMessage = 'Number is too short.';
      isValid = false;
    }

    if (this.extension && this.extension.length > 5) {
      validationMessage = 'Number is too long.';
      isValid = false;
    }

    if (!isMessageDisabled) {
      this.extensionErrors = validationMessage;
    }

    return isValid;
  }

  private togglePreserveCLI(): void {
    this.preserveCLI = !this.preserveCLI;
  }

  public ok(): void {
    this.name = this.name.trim();
    if (!this.name) {
      return;
    }

    this.nodeData.properties = {
      name: this.name,
      numberType: this.selectedNumberType.value,
      number: this.formattedNumber,
      sipHeaders: this.nodeData.properties.sipHeaders,
      preserveCLI: this.preserveCLI
    };

    this.nodeData.properties.isDefined = true;

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.dialogController.ok(this.nodeData);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: {connectorIndex: 0, nodeID: this.nodeData.id},
        customExtensions: null
      })
    ];
    return _connections;
  }

}
