import {LogManager, customElement, bindable, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {HttpClient} from 'aurelia-http-client';

import {SessionStore, OplogService} from 'zailab.common';
import {TrialAccountStatusModel} from './trial-account-status-model';

const logger = LogManager.getLogger('TrialAccountStatusService');

@inject(EventAggregator, HttpClient, OplogService, SessionStore)
export class TrialAccountStatusService {
  constructor(eventAggregator, httpClient, OplogService, sessionStore) {
    this.eventAggregator = eventAggregator;
    this.httpClient = httpClient;
    this.oplog = OplogService;
    this.sessionStore = sessionStore;
  }

  subscribeToOplogUpdates(organisationId) {
    if (organisationId) {
      return new Promise(resolve => {
        resolve(this.oplog.subscribeOn('organisationId', organisationId).in('account-projector.accountStatusView'));
      });
    } else {
      this.eventAggregator.subscribe('created.organisation', () => {
        this.checkIfOrganisationHasBeenCreated();
      });
    }
  }

  subscribeToCompanyInfo(organisationId) {
    if (organisationId) {
      return new Promise(resolve => {
        resolve(this.oplog.subscribeOn('organisationId', organisationId).in('account-projector.accountStatusView'));
      });
    } else {
      this.eventAggregator.subscribe('created.organisation', () => {
        this.checkIfOrganisationHasBeenCreated();
      });
    }
  }

  checkIfOrganisationHasBeenCreated() {
    setTimeout(() => {
      if (!this.sessionStore.get.organisation) {
        this.checkIfOrganisationHasBeenCreated();
      } else {
        this.eventAggregator.publish('update.account.status');
      }
    }, 1000);
  }

  retrieveAccountStatus() {
    return new Promise((resolve, reject) => {
      this._createRequest(
        '/v1/finance/accounts/me/account-status',
        response => {
          if (response.statusCode === 204) {
            resolve(response);
          } else {
            resolve(new TrialAccountStatusModel(response));
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  retrieveCompanyInfo() {
    return new Promise((resolve, reject) => {
      resolve({companyInfo: false})
    });
  }

  _createRequest(uri, callback, errorCallback) {
    this.httpClient
      .createRequest(uri)
      .asGet()
      .send()
      .then(
        response => {
          callback(response);
        },
        error => {
          errorCallback(error);
        }
      );
  }
}
