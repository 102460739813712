import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('AudioFileModel');
/**/
export class AudioFileModel extends BaseModel {

    public id: string = null;
    public audioId: string = null;
    public groupId: string = null;
    public filename: string = null;
    public file: File = null;
    public isSelected: boolean = null;
    public canDelete: boolean = null;
    public links: string[] = null;
    public text: string = null;
    public speechLanguage: string = null;
    public speechRate: string = null;
    public timestamp: string = null;
    public readableTimeStamp: string = '';
    public otherAudio: any[] = [];

    constructor(audioFile: object) {

        super();
        super.mapProperties(audioFile);
    }
}