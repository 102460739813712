import { LogManager } from 'aurelia-framework';
import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('CampaignModel');

export default class extends BaseModel {
  campaignId = null;
  taskTemplateId = null;
  businessPartnerId = null;
  contactCentreId = null;
  archived = null;
  dialerType = null;
  notRespondingEnabled = true;
  autoAcceptTaskEnabled = true;
  webhookEnabled = false;
  externalReference = '';
  name = null;
  numberOfTasksTotalUploaded = 0;
  numberOfTasksRemaining = 0;
  numberOfTasksNotServed = 0;
  numberOfTasksServed = 0;
  numberOfTasksRequeued = 0;
  numberOfTasksCompleted = 0;
  numberOfTasksStale = 0;
  numberOfTasksCleared = 0;
  numberOfTasksPending = 0;
  numberOfTasksSuccessful = 0;
  numberOfTasksUnsuccessful = 0;
  numberOfTasksNotServedReady = 0;
  numberOfTasksRemainingReady = 0;
  numberOfTasksRequeuedReady = 0;
  tasksRemaining = 0;
  preferredAgentReschedulingEnabled = null;
  preferredAgentReschedulingTimeout = null;
  isPlaceholder;
  startDate = null;
  endDate = null;
  percentageTasksRemaining = 0;
  percentageTasksSuccessful = 0;
  percentageTasksUnsuccessful = 0;
  percentageOfTasksServed = 0;
  prospectLists = [];
  diallerOptions = [];
  diallingRules = [];
  paused = false;
  powerDiallerConcurrentCalls = 3;
  operatingHours = {};
  todayStats = {};
  maxDialAttempts = null;
  ignoreDoNotContactList = false;

  constructor(campaign) {
    super();
    if (campaign._id || campaign.id) {
      this.campaignId = campaign._id || campaign.id;
    }
    this.modelEntity(campaign);
  }

  modelEntity(campaign) {
    super.mapProperties(campaign);
    this.tasksRemaining =
      this.numberOfTasksRemaining + this.numberOfTasksPending;
    this.prospectLists = campaign.prospectListItems || [];
    this.diallerOptions = campaign.diallerOptions || {};
    this.powerDiallerConcurrentCalls =
      campaign.powerDiallerConcurrentCalls || 3;

    this.calculatePercentageOfTasksServed();
    this.calculateTasksSuccessful();
    this.calculateTasksUnsuccessful();
  }

  calculatePercentageOfTasksServed() {
    if (!this.numberOfTasksTotal) {
      this.percentageOfTasksServed = 0;
    }
    if (!this.numberOfTasksServed || !this.numberOfTasksTotal) {
      this.percentageOfTasksServed = 0;
      return;
    }
    const num = this.numberOfTasksServed / this.numberOfTasksTotal;

    let percentage = Math.round(num * 100);
    this.percentageOfTasksServed = percentage > 100 ? 100 : percentage;
  }

  calculateTasksSuccessful() {
    if (this.numberOfTasksSuccessful === 0) {
      this.percentageTasksSuccessful = 0;
      return;
    }

    const totalOfOutcomeTasks =
      this.numberOfTasksSuccessful + this.numberOfTasksUnsuccessful;
    const num = this.numberOfTasksSuccessful / totalOfOutcomeTasks;

    let percentage = Math.round(num * 100);
    this.percentageTasksSuccessful = percentage > 100 ? 100 : percentage;
  }

  calculateTasksUnsuccessful() {
    if (this.numberOfTasksUnsuccessful === 0) {
      this.percentageTasksUnsuccessful = 0;
      return;
    }

    const totalOfOutcomeTasks =
      this.numberOfTasksSuccessful + this.numberOfTasksUnsuccessful;
    const num = this.numberOfTasksUnsuccessful / totalOfOutcomeTasks;

    let percentage = Math.round(num * 100);
    this.percentageTasksUnsuccessful = percentage > 100 ? 100 : percentage;
  }
}
