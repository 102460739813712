let br = '\r\n';
/*
 */
export default class {

  boundary;
  data = [];

  constructor() {
    this._generateBoundary();
  }

  _generateBoundary() {

    this.boundary = '';
    this.boundary += Math.floor(Math.random() * 32768);
    this.boundary += Math.floor(Math.random() * 32768);
    this.boundary += Math.floor(Math.random() * 32768);
  }

  _createDisposition(item) {

    let _disposition = '--' + this.boundary + br;
    _disposition += 'Content-Disposition: form-data; name="' + item.name + '";';
    if (item.fileName) {
      _disposition += 'filename=' + item.fileName;
    }
    _disposition += br;
    _disposition += 'Content-Type: ' + item.contentType + br;
    _disposition += br;
    _disposition += item.content;
    _disposition += br;

    return _disposition;
  }

  addDisposition(item) {
    this.data.push(item);
  }

  getContent() {
    let body = br;

    for (let item of this.data) {
      body += this._createDisposition(item);
    }

    body += '--' + this.boundary + '--';
    return body;
  }
}