import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {SessionStore, OplogService} from 'zailab.common';
import {InvitationModel} from './invitation-model';
import {LdapGroupModel} from "../models/ldap-group-model";
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('LdapGroupService');

/*
 * */
@inject(HttpClient, EventAggregator, SessionStore, OplogService)
export class LdapGroupService {
  constructor(private httpClient: HttpClient, private eventAggregator : EventAggregator, private sessionStore: SessionStore, private oplogService: OplogService) {
  }

  public initialiseLdapGroupsOplog(): Promise<any> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplogService.subscribeOn('organisationId', organisationId).in('organisation-projector.displayOrganisationLdapGroupsView'));
    });
  }

  public retrieveLdapGroups(): Promise<any> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve, reject) => {
      let url = 'v1/organisation/organisations/' + organisationId + '/ldapGroups';
      this.httpClient.createRequest(url)
        .asGet()
        .send()
        .then(
          (response) => {
            let groups = [];
            if (response && response.groups) {
              groups = response.groups;
            }
            resolve(this._model(groups));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public addGroup(group: any): Promise<any> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    let payload = {
      ldapGroupId: uuidv4(),
      organisationId: organisationId,
      group: group.toPayload()
    }

    return new Promise((resolve, reject)=> {
        let url = 'v1/organisation/organisations/' + organisationId + '/ldapConnector/ldapGroups';
        this.httpClient.createRequest(url)
          .asPost()
          .withContent(payload)
          .send()
          .then(
            (response) => {
              resolve();
            },
            (error) => {
              reject(error)
            }
          )
      }
    );
  }

  public _model(groupData: LdapGroupModel[]): LdapGroupModel[] {
    let groupList = [];
    groupData.forEach(group => {
      groupList.push(new LdapGroupModel(group));
    });
    return groupList;
  }

  public deleteGroup(group: LdapGroupModel): void {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    let ldapGroupId = group.ldapGroupId;
    let url = 'v1/organisation/organisations/' + organisationId + '/ldapConnector/ldapGroups/' + ldapGroupId;
    this.httpClient.createRequest(url)
      .asDelete()
      .send();
  }
}
