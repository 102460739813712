import { EventAggregator } from 'aurelia-event-aggregator';
import { List } from 'zailab.abstract';
import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore, BootstrapFormRenderer, MESSAGE_EVENTS, FeatureFlagService } from 'zailab.common';
import { WorkTypesService } from '../work-types-service';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
// @ts-ignore
import toastr from 'toastr';

const logger = LogManager.getLogger('ViewWorkType');

@inject(Element, Router, SessionStore, WorkTypesService, ValidationControllerFactory, EventAggregator, FeatureFlagService)
export default class {

  private taskTemplateId: string;
  private version: string;
  public workType: any = {};
  public originalWorkType = null;
  name;
  flowType;
  routingGroup;
  channels;
  services = null;
  sites = null;
  skills = null;
  roles = null;
  callback;
  waitTime;
  targetResponse;
  businessValue;
  targetServiceLevel;
  ready = false;
  businessPartner;
  businessPartners;
  contactCenter;
  contactCentres;
  public businessValueConfig: List;
  public slaConfig: List;
  private validation: ValidationController;
  public isVersioning: boolean;
  public isVersioned: boolean;
  public routingGroupsEnabled: boolean;

  constructor(
    private element: Element,
    private router: Router,
    private sessionStore: SessionStore,
    private workTypesService: WorkTypesService,
    private validationControllerFactory: ValidationControllerFactory,
    private eventAggregator: EventAggregator,
    private featureFlagService: FeatureFlagService
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(params: { templateId: string, version: string }): void {
    this.version = params.version;
    this.taskTemplateId = params.templateId;
    this.retrieveWorkTypeData();
    this.retrieveFeatureFlags();
  }

  @computedFrom('flowType')
  public get isOfficeFlow(): boolean {

    if (this.workType.flowType === 'Office Flow') {
      return true;
    }
    return false;
  }

  private retrieveWorkTypeData(): void {

    this.workTypesService
      .retrieveWorkType(this.taskTemplateId, this.version)
      .then((response) => {
      if (response) {
        this.workType = { ... response };
        this.originalWorkType = { ... response };
      }

      this.setWorkTypeConfig(this.workType);
      this.ready = true;
      this.setupEditables();
    }, (error) => {
      logger.info('Could not retrieve Work Type', error);
    });
  }

  private retrieveFeatureFlags(): void {
    this.featureFlagService
      .isEnabled('RoutingGroups')
      .then(routingGroupsEnabled => {
        this.routingGroupsEnabled = routingGroupsEnabled;
      })
  }

  get canVersionWorkType(): boolean {
    if (!this.version && !this.isVersioned) {
      return true;
    }
    const originalWorkType = JSON.stringify(this.originalWorkType);
    const workType = JSON.stringify(this.workType);
    return workType !== originalWorkType;
  }

  private setupEditables(): void {
    
    this.businessValueConfig = List.Builder() //
      .customEventElement(this.element)
      .selectionType(List.CUSTOM_TYPE) //
      .title('Business Value') //
      .value(`${this.workType.businessValue}`) // Type conversion is to ensure a string is always passed in 
      .icon('arrow-chart') //
      .enableReadOnly(true)
      .build();

    let waitTime;

    if (this.workType.channel === 'Ticket') {
        waitTime = this.responseInHours && this.workType.waitTime > 1000 ? this.workType.waitTime / 3600 : this.workType.waitTime;
    } else {
      waitTime = this.responseInHours ? this.workType.waitTime / 3600 : this.workType.waitTime;
    }
    
    this.slaConfig = List.Builder() //
      .customEventElement(this.element)
      .selectionType(List.CUSTOM_TYPE) //
      .title('Target Service Level') //
      .value(`${waitTime}${this.responseInHours ? 'h' : 's'} / ${this.workType.targetResponse}%`) //
      .value1(waitTime) //
      .value2(this.workType.targetResponse) //
      .icon('dart') //
      .enableReadOnly(true)
      .build();

    this.initValidation();
  }

  private initValidation(): void {

    const targetValidation = (rule, message) => {
      ValidationRules
        .customRule('checkTargetResponseMaxValue', (value) => {
          if(value > 100) {
            return false;
          }
          return true;
        }, 'The target percentage cannot be more than 100.');

      
      ValidationRules
        .customRule('validTaskResponseTimeHoursMaxValue', (value) => {
          if(value > 120) {
            return false;
          }
          return true;
        }, message);

      ValidationRules
        .customRule('validTaskResponseTimeSecondsMaxValue', (value) => {
          if(value > 3600) {
            return false;
          }
          return true;
        }, message);

      ValidationRules
        .ensure('value2')
        .required().when(() => !this.isOfficeFlow).withMessage('Please enter a target percentage.')
        .matches(/^([0-9])+$/).when(() => !this.isOfficeFlow).withMessage('Target percentage must be in numbers.')
        .satisfiesRule('checkTargetResponseMaxValue').when(() => !this.isOfficeFlow)

        .ensure('value1')
        .required().when(() => !this.isOfficeFlow).withMessage('Please enter a response time.')
        .matches(/^([0-9]+\.?[0-9]*|\.[0-9]+)$/).when(() => !this.isOfficeFlow).withMessage('Response time must be in numbers.')
        .satisfiesRule(rule).when(() => !this.isOfficeFlow).withMessage(message)
        .on(this.slaConfig);
    }
    if(this.responseInHours) {
      targetValidation('validTaskResponseTimeHoursMaxValue', 'The response time cannot be more than 120.');
    } else {
      targetValidation('validTaskResponseTimeSecondsMaxValue', 'The response time cannot be more than 3600.');
    }
  }

  private setWorkTypeConfig(workType: any): void {

    if (workType) {
      this.name = workType.name;
      this.channels = workType.channel;
      this.services = workType.service.serviceName;
      this.sites = workType.sites;
      this.roles = workType.roles;
      this.flowType = workType.flowType;
      this.businessValue = workType.businessValue;
      this.targetResponse = workType.targetResponse;
      this.waitTime = workType.waitTime;
      this.businessPartner = workType.businessPartner && workType.businessPartner.name ? [workType.businessPartner] : null;
      this.businessPartners = workType.businessPartners && workType.businessPartners.length > 0 ? workType.businessPartners : null;
      this.contactCenter = workType.contactCentre && workType.contactCentre.name ? [workType.contactCentre] : null;
      this.contactCentres = workType.contactCentres && workType.contactCentres.length > 0 ? workType.contactCentres : null;

      if (workType.callbackAssignedFlows) {
        this.configCallbacks(workType);
      }

      if (workType.skillGroups) {
        this.configSkills(workType);
      }

      if (workType.targetResponse && workType.waitTime) {
        this.configTargetResponse(workType);
      }

      if (workType.businessValue) {
        this.businessValue = [workType.businessValue];
      }
    }
  }

  private configCallbacks(workType: any): void {

    let callbacksList = [];
    let orderedList = [];
    let callbackString = '';

    let _callbacks = workType.callbackAssignedFlows;

    for (let _callback of _callbacks) {
      orderedList.push(_callback.flowName);
    }
    orderedList.sort();

    for (let item of orderedList) {
      callbackString += item;
      callbackString += ', ';
    }
    // @ts-ignore
    callbacksList = callbackString.substring(0, callbackString.length - 2);

    this.callback = callbacksList;
  }

  private configSkills(workType: any): void {

    let _list = [];

    let _groupItems = workType.skillGroups;

    if (_groupItems) {

      for (let _item in _groupItems) {

        _groupItems[_item].skills.forEach((_skill) => {

          if (_skill.mandatory) {

            _list.push(_skill.skillName + ' (Mandatory)');
          } else {

            _list.push(_skill.skillName);
          }
        });
      }
    }

    _list.sort();

    this.skills = _list
  }

  private configTargetResponse(workType: any): void {

    this.targetServiceLevel = [
      { targetData: this.targetResponse + '%' },
      { targetData: this.waitTime + 's' }
    ];

    if (workType.channel === 'Email' || workType.channel === 'SMS' || workType.channel === 'Outbound Call') {

      this.waitTime = (this.waitTime / 60) / 60;
      this.targetServiceLevel[1].targetData = this.waitTime + 'hrs';
    }
  }

  public back(): void {
    this.router.navigate('worktypes');
  }

  public deactivate(): void {
    this.workType = null;
  }

  public cancelBusinessValueEdit(): void {
    this.businessValueConfig.value = this.workType.businessValue;
  }

  public cancelSLAEdit(): void {
    const waitTime = this.responseInHours ? this.workType.waitTime / 3600 : this.workType.waitTime;
    this.slaConfig.value1 = waitTime;
    this.slaConfig.value2 = this.workType.targetResponse;
  }

  public updateSLAValue(): void {
    this.validation
      .validate()
      .then(_validation => {
        if (!_validation.valid) {
          return;
        }
        this.updateWorkType(null, this.slaConfig.value1, this.slaConfig.value2);
      });
  }

  public updateBusinessValue(): void {
    this.validation
      .validate()
      .then(_validation => {
        if (!_validation.valid) {
          return;
        }
        this.updateWorkType(this.businessValueConfig.value);
      });
  }

  private updateWorkType(businessValue? : number, slaWaitTime?: number, targetResponse?: number): void {
    if (slaWaitTime && targetResponse) {
      this.slaConfig.value = `${slaWaitTime}${this.responseInHours ? 'h' : 's'} / ${targetResponse}%`;
      slaWaitTime = this.responseInHours ? slaWaitTime * 3600 : slaWaitTime;
    }
    this.workTypesService
      .updateWorkType(this.taskTemplateId, businessValue, slaWaitTime, targetResponse)
      .then(() => {
        this.workType.businessValue = businessValue || this.workType.businessValue;
        this.workType.slaWaitTime = slaWaitTime || this.workType.slaWaitTime;
        this.workType.targetResponse = targetResponse || this.workType.targetResponse;
      })
      .catch(() => {
        this.cancelBusinessValueEdit();
        this.cancelSLAEdit();
      });
  }

  public versionWorkType(): void {
    this.isVersioning = true;
    
    this.workTypesService
      .versionWorkType(this.taskTemplateId)
      .then(() => {
        this.isVersioning = false;
        this.isVersioned = true;
        this.displayToastr(MESSAGE_EVENTS.SUCCESS, 'This Work Type has been versioned.');
        this.originalWorkType = { ...this.workType };
        this.back();
      })
      .catch(error => {
        logger.error(error);
        this.isVersioning = false;
      });
  }


  private displayToastr(eventName: string, message: string): void {
    let options = Object.assign({}, toastr.options);
    toastr.options = {
      positionClass: 'toast-top-center'
    };
    this.eventAggregator.publish(eventName, message);
    toastr.options = options;
  }

  @computedFrom('workType.channel')
  public get responseInHours(): boolean {
    if (!this.workType) {
      return null;
    }
    let channelName = this.workType.channel;
    return channelName === 'Email' || channelName === 'SMS' || channelName === 'Outbound Call' || channelName === 'Ticket';
  };
}
