import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
/**/
const logger = LogManager.getLogger('ConfirmArchiveDialog');
/**/
@autoinject()
export class ConfirmArchiveDialog {
  constructor(private dialogController: DialogController) {}

  private confirm(): void {
    this.dialogController.ok();
  }

  private cancel(): void {
    this.dialogController.close();
  }
}
