import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/**/
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';

const logger = LogManager.getLogger('TextIVRDialog');

@autoinject
export class TextIVRDialog {
  private nodeData: NodeModel;
  private steps: any[];
  private existingConnections: ConnectorModel[];
  private isAddingContent: boolean = false;
  private nodeConfiguration: ZNodeConfig;

  constructor(
    private dialogController: DialogController,
    private eventAggregator: EventAggregator
  ) {}

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(
      JSON.stringify(this.nodeConfiguration.nodeDefinition)
    ); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.initSteps();
  }

  private initSteps(): void {
    const version = this.nodeData.version;
    const data = {
      ...this.nodeData.properties,
      version,
    };

    this.steps = [
      {
        name: 'Name',
        description: '',
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/text-ivr/steps/name/name'
        ),
        active: true,
        hasAttributes: true,
        completed: false,
        data,
      },
      {
        name: 'Message',
        description: '',
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/text-ivr/steps/message/message'
        ),
        hasAttributes: true,
        completed: false,
        data,
      },
      {
        name: 'Output',
        description: '',
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/text-ivr/steps/output/output'
        ),
        hasAttributes: true,
        completed: false,
        data,
      },
    ];
  }

  public async complete(wizardData: any): Promise<void> {
    const properties: any = wizardData.detail;
    properties.timeoutDuration = this.getParsedValue(
      properties.timeoutDuration
    );
    const updatedNode: NodeModel = await this.mapNodeProperties(properties);
    updatedNode.config = null;
    this.dialogController.ok(updatedNode);
  }

  private getParsedValue(value: string | number): number {
    if (typeof value === 'string') {
      const parsedValue = Number(value);
      if (isNaN(parsedValue)) {
        console.warn('> Error parsing value');
      }
      return parsedValue;
    } else {
      return value;
    }
  }

  private async mapNodeProperties(_properties: any): Promise<NodeModel> {
    let updatedConnections: Array<ConnectorModel> = [];
    updatedConnections = await this.mapConnections(
      _properties.outputs,
      _properties.timeoutDestination,
      this.existingConnections
    );

    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    this.nodeData.properties = _properties;
    this.nodeData.properties.isDefined = true;

    return new NodeModel(this.nodeData);
  }

  private mapConnections(
    outputs: string[],
    timeoutDestination: boolean,
    existingConnections: ConnectorModel[]
  ): Promise<ConnectorModel[]> {
    return new Promise((resolve) => {
      const nodeID: string = this.nodeData.id;
      let connections: ConnectorModel[] = [];

      let promises = [];
      outputs.forEach((output) =>
        promises.push(
          new Promise((resolve) => {
            connections.push(
              new ConnectorModel({
                name: output,
                source: {
                  connectorIndex: connections.length,
                  nodeID: this.nodeData.id,
                },
              })
            );
            resolve({});
          })
        )
      );

      Promise.all(promises).then(() => {
        if (timeoutDestination) {
          const connection: ConnectorModel = new ConnectorModel({
            name: 'Timeout',
            source: { connectorIndex: connections.length, nodeID },
          });
          connections.push(connection);
        }

        const newConnections = this.mapExistingConnections(
          connections,
          existingConnections
        );
        return resolve(newConnections);
      });
    });
  }

  private mapExistingConnections(
    updatedConnections: ConnectorModel[],
    existingConnections: ConnectorModel[]
  ): ConnectorModel[] {
    let connectorIndex: number = 0;
    updatedConnections.forEach((connection) => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest =
          existingConnections[connectorIndex].dest;
      }
      connectorIndex++;
    });
    return updatedConnections;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
