import { autoinject, LogManager } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import moment from 'moment';
import { AudioService, AUDIO_ASSETS, MESSAGE_EVENTS } from 'zailab.common';
import { InteractionService } from "./interaction-service";
import { InteractionModel } from "../interaction-model";
import { IncomingInteractionDialog } from "./incoming-interaction-dialog";
import { IncomingTaskDialog } from "./incoming-task-dialog";
import { INTERACTION_ACTIONS } from "./interaction-actions";
import {InputDialogMessageModel} from "../input-dialog-message-model";
import {InputDialog} from "./input-dialog";
import {DiallerMessageModel} from "../dialler-message-model";

//@ts-ignore
import toastr from 'toastr';
import { TicketModel } from './ticket-model';
import {RoutingStatusService} from "../../member/routing-status/routing-status-service";
import {SessionStore} from "../../../../_common/stores/session-store";
import {ROUTING_STATUS} from "../../member/routing-status/routing-status";
import { DiallerState } from '../../../interaction/call/liveinteraction/dialler-state';

const logger = LogManager.getLogger('Interaction');

@autoinject
export class Interaction {

  private EVENTS = {
    interaction: 'Interaction',
    inputDialog: 'InputDialogMessage',
    diallerMessage: 'DiallerMessage',
    message: 'Message',
    messageAll: 'message.ALL',
    task: 'Task'
  };
  private subscriptions: { [k: string]: Subscription } = {};
  private emailSent: boolean = false;
  private SMSSent: boolean = false;
  private cachedMetadata: any;
  private onCall: boolean = false;

  constructor(
      private eventAggregator: EventAggregator,
      private audioService: AudioService,
      private dialogService: DialogService,
      private interactionService: InteractionService,
      private routingStatusService: RoutingStatusService,
      private sessionStore: SessionStore,
      private diallerState: DiallerState
    ) {
    this.interactionService.subscribeToInteractionNotifications();
    this.subscribeToInteractionMessages();
    this.subscribeToInternalEmailInteractionStarted();
    this.subscribeToInternalSMSInteractionStarted();
    this.subscribeToEndAllInteractions();
    this.subscribeToEmailInteractionStarted();
    this.subscribeToSMSInteractionStarted();
  }

  private subscribeToInteractionMessages(): void {
    this.interactionService.onWebSocketEvent(
      this.EVENTS.interaction,
      (interaction: InteractionModel | TicketModel) => this.handleInteraction(interaction),
      (data: any) => this.interactionService.pongWebsocket(data));
    this.interactionService.onWebSocketInputDialog(this.EVENTS.inputDialog, (inputDialogMessage: InputDialogMessageModel) => this.handleInputDialog(inputDialogMessage));
    this.interactionService.onWebSocketDiallerMessage(this.EVENTS.diallerMessage, (diallerMessage: DiallerMessageModel) => this.handleDiallerMessage(diallerMessage));
    this.interactionService.onWebSocketEvent(this.EVENTS.message, (interaction: InteractionModel) => this.handleMessage(interaction));
    this.interactionService.onWebSocketEvent(this.EVENTS.messageAll, (interaction: InteractionModel) => this.handleMessage(interaction));
    this.interactionService.onWebSocketEvent(this.EVENTS.task, (interaction: InteractionModel) => this.handleTask(interaction));
  }

  private handleInputDialog(inputDialogMessage: InputDialogMessageModel): void {
    if('OPEN' === inputDialogMessage.action){
      this.dialogService.open({ viewModel: InputDialog, model: inputDialogMessage }).whenClosed(response => {
        if (response.wasCancelled) {
          // @ts-ignore
          if (response.output && response.output.rejected === false) {
            return;
          } else {
            // @ts-ignore
            this.interactionService.cancelInputDialog(response.output.inputDialogMessage);
          }
        } else {
          // @ts-ignore
          this.interactionService.captureInputDialog(response.output.inputDialogMessage)
        }
      });
    } else if ('CANCEL' === inputDialogMessage.action){
      this.dialogService.controllers.forEach(_controller => {
        let controller = _controller.controller;
        // @ts-ignore
        if (controller.viewModel && controller.viewModel.inputDialogMessage && (controller.viewModel.inputDialogMessage.interactionId === inputDialogMessage.interactionId)) {
          _controller.cancel({
            rejected: false
          });
        }
      });
    }
  }

  private handleDiallerMessage(diallerMessage: DiallerMessageModel): void {
    let action = diallerMessage.action;
    let reason = diallerMessage.reason;

    if (action === 'LOGOUT') {
      let logoutMessage = 'You have been logged out from the dialler: <br/>' + diallerMessage.reason;
      this.displayToastr(MESSAGE_EVENTS.ERROR, logoutMessage);
      this.diallerState.logError(logoutMessage);
    }

    if (action === 'LOGIN') {
      this.displayToastr(MESSAGE_EVENTS.SUCCESS, 'You have been logged in to the dialler');
      this.diallerState.logSuccess('You have been logged in to the dialler');
      if (reason === 'Predictive') {
        this.routingStatusService.retrieveRoutingStatus(this.sessionStore.get.user.memberId).then(data => {
          if (data.routingStatus !== ROUTING_STATUS.READY) {
            this.displayToastr(MESSAGE_EVENTS.SUCCESS, 'Change your routing status to \'ON DUTY\' to begin receiving calls');
            this.diallerState.logSuccess('Change your routing status to \'ON DUTY\' to begin receiving calls');
          }
        });

      }
    }

    if (action === 'ERROR'){
      let errorMessage = '<b>Dialler Error</b><br/>' + diallerMessage.reason;
      this.displayToastr(MESSAGE_EVENTS.ERROR_IMPORTANT, errorMessage);
      this.diallerState.logError(errorMessage);
    }

    if (action === 'NOTICE') {
      let noticeMessage = '<b>Dialler Message</b><br/>' + diallerMessage.reason;
      this.displayToastr(MESSAGE_EVENTS.SUCCESS, noticeMessage);
      this.diallerState.logSuccess(noticeMessage);
    }

  }

  private displayToastr(eventName: string, message: string): void {
    let options = Object.assign({}, toastr.options);
    toastr.options = {
      positionClass: 'toast-top-center'
    };
    this.eventAggregator.publish(eventName, message);
    toastr.options = options;
  }


  private handleInteraction(interaction: any): void {

    const eventStrategy = {
      MemberNotifiedOfInboundFlowInteraction: () => this.handleIncomingCall(interaction),
      MemberConnectedToInboundFlowInteraction: () => this.handleInteractionConnected(interaction),
      MemberConnectedToOutboundFlowInteraction: () => this.handleInteractionConnected(interaction),
      MemberStartedOutboundFlowInteraction: () => this.handleOutboundInteractionConnected(interaction),
      MemberMissedInboundFlowInteraction: () => this.handleMissedInteraction(),
      MemberDisconnectedFromInboundFlowCall: () => this.handleDisconnectedInboundFromCall(interaction),
      MemberDisconnectedFromOutboundFlowCall: () => this.handleDisconnectedFromOutboundFlowCall(interaction),
      MemberStartedInteraction: () => this.handleStartedInteraction(interaction),
      OutboundFlowEndpointNotified: () => this.handleOutboundEndpointNotified(interaction),
      DiallerTaskRescheduled: () => this.handleDiallerTaskRescheduled(interaction)
    };
    // @ts-ignore
    if (eventStrategy[interaction.type]) {
      // @ts-ignore
      eventStrategy[interaction.type]();
    } else {
      logger.warn('Unable to take action on interaction', interaction);
    }
  }

  private handleMessage(interaction: InteractionModel): void {
    interaction.canAccept = true;
    const eventStrategy = {
      IncomingMessage: () => this.notifyOfIncomingMessage(interaction),
      MessageConnected: () => this.handleInteractionConnected(interaction),
      MissedMessage: () => this.handleMissedInteraction(),
      EndMessage: () => this.handleMessageEnded(interaction)
    };

    if (eventStrategy[interaction.type]) {
      eventStrategy[interaction.type]();
    }
  }

  private handleTask(interaction: InteractionModel): void {
    const eventStrategy = {
      IncomingTask: () => this.notifyOfIncomingTask(interaction),
      ConnectTask: () => this.handleInteractionConnected(interaction),
      MissedTask: () => this.handleMessageEnded(interaction),
      RescheduleTask: () => this.handleDiallerTaskRescheduled(interaction)
    };

    if (eventStrategy[interaction.type]) {
      eventStrategy[interaction.type]();
    }
  }

  private notifyOfIncomingMessage(interaction: InteractionModel): void {
    if(interaction.channel === 'chat'){
      let autoAnswer = interaction.metaData.messageAutoAnswer;
      if(autoAnswer && autoAnswer === 'true') {
        this.interactionService.acceptMessage(interaction);
        return;
      }
    }
    this.audioService.play(AUDIO_ASSETS.INCOMING_MESSAGE_NOTIFICATION, { loop: true, startTime: 0, duration: 3000 });
    this.dialogService.open({ viewModel: IncomingInteractionDialog, model: interaction }).whenClosed(response => {
      if (response.wasCancelled) {
        // @ts-ignore
        if (response.output && response.output.rejected === false) {
          return;
        } else {
          this.interactionService.rejectMessage(interaction);

        }
      } else {
        this.interactionService.acceptMessage(interaction);
      }
      this.audioService.stop();
    });
  }

  private notifyOfIncomingTask(interaction: InteractionModel): void {
    this.audioService.play(AUDIO_ASSETS.INCOMING_TASK_NOTIFICATION, { loop: true, startTime: 0, duration: 3000 });
    this.dialogService.open({ viewModel: IncomingTaskDialog, model: interaction }).whenClosed(response => {
      // @ts-ignore
      if (response.output) {
        // @ts-ignore
        if (response.output.accepted) {
          this.interactionService.acceptTask(interaction);
          // @ts-ignore
        } else if (response.output.rejected) {
          this.interactionService.rejectTask(interaction);
        }
      }
      this.audioService.stop();
    });
  }

  private handleMessageEnded(interaction: InteractionModel): void {
    if (!interaction.interactionId && (interaction.channel.toLowerCase() === 'sms' || interaction.channel.toLowerCase() === 'email' || interaction.taskDescription !== 'CALLBACK REQUESTED')) {
      this.eventAggregator.publish(MESSAGE_EVENTS.INTERACTION_BLOCKED, interaction);
    }
  }

  private handleIncomingCall(interaction: any): void {

    this.audioService.play(AUDIO_ASSETS.RING1, { loop: true });
    this.dialogService.open({ viewModel: IncomingInteractionDialog, model: interaction }).whenClosed(() => this.audioService.stop());
  }

  private handleOutboundInteractionConnected(interaction: InteractionModel): void {
    if (this.onCall && !interaction.isDiallerCall) {
      return;
    }
    this.onCall = true;
    this.handleInteractionConnected(interaction);
  }

  private handleOutboundEndpointNotified(interaction: InteractionModel): void {
    if (interaction.showConversationCard) {
      this.eventAggregator.publish(INTERACTION_ACTIONS.OUTBOUND_ENDPOINT_NOTIFIED, interaction);
    }
  }


  private handleDiallerTaskRescheduled(interaction: InteractionModel): void {

    let dateTime = 'a later date';

    if (interaction.metaData && interaction.metaData['timestamp']) {
      let timestamp = interaction.metaData['timestamp'];
      dateTime = moment(timestamp).toDate();
    }

    this.eventAggregator.publish(MESSAGE_EVENTS.INFO, 'Task has been rescheduled to ' + dateTime + '.');
    this.diallerState.logInfo('Task has been rescheduled to ' + dateTime + '.');
    this.interactionService.addRescheduledTask(interaction.interactionId);
  }

  private handleInteractionConnected(interaction: InteractionModel): void {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | handleInteractionConnected ', interaction);
    this.cancelDialogs();
    if (interaction.showConversationCard || interaction.channel === 'callback') {
      this.eventAggregator.publish(INTERACTION_ACTIONS.CONNECTED_INTERACTION, interaction);
    }
  }

  private handleMissedInteraction(): void {
    this.dialogService.controllers.forEach(controller => {
      // @ts-ignore
      if (this.isInboundInteraction(controller.controller)) {
        controller.cancel();
      }
    });
  }

  private handleDisconnectedInboundFromCall(interaction: InteractionModel): void {
    this.onCall = false;
  }

  private handleDisconnectedFromOutboundFlowCall(interaction: any): any {
    this.onCall = false;
    this.onInteractionEnded(interaction);
    this.eventAggregator.publish(INTERACTION_ACTIONS.MEMBER_CONNECTED_STATE, false);
    this.eventAggregator.publish(INTERACTION_ACTIONS.MEMBER_DISCONNECTED_FROM_OUTBOUND_FLOW_CALL, interaction);
  }

  handleStartedInteraction(interaction: InteractionModel): void {
    if (this.emailSent) {
      this.emailSent = false;
      this.cachedMetadata = null;
    } else if (this.SMSSent) {
      this.SMSSent = false;
      this.cachedMetadata = null;
    } else {
      logger.info('Unexpected started interaction received.');
      return;
    }
    this.handleInteractionConnected(interaction);
  }

  private onInteractionEnded(interaction: any): void {
    if (!interaction.payload) {
      return;
    }
    this.eventAggregator.publish('outbound.interaction.ended', interaction);
  }

  private isInboundInteraction(controller: DialogService): boolean {
    // @ts-ignore
    return controller.viewModel && controller.viewModel.interaction && (controller.viewModel.interaction.type === 'IncomingInteraction' || controller.viewModel.interaction.type === 'MemberNotifiedOfInboundFlowInteraction' || controller.viewModel.interaction.type === 'IncomingMessage');
  }

  private subscribeToInternalEmailInteractionStarted(): void {
    this.subscriptions.emailInteractionStarted = this.eventAggregator.subscribe('email.interaction.started', (metadata) => {
      this.emailSent = true;
      this.cachedMetadata = metadata;
    });
  }

  private subscribeToInternalSMSInteractionStarted(): void {
    this.subscriptions.smsInteractionStarted = this.eventAggregator.subscribe('sms.interaction.started', (metadata) => {
      this.SMSSent = true;
      this.cachedMetadata = metadata;
    });
  }
  
  private subscribeToEndAllInteractions(): void {
    this.subscriptions.endAllInteractions = this.eventAggregator.subscribe('end.all.interactions', (contactCardInteractions) => {
      this.endInteractions(contactCardInteractions);
    });
  }

  private endInteractions(interactions: Array<InteractionModel>): void {
    if (interactions && interactions.length > 0) {
      interactions.forEach((contactCardInteraction) => this.interactionService.endInteraction(contactCardInteraction));
    }
  }

  private cancelDialogs(): void {
    this.dialogService.controllers.forEach(controller => controller.cancel({ rejected: false }));
  }

  private subscribeToEmailInteractionStarted(): void {
    this.subscriptions.emailInteractionStarted = this.eventAggregator.subscribe('email.interaction.started', (metadata) => {
      this.emailSent = true;
      this.cachedMetadata = metadata;
    });
  }

  private subscribeToSMSInteractionStarted(): void {
    this.subscriptions.smsInteractionStarted = this.eventAggregator.subscribe('sms.interaction.started', (metadata) => {
      this.SMSSent = true;
      this.cachedMetadata = metadata;
    });
  }

  public detached(): void {
    this.interactionService.removeSubscriptions();
    for (let key in this.subscriptions) {
      let subscription = this.subscriptions[key];
      if (subscription.dispose) {
        subscription.dispose();
      }
    }
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }
}
