import { inject, computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { SessionStore } from 'zailab.common';

const testNumbers = {
  US: '+1 833 888 4199',
  ZA: '087 405 2924'
};

@inject(DialogController, SessionStore)
export class CreatedOrganisation {

  videoVisible;
  
  constructor(dialogController, sessionStore) {
    this.dialogController = dialogController;
    this.sessionStore = sessionStore;
  }

  @computedFrom('sessionStore.get.organisation')
  get testNumber() {
    const organisation = this.sessionStore.get.organisation;
    if (organisation) {
      return testNumbers[organisation.country.code];
    }
    return '';
  }

  cancel() {
    this.dialogController.cancel();
  }

  toggleVideoVisibility() {
    this.videoVisible = !this.videoVisible;
  }
}
