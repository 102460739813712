import { autoinject } from 'aurelia-dependency-injection';

import { ArrayTools, SessionStore, ZaiForm } from 'zailab.common';
import { BusinessPartnerService } from '../../../../../businesspartner/business-partner-service';
import { ContactCenterService } from '../../../../../contactcenter/contact-center-service';
import { TeamsService } from '../../../../../member/teams/teams-service';
import { MembersService } from '../../../../../member/members/members-service';
import { AudienceTypeReverseMap } from '../../dashboard-alerts-dialog';

export interface ITab2GeneralConfiguration {
  id: string;
  inAppAlert: boolean;
  copyFrom: string;
  audienceType: string;
  audiences: { value: string, label: string, icon?: string, group?: string }[];
  audienceIds: any[];
  emailAlert: boolean;
  emailAlerts: boolean;
  emails: string[];
  valid?: boolean;
  inAppAlertList: any[];
}

enum AudienceType {
  ORGANISATION = 'Organisation',
  BUSINESS_PARTNER = 'Business Partner',
  CONTACT_CENTER = 'Contact Center',
  TEAM = 'Team',
  MEMBER = 'Member',
}

@autoinject()
export class Tab2AudienceConfiguration {
  public form: any[][];
  public formData: { name: string; valid: boolean };
  public loading: boolean;
  public validateForm: () => void;

  public tableColumns = ['Email'];
  public tableData = [];
  public tableTypes;

  private model: { data: ITab2GeneralConfiguration };
  private audienceTypes = [
    AudienceType.ORGANISATION,
    AudienceType.BUSINESS_PARTNER,
    AudienceType.CONTACT_CENTER,
    AudienceType.TEAM,
    AudienceType.MEMBER,
  ];
  public audienceSearchText;
  private audiences = [{ value: 'ALL', label: 'Everyone', icon: 'globe' }];
  public businessPartnerOptions: any[];
  public contactCenterOptions: any[];
  public teamOptions: any[];
  public memberOptions: any[];

  private pageNumber = 0;
  private pageSize = 500;
  public showMoreEnabled = true;
  public noMoreMembers = false;
  public searchText = '';

  constructor(
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private teamService: TeamsService,
    private sessionStore: SessionStore,
    private memberService: MembersService,
  ) {}

  public async activate(bindingContext: {
    data: ITab2GeneralConfiguration;
  }): Promise<void> {
    this.model = bindingContext;
    this.model.data.emails.forEach((email) => this.tableData.push([email]));
    this.loading = true;
    this.initDisplay();
  }

  private generateForm(): void {
    this.form = null;
    setTimeout(() => {
      new ZaiForm()
        .newField()
        .asCheckbox()
        .withTitle('In App Alert', '100px')
        .withFieldWidth('calc(50% - 50px)')
        .withIdentifier('enabled')
        .withValue(this.model.data.inAppAlert)
        .insertField()

        .newField()
        .asDropdown()
        .withTitle('Copy From', '100px')
        .withClass('flex--basis')
        .withIdentifier('copyFrom')
        .withDisplayField('name')
        .withOptions(this.model.data.inAppAlertList)
        .withValue(this.model.data.copyFrom)
        .insertField()

        .newRow()
        .newField()
        .asDropdown()
        .fullWidth()
        .withTitle('Audience Type', '140px')
        .withIdentifier('audienceType')
        .withDisplayField('')
        .withOptions(this.audienceTypes)
        .withValue(this.model.data.audienceType)
        .insertField()

        .newRow()
        .newField()
        .asSearchDropdown()
        .fullWidth()
        .withTitle('Audience', '140px')
        .withDisplayField('label')
        .withOptions(this.audiences)
        .withValidation(
          [{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }],
        )
        .isDisabledWhen(
          !this.model.data.audienceType || this.model.data.audienceType === AudienceType.ORGANISATION
        )
        .multiSelect(true)
        .withItemsIdentifier('selectedAudiences')
        .withValues(this.model.data.audiences)
        .insertField()

        .newRow()
        .newField()
        .asCheckbox()
        .withTitle('Email Alert', '100px')
        .withFieldWidth('calc(50% - 50px)')
        .withIdentifier('emailAlert')
        .withValue(this.model.data.emailAlert)
        .insertField()

        .finaliseForm((form) => {
          this.form = form;
          this.loading = false;
        });
    }, 5);
  }

  public async triggerFormUpdate(data: any): Promise<void> {
    this.formDataChanged({
      ...data,
      emails: this.model.data.emails,
    });
  }

  public async formDataChanged(data: any): Promise<void> {
    let formChanged = false;

    if (
      data.audienceType &&
      this.model.data.audienceType !== data.audienceType
    ) {
      if (
        this.model.data.audienceType === AudienceType.ORGANISATION
      ) {
        this.model.data.audiences = [];
        data.selectedAudiences = [];
        formChanged = true;
      } else if (data.audienceType === AudienceType.ORGANISATION) {
        this.model.data.audiences = [{ value: 'ALL', label: 'Everyone', icon: 'globe', group: 'ORGANISATION_ID' }];
        data.selectedAudiences = [{ value: 'ALL', label: 'Everyone', icon: 'globe', group: 'ORGANISATION_ID' }];
      } else {
        data.selectedAudiences = [];
      }

      await this.setSelectedAudienceType(data.audienceType);
      this.audienceSearchText = null;
      data.audience = null;
      formChanged = true;
    }

    this.model.data.audienceType = data.audienceType;
    this.model.data.audiences = data.selectedAudiences;
    this.model.data.audienceIds = data.selectedAudiences;
    this.model.data.inAppAlert = data.enabled;
    this.model.data.emailAlert = data.emailAlert;
    this.model.data.emails = data.emails;

    if (
      (data.audienceType === 'Organisation' ||
        (data.audienceType &&
          data.selectedAudiences &&
          data.selectedAudiences.length > 0)) &&
      (!data.emailAlert || (data.emailAlert && data.emails.length > 0))
    ) {
      this.model.data.valid = true;
    } else {
      this.model.data.valid = false;
    }
    if (data.copyFrom) {
      let itemToCopy = this.model.data.inAppAlertList.find(
        (item) => item.name === data.copyFrom,
      );
      this.model.data.copyFrom = null;
      this.model.data.audienceType = '';
      this.model.data.audienceIds = itemToCopy.audiences;
      this.model.data.emailAlert = itemToCopy.emailAlerts;
      this.model.data.emails = itemToCopy.emails;
      this.setSelectedAudience();
      this.model.data.emails.forEach((email) => this.tableData.push([email]));

      formChanged = true;
    }

    if (formChanged) {
      this.generateForm();
    }
  }

  public tableDataUpdated(data: string[][]): void {
    this.model.data.emails = data.map((item) => item[0]);
    this.validateForm();
  }

  public audienceChanged(audience: { label: string; value: string }): void {
    if (!audience) {
      return;
    }
    this.pageNumber = 0;
    if (
      this.model.data.audienceType === 'Organisation' ||
      (this.model.data.audienceType && this.model.data.audiences.length > 0)
    ) {
      this.model.data.valid = true;
    } else {
      this.model.data.valid = false;
    }
  }

  public searchAudience(searchText: string): void {
    this.searchText = searchText;
    this.pageNumber = 0;
    this.getMembers();
    this.model.data.audiences = [];

    if (
      this.model.data.audienceType === 'Organisation' ||
      (this.model.data.audienceType && this.model.data.audiences.length > 0)
    ) {
      this.model.data.valid = true;
    } else {
      this.model.data.valid = false;
    }
  }

  public showMore(): Promise<any[]> {
    this.pageNumber += 1;
    return this.getMembers();
  }

  private setSelectedAudienceType(audienceType: string): void {
    this.model.data.audienceType = audienceType;
    if (audienceType === AudienceType.BUSINESS_PARTNER) {
      this.audiences = this.businessPartnerOptions;
    } else if (audienceType === AudienceType.CONTACT_CENTER) {
      this.audiences = this.contactCenterOptions;
    } else if (audienceType === AudienceType.TEAM) {
      this.audiences = this.teamOptions;
    } else if (audienceType === AudienceType.MEMBER) {
      this.audiences = this.memberOptions;
    }
  }

  private async initDisplay(): Promise<void> {
    try {
      const businessPartnerPromise = this.businessPartnerService.findAll().then((results: any[]) => {
        this.businessPartnerOptions = results.map((data: any) => ({
          value: data.id,
          label: data.name,
          icon: 'worktypes',
          group: 'BUSINESS_PARTNER_ID'
        }));
      });
  
      const contactCenterPromise = this.contactCenterService.findAll().then((results: any[]) => {
        this.contactCenterOptions = results.map((data: any) => ({
          value: data.contactCentreId,
          label: data.name,
          icon: 'team-leader',
          group: 'CONTACT_CENTER_ID'
        }));
      });
  
      const teamPromise = this.teamService
        .retrieveOrganisationTeams(this.sessionStore.get.organisation.organisationId)
        .then((results: any[]) => {
          this.teamOptions = ArrayTools.sort(results.map((data: any) => ({
            value: data.teamId,
            label: data.teamName,
            icon: 'teams',
            group: 'TEAM_ID'
          })), 'label');
        });
  
      const membersPromise = this.getMembers();
  
      await Promise.all([businessPartnerPromise, contactCenterPromise, teamPromise, membersPromise]);
      
      this.setSelectedAudience();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  private async getMembers(searchText?: string): Promise<any[]> {
    let results = await this.memberService.retrieveOrganisationMembers(
      null,
      searchText || null,
      null,
      this.pageNumber,
      this.pageSize,
    );
    const mappedMembers = results.map((data: any) => {
      return { value: data.memberId, label: data.fullName, icon: 'members', email: data.email, group: 'MEMBER_ID' };
    });

    if (this.pageNumber === 0) {
      this.memberOptions = mappedMembers;

      if (this.model.data.audienceType) {
        await this.setSelectedAudienceType(this.model.data.audienceType);
      }
    } else {
      this.memberOptions = [].concat(this.memberOptions, mappedMembers);
    }
    const options = this.memberOptions.map((result) => result.email);
    this.tableTypes = [
      {
        type: 'search-dropdown',
        validation: 'email',
        options,
        direction: 'top',
        uniqueKey: true,
      },
    ];
    return mappedMembers;
  }

  private setSelectedAudience(): void {
    if (this.model.data.audienceIds.length === 0) {
      this.model.data.audienceType = 'Organisation';
      this.model.data.audiences = [{ value: 'ALL', label: 'Everyone', icon: 'members', group: 'ORGANISATION_ID' }];
      this.model.data.audienceIds = this.model.data.audiences;
      this.model.data.valid = true;
      this.generateForm();
      return;
    }

    let audiences: { value: string, label: string, icon: string }[] = [];

    this.model.data.audienceIds.forEach((_item) => {
      let item;
      // Organization Audience
      if (_item.audienceType === 'ORGANISATION_ID' || _item.group === 'ORGANISATION_ID') {
        this.model.data.audienceType = 'Organisation';
        item = { value: 'ALL', label: 'Everyone', icon: 'members', group: 'ORGANISATION_ID' };
      } 
      // Business Partner Audience
      else if (_item.audienceType === 'BUSINESS_PARTNER_ID' || _item.group === 'BUSINESS_PARTNER_ID') {
        this.model.data.audienceType = 'Business Partner';
        item = this.businessPartnerOptions.find(option => option.value === _item.value || option.value === _item.audience);
      } 
      // Contact Center Audience
      else if (_item.audienceType === 'CONTACT_CENTER_ID' || _item.group === 'CONTACT_CENTER_ID') {
        this.model.data.audienceType = 'Contact Center';
        item = this.contactCenterOptions.find(option => option.value === _item.value|| option.value === _item.audience);
      } 
      // Team Audience
      else if (_item.audienceType === 'TEAM_ID' || _item.group === 'TEAM_ID') {
        this.model.data.audienceType = 'Team';
        item = this.teamOptions.find(option => option.value === _item.value|| option.value === _item.audience);
      } 
      // Member Audience
      else if (_item.audienceType === 'MEMBER_ID' || _item.group === 'MEMBER_ID') {
        this.model.data.audienceType = 'Member';
        item = this.memberOptions.find(option => option.value === _item.value|| option.value === _item.audience);
      }

      if (item) {
        audiences.push(item);
      }
    });

    this.model.data.audiences = audiences;
    this.model.data.audienceIds = this.model.data.audiences;

    this.generateForm();
  }
}
