import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog'
/**/
import { SurveySelectModel } from './survey-select-model';
import { SurveyDialogService } from './survey-dialog-service';
import { AbstractList } from 'zailab.abstract';
import { SearchTools } from 'zailab.common';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { EmptyStateAbstract } from '../abstracts/empty-state-abstract';
import { IdDialogService } from '../id-dialog-service';
/**/
@autoinject()
export class SurveyDialog extends EmptyStateAbstract {

  private nodeData: NodeModel;
  private isValid: boolean = false;
  private surveyList: Array<object> = [];
  private selectedSurvey: any;
  private existingConnections: Array<ConnectorModel> = [];
  private isSearching: boolean = false;
  private preselectedSurvey: string;
  private oplog: any;
  private searchTerm: string;

  protected emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-survey.svg',
    heading: `There are no surveys to display.`,
    description: `To create a new survey go to Admin Dashboard > Surveys.`,
    buttonText: `Create Survey`,
    hasButton: false,
    hasExternalContentCreator: false,
    hasTip: false
  };

  constructor(public dialogController: DialogController, private surveyDialogService: SurveyDialogService, private dialogOplog: IdDialogService, protected dialogService: DialogService) {
    super(dialogService);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.preselectedSurvey = this.nodeData.properties.surveyId ? this.nodeData.properties.surveyId : null;
    this.existingConnections = this.nodeData.connections;
  }

  public attached(): void {
    this.dialogOplog.initialiseOplog('_id', 'organisationId', 'quality-assurance-projector', 'surveyTemplatesView').then(oplog => this.subscribeToOplogChanges(oplog));
    this.getSurveysBasedOnOrg();
  }

  public detached(): void {
    this.oplog.unsubscribe();
  }

  private subscribeToOplogChanges(_oplog: any): void {
    this.oplog = _oplog;
    this.oplog.on('update', () => this.getSurveysBasedOnOrg());
    this.oplog.on('insert', () => this.getSurveysBasedOnOrg());
  }

  private getSurveysBasedOnOrg(): void {
    this.surveyDialogService.getSurveysBasedOnOrgId().then(_surveys => {
      this.hasEntries = !!_surveys.length;
      this.surveyList = this.handleSurveyResults(_surveys);
      this.nodeDialogInitialised = true;
    });
  }

  public handleSurveyResults(_surveys: Array<any>): Array<any> {
    _surveys.forEach(_survey => {
      if (_survey.surveyId === this.preselectedSurvey) {
        _survey.isSelected = true;
        this.isValid = true;
      }
      _survey.hoverText = `${_survey.description}<br>${_survey.questions.length} Question${_survey.questions.length > 1 ? 's' : ''}`;
    });
    return _surveys
  }

  public select(survey: SurveySelectModel): void {
    this.deselectSurvey();
    this.selectedSurvey = survey;
    this.selectedSurvey.isSelected = true;
    this.isValid = true;
  }

  private deselectSurvey(): void {
    for (let survey of this.surveyList) {
      survey['isSelected'] = false;
    }
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  public startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.nodeData.properties = Object.assign(this.nodeData.properties, this.selectedSurvey);

    this.nodeData.properties['eventId'] = 'survey';
    this.nodeData.properties['isDefined'] = true;

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.dialogController.ok(this.nodeData);
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: { connectorIndex: 0, nodeID: this.nodeData.id },
        customExtensions: null
      })
    ];

    return _connections;
  }

  public doNothing(): void {}
}
