import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 * */
import { SitesService } from '../sites-service';
import { SessionStore, BootstrapFormRenderer } from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('MemberListView');
/*
 * */
@inject(SitesService, SessionStore, DialogController, ValidationControllerFactory)
export class AddSiteDialog {

  siteList = [];
  siteName = '';
  siteFormGroup;
  dialogHeader = "Add Site";

  constructor(sitesService, sessionStore, dialogController, validationControllerFactory) {
    this.sitesService = sitesService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param object
   * */
  activate(siteList) {
    this.siteList = siteList;
    this.initValidation();
  }

  initValidation() {

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let item of this.siteList) {
          if (item.name.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a site name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.siteFormGroup.reset();
    this.dialogController.cancel();
  }

  addSite() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.dialogController.ok(this.name);
    }, error => {
      logger.info('addSite > validation failure', error);
    });

  }

}

