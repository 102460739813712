import {inject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {EventAggregator} from 'aurelia-event-aggregator';

import {SessionStore} from 'zailab.common';

const logger = LogManager.getLogger('ChatService');

@inject(HttpClient, SessionStore, EventAggregator)
export class ChatService {

  private memberId: string;

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private eventAggregator: EventAggregator) {  }

  public initialiseChat(): void {
    this.memberId = this.sessionStore.get.user.memberId;
  }

  private authenticateUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.createRequest('chat/authenticate')
        .asPost()
        .withContent({user_id: this.memberId})
        .send()
        .then((data) => {
          resolve(data.session_token);
        }, (err) => reject(err));
    });
  }
}