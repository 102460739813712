/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZListCardHeader');
/*
 */
@customElement('z-list-card-header')
export class ZListCardHeader {

  @bindable capitals: string = '';
  @bindable color: string;

  public textTransform(): void {

    switch (this.capitals) {

      case 'all':
        this.capitals = 'uppercase';
        break;

      case 'true':
        this.capitals = 'uppercase';
        break;

      case 'first':
        this.capitals = 'capitalize'
        break;

      default:
        'none';
    }

  }
}

