export enum INTERACTION_TYPES {
  VOICE = 'Voice',
  CALLS = 'Calls',
  EMAIL = 'Email',
  SMS = 'SMS',
  CHAT = 'Chat',
  TICKET = 'Ticket',
  MAILBOX = 'Mailbox',
  VOICEMAIL = 'Voicemail',
  CALLBACK = 'Callback',
  CALLBACKS_REQUESTED = 'Callbacks Requested'
}