import { LogManager } from 'aurelia-framework';
import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('MemberInteractionModel');

export class MemberInteractionModel extends BaseModel {
  interactionId = null;
  isTask = null;
  contactId = null;
  conversationId = null;
  state = null;
  channel = null;
  direction = null;
  from = null;
  to = null;
  workType = null;
  type = null;
  payload = null;
  wrapUpChannelIds = [];
  interactionType = null;

  constructor(data) {
    super();
    super.mapProperties(data);
    if (data.taskInformation) {
      data.taskInformation.interactionId = data.interactionId;
    }
    this.payload = data.taskInformation;
  }
}
