import { computedFrom, LogManager } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';
import {IntegrationModel} from "../../../features/integrations/integration-model";

const logger = LogManager.getLogger('UserSessionModel');
const ROLES = {
  ADMINISTRATOR: 'Administrator',
  AGENT: 'Agent',
  TEAM_LEADER: 'Team Leader',
  OFFICE_EMPLOYEE: 'Office Employee',
  QA_MANAGER: 'QA Manager',
  QA: 'QA',
  CAMPAIGN_MANAGER: 'Campaign Manager'
};

export class UserSessionModel extends BaseModel {

  public passportId: string = null;
  public userId: string = null;
  public personId: string = null;
  public recipientId: string = null;
  public email: string = null;
  public username: string = null;
  public userAccessRoles: IRole[] = null;
  public token: string = null;
  public accessCode: string = null;
  public hasOutboundChannel: boolean = null;
  public hasSMSChannel: boolean = null;
  public hasEmailChannel: boolean = null;
  public teams: Array<{ teamId: string }> = [];
  public mappedTeams: Array<string> = [];

  public memberId: string = null;
  public firstName: string = null;
  public surname: string = null;
  public hasAdministratorRole = false;
  public hasTeamLeaderRole = false;
  public hasAgentRole = false;
  public hasOfficeEmployeeRole = false;
  public hasQAManagerRole = false;
  public hasQARole = false;
  public hasCampaignManagerRole = false;
  public roleForOrganisation: string = null; // temporary. to be removed when multiple roles are introduced
  public integration: IntegrationModel = null;

  constructor(data: UserSessionModel) {
    super();
    this.mapEntity(data);
    this.mappedTeams = this.teams.map(team => { return team.teamId; });
  }

  public mapEntity(data: UserSessionModel): void {
    super.mapProperties(data);
    this.validateRoles();
  }

  private validateRoles(): void {
    if (!this.userAccessRoles || !this.userAccessRoles.length) {
      return;
    }

    const roleStrategy = {
      [ROLES.ADMINISTRATOR]: () => { this.hasAdministratorRole = true; },
      [ROLES.AGENT]: () => { this.hasAgentRole = true; },
      [ROLES.TEAM_LEADER]: () => { this.hasTeamLeaderRole = true; },
      [ROLES.OFFICE_EMPLOYEE]: () => { this.hasOfficeEmployeeRole = true; },
      [ROLES.QA]: () => { this.hasQARole = true; },
      [ROLES.QA_MANAGER]: () => { this.hasQAManagerRole = true; },
      [ROLES.CAMPAIGN_MANAGER]: () => { this.hasCampaignManagerRole = true; }
    };

    for (let userAccessRole of this.userAccessRoles) {
      if (userAccessRole.memberId) {
        this.memberId = userAccessRole.memberId;
      }
      if (userAccessRole.accountType === 'ORGANISATION' && roleStrategy[userAccessRole.role]) {
        roleStrategy[userAccessRole.role]();
      }

      if (userAccessRole.role === ROLES.AGENT || userAccessRole.role === ROLES.TEAM_LEADER || userAccessRole.role === ROLES.OFFICE_EMPLOYEE || userAccessRole.role === ROLES.QA || userAccessRole.role === ROLES.QA_MANAGER) {
        this.roleForOrganisation = userAccessRole.role.replace(' ', '_').toUpperCase();
      }
    }
  }
}

interface IRole {
  organisationId: string;
  memberId: string;
  accountType: string;
  role: string;
}