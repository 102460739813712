import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';

import {ContactModel} from '../../contact/contact-model';
import {INTERACTION_ACTIONS, LOADER_ACTIONS} from '../../contact/contactcontroller/contact-controller-actions';
import {InteractionModel} from "../interaction-model";
import { TicketModel } from '../interaction/ticket-model';
//@ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('UnlinkedInteractions');

@customElement('z-unlinked-interactions')
@autoinject()
export class UnlinkedInteractions {
  @bindable private contact: ContactModel;
  @bindable interactions: Array<InteractionModel>;

  private subscription: Subscription;

  constructor(private eventAggregator: EventAggregator) { }

  public attached(): void {
    this.subscribe();
  }

  private subscribe(): void {
    this.subscription && this.subscription.dispose();
    this.subscription = this.eventAggregator.subscribe('retrieve.transcript', () => {
      if(this.contact.servedInteractions.length > 0) {
        this.selectInteraction(this.contact.servedInteractions[0]);
      }
    });
  }

  private selectInteraction(interaction: InteractionModel | TicketModel): void {
    logger.debug(this.getCurrentFormattedTime() + ' - CC | selectInteraction | manual select unlinked interaction ');
    this.eventAggregator.publish(INTERACTION_ACTIONS.SELECT_UNLINKED, interaction);
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }

  public detached(): void {
    this.subscription && this.subscription.dispose();
  }
}
