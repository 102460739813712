import { DialogController } from 'aurelia-dialog';
import { LogManager, autoinject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { v4 as uuidv4 } from 'uuid';
import { CurrentScopeValidation } from 'zailab.common';

const logger = LogManager.getLogger('AgentScriptNodeDialog');

@autoinject
export class AgentScriptNodeDialog {

  public id = '';
  public name = '';
  public names = [];
  public htmlBody = '';
  public option = '';
  public options = new AgentScriptOptions();
  public scriptErrorMessage = '';
  public dialogHeader = 'Agent Script Node';

  private config: any;
  private validation: any;

  constructor(
    private dialogController: DialogController,
    validate: CurrentScopeValidation,
  ) {
    this.validation = validate.getController();
  }

  public activate(config: any): void {
    this.config = config;
    this.names = config.names;
    if (config.edit) {
      this.id = config.cell.id;
      this.name = config.cell.name;
      this.htmlBody = config.cell.text;
      if (config.cell.connectors && config.cell.connectors.length) {
        config.cell.connectors.forEach((conn) => {
          this.options.set(conn.id, conn.name);
        });
      }
    }
    this.initValidation();
  }

  private initValidation(): void {
    ValidationRules
      .customRule('agentScriptNodeUniqueName', (value) => {
        if (this.names.length > 0) {
          for (let name of this.names) {
            if (name.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      }, 'Please enter a unique name.');
    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter the name.')
      .satisfiesRule('agentScriptNodeUniqueName')
      .on(this);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public done(): void {
    this.scriptErrorMessage = '';
    if (!this.htmlBody || this.htmlBody === '<p><br></p>') {
      this.scriptErrorMessage = 'Please enter the script text.';
    }
    this.validation.validate().then((validation) => {
      if (!validation.valid || this.scriptErrorMessage) {
        return;
      }
      const id = this.id ? this.id : uuidv4();
      const name = this.name;
      const text = this.htmlBody;
      const x = this.config.cell.posX;
      const y = this.config.cell.posY;
      const connections = this.options.get();
      this.dialogController.ok({ id, name, text, connections, x, y });
    });
  }

  public handleHtmlValueChanged = (newValueChange: any): void => {
    if (newValueChange.text === '') {
      this.scriptErrorMessage = 'Please enter the script text.';
    } else {
      this.scriptErrorMessage = '';
    }
  }

  public addOption(): void {
    const option = this.option.trim().toLowerCase();
    const values = this.options.getNames().map((v) => v.toLowerCase());
    if (!option || values.length >= 10 || values.indexOf(option) >= 0) {
      return;
    }
    this.options.add(this.option.trim());
    this.option = '';
  }

  public removeOption(option: any): void {
    this.options.remove(option);
  }
}

class AgentScriptOptions {

  private values: AgentScriptOption[] = [];
  private originalValues: AgentScriptOption[] = [];

  public get(): AgentScriptOption[] {
    return this.values;
  }

  public getNames(): string[] {
    return this.values.map((value) => {
      return value.name;
    });
  }

  public set(id: string, name: string): void {
    this.values.push({ id, name });
    this.originalValues.push({ id, name });
  }

  public add(name: string): void {
    const originalOption = this.originalValues.find((option) => option.name === name);
    const id = originalOption ? originalOption.id : uuidv4();
    this.values.push({ id, name });
  }

  public remove(name: string): void {
    const index = this.values.findIndex((option) => option.name === name);
    if (index >= 0) {
      this.values.splice(index, 1);
    }
  }
}

class AgentScriptOption {

  id: string;
  name: string;
}