import {LogManager, bindable, inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {AudioAddService, CUSTOM_EVENTS} from 'zailab.common';
import {BindingSignaler, SignalBindingBehavior} from "aurelia-templating-resources";
import { EventAggregator } from 'aurelia-event-aggregator';
import { v4 as uuidv4 } from 'uuid';
/**/
const logger = LogManager.getLogger('UploadAudioView');

@inject(Element, DialogController, AudioAddService, BindingSignaler, EventAggregator)
export class TextToSpeech {

  @bindable ttsSpeechRate: number = 0;
  @bindable ttsSpeechText: string = '';
  @bindable ttsLanguage: ZISpeechLanguage;
  @bindable isReady:boolean = false;
  @bindable audioPreviewFile:Blob;
  private audioReadableName: string;
  private audioId: string;
  private isPreviewing: boolean = false;
  private ttsFileCreated:boolean = false;
  private subscription: any;

  private languageOptions: Array<ZISpeechLanguage> = [{
    languageName: 'English (United States)',
    languageCode: 'en-us'
  }, {
    languageName: 'English (Canada)',
    languageCode: 'en-ca'
  }, {
    languageName: 'English (Great Britain)',
    languageCode: 'en-gb'
  }, {
    languageName: 'English (India)',
    languageCode: 'en-in'
  }, {
    languageName: 'English (Australia)',
    languageCode: 'en-au'
  }];

  constructor(
    private element: Element,
    private dialogController: DialogController,
    private audioService:AudioAddService,
    private audioPlayerSignal:BindingSignaler,
    private eventAggregator: EventAggregator
  ) {}

  public attached(): void {
    this.invalidate();
  }

  private ttsSpeechTextChanged(_value:string):void {
    if (this.ttsSpeechText !== '') {
      this.signalComplete();
    } else {
      this.isReady = false;
      this.invalidate();
    }
  }

  private previewTts(): void {
    this.isPreviewing = true;
    this.isReady = false;

    this.getFileName().then(() => {

      this.audioId = uuidv4();
      this.audioService.postTts(this.createPayload(), this.audioId).then(_result => {
        this.audioPreviewFile = _result;
        this.ttsFileCreated = true;
        this.audioPlayerSignal.signal('tts-speech-ready');
        this.isReady = true;
        this.isPreviewing = false;
        this.signalComplete();
      })
    });
  }

  private getFileName(): Promise<void> {
    return new Promise(resolve => {
      // Flaap: This is a workaround to retrieve the name. The name in the upper components cannot be bound in due to when these components are rendered.
      this.subscription = this.eventAggregator.subscribe('add-audio:name-retrieved', (name: string) => {
        this.audioReadableName = name;
        this.subscription.dispose();
        resolve();
      });
      this.eventAggregator.publish('add-audio:retrieve-name');
    });
  }

  private createPayload(): ZITtsAudioTabPayload {
    return {
      speechRate: this.ttsSpeechRate.toString(),
      speechLanguage: this.ttsLanguage,
      textMessage: this.ttsSpeechText,
      audioFile: this.audioPreviewFile,
      description: this.audioReadableName,
      ttsFileCreated: this.ttsFileCreated,
      audioId: this.audioId
    }
  }

  private signalComplete():void {
    this.element.dispatchEvent(
      new CustomEvent(CUSTOM_EVENTS.TTS_PAYLOAD_AVAILABLE, {
        bubbles: true,
        detail: this.createPayload()
      })
    );
  }

  private invalidate():void {
    this.element.dispatchEvent(
      new CustomEvent(CUSTOM_EVENTS.TAB_INCOMPLETE, {
        bubbles: true,
        detail: {}
      })
    );
  }
}
