import { PLATFORM } from "aurelia-framework";
import { Router, activationStrategy } from "aurelia-router";

export class Report {
  
  constructor(
    private router: Router
  ) {}

  public configureRouter(config, router): void {
    config.map([
      // @ts-ignore
      {route: 'teams',  name: 'teams', moduleId: PLATFORM.moduleName('features/organisation/report/reports/qa/teams/qa-teams-report'), nav: false, activationStrategy: activationStrategy.invokeLifecycle},
      // @ts-ignore
      {route: 'teams/:teamId',  name: 'team-agents', moduleId: PLATFORM.moduleName('features/organisation/report/reports/qa/team-agents/qa-team-agents-grid'), nav: false},
      // @ts-ignore
      {route: 'teams/:teamId/member/:memberId',  name: 'member', moduleId: PLATFORM.moduleName('features/organisation/report/reports/qa/agent/qa-agent-grid'), nav: false},

      // @ts-ignore
      {route: 'business-partner',  name: 'qa-report-teams', moduleId: PLATFORM.moduleName('features/organisation/report/reports/qa/business-partners/qa-business-partners-report'), nav: false, activationStrategy: activationStrategy.invokeLifecycle},
      // @ts-ignore
      {route: 'business-partner/:businessPartnerId',  name: 'team-agents', moduleId: PLATFORM.moduleName('features/organisation/report/reports/qa/team-agents/qa-team-agents-grid'), nav: false},
      // @ts-ignore
      {route: 'business-partner/:businessPartnerId/member/:memberId',  name: 'member', moduleId: PLATFORM.moduleName('features/organisation/report/reports/qa/agent/qa-agent-grid'), nav: false}
    ]);
    this.router = router;
  }
}
