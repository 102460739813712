declare const PLATFORM;

export function configure(aurelia: any): void {
  aurelia.globalResources(
    PLATFORM.moduleName('./basic-dropdown/basic-dropdown'),
    PLATFORM.moduleName('./confirm-dialog/confirm-dialog'),
    PLATFORM.moduleName('./custom/agent-nav-menu/agent-nav-menu'),
    PLATFORM.moduleName('./form-builder/z-form/z-form'),
    PLATFORM.moduleName('./input/input'),
    PLATFORM.moduleName('./reload-timer/reload-timer'),
    PLATFORM.moduleName('./utility-button/utility-button-v2'),
    PLATFORM.moduleName('./utility-delete-button/utility-delete-button'),
    PLATFORM.moduleName('./utility-input/utility-input-v2'),
    PLATFORM.moduleName('./utility-total/utility-total'),
    PLATFORM.moduleName('./utility/utility-bar-v2'),
    PLATFORM.moduleName('./content-placeholder/content-placeholder'),
  );
}
