import { LogManager, customElement, bindable, inject } from 'aurelia-framework';

import { Event as CustomEvent } from 'zailab.common';

const logger = LogManager.getLogger('ZPill');

@inject(Element)
@customElement('z-pill')
export class ZPill {

  @bindable() 
  public showDelete = false;

  @bindable() 
  public color = '';

  @bindable() 
  public height = '29.39px';

  @bindable() 
  public overflow = 'hidden';
  
  @bindable() 
  public whiteSpace = 'nowrap';

  @bindable() 
  public maxWidth = '';

  @bindable() 
  public textOverflow = 'ellipsis';

  private pill: HTMLElement;
  
  private bullet: HTMLElement;

  constructor(private element: Element) { }

  public bind(): void {
    this.pill.style.height = this.height;
    this.bullet.style.overflow = this.overflow;
    this.bullet.style.whiteSpace = this.whiteSpace;
    this.bullet.style.maxWidth = this.maxWidth;
    this.bullet.style.textOverflow = this.textOverflow;
  }

  public submitDeleteEvent(event: any): void {
    event.stopPropagation();
    new CustomEvent(this.element, 'delete', { event });
  }
}
