
import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController, DialogService} from 'aurelia-dialog';

const logger = LogManager.getLogger('Tab1AdditionalData');

export interface IAdditionalData {
  variableName: string,
  displayName: string
}

interface ITab1AdditionalData {
  data: IAdditionalData[]
}

@autoinject()
export class Tab1AdditionalData {
  private model: ITab1AdditionalData;
  private additionalData: IAdditionalData[];

  public tableColumns = ['Variable Name', 'Display Name'];
  public tableData = [];
  public columnsRegex = [null, /^(?:(?![+{}()\\]).)*$/];

  public activate(
    model: ITab1AdditionalData
  ): void {
    this.model = model;
    this.additionalData = model.data || [];
    let tableData = [];
    this.additionalData.forEach(row => {
      tableData.push([row.variableName, row.displayName]);
    });
    this.tableData = tableData;
  }

  public valueChanged(tableData: any): void {
    this.model.data = tableData.map(row => {
      return {
        displayName: row[1],
        variableName: row[0]
      }
    });
  }
}
