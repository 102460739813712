import { autoinject, LogManager } from "aurelia-framework";
import { Router } from 'aurelia-router';

import { SearchTools } from 'zailab.common';
import { LiveDashboardService } from '../../../interaction/dashboard/live-dashboard/live-dashboard-service';
import { ProspectListLiveDashboardState } from "./prospect-list-live-dashboard-state";

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('ContactMonitoringSettings');

@autoinject
export class ContactMonitoringSettings {

  private campaignId: string;
  private prospectListLiveDashboardState: ProspectListLiveDashboardState = new ProspectListLiveDashboardState();

  private configId: string;
  public originalColumns: any[] = [];
  public columns: any[] = [];
  public defaultSettings = {
    operator: '>=',
    value: '0',
    color: null,
    enabled: false
  };
  public statConfigLimit: number = 5;
  public ready: boolean = false;
  private draggingItem: any;
  public hasError: boolean = false;
  public loading: boolean = false;

  public colourPickerSettings = { value: "var(--primary-accent)",   buttonText: {swatches: "colors"}}
  private nonConfigurableList = [
    'Prospect List',
    'Work Type'
  ];

  constructor(
    protected router: Router,
    private liveDashboardService: LiveDashboardService
  ) { }

  public activate(params: { campaignId: string }): void {
    this.campaignId = params.campaignId;
    this.init();
  }

  private async init(): Promise<void> {
    this.retrieveList();
  }

  protected async retrieveList(): Promise<void> {
    let colourConfig, config;
    try {
      colourConfig = await this.liveDashboardService.retrieveColorConfig('PROSPECT_LIST_LIVE_DASHBOARD');
      this.configId = colourConfig.id;

      if (colourConfig.colorFormats) {
        config = await this.transformData(colourConfig.colorFormats);
      }
    } catch(e) {
      logger.warn(' > Failed to retrieve color config for contact center monitoring due to', e);
    }
    const getMappings = async () => {
      return this.prospectListLiveDashboardState.getMappings(config);
    };
    
    const sortColumns = async () => {
      try {
        const columns = await getMappings();
        const matchingTitles = [];
        const remainingTitles = [];
    
        columns.forEach((setting) => {
          const item = {
            ...setting,
            ...setting.field,
            visible: setting.field.visible === undefined ? true : setting.field.visible
          };
          if (!item.data) {
            item.data = {};
          }
          item.data.isExpanded = true;
    
          if (this.nonConfigurableList.includes(item.title)) {
            matchingTitles.push(item);
          } else {
            remainingTitles.push(item);
          }
        });
    
        const sortedMatchingTitles = matchingTitles.sort((a, b) => {
          const indexA = this.nonConfigurableList.indexOf(a.title);
          const indexB = this.nonConfigurableList.indexOf(b.title);
          return indexA - indexB;
        });
        const sortedColumns = [...sortedMatchingTitles, ...remainingTitles];
    
        return sortedColumns;
      } catch (error) {
        console.error("Error in sortColumns:", error);
        throw error;
      }
    };
    
    // Usage
    try {
      this.columns = await sortColumns();
    } catch (error) {
      console.error("Error setting columns:", error);
    }
    
    this.originalColumns = JSON.parse(JSON.stringify(this.columns));
    this.ready = true;
  }

  private transformData(inputData) {
    let transformedData = [];

    for (let key in inputData) {
      if (inputData.hasOwnProperty(key)) {
        let transformedItem = {
          title: key,
          settings: inputData[key]
        };
        transformedData.push(transformedItem);
      }
    }
    return transformedData;
  }

  public isInvalidPercentage(value: any): boolean {
    return !(value >= 0 && value <= 100);
  }

  public navigateBack(): void {
    if (this.hasError) {
      return;
    }
    setTimeout(() => {
      this.router.navigate('campaignlivedashboard/' + this.campaignId);
    }, 500);
  }

  public async save(): Promise<void> {
    this.loading = true;

    const configId = this.configId || uuidv4();
    let payload: any = {
      id: configId,
      type: 'PROSPECT_LIST_LIVE_DASHBOARD',
      colorFormats: {}
    },
    visibilityConfig = [];

    this.columns.forEach(col => {
      visibilityConfig.push({
        title: col.title,
        visible: col.visible
      });
      let settingsElements = document.querySelectorAll(`#js-live-db-${col.moddedTitle} #drag-row`);
      let config: any = Array.from(settingsElements);

      payload.colorFormats[col.title] = col.settings.map(setting => {
        let item = config.find(conf => conf.className.indexOf(`js-live-db-${col.moddedTitle}-${setting.value}`) >= 0);
        let orderIndex = config.indexOf(item);
        return {
          value: setting.value,
          color: setting.color || '',
          operator: setting.operator,
          orderIndex,
        };
      })
    });

    this.prospectListLiveDashboardState.saveSettings(visibilityConfig);
    try {
      if (!this.configId) {
        this.liveDashboardService
          .addColorConfig(payload.id, payload)
          .then(() => this.navigateBack());
      } else {
        this.liveDashboardService
          .updateColorConfig(payload.id, payload)
          .then(() => this.navigateBack());
      }
    } catch(e) {
      logger.warn('Failed to update campaign live db config ', e);
    }
    this.loading = false;
  }

  public reset(): void {
    this.ready = false;
    this.init();
  }

  public cancel(): void {
    this.router.navigate('campaignlivedashboard/' + this.campaignId);
  }

  public addRow(title: string): void {
    let item = this.columns.find(col => col.title === title);
    if (item.settings.length >= this.statConfigLimit) {
      return;
    }
    item.data.isExpanded = true;

    item.settings.push(JSON.parse(JSON.stringify(this.defaultSettings)));
    this.validateValue(item.settings, item.data.expandCollapseGroup, item.data.expandCollapseHeader, item.data.unitOfMeasurement);
  }

  public removeRow(title: string, selectorIndex: number): void {
    let item = this.columns.find(col => col.title === title);
    item.settings.splice(selectorIndex, 1);
    this.validateValue(item.settings, item.data.expandCollapseGroup, item.data.expandCollapseHeader, item.data.unitOfMeasurement);
  }

  public validateValue(settings: any, expandCollapseGroup: string, expandCollapseHeader: boolean, unitOfMeasurement: string): void {
    this.clearErrors(settings, expandCollapseGroup);

    let values = {};
    settings.forEach((setting, index) => {
      if (!values[setting.value]) {
        values[setting.value] = [];
      }
      values[setting.value].push(index);
    });

    let keys = Object.keys(values);
    keys.forEach(key => {
      if (!key && key !== '0') {
        values[key].forEach(value => {
          settings[value].errorRequired = true;
          this.hasError = true;
          this.setErrorOnGroup(expandCollapseGroup, true);
        });
      } else if (values[key].length > 1) {
        values[key].forEach((value, currentIndex) => {
          if (currentIndex === values[key].length - 1) {
            settings[value].error = true;
          } else {
            settings[value].errorDuplicate = true;
          }
          this.hasError = true;
          this.setErrorOnGroup(expandCollapseGroup, true);
        });
      } else if (unitOfMeasurement === 'Percentage') {
        values[key].forEach((value) => {
          let numberValue: number = parseInt(key);
          if (numberValue < 0 || numberValue > 100) {
            settings[value].percentageError = true;
            this.hasError = true;
            if (expandCollapseGroup && !expandCollapseHeader) {
              this.setErrorOnGroup(expandCollapseGroup, true);
            }
          }
        });
      }
    });
  }

  private setErrorOnGroup(expandCollapseGroup: string, hasError: boolean): void {
    if (!expandCollapseGroup) {
      return;
    }
    this.columns.forEach(col => {
      if (!col.data) {
        return;
      }
      if (col.data.expandCollapseGroup === expandCollapseGroup) {
        col.settings[0].hasError = hasError;
      }
    });
  }

  private clearErrors(settings: any, expandCollapseGroup: string): void {
    this.hasError = false;
    this.setErrorOnGroup(expandCollapseGroup, false);
    settings.forEach(setting => {
      setting.error = null;
      setting.errorDuplicate = false;
      setting.errorRequired = false;
      setting.percentageError = false;
    });
  }

  public toggleExpandCollapseGroup(column: any, columns: any[]): void {
    let group = column.data.expandCollapseGroup;
    column.data.isExpanded = !column.data.isExpanded;
    columns.forEach(col => {
      if (!col.data) {
        return;
      }
      if (col.data.expandCollapseGroup === group) {
        if (col.data.expandCollapseHeader) {
          col.data.isExpanded = column.data.isExpanded;
        }
        if (column.data.isExpanded) {
          col.data.expandClass = 'fade-in';
          col.data.isExpanded = true;
        } else {
          col.data.expandClass = 'fade-out';
          setTimeout(() => {
            col.data.isExpanded = false;
          }, 200);
        }
      }
    })
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string, searchParam2: string): boolean {
    try {
      let match1 = SearchTools.partialMatch(value[searchParam], searchExpression);
      if (match1) {
        return true;
      }
      let match2 = SearchTools.partialMatch(value[searchParam2], searchExpression);
      if (match2) {
        return true;
      }
    return false;
    } catch(e) {}
  }
  
  public handleDragStart(event: DragEvent): any {
    event.stopPropagation();
    let item: any = event.target;
    item.style.opacity = '0.3';
    
    this.draggingItem = item;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', item.innerHTML);
    return true;
  }

  public handleDragEnd(event: DragEvent): any {
    let dropItem: any = event.target;
    dropItem.style.opacity = '1';

    dropItem.parentElement.childNodes.forEach(item => {
      if (item.classList) {
        item.classList.remove('over');
      }
    });
    return false;
  }

  public handleDragOver(event: DragEvent): any {
    event.preventDefault();
  }

  public handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    let current = this.draggingItem;

    if (item && item.parentElement === current.parentElement) {
      if (item.className.indexOf(' over') === -1) {
        item.classList.add('over');
      }
    }
  }

  public handleDragLeave(event: DragEvent): any {
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    item.classList = item.className.replace(' over', '');
  }

  public handleDrop(event: DragEvent): any {

    let current = this.draggingItem;
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    let items = item.parentElement.querySelectorAll('.js-draggable');
    
    event.preventDefault();
    if (item !== current && item.parentElement === current.parentElement) {
      let currentpos = 0, droppedpos = 0;
      for (let it = 0; it < items.length; it++) {
        if (current === items[it]) { currentpos = it; }
        if (item === items[it]) { droppedpos = it; }
      }
      if (droppedpos === 0 || currentpos < droppedpos) {
        item.parentNode.insertBefore(current, item.nextSibling);
      } else {
        item.parentNode.insertBefore(current, item);
      }
    }
  }

  public isConfigurable(value: string): boolean {
    return this.nonConfigurableList.indexOf(value) === -1;
  }
}
