import { autoinject, bindable, containerless, customElement, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { bindingMode } from 'aurelia-binding';

import { MailboxService } from '../mailbox-service';
import { SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('IncomingCallDialog');

@containerless
@customElement('z-mailbox-count')
@autoinject
export class Mailbox {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public count: number;
  private oplog: any;

  constructor(private mailboxService: MailboxService, private sessionStore: SessionStore, private router: Router) { }

  bind(): void {
    let recipientId = this.sessionStore.get.user.recipientId;
    this.retrieveMailboxCount(recipientId);
    this.initialiseOplog(recipientId);
  }

  private retrieveMailboxCount(recipientId: string): void {
    this.mailboxService.retrieveMailboxNewMessageCount(recipientId).then(count => {
      this.count = count;
    });
  }

  private initialiseOplog(recipientId: string): void {
    this.mailboxService.initialiseCountOplog(recipientId).then(oplog => this.handleOplogChanges(oplog));
  }

  private handleOplogChanges(oplog: any): void {
    this.oplog = oplog;

    this.oplog.on('insert', messages => this.mailboxCountRetrieved(messages));
    this.oplog.on('update', messages => this.mailboxCountRetrieved(messages));
  }

  private mailboxCountRetrieved(data: { unplayedCount: number }): void {
    this.count = data.unplayedCount;
  }

  private viewMessages(): void {
    this.router.navigate('mailbox');
  }

  deactivate(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }
}
