import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
import { EmailsValidationSupport } from '../../../../components/molecules/inputs/z-email-basic-input';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { ReportConfig } from '../../report/report-config';
import { ReportMenuCategory } from '../../report/report-menu-category';
import { ReportMenuConfig } from '../../report/report-menu-config';
import { ReportSchedule } from '../report-schedule-model';
import { ReportScheduleService } from '../report-schedule-service';

const logger = LogManager.getLogger('ReportScheduleForm');

@customElement('report-schedule-form')
@autoinject()
export class ReportScheduleForm {

  @bindable({ attribute: 'report-schedule' }) public reportSchedule: ReportSchedule;
  @bindable({ attribute: 'report-list' }) public reportList: ReportSchedule;
  @bindable({ attribute: 'page-header' }) public pageHeader: string;
  @bindable({ attribute: 'is-processing' }) public isProcessing: boolean = false;

  private validation: ValidationController;
  private reportCategories: ReportMenuCategory[];

  public selectedReport: ReportConfig = null;

  constructor(
    private router: Router,
    private element: Element,
    private reportScheduleService: ReportScheduleService,
    private validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
    this.reportCategories = new ReportMenuConfig(false).categories.filter((category) => !category.reports.find((report) => !report.newReport));
  }

  public attached(): void {
    this.initValidationRules();
    if (this.reportSchedule) {
      this.updateReportInput();
    }
  }

  public updateReportInput(): void {
    this.selectedReport = null;
    for (const category of this.reportCategories) {
      for (const report of category.reports) {
        if (report.selector === this.reportSchedule.reportId) {
          this.selectedReport = report;
          break;
        }
      }
    }
  }

  public frequencySelectionChanged(): void {
    // does nothing - referenced in html
  }

  public cancel(): void {
    this.router.navigate('list');
  }

  public save(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      if (this.selectedReport.inputFormConfig.withChannel) {
        this.reportSchedule.reportParameters.selectedChannel = this.selectedReport.inputFormConfig.withChannel;
      }
      if (!this.selectedReport.inputFormConfig.showMembers) {
        this.reportSchedule.reportParameters.selectedAgent = null;
      }
      if (!this.selectedReport.inputFormConfig.showServices) {
        this.reportSchedule.reportParameters.selectedService = null;
      }
      if (!this.selectedReport.inputFormConfig.showTeams) {
        this.reportSchedule.reportParameters.selectedTeam = null;
      }
      
      this.reportSchedule.email = this.reportSchedule.email ? this.reportSchedule.email.replace(/,/g, ';') : '';
      this.element.dispatchEvent(new CustomEvent('save', { bubbles: true, detail: this.reportSchedule }));
    }
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.name).required().maxLength(30).then().satisfies((name) => {
        return this.reportScheduleService.findAll()
          .then((list) => {
            const existingReportSchedule = list.find((reportSchedule) => reportSchedule.name === name && reportSchedule.id !== this.reportSchedule.id);
            return !existingReportSchedule;
          });
      })
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.startDate).required()
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.startTime).required()
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.email).satisfies((email) => {
        if (this.reportSchedule.deliveryMethod === 'EMAIL') {
          if (!email) {
            return false;
          }
          const cleanedEmail = email.replace(/,/g, ';');
          const emailAddresses = cleanedEmail.split(';');
          for (const emailAddress of emailAddresses) {
            const valid = EmailsValidationSupport.EMAIL_REGEXP.test(emailAddress);
            if (!valid) {
              return false;
            }
          }
        }
        return true;
      }).withMessage('Input is invalid')
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.sftpHost).satisfies((sftpHost) => {
        if (this.reportSchedule.deliveryMethod === 'SFTP') {
          if (!sftpHost) {
            return false;
          }
        }
        return true;
      }).withMessage('Input is invalid')
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.sftpPort).satisfies((sftpPort) => {
        if (this.reportSchedule.deliveryMethod === 'SFTP') {
          if (!sftpPort) {
            return false;
          }
          const valid = /[0-9]$/.test(sftpPort);
          if (!valid) {
            return false;
          }
          if (sftpPort < 1 || sftpPort > 65535) {
            return false;
          }
        }
        return true;
      }).withMessage('Input is invalid')
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.sftpPath).satisfies((sftpPath) => {
        if (this.reportSchedule.deliveryMethod === 'SFTP') {
          if (!sftpPath) {
            return false;
          }
        }
        return true;
      }).withMessage('Input is invalid')
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.sftpUsername).satisfies((sftpUsername) => {
        if (this.reportSchedule.deliveryMethod === 'SFTP') {
          if (!sftpUsername) {
            return false;
          }
        }
        return true;
      }).withMessage('Input is invalid')
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.sftpPassword).satisfies((sftpPassword) => {
        if (this.reportSchedule.deliveryMethod === 'SFTP') {
          if (!sftpPassword) {
            return false;
          }
        }
        return true;
      }).withMessage('Input is invalid')
      .ensure((reportSchedule: ReportSchedule) => reportSchedule.reportId).satisfies((reportId) => {
        if (!reportId || reportId === 'Select report') {
          return false;
        }
        return true;
      }).withMessage('Report is required')
      .on(this.reportSchedule);
  }
}
