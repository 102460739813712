import { inject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
import { TableInputModel } from "../../../../../../../../components/custom/table-input";
import { Event } from '../../../../../../../../zailab.common';
/**/
const logger = LogManager.getLogger('Request');
/**/
@inject(Element)
export class Request {

  private requestMethods: string[];
  private method: string;
  private url: string;
  private bodyText: string;
  private requestHeaders: any;
  private isFormData: boolean = false;
  private formData: any;
  private wizard: ZIWizard;

  private tableInputHeaders: TableInputModel;
  private tableFormDataHeaders: TableInputModel;

  constructor(private element: Element) {}

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.initHttpMethods();
    this.extractWizardData();
    this.initTableInputHeaders();
    this.initFormDataHeaders();
    this.validate();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    this.wizard.beforeNextStep(() => {
      this.populateHeadersFromModel();
      this.populateFormDataFromModel();
      this.updateWizardData();
      this.nextStep();
    });
  }

  private initHttpMethods(): void {
    this.requestMethods = ["GET", "POST", "PUT"];
    this.method = this.requestMethods[0];
  }

  private extractWizardData(): void {
    this.url = this.wizard.data.url;
    if (this.wizard.data.method) {
      this.method = this.wizard.data.method;
    }
    this.bodyText = this.wizard.data.bodyText;
    this.requestHeaders = this.wizard.data.requestHeaders;
    this.isFormData = this.wizard.data.isFormData ? this.wizard.data.isFormData : false;
    this.formData = this.wizard.data.formData;
  }

  private validate(): void {
    if (!this.url || !this.method) {
      this.wizard.step.incomplete();
      return;
    }
    this.wizard.step.complete({ url: this.url, method: this.method, bodyText: this.bodyText, requestHeaders: this.requestHeaders });
  }

  private updateWizardData(): void {
    this.wizard.data.url = this.url;
    this.wizard.data.method = this.method;
    this.wizard.data.bodyText = this.bodyText;
    this.wizard.data.requestHeaders = this.requestHeaders;
    this.wizard.data.isFormData = this.isFormData;
    this.wizard.data.formData = this.formData;
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  public populateHeadersFromModel(): void {
    this.requestHeaders = [];
    this.tableInputHeaders.tables[0].rows.forEach((row) => {
      const header = {};
      header[row.cells[0]] = row.cells[1];
      this.requestHeaders.push(header);
    });
  }

  private initTableInputHeaders(): void {
    if (this.requestHeaders && this.requestHeaders instanceof TableInputModel) {
      this.tableInputHeaders = this.requestHeaders;
      return;
    }
    const builder = TableInputModel.builder
      .setHeaders('Header Name', 'Header Value')
      .hideHeaders()
    if (this.requestHeaders) {
      for (const header of this.requestHeaders) {
        for (const prop in header) {
          builder.addRow(prop, header[prop]);
        }
      }
    }
    this.tableInputHeaders = new TableInputModel([builder.build()]);
  }

  public populateFormDataFromModel(): void {
    this.formData = [];
    this.tableFormDataHeaders.tables[0].rows.forEach((row) => {
      const header = {};
      header[row.cells[0]] = row.cells[1];
      this.formData.push(header);
    });
  }

  private initFormDataHeaders(): void {
    if (this.formData && this.formData instanceof TableInputModel) {
      this.tableFormDataHeaders = this.formData;
      return;
    }
    const builder = TableInputModel.builder
      .setHeaders('Name', 'Value')
      .hideHeaders()
    if (this.formData) {
      for (const header of this.formData) {
        for (const prop in header) {
          builder.addRow(prop, header[prop]);
        }
      }
    }
    this.tableFormDataHeaders = new TableInputModel([builder.build()]);
  }

  public triggerMethodChanged(): void {
    new Event(this.element, 'apicallmethodchanged', this.method);
  }

  public triggerUrlChange(): void {
    new Event(this.element, 'apicallurlchanged', this.url);
    this.validate();
  }

  public triggerBodyTextChanged(): void {
    new Event(this.element, 'apicallbodytextchanged', this.bodyText);
    this.validate();
  }

  public triggerTableInputHeadersChanged(): void {
    new Event(this.element, 'apicalltabeinputheaderschanged', this.tableInputHeaders);
  }

  public toggleFormDataEnabled(): void {
    this.isFormData = !this.isFormData;
    new Event(this.element, 'apicallisformdatachanged', this.isFormData);
  }

  public triggerTableFormDataHeadersChanged(): void {
    new Event(this.element, 'apicalltabeformdataheaderschanged', this.tableFormDataHeaders);
  }
}
