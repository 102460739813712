import { AureliaConfiguration } from 'aurelia-configuration';
import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';


const logger = LogManager.getLogger('FeatureFlagService');

@autoinject()
export class FeatureFlagService {

  private knownFeatures: Map<string, boolean>;
  private autoDisableForDevList: string[] = [
    'CRM', 'interactionAnalytics', 'microsoft365'
  ];


  constructor(
    private httpClient: HttpClient,
    private aureliaConfiguration: AureliaConfiguration
  ) {
    this.knownFeatures = new Map();
  }

  public isEnabled(featureName: string, force?: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!force && this.knownFeatures.has(featureName)) {
        if (this.knownFeatures.get(featureName) === undefined) {

          return resolve(new Promise(resolveInner => {
            const checkData = (): any => {
              setTimeout(() => {
                if (this.knownFeatures.get(featureName) !== undefined) {
                  resolveInner(this.knownFeatures.get(featureName));
                } else {
                  checkData();
                }
              }, 500);
            }
            checkData();
          }));
        }


        resolve(this.knownFeatures.get(featureName));
        return;
      }

      this.knownFeatures.set(featureName, undefined);
      this.httpClient.createRequest(`v1/unleash/featureflags/${featureName}`)
        .asGet()
        .send()
        .then((response: any) => {
          this.knownFeatures.set(response.name, response.enabled);
          setTimeout(() => {
            this.knownFeatures.delete(response.name);
          }, 10 * 60 * 1000);
          resolve(response.enabled);
        }, error => {
          logger.error('Feature Flag check rejects >>>>', error);
          resolve(false);
        })
        .catch((error) => {
          logger.error('Feature Flag check catches >>>>', error);
          resolve(false)
        });
    });
  }

  public destroy(): void {
    this.knownFeatures = new Map();
  }

}
