import {autoinject, LogManager, customElement, bindable} from 'aurelia-framework';
import timezone from 'countries-and-timezones';

import {SessionStore, ArrayTools} from 'zailab.common';

const logger = LogManager.getLogger('ZCountrySelector');

@customElement('z-country-selector')
@autoinject()
export class ZCountrySelector {

  private allCountries: Array<ICountry>;
  private orderedCountries: Array<ICountry>;
  private countries: Array<ICountry>;

  @bindable({attribute: 'default-country'}) private defaultCountry: string = 'us';
  @bindable({attribute: 'preferred-countries'}) private preferredCountries: Array<string>;
  @bindable({attribute: 'selected-country'}) private selectedCountry: ICountry;

  constructor(private sessionStore: SessionStore) {
  }

  public attached(): void {
    this.retrieveCountries();
    this.configureCountrySelectionList();
  }

  private retrieveCountries(): void {
    //@ts-ignore
    this.allCountries = Object.values(timezone.getAllCountries());
    this.orderedCountries = ArrayTools.sort(this.allCountries, 'name');
  }

  private configureCountrySelectionList(): void {
    if (this.sessionStore.get.organisation && this.sessionStore.get.organisation.country) {
      this.defaultCountry = this.sessionStore.get.organisation.country.code.toLowerCase();
    }
    this.setupAvailableCountriesList();
    this.selectedCountry = this.countries.find((country) => country.id.toLowerCase() === this.defaultCountry);
    if (this.countries.length === 1) {
      this.selectedCountry = this.countries[0];
    }
  }

  private setupAvailableCountriesList(): void {
    if (this.preferredCountries && Array.isArray(this.preferredCountries)) {
      this.countries = this.orderedCountries.filter((country) => {
        //@ts-ignore
        return this.preferredCountries.includes(country.id.toLowerCase());
      });
    }

    if (!this.preferredCountries) {
      this.countries = this.orderedCountries.filter(country => {
        return country.id.toLowerCase() === this.defaultCountry;
      });
    }
  }
}