import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { List } from 'zailab.abstract';
import { AudioAddService } from 'zailab.common';
import { AbstractList } from "../../../../../abstract/abstract-list";
import { SearchTools } from '../../../../../zailab.common';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { InteractionFlowService } from '../../../../interaction/interactionFlow/interaction-designer/interaction-flow-service';
import { InteractionFlowModel } from '../../../../interaction/interactionFlow/interaction-designer/models/interaction-flow-model';
import { InteractionsService } from '../../../../interaction/interactionFlow/interaction-flow-service';
import { AddAudioDialog } from '../addaudio/add-audio-dialog';
import { AudioGroupService } from '../group/audio-group-service';
import { AudioListDialog } from './audio-list-dialog';
import { LinkAudioGroupDialog } from './link-audio-group-dialog';

const logger = LogManager.getLogger('AudioListView');

@autoinject
export class AudioList extends AbstractList {

  private currentAudioCount: number = 0;
  private expectedAudioCount: number = 0;
  private contentCreatedFlag: boolean = false;
  private retryTimeout: number;
  private selectedGroupId: string;
  private selectedNoGroup: boolean;
  private audioGroupId: string;
  private audioGroupList: any[];

  public searching: boolean;
  public loading: boolean;
  public heading: string = 'All Audio';
  public actions: any = [
    {
      action: 'view',
      label: 'View Audio',
      icon: 'view'
    },
    {
      action: 'group',
      label: 'Move Audio',
      icon: 'split'
    }
  ];

  public displayAudioGroupSearchResults: boolean = false;
  public audioGroupSearchCriteria: string = '';
  public selectedAudioGroupSearch: any;
  public searchTerm: string = '';

  public workflowId: string = '';
  public workflowList: any[] = [];
  public workflowSearchCriteria: string = '';
  public displayWorkflowSearchResults: boolean = false;
  public selectedWorkflowSearch: any;
  public selectedNoWorkflow: boolean;

  constructor(
    private element: any,
    private dialogService: DialogService,
    private audioService: AudioAddService,
    private audioGroupService: AudioGroupService,
    private interactionFlowService: InteractionFlowService,
    private interactionsService: InteractionsService,
    protected eventAggregator: EventAggregator,
    protected router: Router
  ) {
    super(eventAggregator)
  }

  protected activate(params: any): void {
    super.activate(params);
    this.selectedGroupId = null;
    this.searchTerm = params.searchTerm;
    this.selectedNoGroup = params.noGroup;
    this.audioGroupId = params.audioGroupId;
    this.workflowId = params.workflowId;
    this.selectedNoWorkflow = params.noWorkflow;
    super.attached();
  };

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
    this.clearRetryTimeout();
  }

  private clearRetryTimeout(): void {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.heading = 'All Audio';
    this.audioGroupService.getAudioGroups().then((groups) => {
      this.audioGroupList = groups;
      if (this.audioGroupId) {
        this.audioGroupService.getAudioGroup(this.audioGroupId).then((group) => this.heading = `${group.name} : Audio`);
        this.selectedGroupId = this.audioGroupId;
        this.selectedAudioGroupSearch = { id: this.audioGroupId };
        this.audioGroupSearchCriteria = this.audioGroupList.find((group) => group.id === this.audioGroupId).name;
      }
      if (this.selectedNoGroup) {
        this.selectNoAudioGroupSearch();
      }
    });

    this.interactionsService.retrieveOrganisationInteractionFlows().then((interactionsList) => {
      this.workflowList = interactionsList;
      if (this.workflowId) {
        this.interactionFlowService.retrieveInteractionFlow(this.workflowId, null)
          .then((interactionFlow: InteractionFlowModel) => this.heading = `${interactionFlow.interactionFlowName} : Audio`);
        this.selectedWorkflowSearch = { interactionFlowId: this.workflowId };
        this.workflowSearchCriteria = this.workflowList.find((workflow) => workflow.interactionFlowId === this.workflowId).flowName;
      }
      if (this.selectedNoWorkflow) {
        this.selectNoWorkflowSearch();
      }
    });
    this.audioService.getAudioFiles(this.audioGroupId, this.workflowId).then((_audioFiles: Array<ZIAudioFile>) => {
      if (this.selectedNoGroup) {
        _audioFiles = _audioFiles.filter((audio) => !audio.groupId);
      }
      if (this.selectedNoWorkflow) {
        _audioFiles = _audioFiles.filter((audio) => audio.canDelete);
      }
      if (this.searchTerm) {
        _audioFiles = _audioFiles.filter((audio) => SearchTools.partialMatch(audio.filename, this.searchTerm));
      }
      this.setupListConfig(_audioFiles);
    }).catch(_error => {
      logger.info(' Failed to retrieve your organisation audio files > error = ', _error);
    })
  }

  protected setupListConfig(_audioFiles: ZIAudioFile[]): void {
    super.setupListConfig(_audioFiles);
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .items(_audioFiles)
      .icon('icon')
      .uniqueIdentifier('id')
      .displayId('filename')
      .description('description')
      .hasRollover(false)
      .withProportion('medium')
      .tilesPerRow(6)
      .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }

    new PlaceholderService(this.container, this.itemList.items.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  protected addItem(): void {
    this.dialogService.open({ viewModel: AddAudioDialog, model: { audioList: this.itemList.items, audioGroupList: this.audioGroupList, selectedGroupId: this.audioGroupId } }).whenClosed((_response: any) => {
      if (!_response.wasCancelled) {
        const selectedGroupId = _response.output.audioMessages.selectedGroupId;
        this.resetSearchFilter();
        this.audioGroupSearchCriteria = 'not_applicable';
        if (selectedGroupId === 'No Group') {
          this.selectedAudioGroupSearch = { id: '___NO_GROUP___' };
        } else {
          this.selectedAudioGroupSearch = { id: selectedGroupId };
        }
        this.contentCreatedFlag = true;
        this.expectedAudioCount = this.currentAudioCount + 1;
        super.showLoader();
        setTimeout(() => this.confirmSearchFilter(), 1000);
      }
    });
  };

  protected createMetaData(_items: any[]): void {
    _items.forEach(_file => {
      const group = this.audioGroupList.find((group) => group.id === _file.groupId);
      _file.displayId = _file.filename || '';
      _file.audioType = _file.speechRate ? 'TTS' : 'WAV upload';
      _file.description = group ? group.name : 'No Group';
      _file.icon = _file.speechRate ? 'text-size' : 'audio';
    });
  }

  protected selectItem(_item: any): void {
    if (this.itemList.isDeleting) {
      _item.isDeleting = !_item.isDeleting;
    } else {
      this.dialogService.open({
        viewModel: AudioListDialog,
        model: _item
      }).whenClosed((_response: any) => {
        _item.isSelected = false;
      });
    }
  }

  public deleteItems(): void {

    let itemsToDelete = [];

    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      }
    }

    this.deleteSelectedItems(itemsToDelete);

    this.itemList.isDeleting = false;
  }

  protected deleteSelectedItems(items: any[]): void {
    if (!items || !items.length) {
      return;
    }
    this.deferredMessage = items.length > 1 ? `${items.length} audio files deleted.` : `Audio file <b>${items[0].filename}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(audioFile: any): void {
    super.delete(audioFile);
    let audioId = audioFile.id;
    if (audioFile.audioType === 'TTS') {
      this.audioService.deleteTTS(audioId);
    } else {
      this.audioService.deleteAudio(audioId);
    }
    if (audioFile.groupId) {
      this.audioGroupService.deallocateAudioGroup(audioFile.groupId, audioId);
    }
    const items = this.itemList.items.filter(_audioFIle => {
      return _audioFIle.id !== audioId;
    });
    this.setupListConfig(items);
  }

  public selectAction(eventDetail: any, item: any): void {
    if (eventDetail === 'view') {
      this.selectItem(item);
    }
    if (eventDetail === 'group') {
      this.dialogService.open({
        viewModel: LinkAudioGroupDialog,
        model: { item, audioGroupId: item.groupId, audioGroupList: this.audioGroupList }
      }).whenClosed((_response: any) => {
        if (!_response.wasCancelled) {
          let audioGroupId = _response.output;
          this.linkGroup(item, audioGroupId);
        }
      });
    }
  }

  private linkGroup(item: any, audioGroupId: any): void {
    const audioId = item.id;
    if (audioGroupId) {
      super.showLoader();
      if (item.groupId && item.groupId !== audioGroupId) {
        this.audioGroupService.deallocateAudioGroup(item.groupId, audioId).then(() => {
          this.audioGroupService.allocateAudioGroup(audioGroupId, audioId).then(() => setTimeout(() => this.retrieveList(), 1000));
        });
      } else {
        this.audioGroupService.allocateAudioGroup(audioGroupId, audioId).then(() => setTimeout(() => this.retrieveList(), 1000));
      }
    } else if (item.groupId) {
      super.showLoader();
      this.audioGroupService.deallocateAudioGroup(item.groupId, audioId).then(() => setTimeout(() => this.retrieveList(), 1000));
    }
  }

  public toggleSearch(): void {
    this.searching = !this.searching;
  }

  public audioGroups(): void {
    this.router.navigate('audio-group-list');
  }

  public allAudio(): void {
    this.resetSearchFilter();
    this.confirmSearchFilter();
  }

  public selectAudioGroup(event: any): void {
    const selectedGroupId = event.target.value;
    if (!selectedGroupId) {
      this.selectedNoGroup = false;
      this.router.navigate('audio-list');
      this.retrieveList();
    } else if (selectedGroupId === '___NO_GROUP___') {
      this.selectedNoGroup = true;
      this.retrieveList();
    } else {
      this.searching = false;
      this.router.navigate(`audio-list/${selectedGroupId}`);
    }
  }

  public showAudioGroupSearchResults(event: any): void {
    event.stopPropagation();
    this.displayAudioGroupSearchResults = true;
  }

  public closeAudioGroupDropdown(): void {
    setTimeout(() => {
      this.displayAudioGroupSearchResults = false;
    }, 250);
  }

  public selectAudioGroupSearch(audioGroup: any): void {
    this.audioGroupSearchCriteria = audioGroup.name;
    this.selectedAudioGroupSearch = audioGroup;
  }

  public showWorkflowSearchResults(event: any): void {
    event.stopPropagation();
    this.displayWorkflowSearchResults = true;
  }

  public closeWorkflowDropdown(): void {
    setTimeout(() => {
      this.displayWorkflowSearchResults = false;
    }, 250);
  }

  public selectWorkflowSearch(workflow: any): void {
    this.workflowSearchCriteria = workflow.flowName;
    this.selectedWorkflowSearch = workflow;
  }

  public resetSearchFilter(): void {
    this.searchTerm = '';
    this.displayAudioGroupSearchResults = false;
    this.audioGroupSearchCriteria = '';
    this.selectedAudioGroupSearch = null;
    this.displayWorkflowSearchResults = false;
    this.workflowSearchCriteria = '';
    this.selectedWorkflowSearch = null;
  }

  public confirmSearchFilter(): void {
    this.workflowId = null;
    this.audioGroupId = null;
    this.selectedNoGroup = false;
    this.selectedNoWorkflow = false;
    const selectedWorkflowId = this.workflowSearchCriteria && this.selectedWorkflowSearch && this.selectedWorkflowSearch.interactionFlowId;
    const selectedGroupId = this.audioGroupSearchCriteria && this.selectedAudioGroupSearch && this.selectedAudioGroupSearch.id;
    if (selectedGroupId && selectedGroupId === '___NO_GROUP___') {
      this.selectedNoGroup = true;
    }
    if (selectedWorkflowId && selectedWorkflowId === '___NO_WF___') {
      this.selectedNoWorkflow = true;
    }
    const params = [];
    if (this.searchTerm) {
      params.push(`searchTerm=${this.searchTerm}`);
    }
    if (this.selectedNoWorkflow) {
      params.push(`noWorkflow=true`);
    } else if (selectedWorkflowId) {
      params.push(`workflowId=${selectedWorkflowId}`);
      this.workflowId = selectedWorkflowId;
    }
    if (this.selectedNoGroup) {
      params.push(`noGroup=true`);
    } else if (selectedGroupId) {
      params.push(`audioGroupId=${selectedGroupId}`);
      this.audioGroupId = selectedGroupId;
    }
    this.router.navigate(`audio-list?${params.join('&')}`);
    this.retrieveList();
    this.itemList.isSearching = false;
  }

  public selectAllAudioSearch(): void {
    this.audioGroupSearchCriteria = 'All Audio';
  }

  public selectNoAudioGroupSearch(): void {
    this.audioGroupSearchCriteria = 'No Group';
    this.selectedAudioGroupSearch = { id: '___NO_GROUP___' };
  }

  public selectNoWorkflowSearch(): void {
    this.workflowSearchCriteria = 'No Workflow';
    this.selectedWorkflowSearch = { interactionFlowId: '___NO_WF___' };
  }

  public workflows(): void {
    this.router.navigate('/hud/interactionflows');
  }
}