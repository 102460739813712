import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
import { CREATE_ACTIONS } from './create.actions';
import { SessionStore } from 'zailab.common';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from 'aurelia-http-client';

const logger = LogManager.getLogger('CreateService');

@inject(Dispatcher, SessionStore, HttpClient)
export class CreateService {

  constructor(dispatcher, sessionStore, httpClient) {
    this.dispatcher = dispatcher;
    this.sessionStore = sessionStore;
    this.httpClient = httpClient;
  }

  clearState() {
    this.dispatcher.dispatch(CREATE_ACTIONS.CLEAR_STATE);
  }

  formNameSearch(searchParams) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/forms/names')
        .asGet()
        .withParams({ search: searchParams })
        .send()
        .then(
          (response) => {
            resolve(response.formNames);
          }, (error) => {
            reject(error);
          });
    });

  }

  retrievePersonalInfo() {

    let personId = this.sessionStore.get.user.personId;
    this.httpClient.createRequest(`/v1/user/persons/profile-information/${personId}`)
      .asGet()
      .send()
      .then(response => {
        if (response && response.person) {

          let personalInfo = response.person;
          this.dispatcher.dispatch(CREATE_ACTIONS.RETRIEVE_PERSONAL_INFO, personalInfo);
        }
      }, (error) => {

      });
  }


  getSectionData() {
    this.dispatcher.dispatch(CREATE_ACTIONS.FETCH_FORMATTED_SECTIONS, []);
  }

  storeForm(form) {

    this.dispatcher.dispatch(CREATE_ACTIONS.STORE_FORM, form);
  }

  publishForm(formId, name, passRequirement, channels, sections, member, businessPartnerId, contactCentreId) {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    for (let section of sections) {

      for (let question of section.questions) {
        question.yesWeight = parseInt(question.yesWeight);
        question.noWeight = parseInt(question.noWeight);
      }
    }

    let message = {
      formId: uuidv4(),
      organisationId: organisationId,
      name: name,
      channels: channels,
      sections: sections,
      createdBy: member,
      passRequirement: parseInt(passRequirement),
      businessPartnerId,
      contactCentreId
    };

    this.httpClient
      .createRequest('v1/organisation/forms')
      .asPost()
      .withContent(message)
      .send()
      
    this.dispatcher.dispatch(CREATE_ACTIONS.SHOW_SUCCESS);
    this.clearState();
  }

  calculateScore(question) {
    this.dispatcher.dispatch(CREATE_ACTIONS.CALCULATE_SCORE, question);
  }

  addQuestion(question) {
    this.dispatcher.dispatch(CREATE_ACTIONS.ADD_QUESTION, question);
  }

  removeQuestion(question) {
    this.dispatcher.dispatch(CREATE_ACTIONS.REMOVE_QUESTION, question);
  }


  toggleEditQuestion(isInEditState) {
    this.dispatcher.dispatch(CREATE_ACTIONS.TOGGLE_EDIT_QUESTION, isInEditState);
  }

  blankQuestionValidation(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.BLANK_QUESTION_VALIDATION, reason);
  }

  invalidQuestionValidation(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.INVALID_QUESTION_VALIDATION, reason);
  }

  sectionValidation(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.SECTION_VALIDATION, reason);
  }

  clearValidation(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.CLEAR_VALIDATION, reason);
  }

  blankYesWeightValidation(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.BLANK_YES_WEIGHT, reason);
  }

  blankNoWeightValidation(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.BLANK_NO_WEIGHT, reason);
  }

  yesWeightNotNumeric(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.NOT_NUMERIC_YES_WEIGHT, reason);
  }

  noWeightNotNumeric(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.NOT_NUMERIC_NO_WEIGHT, reason);
  }

  equalWeight(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.EQUAL_WEIGHT, reason);
  }

  negativeNoWeight(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.NEGATIVE_NO_WEIGHT, reason);
  }

  negativeYesWeight(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.NEGATIVE_YES_WEIGHT, reason);
  }

  noWeightNotWholeNumber(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.NO_WEIGHT_NOT_WHOLE_NUMBER, reason);
  }

  yesWeightNotWholeNumber(reason) {
    this.dispatcher.dispatch(CREATE_ACTIONS.YES_WEIGHT_NOT_WHOLE_NUMBER, reason);
  }


}