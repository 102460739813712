export class EllipsifyStringValueConverter {
  toView(stringValue, charLimit) {

    if (!stringValue || stringValue.length <= charLimit) {
      return stringValue;
    }

    return `${stringValue.substring(0, charLimit)}...`;
  }
}
