export class SearchTools {

  static partialMatch(value, search) {
    if (!value || !value.match)
      throw new Error(`Value '${value}' does not support regex matching`);
    if (!search || search === '')
      return true;
    search = escapeRegExp(search);
    const regex = new RegExp(search, 'gi');
    return value.match(regex);
  }
}

function escapeRegExp(search) {
  return search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
