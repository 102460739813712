import {EventAggregator} from 'aurelia-event-aggregator';
import {bindable, customElement, autoinject, LogManager} from 'aurelia-framework';

import {ConversationService} from '../conversation-service';
import {CONTACT_ACTIONS} from '../../contact/contactcontroller/contact-controller-actions';
import {ConversationModel} from '../conversation-model';
import {ContactModel} from '../../contact/contact-model';

const logger = LogManager.getLogger('CallOutcome');

@customElement('z-call-outcome')
@autoinject()
export class CallOutcome {

  @bindable private conversation: ConversationModel;
  @bindable private contact: ContactModel;


  private outcomes: object = {
    connected: ['Right Party Contact', 'Wrong Party Contact', 'Voicemail'],
    notConnected: ['Invalid Number', 'Number Busy', 'No Answer', 'Declined']
  };

  constructor(private eventAggregator: EventAggregator) {
  }

  private selectOutcome(category: string, outcomeReason: string): void {
    this.eventAggregator.publish('select.call.outcome', {
      interactionId: this.contact.servedInteraction.interactionId,
      category,
      outcomeReason
    });
    this.contact.closeDispositionCode();
    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
    ;
  }
}
