import {LogManager, customElement, bindable} from 'aurelia-framework';
import { UserPassportService } from '../../features/user/passport/user-passport-service';

const logger = LogManager.getLogger('ZRefreshNotification');

@customElement('z-refresh-notification')
export class ZRefreshNotification {
  @bindable({ attribute: 'update-required' }) public isUpdateRequired: boolean;
  @bindable({ attribute: 'out-of-sync' }) public isOutOfSync: boolean;
  @bindable({ attribute: 'auto-logout' }) public autoLogout: boolean;

  constructor(
    private userPassportService: UserPassportService
  ) {}

  public refresh(): void {
    // @ts-ignore
    window.location.reload(true);
  }

  public refreshToken(): void {
    this.userPassportService._refreshToken();
  }
}
