import { inject, observable } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
* */
import { SessionStore, BootstrapFormRenderer } from 'zailab.common';
import { WorkTypesService } from '../../work-types-service';
/*
* */
@inject(Element, ValidationControllerFactory, SessionStore, WorkTypesService)
export default class {

  @observable name;
  types = [{
    value: 'CONTACT_CENTRE_FLOW', name: 'Contact Center', icon: 'contact-centre-flow', active: true
  }, {
    value: 'OFFICE_FLOW', name: 'Office', icon: 'office-flow', active: true
  }];
  validation;
  taskTemplates = [];
  ready = false;
  config;

  constructor(element, validationControllerFactory, sessionStore, workTypesService) {
    this.element = element;
    this.sessionStore = sessionStore;
    this.workTypesService = workTypesService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(wizard) {

    this._initWizard(wizard);
    if (wizard.data.name) {
      this.name = wizard.data.name;
    }

    if (wizard.data.config && wizard.data.config.flowType) {
      this.config = wizard.data.config;
      if (wizard.data.config.flowType) {
        this.types.forEach(_type => {
          let _sanitisedName = this.config.flowType.toUpperCase().replace(/ /g, '_');
          if (_sanitisedName !== _type.value) {
            _type.active = false;
          } else {
            _type.active = true;
            this.selectType(_type);
          }
        });
      }
    } else if (this.wizard.data.type) {
      this._setSelected(this.wizard.data.type);
    } else {
      this.selectType(this.types[0]);
    }

    this.workTypesService.retrieveWorkTypes().then((taskTemplates) => {
      this.taskTemplates = taskTemplates;
      this.ready = true;
      this._initValidations();
    });
  }

  selectType(type) {

    for (let item of this.types) {
      item.isSelected = false;
    }

    type.isSelected = true;
    this.wizard.data.type = type.value;

    this.element.dispatchEvent(new CustomEvent('changeworktype', {
      bubbles: true, detail: {
        type: type.value
      }
    }));
    this._clearStepSpecificData();
  }

  nameChanged(newValue, oldValue) {

    if (!newValue) {
      this.wizard.step.incomplete();
      this.wizard.data.name = '';
      return;
    }
    this.wizard.step.complete({name: this.name});
  }

  continue() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.wizard.continue();
    }, (error) => { });
  }

  _initWizard(wizard) {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.continue();
    });
  }

  _initValidations() {

    ValidationRules
      .customRule('uniqueName', (name) => {
        if (!name) {
          return true;
        }
        for (let item of this.taskTemplates) {
          if (item.templateName.toLowerCase() === name.toLowerCase()) {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a work type name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique work type name.')
      .on(this);
  }

  _setSelected(type) {
    for (let item of this.types) {
      if (item.value === type) {
        item.isSelected = true;
      }
    }
  }

  _clearStepSpecificData() {

    let _data = {
      channels: null, skills: null, roles: null, businessValue: null, taskResponseTime: null, targetResponse: null
    };

    if (this.name) {
      this.wizard.step.complete(_data);
      this.wizard.resetCompletionStates(0);
      return;
    }
    this.wizard.step.incomplete(_data);
    this.wizard.resetCompletionStates(-1);
  }
}

function _focus(query) {

  let element = document.querySelector(query);

  if (_elementFound(element)) {
    element.focus();
  }

  if (_elementFocused(element)) {
    return;
  }

  setTimeout(() => {
    _focus(query);
  }, 5);
}

function _elementFound(element) {

  if (element) {
    return true;
  }
  return false;
}

function _elementFocused(element) {

  if (element === document.activeElement) {
    return true;
  }
  return false;
}