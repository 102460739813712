import {computedFrom, inject, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
* */
const logger = LogManager.getLogger('SkillModel');

/*
 * */
export class SkillModel extends BaseModel {

  id = null;
  name = null;
  flowCount = 0;
  memberCount = 0;
  invitationCount = 0;
  proficiency = 0;

  constructor(skill) {
    super();
    this.mapProperties(skill);
  }

  @computedFrom('flowCount', 'memberCount')
  get canDelete() {
    if(this.flowCount === 0 && this.memberCount === 0) {
      return true;
    }
  }

  mapProperties(object) {
    if (object && object.skillId) {
      object.id = object.skillId;
      object.name = object.skillName;
    }
    super.mapProperties(object);
  }
}