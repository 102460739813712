import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/**/
import { SessionStore } from 'zailab.common';
import { SurveySelectModel } from "./survey-select-model";
/**/
const logger = LogManager.getLogger('MenuOptionsDialogService');

/**/
@autoinject
export class SurveyDialogService {

  private httpClient: HttpClient;
  private sessionStore: SessionStore;

  constructor(httpClient: HttpClient, sessionStore: SessionStore) {

    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
  }

  public getSurveysBasedOnOrgId(): Promise<object[]> {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('/v1/organisation/surveys')
        .asGet()
        .send()
        .then((response: any) => {
          let surveyList: Array<any> = [];
          if (response.surveyTemplates) {
            surveyList = response.surveyTemplates;//this._modelCollection(response.surveyTemplates);
          }
          resolve(surveyList);
        }, error => {
          reject(error);
        });
    });
  }

  _modelCollection(list: Array<any>): Array<any> {
    let _list: Array<any> = [];
    list.forEach(item => {
      _list.push(this._modelEntity(item));
    });
    return _list;
  }

  _modelEntity(item: any): SurveySelectModel {
    return new SurveySelectModel();
  }
}