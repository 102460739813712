import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { SessionStore, ZaiForm } from 'zailab.common';
import { PLATFORM } from 'aurelia-pal';
import { observable } from 'aurelia-binding';

import { RoutingGroupsService } from '../routing-groups-service';

const logger = LogManager.getLogger('MemberListView');

@autoinject()
export class EditRoutingGroupDialog {

  public form: any[][];
  public formData: { name?: string; valid?: boolean };
  private ready: boolean;
  private submitting: boolean;

  public tabs: any[] = [{
    id: '1',
    name: 'Worktypes',
    // @ts-ignore
    viewModel: PLATFORM.moduleName('features/organisation/interactions/waiting-room-configuration/routing-groups/edit/tabs/tab-1-worktypes/tab-1-worktypes'),
    icon: 'worktypes',
    data: {}
  }, {
    id: '2',
    name: 'Teams',
    // @ts-ignore
    viewModel: PLATFORM.moduleName('features/organisation/interactions/waiting-room-configuration/routing-groups/edit/tabs/tab-2-teams/tab-2-teams'),
    icon: 'teams',
    data: {}
  }, {
    id: '3',
    name: 'Agents',
    // @ts-ignore
    viewModel: PLATFORM.moduleName('features/organisation/interactions/waiting-room-configuration/routing-groups/edit/tabs/tab-3-agents/tab-3-agents'),
    icon: 'members',
    data: {}
  }];
  
  private routingGroup: { routingGroupId: string, name: string };

  constructor(
    private dialogController: DialogController,
    private routingGroupsService: RoutingGroupsService
  ) {}

  public async activate(data: { routingGroupId: string, name: string }): Promise<void> {

    let routingGroup;
    try {
      routingGroup = await this.routingGroupsService.getRoutingGroup(data.routingGroupId);

      this.tabs.forEach(tab => {
        tab.routingGroupId = data.routingGroupId;
        if (tab.name === 'Worktypes') {
          tab.data.selectedWorktypes = routingGroup.taskTemplates.map(tt => {
            return { id: tt.taskTemplateId, name: tt.name }
          });
        } else if (tab.name === 'Teams') {
          tab.data.selectedTeams = routingGroup.teams.map(t => {
            return { id: t.teamId, name: t.name }
          });
        } else if (tab.name === 'Agents') {
          tab.data.selectedAgents = routingGroup.members.map(m => {
            return { id: m.memberId, name: m.name }
          });
        }
      });
      this.ready = true;
    } catch(e) {
      console.warn(' > Failed to retrieve routing group due to:', e);
    } finally {
      this.routingGroup = routingGroup;
      if (this.routingGroup.name) {
        this.formData = {
          name: this.routingGroup.name,
          valid: true
        };
      }
      this.generateForm()
    }
  }
  
  public generateForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput()
      .withTitle('Routing Group Name', '160px')
      .withPlaceholder('Enter text here.')
      .withIdentifier('name')
      .withValue(this.routingGroup.name)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN }
      ])
      .insertField()
      .finaliseForm(form => {
        this.form = form;
      })
  }


  public cancel(): void {
    this.dialogController.cancel();
  }

  public confirm(): void {
    if (this.formData.valid) {
      this.submitting = true;
      
      const workTypeTab = this.tabs.find(tab => tab.name === 'Worktypes');
      const teamTab = this.tabs.find(tab => tab.name === 'Teams');
      const agentTab = this.tabs.find(tab => tab.name === 'Agents');

      let workTypes: { id: string, name: string }[] = [];
      if (workTypeTab.data.selectedWorktypes.length > 0) {
        if (workTypeTab.data.selectedWorktypes[0].name === 'All') {
          workTypeTab.data.selectedWorktypes = [...workTypeTab.data.workTypes]
        } else {
          workTypes = workTypeTab.data.selectedWorktypes;
        }
      }

      let payload = {
        routingGroupId: this.routingGroup.routingGroupId,
        name: this.formData.name,
        members: (agentTab.data.selectedAgents || []).map(m => {
          return { memberId: m.id, name: m.name };
        }),
        teams: (teamTab.data.selectedTeams || []).map(t => {
          return { teamId: t.id, name: t.name };
        }),
        taskTemplates: workTypes.map(tt => {
          return { taskTemplateId: tt.id, name: tt.name };
        })
      };

      this.routingGroupsService
        .updateRoutingGroup(
          payload.routingGroupId,
          payload.name,
          payload.taskTemplates,
          payload.teams,
          payload.members
        )
        .then(() => this.dialogController.ok(payload))
        .catch(e => {
          console.warn('Failed to update Agent Sampling Config due to:', e);
          this.submitting = false;
        })
    }
  }

}

