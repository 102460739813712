import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('InfiniteScrollService');

export class InfiniteScrollService {

  private container: Element;
  private callback: () => void;
  public loader: boolean;

  constructor(container: Element, callback: () => void) {
    this.container = container;
    this.callback = callback;
    this.container.addEventListener('scroll', () => this.scrollComplete());
  }

  private scrollComplete(): void {
    let currentScrollTop = this.container.clientHeight + this.container.scrollTop;
    if (this.container.scrollHeight <= currentScrollTop) {
      this.callback();
      this.loader = true;
    }
  }
}