import { ReportConfig } from './report-config';

export class ReportMenuCategory {
  heading: string = '';
  archived: boolean = false;
  reports: ReportConfig[] = [];
  selected: boolean = false;

  constructor(heading: string, archived?: boolean) {
    this.heading = heading;
    this.archived = archived;
  }

  public withReport(report: ReportConfig): void {
    this.reports.push(report);
  }
}
