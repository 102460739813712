import { HttpClient } from 'aurelia-http-client';
import { inject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CommandFactory, SessionStore, OplogService } from 'zailab.common';
import { WidgetServiceAbstract } from '../widget-service-abstract';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
/* */
const RETRIEVE_SERVICES_API: string = 'v1/organisation/organisations/me/services-with-task-templates';
const RETRIEVE_STATS_BY_SERVICE_API: string = 'v1/organisation/me/services/';
const logger = LogManager.getLogger('WaitingRoomService');
/* */
@inject(HttpClient, SessionStore, OplogService, EventAggregator)
export class WaitingRoomService extends WidgetServiceAbstract {

  teamId: string;
  organisationId: string;

  constructor(httpClient: HttpClient, sessionStore: SessionStore, oplogService: OplogService, eventAggregator: EventAggregator) {
    super(httpClient, sessionStore, oplogService, eventAggregator);
  }

  public retrieveServicesByOrgId(): Promise<Array<ZIService>> {
    return new Promise((resolve: any, reject: any) => {
      this.httpClient.createRequest(RETRIEVE_SERVICES_API).asGet().send().then(
        _response => {
          if(_response && _response.services) {
            resolve(_response.services);
          }
        },
        error => {
          reject(error);
        }
      );
    })
  }

  public retrieveStatsByService(_serviceId: string, _taskTemplateId: string): Promise<object> {

    let retrieveServicesUri: string = `${RETRIEVE_STATS_BY_SERVICE_API}${_serviceId}`;
    if (_taskTemplateId) {
      retrieveServicesUri += `/tasktemplate/` + _taskTemplateId;
    }
    return new Promise((resolve: any, reject: any) => {
      this.httpClient.createRequest(retrieveServicesUri).asGet().send().then(
        _response => {
          resolve(_response as object);
        },
        error => {
          reject(error);
        }
      );
    })
  }

  public retrieveWaitingRoomWidgetData(serviceId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/interaction/live-dashboard/services/${serviceId}/widget`)
      .asGet()
      .send();
  }

  initialiseOplog(_criteria: string, _path: string, _command: string): Promise<ZIOplog> {
    return new Promise((resolve) => {
      resolve(this.oplogService.subscribeOn('_id', _criteria).in(_path + '.' + _command));
    });
  }

}
