/*
*/
import {LogManager} from 'aurelia-framework';
import {handle} from 'aurelia-flux';
/*
*/
import {FORM_ACTIONS} from './form.actions';
/*
*/
const logger = LogManager.getLogger('FormStore');
/*
*/
let STATE = {

  sections: []
};

/*
*/
export class FormStore {

  constructor() {

  }

  get sections() {

    return STATE.sections;
  }

  get form() {

    return STATE.form;
  }

  @handle(FORM_ACTIONS.RETRIEVE_FORM)
  handleRetrieveForm(action, form) {

    let list = [];

    for (let section of form.sections) {

      if (section && section.questions && section.questions.length === 0) {
        continue;
      }
      if (section && section.questions && section.questions.length > 0) {
        list.push(section);
      }
    }

    for (let i = 0; i < list.length; i++) {
      list[i].expanded = true;
    }
    STATE.sections = list;
    STATE.form = form;
  }

  @handle(FORM_ACTIONS.CLEAR_FORM)
  handleClearForm(action, sections) {

    STATE.sections = [];
  }
}