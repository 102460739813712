import {LogManager, inject, customElement, bindable} from 'aurelia-framework';

const logger = LogManager.getLogger('UtilityButtonV2');

import './utility-button-v2.scss';

@customElement('utility-button-v2')
@inject(Element)
export class UtilityButtonV2 {

  @bindable icon;
  @bindable text;
  @bindable focused;
  @bindable spaced = true;
  @bindable position;
  @bindable processing;
  @bindable size;
  @bindable color;
  @bindable({attirbute: 'no-corners'}) noCorners = false;
  @bindable({attirbute: 'no-margin'}) noMargin = false;

  constructor(
    private element: Element
  ) {}

  select() {

    this.element.dispatchEvent(
      new CustomEvent('this', {
        bubbles: true,
        detail: {}
      })
    );
  }

}
