import {LogManager, autoinject} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";
/**/
const logger = LogManager.getLogger('Name');
/**/
@autoinject
export class Name {

  private name: string;
  private variableName: string;
  private wizard: any;

  constructor(private eventAggregator:EventAggregator) {}

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.validate();
  }

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  private initWizard(wizard: any): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      //this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.name = this.wizard.data.name;
    this.variableName = this.wizard.data.variableName ? this.wizard.data.variableName : this.wizard.data.name;
  }

  private updateWizardData(): void {
    this.wizard.data.name = this.name;
    this.wizard.data.variableName = this.variableName;
  }

  private validate(): void {

    if(!this.name) {
      this.wizard.step.incomplete();
      return;
    }

    this.wizard.step.complete({name: this.name, variableName: this.variableName});
  }

  private nextStep(): void {
    this.wizard.continue();
  }

}
