// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { SessionStore } from 'zailab.common';
import { MembersService } from '../../members/members-service';

const logger = LogManager.getLogger('ChannelSelectionChannel');

@autoinject
export class ChannelSelectionChannel {

  public showDropdown: boolean = false;
  public memberChannelAvailability: any = null;

  public direction: { [key: string] : boolean } = {
    inbound: false,
    outbound: false
  };
  public channels: any[] = [];
  public campaigns: any[] = [];
  public filteredCampaigns: any[] = [];
  private selectedDiallerType: string;

  constructor(
    private session: SessionStore,
    private membersService: MembersService,
    private dialogController: DialogController
  ) {}

  public activate(): void {
    if (!this.session.get.user.hasAgentRole) { // todo: flaap - z-auth is not blocking lifecycle logic
      return;
    }
    this.findMemberChannelAvailability()
      .then(() => {
        this.showDropdown = !this.showDropdown;
        this.retrieveCampaigns();
      });
  }

  private retrieveCampaigns(): void {
    this.membersService
      .retrieveCampaigns(this.session.get.user.memberId)
      .then(data => this.campaignsRetrieved(data.campaignIds))
      .catch(e => logger.error)
  }

  private campaignsRetrieved(campaigns: { [key: string]: any }): void {
    if (campaigns) {
      let selectedCampaign;
      const keys = Object.keys(campaigns);
      keys.forEach(key => {

        if (campaigns[key].paused && !campaigns[key].selected) {
          return;
        }

        this.campaigns.push(campaigns[key]);
        if (campaigns[key].selected) {
          selectedCampaign = campaigns[key];
          this.selectedDiallerType = campaigns[key].diallerType;
        }
      });
      this.filteredCampaigns = [...this.campaigns];
      this.selectedDiallerType = selectedCampaign.diallerType;
      if (selectedCampaign.diallerType === 'Power' || selectedCampaign.diallerType === 'Predictive') {
        this.disableAllOtherDiallerTypes(selectedCampaign);
        return;
      }
      this.disableOtherDiallerTypes(selectedCampaign.diallerType);
    }
  }

  private findMemberChannelAvailability(): Promise<any> {
    return this.membersService.findMemberChannelAvailability(this.session.get.user.memberId)
      .then((memberChannelAvailability) => {
        this.memberChannelAvailability = memberChannelAvailability;
        return this.findMember();
      })
      .catch(() => {
        this.memberChannelAvailability = null;
        return this.findMember();
      });
  }

  private findMember(): Promise<any> {
    return this.membersService.retrieveMember(this.session.get.user.memberId)
      .then((member) => {
        if (this.memberChannelAvailability && this.memberChannelAvailability.availability) {
          this.channels = this.memberChannelAvailability.availability.map(channel => {
            return {
              channelName: channel.channelName,
              selected: channel.available,
              checked: channel.available
            }
          });
          this.channels.forEach((channel) => channel.selected = false);
          let channel = this.memberChannelAvailability.availability.find((channel) => channel.channelName === 'OUTBOUND_CALL' && channel.available);
          if (channel) {
            this.selectDirection('outbound');
          } else {
            this.selectDirection('inbound');
            this.selectAllInboundChannels();
          }
        }
      });
  }

  public selectDirection(direction: string): void {
    let keys = Object.keys(this.direction);
    keys.forEach(key => {
      this.direction[key] = key === direction;
    });
    
    this.selectAllInboundChannels();
    if (direction === 'inbound') {
      this.channels.forEach(channel => {
        if(channel.channelName === 'OUTBOUND_CALL') {
          channel.checked = false;
        } else {
          channel.checked = true;
        }
      });
      logger.info('Update channelMap ', this.channels);
    } else {
      this.channels.forEach(channel => {
        if(channel.channelName !== 'OUTBOUND_CALL') {
          channel.checked = false;
        } else {
          channel.checked = true;
        }
      });
      logger.info('Update channelMap ', this.channels);
    }
  }

  public selectCampaign(): void {
    const campaign = this.campaigns.find(camp => camp.selected);
    if (campaign) {
      this.selectedDiallerType = campaign.diallerType;
      if (campaign.diallerType === 'Power' || campaign.diallerType === 'Predictive') {
        this.disableAllOtherDiallerTypes(campaign);
        return;
      }
      this.disableOtherDiallerTypes(campaign.diallerType);
    } else {
      this.enableAllWorkTypes();
    }
  }

  public clearAllCampaigns(): void {
    this.campaigns.forEach(campaign => {
      campaign.selected = false;
      campaign.disabled = false;
    });
    this.selectedDiallerType = null;
  }

  private disableAllOtherDiallerTypes(campaign: any): void {
    this.campaigns.forEach(_campaign => {
      _campaign.disabled = _campaign.campaignName !== campaign.campaignName;
    });
  }

  private enableAllWorkTypes(): void {
    this.campaigns.forEach(campaign => {
      campaign.disabled = false;
    });
    this.selectedDiallerType = null;
  }

  private disableOtherDiallerTypes(diallerType: string): void {
    this.campaigns.forEach(campaign => {
      campaign.disabled = false;
      if (campaign.diallerType !== diallerType) {
        campaign.disabled = true;
      }
    });
  }

  public selectAllCampaigns(): void {
    if (!this.selectedDiallerType) {
      return;
    }
    this.campaigns.forEach(campaign => {
      if (campaign.diallerType === this.selectedDiallerType) {
        campaign.selected = true;
      }
    });
    this.disableOtherDiallerTypes(this.selectedDiallerType);
  }


  public selectAllInboundChannels(): void {
    this.channels.forEach(channel => {
      if (channel.channelName !== 'OUTBOUND_CALL') {
        channel.checked = true;
      } else {
        channel.checked = false;
      }
    });
  }

  public cancel(): void {
    this.channels = [];
    this.campaigns = [];
    this.filteredCampaigns = [];
    this.selectedDiallerType = null;
    this.dialogController.cancel();
  }

  public updateChannelSelection(): void {
    let channelMap = [];
    this.channels.forEach(channel => {
      channelMap.push(
        {
          channelName: channel.channelName,
          available: channel.checked
        }
      )
    });

    const payload = {
      memberId: this.session.get.user.memberId,
      organisationId: this.session.get.organisation.organisationId,
      availability: channelMap,
      campaigns: 
        this.campaigns
          .filter(campaign => campaign.selected)
          .map(campaign => {
            delete campaign.selected;
            return campaign;
          })
    };

    // The below doesn't have the inbound/outbound selection
    this.membersService
      .changeMemberChannelAvailability(this.session.get.user.memberId, payload)
      .then(() => this.dialogController.ok());
  }

  public search(searchTerm: string): void {
    this.filteredCampaigns = this.campaigns.filter(campaign => {
      return campaign.campaignName && campaign.campaignName.toLowerCase().startsWith(searchTerm.toLowerCase());
    })
  }
}
