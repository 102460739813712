import {autoinject, LogManager} from 'aurelia-framework';
import {ZIWizard} from '../../../../../../../../../typings/zai/zai.common';
import {InteractionFlow} from "../../../../interaction-flow";
import {DialogService} from 'aurelia-dialog';
import {TableInputModel} from "../../../../../../../../components/custom/table-input";
/**/
const logger = LogManager.getLogger('SmartRouting');
/**/
@autoinject
export class SmartRouting {

  private smartRoutingEnabled: boolean = false;
  private smartRoutingUrl: string;
  private tableCustomFields: TableInputModel;
  private smartRoutingRequestHeaders: any;
  private tableRequestHeaders: TableInputModel;
  private smartRoutingCustomFields: any;
  private wizard: ZIWizard;

  constructor(private interactionFlow: InteractionFlow, protected dialogService: DialogService) {
  }

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.initTableInputVariables();
    this.initTableInputHeaders();
    this.validate();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.populateHeadersFromModel();
      this.populateVariablesFromModel();
      this.updateWizardData();
      this.nextStep();
    });
  }

  public populateHeadersFromModel(): void {
    this.smartRoutingRequestHeaders = [];
    this.tableRequestHeaders.tables[0].rows.forEach((row) => {
      const header = {};
      header[row.cells[0]] = row.cells[1];
      this.smartRoutingRequestHeaders.push(header);
    });
  }

  public populateVariablesFromModel(): void {
    this.smartRoutingCustomFields = [];
    this.tableCustomFields.tables[0].rows.forEach((row) => {
      const header = {};
      header[row.cells[0]] = row.cells[1];
      this.smartRoutingCustomFields.push(header);
    });
  }

  private updateWizardData(): void {
    this.wizard.data.smartRoutingEnabled = this.smartRoutingEnabled;
    this.wizard.data.smartRoutingUrl = this.smartRoutingUrl;
    this.wizard.data.smartRoutingRequestHeaders = this.smartRoutingRequestHeaders;
    this.wizard.data.smartRoutingCustomFields = this.smartRoutingCustomFields;
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  private extractWizardData(): void {
    this.smartRoutingEnabled = this.getSmartRoutingEnabled();
    this.smartRoutingUrl = this.wizard.data.smartRoutingUrl;
    this.smartRoutingRequestHeaders = this.wizard.data.smartRoutingRequestHeaders;
    this.smartRoutingCustomFields = this.wizard.data.smartRoutingCustomFields;
  }

  private initTableInputVariables(): void {
    const builder = TableInputModel.builder
      .setHeaders('Custom Field Name', 'Custom Field Value')
      .hideHeaders();
    if (this.smartRoutingCustomFields) {
      for (const variable of this.smartRoutingCustomFields) {
        for (const prop in variable) {
          builder.addRow(prop, variable[prop]);
        }
      }
    }
    this.tableCustomFields = new TableInputModel([builder.build()]);
  }

  private initTableInputHeaders(): void {
    const builder = TableInputModel.builder
      .setHeaders('Header Name', 'Header Value')
      .hideHeaders()
    if (this.smartRoutingRequestHeaders) {
      for (const header of this.smartRoutingRequestHeaders) {
        for (const prop in header) {
          builder.addRow(prop, header[prop]);
        }
      }
    }
    this.tableRequestHeaders = new TableInputModel([builder.build()]);
  }

  private validate(): void {
    if (this.smartRoutingEnabled && !this.smartRoutingUrl) {
      this.wizard.step.incomplete();
      return;
    }

    this.updateWizardData();
    this.wizard.step.complete();
  }

  private getSmartRoutingEnabled(): boolean {
    return ((typeof (this.wizard.data.smartRoutingEnabled) === 'undefined') || (this.wizard.data.smartRoutingEnabled === null)) ?
      false : this.wizard.data.smartRoutingEnabled;
  }

  private toggleSmartRoutingEnabled(): void {
    this.smartRoutingEnabled = !this.smartRoutingEnabled;
    this.validate();
  }
}