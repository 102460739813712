import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager, Factory} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {CommandFactory} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('AssignEmailDialogService');
/*
 * */
@inject(HttpClient, EventAggregator, Factory.of(CommandFactory))
export class AssignEmailDialogService {

  constructor(httpClient, eventAggregator, commandFactory) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
  }

  retrieveInteractionFlowEmails(interactionFlowId, params) {
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/assigned-target-resources`)
        .asGet()
        .withParams(params)
        .send()
        .then(
          (response) => resolve(response),
          (error) => reject(error)
        );
    });
  }

  addEmailToInteractionFlow(interactionFlowId, organisationId, email, live) {

    let payload = {organisationId, email, live};

    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/email`)
      .asPost()
      .withContent(payload)
      .send()
      .then(response => {}, error => {});
  }

  removeEmailFromInteractionFlow(interactionFlowId, organisationId, email) {

    let payload = {organisationId, email};

    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/email`)
      .asDelete()
      .withContent(payload)
      .send()
      .then(response => {}, error => {});
  }
}
