import { autoinject, LogManager, customElement, bindable} from 'aurelia-framework';
import timezone from 'countries-and-timezones';

import {SessionStore} from 'zailab.common';

const logger = LogManager.getLogger('ZTimezoneSelector');

@customElement('z-timezone-selector')
@autoinject()
export class ZTimezoneSelector {

  private timezones: Array<ITimezone>;
  @bindable({attribute: 'selected-country'}) private selectedCountry: ICountry;
  @bindable({attribute: 'selected-timezone'}) private selectedTimezone: ITimezone;

  constructor() {}

  private selectedCountryChanged(): void {
    this.timezones = (() => {
      if (!this.selectedCountry.id) {
        return;
      }
      return this.setTimezones(this.selectedCountry.id);
    })();
  }

  private setTimezones(selectedCountry: string): Array<ITimezone> {
    let timezones = timezone.getTimezonesForCountry(selectedCountry);
    timezones.forEach(zone => {
      let zoneName = zone.name.replace('_', ' ');
      zone.description = '(UTC' + zone.dstOffsetStr + ') ' + this.stripName(zoneName);
    });
    this.selectedTimezone = timezones[0];
    return timezones;
  }

  private stripName = name => {
    let startIndex = name.indexOf('/');
    name = name.replace(/\//g, ', ');
    return name.substring(startIndex + 1, name.length);
  };
}
