import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DashboardWidget } from '../../dashboard/dashboard-abstract';

const logger = LogManager.getLogger('ContactFieldsWidget');

@autoinject()
export class ContactFieldsWidget extends DashboardWidget {

  constructor(public router: Router) {
    super();
  }
}