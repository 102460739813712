import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { OutboundFlowService } from './outbound-flow-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionStore } from 'zailab.common';
import { OrganisationSessionModel } from '../../../_common/stores/sessionmodels/organisation-session-model';
/* */
const logger = LogManager.getLogger('OutboundFlowService');

/* */
@autoinject
export class OutboundFlowSelection {
  private showWorkflowDropdown: boolean = false;
  private outboundFlowList: ZIOutboundWorkFlowCollection[];
  private selectedFlow: ZIOutboundWorkFlowCollection;
  private updateEventHandler: any;
  private deleteEventHandler: any;
  private hasOutboundFlow: boolean = false;

  constructor(private outboundService: OutboundFlowService, private eventAggregator: EventAggregator, private sessionStore: SessionStore) {}

  private attached(): void {
    this.setupListeners();
    if (this.sessionStore.get.user && this.sessionStore.get.user.hasOutboundChannel) {
      this.retrieveOutboundFlows();
    }
  }

  private detached(): void {
    this.removeListeners();
  }

  private setupListeners(): void {
    this.updateEventHandler = this.eventAggregator.subscribe('update.workflow', data => this.handleWorkflowUpdate(data));
    this.deleteEventHandler = this.eventAggregator.subscribe('delete.workflow', data => this.handleWorkflowUpdate(data));
  }

  private removeListeners(): void {
    this.updateEventHandler.dispose();
    this.deleteEventHandler.dispose();
  }

  private handleWorkflowUpdate(_response: any): void {

    if(!_response.outboundFlowOptions) {
      return;
    }

    this.outboundFlowList = [].concat(_response.outboundFlowOptions) as ZIOutboundWorkFlowCollection[];
    this.outboundFlowList.unshift({ flowId: null, selected: false, flowName: 'Private (No Flow)' });
    this.setSelectedFlow();
  }

  private retrieveOutboundFlows(): void {
    if (this.organisation) {
      const organisationId = this.organisation.organisationId;
      this.outboundService.retrieveOrganisationInteractionFlows(organisationId).then(
        _response => {
          this.hasOutboundFlow = true;
          this.outboundFlowList = [].concat(_response) as ZIOutboundWorkFlowCollection[];
          this.outboundFlowList.unshift({ flowId: null, selected: false, flowName: 'Private (No Flow)' });
          this.setSelectedFlow();
        },
        _error => {}
      );
    } else {
      setTimeout(() => {
        this.retrieveOutboundFlows();
      }, 500);
    }
  }

  private setSelectedFlow(): void {
    let _selectedFlow: ZIOutboundWorkFlowCollection = this.checkSelected();
    if (_selectedFlow !== null) {
      this.selectedFlow = _selectedFlow;
    } else if (this.outboundFlowList[0]) {
      this.outboundFlowList[0].selected = true;
      this.selectedFlow = this.outboundFlowList[0];
    }
  }

  private checkSelected(): ZIOutboundWorkFlowCollection {
    return (
      this.outboundFlowList.filter(_value => {
        return _value.selected;
      })[0] || null
    );
  }

  private toggleVisibility(): void {
    this.showWorkflowDropdown = !this.showWorkflowDropdown;
  }

  public changeWorkflow(outboundFlow: ZIOutboundWorkFlowCollection): void {
    this.selectedFlow = outboundFlow;
    this.showWorkflowDropdown = false;
    this.outboundService.selectOutboundCallOption(outboundFlow.flowId);
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}
