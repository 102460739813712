import {
  LogManager,
  autoinject,
  inject,
  customElement,
  bindable,
  BindingEngine,
} from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
/**/
import { GridCellModel } from './models/grid-cell-model';
import { ZGridDraggable } from './z-grid-draggable';
import { DraggableService } from '../../../_common/services/draggable.service';
import { Disposable, observable } from 'aurelia-binding';
/**/
const logger = LogManager.getLogger('ZGrid');
/**/
@autoinject
@inject(BindingEngine, EventAggregator, Element, DraggableService)
@customElement('z-grid')
export class ZGrid extends ZGridDraggable {
  private element: Element;
  private grid: Array<Array<GridCellModel>> = [];
  private observerLocator: BindingEngine;
  private eventAggregator: EventAggregator;
  private subscription: Disposable;

  @bindable({ attribute: 'cell-size' }) cellSize: number;
  @bindable({ attribute: 'number-of-columns' }) numberOfColumns: number = 0;
  @bindable({ attribute: 'number-of-rows' }) numberOfRows: number = 0;
  @bindable version: number;
  @bindable data: GridCellModel[];

  public constructor(
    observerLocator: BindingEngine,
    eventAggregator: EventAggregator,
    element: Element,
    draggableService: DraggableService
  ) {
    super(draggableService);

    this.element = element;
    this.observerLocator = observerLocator;
    this.eventAggregator = eventAggregator;
  }

  public dataChanged(): void {
    this.subscribe();
    this.clearNodes();
    this.renderData(this.data);
  }

  public bind(): void {
    this.renderGrid();
    this.renderData(this.data);
    this.subscribe();
  }

  private renderGrid(): void {
    let col: number = 0;
    let row: number = 0;

    while (col < this.numberOfColumns) {
      this.addColumn();
      col++;
    }
    while (row < this.numberOfRows) {
      this.addRow();
      row++;
    }
  }

  private clearNodes(): void {
    const nodes = document.querySelectorAll('.o-node');
    nodes.forEach((node) => node.parentElement.removeChild(node));
  }

  private addColumn(isNewColumn?: boolean): void {
    let newColumnIndex: number = this.grid.length;
    let newColumnCells: Array<GridCellModel> = isNewColumn
      ? this.generateCellsForColumn(newColumnIndex)
      : [];

    this.grid.push(newColumnCells);

    if (isNewColumn) {
      this.numberOfColumns++;
    }
  }

  private generateCellsForColumn(columnIndex: number): Array<GridCellModel> {
    let cells: Array<GridCellModel> = [];
    let rowIndex = 0;

    while (rowIndex < this.numberOfRows) {
      cells.push(new GridCellModel({ x: columnIndex, y: rowIndex }));
      rowIndex++;
    }

    return cells;
  }

  private addRow(isNewRow?: boolean): void {
    let columnIndex: number = 0;

    while (columnIndex < this.grid.length) {
      let newRowIndex = this.grid[columnIndex].length;
      let cell: GridCellModel = new GridCellModel({
        x: columnIndex,
        y: newRowIndex,
      });

      this.grid[columnIndex].push(cell);

      columnIndex++;
    }

    if (isNewRow) {
      this.numberOfRows++;
    }
  }

  private renderData(dataSet: GridCellModel[]): void {
    let highestCoordinates = this.determineHighestCoordinates(dataSet);
    let newColumns: number = highestCoordinates.x - this.numberOfColumns;
    let newRows: number = highestCoordinates.y - this.numberOfRows;

    while (newColumns > -1) {
      this.addColumn(true);

      newColumns--;
    }

    while (newRows > -1) {
      this.addRow(true);

      newRows--;
    }

    dataSet.forEach((cellData) => {
      cellData.version = this.version;
      this.setCellAt(cellData.x, cellData.y, cellData);
    });
  }

  private determineHighestCoordinates(dataSet: Array<GridCellModel>): object {
    let highest_xValue: number = 0;
    let highest_yValue: number = 0;

    dataSet.forEach((cellData) => {
      if (cellData.x > highest_xValue) {
        highest_xValue = cellData.x;
      }

      if (cellData.y > highest_yValue) {
        highest_yValue = cellData.y;
      }

      if (
        cellData.rowsToMerge &&
        cellData.rowsToMerge + cellData.y > highest_yValue
      ) {
        highest_yValue = cellData.rowsToMerge + cellData.y;
      }
    });

    return { x: highest_xValue, y: highest_yValue };
  }

  private setCellAt(x: number, y: number, cell: any): GridCellModel {
    if (!this.grid[x]) {
      return;
    }

    this.grid[x].splice(y, 1, cell);
  }

  private subscribe(): void {
    if (this.subscription) {
      this.subscription.dispose();
    }

    this.subscription = this.observerLocator
      .collectionObserver(this.data)
      .subscribe((data) => this.dataUpated(data));
  }

  private dataUpated(updatedData: Array<object>): void {
    let isRemoved: boolean =
      updatedData[0] &&
      updatedData[0].removed &&
      updatedData[0] &&
      updatedData[0].removed.length > 0
        ? true
        : false;
    let isAdded: boolean =
      updatedData[0] && updatedData[0].addedCount > 0 ? true : false;

    if (isRemoved) {
      let cellToRemove: GridCellModel = updatedData[0].removed[0];

      this.removeCell(cellToRemove);

      return;
    }

    if (isAdded) {
      this.renderData(this.data);
    }
  }

  private removeCell(cellToRemove: GridCellModel): void {
    let x = cellToRemove.x;
    let y = cellToRemove.y;
    let emptyCell: GridCellModel = new GridCellModel({ x, y });

    this.setCellAt(x, y, emptyCell);
  }

  private removeColumn(): void {
    const highestCoordinates = this.determineHighestCoordinates(this.data);

    if (highestCoordinates.x + 1 === this.numberOfColumns) {
      // There's a cell populated in this position
      return;
    }

    this.grid.pop();

    this.numberOfColumns--;
  }

  private removeRow(): void {
    let columnIndex = 0;

    while (columnIndex < this.grid.length) {
      this.grid[columnIndex].pop();

      columnIndex++;
    }
    this.numberOfRows--;
  }

  private findCellAt(x: number, y: number): GridCellModel {
    let cell = this.grid[x][y];

    return cell;
  }

  public selectCell(evt: Event, cell: GridCellModel): void {
    this.eventAggregator.publish('grid.cell.select', { evt, cell });
  }

  public numberOfColumnsChanged(newValue: number, oldValue: number): void {
    if (!oldValue) {
      return;
    }

    let col: number = 0;
    while (col < newValue - oldValue) {
      let newColumnIndex: number = this.grid.length;
      let newColumnCells: Array<GridCellModel> =
        this.generateCellsForColumn(newColumnIndex);
      this.grid.push(newColumnCells);
      col++;
    }
  }

  public numberOfRowsChanged(newValue: number, oldValue: number): void {
    if (!oldValue) {
      return;
    }

    let col: number = 0;
    while (col < newValue - oldValue) {
      let columnIndex: number = 0;
      while (columnIndex < this.grid.length) {
        let newRowIndex = this.grid[columnIndex].length;
        let cell: GridCellModel = new GridCellModel({
          x: columnIndex,
          y: newRowIndex,
        });
        this.grid[columnIndex].push(cell);
        columnIndex++;
      }
      col++;
    }
  }

  protected dragEnd(cell: GridCellModel): boolean {
    if (!this.draggableService.draggingOverData) {
      return;
    }
    this.checkAndAddEmptyColumns();
    this.checkAndAddEmptyRows();
    this.element.dispatchEvent(
      new CustomEvent('cell-dragged', {
        bubbles: true,
      })
    );
    return super.dragEnd(cell);
  }

  private checkAndAddEmptyColumns(): void {
    const x = this.draggableService.draggingOverData.x;
    if (x + 1 >= this.numberOfColumns) {
      this.numberOfColumns += 10;
    }
  }
  private checkAndAddEmptyRows(): void {
    const y = this.draggableService.draggingOverData.y;
    if (y + 1 >= this.numberOfRows) {
      this.numberOfRows += 10;
    }
  }

  public doNothing(): void {}
}
