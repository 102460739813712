import { inject, LogManager } from 'aurelia-framework';
import { ValidationController, ControllerValidateResult } from 'aurelia-validation';

import { CurrentScopeValidation } from 'zailab.common';

import { LowCreditNotificationDetails } from './low-credit-notification-model';
import { LowCreditNotificationService } from './low-credit-notification-service';

const logger = LogManager.getLogger('LowCreditNotification');

@inject(CurrentScopeValidation, LowCreditNotificationService)
export class LowCreditNotificationCtrl {

  public lowCreditNotification: LowCreditNotificationDetails = new LowCreditNotificationDetails();
  public isNotificationConfigured: boolean = false;
  public isNotificationEditSelected: boolean = false;
  public isNotificationSaving: boolean = false;
  public isNotificationEnabledToggling: boolean = false;
  public isNotificationReady: boolean = false;
  public lowCreditNotificationValidation: ValidationController;

  private tempLowCreditNotification: LowCreditNotificationDetails;
  private lowCreditNotificationService: LowCreditNotificationService;

  constructor(currentScopeValidation: CurrentScopeValidation, lowCreditNotificationService: LowCreditNotificationService) {
    this.lowCreditNotificationService = lowCreditNotificationService;
    this.lowCreditNotificationValidation = currentScopeValidation.getController();
  }

  public attached(): void {
    this.isNotificationConfigured = false;
    this.isNotificationEditSelected = false;
    this.lowCreditNotificationService.findLowCreditNotification()
      .then((data: LowCreditNotificationDetails) => this.handleLowCreditNotificationResponse(data));
  }

  public detached(): void {
    this.lowCreditNotificationService.close();
    this.isNotificationReady = false;
  }

  public configureLowCreditNotificationDetails(): void {
    this.isNotificationConfigured = true;
    this.editLowCreditNotificationDetails();
  }

  public editLowCreditNotificationDetails(): void {
    this.isNotificationEditSelected = true;
    this.tempLowCreditNotification = this.lowCreditNotification.clone();
  }

  public saveLowCreditNotificationDetails(): void {
    if (this.isNotificationSaving) {
      return;
    }
    if (!this.tempLowCreditNotification.isEmpty() && this.tempLowCreditNotification.equals(this.lowCreditNotification)) {
      return this.cancelLowCreditNotificationDetails();
    }
    this.isNotificationSaving = true;
    this.lowCreditNotificationValidation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result))
      .catch((error: any) => this.handleError(error));
  }

  public cancelLowCreditNotificationDetails(): void {
    if (this.isNotificationSaving) {
      return;
    }
    this.commitLowCreditNotificationDetails();
    if (this.tempLowCreditNotification.isEmpty()) {
      this.isNotificationConfigured = false;
      this.lowCreditNotification.clear();
    } else {
      this.lowCreditNotification.update(this.tempLowCreditNotification);
    }
  }

  public toggleNotificationEnabled(): void {
    if (this.isNotificationEnabledToggling) {
      return;
    }
    this.isNotificationEnabledToggling = true;
    this.commitLowCreditNotificationEnabledToggle();
    if (this.lowCreditNotification.enabled) {
      this.lowCreditNotificationService.enableLowCreditNotificationDetails(this.lowCreditNotification)
        .then(() => this.isNotificationEnabledToggling = false)
        .catch(() => this.revertLowCreditNotificationEnabledToggle());
    } else {
      this.lowCreditNotificationService.disableLowCreditNotificationDetails(this.lowCreditNotification)
        .then(() => this.isNotificationEnabledToggling = false)
        .catch(() => this.revertLowCreditNotificationEnabledToggle());
    }
  }

  public get isNotificationEnabled(): boolean {
    return this.lowCreditNotification.enabled;
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    if (result.valid) {
      if (this.tempLowCreditNotification.isEmpty()) {
        this.lowCreditNotificationService.createLowCreditNotificationDetails(this.lowCreditNotification)
          .then(() => this.commitLowCreditNotificationDetails(true))
          .catch(() => this.revertLowCreditNotificationDetails());
      } else {
        this.lowCreditNotificationService.updateLowCreditNotificationDetails(this.lowCreditNotification)
          .then(() => this.commitLowCreditNotificationDetails())
          .catch(() => this.revertLowCreditNotificationDetails());
      }
    } else {
      this.revertLowCreditNotificationDetails();
    }
  }

  private commitLowCreditNotificationDetails(create?: boolean): void {
    this.isNotificationEditSelected = false;
    this.isNotificationSaving = false;
    if (create) {
      this.lowCreditNotification.enabled = true;
    }
  }

  private revertLowCreditNotificationDetails(): void {
    this.isNotificationSaving = false;
  }

  private commitLowCreditNotificationEnabledToggle(): void {
    this.lowCreditNotification.enabled = !this.lowCreditNotification.enabled;
  }

  private revertLowCreditNotificationEnabledToggle(): void {
    this.lowCreditNotification.enabled = !this.lowCreditNotification.enabled;
    this.isNotificationEnabledToggling = false;
  }

  private handleLowCreditNotificationResponse(data: LowCreditNotificationDetails): void {
    this.lowCreditNotification = data.clone();
    if (!this.lowCreditNotification.isEmpty()) {
      this.isNotificationConfigured = true;
    }
    this.isNotificationReady = true;
  }

  private handleError(error: any): void {
    logger.error('handleError :: error=', error);
  }
}
