import { autoinject, LogManager } from 'aurelia-framework';
//@ts-ignore
import { DialogController } from 'aurelia-dialog';


const logger = LogManager.getLogger('BulkUploadDialog');

@autoinject()
export class BulkUploadDialog {

  public dispositionList: DispositionList;
  
  public file = null;
  public fileName: string = 'No file selected.';
  validation = null;

  constructor(
    private dialogController: DialogController
  ) {}

  public activate(dispositionList: DispositionList): void {
    this.dispositionList = dispositionList;
  }
  
  public selectFile(evt: any): void {
    let file = evt.target.files[0];
    if(!file || !file.name || !this.isValid(file).extension() || !this.isValid(file).size()) {
      return;
    }

    this.validation = null;
    let reader = new FileReader();
    this.fileName = file.name;

    if(!file){
      return;
    }
    reader.onload = (event) => {
      this.file = {
        content: event.target.result,
        name: this.fileName
      };
    };
    reader.readAsText(file);
  }

  private isValid(file: any): { extension: Function, size: Function } {
    function _extension(): boolean {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];

      if(extension !== 'csv') {
        _setIncompleteStateWith('Please select a CSV file.');
        return false;
      }
      return true;
    }

    function _size(): boolean {
      let maxSize = 10 * 1024 * 1024;

      if(file.size >= maxSize) {
        _setIncompleteStateWith('Please select a CSV file that does not exceed 10MB.');
        return false;
      }
      return true;
    }

    let _setIncompleteStateWith = (validation): void => {
      this.file = null;
      this.fileName = null;
      // this.wizard.step.incomplete({ file: null });
      this.validation = validation;
    };

    return {
      extension: _extension,
      size: _size
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public upload(): void {
    this.dialogController.ok(this.file);
  }

}

