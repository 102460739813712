import {autoinject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {BindingSignaler} from 'aurelia-templating-resources';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
/**/
import {Validation} from 'zailab.validation';
import {SessionStore, BootstrapFormRenderer} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
import {TimezoneAbstract} from '../../timezone-abstract';
/**/
const logger = LogManager.getLogger('OfficeHoursDialog');

/**/
@autoinject()
export class OfficeHoursDialog extends TimezoneAbstract {

  public name: string = '';
  private validation: any;

  protected nodeData: NodeModel;
  private officeHours: Array<object> = [];
  private existingConnections: Array<ConnectorModel> = [];
  private isEditing: string = '';
  private isValid: boolean = false;
  private nodeConfiguration: ZNodeConfig;

  constructor(
    private dialogController: DialogController,
    private updateTimeSignal: BindingSignaler,
    protected sessionStore:SessionStore,
    validationControllerFactory: ValidationControllerFactory
  ) {
    super(sessionStore);
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    super.activate(_config);
    this.existingConnections = this.nodeData.connections;
    if (this.nodeData.properties['daysOfWeek']) {
      let _officeHours: IOfficeHours = this.nodeData.properties['daysOfWeek'] as IOfficeHours;
      // Destructure _officeHours object into officeHours Array
      for (let _day in _officeHours) {
        if (_officeHours[_day].closed) {
          let dayObject: object = {
            dayName: _day,
            startingHour: '09',
            endingHour: '17',
            startingMinute: '00',
            endingMinute: '00',
            closed: true,
            errors: []
          };
          this.officeHours.push(dayObject);
        } else {
          let dayObject: object = {
            dayName: _day,
            startingHour: parseInt(_officeHours[_day].startingTime.substr(0, 2)),
            endingHour: parseInt(_officeHours[_day].endingTime.substr(0, 2)),
            startingMinute: parseInt(_officeHours[_day].startingTime.substr(2, 2)),
            endingMinute: parseInt(_officeHours[_day].endingTime.substr(2, 2)),
            closed: _officeHours[_day].closed,
            errors: []
          };
          this.officeHours.push(dayObject);
        }
      }
      this.name = this.nodeData.properties.name || '';
      this.checkValid();
    }
  }

  public attached(): void {
    this.initialiseValidation();
  }

  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  private meridiem(hour: number): string {
    return hour < 12 ? 'AM' : 'PM'
  }

  private checkValid(): void {
    this.isValid = this.name && this.officeHours.filter(_value => {
      return _value.closed !== true
    }).length > 0
  }

  // Signal update to all time entries
  private updateTime(): void {
    this.updateTimeSignal.signal('update-time-signal');
  }

  private editHours(event: Event, day: object): void {
    this.isEditing = day['dayName'];
  }

  private toggleOpen(event: Event, day: object): void {
    day['closed'] = !event.target['checked'];
    this.checkValid();
  }

  private confirmHours(event: Event, day: object): void {
    if (this.passesValidation(day) === true) {
      this.isEditing = '';
      this.updateTime();
    }
  }

  private passesValidation(_day: object): boolean {
    _day['errors'] = [];
    // Add extra validation here
    if (parseInt(_day['startingHour']) > parseInt(_day['endingHour'])) {
      _day['errors'].push('Start time cannot be after end time');
    }
    return _day['errors'].length === 0;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.name = this.name.trim();
    if (this.name.length === 0) {
      return;
    }
    let saveOfficeHours: object = {};
    this.officeHours.forEach((value) => {
      if (value['closed']) {
        saveOfficeHours[value['dayName']] = {
          closed: true
        }
      } else {
        saveOfficeHours[value['dayName']] = {
          startingTime: ('00' + value['startingHour']).slice(-2) + ('00' + value['startingMinute']).slice(-2) + '00',
          endingTime: ('00' + value['endingHour']).slice(-2) + ('00' + value['endingMinute']).slice(-2) + '00',
        }
      }
    });

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.properties['name'] = this.name;
    this.nodeData.properties['isDefined'] = true;
    this.nodeData.properties['daysOfWeek'] = saveOfficeHours;
    this.nodeData.properties['timezone'] = this.selectedTimezone;
    this.dialogController.ok(new NodeModel(this.nodeData));
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    return [
      new ConnectorModel({
        name: 'yes',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: {connectorIndex: 0, nodeID: this.nodeData.id},
        customExtensions: null
      }),
      new ConnectorModel({
        name: 'no',
        dest: typeof _existingConnections[1] !== 'undefined' ? _existingConnections[1].dest : null,
        source: {connectorIndex: 1, nodeID: this.nodeData.id},
        customExtensions: null
      })
    ];
  }
}
