import {autoinject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';

import {SearchTools} from 'zailab.common';
import {IdDialogService} from '../id-dialog-service';
import {EmptyStateAbstract} from '../abstracts/empty-state-abstract';
import {ZIOplog} from '../../../../../../../typings/zai/zai.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
import { EmergencyAnnouncementService } from '../../../../emergencyAnnouncement/emergency-announcement-service';

const logger = LogManager.getLogger('EmergencyAnnouncementDialog');

@autoinject
export class EmergencyAnnouncementDialog extends EmptyStateAbstract {

  private dialogHeader: string = 'Emergency Announcement';
  private isProcessing: boolean = false;
  private isValid: boolean = true;
  private oplog: ZIOplog

  private emergencyAnnouncements: ZIEmergencyAnnouncement[] = [];
  private selectedEmergencyAnnouncement: ZIEmergencyAnnouncement;

  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private existingNodeData: any = {};

  private isSearching: boolean = false;
  private searchTerm: string;

  public emptyStateConfig: ZIEmptyState = {
    icon: 'exclamation.svg',
    heading: `No items to display.`,
    description: `No items to display. Please configure an emergency announcement on the Administration Dashboard.`,
    hasButton: false,
    hasTip: false,
    hasExternalContentCreator: false
  };

  constructor(
    public dialogController: DialogController,
    protected dialogService: DialogService,
    private idDialogService: IdDialogService,
    private emergencyAnnouncementService: EmergencyAnnouncementService
  ) {
    super(dialogService);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy

    if (this.nodeData.properties) {
      this.existingNodeData = this.nodeData.properties;
    }

    this.existingConnections = this.nodeData.connections;
  }
  
  public attached(): void {
    this.idDialogService.initialiseOplog('_id', 'organisationId', 'interaction-flow-projector', 'emergencyAnnouncementView').then(oplog => this.subscribeToOplogChanges(oplog));
    this.getEmergencyAnnouncements();
  }

  private subscribeToOplogChanges(_oplog: any): void {
    this.oplog = _oplog;
    this.oplog.on('update', () => this.getEmergencyAnnouncements());
    this.oplog.on('insert', () => this.getEmergencyAnnouncements());
  }

  private getEmergencyAnnouncements(): void {
    this.nodeDialogInitialised = false;
    this.emergencyAnnouncementService
      .retrieveEmergencyAnnouncements()
      .then((data: { emergencyMessages: ZIEmergencyAnnouncement[] }) => {
        this.emergencyAnnouncements = data.emergencyMessages;
        this.hasEntries = data.emergencyMessages.length > 0;
        this.nodeDialogInitialised = true;

        this.emergencyAnnouncements.forEach((emergencyAnnouncement: ZIEmergencyAnnouncement) => {

          if (emergencyAnnouncement.emergencyMessageId === this.existingNodeData.emergencyMessageId) {
            this.selectedEmergencyAnnouncement = emergencyAnnouncement;
            emergencyAnnouncement.isSelected = true;
            this.isValid = true;
          }
        });
        this.nodeDialogInitialised = true;
      }, (error) => {
        logger.info(' Failed to retrieve your organisation mailboxes > error = ', error);
      });
  }

  protected activateContentCreator(): void {
    super.activateContentCreator(this.emergencyAnnouncements);
  }

  protected handleContentCreated(): void {
    super.handleContentCreated({});
    this.getEmergencyAnnouncements();
  }

  public startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  private select(item: ZIEmergencyAnnouncement): void {

    this.emergencyAnnouncements.forEach(emergencyAnnouncement => {
      emergencyAnnouncement.isSelected = false;
    });
    this.selectedEmergencyAnnouncement = item;
    item.isSelected = true;
    this.isValid = true;
  }

  private ok(): void {
    this.dialogController.ok(this.mapNodeProperties());
  }

  private mapNodeProperties(): NodeModel {
    let updatedConnections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'default',
        source: {connectorIndex: 0, nodeID: this.nodeData.id}
      })
    ];

    updatedConnections.push(new ConnectorModel({
      name: '1',
      source: {connectorIndex: 1, nodeID: this.nodeData.id}
    }));

    updatedConnections = this.mapExistingConnections(updatedConnections, this.existingConnections);

    this.nodeData.properties.emergencyMessageId = this.selectedEmergencyAnnouncement.emergencyMessageId;
    this.nodeData.properties.isDefined = true;
    this.nodeData.properties.name = this.selectedEmergencyAnnouncement.name;
    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    return new NodeModel(this.nodeData);
  }
  
  private mapExistingConnections(updatedConnections: ConnectorModel[], existingConnections: ConnectorModel[]): ConnectorModel[] {

    let connectorIndex: number = 0;

    updatedConnections.forEach(connection => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest = existingConnections[connectorIndex].dest;
      }
      connectorIndex++
    });

    return updatedConnections;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public doNothing(): void {}
}

interface ZIEmergencyAnnouncement {
  emergencyMessageId?: string,
  name: string,
  audioId?: string,
  workTypeId?: string,
  menuSelectionEnabled: boolean,
  active?: boolean
  isSelected?: boolean
}
