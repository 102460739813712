import { inject, LogManager } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ThreeDSecurePopUp');

export class ThreeDSecurePopUp{

  constructor() {
  }

  open(url, fields, iframe) {
    iframe.style.backgroundColor = 'white';

    if (fields) {
      buildForm(url, fields, iframe).then(() => {
        this._submitForm(iframe);
      });
    } else {
      iframe.src = url;
    }
  };

  _submitForm(iframe) {

    let form = iframe.contentWindow.document;

    form.getElementById('myform').submit();
  }

}

function buildForm(url, fields, iframe) {

  return new Promise((resolve, reject) => {
    let formEl = document.createElement('form');
    formEl.id = 'myform';

    for (let entry in fields) {
      let fieldEl = document.createElement('input');

      fieldEl.setAttribute('name', entry);
      fieldEl.setAttribute('value', fields[entry]);
      fieldEl.setAttribute('type', 'hidden');

      formEl.appendChild(fieldEl);
    }

    formEl.method = 'post';
    formEl.action = url;
    iframe.contentDocument.body.appendChild(formEl);
    resolve();
  });
}
