import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { List } from 'zailab.abstract';
import { AbstractList } from "../../../../../abstract/abstract-list";
import { AddAudioGroupDialog } from './add-audio-group-dialog';
import { AudioGroupService } from './audio-group-service';

const logger = LogManager.getLogger('AudioGroupList');

@autoinject
export class AudioGroupList extends AbstractList {

  constructor(
    private element: any,
    private dialogService: DialogService,
    private audioService: AudioGroupService,
    private router: Router,
    eventAggregator: EventAggregator,
  ) {
    super(eventAggregator)
  }

  protected activate(params: any): void {
    super.activate(params);
  };

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.audioService.getAudioGroups().then((audioGroups: Array<ZIAudioGroup>) => {
      this.setupListConfig(audioGroups);
    }).catch(error => {
      logger.info(' Failed to retrieve your organisation audio files > error = ', error);
    })
  }

  protected setupListConfig(audioFiles: ZIAudioGroup[]): void {
    super.setupListConfig(audioFiles);
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .items(audioFiles)
      .icon('icon')
      .uniqueIdentifier('id')
      .displayId('name')
      .description('description')
      .hasRollover(false)
      .withProportion('medium')
      .tilesPerRow(6)
      .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected addItem(): void {
    this.dialogService.open({ viewModel: AddAudioGroupDialog, model: this.itemList.items }).whenClosed((response: any) => {
      if (!response.wasCancelled) {
        const audioGroupName = response.output;
        this.showLoader();
        this.audioService.addAudioGroup(audioGroupName).then(() => {
          setTimeout(() => this.retrieveList(), 1000);
        });
      }
    });
  };

  protected createMetaData(items: any[]): void {
    items.forEach(item => {
      item.icon = 'split';
      item.description = `${item.links.length} files`;
    });
  }

  protected selectItem(itemList: any): void {
    this.router.navigate(`audio-list?audioGroupId=${itemList.item.id}`);
  }

  protected deleteItems(items: any[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} audio groups deleted.` : `Audio group <b>${items[0].name}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(audioFile: any): void {
    super.delete(audioFile);
    let audioId = audioFile.id;
    this.audioService.deleteAudioGroup(audioId);
    const items = this.itemList.items.filter(audioFIle => {
      return audioFIle.id !== audioId;
    });
    this.setupListConfig(items);
  }

  public allAudio(): void {
    this.router.navigate('audio-list');
  }

  public workflows(): void {
    this.router.navigate('/hud/interactionflows');
  }
}