import { LogManager, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('DoNotContact');

export class DoNotContact {

  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: [''],
        name: 'Do Not Contact',
        moduleId: PLATFORM.moduleName('./donotcontact-list'),
        title: 'List'
      },
    ]);
    this.router = router;
  }
}