import {autoinject, LogManager, PLATFORM} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';

import {SearchTools} from 'zailab.common';
import {IdDialogService} from '../id-dialog-service';
import {EmptyStateAbstract} from '../abstracts/empty-state-abstract';
import {ZIOplog} from '../../../../../../../typings/zai/zai.common';
import {LoadBalancerService} from '../../../../../interaction/loadbalancer/load-balancer-service';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';

const logger = LogManager.getLogger('ZInteractionDesigner');

@autoinject
export class LoadBalancerDialog extends EmptyStateAbstract {

  private dialogHeader: string = 'Load Balancer';
  private isProcessing: boolean = false;
  private isValid: boolean = true;
  private oplog: ZIOplog

  private loadBalancers: ZILoadBalancer[] = [];
  private selectedLoadBalancer: ZILoadBalancer;

  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private existingNodeData: any = {};

  private isSearching: boolean = false;
  private searchTerm: string;

  public emptyStateConfig: ZIEmptyState = {
    icon: 'split.svg',
    heading: `No items to display.`,
    description: `You haven't created any load balancers yet.<br>Please create a load balancer on the Administration Dashboard.`,
    hasButton: true,
    buttonText: `Create Load Balancer`,
    hasTip: true,
    tipText: `Clicking the button above will open the Load Balancer Creator.`,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName(`features/interaction/loadbalancer/add/add-load-balancer-dialog`)
  };

  constructor(
    public dialogController: DialogController,
    protected dialogService: DialogService,
    private idDialogService: IdDialogService,
    private loadBalancerService: LoadBalancerService
  ) {
    super(dialogService);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy

    if (this.nodeData.properties) {
      this.existingNodeData = this.nodeData.properties;
    }

    this.existingConnections = this.nodeData.connections;
  }
  
  public attached(): void {
    this.idDialogService.initialiseOplog('_id', 'organisationId', 'interaction-flow-projector', 'loadBalancerView').then(oplog => this.subscribeToOplogChanges(oplog));
    this.getLoadBalancers();
  }

  private subscribeToOplogChanges(_oplog: any): void {
    this.oplog = _oplog;
    this.oplog.on('update', () => this.getLoadBalancers());
    this.oplog.on('insert', () => this.getLoadBalancers());
  }

  private getLoadBalancers(): void {
    this.nodeDialogInitialised = false;
    this.loadBalancerService
      .retrieveLoadBalancers()
      .then((data: { loadBalancers: ZILoadBalancer[] }) => {
        this.loadBalancers = data.loadBalancers;
        this.hasEntries = data.loadBalancers.length > 0;
        this.nodeDialogInitialised = true;

        this.loadBalancers.forEach((loadBalancer: ZILoadBalancer) => {

          if (loadBalancer.id === this.existingNodeData.loadBalancerId) {
            this.selectedLoadBalancer = loadBalancer;
            loadBalancer.isSelected = true;
            this.isValid = true;
          }
        });
        this.nodeDialogInitialised = true;
      }, (error) => {
        logger.info(' Failed to retrieve your organisation mailboxes > error = ', error);
      });
  }

  protected activateContentCreator(): void {
    super.activateContentCreator(this.loadBalancers);
  }

  protected handleContentCreated(): void {
    super.handleContentCreated({});
    this.getLoadBalancers();
  }

  public startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  private getOutputCount(item: ZLoadBalancer): number {
    let count = 0;
    item.defaultRatios.forEach((ratio: ZRatio) => count += ratio.outputs.length);
    return count;
  }

  private select(item: ZILoadBalancer): void {

    this.loadBalancers.forEach(loadBalancer => {
      loadBalancer.isSelected = false;
    });

    this.selectedLoadBalancer = item;
    item.isSelected = true;
    this.isValid = true;
  }

  private ok(): void {
    this.dialogController.ok(this.mapNodeProperties());
  }

  private mapNodeProperties(): NodeModel {
    let updatedConnections: Array<ConnectorModel> = [];
    const ratio = this.selectedLoadBalancer.defaultRatios[0];
    if(ratio) {
      ratio.outputs.forEach(output => {
        updatedConnections.push(new ConnectorModel({
          name: output.name,
          source: {connectorIndex: updatedConnections.length, nodeID: this.nodeData.id}
        }));
        updatedConnections = this.mapExistingConnections(updatedConnections, this.existingConnections);
      });
    }

    this.nodeData.properties.loadBalancerId = this.selectedLoadBalancer.id;
    this.nodeData.properties.isDefined = true;
    this.nodeData.properties.name = this.selectedLoadBalancer.name;
    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    return new NodeModel(this.nodeData);
  }
  
  private mapExistingConnections(updatedConnections: ConnectorModel[], existingConnections: ConnectorModel[]): ConnectorModel[] {

    let connectorIndex: number = 0;

    updatedConnections.forEach(connection => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest = existingConnections[connectorIndex].dest;
      }
      connectorIndex++
    });

    return updatedConnections;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public doNothing(): void {}
}

interface ZILoadBalancer {
  id?: string,
  organisationId?: string,
  name: string,
  startDate?: string,
  endDate?: string,
  ratios?: ZRatio[],
  temporaryRatios?: ZRatio[],
  defaultRatios?: ZRatio[],
  isSelected?: boolean
}
