/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZDialogWizard');
/*
 */
@customElement('z-dialog-wizard')
export class ZDialogWizard {


}