import { autoinject } from "aurelia-dependency-injection";
import { HttpClient } from "aurelia-http-client";

@autoinject()
export class RoutingGroupsService {

  constructor(
    private httpClient: HttpClient
  ) {}

  public createRoutingGroup(name: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/organisation/interactions/waitingroom/routinggroups')
      .asPost()
      .withContent({ name })
      .send();
  }

  public getRoutingGroups(): Promise<any> {
    return this.httpClient
      .createRequest('v1/organisation/interactions/waitingroom/routinggroups')
      .asGet()
      .send();
  }

  public getRoutingGroup(routingGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/waitingroom/routinggroups/${routingGroupId}`)
      .asGet()
      .send();
  }

  public deleteRoutingGroup(routingGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/organisation/interactions/waitingroom/routinggroups')
      .asDelete()
      .withContent({ routingGroupId })
      .send();
  }

  public updateRoutingGroup(
    routingGroupId: string,
    name: string,
    taskTemplates: { taskTemplateId: string, name: string }[],
    teams: { teamId: string, name: string }[],
    members: { memberId: string, name: string }[]
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/waitingroom/routinggroups/${routingGroupId}`)
      .asPut()
      .withContent({
        name,
        taskTemplates,
        teams,
        members
      })
      .send();
  }

  public getRoutingGroupWorkTypes(routingGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/organisation/task-templates/available-task-templates')
      .asGet()
      .withParams({ routingGroupId })
      .send();
  }

  public getRoutingGroupTeams(routingGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/organisation/me/teams/search-available-teams')
      .asGet()
      .withParams({ routingGroupId })
      .send();
  }

  public getRoutingGroupMembers(pageNumber: number, pageSize: number, routingGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/organisation/members/search-available-members')
      .asGet()
      .withParams({ pageNumber, pageSize, routingGroupId })
      .send();
  }
}
