import {BaseModel} from 'zailab.abstract';

export class RoutingStatusActivityModel extends BaseModel {

  public memberId: string = null;
  public wrapUp: boolean = null;
  public notResponding: boolean = null;
  public wrapUpInteractions: any = {};

  constructor(routingStatus: any) {
    super();
    this.mapProperties(routingStatus);
  }
}
