import {LogManager, autoinject, customElement} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {SessionStore} from 'zailab.common';

const logger = LogManager.getLogger('ZBreadcrumbs');

@autoinject
@customElement('z-breadcrumbs')
export class ZBreadcrumbs {

  private breadcrumbs: Array<IBreadcrumb> = [];
  private title: string;

  constructor(private router: Router, private sessionStore: SessionStore) {}

  public bind(): void {

    let params = this.router.currentInstruction.queryParams;

    if(Object.keys(this.router.currentInstruction.config.settings).length > 0) {
      for(let key in this.router.currentInstruction.config.settings.data) {

        let arr1 = Object.keys(params);
        let arr2 = this.router.currentInstruction.config.settings.data[key];

        if(arr1.length === arr2.length) {

          let arraysMatch = () => {
            arr1: for(let value of arr1) {
              arr2: for(let val of arr2) {
                if(value === val) {
                  continue arr1;
                }
              }
              return false;
            }
            return true;
          };

          if(arraysMatch()) {
            this.title = key;
          }
        }
      }
    }

    this.generateBreadcrumbs(this.router);
  }

  private generateBreadcrumbs(router: any): void {
    let instruction = this.getInstruction(router);
    this.title = null;
    if(instruction.name !== 'HUD') {
      let matchedInstruction = this.breadcrumbs.filter(item => {
        return item.name === instruction.name;
      });
      //@ts-ignore
      if(matchedInstruction.length === 0) {
        this.breadcrumbs.push(instruction);
      }
      this.generateBreadcrumbs(router.parent);
    } else {
      this.breadcrumbs.push(this.determineRoleBasedInstruction(router));
    }
    this.breadcrumbs = this.breadcrumbs.reverse();
  }

  private determineRoleBasedInstruction(router: Router): IBreadcrumb {
    let userRoles = this.sessionStore.get.user.userAccessRoles;
    return {name: userRoles[1].role + ' Dashboard', router, active: this.breadcrumbs.length === 0};
  }

  private getInstruction(router: any): IBreadcrumb {
    return {name: this.title || router.currentInstruction.config.title, router, active: this.breadcrumbs.length === 0};
  }

  private navigate(breadcrumb: IBreadcrumb): void {
    if(breadcrumb.active) {
      return;
    }
    const route = this.determineRoute(breadcrumb);
    breadcrumb.router.navigate(route);
  }

  private determineRoute(breadcrumb: IBreadcrumb): string {
    let name = breadcrumb.router.currentInstruction.config.name;
    let params = '';

    for(let key in breadcrumb.router.currentInstruction.queryParams) {
      params += `&${key}=${breadcrumb.router.currentInstruction.queryParams[key]}`;
    }
    name = name === 'hud' ? 'hud/dashboard' : name + params.replace('&', '?');
    return name;
  }
}

interface IBreadcrumb {
  name: string;
  active: boolean;
  router?: Router;
}