import {LogManager, customElement, autoinject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import { OplogService, SessionStore, WebSocket } from 'zailab.common';

const logger = LogManager.getLogger('ZWebsocketConnectionState');

@customElement('z-websocket-connection-state')
@autoinject()
export class ZWebsocketConnectionState {

  connection = null;
  interval = null;

  constructor(
    private eventAggregator: EventAggregator,
    private oplogService: OplogService,
    private webSocket: WebSocket,
    private sessionStore: SessionStore
  ) {
    this._websocketConnectionState();
  }

  public get websocketState(): boolean {
    return this.connection && this.connection.isConnected;
  }

  private _websocketConnectionState(): void {

    this.eventAggregator.subscribe('websocket.connection.timeout', () => {
      this.connection = {
        message: 'Connection to server lost.',
        status: 'Reconnecting'
      };

      if(!this.interval) {
        this.interval = setInterval(() => {

          if(!this.connection) {

            this.clearInterval();
            return;
          }

          if (this.connection.status.indexOf('...') !== -1) {
            this.connection.status = this.connection.status.replace('...', '');
          } else {
            this.connection.status += '.';
          }
        }, 500);
      }
    });

    this.eventAggregator.subscribe('websocket.connection.reconnect', () => {
      this.connection = {
        message: 'Connection to server lost.',
        status: 'Click to reconnect.',
        userAction: true
      };
      this.clearInterval();
    });

    this.eventAggregator.subscribe('websocket.connection.connected', () => {

      if(this.connection && !this.connection.isConnected) {
        this.connection = {
          message: 'Connected to server.',
          status: ' ',
          isConnected: 'connected'
        };
        this.clearInterval();
        this.oplogService.resubscribeAll();

        setTimeout(() => {
          this.connection = null;
        }, 4000);
      }
    });
  }

  private clearInterval(): void {

    clearInterval(this.interval);
    setTimeout(() => {
      this.interval = null;
    }, 100);
  }

  public reconnect(): void {
    this.connection = {
      message: 'Connection to server lost.',
      status: 'Reconnecting'
    };
    this.webSocket
      .resetWebsocket(this.sessionStore.get.user.userId)
      .then(() => this.oplogService.resubscribeAll());
  }
}
