import Quill from 'quill';
import { QuillImageUpload } from './quill-plugin-image-upload/quill-image-upload';
import { inject, containerless, LogManager } from 'aurelia-framework';
import { bindingMode } from 'aurelia-binding';

import { bindable } from 'aurelia-templating';

const logger = LogManager.getLogger('Z-Quill-Editor');

/**
 * shamelessly borrowed verbatim from https://www.npmjs.com/package/aurelia-quill-plugin
 *
 * 1. We needed visibility on the delta object.
 * 2. Replaced Aurelia "ref" with injected Element
 * 3. Note: this works for Quill Editors instantiated with toolbar option
 *    3.1 TODO: Does not work for CSS selector configured Toolbars (see https://quilljs.com/docs/modules/toolbar/)
 *        I have not been able to properly destroy the quill instance (and related DOM elements [becomes issue when you change detach/retach])
 */
@inject(Element)
export class ZQuillEditor {
  @bindable() options; // per instance options
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value;

  // this allows delta to be observed, while retaining 2 way html value binding
  @bindable() onValueChanged;
  selectionUpdated;

  constructor(element) {
    this.element = element;
  }

  bind() {
    this.options = Object.assign({}, this.options);
  }

  attached() {
    this.editor = new Quill(this.element, this.options);
    // listen for changes and update the value
    this.editor.on('text-change', this.onTextChanged);
    this.editor.on('selection-change', (range, oldRange, source) => {
      if (source === 'user') {
        return;
      }
      if (!this.selectionUpdated && oldRange) {
        this.selectionUpdated = true;
        this.editor.setSelection(oldRange);

        setTimeout(() => this.selectionUpdated = false, 5);
      }
    });

    if (this.value) {
      this.editor.root.innerHTML = this.value;
    }
  }

  onTextChanged = (delta, oldDelta) => {
    this.value = this.editor.root.innerHTML;
    if (this.onValueChanged && typeof this.onValueChanged === 'function') {
      this.onValueChanged(ValueChangedEvent.from(this));
    }
    let currentSelection = this.editor.getSelection();
    if (delta !== oldDelta && delta.ops.length > 0 && currentSelection) {
      this.editor.setSelection(
        currentSelection.index + 1,
        currentSelection.length
      );
    }
  };

  valueChanged(newValue, oldValue) {
    if (newValue !== oldValue && this.editor.root.innerHTML !== newValue) {
      this.editor.root.innerHTML = this.value;
    }
  }

  detached() {
    // clean up
    this.editor.off('text-change', this.onTextChanged);
    this.cleanModules();

    this.editor = null;
    delete this.editor;
  }

  cleanModules() {
    let toolbar = this.element.parentNode.querySelector('.ql-toolbar');
    if (toolbar) {
      toolbar.remove();
    }

    this.editor.options.modules.toolbar = null;
    delete this.editor.options.modules.toolbar;

    this.editor.options.modules.imageUpload = null;
    delete this.editor.options.modules.imageUpload;

    this.editor.theme.modules.toolbar = null;
    delete this.editor.theme.modules.toolbar;
  }
}

export class ValueChangedEvent {
  value;
  delta;
  text;

  constructor(value, delta, text) {
    this.value = value;
    this.delta = delta;
    this.text = text;
  }

  static from(zQuillEditor) {
    return new ValueChangedEvent(
      zQuillEditor.value,
      zQuillEditor.editor.getContents(),
      zQuillEditor.editor.root.textContent
    );
  }
}
