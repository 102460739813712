import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {OplogService, SessionStore, WebSocket} from 'zailab.common';

import {ContactModel} from './contact-model';
import {CustomFieldModel} from "./custom-field-model";

const logger = LogManager.getLogger('ContactService');

@autoinject()
export class ContactService {


  constructor(
    private httpClient: HttpClient,
    private oplogService: OplogService,
    private sessionStore: SessionStore,
    private webSocket: WebSocket
  ) {}

  /**
   *
   * Subscriptions
   *
   */

  public getContactOplog(contactId: string): any {
    return this.oplogService.subscribeOn('_id', contactId).in('contact-projector.contactView');
  }

  public getUnlinkedInteractionsOplog(contactId: string): any {
    return this.oplogService.subscribeOn('contactId', contactId).in('contact-projector.unlinkedInteractionsView');
  }

  public subscribeToConversationChanges(contactId: string): any {
    return this.oplogService.subscribeOn('contactIds', contactId).in('conversation-projector.conversationSearchView');
  }

  public unsubscribeFromContactChanges(contactId: string): void {
    this.oplogService.unsubscribeFromTable('contact-projector.contactView', 'unsubscribe', '_id', contactId);
  }

  /**
   *
   * Get Requests
   *
   */

  public searchContacts(searchText: string, region: string, number: string, email: string): Promise<any> {
    let noContentStatusCode: number = 204;
    return this.httpClient
      .createRequest('v1/organisation/contacts')
      .asGet()
      .withParams({ region, searchText, number, email, size: 10, page: 0 })
      .send()
      .catch(error => {});
  }

  public retrieveContact(contactId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/contacts/${contactId}`)
      .asGet()
      .send()
      .then(response => {
        return response;
      })
      .catch(error => {
        logger.warn('Contact not found', error);
        return null;
      });
  }

  public retrieveCustomFields(): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/contact/custom-fields`)
      .asGet()
      .send()
      .then(response => {
        if (response && response.customFields) {
          return this.modelCustomFields(response.customFields);
        }
      })
      .catch(error => {
        logger.warn('custom fields not found', error);
        return null;
      });
  }


  public modelCustomFields(customFields: CustomFieldModel[]): CustomFieldModel[] {
    let list = [];
    customFields.forEach(customField => {
      list.push(new CustomFieldModel(customField));
    });
    return list;
  }

  /**
   *
   * Post And Put
   *
   */

  public linkInteractionToContact(contactId: string, interactionId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/${interactionId}/link`)
      .asPut()
      .withContent({contactId})
      .send()
      .catch(error => {
        logger.warn('Unable to link interaction, error: ', error);
      });
  }

  public createContact(contactPayload: ContactModel): void {
    this.httpClient
      .createRequest('v1/organisation/contacts')
      .asPost()
      .withContent(contactPayload)
      .send()
      .catch(() => {});
  }

  public updateContact(contactPayload: ContactModel): void {
    this.httpClient
      .createRequest(`v1/organisation/contacts/${contactPayload.contactId}`)
      .asPut()
      .withContent(contactPayload)
      .send()
      .catch(() => {});
  }
  
  public fetchFromUrl(url: string, prop?: string, requestMethod?: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {

      let xhr = new XMLHttpRequest();
      xhr.open(requestMethod || 'GET', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.sessionStore.get.user.token);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve({});
          } else if (xhr.status === 200) {
            try {
              const data: any = JSON.parse(xhr.response);
              resolve(prop ? data[prop] : data);
            } catch(e: any) {
              logger.warn('error, failed to parse additionaldata from url due to cause', e);
            }
          }
        }
      };
      xhr.send(JSON.stringify(data || {}));
    });
  }
  
  public triggerRequest(url: string, method: string): Promise<any> {
    return new Promise((resolve) => {

      let xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.sessionStore.get.user.token);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve({});
          } else if (xhr.status === 200) {
            resolve({});
          }
        }
      };
      xhr.send();
    });
  }

  public notifyTypingStart(sessionId: string): void {
    this.httpClient
      .createRequest(`/v1/organisation/interactions/${sessionId}/start-typing`)
      .asPut()
      .withContent({})
      .send()
      .catch(() => {});
  }

  public notifyTypingStop(sessionId: string): void {
    this.httpClient
      .createRequest(`/v1/organisation/interactions/${sessionId}/stop-typing`)
      .asPut()
      .withContent({})
      .send()
      .catch(() => {});
  }

  public addFocusToContact(sessionId: string): void {
    this.httpClient
      .createRequest(`v1/organisation/interactions/${sessionId}/focus-on`)
      .asPut()
      .withContent({})
      .send()
      .catch(() => {});
  }

  public removeFocusFromContact(sessionId: string): void {
    this.httpClient
      .createRequest(`v1/organisation/interactions/${sessionId}/focus-off`)
      .asPut()
      .withContent({})
      .send()
      .catch(() => {});
  }

  public getSMSFromNumbers(): Promise<any> {
    return this.httpClient
      .createRequest('v1/finance/telephonenumbers/numbers-view')
      .asGet()
      .withParams({
        type: 'SMS',
        assigned: true
      })
      .send();
  }

  public subscribeToContactAddedToConversationEvent(callback: (data: any) => void): void {
    this.webSocket.subscribe({
      name: 'com.zailab.organisation.conversation.api.events.ContactAddedToConversationEvent',
      callback
    });
  }

  public subscribeToInteractionLinkedToConversationEvent(callback: (data: any) => void): void {
    this.webSocket.subscribe({
      name: 'com.zailab.organisation.conversation.api.events.InteractionLinkedToConversationEvent',
      callback
    });
  }
}
