import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, LogManager } from "aurelia-framework";
import { VersionService } from "../../_common/services/version.service";

const logger = LogManager.getLogger('DiagnosticsState');

@autoinject()
export class DiagnosticsState {

  public clientExchangeState: DefaultState = new DefaultState();
  public agentEndpointState: DefaultState = new DefaultState();
  public httpState: Map<string, DefaultHttpState> = new Map();

  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly versionService: VersionService
  ) {
    this.init();
  }

  private init(): void {
    this.eventAggregator.subscribe('websocket.connection.timeout', (data: any) => this.handleConnectionTimeout(data));
    this.eventAggregator.subscribe('websocket.connection.reconnect', (data: any) => this.handleConnectionReconnect(data));
    this.eventAggregator.subscribe('websocket.connection.connected', (data: any) => this.handleConnectionConnected(data));
  }

  private handleConnectionTimeout(data: any): void {
    if (data.id === 'CLIENT_EXCHANGE') {
      this.clientExchangeState.disconnected = true;
      this.clientExchangeState.error = data.env ? data.env.reason : 'n/a';
    }
    if (data.id === 'AGENT_ENDPOINT') {
      this.agentEndpointState.disconnected = true;
      this.agentEndpointState.error = data.env ? data.env.reason : 'n/a';
    }
  }

  private handleConnectionReconnect(data: any): void {
    if (data.id === 'CLIENT_EXCHANGE') {
      this.clientExchangeState.disconnected = true;
      this.clientExchangeState.error = data.env ? data.env.reason : 'n/a';
    }
    if (data.id === 'AGENT_ENDPOINT') {
      this.agentEndpointState.disconnected = true;
      this.agentEndpointState.error = data.env ? data.env.reason : 'n/a';
    }
  }

  private handleConnectionConnected(data: any): void {
    if (data.id === 'CLIENT_EXCHANGE') {
      this.clientExchangeState.enabled = true;
      this.clientExchangeState.disconnected = false;
      this.clientExchangeState.error = '';
    }
    if (data.id === 'AGENT_ENDPOINT') {
      this.agentEndpointState.enabled = true;
      this.agentEndpointState.disconnected = false;
      this.agentEndpointState.error = '';
    }
  }

  private getHttpState(key: string): DefaultHttpState {
    let state = this.httpState.get(key);
    if (!state) {
      state = new DefaultHttpState(key);
      this.httpState.set(key, state);
    }
    return state;
  }

  public cleanHttpState(): void {
    const pastLimit = Date.now() - (5 * 60 * 1000);
    const lastError = this.lastFailedHttp;
    this.httpState = new Map(
      Array.from(this.httpState).filter(([k, v]) => v.requestTimestamp >= pastLimit)
    );
    this.httpState.set(lastError.url, lastError);
  }

  public get version(): string {
    return `v${this.versionService.currentVersion}`;
  }

  public get requestSuccess(): string {
    if (this.httpState.size === 0) {
      return '';
    }
    const all = Array.from(this.httpState);
    const successes = all
      .filter(([k, v]) => v.duration > 0);
    return `${successes.length}/${all.length} ${Math.round(successes.length / all.length * 100)}%`;
  }

  public get averageResponseTime(): string {
    if (this.httpState.size === 0) {
      return '';
    }
    const successes = Array.from(this.httpState)
      .filter(([k, v]) => v.duration > 0);
    let total = 0;
    for (const request of successes) {
      total += request[1].duration;
    }
    const time = successes.length > 0 ? Math.round(total / successes.length) : 0;
    return `${time} ms`;
  }

  public get lastFailedHttp(): any {
    const errors = Array.from(this.httpState)
      .filter(([k, v]) => v.requestError || v.responseError)
      .sort((v1, v2) => {
        if (v1[1].requestTimestamp < v2[1].requestTimestamp) {
          return 1;
        }
        if (v1[1].requestTimestamp > v2[1].requestTimestamp) {
          return -1;
        }
        return 0;
      });
    return errors.length && errors[0][1];
  }

  public logHttpRequest(data: any): void {
    try {
      this.cleanHttpState();
      this.getHttpState(`${data.method} ${data.url}`)
        .logRequest(data.content ? data.content.substring ? data.content : JSON.stringify(data.content) : '');
    } catch (e) {
      this.warn('logHttpRequest :: failed :: data=', data, ' e=', e);
    }
  }

  public logHttpRequestError(data: any): void {
    try {
      this.getHttpState(`${data.requestMessage.method} ${data.requestMessage.url}`)
        .logRequestError(`${data.statusCode}: ${data.response ? data.response : 'Reason n/a'}`);
    } catch (e) {
      this.warn('logHttpRequestError :: failed :: data=', data, ' e=', e);
    }
  }

  public logHttpResponse(data: any): void {
    try {
      if (!data.requestMessage) {
        return;
      }
      this.getHttpState(`${data.requestMessage.method} ${data.requestMessage.url}`)
        .logResponse(data.response ? data.response : '');
    } catch (e) {
      this.warn('logHttpResponse :: failed :: data=', data, ' e=', e);
    }
  }

  public logHttpResponseError(data: any): void {
    try {
      this.getHttpState(`${data.requestMessage.method} ${data.requestMessage.url}`)
        .logResponseError(`${data.statusCode}: ${data.response && data.response instanceof String ? data.response : 'Reason n/a'}`);
    } catch (e) {
      this.warn('logHttpResponseError :: failed :: data=', data, ' e=', e);
    }
  }

  public logHttpRequestRetry(data: any): void {
    try {
      this.getHttpState(`${data.method} ${data.url}`)
        .logRetry();
    } catch (e) {
      this.warn('logHttpRequestRetry :: failed :: data=', data, ' e=', e);
    }
  }

  private warn(...args: any[]): void {
    logger.warn(...args);
  }
}

class DefaultState {

  public enabled: boolean = false;
  public disconnected: boolean = false;
  public error: string = '';
}

class DefaultHttpState {

  public duration: number = 0;
  public requestTimestamp: number = 0;
  public requestPayload: string = '';
  public responseTimestamp: number = 0;
  public responsePayload: string = '';
  public retryTimestamp: number = 0;
  public requestError: string = '';
  public responseError: string = '';

  constructor(
    public readonly url: string
  ) { }

  public logRequest(payload: string): void {
    this.requestTimestamp = Date.now();
    this.requestPayload = payload;
    this.responseTimestamp = 0;
    this.responsePayload = '';
    this.retryTimestamp = 0;
    this.requestError = '';
    this.responseError = '';
  }

  public logResponse(payload: string): void {
    this.responseTimestamp = Date.now();
    this.responsePayload = payload;
    this.duration = this.responseTimestamp - this.requestTimestamp;
  }

  public logRetry(): void {
    this.retryTimestamp = Date.now();
  }

  public logRequestError(error: string): void {
    this.requestError = error;
  }

  public logResponseError(error: string): void {
    this.responseError = error;
  }
}