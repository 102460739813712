import {TelephoneNumberModel} from '../components/atoms/inputs/phonenumber/telephone-number.model';
import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('OrderPhoneNumberValueConverter');

export class OrderPhoneNumbersValueConverter {
  public toView(phoneNumbers: TelephoneNumberModel[]): any {

    if (!phoneNumbers) {
      return []
    }

    let orderedList: TelephoneNumberModel[] = [];

    orderedList = orderedList.concat(phoneNumbers.filter((telephoneNumber) => telephoneNumber.type === 'WORK'));

    orderedList = orderedList.concat(phoneNumbers.filter((telephoneNumber) => telephoneNumber.type === 'HOME'));

    orderedList = orderedList.concat(phoneNumbers.filter((telephoneNumber) => telephoneNumber.type === 'MOBILE'));

    return orderedList;
  }
}
