import {
  LogManager,
  autoinject,
  computedFrom,
  PLATFORM,
} from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';

import { InteractionFlow } from './interaction-flow';
import { NodeModel } from '../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../components/organisms/connector/models/connector-model';
import { DialogCloseResult } from '~aurelia-dialog/dist/commonjs/dialog-result';
import { DisplayMessageService, WebSocket } from 'zailab.common';
import { SessionStore } from '../../../../_common/stores/session-store';
import { OrganisationSessionModel } from '../../../../_common/stores/sessionmodels/organisation-session-model';
import { ExternalReferenceDialog } from './dialogs/external-reference/external-reference-dialog';
import { InteractionFlowModel } from './models/interaction-flow-model';
import { ExportDialog } from './dialogs/export-dialog/export-dialog';

import * as htmlToImage from 'html-to-image';

const logger = LogManager.getLogger('ZInteractionDesigner');
const INTERACTION_FLOW_PUBLISHED_EVENT =
  'com.zailab.interaction.interactionflow.api.events.InteractionFlowDefinitionPublishedEvent';

@autoinject
export class InteractionDesigner {
  private router: Router;
  private dialogService: DialogService;
  private eventAggregator: EventAggregator;
  private subscriptions: Array<Subscription> = [];
  private interactionFlow: InteractionFlow;
  private displayMessageService: DisplayMessageService;
  private webSocket: WebSocket;
  private flowId: string = '';
  private version: number;
  private originOrganisationId: string;
  private suppressWarning: boolean;
  private iid: number;

  private keyDownHandler: (evt: KeyboardEvent) => void = (evt) =>
    this.handleKeyPress(evt);

  constructor(
    router: Router,
    eventAggregator: EventAggregator,
    dialogService: DialogService,
    interactionFlow: InteractionFlow,
    displayMessageService: DisplayMessageService,
    webSocket: WebSocket,
    private sessionStore: SessionStore
  ) {
    this.router = router;
    this.dialogService = dialogService;
    this.eventAggregator = eventAggregator;
    this.interactionFlow = interactionFlow;
    this.displayMessageService = displayMessageService;
    this.webSocket = webSocket;
  }

  public activate(params: {
    interactionFlowId: string;
    version: number;
  }): void {
    this.flowId = params.interactionFlowId;
    this.version = params.version;
    this.interactionFlow.version = params.version;
    this.subscribeToEvents();
  }

  public attached(): void {
    if (this.flowId) {
      this.retrieveInteractionFlow();
      this.iid = setInterval(() => {
        let lastAutoSaveTimestamp =
          this.interactionFlow.lastAutoSaveTimestamp * 1;
        this.interactionFlow.lastAutoSaveTimestamp = 0;
        this.interactionFlow.lastAutoSaveTimestamp = lastAutoSaveTimestamp;
      }, 10000);
    }
  }

  private retrieveInteractionFlow(): void {
    this.interactionFlow.retrieveInteractionFlow(this.flowId, this.version);
  }

  private subscribeToEvents(): void {
    const keyDownHandler = this.keyDownHandler;
    window.addEventListener('keydown', keyDownHandler, false);

    this.subscriptions.push(
      this.eventAggregator.subscribe('node.add', (data) =>
        this.handleAddNode(data)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('node.edit', (node) =>
        this.handleEditNode(node)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('node.view', (node) =>
        this.handleEditNode({ ...node, version: this.version })
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('node.extref', (node) =>
        this.handleExtRefNode(node)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('node.delete', (node) =>
        this.handleDeleteNode(node)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('node.connector.link', (connectorData) =>
        this.handleLinkConnector(connectorData)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('grid.cell.select', (data) =>
        this.handleSelectGridCell(data)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        'interaction.flow.validation.errors',
        (errors) => this.handleValidationErrors(errors)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe('ValidationErrorsReceived', (err) =>
        this.handleValidationErrors(err)
      )
    );
  }

  private handleKeyPress(evt: KeyboardEvent): void {
    if (evt.key === 'Delete') {
      this.interactionFlow.keyPress_delete();
    }
  }

  private handleAddNode(data: {
    nodeData: NodeModel;
    connectorIndex: string;
    customExtensions: string[];
  }): void {
    let flowConfig: object = this.interactionFlow.interactionFlowConfig;
    let parentNode: NodeModel = data.nodeData;
    let parentConnectorIndex: number = parseInt(data.connectorIndex);
    let extensions: string[] =
      data.customExtensions || parentNode.config.defaultExtensions;
    let dialogTemplate: string = PLATFORM.moduleName(
      'features/interaction/interactionFlow/interaction-designer/dialogs/add-node/add-node-dialog'
    );

    let createIdMapWithInputConnections = (): Object => {
      let _map = {};
      this.interactionFlow.definition.nodes.forEach((node) => {
        node.connections.forEach((connection) => {
          if (!connection.dest) {
            return;
          }
          if (_map[connection.dest.nodeID]) {
            _map[connection.dest.nodeID]++;
          } else {
            _map[connection.dest.nodeID] = 1;
          }
        });
      });
      return _map;
    };

    let map = createIdMapWithInputConnections();

    let model = {
      flowConfig,
      extensions,
      unconnectedNodes: this.interactionFlow.definition.nodes.filter(
        (node) => node.name !== 'Start'
      ),
    };

    this.dialogService
      .open({ viewModel: dialogTemplate, model: model })
      .whenClosed((dialog: DialogCloseResult) => {
        if (dialog.wasCancelled) {
          return;
        }
        const nodeId = dialog.output.id;
        if (nodeId) {
          this.interactionFlow.addConnection(parentNode, 0, nodeId);
        } else {
          const nodeEventId = dialog.output.eventId;
          this.interactionFlow
            .addNode(parentNode, parentConnectorIndex, nodeEventId)
            .then(
              (success) => {
                //node added
              },
              (err) => this.showWarning(err)
            );
        }
      });
  }

  private handleEditNode(node: NodeModel): void {
    let dialogTemplate: string = node.config.dialogTemplate;
    let _definition: ZNodeConfig = {
      flowChannel: this.interactionFlow.interactionFlowChannel,
      flowType: this.interactionFlow.interactionFlowType,
      nodeDefinition: node,
    };
    this.dialogService
      .open({ viewModel: dialogTemplate, model: _definition })
      .whenClosed((dialog: DialogCloseResult) => {
        let editedNode: NodeModel;

        if (dialog.wasCancelled) {
          return;
        }

        editedNode = dialog.output;

        this.interactionFlow.updateNode(editedNode);
      });
  }

  private handleExtRefNode(node: NodeModel): void {
    let _definition: ZNodeConfig = {
      flowChannel: this.interactionFlow.interactionFlowChannel,
      flowType: this.interactionFlow.interactionFlowType,
      nodeDefinition: { ...node, version: this.version },
    };
    this.dialogService
      .open({ viewModel: ExternalReferenceDialog, model: _definition })
      .whenClosed((dialog: DialogCloseResult) => {
        let editedNode: NodeModel;

        if (dialog.wasCancelled) {
          return;
        }

        editedNode = dialog.output;

        this.interactionFlow.updateNode(editedNode);
      });
  }

  private handleDeleteNode(node: NodeModel): void {
    this.interactionFlow.deleteNode(node.id);
  }

  private handleLinkConnector(connectorData: ConnectorModel): void {
    let parentConnectorIndex: number = connectorData.src.connectorIndex;
    let parentNode: NodeModel = connectorData.src.nodeData;
    let destId: string = connectorData.destId;

    this.interactionFlow.addConnection(
      parentNode,
      parentConnectorIndex,
      destId
    );
  }

  private handleSelectGridCell(data: any): void {
    let selectedElementName: string = data.evt.target.localName;

    if (selectedElementName !== 'line') {
      this.interactionFlow.deselectConnectors();
    }
  }

  private handleValidationErrors(err: any): void {
    let errors: Error[] = err.state.allErrors;
    let channel: string = this.interactionFlow.interactionFlowChannel;
    let dialogTemplate: string = PLATFORM.moduleName(
      'features/interaction/interactionFlow/interaction-designer/dialogs/error/error-dialog'
    );

    this.dialogService.open({
      viewModel: dialogTemplate,
      model: { errors, channel },
    });
  }

  public uploadFlow(event: Event): void {
    const file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (evt: any) => {
        try {
          const currentVersion = this.interactionFlow.definition.version;
          let flow = new InteractionFlowModel({
            definition: evt.target.result,
          });
          this.interactionFlow.definition = flow.definition;
          const data = JSON.parse(evt.target.result);

          this.interactionFlow.definition.version = currentVersion;
          this.interactionFlow.additionalData = data.additionalData;
          this.interactionFlow.definition.additionalData = data.additionalData;
          this.originOrganisationId =
            this.interactionFlow.definition.organisationId;
          this.publishFlow(null, true);
        } catch (e) {
          logger.warn('Failed to parse uploaded file ', { error: e, evt });
        }
      };
      reader.onerror = (evt: any) => {
        logger.warn('Failed to upload file ', evt);
      };
    }
  }

  public publishFlow(versionFlow?: boolean, ignoreRedirect?: boolean): void {
    if (this.version) {
      return;
    }

    let _isValid: boolean = this.validateFlow();
    if (!_isValid) {
      return;
    }

    if (!ignoreRedirect) {
      this.webSocket.subscribe({
        name: INTERACTION_FLOW_PUBLISHED_EVENT,
        callback: () => this.back(),
      });
    }
    this.interactionFlow
      .publishFlow(
        this.originOrganisationId || this.organisationId,
        versionFlow
      )
      .then((data: any) => {
        this.router.parent.navigate('interactionflows');
      })
      .catch((e) => {
        try {
          let response = e.response ? JSON.parse(e.response) : e;
          const error = {
            state: {
              allErrors: response.details.map((validation) => {
                return {
                  defaultMessage: validation,
                };
              }),
            },
          };
          this.handleValidationErrors(error);
        } catch (e) {
          logger.warn(' > error handling flow errors >>>> ', e);
        }
      });
  }

  private validateFlow(): boolean {
    let _isValid: boolean = true;
    let _hasUndefinedNode: boolean = this.determineUndefinedNodes(
      this.interactionFlow.definition.nodes
    );
    let _hasDisconnectedNode: boolean = this.determineDisconnectedNodes(
      this.interactionFlow.definition.nodes
    );

    if (_hasUndefinedNode || _hasDisconnectedNode) {
      _isValid = false;
    }

    return _isValid;
  }

  private determineUndefinedNodes(nodes: NodeModel[]): boolean {
    let _undefinedNode: NodeModel = nodes.find(
      (node) => !node.properties.isDefined
    );

    if (_undefinedNode) {
      this.showWarning('Please ensure all nodes are defined.', _undefinedNode);
      return true;
    }

    return false;
  }

  private determineDisconnectedNodes(nodes: NodeModel[]): boolean {
    let _disconnectedNode: NodeModel = nodes.find((node) => {
      let isOutputDisconnected: boolean = !!(
        !node.config.disableOutConnector &&
        node.connections.find((connection) => !connection.isConnected)
      );
      let isIntputDisconnected: boolean =
        !node.config.disableInConnector && node.totalIncomingConnectors <= 0;

      return isOutputDisconnected || isIntputDisconnected;
    });

    if (_disconnectedNode) {
      this.showWarning(
        'Please ensure all nodes are connected correctly.',
        _disconnectedNode
      );
      return true;
    }

    return false;
  }

  private showWarning(message: string, metadata?: any): void {
    if (this.suppressWarning) {
      return;
    }

    this.displayMessageService.showCustomWarning(message);

    logger.warn(`InteractionFlow > warning: ${message} `, metadata);
  }

  public openExportSelection(): void {
    this.dialogService
      .open({ viewModel: ExportDialog, model: {} })
      .whenClosed((data: DialogCloseResult) => {
        if (data.wasCancelled) {
          return;
        }
        const type = data.output;

        if (type === 'CSV') {
          this.downloadFlowCSV();
        } else if (type === 'JPEG') {
          this.downloadSnapshot();
        }
      });
  }

  public downloadFlowCSV(): void {
    const name = this.interactionFlow.interactionFlowName;
    let definition = this.interactionFlow.definition;
    definition.organisationId = this.organisationId;
    definition.additionalData = this.interactionFlow.additionalData;

    const dataStr = JSON.stringify(definition);
    const a = document.createElement('a');
    const file = new Blob([dataStr], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = name + '.json';
    a.click();
  }

  public async downloadSnapshot(): Promise<void> {
    const nodes = this.interactionFlow.definition.nodes;
    let columns = 0;
    let rows = 0;

    nodes.forEach((node) => {
      if (node.x > columns) {
        columns = node.x;
      }
      if (node.y > rows) {
        rows = node.y;
      }
    });

    const snapshotContent: any = await document.querySelector(
      '.o-grid.u-scroll-bar.u-scroll-bar--thick'
    );
    snapshotContent.style.overflow = 'unset';
    snapshotContent.style.maxHeight = 'unset';
    htmlToImage
      .toJpeg(snapshotContent, {
        quality: 1,
        skipFonts: true,
        width: snapshotContent.scrollWidth,
        height: snapshotContent.scrollHeight,
      })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${this.interactionFlow.interactionFlowName}.jpeg`;
        link.href = dataUrl;
        link.click();
        snapshotContent.style.overflow = 'auto';
        snapshotContent.style.maxHeight = '60vh';
      });
  }

  private back(): void {
    this.router.parent.navigate('interactionflows');
    this.webSocket.unSubscribe(INTERACTION_FLOW_PUBLISHED_EVENT);
  }

  public detached(): void {
    this.subscriptions.forEach((subscription) => subscription.dispose());
    this.interactionFlow.destroy();

    const keyDownHandler = this.keyDownHandler;
    window.removeEventListener('keydown', keyDownHandler);

    clearInterval(this.iid);
  }

  public cellDragged(): void {
    setTimeout(() => {
      this.interactionFlow.saveFlow();
    }, 500);
  }

  public deleteDraft(): void {
    this.interactionFlow.deleteSavedFlow().then(() => this.back());
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if (!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }
}
