import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {InteractionDetailModel} from './interaction-detail-model';
/**/
const logger = LogManager.getLogger('ChannelModel');
/**/
export class ChannelModel extends BaseModel {

    public channel: string = null;
    public interactionCount: string = null;
    public interactionState: string = null;
    public isPlaceholder: boolean = false;

    constructor(channel: InteractionDetailModel) {

        super();
        this.mapProperties(channel);
    }

    private mapProperties(channel: InteractionDetailModel) : void {
        this.isPlaceholder = channel.interactionType && channel.interactionCount ? false : true;
        this.channel = channel.interactionType;
        this.interactionCount = channel.interactionCount;
        this.interactionState = channel.latestInteractionState;
    }

}