import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { AcceptInvitationService } from './accept-invitation-service';
/*
 * */
const logger = LogManager.getLogger('AcceptInvitation');
/*
 * */
@inject(AcceptInvitationService, Router, EventAggregator)
export class AcceptInvitation {

  invitationId;
  token;
  message = 'Accepting invitation, please wait...';

  constructor(acceptInvitationService, router, eventAggregator) {
    this.acceptInvitationService = acceptInvitationService;
    this.router = router;
    this.eventAggregator = eventAggregator;
    this._registerEvents();
  }

  activate(params) {
    this._log('activate :: params=', params);
    this.invitationId = params.invitationId;
    this.token = params.token;
    this._retrieveInvitationStatus();
  }

  deactivate() {
    if (this.validationErrorsReceivedSub)
      this.validationErrorsReceivedSub.dispose();
  }

  _registerEvents() {
    this._log('_registerEvents');
    this.validationErrorsReceivedSub = this.eventAggregator.subscribe('ValidationErrorsReceived',
      (error) => this._navigateToInvitationErrorRoute(error));
  }

  _retrieveInvitationStatus() {
    this._log('_retrieveInvitationStatus');
    this.acceptInvitationService.getInvitationStatus(this.invitationId)
      .then((response) => this._handleInvitationStatusResponse(response.status, response.email))
      .catch((error) => this._navigateToInvitationErrorRoute(error));
  }

  _handleInvitationStatusResponse(status, email) {
    this._log('_handleInvitationStatusResponse :: args=', { status, email });
    this.email = email;
    if (status === 'ACCEPTED')
      return this._handleAcceptedInvitation();
    if (status === 'PENDING')
      return this._handlePendingInvitation();
    this._navigateToInvitationErrorRoute(status);
  }

  _handleAcceptedInvitation() {
    this._log('_handleAcceptedInvitation');
    this.acceptInvitationService.getRegistrationStatus(this.email)
      .then((response) => this._handleRegistrationStatusResponse(response))
      .catch((error) => this._navigateToInvitationErrorRoute(error));
  }

  _handlePendingInvitation() {
    this._log('_handlePendingInvitation');
    this.acceptInvitationService.postInvitationAccept(this.invitationId, this.token)
      .then(() => this._handleAcceptedInvitation(this.email))
      .catch((error) => this._navigateToInvitationErrorRoute(error));
  }

  _handleRegistrationStatusResponse(response) {
    this._log('_handleRegistrationStatusResponse :: response=', response);
    if (!response.status && !response.userRegistrationId)
      this._navigateToCompleteRegistrationRoute(null, this.invitationId);
    else if (response.status === 'COMPLETED')
      this._navigateToLoginRoute();
    else if (response.status === 'PENDING')
      this._handlePendingRegistrationStatus(response.userRegistrationId);
  }

  _handlePendingRegistrationStatus(userRegistrationId) {
    this._log('_handlePendingRegistrationStatus :: userRegistrationId=', userRegistrationId);
    this.acceptInvitationService.getRegistrationCompletable(userRegistrationId)
      .then((response) => this._handleRegistrationCompletable(response.completable, userRegistrationId));
  }

  _handleRegistrationCompletable(completable, userRegistrationId) {
    this._log('_handleRegistrationCompletable :: args=', { completable, userRegistrationId });
    if (completable === true)
      this._navigateToCompleteRegistrationRoute(userRegistrationId);
  }

  _navigateToInvitationErrorRoute(error) {
    this._log('_navigateToInvitationErrorRoute :: error=', error);
    if (error)
      this.router.navigate('invitationerror', error);
  }

  _navigateToLoginRoute() {
    this._log('_navigateToLoginRoute');
    let email = this.email;
    this.router.navigateToRoute('login', { email }, { replace: true });
  }

  _navigateToCompleteRegistrationRoute(userRegistrationId, invitationId) {
    this._log('_navigateToCompleteRegistrationRoute :: args=', { userRegistrationId, invitationId });
    let email = this.email;
    let isMemberInvite = true;
    this.router.navigateToRoute('completeregistration', { email, invitationId, userRegistrationId, isMemberInvite }, { replace: true });
  }

  _log(...args) {
    logger.debug('🔍', ...args);
  }
}
