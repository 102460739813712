import {inject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {EventAggregator} from 'aurelia-event-aggregator';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {EncryptTools, BootstrapFormRenderer} from 'zailab.common';
import {PasswordService} from '../password-service';
import {PASSWORD_ACTIONS} from '../password-actions';
/*
 * */
const logger = LogManager.getLogger('ResetPassword');
/*
 * */
@inject(PasswordService, ValidationControllerFactory, Router, EventAggregator)
export class ResetPassword {

  password = '';
  confirmPassword = '';
  passportId;
  capsError;
  submitted = false;
  displayPasswordValidation;

  constructor(passwordService, validationControllerFactory, router, eventAggregator) {

    this.passwordService = passwordService;
    this.router = router;
    this.eventAggregator = eventAggregator;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(params) {

    this.passportId = params.passportId;
    this.passwordService.enableCapslockOnPasswordDetection();

    ValidationRules
      .customRule('confirmPassword', (value) => value === this.password, 'Does not match the entered password.');

    ValidationRules
      .ensure('password')
      .required().withMessage('Please enter your new password.')
      .matches(/^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])\S{8,50}$/).withMessage('Must be between 8 and 50 characters long, and contain at least one lowercase, one uppercase and one number.')
      .ensure('confirmPassword')
      .satisfiesRule('confirmPassword')
      .on(this);

    this.subscribeToCapslockError();
  }

  deactivate() {
    this.passwordService.disableCapslockOnPasswordDetection();
  }

  subscribeToCapslockError() {

    this.eventAggregator.subscribe(PASSWORD_ACTIONS.CAPS_LOCK_ERROR, (message) => {
      this.capsError = message;
    });
  }

  submit() {

    if (this.submitted) { // this should stop multiple submitions
      return;
    }

    var encryptedPassword = EncryptTools.encrypt(this.password);
    this.submitted = true;

    this.validation.validate()
      .then((validation) => {
        if(!validation.valid) {
          this.submitted = false;
          return;
        }
        this.passwordService.resetPassword(encryptedPassword, this.passportId).then(() => {
          this.router.navigate('login');
          this.submitted = false;
        })
          .catch(() => {
            this.submitted = false;
          });
      })
      .catch(error => {
        this.submitted = false;
      });

    this.eventAggregator.subscribe('reset.password.failed', () => {
      this.submitted = false;
    });
  }

  checkValidationSize() {

    this.displayPasswordValidation = false;

    if(this.validation && this.validation.errors) {

      if(!this.validation.errors.length) {
        return;
      }

      let validationErrors = this.validation.errors;

      for(let error of validationErrors) {

        for (let property in error) {
          if (error.hasOwnProperty(property) && (error[property] === 'password' ) && (error['message'].length > 30)) {
            this.displayPasswordValidation = true;
            return;
          }
        }
      }
      this.displayPasswordValidation = false;
    }
  }
}
