/**/
import {LogManager, customElement, bindable, inject} from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';
import Pikaday from 'pikaday';
import moment from 'moment-timezone';
/**/
const logger = LogManager.getLogger('ZDatePicker');

/**/
@inject(Element)
@customElement('z-date-picker')
export class ZDatePicker {

  @bindable({attribute: 'selected-date'}) selectedDate: moment;
  @bindable({attribute: 'from-date'}) fromDate: moment;
  @bindable({attribute: 'date-format'}) format: string;
  @bindable({attribute: 'month-only'}) monthOnly: boolean;
  @bindable({attribute: 'with-label'}) withLabel: string;
  @bindable({attribute: 'as-toggle'}) asToggle: boolean;
  @bindable placeholder: string;

  private id: string;
  private datePickerField: HTMLElement;
  private pikaday: any;

  constructor(private element: Element) {
    this.id = uuidv4();
    this.selectedDate = moment();
    this.format = 'DD MMM YYYY';
  }

  private attached(): void {
    this.configureDatePicker();
  }

  private configureDatePicker(): void {
    let today = moment().date();
    let allowFrom = moment().subtract(today < 5 ? 4 : 3, 'months');
    allowFrom.set('date', 1); 

    this.datePickerField = document.getElementById('datepicker' + this.id);
    this.pikaday = new Pikaday({
      field: this.datePickerField,
      defaultDate: this.selectedDate || '',
      format: this.format,
      theme: 'dark-theme',
      minDate: allowFrom.toDate(),
      onSelect: (value) => {
        this.selectDate(value);
      },
      onDraw: (value) => {
        this.processCalendarDraw(value);
      }
    });
  }

  private processCalendarDraw(value: any): void {
    if(!this.monthOnly) {
      return;
    }
    let dayTable: HTMLCollectionOf<Element> = document.getElementsByClassName("pika-table");
    if (dayTable) {
      let i: number;
      for (i = 0; i < dayTable.length; i++) {
        dayTable[i].setAttribute("style", "display: none!important;");
      }
    }
    this.selectDate(moment({year: value.calendars[0].year, month: value.calendars[0].month, day: 1}))
  }

  private selectDate(value: Date): void {
    this.selectedDate = moment(value).format(this.format);
    this.element.dispatchEvent(
      new CustomEvent('date-selected', {
        bubbles: true,
        detail: {date: this.selectedDate}
      })
    );
  }

  private detached(): void {
    this.pikaday.destroy();
  }
}
