import { LogManager, customElement, bindable } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZToggle');
/*
 */
@customElement('z-toggle')
export class ZToggle {
  @bindable label: string;
  @bindable selected: boolean;
  @bindable({ attribute: 'enabled-text' }) enabledText: string;
  @bindable({ attribute: 'disabled-text' }) disabledText: string;
}
