import { LogManager, inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
import { OplogService, SESSION_EVENTS } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('OrganisationService');

/*
 */
@inject(HttpClient, OplogService, EventAggregator)
export class UserPersonService {

  constructor(httpClient, oplogService, eventAggregator) {
    this.httpClient = httpClient;
    this.oplog = oplogService;
    this.eventAggregator = eventAggregator;
  }

  retrieveDeviceInfo(memberId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/members/${memberId}/profiles`)
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            logger.error(error);
          }
        ).catch((error) => {
          logger.error(error);
        }
        );
    });
  }

  retrieveUserInfo(personId) {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/user/persons/profile-information/${personId}`)
        .asGet()
        .send()
        .then(
          (response) => {
            // TODO: kornel 2018/10/04 >  I really dont like this, but here we are
            this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, { firstName: response.person.firstName, surname: response.person.surname });
            resolve(response.person);
          }
        ).catch((error) => {
          logger.error(error);
          resolve()
        });
    });
  }

  retrieveEmailSignature(memberId) {
    return this.httpClient
      .createRequest(`v1/organisation/members/${memberId}/email/signature`)
      .asGet()
      .send()
      .then((res) => res.emailSignature);
  }

  changeEmailSignature(memberId, data) {
    return this.httpClient
      .createRequest(`v1/organisation/members/${memberId}/email/signature`)
      .asPut()
      .withContent(data)
      .send();
  }

  changeProfileInformation(message) {
    this.httpClient.createRequest(`v1/user/persons/${message.personId}/information`)
      .asPut()
      .withContent(message)
      .send()
  }

  changeToDarkTheme() {
    this.httpClient.createRequest(`v1/organisation/members/set-dark-theme`)
      .asPut()
      .withContent({})
      .send();
  }

  changeToLightTheme() {
    this.httpClient.createRequest(`v1/organisation/members/set-light-theme`)
      .asPut()
      .withContent({})
      .send();
  }

  changeCallForwarding(memberId, payload) {
    return this.httpClient.createRequest(`v1/organisation/members/${memberId}/callforward`)
      .asPut().withContent(payload).send();
  }

  enablePersonalVoicemail(memberId, voicemailEnabled) {
    this.httpClient.createRequest(`v1/organisation/members/${memberId}/voicemail`)
      .asPut().withContent({ voicemailEnabled }).send().catch((error) => {
        logger.info('Failed to enable/disable personal voicemail > error = ', error);
      });
  }
  
  changePersonalVoicemail(memberId, audioId) {
    this.httpClient.createRequest(`v1/organisation/members/${memberId}/voicemail/message`)
      .asPut().withContent({ audioId }).send().catch((error) => {
        logger.info('Failed to change personal voicemail message > error = ', error);
      });
  }

  initialiseOplogWith(organisationId) {
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('campaign-projector.campaignListView'));
    });
  }

  updateProfilePicture(base64, personId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('media/images/' + personId)
        .asPut()
        .withContent(base64)
        .send()
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject();
        });
    });
  }
}

function _modelCollection(response) {
  let list = [];
  response.forEach(item => {
    list.push(_modelEntity(item));
  });
  return list;
}

function _modelEntity(item) {
  return new CampaignModel(item);
}
