import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('StatsModel');
/**/
export class StatsModel extends BaseModel {

    public teamId: string = null;
    public currentAgentsIds: Array<string> = [];
    public totalCallsMissedByTeamForCurrentDay: string = null;
    public totalCallsTransferredByTeamForCurrentDay: string = null;
    public averageAgentRatingScoreAchievedByTeam: string = null;

    constructor(stats: object) {

        super();
        super.mapProperties(stats);
    }

    public mapProperties(responseFromOplog : StatsModel): void {
        super.mapProperties(responseFromOplog);
    }
}