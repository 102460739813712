import {inject, LogManager, PLATFORM} from 'aurelia-framework';

import {Router} from 'aurelia-router';
import {FeatureFlagService, SessionStore} from 'zailab.common';
import {WorkTypesService} from '../work-types-service';
import {CreateWorkTypeModel} from './create-work-type-command-model';

const logger = LogManager.getLogger('WorktypeCreator');

@inject(Router, SessionStore, WorkTypesService, Element, FeatureFlagService)
export class WorktypeCreator {

  private nodeConfig: ZNodeConfig;
  private embeddedMode: boolean = false;
  private ready:boolean = false;
  private oplog:any;
  private multiServiceBPWorkType: boolean;

  private steps: Array<any> = [{
    name: 'Define Work Type',
    description: 'Please give a name to this type of work and select the environment in which it will be performed.',
    view: PLATFORM.moduleName('features/organisation/conversation/worktypes/create/selectenvironment/select-environment'),
    active: true,
    completed: false,
    data: {}
  }, {
    name: 'Define Properties',
    description: 'Work items and agents are matched based on their properties e.g. Service: Sales, Skill: English.',
    view: PLATFORM.moduleName('features/organisation/conversation/worktypes/create/defineproperties/define-properties'),
    hasAttributes: true,
    completed: false,
    data: {}
  }];

  private lastStep: any = {
    name: 'Set Priority',
    description: 'Target response time and business value determine the serving order of the work items.',
    view: PLATFORM.moduleName('features/organisation/conversation/worktypes/create/setpriority/set-priority'),
    completed: false,
    data: {}
  };

  constructor(
    private router: Router,
    private sessionStore: SessionStore,
    private workTypesService: WorkTypesService,
    private element: Element,
    private featureFlagService: FeatureFlagService
  ) {}

  public activate(_config?: ZNodeConfig): void {
    this.featureFlagService.isEnabled('multiServiceBPWorkType')
    .then((multiServiceBPWorkType) => {
      this.multiServiceBPWorkType = multiServiceBPWorkType;
    });

    this.nodeConfig = _config ? _config : null;
    this.embeddedMode = _config.mode && _config.mode === 'embedded';
    this.steps.push(this.lastStep);
    // Add node config to steps
    if (this.nodeConfig) {
      this.steps.forEach(_step => {
        _step.data.config = this.nodeConfig
      });
    }
  }

  public attached():void {
    this.workTypesService.initialiseListOplog(this.sessionStore.get.organisation.organisationId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => this.worktypeAdded(response));
    });
    this.ready = true;
  }

  public complete(data: any): void {
    let _model: CreateWorkTypeModel = new CreateWorkTypeModel(data.detail);

    let _payload: any = {
      businessValue: data.detail.businessValue,
      channel: _model.channel,
      name: data.detail.name,
      organisationId: this.sessionStore.get.organisation.organisationId,
      roles: _model.roles,
      service: _model.service,
      sites: _model.sites,
      skillGroups: _model.skillGroups,
      slaWaitTimeInSeconds: _model.slaWaitTimeInSeconds,
      targetResponse: data.detail.targetResponse,
      type: data.detail.type
    };

    if (this.multiServiceBPWorkType) {
      _payload.businessPartners = _model.businessPartnerList.filter(bp => bp.isSelected).map(bp => {
        return {
          id: bp.id,
          name: bp.name
        };
      });
      _payload.contactCentres = _model.contactCenterList.filter(cc => cc.isSelected).map(cc => {
        return {
          id: cc.contactCentreId,
          name: cc.name
        };
      });
    } else {
      if (_model.businessPartner) {
        _payload.businessPartner = {
          id: _model.businessPartner.businessPartnerId,
          name: _model.businessPartner.businessPartnerName
        };
      }
      if (_model.contactCenter) {
        _payload.contactCentre = {
          id: _model.contactCenter.contactCentreId,
          name: _model.contactCenter.contactCentreName
        };
      }
    }

    this.workTypesService.createWorkType(_payload);
  }

  private worktypeAdded(_response:any):void {
    this.oplog.unsubscribe();
    if (this.embeddedMode) {
      // If it is embedded, just notify a parent
      this.element.dispatchEvent(new CustomEvent('worktype-created', {
        bubbles: true,
        detail: _response
      }))
    } else {
      this.router.navigate('/hud/worktypes');
    }
  }

  private cancel(): void {
    this.oplog.unsubscribe();
    if (this.embeddedMode) {
      this.element.dispatchEvent(new CustomEvent('worktype-cancelled', {
        bubbles: true
      }))
    } else {
      this.router.navigate('/hud/worktypes');
    }
  }

  private modifySteps(data: any): void {

    let type = data.detail.type;
    if (type === 'CONTACT_CENTRE_FLOW' && this.steps.length === 2) {
      this.steps.push(this.lastStep);
    } else if (type === 'OFFICE_FLOW' && this.steps.length === 3) {
      this.steps.splice(2, 1);
    }
  }
}
