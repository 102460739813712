import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
import { SessionStore, DownloadService } from 'zailab.common';
import { ASSESSMENT_ACTIONS } from './assessment.actions';
import { HttpClient } from 'aurelia-http-client';

const logger = LogManager.getLogger('AssessmentService');

@inject(Dispatcher, SessionStore, DownloadService, HttpClient)
export class AssessmentService {

  constructor(dispatcher, sessionStore, downloadService, httpClient) {
    this.dispatcher = dispatcher;
    this.sessionStore = sessionStore;
    this.downloadService = downloadService;
    this.httpClient = httpClient;
  }

  clearStore() {
    this.dispatcher.dispatch(ASSESSMENT_ACTIONS.CLEAR_STORE);
  }

  retrieveRecording(interactionId, callType) {

    let call = { type: callType };

    let auth = 'Bearer ' + this.sessionStore.get.user.token;
    let uri = `v1/media/recording/${interactionId}`;

    this.retrievingRecording = true;

    this.downloadService.downloadWithAuth('recording', uri, auth).then(
      recordingBlob => {

        let recordingBlobURI = URL.createObjectURL(recordingBlob);

        call.recordingURL = recordingBlobURI;
        this.dispatcher.dispatch(ASSESSMENT_ACTIONS.RETRIEVE_RECORDING, call);
      },
      error => {
        this.dispatcher.dispatch(ASSESSMENT_ACTIONS.RETRIEVE_RECORDING, call);
      });

  }

  retrieveQuestions(formId) {
    this.httpClient.createRequest(`v1/organisation/forms/${formId}/perform-qa`)
      .asGet()
      .send()
      .then(
        (response) => {
          this.dispatcher.dispatch(ASSESSMENT_ACTIONS.RETRIEVE_QUESTIONS, response);
          this.dispatcher.dispatch(ASSESSMENT_ACTIONS.HIDE_BUTTON, false);
        }, (error) => {
          this.dispatcher.dispatch(ASSESSMENT_ACTIONS.RETRIEVE_QUESTIONS, {});
        });
  }

  deactivateView() {

    this.dispatcher.dispatch(ASSESSMENT_ACTIONS.DEACTIVATE_VIEW, {});
  }

  submitAssessment(assessment) {
    assessment.organisationId = this.sessionStore.get.organisation.organisationId;
    assessment.assessorMemberId = this.sessionStore.get.user.memberId;

    this.httpClient.createRequest('v1/organisation/assessments')
      .asPost()
      .withContent(assessment)
      .send()
      .then((res) => {

        let score = res.score;

        toastr.options = {
          'positionClass': 'toast-top-center',
          'progressBar': true,
          'timeOut': "10000"
        };

        setTimeout(() => {
          toastr.info('Result: ' + score.totalAchieved + '/' + score.maxmimum + ` (${score.percentageAchieved}%)`, 'QA successfully performed');
          this.dispatcher.dispatch(ASSESSMENT_ACTIONS.SHOW_SUCCESS);
        }, 500);

      });
  }

}