import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { OplogService, SessionStore } from 'zailab.common';
import {UserSessionModel} from '../../_common/stores/sessionmodels/user-session-model';

const logger = LogManager.getLogger('ProportionalWidget');

@inject(EventAggregator, SessionStore)
export abstract class ProportionalWidget {

  protected widgetConfig: ZIWidgetConfig;
  protected isReady: boolean = false;
  protected aspectRatio: number = 1;
  protected makePercent: number = 100;
  protected actions: Array<string> = ['view'];
  protected eventAggregator: EventAggregator;
  protected sessionStore: SessionStore;
  protected setupView: any;
  protected setupActive: boolean = false;
  protected currentTeamId: string;
  protected settingsLabel: string;
  protected eventSubscription: { dispose: () => void };
  protected oplogService: OplogService;

  constructor(_eventAggregator: EventAggregator, sessionStore: SessionStore) {
    this.eventAggregator = _eventAggregator;
    this.sessionStore = sessionStore;
  }

  protected activate(_config: ZIWidgetConfig): void {
    this.widgetConfig = _config;
    this.currentTeamId = _config.teamId;
  }

  protected attached(): void {
    this.subscribeToEvents();
    this.aspectRatio = (this.widgetConfig.widgetHeightSpan / this.widgetConfig.widgetWidthSpan) * this.makePercent;
    this.eventAggregator.publish('widget.init.finished', { widgetId: this.widgetConfig.widgetId });
    this.loadWidgetSettings().then(_settings => {
      this.handleSavedState(_settings);
      this.getWidgetData();
    }, _rejected => {
      this.createEmptyState();
      this.getWidgetData();
    });
  }

  protected detached(): void {
    this.eventSubscription.dispose();
    this.killOplog();
  }

  protected subscribeToEvents(): void {
    // TODO: move this code to correct location (maybe teamleader dashboard?)
    this.eventSubscription = this.eventAggregator.subscribe('dashboard.select.team', (payload) => this.currentTeamChanged(payload));
  }

  protected showSetup(): void {
    this.setupActive = true;
  }

  protected loadWidgetSettings(): Promise<object> {
    return new Promise((resolve, reject) => {
      try {
        let localStoreResult = localStorage.getItem(this.user.memberId + '-' + this.widgetConfig.widgetId);
        if (localStoreResult !== null) {
          resolve(JSON.parse(localStoreResult))
        } else {
          reject(null)
        }
      } catch (e) {
        reject(null)
      }
    });
  };

  protected saveWidgetSettings(_config: object): boolean {
    try {
      localStorage.setItem(this.user.memberId + '-' + this.widgetConfig.widgetId, JSON.stringify(_config));
      return true
    } catch (e) {
      return false
    }
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  /* Overridden in child classes */

  protected async setupWidget(): Promise<any> {
    // Will override
  }

  protected handleSavedState(_settings: object): void {
    // Will override
  }

  protected createEmptyState(): void {
    // Will override
  }

  protected getWidgetData(): void {
    // Will override
  }

  protected updateWidgetData(_incomingData: object): void {
    // Will override
  }

  protected setup(): void {
    // Will override
  }

  protected currentTeamChanged(_team: ZITeam): void {
    this.currentTeamId = _team.teamId;
    // Will override
  }

  protected killOplog(): void {
    if (this.oplogService) {
      // @ts-ignore
      this.oplogService.unsubscribe();
    }
  }

  protected reconnectOplog(_teamId: string): void {
    this.killOplog();
    // Will override
  }

  protected handleOplogCommunications(_incomingData: any): void {
    // Will override
  }
}
