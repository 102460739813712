import { PLATFORM } from 'aurelia-pal';
import { Router } from 'aurelia-router';
import { autoinject, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('WaitingRoomConfiguration');


@autoinject()
export class WaitingRoomConfiguration {
  constructor(
    private router: Router
  ) {}

  public configureRouter(config, router) {
    let routeMap = [{
      route: '',
      name: 'waiting-room-config',
      moduleId: PLATFORM.moduleName('features/organisation/interactions/waiting-room-configuration/list/waiting-room-configuration'),
      nav: false,
      title: 'Agents'
    }, {
      route: 'routing-groups',
      name: 'routing-groups',
      moduleId: PLATFORM.moduleName('features/organisation/interactions/waiting-room-configuration/routing-groups/routing-groups'),
      nav: false,
      title: 'Agents'
    }];

    config.map(routeMap);
    this.router = router;

  }
}
