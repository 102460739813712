import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Alert } from '../alert-model';
import { AlertService } from '../alert-service';
/*
* */
const logger = LogManager.getLogger('AlertDialog');
/*
 * */
@autoinject
export class AlertDialog {

  public alert: Alert;

  constructor(
    private dialogController: DialogController,
    private alertService: AlertService
  ) { }

  public activate(alert: Alert): void {
    this.alert = alert;
  }

  public close(): void {
    this.dialogController.cancel();
  }

  public resendAlert(): void {
    this.alertService.send(this.alert.alertId)
      .then(() => this.dialogController.ok());
  }

  public activateAlert(): void {
    this.alertService.activate(this.alert.alertId)
      .then(() => this.dialogController.ok());
  }

  public deactivateAlert(): void {
    this.alertService.deactivate(this.alert.alertId)
      .then(() => this.dialogController.ok());
  }
}
