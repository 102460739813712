import { autoinject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { FeatureFlagService } from '../../featureflags/feature-flag-service';
import { ReportsDialog } from './forms/reports-dialog';
import { ReportMenuCategory } from './report-menu-category';
import { ReportMenuConfig } from './report-menu-config';
import { DisabledDialog } from './forms/disabled-dialog';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('Reports|Select');

@autoinject
export class Reports {
  private allReportsDisabled: boolean = false;
  private archivedReportsEnabled: boolean = false;
  private activityReportsDisabled: boolean = false;
  private reportsDisabled: boolean = false;
  private channelActivityReportLabels = [
    'Call Activity',
    'Email Activity',
    'SMS Activity',
    'WebChat Activity',
    'Instant Message Activity',
    'Agent Call Comparison',
    'Agent Email Comparison',
    'Agent SMS Comparison',
    'Agent WebChat Comparison',
    'Agent Instant Message Comparison',
  ];
  private customReportViews = ['Teams', 'Business Partner'];

  private reportsCategories: ReportMenuCategory[];
  private reportMenuConfig: ReportMenuConfig;
  private selectedReportCategory: ReportMenuCategory = null;

  constructor(
    private featureFlagService: FeatureFlagService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  public async activate(params: { qaReports?: boolean }): Promise<void> {

    this.allReportsDisabled = await this.featureFlagService.isEnabled('AllReportsDisabled');
    this.archivedReportsEnabled = await this.featureFlagService.isEnabled('ArchivedReportsEnabled');
    this.activityReportsDisabled = await this.featureFlagService.isEnabled('DisableActivityReports');
    this.reportsDisabled = await this.featureFlagService.isEnabled('DisableReports');
    this.reportMenuConfig = new ReportMenuConfig(this.archivedReportsEnabled);
    this.reportsCategories = this.reportMenuConfig.categories;
    
    if (params.qaReports) {
      this.selectReportCategory(this.reportsCategories.find(cat => cat.heading === 'Quality Assessment'));
    } else {
      this.selectReportCategory(this.reportsCategories[0]);
    }
  }

  public async selectReport(report: ZIReportsDetail): Promise<void> {
    if (this.allReportsDisabled) {
      return;
    }
    if (report.label === 'Teams') {
      this.router.navigate('report/teams');
      return;
    } else if (report.label === 'Business Partner') {
      this.router.navigate('report/business-partner');
      return;
    }
    let reportDisabled = false;
    if (this.reportsDisabled) {
      reportDisabled = await this.featureFlagService.isEnabled(`DisableReport_${report.label.split(' ').join('')}`, true);
    }
    if (reportDisabled || (this.activityReportsDisabled && this.channelActivityReportLabels.includes(report.label))) {
      this.dialogService.open({
        viewModel: DisabledDialog,
        model: report,
        lock: false
      });
    } else {
      this.dialogService.open({
        viewModel: ReportsDialog,
        model: report,
        lock: false
      });
    }
  }

  private selectReportCategory(category: ReportMenuCategory): void {
    this.reportsCategories.forEach(category => {
      category.selected = false;
    });
    category.selected = true;
    this.selectedReportCategory = category;
  }

  private deactivate(): void {
    this.archivedReportsEnabled = false;
    this.activityReportsDisabled = false;
  }
}
