import {LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('TelephoneNumberModel');

export class TelephoneNumberModel extends BaseModel {

  public formattedNumber: string = null;
  public region: string = null;
  public type: string = null;
  public number: string = null;
  public validation: boolean = false;
  public isNumberValid: boolean = false;
  public isDnc: boolean = false;

  constructor(telephoneNumber: string, type: string, region: string, validation?: boolean, isNumberValid?: boolean, isDnc?: boolean) {
    super();
    this.number = telephoneNumber;
    this.formattedNumber = telephoneNumber;
    this.region = region;
    this.type = type;
    this.validation = validation;
    this.isNumberValid = isNumberValid;
    this.isDnc = isDnc;
  }

  get telephoneNumber(): string {
    return this.number;
  }

  @computedFrom('type')
  get telephoneNumberIcon(): string {
    let type = this.type.toLowerCase();
    if (type === 'work') {
      return 'briefcase';
    } else if (type === 'mobile' || type === 'mobile_two') {
      return 'phone';
    } else if (type === 'home') {
      return 'home';
    }
  }

  set telephoneNumber(telephoneNumber: string) {
    this.number = telephoneNumber;
    this.formattedNumber = telephoneNumber;
  }

  public isValid(): boolean {
    if (this.number || this.number !== '') {
      return true;
    }
    return false;
  }

  public isTypeOf(telephoneNumberType: string): boolean {
    return this.type === telephoneNumberType ? true : false;
  }

  public equals(comparingNumber: TelephoneNumberModel): boolean {
    if (comparingNumber.telephoneNumber === this.number && comparingNumber.type === this.type) {
      return true;
    }
    return false;
  }
}
