import { AUDIO_ASSETS } from './audio.assets';
import { SessionStore } from 'zailab.common';
/*
 */
import { inject, LogManager, computedFrom } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('AudioService');
/*
 */
@inject(SessionStore)
export class AudioService {

  muted = localStorage.getItem('muted') === 'true' ? true : false;

  constructor(sessionStore) {
    this.sessionStore = sessionStore;
    this.preloadAudioFiles();
  }

  preloadAudioFiles() {
    if(!this.user) {
      setTimeout(() => {
        this.preloadAudioFiles();
      }, 500);
      return;
    }

    if (this.user.hasAgentRole || this.user.hasTeamLeaderRole) {
      preloadAudio(AUDIO_ASSETS.WHOOSH);
      preloadAudio(AUDIO_ASSETS.RING1);
      preloadAudio(AUDIO_ASSETS.INCOMING_MESSAGE_NOTIFICATION);
      preloadAudio(AUDIO_ASSETS.INCOMING_REMINDER_NOTIFICATION);
      preloadAudio(AUDIO_ASSETS.INCOMING_TASK_NOTIFICATION);
      preloadAudio(AUDIO_ASSETS.USER_NOT_RESPONDING);
      preloadAudio(AUDIO_ASSETS.TOGGLE_SOUND_ON);
      preloadAudio(AUDIO_ASSETS.CALLS_WAITING_INCREMENT);
    }
    preloadAudio(AUDIO_ASSETS.ALARMING_BEEP);
    preloadAudio(AUDIO_ASSETS.INDUSTRIAL_WARNING);
    preloadAudio(AUDIO_ASSETS.SPACESHIP_ALARM);
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  play(asset, options) {
    if (this.muted) {
      return;
    }
    playAudio(asset, options);
  }

  stop() {
    createjs.Sound.stop();
  }

  toggleMute(mute) {
    this.muted = mute;
    localStorage.setItem('muted', this.muted.toString());
    createjs.Sound.muted = this.muted;
  }

  setVolume(volume) {
    createjs.Sound.volume = volume;
  }
}
/*
 */
function preloadAudio(asset) {
  createjs.Sound.registerSound(asset, asset);
}
/*
 */
function playAudio(audioId, options) {
  if(createjs.Sound.loadComplete(audioId)) {
    createjs.Sound.play(audioId, options);
  }
}
