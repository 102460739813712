import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 */
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { CreateStore } from '../../create.store';
import { CreateService } from '../../create.service';
import { MembersService } from '../../../../../../../features/organisation/member/members/members-service';
/*
 */
const logger = LogManager.getLogger('FormName');
/*
 */
import { v4 as uuidv4 } from 'uuid';


@inject(Router, CreateStore, CreateService, ValidationControllerFactory, MembersService, SessionStore)
export class Name {

  isAttached;
  businessPartners = [];
  contactCentres = [];
  selectedBusinessPartner;
  selectedContactCentre;

  constructor(router, createStore, createService, validationControllerFactory, membersService, sessionStore) {

    this.router = router;
    this.createStore = createStore;
    this.createService = createService;
    this.membersService = membersService;
    this.sessionStore = sessionStore;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this._initValidation();
  }

  activate() {
    this.membersService
      .retrieveMember(this.user.memberId)
      .then((member) => {
        this.businessPartners = member.businessPartners;
      })
  }

  attached() {

    this.isAttached = true;

    this.createService.retrievePersonalInfo();
    setTimeout(() => {

      this.setFocus('formName');
    }, 800);
  }

  _initValidation() {


    this.createService.formNameSearch('').then((response) => {
      ValidationRules
        .customRule('uniqueName', (value) => {
          if (response) {
            for (let form of response) {
              if (form && form.name && (form.name.toLowerCase() === value.toLowerCase())) {
                return false;
              }
            }
          }
          return true;
        });

      function _hasSpecialChars(value) {
        return value.includes('.') || value.includes(',');
      }

      ValidationRules
        .customRule('onlyNumbers', (value) => {
          if (!value && value !== 0) {
            return true;
          }
          if (value.includes('-') || _hasSpecialChars(value) && value.length > 1) {
            return true;
          }
          return /^[0-9]+$/.test(value);
        });

      ValidationRules
        .customRule('numberRange', (value) => {
          if (!value && value !== 0) {
            return true;
          }
          if (value <= 0 || value > 100) {
            return false;
          }
          return true;
        });

      ValidationRules
        .customRule('noDecimals', (value) => {
          if (!value && value !== 0) {
            return true;
          }
          if (value > 0 && value <= 100) {
            if (_hasSpecialChars(value)) {
              return false;
            }
          }
          return true;
        });

      ValidationRules
        .ensure('formName')
        .required().withMessage('Please enter a name for this form.')
        .satisfiesRule('uniqueName').withMessage(`Please enter a unique form name.`)
        .matches(/^([\w]+[\W]*)+$/).withMessage('Is not a valid input.')
        .ensure('passRequirement')
        .required().withMessage('Please enter a pass rate for this form.')
        .satisfiesRule('onlyNumbers').withMessage('The pass rate can only contain numbers.')
        .satisfiesRule('numberRange').withMessage('The pass rate must be between 0 and 100.')
        .satisfiesRule('noDecimals').withMessage('The pass rate must be a whole number.')
        .on(this);
    });
  }

  selectBusinessPartner(businessPartner) {
    if (businessPartner.contactCentres) {
      this.selectedContactCentre = businessPartner.contactCentres[0];
    }
    this.contactCentres = businessPartner.contactCentres || [];
  }

  setFocus(field) {

    let firstElement = document.getElementById(field);
    if (firstElement !== null) {
      firstElement.focus();
    } else {

      setTimeout(() => {

        this.setFocus(field);
      }, 100);
    }
  }

  cancel() {

    this.isAttached = false;
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
    this.createService.clearState();
  }

  createForm() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.createStore.formId = uuidv4();
      this.createStore.formName = this.formName;
      this.createStore.passRequirement = this.passRequirement;
      this.createStore.businessPartnerId = this.selectedBusinessPartner ? this.selectedBusinessPartner.id : null;
      this.createStore.contactCentreId = this.selectedContactCentre ? this.selectedContactCentre.id : null;
      
      this.router.navigate('questions');
    })
    .catch((error) => {
      logger.warn('error >', error);
    });
  }
  
  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }
}
