import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { bindable, LogManager, PLATFORM } from "aurelia-framework";
import { BindingSignaler } from 'aurelia-templating-resources';

import { ArrayTools, FeatureFlagService, SessionStore } from 'zailab.common';
import { CCMStatModel } from './../../../../dashboard/teamleader/models/ccm-stat-model';
import { ConfirmDialog } from './../../../../organisation/conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';
import { DataGrid, DataGridEvents, DataRefresher, DataGridSetting } from './../../../../../components/templates/data-grid/data-grid';
import { LiveDashboardModel } from "../models/live-dashboard-model";
import { ContactCenterMonitoringState } from './contact-center-monitoring-state';
import { TeamLeaderService } from '../../../../dashboard/teamleader/team-leader-service';
import { DisplayTeamsModel } from '../../../../organisation/member/teams/teams-model';

import { v4 as uuidv4 } from 'uuid';

// @ts-ignore
import $ from 'jquery';

// @ts-ignore
import moment from 'moment';
import { LiveDashboardService } from '../live-dashboard-service';
import { ColourConfig } from '../overview/colour-config';

const DEFAULT_CONFIG: MinimalViableLiveDashboardConfig[] = [
  { title: 'No Response (#)', settings: [{ value: 0, colour: '' }, { value: 5, colour: 'var(--unsuccessful)' }] },
  
];

const logger = LogManager.getLogger('ContactCenterMonitoring');

interface MinimalViableLiveDashboardConfig {
  title: string;
  settings: {
    value: number,
    colour: string,
  }[];
  visible?: boolean;
}
interface IStateChannels {
  call: boolean;
  callback: boolean;
  chat: boolean;
  email: boolean;
  im: boolean;
  sms: boolean;
  ticket: boolean;
};
interface IStateStorage {
  requestTimer: number,
  contactCenterMonitoring: {
    grid: {
      columnHeaders: Array<string>,
      channels: IStateChannels,
      teams: Array<string>
    },
    requestTimer: number
  }
};

@autoinject
export class ContactCenterMonitoring {

  @bindable public selectedTeam: { teamId: string; teamName: string; };
  @bindable public selectedWorkType: { id: string, name: string, isSelected: boolean };
  @bindable public workTypesList: [{ id: string, name: string }];
  @bindable public teams: { teamId: string, teamName: string, isAdded?: boolean }[];
  
  private contactCenterMonitoringState: ContactCenterMonitoringState = new ContactCenterMonitoringState();
  private mappings: any;
  
  private refreshIntervalHolder: any;
  public cdDataGrid: DataGrid;
  public refresher: DataRefresher = new DataRefresher();
  public groupedSettings = {
    groupedColumns: ['Team'],
    captionFormat: '<span style="display:flex!important;border-bottom: 1px solid rgba(var(--theme-border-primary-accent-shade-35-rgb),var(--theme-border-opac-8)); " class="sticky flex--align-center">{{:key}} - {{:count}} {{if count == 1 }} agent {{else}} agents {{/if}}</span>'
  };
  
  public filterSettings = { filterType: "menu"};
  public filterBar;
  public filterEnabled: boolean = false;

  private stateStorageKey = 'zai_state';
  public teamSearch: string = '';
  public refreshSubscription: Subscription;
  private teamData: {[key: string]: CCMStatModel} = {};
  public doneLoading: number = 0;
  public channelSelection: IStateChannels = {
    call: true,
    callback: false,
    chat: false,
    email: false,
    im: false,
    sms: false,
    ticket: false
  };
  public pageable: any = {
    refresh: false,
    pageSizes: false,
    buttonCount: 10
  };
  public teamDropdownVisible: boolean = false;
  public teamToAdd: { teamId: string; teamName: string; };
  public isAdding: boolean = false;
  public columns: Array<string> = null;
  public headerReordering = false;
  public gridEnabled: boolean = false;
  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }, { value: 120 }
  ];
  private lastRequestTimestamp: any;
  private defaultRequestTimer = 30;
  private currentRequestTimer;
  public timerDropdownVisible = false;
  public scrollTop: number = 0;
  public scrollLeft: number = 0;

  constructor(
    private eventAggregator: EventAggregator,
    private teamLeaderService: TeamLeaderService,
    private readonly dataGridEvents: DataGridEvents,
    private readonly bindingSignaler: BindingSignaler,
    private router: Router,
    private dialogService: DialogService,
    private readonly featureFlags: FeatureFlagService,
    private sessionStore: SessionStore,
    private liveDashboardService: LiveDashboardService
  ) {}

  public bind(): void {
    const addGroupButtons = this.addGroupButtons;
    const generateFilterExpandCollapse = this.generateFilterExpandCollapse;
    const toggleFilter = this.toggleFilter;
    const getGrid = this.getGrid;
    this.filterBar = {
      input: {
        create: function (args: any): any {
          const columnName = args.column.field;
          let form = document.createElement('form');
          form.setAttribute('autocomplete', 'off');

          let input = document.createElement('input');
          input.setAttribute('autocomplete', 'off');
          input.setAttribute('placeholder', 'Name');
          input.setAttribute('title', 'Filter name');
          input.style.color = 'var(--theme-text-black)';
          input.style.width = '100%';
          input.style.border = '0px';
          input.style.outline = 'none';
          input.style['border-radius'] = '2px';

          function keyUpHandler(): void {
            let gridObj = getGrid();
            gridObj.filterColumn(columnName, 'contains', '<span class="">' + this.value);
            addGroupButtons();
            generateFilterExpandCollapse(toggleFilter);
          }

          input.onkeyup = keyUpHandler;

          form.append(input);
          return form;
        },
        write: function (args: any): any {
          const columnName = args.column.field;
          if (columnName === 'Live Actions') {
            return;
          }
          // @ts-ignore
          var data = ej.DataManager(window.gridData).executeLocal(new ej.Query().select(columnName));
          // @ts-ignore
          args.element.ejAutocomplete({ width: "100%", dataSource: data, enableDistinct: true, focusOut: ej.proxy(args.column.filterBarTemplate.read, this, args) });
        }
      },
      options : {
        create: function (args: any): any {
    
          const columnName = args.column.field;
          let optionOne, optionTwo, optionThree;
          if (columnName === 'Routing Status') {
            optionOne = 'On Duty';
            optionTwo = 'Off Duty';
          } else if (columnName === 'Activity Status') {
            optionOne = 'Waiting';
            optionTwo = 'Conversing';
            optionThree = 'Not Responding';
          } else if (columnName === 'Online/Offline') {
            optionOne = 'Online';
            optionTwo = 'Offline';
          }
    
          function callback(): void {
            let els: any = this.parentElement.querySelectorAll('.ej-item');
    
            let isSelected;
            els.forEach(el => {
              if (this.className.indexOf('selected') >= 0) {
                isSelected = true;
              }
              el.className = el.className.replace(' selected', '');
            });
    
            if (isSelected) {
              let gridObj = getGrid();
              gridObj.clearFiltering(columnName);
              addGroupButtons();
                generateFilterExpandCollapse(toggleFilter);
            } else {
              this.className = this.className + ' selected';
              args.column.value = this.innerHTML;
              args.column.filterBarTemplate.change(args);
            }
          };
    
          let wrapper = document.createElement('ul');
          let itemOne, itemTwo, itemThree;
          let gridObj = getGrid();
    
          if (optionOne) {
            let isSelected = gridObj.filterColumnCollection.find(filter => filter.value === optionOne && filter.headerText === columnName);
            itemOne = document.createElement('li');
            itemOne.className = 'ej-item';
            if (isSelected) {
              itemOne.className += ' selected';
            }
            itemOne.onclick = callback;
            itemOne.innerHTML = optionOne;
            itemOne.title = 'Filter ' + optionOne;
            wrapper.appendChild(itemOne);
          }
    
          if (optionTwo) {
            let isSelected = gridObj.filterColumnCollection.find(filter => filter.value === optionTwo && filter.headerText === columnName);
            itemTwo = document.createElement('li');
            itemTwo.className = 'ej-item';
            if (isSelected) {
              itemOne.className += ' selected';
            }
            itemTwo.onclick = callback;
            itemTwo.innerHTML = optionTwo;
            itemTwo.title = 'Filter ' + optionTwo;
            wrapper.appendChild(itemTwo);
          }
    
          if (optionThree) {
            let isSelected = gridObj.filterColumnCollection.find(filter => filter.value === optionThree && filter.headerText === columnName);
            itemThree = document.createElement('li');
            itemThree.className = 'ej-item';
            if (isSelected) {
              itemOne.className += ' selected';
            }
            itemThree.onclick = callback;
            itemThree.innerHTML = optionThree;
            itemThree.title = 'Filter ' + optionThree;
            wrapper.appendChild(itemThree);
          }
          return wrapper;
        },
        write: function (args: any): any {
          const columnName = args.column.field;
          // @ts-ignore
          var data = ej.DataManager(window.gridData).executeLocal(new ej.Query().select(columnName));
          // @ts-ignore
          args.element.ejAutocomplete({ width: "100%", dataSource: data, enableDistinct: true, focusOut: ej.proxy(args.column.filterBarTemplate.read, this, args) });
        },
        change: function (args: any): any {
          const columnName = args.column.field;
          const value = args.column.value;
          let gridObj = getGrid();
  
          gridObj.filterColumn(columnName, 'contains', value);
          addGroupButtons();
          generateFilterExpandCollapse(toggleFilter);
        }
      }
    };
    this.init();
  }

  public isSortableColumn(title: string): boolean {
    return ['Team', 'Logout', 'Live Actions', 'Change Routing Status', 'Change Presence'].indexOf(title) === -1;
  }

  public actionComplete(event: any): void {
    if (event.requestType === 'reorder') {
      this.save();
    } else if (event.requestType === 'sorting') {
      this.generateFilterExpandCollapse(this.toggleFilter);
    } else if (event.requestType === 'refresh') {
      this.generateFilterExpandCollapse(this.toggleFilter);
      this.addGroupButtons();
      this.loadScrollPosition();
    }
  }

  private getGrid(): any {
    let grid = $('#Grid')[0], gridObj;
    let keys = Object.keys(grid);
    keys.forEach(key => {
      if (key && grid[key] && key.indexOf('jQuery') >= 0) {
        if (grid[key].ejGrid) {
          gridObj = grid[key].ejGrid;
        }
      }
    })
    return gridObj;
  }

  private async init(): Promise<void> {
    this.dataGridEvents.onDragStart(() => {
      this.refresher.enableSelectors = false;
      this.refresher.paused = true;
    });
    this.dataGridEvents.onDragEnd(() => {
      this.refresher.enableSelectors = true;
      this.refresher.paused = false;
    });
    const colorScheme: any = await this.retrieveColorConfig();
    let config = await colorScheme ? this.transformData(colorScheme.colorFormats) : DEFAULT_CONFIG;

    ColourConfig.setColourScheme(ColourConfig.VIEWS.CONTACT_CENTER_MONITORING, config);

    this.mappings = await this.contactCenterMonitoringState.getMappings(config);

    this.cdDataGrid = new DataGrid(this.mappings);
    this.cdDataGrid.dataSource = [];
    this.refresher.currentRequestTimer = this.contactCenterMonitoringState.refreshTime;
  }
  private async retrieveColorConfig(): Promise<void> {
    try {
      let config: any = await this.liveDashboardService.retrieveColorConfig('CONTACT_CENTER_MONITORING_DASHBOARD');
      if (!config) {
        const configId = uuidv4();
        let payload: any = {
          id: configId,
          type: 'CONTACT_CENTER_MONITORING_DASHBOARD',
          colorFormats: {}
        };

        this.liveDashboardService.addColorConfig(payload.id, payload);
      }
      return config;
    } catch(e) {
      logger.warn('Failed to retrieve live dashboard color config ', e);
    }
  }

  private transformData(inputData) {
    let transformedData = [];

    for (let key in inputData) {
      if (inputData.hasOwnProperty(key)) {
        let transformedItem = {
          title: key,
          settings: inputData[key]
        };
        transformedData.push(transformedItem);
      }
    }
    return transformedData;
  }

  public activate(): any {
  }

  public attached(): void {
    this.generateFilterExpandCollapse(this.toggleFilter);
    this.retieveTeams().then(() => this.startRefresh());
  }

  private generateFilterExpandCollapse = (toggleFilter: () => void, counter?: number): void => {
    if (!counter) {
      counter = 0;
    } else if (counter > 20) {
      return;
    }

    let expandCollapseFilter: HTMLElement = document.querySelector('.e-grouptopleftcell.e-lastgrouptopleftcell > .e-headercelldiv.e-emptyCell');
    if (!expandCollapseFilter) {
      counter++;
      setTimeout(() => {
        this.generateFilterExpandCollapse(toggleFilter, counter);
      }, 100);
      return;
    }
    let shevron = document.createElement('section');

    shevron.className = `u-flex u-size-1 u-padding--0 o-z-icon--filter hoverable`;
    shevron.addEventListener('click', () => toggleFilter());
    expandCollapseFilter.innerHTML = '';
    expandCollapseFilter.append(shevron);

    let nameColumns: any = document.querySelectorAll('.e-columnheader > .e-headercell');
    this.removeDragEvents(nameColumns[0]);
    this.removeDragEvents(nameColumns[1]);
  }

  private removeDragEvents(nameColumn: HTMLElement): void {
    nameColumn.classList.remove('e-draggable');
    nameColumn.classList.remove('e-droppable');

    // the following disabled drag and drop for the name column
    let keys = Object.keys(nameColumn);
    keys.forEach(key => {
      if (key && nameColumn[key] && key.indexOf('jQuery') >= 0) {
        if (nameColumn[key].ejWidgets) {
          delete nameColumn[key].ejWidgets;
        }
        if (nameColumn[key].events) {
          delete nameColumn[key].events.mousedown;
        }
        if (nameColumn[key].ejDraggable) {
          delete nameColumn[key].ejDraggable;
        }
        if (nameColumn[key].ejDroppable) {
          delete nameColumn[key].ejDroppable;
        }
      }
    });
  }

  private toggleFilter(): void {
    let filterBar = document.querySelector('.e-filterbar');
    let filterEnabled = !filterBar.classList.contains('open');
    if (filterEnabled) {
      filterBar.classList.add('open');
    } else {
      filterBar.classList.remove('open');
    }
  }
  
  private retieveTeams(): Promise<void> {
    return new Promise(resolve => {
      this.teamLeaderService
        .retrieveTeams()
        .then((results: DisplayTeamsModel[]) => {
          this.teams = ArrayTools.sort(results, 'teamName');
          
          if (this.teams && Array.isArray(this.teams) && this.teams.length > 0 && !this.hasTeamsState()) {
            this.teams[0].isAdded = true;
          }
          this.saveTeamsState();
          resolve();
        })
        .catch((e) => {
          this.setReadyState();
          this.isAdding = false;
        });
    });
  }

  private setReadyState(): void {
    this.refresher.enableSelectors = true;
    this.refresher.paused = false;
    this.cdDataGrid.ready = true;
    this.gridEnabled = true;
    this.refresher.reset();
  }

  private getSelectedTeams(): void {
    if (!this.cdDataGrid) {
      setTimeout(() => {
        this.getSelectedTeams();
      }, 100)
      return;
    }
    this.saveScrollPosition();

    this.contactCenterMonitoringState.clearTimers();
    this.cdDataGrid.dataSource = [];
    this.refresher.paused = true;

    const state = this.getStateFromStorage();
    
    if (state && state.contactCenterMonitoring) {
      const grid = state.contactCenterMonitoring.grid;
      if (grid.teams && grid.teams.length > 0) {
        grid.teams.forEach(teamName => {
          let team = this.teams.find(_team => _team.teamName === teamName);
          if (team) {
            this.addTeam(team);
          }
        });
      } else {
        this.setReadyState();
        this.lastRequestTimestamp = undefined;

        this.isAdding = false;
      }
    } else {
      this.setReadyState();
    }
  }

  private saveScrollPosition(): void {
    const el = document.querySelector('.e-proportional-content.u-scroll-bar');
    if (el) {
      this.scrollTop = el.scrollTop;
      this.scrollLeft = el.scrollLeft;
    }
  }

  private scrollPositionCheck: number = 0;
  private loadScrollPosition(): void {

    if (this.scrollPositionCheck >= 20) {
      this.scrollPositionCheck = 0;
      return;
    }

    let el = document.querySelector('#js-scrollContainer');
    if (el) {
      el.scrollTop = this.scrollTop;
      el.scrollLeft = this.scrollLeft;

      if (
        this.scrollTop !== el.scrollTop ||
        this.scrollLeft !== el.scrollLeft
      ) {
        this.scrollPositionCheck++;
        setTimeout(() => {
          this.loadScrollPosition();
        }, 1000 / 3);
      }
    }
  }

  private startRefresh(): void {
    this.getSelectedTeams();
    if (this.refreshIntervalHolder) {
      clearInterval(this.refreshIntervalHolder);
    }
    this.refreshIntervalHolder = setInterval(() => {
      if (this.refresher.paused) {
        return;
      }
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(moment(), 'seconds') <= 0) {
        if (!this.lastRequestTimestamp) {
          return;
        }
        this.cdDataGrid.dataSource = [];
        this.getSelectedTeams();
      }
    }, 1000 / 3);
  }

  public detached(): void {
    window.clearTimeout(this.retryTimeout);
    this.contactCenterMonitoringState.clearTimers();
    clearInterval(this.refreshIntervalHolder);
  }

  private retryCounter: number = 0;
  private retryTimeout;
  public addGroupButtons = (): void => {
    let groupCaptions: NodeListOf<Element> = document.querySelectorAll('.e-groupcaption');
    if ((!groupCaptions || groupCaptions.length === 0) && this.retryCounter <= 15) {
      this.retryTimeout = setTimeout(() => {
        this.retryCounter += 1;
        this.addGroupButtons();
      }, 200);
      return;
    }
    this.retryCounter = 0;

    const columns =  this.mappings.filter((col: any) => {
      if (col.field.visible) {
        let match = this.cdDataGrid.mappings.find((mapping: any) => col.title === mapping.title);
        col.field.value = match ? match.field.value : '--';
        return true;
      }
      return false;
    }).sort((a, b) => a.field.orderIndex - b.field.orderIndex);

    for(let i = 0; i < groupCaptions.length; i++) {
      let groupWrapper: any = groupCaptions[i];
      let hasButton = groupWrapper.querySelector('.remove-button');
      if (hasButton) {
        continue;
      }

      // @ts-ignore
      const columnTitle = groupWrapper.firstChild.querySelector('span').innerText;
      let button = document.createElement('button');
      button.className = 'remove-button';
      button.textContent = 'Remove';
      button.onclick = () => this.removeTeam(columnTitle);
      // @ts-ignore
      groupWrapper.firstChild.firstChild.title = columnTitle;
      groupWrapper.firstChild.insertBefore(button, groupWrapper.firstChild.firstChild);

      groupWrapper.id = 'js-' + columnTitle;
      groupWrapper.className = 'e-rowcell sticky flex flex--align-center';
      groupWrapper.setAttribute('colspan', '2');
      groupWrapper.style['border-right'] = '1px solid rgba(var(--primary-accent--shade-35-rgb),var(--theme-background-opac-8))';
      groupWrapper.style['background-color'] = 'var(--theme-background-default)';
      groupWrapper.style['z-index'] = 10;

      columns.forEach(column => {
        this.generateGroupCells(groupWrapper.parentElement, column, this.teamData[columnTitle]);
      })
    }
  }

  private generateGroupCells(rowElement: HTMLElement, column: DataGridSetting, team: CCMStatModel): void {
    if (column.title === 'Name' || column.title === 'Team') {
      return;
    }
    let newCell = document.createElement('td');
    let cellContent = document.createElement('span');

    newCell.className = 'e-rowcell';
    newCell.style['border-bottom'] = '1px solid rgba(var(--primary-accent--shade-35-rgb),var(--theme-border-opac-8))';
    if (typeof column.value === 'function') {
      cellContent.innerHTML = column.value(team);
    } else if (typeof column.value === 'string') {
      cellContent.innerHTML = column.value;
    }
    newCell.appendChild(cellContent);
    rowElement.appendChild(newCell);

  }

  public channelSelectionChanged(): void {
    setTimeout(() => {
      this.saveStateToStorage(null, this.channelSelection);
    }, 100);
  }

  private getStateFromStorage(): IStateStorage {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);
      return parsedState;
    } catch(e) {
      return null;
    }
  }

  public saveTeamsState(): void {
    let teams = [];
    this.teams.forEach(team => {
      if (team.isAdded) {
        teams.push(team.teamName);
      }
    });
    this.saveStateToStorage(null, null, teams);
  }

  private saveStateToStorage(columnHeaders: Array<string>, channels: IStateChannels, teams?: Array<string>): void {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);

      if (!parsedState.contactCenterMonitoring) {
        parsedState.contactCenterMonitoring = {
          grid: {}
        };
      }
      parsedState.contactCenterMonitoring.grid.columnHeaders = columnHeaders || parsedState.contactCenterMonitoring.grid.columnHeaders;
      parsedState.contactCenterMonitoring.grid.channels = channels || parsedState.contactCenterMonitoring.grid.channels;
      parsedState.contactCenterMonitoring.grid.teams = teams || parsedState.contactCenterMonitoring.grid.teams;

      localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
    } catch(e) {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({
        contactCenterMonitoring: {
          grid: {
            columnHeaders,
            channels,
            teams
          }
        }
      }));
    }
  }

  public selectedTeamChanged(): void {
    if (this.teams && Array.isArray(this.teams)) {
      this.teams = this.teams.map(team => {
        team.isAdded = false;
        return team;
      });
    }
  }

  public showTeamDropdown(): void {
    this.teamDropdownVisible = !this.teamDropdownVisible;
  }

  public selectTeamToAdd(team: { teamId: string; teamName: string; isAdded?: boolean }): void {
    if (team.isAdded) {
      return;
    }
    this.teamToAdd = team;
    this.teamDropdownVisible = false;
  }

  public async removeTeam(teamName: string): Promise<void> {
    this.teams = this.teams.map(team => {
      if (team.teamName === teamName) {
        team.isAdded = false;
        this.contactCenterMonitoringState.removeTeam(team.teamId);
      }
      return team;
    });
    this.saveTeamsState();
    this.cdDataGrid = new DataGrid(this.mappings);
    this.getSelectedTeams();
  }

  public addTeam(_team?: any): void {
    if (!this.teamToAdd && !_team) {
      return;
    }
    this.isAdding = true;
    const teamToAdd = _team || this.teamToAdd;
    
    let team = this.teams.find(_team => _team.teamId === teamToAdd.teamId);
    if (team) {
      team.isAdded = true;
    }
    this.saveTeamsState();

    this.retrieveData(teamToAdd);
  }

  private async retrieveData(team: { teamId: string, teamName: string }): Promise<void> {
    let setLastTimestamp = setInterval(() => {
      this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer + 0.9);
    }, 500);

    try {
      let teamResponse: { team: any, members: any } = await this.teamLeaderService.retrieveTeamDailyActivity(team.teamId);

      window.clearInterval(setLastTimestamp);
      this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer + 0.9);
      this.teamAgentActivityRetrieved(team, teamResponse);
    } catch(error) {
      logger.warn('Failed to retrieve members due to cause', error);
      window.clearInterval(setLastTimestamp);
    }
  }

  private teamAgentActivityRetrieved(team: { teamId: string, teamName: string }, response: { team: any, members: any }): void {
    let teamData: any = {};
    const emptyState = {
      teamName: undefined,
      currentAgentsIds: [],
      presenceTotals: {},
      callsAmounts: {},
      times: {},
      connectedChannelsAmounts: {},
      interactionCounts: {},
      dispositions: {},
      handledInteractionCounts: {},
      prospectCounts: {},
      averageSurveyScore: 0,
      ...team
    };

    teamData = !response || !response.team ? { ...emptyState } : response.team;
    const trimmedName = (team.teamName || '').trim();
    teamData.name = trimmedName;
    teamData.isTeam = true;
    
    this.teamData[trimmedName] = teamData;

    if (response.members) {
      const agents = ArrayTools.sort(response.members, 'name');
      agents.forEach(agent => {
        agent.teamName = trimmedName;
        this.cdDataGrid.addData(agent, this.cdDataGrid.dataSource);
      });
    }
    this.setReadyState();

    this.teamToAdd = null;
    this.eventAggregator.publish('live-dashboard.refreshed.data');
    this.isAdding = false;
    this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer);
  }

  public sortable = {
    compare: (a: any, b: any, desc: any): number =>  {
      if (a.taskTemplateName === 'All Work Types') {
        return 0;
      } else if (b.taskTemplateName === 'All Work Types') {
        return 0;
      } else if (a.taskTemplateName === b.taskTemplateName) {
        return 0;
      } else if (a.taskTemplateName === null) {
        return desc ? -1 : 1;
      } else if (b.taskTemplateName === null) {
        return desc ? 1 : -1;
      } else if (desc) {
        return -1;
      } else {
        return 1;
      }
    }
  }
  
  public hasTeamsState(): boolean {
    const state = this.getStateFromStorage();
    if (state && state.contactCenterMonitoring) {

      this.currentRequestTimer = state.contactCenterMonitoring.requestTimer || this.defaultRequestTimer;
      const grid = state.contactCenterMonitoring.grid;
      if (grid.teams) {
        grid.teams.forEach(teamName => {
          let team = this.teams.find(team => team.teamName === teamName);
          if (team) {
            team.isAdded = true;
          }
        });
        return true;
      }
    } else {
      this.currentRequestTimer = this.defaultRequestTimer;
    }
    return false;
  }

  public viewAgentStats(event: CustomEvent): void {
    event.stopPropagation();

    const memberId = event.detail.memberId;
    const fullName = event.detail.fullName;
    const averageSurveyScore = event.detail.averageSurveyScore;
    // @ts-ignore
    let agentMenu: string = PLATFORM.moduleName('features/dashboard/teamleader/components/agentstatsdialog/agent-stats-dialog');
    this.dialogService.open({ viewModel: agentMenu, model: { memberId, fullName, averageSurveyScore } });
  }

  public spy(event: CustomEvent): void {
    event.stopPropagation();
    logger.info('CHECK :: SPY');

    const callId = event.detail.callId;
    this.teamLeaderService
      .spyCall(callId)
      .then(() => this.getSelectedTeams());
  }

  public whisper(event: CustomEvent): void {
    event.stopPropagation();
    logger.info('CHECK :: WHISPER');

    const callId = event.detail.callId;
    this.teamLeaderService
      .whisperCall(callId)
      .then(() => this.getSelectedTeams());
  }

  public logout(event: CustomEvent): void {
    event.stopPropagation();

    const user = event.detail.fullName;
    const passportId = event.detail.passportId;
    this.dialogService
      .open({ viewModel: ConfirmDialog, model: {
        message: `This will log out ${user}.`
      } })
      .whenClosed(dialog => {
        if (!dialog.wasCancelled) {
          this.teamLeaderService.logout(passportId);
        }
      });
  }
  
  public async validateInteractions(event: CustomEvent): Promise<void> {
    if (!event.detail.memberId) {
      return;
    }
    try {
      await this.liveDashboardService.validateAndRemoveInteractions(event.detail.memberId, this.sessionStore.get.organisation.organisationId);
    } catch(e) {
      logger.warn(' > Failed to validate interactions due to', e);
    }
  }

  public toggleRoutingStatus(event: CustomEvent): void {
    event.stopPropagation();

    const currentRoutingStatus = event.detail.routingStatus;
    const memberId = event.detail.memberId;
    const routingStatus = currentRoutingStatus ? 'READY' : 'NOT_READY';

    this.teamLeaderService.setRoutingStatus(memberId, routingStatus);
  }
  

  public changePresence(event: CustomEvent): void {
    event.stopPropagation();

    const userId = event.detail.userId;
    const currentPresence = event.detail.currentPresence;

    this.teamLeaderService.changePresence(userId, currentPresence.toUpperCase());
  }

  public settings(): void {
    this.router.navigateToRoute('contactcentermonitoringsettings');
  }

  public save(): void {
    this.refresher.enableSelectors = false;
    this.cdDataGrid.getColumnOrderAndWidths()
      .then((columnOrderAndWidths) => {
        this.contactCenterMonitoringState.saveSettings(columnOrderAndWidths);
        this.contactCenterMonitoringState.setRefreshTime(this.refresher.currentRequestTimer);
        this.refresher.enableSelectors = true;
      });
  }
  
  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: number): void {
    this.currentRequestTimer = value;
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        if (!parsedState.contactCenterMonitoring) {
          parsedState.contactCenterMonitoring = {};
        }
        parsedState.contactCenterMonitoring.requestTimer = value;
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ contactCenterMonitoring: { requestTimer: value } }));
    }
    this.timerDropdownVisible = false;
    this.getSelectedTeams();
  }

}
