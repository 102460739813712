/*
 */
import {inject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {handle, waitFor} from 'aurelia-flux';
/*
 */
import {AssessmentStore} from '../assessment.store';
import {AssessmentService} from '../assessment.service';
import {ASSESSMENT_ACTIONS} from '../assessment.actions';
import InteractionLogService from '../../../../../features/interaction/call/interactionlog/interaction-log-service';
import {UtteranceModel} from '../../../../../features/interaction/call/interactiontranscript/utterance-model';
import {IMInteractionCardService} from '../../../../../features/organisation/conversation/interactioncards/instantmessage/im-interaction-card-service';
import { ConversationService } from '../../../../../features/organisation/conversation/conversation-service';

const logger = LogManager.getLogger('Questions');

import { v4 as uuidv4 } from 'uuid';

/*
 */
@inject(Router, AssessmentService, AssessmentStore, InteractionLogService, IMInteractionCardService, ConversationService)
export class Questions {

  interactionId;
  interaction;
  originEmailInteraction;
  formId;
  utterances = [];
  transcript;
  interaction;
  messageContent;
  hasMessageTranscript;
  messageTranscript = {
    messages: []
  };

  constructor(router, assessmentService, assessmentStore, interactionLogService, imInteractionCardService, conversationService) {

    this.router = router;
    this.assessmentService = assessmentService;
    this.assessmentStore = assessmentStore;
    this.interactionLogService = interactionLogService;
    this.imInteractionCardService = imInteractionCardService;
    this.conversationService = conversationService;
  }

  activate(params) {
    this.interactionId = params.interactionId;
    this.formId = params.formId;
    this.firstName = params.firstName;
    this.surname = params.surname;
    this.memberId = params.memberId;
    this.channel = params.channel;

    this.assessmentService.retrieveQuestions(this.formId);
  }

  attached() {
    this.assessmentService.retrieveQuestions(this.formId);
    this.retrieveTranscriptForInteraction();
  }

  deactivate() {
    this.assessmentService.clearStore();
  }

  retrieveTranscriptForInteraction() {
    this.interactionLogService.retrieveInteraction(this.interactionId)
    .then((data) => {
      this.interaction = data.content[0];
      this.transcript = this.interaction.callTranscript;

      switch(this.channel) {
        case "Outbound Call":
        case "Inbound Call":
          if (this.transcript) {
            this.formatTranscript(this.transcript.words);
          }
          break;
        case "EMAIL":
          this.messageContent = this.interaction.payload.html;
          this.getSource(this.interaction.payload.metadata);
          break;
        case "INSTANT_MESSAGE":
          case "CHAT":
          this.getIMTranscript();
          break;
        default:
          break;
      }
    });
  }

  getIMTranscript() {
    this.imInteractionCardService.getTranscript(this.interactionId)
      .then((transcript) => {
        this.hasMessageTranscript = transcript.messages && transcript.messages.length > 0;
        this.messageTranscript = transcript;
      });
  }

  formatTranscript(callTranscript) {
    this.utterances = [];
    let text = "";
    callTranscript.sort((a,b) => a.position - b.position);
    let utterance = null;
    callTranscript.forEach(transcriptItem => {
      if (transcriptItem.metadata === 'turn') {
        if (utterance) {
          this.utterances.push(utterance);
        }
        utterance = new UtteranceModel(transcriptItem.word);
      } else {
        if (transcriptItem.metadata === 'punc') {
          utterance.words.push(transcriptItem.word);
        } else {
          utterance.words.push(transcriptItem.word + ' ');
        }
      }
    });
    this.utterances.push(utterance);
    this.utterances.forEach(utterance => {
      text += utterance.speaker + ':';
      utterance.words.forEach(word => text += word);
    });
    return text;
  }

  getSource(metadata) {
    if (metadata && metadata.originEmailInteractionId) {
      this.interactionLogService.retrieveInteraction(metadata.originEmailInteractionId).then((data) => {
        this.originEmailInteraction = data.content[0];
      });
    }
  }

  replaceSpeaker(speaker) {
    let to = this.interaction.formattedTo;
    let from = this.interaction.formattedFrom;

    if (speaker.toLowerCase() === 'caller') {
      return to;
    } else {
      return from;
    }
  }

  replaceIMSpeaker(name) {
    if (!name) {
      return this.interaction.members[0].firstName;
    } else {
      return name;
    }
  }

  replaceChatSpeaker(userId) {
    if (userId === 'true') {
      return this.interaction.formattedTo;
    } else {
      return this.interaction.formattedFrom;
    }
  }

  stringify(words) {
    let text = "";
    words.forEach(word => text += word);

    return text;
  }

  toggleExpandSection(section, index) {

    let sectionEl = `#section_${index}`;
    if (!section.expanded) {

      toggleAccordion(sectionEl, true);
    } else {

      toggleAccordion(sectionEl, false);
    }

    section.expanded = !section.expanded;

  }

  setAnswer(question, weight, polarAnswer) {

    if (polarAnswer === 'no') {
      question.noWeight = weight;
    } else {
      question.yesWeight = weight;
    }

    question.value = weight;

    return true;
  }

  back() {

    this.assessmentService.clearStore();
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }

  cancel() {

    this.router.parent.parent.navigate('interactionlog');
  }

  get activateSubmit() {

    let canAtivate = true;

    if (!this.assessmentStore.formQuestions || !this.assessmentStore.formQuestions.sections) {

      return false;
    }
    for (let section of this.assessmentStore.formQuestions.sections) {
      if (section.questions) {
        for (let question of section.questions) {

          if (question.value === undefined) {
            canAtivate = false;
          }

        }
      }
    }

    return canAtivate;
  }

  submit() {

    let sections = [];
    for (let section of this.assessmentStore.formQuestions.sections) {
      let sec = {name: section.name, questions: []};
      if (section.questions) {
        for (let question of section.questions) {
          sec.questions.push({
            id: question.id,
            type: question.type,
            value: question.value,
            yesWeight: question.yesWeight !== null ? question.yesWeight : null,
            noWeight: question.noWeight !== null ? question.noWeight : null

          });
        }


      }
      sections.push(sec);
    }

    let assessment = {
      assessmentId: uuidv4(),
      interactionId: this.interactionId,
      formId: this.formId,
      memberId: this.memberId,
      channel: this.channel,
      sections: sections
    };
    this.assessmentService.submitAssessment(assessment);
    this.router.parent.navigate(`forms/view/${this.memberId}?memberName=${this.firstName} ${this.surname}`);
  }

  @handle(ASSESSMENT_ACTIONS.SHOW_SUCCESS)
  @waitFor(AssessmentStore)
  handleShowSuccessMessage() {

    this.router.parent.navigate(`forms/view/${this.memberId}?memberName=${this.firstName} ${this.surname}`);
  }

}

/*
 */
function setFocus(elementId) {

  let element = document.getElementById(elementId);
  if (element === null) {
    setTimeout(() => {
      setFocus(elementId);
    }, 100);
  } else {
    element.focus();
  }
}

/*
 */
function toggleAccordion(elementId, expand) {

  let childHeight = $(elementId).find("ul:first").height();

  if (expand) {
    $(elementId).height(childHeight);

    setTimeout(() => {
      $(elementId).height('auto');
    }, 200);

    return;
  }

  $(elementId).height(childHeight);
  $(elementId).height(0);

}