import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Router} from 'aurelia-router';
import {SessionStore} from 'zailab.common';

import {LdapGroupService} from "../ldap-groups-service";
import {LdapGroupModel} from "../../models/ldap-group-model";

const logger = LogManager.getLogger('AddLdapGroup');

@inject(LdapGroupService, Router, SessionStore, EventAggregator)
export class AddLdapGroup {
  steps;
  ready = true;

  constructor(ldapGroupService, router, sessionStore, eventAggregator) {
    this.ldapGroupService = ldapGroupService;
    this.router = router;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
  }

  activate() {
    this._initSteps();
  }

  _initSteps() {
    this.steps = [
      {
        name: 'Select Role',
        description: 'Please select a role for your new members.',
        view: PLATFORM.moduleName('features/organisation/organisation/ldapgroups/create/roles/roles'),
        active: true,
        hasAttributes: true,
        completed: false
      },
      {
        name: 'Define Properties',
        description: 'Please enter the LDAP path that references the group of users you would like to import',
        view: PLATFORM.moduleName('features/organisation/organisation/ldapgroups/create/attributes/attributes'),
        hasAttributes: true,
        completed: false
      }
    ];
  }

  complete(data) {
    this.ready = false;
    const group = new LdapGroupModel(data.detail);


    this.ldapGroupService.addGroup(group)
      .then(
        (resolve) => {
          this.router.navigateBack();
        },
        (error) => {
          logger.error('Unable to POST data ', error)
          this.router.navigateBack();
        }
      );
  }

  cancel() {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }
}
