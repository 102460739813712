import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('CampaignActionMenuDialog');
/*
 */
@autoinject
export class CampaignActionMenuDialog {

  public campaign: any;
  public actions: any;

  constructor(
    private readonly dialogController: DialogController
  ) { }

  public activate(item: any): void {
    this.campaign = item.campaign;
    this.actions = item.actions;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public submit(selectAction: string): void {
    this.dialogController.ok({
      action: selectAction,
      campaign: this.campaign
    });
  }
}
