import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionStore } from '../../../../_common/stores/session-store';
import { AssistService } from '../assist-service';

const logger = LogManager.getLogger('AssistCount');

@autoinject
export class AssistCount {

  public count: number = 0;

  constructor(
    private events: EventAggregator,
    private assistService: AssistService,
    private session: SessionStore,
    private router: Router
  ) { }

  public activate(): void {
    if (!this.session.get.user.hasTeamLeaderRole) {
      return;
    }
    this.findTeamLeaderAssist();
    setInterval(() => {
      this.findTeamLeaderAssist();
    }, 10000);
    this.events.subscribe('findTeamLeaderAssist', (response) => {
      if (!response) {
        this.count = 0;
        return;
      }
      this.count = response.length;
    });
  }

  public view(): void {
    this.router.navigate('assist');
  }

  private findTeamLeaderAssist(): Promise<any> {
    return this.assistService.findAllTeamLeaderAssists()
      .then((response) => {
        if (!response) {
          this.count = 0;
          return;
        }
        this.events.publish('findTeamLeaderAssist', response);
        return true;
      })
      .catch((error) => logger.warn('findTeamLeaderAssist :: error=', error));
  }
}
