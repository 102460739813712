import {LogManager} from 'aurelia-framework';
/*
 */
import numeral from 'numeral';
/*
 */
const logger = LogManager.getLogger('CurrencyTools');
/*
 */
export class CurrencyTools {

  convertToCurrency(value, currencyUnit) {
    return currencyUnit + ' ' + numeral(value).format('0,0.00');
  }
}
