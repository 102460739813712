import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';

import {ConversationModel} from '../conversation-model';
import {ConversationService} from '../conversation-service';
import {InteractionModel} from '../interaction-model';
import {CONTACT_ACTIONS, CONVERSATION_ACTIONS} from '../../contact/contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('ActionMenu');

@customElement('z-action-menu')
@autoinject()
export class ActionMenu {
  @bindable private contactid: string;
  @bindable private interaction: InteractionModel;
  @bindable private conversationList: InteractionModel;
  private subMenuVisible: boolean;
  private pendingAndNewConversations: ConversationModel[];
  private visibilityComponent: string = 'action-menu';

  constructor(private conversationService: ConversationService, private eventAggregator: EventAggregator) {
    this.conversationService = conversationService;
    this.eventAggregator = eventAggregator;
  }

  public attached(): void {
  }

  private retrieveContactConversations(): void {
  }

  private splitOutPendingConversations(rawConversationList: ConversationModel[], isOplog?: boolean): void {
    this.pendingAndNewConversations = [];
    rawConversationList.forEach(conversation => {
      if (conversation.status.toLowerCase() === 'pending' || conversation.status.toLowerCase() === 'new') {
        this.pendingAndNewConversations.unshift(conversation);
      }
    });
  }

  private linkInteractionToConversation(conversation: ConversationModel): void {
    this.eventAggregator.publish(CONVERSATION_ACTIONS.SELECT, conversation);
    this.interaction.linkToConversation(conversation.conversationId);
    this.showNewConversationView();
  }

  private createNewConversation(): void {
    this.eventAggregator.publish(CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION, this.interaction);

  }

  private showSubMenu(): void {
    this.subMenuVisible = true;
  }

  private hideSubMenu(): void {
    this.subMenuVisible = false;
  }

  private toggleMenu(): void {
    let wrapper = document.querySelector('.js-component-visibility-' + this.interaction.interactionId);
    let el = wrapper.querySelector('.js-component-container');
    $(el).toggleClass('is-visible');
  }

  private showNewConversationView(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: 'interactions',
      contactId: this.contactid
    });
  }
}
