import {WidgetModel} from './widget-model';

export class DashboardModel {
  public active: boolean;
  public viewModel: string;
  public title: string;
  public widgets: WidgetModel[];

  constructor(viewModel?: string) {
    this.viewModel = viewModel;
    this.title = '';
    this.active = false;
    this.widgets = [];
  }
}