import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('ChangeMemberInformationDialog');
/*
 * */
@inject(DialogController, ValidationControllerFactory)
export class ChangeMemberInformationDialog {

  externalReference = '';
  changeMemberInformationFormGroup;
  dialogHeader = 'Change Member Information';

  constructor(dialogController, validationControllerFactory) {
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * */
  activate(member) {

    this.externalReference = member.externalReference;

    ValidationRules
      .ensure('externalReference')
      .required().withMessage('External reference is required.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.changeMemberInformationFormGroup.reset();
    this.dialogController.cancel();
  }

  /**
   * closes the dialog
   * @return name {object}
   */
  rename() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }

      this.dialogController.ok({
        externalReference: this.externalReference
      });
    }, () => {});
  }
}

