import { inject, customElement, bindable, LogManager, containerless } from 'aurelia-framework';
/*
 */
import { Event, SessionStore } from 'zailab.common';
import { List } from 'zailab.abstract';
import { CosService } from '../cos-service';
/*
 */
let logger = LogManager.getLogger('CosWidget');
/*
 */
@containerless()
@customElement('z-condensed-cos-list')
@inject(Element, SessionStore, CosService)
export class CosWidget {

  @bindable required;
  @bindable multiselect;
  @bindable nodefaultselected;
  @bindable selected;
  @bindable canedit;
  @bindable({ attribute: 'items-per-row' }) itemsperrow;
  list;
  classesOfService = [
    {name: "INTERNAL", displayName: "Internal Calls"},
    {name: "LOCAL_EXCLUDING_MOBILE", displayName: "Local (Excluding mobile)"},
    {name: "LOCAL_INCLUDING_MOBILE", displayName: "Local (Including Mobile)"},
    {name: "LOCAL_AND_INTERNATIONAL", displayName: "Local and International"}
  ];

  constructor(element, sessionStore, cosService) {
    this.element = element;
    this.sessionStore = sessionStore;
    this.cosService = cosService;
  }

  bind() {
    this._setupConfig();
  }

  _setupConfig() {

    let defaultSelectionStrategy = (this.nodefaultselected) ? null : this._defaultSelectionStrategy;

    this.list = List.Builder() //
      .required(this.required) //
      .customEventElement(this.element) //
      .selectionType(this.multiselect ? List.MULTIPLE_SELECTION_TYPE : List.SINGLE_SELECTION_TYPE) //
      .title(this.title) //
      .items(this.classesOfService) //
      .selectedItems(this.selected) //
      .icon('cos') //
      .uniqueIdentifier('name') //
      .displayId('displayName') //
      .selectionStrategy(defaultSelectionStrategy) //
      .enableReadOnly(this.canedit)
      .build();
  }

  get title() {
    return 'Select Class' + (this.multiselect ? 'es' : '') + ' of Service';
  }

  get itemsPerRow() {
    return this.itemsperrow || 6;
  }

  select(event) {
    new Event(this.element, 'select', event);
  }

  _defaultSelectionStrategy(item) {
    return item.name === 'INTERNAL';
  }

  updateItems(data) {
    new Event(this.element, 'updateitems', data);
  }
}