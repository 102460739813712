// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { SMSGatewayService } from '../../sms-gateway-service';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('SMSGatewayAccountConfigDialog');

@autoinject()
export class SMSGatewayAccountConfigDialog {

  public dialogHeader = "Define the properties of your Account";
  public loading: boolean = false;
  public error: string;

  public gatewayAccountNames: string[] = [];
  public gatewayId: string;
  public gatewayAccountId: string;
  public name: string = '';
  public currentName: string;
  public username: string = '';
  public password: string = '';
  public number: string = '';

  private validation: ValidationController;

  constructor(
    private dialogController: DialogController,
    private SMSGatewayService: SMSGatewayService,
    private sessionStore: SessionStore,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(data: {
    gatewayId: string,
    gatewayAccountNames: string[],
    gatewayAccount?: ISMSGatewayAccount
  }): void {
    this.gatewayAccountNames = data.gatewayAccountNames;
    this.gatewayId = data.gatewayId;

    if (data.gatewayAccount) {
      this.gatewayAccountId = data.gatewayAccount.id;
      this.name = data.gatewayAccount.name || '';
      this.currentName = `${data.gatewayAccount.name}`;
      this.username = data.gatewayAccount.username || '';
      this.password = data.gatewayAccount.password || '';
      this.number = data.gatewayAccount.number || '';
    }

    ValidationRules
      .customRule('uniqueName', (newName) => {
        if (newName === this.currentName) {
          return true;
        }
        for (let name of this.gatewayAccountNames) {
          if (name.toLowerCase() === newName.toLowerCase()) {
            return false;
          }
        }
        return true;
      }, 'Please enter a unique name.');
      
    ValidationRules
      .customRule('validNumber', (value) => {
        return /^\d+$/.test(value);
      }, 'Please enter a valid number.');


    ValidationRules
      .ensure('name').required().satisfiesRule('uniqueName')
      .ensure('username').required()
      .ensure('password').required()
      .ensure('number').required().then().satisfiesRule('validNumber')
      .on(this);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public save(): void {
    this.error = null;
    this.loading = true;
    this.validation
      .validate()
      .then((validation) => {
        if (!validation.valid) {
          this.loading = false;
          return;
        }
        if (this.gatewayAccountId) {
          const gatewayAccount: ISMSGatewayAccount = {
            id: this.gatewayAccountId,
            name: this.name,
            username: this.username,
            password: this.password,
            number: this.number
          };
          this.SMSGatewayService
            .updateGatewayAccount(
              this.sessionStore.get.organisation.organisationId,
              this.gatewayId,
              gatewayAccount.id,
              gatewayAccount.name,
              gatewayAccount.username,
              gatewayAccount.password,
              gatewayAccount.number
            )
            .then(() => this.dialogController.ok(gatewayAccount))
            .catch(() => this.loading = false);
        } else {
          const gatewayAccount: ISMSGatewayAccount = {
            id: uuidv4(),
            name: this.name,
            username: this.username,
            password: this.password,
            number: this.number
          }

          this.SMSGatewayService
            .createGatewayAccount(
              this.sessionStore.get.organisation.organisationId,
              this.gatewayId,
              gatewayAccount.id,
              gatewayAccount.name,
              gatewayAccount.username,
              gatewayAccount.password,
              gatewayAccount.number
            )
            .then(() => this.dialogController.ok(gatewayAccount))
            .catch(() => this.loading = false);
        }
      });
  }
}

