import { inject, LogManager, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { SessionStore } from 'zailab.common';
/*
 * */
import { AssignEmailDialogService } from './assign-email-dialog-service';
/*
 * */
const logger = LogManager.getLogger('AssignEmailDialog');
/*
 * */
@inject(DialogController, AssignEmailDialogService, SessionStore)
export class AssignEmailDialog {

  dialogHeader = 'Assign Email';
  liveDialogHeader = 'Assign Live Email';
  emailAddresses = [];
  interactionFlowId;

  assignEmailTabs = [];

  constructor(dialogController, assignEmailDialogService, sessionStore) {
    this.dialogController = dialogController;
    this.assignEmailDialogService = assignEmailDialogService;
    this.sessionStore = sessionStore;
  }

  activate(model) {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.organisationId = model.organisationId;
    this.flowName = model.interactionFlow.flowName;


    this.assignEmailTabs = [
      { id: '1', name: 'Custom Email Address', viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/assign-email/add-custom-email/add-custom-email'), icon: 'plus' },
      { id: '2', name: 'Generated Email Address', viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/assign-email/add-generic-email/add-generic-email'), icon: 'plus' },
    ];

    if (model.liveNumbers === true) {
      this._setNumbersType(model.liveNumbers);
    }
    this._retrieveEmails();
  }

  _setNumbersType(liveNumbers) {
    this.liveNumbers = liveNumbers;
    this.dialogHeader = this.liveDialogHeader;
  }

  _retrieveEmails() {
    const params = this.liveNumbers ? { live: this.liveNumbers } : null;
    this.assignEmailDialogService
      .retrieveInteractionFlowEmails(this.interactionFlowId, params)
      .then(data => {
        this.emailAddresses = data.emailAddresses;
      });
  }

  addEmailToInteractionFlow(email) {
    this.assignEmailDialogService.addEmailToInteractionFlow(this.interactionFlowId, this.organisationId, email, this.liveNumbers);
    this.emailAddresses.push(email);
  }

  removeEmailFromInteractionFlow(index, email) {
    this.assignEmailDialogService.removeEmailFromInteractionFlow(this.interactionFlowId, this.organisationId, email);
    this.emailAddresses.splice(index, 1);
  }

  cancel() {
    this.dialogController.cancel();
  }

  done() {
    this.dialogController.ok();
  }

}
