import { inject, LogManager } from 'aurelia-framework';
import { ApplicationProperties } from 'zailab.common';
import FileSaver from 'file-saver';
import { SessionStore } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('DownloadService');
/*
 */
@inject(ApplicationProperties, SessionStore)
export class DownloadService {
  constructor(applicationProperties, sessionStore) {
    this.applicationProperties = applicationProperties;
    this.sessionStore = sessionStore;
    if (this.sessionStore.get.user) {
      this.authHeader = 'Bearer ' + this.sessionStore.get.user.token;
    }
  }

  download(fileName, uri, download) {
    let host = this.applicationProperties.apiQueryEndpoint;
    //uri = host + uri;
    return new Promise((resolve, reject) => {
      this.executeXHRRequest(uri).then(
        response => {
          if (download === true) {
            FileSaver.saveAs(response, fileName);
          }
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  downloadWithoutEndpoint(filename, uri, download) {
    return new Promise((resolve, reject) => {
      this.executeXHRRequest(uri).then(
        response => {
          if (download === true) {
            FileSaver.saveAs(response, filename);
          }
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  downloadWithAuth(fileName, uri, auth, download) {
    let host = this.applicationProperties.apiQueryEndpoint;
    uri = host + uri;
    return new Promise((resolve, reject) => {
      this.executeXHRRequest(uri).then(
        response => {
          if (download === true) {
            FileSaver.saveAs(response, fileName);
          }
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  downloadWithoutAuthAndEndpoint(fileName, uri) {
    return new Promise((resolve, reject) => {
      this.executeXHRRequestWithoutAuth(uri).then(
        response => {
          FileSaver.saveAs(response, fileName);
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  executeXHRRequest(uri) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', uri, true);
    xhr.setRequestHeader('Authorization', this.authHeader);
    xhr.responseType = 'blob';
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {
            resolve(xhr.response);
          } else {
            reject(xhr.statusText);
          }
        }
      };
      xhr.send();
    });
  }

  executeXHRRequestWithoutAuth(uri) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', uri, true);
    xhr.responseType = 'blob';
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {
            resolve(xhr.response);
          } else {
            reject(xhr.statusText);
          }
        }
      };
      xhr.send();
    });
  }
}
