export const PAYGATE_VALIDATION_MESSAGE = {
  cardholderName: {
    empty: 'Please enter a Cardholder Name.',
    invalid: 'Please enter a valid Cardholder Name.',
  },
  address: {
    empty: 'Please enter your Address Line 1.',
  },
  city: {
    empty: 'Please enter your City.',
  },
  country: {
    empty: 'Please enter your Country.',
  },
  postalCode: {
    empty: 'Please enter a Code.',
  },
  region: {
    empty: 'Please enter your Region / State / Province.',
  },
  cardNumber: {
    empty: 'Please enter your Card Number.',
    invalid: 'Please enter a valid Card Number.',
    noSupported: 'VISA and Mastercard cards only.',
  },
};