import {CUSTOM_EVENTS} from 'zailab.common';
import {ValidationControllerFactory} from 'aurelia-validation';
import {LogManager, inject} from "aurelia-framework";
/**/
const logger = LogManager.getLogger('Tab Base');
/**/
@inject(ValidationControllerFactory, Element)
export class TabBase {

  protected tabData: any;
  protected element: Element;
  protected validationControllerFactory: ValidationControllerFactory;

  constructor(_validationCF: ValidationControllerFactory, _element: Element) {
    this.element = _element;
    this.validationControllerFactory = _validationCF;
  }

  protected activate(_tab: object): void {
    this.tabData = _tab;
  }

  protected setExistingData():void {
    // Will override
  }

  protected sendTabData(_params: any): void {
    this.element.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TAB_RESULT, {
      bubbles: true,
      detail: {id: this.tabData.id, valid: true, value: _params}
    }));
  }

  protected invalidate(): void {
    this.element.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TAB_RESULT, {
      bubbles: true,
      detail: {id: this.tabData.id, valid: false}
    }));
  }
}