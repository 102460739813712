import { AccountSessionModel } from '../../../../../_common/stores/sessionmodels/account-session-model';
import { Money } from '../recharge-trigger-model';
import { ZSelectInputOption } from '../../../../../_common/models/input-model';

export class AutoRechargeDetails {

  public account: AccountSessionModel;
  public threshold: string;
  public recharge: string;
  public cardId: string;
  public enabled: boolean;
  public thresholdOptions: ZSelectInputOption<number>[];
  public rechargeOptions: ZSelectInputOption<number>[];
  public cardOptions: ZSelectInputOption<string>[];
  public selectedThreshold: ZSelectInputOption<number>;
  public selectedRecharge: ZSelectInputOption<number>;
  public selectedCard: ZSelectInputOption<string>;

  public clone(): AutoRechargeDetails {
    return Object.assign(new AutoRechargeDetails(), this);
  }

  public isEmpty(): boolean {
    return !this.threshold && !this.recharge && !this.cardId;
  }

  public clear(): void {
    this.threshold = null;
    this.recharge = null;
    this.cardId = null;
  }

  public update(other: AutoRechargeDetails): void {
    this.threshold = other.threshold;
    this.recharge = other.recharge;
    this.cardId = other.cardId;
  }

  public populatePayloadValues(): void {
    this.threshold = this.selectedThreshold.value + '';
    this.recharge = this.selectedRecharge.value + '';
    this.cardId = this.selectedCard.value + '';
  }

  public toPayload(): AutoRechargePayload {
    return new AutoRechargePayload(
      new Money(this.threshold, this.account.available.currency),
      new Money(this.recharge, this.account.available.currency),
      this.cardId
    );
  }

  public equals(other: AutoRechargeDetails): boolean {
    return this.threshold === other.threshold && this.recharge === other.recharge && this.cardId === other.cardId;
  }
}

export class AutoRechargePayload {

  public threshold: Money;
  public rechargeBalance: Money;
  public cardId: string;

  constructor(threshold: Money, recharge: Money, cardId: string) {
    this.threshold = threshold;
    this.rechargeBalance = recharge;
    this.cardId = cardId;
  }
}

export class PaymentDetails {

  public cards: CardInfo[];

  public get containsCards(): boolean {
    return this.cards && this.cards.length > 0;
  }

  public clone(): PaymentDetails {
    return Object.assign(new PaymentDetails(), this);
  }
}

export class CardInfo {

  public id: string;
  public number: string;
  public type: string;
  public expiry: Expiry;

  constructor(id: string, number: string, type: string, expiry: Expiry) {
    this.id = id;
    this.number = number;
    this.type = type;
    this.expiry = new Expiry(expiry.month, expiry.year);
  }

  public get label(): string {
    return `${this.type} ${this.number} (${this.expiry.label})`;
  }
}

export class Expiry {

  public month: string;
  public year: string;

  constructor(month: string, year: string) {
    this.month = month;
    this.year = year;
  }

  public get label(): string {
    return `${this.month}/${this.year}`;
  }
}