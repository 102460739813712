import {LogManager, autoinject} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";
/**/
const logger = LogManager.getLogger('Name');
/**/
@autoinject
export class Options {

  private maxDuration: number = 20;
  private maxSilence: number = 2;
  private wizard: any;

  constructor(private eventAggregator:EventAggregator) {}

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.validate();
  }

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  private initWizard(wizard: any): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.maxDuration = this.wizard.data.maxDuration ? this.wizard.data.maxDuration : 20;
    this.maxSilence = this.wizard.data.maxSilence ? this.wizard.data.maxSilence : 2;
  }

  private updateWizardData(): void {
    this.wizard.data.maxDuration = this.maxDuration;
    this.wizard.data.maxSilence = this.maxSilence;
  }

  private validate(): void {

    if(!this.maxDuration || !this.maxSilence) {
      this.wizard.step.incomplete();
      return;
    }

    this.wizard.step.complete({maxDuration: this.maxDuration, maxSilence: this.maxSilence});
  }

  private nextStep(): void {
    this.wizard.continue();
  }

}