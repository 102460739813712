import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('ConfirmDialog');

@autoinject()
export class ConfirmDialog {
  public message: string;

  constructor(private dialogController: DialogController) {}

  public activate(data: { message: string }): void {
    if (data && data.message) {
      this.message = data.message;
    }
  }

  public confirm(): void {
    this.dialogController.ok();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
