import {inject, LogManager} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('AccountState');

export class AccountState {

  // TODO : Move all account state here

  lastUsageDateLoaded = null;

}
