export const ANALYTICS_DB_CONFIG = {
  dev1: {
    WRSummaryReport: '3c4c9218-688a-4068-824a-305ae7cb3bfc',
    SessionReport: '75ff849e-4ffb-4d50-b8ba-fa06902b113f',
  },
  prod: {
    WRSummaryReport: 'f016ba7f-6c8d-4300-80b4-53e687142e36',
    SessionReport: 'eafded24-7342-4135-bc02-3ef5186e9a20',
  }
};

