import {LogManager, autoinject, bindable} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {AudioFileModel, FetchAudioService} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
import {EmptyAudioAbstract} from '../abstracts/empty-audio-abstract';
import {computedFrom} from 'aurelia-binding';
import {BootstrapFormRenderer} from "../../../../../../_common/renderers/bootstrap-form-renderer";
import { ValidationController, ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';

const logger = LogManager.getLogger('PlayTextMessageDialog');

@autoinject
export class PlayTextMessageDialog {

  @bindable textMessage: string = '';

  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private nodeConfiguration: ZNodeConfig;
  private values: Array<string> = [];
  private canSave: boolean = false;
  private validation: ValidationController

  constructor(
    private dialogController: DialogController,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.textMessage = this.nodeData.properties["textMessage"];
    this.initValidations();
  }

  private initValidations(): void {
    ValidationRules
      .ensure('textMessage')
      .required()
      .on(this);
  }

  // Class-specific
  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.nodeData.properties["name"] = this.textMessage;
    this.nodeData.properties["textMessage"] = this.textMessage;
    this.nodeData.properties.isDefined = true;
    this.dialogController.ok(this.nodeData);
  }

  public mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: {connectorIndex: 0, nodeID: this.nodeData.id},
        customExtensions: null
      })
    ];
    return _connections;
  }

  @computedFrom('textMessage')
  get isValid(): boolean {
    return !!(this.textMessage);
  }

}
