import { inject, LogManager } from 'aurelia-framework';
/*
 */
import { BaseModel } from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('TrialAccountStatusModel');
/*
 */
export class TrialAccountStatusModel extends BaseModel {

  accountId = null;
  available = {
    amount: null,
    currency: null
  };
  daysRemaining = null;
  status = null;
  urgency = null;
  isTrialAccount = null;
  trialAccountStatus = null;
  balanceStatus = null;

  constructor(balance) {
    super();
    this.mapProperties(balance);
    if (balance.status) {
      if (balance.numberOfDaysRemaining) {
        this.daysRemaining = balance.numberOfDaysRemaining;
      }
      if (balance.amount) {
        this.available.amount = balance.amount;
        this.available.currency = balance.currency;
      }
      this.checkIsTrialAccount(balance.status);
      this.calculateUrgency(balance.status);
      this.getTrialStatusText(balance.status);
    }
    this.balanceStatus = this.available.amount > 0 ? 'high' : 'low';
  }

  checkIsTrialAccount(status) {
    if (status.toLowerCase().includes('trial')) {
      this.isTrialAccount = true;
    } else {
      this.isTrialAccount = false;
    }
  }

  calculateUrgency(status) {
    if (status.toLowerCase().includes('expired')) {
      this.urgency = 'high';
    }
    else if (status.toLowerCase() === 'trial') {
      if (this.daysRemaining > 10) {
        this.urgency = 'low';

      } else if (this.daysRemaining > 0) {
        this.urgency = 'medium';

      } else {
        this.urgency = 'high';
      }
    }
    else {
      this.urgency = 'low';
    }
  }

  getTrialStatusText(status) {
    if (status.toLowerCase().indexOf('expired') > -1) {
      this.trialAccountStatus = 'Trial Expired';

    } else if (status.toLowerCase().indexOf('trial') > -1) {
      this.trialAccountStatus = 'Trial Active';

    } else {
      this.trialAccountStatus = '';
    }
  }
}
