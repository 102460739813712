import {LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
import {ActivityContactModel} from './activity-contact-model';
import {ActivityInteractionModel} from './activity-interaction-model';
import {RecentActivityModel} from './recent-activity-model';
/*
 * */
const logger = LogManager.getLogger('ActivityResponseModel');
/*
 * */
export class ActivityResponseModel extends BaseModel {

  public activities: RecentActivityModel[];
  public totalActivities: number = 0;

  constructor() {
    super();
    this.activities = [];
  }

  public addActivity(activity: RecentActivityModel): void {
    this.activities.push(activity);
  }
}
