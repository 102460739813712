import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { SessionStore, OplogService, SESSION_EVENTS } from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('UserPassportService');

/*
 * */
@inject(HttpClient, EventAggregator, SessionStore, OplogService)
export class UserPassportService {
  constructor(httpClient, eventAggregator, sessionStore, oplogService) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.oplogService = oplogService;
  }

  subscribeToUserAccessRoleChanges() {
    this._refreshToken();
    const personId = this.sessionStore.get.user.personId;
    this.oplog = this.oplogService
      .subscribeOn('_id', personId)
      .in('passport-projector.loginView');
    this.oplog.on('insert', () => {
      this._refreshToken();
    });
    this.oplog.on('update', () => {
      this._refreshToken();
      this.eventAggregator.publish('PASSPORT:UPDATE');
    });
  }

  unsubscribeFromUserAccessRoleChanges() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  _refreshToken() {
    this.httpClient
      .createRequest('v1/user/passports/refresh-token')
      .asPut()
      .send()
      .then(
        (response) => {
          this.eventAggregator.publish('application.update.token', response);
        },
        (error) => {
          logger.warn('could not update accessToken >>', error);
        }
      )
      .catch((error) => {
        logger.warn('could not update accessToken >>', error);
      });
  }
}
