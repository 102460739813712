import { LogManager, computedFrom } from 'aurelia-framework';
import { InteractionModel } from '../conversation/interaction-model';

const logger = LogManager.getLogger('TaskModel');

export class TaskModel {
  public taskName: string = null;
  public title: string = null;
  public firstName: string = null;
  public surname: string = null;
  public email: string = null;
  public number: string = null;
  public type: string = null;
  public prospectId: string = null;
  public campaignName: string = null;
  public prospectListId: string = null;
  public prospectListName: string = null;
  public interactionId: string = null;
  public conversationId: string = null;
  public flowId: string = null;
  public interaction: InteractionModel = null;
  private isActive: boolean = false;
  private isSelected: boolean = false;

  public mapTaskInfo(info: InteractionModel): void {
    if(!info) {
      return;
    }
    this.taskName = info.payload.taskName;
    this.number = info.payload.sourceNumber;
    this.type = info.payload.type;

    if (info.payload.contact) {
      this.firstName = info.payload.contact.firstName;
      this.surname = info.payload.contact.surname;
      this.email = info.payload.contact.emailAddress;
      this.title = info.payload.contact.title;
    }

    if (info.payload.metadata) {
      this.prospectId = info.payload.metadata.prospectId;
      this.campaignName = info.payload.metadata.campaignName;
      this.prospectListId = info.payload.metadata.prospectListId;
      this.prospectListName = info.payload.metadata.prospectListName;
    }

    this.interactionId = info.payload.interactionId;
    this.flowId = info.payload.outboundInteractionFlowId && info.payload.outboundInteractionFlowId !== '' ? info.payload.outboundInteractionFlowId : null;
  }

  @computedFrom('title', 'firstName', 'surname')
  get _fullName(): string {
    if (!this.firstName && !this.surname) {
      return '(No Name)';
    }
    if (!this.firstName && this.surname) {
      return this.surname;
    }
    if (this.firstName && !this.surname) {
      return this.firstName;
    } else {
      return this.firstName + ' ' + this.surname;
    }
  }

  @computedFrom('type')
  get taskDescription(): string {
    if (this.type) {
      return this.type.toLowerCase() === 'campaign' ? 'CAMPAIGN PROSPECT' : 'CALLBACK REQUESTED';
    }
    return null;
  }
}
