
class ViewState {

  public static LIVE: string = 'LIVE';
  public static OVERVIEW: string = 'OVERVIEW';

  private selected = ViewState.LIVE;

  public get isLive(): boolean {
    return this.selected === ViewState.LIVE;
  }

  public get isOverview(): boolean {
    return this.selected === ViewState.OVERVIEW;
  }

  public toggleSelected(): void {
    if (this.isLive) {
      this.selectOverview();
    } else if (this.isOverview) {
      this.selectLive();
    }
  }

  private selectLive(): void {
    this.selected = ViewState.LIVE;
  }

  private selectOverview(): void {
    this.selected = ViewState.OVERVIEW;
  }
}

export { ViewState };
