import {LogManager, autoinject} from 'aurelia-framework';
/**/
import {SessionStore} from 'zailab.common';
import {TelephoneNumberModel} from '../../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import {CallForwardModel} from '../../models/call-forward-model';

const logger = LogManager.getLogger('TelephoneNumberService');

interface INumberType {
  description: string;
  value: string;
}

@autoinject
export class TelephoneNumberService {

  constructor(private sessionStore: SessionStore) {}

  /*
 * Extracts/formats the telephone number from the 'callForward' payload from the backend. */
  public extractTelephoneNumber(callForward: CallForwardModel): TelephoneNumberModel {

    let countryCode = this.sessionStore.get.organisation.country.code;
    let telephoneNumber = new TelephoneNumberModel('', '', countryCode, true);

    if (callForward.numberType !== 'NUMBER') {
      return telephoneNumber;
    }

    telephoneNumber.number = callForward.number;
    telephoneNumber.isNumberValid = true;

    return telephoneNumber;
  }

  /*
   * Retrieves the telephone number types displayed in the telephone 'number types' dropdown. */
  public getTelephoneNumberTypes(): INumberType[] {
    return [{description: 'Number', value: 'NUMBER'}, {description: 'Extension', value: 'EXT'}];
  }

  public getSelectedNumberType(numberType: string): INumberType {

    let numberTypes = this.getTelephoneNumberTypes();

    if(!numberType) {
      return numberTypes[0];
    }

    return numberTypes.find(type => type.value === numberType);
  }

}