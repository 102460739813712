export interface AgentScriptAdminConfigModel {
    id: string,
    name: string,
    workTypeIds: string[]
}

export function mapAgentScriptAdminConfigModel(data: any): AgentScriptAdminConfigModel {
  return {
    id: data.id,
    name: data.name,
    workTypeIds: data.workTypeIds,
  }
}
