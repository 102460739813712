import {LogManager, inject, customElement, bindable, computedFrom, PLATFORM} from 'aurelia-framework';
import {EventAggregator}from 'aurelia-event-aggregator';
/*
 */
import {PictureService} from 'zailab.common';
import {PictureStore} from "./picture-store";
import {FeatureFlagService} from '../../../features/featureflags/feature-flag-service';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';
/*
 */
const logger = LogManager.getLogger('ZProfilePicture');
/*
 */
@customElement('z-profile-picture')
@inject(Element, PictureService, EventAggregator, PictureStore, FeatureFlagService, ZaiFileLoader)
export class ZProfilePicture {

  static profilePictureDisabled;

  @bindable({attribute: 'picture-id'}) pictureId;
  @bindable({attribute: 'default-picture'}) defaultPicture;
  @bindable({attribute: 'picture-style'}) pictureStyle;
  @bindable warning;
  @bindable editable;
  @bindable url;
  @bindable color;
  @bindable round;

  constructor(element, pictureService, eventAggregator, pictureStore, featureFlagService, zaiFileLoader) {
    this.element = element;
    this.pictureService = pictureService;
    this.pictureStore = pictureStore;
    this.eventAggregator = eventAggregator;
    this.featureFlagService = featureFlagService;
    this.zaiFileLoader = zaiFileLoader;

    this.eventAggregator.subscribe('change.picture', payload => this.updateCache(payload));
  }

  bind() {
    if (ZProfilePicture.profilePictureDisabled === undefined) {
      ZProfilePicture.profilePictureDisabled = null;
      this.featureFlagService.isEnabled('profilePicture')
        .then((profilePictureDisabled) => {
          ZProfilePicture.profilePictureDisabled = profilePictureDisabled;
        });
    }
  }

  attached() {
    this.defaultPicture = ZProfilePicture.profilePictureDisabled ? PLATFORM.moduleName('../../../_assets/img/profile_pic-holder.png') : this.defaultPicture || PLATFORM.moduleName('../../../_assets/img/profile_pic-holder.png');
  }

  pictureIdChanged() {

    if(this.pictureId && !ZProfilePicture.profilePictureDisabled) {
      this.pictureService.retrieveImage(this.pictureId).then(pictureUrl => {
        this.url = pictureUrl;
        this.updateCache({
          id: this.pictureId,
          data: pictureUrl
        });
      }).catch(error => {

      });
    }
  }

  urlChanged(_newValue) {
    this.url = _newValue
  }

  @computedFrom('url', 'pictureId', 'pictures')
  get pictureSource() {
    if(!this.url || !this.pictureId || this.pictureStore.count === 0) {
      return false;
    }

    if(this.url) {
      return this.url;
    }

    let src = this.pictureStore.getPictureById(this.pictureId);

    if(src) {
      return src;
    }
    return this.defaultPicture;
  }

  isPictureRetrievable() {
    return !this.pictureSource;
  }

  updateCache(payload){
    let _pictureid = payload.id;
    let _data = payload.data;

    this.url = _data;
    this.pictureStore.addPicture(_pictureid, _data);
  }

  edit() {

    this.element.dispatchEvent(
      new CustomEvent('edit', {
        bubbles: true,
        detail: {}
      })
    );
  }

  getImage(image) {
    const imageContent = this.zaiFileLoader.getFile(image);
    if (imageContent) {
      return imageContent;
    }
    return './_assets/img/svg/' + image + '.svg';
  }

}
