import { BootstrapFormRenderer } from 'zailab.common';
import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import CampaignService from '../../campaign-service';
import { ProspectListContentModel } from './prospect-list-content-model';
//@ts-ignore
import moment from 'moment';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('ProspectListDialog');

@autoinject
export class ProspectListDialog {
  private campaignId: string;
  private prospectListId: string;
  private page: number = 0;

  public tableHeaders: any = [
    {property: 'lineNumber', description: 'Line No'},
    {property: 'dateAdded', description: 'Date'},
    {property: 'title', description: 'Title'},
    {property: 'firstName', description: 'Firstname'},
    {property: 'surname', description: 'Surname'},
    {property: 'mobileNumberOne', description: 'Mobile_Number_One'},
    {property: 'mobileNumberTwo', description: 'Mobile_Number_Two'},
    {property: 'mobileNumberThree', description: 'Mobile_Number_Three'},
    {property: 'mobileNumberFour', description: 'Mobile_Number_Four'},
    {property: 'mobileNumberFive', description: 'Mobile_Number_Five'},
    {property: 'mobileNumberSix', description: 'Mobile_Number_Six'},
    {property: 'mobileNumberSeven', description: 'Mobile_Number_Seven'},
    {property: 'emails', description: 'Email'},
    {property: 'status', description: 'Status'}
  ];
  public customFieldHeaders: { description: string, disabled?: boolean }[] = [];
  public lastUpdatedCustomFieldHeaders: any[] = [];
  public prospects: Array<any>;
  public numberOfProspects: number;
  public attachedToDom: boolean;
  
  private validation: ValidationController;
  private showFilter: any = false;

  public statusList: ISearchStatus[] = [
    { name: 'Served', status: 'SERVED' },
    { name: 'Not Served', status: 'NOT_SERVED' },
    { name: 'Cleared', status: 'CLEARED' },
    { name: 'Stale', status: 'STALE' },
    { name: 'Requeued', status: 'REQUEUED' }
  ];
  public lastUpdatedStatusList: ISearchStatus[] = [...this.statusList];
  public fromDate: string;
  public toDate: string;
  public customFieldsFilter: { description: string, value: string }[] = [];
  public customHeaderToAdd: string;
  public firstNameOrSurname: string;
  public numberOrEmail: string;
  public currentDate = moment();

  constructor(
    private campaignService: CampaignService,
    private router: Router,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public async activate(params: any): Promise<void> {
    this.campaignId = params.campaignId;
    this.prospectListId = params.prospectListId;
    await this.retrieveHeaders();
    this.retrieveProspectList();
  }

  private async retrieveHeaders(): Promise<void> {
    await this.campaignService
    .retrieveProspectCustomHeaders(this.campaignId, this.prospectListId)
    .then(response => {
      this.customFieldHeaders = response.map(header => {
        return {
          property: header,
          description: header
        };
      });
      this.tableHeaders = this.tableHeaders.concat([...this.customFieldHeaders]);
    });
  }

  public attached(): void {
    this.attachedToDom = true;

    this.initValidation();
  }

  private retrieveProspectList(): void {
    const fromDate = this.fromDate || moment().subtract(4, 'months').format('YYYY-MM-DD');
    const toDate = this.toDate || moment().add(8, 'months').format('YYYY-MM-DD');
    
    this.campaignService
      .retrieveProspects(
        this.campaignId, 
        this.prospectListId,
        this.page,
        fromDate,
        toDate,
        this.firstNameOrSurname,
        this.numberOrEmail,
        this.statusList.map(status => {
          if (status.selected) {
            return status.status;
          }
          return null;
        }).filter(status => !!status),
        this.customFieldsFilter.filter(field => !!field.value)
      )
      .then((response) => this.handleProspectsRetrieved(response));
  }

  private async handleProspectsRetrieved(response: { payload: IProspectListContent[], totalElements: number }): Promise<void> {
    if (this.page === 0) {
      this.prospects = response.payload.map(prospect => new ProspectListContentModel(prospect));
      this.numberOfProspects = response.totalElements || 0;
    } else {
      this.prospects = this.prospects.concat(response.payload.map(prospect => new ProspectListContentModel(prospect)));
    }
  }

  private initValidation(): void {

    
    ValidationRules
      .customRule('dateFromBeforeDateTo', () => {
        if (!this.fromDate || !this.toDate) {
          return true;
        }
        var startDate = moment(this.fromDate);
        var endDate = moment(this.toDate);
        return (startDate <= endDate);
      }, 'The `Date From` cannot be after the `Date To`.');

    
    ValidationRules
      .customRule('dateDifferenceDoesntExceed30Days', () => {
        if (!this.fromDate || !this.toDate) {
          return true;
        }
        var startDate = moment(this.fromDate);
        var endDate = moment(this.toDate);
        return (
          startDate.diff(endDate, 'days') <= 30 &&
          startDate.diff(endDate, 'days') >= -30
        );
      }, 'The range from `Date From` to `Date To` cannot exceed 30 days.');
    
    ValidationRules
      .ensure('fromDate')
      .satisfiesRule('dateFromBeforeDateTo')
      .then()
      .satisfiesRule('dateDifferenceDoesntExceed30Days')
      .then()
      .satisfies(() => {
        var startDate = moment(this.fromDate);
        return (this.currentDate.isAfter(startDate));
      })
      .withMessage('The `Date From` cannot be a future date.')
      .ensure('toDate')
      .satisfiesRule('dateFromBeforeDateTo')
      .then()
      .satisfiesRule('dateDifferenceDoesntExceed30Days')
      .then()
      .satisfies(() => {
        var endDate = moment(this.toDate);
        return (this.currentDate.isAfter(endDate));
      })
      .withMessage('The `Date To` cannot be a future date.')
      .on(this);
  }

  public columnHasContent(property: string): boolean {
    if (property.indexOf('mobileNumber') < 0) {
      return true;
    }
    let prospectWithData = this.prospects.find(prospect => {
      return !!prospect[property];
    })
    return prospectWithData ? true : false;
  }

  public getLeftPosition(): void {
    if (!this.canShowMore) {
      return;
    }
    let gridContainer: any = document.querySelector('#prospectListGrid');
    let showMoreContainer: any = document.querySelector('#showMoreContainer');
    if (!gridContainer || !showMoreContainer) {
      setTimeout(() => {
        return this.getLeftPosition();
      }, 150);
      return;
    }
    showMoreContainer.style.left = (gridContainer.offsetWidth / 2) - (showMoreContainer.offsetWidth / 2) + 'px';
  }
  
  public openSearchFilter(): void {
    this.showFilter = !this.showFilter;
  }

  public searchByFilter(): void {
    this.lastUpdatedStatusList = [...this.statusList];
    this.lastUpdatedCustomFieldHeaders = [...this.customFieldHeaders];
    this.retrieveProspectList();
    this.showFilter = false;
  }

  public resetFilter(): void {
    this.statusList.forEach(status => status.selected = false);
    this.lastUpdatedStatusList = [...this.statusList];
    this.fromDate = '';
    this.toDate = '';
    this.customFieldsFilter = [];
    this.customFieldHeaders.forEach(field => field.disabled = false);
    this.lastUpdatedCustomFieldHeaders = [...this.customFieldHeaders];
    this.customHeaderToAdd = '';
    this.firstNameOrSurname = '';
    this.numberOrEmail = '';
    this.retrieveProspectList();
  }

  public discardChanges(): void {
    this.statusList = [...this.lastUpdatedStatusList];
    this.customFieldHeaders = [...this.lastUpdatedCustomFieldHeaders];
  }

  public cancelFilterChanges(): void {
    this.discardChanges;
    this.showFilter = false;
  }

  public selectStatus(status: ISearchStatus): void {
    status.selected = !status.selected;
  }

  public addCustomField(): void {
    let customField = this.customFieldHeaders.find(header => header.description === this.customHeaderToAdd);

    this.customFieldsFilter.push({ description: customField.description, value: '' });
    this.customHeaderToAdd = '';
    customField.disabled = true;
  }

  public showMore(): void {
    this.page++;
    this.retrieveProspectList();
  }

  public back(): void {
    this.router.navigateBack();
  }

  @computedFrom('prospects', 'prospects.length', 'numberOfProspects')
  public get canShowMore(): boolean {
    return this.prospects && this.numberOfProspects > 100 && this.numberOfProspects > this.prospects.length;
  }
}
