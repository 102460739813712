export class TextIngestionField {

  public locked = false;
  public enabled = true;
  public noLock = false;
  private bu: TextIngestionField;
  private def: TextIngestionField;

  constructor(
    public fieldName: string,
    public fieldSource: string,
    public fieldValue: string,
  ) { }

  public clone(): TextIngestionField{
    let field = new TextIngestionField(this.fieldName, this.fieldSource, this.fieldValue);
    field.enabled = this.enabled;
    field.locked = this.locked;
    field.noLock = this.noLock;
    field.bu = this.bu;
    field.def = this.def;
    return field;
  }

  public disable(): TextIngestionField {
    this.bu = Object.assign({}, this);
    this.enabled = false;
    this.fieldSource = '';
    this.fieldValue = '';
    return this;
  }

  public enable(): TextIngestionField {
    this.enabled = true;
    if (this.bu) {
      this.fieldSource = this.bu.fieldSource;
      this.fieldValue = this.bu.fieldValue;
    }
    return this;
  }

  public clear(): void {
    this.fieldName = '';
    this.fieldSource = '';
    this.fieldValue = '';
  }

  public lock(def?: TextIngestionField): TextIngestionField {
    this.locked = true;
    if (!this.def) {
      this.def = Object.assign({}, def || this);
    } else {
      this.fieldSource = this.def.fieldSource;
      this.fieldValue = this.def.fieldValue;
    }
    return this;
  }

  public unlock(): TextIngestionField {
    this.locked = false;
    this.fieldSource = '';
    this.fieldValue = '';
    return this;
  }
}