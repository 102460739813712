import {LogManager, customElement, bindable, inject} from 'aurelia-framework';

// todo: (phillip) confirm this component usage. This is a non existent import
import {ZSwitchRadioInterface} from './z-switch-radio-interface';
/*
 */
const logger = LogManager.getLogger('ZSwitchRadio');

/*
 */
@customElement('z-switch-radio')
@inject(Element)
export class ZSwitchRadio {

  @bindable options: ZSwitchRadioInterface[] = [];
  @bindable initial: string;

  private initialSelection: string;

  constructor(private element: Element) {
  }

  public bind(): void {
    this.options = JSON.parse(JSON.stringify(this.options)); // prevent shallow binding
    this.setInitialValue(this.initial);
  }

  private setInitialValue(_initial:string):void {
    this.options.forEach(option => {
      if (option.label === _initial) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
    })
  }

  private select(selectedOption: ZSwitchRadioInterface): void {
    this.options.forEach(option => {
      option.isSelected = false;
    });
    selectedOption.isSelected = true;
    // Tell the parent which option is selected
    this.element.dispatchEvent(new CustomEvent('radioselected', {
      bubbles: true,
      detail: {
        active: selectedOption.label
      }
    }));
  }
}