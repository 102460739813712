import Croppie from 'croppie';
import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {DialogController} from 'aurelia-dialog';
/*
*/
import {PictureService} from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('ChangeProfilePicture');
/*
*/
@inject(EventAggregator, DialogController, PictureService)
export class ChangeProfilePicture {

  pictureId = null;
  defaultPicture = null;
  imageUrl;
  ready = false;

  hasImage;
  submitted;
  dialogHeader = 'change profile picture';
  imageName;
  validationMessage = '';
  file;

  constructor(eventAggregator, controller, pictureService) {

    this.eventAggregator = eventAggregator;
    this.controller = controller;
    this.pictureService = pictureService;
  }

  activate(picture) {

    if(picture.length) {
      this.pictureId = picture[0].pictureId;
      this.defaultPicture = picture[0].defaultPicture;
    } else {
      this.pictureId = picture.pictureId;
      this.defaultPicture = picture.defaultPicture;
    }
  }

  attached() {
    this.initImageCropper();
    this.retrievePicture();
  }

  initImageCropper() {

    this.imageCropper = new Croppie(this.filePreview, {
      viewport: {
        width: 210,
        height: 210,
        type: 'square' //default 'square'
      },
      boundary: {
        width: 210,
        height: 210
      },
      customClass: '', //imageCropper
      enableZoom: true, //default true // previously showZoom
      showZoomer: true, //default true
      mouseWheelZoom: true, //default true
      update: function(cropper) { }
    });

    this.imageCropper.setZoom(0);
  }

  retrievePicture() {

    if(!this.pictureId) {
      this.initImage();
      return;
    }
    this.pictureService.retrieveImage(this.pictureId).then((response) => {

      this.imageUrl = response;
      this.initImage();
    }).catch((error) => {
      this.initImage();
    });
  }

  initImage() {

    this.imageCropper.bind({
      url: this.imageUrl || this.defaultPicture
    });

    this.ready = true;
  }

  selectPicture(evt) {

    this.file = evt.target.files[0];

    if(!this.file || !this.file.name || !this._isValid().extension() || !this._isValid().size()) {
      return;
    }

    let reader = new FileReader();

    this.imageName = this.file.name;

    reader.onload = event => {
      this.imageCropper.bind(event.target.result);
      this.hasImage = true;
    };

    reader.readAsDataURL(this.file);
  }

  _isValid() {

    const _extension = () => {

      let extension = this.file.name.split('.');
      extension = extension[extension.length - 1];
      extension = extension.toLowerCase();

      if(extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
        this.validationMessage = 'Please select jpg or png file.';
        return false;
      }
      this.validationMessage = '';
      return true;
    }

    const _size = () => {

      let maxSize = 350 * 1024;

      if(this.file.size >= maxSize) {
        this.validationMessage = 'Please select jpg or png file that does not exceed 350kb.';
        return false;
      }
      this.validationMessage = '';
      return true;
    }

    return {
      extension: _extension,
      size: _size
    }
  }

  change() {

    if(!this.file || !this.file.name || !this._isValid().extension() || !this._isValid().size()) {
      return;
    }

    this.submitted = true;

    this.imageCropper.result('canvas').then(imgBase64 => {
      this.controller.ok(imgBase64);
      this.submitted = false;
    });
  }

  cancel() {
    this.controller.cancel();
  }
}
