import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import {
  Redirect,
  Router,
  RouterConfiguration,
  RouteConfig,
  NavigationInstruction,
  Next,
  RedirectToRoute,
} from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-http-client';
import { computedFrom } from 'aurelia-binding';

import {
  MESSAGE_EVENTS,
  SessionStore,
  SESSION_EVENTS,
  OplogService,
  DisplayMessageService,
} from 'zailab.common';
import { WebSocket } from './_common/services/websocket';
import { ApplicationProperties } from './_config/application.properties';
import { LoginService } from './features/user/passport/login/login-service';
import { VersionService, TabVisibilityHandler } from 'zailab.common';
import { LogAppender } from './_common/services/unhandled.error.service';
import { WindowService } from './_common/services/window.service';
import HttpInterceptor from './_config/http-interceptor';
import { PassportStore } from './_common/stores/passport-store';
import { AppRoutes } from './app-routes';
import { UserSessionModel } from './_common/stores/sessionmodels/user-session-model';

import 'bootstrap';
import 'file-saver';
import 'conversations-vendor';
import { PictureStore } from './components/atoms/images/picture-store';
import { FeatureFlagService } from './features/featureflags/feature-flag-service';
import { DiagnosticsState } from './features/diagnostics/diagnostics-state';
import { AutoLogoutNotifierService } from './features/user/passport/auto-logout-notification-service';

import './index.scss';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';

import '../node_modules/jquery/dist/jquery.min.js';
import { AlertSoundService } from './features/media/alert-sound-config/alert-sound-service';

const logger = LogManager.getLogger('App');

@autoinject()
export class App {
  private router: Router;

  constructor(
    private applicationProperties: ApplicationProperties,
    private http: HttpClient,
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    public webSocket: WebSocket,
    private loginService: LoginService,
    private windowService: WindowService,
    private logAppender: LogAppender,
    private oplogService: OplogService,
    private displayMessageService: DisplayMessageService,
    private versionService: VersionService,
    private passportStore: PassportStore,
    private tabVisibilityHandler: TabVisibilityHandler,
    private pictureStore: PictureStore,
    private featureFlagService: FeatureFlagService,
    private diagnosticsState: DiagnosticsState,
    private autoLogoutNotifierService: AutoLogoutNotifierService,
    private alertSoundService: AlertSoundService,
    public zaiFileLoader: ZaiFileLoader
  ) {
    logAppender.registerHandlers();
    LogManager.addAppender(logAppender); // added here instead of main.js because of event aggregator
    this.subscribeEvents();
    this.alertSoundService.init();
  }

  async attached(): Promise<void> {
    this.subscribeToBodyClick();
    this.zaiFileLoader.log();
    this.showChatElement('.arn-widget-btn');

    if (this.user) {
      this.showChatElement('#arn-widget-container');
    } else {
      await this.showChatElement('#arnBarWidgetDiv');
      this.subsbribeToWidgetClickEvents();
    }
  }

  private showChatElement(selector: string): Promise<void> {
    return new Promise((resolve) => {
      let getElelent = (): void => {
        let el: HTMLElement = document.querySelector(selector);
        if (!el) {
          setTimeout(() => {
            getElelent();
          }, 1000 / 3);
          return;
        }
        el.setAttribute(
          'style',
          'opacity: 1 !important; z-index: 99000!important'
        );
        resolve();
      };
      getElelent();
    });
  }

  private hideChatElement(selector: string): Promise<void> {
    return new Promise((resolve) => {
      let getElelent = (): void => {
        let el: HTMLElement = document.querySelector(selector);
        if (!el) {
          setTimeout(() => {
            getElelent();
          }, 1000 / 3);
          return;
        }
        el.setAttribute(
          'style',
          'display: none !important; opacity: 0 !important;'
        );
        resolve();
      };
      getElelent();
    });
  }

  public subsbribeToWidgetClickEvents(): void {
    let element = document.querySelector('#arnBarWidgetDiv');
    element.addEventListener('click', (event: any) => {
      const rect = element.getBoundingClientRect();
      const distanceFromRight = rect.right - event.clientX;

      // Check if the click is within 40px from the right side
      if (distanceFromRight <= 40) {
        this.hideChatElement('#arnBarWidgetDiv');
      } else {
        window
          .open('https://zaihelp-new.release.page/release-notes', '_blank')
          .focus();
      }
    });
  }

  subscribeToBodyClick(): void {
    document.addEventListener('click', (event) => {
      this.eventAggregator.publish('document.body.clicked', event);
    });
  }

  subscribeEvents(): void {
    this.autoLogoutNotifierService.initialiseAutoLogout();

    this.eventAggregator.subscribe('application.update.token', (userData) => {
      this.updateUserData(userData);
    });

    // this.eventAggregator.subscribe('router:navigation:processing', (data, event) => {
    // });

    this.eventAggregator.subscribe(
      'router:navigation:complete',
      (data, event) => {
        this.windowService.disableDoubleClick();
        this.windowService.enableCapslockDetection();
      }
    );

    // this.eventAggregator.subscribe('router:navigation:error', (data, event) => {
    //   // this.router.navigateToRoute('login');
    // });

    this.eventAggregator.subscribe(
      MESSAGE_EVENTS.ERROR_UNHANDLED,
      (message) => {
        // catch the root router error
        if (
          message ===
          'Router navigation failed, and no previous location could be restored.'
        ) {
          this.router.navigateToRoute('error', { code: 2 });
        }
      }
    );
  }

  private updateUserData(userData: any): void {
    let organisationId = '';

    userData.userAccessRoles.forEach((role) => {
      if (role.accountType === 'ORGANISATION') {
        organisationId = role.organisationId;
        this.eventAggregator.publish(SESSION_EVENTS.ORGANISATION.EVENT, {
          organisationId,
        });
      }
    });

    this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, userData);
    if (organisationId) {
      this.loginService.displayChannelsView(organisationId);
    }

    this.http.configure((req) => {
      // @ts-ignore
      req.withInterceptor(
        new HttpInterceptor(this.http, this.sessionStore, this.diagnosticsState)
      );
      req.withBaseUrl(this.applicationProperties.apiQueryEndpoint);
      req.withHeader(
        'Authorization',
        'Bearer ' + this.sessionStore.get.user.token
      );
      req.withHeader('Session', this.sessionStore.get.application.appId);
    });
    this.eventAggregator.publish('application.update.userAccess', {});
  }

  activate(): void {
    this.http.configure((req) => {
      // @ts-ignore
      req.withInterceptor(
        new HttpInterceptor(this.http, this.sessionStore, this.diagnosticsState)
      );
      req.withBaseUrl(this.applicationProperties.apiQueryEndpoint);
      const user = this.sessionStore.get.user;
      const token = user ? 'Bearer ' + user.token : 'Bearer ';
      req.withHeader('Authorization', token);
      req.withHeader('Session', this.sessionStore.get.application.appId);
    });

    this.versionService.init();
  }

  refresh(): void {
    // @ts-ignore
    window.location.reload(true);
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    config.title = 'ZaiConversations';
    config.options.pushState = true;
    config.addPipelineStep('authorize', AuthStep);

    let routeConfigs: RouteConfig[] = AppRoutes.routes;

    config.map(routeConfigs);
    this.router = router;
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }
}

@autoinject()
class AuthStep {
  private showLoader: boolean;

  constructor(
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator
  ) {}

  run(navigationInstruction: NavigationInstruction, next: Next): any {
    const user: UserSessionModel = this.sessionStore.get.user;
    /*AUTH*/
    let isAuthRoute: boolean = navigationInstruction
      .getAllInstructions()
      .some((i) => i.config.auth);
    if (isAuthRoute) {
      if (!user) {
        return next.cancel(new RedirectToRoute('login'));
      }
    }

    /*BLOCKED*/
    let isBlocked: boolean = navigationInstruction
      .getAllInstructions()
      .some((i) => i.config.isBlocked);
    if (isBlocked) {
      return next.cancel(new Redirect('/error/1'));
    }

    /*AUTHORISED*/
    let allInstructions: NavigationInstruction[] =
      navigationInstruction.getAllInstructions();
    let nextInstruction: NavigationInstruction = allInstructions[1];
    let access: any = nextInstruction
      ? nextInstruction.config.settings.access ||
        nextInstruction.config.settings
      : null;

    if (access && user && user.userAccessRoles) {
      let hasAccess = false;
      const roles = user.userAccessRoles;

      for (let role of access) {
        for (let item of roles) {
          if (role === item.role) {
            hasAccess = true;
          }
        }
      }
      if (!hasAccess) {
        return next.cancel(new Redirect('/error/2'));
      }
    }

    return next();
  }
}
