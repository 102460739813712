import {LogManager} from 'aurelia-framework';

import {BaseModel} from 'zailab.abstract';
import {ActivityContactModel} from './activity-contact-model';
import {ActivityInteractionModel} from './activity-interaction-model';

import moment from 'moment-timezone';

const logger = LogManager.getLogger('RecentActivityModel');

export class RecentActivityModel extends BaseModel {

  public contact: ActivityContactModel = null;
  public interaction: ActivityInteractionModel = null;
  public date: number = null;
  public isPlaceholder: boolean = false;

  constructor(response: RecentActivityModel) {
    super();
    this.mapProperties(response);
    this.contact = new ActivityContactModel(response.contact);
    this.interaction = new ActivityInteractionModel(response.interaction);
    this.interaction.timestamp = moment(this.date).format('x');
  }
}
