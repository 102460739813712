import { computedFrom } from 'aurelia-binding';
import {LogManager, autoinject} from 'aurelia-framework';
import {ZIWizard} from '../../../../../../../../../typings/zai/zai.common';
import {InteractionFlow} from "../../../../interaction-flow";
/**/
const logger = LogManager.getLogger('NumberOfRings');
/**/
@autoinject
export class Conversations {

  private showIncomingCallNotification: boolean = true;
  private showConversationCard: boolean = true;
  private wizard: ZIWizard;
  private isCCFlow : boolean;
  private screenPopUrl : string;
  private useEmbeddedIFrame : boolean;

  constructor(private interactionFlow: InteractionFlow) {
    this.isCCFlow = this.interactionFlow.interactionFlowType === 'Contact Centre Flow';
  }

  public activate(wizard: ZIWizard): void {

    this.initWizard(wizard);
    this.extractWizardData();
  }

  private initWizard(wizard: ZIWizard): void {

    this.wizard = wizard;

    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.screenPopUrl = (typeof(this.wizard.data.screenPopUrl) === 'undefined') ? null : this.wizard.data.screenPopUrl;
    this.useEmbeddedIFrame = (typeof(this.wizard.data.screenPopUrl) === 'undefined') ? true : this.wizard.data.useEmbeddedIFrame;
    this.showIncomingCallNotification = ((typeof(this.wizard.data.showIncomingCallNotification) === 'undefined') || (this.wizard.data.showIncomingCallNotification === null)) ? true : this.wizard.data.showIncomingCallNotification;
    this.showConversationCard = ((typeof(this.wizard.data.showConversationCard) === 'undefined') || (this.wizard.data.showConversationCard === null)) ? true : this.wizard.data.showConversationCard;
  }

  private toggleShowIncomingCallNotification(): void {
    this.showIncomingCallNotification = !this.showIncomingCallNotification;
  }

  private toggleShowConversationCard(): void {
    this.showConversationCard = !this.showConversationCard;
  }

  public toggleUseEmbeddedIFrame(): void {
    this.useEmbeddedIFrame = !this.useEmbeddedIFrame;
  }

  private updateWizardData(): void {
    this.wizard.data.showIncomingCallNotification = this.showIncomingCallNotification;
    this.wizard.data.showConversationCard = this.showConversationCard;
    this.wizard.data.screenPopUrl = this.screenPopUrl;
    this.wizard.data.useEmbeddedIFrame = this.useEmbeddedIFrame;
  }

  public screenPopUrlUpdated(event: { srcElement: { value: string }}): void {
    if (event.srcElement.value) {
      this.screenPopUrl = event.srcElement.value;
      this.wizard.data.screenPopUrl = event.srcElement.value;
    }
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isChat(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'Chat';
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isInstantMessage(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'Instant Message';
  }

}
