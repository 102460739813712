import { LogManager, inject } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";
import { Event } from '../../../../../../../../zailab.common';
/**/
const logger = LogManager.getLogger('Name');
/**/
@inject(Element, EventAggregator)
export class Name {

  private name: string;
  private timeout: number;
  private wizard: any;
  private maxCallTimeout: number;

  constructor(
    private element: Element,
    private eventAggregator: EventAggregator
  ) { }

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.validate();
  }

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  private initWizard(wizard: any): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.name = this.wizard.data.name;
    this.maxCallTimeout = this.wizard.data.maxCallTimeout;
    this.timeout = this.wizard.data.timeout || 0;
  }

  private updateWizardData(): void {
    this.wizard.data.name = this.name;
    this.wizard.data.timeout = this.timeout;
  }

  public triggerNameChange(): void {
    new Event(this.element, 'apicallnamechanged', this.name);
    this.validate();
  }

  public triggerTimeoutChange(): void {
    new Event(this.element, 'apicalltimeoutchanged', this.timeout);
    this.validate();
  }

  private validate(): void {
    if (!this.name || this.timeout < 0 || (!this.maxCallTimeout && this.timeout > 10000) || (this.maxCallTimeout && this.timeout > 60000)) {
      this.wizard.step.incomplete();
      return;
    }
    this.wizard.step.complete({ name: this.name, timeout: this.timeout });
  }

  private nextStep(): void {
    this.wizard.continue();
  }
}
