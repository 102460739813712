import {AccountAddressModel} from '../account-adress-model';
import {BaseModel} from 'zailab.abstract';

export class AddressErrorModel extends BaseModel{

  public addressLine1 = null;
  public city = null;
  public region = null;
  public code = null;

  constructor(companyInfo: any){
    super();
    this.mapProperties(companyInfo);
  }

  get hasError(): boolean {

    if(this.address || this.city || this.region || this.country || this.postalCode) {
      return true;
    }

    return false

  }

}