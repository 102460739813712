import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from 'aurelia-dialog';
import { LogManager } from 'aurelia-framework';
import { CallTranscriptModel } from "../../../organisation/conversation/call-transcript-model";
import InteractionLogModel from "../interactionlog/interaction-log-model";
const logger = LogManager.getLogger('ViewInteractionTranscriptChatDialog');

@autoinject
export class ViewInteractionTranscriptChatDialog {

  private dialogController: DialogController;
  public transcript: CallTranscriptModel;
  public interaction: InteractionLogModel;
  public dialogHeader = 'Interaction Chat Transcript';

  constructor(dialogController: DialogController) {
    this.dialogController = dialogController;
  }

  public activate(config: ZaiInteractionTranscript): void {
    this.transcript = config.transcript;
    this.interaction = config.interaction;
  }

  public replaceSpeaker(userId: string): string {
    const interaction: any = this.interaction;
    if (userId === 'true') {
      return this.interaction.formattedTo;
    } else {
      return this.interaction.formattedFrom;
    }
  }

  public cancel(): void {
    this.transcript = null;
    this.dialogController.cancel();
  }
}