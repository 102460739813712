import { autoinject, LogManager } from 'aurelia-framework';
import { ObserverLocator } from 'aurelia-binding';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

import { SessionStore, MESSAGE_EVENTS } from 'zailab.common';
import { DispositionCodesService } from '../disposition-codes-service';
import { BootstrapFormRenderer } from 'zailab.common';
import { DispositionModel, Disposition } from '../disposition-codes-model';
import { ZIOplog } from "../../../../../../typings/zai/zai.common";
import { EditTieredDispositionListState, state } from '../tiered/edit-tiered-disposition-list-state';

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import $ from 'jquery';

const logger = LogManager.getLogger('EditDispositionList');

@autoinject
export class EditDispositionList {

  private state: EditTieredDispositionListState = state;
  private ready: boolean = false;
  private dispositionListId: string;
  private validation: ValidationController;
  private dispositionList: DispositionModel;
  private eventSubscription: Subscription;
  private addState: boolean;

  private interactionOutcomeReasons: Array<IOutcome>;
  private conversationOutcomes: Array<IOutcome>;
  private learningContributions: Array<IOutcome> = [{ name: 'N/A' }, { name: 'Positive' }, { name: 'Neutral' }, { name: 'Negative' }];

  private description: string;
  private interactionOutcomeReason: IOutcome;
  private conversationOutcome: string;
  private learningContribution: string;

  private isValid: boolean;
  private dispositionCodesUniqueName: boolean = false;
  private oplog: ZIOplog;
  private validationCodes: Array<string> = ['DispositionList.enabled', 'DispositionCode.description.populated', 'DispositionCode.description.notUnique', 'DispositionCode.interactionOutcomeReason.populated', 'DispositionCode.conversationOutcome.populated', 'DispositionCode.learningContribution.populated', 'DispositionList.enabled.not.unique'];

  private params: any = null;

  constructor(private observerLocator: ObserverLocator, private dispositionCodesService: DispositionCodesService, private validationControllerFactory: ValidationControllerFactory, private sessionStore: SessionStore, private router: Router, private eventAggregator: EventAggregator) {
    this.initialiseValidation();
    this.addVariableObservers();
  }

  public activate(params: { dispositionlist: string; }): void {
    this.params = params;
    this.dispositionListId = params.dispositionlist;
  }

  public attached(): void {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.initialiseOplog(organisationId);
    this.retrieveData();
  }

  private initialiseValidation(): void {
    this.validation = this.validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let list of this.dispositionList.dispositionCodes) {
          if (list.description.toLowerCase() === value.toLowerCase()) {
            this.dispositionCodesUniqueName = false;
            return false;
          }
        }
        this.dispositionCodesUniqueName = true;
        return true;
      }, null);

    ValidationRules
      .customRule('hasSelectedValue', (value) => {
        return !!value;
      }, null);

    ValidationRules
      .ensure('description')
      .required().withMessage('Please enter a code description.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique disposition description.')
      .on(this);
  }

  private addVariableObservers(): void {
    this.observerLocator
      .getObserver(this, 'description')
      .subscribe(() => this.validateVariableChanges());

    this.observerLocator
      .getObserver(this, 'interactionOutcomeReason')
      .subscribe(() => { this.description && this.validateVariableChanges() });

    this.observerLocator
      .getObserver(this, 'conversationOutcome')
      .subscribe(() => { this.description && this.validateVariableChanges() });

    this.observerLocator
      .getObserver(this, 'learningContribution')
      .subscribe(() => { this.description && this.validateVariableChanges() });
  }

  private validateVariableChanges(): void {
    this.validation.validate().then(validation => {
      if (validation.valid && this.hasValidFormData()) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    })
      .catch(e => { });
  }

  private hasValidFormData(): boolean {
    return (this.dispositionList.direction !== 'Outbound' || this.interactionOutcomeReason) && this.conversationOutcome && this.learningContribution && this.hasUniqueName();
  }

  private hasUniqueName(): boolean {
    if (this.description && this.dispositionCodesUniqueName) {
      return true;
    }
  }

  private retrieveData(): void {
    this.retrieveDispositionList();
    this.retrieveInteractionOutcomeReasons();
    this.retrieveConversationOutcomes();
  }

  private async retrieveDispositionList(): Promise<any> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.dispositionList = await this.dispositionCodesService.retrieveDispositionList(organisationId, this.dispositionListId);

    try {

      if (this.isTiered) {

        let dispositionCodes = [];

        const dispositionList = new DispositionModel(this.dispositionList);

        const dispositionListCategories = dispositionList.categories
          .find((c) => c.name === this.params.category);
        if (dispositionListCategories) {

          const dispositionListCategoriesSubcategories = dispositionListCategories.subcategories
            .find((s) => s.name === this.params.subcategory);
          if (dispositionListCategoriesSubcategories) {

            const dispositionCodeIds = dispositionListCategoriesSubcategories.dispositionCodeIds;

            dispositionCodes = dispositionList.dispositionCodes
              .filter((dispositionCode) => dispositionCodeIds.indexOf(dispositionCode.dispositionCodeId) !== -1);
          }
        }

        dispositionList.dispositionCodes = dispositionCodes;
        
        this.dispositionList = dispositionList;

        this.ready = true;
      } else {

        if (this.dispositionList.tiered) {
          this.state.saveModel(this.dispositionListId, this.dispositionList);
          this.router.navigate(`edit-tier1-list/${this.dispositionListId}`);
        } else {
          this.ready = true;
        }
      }

    } catch (e) {
      logger.error('retrieveDispositionList : error=', e);
    }
  }

  private async retrieveInteractionOutcomeReasons(): Promise<any> {
    try {
      let organisationId = this.sessionStore.get.organisation.organisationId;
      this.interactionOutcomeReasons = await this.dispositionCodesService.retrieveInteractionOutcomeReasons(organisationId);
    } catch (e) {
      this.interactionOutcomeReasons = [];
    }
  }

  private async retrieveConversationOutcomes(): Promise<any> {
    try {
      this.conversationOutcomes = await this.dispositionCodesService.retrieveConversationOutcomes();
    } catch (e) {
      this.conversationOutcomes = [];
    }
  }

  private enableAddState(): void {
    this.addState = true;
    this.focus('#js-dispositionDescription');
  }
  private disableAddState(): void {
    this.addState = false;
    this.description = null;
    this.interactionOutcomeReason = null;
    this.conversationOutcome = null;
    this.learningContribution = null;
  }

  private focus(elementId: string): void {
    let element: HTMLElement = document.querySelector(elementId);
    if (!element) {
      setTimeout(() => this.focus(elementId), 250);
      return;
    }
    element.focus();
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  private addDisposition(): void {
    if (this.dispositionList.enabled) {
      return;
    }

    const dispositionCode = {
      dispositionCodeId: uuidv4(),
      description: this.description,
      interactionOutcomeReason: this.interactionOutcomeReason ? this.interactionOutcomeReason.name : '',
      //@ts-ignore
      conversationOutcome: this.conversationOutcome ? this.conversationOutcome.name : '',
      //@ts-ignore
      learningContribution: this.learningContribution ? this.learningContribution.name : '',
      category: this.params.category,
      subcategory: this.params.subcategory
    };
    let organisationId = this.sessionStore.get.organisation.organisationId;

    if (this.dispositionList.direction !== 'Outbound') {
      dispositionCode.interactionOutcomeReason = 'N/A';
    }

    this.dispositionList.dispositionCodes.push(dispositionCode);
    this.disableAddState();
    this.subscribeToValidationError((message: IValidationError) => {
      //@ts-ignore
      if (this.dispositionList.dispositionCodes.indexOf(dispositionCode) > -1 && this.validationCodes.includes(message.state.globalError.code)) {
        this.dispositionList.dispositionCodes.splice(this.dispositionList.dispositionCodes.indexOf(dispositionCode), 1);
      }
    });
    this.dispositionCodesService.addDispositionCodeToDispositionList(organisationId, this.dispositionListId, dispositionCode);
    this.smoothScrollToBottom();
  }

  private subscribeToValidationError(callback: (message: IValidationError) => void): void {
    this.eventSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', (message: IValidationError) => {
      callback(message);
      this.eventSubscription && this.eventSubscription.dispose();
    });
  }

  private removeDispositionCode(dispositionCode: Disposition): void {
    if (this.dispositionList.enabled) {
      return;
    }
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.dispositionCodesService.removeDispositionCodeFromDispositionList(organisationId, this.dispositionListId, dispositionCode.dispositionCodeId);
  }

  private initialiseOplog(organisationId: string): void {
    this.dispositionCodesService.initialiseOplog(organisationId).then(oplog => this.subscribeToOplogChanges(oplog));
  }

  private subscribeToOplogChanges(oplog: ZIOplog): void {
    this.oplog = oplog;
    this.oplog.on('insert', () => this.handleOplogChanged());
    this.oplog.on('update', () => this.handleOplogChanged());
    this.oplog.on('delete', () => this.handleOplogChanged());
  }

  private handleOplogChanged(): void {
    this.retrieveData();
  }

  private cancel(): void {
    this.disableAddState();
  }

  public navigateDone(): void {
    this.router.navigate('');
  }

  public navigateTier1(): void {
    this.router.navigateBack();
    this.router.navigateBack();
  }

  public navigateTier2(): void {
    this.router.navigateBack();
  }

  public deactivate(): void {
    this.oplog && this.oplog.unsubscribe();
    this.eventSubscription && this.eventSubscription.dispose();
    this.observerLocator.getObserver(this, 'description').unsubscribe(() => { });
    this.observerLocator.getObserver(this, 'interactionOutcomeReason').unsubscribe(() => { });
    this.observerLocator.getObserver(this, 'conversationOutcome').unsubscribe(() => { });
    this.observerLocator.getObserver(this, 'learningContribution').unsubscribe(() => { });
  }

  public get isTiered(): boolean {
    return this.params.category && this.params.subcategory;
  }

  public get heading(): string {
    if (!this.dispositionList) {
      return;
    }
    if (this.isTiered) {
      return `${this.params.category} - ${this.params.subcategory} [${this.dispositionList.name}]`;
    }
    return this.dispositionList.name;
  }
}

interface IOutcome {
  name: string;
  isSelected?: boolean;
}

interface IValidationError {
  state: {
    globalError: {
      code: string;
    }
  }
}