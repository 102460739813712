import {autoinject, bindable, customElement, LogManager, observable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

import moment from 'moment-timezone';
import timezone from 'countries-and-timezones';

import {ArrayTools, MESSAGE_EVENTS} from 'zailab.common';
import {ChartSettingsBuilder} from './chart-settings-builder';
import {TotalInteractionsModel} from './models/total-interactions-model';
import {DataPointModel} from './models/data-point-model';
import {GraphConfig} from './graph-config';
import {GRAPH_COLOR_NAMES, GRAPH_COLORS} from './graph-colors';
import {DATE_VALIDATION_MESSAGES} from './graph-validations';
import {ServiceWithTaskTemplatesModel} from './models/service-with-task-template-model';
import {TaskTemplateModel} from './models/task-template-model';
import {FilterByModel} from './models/filter-by-model';
import {GroupByModel} from './models/group-by-model';
import {LiveDashboardService} from '../../../features/interaction/dashboard/live-dashboard/live-dashboard-service';
import {computedFrom} from "aurelia-binding";
import {INTERACTION_TYPES} from './interaction-types';
import {TotalCountsModel} from './models/total-counts-model';

const logger = LogManager.getLogger('ZChart');

declare let AmCharts: any;

@customElement('z-chart')
@autoinject
export class ZChart {

  protected chart: any = null;
  protected interactionsData: TotalInteractionsModel;
  protected formattedInteractionsData: Array<TotalCountsModel> = [];

  protected currentDate: moment = null;
  protected currentTime: moment = null;
  @observable protected fromDate: moment = null;
  protected selectedFromDate: moment = null;
  @observable protected toDate: moment = null;
  protected selectedToDate: moment = null;
  protected displayDateFormat: string = 'ddd, DD MMM YYYY';
  protected restDateFormat: string = 'ddd, DD MMM YYYY HH:mm:ss [GMT]';

  protected filterOptions: FilterByModel[] = [];

  protected selectedFilterOption: FilterByModel;
  protected groupByOptions: GroupByModel[] = [];
  protected selectedGroupByOption: GroupByModel;
  protected ready: boolean = false;
  @bindable({attribute: 'selected-service'}) protected selectedService: ServiceWithTaskTemplatesModel = null;
  @bindable({attribute: 'selected-work-type'}) protected selectedWorkType: TaskTemplateModel = null;
  @bindable({attribute: 'selected-timezone'}) protected selectedTimezone: ITimezone = null;
  @bindable({attribute: 'graph-resource'}) protected graphResource: string;
  @bindable private filters: string[];

  constructor(protected eventAggregator: EventAggregator, protected liveDashboardService: LiveDashboardService) {
    this.liveDashboardService = liveDashboardService;
  }

  private created(): void {
    this.groupByOption();
    this.setDateAndTime();
    this.disableGroupByOptions('Day', 'Week', 'Month');
  }

  private attached(): void {
    this.addFilters();
    this.createGraph();
  }

  @computedFrom('graphResource')
  private get graphTitle(): string {
    return this.graphResource.replace(/-/g, ' ');
  }

  private selectedServiceChanged(): void {
    this.createGraph();
  }

  private selectedWorkTypeChanged(): void {
    this.createGraph();
  }

  private selectedTimezoneChanged(): void {
    this.createGraph();
  }

  private selectedFilterOptionChanged(): void {
    if (!this.selectedFilterOption) {
      this.addFilters();
    }
    this.createGraph();
  }

  private groupByOption(): void {
    this.groupByOptions.push(new GroupByModel('Hour', true, false, 40, 24));
    this.groupByOptions.push(new GroupByModel('Day', false, true, 120, 7));
    this.groupByOptions.push(new GroupByModel('Week', false, true, 100, 14));
    this.groupByOptions.push(new GroupByModel('Month', false, true, 110, 12));
    this.selectedGroupByOption = this.groupByOptions.find(option => {
      return option.selected;
    });
  }

  private addFilters(): void {
    let defaultFilterOption: FilterByModel = new FilterByModel({name: '', selected: true, isDefault: true});
    if (this.filterOptions.length === 0) {
      this.filterOptions.push(new FilterByModel(defaultFilterOption));
    }

    if (this.filters && this.filters.length > 0) {
      this.filters.forEach(option => {
        this.filterOptions.push(new FilterByModel({name: option, selected: false}))
      });
    }
    this.selectedFilterOption = this.filterOptions[0];
  }

  protected createGraph(): void {
    if (!this.graphResource || !this.selectedTimezone || !this.selectedGroupByOption || !this.selectedService || !this.selectedFilterOption) {
      return;
    }

    let serviceId: string = this.selectedService ? this.selectedService.serviceId : '';
    let workTypeId: string = this.selectedWorkType ? this.selectedWorkType.id : '';
    let filterOption = this.selectedFilterOption.name;

    if (this.selectedFilterOption.name === 'Calls') {
      filterOption = 'Voice';
    } else if (this.selectedFilterOption.name === 'Voicemail') {
      filterOption = 'Mailbox';
    } else if (this.selectedFilterOption.name === 'Callbacks Requested') {
      filterOption = 'Callback';
    }

    this.liveDashboardService.retrieveGraphData(this.graphResource + '-interactions', serviceId, workTypeId || '', this.selectedFromDate, this.selectedToDate, this.selectedTimezone.name, this.selectedGroupByOption.name.toLowerCase(), filterOption).then((response) => {
      this.interactionsData = response;
      this.addChart();
      this.ready = true;
    });
  }

  protected setDateAndTime(): void {
    this.currentDate = moment().utc().format(this.displayDateFormat);
    this.currentTime = moment.utc().format('HH:mm:ss');
    this.fromDate = this.currentDate;
    this.toDate = this.currentDate;
    this.selectedFromDate = moment.utc(this.fromDate).format(this.restDateFormat);
    this.selectedToDate = moment.utc(this.toDate).format(this.restDateFormat);
  }

  private addChart(): void {
    let chartBuilder: ChartSettingsBuilder = new ChartSettingsBuilder();
    this.setupChart(chartBuilder);
    let chartId = `js-${this.graphResource}-interactions`;
    this.chart = AmCharts.makeChart(chartId,
      chartBuilder
        .withMinimumPeriod(this.selectedGroupByOption.name)
        .withDateFormats(this.selectedGroupByOption.name)
        .build());
    this.chart.addListener('rendered', () => this.zoomChart());
    this.zoomChart();
  }

  private zoomChart(): void {
    if (this.chart) {
      this.chart.zoomToIndexes(this.chart.dataProvider.length - this.selectedGroupByOption.zoom, this.chart.dataProvider.length - 1);
    }
  }

  private selectGroupByOption(selectedOption: GroupByModel): void {
    this.groupByOptions.filter(item => {
      item.selected = item.equals(selectedOption);
      this.selectedGroupByOption = selectedOption;
    });
    this.createGraph();
  }

  private selectGroupByOptionByName(name: string): void {
    this.selectGroupByOption(this.groupByOptions.find(item => item.name === name));
  }

  private selectFilterOption(selectedItem: any): void {
    this.filterOptions.filter(item => {
      this.selectedFilterOption = selectedItem;
      return item.selected = selectedItem === item;
    });
    this.createGraph();
  }

  private fromDateChanged(newValue: any, oldValue: any): void {
    if (!moment.utc(newValue || oldValue).isValid()) {
      return;
    }

    let previousDate = moment(oldValue);
    let newDate = moment(newValue);

    if (newDate.isAfter(this.currentDate)) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, DATE_VALIDATION_MESSAGES.PAST_DATE);
      setTimeout(() => {
        this.fromDate = oldValue;
      }, 20);
      return;
    }

    if (newDate.isAfter(this.toDate)) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, DATE_VALIDATION_MESSAGES.INVALID_FROM_DATE);
      setTimeout(() => {
        this.fromDate = oldValue;
      }, 20);
      return;
    }

    let timeDifferenceInDays = moment.duration(moment(this.toDate).diff(newDate)).asDays();
    let daysInCurrentYear = moment(this.toDate).isLeapYear() ? 366 : 365;

    if (timeDifferenceInDays > daysInCurrentYear) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, DATE_VALIDATION_MESSAGES.DURATION_LIMIT_REACHED);
      setTimeout(() => {
        this.fromDate = oldValue;
      }, 20);
      return;
    }

    if (previousDate.diff(newDate)) {
      this.selectedFromDate = moment(newDate).format(this.restDateFormat);
      this.timeScaleSelectionStrategy(this.toDate, newDate);
    }
  }

  private toDateChanged(newValue: any, oldValue: any): void {
    if (!moment.utc(newValue || oldValue).isValid()) {
      return;
    }

    let previousDate = moment(oldValue);
    let newDate = moment(newValue);
    if (newDate.isAfter(this.currentDate)) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, DATE_VALIDATION_MESSAGES.PAST_DATE);
      setTimeout(() => {
        this.toDate = oldValue;
      }, 20);
      return;
    }

    if (newDate.isBefore(this.fromDate)) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, DATE_VALIDATION_MESSAGES.INVALID_TO_DATE);
      setTimeout(() => {
        this.toDate = oldValue;
      }, 20);
      return;
    }

    let timeDifferenceInDays = moment.duration(moment(this.fromDate).diff(newDate)).asDays();
    let daysInCurrentYear = moment(this.fromDate).isLeapYear() ? 366 : 365;

    if (timeDifferenceInDays > daysInCurrentYear) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, DATE_VALIDATION_MESSAGES.DURATION_LIMIT_REACHED);
      setTimeout(() => {
        this.toDate = oldValue;
      }, 20);
      return;
    }

    if (previousDate.diff(newDate)) {
      this.selectedToDate = moment(newDate).format(this.restDateFormat);
      this.timeScaleSelectionStrategy(newDate, this.fromDate);
    }
  }

  protected timeScaleSelectionStrategy(currentDate: Date, newDate: Date): void {
    let timeDifferenceInDays = moment.duration(moment(currentDate).diff(newDate)).asDays();
    let timeDifferenceInWeeks = moment.duration(moment(currentDate).diff(newDate)).asWeeks();

    if (timeDifferenceInWeeks > 13) {
      this.selectGroupByOptionByName('Month');
      this.disableGroupByOptions('Hour');

    } else if (timeDifferenceInWeeks >= 1) {
      this.selectGroupByOptionByName('Week');
      this.disableGroupByOptions('Month');

    } else if (timeDifferenceInDays >= 1) {
      this.selectGroupByOptionByName('Day');
      this.disableGroupByOptions('Week', 'Month');

    } else {
      this.selectGroupByOptionByName('Hour');
      this.disableGroupByOptions('Day', 'Week', 'Month');
    }
  }

  protected disableGroupByOptions(...optionsToDisable: string[]): void {
    this.groupByOptions.map(option => {
      // @ts-ignore
      option.disabled = optionsToDisable.includes(option.name);
    });
  }

  protected setupChart(chartBuilder: ChartSettingsBuilder): void {
    this.interactionsData.distributions.forEach((distribution) => {
      let dataPoint: DataPointModel = new DataPointModel();
      dataPoint.date = moment.utc(distribution.timescale.timestamp).format('HH DD-MM-YYYY');
      distribution.interactionCounts.forEach((interactionCount) => {

        let _type = this.defineInteractionType(interactionCount.type);

        this.addGraphCategory(interactionCount, chartBuilder);
        dataPoint[_type] = interactionCount.totalIn;
      });
      chartBuilder.withDataPoint(dataPoint);
    });

    this.formattedInteractionsData = [];
    this.interactionsData.counts.forEach(interactionCount => {
      let count = new TotalCountsModel();
      count.type = this.defineInteractionType(interactionCount.type);
      count.total = interactionCount.total;
      count.color = GRAPH_COLOR_NAMES[interactionCount.type.toString().toUpperCase()];

      this.formattedInteractionsData.push(count);
    });
  }

  protected addGraphCategory(interaction: any, chartBuilder: ChartSettingsBuilder): void {

    let _type = this.defineInteractionType(interaction.type);
    let graphConfig = new GraphConfig();
    graphConfig.fillColors = GRAPH_COLORS[_type.toUpperCase().toString().replace(/ /g, '_')];
    graphConfig.lineColor = GRAPH_COLORS[_type.toUpperCase().toString().replace(/ /g, '_')];
    graphConfig.title = _type;
    graphConfig.valueField = _type;
    graphConfig.fixedColumnWidth = this.selectedGroupByOption.barSize;

    chartBuilder.withGraph(graphConfig);
  }

  protected defineInteractionType(type: INTERACTION_TYPES): INTERACTION_TYPES {

    if (type === 'Voice') {
      return INTERACTION_TYPES.CALLS;
    } else if (type === 'Mailbox') {
      return INTERACTION_TYPES.VOICEMAIL;
    } else if (type === 'Callback') {
      return INTERACTION_TYPES.CALLBACKS_REQUESTED;
    }

    return type;
  }

  protected createMockData(): any {

    let distributions = [];
    let counts = [];
    let distribution = {timescale: null, interactionCounts: null};
    let totalCount = 0;

    let dateDifference = moment.duration(moment(this.selectedFromDate).diff(this.selectedToDate));

    let dateDifferences = {
      hour: dateDifference.clone().hours(),
      day: dateDifference.clone().days(),
      week: dateDifference.clone().weeks(),
      month: dateDifference.clone().months()
    };

    for (let i = 0; i < 24; i++) {
      let interactionCounts = [];
      let timescale = {};
      let _counts = [];
      this.filterOptions.forEach(option => {
        if (option.name !== '') {
          let interaction = {
            type: option.name,
            totalIn: Math.floor(Math.random() * (50 - 13) + 13),
            totalOut: 0
          };
          interactionCounts.push(interaction);

          totalCount += interaction.totalIn;

          let count = {
            type: option.name,
            total: totalCount
          };

          _counts.push(count);
        }

      });

      let groupByOptionName = this.selectedGroupByOption ? this.selectedGroupByOption.name : 'Hour';
      timescale = {
        scale: groupByOptionName,
        timestamp: groupByOptionName === 'Hour' ? moment.utc(this.currentDate).add(i, groupByOptionName.toLowerCase().charAt(0)) : moment.utc(this.currentDate).subtract(i, groupByOptionName.toLowerCase().charAt(0))
      };

      let distribution = {
        timescale: timescale,
        interactionCounts: interactionCounts
      };
      distributions.push(distribution);

      if (counts.length === 0) {
        counts = _counts;
      }
    }

    for (let time in dateDifferences) {
      if (this.selectedGroupByOption && this.selectedGroupByOption.name && this.selectedGroupByOption.name.toLowerCase() === time) {
        for (let d = 0; d < dateDifferences[time]; d++) {
          distributions.push(distribution);
        }
      }
    }

    let interactions = {
      distributions: distributions,
      counts: counts
    };

    return interactions;
  }
}