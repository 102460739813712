import { ContactFieldModel } from "../contact-fields-model";

export class ContactFieldsListModel {

  public name: string;
  public type: any;
  public dropdownChoices: string[] = [];
  public currentChoice: string;
  public ready: boolean = false;
  public addState: boolean;
  public types: Array<IOutcome> = [{ name: 'TEXTBOX' }, { name: 'DROPDOWN' }];
  public isValid: boolean;
  public isValidChoice: boolean;
  public contactFields: ContactFieldModel[];

  public disableAddState(): void {
    this.addState = false;
    this.name = null;
    this.type = null;
    this.currentChoice = null;
    this.dropdownChoices = [];
  }
}

interface IOutcome {
  name: string;
  isSelected?: boolean;
}