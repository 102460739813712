import { bindable, computedFrom, customElement, inject, LogManager, observable } from 'aurelia-framework';
import $ from 'jquery';

const logger = LogManager.getLogger('ZDropDown');

@inject(Element)
@customElement('z-dropdown')
export class ZDropDown {
  @bindable
  @observable
  items;
  formattedItems = [];
  @bindable itemdescriptionproperty = '';
  @bindable alternateitemdescriptionproperty;
  @bindable subitemname = '';
  @bindable subitemdescriptionproperty = '';
  @bindable noitemsmessage = 'Please make a selection';
  @bindable
  @observable
  selecteditem = false;
  isOpen = false;
  @bindable align;
  @bindable size;
  @bindable uppercase;
  @bindable noborder;
  @bindable inline;
  @bindable isheader;
  @bindable reset = false;
  @bindable setinitialactiveoption = true;
  simpleDropdown = true;
  @bindable parentisnotabootstrapcolumn;
  @bindable disable;
  @bindable({ attribute: 'active-loader' })
  @bindable({ attribute: 'default-selection' }) defaultSelection;
  @bindable({ attribute: 'items-bind' }) itemsBind = false;
  @bindable({ attribute: 'special' }) special = false;
  @bindable position;
  activeLoader;
  initialising = true;
  @bindable({ attribute: 'min-width' }) minWidth;

  constructor(element) {
    this.element = element;
  }

  @computedFrom('alternateitemdescriptionproperty', 'selecteditemdescription', 'noitemsmessage')
  get displayText() {
    if (this.alternateitemdescriptionproperty) {
      return this.selecteditemalternatedescription;
    }
    if (this.selecteditemdescription) {
      return this.selecteditemdescription;
    }
    return null;
  }

  itemsChanged() {
    if (this.items && this.items.length === 0) {
      this.initialising = false;
      return;
    }

    if (!this.items) {
      this.initialising = false;
      this.formattedItems = [];
      this.noitemsmessage = (this.noitemsmessage === 'Please make a selection' ? 'No options available' : this.noitemsmessage);
      return;
    }
    this.initialising = true;
    this.setUpFormattedItemList();
  }

  attached() {
    this.isItemsAnArray();
    this.setUpFormattedItemList();
    this.hideMenuOnOutsideClick();
  }

  isItemsAnArray() {
    if (this.itemdescriptionproperty && this.itemdescriptionproperty.length > 0) {
      this.simpleDropdown = false;
      return;
    }
  }

  setUpFormattedItemList() {
    this.setFormattedItems();
    if (!this.simpleDropdown) {
      this.setFormattedSubItems();
    }
    this.selectInitialItem();
  }

  setFormattedItems() {
    if (this.items && this.items.length) {
      this.formattedItems = this.itemsBind ? this.items : Object.assign([], this.items);
      this.initialising = false;
    }
  }

  setFormattedSubItems() {
    if (this.subitemname) {
      this.formattedItems.forEach(item => {
        item.subitems = item[this.subitemname];
      });
    }
  }

  selectInitialItem() {
    if (this.selecteditem && typeof this.selecteditem === 'object') {
      this.findAndMarkSelectedItem();
    } else {
      if (this.setinitialactiveoption) {
        this.selectFirstItemByDefault();
      }
    }
  }

  findAndMarkSelectedItem() {
    if (!this.simpleDropdown) {
      const item = this.formattedItems.find((item) => this.selecteditem[this.itemdescriptionproperty] && this.selecteditem[this.itemdescriptionproperty] === item[this.itemdescriptionproperty]);
      if (item) {
        this.selectItem(null, item);
      } else {
        this.selectFirstItemByDefault();
      }
    }
  }

  selectFirstItemByDefault() {

    if (this.defaultSelection) {
      this.selectItem(null, this.defaultSelection);
      return;
    }

    if (!this.simpleDropdown) {
      this.selectItem(null, this.formattedItems[0]);
    } else {
      this.selecteditem = this.formattedItems[0];
    }
  }

  selectItem(event, item, subItem) {
    event && event.stopPropagation();
    if (item !== undefined) {
      if (!this.simpleDropdown) {
        this.markAllItemsAsUnselected();
        this.markItemAsSelected(item, subItem);
        this.setSelectedItem(item, subItem);
      } else {
        this.markItemAsSelected(item);
      }

      this.dispatch('select', this.selecteditem);
      this.isOpen = false;
    }
  }

  markItemAsSelected(item, subItem) {
    if (!this.simpleDropdown) {
      item.isSelected = true;
      if (subItem) {
        subItem.isSelected = true;
      }
    } else {
      this.selecteditem = item;
    }
  }

  setSelectedItem(item, subItem) {
    this.selecteditem = this.itemsBind ? item : Object.assign({}, item);
    if (subItem) {
      this.selecteditem[this.subitemname] = subItem;
    }
  }

  markAllItemsAsUnselected() {
    if (!this.simpleDropdown) {
      this.formattedItems.forEach(otherItem => {
        otherItem.isSelected = false;
        if (otherItem.subitems) {
          otherItem.subitems.forEach(otherSubItem => {
            otherSubItem.isSelected = false;
          });
        }
      });
    }
  }

  @computedFrom('items', 'selecteditem')
  get selecteditemdescription() {
    if (!this.items || this.items.length == 0) {
      return this.noitemsmessage;
    }
    if (!this.selecteditem) {
      return this.noitemsmessage;
    }
    if (this.subitemname && this.selecteditem[this.subitemname] && this.selecteditem[this.subitemname][this.subitemdescriptionproperty] != undefined) {
      return this.selecteditem[this.itemdescriptionproperty] + ' | ' + this.selecteditem[this.subitemname][this.subitemdescriptionproperty];
    }
    if (!this.simpleDropdown) {
      return this.selecteditem[this.itemdescriptionproperty];
    } else {
      return this.selecteditem;
    }
  }

  @computedFrom('items', 'selecteditem')
  get selecteditemalternatedescription() {
    if (!this.items || this.items.length == 0) {
      return this.noitemsmessage;
    }
    if (!this.selecteditem) {
      return this.noitemsmessage;
    }
    if (this.subitemname && this.selecteditem[this.subitemname] && this.selecteditem[this.subitemname][this.subitemdescriptionproperty] != undefined) {
      return this.selecteditem[this.alternateitemdescriptionproperty] + ' | ' + this.selecteditem[this.subitemname][this.subitemdescriptionproperty];
    }
    if (!this.simpleDropdown) {
      return this.selecteditem[this.alternateitemdescriptionproperty];
    } else {
      return this.selecteditem;
    }
  }

  selecteditemChanged(newValue, oldValue) {
    if (newValue === oldValue) {
      this.setUpFormattedItemList();
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this._closeNonActiveSubMenus();
  }

  toggleSubMenu(item, subItem) {
    item.isExpanded = !item.isExpanded;
    if (subItem) {
      subItem.isExpanded = !item.isExpanded;
    }
  }

  dispatch(name, data) {
    this.element.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail: data
      })
    );
  }

  closeDropDown() {
    this.isOpen = false;
    this._closeNonActiveSubMenus();
  }

  _closeNonActiveSubMenus() {
    if (!this.simpleDropdown) {
      this.formattedItems.forEach(item => {
        if (item.isSelected !== true) {
          item.isExpanded = false;
        }
      });
    }
  }

  hideMenuOnOutsideClick() {
    let checkDropdown = this;
    let dropdownIsOpen = true;

    $('z-dropdown').hover(function () {
      if (checkDropdown.isOpen === true) {
        dropdownIsOpen = true;
      } else {
        dropdownIsOpen = false;
      }
    });

    $('body').click(function () {
      checkDropdown._closeOpenDropdowns(checkDropdown, dropdownIsOpen);
      checkDropdown._resetDropdowns(checkDropdown);
    });
  }

  _closeOpenDropdowns(dropdown, dropdownIsOpen) {
    if (dropdownIsOpen) {
      dropdown.closeDropDown();
    }
  }

  _resetDropdowns(dropdown) {
    if (dropdown.reset === true) {
      dropdown.selectFirstItemByDefault();
      dropdown.selecteditem = dropdown.formattedItems[0];
      dropdown.reset = false;
    }
  }
}
