import { bindable, customElement, inject, LogManager, computedFrom } from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('ZCardHeader');

@customElement('z-card-header')
@inject(Element)
export class ZCardHeader {
  @bindable capitals = '';
  @bindable header;
  @bindable color;
  @bindable actions;
  @bindable({ attribute: 'disable-actions' })
  disableActions;

  constructor(element) {
    this.element = element;
    this.visibilityComponent = uuidv4();
  }

  @computedFrom('actions')
  get formattedActions() {
    if (!this.actions) {
      return [];
    }

    let _actions = [];

    for (let _item of this.actions) {
      _actions.push({
        event: _item.action,
        name: _case.first(_item.label) || _case.first(_item.action),
        icon: _item.icon || _item.action.replace(' ', ''),
        separated: _item.separated,
        warning: _item.warning
      });
    }
    return _actions;
  }

  dispatchEvent(eventName) {
    this.element.dispatchEvent(
      new CustomEvent(eventName.replace(' ', ''), {
        bubbles: true,
        detail: {}
      })
    );
  }

  toggle() {
    let wrapper = document.querySelector('.js-component-visibility-' + this.visibilityComponent);
    let el = wrapper.querySelector('.js-component-container');
    $(el).toggleClass('is-visible');
  }

  textTransform() {
    switch (this.capitals) {
      case 'all':
        this.capitals = 'uppercase';
        break;

      case 'true':
        this.capitals = 'uppercase';
        break;

      case 'first':
        this.capitals = 'capitalize';
        break;

      default:
        'none';
    }
  }
}

let _case = {
  first: string => {
    let array = string.split(' ');
    let newString = '';

    for (let item of array) {
      newString += item.charAt(0).toUpperCase() + item.slice(1) + ' ';
    }
    return newString;
  }
};
