import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {MailboxService} from 'zailab.common';

const logger = LogManager.getLogger('ViewMemberDialog');

@inject(DialogController, MailboxService)
export class ViewMembers {

  private availableMembers: Array<ZIMailboxRecipient> = [];
  private assignedMembers: Array<ZIMailboxRecipient> = [];
  private isBusy: boolean = false;
  private mailboxId: string;
  private remainingOperations: number = 0;
  private searchName: string = '';
  private pageNumber: number = 0;
  private pageSize: number = 6;
  public noMoreMembers: boolean = false;

  constructor(
    private dialogController: DialogController,
    private mailboxService: MailboxService
  ) {}

  public activate(_mailbox: ZIMailbox): void {
    this.mailboxId = _mailbox.mailboxId;
    this.assignedMembers = _mailbox.recipients.recipients as ZIMailboxRecipient[];
    this.getMembers();
  }

  private getMembers(): void {
    this.noMoreMembers = false;
    this.mailboxService
      .getAllRecipientsForOrg(this.searchName, this.pageNumber, this.pageSize)
      .then(members => {
        if (this.assignedMembers.length > 0) {
          let availableMembers = members.filter((_member) => {
            return !this.assignedMembers.find(_assignedMember => _member.id === _assignedMember.id);
          });

          if (this.pageNumber >= 1) {
            if (!availableMembers || availableMembers.length === 0) {
              this.noMoreMembers = true;
            }
            this.availableMembers = [].concat(this.availableMembers, availableMembers);
          } else {
            this.availableMembers = availableMembers;
          }
        } else if (this.pageNumber === 0) {
          this.availableMembers = members;
        } else if (!members || members.length === 0) {
          this.noMoreMembers = true;
        } else {
          let availableMembers = members.filter((_member) => {
            return !this.assignedMembers.find(_assignedMember => _member.id === _assignedMember.id);
          });
          this.availableMembers = [].concat(this.availableMembers, availableMembers);
        }
      });
  }

  public searchMembers(): void {
    this.pageNumber = 0;
    this.getMembers();
  }

  public showMore(): void {
    this.pageNumber += 1;
    this.getMembers();
  }

  attached(): void {
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private done(): void {
    this.dialogController.ok();
  }

  private validate(): void {
    if (this.assignedMembers.length === 0) {
      return;
    }
  }

  // Methods called from view

  private addMemberToMailbox(_index: number, _member: ZIMailboxRecipient): void {
    this.isBusy = true;
    this.remainingOperations++;
    this.availableMembers.splice(_index, 1);
    if (!_member.recipientId) {
      this.mailboxService.getRecipientIdByMemberId(_member.id).then(_result => {
        _member.recipientId = _result.recipientId;
        this.assignedMembers.push(_member);
        this.triggerAddMemberCommand(_member);
      });
    } else {
      this.assignedMembers.push(_member);
      this.triggerAddMemberCommand(_member);
    }

  }

  private removeMemberFromMailbox(_index: number, _member: ZIMailboxRecipient): void {
    this.isBusy = true;
    this.remainingOperations++;
    this.assignedMembers.splice(_index, 1);
    this.availableMembers.push(_member);
    this.triggerRemoveMemberCommand(_member);
  }

  private triggerAddMemberCommand(_member: ZIMailboxRecipient): void {
    let _payload: ZIMailboxRecipientPayload = {
      recipientId: _member.recipientId,
      mailboxId: this.mailboxId
    };
    this.mailboxService.addMemberToMailbox(_payload).then(_result => {
      this.isBusy = false;
    });
  }

  private triggerRemoveMemberCommand(_member: ZIMailboxRecipient): void {
    let _payload: ZIMailboxRecipientPayload = {
      recipientId: _member.recipientId,
      mailboxId: this.mailboxId
    };
    this.mailboxService.removeMemberFromMailbox(_payload).then(_result => {
      this.isBusy = false;
    });
  }
}