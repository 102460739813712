import {inject, LogManager, computedFrom} from 'aurelia-framework';

import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('ChannelModel');

export class ChannelModel extends BaseModel {

  public channelName: string = null;
  public displayName: string = null;
  public tenantId: string = null;

  constructor(channel?: string) {
    super();
    this.channelName = channel;
    this.setdisplayName();
  }

  public setdisplayName(): void {
    switch (this.channelName) {
      case 'INBOUND_CALL':
        this.displayName = 'Inbound Call';
        break;
      case 'OUTBOUND_CALL':
        this.displayName = 'Outbound Call';
        break;
      case 'CHAT':
        this.displayName = 'Chat';
        break;
      case 'SMS':
        this.displayName = 'SMS';
        break;
      case 'EMAIL':
        this.displayName = 'Email';
        break;
      case 'TICKET':
        this.displayName = 'Ticket';
        break;
      case 'INSTANT_MESSAGE':
        this.displayName = 'Instant Message';
        break;
      default :
        this.displayName = '';
    }
  }

  @computedFrom('displayName')
  public get type(): string {
      return this.displayName ? this.displayName.toUpperCase().replace(' ', '_') : '';
  }
}