import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { SessionStore } from 'zailab.common';
import { RoutingStatusService } from './routing-status-service';
import { ChannelSelectionChannel } from './channel-selection/channel-selection-dialog';

const logger = LogManager.getLogger('RoutingStatusChannel');

@autoinject
export class RoutingStatusChannel {

  public isDisabled: boolean = true;
  // public showDropdown: boolean = false;
  // public memberChannelAvailability: any = null;
  private subscription: Subscription;

  // public direction: { [key: string] : boolean } = {
  //   inbound: false,
  //   outbound: false
  // };
  // public channels: any[] = [];
  // public campaigns: any[] = [];
  // private selectedDiallerType: string;

  constructor(
    private session: SessionStore,
    private eventAggregator: EventAggregator,
    private routingStatusService: RoutingStatusService,
    private dialogService: DialogService
  ) {}

  public activate(): void {
    if (!this.session.get.user.hasAgentRole) { // todo: flaap - z-auth is not resolving this block
      return;
    }
    this.subscribeToPresence();
    this.retrieveRoutingStatus();
  }

  private subscribeToPresence(): void {
    this.subscription = this.eventAggregator
      .subscribe(
        'current.agent.status',
        (presence: string) => {
          this.isDisabled = presence === 'Ready';
        }
      );
  }

  private retrieveRoutingStatus(): void {
    this.routingStatusService
      .retrieveRoutingStatus(this.session.get.user.memberId)
      .then(data => {
        this.isDisabled = data.routingStatus === 'Ready'
      });
  }

  public openChannelSelectionDialog(): void {
    this.dialogService.open({
      viewModel: ChannelSelectionChannel,
      model: {}
    });
  }

  public deactivate(): void {
    this.subscription && this.subscription.dispose();
  }
}
