import {BaseModel} from 'zailab.abstract';

export class TaskTemplateModel extends BaseModel {
  public id: string = null;
  public name: string = null;
  public isSelected: boolean = false;

  constructor(taskTemplate: any){
    super();
    this.mapProperties(taskTemplate);
  }
}