import { DialogController } from 'aurelia-dialog';
import { inject, LogManager } from 'aurelia-framework';
import { WorkTypesService } from '../../../conversation/worktypes/work-types-service';

/*
 */
const logger = LogManager.getLogger('VerintAnalyticsConfigurationDialog');
/*
 */
@inject(DialogController, WorkTypesService)
export class VerintAnalyticsVoiceWorktypeConfigurationDialog {

  dialogHeader = "Verint Analytics Voice WorkType Configuration";
  allAvailableWorkTypes = [];
  selectedWorkTypes = {};
  allWorkTypesEnabled = true;

  constructor(dialogController, workTypesService) {
    this.dialogController = dialogController;
    this.workTypesService = workTypesService;
  }

  activate(data) {
    logger.debug('activating with data', data);
    this.allWorkTypesEnabled = data.allWorkTypesEnabled;
    let previouslyEnabledWorkTypes = data.enabledWorkTypes;
    this.workTypesService.retrieveWorkTypes()
      .then((res) => {
        let filteredWorkTypes = 
          res.filter(workType => workType.channel.includes('Call') && workType.flowType !== 'Office Flow');
        
        filteredWorkTypes.forEach(workType => {
          this.workTypesService.retrieveWorkType(workType.templateId).then((taskTemplate) => {
            this.allAvailableWorkTypes.push(taskTemplate);
            let taskTemplateId = taskTemplate.id;
            if (previouslyEnabledWorkTypes.includes(taskTemplateId)) {
              this.selectedWorkTypes[taskTemplateId] = true;
            }
          },
          (error) => {
            logger.error('Unable to retrieve work type ' + workType.taskTemplateId, error);
          });
          
        });
        
      });
  }

  toggleAllWorkTypesSelected() {
    this.allWorkTypesEnabled = !this.allWorkTypesEnabled;
  }
  
  toggleWorkType(taskTemplateId) {
    if (taskTemplateId in this.selectedWorkTypes) {
      this.selectedWorkTypes[taskTemplateId] = !this.selectedWorkTypes[taskTemplateId];
    } else {
      this.selectedWorkTypes[taskTemplateId] = true;
    }
  }

  cancel() {
    this.dialogController.cancel();
  }

  save() {
    let response = {
      enabledWorkTypes: this.selectedWorkTypes,
      allWorkTypesEnabled: this.allWorkTypesEnabled
    };
    this.dialogController.ok(response);
  }

  toggleEnabled() {
    this.verintInteractionAnalyticsConfiguration.enabled = !this.verintInteractionAnalyticsConfiguration.enabled;
  }
}

