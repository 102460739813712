import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { List } from '../../../../abstract/list/list-builder';
import { SessionStore } from 'zailab.common';
import { LiveDashboardService } from '../../../interaction/dashboard/live-dashboard/live-dashboard-service';
import { AssistService } from '../assist-service';

const logger = LogManager.getLogger('AssistList');

@autoinject()
export class AssistList {

  public loading: boolean;
  public itemList: List;
  public durationTimeout = 86400000; // 24 hours in milliseconds

  private list: any[];

  constructor(
    private eventAggregator: EventAggregator,
    private assistService: AssistService,
    private liveDashboardService: LiveDashboardService,
    private sessionStore: SessionStore
  ) { }

  public attached(): void {
    this.retrieveList();
    this.eventAggregator.subscribe('findTeamLeaderAssist', (response) => {
      this.list = response || [];
      this.setupListConfig();
    });
  }

  private retrieveList(): Promise<any> {
    this.eventAggregator.publish('app:loader:show');
    return this.findAllTeamLeaderAssists();
  }

  private setupListConfig(): void {
    this.itemList = List.Builder()
      .items(this.list)
      .icon('icon')
      .displayId('name')
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .build();
    this.itemList.select = true;
    this.itemList.autoSelect = false;
    this.eventAggregator.publish('app:loader:hide');
  }

  private handleFailure(error: any): void {
    logger.warn('retrieve list > error = ', error);
    this.eventAggregator.publish('app:loader:hide');
  }

  public lowerHand(event: any, member: any): void {
    if (!member || !member.memberId) {
      return;
    }
    this.loading = true;
    this.assistService.findAllAssists(member.memberId)
      .then((response) => {
        const assist = response.find((item) => item.handRaised);
        if (!assist) {
          this.retrieveList();
          this.loading = false;
          return;
        }
        this.assistService.postLowerHand(assist.assistId, assist.memberId)
          .then(() => this.checkForUpdate(this.list.length - 1));
      })
      .catch((error) => logger.warn('lowerHand :: findAllAssists :: error=', error));
  }

  public async validateInteractions(member: any): Promise<void> {
    if (!member || !member.memberId) {
      return;
    }
    member.isValidating = true;
    try {
      await this.liveDashboardService.validateAndRemoveInteractions(member.memberId, this.sessionStore.get.organisation.organisationId);
      member.isValidated = true;
      setTimeout(() => {
        member.isValidating = false;
        member.isValidated = false;
      }, 2000);
    } catch(e) {
      logger.warn(' > Failed to validate interactions due to', e);
      member.isValidating = false;
    }
  }

  private checkForUpdate(listLength: number): void {
    setTimeout(() => {
      this.retrieveList()
        .then(() => {
          if (listLength === this.list.length) {
            this.loading = false;
          } else {
            this.checkForUpdate(listLength);
          }
        });
    }, 500);
  }

  public findAllTeamLeaderAssists(): Promise<any> {
    return this.assistService.findAllTeamLeaderAssists()
      .then((list) => {
        this.eventAggregator.publish('findTeamLeaderAssist', list);
        return true;
      })
      .catch(error => this.handleFailure(error));
  }
}