export const CONTACT_ACTIONS = {
  AUTOSELECT: 'auto.select',
  CHANGE_VIEW: 'change.view',
  CREATE: 'create.contact',
  EDIT: 'edit.contact',
  OPLOG_UPDATE: 'contact.oplog:update',
  SEARCH: 'search.contact',
  SEARCH_RESULT: 'search.contact.result',
  SEARCH_NEXT_PAGE: 'search.contact:next.page',
  SEARCH_RESULT_NEXT_PAGE: 'search.contact.result:next.page',
  SELECT: 'select.contact',
  PREVIOUS: 'previous.view',
  SUBMIT_REMINDER: 'submit.reminder',
  SELECT_FROM_RECENT_ACTIVITY: 'select.from.recent.activity',
  SELECT_FROM_RECENT_CONVERSATION: 'select.conversation.from.recentconversations',
  SELECT_AND_OPEN_CONTACT: 'select.and.open.contact',
  SELECT_SEARCH: 'select.contact:search',
  UPDATE: 'update.contact',
  UPDATE_WORKFLOW: 'update.workflow'
};

export const CONVERSATION_ACTIONS = {
  CHANGE_NAME: 'change.conversation.name',
  CREATE: 'create.conversation',
  CREATE_AND_LINK_INTERACTION: 'create.and.link.conversation',
  CREATE_NEW: 'create.new.conversation',
  DISCARD: 'discard.conversation',
  LINK_INTERACTION: 'link.interaction.to.conversation',
  LIST_INSERT_OPLOG: 'conversationList.insert:oplog',
  LIST_UPDATE_OPLOG: 'conversationList.update:oplog',
  RETRIEVE_CONTACT: 'conversation.retrieved:contact',
  REVIEW_STATUS: 'review.status',
  SELECT: 'select.conversation',
  CONTACT_CONVERSATIONS_CHANGED: 'contact.conversations.changed'
};

export const INTERACTION_ACTIONS = {
  CONNECTED: 'connected.interaction',
  CONNECTED_INTERACTION: 'connected.interaction',
  CONNECTED_CALL: 'connected.interaction:call',
  CONNECTED_EMAIL: 'connected.interaction:email',
  CONNECTED_CHAT: 'connected.interaction:chat',
  ENDED_OUTBOUND: 'outbound.interaction.ended',
  GET_RECORDING_URL: 'get.recording.url',
  RESUME_INTERACTION_LINKING: 'resume.interaction.linking',
  OUTBOUND_CONNECTED_EMAIL: 'outbound.connected.interaction:email',
  SELECT: 'select.interaction',
  SELECT_UNLINKED: 'select.unlinked.interaction',
  TASK_INCOMING: 'incoming.task',
  UNLINKED_OPLOG_UPDATE: 'unlinked.interaction:oplog:update',
  UPDATE: 'conversation.interactions.update:oplog',
  WRAP_UP_ENDED: 'conversation.interaction.wrapup.ended',
  WRAP_UP_STARTED: 'conversation.interaction.wrapup.started',
  CONNECTED_INTERACTIONS_RECOVERED: 'conversation.connected.interactions.recovered',
  INTERACTION_LINKED_TO_CONVERSATION: 'interaction.linked.to.conversation',
  MEMBER_DISCONNECTED_FROM_CALL: 'member.disconnected.from.call',
  MEMBER_CONNECTED_STATE: 'member.connected.state.update',
  MEMBER_DISCONNECTED_FROM_OUTBOUND_FLOW_CALL: 'member.is.disconnected.from.outbound.flow.call',
  OUTBOUND_ENDPOINT_NOTIFIED: 'outbound.call.notified',
  OUTBOUND_ENDPOINT_ACCEPTED: 'outbound.call.answered',
  WRAP_UP_INTERACTION_UPDATED: 'conversation.interaction.wrapup.updated',
  IS_ON_CALL: 'is.on.call'
};

export const CONTROLLER_ACTIONS = {
  CALLBACK_CLICK_TO_DIAL: 'callback.click.to.dial',
  CLICK_TO_DIAL: 'click.to.dial'
};

export const LOADER_ACTIONS = {
  CHANGE_LOADER_CONTACT_INFO: 'change.loader:contact.info',
  CHANGE_LOADER_CONVERSATION_LIST: 'change.loader:conversation.list',
  CHANGE_LOADER_INTERACTION_LIST: 'change.loader:interaction.list',
  CHANGE_LOADER_SEARCH: 'change.loader:search',
  CHANGE_LOADER_UNLINKED_INTERACTIONS: 'change.loader:unlinked.interactions'
};
