import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController, DialogService} from 'aurelia-dialog';

const logger = LogManager.getLogger('Tab2InputCapture');

export interface IInputCapture {
  displayText: string,
  variableName: string,
  inputType: string
}

interface ITab2InputCapture {
  data: IInputCapture[]
}

@autoinject()
export class Tab2InputCapture {
  private model: ITab2InputCapture;
  private inputCaptures: IInputCapture[];

  public tableColumns = ['Display Text', 'Variable Name', 'Control Type'];
  public tableData = [];
  public tableTypes = [
    {
      type: 'text',
      validation: 'required',
    },
    {
      type: 'text',
      validation: 'required',
    },
    {
      type: 'dropdown',
      placeholder: 'Control Type',
      validation: 'required',
      options: ['Textbox', 'Textarea', 'Checkbox'],
      direction: 'bottom',
    },
  ];
  public columnsRegex = [/^(?:(?![+{}()\\]).)*$/, null];

  public activate(
    model: ITab2InputCapture
  ): void {
    this.model = model;
    this.inputCaptures = model.data || [];
    let tableData = [];
    this.inputCaptures.forEach(row => {
      tableData.push([row.displayText, row.variableName, row.inputType]);
    });
    this.tableData = tableData;
  }


  public valueChanged(tableData: any): void {
    this.model.data = tableData.map(row => {
      return {
        displayText: row[0],
        variableName: row[1],
        inputType: row[2]
      }
    });
  }
}
