import {inject, LogManager} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('InteractionFlowModel');
/*
 */
export class UserModel extends BaseModel {

  passportId = null;
  userId = null;
  personId = null;
  firstName = null;
  surname = null;
  email = null;

  constructor(organisationInformation) {
    super();
    this.mapProperties(organisationInformation);
  }
}