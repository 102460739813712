/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPageList');
/*
 */
@customElement('z-page-list')
export class ZPageList {

  @bindable sidecolwidth;
  @bindable centercolwidth = 12;
  @bindable nopaddingright = false;

  bind() {

    this.columnWidth = this.centercolwidth - (this.sidecolwidth * 2);
  }

}
