import {autoinject, LogManager} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {HttpClient} from 'aurelia-http-client';
import {EventAggregator} from 'aurelia-event-aggregator';

import {ApplicationProperties, SESSION_EVENTS} from 'zailab.common';
import {ViewInteractionDialog} from "../viewinteraction/view-interaction-dialog";
import InteractionLogModel from "../interaction-log-model";

const logger = LogManager.getLogger('ViewInteraction');

@autoinject
export class ViewInteractionLogDialog {

  private interactionId: string = null;

  constructor(private dialogService: DialogService, private httpClient: HttpClient, private applicationProperties: ApplicationProperties, private eventAggregator: EventAggregator) { }

  public activate(params: { interactionId: string }): void {
    if(params && params.interactionId) {
      this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, { token: this.applicationProperties.hackToken });
      this.interactionId = params.interactionId;
      this.retrieveInteraction();
    }
  }

  private retrieveInteraction(): void {
    let queryEndpoint = this.applicationProperties.apiQueryEndpoint;
    this.httpClient.createRequest(`v1/organisation/interactions/history/${this.interactionId}`)
      .withBaseUrl(queryEndpoint)
      .withHeader('Authorization', 'Bearer ' + this.applicationProperties.hackToken)
      .asGet()
      .send()
      .then((response: any) => {
        if(response && Array.isArray(response.calls)) {
          let interaction = this.modelCollection(response.calls || [])[0];
          this.viewInteraction(interaction);
        } else {
          logger.error('Unable to find interaction in response', response);
        }
      })
      .catch(((error: string) => {
        logger.error('Unable to find interaction due to cause', error);
      }));
  }

  private viewInteraction(interaction: any): void {
    if (interaction.isPlaceholder) {
      return;
    }
    this.dialogService
      .open({
        viewModel: ViewInteractionDialog,
        model: interaction
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
        }
      });
  }

  private modelCollection(response: any): any {
    let list = [];
    response.forEach(item => {
      list.push(new InteractionLogModel(item));
    });
    return list;
  }
}