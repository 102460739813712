export class WidgetConfig {
  public title: string = '';
  public description: string = '';
  public actions: Array<any> = [
    {
      action: 'view',
      label: 'view'
    }
  ];
  public showDescription: boolean = false;

  constructor(title: string, description: string, actions?: Array<any>) {
    this.title = title;
    this.description = description;
    this.actions = actions;
  }

  private toggleDescriptionVisibility(): void {
    this.showDescription = !this.showDescription;
  }
}
