import { computedFrom } from 'aurelia-binding';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { ArrayTools, CurrentScopeValidation, SessionStore } from 'zailab.common';
import { BusinessPartner } from '../../../businesspartner/business-partner-model';
import { BusinessPartnerService } from '../../../businesspartner/business-partner-service';
import { ContactCenter } from '../../../contactcenter/contact-center-model';
import { ContactCenterService } from '../../../contactcenter/contact-center-service';
import { MembersService } from '../../../member/members/members-service';
import { DisplayTeamsModel } from '../../../member/teams/teams-model';
import { TeamsService } from '../../../member/teams/teams-service';

import { AutoLogoutService } from '../auto-login-service';
import { AudienceType, AudienceTypeSelection, Option } from './auto-logout-model';
import { MemberModel } from '../../../conversation/member-model';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('AddAutoLogoutConfig');

@autoinject()
export class AddAutoLogoutConfig {
  
  private validation: ValidationController;

  public audienceTypeOptions: Option[] = AudienceTypeSelection.options;

  public businessPartnerOptions: Option[];
  public contactCenterOptions: Option[];
  public teamOptions: Option[];
  public memberOptions: Option[];
  public timeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180];
  public roleOptions = [
    'Select a role',
    'Administrator',
    'Agent',
    'Team Leader',
    'Office Employee',
    'QA',
    'QA Manager',
    'Campaign Manager'
  ];

  public audienceTypeSelect: HTMLSelectElement;
  public audienceSelect: HTMLSelectElement;

  private pageNumber: number = 0;
  private pageSize: number = 6;
  public showMoreEnabled: boolean = true;
  public noMoreMembers: boolean = false;

  public audienceTypeSelectDisabled: boolean = false;
  public audienceSelectDisabled: boolean = false;

  public isProcessing: boolean = false;
  public submitted: boolean = false;

  public config: any = {
    audiences: []
  };
  private currentName: string;
  private ruleNames: string[];

  constructor(
    private autoLogoutService: AutoLogoutService,
    private router: Router,
    private sessionStore: SessionStore,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private teamService: TeamsService,
    public validate: CurrentScopeValidation,
    private dialogController: DialogController,
    private memberService: MembersService
  ) {
    this.validation = validate.getController();
  }

  public activate(model: {
    ruleNames: string[],
    rule?: { id: string, name: string }
  }): void {
    this.ruleNames = model.ruleNames;

    if (model.rule && model.rule.id) {
      this.currentName = model.rule.name;
      this.retrieveAutoLoginProfile(model.rule.id);
    } else {
      this.config.roles = this.roleOptions.filter(role => role !== 'Select a role').map(role => {
        return {
          value: role,
          label: role
        };
      });
      ArrayTools.sort(this.config.roles, 'label');
      this.initValidationRules();
    }
  }

  private async retrieveAutoLoginProfile(id: string): Promise<void> {
    let config: {
      id: string,
      name: string,
      logoutEnabled: boolean,
      timeInMillis: number,
      audienceType: string,
      audience: { audienceId: string, audienceName: string}[],
      roles: string[]
  } = await this.autoLogoutService.retrieveAutoLoginConfig(id);
    let audienceType = this.toTitleCase(config.audienceType);
    this.config = {
      id: config.id,
      name: config.name,
      logoutEnabled: config.logoutEnabled,
      roles: config.roles.map(role => {
        return {
          label: role,
          value: role
        };
      }),
      time: config.timeInMillis / 60 / 1000,
      audienceType: this.audienceTypeOptions.find(at => at.label === audienceType),
      audiences: config.audience.length > 0 ? config.audience.map(item => {
        return {
          label: item.audienceName,
          value: item.audienceId
        }
      }) : []
    };
    this.initValidationRules();
  }

  public attached(): void {
    this.initDisplay();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  private initValidationRules(): void {
    this.validation.reset();
    
    ValidationRules
      .customRule('uniqueName', (value) => {
        if (this.currentName && value && this.currentName.toLowerCase() === value.toLowerCase()) {
          return true;
        }
        let rule = this.ruleNames.find(item => value && item.toLowerCase() === value.toLowerCase());
        if (rule) {
          return false;
        }
        return true;
      }, 'Please enter a unique name.');
    
    ValidationRules
      .ensure('name').required().withMessage('Please enter a name.').satisfiesRule('uniqueName')
      .ensure('time').required().withMessage('Please select a Time.')
      .on(this.config);
  }

  private initDisplay(): void {
    this.businessPartnerService.findAll().then((results: BusinessPartner[]) => {
      this.businessPartnerOptions = results.map((data: BusinessPartner) => new DefaultOption(data.id, data.name));
    });
    this.contactCenterService.findAll().then((results: ContactCenter[]) => {
      this.contactCenterOptions = results.map((data: ContactCenter) => new DefaultOption(data.contactCentreId, data.name));
    });
    this.teamService.retrieveOrganisationTeams(this.sessionStore.get.organisation.organisationId).then((results: DisplayTeamsModel[]) => {
      this.teamOptions = results.map((data: DisplayTeamsModel) => new DefaultOption(data.teamId, data.teamName));
    });
    this.getMembers();
  }
  private getMembers():void {
    this.memberService
      .retrieveOrganisationMembers(null, null, null, this.pageNumber, this.pageSize)
      .then((results: any[]) => {
        results = results.map((data: MemberModel) => new DefaultOption(data.memberId, data.fullName));

        if (this.pageNumber >= 1) {
          if (!results || results.length === 0) {
            this.noMoreMembers = true;
          }
          if (results.length < this.pageSize) {
            this.showMoreEnabled = false;
          }
          this.memberOptions = [].concat(this.memberOptions, results);
        } else {
          this.memberOptions = results;
        }
      });
  }

  public searchMembers(): void {
    this.pageNumber = 0;
    this.showMoreEnabled = true;
    this.getMembers();
  }

  public showMore(): void {
    this.pageNumber += 1;
    this.getMembers();
  }

  public roleChanged(role: string): void {
    if (!role || role === 'Select a role') {
      return;
    }
    if (!this.config.roles.find(item => item.label === role)) {
      this.config.roles.push({ label: role, value: role });
      ArrayTools.sort(this.config.roles, 'label');
    }
  }

  public removeRole(role: string): void {
    this.config.roles.splice(this.config.roles.indexOf(role), 1);
  }

  public audienceChanged(audience: { label: string, value: string }): void {
    if (!audience) {
      return;
    }
    if (this.config.audiences.indexOf(audience) < 0) {
      this.config.audiences.push(audience);
    }
  }

  public removeAudience(audience: string): void {
    this.config.audiences.splice(this.config.audiences.indexOf(audience), 1);
  }

  public audienceTypeChanged(audienceType: any): void {
    if (!audienceType || !audienceType.value) {
      return;
    }
    this.validation.validate()
    this.config.audience = null;
    this.config.audiences = [];
  }

  public save(): void {
    this.isProcessing = true;
    this.submitted = true;
    this.validation
      .validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (!this.isValidRoles || !this.isValidAdiences || !this.isValidTime) {
      this.isProcessing = false;
      return;
    }
    if (result.valid) {
      let audienceMap = {};
      this.config.audiences.forEach(audience => {
        audienceMap[audience.value] = audience.label;
      });

      let payload = {
        id: this.config.id,
        name: this.config.name,
        logoutEnabled: !!this.config.logoutEnabled,
        timeInMillis: this.config.time * 60 * 1000,
        audienceType: (this.config.audienceType.label.toUpperCase()),
        audience: audienceMap,
        roles: this.config.roles.map(role => {
          return role.value;
        })
      };

      if (payload.id) {
        this.autoLogoutService
          .updateAutoLogoutProfile(payload)
          .then(() => this.dialogController.ok(payload))
          .catch((e: any) => {
            logger.warn(' > Failed to create auto logout profile due to', e);
          });
      } else {
        const id = uuidv4();
        payload.id = id;
        this.autoLogoutService
          .createAutoLogoutProfile(payload)
          .then(() => this.dialogController.ok(payload))
          .catch((e: any) => {
            logger.warn(' > Failed to create auto logout profile due to', e);
          });
      }
    }
  }

  @computedFrom('config.audienceType', 'teamOptions', 'memberOptions')
  public get audienceOptions(): Option[] {
    let audienceType = this.convertToEnumFormat(this.config.audienceType);

    if (audienceType === AudienceType.BUSINESS_PARTNER) {
      return this.businessPartnerOptions;
    }
    if (audienceType === AudienceType.CONTACT_CENTER) {
      return this.contactCenterOptions;
    }
    if (audienceType === AudienceType.TEAM) {
      return this.teamOptions;
    }
    if (audienceType === AudienceType.MEMBER) {
      return this.memberOptions;
    }
    return [new DefaultOption('ALL', 'Everyone')];
  }

  private convertToEnumFormat(value: any): string {
    if (!value) {
      return '';
    }
    if (value.value) {
        return value.value.toUpperCase().replace(' ', '_');
    }
    return '';
  }

  @computedFrom('config.audienceType')
  public get isValidAdienceType(): boolean {
    return this.config.audienceType && this.config.audienceType.value;
  }

  private toTitleCase(str: string): string {
    return str.toLowerCase().split(' ').map(word => {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  @computedFrom('config.roles', 'config.roles.length')
  public get isValidRoles(): boolean {
    return this.config.roles.length > 0;
  }

  @computedFrom('config.audiences', 'config.audiences.length', 'config.audienceType')
  public get isValidAdiences(): boolean {
    return this.config.audiences.length > 0 || this.config.audienceType.label === 'Organisation';
  }

  @computedFrom('config.time')
  public get isValidTime(): boolean {
    return !!this.config.time;
  }

}

class DefaultOption implements Option {

  constructor(
    public value: string,
    public label: string
  ) { }
}