import { autoinject, LogManager } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';

import { BootstrapFormRenderer } from 'zailab.common';
import { WebhookService } from '../webhook-service';
import { TableInputModel } from "../../../../../components/custom/table-input";

const logger = LogManager.getLogger('AddWebhook');

@autoinject()
export class AddWebhook {
  
  public name: string;
  public tableInputHeaders: TableInputModel;
  public responseType: string = 'JSON';
  public live: boolean = false;

  private interactionFlowId: string;
  private webhooks: any[] = [];
  private validation: any;

  constructor(
    validationControllerFactory: ValidationControllerFactory,
    private webhookService: WebhookService,
    private dialogController: DialogController
  ) {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(data: { data: { interactionFlowId: string; live: boolean } }): void {
    this.interactionFlowId = data.data.interactionFlowId;
    this.live = data.data.live;

    this.initValidation();
    this.initTableInputHeaders();
  }

  private initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let item of this.webhooks) {
          if (item.name.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      }, 'Please enter a unique name.');

    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a webhook name.')
      .satisfiesRule('uniqueName')
      .on(this);
  }

  private initTableInputHeaders(): void {
    const builder = TableInputModel.builder
      .setHeaders('Header Name', 'Header Value')
      .hideHeaders();

    this.tableInputHeaders = new TableInputModel([builder.build()]);
  }

  public selectJSON(): void {
    this.responseType = 'JSON';
  }

  public selectXML(): void {
    this.responseType = 'XML';
  }

  public rowAdded(): void {

    let scrollContainer = document.querySelector('#scroll-container');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }

  private extractTableContent(): { [key: string]: string } {
    const rows = this.tableInputHeaders.table.rows;
    let map = {};

    if (rows) {
      rows.forEach(row => {
        map[row.cells[0]] = row.cells[1];
      });
    }
    return map;
  }

  public createAndAssignWebhook(): void {
    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }

      this.webhookService
        .createAndAssignWebhook(
          this.interactionFlowId,
          this.name,
          this.responseType,
          this.extractTableContent(),
          this.live
        )
        .then(() => this.dialogController.ok());
    });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}