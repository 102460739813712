import { inject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore } from 'zailab.common';
import { WebSocketKiosk } from './websocket.kiosk';
import { UrlFactory } from '../factories/url.factory';
import { ApplicationProperties } from '../../_config/application.properties';
import { CameraService } from './camera.service';
import { PEER_SERVICE_EVENTS } from './peer.service.events';
import Peer from 'zaipeerjs';
import { v4 as uuidv4 } from 'uuid';

const PEER_EVENTS = {
  DISCONNECTED: 'disconnected',
  OPENED: 'open',
  CALL_RECEIVED: 'incoming-call',
  CALL_ANSWERED: 'call-answered',
  CALL_DISCONNECTED: 'end-call'
};
const logger = LogManager.getLogger('PeerService');

@inject(WebSocketKiosk, SessionStore, UrlFactory, ApplicationProperties, EventAggregator, CameraService)
export class PeerService {
  ready = false;
  peer = null;
  remotePeerId = null;
  remoteConnection = null;
  remoteStream = null;
  localStream = null;
  remoteStreamURL = null;
  hasNoStreamObserver = true;
  forceClosed = false;

  constructor(webSocketKiosk, sessionStore, urlFactory, applicationProperties, eventAggregator, cameraService) {
    this.webSocketKiosk = webSocketKiosk;
    this.sessionStore = sessionStore;
    this.urlFactory = urlFactory;
    this.applicationProperties = applicationProperties;
    this.eventAggregator = eventAggregator;
    this.cameraService = cameraService;
  }

  registerPeer() {
    const user = this.sessionStore.get.user;
    const organisation = this.sessionStore.get.organisation;
    if (user.hasAgentRole === false || (this.webSocketKiosk.ws && [0, 1].indexOf(this.webSocketKiosk.ws.readyState) !== -1)) {
      logger.info('REGISTER PEER SKIPPED BECAUSE USER ROLE DOES NOT ALLOW CALLS OR AN EXISTING WS WAS FOUND WITH CONNECTING/OPEN READYSTATE : this.socket = ', this.socket, ' this.webSocketKiosk = ', this.webSocketKiosk);
      return;
    } else {
      logger.info('REGISTER PEER BECAUSE USER ROLE DOES ALLOW CALLS AND NO EXISTING WS WAS FOUND OR EXISTING WS WAS FOUND WITHOUT CONNECTING/OPEN READYSTATE : this.socket.ws = ', this.webSocketKiosk.ws);
    }

    if (!organisation || !organisation.country) {
      setTimeout(() => {
        this.registerPeer();
      }, 1000);
      return;
    }

    this.ready = false;

    const userId = user.userId;
    let host = this.applicationProperties.apiInteractionEndpoint;
    const organisationCountryCode = organisation.country.code;

    let region = null;

    if (host && host.includes('region')) {
      if (organisationCountryCode === 'US') {
        region = 'usw2';
      } else if (organisationCountryCode === 'EU') {
         region = 'eu';
      } else {
        region = 'za';
      }
      host = host.replace('region', region);
    }

    this.webSocketKiosk.io.uri = this.urlFactory.buildSearchUrl(host, `id=${userId}&token=token`);

    this.webSocketKiosk.subscribe('END-CALL', data => {
      this.disconnectCall();
    });

    this.webSocketKiosk.connect(() => {
      this.peer = new Peer(userId, {
        debug: 3,
        websocket: this.webSocketKiosk.ws,
        token: uuidv4()
      });

      this.peer.on(PEER_EVENTS.DISCONNECTED, peer => {
        if (!this.forceClosed) {
          this.webSocketKiosk.webSocketClosed();
        }
      });

      this.peer.on(PEER_EVENTS.OPENED, peer => {
        this.ready = true;
        this.eventAggregator.publish(PEER_SERVICE_EVENTS.PEER_REGISTERED);
      });

      this.peer.on(PEER_EVENTS.CALL_RECEIVED, (remotePeerId, connection) => {
        this.remotePeerId = remotePeerId;
        this.remoteConnection = connection;

        this.eventAggregator.publish(PEER_SERVICE_EVENTS.PEER_CALL_RECEIVED);
      });

      this.peer.on(PEER_EVENTS.CALL_ANSWERED, connection => {
        this.eventAggregator.publish(PEER_SERVICE_EVENTS.PEER_CALL_ANSWERED, { connection: connection });
      });

      this.peer.on(PEER_EVENTS.CALL_DISCONNECTED, () => {
        this.disconnectCall();
      });
    });
  }

  close() {
    if (this.peer) {
      this.peer.on(PEER_EVENTS.DISCONNECTED, () => {});
      this.peer.on(PEER_EVENTS.OPENED, () => {});
      this.peer.on(PEER_EVENTS.CALL_RECEIVED, () => {});
      this.peer.on(PEER_EVENTS.CALL_ANSWERED, () => {});
      this.peer.on(PEER_EVENTS.CALL_DISCONNECTED, () => {});

      this.peer.destroy();
      this.forceClosed = true;
      this.webSocketKiosk.close();
    }
  }

  acceptCall(callback) {
    try {
      this.cameraService.getMediaStream((error, stream) => {
        if (error) {
          callback(error);
          return;
        }

        this.localStream = stream;
        if (this.remoteConnection) {
          this.remoteConnection.on('stream', remoteStream => {
            this.remoteStream = remoteStream;
            this.remoteStreamURL = URL.createObjectURL(remoteStream);

            callback(null);
          });

          this.remoteConnection.answer(this.localStream);
          return;
        }

        let result = this.peer.acceptCall(this.remotePeerId, this.localStream);

        result.call.on('stream', remoteStream => {
          this.remoteStream = remoteStream;
          this.remoteStreamURL = URL.createObjectURL(remoteStream);

          callback(null);
        });
      });
    } catch (exception) {
      callback(exception);
    }
  }

  rejectCall(callback) {
    this.peer.rejectCall(this.remotePeerId);
    callback();
  }

  disconnectCall() {
    stopTracks(this.localStream);
    stopTracks(this.remoteStream);

    setTimeout(() => {
      this.remoteConnection = null;
      this.localStream = null;
      this.remoteStream = null;
      this.remoteStreamURL = null;
    });

    this.eventAggregator.publish(PEER_SERVICE_EVENTS.PEER_CALL_DISCONNECTED);
  }

  endCall() {
    this.peer.disconnectFromCall(this.remotePeerId, this.remoteConnection);

    this.disconnectCall();
  }
}

function stopTracks(stream) {
  if (!stream) {
    return;
  }

  let tracks = stream.getTracks();
  for (let track of tracks) {
    track.stop();
    track.enabled = false;
  }
}
