import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('WorkTypesListModel');
/*
 */
export default class extends BaseModel {

  _id = null;
  id = null;
  templateId = null;
  templateName = null;
  name = null;
  channel = null;
  flowId = null;
  flowName = null;
  flowType = null;
  callbackAssignedInteractionFlowIds = null;
  assignedToFlow = null;
  businessValue = null;
  roles = null;
  service = null;
  sites = null;
  skillGroups = null;
  targetResponse = null;
  waitTime = null;
  interactionFlows = null;
  businessPartner = null;
  businessPartners = null;
  contactCentre = null;
  contactCentres = null;

  constructor(taskTemplate) {
    super();
    this.mapProperties(taskTemplate);
    if(taskTemplate.taskTemplateId || taskTemplate._id || taskTemplate.id) {
      this.templateId = taskTemplate.taskTemplateId || taskTemplate._id || taskTemplate.id;
    }
    if(taskTemplate.name) {
      this.templateName = taskTemplate.name;
    }
  }

  @computedFrom('flowType')
  get icon() {
    if(this.flowType) {
      return this.flowType.replace(/ /g, '-').toLowerCase();
    }
  }

  @computedFrom('callbackAssignedInteractionFlowIds')
  get isCallback() {
    return this.callbackAssignedInteractionFlowIds && this.callbackAssignedInteractionFlowIds.length > 0;
  }

  @computedFrom('isCallback')
  get descriptionTwo() {
    if (this.isCallback) {
      return 'Assigned as Callback';
    } else if (this.interactionFlows && this.interactionFlows.length === 1) {
      return 'Assigned to a flow';
    } else if (this.interactionFlows && this.interactionFlows.length > 1) {
      return 'Assigned to flows';
    }
  }

  @computedFrom('flowId')
  get canDelete() {
    return !this.interactionFlows || this.interactionFlows.length === 0;
  }
}