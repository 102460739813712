import {LogManager, inject, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZUtilityButton');
/*
 */
@customElement('z-utility-button')
@inject(Element)
export class ZUtilityButton {

  @bindable icon;
  @bindable text;
  @bindable focused;
  @bindable spaced = true;
  @bindable position;
  @bindable processing;
  @bindable size;
  @bindable color;
  @bindable({attirbute: 'no-corners'}) noCorners = false;

  constructor(element) {

    this.element = element;
  }

  select() {

    this.element.dispatchEvent(
      new CustomEvent('this', {
        bubbles: true,
        detail: {}
      })
    );
  }

}
