export const ACCOUNT_INFO_VALIDATION_MESSAGES = {
  COMPANY_ADDRESS:  {NOT_EMPTY: 'Please enter your Company Address.'},
  CONTACT_EMAIL:    {NOT_EMPTY: 'Please enter a valid Contact Email Address.',          IS_VALID: 'Your Email Address must contain @ and a full stop.'},
  CONTACT_NAME:     {NOT_EMPTY: 'Please enter a Contact First Name.',                   IS_VALID: 'Please enter a valid First Name.'},
  CONTACT_LASTNAME: {NOT_EMPTY: 'Please enter a Contact Last Name.',                    IS_VALID: 'Please enter a valid Last Name.'},
  CONTACT_SURNAME:  {NOT_EMPTY: 'Please enter a Contact Last Name.',                      IS_VALID: 'Please enter a valid Last Name.'},
  COMPANY_NAME:     {NOT_EMPTY: 'Please enter your Company Name.'},
  REGION:           {NOT_EMPTY: 'Please enter a valid Region Name.'},
  POSTAL_CODE:      {NOT_EMPTY: 'Please enter a valid Postal Code.',                    IS_VALID: 'Postal Code must be numbers.'},
  ZIP_CODE:         {NOT_EMPTY: 'Please enter a valid Zip+4 Address.'},
  VAT_NUMBER:       {IS_VALID: 'Invalid VAT number. Please enter a VAT number of 10 digits without hyphens.'},
  COUNTRY:          {NOT_EMPTY: 'Please select a Country.'},
  CITY:             {NOT_EMPTY: 'Please enter a valid City.'}
};
