export class WidgetModel {
  public title: string;
  public view: string;
  public widgetId: string;
  public isPlaceHolder: boolean;
  public isChild: boolean;
  public childWidgets: WidgetModel[] = [];
  public isVisibleForOrganisation?: boolean;
  public widgetHeightSpan: number;
  public widgetWidthSpan: number;

  constructor(title: string, view: string, widgetId: string, isChild: boolean, isPlaceHolder: boolean = false, isVisibleForOrganisation: boolean = true, widgetHeightSpan?: number, widgetWidthSpan?: number) {
    this.title = title;
    this.view = view;
    this.widgetId = widgetId;
    this.isPlaceHolder = isPlaceHolder;
    this.isChild = isChild;
    this.isVisibleForOrganisation = isVisibleForOrganisation;
    this.widgetHeightSpan = widgetHeightSpan;
    this.widgetWidthSpan = widgetWidthSpan;
  }
}