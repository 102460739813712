export const MESSAGE_EVENTS = {
  INFO: 'zailab.incoming.notification',
  ERROR: 'zailab.error.internal',
  ERROR_IMPORTANT: 'zailab.error.internal.important',
  ERROR_UNHANDLED: 'zailab.error.unhandled',
  WARNING: 'zailab.warning.internal',
  SUCCESS: 'zailab.success.internal',
  NOTIFICATION: 'zailab.message.notification',
  INCOMING_CALL_NOTIFICATION: 'zailab.notification.incoming.call',
  MISSED_CALL: 'zailab.notification.missed.call',
  CALL_CONNECTED: 'zailab.notification.connected.call',
  START_OUTBOUND_CALL_INTERACTION: 'zailab.notification.call.started',
  START_OUTBOUND_INTERACTION: 'zailab.notification.start.interactions',
  ENABLE_AUTOMATION: 'zailab.notification.set.automation',
  INTERACTION_BLOCKED: 'zailab.notification.block.interactions',
  INTERACTION_CONNECTED: 'zailab.interactions.connected',
  INTERACTION_ENDED: 'zailab.interactions.ended',
};
