import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import {
  Aurelia,
  autoinject,
  computedFrom,
  FrameworkConfiguration,
  LogManager,
  observable,
} from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import {
  NavigationInstruction,
  Router,
  RouterConfiguration,
} from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import {
  AudioService,
  AUDIO_ASSETS,
  DraggableService,
  ExclusionListService,
  PeerService,
  PushNotificationService,
  SessionStore,
  SESSION_EVENTS,
  WebSocket,
} from 'zailab.common';
import { EmbeddedWebphone } from '../components/organisms/embeddedwebphone/embedded-webphone';
import { WebphoneService } from '../components/organisms/embeddedwebphone/webphone-service';
import { FeatureFlagService } from '../features/featureflags/feature-flag-service';
import { AlertPriority } from '../features/organisation/alert/alert-model';
import { AlertService } from '../features/organisation/alert/alert-service';
import { ConfirmDialog } from '../features/organisation/conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';
import { ROUTING_STATUS } from '../features/organisation/member/routing-status/routing-status';
import { RoutingStatusActivity } from '../features/organisation/member/routing-status/routing-status-activity';
import { RoutingStatusService } from '../features/organisation/member/routing-status/routing-status-service';
import { LoginService } from '../features/user/passport/login/login-service';
import { UserPassportService } from '../features/user/passport/user-passport-service';
import { DisplayMessageService } from '../_common/services/display.message.service';
import { OrganisationSessionModel } from '../_common/stores/sessionmodels/organisation-session-model';
import { UserSessionModel } from '../_common/stores/sessionmodels/user-session-model';
import { HUD_LEVEL_ROUTES } from './hud-level-routes';
import { QuillImageUpload } from '../components/atoms/wysiwyg/quill-plugin-image-upload/quill-image-upload';
import { initPlaceHolder } from '../components/atoms/wysiwyg/quill-plugin-image-upload/quill-image-upload-placeholder';
import { InAppAlertsNotificationsService } from '../features/organisation/in-app-alerts/in-app-alerts-notifications-service';

import Quill from 'quill';

declare const PLATFORM: any;

const logger = LogManager.getLogger('HeadsUpDisplay');

@autoinject()
export class HeadsUpDisplay {
  private isTwelveColumnRoute: boolean;
  private isLogoutDisabled: boolean;
  private isHomeNavigationDisabled: boolean;
  private isNavigationProcessing: boolean = false;
  private isAgentEligibleToLogout: boolean = true;
  private isViewToIgnore: boolean;
  private isMuted: boolean = false;
  private loggedInUserRole: string = '';
  private showTutorials: boolean = false;
  private roleSubscription: Subscription;
  private tutorialSubscription: Subscription;
  private showLoader: boolean = false;
  private allSubscriptions: Subscription[] = [];
  private isLoaderHidden: boolean;
  private updateSubscription: Subscription;
  private predictiveDiallerEnabled: boolean = false;
  public activeRoute: string;

  private embeddedWebphoneEnabled: boolean = false;
  private webphoneSource: string;
  private webphoneWindow;
  @observable public isOnCall: boolean; // two-way binding on z-embedded-webphone
  @observable public routingStatus: string; // two-way binding on z-embedded-webphone

  public isOnCallChanged(newValue: any, oldValue: any): void {
    // this is required to have two-way binding to share state
  }

  public routingStatusChanged(newValue: any, oldValue: any): void {
    // this is required to have two-way binding to share state
  }

  public routingStatusChannelAvailabilityEnabled: boolean = false;
  public chatContent: string = '';
  public inHouseWFMConfigEnabled = false;

  constructor(
    private aurelia: Aurelia,
    private router: Router,
    private eventAggregator: EventAggregator,
    private httpClient: HttpClient,
    private sessionStore: SessionStore,
    private peerService: PeerService,
    private audioService: AudioService,
    private routingStatusActivity: RoutingStatusActivity,
    private loginService: LoginService,
    private exclusionListService: ExclusionListService,
    private userPassportService: UserPassportService,
    private draggableService: DraggableService,
    private webSocket: WebSocket,
    private pushNotificationService: PushNotificationService,
    private featureFlagService: FeatureFlagService,
    private displayMessageService: DisplayMessageService,
    private alertService: AlertService,
    private routingStatusService: RoutingStatusService,
    private dialogService: DialogService,
    private inAppAlertsNotificationsService: InAppAlertsNotificationsService,
    webphoneService: WebphoneService
  ) {
    const localWebphoneEnv = 'dev1';
    const source: string | LocalWebphoneConfig = webphoneService.getSource();
    if (typeof source === 'string') {
      this.webphoneSource = source;
    } else if (localWebphoneEnv in source) {
      this.webphoneSource = source[localWebphoneEnv];
    }
  }

  public activate(): Promise<void> {
    this.initFeatureflagRequests();
    this.registerQuillModules();

    this.httpClient.configure((req) => {
      req.withHeader('Authorization', 'Bearer ' + this.user.token);
    });
    // @ts-ignore
    this.ignoreViewClass(this.router.history.fragment);
    this.initialise();
    this.createSubscriptions();
    this.inAppAlertsNotificationsService.initialise();

    let frameworkConfiguration = new FrameworkConfiguration(
      this.aurelia
    ).feature(PLATFORM.moduleName('features/index'));

    return frameworkConfiguration.apply();
  }

  private initFeatureflagRequests(): void {
    this.featureFlagService
      .isEnabled('JiraChatEnabled')
      .then(async (jiraChatEnabled) => {
        if (jiraChatEnabled) {
          this.showChatElement('#spartez-support-chat-container');
        }
      });
      
    this.featureFlagService
      .isEnabled('InHouseWFM')
      .then(inHouseWFMConfigEnabled => this.inHouseWFMConfigEnabled = inHouseWFMConfigEnabled);
  }

  private showChatElement(selector: string): void {
    let getElelent = (): void => {
      let el: HTMLElement = document.querySelector(selector);
      if (!el) {
        setTimeout(() => {
          getElelent();
        }, 1000 / 3);
        return;
      }
      el.setAttribute(
        'style',
        'display: block; opacity: 1 !important; z-index: 99000!important'
      );
    };
    getElelent();
  }

  private hideChatElement(selector: string): void {
    let getElelent = (): void => {
      let el: HTMLElement = document.querySelector(selector);
      if (!el) {
        setTimeout(() => {
          getElelent();
        }, 1000 / 3);
        return;
      }
      el.setAttribute('style', 'display: none; opacity: 0 !important;');
    };
    getElelent();
  }

  private registerQuillModules(): void {
    Quill.register('modules/imageUpload', QuillImageUpload);
    initPlaceHolder();
  }

  private ignoreViewClass(uri: any): void {
    this.isViewToIgnore =
      uri.includes('/dashboard') ||
      uri.includes('/user/profile') ||
      uri.includes('/conversation') ||
      uri.includes('/reports') ||
      uri.includes('/forms/create');
  }

  private initialise(): void {
    if (this.user) {
      this.appendUserRolesToDOM();
      this.setUpOrganisationInformation();
    }
    this.featureFlagService.isEnabled('predictive-dialler').then(
      (response) => {
        this.predictiveDiallerEnabled = response;
      },
      (error) => {
        logger.error('Unable to retrieve feature predictive-dialler ');
      }
    );

    this.featureFlagService
      .isEnabled('routing-status-channel-availability')
      .then(
        (response) => {
          this.routingStatusChannelAvailabilityEnabled = response;
        },
        (error) => {
          logger.error('Unable to retrieve feature predictive-dialler ');
        }
      );

    if (this.isAgent) {
      this.featureFlagService
        .isEnabled('embeddedWebphoneEnabled')
        .then((embeddedWebphoneEnabled) => {
          this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
        });
    }
  }

  private appendUserRolesToDOM(): void {
    if (this.user.userAccessRoles) {
      let _userRolesConcatenated = '';
      this.user.userAccessRoles.forEach((_value) => {
        _userRolesConcatenated += _value
          ? _value.role.replace('_', '-').toLowerCase() + ' '
          : '';
      });
      this.loggedInUserRole = _userRolesConcatenated;
    }
  }

  private setUpOrganisationInformation(): void {
    let organisationId = this.organisation
      ? this.organisation.organisationId
      : null;

    if (organisationId) {
      this.loginService.displayChannelsView(organisationId);
      this.loginService
        .displayOrganisationView(organisationId)
        .then((response) => {
          if (response && response.organisation) {
            let organisationInfo = response.organisation;
            this.eventAggregator.publish(
              SESSION_EVENTS.ORGANISATION.EVENT,
              organisationInfo
            );
          }
        })
        .catch((error) => {
          logger.warn(
            ' An error occurred on displayOrganisationView query ',
            error
          );
        });
    }
  }

  private createSubscriptions(): void {
    this.createRoleBasedSubscriptions();
    this.userPassportService.subscribeToUserAccessRoleChanges();
    this.allSubscriptions.push(
      this.eventAggregator.subscribe('app:loader:show', () =>
        this.toggleLoader(true, null)
      )
    );
    this.allSubscriptions.push(
      this.eventAggregator.subscribe('app:loader:hide', (_config) =>
        this.toggleLoader(false, _config)
      )
    );
    this.allSubscriptions.push(
      this.eventAggregator.subscribe('router:navigation:complete', (data) =>
        this.navigationSuccess(data)
      )
    );
    this.allSubscriptions.push(
      this.eventAggregator.subscribe('router:navigation:processing', (data) =>
        this.handleRouterNavigationProcessing(data.instruction)
      )
    );
    this.allSubscriptions.push(
      this.eventAggregator.subscribe('update.account.status', () =>
        this.appendUserRolesToDOM()
      )
    );
    this.allSubscriptions.push(
      this.eventAggregator.subscribe('logout.removed.member', () =>
        this.logout()
      )
    );
    this.allSubscriptions.push(
      this.eventAggregator.subscribe(
        'current.agent.status.ready',
        (routingStatus) => {
          if (
            !this.embeddedWebphoneEnabled &&
            routingStatus.toLowerCase() ===
              ROUTING_STATUS.NOT_READY.toLowerCase()
          ) {
            // trigger change routing status if webphone is open
            try {
              // try catch resolved cors
              if (this.webphoneWindow && !this.webphoneWindow.closed) {
                this.routingStatusService.setRoutingStatusToReady(
                  this.user.memberId
                );
                return;
              }
              this.displayWebphone(true);
            } catch (e) {
              this.routingStatusService.setRoutingStatusToReady(
                this.user.memberId
              );
            }
          }
        }
      )
    );
    this.alertService.onAlertMessage((alertData) => {
      const alertMessage = alertData.messages[alertData.messages.length - 1];
      switch (alertMessage.priority) {
        case AlertPriority.LOW:
          this.displayMessageService.showClosableInfo(alertMessage.message);
          break;
        case AlertPriority.MEDIUM:
          this.displayMessageService.showClosableWarning(alertMessage.message);
          break;
        case AlertPriority.HIGH:
          this.displayMessageService.showClosableError(alertMessage.message);
          break;
        default:
          this.displayMessageService.showClosableSuccess(alertMessage.message);
          break;
      }
    });
  }

  private async createRoleBasedSubscriptions(): Promise<void> {
    if (this.user.hasAgentRole) {
      this.registerStatusEvents();
      this.subscribeToInteractions();

      await this.pushNotificationService.requestPermission();
    } else if (this.user.hasAdministratorRole) {
      this.subscribeToOrganisationCreatedEvent();
    }
  }

  private handleRouterNavigationProcessing(
    instruction: NavigationInstruction
  ): void {
    this.isNavigationProcessing = true;
    const uri = instruction.fragment;
    this.ignoreViewClass(uri);
  }

  private registerStatusEvents(): void {
    this.eventAggregator.subscribe('current.agent.status', (routingStatus) => {
      this.isAgentEligibleToLogout =
        routingStatus && routingStatus.toLowerCase() !== 'ready';
    });
  }

  private subscribeToOrganisationCreatedEvent(): void {
    this.webSocket.subscribe({
      name: 'com.zailab.user.organisationregistration.api.events.OrganisationRegisteredEvent',
      callback: (response) => {
        let organisation = response.state;
        this.eventAggregator.publish(
          SESSION_EVENTS.ORGANISATION.EVENT,
          organisation
        );
      },
    });
  }

  public attached(): void {
    if (this.user.hasAgentRole) {
      this.peerService.registerPeer();
      this.exclusionListService.retrieveExclusionList();
      this.routingStatusActivity.initialise();
    }

    this.showChatElement('#arn-widget-container');
    this.hideChatElement('#arnBarWidgetDiv');
    this.isMuted = this.audioService.muted;
  }

  private toggleLoader(_state: boolean, _config: any): void {
    this.showLoader = _state;
    if (_state === false && _config && _config.message) {
      try {
        this.showToast(_config);
      } catch (e) {
        // Could not show toastr
      }
    }
  }

  public async displayWebphone(toggleRoutingStatus: boolean): Promise<void> {
    const accessCode = await this.loginService.retrieveAccessToken();
    try {
      // try catch resolved cors
      if (this.webphoneWindow && !this.webphoneWindow.closed) {
        return;
      }
      this.webphoneWindow = window.open(
        `${this.webphoneSource}?accessCode=${accessCode}&toggleRoutingStatus=${toggleRoutingStatus}`,
        'window',
        'toolbar=no,menubar=no,resizable=no,width=302,height=602,location=no'
      );
    } catch (e) {}
  }

  private showToast(_config: any): void {
    if (!_config.toastrType) {
      _config.toastrType = 'success';
    }
    // @ts-ignore
    toastr[_config.toastrType](_config.message, '', {
      progressBar: false,
      preventDuplicates: true,
      positionClass: 'toast-top-right',
      closeMethod: 'slideUp',
      timeOut: 7000,
      escapeHtml: false,
      showDuration: 500,
      hideDuration: 500,
      extendedTimeOut: 5000,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    });
  }

  private configureRouter(config: RouterConfiguration, router: Router): void {
    config.map(HUD_LEVEL_ROUTES);
    this.router = router;
  }

  private navigationSuccess(data: any): void {
    this.isNavigationProcessing = false;
    let navigationInstruction = data.instruction;
    this.activeRoute = navigationInstruction.params.childRoute;

    this.isTwelveColumnRoute = navigationInstruction
      .getAllInstructions()
      .some((i) => i.config.isTwelveColumnRoute);
    this.isLoaderHidden = navigationInstruction
      .getAllInstructions()
      .some((i) => i.config.isLoaderHidden);
    this.isLogoutDisabled = navigationInstruction
      .getAllInstructions()
      .some((i) => i.config.isLogoutDisabled);
    this.isHomeNavigationDisabled = navigationInstruction
      .getAllInstructions()
      .some((i) => i.config.isHomeNavigationDisabled);
  }

  private toggleMute(): void {
    this.isMuted = !this.isMuted;
    this.audioService.toggleMute(this.isMuted);

    if (this.isMuted === false) {
      this.audioService.play(AUDIO_ASSETS.TOGGLE_SOUND_ON, { volume: 0.5 });
    }
  }

  private logout(): void {
    if (this.embeddedWebphoneEnabled) {
      if (
        this.isOnCall ||
        (this.routingStatus && this.routingStatus.toLowerCase() === 'ready')
      ) {
        const message = this.isOnCall
          ? EmbeddedWebphone.WARNING_MESSAGE.ON_CALL
          : EmbeddedWebphone.WARNING_MESSAGE.ON_DUTY;
        this.showConfirmationPop(message).then(() => this.continueLoggingOut());
      } else {
        this.continueLoggingOut();
      }
    } else {
      this.continueLoggingOut();
    }
  }

  private continueLoggingOut(): void {
    window.onbeforeunload = () => {};
    this.hideChatElement('#arn-widget-container');
    this.router.parent.navigate('logout');
  }

  private showConfirmationPop(message: string): Promise<void> {
    return new Promise((resolve) => {
      this.dialogService
        .open({ viewModel: ConfirmDialog, model: { message } })
        .whenClosed((dialog) => {
          if (!dialog.wasCancelled) {
            resolve();
          }
        });
    });
  }

  public navigateHome(): void {
    this.router.navigate('dashboard');
  }

  public navigateToCompanyDirectory(): void {
    this.router.navigate('members');
  }

  public navigateToMemberShifts(): void {
    this.router.navigate('in-house-wfm/shift');
  }

  private subscribeToInteractions(): void {
    setTimeout(() => {
      this.draggableService.initialise('draggable');
    }, 50);
  }

  /* Getters and setters */

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('user')
  private get memberId(): string {
    return this.user.memberId;
  }

  @computedFrom('user.hasOutboundChannel')
  private get hasOutboundFlows(): boolean {
    return this.user && this.user.hasOutboundChannel;
  }

  @computedFrom('user.hasAdministratorRole')
  public get isAdmin(): boolean {
    if (this.user) {
      return this.user.hasAdministratorRole;
    }
    return false;
  }

  @computedFrom('user.hasAgentRole')
  public get isAgent(): boolean {
    if (this.user) {
      return this.user.hasAgentRole;
    }
    return false;
  }

  @computedFrom('user')
  private get hasOrganisation(): boolean {
    if (this.user) {
      return this.user && this.user.userAccessRoles.length > 1;
    }
    return false;
  }

  public deactivate(): void {
    this.userPassportService.unsubscribeFromUserAccessRoleChanges();
    this.allSubscriptions.forEach((subscription: Subscription) =>
      subscription.dispose()
    );
    this.alertService.offAlertMessage();
    this.inAppAlertsNotificationsService.dispose();
  }
}
