import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';

import {SessionStore, ApplicationProperties, UrlFactory, ArrayTools} from 'zailab.common';
import {TeamModel} from './models/team-model';
import {StatsModel} from './models/stats-model';
import {AgentModel} from './models/agent-model';
import {AgentDailyStatsModel} from './components/agentstatsdialog/agent-stats-dialog-model';
import {OrganisationSessionModel} from '../../../_common/stores/sessionmodels/organisation-session-model';
import { CCMStatModel } from './models/ccm-stat-model';
// @ts-ignore
import { AureliaConfiguration } from 'aurelia-configuration';

const logger = LogManager.getLogger('TeamLeaderService');

@autoinject
export class TeamLeaderService {

  private httpClient: HttpClient;
  private sessionStore: SessionStore;
  private applicationProperties: ApplicationProperties;
  private urlFactory: UrlFactory;

  countryRegionMap = new Map([
    ['US','usw2'],
    ['ZA','za'],
    ['AT','euw1'],
    ['BE','euw1'],
    ['EE','euw1'],
    ['ES','euw1'],
    ['FI','euw1'],
    ['FR','euw1'],
    ['DE','euw1'],
    ['GR','euw1'],
    ['IE','euw1'],
    ['IT','euw1'],
    ['LV','euw1'],
    ['LT','euw1'],
    ['LU','euw1'],
    ['MT','euw1'],
    ['NL','euw1'],
    ['PT','euw1'],
    ['SK','euw1'],
    ['SI','euw1']
  ]);
  private countryRegionMapDev1 = new Map([
    ['US','usw2'],
    ['ZA','za'],
    ['AT','eu'],
    ['BE','eu'],
    ['EE','eu'],
    ['ES','eu'],
    ['FI','eu'],
    ['FR','eu'],
    ['DE','eu'],
    ['GR','eu'],
    ['IE','eu'],
    ['IT','eu'],
    ['LV','eu'],
    ['LT','eu'],
    ['LU','eu'],
    ['MT','eu'],
    ['NL','eu'],
    ['PT','eu'],
    ['SK','eu'],
    ['SI','eu']
  ]);

  constructor(
    httpClient: HttpClient,
    sessionStore: SessionStore,
    applicationProperties: ApplicationProperties,
    urlFactory: UrlFactory,
    private aureliaConfiguration: AureliaConfiguration
  ) {

    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.applicationProperties = applicationProperties;
    this.urlFactory = urlFactory;
  }

  public retrieveTeams(): Promise<Array<TeamModel>> {
    return this.httpClient
      .createRequest(`v1/organisation/teams/me`)
      .asGet()
      .send()
      .then((response: any) => {
        let teamList: Array<TeamModel> = this.modelCollection(response.teams || [], TeamModel) as Array<TeamModel>;
        return teamList;
      });
  }

  public retrieveTeamStats(teamId: string): Promise<StatsModel> {
    return this.httpClient
      .createRequest(`v1/organisation/teams/${teamId}/statistics`)
      .asGet()
      .send()
      .then(response => {
        return new StatsModel(response);
      });
  }

  public retrieveTeamAgentsDailyActivity(teamId: string): Promise<AgentModel | AgentModel[]> {
    return this.httpClient
    .createRequest(`v1/organisation/teams/${teamId}/activity`)
    .asGet()
    .send()
    .then((response: any) => {
      let agents: AgentModel = this.modelCollection(response.memberDailyStatistics || [], AgentModel) as AgentModel;
      return agents;
    });
  }

  public retrieveTeamDailyActivity(teamId: string): Promise<{ team: any, members: any }> {
    return this.httpClient
      .createRequest(`v1/interaction/contact-centre-monitoring/teamstatistics/${teamId}`)
      .asGet()
      .send()
      .then((response: any) => {
        let mappedData = {
          team: this.modelCollection(response.team, CCMStatModel) as CCMStatModel,
          members: ArrayTools.sort(this.modelCollection(response.members || [], CCMStatModel) as CCMStatModel, 'fullName')
        }
        return mappedData;
      });
  }

  public retrieveAgentDailyStats(memberId: string): Promise<{ [key: string]: AgentDailyStatsModel }> {
 
    const uri: string = `v1/performancemanagement/agentDailyStatistics/${memberId}`;

    return this.retrieve(uri).then(
        (response : any)=> {

          let agentStats: { [key: string]: AgentDailyStatsModel } = {};

          if (response) {
			const keys = Object.keys(response.dailyInteractionStatistics);
			keys.forEach(key => agentStats[key] = new AgentDailyStatsModel(response.dailyInteractionStatistics[key]))
          }
          return agentStats;
        });
  }

  private retrieve(uri: string): Promise<any> {
    return this.httpClient.createRequest(uri)
      .asGet()
      .send();
  }

  public modelCollection(items: any, Model: any): object {

    if (!items) {
      return;
    }

    if (items instanceof Array) {

      let mappedList: Array<any> = [];

      for (let item of items) {

        mappedList.push(new Model(item))
      }

      return mappedList;
    }

    let mappedObject: object = new Model(items);

    return mappedObject;
  }

  public logout(passportId: string): any {
    const uri: string = 'v1/user/passports/' + passportId + '/logout';
    return this.httpClient.createRequest(uri)
      .asPut()
      .send();
  }

  public setRoutingStatus(memberId: string, routingStatus: string): any{
    const uri: string = 'v1/organisation/members/' + memberId + '/change-routing-status';
    return this.httpClient.createRequest(uri)
      .asPost()
      .withContent({
        routingStatus: routingStatus
      })
      .send();
  }

  public changePresence(userId: string, presence: string): any{
    const uri: string = 'v1/user/users/' + userId + '/presence';
    return this.httpClient.createRequest(uri)
      .asPut()
      .withContent({
        presence: presence
      })
      .send();
  }

  public spyCall(callId: string): Promise<void> {

    let organisationId: string = this.organisation.organisationId;
    let memberId: string = this.sessionStore.get.user.memberId;
    let uri: string = `snoop/${organisationId}/${memberId}/spy/${callId}`;

    return this.post(uri);
  }

  public whisperCall(callId: string): Promise<void> {

    let organisationId: string = this.organisation.organisationId;
    let memberId: string = this.sessionStore.get.user.memberId;
    let uri: string = `snoop/${organisationId}/${memberId}/whisper/${callId}`;

    return this.post(uri);
  }

  private post(uri: string): Promise<any> {
    let url: string = this.getRegionBaseUrl() + uri;
    return this.httpClient.post(url, {});
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  private getRegionBaseUrl(): string {
    let organisationCountryCode = this.sessionStore.get.organisation.country.code;
    let region = null;
    let url: string = this.applicationProperties.apiRouterEndpoint;
    // @ts-ignore
    const environment = this.aureliaConfiguration.environment;

    if (url && url.includes('region')) {
      if (environment === 'local' || environment === 'dev1') {
        region = this.countryRegionMapDev1.get(organisationCountryCode);
      } else {
        region = this.countryRegionMap.get(organisationCountryCode);
      }
      if(!region){
        region = 'za';
      }
      url = url.replace('region', region);
    }
    return url;
  }
}
