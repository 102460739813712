import { autoinject, LogManager, computedFrom, PLATFORM } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { SessionStore } from 'zailab.common';
import { ContactService } from '../../contact/contact-service';
import { InteractionService } from '../interaction-service'
import { CONTACT_ACTIONS, INTERACTION_ACTIONS } from "../../contact/contactcontroller/contact-controller-actions";
import { InfiniteScrollService } from "../../../../_common/services/infinitescroll-service";
import { PlaceholderService } from "../../../../_common/services/placeholder-service";
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
import { UserSessionModel } from '../../../../_common/stores/sessionmodels/user-session-model';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

let logger = LogManager.getLogger('RecentActivityView');

@autoinject()
export class RecentActivity {

  public activities = [];
  private recentActivityOplog: ZIOplog;
  private agentStatisticsOplog: ZIOplog;
  private page = 0;
  private size = 18;
  private scrollLoader = false;
  private searchParams = {
    fromDate: '',
    toDate: '',
    channel: '',
    conversationName: '',
    contactName: ''
  };
  private ready = false;
  private memberId;
  private container;
  private placeholders: any = 0;
  private queryIntervalEnabled = false;
  private queryIntervalIdentifier;
  private lastOplogTime;
  public embeddedWebphoneEnabled = null;
  private infiniteScroll: InfiniteScrollService;
  private placeholderService: PlaceholderService;
  private connectedInteractionSubscription: any;
  public showOpenAgentSchedule = false;

  constructor(
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private interactionService: InteractionService,
    private featureFlagService: FeatureFlagService
  ) {
    this.memberId = this.sessionStore.get.user.memberId;
  }

  public activate(): void {
    this.activities = [];
    this.featureFlagService.isEnabled('agent-schedule-calabrio')
      .then((enabled) => {
        this.showOpenAgentSchedule = enabled;
      });
    this.featureFlagService.isEnabled('queryIntervalRecentActivity')
      .then((enabled) => {
        this.queryIntervalEnabled = enabled;
      });
    if (this.isAgent) {
      this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
        .then(async (embeddedWebphoneEnabled) => {
          this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
        });
    }
  }

  public attached(): void {
    this.retrieveRecentActivity(false, false);
    this._notifyRoutingStatus();
    this.subscribe();
    this.infiniteScroll = new InfiniteScrollService(this.container, () => this.scrollEnd());
  }

  public generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.activities.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  public reGeneratePlaceholders(length: number): void {
    if (this.placeholderService) {
      this.placeholders = this.placeholderService.generatePlaceholders(length);
    }
  }

  public subscribe(): void {
    this.recentActivityOplog = this.interactionService.initialiseRecentActivityOplog(this.memberId)
    this.recentActivityOplog.on('insert', () => this.retrieveRecentActivity(false, true));
    this.recentActivityOplog.on('update', () => this.retrieveRecentActivity(false, true));

    this.eventAggregator.subscribe('get.recent.activity', () => {
      this.retrieveRecentActivity(false, true);
    });

    if (this.queryIntervalEnabled) {
      this.queryIntervalIdentifier = setInterval(() => {
        if (!this.lastOplogTime || Date.now() - this.lastOplogTime >= 30000) {
          this.retrieveRecentActivity(false, false);
        }
      }, 30000);
    }
  }

  public scrollEnd(): void {
    this.retrieveRecentActivity(true, false);
  }

  public resetPageIndex(): void {
    this.page = 0;
  }

  public retrieveRecentActivity(isScrolling: boolean, isOplog: boolean): void {

    if (isScrolling) {
      this.page++;
    } else {
      this.toggleReady(false);
    }

    if (isOplog) {
      this.lastOplogTime = Date.now();
    }

    this.interactionService.retrieveRecentActivity(this.memberId, this.page, this.size).then((response) => {

      this.activities = this.page === 0 ? response.activities : this.activities.concat(response.activities);

      this.activities.forEach((activity) => {
        if (activity.interaction.interactionType.toLowerCase() === 'inbound chat') {
          activity.interaction.to = `${this.sessionStore.get.user.firstName} ${this.sessionStore.get.user.surname}`;
        }
      });

      this.toggleScrollLoader();
      this.toggleReady(true);
      if (isOplog) {
        this.reGeneratePlaceholders(this.activities.length);
      } else {
        this.generatePlaceholders();
      }

    }, (error) => {
      logger.info(' Failed to retrieve recent activity > error = ', error);
      this.toggleScrollLoader();
      this.toggleReady(true);
      if (isOplog) {
        this.reGeneratePlaceholders(this.activities.length);
      } else {
        this.generatePlaceholders();
      }
    });
  }

  public toggleReady(value: boolean): void {
    this.ready = value;
  }

  private toggleScrollLoader(): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.loader = false;
    }
  }

  private _notifyRoutingStatus(): void {
    this.eventAggregator.publish('check.agent.activity');
  }

  public selectItem(item: any): void {
    if (item.isPlaceholder || (!item.interaction.from && item.interaction.channel !== 'CHAT')) {
      return;
    }
    item.interaction.interactionId = item.interaction.id;
    item.interaction.recentActivity = true;
    if (item.contact && item.contact.id) {
      item.interaction.contactId = item.contact.id;
      this.eventAggregator.publish(CONTACT_ACTIONS.SELECT_FROM_RECENT_ACTIVITY, item.interaction);
    } else {
      item.interaction.resumeLinking = true;
      item.interaction.correlationId = uuidv4();
      this.eventAggregator.publish(INTERACTION_ACTIONS.RESUME_INTERACTION_LINKING, item.interaction);
    }
  }

  public deactivate(): void {
    this.recentActivityOplog && this.recentActivityOplog.unsubscribe();
    this.agentStatisticsOplog && this.agentStatisticsOplog.unsubscribe();
    this.connectedInteractionSubscription && this.connectedInteractionSubscription.dispose();
    this.queryIntervalIdentifier && clearInterval(this.queryIntervalIdentifier);
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('user.hasAgentRole')
  public get isAgent(): boolean {
    if (this.user) {
      return this.user.hasAgentRole;
    }
    return false;
  }
}
