import {autoinject, LogManager, customElement, bindable} from 'aurelia-framework';
import {ContactModel} from "../../../../contact/contact-model";
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {observable} from "aurelia-binding";

const logger = LogManager.getLogger('Chat');

@customElement('z-chat')
@autoinject()
export class Chat {

  @bindable private contact: ContactModel;
  @observable private layerConversationId: string;
  private conversationView: any;
  private appending: any;
  private isFocusChat: boolean = false;
  private subscription: Subscription

  constructor(private eventAggregator: EventAggregator) {
  }

  public attached(): void {
    this.updateChatInteraction();
    this.subscribeToChatEnded();
  }

  private contactChanged(): void {
    this.updateChatInteraction();
  }

  private layerConversationIdChanged(): void {
    setTimeout(() => {
      this.conversationView = document.createElement('layer-conversation-view');
      this.appending = document.getElementById('appendToMe');
      if (this.conversationView && this.appending) {
        this.appending.insertBefore(this.conversationView, this.appending.firstChild);
        this.conversationView.conversationId = this.layerConversationId;
      } else {
        logger.info('Chat not appending correctly');
      }
    }, 200)
  }

  private updateChatInteraction(): void {
    if (this.contact && this.contact.servedInteraction) {
      let changedLayerConversationId = `layer:///conversations/${this.contact.servedInteraction.interactionId}`;
      if (!this.alreadyOnThisConversation(changedLayerConversationId)) {
        this.layerConversationId = changedLayerConversationId;
      }
    }
  }

  private alreadyOnThisConversation(changedLayerConversationId: string): boolean {
    if (this.layerConversationId && this.layerConversationId === changedLayerConversationId) {
      return true;
    }
  }

  private endChat(): void {
    if (this.conversationView && this.appending) {
      this.appending.removeChild(this.conversationView);
      this.conversationView.conversationId = this.layerConversationId;
      this.conversationView = null;
    }


    this.eventAggregator.publish('deselect.chat.channel');
    this.contact.servedInteraction = null;
    this.layerConversationId = null;
    this.eventAggregator.publish('end.interactions');
  }

  private showMediumChannel(): void {
    if (this.contact.channelViewStrategy.size === 'small') {
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    }
    this.isFocusChat = true;
  }

  private subscribeToChatEnded(): void {
    this.subscription = this.eventAggregator.subscribe('minimize.chat.window', () => {
      this.contact.channelViewStrategy.size = 'small';
      this.endChat();
    });
  }

  public detached(): void {
    this.subscription && this.subscription.dispose();
  }
}