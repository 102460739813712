import {inject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
* */
const logger = LogManager.getLogger('Campaigns');
/*
* */
@inject(Router)
export default class {

  constructor(router) {

    this.router = router;
  }

  configureRouter(config, router) {

    config.map([

      {route: '',  name: 'campaign dashboard', moduleId: PLATFORM.moduleName('features/campaigns/campaign/dashboard/campaign-dashboard'), nav: false, title: ''},
      {route: 'list',  name: 'campaign list', moduleId: PLATFORM.moduleName('features/campaigns/campaign/list/campaigns'), nav: false, title: 'Campaigns'},
      {route: 'audit',  name: 'campaign audit', moduleId: PLATFORM.moduleName('features/campaigns/campaign/audit/campaigns-audit'), nav: false, title: 'Campaigns Audit'},
      {route: ':campaignId',  name: 'prospects', moduleId: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/list/prospect-list'), nav: false, title: 'Prospects'},
      {route: ':campaignId/prospectlist',  name: 'upload prospects', moduleId: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/upload'), nav: false, title: 'Upload Prospects'},
      {route: ':campaignId/prospectlist/:prospectListId',  name: 'upload prospects', moduleId: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/list/view-prospect-list'), nav: false, title: 'View Prospect Lists'},
      {route: ':campaignId/prospectlists/schedule',  name: 'schedule prospect list download', moduleId: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/schedule'), nav: false, title: 'Schedule Prospect List Download'},
    ]);

    this.router = router;
  }
}
