import { inject, LogManager } from 'aurelia-framework';
/*
 */
import { BaseModel } from 'zailab.abstract';
/*
 */
import { v4 as uuidv4 } from 'uuid';
/*
 */
const logger = LogManager.getLogger('InteractionFlowModel');
/*
 */
export class OrganisationModel extends BaseModel {

  organisationRegistrationId = uuidv4();
  organisationName = null;
  initialAgentEmail = null;
  creator = null;
  timezone = null;
  country = null;
  Authorization = null;

  constructor(organisationInformation) {
    super();
    this.mapProperties(organisationInformation);
  }
}