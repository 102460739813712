import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 * */
import { ServicesService } from '../services-service';
import { SessionStore, BootstrapFormRenderer } from 'zailab.common';
import {ServiceModel} from '../../../../../_common/models/service-model';
/*
 * */
const logger = LogManager.getLogger('MemberListView');
/*
 * */
@inject(ServicesService, SessionStore, DialogController, ValidationControllerFactory)
export class AddServiceDialog {

  serviceList = [];
  name = '';
  serviceFormGroup;
  dialogHeader = "Add Service";
  isReady = true;

  constructor(servicesService, sessionStore, dialogController, validationControllerFactory) {
    this.servicesService = servicesService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param serviceList
   * */
  activate(serviceList) {
    this.serviceList = serviceList;
    this.initValidation();
  }

  initValidation() {

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let item of this.serviceList) {
          if (item.name.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a service name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.serviceFormGroup.reset();
    this.dialogController.cancel();
  }

  addService() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.dialogController.ok(this.name);
    }, error => {
      logger.info('addService > validation failure', error);
    });

  }

}

