import {HttpClient} from 'aurelia-http-client';
import {autoinject, LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('WebhookService');

@autoinject()
export class WebhookService {

  constructor(private httpClient: HttpClient) { }

  public createAndAssignWebhook(
    interactionFlowId: string,
    name: string,
    responseType: string,
    responseTypeValues: { [key: string]: any },
    live: boolean
  ): Promise<any> {
    
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/webhook`)
        .asPost()
        .withContent({
          name,
          responseType,
          responseTypeValues,
          live
        })
        .send()
        .then(
          (response) => resolve(response),
          (error) => reject(error)
        );
    });
  }

  public getAssignedWebhooks(interactionFlowId: string, live: boolean): Promise<any> {
    
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/webhooks`)
        .asGet()
        .withParams({ live })
        .send()
        .then(
          (response: any) => resolve(response ? response.webhooks : []),
          (error) => reject(error)
        );
    });
  }

  public updateWebhook(
    interactionFlowId: string,
    webhookId: string,
    responseType: string,
    responseTypeValues: { [key: string]: any }
  ): Promise<void> {
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/webhook`)
        .asPut()
        .withContent({
          webhookId,
          responseType,
          responseTypeValues
        })
        .send()
        .then(
          () => resolve(),
          (error) => reject(error)
        );
    });
  }

  public removeWebhookFromFlow(interactionFlowId: string, webhookId: string): Promise<void> {
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/webhook`)
        .asDelete()
        .withContent({ webhookId })
        .send()
        .then(
          () => resolve(),
          (error) => reject(error)
        );
    });
  }

  public retrieveWebhookAudit(id: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/interaction/interaction-flows/audit/webhook')
      .withParams({ id })
      .asGet()
      .send();
  }
}
