import {inject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
 */
@inject(Router)
export class OrganisationSurveys {

    constructor(router) {
      this.router = router;
    }

    configureRouter(config, router) {
      let routeMap = [
          {route: '',      name: 'list',  moduleId: PLATFORM.moduleName('./list/display-surveys'),   nav: false, title: 'List', isTwelveColumnRoute: true},
          {route: 'wizard',      name: 'wizard',  moduleId: PLATFORM.moduleName('./wizard/survey-wizard'),   nav: false, title: 'Wizard', isTwelveColumnRoute: true},
          {route: 'survey/:surveyId',      name: 'survey',  moduleId: PLATFORM.moduleName('./survey/survey'),   nav: false, title: 'Survey', isTwelveColumnRoute: true}
      ];
      config.map(routeMap);
      this.router = router;
    }

    activate(params) {
      if (params && params.add === 'true') {
        this.add = true;
      }
    }

    attached() {
      if (this.add) {
        this.router.navigate('wizard');
      }
    }
}
