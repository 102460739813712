/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZInlineFormElement');
/*
 */
@customElement('z-inline-form-element')
export class ZInlineFormElement {

  @bindable label;
  @bindable required;
  @bindable error;

}
