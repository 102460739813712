import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { EventAggregator } from "aurelia-event-aggregator";

import { ArrayTools, OplogService, SessionStore } from 'zailab.common';
import { visibilityAware, ZIVisibilityAware } from '../../../../_common/services/visibilityawareness/visibility-aware';
import { UserSessionModel } from '../../../../_common/stores/sessionmodels/user-session-model';
import { WidgetConfig } from './widget-config';
import { TeamLeaderDashboardService } from './team-leader-dashboard-service';
import { StatsBuilder } from '../../../interaction/dashboard/live-dashboard/components/stats-builder';
import { SegmentModel } from '../../../interaction/dashboard/live-dashboard/components/segment-model';
import { WaitingRoomWidgetModel } from "./waitingroomwidget/waiting-room-widget-model";

import moment from 'moment';
import { StatsConfig } from '../../../interaction/dashboard/live-dashboard/components/stats-config';
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';
import { PresenceService } from '../../../user/passport/presence/presence-service';

const logger = LogManager.getLogger('TeamLeaderDashboard');

@autoinject
@visibilityAware
export class TeamLeaderDashboard implements ZIVisibilityAware {

  public campaignsLiveDashboardEnabled: boolean = false;

  private dashboardId: string;
  private teams: Array<ZITeam> = [];
  private selectedTeam: ZITeam;
  private teamsReady: boolean = false;
  private services: Array<ZIService> = [];
  private selectedService: ZIService;
  private servicesReady: boolean = false;

  private lastRequestTimestamp: number;
  private signalerInterval: number;

  private agentAvailabilityWidget: WidgetConfig;
  private agentsWidgetConfig: StatsBuilder;
  private agentsOnDuty: any = [
    { property: 'CONVERSING', label: 'Conversing', count: 0 },
    { property: 'NOT_RESPONDING', label: 'Not Responding', count: 0 },
    { property: 'WAITING', label: 'Waiting', count: 0 }
  ];

  private agentsOffDuty: { property: string, label: string, count: number; color?: string; }[] = [];
  private agentStatus: any = [
    { property: 'READY', label: 'On Duty', count: 0 },
    { property: 'NOT_READY', label: 'Off Duty', count: 0 }
  ];
  public presences: { [key: string]: { presenceCodeName: string; color: string;property?: string; }} = {};

  private waitingRoomWidget: WidgetConfig;
  private waitingRoomWidgetData: WaitingRoomWidgetModel;
  private waitingRoomWaitingWidgetConfig: StatsConfig;
  private waitingRoomOutOfSLAWidgetConfig: StatsConfig;

  constructor(
    private teamLeaderDashboardService: TeamLeaderDashboardService,
    private oplogService: OplogService,
    private sessionStore: SessionStore,
    private router: Router,
    private bindingSignaler: BindingSignaler,
    private eventAggregator: EventAggregator,
    private featureFlags: FeatureFlagService,
    private presenceService: PresenceService
  ) {
    this.eventAggregator = eventAggregator;
  }

  public async activate(): Promise<void> {
    this.featureFlags.isEnabled('campaignsLiveDashboard').then((enabled) => this.campaignsLiveDashboardEnabled = enabled);
    this.agentAvailabilityWidget = new WidgetConfig(
      'Agent Availability',
      'Agent availability lets you know in real-time whether your agents are ready for work as well as an overview of what your agents are doing.',
      [{ action: 'view', label: 'view' }]
    );
    this.waitingRoomWidget = new WidgetConfig(
      'Waiting Room',
      'Waiting room lets you know in real-time how many calls and messages are waiting to be served to your agents.',
      [{ action: 'view', label: 'view' }]
    );

    await this.retrievePresences();
    
    this.retrieveMyTeams();
    this.retrieveMyServices();
    this.initSignaler();
  }
  
  private async retrievePresences(): Promise<void> {

    try {
      let presences: { presenceCodeName: string; color: string; }[] = await this.presenceService.retrievePresences();
      presences.forEach(presence => {
        this.presences[presence.presenceCodeName.toUpperCase()] = {
          ...presence,
          property: presence.presenceCodeName.toUpperCase()
        };
      });

    } catch(error) {
      logger.warn(`Could not retrieve presences for user ${this.sessionStore.get.user.email} >>`, error);
    }
  }


  private retrieveMyTeams(): void {
    this.teamsReady = false;
    this.teamLeaderDashboardService
      .retrieveMyTeams()
      .then(response => {
        if (response.teams.length > 0) {
          this.teams = response.teams as Array<ZITeam>;
          if (!this.selectedTeam) {
            this.selectTeam(this.getLocalTeam());
          }
        } else {
          this.teams = [];
        }
        this.teamsReady = true;
      })
      .catch((error: Error) => {
        logger.info(
          'An error occurred while trying to retrieve your live dashboard information. Error = ',
          error
        );
      });
  }

  private retrieveAgentsFromSelectedTeam(team: ZITeam): void {
    this.teamsReady = false;
    this.teamLeaderDashboardService
      .retrieveAgentsFromSelectedTeam(team.teamId)
      .then(agents => {
        if (agents.agentsByActivityState) {
          this.setAgentDataCounts(
            agents.agentsByActivityState,
            this.agentsOnDuty
          );
        }
        if (agents.agentsByPresence) {
          let agentsOffDuty = [];
          const presences = Object.keys(agents.agentsByPresence);
          presences.forEach(presence => {
            const colorConfig = this.presences[presence] ? this.presences[presence] : { color: 'var(--gray-alt)'};

            agentsOffDuty.push({
              ...colorConfig,
              presenceCodeName: presence,
              count: agents.agentsByPresence[presence].length
            })
          });

          this.agentsOffDuty = ArrayTools.sort(agentsOffDuty, 'presenceCodeName');
        }
        if (agents.agentsByRoutingStatus) {
          this.setAgentDataCounts(
            agents.agentsByRoutingStatus,
            this.agentStatus
          );
          this.setupAgentsWidgetConfig();
        }
        this.teamsReady = true;
      });
  }

  private retrieveMyServices(): void {
    const memberId = this.sessionStore.get.user.memberId;
    this.teamLeaderDashboardService
      .retrieveMemberServices(memberId)
      .then(response => {
        if (response.length > 0) {
          this.services = response as Array<ZIService>;
          if (!this.selectedService) {
            this.selectService(this.getLocalService());
          }
        } else {
          this.services = [];
        }
        this.servicesReady = true;
      })
      .catch((error: Error) => {
        logger.info(
          'An error occurred while trying to retrieve your live dashboard information. Error = ',
          error
        );
      });
  }

  private retrieveWaitingRoomWidgetData(service: ZIService): void {
    this.teamLeaderDashboardService
      .retrieveWaitingRoomWidgetData(service.serviceId)
      .then(widgetData => {
        this.waitingRoomWidgetData = widgetData;
        this.setupWaitingRoomWaitingWidgetConfig();
        this.setupWaitingRoomOutOfSLAWidgetConfig();
      });
  }

  private setAgentDataCounts(data: any, target: any): void {
    target.forEach((prop: any) => {
      prop.count = data[prop.property] ? data[prop.property].length : 0;
    });
  }

  private setupAgentsWidgetConfig(): void {
    let agentStatsBuilder: StatsBuilder = new StatsBuilder()
      .withSegmentsLabel('Agents')
      .withSegment(
        new SegmentModel(
          this.agentStatus[0].count || 0,
          'color-green',
          'On Duty',
        )
      )
      .withSegment(
        new SegmentModel(
          this.agentStatus[1].count || 0,
          'color-yellow',
          'Off Duty'
        )
      )
      .build();
    this.agentsWidgetConfig = agentStatsBuilder;
  }

  private setupWaitingRoomWaitingWidgetConfig(): void {
    let waitingRoomWaitingWidget: StatsConfig = new StatsBuilder()
      .withSegmentsLabel('Waiting')
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingCalls).length || 0, 'color-anakiwa', 'Calls', '',
        { label: 'Longest Waiting Call', value: this.waitingRoomWidgetData.longestWaitingCallTimestamp }))
      .withSegment(
        new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingEmails).length || 0, 'color-brilliant-rose', 'Emails', '',
          { label: 'Longest Waiting Email', value: this.waitingRoomWidgetData.longestWaitingEmailTimestamp })
      )
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingSmss).length || 0, 'color-bright-lilac', 'SMSs', '',
        { label: 'Longest Waiting SMS', value: this.waitingRoomWidgetData.longestWaitingSmsTimestamp }))
      .withSegment(
        new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingCallbacks).length || 0, 'color-cornflower-blue', 'Callbacks', '',
          { label: 'Longest Waiting Callback', value: this.waitingRoomWidgetData.longestWaitingCallbackTimestamp })
      )
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingWebchats).length || 0, 'color-electric-violet', 'Webchats', '',
        { label: 'Longest Waiting Webchat', value: this.waitingRoomWidgetData.longestWaitingWebchatTimestamp }))
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingIMs).length || 0, 'color-bright-green', 'Instant Messages', '',
        { label: 'Longest Waiting Instant Message', value: this.waitingRoomWidgetData.longestWaitingIMTimestamp }))

      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.waitingTickets).length || 0, 'color-yellow', 'Tickets', '',
        { label: 'Longest Waiting Ticket', value: this.waitingRoomWidgetData.longestWaitingTicketTimestamp }))

      .build();
    this.waitingRoomWaitingWidgetConfig = waitingRoomWaitingWidget;
  }

  private setupWaitingRoomOutOfSLAWidgetConfig(): void {
    let waitingRoomOutOfSLAWidget: StatsConfig = new StatsBuilder()
      .withSegmentsLabel('Out of SLA')
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaCalls).length || 0, 'color-anakiwa', 'Calls', '',
        { label: 'Longest Waiting Call', value: this.waitingRoomWidgetData.longestWaitingCallTimestamp }))
      .withSegment(
        new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaEmails).length || 0, 'color-brilliant-rose', 'Emails', '',
          { label: 'Longest Waiting Email', value: this.waitingRoomWidgetData.longestWaitingEmailTimestamp })
      )
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaSmss).length || 0, 'color-bright-lilac', 'SMSs', '',
        { label: 'Longest Waiting SMS', value: this.waitingRoomWidgetData.longestWaitingSmsTimestamp }))
      .withSegment(
        new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaCallbacks).length || 0, 'color-cornflower-blue', 'Callbacks', '',
          { label: 'Longest Waiting Callback', value: this.waitingRoomWidgetData.longestWaitingCallbackTimestamp })
      )
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaWebchats).length || 0, 'color-electric-violet', 'Webchats', '',
        { label: 'Longest Waiting Webchat', value: this.waitingRoomWidgetData.longestWaitingWebchatTimestamp }))
      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaIMs).length || 0, 'color-bright-green', 'Instant Messages', '',
        { label: 'Longest Waiting Instant Message', value: this.waitingRoomWidgetData.longestWaitingIMTimestamp }))

      .withSegment(new SegmentModel(Object.keys(this.waitingRoomWidgetData.outOfSlaTickets).length || 0, 'color-yellow', 'Tickets', '',
        { label: 'Longest Waiting Ticket', value: this.waitingRoomWidgetData.longestWaitingTicketTimestamp }))

      .build();
    this.waitingRoomOutOfSLAWidgetConfig = waitingRoomOutOfSLAWidget;
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('user')
  private get memberId(): string {
    return this.user.memberId;
  }

  private selectTeam = (team: ZITeam): void => {
    team.isSelected = true;
    this.selectedTeam = team;
    this.setLocalTeam(this.selectedTeam);
    this.retrieveAgentsFromSelectedTeam(this.selectedTeam);
  };

  private getLocalTeam(): ZITeam {
    this.selectedTeam = JSON.parse(
      localStorage.getItem(this.memberId + '-' + 'tl-team')
    ) as ZITeam;
    if (this.selectedTeam) {
      return this.teams.some(team => team.teamId === this.selectedTeam.teamId)
        ? this.selectedTeam
        : this.teams[0];
    }
    return this.teams[0];
  }

  private setLocalTeam(team: ZITeam): void {
    localStorage.setItem(this.memberId + '-' + 'tl-team', JSON.stringify(team));
  }

  private selectService = (service: ZIService): void => {
    this.selectedService = service;
    this.setLocalService(this.selectedService);
    this.retrieveWaitingRoomWidgetData(this.selectedService);
  };

  private getLocalService(): ZIService {
    this.selectedService = JSON.parse(
      localStorage.getItem(this.memberId + '-' + 'tl-service')
    ) as ZIService;
    if (this.selectedService) {
      return this.services.some(service => service.serviceId === this.selectedService.serviceId)
        ? this.selectedService
        : this.services[0];
    }
    return this.services[0];
  }

  private setLocalService(service: ZIService): void {
    localStorage.setItem(this.memberId + '-' + 'tl-service', JSON.stringify(service));
  }

  public viewAgents = (): void => {
    let agentAvailabilityDashboard = `agentavailabilitydashboard`;
    if (this.selectedTeam) {
      agentAvailabilityDashboard += `?teamId=${this.selectedTeam.teamId}`;
    }
    this.router.navigate(agentAvailabilityDashboard);
  };

  public viewLiveDashboard = (): void => {
    let liveDashboardPath = `livedashboard`;
    if (this.selectedService) {
      liveDashboardPath += `?serviceId=${this.selectedService.serviceId}`;
    }
    this.router.navigate(liveDashboardPath);
  };

  public viewInteractionLog = (): void => {
    this.router.navigate(`interactionlog`);
  };

  public viewReports = (): void => {
    this.router.navigate(`reports`);
  };

  public viewReportSchedule = (): void => {
    this.router.navigate('reportschedule');
  };

  public viewAlert = (): void => {
    this.router.navigate('alert');
  };

  public viewReminders = (): void => {
    this.router.navigate('reminders');
  };

  public viewContactCenterMonitoring = (): void => {
    this.router.navigate('contactcentermonitoring');
  };

  public viewCampaignLiveDashboard = (): void => {
    this.router.navigate('campaignlivedashboard');
  };

  public viewAnalytics = (): void => {
    this.router.navigate('analytics');
  };

  private initSignaler(): void {
    this.lastRequestTimestamp = new moment().add('seconds', 30);
    this.setupSignaler();
  }

  private setupSignaler(): void {
    const signal = 300;
    if (this.signalerInterval) {
      clearInterval(this.signalerInterval);
    }
    this.signalerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        this.reloadData();
      }
    }, signal);
  }

  private async reloadData(): Promise<void> {
    if (this.teams && this.selectedTeam) {
      await this.selectTeam(this.selectedTeam);
    }
    if (this.services && this.selectedService) {
      await this.selectService(this.selectedService);
    }
    this.initSignaler();
  }

  private detached(): void {
    clearInterval(this.signalerInterval);
  }

  becameInvisible(): void {
  }

  becameVisible(): void {
    this.reloadData();
  }
}
