import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {DisplayServiceModel} from "../organisation/services/service-model";
import FileSaver from 'file-saver';
import {RoleModel} from "../organisation/roles/role-model";
import {BusinessPartner} from "../businesspartner/business-partner-model";

const logger = LogManager.getLogger('ReportsService');

@autoinject
export class ReportsService {

  constructor(private httpClient: HttpClient) {
  }

  public retrieveOrganisationServices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/services')
        .asGet()
        .send()
        .then((response) => {
          let services = this.serviceListFactory(response);
          resolve(services);
        }, error => {
          reject(error);
        });
    });
  }

  public retrieveMemberServices(memberId:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/organisation/organisations/me/services-with-task-templates/members/${memberId}`)
        .asGet()
        .send()
        .then((response: any) => {
          let services = this.serviceListFactory(response.services);
          resolve(services);
        }, error => {
          reject(error);
        });
    });
  }

  public serviceListFactory(serviceData: any): any[] {
    let serviceList = [];
    serviceData.forEach(service => {
      service.isSelected = service.selected;
      serviceList.push(new DisplayServiceModel(service));
    });
    return serviceList;
  }

  public retrieveRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/organisation/me/roles`)
        .asGet()
        .send()
        .then((response: any) => {
          const roles = response.map(roleResponse => new RoleModel({roleValue: roleResponse}));
          resolve(roles);
        }, error => {
          reject(error);
        });
    });
  }

  public retrieveBusinessPartners(memberId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/organisation/members/${memberId}/business-partners`)
        .asGet()
        .send()
        .then((response: any) => {
          const businessPartners = response.businessPartners.map(bp => {
            let businessPartner = new BusinessPartner();
            businessPartner.id = bp.id;
            businessPartner.name = bp.name;
            return businessPartner
          });
          resolve(businessPartners);
        }, error => {
          reject(error);
        });
    });
  }

  public retrieveOrganisationTeams(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/teams')
        .asGet()
        .send()
        .then((response) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });

  }

  public retrieveAgentsForSelectedTeam(team: any, isQaReport: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/me/members`)
        .asGet()
        .withParams({role: "AGENT", teamId: team.teamId})
        .send()
        .then((response) => {

          if(response && response.length > 0 && isQaReport) {
            let allAgentsForQa = {memberId: 'All', firstName: 'All', surname: 'Agents'};
            response.forEach(item => {item.memberId = item.id});
            response.splice(0, 0, allAgentsForQa);
            resolve(response);
          } else {
            response.forEach(item => {item.memberId = item.id});
            resolve(response);
          }
        }, error => {
          reject([]);
        });
    });
  }

  public retrieveAllAgents(team: any, isQaReport: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/me/members`)
        .asGet()
        .withParams({role: "AGENT"})
        .send()
        .then((response) => {

          if(response && response.length > 0 && isQaReport) {
            let allAgentsForQa = {memberId: 'All', firstName: 'All', surname: 'Agents'};
            response.forEach(item => {item.memberId = item.id});
            response.splice(0, 0, allAgentsForQa);
            resolve(response);
          } else {
            response.forEach(item => {item.memberId = item.id});
            resolve(response);
          }
        }, error => {
          reject([]);
        });
    });
  }

  public retrieveQualityAssessmentForms(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/forms`)
        .asGet()
        .send()
        .then((response) => {
          resolve(response.forms);
        }, (error) => {
          reject(error);
        });
    });
  }

  public downloadReportFile(url: string, params: object, fileType: string, fileName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(url)
        .asGet()
        .withParams(params)
        .withResponseType('blob')
        .withHeader('Accept', fileType)
        .send().then(
        (response) => {
          FileSaver.saveAs(response.content ? response.content : response, fileName);
          resolve(true);
        },
        (error) => {
          reject(error);
        });
    });

  }
}