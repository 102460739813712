import { OplogManager } from './oplog-manager';
import { ContactModel } from '../contact-model';
import { LogManager } from 'aurelia-framework';
import { ConversationModel } from '../../conversation/conversation-model';
import { ContactFactory } from '../contact-factory';
import { ConversationService } from '../../conversation/conversation-service';
import { InteractionModel } from '../../conversation/interaction-model';
import { ContactService } from '../contact-service';
import { FlowsModel } from './flows-model';
import { StatusModel } from '../../conversation/status/status-model';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ContactDirectorViewStrategies } from './contact-director-view-strategies';
import { SessionStore } from 'zailab.common';
import {
  CONTACT_ACTIONS,
  CONVERSATION_ACTIONS,
  INTERACTION_ACTIONS,
  LOADER_ACTIONS,
} from './contact-controller-actions';
import { InteractionService } from '../../interactions/interaction-service';
import { TelephonyService } from '../../../telephony/telephony-service';

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { MemberInteractionModel } from '../../conversation/interaction/member-interaction-model';
import { ProcessingLayer } from '../../conversation/interaction/processing-layer';
import { WaypointModel } from '../../conversation/waypoint-model';
import { TicketModel } from '../../conversation/interaction/ticket-model';

// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('ContactState');

export class ContactState {
  public contactTabs: ContactModel[] = [];
  public contactCardShown: boolean = false;
  public tabsLimit = 5;
  public activity: boolean = false;

  public flows: FlowsModel;
  public statusCodes: StatusModel[] = [];
  public onCall: boolean = false;
  private contactFactory: ContactFactory;
  private conversationService: ConversationService;
  private contactService: ContactService;
  private eventAggregator: EventAggregator;
  private sessionStore: SessionStore;
  private interactionService: InteractionService;

  constructor(
    contactFactory: ContactFactory,
    conversationService: ConversationService,
    contactService: ContactService,
    eventAggregator: EventAggregator,
    sessionStore: SessionStore,
    interactionService: InteractionService,
    private telephonyService: TelephonyService,
    private oplogManager: OplogManager
  ) {
    this.contactService = contactService;
    this.contactFactory = contactFactory;
    this.conversationService = conversationService;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.flows = new FlowsModel();
    this.interactionService = interactionService;
  }

  public selectedContactCard(): ContactModel {
    return this.contactTabs.find((contact) => contact.isSelected);
  }

  public getContactCard(
    contactId?: string,
    correlationId?: string,
    conversationId?: string
  ): ContactModel {
    return this.contactTabs.find((contact) => {
      if (!contactId && correlationId) {
        return contact.correlationId === correlationId;
      }
      if (contact.contactId) {
        return contact.contactId === contactId;
      } else if (conversationId) {
        let conversation = contact.conversations.find(
          (conversation) => conversation.conversationId === conversationId
        );
        if (conversation) {
          return true;
        }
        return false;
      }
    });
  }

  public getUnknownContactCard(): ContactModel {
    return this.contactTabs.find((contact) => !contact.contactId);
  }

  public checkTabsLength(): void {
    if (this.contactTabs.length > this.tabsLimit) {
      this.removeContactByIndex(0, true);
    }
  }

  public forceCloseAllContacts(): void {
    this.contactTabs.forEach((tab) => tab.unsunbscribeFromSubscriptions());
    this.contactTabs = [];
    this.closeContactCard();
    this.saveState();
  }

  public closeAllContacts(): void {
    for (let index = this.contactTabs.length - 1; index >= 0; index--) {
      this.removeContactByIndex(index, true);
    }
  }

  public removeContactByIndex(index: number, force?: boolean): void {
    let contactTab = this.contactTabs[index];
    if (contactTab.showDispositionCode && !force) {
      return;
    }
    contactTab.unsunbscribeFromSubscriptions();

    this.eventAggregator.publish('get.recent.activity');
    this.contactTabs.splice(index, 1);

    if (this.contactTabs.length === 0) {
      this.closeContactCard();
    } else if (index === 0) {
      this.selectContactCard(this.contactTabs[0]);
    } else {
      this.selectContactCard(this.contactTabs[index === 0 ? 0 : index - 1]);
    }
    this.saveState();
  }

  public showDispositionCode(data: any): void {
    let contactCardWithCall: ContactModel = null;
    if (data.isDiallerCall) {
      contactCardWithCall = this.contactTabs.find((contactCard) => {
        if (
          contactCard.servedInteractions.filter(
            (interaction) => interaction.interactionId === data.interactionId
          ).length > 0
        ) {
          return true;
        }

        if (
          contactCard.conversations.filter(
            (conversation) =>
              conversation.interactions.filter(
                (interaction) =>
                  interaction.interactionId === data.interactionId
              ).length > 0
          ).length > 0
        ) {
          return true;
        }
      });
    } else {
      contactCardWithCall = this.contactTabs.find(
        (contactCard) =>
          contactCard.servedInteraction &&
          contactCard.servedInteraction.interactionId &&
          contactCard.servedInteraction.interactionId === data.interactionId
      );
    }
    if (contactCardWithCall) {
      this.selectContactCard(contactCardWithCall);
      if (
        contactCardWithCall.viewStrategy &&
        !contactCardWithCall.viewStrategy.searchContact
      ) {
        contactCardWithCall.openDispositionCode();
      }
    }
  }

  public injectThirdPartyContactData(data: any): void {
    let contactCardWithCall = this.contactTabs.find(
      (contactCard) =>
        contactCard.servedInteraction &&
        contactCard.servedInteraction.interactionId &&
        contactCard.servedInteraction.interactionId === data.interactionId
    );
    if (contactCardWithCall) {
      this.selectContactCard(contactCardWithCall);
      if (
        contactCardWithCall.viewStrategy &&
        contactCardWithCall.viewStrategy.searchContact
      ) {
        contactCardWithCall.injectContactData(data);
      }
    }
  }

  public showDispositionList(
    data: {
      interactionId: string;
      correlationId: string;
      conversationId: string;
    },
    dispositionList: any
  ): void {
    let contactCardWithCall;
    let contactCard = this.getContactCard(
      null,
      data.correlationId,
      data.conversationId
    );
    if (contactCard) {
      contactCard.dispositionList = dispositionList;
      contactCard.dispositionInteractionId = data.interactionId;
      this.selectContactCard(contactCard);
      contactCard.openDispositionList();
      return;
    } else if (!contactCard) {
      contactCardWithCall = this.contactTabs.find(
        (contactCard) =>
          contactCard.servedInteraction &&
          contactCard.servedInteraction.interactionId &&
          contactCard.servedInteraction.interactionId === data.interactionId
      );
    }

    if (!contactCardWithCall) {
      contactCardWithCall = this.contactTabs.find((contactCard) => {
        return !!contactCard.conversations.find((conversation) => {
          return !!conversation.interactions.find(
            (interaction) => interaction.interactionId === data.interactionId
          );
        });
      });
    }

    if (!contactCardWithCall) {
      contactCardWithCall = this.contactTabs.find((contactCard) => {
        return !!contactCard.servedUnlinkedInteractions.find(
          (interaction) => interaction.interactionId === data.interactionId
        );
      });
    }

    if (!contactCardWithCall) {
      if (this.contactTabs.length === 1) {
        contactCardWithCall = this.selectedContactCard();
      }
    }

    if (contactCardWithCall) {
      contactCardWithCall.dispositionList = dispositionList;
      contactCardWithCall.dispositionInteractionId = data.interactionId;
      this.selectContactCard(contactCardWithCall);
      contactCardWithCall.openDispositionList();
    }
  }

  public openContactCard(): void {
    this.contactCardShown = true;
  }

  public closeContactCard(): void {
    this.contactCardShown = false;
  }

  public async selectContactCard(contactCard: ContactModel): Promise<void> {
    this.contactTabs.forEach((_contactCard, index) => {
      if (this.contactTabs.indexOf(contactCard) !== index) {
        _contactCard.deselect();
      }
    });

    contactCard.channelViewStrategy.setSize(
      contactCard.showDispositionCode ? 'large' : 'small'
    );
    contactCard.channelViewStrategy.setChannel('call');
    contactCard.select();
    this.openContactCard();
  }

  public saveState(): void {}

  public populateSearchResults(info: {
    contactId: string;
    correlationId: string;
    contacts: ContactModel[];
    isManualSearch?: boolean;
  }): void {
    let contactCard = this.selectedContactCard();
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | populateSearchResults ',
      {
        eventInfo: info,
        contactCard,
      }
    );

    if (!contactCard) {
      setTimeout(() => {
        this.populateSearchResults(info);
      }, 200);
    } else {
      this.continuePopulatingSearchResults(contactCard, info);
    }
  }

  private continuePopulatingSearchResults(
    contactCard: ContactModel,
    info: {
      contactId: string;
      correlationId: string;
      contacts: ContactModel[];
      isManualSearch?: boolean;
    }
  ): void {
    if (
      info.contacts &&
      info.contacts.length === 1 &&
      contactCard &&
      contactCard.servedInteractions.length > 0 &&
      !info.isManualSearch
    ) {
      let newContact = this.contactFactory.build(info.contacts[0].contactId);
      newContact.mapContactInfoFromSearch(info.contacts[0]);
      this.eventAggregator.publish(CONTACT_ACTIONS.SELECT_SEARCH, newContact);
      return;
    }
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | populateSearchResults | attempting update on contactCard',
      contactCard
    );

    if (!contactCard) {
      return;
    }
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | populateSearchResults | updating search results'
    );

    contactCard.searchContactResults = [];
    if (info.contacts) {
      info.contacts.forEach((contact) => {
        let newContact = this.contactFactory.build(contact.contactId);
        newContact.mapContactInfoFromSearch(contact);
        contactCard.searchContactResults.push(newContact);
      });
      if (info.contacts.length > 1) {
        if (contactCard.servedInteraction) {
          contactCard.addServedUnlinkedInteraction(
            contactCard.servedInteraction
          );
        }
        contactCard.retrieveOrganisationMemberTransferList();
        contactCard.retrieveOrganisationWorktypeColorConfig();
        contactCard.retrieveSMSFromNumbers();
        contactCard.changeViewStrategy('searchContactUnknownContact');
      }
    } else {
      contactCard.retrieveOrganisationMemberTransferList();
      contactCard.retrieveOrganisationWorktypeColorConfig();
      contactCard.retrieveSMSFromNumbers();
      contactCard.changeViewStrategy('searchContactUnknownContact');
    }
  }

  public async selectContactFromSearch(
    selectedContact: ContactModel
  ): Promise<void> {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | selectContactFromSearch '
    );
    let contactCard = this.getContactCard(selectedContact.contactId);
    if (!contactCard) {
      contactCard = this.selectedContactCard();
    }
    if (contactCard && contactCard !== this.selectedContactCard()) {
      let currentCard = this.selectedContactCard();
      const servedInteractions = currentCard.servedInteractions || [];

      logger.debug(
        this.getCurrentFormattedTime() + ' - CC | addServedInteraction 2'
      );
      servedInteractions.forEach((interaction) => {
        contactCard.addServedUnlinkedInteraction(interaction);
        contactCard.addServedInteraction(interaction);
      });
      this.removeContactByIndex(this.contactTabs.indexOf(currentCard));
      this.selectContactCard(contactCard);
    }

    if (contactCard) {
      this.eventAggregator.publish(
        'change.channel.size.hide',
        selectedContact.contactId
      );
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | selectContactFromSearch | populating card '
      );
      contactCard.contactId = selectedContact.contactId;
      contactCard.subscribeToContactChanges();
      contactCard.mapContactInfo(selectedContact);
      await contactCard.populateUnlinkedInteractions(
        !contactCard.servedInteraction
      );
      contactCard.retrieveCustomFields();
      contactCard.retrieveOrganisationMemberTransferList();
      contactCard.retrieveOrganisationWorktypeColorConfig();
      contactCard.retrieveSMSFromNumbers();

      // todo: confirm possibility for not linking to contact, is that the interaction isn't being added as a servedInteraction
      if (!!contactCard.servedInteraction) {
        await this.linkIfInteractionPresent(contactCard, true);
      }
      await contactCard.populateConversations(
        true,
        !contactCard.servedInteraction
      );
      this.saveState();
    }
    this.eventAggregator.publish(
      'contact-oplog-subscribe',
      selectedContact.contactId
    );
  }

  public async linkIfInteractionPresent(
    selectedContactCard: ContactModel,
    ignoreConversationLinking?: boolean
  ): Promise<void> {
    if (!selectedContactCard.servedInteraction) {
      return;
    }
    let taskInteraction = await this.isTask(selectedContactCard);
    if (taskInteraction && taskInteraction.channel !== 'chat') {
      let promises = [];

      selectedContactCard.servedInteractions.forEach((interaction) =>
        promises.push(
          new Promise(async (innerResolve) => {
            await interaction.linkToContact(selectedContactCard.contactId);
            innerResolve({});
          })
        )
      );

      Promise.all(promises).then(() => {
        if (taskInteraction.metaData.diallerType !== 'Predictive') {
          this.eventAggregator.publish(
            CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION,
            selectedContactCard.servedInteractions
          );
          this.eventAggregator.publish(
            CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION,
            selectedContactCard.servedUnlinkedInteractions
          );
        }
      });
    } else if (selectedContactCard.servedInteraction) {
      return new Promise((resolve) => {
        let promises = [];

        selectedContactCard.servedInteractions.forEach((interaction) =>
          promises.push(
            new Promise(async (innerResolve) => {
              await interaction.linkToContact(selectedContactCard.contactId);
              selectedContactCard.removeServedUnlinkedInteraction(
                interaction.interactionId
              );
              if (!ignoreConversationLinking) {
                selectedContactCard.conversationItemsLinkingStrategy();
              }
              innerResolve({});
            })
          )
        );

        Promise.all(promises).then(() => resolve());
      });
    }
  }

  public isTask(selectedContactCard: ContactModel): InteractionModel {
    for (
      let index = selectedContactCard.servedInteractions.length - 1;
      index >= 0;
      index--
    ) {
      let interaction = selectedContactCard.servedInteractions[index];
      if (
        interaction instanceof InteractionModel &&
        (interaction.channel === 'campaign' ||
          interaction.type === 'campaign' ||
          interaction.channel === 'chat')
      ) {
        return interaction;
      }
    }
    return null;
  }

  public addContactCard(): void {
    this.contactTabs.forEach((contactCard) => contactCard.deselect());

    let contactModel = this.contactFactory.build();
    contactModel.changeViewStrategy('searchContactEmpty');
    this.contactTabs.push(contactModel);
    this.checkTabsLength();
    this.selectContactCard(contactModel);
    this.openContactCard();
  }

  public toggleCard(): void {
    this.contactCardShown = !this.contactCardShown;

    let selectedContactCard = this.selectedContactCard();

    if (this.contactCardShown) {
      selectedContactCard.addFocus();
    } else {
      selectedContactCard.removeFocus();
    }
  }

  public changeView(viewObject: {
    viewStrategy: string;
    contactId: string;
    correlationId?: string;
    previous?: boolean;
  }): void {
    let contactCard = this.getContactCard(
      viewObject.contactId,
      viewObject.correlationId
    );

    if (contactCard && !contactCard.showDispositionCode) {
      contactCard.previousViewStrategy = contactCard.viewStrategy;
      contactCard.viewStrategy = viewObject.previous
        ? contactCard.previousViewStrategy
        : ContactDirectorViewStrategies.getStrategy(viewObject.viewStrategy);
    }
  }

  public changeViewSelected(viewObject: {
    viewStrategy: string;
    contactId: string;
    correlationId?: string;
    previous?: boolean;
  }): void {
    let contactCard = this.selectedContactCard();

    if (contactCard && !contactCard.showDispositionCode) {
      contactCard.previousViewStrategy = contactCard.viewStrategy;
      contactCard.viewStrategy = viewObject.previous
        ? contactCard.previousViewStrategy
        : ContactDirectorViewStrategies.getStrategy(viewObject.viewStrategy);
    }
  }

  public previousView(contactId: string): void {
    let contactCard = this.getContactCard(contactId);
    if (contactCard && contactCard.previousViewStrategy) {
      contactCard.viewStrategy = contactCard.previousViewStrategy;
    }
  }

  public createConversation(contactId: string): void {
    let contactCard = this.getContactCard(contactId);
    contactCard.deselectAllConversations();
    contactCard.createConversation();
  }

  public removeConversation(conversation: ConversationModel): void {
    let contactCard = this.getContactCard(conversation.contactId);
    if (contactCard) {
      contactCard.deleteConversation(conversation.conversationId);
      contactCard.discardConversation(conversation.conversationId);
      if (conversation.isSelected) {
        contactCard.autoSelectConversation();
      }
    }
    this.eventAggregator.publish(
      CONVERSATION_ACTIONS.CONTACT_CONVERSATIONS_CHANGED
    );
  }

  public updateConversationName(conversationId: string, name: string): void {
    let contactCard = this.selectedContactCard();
    let selectedConversation = contactCard.getConversation(conversationId);
    selectedConversation.updateName(name);
  }

  public selectConversation(conversation: ConversationModel): void {
    let contactCard = this.getContactCard(conversation.contactId);
    if (contactCard) {
      contactCard.closeDispositionList();

      let conversationToSelect = contactCard.getConversation(
        conversation.conversationId
      );
      if (conversationToSelect) {
        contactCard.deselectAllUnlinkedInteractions();
        contactCard.deselectAllConversations();
        contactCard.changeViewStrategy('interactions');

        conversationToSelect.select(true);
        if (
          !conversationToSelect.status ||
          conversationToSelect.status.toLowerCase() === 'pending' ||
          conversationToSelect.status.toLowerCase() === 'new'
        ) {
          contactCard.selectedConversation = conversationToSelect;
        } else {
          contactCard.selectedConversation = null;
        }
      }
    }
  }

  public autoSelectConversation(data: {
    contactId: string;
    correlationId: string;
  }): void {
    let contactCard = this.getContactCard(data.contactId, data.correlationId);
    if (contactCard) {
      contactCard.removeUnhandledAction(
        ContactModel.UNHANDLED_ACTIONS.CONVERSATIONS
      );
      contactCard.autoSelectConversation();
    }
  }

  public updateConversation(conversation: ConversationModel): void {
    let contactCard = this.getContactCard(conversation.contactId);
    if (contactCard) {
      let updatedConversation = contactCard.getConversation(
        conversation._id || conversation.conversationId
      );
      if (updatedConversation) {
        if (
          updatedConversation.status !== 'successful' &&
          updatedConversation.status !== 'unsuccessful' &&
          (conversation.status === 'successful' ||
            conversation.status === 'unsuccessful')
        ) {
          contactCard.moveConversationToClosed(updatedConversation);
        } else if (
          (updatedConversation.status === 'successful' ||
            updatedConversation.status === 'unsuccessful') &&
          conversation.status !== 'successful' &&
          conversation.status !== 'unsuccessful'
        ) {
          contactCard.moveConversationToOpen(updatedConversation);
        }
        updatedConversation.mapConversationInfo(conversation, true);
        contactCard.selectConversation(updatedConversation.conversationId);
        this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
          contactId: conversation.contactId,
          status: false,
          loader: 'conversationList',
        });
      }
    }
  }

  public handleConversationCreated(conversation: ConversationModel): void {
    let contactCard = this.getContactCard(conversation.contactId);
    if (contactCard) {
      let newConversation = new ConversationModel(
        this.conversationService,
        this.eventAggregator,
        conversation._id || conversation.conversationId
      );
      newConversation.contactId = conversation.contactId;
      newConversation.firstName = contactCard.firstName;
      newConversation.surname = contactCard.surname;
      newConversation.mapConversationInfo(conversation);

      if (
        contactCard.activeConversation &&
        contactCard.activeConversation.conversationId ===
          conversation.conversationId
      ) {
        newConversation = contactCard.activeConversation;
      } else {
        contactCard.conversations.unshift(newConversation);
        contactCard.openConversations.unshift(newConversation);
      }

      if (
        contactCard.servedInteraction &&
        contactCard.servedInteraction.conversationId ===
          newConversation.conversationId &&
        contactCard.activeConversation &&
        contactCard.activeConversation.conversationId !==
          newConversation.conversationId &&
        contactCard.findTask()
      ) {
        contactCard.activeConversation = newConversation;
      }
      contactCard.selectConversation(newConversation.conversationId);

      if (
        !newConversation.status ||
        newConversation.status.toLowerCase() === 'pending' ||
        newConversation.status.toLowerCase() === 'new'
      ) {
        contactCard.selectedConversation = newConversation;
      }
      contactCard.loaders.conversationList = false;
    }
  }

  public reviewConversationStatus(
    memberId: string,
    status: string,
    reason: string,
    conversationId: string
  ): void {
    let contactCard = this.selectedContactCard();
    if (contactCard) {
      let conversation = contactCard.getConversation(conversationId);
      conversation.reviewStatus(memberId, status, reason);
      conversation.showReminder = false;
    }
  }

  public mapFlow(
    outboundFlows: Array<{
      flowId: string;
      flowName: string;
      selected: boolean;
    }>
  ): void {
    let emptyFlow = {
      flowId: null,
      flowName: 'None (Private)',
      selected: false,
    };
    outboundFlows.unshift(emptyFlow);
    if (outboundFlows.filter((flows) => flows.selected).length === 0) {
      outboundFlows[0].selected = true;
    }
    this.flows.outboundFlows = outboundFlows;
  }

  public handleConversationInteractionUpdate(data: {
    interactions: InteractionModel[];
    conversationId: string;
    contactId: string;
  }): void {
    let contactCard = this.getContactCard(data.contactId);
    if (contactCard) {
      if (contactCard.hasServedInteraction()) {
        let conversation = contactCard.getConversation(data.conversationId);
        let interaction = conversation.getInteraction(
          contactCard.servedInteraction.interactionId
        );

        if (interaction) {
          contactCard.changeViewStrategy('interactions');
          contactCard.deselectAllUnlinkedInteractions();
          contactCard.deselectAllConversations();
          interaction.select();
        }
      }
      this.eventAggregator.publish(
        CONVERSATION_ACTIONS.CONTACT_CONVERSATIONS_CHANGED
      );
    }
  }

  public async selectUnlinkedInteraction(
    interaction: InteractionModel | TicketModel
  ): Promise<void> {
    let contactCard = this.selectedContactCard();
    if (contactCard) {
      contactCard.closeDispositionList();
      logger.debug(
        this.getCurrentFormattedTime() + ' - CC | select unlinked interaction '
      );
      contactCard.selectedConversation = null;
      contactCard.changeViewStrategy('unlinkedInteractions');
      contactCard.deselectAllConversations();
      contactCard.deselectAllUnlinkedInteractions(interaction.interactionId);

      let rawData = await this.conversationService.retrieveInteraction(
        interaction.interactionId
      );

      if (rawData) {
        interaction.loaders.journey = false;
        if (rawData.journey && rawData.journey.waypoints) {
          interaction.journey = rawData.journey.waypoints.map(
            (waypoint) => new WaypointModel(waypoint)
          );
        }
      }
      if (interaction instanceof TicketModel) {
        interaction.mapMetaData(rawData.metaData);
        interaction.mapJourney(rawData.journey);
        interaction.mapAdditionalData(rawData.additionalData);
      } else {
        interaction.mapInteraction(rawData);
        interaction.mapInteraction(rawData.metaData);
        interaction.mapInteraction(rawData.content);
      }

      interaction.select();
      contactCard.selectedUnlinkedInteraction = interaction;
    }
  }

  public selectInteraction(data: {
    interaction: InteractionModel | TicketModel;
    contactId?: string;
    auto?: boolean;
  }): void {
    let contactCard =
      this.getContactCard(data.contactId) || this.selectedContactCard();
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | selectInteraction on',
      contactCard
    );
    if (contactCard) {
      contactCard.deselectAllUnlinkedInteractions();
      let selectedConversation = contactCard.getSelectedConversation();
      if (selectedConversation) {
        if (data.auto) {
          if (contactCard.servedInteraction) {
            let interaction = selectedConversation.interactions.find(
              (_interaction) =>
                _interaction.interactionId ===
                contactCard.servedInteraction.interactionId
            );
            if (interaction) {
              selectedConversation.selectInteraction(interaction.interactionId);
            } else {
              selectedConversation.selectInteraction(
                data.interaction.interactionId
              );
            }
          } else {
            selectedConversation.selectInteraction(
              data.interaction.interactionId
            );
          }
        } else {
          selectedConversation.selectInteraction(
            data.interaction.interactionId
          );
        }
      }
    }
  }

  public toggleLoader(info: {
    contactId: string;
    status: boolean;
    loader: string;
  }): void {
    let contactCard = this.getContactCard(info.contactId);
    if (contactCard) {
      contactCard.loaders[info.loader] = info.status;
    }
  }

  public changeContactCardChannel(info: {
    contactId: string;
    channel: string;
  }): void {
    let contactCard = this.selectedContactCard();
    if (contactCard) {
      contactCard.channelViewStrategy.setChannel(info.channel);
    }
  }

  public expandContactCardChannels(): void {
    let contactCard = this.selectedContactCard();
    if (contactCard) {
      contactCard.channelViewStrategy.expand();
    }
  }

  public collapseContactCardChannels(): void {
    let contactCard = this.selectedContactCard();
    if (contactCard) {
      contactCard.channelViewStrategy.collapse();
    }
  }

  public resetContactCardChannels(): void {
    let contactCard = this.selectedContactCard();
    if (contactCard) {
      contactCard.channelViewStrategy.setSize(
        contactCard.showDispositionCode ? 'large' : 'small'
      );
    }
  }

  public updateWrapupInteraction(data: any): void {
    let contactCard = this.getContactCard(data.contactId);

    if (contactCard) {
      contactCard.servedInteractions.forEach((interaction) => {
        if (interaction.interactionId === data.interactionId) {
          // @ts-ignore
          interaction.wrapUpChannelIds = data.wrapUpChannelIds;
        }
      });
    } else {
      if (data.wrapUpChannelIds) {
        data.isServed = true;
      }
    }
    this.saveState();
  }

  public addWrapUpToContact(interaction: InteractionModel): void {
    let contactCard = this.getContactCard(interaction.contactId);
    if (contactCard) {
      contactCard.showWrapUp();
      const servedInteraction = contactCard.servedInteractions.find(
        (servedInteraction) =>
          servedInteraction.interactionId === interaction.interactionId
      );
      if (servedInteraction) {
        // @ts-ignore
        servedInteraction.wrapUp = true;
      }
    }
  }

  public removeWrapUp(interaction: InteractionModel): void {
    let contactCard = this.getContactCard(interaction.contactId);
    if (contactCard) {
      contactCard.removeWrapUp();
      const servedInteraction = contactCard.servedInteractions.find(
        (servedInteraction) =>
          servedInteraction.interactionId === interaction.interactionId
      );
      if (servedInteraction) {
        // @ts-ignore
        servedInteraction.wrapUp = false;
      }
    }
  }

  public rehydrateInteractions(data: {
    interactions: Array<MemberInteractionModel>;
    initiateLinking?: boolean;
  }): void {
    this.closeAllContacts();

    let contacts: { [k: string]: Array<InteractionModel | TicketModel> } = {};
    let promises = [];
    data.interactions.forEach((interaction, index) =>
      promises.push(
        new Promise((resolve) => {
          if (this.isEligibleToRehydrate(interaction)) {
            ProcessingLayer.processInteraction(interaction).then(
              (modelledInteraction) => {
                if (
                  modelledInteraction instanceof InteractionModel &&
                  modelledInteraction.isCallChannel()
                ) {
                  this.eventAggregator.publish(INTERACTION_ACTIONS.IS_ON_CALL);
                }
                if (!contacts[modelledInteraction.contactId]) {
                  if (modelledInteraction.contactId) {
                    contacts[modelledInteraction.contactId] = [];
                  } else {
                    contacts['unknown-' + index] = [modelledInteraction];
                  }
                }
                if (contacts[modelledInteraction.contactId]) {
                  contacts[modelledInteraction.contactId].push(
                    modelledInteraction
                  );
                }
                resolve([]);
              }
            );
          } else {
            resolve([]);
          }
        })
      )
    );

    Promise.all(promises).then(() => {
      if (Object.keys(contacts).length === 0) {
        return;
      }
      for (let contactId in contacts) {
        let contactCard = this.contactFactory.build();
        contactCard.contactId = contactId.includes('unknown-')
          ? null
          : contactId;

        let interactions = contacts[contactId];
        if (contactId.includes('unknown-')) {
          logger.info(' CC | rehydrate interactions for unknown contact ');

          let promises = [];
          logger.debug(
            this.getCurrentFormattedTime() + ' - CC | addServedInteraction 3'
          );
          interactions.forEach((interaction) =>
            promises.push(
              new Promise(async (resolve) => {
                await contactCard.addServedUnlinkedInteraction(interaction);
                this.oplogManager.subscribeToInteractionChanges(
                  interaction.interactionId
                );
                contactCard.addServedInteraction(interaction);
                resolve({});
              })
            )
          );
          Promise.all(promises).then(() => {
            contactCard.retrieveOrganisationMemberTransferList();
            contactCard.retrieveOrganisationWorktypeColorConfig();
            contactCard.retrieveSMSFromNumbers();
            contactCard.populateSearchParams(interactions[0], true);
            interactions[0].select();
            contactCard.changeViewStrategy('searchContactUnknownContact');
          });
        } else {
          logger.info(' CC | rehydrate interactions for known contact ');

          let conversationToSelect = interactions.find(
            (_interaction) => _interaction.conversationId
          );
          if (conversationToSelect) {
            contactCard.conversationIdToSelect =
              conversationToSelect.conversationId;
          }
          logger.debug(
            this.getCurrentFormattedTime() + ' - CC | addServedInteraction 4',
            data
          );
          interactions.forEach((interaction) => {
            this.oplogManager.subscribeToInteractionChanges(
              interaction.interactionId
            );
            contactCard.addServedInteraction(interaction);
          });
          this.oplogManager.subscribeToContactInfoChanges(contactId);
          this.oplogManager.subscribeToConversationListChanges(contactId);
          this.oplogManager.subscribeToUnlinkedInteractionChanges(contactId);
          contactCard.populateContactCard(true, !data.initiateLinking);
        }
        this.contactTabs.push(contactCard);
      }
      this.checkTabsLength();
      this.saveState();
      this.populateRehydratedInteractions(data.interactions);
    });
  }

  private isEligibleToRehydrate(interaction: MemberInteractionModel): boolean {
    if (interaction.interactionType === 'INTERNAL') {
      return false;
    }
    return interaction.state === 'CONNECTED' || interaction.state === 'WRAP_UP';
  }

  private populateRehydratedInteractions(
    interactions: Array<MemberInteractionModel>
  ): void {
    let interactionUpdatedCount = 0;
    interactions.forEach(async (interaction) => {
      let rawData = await this.conversationService.retrieveInteraction(
        interaction.interactionId
      );
      let contactCard = this.getContactCard(interaction.contactId);
      if (!contactCard) {
        contactCard = this.contactTabs[0];
      }

      let servedUnlinkedInteraction =
        contactCard.getServedUnlinkedInteractionWithId(
          interaction.interactionId
        );
      if (servedUnlinkedInteraction) {
        this.mapWaypoints(servedUnlinkedInteraction, rawData);
      }

      let servedInteraction = contactCard.getServedInteractionWithId(
        interaction.interactionId
      );
      if (servedInteraction) {
        this.mapWaypoints(servedInteraction, rawData);
      }
      interactionUpdatedCount++;

      if (interactionUpdatedCount === interactions.length) {
        this.selectContactCard(this.contactTabs[0]);
      }
    });
  }

  private mapWaypoints(
    interaction: InteractionModel | TicketModel,
    rawData: any
  ): void {
    if (interaction instanceof InteractionModel) {
      interaction.mapInteraction(rawData);
    } else if (interaction instanceof TicketModel) {
      interaction.mapJourney(rawData.journey);
    }
    if (rawData && rawData.journey && rawData.journey.waypoints) {
      interaction.journey = rawData.journey.waypoints.map(
        (waypoint) => new WaypointModel(waypoint)
      );
    }
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }
}
