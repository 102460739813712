import {LogManager} from 'aurelia-framework';
import countriesAndTimezones from 'countries-and-timezones';
import {ArrayTools} from 'zailab.common';

const logger = LogManager.getLogger('CountriesTools');
export const COUNTRIES = {
  'za': { regions: ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'] },
  'us': { regions: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] }
};

interface IcountryItem {
  id: string,
  name: string,
  timezones: Array<any>,
  isSelected: false,
  isExpanded: false,
  subitems: any
}

export class CountriesTools {

  static getAllCountriesList(defaultValue?: string): Array<IcountryItem> {
    let countries = countriesAndTimezones.getAllCountries();
    countries = (() => {
      let list = [];
      for (let key in countries) {
        list.push(countries[key]);
      }
      return ArrayTools.sort(list, 'name');
    })();
    let orderedCountries = countries.filter((country) => country.name === defaultValue);
    orderedCountries = orderedCountries.concat(countries.filter((country) => country.name !== defaultValue));
    return orderedCountries;
  }

  static getfilteredCountriesList(wantedCountries: Array<string>): Array<{ name: string, id: string }> {
    let countries = countriesAndTimezones.getAllCountries();
    countries = (() => {
      let list = [];
      for (let key in countries) {
        list.push(countries[key]);
      }
      return ArrayTools.sort(list, 'name');
    })();
    let orderedCountries = countries.filter((country) => {
      return wantedCountries.some((countryName) => country.name === countryName)
    });
    return orderedCountries;
  }

  static getCountryRegions(country: string): string[] {

    return COUNTRIES[country].regions;
  }
}
