/*
 */
import {LogManager, customElement, bindable, inject} from 'aurelia-framework';
import {TeamModel} from '../../models/team-model';
import {TeamLeaderService} from '../../team-leader-service';
/*
 */
const logger = LogManager.getLogger('TeamDropDown');
/*
 */
@customElement('z-team-drop-down')
@inject(Element, TeamLeaderService)
export class TeamDropDown {

    private element: Element;
    private teamLeaderService: TeamLeaderService;
    private teams: Array<TeamModel> = [];
    @bindable({ attribute: 'default-team-id' }) defaultTeamId;
    private defaultTeam: TeamModel;

    constructor(element: Element, agentsService: TeamLeaderService) {
        this.element = element;
        this.teamLeaderService = agentsService;
    }

    public attached(): void {
        this.retrieveTeams();
    }

    private retrieveTeams(): void {
        this.teamLeaderService.retrieveTeams()
            .then(teams => this.teamsRetrieved(teams), error => this.reportError(error));
    }

    private teamsRetrieved(teams: Array<TeamModel>): void {

        if(this.defaultTeamId) {
            this.defaultTeam = teams.find(team => {return team.teamId === this.defaultTeamId});
        }

        this.teams = teams;
    }

    private selectTeam(team: TeamModel): void {

        if(!team) {
            return;
        }

        this.dispatch('teamselected', team);
    }

    private reportError(msg: any): void {
        logger.error('errorMessage >', msg);
    }

    private dispatch(name: string, data: any): void {
        this.element.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: data
            })
        );
    }

}