import { bindingMode } from "aurelia-binding";
import { inject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-framework";
import { BindingSignaler } from "aurelia-templating-resources";
import moment, { Moment } from 'moment';

import { Event } from "zailab.common";

import './reload-timer.scss';

@inject(Element, BindingSignaler)
export class ReloadTimer {
  @bindable({ attribute: 'retrieving-data' })
  public retrievingData: boolean;
  @bindable({ attribute: 'last-request-timestamp' })
  public lastRequestTimestamp: Moment;
  @bindable({ attribute: 'current-request-timer', defaultBindingMode: bindingMode.twoWay })
  public currentRequestTimer: number;
  @bindable({ attribute: 'request-triggered', defaultBindingMode: bindingMode.twoWay })
  public requestTriggered;
  @bindable view: string;

  private stateStorageKey: string = 'zai_timer';
  private signallerInterval: any;
  public timerDropdownVisible = false;
  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }, { value: 55 }, { value: 60 }
  ];

  constructor(
    private element: Element,
    private bindingSignaler: BindingSignaler
  ) {}

  public bind(): void {
    const storedValue = this.getStateFromStorage()[this.view];

    if (storedValue) {
      this.currentRequestTimer = storedValue;
    }
  }

  private getStateFromStorage(): any {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);
      return parsedState || {};
    } catch(e) {
      return {};
    }
  }

  private setStageInStorage(): void {
    const currentState = this.getStateFromStorage();
    localStorage.setItem(this.stateStorageKey, JSON.stringify({
      ...currentState,
      [this.view]: this.currentRequestTimer
    }));
  }

  public attached(): void {
    this.requestTriggered = () => {
      this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer + 0.9);
      this.setupSignaller();
    };
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(moment(), 'seconds') <= 0) {
        if (this.retrievingData) {
          return;
        }
        this.triggerNotice();
      }
    }, signal);
  }

  private triggerNotice(): void {
    clearInterval(this.signallerInterval);
    this.signallerInterval = null;
    new Event(this.element, 'request', {});
  }
  
  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(time: number): void {
    this.currentRequestTimer = time;
    this.lastRequestTimestamp = moment().add('seconds', this.currentRequestTimer + 0.9);
    this.timerDropdownVisible = false;
    this.triggerNotice();
    this.setStageInStorage();
  }
}
