import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
const logger = LogManager.getLogger('ActivityContactModel');

export class ActivityContactModel extends BaseModel {

  public id: string = null;
  public firstName: string = null;
  public surname: string = null;

  constructor(response: Object) {
    super();
    this.mapProperties(response);
  }

  get fullName(): string {
    if (this.id && !this.firstName && !this.surname) {
      return '(No Name)';
    }
    if (!this.id && !this.firstName && !this.surname) {
      return 'Unknown Contact';
    }
    if (this.firstName && !this.surname) {
      return this.firstName;
    }
    if (!this.firstName && this.surname) {
      return this.surname;
    }
    else {
      return this.firstName + ' ' + this.surname;
    }
  }
}
