import { LogManager, customElement, bindable } from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('ZDropdownList');

interface ZDropdownListItem {
  [key: string]: string;
}

@customElement('z-dropdown-list')
export class ZDropdownList {
  @bindable private list: Array<ZDropdownListItem> = [];
  @bindable private listItemDisplayProperty: string = '';
  @bindable private icon: string = '';
  @bindable onClickHandler: (item: ZDropdownListItem) => {};
  @bindable selectedItem: any = null;
  @bindable displayReady: boolean = true;

  private showList: boolean = false;
  private showIcon: boolean = false;
  private hideDelay: number = 1000;
  private autoCloseDelay: number = 5000;
  private autoCloseTimer: number = 0;
  private uniqueIdentifier: string;
  private noItemsText: string = 'No items to select';

  private static allDropdowns: ZDropdownList[] = [];

  constructor() {
    this.uniqueIdentifier = uuidv4();
  }

  public attached(): void {
    ZDropdownList.allDropdowns.push(this);

    if(this.icon && typeof this.icon === 'string' && this.icon !== '') {
      this.showIcon = true;
      this.icon = this.icon || this.listItemDisplayProperty;
    }
  }

  private onClick(item: ZDropdownListItem): void {
    if (this.onClickHandler && typeof this.onClickHandler === 'function') {
      this.onClickHandler(item);
    }
    this.closeMenu();
  }

  private toggleShowList(): void {
    this.showList = !this.showList;
  }

  private static closeAllExcept(exceptionIdentifier: string): void {
    ZDropdownList.allDropdowns.forEach(instance => {
      if (instance.uniqueIdentifier !== exceptionIdentifier) {
        instance.closeMenu();
      }
    });
  }

  private showListChanged(): void {
    if (this.showList) {
      ZDropdownList.closeAllExcept(this.uniqueIdentifier);
      this.startAutoCloseMenu(this.autoCloseDelay);
    }
  }

  private startAutoCloseMenu(delay: number): void {
    this.autoCloseTimer = window.setTimeout(() => this.closeMenu(), delay);
  }

  private closeMenu(): void {
    this.showList = false;
    this.autoCloseTimer = 0;
  }

  private preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  private allowAutoClose(): void {
    this.startAutoCloseMenu(this.hideDelay);
  }
}
