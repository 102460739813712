import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { InfiniteScrollService } from '../../../../_common/services/infinitescroll-service';
import { ViewCampaignAuditDialog } from '../audit/view-audit';
import CampaignService from '../campaign-service';

// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('CampaignsAudit');
@autoinject
export class CampaignsAudit {
  public header: string = 'Campaigns Audit Trail';
  public searchTerm: string = '';
  public ready: boolean = false;
  public auditTrail: any[] = [];
  public total: number | string;
  public isSearching: boolean = false;
  public tableContainer: Element;
  public infiniteScroll: InfiniteScrollService;
  private page: number = 0;

  constructor(
    private campaignService: CampaignService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  public activate(): void {
    this.retrieveAuditTrail();
  }

  public attached(): void {
    this.initialiseInfiniteScroll();
  }

  public retrieveAuditTrail(): void {
    this.campaignService
      .retrieveAudit(this.page)
      .then((response) => {
        if (response && Array.isArray(response.auditCampaigns)) {
          response.auditCampaigns.forEach((flow) => {
            flow.formattedTimestamp = moment(flow.lastUpdatedTimestamp).format('D MMM YY HH:mm:ss')
          });
          this.auditTrail = this.auditTrail.concat(response.auditCampaigns);
          this.total = response.total;
        }
      })
      .catch((error) => {
        logger.warn(' > failed to get campaign audits ', error);
        this.total = '--';
      })
      .finally(() => {
        this.ready = true;
      });
  }

  private initialiseInfiniteScroll(): void {
    this.infiniteScroll = new InfiniteScrollService(this.tableContainer, () => {
      this.page++;
      this.retrieveAuditTrail();
    });
  }

  public viewChange(auditRecord: any): void {
    this.dialogService
      .open({
        viewModel: ViewCampaignAuditDialog,
        model: auditRecord.responseInformation
      })
      .whenClosed(() => { });
  }
  
  public viewLiveDashboard(): void {
    this.router.navigate('list');
  }

  public toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  public partialMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression.length === 0) {
      return true;
    }
    searchExpression = searchExpression.toLowerCase();
    let formattedTimestamp = value.formattedTimestamp ? value.formattedTimestamp.toLowerCase() : '';
    let name = value.name ? value.name.toLowerCase() : '';
    let lastUpdatedBy = value.lastUpdatedBy ? value.lastUpdatedBy.toLowerCase() : '';
    let lastUpdatedByEmail = value.lastUpdatedByEmail ? value.lastUpdatedByEmail.toLowerCase() : '';
    let action = value.action ? value.action.toLowerCase() : '';
    let objectChanged = value.objectChanged ? value.objectChanged.toLowerCase() : '';
    return (
      formattedTimestamp.includes(searchExpression) ||
      name.includes(searchExpression) ||
      lastUpdatedBy.includes(searchExpression) ||
      lastUpdatedByEmail.includes(searchExpression) ||
      action.includes(searchExpression) ||
      objectChanged.includes(searchExpression)
    );
  }
}
