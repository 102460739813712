import { LogManager } from 'aurelia-framework';

import moment from 'moment-timezone';

const logger = LogManager.getLogger('DateTimeTools');

export class DateTimeTools {
  convertToLocalTime(sourceTime, sourceZone) {
    let localTime = moment.tz(sourceTime, sourceZone)._d;
    let formattedDate =
      localTime.getFullYear() +
      '-' +
      (localTime.getMonth() + 1) +
      '-' +
      localTime.getDate();
    let formattedTime =
      (localTime.getHours() < 10
        ? '0' + localTime.getHours()
        : localTime.getHours()) +
      ':' +
      (localTime.getMinutes() < 10
        ? '0' + localTime.getMinutes()
        : localTime.getMinutes());
    let dateObj = localTime;

    return { date: formattedDate, time: formattedTime, dateObj: dateObj };
  }

  /**
   * converts a date object into a sting date and includes and AM/PM indicator
   * @param {Date} sourceTime the date object that needs to be formatted
   * @param {String} sourceZone a specific timezone that the sourceTime is provided in.  will use browser default if not provided
   * @returns {String} date the formatted date in string format
   * @returns {String} time the formatted time in am/pm format
   * @returns {Date} dateObj the date specified in local time
   */

  convertToAMPMFormat(sourceTime, sourceZone) {
    let localTime = moment.tz(sourceTime, sourceZone)._d;
    let formattedDate =
      localTime.getFullYear() +
      '-' +
      (localTime.getMonth() + 1) +
      '-' +
      localTime.getDate();

    let ampm = localTime.getHours() >= 12 ? 'pm' : 'am';

    let hours = ((localTime.getHours() + 11) % 12) + 1;
    let minutes =
      localTime.getMinutes() < 10
        ? '0' + localTime.getMinutes()
        : localTime.getMinutes();

    let formattedTime = hours + ':' + minutes + ' ' + ampm;
    let dateObj = localTime;

    return { date: formattedDate, time: formattedTime, dateObj: dateObj };
  }

  /**
   * converts a date object into a string date in the YYYY-MM-DD format
   * @param {Date} Date that you want formatted in a javascript date object
   * @return {String} date the formatted date in string format
   */
  formatToString(date) {
    let formattedDate = moment(date);
    return formattedDate.format('YYYY-MM-DD');
  }

  /**
   * converts a string time in format HH:MM into a localised time
   * @param {String} time that you want formatted
   */
  convertUTCStringTimeToLocalTime(time) {
    let localTime = moment.utc(time, 'HH:mm:ss').local().format('HH:mm:ss');
    return localTime;
  }

  convertMillisecondsToTime(ms) {
    if (!ms) {
      return '0m 0s';
    }

    if (ms.toString().indexOf('s') > -1)
      // Check if time already formatted
      return ms;
    var x, time, days, hours, minutes, seconds;
    x = Math.floor(ms / 1000);
    seconds = Math.floor(x % 60);
    x /= 60;
    minutes = Math.floor(x % 60);
    x /= 60;
    hours = Math.floor(x % 24);
    x /= 24;
    days = Math.floor(x);
    time =
      (days > 0 ? days + 'd ' : '') +
      (hours > 0 ? hours + 'h ' : '') +
      minutes +
      'm ' +
      seconds +
      's';
    return time;
  }

  convertToStartOfDayTimeStamp(year, month, day) {
    return this.convertToTimeStamp(year, month, day, 0, 0, 0);
  }

  convertToEndOfDayTimeStamp(year, month, day) {
    return this.convertToTimeStamp(year, month, day, 23, 59, 59);
  }

  convertToTimeStamp(year, month, day, hour, minute, second) {
    let date = new Date(Date.UTC(year, month, day, hour, minute, second));
    return date.getTime();
  }

  getMonthNumber(month) {
    let months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };

    return months[month];
  }

  convertTimeToHoursAndMinutes(time) {
    var momentTime = moment(time, 'HH:mm');
    var hours = momentTime.hours();
    var minutes = momentTime.minutes();
    var formattedTime = hours + 'h';

    if (minutes > 0) {
      formattedTime += ' ' + minutes + 'm';
    }

    return formattedTime;
  }

  minutesToHoursAndMinutesString(minutes) {
    /*
    Converts minutes to a string in the format "Xh Ym".
  
    Args:
        minutes: An integer representing the number of minutes.
  
    Returns:
        A string in the format "Xh Ym", where X is the number of hours and Y is the remaining minutes.
    */

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursString = hours > 0 ? `${hours}h ` : '';
    const minutesString = remainingMinutes > 0 ? `${remainingMinutes}m` : '';

    return hoursString + minutesString;
  }

  convertMinutesToTimeString(minutesInput) {
    /*
    Converts minutes to a string in the format "HH:MM".
  
    Args:
        minutes: An integer representing the number of minutes.
  
    Returns:
        A string in the format "HH:MM", where HH is the hours with leading zeros and MM is the minutes with leading zeros.
    */

    const hours = Math.floor(minutesInput / 60);
    const minutes = minutesInput % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  static generateTimeList() {
    const times = [];
    let hours = 0;
    let minutes = 0;

    while (hours < 24) {
        let hourStr = hours < 10 ? `0${hours}` : `${hours}`;
        const minuteStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        times.push(`${hourStr}:${minuteStr}`);

        minutes += 10;
        if (minutes === 60) {
            minutes = 0;
            hours++;
        }
    }

    return times;
  }
}
