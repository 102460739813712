import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';
import { SessionStore } from 'zailab.common';

import { ConversationModel } from '../conversation-model';
import { ConversationService } from '../conversation-service';
import { StatusModel } from './status-model';
import {
  CONTACT_ACTIONS, CONVERSATION_ACTIONS,
  LOADER_ACTIONS
} from '../../contact/contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('Status');

@customElement('z-status')
@autoinject()
export class Status {
  @bindable private contactid: string;
  @bindable private conversation: ConversationModel;
  @bindable public statusCodes: StatusModel[];
  @bindable public canViewReminder: boolean;
  private toggleEdit: boolean = false;
  private memberId: string = null;
  private isCompleted: boolean = false;
  private initialStatus: string = null;
  private conversationStatus: string = null;
  private conversationReason: string = null;

  constructor(private eventAggregator: EventAggregator, private conversationService: ConversationService, private sessionStore: SessionStore) {
    this.memberId = this.sessionStore.get.user.memberId;
  }

  public attached(): void {
    if (this.conversation) {
      this.initialStatus = this.conversation.status;
    }
  }

  private selectStatus(status: StatusModel): void {
    this.conversationReason = null;
    this.isCompleted = false;
    this.closeAllReasonCodes();

    status.select();
    if (status.hasReasonCodes) {
      status.open();
    } else {
      this.isCompleted = true;
    }
    this.eventAggregator.publish('change.reminder', {
      contactId: this.conversation.contactId,
      conversationId: this.conversation.conversationId,
      showReminder: status.status === 'PENDING'
    });
    this.conversationStatus = status.status.toLowerCase();
  }

  private closeAllReasonCodes(): void {
    this.statusCodes.forEach(statusCode => {
      statusCode.close();
      statusCode.deselect();
    });
  }

  private selectReasonCode(status: StatusModel, reasonCode: string): void {
    status.isOpen = false;
    status.reason = reasonCode;
    this.conversationReason = reasonCode;
    this.isCompleted = true;
  }

  private done(): void {
    this.eventAggregator.publish(CONVERSATION_ACTIONS.REVIEW_STATUS, {
      status: this.conversationStatus,
      conversationId: this.conversation.conversationId,
      reason: this.conversationReason,
      member: this.memberId
    });
    this.eventAggregator.publish(CONTACT_ACTIONS.PREVIOUS, this.conversation.contactId);
    this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, { contactId: this.conversation.contactId, status: true, loader: 'conversationList' });
    this.resetValues();
  }

  private cancel(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: 'interactions',
      contactId: this.conversation.contactId
    });
    this.conversation.status = this.initialStatus;
    this.resetValues();
  }

  public detached(): void {
    this.resetValues();
  }

  private editConversationName(): void {
    this.toggleEdit = true;
  }

  private resetValues(): void {
    this.isCompleted = false;
    this.conversationReason = null;
    this.conversationStatus = null;
    this.statusCodes.forEach((code) => {
      code.deselect();
    });
  }

  private updateConversationName(): void {
    this.toggleEdit = false;
    let conversationName = this.conversation.determineConversationName();
    this.eventAggregator.publish(CONVERSATION_ACTIONS.CHANGE_NAME, {
      conversationId: this.conversation.conversationId,
      newName: conversationName
    });
  }
}
