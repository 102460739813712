import { INTERACTION_ACTIONS } from './../../interaction/interaction-actions';
import { inject, LogManager, customElement, bindable } from 'aurelia-framework';
import { InteractionModel } from '../../interaction-model';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { ConversationModel } from '../../conversation-model';
import { Event, SessionStore } from 'zailab.common';
import { ContactService } from '../../../contact/contact-service';
import { computedFrom } from "aurelia-binding";
import { WaypointModel } from '../../waypoint-model';
import { InteractionService } from '../../interaction/interaction-service';
import { InteractionService as OtherInteractionService } from '../../../interactions/interaction-service';
import { ConversationService } from '../../conversation-service';
import { MemberInteractionModel } from '../../interaction/member-interaction-model';
import { DispositionCodesService } from '../../../organisation/dispositioncodes/disposition-codes-service';
import { Disposition, DispositionCode, DefaultDispositionCode, DispositionModel } from '../../../organisation/dispositioncodes/disposition-codes-model';
import { ContactModel } from '../../../contact/contact-model';

const logger = LogManager.getLogger('SmsInteractionCard');

@customElement('z-sms-interaction-card')
@inject(
  Element,
  SessionStore,
  InteractionService,
  OtherInteractionService,
  EventAggregator,
  ConversationService,
  DispositionCodesService
)
export class SmsInteractionCard {
  @bindable private interaction: InteractionModel;
  @bindable private contactid: string;
  @bindable private correlationid: string;
  @bindable public contact: ContactModel;
  @bindable private unlinked: boolean;
  @bindable private conversations: ConversationModel[];

  public additionalDataVisible: boolean = false;
  private subscriptions: Subscription[] = [];
  public showDispositionCodeSelectButton: boolean = false;
  public disposition: string = null;
  public dispositionCode: DefaultDispositionCode = null;

  constructor(
    private element: Element,
    private sessionStore: SessionStore,
    private interactionService: InteractionService,
    private otherInteractionService: OtherInteractionService,
    private eventAggregator: EventAggregator,
    private conversationService: ConversationService,
    private dispositionCodesService: DispositionCodesService
  ) { }

  public bind(): void {
    this.subscriptions.push(this.eventAggregator.subscribe('select.disposition', () => {
      this.updateInteractionDisplay();
    }));

    this.subscriptions.push(this.eventAggregator.subscribe(INTERACTION_ACTIONS.SELECT, (data) => {
      if (this.interaction.interactionId === data.interaction.interactionId) {
        this.updateInteractionDisplay();
      }
    }));
  }

  public attached(): void {
    if (this.interaction) {
      this.buildSmsView();
    }
    const interactionId = this.interaction.interactionId;

    const interactionSubscription = this.eventAggregator.subscribe(
      'connected-interaction-' + interactionId,
      (interaction: any) => {
        if (!this.interaction) {
          return interactionSubscription && interactionSubscription.dispose();
        }
        if (interaction.interactionId !== interactionId) {
          return;
        }
        this.interaction.isLive = true;
      }
    );
    const connectedInteractionSubscription = this.eventAggregator.subscribe(
      'connection-interaction-updated',
      data => {
        if (!this.interaction) {
          return connectedInteractionSubscription && connectedInteractionSubscription.dispose();
        }
        if (this.interaction.isLive) {
          let isLiveInteraction = data && data.interactions && data.interactions.find(interaction => interaction.interactionId === this.interaction.interactionId);

          if (!isLiveInteraction) {
            this.interaction.isLive = false;
            this.eventAggregator.publish('cc-interaction-ended', { interactionId: this.interaction.interactionId, contactId: this.contactid, correlationId: this.contact.correlationId })
          }
        }
        if (!data.interactions || data.interactions.length === 0) {
          this.interaction.isLive = false;
          this.eventAggregator.publish('cc-interaction-ended', { interactionId: this.interaction.interactionId, contactId: this.contactid, correlationId: this.contact.correlationId })
        }
      }
    );
    this.eventAggregator.publish('request-connected-interactions', this.interaction.interactionId);
  }



  public selectDispositionCode(): void {
    this.eventAggregator.publish('member.select.disposition.code', {
      interactionId: this.interaction.interactionId,
      correlationId: this.correlationid
    });
  }

  public showAdditionalData(): void {
    this.additionalDataVisible = true;
  }

  public interactionChanged(newValue: any, oldValue: any): void {
    if (this.interaction) {
      this.buildSmsView();
    }
    if (!newValue || !oldValue || newValue.interactionId === oldValue.interactionId) {
      return;
    }
    this.updateInteractionDisplay();
  }

  private updateInteractionDisplay(): void {
    this.showDispositionCodeSelectButton = false;
    this.conversationService.retrieveInteraction(this.interaction.interactionId)
      .then((interaction) => {
        if (interaction.agent) {
          this.interaction.agent = interaction.agent;
        }

        const metaData = interaction.metaData as any;
        if (metaData.serviceId && metaData.direction) {
          this.interaction.metaData = metaData;
          let organisationId = this.sessionStore.get.organisation.organisationId;
          let serviceId = metaData.serviceId;
          let direction = metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
          let channel = 'SMS';
          this.dispositionCodesService.retrieveDispositionListByServiceChannelAndDirection(organisationId, serviceId, channel, direction, metaData.tiered)
            .then((dispositionList: DispositionModel) => {
              this.showDispositionCodeSelectButton = dispositionList && dispositionList.dispositionCodes && dispositionList.dispositionCodes.length > 0;
              if (this.showDispositionCodeSelectButton) {
                this.eventAggregator.publish('select-disposition-code:enabled' + this.interaction.conversationId);
              }
            });
        }
    });
  }

  public colorChanged(data: { timeInSeconds: number, color: string }): void {

    new Event(this.element, 'interaction-color-change', {
      contactId: this.contact.contactId,
      correlationId: this.contact.correlationId || this.interaction.correlationId,
      color: data.color,
      timeInSeconds: data.timeInSeconds,
      interactionId: this.interaction.interactionId
    });
  }

  @computedFrom('interaction', 'interaction.dispositionCode')
  public get dispositionTitle(): string {
    if (!this.interaction.dispositionCode) {
      return '';
    }
    let title = `Outcome: ${this.interaction.dispositionCode.conversationOutcome}\nReason: ${this.interaction.dispositionCode.interactionOutcomeReason}`;
    return title;
  }

  @computedFrom('interaction', 'interaction.dispositionCode')
  public get dispositionColor(): string {
    if (!this.interaction.dispositionCode) {
      return '';
    }
    return this.interaction.dispositionCode.isNegative ? 'var(--unsuccessful)' : this.interaction.dispositionCode.isPositive ? 'var(--successful)' : '';
  }

  private buildSmsView(): void {
    if (this.interaction && this.interaction.type === 'Contact Centre Flow') {
      this.interaction.direction = 'inbound';
    }
  }

  @computedFrom('contactid', 'interaction')
  private get agentName(): string {
    const user = this.sessionStore.get.user;
    if (user && !this.contactid) {
      return `${user.firstName} ${user.surname}`;
    }
    return this.interaction.agentName;
  }

  public endInteraction(): void {
    this.interactionService.endInteraction(this.interaction);
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId
    });
    this.eventAggregator.publish('dock-cards');
  }

  public detached(): void {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }
}
