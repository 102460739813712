import {BaseModel} from 'zailab.abstract';

export class AccountAddressModel extends BaseModel{

  public addressLine1: string = null;
  public addressLine2: string = null;
  public country: string = null;
  public code: string = null;
  public city: string = null;
  public region: string = null;

  constructor(address: AccountAddressModel){
    super();
    this.mapProperties(address);
  }
}