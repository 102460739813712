import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

import {WebSocket, UrlFactory, OplogService} from 'zailab.common';
import {PresenceModel} from './presence-model';

const logger = LogManager.getLogger('PresenceService');

@inject(HttpClient, UrlFactory, OplogService, WebSocket, EventAggregator)
export class PresenceService {
  constructor(httpClient, urlFactory, oplogService, webSocket, eventAggregator) {
    this.httpClient = httpClient;
    this.urlFactory = urlFactory;
    this.oplog = oplogService;
    this.webSocket = webSocket;
    this.eventAggregator = eventAggregator;
  }

  retrievePresences() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/user/users/presences')
          .asGet()
          .send()
          .then((response) => {
            resolve(response.presenceCodes);
          }, (error) => {
            reject(error);
          });
    });
  }

  retrieveSelectedPresence(_userId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/user/users/${_userId}/presence`)
          .asGet()
          .send()
          .then((response) => {
            resolve(response);
          }, (error) => {
            reject(error);
          });
    });
  }

  changePresence(_userId, presence) {
    const PAYLOAD = {
      presence: presence
    };
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/user/users/${_userId}/presence`)
          .asPut()
          .withContent(PAYLOAD)
          .send()
          .then((response) => {
            resolve(response);
          }, (error) => {
            reject(error);
          });
    });
  }

  initialiseOplog(userId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', userId).in('passport-projector.displayPresenceView'));
    });
  }
}
