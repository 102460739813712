import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {Router} from 'aurelia-router';
import {HttpClient} from 'aurelia-http-client';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';

import {WINDOW_EVENTS, SessionStore, SESSION_EVENTS, BootstrapFormRenderer, WebSocket, UrlFactory} from 'zailab.common';
import {LoginService} from './login-service';
import {UserSessionModel} from '../../../../_common/stores/sessionmodels/user-session-model';
import { AssistService } from '../../../organisation/assist/assist-service';

const logger = LogManager.getLogger('Login');

@autoinject
export class Login {
  private identity: string = '';
  private password: string = '';
  private error: string = null;
  private capsError: string = null;
  private submitted: boolean = false;
  private isLoading: boolean;
  private focusStart: string;
  private validation: ValidationController;
  private subscriptions: { [key: string]: Subscription } = {};
  private validateThatUserBelongsToOrganisation: boolean;
  private fromRoute: string;
  private showSSO: boolean = false
  private samlPath: String = null;

  constructor(
    private httpClient: HttpClient,
    private webSocket: WebSocket,
    private loginService: LoginService,
    private router: Router,
    private eventAggregator: EventAggregator,
    validationControllerFactory: ValidationControllerFactory,
    private sessionStore: SessionStore,
    public urlFactory: UrlFactory,
    private assistService: AssistService
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(params: { accessCode: string; email: string; }): void {
    if (params.accessCode) {
      this.authenticateWithAccessCode(params.accessCode);
      return;
    }
    this.validateThatUserBelongsToOrganisation = this.routedFromInvitation();
    //@ts-ignore
    this.focusStart = params.email ? 'password' : 'email';
    this.identity = params.email ? params.email : '';

    this.samlPath = this.router.history.fragment;
    if(this.samlPath.startsWith('/login/')){
      this.samlPath = this.samlPath.replace('/login/','');
      this.loginService.getLoginPath(this.samlPath).then((response) => {
        this.showSSO = response
      })
    }

    this.clearStateIfExists();
    this.setupValidations();
    this.enableCapslockOnPasswordDetection();
  }

  private authenticateWithAccessCode(accessCode: string): void {
    this.isLoading = true;
    this.loginService.authenticateWithAccessCode(accessCode).then(
      response => this.authenticationSuccessful(response),
      error => this.authenticationFailure(error)
    );
  }

  private routedFromInvitation(): boolean {
    //@ts-ignore
    const previousLocation = this.router.history.previousLocation;
    if(previousLocation) {
     return previousLocation.indexOf('acceptinvitation?email') !== -1;
    }
    return false;
  }

  private clearStateIfExists(): void {
    if (this.user) {
      this.eventAggregator.publish(SESSION_EVENTS.RESET.EVENT, {});
    }
    localStorage.removeItem('zai-consult');
  }

  private setupValidations(): void {
    ValidationRules.ensure('identity')
      .required()
      .withMessage('Please enter your email.')
      .then()
      .email()
      .withMessage('Please enter a valid email.')
      .ensure('password')
      .required()
      .withMessage('Please enter your password.')
      .on(this);
  }

  private enableCapslockOnPasswordDetection(): void {
    this.subscriptions[WINDOW_EVENTS.ON_CAPSLOCK_DETECTED] = this.eventAggregator.subscribe(WINDOW_EVENTS.ON_CAPSLOCK_DETECTED, message => { this.capsError = message.settings.message; });
    this.subscriptions[WINDOW_EVENTS.OFF_CAPSLOCK_DETECTED] = this.eventAggregator.subscribe(WINDOW_EVENTS.OFF_CAPSLOCK_DETECTED, () => { this.capsError = null; });
  }

  // user actions

  private forgotPassword(): void {
    this.router.navigate('password');
  }

  private join(): void {
    this.router.navigate('join');
  }

  private submit(): void {
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    this.error = '';

    this.validation.validate()
      .then(validation => {
        if (!validation.valid) {
          this.submitted = false;
          return;
        }
        this.triggerLogin();
      }, () => {
        this.submitted = false;
        this.error = 'Email or password is incorrect. Please try again.';
      });
  }

  private triggerLogin(): void {
    this.loginService.submitLogin(this.identity, this.password).then(
      response => this.authenticationSuccessful(response),
      error => this.authenticationFailure(error)
    );
  }

  private authenticationSuccessful(response: UserSessionModel): void {
    if(this.validateThatUserBelongsToOrganisation && this.isDefaultUser(response)) {
      setTimeout(() => {
        this.triggerLogin();
      }, 200);
      return;
    }

    this.storeSession(response);
    this.error = null;

    this.httpClient.configure(req => {
      req.withHeader('Authorization', 'Bearer ' + response.token);
      req.withHeader('Session', this.sessionStore.get.application.appId);
    });
    this.loginService.reportLogin(response.email, response.passportId, response.token);
  }

  private isDefaultUser(response: UserSessionModel): boolean {
    return response.userAccessRoles.length === 1;
  }

  private async storeSession(response: UserSessionModel): Promise<void> {
    const organisationId = await this.getOrganisationIdIfUserBelongsToAnOrganisation(response);
    this.subscribeToSessionStored(organisationId);
    this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, response);
    this.eventAggregator.publish(SESSION_EVENTS.ORGANISATION.EVENT, { organisationId });
  }

  private getOrganisationIdIfUserBelongsToAnOrganisation(response: UserSessionModel): string {
    for (let userAccessRole of response.userAccessRoles) {
      if (userAccessRole.accountType === 'ORGANISATION') {
        return userAccessRole.organisationId;
      }
    }
    return null;
  }

  private subscribeToSessionStored(organisationId: string): void {
    this.subscriptions[SESSION_EVENTS.UPDATED.USER] = this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.USER, () => this.sessionDataUpdated(SESSION_EVENTS.UPDATED.USER));
    if(organisationId) {
      this.subscriptions[SESSION_EVENTS.UPDATED.ORGANISATION] = this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.ORGANISATION, () => this.sessionDataUpdated(SESSION_EVENTS.UPDATED.ORGANISATION));
    }
  }

  private async sessionDataUpdated(sessionUpdatedEvent: string): Promise<void> {
    this.subscriptions[sessionUpdatedEvent].dispose();
    delete this.subscriptions[sessionUpdatedEvent];
    if(this.subscriptions[SESSION_EVENTS.UPDATED.USER] || this.subscriptions[SESSION_EVENTS.UPDATED.ORGANISATION]) {
      return;
    }
    this.openUserWebsocket();
  }

  private openUserWebsocket(): void {
    this.webSocket
      .resetWebsocket(this.user.userId)
      .then(() => {
        this.handleWebsocketOpened();
      })
      .catch(() => {
      });
  }

  private async handleWebsocketOpened(): Promise<void> {
    if (!this.user) {
      return;
    }
    
    if (this.sessionStore.get.user.hasAgentRole) {
      await this.retractTeamleaderAssistance();
    }
    this.router.navigate('hud/dashboard');
  }

  private async retractTeamleaderAssistance(): Promise<void> {
    try {
      const allAssists = await this.assistService.findAllAssists(this.sessionStore.get.user.memberId);
      const assist = allAssists.find((item) => item.handRaised);
      if (!assist) {
        return;
      }
      await this.assistService.postLowerHand(assist.assistId, assist.memberId);
    } catch(e) {}
  }

  private authenticationFailure(response: Error): void {
    this.isLoading = false;
    this.submitted = false;
    this.error = 'Something is not correct with what you have entered.';
  }

  private deactivate(): void {
    this.removeSubscriptions();
  }

  private removeSubscriptions(): void {
    for(let key in this.subscriptions) {
      this.subscriptions[key].dispose();
    }
  }

  public redirect(): void{
    window.location = this.urlFactory.buildUrl(this.router.history.location.origin, '/saml2/authenticate/' + this.samlPath, '');
  }

  @computedFrom('sessionStore.get.user')
  get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }
}
