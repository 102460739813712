import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/**/
import Service from '../prospect-list-service';
/**/
const logger = LogManager.getLogger('ClearRemainingProspectsDialog');
/**/
@inject(DialogController, Service)
export class ClearRemainingProspectsDialog {

  campaignId;
  prospectListId;

  constructor(dialogController, service) {
    this.dialogController = dialogController;
    this.service = service;
  }

  activate(data) {
    this.campaignId = data.campaignId;
    this.prospectListId = data.prospectListId;
  }

  confirm() {
    this.service.removeUnservedProspects(this.campaignId, this.prospectListId);
    this.dialogController.ok();
  }

  close() {
    this.dialogController.cancel();
  }
}
