import {LogManager, autoinject, computedFrom, PLATFORM} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';

import {CommandFactory, SessionStore, OplogService, MailboxService, WebSocket} from 'zailab.common';
import {OrganisationSessionModel} from './../../_common/stores/sessionmodels/organisation-session-model';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('Mailbox Dialog');

@autoinject
export class MailboxCreatorDialog {

  private currentMailbox: ZIMailbox = null;
  private steps: Array<object>;
  private mailboxes: ZIMailbox[];
  private isAddingContent: boolean = false;
  private completedMailboxCreation: boolean = false;

  constructor(private dialogController: DialogController, private eventAggregator: EventAggregator, private sessionStore: SessionStore, private mailboxService: MailboxService, private webSocket: WebSocket) {
  }

  public activate(_mailboxes: ZIMailbox[]): void {

    this.mailboxes = _mailboxes || [];

    this.initSteps();
  }

  private initSteps(): void {

    this.steps = [{
      name: 'Name',
      description: '',
      view: PLATFORM.moduleName('features/mailbox/steps/name/name'),
      active: true,
      hasAttributes: true,
      completed: false,
      data: this.mailboxes
    }, {
      name: 'Message',
      description: '',
      view: PLATFORM.moduleName('features/mailbox/steps/message/message'),
      hasAttributes: true,
      completed: false,
      data: this.currentMailbox
    }, {
      name: 'Add Members',
      description: '',
      view: PLATFORM.moduleName('features/mailbox/steps/add-members/add-members'),
      hasAttributes: true,
      completed: false,
      data: this.currentMailbox
    }];
  }

  private activateContentCreator(): void {
    this.isAddingContent = true;
  }

  private handleContentCreated(): void {
    this.isAddingContent = false;
    this.eventAggregator.publish('audio.file.created');
  }

  private cancelContentCreator(): void {
    this.isAddingContent = false;
  }

  private complete(wizardData: object): void {
    let _mailboxData: any = wizardData.detail;
    this.completedMailboxCreation = false;

    // Set up mailbox properties
    let newMailbox: ZIMailbox = {
      audioId: _mailboxData.audio.id,
      textToSpeechAttributes: _mailboxData.audio && _mailboxData.audio.text ? _mailboxData.audio : null,
      mailboxId: uuidv4(),
      mailboxName: _mailboxData.mailboxName,
      organisationId: this.organisation.organisationId
    };
    // Create Mailbox
    this.mailboxService.createMailbox(newMailbox).then(_result => {
      let mediaPayload: any = {
        mailboxId: newMailbox.mailboxId,
        audioId: _mailboxData.audio.id,
        organisationId: this.organisation.organisationId
      };

      let ttsPayload: any = {
        mailboxId: newMailbox.mailboxId,
        textToSpeechId: _mailboxData.audio.id,
        organisationId: this.organisation.organisationId,
        description: _mailboxData.audio.filename,
        text: _mailboxData.audio.text,
        speechLanguage: _mailboxData.audio.speechLanguage,
        languageCode: _mailboxData.audio.speechLanguage ? _mailboxData.audio.speechLanguage.languageCode : '',
        languageName: _mailboxData.audio.speechLanguage ? _mailboxData.audio.speechLanguage.languageName : '',
        speechRate: _mailboxData.audio.speechRate
      };

      // Add members to mailbox
      _mailboxData.recipients.forEach((_value, _index) => {
        this.mailboxService.getRecipientIdByMemberId(_value.id).then(_result => {
          let recipientId = _result;
          let recipientPayload: ZIMailboxRecipientPayload = {
            recipientId: recipientId,
            mailboxId: newMailbox.mailboxId
          };

          this.mailboxService.addMemberToMailbox(recipientPayload).then(_result => {
            if (_index === _mailboxData.recipients.length - 1) {
              this.dialogController.ok(_mailboxData);
            }
          });
        });
      });

      if (_mailboxData.audio.speechLanguage) {
        this.mailboxService.addAssociatedTTS(ttsPayload);
        return
      }

      this.mailboxService.addAssociatedAudio(mediaPayload).then(_result => {
        logger.info('Added audio to mailbox >>', _result);
      });
    });
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}