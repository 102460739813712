import { LogManager } from "aurelia-framework";

import { InteractionAgentStats } from './column-config/interaction-agent-stats';
import { DataGridMapping, DataGridSetting } from '../../../../../../components/templates/data-grid/data-grid';

const logger = LogManager.getLogger('LiveDashboardState');

class QAReportGridState {


  private readonly settingName: string = 'zailab_reports_settings';
  public state: {
    refreshTime: number,
    teams: any[],
    settings: DataGridSetting[],
  };

  constructor() {
    this.init();
  }

  private init(): void {
    if (!localStorage.getItem(this.settingName)) {
      const width = 100;
      this.state = {
        refreshTime: 30,
        teams: [],
        settings: this.defaultMappings.map((mapping, index) => {
          return new DataGridSetting(
            mapping.title,
            mapping.field.visible === undefined ? true : mapping.field.visible,
            mapping.field.width ? mapping.field.width : width,
            index,
            mapping.field.tooltip,
            mapping.field.disabled === undefined ? false : mapping.field.disabled,
            null,
            null,
            mapping.field.cssClass,
            mapping.field.filterType,
            mapping.field.disableSorting
          );
        })
      };
      this.save();
    } else {
      this.load();
    }
  }

  public load(): void {
    this.state = JSON.parse(localStorage.getItem(this.settingName));
  }

  public save(): void {
    localStorage.setItem(this.settingName, JSON.stringify(this.state));
  }

  public reset(items: any[]): void {
    items.forEach(item => {
      let _item = this.settings.find(setting => setting.title === item.title);
      if (_item) {
        _item = {
          ..._item,
          ...item
        };
      }
    })
  }

  public clear(): void {
    localStorage.removeItem(this.settingName);
    this.init();
  }

  public toggleVisible(title: string): void {
    const setting = this.state.settings.find((data) => data.title === title);
    setting.visible = !setting.visible;
  }

  public setColumnOrderAndWidths(columnOrderAndWidths: { title: string, width: number }[]): void {
    this.state.settings.forEach((setting) => setting.orderIndex = -1);
    columnOrderAndWidths.forEach((columnOrderAndWidth, index) => {
      const setting = this.state.settings.find((setting) => setting.title === columnOrderAndWidth.title);
      setting.orderIndex = index;
      setting.width = columnOrderAndWidth.width;
    });
    if (this.state.settings.length > columnOrderAndWidths.length) {
      const settings = this.state.settings.filter((setting) => setting.orderIndex === -1);
      settings.forEach((setting, index) => setting.orderIndex = columnOrderAndWidths.length + index);
    }
  }

  public setRefreshTime(time: any): void {
    this.state.refreshTime = time;
  }

  public get refreshTime(): number {
    return this.state.refreshTime;
  }

  public get settings(): DataGridSetting[] {
    return Object.assign([], this.state.settings);
  }

  public set settings(settings: any[]) {
    this.state.settings = settings.map(setting => new DataGridSetting(
      setting.title,
      setting.visible,
      setting.width,
      setting.orderIndex,
      setting.tooltip,
      setting.disabled,
      null,
      null,
      setting.cssClass,
      setting.filterType,
      setting.disableSorting
    ));
  }

  public get teams(): any[] {
    return Object.assign([], this.state.teams);
  }

  public get mappings(): Promise<DataGridMapping[]> {

    return new Promise(resolve => {
      const settings = [...this.settings];
      const mappings: DataGridMapping[] = [];

      let asyncTask = index => {
        // Simulate an asynchronous task to ensure all columns are mapped
        const setting = settings.find((setting) => setting.orderIndex === index);
        if (setting.visible) {
          const mapping = this.defaultMappings.find((mapping) => mapping.title === setting.title);
          if (!mapping) {
            return;
          }
          if (!mapping.field) {
            mapping.field = {};
          }
          mapping.field.width = setting.width;
          mappings.push(mapping);
        }
      }

      let promises = [];
      for (let i = 0; i < settings.length; i++) {
        promises.push(asyncTask(i));
      }

      Promise.all(promises).then(() => {
        resolve(this.defaultMappings);
      })
    });
  }
  
  public get defaultMappings(): DataGridMapping[] {
    return [].concat(
      new InteractionAgentStats().columnConfig,
    )
  }
}

export const QA_REPORT_GRID_STATE = new QAReportGridState();
