import {LogManager, autoinject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/**/
import {SessionStore} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
import {TelephoneNumberModel} from '../../../../../../components/atoms/inputs/phonenumber/telephone-number.model';
/**/
const logger = LogManager.getLogger('Call Forward Dialog');

/**/
interface INumberType {
  description: string;
  value: string;
}

@autoinject
export class EndcallDialog {

  private name: string = '';
  public nodeData: NodeModel;
  public existingConnections: Array<ConnectorModel> = [];
  public dialogHeader: string = 'End';
  private nodeConfiguration: ZNodeConfig;

  constructor(public dialogController: DialogController, private sessionStore: SessionStore) {
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.name = this.nodeData.properties.name || '';
  }

  public ok(): void {

    this.nodeData.properties = {
      name: this.name,
    };

    this.nodeData.properties.isDefined = true;

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.dialogController.ok(this.nodeData);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [];
    return _connections;
  }
}