import {LogManager, inject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {DialogController} from 'aurelia-dialog';
/**/
import {CUSTOM_EVENTS, Event} from 'zailab.common';
/**/
const logger = LogManager.getLogger('DaysOfWeekController');
const defaultDaysOfWeek:Array<string> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const defaultStartingHour:number = 9;
const defaultStartingMinute:number = 0;
const defaultEndingHour:number = 17;
const defaultEndingMinute:number = 0;

/**/
@inject(DialogController, BindingSignaler, Element)

export class DaysOfWeek {

  private tabData: ZTabData;
  private daysOfWeek: Array<ZDayTimeExpanded> = [];
  private optionList: Array<ZSwitchRadioInterface>;

  constructor(private dialogController: DialogController, private updateTimeSignal: BindingSignaler, private element: Element) {
  }

  public activate(tabData: ZTabData): void {
    this.setupStateData(tabData);
    this.setupDaysOfWeek();
    this.checkComplete();
    this.optionList = [
      {label: 'N/A', isSelected: true},
      {label: 'All Day', isSelected: false},
      {label: 'Custom', isSelected: false}
    ];
  }

  public attached(): void {
  }

  private setupStateData(tabData: ZTabData): void {
    this.tabData = tabData;
  }

  private setupDaysOfWeek(): void {
    if (this.tabData.data && this.tabData.data["customTimeRouteDates"] && this.tabData.data["type"] === "dow") {
      let _daysOfWeek: Array<any> = this.tabData.data["customTimeRouteDates"] as Array<any>;
      _daysOfWeek.map(_day => {
        let _customTimeRouteDate: ZDayOfWeek = _day.customTimeRouteDate;
        let _dayObject: ZDayTimeExpanded = {
          dow: _customTimeRouteDate.dow,
          startingHour: parseInt(_customTimeRouteDate.startTime.substr(0, 2)),
          endingHour: parseInt(_customTimeRouteDate.endTime.substr(0, 2)),
          startingMinute: parseInt(_customTimeRouteDate.startTime.substr(2, 2)),
          endingMinute: parseInt(_customTimeRouteDate.endTime.substr(2, 2)),
          type: this.extractType(_customTimeRouteDate),
          errors: []
        };
        this.daysOfWeek.push(_dayObject);
      });
    } else {
      // Create defaults
      defaultDaysOfWeek.map(_day => {
        let _dayObject: ZDayTimeExpanded = {
          dow: _day,
          startingHour: defaultStartingHour,
          endingHour: defaultEndingHour,
          startingMinute: defaultStartingMinute,
          endingMinute: defaultEndingMinute,
          type: "N/A",
          errors: []
        };
        this.daysOfWeek.push(_dayObject);
      })
    }
  }

  private extractType(_data: ZDayOfWeek): string {
    if (_data.allDay) {
      return "All Day"
    }
    if (_data.notApplicable) {
      return "N/A"
    }
    return "Custom"
  }

  private updateTime(): void {
    this.updateTimeSignal.signal('update-time-signal');
  }

  private passesValidation(_day: object): boolean {
    _day['errors'] = [];
    // Add extra validation here
    if (parseInt(_day['startingHour']) > parseInt(_day['endingHour'])) {
      _day['errors'].push('Start time cannot be after end time');
    }
    return _day['errors'].length === 0;
  }

  private packagePayload(): ZCustomTimeRoutesPayload {
    return {
      name: "time route",
      type: "dow",
      customTimeRouteDates: this.daysOfWeek.map(_day => {
        let _dayObject: ZDayOfWeek = {
          allDay: _day.type === "All Day",
          date: null,
          dow: _day.dow,
          notApplicable: _day.type === "N/A",
          startTime: this.convertTimeToString(_day, "start"),
          endTime: this.convertTimeToString(_day, "end")
        };
        return {customTimeRouteDate: _dayObject};
      })
    };
  }

  private convertTimeToString(_day: object, _type: string): string {
    return ('00' + _day[_type + "ingHour"]).slice(-2) + ('00' + _day[_type + "ingMinute"]).slice(-2) + "00"
  }

  private checkComplete():void {
    if (this.checkNA()) {
      this.signalComplete();
    } else {
      this.signalIncomplete();
    }
  }

  private meridiem(hour: number): string {
    return hour < 12 ? 'AM' : 'PM'
  }

  private checkNA():boolean {
    return !!this.daysOfWeek.filter(_day => {
      return _day['type'] !== "N/A"
    }).length
  }

  private signalComplete(): void {
    this.element.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TAB_COMPLETE, {
      bubbles: true,
      detail: this.packagePayload()
    }));
  }

  private signalIncomplete(): void {
    this.element.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TAB_INCOMPLETE, {
      bubbles: true,
      detail: {}
    }));
  }

  private markAllDaysNotEditing():void {
    this.daysOfWeek.forEach(_value => {
      _value.isEditing = false;
    })
  }

  /* METHODS CALLED FROM VIEW */

  private editHours(day: ZDayTime): void {
    this.signalIncomplete();
    this.markAllDaysNotEditing();
    day.isEditing = true;
  }

  private updateSelection(_event: Event, _day: ZDayTime): void {
    _event.stopPropagation();
    _day.type = _event.detail.active;
    this.checkComplete();
  }

  private confirmHours(day: ZDayTime): void {
    if (this.passesValidation(day)) {
      day.isEditing = false;
      this.updateTime();
      this.signalComplete();
    }

  }
}
