import {LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('StatusModel');

export class StatusModel extends BaseModel {

  public statusColor: string;
  public statusIcon: string;
  public isOpen: boolean;
  public isSelected: boolean;

  constructor(public status: string, public reasonCodes: string[], public reason: string) {
    super();
    this.status = status;
    this.reasonCodes = reasonCodes;
    this.reason = reason;
    this.setColor(status);
    this.setIcon(status);
  }

  private setColor(status: string): void {
    switch (status) {
      case 'SUCCESSFUL':
        this.statusColor = 'green';
        break;
      case 'UNSUCCESSFUL':
        this.statusColor = 'red';
        break;
      case 'PENDING':
        this.statusColor = 'yellow';
        break;
    }
  }

  private setIcon(status: string): void {
    switch (status) {
      case 'SUCCESSFUL':
        this.statusIcon = 'check';
        break;
      case 'UNSUCCESSFUL':
        this.statusIcon = 'times';
        break;
      case 'PENDING':
        this.statusIcon = 'pending';
        break;
    }
  }

  get hasReasonCodes(): boolean {
    return this.reasonCodes && this.reasonCodes.length > 0;
  }

  public close(): void {
    this.isOpen = false;
  }

  public select(): void {
    this.isSelected = true;
  }

  public deselect(): void {
    this.isSelected = false;
  }

  public open(): void {
    this.isOpen = true;
  }

}
