import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('DigitModel');
/**/
interface Digit {
  value?: string,
  isSelected?: boolean
}

export class DigitModel extends BaseModel {
  public value: string = null;
  public isSelected: boolean = null;

  constructor(digit: Digit) {
    super();
    super.mapProperties(digit);
  }
}