import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/*
 * */
import {AssignEndpointDialogService} from './assign-endpoint-dialog-service';
/*
 * */
const logger = LogManager.getLogger('AssignEndpointDialog');
/*
 * */
@inject(DialogController, AssignEndpointDialogService)
export class AssignEndpointDialog {

  dialogHeader = 'Assign Endpoints';
  liveNumbers = 'Assign Live Endpoints';
  assignedEndpoints = [];
  availableEndpoints = [];
  interactionFlowId;
  organisationId;
  liveNumbers;

  constructor(dialogController, assignEndpointDialogService) {
    this.dialogController = dialogController;
    this.assignEndpointDialogService = assignEndpointDialogService;
  }

  activate(model) {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.organisationId = model.organisationId;

    if (model.liveNumbers === true) {
      this._setNumbersType(model.liveNumbers);
    }
    this._retrieveAssignedEndpoints();
  }

  _setNumbersType(liveNumbers) {
    this.liveNumbers = liveNumbers;
    this.dialogHeader = this.liveDialogHeader;
  }

  _retrieveAssignedEndpoints() {
    const params = { live: this.liveNumbers };
    this.assignEndpointDialogService._retrieveAllEndpoints(params).then(
      (response) => {
        if (response.endpoints) {
          for(let endpoint of response.endpoints){
            if(endpoint.flowIds && endpoint.flowIds.indexOf(this.interactionFlowId) >= 0){
              this.assignedEndpoints.push(endpoint);
            } else if(endpoint.flowIds && endpoint.flowIds.length == 0){
              this.availableEndpoints.push(endpoint);
            }
          }
        }
      },
      (error) => {
        logger.info('Retrieving assigned endpoints > error = ', error);
      });
  }

  assignEndpointToInteractionFlow(endpoint) {
    this.assignEndpointDialogService.assignEndpointToInteractionFlow(this.interactionFlowId, endpoint.configId);
    this.assignedEndpoints.push(endpoint);
    this.availableEndpoints.splice(this.availableEndpoints.indexOf(endpoint), 1);
  }

  unassignEndpointFromInteractionFlow(endpoint) {
    this.assignEndpointDialogService.unassignEndpointFromInteractionFlow(this.interactionFlowId, endpoint.configId);
    this.availableEndpoints.push(endpoint);
    this.assignedEndpoints.splice(this.assignedEndpoints.indexOf(endpoint), 1);
  }

  cancel() {
    this.dialogController.cancel();
  }

  done() {
    this.dialogController.ok();
  }

}
