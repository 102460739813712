import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer} from 'zailab.common'
/*
 * */
const logger = LogManager.getLogger('ChangePasswordDialog');
/*
 * */
@inject(DialogController, ValidationControllerFactory)
export class ChangePasswordDialog {

  password = '';
  newPassword = '';
  confirmNewPassword = '';
  changePasswordFormGroup;
  dialogHeader = "Change Password";

  constructor(dialogController, validationControllerFactory) {

    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * */
  activate() {
    this._initValidation(this.validation);
  }

  /**
   * initialises the form validation
   * @param validation
   * @private
   */
  _initValidation(validation) {

    ValidationRules
      .customRule('confirmNewPassword', (value) => !value || value === this.newPassword, 'Does not match the entered password.');

    ValidationRules
      .ensure('password')
      .required().withMessage('Please do not leave this blank.')
      .ensure('newPassword')
      .required().withMessage('Please do not leave this blank.')
      .matches(/^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])\S{8,50}$/).withMessage('Must be between 8 and 50 characters long, and contain at least one lowercase, one uppercase and one number.')
      .ensure('confirmNewPassword')
      .required().withMessage('Please do not leave this blank.')
      .satisfiesRule('confirmNewPassword')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.changePasswordFormGroup.reset();
    this.dialogController.cancel();
  }

  /**
   * closes the aurelia dialog
   * @returns passwords {object}
   * */
  change() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }

      this.dialogController.ok({
        oldPassword: this.password,
        newPassword: this.newPassword
      });
    }, () => {
    });
  }
}

