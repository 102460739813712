import { autoinject, LogManager, customElement, bindable, PLATFORM } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import { AbstractList } from 'zailab.abstract';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
import { AgentModel } from '../../models/agent-model';
import { AgentDailyStatsModel } from '../agentstatsdialog/agent-stats-dialog-model';
import { TeamLeaderService } from '../../team-leader-service';
/*
 */
import { OplogService } from '../../../../../_common/services/database-service';
import { ChannelModel } from '../../models/channel-model';
import { PlaceholderService } from "../../../../../_common/services/placeholder-service";
import { AgentOverviewDialog } from './agent-overview-dialog';
import { FeatureFlagService } from '../../../../featureflags/feature-flag-service';
import { ArrayTools } from '../../../../../zailab.common';
import { PresenceService } from '../../../../user/passport/presence/presence-service';
/*
 */
const logger = LogManager.getLogger('AgentManifest');
/*
 */
@customElement('z-teamleader-agent-manifest')
@autoinject
export class AgentManifest extends AbstractList {
  @bindable team: string;
  private agents: AgentModel[] = [];
  private subscriptions: Array<any> = [];
  private loading: boolean = true;
  public disableWhisperEnabled: boolean = false;
  public showAgentMenu: boolean = false;
  private spyAction = {
    action: 'spy',
    label: 'Spy',
    icon: 'view',
    disabled: false
  };
  private whisperAction = {
    action: 'whisper',
    label: 'Whisper',
    icon: 'call-outbound',
    disabled: true
  };
  private logoutAction = {
    action: 'logout',
    label: 'Logout',
    icon: 'power',
    disabled: false
  };
  private actions = [
    {
      action: 'view',
      label: 'Agent Stats',
      icon: 'bar-chart'
    }
  ];
  public presences = [];
  private placeholderService: PlaceholderService;
  private placeholders: number = 0;
  public container: Element;

  public currentSelectedFilter = 'ALL';

  public filteredAgents: AgentModel[];

  public viewState = new ViewState();

  constructor(
    private featureFlagService: FeatureFlagService,
    private teamLeaderService: TeamLeaderService,
    private oplogService: OplogService,
    private dialogService: DialogService,
    private bindingSignaler: BindingSignaler,
    private presenceService: PresenceService,
    eventAggregator: EventAggregator,
  ) {
    super(eventAggregator);
    this.loading = false;
  }

  public bind(): void {
    this.retrievePresences();
  }

  private async retrievePresences(): Promise<void> {
    try {
      let presences: { presenceCodeName: string; color: string; }[] = await this.presenceService.retrievePresences();
      this.presences = ArrayTools.sort(presences, 'presenceCodeName');
    } catch(error) {
      logger.warn(`Could not retrieve presences >>`, error);
    }
  }

  public teamChanged(newValue: string): void {
    this.loading = true;
    this.unsubscribe();
    this.teamLeaderService.retrieveTeamAgentsDailyActivity(this.team).then(agents => this.teamAgentActivityRetrieved(agents), this.reportError);
  }

  private unsubscribe(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private teamAgentActivityRetrieved(agents: AgentModel[]): void {
    this.agents = agents;
    this.updateAgent();
    this.agents.forEach(agent => {
      this.subscribeAgent(agent.memberId);
    });
    this.loading = false;
    super.hideLoader();
    this.updateAgentList();
    this.bindingSignaler.signal('agent-availability-members');
  }

  private subscribeAgent(memberId: string): void {
    this.subscribe('_id', memberId, 'member-projector.memberDailyStatisticsView', 'update', response => {
      this.updateAgent(response);
    });
    this.subscribe('_id', memberId, 'member-projector.memberDailyStatisticsView', 'insert', response => {
      this.updateAgent(response);
    });
  }

  private subscribe(keyField: string, keyValue: string, viewName: string, operation: string, callback: any): void {
    let subscription = this.oplogService.subscribeOn(keyField, keyValue).in(viewName);
    subscription.on(operation, callback);
    this.subscriptions.push(subscription);
  }

  private updateAgent(response?: any): void {
    let memberId: string = response ? response._id : null;

    for (let agent of this.agents) {
      if (response && agent.memberId === memberId) {
        agent.mapProperties(response);
      }

      let list = [].concat(this.actions);

      for (let i = list.length - 1; i >= 0; i--) {
        let action = list[i];
        if (action.action === 'spy' || action.action === 'whisper') {
          list.splice(i, 1);
        }
      }

      if (agent.callId && agent.isConversing && !agent.onWrapUp) {

        this.spyAction.disabled = (agent.spyLocked || agent.whisperLocked);
        this.whisperAction.disabled = (agent.spyLocked || agent.whisperLocked);
        if (this.disableWhisperEnabled) {
          list.push(this.spyAction);
        } else {
          list = list.concat([this.spyAction, this.whisperAction]);
        }
      }

      if (agent.currentPresence !== 'Offline') {
        list = list.concat([this.logoutAction]);
      }
      agent.actions = list;
    }
  }

  private toggleAgentMenu(agent: AgentModel): void {
    this.agents.forEach(_agent => {
      if (_agent.memberId !== agent.memberId) {
        _agent.showMenu = false;
      }
    });
    agent.showMenu = !agent.showMenu;
  }

  private viewAgentStats(agent: AgentModel): void {
    let agentMenu: string = PLATFORM.moduleName('features/dashboard/teamleader/components/agentstatsdialog/agent-stats-dialog');
    let memberId: string = agent.memberId;
    let fullName: string = agent.fullName;
    let averageSurveyScore: string = agent.averageSurveyScore;

    this.dialogService.open({ viewModel: agentMenu, model: { memberId, fullName, averageSurveyScore } }).whenClosed(dialog => {
      if (dialog.wasCancelled) {
        return;
      }
    });
  }

  private logout(agent: AgentModel): void {
    this.teamLeaderService.logout(agent.passportId);
  }

  private toggleRoutingStatus(agent: AgentModel): void {
    var routingStatus = (agent.currentRoutingStatus.toLowerCase() === 'ready') ? 'NOT_READY' : 'READY';
    this.teamLeaderService.setRoutingStatus(agent.memberId, routingStatus);
  }

  public changePresence(agent: AgentModel, presence: string): void {
    this.teamLeaderService.changePresence(agent.userId, presence);
    agent.currentPresence = presence;
    // @ts-ignore
    agent.displaySearchResults = false;
  }

  private spy(callId: string): void {
    logger.info('CHECK :: SPY');
    this.teamLeaderService.spyCall(callId);
  }

  private whisper(callId: string): void {
    logger.info('CHECK :: WHISPER');
    this.teamLeaderService.whisperCall(callId);
  }

  get blockSnooping(): boolean {
    if (this.agents) {
      for (let agent of this.agents) {
        if (agent.spyLocked || agent.whisperLocked || agent.onWrapUp) {
          return true;
        }
      }
      return false;
    }
  }

  private cardHover(agent: AgentModel): void {
    agent.isHovered = !agent.isHovered;
  }

  private reportError(msg: any): void {
    logger.error('errorMessage >', msg);
  }

  public attached(): void {
    this.featureFlagService.isEnabled('disableWhisper')
      .then((disableWhisperEnabled) => {
        this.disableWhisperEnabled = disableWhisperEnabled;
      });
  }

  public deactivate(): void {
    this.unsubscribe();
  }

  public getFilteredAgents(): AgentModel[] {
    if (this.currentSelectedFilter === 'ALL') {
      return this.agents;
    }
    return this.agents.filter((agent) => {
      if (this.currentSelectedFilter === 'OFFLINE') {
        return agent.currentPresence && agent.currentPresence.toLowerCase() === 'offline';
      }
      return agent.currentPresence && agent.currentPresence.toLowerCase() !== 'offline';
    });
  }

  public updateAgentList(): boolean {
    this.filteredAgents = this.getFilteredAgents();
    this.placeholderService = new PlaceholderService(this.container, this.filteredAgents.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
    return true;
  }

  public editAgent(agent: AgentModel): void {
    this.dialogService.open({ viewModel: AgentOverviewDialog, model: {agent, disableWhisperEnabled:this.disableWhisperEnabled} }).whenClosed(dialog => {
      if (dialog.wasCancelled) {
        return;
      }
    });
  }

  public togglePresenceSelector(agent: any): void {
    agent.displaySearchResults = !agent.displaySearchResults;
  }
}

class ViewState {

  public static LIVE: string = 'LIVE';
  public static OVERVIEW: string = 'OVERVIEW';

  private selected = ViewState.LIVE;

  public get isLive(): boolean {
    return this.selected === ViewState.LIVE;
  }

  public get isOverview(): boolean {
    return this.selected === ViewState.OVERVIEW;
  }

  public toggleSelected(): void {
    if (this.isLive) {
      this.selectOverview();
    } else if (this.isOverview) {
      this.selectLive();
    }
  }

  private selectLive(): void {
    this.selected = ViewState.LIVE;
  }

  private selectOverview(): void {
    this.selected = ViewState.OVERVIEW;
  }
}