import { autoinject, LogManager } from 'aurelia-framework';

import moment from 'moment';
import $ from 'jquery'

const logger = LogManager.getLogger('TemporaryLoadBalancersTab');

@autoinject
export class TemporaryLoadBalancersTab {

  private loadBalancer: ZLoadBalancer;

  constructor() {}

  private activate({ data }: { data: ZLoadBalancer }): void {
    this.loadBalancer = data;
  }

  private formatReadableDate(timestamp: string): string {
    return moment(parseInt(timestamp)).format('DD/MM/YYYY hh:mm');
  }

  private toggleExpandRatio(ratio: ZRatio, index: number): void {

    let sectionEl = `#ratio_${index}`;
    if (!ratio.expanded) {
      this.toggleAccordion(sectionEl, true);
    } else {
      this.toggleAccordion(sectionEl, false);
    }
    ratio.expanded = !ratio.expanded;
  }
  
  private toggleAccordion(elementId: string, expand: boolean): void {
    let childHeight = $(elementId).find("ul:first").height();

    if (expand) {
      $(elementId).height(childHeight);
      setTimeout(() => {
        $(elementId).height('auto');
      }, 200);
      return;
    }
    $(elementId).height(childHeight);
    $(elementId).height(0);
  }
}