import { autoinject } from "aurelia-dependency-injection";
import { LogManager, PLATFORM } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";

const logger = LogManager.getLogger('AutoLogout');

@autoinject()
export class AutoLogout {

  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: ['', 'list'],
        name: 'Auto Logout List',
        moduleId: PLATFORM.moduleName('./list/auto-logout-list'),
        title: 'List'
      }
    ]);
    this.router = router;
  }
}