import {bindable, customElement, LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('ZTextBulb');

@customElement('z-text-bulb')
export class ZTextBulb {

  @bindable private size : string;
  @bindable({attribute: 'text-color'})
  textColor : string;
  @bindable({attribute: 'bulb-color'})
  bulbColor : string;
  @bindable private spacing : string;
  @bindable private capitals : string = '';
  @bindable({attribute: 'reverse-order'})
  reverseOrder : boolean;
  @bindable private weight : string;
  @bindable({attribute: 'font-family'})
  fontFamily: string;
  @bindable({attribute: 'font-weight'})
  fontWeight: string;

  private created() : void {
    this.textTransform();
  }

  private textTransform() : void {

    switch (this.capitals) {

      case 'all': this.capitals = 'uppercase';
        break;

      case 'true': this.capitals = 'uppercase';
        break;

      case 'first': this.capitals = 'capitalize';
        break;

      default: 'none';
    }

  }
}
