import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { ZaiForm } from 'zailab.common';
import { AssistantService } from '../assistants-service';

import { v4 as uuidv4 } from 'uuid';

import './add-assistant-dialog.scss';

const logger = LogManager.getLogger('AddAssistantDialog');

@autoinject()
export class AddAssistantDialog {
  public form: any[][];
  public formData: {
    name: string;
    description: string;
    purpose: string;
    greeting: string;
    token: string;
    valid: boolean;
  };
  public model: {
    name: string;
    description: string;
    purpose: string;
    greeting: string;
    token: string;
  };
  private existingNames: string[];

  public error: string;
  public loading = false;
  public submitting = false;

  constructor(
    private dialogController: DialogController,
    private assistantService: AssistantService
  ) {}

  public async activate(existingNames: []): Promise<void> {
    this.existingNames = existingNames;
    this.generateForm();
  }

  private generateForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput()
      .withFocus()
      .fullWidth()
      .withIdentifier('name')
      .withTitle('Name', '160px')
      .withPlaceholder('Enter text here.')
      .withValue('')
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 50 },
        {
          validationType: ZaiForm.VALIDATION_TYPES.UNIQUE_NAME,
          value: this.existingNames,
        },
      ])
      .insertField()
      
      .newRow()
      .newField()
      .asTextInput()
      .fullWidth()
      .withIdentifier('description')
      .withTitle('Description', '160px')
      .withPlaceholder('Enter enter description here.')
      .withValue('')
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 100 },
      ])
      .insertField()
      
      .newRow()
      .newField()
      .asTextArea()
      .fullWidth()
      .withIdentifier('purpose')
      .withTitle('Purpose', '160px')
      .withPlaceholder('Enter enter purpose here.')
      .withValue('')
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 100 },
      ])
      .insertField()
      
      .newRow()
      .newField()
      .asTextArea()
      .fullWidth()
      .withIdentifier('greeting')
      .withTitle('Greeting', '160px')
      .withPlaceholder('Enter enter greeting here.')
      .withValue('')
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
      ])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public formDataChanged(data: any): void {
    this.model = { ...data };
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public confirm(): void {
    this.error = '';
    if (this.formData.valid) {
      this.submitting = true;

      this.createAssistant(
        uuidv4(),
        this.model.name.trim(),
        this.model.description.trim(),
        this.model.purpose.trim(),
        this.model.greeting.trim(),
      );
    }
  }

  private createAssistant(
    id: string,
    name: string,
    description: string,
    purpose: string,
    greeting: string,
  ): void {
    this.assistantService
      .createAssistant(
        id,
        name,
        description,
        purpose,
        greeting,
      )
      .then(() => this.dialogController.ok({
        id,
        name,
        description,
        purpose,
        greeting,
      }))
      .catch((e) => {
        logger.error('Failed to update WFM Config Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }
}
