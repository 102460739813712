import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import { v4 as uuidv4 } from 'uuid';

@autoinject
export class AudioGroupService {

  private baseUrl = '/v1/media/audio-group';

  constructor(private httpClient: HttpClient) {
  }

  public getAudioGroups(): Promise<any> {
    return this.httpClient
      .createRequest(this.baseUrl)
      .asGet()
      .send()
      .then((response: any) => {
        return response.audioGroups.map((group) => {
          group.canDelete = !group.links || !group.links.length;
          return group;
        });
      });
  }

  public getAudioGroup(audioGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${audioGroupId}`)
      .asGet()
      .send();
  }

  public addAudioGroup(audioGroupName: string): Promise<any> {
    const audioGroupId = uuidv4();
    return this.httpClient
      .createRequest(`${this.baseUrl}/${audioGroupId}`)
      .withContent({ audioGroupName })
      .asPost()
      .send();
  }

  public deleteAudioGroup(audioGroupId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${audioGroupId}`)
      .asDelete()
      .send();
  }

  public allocateAudioGroup(audioGroupId: string, audioId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${audioGroupId}/link/${audioId}`)
      .asPost()
      .send();
  }

  public deallocateAudioGroup(audioGroupId: string, audioId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${audioGroupId}/link/${audioId}`)
      .asDelete()
      .send();
  }
}