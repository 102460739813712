import { EventAggregator } from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';
import {ContactModel} from '../contact-model';
import {FlowsModel} from '../contactcontroller/flows-model';
import {StatusModel} from '../../conversation/status/status-model';
import {BindingSignaler} from 'aurelia-templating-resources';
import { ZIOplog } from '../../../../../typings/zai/zai.common';

const logger = LogManager.getLogger('ContactCard');

@customElement('z-contact-card')
@autoinject
export class ContactCard {

  @bindable public contact: ContactModel;
  @bindable public flows: FlowsModel;
  @bindable public statusCodes: StatusModel[];
  @bindable public onCall: boolean;
  @bindable public loaders: {
    contactInfoLoader: boolean;
    conversationLoader: boolean;
    unlikedInteractionLoader: boolean;
    interactionLoader: boolean;
  };
  public contactPanelIsExpanded = false;
  public conversationPanelIsExpanded = true;
  protected connectedInteractionsOplog: ZIOplog = null;

  constructor(
    private bindingSignaler: BindingSignaler,
    private eventAggregator: EventAggregator
  ) {}

  public attached(): void {
    this.setupSignaller();
  }

  private interval: number;
  private setupSignaller(): void {
    let signal = 5000;
    this.interval = setInterval(() => {
      this.bindingSignaler.signal('myTimeAgoSignal');
    }, signal);
  }

  public expandContactPanel(): void {
    this.contactPanelIsExpanded = !this.contactPanelIsExpanded;
    this.conversationPanelIsExpanded = false;
  }

  public expandListPanel(): void {
    this.conversationPanelIsExpanded = !this.conversationPanelIsExpanded;
    this.contactPanelIsExpanded = false;
  }

  public detached(): void {
    if (this.contact && this.contact.contactId) {
      this.contact.unsunbscribeFromSubscriptions();
    }
  }
}
