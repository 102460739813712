import {EventAggregator} from 'aurelia-event-aggregator';
import {inject, LogManager}  from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/*
 */
import {PeerService, PEER_SERVICE_EVENTS} from 'zailab.common';
import {HUD_ANIMATOR_EVENTS} from '../../../../hud/hud.animator.events';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';
/*
*/
const logger = LogManager.getLogger('IncomingCallDialog');
/*
*/
@inject(EventAggregator, PeerService, DialogController, ZaiFileLoader)
export class IncomingCallDialog {

  items;

  constructor(eventAggregator, peerService, dialogController, zaiFileLoader) {

    this.eventAggregator = eventAggregator;
    this.peerService = peerService;
    this.controller = dialogController;
    this.zaiFileLoader = zaiFileLoader;
  }

  activate(items) {

    this.kioskCall = items.kioskCall;
    this.items = items;

    this.eventAggregator.subscribe('peer.call.disconnected', () => {

      if (this.controller) {
        this.controller.close();
      }
    });
    this.eventAggregator.subscribe('call.dialog.close', () => {
      this.autoClose();
    });
  }

  /**
   * close dialog because of event
   */
  autoClose() {
    this.controller.close();
    this.eventAggregator.publish(HUD_ANIMATOR_EVENTS.PROMPT_CLOSED);
  }

  /**
   * close dialog because
   */
  cancel() {
    this.controller.close();
  }

  /**
   * accept a kiosk call
   */
  acceptCall() {

    this.peerService.acceptCall(() => {

      if (this.controller) {
        this.controller.close();
      }

      this.eventAggregator.publish(PEER_SERVICE_EVENTS.PEER_CALL_ANSWER_ACCEPT);
    });
  }

  /**
   * reject a kiosk call
   */
  rejectCall() {

    this.peerService.rejectCall(() => {

      if (this.controller) {
        this.controller.close();
      }

      this.eventAggregator.publish(PEER_SERVICE_EVENTS.PEER_CALL_ANSWER_REJECT);
    });
  }
}
