import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('ChangeProfileInformationDialog');
/*
 * */
@inject(DialogController, ValidationControllerFactory)
export class ChangeProfileInformationDialog {

  firstName = '';
  surname = '';
  changeProfileInformationFormGroup;
  dialogHeader = "Change Name";

  constructor(dialogController, validationControllerFactory) {
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * */
  activate(user) {

    this.firstName = '' + user.firstName;
    this.surname = '' + user.surname;

    ValidationRules
      .ensure('firstName')
      .required().withMessage('First name is required.')
      .matches(/^([a-zA-Z-\s]){2,30}$/).withMessage('Must be between 2 and 30 characters long, and cannot contain special characters.')
      .ensure('surname')
      .required().withMessage('Last name is required.')
      .matches(/^([a-zA-Z-\s]){2,30}$/).withMessage('Must be between 2 and 30 characters long, and cannot contain special characters.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.changeProfileInformationFormGroup.reset();
    this.dialogController.cancel();
  }

  /**
   * closes the dialog
   * @return name {object}
   */
  rename() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }

      this.dialogController.ok({
        firstName: this.firstName,
        surname: this.surname
      });
    }, () => {});
  }
}

