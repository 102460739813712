import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import {
  ArrayTools,
  DateTimeTools,
  Event,
  ZaiForm,
} from 'zailab.common';
import { InHouseWFMService } from '../in-house-wfm-service';
import { PresenceService } from '../../../../user/passport/presence/presence-service';
import { WorkTypesService } from '../../../conversation/worktypes/work-types-service';

import './edit-shift-dialog.scss';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('EditShiftDialog');

@autoinject()
export class EditShiftDialog {
  private name = '';
  private startTime = '08:00';
  private duration = '08:00';

  public presences: { presenceCodeName: string }[];

  public form: any[][];
  public formData: {
    name: string;
    startTimeOfDay: string;
    shiftDurationInMin: string;
    valid: boolean;
  };
  private submitting: boolean;

  public breakForm: any[][];
  public breakFormData;

  public nonQueueWorkForm: any[][];
  public nonQueueWorkFormData;

  public workTypesForm: any[][];
  public workTypesFormData;
  private workTypes: any[] = [];
  private selectedWorkTypesMap: { [key: string]: string };
  private selectedWorkTypes: string[];

  public tableColumns = ['Name', 'Time', 'Duration'];
  private editingBreakIndex: number;
  private breakNameSet: string;
  private break: {
    name: string;
    maxDurationInMin: string;
    minStartTimeOfDay: string;
    maxStartTimeOfDay: string;
    presence: string;
  };
  public breaksTable = [];
  public breaks = [];
  public editingBreak: any;

  public nonQueueWorkTableColumns = ['Presence', 'Duration'];
  private nonQueueWork: {
    maxDurationInMin: string;
    presence: string;
  };
  public nonQueueWorkTable = [];
  public nonQueueWorkList = [];

  private memberIds: { memberId: string, shiftIds: string[] }[];
  private shift: any;
  public error: string;
  public loading = false;

  constructor(
    private dialogController: DialogController,
    private inHouseWFMService: InHouseWFMService,
    private presenceService: PresenceService,
    private workTypeService: WorkTypesService,
    private dateTimeTools: DateTimeTools
  ) {}

  public async activate(data: {
    memberId: string,
    shifts: any[]
  }[]): Promise<void> {
    this.memberIds = data.map(item => {
      return {
        memberId: item.memberId,
        shiftIds: item.shifts.map(shift => shift.id)
      };
    });
    this.shift = data[0].shifts[0];
    this.mapShift();
  }

  private async mapShift(): Promise<void> {
    this.loading = true;

    if (this.shift) {
      this.name = this.shift.name;
      this.startTime = this.shift.startTime;
      this.duration = this.dateTimeTools.convertMinutesToTimeString(
        this.shift.durationInMin
      );

      this.breaks = this.shift.breaks.map((br) => {
        br.maxDurationInMin = this.dateTimeTools.convertMinutesToTimeString(
          br.maxDurationInMin
        );
        return br;
      });

      this.nonQueueWorkList = Object.entries(
        this.shift.offDutyWork
      ).map(([presence, maxDurationInMin]) => {
        const data = {
          presence,
          maxDurationInMin,
        };

        const convertedTime =
          this.dateTimeTools.minutesToHoursAndMinutesString(maxDurationInMin);
        this.nonQueueWorkTable.push([presence, convertedTime]);
        return data;
      });
      this.selectedWorkTypesMap = this.shift.taskTemplates;
      this.selectedWorkTypes = Object.values(this.shift.taskTemplates);
    }

    await this.setBreakState();
    await this.setNonQueueWorkState();
    await this.generateShiftTemplateForm();
    await this.retrievePresences();
    await this.populateBreaksTable();
    await this.retrieveWorkTypes();
    await this.generateWorkTypesForm();
    this.loading = false;
  }

  private generateShiftTemplateForm(): void {
    new ZaiForm()
      .newRow()
      .newField()
      .asTimeInput()
      .withTitle('Start Time', '138px')
      .withIdentifier('startTimeOfDay')
      .withValue(this.startTime)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()
      .newField()
      .asTimeInput()
      .withTitle('Duration', '100px')
      .withIdentifier('shiftDurationInMin')
      .withValue(this.duration)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  private setBreakState(): void {
    this.break = {
      name: '',
      minStartTimeOfDay: '08:00',
      maxStartTimeOfDay: '21:00',
      maxDurationInMin: '01:00',
      presence: '',
    };
  }

  private async retrievePresences(): Promise<void> {
    this.presences = await this.presenceService
      .retrievePresences()
      .catch((e) => console.warn(' > Failed to retrieve presences due to', e));

    if (this.presences?.length > 0) {
      this.presences = ArrayTools.sort(this.presences, 'presenceCodeName');
    }

    this.generateBreaksForm();
    this.generateNonQueueWorkForm();
  }

  private generateBreaksForm(): void {
    this.breakForm = null;
    setTimeout(() => {
      new ZaiForm()
        .newField()
        .asDropdown()
        .withTitle('Presence', '138px')
        .withIdentifier('presence')
        .withInputWidth('138px')
        .withPlaceholder('Select a presence')
        .withDisplayField('presenceCodeName')
        .withOptions(this.presences)
        .withValue(this.break.presence)
        .insertField()

        .newField()
        .asTimeInput()
        .withTitle('Max Duration', '105px')
        .withIdentifier('maxDurationInMin')
        .withValue(this.break.maxDurationInMin)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .newRow()
        .newField()
        .asTextInput()
        .fullWidth()
        .withFocus()
        .withTitle('Custom Name', '138px')
        .withPlaceholder('Enter break name here.')
        .withIdentifier('name')
        .withValue(this.break.name)
        .withValidation([
          { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        ])
        .insertField()

        .newRow()
        .newField()
        .asTimeInput()
        .withTitle('Earliest Time', '135px')
        .withIdentifier('minStartTimeOfDay')
        .withValue(this.break.minStartTimeOfDay)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()
        .newField()
        .asTimeInput()
        .withTitle('Latest Time', '100px')
        .withIdentifier('maxStartTimeOfDay')
        .withValue(this.break.maxStartTimeOfDay)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .finaliseForm((form) => {
          this.breakForm = form;
          if (this.shift) {
            this.setFormDataValid();
          }
        });
    });
  }

  private setFormDataValid(): void {
    if (!this.formData) {
      setTimeout(() => {
        this.setFormDataValid();
      }, 50);
      return;
    }
    this.formData.valid = true;
  }

  private generateNonQueueWorkForm(): void {
    this.nonQueueWorkForm = null;
    setTimeout(() => {
      new ZaiForm()
        .newRow()
        .newField()
        .asDropdown()
        .withTitle('Presence', '138px')
        .withIdentifier('presence')
        .withInputWidth('138px')
        .withPlaceholder('Select a presence')
        .withDisplayField('presenceCodeName')
        .openToTop()
        .withOptions(this.presences)
        .withValue(this.nonQueueWork.presence)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .newField()
        .asTimeInput()
        .withTitle('Max Duration', '105px')
        .withIdentifier('maxDurationInMin')
        .withValue(this.nonQueueWork.maxDurationInMin)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .finaliseForm((form) => {
          this.nonQueueWorkForm = form;
        });
    });
  }

  private populateBreaksTable(): void {
    this.breaks.forEach((br) => {
      const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
        br.maxDurationInMin
      );
      this.breaksTable.push([
        br.name,
        br.minStartTimeOfDay + ` (${convertedTime})`,
      ]);
    });
  }

  private async retrieveWorkTypes(): Promise<void> {
    const workTypes = await this.workTypeService
      .retrieveWorkTypes()
      .catch((e) => console.warn(' > Failed to retrieve worktypes due to', e));

    this.setDisabledWorkTypes(workTypes);
    this.workTypes = ArrayTools.sort(workTypes, 'templateName');
  }

  private setDisabledWorkTypes(workTypes?: any[]): void {
    (workTypes || this.workTypes).forEach(workType => {
      workType.disabled = !!this.selectedWorkTypesMap[workType.templateId];
    })
  }

  private generateWorkTypesForm(): void {
    this.workTypesForm = null;
    setTimeout(() => {
      new ZaiForm()
        .newField()
        .asSearchDropdown()
        .fullWidth()
        .withTitle('Work Types', '138px')
        .withInputWidth('120px')
        .withClass('flex--basis')
        .withIdentifier('workTypes')
        .withDisplayField('templateName')
        .withOptions(this.workTypes)
        .withPlaceholder('Select a work type')
        .withValue('')
        .openToTop()
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .multiSelect()
        .withItemsIdentifier('selectedWorkTypes')
        .withValues(this.selectedWorkTypes)
        .insertField()

        .finaliseForm((form) => {
          this.workTypesForm = form;
        });
    });
  }
  
  public workTypeFormDataChanged(data: any): void {
    this.selectedWorkTypes = data.selectedWorkTypes;

    const selectedWorkTypesMap = {};
    this.selectedWorkTypes.forEach((selectedWorkType) => {
      const workType = this.workTypes.find((_workType) => {
        return _workType.templateName === selectedWorkType;
      });
      if (workType) {
        selectedWorkTypesMap[workType.templateId] = workType.templateName;
      }
    });
    this.selectedWorkTypesMap = selectedWorkTypesMap;
    this.setDisabledWorkTypes();
  }
  
  public breakFormDataChanged(data: any): void {
    if (data.presence && data.presence !== this.breakNameSet) {
      this.break.presence = data.presence;
      this.break.minStartTimeOfDay = data.minStartTimeOfDay;
      this.break.maxStartTimeOfDay = data.maxStartTimeOfDay;
      this.break.maxDurationInMin = data.maxDurationInMin;
      this.break.name = data.presence;
      this.breakNameSet = data.presence;

      if (!this.breakFormData) {
        this.breakFormData = {
          name: data.presence,
          presence: data.presence,
          minStartTimeOfDay: data.minStartTimeOfDay,
          maxStartTimeOfDay: data.maxStartTimeOfDay,
          maxDurationInMin: data.maxDurationInMin,
        };
      }
      this.breakFormData.valid = true;

      this.generateBreaksForm();
    }
  }

  public addBreak(): void {
    const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
      this.breakFormData.maxDurationInMin
    );
    this.breaksTable.push([
      this.breakFormData.name,
      this.breakFormData.minStartTimeOfDay + ` (${convertedTime})`,
    ]);
    this.editingBreakIndex = null;
    this.breaks.push(this.breakFormData);
    this.breakFormData.valid = false;
    this.setBreakState();
    this.generateBreaksForm();
  }

  public editBreak(breakData: any): void {
    this.break = this.breaks.find((br) => br.name === breakData[0]);
    this.editingBreakIndex = this.breaks.indexOf(this.break);
    this.editingBreak = JSON.parse(JSON.stringify(this.break));
    this.generateBreaksForm();
  }

  public updateBreak(): void {
    const breakTableDataToUpdate = this.breaksTable.find(
      (brt) => brt[0] === this.editingBreak.name
    );
    const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
      this.breakFormData.maxDurationInMin
    );
    const index = this.breaksTable.indexOf(breakTableDataToUpdate);
    this.breaksTable.splice(index, 1, [
      this.breakFormData.name,
      this.breakFormData.minStartTimeOfDay + ` (${convertedTime})`,
    ]);

    this.breaks[this.editingBreakIndex] = { ...this.breakFormData };

    this.editingBreak = null;
    this.editingBreakIndex = null;
    this.setBreakState();
    this.generateBreaksForm();
  }

  public breakRemoved(data: Event): void {
    this.breaks = this.breaks.filter((br) => br.name !== data[0]);
  }

  public cancelEditBreak(): void {
    this.editingBreak = null;
    this.editingBreakIndex = null;
    this.setBreakState();
    this.generateBreaksForm();
  }

  public addNonQueueWork(): void {
    const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
      this.nonQueueWorkFormData.maxDurationInMin
    );
    this.nonQueueWorkTable.push([
      this.nonQueueWorkFormData.presence,
      convertedTime,
    ]);
    this.nonQueueWorkList.push(this.nonQueueWorkFormData);
    this.setNonQueueWorkState();
    this.nonQueueWorkFormData.valid = false;
    this.generateNonQueueWorkForm();
  }

  public nonQueueWorkRemoved(data: Event): void {
    this.nonQueueWorkList = this.nonQueueWorkList.filter(
      (nqwl) => nqwl.presence !== data[0]
    );
  }

  private setNonQueueWorkState(): void {
    this.nonQueueWork = {
      maxDurationInMin: '01:00',
      presence: '',
    };
  }

  private async getWorkTypeIdsFromNames(
    selectedWorkTypes: any[]
  ): Promise<string[]> {
    const workTypeIds = selectedWorkTypes.map((selectedWorkType) => {
      const workType = this.workTypes.find((_workType) => {
        return _workType.templateName === selectedWorkType;
      });
      return workType ? workType.templateId : null;
    });
  
    return workTypeIds.filter((id) => id !== null);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public async confirm(): Promise<void> {
    this.error = '';
    if (this.formData.valid) {
      this.submitting = true;
      
      const taskTemplateIds = await this.getWorkTypeIdsFromNames(
        this.selectedWorkTypes
      );

      const payload = {
        name: this.formData.name,
        startTimeOfDay: this.formData.startTimeOfDay,
        shiftDurationInMin: moment
          .duration(this.formData.shiftDurationInMin)
          .asMinutes(),
        breaks: this.breaks.map((br) => {
          br.maxDurationInMin = moment
            .duration(br.maxDurationInMin)
            .asMinutes();
          delete br.valid;
          return br;
        }),
        offDutyWork: this.nonQueueWorkList.reduce((acc, item) => {
          acc[item.presence] = moment
            .duration(item.maxDurationInMin)
            .asMinutes();
          return acc;
        }, {}),
        taskTemplateIds
      };

      this.memberIds.forEach(member => {
        this.updateShiftTemplate(member, payload);
      });
    }
  }

  private updateShiftTemplate(
    member: {
      memberId: string,
      shiftIds: string[]
    },
    payload: {
      name: string;
      startTimeOfDay;
      shiftDurationInMin: number;
      breaks: any[];
      offDutyWork: { [key: string]: number };
      taskTemplateIds: string[]
    }
  ): void {
    this.inHouseWFMService
      .updateShift(
        member.memberId,
        member.shiftIds,
        payload.startTimeOfDay,
        payload.shiftDurationInMin,
        payload.breaks,
        payload.offDutyWork,
        payload.taskTemplateIds
      )
      .then(() => {
        this.dialogController.ok({
          id: this.shift.id,
          name: payload.name,
          startTimeOfDay: payload.startTimeOfDay,
          shiftDurationInMin: payload.shiftDurationInMin,
          breaks: payload.breaks,
          offDutyWork: payload.offDutyWork,
        });
      })
      .catch((e) => {
        console.warn('Failed to update Agent Sampling Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }
}
