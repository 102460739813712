import { bindable, customElement, LogManager } from 'aurelia-framework';
import {SegmentModel} from '../../../../features/interaction/dashboard/live-dashboard/components/segment-model';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const logger = LogManager.getLogger('ZDonut');
const donutEndPoint: number = 100;
const donutCircumference: number = 100;

@customElement('z-donut')
export class ZDonut {

  @bindable({attribute: 'center-label'}) private centerLabel: string = null;
  @bindable({attribute: 'donut-segments'}) private donutSegments: SegmentModel[] = [];
  private segmentData: Array<IDonutSegment> = [];
  private segmentsTotal: number = 0;
  private tooltip: HTMLElement;
  private tooltipData: any = {label: '', value: 0};
  private donutId: string = '';
  private defaultTooltip: boolean = true;

  private bind(): void {
    this.setupDonut(this.donutSegments);
    this.donutId = uuidv4();
  }

  private donutSegmentsChanged(value: any): void {
    this.setupDonut(value);
  }

  private setupDonut(donutSegments: SegmentModel[]): void {
    if(!donutSegments) {
      return;
    }
    let tempSegmentData: IDonutSegment[] = [];
    let tempSegmentsTotalLength: number = donutSegments.reduce((total, segment) => {return total += segment.segmentLength},0);

    let runningOffset = 0;
    donutSegments.forEach((segment) => {
      let segmentLengthPercentage = segment.segmentLength / tempSegmentsTotalLength * donutCircumference;
      runningOffset = runningOffset + segmentLengthPercentage;

      let _segment = {
        segmentColor: segment.segmentColor,
        segmentLength: segmentLengthPercentage,
        segmentLengthRemainder: donutCircumference - segmentLengthPercentage,
        segmentStart: runningOffset
      };
      tempSegmentData.push(_segment);
    });

    this.segmentData = tempSegmentData;
    this.segmentsTotal = tempSegmentsTotalLength;
  }

  private displayTooltip(event: any, index: number): void {
    if(!this.donutSegments && !this.donutSegments[index].segmentTooltip) {
      return;
    }
    if(!this.donutSegments[index].segmentTooltip) {
      this.tooltipData = {
        label: this.donutSegments[index].label,
        value: this.donutSegments[index].segmentLength,
        color: this.donutSegments[index].segmentColor
      };
    } else {
      this.defaultTooltip = false;
      this.tooltipData = this.donutSegments[index].segmentTooltip;
    }
    let tooltip: HTMLElement = document.querySelector(`#js-${this.donutId} .donut-tooltip`);
    this.tooltip = tooltip;

    this.tooltip.classList.add('active');
    this.tooltip.setAttribute("style", `left: ${event.layerX + 14}px; top: ${event.layerY + 14}px; `);
  }

  private removeTooltip(): void {
    if(!this.tooltip) {
      return;
    }
    this.tooltip.classList.remove('active');
    this.tooltipData = null;
    this.defaultTooltip = true;
  }

  private get currentTimeStamp(): moment {
    return moment();
  }
}

