import { OplogManager } from './oplog-manager';
import { ContactCardTools } from './contact-card-tools';
import { ContactCardEntryPoint } from './contact-card-entries';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, customElement, LogManager } from 'aurelia-framework';

import { SessionStore } from 'zailab.common';
import { ConversationService } from '../../conversation/conversation-service';
import { ContactModel } from '../contact-model';
import { ContactFactory } from '../contact-factory';
import { ContactService } from '../contact-service';
import { ConversationFactory } from '../../conversation/conversation-factory';
import { ContactControllerSubscriptions } from './contact-controller-subscriptions';
import { ContactState } from './contact-state';
import { ChannelService } from '../../conversation/channel/channel-service';
import { WrapUp } from '../../interactions/wrapup/wrap-up';
import { ConnectedInteractions } from '../../interactions/connectedinteractions/connected-interactions';
import { ReminderService } from '../../conversation/reminder/reminder-service';
import { InteractionService } from "../../interactions/interaction-service";
import { TelephonyService } from '../../../telephony/telephony-service';
import { InteractionModel } from "../../conversation/interaction-model";

// @ts-ignore
import moment from 'moment-timezone';
import { DispositionCodesService } from '../../organisation/dispositioncodes/disposition-codes-service';
import { TicketModel } from '../../conversation/interaction/ticket-model';
import { Router } from 'aurelia-router';
import { ContactCardFlowService } from './contact-card-flow';
import { ContactCardEventing } from './contact-card-eventing';

const logger = LogManager.getLogger('ContactController');

@customElement('z-contact-controller')
@autoinject
export class ContactController {
  private contactState: ContactState;
  private eaSubscriptions: { [k: string]: Subscription } = {};
  private dialingDebounced: boolean = false;

  constructor(
    private router: Router,
    private contactFactory: ContactFactory,
    private conversationFactory: ConversationFactory,
    private contactService: ContactService,
    private conversationService: ConversationService,
    private eventAggregator: EventAggregator,
    private channelService: ChannelService,
    private sessionStore: SessionStore,
    private reminderService: ReminderService,
    private contactControllerSubscriptions: ContactControllerSubscriptions,
    private wrapup: WrapUp,
    private connectedInteractions: ConnectedInteractions,
    private interactionService: InteractionService,
    private telephonyService: TelephonyService,
    private dispositionCodesService: DispositionCodesService,
    private contactCardEntryPoint: ContactCardEntryPoint,
    private contactCardFlowService: ContactCardFlowService,
    private contactCardEventing: ContactCardEventing,
    private contactCardTools: ContactCardTools,
    private oplogManager: OplogManager
  ) {
    this.contactState = new ContactState(this.contactFactory, this.conversationService, this.contactService, this.eventAggregator, this.sessionStore, this.interactionService, this.telephonyService, this.oplogManager);
  }

  public attached(): void {
    this.subscribeToContactCardEntryEvents();
    this.contactCardEventing
      .init(
        this.contactState,
        this.eventAggregator,
        this.conversationService,
        this.sessionStore,
        this.dispositionCodesService,
        this.contactService,
        this.channelService,
        this.reminderService,
        this.conversationFactory,
        this.contactCardTools,
        this.oplogManager
      );
    this.wrapup.initialise();
    this.connectedInteractions.initialise();

    this.subscribeToInternalEvents();
  }

  public navigateToLiveInteraction(): void {
    this.router.navigate('liveinteraction');
  }

  private subscribeToContactCardEntryEvents(): void {

    this.contactCardEntryPoint
      .interactionConnected(
        async (interaction: InteractionModel) => {
          logger.info(' CC | Open CC for connected interaction ');
          this.handleConnectedInteraction(interaction);
          
          if (interaction.screenPopUrl) {
            if (interaction.useEmbeddedIFrame) {
              this.eventAggregator.publish('interaction-link-open', {
                firstName: interaction.firstName,
                surname: interaction.surname,
                remote: interaction.direction === 'OUTBOUND' ? interaction.to : interaction.from,
                url: interaction.screenPopUrl,
                interactionId: interaction.interactionId
              });
            } else {
              window.open(interaction.screenPopUrl, '_blank');
            }
          }

          this.contactCardTools.retrieveConversationsFlows();
          this.contactCardTools.retrieveReasonCodes();
        }
      );
    this.contactCardEntryPoint
      .selectedFromRecentActivity(
        (interaction: InteractionModel) => {
          logger.info(' CC | Open CC from recent activity ');
          this.polulateContactCard(interaction);
        }
      );
    this.contactCardEntryPoint
      .resumeLinkingFromRecentActivity(
        (interaction: InteractionModel) => {
          logger.info(' CC | Open CC and linking flow from recent activity ');
          this.polulateContactCard(interaction);
        }
      );
    this.contactCardEntryPoint
      .selectedFromRecentConversations(
        (interaction: InteractionModel) => {
          logger.info(' CC | Open CC from recent conversations ');
          this.polulateContactCard(interaction);
        }
      );

    this.contactCardTools
      .defineContactState(this.contactState);

    this.contactCardTools
      .subscribeToInteractionLinkedToConversation(
        (data: { interactionId: string; contactId: string; }) =>
          this.contactCardTools.handleInteractionLinkedToConversation(
            data,
            (unlinkedInteraction: any) => this.polulateContactCard(unlinkedInteraction)
          )
      );

    this.contactControllerSubscriptions.addSessionToInteraction =
      this.eventAggregator.subscribe(
        'ADD:SESSIONID:TO:INTERACTION',
        (data: { contactId: string, correlationId: string, interactionId: string, sessionId: string }) => {
          let card = this.contactState.getContactCard(data.contactId, data.correlationId);
          if (card) {
            let interactionToUpdate: any = card.servedInteractions.find(interaction => interaction.interactionId === data.interactionId);

            if (interactionToUpdate) {
              interactionToUpdate.sessionId = data.sessionId;
            }
          }
        }
      );
  }

  private handleConnectedInteraction(interaction: InteractionModel): void {
    if (interaction.metaData && (interaction.metaData.diallerType === 'Progressive' || interaction.metaData.diallerType === 'Preview' || interaction.metaData.diallerType === 'Power' || interaction.metaData['isDiallerCall'])) {
      this.contactState.forceCloseAllContacts();
      this.navigateToLiveInteraction();
      return;
    }
    if (interaction.isCallChannel && interaction.isCallChannel()) {
      logger.debug(this.getCurrentFormattedTime() + ' - CC | handleConnectedInteraction | setting on call ');
    }
    if (interaction.channel === 'campaign' || interaction.channel === 'callback' || interaction.channel === 'Ticket') {
      // query against task view
      this.polulateContactCard(interaction);
      return;
    }
    this.contactCardTools
      .determineIfInteractionCreated(
        interaction,
        (data: { interaction: InteractionModel | TicketModel, rawData: any }) =>
          this.polulateContactCard(data.interaction, data.rawData)
      );
  }

  private async polulateContactCard(interaction: InteractionModel | TicketModel, rawData?: any): Promise<void> {
    if (rawData) {
      if (interaction instanceof InteractionModel) {
        if (!interaction.isDiallerCall) {
          interaction.mapInteraction(rawData);
        } else {
          //Need to only map certain properties for dialler tasks
          interaction.channel = rawData.channel;
          interaction.direction = rawData.direction;
          interaction.flowName = rawData.flowName;
          interaction.from = rawData.from;
          interaction.to = rawData.to;
          rawData = null;
        }
      } else if (interaction instanceof TicketModel) {
        interaction.mapInteraction(rawData);
      }
    }
    
    this.contactCardFlowService
      .setContactState(this.contactState)
      .startContactCardFlow(interaction);
  }

  public addContactCard(): void {
    this.contactState.addContactCard();
  }

  public removeContactCard(index: number, event: MouseEvent): void {
    event.stopPropagation();
    this.contactState.removeContactByIndex(index);
    try {
      logger.info(' CC | remove contact card ', index, ' from ', this.contactState.contactTabs.length);
    } catch(e) {
      logger.info(' CC | remove contact card ', index);
    }
  }

  private subscribeToInternalEvents(): void {
    this.contactControllerSubscriptions.interactionLiveStateEnded =
      this.eventAggregator.subscribe('cc-interaction-ended', (data: { interactionId: string, contactId: string, correlationId: string }) => {
        let contactCard = this.contactState.getContactCard(data.contactId, data.correlationId);
        if (contactCard && data.interactionId === contactCard.longestActiveInteraction) {
          contactCard.longestActiveInteractionColor = null;
          contactCard.longestActiveInteractionTime = null;
          contactCard.longestActiveInteraction = null;
          
          let contactCardHandle = document.getElementById(`contact-card-${data.correlationId}`);
          if (contactCardHandle) {
            contactCardHandle.style.setProperty(`--border`, 'unset');
            contactCardHandle.style.setProperty(`--border-right`, 'unset');
          }
        }
      });
    this.contactControllerSubscriptions.dockCards = this.eventAggregator.subscribe('dock-cards', () => this.dockCards())
  }

  public colorChanged(data: { contactId: string, timeInSeconds: number, color: string, interactionId: string, correlationId: string }): void {
    if (!data.color) {
      data.color = 'unset';
    }
    let contactCard = this.contactState.getContactCard(data.contactId, data.correlationId);

    if (
      contactCard.longestActiveInteractionTime &&
      contactCard.longestActiveInteractionTime > data.timeInSeconds
    ) {
      return;
    }

    contactCard.longestActiveInteractionColor = data.color;
    contactCard.longestActiveInteractionTime = data.timeInSeconds;
    contactCard.longestActiveInteraction = data.interactionId;

    let contactCardHandle = document.getElementById(`contact-card-${data.correlationId}`);

    contactCardHandle.style.setProperty(`--border`, '10px solid ' + data.color);
    contactCardHandle.style.setProperty(`--border-right`, '7px solid ' + data.color);
  }

  public dockCards(): void {
    this.contactState.toggleCard();
  }

  public selectContactCard(contactCard: ContactModel): void {
    if (contactCard.isSelected) {
      this.eventAggregator.publish('change.channel.size.reset', contactCard.contactId);
      return;
    }
    this.contactState.selectContactCard(contactCard);
    contactCard.hasChange = false;
  }

  public unbind(): void {
    this.oplogManager.discardAllSubscriptions();
    this.contactCardFlowService = new ContactCardFlowService();

    this.contactControllerSubscriptions.disposeOfSubscriptions();
    for (let key in this.eaSubscriptions) {
      this.eaSubscriptions[key] && this.eaSubscriptions[key].dispose && this.eaSubscriptions[key].dispose();
    }
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }
}
