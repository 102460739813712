import { LogManager, autoinject } from 'aurelia-framework';
import { List } from 'zailab.abstract';
import { SearchTools } from 'zailab.common';
import { EventAggregator } from 'aurelia-event-aggregator'
import { PlaceholderService } from "../_common/services/placeholder-service";
import { ZIOplog } from "../../typings/zai/zai.common";

const logger = LogManager.getLogger('AbstractListView');

@autoinject()
export class AbstractList {

  protected oplog: ZIOplog;
  protected itemList: List;
  protected placeholderService: PlaceholderService;
  protected placeholderRows: number = 2;
  protected element: Element;
  protected container: HTMLElement;
  protected retryTimeout: number;
  protected isDeleting: boolean = false;
  protected isSearching: boolean = false;
  protected isAdding: boolean = false;
  protected listToDelete: any[] = [];
  protected searchTerm: string;
  protected deferredMessage: string = '';
  protected ready: boolean = false;
  protected totalDeletedItems: number = 0;
  protected currentDeleteCount: number = 0;
  protected placeholders: number = 0;
  protected expectedItemCount: number = 0;

  constructor(protected eventAggregator: EventAggregator) {

  }

  protected activate(params?: any): void {
    if (params && params.add) {
      this.isAdding = params.add === 'true' || false;
    }
    this.subscribeToOplog();
  }

  protected attached(): void {
    this.retrieveList();
  }

  protected detached(): void {
    this.oplog && this.oplog.unsubscribe();
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
    this.hideLoader();
  }

  protected deactivate(): void {

  }

  protected subscribeToOplog(): void {
    // Will override
  }

  protected retrieveList(): void {
    this.showLoader();
  }

  protected hideLoader(_message?: string): void {
    if (_message) {
      this.eventAggregator && this.eventAggregator.publish('app:loader:hide', {message: _message});
    } else {
      this.eventAggregator && this.eventAggregator.publish('app:loader:hide');
    }
  }

  protected showLoader(): void {
    this.eventAggregator && this.eventAggregator.publish('app:loader:show');
  }

  protected selectItem(_items: any): void {
    if (this.itemList && this.itemList.isDeleting) {
      this._toggleItemDeletionState(_items.item);
    } else {
      this._toggleItemSelectionState(_items.item);
    }
  }

  protected toggleDelete(): void {
    this.itemList.isDeleting = !this.itemList.isDeleting;
    let _list: any[] = this.itemList.items ? this.itemList.items : this.itemList;
    _list.forEach(item => item.isDeleting = false);
  }

  protected clearUrlParams(): void {
    let loc = location.href.replace('?add=true', '');
    window.history.pushState({}, '', loc);
  }

  protected showAddItem(_params?: any): void {
    this.showLoader();
  }

  protected setupListConfig(_items: any[]): void {
    this.hideLoader();
    this.createMetaData(_items);
    this.expectedItemCount = _items.length;
  }

  protected createMetaData(_items: any[]): void {
    // Will override
  }

  protected deselect(item: any): void {
    // Will override
  }

  protected select(item: any): void {
    // Will override
  }

  protected toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  protected deleteItems(_items: any[]): void {
    this.showLoader();
    this.totalDeletedItems = _items.length;
    this.currentDeleteCount = 0;
    _items.forEach(item => {
      this.delete(item);
    });
  }

  protected delete(item: any): void {
    this.currentDeleteCount++;
    if (this.totalDeletedItems === this.currentDeleteCount) {
      this.hideLoader(this.deferredMessage);
      this.toggleDelete();
    }
  }

  protected createEnrichedList(_list: any[], _key: string, _maximum: number, _description: string, _random: boolean): string {
    if (_list && _list.length > 0) {
      const _maximumCount = _maximum;
      const _currentCount = _list.length;
      const _overflowCount = _currentCount - _maximumCount;
      let _returnText = `<b>${_currentCount} ${_description}${_currentCount > 1 ? 's' : ''}:</b>`;
      if (_random) {
        _list.sort(() => Math.random() - 0.5);
      }
      if (_overflowCount < 0) {
        _list.forEach(_item => {
          _returnText += `<br>${_item[_key]}`
        })
      } else {
        for (let _x = 0; _x < _maximumCount; _x++) {
          _returnText += `<br>${_list[_x][_key]}`
        }
        _returnText += `<br><b>... and ${_overflowCount} more</b>`
      }
      return _returnText;
    } else {
      return `No ${_description}s assigned`
    }
  }

  protected startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (value[searchParam] === null) {
      value[searchParam] = '-';
    }
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  // Private methods

  private _toggleItemDeletionState(item: any): void {
    if (item.isSelected || item.canDelete === false) {
      return;
    }
    if (item.isDeleting) {
      let index = this.listToDelete.indexOf(item);
      this.listToDelete.splice(index);
      this._markItemForDeletion(item, false);
    } else {
      this.listToDelete.push(item);
      this._markItemForDeletion(item, true);
    }
  }

  private _toggleItemSelectionState(item: any): void {
    if (item.isSelected) {
      this.deselect(item);
    } else {
      this.select(item);
    }
  }

  private _markItemForDeletion(itemToMark: any, isDeleting: boolean): void {
    this.itemList.items.forEach(item => {
      if (itemToMark === item) {
        item.isDeleting = isDeleting;
      }
    });
  }
}
