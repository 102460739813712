import {LogManager} from 'aurelia-framework';
/**/
import {GridCellModel} from '../../grid/models/grid-cell-model';
import {ConnectorModel} from '../../connector/models/connector-model';
import {InteractionConfig} from '../../../../features/interaction/interactionFlow/interaction-designer/interaction-config-strategy';
/**/
const logger = LogManager.getLogger('NodeModel');
/**/
export class NodeModel extends GridCellModel {

  public parentConnector: ConnectorModel;
  public connections: Array<ConnectorModel> = [];
  public properties: any = null;
  public eventId: string = null;
  public config: InteractionConfig = null;
  public name: string = null;
  public type: string = null;
  public inputConnectors: object[] = [];
  public outputConnectors: object[] = [];
  public totalIncomingConnectors: number = 0;

  constructor(node: any) {

    super(node);
    super.mapProperties(node);

    this.mapProperties(node);
  }

  public mapProperties(node: any): void {

    if(!node.config) {
      return;
    }

    let properties: any = node.properties || {};

    this.cellTemplate = 'components/organisms/node/z-node';
    this.config = node.config;
    this.name = properties.name || this.config ? this.config.name : '';
    this.inputConnectors = this.config ? this.config.inputConnectors : [];
    this.outputConnectors = node.outputConnectors || this.config.outputConnectors;
    this.rowsToMerge = this.outputConnectors ? this.outputConnectors.length : 0;
  }

}
