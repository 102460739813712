import { autoinject } from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

@autoinject
export class WorkTypeCreatorWrapper {

  private nodeConfiguration:ZNodeConfig;

  constructor(private dialogController:DialogController) {}

  public activate(_nodeConfig:ZNodeConfig):void {
    this.nodeConfiguration = _nodeConfig;
  }

  private cancelContentCreator():void {
    this.dialogController.cancel();
  }

  private handleContentCreated():void {
    this.dialogController.ok();
  }
}