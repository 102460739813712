import {autoinject} from "aurelia-dependency-injection";
import {AnalyticsModel} from "./analytics-model";
import {DialogController} from "aurelia-dialog";
import {AnalyticsModelResults} from "./analytics-model-results";
import {GroupByModel} from "../../../../../components/organisms/charts/models/group-by-model";
import {ChartSettingsBuilder} from "../../../../../components/organisms/charts/chart-settings-builder";
import {DataPointModel} from "../../../../../components/organisms/charts/models/data-point-model";
import {TotalCountsModel} from "../../../../../components/organisms/charts/models/total-counts-model";
import {GRAPH_COLOR_NAMES, GRAPH_COLORS} from "../../../../../components/organisms/charts/graph-colors";
import {GraphConfig} from "../../../../../components/organisms/charts/graph-config";
import {INTERACTION_TYPES} from "../../../dashboard/live-dashboard/interaction-types";

declare let AmCharts: any;

@autoinject
export class ViewInteractionPhraseAnalyticsDialog{
  dialogController: DialogController;
  protected chart: any = null;
  analytics: AnalyticsModel[];
  phrase: string;
  ready: boolean = false;
  dialogHeader: string = 'Phrase Analytics: ';
  protected formattedInteractionsData: Array<TotalCountsModel> = [];

  groupByOption: GroupByModel = new GroupByModel('Day', true, false, 120, 7);

  constructor(dialogController: DialogController) {
    this.dialogController = dialogController;
  }


  public activate(analytics: AnalyticsModelResults): void {
    this.analytics = analytics.results;
    this.phrase = analytics.phrase;
    this.dialogHeader += analytics.phrase;
    setTimeout(() => this.addChart(), 500);

  }

  public cancel(): void {
    this.analytics = null;
    this.phrase = null;
    this.dialogController.cancel();
  }

  private addChart(): void {
    let chartId = 'js-phrase-occurrences';

    let graphs = [];

    let formattedData = [];
    this.analytics.forEach(analytic => {
      formattedData.push({
        date: analytic.date,
        occurrences: analytic.occurrences
      });
    });
    formattedData.sort((d1,d2) => {
      if (Date.parse(d1.date) < Date.parse(d2.date)) {return -1;}
      if (Date.parse(d1.date) > Date.parse(d2.date)) {return 1;}

      return 0;
    });

    graphs.push({
      fillColors: ['var(--theme-text-primary-accent)'],
      color: 'var(--theme-text-primary-accent)',
      lineColor: 'var(--primary-accent)',
      title: 'date',
      type: 'column',
      valueField: 'occurrences',
      balloonText: '[[category]]: <b>[[value]] occurrences</b>',
      fillAlphas: 0.8,
      lineAlpha: 0.2,
    });

    this.chart = AmCharts.makeChart(chartId, {
      type: 'serial',
      theme: 'none',
      dataProvider: formattedData,
      valueAxes: [ {
        color: 'var(--theme-text-primary-accent)',
        axisColor: 'var(--theme-background-default)',
        gridAlpha: 0.2,
        dashLength: 0,
        integersOnly: true
      } ],
      gridAboveGraphs: true,
      startDuration: 1,
      graphs: graphs,
      chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false
      },
      categoryField: 'date',
      categoryAxis: {
        color: 'var(--theme-text-primary-accent)',
        axisColor: 'var(--theme-background-default)',
        gridPosition: 'start',
        gridAlpha: 0,
        tickPosition: 'start',
        tickLength: 20,
      },
      export: {
        enabled: true
      }

    } );
    this.ready = true;
    this.chart.validateData();
  }
}
