/*
 */
import {LogManager, customElement, bindable, inject} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZUtilityButtonBulkEdit');
/*
 */
@inject(Element)
@customElement('z-utility-button-bulk-edit')
export class ZUtilityButtonBulkEdit {

  @bindable isbulkediting;

  constructor(element) {
    this.element = element;
  }

  toggle(){
    this.dispatch('toggle', '');
  }

  accept() {
    this.dispatch('accept', '');
  }

  dispatch(name, data) {
    this.element.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail: data
      })
    );
  }

}
