import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 */
import { BootstrapFormRenderer } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('AddIMDialog');
/*
 */
@inject(DialogController, ValidationControllerFactory)
export default class {

  endpoint = null;
  apiKey = null;
  channels = ['WhatsApp'];
  whatsappSelected = true;

  constructor(dialogController, validationControllerFactory) {
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(itemList) {
    ValidationRules
      .customRule('uniqueEndpoint', (name) => {
        if(itemList) {
          for (let item of itemList) {
            if (item.endpoint.toLowerCase() === this.endpoint.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      });
    ValidationRules
      .ensure('endpoint')
      .required().withMessage('Please enter an endpoint.')
      .satisfiesRule('uniqueEndpoint').withMessage('Please enter a unique endpoint.')
      .ensure('apiKey')
      .required().withMessage('Please enter an API key.')
      .on(this);
  }


  cancel() {
    this.dialogController.cancel();
  }

  add() {
    this.validation.validate().then((validation) => {

      if (!validation.valid) {
        return;
      }
      let endpoint = this.endpoint;
      let apiKey = this.apiKey;
      let channels = this.channels;
      this.dialogController.ok({endpoint, apiKey, channels});
    }, error => {
      logger.info('Validation for creating Im config endpoint unsuccessful.', error);
    });
  }

  toggleWhatsApp(){
    if(this.whatsappSelected){
      this.channels.push('WhatsApp');
    } else {
      this.channels.splice(this.channels.indexOf('WhatsApp'), 1);
    }
  }
}

