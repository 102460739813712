import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('EmergencyAnnouncementWidget');

@autoinject
export class EmergencyAnnouncementWidget {

  private actions = ['view'];

  constructor(private router: Router) { }

  private view(): void {
    this.router.navigate('emergencyannouncement');
  }
}
