/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPageHeader');
/*
 */
@customElement('z-page-header')
export class ZPageHeader {

  @bindable header;
  @bindable isdashboard;
  @bindable nodescription;
  @bindable dropdownheader;
}
