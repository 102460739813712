export class ContactFieldModel {

  public id: string;
  public name: string;
  public type: string = null;
  public dropdownChoices: string[] = [];
  public deleted: boolean;

  constructor(contactField: any) {
    this.id = contactField.customFieldId;
    this.name = contactField.name;
    this.type = contactField.type;
    this.dropdownChoices = contactField.dropdownChoices;
    this.deleted = contactField.deleted;
  }
}
