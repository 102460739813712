import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('LinkAudioGroupDialog');

@autoinject
export class LinkAudioGroupDialog {

  public audioGroupList = [];
  public audioGroupId = '';
  public dialogHeader = "Assign Audio Group";

  constructor(private dialogController: DialogController) {
  }

  activate(data: any): void {
    this.audioGroupId = data.audioGroupId;
    this.audioGroupList = data.audioGroupList;
  }

  cancel(): void {
    this.dialogController.cancel();
  }

  linkAudioGroup(): void {
    this.dialogController.ok(this.audioGroupId);
  }
}

