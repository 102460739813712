import { DialogService } from 'aurelia-dialog';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore, AudioService, AUDIO_ASSETS } from 'zailab.common';
import { RoutingStatusActivityDialog } from './routing-status-activity-dialog';
import { RoutingStatusService } from './routing-status-service';
import {ZIOplog} from '../../../../../typings/zai/zai.common';
import {UserSessionModel} from '../../../../_common/stores/sessionmodels/user-session-model';
import {visibilityAware, ZIVisibilityAware} from '../../../../_common/services/visibilityawareness/visibility-aware';
import {INTERACTION_ACTIONS} from "../../conversation/interaction/interaction-actions";

const logger = LogManager.getLogger('RoutingStatus');

@autoinject()
@visibilityAware
export class RoutingStatusActivity implements ZIVisibilityAware{
  private wrapUp: boolean = false;
  private notResponding: boolean = false;
  private contactCardOpen: boolean = false;
  private connected: boolean = false;
  private allSubscriptions: Subscription[] = [];
  private oplog: ZIOplog;

  constructor(private routingStatusService: RoutingStatusService, private sessionStore: SessionStore, private eventAggregator: EventAggregator, private router: Router, private dialogService: DialogService, private audioService: AudioService) {
  }

  public initialise(): void {
    this.registerToEvents();
    this.retrieveAgentActivity();
  }

  private retrieveAgentActivity(): void {
    let memberId = this.user.memberId;
    this.routingStatusService.retrieveMemberStatusActivity(memberId).then(
      response => {
        this.wrapUp = response.wrapUp;
        this.notResponding = response.notResponding;
        this.processStatusActivity();
      },
      error => {
        logger.info('retrieveMemberStatusActivity > error = ', error);
      }
    );
  }

  private registerToEvents(): void {
    let memberId = this.user.memberId;
    this.routingStatusService.subscribeToMemberActivityChange(memberId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('update', response => {
        this.wrapUp = response.wrapUp;
        this.notResponding = response.notResponding;
        this.processStatusActivity();
        this.eventAggregator.publish('wrap.up', this.wrapUp);
      });
    });

    this.allSubscriptions.push(this.eventAggregator.subscribe(INTERACTION_ACTIONS.MEMBER_CONNECTED_STATE, connected => {
      this.connected = connected;
    }));

    this.allSubscriptions.push(this.eventAggregator.subscribe('check.agent.activity', () => {
      this.processStatusActivity();
    }));
  }

  private processStatusActivity(): void {
    if (this.checkIfOnWrapUp()) {
      return;
    }
    if (this.notResponding) {
      this.displayAgentActivityDialog();
    } else {
      this.closeRoutingStatusActivityDialog();
    }
  }

  private closeRoutingStatusActivityDialog(): void {
    this.dialogService.controllers.forEach(controller => {
      // @ts-ignore
      if (controller.controller.viewModel instanceof RoutingStatusActivityDialog) {
        controller.cancel({ rejected: false });
      }
    });
  }

  private checkIfOnWrapUp(): boolean {
    return this.wrapUp;
  }

  private displayAgentActivityDialog(): void {
    this.dialogService.controllers.forEach(controller => controller.cancel({ rejected: false }));
    this.dialogService
      .open({
        viewModel: RoutingStatusActivityDialog,
        model: this.user.memberId
      })
      .whenClosed(
        response => { },
        error => {
          logger.info('Opening Dialog > Error =', error);
        }
      );
  }

  @computedFrom('sessionStore.get.user')
  get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  public detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    this.allSubscriptions.forEach(_subscription => {
      try {
        _subscription.dispose();
      } catch(e) {
        // Could not remove subscription
      }
    })
  }

  becameInvisible(): void {
  }

  becameVisible(): void {
    this.retrieveAgentActivity();
  }
}
