import {inject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/*
 */
import {UsageModel} from './usage.model';
/*
 */
import FileSaver from 'file-saver';
/*
 */
const logger = LogManager.getLogger('UsageService');

/*
 */
@inject(HttpClient)
export class UsageService {

  constructor(httpClient) {

    this.httpClient = httpClient;
  }

  retrieveUsage(fromDate, toDate, pageIndex, resultAmount,currencySymbol) {
    return new Promise((resolve, reject) => {

      let params = {startDate: fromDate, endDate: toDate, page: pageIndex, size: resultAmount};
      this._createRequest('/v1/finance/accounts/me/charges', params, (response) => {
        if (response && response.charges) {

          resolve(this._formatUsageData(response.charges, currencySymbol));
        } else {
          resolve(this._formatUsageData([]));
        }
      }, (error) => reject(error));
    });
  }

  _createRequest(uri, params, callback, errorCallback) {
    this.httpClient.createRequest(uri)
      .asGet()
      .withParams(params)
      .send().then(
      (response) => {
        callback(response);
      },
      (error) => {
        errorCallback(error);
      });
  }

  _formatUsageData(usageResponse, currencySymbol) {
    let usageList = [];
    let currentItemId = null;
    let isColoured = false;

    usageResponse.forEach(usageItem => {
      usageItem.components.forEach((component, index) => {
        let item = new UsageModel(usageItem, index, currencySymbol);
        if (currentItemId && currentItemId === item.itemId) {
          item.isColoured = isColoured;
        } else {
          currentItemId = item.itemId;
          isColoured = !isColoured;
          item.isColoured = isColoured;
        }
        usageList.push(item);

      });

    });
    return usageList;
  }

  downloadUsageReport(toDate, fromDate) {

    return new Promise((resolve, reject) => {
      let params = {startDate: fromDate, endDate: toDate};
      let uri = '/v1/finance/accounts/me/charges';
      this.httpClient.createRequest(uri)
        .asGet()
        .withParams(params)
        .withResponseType('blob')
        .withHeader('Accept', 'application/zip')
        .send().then(
        (response) => {
          FileSaver.saveAs(response.content, 'Itemised Usage.zip');
          resolve(true);
        },
        (error) => {
          reject(error);
        });
    });
  }
}
