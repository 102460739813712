import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('ActivityModel');

export class ActivityModel extends BaseModel {

  memberId = null;
  activity = null;

  constructor(activity) {
    super();
    this.mapProperties(activity);
  }

}
