/*
*/
import {inject, LogManager} from 'aurelia-framework';
import {handle, waitFor} from 'aurelia-flux';
import {Router} from 'aurelia-router';
/*
*/
import {AssessmentStore} from '../assessment.store';
import {SELECT_FORM_ACTIONS} from './selectform.actions';
import {SelectFormService} from './selectform.service';
import {SelectFormStore} from './selectform.store';
/*
*/
const logger = LogManager.getLogger('SelectForm');

/*
*/
@inject(Router, SelectFormService, SelectFormStore, AssessmentStore)
// TODO: CLEANUP(FLUX): JE 28/07/2016 - The view model currently stores state and should be moved to the data store.
export class SelectForm {

  constructor(router, selectFormService, selectFormStore, assessmentStore) {

    this.router = router;
    this.selectFormService = selectFormService;
    this.selectFormStore = selectFormStore;
    this.assessmentStore = assessmentStore;
  }

  resolve;
  _pagesize = 12;
  currentPage;
  pages;
  hasSearched;
  searchText;
  allItems;
  showSearch;

  canActivate(params) {
    this.firstName = params.firstName;
    this.surname = params.surname;
    this.memberId = params.memberId;
    this.channel = params.channel;

    this.selectFormService.retrieveForms();

    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  setFocus(field) {

    let firstElement = document.getElementById(field);
    if (firstElement !== null) {
      firstElement.focus();
    } else {

      setTimeout(() => {

        this.setFocus(field);
      }, 100);
    }
  }

  get pagedItems() {

    if (!this.pages) {
      return;
    }

    return this.pages[this.currentPage];
  }

  next() {

    this.currentPage++;
  }

  prev() {

    this.currentPage--;
  }

  get totalItems() {

    return this.allItems.length;
  }

  get showNext() {

    if (this.pages)
      return this.currentPage < this.pages.length - 1;
  }

  get showPrev() {

    return this.currentPage > 0;
  }

  initPages() {

    this.currentPage = 0;
    this.pages = [];

    if (!this.allItems) {
      this.pages.push([]);
      return;
    }

    let pageItems = null;

    for (let itemIndex = 0; itemIndex < this.allItems.length; itemIndex++) {
      if (itemIndex % this._pagesize === 0) {

        if (pageItems !== null) {
          this.pages.push(pageItems);
        }
        pageItems = [];
      }

      pageItems.push(this.allItems[itemIndex]);
    }

    if (pageItems !== null && pageItems.length > 0) {
      this.pages.push(pageItems);
    }
  }

  search() {

    if (!this.allItems || this.allItems.length === 0) {

      return;
    }

    if (!this.searchText || this.searchText.length === 0) {

      this.initPages();
      return;
    }

    let items = [];
    let startList = [];
    this.pages = [];

    for (let item of this.allItems) {

      if (this.startsWith(item, this.searchText)) {

        startList.push(item);
        item.added = true;
      } else {

        item.added = false;
      }
    }

    this.items = startList;

    let pageItems = null;

    for (let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {

      if (itemIndex % this._pagesize === 0) {

        if (pageItems !== null) {
          this.pages.push(pageItems);
        }
        pageItems = [];
      }

      pageItems.push(this.items[itemIndex]);
    }

    if (pageItems !== null && pageItems.length > 0) {
      this.pages.push(pageItems);
    }

  }

  startsWith(item, searchText) {

    let found = false;

    if (item['name'] && item['name'].toLowerCase().startsWith(searchText.toLowerCase())) {

      found = true;
    }

    return found;
  }

  get enableSearch() {

    let pages = this.allItems.length / this._pagesize;

    if (pages > 1) {
      return true;
    }

    return false;
  }

  toggleSearch() {

    this.showSearch = !this.showSearch;

    setTimeout(() => {

      this.setFocus('searchText');
    }, 500);
  }

  add() {

    this.router.navigate('create');
  }

  @handle(SELECT_FORM_ACTIONS.RETRIEVE_FORMS)
  @waitFor(SelectFormStore)
  handleRetrieveForms(action, forms) {
    this.allItems = forms;
    this.initPages();
    this.resolve(true);
  }

  selectForm(form) {

    let formId = form.formId;

    if (!formId) {
      return;
    }

    this.router.navigate(`questions/${formId}?firstName=${this.firstName}&surname=${this.surname}&memberId=${this.memberId}&channel=${this.channel}`);

  }

  cancel() {

    this.router.parent.parent.navigate('interactionlog');
  }

}
