import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules } from 'aurelia-validation';
import { ValidationController } from '~aurelia-validation/dist/amd/validation-controller';
import { SessionStore } from '../../../../../_common/stores/session-store';
import { CurrentScopeValidation } from '../../../../../_common/validation/current-scope-validation';
import { DispositionCodesService } from '../disposition-codes-service';
import { EditTieredDispositionListState, state } from './edit-tiered-disposition-list-state';

const logger = LogManager.getLogger('EditTieredDispositionList');

@autoinject
export class EditTieredDispositionList {

  public params: any = {};
  public ready: boolean = false;
  public category: string = '';
  public categoryMap: Map<string, Array<string>> = new Map();

  private state: EditTieredDispositionListState = state;
  private validation: ValidationController;

  constructor(
    private readonly router: Router,
    private readonly validator: CurrentScopeValidation,
    private readonly sessionStore: SessionStore,
    private readonly dispositionCodesService: DispositionCodesService
  ) {
    this.init();
  }

  private init(): void {

    this.validation = this.validator.getController();

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let category of this.categories) {
          if (category.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      }, null);

    ValidationRules
      .customRule('specialCharacters', (value) => {
        var format = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]/;
        if (format.test(value)) {
          return false;
        }
        return true;
      }, null);

    ValidationRules
      .ensure('category')
      .required().withMessage(`Please enter a ${this.headingAdd}.`)
      .then()
      .satisfiesRule('specialCharacters').withMessage('Special characters are not allowed.')
      .then()
      .satisfiesRule('uniqueName').withMessage(`Please enter a unique ${this.headingAdd}.`)
      .on(this);
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  private retrieveCategories(): void {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.dispositionCodesService.retrieveDispositionList(organisationId, this.dispositionListId)
      .then((response) => {
        if (response.categories && response.categories.length) {
          const categories = response.categories;
          for (const category of categories) {
            this.categoryMap.set(category.name, category.subcategories.map((subcategory) => subcategory.name));
          }
        }
        this.state.saveState(this.dispositionListId, this.categoryMap);
        this.state.saveModel(this.dispositionListId, response);
        this.ready = true;
      });
  }

  private get dispositionListId(): string {
    return this.params.dispositionlist;
  }

  private get isTier1(): boolean {
    return !this.params.category;
  }

  public activate(params: any): void {
    this.params = params;
    this.retrieveCategories();
  }

  public editCategory(category: string): void {
    if (this.isTier1) {
      this.router.navigate(`edit-tier2-list/${this.dispositionListId}/${category}`);
    } else {
      this.router.navigate(`edit-tier3-list/${this.dispositionListId}/${this.params.category}/${category}`);
    }
  }

  public cancel(): void {
    this.category = '';
  }

  public addCategory(): void {
    this.validation.validate().then((results) => {
      if (results.valid) {
        if (this.isTier1) {
          this.categoryMap.set(this.category, new Array());
        } else {
          this.categories.push(this.category);
        }
        this.cancel();
        this.smoothScrollToBottom();
      }
    });
  }

  public navigateBack(): void {
    if (this.isTier1) {
      this.router.navigate('');
      return;
    }
    this.router.navigateBack();
  }

  public get categories(): Array<string> {
    if (this.isTier1) {
      return Array.from(this.categoryMap.keys());
    }
    return this.categoryMap.get(this.params.category);
  }

  public get heading(): string {
    if (this.isTier1) {
      return 'Tier 1 Categories';
    }
    return `Tier 2 Subcategories - ${this.params.category}`;
  }

  public get headingAdd(): string {
    if (this.isTier1) {
      return 'Tier 1 Category';
    }
    return `Tier 2 Subcategory - ${this.params.category}`;
  }

  public get backButtonName(): string {
    if (this.isTier1) {
      return 'Back';
    }
    return `Tier 1 Categories`;
  }

  public get model(): any {
    return this.state.loadModel(this.dispositionListId);
  }
}