import {autoinject, LogManager} from 'aurelia-framework';
import {ReportsService} from './reports.service';

const logger = LogManager.getLogger('ReportDownloader');

@autoinject()
export class ReportDownloader {
  private _url: string = '';
  private _fileName: string = '';
  private _params: object = {};
  private _fileType: string = 'application/zip';

  constructor(private reportsService: ReportsService) {
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get fileName(): string {
    return this._fileName;
  }

  set fileName(value: string) {
    this._fileName = value;
  }

  get params(): any {
    return this._params;
  }

  set params(value: any) {
    this._params = value;
  }

  get fileType(): string {
    return this._fileType;
  }

  set fileType(value: string) {
    this._fileType = value;
  }

  public addQueryParam(key: string, value: string): void {
    this._params[key] = value;
  }

  public removeQueryParam(key: string): void {
    delete this._params[key];
  }

  public download(): Promise<any> {
    return this.reportsService.downloadReportFile(this._url, this._params, this._fileType, this._fileName);
  }
}
