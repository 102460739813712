import {inject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
*/
const logger = LogManager.getLogger('OrganisationTaskTemplates');
/*
*/
@inject(Router)
export class OrganisationTaskTemplates {

  constructor(router) {
    this.router = router;
  }

  configureRouter(config, router) {

    let routeMap = [
      {route: '', name: 'list', moduleId: PLATFORM.moduleName('./list/work-types'), nav: false, title: 'Work Types', isTwelveColumnRoute: true},
      {route: 'audit', name: 'audit', moduleId: PLATFORM.moduleName('./audit/worktypes-audit'), nav: false, title: 'Work Types Audit', isTwelveColumnRoute: true},
      {route: 'create', name: 'create', moduleId: PLATFORM.moduleName('./create/worktype-creator'), nav: false, title: 'Create Work Type', isTwelveColumnRoute: true},
      {
        route: 'worktypes-color-settings',
        name: 'worktypes-color-settings',
        moduleId: PLATFORM.moduleName('./settings/worktype-color-settings'),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Worktype Settings',
        settings: {
          access: ['Administrator']
        }
      }
    ];

    config.map(routeMap);

    this.router = router;
  }
}
