import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { MailboxService } from '../mailbox-service';
import { MailboxMessagesPlayback } from '../viewmessage/mailbox-messages-dialog';
import { PlaceholderService } from "../../../../_common/services/placeholder-service";

const logger = LogManager.getLogger('MailboxMessages');

@inject(DialogService, SessionStore, MailboxService)
export class MailboxMessages {
  placeholders = 0;
  container;
  actions = [
    {
      action: 'view',
      label: 'View Recording'
    }
  ];
  currentSelectedType = 'GROUP';
  groupMessages = null;
  personalMessages = null;

  constructor(dialogService, sessionStore, mailboxService) {
    this.dialogService = dialogService;
    this.sessionStore = sessionStore;
    this.mailboxService = mailboxService;
  }

  activate(params) {
    this.mailboxService.initialiseOplog(this.user.recipientId).then(oplog => this.handleOplog(oplog));
  }

  handleOplog(oplog) {
    this.oplog = oplog;
    this.oplog.on('insert', data => this.handleOplogChanges(data));
    this.oplog.on('update', data => this.handleOplogChanges(data));
  }

  attached() {
    this.retrieveMail();
  }

  handleOplogChanges(data) {
    if (data.mailboxType === 'GROUP') {
      this.groupMessages = null;
      this.retrieveGroupMail(true);
      // (1) BUG: The oplog data is missing some fields that is needed for the view so we have to do the query again for now
      // (1) this.groupMessages = this.mailboxService.model(data.mailboxMessages); TODO: test having multiple group mailboxes and getting messages from multiple mbs
    } else {
      this.personalMessages = null;
      this.retrievePersonalMail(true);
      // (1) BUG: The oplog data is missing some fields that is needed for the view so we have to do the query again for now
      // (1) this.personalMessages = this.mailboxService.model(data.mailboxMessages);
    }
    // (1) this.setMailboxMessages();
  }

  retrieveMail() {
    if (this.currentSelectedType === 'GROUP') {
      this.retrieveGroupMail();
    } else {
      this.retrievePersonalMail();
    }
  }

  retrieveGroupMail(forceQuery) {
    if (!forceQuery && this.groupMessages) {
      return this.setMailboxMessages();
    }
    this.mailboxService
      .retrieveMailboxMessages(this.user.recipientId)
      .then(messages => this._groupMailboxRetrieved(messages))
      .catch(error => this._mailboxError(error));
  }

  retrievePersonalMail(forceQuery) {
    if (!forceQuery && this.personalMessages) {
      return this.setMailboxMessages();
    }
    this.mailboxService
      .retrievePersonalMailboxMessages(this.user.recipientId)
      .then(messages => this._personalMailboxRetrieved(messages))
      .catch(error => this._mailboxError(error));
  }

  setMailboxMessages() {
    if (this.currentSelectedType === 'GROUP') {
      this.itemList = this._buildList(this.groupMessages);
    } else {
      this.itemList = this._buildList(this.personalMessages);
    }
    this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 2, (placeholders) => {
      logger.info('placeholders=', placeholders);
      this.placeholders = placeholders;
    });
    this.ready = true;
  }

  _buildList(messages) {
    const list = List.Builder() //
      .items(messages) //
      .uniqueIdentifier('mailboxMessageId') //
      .displayId('mailboxName') //
      .build();
    return list;
  }

  _groupMailboxRetrieved(messages) {
    this.groupMessages = messages;
    this.setMailboxMessages();
  }

  _personalMailboxRetrieved(messages) {
    this.personalMessages = messages;
    this.setMailboxMessages();
  }

  /**
   * callback for failed mailbox query
   * @param error
   * @private
   */
  _mailboxError(error) {
    this.ready = false;
    logger.info(' retrieve mailbox items >> error = ', error);
  }

  /**
   * select a message to view
   * opens aurelia dialog
   * @param message
   */
  selectMessage(message) {
    this.dialogService
      .open({
        viewModel: MailboxMessagesPlayback,
        model: message
      });
  }

  deactivate() {
    this.unsubscribeOplog();
  }

  unsubscribeOplog() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  partialMatch(searchExpression, item) {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    let includesName = item.mailboxName.includes(searchExpression.toLowerCase());
    let includesFrom = item.from.includes(searchExpression.toLowerCase());
    return includesName || includesFrom;
  }
}
