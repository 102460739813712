import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/*
 */
import { FeatureFlagService, SessionStore } from 'zailab.common';
import { computedFrom } from "aurelia-binding";
import { UserPersonService } from '../../../user/person/user-person-service';
import { UserSessionModel } from '../../../../_common/stores/sessionmodels/user-session-model';
import { ThemeService } from '../../../../_common/services/theme-service';

const logger = LogManager.getLogger('Profile');

@autoinject
export class Profile {
  private deviceInfo: any;
  public showDropdown: boolean = false;
  public themesEnabled: boolean;
  public themeSwitch: boolean; // false = dark, true = light

  constructor(
    private sessionStore: SessionStore, 
    private router: Router, 
    private userPersonService: UserPersonService,
    private featureFlagService: FeatureFlagService
  ) {}

  public async bind(): Promise<void> {
    this.userPersonService.retrieveUserInfo(this.user.personId);
    this.deviceInfo = await this.userPersonService.retrieveDeviceInfo(this.user.memberId);
    this.themesEnabled = await this.featureFlagService.isEnabled(
      'ThemesEnabled'
    );
    if (this.themesEnabled) {
      this.themeSwitch = this.deviceInfo.theme === 'Light';
      if (this.themeSwitch) {
        ThemeService.loadLightTheme(true);
      } else {
        ThemeService.loadDarkTheme(true);
      }
    } else {
      ThemeService.loadDarkTheme(true);
      this.userPersonService.changeToDarkTheme();
    }
  }

  private toggleVisibility(): void {
    this.showDropdown = !this.showDropdown;
  }

  public viewProfile(): void {
    this.toggleVisibility();
    this.router.navigate('userprofile');
  }

  public viewOrganisation(): void {
    this.toggleVisibility();

    if (this.sessionStore.get.user.hasAdministratorRole) {
      this.router.navigate('manageorganisation');
    } else {
      this.router.navigate('members');
    }
  }

  public viewDiagnostics(): void {
    this.toggleVisibility();
    this.router.navigate('diagnostics');
  }

  public triggerThemeChange(): void {
    if (!this.themesEnabled) {
      return;
    }
    if (this.themeSwitch) {
      ThemeService.loadLightTheme(true);
      this.userPersonService.changeToLightTheme();
    } else {
      ThemeService.loadDarkTheme(true);
      this.userPersonService.changeToDarkTheme();
    }
  }

  @computedFrom('sessionStore.get.user')
  get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('user')
  get userRole(): string {

    if (!this.user) {
      return '';
    }
    for (let user of this.user.userAccessRoles) {
      if (user.accountType.toLowerCase() === 'organisation') {
        return user.role;
      }
    }
    return 'Administrator';
  }
}
