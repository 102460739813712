import { ViewCampaignAuditDialog } from './../../../../campaigns/campaign/audit/view-audit';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { InfiniteScrollService } from '../../../../../_common/services/infinitescroll-service';

// @ts-ignore
import moment from 'moment';
import { WorkTypesService } from '../work-types-service';
import { DialogService } from 'aurelia-dialog';

const logger = LogManager.getLogger('WorktypesAudit');
@autoinject
export class WorktypesAudit {
  public header: string = 'Work Types Audit Trail';
  public searchTerm: string = '';
  public ready: boolean = false;
  public auditTrail: any[] = [];
  public total: number | string;
  public isSearching: boolean = false;
  public tableContainer: Element;
  public infiniteScroll: InfiniteScrollService;
  private pageNumber: number = 0;

  constructor(
    private router: Router,
    private workTypesService: WorkTypesService,
    private dialogService: DialogService
  ) {}

  public activate(): void {
    this.retrieveAuditTrail();
  }

  public attached(): void {
    this.initialiseInfiniteScroll();
  }

  public retrieveAuditTrail(): void {
    this.workTypesService
      .retrieveWorkTypeAudits(this.pageNumber)
      .then(response => {
        if (response && Array.isArray(response.taskTemplateAuditTrailsViews)) {
          response.taskTemplateAuditTrailsViews.forEach((item) => {
            item.formattedTimestamp = moment(parseInt(item.lastUpdatedTimestamp)).format('D MMM YY HH:mm:ss');
          });
          this.auditTrail = this.auditTrail.concat(response.taskTemplateAuditTrailsViews);
          this.total = response.total;
          this.ready = true;
        }
      })
      .catch((error) => {
        logger.warn(' > failed to get campaign audits ', error);
        this.total = '--';
        this.ready = true;
      });
  }

  private initialiseInfiniteScroll(): void {
    this.infiniteScroll = new InfiniteScrollService(this.tableContainer, () => {
      this.pageNumber++;
      this.retrieveAuditTrail();
    });
  }
  
  public viewWorkTypes(): void {
    this.router.navigate('');
  }

  public toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  public partialMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression.length === 0) {
      return true;
    }
    searchExpression = searchExpression.toLowerCase();

    let formattedTimestamp = value.formattedTimestamp ? value.formattedTimestamp.toLowerCase() : '';
    let name = value.name ? value.name.toLowerCase() : '';
    let lastUpdatedBy = value.lastUpdatedBy ? value.lastUpdatedBy.toLowerCase() : '';
    let lastUpdatedByEmail = value.lastUpdatedByEmail ? value.lastUpdatedByEmail.toLowerCase() : '';
    let action = value.action ? value.action.toLowerCase() : '';
    let taskTemplateName = value.taskTemplateName ? value.taskTemplateName.toLowerCase() : '';
    return (
      formattedTimestamp.includes(searchExpression) ||
      name.includes(searchExpression) ||
      lastUpdatedBy.includes(searchExpression) ||
      lastUpdatedByEmail.includes(searchExpression) ||
      action.includes(searchExpression) ||
      taskTemplateName.includes(searchExpression)
    );
  }

  public viewChange(auditRecord: any): void {
    let model = {
      size: 'small'
    };
    if (auditRecord.updatedValues) {
      model = {
        ...auditRecord.updatedValues,
        ...model
      }
    }
    this.dialogService
      .open({
        viewModel: ViewCampaignAuditDialog,
        model
      })
      .whenClosed(() => { });
  }
}
