import { autoinject, bindable, computedFrom, customElement, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { SessionStore } from '../../../../_common/stores/session-store';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { BusinessPartner } from '../../businesspartner/business-partner-model';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenter } from '../../contactcenter/contact-center-model';
import { ContactCenterService } from '../../contactcenter/contact-center-service';
import { MemberModel } from '../../conversation/member-model';
import { MembersService } from '../../member/members/members-service';
import { DisplayTeamsModel } from '../../member/teams/teams-model';
import { TeamsService } from '../../member/teams/teams-service';
import { Alert, AlertAudienceType, AlertAudienceTypeSelection, AlertPrioritySelection, AlertTrigger, AlertTriggerSelection, Option } from '../alert-model';
import { AlertService } from '../alert-service';

const logger = LogManager.getLogger('AlertForm');

@customElement('alert-form')
@autoinject()
export class AlertForm {

  @bindable({ attribute: 'alert' }) public alert: Alert;
  @bindable({ attribute: 'page-header' }) public pageHeader: string;
  @bindable({ attribute: 'is-processing' }) public isProcessing: boolean = false;

  private validation: ValidationController;

  public triggerOptions: Option[] = AlertTriggerSelection.options;
  public priorityOptions: Option[] = AlertPrioritySelection.options;
  public audienceTypeOptions: Option[] = this.isAdmin ? AlertAudienceTypeSelection.options : AlertAudienceTypeSelection.leaderOptions;

  public businessPartnerOptions: Option[];
  public contactCenterOptions: Option[];
  public teamOptions: Option[];
  public memberOptions: Option[];

  public audienceTypeSelect: HTMLSelectElement;
  public audienceSelect: HTMLSelectElement;
  
  private audienceSearchText: string = '';
  private pageNumber: number = 0;
  private pageSize: number = 6;
  public showMoreEnabled: boolean = true;
  public noMoreMembers: boolean = false;

  public audienceTypeSelectDisabled: boolean = false;
  public audienceSelectDisabled: boolean = false;

  private alerts: any[] = [];

  constructor(
    private router: Router,
    private element: Element,
    private sessionStore: SessionStore,
    private alertService: AlertService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private teamService: TeamsService,
    private memberService: MembersService,
    public validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
    this.validation.validateTrigger = validateTrigger.change;
  }

  public attached(): void {
    this.initValidationRules();
    this.initDisplay();
    this.getAllAlerts();
  }

  private async getAllAlerts(): Promise<void> {
    this.alerts = await this.alertService.findAll();
  }

  public cancel(): void {
    this.router.navigate('list');
  }

  public save(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  public updateAudience(item: { label: string }): void {
    if (!item) {
      return;
    }
    let label = this.convertToEnumFormat(item.label);
    if (label === AlertTrigger.ONCE_OFF) {
      this.audienceTypeSelectDisabled = false;
      this.audienceSelectDisabled = false;
    } else {
      this.audienceTypeSelectDisabled = true;
      this.audienceSelectDisabled = true;
      this.alert.audienceType = null;
      this.alert.audience = null;
    }
  }

  @computedFrom('alert.audienceType', 'teamOptions', 'memberOptions')
  public get audienceOptions(): Option[] {
    let audienceType = this.convertToEnumFormat(this.alert.audienceType);

    if (audienceType === AlertAudienceType.BUSINESS_PARTNER) {
      return this.businessPartnerOptions;
    }
    if (audienceType === AlertAudienceType.CONTACT_CENTER) {
      return this.contactCenterOptions;
    }
    if (audienceType === AlertAudienceType.TEAM) {
      return this.teamOptions;
    }
    if (audienceType === AlertAudienceType.MEMBER) {
      return this.memberOptions;
    }
    return [new DefaultOption('ALL', 'Everyone')];
  }

  private convertToEnumFormat(value: string): string {
    if (value) {
      return value.toUpperCase().replace(' ', '_');
    }
    return '';
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      this.element.dispatchEvent(new CustomEvent('save', { bubbles: true, detail: this.alert }));
    }
  }

  private initValidationRules(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        let loadBalancer = this.alerts.find(alert => alert.name === value && alert.alertId !== this.alert.alertId);
        if (loadBalancer) {
          return false;
        }
        return true;
      }, 'Please enter a unique name.');

    this.validation.reset();
    ValidationRules
      .ensure('name')
        .required().withMessage('Name is required.')
        .satisfiesRule('uniqueName')
        .maxLength(30)
      .ensure('message')
        .required().withMessage('Message is required.')
      .ensure('trigger')
        .required().withMessage('Trigger is required.')
      .ensure('priority')
        .required().withMessage('Priority is required.')
      .ensure('audienceType')
        .required()
          .when(() => !this.alert.trigger || this.alert.trigger === 'Once Off')
          .withMessage('Audience Type is required.')
      .ensure('audience')
        .required()
          .when(() => !this.alert.trigger || this.alert.trigger === 'Once Off')
          .withMessage('Audience is required.')
      .on(this.alert);
  }

  private initDisplay(): void {
    if (!this.isAdmin) {
      this.audienceTypeOptions = this.audienceTypeOptions.filter(item => item.label === 'Team' || item.label === 'Member');
    }
    this.businessPartnerService.findAll().then((results: BusinessPartner[]) => {
      this.businessPartnerOptions = results.map((data: BusinessPartner) => new DefaultOption(data.id, data.name));
    });
    this.contactCenterService.findAll().then((results: ContactCenter[]) => {
      this.contactCenterOptions = results.map((data: ContactCenter) => new DefaultOption(data.contactCentreId, data.name));
    });
    this.teamService.retrieveOrganisationTeams(this.sessionStore.get.organisation.organisationId).then((results: DisplayTeamsModel[]) => {
      this.teamOptions = results.map((data: DisplayTeamsModel) => new DefaultOption(data.teamId, data.teamName));
    });
    this.getMembers();
  }

  private getMembers():void {
    this.memberService
      .retrieveOrganisationMembers(null, this.audienceSearchText, null, this.pageNumber, this.pageSize)
      .then((results: any[]) => {
        results = results.map((data: MemberModel) => new DefaultOption(data.memberId, data.fullName));

        if (this.pageNumber >= 1) {
          if (!results || results.length === 0) {
            this.noMoreMembers = true;
          }
          if (results.length < this.pageSize) {
            this.showMoreEnabled = false;
          }
          this.memberOptions = [].concat(this.memberOptions, results);
        } else {
          this.memberOptions = results;
        }
      });
  }

  public searchMembers(): void {
    this.pageNumber = 0;
    this.showMoreEnabled = true;
    this.getMembers();
  }

  public showMore(): void {
    this.pageNumber += 1;
    this.getMembers();
  }

  public audienceChanged(audience: { label: string, value: string }): void {
    if (audience) {
      this.alert.id = audience.value;
    }
  }

  public audienceTypeChanged(): void {
    this.alert.audience = null;
    this.alert.id = null;
  }

  @computedFrom('sessionStore.get.user')
  private get isAdmin(): boolean {
    return this.sessionStore.get.user.hasAdministratorRole;
  }
}

class DefaultOption implements Option {

  constructor(
    public value: string,
    public label: string
  ) { }
}
