import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';
import { Router } from 'aurelia-router';

import { AbstractList, List } from 'zailab.abstract';
import { AgentMatchingService } from '../agent-matching-service';
import { PlaceholderService } from './../../../../_common/services/placeholder-service';
import { MembersService } from '../../../organisation/member/members/members-service';
import { BootstrapFormRenderer } from '../../../../zailab.common';
import { ViewAgentMatchingScoreDialog } from '../view-score-dialog/view-score-dialog';

import moment from 'moment';

const logger = LogManager.getLogger('WebchatConfigurationList');

interface IMember {
  id: string;
  firstName: string;
  surname: string;
  fullName: string;
}

@autoinject()
export class AgentMatchingScores extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders = 0;
  public container: any;

  public loading: boolean;
  public itemList: any;

  public pageNumber = 0;
  public pageSize = 20;
  public filter = {
    searchText: '',
    interactionId: '',
    memberId: null,
    member: null,
    fromDate: null,
    toDate: null,
  };
  public currentDate = moment();
  public showMoreEnabled: boolean;
  public members: IMember[];
  public activeFilter: string;

  private validation: ValidationController;

  constructor(
    private dialogService: DialogService,
    private agentMatchingService: AgentMatchingService,
    private membersService: MembersService,
    private router: Router,
    eventAggregator: EventAggregator,
    validationControllerFactory: ValidationControllerFactory
  ) {
    super(eventAggregator);
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(params: {
    interactionId?: string;
    memberId?: string;
    from?: string;
    to?: string;
  }): void {
    this.getMembers(params);
  }

  public getMembers = (params?: any): void => {
    this.membersService
      .retrieveOrganisationMembersAndTotalMemberCount(
        '',
        this.filter.searchText,
        '',
        this.pageNumber,
        this.pageSize
      )
      .then((data: { members: IMember[] }) => {
        if (this.pageNumber >= 1) {
          this.members = [].concat(this.members, data.members);
        } else {
          this.members = data.members;
        }
        this.showMoreEnabled = data.members.length === this.pageSize;

        if (
          params &&
          ((params.interactionId && params.interactionId !== 'null') ||
            (params.memberId && params.memberId !== 'null')) &&
          params.from &&
          params.from !== 'null' &&
          params.to &&
          params.to !== 'null'
        ) {
          if (params.interactionId && params.interactionId !== 'null') {
            this.filter.interactionId = params.interactionId;
          }
          if (params.memberId && params.memberId !== 'null') {
            this.filter.memberId = params.memberId;
            this.filter.member = this.members.find(
              (member) => member.id === params.memberId
            );
            this.filter.searchText = `${this.filter.member.firstName} ${this.filter.member.surname}`;
          }
          this.filter.fromDate = moment(parseInt(params.from)).format(
            'YYYY-MM-DD'
          );
          this.filter.toDate = moment(parseInt(params.to)).format('YYYY-MM-DD');

          this.searchByFilter(this.filter);
        } else {
          this.searchByFilter();
        }
      });
  };

  public attached(): void {
    this.setupListView([]);

    ValidationRules.customRule(
      'dateFromBeforeDateTo',
      () => {
        if (!this.filter.fromDate || !this.filter.toDate) {
          return true;
        }
        const startDate = moment(this.filter.fromDate);
        const endDate = moment(this.filter.toDate);
        return startDate <= endDate;
      },
      'The `Date From` cannot be after `Date To`.'
    );

    ValidationRules.ensure('fromDate')
      .satisfiesRule('dateFromBeforeDateTo')
      .then()
      .satisfies((value: string) => {
        if (!value && !this.filter.toDate) {
          return true;
        }
        const startDate = moment(value);
        return this.currentDate.isAfter(startDate);
      })
      .withMessage('The `Date From` cannot be a future date.')
      .ensure('toDate')
      .satisfiesRule('dateFromBeforeDateTo')
      .then()
      .satisfies((value: string) => {
        if (!this.filter.fromDate && !value) {
          return true;
        }
        const endDate = moment(value);
        return this.currentDate.isAfter(endDate);
      })
      .withMessage('The `Date To` cannot be a future date.')
      .on(this.filter);
  }

  public memberSelected(data: IMember): void {
    this.filter.member = data;
  }

  public showMore(): void {
    this.pageNumber += 1;
    this.getMembers();
  }

  private setupListView(data: any[]): void {
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableClick()
      .items(data)
      .uniqueIdentifier('id')
      .hasRollover(false)
      .tilesPerRow(5)
      .disableOrderBy()
      .build();
    this.generatePlaceholders();
    super.hideLoader();
  }

  public select(item: any): void {
    this.dialogService
      .open({
        viewModel: ViewAgentMatchingScoreDialog,
        model: item,
      })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          //
        }
      });
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.itemList.items.length,
      5,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }

  public filterContent(filter: string): void {
    if (this.activeFilter === filter) {
      this.activeFilter = '';
    } else {
      this.activeFilter = filter;
    }
  }

  public cancelSearch(): void {
    this.isSearching = false;
  }

  public resetSearchFilter(): void {
    this.filter = {
      searchText: '',
      member: null,
      memberId: null,
      interactionId: '',
      fromDate: '',
      toDate: '',
    };
    this.validation.validate();
    const newURL = new URL(window.location.href);
    newURL.search = '';
    window.history.pushState({}, '', newURL.toString());
  }

  public searchByFilter(filterData?: any): void {
    let params;

    if (filterData) {
      params = {
        interactionId: filterData.interactionId || null,
        memberId: filterData.memberId || null,
      };
    } else {
      params = {
        interactionId: this.filter.interactionId || null,
        memberId: this.filter.member ? this.filter.member.id : null,
      };
    }

    params.from = this.filter.fromDate
      ? moment(this.filter.fromDate).valueOf()
      : moment().subtract(1, 'hour').valueOf();
    params.to = this.filter.toDate
      ? moment(this.filter.toDate).endOf('day').valueOf()
      : moment().valueOf();

    this.validation.validate().then((result) => {
      if (!result.valid && !filterData) {
        return;
      }
      this.retrieveWebchatConfigurations(
        params.interactionId,
        params.memberId,
        params.from,
        params.to,
        !!filterData
      );
    });
  }

  private async retrieveWebchatConfigurations(
    interactionId: string,
    memberId: string,
    from: number,
    to: number,
    isAutoQuery?: boolean
  ): Promise<void> {
    this.loading = true;
    let agentMatchingScores;
    try {
      agentMatchingScores =
        await this.agentMatchingService.retrieveAgentMatchingScores(
          interactionId,
          memberId,
          from,
          to
        );
      agentMatchingScores.forEach(
        (score) =>
          (score.formattedTime = moment(parseInt(score.timestamp)).format(
            'DD/MM/YYYY hh:mm'
          ))
      );
    } catch (e) {
      logger.warn('Failed to retrieve webchat configs due to', { e });
      agentMatchingScores = [];
    } finally {
      this.loading = false;
    }

    if (!isAutoQuery) {
      const newURL = new URL(window.location.href);
      if (interactionId) {
        newURL.searchParams.set('interactionId', interactionId);
      }
      if (memberId) {
        newURL.searchParams.set('memberId', memberId);
      }
      if (from) {
        newURL.searchParams.set('from', from.toString());
      }
      if (to) {
        newURL.searchParams.set('to', to.toString());
      }
      window.history.pushState({}, '', newURL.toString());
    }

    this.setupListView(agentMatchingScores);
    this.isSearching = false;
  }

  public match(
    searchExpression: string,
    value: string,
    param: string
  ): boolean {
    return value[param];
  }
}
