import {LogManager, autoinject} from 'aurelia-framework';
import {CannedResponseService} from '../canned-responses-service';
import {CannedResponseView} from '../canned-responses-model';
import {Router} from 'aurelia-router';

const logger = LogManager.getLogger('CannedResponseAdd');

@autoinject()
export class CannedResponseAdd {

  public cannedResponse: CannedResponseView = new CannedResponseView();
  public pageHeader: string = 'Create Canned Response';
  public isBusy: boolean = false;

  constructor(private cannedResponseService: CannedResponseService, private router: Router) {
  }

  public saveCannedResponse(): void {
    this.isBusy = true;
    this.cannedResponseService.addCannedResponse(this.cannedResponse.toPayload())
      .then(() => this.handleAddCannedResponseSuccess())
      .catch((error: Error) => this.handleAddCannedResponseError(error));
  }

  private handleAddCannedResponseSuccess(): void {
    this.isBusy = false;
    this.router.navigate('list');
  }

  private handleAddCannedResponseError(error: Error): void {
    this.isBusy = false;
    logger.error('saveCannedResponse :: error=', error)
  }
}