import {LogManager, customElement, bindable} from 'aurelia-framework';

const logger = LogManager.getLogger('ZTooltip');
const HALF_VALUE:number = 0.5;
/*
 */
@customElement('z-tooltip')
export class ZTooltip {

  public tooltipElement:HTMLElement;

  @bindable({attribute: 'is-visible'}) private isVisible: boolean;

  constructor() {}

  private isVisibleChanged(_newValue:boolean):void {
    if (_newValue) {
      this.addMouseMove();
    } else {
      this.removeMouseMove();
    }
  }

  private addMouseMove():void {
    document.addEventListener('mousemove', this.handleMove);
  }

  private removeMouseMove():void {
    document.removeEventListener('mousemove', this.handleMove);
  }

  private handleMove = (_event:MouseEvent):void => {
    this.tooltipElement.style.left = (_event.clientX - this.tooltipElement.clientWidth * HALF_VALUE) + 'px';
    this.tooltipElement.style.top = (_event.clientY - this.tooltipElement.clientHeight) + 'px';
  }

}
