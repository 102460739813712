import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import { DoNotContactListImport, DoNotContactListImportRequest, DoNotContactListItem } from "./donotcontact-model";
import { ApplicationProperties, SessionStore } from "zailab.common";

const logger = LogManager.getLogger('DoNotContactService');

@autoinject
export class DoNotContactService {

  private baseUrl = 'v1/interaction/do-not-contact/me/list';
  private importUrl = 'v1/interaction/do-not-contact/me/import';
  private contactUrl = 'v1/interaction/do-not-contact/me/contact';

  constructor(
    private httpClient: HttpClient,
    private applicationProperties: ApplicationProperties,
    private sessionStore: SessionStore
  ) { }

  public retrieveAll(): Promise<DoNotContactListItem[]> {
    return this.httpClient
      .createRequest(this.baseUrl)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response || !response.length) return [];
        return response.map((item: DoNotContactListItem) => this.mapDoNotContactListItem(item));
      });
  }

  public retrieve(doNotContactType: string, doNotContactValue: string): Promise<DoNotContactListItem> {
    return this.httpClient
      .createRequest(this.contactUrl)
      .withParams({doNotContactType, doNotContactValue})
      .asGet()
      .send()
      .then((response: any) => {
        return response;
      });
  }

  public delete(doNotContactId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${doNotContactId}`)
      .asDelete()
      .send();
  }

  public create(doNotContactListItem: DoNotContactListItem): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${doNotContactListItem.doNotContactId}`)
      .asPost()
      .withContent(doNotContactListItem)
      .send();
  }

  public import(file: { content: string, name: string, length: number }): Promise<any> {
    return new Promise((resolve, reject) => {
      let body = new DoNotContactListImportRequest();

      body.addDisposition({
        name: 'meta-data',
        contentType: 'application/json',
        content: JSON.stringify({
          fileName: file.name,
          fileLines: file.length - 1 // exclude the headings
        })
      });

      body.addDisposition({
        name: 'file',
        fileName: file.name,
        contentType: 'application/octet-stream',
        content: file.content
      });

      let host = this.applicationProperties.apiQueryEndpoint;
      let xhr = new XMLHttpRequest();
      xhr.open('POST', host + this.baseUrl, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.sessionStore.get.user.token);
      xhr.setRequestHeader('Content-Type', 'multipart/mixed; boundary=' + body.boundary);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve({});
          } else if (xhr.status === 200) {
            resolve(xhr.response);
          } else if (xhr.status === 500) {
            reject(xhr.response);
          }
        }
      };
      xhr.send(body.getContent());
    });
  }

  public retrieveImport(): Promise<DoNotContactListImport> {
    return this.httpClient
      .createRequest(this.importUrl)
      .asGet()
      .send()
      .then((response: any) => {
        return response;
      });
  }

  private mapDoNotContactListItem(item: DoNotContactListItem): DoNotContactListItem {
    return {
      doNotContactId: item.doNotContactId,
      doNotContactName: item.doNotContactName,
      doNotContactType: item.doNotContactType,
      doNotContactValue: item.doNotContactValue
    };
  }
}