import { inject, LogManager, Factory } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';

import { MailboxModel } from './mailbox.model';
import {
  DateTimeTools,
  CommandFactory,
  ClickToDialService,
  SessionStore,
  DownloadService,
  OplogService
} from 'zailab.common';

const logger = LogManager.getLogger('MailboxService');

@inject(OplogService, DateTimeTools, HttpClient, EventAggregator, Factory.of(CommandFactory), ClickToDialService, SessionStore, DownloadService)
export class MailboxService {
  constructor(oplogService, dateTimeTools, httpClient, eventAggregator, commandFactory, clickToDialService, sessionStore, downloadService) {
    this.oplog = oplogService;
    this.dateTimeTools = dateTimeTools;
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.clickToDialService = clickToDialService;
    this.sessionStore = sessionStore;
    this.downloadService = downloadService;
  }

  initialiseOplog(recipientId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('recipients.recipientId', recipientId).in('mailbox-projector.displayMessagesView'));
    });
  }

  retrieveMailboxNewMessageCount(recipientId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/recipients/${recipientId}/unplayed-message-count`)
        .asGet()
        .withParams({ recipientId: recipientId })
        .send()
        .then(
          (response) => {
            if (response && response.unplayedMessagesCount) {
              resolve(response.unplayedMessagesCount);
              return;
            }
            resolve(0);
          },
          (error) => {
            resolve(0);
          }
        )
    });
  }

  retrieveMailboxMessages(recipientId) {
    return this.httpClient
      .createRequest(`v1/organisation/mailboxes/recipients/${recipientId}/messages`)
      .asGet()
      .send()
      .then((response) => this.model(response && response.messages ? response.messages : []));
  }

  retrievePersonalMailboxMessages(recipientId) {
    return this.httpClient
      .createRequest(`v1/organisation/mailboxes/recipients/${recipientId}/messages?mailboxType=PERSONAL`)
      .asGet()
      .send()
      .then((response) => this.model(response && response.messages ? response.messages : []));
  }

  initialiseCountOplog(recipientId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', recipientId).in('mailbox-projector.displayUnplayedMessageCountView'));
    });
  }

  selectMessage(message, mailboxId, organisationId) {
    return new Promise((resolve, reject) => {
      if (message.mailboxMessageId === null) {
        reject();
      } else {
        this._recordingUrl(organisationId, mailboxId, message.mailboxMessageId).then(
          audioSource => {
            this.postMessageHasBeenPlayed(message);
            resolve(audioSource);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  postMessageHasBeenPlayed(message) {
    let mailboxId = message.mailboxId;
    return this.httpClient
      .createRequest(`v1/organisation/mailboxes/${mailboxId}/message-playback`)
      .asPut()
      .withContent({ messageId: message.mailboxMessageId })
      .send();
  }

  _recordingUrl(organisationId, mailboxId, mailboxMessageId) {
    return new Promise((resolve, reject) => {

      let uri = `v1/media/voicemail/${mailboxMessageId}/url`;

      this.httpClient
        .createRequest(uri)
        .asGet()
        .send()
        .then(
          recordingURL => {
            resolve(recordingURL);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  dial(source, destination) {
    this.clickToDialService.dial(source, destination);
  }

  model(list) {
    let newList = [];

    if (list) {
      list.forEach(item => {
        newList.push(new MailboxModel(item, this.dateTimeTools));
      });
    }

    newList = newList.sort((a, b) => {
      return a['date'] > b['date'] ? -1 : a['date'] < b['date'] ? 1 : 0;
    });

    return newList;
  }
}
