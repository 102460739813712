import {LogManager, customElement, bindable, containerless} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZCardStat');
/*
 */
@containerless
@customElement('z-card-stat')
export class ZCardStat {

  @bindable width: string;
  @bindable height: string;
}
