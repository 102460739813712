import { autoinject, LogManager } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('IntegrationsModule');

declare const PLATFORM;

@autoinject
export class InAppAlerts {
  constructor(private router: Router) {}

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: '',
        name: '',
        moduleId: PLATFORM.moduleName(
          'features/organisation/in-app-alerts/list/platform-alerts-list'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Platform Alerts',
        settings: { access: ['Administrator'] },
      },
      {
        route: 'dashboard-alerts',
        name: 'dashboard-alerts',
        moduleId: PLATFORM.moduleName(
          'features/organisation/in-app-alerts/list/dashboard-alerts/dashboard-alerts-list'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Dashboard Alerts',
        settings: { access: ['Administrator'] },
      },
      {
        route: 'in-app-alerts-audit',
        name: 'in-app-alerts-audit',
        moduleId: PLATFORM.moduleName(
          'features/organisation/in-app-alerts/audit/in-app-alerts-audit'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'In App Alert Audits',
        settings: {
          access: ['Administrator'],
        },
      },
    ]);
    this.router = router;
  }
}
