import { autoinject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-http-client';
import { SessionStore, ClickToDialService } from 'zailab.common';
import { ContactModel } from '../../contact/contact-model';
import { InteractionModel } from '../interaction-model';
import { InteractionService } from '../interaction/interaction-service';

const logger = LogManager.getLogger('ChannelService');

@autoinject()
export class ChannelService {
  constructor(
    private clickToDialService: ClickToDialService,
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private httpClient: HttpClient,
    private interactionService: InteractionService
  ) {}

  /**
   *
   * Get Methods
   *
   */

  public retrieveFlowEmails(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/interaction/interaction-flows/emails')
        .asGet()
        .send()
        .then(
          (response) => {
            if (response && response.emails) {
              resolve(response.emails);
            } else {
              resolve(response);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public uploadAttachment(
    attachmentId: string,
    emailId: string,
    file: Object
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`email/${emailId}/attachment/${attachmentId}`)
        .asPut()
        .withContent(file)
        .send()
        .then(
          (response) => {
            resolve(true);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  public uploadImage(
    emailId: string,
    imageId: string,
    file: Object,
    fileName: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`email/${emailId}/image/${imageId}`)
        .withParams({ fileName: fileName })
        .asPut()
        .withContent(file)
        .send()
        .then(
          (response) => resolve(response),
          (error) => reject(error)
        );
    });
  }

  public deleteImage(emailId: string, imageId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`email/${emailId}/image/${imageId}`)
        .asDelete()
        .send()
        .then(
          (response) => resolve(response),
          (error) => reject(error)
        );
    });
  }

  public retrieveCallNumbers(contactId: string): Promise<Object[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/contacts/${contactId}`)
        .asGet()
        .send()
        .then(
          (response) => {
            let _list = [];

            if (response && response.telephoneNumbers) {
              _list = response.telephoneNumbers;
            }
            resolve(this.buildListOfTelephoneNumbers(_list));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public subscribe(contactId: string): void {}

  public retrieveOutboundFlows(memberId: string): Promise<Object[]> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/members/${memberId}/profiles`)
        .asGet()
        .send()
        .then(
          (response) => {
            if (response && response.outboundFlowOptions) {
              resolve(response.outboundFlowOptions);
            } else {
              resolve([]);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   *
   * Post and Put methods
   *
   */

  public call(
    interaction: InteractionModel,
    metadata: object,
    additionalProperties: any
  ): void {
    this.clickToDialService.dial(
      interaction.email,
      interaction.recipientNumber,
      interaction.conversationId,
      interaction.flow.flowId,
      interaction.contactId,
      interaction.campaignId,
      interaction.campaignName,
      interaction.prospectListId,
      metadata,
      additionalProperties
    );
  }

  public selectOutboundCallOption(flowId: string): void {
    let memberId = this.sessionStore.get.user.memberId;
    let payload = { flowId };

    this.httpClient
      .createRequest(
        `v1/organisation/members/${memberId}/outbound-flow-options`
      )
      .asPost()
      .withContent(payload)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  public sendEmail(interaction: InteractionModel, metadata?: object): void {
    this.interactionService.startEmailInteraction(interaction, metadata);
  }

  public sendSms(interaction: InteractionModel, metadata?: object): void {
    this.interactionService.startSMSInteraction(interaction, metadata);
  }

  public addNote(interaction: InteractionModel): void {
    this.eventAggregator.publish('add.interaction', interaction);
  }

  /**
   *
   * Data Formatter
   *
   */

  private buildListOfTelephoneNumbers(numbers: any[]): any {
    let numberList = [];
    numbers.forEach((eachNumber) => {
      if (eachNumber && eachNumber.number !== '') {
        numberList.push(eachNumber);
      }
    });
    return numberList;
  }

  private buildListOfEmailNames(response: Object): string[] {
    let emails = [];
    if (response && response.emails) {
      response.emails.forEach((eachEmail) => {
        emails.push(eachEmail.email);
      });
    }
    return emails;
  }

  private buildListOfMobileNumbers(contact: ContactModel): string[] {
    let smsNumbers = [];
    if (contact && contact.telephoneNumbers.length > 0) {
      contact.telephoneNumbers.forEach((telephoneNumber) => {
        if (telephoneNumber.type === 'MOBILE' && telephoneNumber.number) {
          smsNumbers.push(telephoneNumber.formattedNumber);
        }
      });
      return smsNumbers;
    }
  }
}
