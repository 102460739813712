import {autoinject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

import {InteractionFlowModel} from "../../../../interaction-flow-model";

const logger = LogManager.getLogger('FlowVersionDialog');

interface IVersion {
  version: number;
}

@autoinject()
export class SubFlowFlowVersionDialog {

  public dialogHeader: string = 'Flow Versions';
  private interactionFlowId: string;
  private versions: IVersion[] = [];
  public selectedVersion: string;
  public isVersionedFlow: boolean;

  constructor(
    private dialogController: DialogController
  ) {}

  activate(model: { flow: InteractionFlowModel, selectedVersion: string, isVersioned: boolean; }): void {
    this.interactionFlowId = model.flow.interactionFlowId;
    this.selectedVersion = model.selectedVersion;
    this.isVersionedFlow = model.isVersioned;
    model.flow.versions.forEach(version => this.versions.push({version: version.version}));
  }

  public select(version: number): void {
    this.dialogController.ok({
      version,
      interactionFlowId: this.interactionFlowId
    });
  }

  public doNothing(): void {}

  public cancel(): void {
    this.dialogController.cancel();
  }

  public done(): void {
    this.dialogController.ok();
  }

}
