//@ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

import CampaignService from '../campaign-service';
import { ZaiForm } from '../../../../zailab.common';
import { observable } from 'aurelia-binding';

const logger = LogManager.getLogger('ScheduleDncListDialog');

interface IDncSchedule {
  host?: string;
  port?: number;
  username?: string;
  password?: string;
  path?: string;
  intervalUnit?: string;
}

@autoinject
export class ScheduleDncListDialog {

  public campaign: any;
  public dncSchedule: IDncSchedule = {};
  public intervalUnits = ['HOUR', 'DAY', 'WEEK', 'MONTH'];
  public isScheduleSet: boolean = false;
  public isProcessing: boolean = false;
  
  public form: any[][];
  @observable public formData: {
    host: string;
    port: number;
    username: string;
    password: string;
    path: string;
    intervalUnit: string;
    valid: boolean;
  };

  constructor(
    private dialogController: DialogController,
    private campaignService: CampaignService
  ) {}

  public activate(campaign: any): void {
    this.campaign = campaign;
    this.retrieveData();
  }

  private retrieveData(): void {
    this.isProcessing = true;
    this.campaignService.retrieveDncSchedule(this.campaign.campaignId)
      .then((dncSchedule: any) => {
        if (dncSchedule) {
          this.isScheduleSet = true;
          this.dncSchedule = {
            host: dncSchedule.sftpConfiguration.host,
            port: dncSchedule.sftpConfiguration.port,
            username: dncSchedule.sftpConfiguration.username,
            password: dncSchedule.sftpConfiguration.password,
            path: dncSchedule.sftpConfiguration.path,
            intervalUnit: dncSchedule.intervalUnit
          };
        }
        this.isProcessing = false;

        this.generateForm();
      });
  }

  private generateForm(): void {
    let zaiForm = new ZaiForm()
      .newField()
      .asTextInput();

    if (!this.isScheduleSet) {
      zaiForm = zaiForm.withFocus()
    }
    zaiForm.withIdentifier('host')
      .withTitle('SFTP Host', '160px')
      .withPlaceholder('Please enter a host.')
      .withValue(this.dncSchedule.host)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
      ])
      .insertField()

      .newRow()
      .newField()
      .asNumberInput()
      .withIdentifier('port')
      .withTitle('SFTP Port', '160px')
      .withPlaceholder('Please enter a port.')
      .withValue(this.dncSchedule.port)
      .withMinValue(1)
      .withMaxValue(65535)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
      ])
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('username')
      .withTitle('SFTP Username', '160px')
      .withPlaceholder('Please enter a username.')
      .withValue(this.dncSchedule.username)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
      ])
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .enableSecure()
      .withIdentifier('password')
      .withTitle('SFTP Password', '160px')
      .withPlaceholder('Please enter a password.')
      .withValue(this.dncSchedule.password)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
      ])
      .insertField()

      .newRow()
      .newField()
      .asDropdown()
      .withIdentifier('intervalUnit')
      .withTitle('Interval', '160px')
      .withClass('flex--basis')
      .withDisplayField('')
      .withOptions(this.intervalUnits)
      .withValue(this.dncSchedule.intervalUnit || 'HOUR')
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }
  
  public async formDataChanged(data: any): Promise<void> {
    this.dncSchedule = data;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public remove(): void {
    this.isProcessing = true;
    this.campaignService.removeDncSchedule(this.campaign.campaignId)
      .then(() => {
        this.isProcessing = false;
        this.dialogController.ok();
      });
  }

  public submit(): void {
    const request = {
      sftpConfiguration: {
        host: this.dncSchedule.host,
        port: this.dncSchedule.port,
        username: this.dncSchedule.username,
        password: this.dncSchedule.password,
        path: this.dncSchedule.path
      },
      intervalUnit: this.dncSchedule.intervalUnit
    };
    this.campaignService.setDncSchedule(this.campaign.campaignId, request)
      .then(() => {
        this.isProcessing = false;
        this.dialogController.ok();
      });
  }
}
