import {LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import moment from 'moment-timezone';
import {MemberModel} from './member-model';

const logger = LogManager.getLogger('WaypointModel');

export class WaypointModel extends BaseModel {

  public type: string = null;
  public timestamp: string = null;
  public flowName: string = null;
  public label: string = null;
  public status: string = null;
  public color: string = null;
  public time: string = null;
  public duration: any = null;
  public _member: MemberModel = new MemberModel({});

  constructor(waypoint?: WaypointModel[]) {
    super();
    super.mapProperties(waypoint);
    this.setMember(waypoint.member);
    this.interactionType();
  }

  private interactionType(): void {
    switch (this.type) {
      case 'START':
        this.label = 'Start';
        this.status = 'pending';
        this.color = 'yellow';
        break;

      case 'WAIT':
        this.label = 'Wait Time';
        this.status = 'pending';
        this.color = 'yellow';
        break;

      case 'CONNECT':
        this.label = this._member.fullNameShort;
        this.status = 'connected';
        this.color = 'green';
        break;

      case 'END':
        this.label = 'End';
        this.status = 'ended';
        this.color = 'red';
        break;
    }
  }

  private setMember(member: MemberModel): void {
    this._member = new MemberModel(member);
  }

  @computedFrom('timestamp')
  get twentyFourHourFormat(): void {
    return moment(this.timestamp).format('HH:mm:ss');
  }
}

