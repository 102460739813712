import { ValidationController } from '~aurelia-validation/dist/amd/validation-controller';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

import { CampaignInteractionFlowService } from '../addcampaign/campaign-interaction-flow-service';
import {
  BootstrapFormRenderer,
  FeatureFlagService,
  ArrayTools,
} from 'zailab.common';
import {
  ValidationControllerFactory,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';
import { WorkTypesService } from '../../../organisation/conversation/worktypes/work-types-service';

import './editcampaign.scss';

const logger = LogManager.getLogger('EditCampaignDialog');

@autoinject()
export class EditCampaignDialog {
  campaignId;
  dialerType;
  diallerOptions;

  public workTypesReady = false;
  public workTypes = [];
  public workTypeId;
  public workType;

  autoAcceptTaskEnabled = false;
  ignoreDoNotContactList = false;

  originalCampaign: ICampaign;
  availableInboundFlows = [];
  availableOutboundFlows = [];
  diallerTypes = ['Preview', 'Progressive', 'Power', 'Outbound IVR'];
  voicemailDetectionStrategies = ['Faster', 'More Accurate'];
  onDetectVoicemailOptions = ['Hangup', 'Transfer To Flow', 'Connect To Agent'];
  outboundIVROnDetectVoicemailOptions = ['Hangup', 'Transfer To Flow'];
  overflowCallOptions = ['Hangup', 'Transfer To Flow'];

  outboundIVROverDialRatio = 3;
  outboundIVRMaxCalls = 50;
  campaignDiallerTypeEnabled = false;
  doNotContactCampaignEnabled = true;

  predictiveDiallerStrategies = ['Optimise For Agent'];

  predictiveDiallerCallPoolExpiry = 5;
  predictiveDiallerCallPoolAssessmentInterval = 60;
  predictiveDiallerDefaultHandleTime = 300;
  predictiveDiallerDefaultHandleTimeDeviation = 5;
  predictiveDiallerDefaultRingTime = 20;
  predictiveDiallerDefaultRingTimeDeviation = 3;
  predictiveDiallerShortCallThreshold = 15;
  predictiveDiallerMaxCallDurationThreshold = 3600;
  preferredAgentReschedulingEnabled = false;
  preferredAgentReschedulingTimeoutMinutes = 30;
  preferredAgentReschedulingTimeoutHours = 0;

  predictiveDiallerOptions = {
    diallerStrategy: 'Optimise For Agent',
    callPoolExpiry: null,
    callPoolAssessmentInterval: null,
    defaultHandleTime: null,
    defaultHandleTimeDeviation: null,
    defaultRingTime: null,
    defaultRingTimeDeviation: null,
    callRatio: 1,
    shortCallThreshold: 15,
    callDurationThreshold: 3600,
  };

  validation: ValidationController;
  public error: string;

  constructor(
    private dialogController: DialogController,
    private campaignInteractionFlowService: CampaignInteractionFlowService,
    private featureFlagService: FeatureFlagService,
    private workTypesService: WorkTypesService,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(campaign: ICampaign): void {
    this.originalCampaign = { ...campaign };
    this.campaignId = campaign.campaignId;
    this.dialerType = campaign.dialerType;
    this.diallerOptions = campaign.diallerOptions;
    this.autoAcceptTaskEnabled = campaign.autoAcceptTaskEnabled;
    this.ignoreDoNotContactList = campaign.ignoreDoNotContactList;
    this.outboundIVRMaxCalls = this.diallerOptions.outboundIVRMaxCalls;
    this.outboundIVROverDialRatio =
      this.diallerOptions.outboundIVROverDialRatio;
    if (campaign.diallerOptions.predictiveDiallerOptions) {
      this.predictiveDiallerOptions =
        this.diallerOptions.predictiveDiallerOptions ||
        this.predictiveDiallerOptions;
      this.predictiveDiallerShortCallThreshold =
        this.predictiveDiallerOptions.callDurationThreshold;
      this.predictiveDiallerMaxCallDurationThreshold =
        this.predictiveDiallerOptions.callDurationThreshold;
      this.predictiveDiallerCallPoolExpiry =
        this.predictiveDiallerOptions.callPoolExpiry;

      this.predictiveDiallerCallPoolAssessmentInterval =
        this.predictiveDiallerOptions.callPoolAssessmentInterval;
      this.predictiveDiallerShortCallThreshold =
        this.predictiveDiallerOptions.shortCallThreshold;
      this.predictiveDiallerMaxCallDurationThreshold =
        this.predictiveDiallerOptions.maxCallDurationThreshold;
    }

    this.preferredAgentReschedulingEnabled =
      campaign.preferredAgentReschedulingEnabled || false;

    this.ignoreDoNotContactList =
      campaign.ignoreDoNotContactList || false;

    if (campaign.preferredAgentReschedulingTimeout) {
      this.preferredAgentReschedulingTimeoutHours = Math.floor(
        campaign.preferredAgentReschedulingTimeout / 60
      );
      this.preferredAgentReschedulingTimeoutMinutes =
        campaign.preferredAgentReschedulingTimeout % 60;
    }

    this.getInteractionFlows();
    this.getWorkTypes();
    this.getFeatureFlags();
    this.setValidationRules();
  }

  private getInteractionFlows(): void {
    this.campaignInteractionFlowService
      .retrieveInteractionFlows()
      .then((interactionFlows) => {
        interactionFlows.forEach((flow) => {
          if (flow.flowType === 'Contact Centre Flow') {
            if (flow.channel === 'Inbound Call') {
              if (
                flow.numbers.length > 0 ||
                flow.interactionFlowId ===
                  this.diallerOptions.targetInteractionFlowId
              ) {
                this.availableInboundFlows.push(flow);
              }
            } else if (flow.channel === 'Outbound Call') {
              this.availableOutboundFlows.push(flow);
            }
          }
        });
      });
  }

  private getWorkTypes(): void {
    this.workTypesService.outboundWorkTypes().then((taskTemplates) => {
      let promises = [];

      taskTemplates.map((tt) =>
        promises.push(
          new Promise((resolve) => {
            if (
              this.diallerOptions.taskTemplateId &&
              this.diallerOptions.taskTemplateId === tt.templateId
            ) {
              this.workType = tt.templateName;
            }
            // strip out unnecessary fields
            resolve({
              templateId: tt.templateId,
              templateName: tt.templateName,
            });
          })
        )
      );

      Promise.all(promises).then(
        (response: { templateId: string; templateName: string }[]) => {
          this.workTypes = ArrayTools.sort(response, 'templateName');
          if (this.originalCampaign.taskTemplateId) {
            const workType = response.find(
              (wt) => wt.templateId === this.originalCampaign.taskTemplateId
            );
            if (workType) {
              this.workTypeId = workType.templateId;
              this.workType = workType.templateName;
            }
          }
          this.workTypesReady = true;
        }
      );
    });
  }

  private getFeatureFlags(): void {
    this.featureFlagService.isEnabled('CampaignDiallerType').then((enabled) => {
      this.campaignDiallerTypeEnabled = enabled;
    });
    this.featureFlagService.isEnabled('predictive-dialler').then((enabled) => {
      if (enabled) {
        this.diallerTypes.push('Predictive');
      }
    });
  }

  public toggleAutoAcceptTaskEnabled(): void {
    this.autoAcceptTaskEnabled = !this.autoAcceptTaskEnabled;
  }

  public toggleRescheduleLeadsEnabled(): void {
    this.preferredAgentReschedulingEnabled =
      !this.preferredAgentReschedulingEnabled;
  }

  public toggleVoicemailDetectionEnabled(): void {
    this.diallerOptions.voicemailDetectionEnabled =
      !this.diallerOptions.voicemailDetectionEnabled;
  }

  public toggleWaitForVoicemailBeep(): void {
    this.diallerOptions.waitForVoicemailBeep =
      !this.diallerOptions.waitForVoicemailBeep;
  }

  public toggleIgnoreDoNotContactList(): void {
    this.ignoreDoNotContactList = !this.ignoreDoNotContactList;
  }

  public diallerTypeChanged(event: Event): void {
    this.error = null;
    if (
      !this.originalCampaign.paused &&
      this.originalCampaign.dialerType !== this.dialerType
    ) {
      this.error = 'Dialer Type can only be changed when a campaign is paused.';
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public submit(): void {
    let preferredAgentReschedulingTimeoutHours: any =
      this.preferredAgentReschedulingTimeoutHours;
    try {
      preferredAgentReschedulingTimeoutHours = parseInt(
        preferredAgentReschedulingTimeoutHours
      );
    } catch {
      /**/
    }
    let preferredAgentReschedulingTimeoutMinutes: any =
      this.preferredAgentReschedulingTimeoutMinutes;
    try {
      preferredAgentReschedulingTimeoutMinutes = parseInt(
        preferredAgentReschedulingTimeoutMinutes
      );
    } catch {
      /**/
    }

    const preferredAgentReschedulingTimeout =
      preferredAgentReschedulingTimeoutHours * 60 +
      preferredAgentReschedulingTimeoutMinutes;

    this.validation.validate().then(
      (validation) => {
        if (!validation.valid) {
          return;
        }
        this.diallerOptions.outboundIVRMaxCalls = this.outboundIVRMaxCalls;
        this.diallerOptions.outboundIVROverDialRatio =
          this.outboundIVROverDialRatio;

        this.predictiveDiallerOptions.callPoolExpiry =
          this.predictiveDiallerCallPoolExpiry;
        this.predictiveDiallerOptions.callPoolAssessmentInterval =
          this.predictiveDiallerCallPoolAssessmentInterval;
        this.predictiveDiallerOptions.defaultDialRatio =
          this.diallerOptions.powerDiallerConcurrentCalls;
        this.predictiveDiallerOptions.shortCallThreshold =
          this.predictiveDiallerShortCallThreshold;
        this.predictiveDiallerOptions.maxCallDurationThreshold =
          this.predictiveDiallerMaxCallDurationThreshold;
        this.diallerOptions.predictiveDiallerOptions =
          this.predictiveDiallerOptions;

        this.predictiveDiallerOptions.callPoolExpiry =
          this.predictiveDiallerCallPoolExpiry;
        this.predictiveDiallerOptions.callPoolAssessmentInterval =
          this.predictiveDiallerCallPoolAssessmentInterval;
        this.predictiveDiallerOptions.defaultHandleTime =
          this.predictiveDiallerDefaultHandleTime;
        this.predictiveDiallerOptions.defaultHandleTimeDeviation =
          this.predictiveDiallerDefaultHandleTimeDeviation;
        this.predictiveDiallerOptions.defaultRingTime =
          this.predictiveDiallerDefaultRingTime;
        this.predictiveDiallerOptions.defaultRingTimeDeviation =
          this.predictiveDiallerDefaultRingTimeDeviation;
        this.diallerOptions.predictiveDiallerOptions =
          this.predictiveDiallerOptions;

        const workType = this.workType
          ? this.workTypes.find((tt) => tt.templateName === this.workType)
          : null;
        const taskTemplateId = workType ? workType.templateId : null;
        const campaignId = this.campaignId;
        const dialerType = this.dialerType;
        const diallerOptions = this.diallerOptions;
        const autoAcceptTaskEnabled = this.autoAcceptTaskEnabled;
        this.dialogController.ok({
          campaignId,
          taskTemplateId,
          autoAcceptTaskEnabled,
          dialerType,
          diallerOptions,
          preferredAgentReschedulingEnabled:
            this.preferredAgentReschedulingEnabled,
          preferredAgentReschedulingTimeout: this
            .preferredAgentReschedulingEnabled
            ? preferredAgentReschedulingTimeout
            : 0,
          ignoreDoNotContactList:
            this.ignoreDoNotContactList,
        });
      },
      (error) => {
        logger.info(
          'Validation for creating campaign name unsuccessful.',
          error
        );
      }
    );
  }

  private setValidationRules(): void {
    ValidationRules.customRule(
      'maxCallBounds',
      (maxCalls) => {
        if (maxCalls < 1 || maxCalls > 50) {
          return false;
        }
        return true;
      },
      'Max calls must be between 1 and 50.'
    );

    ValidationRules.customRule(
      'overDialRatio',
      (overDialRatio) => {
        if (overDialRatio < 0 || overDialRatio > 5) {
          return false;
        }
        return true;
      },
      'OverDial ratio must be between 0 and 5.'
    );

    ValidationRules.customRule(
      'predictiveDiallerCallPoolExpiry',
      (predictiveDiallerCallPoolExpiry) =>
        predictiveDiallerCallPoolExpiry > 0 &&
        predictiveDiallerCallPoolExpiry <= 30,
      'Assessment expiry must be between 1 and 30s'
    );

    ValidationRules.customRule(
      'predictiveDiallerCallPoolAssessmentInterval',
      (predictiveDiallerCallPoolAssessmentInterval) =>
        predictiveDiallerCallPoolAssessmentInterval >= 1 &&
        predictiveDiallerCallPoolAssessmentInterval <= 60,
      'Assessment pool interval must be between 1 and 60s'
    );

    ValidationRules.ensure('outboundIVRMaxCalls')
      .required()
      .withMessage('Max calls must be between 1 and 50.')
      .when(() => this.dialerType === 'Outbound IVR')
      .satisfiesRule('maxCallBounds')
      .when(() => this.dialerType === 'Outbound IVR')
      .ensure('outboundIVROverDialRatio')
      .required()
      .withMessage('OverDial ratio must be between 0 and 5.')
      .when(() => this.dialerType === 'Outbound IVR')
      .satisfiesRule('overDialRatio')
      .when(() => this.dialerType === 'Outbound IVR')

      .ensure('predictiveDiallerCallPoolExpiry')
      .required()
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Call Pool Expiry must be between 1 and 30s')
      .then()
      .satisfiesRule('predictiveDiallerCallPoolExpiry')
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Call Pool Expiry must be between 1 and 30s')

      .ensure('predictiveDiallerCallPoolAssessmentInterval')
      .required()
      .withMessage('Assessment interval must be between 1 and 60s')
      .satisfiesRule('predictiveDiallerCallPoolAssessmentInterval')
      .withMessage('Assessment interval must be between 1 and 60s')

      .on(this);
  }
}
