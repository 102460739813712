import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'zailab.common';
import { JoinService } from './join-service';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { ApplicationProperties } from "../../../../_config/application.properties";

const logger = LogManager.getLogger('Join');

@inject(ValidationControllerFactory, Router, EventAggregator, JoinService, ApplicationProperties)
export class Join {

  // View Model
  emailAddress = '';
  firstName = '';
  lastName = '';
  organisationName = '';
  // View Errors
  emailError;
  otherError;
  commsError;
  // Telephone Number Custom Element Model
  availableCountries = '';
  inputTelephoneNumber;
  europeanCountries = ["at", "be", "ee", "fi", "fr", "de", "gr", "ie", "it", "lv", "lt", "lu", "mt", "nl", "pt", "sk", "si", "es"];

  constructor(validationControllerFactory, router, eventAggregator, joinService, applicationProperties) {
    this.router = router;
    this.eventAggregator = eventAggregator;
    this.joinService = joinService;
    this.availableCountries = applicationProperties.availableCountries.join();
    this.inputTelephoneNumber = new TelephoneNumberModel('', '', applicationProperties.currentRegion, true, false);
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate() {
    ValidationRules
      // Validate Email
      .ensure('emailAddress').required().withMessage('Please enter your email.') //
      .then().email().withMessage('Please enter a valid email.') //
      .on(this) //
      // Validate First Name
      .ensure('firstName').required().withMessage('Please enter your first name.') //
      .then().matches(/^[\w'\-,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/).withMessage('Please enter a valid first name.') //
      .on(this) //
      // Validate Last Name
      .ensure('lastName').required().withMessage('Please enter your last name.')
      .then().matches(/^[\w'\-,.][^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/).withMessage('Please enter a valid last name.') //
      .on(this) //
      // Validate Organisation Name
      .ensure('organisationName').required().withMessage('Please enter your company name.') //
      .on(this);
    this.availableCountries = this.availableCountries + ',' + this.europeanCountries.join();
  }

  submit() {
    if (this.submitted) {
      return;
    }
    this.validation.validate() //
      .then((validation) => {
        if (this.inputTelephoneNumber.formattedNumber === '' || !this.inputTelephoneNumber.isNumberValid) {
          // Send an event to the z-phone-number-input component
          this.eventAggregator.publish('PHONE.NUMBER.VALIDATE');
          return;
        }
        if (!validation.valid) {
          return;
        }
        this.emailError = false;
        this.otherError = false;
        this.commsError = false;
        this.submitted = true;
        this.joinService //
          .submit(this.emailAddress, this.organisationName, this.firstName, this.lastName, this.inputTelephoneNumber.number) //
          .then(() => this._handleJoinEmailSent()) //
          .catch(() => this._handleJoinEmailFailed());
      });
  }

  login(_email) {
    let finalRoute = _email ? 'login?email=' + _email : 'login';
    this.router.navigate(finalRoute);
  }

  _handleJoinEmailFailed() {
    this.emailError = true;
    this.submitted = false;
  }

  _handleJoinEmailSent() {
    this.router.navigate('emailsubmitted');
  }
}