import { Router, RouterConfiguration } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { LogManager, PLATFORM } from 'aurelia-framework';

import { CannedResponseService } from './canned-responses-service';
import { CannedResponseModel } from './canned-responses-model';

const logger = LogManager.getLogger('CannedResponses');

@autoinject()
export class CannedResponses {

  private router: Router;
  private cannedResponseService: CannedResponseService;

  constructor(cannedResponseService: CannedResponseService) {
    this.cannedResponseService = cannedResponseService;
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {

    let routeMap: Array<any> = [
      { route: ['', 'list'], /*    */name: 'Canned Response List', /*  */moduleId: PLATFORM.moduleName('./list/canned-responses-list'), /*    */title: 'List' },
      { route: 'add', /*     */name: 'Canned Response Add', /*   */moduleId: PLATFORM.moduleName('./add/canned-responses-add'), /*      */title: 'Add' },
      { route: 'edit/:id', /**/name: 'Canned Response Edit', /*  */moduleId: PLATFORM.moduleName('./edit/canned-responses-edit'), /*    */title: 'Edit' }
    ];
    config.map(routeMap);
    this.router = router;
  }
}