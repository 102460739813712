import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
import { TEAMS_ACTIONS } from './teams.actions';
import { HttpClient } from 'aurelia-http-client';
/*
 */
import { SessionStore, UrlFactory } from 'zailab.common';
import MemberPropertiesModel from "../../../../features/organisation/member/members/member-properties-model";
/*
 */
const logger = LogManager.getLogger('TeamsService');
/*
 */
@inject(Dispatcher, SessionStore, HttpClient, UrlFactory)
export class TeamsService {

  constructor(dispatcher, sessionStore, httpClient, urlFactory) {

    this.dispatcher = dispatcher;
    this.sessionStore = sessionStore;
    this.httpClient = httpClient;
    this.urlFactory = urlFactory;
  }

  retrieveMembers(teamId, role, serviceId) {
    return this.httpClient
      .createRequest(`v1/organisation/me/members`)
      .asGet()
      .withParams({teamId: teamId, role: role, serviceId: serviceId})
      .send()
      .then(
        response => {
          let result = this._mapMembers(response);
          return result;
        })
      .catch(error => {
        return [];
      });
  }

  _mapMembers(response) {
    let members = [];

    if (response) {
      response.forEach(member => {
        members.push(new MemberPropertiesModel(member))
      });
    }

    return members;
  }
}