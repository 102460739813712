import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

import moment from 'moment';
import toastr from 'toastr';
import { AudioFileModel, FetchAudioService, SearchTools } from 'zailab.common';
import { AudioSearchFilter } from '../audio-search-filter';
import { EmptyStateAbstract } from './empty-state-abstract';

const logger = LogManager.getLogger('EmptyAudioAbstract');

@autoinject
export class EmptyAudioAbstract extends EmptyStateAbstract {


  protected audioMessageList: AudioFileModel[] = [];
  protected existingNodeData: ZIAudioPayload;
  protected selectedAudioMessage: AudioFileModel;
  protected preselectedAudioMessage: AudioFileModel = null;
  protected createdItem: AudioFileModel;
  protected isSearching: boolean = false;
  protected currentAudioCount: number = 0;
  protected expectedAudioCount: number = 0;
  protected contentCreatedFlag: boolean = false;
  protected retryTimeout: number;
  protected searchTerm: string;

  constructor(
    protected dialogService: DialogService,
    protected fetchAudioService: FetchAudioService,
    public audioSearchFilter: AudioSearchFilter,
  ) {
    super(dialogService);
    this.audioSearchFilter.onConfirmed(() => {
      this.audioMessageList = this.audioSearchFilter.filter();
    });
  }

  protected detached(): void {
    this.clearRetryTimeout();
  }

  protected handleContentCreated(_contentCreatorResponse: any): void {
    super.handleContentCreated(_contentCreatorResponse);
    this.contentCreatedFlag = true;
    this.expectedAudioCount = this.currentAudioCount + 1;
    this.retrieveAudioFiles();
  }

  protected retrieveAudioFiles(_searchText: string = ''): void {
    this.fetchAudioService.searchAudioFiles(_searchText)
      .then(audioMessages => this.audioFilesRetrieved(audioMessages), this.reportError);
  }

  protected audioFilesRetrieved(_audioFiles: AudioFileModel[]): void {
    this.currentAudioCount = _audioFiles.length;

    if (this.contentCreatedFlag && this.currentAudioCount < this.expectedAudioCount) {
      this.retryTimeout = setTimeout(() => {
        this.retrieveAudioFiles();
      }, 1000);
      return;
    }

    if (this.contentCreatedFlag) {
      this.currentAudioCount = this.expectedAudioCount;
      this.contentCreatedFlag = false;
    }

    this.hasEntries = true;
    this.audioMessageList = _audioFiles;
    this.currentAudioCount = _audioFiles.length;
    this.nodeDialogInitialised = true;

    this.audioMessageList.sort((a: AudioFileModel, b: AudioFileModel) => {
      return parseInt(b.timestamp) - parseInt(a.timestamp);
    });

    this.audioMessageList.forEach(_audio => {
      _audio.audioId = _audio.id;
      _audio.readableTimeStamp = moment(_audio.timestamp).calendar() || "Just now";
      if (!this.contentCreatedFlag && this.preselectedAudioMessage && _audio.id === this.preselectedAudioMessage.audioId) {
        _audio.isSelected = true;
        this.selectedAudioMessage = _audio;
      }
    });

    if (this.contentCreatedFlag) {
      this.audioMessageList[0].isSelected = true;
      this.selectedAudioMessage = this.audioMessageList[0];
      this.contentCreatedFlag = false;
    }

    this.audioSearchFilter.setAllAudioList(this.audioMessageList);
  }

  private clearRetryTimeout(): void {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
  }

  protected toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  protected selectExistingAudioMessage(audioMessage: AudioFileModel): void {
    this.audioMessageList.forEach(file => {
      file.isSelected = false;
    });
    this.selectedAudioMessage = audioMessage;
    audioMessage.isSelected = true;
  }

  protected startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  private reportError(): void {
    toastr.error('Could not retrieve your audio files. Please wait a few moment and try again.');
  }
}