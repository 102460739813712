import {inject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router, Redirect} from 'aurelia-router';

@inject(Router)
export class Password {
  
  constructor(router) {
    
    this.router = router;
  }
  
  configureRouter(config, router) {

    config.map([

      {route: '',                name: 'forgotpassword',  moduleId: PLATFORM.moduleName('./forgotpassword/forgot-password'),            nav: false, title: 'Forgot Password'},
      {route: 'emailsubmitted',  name: 'emailsubmitted',  moduleId: PLATFORM.moduleName('./emailsubmitted/email-submitted'),            nav: false, title: 'Email Submitted'}
    ]);

    this.router = router;
  }
}