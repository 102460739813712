import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('ArrayTools');

export class ArrayTools {

  static containsAll(a1, a2) {

    if (a1.length !== a2.length) {
      return false;
    }

    for (let i1 of a1) {

      if (a2.indexOf(i1) === -1) {
        return false;
      }
    }

    return true;
  }

  static sort(list, key, keyToIgnore) {

    if(!list) return [];

    return list.sort(function(a,b) {

      let _a = a[key];
      let _b = b[key];

      if(!_a || !_b) return 0;
      if(keyToIgnore && (a[keyToIgnore] || b[keyToIgnore])) return 0;

      if (typeof _a === 'number') {
        return (_a > _b) ? 1 : ((_b > _a) ? -1 : 0);
      }
      return (_a.toLowerCase() > _b.toLowerCase()) ? 1 : ((_b.toLowerCase() > _a.toLowerCase()) ? -1 : 0);
    });
  }

  static sortTextAndStringedNumbers(array, key) {
    return array.sort((a, b) => {
      const valueA = isNaN(a[key]) ? a[key] : parseFloat(a[key]);
      const valueB = isNaN(b[key]) ? b[key] : parseFloat(b[key]);
  
      if (!isNaN(valueA) && !isNaN(valueB)) {
        // If both values are numbers, sort numerically
        return valueA - valueB;
      } else if (!isNaN(valueA) && isNaN(valueB)) {
        // If A is a number and B is not, A comes first
        return -1;
      } else if (isNaN(valueA) && !isNaN(valueB)) {
        // If B is a number and A is not, B comes first
        return 1;
      } else {
        // If neither is a number, sort alphabetically
        return a[key].localeCompare(b[key]);
      }
    });
  }
}
