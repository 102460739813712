import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore } from 'zailab.common';
import { PresenceService } from './presence-service';
import { PresenceModel } from './presence-model';

const logger = LogManager.getLogger('Presence');

@inject(PresenceService, SessionStore, EventAggregator)
export class Presence {
  routingStatus;
  selectedPresence = 'Away';
  presences = [];
  hudPosition;
  isPresenceOptionsOpened = false;
  visibilityComponent = 'presence';
  isAgentRole = false;
  oplog;

  constructor(presenceService, sessionStore, eventAggregator) {
    this.presenceService = presenceService;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
  }

  get presenceMsg() {
    if (this.routingStatus && this.routingStatus === 'Ready') {
      return 'To change your presence, switch yourself to "Not Ready" for work.';
    }

    return 'Please select your presence.';
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  activate(hudPosition) {
    const userId = this.user.userId;
    const isAgentRole = this.user.hasAgentRole;

    this.retrieveSelectedPresence(userId);
    this.retrievePresences();
    this.initialiseOplog(userId);

    this.eventAggregator.subscribe('routing.status.updated', data => {
      this.routingStatus = data.routingStatus;
    });
    this.hudPosition = hudPosition;
  }

  retrieveSelectedPresence(userId) {
    this.presenceService.retrieveSelectedPresence(userId).then(
      presenceView => {
        this.selectedPresence = presenceView.presence.toUpperCase();
      },
      error => {
        logger.info('presence > error = ', error);
      }
    );
  }

  retrievePresences() {
    this.presenceService.retrievePresences().then(
      presences => {
        this.presences = presences;
      },
      error => {
        logger.info('presences > error = ', error);
      }
    );
  }

  initialiseOplog(userId) {
    this.presenceService.initialiseOplog(userId).then(oplog => this.subscribeToOplogChanges(oplog));
  }

  subscribeToOplogChanges(oplog) {
    this.oplog = oplog;
    this.oplog.on('insert', data => this.handleOplogChanged(data));
    this.oplog.on('update', data => this.handleOplogChanged(data));
  }

  handleOplogChanged(data) {
    const presenceModel = new PresenceModel(data);
    this.selectedPresence = presenceModel.presence;
  }

  get showToggle() {
    if (this.hudPosition === 'toggle') {
      return true;
    }
  }

  get showIndicator() {
    if (this.hudPosition === 'indicator') {
      return true;
    }
  }

  get showPresence() {
    if (this.hudPosition === 'presenceText') {
      return true;
    }
  }

  get showPresenceOptions() {
    if (this.hudPosition === 'presenceText' && this.isPresenceOptionsOpened) {
      return true;
    }
  }

  changePresence(presence) {
    this.selectedPresence = presence;
    this.isPresenceOptionsOpened = false;
    this.presenceService.changePresence(this.user.userId, presence.toUpperCase());
  }

  togglePresenceOption() {
    let wrapper = document.querySelector('.js-component-visibility-' + this.visibilityComponent);
    let el = wrapper.querySelector('.js-component-container');
    $(el).toggleClass('is-visible');
  }

  detached() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }
}
