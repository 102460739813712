import {inject, LogManager} from 'aurelia-framework';
import {WebSocket} from '../services/websocket';


const logger = LogManager.getLogger('CommandFactory');

@inject(WebSocket)
export class CommandFactory {
  aggregate = '';
  domain = '';
  subDomain = '';
  commandName = '';
  payload = '';
  eventName = null;

  /**
   * @constructor
   * This builds a command that can be sent to the backend.
   * If you are not passing in the websocket then use the aurelia factory to strategy to inject the class
   * @param {object} webSocket an instance of the zailab common websocket, not needed if you use a factory strategy
   * @param {string} subDomain the sub domain of the service that this command is meant for
   * @param {string} domain the domain of the service this command is meant for
   * @param {string} aggregate the root or aggregate this command relates to inside of the domain context
   * @param {string} commandName the name of the command
   * @param {object} payload the json payload that is being sent
   * @param {string} eventName the name of the event
   * */
  constructor(webSocket, subDomain, domain, aggregate, commandName, payload, eventName) {
    this.webSocket = webSocket;
    this.domain = domain;
    this.subDomain = subDomain;
    this.aggregate = aggregate;
    this.commandName = commandName;
    this.payload = payload;
    this.eventName = eventName;
  }

  /**
   * The typeId of the command that is used to uniquely identify it through the system
   * */
  get typeId() {
    return 'com.zailab.' + this.domain + '.' + this.aggregate + '.api.commands.' + this.commandName;
  }

  /**
   * The typeId of the event that is can be subscribed on
   * */
  get eventId() {
    return 'com.zailab.' + this.domain + '.' + this.aggregate + '.api.events.' + this.eventName;
  }

  /**
   * the command message
   * @returns {string} message the content of the command that is sent to the backend
   * */
  get message() {
    let message = {
      feature: this.subDomain,
      name: this.typeId,
      state: this.payload
    };
    return message;
  }

  /**
   * This will publish this instance of the command over the web socket
   * */
  publish() {
    this.webSocket.publish(this.message);
  }

  /**
   * This will publish this instance of the command over the web socket
   * and subscribes to the event specified in the eventName property
   * returns a promise that will resolve when the event is received from the backend
   * @returns Promise that will resolve when eventName is received
   * */
  publishAndSubscribeToEvent() {
    return new Promise((resolve, reject) => {
      if (!this.eventName) {
        reject('No eventName provided for subscription');
      }
      this.webSocket.subscribe({
        name: this.eventId,
        callback: data => {
          this.webSocket.unSubscribe(this.eventId);
          resolve(data);
        }
      });
      this.publish();
    });
  }

}