import { computedFrom, observable } from 'aurelia-binding';
import { customElement, autoinject, bindable, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { ConfirmDialog } from './confirm-dialog';
import { ContactService } from '../../../../../contact/contact-service';

const logger = LogManager.getLogger('VoiceAuthenticator');

@customElement('z-voice-authenticator')
@autoinject()
export class VoiceAuthenticator {
  @bindable({attribute: 'additional-data'}) @observable private additionalData: number;
  @bindable ({attribute: 'is-live'}) isLive: boolean;

  private static STATES = {
    NOT_REGISTERED: 'NotRegistered',
    REGISTERED: 'Registered',
    PARTIALLY_REGISTERED: 'PartiallyRegistered',
    REGISTERED_VALIDATED: 'RegisteredValidated',
    OPTED_OUT: 'OptedOut',
    MATCH: 'Match',
    MISMATCH: 'Mismatch'
  };
  public enabled: boolean = false;
  public state: string = '';
  public callsCount: string;
  public msisdn: string;
  public authFlag: string;

  private getStatusUrl: string;
  private validateUrl: string;
  private deregisterUrl: string;

  constructor(
    private dialogService: DialogService,
    private contactService: ContactService
  ) {}

  public async additionalDataChanged(newValue: { [key: string]: string }): Promise<void> {
    if (newValue) {
      for(let prop in newValue) {
        switch(prop) {
          case 'Req MSISDN': 
            this.msisdn = newValue[prop];
            break;
          case 'vb_get_status':
            if (newValue['vb_enabled']) {
              this.getStatusUrl = newValue[prop];
              this.getRegistrationStatus();
              this.enabled = true;
            }
            break;
          case 'vb_get_calls':
            this.callsCount = newValue[prop];
            break;
          case 'vb_validate':
            this.validateUrl = newValue[prop];
            break;
          case 'vb_deregister':
            this.deregisterUrl = newValue[prop];
            break;
          case 'vb_auth_flag':
            this.authFlag = newValue[prop];
            break;
        }
      }
    }
  }

  private getRegistrationStatus(): void {
    this.contactService
      .fetchFromUrl(this.getStatusUrl)
      .then((data: { status: string, statusData: string, configSetName: string }) => {
        this.state = data.status;
      });
  }

  public validate(): void {
    this.showConfirmationPop()
      .then(() => {
        this.contactService
          .fetchFromUrl(this.validateUrl, null)
          .then(() => this.getRegistrationStatus());
      });
  }

  public deregister(): void {
    this.showConfirmationPop()
      .then(() => {
        this.contactService
          .fetchFromUrl(this.deregisterUrl, null)
          .then(() => this.getRegistrationStatus());
      });
  }

  private showConfirmationPop(): Promise<void> {
    return new Promise(resolve => {
    this.dialogService
      .open({ viewModel: ConfirmDialog, model: {} })
      .whenClosed(dialog => {
        if (!dialog.wasCancelled) {
          resolve();
        }
      });
    });
  }
  
  @computedFrom('state')
  public get isNotRegistered(): boolean {
    return this.state === VoiceAuthenticator.STATES.NOT_REGISTERED || this.state === VoiceAuthenticator.STATES.PARTIALLY_REGISTERED;
  }
  
  @computedFrom('state')
  public get isRegistered(): boolean {
    return this.state === VoiceAuthenticator.STATES.REGISTERED;
  }

  @computedFrom('state')
  public get isRegisteredValidated(): boolean {
    return this.state === VoiceAuthenticator.STATES.REGISTERED_VALIDATED;
  }

  @computedFrom('state')
  public get isOptedOut(): boolean {
    return this.state === VoiceAuthenticator.STATES.OPTED_OUT;
  }

  @computedFrom('authFlag')
  public get match(): boolean {
    return this.authFlag === VoiceAuthenticator.STATES.MATCH;
  }

  @computedFrom('authFlag')
  public get mismatch(): boolean {
    return this.authFlag === VoiceAuthenticator.STATES.MISMATCH;
  }

  @computedFrom('authFlag')
  public get noAuth(): boolean {
    return this.authFlag === '' || this.authFlag === undefined;
  }

  @computedFrom('isRegisteredValidated', 'match', 'mismatch')
  public get error(): boolean {
    return this.isRegisteredValidated && !this.match && !this.mismatch;
  }

  @computedFrom('state')
  public get stateColor(): string {
    if (!this.state) {
      return 'var(--theme-background-default)';
    }
    if (this.isNotRegistered || this.isOptedOut || this.error || this.mismatch) {
        return 'var(--unsuccessful)';
    }
    if (this.isRegistered && !this.isRegisteredValidated) {
      return 'var(--pending)';
    }
    if (this.isRegisteredValidated) {
      return 'var(--successful)';
    }
  }
}
