import { HttpClient } from 'aurelia-http-client';
import { inject, LogManager, Factory } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { CommandFactory, SessionStore, OplogService } from 'zailab.common';
import { DisplayTeamsModel } from './teams-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('TeamsService');
/*
 * */
@inject(HttpClient, EventAggregator, Factory.of(CommandFactory), SessionStore, OplogService)
export class TeamsService {

  constructor(httpClient, eventAggregator, commandFactory, sessionStore, oplogService) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
  }

  retrieveOrganisationTeams(organisationId) {

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest('v1/organisation/me/teams')
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(this._modelCollection(response));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  initialiseTeamsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('member-projector.displayTeamsView'));
    });
  }

  retrieveTeamsStats() {
    let organisationId = this.sessionStore.get.organisation.organisationId;

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/teams/team-widget-view`)
        .asGet()
        .send()
        .then((response) => {

          let _count = 0;

          if (response.teamIds && response.teamIds[0]) {
            _count = response.teamIds.length;
          }
          resolve(_count);
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseStatsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('member-projector.teamWidgetView'));
    });
  }

  /**
   * @private
   * */
  _modelCollection(teamData) {
    let teamList = [];
    teamData.forEach(team => {
      teamList.push(this._modelEntity(team));
    });
    return teamList;
  }

  _modelEntity(team) {
    return new DisplayTeamsModel(team);
  }

  /**
   * Removes a team
   * @fires RemoveTeamCommand
   * to 'command-service-member'
   * @param {string} organisationId the member to remove
   * @param {object} team the member to remove
   * @emits TeamRemovedEvent on completion from the backend
   * */
  removeTeam(organisationId, team) {

    this.httpClient.createRequest(`v1/organisation/me/teams/${team.id}`)
      .asDelete()
      .send()
      .then(response => {}, error => {});
  }

  /**
   * adds a team
   * @fires AddTeamCommand
   * to 'command-service-member'
   * @param {string} organisationId the member to remove
   * @param {string} teamName the member to remove
   * @emits TeamAddedEvent on completion from the backend
   * */
  addTeam(organisationId, teamName) {

    let teamId = uuidv4();
    let commandPayload = {id: teamId, name: teamName};

    this.httpClient.createRequest(`v1/organisation/me/teams`)
      .asPost()
      .withContent(commandPayload)
      .send()
      .then(response => {}, error => {});
  }

}
