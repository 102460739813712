import {inject, LogManager} from 'aurelia-framework';
/*
 * */
const logger = LogManager.getLogger('LoadedCustomAttribute');

/*
 * */
@inject(Element)
export class LoadedCustomAttribute {
    constructor(private element: HTMLElement) {
    }

    public attached(): void {
        let event = new CustomEvent('created', {
            bubbles: true
        });

        this.element.dispatchEvent(event);
    }
}
