import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ConfirmDialog');

@autoinject
export class ConfirmDialog {

  public header = 'Confirm';
  public text = 'Confirm?';

  constructor(
    private dialogController: DialogController
  ) { }

  public activate(model: { header: string, text: string }): void {
    this.header = model.header;
    this.text = model.text;
    this.listenForKeydown();
  }

  public deactivate(): void {
    this.ignoreKeydown();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.dialogController.ok();
  }

  private listenForKeydown(): void {
    window.addEventListener('keydown', this.keyPressedConfirmDialog);
  }

  private ignoreKeydown(): void {
    window.removeEventListener('keydown', this.keyPressedConfirmDialog);
  }

  private keyPressedConfirmDialog = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      this.ok();
    }
    if (event.key === 'Escape') {
      this.cancel();
    }
  }
}

