import {inject, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('SurveyModel');
/*
 * */
export class SurveyModel extends BaseModel {

  surveyId = uuidv4();
  surveyName = '';
  type = 'agentRating';
  description = '';
  outcome = false;
  serviceId = '';
  questions = [];

  constructor(survey) {
    super();
    this.mapProperties(survey);
  }

}
