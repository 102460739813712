import { DialogController, DialogService } from 'aurelia-dialog';
import { inject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
/*
 */
import { v4 as uuidv4 } from 'uuid';
/*
 */
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { InteractionAnalyticsConfiguration } from '../../interaction-analytics-configuration-model';
import { VerintAnalyticsVoiceWorktypeConfigurationDialog } from "./verint-speech-analytics-voice-worktype-configuration";
import OrganisationService from '../../organisation-service';

/*
 */
const logger = LogManager.getLogger('VerintAnalyticsConfigurationDialog');
/*
 */
@inject(DialogService, DialogController, ValidationControllerFactory, OrganisationService, SessionStore)
export class VerintSpeechAnalyticsConfigurationDialog {

  workforceManagementId;
  dialogHeader = "Verint Speech Analytics Configurations";
  verintSpeechInteractionAnalyticsConfiguration = new InteractionAnalyticsConfiguration();
  s3RoleARN;
  s3RoleSessionName;
  s3RoleExternalId;
  s3BucketARN;
  s3BucketRegion;
  enabled;
  enabledSpeechWorkTypes;

  constructor(dialogService, dialogController, validationControllerFactory, organisationService, sessionStore) {
    this.dialogController = dialogController;
    this.dialogService = dialogService;
    this.organisationService = organisationService;
    this.sessionStore = sessionStore;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(providerConfig) {
    this.workforceManagementId = providerConfig.workforceManagementId
    if (providerConfig.properties) {
      this.s3BucketARN = providerConfig.properties.s3BucketARN;
      this.s3BucketRegion = providerConfig.properties.s3BucketRegion;
      this.s3RoleARN = providerConfig.properties.s3RoleARN;
      this.s3RoleSessionName = providerConfig.properties.s3RoleSessionName;
      this.s3RoleExternalId = providerConfig.properties.s3RoleExternalId;
    }
    this.enabled = providerConfig.enabled;
    this.verintSpeechInteractionAnalyticsConfiguration.enabledWorkTypes = providerConfig.enabledWorkTypes ? providerConfig.enabledWorkTypes : [];
    this.verintSpeechInteractionAnalyticsConfiguration.allWorkTypesEnabled = providerConfig.allWorkTypesEnabled !== undefined ? providerConfig.allWorkTypesEnabled : true;
    logger.debug("Activated with speechConfig ", this.verintSpeechInteractionAnalyticsConfiguration);
    ValidationRules
      .ensure('s3RoleARN').required()
      .ensure('s3RoleSessionName').required()
      .ensure('s3RoleExternalId').required()
      .ensure('s3BucketARN').required()
      .ensure('s3BucketRegion').required()
      .ensure('enabledWorkTypes').required()
      .on(this);
  }

  selectEnabledVoiceWorkTypes() {
    this.dialogService.open({ 
      viewModel: VerintAnalyticsVoiceWorktypeConfigurationDialog, 
      model:  {
        allWorkTypesEnabled: this.verintSpeechInteractionAnalyticsConfiguration.allWorkTypesEnabled,
        enabledWorkTypes: this.verintSpeechInteractionAnalyticsConfiguration.enabledWorkTypes
      }
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.enabledSpeechWorkTypes = response.output.enabledWorkTypes;
        this.verintSpeechInteractionAnalyticsConfiguration.allWorkTypesEnabled = response.output.allWorkTypesEnabled;
      }
      logger.debug('Response: ', this.verintSpeechInteractionAnalyticsConfiguration);
    });
  }


  cancel() {
    this.dialogController.cancel();
  }

  save() {
    this.loading = true;
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        this.loading = false;
        return;
      }
      this.verintSpeechInteractionAnalyticsConfiguration.properties.s3RoleARN = this.s3RoleARN;
      this.verintSpeechInteractionAnalyticsConfiguration.properties.s3RoleSessionName = this.s3RoleSessionName;
      this.verintSpeechInteractionAnalyticsConfiguration.properties.s3RoleExternalId = this.s3RoleExternalId;
      this.verintSpeechInteractionAnalyticsConfiguration.properties.s3BucketARN = this.s3BucketARN;
      this.verintSpeechInteractionAnalyticsConfiguration.properties.s3BucketRegion = this.s3BucketRegion;
      this.verintSpeechInteractionAnalyticsConfiguration.name = 'Verint';
      this.verintSpeechInteractionAnalyticsConfiguration.type = 'SPEECH';
      this.verintSpeechInteractionAnalyticsConfiguration.enabled = !!this.enabled;
      if (this.enabledSpeechWorkTypes) {
        this.verintSpeechInteractionAnalyticsConfiguration.enabledWorkTypes = 
            this.filterWorkTypes(this.enabledSpeechWorkTypes);
      }

      logger.debug('Sending Voice Interaction Analytics', this.verintSpeechInteractionAnalyticsConfiguration);
      
      this.organisationService
        .setSpeechAnalytics(this.sessionStore.get.organisation.organisationId, this.workforceManagementId, this.verintSpeechInteractionAnalyticsConfiguration)
        .then(() => {
          this.loading = false;
          this.dialogController.ok(this.verintSpeechInteractionAnalyticsConfiguration);
        })
        .catch(error => {
          logger.error('Unable to set analytics configuration ', error);
        });
    });
  }

  filterWorkTypes(enabledWorkTypes) {
    logger.debug('Checking enabled worktypes', enabledWorkTypes);
    if (!enabledWorkTypes) {
      return [];
    }
    let filteredEnabledWorkTypes = [];
    for (let key of Object.keys(enabledWorkTypes)) {
        if (enabledWorkTypes[key]) {
          filteredEnabledWorkTypes.push(key);
        }
    }
    logger.debug('Returning filtered worktypes', filteredEnabledWorkTypes);

    return filteredEnabledWorkTypes;
  }

  toggleEnabled() {
    this.enabled = !this.enabled;
  }
}

