import { computedFrom } from 'aurelia-framework';

export class CannedResponse {

  public id: string;
  public organisationId: string;
  public name: string;
  public subject: string;
  public channel: string;
  public templates: Array<Template>;

  public equals(other: CannedResponse): boolean {
    return this.id === other.id;
  }
}

export class CannedResponseModel extends CannedResponse {

  public canDelete: boolean = true;

  public static from(other: CannedResponse): CannedResponseModel {
    return Object.assign(new CannedResponseModel(), other);
  }

  @computedFrom('channel')
  public get icon(): string {
    return this.channel.toLowerCase();
  }

  @computedFrom('templates', 'templates.length')
  public get body(): string {
    if (!this.templates && !this.templates.length) {
      return '';
    }
    return this.htmlTemplate ? this.htmlTemplate.body : this.plainTemplate ? this.plainTemplate.body : '';
  }

  public toView(): CannedResponseView {
    return CannedResponseView.from(this);
  }

  public toPayload(): CannedResponse {
    return CannedResponseView.from(this).toPayload();
  }

  private get plainTemplate(): Template {
    return this.findTemplateByContentType(ResponseTemplateContentType.PLAIN);
  }

  private get htmlTemplate(): Template {
    return this.findTemplateByContentType(ResponseTemplateContentType.HTML);
  }

  private findTemplateByContentType(contentType: ResponseTemplateContentType): Template {
    return this.templates.find((template) => template.contentType === contentType);
  }
}

class ResponseTemplateContentType {

  static PLAIN: string = 'PLAIN';
  static HTML: string = 'HTML';
}

export class Template {

  public contentType: string;
  public body: string;
}

export class CannedResponseView extends CannedResponse {

  public channel: string = CHANNELS.EMAIL;
  public htmlBody: string;
  public textBody: string;

  @computedFrom('htmlBody', 'textBody')
  public get body(): string {
    return this.isChannelEmail() ? this.htmlBody : this.textBody;
  }

  @computedFrom('textBody')
  public get isBodyTextBlank(): boolean {
    return !this.textBody;
  }

  public toPayload(): CannedResponse {
    const payload: CannedResponse = new CannedResponse();
    payload.id = this.id;
    payload.organisationId = this.organisationId;
    payload.name = this.name;
    payload.channel = this.channel;
    if (this.isChannelEmail()) {
      payload.subject = this.subject;
    }
    payload.templates = [];
    payload.templates.push(this.toTemplate());
    return payload;
  }

  public isEqual(other: CannedResponseView): boolean {
    if (this.id !== other.id ||
      this.organisationId !== other.organisationId ||
      this.name !== other.name ||
      this.subject !== other.subject ||
      this.body !== other.body) {
      return false;
    }
    return true;
  }

  public isValid(): boolean {
    return !!this.name && !!this.body;
  }

  private isChannelEmail(): boolean {
    return this.channel === CHANNELS.EMAIL;
  }

  private toTemplate(): Template {
    const template: Template = new Template();
    template.contentType = this.isChannelEmail() ? ResponseTemplateContentType.HTML : ResponseTemplateContentType.PLAIN;
    template.body = this.body;
    return template;
  }

  public static from(model: CannedResponseModel): CannedResponseView {
    const view = new CannedResponseView();
    view.id = model.id;
    view.organisationId = model.organisationId;
    view.name = model.name;
    view.channel = model.channel;
    view.subject = model.subject;
    if (view.isChannelEmail()) {
      view.htmlBody = model.body;
    } else {
      view.textBody = model.body;
    }
    return view;
  }
}

export const CHANNELS = {
  SMS: 'SMS',
  EMAIL: 'EMAIL'
}

export const EMAIL_EDITOR_OPTIONS = {
  formats: [
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'font',
    'color',
    'background',
    'align'
  ],
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'bullet' }, { list: 'ordered' }],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean']
    ]
  }
};