import { LogManager, autoinject, inject } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";

import { BootstrapFormRenderer } from '../../../../../../../zailab.common';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { Validation } from '../../../../../../../zailab.validation';

const logger = LogManager.getLogger('Page1EditAssistantApi');

@autoinject
export class Page1EditAssistantApi {
  public name: string;
  public description: string;
  public validation: ValidationController;
  public data: { name: string; description: string } = { name: '', description: '' };
  
  private wizard: any;
  
  public Page1EditAssistantApi: any;
  static Page1EditAssistantApi: any;

  constructor(
    private element: Element,
    private eventAggregator: EventAggregator,
    private validationControllerFactory: ValidationControllerFactory
  ) { 
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.initiateValidation();
    this.extractWizardData();
    this.validate();

    this.data = this.wizard.data || { name: '', description: '' };
    this.name = this.data.name;
    this.description = this.data.description;
}

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  private initWizard(wizard: any): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.name = this.wizard.data.name;
    this.description = this.wizard.data.description;
  }

  private updateWizardData(): void {
    this.wizard.data.name = this.name;
    this.wizard.data.description = this.description;
    const currentData = this.data;

    this.wizard.data = {
        ...this.wizard.data,
        ...currentData
    };
    
  }

  private initiateValidation(): void {
    ValidationRules
      .ensure('name')
      .required()
      .matches(/^[a-zA-Z0-9_-]+$/)
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')

      .ensure('description')
      .required()
      .withMessage('Please enter a description.')
      .on(this);
  }

  private validate(): void {
    if (!this.name || !this.description) {
      this.wizard.step.incomplete();
      return;
    }
    this.wizard.step.complete({ name: this.name, description: this.description });
  }

  private nextStep(): void {
    this.wizard.continue();
  }
}
