import { inject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
*/
import { SessionStore, MESSAGE_EVENTS } from 'zailab.common';
import { NumbersService } from '../numbers-service';
/*
*/
const logger = LogManager.getLogger('ReturnNumberDialog');
/*
*/
@inject(DialogService, DialogController, EventAggregator, NumbersService, SessionStore)
export class ReturnNumberDialog {

  numberObject = {};

  constructor(dialogService, dialogController, eventAggregator, numbersService, sessionStore) {
    this.dialogService = dialogService;
    this.dialogController = dialogController;
    this.eventAggregator = eventAggregator;
    this.numbersService = numbersService;
    this.sessionStore = sessionStore;
  }

  activate(number) {
    this.numberObject = number;
  }

  confirm() {
    let numberId = this.numberObject.numberId;
    let country = 'ZA';
    let numberType = this.numberObject.numberType.toUpperCase();
    let provider = this.numberObject.provider;
    let externalReference = this.numberObject.externalReference;
    let telephoneNumber = this.numberObject.number;
    this.numbersService.returnNumber(numberId, country, numberType, provider, externalReference, telephoneNumber)
      .then(() => {
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Number returned.');
        this.dialogController.ok();
      })
      .catch(() => {
        this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'Returning number failed.');
      });
  }

  cancel() {
    this.dialogController.close();
  }
}
