import { LogManager, inject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/**
 */
import { SessionStore } from 'zailab.common';
/**
 */
import { AccountService } from '../account-service';
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';
/**
 */
const logger = LogManager.getLogger('Interaction Logs Widget');
/**
 */
@inject(Router, SessionStore, AccountService, FeatureFlagService)
export class AccountDashboardWidget {

  availableBalance;
  periodUsage;
  balanceDue;
  displayAccount = false;

  constructor(router, sessionStore, accountService, featureFlagService) {

    this.router = router;
    this.sessionStore = sessionStore;
    this.accountService = accountService;
    this.featureFlagService = featureFlagService;
  }

  activate() {

    this.featureFlagService.isEnabled('fixed-pricing').then((enabled) => {
      if (!enabled) {

        this.displayAccount = true;

        this.trySetUpOplog();

        this.accountService.retrieveAccountStats().then((response) => {

          this.availableBalance = response.available.amount;
          this.periodUsage = response.usage.amount;
          this.balanceDue = response.due.amount;
        }, (error) => {
          logger.info('Could not retrieve Account Stats.', error);
        });
      }
    });
  }

  trySetUpOplog() {
    this.waitForAccountId().then((accountId) => {
      this.setUpOplog(accountId);
    }, () => {
      this.trySetUpOplog();
    });
  }

  waitForAccountId() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const account = this.sessionStore.get.account;
        account && account.accountId ? resolve(account.accountId) : reject();
      }, 10);
    });
  }

  setUpOplog(accountId) {

    this.accountService.initialiseStatsOplog(accountId).then((oplog) => {

      this.oplog = oplog;

      this.oplog.on('update', (response) => {
        this.availableBalance = response.available ? response.available.amount : this.availableBalance;
        this.periodUsage = response.usage ? response.usage.amount : this.periodUsage;
        this.balanceDue = response.due ? response.due.amount : this.balanceDue;
      });
    });
  }

  unbind() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  @computedFrom('sessionStore.get.account')
  get account() {
    return this.sessionStore.get.account;
  }

  @computedFrom('account')
  get balanceStatus() {
    if (this.account && !this.account.isTrialAccount) {
      if (this.account.available.amount > 0) {
        return 'green';
      }
      return 'red';
    }
    return '';
  }

  @computedFrom('account.isTrialAccount')
  get actions() {

    let _actions = ['overview', 'buy credit', 'view usage'];

    if (this.account && !this.account.isTrialAccount) {
      _actions.push('buy numbers');
      _actions.push('company information');
      return _actions;
    }

    return _actions;
  }

  @computedFrom('account.currencySymbol')
  get currencySymbol() {
    return this.account ? this.account.currencySymbol : '';
  }

  navigate(tab) {
    this.router.navigate('account?tab=' + tab);
  }
}