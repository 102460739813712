import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {MailboxService} from 'zailab.common';

const logger = LogManager.getLogger('AddMemberDialog');

@inject(DialogController, MailboxService)
export class AddMembers {

  private availableMembers: Array<ZIMailboxRecipient> = [];
  private assignedMembers: Array<ZIMailboxRecipient> = [];
  private wizard: object;
  private mailboxId: string;
  private mailboxExists: boolean = false;
  private searchName: string = '';
  private pageNumber: number = 0;
  private pageSize: number = 6;
  public noMoreMembers: boolean = false;

  constructor(private dialogController: DialogController, private mailboxService: MailboxService) {

  }

  public activate(wizard: object): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.validate();
  }

  attached(): void {
  }

  private initWizard(wizard: object): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.mailboxId = this.wizard.data.mailboxId || null;
    if (this.mailboxId) {
      this.mailboxExists = true;
    }
    this.assignedMembers = this.wizard.data.recipients || [];
    this.getMembers();
  }

  private getMembers(): void {
    this.noMoreMembers = false;
    this.mailboxService
      .getAllRecipientsForOrg(this.searchName, this.pageNumber, this.pageSize)
      .then(members => {
        if (this.assignedMembers.length > 0) {
          let availableMembers = members.filter((_member) => {
            return !this.assignedMembers.find(_assignedMember => _member.id === _assignedMember.id);
          });

          if (this.pageNumber >= 1) {
            if (!availableMembers || availableMembers.length === 0) {
              this.noMoreMembers = true;
            }
            this.availableMembers = [].concat(this.availableMembers, availableMembers);
          } else {
            this.availableMembers = availableMembers;
          }
        } else if (this.pageNumber === 0) {
          this.availableMembers = members;
        } else if (!members || members.length === 0) {
          this.noMoreMembers = true;
        } else {
          let availableMembers = members.filter((_member) => {
            let itemFound = !this.assignedMembers.find(_assignedMember => _member.id === _assignedMember.id);
            return itemFound;
          });
          this.availableMembers = [].concat(this.availableMembers, availableMembers);
        }
      });
  }

  public searchMembers(): void {
    this.pageNumber = 0;
    this.getMembers();
  }

  public showMore(): void {
    this.pageNumber += 1;
    this.getMembers();
  }

  private updateWizardData(): void {
    this.wizard.data.recipients = this.assignedMembers;
  }

  private validate(): void {

    if (this.assignedMembers.length === 0) {
      this.wizard.step.incomplete();
      return;
    }

    this.wizard.step.complete({recipients: this.assignedMembers});
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  // Methods called from view

  private addMemberToMailbox(_index: number, _member: ZIMailboxRecipient): void {
    this.availableMembers.splice(_index, 1);
    if (!_member.recipientId) {
      this.mailboxService.getRecipientIdByMemberId(_member.id).then(_result => {
        _member.recipientId = _result;
        this.assignedMembers.push(_member);
        this.triggerAddMemberCommand(_member);
        this.validate();
      });
    } else {
      this.assignedMembers.push(_member);
      this.triggerAddMemberCommand(_member);
      this.validate();
    }
  }

  private removeMemberFromMailbox(_index: number, _member: ZIMailboxRecipient): void {
    this.assignedMembers.splice(_index, 1);
    this.availableMembers.push(_member);
    this.triggerRemoveMemberCommand(_member);
    this.validate();
  }

  private triggerAddMemberCommand(_member: ZIMailboxRecipient): void {
    if (!this.mailboxExists) {
      return
    }
    let _payload: ZIMailboxRecipientPayload = {
      recipientId: _member.recipientId.recipientId.recipientId,
      mailboxId: this.mailboxId
    };
    this.mailboxService.addMemberToMailbox(_payload).then(_result => {

    });
  }

  private triggerRemoveMemberCommand(_member: ZIMailboxRecipient): void {
    if (!this.mailboxExists) {
      return
    }
    let _payload: ZIMailboxRecipientPayload = {
      recipientId: _member.recipientId,
      mailboxId: this.mailboxId
    };
    this.mailboxService.removeMemberFromMailbox(_payload).then(_result => {

    });
  }
}