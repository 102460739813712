// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';

import './view-score-dialog.scss';

const logger = LogManager.getLogger('ViewAgentMatchingScoreDialog');

@autoinject()
export class ViewAgentMatchingScoreDialog {
  public loading = false;
  public agentRoutingScore;

  constructor(private dialogController: DialogController) {}

  public async activate(data: any): Promise<void> {
    this.agentRoutingScore = data;
  }

  public toggleExpand(section, index) {
    const sectionEl = `#section_${index}`;
    if (!section.expanded) {
      this.toggleAccordion(sectionEl, true);
    } else {
      this.toggleAccordion(sectionEl, false);
    }

    section.expanded = !section.expanded;
  }

  private toggleAccordion(elementId, expand) {
    const childHeight = $(elementId).find('ul:first').height();

    if (expand) {
      $(elementId).height(childHeight);

      setTimeout(() => {
        $(elementId).height('auto');
      }, 200);

      return;
    }

    $(elementId).height(childHeight);
    $(elementId).height(0);
  }

  public close(): void {
    this.dialogController.cancel();
  }
}
