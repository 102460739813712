import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { AbstractList, List } from 'zailab.abstract';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { InHouseWFMService } from '../in-house-wfm-service';
import { ConfigureInhouseWFMDialog } from '../configure-in-house-wfm-dialog/configure-in-house-wfm-dialog';

const logger = LogManager.getLogger('InHouseWFMList');

@autoinject()
export class InHouseWFMList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders = 0;
  public container: any;

  public loading: boolean;
  public widgets: any = [];

  private config = {
    name: 'Config',
    config: null,
    icon: 'calendar-cogwheel'
  };
  private shiftTemplate = {
    name: 'Shift Templates',
    icon: 'file-checklist'
  };
  private shifts = {
    name: 'Shifts',
    icon: 'clock'
  };
  
  public actions = [{
    action: 'view',
    label: 'View',
  }];

  constructor(
    private dialogService: DialogService,
    private inHouseWFMService: InHouseWFMService,
    private router: Router,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.setUpList();
  }

  private async setUpList(): Promise<void> {
    super.showLoader();

    this.config.config = await this.inHouseWFMService
      .retrieveConfig()
      .catch((e) =>
        console.warn(' > Failed to retrieve shift templates due to ', e)
      );
    super.hideLoader();

    if (!this.config.config) {
      this.openConfigDialog(null);
    }

    this.widgets = [this.config, this.shiftTemplate, this.shifts];
    this.generatePlaceholders();
  }

  public selectWidget(widget: any): void {
    if (widget.name === 'Config') {
      this.openConfigDialog(widget);
    } else if (widget.name === 'Shift Templates') {
      this.router.navigate('shift-templates');
    } else if (widget.name === 'Shifts') {
      this.router.navigate('shifts');
    }
  }

  public openConfigDialog(config: any): void {
    this.dialogService
      .open({
        viewModel: ConfigureInhouseWFMDialog,
        model: config,
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return;
        }
        const configWidget = this.widgets.find(
          (widget) => widget.name === this.config.name
        );
        if (configWidget) {
          configWidget.config = { ...response.output };
        }
      });
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.widgets.length,
      5,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }

  public delete(): void {
    const item = this.itemList.items.find((_item) => _item.isDeleting);
    if (!item) {
      return;
    }

    super.showLoader();
    this.inHouseWFMService
      .deleteShiftTemplates(item.id)
      .then(() => {
        super.hideLoader();
        this.itemList.items.splice(this.itemList.items.indexOf(item), 1);
        this.generatePlaceholders();
      })
      .catch((e) => {
        super.hideLoader();
        console.warn(' > Failed to remove shift template due to', e);
      });
  }
}
