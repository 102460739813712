import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { SessionStore, WebSocket } from 'zailab.common';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('LogoutService');

@inject(HttpClient, SessionStore, WebSocket)
export class LogoutService {
  
  constructor(httpClient, sessionStore, webSocket) {
    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.webSocket = webSocket;
  }

  logout(passportId) {
    return new Promise(resolve => {
      this.webSocket.publish({
        name: 'DeregisterOperationLog',
        state: {
          nameSpace: '',
          operation: '',
          keyField: '',
          keyValue: ''
        }
      });

      this.httpClient.createRequest(`v1/user/passports/${passportId}/logout`)
        .asPut()
        .withContent({})
        .send()
        .then(() => resolve());

      this.httpClient.configure(req => {
        req.withHeader('Authorization', '');
      });
    });
  }
}
