import { autoinject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SearchTools, WebSocket } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { EmergencyAnnouncementService } from '../emergency-announcement-service';
import { PlaceholderService } from '../../../../_common/services/placeholder-service';
import { AddEmergencyAnnouncementDialog } from '../add/add-emergency-announcement-dialog';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
import { EditEmergencyAnnouncementDialog } from '../edit/edit-emergency-announcement-dialog';

let logger = LogManager.getLogger('EmergencyAnnouncementList');

@autoinject
export class EmergencyAnnouncementList extends AbstractList {

  protected isDeleting: boolean = false;
  protected oplog: ZIOplog;

  private actions = [{
    action: 'edit',
    label: 'Edit',
    icon: 'edit'
  }];

  constructor(
    protected element: any,
    private dialogService: DialogService,
    private emergencyAnnouncementService: EmergencyAnnouncementService,
    protected eventAggregator: EventAggregator,
    private webSocket: WebSocket
  ) {
    super(eventAggregator);
  }

  protected activate(): void {
    super.activate();
    this.registerToDeletedEvents();
  }

  protected attached(): void {
    super.attached();
  }

  protected deactivate(): void {
    this.unsubscribeFromDeletedEvent();
  }

  protected detached(): void {
    super.detached();
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
    this.itemList.items.forEach(item => {
      item.isDeleting = false;
    });

  }

  protected subscribeToOplog(): void {
    this.emergencyAnnouncementService.initialiseOplog().then(oplog => {
      this.oplog = oplog;

      this.oplog.on('insert', () => this.retrieveList());
      this.oplog.on('update', () => this.retrieveList());
      this.oplog.on('delete', () => this.retrieveList());
    });
  }

  public deleteEmergencyAnnouncements(): void {

    let listToDelete = [];

    // @ts-ignore
    this.itemList.items.forEach(item => {
      if (item.isDeleting) {
        listToDelete.push(item);
      }
    });

    this.deleteItems(listToDelete);
    this.hideLoader();

    // @ts-ignore
    this.itemList.items.forEach(item => item.isDeleting = false);
    this.toggleDelete();
  }

  public select(_item: ZEmergencyAnnouncement): void {
    if (_item.assignedToFlow) {
      return;
    }
    _item.isDeleting = !_item.isDeleting;
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.emergencyAnnouncementService
      .retrieveEmergencyAnnouncements()
      .then((data: { emergencyMessages: ZEmergencyAnnouncement[]}) => {
        this.setupListConfig(data.emergencyMessages);
        this.setupPlaceholders(data.emergencyMessages);
        super.hideLoader();
      })
      .catch(error => {
        logger.info('WARN >  Could not retrieve and list mailboxes > error =', error);
        super.hideLoader();
      }
    );
  }

  protected setupListConfig(emergencyAnnouncements: ZEmergencyAnnouncement[]): void {
    super.setupListConfig(emergencyAnnouncements);
    this.itemList = List.Builder()
      .items(emergencyAnnouncements)
      .build();

    this.createActionsList();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }
  
  private setupPlaceholders(emergencyAnnouncements: any[]): void {
    this.placeholderService = new PlaceholderService(this.container, emergencyAnnouncements.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  private createActionsList(): void {
    this.itemList.items.forEach(item => {
      item.actions = this.actions;
    });
  }

  private edit(emergencyAnnouncement: ZEmergencyAnnouncement): void {
    this.showLoader();
    this.dialogService
      .open({viewModel: EditEmergencyAnnouncementDialog, model: emergencyAnnouncement})
      .whenClosed((response: any) => {
        this.hideLoader();
      });
  }

  private toggleActiveState(event: MouseEvent, item: ZEmergencyAnnouncement): void {
    if (this.isDeleting) {
      return;
    }
    event.stopImmediatePropagation();
    item.active = !item.active;

    if (item.active) {
      this.emergencyAnnouncementService
        .activateEmergencyAnnouncement(item.emergencyMessageId);
    } else {
      this.emergencyAnnouncementService
        .deactivateEmergencyAnnouncement(item.emergencyMessageId);
    }
  }

  private registerToDeletedEvents(): void {
    this.webSocket.subscribe({
      name: 'com.zailab.interaction.emergencymessage.api.events.EmergencyMessageDeletedEvent',
      callback: (payload: { state: { emergencyMessageId: string; } }) => {
        this.itemList.items = this.itemList.items.filter((item: ZEmergencyAnnouncement) => item.emergencyMessageId !== payload.state.emergencyMessageId);
      }
    });
  }

  public unsubscribeFromDeletedEvent(): void {
    this.webSocket.unSubscribe('com.zailab.interaction.emergencymessage.api.events.EmergencyMessageDeletedEvent');
  }

  private openCreateDialog(action: string, emergencyAnnouncement?: any): void {
    this.showLoader();
    this.dialogService
      .open({viewModel: AddEmergencyAnnouncementDialog, model: emergencyAnnouncement})
      .whenClosed((response: any) => {
        this.hideLoader();
      });
  }

  protected toggleSearch():void {
    super.toggleSearch();
  }

  public delete(emergencyAnnouncement: ZEmergencyAnnouncement): void {
    this.emergencyAnnouncementService
      .deleteEmergencyAnnouncement(emergencyAnnouncement.emergencyMessageId)
      .then(() => {
        this.itemList.items = this.itemList.items.filter((item: any) => item.emergencyMessageId !== emergencyAnnouncement.emergencyMessageId);
        this.setupPlaceholders(this.itemList.items);
        logger.info(`Emergency Announcement with id ${emergencyAnnouncement.emergencyMessageId} has been deleted`)
      });
  }

  // Override methods

  protected startsWithMatch(searchExpression: string, value: any, searchParam: string): any {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: any, searchParam: string): any {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
