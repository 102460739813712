import { LogManager, customElement, bindable } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZButton');
/*
 */
@customElement('z-button')
export class ZButton {

  @bindable location;
  @bindable size;
  @bindable active = true;
  @bindable nomargins;
  @bindable color;

}
