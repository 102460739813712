import { autoinject, LogManager } from "aurelia-framework";
import { ValidationController, ValidationControllerFactory, validateTrigger, ValidationRules } from "aurelia-validation";
import { ObserverLocator } from 'aurelia-binding';

import { ContactFieldsListModel } from "./contact-fields-list-model";
import { BootstrapFormRenderer } from "../../../../../_common/renderers/bootstrap-form-renderer";

const logger = LogManager.getLogger('ContactFieldsListRules');

@autoinject
export class ContactFieldsListRules {

  private contactFieldsList: ContactFieldsListModel;
  private validation: ValidationController;
  private contactFieldUniqueName: boolean = false;

  constructor(
    private validationControllerFactory: ValidationControllerFactory,
    private observerLocator: ObserverLocator
  ) { }

  public init(contactFieldsList: ContactFieldsListModel): void {
    this.contactFieldsList = contactFieldsList;
    this.initValidation();
    this.initObservers();
  }

  public close(): void {
    this.observerLocator.getObserver(this, 'name').unsubscribe(() => { });
    this.observerLocator.getObserver(this, 'type').unsubscribe(() => { });
    this.observerLocator.getObserver(this, 'currentChoice').unsubscribe(() => { });
    this.observerLocator.getArrayObserver(this.contactFieldsList.dropdownChoices).unsubscribe(() => { });
  }

  private initValidation(): void {
    this.validation = this.validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let field of this.contactFieldsList.contactFields) {
          if (field.name.toLowerCase() === value.toLowerCase() && !field.deleted) {
            this.contactFieldUniqueName = false;
            return false;
          }
        }
        this.contactFieldUniqueName = true;
        return true;
      }, null);
    ValidationRules
      .customRule('specialCharacters', (value) => {
        var format = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]/;
        if (format.test(value)) {
          return false;
        }
        this.contactFieldUniqueName = true;
        return true;
      }, null);
    ValidationRules
      .customRule('dropdownChoicesRequired', (value) => {
        return !!this.contactFieldsList.currentChoice || this.contactFieldsList.dropdownChoices.length > 0;
      }, null);
    ValidationRules
      .customRule('30CharactersOrLess', (value) => {
        return !value || value.length <= 30;
      }, null);
    ValidationRules
      .customRule('startsWithNonNumeric', (value) => {
        return value && value.length > 0 && !(/[0-9]/.test(value[0]));
      }, null);
    ValidationRules
      .ensure('name')
      /**/.required().withMessage('Please enter field name.')
      /**/.then()
      /**//**/.satisfiesRule('uniqueName').withMessage('Please enter a unique field name.')
      /**//**/.satisfiesRule('specialCharacters').withMessage('Special characters are not allowed.')
      /**//**/.satisfiesRule('30CharactersOrLess').withMessage('Cannot exceed 30 characters.')
      /**//**/.satisfiesRule('startsWithNonNumeric').withMessage('Cannot start with numeric value.')
      .ensure('type')
      /**/.required().withMessage('Please select a type.')
      .ensure('currentChoice')
      /**/.satisfiesRule('specialCharacters').withMessage('Special characters are not allowed.')
      /**/.satisfiesRule('30CharactersOrLess').withMessage('Cannot exceed 30 characters.')
      /**/.satisfiesRule('dropdownChoicesRequired').withMessage('Please add at least 1 dropdown choice.')
      /**//**/.when((obj: ContactFieldsListModel) => obj.type && obj.type.name === 'DROPDOWN')
      .on(this.contactFieldsList);
  }

  private initObservers(): void {
    this.observerLocator
      .getObserver(this.contactFieldsList, 'name')
      .subscribe(() => this.validateVariableChanges());
    this.observerLocator
      .getObserver(this.contactFieldsList, 'type')
      .subscribe(() => { this.validateVariableChanges() });
    this.observerLocator
      .getObserver(this.contactFieldsList, 'currentChoice')
      .subscribe(() => { this.validateCurrentChoice() });
    this.observerLocator
      .getArrayObserver(this.contactFieldsList.dropdownChoices)
      .subscribe(() => { this.validateCurrentChoice() });
  }

  private validateVariableChanges(): void {
    this.validation.validate()
      .then(validation => {
        const isTypeValuesValid = (this.contactFieldsList.type && this.contactFieldsList.type.name === 'TEXTBOX') || (this.contactFieldsList.isValidChoice && !this.contactFieldsList.currentChoice);
        if (validation.valid && this.hasUniqueName() && isTypeValuesValid) {
          this.contactFieldsList.isValid = true;
        } else {
          this.contactFieldsList.isValid = false;
        }
      })
      .catch(e => logger.error('validateVariableChanges :: e=', e));
  }

  private validateCurrentChoice(): void {
    this.validation.validate({ object: this, propertyName: 'currentChoice' })
      .then(validation => {
        this.contactFieldsList.isValidChoice = validation.valid;
        this.validateVariableChanges();
      })
      .catch(e => logger.error('validateCurrentChoice :: error=', e));
  }

  private hasUniqueName(): boolean {
    return this.contactFieldsList.name && this.contactFieldUniqueName;
  }
}