import { bindable, containerless } from 'aurelia-framework';
import { inject } from 'aurelia-dependency-injection';

import { Event } from 'zailab.common';

import './date-slider.scss';

@inject(Element)
@containerless
export class DateSlider {
  @bindable public values;
  @bindable({ attribute: 'selected-date' }) public selectedDate: string;
  @bindable({ attribute: 'field-name' }) public fieldName: string;
  @bindable({ attribute: 'no-margin' }) public noMargin?: boolean;
  @bindable({ attribute: 'select-today' }) public selectValue: () => void;
  @bindable public background = '';
  public modifiedValues;

  public dateWrapper: HTMLElement;
  public disableScroll;
  private step: number;
  private todayValue: string;

  constructor(private element: Element) {}

  public bind(): void {
    if (this.fieldName) {
      this.modifiedValues = [{}].concat(this.values, [{}]);
    } else {
      this.modifiedValues = [''].concat(this.values, ['']);
    }
  }

  public attached(): void {
    this.centerToday();
    this.selectValue = () => {
      this.selectedDate = this.todayValue;
      this.centerToday(true);
    }
  }

  private centerToday(isSmooth?: boolean): void {
    const dateWrapper = this.dateWrapper;
    if (!this.todayValue) {
      this.todayValue = this.selectedDate;
    }
    const selectedIndex = this.modifiedValues.findIndex((date) => {
      if (
        !this.fieldName && !date ||
        this.fieldName && !date[this.fieldName]
      ) {
        return;
      }
      return this.fieldName ? date[this.fieldName].startsWith(this.selectedDate[this.fieldName]) : date.startsWith(this.selectedDate);
    });
    this.step = selectedIndex;
    if (!isSmooth) {
      dateWrapper.style.scrollBehavior = 'auto';
    }
    const offset = (dateWrapper.clientWidth - 180) / 2;
    dateWrapper.scrollLeft = selectedIndex * 180 - offset;
    dateWrapper.style.scrollBehavior = 'smooth';
    new Event(this.element, 'change', this.selectedDate);
  }

  private smoothScrollTo(position): void {
    this.dateWrapper.scrollTo({
      top: 0,
      left: position,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    if (this.step <= 1) {
      return;
    }
    this.disableScroll = true;
    this.step--;
    this.selectedDate = this.modifiedValues[this.step];
    const dateWrapper = this.dateWrapper;
    const offset = 180;
    this.smoothScrollTo(dateWrapper.scrollLeft - offset);
    setTimeout(() => (this.disableScroll = false), 300);
    new Event(this.element, 'change', this.selectedDate);
  }

  public scrollRight(): void {
    if (this.step >= this.modifiedValues.length - 2) {
      return;
    }
    this.disableScroll = true;
    this.step++;
    this.selectedDate = this.modifiedValues[this.step];
    const dateWrapper = this.dateWrapper;
    const offset = 180;
    this.smoothScrollTo(dateWrapper.scrollLeft + offset);
    setTimeout(() => (this.disableScroll = false), 300);
    new Event(this.element, 'change', this.selectedDate);
  }
}
