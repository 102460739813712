import { autoinject } from "aurelia-dependency-injection";
import { DialogService } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";

import { List, AbstractList } from "zailab.abstract";
import { MESSAGE_EVENTS, SessionStore } from "zailab.common";
import { PlaceholderService } from "../../../../../_common/services/placeholder-service";
import { AddAutoLogoutConfig } from "../add/auto-logout-add";
import { AutoLogoutService } from "../auto-login-service";

const logger = LogManager.getLogger('AutoLogout');

@autoinject()
export class AutoLogout extends AbstractList {

  protected itemList: List;
  protected container: HTMLElement;
  public placeholders: number = 0;

  constructor(
    protected eventAggregator: EventAggregator,
    private autoLogoutService: AutoLogoutService,
    private router: Router,
    private dialogService: DialogService,
    private sessionStore: SessionStore
    
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.retrieveAutoLogoutConfigs();
  }

  private async retrieveAutoLogoutConfigs(): Promise<void> {
    try {
      this.showLoader();
      const autoLogins = await this.autoLogoutService.retrieveAutoLoginConfigs();

      this.itemList = List.Builder()
        .items(autoLogins ? autoLogins.rules : [])
        .enableAdd()
        .enableDelete()
        .enableClick()
        .selectionType(List.MULTIPLE_SELECTION_TYPE)
        .build();

    } catch(e) {
      logger.error('Failed to retrieve post interaction processors due to: ', e);
    } finally {
      this.generatePlaceholders();
      this.setReady();
      this.hideLoader();
    }
  }

  private generatePlaceholders(): void {
    if (!this.container) {
      return;
    }
    this.placeholders = 0;
    this.placeholderService = new PlaceholderService(this.container, this.itemList ? this.itemList.items.length : 0, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  private setReady(): void {
    this.ready = true;
  }
  

  public select(item: any): void {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.itemList.select && !this.itemList.isDeleting) {
      this.selectItem(item);
      return;
    }
    if (this.itemList.isDeleting && item.canDelete !== false) {
      this.itemList.selectToDelete(item);
    } else {
      logger.warn('Cannot select item >>>', item);
    }
  }

  public add(): void {
    
    this.dialogService
      .open({
        viewModel: AddAutoLogoutConfig,
        model: {
          ruleNames: this.itemList.items.map(item => item.name)
        }
      })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          this.itemList.items.push({ id: response.output.id, name: response.output.name });
          this.generatePlaceholders();
        }
      });
  }

  public deleteItems(): void {
    const itemsToDelete: any[] = [];
    let remainingItems: any[] = [];

    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      } else {
        remainingItems.push(item);
      }
    }
    super.deleteItems(itemsToDelete);
    this.itemList.items = remainingItems;
    this.itemList.isDeleting = false;
    this.hideLoader();
    this.generatePlaceholders();

    if (itemsToDelete.length === 1) {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Auto Logout rule ${itemsToDelete[0].name} deleted.`);
    } else if (itemsToDelete.length > 1) {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `${itemsToDelete.length} Auto Logout rules deleted.`);
    }
  }

  protected delete(item: any): void {
    this.autoLogoutService.removeAutoLogoutProfile(item);
  }

  public selectItem(item: any): void {
    this.dialogService
      .open({
        viewModel: AddAutoLogoutConfig,
        model: {
          rule: item,
          ruleNames: this.itemList.items.map(item => item.name)
        }
      })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let updatedItem = this.itemList.items.find(item => item.id === response.output.id);
          updatedItem.name = response.output.name;
          updatedItem.logoutEnabled = response.output.logoutEnabled;
          updatedItem.audienceType = response.output.audienceType;
          updatedItem.audience = response.output.audience;
          updatedItem.timeInMillis = response.output.timeInMillis;
          updatedItem.roles = response.output.roles;
        }
      });
  }
}