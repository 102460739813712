import {
  inject,
  bindable,
  customElement,
  LogManager,
  observable
} from 'aurelia-framework';
import { StatsConfig } from '../../../../../features/interaction/dashboard/live-dashboard/components/stats-config';

const logger = LogManager.getLogger('ZProportionalDonutWidget');

@inject(Element)
@customElement('z-proportional-donut-widget')
export class ZProportionalDonutWidget {
  @bindable({ attribute: 'widget-data' })
  @observable
  private widgetData: StatsConfig;
  @bindable({ attribute: 'show-labels' }) private showLabels: boolean = true;

  constructor(private element: Element) {}

  private select(data: any): void {
    this.element.dispatchEvent(
      new CustomEvent('navigate', {
        bubbles: true,
        detail: data
      })
    );
  }
}
