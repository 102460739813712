import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';
import { ContactModel } from '../contact-model';
import { CONTACT_ACTIONS } from '../contactcontroller/contact-controller-actions';
import { ContactControllerSubscriptions } from '../contactcontroller/contact-controller-subscriptions';
import { InteractionModel } from "../../conversation/interaction-model";
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from 'components_v2/confirm-dialog/confirm-dialog';
import { DoNotContactService } from 'features/organisation/donotcontact/donotcontact-service';
import { DoNotContactType } from 'features/organisation/donotcontact/donotcontact-model';
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('Contact');

@customElement('z-contact')
@autoinject()
export class Contact {

  @bindable public contact: ContactModel;
  @bindable public canedit: boolean;
  @bindable public viewstrategyname: string;
  @bindable public unidentifiedcontact: boolean;
  @bindable public disabled: boolean;
  @bindable public liveinteraction: InteractionModel;
  @bindable public details: Object;
  @bindable public showEdit: boolean;

  public contactCount: number = 0;
  public doNotContactAgentEnabled = true;

  constructor(
    private eventAggregator: EventAggregator, 
    private contactControllerSubscriptions: ContactControllerSubscriptions, 
    private dialogService: DialogService,
    private dncService: DoNotContactService,
  ) {
    this.contactControllerSubscriptions.contactSearchResults = this.eventAggregator.subscribe(CONTACT_ACTIONS.SEARCH_RESULT, (info: { contactId: string, contacts: ContactModel[] }) => {
      if(info.contacts) {
        this.contactCount = info.contacts.length;
      }
      this.contactControllerSubscriptions.contactSearchResults.dispose();
    });
    this.contactControllerSubscriptions.contactThirdPartyMatches = this.eventAggregator.subscribe(CONTACT_ACTIONS.THIRD_PARTY_MATCHES, (info: { interactionId: string, numberOfContacts: number, view: string }) => {
      this.contactCount = info.numberOfContacts;
      if(info.view) {
        this.contact.changeViewStrategy('unknownContact')
      }
      this.contactControllerSubscriptions.contactThirdPartyMatches.dispose();
    });
  }

  public showSearchContactsView(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: 'searchContactUnknownContact',
      correlationId: this.contact.correlationId,
      contactId: this.contact.contactId
    });
  }

  public showEditContactView(): void {
    let viewStrategy = 'editContact';

    if(this.contact.viewStrategy.emptyConversation) {
      viewStrategy = 'editContactEmptyConversation';
    } else if(this.contact.viewStrategy.unlinkedInteractions) {
      viewStrategy = 'editContactUnlinkedInteraction';
    }

    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: viewStrategy,
      correlationId: this.contact.correlationId,
      contactId: this.contact.contactId
    });
  }

  public showCreateContactView(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: 'createContact',
      correlationId: this.contact.correlationId,
      contactId: this.contact.contactId
    });
  }

  public openContactInSalesforce(): void {
    //@ts-ignore
    window.open(this.contact.crmInformation.url, '_blank');
  }

  public confirmDNC(type?: string, value?: string): void {    
    const header = `Add ${type ? type.toLowerCase() : 'contact'} to the do not contact list`;
    const text = `Are you sure you want to add ${value ? value : 'this contact'} to the Do Not Contact list?`;
    this.dialogService.open({ viewModel: ConfirmDialog, model: {header, text} }).whenClosed((response: any) => {
      if (!response.wasCancelled) {
        this.eventAggregator.publish('change.channel.size.hide', this.contact.contactId);
        if (!type || !value) {
          const promises = [];
          for (const email of this.contact.emails) {
            if (email.isDnc) {
              continue;
            }
            promises.push(this.dncService.create({
              doNotContactId: uuidv4(),
              doNotContactName: this.contact._fullName,
              doNotContactType: DoNotContactType.EMAIL,
              doNotContactValue: email.email
            }));
          }
          for (const number of this.contact.telephoneNumbers) {
            if (number.isDnc) {
              continue;
            }
            promises.push(this.dncService.create({
              doNotContactId: uuidv4(),
              doNotContactName: this.contact._fullName,
              doNotContactType: DoNotContactType.NUMBER,
              doNotContactValue: number.formattedNumber
            }));
          }
          Promise.all(promises).then(() => setTimeout(() => this.contact.retrieveDnc(), 500));
        } else {
          this.dncService.create({
            doNotContactId: uuidv4(),
            doNotContactName: this.contact._fullName,
            doNotContactType: type.toUpperCase(),
            doNotContactValue: value
          }).then(() => setTimeout(() => this.contact.retrieveDnc(), 500));
        }
      } 
    });
  }
}
