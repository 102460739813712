/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZBulb');
/*
 */
@customElement('z-bulb')
export class ZBulb {

  @bindable size;
  @bindable color;

}
