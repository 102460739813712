import { autoinject } from 'aurelia-dependency-injection';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { SessionStore } from 'zailab.common';

import './tab2-advanced-settings.scss';

@autoinject()
export class Tab2AdvancedSettings {
  private model: any;
  public webchatWidget: {
    bannerName?: string;
    bannerColor?: string | null;
    companyLogo?: any;
    customerTimeout?: number;
    file?: any;
    collectClientLocation: boolean;
    collectClientData: boolean;
  } = null;
  public file = null;
  public fileName = 'No file selected.';
  public validation: string;
  public validExtensions: string[] = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
  ];

  constructor(
    private controller: DialogController,
    private sessionStore: SessionStore
  ) {
    this.controller = controller;
    this.controller.settings.lock = true;
  }

  public activate(bindingContext: {
    data: {
      webchatConfiguration: any;
    };
  }): void {
    this.model = bindingContext;
    this.webchatWidget = this.model.data.webchatConfiguration;

    if (!this.webchatWidget.bannerName && !this.webchatWidget.customerTimeout) {
      this.webchatWidget.bannerName =
        this.sessionStore.get.organisation.organisationName;
      this.webchatWidget.customerTimeout = 5;
    }
  }

  public selectFile(evt: any): void {
    const file = evt.target.files[0];
    if (
      !file ||
      !file.name ||
      !this.isValid(file).extension() ||
      !this.isValid(file).size()
    ) {
      return;
    }

    this.validation = null;
    const reader = new FileReader();
    this.fileName = file.name;

    if (!file) {
      return;
    }
    reader.onload = (event: any) => {
      const base64Content = btoa(event.target.result);
      this.webchatWidget.file = {
        content: base64Content,
        name: this.fileName,
        type: file.type,
      };
    };
    reader.readAsDataURL(file);
  }

  public toggleCollectClientLocation(): void {
    this.webchatWidget.collectClientLocation =
      !this.webchatWidget.collectClientLocation;
  }

  public toggleCollectClientData(): void {
    this.webchatWidget.collectClientData =
      !this.webchatWidget.collectClientData;
  }

  private isValid(file: any): { extension: any; size: any } {
    const _extension = (): boolean => {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];

      if (this.validExtensions.indexOf(extension) === -1) {
        _setIncompleteStateWith(
          'Please select a valid image file (JPG, JPEG, PNG, GIF, BMP, WEBP).'
        );
        return false;
      }
      return true;
    };

    const _size = (): boolean => {
      const maxSize = 10 * 1024 * 1024;

      if (file.size >= maxSize) {
        _setIncompleteStateWith(
          'Please select a file that does not exceed 1MB.'
        );
        return false;
      }
      return true;
    };

    const _setIncompleteStateWith = (validation): void => {
      this.file = null;
      this.fileName = null;
      // this.wizard.step.incomplete({ file: null });
      this.validation = validation;
    };

    return {
      extension: _extension,
      size: _size,
    };
  }
}
