import { autoinject, LogManager } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('IntegrationsModule');

declare const PLATFORM;

@autoinject
export class InHouseWFM {
  constructor(private router: Router) {}

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: '',
        name: '',
        moduleId: PLATFORM.moduleName(
          'features/organisation/workforcemanagement/in-house-wfm/list/in-house-wfm-list'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'In House WFM',
        settings: { access: ['Administrator'] },
      },
      {
        route: 'shift-templates',
        name: 'shift-templates',
        moduleId: PLATFORM.moduleName(
          'features/organisation/workforcemanagement/in-house-wfm/shift-templates/shift-templates'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Shift Templates',
        settings: { access: ['Administrator'] },
      },
      {
        route: 'shifts',
        name: 'shifts',
        moduleId: PLATFORM.moduleName(
          'features/organisation/workforcemanagement/in-house-wfm/shifts/shifts'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Shifts',
        settings: { access: ['Administrator'] },
      },
      {
        route: 'shift',
        name: 'shift',
        moduleId: PLATFORM.moduleName(
          'features/organisation/workforcemanagement/in-house-wfm/shift/shift'
        ),
        nav: false,
        title: 'Member Shifts',
        settings: {
          access: [
            'Administrator',
            'Agent',
            'Campaign Manager',
            'Office Employee',
            'QA',
            'QA Manager',
            'Team Leader',
          ]
        },
      },
    ]);
    this.router = router;
  }
}
