import {CurrencyTools} from 'zailab.common';

const currencyTools = new CurrencyTools();

export class NumberToCurrencyValueConverter {
  toView(amount, symbol) {

    symbol = symbol ? symbol : '';

    if (amount < 0) {
      let absoluteValue = Math.abs(amount);
      return currencyTools.convertToCurrency(absoluteValue, '- ' + symbol);
    } else {
      return currencyTools.convertToCurrency(amount, symbol);
    }
  }
}
