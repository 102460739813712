import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { Oplog } from '../../../_common/services/database-service';
import { SessionStore } from '../../../_common/stores/session-store';
import { ReportMenuConfig } from '../report/report-menu-config';
import { ReportsService } from '../report/reports.service';
import { DeliveryInformation, DeliveryMethod, Frequency, ReportSchedule, Schedule, ScheduledJob, ScheduledJobResponse, SFTPConfiguration } from './report-schedule-model';

const logger = LogManager.getLogger('ReportScheduleService');

@autoinject()
export class ReportScheduleService {

  public readonly reportCategories = new ReportMenuConfig(false).categories.filter((category) => !category.reports.find((report) => !report.newReport));
  private readonly baseUrl = 'v3/performance-management/scheduledjob';

  private services;
  private teams;
  private agents;

  constructor(
    private http: HttpClient,
    private sessionStore: SessionStore,
    private oplog: Oplog,
    private reportsService: ReportsService
  ) { }

  public findAll(): Promise<ReportSchedule[]> {

    const promises = [];

    promises.push(this.reportsService.retrieveOrganisationServices()
      .then((services) => this.services = services));
    promises.push(this.reportsService.retrieveOrganisationTeams()
      .then((teams) => this.teams = teams));
    promises.push(this.reportsService.retrieveAllAgents(null, false)
      .then((agents) => this.agents = agents));

    return Promise.all(promises)
      .then(() => {

        return this.http.createRequest(`${this.baseUrl}/me`)
          .asGet()
          .send()
          .then((response: ScheduledJobResponse) => {
            return response.scheduledJobs
              .filter((scheduledJob) => !!scheduledJob.reportArguments)
              .map((scheduledJob) => {

                const startDateFormatted = moment(scheduledJob.schedule.startDate).format('ddd DD MMM YYYY');

                let reportName;
                start: for (const reportCategory of this.reportCategories) {
                  for (const report of reportCategory.reports) {
                    if (report.selector === scheduledJob.reportName) {
                      reportName = report.reportName;
                      break start;
                    }
                  }
                }

                const service = this.services.find((service) => scheduledJob.reportArguments['service'] === service.serviceId);
                const team = this.teams.find((team) => scheduledJob.reportArguments['team'] === team.teamId);
                const agent = this.agents.find((agent) => scheduledJob.reportArguments['agent'] === agent.memberId);

                if (service && service.serviceName) {
                  scheduledJob.reportArguments['serviceName'] = service.serviceName;
                }
                if (team && team.teamName) {
                  scheduledJob.reportArguments['teamName'] = team.teamName;
                }
                if (agent && agent.firstName && agent.surname) {
                  scheduledJob.reportArguments['agentName'] = `${agent.firstName} ${agent.surname}`;
                }

                const reportSchedule = new ReportSchedule();
                reportSchedule.id = scheduledJob.scheduledJobId;
                reportSchedule.name = scheduledJob.jobName;
                reportSchedule.reportId = scheduledJob.reportName;
                reportSchedule.reportParameters = scheduledJob.reportArguments;
                reportSchedule.deliveryMethod = scheduledJob.deliveryInformation.deliveryMethod;
                reportSchedule.email = scheduledJob.deliveryInformation.emailTos;
                if (scheduledJob.deliveryInformation.sftpConfiguration) {
                  reportSchedule.sftpHost = scheduledJob.deliveryInformation.sftpConfiguration.host;
                  reportSchedule.sftpPort = scheduledJob.deliveryInformation.sftpConfiguration.port;
                  reportSchedule.sftpPath = scheduledJob.deliveryInformation.sftpConfiguration.path;
                  reportSchedule.sftpUsername = scheduledJob.deliveryInformation.sftpConfiguration.username;
                  reportSchedule.sftpPassword = scheduledJob.deliveryInformation.sftpConfiguration.password;
                }
                reportSchedule.frequency = scheduledJob.schedule.frequency;
                reportSchedule.startDate = scheduledJob.schedule.startDate;
                reportSchedule.startTime = scheduledJob.schedule.time;
                reportSchedule.reportName = reportName;
                reportSchedule.startDateFormatted = startDateFormatted;

                return reportSchedule;
              });
          });
      });
  }

  public delete(id: any): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asDelete()
      .send();
  }

  public findById(id: any): Promise<ReportSchedule> {
    return this.http.createRequest(`${this.baseUrl}/me/${id}`)
      .asGet()
      .send()
      .then((scheduledJob: any) => {

        const reportSchedule = new ReportSchedule();
        reportSchedule.id = scheduledJob.scheduledJobId;
        reportSchedule.name = scheduledJob.jobName;
        reportSchedule.reportId = scheduledJob.reportName;
        reportSchedule.reportParameters = scheduledJob.reportArguments;
        reportSchedule.deliveryMethod = scheduledJob.deliveryInformation.deliveryMethod;
        reportSchedule.email = scheduledJob.deliveryInformation.emailTos;
        if (scheduledJob.deliveryInformation.sftpConfiguration) {
          reportSchedule.sftpHost = scheduledJob.deliveryInformation.sftpConfiguration.host;
          reportSchedule.sftpPort = scheduledJob.deliveryInformation.sftpConfiguration.port;
          reportSchedule.sftpPath = scheduledJob.deliveryInformation.sftpConfiguration.path;
          reportSchedule.sftpUsername = scheduledJob.deliveryInformation.sftpConfiguration.username;
          reportSchedule.sftpPassword = scheduledJob.deliveryInformation.sftpConfiguration.password;
        }
        reportSchedule.frequency = scheduledJob.schedule.frequency;
        reportSchedule.startDate = scheduledJob.schedule.startDate;
        reportSchedule.startTime = scheduledJob.schedule.time;

        return reportSchedule;
      });
  }

  public update(reportSchedule: ReportSchedule): Promise<any> {

    const id = reportSchedule.id;

    const scheduledJob = this.makeScheduledJob(id, reportSchedule);

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('performance-management-projector.scheduledJobView')
      .withKeyField('_id')
      .withKeyValue(id);

    this.http.createRequest(`${this.baseUrl}/${id}`)
      .asPut()
      .withContent(scheduledJob)
      .send();

    return oplogSubscription.when('update').then((data) => {

      oplogSubscription.unsubscribe();
      return data;
    });
  }

  public insert(reportSchedule: ReportSchedule): Promise<any> {

    const id = uuidv4();

    const scheduledJob = this.makeScheduledJob(id, reportSchedule);

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('performance-management-projector.scheduledJobView')
      .withKeyField('_id')
      .withKeyValue(id);

    this.http.createRequest(`${this.baseUrl}/${id}`)
      .asPost()
      .withContent(scheduledJob)
      .send();

    return oplogSubscription.when('insert').then((data) => {

      oplogSubscription.unsubscribe();
      return data;
    });
  }

  private makeScheduledJob(id: string, reportSchedule: ReportSchedule): ScheduledJob {

    const date = moment(reportSchedule.startDate).format('YYYY-MM-DD');

    const schedule = new Schedule();
    schedule.frequency = reportSchedule.frequency as Frequency;
    schedule.startDate = date;
    schedule.endDate = date;
    schedule.time = reportSchedule.startTime;
    schedule.timezone = reportSchedule.reportParameters.selectedTimezone.name;

    const deliveryInformation = new DeliveryInformation();
    deliveryInformation.deliveryMethod = reportSchedule.deliveryMethod as DeliveryMethod;
    deliveryInformation.emailTos = reportSchedule.email; // reportSchedule.deliveryMethod === 'EMAIL' ? reportSchedule.email : reportSchedule.sftp;

    if (reportSchedule.deliveryMethod === 'EMAIL') {
      deliveryInformation.emailTos = reportSchedule.email;
    }

    if (reportSchedule.deliveryMethod === 'SFTP') {
      deliveryInformation.sftpConfiguration = new SFTPConfiguration();
      deliveryInformation.sftpConfiguration.host = reportSchedule.sftpHost;
      deliveryInformation.sftpConfiguration.port = reportSchedule.sftpPort;
      deliveryInformation.sftpConfiguration.path = reportSchedule.sftpPath;
      deliveryInformation.sftpConfiguration.username = reportSchedule.sftpUsername;
      deliveryInformation.sftpConfiguration.password = reportSchedule.sftpPassword;
    }

    const reportArguments: any = {};
    if (reportSchedule.reportParameters.selectedChannel) {
      reportArguments.channel = reportSchedule.reportParameters.selectedChannel;
    }
    if (reportSchedule.reportParameters.selectedAgent) {
      reportArguments.agent = reportSchedule.reportParameters.selectedAgent.memberId;
    }
    if (reportSchedule.reportParameters.selectedService) {
      reportArguments.service = reportSchedule.reportParameters.selectedService.serviceId || reportSchedule.reportParameters.selectedService.serviceName;
    }
    if (reportSchedule.reportParameters.selectedTeam) {
      reportArguments.team = reportSchedule.reportParameters.selectedTeam.teamId || reportSchedule.reportParameters.selectedTeam.teamName;
    }
    if (reportSchedule.reportParameters.selectedTimezone) {
      reportArguments.timezone = reportSchedule.reportParameters.selectedTimezone.name;
    }
    if (reportSchedule.reportParameters.selectedCountry) {
      reportArguments.country = reportSchedule.reportParameters.selectedCountry.id;
    }
    if (reportArguments.service === 'All') {
      delete reportArguments.service;
    }
    if (reportArguments.team === 'All') {
      delete reportArguments.team;
    }
    start: for (const reportCategory of this.reportCategories) {
      for (const report of reportCategory.reports) {
        if (report.selector === reportSchedule.reportId) {
          reportArguments.category = report.category;
          break start;
        }
      }
    }

    const scheduledJob = new ScheduledJob();
    scheduledJob.deliveryInformation = deliveryInformation;
    scheduledJob.jobName = reportSchedule.name;
    scheduledJob.organisationId = this.sessionStore.get.organisation.organisationId;
    scheduledJob.reportArguments = reportArguments;
    scheduledJob.reportName = reportSchedule.reportId;
    scheduledJob.schedule = schedule;
    scheduledJob.scheduledJobId = id;

    return scheduledJob;
  }
}
