import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-http-client';
import { DialogService } from 'aurelia-dialog';

import { SessionStore, SESSION_EVENTS, WebSocket, PeerService, OplogService } from 'zailab.common';
import { ApplicationProperties } from '../_config/application.properties';
import { LogoutService } from './logout.service';
import HttpInterceptor from '../_config/http-interceptor';
import { PassportStore } from './../_common/stores/passport-store';
import { CrmStore } from '../features/integrations/integrations-store';

import { PictureStore } from "../components/atoms/images/picture-store";
import { RoutingStatusService } from '../features/organisation/member/routing-status/routing-status-service';
import { RoutingStatusModel } from '../features/organisation/member/routing-status/routing-status-model';
import { SessionService } from '../_common/services/session-service';
import { AssistService } from '../features/organisation/assist/assist-service';
import { ThemeService } from '../_common/services/theme-service';

const logger = LogManager.getLogger('Logout');

@autoinject
export class Logout {
  private autoLogout: boolean;

  constructor(
    private eventAggregator: EventAggregator,
    private router: Router,
    private sessionStore: SessionStore,
    private webSocket: WebSocket,
    private peerService: PeerService,
    private applicationProperties: ApplicationProperties,
    private httpClient: HttpClient,
    private crmStore: CrmStore,
    private pictureStore: PictureStore,
    private sessionService: SessionService,
    private dialogService: DialogService,
    private oplogService: OplogService,
    private assistService: AssistService
  ) { }

  public activate(params: any): void {
    if (params) {
      this.autoLogout = params.autoLogout;
    }
    this.oplogService.unsubscribeAll();
    localStorage.removeItem('zai-consult');
  }

  async attached(): Promise<void> {
    // window.onbeforeunload = (e): void => { };
    // if (this.dialogService.hasActiveDialog) {
    //   this.dialogService.controllers.forEach((controller) => controller.close());
    // }
    if (!this.autoLogout) {
      if (this.sessionStore.get.user.hasAgentRole) {
        await this.retractTeamleaderAssistance();
      }
      await this.sessionService.terminate();
    }
    // this.clearAllStates();
    // this.resetWebsocket();
    // this.peerService.close();
    this.router.navigate('');
    location.reload();
  }

  private async retractTeamleaderAssistance(): Promise<void> {
    const allAssists = await this.assistService.findAllAssists(this.sessionStore.get.user.memberId);
    const assist = allAssists.find((item) => item.handRaised);
    if (!assist) {
      return;
    }
    await this.assistService.postLowerHand(assist.assistId, assist.memberId);
  }

  private resetWebsocket(): void {
    this.webSocket
      .resetWebsocket()
      .then(() => { })
      .catch(() => { });
  }

  private clearAllStates(): void {
    this.eventAggregator.publish(SESSION_EVENTS.RESET.EVENT, {});
    this.crmStore.clearState();
    this.pictureStore.clear();
  }
  
}
