import {LogManager} from 'aurelia-framework';
import {ContactDirectorViewStrategyModel} from './contact-director-view-strategy-model';

const logger = LogManager.getLogger('ContactDirectorViewStrategies');

export class ContactDirectorViewStrategies {
  public static getStrategy(strategyName: string): ContactDirectorViewStrategyModel {
    let strategies: any = {
      conversationStatus: new ContactDirectorViewStrategyModel(false, false, false, false, true, false, true, false, false, false, false, false, true, false, false, false),

      searchContactUnknownContact: new ContactDirectorViewStrategyModel(true, false, true, false, false, false, false, false, true, false, true, true, true, true, false, false),
      searchContactEmpty: new ContactDirectorViewStrategyModel(true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true),

      createContact: new ContactDirectorViewStrategyModel(false, true, true, false, false, false, false, false, true, false, true, true, true, true, false, false),
      createContactEmpty: new ContactDirectorViewStrategyModel(false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, true),

      editContact: new ContactDirectorViewStrategyModel(false, false, true, true, false, false, false, false, false, false, true, false, true, false, false, false),
      editContactUnlinkedInteraction: new ContactDirectorViewStrategyModel(false, false, false, true, false, false, false, false, true, false, true, false, true, false, false, false),
      editContactEmptyConversation: new ContactDirectorViewStrategyModel(false, false, false, true, false, false, false, true, false, false, true, false, true, false, false, false),

      emptyContact: new ContactDirectorViewStrategyModel(false, false, false, false, false, true, false, false, false, false, false, false, true, false, false, false),
      emptyConversation: new ContactDirectorViewStrategyModel(false, false, false, false, false, false, false, true, false, false, false, false, true, false, false, false),
      interactions: new ContactDirectorViewStrategyModel(false, false, true, false, false, false, true, false, false, false, false, true, true, false, false, false),

      unlinkedInteractions: new ContactDirectorViewStrategyModel(true, false, true, false, false, false, false, false, true, false, false, false, true, false, true, false),
      callOutcome: new ContactDirectorViewStrategyModel(false, false, false, false, false, false, false, false, false, true, false, true, true, false, false, false),
      unknownContact: new ContactDirectorViewStrategyModel(false, false, true, false, false, false, false, false, true, false, true, true, true, true, true, false),
      wrapUp: new ContactDirectorViewStrategyModel(false, false, true, false, false, false, false, false, true, false, true, true, true, false, true, false)
    };
    return strategies[strategyName];
  }
}
