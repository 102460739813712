import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager } from "aurelia-framework";
import { SearchTools } from "zailab.common";

const logger = LogManager.getLogger('ZView');

export class ZListViewSupport {
  
  public isLoading = false;
  public isDeleting = false;
  public isSearching = false;
  public searchTerm = '';
  public placeholders = 30;
  public tiles = 30;
  public items = [];

  constructor(
    private events?: EventAggregator,
  ) { }

  public showLoader(): void {
    this.isLoading = true;
    if (!this.events) {
      return;
    }
    this.events.publish('app:loader:show');
  }

  public hideLoader(): void {
    this.isLoading = false;
    if (!this.events) {
      return;
    }
    this.events.publish('app:loader:hide');
  }

  public toggleSearch(): void {
    this.searchTerm = '';
    this.isSearching = !this.isSearching;
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
    for (const item of this.items) {
      item.isDeleting = false;
    }
  }

  public partialMatch = (searchExpression: string, value: any, searchParam: string): boolean =>  {
    if (this.items && this.items.length > 0) {
      const items = this.items.filter((item) => SearchTools.partialMatch(item[searchParam], searchExpression));
      if (items.length < this.tiles) {
        this.placeholders = this.tiles - items.length;
      } else {
        this.placeholders = 6 - (items.length % 6);
      }
    } else {
      this.placeholders = this.tiles;
    }
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}

export interface ZView {
  "header": "string",
  "description": "string",
  "matchTerm": "string",
  "orderBy": "string",
  "tile": {
    "header": "string"
  },
  "baseUrl": "string"
}