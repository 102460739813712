import {LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('StatusModel');

export class RecentConversationModel {

  public id: string;
  public name: string;
  public status: string;
  public timestamp: number;
  public contactId: string;
  public statusColor: string;
  public isDeleting: boolean;
  public isPlaceholder: boolean;
  public reason: string;
  public isSelected: boolean;
  public contactFirstName: string;
  public contactLastName: string;

  constructor(conversation: object) {
    // super();
    // this.mapProperties(conversation);
    Object.assign(this, conversation);
  }

  @computedFrom('contactFirstName', 'contactLastName')
  get contactFullName(): string {
    if(this.contactFirstName && this.contactLastName) {
      return this.contactFirstName + ' ' + this.contactLastName;
    }

    if (this.contactFirstName && !this.contactLastName) {
      return this.contactFirstName;
    }

    if (!this.contactFirstName && this.contactLastName) {
      return this.contactLastName;
    }

    return '(No Name)';
  }
}
