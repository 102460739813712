export class SyncStatusModel {
  public syncId: string = '';
  public organisationId: string = '';
  public status: string = '';
  public totalContacts: number = 0;
  public contactsProcessed: number = 0;
  public percentageCompleted: number = 0;
  public hasErrors: boolean = false;

  constructor(data: any) {
    Object.assign(this, data);
  }
}