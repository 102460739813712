import {LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('CreateWorkTypeModel');
/*
 */
export class CreateWorkTypeModel extends BaseModel {

  channel = null;
  service = null;
  sites = null;
  skills = null;
  taskResponseTime = null;
  businessPartner = null;
  businessPartnerList = null;
  contactCenterList = null;
  contactCenter = null;

  constructor(item) {
    super();
    this.taskResponseTime = item.taskResponseTime;
    this.channels = item.channels;
    this.services = item.services || [];
    this._sites = item.sites || [];
    this.skillGroups = item.skills;
    this.roles = item.roles;
    this.businessPartners = item.businessPartners;
    this.businessPartnerList = item.businessPartners;
    this.contactCenters = item.contactCentres;
    this.contactCenterList = item.contactCentres;
  }

  set businessPartners(businessPartners) {
    if (!businessPartners) {
      return;
    }
    businessPartners.forEach((item) => {
      if (item.isSelected) {
        this.businessPartner = {
          businessPartnerId: item.id,
          businessPartnerName: item.name
        };
      }
    });
  }

  set contactCenters(contactCenters) {
    if (!contactCenters) {
      return;
    }
    contactCenters.forEach((item) => {
      if (item.isSelected) {
        this.contactCenter = {
          contactCentreId: item.contactCentreId,
          contactCentreName: item.name
        };
      }
    });
  }

  set channels(channels) {
    channels.forEach((item) => {
      if(item.isSelected) {
        this.channel = item.displayName;
      }
    });
  }

  @computedFrom('channel')
  get channels() {
    return this.channel;
  }

  set services(services) {

    for(let service of services) {
      if(service.isSelected) {
        service.id = service.serviceId;
        service.name = service.serviceName;
        this.service = service;
        return;
      }
    }
  }

  @computedFrom('service')
  get services() {
    return this.service;
  }

  set _sites(sites) {
    let _sites = [];
    for(let site of sites) {
      if(site.isSelected) {
        site.siteId = site.id;
        site.siteName = site.name;
        site.selected = true;
        _sites.push(site);
      }
    }
    this.sites = _sites;
  }

  @computedFrom('sites')
  get _sites() {
    return this.sites;
  }

  get slaWaitTimeInSeconds() {

    if(this.channel === 'Outbound Call' || this.channel === 'SMS' || this.channel === 'Email' || this.channel === 'Ticket') {
      return Math.ceil(parseFloat(this.taskResponseTime) * 3600);
    }
    return this.taskResponseTime ? parseInt(this.taskResponseTime): null;
  }

  set skillGroups(_skills) {
    this.skills =  _skills;
  }

  @computedFrom('skills')
  get skillGroups() {
    return this.skills;
  }

  set roles(roles) {
    let _roles = [];
    if (roles) {
      for (let role of roles) {
        if(role.isSelected) {
          _roles.push(role.roleName);
        }
      }
    }
    this._roles = _roles;
  }

  @computedFrom('_roles')
  get roles() {
    return this._roles;
  }
}
