import {inject, LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('BaseModel');

export class BaseModel {

  constructor() {
  }

  /**
   * this will mape the fields from the object you pass in onto the instance of this model object
   * @param object object ans instance of the object that you want to map
   * */
  mapProperties(object) {
    if (object) {
      this._initialiseProperties();
      this._assignEntityProperties(object);
    }
  }

  updatePropreties(object) {
    if (object) {
      this._assignEntityProperties(object)
    }
  }

  _initialiseProperties() {
    for (let property in this) {
      if (!property) {
        property = null;
      }
    }
  }

  _assignEntityProperties(object) {
    for (let property in object) {
      this._assignPropertyToEntityIfDefined(object, property);
    }
  }

  _assignPropertyToEntityIfDefined(object, property) {
    if (object.hasOwnProperty(property) && object[property] !== null && this.hasOwnProperty(property)) {
      this[property] = object[property];
    }
  }
}
