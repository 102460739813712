import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('AgentScriptAdminConfig');

@autoinject()
export class AgentScriptAdminConfig {

  constructor(public router: Router) {
  }

  public configureRouter(config, router): void {
    config.map([
      {route: '', name: 'list', title: 'List', moduleId: PLATFORM.moduleName('./agent-script-admin-config-list')},
      {route: 'designer/:id', name: 'designer', title: 'Designer', moduleId: PLATFORM.moduleName('./agent-script-designer')},
    ]);
    this.router = router;
  }
}