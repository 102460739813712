import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('UploadResultsModel');
/*
 */
export default class extends BaseModel {

  duplicated = null;
  failed = null;
  merged = null;
  prospectListName = null;
  uploaded = null;
  total = null;
  uploadedPercentage = null;

  constructor(uploadResults) {

    super();
    super.mapProperties(uploadResults);
  }
}