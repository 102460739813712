import {inject, LogManager, Factory} from 'aurelia-framework';
import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';
import {EventAggregator} from 'aurelia-event-aggregator';
import {CommandFactory, OplogService} from 'zailab.common';
/*
 */
import {RecentActivityModel} from './recent-activity-model';
import {ActivityResponseModel} from './activity-response-model';
import {WrapUpInteractionModel} from './wrap-up-interaction-model';
import {MemberInteractionModel} from '../conversation/interaction/member-interaction-model';
/*
 */
let logger = LogManager.getLogger('InteractionService');

/*
 */
@inject(EventAggregator, HttpClient, OplogService, Factory.of(CommandFactory))
export class InteractionService {

  constructor(private eventAggregator: EventAggregator, private httpClient: HttpClient, private oplogService: OplogService, private commandFactory: CommandFactory) {}

  public retrieveRecentActivity(memberId: string, page: number, size: number): Promise<ActivityResponseModel[]> {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/interactions/recent-activities')
        .asGet()
        .withParams({memberId, page, size})
        .send()
        .then((response) => {

          let activitiesResponse: ActivityResponseModel = new ActivityResponseModel();
          activitiesResponse.totalActivities = response.total;

          response.activities.forEach((activity) => {
            activitiesResponse.addActivity(new RecentActivityModel(activity));
          });
          resolve(activitiesResponse);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public initialiseRecentActivityOplog(memberId: string): any {
    // @ts-ignore
    return this.oplogService.subscribeOn('_id', memberId).in('interaction-projector.recentActivityView');
  }

  public retrieveMemberWrapUpInteractions(memberId: string): Promise<WrapUpInteractionModel[]> {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/interactions/wrap-up')
        .asGet()
        .withParams({memberId})
        .send()
        .then((response) => {
          if(response) {
            let wrapUpInteractions: WrapUpInteractionModel[] = response.map((interaction) => {
              return new WrapUpInteractionModel(interaction);
            });
            resolve(wrapUpInteractions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public retrieveTask(taskId: string): Promise<InteractionTaskModel> {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/interactions/tasks/${taskId}`)
        .asGet()
        .send()
        .then((response) => {

          let task = null;
          if (response) {
            let payload = {
              type: 'ConnectTask',
              payload: response
            };
            let interaction = {
              payload: payload,
              source: 'TR.ALL'
            };
            task =  new InteractionTaskModel(interaction);
          }

          resolve(task);
        }, (error) => {
          reject(null);
        })
    });
  }

  public retrieveConnectedInteractions(memberId: string): Promise<Array<MemberInteractionModel>> {

    let requestURL: string = `v1/organisation/interactions/members/${memberId}/interactions`;

    return this.httpClient.createRequest(requestURL)
      .asGet()
      .send()
      .then((response: any) => {

        if (response) {
          let promise = [];
          promise.push(response.memberInteractions.map(memberInteraction => {
            memberInteraction.memberId = response.memberId;
            return new MemberInteractionModel(memberInteraction);
          }));
          
          return Promise.all(promise)
          .then((mappedMemberInteractions) => {
            return mappedMemberInteractions[0];
          });
        }
      })
      .catch(() => [])
  }

  public getConnectedInteractionsOplog(memberId: string): Promise<any> {
    return new Promise(resolve => {
      resolve(this.oplogService.subscribeOn('_id', memberId).in('interaction-projector.memberInteractionsView'));
    });
  }

}
