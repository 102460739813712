import { inject } from 'aurelia-dependency-injection';
import {LogManager, customElement, bindable} from 'aurelia-framework';
// @ts-ignore
import moment from 'moment';

import { ArrayTools, Event } from 'zailab.common';

const logger = LogManager.getLogger('ZColoredTimer');

@inject(Element)
@customElement('z-colored-timer')
export class ZColoredTimer {

  @bindable({attribute: 'start-time'}) private startTime: boolean;
  @bindable({attribute: 'color-rules'}) private colorRules: any[];

  private rules: any[];

  private interval: number;
  public liveTimer: string;
  public color: string;

  constructor(
    private element: Element
  ) {}

  public attached(): void {
    if (!this.startTime) {
      return;
    }

    this.rules = ArrayTools.sort([...this.colorRules], 'value').reverse();

    // Update the timer display every second
    this.interval = setInterval(() => this.updateTimer(), 150);
  }

  private updateTimer(): void {
    const diffDuration = moment.duration(moment.utc().diff(moment(this.startTime).utc()));

    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    const seconds = diffDuration.seconds();

    // Update the timer display
    this.liveTimer = `${hours < 10 ? '0' + hours: hours}h${minutes < 10 ? '0' + minutes: minutes}m:${seconds < 10 ? '0' + seconds : seconds}s`;

    if (this.rules && Array.isArray(this.rules)) {
      this.initiateColorRules(diffDuration);
    }
  }

  private initiateColorRules(diffDuration: moment): void {
    let diffSeconds = diffDuration.asSeconds();

    for (let config of this.rules) {
      if (diffSeconds >= config.value) {

        if (this.color !== config.color) {
          new Event(this.element, 'color-change', {
            timeInSeconds: diffSeconds,
            color: config.color
          });
        }
        return this.color = config.color;
      }
    }
  }

  public detached(): void {
    this.interval && window.clearInterval(this.interval);
  }
}
