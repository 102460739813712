import {LogManager} from 'aurelia-framework';

import {ChannelModel} from './channel-model';

const logger = LogManager.getLogger('ChatChannelModel');

export class ReminderChannelModel extends ChannelModel {

  constructor(size?: string) {
    super('reminder', 'bell');
    this.size = size ? size : 'small';
  }
}
