export class PictureStore {

  private pictures: Map<string, any>;

  constructor(){
    this.pictures = new Map();
  }

  public addPicture(id: string, data: any): void {
    this.pictures.set(id,data);
  }

  public getPictureById(id: string): any {
    return this.pictures.get(id);
  }
  public getPictures(): Map<string,any> {
    return this.pictures;
  }

  public get count(): number {
    return this.pictures.size;
  }

  public clear(): void {
    this.pictures.clear();
  }

}