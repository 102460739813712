import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
/*
 * */
import TelephoneNumbersService from '../../../../finance/telephonenumbers/telephone-numbers-service';
import { AbstractList } from 'zailab.abstract';
import { SessionStore } from 'zailab.common';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
const logger = LogManager.getLogger('AssignMemberDialog');
/*
 * */
@inject(TelephoneNumbersService, SessionStore, DialogController, EventAggregator)
export class AssignMembersDialog extends AbstractList {

  number = null;
  dialogHeader = 'Assign Number to Member';
  defaultpicture = 'target/_assets/img/profile_pic-holder.png';
  isSearching = false;

  constructor(numbersService, sessionStore, dialogController, eventAggregator) {
    super(eventAggregator);
    this.numbersService = numbersService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param members
   * */
  activate(members) {

    if (members.length > 7) {
      this.isSearching = true;
    }

    this.itemList = members;
  }

  /**
   * closes dialog
   * passes selected member back
   * @param member
   */
  select(member) {
    this.dialogController.ok(member);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.dialogController.cancel();
  }

  /**
   * @param searchExpression
   * @param value
   * @returns {*|boolean}
   */
  startsWithMatch(searchExpression, value) {
    return searchExpression && (value.email.toLowerCase().startsWith(searchExpression.toLowerCase()) || value.firstName.toLowerCase().startsWith(searchExpression.toLowerCase()) || value.surname.toLowerCase().startsWith(searchExpression.toLowerCase()));
  }
}

