import { ArrayTools, SearchTools } from 'zailab.common';
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { WorkTypesService } from '../work-types-service';
import { ConfirmDialog } from '../../interactioncards/call/components/voiceauthenticator/confirm-dialog';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('WorktypeSettings');

@autoinject
export class WorktypeSettings {

  private configId: string;
  public worktypes: any[] = [];
  public originalColumns: any[] = [];
  public columns: any[] = [];
  public defaultSettings = {
    operator: '>=',
    value: '0',
    colour: null,
    enabled: false,
    expandClass: 'fade-in',
    isExpanded: true
  };
  public statConfigLimit: number = 5;
  public ready: boolean = false;
  private draggingItem: any;
  public hasError: boolean = false;
  public loading: boolean = false;
  public worktypeToAdd: any;
  public worktypeDropdownVisible: boolean = false;

  public colourPickerSettings = { value: "#278787",   buttonText: {swatches: "colors"}}

  constructor(
    protected router: Router,
    private workTypesService: WorkTypesService,
    private dialogService: DialogService
  ) { }

  public activate(): void {
    this.init();
  }

  private async init(): Promise<void> {
    await this.retrieveWorktypes();
    await this.retrieveList();
  }

  private async retrieveWorktypes(): Promise<void> {
    try {
      let worktypes = await this.workTypesService.retrieveWorkTypes(true);
      this.worktypes = ArrayTools.sort(worktypes.map(wt => {
        return {
          templateId: wt.templateId,
          templateName: wt.templateName
        };
      }), 'templateName')
    } catch(e) {
      logger.warn(' > Failed to retrieve worktypes due to', e);
    }
  }

  protected async retrieveList(): Promise<void> {
    const colorConfig = await this.workTypesService.retrieveWorktypeColorConfig();
    
    this.configId = colorConfig.id;

    if (colorConfig.colorFormats) {
      const worktypeIds = Object.keys(colorConfig.colorFormats);

      let columns = worktypeIds.map(id => {
        let workType = this.worktypes.find(wt => wt.templateId === id);
        return {
          title: workType ? workType.templateName : '--',
          moddedTitle: workType ? workType.templateName.replace(/ /g, '_') : 'noname',
          settings: [...colorConfig.colorFormats[id]],
          data: {
            isExpanded: false,
            templateId: workType ? workType.templateId : 'noid',
            unitOfMeasurement: 'Seconds'
          }
        };
      });

      this.columns = ArrayTools.sort(columns, 'title')
    }

    this.originalColumns = JSON.parse(JSON.stringify(this.columns));
    this.ready = true;
  }

  public selectWorktypeToAdd(worktype: { templateId: string; templateName: string; isAdded?: boolean }): void {
    if (worktype.isAdded) {
      return;
    }
    this.worktypeToAdd = worktype;
    this.worktypeDropdownVisible = false;
  }

  public showWorktypeDropdown(): void {
    this.worktypeDropdownVisible = !this.worktypeDropdownVisible;
  }

  public addWorktype(): void {
    if (!this.worktypeToAdd) {
      return;
    }

    this.columns.push({
      title: this.worktypeToAdd.templateName,
      moddedTitle: this.worktypeToAdd.templateName.replace(/ /g, '_'),
      settings: [{ ...this.defaultSettings }],
      data: {
        isExpanded: true,
        templateId: this.worktypeToAdd.templateId,
        unitOfMeasurement: 'Seconds'
      }
    });
    this.worktypeToAdd = null;
  }

  protected selectItems(items: any): void {
    items.item.isSelected = !items.item.isSelected;
  }

  public isInvalidPercentage(value: any): boolean {
    return !(value >= 0 && value <= 100);
  }

  public navigateToWorktypes(): void {
    if (this.hasError) {
      return;
    }
    this.router.navigate('');
  }

  public async save(): Promise<void> {
    this.loading = true;

    const configId = this.configId || uuidv4();
    let payload: any = {
      id: configId,
      type: 'WORKTYPE',
      colorFormats: {}
    };

    this.columns.forEach(col => {
      let settingsElements = document.querySelectorAll(`#js-worktype-${col.moddedTitle} #drag-row`);
      let config: any = Array.from(settingsElements);
        
      payload.colorFormats[col.data.templateId] = col.settings.map(setting => {
        return {
          value: setting.value,
          color: setting.color || '',
          operator: setting.operator,
          orderIndex: config.indexOf(config.find(conf => conf.className.indexOf(`js-worktype-${col.moddedTitle}-${setting.value}`) >= 0))
        };
      })
    });

    try {

      if (!this.configId) {
        this.workTypesService
          .addWorktypeColorConfig(payload.id, payload)
          .then(() => this.navigateToWorktypes());
      } else {
        this.workTypesService
          .updateWorktypeColorConfig(payload.id, payload)
          .then(() => this.navigateToWorktypes());
      }
    } catch(e) {
      logger.warn('Failed to update worktypes config ', e);
    }
    this.loading = false;
  }

  public reset(): void {
    this.ready = false;
    this.init();
  }

  public cancel(): void {
    this.router.navigate('');
  }

  public addRow(title: string): void {
    let item = this.columns.find(col => col.title === title);
    if (item.settings.length >= this.statConfigLimit) {
      return;
    }

    item.settings.push({
      ...this.defaultSettings
    });
    this.validateValue(item.settings, item.data.expandCollapseGroup, item.data.expandCollapseHeader, item.data.unitOfMeasurement);
  }

  public removeRow(title: string, selectorIndex: number): void {
    let item = this.columns.find(col => col.title === title);
    item.settings.splice(selectorIndex, 1);
    this.validateValue(item.settings, item.data.expandCollapseGroup, item.data.expandCollapseHeader, item.data.unitOfMeasurement);
  }

  public removeWorktype(title: string): void {
    const message = `
      This will remove the handle time alert for 
      <span class="label medium blue-highlight-darkest">
        ${title}
      </span>.
    `;

    this.dialogService
      .open({ viewModel: ConfirmDialog, model: { message } })
      .whenClosed(dialog => {
        if (!dialog.wasCancelled) {
          let workTypeToRemove = this.columns.find(column => column.title === title);
          this.columns.splice(this.columns.indexOf(workTypeToRemove), 1);
        }
      });
  }

  public validateValue(settings: any, expandCollapseGroup: string, expandCollapseHeader: boolean, unitOfMeasurement: string): void {
    this.clearErrors(settings, expandCollapseGroup);

    let values = {};
    settings.forEach((setting, index) => {
      if (!values[setting.value]) {
        values[setting.value] = [];
      }
      values[setting.value].push(index);
    });

    let keys = Object.keys(values);
    keys.forEach(key => {
      if (!key && key !== '0') {
        values[key].forEach(value => {
          settings[value].errorRequired = true;
          this.hasError = true;
          this.setErrorOnGroup(expandCollapseGroup, true);
        });
      } else if (values[key].length > 1) {
        values[key].forEach((value, currentIndex) => {
          if (currentIndex === values[key].length - 1) {
            settings[value].error = true;
          } else {
            settings[value].errorDuplicate = true;
          }
          this.hasError = true;
          this.setErrorOnGroup(expandCollapseGroup, true);
        });
      } else if (unitOfMeasurement === 'Percentage') {
        values[key].forEach((value) => {
          let numberValue: number = parseInt(key);
          if (numberValue < 0 || numberValue > 100) {
            settings[value].percentageError = true;
            this.hasError = true;
            if (expandCollapseGroup && !expandCollapseHeader) {
              this.setErrorOnGroup(expandCollapseGroup, true);
            }
          }
        });
      }
    });
  }

  private setErrorOnGroup(expandCollapseGroup: string, hasError: boolean): void {
    if (!expandCollapseGroup) {
      return;
    }
    this.columns.forEach(col => {
      if (col.data.expandCollapseGroup === expandCollapseGroup) {
        col.settings[0].hasError = hasError;
      }
    });
  }

  private clearErrors(settings: any, expandCollapseGroup: string): void {
    this.hasError = false;
    this.setErrorOnGroup(expandCollapseGroup, false);
    settings.forEach(setting => {
      setting.error = null;
      setting.errorDuplicate = false;
      setting.errorRequired = false;
      setting.percentageError = false;
    });
  }

  public toggleExpandCollapseGroup(column: any, settings: any[]): void {
    column.data.isExpanded = !column.data.isExpanded;
    settings.forEach((config, index) => {
      if (index === 0) {
        return;
      }
      if (column.data.isExpanded) {
        config.expandClass = 'fade-in';
        config.isExpanded = true;
      } else {
        config.expandClass = 'fade-out';
        setTimeout(() => {
          config.isExpanded = false;
        }, 200);
      }
    })
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string, searchParam2: string): boolean {
    try {
      let match1 = SearchTools.partialMatch(value[searchParam], searchExpression);
      if (match1) {
        return true;
      }
      let match2 = SearchTools.partialMatch(value[searchParam2], searchExpression);
      if (match2) {
        return true;
      }
    return false;
    } catch(e) {}
  }
  
  public handleDragStart(event: DragEvent): any {
    event.stopPropagation();
    let item: any = event.target;
    item.style.opacity = '0.3';
    
    this.draggingItem = item;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', item.innerHTML);
    return true;
  }

  public handleDragEnd(event: DragEvent): any {
    let dropItem: any = event.target;
    dropItem.style.opacity = '1';

    dropItem.parentElement.childNodes.forEach(item => {
      if (item.classList) {
        item.classList.remove('over');
      }
    });
    return false;
  }

  public handleDragOver(event: DragEvent): any {
    event.preventDefault();
  }

  public handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    let current = this.draggingItem;

    if (item && item.parentElement === current.parentElement) {
      if (item.className.indexOf(' over') === -1) {
        item.classList.add('over');
      }
    }
  }

  public handleDragLeave(event: DragEvent): any {
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    item.classList = item.className.replace(' over', '');
  }

  public handleDrop(event: DragEvent): any {

    let current = this.draggingItem;
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    let items = item.parentElement.querySelectorAll('.js-draggable');
    
    event.preventDefault();
    if (item !== current && item.parentElement === current.parentElement) {
      let currentpos = 0, droppedpos = 0;
      for (let it = 0; it < items.length; it++) {
        if (current === items[it]) { currentpos = it; }
        if (item === items[it]) { droppedpos = it; }
      }
      if (droppedpos === 0 || currentpos < droppedpos) {
        item.parentNode.insertBefore(current, item.nextSibling);
      } else {
        item.parentNode.insertBefore(current, item);
      }
    }
  }
}
