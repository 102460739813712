import { inject, bindable, containerless, customElement, LogManager } from 'aurelia-framework';
import { List } from 'zailab.abstract';
import { Event } from 'zailab.common';
import { BusinessPartner } from '../../businesspartner/business-partner-model';
import { ContactCenter } from '../contact-center-model';
import { ContactCenterService } from '../contact-center-service';
/*
 */
let logger = LogManager.getLogger('CondensedContactCenterList');
/*
 */
@containerless()
@customElement('z-condensed-contact-center-list')
@inject(Element, ContactCenterService)
export class CondensedContactCenterList {

  @bindable public required: boolean = false;
  @bindable public multiselect: boolean = false;
  @bindable public nodefaultselected: boolean = false;
  @bindable public ignoreBusinessPartners: boolean = false;
  @bindable public selectedBusinessPartners: BusinessPartner[] = [];
  @bindable public selected: ContactCenter[] = [];
  @bindable public canedit: boolean = false;
  @bindable public disabled: boolean = false;
  @bindable public titleoverride: string = '';
  @bindable({ attribute: 'items-per-row' }) public itemsperrow: number = 6;
  @bindable public subtitlesenabled: boolean = false;

  public list: List;

  constructor(
    private element: Element,
    private contactCenterService: ContactCenterService
  ) { }

  public attached(): void {
    this.init();
  }

  private init(): void {
    this.contactCenterService.findAll().then((items: ContactCenter[]) => {
      this._setupConfig(this.ignoreBusinessPartners ? items : items.filter((item: ContactCenter) => {
        const businessPartner = this.selectedBusinessPartners.find((selectedBusinessPartner: BusinessPartner) => {
          return item.businessPartnerId === selectedBusinessPartner.id;
        });
        return !!businessPartner;
      }));
    });
  }

  public disabledChanged(): void {
    if (this.list) {
      this.list.isDisabled = this.disabled;
    }
  }

  public selectedBusinessPartnersChanged(): void {
    if (this.list) {
      this.init();
    }
  }

  private _setupConfig(items: ContactCenter[]): void {

    if (this.list) {
      
      this.list.items = items;
      this.list.selectedItems = this.selected ? Object.assign([], this.selected) : [];
      if (!items || !items.length) {
        this.list.isEditing = false;
        this.list.isDisabled = true;
      } else {
        this.list.selectSelectedItems();
      }

      return;
    }

    this.list = List.Builder() //
      .required(this.required) //
      .selectionType(this.multiselect ? List.MULTIPLE_SELECTION_TYPE : List.SINGLE_SELECTION_TYPE) //
      .items(items) //
      .title(this.titleoverride ? this.titleoverride : 'Select Contact Center') //
      .selectedItems(this.selected ? Object.assign([], this.selected) : []) //
      .icon('team-leader') //
      .uniqueIdentifier('id') //
      .displayId('name') //
      .selectionStrategy(null) //
      .enableReadOnly(this.canedit) //
      .enableDisabled(this.disabled) //
      .autoSelect(false) //
      .build();
  }

  public select(event: Event): void {
    new Event(this.element, 'select', event);
  }

  public get itemsPerRow(): number {
    return this.itemsperrow || 6;
  }

  public updateItems(data: ContactCenter[]): void {
    new Event(this.element, 'updateitems', data);
  }
}