import {autoinject, LogManager, PLATFORM} from "aurelia-framework";
import {Router, RouterConfiguration} from 'aurelia-router';

const logger = LogManager.getLogger('IntegrationsModule');

@autoinject
export class IntegrationsModule {
  private actions: string[] = ['authenticate','sync'];

  constructor(private router: Router) {
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([{
      route: '',
      name: '',
      moduleId: PLATFORM.moduleName('features/organisation/organisation/sms-gateways/list/sms-gateway-list'),
      isTwelveColumnRoute: true,
      nav: false,
      title: 'SMS Gateways',
      settings: {access: ['Administrator']}
    }, {
      route: ':gatewayId',
      name: 'smsgatewayaccounts',
      moduleId: PLATFORM.moduleName('features/organisation/organisation/sms-gateways/accounts/sms-gateway-account-list'),
      isTwelveColumnRoute: true,
      nav: false,
      title: 'SMS Gateway Accounts',
      settings: {access: ['Administrator']}
    }]);
    this.router = router;
  }

}
