import {AccountSessionModel} from './../../../../_common/stores/sessionmodels/account-session-model';
import {inject, LogManager, computedFrom} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
import {BuyCreditModel} from './../buycredit/buycredit-model';
import {SessionStore, DateTimeTools, MESSAGE_EVENTS, BootstrapFormRenderer, COUNTRIES} from 'zailab.common';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {BuyCreditService} from './../buycredit/buycredit-service';
import creditCardValidator from 'credit-card-validator';
import {ThreeDSecurePopUp} from './../buycredit/3d-secure-pop-up';
import * as crs from '../../../../_assets/utils/crs.min.js';
/*
 */
import { v4 as uuidv4 } from 'uuid';
import {BuyCredit} from '../buycredit/buycredit';
import {TelephoneNumberModel} from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import {PAYGATE_VALIDATION_MESSAGE} from '../buycredit/paygate-validation-message';
import {CountriesTools} from '../../../../_common/tools/countries.tools';
import {ProfileStore} from '../../../../hud/_widgets/profile/profile.store';
import {ThreeDSecureDialog} from '../3d-secure-dialog/three-d-secure-dialog';
import {DialogService} from 'aurelia-dialog';
import {Router} from 'aurelia-router';
import toastr from 'toastr';
import {AddressInterface} from '../buycredit/buy-credit-interfaces/address-interface';
import {SourceInterface} from '../buycredit/buy-credit-interfaces/source-interface';
import {CustomerInterface} from '../buycredit/buy-credit-interfaces/customer-interface';
import moment from 'moment-timezone';
import {log} from "util";

/*
 */
const logger = LogManager.getLogger('UpgradeAccount');

@inject(BuyCreditService, ValidationControllerFactory, SessionStore, DateTimeTools, EventAggregator, Element, DialogService, Router)
export class UpgradeAccount {
  private showAddCard: boolean = false;
  private expirationMonth: string;
  private expirationYear: string;
  private cardNumber: string = '';
  private cardName: string;
  private cardType: string;
  private additionalAddress: boolean = false;
  private securePaymentButton: boolean;
  private selectedCard: object;
  private availableYears: Array<{ year: string }> = [];
  private availableMonths: Array<{ month: string }> = [];
  cardTypes = [{type: 'VISA'}, {type: 'MasterCard'}];

  private cvvNumber: string = '';
  private amount: string = '';
  private paymentId;
  private isProcessingPayment = false;
  private isProcessingCard = false;
  private securePaymentMessage = false;

  private cardDetailsForm;
  private cardInformationFormGroup;

  private paymentPopup: any;
  private showSecurePayment = false;
  private buyCreditModel: BuyCreditModel;
  private resetDropdown: boolean = false;

  private userProfile: {
    firstName: string;
    surname: string;
    email: string;
  };

  private oplog;
  private paymentOplog;
  private validation: any;
  private serviceValidation: any;
  private firstName: string = null;
  private surname: string = null;
  private secureUrl: string = null;
  private testValue = {
    value: 'test'
  };
  private fields: Array<any> = null;
  private country: string = null;
  private availableCountries: Array<{ name: string; id: string }> = [];
  private availableRegions: Array<{ name: string }> = [];

  private phoneNumber: TelephoneNumberModel;

  private cardInfo = {
    address1: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    postalCode: '',
    companyName: '',
    cardholderName: '',
    contactPhoneNumber: new TelephoneNumberModel('', '', 'za', true, true)
  };

  private serviceAddress = {
    addressLine1: '',
    addressLine2: '',
    serviceCity: '',
    serviceRegion: '',
    serviceCountry: 'South Africa',
    code: ''
  };

  private cardSubmittedSubscription: Subscription;
  private cardFailedSubscription: Subscription;

  constructor(private buyCreditService: BuyCreditService, private validationControllerFactory: ValidationControllerFactory, private sessionStore: SessionStore, private dateTimeTools: DateTimeTools, private eventAggregator: EventAggregator, private element: Element, private dialogService: DialogService, private router: Router) {
    this.availableYears = this.initializeYears();
    this.availableMonths = this.initializeMonths();

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.serviceValidation = validationControllerFactory.createForCurrentScope();
    this.serviceValidation.addRenderer(new BootstrapFormRenderer());
    this.serviceValidation.validateTrigger = validateTrigger.change;

    let country = this.isSouthAfricanOrg ? 'za' : 'us';
    this.phoneNumber = new TelephoneNumberModel('', '', country);

    if (country === 'za') {
      this.availableCountries = [{name: 'South Africa', id: '01'}];
      this.availableRegions = CountriesTools.getCountryRegions('za');
    } else {
      this.availableCountries = [{name: 'United States', id: '02'}];
      this.availableRegions = CountriesTools.getCountryRegions('us');
    }
  }

  private attached(): void {
    this.setupAllCardInfoValidation();
    this.setupServiceAddressValidation();
    if (!this.isSouthAfricanOrg) {
      this.subscribeToStripeForm();
    }

    this.buyCreditService
      .retrieveCards()
      .then(response => {
        this.buyCreditModel = new BuyCreditModel(response);
        this.subscribeToAccountActivity();
      })
      .catch(e => {
        logger.info('Cards could not be retrieved');
      });

    this.buyCreditService.retrievePersonalInfo().then(
      response => {
        this.userProfile = {
          firstName: response.firstName,
          surname: response.surname,
          email: response.emails[0]
        };
      },
      error => {
        logger.warn('Retrieving personal information > error =', error);
      }
    );
    this.eventAggregator.subscribe('show.loader.upgrade', () => {
      this.isProcessingCard = true;
    });

    crs.init();
  }

  private subscribeToStripeForm(): void {
    this.cardSubmittedSubscription = this.eventAggregator.subscribe('submitted.stripe.card', cardInfo => {

      this.cardInfo.contactPhoneNumber = cardInfo.result.phoneNumber;
      this.upgradeStripeCard(cardInfo.result.token, cardInfo.serviceAddress, cardInfo.address);
    });

    this.cardFailedSubscription = this.eventAggregator.subscribe('failed.stripe.card', () => {
      this.isProcessingCard = false;
    });
  }

  private upgradeStripeCard(cardToken: any, serviceAddress: any, billingAddress: any): void {
    this.isProcessingPayment = true;
    this.validation
      .validate()
      .then(validation => {
        if (!validation.valid && !this.cardInfo.contactPhoneNumber.isValid()) {
          return;
        }
        this.isProcessingPayment = true;
        this.paymentId = uuidv4();
        this.buyCreditService.subscribeToPaymentStatus(this.paymentId).then(oplog => {
          this.paymentOplog = oplog;
          this.paymentOplog.on('insert', payment => this._handlePaymentOplog(payment));
          this.paymentOplog.on('update', payment => this._handlePaymentOplog(payment));
          this.paymentOplog.on('delete', payment => this._handlePaymentOplog(payment));
        });

        let source: SourceInterface = {
          provider: 'STRIPE',
          type: 'TOKEN',
          token: {
            value: cardToken.id
          }
        };

        let customer: CustomerInterface = {
          firstName: this.userProfile.firstName,
          lastName: this.userProfile.surname,
          mobile: this.cardInfo.contactPhoneNumber.formattedNumber,
          email: this.userProfile.email
        };

        let address: AddressInterface = {
          addressLine1: billingAddress.address_line1,
          addressLine2: billingAddress.address_line2,
          city: billingAddress.address_city,
          region: billingAddress.address_state,
          country: billingAddress.address_country,
          code: billingAddress.address_zip
        };

        let payload = {
          id: this.paymentId,
          chargeRequestType: 'NEW_CARD',
          reference: this.buyCreditModel.accountId,
          source: source,
          customer: customer,
          address: address,
          serviceAddress: serviceAddress,
          value: {
            currency: this.account.available.currency,
            amount: this._convertAmount(this.amount, 'toRand')
          },
          metadata: this.buyCreditModel.metadata
        };
        return this.postSecurePayment(payload);
      })
      .then(response => {
        this.checkIf3DSecure(response);
      })
      .catch(error => {
        this.isProcessingCard = false;
        this.isProcessingPayment = false;
      });
  }

  @computedFrom('sessionStore.get.account')
  private get account(): AccountSessionModel {
    return this.sessionStore.get.account;
  }

  @computedFrom('account.available')
  private get isSouthAfricanOrg(): boolean {
    return this.account.available.currency === 'ZAR';
  }

  @computedFrom('account.currencySymbol')
  private get currencySymbol(): string {
    return this.account.currencySymbol;
  }

  private submitStripeCard(): void {
    this.validation.validate().then(validation => {
      if (validation.valid) {
        this.eventAggregator.publish('submit.stripe.card');
      }
      if (!validation.valid) {
        // this.eventAggregator.publish('submit.stripe.card');
        this.isProcessingCard = false;
        return;
      }
    })
      .catch(e => {
        this.isProcessingCard = false;
      });
  }

  private setupAllCardInfoValidation(): void {
    let checkCardBrand = cardNumber => {
      let cardType = creditCardValidator.getCardName(cardNumber);
      if (cardType === '') {
        return true;
      }
      return cardType === 'visa' || cardType === 'mastercard';
    };

    ValidationRules.customRule('validCreditCardRule', value => {
      if (!value) {
        return true;
      }
      return creditCardValidator.validateCard(value) && /^\d+$/.test(value) && value.length > 13 && value.length < 19;
    });

    ValidationRules.customRule('validCreditCardBrandRule', value => {
      return checkCardBrand(value);
    });

    ValidationRules
      .ensure('expirationMonth')
      .required()
      .withMessage('Please select expiration month.')
      .ensure('expirationYear')
      .required()
      .withMessage('Please select expiration year.')
      .ensure('cardNumber')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardNumber.empty)
      .satisfiesRule('validCreditCardBrandRule')
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardNumber.noSupported)
      .satisfiesRule('validCreditCardRule')
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardNumber.invalid)
      .ensure('cvvNumber')
      .required()
      .withMessage('CVV is required.')
      .matches(/^\d{3}$/)
      .withMessage('CVV must be 3 digits, with no hyphens or spaces.')
      .ensure('amount')
      .required()
      .withMessage('Credit amount must be numeric and greater than zero.')
      .on(this)
      .ensure('cardholderName')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.empty)
      .matches(/^([a-zA-Z'\s-])+$/)
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.invalid)
      .ensure('address1')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.address.empty)
      .ensure('city')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('country')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.country.empty)
      .ensure('postalCode')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.postalCode.empty)
      .ensure('region')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.region.empty)
      .on(this.cardInfo);
  }

  private setupServiceAddressValidation(): void {
    ValidationRules
      .ensure('addressLine1')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.address.empty)
      .ensure('serviceCity')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('code')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.postalCode.empty)
      .on(this.serviceAddress);
  }

  private regionDropdownChanged(region: any): void {
  }

  private serviceAddressRegionDropdownChanged(region: any): void {
  }

  private serviceAddressCountryDropdownChanged(country: any): void {
  }

  private countryDropdownChanged(country: any): void {
    if (country !== 'South Africa') {
      this.resetServiceAddress();
      this.additionalAddress = false;
    }
  }

  private resetServiceAddress(): void {
    this.serviceAddress = {
      addressLine1: '',
      addressLine2: '',
      serviceCity: '',
      serviceRegion: '',
      serviceCountry: 'South Africa',
      code: ''
    };
    this.setupServiceAddressValidation();
  }

  private additionalAddressChanged(): void {
    if (this.cardInfo.country === 'South Africa' && this.cardInfo.address1.length > 0 && this.cardInfo.city.length > 0 && this.cardInfo.postalCode.length > 0 && this.cardInfo.region.length > 0) {
      this.additionalAddress = !this.additionalAddress;
      this.setServiceAddressToBillingAddress();
    }
  }

  private setServiceAddressToBillingAddress(): void {
    this.serviceAddress.serviceCountry = 'South Africa';
    this.serviceAddress.serviceRegion = this.cardInfo.region;
    this.serviceAddress.code = this.cardInfo.postalCode;
    this.serviceAddress.addressLine1 = this.cardInfo.address1;
    this.serviceAddress.addressLine2 = this.cardInfo.address2 ? this.cardInfo.address2 : '';
    this.serviceAddress.serviceCity = this.cardInfo.city;
  }

  @computedFrom('cardInfo.country')
  public get selectedCountryIsSouthAfrica(): boolean {
    if (this.cardInfo.country === 'South Africa') {
      return true;
    }
  }

  private subscribeToAccountActivity(): void {
    this.buyCreditService.subscribe(this.buyCreditModel.accountId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', data => this.handleOplogChanges(data));
      this.oplog.on('update', data => this.handleOplogChanges(data));
      this.oplog.on('delete', data => this.handleOplogChanges(data));
    });
  }

  private handleOplogChanges(buyCredit: BuyCreditModel): void {
    this.buyCreditModel.updatePropreties(buyCredit);
  }

  public securePayment(): void {
    this.validation.validate().then(validation => {
      if (!validation.valid && !this.cardInfo.contactPhoneNumber.isValid()) {
        return;
      }

      this.serviceValidation.validate().then(serviceValidation => {
        if (!serviceValidation.valid) {
          return;
        }

        this.isProcessingPayment = true;

        this.paymentId = uuidv4();
        this.buyCreditService.subscribeToPaymentStatus(this.paymentId).then(oplog => {
          this.paymentOplog = oplog;

          this.paymentOplog.on('insert', payment => this._handlePaymentOplog(payment));
          this.paymentOplog.on('update', payment => this._handlePaymentOplog(payment));
          this.paymentOplog.on('delete', payment => this._handlePaymentOplog(payment));
        });

        let source: SourceInterface = {
          provider: 'PAYGATE',
          type: 'CARD',
          card: {
            number: this.cardNumber,
            holder: this.cardInfo.cardholderName,
            type: creditCardValidator.getCardName(this.cardNumber),
            expiry: {
              month: this._formatExpirationMonthNumber(this.expirationMonth.month),
              year: this.expirationYear.year
            }
          }
        };

        let customer: CustomerInterface = {
          firstName: this.userProfile.firstName,
          lastName: this.userProfile.surname,
          mobile: this.cardInfo.contactPhoneNumber.formattedNumber,
          email: this.userProfile.email
        };

        let address: AddressInterface = {
          addressLine1: this.cardInfo.address1,
          addressLine2: this.cardInfo.address2 || '',
          city: this.cardInfo.city,
          region: this.cardInfo.region,
          country: this.cardInfo.country,
          code: this.cardInfo.postalCode
        };

        let serviceAddress = {
          addressLine1: this.serviceAddress.addressLine1,
          addressLine2: this.serviceAddress.addressLine2 || '',
          city: this.serviceAddress.serviceCity,
          region: this.serviceAddress.serviceRegion,
          country: this.serviceAddress.serviceCountry,
          code: this.serviceAddress.code
        };

        if (this.additionalAddress) {
          serviceAddress = {
            addressLine1: this.cardInfo.address1,
            addressLine2: this.cardInfo.address2 || '',
            city: this.cardInfo.city,
            region: this.cardInfo.region,
            country: this.cardInfo.country,
            code: this.cardInfo.postalCode
          };
        } else {
          serviceAddress = {
            addressLine1: this.serviceAddress.addressLine1,
            addressLine2: this.serviceAddress.addressLine2 || '',
            city: this.serviceAddress.serviceCity,
            region: this.serviceAddress.serviceRegion,
            country: this.serviceAddress.serviceCountry,
            code: this.serviceAddress.code
          };
        }


        let payload = {
          id: this.paymentId,
          chargeRequestType: 'NEW_CARD',
          reference: this.buyCreditModel.accountId,
          source: source,
          customer: customer,
          address: address,
          serviceAddress: serviceAddress,
          value: {
            currency: this.account.available.currency,
            amount: this._convertAmount(this.amount, 'toRand')
          },
          securityCode: this.cvvNumber
        };
        return this.postSecurePayment(payload);

      }).then(response => {
        this.checkIf3DSecure(response);
      })
        .catch(error => {
          this.isProcessingPayment = false;
        });
    })
      .catch(error => {
        this.isProcessingPayment = false;
      });
  }

  private checkIf3DSecure(response: { redirectUri: string; params: Array<object> }): void {
    if (response.redirectUri) {
      this.secureUrl = response.redirectUri;

      let params = response.params;

      let arr = [];

      for (let entry in params) {
        arr.push({[entry]: params[entry]});
      }

      this.fields = params;
      this.open3DSecurePopup();
      this.securePaymentMessage = true;
    }
  }

  private open3DSecurePopup(): void {
    this.dialogService
      .open({
        viewModel: ThreeDSecureDialog,
        model: {url: this.secureUrl, fields: this.fields}
      })
      .whenClosed(() => {
        this.isProcessingPayment = false;
        this.securePaymentMessage = false;
        this.showSecurePayment = false;
        this.unsubscribePaymentOplog();
        this.amount = '';
        this.cardDetailsForm.reset();
      });
  }

  private _handlePaymentOplog(data: object): void {
    if (this.paymentId !== data._id) {
      return;
    }

    switch (data.status) {
      case 'Successful':
        this.unsubscribePaymentOplog();
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Payment successful, your account has been credited with ${this.currencySymbol} ${this._convertAmount(this.amount, 'toRand')}`);
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Your account has now been upgraded`);
        this.dialogService.controllers.forEach(controller => {
          controller.cancel();
        });
        this.navigateToBuyCreditPage();
        this.isProcessingPayment = false;
        this.securePaymentMessage = false;
        break;
      case 'Failed':
        this.unsubscribePaymentOplog();
        this.isProcessingPayment = false;
        this.isProcessingCard = false;
        this.securePaymentMessage = false;
        this.dialogService.controllers.forEach(controller => {
          controller.cancel();
        });
        break;
      default:
        this.securePaymentMessage = false;
        return;
    }

    this.showSecurePayment = false;
    this.unsubscribePaymentOplog();
  }

  private _convertAmount(amount: string, convertAmount: string): string {
    let amountWithoutCurrency = amount.replace(this.currencySymbol, '').trim();
    let fullAmount = amountWithoutCurrency.split('.');
    let randAmount = fullAmount[0] ? fullAmount[0] : '';
    let centAmount = fullAmount[1] ? fullAmount[1] : '';

    if (convertAmount === 'toCents') {
      if (centAmount === '' || centAmount.length > 1) {
        amountWithoutCurrency = amountWithoutCurrency * 100;
      } else {
        amountWithoutCurrency = parseFloat(randAmount + centAmount);
        amountWithoutCurrency = amountWithoutCurrency * 10;
      }
    }

    if (convertAmount === 'toRand') {
      if (centAmount.length === 0) {
        amountWithoutCurrency = amountWithoutCurrency + '.00';
      } else if (centAmount.length === 1) {
        amountWithoutCurrency = parseFloat(randAmount + centAmount);
        amountWithoutCurrency = amountWithoutCurrency / 10 + '0';
      }
    }
    return amountWithoutCurrency;
  }

  private _formatExpirationMonthNumber(rawMonthValue: string): string {
    let convertedMonthValue = this.dateTimeTools.getMonthNumber(rawMonthValue);
    if (convertedMonthValue < 10) {
      return '0' + convertedMonthValue;
    } else {
      return convertedMonthValue;
    }
  }

  private postSecurePayment(payload: any): Promise<any> {
    return this.buyCreditService.securePayment(payload);
  }

  private unsubscribePaymentOplog(): void {
    if (this.paymentOplog) {
      this.paymentOplog.unsubscribe();
    }
  }

  public _currencyCheckOnAmount(): void {
    if (this.amount.charAt(0) !== this.currencySymbol && this.amount.length > 0) {
      this.amount = this.currencySymbol + ' ' + this.amount;
    }

    if (this.amount.indexOf('.') > -1) {
      let currentAmount = this.amount.split('.');
      if (currentAmount[1].length > 2) {
        this.amount = currentAmount[0] + '.' + currentAmount[1].substring(0, 2);
      }
    }

    if (this.amount.indexOf(this.currencySymbol + ' 0') > -1 || this.amount.indexOf(this.currencySymbol + '0') > -1 || this.amount.indexOf('  ') > -1) {
      this.amount = '';
    }
  }

  public keyCheck(): void {
    this._currencyCheckOnAmount();
  }

  private navigateToBuyCreditPage(): void {
    this.router.navigate('account?tab=buy%20credit');
    this.element.dispatchEvent(
      new CustomEvent('upgrade', {
        bubbles: true,
        detail: 'buy credit'
      })
    );
  }

  public keyPress(event: Event): boolean {
    var numbers = /^[0-9 R$.]+$/;
    if (event.key.match(numbers)) {
      return true;
    }
    return false;
  }

  private initializeYears(): Array<{ year: string }> {
    let currentYear = moment().year();
    let maxYear = currentYear + 50;
    let yearsArray = [];

    while (currentYear < maxYear) {
      yearsArray.push({year: currentYear.toString()});
      currentYear++;
    }
    return yearsArray;
  }

  private initializeMonths(): Array<{ month: string }> {
    return moment.months().map((month: string) => {
      return {month: month};
    });
  }

  private detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    if (!this.isSouthAfricanOrg) {
      this.cardSubmittedSubscription.dispose();
      this.cardFailedSubscription.dispose();
    }
    this.unsubscribePaymentOplog();
  }
}
