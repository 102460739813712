import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { TeamsService } from '../teams-service';
import { AddTeamDialog } from '../addteam/add-team-dialog';
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('TeamsListView');
/*
 * */
@inject(TeamsService, Router, EventAggregator, SessionStore, DialogService, Element)
export class TeamsListView extends AbstractList {

  constructor(private teamsService: TeamsService, private router: Router, protected eventAggregator: EventAggregator, private sessionStore: SessionStore, private dialogService: DialogService, private element: Element) {
    super(eventAggregator)
  }

  protected activate(params?: any): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected subscribeToOplog(): void {
    this.teamsService.initialiseTeamsOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('update', response => {
        this.showLoader();
        let _itemList = this.teamsService._modelCollection(response.teams);
        this.setupListConfig(_itemList);
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.teamsService.retrieveOrganisationTeams(this.sessionStore.get.organisation.organisationId).then(
      _teams => {
        this.setupListConfig(this.teamsService._modelCollection(_teams));
        if (this.isAdding) {
          this.clearUrlParams();
          this.showAddItem();
        }
      },
      error => {
        logger.info('retrieve organisation teams > error = ', error);
        this.hideLoader();
      }
    );
  }

  protected deleteItems(_items:any):void {
    super.deleteItems(_items);
    this.deferredMessage = `${_items.length} team${_items.length > 1 ? 's' : ''} deleted`;
  }

  protected delete(_item: any): void {
    super.delete(_item);
    this.teamsService.removeTeam(this.sessionStore.get.organisation.organisationId, _item);
  }

  protected showAddItem(): void {
    super.showAddItem();
    this.dialogService.open({viewModel: AddTeamDialog, model: this.itemList.items}).whenClosed(_response => {
      if (!_response.wasCancelled) {
        this.deferredMessage = `Team <b>${_response['output']}</b> added.`;
        this.teamsService.addTeam(this.sessionStore.get.organisation.organisationId, _response['output']);
      } else {
        this.hideLoader();
      }
    });
  }

  protected setupListConfig(teams: ZITeam[]): void {
    super.setupListConfig(teams);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .enableAdd()
    .enableDelete()
    .items(teams)
    .icon('icon')
    .uniqueIdentifier('id')
    .displayId('name')
    .description('')
    .withProportion('medium')
    .hasRollover(false)
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }
}
