import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { OplogService, WebSocket } from 'zailab.common';
import IMModel from './im-model';
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('IMService');

@inject(HttpClient, OplogService, WebSocket)
export default class {
  constructor(httpClient, oplogService, webSocket) {
    this.httpClient = httpClient;
    this.oplog = oplogService;
    this.webSocket = webSocket;
  }

  retrieveIMEndpoints() {
    return new Promise((resolve, reject) => {
      const url = 'v1/interaction/imconfig';
      this.httpClient
        .createRequest(url)
        .asGet()
        .send()
        .then(
          response => {
            if (response.statusCode === 200) {
              response.endpoints = _modelCollection(response.endpoints || []);
            }
            resolve(response);
          },
          error => {
            reject(error);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  subscribeToActiveCampaigns(organisationId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('interaction-flow-projector.imendpoint'));
    });
  }

  createEndpoint(endpoint) {
    let configId = uuidv4();
    let payload = {
      endpoint: endpoint.endpoint,
      apiKey: endpoint.apiKey,
      channels: endpoint.channels
    };

    return this.httpClient.createRequest('v1/interaction/imconfig/' + configId)
      .asPost()
      .withContent(payload)
      .send();
  }
}

function _modelCollection(response) {
  let list = [];
  response.forEach(item => {
    list.push(_modelEntity(item));
  });
  return list;
}

function _modelEntity(item) {
  return new IMModel(item);
}
