import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
 * */
@inject(Router)
export class Join {

  constructor(router) {

    this.router = router;
  }

  login() {
    this.router.navigate('login');
  }
}