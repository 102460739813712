import { LogManager, computedFrom } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';
import { CURRENCY_TO_SYMBOL_MAP } from './../../../features/finance/account/currency-map';

const logger = LogManager.getLogger('AccountSessionModel');

export class AccountSessionModel extends BaseModel {

  public accountId: string = null;
  public available: IAvailable = { amount: null, currency: null };
  public daysRemaining: number = null;
  public status: string = null;
  public urgency: string = null;
  public isTrialAccount: boolean = true;
  public trialAccountStatus: string = null;
  public balanceStatus: string = null;
  public currencySymbol: string = null;

  constructor(data: AccountSessionModel) {
    super();
    this.mapEntity(data);
  }

  public mapEntity(data: AccountSessionModel): void {
    super.mapProperties(data);
    this.currencySymbol = CURRENCY_TO_SYMBOL_MAP[this.available.currency];
  }
}

interface IAvailable {
  amount: number;
  currency: string;
}