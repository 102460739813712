import { LogManager, autoinject, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { MailboxService, OplogService, SearchTools } from 'zailab.common';
import { IdDialogService } from '../id-dialog-service';
import { DialogService } from 'aurelia-dialog';
/**/
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { EmptyStateAbstract } from '../abstracts/empty-state-abstract';
import { Router } from 'aurelia-router';
/**/
const logger = LogManager.getLogger('Mailbox Dialog');

/**/
@autoinject
export class MailboxDialog extends EmptyStateAbstract {

  private nodeData: NodeModel;
  private mailboxes: Array<ZIMailbox> = [];
  private existingConnections: Array<ConnectorModel> = [];
  private existingNodeData: any = {};
  private selectedMailbox: ZIMailbox;
  private isSearching: boolean = false;
  private isValid: boolean = false;
  private oplog: any;
  private searchTerm: string;

  public emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-mailbox.svg',
    heading: `No mailboxes to display.`,
    description: `You haven't created any mailboxes yet.<br>Please click the link below to create a mailbox and assign an audio file.`,
    hasButton: true,
    buttonText: `Create Mailbox`,
    hasTip: true,
    tipText: `Clicking the button above will open the Mailbox Creator.`,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName(`features/mailbox/mailbox-creator-dialog`)
  };

  constructor(public dialogController: DialogController, private mailboxService: MailboxService, private idDialogService: IdDialogService, public router: Router, protected dialogService: DialogService) {
    super(dialogService);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy

    if (this.nodeData.properties) {
      this.existingNodeData = this.nodeData.properties;
    }

    this.existingConnections = this.nodeData.connections;
  }

  public attached(): void {
    this.idDialogService.initialiseOplog('_id', 'organisationId', 'mailbox-projector', 'displayOrganisationMailboxesView').then(oplog => this.subscribeToOplogChanges(oplog));
    this.getAndListMailboxes();
  }

  private subscribeToOplogChanges(_oplog: any): void {
    this.oplog = _oplog;
    this.oplog.on('update', () => this.getAndListMailboxes());
    this.oplog.on('insert', () => this.getAndListMailboxes());
  }

  private getAndListMailboxes(): void {
    this.nodeDialogInitialised = false;
    this.mailboxService.getAllMailboxes().then((_mailboxes: Array<ZIMailbox>) => {
      this.mailboxes = _mailboxes;
      this.hasEntries = !!_mailboxes.length;
      this.nodeDialogInitialised = true;
      this.mailboxes.forEach((mailbox: ZIMailbox) => {
        mailbox.hoverText = this.concatenateRecipients(mailbox);
        if (mailbox.mailboxId === this.existingNodeData.mailboxId) {
          this.selectedMailbox = mailbox;
          mailbox.isSelected = true;
          this.isValid = true;
        }
      });
      this.nodeDialogInitialised = true;
    }, (error) => {
      logger.info(' Failed to retrieve your organisation mailboxes > error = ', error);
    });
  }

  protected activateContentCreator(): void {
    super.activateContentCreator(this.mailboxes);
  }

  protected handleContentCreated(): void {
    super.handleContentCreated({});
    this.getAndListMailboxes();
  }

  private concatenateRecipients(_mailbox: ZIMailbox): string {
    if (_mailbox.recipients && _mailbox.recipients.recipients) {
      return _mailbox.recipients.recipients ? _mailbox.recipients.recipients.map((_recipient: ZIMailboxRecipient) => {
        return _recipient.email
      }).join('<br>') : 'No recipients assigned yet';
    }
  }

  private selectMailbox(_selectedMailbox: ZIMailbox): void {

    this.mailboxes.forEach(_mailbox => {
      _mailbox.isSelected = false;
    });

    this.selectedMailbox = _selectedMailbox;
    _selectedMailbox.isSelected = true;
    this.isValid = true;
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  private ok(): void {
    this.dialogController.ok(this.mapNodeProperties());
  }

  public doNothing(): void {}

  private createPayload(): any {

    return {
      mailboxName: this.selectedMailbox.mailboxName,
      mailboxId: this.selectedMailbox.mailboxId,
      audioId: this.selectedMailbox.audioId,
      textMessage: this.selectedMailbox.textToSpeechAttributes ? this.selectedMailbox.textToSpeechAttributes.text : '',
      members: this.selectedMailbox.recipients,
      audioMessages: { audioId: this.selectedMailbox.audioId, description: '' },
      recipientId: '',
      speechLanguage: this.selectedMailbox.textToSpeechAttributes ? this.selectedMailbox.textToSpeechAttributes.speechLanguage : {},
      languageCode: this.selectedMailbox.textToSpeechAttributes ? this.selectedMailbox.textToSpeechAttributes.speechLanguage.languageCode : '',
      speechRate: this.selectedMailbox.textToSpeechAttributes ? this.selectedMailbox.textToSpeechAttributes.speechRate : '',
      textToSpeechId: this.selectedMailbox.textToSpeechAttributes ? this.selectedMailbox.textToSpeechAttributes.textToSpeechId : '',
      audioFileName: 'notused.wav',
      audioSource: 'upload',
      membersToRemove: []
    }
  }

  private mapNodeProperties(): NodeModel {
    this.nodeData.properties = this.createPayload();
    this.nodeData.properties.isDefined = true;
    this.nodeData.properties.name = this.selectedMailbox.mailboxName;
    this.nodeData.connections = this.existingConnections;
    return new NodeModel(this.nodeData);
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  public startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

}
