import { DialogController, DialogService } from 'aurelia-dialog';
import { LogManager, inject } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { InteractionAnalyticsConfiguration } from '../../interaction-analytics-configuration-model';
import OrganisationService from '../../organisation-service';
import { VerintAnalyticsService } from '../verint-analytics-service';
import { VerintTextAnalyticsWorktypeConfigurationDialog } from "./verint-text-analytics-worktype-configuration";

const logger = LogManager.getLogger('VerintTextAnalyticsConfigurationDialog');

@inject(DialogService, DialogController, ValidationControllerFactory, OrganisationService, VerintAnalyticsService, SessionStore)
export class VerintTextAnalyticsConfigurationDialog {

  workforceManagementId;
  dialogHeader = "Verint Text Analytics Configuration";
  verintTextInteractionAnalyticsConfiguration = new InteractionAnalyticsConfiguration();
  ingestionApiAddress;
  keyId;
  keyValue;
  textStoreId;
  textProjectId;
  languageCode;
  enabled;
  enabledTextWorkTypes;

  constructor(dialogService, dialogController, validationControllerFactory, organisationService, verintAnalyticsService, sessionStore) {
    this.dialogController = dialogController;
    this.dialogService = dialogService;
    this.organisationService = organisationService;
    this.verintAnalyticsService = verintAnalyticsService;
    this.sessionStore = sessionStore;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(model) {
    this.workforceManagementId = model.data.workforceManagementId;
    this.isProcessing = true;
    this.verintAnalyticsService.findTextAnalyticsConfig().then((providerConfig) => {
    if (providerConfig.properties) {
      this.ingestionApiAddress = providerConfig.properties.ingestionApiAddress;
      this.keyId = providerConfig.properties.keyId;
      this.keyValue = providerConfig.properties.keyValue;
      this.textStoreId = providerConfig.properties.textStoreId;
      this.textProjectId = providerConfig.properties.textProjectId;
      this.languageCode = providerConfig.properties.languageCode;
    }
    this.enabled = providerConfig.enabled;
    this.verintTextInteractionAnalyticsConfiguration.enabledWorkTypes = providerConfig.enabledWorkTypes ? providerConfig.enabledWorkTypes : [];
    this.verintTextInteractionAnalyticsConfiguration.allWorkTypesEnabled = providerConfig.allWorkTypesEnabled !== undefined ? providerConfig.allWorkTypesEnabled : true;
  }).then(() => this.isProcessing = false);
    ValidationRules
      .ensure('ingestionApiAddress').required()
      .ensure('keyId').required()
      .ensure('keyValue').required()
      .ensure('textStoreId').required()
      .ensure('textProjectId').required()
      .ensure('languageCode').required()
      .ensure('enabledWorkTypes').required()
      .on(this);
  }

  selectEnabledTextWorkTypes() {
    this.dialogService.open({ 
      viewModel: VerintTextAnalyticsWorktypeConfigurationDialog, 
      model:  {
        allWorkTypesEnabled: this.verintTextInteractionAnalyticsConfiguration.allWorkTypesEnabled,
        enabledWorkTypes: this.verintTextInteractionAnalyticsConfiguration.enabledWorkTypes
      }
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.enabledTextWorkTypes = response.output.enabledWorkTypes;
        this.verintTextInteractionAnalyticsConfiguration.allWorkTypesEnabled = response.output.allWorkTypesEnabled;
      }
    });
  }


  cancel() {
    this.dialogController.cancel();
  }

  save() {
    this.isProcessing = true;
    this.loading = true;
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        this.loading = false;
        return;
      }
      this.verintTextInteractionAnalyticsConfiguration.properties.ingestionApiAddress = this.ingestionApiAddress;
      this.verintTextInteractionAnalyticsConfiguration.properties.keyId = this.keyId;
      this.verintTextInteractionAnalyticsConfiguration.properties.keyValue = this.keyValue;
      this.verintTextInteractionAnalyticsConfiguration.properties.textStoreId = this.textStoreId;
      this.verintTextInteractionAnalyticsConfiguration.properties.textProjectId = this.textProjectId;
      this.verintTextInteractionAnalyticsConfiguration.properties.languageCode = this.languageCode;
      this.verintTextInteractionAnalyticsConfiguration.name = 'Verint';
      this.verintTextInteractionAnalyticsConfiguration.type = 'TEXT';
      this.verintTextInteractionAnalyticsConfiguration.enabled = !!this.enabled;
      if (this.enabledTextWorkTypes) {
        this.verintTextInteractionAnalyticsConfiguration.enabledWorkTypes = 
            this.filterWorkTypes(this.enabledTextWorkTypes);
      }

      this.organisationService
        .setTextAnalytics(this.sessionStore.get.organisation.organisationId, this.workforceManagementId, this.verintTextInteractionAnalyticsConfiguration)
        .then(() => {
          this.loading = false;
          this.isProcessing = false;
        })
        .catch(error => {
          logger.error('Unable to set analytics configuration ', error);
          this.isProcessing = false;
        });
    });
  }

  filterWorkTypes(enabledWorkTypes) {
    if (!enabledWorkTypes) {
      return [];
    }
    let filteredEnabledWorkTypes = [];
    for (let key of Object.keys(enabledWorkTypes)) {
        if (enabledWorkTypes[key]) {
          filteredEnabledWorkTypes.push(key);
        }
    }
    return filteredEnabledWorkTypes;
  }

  toggleEnabled() {
    this.enabled = !this.enabled;
  }
}

