import {Router} from 'aurelia-router';
import {inject, LogManager, PLATFORM} from 'aurelia-framework';
/*
*/
import {WorkforceDashboardService} from './workforce-management-dashboard-service';
import {WorkforceDashboardStore} from './workforce-management-dashboard-store';
/*
*/
const logger = LogManager.getLogger('WorkforceDashboard');
/*
*/
@inject(Router, WorkforceDashboardService, WorkforceDashboardStore)
export class WorkforceDashboard {

  constructor(router, workforceDashboardService, workforceDashboardStore) {

    this.router = router;
    this.workforceDashboardService = workforceDashboardService;
    this.workforceDashboardStore = workforceDashboardStore;
  }

  configureRouter(config, router) {

    let routeMap = [
      {route: '',   name: 'expandedchart',    moduleId: PLATFORM.moduleName('features/organisation/workforcemanagement/views/expandedchart/expanded-chart'),       nav: false, title: 'Workforce Dashboard'}
    ];

    config.map(routeMap);
    this.router = router;

  }

}
