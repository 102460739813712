import {LogManager, inject, customElement, bindable} from 'aurelia-framework';

const logger = LogManager.getLogger('PhoneticAlphabet');

@customElement('z-phonetic-alphabet')
export class PhoneticAlphabet {

  showPhoneticAlphabet = false;

  @bindable phoneticAlphabet;

  phoneticAlphabet = [
    'Alpha',
    'Bravo',
    'Charlie',
    'Delta',
    'Echo',
    'Foxtrot',
    'Golf',
    'Hotel',
    'India',
    'Juliet',
    'Kilo',
    'Lima',
    'Mike',
    'November',
    'Oscar',
    'Papa',
    'Quebec',
    'Romeo',
    'Sierra',
    'Tango',
    'Uniform',
    'Victor',
    'Whiskey',
    'X-ray',
    'Yankee',
    'Zulu'
  ];

  togglePhoneticAlphabet() {
    this.showPhoneticAlphabet = !this.showPhoneticAlphabet;
  }
}
