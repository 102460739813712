import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';

import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';

import './assistant-dialog.scss';
import {FormatTools} from "../../../../../../_common/tools/format-tools";

const logger = LogManager.getLogger('InteractionFlowAssistantDialog');

declare const PLATFORM;

@autoinject
export class InteractionFlowAssistantDialog {

  private nodeData: NodeModel;
  private steps: object[];
  private existingConnections: ConnectorModel[];
  private nodeConfiguration: ZNodeConfig;

  constructor(
    private dialogController: DialogController,
  ) {}

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.initSteps();
  }

  private initSteps(): void {
    const data = {
      ...this.nodeData.properties,
      version: this.nodeData.version
    }

    this.steps = [{
      name: 'Assistant',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/assistant/steps/step-1-assistant/step-1-assistant'),
      active: true,
      completed: false,
      data
    }, {
      name: 'Assistant Timeout',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/assistant/steps/step-2-timeout/step-2-timeout'),
      completed: false,
      data
    }];
  }

  public complete(wizardData: any): void {
    const nudgeEnabled = wizardData.detail.nudgeMessageEnabled;

    let properties: object = {
      name: wizardData.detail.name,
      assistantId: wizardData.detail.assistantId,
      nudgeMessageEnabled: nudgeEnabled,
      nudgeMessage: nudgeEnabled ? wizardData.detail.nudgeMessage : '',
      nudgeMessageInterval: FormatTools.parseIntOrDefault(wizardData.detail.nudgeMessageInterval, 8),
      nudgeMessageDisconnectTimeout: FormatTools.parseIntOrDefault(wizardData.detail.nudgeMessageDisconnectTimeout, 12),
    };

    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    updatedNode.config = null;
    this.dialogController.ok(updatedNode);
  }

  private mapNodeProperties(_properties: any): NodeModel {
    this.nodeData.properties = _properties;
    this.nodeData.properties.isDefined = true;

    return new NodeModel(this.nodeData);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
