import { DialogController } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { CurrentScopeValidation } from "zailab.common";
import { AgentScriptAdminConfigState } from "./agent-script-admin-config-state";

const logger = LogManager.getLogger('AgentScriptAdminConfigUpdateDialog');

@autoinject
export class AgentScriptAdminConfigUpdateDialog {

  public loading = false;
  public valid = false;
  public selectedWorkTypes = [];
  public selectedWorkType = null;
  public name = '';
  public id = '';
  public addedWorkTypes = [];
  public removedWorkTypes = [];

  private validation: any;

  constructor(
    private dialog: DialogController,
    private state: AgentScriptAdminConfigState,
    validate: CurrentScopeValidation,
  ) {
    this.validation = validate.getController();
  }

  public activate(item: any): void {
    this.id = item.id;
    this.name = item.name;
    if (item.workTypeIds) {
      this.selectedWorkTypes = this.state.workTypes.filter((workType) => item.workTypeIds.indexOf(workType.templateId) >= 0);
    }
    this.initValidation();
  }

  private initValidation(): void {
    ValidationRules
      .customRule('agentScriptAdminConfigUniqueName', (value) => {
        if (this.state.agentScriptAdminConfigList.length > 0) {
          for (let item of this.state.agentScriptAdminConfigList) {
            if (item.id !== this.id && item.name.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      }, 'Please enter a unique name.');
    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter the agent script name.')
      .satisfiesRule('agentScriptAdminConfigUniqueName')
      .on(this);
  }

  public select(event: any): void {
    const workType = event.detail;
    if (!workType) {
      return;
    }
    this.selectedWorkTypes.push(workType);
    this.addedWorkTypes.push(workType);
    this.state.addSelectedWorkType(workType);
  }

  public removeWorkType(workType: any): void {
    const index = this.selectedWorkTypes.indexOf(workType);
    this.selectedWorkTypes.splice(index, 1);
    this.removedWorkTypes.push(workType);
    this.state.removeSelectedWorkType(workType);
  }

  public close(): void {
    this.addedWorkTypes.forEach((workType) => this.state.removeSelectedWorkType(workType));
    this.removedWorkTypes.forEach((workType) => this.state.addSelectedWorkType(workType));
    this.dialog.cancel();
  }

  public save(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.loading = true;
      const id = this.id;
      const name = this.name;
      const workTypeIds = this.selectedWorkTypes.map((workType) => workType.templateId);
      this.state.editAgentScriptAdminConfig({ id, name, workTypeIds }).then(() => {
        this.dialog.ok({ id, name, workTypeIds });
        this.loading = false;
      }).catch((error) => {
        logger.error(error);
        this.loading = false;
      });
    });
  }
}