import { inject, Factory } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-http-client';

import { PASSWORD_ACTIONS } from './password-actions';
import {
  CommandFactory,
  UrlFactory,
  WINDOW_EVENTS,
  EncryptTools,
} from 'zailab.common';
import { UserRegistrationSettings } from '../../registration/user-registration-settings';

@inject(EventAggregator, HttpClient, Factory.of(CommandFactory), UrlFactory)
export class PasswordService {
  capslockOnPasswordDetection;

  constructor(eventAggregator, httpClient, commandFactory, urlFactory) {
    this.eventAggregator = eventAggregator;
    this.httpClient = httpClient;
    this.commandFactory = commandFactory;
    this.urlFactory = urlFactory;
  }

  requestPasswordReset(_email) {
    return this.getPassportIdByEmail(_email)
      .then((data) => {
        this.httpClient
          .createRequest(
            `v1/user/passports/${data.passportId}/password/request-reset`
          )
          .asPut()
          .withHeader(
            'Authorization',
            'Bearer eyJhbGciOiJIUzUxMiJ9.eyJuYmYiOjE1MjE4MTMxNzIsImlzcyI6InphaWxhYiIsIlVTRVIiOiJ7XCJ1c2VySWRcIjpcIlwiLFwidXNlcm5hbWVcIjpcIlwiLFwib3JnYW5pc2F0aW9uSWRcIjpcIlwiLFwicm9sZXNcIjpbe1wib3JnYW5pc2F0aW9uSWRcIjpcIlwiLFwiYWNjb3VudFR5cGVcIjpcIlwiLFwicm9sZVwiOlwiQU5PTllNT1VTXCIsXCJtZW1iZXJJZFwiOlwiXCJ9XX0iLCJpYXQiOjE1MjE4MTMxNzIsImp0aSI6ImQwM2ZkODA1LTFhY2MtNDZhMS05OTk3LTBkNThhOTY2ZGY5OCJ9.BtftO_lIUtRDr65RjWHny_nkCLdXK4jga3HjwpVW05N7vcAjyns4uPD51r4Xj9yOXRJC7v0X9oOW7Q7fMjaHGQ'
          )
          .withContent({ email: _email })
          .send();
      })
      .catch((e) => console.log(' > Failed to get reset request due to', e));
  }

  getPassportIdByEmail(_email) {
    return this.httpClient
      .createRequest(`v1/user/passports/password/request-reset`)
      .asGet()
      .withParams({ email: _email })
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }

  resetPassword(_newPassword, _passportId) {
    return this.httpClient
      .createRequest(`v1/user/passports/${_passportId}/password/reset`)
      .asPut()
      .withHeader(
        'Authorization',
        'Bearer eyJhbGciOiJIUzUxMiJ9.eyJuYmYiOjE1MjE4MTMxNzIsImlzcyI6InphaWxhYiIsIlVTRVIiOiJ7XCJ1c2VySWRcIjpcIlwiLFwidXNlcm5hbWVcIjpcIlwiLFwib3JnYW5pc2F0aW9uSWRcIjpcIlwiLFwicm9sZXNcIjpbe1wib3JnYW5pc2F0aW9uSWRcIjpcIlwiLFwiYWNjb3VudFR5cGVcIjpcIlwiLFwicm9sZVwiOlwiQU5PTllNT1VTXCIsXCJtZW1iZXJJZFwiOlwiXCJ9XX0iLCJpYXQiOjE1MjE4MTMxNzIsImp0aSI6ImQwM2ZkODA1LTFhY2MtNDZhMS05OTk3LTBkNThhOTY2ZGY5OCJ9.BtftO_lIUtRDr65RjWHny_nkCLdXK4jga3HjwpVW05N7vcAjyns4uPD51r4Xj9yOXRJC7v0X9oOW7Q7fMjaHGQ'
      )
      .withContent({ newPassword: _newPassword })
      .send();
  }

  changePassword(_oldPassword, _newPassword, _passportId) {
    const requestParameters = {
      previousPassword: EncryptTools.encrypt(_oldPassword),
      newPassword: EncryptTools.encrypt(_newPassword),
    };

    return this.httpClient
      .createRequest(`v1/user/passports/${_passportId}/password`)
      .asPut()
      .withContent(requestParameters)
      .send();
  }

  enableCapslockOnPasswordDetection() {
    this.capslockOnPasswordDetection = this.eventAggregator.subscribe(
      WINDOW_EVENTS.ON_CAPSLOCK_DETECTED,
      (data) => {
        // TODO this gets called for every key event - not safe
        this.eventAggregator.publish(
          PASSWORD_ACTIONS.CAPS_LOCK_ERROR,
          data.settings.message
        );
      }
    );

    this.capslockOffPasswordDetection = this.eventAggregator.subscribe(
      WINDOW_EVENTS.OFF_CAPSLOCK_DETECTED,
      (data) => {
        // TODO this gets called for every key event - not safe
        this.eventAggregator.publish(PASSWORD_ACTIONS.CAPS_LOCK_ERROR, null);
      }
    );
  }

  /**
   * un-subscribes to capslock changes as you are typing
   * */
  disableCapslockOnPasswordDetection() {
    this.capslockOnPasswordDetection.dispose();
    this.capslockOffPasswordDetection.dispose();
  }
}
