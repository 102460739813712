import { LogManager, autoinject, Factory } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
import { WebSocket, CommandFactory } from 'zailab.common';
import { AccountDetailPayloadModel } from '../accountdetails/account-detail-payload-model';
/*
 */
const logger = LogManager.getLogger('AccountDetailsService');
/*
 */
@autoinject()
export class AccountDetailsService {

  constructor(private webSocket: WebSocket, private commandFactory: CommandFactory, private httpClient: HttpClient, private eventAggregator: EventAggregator) {
  }

  /**
   * API calls to save account and card information
   */
  public saveAccountInfo(companyInformation: AccountDetailPayloadModel): any {

    let uri = `/v1/finance/accounts/${companyInformation.accountId}/company-information`;

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(uri)
        .asPut()
        .withContent(companyInformation)
        .send().then(response => resolve(), error => reject(error));

    });
  }

  public retrieveAccountStatus(): any {

    return new Promise((resolve, reject) => {
      this.createRequest('/v1/finance/accounts/me/account-status', response => resolve(response), error => reject(error));
    });

  }

  public retrieveCompanyInformation(): any {

    return new Promise((resolve, reject) => {
      this.createRequest('/v1/finance/accounts/me/company-information', (response) => {

        let companyInformation: AccountDetailPayloadModel = new AccountDetailPayloadModel(response);

        resolve(companyInformation);

      }, error => reject(error));
    });

  }

  private createRequest(uri: any, callback: any, errorCallback: any): any {
    this.httpClient.createRequest(uri)
      .asGet()
      .send().then(
        response => callback(response),
        error => errorCallback(error));
  }

  public modelCollection(items: any, Model: any): any {

    if (!items) {
      return;
    }

    if (items instanceof Array) {

      let mappedList: Array<any> = [];

      for (let item of items) {

        mappedList.push(new Model(item))
      }

      return mappedList;
    }

    let mappedObject: object = new Model(items);

    return mappedObject;
  }
}
