import {LogManager} from 'aurelia-framework';

import {ChannelModel} from './channel-model';

const logger = LogManager.getLogger('NoteChannelModel');

export class NoteChannelModel extends ChannelModel {

  constructor(size?: string) {
    super('note', 'feather');
    this.size = size ? size : 'small';
  }
}
