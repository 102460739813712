import { inject, LogManager, observable } from 'aurelia-framework';

import { PurchaseNumbers } from '../purchase-numbers';
import { SessionStore } from 'zailab.common';
import {FeatureFlagService} from "../../../../../featureflags/feature-flag-service";

const logger = LogManager.getLogger('PurchaseNumbersCriteria');

@inject(PurchaseNumbers, SessionStore, FeatureFlagService)
export class PurchaseNumbersCriteria {

  showNumberTypeDropdownList = null;
  error = null;
  selectedCountryCode = '';
  countryCode = '';
  prefix = '';
  @observable numberPrefix = '';
  regions = [];
  currentRegions = {
    ZA: { region: 'South Africa', regionCode: 'ZA', code: '+27' },
    US: { region: 'United States', regionCode: 'US', code: '+1' },
    AT: { region: 'Austria', regionCode: 'AT', code: '+43' },
    BE: { region: 'Belgium ', regionCode: 'BE', code: '+32' },
    EE: { region: 'Estonia', regionCode: 'EE', code: '+372' },
    FI: { region: 'Finland', regionCode: 'FI', code: '+358' },
    FR: { region: 'France', regionCode: 'FR', code: '+33' },
    DE: { region: 'Germany', regionCode: 'DE', code: '+49' },
    GR: { region: 'Greece', regionCode: 'GR', code: '+30' },
    IE: { region: 'Ireland', regionCode: 'US', code: '+353' },
    IT: { region: 'Italy', regionCode: 'IE', code: '+39' },
    LV: { region: 'Latvia', regionCode: 'LV', code: '+371' },
    LT: { region: 'Lithuania', regionCode: 'LT', code: '+370' },
    LU: { region: 'Luxembourg', regionCode: 'LU', code: '+352' },
    MT: { region: 'Malta', regionCode: 'MT', code: '+356' },
    NL: { region: 'Netherlands', regionCode: 'NL', code: '+31' },
    PT: { region: 'Portugal', regionCode: 'PT', code: '+351' },
    SK: { region: 'Slovakia', regionCode: 'SK', code: '+421' },
    SI: { region: 'Slovenia', regionCode: 'SI', code: '+386' },
    ES: { region: 'Spain', regionCode: 'ES', code: '+34' },
  };
  numberTypes = [{ type: 'VOICE' }, { type: 'SMS' }, { type: 'TOLL-FREE' }];

  constructor(purchaseNumbers, sessionStore, featureFlagService) {
    this.purchaseNumbers = purchaseNumbers;
    this.sessionStore = sessionStore;
    this.featureFlagService = featureFlagService;
  }

  activate() {

    const region = this.currentRegions[this.sessionStore.get.organisation.country.code];
    if (region) {
      this.regions.push(region);
    }
  }

  keyPress(event) {
    var numbers = /^[0-9]+$/;
    if (event.key.match(numbers)) {
      return true;
    }
  }

  numberPrefixChanged() {
    this.purchaseNumbers.numberPrefix = this.countryCode + this.numberPrefix;
  }

  selectedRegion(item) {
    this.setSavedPrefix();
    this.countryCode = item.code;
    this.purchaseNumbers.countryCode = item.regionCode;
    this.setLocalPrefix();
    this.countryCodeChanged();
  }

  setSavedPrefix() {
    if (this.purchaseNumbers.numberPrefix) {
      this.purchaseNumbers.numberPrefix = this.strippedPrefix();
    }
  }

  setLocalPrefix() {
    if (this.purchaseNumbers.numberPrefix) {
      this.numberPrefix = this.strippedPrefix();
    }
  }

  strippedPrefix() {
    return this.purchaseNumbers.numberPrefix.replace(this.countryCode, '');;
  }

  countryCodeChanged() {
    this.purchaseNumbers.numberPrefix = this.countryCode + this.numberPrefix;
  }
}
