/*
*/
import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
/*
*/
import { HttpClient } from 'aurelia-http-client';
/*
*/
import { FORM_ACTIONS } from './form.actions';
import { LIST_ACTIONS } from "../list/list.actions";
/*
*/
const logger = LogManager.getLogger('FormService');

/*
*/
@inject(Dispatcher, HttpClient)
export class FormService {

  constructor(dispatcher, httpClient) {

    this.dispatcher = dispatcher;
    this.httpClient = httpClient;
  }

  retrieveForm(formId) {
    this.httpClient.createRequest(`v1/organisation/forms/${formId}/perform-qa`)
      .asGet()
      .send()
      .then(
        (response) => {
          this.dispatcher.dispatch(FORM_ACTIONS.RETRIEVE_FORM, response);
        }, (error) => {
          this.dispatcher.dispatch(FORM_ACTIONS.RETRIEVE_FORM, [])
        });
  }

  clearStore() {

    this.dispatcher.dispatch(FORM_ACTIONS.CLEAR_FORM, []);
  }
}