import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('LdapGroupModel');
/*
 * */
export class LdapGroupModel extends BaseModel {
  _ldapGroupId = null;
  _ldapPath = null;
  _channels = [];
  _classOfService = null;
  _role = null;
  _services = [];
  _site = null;
  _skills = null;

  canDelete = true;

  constructor(group) {
    super();
    this.ldapGroupId = group._id || group.ldapGroupId;
    this.channels = group.channels || [];
    this.classOfService = group.classOfService || [];
    this.services = group.services || [];
    this.site = group.sites || [];
    this.skills = group.skills || [];
    this.role = group.role;
    this.ldapPath = group.ldapPath;
  }

  toPayload() {
    return {
      ldapPath: this._ldapPath,
      channels: this._channels,
      classOfService: this._classOfService,
      role: this._role,
      services: this._services,
      site: this._site,
      skills: this._skills
    }
  }

  set ldapGroupId (groupId) {
    this._ldapGroupId = groupId;
  }

  @computedFrom('_ldapGroupId')
  get ldapGroupId() {
    return this._ldapGroupId;
  }

  set channels(channels) {
    channels.forEach((item) => {
      if(item.isSelected) {
        this._channels.push(item.channelName);
      }
    });
  }

  @computedFrom('_channels')
  get channels() {
    return this._channels;
  }

  set role(role) {
    this._role = role;
  }

  @computedFrom('_role')
  get role() {
    return this._role;
  }

  set ldapPath(path) {
    this._ldapPath = path;
  }

  @computedFrom('_ldapPath')
  get ldapPath() {
    return this._ldapPath;
  }

  set classOfService(classesOfService) {
    if (classesOfService.length === 0) {
      this._classOfService = '';

      return;
    }
     for(let item of classesOfService) {
      if(item.isSelected) {
        this._classOfService = item.name;
        return;
      }
    }
  }

  @computedFrom('_classOfService')
  get classOfService() {
    return this._classOfService;
  }

  set services(services) {
    let _services = [];
    for(let service of services) {
      if(service.isSelected) {
        _services.push({
          id: service.serviceId,
          name: service.serviceName
        });
      }
    }
    this._services = _services;
  }

  @computedFrom('_services')
  get services() {
    return this._services;
  }

  set site(sites) {
    for(let site of sites) {
      if(site.isSelected) {
        this._site = {
          id: site.siteId,
          name: site.siteName
        };
        return;
      }
    }
  }

  @computedFrom('_site')
  get site() {
    return this._site;
  }

  set skills(skillGroups) {
    let _skills = [];
    for(let skillGroup of skillGroups) {
      for (let skill of skillGroup.skills) {
        _skills.push({
          skillGroupId: skill.skillGroupId,
          skillId: skill.skillId,
          skillName: skill.skillName
        })
      }
    }
    this._skills = _skills;
  }

  get skills() {
    return this._skills;
  }


}