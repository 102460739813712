import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZBar');

/*
 */
@customElement('z-bar')
export class ZBar {

  @bindable size: number;
  @bindable value: number;
  @bindable avg: number;
  @bindable color: string;
  @bindable({attribute: 'has-tooltip'}) private hasTooltip: boolean = false;

  private showTooltip: boolean = false;
  private tooltipReference: HTMLElement;

  showGraphTooltip(): void {
    if (this.hasTooltip) {
      this.showTooltip = true;
    }
  }

  hideGraphTooltip(): void {
    if (this.hasTooltip) {
      this.showTooltip = false;
    }
  }

  showToolTipDynamically(): void {
    let divisionNumber:number = 2;
    let bounds:any = event.target.getBoundingClientRect();
    let xOffset:number = event.clientX - bounds.left;
    this.tooltipReference.style.left = (xOffset - this.tooltipReference.offsetWidth/divisionNumber)+ 'px';
  }
}
