import { autoinject } from "aurelia-dependency-injection";
import { HttpClient } from "aurelia-http-client";

@autoinject
export class QAReportService {

  constructor(
    private httpClient: HttpClient
  ) {}

  public getQABPReport(date: string): Promise<any> {
    return this.httpClient
    .createRequest(`v1/organisation/qualityassurance/reports/bpreport`)
    .asGet()
    .withParams({
      date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .send();
  }

  public getQABPAgentReport(date: string, businessPartnerId: string, serviceName: string, taskTemplateName: string): Promise<any> {
    return this.httpClient
    .createRequest(`v1/organisation/qualityassurance/reports/bpagentreport`)
    .asGet()
    .withParams({
      date: 'TODAY',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      businessPartnerId,
      serviceName,
      taskTemplateName
    })
    .send();
  }

  

  public getQATeamReport(date: string): Promise<any> {
    return this.httpClient
    .createRequest(`v1/organisation/qualityassurance/reports/teamreport`)
    .asGet()
    .withParams({
      date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .send();
  }

  public getQATeamAgentReport(date: string, teamId: string, serviceName: string, taskTemplateName: string): Promise<any> {
    return this.httpClient
    .createRequest(`v1/organisation/qualityassurance/reports/teamagentreport`)
    .asGet()
    .withParams({
      date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      teamId,
      serviceName,
      taskTemplateName
    })
    .send();
  }

  public getQAAgentReport(date: string, memberId: string, serviceName: string, taskTemplateName: string): Promise<any> {
    return this.httpClient
    .createRequest(`v1/organisation/qualityassurance/reports/agentreport`)
    .asGet()
    .withParams({
      date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      memberId,
      serviceName,
      taskTemplateName
    })
    .send();
  }

  public getAssessmentResult(assessmentId: string): any {
    return this.httpClient.createRequest(`v1/organisation/assessments/${assessmentId}`)
      .asGet()
      .send();
  } 

  public getAssessmentForms(): any {
    return this.httpClient.createRequest(`v1/organisation/forms`);
  }
}
