import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DashboardWidget } from '../../organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('AlertWidget');

@autoinject()
export class AlertWidget extends DashboardWidget {

  constructor(public router: Router) {
    super();
  }
}
