import {LogManager, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import { DashboardWidget } from '../../../../organisation/organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('Interaction Logs Widget');

@autoinject()
export class InteractionLogsWidget extends DashboardWidget {
  constructor(public router: Router) {
    super();
  }
}