import { bindable, customElement, LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('ZGridNodeConnector');

customElement('z-grid-node-connector')
export class ZGridNodeConnector {

  @bindable 
  name: string;

  @bindable 
  connected: boolean;

  @bindable 
  hover: boolean;

  @bindable 
  showName: boolean;
}