import { LogManager } from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ListActions');
/*
 */
export class SingleSelect {

  required = false;

  constructor(required, element) {
    this.required = required;
    this.element = element;

    return (item, list) => {
      this._canSelect(item, list, this.required);
    }
  }

  _canSelect(item, list, required) {

    if (item.isPlaceholder) {
      return;
    }
    if (item.isSelected) {
      if (required) {
        return;
      }
      item.isSelected = !item.isSelected;
      return;
    }

    for (let _item of list) {
      _item.isSelected = false;
    }
    this._selectItem(item);
  }

  _selectItem(item) {
    if (item.isDisabled) {
      return;
    }
    item.isSelected = true;
  }
}
/*
 * */
export class MultipleSelect {

  required = false;
  uniqueIdentifier;

  constructor(required, element) {
    this.required = required;
    this.element = element;

    return (item, list) => {
      this._select(item, list, required);
    };
  }

  _select(item, list, required) {

    let count = 0;
    for (let _item of list) {
      if (_item.isSelected) {
        count++;
      }
    }
    if ((required && count === 1 && item.isSelected) || item.isPlaceholder) {
      return;
    }
    this._toggleItemSelectedState(item);
  }

  _toggleItemSelectedState(item) {
    if (item.isDisabled) {
      item.isSelected = false;
      return;
    }
    item.isSelected = !item.isSelected;
  }
}
/*
* */
export class ToggleDelete {

  list;

  constructor(list) {
    this.list = list;
    return () => {
      this._toggleDeleteState();
    };
  }

  _toggleDeleteState(value) {
    if (this.list.isBulkEditing){
      return;
    }

    this.list.isDeleting = value || !this.list.isDeleting;
    if (!this.list.isDeleting) {
      for (let item of this.list.items) {
        item.isDeleting = false;
      }
    }
  }

  static toggleItemDeletion(item) {
    if (item.isSelected) {
      return;
    }
    item.isDeleting = !item.isDeleting;
  }
}
/*
* */
export class ToggleBulkEdit {

  list;

  constructor(list) {
    this.list = list;
    return () => {
      this._toggleBulkEditState();
    };
  }

  _toggleBulkEditState(value) {
    if (this.list.isDeleting){
      return;
    }

    this.list.isBulkEditing = value || !this.list.isBulkEditing;
    if (!this.list.isBulkEditing) {
      for (let item of this.list.items) {
        item.isBulkEditing = false;
      }
    }
  }

  static toggleItemBulkEditing(item) {
    if (item.isSelected) {
      return;
    }
    item.isBulkEditing = !item.isBulkEditing;
  }
}
/*
* */
export class ToggleSearch {

  list;

  constructor(list) {
    this.list = list;
    return () => {
      this._toggleSearchState();
    };
  }

  _toggleSearchState(value) {
    this.list.isSearching = value || !this.list.isSearching;
    if (!this.list.isSearching) {
      this.list.isSearching = false;
      this.list.searchTerm = '';
    }
  }
}
/*
* */
export class ToggleEdit {

  list;

  constructor(list) {
    this.list = list;
    return () => {
      this._toggleEditState();
    };
  }

  _toggleEditState(value) {
    this.list.isEditing = value || !this.list.isEditing;

    if (this.list.isEditing) {

      let _items = [];

      for (let item of this.list.items) {
        _items.push(Object.assign({}, item));
      }

      this.list.clonedItems = _items;
    }
  }
}
