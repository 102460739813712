import { computedFrom } from 'aurelia-binding';
import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject, bindable, LogManager, PLATFORM } from 'aurelia-framework';
import { FetchAudioService } from 'zailab.common';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { AUDIO_TOOLS } from '../../../../../../_common/tools/audio-tools';
import { EmptyAudioAbstract } from '../abstracts/empty-audio-abstract';
import { AudioSearchFilter } from '../audio-search-filter';

const logger = LogManager.getLogger('PlayMessageDialog');

@autoinject
export class PlayMessageDialog extends EmptyAudioAbstract {

  public isInterruptible: boolean = false;
  public messageName: string = '';
  public languageVariableName: string = '';

  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];

  public emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-audio.svg',
    heading: `Your organisation does not yet have any audio messages.`,
    description: `You can create an audio message by uploading a WAV file or creating a text-to-speech audio message.`,
    buttonText: `Create Audio`,
    hasButton: true,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName('features/media/media/audiofiles/addaudio/add-audio-dialog'),
    hasTip: true,
    tipText: `Clicking this button will show the audio content creator.`
  };

  constructor(
    protected fetchAudioService: FetchAudioService,
    private dialogController: DialogController,
    protected dialogService: DialogService,
    public audioSearchFilter: AudioSearchFilter,
  ) {
    super(dialogService, fetchAudioService, audioSearchFilter);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    if (this.nodeData.properties) {
      this.existingNodeData = this.nodeData.properties as ZIAudioPayload;
      this.preselectedAudioMessage = AUDIO_TOOLS.getSelectedAudio(this.existingNodeData);
      this.messageName = this.existingNodeData.name;
      this.languageVariableName = this.existingNodeData.languageVariableName;
      this.isInterruptible = this.existingNodeData.interruptible || false;
    }
    this.existingConnections = this.nodeData.connections;
  }

  public attached(): void {
    this.retrieveAudioFiles();
  }

  // Overrides

  protected activateContentCreator(): void {
    super.activateContentCreator(this.nodeConfiguration);
  }

  protected toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  // Class-specific

  private toggleInterruptible(): void {
    this.isInterruptible = !this.isInterruptible;
  }

  private createPayload(): ZIAudioPayload {
    let audioMessages = AUDIO_TOOLS.getAudioMessages(this.selectedAudioMessage);
    return {
      description: '',
      speechRate: '0',
      speechLanguage: {
        languageName: 'English (United States)',
        languageCode: 'en-us'
      },
      interruptible: this.isInterruptible,
      textMessage: null,
      name: this.messageName,
      languageVariableName: this.languageVariableName,
      audioMessages
    };
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.nodeData.properties = this.createPayload();
    this.nodeData.properties.isDefined = true;
    this.dialogController.ok(this.nodeData);
  }

  public doNothing(): void {}

  // Getters and Setters

  @computedFrom('selectedAudioMessage', 'messageName')
  get isValid(): boolean {
    return !!(this.selectedAudioMessage && this.messageName);
  }
}
