import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { BaseModel } from 'zailab.abstract';
import { DateTimeTools } from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('InteractionLogModel');
const outcomeColours = { answered: 'green', abandoned: 'red', missed: 'red', forwarded: 'yellow', callback: 'yellow', mailbox: 'yellow', sent: 'green', served: 'green' };
const dispositionOutcomeColors = { 'closed - successful': 'green', 'closed - unsuccessful': 'red', 'open - pending': 'yellow' };
/*
 * */
export default class InteractionLogModel extends BaseModel {

    interactionId = null;
    agentRatingSurveys = [];
    agentRatingSurveyTotal = null;
    averageGeneralSurveyPercentage = null;
    outcome = null;
    channel = null;
    duration = null;
    from = null;
    generalSurveys = [];
    members = null;
    assistants = null;
    payload = null;
    recordingId = null;
    startTimestamp = null;
    surveyType = null;
    taskName = null;
    to = null;
    tos = [];
    type = null;
    disposition = null;
    dispositionCategory = null;
    dispositionSubCategory = null;
    dispositionOutcome = null;
    callSentiment = null;
    callTranscript = null;
    linkedConsultingInteractionId = null;
    consultInteractionIds = [];
    sourceDisconnected = null;
    targetDisconnected = null;
    variables = {};
    recordingRemoved = false;
    conference = null;
    conferenceInitiator = null;

    constructor(interaction) {
      super();
      this.mapProperties(interaction);
      this.convertTimestamps();
    }

    convertTimestamps() {
      this.startTimestamp = parseInt(this.startTimestamp);
      this.endTimestamp = parseInt(this.endTimestamp);
      this.answerTimestamp = parseInt(this.answerTimestamp);
    }

    @computedFrom('sourceDisconnected', 'targetDisconnected', 'type', 'channel')
    get disconnectedBy() {
      if ("Contact Centre Flow" === this.type) {
        if ("Inbound Call" === this.channel) {
          if (this.sourceDisconnected){
            return "Customer";
          } else if (this.targetDisconnected) {
            return "Member";
          }
        } else if ("Outbound Call" === this.channel) {
          if (this.sourceDisconnected){
            return "Member";
          } else if (this.targetDisconnected) {
            return "Customer";
          }
        }
      }
      return undefined;
    }

    @computedFrom('channel', 'type', 'outcome')
    get formattedChannel() {
        if (this.outcome) {
            if (_case.lower(this.type) === 'outbound' && (_case.lower(this.channel) !== 'sms') && (_case.lower(this.channel) !== 'email') && (_case.lower(this.channel) !== 'ticket')) {
                return 'Outbound Call';
            }
            if (_case.lower(this.type) === 'inbound' && (_case.lower(this.channel) !== 'sms') && (_case.lower(this.channel) !== 'email') && (_case.lower(this.channel) !== 'ticket')) {
                return 'Inbound Call';
            }
        }

        if (_case.lower(this.type) === 'internal') {
            return 'Internal Call'
        }

        if (_case.lower(this.channel) === 'email') {
            return _case.lower(this.type) === 'outbound' ? 'Outbound Email' : 'Inbound Email';

        }

        if (_case.lower(this.channel) === 'sms') {
            return _case.lower(this.type) === 'outbound' ? 'Outbound SMS' : 'Inbound SMS';
        }

        if (_case.lower(this.channel) === 'chat') {
            return 'Chat';
        }

        if (_case.lower(this.channel) === 'ticket') {
            return 'Ticket';
        }

        if (_case.lower(this.channel) === 'instant_message') {
            return 'Instant Message';
        }

        return this.channel;
    }

    @computedFrom('channel', 'type', 'direction', 'outcome')
    get channelIcon() {
      let _channel = this.channel ? this.channel.toLowerCase() : '';
      let _type = this.type ? this.type.toLowerCase() : '';
      let _isPrivateCall = !this.taskName;
      
      if (_channel === 'email') {
        return 'envelope-closed';
      }
      if (_channel === 'sms') {
          return 'comments';
      }
      if (_channel === 'ticket') {
        return 'channel-ticket';
      }
      if (_channel === 'chat' || _channel === 'instant_message') {
        return 'comments-square';
      }
      if (_channel === 'outbound call' || _channel === 'outbound flow call') {
        return 'call-outbound';
      }
      if (_channel === 'inbound call' || _channel === 'internal call' || _channel === 'flow call' || _channel === 'inbound flow call') {
        return 'call-inbound';
      }

      if (this.outcome === 'abandoned' && _type === 'inbound') {
        return 'call-inbound';
      } else if (this.outcome === 'abandoned' && _type === 'outbound') {
        return 'call-outbound';
      } else if (this.outcome === 'answered' && _type === 'internal') {
        return 'call-internal';
      } else if (this.outcome === 'abandoned' && _type === 'internal') {
        return 'call-internal';
      } else if (this.outcome === 'answered' && (_channel === 'outbound call' || _channel === 'outbound flow call')) {
        return 'call-outbound';
      } else if (this.outcome === 'answered' && _isPrivateCall && _type === 'outbound' && _channel !== 'sms' && _channel !== 'email') {
        return 'call-outbound';
      } else if (this.outcome === 'answered' && _isPrivateCall && _type === 'inbound' && _channel !== 'sms' && _channel !== 'email') {
        return 'call-inbound';
      } else if (this.outcome === 'mailbox') {
        return 'call-inbound';
      } else if (this.outcome === 'callback') {
        return 'call-inbound';
      }
    }

    @computedFrom('outcome')
    get outcomeColor() {
        return outcomeColours[this.formattedOutcome ? this.formattedOutcome : 'received'];
    }

    @computedFrom('dispositionOutcome')
    get dispositionOutcomeColor() {
        return dispositionOutcomeColors[this.dispositionOutcome ? this.dispositionOutcome.toLowerCase() : ''];
    }

    @computedFrom('taskName', 'type', 'channel', 'flowName', 'outcome')
    get formattedWorkType() {
        if (this.type === 'Internal') {
            return 'Internal';
        } else if (!this.taskName && (_case.lower(this.channel) === 'email' || _case.lower(this.channel) === 'sms' || _case.lower(this.channel) === 'chat')) {
            return '-';
        } else if (!this.taskName && !this.flowName) {
            return 'Private';
        } else if (this.flowName && this.outcome === 'mailbox') {
            return this.flowName;
        } else {
            return this.taskName;
        }
    }

    @computedFrom('type', 'members', 'channel')
    get formattedFrom() {
        if (this.type === 'Outbound' || this.channel === 'Outbound Call' ) {
            return this.members && this.members[0] ? this.members[0].firstName + " " + this.members[0].surname : this.from;
        }
        if (this.type === 'Internal') {
          if (this.from.startsWith("*9")) {
            return this.from;
          }
          return (this.members && this.members[0]) ? this.members[0].firstName + " " + this.members[0].surname : this.from;
        }

        if ((_case.lower(this.channel) === 'email' || _case.lower(this.channel) === 'sms')) {
            return this.from;
        }

        if (_case.lower(this.channel) === 'chat' && this.payload && this.payload.source) {
            let chatContactData = this.payload.source;
            if (chatContactData.email) {
                return chatContactData.email;
            } else if (chatContactData.firstName && chatContactData.surname) {
                return `${chatContactData.firstName} ${chatContactData.surname}`;
            } else {
                return 'Unknown Contact';
            }
        }

        if (this.type === 'Inbound' || this.channel === 'Inbound Call' || this.channel === 'Kiosk Call') {
            return this.from;
        }

        return this.from;
    }

    @computedFrom('type', 'channel', 'payload')
    get formattedTo() {
        if (this.type === 'Outbound' || this.channel === 'Outbound Call') {
            return this.to;
        }

        if (this.type === 'Inbound' || this.channel === 'Inbound Call' || this.channel === 'Kiosk Call' || ((_case.lower(this.channel) === 'email' || _case.lower(this.channel) === 'sms' || _case.lower(this.channel) === 'chat') && _case.lower(this.type) !== 'outbound')) {
          if(_case.lower(this.channel) === 'email') {
           return this.tos;
          }
            return this.members && this.members[0] ? this.members[0].firstName + " " + this.members[0].surname : this.to;
        }

        if (this.type === 'Internal') {
          if (this.to.startsWith("*9")) {
            return this.to;
          }
          if (this.from.startsWith("*9")) {
            return this.members && this.members[0] ? this.members[0].firstName + " " + this.members[0].surname : this.to
          }
            return this.members && this.members[1] ? this.members[1].firstName + " " + this.members[0].surname : this.to
        }

        return this.to;
    }

    @computedFrom('type', 'channel', 'outcome')
    get formattedOutcome() {
        let _outcome = _case.lower(this.outcome);
        if (_case.lower(this.channel) === 'email' || _case.lower(this.channel) === 'sms') {
            if (_case.lower(this.type) === 'outbound') {
                _outcome = 'sent';
            }
        }

        if (_case.lower(this.channel) === 'chat') {
            _outcome = this.outcome;
        }

        if (!_outcome) {
            _outcome = 'no call outcome';
        }

        return _outcome;
    }

    @computedFrom('startTimestamp')
    get date() {
        return _date(this.startTimestamp).date;
    }

    @computedFrom('startTimestamp')
    get time() {
        return _date(this.startTimestamp).time;
    }

    @computedFrom('numberOfAgentRatingSurveys', 'numberOfGeneralSurveys', 'agentRatingSurveyTotal', 'averageGeneralSurveyPercentage')
    get formattedSurveyTotal() {
        if (this.numberOfAgentRatingSurveys > 0 && this.numberOfGeneralSurveys > 0) {
            return '(' + (this.numberOfAgentRatingSurveys + this.numberOfGeneralSurveys) + ')';
        } else if (this.numberOfAgentRatingSurveys > 1 || this.numberOfGeneralSurveys > 1) {
            return '(' + (this.numberOfAgentRatingSurveys + this.numberOfGeneralSurveys) + ')';
        } else if (!this.agentRatingSurveyTotal && this.numberOfGeneralSurveys > 0) {
            return this.averageGeneralSurveyPercentage + '%';
        } else if (this.agentRatingSurveyTotal > 0 && !this.numberOfGeneralSurveys) {
            return this.agentRatingSurveyTotal + '%';
        } else {
            return '';
        }
    }

    @computedFrom('numberOfAgentRatingSurveys', 'numberOfGeneralSurveys', 'agentRatingSurveyTotal')
    get formattedSurveyText() {
        if (this.numberOfAgentRatingSurveys > 0 && this.numberOfGeneralSurveys > 0) {
            return 'Surveys';
        } else if (!this.numberOfAgentRatingSurveys && this.numberOfGeneralSurveys > 0) {
            return 'General';
        } else if (this.numberOfAgentRatingSurveys > 0 && !this.numberOfGeneralSurveys) {
            return 'Agent';
        } else {
            return '-';
        }
    }

    @computedFrom('numberOfAgentRatingSurveys', 'numberOfGeneralSurveys', 'agentRatingSurveyTotal', 'averageGeneralSurveyPercentage')
    get formattedSurveyColor() {
        if ((this.numberOfAgentRatingSurveys > 0 && this.agentRatingSurveyTotal < 80) || (this.numberOfGeneralSurveys > 0 && this.averageGeneralSurveyPercentage < 80)) {
            return 'red';
        } else if (this.agentRatingSurveyTotal && this.agentRatingSurveyTotal < 80) {
            return 'red';
        } else if (this.averageGeneralSurveyPercentage && this.averageGeneralSurveyPercentage < 80) {
            return 'red';
        }
    }

    @computedFrom('agentRatingSurveys', 'generalSurveys')
    get surveys() {
        if ((this.agentRatingSurveys && this.agentRatingSurveys.length > 0) || (this.generalSurveys && this.generalSurveys.length > 0)) {
            return [{
                type: 'Agent Rating Survey',
                surveys: this.agentRatingSurveys ? this.agentRatingSurveys : []
            }, {
                type: 'General Survey',
                average: this.averageGeneralSurveyPercentage,
                surveys: this.generalSurveys ? this.generalSurveys : []
            }];
        }
        return [];
    }

    @computedFrom('payload')
    get chatContact() {
        if (this.payload.source && this.payload.source.firstName && this.payload.source.surname) {
            return `${this.payload.source.firstName} ${this.payload.source.surname}`;
        } else if (this.payload.source && this.payload.source.email) {
            return `${this.payload.source.email}`;
        }
    }
}

function _date(startTimestamp) {
    let _date = new DateTimeTools();
    return _date.convertToLocalTime(startTimestamp, 'UTC');
}

let _case = {
    lower: (string) => {
        return string ? string.toLowerCase() : false;
    },
    first: (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
};
