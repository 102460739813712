
import { LogManager } from 'aurelia-framework';
// @ts-ignore
import moment from 'moment';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { TimeAgoValueConverter } from "../../../../../../converters/time-ago";
import { ColourConfig } from '../colour-config';

const logger = LogManager.getLogger('ConfigMapper');

export const CHANNELS = {
  INBOUND_CALL: 'INBOUND_CALL',
  CALLBACK: 'CALLBACK',
  CHAT: 'CHAT',
  EMAIL: 'EMAIL',
  INSTANT_MESSAGE: 'INSTANT_MESSAGE',
  SMS: 'SMS',
  TICKET: 'TICKET'
};

export class ConfigMapper {
  private timeAgoValueConverter: TimeAgoValueConverter = new TimeAgoValueConverter();
  private timers: any = {};

  
  // POPULATING DATA
  public getAgentLiveStats(c: any, key: string): any {
    if (c.agentLiveStats) {
      return c.agentLiveStats[key] || 0;
    }
    return 0;
  }

  // 🅶🅴🆃 🅻🅸🆅🅴 🆂🆃🅰🆃🆂
  public getLiveStats(c: any, channel: string, key: string): any {
    // service / all worktypes level
    if (c.channelStats) {
      let channelData = c.channelStats[channel];
      if (channelData) {
        return channelData.interactionLiveStats[key];
      }
      return '--';
    }
    // worktype level
    if (c.interactionLiveStats) {
      if (c.channel === channel) {
        return c.interactionLiveStats[key] || '--';
      } else {
        return '--';
      }
    }
  }

  public getTotalLiveStats(c: any, key: string, channel?: string): any {
    // service / all worktypes level
    if (c.totalChannelStats && c.totalChannelStats.interactionLiveStats) {
      return c.totalChannelStats.interactionLiveStats[key] || '--';
    }
    // worktype level
    if (c.interactionLiveStats) {
      if (!channel || c.channel === channel) {
        return c.interactionLiveStats[key] || '--';
      }
    }
    return '--';
  }

  // 🅶🅴🆃 🆃🅾🅳🅰🆈 🆂🆃🅰🆃🆂
  public getTodayStats(c: any, channel: string, key: string): any {
    // service / all worktypes level
    
    if (c.totalChannelStats && c.totalChannelStats.interactionTodayStats) {
      if (channel) {
        if (c.channelStats) {
          let channelData = c.channelStats[channel];
          return channelData && channelData.interactionTodayStats[key] ? channelData.interactionTodayStats[key] : '--';
        }
      }
      return c.totalChannelStats.interactionTodayStats[key] || '--';
    }

    // worktype level
    if (c.interactionTodayStats) {
      if (c.channel === channel) {
        return c.interactionTodayStats[key] || '--';
      } else {
        return '--';
      }
    }
    return '--'
  }

  public getTotalTodayStats(c: any, key: string, channel?: string): any {
    // service / all worktypes level
    if (c.totalChannelStats && c.totalChannelStats.interactionTodayStats) {
      if (channel) {
        if (c.channelStats) {
          let channelData = c.channelStats[channel];
          return channelData && channelData.interactionTodayStats[key] ? channelData.interactionTodayStats[key] : '--';
        }
      } else {
        return c.totalChannelStats.interactionTodayStats[key] || '--';
      }
      return c.totalChannelStats.interactionTodayStats[key] || '--';
    }
    // worktype level
    if (c.interactionTodayStats) {
      if (!channel || c.channel === channel) {
        return c.interactionTodayStats[key] || '--';
      }
    }
    return '--';
  }

  // 🅶🅴🅽🅴🆁🅰🆃🅴 🆃🅸🅼🅴🆁🆂
  public longestWaitingTimer(c: any, channel?: string, className?: string): string {
    const id = uuidv4();
    const getTimerValue = value => {
      if (value) {
        if (value === '--') {
          return value;
        }
        return this.timeAgoValueConverter.toView(value, new Date, ':');
      }
      return '--'
    }
    const calculateValue = channel ?
      () => getTimerValue(this.getLiveStats(c, channel, 'longestWaiting')) :
      () => getTimerValue(this.getTotalLiveStats(c, 'longestWaiting'));
    let value = calculateValue();

    const incrementFunction = () => {
      value = calculateValue();
      if (value && value !== '--') {
        this.timers[id] && window.clearInterval(this.timers[id])
        this.timers[id] = null;
        this.timers[id] = setInterval(() => {
          let newValue = calculateValue();
          let element = document.querySelector(`#js-${id}`);
          if (element) {
            element.innerHTML = newValue;
            element.className = `${className || ''}`;
          }
        }, 300);
      }
    };

    return `<div id="js-${id}" class="${className || ''}" onload="${incrementFunction()}">${value}</div>`
  }

  public formatToDayTime(value: string): string {
    if (value && value !== '--') {
      return moment(Date.parse(new Date(value).toUTCString())).utc().format('HH:mm:ss')
    }
    return '--';
  }

  public clearTimers(): void {
    const keys = Object.keys(this.timers);
    keys.forEach(key => {
      window.clearInterval(this.timers[key]);
    });
    this.timers = [];
  }

  // COLOR RULES

  public getColourConfig(title: string, value: any): string {
    if (!value || value === '--') {
      return;
    }
    const colourScheme = ColourConfig.getColourScheme(ColourConfig.VIEWS.LIVE_DASHBOARD_OVERVIEW);

    if (colourScheme[title]) {
      const config = colourScheme[title].settings;
      for(let index = 0; index < config.length; index++) {
        let setting = config[index];

        if (value >= setting.value) {
          return 'background: ' + setting.colour + '!important';
        }
      }
    } else {
      return '';
    }
  }

  // 🅳🅰🆃🅰 🅼🅾🅳🅸🅵🅸🅴🆁🆂
  public roundData(value: number): number {
    return -Math.round(-value * 10) / 10
  }
}