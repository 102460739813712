import { autoinject } from "aurelia-dependency-injection";

@autoinject()
export class Tab1generalSettings {

  public model: any;
  public webchatWidget: { name: string };

  public activate(bindingContext: {
    data: {
      webchatConfiguration: any
    }
  }): void {
    this.model = bindingContext;
    this.webchatWidget = this.model.data.webchatConfiguration;
  }
}
