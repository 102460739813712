import { LogManager, autoinject } from "aurelia-framework";
import { Subscription, EventAggregator } from "aurelia-event-aggregator";

import { ZIOplog } from "../../../../../../typings/zai/zai.common";
import { ContactFieldsService } from "../contact-fields-service";

const logger = LogManager.getLogger('ContactFieldsListEvents');

@autoinject
export class ContactFieldsListEvents {

  private eventSubscription: Subscription;
  private oplog: ZIOplog;
  private validationCodes: Array<string> = [
    'DispositionList.enabled',
    'DispositionCode.description.populated',
    'DispositionCode.description.notUnique',
    'DispositionCode.interactionOutcomeReason.populated',
    'DispositionCode.conversationOutcome.populated',
    'DispositionCode.learningContribution.populated',
    'DispositionList.enabled.not.unique'
  ];

  constructor(
    private contactFieldsService: ContactFieldsService,
    private eventAggregator: EventAggregator
  ) { }

  public async init(organisationId: string): Promise<void> {
    this.oplog = await this.contactFieldsService.initialiseOplog(organisationId);
  }

  public close(): void {
    this.oplog && this.oplog.unsubscribe();
    this.contactFieldsService.unsubscribeFromContactFieldDeletedEvent();
    this.eventSubscription && this.eventSubscription.dispose();
  }

  public onValidationError(handler: (message: IValidationError) => void): void {
    this.eventSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', (message: IValidationError) => {
      logger.info('onValidationError :: message=', message);
      if (this.validationCodes.includes(message.state.globalError.code)) {
        handler(message);
        this.eventSubscription && this.eventSubscription.dispose();
      }
    });
  }

  public onDataInserted(handler: (data: any) => void): void {
    this.oplog.on('insert', handler);
  }

  public onDataUpdated(handler: (data: any) => void): void {
    this.oplog.on('update', handler);
  }

  public onDataDeleted(handler: (data: any) => void): void {
    this.oplog.on('delete', handler);
  }

  public onContactFieldDeletedEvent(handler: (data: any) => void): void {
    this.contactFieldsService.subscribeToContactFieldDeletedEvent(handler);
  }
}

export interface IValidationError {
  state: {
    globalError: {
      code: string;
    }
  }
}