import {inject, customElement, bindable, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 */
let logger = LogManager.getLogger('WizardHeader');
/*
 */
@customElement('z-wizard-header')
@inject(EventAggregator, Element)
export class WizardHeader {

  @bindable steps;
  @bindable description;

  constructor() {

  }
}