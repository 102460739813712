import { bindable } from 'aurelia-templating';
import { LogManager } from 'aurelia-framework';
import { autoinject } from "aurelia-dependency-injection";
import { MembersService } from "../../../members-service";
import { MemberModel } from './../../../../../conversation/member-model';

const logger = LogManager.getLogger('ViewMemberPermissions');

@autoinject
export class ViewMemberPermissions {
  @bindable private model: any;

  public originalGlobalPermissions: any[];
  public originalRolePermissions: any[];
  public globalPermissions: any[];
  public rolePermissions: any[];
  private role: string;

  constructor(
    private membersService: MembersService
  ) {}

  public activate(bindingContext: any): void {
    this.model = bindingContext.data.member;
    // @ts-ignore
    this.role = this.convertRoleCasingToNameCase(this.model._roles[0].roleValue);
    this.getPermissions();
  }

  private async getPermissions(): Promise<void> {
    const response = await this.membersService.getPublishedPermissions();

    this.originalGlobalPermissions = JSON.parse(JSON.stringify(response.globalPermissions));
    this.originalRolePermissions = JSON.parse(JSON.stringify(response.rolePermissions[this.role]));

    this.globalPermissions = response.globalPermissions;
    this.rolePermissions = response.rolePermissions[this.role];

    const assignedPermissions = Object.keys(this.model.permissions);

    this.globalPermissions = this.globalPermissions.map(gp => {
      if (assignedPermissions.indexOf(gp.permissionId) >= 0) {
        return {
          ...gp,
          selectedOption: this.model.permissions[gp.permissionId].value,
          applyDataRestriction: this.model.permissions[gp.permissionId].applyDataRestriction
        };
      }
      return {
        ...gp,
        selectedOption: 'Blocked'
      };
    });

    this.rolePermissions = this.rolePermissions.map(rp => {
      if (assignedPermissions.indexOf(rp.permissionId) >= 0) {
        return {
          ...rp,
          selectedOption: this.model.permissions[rp.permissionId].value,
          applyDataRestriction: this.model.permissions[rp.permissionId].applyDataRestriction
        }
      }
      return {
        ...rp,
        selectedOption: 'Blocked'
      };
    });
  }

  private convertRoleCasingToNameCase(value: string): string {
    let split = value.toLowerCase().split('_');
    for (var i = 0; i < split.length; i++) {
      split[i] = split[i].charAt(0).toUpperCase() + split[i].substring(1);
    }
    return (split.join(' ')).replace('_', ' ');
  }

  public allowApplyDataRestriction(permission: any): void {
    permission.applyDataRestriction = !permission.applyDataRestriction;
    this.save(
      permission.permissionId,
      permission.selectedOption,
      permission.applyDataRestriction
    );
  }

  public permissionValueChanged(permission: any, selectedOption: string): void {
    this.save(
      permission.permissionId,
      selectedOption,
      permission.applyDataRestriction
    );
  }

  public save(permissionId: string, selectedOption: string, applyDataRestriction: boolean): void {
    this.model.permissions[permissionId] = {
      permissionId,
      value: selectedOption,
      applyDataRestriction
    };

    this.membersService
      .updateMemberPermissions(
        this.model.memberId,
        permissionId,
        selectedOption,
        !!applyDataRestriction
      )
  }
}
