import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/**/
import { SessionStore } from 'zailab.common';
import { WorkTypeModel } from './models/work-type-model';
import { InteractionFlow } from '../../interaction-flow';
/**/
const logger = LogManager.getLogger('Callback Service');

/**/
@autoinject
export class CallbackService {

  private httpClient: HttpClient;
  private sessionStore: SessionStore;
  private interactionFlow: InteractionFlow;

  constructor(httpClient: HttpClient, sessionStore: SessionStore, interactionFlow: InteractionFlow) {

    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.interactionFlow = interactionFlow;
  }

  public retrieveWorkTypes(name: string): Promise<WorkTypeModel[]> {
    return this.retrieve(`v1/organisation/task-templates/search?templateType=callback&name=`) //
      .then((data: any) => this.modelCollection(data.taskTemplates, WorkTypeModel));
  }

  private retrieve(uri: string): Promise<Array<any>> {

    return new Promise((resolve: any, reject: any) => {

      this.httpClient.createRequest(uri)
        .asGet()
        .send()
        .then(resolve, reject);
    });
  }

  public modelCollection(items: any, Model: any): any {
    if (!items) {
      return;
    }

    if (items instanceof Array) {
      let mappedList: Array<any> = [];
      for (let item of items) {
        mappedList.push(new Model(item))
      }

      return mappedList;
    }

    return new Model(items);
  }
}