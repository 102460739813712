import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('RenameOrganisationDialog');
/*
 * */
@inject(DialogController, ValidationControllerFactory)
export class RenameOrganisationDialog {

  organisationName = '';
  organisationFormGroup;
  dialogHeader = "Rename Organisation";

  constructor(dialogController, validationControllerFactory) {
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param name
   */
  activate(name) {

    this.organisationName = '' + name;
    this._initValidation();
  }

  /**
   * initialise the form validations
   * @param validation
   * @private
   */
  _initValidation() {


    ValidationRules
      .customRule('length', (value) => {
        if(!value) {
          return true;
        }
        if(value.length > 30 || value.length < 2) {
          return false;
        }
        return true;
      });

    ValidationRules
      .customRule('validValue', (value) => {
        if(!value || value.length < 2) {
          return true;
        }
        let re = /^([a-zA-Z-\s'])+$/;
        return re.test(value);
      });

    ValidationRules
      .ensure('organisationName')
      .required().withMessage('Organisation name is required.')
      .satisfiesRule('validValue').withMessage('This is not a valid input.')
      .satisfiesRule('length').withMessage('Must be between 2 and 30 characters long.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.organisationFormGroup.reset();
    this.dialogController.cancel();
  }

  /**
   * does the form validation
   * closes the dialog
   */
  rename() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }
      this.dialogController.ok(this.organisationName);
    }, () => {});
  }
}

