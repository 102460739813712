import {inject, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('DisplaySiteModel');
/*
 * */
export class DisplaySitesModel extends BaseModel {

  id = '';
  name = '';
  memberCount = 0;
  rolloverTextContent = '';
  icon = '';
  canDelete = true;
  isSelected = false; // used for select-site-dialog

  constructor(site) {
    super();
    this.mapProperties(site);
  }

  mapProperties(object) {
    if (object && object.siteId) {
      object.id = object.siteId;
      object.name = object.siteName;
    } else {
      object.siteId = object.id;
      object.siteName = object.name;
    }
    super.mapProperties(object);
  }
}