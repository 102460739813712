import { LogManager, autoinject } from 'aurelia-framework';
import './alert-sound-config.scss';
import { DialogService } from 'aurelia-dialog';
import { AlertSoundConfigDialog } from '../alert-sound-config-dialog/alert-sound-config-dialog';

const logger = LogManager.getLogger('AlertSoundConfig');

@autoinject
export class AlertSoundConfig {
  constructor(private dialogService: DialogService) {}

  openDialog() {
    const settings = { viewModel: AlertSoundConfigDialog };
    this.dialogService.open(settings);
  }
}
