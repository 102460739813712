import { inject, LogManager, ObserverLocator, observable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 * */
import { SessionStore, BootstrapFormRenderer } from 'zailab.common';
import UserRegistrationService from '../../../../registration/user-registration-service';
import { MembersService } from '../../../../../organisation/member/members/members-service';
import { AbstractList } from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('Emails');
/*
 * */
@inject(ObserverLocator, UserRegistrationService, MembersService, SessionStore, DialogService, EventAggregator, ValidationControllerFactory)
export class Emails extends AbstractList {

  ready = false;
  allEmails = {
    pendingInvitations: [],
    organisationMembers: []
  };
  @observable name = '';
  @observable domain = '';
  currentDomain = '';
  emailFormGroup;
  addState;
  validationCount = 0;
  subscription;

  constructor(observerLocator, userRegistrationService, membersService, sessionStore, dialogService, eventAggregator, validationControllerFactory) {
    super();
    this.observerLocator = observerLocator;
    this.userRegistrationService = userRegistrationService;
    this.memberService = membersService;
    this.sessionStore = sessionStore;
    this.dialogService = dialogService;
    this.eventAggregator = eventAggregator;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.subscribeToInvitationSent();
  }

  subscribeToInvitationSent() {
    this.subscription = this.eventAggregator.subscribe('invitation.send', email => {

      this.itemList.forEach(item => {
        if (item.email === email) {
          item.sent = true;
          this.allEmails.pendingInvitations.push(item);

          setTimeout(() => {
            this.itemList = this.itemList.filter(_item => {
              if (_item.email === email) {
                return false;
              }
              return true;
            });
          }, 2000);
        }
      })
    });
  }

  activate(wizard) {

    this._initWizard(wizard);

    if (wizard.data.emails && wizard.data.emails.length) {
      wizard.step.complete();
    }

    this.userRegistrationService.retrieveInvitations(this.sessionStore.get.organisation.organisationId).then((emails) => {

      this.allEmails.pendingInvitations = emails;
      this.ready = true;
    }, (error) => {
      logger.info('retrieve pending invitations > error = ', error);
      this.ready = true;
    });

    this.memberService.retrieveOrganisationMembers(this.sessionStore.get.organisation.organisationId).then((members) => {

      this.allEmails.organisationMembers = members;
      this.domain = members[0].email.split('@')[1];
      this.currentDomain = JSON.parse(JSON.stringify(this.domain));
      this.ready = true;
    }, (error) => {
      this.ready = true;
    });
  }

  attached() {

    this.subscription = this.observerLocator
      .getObserver(this, 'name')
      .subscribe((newValue, oldValue) => {
        this.name = newValue.replace(/@/g, '');

        if (newValue.indexOf('@') !== -1) {
          this._focus('#js-domain');
        }
      });
  }

  deactivate() {
    this._removeSubscription();
  }

  /**
   * subscribe to next click in wizard to validate step
   * @param wizard
   * @private
   */
  _initWizard(wizard) {

    wizard.data.emails = wizard.data.emails ? wizard.data.emails : [];
    this.itemList = jQuery.extend(true, [], wizard.data.emails);
    this.allEmails.invitationEmails = this.itemList;
    this.wizard = wizard;

    wizard.beforeNextStep(() => {
      this._completeInvitationStep();
    });
  }

  get fullEmail() {
    return this.name + '@' + this.domain;
  }

  get hasEmail() {
    return this.name.length && this.domain.length;
  }

  enableAddState() {
    this._initValidation();
    this.addState = true;
    this._focus('#js-name');
    this.wizard.step.incomplete();
  }

  _disableAddState() {
    this.addState = false;
    this.name = '';

    if (this.itemList.length) {
      this.wizard.step.complete();
    }
  }

  addEmail() {

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }

      this.allEmails.invitationEmails = this.itemList;
      this.itemList.push({ email: this.fullEmail });
      this.currentDomain = JSON.parse(JSON.stringify(this.domain));
      this._disableAddState();
      this.wizard.data.emails = JSON.parse(JSON.stringify(this.itemList));
      this.wizard.step.complete();
    }, (error) => {
      logger.info('addEmail > validation > failure >', error);
    });
  }

  cancel() {
    this._disableAddState();
    this.domain = JSON.parse(JSON.stringify(this.currentDomain));
  }

  delete(index) {
    this.itemList.splice(index, 1);
    this.wizard.data.emails = JSON.parse(JSON.stringify(this.itemList));

    if (this.itemList.length === 0) {
      this.wizard.step.incomplete();
    }
  }

  _initValidation() {

    ValidationRules
      .customRule('existingEmail', () => {
        for (let key in this.allEmails) {
          if (this.allEmails.hasOwnProperty(key)) {
            for (let _item of this.allEmails[key]) {
              if (_item.address && _item.address.toLowerCase() === this.fullEmail.toLowerCase()) {
                return false;
              }
              if (_item.email && _item.email.toLowerCase() === this.fullEmail.toLowerCase()) {
                return false;
              }
            }
          }
        }
        return true;
      });

    ValidationRules
      .customRule('validEmail', () => {
        let re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(this.fullEmail);
      });

    ValidationRules
      .ensure('name')
      .satisfiesRule('validEmail').withMessage('Please enter a valid email.')
      .satisfiesRule('existingEmail').withMessage('This email has already been added.')
      .on(this);
  }

  nameChanged() {
    this._validateForm();
  }

  domainChanged() {
    this._validateForm();
  }

  _validateForm() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
    }, (error) => { });
  }

  _focus(string) {

    let element = document.querySelector(string);

    if (!element) {
      setTimeout(() => {
        this._focus(string);
      }, 10);
      return;
    }

    element.select();

    if (element !== document.activeElement) {

      setTimeout(() => {
        this._focus(string);
      }, 10);
    }
  }

  _completeInvitationStep() {
    if (this.subscription) {
      this.subscription.dispose();
    }
    this.wizard.continue({
      emails: this.itemList
    });
  }

  _removeSubscription() {

    this.emailFormGroup.reset();

    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  startsWithMatch(searchExpression, value) {
    return searchExpression && value.email.toLowerCase().startsWith(searchExpression.toLowerCase());
  }
}

