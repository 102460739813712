import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
* */
import {SkillsService} from '../skills-service';
import {SessionStore, BootstrapFormRenderer} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('AddSkillDialog');

/*
 * */
@inject(SkillsService, SessionStore, DialogController, ValidationControllerFactory)
export class AddSkillDialog {

  skillsList = [];
  skillName = '';
  skillFormGroup;
  dialogHeader = "Add Skill";

  constructor(skillsListService, sessionStore, dialogController, validationControllerFactory) {
    this.skillsListService = skillsListService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param object
   * */
  activate(skills) {

    this.skillList = skills;
    this.initValidation();
  }

  initValidation() {

    ValidationRules
      .customRule('uniqueName', (value) => {
        if (this.skillList.length > 0) {
          for (let item of this.skillList) {
            if (item.name.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      });

    ValidationRules
      .ensure('skillName')
      .required().withMessage('Please enter a skill name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.skillFormGroup.reset();
    this.dialogController.cancel();
  }

  addSkill() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.dialogController.ok(this.skillName);
    }, error => {
      logger.info('Error > unable to validate =', error);
    });
  }
}

