import { inject, LogManager } from 'aurelia-framework';

import SubscriptionsProviderService from './subscriptions-provider-service';
import { WebSocket } from './websocket';

const logger = LogManager.getLogger('OplogService');

@inject(WebSocket, SubscriptionsProviderService)
export class OplogService {
  static subscriptions = {};
  /**
   * Initialise a new instance of this class
   * @returns {{subscribeOn: (function(keyField, keyValue)), unsubscribeAll: (function())}}
   * which exposes the following
   * @returns {{in: (function(nameSpace))}}
   * which then returns a new instance of this class
   * USAGE
   * --------
   * service.subscribeOn('fieldName', 'fieldValue').in('nameSpace');
   */
  constructor(webSocket, subscriptionsProviderService) {
    this.webSocket = webSocket;
    this.subscriptionService = subscriptionsProviderService;

    _subscription(this.webSocket, this.subscriptionService).registerEvents();

    return {
      subscribeOn: (keyField, keyValue) => this.subscribeOn(keyField, keyValue),
      unsubscribeAll: () => this.unsubscribeAll(),
      resubscribeAll: () => this.resubscribeAll(),
    };
  }

  subscribeOn(keyField, keyValue) {
    return {
      in: (nameSpace) => {
        let instance = new Instance(this.webSocket, this.subscriptionService);
        instance.keyField = keyField;
        instance.keyValue = keyValue;
        instance.nameSpace = nameSpace;

        if (!OplogService.subscriptions[nameSpace]) {
          OplogService.subscriptions[nameSpace] = {
            [keyValue]: instance,
          };
        } else {
          OplogService.subscriptions[nameSpace][keyValue] = instance;
        }
        return instance;
      },
    };
  }

  unsubscribeAll() {
    const namespaces = Object.keys(OplogService.subscriptions);

    namespaces.forEach((nameSpace) => {
      const keyValues = Object.keys(OplogService.subscriptions[nameSpace]);

      keyValues.forEach((keyValue) => {
        OplogService.subscriptions[nameSpace][keyValue].unsubscribe();
        delete OplogService.subscriptions[nameSpace][keyValue];
        if (Object.keys(OplogService.subscriptions[nameSpace]).length === 0) {
          delete OplogService.subscriptions[nameSpace];
        }
      });
    });
  }

  resubscribeAll() {
    const keys = Object.keys(OplogService.subscriptions);

    keys.forEach((key) => {
      const subscriptions = OplogService.subscriptions[key];
      const subscriptionKeys = Object.keys(subscriptions);

      subscriptionKeys.forEach((subKey) => {
        const subscription = subscriptions[subKey];
        const keyField = subscription.keyField;
        const keyValue = subscription.keyValue;
        const nameSpace = subscription.nameSpace;

        this.subscribeOn(keyField, keyValue).in(nameSpace);
      });
    });
  }
}

@inject(WebSocket, SubscriptionsProviderService)
export class Oplog {
  constructor(webSocket, subscriptionsProviderService) {
    this.webSocket = webSocket;
    this.subscriptionService = subscriptionsProviderService;
    _subscription(this.webSocket, this.subscriptionService).registerEvents();
  }

  subscribe() {
    return new OplogSubscription(this.webSocket, this.subscriptionService);
  }
}

export class OplogSubscription {
  instance = null;

  constructor(webSocket, subscriptionService) {
    this.instance = new Instance(webSocket, subscriptionService);
  }

  withKeyField(keyField) {
    this.instance.keyField = keyField;
    return this;
  }

  withKeyValue(keyValue) {
    this.instance.keyValue = keyValue;
    return this;
  }

  withNameSpace(nameSpace) {
    this.instance.nameSpace = nameSpace;
    return this;
  }

  unsubscribe() {
    this.instance.unsubscribe();
    if (OplogService.subscriptions[this.instance.nameSpace]) {
      delete OplogService.subscriptions[this.instance.nameSpace][
        this.instance.keyValue
      ];
    }
  }

  when(...ops) {
    return new Promise((resolve) =>
      ops.forEach((op) => this.instance.on(op, resolve))
    );
  }

  on(op, callback) {
    return this.instance.on(op, callback);
  }
}

class Instance {
  nameSpace = null;
  keyField = null;
  keyValue = null;
  operations = [];

  constructor(webSocket, subscriptionService) {
    this.webSocket = webSocket;
    this.subscriptionService = subscriptionService;
  }

  /**
   * subscribe to database operations
   * @param operation ['insert', 'update', 'delete']
   * @param callback
   * @param customFormat
   */
  on(operation, callback, customFormat) {
    this.operations.push(operation);

    _subscription(this.webSocket, this.subscriptionService).subscribeToOplog(
      this.nameSpace,
      operation,
      this.keyField,
      this.keyValue,
      (response) => {
        callback(
          customFormat
            ? _format(
                customFormat.field && response[customFormat.field]
                  ? response[customFormat.field]
                  : response,
                operation
              )
            : response
        );
      }
    );
  }

  /**
   * unsubscribe from all database operations
   */
  unsubscribe() {
    for (let operation of this.operations) {
      _subscription(this.webSocket, this.subscriptionService).unSubscribeOplog(
        this.nameSpace,
        operation,
        this.keyField,
        this.keyValue
      );
    }
    this.operations = [];
  }

  unsubscribeFromTable(nameSpace, operation, keyField, keyValue) {
    _subscription(this.webSocket, this.subscriptionService).unSubscribeOplog(
      nameSpace,
      operation,
      keyField,
      keyValue
    );
  }
}

function _subscription(ws, subscriptionService) {
  function _registerEvents() {
    ws.subscribe({
      name: 'OperationLogged',
      callback: (message) => {
        let id = message.state.id;
        let obj = message.state.o;
        try {
          if (message.state.o.u) {
            obj = {
              ...message.state.o,
              ...message.state.o.u,
            };
            delete obj.u;
          }
        } catch (e) {
          console.warn(' > cannot parse data >>>>> ', e);
        }

        if (
          !subscriptionService.subscribers[id] ||
          typeof subscriptionService.subscribers[id] !== 'function'
        )
          return;

        subscriptionService.subscribers[id](obj);
      },
    });
  }

  function _subscribeOplog(nameSpace, operation, keyField, keyValue, callback) {
    let id = _provisionId(nameSpace, operation, keyField, keyValue);

    subscriptionService.subscribers[id] = callback;
    ws.publish({
      name: 'RegisterOperationLog',
      state: {
        nameSpace: nameSpace,
        operation: operation,
        keyField: keyField,
        keyValue: keyValue,
      },
    });
  }

  function _unSubscribeOplog(nameSpace, operation, keyField, keyValue) {
    let id = _provisionId(nameSpace, operation, keyField, keyValue);
    delete subscriptionService.subscribers[id];

    ws.publish({
      name: 'DeregisterOperationLog',
      state: {
        nameSpace: nameSpace,
        operation: operation,
        keyField: keyField,
        keyValue: keyValue,
      },
    });
  }

  function _deregister() {
    subscriptionService.subscribers = {};
    ws.publish({
      name: 'DeregisterOperationLog',
    });
  }

  function _provisionId(nameSpace, operation, keyField, keyValue) {
    let sep = '~';
    return nameSpace + sep + operation + sep + keyField + sep + keyValue;
  }

  return {
    registerEvents: _registerEvents,
    subscribeToOplog: _subscribeOplog,
    unSubscribeOplog: _unSubscribeOplog,
  };
}

/**
 * Return response from different format oplog key fields as per examples:
 * `_response.85eab590-370b-11e7-a919-92ebcb67fe33: {...}`
 * `_response.campaigns: {...}`
 * `_response.campaigns.85eab590-370b-11e7-a919-92ebcb67fe33:: {...}`
 * `_response.campaigns.85eab590-370b-11e7-a919-92ebcb67fe33.numberOfTasksRemaining: 4`
 * @param _response
 * @param operation
 * @private
 */
function _format(_response, operation) {
  const indicators = {
    insert: {
      wildcard: '-',
      callback: _insert,
    },
    update: {
      wildcard: '.',
      callback: _update,
    },
    delete: {
      wildcard: '_',
      callback: _delete,
    },
  };

  function _insert(_property) {
    return _response[_property];
  }

  function _delete(_property) {
    return _response && _response[_property];
  }

  function _update(_property, existingData) {
    let keys = _property.split('.');
    let data = _response;

    data[keys[0]] = existingData ? existingData[keys[0]] : {};

    if (keys[1]) {
      if (keys[2]) {
        data[keys[0]][keys[2]] = _response[_property];
      } else {
        data[keys[0]] = _response[_property];
      }
      data[keys[0]]._id = keys[1];
    } else {
      data = _response[_property];
    }
    return data;
  }

  let concatData;

  for (let _property in _response) {
    if (
      _response.hasOwnProperty(_property) &&
      _property.includes(indicators[operation].wildcard)
    ) {
      concatData = indicators[operation].callback(_property, concatData);
    }
  }
  return concatData;
}
