import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import * as impl from './agent-script-admin-config-impl.json';

@autoinject
export class AgentScriptDefinitionService {

  public impl = impl;

  constructor(
    private httpClient: HttpClient
  ) { }

  public async retrieve(agentScriptId: any): Promise<any> {
    return this.httpClient
      .createRequest(`${this.impl.baseUrl}${agentScriptId}/definition`)
      .asGet()
      .send();
  }

  public async update(data: any): Promise<any> {
    return this.httpClient
      .createRequest(`${this.impl.baseUrl}${data.agentScriptId}/definition`)
      .asPut()
      .withContent(data)
      .send();
  }

  public async retrieveByWorkTypeId(worktypeId: any): Promise<any> {
    return this.httpClient
      .createRequest(`${this.impl.baseUrl}definition`)
      .withParams({ worktypeId })
      .asGet()
      .send();
  }
}