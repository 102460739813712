import { inject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { SessionStore, ApplicationProperties, MESSAGE_EVENTS } from 'zailab.common';
import { AssignEmailDialog } from '../assign-email-dialog';
/*
 * */
const logger = LogManager.getLogger('InteractionFlowAddCustomEmail');
/*
 * */
@inject(EventAggregator, SessionStore, ApplicationProperties, AssignEmailDialog)
export class InteractionFlowAddDefaultEmail {

  generatedEmail;

  constructor(eventAggregator, sessionStore, applicationProperties, assignEmailDialog) {
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.applicationProperties = applicationProperties;
    this.assignEmailDialog = assignEmailDialog;
  }

  activate() {
    this._generateEmailAddress(this.assignEmailDialog.flowName);
  }

  addEmailToInteractionFlow() {
    this._checkIfGenericEmailAddressExists(this.generatedEmail);
  }

  _generateEmailAddress(interactionflowName) {
    let orgName = this.sessionStore.get.organisation.organisationName || 'unknown';
    let flowName = interactionflowName;

    flowName = flowName.replace(/[^\w]/gi, '').toLowerCase();
    orgName = orgName.replace(/[^\w]/gi, '').toLowerCase();
    this.generatedEmail = flowName + '.' + orgName + this.applicationProperties.interactionFlowEmailDomain;
  }

  _checkIfGenericEmailAddressExists(customEmailAddress) {
    let emailAddressFound = false;
    this.assignEmailDialog.emailAddresses.forEach(email => {
      if (email === customEmailAddress) {
        emailAddressFound = true;
      }
    });

    if (emailAddressFound) {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, `Duplicate emails cannot be assigned to flow.`);
    } else {
      this.assignEmailDialog.addEmailToInteractionFlow(customEmailAddress);
    }
  }
}
