import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('TimeRangeModel');

export class TimeRangeModel extends BaseModel{

  public startingHour: number = null;
  public startingMinute: number = null;
  public endingHour: number = null;
  public endingMinute: number = null;

  constructor(startTime: string, endTime: string) {
    super();
    this.extractStartTime(startTime);
    this.extractEndTime(endTime);
  }

  private extractStartTime(startTime: string): void {

    let hours = '9';
    let minutes = '0';

    if(startTime) {
      hours = startTime.substr(0, 2);
      minutes = startTime.substr(2, 2);
    }

    this.startingHour = parseInt(hours);
    this.startingMinute = parseInt(minutes);
  }

  private extractEndTime(endTime: string): void {

    let hours = '17';
    let minutes = '0';

    if(endTime) {
      hours = endTime.substr(0, 2);
      minutes = endTime.substr(2, 2);
    }

    this.endingHour = parseInt(hours);
    this.endingMinute = parseInt(minutes);
  }

  get fromTime(): string {

    let seconds = '00';
    let hours = this.startingHour.toString();
    let minutes = this.startingMinute.toString() + seconds;

    hours = parseInt(this.startingHour) < 10 ? `0${hours}` : hours;
    minutes = parseInt(this.startingMinute) < 10 ? `0${minutes}` : minutes;

    return `${hours}${minutes}`;
  }

  get toTime(): string {

    let seconds = '00';
    let hours = this.endingHour.toString();
    let minutes = this.endingMinute.toString() + seconds;

    hours = parseInt(this.endingHour) < 10 ? `0${hours}` : hours;
    minutes = parseInt(this.endingMinute) < 10 ? `0${minutes}` : minutes;

    return `${hours}${minutes}`;
  }
}