import {autoinject, LogManager, PLATFORM} from 'aurelia-framework';
import Push from 'push.js';
import { v4 as uuidv4 } from 'uuid';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';

const logger = LogManager.getLogger('PushNotificationService');

interface INotificationOptions {
  body?: string;
  tag?: string,
  timeout?: number;
  requireInteraction?: boolean;
  icon?: string;
  data?: string;
  onClick?: Function;
}

@autoinject
export class PushNotificationService {

  private uniqueId: string = uuidv4();
  private pushNotification = Push;
  private options: INotificationOptions = {
    body: '',
    tag: this.uniqueId,
    requireInteraction: false,
    icon: this.zaiFileLoader.getFile('notification-zailab-logo'),
    data: '',
    onClick: () => {
      window.focus();
      this.close();
    }
  };

  constructor(private zaiFileLoader: ZaiFileLoader) {}

  public requestPermission(): Promise<void> {
    return new Promise((resolve, reject) => {
      if(this.pushNotification.Permission.has() === false) {
        this.pushNotification.Permission.request((onGranted) => {
          resolve(onGranted);
        }, (onDenied) => {
          reject(onDenied);
        });
      }
      resolve();
    })
  }

  public notify(title: string, options: INotificationOptions): void {
    if(this.pushNotification.Permission.has() === false) {
      return;
    }
    this.options.onClick = options.onClick || this.options.onClick;
    this.pushNotification.create(title || '', this.options);
  }

  public close(): void {
    this.pushNotification.close(this.uniqueId);
  }
}