import {inject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/*
 * */
import {RoleModel} from './role-model';
/*
 * */
const logger = LogManager.getLogger('RolesService');

/*
 * */
@inject(HttpClient)
export class RolesService {

  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  displayRoles(organisationId, limitedRoles) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/roles')
          .asGet()
          .send()
          .then((response) => {
            resolve(_model(response, limitedRoles));
          }, (error) => {
            reject(error);
          }).catch((error) => {
        reject(error);
      });
    });
  }
}

/**
 * @param response
 * @param limitedRoles
 * @returns {Array}
 * @private
 */
function _model(roles, limitedRoles) {
  let foundRoles = [];
  if (limitedRoles) {
    foundRoles = roles.filter(_role => {
      return limitedRoles.includes(_role);
    });
  } else {
    foundRoles = roles;
  }
  return foundRoles.map(_role => {
    return new RoleModel({roleValue: _role, isSelected: false});
  });
}