import {AbstractService} from '../../_abstract/abstract-service';
import {HttpClient} from 'aurelia-http-client';
import {autoinject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {CommandFactory, OplogService, SessionStore} from 'zailab.common';
import {DisplaySitesModel} from './site-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('SitesService');

/*
 * */
@autoinject()
export class SitesService extends AbstractService {

  private selectDeselectSubscription: any;

  constructor(protected httpClient: HttpClient, protected eventAggregator: EventAggregator, protected oplogService: OplogService, protected sessionStore:SessionStore) {
    super(httpClient, oplogService, eventAggregator);
  }

  public retrieveSites(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fetchDataFromAPI({
        url: `v1/organisation/me/sites`,
        tag: `retrieve-sites`
      }).then(_result => {
        resolve(_result);
      }).catch(_error => {
        reject(_error)
      })
    })
  }

  public removeSite(site: any): void {
    this.subscribeToValidationError(site);
    this.sendDeleteCommandToAPI({
      url: `v1/organisation/${this.sessionStore.get.organisation.organisationId}/sites/${site.id}`,
      tag: `delete-site`
    }).then(_result => {

    }).catch(_error => {

    })
  }

  private subscribeToValidationError(item: any): any {

    if (this.selectDeselectSubscription) {
      this.selectDeselectSubscription.dispose();
    }

    this.selectDeselectSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', () => {
      item.showLoader = false;
    });
  }

  /**
   * Adds a site
   * @fires AddSiteCommand
   * to 'command-service-organisation'
   * @param {string} organisationId of the site to add
   * @param {string} name of the site to add
   * */

  public addSite(organisationId: string, name: string): Promise<any> {
    let payload = {id: uuidv4(), name: name};
    return new Promise((resolve, reject) => {
      this.sendPostCommandToAPI({
        url: `v1/organisation/${this.sessionStore.get.organisation.organisationId}/sites`,
        tag: `create-new-site`,
        content: payload
      }).then(_result => {
        resolve(_result);
      }).catch(_error => {
        reject(_error);
      })
    });
  }
}
