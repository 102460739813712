declare const PLATFORM: any;

// TODO: kornel 2018/10/05 >  this is not nice to work with.  find a nicer way of handling all these routes
export const HUD_LEVEL_ROUTES = [
  {
    route: 'dashboard',
    name: 'dashboard',
    moduleId: PLATFORM.moduleName('features/dashboard/dashboard'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Dashboard',
    settings: {
      access: [
        'DEFAULT_USER',
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'manageorganisation',
    name: 'manageorganisation',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/manage/manage-organisation'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Manage Organisation',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'manageldapgroups',
    name: 'manageldapgroups',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/ldapgroups/ldap-groups'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Manage LDAP Groups',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'userprofile',
    name: 'userprofile',
    moduleId: PLATFORM.moduleName('features/user/person/user-profile'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Personal Information',
    settings: {
      access: [
        'DEFAULT_USER',
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'diagnostics',
    name: 'diagnostics',
    moduleId: PLATFORM.moduleName('features/diagnostics/diagnostics'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Diagnostics',
    settings: {
      access: [
        'DEFAULT_USER',
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'account',
    name: 'account',
    moduleId: PLATFORM.moduleName('features/finance/account/account'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Account',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'interactionflows',
    name: 'interactionflows',
    moduleId: PLATFORM.moduleName(
      'features/interaction/interactionFlow/interaction-flow'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Work Flows',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'loadbalancers',
    name: 'loadbalancers',
    moduleId: PLATFORM.moduleName(
      'features/interaction/loadbalancer/list/load-balancer-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Load Balancers',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'interactionlog',
    name: 'interactionlog',
    moduleId: PLATFORM.moduleName(
      'features/interaction/call/interactionlog/list/interaction-log'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Interaction Log',
    settings: {
      access: [
        'Administrator',
        'Agent',
        'Campaign Manager',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'recentconversations',
    name: 'recentconversations',
    moduleId: PLATFORM.moduleName(
      'features/organisation/conversation/recentconversations/recent-conversations'
    ),
    nav: false,
    title: 'Recent Conversations',
    isTwelveColumnRoute: true,
    isLogoutDisabled: true,
    settings: {
      access: ['Agent'],
    },
  },
  {
    route: 'connectedinteractions',
    name: 'connectedinteractions',
    moduleId: PLATFORM.moduleName(
      'features/interaction/call/connectedinteractions/connected-interactions'
    ),
    nav: false,
    title: 'Open Interactions',
    isTwelveColumnRoute: true,
    isLogoutDisabled: true,
    settings: {
      access: ['Agent'],
    },
  },
  {
    route: 'web-content',
    name: 'web-content',
    moduleId: PLATFORM.moduleName(
      'features/interaction/call/interaction-links/interaction-links-empty'
    ),
    nav: false,
    title: 'Interaction Links',
    isTwelveColumnRoute: true,
    isLogoutDisabled: true,
    settings: {
      access: ['Agent'],
    },
  },
  {
    route: 'recentactivity',
    name: 'recentactivity',
    moduleId: PLATFORM.moduleName(
      'features/organisation/interactions/recentactivity/recent-activity'
    ),
    nav: false,
    title: 'Recent Activity',
    isTwelveColumnRoute: true,
    isLogoutDisabled: true,
    settings: {
      access: ['Agent'],
    },
  },
  {
    route: 'liveinteraction',
    name: 'liveinteraction',
    moduleId: PLATFORM.moduleName(
      'features/interaction/call/liveinteraction/live-interaction'
    ),
    nav: false,
    title: 'Live Interaction',
    isTwelveColumnRoute: true,
    isLogoutDisabled: true,
    settings: {
      access: ['Agent'],
    },
  },
  {
    route: 'invitations',
    name: 'invitations',
    moduleId: PLATFORM.moduleName(
      'features/user/invitation/invitations/invitations'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Invitations',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'worktypes',
    name: 'worktypes',
    moduleId: PLATFORM.moduleName(
      'features/organisation/conversation/worktypes/work-types'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Work Types',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'worktype/:templateId',
    name: 'worktype',
    moduleId: PLATFORM.moduleName(
      'features/organisation/conversation/worktypes/view/view'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Work Types',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'sites',
    name: 'sites',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/sites/list/sites'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Sites',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'services',
    name: 'services',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/services/list/service'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Services',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'skillgroups',
    name: 'skillgroups',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/skillgroups/list/skill-groups'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'SkillGroups',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'skills',
    name: 'skills',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/skills/list/skills'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Skills',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'surveys',
    name: 'surveys',
    moduleId: PLATFORM.moduleName(
      'features/organisation/qualityassurance/surveys/surveys'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Surveys',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'numbers',
    name: 'numbers',
    moduleId: PLATFORM.moduleName(
      'features/finance/telephonenumbers/list/telephone-numbers'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Numbers',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'campaigns',
    name: 'campaigns',
    moduleId: PLATFORM.moduleName('features/campaigns/campaign/campaign'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Campaigns',
    settings: {
      access: ['Campaign Manager'],
    },
  },
  {
    route: 'campaignlivedashboard',
    name: 'campaignlivedashboard',
    moduleId: PLATFORM.moduleName(
      'features/campaigns/campaign/dashboard/campaign-live-dashboard'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Campaigns Live Dashboard',
    settings: {
      access: ['Campaign Manager', 'Team Leader'],
    },
  },
  {
    route: 'campaignlivedashboard/:campaignId',
    name: 'campaignlivedashboard',
    moduleId: PLATFORM.moduleName(
      'features/campaigns/campaign/dashboard/prospect-list-live-dashboard'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Prospect List Live Dashboard',
    settings: {
      access: ['Campaign Manager', 'Team Leader'],
    },
  },
  {
    route: 'campaignlivedashboardsettings',
    name: 'campaignlivedashboardsettings',
    moduleId: PLATFORM.moduleName(
      'features/campaigns/campaign/dashboard/campaign-live-dashboard-settings'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Campaigns Live Dashboard Settings',
    settings: {
      access: ['Campaign Manager', 'Team Leader'],
    },
  },
  {
    route: 'campaignlivedashboardsettings/:campaignId',
    name: 'campaignlivedashboardsettings',
    moduleId: PLATFORM.moduleName(
      'features/campaigns/campaign/dashboard/prospect-list-live-dashboard-settings'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Campaigns Live Dashboard Settings',
    settings: {
      access: ['Campaign Manager', 'Team Leader'],
    },
  },
  {
    route: 'teams',
    name: 'teams',
    moduleId: PLATFORM.moduleName(
      'features/organisation/member/teams/list/teams'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Teams',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'members',
    name: 'members',
    moduleId: PLATFORM.moduleName(
      'features/organisation/member/members/list/members'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Members',
    settings: {
      access: [
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'member/:memberId',
    name: 'member',
    moduleId: PLATFORM.moduleName(
      'features/organisation/member/members/view/view-member'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Members',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'members/edit',
    name: 'member',
    moduleId: PLATFORM.moduleName(
      'features/organisation/member/members/bulkedit/members-bulkedit'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Members Edit',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'mailbox',
    name: 'mailbox',
    moduleId: PLATFORM.moduleName(
      'features/organisation/mailbox/list/mailbox-messages'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Mailbox',
    settings: {
      access: [
        'DEFAULT_USER',
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'analytics',
    name: 'analytics',
    moduleId: PLATFORM.moduleName('features/organisation/analytics/analytics'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Analytics',
    settings: {
      access: [
        'Administrator',
        'Campaign Manager',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'reports',
    name: 'reports',
    moduleId: PLATFORM.moduleName('features/organisation/report/reports'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Report',
    settings: {
      access: [
        'Administrator',
        'Campaign Manager',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'report',
    name: 'report',
    moduleId: PLATFORM.moduleName(
      'features/organisation/report/reports/report'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Report',
    settings: {
      access: [
        'Administrator',
        'Campaign Manager',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'reportschedule',
    name: 'reportschedule',
    moduleId: PLATFORM.moduleName(
      'features/organisation/reportschedule/report-schedule'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Report Schedule',
    settings: {
      access: [
        'Administrator',
        'Campaign Manager',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'transferlist',
    name: 'transferlist',
    moduleId: PLATFORM.moduleName(
      'features/organisation/transferlist/transfer-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Transfer List',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'bypasscos',
    name: 'bypasscos',
    moduleId: PLATFORM.moduleName(
      'features/organisation/bypass-cos/bypass-cos'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Safelist',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'apimonitoring',
    name: 'apimonitoring',
    moduleId: PLATFORM.moduleName('features/monitoring/api-monitoring'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'API Monitoring',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'businesspartner',
    name: 'businesspartner',
    moduleId: PLATFORM.moduleName(
      'features/organisation/businesspartner/business-partner'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Business Partner',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'contactcenter',
    name: 'contactcenter',
    moduleId: PLATFORM.moduleName(
      'features/organisation/contactcenter/contact-center'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Contact Center',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'alert',
    name: 'alert',
    moduleId: PLATFORM.moduleName('features/organisation/alert/alert'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Alert',
    settings: {
      access: [
        'Administrator',
        'Campaign Manager',
        'Team Leader',
        'QA Manager',
      ],
    },
  },
  {
    route: 'alert-messages',
    name: 'alert-messages',
    moduleId: PLATFORM.moduleName(
      'features/organisation/alert/messages/alert-messages'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Alert Messages',
    settings: {
      access: [
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'assist',
    name: 'assist',
    moduleId: PLATFORM.moduleName('features/organisation/assist/assist'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Assist',
    settings: {
      access: ['Team Leader'],
    },
  },
  {
    route: 'qateams',
    name: 'qateams',
    moduleId: PLATFORM.moduleName('hud/dashboard/qualityassessor/teams/teams'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Teams',
    settings: {
      access: ['QA', 'QA Manager'],
    },
  },
  {
    route: 'forms',
    name: 'forms',
    moduleId: PLATFORM.moduleName('hud/dashboard/qualityassessor/forms/forms'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Forms',
    settings: {
      access: ['QA', 'QA Manager'],
    },
  },
  {
    route: 'assessment/:interactionId',
    name: 'assessment',
    moduleId: PLATFORM.moduleName(
      'hud/dashboard/qualityassessor/assessment/assessment'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Assessment',
    settings: {
      access: ['QA', 'QA Manager'],
    },
  },
  {
    route: 'qualityassessor',
    name: 'qualityassessor',
    moduleId: PLATFORM.moduleName(
      'hud/dashboard/qualityassessor/qualityassessor'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Quality Assessor',
    settings: {
      access: [
        'Administrator',
        'Campaign Manager',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'livedashboard',
    name: 'livedashboard',
    moduleId: PLATFORM.moduleName(
      'features/interaction/dashboard/live-dashboard/live-dashboard-routes'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Live Dashboard',
    settings: {
      access: ['Team Leader', 'Agent'],
    },
  },
  {
    route: 'livedashboardoverview',
    name: 'livedashboardoverview',
    moduleId: PLATFORM.moduleName(
      'features/interaction/dashboard/live-dashboard/overview/live-dashboard-overview'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Live Dashboard Overview',
    settings: {
      access: ['Team Leader', 'Agent'],
    },
  },
  {
    route: 'livedashboardsettings',
    name: 'livedashboardsettings',
    moduleId: PLATFORM.moduleName(
      'features/interaction/dashboard/live-dashboard/overview/live-dashboard-settings'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Live Dashboard Settings',
    settings: {
      access: ['Team Leader'],
    },
  },
  {
    route: 'workforcedashboard',
    name: 'workforcedashboard',
    moduleId: PLATFORM.moduleName(
      'features/organisation/workforcemanagement/workforce-management-dashboard'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Workforce Dashboard',
    settings: {
      access: ['Team Leader'],
    },
  },
  {
    route: 'agentavailabilitydashboard',
    name: 'agentavailabilitydashboard',
    moduleId: PLATFORM.moduleName('features/dashboard/teamleader/teamleader'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Agent Availability',
    settings: {
      access: ['Team Leader', 'Administrator', 'Campaign Manager'],
    },
  },
  {
    route: 'contactcentermonitoring',
    name: 'contactcentermonitoring',
    moduleId: PLATFORM.moduleName(
      'features/interaction/dashboard/live-dashboard/contactcentermonitoring/contact-center-monitoring'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Contact Center Monitoring',
    settings: {
      access: ['Team Leader', 'Campaign Manager'],
    },
  },
  {
    route: 'contactcentermonitoringsettings',
    name: 'contactcentermonitoringsettings',
    moduleId: PLATFORM.moduleName(
      'features/interaction/dashboard/live-dashboard/contactcentermonitoring/contact-center-monitoring-settings'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Contact Center Monitoring',
    settings: {
      access: ['Team Leader', 'Campaign Manager'],
    },
  },
  {
    route: 'reminders',
    name: 'reminders',
    moduleId: PLATFORM.moduleName('features/organisation/reminders/reminders'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Reminders',
    settings: {
      access: ['Team Leader'],
    },
  },
  {
    route: ['audio-list'],
    name: 'audio-list',
    moduleId: PLATFORM.moduleName(
      'features/media/media/audiofiles/list/audio-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Audio',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'audio-group-list',
    name: 'audio-group-list',
    moduleId: PLATFORM.moduleName(
      'features/media/media/audiofiles/group/audio-group-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Audio Groups',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'mailbox-list',
    name: 'mailbox-list',
    moduleId: PLATFORM.moduleName('features/mailbox/list/mailbox-list'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Mailboxes',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'disposition-codes',
    name: 'disposition-codes',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/dispositioncodes/disposition-codes'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Disposition Codes',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'contact-fields',
    name: 'contact-fields',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/contactfields/contact-fields'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Contact Fields',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'integrations',
    name: 'integrations',
    moduleId: PLATFORM.moduleName('features/integrations/integrations-module'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Integrations',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'cannedresponses',
    name: 'cannedresponses',
    moduleId: PLATFORM.moduleName('features/cannedresponses/canned-responses'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Canned Responses',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'instantmessaging',
    name: 'instantmessaging',
    moduleId: PLATFORM.moduleName('features/im/im'),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Instant Messaging Configuration',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'emergencyannouncement',
    name: 'emergencyannouncement',
    moduleId: PLATFORM.moduleName(
      'features/interaction/emergencyAnnouncement/list/emergency-announcements'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Emergency Announcement',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'smsgateways',
    name: 'smsgateways',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/sms-gateways/sms-gateways'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'SMS Gateways',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'autologout',
    name: 'autologout',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/auto-logout/auto-logout'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Auto Logout',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'post-interaction-processing',
    name: 'post-interaction-processing',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/post-interaction-processing/post-interaction-processing-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Post Interaction Processing',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'interaction-log-filters',
    name: 'interaction-log-filters',
    moduleId: PLATFORM.moduleName(
      'features/interaction/call/interactionlog/searchablevariables/searchable-variables'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Interaction Log Filters',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'presence-codes',
    name: 'presence-codes',
    moduleId: PLATFORM.moduleName(
      'features/organisation/organisation/presence-codes/list/presence-codes-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Presence Codes',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'waiting-room-configuration',
    name: 'waiting-room-configuration',
    moduleId: PLATFORM.moduleName(
      'features/organisation/interactions/waiting-room-configuration/waiting-room-configuration'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Waiting Room Configuration',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'webchat-configurations',
    name: 'webchat-configurations',
    moduleId: PLATFORM.moduleName(
      'features/organisation/webchat/list/webchat-list'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Webchat Configuration',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'agent-matching-dashboard',
    name: 'agent-matching-dashboard',
    moduleId: PLATFORM.moduleName(
      'features/task/agent-matching/list/agent-matching-scores'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Agent Matching Dashboard',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'donotcontact',
    name: 'donotcontact',
    moduleId: PLATFORM.moduleName(
      'features/organisation/donotcontact/donotcontact'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Do Not Contact',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'in-app-alerts',
    name: 'in-app-alerts',
    moduleId: PLATFORM.moduleName(
      'features/organisation/in-app-alerts/in-app-alerts'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'In App Alerts',
    settings: {
      access: ['Administrator'],
    },
  },
  {
    route: 'in-house-wfm',
    name: 'in-house-wfm',
    moduleId: PLATFORM.moduleName(
      'features/organisation/workforcemanagement/in-house-wfm/in-house-wfm'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'In House WFM',
    settings: {
      access: [
        'Administrator',
        'Agent',
        'Campaign Manager',
        'Office Employee',
        'QA',
        'QA Manager',
        'Team Leader',
      ],
    },
  },
  {
    route: 'agent-script-admin-config',
    name: 'agent-script-admin-config',
    moduleId: PLATFORM.moduleName(
      'features/quality-assurance/agent-script/admin-config/agent-script-admin-config'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Agent Scripts',
    settings: { access: ['Administrator'] },
  },
  {
    route: 'assistants',
    name: 'assistants',
    moduleId: PLATFORM.moduleName(
      'features/interaction/interactionFlow/assistants/assistants'
    ),
    isTwelveColumnRoute: true,
    nav: false,
    title: 'Assistants',
    settings: {
      access: ['Administrator'],
    },
  },
];
