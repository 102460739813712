import {inject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';

/*
 */
@inject(Router)
export class OrganisationInteractionManager {

  constructor(router) {

    this.router = router;
  }

  configureRouter(config, router) {

    let routeMap = [{
      route: '',
      name: 'list',
      moduleId: PLATFORM.moduleName('./list/display-interaction-flows'),
      nav: false,
      title: 'List',
      isTwelveColumnRoute: true
    }, {
      route: 'audit',
      name: 'audit',
      moduleId: PLATFORM.moduleName('./audit/interaction-flows-audit'),
      nav: false,
      title: 'Interactionflows Audit',
      isTwelveColumnRoute: true
    }, {
      route: 'create',
      name: 'create',
      moduleId: PLATFORM.moduleName('./create/interaction-flow-properties'),
      nav: false,
      title: 'Create',
      isTwelveColumnRoute: false,
      isWingsHidden: true
    }, {
      route: 'interactiondesigner/:interactionFlowId',
      name: 'interactiondesigner',
      moduleId: PLATFORM.moduleName('./interaction-designer/interaction-designer'),
      nav: false,
      title: 'Interaction Designer',
      isTwelveColumnRoute: true,
      isWingsHidden: false
    }];

    config.map(routeMap);

    this.router = router;
  }
}
