import { AccountSessionModel } from '../../../../../_common/stores/sessionmodels/account-session-model';
import { Money } from '../recharge-trigger-model';

export class LowCreditNotificationDetails {

  public account: AccountSessionModel;
  public threshold: string;
  public emails: string[];
  public enabled: boolean;

  public clone(): LowCreditNotificationDetails {
    return Object.assign(new LowCreditNotificationDetails(), this);
  }

  public isEmpty(): boolean {
    return !this.threshold && !this.emails;
  }

  public clear(): void {
    this.threshold = null;
    this.emails = null;
  }

  public update(other: LowCreditNotificationDetails): void {
    this.threshold = other.threshold;
    this.emails = other.emails;
  }

  public toPayload(): LowCreditNotificationPayload {
    return new LowCreditNotificationPayload(new Money(this.threshold, this.account.available.currency), this.emails);
  }

  public equals(other: LowCreditNotificationDetails): boolean {
    return this.threshold === other.threshold && this.emails === other.emails;
  }
}

export class LowCreditNotificationPayload {

  public threshold: Money;
  public emails: string[];

  constructor(threshold: Money, emails: string[]) {
    this.threshold = threshold;
    this.emails = emails;
  }
}
