// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { BootstrapFormRenderer } from 'zailab.common';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';

import ProspectListService from '../prospectlist/prospect-list-service';
import { observable } from 'aurelia-binding';
//@ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('ExportProspectsDialog');

@autoinject
export class ExportProspectsDialog {

  private campaignId: string;
  private ignoreDateChange: boolean;

  // Form properties
  public notServed: boolean = true;
  public requeued: boolean = true;
  public completed: boolean = true;
  public stale: boolean = true;
  public prospectLists: { prospectListId: string, name: string }[] = [];
  public selectedProspectListName: string = 'All';
  public allTime: boolean = true;
  @observable public fromDate: any;
  @observable public toDate: any;

  private validation: any;

  constructor(
    private dialogController: DialogController,
    private prospectListService: ProspectListService,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(data: { campaignId: string }): void {
    this.setupValidation();
  
    this.campaignId = data.campaignId;
    this.prospectListService
      .retrieveCampaign(this.campaignId)
      .then(response => {
        let promises = [];
        promises.push(response.prospectLists.map(item => {
          return {
            prospectListId: item.prospectListId,
            name: item.name
          };
        }));

        Promise.all(promises)
          .then((data) => {
            this.prospectLists = [{ prospectListId: '', name: 'All' }].concat(data[0]);
          });

      });
  }

  private setupValidation(): void {
    ValidationRules.customRule('validDateValue', (value: any) => {
      return moment(value).isValid();
    }, 'The date value is incorrect.');

    ValidationRules.customRule('validDateRange', (value: any) => {
      let threeMonthDifference = moment(this.fromDate).diff(moment(this.toDate), 'months', true);
      if (threeMonthDifference > 0) {
        return false;
      }
      return true;
    }, 'The date range is incorrect.');

    ValidationRules
      .ensure('fromDate')
      .required()
      .when(() => !this.allTime)
      .withMessage('Please enter a date.')
      .satisfiesRule('validDateValue')
      .when(() => !this.allTime)
      .satisfiesRule('validDateRange')
      .when(() => !this.allTime)
      .ensure('toDate')
      .required()
      .when(() => !this.allTime)
      .withMessage('Please enter a date.')
      .satisfiesRule('validDateValue')
      .when(() => !this.allTime)
      .satisfiesRule('validDateRange')
      .when(() => !this.allTime)
      .on(this);
  }

  public toggleNotServedEnabled(): void {
    this.notServed = !this.notServed;
  }

  public toggleRequeuedEnabled(): void {
    this.requeued = !this.requeued;
  }

  public toggleCompletedEnabled(): void {
    this.completed = !this.completed;
  }

  public toggleStaleLeadsEnabled(): void {
    this.stale = !this.stale;
  }

  public toggleAllTimeEnabled(): void {
    this.allTime = !this.allTime;

    if (this.allTime) {
      this.clearDateSelection();
    }
  }

  private clearDateSelection(): void {
    this.ignoreDateChange = true;
    this.fromDate = '';
    this.toDate = '';
    setTimeout(() => {
      this.ignoreDateChange = false;
    }, 100);
  }
  
  public fromDateChanged(): void {
    if (!this.ignoreDateChange) {
      this.deselectAllTime();
    }
  }

  public toDateChanged(): void {
    if (!this.ignoreDateChange) {
      this.deselectAllTime();
    }
  }

  private deselectAllTime(): void {
    this.allTime = false;
  }


  public cancel(): void {
    this.dialogController.cancel();
  }

  public submit(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }

      const selectedProspectList = this.prospectLists.find(pp => pp.name === this.selectedProspectListName);
      const params = {
        notServed: this.notServed,
        requeued: this.requeued,
        completed: this.completed,
        stale: this.stale,
        prospectListId: selectedProspectList ? selectedProspectList.prospectListId : '',
        fromDate: this.fromDate,
        toDate: this.toDate ? moment(this.toDate).add('day', 1).format('YYYY-MM-DD'): undefined
      };
      
      this.dialogController.ok(params);
    });
  }
}

