/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPanel');
/*
 */
@customElement('z-panel')
export class ZPanel {

  @bindable marginbottom;
  @bindable padding;
  @bindable buttons;
  @bindable fullheight;
  @bindable header;
  @bindable alignheader;
  @bindable utility;
  @bindable footer;
  @bindable headerclassname;

  bodyHeight;
  contentHeight;
  bind() {
    // calculate body height
    let utility = this.utility;
    let footer = this.footer;
    let utilityAndFooter = (this.utility && this.footer);

    if (utilityAndFooter) {
      this.bodyHeight = 'o-panel__body--utility-footer';
    } else if (utility) {
      this.bodyHeight = 'o-panel__body--utility';
    } else if (footer) {
      this.bodyHeight = 'o-panel__body--footer';
    } else {
      this.bodyHeight = 'o-panel__body--full-height'
    }

    let header = this.header;
    let fullheight = this.fullheight;
    if (header && fullheight) {
      this.contentHeight = 'o-panel__content--full-height-with-header';
    } else {
      this.contentHeight = 'o-panel__content--full-height';
    }
  }

}
