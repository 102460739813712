import { autoinject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { TeamLeaderService } from './team-leader-service';
import { TeamModel } from './models/team-model';
import { OplogService } from '../../../_common/services/database-service';
import { StatsModel } from './models/stats-model';
import { visibilityAware, ZIVisibilityAware } from '../../../_common/services/visibilityawareness/visibility-aware';
import { SessionStore } from '../../../_common/stores/session-store';
import { computedFrom } from 'aurelia-binding';

const logger = LogManager.getLogger('Teamleader');

@autoinject
@visibilityAware
export class Teamleader implements ZIVisibilityAware{
  public agentStats: StatsModel;
  public teamId: string;
  public loading: boolean = false;
  private subscriptions: any[] = [];
  private defaultTeamId: string;

  constructor(
    private teamLeaderService: TeamLeaderService,
    private databaseService: OplogService,
    private router: Router,
    private eventAggregator:EventAggregator,
    private sessionStore: SessionStore
  ) {}

  public activate(params: any): void {
    this.defaultTeamId = params.teamId;
  }

  private selectTeam(team: TeamModel): void {
    this.teamId = team.teamId;
    this.retrieveTeamStats(this.teamId);
  }

  private retrieveTeamStats(teamId: string): void {
    this.toggleLoader(true);
    this.unsubscribe();
    this.teamLeaderService.retrieveTeamStats(teamId).then(overview => this.teamStatsRetrieved(overview), this.reportError);
  }

  private teamStatsRetrieved(stats: StatsModel): void {
    this.toggleLoader(false);
    this.agentStats = stats;
    this.subscribe('_id', stats.teamId, 'member-projector.teamStatisticsView', 'update', response => {
      this.updateTeamStats(response);
    });
  }

  private updateTeamStats(response: any): void {
    if (response._id) {
      response.teamId = response._id;
    }
    let convertedResponse: StatsModel = new StatsModel(response);
    this.agentStats.mapProperties(convertedResponse);
  }

  private toggleLoader(isLoading: boolean): void {
    this.loading = isLoading;
  }

  private subscribe(keyField: string, keyValue: string, viewName: string, operation: string, callback: any): void {
    // @ts-ignore
    let subscription = this.databaseService.subscribeOn(keyField, keyValue).in(viewName);
    subscription.on(operation, callback);
    this.subscriptions.push(subscription);
  }

  private reportError(msg: any): void {
    logger.warn('errorMessage >', msg);
  }

  @computedFrom('sessionStore.get.user')
  public get isCampaignManager(): boolean {
    return this.sessionStore.get.user.hasCampaignManagerRole;
  }

  public deactivate(): void {
    this.unsubscribe();
  }

  private unsubscribe(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  becameInvisible(): void {
  }

  becameVisible(): void {
    this.retrieveTeamStats(this.teamId);
  }
}