import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/**/
import {SessionStore, OplogService, WebSocket, ApplicationProperties} from 'zailab.common';
import {DispositionModel} from './disposition-codes-model';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
import uploadRequestBody from '../../../campaigns/campaign/prospectlist/upload-request-body';
/**/
const logger = LogManager.getLogger('DispositionCodesService');

@autoinject
export class DispositionCodesService {

  constructor(
    private httpClient: HttpClient,
    private oplogService: OplogService,
    private sessionStore: SessionStore,
    private webSocket: WebSocket,
    private applicationProperties: ApplicationProperties
  ) {}

  public putDispositionCodeOnInteraction(interactionId: string, memberId: string, dispositionCodeId: string, comments: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/${interactionId}/disposition-code`)
      .asPut()
      .withContent({ memberId, dispositionCodeId, comments })
      .send()
      .catch(error => {
      });
  }

  public retrieveDispositions(): Promise<DispositionModel[]> {

    let organisationId: string = this.sessionStore.get.organisation.organisationId;

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists`)
        .asGet().send()
        .then(response => resolve(this.modelCollection(response, DispositionModel)), error => reject(error));
    });
  }

  public createDispositionCode(dispositionCode: DispositionModel): Promise<any> {

    let organisationId: string = this.sessionStore.get.organisation.organisationId;

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionCode.id}`)
        .asPost().withContent(dispositionCode)
        .send().then(success => resolve(success), error => reject(error))
    });
  }

  public retrieveDispositionCode(organisationId: string, dispositionCodeId: string): Promise<any> {
    return this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/dispositioncode`)
      .asGet()
      .withParams({ dispositionCodeId })
      .send()
      .catch((error: any) => {
        throw new Error(error);
      });
  }

  public retrieveDispositionList(organisationId: string, dispositionListId: string): Promise<any> {
    return this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionListId}/dispositionlist`)
      .asGet()
      .send()
      .then((response: any) => {
        try {
          return new DispositionModel(response);
        } catch (e) {
          throw new Error('');
        }
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }

  public retrieveDispositionListByServiceChannelAndDirection(organisationId: string, serviceId: string, channel: string, direction: string, tiered: boolean = false): Promise<any> {
    return this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/dispositionlist`)
      .asGet()
      .withParams({ serviceId, channel, direction, tiered })
      .send()
      .then((response: any) => {
        try {
          return new DispositionModel(response);
        } catch (e) {
          throw new Error('');
        }
      })
      .catch((error: any) => {
        // throw new Error(error);
      });
  }

  public retrieveDispositionListByIdAndOrganisationId(id: string, organisationId: string): Promise<any> {
    return this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${id}/dispositionlist`)
      .asGet()
      .withParams({ organisationId, id})
      .send()
      .then((response: any) => {
        try {
          return new DispositionModel(response);
        } catch (e) {
          throw new Error('');
        }
      })
      .catch((error: any) => {
        logger.warn('retrieveDispositionListByIdAndOrganisationId FAILED', error);
      });
  }


  public retrieveInteractionOutcomeReasons(organisationId: string): Promise<any> {

    return new Promise((resolve) => {
      this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/interactionoutcomereasons`)
        .asGet()
        .send()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    });
  }

  public retrieveConversationOutcomes(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.createRequest(`v1/organisation/conversationoutcomes`)
        .asGet()
        .send()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    });
  }

  public addDispositionCodeToDispositionList(organisationId: string, dispositionListId: string, dispositionCode: any): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionListId}/dispositioncodes/${dispositionCode.dispositionCodeId}`)
      .asPut()
      .withContent(dispositionCode)
      .send()
      .catch(error => {
      });
  }

  public removeDispositionCodeFromDispositionList(organisationId: string, dispositionListId: string, dispositionCodeId: any): void {
    this.httpClient
      .createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionListId}/dispositioncodes/${dispositionCodeId}`)
      .asDelete()
      .withContent({})
      .send()
      .catch(error => {
      });
  }

  public toggleDispositionListEnabled(organisationId: string, dispositionListId: string, enabled: boolean): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionListId}/enabled`)
      .asPut()
      .withContent({ enabled })
      .send()
      .catch(error => {});
  }

  public deleteDispositionList(organisationId: string, dispositionListId: string): void {
    this.httpClient
      .createRequest(`v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionListId}`)
      .asDelete()
      .send()
      .catch((error: string) => {});
  }

  public bulkUploadDispositionList(organisationId: string, dispositionListId: string, fileId: string, file: any): Promise<void> {

    return new Promise((resolve, reject) => {

      let body = new uploadRequestBody();

      body.addDisposition({
        name: 'file',
        fileName: file.name,
        contentType: 'application/octet-stream',
        content: file.content
      });

      let host = this.applicationProperties.apiQueryEndpoint;
      let xhr = new XMLHttpRequest();
      xhr.open('POST', host + `v1/organisation/organisations/${organisationId}/dispositionlists/${dispositionListId}/file/${fileId}`, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.sessionStore.get.user.token);
      xhr.setRequestHeader('Content-Type', 'multipart/mixed; boundary=' + body.boundary);

      xhr.onreadystatechange = function(): void {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.send(body.getContent());
    })
  }

  public modelCollection(items: any, Model: any): any {
    if (!items) {
      return [];
    }
    if (items instanceof Array) {
      let mappedList: Array<any> = [];
      for (let item of items) {
        mappedList.push(new Model(item))
      }
      return mappedList;
    }
    return new Model(items);
  }
 
  public initialiseOplog(organisationId: string): Promise<ZIOplog> {
    return new Promise(resolve => {
      // @ts-ignore
      resolve(this.oplogService.subscribeOn('organisationId', organisationId).in('organisation-projector.dispositionListView'));
    });
  }

  public subscribeToDispositionListDeletedEvent(callback: (data: any) => void): void {
    this.webSocket.subscribe({
      name: 'com.zailab.organisation.dispositionlist.api.events.DispositionListDeletedEvent',
      callback
    });
  }

  public unsubscribeFromDispositionListDeletedEvent(): void {
    this.webSocket.unSubscribe('com.zailab.organisation.dispositionlist.api.events.DispositionListDeletedEvent');
  }
}
