import { LogManager, customElement, bindable } from 'aurelia-framework';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('ZTimeline');
/*
 */
@customElement('z-timeline')
export class ZTimeLine {

    @bindable private layout: string;
    @bindable private scroll: boolean;
    private scrollButtonTop: Element;
    private scrollButtonBottom: Element;
    private scrollBoxWrap: any;
    private scrollBoxInner: any;
    private wrapHeight: number;
    private innerHeight: number;
    private scrollInterval;
    private timelineScrollId: string;
    private hasOverflow: boolean;
    private scrollAtTop: boolean;
    private scrollAtBottom: boolean;

    constructor() {
        this.timelineScrollId = uuidv4();
    }

    public attached(): void {
        if (this.scroll) {
            this.scrollBoxWrap = document.getElementById(`js-scroll-box-wrap-${this.timelineScrollId}`);
            this.scrollBoxInner = document.getElementById(`js-scroll-box-inner-${this.timelineScrollId}`);
            this.checkForOverflow();

            this.scrollBoxWrap.addEventListener('scroll', () => {
                this.checkScrollPosition();
            });
        }
    }

    private getWrapHeight(e: any): void {
        // if the window size changes retrieve sizes
        this.wrapHeight = e.height;
        this.innerHeight = this.scrollBoxInner.clientHeight;

        this.checkForOverflow();
        this.checkScrollPosition();
    }

    private getScrollHeight(e: any): void {
        // if more child elements are added retrieve sizes
        this.innerHeight = e.height;
        this.wrapHeight = this.scrollBoxWrap.clientHeight;

        this.checkForOverflow();
        this.checkScrollPosition();
    }

    private checkScrollPosition(): void {
        let currentScrollTop = this.scrollBoxWrap.clientHeight + this.scrollBoxWrap.scrollTop;

        if (this.scrollBoxWrap.scrollTop > 0) {
            this.scrollAtTop = false;
        }
        else if (this.scrollBoxWrap.scrollTop === 0) {
            this.scrollAtTop = true;
        }

        if (this.scrollBoxWrap.scrollHeight === currentScrollTop) {
            this.scrollAtBottom = true;
        } else if (this.scrollBoxWrap.scrollHeight > currentScrollTop) {
            this.scrollAtBottom = false;
        }
    }

    private checkForOverflow(): void {
        if (this.innerHeight > this.wrapHeight) {
            this.hasOverflow = true;
        }
    }

    private scrollUp(): void {
        this.scrollInterval = setInterval(() => {
          this.scrollBoxWrap.scrollTop -= 100;
        }, 100);
      }
    
      private scrollDown(): void {
        this.scrollInterval = setInterval(() => {
          this.scrollBoxWrap.scrollTop += 100;
        }, 100);
      }
    
      private stopScrolling(): void {
        clearInterval(this.scrollInterval);
      }

}
