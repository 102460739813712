import { observable } from 'aurelia-binding';
import { LogManager, autoinject } from 'aurelia-framework';
import { ZaiForm } from 'zailab.common';

const logger = LogManager.getLogger('Input Capture | Name');

@autoinject
export class Name {
  public model;

  public form: any[][];
  @observable public formData: {
    name: string;
    variableName: string;
    valid: boolean;
  };

  public activate(bindingContext: { data: any }): void {
    this.initWizard(bindingContext);
    this.setupForm();
  }

  private initWizard(bindingContext: any): void {
    this.model = bindingContext;
    bindingContext.beforeNextStep(() => {
      this.model.continue();
    });

    if (this.model.data.name && this.model.data.variableName) {
      this.model.step.complete({});
    }
  }

  private setupForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput()
      .withFocus()
      .withIdentifier('name')
      .withTitle('Name', '160px')
      .withPlaceholder('Please enter a name for this Input Capture.')
      .withValue(this.model.data.name)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN },
        {
          validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
          value: 50,
        },
      ])
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('variableName')
      .withTitle('Variable Name', '160px')
      .withPlaceholder('Please enter a variable name for this Input Capture.')
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN },
        {
          validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
          value: 50,
        },
      ])
      .withValue(this.model.data.variableName)
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public formDataChanged(newValue: any): void {
    if (newValue.valid) {
      this.model.step.complete(newValue);
      this.model.data.name = newValue.name;
      this.model.data.variableName = newValue.variableName;
    } else {
      this.model.step.incomplete();
    }
  }
}
