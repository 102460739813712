import {LogManager, customElement, bindable, inject, observable} from 'aurelia-framework';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('ZListContainer');

@inject(Element)
@customElement('z-list-container')
export class ZListContainer {

  @bindable staticbody: boolean;
  @bindable iswizardlistview: boolean;
  @bindable loading: boolean;
  private container: Element;
  private element: Element;
  private uniqueIdSelector: string;
  private scrollFadeTop: boolean;

  constructor(element: Element) {
    this.element = element;
    this.uniqueIdSelector = uuidv4();
  }

  public attached(): void {
    this.container = document.getElementById('js-scrollable-list-view-' + this.uniqueIdSelector);
    logger.info('Adding scroll event to z-list-container');
    this.container.addEventListener('scroll', () => {
      logger.info('scrolling z-list-container');
      let currentScrollTop = this.container.clientHeight + this.container.scrollTop;
      if (this.container.scrollTop > 0 && !this.scrollFadeTop) {
        this.scrollFadeTop = true;
      } else if (this.container.scrollTop === 0) {
        this.scrollFadeTop = false;
      } else if (this.container.scrollHeight <= currentScrollTop) {
        this.dispatch('scrollend', '');
      }
    });
  }

  private dispatch(name: string, data: any): void {
    this.element.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail: data
      })
    );
  }

  public detached(): void {
    logger.info('removing z-list-container scroller');
    this.container.removeEventListener('js-scrollable-list-view-' + this.uniqueIdSelector, () => {

    });
    this.scrollFadeTop = false;
  }

}
