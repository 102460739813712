import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import * as impl from './agent-script-admin-config-impl.json';
import { AgentScriptAdminConfigModel, mapAgentScriptAdminConfigModel } from "./agent-script-admin-config-model";

@autoinject
export class AgentScriptAdminConfigService {

  public impl = impl;

  constructor(
    private httpClient: HttpClient
  ) { }

  public async create(data: AgentScriptAdminConfigModel): Promise<any> {
    return this.httpClient
      .createRequest(this.impl.baseUrl)
      .asPost()
      .withContent(data)
      .send();
  }

  public async retrieveAll(): Promise<AgentScriptAdminConfigModel[]> {
    return this.httpClient.createRequest(this.impl.baseUrl)
      .asGet()
      .send()
      .then((res: any) => res.map((data) => mapAgentScriptAdminConfigModel(data)));
  }

  public async retrieve(id: string): Promise<AgentScriptAdminConfigModel> {
    return this.httpClient.createRequest(`${this.impl.baseUrl}/${id}`)
      .asGet()
      .send()
      .then((res: any) => mapAgentScriptAdminConfigModel(res));
  }

  public async update(data: AgentScriptAdminConfigModel): Promise<any> {
    return this.httpClient
      .createRequest(`${this.impl.baseUrl}/${data.id}`)
      .asPut()
      .withContent(data)
      .send();
  }

  public async delete(id: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.impl.baseUrl}/${id}`)
      .asDelete()
      .send();
  }
}