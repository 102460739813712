import { LogManager, inject } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { EncryptTools, WindowService, BootstrapFormRenderer } from 'zailab.common';
import { COMPLETE_REGISTRATION_ACTIONS } from './complete-registration-actions';
import { CompleteRegistrationService } from './complete-registration-service';
import { UserPassportService } from '../../passport/user-passport-service';

const logger = LogManager.getLogger('CompleteRegistration');

@inject(EventAggregator, ValidationControllerFactory, Router, CompleteRegistrationService, WindowService, UserPassportService)
export class CompleteRegistration {

  invitationId;
  userRegistrationId;
  token;
  firstName = '';
  surname = '';
  email = '';
  password = '';
  confirmPassword = '';
  termsValidated;
  capsError = null;
  isCurrentStep;
  displayPasswordValidation;
  isMemberInvite;
  submitted = false;

  constructor(eventAggregator, validationControllerFactory, router, completeRegistrationService, windowService, userPassportService) {

    this.eventAggregator = eventAggregator;
    this.completeRegistrationService = completeRegistrationService;
    this.router = router;
    this.windowService = windowService;
    this.userPassportService = userPassportService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    this._registerEvents();
  };

  activate(params) {

    this._log('activate :: params=', params);

    this._initialiseValidation();
    this.windowService.disableUnload();
    this.completeRegistrationService.enableCapslockOnPasswordDetection();

    let invitationId = params.invitationId;
    let userRegistrationId = params.userRegistrationId;
    let token = params.token;
    if (params.email) {
      this.email = params.email;
    }
    if (params.isMemberInvite) {
      this.isMemberInvite = params.isMemberInvite;
    }
    this.displayPasswordValidation = false;

    if (userRegistrationId) {
      this.userRegistrationId = userRegistrationId;
    }

    if (invitationId) {

      this.invitationId = invitationId;

      this.completeRegistrationService.completeRegistrationByInvitation(userRegistrationId).then(() => {
      }, () => {
      }).catch((error) => {
        this._log('completeRegistrationByInvitation :: error=', error);
        this.router.navigate('registrationerror');
      });

    } else {
      this.token = token;
      this.completeRegistrationService.completeRegistration(userRegistrationId, token).then((_response) => {
        this.firstName = _response.firstName;
        this.surname = _response.surname;
        this.email = _response.email;
      }).catch((error) => {
        this._log('completeRegistration :: error=', error);
        this.router.navigate('registrationerror');
      });
    }
  }

  attached() {

    this._initialiseSteps();
  }

  deactivate() {
    this.windowService.enableUnload();
    this.completeRegistrationService.disableCapslockOnPasswordDetection();
  }

  get isName() {
    return this.isCurrentStep && this.isCurrentStep[0].active;
  }

  get isPassword() {
    return this.isCurrentStep && this.isCurrentStep[1].active;
  }

  get commandPayload() {
    return { email: this.email, userRegistrationId: this.userRegistrationId, invitationId: this.invitationId, firstName: this.firstName, surname: this.surname };
  }

  next() {

    this.validation.validate()
      .then((validation) => {
        if (!validation.valid) {
          return;
        }
        if (this.isName && this.invitationId) {
          this.completeRegistrationService.subscribeToUserIdentityTrustedEvent()
            .then((data) => this.handleUserIdentityTrustedEvent(data));
          this.completeRegistrationService.subscribeToUserIdentityConfirmationRequiredEvent()
            .then(() => this.router.navigate('emailsubmitted'));
          this.completeRegistrationService.submitRegistrationFromInvite(this.commandPayload);
        } else {
          this.showPasswordView();
        }
      }).catch(() => {
      });
  }

  handleUserIdentityTrustedEvent(data) {
    this.userRegistrationId = data.state.userRegistrationId;
    this.showPasswordView();
  }

  showPasswordView() {
    this.isCurrentStep[0].active = false;
    this.isCurrentStep[1].active = true;
    this._initialisePasswordValidation();
  }

  back() {

    this.isCurrentStep[0].active = true;
    this.isCurrentStep[1].active = false;
    this._initialiseValidation();
  }

  submit() {

    if (this.submitted) return;

    this.error = null;
    this.submitted = true;

    this.validation.validate()
      .then((validation) => {

        this.submitted = false;
        if (!validation.valid) {
          return;
        }
        this._validateTerms();
      }, () => {
        this.submitted = false;
      }).catch(() => {
        this.back();
        this.submitted = false;
      });
  }

  selectTerms(termsValidate) {

    if (termsValidate) {

      this.error = 'Please accept the Terms and Conditions.';
    } else {

      this.validateTerms = !termsValidate;
      this.error = null;
    }
    return true;
  }

  checkValidationSize() {

    this.displayPasswordValidation = false;

    if (this.validation && this.validation.errors) {

      if (!this.validation.errors.length) {
        return;
      }

      let validationErrors = this.validation.errors;

      for (let error of validationErrors) {

        for (let property in error) {
          if (error.hasOwnProperty(property) && (error[property] === 'password') && (error['message'].length > 30)) {
            this.displayPasswordValidation = true;
            return;
          }
        }
      }
      this.displayPasswordValidation = false;
    }
  }

  /**
   * @private
   * */
  _registerEvents() {

    this.eventAggregator.subscribe(COMPLETE_REGISTRATION_ACTIONS.CAPS_LOCK_ERROR, (message) => {

      this.capsError = message;
    });
  }

  /**
   * @private
   * */
  _validateTerms() {

    if (!this.termsValidated) {

      this.error = 'Please accept the Terms and Conditions.';
      this.submitted = false;
      return;
    }

    this._register();
  }

  /**
   * @private
   * */
  _initialiseValidation() {

    ValidationRules
      .customRule('length', (value) => {
        if (!value) {
          return true;
        }
        let re = /^([a-zA-Z-\s'])+$/.test(value);
        if ((value.length > 30 || value.length < 2) && re) {
          return false;
        }
        return true;
      });

    ValidationRules
      .customRule('validValue', (value) => {
        if (!value) {
          return true;
        }
        let re = /^([a-zA-Z-\s'])+$/.test(value);
        if ((value.length <= 30 && value.length >= 2) && !re) {
          return false;
        }
        return re;
      });

    ValidationRules
      .ensure('firstName')
      .required().withMessage('Please enter your first name.')
      .satisfiesRule('length').withMessage('Must be between 2 and 30 characters long.')
      .satisfiesRule('validValue').withMessage('Is not a valid input.')
      .ensure('surname')
      .required().withMessage('Please enter your last name.')
      .satisfiesRule('length').withMessage('Must be between 2 and 30 characters long.')
      .satisfiesRule('validValue').withMessage('Is not a valid input.')
      .on(this);
  }

  _initialisePasswordValidation() {

    ValidationRules
      .customRule('confirmPassword', (value) => value === this.password, 'Your passwords do not match.');

    ValidationRules
      .ensure('password')
      .required().withMessage('Please enter your password.')
      .matches(/^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])\S{8,50}$/).withMessage('Must be between 8 and 50 characters, and contain at least one lowercase, one uppercase and one number.')
      .ensure('confirmPassword')
      .satisfiesRule('confirmPassword')
      .on(this);
  }

  /**
   * @private
   * */
  _initialiseSteps() {
    this.isCurrentStep = {
      0: {
        name: 'firstName',
        active: true
      },
      1: {
        name: 'password',
        active: false
      }
    };
  }

  /**
   * @private
   * */
  _register() {

    if (this.submitted) {
      return;
    }

    var encryptedPassword = EncryptTools.encrypt(this.password);
    this.submitted = true;

    var registrationInfo = {
      userRegistrationId: this.userRegistrationId,
      token: this.token,
      firstName: this.firstName,
      surname: this.surname,
      password: encryptedPassword,
      acceptedTerms: this.termsValidated
    };

    this._log('_register :: this.userRegistrationId=', this.userRegistrationId);

    this.completeRegistrationService.completeUserRegistration(registrationInfo).then((email) => {
      this.router.navigateToRoute('verify', { email: this.email }, { replace: true });
    })
      .catch(e => {
        this.submitted = false;
      });
  }

  _log(...args) {
    logger.debug('🔍', ...args);
  }
}
