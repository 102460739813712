import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import TelephoneNumbersService from '../../../../finance/telephonenumbers/telephone-numbers-service';
import { AbstractList } from 'zailab.abstract';
import { SessionStore } from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('UnassignMemberDialog');
/*
 * */
@inject(TelephoneNumbersService, SessionStore, DialogController, EventAggregator)
export class UnassignMemberDialog extends AbstractList {

  number = null;
  dialogHeader = 'Unassign Number';

  constructor(telephoneNumbersService, sessionStore, dialogController, eventAggregator) {
    super(eventAggregator);
    this.numberListService = telephoneNumbersService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;
  }

  activate(number) {
    this.number = number;
  }

  cancel() {
    this.dialogController.cancel();
  }

  unAssignFromMember() {
    this.numberListService.unAssignNumberFromMember(this.number.telephoneNumberId, this.number.telephoneNumber, this.number.assignee.id, this.sessionStore.get.organisation.organisationId);
    this.dialogController.ok();
  }
}

