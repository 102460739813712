import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { AudioService, AUDIO_ASSETS, PushNotificationService } from 'zailab.common';

import { RoutingStatusService } from './routing-status-service';

const logger = LogManager.getLogger('ActivityDialog');

@autoinject()
export class RoutingStatusActivityDialog {

  private dialogHeader: string = 'Not Responding';
  private allSubscriptions: Subscription[] = [];
  private memberId: string;

  constructor(private routingStatusService: RoutingStatusService, private dialogController: DialogController, private eventAggregator: EventAggregator, private audioService: AudioService, private pushNotificationService: PushNotificationService) {
  }

  public activate(data: string): void {
    this.memberId = data;
  }

  public attached(): void {
    this.audioService.play(AUDIO_ASSETS.USER_NOT_RESPONDING, { startTime: 0, duration: 3000 });
    this.subscribe();
    this.pushNotificationService.notify(this.dialogHeader, { body: '' });
  }

  private subscribe(): void {
    this.allSubscriptions.push(this.eventAggregator.subscribe('close.activity.dialog', () => {
      this.dialogController.cancel();
    }));
    this.allSubscriptions.push(this.eventAggregator.subscribe('routing.status.updated', routingStatusView => {
      if (routingStatusView.routingStatus === 'Not Ready') {
        this.dialogController.cancel();
      }
      if (routingStatusView.routingStatus === 'Ready') {
        this.dialogController.ok();
      }
    }));
  }

  public ready(): void {
    this.routingStatusService.markMemberAsResponsive(this.memberId);
    this.pushNotificationService.close();
    this.dialogController.ok();
  }

  public notReady(): void {
    this.routingStatusService.setRoutingStatusToNotReady(this.memberId);
    this.pushNotificationService.close();
    this.dialogController.ok();
  }

  public detached(): void {
    this.audioService.stop();
    this.allSubscriptions.forEach(_subscription => {
      try {
        _subscription.dispose();
      } catch (e) {
        // Could not dispose of subscription
      }
    });
  }

}

