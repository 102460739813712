/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZListCard');
/*
 */
@customElement('z-list-card')
export class ZListCard {

  @bindable delete;
  @bindable candelete = true;
  @bindable hoverable;
  @bindable selected;
  @bindable size;
  @bindable placeholder;
  @bindable listclass;
  @bindable highlight;
  @bindable hasloader = false;
  @bindable isDeleting = false;
  @bindable({attribute: 'has-error'}) hasError;
  @bindable({attribute: 'is-hovered'}) isHovered;
}
