import { LogManager, customElement, bindable, inject } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';

import { SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('ZCurrencyInput');

@customElement('z-currency-input')
@inject(SessionStore)
export class ZCurrencyInput {

  @bindable({ attribute: 'field-name' }) fieldName: string;
  @bindable({ attribute: 'input-value' }) inputValue: string;
  @bindable({ attribute: 'min-input-value' }) minValue: number;
  @bindable({ attribute: 'max-input-value' }) maxValue: number;
  @bindable({ attribute: 'is-edit' }) isEdit: boolean;
  @bindable({ attribute: 'validation-controller' }) validate: ValidationController;
  @bindable({ attribute: 'validation-rules' }) rules: string[];

  private sessionStore: SessionStore;
  private currencyFormatter: CurrencyFormatter;
  private currencyValidationSupport: CurrencyValidationSupport;

  constructor(sessionStore: SessionStore) {
    this.sessionStore = sessionStore;
    this.currencyFormatter = new CurrencyFormatter(this.currencySymbol);
  }

  public attached(): void {
    this.currencyValidationSupport = new CurrencyValidationSupport(this.minValue, this.maxValue, this.fieldName);
    this.initValidationRules(this);
  }

  public get currencySymbol(): string {
    return this.sessionStore.get.account.currencySymbol;
  }

  public get inputValueWrapper(): string {
    return this.currencyFormatter.prependCurrencySymbol(this.inputValue);
  }

  public set inputValueWrapper(value: string) {
    this.inputValue = this.currencyFormatter.removeCurrencySymbol(value);
  }

  private initValidationRules(model: ZCurrencyInput): void {

    const rules = ValidationRules
      .ensure('inputValueWrapper')
      .required()
      .withMessage(`Please enter ${this.currencyValidationSupport.fieldName}amount.`)
      .then()
      .satisfies((value: string) => this.validateNotificationThresholdFormatting(value))
      .withMessage(`Please enter a valid ${this.currencyValidationSupport.formattedFieldName}amount.`);

    if (this.currencyValidationSupport.isInputValueRanged) {
      rules.then()
        .satisfies((value: string) => this.validateNotificationThresholdAmount(value))
        .withMessage(`Please enter a${this.currencyValidationSupport.isAnNeeded ? 'n' : ''} ${this.currencyValidationSupport.formattedFieldName}amount between ${this.currencyValidationSupport.minValue} and ${this.currencyValidationSupport.maxValue}.`)
    }

    if (this.rules) {
      this.rules.forEach((rule) => rules.satisfiesRule(rule));
    }

    rules.on(model);
  }

  private validateNotificationThresholdFormatting(value: string): boolean {
    return CurrencyValidationSupport.NUMBER_REGEXP.test(this.currencyFormatter.removeCurrencySymbol(value));
  }

  private validateNotificationThresholdAmount(value: string): boolean {
    let nValue = Number.parseFloat(this.currencyFormatter.removeCurrencySymbol(value));
    return nValue > this.currencyValidationSupport.minValue && nValue < this.currencyValidationSupport.maxValue;
  }
}

class CurrencyFormatter {

  private currencySymbol: string;

  constructor(currencySymbol: string) {
    this.currencySymbol = currencySymbol;
  }

  prependCurrencySymbol(value: string): string {
    return `${this.currencySymbol} ${value ? value : ''}`;
  }

  public removeCurrencySymbol(value: string): string {
    let temp = value.split(' ').join('');
    if (temp.startsWith(this.currencySymbol)) {
      temp = temp.substring(1);
    }
    return temp;
  }
}

class CurrencyValidationSupport {

  static NUMBER_REGEXP = /^\d+\.{0,1}\d{0,2}$/;

  public minValue: number;
  public maxValue: number;
  public fieldName: string;

  constructor(minValue: number, maxValue: number, fieldName: string) {
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.fieldName = fieldName;
  }

  public get isInputValueRanged(): boolean {
    return Number.parseFloat(this.minValue + '') < Number.parseFloat(this.maxValue + '');
  }

  public get formattedFieldName(): string {
    if (!this.fieldName) {
      return '';
    }
    return `${this.fieldName} `;
  }

  public get isAnNeeded(): boolean {
    if (!this.fieldName) {
      return true;
    } else if (['i', 'o', 'u', 'a', 'e'].indexOf(this.fieldName[0]) !== -1) {
      return true;
    }
    return false;
  }
}