import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { OplogService, WebSocket } from 'zailab.common';

const logger = LogManager.getLogger('WaitingRoomConfigService');

@autoinject()
export class WaitingRoomConfigService {
  constructor(
    private httpClient: HttpClient,
    private webSocket: WebSocket
  ) {}

  public retrieveAgentSamplingConfig(): Promise<any> {
    const url = `v1/organisation/interactions/waitingroom/agentsampling`;
    return this.httpClient
      .createRequest(url)
      .asGet()
      .send()
      .catch(e => { throw new Error('Failed to retrieve Agent Sampling Confgurations.') });
  }

  
  public updateAgentSamplingConfig(
    enabled: boolean,
    globalAgentThreshold: number,
    globalAgentSampleSize: number,
    globalTaskThreshold: number,
    globalTaskSampleSize: number
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/interactions/waitingroom/agentsampling`)
      .asPost()
      .withContent({
        enabled,
        globalAgentThreshold,
        globalAgentSampleSize,
        globalTaskThreshold,
        globalTaskSampleSize
      })
      .send()
  }

}
