import { LogManager, customElement, bindable } from 'aurelia-framework';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import $ from 'jquery';
import { containerless } from 'aurelia-templating';

const logger = LogManager.getLogger('ColorPicker');

@customElement('z-color-picker')
@containerless()
export class ColorPicker {
  @bindable({ attribute: 'selected-color' }) public selectedColor: string;
  @bindable({ attribute: 'disable-no-fill' }) public disableNoFill: boolean;
  public customColors: { color: string; selected?: boolean }[] = [];
  public previousColor: string;

  private pickerId: string = uuidv4();
  private closeTimeout: any;
  public PALETTE: string[][] = [
    ['#26a8ff', '#007acc', '#00223a'], // BLUE
    ['#aee28e', '#13ff00', '#124429'], // GREEN
    ['#edbb5a', '#ffa800', '#B34e24'], // ORANGE
    ['#ff6363', '#ff0000', '#800020'], // RED
  ];
  public DEFAULT_CUSTOM_PALETTE: string[] = [''];
  public visible: boolean = false;
  public colorRadiantVisible: boolean = false;

  public show(): void {
    this.visible = true;
  }
  public hide(): void {
    this.visible = false;
  }

  private getColorPicker(): any {
    let colorPicker = $('#colorPicker-' + this.pickerId)[0],
      colorPickerObj;
    let keys = Object.keys(colorPicker);
    keys.forEach((key) => {
      if (key && colorPicker[key] && key.indexOf('jQuery') >= 0) {
        if (colorPicker[key].ejColorPicker) {
          colorPickerObj = colorPicker[key].ejColorPicker;
        }
      }
    });
    return colorPickerObj;
  }

  private counter = 0;
  public showColorRadiant(): void {
    this.colorRadiantVisible = true;
    this.counter++;
    var colorObj = this.getColorPicker();

    if (!colorObj) {
      if (this.counter >= 15) {
        return;
      }
      setTimeout(() => {
        this.showColorRadiant();
      }, 50);
      return;
    }

    if (this.selectedColor) {
      this.previousColor = this.selectedColor;
    }
    colorObj.model.select = (args: any): void => {
      if (!this.customColors) {
        this.customColors = [];
      }
      const selectedItem = this.customColors.find((item) => item.selected);
      if (selectedItem) {
        selectedItem.color = args.value;
        selectedItem.selected = false;
      } else {
        const hasCustom = this.customColors.find(
          (item) => item.color === args.value
        );
        if (!hasCustom) {
          this.customColors.push({ color: args.value });
        }
      }
      this.setColor(args.value);
    };

    colorObj.show();
    this.setPopPosition();
  }
  public hideColorRadiant(): void {
    this.colorRadiantVisible = false;
  }

  private setPopPosition(): void {
    let colorPop: HTMLElement = document.querySelector(
      'div#colorPicker-' + this.pickerId + '_popup'
    );
    var selecterWrapper = document.querySelector(
      '#selecterWrapper-' + this.pickerId
    );
    if (!colorPop || !selecterWrapper) {
      setTimeout(() => {
        this.setPopPosition();
      }, 50);
      return;
    }
    let pos = selecterWrapper.getBoundingClientRect();
    if (!pos.y && !pos.x) {
      setTimeout(() => {
        this.setPopPosition();
      }, 50);
      return;
    }
    colorPop.style.top = pos.y + 'px';
    colorPop.style.left = pos.x + 'px';
    colorPop.style.opacity = '100';
  }

  public resetTimer(): void {
    this.clearTimeout();
  }

  public startCloseTimer(): void {
    this.clearTimeout();

    this.closeTimeout = setTimeout(() => {
      this.hide();
    }, 1000);
  }

  public unbind(): void {
    this.clearTimeout();
  }

  private clearTimeout(): void {
    this.closeTimeout && window.clearTimeout(this.closeTimeout);
  }

  public select(event: any, color?: string): void {
    event.stopPropagation();
    this.hide();
    this.clearTimeout();
    this.setColor(color);
  }

  public selectCustom(
    event: any,
    color: { color: string; selected?: boolean }
  ): void {
    event.stopPropagation();
    this.setColor(color.color);
  }

  public setColor(color: string): void {
    if (color) {
      this.selectedColor = color;
    } else {
      this.selectedColor = null;
    }
  }
}
