import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';
import {autoinject, LogManager, Factory} from 'aurelia-framework';

import {OplogService, SessionStore} from 'zailab.common';
import { ZIOplog } from '../../../../typings/zai/zai.common';

import { v4 as uuidv4 } from 'uuid';

const API_URLS = {
  EMERGENCY_ANOUNCEMENT: 'v1/interaction/emergency-messages'
};

const logger = LogManager.getLogger('EmergencyAnnouncementService');

@autoinject
export class EmergencyAnnouncementService {

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private oplog: OplogService) {}

  public initialiseOplog(): Promise<ZIOplog> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      // @ts-ignore
      resolve(this.oplog.subscribeOn('organisationId', organisationId).in('interaction-flow-projector.emergencyMessageView'));
    });
  }

  public createEmergencyAnnouncement(emergencyAnnouncement: ZEmergencyAnnouncement): Promise<ZEmergencyAnnouncement> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    const emergencyMessageId = uuidv4();
    const payload = {
      organisationId,
      ...emergencyAnnouncement
    };
    return this.httpClient.createRequest(`${API_URLS.EMERGENCY_ANOUNCEMENT}/${emergencyMessageId}`)
      .asPost()
      .withContent(payload)
      .send()
      .catch((error) => logger.warn(`Failed to create emergency announcement with id ${emergencyAnnouncement.emergencyMessageId} due to cause:`, error));
  }

  public activateEmergencyAnnouncement(emergencyMessageId: string): Promise<ZEmergencyAnnouncement> {
    return this.httpClient.createRequest(`${API_URLS.EMERGENCY_ANOUNCEMENT}/${emergencyMessageId}/active`)
      .asPut()
      .withContent({})
      .send()
      .catch((error) => logger.warn(`Failed to create emergency announcement with id ${emergencyMessageId} due to cause:`, error));
  }

  public deactivateEmergencyAnnouncement(emergencyMessageId: string): Promise<ZEmergencyAnnouncement> {
    return this.httpClient.createRequest(`${API_URLS.EMERGENCY_ANOUNCEMENT}/${emergencyMessageId}/active`)
      .asDelete()
      .send()
      .catch((error) => logger.warn(`Failed to create emergency announcement with id ${emergencyMessageId} due to cause:`, error));
  }

  public updateEmergencyAnnouncement(emergencyAnnouncement: ZEmergencyAnnouncement): Promise<ZEmergencyAnnouncement> {
    let payload = {
      audioId: emergencyAnnouncement.audioId,
      workTypeId: emergencyAnnouncement.workTypeId,
      menuSelectionEnabled: emergencyAnnouncement.menuSelectionEnabled
    };
    return this.httpClient.createRequest(`${API_URLS.EMERGENCY_ANOUNCEMENT}/${emergencyAnnouncement.emergencyMessageId}`)
      .asPut()
      .withContent(payload)
      .send()
      .catch((error) => logger.warn(`Failed to create emergency announcement with id ${emergencyAnnouncement.emergencyMessageId} due to cause:`, error));
  }

  public retrieveEmergencyAnnouncements(): Promise<{ emergencyMessages: ZEmergencyAnnouncement[] }> {
    return this.httpClient.createRequest(API_URLS.EMERGENCY_ANOUNCEMENT)
      .asGet()
      .send()
      .catch((error) => logger.warn('Failed to retrieve emergency announcements due to cause:', error));
  }

  public retrieveEmergencyAnnouncement(emergencyAnnouncementId: string): Promise<ZEmergencyAnnouncement> {
    return this.httpClient.createRequest(`${API_URLS.EMERGENCY_ANOUNCEMENT}/${emergencyAnnouncementId}`)
      .asGet()
      .send()
      .catch((error) => logger.warn(`Failed to retrieve emergency announcement with id ${emergencyAnnouncementId} due to cause:`, error));
  }

  public deleteEmergencyAnnouncement(emergencyAnnouncementId: string): Promise<ZEmergencyAnnouncement> {
    return this.httpClient.createRequest(`${API_URLS.EMERGENCY_ANOUNCEMENT}/${emergencyAnnouncementId}`)
      .asDelete()
      .send()
      .catch((error) => logger.warn(`Failed to delete emergency announcement with id ${emergencyAnnouncementId} due to cause:`, error));
  }
}
