import {inject, LogManager, customElement, bindable} from 'aurelia-framework';

const logger = LogManager.getLogger('ZDialog');
@inject(Element)
@customElement('z-dialog')
export class ZDialog {

  @bindable header;
  @bindable list;
  /**
   * @param size string determines the size/width of the popup
   * current accepted values are wide or small
   * defaults to small*/
  @bindable size;
  @bindable hasfooter;
  /**
   * the height of popup is currently set to a fixed 500px.
   * by adding the value of 'auto' it will add a modifier to make it scale vertically
   */
  @bindable height;

  @bindable width;
  @bindable showcancel;

  constructor(element) {
    this.element = element;
  }

  bind() {

    this.setWidth();
  }

  setWidth() {

    switch (this.width) {
      case 'small': this.width = 'small';
        break;
      case 'small-medium': this.width = 'small-medium';
        break;
      case 'medium': this.width = 'medium';
        break;
      case 'large': this.width = 'large';
        break;
      default: '';
    }
  }

  /**
   * this gets called when the cross at the top right is selected.
   * @fires cancel CustomEvent triggers a cancel dom event on the element*/
  cancel() {
    this.dispatch('cancel', '');
  }

  dispatch(name, data) {
    this.element.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail: data
      })
    );
  }

  get showFooter() {
    return this.hasfooter ? true : false;
  }

  get test() {
    return this.showcancel ? false : true;
  }
}