import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('LogService');

@autoinject()
export class LogService {

  constructor(
    private sessionStore: SessionStore,
    private httpClient: HttpClient
  ) {}

  public log(data: any): void {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    let memberId = this.sessionStore.get.user.memberId;
    const payload = {
      type: 'info',
      organisationId,
      memberId,
      source: 'conversations',
      data
    };

    this.httpClient.createRequest(`v1/log`)
      .asPost()
      .withContent(payload)
      .send()
      .catch(error => {});
  }

}
