import { inject, LogManager } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, validateTrigger } from 'aurelia-validation';

import { BootstrapFormRenderer } from 'zailab.common';

const logger = LogManager.getLogger('CurrentScopeValidation');

@inject(ValidationControllerFactory)
export class CurrentScopeValidation {

  private validationControllerFactory: ValidationControllerFactory;
  private controller: ValidationController;

  constructor(validationControllerFactory: ValidationControllerFactory) {
    this.validationControllerFactory = validationControllerFactory;
    this.init();
  }

  private init(): void {
    this.controller = this.validationControllerFactory.createForCurrentScope();
    this.controller.addRenderer(new BootstrapFormRenderer());
    this.controller.validateTrigger = validateTrigger.change;
  }

  public getController(): ValidationController {
    return this.controller;
  }
}
