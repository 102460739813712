import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';

import { BootstrapFormRenderer, SessionStore, SearchTools } from 'zailab.common';
import { EmergencyAnnouncementService } from '../emergency-announcement-service';
import { FetchAudioService } from '../../../../_common/services/fetch-audio-service';
import { AudioFileModel } from '../../../../_common/models/audio-file-model';
import { WorkTypesService } from '../../../organisation/conversation/worktypes/work-types-service';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import toastr from 'toastr';
import { WorkTypeModel } from '../../interactionFlow/interaction-designer/dialogs/callback/models/work-type-model';
import { AudioSearchFilter } from '../../interactionFlow/interaction-designer/dialogs/audio-search-filter';

const logger = LogManager.getLogger('AddEmergencyAnnouncementDialog');

@autoinject
export class AddEmergencyAnnouncementDialog {

  private emergencyAnnouncement: ZEmergencyAnnouncement = { name: '', menuSelectionEnabled: true };
  private emergencyAnnouncements: ZEmergencyAnnouncement[];
  private audioMessageList: AudioFileModel[] = [];
  private workTypes: WorkTypeModel[] = [];

  private validation;

  private dialogHeader: string = 'Add Emergency Announcement';

  private saving: boolean = false;
  private error: string;
  private searchTerm: string = '';
  private searchTermWorkType: string = '';
  private isSearching: boolean = false;
  private isSearchingWorkType: boolean = false;

  constructor(
    private emergencyAnnouncementService: EmergencyAnnouncementService,
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    private sessionStore: SessionStore,
    private fetchAudioService: FetchAudioService,
    private workTypesService: WorkTypesService,
    public audioSearchFilter: AudioSearchFilter,
  ) {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param object
   * */
  private activate(data: ZEmergencyAnnouncement[]): void {
    this.emergencyAnnouncements = data || [];
    this.initValidation();
    this.retrieveAudioFiles();
    // this.retrieveWorkTypes();
  }

  private initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        let loadBalancer = this.emergencyAnnouncements.find(item => item.name.toLowerCase() === value.toLowerCase());
        if (loadBalancer) {
          return false;
        }
        return true;
      }, 'Please enter a unique name.');
    
    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a emergency announcement name.')
      .satisfiesRule('uniqueName')
      .on(this.emergencyAnnouncement);
  }

  private retrieveAudioFiles(_searchText: string = ''): void {
    this.fetchAudioService.searchAudioFiles(_searchText)
    .then(audioMessages => this.audioFilesRetrieved(audioMessages), this.reportError);
  }
  protected audioFilesRetrieved(_audioFiles: AudioFileModel[]): void {
    this.audioMessageList = _audioFiles;

    this.audioMessageList.sort((a: AudioFileModel, b: AudioFileModel) => {
      return parseInt(b.timestamp) - parseInt(a.timestamp);
    });

    this.audioMessageList.forEach(_audio => {
      _audio.audioId = _audio.id;
      _audio.readableTimeStamp = moment(_audio.timestamp).calendar() || "Just now";
    });
    
    this.audioSearchFilter.onConfirmed(() => this.audioMessageList = this.audioSearchFilter.filter());
    this.audioSearchFilter.setAllAudioList(this.audioMessageList);
  }
  
  // private retrieveWorkTypes(): void {
  //   this.workTypesService.retrieveWorkTypes().then((workTypes: WorkTypeModel[]) => {
  //     this.workTypes = workTypes;
  //   });
  // }
  
  private selectAudioMessage(audioMessage: AudioFileModel): void {
    this.error = null;
    this.audioMessageList.forEach(item => item.isSelected = false);
    audioMessage.isSelected = true;
    this.emergencyAnnouncement.audioId = audioMessage.id;
  }

  // private selectWorkType(workType: WorkTypeModel): void {
  //   this.error = null;
  //   this.workTypes.forEach(item => item.isSelected = false);
  //   workType.isSelected = true;
  //   this.emergencyAnnouncement.workTypeId = workType.templateId;
  // }

  private reportError(): void {
    toastr.error('Could not retrieve your audio files. Please wait a few moment and try again.');
  }

  protected partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
  
  protected toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }
  
  protected toggleWorkTypeSearch(): void {
    this.isSearchingWorkType = !this.isSearchingWorkType;
    if (!this.isSearchingWorkType) {
      this.searchTerm = '';
    }
  }

  /**
   * Closes the Aurelia dialog
   */
  private cancel(): void {
    this.dialogController.cancel();
  }

  private save(): void {

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      if (this.isValidEmergencyAnnouncement() === false) {
        return;
      }
      this.createEmergencyAnnouncement();
    }, error => {
      logger.info('addEmergencyAnnouncement > validation failure', error);
    });
  }

  private isValidEmergencyAnnouncement(): boolean {
    if (!this.emergencyAnnouncement.audioId) {
      this.error = 'Please select an audio.';
      return false;
    }
    // if (this.workTypes.length > 0 && !this.emergencyAnnouncement.workTypeId) {
    //   this.error = 'Please select a work type.';
    //   return false;
    // }
    return true;
  }

  private createEmergencyAnnouncement(): void {
    
    this.emergencyAnnouncementService
      .createEmergencyAnnouncement(this.emergencyAnnouncement)
      .then(() => this.dialogController.ok());
  }

}

