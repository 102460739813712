import { LogManager, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('Alert');

export class Alert {

  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: ['', 'list'],
        name: 'Alert List',
        moduleId: PLATFORM.moduleName('./list/alert-list'),
        title: 'List'
      },
      {
        route: 'add',
        name: 'Alert Add',
        moduleId: PLATFORM.moduleName('./add/alert-add'),
        title: 'Add'
      }
    ]);
    this.router = router;
  }
}