import { inject, LogManager } from 'aurelia-framework';
import { AudioAddService } from 'zailab.common';
import { bindable, customElement } from "aurelia-templating";
import { bindingMode } from "aurelia-binding";
import { EventAggregator } from 'aurelia-event-aggregator';
/* */
const logger = LogManager.getLogger('AudioManager');

/* */
@inject(EventAggregator)
@customElement('z-audio-manager')
export class ZAudioUpload {

  @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedTab;
  @bindable dragDropActive: boolean = false;
  @bindable isValid: boolean = false;

  public audioTabs: Array<any> = [
    { id: '1', name: 'Upload Audio', icon: 'cloud-upload', viewModel: 'features/media/media/audiofiles/addaudio/uploadaudio/upload-audio' },
    { id: '2', name: 'Text to Speech', icon: 'text-size', viewModel: 'features/media/media/audiofiles/addaudio/texttospeech/text-to-speech' }
  ];

  constructor(private readonly events: EventAggregator) {
    this.events.subscribe('audio-upload-first-added', () => {
      this.audioTabs[1].faded = true;
    });
    this.events.subscribe('audio-upload-last-removed', () => {
      this.audioTabs[1].faded = false;
    });
  }
}