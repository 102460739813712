import {HttpClient} from 'aurelia-http-client';
import {Factory, inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {CommandFactory, OplogService, SessionStore} from 'zailab.common';
import {DisplayServiceModel} from './service-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('ServicesService');

/*
 * */
@inject(HttpClient, EventAggregator, Factory.of(CommandFactory), SessionStore, OplogService)
export class ServicesService {

  selectDeselectSubscription;

  constructor(httpClient, eventAggregator, commandFactory, sessionStore, oplogService) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
  }

  retrieveOrganisationServices() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/services')
        .asGet()
        .send()
        .then((response) => {
          resolve(this._serviceListFactory(response));
        }, (error) => {
          reject(error);
        });
    });
  }

  retrieveOrganisationSelectedServices() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/organisations/me/selected-services')
        .asGet()
        .send()
        .then((response) => {
          resolve(this._serviceListFactory(response['services']));
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseServicesOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('organisation-projector.displayServicesView'));
    });
  }

  retrieveServicesStats() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/services')
        .asGet()
        .send()
        .then((response) => {
          if (response) {
            resolve(response.length || 0);
          }
          else {
            resolve(0);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseStatsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('organisation-projector.serviceWidgetView'));
    });
  }

  displayServices() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/organisations/me/selected-services')
        .asGet()
        .send()
        .then((response) => {
          resolve(this._model(response));
        }, (error) => {
          reject(error);
        });
    });
  }

  /**
   * @private
   * */
  _serviceListFactory(serviceData) {
    let serviceList = [];
    serviceData.forEach(service => {

      service.isSelected = service.selected;
      serviceList.push(new DisplayServiceModel(service));
    });
    return serviceList;
  }

  /**
   * removes a service from an organisation
   * @fires RemoveServiceCommand
   * to 'command-service-organisation'
   * @param {string} organisationId of the service
   * @param {DisplayServiceModel} service the service to remove
   * @emits ServiceRemovedEvent on completion from the backend
   * */
  removeService(organisationId, service) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/${organisationId}/services/${service.id}`)
        .asDelete()
        .send()
        .then((response) => {
          resolve(service.id);
        }, (error) => {
          reject(error)
        });
    });
  }


  /**
   * deselects a service in an organisation
   * @fires DeselectServiceCommand
   * to 'command-service-organisation'
   * @param {string} organisationId of the service
   * @param {DisplayServiceModel} service the service to deselect
   * @emits ServiceDeselectedEvent on completion from the backend
   * */
  deselectService(organisationId, service) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/services/${service.id}/selection`)
        .asDelete()
        .send()
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  }

  /**
   * selects a service in an organisation
   * @fires SelectServiceCommand
   * to 'command-service-organisation'
   * @param {string} organisationId of the service
   * @param {DisplayServiceModel} service the service to select
   * @emits ServiceSelectedEvent on completion from the backend
   * */
  selectService(organisationId, service) {
    return this.httpClient.createRequest(`v1/organisation/organisations/${organisationId}/services/${service.id}/selection`)
      .asPut()
      .send()
      .then((response) => { return response; })
      .catch((error) => { return error; });
  }

  subscribeToValidationError() {
    if (this.selectDeselectSubscription) {
      this.selectDeselectSubscription.dispose();
    }

    return new Promise((resolve) => {
      this.selectDeselectSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', () => {
        this.selectDeselectSubscription.dispose();
        resolve();
      });
    });
  }

  unsubscribeFromValidationError() {
    if (this.selectDeselectSubscription) {
      this.selectDeselectSubscription.dispose();
    }
  }

  /**
   * adds a service to an organisation
   * @fires AddServiceCommand
   * to 'command-service-organisation'
   * @param {string} organisationId of the service
   * @param {string} name the name of the service to add
   * @emits ServiceRemovedEvent on completion from the backend
   * */
  addService(organisationId, name) {
    return new Promise((resolve, reject) => {
      let id = uuidv4();
      let commandPayload = {
        id: id, name: name
      };
      this.httpClient.createRequest(`v1/organisation/${organisationId}/services`)
        .asPost()
        .withHeader('Content-Type', 'application/json;charset=UTF-8')
        .withContent(commandPayload)
        .send()
        .then((response) => {
          resolve(commandPayload);
        }, (error) => {
          reject(error);
        });
    });
  }

  /**
   * @param data
   * @returns {Array}
   * @private
   */
  _model(data) {
    return data.services.map(_service => {
      return new DisplayServiceModel(_service);
    });
  }
}
