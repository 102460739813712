import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';

import { HttpClient } from 'aurelia-http-client';
import { SessionStore, OplogService, MESSAGE_EVENTS } from 'zailab.common';
import { MembersService } from '../member/members/members-service';
import { SeverityMap } from './list/dashboard-alerts-dialog/dashboard-alerts-dialog';

@autoinject()
export class InAppAlertsNotificationsService {
  private passportSubscription: any;
  private updateTimeout;

  private alertIds: { id: string, value: string }[] = [];
  private alertsNotificationOplogs: any = [];

  constructor(
    private httpClient: HttpClient,
    private oplogService: OplogService,
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private membersService: MembersService
  ) {}

  public initialise(): void {
    this.subscribeToPassportChanges();
    this.subscribeAndUpdate();
    this.retrieveAlerts();
  }

  private async subscribeAndUpdate(): Promise<void> {
    this.alertIds.length = 0;
    await this.retrieveOplogIds();
    this.subscribeToAlertsOplogs();
  }

  private subscribeToPassportChanges(): void {
    this.passportSubscription = this.eventAggregator.subscribe(
      'PASSPORT:UPDATE',
      async () => {
        window.clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => this.subscribeAndUpdate(), 500);
      }
    );
  }

  private async retrieveOplogIds(): Promise<void> {
    const organisationId = {
      id: this.sessionStore.get.organisation.organisationId,
      value: 'ORGANISATION_ID'
    };
    const member = {
      id: this.sessionStore.get.user.memberId,
      value: 'MEMBER_ID'
    }
    const memberData = await this.membersService.retrieveMember(member.id);
    const teamIds = memberData.teams.map((team) => {
      return {
        id: team.id,
        value: 'TEAM_ID'
      };
    });
    const bpIds = memberData.businessPartners.map((bp) => {
      return {
        id: bp.id,
        value: 'BUSINESS_PARTNER_ID'
      }
    });
    const ccIds = [];
    for (const partner of memberData.businessPartners) {
      const contactCentres = partner.contactCentres || [];
      for (const centre of contactCentres) {
        ccIds.push({
          id: centre.id,
          value: 'CONTACT_CENTER_ID'
        });
      }
    }
    this.alertIds = [organisationId, member, ...teamIds, ...bpIds, ...ccIds];
  }

  private subscribeToAlertsOplogs(): void {
    this.alertIds.forEach((field) => {
      this.alertsNotificationOplogs[field.id] = this.subscribe(
        `audiencesMap.${field.id}`,
        field.value,
        'alerts-projector.alerts',
        (data) => this.triggerNotifications(data)
      );
    });
  }

  private subscribe(
    field: string,
    value: string,
    view: string,
    callback: (data) => void
  ): any {
    const oplog: any = this.oplogService.subscribeOn(field, value).in(view);
    oplog.on('insert', (data) => callback(data));
    oplog.on('update', (data) => callback(data));
    oplog.on('delete', (data) => callback(data));
    return oplog;
  }

  private triggerNotifications(data): void {
    if (data.status === 'RESOLVED') {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, data.message);
    } else if (data.status === 'FIRING') {
      if (data.severity === SeverityMap.Critical) {
        this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, data.message);
      } else if (data.severity === SeverityMap.Warning) {
        this.eventAggregator.publish(MESSAGE_EVENTS.WARNING, data.message);
      } else if (data.severity === SeverityMap.Informational) {
        this.eventAggregator.publish(MESSAGE_EVENTS.INFO, data.message);
      }
    }
  }

  public dispose(): void {
    this.passportSubscription && this.passportSubscription.dispose();
  }

  private async retrieveAlerts(): Promise<void> {
    const alerts: any = await this.httpClient
      .createRequest('/v1/notifications/alerts/')
      .asGet()
      .send();
    alerts.forEach((alert) => this.triggerNotifications(alert));
  }
}
