import {LogManager} from 'aurelia-framework';
const logger = LogManager.getLogger('FullNameValueConverter');

export class FullNameValueConverter {
  public toView(names: { firstName: string, surname: string }): any {
    if (!names.firstName && !names.surname) {
      return '(No Name)';
    }
    if (!names.firstName && names.surname) {
      return names.surname;
    }
    if (names.firstName && !names.surname) {
      return names.firstName;
    } else {
      return names.firstName + ' ' + names.surname;
    }
  }
}
