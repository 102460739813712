import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ProportionalWidget } from '../../../../abstract/widgets/proportional-widget-abstract';

const logger = LogManager.getLogger('TransferListWidget');

@autoinject
export class TransferListWidget extends ProportionalWidget {

  constructor(private router: Router, eventAggregator: EventAggregator) {
    super(eventAggregator, null);
  }

  public activate(widgetConfig: ZIWidgetConfig): void {
    super.activate(widgetConfig);
  }

  public view(): void {
    this.router.navigate('transferlist');
  }
}
