import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('z-table');

/*
 */
@customElement('z-table')
export class ZTable {
  /*
   * The table headers should be an object taht defines the headers for your table and how they map to your data:
   * {property: "itemName", description: "Item Name"}
   * property is the property name a it is in the datarow object
   * description is the text displayed in the heading of the table
   * @type {object}
   */
  @bindable headers;

  /*
   * The dataRows contains the data that is displayed in the table.  the properties should match the ones in the tableModel
   * @type {object}
   */
  @bindable datarows;

  /*
   * HasMoreRows will determine whether to pull more data, or stop all data pulls for the current query
   * @type {boolean}
   */
  @bindable hasmorerows;

}