import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { OplogService, WebSocket } from 'zailab.common';

const logger = LogManager.getLogger('IMService');

@autoinject()
export class PostInteractionProcessingService {
  constructor(
    private httpClient: HttpClient,
    private webSocket: WebSocket
  ) {}

  public retrievePostInteractionProcessors(organisationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `v1/organisation/${organisationId}/interaction-post-processors`;
      this.httpClient
        .createRequest(url)
        .asGet()
        .send()
        .then(response => {
          // @ts-ignore
          resolve(response.interactionPostProcessors || []);
        })
        .catch(error => reject(error));
    });
  }

  public retrievePostInteractionProcessorsAudit(organisationId: string, page: number, size: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `v1/organisation/${organisationId}/interaction-post-processors/audit`;
      this.httpClient
        .createRequest(url)
        .asGet()
        .withParams({ page, size })
        .send()
        .then(response => {
          // @ts-ignore
          resolve(response.entries || []);
        })
        .catch(error => reject(error));
    });
  }
  
  public createInteractionPostProsessor(organisationId: string, payload: any): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors`)
      .asPost()
      .withContent(payload)
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }

  public subscribeToInteractionPostProsessorCreated(callback: (data: any) => void): void {
    this.webSocket.subscribe({
      name: 'com.zailab.organisation.interactionpostprocessor.api.events.InteractionPostProcessorCreatedEvent',
      callback
    });
  }

  public unsubscribeFromInteractionProcessorAddedEvent(): void {
    this.webSocket.unSubscribe('com.zailab.interaction.emergencymessage.api.events.EmergencyMessageDeletedEvent');
  }z
  
  public activatePostProcessor(organisationId: string, interactionPostProcessorId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/active`)
      .asPut()
      .withContent({ active: true })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public deactivatePostProcessor(organisationId: string, interactionPostProcessorId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/active`)
      .asPut()
      .withContent({ active: false })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }

  public setInteractionPostProsessorOutcomes(organisationId: string, interactionPostProcessorId: string, outcomes: string[]): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/outcomes`)
      .asPut()
      .withContent({ outcomes })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }

  public setInteractionPostProsessorWorktypes(organisationId: string, interactionPostProcessorId: string, workTypes: string[]): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/workTypes`)
      .asPut()
      .withContent({ workTypes })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public setInteractionPostProsessorDispositions(organisationId: string, interactionPostProcessorId: string, dispositions: any[]): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/dispositions`)
      .asPut()
      .withContent({ dispositions })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public setInteractionPostProsessorDelay(organisationId: string, interactionPostProcessorId: string, delay: number): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/delay`)
      .asPut()
      .withContent({ delay })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public addInteractionPostProsessorAction(organisationId: string, interactionPostProcessorId: string, action: any): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/actions`)
      .asPost()
      .withContent(action)
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public changeInteractionPostProsessorActionPriorities(organisationId: string, interactionPostProcessorId: string, actions: string[]): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/actions/priorities`)
      .asPut()
      .withContent({ actions })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public removeInteractionPostProsessorAction(organisationId: string, interactionPostProcessorId: string, actionName: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/actions/${actionName}`)
      .asDelete()
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public deleteInteractionPostProsessorDispositions(organisationId: string, interactionPostProcessorId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}`)
      .asDelete()
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
  
  public setInteractionPostProcessorFilterVariables(organisationId: string, interactionPostProcessorId: string, filterVariables: any[]): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/interaction-post-processors/${interactionPostProcessorId}/filter-variables`)
      .asPut()
      .withContent({ filterVariables })
      .send()
      .then(response => {
        Promise.resolve(response);
      })
  }
}
