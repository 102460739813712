// This component is unnecessary
// todo: (Phillip-Juan) use css: text-transform: capitalize;
export class PropercaseValueConverter {
  toView(str) {
    if (!str) {return}
    return str.replace(/\w\S*/g, txt => {
      if(txt.charAt(0).toUpperCase() === 'I' && txt.charAt(1).toUpperCase() === 'D' && !txt.charAt(2)) {
        return 'ID';
      }
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
