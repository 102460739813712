/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZValidationText');
/*
 */
@customElement('z-validation-text')
export class ZValidationText {

  @bindable error;

}
