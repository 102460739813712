import {autoinject} from 'aurelia-framework';

import {SessionStore, ApplicationProperties} from 'zailab.common';
import {ChatService} from '../../../features/organisation/conversation/channel/views/chat/chat-service';


@autoinject
export class Agent {

  private isEligibleForChat: boolean = false;

  constructor(private sessionStore: SessionStore, private applicationProperties: ApplicationProperties, private chatService: ChatService) {
    const orgId = this.sessionStore.get.organisation.organisationId;
    this.isEligibleForChat = this.applicationProperties.showExperimentalFeatures(orgId);

    if(this.isEligibleForChat) {
      this.chatService.initialiseChat();
    }
  }
}
