import { bindable, computedFrom, inject, LogManager } from 'aurelia-framework';

import { Event } from 'zailab.common';
// @ts-ignore
import toastr from 'toastr';

const logger = LogManager.getLogger('PostInteractionProcessingActions');

@inject(Element)
export class PostInteractionProcessingActions {

  @bindable private model: any;

  private apiCallModel = 'features/interaction/interactionFlow/interaction-designer/dialogs/api-call/api-call-dialog';

  public apiCallView: string;
  public apiModel: any;

  constructor(private element: Element) {}

  public activate(bindingContext: any): void {
    this.model = bindingContext;
    this.apiCallView = `${this.apiCallModel}`;
    this.model.data = {
      interactionPostProcessorId: bindingContext.data.interactionPostProcessorId || undefined,
      assignedActions: bindingContext.data.assignedActions || 
        (
          bindingContext.data.actions ? bindingContext.data.actions.map(ac => {
            // @ts-ignore
            ac.formData ? Object.entries(ac.formData) : undefined;

            let mappedOutputVariablesData = {};
            let keys = Object.keys(ac.outputVariables);
              keys.forEach(key => {
                mappedOutputVariablesData[key] = [mappedOutputVariablesData[key]]
              });
            // @ts-ignore
            // ac.outputVariables = mappedOutputVariablesData;
            ac.outputVariables ? Object.entries(ac.outputVariables) : undefined;
            // @ts-ignore
            ac.requestHeaders ? Object.entries(ac.requestHeaders) : undefined;

            return ac;
          }) : []
        ),
      temporaryAction: bindingContext.data.temporaryAction || {}
    };

    this.apiModel = {
      properties: { ...this.model.data.temporaryAction },
      closeCallback: (data: any) => this.addApiCall(data),
      isDialog: false
    }
  }

  private addApiCall(data: any): void {
    let item = this.model.data.assignedActions.find(aa => aa.name === data.name);
    if (item) {
      toastr.error(`An interaction processor with the name '${data.name}' already exists.`);
      return;
    }
    this.model.data.assignedActions.push(data);
    this.apiModel.properties = {};
    this.apiCallView = null;
    setTimeout(() => {
      this.apiCallView = `${this.apiCallModel}`;
    });
    delete this.model.data.temporaryAction;
    new Event(this.element, 'add-action', { action: data});
  }

  public removeAction(index: number): void {
    new Event(this.element, 'remove-action', { action: this.model.data.assignedActions[index] });
    this.model.data.assignedActions.splice(index, 1);
  }

  public deprioritiseAction(index: number): void {
      let newIndex = index+1;
      if (newIndex >= this.model.data.assignedActions.length) {
        return;
      }
    this.array_move(this.model.data.assignedActions, index, newIndex);
  }

  public prioritiseAction(index: number): void {
    let newIndex = index - 1;
    if (newIndex < 0) {
      return;
    }
    this.array_move(this.model.data.assignedActions, newIndex, index);
  }

  public mapOutputVariales(outputVariables: any): any[] {
    if (!outputVariables) {
      return [];
    }
    if (Array.isArray(outputVariables)) {
      let items = outputVariables.filter(item => item !== undefined);
      let newItems = [];

      [...items].forEach((value) => {
        // @ts-ignore
        newItems = newItems.concat(Object.entries(value));
      });
      return newItems
    }
    // @ts-ignore
    return Object.entries({ ...outputVariables });
  }

  private array_move(arr: Array<any>, old_index: number, new_index: number): void {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    new Event(this.element, 'change-action-priorities', { actions: this.model.data.assignedActions.map(a => a.name) });
  };

  public apiCallNameChanged(name: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      name
    };
  }

  public apiCallTimeoutChanged(timeout: number): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      timeout
    };
  }

  public apiCallMethodChanged(method: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      method
    };
  }

  public apiCallUrlChanged(url: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      url
    };
  }

  public apiCallBodyTextChanged(bodyText: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      bodyText
    };
  }

  public apiCallTabeInputHeadersChanged(requestHeaders: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      requestHeaders
    };
  }

  public apiCallIsFormDataChanged(isFormData: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      isFormData
    };
  }

  public apiCallTabeFormDataHeadersChanged(formData: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      formData
    };
  }

  public apiCallResponseTypeChanged(responseType: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      responseType
    };
  }

  public apiCallOutputVariablesChanged(outputVariables: string): void {
    this.model.data.temporaryAction = {
      ...this.model.data.temporaryAction,
      outputVariables
    };
  }

  @computedFrom('model')
  public get hasProcessor(): boolean {
    if (!this.model) {
      return
    }
    return typeof this.model.data.interactionPostProcessorId === 'string';
  }
}