import { autoinject, LogManager, customElement, bindable, computedFrom } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore, DateTimeTools } from 'zailab.common';
import { AbstractList } from 'zailab.abstract';
import { IReminder } from './reminder-model';
import { AgentModel } from '../../dashboard/teamleader/models/agent-model';
import { PlaceholderService } from '../../../_common/services/placeholder-service';
import { RemindersService } from './reminders-service';
import { MembersService } from '../member/members/members-service';
import { UserSessionModel } from '../../../_common/stores/sessionmodels/user-session-model';
// import { TeamsService } from '../../../hud/dashboard/qualityassessor/teams/teams.service';
import { SelectAgentDialog } from './selectAgent/select-agent-dialog';

import moment from 'moment';

const logger = LogManager.getLogger('Reminders');

@autoinject
export class Reminders extends AbstractList {
  private teams: any[] = [];
  private reminders: IReminder[] = [];
  private agents: AgentModel[] = [];
  private filteredReminders: IReminder[] = [];
  private loading: boolean = true;
  private order: string = 'agent.firstName'; // figure this out
  private selectedTeam: any;
  
  private placeholderService: PlaceholderService;
  private placeholders: number = 0;
  public container: Element;
  private showFilter: boolean = false;
  public enableSelectors: boolean = false;
  private lastRequestTimestamp: string;
  private signallerInterval: number;

  constructor(
    private reminderService: RemindersService,
    private membersService: MembersService,
    private sessionStore: SessionStore,
    private bindingSignaler: BindingSignaler,
    private dialogService: DialogService,
    private dateTimeTools: DateTimeTools,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
    this.loading = false;
  }
  
  public activate(): void {
    this.retrieveMember();
    setTimeout(() => {
      super.hideLoader();
    }, 10000)
  }

  private retrieveMember(): void {
    this.membersService
      .retrieveMember(this.user.memberId)
      .then((member) => {
        this.teams = member.teams;
        if (member.teams) {
          const team = member.teams[0];
          if (team) {
            this.selectedTeam = team;
            team.isSelected = true;
          }
          this.retrieveReminders();
        }
      })
  }

  private retrieveReminders(): void {
    if (!this.selectedTeam) {
      return;
    }
    this.enableSelectors = false;
    
    this.reminderService
      .retrieveReminders(this.selectedTeam.teamId)
      .then(reminders => this.remindersRetrieved(reminders));
  }

  private remindersRetrieved(reminders: IReminder[]): void {
    super.hideLoader();
    this.setupListConfig(reminders);
    this.reminders = reminders;
    this.enableSelectors = true;
    this.lastRequestTimestamp = new moment().add('seconds', 30);
    this.setupSignaller();
  }

  public formatDate(dueTimestamp: string): string {

    let convertedCreatedTimestamp = parseInt(dueTimestamp);

    let dateObj = this.dateTimeTools.convertToLocalTime(convertedCreatedTimestamp, 'UTC').dateObj;
    let formattedDate = dateObj.toLocaleString('en-us', {day: '2-digit', month: 'short', year: 'numeric'});

    return formattedDate;
  }

  public dueTime(dueTimestamp: string): string {
    return moment(parseInt(dueTimestamp, 10)).format('HH:mm');
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        this.retrieveReminders();
      }
    }, signal);
  }

  public openSearchFilter(): void {
    this.showFilter = !this.showFilter;
  }

  public selectTeam(event: any): void {
    this.selectedTeam = event;
    this.retrieveReminders();
  }

  public selectMember(reminderId: string): void {
    this.dialogService
      .open({
        viewModel: SelectAgentDialog,
        model: {
          teamId: this.selectedTeam.teamId,
          reminderId
        }
      })
      .whenClosed(response => {
        if (response.wasCancelled) {
          return;
        }

        let reminder = this.reminders.find(reminder => reminder.reminderId === reminderId);
        if (reminder) {
          reminder.member = response.output;
        }
      });
  }

  protected startsWithMatch(searchExpression: string, value: any, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    const contactName = `${value.contact.firstName} ${value.contact.surname}`.toLowerCase();
    const agentName = `${value.member.firstName} ${value.member.surname}`.toLowerCase();
    
    return contactName.startsWith(searchExpression.toLowerCase()) || agentName.startsWith(searchExpression.toLowerCase());
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }
}
