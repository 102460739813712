import { autoinject } from 'aurelia-dependency-injection';
import { Router } from "aurelia-router";

@autoinject
export class AutoLogoutDashboardWidget {
  

  constructor(
    private router: Router
  ) {}

  public view(): void {
    this.router.navigate('autologout');
  }
}