import {inject, LogManager} from 'aurelia-framework';
/*
 * */
const logger = LogManager.getLogger('ZFocusCustomAttribute');
/*
 * */
@inject(Element)
export class ZFocusCustomAttribute {

  focusTimeout;

  constructor(element) {
    this.element = element;
  }

  detached() {
    clearTimeout(this.focusTimeout);
  }

  valueChanged(focus) {
    if (focus) {
      this._focus();
    }
  }

  _focus() {
    logger.info('Calling _focus>>>');
    this.element.select();
    if (this.isActiveElement()) {
      return;
    }
    this.focusTimeout = setTimeout(() => {
      this._focus();
    }, 100);
  }

  isActiveElement() {
    return this.element === document.activeElement
  }
}