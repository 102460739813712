import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, computedFrom, customElement } from "aurelia-framework";
import { ContactService } from "features/organisation/contact/contact-service";

@customElement('z-interaction-card-additional-data')
@autoinject
export class InteractionCardAdditionalData {

  @bindable
  public interaction: any;

  @bindable
  public contact: any;

  @bindable
  public voiceAuth: boolean;

  public additionalDataVisible: boolean;

  constructor(
    private eventAggregator: EventAggregator,
    private contactService: ContactService,
  ) {}

  public showAdditionalData(): void {
    this.additionalDataVisible = true;
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.direction === 'OUTBOUND' ? this.interaction.to : this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId
    });
    this.eventAggregator.publish('dock-cards');
  }

  public triggerAdditionalDataButton(data: any): void {
    this.contactService.triggerRequest(data.value, data.requestMethod);
  }
}
