import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

import { SessionStore, WindowService, SESSION_EVENTS } from 'zailab.common';
import { DashboardModel } from './dashboard-model';
import { UserSessionModel } from '../../_common/stores/sessionmodels/user-session-model';
import { OrganisationSessionModel } from '../../_common/stores/sessionmodels/organisation-session-model';

const logger = LogManager.getLogger('Dashboard');

declare const PLATFORM: any;

@autoinject
export class Dashboard {
  private roleDashboardConfigs: RoleDashboardConfigs = {
    DEFAULT_USER: new DashboardModel(
      PLATFORM.moduleName('./defaultuser/default-user')
    ),
    Administrator: new DashboardModel(
      PLATFORM.moduleName('./administrator/administrator')
    ),
    Agent: new DashboardModel(PLATFORM.moduleName('./agent/agent')),
    'Team Leader': new DashboardModel(
      PLATFORM.moduleName(
        '../organisation/member/teamleader/team-leader-dashboard'
      )
    ),
    'Office Employee': new DashboardModel(
      PLATFORM.moduleName('./officeemployee/office-employee')
    ),
    QA: new DashboardModel(PLATFORM.moduleName('./qa/qa')),
    'QA Manager': new DashboardModel(PLATFORM.moduleName('./qa/qa')),
    'Campaign Manager': new DashboardModel(
      PLATFORM.moduleName('../campaigns/campaign/dashboard/campaign-dashboard')
    ),
  };
  private subscriptions: { [key: string]: Subscription } = {};
  private dashboards: DashboardModel[];

  constructor(
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private windowService: WindowService
  ) {}

  public activate(): void {
    this.loadDashboardsBasedOnRoles();
    this.subscribeToAccessChanges();
  }

  public attached(): void {
    this.windowService.enableUnload();
  }

  public loadDashboardsBasedOnRoles(sessionEvent?: string): void {
    if (!this.user || !this.user.userAccessRoles) {
      return;
    }
    const roles = this.user.userAccessRoles;
    if (!this.organisationId && roles.length > 1) {
      setTimeout(() => {
        this.loadDashboardsBasedOnRoles(sessionEvent);
      }, 100);
      return;
    }

    let list: DashboardModel[] = [];
    roles.forEach((item) => {
      const role: string = item.role;
      if (roles.length > 1 && role === 'DEFAULT_USER') {
        return;
      }
      list.push(this.roleDashboardConfigs[role]);
    });
    if (sessionEvent && !this.canEnableDashboard(list)) {
      return;
    }
    this.dashboards = list;
  }

  private canEnableDashboard(dashboards: Array<DashboardModel>): boolean {
    for (let dashboard of dashboards) {
      if (dashboard.viewModel.includes('defaultuser')) {
        return true;
      } else if (
        !dashboard.viewModel.includes('defaultuser') &&
        this.organisationId
      ) {
        return true;
      }
      return false;
    }
  }

  private subscribeToAccessChanges(): void {
    this.subscriptions[SESSION_EVENTS.UPDATED.USER] =
      this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.USER, () =>
        this.loadDashboardsBasedOnRoles()
      );
    this.subscriptions[SESSION_EVENTS.UPDATED.ORGANISATION] =
      this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.ORGANISATION, () =>
        this.loadDashboardsBasedOnRoles()
      );
  }

  public deactivate(): void {
    this.removeSubscriptions();
  }

  private removeSubscriptions(): void {
    for (let key in this.subscriptions) {
      this.subscriptions[key].dispose();
    }
  }

  @computedFrom('dashboards')
  public get dashboardList(): DashboardModel[] {
    return this.dashboards;
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if (!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }
}

interface RoleDashboardConfigs {
  [key: string]: DashboardModel;
}
