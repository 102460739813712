import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
/*
 * */
import { AssignNumberDialogService } from './assign-number-dialog-service';
import { AssignCallerIdDialogService } from '../assign-caller-id/assign-caller-id-dialog-service';
import TelephoneNumbersService from '../../../finance/telephonenumbers/telephone-numbers-service';
/*
 * */
const logger = LogManager.getLogger('AssignNumberDialog');
/*
 * */
@inject(DialogController, AssignNumberDialogService, AssignCallerIdDialogService, TelephoneNumbersService)
export class AssignNumberDialog {

  dialogHeader = 'Assign Numbers';
  liveDialogHeader = 'Assign Live Numbers';
  voiceNumbers = [];
  availableNumbers = [];
  interactionFlowId;
  organisationId;
  liveNumbers;
  numbers;

  constructor(dialogController, assignNumberDialogService, assignCallerIdDialogService, telephoneNumbersService) {
    this.dialogController = dialogController;
    this.assignNumberDialogService = assignNumberDialogService;
    this.assignCallerIdDialogService = assignCallerIdDialogService;
    this.telephoneNumbersService = telephoneNumbersService;
  }

  activate(model) {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.organisationId = model.organisationId;
    this.flowName = model.interactionFlow.flowName;
    this.flowChannel = model.interactionFlow.channel;
    this.numbers = model.interactionFlow.numbers;

    this._retrieveAvailableOrganisationNumbers(this.organisationId);

    if (model.liveNumbers === true) {
      this._setNumbersType(model.liveNumbers);
    }
    this._retrieveAssignedNumbers();
  }

  _setNumbersType(liveNumbers) {
    this.liveNumbers = liveNumbers;
    this.dialogHeader = this.liveDialogHeader;
  }

  _retrieveAssignedNumbers() {
    if (this.flowChannel.toLowerCase() === 'outbound call') {
      this.voiceNumbers = this.numbers.map((number) => {
        return {
          telephoneNumber: number,
          telephoneNumberId: number
        };
      });
    } else {
      const params = { live: this.liveNumbers };
      this.assignNumberDialogService._retrieveAssignedNumbers(this.interactionFlowId, params).then(
        (response) => {
          if (response.numbers) {
            this.voiceNumbers = response.numbers;
          }
        },
        (error) => {
          logger.info('Retrieving assigned numbers > error = ', error);
        });
    }
  }

  _retrieveAvailableOrganisationNumbers(organisationId) {
    if (this.flowChannel.toLowerCase() === 'outbound call') {
      this.telephoneNumbersService.retrieveOrganisationNumbers().then((response) => {
        this.availableNumbers = [];
        if (response && response.length) {
          this.availableNumbers = response.filter((availableNumber) => {
            return this.numbers.indexOf(availableNumber.telephoneNumber) === -1;
          });
        }
      });
    } else {
      this.assignNumberDialogService.retrieveAvailableOrganisationNumbers(organisationId, this.flowChannel).then(
        (response) => {
          this.availableNumbers = response || [];

        },
        (error) => {
          logger.info('Retrieving available numbers > error = ', error);
        });
    }
  }

  addVoiceNumberToInteractionFlow(index, number) {
    if (this.flowChannel.toLowerCase() === 'outbound call') {
      this.assignCallerIdDialogService.assignCallerIdToFlow(this.interactionFlowId, this.organisationId, number.telephoneNumber);
    } else {
      this.assignNumberDialogService.addNumberToInteractionFlow(this.interactionFlowId, this.organisationId, number.telephoneNumber, this.flowName, number.telephoneNumberId, this.liveNumbers);
    }
    this.voiceNumbers.push(number);
    this.availableNumbers.splice(index, 1);
  }

  removeVoiceNumberFromInteractionFlow(index, number) {
    if (this.flowChannel.toLowerCase() === 'outbound call') {
      this.assignCallerIdDialogService.unAssignCallerIdFromFlow(this.interactionFlowId, this.organisationId, number.telephoneNumber);
    } else {
      this.assignNumberDialogService.removeNumberFromInteractionFlow(this.interactionFlowId, this.organisationId, number.telephoneNumber, this.flowName, number.telephoneNumberId);
    }
    this.availableNumbers.push(number);
    this.voiceNumbers.splice(index, 1);
  }

  cancel() {
    this.dialogController.cancel();
  }

  done() {
    this.dialogController.ok();
  }

}
