import { autoinject } from 'aurelia-dependency-injection';
import { ApplicationProperties, SessionStore } from 'zailab.common';
import { AureliaConfiguration } from 'aurelia-configuration';

import { AnalyticsCategoryModel } from './analytics-category-model';
import { ANALYTICS_DB_CONFIG } from './analytics-dashboard-config';

import { BoldBI } from '@boldbi/boldbi-embedded-sdk';

import './analytics.scss';

@autoinject
export class Analytics {
  public analyticsCategories: AnalyticsCategoryModel[] = [];

  constructor(
    public applicationProperties: ApplicationProperties,
    private sessionStore: SessionStore,
    private aureliaConfiguration: AureliaConfiguration
  ) {
  }

  public attached(): void {
    const dev = this.aureliaConfiguration.is('local') || this.aureliaConfiguration.is('dev1');
    const env = dev ? 'dev1' : 'prod';

    this.analyticsCategories
      .push(
        new AnalyticsCategoryModel(
          'Waiting Room Summary Report',
          this.applicationProperties.boldBIServerEndpoint + '/bi/site/reports',
          ANALYTICS_DB_CONFIG[env].WRSummaryReport
        ),
        new AnalyticsCategoryModel(
          'Session Report',
          this.applicationProperties.boldBIServerEndpoint + '/bi/site/reports',
          ANALYTICS_DB_CONFIG[env].SessionReport
        )
      );
    this.selectReportCategory(this.analyticsCategories[0]);
  }

  public selectReportCategory(category: AnalyticsCategoryModel): void {
    this.analyticsCategories.forEach(category => {
      category.selected = false;
    });
    category.selected = true;
    this.renderBoldBiReport(category);
  }

  private renderBoldBiReport(category: AnalyticsCategoryModel): void {
    const reportConfig = {
      serverUrl: category.boldBIServerUrl,
      dashboardId: category.dashboardId,                
      embedContainerId: "bold-bi-wrapper",
      embedType: BoldBI.EmbedType.Component,
      mode: BoldBI.Mode.View,
      dashboardSettings: {
        themeSettings : {
          appearance :"dark", // todo: validate if we want to apply based on app theme 
        }
      },
      authorizationServer: {
        url: this.applicationProperties.apiQueryEndpoint + 'v1/boldbi/authorize',
        headers: {
          'Authorization': 'Bearer ' + this.sessionStore.get.user.token
        }
      },
    };

    var boldbiEmbedInstance = BoldBI.create(reportConfig);
    boldbiEmbedInstance.loadDashboard();
  }
}
