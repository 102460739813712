import { SearchTools } from 'zailab.common';
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { LiveDashboardState } from './live-dashboard-state';

import { LiveDashboardService } from '../live-dashboard-service';

const logger = LogManager.getLogger('LiveDashboardSettings');

@autoinject
export class LiveDashboardSettings {

  private liveDashboardState: LiveDashboardState = new LiveDashboardState();

  public originalColumns: any[] = [];
  public columns: any[] = [];
  public defaultSettings = {
    operator: '>=',
    value: '0',
    colour: null,
    enabled: false
  };
  public statConfigLimit: number = 5;
  public ready: boolean = false;
  private draggingItem: any;
  public hasError: boolean = false;
  public loading: boolean = false;

  public colourPickerSettings = { value: "#278787",   buttonText: {swatches: "colors"}}

  constructor(
    protected router: Router,
    private liveDashboardService: LiveDashboardService
  ) { }

  public activate(): void {
    this.init();
  }

  private async init(): Promise<void> {
    this.retrieveList();
  }

  protected async retrieveList(): Promise<void> {
    const colourConfig = await this.liveDashboardService.retrieveMyDashboardColourConfig('LIVE_DASHBOARD');
    const columns = await this.liveDashboardState.getMappings(colourConfig);
    
    this.columns = columns.map((setting) => {
      let item: any = {
        ...setting,
        ...setting.field
      };
      delete setting.field;

      item.isSelected = setting.visible;
      return item;
    });
    this.originalColumns = JSON.parse(JSON.stringify(this.columns));
    this.ready = true;
  }

  protected selectItems(items: any): void {
    items.item.isSelected = !items.item.isSelected;
  }

  public isInvalidPercentage(value: any): boolean {
    return !(value >= 0 && value <= 100);
  }

  public navigateToOverview(): void {
    if (this.hasError) {
      return;
    }
    this.router.navigateToRoute('livedashboardoverview');
  }

  public async save(): Promise<void> {
    this.loading = true;
    let payload = this.columns.map(col => {
      return {
        title: col.title,
        visible: col.visible,
        settings: col.settings.map(setting => {
          return {
            value: setting.value,
            colour: setting.colour
          };
        })
      };
    });

    try {
      // this.liveDashboardState.save();
      await this.liveDashboardService.updateMyDashboardColourConfig(payload, 'LIVE_DASHBOARD');
      this.navigateToOverview();
    } catch(e) {
      logger.warn('Failed to update live db config ', e);
    }
    this.loading = false;
  }

  public reset(): void {
    this.ready = false;
    this.init();
  }

  public cancel(): void {
    this.router.navigateToRoute('livedashboardoverview');
  }

  public addRow(tooltip: string): void {
    let item = this.columns.find(col => col.tooltip === tooltip);
    if (item.settings.length >= this.statConfigLimit) {
      return;
    }

    item.settings.push({
      ...this.defaultSettings
    });
    this.validateValue(item.settings, item.data.expandCollapseGroup, item.data.expandCollapseHeader, item.data.unitOfMeasurement);
  }

  public removeRow(tooltip: string, selectorIndex: number): void {
    let item = this.columns.find(col => col.tooltip === tooltip);
    item.settings.splice(selectorIndex, 1);
    this.validateValue(item.settings, item.data.expandCollapseGroup, item.data.expandCollapseHeader, item.data.unitOfMeasurement);
  }

  public validateValue(settings: any, expandCollapseGroup: string, expandCollapseHeader: boolean, unitOfMeasurement: string): void {
    this.clearErrors(settings, expandCollapseGroup);

    let values = {};
    settings.forEach((setting, index) => {
      if (!values[setting.value]) {
        values[setting.value] = [];
      }
      values[setting.value].push(index);
    });

    let keys = Object.keys(values);
    keys.forEach(key => {
      if (!key && key !== '0') {
        values[key].forEach(value => {
          settings[value].errorRequired = true;
          this.hasError = true;
          this.setErrorOnGroup(expandCollapseGroup, true);
        });
      } else if (values[key].length > 1) {
        values[key].forEach((value, currentIndex) => {
          if (currentIndex === values[key].length - 1) {
            settings[value].error = true;
          } else {
            settings[value].errorDuplicate = true;
          }
          this.hasError = true;
          this.setErrorOnGroup(expandCollapseGroup, true);
        });
      } else if (unitOfMeasurement === 'Percentage') {
        values[key].forEach((value) => {
          let numberValue: number = parseInt(key);
          if (numberValue < 0 || numberValue > 100) {
            settings[value].percentageError = true;
            this.hasError = true;
            if (expandCollapseGroup && !expandCollapseHeader) {
              this.setErrorOnGroup(expandCollapseGroup, true);
            }
          }
        });
      }
    });
  }

  private setErrorOnGroup(expandCollapseGroup: string, hasError: boolean): void {
    if (!expandCollapseGroup) {
      return;
    }
    this.columns.forEach(col => {
      if (col.data.expandCollapseGroup === expandCollapseGroup) {
        col.settings[0].hasError = hasError;
      }
    });
  }

  private clearErrors(settings: any, expandCollapseGroup: string): void {
    this.hasError = false;
    this.setErrorOnGroup(expandCollapseGroup, false);
    settings.forEach(setting => {
      setting.error = null;
      setting.errorDuplicate = false;
      setting.errorRequired = false;
      setting.percentageError = false;
    });
  }

  public toggleExpandCollapseGroup(column: any, columns: any[]): void {
    let group = column.data.expandCollapseGroup;
    column.data.isExpanded = !column.data.isExpanded;
    columns.forEach(col => {
      if (!col.data) {
        return;
      }
      if (col.data.expandCollapseGroup === group) {
        if (col.data.expandCollapseHeader) {
          col.data.isExpanded = column.data.isExpanded;
        }
        if (column.data.isExpanded) {
          col.data.expandClass = 'fade-in';
          col.data.isExpanded = true;
        } else {
          col.data.expandClass = 'fade-out';
          setTimeout(() => {
            col.data.isExpanded = false;
          }, 200);
        }
      }
    })
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string, searchParam2: string): boolean {
    try {
      let match1 = SearchTools.partialMatch(value[searchParam], searchExpression);
      if (match1) {
        return true;
      }
      let match2 = SearchTools.partialMatch(value[searchParam2], searchExpression);
      if (match2) {
        return true;
      }
    return false;
    } catch(e) {}
  }
  
  public handleDragStart(event: DragEvent): any {
    event.stopPropagation();
    let item: any = event.target;
    item.style.opacity = '0.3';
    
    this.draggingItem = item;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', item.innerHTML);
    return true;
  }

  public handleDragEnd(event: DragEvent): any {
    let dropItem: any = event.target;
    dropItem.style.opacity = '1';

    dropItem.parentElement.childNodes.forEach(item => {
      if (item.classList) {
        item.classList.remove('over');
      }
    });
    return false;
  }

  public handleDragOver(event: DragEvent): any {
    event.preventDefault();
  }

  public handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    let current = this.draggingItem;

    if (item && item.parentElement === current.parentElement) {
      if (item.className.indexOf(' over') === -1) {
        item.classList.add('over');
      }
    }
  }

  public handleDragLeave(event: DragEvent): any {
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    item.classList = item.className.replace(' over', '');
  }

  public handleDrop(event: DragEvent): any {

    let current = this.draggingItem;
    // @ts-ignore
    var path = event.composedPath ? event.composedPath() : event.path;
    let item: any = path.find(el => el.id === 'drag-row');
    let items = item.parentElement.querySelectorAll('.js-draggable');
    
    event.preventDefault();
    if (item !== current && item.parentElement === current.parentElement) {
      let currentpos = 0, droppedpos = 0;
      for (let it = 0; it < items.length; it++) {
        if (current === items[it]) { currentpos = it; }
        if (item === items[it]) { droppedpos = it; }
      }
      if (droppedpos === 0 || currentpos < droppedpos) {
        item.parentNode.insertBefore(current, item.nextSibling);
      } else {
        item.parentNode.insertBefore(current, item);
      }
    }
  }

  public isConfigurable(value: string): boolean {
    return ['Service', 'Worktype', 'Business Partner'].indexOf(value) === -1;
  }
}
