import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('Webhooks');

@autoinject()
export class ViewAuditDataDialog {

  public dialogHeader: string = 'Audit Data';
  public data: { key: string; value: string; }[] = [];

  constructor(private dialogController: DialogController) {}

  public activate(data: IInteractionFlowAuditData): void {
    if (data && typeof data === 'object') {
      this.data = this.convertToArray(data);
    }
  }

  private convertToArray(data: IInteractionFlowAuditData): { key: string; value: string; }[] {
    if (data && Array.isArray(data)) {
      return data;
    }
    let array = [];
    Object.keys(data).forEach(key => {
      array.push({
        variableName: key,
        displayName: this.isArrayOfStrings(data[key]) ? data[key] : ( 
          data[key] && typeof data[key] === 'object' ? (
            Object.keys(data[key]).length > 0 ? this.convertToArray(data[key]) : JSON.stringify(data[key])
          ) : `${data[key]}`
        )
      })
    });
    return array;
  }

  public confirm(): void {
    this.dialogController.ok();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public isObject(value: string | object): boolean {
    return value && typeof value === 'object' && Object.keys(value).length > 0;
  }

  public isArrayOfStrings(value: string | object): boolean {
    return value && Array.isArray(value) && (!value[0] || typeof value[0] === 'string');
  }
}
