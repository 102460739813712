import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('EmailSignatureModel');

export class EmailSignatureModel {

  constructor(
    public type: string,
    public signature: string
  ) { }

  get status(): string {
    return !this.signature ? 'Disabled' : 'Enabled';
  }
}
