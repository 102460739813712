import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { OplogService, WebSocket } from 'zailab.common';

const logger = LogManager.getLogger('AutoLogoutService');

@autoinject()
export class AutoLogoutService {
  constructor(
    private httpClient: HttpClient
  ) {}

  public retrieveAutoLoginConfigs(): Promise<any> {
    return this.httpClient
      .createRequest('/v1/organisation/autologoutrules')
      .asGet()
      .send();
  }

  public retrieveAutoLoginConfig(id: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/autologoutrules/${id}`)
      .asGet()
      .send();
  }
  
  public createAutoLogoutProfile(payload: any): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/autologoutrules/${payload.id}`)
      .asPost()
      .withContent(payload)
      .send();
  }
  
  public updateAutoLogoutProfile(payload: any): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/autologoutrules/${payload.id}`)
      .asPut()
      .withContent(payload)
      .send();
  }

  public removeAutoLogoutProfile(payload: any): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/autologoutrules/${payload.id}`)
      .asDelete()
      .withContent(payload)
      .send();
  }

}
