import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore } from 'zailab.common';
import { DashboardWidget } from '../../dashboard/dashboard-abstract';
import { SitesService } from '../sites-service';

const logger = LogManager.getLogger('Sites Widget');

@inject(Router, SessionStore, SitesService)
export class SitesDashboardWidget extends DashboardWidget {

  private oplog: any;

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }, {
    name: 'Add',
    event: 'add',
    icon: 'add'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private sessionStore: SessionStore,
    private sitesService: SitesService
  ) {
    super();
  }

  public attached(): void {
    this.sitesService.subscribeToOplogBasedOnCriteria({
      key: '_id',
      value: this.sessionStore.get.organisation.organisationId,
      location: 'organisation-projector.siteWidgetView'
    }).then(_oplog => {
      this.oplog = _oplog;
      this.oplog.on('insert', (response) => {
        this.count = response.sites.length;
      });
      this.oplog.on('update', (response) => {
        this.count = response.sites.length;
      });
    });
    this.retrieveSitesStats();
  }

  public detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  private retrieveSitesStats(): void {
    this.sitesService.retrieveSites().then((response) => {
      if (response) {
        this.count = response.length;
      }
      this.ready = true;
    }, (error) => {
      logger.info('Error >  unable to retrieve sites =', error);
    });
  }
}