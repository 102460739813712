/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZCssGrid');
/*
 */
@customElement('z-css-grid')
export class ZCssGrid {

}