import { inject, LogManager } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { ObserverLocator } from 'aurelia-binding';
/*
 */
import { SessionStore, BootstrapFormRenderer, MESSAGE_EVENTS } from 'zailab.common';
import { InteractionsService } from '../interaction-flow-service';
import { InteractionFlowModel } from '../interaction-flow-model';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
const logger = LogManager.getLogger('InteractionFlowProperties');
/*
 * */
@inject(ValidationControllerFactory, Router, ObserverLocator, SessionStore, InteractionsService, EventAggregator)
export class InteractionFlowProperties {

  formIsValid = false;
  input;
  subflow = false;
  itemCount = 6;
  flowTypes = [{
    name: 'Contact Centre'
  }, {
    name: 'Office'
  }];

  constructor(validationControllerFactory, router, observerLocator, sessionStore, interactionsService, eventAggregator) {

    this.router = router;
    this.observerLocator = observerLocator;
    this.sessionStore = sessionStore;
    this.interactionsService = interactionsService;
    this.eventAggregator = eventAggregator;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.initValidation();
    this.interactionFlow = new InteractionFlowModel();
  }

  activate() {
    this.ready = false;

    this.interactionsService.retrieveInteractionFlowSettings().then(
      (channels) => {

        channels = channels.filter((channel) => {
          return channel.channelName !== 'Website Call' && channel.channelName !== 'Kiosk Call'
        });

        let _channelsStart = [];
        let _channelsEnd = [];
        const positions = { email: 0, sms: 1, 'chat': 2, 'website call': 3, 'kiosk call': 4, 'instant message': 5, 'ticket': 6};

        for (let channel of channels) {
          let name = channel.channelName.toLowerCase();

          if (name.includes('inbound') || name.includes('outbound')) {
            _channelsStart.push(channel);
            continue;
          }
          channel.channelPosition = positions[name];
          _channelsEnd.push(channel);
        }

        _channelsEnd = _channelsEnd.sort(function (a, b) {
          return ~~(a['channelPosition'] > b['channelPosition']);
        });

        this.allChannels = _channelsStart.concat(_channelsEnd);
        this.channels = _channelsStart.concat(_channelsEnd);
        this._checkToEnableChannelsBasedOnAccountStatus();
        this.ready = true;
      })
      .catch(() => {
        this.ready = true;
      });
  }

  _checkToEnableChannelsBasedOnAccountStatus() {
    let disableTheseChannels = ['website', 'kiosk'];
    const account = this.sessionStore.get.account;
    if (account) {
      if (account.status.toLowerCase().includes('trial')) {
        disableTheseChannels = ['website', 'kiosk', 'chat', 'sms', 'outbound call', 'ticket'];
      }
    } else {
      setTimeout(() => {
        this._checkToEnableChannelsBasedOnAccountStatus();
      }, 50);
    }

    if (this.channels) {
      this.channels.forEach(channel => {
        this._ifAccountStatusAvailableDisableCertainChannels(channel, disableTheseChannels);
      });
    }
  }

  _ifAccountStatusAvailableDisableCertainChannels(channel, disableSelectChannels) {
    disableSelectChannels.forEach(channelType => {
      if (channel.channelName.toLowerCase().includes(channelType)) {
        channel.disabled = true;
      }
    });
  }

  attached() {

    this.focus(this.input);

    this.subscription = this.observerLocator
      .getObserver(this, 'flowName')
      .subscribe(() => {
        this.isValidInteraction();
      });
  }

  deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  focus(el) {

    let element = $(el);
    element.focus();

    if (!element.is(':focus')) {

      setTimeout(() => {
        this.focus(el);
      }, 5);
    }
  }

  initValidation() {

    this.interactionsService.retrieveOrganisationInteractionFlows(this.sessionStore.get.organisation.organisationId).then(
      (interactionsList) => {

        ValidationRules
          .customRule('uniqueName', (value) => {
            if (value) {
              value = value.trim();
            }
            for (let interaction of interactionsList) {
              if (interaction.flowName.toLowerCase() === value.toLowerCase()) {
                return false;
              }
            }
            return true;
          });

        ValidationRules
          .ensure('flowName')
          .required().withMessage('Please enter a work flow name.')
          .satisfiesRule('uniqueName').withMessage('Please enter a unique work flow name.')
          .matches(/^[a-zA-Z0-9_ -]*$/).withMessage('Must be a valid work flow name.')
          .on(this);
      },
      (error) => {
        logger.info('Error > unable to retrieve interaction flows =', error);
      });
  }

  setInteractionFlowType(interactionFlowType) {

    for (var channel of this.channels) {
      channel.selected = false;
    }

    this.interactionFlow.flowType = interactionFlowType;
    this.interactionFlow.channel = null;
    this.formIsValid = false;
  }

  selectItem(channel) {

    for (let item of this.channels) {
      item.selected = false;
      if (item === 'CHAT') {
        this.itemCount = 7;
      }
      item.isPlaceholder = false;
    }

    if (channel.isPlaceholder) {
      return;
    }


    channel.selected = true;
    this.interactionFlow.channel = channel.channelName;
    this.isValidInteraction();
  }

  toggleSubflow(){
    if(this.subflow){
      this.subflow = false;
    } else {
      this.subflow = true;
    }
  }

  routeToInteractionList() {

    this.router.navigate('/hud/interactionflows');
  }

  isValidInteraction() {

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      if (!this.interactionFlow.flowType || !this.interactionFlow.channel) {
        this.formIsValid = false;
        return;
      }
      this.formIsValid = true;
    },
      error => {
        this.formIsValid = false;
      });
  }

  finish() {

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }

      this.ready = false;

      this.interactionFlow.flowName = this.flowName.trim();
      
      this.interactionFlow.isSubflow = this.interactionFlow.flowType == 'Contact Centre' && this.interactionFlow.channel == 'Inbound Call' && this.subflow;
      this.interactionsService
        .createInteractionFlow(this.interactionFlow, this.sessionStore.get.organisation.organisationId)
        .then(flowId => this._interactionFlowCreated(flowId))
        .catch(err => this._reportError(err));
    });
  }

  _interactionFlowCreated(flowId) {

    this.router.navigate('/hud/interactionflows');
  }

  _reportError(response) {
    const formattedResponse = JSON.parse(response.response);
    this.ready = false;
    let errorMessage = `<b>${formattedResponse.message}</b><br/>${formattedResponse.details[0]}`;
    this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, errorMessage);
  }


  typeMatch(searchExpression, value) {

    if (!searchExpression) {
      return false;
    }

    for (let type of value.types) {

      if (type.toLowerCase() === searchExpression.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

}

