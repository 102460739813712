import {LogManager} from "aurelia-framework";
import {BaseModel} from "../../../../zailab.abstract";

const logger = LogManager.getLogger('LdapSyncModel');

export class LdapSyncModel extends BaseModel {
  public organisationId: string;
  public url: string;
  public username: string;
  public password: string;
  public enabled: boolean;
  public token: string;

  constructor(ldapSync?: any) {
    super();
    this.mapProperties(ldapSync);
  }
}