import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('Clone');

export class Clone {

  /**
   * Create a Clone of a list.
   * @param list {Array}
   * @param displayId {String}
   * @returns {Array}
   */
  static list(list, displayId) {

    let _items = [];

    for(let item of list) {
      if(item[displayId]) {
        _items.push(Clone.object(item));
      }
    }
    return _items;
  }

  /**
   * Create a Clone of an object.
   * @param item {Object}
   */
  static object(item) {
    return Object.assign({}, item);
  }
}


