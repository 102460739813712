import { LogManager, customElement, bindable } from 'aurelia-framework';
import { ZSelectInputOption } from '../../../_common/models/input-model';

const logger = LogManager.getLogger('ZSelectInput');

@customElement('z-select-input')
export class ZSelectInput {

  @bindable() options: ZSelectInputOption<any>[];
  @bindable() selected: ZSelectInputOption<any>;
  @bindable({ attribute: 'is-edit' }) isEdit: boolean;

  public get value(): string {
    return this.selected.text;
  }

  public set value(value: string) {
    this.selected = this.findOptionByText(value);
  }

  findOptionByText(text: string): ZSelectInputOption<any> {
    return this.options.filter((option) => option.text === text)[0];
  }
}