import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('FilterByCustomPropertiesValueConverter');

import {IDispositionFilterProperty} from "../../typings/zai/zai.common";

export class FilterByCustomPropertiesValueConverter {

  public toView(items: any, properties: IDispositionFilterProperty): any {
    if (!items || !properties) {
      return items;
    }

    let list: Array<any> = [];
    let itemMatched;

    itemsLoop: for(let item of items) {

      itemMatched = true;
      
      propertiesLoop: for(let key in properties) {
        if(properties[key] || properties[key] === false || properties[key] === 0) {
          if(typeof properties[key] === 'string' && item[key] && properties[key] && !item[key].toLowerCase().includes(properties[key].toLowerCase())) {
            itemMatched = false;
          } else if(typeof properties[key] === 'boolean' && properties[key] !== item[key]) {
            itemMatched = false;
          } else if(typeof properties[key] === 'object' && typeof item[key] === 'object') {
            
            objectLoop: for(let prop in properties[key]) {
              if(!properties[key][prop] || !item[key][prop]) {
                continue propertiesLoop;
              } else {
                if(properties[key][prop].toLowerCase() === item[key][prop].toLowerCase()) {
                  continue propertiesLoop;
                } else {
                  itemMatched = false;
                }
              }
            }
          }
        }
      }
      if(itemMatched) {
        list.push(item);
      }
    }
    return list;
  }
}
