const EVENT_PREFIX: string = 'STATE:UPDATE:';
const EVENT_UPDATED_PREFIX: string = 'STATE:UPDATED:';
const SESSION_EVENTS = {
  APPLICATION: {
    NAME: 'APPLICATION',
    EVENT: EVENT_PREFIX + 'APPLICATION'
  }, ACCOUNT: {
    NAME: 'ACCOUNT',
    EVENT: EVENT_PREFIX + 'ACCOUNT'
  }, ORGANISATION: {
    NAME: 'ORGANISATION',
    EVENT: EVENT_PREFIX + 'ORGANISATION'
  }, USER: {
    NAME: 'USER',
    EVENT: EVENT_PREFIX + 'USER'
  }, WEATHER: {
    NAME: 'WEATHER',
    EVENT: EVENT_PREFIX + 'WEATHER'
  },
  RESET: {
    NAME: 'RESET',
    EVENT: EVENT_PREFIX + 'RESET'
  },
  UPDATED: {
    ACCOUNT: EVENT_UPDATED_PREFIX + 'ACCOUNT',
    ORGANISATION: EVENT_UPDATED_PREFIX + 'ORGANISATION',
    USER: EVENT_UPDATED_PREFIX + 'USER',
  }
};

const SESSION_KEYS = { // think about current users
  CONVERSATIONS: 'zai_conversations', // this is the sessionStorage entity
  [SESSION_EVENTS.APPLICATION.NAME]: 'zai_app',
  [SESSION_EVENTS.ACCOUNT.NAME]: 'zai_acc',
  [SESSION_EVENTS.ORGANISATION.NAME]: 'zai_organisation',
  [SESSION_EVENTS.WEATHER.NAME]: 'zai_temperature',
  [SESSION_EVENTS.USER.NAME]: 'zai_user'
};

export { SESSION_EVENTS, SESSION_KEYS }
/*
session example layout
----------------------
zai_conversations = {
  zai_acc: 'account data',
  zai_organisation: 'organisation data',
  zai_task: 'task data',
  zai_temperature: 'temperature data',
  zai_user: 'user data'
};
*/
