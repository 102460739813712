import { LogManager, customElement, bindable } from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';
/*
 */
const logger = LogManager.getLogger('ZTextInput');
/*
 */
@customElement('z-text-input')
export class ZTextInput {

  @bindable focuson;
  @bindable inputvalue;
  @bindable placeholder;
  @bindable isDisabled;
  @bindable uniqueidentifier;
  @bindable icon;
  @bindable color;
  @bindable type;
  @bindable({ attribute: 'time-step' }) timeStep;
  @bindable longvalidation;
  @bindable hasnovalidation;
  @bindable className;
  @bindable change;
  @bindable readonly;

  attached() {
    if (!this.uniqueidentifier) {
      this.uniqueidentifier = uuidv4();
    }
    if (this.isDisabled) {
      const inputElement = document.getElementById(this.uniqueidentifier);
      inputElement.disabled = true;
    }
  }

  isDisabledChanged() {
    if (!this.uniqueidentifier) {
      this.uniqueidentifier = uuidv4();
    }
    const inputElement = document.getElementById(this.uniqueidentifier);
    if (!inputElement) {
      return;
    }
    if (this.isDisabled) {
      inputElement.disabled = true;
    } else {
      inputElement.disabled = false;
    }
  }

  get hasHighlight() {

    if (this.color) {
      return true;
    }
  }

  get hasIcon() {

    if (this.icon) {
      return true;
    }
  }

  onChange(event) {
    this.change && this.change(event);
  }
}
