import { inject, LogManager } from 'aurelia-framework';
import { HttpClient, HttpResponseMessage, RequestBuilder } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';

import { Oplog, SessionStore, MESSAGE_EVENTS } from 'zailab.common';

import { LowCreditNotificationDetails } from './low-credit-notification-model';

const logger = LogManager.getLogger('LowCreditNotificationService');

@inject(HttpClient, Oplog, SessionStore, EventAggregator)
export class LowCreditNotificationService {

  private httpClient: HttpClient;
  private oplog: Oplog;
  private sessionStore: SessionStore;
  private eventAggregator: EventAggregator;
  private oplogSubscription: any;
  private eventSubscription: any;
  private timeoutSubscription: any;

  constructor(httpClient: HttpClient, oplog: Oplog, sessionStore: SessionStore, eventAggregator: EventAggregator) {
    this.httpClient = httpClient;
    this.oplog = oplog;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
  }

  public findLowCreditNotification(): Promise<LowCreditNotificationDetails> {
    return this.createRequest().asGet().send()
      .then((response: LowCreditNotificationResponse) => this.mapResponseAsLowCreditNotificationDetails(response));
  }

  public createLowCreditNotificationDetails(data: LowCreditNotificationDetails): Promise<HttpResponseMessage> {
    return new Promise((resolve, reject) => {
      this.handlePromise(data, resolve, reject);
      this.createRequest(data).asPost().send();
    });
  }

  public updateLowCreditNotificationDetails(data: LowCreditNotificationDetails): Promise<any> {
    return new Promise((resolve, reject) => {
      this.handlePromise(data, resolve, reject);
      this.createRequest(data).asPut().send();
    });
  }

  public enableLowCreditNotificationDetails(data: LowCreditNotificationDetails): Promise<HttpResponseMessage> {
    return new Promise((resolve, reject) => {
      this.handlePromise(data, resolve, reject);
      this.createRequest(data, 'enable').asPut().send();
    });
  }

  public disableLowCreditNotificationDetails(data: LowCreditNotificationDetails): Promise<HttpResponseMessage> {
    return new Promise((resolve, reject) => {
      this.handlePromise(data, resolve, reject);
      this.createRequest(data, 'disable').asPut().send();
    });
  }

  public close(): void {
    this.oplogSubscription && this.oplogSubscription.unsubscribe();
    this.eventSubscription && this.eventSubscription.dispose();
    this.timeoutSubscription && clearTimeout(this.timeoutSubscription);
  }

  private mapResponseAsLowCreditNotificationDetails(response: LowCreditNotificationResponse): LowCreditNotificationDetails {
    const data: LowCreditNotificationDetails = new LowCreditNotificationDetails();
    data.account = this.sessionStore.get.account;
    if (response) {
      data.emails = response.emails;
      data.threshold = response.threshold + '';
      data.enabled = response.enabled;
    }
    return data;
  }

  private onLowCreditNotificationViewChange(accountId: string): Promise<any> {
    this.oplogSubscription = this.oplog
      .subscribe()
      .withKeyField('_id')
      .withKeyValue(accountId)
      .withNameSpace('account-projector.lowCreditNotificationDetailsView');
    return this.oplogSubscription.when('insert', 'update');
  }

  private handlePromise(data: LowCreditNotificationDetails, resolve: any, reject: any): void {
    this.timeoutSubscription = setTimeout(() => {
      this.close();
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'Your changes have timed out and may not have been saved');
      reject();
    }, 10000);
    this.onLowCreditNotificationViewChange(data.account.accountId).then(() => {
      this.close();
      this.handleLowCreditNotificationViewChange();
      resolve();
    });
    this.eventSubscription = this.eventAggregator.subscribeOnce('ValidationErrorsReceived', (data) => {
      this.close();
      if (data.state.entity === 'configureLowCreditNotification') {
        reject();
      } else {
        logger.info('ValidationErrorsReceived :: data=', data);
      }
    });
  }

  private handleLowCreditNotificationViewChange(): void {
    this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Your changes have been saved');
  }

  private createRequest(data?: LowCreditNotificationDetails, path?: string): RequestBuilder {
    const request: RequestBuilder = this.httpClient.createRequest(this.getBaseUri(!!data ? data.account.accountId : 'me', path));
    if (!path && data) {
      request.withContent(data.toPayload());
    }
    return request;
  }

  private getBaseUri(accountId: string, path: string): string {
    return `v1/finance/accounts/${accountId}/low-credit-notification${path ? `/${path}` : ''}`;
  }
}

class LowCreditNotificationResponse extends HttpResponseMessage {

  public threshold: number;
  public emails: string[];
  public enabled: boolean;
}
