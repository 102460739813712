import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import FileSaver from 'file-saver';
import { ApplicationProperties, DownloadService, OplogService, SessionStore } from 'zailab.common';

import ProspectListModel from './prospect-list-model';
import UploadRequestBody from './upload-request-body';
import UploadResultsModel from './uploadresultsdialog/upload-results-model';

const logger = LogManager.getLogger('ProspectListService');

@inject(HttpClient, SessionStore, ApplicationProperties, OplogService, DownloadService)
export default class {
  constructor(httpClient, sessionStore, applicationProperties, oplogService, downloadService) {
    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.applicationProperties = applicationProperties;
    this.oplog = oplogService;
    this.downloadService = downloadService;
  }

  retrieveCampaign(campaignId) {
    return new Promise((resolve, reject) => {
      this._retrieve(`v1/campaign/campaigns/${campaignId}/prospectlists`).then(response => {
        response.prospectLists = this.modelCollection(response.prospectLists || []);
        resolve(response);
      });
    });
  }

  initialiseOplogWith(campaignId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', campaignId).in('campaign-projector.campaignProspectListView'));
    });
  }

  uploadProspectList(data, file) {
    return new Promise((resolve, reject) => {
      let body = new UploadRequestBody();

      body.addDisposition({
        name: 'meta-data',
        contentType: 'application/json',
        content: JSON.stringify({
          name: data.name,
          externalReference: data.externalReference,
          campaignId: data.campaignId,
          taskTemplate: data.taskTemplate,
          startTimestamp: data.startTimestamp,
          endTimestamp: data.endTimestamp,
          timezone: data.timezone,
          headersIncluded: data.headersIncluded,
          businessPartnerId: data.businessPartnerId,
          contactCentreId: data.contactCentreId
        })
      });

      body.addDisposition({
        name: 'file',
        fileName: file.name,
        contentType: 'application/octet-stream',
        content: file.content
      });

      let host = this.applicationProperties.apiQueryEndpoint;
      let xhr = new XMLHttpRequest();
      xhr.open('POST', host + `v1/campaign/prospectlists/${data.prospectListId}`, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.sessionStore.get.user.token);
      xhr.setRequestHeader('Content-Type', 'multipart/mixed; boundary=' + body.boundary);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve();
          } else if (xhr.status === 200) {
            reject(xhr.response);
          } else if (xhr.status === 500) {
            reject('Failed to upload. The file exceeds 1000 lines.');
          }
        }
      };
      xhr.send(body.getContent());
    });
  }

  linkConversationToProspect(conversationId) {
    const task = JSON.parse(localStorage.getItem('contactTabsState' + this.sessionStore.get.user.memberId));
    let prospectListId = task.payload.metadata.prospectListId;
    let prospectId = task.payload.metadata.prospectId;

    this.httpClient
      .createRequest(`/v1/campaign/campaigns/prospectlist/${prospectListId}/prospect/${prospectId}/conversation/${conversationId}/link`)
      .asPut()
      .withContent({})
      .send()
      .then(
        response => { },
        error => {
          logger.warn(' linkConversationToProspect > error = ', error);
        }
      );
  }

  retrieveUploadResults(prospectListId) {
    return new Promise((resolve, reject) => {
      this._retrieve(`v1/campaign/campaigns/prospectlist/${prospectListId}/feedback`).then(
        response => {
          resolve(new UploadResultsModel(JSON.parse(JSON.stringify(response))));
        },
        error => {
          reject(error);
        }
      );
    });
  }

  downloadProspectListResults(prospectList) {
    const prospectListId = prospectList.prospectListId;
    const fileName = prospectList.name;

    return new Promise((resolve, reject) => {
      const uri = `/v1/campaign/campaigns/prospectlist/${prospectListId}/feedback`;
      let params = { fileName: fileName + '_results' };

      return new Promise((resolve, reject) => {
        this.httpClient
          .createRequest(uri)
          .asGet()
          .withParams(params)
          .withResponseType('blob')
          .withHeader('Accept', 'application/zip')
          .send()
          .then(
            response => {
              FileSaver.saveAs(response.content, `${fileName}_results.zip`);
              resolve(true);
            },
            error => {
              reject(error);
            }
          );
      });
    });
  }

  downloadProspectsReport(prospectList) {

    const prospectListId = prospectList.prospectListId;
    const fileName = prospectList.name;

    return new Promise((resolve, reject) => {
      let uri = `v1/campaign/campaigns/prospectlist/${prospectListId}/report`;
      let params = { fileName: fileName + '_Prospect-List-Report' };

      return new Promise((resolve, reject) => {
        this.httpClient
          .createRequest(uri)
          .asGet()
          .withParams(params)
          .withResponseType('blob')
          .withHeader('Accept', 'application/zip')
          .send()
          .then(
            response => {
              FileSaver.saveAs(response.content, `${fileName}_Prospect-List-Report.zip`);
              resolve(true);
            },
            error => {
              reject(error);
            }
          );
      });
    });
  }

  removeUnservedProspects(campaignId, prospectListId) {
    this.httpClient
      .createRequest(`/v1/campaign/campaigns/${campaignId}/prospectlists/${prospectListId}/prospects`)
      .asDelete()
      .send()
      .then(() => {
      })
      .catch(() => {
      });
  }

  modelCollection(response) {
    let list = [];
    response.forEach(item => {
      list.push(this.modelEntity(item));
    });
    return list;
  }

  modelEntity(item) {
    return new ProspectListModel(item);
  }

  _retrieve(url) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(url)
        .asGet()
        .withParams()
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }
}
