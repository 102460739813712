import { computedFrom, LogManager } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('WeatherSessionModel');

export class WeatherSessionModel extends BaseModel {

  public hours: string = null;
  public temp: string = null;
  public weatherCode: string = null;

  constructor(data: WeatherSessionModel) {
    super();
    this.mapEntity(data);
  }

  public mapEntity(data: WeatherSessionModel): void {
    super.mapProperties(data);
  }
}