import {LogManager, inject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 */
import Service from '../prospect-list-service';
import {MESSAGE_EVENTS} from 'zailab.common';
/*
 */
import { v4 as uuidv4 } from 'uuid';
/*
 */
const logger = LogManager.getLogger('Upload');
/*
 */
@inject(Service, Router, EventAggregator)
export default class {

  steps = [{
    name: 'Define Properties',
    description: 'Please define the properties of the prospect list below.',
    view: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/defineproperties/define-properties'),
    active: true,
    completed: false,
    data: {
      sftpUpload: false
    }
  }, {
    name: 'Upload File',
    description: 'Please upload a CSV file in the required format with all prospects having at least one valid phone number.',
    view: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/uploadfile/upload-file'),
    completed: false
  }];


  constructor(service, router, eventAggregator) {
    this.service = service;
    this.router = router;
    this.eventAggregator = eventAggregator;
  }

  activate(params, data, obj) {
    this.steps[0].data.campaignId = params.campaignId;
    this.steps[0].data.prospectListId = uuidv4();

    this.steps[0].data.businessPartnerId = obj.queryParams.businessPartnerId;
    this.steps[0].data.contactCentreId = obj.queryParams.contactCentreId;
    
    this.ready = true;
  }

  cancel() {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }

  complete(event) {

    this.ready = false;
    let file = event.detail.file;
    delete event.detail.file;
    let data = event.detail;


    this.service.uploadProspectList(data, file).then(() => {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Thank you! <br><br> Prospects are being processed into contacts and work items are being created.');
      this.router.navigateBack(); // TODO: replace with actual route to navigate to
    }, (error) => {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, error);
      this.ready = true;
    });
  }
}
