import { inject, LogManager } from 'aurelia-framework';
/*
 */
import { SessionStore } from 'zailab.common';
import { UsageService } from './usage-service';
import { AccountState } from '../account-state';
/*
 */
import moment from 'moment-timezone';
/*
 */
const logger = LogManager.getLogger('Usage');
/*
 */
@inject(UsageService, SessionStore, AccountState)
export class Usage {

  tableContent = [];
  tableHeaders = [{property: 'date', description: 'Date'},
    {property: 'startTime', description: 'Start Time'},
    {property: 'duration', description: 'Duration'},
    {property: 'category', description: 'Category'},
    {property: 'item', description: 'Item'},
    {property: 'direction', description: 'Direction'},
    {property: 'source', description: 'Source'},
    {property: 'destination', description: 'Destination'},
    {property: 'numberOfUnits', description: 'No. of Units'},
    {property: 'unit', description: 'Unit'},
    {property: 'rate', description: 'Rate', alignment: 'right'},
    {property: 'charge', tooltip: 'originalCharge', description: 'Charge', alignment: 'right'}];
  fromDate;
  toDate;
  errorMessage;
  isTableUsagesProcessing = false;
  isDownloading = false;
  isNotLastContentInTable = true;

  constructor(usageService, sessionStore, accountState) {
    this.usageService = usageService;
    this.sessionStore = sessionStore;
    this.accountState = accountState;
    this.setupUsageParameters();
  }

  setupUsageParameters() {
    if (this.accountState.lastUsageDateLoaded) {
      let previousUsageDates = this.accountState.lastUsageDateLoaded.split(' / ');
      this.fromDate = previousUsageDates[0];
      this.toDate = previousUsageDates[1];

    } else {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let firstDayOfTheMonth = new Date(year, month, 1);
      this.fromDate = moment(firstDayOfTheMonth).format('YYYY-MM-DD');
      this.toDate = moment().format('YYYY-MM-DD');
    }
  }

  attached() {
    this.retrieveUsageData();
  }

  retrieveUsageData() {
    this.setToDate = moment(this.toDate).format('DDMMYYYY');
    this.setFromDate = moment(this.fromDate).format('DDMMYYYY');
    this.saveUsageDatesToAccount(this.fromDate, this.toDate);
    this.usagePageIndex = -1;
    this.usageResultAmount = 50;
    if (this.tableContent.length > 0) {
      this.tableContent = [];
    }

    setTimeout(() => {
      let dateValidation = this._checkIfDateIsValid();
      if (dateValidation === true) {
        this.errorMessage = '';
        this.isTableUsagesProcessing = true;

        this.getTableContent().then(() => {
          this.isTableUsagesProcessing = false;

        });

      } else {
        this.errorMessage = dateValidation;
      }
    }, 100);
  }

  getTableContent() {
    return new Promise((resolve) => {
      this.usagePageIndex++;
      if (this.isNotLastContentInTable || this.isTableUsagesProcessing) {
        this.usageService.retrieveUsage(this.setFromDate, this.setToDate, this.usagePageIndex, this.usageResultAmount, this.sessionStore.get.account.currencySymbol).then(
          (response) => {
            this.isNotLastContentInTable = true;
            this.getAdditionalData(response);
            resolve(response);
          },
          (error) => {
            this.errorMessage = '' + error;
            logger.info('Usage > error = ', error);
          });
      }
    });
  }

  getAdditionalData(response) {
    let formatResults = response;
    if (formatResults != undefined) {
      if (formatResults.length < this.usageResultAmount) {
        this.isNotLastContentInTable = false;
      }
      formatResults.forEach(result => {
        this.tableContent.push(result);
      });
    } else {
      this.isNotLastContentInTable = false;
    }
  }

  download() {
    this.isDownloading = true;
    let toDate = moment(this.toDate).format('DDMMYYYY');
    let fromDate = moment(this.fromDate).format('DDMMYYYY');
    this.usageService.downloadUsageReport(toDate, fromDate).then(
      (success) => {
        this.isDownloading = false;
      },
      (error) => {
        this.isDownloading = false;
      });
  }

  _checkIfDateIsValid() {
    let toDate = moment(this.toDate);
    let fromDate = moment(this.fromDate);
    let dateDifference = toDate.diff(fromDate, 'days');

    if (dateDifference < 0) {
      return 'To Date cannot be before From Date';
    }

    if (dateDifference >= 31) {
      return 'The search period cannot be more than 31 days';
    }

    return true;
  }

  saveUsageDatesToAccount(fromDate, toDate) {
    this.accountState.lastUsageDateLoaded = fromDate + ' / ' + toDate;
  }
}
