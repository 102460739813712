import { ReportConfig } from "./report-config";
import {LogManager} from "aurelia-framework";

const logger = LogManager.getLogger('ReportConfigBuilder');

export class ReportConfigBuilder {
  private reportConfig: ReportConfig;

  constructor() {
    this.reset();
  }

  public withReportType(type: string): ReportConfigBuilder {
    this.reportConfig.reportType = type;
    return this;
  }

  public withDatePeriod(datePeriod: string): ReportConfigBuilder {
    this.reportConfig.datePeriod = datePeriod;
    return this;
  }

  public withSelector(selector: string): ReportConfigBuilder {
    this.reportConfig.selector = selector;
    return this;
  }

  public withIcon(icon: string): ReportConfigBuilder {
    this.reportConfig.icon = icon;
    return this;
  }

  public withNewReport(newReport: boolean): ReportConfigBuilder {
    this.reportConfig.newReport = newReport;
    return this;
  }

  public withCategory(category: string): ReportConfigBuilder {
    this.reportConfig.category = category;
    return this;
  }

  public withReportName(reportName: string): ReportConfigBuilder {
    this.reportConfig.reportName = reportName;
    return this;
  }

  public withReportDescription(reportDescription: string): ReportConfigBuilder {
    this.reportConfig.reportDescription = reportDescription;
    return this;
  }

  public withLabel(label: string): ReportConfigBuilder {
    this.reportConfig.label = label;
    return this;
  }

  public showServices(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showServices = enabled;
    return this;
  }

  public showRoles(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showRoles = enabled;
    return this;
  }

  public showTimeBuckets(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showTimeBuckets = enabled;
    return this;
  }

  public showBusinessPartners(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showBusinessPartners = enabled;
    return this;
  }

  public showTeams(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showTeams = enabled;
    return this;
  }

  public showMembers(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showMembers = enabled;
    return this;
  }

  public showTimeZone(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showTimezone = enabled;
    return this;
  }

  public showDate(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.showDate = enabled;
    return this;
  }

  public withChannelParam(channel: string): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.withChannel = channel;
    return this;
  }

  public withOnlySpecificService(enabled: boolean): ReportConfigBuilder {
    this.reportConfig.inputFormConfig.requiredService = enabled;
    return this;
  }

  public build(): ReportConfig {
    return this.reportConfig;
  }

  public reset(): void {
    this.reportConfig = new ReportConfig();
  }


}