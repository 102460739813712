import { LogManager } from 'aurelia-framework';
import { InteractionNodes } from './interaction-config-models';

declare const PLATFORM;

const logger = LogManager.getLogger('InteractionConfigStrategy');
export const ON_SMS = 'onSms';
export const ON_CALL = 'onCall';
export const ON_EMAIL = 'onEmail';
export const ON_TICKET = 'onTicket';
export const ON_CHAT = 'onChat';
export const PLAY_MESSAGE = 'playMessage';
export const CRITERIA = 'criteria';
export const CRITERIA_PABX = 'criteriaPabx';
export const CALL_FORWARD = 'callForward';
export const SURVEY = 'survey';
export const DECISION = 'decision';
export const PLAY_TEXT_MESSAGE = 'playTextMessage';
export const START_RECORDING = 'startCallRecording';
export const STOP_RECORDING = 'stopCallRecording';
export const PUBLIC_HOLIDAY = 'publicHolidays';
export const OFFICE_HOURS = 'officeHours';
export const CUSTOM_TIME_ROUTES = 'customTimeRoutes';
export const SELECTION_MENU = 'selectionMenu';
export const PATTERN_MENU = 'patternMenu';
export const SCREEN_CALL = 'screenCall';
export const MAILBOX = 'mailbox';
export const CALLBACK = 'callback';
export const WRAP_UP = 'wrapUp';
export const EMERGENCY_ANOUNCEMENT = 'emergencyAnnouncement';
export const LOAD_BALANCER = 'loadBalancer';
export const WAITING_STRATEGY = 'queueingStrategy';
export const WAITING_STRATEGY_PABX = 'queueingStrategyPabx';
export const END_CALL = 'endCall';
export const API_CALL = 'apiCall';
export const INPUT_DIALOG = 'inputDialog';
export const SUBFLOW = 'subflow';
export const SET_VARIABLES = 'setVariables';
export const RECORD_VOICE_CLIP = 'recordVoiceClip';
export const SEND_SMS = 'sendSMS';
export const SEND_EMAIL = 'sendEmail';
export const ASSISTANT = 'assistant';
/*
 * Available properties for each eventId:
 * -------------------------------------
 * identifier = eventId
 * [disableInConnector: boolean] (disables incoming connector)
 * [disableOutConnector: boolean] (disables outgoing connector)
 * [image: '', dialogTemplate: string] (defines a dialog template for editing node properties)
 * [defaultExtensions: array] species the next available steps/nodes
 * [alternativeExtensions: array] species the alternative next available steps/nodes (normally used for exit destinations)
 * [name: string] default name displayed on the node
 * [description: string] node description for info button
 * [defaultProperties: object] default node properties
 * [isDefined: boolean] specifies whether or not the node is defined by default
 * [type: string] node event type
 * [inputConnectors: array] needed on the backend to match input connectors
 * [outputConnectors: array] needed on the backend to match output connectors
 */

export class InteractionConfigStrategy {
  static inboundCallFlow: object = {
    defaultStartNode: 'incomingCall',
    incomingCall: InteractionNodes.useStart().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      SURVEY,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    criteria: InteractionNodes.useCriteria().extendsTo([
      WAITING_STRATEGY,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SURVEY,
      RECORD_VOICE_CLIP,
      API_CALL,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    recordVoiceClip: InteractionNodes.useRecordVoiceClip().extendsTo([
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    apiCall: InteractionNodes.useApiCall().extendsTo([
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    inputDialog: InteractionNodes.useInputDialog().extendsTo([
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      END_CALL,
      SURVEY,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      ON_CALL,
      STOP_RECORDING,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    queueingStrategy: InteractionNodes.useQueueingStrategy().extendsTo([
      START_RECORDING,
      ON_CALL,
      SURVEY,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      CALL_FORWARD,
      DECISION,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    officeHours: InteractionNodes.useOfficeHours().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    publicHolidays: InteractionNodes.usePublicHolidays().extendsTo([
      CRITERIA,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    customTimeRoutes: InteractionNodes.useCustomTimeRoutes().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    playMessage: InteractionNodes.usePlayMessage().extendsTo([
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    emergencyAnnouncement:
      InteractionNodes.useEmergencyAnnouncement().extendsTo([
        PUBLIC_HOLIDAY,
        OFFICE_HOURS,
        CUSTOM_TIME_ROUTES,
        SELECTION_MENU,
        PATTERN_MENU,
        SCREEN_CALL,
        CRITERIA,
        PLAY_TEXT_MESSAGE,
        WAITING_STRATEGY,
        END_CALL,
        SURVEY,
        CALL_FORWARD,
        DECISION,
        RECORD_VOICE_CLIP,
        API_CALL,
        INPUT_DIALOG,
        SUBFLOW,
        SET_VARIABLES,
      ]),
    loadBalancer: InteractionNodes.useLoadBalancer().extendsTo([
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    selectionMenu: InteractionNodes.useSelectionMenu().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      PATTERN_MENU,
      RECORD_VOICE_CLIP,
      API_CALL,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    patternMenu: InteractionNodes.usePatternMenu().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      PATTERN_MENU,
      SELECTION_MENU,
      RECORD_VOICE_CLIP,
      API_CALL,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    onCall: InteractionNodes.useOnInteraction().extendsTo([
      END_CALL,
      STOP_RECORDING,
      SURVEY,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SELECTION_MENU,
      PATTERN_MENU,
      CALL_FORWARD,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    screenCall: InteractionNodes.useScreenCall().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      SUBFLOW,
      END_CALL,
      SET_VARIABLES,
    ]),
    subflow: InteractionNodes.useSubFlow().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    playTextMessage: InteractionNodes.usePlayTextMessage().extendsTo([
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    startCallRecording: InteractionNodes.useStartCallRecording().extendsTo([
      ON_CALL,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SURVEY,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    stopCallRecording: InteractionNodes.useStopCallRecording().extendsTo([
      END_CALL,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    mailbox: InteractionNodes.useMailbox(),
    survey: InteractionNodes.useSurvey().extendsTo([
      END_CALL,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      START_RECORDING,
      ON_CALL,
      STOP_RECORDING,
      MAILBOX,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
      SET_VARIABLES,
    ]),
    callForward: InteractionNodes.useCallForward().extendsTo([
      END_CALL,
      SET_VARIABLES,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      END_CALL,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      START_RECORDING,
      ON_CALL,
      STOP_RECORDING,
      MAILBOX,
      CALL_FORWARD,
      DECISION,
      RECORD_VOICE_CLIP,
      API_CALL,
      INPUT_DIALOG,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SUBFLOW,
    ]),
    callback: InteractionNodes.useCallback(),
    endCall: InteractionNodes.useEnd(),
  };

  static outboundCallFlow: object = {
    defaultStartNode: 'outgoingCall',
    outgoingCall: InteractionNodes.useStart().extendsTo([
      CRITERIA,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    apiCall: InteractionNodes.useApiCall().extendsTo([
      CRITERIA,
      END_CALL,
      SURVEY,
      DECISION,
      API_CALL,
      INPUT_DIALOG,
      ON_CALL,
      START_RECORDING,
      STOP_RECORDING,
      SET_VARIABLES,
    ]),
    inputDialog: InteractionNodes.useInputDialog().extendsTo([
      CRITERIA,
      END_CALL,
      SURVEY,
      DECISION,
      API_CALL,
      INPUT_DIALOG,
      ON_CALL,
      START_RECORDING,
      STOP_RECORDING,
      SET_VARIABLES,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      START_RECORDING,
      ON_CALL,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    criteria: InteractionNodes.useCriteria().extendsTo([
      START_RECORDING,
      ON_CALL,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    onCall: InteractionNodes.useOnInteraction().extendsTo([
      END_CALL,
      STOP_RECORDING,
      SURVEY,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    startCallRecording: InteractionNodes.useStartCallRecording().extendsTo([
      ON_CALL,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    stopCallRecording: InteractionNodes.useStopCallRecording().extendsTo([
      END_CALL,
      SURVEY,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
    survey: InteractionNodes.useSurvey().extendsTo([
      END_CALL,
      INPUT_DIALOG,
      DECISION,
      API_CALL,
      SET_VARIABLES,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      CRITERIA,
      END_CALL,
      SURVEY,
      DECISION,
      API_CALL,
      INPUT_DIALOG,
      ON_CALL,
      START_RECORDING,
      STOP_RECORDING,
    ]),
  };

  static inboundOfficeFlow: object = {
    defaultStartNode: 'incomingCall',
    criteriaPabx: InteractionNodes.useCriteria()
      .extendsTo([
        WAITING_STRATEGY_PABX,
        PLAY_MESSAGE,
        PLAY_TEXT_MESSAGE,
        SET_VARIABLES,
      ])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/work-type/work-type-dialog'
        )
      ),
    queueingStrategyPabx: InteractionNodes.useQueueingStrategy()
      .extendsTo([
        START_RECORDING,
        ON_CALL,
        PLAY_MESSAGE,
        PLAY_TEXT_MESSAGE,
        CALL_FORWARD,
        DECISION,
        SET_VARIABLES,
      ])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/waiting-room-dialog'
        )
      ),
    officeHours: InteractionNodes.useOfficeHours().extendsTo([
      CRITERIA_PABX,
      PUBLIC_HOLIDAY,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      CALL_FORWARD,
      DECISION,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SET_VARIABLES,
    ]),
    publicHolidays: InteractionNodes.usePublicHolidays().extendsTo([
      CRITERIA_PABX,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      CALL_FORWARD,
      DECISION,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SET_VARIABLES,
    ]),
    customTimeRoutes: InteractionNodes.useCustomTimeRoutes().extendsTo([
      CRITERIA_PABX,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      CALL_FORWARD,
      DECISION,
      SET_VARIABLES,
    ]),
    playMessage: InteractionNodes.usePlayMessage().extendsTo([
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA_PABX,
      WAITING_STRATEGY_PABX,
      END_CALL,
      CALL_FORWARD,
      DECISION,
      SET_VARIABLES,
    ]),
    playTextMessage: InteractionNodes.usePlayTextMessage().extendsTo([
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      SELECTION_MENU,
      PATTERN_MENU,
      SCREEN_CALL,
      CRITERIA_PABX,
      WAITING_STRATEGY_PABX,
      END_CALL,
      CALL_FORWARD,
      DECISION,
      SET_VARIABLES,
    ]),
    selectionMenu: InteractionNodes.useSelectionMenu().extendsTo([
      CRITERIA_PABX,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      END_CALL,
      CALL_FORWARD,
      DECISION,
      PATTERN_MENU,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SET_VARIABLES,
    ]),
    patternMenu: InteractionNodes.usePatternMenu().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      PATTERN_MENU,
      SELECTION_MENU,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
      SET_VARIABLES,
    ]),
    incomingCall: InteractionNodes.useStart().extendsTo([
      CRITERIA_PABX,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      DECISION,
      SET_VARIABLES,
    ]),
    onCall: InteractionNodes.useOnInteraction()
      .extendsTo([
        END_CALL,
        STOP_RECORDING,
        PLAY_MESSAGE,
        PLAY_TEXT_MESSAGE,
        SELECTION_MENU,
        PATTERN_MENU,
        CALL_FORWARD,
        SET_VARIABLES,
      ])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      ),
    screenCall: InteractionNodes.useScreenCall().extendsTo([
      CRITERIA_PABX,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      CALL_FORWARD,
      DECISION,
      SET_VARIABLES,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      SET_VARIABLES,
    ]),
    startCallRecording: InteractionNodes.useStartCallRecording()
      .extendsTo([ON_CALL, PLAY_MESSAGE, PLAY_TEXT_MESSAGE, SET_VARIABLES])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      ),
    stopCallRecording: InteractionNodes.useStopCallRecording()
      .extendsTo([
        END_CALL,
        PLAY_MESSAGE,
        PLAY_TEXT_MESSAGE,
        CALL_FORWARD,
        DECISION,
        SET_VARIABLES,
      ])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      ),
    mailbox: InteractionNodes.useMailbox(),
    callForward: InteractionNodes.useCallForward().extendsTo([
      END_CALL,
      SET_VARIABLES,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      CRITERIA,
      PUBLIC_HOLIDAY,
      OFFICE_HOURS,
      CUSTOM_TIME_ROUTES,
      PLAY_MESSAGE,
      PLAY_TEXT_MESSAGE,
      SCREEN_CALL,
      MAILBOX,
      END_CALL,
      SURVEY,
      CALL_FORWARD,
      DECISION,
      PATTERN_MENU,
      SELECTION_MENU,
      LOAD_BALANCER,
      EMERGENCY_ANOUNCEMENT,
    ]),
    endCall: InteractionNodes.useEnd(),
  };

  static outboundOfficeFlow: object = {
    defaultStartNode: 'outgoingCall',
    outgoingCall: InteractionNodes.useStart().extendsTo([
      CRITERIA_PABX,
      SET_VARIABLES,
    ]),
    criteriaPabx: InteractionNodes.useCriteria().extendsTo([
      START_RECORDING,
      ON_CALL,
      SET_VARIABLES,
    ]),
    onCall: InteractionNodes.useOnInteraction()
      .extendsTo([END_CALL, STOP_RECORDING, SET_VARIABLES])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      ),
    startCallRecording: InteractionNodes.useStartCallRecording()
      .extendsTo([ON_CALL, SET_VARIABLES])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      ),
    stopCallRecording: InteractionNodes.useStopCallRecording()
      .extendsTo([END_CALL, SET_VARIABLES])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      ),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      CRITERIA_PABX,
      START_RECORDING,
      ON_CALL,
      END_CALL,
      STOP_RECORDING,
    ]),
    survey: InteractionNodes.useSurvey().extendsTo([END_CALL, SET_VARIABLES]),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
  };

  static emailFlow: object = {
    defaultStartNode: 'incomingEmail',
    incomingEmail: InteractionNodes.useStart().extendsTo([
      CRITERIA,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      SEND_EMAIL,
    ]),
    criteria: InteractionNodes.useCriteria().extendsTo([
      LOAD_BALANCER,
      WAITING_STRATEGY,
      EMERGENCY_ANOUNCEMENT,
      API_CALL,
      SET_VARIABLES,
      SEND_EMAIL,
    ]),
    onEmail: InteractionNodes.useOnInteraction()
      .extendsTo([END_CALL, API_CALL, SET_VARIABLES, SEND_EMAIL])
      .withName('On Email')
      .withDialogTemplate(null)
      .withDefaultProperties({ isDefined: true })
      .withIcon('envelope-open')
      .withImage('envelope-open'),
    queueingStrategy: InteractionNodes.useQueueingStrategy()
      .extendsTo([ON_EMAIL, SET_VARIABLES, DECISION])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/non-realtime/non-realtime-waiting-room-with-steps-dialog'
        )
      ),
    officeHours: InteractionNodes.useOfficeHours().extendsTo([
      CRITERIA,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      WAITING_STRATEGY,
      END_CALL,
      SEND_EMAIL,
    ]),
    apiCall: InteractionNodes.useApiCall().extendsTo([
      WAITING_STRATEGY,
      CRITERIA,
      END_CALL,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      SEND_EMAIL,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      END_CALL,
      CRITERIA,
      WAITING_STRATEGY,
      ON_EMAIL,
      API_CALL,
      DECISION,
      OFFICE_HOURS,
      SEND_EMAIL,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      CRITERIA,
      DECISION,
      API_CALL,
      END_CALL,
      SET_VARIABLES,
      OFFICE_HOURS,
      SEND_EMAIL,
    ]),
    sendEmail: InteractionNodes.useSendEmail().extendsTo([
      WAITING_STRATEGY,
      CRITERIA,
      END_CALL,
      SEND_EMAIL,
    ]),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
  };

  static chatFlow: object = {
    defaultStartNode: 'incomingChat',
    incomingChat: InteractionNodes.useStart().extendsTo([
      CRITERIA,
      OFFICE_HOURS,
      SELECTION_MENU,
      PLAY_TEXT_MESSAGE,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    criteria: InteractionNodes.useCriteria().extendsTo([
      WAITING_STRATEGY,
      PLAY_TEXT_MESSAGE,
      API_CALL,
      SET_VARIABLES,
      ASSISTANT,
    ]),
    selectionMenu: InteractionNodes.useTextIVR().extendsTo([
      CRITERIA,
      OFFICE_HOURS,
      PLAY_TEXT_MESSAGE,
      SELECTION_MENU,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      END_CALL,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    onChat: InteractionNodes.useOnInteraction().extendsTo([
        END_CALL,
        SELECTION_MENU,
        PLAY_TEXT_MESSAGE,
        API_CALL,
        SET_VARIABLES,
        DECISION,
        WAITING_STRATEGY,
        PATTERN_MENU,
      ])
      .withName('On Chat')
      .withIcon('comments-square')
      .withImage('comments-square')
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/on-chat/on-chat-dialog'
        )
      )
      .withOutputConnectors([{ name: 'always' }, { name: 'Timeout' }])
      .withDefaultProperties({ isDefined: true }),
    queueingStrategy: InteractionNodes.useQueueingStrategy().extendsTo([
      PLAY_TEXT_MESSAGE,
      ON_CHAT,
      SET_VARIABLES,
      DECISION,
    ]),

    officeHours: InteractionNodes.useOfficeHours().extendsTo([
      CRITERIA,
      SELECTION_MENU,
      WAITING_STRATEGY,
      PLAY_TEXT_MESSAGE,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    patternMenu: InteractionNodes.useInputCapture().extendsTo([
      CRITERIA,
      SELECTION_MENU,
      WAITING_STRATEGY,
      PLAY_TEXT_MESSAGE,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      PATTERN_MENU,
      END_CALL,
      ASSISTANT,
    ]),
    playTextMessage: InteractionNodes.usePlayTextMessage().extendsTo([
      OFFICE_HOURS,
      SELECTION_MENU,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      END_CALL,
      SELECTION_MENU,
      OFFICE_HOURS,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      ON_CHAT,
      API_CALL,
      DECISION,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    apiCall: InteractionNodes.useApiCall().extendsTo([
      OFFICE_HOURS,
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      CRITERIA,
      SELECTION_MENU,
      OFFICE_HOURS,
      PLAY_TEXT_MESSAGE,
      DECISION,
      API_CALL,
      END_CALL,
      SET_VARIABLES,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    assistant: InteractionNodes.useAssistant().extendsTo([
      CRITERIA,
      SELECTION_MENU,
      OFFICE_HOURS,
      PLAY_TEXT_MESSAGE,
      DECISION,
      API_CALL,
      END_CALL,
      SET_VARIABLES,
      PATTERN_MENU,
      ASSISTANT,
      WAITING_STRATEGY
    ]),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
  };

  static instantMessageChat: object = {
    defaultStartNode: 'incomingIM',
    incomingIM: InteractionNodes.useStart().extendsTo([
      CRITERIA,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      SELECTION_MENU,
      ASSISTANT,
      PATTERN_MENU,
    ]),
    criteria: InteractionNodes.useCriteria().extendsTo([
      LOAD_BALANCER,
      WAITING_STRATEGY,
      EMERGENCY_ANOUNCEMENT,
      API_CALL,
      SET_VARIABLES,
      ASSISTANT,
    ]),
    selectionMenu: InteractionNodes.useTextIVR().extendsTo([
      CRITERIA,
      OFFICE_HOURS,
      PLAY_TEXT_MESSAGE,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      END_CALL,
      ASSISTANT,
    ]),
    onChat: InteractionNodes.useOnInteraction()
      .extendsTo([END_CALL, SET_VARIABLES, SELECTION_MENU, PATTERN_MENU])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/on-chat/on-chat-dialog'
        )
      )
      .withName('On Message')
      .withIcon('comments-square')
      .withImage('comments-square'),
    queueingStrategy: InteractionNodes.useQueueingStrategy()
      .extendsTo([ON_CHAT, SET_VARIABLES, DECISION, SELECTION_MENU])
      .withDefaultProperties({
        isDefined: true,
        agentsInQueue: '0',
        hasExitDestination: false,
        queueAgentRingDuration: '20',
        queueMediaType: '',
        queueStrategy: 'ROUND_ROBIN',
        saved: false,
        totalInteractions: '',
        waitTime: '',
      }),
    officeHours: InteractionNodes.useOfficeHours().extendsTo([
      CRITERIA,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      WAITING_STRATEGY,
      END_CALL,
      SELECTION_MENU,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    apiCall: InteractionNodes.useApiCall().extendsTo([
      WAITING_STRATEGY,
      CRITERIA,
      END_CALL,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      END_CALL,
      CRITERIA,
      WAITING_STRATEGY,
      ON_CHAT,
      API_CALL,
      DECISION,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      CRITERIA,
      DECISION,
      API_CALL,
      END_CALL,
      SET_VARIABLES,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    patternMenu: InteractionNodes.useInputCapture().extendsTo([
      CRITERIA,
      SELECTION_MENU,
      WAITING_STRATEGY,
      PLAY_TEXT_MESSAGE,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      PATTERN_MENU,
      END_CALL,
      ASSISTANT,
    ]),
    playTextMessage: InteractionNodes.usePlayTextMessage().extendsTo([
      CRITERIA,
      PLAY_TEXT_MESSAGE,
      WAITING_STRATEGY,
      END_CALL,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      OFFICE_HOURS,
      SELECTION_MENU,
      PATTERN_MENU,
      ASSISTANT,
    ]),
    assistant: InteractionNodes.useAssistant().extendsTo([
      CRITERIA,
      SELECTION_MENU,
      OFFICE_HOURS,
      PLAY_TEXT_MESSAGE,
      DECISION,
      API_CALL,
      END_CALL,
      SET_VARIABLES,
      PATTERN_MENU,
      ASSISTANT,
      WAITING_STRATEGY
    ]),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
  };

  static smsFlow: object = {
    defaultStartNode: 'incomingCall',
    incomingCall: InteractionNodes.useStart().extendsTo([
      CRITERIA,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      SEND_SMS,
    ]),
    criteria: InteractionNodes.useCriteria().extendsTo([
      LOAD_BALANCER,
      WAITING_STRATEGY,
      EMERGENCY_ANOUNCEMENT,
      API_CALL,
      SET_VARIABLES,
      SEND_SMS,
    ]),
    queueingStrategy: InteractionNodes.useQueueingStrategy()
      .extendsTo([ON_SMS, SET_VARIABLES, DECISION])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/non-realtime/non-realtime-waiting-room-with-steps-dialog'
        )
      )
      .withDefaultProperties({
        isDefined: true,
        agentsInQueue: '0',
        hasExitDestination: false,
        queueAgentRingDuration: '20',
        queueMediaType: '',
        queueStrategy: 'ROUND_ROBIN',
        saved: false,
        totalInteractions: '',
        waitTime: '',
        notRespondingEnabled: true,
      }),
    officeHours: InteractionNodes.useOfficeHours().extendsTo([
      CRITERIA,
      DECISION,
      API_CALL,
      SET_VARIABLES,
      WAITING_STRATEGY,
      END_CALL,
      SEND_SMS,
    ]),
    onSms: InteractionNodes.useOnInteraction()
      .extendsTo([END_CALL, API_CALL, SET_VARIABLES, SEND_SMS])
      .withDialogTemplate(null)
      .withName('On SMS')
      .withIcon('sms')
      .withImage('comments'),
    apiCall: InteractionNodes.useApiCall().extendsTo([
      WAITING_STRATEGY,
      CRITERIA,
      END_CALL,
      API_CALL,
      SET_VARIABLES,
      DECISION,
      OFFICE_HOURS,
      SEND_SMS,
    ]),
    setVariables: InteractionNodes.useSetVariables().extendsTo([
      END_CALL,
      CRITERIA,
      WAITING_STRATEGY,
      ON_SMS,
      API_CALL,
      DECISION,
      OFFICE_HOURS,
      SEND_SMS,
    ]),
    decision: InteractionNodes.useDecision().extendsTo([
      CRITERIA,
      DECISION,
      API_CALL,
      END_CALL,
      SET_VARIABLES,
      OFFICE_HOURS,
      SEND_SMS,
    ]),
    sendSMS: InteractionNodes.useSendSMS().extendsTo([
      WAITING_STRATEGY,
      CRITERIA,
      END_CALL,
      SEND_SMS,
    ]),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
  };

  static ticketFlow: object = {
    defaultStartNode: 'incomingTicket',
    incomingTicket: InteractionNodes.useStart().extendsTo([CRITERIA, DECISION]),
    decision: InteractionNodes.useDecision().extendsTo([CRITERIA]),
    criteria: InteractionNodes.useCriteria().extendsTo([WAITING_STRATEGY]),
    queueingStrategy: InteractionNodes.useQueueingStrategy().extendsTo([
      ON_TICKET,
    ]),
    onTicket: InteractionNodes.useOnInteraction()
      .extendsTo([END_CALL])
      .withDialogTemplate(
        PLATFORM.moduleName(
          'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
        )
      )
      .withName('On Ticket')
      .withIcon('icon_blue_ticket')
      .withImage('icon_blue_ticket')
      .withDefaultProperties({ isDefined: true }),
    endCall: InteractionNodes.useEnd().withDialogTemplate(
      PLATFORM.moduleName(
        'features/interaction/interactionFlow/interaction-designer/dialogs/name-only/name-only-dialog'
      )
    ),
  };

  static nodeConfigMap: object = {
    ContactCentreFlowInboundCall: InteractionConfigStrategy.inboundCallFlow,
    ContactCentreFlowOutboundCall: InteractionConfigStrategy.outboundCallFlow,
    OfficeFlowInboundCall: InteractionConfigStrategy.inboundOfficeFlow,
    OfficeFlowOutboundCall: InteractionConfigStrategy.outboundOfficeFlow,
    ContactCentreFlowEmail: InteractionConfigStrategy.emailFlow,
    ContactCentreFlowSMS: InteractionConfigStrategy.smsFlow,
    ContactCentreFlowTicket: InteractionConfigStrategy.ticketFlow,
    ContactCentreFlowChat: InteractionConfigStrategy.chatFlow,
    ContactCentreFlowInstantMessage:
      InteractionConfigStrategy.instantMessageChat,
  };

  static getConfigStrategy(_descriptor: string): InteractionConfig {
    return InteractionConfigStrategy.nodeConfigMap[_descriptor];
  }
}

export interface InteractionConfig {
  defaultStartNode: string;
  disableInConnector: boolean;
  disableOutConnector: boolean;
  image: '';
  dialogTemplate: string;
  defaultExtensions: string[];
  alternativeExtensions: string[];
  name: string;
  description?: string;
  defaultProperties: object;
  isDefined: boolean;
  type: string;
  inputConnectors: { name: string }[];
  outputConnectors: { name: string }[];
}
