import { LogManager, observable, autoinject } from 'aurelia-framework';
import timezone from 'countries-and-timezones';
import { SessionStore } from 'zailab.common';
import { NodeModel } from '../../../../components/organisms/node/models/node-model';

const logger = LogManager.getLogger('Timezones >>');

@autoinject()
export class TimezoneAbstract {

  protected availableTimezones: ZITimezone[];
  @observable protected selectedTimezone: ZITimezone;
  protected userTimezone: any;
  protected appId: string = 'acc751efcb054031a7ac937bfabce';
  protected orgCountryCode: string = 'ZA';
  protected nodeData: NodeModel;

  constructor(protected sessionStore: SessionStore) {

  }

  protected activate(_config?: ZNodeConfig, timezoneName?: string): void {
    this.availableTimezones = this.getTimezones();
    this.selectedTimezone = this.getDefaultTimezone(timezoneName);
  }

  protected getDefaultTimezone(timezoneName?: string): ZITimezone {
    let defaultSelectedTimezone = this.nodeData && this.nodeData.properties && this.nodeData.properties.timezone ? this.nodeData.properties.timezone as ZITimezone : this.availableTimezones[0] as ZITimezone;
    // Check if selected timezone is valid (temporary hack for Nuie) DL
    if (this.availableTimezones.some(_zone => {
      if (timezoneName) {
        return _zone.name === timezoneName;
      }
      return _zone.name === defaultSelectedTimezone.name
    })) {
      return defaultSelectedTimezone;
    } else {
      return this.availableTimezones[0]
    }
  }

  protected getTimezones(): ZITimezone[] {
    let _allTimezones = timezone.getAllTimezones();
    let _timezoneArray = Object.keys(_allTimezones).map(key => _allTimezones[key]);
    let _countryTimezones = _timezoneArray.filter(_key => {
      return _key.countries.includes(this.sessionStore.get.organisation.country.code)
    });
    _countryTimezones.sort((a: ZITimezone, b: ZITimezone) => {
      return b.utcOffset - a.utcOffset;
    });
    return _countryTimezones.map((_zone) => {
      let zoneName = _zone.name.replace('_', ' ');
      return {
        name: _zone.name,
        utcOffset: _zone.utcOffset,
        offsetStr: _zone.dstOffsetStr,
        countries: _zone.countries,
        description: '(UTC' + _zone.dstOffsetStr + ') ' + this.stripName(zoneName)
      } as ZITimezone;
    });
  }

  protected stripName(_name: string): string {
    let startIndex = _name.indexOf('/');
    return _name.substring(startIndex + 1, _name.length).replace(/\//g, ', ');
  }

  protected selectedTimezoneChanged(_newValue: ZITimezone, _oldValue: ZITimezone): void {

  }

}
