import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { ArrayTools, DateTimeTools, MESSAGE_EVENTS } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { InHouseWFMService } from '../in-house-wfm-service';
import { AddShiftTemplateDialog } from './add-shift-template/add-shift-template-dialog';

const logger = LogManager.getLogger('ShiftTemplateList');

@autoinject()
export class ShiftTemplateList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders = 0;
  public container: any;

  public loading: boolean;
  public itemList: any;

  constructor(
    private dialogService: DialogService,
    private inHouseWFMService: InHouseWFMService,
    private dateTimeTools: DateTimeTools,
    private router: Router,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public activate(): void {
    this.retrieveShiftTemplates();
  }

  private async retrieveShiftTemplates(): Promise<void> {
    super.showLoader();
    const shiftTemplateData = await this.inHouseWFMService
      .retrieveShiftTemplates()
      .catch((e) =>
        console.warn(' > Failed to retrieve shift templates due to ', e)
      );
    super.hideLoader();

    this.setupListView(shiftTemplateData ? shiftTemplateData.templates : []);
  }

  private setupListView(shiftTemplates: any[]): void {
    shiftTemplates = ArrayTools.sort(shiftTemplates, 'name');
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableClick()
      .enableAdd()
      .enableDelete()
      .items(shiftTemplates)
      .uniqueIdentifier('id')
      .hasRollover(false)
      .tilesPerRow(5)
      .disableOrderBy()
      .build();
    this.generatePlaceholders();
    super.hideLoader();
  }

  public select(shiftTemplate: any): void {
    if (this.itemList.isDeleting) {
      this.itemList.items.forEach((item) => (item.isDeleting = false));
      shiftTemplate.isDeleting = true;
    } else {
      this.openShiftTemplateDialog(shiftTemplate);
    }
  }

  public openShiftTemplateDialog(shiftTemplate: any): void {
    this.dialogService
      .open({
        viewModel: AddShiftTemplateDialog,
        model: {
          shiftTemplate,
          existingNames: this.itemList.items
            .map((item) => {
              if (shiftTemplate && shiftTemplate.name === item.name) {
                return null;
              }
              return item.name;
            })
            .filter((item) => !!item),
        },
      })
      .whenClosed(
        (response: {
          wasCancelled: boolean;
          output: { created?: any; updated?: any };
        }) => {
          if (!response.wasCancelled) {
            if (response.output.created) {
              this.itemList.items.push(response.output.created);

              setTimeout(() => {
                this.itemList.items = ArrayTools.sort(
                  this.itemList.items,
                  'name'
                );
              }, 50);
            } else if (response.output.updated) {
              const item = this.itemList.items.find(
                (_item) => _item.id === response.output.updated.id
              );
              if (item) {
                item.name = response.output.updated.name;
                item.startTimeOfDay = response.output.updated.startTimeOfDay;
                item.shiftDurationInMin =
                  response.output.updated.shiftDurationInMin;
                item.breaks = response.output.updated.breaks;
                item.offDutyWork = response.output.updated.offDutyWork;
              }
            }
          }
        }
      );
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.itemList.items.length,
      5,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }

  public delete(): void {
    const item = this.itemList.items.find((_item) => _item.isDeleting);
    if (!item) {
      return;
    }

    super.showLoader();
    this.inHouseWFMService
      .deleteShiftTemplates(item.id)
      .then(() => {
        super.hideLoader();
        this.itemList.items.splice(this.itemList.items.indexOf(item), 1);
        this.generatePlaceholders();
        this.itemList.toggleDelete();
      })
      .catch((e) => {
        super.hideLoader();
        console.warn(' > Failed to remove shift template due to', e);
      });
  }

  public navigateToInHouseWFM(): void {
    this.router.navigate('');
  }
}
