import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DashboardWidget } from '../../dashboard/dashboard-abstract';
import { ServicesService } from '../services-service';

const logger = LogManager.getLogger('Services Widget');

@autoinject()
export class ServicesDashboardWidget extends DashboardWidget {
  
  private oplog: any;

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }, {
    name: 'Add',
    event: 'add',
    icon: 'add'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private servicesService: ServicesService
  ) {
    super();
  }

  public attached(): void {
    this.servicesService.initialiseStatsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.count = response.services.length;
      });
      this.oplog.on('update', (response) => {
        this.count = response.services.length;
      });
    });
    this.retrieveServicesStats();
  }

  public detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  public retrieveServicesStats(): void {
    this.servicesService.retrieveServicesStats().then((response) => {
      this.count = response;
      this.ready = true;
    }, (error) => {
      logger.warn('Could not retrieve Services Stats', error);
    });
  }
}