import {inject, LogManager, Factory} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {HttpClient} from 'aurelia-http-client';
/*
 * */
import {CommandFactory, OplogService} from 'zailab.common';
import {ActivityModel} from './activity-model';
/*
 * */
const logger = LogManager.getLogger('ActivityService');
/*
 * */
@inject(OplogService, EventAggregator, HttpClient, Factory.of(CommandFactory))
export class ActivityService {

  constructor(oplogService, eventAggregator, httpClient, commandFactory) {
    this.oplog = oplogService;
    this.eventAggregator = eventAggregator;
    this.httpClient = httpClient;
    this.commandFactory = commandFactory;
  }

  initialiseMemberActivityOplogWith(memberId) {
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', memberId).in('member-projector.displayMemberActivityView'));
    });
  }

  retrieveMemberActivity(memberId) {
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/organisation/members/${memberId}/activity`)
        .asGet()
        .send()
        .then((response) => {
          resolve(new ActivityModel(response));
        }, (error) => {
          reject(error);
        });
    });

  }

  retrieveMemberStatusActivity(memberId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/members/${memberId}/activity-routability`)
        .asGet()
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  retrieveRoutingStatus(memberId) {

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`v1/organisation/members/${memberId}/routing-status`)
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );

    });
  }

}
