import { ProportionalWidget } from 'abstract/widgets/proportional-widget-abstract';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class AgentScriptAdminConfigWidget extends ProportionalWidget {

  constructor(private router: Router, eventAggregator: EventAggregator) {
    super(eventAggregator, null);
  }

  public activate(widgetConfig: ZIWidgetConfig): void {
    super.activate(widgetConfig);
  }

  public view(): void {
    this.router.navigate('agent-script-admin-config');
  }
}