import { LogManager, autoinject, inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

const logger = LogManager.getLogger('Page2EditAssistantApi');

@autoinject
export class Page2EditAssistantApi {
  private wizard: any;

  public data: {
    tableData: {
      name: string;
      type: string;
      description: string;
      required: boolean;
    }[];
  } = {
    tableData: [],
  };

  public newProperty = {
    name: '',
    type: 'string',
    description: '',
    required: false,
  };
  public properties: Array<{
    name: string;
    type: string;
    description: string;
    required: boolean;
  }> = [];

  public tableColumns = ['Name', 'Type', 'Description', 'Required'];
  public tableTypes = [
    {
      type: 'text',
      validation: 'required',
    },
    {
      type: 'dropdown',
      options: ['Boolean', 'String', 'Number'],
      validation: 'required',
    },
    {
      type: 'text',
      validation: 'required',
    },
    {
      type: 'checkbox',
      label: 'Required',
      true: 'Yes',
      false: 'No',
    },
  ];
  public tableData;
  public columnsRegex = [/^(?:(?![]).)*$/, null, /^(?:(?![]).)*$/, null];

  constructor(
    private eventAggregator: EventAggregator
  ) {}

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.validate();
    this.tableData = this.properties
      ? this.properties.map((row) => Object.values(row))
      : [];
    this.data = this.wizard.data;
  }

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  public tableDataUpdated(tableData: any[]): void {
    this.properties = [];
    if (tableData.length > 0) {
      this.properties = tableData.map((row) => {
        const name = row[0];
        const type = row[1];
        const description = row[2];
        const required = row[3] === 'No' ? false : true;

        return { name, type, description, required };
      });
    }
    this.wizard.data.properties = this.properties;
    this.validate();
  }

  private validate(): void {
    if (this.properties.length > 0) {
      this.wizard.step.complete({});
    } else {
      this.wizard.step.incomplete();
    }
  }

  private extractWizardData(): void {
    this.properties = this.wizard.data.properties || [];
  }

  private updateWizardData(): void {
    this.wizard.data.properties = this.properties;

    const currentData = this.data;

    this.wizard.data = {
      ...this.wizard.data,
      ...currentData,
    };
  }

  private initWizard(wizard: any): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
      return this.properties.length > 0;
    });
  }

  private resetNewProperty(): void {
    this.newProperty = {
      name: '',
      type: '',
      description: '',
      required: false,
    };
  }

  private nextStep(): void {
    this.wizard.continue();
  }
}
