import { HttpClient } from 'aurelia-http-client';
import { inject, LogManager } from 'aurelia-framework';
/**
 */
import { SessionStore, OplogService } from 'zailab.common';
import { AccountModel } from './account-model';
/**
 */
const logger = LogManager.getLogger('AccountService');
/*
 */
@inject(SessionStore, HttpClient, OplogService)
export class AccountService {
  constructor(sessionStore, httpClient, oplogService) {
    this.sessionStore = sessionStore;
    this.httpClient = httpClient;
    this.oplog = oplogService;
  }

  subscribeToAccountStatus(accountId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', accountId).in('account-projector.accountStatusView'));
    });
  }

  retrieveAccountStatus() {
    return new Promise((resolve, reject) => {
      this._createRequest(
        '/v1/finance/accounts/me/account-status',
        response => {
          resolve(new AccountModel(response));
        },
        error => {
          reject(error);
        }
      );
    });
  }

  retrieveAccountStats() {
    return new Promise((resolve, reject) => {
      this._createRequest(
        '/v1/finance/accounts/me/summary',
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  initialiseStatsOplog(accountId) {
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', accountId).in('account-projector.accountSummaryView'));
    });
  }

  _createRequest(uri, callback, errorCallback) {
    this.httpClient
      .createRequest(uri)
      .asGet()
      .send()
      .then(
        response => {
          callback(response);
        },
        error => {
          errorCallback(error);
        }
      );
  }
}
