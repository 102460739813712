import { autoinject } from 'aurelia-dependency-injection';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { LogManager } from 'aurelia-framework';
import { CallTranscriptModel } from '../../../organisation/conversation/call-transcript-model';
import InteractionLogModel from '../interactionlog/interaction-log-model';
// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('ViewInteractionTranscriptIMDialog');

@autoinject
export class ViewInteractionTranscriptIMDialog {
  private dialogController: DialogController;
  public transcript: CallTranscriptModel;
  public interaction: InteractionLogModel;
  public dialogHeader = 'Interaction Instant Message Transcript';

  constructor(dialogController: DialogController) {
    this.dialogController = dialogController;
  }

  public activate(config: ZaiInteractionTranscript): void {
    const transcript = config.transcript;

    transcript.messages.forEach((item) => {
      if (item.media && item.media.url) {
          let mediaContent = '';

          if (this.isImage(item.media.type)) {
            mediaContent = `<img style="max-width:100%; max-height:100%;" src="${item.media.url}">`;
          } else {
            mediaContent = `
              <div if.bind="${!this.isImage(item.media.type)}">
                <div class="truncate" title="${item.media.title}">
                  ${item.media.title}
                </div>
                <div class="font-medium">
                  <b>${this.getFileTypeString(item.media.type)}</b>
                </div>
              </div>
            `;
          }

          item.text = item.text ? `${item.text}<br><br>${mediaContent}` : mediaContent;
        }
  });

  this.transcript = transcript;
  this.interaction = config.interaction;
}

  public isImage(type: string): boolean {
    return type.indexOf('image') >= 0;
  }

  public getFileTypeString(mimetype): string {
    if (!mimetype) {
       return 'Unknown File';
    }

    // Split the mimetype into main type and subtype
    const parts = mimetype.split('/');

    // Handle images
    if (parts[0] === 'image') {
      return `${parts[1].toUpperCase()} Image`;
    }

    // Handle other file types with extension-based mapping
    const extensionMap = {
      'application/pdf': 'PDF File',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'XLSX File',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'DOCX File',
      'application/msword': 'DOC File',
      'text/plain': 'TXT File',
      'application/vnd.ms-excel': 'XLS File',
      'application/vnd.ms-powerpoint': 'PPT File',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'PPTX File',
      'text/csv': 'CSV File',
    };

    if (extensionMap[mimetype]) {
      return extensionMap[mimetype];
    }

    // Handle unknown file types with generic category based on main type
    switch (parts[0]) {
      case 'application':
        return 'Document';
      case 'audio':
        return 'Audio File';
      case 'video':
        return 'Video File';
      case 'text':
        return 'Text File';
      default:
        return 'Unknown File';
    }
  }

  public replaceSpeaker(name: string): string {
    const interaction: any = this.interaction;
    if (!name) {
      return this.interaction.members[0].firstName;
    } else {
      return name;
    }
  }

  public formatToTime(timestamp: number): any {
    if (timestamp) {
      return moment(timestamp).format('HH:mm');
    }
  }

  public cancel(): void {
    this.transcript = null;
    this.dialogController.cancel();
  }
}
