import { LogManager, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('BypassCos');

export class BypassCos {

  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: ['', 'list'],
        name: 'Safelist List',
        moduleId: PLATFORM.moduleName('./list/bypass-cos-list'),
        title: 'List'
      },
      {
        route: 'add',
        name: 'Safelist Add',
        moduleId: PLATFORM.moduleName('./add/bypass-cos-add'),
        title: 'Add'
      },
      {
        route: 'edit/:id',
        name: 'Safelist Edit',
        moduleId: PLATFORM.moduleName('./edit/bypass-cos-edit'),
        title: 'Edit'
      }
    ]);
    this.router = router;
  }
}