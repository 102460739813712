import {GRAPH_COLORS} from './graph-colors';

export class GraphConfig {
  public equalWidths: boolean = false;
  public balloonText: string = '<span style=\'font-size:1rem:\'>[[title]]: <b>[[value]]</b></span>';
  public type:string = 'column';
  public color: string = 'var(--theme-text-default)';
  public fixedColumnWidth: number = 40;
  public fillAlphas: number = 1;
  public lineAlpha: number = 0.5;
  public fillColors: GRAPH_COLORS = GRAPH_COLORS.CALLS;
  public lineColor: GRAPH_COLORS = GRAPH_COLORS.CALLS;
  public title: string;
  public valueField: string;

  constructor() {

  }
}
