import { autoinject, LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";
import { FeatureFlagService } from "../../../featureflags/feature-flag-service";

const logger = LogManager.getLogger('CampaignDashboard');

@autoinject
export class CampaignDashboard {

  public campaignsLiveDashboardEnabled: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly featureFlags: FeatureFlagService
  ) { }
  
  public activate(): void {
    this.featureFlags.isEnabled('campaignsLiveDashboard').then((enabled) => this.campaignsLiveDashboardEnabled = enabled);
  }

  public viewCampaigns = (): void => {
    this.router.navigate('campaigns/list')
  }

  public viewReports = (): void => {
    this.router.navigate(`reports`);
  }

  public viewReportSchedule = (): void => {
    this.router.navigate('reportschedule');
  }

  public viewAlert = (): void => {
    this.router.navigate('alert');
  }

  public viewInteractionLog = (): void => {
    this.router.navigate('interactionlog');
  }

  public viewAgentAvailabilityDashboard = (): void => {
    this.router.navigate('agentavailabilitydashboard');
  }

  public viewContactcentermonitoring = (): void => {
    this.router.navigate('contactcentermonitoring');
  }

  public viewCampaignLiveDashboard = (): void => {
    this.router.navigate('campaignlivedashboard');
  };

  public viewAnalytics = (): void => {
    this.router.navigate('analytics');
  };
}
