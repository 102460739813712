import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import CampaignService from '../campaign-service';
import {
  CAMPAIGN_LIVE_DASHBOARD_STATE,
  CampaignLiveDashboardState,
} from './campaign-live-dashboard-state';
import {
  DataGrid,
  DataGridEvents,
  DataRefresher,
  NavigatingBreadcrumb,
} from '../../../../components/templates/data-grid/data-grid';
import { LiveDashboardService } from '../../../interaction/dashboard/live-dashboard/live-dashboard-service';
import { ColourConfig } from 'features/interaction/dashboard/live-dashboard/overview/colour-config';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('CampaignDashboard');
const DEFAULT_CONFIG: MinimalViableLiveDashboardConfig[] = [
  {
    title: 'Remaining [Ready]',
    settings: [
      { value: 0, color: '' },
      { value: 1, color: 'var(--successful)' },
    ],
  },
  {
    title: 'On Duty Agents',
    settings: [
      { value: 0, color: '' },
      { value: 1, color: 'var(--successful)' },
    ],
  },
  {
    title: 'Conversing Agents',
    settings: [
      { value: 0, color: '' },
      { value: 1, color: 'var(--successful)' },
    ],
  },
  {
    title: 'Not Responding Agents',
    settings: [
      { value: 0, color: '' },
      { value: 1, color: 'var(--unsuccessful)' },
    ],
  },
  {
    title: 'Off Duty Agents',
    settings: [
      { value: 0, color: '' },
      { value: 1, color: 'var(--unsuccessful)' },
    ],
  },
  {
    title: '% In SLA (30min)',
    settings: [
      { value: 0, color: 'var(--unsuccessful)' },
      { value: 86, color: '' },
    ],
  },
];

interface MinimalViableLiveDashboardConfig {
  title: string;
  settings: {
    value: number;
    color: string;
  }[];
  visible?: boolean;
}
interface IStateChannels {
  call: boolean;
  callback: boolean;
  chat: boolean;
  email: boolean;
  im: boolean;
  sms: boolean;
  ticket: boolean;
}
interface IStateStorage {
  requestTimer: number;
  contactCenterMonitoring: {
    grid: {
      columnHeaders: Array<string>;
      channels: IStateChannels;
      teams: Array<string>;
    };
    requestTimer: number;
  };
}

@autoinject
export class CampaignDashboard {
  private refreshIntervalHolder: any;

  public persistence: any = [];
  public refresher: DataRefresher = new DataRefresher(300);
  public breadcrumbs: NavigatingBreadcrumb[] = [
    new NavigatingBreadcrumb('', 'Campaigns Live Dashboard', true),
  ];
  public cdDataGrid: DataGrid;

  private campaignDashboardState: CampaignLiveDashboardState =
    new CampaignLiveDashboardState();
  private mappings: any;
  public gridEnabled = false;

  constructor(
    private readonly router: Router,
    private readonly dataGridEvents: DataGridEvents,
    private readonly bindingSignaler: BindingSignaler,
    private readonly campaignService: CampaignService,
    private liveDashboardService: LiveDashboardService
  ) {}

  private async init(): Promise<void> {
    this.dataGridEvents.onDragStart(() => {
      this.refresher.enableSelectors = false;
      this.refresher.paused = true;
    });
    this.dataGridEvents.onDragEnd(() => {
      this.refresher.enableSelectors = true;
      this.refresher.paused = false;
    });

    const colorScheme: any = await this.retrieveColorConfig();
    const config =
      (await colorScheme) &&
      colorScheme.colorFormats &&
      Object.keys(colorScheme.colorFormats).length > 0
        ? this.transformData(colorScheme.colorFormats)
        : DEFAULT_CONFIG;
    ColourConfig.setColourScheme(
      ColourConfig.VIEWS.CAMPAIGN_LIVE_DASHBOARD,
      config
    );

    this.mappings = await this.campaignDashboardState.getMappings(config);
    this.cdDataGrid = new DataGrid(this.mappings);
    this.cdDataGrid.dataSource = [];

    this.refresher.currentRequestTimer =
      CAMPAIGN_LIVE_DASHBOARD_STATE.refreshTime;
    this.startRefresh();
  }
  private async retrieveColorConfig(): Promise<void> {
    try {
      const config: any = await this.liveDashboardService.retrieveColorConfig(
        'CAMPAIGN_LIVE_DASHBOARD'
      );
      if (!config) {
        const configId = uuidv4();
        const payload: any = {
          id: configId,
          type: 'CAMPAIGN_LIVE_DASHBOARD',
          colorFormats: {},
        };

        this.liveDashboardService.updateColorConfig(payload.id, payload);
      }
      return config;
    } catch (e) {
      logger.warn('Failed to retrieve live dashboard color config ', e);
    }
  }

  private transformData(inputData) {
    const transformedData = [];

    for (const key in inputData) {
      if (inputData.hasOwnProperty(key)) {
        const transformedItem = {
          title: key,
          settings: inputData[key],
        };
        transformedData.push(transformedItem);
      }
    }
    return transformedData;
  }

  private startRefresh(): void {
    this.refresh();
    if (this.refreshIntervalHolder) {
      clearInterval(this.refreshIntervalHolder);
    }
    this.refreshIntervalHolder = setInterval(() => {
      if (this.refresher.paused) {
        return;
      }
      this.bindingSignaler.signal('myTimeToSignal');
      if (this.refresher.isDone()) {
        this.refresh();
      }
    }, 1000 / 3);
  }

  private refresh(): void {
    this.refresher.enableSelectors = false;
    this.refresher.paused = true;
    const tempList = [];
    this.campaignService
      .retrieveLiveDashboard()
      .then((res) => {
        this.cdDataGrid.ready = true;
        if (res.campaigns) {
          res.campaigns.sort((o1, o2) => {
            if (o1.name > o2.name) {
              return 1;
            }
            if (o1.name < o2.name) {
              return -1;
            }
            return 0;
          });
          for (const campaign of res.campaigns) {
            campaign.campaignId = campaign.id;
            campaign.campaignName = campaign.name;
            campaign.prospectListName = 'ALL';
            campaign.worktype = 'ALL';
            this.cdDataGrid.addData(campaign, tempList);
          }
        }
        this.cdDataGrid.dataSource = tempList;
        this.setReadyState();
      })
      .catch((err) => {
        logger.warn('init :: err=', err);
      });
  }

  private setReadyState(): void {
    this.refresher.enableSelectors = true;
    this.refresher.paused = false;
    this.cdDataGrid.ready = true;
    this.gridEnabled = true;
    this.refresher.reset();
  }

  public activate(): void {
    this.init();
  }

  public detached(): void {
    clearInterval(this.refreshIntervalHolder);
  }

  public select(event: any): void {
    this.router.navigate(`campaignlivedashboard/${event.detail}`);
  }

  public settings(): void {
    this.router.navigate('campaignlivedashboardsettings');
  }

  public save(): void {
    this.refresher.enableSelectors = false;
    this.cdDataGrid.getColumnOrderAndWidths().then((columnOrderAndWidths) => {
      this.campaignDashboardState.saveSettings(columnOrderAndWidths);
      this.campaignDashboardState.setRefreshTime(
        this.refresher.currentRequestTimer
      );
      this.refresher.enableSelectors = true;
    });
  }
}
