import { HttpResponseMessage } from "aurelia-http-client";

export class ReportSchedule {

  public id: string;
  public name: string;
  public frequency: string = 'DAILY';
  public startDate: string;
  public startTime: string;
  public weekDay: string;
  public monthDay: string;
  public deliveryMethod: string = 'EMAIL';
  public email: string;
  public sftpHost: string;
  public sftpPort: number;
  public sftpPath: string;
  public sftpUsername: string;
  public sftpPassword: string;
  public reportId: string;
  public reportName?: string;
  public reportParameters?: any = {};
  public startDateFormatted?: string;
  public icon?: string = 'schedule';
  public canDelete?: boolean = true;

  public get description(): string {
    return `${this.frequency}\r\nFrom: ${this.startDateFormatted}\r\nTime: ${this.startTime}\r\n${this.deliveryMethod}: ${this.deliveryMethod === 'EMAIL' ? this.email : this.sftpHost}`;
  }

  public get descriptionTwo(): string {
    return ` ${this.reportName}${this.reportParameters.teamName ? `\r\nTeam: ${this.reportParameters.teamName}` : ''}${this.reportParameters.agentName ? `\r\nAgent: ${this.reportParameters.agentName}` : ''}${this.reportParameters.serviceName ? `\r\nService: ${this.reportParameters.serviceName}` : ''}`;
  }
}

export class ScheduledJob {

  public scheduledJobId: string;
  public organisationId: string;
  public jobName: string;
  public reportName: string;
  public reportArguments: Map<string, string>;
  public schedule: Schedule;
  public deliveryInformation: DeliveryInformation;
}

export class DeliveryInformation {

  public deliveryMethod: DeliveryMethod;
  public emailTos: string;
  public sftpConfiguration: SFTPConfiguration;
}

export enum DeliveryMethod {

  EMAIL = 'EMAIL', SFTP = 'SFTP'
}

export class SFTPConfiguration {

  public host: string;
  public port: number;
  public username: string;
  public password: string;
  public path: string;
}

export class Schedule {

  public timezone: string;
  public startDate: string;
  public endDate: string;
  public time: string;
  public frequency: Frequency;
}

export enum Frequency {

  DAILY = 'DAILY', WEEKLY = 'WEEKLY', MONTHLY = 'MONTHLY', ONCE_OFF = 'ONCE_OFF'
}

export class ScheduledJobResponse extends HttpResponseMessage {

  public scheduledJobs: Array<ScheduledJob>;
}