import { autoinject } from "aurelia-dependency-injection";

import './tab-2-activity-mapping.scss';
import { DataGrid } from "../../../../../../components/templates/data-grid/data-grid";
import { DEFAULT_DATA } from "./activity-mapping-default-values";

interface IActivityMappingItem {
  loggedStatus: string;
  presence: string;
  routingStatus: string;
  activity: string;
  channel: string;
  eventReason: string;
  eventMode: string;
}

@autoinject()
export class ActivityMappingTab {
  private model: { data: { activityConfigurationMapping: IActivityMappingItem[] }};
  public dataGrid: DataGrid;
  public addingActivityMapping: boolean = false;
  public scrollContainer: HTMLElement;
  public newRow;

  private EMPTY_ROW_DATA: IActivityMappingItem = {
    loggedStatus: '',
    presence: '',
    routingStatus: '',
    activity: '',
    channel: '',
    eventReason: '',
    eventMode: ''
  };

  public activate(bindingContext: { data: any }): void {
    this.model = bindingContext;
  }

  public addActivityMapping(): void {
    const tbodyElement = this.scrollContainer.querySelector('tbody');
    const previousLength = tbodyElement.children.length;
    
    this.model.data.activityConfigurationMapping.push({...this.EMPTY_ROW_DATA});
    this.scrollToBottom(previousLength);
  }

  private scrollToBottom(previousLength: number): void {
    const tbodyElement = this.scrollContainer.querySelector('tbody');
    const newLength = tbodyElement.children.length;
    if (newLength === previousLength) {
      setTimeout(() => this.scrollToBottom(previousLength), 50) 
      return;
    }

    setTimeout(() => {
      const lastItem = this.scrollContainer.lastElementChild;
      if (lastItem) {
        lastItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 200);
  }

  public removeRow = (index: number) => {
    this.model.data.activityConfigurationMapping.splice(index, 1);
  }
}
