import { computedFrom, autoinject, LogManager } from 'aurelia-framework';
import { RoutingStatusService } from './routing-status-service';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore } from 'zailab.common';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
import { UserSessionModel } from '../../../../_common/stores/sessionmodels/user-session-model';
import { visibilityAware, ZIVisibilityAware } from '../../../../_common/services/visibilityawareness/visibility-aware';
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';
import { DisplayMessageService } from '../../../../_common/services/display.message.service';

const logger = LogManager.getLogger('RoutingStatus');
export const ROUTING_STATUS = {
  READY: 'Ready', NOT_READY: 'Not Ready'
};

@autoinject()
@visibilityAware
export class RoutingStatus implements ZIVisibilityAware {

  private routingStatus: string = ROUTING_STATUS.NOT_READY;
  private agentIsReady: boolean = false;
  private oplog: ZIOplog;

  public disabled: boolean = false;
  public embeddedWebphoneInitialized: boolean = null;
  public embeddedWebphoneValidMediaDevices: boolean = true;
  public embeddedWebphoneEnabled: boolean = null;

  private subscriptions = [];

  constructor(
    private routingStatusService: RoutingStatusService,
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    private featureFlagService: FeatureFlagService
  ) { }

  public activate(): void {
    
    if (this.isAgent) {
      this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
        .then(embeddedWebphoneEnabled => {
          this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
          if (embeddedWebphoneEnabled && this.embeddedWebphoneInitialized === null) {
            this.embeddedWebphoneInitialized = false;
          }
        });
    }
    this.subscriptions.push(this.eventAggregator.subscribe('AWAIT-EMBEDDED-WEBPHONE-INITIALIZATION', () => {
      this.embeddedWebphoneInitialized = false;
    }));
    this.subscriptions.push(this.eventAggregator.subscribe('EMBEDDED-WEBPHONE-INITIALIZED', () => {
      this.embeddedWebphoneInitialized = true;
    }));
    this.subscriptions.push(this.eventAggregator.subscribe('EMBEDDED-WEBPHONE-MISSING-DEVICE', () => {
      this.embeddedWebphoneValidMediaDevices = false;
    }));
    this.subscriptions.push(this.eventAggregator.subscribe('EMBEDDED-WEBPHONE-DEVICES-FOUND', () => {
      this.embeddedWebphoneValidMediaDevices = true;
    }));
    this.subscriptions.push(this.eventAggregator.subscribe('TRIGGER-RECONNECT-REQUESTS', () => {
      this.retrieveRoutingStatus();
    }));
  }

  public attached(): void {
    this.initialiseOplog();
    this.retrieveRoutingStatus();
  }

  private initialiseOplog(): void {
    this.routingStatusService.initialiseRoutingStatusOplog(this.user.memberId).then(oplog => this.subscribeToOplogChanges(oplog));
  }

  private subscribeToOplogChanges(oplog: ZIOplog): void {
    this.oplog = oplog;
    this.oplog.on('insert', data => this.handleRoutingStatusChanged(data));
    this.oplog.on('update', data => this.handleRoutingStatusChanged(data));
  }

  private retrieveRoutingStatus(): void {
    this.routingStatusService.retrieveRoutingStatus(this.user.memberId).then(data => {
      this.handleRoutingStatusChanged(data);
    }, error => {
      logger.info('routingStatus > error = ', error);
    });
  }

  private handleRoutingStatusChanged(data: any): void {
    this.routingStatus = data.routingStatus;
    this.agentIsReady = this.routingStatus === ROUTING_STATUS.READY;
    this.eventAggregator.publish('current.agent.status', this.routingStatus);

  }

  public changeRoutingStatus(): void {
    //this.eventAggregator.publish('app:loader:show');
    if (this.agentIsReady) {
      this.routingStatusService.setRoutingStatusToNotReady(this.user.memberId);
      this.routingStatus = ROUTING_STATUS.NOT_READY;
      this.agentIsReady = false;
    } else {
      if (this.embeddedWebphoneEnabled) {
        this.setReady();
        return;
      }

      this.featureFlagService.isEnabled('onDutyOpenWebphone')
        .then((onDutyOpenWebphoneEnabled) => {
          if (onDutyOpenWebphoneEnabled) {
            this.eventAggregator.publish('current.agent.status.ready', this.routingStatus);
          } else {
            this.setReady();
          }
        });
    }
  }

  private setReady(): void {
    this.routingStatusService.setRoutingStatusToReady(this.user.memberId);
    this.routingStatus = ROUTING_STATUS.READY;
    this.agentIsReady = true;
  }

  private detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    this.subscriptions.forEach(subscription => subscription.dispose());
  }

  @computedFrom('agentIsReady')
  get agentReady(): boolean {
    return this.agentIsReady;
  }

  @computedFrom('sessionStore.get.user')
  get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('user') get hasAgentRole(): boolean {
    return this.user ? this.user.hasAgentRole : false;
  }

  becameInvisible(): void {
    logger.debug('KS >>> Became invisible');
  }

  becameVisible(): void {
    this.retrieveRoutingStatus();
  }

  @computedFrom('user.hasAgentRole')
  public get isAgent(): boolean {
    if (this.user) {
      return this.user.hasAgentRole;
    }
    return false;
  }
}
