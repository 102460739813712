import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/**
 */
import { SessionStore } from 'zailab.common';
import { MembersService } from '../members-service';
/**
 */
const logger = LogManager.getLogger('Members Widget');
/**
 */
@inject(Router, MembersService, SessionStore)
export class MembersDashboardWidget {
  actions = ['view'];
  ready = false;
  members = {};

  constructor(router, membersService, sessionStore) {
    this.router = router;
    this.membersService = membersService;
    this.sessionStore = sessionStore;
  }

  activate(model) {
    this.intitisaliseMemberState();
  }

  detached() {
    if(this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  intitisaliseMemberState() {

    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.intitisaliseMemberState();
      }, 500);
      return;
    }

    this.membersService.initialiseStatsOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', response => {
        if (response) {
          this.retrieveMembersStats();
        }
      });
      this.oplog.on('update', response => {
        if (response) {
          this.retrieveMembersStats();
        }
      });
    });

    this.retrieveMembersStats();
  }

  retrieveMembersStats() {
    this.membersService.retrieveMembersStats().then(
      response => {

        this.members = response.membersByRole;
        this.ready = true;
      },
      error => {
        logger.info('Could not retrieve Members Stats.', error);
      }
    );
  }

  view() {
    this.router.navigate('members');
  }
}
