import { inject, LogManager, computedFrom, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { NumbersService } from '../numbers-service';
import { SessionStore, MESSAGE_EVENTS } from 'zailab.common';

/*
 * */
const logger = LogManager.getLogger('PurchaseNumbers');
/*
 * */
@inject(DialogController, NumbersService, SessionStore, EventAggregator)
export class PurchaseNumbers {
  purchaseStages = [
    { id: 1, title: 'Purchase Number', url: PLATFORM.moduleName('features/finance/account/numbers/purchasenumbers/criteria/criteria') },
    { id: 2, title: 'Purchase Number', url: PLATFORM.moduleName('features/finance/account/numbers/purchasenumbers/confirmnumbers/confirm-numbers') }
  ];
  selectedViewModel = this.purchaseStages[0].url;
  selectedViewTitle = this.purchaseStages[0].title;
  selectedViewId = this.purchaseStages[0].id;

  selectedType;
  selectedRegion;
  resetDropdown = false;
  numberStartsWith;
  numberPrefix;
  selectedNumber;
  canPurchase = false;
  isProcessing;
  countryCode;

  constructor(dialogController, numbersService, sessionStore, eventAggregator) {
    this.dialogController = dialogController;
    this.numbersService = numbersService;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
    this.subscribeToPurchaseNumberError();

  }

  @computedFrom('selectedType', 'selectedRegion')
  get canSearch() {
    if (this.selectedType && this.selectedRegion && this.selectedType.type === 'VOICE' && this.selectedRegion.region) {
      return true;
    }
    if (this.selectedType && this.selectedRegion && this.selectedType.type === 'SMS' && this.selectedRegion.region) {
      return true;
    }
    if (this.selectedType && this.selectedRegion && this.selectedType.type === 'TOLL-FREE' && this.selectedRegion.region) {
      return true;
    }
    return false;
  }

  cancelAndNavigateToDisplayNumbers() {
    this.resetDropdown = true;
    this.dialogController.cancel();
  };

  searchAvailableNumbers() {
    this.selectedViewModel = this.purchaseStages[1].url;
    this.selectedViewTitle = this.purchaseStages[1].title;
    this.selectedViewId = this.purchaseStages[1].id;
  };

  navigateBackToPurchaseNumbersCriteria() {
    this.selectedViewModel = this.purchaseStages[0].url;
    this.selectedViewTitle = this.purchaseStages[0].title;
    this.selectedViewId = this.purchaseStages[0].id;
    this.selectedNumber = null;
  };

  purchaseNumbers() {
    let numberId = this.selectedNumber.numberId;
    let country = this.selectedRegion.regionCode;
    let numberType = this.selectedNumber.numberType;
    let provider = this.selectedNumber.provider;
    let externalReference = this.selectedNumber.externalReference;
    let telephoneNumber = this.selectedNumber.telephoneNumber;

    this.isProcessing = true;

    this.numbersService.rentNumber(numberId, country, numberType, provider, externalReference, telephoneNumber).then((response) => {
      this.resetDropdown = true;
      this.isProcessing = false;
      this.dialogController.close();
    }, (error) => {
      this.isProcessing = false;
    });
  }

  subscribeToPurchaseNumberError() {
    this.eventAggregator.subscribe('purchase.number.error', () => {
      this.purchaseNumbers.navigateBackToPurchaseNumbersCriteria();
    });

    this.errorSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', (response) => {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'Error purchasing number.');
      this.isProcessing = false;
    });
  }

  unSubscribeFromValidationErrorsReceived() {
    this.errorSubscription.dispose();
  }

  detached() {
    this.unSubscribeFromValidationErrorsReceived();
  }
}
