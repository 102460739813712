import { autoinject, LogManager } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('IntegrationsModule');

declare const PLATFORM;

@autoinject
export class Assistants {
  constructor(private router: Router) {}

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: '',
        name: '',
        moduleId: PLATFORM.moduleName(
          './list/assistants-list'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Assistants',
        settings: { access: ['Administrator'] },
      },
      {
        route: ':assistantId',
        name: 'edit-assistant',
        moduleId: PLATFORM.moduleName(
          './edit-assistant/edit-assistant'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Edit Assistant',
        settings: { access: ['Administrator'] },
      },
      {
        route: 'audit',
        name: 'audit',
        moduleId: PLATFORM.moduleName(
          './assistant-audit/assistant-audit'
        ),
        isTwelveColumnRoute: true,
        nav: false,
        title: 'Assistant Audit',
        settings: { access: ['Administrator'] },
      },
    ]);
    this.router = router;
  }
}
