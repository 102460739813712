import { autoinject, LogManager, customElement, bindable } from 'aurelia-framework';
import { InteractionModel } from '../../interaction-model';

const logger = LogManager.getLogger('ReminderInteractionCard');

@customElement('z-reminder-interaction-card')
@autoinject
export class ReminderInteractionCard {
  @bindable private interaction: InteractionModel;
}
