/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
import $ from 'jquery';
/*
 */
const logger = LogManager.getLogger('ZUtilityInput');
/*
 */
@customElement('z-utility-input')
export class ZUtilityInput {

  @bindable position;
  @bindable spaced = true;
  @bindable placeholder;
  @bindable value;
  @bindable autofocus = true;
  @bindable fullwidth;
  input;

  attached() {
    if (this.autofocus) {
      $(this.input).focus();
    }
  }

}
