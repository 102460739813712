import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ZPropV2');

@autoinject()
@customElement('z-proportional-container-v2')

export class ZProportionalContainerV2 {

  @bindable private selected:boolean = false;
  @bindable private highlight:boolean = false;
  @bindable private warning:boolean = false;

  @bindable({attribute: 'is-deleting'}) private isDeleting:boolean = false;
  @bindable({attribute: 'proportion'}) private proportion: number = 60;
  @bindable({attribute: 'is-hoverable'}) private hoverable: boolean = false;
  @bindable({attribute: 'has-info-icon'}) private hasInfoIcon: boolean = false;
  @bindable({attribute: 'has-loader'}) private hasLoader: boolean = false;
  @bindable({attribute: 'is-clickable'}) private isClickable: boolean = false;
  @bindable({attribute: 'has-submenu'}) private hasSubmenu: boolean = false;

  constructor() {

  }

  attached(): void {

  }
}