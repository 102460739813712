import { inject, LogManager, PLATFORM } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import {Router} from 'aurelia-router';
/*
*/
import { SessionStore } from 'zailab.common';
import OrganisationService from '../organisation-service';
import { ChangeProfilePicture } from "../../../user/passport/changeprofilepicture/change-profile-picture-dialog";
import { RenameOrganisationDialog } from "../renameorganisation/rename-organisation";
import { SAMLConfigurationDialog } from "../saml/saml-configuration";
import {LdapSyncDialog} from "../ldapsync/ldap-sync";
import {EncryptTools} from "../../../../_common/tools/encrypt.tools";
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';
/*
*/
const logger = LogManager.getLogger('ManageOrganisation');
/*
*/
@inject(DialogService, SessionStore, OrganisationService, Router, FeatureFlagService, ZaiFileLoader)
export class ManageOrganisation {

  organisationName = null;
  pictureId = null;
  picturePath = '';
  defaultPicture;
  url = null;
  ready = false;
  samlEnabled = false;
  workforceManagementVerint = false;
  interactionAnalyticsEnabled = false;
  verintSpeechAnalyticsConfig = {};
  path = '';
  metadataUrl = '';
  idPRedirectUrl = '';
  ldapSync = {};
  ldapGroups = {};
  integrationToken = '';
  ldapPassword = '';

  constructor(dialogService, sessionStore, organisationService, router, featureFlagService, zaiFileLoader) {
    this.sessionStore = sessionStore;
    this.organisationService = organisationService;
    this.dialogService = dialogService;
    this.router = router;
    this.featureFlagService = featureFlagService;
    this.zaiFileLoader = zaiFileLoader;
    this.defaultPicture = this.zaiFileLoader.getFile('create_org-placeholder');
  }

  activate() {
    
    this.featureFlagService.isEnabled('workforceManagementVerint')
    .then((workforceManagementVerint) => {
      this.workforceManagementVerint = workforceManagementVerint;
    });
    this.featureFlagService.isEnabled('interactionAnalytics')
    .then((interactionAnalyticsEnabled) => {
      this.interactionAnalyticsEnabled = interactionAnalyticsEnabled;
    });

    this.retrieveOrganisationInfo();
    this.ldapSyncEnabled = false;
  }

  retrieveOrganisationInfo() {
    this.organisationService.retrieveOrganisationInfo().then((response) => {
      this.organisationName = response.organisationName;
      this.pictureId = this.sessionStore.get.organisation.organisationId;
      this.picturePath = response.profilePicture;
      this.ldapSync = response.ldapConnectorInformation;
      if (response.ldapConnectorInformation) {
        this.ldapPassword = this.ldapSync.password;
      }
      this.integrationToken = response.integrationToken;
      this.ready = true;
      this.samlEnabled = response.samlEnabled;
      this.metadataUrl = response.metadataUrl;
      this.idPRedirectUrl = response.idPRedirectUrl;
      this.path = response.path ? response.path : response.organisationName;
    }, (error) => {
      logger.warn('Could not retrieve Organisation information.', error);
    });
  }

  changeProfilePicture() {
    this.dialogService.open({ viewModel: ChangeProfilePicture, model: { pictureId: this.pictureId, defaultPicture: this.defaultPicture } }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.url = response.output;
        let rawBase64 = response.output.split('base64,')[1];
        this.organisationService.updateProfilePicture(rawBase64, this.pictureId);
      }
    });
  }

  changeOrganisationName() {
    this.dialogService.open({ viewModel: RenameOrganisationDialog, model: this.organisationName }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.organisationName = response.output;
        this.organisationService.changeOrganisationName(this.sessionStore.get.organisation.organisationId, this.organisationName);
      }
    });
  }

  changeSamlSettings(){
    let data = {
      samlEnabled: this.samlEnabled,
      path: this.path,
      metadataUrl: this.metadataUrl,
      idPRedirectUrl: this.idPRedirectUrl,
      organisationName: this.organisationName
    };
    this.dialogService.open({ viewModel: SAMLConfigurationDialog, model: data }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.samlEnabled = response.output.samlEnabled;
        this.path = response.output.path;
        this.metadataUrl = response.output.metadataUrl;
        this.idPRedirectUrl = response.output.idPRedirectUrl;
        this.organisationService.setAuthenticationConfiguration(this.sessionStore.get.organisation.organisationId, this.samlEnabled, this.path, this.metadataUrl, this.idPRedirectUrl);
      }
    });
  }

  toggleLdapSyncEnabled() {
    this.ldapSync.enabled = !this.ldapSync.enabled;
    this.updateLdapSync();
  }

  changeLdapSync() {
    this.dialogService.open({
      viewModel: LdapSyncDialog,
      model: this.ldapSync
    }).whenClosed((response) => {
      if (response.wasCancelled) {
        return;
      }
      this.ldapSync = response.output;
      this.updateLdapSync();
    });
  }

  updateLdapSync() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.ldapSync.organisationId = organisationId;
    this.ldapSync.integrationToken = this.integrationToken;
    if (this.ldapSync.password !== this.ldapPassword) {
      this.ldapSync.password = EncryptTools.encrypt(this.ldapSync.password);
    }
    this.ldapPassword = this.ldapSync.password;
    this.organisationService.updateLdapConnectionInfo(this.ldapSync, organisationId).then(
      (result) => {
      },
      (error) => {
        logger.error('Unable to update information ', error);
      });
  }

  manageLdapGroups() {
    this.router.navigate('manageldapgroups');
  }



}