import moment from 'moment-timezone';
import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('OrderByTimestampValueConverter');

export class OrderByTimestampValueConverter {
  public toView(items: any, direction:string = 'forward'): any {
    let tempList: any[] = [];
    if (!items || items.length === 0) {
      tempList = [];
    }
    else {
      tempList = items.sort((x, y) => {
        return moment.utc(x.timestamp).diff(moment.utc(y.timestamp));
      });
    }

    return direction === 'reverse' ? tempList.reverse() : tempList;
  }
}
