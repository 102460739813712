import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { WebSocket } from '../../../../_common/services/websocket';
import { UserRegistrationSettings } from '../user-registration-settings';

const logger = LogManager.getLogger('JoinService');

@inject(WebSocket, HttpClient)
export class JoinService {

  constructor(webSocket, httpClient) {
    this.webSocket = webSocket;
    this.httpClient = httpClient;
    this._registerEvents();
  }

  _registerEvents() {
    // this._registerEvent('com.zailab.user.userregistration.api.events.UserRegistrationRejectedEvent', () => this.rejectRegistration());
    // this._registerEvent('com.zailab.user.userregistration.api.events.UserRegistrationAcceptedEvent', () => this.acceptRegistration());
  }

  _registerEvent(name, callback) {
    this.webSocket.subscribe({ name, callback });
  }

  submit(email, organisationName, firstName, surname, contactNumber) {
    return this.httpClient.createRequest('v1/user/user-registrations/submit')
      .asPost()
      .withContent({ email, organisationName, firstName, surname, contactNumber })
      .withHeader('Content-Type', 'application/json')
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }
}

