import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
* */
import { SessionStore } from 'zailab.common';
import { MailboxService } from '../mailbox-service';
import { HUD_ANIMATOR_EVENTS } from '../../../../hud/hud.animator.events';
/*
 * */
const logger = LogManager.getLogger('MailboxMessagesPlayback');
/*
 * */
@inject(DialogController, SessionStore, MailboxService, EventAggregator)
export class MailboxMessagesPlayback {

  message;
  retrievingRecording;
  recordingRetrieved;
  recordingFailed;
  dialingDebounced = false;
  markedAsRead = false;

  constructor(dialogController, sessionStore, mailboxService, eventAggregator) {

    this.controller = dialogController;
    this.sessionStore = sessionStore;
    this.mailboxService = mailboxService;

    eventAggregator.subscribe(HUD_ANIMATOR_EVENTS.PROMPT_OPENED, () => { // close this prompt when another prompt is opened
      if (this.controller) {
        this.controller.close(false, new Error('Another prompt opened'));
      }
    });

    eventAggregator.subscribe('router:navigation:processing', () => { // close this prompt when navigating
      if (this.controller) {
        this.controller.close(false, new Error('Router navigated'));
      }
    });
  }

  /**
   * gets called when the dialog view model is activated.
   * @param message
   */
  activate(message) {
    this.message = message;
    this.markedAsRead = this.message.played;
  }

  /**
   * closes the dialog
   */
  cancel() {
    this.controller.cancel();
  }

  /**
   * starts a sip call
   */
  dial() {
    if (this.dialingDebounced) {
      return;
    }
    this.dialingDebounced = true;
    this.mailboxService.dial(this.sessionStore.get.user.email, this.message.from);
    setTimeout(() => this.dialingDebounced = false, 3000);
  }

  /**
   * retrieves the recording for selected mailbox
   */
  retrieveRecording() {

    let organisationId = this.sessionStore.get.organisation.organisationId;
    let mailboxId = this.message.mailboxId;

    this.retrievingRecording = true;

    this.mailboxService.selectMessage(this.message, mailboxId, organisationId).then((audioSource) => {

      this.message.audioSource = audioSource;
      this.retrievingRecording = false;
      this.recordingRetrieved = true;
      this.markedAsRead = true;
    }, () => {
      this.retrievingRecording = false;
      this.recordingRetrieved = false;
      this.recordingFailed = false;
    });
  }

  markAsRead() {
    if (!this.markedAsRead) {
      this.markedAsRead = true;
      this.mailboxService.postMessageHasBeenPlayed(this.message)
        .catch(() => this.markedAsRead = false);
    }
  }
}
