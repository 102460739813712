import {inject, LogManager, customElement, containerless, observable, bindable, computedFrom} from 'aurelia-framework';
/*
 */
import {Event, Clone, ArrayTools} from 'zailab.common'
/*
 */
const logger = LogManager.getLogger('ZCondensedList');
/*
 */
@containerless()
@inject(Element)
@customElement('z-condensed-list')
export default class {

  @bindable @observable list;
  @bindable({attribute: 'items-per-row'}) itemsperrow;
  @bindable({attribute: 'disable-save'}) disableSave;
  @bindable subtitle;
  ready = false;

  constructor(element) {
    this.element = element;
  }

  get itemsPerRow() {
    return this.itemsperrow || 6;
  }

  @computedFrom('list.items')
  get selectedItemsConcatenated() {

    if(!this.list || !this.list.items || !this.list.items.length) {
      return 'No items selected.';
    }

    let _items = Clone.list(this.list.items, this.list.uniqueIdentifier);
    _items = ArrayTools.sort(_items, this.list.displayId);
    let _itemsString = '';

    for(let item of _items) {
      if(item.isSelected) {

        _itemsString += item[this.list.displayId];
        _itemsString += ', ';
      }
    }
    _itemsString = _itemsString.substring(0, _itemsString.length - 2);

    if(_itemsString.length === 0) {
      return 'No items selected.';
    }
    return _itemsString;
  }

  listChanged(newValue) {
    if(newValue && newValue.items) {
      this.ready = true;
    }
  }

  selectItem(item) {
    if(this.list.select) {
      this.list.select(item, this.list.items, this.list.required, this.element);
      new Event(this.element, 'select', { list: this.list.items, item: item });
    }
  }

  acceptEdit() {
    new Event(this.element, 'updateitems', {items: Clone.list(this.list.items, this.list.displayId), originalItems: this.list.clonedItems});
    this.list.toggleEdit();
  }

  cancelEdit() {
    this.list.items = Clone.list(this.list.clonedItems, this.list.displayId);
    this.list.toggleEdit();
    new Event(this.element, 'canceledit');
  }
}
