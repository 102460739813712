import { ReportMenuCategory } from './report-menu-category';
import { ReportConfigBuilder } from './report-config-builder';
import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ReportMenuConfig');

export class ReportMenuConfig {
  public categories: ReportMenuCategory[] = [];
  private archivedReportsEnabled: boolean;

  constructor(archivedReportsEnabled: boolean) {
    this.archivedReportsEnabled = archivedReportsEnabled;
    this.configureCategories();
  }

  private configureCategories(): void {
    this.categories.push(this.getNewAgentReportsCategory());
    this.categories.push(this.getNewWaitingRoomReportsCategory());
    this.categories.push(this.getNewInteractionsCategory());

    this.categories.push(this.getCustomerSentimentReportsCategory());
    this.categories.push(this.getPeakLicenceCategory());
    this.categories.push(this.getQualityAssessmentReportsCategory());
    // this.categories.push(this.getConversationsReportsCategory());
    // this.categories.push(this.getCampaignsReportsCategory());

    if (this.archivedReportsEnabled) {
      this.archiveOldReports();
    }
  }

  private archiveOldReports(): void {
    let archivedCategories: ReportMenuCategory[] = [];
    archivedCategories.push(this.getAgentReportsCategory());
    archivedCategories.push(this.getWaitingRoomReportsCategory());
    archivedCategories.push(this.getInteractionsReportsCategory());
    let categoriesArchived: ReportMenuCategory[] = archivedCategories.filter(
      category => category.archived
    );
    let archivedCategory = new ReportMenuCategory('Archived', false);

    let reportsFromArchivedCategory = categoriesArchived.map(
      category => category.reports
    );

    let archivedReports = [];
    reportsFromArchivedCategory.forEach(reportsList => {
      archivedReports.push(...reportsList);
    });

    archivedCategory.reports = archivedReports;
    this.categories.push(archivedCategory);
  }

  private getNewAgentReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Agents', true);
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Call Activity')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how a specific agent is spending their time on calls.'
        )
        .withSelector('agent-activity-calls-v3')
        .withCategory('call-activity')
        .withReportName('Call Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Email Activity')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how a specific agent is spending their time with emails.'
        )
        .withSelector('agent-activity-emails-v3')
        .withCategory('messages-activity')
        .withChannelParam('email')
        .withReportName('Email Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('SMS Activity')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how a specific agent is spending their time with SMSs.'
        )
        .withSelector('agent-activity-sms-v3')
        .withCategory('messages-activity')
        .withChannelParam('sms')
        .withReportName('SMS Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('WebChat Activity')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how a specific agent is spending their time with WebChats.'
        )
        .withSelector('agent-activity-webchat-v3')
        .withCategory('messages-activity')
        .withChannelParam('Chat')
        .withReportName('WebChat Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Instant Message Activity')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how a specific agent is spending their time with Instant Messages.'
        )
        .withSelector('agent-activity-webchat-v3')
        .withCategory('messages-activity')
        .withChannelParam('INSTANT_MESSAGE')
        .withReportName('Instant Message Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Presence')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how well a specific agent is managing their time.'
        )
        .withSelector('agent-activity-presence-v3')
        .withCategory('agent-presence')
        .withReportName('Presence')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent Activity')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See what activities an agent performed during the day'
        )
        .withSelector('agent-activity-detailed')
        .withCategory('agent-activity-detailed')
        .withReportName('Agent Activity Detailed')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Login Logout')
        .withReportType('Individual Agent')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See how often a specific agent logs in and logs out.'
        )
        .withSelector('agent-activity-login-v3')
        .withCategory('agent-login-logout-details')
        .withReportName('Login Logout')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent Call Comparison')
        .withReportType('Compare Agents')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          "Find out which agents aren't performing well with calls."
        )
        .withSelector('compare-agents-calls-v3')
        .withCategory('agent-calls-comparison')
        .withReportName('All Agents Call Comparison')
        .showServices(false)
        .showTeams(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent Email Comparison')
        .withReportType('Compare Agents')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          "Find out which agents aren't performing well with emails."
        )
        .withSelector('compare-agents-emails-v3')
        .withCategory('agent-messages-comparison')
        .withChannelParam('email')
        .withReportName('All Agents Email Comparison')
        .showServices(false)
        .showTeams(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent SMS Comparison')
        .withReportType('Compare Agents')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          "Find out which agents aren't performing well with SMSs."
        )
        .withSelector('compare-agents-sms-v3')
        .withCategory('agent-messages-comparison')
        .withChannelParam('sms')
        .withReportName('All Agents SMS Comparison')
        .showServices(false)
        .showTeams(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent WebChat Comparison')
        .withReportType('Compare Agents')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          "Find out which agents aren't performing well with WebChats."
        )
        .withSelector('compare-agents-webchat-v3')
        .withCategory('agent-messages-comparison')
        .withChannelParam('Chat')
        .withReportName('All Agents WebChat Comparison')
        .showServices(false)
        .showTeams(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent Instant Message Comparison')
        .withReportType('Compare Agents')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          "Find out which agents aren't performing well with Instant Messages."
        )
        .withSelector('compare-agents-webchat-v3')
        .withCategory('agent-messages-comparison')
        .withChannelParam('INSTANT_MESSAGE')
        .withReportName('All Agents Instant Message Comparison')
        .showServices(false)
        .showTeams(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Agent Presence Comparison')
        .withReportType('Compare Agents')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'Find out which agents are mismanaging their time.'
        )
        .withSelector('compare-agents-presence-v3')
        .withCategory('agent-presence-comparison')
        .withReportName('All Agents Presence Comparison')
        .showServices(false)
        .showTeams(true)
        .build()
    );

    return reportCategory;
  }

  private getNewWaitingRoomReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Waiting Room');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Call Distribution')
        .withReportType('Per Day')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See whether calls are being answered adequately from the waiting room.'
        )
        .withSelector('waiting-room-calls-v3')
        .withCategory('calls-distribution')
        .withReportName('Call Distribution')
        .withIcon('hourglass')
        .withOnlySpecificService(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Email Distribution')
        .withReportType('Per Day')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See whether emails are being answered adequately from the waiting room.'
        )
        .withSelector('waiting-room-emails-v3')
        .withCategory('messages-distribution')
        .withReportName('Email Distribution')
        .withIcon('hourglass')
        .withChannelParam('email')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('WebChat Distribution')
        .withReportType('Per Day')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See whether WebChats are being answered adequately from the waiting room.'
        )
        .withSelector('waiting-room-webchat-v3')
        .withCategory('messages-distribution')
        .withReportName('WebChat Distribution')
        .withIcon('hourglass')
        .withChannelParam('Chat')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Instant Message Distribution')
        .withReportType('Per Day')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See whether Instant Messages are being answered adequately from the waiting room.'
        )
        .withSelector('waiting-room-im-v3')
        .withCategory('messages-distribution')
        .withReportName('Instant Message Distribution')
        .withIcon('hourglass')
        .withChannelParam('INSTANT_MESSAGE')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('SMS Distribution')
        .withReportType('Per Day')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See whether SMSs are being answered adequately from the waiting room.'
        )
        .withSelector('waiting-room-sms-v3')
        .withCategory('messages-distribution')
        .withReportName('SMS Distribution')
        .withIcon('hourglass')
        .withChannelParam('sms')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Call Distribution')
        .withReportType('Per Work Type')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See if there are any types of calls not being responded to.'
        )
        .withSelector('compare-work-types-calls-v3')
        .withCategory('calls-distribution-worktypes')
        .withReportName('Call Distribution Work Types')
        .withIcon('briefcase')
        .withOnlySpecificService(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Email Distribution')
        .withReportType('Per Work Type')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See if there are any types of emails not being responded to.'
        )
        .withSelector('compare-work-types-emails-v3')
        .withCategory('messages-distribution-worktypes')
        .withReportName('Email Distribution Work Types')
        .withIcon('briefcase')
        .withOnlySpecificService(true)
        .withChannelParam('email')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('SMS Distribution')
        .withReportType('Per Work Type')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See if there are any types of SMSs not being responded to.'
        )
        .withSelector('compare-work-types-sms-v3')
        .withCategory('messages-distribution-worktypes')
        .withReportName('SMS Distribution Work Types')
        .withIcon('briefcase')
        .withOnlySpecificService(true)
        .withChannelParam('sms')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('WebChat Distribution')
        .withReportType('Per Work Type')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See if there are any types of WebChats not being responded to.'
        )
        .withSelector('compare-work-types-webchat-v3')
        .withCategory('messages-distribution-worktypes')
        .withReportName('WebChat Distribution Work Types')
        .withIcon('briefcase')
        .withOnlySpecificService(true)
        .withChannelParam('Chat')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Instant Message Distribution')
        .withReportType('Per Work Type')
        .withDatePeriod('Contact Centre')
        .withReportDescription(
          'See if there are any types of Instant Messages not being responded to.'
        )
        .withSelector('compare-work-types-im-v3')
        .withCategory('messages-distribution-worktypes')
        .withReportName('Instant Message Distribution Work Types')
        .withIcon('briefcase')
        .withOnlySpecificService(true)
        .withChannelParam('INSTANT_MESSAGE')
        .build()
    );

    return reportCategory;
  }

  private getNewInteractionsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Interactions');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Contact Centre Calls')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of every call routed through a flow.'
        )
        .withSelector('interactions-v3')
        .withCategory('contact-centre-calls')
        .withIcon('call-interactions')
        .withReportName('Contact Centre Calls')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Office Calls')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of calls made between back-office staff.'
        )
        .withSelector('interactions-office-calls-v3')
        .withCategory('office-calls')
        .withIcon('headphones')
        .withReportName('Office Calls')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Internal Calls')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of calls made between members.'
        )
        .withSelector('interactions-internal-calls-v3')
        .withCategory('internal-calls')
        .withIcon('call-internal')
        .withReportName('Internal Calls')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Private Calls')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of private calls made from your centre.'
        )
        .withSelector('interactions-private-calls-v3')
        .withCategory('private-calls')
        .withIcon('phone-no-response')
        .withReportName('Private Calls')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Contact Centre SMSs')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of every SMS routed through a flow.'
        )
        .withSelector('interactions-sms-v3')
        .withCategory('contact-centre-messages')
        .withChannelParam('sms')
        .withIcon('comments')
        .withReportName('Contact Centre SMSs')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Contact Centre WebChats')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of every WebChat session routed through a flow.'
        )
        .withSelector('interactions-webchat-v3')
        .withCategory('contact-centre-messages')
        .withChannelParam('Chat')
        .withIcon('comments')
        .withReportName('Contact Centre WebChats')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Contact Centre Instant Messages')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of every Instant Message session routed through a flow.'
        )
        .withSelector('interactions-im-v3')
        .withCategory('contact-centre-messages')
        .withChannelParam('INSTANT_MESSAGE')
        .withIcon('comments')
        .withReportName('Contact Centre Instant Messages')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Contact Centre Emails')
        .withReportType('Per Interaction')
        .withReportDescription(
          'Get a detailed account of every email routed through a flow.'
        )
        .withSelector('interactions-email-v3')
        .withCategory('contact-centre-messages')
        .withChannelParam('email')
        .withIcon('envelope-closed')
        .withReportName('Contact Centre Emails')
        .showServices(false)
        .build()
    );

    return reportCategory;
  }

  private getAgentReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Agents', true);
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withReportType('Calls')
        .withDatePeriod('monthly')
        .withLabel('Agent')
        .withSelector('agent-calls-monthly')
        .withCategory('agent-calls-monthly')
        .withReportName('Agent Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withReportType('Calls')
        .withDatePeriod('monthly')
        .withLabel('Compare Agents')
        .withSelector('compare-agents-calls-monthly')
        .withCategory('compare-agents-calls-monthly')
        .withReportName('Agent Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withReportType('Calls')
        .withDatePeriod('daily')
        .withLabel('Compare Agents')
        .withSelector('compare-agents-calls-daily')
        .withCategory('compare-agents-calls-daily')
        .withReportName('Agent Activity')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withReportType('Status')
        .withDatePeriod('monthly')
        .withLabel('Agent')
        .withSelector('agent-status-monthly')
        .withCategory('agent-status-monthly')
        .withReportName('Agent Activity')
        .showServices(false)
        .showTeams(true)
        .showMembers(true)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withReportType('Status')
        .withDatePeriod('monthly')
        .withLabel('Compare Agents')
        .withSelector('compare-agents-status-monthly')
        .withCategory('compare-agents-status-monthly')
        .withReportName('Agent Activity')
        .showServices(false)
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withReportType('Status')
        .withDatePeriod('daily')
        .withLabel('Compare Agents')
        .withSelector('compare-agents-status-daily')
        .withCategory('compare-agents-status-daily')
        .withReportName('Agent Activity')
        .showServices(false)
        .build()
    );

    return reportCategory;
  }

  private getWaitingRoomReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Waiting Room', true);
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Waiting Room')
        .withDatePeriod('monthly')
        .withSelector('waiting-room-calls-monthly')
        .withCategory('waiting-room-calls-monthly')
        .withReportName('Waiting Room')
        .withIcon('hourglass')
        .build()
    );

    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Compare Work Types')
        .withDatePeriod('monthly')
        .withSelector('compare-work-types-calls-monthly')
        .withCategory('waiting-room-calls-monthly')
        .withReportName('Waiting Room')
        .withIcon('hourglass')
        .build()
    );

    return reportCategory;
  }

  private getInteractionsReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Interactions', true);
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Interactions')
        .withDatePeriod('monthly')
        .withSelector('interactions-monthly')
        .withCategory('interactions-monthly')
        .withReportName('Interactions')
        .withIcon('call-interactions')
        .build()
    );

    return reportCategory;
  }

  private getQualityAssessmentReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Quality Assessment');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Teams')
        .withDatePeriod('')
        .withSelector('teams-monthly')
        .withCategory('teams-monthly')
        .withIcon('members')
        .build()
    );
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Business Partner')
        .withDatePeriod('')
        .withSelector('business-partners-monthly')
        .withCategory('business-partners-monthly')
        .withIcon('worktypes')
        .build()
    );

    return reportCategory;
  }

  private getCustomerSentimentReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Customer Sentiment');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Agent Survey Rating')
        .withDatePeriod('monthly')
        .withSelector('agent-survey-rating-monthly')
        .withCategory('agent-survey-rating-monthly')
        .withReportName('Agent Survey Rating')
        .withIcon('surveys')
        .build()
    );

    // reportCategory.withReport(
    //   new ReportConfigBuilder()
    //     .withLabel('General Survey Rating')
    //     .withDatePeriod('monthly')
    //     .withSelector('general-survey-rating-monthly')
    //     .withCategory('general-survey-rating-monthly')
    //     .withReportName('General Survey Rating')
    //     .withIcon('surveys')
    //     .build()
    // );

    return reportCategory;
  }

  private getPeakLicenceCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Peak Licence');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withNewReport(true)
        .withLabel('Peak Concurrent Licence')
        .withReportType('')
        .withDatePeriod('')
        .withReportDescription(
          'Find out peak concurrent user usage.'
        )
        .withSelector('peak-concurrent-licence-report')
        .withCategory('peak-concurrent-licence-report')
        .withReportName('Peak Licence')
        .showServices(false)
        .showRoles(true)
        .showTimeBuckets(true)
        .showBusinessPartners(true)
        .showTeams(false)
        .build()
    );

    return reportCategory;
  }

  private getConversationsReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Conversations');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Conversations')
        .withDatePeriod('monthly')
        .withSelector('conversations-monthly')
        .withCategory('conversations-monthly')
        .withReportName('Conversations')
        .withIcon('comments')
        .build()
    );

    return reportCategory;
  }

  private getCampaignsReportsCategory(): ReportMenuCategory {
    let reportCategory = new ReportMenuCategory('Campaigns');
    reportCategory.withReport(
      new ReportConfigBuilder()
        .withLabel('Prospects')
        .withDatePeriod('monthly')
        .withSelector('prospects')
        .withCategory('prospects')
        .withReportName('Campaigns')
        .withIcon('comments')
        .build()
    );

    return reportCategory;
  }
}
