import { autoinject, LogManager } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import { SessionStore } from "../../../_common/stores/session-store";
import { TransferList, TransferRecipient } from "./transfer-list-model";
import { Oplog } from "../../../_common/services/database-service";

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('TransferListService');

@autoinject
export class TransferListService {

  constructor(
    private readonly http: HttpClient,
    private oplog: Oplog,
    private readonly session: SessionStore,
  ) { }

  private get baseUrl(): string {
    return `/v1/organisation/${this.session.get.organisation.organisationId}/transfer-list`;
  }

  private syncRequest(op: OPERATION, id: string, runnable: () => void): Promise<any> {
    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('organisation-projector.transferListView')
      .withKeyField('_id')
      .withKeyValue(id);
    runnable();
    return oplogSubscription.when(op).then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  private mapTransferList(data: TransferList): TransferList {
    const item = new TransferList();
    item.id = data.id;
    item.name = data.name;
    item.businessPartner = data.businessPartner;
    item.contactCenter = data.contactCenter;
    item.transferRecipients = data.transferRecipients.map((data: TransferRecipient) => {
      const contact = new TransferRecipient();
      contact.id = data.transferRecipientId;
      contact.name = data.name;
      contact.surname = data.surname;
      contact.contactNumber = data.contactNumber;
      contact.category = item.name;
      contact.type = 'Contact';
      return contact;
    });
    item.transferTeams = data.transferTeams;
    item.transferMembers = data.transferMembers;
    return item;
  }

  public findAll(): Promise<Array<TransferList>> {
    return this.http.createRequest(`${this.baseUrl}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response) {
          return null;
        }
        return response.map((data: TransferList) => this.mapTransferList(data));
      })
      .catch((e) => {
        if (e.statusCode === 404) {
          // not found do nothing
        } else {
          return e;
        }
      });
  }

  public findById(id: string): Promise<TransferList> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response) {
          return null;
        }
        return this.mapTransferList(response);
      })
      .catch((e) => {
        if (e.statusCode === 404) {
          // not found do nothing
        } else {
          return e;
        }
      });
  }

  public findMemberById(): Promise<Array<TransferList>> {
    const memberId = this.session.get.user.memberId;
    return this.http.createRequest(`${this.baseUrl}/member/${memberId}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response) {
          return null;
        }
        return response.map((data: TransferList) => this.mapTransferList(data));
      })
      .catch((e) => {
        if (e.statusCode === 404) {
          // not found do nothing
        } else {
          return e;
        }
      });
  }

  public delete(id: any): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asDelete()
      .send();
  }

  public async insert(data: TransferList): Promise<any> {
    data.id = uuidv4();
    return this.syncRequest(OPERATION.INSERT, data.id, () => {
      this.http.createRequest(`${this.baseUrl}/${data.id}`)
        .asPost()
        .withContent(data)
        .send();
    });
  }

  public async addTransferRecipient(id: any, data: TransferRecipient): Promise<any> {
    data.id = uuidv4();
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/transfer-recipient/${data.id}`)
        .asPost()
        .withContent(data)
        .send();
    });
  }

  public async removeTransferRecipient(id: any, data: TransferRecipient): Promise<any> {
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/transfer-recipient/${data.id}`)
        .asDelete()
        .send();
    });
  }

  public async addTransferTeam(id: any, teamId: string): Promise<any> {
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/transfer-team/${teamId}`)
        .asPost()
        .withContent({})
        .send();
    });
  }

  public async removeTransferTeam(id: any, teamId: string): Promise<any> {
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/transfer-team/${teamId}`)
        .asDelete()
        .send();
    });
  }

  public async addTransferMember(id: any, memberId: string): Promise<any> {
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/transfer-member/${memberId}`)
        .asPost()
        .withContent({})
        .send();
    });
  }

  public async removeTransferMember(id: any, memberId: string): Promise<any> {
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/transfer-member/${memberId}`)
        .asDelete()
        .send();
    });
  }
}

enum OPERATION {
  INSERT = 'insert',
  UPDATE = 'update',
}