import { autoinject } from 'aurelia-dependency-injection';

import { ConfigMapper } from './config-mapper';

@autoinject
export class InteractionTodayTransferStats {

  constructor(private configMapper: ConfigMapper) {}

  public columnConfig = {
    // 🆃🅾🆃🅰🅻 🅸🅽 🅰🅽🅳 🅾🆄🆃 🅾🅵 🆂🅻🅰 30 🅼🅸🅽 🅼🅸🅽🆄🆃🅴🆂
    'Consults': {
      tooltip: 'Consults',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'consults'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Consults', this.configMapper.getTotalTodayStats(c, 'consults'))
    },
    'Conference': {
      tooltip: 'Conference',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'conferences'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Conference', this.configMapper.getTotalTodayStats(c, 'conferences'))
    },
    'Transfers': {
      tooltip: 'Transfers',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'transfers'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Transfers', this.configMapper.getTotalTodayStats(c, 'transfers'))
    },
    'Scheduled': {
      tooltip: 'Scheduled',
      value: (c) => {
        try {
          return c.numberOfAgentsForecasted ? parseInt(c.numberOfAgentsForecasted) : '--';
        } catch(e) {
          return '--';
        }
      },
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Agents'
      }
    },
    'Cleared': {
      tooltip: 'Cleared',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'cleared'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      }
    }
  };
}
