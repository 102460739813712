/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZListCardBody');
/*
 */
@customElement('z-list-card-body')
export class ZListCardBody {
  @bindable hashover: boolean;
  @bindable short: boolean;
  @bindable padding: string;
}
