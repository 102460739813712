import { inject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";

@inject(HttpClient)
export class VerintService {

  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  getRoles() {
    return this.httpClient
      .createRequest('v1/verint/me/roles')
      .asGet()
      .send();
  }

  putEmployeeRoles() {
    return this.httpClient
      .createRequest('v1/verint/me/employee/roles')
      .asPut()
      .send();
  }
}