import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';

import {TelephoneNumberModel} from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import {ContactModel} from '../contact-model';
import {CONTACT_ACTIONS} from '../contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('SearchContact');

@customElement('z-search-contact')
@autoinject()
export class SearchContact {
  @bindable private contact: ContactModel;
  private page: number = -1;
  private size: number = 12;
  private layerConversationId: string;
  private number: TelephoneNumberModel;
  private isSelected: boolean = false;
  private telephoneNumberInitialisation: boolean = false;

  constructor(private eventAggregator: EventAggregator) {
    this.eventAggregator = eventAggregator;
  }

  public attached(): void {
    this.initialiseTelephoneNumber();
    this.updateChatInteraction();
  }

  private updateChatInteraction(): void {
    if (this.contact && this.contact.servedInteraction) {
      this.layerConversationId = `layer:///conversations/${this.contact.servedInteraction.interactionId}`;
    }
  }

  private initialiseTelephoneNumber(): void {
    this.telephoneNumberInitialisation = true;
    this.number = new TelephoneNumberModel('', '', '', false);
  }

  private searchContacts(scrollEnd?: boolean): void {
    let searchText = '';
    if (this.contact.searchContactParams.searchText) {
      searchText = this.contact.searchContactParams.searchText;
    }
    this.eventAggregator.publish(CONTACT_ACTIONS.SEARCH, searchText);
  }

  private createContact(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      correlationId: this.contact.correlationId,
      contactId: this.contact.contactId,
      viewStrategy: this.contact.servedInteraction? 'createContact': 'createContactEmpty'
    });
  }

  private cancel(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      correlationId: this.contact.correlationId,
      contactId: this.contact.contactId,
      viewStrategy: 'emptyContact'
    });
  }

  private selectContact(selectedContact: ContactModel): void {
    // @ts-ignore
    if(selectedContact.isPlaceholder) {
      return;
    }
    this.contact.searchContactParams.selectedContact = selectedContact;
    this.eventAggregator.publish(CONTACT_ACTIONS.SELECT_SEARCH, selectedContact);
  }

}
