import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
/**/
import { SessionStore } from 'zailab.common';
import { TimezoneAbstract } from '../../../interaction/interactionFlow/interaction-designer/timezone-abstract';
/**/
const logger = LogManager.getLogger('SetOperatingHoursDialog');

/**/
@autoinject()
export class SetOperatingHoursDialog extends TimezoneAbstract {

  public officeHours: Array<object> = [];
  public isEditing: string = '';
  public isValid: boolean = false;
  private campaignId: string;

  constructor(private dialogController: DialogController, private updateTimeSignal: BindingSignaler, protected sessionStore: SessionStore) {
    super(sessionStore);
  }

  public activate(campaign: any): void {
    this.campaignId = campaign.campaignId;
    let _officeHours: IOfficeHours = {
      Monday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: false
      },
      Tuesday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: false
      },
      Wednesday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: false
      },
      Thursday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: false
      },
      Friday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: false
      },
      Saturday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: true
      },
      Sunday: {
        startingTime: '090000',
        endingTime: '170000',
        closed: true
      }
    };
    if (campaign.operatingHours && campaign.operatingHours.timezone) {
      super.activate(null, campaign.operatingHours.timezone);
      for (const day in _officeHours) {
        const dayName = day.toLowerCase();
        _officeHours[day].closed = campaign.operatingHours[dayName].closed;
        _officeHours[day].startingTime = campaign.operatingHours[dayName].startTime;
        _officeHours[day].endingTime = campaign.operatingHours[dayName].endTime;
      }
    } else {
      super.activate();
    }
    for (let _day in _officeHours) {
      if (_officeHours[_day].closed) {
        let dayObject: object = {
          dayName: _day,
          startingHour: '09',
          endingHour: '17',
          startingMinute: '00',
          endingMinute: '00',
          closed: true,
          errors: []
        };
        this.officeHours.push(dayObject);
      } else {
        let dayObject: object = {
          dayName: _day,
          startingHour: parseInt(_officeHours[_day].startingTime.substr(0, 2)),
          endingHour: parseInt(_officeHours[_day].endingTime.substr(0, 2)),
          startingMinute: parseInt(_officeHours[_day].startingTime.substr(2, 2)),
          endingMinute: parseInt(_officeHours[_day].endingTime.substr(2, 2)),
          closed: _officeHours[_day].closed,
          errors: []
        };
        this.officeHours.push(dayObject);
      }
    }
    this.checkValid();
  }

  public meridiem(hour: number): string {
    return hour < 12 ? 'AM' : 'PM'
  }

  private checkValid(): void {
    this.isValid = this.officeHours.filter(_value => {
      return _value.closed !== true
    }).length > 0
  }

  // Signal update to all time entries
  private updateTime(): void {
    this.updateTimeSignal.signal('update-time-signal');
  }

  public editHours(event: Event, day: object): void {
    this.isEditing = day['dayName'];
  }

  public toggleOpen(event: Event, day: object): void {
    day['closed'] = !event.target['checked'];
    this.checkValid();
  }

  public confirmHours(event: Event, day: object): void {
    if (this.passesValidation(day) === true) {
      this.isEditing = '';
      this.updateTime();
    }
  }

  private passesValidation(_day: object): boolean {
    _day['errors'] = [];
    // Add extra validation here
    if (parseInt(_day['startingHour']) > parseInt(_day['endingHour'])) {
      _day['errors'].push('Start time cannot be after end time');
    }
    return _day['errors'].length === 0;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    let operatingHours: object = { timezone: this.selectedTimezone.name };
    this.officeHours.forEach((value) => {
      const dayName = value['dayName'].toLowerCase();
      if (value['closed']) {
        operatingHours[dayName] = {
          closed: true
        }
      } else {
        operatingHours[dayName] = {
          startTime: ('00' + value['startingHour']).slice(-2) + ('00' + value['startingMinute']).slice(-2) + '00',
          endTime: ('00' + value['endingHour']).slice(-2) + ('00' + value['endingMinute']).slice(-2) + '00',
        }
      }
    });
    this.dialogController.ok({ campaignId: this.campaignId, operatingHours });
  }
}
