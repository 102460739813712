export const DEFAULT_DATA = [{
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Waiting',
  channel: 'N/A',
  eventReason: '1',
  eventMode: 'Idle'
}, {
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Conversing',
  channel: 'Blended',
  eventReason: '2',
  eventMode: 'Blended'
}, {
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Conversing',
  channel: 'Voice',
  eventReason: '3',
  eventMode: 'Voice'
}, {
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Conversing',
  channel: 'Campaign Task',
  eventReason: '4',
  eventMode: 'Outbound'
}, {
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Conversing',
  channel: 'Email',
  eventReason: '5',
  eventMode: 'Email'
}, {
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Conversing',
  channel: 'Instant Message',
  eventReason: '7',
  eventMode: 'Instant Message'
}, {
  loggedStatus: 'Logged In',
  presence: 'Working',
  routingStatus: 'On Duty',
  activity: 'Conversing',
  channel: 'Webchat',
  eventReason: '8',
  eventMode: 'Webchat'
}, {
  loggedStatus: 'Logged In',
  presence: 'Break',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '10',
  eventMode: 'Break'
}, {
  loggedStatus: 'Logged In',
  presence: 'Away',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '11',
  eventMode: 'Away'
}, {
  loggedStatus: 'Logged In',
  presence: 'Busy',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '12',
  eventMode: 'Busy'
}, {
  loggedStatus: 'Logged In',
  presence: 'Lunch',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '13',
  eventMode: 'Lunch'
}, {
  loggedStatus: 'Logged In',
  presence: 'Training',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '14',
  eventMode: 'Training'
}, {
  loggedStatus: 'Logged In',
  presence: 'Meeting',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '15',
  eventMode: 'Meeting'
}, {
  loggedStatus: 'Logged Out',
  presence: 'Offline',
  routingStatus: 'Off Duty',
  activity: 'N/A',
  channel: 'N/A',
  eventReason: '16',
  eventMode: 'Offline'
}];
