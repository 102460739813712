import {LogManager} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('UploadFile');
/*
 */
export default class {

  file = null;
  fileName = 'No file selected.';
  validation = null;

  constructor() {

  }

  activate(wizard) {

    if(wizard) {
      this._activate(wizard);
    }
  }

  _activate(wizard) {
    this.wizard = wizard;

    if(wizard.data.file) {
      this.file = wizard.data.file.content;
      this.fileName = wizard.data.file.name;
    }

    wizard.beforeNextStep(() => {
      this._continue();
    });
  }

  selectFile(evt) {

    let file = evt.target.files[0];

    if(!file || !file.name || !this._isValid(file).extension() || !this._isValid(file).size()) {
      return;
    }

    this.validation = null;

    let reader = new FileReader();
    this.fileName = file.name;

    if(!file){
      return;
    }

    reader.onload = (event) => {
      this.file = {
        content: event.target.result,
        name: this.fileName
      };
      this.wizard.step.complete({file: this.file});
    };

    reader.readAsText(file);
  }

  _continue() {

    if(!this.file) {
      console.error('validation failed', this.file);
      this.validation = 'Please select a file to upload.';
      return;
    }

    this.validation = null;
    let file = this.file;
    this.wizard.continue({file: file});
  }

  _isValid(file) {

    function _extension() {

      let extension = file.name.split('.');
      extension = extension[extension.length - 1];

      if(extension !== 'csv') {

        _setIncompleteStateWith('Please select a CSV file.');
        return false;
      }
      return true;
    }

    function _size() {

      let maxSize = 10 * 1024 * 1024;

      if(file.size >= maxSize) {

        _setIncompleteStateWith('Please select a CSV file that does not exceed 10MB.');
        return false;
      }
      return true;
    }

    let _setIncompleteStateWith = (validation) => {

      this.file = null;
      this.fileName = null;
      this.wizard.step.incomplete({ file: null });
      this.validation = validation;
    };

    return {
      extension: _extension,
      size: _size
    }
  }
}