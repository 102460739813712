import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ThreeDSecurePopUp} from '../buycredit/3d-secure-pop-up';

let logger = LogManager.getLogger('ThreeDSecureDialog');

@autoinject
export class ThreeDSecureDialog {

  private secureUrl: string;
  private fields: any;
  private iframe: HTMLElement;

  constructor(private threeDSecurePopUp: ThreeDSecurePopUp, private dialogController: DialogController) {
  }

  public activate(iframeData: any): void {
    this.secureUrl = iframeData.url;
    this.fields = iframeData.fields
  }

  private init3dSecure(): void {

    this.threeDSecurePopUp.open(this.secureUrl, this.fields, this.iframe);
  }

  private cancel(): void {
    this.dialogController.cancel();
    this.iframe.src = ''
  }

}