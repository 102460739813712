import { LogManager, autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { AbstractList, List } from 'zailab.abstract';
import { PlaceholderService } from '../../../../_common/services/placeholder-service';
import { InAppAlertsService } from '../in-app-alerts-service';

const logger = LogManager.getLogger('InHouseWFMList');

@autoinject()
export class InHouseWFMList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders = 0;
  public container: any;

  public loading: boolean;
  public configs: any = [];

  private dashboardAlert = {
    name: 'Dashboard Alerts',
    length: null
  };
  public actions = [{
    action: 'view',
    label: 'View',
  }];

  constructor(
    private router: Router,
    private inAppAlertsService: InAppAlertsService,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.retrieveInAppAlerts();
  }

  private async retrieveInAppAlerts(): Promise<void> {
    const inAppAlerts = await this.inAppAlertsService
      .retrieveInAppAlerts()
      .catch((e) =>
        console.warn(' > Failed to retrieve in app alerts due to', e)
      );
    this.dashboardAlert.length = inAppAlerts.length;
    this.setUpList();
  }

  private async setUpList(): Promise<void> {
    this.configs = [this.dashboardAlert];
    this.generatePlaceholders();
  }

  public selectConfig(config: any): void {
    if (config.name === 'Dashboard Alerts') {
      this.router.navigate('dashboard-alerts');
    }
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.configs.length,
      5,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }
}
