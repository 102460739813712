import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import OrganisationService from "../organisation-service";
import { InteractionAnalyticsConfiguration } from "../interaction-analytics-configuration-model";
import { WorkTypesService } from "features/organisation/conversation/worktypes/work-types-service";

const logger = LogManager.getLogger('VerintTextAnalyticsFieldMappings');

@autoinject
export class VerintAnalyticsService {

  private baseUri = 'v1/organisation/me/text-ingestion';

  constructor(
    private httpClient: HttpClient,
    private organisationService: OrganisationService,
    private workTypesService: WorkTypesService,
  ) { }

  public findStandardFields(): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUri}/fields/standard`)
      .asGet()
      .send();
  }

  public findStandardFieldNames(): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUri}/field-names/standard`)
      .asGet()
      .send();
  }

  public findCustomFieldNames(): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUri}/field-names/custom`)
      .asGet()
      .send();
  }

  public findDataSources(): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUri}/field-sources`)
      .asGet()
      .send();
  }

  public findFieldValuesForInteractionData(): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUri}/field-values/interaction-data`)
      .asGet()
      .send();
  }

  public findFieldMappings(workTypeId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUri}/field-mappings/${workTypeId}`)
      .asGet()
      .send();
  }

  public setFieldMappings(organisationId, workforceManagementId, workTypeId: string, fieldMappings: any): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/${organisationId}/workforce-management/${workforceManagementId}/settings/text-analytics/${workTypeId}`)
      .asPut()
      .withContent(fieldMappings)
      .send();
  }

  public findEnabledWorkTypes(): Promise<any> {
    return this.organisationService.retrieveOrganisationInfo().then((response) => {
      let config = this.extractVerintTextAnalyticsConfig(response.interactionAnalyticsProviderConfig);
      let enabledWorkTypes = config.enabledWorkTypes;
      if (config.allWorkTypesEnabled) {
        return this.findWorkTypes();
      }
      if (!enabledWorkTypes || !enabledWorkTypes.length) {
        return [];
      }
      return Promise.all(enabledWorkTypes.map((workTypeId) => {
        return this.workTypesService.retrieveWorkType(workTypeId);
      }));
    });
  }

  public findMappedWorkTypes(): Promise<any> {
    return this.findEnabledWorkTypes().then((data) => {
      if (data && data.length) {
        return Promise.all(data.map((item) => this.findFieldMappings(item.id).then((mapping) => {
          if (mapping) {
            return {
              id: item.id,
              name: item.name
            };
          }
        }))).then((mappings) => mappings.filter((item) => !!item));
      }
    });
  }

  public findTextAnalyticsConfig(): Promise<any> {
    return this.organisationService.retrieveOrganisationInfo().then((response) => {
      let config = this.extractVerintTextAnalyticsConfig(response.interactionAnalyticsProviderConfig);
      return config;
    });
  }

  private findWorkTypes(): Promise<any> {
    return this.workTypesService.retrieveWorkTypes().then((res) => {
      let filteredWorkTypes = res.filter((workType) => workType.channel.includes('Chat') && workType.flowType !== 'Office Flow');
      return filteredWorkTypes.map((workType) => {
        return {
          id: workType.templateId,
          name: workType.templateName
        }
      });
    });
  }

  private extractVerintTextAnalyticsConfig(providerConfig: any): InteractionAnalyticsConfiguration {
    let verintConfig: any = new InteractionAnalyticsConfiguration();
    if (!providerConfig) {
      verintConfig.enabled = false;
      return verintConfig;
    }
    verintConfig = providerConfig.filter(config => config.name === 'Verint' && config.type === 'TEXT');
    if (!verintConfig || verintConfig.length === 0) {
      verintConfig.enabled = false;
      return verintConfig;
    }
    return verintConfig[0];
  }
}
