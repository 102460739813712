import {LogManager} from 'aurelia-framework';
const logger = LogManager.getLogger('AudioTools');


class AudioTools {

  public getSelectedAudioFromWizard(wizard: any): any {
    if (wizard.data.audioMessages && wizard.data.audioMessages.audioId) {
      return wizard.data.audioMessages;
    }
    if (wizard.data.audioMessages && wizard.data.audioMessages._id) {
      return wizard.data.audioMessages._id;
    }
  }

  public getSelectedAudio(data: any): any {
    if (data.audioMessages && data.audioMessages.audioId) {
      return data.audioMessages;
    }
    if (data.audioMessages && data.audioMessages._id) {
      return data.audioMessages._id;
    }
  }

  public getSelected(data: any): any {
    if (data && data.audioId) {
      return data;
    }
    if (data && data._id) {
      return data._id;
    }
    if (typeof data === 'string') {
      return data;
    }
  }

  public getAudioMessages(selectedAudioMessage: any): any {
    let audioMessages = {};
    if (!selectedAudioMessage) {
      return audioMessages;
    }
    if (selectedAudioMessage._id) {
      return selectedAudioMessage;
    }
    if (selectedAudioMessage.otherAudio && selectedAudioMessage.otherAudio.length) {
      for (const otherAudio of selectedAudioMessage.otherAudio) {
        audioMessages[otherAudio.language] = {
          audioId: otherAudio.id,
          description: otherAudio.filename
        };
      }
      let defaultAudio = selectedAudioMessage.otherAudio.find((otherAudio) => otherAudio.defaultSelection);
      if (!defaultAudio) {
        defaultAudio = selectedAudioMessage.otherAudio[0];
      }
      audioMessages['_def'] = {
        audioId: defaultAudio.id,
        description: defaultAudio.filename
      };
      audioMessages['_id'] = {
        audioId: selectedAudioMessage.otherAudio[0].id,
        description: selectedAudioMessage.otherAudio[0].filename
      };
    } else {
      audioMessages = {
        audioId: selectedAudioMessage.audioId,
        description: selectedAudioMessage.filename
      };
    }
    return audioMessages;
  }

  public getAudioAnnouncement(selectedAudioMessage: any): any {
    let audioMessages = {};
    if (!selectedAudioMessage) {
      return audioMessages;
    }
    if (selectedAudioMessage.otherAudio && selectedAudioMessage.otherAudio.length) {
      for (const otherAudio of selectedAudioMessage.otherAudio) {
        audioMessages[otherAudio.language] = {
          audioId: otherAudio.id,
          filename: otherAudio.filename
        };
      }
      let defaultAudio = selectedAudioMessage.otherAudio.find((otherAudio) => otherAudio.defaultSelection);
      if (!defaultAudio) {
        defaultAudio = selectedAudioMessage.otherAudio[0];
      }
      audioMessages['_def'] = {
        audioId: defaultAudio.id,
        filename: defaultAudio.filename
      };
      audioMessages['_id'] = {
        audioId: selectedAudioMessage.otherAudio[0].id,
        filename: selectedAudioMessage.otherAudio[0].filename
      };
    } else {
      audioMessages = {
        audioId: selectedAudioMessage.audioId,
        filename: selectedAudioMessage.filename
      };
    }
    return audioMessages;
  }
}

export const AUDIO_TOOLS = new AudioTools();