import { LogManager } from 'aurelia-framework';
import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('IMModel');

export default class extends BaseModel {

  configId;
  endpoint;
  apiKey;
  channels;

  constructor(imConfig) {
    super();
    this.modelEntity(imConfig);
  }

  modelEntity(imConfig) {
    super.mapProperties(imConfig);
  }
}