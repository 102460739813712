import {LogManager, inject, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZUtilityIcon');
/*
 */
@customElement('z-utility-icon')
@inject(Element)
export class ZUtilityIcon {

  @bindable private icon: string;
  @bindable private text: string;
  @bindable private spaced: boolean = true;
  @bindable private position: string;

}
