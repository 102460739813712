import { inject, LogManager, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('QualityAssessor');

@inject(Router)
export class QualityAssessor {

  constructor(router) {

    this.router = router;
  }

  configureRouter(config, router) {

    config.map([

      { route: ['', 'qateams'], name: 'qateams', moduleId: PLATFORM.moduleName('hud/dashboard/qualityassessor/teams/teams'), nav: false, title: 'Teams' },
      { route: 'forms', name: 'forms', moduleId: PLATFORM.moduleName('hud/dashboard/qualityassessor/forms/forms'), nav: false, title: 'Forms', isWingsHidden: true },
      { route: 'assessment/:interactionId', name: 'assessment', moduleId: PLATFORM.moduleName('hud/dashboard/qualityassessor/assessment/assessment'), nav: false, title: 'Assessment', isWingsHidden: true },
      { route: 'reports', name: 'reports', moduleId: PLATFORM.moduleName('features/organisation/report/reports'), nav: false, title: 'Report' }
    ]);

    this.router = router;
  }
}
