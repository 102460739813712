import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { SkillsService } from '../skills-service';
import { AddSkillDialog } from '../addskill/add-skill-dialog';
import { SkillModel } from '../skill-model';
import {SkillGroupsListService} from "../../skillgroups/skill-groups-service";
/*
 * */
const logger = LogManager.getLogger('SkillsListView');

/*
 * */
@inject(SkillsService, SkillGroupsListService, Router, EventAggregator, SessionStore, DialogService, Element)
export class SkillsListView extends AbstractList {

  private skillGroupId: string;
  private skillGroupName: string;
  private allSkills: SkillModel[] = [];

  constructor(private skillsListService: SkillsService, private skillGroupsListService: SkillGroupsListService, private router: Router, protected eventAggregator: EventAggregator, private sessionStore: SessionStore, private dialogService: DialogService, private element: Element) {
    super(eventAggregator);
  }

  public activate(params?: any): void {
    this.skillGroupId = params.skillGroupId;
    this.subscribeToOplog();
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected subscribeToOplog(): void {
    this.skillsListService.subscribeToOplogBasedOnCriteria({
      key: '_id',
      value: this.skillGroupId,
      location: 'organisation-projector.displaySkillsView'
    }).then(_oplog => {
      this.oplog = _oplog;
      this.oplog.on('update', response => this.handleOplogUpdates(response));
    });
  }

  private handleOplogUpdates(response: any): void {
    // Get single updated skill
    try {
      let _skillMap: any[] = response.skills.map(_skill => {
        return new SkillModel(_skill);
      });
      this.allSkills = _skillMap;
      this.setupListConfig(_skillMap);
    } catch (e) {
      try {
        let _foundSkill: any = Object.keys(response).filter(_entry => {
          return response[_entry].skillId;
        })[0];
        let _addedItem: SkillModel = new SkillModel(response[_foundSkill]);
        this.allSkills.push(_addedItem);
        this.deferredMessage = `Skill ${_addedItem.name} added`;
        this.setupListConfig(this.allSkills);
      } catch (e) {
        // Could not parse skills
      }
    }
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.skillsListService.retrieveSkills(this.skillGroupId)
    .then((_response) => {
      this.allSkills = _response.skills;
      this.setupListConfig(_response.skills);
    }).catch((error) => {
      logger.warn('Failed to retrieve your skills > error = ', error);
    });
    this.skillGroupsListService.retrieveSkillGroup(this.skillGroupId)
      .then((_response) => {
        this.skillGroupName = _response.name;
      }).catch((error) => {
      logger.warn('Failed to retrieve your skillGroup > error = ', error);
    });
  }

  protected delete(skill: any): void {
    super.delete(skill);
    this.skillsListService.removeSkill(this.sessionStore.get.organisation.organisationId, this.skillGroupId, skill);
  }

  protected showAddItem(): void {
    super.showAddItem();
    this.dialogService.open({viewModel: AddSkillDialog, model: this.itemList.items}).whenClosed(_response => {
      if (_response.wasCancelled) {
        this.hideLoader();
      } else {
        this.deferredMessage = `Skill <b>${_response['output']}</b> added.`;
        this.skillsListService.addSkill(this.sessionStore.get.organisation.organisationId, this.skillGroupId, _response['output']);
      }
    });
  }

  protected deleteItems(items: SkillModel[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} skills deleted.` : `Skill <b>${items[0].name}</b> deleted`;
    super.deleteItems(items)
  }

  protected setupListConfig(_skills: any[]): void {
    super.setupListConfig(_skills);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .enableAdd()
    .enableDelete()
    .items(_skills)
    .icon('icon')
    .uniqueIdentifier('id')
    .displayId('name')
    .description('')
    .withProportion('medium')
    .hasRollover(false)
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected createMetaData(_items: any[]): void {
    _items.forEach(_skill => {
      _skill.icon = 'skills';
      _skill.description = `Members: ${_skill.memberCount}`;
      // TODO: Can be implemented when we receive a list of members:
      // _skill.rolloverTextContent = this.createEnrichedList(_skill.members, 'fullName', 3, 'assignees', true);
    });
  }

  public skillGroups(): void {
    this.router.navigate('skillGroups');
  }
}