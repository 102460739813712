import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { PersonalVoicemailModel } from '../models/personal-voicemail-model';
import { AudioAddService } from '../../../../_common/services/audio.add.service';
import { List } from '../../../../abstract/list/list-builder';
import { SearchTools } from '../../../../_common/tools/search-tools';
import { AudioSearchFilter } from '../../../interaction/interactionFlow/interaction-designer/dialogs/audio-search-filter';
/**/
const logger = LogManager.getLogger('PersonalVoicemailDialog');

@autoinject
export class PersonalVoicemailDialog {

  public audioId: string;
  public audioList: ZIAudioFile[];

  protected itemList: List;

  public tableHeaders = [
    { property: 'filename', description: 'Name' },
    { property: 'timestamp', description: 'Created' },
  ];

  constructor(
    private dialogController: DialogController,
    private audioAddService: AudioAddService,
    private element: Element,
    public audioSearchFilter: AudioSearchFilter,
  ) { }

  public async activate(personalVoicemail: PersonalVoicemailModel): Promise<void> {
    
    this.audioId = personalVoicemail.audioId;
    this.audioList = await this.audioAddService.getAudioFiles();

    this.createMetaData(this.audioList);

    this.itemList = List.Builder()
      .required(true)
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .items(this.audioList)
      .icon('icon')
      .uniqueIdentifier('id')
      .displayId('filename')
      .description('')
      .hasRollover(false)
      .tilesPerRow(3)
      .build();

    const audio = this.audioList.find((audio) => {
      return audio.id === this.audioId
    });
    if (audio) {
      this.selectItem(audio);
    }

    this.audioSearchFilter.onConfirmed(() => this.itemList.items = this.audioSearchFilter.filter());
    this.audioSearchFilter.setAllAudioList(this.itemList.items);
  }

  private createMetaData(_items: any[]): void {
    _items.forEach(_file => {
      _file.audioType = _file.speechRate ? 'TTS' : 'WAV upload';
      _file.icon = _file.speechRate ? 'text-size' : 'audio';
    });
  }

  public selectItem(data: any): void {
    this.itemList.select(data, this.itemList.items, this.itemList.required, this.element);
    this.audioId = data.id;
  }

  public ok(): void {
    let audioId = this.audioId;
    this.dialogController.ok({ audioId });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }
}
