import { autoinject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore, SESSION_EVENTS, MESSAGE_EVENTS, PushNotificationService } from 'zailab.common';
import { ReminderService } from '../reminder-service';
import { ReminderModel } from '../reminder-model';
import { CONTACT_ACTIONS } from '../../../contact/contactcontroller/contact-controller-actions';
import { AudioService } from '../../../../../_common/services/audio.service';
import { AUDIO_ASSETS } from '../../../../../_common/services/audio.assets';

const logger = LogManager.getLogger('ViewReminder');

@autoinject()
export class ViewReminder {
  private currentTime: number = null;
  private visibilityComponent: string = 'reminder';
  private isVisible: boolean = false;
  private autoCloseTimer: number = 0;
  private visibilityTime: number = 3000;
  private deferAutoClose: boolean = false;
  private reminders: IReminders = {
    done: [],
    pending: []
  };
  private updateSubscription;
  public alertCount = 0;
  public mailboxCount = 0;

  constructor(
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private reminderService: ReminderService,
    private audioService: AudioService,
    private pushNotificationService: PushNotificationService
  ) {}

  public activate(): void {
    this.getCurrentTime();
    this.retrieveReminders();
    this.subscribeToIncomingReminder();
  }

  private retrieveReminders(): void {
    const memberId = this.sessionStore.get.user.memberId;
    if (memberId) {
      this.retrieveCompletedReminders(memberId);
      this.retrievePendingReminders(memberId);
    } else if (!this.updateSubscription) {
      this.updateSubscription = this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.ORGANISATION, () => {
        this.updateSubscription.dispose();
        this.retrieveReminders();
      });
    }
  }

  private retrieveCompletedReminders(memberId: string): void {
    this.reminderService
      .retrieveReminders(memberId, this.currentTime, 'DONE')
      .then((response: ReminderModel[]) => {
        this.reminders.done = response;
      })
      .catch((error: Error) => {
        logger.info(' An error occurred. Unable to retrieve reminders with status `DONE` > ', error);
      });
  }

  private retrievePendingReminders(memberId: string): void {
    this.reminderService
      .retrieveReminders(memberId, this.currentTime, 'PENDING')
      .then((response: ReminderModel[]) => {
        this.reminders.pending = response;
      })
      .catch((error: Error) => {
        logger.info(' An error occurred. Unable to retrieve reminders with status `PENDING` > ', error);
      });
  }

  private subscribeToIncomingReminder(): void {
    this.reminderService.onReminderReceived(reminder => {
      let reminderMessage = '';

      if (reminder) {
        const firstName = reminder.state.contactFirstName || '';
        const surname = reminder.state.contactSurname || '';

        if (firstName || surname) {
          reminderMessage = ' Reminder to contact ' + firstName + ' ' + surname;
        } else {
          reminderMessage = ' Reminder to contact ' + 'Unknown Contact';
        }
      }

      this.eventAggregator.publish(MESSAGE_EVENTS.INFO, reminderMessage);
      this.pushNotificationService.notify('Incoming Reminder', { body: reminderMessage, timeout: 3000 });
      this.audioService.play(AUDIO_ASSETS.INCOMING_REMINDER_NOTIFICATION);
      this.getCurrentTime();
      this.retrieveReminders();
    });
  }

  private getCurrentTime(): void {
    const currentDate = new Date();
    this.currentTime = currentDate.getTime();
  }

  private toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  private get isContentScrollable(): boolean {
    let minScrollLength = 3;
    let maxScrollLength = 5;

    const itemListLength = this.reminders.pending.length + this.reminders.done.length;

    if ((this.reminders.pending.length > minScrollLength && this.reminders.done.length > minScrollLength) || itemListLength > maxScrollLength) {
      return true;
    }
    return false;
  }

  public acknowledgeReminder(item: ReminderModel): void {
    item.processing = true;
    this.reminderService
      .acknowledgeReminder(item.reminderId)
      .then(() => {
        this.itemAcknowledged(item);
        this.viewReminderInteraction(item);
      })
      .catch((error: string) => {
        logger.info(' Unable to acknowledge reminder. Error = ', error);
      });
  }

  private itemAcknowledged(item: ReminderModel): void {
    this.removeReminderFromPending(item);
    this.addReminderToDone(item);
  }

  private removeReminderFromPending(item: ReminderModel): void {
    this.reminders.pending = this.reminders.pending.filter((reminder: ReminderModel) => {
      return item.reminderId !== reminder.reminderId;
    });
  }

  private viewReminderInteraction(item: ReminderModel): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.SELECT_FROM_RECENT_CONVERSATION, {
      contactId: item.metadata.contactId,
      conversationId: item.metadata.conversationId,
      channel: 'reminder'
    });
    this.toggleVisibility();
  }

  private addReminderToDone(item: ReminderModel): void {
    this.reminders.done.unshift(item);
  }

  public detached(): void {
    this.updateSubscription && this.updateSubscription.dispose();
  }
}

interface IReminders {
  done: ReminderModel[];
  pending: ReminderModel[];
}
