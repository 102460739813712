import { LogManager, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

const logger = LogManager.getLogger('BusinessPartner');

export class BusinessPartner {

  public router: Router;

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: ['', 'list'],
        name: 'Business Partner List',
        moduleId: PLATFORM.moduleName('./list/business-partner-list'),
        title: 'List'
      },
      {
        route: 'add',
        name: 'Business Partner Add',
        moduleId: PLATFORM.moduleName('./add/business-partner-add'),
        title: 'Add'
      },
      {
        route: 'edit/:id',
        name: 'Business Partner Edit',
        moduleId: PLATFORM.moduleName('./edit/business-partner-edit'),
        title: 'Edit'
      }
    ]);
    this.router = router;
  }
}