import { inject, computedFrom, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
/*
 * */
import { SurveyService } from '../survey-service';
import { SurveyModel } from '../survey-model';
import { SessionStore } from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('SurveyWizard');
/*
 * */
@inject(SurveyService, Router, EventAggregator, SessionStore)
export class SurveyWizard {

  survey;
  steps;
  activeStep;
  isProcessing = false;
  _isPropertiesValid;
  isQuestionsValid;

  constructor(surveyService, router, eventAggregator, sessionStore) {
    this.surveyService = surveyService;
    this.eventAggregator = eventAggregator;
    this.survey = new SurveyModel();
    this.router = router;
    this.sessionStore = sessionStore;
    this.setSteps();
  }

  setSteps() {
    this.steps = [
      new Step(1, 'Properties', './survey-properties'),
      new Step(2, 'Questions', './survey-questions')
    ];
    this.activeStep = this.steps[0];
  }

  nextStep() {
    if (this.isPropertiesValid) {
      this.activeStep = this.steps[this.activeStep.id];
    }
  }

  previousStep() {

    this.activeStep = this.steps[this.activeStep.id - 2];

  }

  get isPropertiesValid() {
    return this._isPropertiesValid;
  }

  set isPropertiesValid(value) {
    this._isPropertiesValid = value;
  }

  @computedFrom('activeStep')
  get isLastPage() {
    return this.activeStep.id === 2;
  }

  @computedFrom('activeStep')
  get isFirstPage() {
    return this.activeStep.id === 1;
  }

  finish() {
    if (this.isPropertiesValid && this.isQuestionsValid) {

      this.surveyService.publishSurvey(this.survey, this.sessionStore.get.organisation.organisationId).then(response => {
        this.routeToSurveyList();
      }, error => {
        logger.info('publishing error >>', error);
      });
    }
  }

  routeToSurveyList() {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }

}

class Step {
  id = 0;
  title = '';
  path = '';

  constructor(id, title, path) {
    this.id = id;
    this.title = title;
    this.path = path;
  }
}

