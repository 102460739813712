/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZIcon');
/*
 */
@customElement('z-icon')
export class ZIcon {

  @bindable size;
  @bindable icon;
  @bindable color;
}
