import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('RoutingStatusModel');

export class RoutingStatusModel extends BaseModel {

    public memberId: string = null;
    public routingStatus: any = null;

    constructor(routingStatus: any) {
      super();
      this.mapProperties(routingStatus);
    }
}
