import { autoinject, LogManager, customElement, bindable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ContactModel } from '../contact-model';
import { TelephoneNumberTypes } from '../../../../components/atoms/inputs/phonenumber/telephone-number-type';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'zailab.common';
import { CONTACT_ACTIONS, LOADER_ACTIONS } from '../contactcontroller/contact-controller-actions';
import { ContactFactory } from '../contact-factory';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('EditContact');

@customElement('z-edit-contact')
@autoinject()
export class EditContact {
  @bindable private contactid: string;
  @bindable private contact: ContactModel;
  @bindable private isLoading: boolean;
  private editedContact: ContactModel;
  private contactTitles: string[] = ['None', 'Mr', 'Miss', 'Mrs', 'Ms', 'Dr', 'Prof'];
  private error: string = '';
  private validation: any;
  private telephoneNumberTypes: typeof TelephoneNumberTypes = null;
  private editContactScrollId: string;
  private hasOverflow: boolean;
  private scrollBoxWrap: any;
  private scrollBoxInner: any;
  private wrapHeight: number;
  private innerHeight: number;
  private wrapper: HTMLElement;

  private firstNameRegex = /^([a-zA-Z'\s-])+$/;
  private surnameRegex = /^([a-zA-Z'\s-])+$/;

  public height: string = '100%';

  constructor(private contactFactory: ContactFactory, private eventAggregator: EventAggregator, private validationControllerFactory: ValidationControllerFactory) {
    this.eventAggregator = eventAggregator;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    this.telephoneNumberTypes = TelephoneNumberTypes;
    this.editContactScrollId = uuidv4();
  }

  public bind(): void {
    if (!this.contact.editedContact) {
      let contactDeepCopy: any = { ...this.contact };
      this.contact.editedContact = this.contactFactory.build();
      this.contact.editedContact.mapContactInfoFromSearch(contactDeepCopy);
      this.contact.editedContact.initNumbers(this.contact.telephoneNumbers);
      this.contact.editedContact.initEmailAddress(contactDeepCopy.emails);
      this.contact.editedContact.retrieveCustomFields();
      this.scrollBoxWrap = document.getElementById(`js-scroll-box-wrap-${this.editContactScrollId}`);
      this.scrollBoxInner = document.getElementById(`js-scroll-box-inner-${this.editContactScrollId}`);
      this.checkForOverflow();
    } else {
      setTimeout(() => {
        this.eventAggregator.publish('PHONE.NUMBER.VALIDATE');
        this.eventAggregator.publish('EMAIL.VALIDATE');
      }, 0)
    }
  }

  public attached(): void {
    this.initialiseValidation();
    const contactCardBodyElement: HTMLElement = this.wrapper.closest('.c-contact-card-body');
    this.height = `${contactCardBodyElement.offsetHeight}px`;
  }

  private updateContact(): void {
    this.error = '';

    if (!this.checkIfOneNumberNotNull() && !this.checkIfOneEmailNotNull()) {
      this.error = 'Please specify at least one contact method.';
      return;
    }

    if (this.checkIfNumbersInvalid()) {
      return;
    }

    if (this.checkIfEmailsInvalid()) {
      return;
    }

    if (!this.checkDuplicateEmails()) {
      this.error = 'Duplicate email address.';
      return;
    }

    if (this.checkIfDuplicateMobileNumber()) {
      this.error = 'Duplicate mobile number.';
      return;
    }

    if (this.contact.editedContact && this.contact.editedContact.emails && this.contact.editedContact.emails.length > 0) {
      this.contact.editedContact.emails.forEach((email, index) => {
        if (email.email === '' || email.email.length === 0) {
          this.contact.editedContact.removeEmail(index);
        }
      });
    }

    this.contact.editedContact.telephoneNumbers = this.contact.editedContact.telephoneNumbers.filter((phoneNumber, index) => { return !(phoneNumber.number === '' && index > 2); });

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.contact.editedContact.surname = this.contact.editedContact.lastName;

      if (this.contact.editedContact.equals(this.contact)) {
        this.cancel();
        return;
      }

      this.contact.editedContact.contactId = this.contact.contactId;

      const editContactPayload: any = this.contact.editedContact.editContactPayload;

      this.eventAggregator.publish(CONTACT_ACTIONS.UPDATE, editContactPayload);
      this.eventAggregator.publish('change.channel.size.hide', this.contact.contactId);
      this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
      this.eventAggregator.publish(CONTACT_ACTIONS.PREVIOUS, this.contact.contactId);
      this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, { contactId: this.contact.contactId, status: true, loader: 'contactInfo' });
      
      this.contact.customFieldsProperties = this.contact.editedContact.customFieldsProperties ? this.contact.editedContact.customFieldsProperties.map((obj) => Object.assign({}, obj)) : [];
      this.contact.customFields = editContactPayload.customFields ? editContactPayload.customFields.map((obj) => Object.assign({}, obj)) : [];
    }, (error) => {
      logger.info('Error > Updating Contact >', error);
    });
  }

  private cancel(): void {
    this.error = '';
    this.eventAggregator.publish(CONTACT_ACTIONS.PREVIOUS, this.contact.contactId);
    if (this.validation) {
      this.validation.reset();
    }
  }

  private initialiseValidation(): void {
    let maxLength = 30;
    ValidationRules
      .ensure('firstName')
      .required()
      .matches(this.firstNameRegex)
      .withMessage('Must be a valid first name.')
      .maxLength(maxLength)
      .withMessage('First name cannot exceed 30 characters.')
      .ensure('lastName')
      .required()
      .matches(this.surnameRegex)
      .withMessage('Must be a valid last name.')
      .maxLength(maxLength)
      .withMessage('Last Name cannot exceed 30 characters.')
      .on(this.contact.editedContact);
  }

  private checkIfOneNumberNotNull(): boolean {
    return this.contact.editedContact.telephoneNumbers.filter((phoneNumber) => phoneNumber.number !== '').length > 0;
  }

  private checkIfOneEmailNotNull(): boolean {
    return this.contact.editedContact.emails.filter((email) => email.email !== '').length > 0;
  }

  private checkIfDuplicateMobileNumber(): boolean {
    let mobileNumbers = this.contact.editedContact.validNumbers.filter((phoneNumber) => {
      return phoneNumber.type.toLowerCase() === 'mobile';
    });

    let duplicateNumber: boolean = false;

    const INDEX_OF_FIRST_NUMBER: number = 0;
    const INDEX_OF_SECOND_NUMBER: number = 1;
    const MAXIMUM_MOBILE_NUMBER: number = 2;


    if (mobileNumbers.length === MAXIMUM_MOBILE_NUMBER) {
      if (mobileNumbers[INDEX_OF_FIRST_NUMBER].number && mobileNumbers[INDEX_OF_SECOND_NUMBER].number) {
        duplicateNumber = mobileNumbers[INDEX_OF_FIRST_NUMBER].number === mobileNumbers[INDEX_OF_SECOND_NUMBER].number;
      }
    }

    return duplicateNumber
  }

  private checkIfNumbersInvalid(): boolean {
    const hasInvalidNumber = this.contact.editedContact.telephoneNumbers.some(_phoneNumber => {
      return !_phoneNumber.isNumberValid && _phoneNumber.number !== ''
    });
    return hasInvalidNumber
  }

  private checkDuplicateEmails(): boolean {
    const uniqueValues = this.contact.editedContact.emails.reduce((map, item) => {
      map[item.email] = (map[item.email] || 0) + 1;
      return map
    }, {});
    const duplicates = Object.keys(uniqueValues).filter((entry) => {
      return uniqueValues[entry] > 1 && entry !== '';
    });
    return duplicates.length === 0;
  }

  private checkIfEmailsInvalid(): boolean {
    const hasInvalidEmail = this.contact.editedContact.emails.some((email) => {
      return email && !email.isValid
    });
    return hasInvalidEmail;
  }

  private addEmail(): void {
    this.contact.editedContact.addEmail();
  }

  private addPhoneNumber(): void {
    this.contact.editedContact.addPhoneNumber();
  }

  private toggleConfirmDelete(indexOfItem: number): void {
    this.contact.editedContact.emails.forEach((email, index) => {
      if (index === indexOfItem) {
        if (!email.email) {
          this.removeEmail(index);
        } else {
          // @ts-ignore
          email.confirmDelete = !email.confirmDelete;
        }
      }
    });
  }

  private toggleConfirmDeletePhoneNumber(indexOfItem: number): void {
    this.contact.editedContact.telephoneNumbers.forEach((phoneNumber, index) => {
      if (index === indexOfItem) {
        if (!phoneNumber.number) {
          this.removePhoneNumber(index);
        } else {
          // @ts-ignore
          phoneNumber.confirmDelete = !phoneNumber.confirmDelete;
        }
      }
    });
  }

  private removePhoneNumber(index: number): void {
    this.contact.editedContact.removePhoneNumber(index);
  }

  private removeEmail(index: number): void {
    this.contact.editedContact.removeEmail(index);
  }

  @computedFrom('contact.editedContact.telephoneNumbers')
  get canDeleteNumbers(): boolean {
    if (this.contact.editedContact && this.contact.editedContact.telephoneNumbers) {
      const numberOfMobileNumber = this.contact.editedContact.telephoneNumbers.filter((phoneNumber) => {
        return phoneNumber.type === 'MOBILE';
      });
      return numberOfMobileNumber.length > 1;
    }
  }

  private getWrapHeight(e: any): void {
    // if the window size changes retrieve sizes
    this.wrapHeight = e.height;
    this.innerHeight = this.scrollBoxInner.clientHeight;

    this.checkForOverflow();
  }

  private getScrollHeight(e: any): void {
    // if more child elements are added retrieve sizes
    this.innerHeight = e.height;
    this.wrapHeight = this.scrollBoxWrap.clientHeight;

    this.checkForOverflow();
  }

  private checkForOverflow(): void {
    this.hasOverflow = this.innerHeight > this.wrapHeight;
  }

  private get hasErrorMessages(): boolean {
    if (this.contact && this.contact.editedContact && this.contact.editedContact.telephoneNumbers) {

      for (let number of this.contact.editedContact.telephoneNumbers) {
        if (!number.isNumberValid) {
          return true;
        }
      }
    }
    if (this.contact && this.contact.editedContact && this.contact.editedContact.emails) {

      for (let email of this.contact.editedContact.emails) {
        if (!email.isValid) {
          return true;
        }
      }
    }
    return false;
  }

  @computedFrom('contact', 'hasErrorMessages', 'validation')
  public get isSaveDisabled(): boolean {
    if (!this.contact.editedContact) {
      return false;
    }
    return this.validation.errors.length > 0 || !this.contact.editedContact.firstName || !this.contact.editedContact.lastName || this.hasErrorMessages;
  }
}
