import {LogManager} from 'aurelia-framework';

import {BaseModel} from 'zailab.abstract';
import {TaskTemplateModel} from './task-template-model';

const logger = LogManager.getLogger('ServiceWithTaskTemplatesModel');

export class ServiceWithTaskTemplatesModel extends BaseModel {

  public organisationId?: string = null;
  public serviceId: string = null;
  public serviceName: string = null;
  public taskTemplates: TaskTemplateModel[] = [];
  public isSelected: boolean = false;
  public isAdded?: boolean = false;

  constructor(service: any) {
    super();
    this.mapProperties(service);
    this.mapTaskTemplates(service.taskTemplates);
  }

  public mapTaskTemplates(taskTemplateData: any[]): void {
    this.taskTemplates = [];
    if (!taskTemplateData) {
      return;
    }
    taskTemplateData.forEach(taskTemplate => {
      this.taskTemplates.push(new TaskTemplateModel(taskTemplate));
    });
  }
}
