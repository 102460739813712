import {inject, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('SurveyListModel');
/*
 * */
export class SurveyListModel extends BaseModel {

  surveyId = null;
  description = null;
  interactionFlows = [];
  name = null;
  questions = [];
  taskTemplateName = null;
  type = null;
  selected = false;

  constructor(survey) {
    super();
    this.mapProperties(survey);
  }
}
