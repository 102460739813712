import {HttpClient} from 'aurelia-http-client';
import {LogManager, autoinject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import semver from 'semver';
/**/
import {OplogService} from 'zailab.common';
// @ts-ignore
import packageJson from '../../../package.json';
/**/
const logger = LogManager.getLogger('VersionService');

@autoinject()
export class VersionService {

  public isUpdateRequired;
  public currentVersion = packageJson.version;
  private oplogSubscription;

  constructor(private httpClient: HttpClient, private eventAggregator: EventAggregator, private oplogService: OplogService) {}

  public init(): void {

    this.retrieveLatestVersionNumber();
    this.subscribe();

  }

  private retrieveLatestVersionNumber(): void {

    this.httpClient.get('application/version/conversations').then(
      latestVersion => this.onVersionUpdated(latestVersion),
      error          => logger.info(error));
  }

  private onVersionUpdated(latestVersion: any): void {
    this.isUpdateRequired = semver.lt(this.currentVersion, latestVersion);

  }

  private subscribe(): void {

    this.subscribeToVersionUpdates();

    this.eventAggregator.subscribe('websocket.connection.connected', () => this.websocketConnected());

  }

  private subscribeToVersionUpdates(): void {

    if(this.oplogSubscription) {
      // clear stale subscriptions
      this.unsubscribeFromVersionUpdates();
    }

    this.oplogSubscription = this.oplogService.subscribeOn('application', 'conversations').in('version-service.versionServiceView');
    this.oplogSubscription.on('update', data => this.onVersionUpdated(data.version));
    this.oplogSubscription.on('insert', data => this.onVersionUpdated(data.version));

  }

  private websocketConnected(): void {

    this.subscribeToVersionUpdates();
    this.retrieveLatestVersionNumber();
  }

  private unsubscribeFromVersionUpdates(): void {

    this.oplogSubscription.unsubscribe();
  }
}
