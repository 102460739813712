/**
 * Draw IO :: Feature model :: Update when making feature changes
 * https://www.draw.io/#G15PPElZ3HONpiWPLoQP-F0gIJrxCB-F39
 */
import {LogManager, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { SMSGatewayService } from '../sms-gateway-service';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { AbstractList, List } from 'zailab.abstract';
import { EventAggregator } from 'aurelia-event-aggregator';
import { MESSAGE_EVENTS, SessionStore } from 'zailab.common';
import { SMSGatewayAccountConfigDialog } from './add-dialog/add sms-gateway-account-dialog';

const logger = LogManager.getLogger('SMSGatewayAccountList');

@autoinject
export class SMSGatewayAccountList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders: number = 0;
  public container: any;
  public actions = [{
    action: 'edit',
    label: 'Edit',
    icon: 'edit'
  }];

  public itemList: any;

  private queryParams: {
    gatewayId: string;
    'add-account'?: string;
  };

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private SMSGatewayService: SMSGatewayService,
    private sessionStore: SessionStore,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public activate(params: any): void {
    this.queryParams = params;
  }

  public attached(): void {
    this.retrieveSMSGatewayAccounts();
  }

  private async retrieveSMSGatewayAccounts(): Promise<void> {
    try {
      let gatewayAccounts = await this.SMSGatewayService.retrieveSMSGatewayAccounts(this.sessionStore.get.organisation.organisationId, this.queryParams.gatewayId);
      gatewayAccounts = gatewayAccounts.map(gateway => {
        gateway.actions = this.actions;
        return gateway;
      });

      this.itemList = List.Builder() //
        .required(true) //
        .customEventElement(this.element)
        .enableClick()
        .enableAdd()
        .enableDelete()
        .selectionType(List.MULTIPLE_SELECTION_TYPE) //
        .autoSelect(false)
        .items(gatewayAccounts) //
        .uniqueIdentifier('id') //
        .hasRollover(false)
        .tilesPerRow(5)
        .disableOrderBy()
        .build();


      if (this.queryParams['add-account']) {
        this.openGatewayAccountConfigDialog();
        
        // @ts-ignore
        const url = new URL(window.location);
        url.searchParams.delete('add-account');
        window.history.pushState(null, '', url.toString());
      }

      this.generatePlaceholders();
    } catch(e) {
      logger.warn('Failed to retrieve sms gateways due to', e);
    }
  }

  public select(item: any): void {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.itemList.select && !this.itemList.isDeleting) {
      this.openGatewayAccountConfigDialog(item);
      return;
    }
    if (this.itemList.isDeleting && item.canDelete !== false) {
      this.itemList.selectToDelete(item);
    } else {
      logger.warn('Cannot select item >>>', item);
    }
  }

  public deleteItems(): void {
    let itemsToDelete: ISMSGatewayAccount[] = [];
    let remainingItems: ISMSGatewayAccount[] = [];

    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      } else {
        remainingItems.push(item);
      }
    }
    super.deleteItems(itemsToDelete);
    this.itemList.items = remainingItems;
    this.itemList.isDeleting = false;
    this.hideLoader();
    this.generatePlaceholders();

    if (itemsToDelete.length === 1) {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Account ${itemsToDelete[0].name} deleted.`);
    } else if (itemsToDelete.length > 1) {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `${itemsToDelete} Accounts deleted.`);
    }
  }
  
  protected delete(smsGatewayAccount: ISMSGatewayAccount): void {
    this.SMSGatewayService.deleteGatewayAccount(this.sessionStore.get.organisation.organisationId, this.queryParams.gatewayId, smsGatewayAccount.id);
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  public openGatewayAccountConfigDialog(gatewayAccount?: ISMSGatewayAccount): void {
    this.dialogService
      .open({
          viewModel: SMSGatewayAccountConfigDialog,
          model: {
            gatewayId: this.queryParams.gatewayId,
            gatewayAccountNames: this.itemList.items.map(gw => { return gw.name; }),
            gatewayAccount
          }
        })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          
          let _gatewayAccount = response.output;
          if (gatewayAccount) {
            let gatewayAccountToUpdate = this.itemList.items.find((gwa: ISMSGateway) => {
              if (gwa.id === _gatewayAccount.id) {
                return true;
              }
            });

            if (gatewayAccountToUpdate) {
              gatewayAccountToUpdate.name = _gatewayAccount.name;
              gatewayAccountToUpdate.host = _gatewayAccount.host;
              gatewayAccountToUpdate.port = _gatewayAccount.port;
            }
          } else {
            this.itemList.items.push({
              ..._gatewayAccount,
              actions: this.actions
            });
          }
        }
      });
  }

  public back(): void {
    this.router.parent.navigate('smsgateways');
  }
}