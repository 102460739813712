import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('ViewLoadBalancerDialog');

@autoinject
export class ViewLoadBalancerDialog {

  private dialogHeader: string = 'Load Balancer';
  private loadBalancer: ZLoadBalancer;
  private loadBalancerTabs: {id: string, name: string, viewModel: string, icon: string, data: any}[];

  constructor(private dialogController: DialogController) {}

  private activate(loadBalancer: ZLoadBalancer): void {
    this.loadBalancer = loadBalancer;
    this.dialogHeader += `: ${loadBalancer.name}`;
    this.loadBalancerTabs = [
      { id: '1', name: 'Default', viewModel: PLATFORM.moduleName('features/interaction/loadbalancer/view/default'), icon: 'split', data: loadBalancer },
      { id: '2', name: 'Temporary', viewModel: PLATFORM.moduleName('features/interaction/loadbalancer/view/temporary'), icon: 'split', data: loadBalancer },
    ];
  }

  /**
   * Closes the Aurelia dialog
   */
  private cancel(): void {
    this.dialogController.cancel();
  }
}