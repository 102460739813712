import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DashboardWidget } from '../../organisation/organisation/dashboard/dashboard-abstract';


const logger = LogManager.getLogger('ResponseTemplateWidget');

@autoinject
export class ResponseTemplateWidget extends DashboardWidget {

  constructor(public router: Router) {
    super();
  }

}
