import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('ExportDialog');

@autoinject
export class ExportDialog {
  public options = ['CSV', 'JPEG'];
  public type = 'CSV';

  constructor(private dialogController: DialogController) {}

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.dialogController.ok(this.type);
  }
}
