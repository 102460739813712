import { DialogController } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { AgentScriptFlow } from "./agent-script-designer-model";

const logger = LogManager.getLogger('AgentScriptPreviewDialog');

@autoinject
export class AgentScriptPreviewDialog {

  public flow: AgentScriptFlow;

  constructor(
    private dialogController: DialogController,
  ) { }

  public activate(model: AgentScriptFlow) {
    this.flow = model;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}