import {DateFormat} from './date-format';

export class CategoryAxisConfig {
  public autoGridCount: boolean = true;
  public boldPeriodBeginning: boolean = false; //
  public equalSpacing: boolean = true;
  public parseDates: boolean = true;
  public minPeriod: string = 'hh';
  public gridPosition: string = 'start';
  public axisAlpha: number = 0;
  public gridAlpha: number = 0;
  public position: string = 'left';
  public minHorizontalGap: number = 100;
  public dateFormats: DateFormat[] = [new DateFormat('hh', 'JJ:NN'), new DateFormat('DD', 'EEE, DD MMM'), new DateFormat('WW', 'week W')];

  constructor() {

  }
}