import { bindable, customElement } from "aurelia-framework";

@customElement('z-rich-text-editor')
export class RichTextEditor {

  @bindable
  public value: string;
  
  public scriptErrorMessage: string;
  public editorOptions = {
    formats: [
      'bold',
      'italic',
      'underline',
      'strike',
      'list',
      'font',
      'color',
      'background',
      'align'
    ],
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['clean']
      ]
    }
  }

  public handleValueChanged = (newValueChange: any): void => {
    if (newValueChange.text === '') {
      this.scriptErrorMessage = 'Please enter value.';
    } else {
      this.scriptErrorMessage = '';
    }
  }
}