import {inject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';

/*
 */
@inject(Router)
export class DispositionCodes {

  constructor(private router: Router) {

    this.router = router;
  }

  public configureRouter(config: any, router: Router): void {

    let routeMap = [
      {route: '',       name: 'list',   moduleId: PLATFORM.moduleName('./list/disposition-codes-list'),      nav: false, title: 'List',    isTwelveColumnRoute: true},
      {route: 'add-list', name: 'addlist', moduleId: PLATFORM.moduleName('./addlist/add-disposition-codes-list'),  nav: false, title: 'Create',  isTwelveColumnRoute: true},
      {route: 'edit-list/:dispositionlist', name: 'editlist', moduleId: PLATFORM.moduleName('./editlist/edit-disposition-list'),  nav: false, title: 'Edit',  isTwelveColumnRoute: true},
      {route: 'edit-tier1-list/:dispositionlist', name: 'editlist', moduleId: PLATFORM.moduleName('./tiered/edit-tiered-disposition-list'),  nav: false, title: 'Tier 1',  isTwelveColumnRoute: true},
      {route: 'edit-tier2-list/:dispositionlist/:category', name: 'editlist', moduleId: PLATFORM.moduleName('./tiered/edit-tiered-disposition-list'),  nav: false, title: 'Tier 2',  isTwelveColumnRoute: true},
      {route: 'edit-tier3-list/:dispositionlist/:category/:subcategory', name: 'editlist', moduleId: PLATFORM.moduleName('./editlist/edit-disposition-list'),  nav: false, title: 'Tier 3',  isTwelveColumnRoute: true}
    ];

    config.map(routeMap);

    this.router = router;
  }
}
