import {bindable, computedFrom, customElement, inject, LogManager, NewInstance} from 'aurelia-framework';
import {validateTrigger, ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {observable} from "aurelia-binding";

import moment from 'moment-timezone';

import {BootstrapFormRenderer, SessionStore} from 'zailab.common';
import {ReportsService} from '../../../features/organisation/report/reports.service';
import {ReportBuilder} from "../../../features/organisation/report/report-builder";
import {ReportDownloader} from "../../../features/organisation/report/report-downloader";
import {UserSessionModel} from '../../../_common/stores/sessionmodels/user-session-model';
import {MembersService} from '../../../features/organisation/member/members/members-service';

const logger = LogManager.getLogger('ZReportInputForm');

@customElement('z-report-input-form')
@inject(NewInstance.of(ReportBuilder), ReportsService, ValidationControllerFactory, SessionStore, MembersService)
export class ReportInputForm {

  @bindable public reportsCategory: string = '';
  @bindable public config: IReportInputFormConfig = {
    showServices: false,
    showRoles: false,
    showTimeBuckets: false,
    showBusinessPartners: false,
    requiredService: false,
    showTeams: false,
    showMembers: false,
    showTimezone: true,
    showDate: true,
    withChannel: null
  };
  @bindable public reportName: string = '';
  @bindable public schedule: boolean = false;
  @bindable public reportParameters: any = {};

  protected previousDate: moment = null;
  @observable protected selectedDate: moment = null;
  @observable protected selectedStartDate: moment = null;
  @observable protected selectedEndDate: moment = null;
  protected canDownload: boolean = false;
  protected validation = null;
  protected dateFormat: string = 'DD MMM YYYY';
  protected formattedDate: moment;
  protected downloading: boolean = false;
  protected downloadStatusMessage: string = '';

  private services: any = [];
  private roles: any = [];
  private timeBuckets: any[] = [];
  private businessPartners: any = [];

  private teams: Array<{ teamId: string, teamName: string }> = [];

  private agents: Array<{ memberId: string, firstName: string, surname: string, agentFullName: string }> = [];
  private noAgents: boolean = false;

  private active: boolean = false;

  constructor(
    private reportBuilder: ReportBuilder,
    private reportsService: ReportsService,
    protected validationControllerFactory: ValidationControllerFactory,
    protected sessionStore: SessionStore,
    private membersService: MembersService
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.setPreviousDate();
  }

  private created(): void {
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public attached(): Promise<any> {
    this.active = false;
    const promises: Promise<any>[] = [];
    if (this.schedule || this.config.showServices) {
      const memberId: string = this.sessionStore.get.user.memberId;
      promises.push(this.retrieveMemberServices(memberId));
    }
    if (this.schedule || this.config.showRoles) {
      promises.push(this.retrieveRoles());
    }
    if (this.schedule || this.config.showBusinessPartners) {
      promises.push(this.retrieveBusinessPartners());
    }
    if (this.schedule || this.config.showTimeBuckets) {
      this.initTimeBuckets();
    }
    if (this.schedule || this.config.showTeams) {
      if (this.isAdmin){
        promises.push(this.retrieveOrganisationTeams());
      } else {
        promises.push(this.retrieveMemberTeams());
      }
    }
    this.setupDayInMonthValidation();
    this.setDate();
    return Promise.all(promises)
      .then(() => {
        this.active = true;
        return {};
      });
  }

  public configChanged(newValue: IReportInputFormConfig, oldValue: IReportInputFormConfig): void {
    if (this.schedule) {
      const memberId: string = this.sessionStore.get.user.memberId;
      this.retrieveMemberServices(memberId);
      if (this.isAdmin) {
        this.retrieveOrganisationTeams();
      } else {
        this.retrieveMemberTeams();
      }
    }
  }

  protected setPreviousDate(): void {
    this.previousDate = moment.utc().subtract(1, 'days').format(this.dateFormat);
  }

  protected setDate(): void {
    this.previousDate = moment.utc().format(this.dateFormat);
    this.selectedStartDate = moment(this.previousDate).subtract(89, 'days').format(this.dateFormat);
    this.selectedEndDate = this.previousDate;
  }

  public selectTeam(value: any): void {
    if (!this.active) {
      return;
    }
    this.selectedTeam = value;
    this.retrieveAgentsFromSelectedTeam(value);
  }

  public selectService(value: any): void {
    if (!this.active) {
      return;
    }
    this.selectedService = value;
  }

  public selectRole(value: any): void {
    if (!this.active) {
      return;
    }
    this.selectedRole = value;
  }

  public selectTimeBucket(value: string): void {
    if (!this.active) {
      return;
    }
    this.selectedTimeBucket = value;
  }

  public selectBusinessPartner(value: any): void {
    if (!this.active) {
      return;
    }
    this.selectedBusinessPartner = value;
  }

  private retrieveOrganisationServices(): Promise<any> {
    return this.reportsService.retrieveOrganisationServices().then((response) => {
      this.services = response;
      if (!this.config.requiredService) {
        this.services.splice(0, 0, { serviceName: 'All' });
      }
      if (this.reportParameters.service && this.reportParameters.service !== 'All') {
        this.reportParameters.selectedService = this.services.find((service) => service.serviceId === this.reportParameters.service);
      } else {
        this.reportParameters.selectedService = this.services[0];
      }
    });
  }

  private retrieveMemberServices(memberId:any): Promise<any> {
    return this.reportsService.retrieveMemberServices(memberId).then((response) => {
      this.services = response;
      if (!this.config.requiredService) {
        this.services.splice(0, 0, { serviceName: 'All' });
      }
      if (this.reportParameters.service && this.reportParameters.service !== 'All') {
        this.reportParameters.selectedService = this.services.find((service) => service.serviceId === this.reportParameters.service);
      } else {
        this.reportParameters.selectedService = this.services[0];
      }
    });
  }

  private retrieveRoles(): Promise<any> {
    return this.reportsService.retrieveRoles().then((response) => {
      this.roles = response;
      this.roles.splice(0, 0, { roleValue: 'ALL', roleName: 'All' });
      if (this.reportsCategory !== 'peak-concurrent-licence-report') {
        this.roles.splice(0, 0, { roleValue: 'NA', roleName: 'N/A'});
      }
      this.reportParameters.selectedRole = this.roles[0];
    });
  }

  private retrieveBusinessPartners(): Promise<any> {
    return this.reportsService.retrieveBusinessPartners(this.user.memberId).then((businessPartners) => {
      this.businessPartners = businessPartners;
      this.businessPartners.splice(0, 0, {id: 'ALL', name: 'All'});
      if (this.reportsCategory !== 'peak-concurrent-licence-report') {
        this.businessPartners.splice(0, 0, {id: 'NA', name: 'N/A'});
      }
      this.reportParameters.selectedBusinessPartner = this.businessPartners[0];
    });
  }

  private initTimeBuckets(): void {
    this.timeBuckets = [
        {timeBucketValue: 'HALF_HOUR', timeBucketName: '30 Min'},
        {timeBucketValue: 'HOUR', timeBucketName: '1 Hour'},
        {timeBucketValue: 'DAY', timeBucketName: '1 Day'},
        {timeBucketValue: 'WEEK', timeBucketName: '1 Week'},
        {timeBucketValue: 'MONTH', timeBucketName: '1 Month'}
      ];
    this.reportParameters.selectedTimeBucket = this.timeBuckets[0];
  }

  private retrieveOrganisationTeams(): Promise<any> {
    return this.reportsService.retrieveOrganisationTeams().then((response) => this.teamsRetrieved(response));
  }

  private teamsRetrieved(response: any[]): any {
    this.teams = this.isAdmin ? [{ teamId: null, teamName: "All" }] : [];
    if (!response || !response.length) {
      return;
    }
    
    let tmpTeams = response.map(team => { return { teamId: team.id, teamName: team.name } });
    tmpTeams.sort(function (a: any, b: any): number {
      return a.teamName.toLowerCase().localeCompare(b.teamName.toLowerCase());
    });

    this.teams = this.teams.concat(tmpTeams);

    return this.continueTeamsSelection();
  }

  private retrieveMemberTeams(): Promise<any> {
    return this.membersService
      .retrieveMember(this.user.memberId)
      .then((member) => this.memberTeamsRetrieved(member.teams))
  }

  private memberTeamsRetrieved(response: any[]): any {
    this.teams = this.isAdmin ? [{ teamId: null, teamName: "All" }] : [];
    if (!response || !response.length) {
      return;
    }
    response.sort(function (a: any, b: any): number {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    this.teams = this.teams.concat(response.map(team => {return {teamId: team.id, teamName: team.name}}));
    return this.continueTeamsSelection();
  }

  private async continueTeamsSelection(): Promise<void> {
    if (this.teams && this.teams.length > 0) {
      if (this.reportParameters.team && this.reportParameters.team !== 'All') {
        this.reportParameters.selectedTeam = this.teams.find((team) => team.teamId === this.reportParameters.team);
      } else {
        this.reportParameters.selectedTeam = this.teams[0];
      }
      if (this.selectedTeam) {
        return this.retrieveAgentsFromSelectedTeam(this.selectedTeam);
      }
    }
  }

  private retrieveAgentsFromSelectedTeam(selectedTeam: any): Promise<any> {
    if (selectedTeam && selectedTeam.teamId) {
      return this.reportsService.retrieveAgentsForSelectedTeam(selectedTeam, false).then((response) => {
        this.agents = response;
        return this.setAgents();
      });
    }
    return this.reportsService.retrieveAllAgents(selectedTeam, false).then((response) => {
      this.agents = response;
      return this.setAgents();
    });
  }

  private setAgents(): Promise<any> {
    return new Promise((resolve) => {
      if (this.agents && this.agents.length > 0) {
        this.agents.map(agent => {
          agent.agentFullName = agent.firstName + ' ' + agent.surname;
        });
        this.agents.sort((a: any, b: any): number => {
          return a.agentFullName.toLowerCase().localeCompare(b.agentFullName.toLowerCase());
        });
        if (this.reportParameters.agent) {
          this.reportParameters.selectedAgent = this.agents.find((agent) => agent.memberId === this.reportParameters.agent);
        } else {
          this.reportParameters.selectedAgent = this.agents[0];
        }
        this.noAgents = false;
      } else {
        this.agents = [{ memberId: '', firstName: '', surname: '', agentFullName: 'No agents' }];
        this.reportParameters.selectedAgent = this.agents[0];
        this.noAgents = true;
      }
      this.validate();
      resolve();
    });
  }

  public selectAgent(value: any): void {
    if (!this.active) {
      return;
    }
    this.selectedAgent = value;
  }

  @computedFrom('selectedTeam')
  private get teamName(): string {
    if (this.selectedTeam) {
      return this.selectedTeam.teamName;
    }
  }

  @computedFrom('selectedAgent')
  private get agentFullName(): string {
    if (this.selectedAgent) {
      return this.selectedAgent.agentFullName;
    }
  }

  @computedFrom('selectedStartDate', 'selectedEndDate', 'agentFullName', 'selectedService')
  protected get fileName(): string {
    let _fileName = `${this.reportName}`;
    if (this.config.showServices && this.selectedService && this.selectedService.serviceName) {
      _fileName += ` ${this.selectedService.serviceName}`;
    }
    if (this.config.showMembers && this.agentFullName) {
      _fileName += ` ${this.agentFullName}`;
    }
    if (this.config.showTeams && !this.config.showMembers && this.teamName) {
      _fileName += ` ${this.teamName}`;
    }
    if (this.config.showDate && this.selectedStartDate && this.selectedEndDate) {
      _fileName += ` ${this.selectedStartDate} to ${this.selectedEndDate}`;
    }
    _fileName += `.zip`;
    return _fileName.replace(/ /g, '-');
  }

  @computedFrom('selectedStartDate', 'selectedEndDate')
  public get validTimeFrame(): boolean {
    let threeMonthDifference = moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'months', true);
    return threeMonthDifference < -3;
  }

  @computedFrom('sessionStore.get.user')
  private get isAdmin(): boolean {
    return this.sessionStore.get.user.hasAdministratorRole;
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  private setupDayInMonthValidation(): void {
    ValidationRules.customRule('dateInPast', (value: any) => {
      let selectedDateMoment = moment(value).format(this.dateFormat);
      return !moment(this.previousDate).isBefore(selectedDateMoment);
    }, 'Please enter a past date.');

    ValidationRules.customRule('validDateValue', (value: any) => {
      return moment(value).isValid();
    }, 'The date value is incorrect.');

    ValidationRules.customRule('validDateRange', (value: any) => {
      let threeMonthDifference = moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'months', true);
      if (threeMonthDifference > 0) {
        return false;
      }
      return true;
    }, 'The date range is incorrect.');

    ValidationRules.customRule('validTimeFrame', (value: any) => {
      let threeMonthDifference = moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days', true);
      if (threeMonthDifference < -89) {
        return false;
      }
      return true;
    }, 'From Date and To Date can not be longer than 89 Days apart.');

    ValidationRules.customRule('noAgentsForTeam', (value: any) => {
      return !this.noAgents;
    }, 'There are no agents for this team');

    ValidationRules
      .ensure('selectedStartDate').required().when(() => this.config.showDate).withMessage('Please enter a date.').satisfiesRule('dateInPast').satisfiesRule('validTimeFrame').satisfiesRule('validDateValue')
      .ensure('selectedEndDate').required().when(() => this.config.showDate).withMessage('Please enter a date.').satisfiesRule('dateInPast').satisfiesRule('validTimeFrame').satisfiesRule('validDateValue').satisfiesRule('validDateRange')
      .ensure('selectedTeam').required().when(() => this.config.showTeams).withMessage('Please select a team.')
      .ensure('selectedAgent').required().when(() => this.config.showTeams).withMessage('Please select an agent.').satisfiesRule('noAgentsForTeam')
      .on(this);
  }

  protected selectedStartDateChanged(): void {
    this.validate();
  }

  protected selectedEndDateChanged(): void {
    this.validate();
  }

  protected validate(): void {
    if (!this.selectedDate) {
      this.formattedDate = moment.utc(this.previousDate);
    } else {
      this.formattedDate = moment.utc(this.selectedDate).format('x');
    }
    if (this.validation) {
      this.validation.validate().then(validation => {
        this.canDownload = validation.valid;
      });
    }
  }

  private downloadReport(): void {
    let baseUrl = 'v3/performance-management/reports/' + this.reportsCategory;
    this.downloading = true;
    this.makeQueryParameters();
    let reportDownloader: ReportDownloader = this.reportBuilder
      .withUrl(baseUrl)
      .withFileName(this.fileName)
      .build();
    reportDownloader.download().then(
      () => {
        this.downloading = false;
        this.downloadStatusMessage = 'Download Successful';
      },
      (e) => {
        this.downloading = false;
        if (e.statusCode === 429) {
          this.downloadStatusMessage = 'Our reporting service is at capacity. Please try again later.';
        } else {
          this.downloadStatusMessage = 'Download Unsuccessful';
        }
      }
    );
  }

  protected makeQueryParameters(): void {
    if (this.config.withChannel) {
      this.reportBuilder.withParams('channel', this.config.withChannel);
    }
    if (this.config.showDate) {
      let endDate = moment(this.selectedEndDate).format('ddd, DD MMM YYYY [00:00:00 GMT]');
      this.reportBuilder.withParams('endDate', endDate);
      let startDate = moment(this.selectedStartDate).format('ddd, DD MMM YYYY [00:00:00 GMT]');
      this.reportBuilder.withParams('startDate', startDate);
    }
    if (this.config.showServices) {
      this.reportBuilder.withParams('serviceName', this.selectedService.serviceName);
      this.reportBuilder.withParams('serviceId', this.selectedService.serviceId);
    }
    if (this.config.showTeams) {
      this.reportBuilder.withParams('teamId', this.selectedTeam.teamId);
      this.reportBuilder.withParams('teamName', this.teamName.replace(/ /g, '-'));
    }
    if (this.config.showMembers) {
      this.reportBuilder.withParams('memberId', this.selectedAgent.memberId);
      this.reportBuilder.withParams('fullName', this.agentFullName.replace(/ /g, '-'));
    }
    if (this.config.showTimezone) {
      this.reportBuilder.withParams('timezone', this.selectedTimezone.name);
    }
    if (this.config.showRoles) {
      if (this.selectedRole.roleValue === 'NA') {
        this.reportBuilder.removeParams('role');
      } else {
        this.reportBuilder.withParams('role', this.selectedRole.roleValue);
      }
    }
    if (this.config.showBusinessPartners) {
      if (this.selectedBusinessPartner.id === 'NA') {
        this.reportBuilder.removeParams('businessPartner');
      } else {
        this.reportBuilder.withParams('businessPartner', this.selectedBusinessPartner.id);
      }
    }
    if (this.config.showTimeBuckets) {
      this.reportBuilder.withParams('timeBucketInterval', this.selectedTimeBucket.timeBucketValue);
    }
  }

  public get selectedService(): any {
    return this.reportParameters.selectedService;
  }

  public set selectedService(selectedService: any) {
    this.reportParameters.selectedService = selectedService;
  }

  public get selectedRole(): any {
    return this.reportParameters.selectedRole;
  }

  public set selectedRole(selectedRole: any) {
    this.reportParameters.selectedRole = selectedRole;
  }

  public get selectedBusinessPartner(): any {
    return this.reportParameters.selectedBusinessPartner;
  }

  public set selectedBusinessPartner(selectedBusinessPartner: any) {
    this.reportParameters.selectedBusinessPartner = selectedBusinessPartner;
  }

  public get selectedTimeBucket(): any {
    return this.reportParameters.selectedTimeBucket;
  }

  public set selectedTimeBucket(selectedTimeBucket: any) {
    this.reportParameters.selectedTimeBucket = selectedTimeBucket;
  }

  public get selectedTeam(): { teamId: string, teamName: string } {
    return this.reportParameters.selectedTeam;
  }

  public set selectedTeam(selectedTeam: { teamId: string, teamName: string }) {
    this.reportParameters.selectedTeam = selectedTeam;
  }

  public get selectedAgent(): { memberId: string, firstName: string, surname: string, agentFullName: string } {
    return this.reportParameters.selectedAgent;
  }

  public set selectedAgent(selectedAgent: { memberId: string, firstName: string, surname: string, agentFullName: string }) {
    this.reportParameters.selectedAgent = selectedAgent;
  }

  public get selectedCountry(): any {
    return this.reportParameters.selectedCountry;
  }

  public set selectedCountry(selectedCountry: any) {
    this.reportParameters.selectedCountry = selectedCountry;
  }

  public get selectedTimezone(): any {
    return this.reportParameters.selectedTimezone;
  }

  public set selectedTimezone(selectedTimezone: any) {
    this.reportParameters.selectedTimezone = selectedTimezone;
  }
}
