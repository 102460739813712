import {LogManager, bindable, inject, bindingMode, observable} from "aurelia-framework";
import autocomplete, { AutocompleteItem, AutocompleteResult } from "autocompleter";
import { ValidationControllerFactory, ValidationRules, validateTrigger, ValidationController } from 'aurelia-validation';

const logger = LogManager.getLogger('ZEmailTagInputV2');

const CLASS_NAME_GROUP = "group";
const CLASS_NAME_AUTOCOMPLETE = "autocomplete";

const CLASS_NAME_AUTOCOMPLETE_LIST_HEADER = "autocomplete-header";
const CLASS_NAME_AUTOCOMPLETE_LIST_HEADER_ICON = "autocomplete-header__icon";
const CLASS_NAME_AUTOCOMPLETE_LIST_HEADER_CONTACT = "autocomplete-header__name";

const CLASS_NAME_AUTOCOMPLETE_ITEM = "autocomplete-item";

const getSelectedAutocompleteItemElement = () => {
  return document.querySelector(`.${CLASS_NAME_AUTOCOMPLETE} .selected`);
};

const createAutocompleteHeaderElement = (iconInitials, contactName) => {
  // required elements
  const outer = document.createElement("div");
  const icon = document.createElement("div");
  const contact = document.createElement("div");

  outer.className = CLASS_NAME_AUTOCOMPLETE_LIST_HEADER;

  icon.className = CLASS_NAME_AUTOCOMPLETE_LIST_HEADER_ICON  + " profile-circle label medium blue-base-dark bold";
  icon.textContent = iconInitials;

  contact.className = CLASS_NAME_AUTOCOMPLETE_LIST_HEADER_CONTACT;
  contact.textContent = contactName;

  outer.appendChild(icon);
  outer.appendChild(contact);
  return outer;
};

const createRenderItemElement = (item: EmailInput) => {
  const div = document.createElement("div");
  div.className = `${CLASS_NAME_AUTOCOMPLETE_ITEM} medium`;
  div.textContent = item.label;
  return div;
};

const createRenderGroupElement = (groupName: string) => {
  const div = document.createElement("div");
  div.classList.add(CLASS_NAME_GROUP);
  div.textContent = groupName;
  return div;
};

export interface EmailInput {
  label: string;
}

export interface EmailTag {
  label: string;
  isInvalid?: boolean;
  tooltip?: string;
}

type AutocompleteClient = EmailInput & AutocompleteItem;

@inject(ValidationControllerFactory)
export class ZEmailTagInputV2 {
  @bindable()
  contactName: string;

  @bindable()
  contactInitials: string;

  @bindable()
  initialItems: Array<EmailTag> = [];

  @bindable({ defaultBindingMode: bindingMode.oneWay })
  options: Array<EmailInput> = [];

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public value: Array<EmailTag> = [];

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  isValid: boolean;

  @bindable disabled: boolean;

  // ------------------------------------------------------------------------------------
  // internal state
  @observable
  private items: Array<EmailTag> = [];

  private invalidItemCount: number = 0 ;

  private component: HTMLInputElement;
  private textInput: HTMLInputElement;
  private controller: ValidationController = null;
  private autocomplete: AutocompleteResult;

  private autocompleteMouseDown: EventListenerOrEventListenerObject;

  protected focussed: boolean = false;

  constructor(private validationControllerFactory: ValidationControllerFactory) {
    this.controller = validationControllerFactory.createForCurrentScope();
    this.controller.validateTrigger = validateTrigger.manual;
  }

  public bind(): void {
    // TODO: Implement Deep Copy
    this.invalidItemCount = 0;
    const copyOfItems = this.value.slice();
    this.value = [];

    copyOfItems.forEach((it) => {
      // TODO: At this point, we want to do this without firing event
      this.addTag(it);
    });
    this.updateValid();
  }

  attached(): void {
    // delays autocomplete "onSelect" until mouseup
    this.autocompleteMouseDown = (evt: Event) => {
      evt.preventDefault();
    };

    // TODO: Decouple autocomplete from this component
    this.autocomplete = autocomplete<AutocompleteClient>({
      minLength: 1,
      className: CLASS_NAME_AUTOCOMPLETE,
      input: this.textInput,
      fetch: (text: string, update: (items: EmailInput[]) => void) => {
        text = text.toLowerCase().trim();
        const suggestions = this.options.filter(n => n.label.toLowerCase().trim().startsWith(text))
        update(suggestions);
      },
      onSelect: (item: EmailInput, input: HTMLInputElement) => {
        this.addTag(item);
        input.focus();
      },
      render: (item: EmailInput) => {
        return createRenderItemElement(item);
      },
      renderGroup: (groupName) => {
        return createRenderGroupElement(groupName);
      },
      customize: (input: HTMLInputElement, inputRect: ClientRect | DOMRect, container: HTMLDivElement, maxHeight: number): void => {
        const autoCompleteHeaderElement = createAutocompleteHeaderElement(this.contactInitials, this.contactName);
        container.insertAdjacentElement('afterbegin',autoCompleteHeaderElement);
        container.addEventListener('mousedown', this.autocompleteMouseDown);
      }
    });
  }

  unbind(): void {
    if (this.autocomplete) {
      this.autocomplete.destroy();
    }
  }

  // Aurelia convention - called after textInput has changed
  protected textInputChanged(evt: KeyboardEvent): boolean {
    const key = evt.key;

    let returnVal = false;
    switch (key) {
      case "Enter":
      case "Tab":
      case ";":
      case ",":
      case " ":
        if (this.textInput.value.trim() === "") {
          // generally, do nothing
          if (key === "Tab") {
            // Default tab behaviour text input, and move to next tab index
            this.textInput.value = "";
            // allow default behaviour (focus next element)
            returnVal = true;
            break;
          }
        } else {
          // check if we have autocomplete item;
          const selected = getSelectedAutocompleteItemElement();
          // in this case, we want to see if Space was pressed to "select" an autocomplete suggestion
          if (key === " ") {

            if (selected) {
              const newTag: EmailInput = {
                label: selected.textContent.trim(),
              };

              this.addTag(newTag);
              returnVal = false;
              break;
            }
          }

          if (key === "Tab") {
            const selected = getSelectedAutocompleteItemElement()
            if (selected) {
              const newTag: EmailInput = {
                label: selected.textContent.trim(),
              };
              this.addTag(newTag);
              returnVal = false;
              selected.parentElement.remove();
              break;
            }
          }

          // we're dealing with input, perhaps pasted in, so ensure we tokenize appropriately
          const inputs = this.textInput.value.split(/[\s,;]+/);
          inputs.forEach((it) => {
            const newTag: EmailInput = {
              label: it,
            };

            this.addTag(newTag);
            returnVal = false;
          })
        }
        break;
      case "Backspace":
        // Backspace - if text empty, then remove last tag
        if ((this.textInput.value === "") && (this.items.length > 0)) {
          this.removeTag(this.items.length -1);
        }
        returnVal = true;
        break;
      default:
        // do nothing
        returnVal = true;
        break;
    }
    return returnVal;
  }

  protected addTag(item: EmailInput): void {
    const normalizedItem = Object.assign({}, item, {
      label: item.label ? item.label.trim() : ''
    });


    ValidationRules
      .ensure('label')
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/)
      .withMessage('Please enter a valid email.')
      .on(normalizedItem);

    // do validation checks here
    this.controller.validate({ object: normalizedItem })
    .then(result => {
      const newTag: EmailTag = normalizedItem;
      
      if (normalizedItem.label && /\$\{(.*?)\}/gm.test(normalizedItem.label)) {
        newTag.isInvalid = false;
      } else {
        newTag.isInvalid = !result.valid;
      }

      if ((newTag.isInvalid) && (result.results.length > 0)) {
        newTag.tooltip = result.results[0].message;
      }

      // update isInvalid
      this.incrementInvalidCount(newTag.isInvalid);

      // add tag
      this.items.push(newTag);

      this.value = this.value.concat(Object.assign({}, newTag));
      this.textInput.value = "";
      this.controller.reset();
    }).catch((err) => {
      this.controller.reset();
    })
  }

  // using indexes as array may contain multiple instances of the same entry
  protected removeTag(idx:number): void {
    if ((this.items.length > 0) && (idx < this.items.length)) {

      // update isValid
      this.decrementInvalidCount(this.items[idx].isInvalid);

      this.items.splice(idx,1);

      this.value = this.items.map((it) => Object.assign({},it));
      this.textInput.value = "";
    }
  }

  private incrementInvalidCount(isInvalid: boolean): void {
    if (isInvalid) {
      this.invalidItemCount += 1;
    }
    this.updateValid();
  }

  private decrementInvalidCount(isInvalid: boolean): void {
    if (isInvalid) {
      this.invalidItemCount -= 1;
    }
    this.updateValid();
  }

  private updateValid(): void {
    this.isValid = !(this.invalidItemCount > 0);
  }

  // when we get focus on text input, allow entire component to be styled
  protected focusIn(evt: Event): boolean {
    this.focussed = true;
    return false;
  }

  protected setInputFocus(evt: Event): boolean {
    this.textInput.focus();
    return false;
  }
  
  protected focusOut = (evt: Event): boolean => {
    // when we lose focus, create tag with input text
    if (document.activeElement !== this.textInput) {
      this.focussed = false;

      const inputs = this.textInput.value.split(/[\s,;]+/);
      this.textInput.value = "";
      inputs.forEach((it) => {
        if (!it || it.length === 0) {
          return;
        }
        const newTag: EmailInput = {
          label: it,
        };
        this.addTag(newTag);
      })
    }
    return false;
  }

  // class field syntax - prevents having to bind by using an arrow function
  public onCloseTag = (data) => {
    const { index } = data;
    this.removeTag(parseInt(index, 10));
  }

  private detached(): void {
    this.invalidItemCount = 0;
    this.items = [];
    this.autocompleteMouseDown = null;
  }
}
