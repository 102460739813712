import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('OrganisationNumberModel');

export class OrganisationNumberModel extends BaseModel {

  numberId = null;
  country = null;
  provider = null;
  externalReference = null;
  number = null;
  status = null;
  numberType = null;
  assignee = null;
  actions = [];

  constructor(number) {
    super();
    this.mapProperties(number);
    this._mapOplogMessageProperties(number);
    this.formatNumberType(number.numberType);
  }

  _mapOplogMessageProperties(number) {
    if (number.telephoneNumber) {
      this.number = number.telephoneNumber;
    }

    if (number.telephoneNumberId) {
      this.numberId = number.telephoneNumberId;
    }
  }

  formatNumberType(numberType) {

    if(!numberType) {
      return;
    }

    this.numberType = numberType.replace('_', '-').toLowerCase();
  }


  get numberIcon() {
    return 'numbers';
  }

  @computedFrom('assignee')
  get assigned() {
    return this.assignee && this.assignee.id;
  }

  @computedFrom('assignee')
  get descriptionTwo() {
    if(this.assignee && this.assignee.id) {

      if(this.assignee.type === 'MEMBER') {
        return `Assigned to ${this.assignee.firstName} ${this.assignee.surname}`;

      } else if(this.assignee.type === 'FLOW') {
        return `Assigned to ${this.assignee.flowName}`;
      }
    }
    return 'Unassigned';
  }
}
