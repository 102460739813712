import { inject, bindable, customElement, LogManager } from 'aurelia-framework';
import { WidgetConfig } from '../../../../../features/organisation/member/teamleader/widget-config';

const logger = LogManager.getLogger('ZProportionalWidgetV2');

@customElement('z-proportional-widget-v2')
export class ZProportionalWidgetV2 {
  @bindable private onClickHandler: Function;
  @bindable private widgetConfig: WidgetConfig;

  constructor() {}

  private onClick(): void {
    if (this.onClickHandler && typeof this.onClickHandler === 'function') {
      this.onClickHandler();
    }
  }
}
