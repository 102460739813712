import { OrganisationSessionModel } from './../../../_common/stores/sessionmodels/organisation-session-model';
import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { CommandFactory, SessionStore, OplogService, MailboxService, SearchTools } from 'zailab.common';
import { AbstractList } from 'zailab.abstract';
import { ViewMembers } from '../steps/view-members/view-members';
import { ViewAudio } from '../steps/view-audio/view-audio';
import { MailboxCreatorDialog } from '../mailbox-creator-dialog';
import { PlaceholderService } from "../../../_common/services/placeholder-service";

let logger = LogManager.getLogger('MailboxListView');

@inject(Element, DialogService, MailboxService, SessionStore)
export class MailboxList extends AbstractList {

  protected isDeleting: boolean = false;

  private actions = [
    {
      action: 'view',
      label: 'view members'
    },
    {
      action: 'listen',
      label: 'listen to audio',
      icon: 'mailbox'
    }
  ];

  constructor(protected element: any, private dialogService: DialogService, private mailboxService: MailboxService, private sessionStore: SessionStore, protected eventAggregator: EventAggregator) {
    super(eventAggregator);
  }

  protected activate(): void {
    super.activate();
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected subscribeToOplog(): void {
    this.mailboxService.initialiseMailboxesOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.retrieveList();
      });
      this.oplog.on('update', (response) => {
        this.retrieveList();
      });
      this.oplog.on('delete', (response) => {
        this.retrieveList();
      });
    });

    this.mailboxService.initialiseMailboxRecipientsOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.retrieveList();
      });
      this.oplog.on('update', (response) => {
        this.retrieveList();
      });
      this.oplog.on('delete', (response) => {
        this.retrieveList();
      });
    });
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;
  }

  public deleteMailboxes(): void {

    let listToDelete = [];

    this.itemList.forEach(_mailbox => {
      if (_mailbox.isDeleting) {
        listToDelete.push(_mailbox);
      }
    });

    this.deleteItems(listToDelete);
    this.hideLoader();

    this.itemList.forEach(item => {
      item.isDeleting = false;
    });
    this.toggleDelete();
  }

  public selectMailbox(_item: any): void {
    _item.isDeleting = !_item.isDeleting;
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.mailboxService.getAllMailboxes().then(
      (_mailboxes: Array<ZIMailbox>) => {
        this.itemList = _mailboxes;
        this.placeholderService = new PlaceholderService(this.container, this.itemList.length, 2, (placeholders) => {
          this.placeholders = placeholders;
        });
        super.hideLoader();
      },
      error => {
        logger.info('WARN >  Could not retrieve and list mailboxes > error =', error);
        super.hideLoader();
      }
    );
  }

  public addNewMailbox = (): void => {
    this.showLoader();
    this.dialogService.open({viewModel: MailboxCreatorDialog, model: this.itemList}).whenClosed((response: any) => {
      this.hideLoader();
      if (!response.wasCancelled) {
        this.retrieveList();
      }
    });
  };

  protected toggleSearch():void {
    super.toggleSearch();
  }

  public viewExistingRecipients = (_mailbox: ZIMailbox) => {
    this.dialogService.open({viewModel: ViewMembers, model: _mailbox}).whenClosed((response: any) => {
    });
  };

  public listenToAudio = (_mailbox: ZIMailbox) => {
    this.dialogService.open({viewModel: ViewAudio, model: _mailbox}).whenClosed((response: any) => {
    });
  };

  public delete(_mailbox: ZIMailbox): void {
    let payload: any = {
      mailboxId: _mailbox.mailboxId
    };

    this.mailboxService.deleteMailbox(payload);
  }

  // Override methods

  protected startsWithMatch(searchExpression: string, value: any, searchParam: string): any {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: any, searchParam: string): any {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}
