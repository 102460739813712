import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ContactCenter } from '../contact-center-model';
import { ContactCenterService } from '../contact-center-service';

const logger = LogManager.getLogger('ContactCenterEdit');

@autoinject()
export class ContactCenterEdit {

  public contactCenter: ContactCenter = new ContactCenter();
  public isProcessing: boolean = false;

  constructor(
    private contactCenterService: ContactCenterService,
    private router: Router
  ) { }

  public activate(params: { id: string }): Promise<any> {
    return this.contactCenterService.findById(params.id)
      .then((contactCenter: ContactCenter) => this.contactCenter = contactCenter);
  }

  public save(): void {
    this.isProcessing = true;
    this.contactCenterService.update(this.contactCenter)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }
}