import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'zailab.common';
import { Validation } from 'zailab.validation';
import { TableInputModel } from '../../../../components/custom/table-input';
import { IntegrationsService } from '../../integrations-service';

const logger = new LogManager.getLogger('ConfigureMicrosoft365EmailsDialog');

@autoinject
export class ConfigureMicrosoft365EmailsDialog {

  public configId: string = '';
  public name: string = '';
  private validation: any;

  public tableInputHeaders: TableInputModel;
  public isAddingContent: boolean = false;
  public isValid: boolean = false;

  public emails: any = {};
  private changedHeaders;

  constructor(
    private dialogController: DialogController,
    private integrationService: IntegrationsService,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(config?: { id: string }): void {
    if (!config.id) {
      logger.warn(' > Config has not been configured, thus cannot configure emails yet');
      return;
    }
    this.configId = config.id;
    this.retrieveEmailsConfig();    
  }

  private async retrieveEmailsConfig(): Promise<void> {
    try {
      const emailData: IMicrosoft365IntegrationConfigEmails = await this.integrationService.getMicrosoft365ConfigEmails(this.configId);
      this.emails = emailData.emails;
    } catch (e) {
      logger.warn(' > Failed to get Microsoft 365 Config Emails due to , e');
    }
    this.initTableInputHeaders();
  }

  private initTableInputHeaders(): void {
    let builder = TableInputModel.builder
      .setHeaders('Email')
      .hideHeaders();

    if (this.emails) {
      this.isValid = true;
      this.emails.forEach(email => {
        builder.addRow(email);
      });
    }
    this.tableInputHeaders = new TableInputModel([builder.build()]);
  }

  public validate(): void {
    if (this.name.length === 0) {
      this.isValid = false;
      return;
    }
    this.isValid = true;
  }

  public attached(): void {
    this.initialiseValidation();
  }

  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  public valueChanged(tableData: any): void {
    if (tableData) {
      this.changedHeaders = tableData;
    }
    this.checkTableData();
  }

  public rowAdded(data: any): void {
    this.addEmail(data);

    let scrollContainer = document.querySelector('#scroll-container');
    if (scrollContainer) {
      setTimeout(() => {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 50);
    }
  }

  private addEmail(data: any): void {
    if (data[0].length === 0) {
      return;
    }
    this.integrationService.addMicrosoft365Email(this.configId, data[0]);
  }

  public rowRemoved(data: any): void {
    this.removeEmail(data);
    this.checkTableData();
  }

  private removeEmail(data: any): void {
    if (data[0].length === 0) {
      return;
    }
    this.integrationService.removeMicrosoft365Email(this.configId, data[0]);
  }

  public checkTableData(): void {
    setTimeout(() => {
      let variables = this.extractTableContent();
      
      if (variables && Object.keys(variables)) {
        let keys = Object.keys(variables);
        if (keys.length > 0) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      } else {
        this.isValid = false;
      }
    }, 100);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.dialogController.ok();
  }

  private extractTableContent(): { [key: string]: string } {
    const rows = this.tableInputHeaders.table.rows;
    let map = {};

    if (rows) {
      rows.forEach(row => {
        if (!row.cells[0] || !row.cells[1]) {
          return;
        }
        map[row.cells[0]] = row.cells[1];
      });
    }
    return map;
  }

}