import {LogManager, observable} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController} from 'aurelia-validation';
/**/
import {BootstrapFormRenderer} from 'zailab.common';
import {TabBase} from './tab-base';
import {bindable} from 'aurelia-templating';
/**/
const logger = LogManager.getLogger('RangeOfDigitsTab');
const KEYPRESS_TYPES: any = {
  FIXED_NUMBER: 'fixedNumber',
  KEYPRESS_TERMINATE: 'keypressTerminator'
};

/**/
export class RangeOfDigitsTab extends TabBase {

  private validationController: ValidationController;
  private existingMaxLength: number;
  @bindable public inputMaxLength: number = 0;
  private rangeEndsWith: string = '#';
  private keypressType: string = KEYPRESS_TYPES.FIXED_NUMBER;
  public timeoutDestination: boolean = false;
  @observable public timeoutDuration: number = 300;

  constructor(_validationControllerFactory: ValidationControllerFactory, _element: Element) {
    super(_validationControllerFactory, _element);
  }

  public activate(_tab: object): void {
    super.activate(_tab);
  }

  public attached(): void {
    this.setExistingData();
  }

  private setRangeType(_type: string): void {
    this.keypressType = _type;
    this.sendPayload();
  }

  private setKeypressTerminator(_key: string): void {
    this.rangeEndsWith = _key;
    this.sendPayload();
  }

  private inputMaxLengthChanged(_newValue: number, _oldValue: number): void {
    this.sendPayload();
  }

  public timeoutDurationChanged(newValue: string, oldValue: string): void {
    if (!this.tabData) {
      return;
    }
    this.sendPayload();
  }

  public toggletimeoutDestination(): void {
    this.timeoutDestination = !this.timeoutDestination;
    this.sendPayload();
  }

  private sendPayload(): any {
    let duration;
    if (this.timeoutDuration) {
      if (typeof this.timeoutDuration === 'string') {
        duration = parseInt(this.timeoutDuration);
      } else {
        duration = this.timeoutDuration;
      }
    }
    this.sendTabData({
      inputMaxLength: this.keypressType === KEYPRESS_TYPES.FIXED_NUMBER ? parseInt(this.inputMaxLength) : 0,
      endsWith: this.keypressType === KEYPRESS_TYPES.KEYPRESS_TERMINATE ? this.rangeEndsWith : '',
      timeoutDestination: this.timeoutDestination,
      timeoutDuration: this.timeoutDestination ? duration : undefined
    });
  }

  public setExistingData(): void {
    this.keypressType = this.tabData.data.endsWith !== '' ? KEYPRESS_TYPES.KEYPRESS_TERMINATE : KEYPRESS_TYPES.FIXED_NUMBER;
    this.inputMaxLength = this.tabData.data.inputMaxLength ? this.tabData.data.inputMaxLength : 1;
    this.rangeEndsWith = this.tabData.data.endsWith ? this.tabData.data.endsWith : '#';
    this.timeoutDestination = this.tabData.data.timeoutDestination || false;
    this.timeoutDuration = this.tabData.data.timeoutDuration || 300;
    this.sendPayload();
  }
}