import { autoinject, LogManager, computedFrom, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ReportConfig } from "../report-config";

const logger = LogManager.getLogger('ReportsDialog');

@autoinject
export class ReportsDialog {

  private selectedReport: ReportConfig = null;
  private reportName: string = null;

  constructor(private dialogController: DialogController) {

  }

  public activate(report: ReportConfig): void {
    this.reportName = report.label;
    this.selectedReport = report;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  @computedFrom('selectedReport')
  private get reportPath(): string {
    if (this.selectedReport.newReport === true) {
      return PLATFORM.moduleName('./reports-v3');
    }
    else {
      return PLATFORM.moduleName(`./${this.selectedReport.selector}`);
    }
  }

  @computedFrom('selectedReport')
  private get reportView(): string {
    if (this.selectedReport.newReport === true) {
      return PLATFORM.moduleName('./reports-v3.html');
    }
    else {
      return PLATFORM.moduleName(`./${this.selectedReport.selector}.html`);
    }
  }
}