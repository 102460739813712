import {LogManager} from 'aurelia-framework';
import {ReportConfig} from "../report-config";

const logger = LogManager.getLogger('ReportsV3');

export class ReportsV3 {
  private reportConfig: ReportConfig = null;

  constructor() {
  }

  activate(reportConfig: ReportConfig): void {
    this.reportConfig = reportConfig;
  }
}