import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ReportsDialog');

@autoinject
export class DisabledDialog {

  constructor(
    private dialogController: DialogController
  ) { }

  public cancel(): void {
    this.dialogController.cancel();
  }
}