import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { AudioFileModel, FetchAudioService, SearchTools } from 'zailab.common';
import { AUDIO_TOOLS } from '../../../../../../../../_common/tools/audio-tools';
import { EmptyAudioAbstract } from '../../../abstracts/empty-audio-abstract';
import { AudioSearchFilter } from '../../../audio-search-filter';

const logger = LogManager.getLogger('Menu Options | Message');

@autoinject
export class Message extends EmptyAudioAbstract {

  private wizard: any;
  public languageVariableName: string = '';

  protected emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-audio.svg',
    heading: `Your organisation does not yet have any audio messages.`,
    description: `You can create an audio message by uploading a WAV file or creating a text-to-speech audio message.`,
    buttonText: `Create Audio`,
    hasButton: true,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName('features/media/media/audiofiles/addaudio/add-audio-dialog'),
    hasTip: true,
    tipText: `Clicking this button will show the audio content creator.`
  };

  constructor(
    protected fetchAudioService: FetchAudioService,
    private eventAggregator: EventAggregator,
    private element: Element,
    protected dialogService: DialogService,
    public audioSearchFilter: AudioSearchFilter,
  ) {
    super(dialogService, fetchAudioService, audioSearchFilter);
  }

  // Lifecycle

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.extractWizardData();
  }

  public attached(): void {
    this.retrieveAudioFiles();
    this.eventAggregator.publish('wizard.step.activate');
  }

  // Wizard

  private nextStep(): void {
    this.wizard.continue();
  }

  private initWizard(wizard: any): void {
    this.wizard = wizard;
  }

  private extractWizardData(): void {
    this.preselectedAudioMessage = AUDIO_TOOLS.getSelectedAudioFromWizard(this.wizard);
    this.languageVariableName = this.wizard.data.languageVariableName;
  }

  // Overrides

  protected activateContentCreator(): void {
    super.activateContentCreator({});
  }

  protected handleContentCreated(_contentCreatorResponse: any): void {
    super.handleContentCreated(_contentCreatorResponse);
    this.validate();
  }

  protected audioFilesRetrieved(audioFiles: AudioFileModel[]): void {
    super.audioFilesRetrieved(audioFiles);
    this.validate();
  }

  protected selectExistingAudioMessage(audioMessage: AudioFileModel): void {
    super.selectExistingAudioMessage(audioMessage);
    this.validate();
  }

  public languageVariableNameChanged(): void {
    this.validate();
  }

  // Observable

  private isAddingContentChanged = (_newValue: boolean): void => {
    this.element.dispatchEvent(new CustomEvent(_newValue ? 'show-content-creator' : 'hide-content-creator', {
      bubbles: true,
      detail: {}
    }));
  };

  private validate(): void {

    if (!this.selectedAudioMessage || !this.selectedAudioMessage.audioId) {
      this.wizard.step.incomplete();
      return;
    }

    let audioMessages = AUDIO_TOOLS.getAudioMessages(this.selectedAudioMessage);

    this.wizard.step.complete({
      languageVariableName: this.languageVariableName,
      audioMessages,
      speechLanguage: {
        languageCode: 'en-us',
        languageName: 'English (United States)'
      },
      speechRate: '0',
      textMessage: null
    });
  }

  public doNothing(): void {}

  protected startsWithMatch(searchExpression: string, value: string, searchParam: string): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
