import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ReportSchedule } from '../report-schedule-model';
import { ReportScheduleService } from '../report-schedule-service';

const logger = LogManager.getLogger('ReportScheduleEdit');

@autoinject()
export class ReportScheduleEdit {

  public reportSchedule: ReportSchedule = new ReportSchedule();
  public isProcessing: boolean = false;

  constructor(
    private reportScheduleService: ReportScheduleService,
    private router: Router
  ) { }

  public activate(params: any): Promise<any> {
    return this.reportScheduleService.findById(params.id)
      .then((reportSchedule: ReportSchedule) => this.reportSchedule = reportSchedule);
  }

  public save(): void {
    this.isProcessing = true;
    this.reportScheduleService.update(this.reportSchedule)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }
}