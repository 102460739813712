import { LogManager, inject, Factory } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/**/
import { InteractionFlowModel } from './models/interaction-flow-model';
import { InteractionFlowDefinitionModel } from './models/interaction-flow-definition-model';
import { CommandFactory, UrlFactory, ApplicationProperties, SessionStore, WebSocket } from 'zailab.common';
import { ConnectorModel } from '../../../../components/organisms/connector/models/connector-model';
/**/
const logger = LogManager.getLogger('InteractionDesignerService');
const VERSION: string = 'v2.0.0';

/**/
@inject(HttpClient, Factory.of(CommandFactory), UrlFactory, ApplicationProperties, SessionStore, WebSocket)
export class InteractionFlowService {

  constructor(public httpClient: HttpClient, public commandFactory: CommandFactory, public urlFactory: UrlFactory, public applicationProperties: ApplicationProperties, public sessionStore: SessionStore, public webSocket: WebSocket) {

    this.httpClient = httpClient;
    this.commandFactory = commandFactory;
    this.urlFactory = urlFactory;
    this.applicationProperties = applicationProperties;
    this.sessionStore = sessionStore;
    this.webSocket = webSocket;
  }

  public retrieveInteractionFlow(interactionFlowId: string, version: number): Promise<InteractionFlowModel> {

    if (!interactionFlowId) {
      return;
    }

    const uri: string = `v1/interaction/interaction-flows/${interactionFlowId}`;
    const params = version ? { version } : null;

    return new Promise((resolve: any, reject: any) => {

      this.httpClient.createRequest(uri)
        .asGet()
        .withParams(params)
        .send()
        .then((interactionFlow: InteractionFlowModel) => {

          let _interactionFlow: InteractionFlowModel = this.modelCollection(interactionFlow, InteractionFlowModel);

          if (!_interactionFlow.definition.version && _interactionFlow.definition.nodes.length) {
            // if no version number is specified and a flow has been defined, we assume it's a legacy flow and route to legacy designer
            this.routeToLegacy(interactionFlowId, _interactionFlow.channel, _interactionFlow.interactionFlowName)
            return;
          }

          resolve(_interactionFlow);

        }, reject);
    })
  }

  public modelCollection(items: any, Model: any): any {

    if (!items) {
      return;
    }

    if (items instanceof Array) {
      let mappedList: Array<any> = [];

      for (let item of items) {
        mappedList.push(new Model(item))
      }
      return mappedList;
    }

    let mappedObject: object = new Model(items);
    return mappedObject;
  }

  public publishInteractionFlow(organisationId: string, interactionFlowId: string, definition: InteractionFlowDefinitionModel, versionFlow?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      let connections: Array<ConnectorModel> = [];
      let _definition = JSON.parse(JSON.stringify(definition)); // make an immutable copy of the definition

      _definition.version = VERSION;
      _definition.organisationId = organisationId;

      _definition.nodes.forEach(node => {
        delete node.config;
        node.connections.forEach(connection => connections.push(connection));
      });

      _definition.connections = connections;
      let payload = { definition: JSON.stringify(_definition), newversion: versionFlow };
      let request = this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/definition`).asPut().withContent(payload);
      if (versionFlow) {
        request.withParams({ newversion: versionFlow })
      }
      request
        .send()
        .then((response: any) => {
          if (response.message === 'Validation Error') {
            return reject(response);
          }
          resolve()
        })
        .catch((error) => reject(error));
    });
  }

  public routeToLegacy(interactionFlowId: string, channel: string, flowName: string): void {

    let organisationId: string = this.sessionStore.get.organisation.organisationId;

    this.httpClient.createRequest('user/loginView/refreshAccessCode')
      .asGet()
      .send()
      .then(response => {
        window.location = this.urlFactory.buildUrl(this.applicationProperties.apiRedirectEndpoint, 'signin', `accessCode=${response.accessCode}&organisationName=${organisationId}&interactionManagerId=${interactionFlowId}&flowType=${channel}&flowName=${flowName}`);
      }, (error) => {
      });
  }

  public saveIncompleteFlow(interactionFlowId: string, definition: InteractionFlowDefinitionModel): Promise<any> {
    let connections: Array<ConnectorModel> = [];
    let _definition = JSON.parse(JSON.stringify(definition));
    _definition.nodes.forEach(node => {
      delete node.config;
      node.connections.forEach(connection => connections.push(connection));
    });
    _definition.connections = connections;
    const payload = { definition: JSON.stringify(_definition) };
    return this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/save`).asPut().withContent(payload).send();
  }

  public deleteSavedFlow(interactionFlowId: string): Promise<any> {
    return this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/save`).asDelete().send();
  }
}




