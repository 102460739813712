export const PEER_SERVICE_EVENTS = {
  PEER_REGISTERED: 'peer.registered',
  PEER_CALL_REJECTED: 'peer.call.rejected',
  PEER_CALL_RECEIVED: 'peer.call.received',
  PEER_CALL_ANSWERED: 'peer.call.answered',
  PEER_CALL_DISCONNECTED: 'peer.call.disconnected',
  PEER_CALL_ANSWER_ACCEPT: 'peer.call.answer.accept',
  PEER_CALL_ANSWER_REJECT: 'peer.call.answer.reject',
  PEER_CALL_VIDEO_TOGGLE: 'peer.call.video.toggle'
};
