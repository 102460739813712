import {LogManager, customElement, bindable, containerless} from 'aurelia-framework';

import $ from 'jquery';

import './input.scss';

const logger = LogManager.getLogger('InputV2');

@containerless
@customElement('input-v2')
export class UtilityInputV2 {

  @bindable position;
  @bindable spaced = true;
  @bindable placeholder;
  @bindable value;
  @bindable autofocus = true;
  @bindable fullwidth;
  input;

  attached() {
    if (this.autofocus) {
      $(this.input).focus();
    }
  }

}
