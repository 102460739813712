import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('AreaCodeModel');

export class AreaCodeModel extends BaseModel {

  code;
  area;
  areaCodeDescription;

  constructor(code, area) {
    super();
    this.area = area;
    this.code = code;
    this.areaCodeDescription = code + ' ' + area;
  }
}
