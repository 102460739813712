import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
/*
 * */
import { FetchAudioService, SessionStore } from 'zailab.common';
import { SurveyService } from '../survey-service';
import { SurveyWizard } from './survey-wizard';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
import { AudioFileModel } from '../../../../../_common/models/audio-file-model';
import { AudioSearchFilter } from '../../../../interaction/interactionFlow/interaction-designer/dialogs/audio-search-filter';
/*
 * */
const logger = LogManager.getLogger('SurveyQuestions');

/* */
@autoinject
export class SurveyQuestions {

  private responseTypes: string[] = ['keypress', 'voice'];
  private autofocus: boolean = true;
  private audioMessagesSearchResults: AudioFileModel[];
  private audioMessage: any;
  private editingQuestion: ZISurveyQuestion;
  private existingNodeData: any;
  private isSearching: boolean = false;

  constructor(
    private surveyService: SurveyService,
    private sessionStore: SessionStore,
    private surveyWizard: SurveyWizard,
    private eventAggregator: EventAggregator,
    private fetchAudioService: FetchAudioService,
    public audioSearchFilter: AudioSearchFilter,
  ) {

  }

  public activate(): void {
    if (!this.surveyWizard.survey.questions || this.surveyWizard.survey.questions.length === 0) {
      this.surveyWizard.isQuestionsValid = false;
    }
    this.retrieveExistingAudioFiles();
  }

  public detached(): void {
    let questionToRemove;
    for (let question of this.surveyWizard.survey.questions) {
      question.isInEditState = false;
      if (!question.saved) {
        questionToRemove = question;
      }
    }

    if (questionToRemove) {
      this.removeQuestion(questionToRemove);
    }
  }

  private retrieveExistingAudioFiles(): void {
    this.fetchAudioService.searchAudioFiles('')
      .then(audioMessages => this.audioFilesRetrieved(audioMessages), this.reportError);
  }

  private audioFilesRetrieved(audioFiles: any[]): void {
    this.audioMessagesSearchResults = audioFiles;
    this.audioMessagesSearchResults.forEach(_audio => {
      _audio.audioId = _audio.id;
      if (this.existingNodeData && this.existingNodeData.audioMessages && _audio.id === this.existingNodeData.audioMessages.audioId) {
        _audio.isSelected = true;
        this.audioMessage = _audio;
      }
    });

    this.audioSearchFilter.onConfirmed(() => this.audioMessagesSearchResults = this.audioSearchFilter.filter());
    this.audioSearchFilter.setAllAudioList(this.audioMessagesSearchResults);
  }

  private reportError(msg: string): void {
    logger.info('Error: ', msg);
  }

  private focusOnQuestionText(id: string): void {
    setTimeout(() => {
      if (this.autofocus) {
        document.getElementById(id).focus();
      }
    }, 200);
  }

  private removeQuestion(question: any): void {
    let index = this.surveyWizard.survey.questions.indexOf(question);
    this.surveyWizard.survey.questions.splice(index, 1);
    this.surveyWizard.isQuestionsValid = !(!this.surveyWizard.survey.questions || this.surveyWizard.survey.questions.length === 0);
    this.checkIfEditing();
  }

  private checkIfEditing(): void {
    this.surveyWizard['isInEditState'] = this.surveyWizard.survey.questions.some((_question: ZISurveyQuestion) => _question.isInEditState);
  }

  private validateQuestion(_question: ZISurveyQuestion): boolean {
    if (!_question.text || _question.text === '') {
      _question.isValid = false;
      _question.textValidationMessage = 'Please enter a question.';
      return false;
    } else {
      _question.textValidationMessage = '';
    }
    _question.isValid = true;
    return true;
  }

  private updateWizardWithQuestion(_updatedQuestion: ZISurveyQuestion): void {
    let _wizardQuestion: ZISurveyQuestion = this.surveyWizard.survey.questions.find(_question => _question.id === _updatedQuestion.id);
    if (_wizardQuestion) {
      Object.assign(_wizardQuestion, _updatedQuestion);
    }
  }

  private validateSurvey(): void {
    this.surveyWizard['isQuestionsValid'] = !this.surveyWizard.survey.questions.some((_question: ZISurveyQuestion) => _question.isValid === false);
    this.surveyWizard['isInEditState'] = this.surveyWizard.survey.questions.some((_question: ZISurveyQuestion) => _question.isInEditState === true);
  }

  private getLastIndex(): number {
    if (this.surveyWizard.survey.questions instanceof Array) {
      return this.surveyWizard.survey.questions.length;
    }
  }

  /* Methods called from view */

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
  }

  private validateOnTyping(_event: any): void {
    this.validateQuestion(this.editingQuestion);
  }

  private selectExistingAudioMessage(_audioMessage: any): void {
    _audioMessage.description = uuidv4();
    if (_audioMessage.isPlaceholder) {
      return;
    }
    this.audioMessagesSearchResults.forEach(_audio => {
      _audio.isSelected = false;
    });
    _audioMessage.isSelected = true;
    if (_audioMessage.text && _audioMessage.text.length > 0) {
      this.editingQuestion.audioMessages.audioId = null;
      this.editingQuestion.audioMessages.description = null;
    } else {
      this.editingQuestion.audioMessages = _audioMessage;
    }
    this.editingQuestion.textMessage = _audioMessage.text ? _audioMessage.text : null;
    this.editingQuestion.textToSpeechId = _audioMessage.id;
    this.isSearching = false;
    this.editingQuestion.isValid = this.validateQuestion(this.editingQuestion);
  }

  private startsWithMatch(searchExpression: string, value: any): boolean {
    if (!searchExpression || value.filename === null) {
      return false;
    }
    return value.filename.toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  private acceptQuestion(acceptedQuestion: ZISurveyQuestion): void {
    this.validateQuestion(acceptedQuestion);
    if (acceptedQuestion.isValid) {
      acceptedQuestion.saved = true;
      acceptedQuestion.isInEditState = false;
      this.editingQuestion = null;
    }
    this.audioMessagesSearchResults.forEach(_audio => _audio.isSelected = false);
    this.checkIfEditing();
    this.validateSurvey();

  }

  private editQuestion(acceptedQuestion: ZISurveyQuestion): void {
    this.editingQuestion = acceptedQuestion;
    this.editingQuestion.isInEditState = true;
    this.audioMessagesSearchResults.forEach(_audio => {
      _audio.isSelected = this.editingQuestion.audioMessages.audioId === _audio.audioId;
    });
    this.checkIfEditing();
    this.focusOnQuestionText(this.editingQuestion.id);
  }

  private addQuestion(): void {
    let indexCount = this.getLastIndex();
    let id = uuidv4();
    let question: ZISurveyQuestion = {
      index: indexCount,
      id: id,
      text: '',
      type: 'keypress',
      interruptible: true,
      validOptions: ['1', '2', '3', '4', '5'],
      audioType: '',
      audioMessages: {
        audioId: '',
        description: uuidv4()
      },
      textMessage: '',
      speechLanguage: {
        languageName: 'English (United States)',
        languageCode: 'en-us'
      },
      speechRate: 0,
      isInEditState: true,
      audioUrl: ''
    };

    this.surveyWizard.survey.questions.push(question);
    this.editingQuestion = question;
    this.checkIfEditing();
    this.focusOnQuestionText(id);
  }

}
