/*
*/
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
*/
const logger = LogManager.getLogger('ZStatsStack');
/*
*/
@customElement('z-stats-stack')
export class ZStatsStack {
    
  @bindable header: string;
  @bindable columns: string;
  @bindable rows: string;
  @bindable description: string;
  @bindable({attribute: 'stack-from-left'}) stackfromleft: boolean = false;
  @bindable({attribute: 'is-stacked'}) isstacked: boolean = false;
  @bindable({attribute: 'is-simple-stat'}) issimplestat: boolean = true;
  @bindable({attribute: 'has-children'}) ischild: boolean;
  @bindable({attribute: 'is-header-section'}) isheadersection: boolean;
  @bindable({attribute: 'has-background'}) hasbackground: boolean = true;
  @bindable({attribute: 'has-graph'}) hasgraph: boolean = false;

}
