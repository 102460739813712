import { inject } from "aurelia-dependency-injection";
import { ZaiFileLoader } from "_assets/utils/zai-file-loader";

@inject(ZaiFileLoader)
export class Error {
  
  code;

  constructor(zaiFileLoader) {
    this.zaiFileLoader = zaiFileLoader;
  }
  
  activate(params) {
    this.code = params.code;
  }
  
  back() {
    window.history.go(-1 * this.code);
  }
}