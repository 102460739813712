import { inject, LogManager } from 'aurelia-framework';
import { MemberState } from "../member-state";
import { Router } from "aurelia-router";
import { MembersService } from "../members-service";
import { SessionStore, MESSAGE_EVENTS } from "zailab.common";
import { EventAggregator } from "aurelia-event-aggregator";
import { ContactCenterService } from '../../../contactcenter/contact-center-service';
/*
 * */
const logger = LogManager.getLogger('BulkEditView');

/*
 * */
@inject(Element, Router, SessionStore, EventAggregator, MembersService, MemberState, ContactCenterService)
export class MembersBulkEditView {

  servicesToAdd = [];
  servicesToRemove = [];
  teamsToAdd = [];
  teamsToRemove = [];
  channelsToAdd = [];
  channelsToRemove = [];
  skillsToAdd = [];
  skillsToRemove = [];
  capacityRules = [];
  businessPartnersToAdd = [];
  businessPartnersToRemove = [];
  contactCentersToAdd = [];
  contactCentersToRemove = [];

  role = null;
  site = null;
  classOfService = null;

  canComplete = false;

  contactCenterSupport = new ContactCenterSupport();

  constructor(element, router, sessionStore, eventAggregator, memberService, memberState, contactCenterService) {
    this.element = element;
    this.router = router;
    this.organisationId = sessionStore.get.organisation.organisationId;
    this.eventAggregator = eventAggregator;
    this.memberService = memberService
    this.memberState = memberState;
    this.contactCenterService = contactCenterService;
  }

  activate() {
    if (!this.memberState.members || !this.memberState.members.length) {
      this.router.navigate('hud/members');
    }
    this.contactCenterService.findAll().then((contactCenters) => {
      this.contactCenterSupport.setContactCenters(contactCenters);
    });
  }

  _getSelectedServices(services) {
    let result = [];
    services.forEach(service => {
      if (service.isSelected) {
        result.push(service);
      }
    });
    return result;
  }

  updateServicesToAdd(data) {
    this.servicesToAdd = this._getSelectedServices(data.items);
    this._canComplete()
  }

  updateServicesToRemove(data) {
    this.servicesToRemove = this._getSelectedServices(data.items);
    this._canComplete()
  }

  updateCapacityRules(data) {
    this.capacityRules = data.items;
    this._canComplete()
  }

  _getSelectedTeams(teams) {
    let result = [];
    teams.forEach(team => {
      if (team.isSelected) {
        result.push(team);
      }
    });
    return result;
  }

  updateTeamsToAdd(data) {
    this.teamsToAdd = this._getSelectedTeams(data.items);
    this._canComplete()
  }

  updateTeamsToRemove(data) {
    this.teamsToRemove = this._getSelectedTeams(data.items);
    this._canComplete()
  }

  _getSelectedChannels(channels) {
    let result = [];
    channels.forEach(channel => {
      if (channel.isSelected) {
        result.push(channel);
      }
    });
    return result;
  }

  updateChannelsToAdd(data) {
    this.channelsToAdd = this._getSelectedChannels(data.items);
    this._canComplete()
  }

  updateChannelsToRemove(data) {
    this.channelsToRemove = this._getSelectedChannels(data.items);
    this._canComplete()
  }

  updateSkillsToAdd(data) {
    data.items.forEach(skillGroup => {
      skillGroup.skills = skillGroup.skills.filter(skill => {
        return skill.isSelected
      });
    });
    this.skillsToAdd = data.items;
    this._canComplete()
  }

  updateSkillsToRemove(data) {
    data.items.forEach(skillGroup => {
      skillGroup.skills = skillGroup.skills.filter(skill => {
        return skill.isSelected
      });
    });
    this.skillsToRemove = data.items;
    this._canComplete()
  }

  updateRole(data) {
    this.role = null;
    data.items.forEach(item => {
      if (item.isSelected) {
        this.role = item;
      }
    });
    this._canComplete()
  }

  updateSite(data) {
    this.site = null;
    data.items.forEach(item => {
      if (item.isSelected) {
        this.site = item;
      }
    });
    this._canComplete()
  }

  updateClassOfService(data) {
    this.classOfService = null;
    data.items.forEach(item => {
      if (item.isSelected) {
        this.classOfService = item;
      }
    });
    this._canComplete()
  }

  updateBusinessPartnersToAdd(event) {
    this.businessPartnersToAdd = event.items.filter((item) => item.isSelected);
    this.contactCenterSupport.selectedBusinessPartners = this.businessPartnersToAdd;
    this.contactCentersToAdd = this.contactCenterSupport.selectedContactCentres;
    this.contactCenterSupport.update()
    this._canComplete()
  }

  updateBusinessPartnersToRemove(event) {
    this.businessPartnersToRemove = event.items.filter((item) => item.isSelected);
    this._canComplete()
  }

  updateContactCentersToAdd(event) {
    this.contactCentersToAdd = event.items.filter((item) => item.isSelected);
    this.contactCenterSupport.selectedContactCentres = this.contactCentersToAdd;
    this._canComplete()
  }

  updateContactCentersToRemove(event) {
    this.contactCentersToRemove = event.items.filter((item) => item.isSelected);
    this._canComplete()
  }

  back() {
    this.router.navigate('members');
  }

  _canComplete() {
    this.canComplete = this.memberState.members.length && (this.role || this.site || this.classOfService || this.capacityRules.length ||
      this._getSelectedServices(this.servicesToAdd).length || this._getSelectedServices(this.servicesToRemove).length ||
      this._getSelectedTeams(this.teamsToAdd).length || this._getSelectedTeams(this.teamsToRemove).length ||
      this._getSelectedChannels(this.channelsToAdd).length || this._getSelectedChannels(this.channelsToRemove).length ||
      this._extractSkillIdsFromSkillGroups(this.skillsToAdd).length || this._extractSkillIdsFromSkillGroups(this.skillsToRemove).length ||
      this.businessPartnersToAdd.length || this.businessPartnersToRemove.length ||
      this.contactCentersToAdd.length || this.contactCentersToRemove.length
    );
  }

  complete() {
    this.memberState.members.forEach(member => {
      this.memberService.retrieveMember(member.memberId).then((response) => {
        this._updateRole(response).then(() => {
          this._updateSite(response);
          this._updateClassOfService(response);
          this._updateCapacityRules(response);

          this._updateServices(response);
          this._updateTeams(response);
          this._updateChannels(response);
          this._updateSkills(response);
          this._updateBusinessPartners(response);
          this._updateContactCenters(response);
          this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, "The changes are being processed. This might take a couple of minutes.");
        });
      }, error => {
        logger.error('retrieveMember > failure > ', error);
      });
    });
    this.router.navigate('members');
  }

  _servicesContainsService(services, serviceId) {
    for (const service of services) {
      if (service.serviceId === serviceId) {
        return true;
      }
    }
    return false;
  }

  _updateServices(member) {
    let _serviceIds = member.services.map(service => {return service.id});
    let _serviceIdsToAdd = this.servicesToAdd.filter(service => { return !this._servicesContainsService(member.services, service.id) }).map(service => { return service.id });
    let _serviceIdsToRemove = this.servicesToRemove.filter(service => { return this._servicesContainsService(member.services, service.id) }).map(service => { return service.id });

    if ((!_serviceIdsToAdd || !_serviceIdsToAdd.length) && (!_serviceIdsToRemove || !_serviceIdsToRemove.length)){
      return;
    }

    _serviceIds = _serviceIds.concat(_serviceIdsToAdd);
    _serviceIds = _serviceIds.filter(serviceId => {
      return !_serviceIdsToRemove.includes(serviceId);
    })

    this.memberService.updateMemberServices(member.memberId, _serviceIds);
  }

  _teamsContainsTeam(teams, teamId) {
    for (const team of teams) {
      if (team.id === teamId) {
        return true;
      }
    }
    return false;
  }

  _updateTeams(member) {
    let _teamIds = member.teams.map(team => {return team.id});
    let _teamIdsToAdd = this.teamsToAdd.filter(team => { return !this._teamsContainsTeam(member.teams, team.id) }).map(team => { return team.id });
    let _teamIdsToRemove = this.teamsToRemove.filter(team => { return this._teamsContainsTeam(member.teams, team.id) }).map(team => { return team.id });

    if ((!_teamIdsToAdd || !_teamIdsToAdd.length) && (!_teamIdsToRemove || !_teamIdsToRemove.length)){
      return;
    }

    _teamIds = _teamIds.concat(_teamIdsToAdd);
    _teamIds = _teamIds.filter(teamId => {
      return !_teamIdsToRemove.includes(teamId);
    })

    this.memberService.updateMemberTeams(member.organisationId, member.memberId, _teamIds);
  }

  _channelContainsChannel(channels, channelName) {
    for (const channel of channels) {
      if (channel.channelName === channelName) {
        return true;
      }
    }
    return false;
  }

  _updateChannels(member) {
    let _channels = member.channels.map(channel => {return channel.channelName});
    let _channelsToAdd = this.channelsToAdd.filter(channel => { return !this._channelsContainschannel(member.channels, channel.channelName) }).map(channel => { return channel.channelName });
    let _channelsToRemove = this.channelsToRemove.filter(channel => { return this._channelsContainschannel(member.channels, channel.channelName) }).map(channel => { return channel.channelName });

    if ((!_channelsToAdd || !_channelsToAdd.length) && (!_channelsToRemove || !_channelsToRemove.length)){
      return;
    }

    _channels = _channels.concat(_channelsToAdd);
    _channels = _channels.filter(channel => {
      return !_channelsToRemove.includes(channel);
    })

    this.memberService.updateMemberChannels(member.memberId, _channels);
  }

  _channelsContainschannel(channels, channelName) {
    for (const channel of channels) {
      if (channel.channelName === channelName) {
        return true;
      }
    }
    return false;
  }

  _extractSkillIdsFromSkillGroups(skillGroups) {
    let skills = [];
    skillGroups.forEach(skillGroup => {
      skillGroup.skills.forEach(skill => {
        skills.push(skill.id);
      });
    });
    return skills;
  }

  _updateSkills(member) {
    let _skillIds = this._extractSkillIdsFromSkillGroups(member.skillGroups);
    let _skillIdsToAdd = this._extractSkillIdsFromSkillGroups(this.skillsToAdd).filter(skillId => {return !_skillIds.includes(skillId)});
    let _skillIdsToRemove = this._extractSkillIdsFromSkillGroups(this.skillsToRemove).filter(skillId => {return _skillIds.includes(skillId)});

    if ((!_skillIdsToAdd || !_skillIdsToAdd.length) && (!_skillIdsToRemove || !_skillIdsToRemove.length)){
      return;
    }

    _skillIds = _skillIds.concat(_skillIdsToAdd);
    _skillIds = _skillIds.filter(skillId => {
      return !_skillIdsToRemove.includes(skillId);
    })

    this.memberService.updateMemberSkills(member.memberId, _skillIds);
  }

  _memberHasRole(member, roleName) {
    for (const role of member._roles) {
      if (role.roleName === roleName) {
        return true;
      }
    }
    return false;
  }

  _updateRole(member) {
    return new Promise((resolve, reject) => {
      if (this.role) {
        if (!this._memberHasRole(member, this.role.roleName)) {
          this.memberService.updateMemberRole(member.memberId, this.role.roleValue);
        }
      }
      resolve();
    });
  }

  _memberHasSite(member, siteId) {
    for (const site of member.sites) {
      if (site.id === siteId) {
        return true;
      }
    }
    return false;
  }

  _updateSite(member) {
    if (this.site && !this._memberHasSite(member, this.site.id)) {
      this.memberService.allocateMemberToSite(member.memberId, this.site.id);
    }
  }

  _updateClassOfService(member) {
    if (this.classOfService && member._classesOfService !== this.classOfService) {
      this.memberService.allocateClassOfServiceToMember(member.memberId, this.classOfService.name);
    }
  }

  _updateCapacityRules(member) {
    if (this.capacityRules && this.capacityRules.length) {
      this.memberService.allocateCapacityRulesToMember(member.memberId, this.capacityRules);
    }
  }

  _businessPartnersContainsBusinessPartner(businessPartners, businessPartnerId) {
    for (const businessPartner of businessPartners) {
      if (businessPartner.id === businessPartnerId) {
        return true;
      }
    }
    return false;
  }

  _updateBusinessPartners(member) {
    let _businessPartnerIds = member.businessPartners.map(businessPartner => {return businessPartner.id});

    let _businessPartnerIdsToAdd = this.businessPartnersToAdd.filter(businessPartner => { return !this._businessPartnersContainsBusinessPartner(member.businessPartners, businessPartner.id) }).map(businessPartner => { return businessPartner.id });
    let _businessPartnerIdsToRemove = this.businessPartnersToRemove.filter(businessPartner => { return this._businessPartnersContainsBusinessPartner(member.businessPartners, businessPartner.id) }).map(businessPartner => { return businessPartner.id });

    if ((!_businessPartnerIdsToAdd || !_businessPartnerIdsToAdd.length) && (!_businessPartnerIdsToRemove || !_businessPartnerIdsToRemove.length)){
      return;
    }

    _businessPartnerIds = _businessPartnerIds.concat(_businessPartnerIdsToAdd);
    _businessPartnerIds = _businessPartnerIds.filter(businessPartnerId => {
      return !_businessPartnerIdsToRemove.includes(businessPartnerId);
    })

    return this.memberService.updateMemberBusinessPartners(member.memberId, _businessPartnerIds).then(response => {
    });
  }

  _contactCentreContainsContactCentre(contactCentres, contactCentreId) {
    for (const contactCentre of contactCentres) {
      if (contactCentre.id === contactCentreId) {
        return true;
      }
    }
    return false;
  }

  _updateContactCenters(member) {
    let _contactCentreIds = member.contactCentres.map(contactCentre => {return contactCentre.id});

    let _contactCentreIdsToAdd = this.contactCentersToAdd.filter(contactCentre => { return !this._contactCentreContainsContactCentre(member.contactCentres, contactCentre.id) }).map(contactCentre => { return contactCentre.id });
    let _contactCentreIdsToRemove = this.contactCentersToRemove.filter(contactCentre => { return this._contactCentreContainsContactCentre(member.contactCentres, contactCentre.id) }).map(contactCentre => { return contactCentre.id });

    if ((!_contactCentreIdsToAdd || !_contactCentreIdsToAdd.length) && (!_contactCentreIdsToRemove || !_contactCentreIdsToRemove.length)){
      return;
    }

    _contactCentreIds = _contactCentreIds.concat(_contactCentreIdsToAdd);
    _contactCentreIds = _contactCentreIds.filter(contactCentreId => {
      return !_contactCentreIdsToRemove.includes(contactCentreId);
    })

    this.memberService.updateMemberContactCentres(member.memberId, _contactCentreIds);
  }
}

class ContactCenterSupport {

  selectedBusinessPartners = [];
  selectedContactCentres = [];
  isContactCenterDisabled = true;
  contactCenters = [];

  setContactCenters(contactCenters) {

    this.contactCenters = contactCenters;
    this.update();
  }

  update() {

    const selectedContactCentres = [];
    const removedContactCentres = [];
    for (const contactCenter of this.selectedContactCentres) {
      const cc = this.contactCenters.find((cc) => cc.contactCentreId === contactCenter.id);
      const businessPartners = this.selectedBusinessPartners.filter((selectedBusinessPartner) => {
        return cc.businessPartnerId === selectedBusinessPartner.id;
      });
      if (businessPartners.length > 0) {
        selectedContactCentres.push(contactCenter);
      } else {
        removedContactCentres.push(contactCenter);
      }
    }

    const businessPartners = this.selectedBusinessPartners.filter((selectedBusinessPartner) => {
      const cc = this.contactCenters.find((cc) => cc.businessPartnerId === selectedBusinessPartner.id);
      return !!cc;
    });

    this.isContactCenterDisabled = this.selectedBusinessPartners.length === 0 || businessPartners.length === 0;
    this.selectedContactCentres = selectedContactCentres;

    return removedContactCentres;
  }

  doNothing() {}
}