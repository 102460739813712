export class ValueAxesConfig {
  public stackType: string = 'regular';
  public axisColor: string = 'var(--primary-accent)';
  public axisAlpha: number = 0.6;
  public gridColor: string = 'var(--primary-accent)';
  public gridAlpha: number = 0.1;
  public gridThickness: number = 1;
  public gridCount: number = 10;
  public labelFrequency: number = 1;
  public markPeriodChange: boolean = false;
  public precision: number = 0;
  public autoGridCount: boolean = false;
  public strictMinMax: boolean = true;
  public minimum: number = 0;
  public maximum: number = 10;

  constructor() {

  }
}
