import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';
import $ from 'jquery';
import { SessionStore } from 'zailab.common';

import { InteractionModel } from '../../../interaction-model';
import { ContactModel } from '../../../../contact/contact-model';
import { CONTROLLER_ACTIONS, LOADER_ACTIONS } from '../../../../contact/contactcontroller/contact-controller-actions';
import { computedFrom } from "aurelia-binding";
import { CannedResponseService } from '../../../../../cannedresponses/canned-responses-service';
import { CannedResponseModel } from '../../../../../cannedresponses/canned-responses-model';
import { Menu } from '../../../../../../components/molecules/dropdowns/z-dropdown-menu';
import {FeatureFlagService} from "../../../../../featureflags/feature-flag-service";

const logger = LogManager.getLogger('Sms');

@customElement('z-sms')
@autoinject()
export class Sms {
  @bindable private contact: ContactModel;

  private recipientNumber: string = null;
  private message: string = null;
  private confirmDelete: boolean = false;
  private remaining: any = null;
  private messages: any = null;
  private limit: number = 160;
  private noMobileNumber: string = 'No mobile numbers.';
  public senderNumbersAsStrings: string[] = [];
  private senderNumber: string;
  private noSenderNumber: string = 'no-reply';

  public responseTemplateMenu: Menu<CannedResponseModel> = null;

  constructor(private eventAggregator: EventAggregator, private sessionStore: SessionStore, private cannedResponseService: CannedResponseService, private featureFlagService: FeatureFlagService) {
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.featureFlagService = featureFlagService;
  }

  private listenForKeyChanges(): any {
    this.remaining = $('#js-remaining');
    this.messages = $('#js-messages');
    $('#replyText').on('keyup keydown change', () => {
      let chars = this.message ? this.message.length : 0;
      let messages = Math.ceil(chars / this.limit);
      let remaining = messages * this.limit - (chars % (messages * this.limit) || messages * this.limit);
      if (!this.message || (this.message && this.message.length === 0)) {
        this.remaining.text('160');
        this.messages.text('1');
      } else {
        this.remaining.text(remaining);
        this.messages.text(messages);
      }
    });
  }

  public attached(): void {
    this.listenForKeyChanges();
    this.cannedResponseService.findResponseTemplates('SMS') //
      .then((cannedResponses: CannedResponseModel[]) => this.handleResponseTemplatesResponse(cannedResponses));

    this.senderNumbersAsStrings = this.contact.smsFromNumbers.map(number => {
      return number.telephoneNumber;
    });
  }

  private handleResponseTemplatesResponse(cannedResponses: CannedResponseModel[]): void {
    this.responseTemplateMenu = new Menu();
    this.responseTemplateMenu.onchange = (responseTemplate: CannedResponseModel) => this.handleSelectedResponseTemplate(responseTemplate);
    cannedResponses.forEach((responseTemplate: CannedResponseModel) => this.responseTemplateMenu.options.push(responseTemplate));
  }

  private handleSelectedResponseTemplate(responseTemplate: CannedResponseModel): void {
    this.message = responseTemplate.body;
  }

  private toggleConfirmDelete(): void {
    this.confirmDelete = !this.confirmDelete;
  }

  private sendSms(): void {

    let conversation = this.contact.getSelectedConversation();
    if (conversation && conversation.status.toLowerCase() !== 'pending' && conversation.status.toLowerCase() !== 'new') {
      conversation = null;
    }

    let sms: InteractionModel = new InteractionModel({
      contactId: this.contact.contactId,
      conversationId: conversation ? conversation.conversationId : null,
      correlationId: this.contact.correlationId,
      channel: 'SMS',
      message: this.message,
      to: this.recipientNumber,
      userId: this.sessionStore.get.user.userId,
      from: this.senderNumber ? this.senderNumber : this.sessionStore.get.user.userId
    });

    this.eventAggregator.publish(CONTROLLER_ACTIONS.SEND_SMS, sms);
    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
    this.eventAggregator.publish(LOADER_ACTIONS.CHANGE_LOADER_INTERACTION_LIST, { contactId: this.contact.contactId, status: true });

    this.remaining.text('160');
    this.messages.text('1');
    this.message = '';
  }

  @computedFrom('contact', 'contact.mobileNumberAsStrings')
  private get mobileNumberAsStrings(): Array<string> {
    if (this.contact.contactId) {
      return this.contact.mobileNumberAsStrings;
    } else if (this.contact.servedInteractions.length > 0) {

      if (this.contact.servedInteraction.from) {
        let re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (re.test(this.contact.servedInteraction.from)) {
          return [];
        }
      }

      let from = this.contact.servedInteractions[0].from;
      if (this.contact.servedInteractions[0].formattedDirection === 'Outbound') {
        from = this.contact.servedInteractions[0].interactionTo;
      }
      if (from.includes('@')) {
        return [];
      }
      return [from];
    }
    return [];
  }

  @computedFrom('contact')
  private get emailAsStrings(): Array<string> {
    if (this.contact.contactId) {
      return this.contact.emailAsStrings;
    } else if (this.contact.servedInteractions.length > 0) {
      return [this.contact.servedInteractions[0].from];
    }
    return [];
  }

  private showMediumChannel(): void {
    if (this.contact.channelViewStrategy.size === 'small') {
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    }
  }

  private discardSms(): void {
    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
    this.confirmDelete = !this.confirmDelete;
    this.message = '';
    if (!this.message || (this.message && this.message.length === 0)) {
      this.remaining.text('160');
      this.messages.text('1');
    }
  }
}