import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';
import {ConversationModel} from './conversation-model';
import {ConversationService} from './conversation-service';
import {EventAggregator} from 'aurelia-event-aggregator';

const logger = LogManager.getLogger('ConversationFactory');

@autoinject
export class ConversationFactory {

  constructor(private conversationService: ConversationService, private eventAggregator: EventAggregator) { }

  public build(conversationId?: string): ConversationModel {
    return new ConversationModel(this.conversationService,  this.eventAggregator, conversationId || uuidv4());
  }

}
