import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { Dispatcher } from 'aurelia-flux';
import { SessionStore } from 'zailab.common';
import { SELECT_FORM_ACTIONS } from './selectform.actions';

const logger = LogManager.getLogger('SelectFormService');

@inject(Dispatcher, SessionStore, HttpClient)
export class SelectFormService {

  constructor(dispatcher, sessionStore, httpClient) {

    this.dispatcher = dispatcher;
    this.sessionStore = sessionStore;
    this.httpClient = httpClient;
  }

  retrieveForms() {
    this.httpClient.createRequest('v1/organisation/forms')
      .asGet()
      .send()
      .then(
        (response) => {
          if (response && response.forms && response.forms.length > 0) {
            this.dispatcher.dispatch(SELECT_FORM_ACTIONS.RETRIEVE_FORMS, response.forms);
          } else {
            this.dispatcher.dispatch(SELECT_FORM_ACTIONS.RETRIEVE_FORMS, []);
          }
        }, (error) => {
          this.dispatcher.dispatch(SELECT_FORM_ACTIONS.RETRIEVE_FORMS, [])
        });
  }

}