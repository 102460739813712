import {MESSAGE_EVENTS} from 'zailab.common';
import {EventAggregator} from 'aurelia-event-aggregator';
import {inject, LogManager} from 'aurelia-framework';
import AjaxInterceptor from 'ajax-interceptor';

const logger = LogManager.getLogger('LogAppender');

const IGNORED_ERRORS = [
  'Error: Route not found'
];

const IGNORED_REASONS = [
  'NotFoundError: Failed to execute \'removeChild\' on \'Node\': The node to be removed is not a child of this node.'
];

@inject(EventAggregator)
export class LogAppender {

  constructor(eventAggregator) {

    this.errorHandler = new ErrorHandler(eventAggregator);
    this.eventAggregator = eventAggregator;
  }

  registerHandlers() {
    registerWindowErrorHandler(this.eventAggregator);
    registerWindowPromiseRejectionHandler(this.eventAggregator);
    registerXhrErrorHandler(this.eventAggregator);
  }

  error(log, message) {

    this.errorHandler.handleMessage(message);
  }

  debug(log, message) {
  }

  info(log, message) {
  }

  warn(log, message) {
  }
}
/*
 Ignore this error because it does not effect the stability of the app
 */
function ignore(message) {

  for (let error of IGNORED_ERRORS) {

    if (message.indexOf(error) !== -1) {

      logger.info('ignore > error message = ', message);
      return true;
    }
  }

  for (let reason of IGNORED_REASONS) {

    if (message === reason) {

      logger.info('ignore > reason message = ', message);
      return true;
    }
  }

  return false;
}
/*
 Handle aurelia errors
 LIMITATION: this handler cannot prevent the default behaviour therefor cannot prevent the error from showing in the log
 */
class ErrorHandler {

  constructor(eventAggregator) {

    this.eventAggregator = eventAggregator;
  }

  handleMessage(message) {

    if (ignore(message + '')) {
      return;
    }

    this.eventAggregator.publish(MESSAGE_EVENTS.ERROR_UNHANDLED, message);
  }
}
/*
 Handle window errors
 Not sure when this will be called - not seen yet
 */
function registerWindowErrorHandler(eventAggregator) {

  window.addEventListener('error', (errorEvent) => {

    eventAggregator.publish(MESSAGE_EVENTS.ERROR_UNHANDLED, `${errorEvent.error.message} -> ${errorEvent.error.stack}`);
  });
}
/*
 Handle core-js promise rejection
 */
function registerWindowPromiseRejectionHandler(eventAggregator) {

  let baseOnunhandledrejection = window.onunhandledrejection;
  window.onunhandledrejection = (rejection) => {

    try {

      if (ignore(rejection.reason + '')) {

        rejection.preventDefault(); // prevent the original error from displaying in the console
        return;
      }

      let msg = `Unhandled promise rejection : ${rejection.reason}`;

      if (rejection.reason && rejection.reason.stack) {
        msg += ` -> ${rejection.reason.stack}`;
      }

      logger.error('registerWindowPromiseRejectionHandler > rejection = ', rejection);

      eventAggregator.publish(MESSAGE_EVENTS.ERROR_UNHANDLED, msg);
    } finally {

      if (baseOnunhandledrejection) {
        baseOnunhandledrejection(data);
      }
    }
  };
}

function registerXhrErrorHandler(eventAggregator) {
  AjaxInterceptor.addResponseCallback((xhr) => {
    if (xhr.status === 500) {
      // eventAggregator.publish(MESSAGE_EVENTS.ERROR_UNHANDLED, `${xhr.statusCode} - ${xhr.statusText} -> ${xhr.responseText}`);
    }
  });
  AjaxInterceptor.wire();
}
