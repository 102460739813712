import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {CurrencyTools} from 'zailab.common';
import {BaseModel} from 'zailab.abstract';
/*
 */
const currencyTools = new CurrencyTools();
const logger = LogManager.getLogger('OverviewBalanceModel');

export class OverviewBalanceModel extends BaseModel {

  accountId = null;
  memberIds = null;
  pricingModel = null;
  creditLimit = {
    amount: null,
    currency: null
  };
  available = {
    amount: null,
    currency: null
  };
  due = {
    amount: null,
    currency: null
  };
  usage = {
    subtotal: {
      amount: null,
      currency: null
    },
    taxes: {
      amount: null,
      currency: null
    },
    components: {
      software: {
        amount: null,
        currency: null
      },
      carrier: {
        amount: null,
        currency: null
      },
      storage: {
        amount: null,
        currency: null
      },
      numbers: {
        amount: null,
        currency: null
      }
    }
  };

  constructor(balance) {
    super();
    this.mapProperties(balance);
    this.calculateUsagePercentage();
  }

  calculateUsagePercentage() {
    this.usage.currentUsagePercentage = 0;
    this.usage.availableBalancePercentage = 0;
    this.usage.balanceDuePercentage = 0;

    if (!this.due.amount) {
      this.due.amount = 0;
    }

    if (this.available.amount > 0 && (this.usage.subtotal.amount > 0 || this.due.amount > 0)) {
      let total = this.available.amount + this.usage.subtotal.amount + this.due.amount;
      this.usage.currentUsagePercentage = this.usage.subtotal.amount / parseFloat(total) * 100;
      this.usage.balanceDuePercentage = this.due.amount / parseFloat(total) * 100;
      this.usage.availableBalancePercentage = 100 - (this.usage.currentUsagePercentage + this.usage.balanceDuePercentage);
    }

    if ((this.available.amount > 0 && this.usage.subtotal.amount === 0 && this.due.amount === 0) || this.available.amount < 0) {
      this.usage.currentUsagePercentage = 0;
      this.usage.balanceDuePercentage = 0;
      this.usage.availableBalancePercentage = 100;
    }
  }
}
