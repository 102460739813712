import {DialogService} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {AbstractList} from 'zailab.abstract';
import AddIMDialog from '../add/add-im-dialog';
import IMService from '../im-service';
import {PlaceholderService} from "../../../_common/services/placeholder-service";
import {visibilityAware} from "../../../_common/services/visibilityawareness/visibility-aware";

const logger = LogManager.getLogger('IMList');

@autoinject()
@visibilityAware
export default class extends AbstractList {
  protected itemList: any = [];
  public itemListLength = 0;
  public placeholders = 0;
  public container;
  public isDeleting = false;
  public enableSelectors: boolean = false;

  constructor(
    private imService: IMService,
    private dialogService: DialogService,
    private router: Router,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.retrieveIMEndpoints(true);
  }

  private retrieveIMEndpoints(loaderEnabled: boolean): void {
    if (loaderEnabled) {
      this.ready = false;
    }

    this.enableSelectors = false;
    this.imService.retrieveIMEndpoints().then(
      response => {
        if(response.endpoints) {
          this.itemList = response.endpoints;
          this.itemListLength = this.itemList.length;
        }
        this.ready = true;
        this.generatePlaceholders();
        this.enableSelectors = true;
      },
      error => {
        logger.info('Error: Unable to retrieve list of campaigns.', error);
        this.ready = true;
        this.generatePlaceholders();
        this.enableSelectors = true;
      }
    );
  }

  public generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.itemList.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
  }


  public add(): void {
    this.dialogService
      .open({ viewModel: AddIMDialog, model: this.itemList })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let endpoint = response.output;

          this.imService.createEndpoint(endpoint);
        }
      });
  }

  public becameVisible(): void {
    this.retrieveIMEndpoints(false);
  }
  
  public viewReports = (): void => {
    this.router.navigateToRoute('reports');
  };

}
