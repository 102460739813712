import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {OplogService} from 'zailab.common';
import {EventAggregator} from "aurelia-event-aggregator";

const logger = LogManager.getLogger('Abstract service');
/* */
@autoinject
export class AbstractService {

  protected validationErrorSubscription:any;

  constructor(protected httpClient: HttpClient, protected oplogService: OplogService, protected eventAggregator:EventAggregator) {
    logger.info(this.oplogService);
  }

  protected fetchDataFromAPI(_config: ZIApiMessage): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(_config.url)
        .asGet()
        .withParams(_config.parameters ? _config.parameters : null)
        .withContent(_config.content ? _config.content : null)
        .send()
        .then(
          (response) => {
            try {
              resolve(response);
            } catch (error) {
              reject(error)
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  protected sendPostCommandToAPI(_config: ZIApiMessage): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(_config.url)
        .asPost()
        .withHeader('Content-Type', 'application/json;charset=UTF-8')
        .withParams(_config.parameters ? _config.parameters : null)
        .withContent(_config.content ? _config.content : null)
        .send()
        .then(
          (response) => {
            let x = response;
            try {
              resolve(response);
            } catch (error) {
              reject(error)
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  protected sendDeleteCommandToAPI(_config: ZIApiMessage): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(_config.url)
        .asDelete()
        .withHeader('Content-Type', 'application/json;charset=UTF-8')
        .withParams(_config.parameters ? _config.parameters : null)
        .withContent(_config.content ? _config.content : null)
        .send()
        .then(
          (response) => {
            try {
              resolve(response);
            } catch (error) {
              reject(error)
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public subscribeToOplogBasedOnCriteria(_config: ZIOplogSubscription): Promise<any> {
    return new Promise((resolve) => {
      resolve(this.oplogService['subscribeOn'](_config.key, _config.value).in(_config.location))
    });
  }

  protected subscribeToValidationError(item: any): any {
    if (this.validationErrorSubscription) {
      this.validationErrorSubscription.dispose();
    }
    this.validationErrorSubscription = this.eventAggregator.subscribe('ValidationErrorsReceived', (_test) => {
      logger.info(_test);
      item.showLoader = false;
      this.eventAggregator.publish('app:loader:hide');
    });
  }
}