/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZSidePanelListTemplate');
/*
 */
@customElement('z-side-panel-list-template')
export class ZSidePanelListTemplate {

}