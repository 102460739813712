import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { DoNotContactService } from './donotcontact-service';
import { stat } from 'fs';

const logger = LogManager.getLogger('DoNotContactListImportDialog');

@autoinject
export class DoNotContactListImportDialog {

  public loading = true;
  public valid = false;
  public errorMessage = '';
  public fileName = '';
  public stats: any;
  public viewState = '';

  private maxSize = 1000 * 1000; // 1mb
  private file = {
    content: '',
    name: '',
    length: 0
  };
  private intervalId;

  constructor(
    private dialogController: DialogController,
    private doNotContactService: DoNotContactService
  ) { }

  public activate(): void {
    this.retrieveStats();
  }

  public selectFile(fileSelectedEvent: any): void {
    let file = fileSelectedEvent.target.files[0];
    this.valid = false;
    this.errorMessage = '';
    if (!file || !file.name) {
      this.errorMessage = 'Please select a valid file.';
      return;
    }
    this.fileName = file.name;
    if (this.isValidFile(file)) {
      this.valid = true;
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        const content = readerEvent.target.result as string;
        const length = content.split('\r\n').length;
        this.file = {
          name: this.fileName,
          content,
          length
        };
      };
      reader.readAsText(file);
    }
  }

  public close(): void {
    if (this.loading) {
      return;
    }
    clearInterval(this.intervalId);
    this.dialogController.cancel();
  }

  public import(): void {
    this.errorMessage = 'Processing import...';
    this.loading = true;
    this.valid = false;
    this.doNotContactService.import(this.file)
      .then((...args) => {
        this.retrieveStats();
      })
      .catch((...args) => {
        this.errorMessage = 'Import failed';
        this.loading = false;
      });
  }

  private retrieveStats() {
    this.viewState = 'INIT';
    setTimeout(() => {
      this.intervalId = setInterval(() => {
        this.loading = true;
        this.doNotContactService.retrieveImport().then((data: any) => {
          this.stats = data;
          this.loading = false;
          if (!this.stats || this.stats.done) {
            clearInterval(this.intervalId);
            if (!this.fileName) {
              this.viewState = 'BROWSE';
            } else {
              this.close();
            }
          } else {
            this.viewState = 'STATS';
          }
        });
      }, 1000);
    }, 2000);
  }

  public resetFile(event: any): void {
    event.stopPropagation();
  }

  public clear(): void {
    this.stats.done = false;
  }

  public history(): void {
    this.viewState = this.viewState === 'STATS' ? 'BROWSE' : 'STATS';
  }

  private isValidFile(file: any): boolean {
    let split = file.name.split('.');
    if (split.length < 2) {
      this.errorMessage = 'Please select a valid file';
      return false;
    }
    let extension = split[split.length - 1];
    if (extension !== 'csv') {
      this.errorMessage = 'Please select a CSV file';
      return false;
    }
    if (file.size >= this.maxSize) {
      this.errorMessage = 'Please select a file with maximum size of 1mb';
      return false;
    }
    return true;
  }
}

