import {LogManager, singleton} from 'aurelia-framework';
/**/
const logger = LogManager.getLogger('DraggableService');
/**/
export class DraggableService {

  public draggingData: any;
  public draggingOverData: any;
  public isDragReady = false;
  public dragoffset = {
    x: 0,
    y: 0
  };
  public el;

  constructor() {}

  public init(): void {
    this.initPosition();
    this.events();
  };

  public initPosition(): void {
    this.el.style.position = 'fixed';
    this.el.style.top = 'auto';
    this.el.style.bottom = '20px';
    this.el.style.left = '20px';
  };

  public events(): void {
    this._on(this.el, 'mousedown', (e) => {
      this.isDragReady = true;
      this.dragoffset.x = e.pageX - this.el.offsetLeft;
      this.dragoffset.y = e.pageY - this.el.offsetTop;
    });
    this._on(document, 'mouseup', () => {
      this.isDragReady = false;
    });
    this._on(document, 'mousemove', (e) => {
      if (this.isDragReady) {

        var top = e.pageY - this.dragoffset.y;
        var left = e.pageX - this.dragoffset.x;
        var w = window.innerWidth;
        var h = window.innerHeight;

        if (e.pageY < 1 || e.pageY > h || e.pageX < 1 || e.pageX > w) {
          return;
        }

        this.el.style.top = top + 'px';
        this.el.style.bottom = 'auto';
        this.el.style.left = left + 'px';
      }
    });
  };

  public dragStart(metaData: any): boolean {

    this.draggingData = metaData;

    return true;
  }

  public dragOver(event: DragEvent, metaData: any): DragEvent {

    event.dataTransfer.dropEffect = 'move';
    this.draggingOverData = metaData;

    return event;
  }

  public dragEnd(metaData: any): boolean {

    this.draggingData = null;
    this.draggingOverData = null;

    return true;
  }

  public dragLeave(metaData: any): boolean {

    this.draggingOverData = null;

    return true;
  }

  public initialise(id: string): void {

    this.el = document.getElementById(id);

    if (!this.el) {

      setTimeout(() => {
        this.initialise(id);
      }, 1000);

      return;
    }

    this.init();
  }

  private _on(el: HTMLElement, event: any, fn: any): void {
    document.attachEvent ? el.attachEvent('on' + event, fn) : el.addEventListener(event, fn, !0);
  }

}