import { LogManager, autoinject, PLATFORM } from 'aurelia-framework';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
import { observable } from 'aurelia-binding';
import { DataTools } from '../../../../../../../../_common/tools/data-tools';

const logger = LogManager.getLogger('Keypad');

@autoinject
export class Keypad {
  private model: ZIWizard;
  public timeoutDestination = false;
  @observable public timeoutDuration = 300;

  public getParsedValue = (value: string | number) =>
    DataTools.getParsedValue(value);

  public activate(bindingContext: ZIWizard): void {
    this.initWizard(bindingContext);
    this.extractWizardData();
  }

  private initWizard(bindingContext: ZIWizard): void {
    this.model = bindingContext;
  }

  private extractWizardData(): void {
    this.timeoutDestination = this.model.data.timeoutDestination || false;
    this.timeoutDuration = this.model.data.timeoutDuration || 300;
    this.model.step.complete();
    this.saveData();
  }

  public toggletimeoutDestination(): void {
    this.timeoutDestination = !this.timeoutDestination;
    this.saveData();
  }

  private saveData(): any {
    let duration;
    if (this.timeoutDuration) {
      if (typeof this.timeoutDuration === 'string') {
        duration = parseInt(this.timeoutDuration);
      } else {
        duration = this.timeoutDuration;
      }
    }
    this.model.data.timeoutDestination = this.timeoutDestination ? true : false;
    this.model.data.timeoutDuration = this.timeoutDestination
      ? duration
      : undefined;
  }
}
