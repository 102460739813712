import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {InteractionFlowDefinitionModel} from './interaction-flow-definition-model';
/**/
const logger = LogManager.getLogger('InteractionFlowModel');
/**/
export class InteractionFlowModel extends BaseModel {

  public channel: string = null;
  public interactionFlowName: string = null;
  public interactionFlowType: string = null;
  public interactionFlowId: string = null;
  public definition: InteractionFlowDefinitionModel = null;
  public subflow: boolean = false;
  public endNodeNames: Array<string>;
  public additionalData = {};

  private existingDescriptor;

  constructor(interactionFlow: any) {

    super();
    super.mapProperties(interactionFlow);

    const definitionModel = interactionFlow.definition ? JSON.parse(interactionFlow.definition) : {};
    this.existingDescriptor = definitionModel.flowDescriptor;
    this.additionalData = interactionFlow.additionalData;

    this.definition = interactionFlow.definition instanceof InteractionFlowDefinitionModel ? interactionFlow.definition : this.extractDefinition(interactionFlow.definition);
    this.endNodeNames = interactionFlow.endNodeNames;
  }

  get flowDescriptor(): string {
    return this.existingDescriptor || this.removeBlankSpaces(`${this.interactionFlowType}${this.channel}`);
  }

  private removeBlankSpaces(text: string): string {

    const BLANK_SPACES: RegExp = / /g;

    return text.replace(BLANK_SPACES, '');
  }

  private extractDefinition(rawDefinition: string): InteractionFlowDefinitionModel {

    if(!rawDefinition) {
      return new InteractionFlowDefinitionModel();
    }

    let mappedDefinition: InteractionFlowDefinitionModel = new InteractionFlowDefinitionModel(JSON.parse(rawDefinition), this.flowDescriptor);

    return mappedDefinition;
  }
}