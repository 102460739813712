import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

import moment from 'moment';

import { ConfigMapper } from './config-mapper';

const logger = LogManager.getLogger('InteractionTeamStats');

@autoinject
export class InteractionTeamStats {

  constructor(
    private configMapper: ConfigMapper,
    private featureflags?: { [key: string]: boolean }
  ) {}

  public clearTimers(): void {
    this.configMapper.clearTimers();
  }

  public columnConfig = {
    'Name': {
      value: (c) => c.fullName,
      align: 'left',
      width: 150,
      filterType: 'input',
      disabled: true
    },
    'Team': {
      value: (c) => {
        return c.teamName;
      },
      width: 150,
      // The field `disabled` is required for grouping.
      // This disables the ability to deselect the header.
      disabled: true
    },
    'Logout': {
      value: (c) => {
        if (c.isTeam) {
          return '';
        }
        return `
          <div
            class="list-item hover flex flex--justify-center"
            title="Logout"
            style="height: 32px; align-items: center;"
            onclick="this.dispatchEvent(new CustomEvent('logout', { bubbles: true, detail: { passportId: '${c.passportId}', fullName: '${c.fullName}' } }))"
          >
            <div class="labels">
              <div class="label icon o-z-icon o-z-icon--power" style="margin-right: 0;"></div>
            </div>
          </div>
        `;
      },
      align: 'left',
      width: 50,
      cssClass: 'full-size',
      disableSorting: true
    },
    'Validate Interactions': {
      value: (c) => {
        if (c.isTeam) {
          return '';
        }
        return `
          <div
            class="list-item hover flex flex--justify-center"
            title="Validate Interactions"
            style="height: 32px; align-items: center;"
            onclick="this.dispatchEvent(new CustomEvent('validate-interactions', { bubbles: true, detail: { memberId: '${c.memberId}' } }))"
          >
            <div class="labels">
              <i class="label blue-highlight" style="font-size: 15px;">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
              </i>
            </div>
          </div>
        `;
      },
      align: 'left',
      width: 50,
      cssClass: 'full-size',
      disableSorting: true,
      disable: true
    },
    'Routing Status': {
      value: (c) => c.isTeam ? '<span style="display: none!important;"><span class="bad-light">Off Duty</span><span class="good-light">On Duty</span></span>' : (c.routingStatus === 'READY' ? 'On Duty' : 'Off Duty'),
      align: 'left',
      width: 125,
      style: (c) => c.isTeam ? '' : (c.routingStatus === 'READY' ? 'good-light' : 'bad-light'),
      cssClass: 'u-position-relative square-component',
      filterType: 'options'
    },
    'Activity Status': {
      value: (c) => c.isTeam ?
        '<span style="display: none!important;"><span class="bad-text-light">Waiting</span><span class="neutral-text">Waiting</span><span class="">Waiting</span><span class="good-text-light">Conversing</span><span class="bad-text-light">Not Responding</span><span class="neutral-text">Wrap Up</span></span>' :
        this.configMapper.getActivityStatus(c) || '--',
      align: 'left',
      width: 100,
      style: (c) =>  c.isTeam ? '' : this.configMapper.getActivityStatusColour(c),
      cssClass: 'u-position-relative square-component',
      filterType: 'options'
    },
    'Activity Status Duration': {
      value: (c) => c.isTeam ? '' : (c.currentActivity && c.currentActivity.name !== '--' ? this.configMapper.createTimer(c.currentActivity, 'startTimestamp') : '--'),
      align: 'left',
      width: 100,
      style: (c) => !c.isTeam && c.currentActivity && c.currentActivity.name !== '--' && this.configMapper.durationPassedLimit(c) ? 'bad-light' : '',
      cssClass: 'u-position-relative square-component'
    },
    'Online/Offline': {
      value: (c) => c.isTeam ? '<span style="display: none!important;"><span class="u-faded-content">Offline</span><span class="good-light">Online</span></span>' : c.onlineOffline || '--',
      align: 'left',
      width: 125,
      style: (c) => c.isTeam ? '' : (c.onlineOffline === 'Online' ? 'good-light' : 'u-faded-content'),
      cssClass: 'u-position-relative square-component',
      filterType: 'options'
    },
    'Presence': {
      value: (c) => c.isTeam ? '' : (c.presenceInformation.name && c.presenceInformation.name !== 'Undefined' ? c.presenceInformation.name : 'Available'),
      align: 'left',
      width: 100,
      style: (c) => {
        if (c.isTeam) {
          return '';
        }
        if (c.presenceInformation.name === 'Offline') {
          return 'u-faded-content';
        } else if (c.presenceInformation.name === 'Available' || c.presenceInformation.name === 'Undefined') {
          return 'good-text-light';
        } else {
          return 'neutral-text';
        }
      },
      cssClass: 'u-position-relative square-component'
    },
    'Presence Duration': {
      value: (c) => c.isTeam ? '' : (c.presenceInformation ? this.configMapper.createTimer(c.presenceInformation, 'startTimestamp', true) : '--'),
      align: 'left',
      width: 100,
      cssClass: 'u-position-relative square-component',
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Presence Duration',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceInformation.startTimestamp;
        if (value && value !== '--') {
          value = moment().diff(value, 'seconds');
        }
        return '" style="' + this.configMapper.getColourConfig('Presence Duration', value);
      }
    },
    'Live Actions': {
      value: (c) => {
        if (c.isTeam) {
          return '';
        }
        let value = null;
        if (c.callId && c.isConversing && !c.onWrapUp) {
          value = `
            <div
              class="list-item hover flex flex--justify-center"
              class.bind="${c.spyLocked || c.whisperLocked ? 'u-faded-content' : ''}"
              click.trigger="spy(callId)"
              title="Spy"
              style="height: 32px; align-items: center;"
              onclick="this.dispatchEvent(new CustomEvent('spy', { bubbles: true, detail: { callId: '${c.callId}' } }))"
            >
              <div class="labels">
                <div class="label icon o-z-icon o-z-icon--view" style="margin-right: 0;"></div>
              </div>
            </div>
          `;
        }
        
        if (!this.featureflags.whisperDisabled && !c.whisperDisabled && c.callId && c.isConversing && !c.onWrapUp) {
          value +=  `
            <div
              class="list-item hover flex flex--justify-center"
              class.bind="${c.spyLocked || c.whisperLocked ? 'u-faded-content' : ''}"
              title="Whisper"
              style="height: 32px; align-items: center;"
              onclick="this.dispatchEvent(new CustomEvent('whisper', { bubbles: true, detail: { callId: '${c.callId}' } }))"
            >
              <div class="labels">
                <div class="label icon o-z-icon o-z-icon--call-outbound" style="margin-right: 0;"></div>
              </div>
            </div>
          `;
        }
        return `<div class="list-item flex flex--justify-around">${value || '-'}</div>`;
      },
      align: 'left',
      width: 100,
      cssClass: 'full-size'
    },
    'Change Routing Status': {
      value: (c) => {
        if (c.isTeam) {
          return '';
        }
        return `
          <label class="o-switch-checkbox">
            <input
              type="checkbox"
              class="o-switch-checkbox__input"
              ${c.routingStatus === 'READY' ? 'checked' : ''}
              onchange="(function(e){
                
                
                const memberId = e.getAttribute('data.memberId');
                const parent = e.parentElement;
                let slider = parent.querySelector('#js-slider');
                let sliderText = parent.querySelector('#js-slider-text');

                const newStatus = e.checked;
                
                if (newStatus) {
                  slider.className += ' is-open';
                  sliderText.innerHTML = 'On Duty';
                } else {
                  slider.className = slider.className.replace(' is-open', '');
                  sliderText.innerHTML = 'Off Duty';
                }

                e.dispatchEvent(new CustomEvent('changeroutingstatus', { bubbles: true, detail: { routingStatus: newStatus, memberId } }))
            })(this);"
              data.memberId="${c.memberId}"
            />
            <span id="js-slider" class="o-switch-checkbox__slider ${c.routingStatus === 'READY' ? 'is-open' : ''}">
              <div id="js-slider-text" class="o-switch-checkbox__slider-label">${c.routingStatus === 'READY' ? 'On Duty' : 'Off Duty'}</div>
            </span>
          </label>
        `;
      },

      align: 'left',
      width: 100,
      cssClass: 'u-position-relative full-size square-component'
    },
    'Change Presence': {
      value: (c) => {

        const truncate = (str: string, n: number): any => {
          return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
        };

        if (c.isTeam) {
          return '';
        }
        let dropdownSelector = `
          <select
            class="align-center medium capitalize content-inner u-full-width u-scroll-bar u-scroll-bar--thick ${c.presenceInformation.name === 'Offline' || c.presenceInformation.name === 'Working' ? 'u-faded-content' : ''}"
            onchange="this.dispatchEvent(new CustomEvent('changepresence', { bubbles: true, detail: { userId: '${c.userId}', currentPresence: this.value }}))"
            style="background-color:var(--theme-background-default);border: 0px;padding: 0;max-height: 150px;"
          >
        `;
        if (c.presenceInformation.name !== 'Offline') {
          this.configMapper.presences.forEach(presence => {
            let _presence = this.configMapper.convertStringCasingToNameCase(presence.presenceCodeName);
            dropdownSelector += `<option value="${_presence}" ${c.presenceInformation.name === _presence ? 'selected' : ''}>${truncate(_presence, 15)}</option>`
          })
        }
        dropdownSelector += `</select>`;
        return dropdownSelector;
      },
      align: 'left',
      width: 100,
      cssClass: 'u-position-relative full-size square-component'
    },
    'Hold Duration': {
      value: (c) =>  c.callerPutOnHoldTimestamp ? this.configMapper.createTimer(c, 'callerPutOnHoldTimestamp') : '--',
      align: 'left',
      width: 100,
      cssClass: 'u-position-relative square-component',
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Hold Duration',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.callerPutOnHoldTimestamp;
        if (value && value !== '--') {
          value = moment().diff(value, 'seconds');
        }
        return '" style="' + this.configMapper.getColourConfig('Hold Duration', value);
      }
    },
    'Consult Duration': {
      value: (c) => c.consultStartedTimestamp ? this.configMapper.createTimer(c, 'consultStartedTimestamp', true) : '--',
      align: 'left',
      width: 100,
      cssClass: 'u-position-relative square-component',
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Consult Duration',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.consultStartedTimestamp;
        if (value && value !== '--') {
          value = moment().diff(value, 'seconds');
        }
        return '" style="' + this.configMapper.getColourConfig('Consult Duration', value);
      }
    },
    'Conference Duration': {
      value: (c) => c.conferenceStartedTimestamp ? this.configMapper.createTimer(c, 'conferenceStartedTimestamp', true) : '--',
      align: 'left',
      width: 100,
      cssClass: 'u-position-relative square-component',
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Conference Duration',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.conferenceStartedTimestamp;
        if (value && value !== '--') {
          value = moment().diff(value, 'seconds');
        }
        return '" style="' + this.configMapper.getColourConfig('Conference Duration', value);
      }
    },
    'Inbound Call Connected': {
      value: (c) => c.callsAmounts.inboundCallsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Inbound Call Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.callsAmounts.inboundCallsConnected;
        return '" style="' + this.configMapper.getColourConfig('Inbound Call Connected', value);
      }
    },
    'Outbound Call Connected': {
      value: (c) => c.callsAmounts.outBoundCallsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Outbound Call Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.callsAmounts.outBoundCallsConnected;
        return '" style="' + this.configMapper.getColourConfig('Outbound Call Connected', value);
      }
    },
    'Call Connected': {
      value: (c) => c.callsAmounts.callsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Call Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.callsAmounts.callsConnected;
        return '" style="' + this.configMapper.getColourConfig('Call Connected', value);
      }
    },
    'IMs Connected': {
      value: (c) => c.connectedChannelsAmounts.imsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'IMs Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.connectedChannelsAmounts.imsConnected;
        return '" style="' + this.configMapper.getColourConfig('IMs Connected', value);
      }
    },
    'Webchats Connected': {
      value: (c) => c.connectedChannelsAmounts.webChatsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Webchats Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.connectedChannelsAmounts.webChatsConnected;
        return '" style="' + this.configMapper.getColourConfig('Webchats Connected', value);
      }
    },
    'SMSs Connected': {
      value: (c) => c.connectedChannelsAmounts.smsSConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'SMSs Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.connectedChannelsAmounts.smsSConnected;
        return '" style="' + this.configMapper.getColourConfig('SMSs Connected', value);
      }
    },
    'Emails Connected': {
      value: (c) => c.connectedChannelsAmounts.emailsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Emails Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.connectedChannelsAmounts.emailsConnected;
        return '" style="' + this.configMapper.getColourConfig('Emails Connected', value);
      }
    },
    'Campaign Task Connected': {
      value: (c) => c.connectedChannelsAmounts.campaignTasksConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Campaign Task Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.connectedChannelsAmounts.campaignTasksConnected;
        return '" style="' + this.configMapper.getColourConfig('Campaign Task Connected', value);
      }
    },
    'Tickets Connected': {
      value: (c) => c.connectedChannelsAmounts.ticketsConnected || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Tickets Connected',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.connectedChannelsAmounts.ticketsConnected;
        return '" style="' + this.configMapper.getColourConfig('Tickets Connected', value);
      }
    },
    'Webphone Status': {
      value: (c) => {
        if (c.isTeam) {
          return '';
        }
        return `
          <div
            class="o-z-icon o-z-icon--phone black-svg"
            style="padding: 8px 0;"
            title="${c.webphoneStatus || '--'} &#x0a ${c.formattedWebphoneTime}"
          ></div>
        `
      },
      align: 'left',
      width: 100,
      style: (c) => {
        if (c.isTeam) {
          return '';
        }
        return (
          c.webphoneStatus === 'Login Successful' ||
          c.webphoneStatus === 'Call Connected' ? 'green-bg-light' : (
            c.webphoneStatus === 'Login Attempted' ||
            c.webphoneStatus === 'Call Rejected' ||
            c.webphoneStatus === 'Call Cancelled' ? 'orange-bg-light' : (
              c.webphoneStatus === 'Media Device Error' ||
              c.webphoneStatus === 'Popup Error' ||
              c.webphoneStatus === 'Login Error' ? 'red-bg' : 'gray-bg'
            )
          )
        );
      }
    },
    'No Response (#)': {
      value: (c) => c.interactionCounts.noResponse || '--',
      align: 'left',
      width: 100,
      cssClass: 'u-position-relative square-component',
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'No Response (#)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.interactionCounts.noResponse;
        return '" style="' + this.configMapper.getColourConfig('No Response (#)', value);
      }
    },
    'Hold (#)': {
      value: (c) => c.interactionCounts.hold || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Hold (#)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.interactionCounts.hold;
        return '" style="' + this.configMapper.getColourConfig('Hold (#)', value);
      }
    },
    'Consults (#)': {
      value: (c) => c.interactionCounts.consults || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Consults (#)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.interactionCounts.consults;
        return '" style="' + this.configMapper.getColourConfig('Consults (#)', value);
      }
    },
    'Conference Call Connected': {
      value: (c) => c.interactionCounts.conferences || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Conference Call (#)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.interactionCounts.conferences;
        return '" style="' + this.configMapper.getColourConfig('Conference Call Connected', value);
      }
    },
    'Transfers': {
      value: (c) => c.interactionCounts.transfers || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Transfers',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.interactionCounts.transfers;
        return '" style="' + this.configMapper.getColourConfig('Transfers', value);
      }
    },
    'Inbound Work Calls': {
      value: (c) => c.handledInteractionCounts.inboundWorkCalls || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Inbound Work Calls',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.inboundWorkCalls;
        return '" style="' + this.configMapper.getColourConfig('Inbound Work Calls', value);
      }
    },
    'Outbound Work Calls': {
      value: (c) => c.handledInteractionCounts.outboundWorkCalls || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Outbound Work Calls',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.outboundWorkCalls;
        return '" style="' + this.configMapper.getColourConfig('Outbound Work Calls', value);
      }
    },
    'Inbound Private Calls': {
      value: (c) => c.handledInteractionCounts.inboundPrivateCalls || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Inbound Private Calls',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.inboundPrivateCalls;
        return '" style="' + this.configMapper.getColourConfig('Inbound Private Calls', value);
      }
    },
    'Outbound Private Calls': {
      value: (c) => c.handledInteractionCounts.outboundPrivateCalls || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Outbound Private Calls',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.outboundPrivateCalls;
        return '" style="' + this.configMapper.getColourConfig('Outbound Private Calls', value);
      }
    },
    'Internal Calls': {
      value: (c) => c.handledInteractionCounts.internalCalls || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Internal Calls',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.internalCalls;
        return '" style="' + this.configMapper.getColourConfig('Internal Calls', value);
      }
    },
    'Email': {
      value: (c) => c.handledInteractionCounts.emails || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Email',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.emails;
        return '" style="' + this.configMapper.getColourConfig('Email', value);
      }
    },
    'SMS': {
      value: (c) => c.handledInteractionCounts.sms || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'SMS',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.sms;
        return '" style="' + this.configMapper.getColourConfig('SMS', value);
      }
    },
    'Webchat': {
      value: (c) => c.handledInteractionCounts.webChats || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Webchat',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.webChats;
        return '" style="' + this.configMapper.getColourConfig('Webchat', value);
      }
    },
    'Instant Message': {
      value: (c) => c.handledInteractionCounts.instantMessages || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Instant Message',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.instantMessages;
        return '" style="' + this.configMapper.getColourConfig('Instant Message', value);
      }
    },
    'Ticket': {
      value: (c) => c.handledInteractionCounts.tickets || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Ticket',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.handledInteractionCounts.tickets;
        return '" style="' + this.configMapper.getColourConfig('Ticket', value);
      }
    },
    'Propsects': {
      value: (c) => c.prospectCounts.prospects || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Propsects',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.prospectCounts.prospects;
        return '" style="' + this.configMapper.getColourConfig('Propsects', value);
      }
    },
    'Successful (Prospect conversation outcome)': {
      value: (c) => c.prospectCounts.successfulProspects || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Successful (Prospect conversation outcome)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.prospectCounts.successfulProspects;
        return '" style="' + this.configMapper.getColourConfig('Successful (Prospect conversation outcome)', value);
      }
    },
    'Pending (Prospect conversation outcome)': {
      value: (c) => c.prospectCounts.pendingProspects || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Pending (Prospect conversation outcome)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.prospectCounts.pendingProspects;
        return '" style="' + this.configMapper.getColourConfig('Pending (Prospect conversation outcome)', value);
      }
    },
    'Unsuccessful (Prospect Conversation Outcome)': {
      value: (c) => c.prospectCounts.unsuccessfulProspects || '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Unsuccessful (Prospect conversation outcome)',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.prospectCounts.unsuccessfulProspects;
        return '" style="' + this.configMapper.getColourConfig('Unsuccessful (Prospect conversation outcome)', value);
      }
    },
    'Total Waiting Time': {
      value: (c) =>  this.configMapper.formatToDayTime(c.times.waiting),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Waiting Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.waiting;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Waiting Time', value);
      }
    },
    'Total Not Responding Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.notResponding),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Not Responding Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.notResponding;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Not Responding Time', value);
      }
    },
    'Total Hold Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.hold),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Hold Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.hold;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Hold Time', value);
      }
    },
    'Total Consult Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.consult),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Consult Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.consult;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Consult Time', value);
      }
    },
    'Total Conference Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.conference),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Conference Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.conference;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Conference Time', value);
      }
    },
    'Total Wrap Up Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.wrapUp),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Wrap Up Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.wrapUp;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Wrap Up Time', value);
      }
    },
    'Total Talk Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.talk),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Talk Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.talk;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Talk Time', value);
      }
    },
    'Total handle Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.totalHandle),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total handle Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.totalHandle;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total handle Time', value);
      }
    },
    'Average Handle Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.averageHandle),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average Handle Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.averageHandle;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Average Handle Time', value);
      }
    },
    'Total Conversing Time': {
      value: (c) => this.configMapper.formatToDayTime(c.times.conversing),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Conversing Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.times.conversing;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Conversing Time', value);
      }
    },
    'Agent Rating %': {
      value: (c) => c.averageSurveyScore || c.averageSurveyScore === 0 ? c.averageSurveyScore + '%' : '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Agent Rating %',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.averageSurveyScore;
        return '" style="' + this.configMapper.getColourConfig('Agent Rating %', value);
      }
    },
    'Total Available Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalAvailableTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Available Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalAvailableTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Available Time', value);
      }
    },
    'Total Away Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalAwayTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Away Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalAwayTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Away Time', value);
      }
    },
    'Total Break Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalBreakTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Break Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalBreakTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Break Time', value);
      }
    },
    'Total Lunch Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalLunchTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Lunch Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalLunchTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Lunch Time', value);
      }
    },
    'Total Busy Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalBusyTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Busy Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalBusyTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Busy Time', value);
      }
    },
    'Total Meeting Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalMeetingTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Meeting Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalMeetingTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Meeting Time', value);
      }
    },
    'Total Training Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalTrainingTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Training Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalTrainingTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Training Time', value);
      }
    },
    'Total Off duty Time': {
      value: (c) => this.configMapper.formatToDayTime(c.presenceTotals ? c.presenceTotals.totalOffDutyTime : 0),
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Total Off duty Time',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.presenceTotals ? c.presenceTotals.totalOffDutyTime : 0;
        if (value && value !== '--') {
          value = moment.duration(value).asSeconds();
        }
        return '" style="' + this.configMapper.getColourConfig('Total Off duty Time', value);
      }
    },
    'Disposition %': {
      value: (c) => c.dispositionsPercentage || c.dispositionsPercentage === 0 ? c.dispositionsPercentage + '%' : '--',
      align: 'left',
      width: 100,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Disposition %',
        expandCollapseHeader: true
      },
      style: c => {
        let value = c.dispositionsPercentage;
        return '" style="' + this.configMapper.getColourConfig('Disposition %', value);
      }
    }
  };
}
