import { bindable, customElement } from "aurelia-framework";

@customElement('z-interaction-card')
export class InteractionCard {

  @bindable
  public interaction: any;

  @bindable
  public contact: any;

  @bindable
  public unlinked: any;

  @bindable
  public conversations: any;

  @bindable
  public voiceAuth: boolean;

  @bindable
  public type: boolean;
}