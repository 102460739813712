import {inject, LogManager, Factory} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {CommandFactory} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('ConfigureAdditionalDataService');
/*
 * */
@inject(Factory.of(CommandFactory), HttpClient)
export class ConfigureAdditionalDataService {

  constructor(commandFactory, httpClient) {
    this.commandFactory = commandFactory;
    this.httpClient = httpClient;
  }

  setAdditionalData(interactionFlowId, organisationId, additionalData) {

    let payload = {organisationId, additionalData};

    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/additionalData`)
      .asPut()
      .withContent(payload)
      .send()
      .then(response => {}, error => {});
  }
}
