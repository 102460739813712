import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

import { ConfigMapper } from './config-mapper';

const logger = LogManager.getLogger('InteractionAgentStats');

@autoinject
export class InteractionAgentStats {

  constructor(private configMapper: ConfigMapper) {}

  public columnConfig = {
    'Off': {
      tooltip: 'Offline',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'offline'),
      align: 'right',
      width: 300,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Off', this.configMapper.getAgentLiveStats(c, 'offline'))
    },
    'On': {
      tooltip: 'Online',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'online'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('On', this.configMapper.getAgentLiveStats(c, 'online'))
    },
    'On Duty': {
      tooltip: 'On Duty',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'onDuty'),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('On Duty', this.configMapper.getAgentLiveStats(c, 'onDuty'))
    },
    'Off Duty': {
      tooltip: 'Off Duty',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'offDuty'),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Off Duty', this.configMapper.getAgentLiveStats(c, 'offDuty'))
    },
    'Conversing': {
      tooltip: 'Conversing',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'conversing'),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Conversing', this.configMapper.getAgentLiveStats(c, 'conversing'))
    },
    'W A': {
      tooltip: 'Waiting Agents',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'waiting'),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W A', this.configMapper.getAgentLiveStats(c, 'waiting'))
    },
    'N R': {
      tooltip: 'Not Responding',
      value: (c) => this.configMapper.getAgentLiveStats(c, 'notResponding'),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Agents'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('N R', this.configMapper.getAgentLiveStats(c, 'notResponding'))
    }
  };
}
