import { inject, customElement, bindable, LogManager, computedFrom } from 'aurelia-framework';
/*
 */
import { SessionStore, Event } from 'zailab.common';
import {ChannelsService} from "../../channels/channels-service";
/*
 */
let logger = LogManager.getLogger('CapacityRulesWidget');

/*
 */
@customElement('z-condensed-capacity-rules-list')
@inject(Element, SessionStore, ChannelsService)
export class CapacityRulesWidget {

  @bindable canedit;
  @bindable titleoverride;
  @bindable({ attribute: 'items-per-row' }) itemsperrow;
  @bindable capacityrules = [];

  @bindable ignoreRole = false;
  @bindable role;

  isEditing = false;
  originalCapacityRules = [];
  defaultCapacityRules = [
    {
      channelName: "VOICE",
      friendlyChannelName: "Voice",
      capacity: 0
    },
    {
      channelName: "WEBCHAT",
      friendlyChannelName: "Webchat",
      capacity: 0
    },
    {
      channelName: "INSTANT_MESSAGE",
      friendlyChannelName: "Instant Message",
      capacity: 0
    },
    {
      channelName: "EMAIL",
      friendlyChannelName: "Email",
      capacity: 0
    },
    {
      channelName: "SMS",
      friendlyChannelName: "SMS",
      capacity: 0
    },
    {
      channelName: "TICKET",
      friendlyChannelName: "Ticket",
      capacity: 0
    }
  ]

  constructor(element, sessionStore, channelsService) {

    this.element = element;
    this.sessionStore = sessionStore;
    this.channelsService = channelsService;
  }

  bind() {
    if (this.capacityrules && this.capacityrules.length > 0) {
      this.defaultCapacityRules.forEach(dcr => {
        let found = false;

        this.capacityrules.forEach(cr => {
          if (cr.channelName === dcr.channelName) {
            cr.friendlyChannelName = dcr.friendlyChannelName;
            found = true;
          }
        });

        if (found === false) {
          this.capacityrules.push(dcr);
        }
      });
    }
    this.sortList();
    this.originalCapacityRules = _clone(this.capacityrules);
  }

  sortList() {
    const rules = _clone(this.capacityrules || []);
    this.orderedCapacityRules = [
      rules.find(rule => rule.channelName === 'VOICE') || this.defaultCapacityRules[0],
      rules.find(rule => rule.channelName === 'WEBCHAT') || this.defaultCapacityRules[1],
      rules.find(rule => rule.channelName === 'INSTANT_MESSAGE') || this.defaultCapacityRules[2],
      rules.find(rule => rule.channelName === 'EMAIL') || this.defaultCapacityRules[3],
      rules.find(rule => rule.channelName === 'SMS') || this.defaultCapacityRules[4],
      rules.find(rule => rule.channelName === 'TICKET') || this.defaultCapacityRules[5]
    ];

    this.orderedCapacityRules.forEach(cpr => {
      if (!cpr.channelsToInterrupt) {
        return;
      } 
      for(let value of cpr.channelsToInterrupt) {
        switch(value) {
          case 'INSTANT_MESSAGE':
            cpr.canInterruptInstantMessage = true;
            break;
          case 'SMS':
            cpr.canInterruptSMS = true;
            break;
          case 'EMAIL':
            cpr.canInterruptEmail = true;
            break;
          case 'TICKET':
            cpr.canInterruptTicket = true;
            break;
          case 'VOICE':
            cpr.canInterruptVoice = true;
            break;
          case 'WEBCHAT':
            cpr.canInterruptWebchat = true;
            break;
        }
      }
    })
  }

  get itemsPerRow() {
    return this.itemsperrow || 6;
  }

  get title() {
    return (this.titleoverride) ? this.titleoverride : 'Select Capacity Rules';
  }

  @computedFrom('capacityRules', 'canedit')
  get capacityRulesConcatenated() {

    if (!this.canedit) {
      return "Capacity rules are only available for Agents"
    }

    if (!this.capacityrules) {
      return 'No capacity Rules defined';
    }

    let _capacityRules = [];
      this.capacityrules.forEach(capacityRule => {
        _capacityRules.push(capacityRule);
      })

    if (_capacityRules.length) {
      return this._concatenateCapacityRules(_capacityRules);
    }
    return 'No capacity Rules defined';
  }

  _concatenateCapacityRules(_capacityRules) {
    let _itemsString = '';
    for (let index = 0; index < _capacityRules.length; index++) {
      _itemsString += (_capacityRules[index].friendlyChannelName || _capacityRules[index].channelName) + ' : ' + _capacityRules[index].capacity;
      if (index === _capacityRules.length - 1) {
        continue;
      }
      _itemsString += ', ';
    }
    return _itemsString;
  }

  toggleEdit(value) {
    this.isEditing = value || !this.isEditing;
  }

  acceptEdit() {
    this.orderedCapacityRules.forEach(rule => {
      rule.canEditEmail = !!rule.canEditEmail;
      rule.channelsToInterrupt = [];
      if (rule.canInterruptInstantMessage) {
        rule.channelsToInterrupt.push('INSTANT_MESSAGE');
      }
      if (rule.canInterruptSMS) {
        rule.channelsToInterrupt.push('SMS');
      }
      if (rule.canInterruptEmail) {
        rule.channelsToInterrupt.push('EMAIL');
      }
      if (rule.canInterruptTicket) {
        rule.channelsToInterrupt.push('TICKET');
      }
      if (rule.canInterruptVoice) {
        rule.channelsToInterrupt.push('VOICE');
      }
      if (rule.canInterruptWebchat) {
        rule.channelsToInterrupt.push('WEBCHAT');
      }
      delete rule.canInterruptInstantMessage;
      delete rule.canInterruptSMS;
      delete rule.canInterruptEmail;
      delete rule.canInterruptTicket;
      delete rule.canInterruptVoice;
      delete rule.canInterruptWebchat;
    });
    new Event(this.element, 'updateitems', { items: _clone(this.orderedCapacityRules), originalItems: this.originalCapacityRules });
    this.capacityrules = _clone(this.orderedCapacityRules);
    this.sortList();
    this.toggleEdit();
  }

  cancelEdit() {
    this.orderedCapacityRules = _clone(this.defaultCapacityRules)
    this.sortList();
    this.toggleEdit();
  }

  channelChanged() {
    return true;
  }
}

function _clone(data) {
  return JSON.parse(JSON.stringify(data));
}