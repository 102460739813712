import { inject, LogManager } from 'aurelia-framework';
import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('PresenceModel');

export class PresenceModel extends BaseModel {
  userId = null;
  presence = null;

  constructor(presence) {
    super();
    this.mapProperties(presence);
    this.presence = this.presence.toUpperCase();
  }
}
