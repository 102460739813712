import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager, Factory, computedFrom} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {WebSocket, CommandFactory, SessionStore, OplogService} from 'zailab.common';
import TelephoneNumbersModel from './telephone-numbers-model';
import {MembersModel} from './members-model';
/*
 * */
const logger = LogManager.getLogger('TelephoneNumbersService');
/*
 * */
@inject(HttpClient, WebSocket, EventAggregator, Factory.of(CommandFactory), SessionStore, OplogService)
export default class {

  constructor(httpClient, webSocket, eventAggregator, commandFactory, sessionStore, oplogService) {
    this.httpClient = httpClient;
    this.webSocket = webSocket;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
  }

  retrieveOrganisationNumbers() {

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest('v1/finance/telephonenumbers/numbers-view')
        .asGet()
        .send()
        .then(response => {

          let telephoneNumbers = this._modelCollection(response.telephoneNumbers ? response.telephoneNumbers : []);
          resolve(telephoneNumbers);

        }, error => reject(error));

    });
  }

  initialiseNumbersOplog() {
    let organisationId = this.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('telephone-number-projector.organisationTelephoneNumbersView'));
    });
  }

  /**
   * Retrieves the list of members for an organisation
   * @returns Promise that resolves to a list of member objects.
   * from collection 'displayMembersView'
   * from 'query-service-telephone-number'
   * @param {string} organisationId the organisationId of the organisation
   * @throws 503: Service not available
   * */

  displayMembers() {

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest('v1/finance/telephonenumbers/members-view')
        .asGet()
        .send()
        .then(response => {

          resolve(this._memberListFactory(response && response.members ? response.members : []));

        }, error => reject(error));

    });
  }

  retrieveNumbersStats() {

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest('v1/finance/telephonenumbers/number-widget-view')
        .asGet()
        .send()
        .then((response) => {

          let _count = 0;
          if (response.numbers) {
            _count = response.numbers.length;
          }
          resolve(_count);
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseStatsOplog() {
    let organisationId = this.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('telephone-number-projector.numberWidgetView'));
    });
  }

  /**
   * assigns a number to a member
   * @fires AssignTelephoneNumberToMemberCommand
   * to 'command-service-telephone-number'
   * @param {string} telephoneNumberId the member to remove
   * @param {string} telephoneNumber the member to remove
   * @param {string} memberId the member to remove
   * @param {string} userId the member to remove
   * @param {string} firstName the member to remove
   * @param {string} surname the member to remove
   * @param {string} email the member to remove
   * @param {string} organisationId the member to remove
   * @emits TelephoneNumberAssignedToMemberEvent on completion from the backend
   * */
  assignNumberToMember(telephoneNumberId, telephoneNumber, memberId, userId, firstName, surname, email, organisationId) {

    let commandPayload = {
      telephoneNumberId: telephoneNumberId,
      telephoneNumber: telephoneNumber,
      memberId: memberId,
      userId: userId,
      firstName: firstName,
      surname: surname,
      email: email,
      organisationId: organisationId
    };
    this.httpClient.createRequest(`v1/finance/telephonenumbers/${telephoneNumberId}/assigntomember`)
      .asPut()
      .withContent(commandPayload)
      .send();
  }

  /**
   * Unassigns a number from a member
   * @fires UnassignTelephoneNumberFromMemberCommand
   * to 'command-service-telephone-number'
   * @param {string} telephoneNumberId the member to remove
   * @param {string} telephoneNumber the member to remove
   * @param {string} memberId the member to remove
   * @param {string} organisationId the member to remove
   * @emits TelephoneNumberUnassignedFromMemberEvent on completion from the backend
   * */
  unAssignNumberFromMember(telephoneNumberId, telephoneNumber, memberId, organisationId) {

    let commandPayload = {
      telephoneNumberId: telephoneNumberId,
      telephoneNumber: telephoneNumber,
      memberId: memberId,
      organisationId: organisationId
    };
    this.httpClient.createRequest(`v1/finance/telephonenumbers/${telephoneNumberId}/unassignfrommember`)
      .asPut()
      .withContent(commandPayload)
      .send();
  }

  /**
   * @private
   * */
  _createRequest(uri, params, callback, rejectCallback) {
    this.httpClient.createRequest(uri)
      .asGet()
      .withParams(params)
      .send()
      .then(
        (response) => {
          callback(response);
        },
        (error) => {
          rejectCallback(error);
        }).catch((error) => {
      rejectCallback(error);
    });
  }

  /**
   * @private
   * */
  _modelCollection(numberData) {
    let numberList = [];
    numberData.forEach(number => {
      numberList.push(this._modelEntity(number));
    });
    return numberList;
  }

  _modelEntity(item) {
    return new TelephoneNumbersModel(item);
  }

  /**
   * @private
   * */
  _memberListFactory(memberData) {
    let memberList = [];
    memberData.forEach(member => {
      memberList.push(new MembersModel(member));
    });
    return memberList;
  }

  @computedFrom('sessionStore.get.organisation')
  get organisation() {
    return this.sessionStore.get.organisation;
  }
}
