import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, BindingEngine, customElement, LogManager} from 'aurelia-framework';
import {SessionStore, SortTools} from 'zailab.common';

import {ContactModel} from '../../contact/contact-model';
import {ConversationModel} from '../conversation-model';
import {ConversationService} from '../conversation-service';
import {CONVERSATION_ACTIONS} from '../../contact/contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('ListBuilder');

@customElement('z-list')
@autoinject()
export class ConversationList {
  @bindable private contact: ContactModel;

  constructor(private conversationService: ConversationService, private bindingEngine: BindingEngine, private eventAggregator: EventAggregator, private sessionStore: SessionStore) {
    this.conversationService = conversationService;
    this.bindingEngine = bindingEngine;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
  }

  public selectConversation(conversation: ConversationModel): void {
    if (!this.contact.showDispositionCode) {
      this.eventAggregator.publish(CONVERSATION_ACTIONS.SELECT, conversation);
    }
  }

  public selectActive(): void {
    if (this.contact.activeConversation.isSelected) {
      return;
    }
    this.contact.deselectAllConversations();
    this.contact.deselectAllUnlinkedInteractions();
    this.contact.selectConversation(this.contact.activeConversation.conversationId);
  }

  public showConversationsHistory(): void {
    this.contact.showConversationHistory();
  }

  public hideConversationsHistory(): void {
    this.contact.hideConversationHistory();
  }
}
