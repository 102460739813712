import {Factory, inject, LogManager} from 'aurelia-framework';
import {CommandFactory, WebSocket} from 'zailab.common';
import {HttpClient} from 'aurelia-http-client';

const logger = LogManager.getLogger('InviteService');

@inject(Factory.of(CommandFactory), WebSocket, HttpClient)
export class InviteService {
  invitationIssuedEvent = 'com.zailab.user.invitation.api.events.InvitationIssuedEvent';

  constructor(commandFactory, webSocket, httpClient) {
    this.commandFactory = commandFactory;
    this.webSocket = webSocket;
    this.httpClient = httpClient;
  }


  sendInvitation(commandPayload, callback) {
    this.subscribeToInvitationIssuedEvent(callback);
    this.httpClient.createRequest(`v1/user/invitations`)
      .asPost()
      .withContent(commandPayload)
      .send();
  }

  subscribeToInvitationIssuedEvent(callback) {
    this.webSocket.subscribe({
      name: this.invitationIssuedEvent, callback: (data) => {
        if (data && data.state) {
          callback(data.state.email);
        }
      }
    });
  }

  unSubscribeToInvitationIssuedEvent() {
    this.webSocket.unSubscribe(this.invitationIssuedEvent);
  }
}
