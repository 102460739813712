import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Alert } from '../alert-model';
import { AlertService } from '../alert-service';

const logger = LogManager.getLogger('AlertAdd');

@autoinject()
export class AlertAdd {

  public alert: Alert = new Alert();
  public isProcessing: boolean = false;

  constructor(
    private alertService: AlertService,
    private router: Router
  ) { }

  public save(): void {

    const alert: any = {
      audience: this.alert.id,
      audienceType: this.convertToEnumFormat(this.alert.audienceType),
      canDelete: this.alert.canDelete,
      icon: this.alert.icon,
      message: this.alert.message,
      name: this.alert.name,
      priority: this.convertToEnumFormat(this.alert.priority),
      trigger: this.convertToEnumFormat(this.alert.trigger)
    };
    this.isProcessing = true;
    this.alertService.insert(alert)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private convertToEnumFormat(value: string): any {
    if (value) {
      return value.toUpperCase().replace(' ', '_');
    }
    return '';
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }
}