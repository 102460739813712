import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager, } from 'aurelia-framework';
import {
  validateTrigger,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';

import { CannedResponseService } from '../../../../../cannedresponses/canned-responses-service';
import { UserPersonService } from '../../../../../user/person/user-person-service';

// @ts-ignore
import $ from 'jquery';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import { ChannelService } from '../../../../../organisation/conversation/channel/channel-service';
import { computedFrom } from 'aurelia-binding';
import { NumbersService } from '../../../../../finance/account/numbers/numbers-service';

const logger = LogManager.getLogger('SendEmailDialog');

@autoinject()
export class SendEmailDialog {

  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private nodeConfiguration: ZNodeConfig;


  private smsId: string;
  public fromNumber: string;
  public toNumber: string = '${from}';

  public errors: {[key: string]: boolean} = {}
  public valid: {[key: string]: boolean} = {}


  public smsNumbers: any[] = [];
  public noContactNumbers: string = 'No email addresses.';
  private validation: any;
  public ready = false;
  private remaining: any = null;
  private message: string = null;
  private messages: any = null;
  private limit: number = 160;

  constructor(
    private sessionStore: SessionStore,
    private validationControllerFactory: ValidationControllerFactory,
    private channelService: ChannelService,
    private cannedResponseService: CannedResponseService,
    private userPersonService: UserPersonService,
    private dialogController: DialogController,
    private numbersService: NumbersService
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.changeOrBlur;

    this.smsId = uuidv4();

  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;

    this.initialiseFormValues();
    this.retrieveNumbers();
    this.initValidation();
  }

  private retrieveNumbers(): void {
    this.numbersService
      .retrieveOrganisationNumbersList()
      .then((numbers: INumber[]) => {
        numbers = numbers.filter(number => number.numberType === 'sms')
        this.smsNumbers = numbers;
      })
      .catch(e => {
        logger.warn('Failed to retrieve flows due to ', e);
      });
  }

  private initialiseFormValues(): void {
    this.smsId = this.nodeData.properties.smsId;
    this.fromNumber = this.nodeData.properties.from;
    this.toNumber = this.nodeData.properties.to || this.toNumber;
    this.message = this.nodeData.properties.message;
  }

  private initValidation(): void {
    ValidationRules
      .ensure('toNumber')
      .required()
      .withMessage('Please enter a to number.')
      .ensure('message')
      .required()
      .withMessage('Please enter your message content.')
      .on(this);
  }

  public attached(): void {
    this.listenForKeyChanges();
  }

  private listenForKeyChanges(): any {
    this.remaining = $('#js-remaining');
    this.messages = $('#js-messages');
    $('#replyText').on('keyup keydown change', () => {
      let chars = this.message ? this.message.length : 0;
      let messages = Math.ceil(chars / this.limit);
      let remaining = messages * this.limit - (chars % (messages * this.limit) || messages * this.limit);
      if (!this.message || (this.message && this.message.length === 0)) {
        this.remaining.text('160');
        this.messages.text('1');
      } else {
        this.remaining.text(remaining);
        this.messages.text(messages);
      }
    });
  }

  public selectFromNumber(number: any): void {
    if (number) {
      this.errors.fromNumber = false;
    }
  }

  public fromNumberChanged(number: any): void {
    if (!number) {
      return;
    }
    this.errors.fromNumber = number !== this.fromNumber;
  }

  public validateToNumber(): void {
    const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gm;
    this.errors.toNumber = !this.toNumber || !phoneNumberRegex.test(this.toNumber);
  }

  @computedFrom('fromNumber', 'toNumber', 'message', 'errors')
  public get hasError(): boolean {
    this.validateToNumber();

    if (Object.keys(this.errors).find(error => !!this.errors[error])) {
      return true;
    }
    return false;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.validation
      .validate()
      .then((validation) => {
        if (!validation.valid) {
          return;
        }

        let sms = {
          channel: 'SMS',
          smsId: this.smsId,
          from: this.fromNumber,
          to: this.toNumber,
          message: this.message,
          externalReference: this.nodeData.properties.externalReference
        };
        this.nodeData.properties = {
          isDefined: true,
          ...sms
        };
        this.dialogController.ok(this.nodeData);
      })
      .catch(error => {
        logger.info('sms > validation failure', error);
      });
  }
}
