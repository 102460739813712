import {LogManager} from 'aurelia-framework';

declare let Stripe: any;

const logger = LogManager.getLogger('stripe-service');


export class StripeService {

  private scriptTag: HTMLScriptElement;

  public initStripe(): void {
    this.scriptTag = document.createElement('script');
    this.scriptTag.setAttribute('src', 'https://js.stripe.com/v3/');
    this.scriptTag.async = true;
  }

  public checkStripe(): Promise<any>{
    return new Promise((resolve) => {
      try {
        if (Stripe) {
          resolve()
        }
      } catch(e) {
        this.initStripe();
        this.scriptTag.addEventListener('load', () => {
          resolve();
        });
        document.body.appendChild(this.scriptTag);
      }
    });
  }

  public close(): void {
    this.scriptTag.remove();
  }

}