import {BalloonConfig} from './balloon-config';
import {ValueAxesConfig} from './value-axes-config';
import {CategoryAxisConfig} from './category-axis-config';
import {ExportConfig} from './export-config';
import {ChartScrollbarConfig} from './chart-scrollbar-config';
import {GraphConfig} from './graph-config';

export class ChartSettings {
  public addClassNames: boolean = true;
  public dataDateFormat: string = 'HH DD MM YYYY';
  public mouseWheelScrollEnabled: boolean = true;
  public mouseWheelZoomEnabled: boolean = false;
  public zoomOutText: string = '';
  public type: string = 'serial';
  public theme: string = 'none';
  public fontSize: string = '14';
  public color: string = 'var(--primary-accent)';
  public columnWidth: number = 0.5;
  public balloon: BalloonConfig;
  public valueAxes: ValueAxesConfig[];
  public categoryAxis: CategoryAxisConfig;
  public export: ExportConfig;
  public chartScrollbar: ChartScrollbarConfig;
  public categoryField: string = 'date';
  public graphs: GraphConfig[] = [];
  public dataProvider: any[] = [];
  public pathToImages: string = '_assets/img/amcharts/';

  constructor() {
    this.valueAxes = [new ValueAxesConfig()];
    this.balloon = new BalloonConfig();
    this.categoryAxis = new CategoryAxisConfig();
    this.export = new ExportConfig();
    this.chartScrollbar = new ChartScrollbarConfig();
  }

}
