import { LogManager, computedFrom } from 'aurelia-framework';
/**/
import { BaseModel } from 'zailab.abstract';
import { IExpectedData } from './interfaces';
/**/
const logger = LogManager.getLogger('ReminderCommandModel');
/**/
export class ReminderCommandModel extends BaseModel {
  public dueTimestamp: string = null;
  public type: string = 'followup';
  public memberId: string = null;
  public message: string = null;
  public contactId: string = null;

  constructor(data: IExpectedData) {
    super();
    super.mapProperties(data);
  }
}
