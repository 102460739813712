import { inject, LogManager } from 'aurelia-framework';
import { ObserverLocator } from 'aurelia-binding';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
/*
 */
import { SurveyWizard } from './survey-wizard';
import { SurveyService } from '../survey-service';
import { SessionStore, BootstrapFormRenderer } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('SurveyProperties');
/*
 */
@inject(ObserverLocator, SurveyService, SurveyWizard, ValidationControllerFactory, SessionStore)
export class SurveyProperties {

  validationMessage = null;
  surveyList = [];
  input;
  surveyName;

  constructor(observerLocator, surveyService, surveyWizard, validationControllerFactory, sessionStore) {

    this.observerLocator = observerLocator;
    this.surveyService = surveyService;
    this.surveyWizard = surveyWizard;
    this.sessionStore = sessionStore;

    if (this.surveyWizard.survey.surveyName) {
      this.surveyName = this.surveyWizard.survey.surveyName;
      this.surveyWizard.isPropertiesValid = true;
    } else {
      this.surveyWizard.isPropertiesValid = false;
    }

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.initValidation();
  }

  initValidation() {

    this.surveyService.retrieveSurveyList(this.sessionStore.get.organisation.organisationId).then(
      (surveyList) => {

        ValidationRules
          .customRule('uniqueName', (value) => {
            for (let survey of surveyList) {
              if (survey.name.toLowerCase() === value.toLowerCase()) {
                return false;
              }
            }
            return true;
          });

        ValidationRules
          .ensure('surveyName')
          .required().withMessage('Please enter a survey name.')
          .satisfiesRule('uniqueName').withMessage('Please enter a unique survey name.')
          .on(this);
      },
      (error) => {
        logger.info('retrieveSurveyList > error = ', error);
      });
  }

  validateName() {

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        this.surveyWizard.isPropertiesValid = false;
        return;
      }
      this.surveyWizard.survey.surveyName = this.surveyName;
      this.surveyWizard.isPropertiesValid = true;
    },
      error => {
        this.surveyWizard.isPropertiesValid = false;
      });
  }

  attached() {

    this.subscription = this.observerLocator
      .getObserver(this, 'surveyName')
      .subscribe(() => {
        this.validateName();
      });
  }

  deactivate() {
    this.subscription.dispose();
  }

  setSurveyType(surveyType) {
    this.surveyWizard.survey.type = surveyType;
  }
}

