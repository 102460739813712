import {inject, LogManager, Factory} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {CommandFactory} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('AssignCallerIdDialogService');
/*
 * */
@inject(Factory.of(CommandFactory), HttpClient)
export class AssignCallerIdDialogService {

  constructor(commandFactory, httpClient) {
    this.commandFactory = commandFactory;
    this.httpClient = httpClient;
  }

  assignCallerIdToFlow(interactionFlowId, organisationId, callerId) {

    let payload = {organisationId, callerId};

    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/caller-id`)
      .asPut()
      .withContent(payload)
      .send()
      .then(response => {}, error => {});
  }

  unAssignCallerIdFromFlow(interactionFlowId, organisationId, callerId) {

    let payload = {organisationId, callerId};

    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/caller-id`)
      .asDelete()
      .withContent(payload)
      .send()
      .then(response => {}, error => {});
  }

}
