import { LogManager, customElement, bindable, inject } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';

const logger = LogManager.getLogger('ZEmailBasicInput');

@customElement('z-email-basic-input')
export class ZEmailBasicInput {

  @bindable({ attribute: 'label' }) label: string;
  @bindable({ attribute: 'emails' }) emails: string[];
  @bindable({ attribute: 'max-emails' }) maxEmails: number;
  @bindable({ attribute: 'is-edit' }) isEdit: boolean;
  @bindable({ attribute: 'validation-controller' }) validate: ValidationController;

  private emailsFormatter: EmailsFormatter = new EmailsFormatter();
  private emailsValidationSupport: EmailsValidationSupport;

  public attached(): void {
    this.emailsValidationSupport = new EmailsValidationSupport(this.maxEmails, this.label);
    this.initValidationRules(this);
  }

  public get inputValueWrapper(): string {
    return this.emailsFormatter.joinEmails(this.emails);
  }

  public set inputValueWrapper(value: string) {
    this.emails = this.emailsFormatter.separateEmails(value);
  }

  public get emailsDisplayCompact(): string {
    if (!this.emails) {
      return '';
    }
    const emails = this.emails;
    return `${emails[0]} ${emails.length > 1 ? `(+${emails.length - 1})` : ''}`
  }

  public get emailsTitle(): string {
    if (!this.emails) {
      return '';
    }
    return this.emails.join('\r\n');
  }

  private initValidationRules(model: ZEmailBasicInput): void {

    const rules = ValidationRules
      .ensure('inputValueWrapper')
      .required()
      .withMessage(`Please enter at least one ${this.emailsValidationSupport.fieldName} email address.`)
      .then()
      .satisfies((value: string) => this.validateNotificationEmailsDisplayFormatting(value))
      .withMessage(`Please enter only valid ${this.emailsValidationSupport.fieldName} email addresses.`)

    if (this.emailsValidationSupport.maxValue > 0) {
      rules.then()
        .satisfies((value: string) => this.validateNotificationEmailsDisplayLength(value))
        .withMessage(`Please enter no more than ${this.emailsValidationSupport.maxValue} ${this.emailsValidationSupport.fieldName} email addresses.`)
    }

    rules.on(model);
  }

  private validateNotificationEmailsDisplayFormatting(value: string): boolean {
    const emails = value.split(';');
    return emails.filter((email) => EmailsValidationSupport.EMAIL_REGEXP.test(email.trim())).length === emails.length;
  }

  private validateNotificationEmailsDisplayLength(value: string): boolean {
    const emails = value.split(';');
    return emails.length <= this.emailsValidationSupport.maxValue;
  }
}

class EmailsFormatter {

  joinEmails(emails: string[]): string {
    return emails ? emails.join('; ') : '';
  }

  separateEmails(value: string): any {
    const valueEmails = value.split(';');
    const emails = [];
    valueEmails.forEach((email) => emails.push(email.trim()));
    return emails;
  }
}

export class EmailsValidationSupport {

  static EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/);

  public maxValue: number;
  public fieldName: string;

  constructor(maxValue: number, fieldName: string) {
    this.maxValue = maxValue;
    this.fieldName = fieldName;
  }
}