/*
*/
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
*/
const logger = LogManager.getLogger('StatShort');
/*
*/
@customElement('z-stats-short')
export class StatShort {

  @bindable value;
  @bindable subvalue;
  @bindable label;
  @bindable color;
	@bindable indicator;
	@bindable position = 'left';
	@bindable textalignment;
	@bindable bordertop;
	@bindable borderbottom;
	@bindable placeholder;
	@bindable widthsize = 'third';
}
