/*
*/
import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
import { HttpClient } from 'aurelia-http-client';
/*
*/
import { SessionStore, UrlFactory, DateTimeTools } from 'zailab.common';
/*
*/
import { VIEW_ACTIONS } from './view.actions.js';
/*
*/
const logger = LogManager.getLogger('ViewService');

/*
*/
@inject(Dispatcher, HttpClient, SessionStore, UrlFactory, DateTimeTools)
export class ViewService {

  constructor(dispatcher, httpClient, sessionStore, urlFactory, dateTimeTools) {

    this.dispatcher = dispatcher;
    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.urlFactory = urlFactory;
    this.dateTimeTools = dateTimeTools;
  }

  retrieveForms(memberId) {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.searchPerformedAssessments(organisationId, memberId, '', '', '', '', '', '');
  }

  searchPerformedAssessments(organisationId, memberId, fromDate, toDate, channel, formName, assessorFirstName, assessorSurname) {
    this.httpClient.createRequest('v1/organisation/assessments')
      .asGet()
      .withParams({
        memberId: memberId,
        fromDate: fromDate ? fromDate : '',
        toDate: toDate ? toDate : '',
        channel: channel ? channel : '',
        formName: formName ? formName : '',
        assessorFirstName: assessorFirstName ? assessorFirstName : '',
        assessorSurname: assessorSurname ? assessorSurname : ''
      })
      .send()
      .then(response => {
        if (response && response.assessments) {

          this.dispatcher.dispatch(VIEW_ACTIONS.RETRIEVE_FORMS, response.assessments);
        } else {

          this.dispatcher.dispatch(VIEW_ACTIONS.RETRIEVE_FORMS, []);
        }
      }, error => {
        this.dispatcher.dispatch(VIEW_ACTIONS.RETRIEVE_FORMS, [])
      });
  }
}