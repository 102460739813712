import { LogManager, computedFrom } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';
import { StatsConfig } from "../components/stats-config";
import { StatsBuilder } from "../components/stats-builder";
import { SegmentModel } from "../components/segment-model";

//@ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('LiveDashboardModel');

interface ICallbacks {
  [key: string]: number;
}

interface INotHandledCount {
  abandoned: number;
  callback: number;
  mailbox: number;
  missed: number;
  cleared: number;
  forwarded: number;
}

export class LiveDashboardModel extends BaseModel {

  public dashboardId: string = null;
  public taskTemplateId: string = null;
  public serviceId: string = null;
  public organisationId: string = null;
  public isSelected: boolean = false;

  public agentsReady: number = null;
  public agentsNotReady: number = null;

  public callsWaiting: number = null;
  public emailsWaiting: number = null;
  public smssWaiting: number = null;

  public numberOfCallsWaitingOutOfSla: number = null;
  public numberOfEmailsWaitingOutOfSla: number = null;
  public numberOfSMSsWaitingOutOfSla: number = null;

  public callsReceived: number = null;
  public emailsReceived: number = null;
  public smssReceived: number = null;
  public webchatsReceived: number = null;
  public imsReceived: number = null;
  public ticketsReceived: number = null;

  public numberOfCallsHandled: number = null;
  public numberOfEmailsHandled: number = null;
  public numberOfSMSsHandled: number = null;
  public numberOfWebchatsHandled: number = null;
  public numberOfIMsHandled: number = null;
  public numberOfTicketsHandled: number = null;

  public notHandledCount: INotHandledCount = { abandoned: null, callback: null, mailbox: null, missed: null, cleared: null, forwarded: null };

  public percentageAnsweredInSLA: number = null;
  
  public callsAnsweredOutOfSla: number = null;
  public emailsServedOutOfSla: number = null;
  public smssServedOutOfSla: number = null;
  public ticketsServedOutOfSla: number = null;
  public webchatsServedOutOfSla: number = null;
  public imsServedOutOfSla: number = null;

  public averageHandleTime: number = null;
  public averageEmailHandleTime: number = null;
  public averageSMSHandleTime: number = null;
  public averageWebchatHandleTime: number = null;
  public averageIMHandleTime: number = null;
  public averageTicketHandleTime: number = null;

  public averageWaitTime: number = null;
  public averageEmailWaitTime: number = null;
  public averageSMSWaitTime: number = null;
  public averageWebchatWaitTime: number = null;
  public averageIMWaitTime: number = null;
  public averageTicketWaitTime: number = null;

  public longestWaitTime: number = null;
  public longestEmailWaitTime: number = null;
  public longestSMSWaitTime: number = null;

  public callsAnsweredOutOfSlaPercentage: number = null;
  public emailsServedOutOfSlaPercentage: number = null;
  public smssServedOutOfSlaPercentage: number = null;
  public webchatsServedOutOfSlaPercentage: number = null;
  public imsServedOutOfSlaPercentage: number = null;
  public ticketsServedOutOfSlaPercentage: number = null;

  public waitingCalls: any = {};
  public waitingEmails: any = {};
  public waitingSMSs: any = {};
  public waitingWebchats: any = {};
  public waitingIMs: any = {};
  public waitingTickets: any = {};

  public outOfSLACalls: any = {};
  public outOfSLAEmails: any = {};
  public outOfSLASMSs: any = {};
  public outOfSLAWebchats: any = {};
  public outOfSLAIMs: any = {};
  public outOfSLATickets: any = {};

  public longestWaitingCallTimestamp: number = null;
  public longestWaitingEmailTimestamp: number = null;
  public longestWaitingSMSTimestamp: number = null;
  public longestWaitingWebchatTimestamp: number = null;
  public longestWaitingIMTimestamp: number = null;
  public longestWaitingTicketTimestamp: number = null;

  public waitingCallbacks: Array<ICallbacks> = [];
  public outOfSlaCallbacks: Array<ICallbacks> = [];
  public longestWaitingCallback: number = null;

  public serviceName: string;
  public taskTemplateName: string;
  public loading: boolean = false;
  public taskTemplates: Array<ITaskTemplate> = [];

  public agentsWaiting: string = null;
  public agentsConversing: string = null;
  public agentsNotResponding: string = null;

  private minimalLiveDashboard;

  constructor(dashboardData: any, minimalLiveDashboard?: boolean) {
    super();
    this.minimalLiveDashboard = minimalLiveDashboard;
    this.mapProperties(dashboardData);
  }

  @computedFrom('agentsReady', 'agentsNotReady')
  public get showAgentStatusSegments(): boolean {
    return !(this.agentsReady && '-' === this.agentsReady.toString());
  }

  @computedFrom('agentsReady', 'agentsNotReady',
    'waitingCalls', 'waitingEmails', 'waitingSMSs', 'waitingWebchats', 'waitingCallbacks',
    'outOfSLACalls', 'outOfSLAEmails', 'outOfSLASMSs', 'outOfSLAWebchats', 'outOfSlaCallbacks',
    'callsReceived', 'emailsReceived', 'smssReceived', 'webchatsReceived',
    'numberOfCallsHandled', 'numberOfEmailsHandled', 'numberOfSMSsHandled', 'numberOfWebchatsHandled',
    'notHandledCount.abandoned', 'notHandledCount.mailbox', 'notHandledCount.callback', 'notHandledCount.missed', 'notHandledCount.forwarded')
  public get widgetsConfig(): StatsConfig[] {
    let tempWidgetConfig: StatsConfig[] = [];
    let agentStatsBuilder: StatsBuilder = new StatsBuilder()
      .withSegmentsLabel('Total')
      .withTitle('Agent Status')
      .withId('agent-status-live')
      .withIsLive(true);
    if (this.showAgentStatusSegments) {
      agentStatsBuilder
        .withSegment(new SegmentModel(this.agentsReady || 0, 'color-green', 'On Duty'))
        .withSegment(new SegmentModel(this.agentsNotReady || 0, 'color-yellow', 'Off Duty'));
    }
    tempWidgetConfig.push(agentStatsBuilder.build());
    tempWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(Object.keys(this.waitingCalls).length || 0, 'color-anakiwa', 'Call', 'Calls'))
      .withSegment(new SegmentModel(Object.keys(this.waitingEmails).length || 0, 'color-brilliant-rose', 'Email', 'Emails'))
      .withSegment(new SegmentModel(Object.keys(this.waitingSMSs).length || 0, 'color-bright-lilac', 'SMS', 'SMSs'))
      .withSegment(new SegmentModel(Object.keys(this.waitingCallbacks).length || 0, 'color-cornflower-blue', 'Callback', 'Callbacks'))
      .withSegment(new SegmentModel(Object.keys(this.waitingWebchats).length || 0, 'color-electric-violet', 'Webchat', 'Webchats'))
      .withSegment(new SegmentModel(Object.keys(this.waitingIMs).length || 0, 'color-green', 'Instant Message', 'Instant Messages'))
      .withSegment(new SegmentModel(Object.keys(this.waitingTickets).length || 0, 'color-yellow', 'Ticket', 'Tickets'))
      .withSegmentsLabel('Waiting')
      .withTitle('Waiting')
      .withId('waiting-live')
      .routeToView(this.minimalLiveDashboard ? '' : 'waiting')
      .withParamData('serviceId', 'workTypeId')
      .withIsLive(true)
      .build());
    tempWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(Object.keys(this.outOfSLACalls).length || 0, 'color-anakiwa', 'Call', 'Calls'))
      .withSegment(new SegmentModel(Object.keys(this.outOfSLAEmails).length || 0, 'color-brilliant-rose', 'Email', 'Emails'))
      .withSegment(new SegmentModel(Object.keys(this.outOfSLASMSs).length || 0, 'color-bright-lilac', 'SMS', 'SMSs'))
      .withSegment(new SegmentModel(Object.keys(this.outOfSlaCallbacks).length || 0, 'color-cornflower-blue', 'Callback', 'Callbacks'))
      .withSegment(new SegmentModel(Object.keys(this.outOfSLAWebchats).length || 0, 'color-electric-violet', 'Webchat', 'Webchats'))
      .withSegment(new SegmentModel(Object.keys(this.outOfSLAIMs).length || 0, 'color-green', 'Instant Message', 'Instant Messages'))
      .withSegment(new SegmentModel(Object.keys(this.outOfSLATickets).length || 0, 'color-yellow', 'Ticket', 'Tickets'))
      .withSegmentsLabel('Out of SLA')
      .withTitle('Out of SLA')
      .withId('out-of-sla-live')
      .routeToView(this.minimalLiveDashboard ? '' : 'waiting')
      .withParamData('serviceId', 'workTypeId', 'outOfSLA')
      .withIsLive(true)
      .build());
    tempWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(this.callsReceived || 0, 'color-anakiwa', 'Call', 'Calls'))
      .withSegment(new SegmentModel(this.emailsReceived || 0, 'color-brilliant-rose', 'Email', 'Emails'))
      .withSegment(new SegmentModel(this.smssReceived || 0, 'color-bright-lilac', 'SMS', 'SMSs'))
      .withSegment(new SegmentModel(this.webchatsReceived || 0, 'color-electric-violet', 'Webchat', 'Webchats'))
      .withSegment(new SegmentModel(this.imsReceived || 0, 'color-green', 'Instant Message', 'Instant Messages'))
      .withSegment(new SegmentModel(this.ticketsReceived || 0, 'color-yellow', 'Ticket', 'Tickets'))
      .withSegmentsLabel('Total')
      .withTitle('Total Received')
      .withId('total')
      .routeToView(this.minimalLiveDashboard ? '' : 'total-received')
      .withParamData('serviceId', 'workTypeId')
      .build());
    tempWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(this.numberOfCallsHandled || 0, 'color-anakiwa', 'Call', 'Calls'))
      .withSegment(new SegmentModel(this.numberOfEmailsHandled || 0, 'color-brilliant-rose', 'Email', 'Emails'))
      .withSegment(new SegmentModel(this.numberOfSMSsHandled || 0, 'color-bright-lilac', 'SMS', 'SMSs'))
      .withSegment(new SegmentModel(this.numberOfWebchatsHandled || 0, 'color-electric-violet', 'Webchat', 'Webchats'))
      .withSegment(new SegmentModel(this.numberOfIMsHandled || 0, 'color-green', 'Instant Message', 'Instant Messages'))
      .withSegment(new SegmentModel(this.numberOfTicketsHandled || 0, 'color-yellow', 'Ticket', 'Tickets'))
      .withSegmentsLabel('Handled')
      .withTitle('Handled')
      .withId('handled')
      .routeToView(this.minimalLiveDashboard ? '' : 'handled')
      .withParamData('serviceId', 'workTypeId')
      .build());
    tempWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(this.notHandledCount.abandoned || 0, 'color-anakiwa', 'Abandoned', 'Abandoned'))
      .withSegment(new SegmentModel(this.notHandledCount.callback || 0, 'color-cornflower-blue', 'Callback', 'Callbacks'))
      .withSegment(new SegmentModel(this.notHandledCount.mailbox || 0, 'color-marigold-yellow', 'Voicemail', 'Voicemails'))
      .withSegment(new SegmentModel(this.notHandledCount.missed || 0, 'color-salmon', 'Missed'))
      .withSegment(new SegmentModel(this.notHandledCount.cleared || 0, 'color-brilliant-rose', 'Cleared'))
      .withSegment(new SegmentModel(this.notHandledCount.forwarded || 0, 'color-azure-radiance', 'Forwarded'))
      .withSegmentsLabel('Not Handled')
      .withTitle('Not Handled')
      .withId('not-handled')
      .routeToView(this.minimalLiveDashboard ? '' : 'not-handled')
      .withParamData('serviceId', 'workTypeId')
      .build());

    return tempWidgetConfig;
  }

  @computedFrom('longestWaitingCallTimestamp', 'longestWaitingEmailTimestamp', 'longestWaitingSMSTimestamp', 'longestWaitingCallback', 'longestWaitingWebchatTimestamp', 'longestWaitingIMTimestamp',
    'averageHandleTime', 'averageEmailHandleTime', 'averageSMSHandleTime', 'averageWebchatHandleTime',
    'averageEmailWaitTime', 'averageSMSWaitTime', 'averageWebchatWaitTime')
  public get simpleWidgetsConfig(): StatsConfig[] {
    let tempSimpleWidgetConfig: StatsConfig[] = [];
    tempSimpleWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(this.longestWaitingCallTimestamp || 0, 'color-anakiwa', 'Call'))
      .withSegment(new SegmentModel(this.longestWaitingEmailTimestamp || 0, 'color-brilliant-rose', 'Email'))
      .withSegment(new SegmentModel(this.longestWaitingSMSTimestamp || 0, 'color-bright-lilac', 'SMS'))
      .withSegment(new SegmentModel(this.longestWaitingCallback || 0, 'color-cornflower-blue', 'Callback'))
      .withSegment(new SegmentModel(this.longestWaitingWebchatTimestamp || 0, 'color-electric-violet', 'Webchat'))
      .withSegment(new SegmentModel(this.longestWaitingIMTimestamp || 0, 'color-green', 'Instant Message'))
      .withSegment(new SegmentModel(this.longestWaitingTicketTimestamp || 0, 'color-yellow', 'Ticket'))
      .withTitle('Longest Waiting')
      .withId('longest-waiting-live')
      .routeToView(this.minimalLiveDashboard ? '' : 'waiting')
      .withParamData('serviceId', 'workTypeId', 'longestWaiting')
      .withIsLive(true)
      .withHiddenState(this.minimalLiveDashboard)
      .build());
    tempSimpleWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(this.averageHandleTime || 0, 'color-anakiwa', 'Calls'))
      .withSegment(new SegmentModel(this.averageEmailHandleTime || 0, 'color-brilliant-rose', 'Emails'))
      .withSegment(new SegmentModel(this.averageSMSHandleTime || 0, 'color-bright-lilac', 'SMSs'))
      .withSegment(new SegmentModel(this.averageWebchatHandleTime || 0, 'color-electric-violet', 'Webchats'))
      .withSegment(new SegmentModel(this.averageIMHandleTime || 0, 'color-green', 'Instant Message'))
      .withSegment(new SegmentModel(this.averageTicketHandleTime || 0, 'color-yellow', 'Ticket'))
      .withTitle('Average Handle Time')
      .withId('handle-time')
      .withHiddenState(this.minimalLiveDashboard)
      .build());
    tempSimpleWidgetConfig.push(new StatsBuilder()
      .withSegment(new SegmentModel(this.averageWaitTime || 0, 'color-anakiwa', 'Calls'))
      .withSegment(new SegmentModel(this.averageEmailWaitTime || 0, 'color-brilliant-rose', 'Emails'))
      .withSegment(new SegmentModel(this.averageSMSWaitTime || 0, 'color-bright-lilac', 'SMSs'))
      .withSegment(new SegmentModel(this.averageWebchatWaitTime || 0, 'color-electric-violet', 'Webchats'))
      .withSegment(new SegmentModel(this.averageIMWaitTime || 0, 'color-green', 'Instant Message'))
      .withSegment(new SegmentModel(this.averageTicketWaitTime || 0, 'color-yellow', 'Ticket'))
      .withTitle('Average Wait Time')
      .withId('wait-time')
      .withHiddenState(this.minimalLiveDashboard)
      .build());
    return tempSimpleWidgetConfig;
  }

  @computedFrom('callsAnsweredOutOfSla', 'callsAnsweredOutOfSlaPercentage',
    'emailsServedOutOfSla', 'messagesServedOutOfSlaPercentage',
    'smssServedOutOfSla', 'smssServedOutOfSlaPercentage', 'webchatsServedOutOfSlaPercentage')
  public get outOfSlaWidget(): object {

    this.callsAnsweredOutOfSla = this.callsAnsweredOutOfSla || 0;
    this.callsAnsweredOutOfSlaPercentage = this.callsAnsweredOutOfSlaPercentage || 0;
    this.emailsServedOutOfSla = this.emailsServedOutOfSla || 0;
    this.emailsServedOutOfSlaPercentage = this.emailsServedOutOfSlaPercentage || 0;
    this.smssServedOutOfSla = this.smssServedOutOfSla || 0;
    this.smssServedOutOfSlaPercentage = this.smssServedOutOfSlaPercentage || 0;
    this.ticketsServedOutOfSla = this.ticketsServedOutOfSla || 0;
    this.ticketsServedOutOfSlaPercentage = this.ticketsServedOutOfSlaPercentage || 0;

    let statsCustomWidgetConfig = {
      segments: [
        {
          segmentLength: `${this.callsAnsweredOutOfSla || 0} (${this.callsAnsweredOutOfSlaPercentage}%)`,
          segmentColor: 'color-anakiwa',
          label: 'Calls',
          growValue: this.callsAnsweredOutOfSlaPercentage
        },
        {
          segmentLength: `${this.emailsServedOutOfSla || 0} (${this.emailsServedOutOfSlaPercentage}%)`,
          segmentColor: 'color-brilliant-rose',
          label: 'Emails',
          growValue: this.emailsServedOutOfSlaPercentage
        },
        {
          segmentLength: `${this.smssServedOutOfSla || 0} (${this.smssServedOutOfSlaPercentage}%)`,
          segmentColor: 'color-bright-lilac',
          label: 'SMSs',
          growValue: this.smssServedOutOfSlaPercentage
        },
        {
          segmentLength: `${this.webchatsServedOutOfSla || 0} (${this.webchatsServedOutOfSlaPercentage}%)`,
          segmentColor: 'color-electric-violet',
          label: 'Webchats',
          growValue: this.webchatsServedOutOfSlaPercentage
        },
        {
          segmentLength: `${this.imsServedOutOfSla || 0} (${this.imsServedOutOfSlaPercentage}%)`,
          segmentColor: 'color-green',
          label: 'Instant Message',
          growValue: this.imsServedOutOfSlaPercentage
        },
        {
          segmentLength: `${this.ticketsServedOutOfSla || 0} (${this.ticketsServedOutOfSlaPercentage}%)`,
          segmentColor: 'color-yellow',
          label: 'Tickets',
          growValue: this.ticketsServedOutOfSlaPercentage
        }
      ],
      title: 'Handled Out of SLA',
      isLive: false,
      id: 'out-of-sla',
      isHidden: this.minimalLiveDashboard
    };

    return statsCustomWidgetConfig;
  }

  public get numberOfWaitingCalls(): string {
    const keys = Object.keys(this.waitingCalls);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfWaitingCallbacks(): string {
    const keys = Object.keys(this.waitingCallbacks);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfWaitingWebchats(): string {
    const keys = Object.keys(this.waitingWebchats);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfWaitingEmails(): string {
    const keys = Object.keys(this.waitingEmails);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfWaitingIMs(): string {
    const keys = Object.keys(this.waitingIMs);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfWaitingSMSs(): string {
    const keys = Object.keys(this.waitingSMSs);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfWaitingTickets(): string {
    const keys = Object.keys(this.waitingTickets);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLACalls(): string {
    const keys = Object.keys(this.outOfSLACalls);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLACallbacks(): string {
    const keys = Object.keys(this.outOfSlaCallbacks);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLAWebchats(): string {
    const keys = Object.keys(this.outOfSLAWebchats);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLAEmails(): string {
    const keys = Object.keys(this.outOfSLAEmails);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLAIMs(): string {
    const keys = Object.keys(this.outOfSLAIMs);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLASMSs(): string {
    const keys = Object.keys(this.outOfSLASMSs);
    return keys.length ? keys.length + '' : '--';
  }

  public get numberOfOutOfSLATickets(): string {
    const keys = Object.keys(this.outOfSLATickets);
    return keys.length ? keys.length + '' : '--';
  }
}
