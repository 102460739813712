import { UserSessionModel } from './../../../../_common/stores/sessionmodels/user-session-model';
import { SessionStore } from './../../../../_common/stores/session-store';
import { FeatureFlagService } from 'zailab.common';
import {autoinject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { computedFrom } from 'aurelia-binding';

const logger = LogManager.getLogger('LiveDashboardRoutes');

@autoinject
export class LiveDashboardRoutes {

  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private sessionStore: SessionStore
  ) {}

  public configureRouter(config: any, router: any): void {

    let routeMap = [{
      route: '',
      name: 'livedashboard',
      moduleId: PLATFORM.moduleName('features/interaction/dashboard/live-dashboard/live-dashboard'),
      nav: false,
      title: 'Live Dashboard'
    }, {
      route: 'total-received',
      name: 'total-received',
      moduleId: PLATFORM.moduleName('features/interaction/dashboard/live-dashboard/total/total-received'),
      nav: false,
      title: 'Total Received'
    }, {
      route: 'waiting',
      name: 'waiting',
      moduleId: PLATFORM.moduleName('features/interaction/dashboard/live-dashboard/waitinginteractions/waiting-interactions'),
      nav: false,
      title: 'Waiting',
      settings: {
        data: {
          'Total Waiting Interactions': ['serviceId', 'workTypeId'],
          'Total Waiting Interactions Out of SLA': ['serviceId', 'workTypeId', 'outOfSLA'],
          'Longest Waiting Interactions': ['serviceId', 'workTypeId', 'longestWaiting']
        }
      }
    }, {
      route: 'handled',
      name: 'handled',
      moduleId: PLATFORM.moduleName('features/interaction/dashboard/live-dashboard/handled/handled'),
      nav: false,
      title: 'Total Handled'
    }, {
      route: 'not-handled',
      name: 'not-handled',
      moduleId: PLATFORM.moduleName('features/interaction/dashboard/live-dashboard/nothandled/not-handled'),
      nav: false,
      title: 'Total Not Handled'
    }];

    config.map(routeMap);
    this.router = router;
  }

  public activate(): void {
    this.getFeatureFlags();
  }

  private getFeatureFlags(): void {
    this.featureFlagService
      .isEnabled('AgentLiveDashboardAccess')
      .then(async (agentLiveDashboardAccess) => {
        if (!agentLiveDashboardAccess) {
          if (this.user && this.user.hasAgentRole) {
            this.router.parent.navigate('dashboard');
          }
        }
      });
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }
}