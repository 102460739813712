import { LogManager } from 'aurelia-framework';
/*
 */
import { ArrayTools, Event } from 'zailab.common';
import {SingleSelect, MultipleSelect, ToggleDelete, ToggleSearch, ToggleEdit, ToggleBulkEdit} from './list-actions';
/*
 */
const logger = LogManager.getLogger('List');
/*
 */
export class List {

  constructor(sessionStore) {

    this.sessionStore = sessionStore;
    this._list_instance = {
      required: false,
      selectionType: undefined,
      select: undefined,
      autoSelect: true,
      multiSelect: undefined,
      toggleDelete: undefined,
      toggleBulkEdit: undefined,
      selectToDelete: undefined,
      selectToBulkEdit: undefined,
      toggleSearch: undefined,
      searchTerm: undefined,
      toggleEdit: undefined,

      // Collections
      items: [],
      clonedItems: [],
      selectedItems: [],
      itemsToDelete: [],
      itemsToBulkEdit: [],

      // Runtime
      element: undefined,
      uniqueIdentifier: 'id',
      selectionStrategy: List.SINGLE_SELECTION_TYPE,

      // Flags
      addEnabled: false,
      deleteEnabled: false,
      bulkEditEnabled: false,
      clickEnabled: false,
      hasRollover: false,
      orderByDisabled: false,

      // Properties
      proportion: 'medium',
      tilesPerRow: 6,
      description: 'description',
      descriptionTwo: 'descriptionTwo',
      icon: 'icon',
      rolloverText: 'rolloverTextContent',
      title: '',
      mode: 'tiles',  // 'tiles' or 'row'
      actionMenu: [],

      // States
      isDeleting: false,
      isBulkEditing: false,
      isSearching: false,
      isEditing: false,
      isDisabled: false
    };
  }

  get list_instance() {
    return this._list_instance;
  }

  set list_instance(value) {
    this._list_instance = value;
  }

  static get CUSTOM_TYPE() {
    return 'custom';
  };

  static get SINGLE_SELECTION_TYPE() {
    return 'single';
  };

  static get MULTIPLE_SELECTION_TYPE() {
    return 'multiple';
  };

  set tilesPerRow(_number) {
    this._list_instance.tilesPerRow = _number;
  }
  
  get tilesPerRow() {
    return this._list_instance.tilesPerRow;
  }

  set mode(_text) {
    this._list_instance.mode = _text;
  }

  get mode() {
    return this._list_instance.mode;
  }

  set proportion(_number) {
    this._list_instance.proportion = _number;
  }

  get proportion() {
    return this._list_instance.proportion;
  }
  
  set hasRollover(_true) {
    this._list_instance.hasRollover = _true;
  }
  
  get hasRollover() {
    return this._list_instance.hasRollover
  }  

  set rolloverText(_text) {
    this._list_instance.rolloverText = _text;
  }
  
  get rolloverText() {
    return this._list_instance.rolloverText
  }

  set required(required) {
    this._list_instance.required = required;
  }

  get clickEnabled() {
    return this._list_instance.clickEnabled
  }

  set clickEnabled(enabled) {
    this._list_instance.clickEnabled = enabled;
  }

  get required() {
    return this._list_instance.required;
  }

  set title(title) {
    this._list_instance.title = title;
  }

  get title() {
    return this._list_instance.title;
  }

  set description(description) {
    this._list_instance.description = description;
  }

  get description() {
    return this._list_instance.description;
  }

  set descriptionTwo(descriptionTwo) {
    this._list_instance.descriptionTwo = descriptionTwo;
  }

  get descriptionTwo() {
    return this._list_instance.descriptionTwo;
  }

  set items(items) {
    this._list_instance.items = items;
  }

  get items() {
    return this._list_instance.items;
  }

  set clonedItems(clonedItems) {
    this._list_instance.clonedItems = clonedItems;
  }

  get clonedItems() {
    return this._list_instance.clonedItems;
  }

  set selectedItems(selectedItems) {
    this._list_instance.selectedItems = selectedItems;
  }

  get selectedItems() {
    return this._list_instance.selectedItems;
  }

  set value(value) {
    this._list_instance.value = value;
  }

  get value() {
    return this._list_instance.value;
  }

  set value1(value) {
    this._list_instance.value1 = value;
  }

  get value1() {
    return this._list_instance.value1;
  }

  set value2(value) {
    this._list_instance.value2 = value;
  }

  get value2() {
    return this._list_instance.value2;
  }

  set icon(icon) {
    this._list_instance.icon = icon;
  }

  get icon() {
    return this._list_instance.icon;
  }

  set uniqueIdentifier(uniqueIdentifier) {
    this._list_instance.uniqueIdentifier = uniqueIdentifier;
  }

  get uniqueIdentifier() {
    return this._list_instance.uniqueIdentifier;
  }

  set orderByDisabled(orderByDisabled) {
    this._list_instance.orderByDisabled = orderByDisabled;
  }

  get orderByDisabled() {
    return this._list_instance.orderByDisabled;
  }

  set displayId(displayId) {
    this._list_instance.displayId = displayId;
  }

  get displayId() {
    return this._list_instance.displayId;
  }

  set selectionStrategy(selectionStrategy) {
    this._list_instance.selectionStrategy = selectionStrategy;
  }

  get selectionStrategy() {
    return this._list_instance.selectionStrategy;
  }

  set select(select) {
    this._list_instance.select = select;
  }

  get select() {
    return this._list_instance.select;
  }

  set element(element) {
    this._list_instance.element = element;
  }

  get element() {
    return this._list_instance.element;
  }

  set addEnabled(addEnabled) {
    this._list_instance.addEnabled = addEnabled;
  }

  get addEnabled() {
    return this._list_instance.addEnabled;
  }

  set toggleDelete(toggleDelete) {
    this._list_instance.toggleDelete = toggleDelete;
  }

  get toggleDelete() {
    return this._list_instance.toggleDelete;
  }

  set toggleBulkEdit(toggleBulkEdit) {
    this._list_instance.toggleBulkEdit = toggleBulkEdit;
  }

  get toggleBulkEdit() {
    return this._list_instance.toggleBulkEdit;
  }

  selectToDelete(item) {
    item.isDeleting = !item.isDeleting;
  }

  selectToBulkEdit(item) {
    item.isBulkEditing = !item.isBulkEditing;
  }

  set isDeleting(isDeleting) {
    this._list_instance.isDeleting = isDeleting;
  }

  get isDeleting() {
    return this._list_instance.isDeleting;
  }

  set isBulkEditing(isBulkEditing) {
    this._list_instance.isBulkEditing = isBulkEditing;
  }

  get isBulkEditing() {
    return this._list_instance.isBulkEditing;
  }

  set toggleEdit(toggleEdit) {
    this._list_instance.toggleEdit = toggleEdit;
  }

  get toggleEdit() {
    return this._list_instance.toggleEdit;
  }

  set isEditing(isEditing) {
    this._list_instance.isEditing = isEditing;
  }

  get isEditing() {
    return this._list_instance.isEditing;
  }

  set isDisabled(isDisabled) {
    this._list_instance.isDisabled = isDisabled;
  }

  get isDisabled() {
    return this._list_instance.isDisabled;
  }

  set toggleSearch(toggleSearch) {
    this._list_instance.toggleSearch = toggleSearch;
  }

  get toggleSearch() {
    return this._list_instance.toggleSearch;
  }

  set isSearching(isSearching) {
    this._list_instance.isSearching = isSearching;
  }

  get isSearching() {
    return this._list_instance.isSearching;
  }

  set searchTerm(searchTerm) {
    this._list_instance.searchTerm = searchTerm;
  }

  get searchTerm() {
    return this._list_instance.searchTerm;
  }

  set autoSelect(boolean) {
    this._list_instance.autoSelect = boolean;
  }

  get autoSelect() {
    return this._list_instance.autoSelect;
  }

  selectDefaultItems() {
    let hasSelected = false;
    let defaultItemSelection;

    if (_hasItems(this.items)) {
      this.items.forEach(item => {
        hasSelected = item.isSelected || hasSelected;

        if (this.selectionStrategy(item, this.multiSelect)) {
          if (this.multiselect) {
            defaultItemSelection = defaultItemSelection || [];
            defaultItemSelection.push(item);
          } else {
            defaultItemSelection = item;
          }
        }
      });
    }

    if (!hasSelected) {
      if (defaultItemSelection) {
        if (!_selectArrayItems(defaultItemSelection)) {
          this.select(defaultItemSelection, this.items, this.required);
          new Event(this.element, 'select', { list: this.items, item: defaultItemSelection });
        } else {
          for (let _item of defaultItemSelection) {
            this.select(_item, this.items, this.required);
            new Event(this.element, 'select', { list: this.items, item: _item });
          }
        }
        return;
      }
      if (!_selectFirstItem(this.items, this.displayId, this.element)) {
      }
    }
  }

  selectSelectedItems() {
    for (let item of this.items) {
      for (let _item of this.selectedItems) {
        this._selectIfItemsMatch(item, _item);
      }
    }
  }

  _selectIfItemsMatch(item, _item) {
    if (item[this.uniqueIdentifier] === _item[this.uniqueIdentifier]) {
      this.select(item, this.items, this.required);
      if (_isCustomEventElementSpecified(this.element)) {
        new Event(this.element, 'select', {
          list: this.items,
          item: item
        });
      }
    }
  }

  static Builder(sessionStore) {

    const list = new List(sessionStore);

    class Builder {
      /**
       * Do you have to have at least 1 item selected.
       * @param {boolean} required
       * */
      required(required) {
        list.required = required;
        return this;
      }
      /**
       * Is it a single/multiple selection list
       * @param {string} selectionType
       * */
      selectionType(selectionType) {

        _isRequiredSpecified(list.required);
        _isCustomEventElementSpecified(list.element);
        if (_selectionTypeSpecified(selectionType)) {

          let selectStrategy = {};
          selectStrategy[List.SINGLE_SELECTION_TYPE] = () => {
            list.select = new SingleSelect(list.required, list.element);
          };
          selectStrategy[List.MULTIPLE_SELECTION_TYPE] = () => {
            list.select = new MultipleSelect(list.required, list.element);
          };
          selectStrategy.hasOwnProperty(selectionType) && selectStrategy[selectionType]();
          list.multiselect = selectionType === List.MULTIPLE_SELECTION_TYPE;
        }
        return this;
      }

      autoSelect(_boolean) {
        list.autoSelect = _boolean;
        return this;
      }
      /**
       * Title of your list
       * @param {string} title
       * */
      title(title) {
        list.title = title;
        return this;
      }
      /**
       * A description of the view
       * @param {string} description
       * */
      description(description) {
        list.description = description;
        return this;
      }
      /**
       * A descriptionTwo of the view
       * @param {string} descriptionTwo
       * */
      descriptionTwo(descriptionTwo) {
        list.descriptionTwo = descriptionTwo;
        return this;
      }
      /**
       * Your list items
       * @param {Array} items
       * */
      items(items) {
        list.items = items;
        return this;
      }      
      
      tilesPerRow(numberOfTiles) {
        list.tilesPerRow = numberOfTiles;
        return this;
      }

      hasRollover(_boolean) {
        list.hasRollover = _boolean;
        return this;
      }

      rolloverText(_text) {
        list.rolloverText = _text;
        return this;
      }

      withActionMenu(_items) {
        list.actionMenu = _items;
        return this
      }

      withProportion(_height) {
        list.proportion = _height;
        return this
      }

      enableClick() {
        list.clickEnabled = true;
        return this
      }

      /**
       * Your list with selected items
       * @param {Array} selectedItems
       * */
      selectedItems(selectedItems) {
        list.selectedItems = selectedItems;
        return this;
      }

      /**
       * The value
       * @param {string} value
       * */
       value(value) {
        list.value = value;
        return this;
      }
      /**
       * Part of concatinated value
       * @param {string} value
       * */
       value1(value) {
        list.value1 = value;
        return this;
       }
      /**
       * The value
       * @param {string} value
       * */
       value2(value) {
        list.value2 = value;
        return this;
      }
      /**
       * Icon to display
       * @param {string} icon
       * */
      icon(icon) {
        list.icon = icon;
        return this;
      }
      /**
       * The unique property of each item in the itemList
       * @param {string} uniqueIdentifier
       * */
      uniqueIdentifier(uniqueIdentifier) {
        list.uniqueIdentifier = uniqueIdentifier;
        return this;
      }
      /**
       * The unique property of each item in the itemList
       * */
      disableOrderBy() {
        list.orderByDisabled = true;
        return this;
      }
      /**
       * The property of each item in the itemList you want to display
       * @param {string} displayId
       * */
      displayId(displayId) {
        list.displayId = displayId;
        return this;
      }
      /**
       * Allows you to select items to delete
       * */
      enableAdd() {
        list.addEnabled = true;
        return this;
      }
      /**
       * Allows you to select items to delete
       * */
      enableDelete() {
        list.toggleDelete = new ToggleDelete(list);
        list.selectToDelete = ToggleDelete.toggleItemDeletion;
        list.isDeleting = false;
        return this;
      }
      /**
       * Allows you to select items to bulk edit
       * */
      enableBulkEdit() {
        list.toggleBulkEdit = new ToggleBulkEdit(list);
        list.selectToBulkEdit = ToggleBulkEdit.toggleItemBulkEditing;
        list.isBulkEditing = false;
        return this;
      }
      /**
       * Allows you to change selected items
       * */
      enableReadOnly(canEdit) {
        if (canEdit) {
          list.toggleEdit = new ToggleEdit(list);
          list.isEditing = false;
        }
        return this;
      }
      /**
       * Allows you to change selected items
       * */
      enableDisabled(disabled) {
        list.isDisabled = disabled;
        return this;
      }
      /**
       * Allows you to search for items
       * */
      enableSearch() {
        list.toggleSearch = new ToggleSearch(list);
        list.isSearching = false;
        return this;
      }
      /**
       * Sets which items you want to be selected by default
       * @param {function} selectionStrategy
       * */
      selectionStrategy(selectionStrategy) {
        list.selectionStrategy = selectionStrategy;
        return this;
      }
      /**
       * Element to use to dispatch custom event
       * @param {HTML DOM Element Object} element
       * */
      customEventElement(element) {
        if (_selectionTypeSpecifiedBeforeElement(list.selectionType)) {
          list.element = element;
        }
        return this;
      }
      /**
       * Returns the created list entity
       * */
      build() {
        this.enableSearch();
        if (list.value) {
          return list;
        }

        if (_selectedItemsSpecified(list.selectedItems)) {
          list.selectSelectedItems();
        } else if (_selectionStrategySpecified(list.selectionStrategy)) {
          list.selectDefaultItems();
        }
        return list;
      }
    }
    return new Builder();
  }
}
/*
 * */
function _hasItems(items) {
  if (!items || items.length === 0) {
    return false;
  }
  return true;
}
/*
 * */
function _isRequiredSpecified(required) {
  if (!required) {
  }
}
/*
 * */
function _isCustomEventElementSpecified(element) {
  if (!element) {
    return false;
  }
  return true;
}
/*
 * */
function _selectionStrategySpecified(strategy) {
  if (!strategy) {
    return false;
  }
  return true;
}
/*
 * */
function _selectionTypeSpecified(type) {
  if (!type) {
    return false;
  }
  return true;
}
/*
 * */
function _selectionTypeSpecifiedBeforeElement(type) {
  if (type) {
    return false;
  }
  return true;
}
/*
 * */
function _selectedItemsSpecified(items) {
  if (!items || !Array.isArray(items)) {
    return false;
  }
  return true;
}
/*
 * */
function _selectArrayItems(defaultItemSelection) {
  return Array.isArray(defaultItemSelection);
}
/*
 * */
function _selectFirstItem(items, displayId, element) {
  if (items && items[0] && displayId) {
    items[0].isSelected = true;
    new Event(element, 'select', { list: items, item: items[0] });
    return items[0];
  }
  return false;
}