import {
  autoinject,
  LogManager,
  customElement,
  bindable,
} from 'aurelia-framework';
import { observable } from 'aurelia-binding';
import { v4 as uuidv4 } from 'uuid';
/**/
@customElement('z-autoclose-dropdown')
@autoinject()
export class ZAutocloseDropdown {
  @bindable @observable private display: boolean = false;
  @bindable({ attribute: 'hide-delay' }) private hideDelay: number = 500;
  @bindable({ attribute: 'autoclose-delay' })
  private autoCloseDelay: number = 5000;
  private autoCloseTimer: number = 0;
  private uniqueIdentifier: string;

  private static allDropdowns: ZAutocloseDropdown[] = [];

  constructor() {
    this.uniqueIdentifier = uuidv4();
  }

  public attached(): void {
    // Push the instance into the static array
    ZAutocloseDropdown.allDropdowns.push(this);
  }

  /* Static method - try do this in ES6 */

  private static closeAllExcept(_except: string): void {
    ZAutocloseDropdown.allDropdowns.forEach((_instance) => {
      if (_instance.uniqueIdentifier !== _except) {
        _instance.closeMenu();
      }
    });
  }

  private displayChanged(): void {
    if (this.display) {
      ZAutocloseDropdown.closeAllExcept(this.uniqueIdentifier);
      this.startAutoCloseMenu(this.autoCloseDelay);
    }
  }

  private startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = window.setTimeout(() => this.closeMenu(), _delay);
  }

  private closeMenu(): void {
    this.display = false;
    this.autoCloseTimer = 0;
  }

  private preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  private allowAutoClose(): void {
    this.startAutoCloseMenu(this.hideDelay);
  }
}

interface ISubscription {
  dispose: Function;
}
