import { BindingSignaler } from 'aurelia-templating-resources';
import { DataGrid, DataGridEvents, DataRefresher, DataGridSetting } from './../../../../../components/templates/data-grid/data-grid';
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Aurelia, bindable, computedFrom, customElement, LogManager } from "aurelia-framework";

import { SessionStore, ArrayTools, SortTools } from 'zailab.common';
import { LiveDashboardService } from "../live-dashboard-service";
import { LiveDashboardModel } from "../models/live-dashboard-model";
import { LiveDashboardState } from './live-dashboard-state';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
// @ts-ignore
import moment from 'moment';
import { ColourConfig } from './colour-config';

interface MinimalViableLiveDashboardConfig {
  title: string;
  settings: {
    value: number,
    colour: string,
  }[];
  visible?: boolean;
}
interface IStateStorage {
  requestTimer: number,
  liveDashboard: {
    requestTimer: number,
    grid: {
      columnHeaders: Array<string>,
      channels: IStateChannels,
      services: Array<string>
    }
  }
};

const logger = LogManager.getLogger('LiveDashboardOverview');

interface IStateChannels {
  call: boolean;
  callback: boolean;
  chat: boolean;
  email: boolean;
  im: boolean;
  sms: boolean;
  ticket: boolean;
};

const GLOBAL_SLA_RULES = [{ value: 0, colour: 'var(--unsuccessful-alt-inverse)' }, { value: 85, colour: '' }];
const DEFAULT_CONFIG: MinimalViableLiveDashboardConfig[] = [
  { title: 'Service', settings: [], visible: true },
  { title: 'Worktype', settings: [], visible: true },
  { title: 'BP', settings: [], visible: true },
  { title: 'Out SLA', settings: [], visible: true },
  { title: 'In SLA', settings: [], visible: true },
  { title: 'Tot Rec', settings: [], visible: true },

  { title: 'HDL Calls', settings: [], visible: true },
  { title: 'HDL Chats', settings: [], visible: true },
  { title: 'HDL Emails', settings: [], visible: true },
  { title: 'HDL IMs', settings: [], visible: true },
  { title: 'HDL SMS', settings: [], visible: true },
  { title: 'HDL Tickets', settings: [], visible: true },

  { title: 'ABND', settings: [], visible: true },
  { title: 'MIS', settings: [], visible: true },

  { title: 'CB', settings: [], visible: true },
  { title: 'FW', settings: [], visible: true },
  { title: 'VM', settings: [], visible: true },
  { title: 'AWT', settings: [], visible: true },
  { title: 'AWT 30 min', settings: [], visible: true },
  { title: 'AHT', settings: [], visible: true },

  { title: 'Consults', settings: [], visible: true },
  { title: 'Transfers', settings: [], visible: true },
  { title: 'Cleared', settings: [], visible: true },

  { title: 'N R', settings: [{ value: 0, colour: '' }, { value: 1, colour: 'var(--unsuccessful-alt-inverse)' }] },
  
  { title: 'W', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }], visible: true },
  { title: 'W Calls', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'W Chats', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'W Emails', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'W IMs', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'W SMS', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'W Tickets', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 11, colour: 'var(--pending)' }, { value: 20, colour: 'var(--unsuccessful-alt-inverse)' }] },

  { title: 'Tot In SLA 30 min', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'Tot Calls In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Chats In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Emails In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot IMs In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot SMS In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Tickets In SLA 30 min', settings: GLOBAL_SLA_RULES },
  
  { title: 'Tot Out SLA 30 min', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'Tot Calls Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Chats Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Emails Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot IMs Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot SMS Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Tickets Out SLA 30 min', settings: GLOBAL_SLA_RULES },

  { title: 'HDL In SLA 30 min', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'HDL Calls In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Chats In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Emails In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL IMs In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL SMS In SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Tickets In SLA 30 min', settings: GLOBAL_SLA_RULES },
  
  { title: 'HDL Out SLA 30 min', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'HDL Calls Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Chats Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Emails Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL IMs Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL SMS Out SLA 30 min', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Tickets Out SLA 30 min', settings: GLOBAL_SLA_RULES },

  { title: 'Tot In SLA (day)', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'Tot Calls In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Chats In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Emails In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot IMs In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot SMS In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Tickets In SLA (day)', settings: GLOBAL_SLA_RULES },
  
  { title: 'Tot Out SLA (day)', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'Tot Calls Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Chats Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Emails Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot IM Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot SMS Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'Tot Tickets Out SLA (day)', settings: GLOBAL_SLA_RULES },

  { title: 'HDL In SLA (day)', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'HDL Calls In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Chats In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Emails In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL IMs In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL SMS In SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Tickets In SLA (day)', settings: GLOBAL_SLA_RULES },
  
  { title: 'HDL Out SLA (day)', settings: GLOBAL_SLA_RULES, visible: true },
  { title: 'HDL Calls Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Chats Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Emails Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL IMs Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL SMS Out SLA (day)', settings: GLOBAL_SLA_RULES },
  { title: 'HDL Tickets Out SLA (day)', settings: GLOBAL_SLA_RULES },
  
  { title: 'L W', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'var(--unsuccessful-alt-inverse)' }], visible: true },
  { title: 'L W Call', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'L W Chat', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'L W Email', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'L W IM', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'revar(--unsuccessful-alt-inverse)d' }] },
  { title: 'L W SMS', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'var(--unsuccessful-alt-inverse)' }] },
  { title: 'L W Ticket', settings: [{ value: 0, colour: 'var(--successful-alt-inverse)' }, { value: 60, colour: 'var(--pending)' }, { value: 180, colour: 'var(--unsuccessful-alt-inverse)' }] },
];

@autoinject
export class LiveDashboardOverview {

  @bindable public selectedService: { serviceId: string; serviceName: string; };
  @bindable public selectedWorkType: { id: string, name: string, isSelected: boolean };
  @bindable public workTypesList: [{ id: string, name: string }];
  @bindable public services: { serviceId: string, serviceName: string, isAdded?: boolean }[];

  private liveDashboardState: LiveDashboardState = new LiveDashboardState();
  private mappings: any;
  
  private refreshIntervalHolder: any;
  public cdDataGrid: DataGrid;
  public refresher: DataRefresher = new DataRefresher();
  public groupedSettings = {
    groupedColumns: ["Service"],
    captionFormat: '<span style="display:flex!important;border-bottom: 1px solid rgba(var(--theme-border-primary-accent-shade-35-rgb),.8); " class="sticky flex--align-center">{{:key}} - {{:count}} {{if count == 1 }} worktypes {{else}} worktypes {{/if}}</span>'
  }

  public refreshSubscription: Subscription;
  public overviewData: LiveDashboardModel[] = [];
  public showNoResults: boolean = false;
  public showRefreshing: boolean = false;
  public doneLoading: number = 0;
  public channelSelection: IStateChannels = {
    call: true,
    callback: false,
    chat: false,
    email: false,
    im: false,
    sms: false,
    ticket: false
  };
  public pageable: any = {
    refresh: false,
    pageSizes: false,
    buttonCount: 10
  };
  private overviewGridDataSource: any;
  public serviceDropdownVisible: boolean = false;
  public serviceToAdd: { serviceId: string; serviceName: string; };
  public isAdding: boolean = false;
  public columns: Array<string> = null;
  public headerReordering = false;
  public gridEnabled: boolean = false;
  public enableSelectors: boolean = false;
  private retrievingData = false;

  private oldErrorHandler: any;
  private refreshing: boolean = false;
  
  private stateStorageKey = 'zai_state';
  private defaultRequestTimer = 30;
  private currentRequestTimer;
  public timerDropdownVisible = false;
  public lastRequestTimestamp: number;
  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }, { value: 120 }
  ];
  private signallerInterval: number;
  private servicesData: {[key: string]: any} = {};
  public scrollTop: number = 0;
  public scrollLeft: number = 0;

  constructor(
    private eventAggregator: EventAggregator,
    private liveDashboardService: LiveDashboardService,
    private readonly dataGridEvents: DataGridEvents,
    private readonly bindingSignaler: BindingSignaler,
    private router: Router,
    private sessionStore: SessionStore,
  ) {}

  public async activate(): Promise<void> {
    this.retrieveServices();
  }

  private saveScrollPosition(): void {
    const el = document.querySelector('.e-proportional-content.u-scroll-bar');
    if (el) {
      this.scrollTop = el.scrollTop;
      this.scrollLeft = el.scrollLeft;
    }
  }

  private scrollPositionCheck: number = 0;
  private loadScrollPosition(): void {

    if (this.scrollPositionCheck >= 20) {
      this.scrollPositionCheck = 0;
      return;
    }

    let el = document.querySelector('#js-scrollContainer');
    if (el) {
      el.scrollTop = this.scrollTop;
      el.scrollLeft = this.scrollLeft;

      if (
        this.scrollTop !== el.scrollTop ||
        this.scrollLeft !== el.scrollLeft
      ) {
        this.scrollPositionCheck++;
        setTimeout(() => {
          this.loadScrollPosition();
        }, 1000 / 3);
      }
    }
  }

  public resizeComplete(event: any): void {
    let changedColumn = this.cdDataGrid.columnSettings.find(col => col.title === event.column.field);
    changedColumn.width = event.newWidth;
    this.save();
  }

  public actionComplete(event: any): void {
    if (event.requestType === 'reorder') {
      this.save();
    } else if (event.requestType === 'refresh') {
      this.removeDragEvents();
      this.addGroupButtons();
      this.loadScrollPosition();
    }
  }

  private removeDragEvents(): void {
    
    let nameColumns: any = document.querySelectorAll('.e-columnheader > .e-headercell');
    this.removeDragEventHandlers(nameColumns[0]);
    this.removeDragEventHandlers(nameColumns[1]);
  }

  private removeDragEventHandlers(nameColumn: HTMLElement): void {
    nameColumn.classList.remove('e-draggable');
    nameColumn.classList.remove('e-droppable');

    // the following disabled drag and drop for the name column
    let keys = Object.keys(nameColumn);
    keys.forEach(key => {
      if (key && nameColumn[key] && key.indexOf('jQuery') >= 0) {
        if (nameColumn[key].ejWidgets) {
          delete nameColumn[key].ejWidgets;
        }
        if (nameColumn[key].events) {
          delete nameColumn[key].events.mousedown;
        }
        if (nameColumn[key].ejDraggable) {
          delete nameColumn[key].ejDraggable;
        }
        if (nameColumn[key].ejDroppable) {
          delete nameColumn[key].ejDroppable;
        }
      }
    });
  }


  private async retrieveServices(): Promise<void> {
    const memberId = this.sessionStore.get.user.memberId;
    let response: any = await this.liveDashboardService.retrieveMyServices(memberId);
    this.services = response.sort(SortTools.compareBy('serviceName'));

    this.init();
  }

  private getSelectedServices(): void {
    this.saveScrollPosition();
    const state = this.getStateFromStorage();
    if (state && state.liveDashboard) {
      this.currentRequestTimer = state.liveDashboard.requestTimer || this.defaultRequestTimer;
      const grid = state.liveDashboard.grid;

      if (grid) {
        if (grid.channels) {
          this.channelSelection = grid.channels;
        }
        if (grid.columnHeaders) {
          this.columns = grid.columnHeaders;
        }
        if (grid.services) {
          if (grid.services.length === 0) {
            this.refresh();
            return;
          }
          grid.services.forEach(serviceName => {
            let service = this.services.find(service => service.serviceName === serviceName);
            if (service) {
              this.addService(service);
            }
          });
        }
      } else {
        this.setReadyState();
      }
    } else {
      this.setReadyState();
    }
  }

  private async init(): Promise<void> {
    this.dataGridEvents.onDragStart(() => {
      this.refresher.enableSelectors = false;
      this.refresher.paused = true;
    });
    this.dataGridEvents.onDragEnd(() => {
      this.refresher.enableSelectors = true;
      this.refresher.paused = false;
    });

    const colorScheme: any = await this.retrieveColorConfig();
    
    ColourConfig.setColourScheme(ColourConfig.VIEWS.LIVE_DASHBOARD_OVERVIEW, colorScheme || DEFAULT_CONFIG);

    this.mappings = await this.liveDashboardState.getMappings(colorScheme || DEFAULT_CONFIG);

    this.cdDataGrid = new DataGrid(this.mappings);
    this.refresher.currentRequestTimer = this.liveDashboardState.refreshTime;
    this.getSelectedServices();
    this.setupSignaller();
  }
  private async retrieveColorConfig(): Promise<void> {
    try {
      let config: any = await this.liveDashboardService.retrieveMyDashboardColourConfig('LIVE_DASHBOARD');
      if (config) {
        config.forEach(item => {
          item.settings = item.settings;
        });
      } else {
        config = DEFAULT_CONFIG;
        this.liveDashboardService.updateMyDashboardColourConfig(config, 'LIVE_DASHBOARD');
      }
      return config;
    } catch(e) {
      logger.warn('Failed to retrieve live dashboard color config ', e);
    }
  }


  private refresh(): void {
    this.setupSignaller();
    // @ts-ignore
    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.refresher.enableSelectors = false;
    this.refresher.paused = true;
    let services = this.liveDashboardState.services;
    const taskTemplateId: string = this.selectedWorkType ? this.selectedWorkType.id : null;

    if (services && services.length > 0) {
      services.forEach(service => {
        this.retrieveData({ serviceId: service.serviceId }, taskTemplateId);
      });
    } else {
      this.setReadyState();
    }
  }

  private setReadyState(): void {
    this.refresher.enableSelectors = true;
    this.refresher.paused = false;
    this.cdDataGrid.ready = true;
    this.enableSelectors = true;
    this.gridEnabled = true;
    this.refresher.reset();
  }

  public deactivate(): void {
    this.liveDashboardState.clearTimers();
    clearInterval(this.refreshIntervalHolder);
    clearInterval(this.signallerInterval);
  }

  public refreshDashboard(): void {
    this.cdDataGrid = new DataGrid(this.mappings);
    this.getSelectedServices();
  }

  private retryCounter: number = 0;
  public addGroupButtons = (): void => {
    let groupCaptions: NodeListOf<Element> = document.querySelectorAll('.e-groupcaption');
    if ((!groupCaptions || groupCaptions.length === 0) && this.retryCounter <= 15) {
      setTimeout(() => {
        this.retryCounter += 1;
        this.addGroupButtons();
      }, 200);
      return;
    }
    this.retryCounter = 0;

    const columns = this.mappings.filter((col: any) => {
      if (col.field.visible) {
        let match = this.cdDataGrid.mappings.find((mapping: any) => col.title === mapping.title);
        col.field.value = match ? match.field.value : '--';
        return true;
      }
      return false;
    }).sort((a, b) => a.field.orderIndex - b.field.orderIndex);

    for(let i = 0; i < groupCaptions.length; i++) {
      let groupWrapper: any = groupCaptions[i];
      let hasButton = groupWrapper.querySelector('.remove-button');
      if (hasButton) {
        continue;
      }

      // @ts-ignore
      const columnTitle = groupWrapper.firstChild.querySelector('span').innerText;
      let button = document.createElement('button');
      button.className = 'remove-button';
      button.textContent = 'Remove';
      button.onclick = () => this.removeService(columnTitle);
      // @ts-ignore
      groupWrapper.firstChild.firstChild.title = columnTitle;
      groupWrapper.firstChild.insertBefore(button, groupWrapper.firstChild.firstChild);

      groupWrapper.id = 'js-' + columnTitle;
      groupWrapper.className = 'e-rowcell sticky flex flex--align-center';
      groupWrapper.setAttribute('colspan', '2');
      groupWrapper.style['border-right'] = '1px solid rgba(var(--primary-accent--shade-35-rgb),var(--theme-background-opac-8))';
      groupWrapper.style['background-color'] = 'var(--theme-background-default)';
      groupWrapper.style['z-index'] = 10;

      columns.forEach(column => {
        this.generateGroupCells(groupWrapper.parentElement, column, this.servicesData[columnTitle]);
      })
    }
  }
  private generateGroupCells(rowElement: HTMLElement, column: DataGridSetting, service: any): void {
    if (column.title === 'Service' || column.title === 'Worktype') {
      return;
    }
    logger.info()
    let newCell = document.createElement('td');
    let cellContent = document.createElement('span');

    newCell.className = 'e-rowcell';
    newCell.style['border-bottom'] = '1px solid rgba(var(--primary-accent--shade-35-rgb),var(--theme-border-opac-8))';
    newCell.style['text-align'] = 'right';

      // @ts-ignore
    const value = column.field.value;

    if (typeof value === 'function') {
      // @ts-ignore
      cellContent.innerHTML = value(service);
    } else {
      cellContent.innerHTML = value;
    }
    newCell.appendChild(cellContent);
    rowElement.appendChild(newCell);
  }

  private getStateFromStorage(): IStateStorage {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);
      return parsedState;
    } catch(e) {
      return null;
    }
  }

  public saveServicesState(): void {
    let services = [];
    this.services.forEach(service => {
      if (service.isAdded) {
        services.push(service.serviceName);
      }
    });
    this.saveStateToStorage(null, null, services);
  }

  private saveStateToStorage(columnHeaders: Array<string>, channels: IStateChannels, services?: Array<string>): void {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);

      if (!parsedState.liveDashboard) {
        parsedState.liveDashboard = {
          grid: {}
        };
      }
      parsedState.liveDashboard.grid.columnHeaders = columnHeaders || parsedState.liveDashboard.grid.columnHeaders;
      parsedState.liveDashboard.grid.channels = channels || parsedState.liveDashboard.grid.channels;
      parsedState.liveDashboard.grid.services = services || parsedState.liveDashboard.grid.services;

      localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
    } catch(e) {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({
        liveDashboard: {
          grid: {
            columnHeaders,
            channels,
            services
          }
        }
      }));
    }
  }

  public selectedServiceChanged(): void {
    if (this.services && Array.isArray(this.services)) {
      this.services = this.services.map(service => {
        service.isAdded = false;
        return service;
      });
    }
  }

  public navigateToLiveDashboard(): void {
    this.router.navigateToRoute('livedashboard');
  }
  
  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: number): void {
    this.currentRequestTimer = value;
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        parsedState.liveDashboard.requestTimer = value;
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ liveDashboard: { requestTimer: value } }));
    }
    this.timerDropdownVisible = false;
    this.refreshDashboard();
  }

  public showServiceDropdown(): void {
    this.serviceDropdownVisible = !this.serviceDropdownVisible;
  }

  public selectServiceToAdd(service: { serviceId: string; serviceName: string; isAdded?: boolean }): void {
    if (service.isAdded) {
      return;
    }
    this.serviceToAdd = service;
    this.serviceDropdownVisible = false;
  }

  public groupHeaderTemplate(serviceName: string): string {
    return `Service: ${serviceName} &nbsp;&nbsp;&nbsp;<button style="float:right;" click.trigger="removeService('${serviceName}')">Remove</button>`;
  }

  public headerTemplate(serviceName: string): string {
    const value = this.headerReordering ? '-' : serviceName;
    return `<span title="${value}" class="${this.headerReordering ? 'u-faded-content' : ''}">${value}</span>`;
  }

  public removeService(serviceName: string): void {
    this.services = this.services.map(service => {
      if (service.serviceName === serviceName) {
        service.isAdded = false;
        this.liveDashboardState.removeService(service.serviceId);
      }
      return service;
    });
    this.saveServicesState();
    this.cdDataGrid = new DataGrid(this.mappings);
    this.getSelectedServices();
  }

  public addService(_service?: any): void {
    if (!this.serviceToAdd && !_service) {
      return;
    }
    this.isAdding = true;
    const serviceToAdd = _service || this.serviceToAdd;
    const taskTemplateId: string = this.selectedWorkType ? this.selectedWorkType.id : null;
    
    let service = this.services.find(service => service.serviceId === serviceToAdd.serviceId);
    if (service) {
      service.isAdded = true;
    }
    this.saveServicesState();

    this.retrieveData(serviceToAdd, taskTemplateId);
  }

  private retrieveData(service: { serviceId: string }, taskTemplateId: string): void {
    this.retrievingData = true;
    this.setupSignaller();
    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.liveDashboardService
      .retrieveNewLiveDashboard(service.serviceId, taskTemplateId, true)
      .then((response: {
        service: { id: string, name: string }
        worktypeDashboards: any[]
      }) => {
        this.retrievingData = false;
        this.handleServicesRetrieved(response, service);
      })
      .catch(e => {
        this.retrievingData = false;
        this.handleServicesRetrieved(null, service);
      });
  }

  private handleServicesRetrieved(response: {
    service: { id: string, name?: string, serviceName?: string }
    worktypeDashboards: any[]
  }, service?: any): void {

    if (!response) {
      if (service) {
        response = {
          service,
          worktypeDashboards: [{ serviceName: service.serviceName, name: '--', noData: true }]
        };
      }
    }
    if (!response) {
      this.enableSelectors = true;
      this.gridEnabled = true;
      return;
    }
    
    this.servicesData[response.service.name || response.service.serviceName] = { ...response};

    const workTypes = ArrayTools.sort(response.worktypeDashboards, 'name');
    workTypes.forEach(tt => {
      this.cdDataGrid.addData(tt, this.cdDataGrid.dataSource);
    });

    this.setReadyState();

    this.serviceToAdd = null;
    this.showRefreshing = false;
    this.setupSignaller();
    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.isAdding = false;
  }

  public sortable = {
    compare: (a: any, b: any, desc: any): number =>  {
      if (a.taskTemplateName === 'All Work Types') {
        return 0;
      } else if (b.taskTemplateName === 'All Work Types') {
        return 0;
      } else if (a.taskTemplateName === b.taskTemplateName) {
        return 0;
      } else if (a.taskTemplateName === null) {
        return desc ? -1 : 1;
      } else if (b.taskTemplateName === null) {
        return desc ? 1 : -1;
      } else if (desc) {
        return -1;
      } else {
        return 1;
      }
    }
  }

  public settings(): void {
    this.router.navigateToRoute('livedashboardsettings');
  }

  public save(): void {
    this.refresher.enableSelectors = false;
    this.cdDataGrid.getColumnOrderAndWidths()
      .then((columnOrderAndWidths) => {
        this.liveDashboardState.saveSettings(columnOrderAndWidths);
        this.refresher.enableSelectors = true;
      });
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    if (this.cdDataGrid.dataSource.length === 0) {
      return;
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        if (this.retrievingData) {
          return;
        }
        this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
        this.refreshDashboard();
      }
    }, signal);
  }

  public navigateBack(): void {
    this.router.navigateBack();
  }

  get currentTimeStamp(): Date {
    return new Date;
  }

  @computedFrom('channelSelection.call', 'channelSelection.callback', 'channelSelection.chat', 'channelSelection.email', 'channelSelection.im', 'channelSelection.sms', 'channelSelection.ticket')
  get showCallSeparator(): boolean {
    if (this.channelSelection.call && (this.channelSelection.callback || this.channelSelection.chat || this.channelSelection.email || this.channelSelection.im || this.channelSelection.sms) || this.channelSelection.ticket) {
      return true;
    }
    return false;
  }

  @computedFrom('channelSelection.callback', 'channelSelection.chat', 'channelSelection.email', 'channelSelection.im', 'channelSelection.sms', 'channelSelection.ticket')
  get showCallbackSeparator(): boolean {
    if (this.channelSelection.callback && (this.channelSelection.chat || this.channelSelection.email || this.channelSelection.im || this.channelSelection.sms || this.channelSelection.ticket)) {
      return true;
    }
    return false;
  }

  @computedFrom('channelSelection.chat', 'channelSelection.email', 'channelSelection.im', 'channelSelection.sms', 'channelSelection.ticket')
  get showChatSeparator(): boolean {
    if (this.channelSelection.chat && (this.channelSelection.email || this.channelSelection.im || this.channelSelection.sms || this.channelSelection.ticket)) {
      return true;
    }
    return false;
  }

  @computedFrom('channelSelection.email', 'channelSelection.im', 'channelSelection.sms', 'channelSelection.ticket')
  get showEmailSeparator(): boolean {
    if (this.channelSelection.email && (this.channelSelection.im || this.channelSelection.sms || this.channelSelection.ticket)) {
      return true;
    }
    return false;
  }

  @computedFrom('channelSelection.im', 'channelSelection.sms')
  get showImSeparator(): boolean {
    if (this.channelSelection.im && this.channelSelection.sms) {
      return true;
    }
    return false;
  }

  @computedFrom('channelSelection.sms')
  get showSMSSeparator(): boolean {
    if (this.channelSelection.sms && this.channelSelection.ticket) {
      return true;
    }
    return false;
  }

  @computedFrom('channelSelection.ticket')
  get showTicketSeparator(): boolean {
    if (this.channelSelection.ticket) {
      return true;
    }
    return false;
  }
}
