import {autoinject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

const logger = LogManager.getLogger('TabVisibilityHandler');

@autoinject()
export class TabVisibilityHandler {

  constructor(private eventAggregator: EventAggregator) {
    document.addEventListener('visibilitychange', event => {
      let isVisible = !document.hidden;
      this.eventAggregator.publish('document.visibility.changed', isVisible);
    });
  }
}