import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
  ValidationControllerFactory,
  ValidationController,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';
/*
 * */
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { PasswordService } from '../password-service';
/*
 * */
const logger = LogManager.getLogger('ForgotPassword');
/*
 * */
@inject(Router, PasswordService, ValidationControllerFactory, SessionStore)
export class ForgotPassword {
  email = '';
  error;
  sessionStore;

  constructor(
    router,
    passwordService,
    validationControllerFactory,
    sessionStore
  ) {
    this.router = router;
    this.passwordService = passwordService;
    this.sessionStore = sessionStore;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate() {
    ValidationRules.ensure('email')
      .required()
      .withMessage('Please enter your email.')
      .email()
      .withMessage('Please enter a valid email.')
      .on(this);
  }

  login() {
    this.router.parent.navigate('login');
  }

  submit() {
    this.submitted = true;

    this.validation
      .validate()
      .then((validation) => {
        if (!validation.valid) {
          this.submitted = false;
          return;
        }
        this.passwordService
          .requestPasswordReset(this.email)
          .then(() => {
            this.router.navigate('emailsubmitted');
            this.submitted = false;
          })
          .catch((error) => {
            this.submitted = false;
            console.warn(' > Failed to reset password due to', error);
          });
      })
      .catch((error) => {
        this.submitted = false;
      });
  }
}
