import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { AbstractList, List } from 'zailab.abstract';
import { SessionStore } from 'zailab.common';
import { InfiniteScrollService } from '../../../../../_common/services/infinitescroll-service';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { WorkTypesService } from '../work-types-service';

import { DialogService } from 'aurelia-dialog';
import { AssignedFlowsDialog } from '../assigned-flows-dialog/assigned-flows-dialog-dialog';
import { WorkTypesSearchFilter } from '../filter/work-types-search-filter';
import { WorkTypeVersionDialog } from '../work-type-versions/work-type-versions-dialog';

const logger = LogManager.getLogger('WorkTypesListView');

@autoinject
export class WorkTypesListView extends AbstractList {

  private page: number = 0;
  private defaultHeader: string = 'Work Types';
  private infiniteScroll: InfiniteScrollService;

  public auditTrail: any[] = [];
  public header = this.defaultHeader;
  public tableContainer: Element;
  public loadingList: boolean = false;
  
  private actions = [{
    action: 'view',
    label: 'view'
  }, {
    action: 'viewassignedflows',
    label: 'assigned flows',
    icon: 'copy'
  }];
  private versionAction = {
    action: 'worktypeversions',
    label: 'work type versions',
    icon: 'copy'
  }; 

  constructor(
    public element:Element,
    private router:Router,
    protected eventAggregator:EventAggregator,
    private sessionStore:SessionStore,
    private workTypesService:WorkTypesService,
    private dialogService: DialogService,
    private workTypesSearchFilter: WorkTypesSearchFilter,
  ) {
    super(eventAggregator);
  }

  protected activate(params: { _param: string }): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    this.workTypesSearchFilter.closeFilter();
    super.detached();
  }

  protected subscribeToOplog(): void {
    this.workTypesService.initialiseListOplog(this.sessionStore.get.organisation.organisationId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('update', response => {
        let workType = this.workTypesService._taskTemplateListFactory([response])[0];
        for (let item of this.itemList.items) {
          if (item.templateId === workType.templateId) {
            item.mapProperties(workType);
          }
        }
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();

    this.workTypesService.retrieveWorkTypes()
      .then(taskTemplateList => {
        this.setupListConfig(taskTemplateList);
        taskTemplateList.forEach(taskTemplate => {
          taskTemplate.actions = [...this.actions];
          if (taskTemplate.channel !== 'Chat') {
            taskTemplate.actions.push(this.versionAction);
          }
        });
      }, error => {
        logger.info('retrieve worktypes > error = ', error);
      });
  }

  protected async setupListConfig(_workTypes: any[]): Promise<void> {

    this.loadingList = true;

    super.setupListConfig(_workTypes);
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .items(_workTypes)
      .icon('icon')
      .uniqueIdentifier('templateId')
      .displayId('templateName')
      .description('flowName')
      .descriptionTwo('descriptionTwo')
      .withProportion('medium')
      .tilesPerRow(6)
      .build();

    this.workTypesSearchFilter.onConfirmed(() => {
      this.loadingList = true;
      this.itemList.items = this.workTypesSearchFilter.filter();
      this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 5, (placeholders) => {
        this.placeholders = placeholders;
      });
      this.loadingList = false;
    });
    await this.workTypesSearchFilter.setAllWorkTypeList(this.itemList.items);
      
    this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });

    this.loadingList = false;

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected selectItem(_items:any): void {
    super.selectItem(_items);
    let templateId = _items.item.templateId || _items.item._id;
    this.router.parent.navigate(`worktype/${templateId}`);
  }

  protected addItem(): void {
    this.router.navigate('create');
  }

  protected deleteItems(items: any[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} work types deleted.` : `Work type <b>${items[0].templateName}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(workType: any):void {
    super.delete(workType);
    let id = workType.templateId ? workType.templateId : workType._id;
    this.workTypesService.removeWorkType(workType);
    this.itemList.items = this.itemList.items.filter(_worktype => {
      return _worktype.id !== id && _worktype.templateId !== id;
    });
  }

  public select(item: any): void {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.itemList.select && !this.itemList.isDeleting) {
      if (this.itemList.autoSelect) {
        this.itemList.select(item, this.itemList.items, this.itemList.required, this.element);
      }
      this.selectItem({ data: this.itemList.items, item: item });
      return;
    }
    if (this.itemList.isDeleting && item.canDelete !== false) {
      this.itemList.selectToDelete(item);
    } else {
      // TODO: Add in a toastr
      logger.warn('Cannot select item >>>', item);
    }
  }

  public removeItems(): void {

    let itemsToDelete = [];

    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      }
    }

    if (itemsToDelete.length) {
      this.deleteItems(itemsToDelete);
    }
    this.itemList.isDeleting = false;
  }

  public toggleDelete(): void {
    this.itemList.toggleDelete();
  }

  public viewAudit(): void {
    this.router.navigate('audit');
  }

  public viewColorSettings(): void {
    this.router.navigate('worktypes-color-settings');
  }

  public viewWorkTypeVersions(workType: any): void {
    this.dialogService.open({
      viewModel: WorkTypeVersionDialog,
      model: workType
    }).whenClosed((response: any) => {
      if (!response.wasCancelled) {}
      if (response.output && response.output.version) {
        this.router.parent.navigate(`worktype/${workType.templateId}?version=` + response.output.version);
      }
    });
  }

  public viewAssignedFlows(workType: any): void {
    this.dialogService.open({
      viewModel: AssignedFlowsDialog,
      model: workType.interactionFlows
    });
  }
}
