
import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController} from 'aurelia-dialog';

import { ConfigureAdditionalDataService } from './configure-additional-data-service';


import './configure-additional-data-dialog.scss';
import { IAdditionalData } from './tabs/tab-1-additional-data/tab-1-additional-data';
import { IInputCapture } from './tabs/tab-2-capture-input/tab-2-capture-input';

const controlTypeMap = {
  'Textbox': 'TEXTBOX',
  'Textarea': 'TEXTAREA',
  'Checkbox': 'CHECKBOX'
};

const reverseControlTypeMap = {
  'TEXTBOX': 'Textbox',
  'TEXTAREA': 'Textarea',
  'CHECKBOX': 'Checkbox'
};
const logger = LogManager.getLogger('ConfigureAdditionalDataDialog');

declare const PLATFORM;

@autoinject()
export class ConfigureAdditionalDataDialog {

  private organisationId: string;
  private interactionFlowId: string;
  public changeTab: (index: number) => void;

  public submitting: boolean;
  public tabs: any;
  public defaultTabs = [
    {
      id: '1',
      name: 'Additional Data',
      viewModel: PLATFORM.moduleName(
        'features/interaction/interactionFlow/configure-additional-data/tabs/tab-1-additional-data/tab-1-additional-data',
      ),
      icon: '',
      selected: true,
      data: [],
    },
    {
      id: '2',
      name: 'Input Capture',
      viewModel: PLATFORM.moduleName(
        'features/interaction/interactionFlow/configure-additional-data/tabs/tab-2-capture-input/tab-2-capture-input',
      ),
      icon: '',
      selected: false,
      data: [],
    },
  ];
  public tableTypes = [
    {
      type: 'text',
      validation: 'required',
    },
    {
      type: 'text',
      validation: 'required',
    },
  ];
  public ready: boolean = false;

  constructor(
    private dialogController: DialogController,
    private configureAdditionalDataService: ConfigureAdditionalDataService,
  ) {}

  public async activate(
    model: {
      interactionFlow: {
        interactionFlowId: string,
        additionalData: {
          displayVariables: {
            variableName: string,
            displayName: string
          }[],
          formVariables: {
            displayText: string,
            variableName: string,
            inputType: string
          }[]
        }
      },
      organisationId: string
    }): Promise<void> {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.organisationId = model.organisationId;

    if (!Array.isArray(model.interactionFlow.additionalData)) {
      this.defaultTabs[0].data = [...model.interactionFlow.additionalData.displayVariables];
    } else {
      this.defaultTabs[0].data = [];
    }
    
    if (!Array.isArray(model.interactionFlow.additionalData)) {
      const formVariables = model.interactionFlow.additionalData.formVariables;
      this.defaultTabs[1].data = formVariables.map(row => ({
        displayText: row.displayText,
        variableName: row.variableName,
        inputType: reverseControlTypeMap[row.inputType]
      }));
    } else {
      this.defaultTabs[1].data = [];
    }
  

    this.tabs = this.defaultTabs;
    this.ready = true;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public save(): void {
    let tab1Data: IAdditionalData[] = this.tabs[0].data;
    let tab2Data: IInputCapture[] = this.tabs[1].data;

    this.configureAdditionalDataService
      .setAdditionalData(
        this.interactionFlowId,
        this.organisationId,
        {
          displayVariables: tab1Data,
          formVariables: tab2Data.map(item => ({
            displayText: item.displayText,
            variableName: item.variableName,
            inputType: controlTypeMap[item.inputType]
          }))
        }
      );
    this.dialogController.ok();
  }
}
