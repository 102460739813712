import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('DigitModel');
/**/
export class DigitModel extends BaseModel {

  public value: string = null;
  public isSelected: boolean = null;
  public disableSelection: boolean = null;

  constructor(digit: object) {
    super();
    super.mapProperties(digit);
  }
}