import { DialogController } from 'aurelia-dialog';
import { inject, LogManager } from 'aurelia-framework';
import { WorkTypesService } from '../../../conversation/worktypes/work-types-service';
import OrganisationService from '../../organisation-service';
import { VerintAnalyticsService } from '../verint-analytics-service';
import { SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('VerintAnalyticsConfigurationDialog');

@inject(DialogController, WorkTypesService, OrganisationService, VerintAnalyticsService, SessionStore)
export class VerintTextAnalyticsWorktypeConfigurationDialog {

  workforceManagementId;
  dialogHeader = "Verint Analytics Text WorkType Configuration";
  allAvailableWorkTypes = [];
  selectedWorkTypes = {};
  allWorkTypesEnabled = true;
  config;

  constructor(dialogController, workTypesService, organisationService, verintAnalyticsService, sessionStore) {
    this.dialogController = dialogController;
    this.workTypesService = workTypesService;
    this.organisationService = organisationService;
    this.verintAnalyticsService = verintAnalyticsService;
    this.sessionStore = sessionStore;
  }

  activate(model) {
    this.workforceManagementId = model.data.workforceManagementId;
    this.isProcessing = true;
    this.verintAnalyticsService.findTextAnalyticsConfig().then((data) => {
      this.config = Object.assign({}, data);
      this.allWorkTypesEnabled = data.allWorkTypesEnabled;
      let previouslyEnabledWorkTypes = data.enabledWorkTypes;
      this.workTypesService.retrieveWorkTypes()
        .then((res) => {
          let filteredWorkTypes = res.filter(workType => workType.channel.indexOf('Chat') >= 0 && workType.flowType !== 'Office Flow');
          filteredWorkTypes.forEach(workType => {
            this.workTypesService.retrieveWorkType(workType.templateId).then((taskTemplate) => {
              this.allAvailableWorkTypes.push(taskTemplate);
              let taskTemplateId = taskTemplate.id;
              if (previouslyEnabledWorkTypes && previouslyEnabledWorkTypes.includes(taskTemplateId)) {
                this.selectedWorkTypes[taskTemplateId] = true;
              }
              this.isProcessing = false;
            },
            (error) => {
              logger.error('Unable to retrieve work type ' + workType.taskTemplateId, error);
              this.isProcessing = false;
            });
          });
        });
    });
  }

  toggleAllWorkTypesSelected() {
    this.allWorkTypesEnabled = !this.allWorkTypesEnabled;
  }
  
  toggleWorkType(taskTemplateId) {
    if (taskTemplateId in this.selectedWorkTypes) {
      this.selectedWorkTypes[taskTemplateId] = !this.selectedWorkTypes[taskTemplateId];
    } else {
      this.selectedWorkTypes[taskTemplateId] = true;
    }
  }

  cancel() {
    this.dialogController.cancel();
  }

  save() {
    this.isProcessing = true;
    if (this.allWorkTypesEnabled) {
      this.config.enabledWorkTypes = [];
      this.config.allWorkTypesEnabled = this.allWorkTypesEnabled;
    } else {
      this.config.enabledWorkTypes = this.filterWorkTypes(this.selectedWorkTypes);
      this.config.allWorkTypesEnabled = !this.config.enabledWorkTypes || !this.config.enabledWorkTypes.length ? true : false;
    }
    
    this.organisationService
      .setTextAnalytics(this.sessionStore.get.organisation.organisationId, this.workforceManagementId, this.config)
      .then(() => this.isProcessing = false);
  }

  filterWorkTypes(enabledWorkTypes) {
    if (!enabledWorkTypes) {
      return [];
    }
    let filteredEnabledWorkTypes = [];
    for (let key of Object.keys(enabledWorkTypes)) {
        if (enabledWorkTypes[key]) {
          filteredEnabledWorkTypes.push(key);
        }
    }
    return filteredEnabledWorkTypes;
  }
}

