import { autoinject, LogManager, Factory } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { HttpClient, HttpResponseMessage } from 'aurelia-http-client';

import { WebSocket } from '../../../../_common/services/websocket';
import { UserRegistrationSettings } from '../user-registration-settings';
import { COMPLETE_REGISTRATION_ACTIONS } from './complete-registration-actions';
import { WINDOW_EVENTS } from 'zailab.common';

const logger = LogManager.getLogger('CompleteRegistrationService');

@autoinject
export class CompleteRegistrationService {
  capslockOnPasswordDetection: Subscription;
  capslockOffPasswordDetection: Subscription;

  constructor(
    private webSocket: WebSocket,
    private eventAggregator: EventAggregator,
    private httpClient: HttpClient
  ) {}

  /**
   * Completes registration for the user
   * @returns Promise that resolves an email.
   * @fires CompleteUserRegistrationCommand
   * to 'command-service-registration'
   * @param {object} info registration information
   * */
  public completeUserRegistration(payload: any): Promise<any> {
    return this.submitRegistration(payload);
  }

  public subscribeToUserIdentityTrustedEvent(): Promise<any> {
    return this.subscribeToEvent(
      'com.zailab.user.userregistration.api.events.IdentityTrustedEvent'
    );
  }

  public subscribeToUserIdentityConfirmationRequiredEvent(): Promise<any> {
    return this.subscribeToEvent(
      'com.zailab.user.userregistration.api.events.IdentityConfirmationRequiredEvent'
    );
  }

  private subscribeToEvent(name: string): Promise<any> {
    return new Promise((resolve) => {
      this.webSocket.subscribe({
        name,
        callback: (data) => {
          this.webSocket.unSubscribe(name);
          resolve(data);
        },
      });
    });
  }

  public submitRegistrationFromInvite(payload: any): Promise<any> {
    return this.httpClient
      .createRequest(`v1/user/user-registrations/submit`)
      .asPost()
      .withContent(payload)
      .withHeader('Content-Type', 'application/json')
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }

  private submitRegistration(payload: any): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/user/user-registrations/${payload.userRegistrationId}/complete`
      )
      .asPost()
      .withContent(payload)
      .withHeader('Content-Type', 'application/json')
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send();
  }

  /**
   * Checks if normal registration completed else complete it
   * @param {object} userRegistrationId
   * @param {object} token
   * */
  public completeRegistration(
    userRegistrationId: string,
    token: string
  ): Promise<any> {
    return this._completeRegistration(userRegistrationId, token);
  }

  /**
   * Checks if registration by invitation completed else complete it
   * @param {object} userRegistrationId
   * */
  public completeRegistrationByInvitation(
    userRegistrationId: string
  ): Promise<any> {
    return this._completeRegistration(userRegistrationId);
  }

  /**
   * @private
   * */
  private _completeRegistration(
    userRegistrationId: string,
    token: string = ''
  ): Promise<UserRegistrationsCompletableResponse> {
    return this.httpClient
      .createRequest(
        `v1/user/user-registrations/${userRegistrationId}/completable`
      )
      .asGet()
      .withParams({ userRegistrationId, token })
      .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
      .send()
      .then((response: UserRegistrationsCompletableResponse) =>
        this._handleUserRegistrationsCompletableResponse(response)
      );
  }

  private _handleUserRegistrationsCompletableResponse(
    response: UserRegistrationsCompletableResponse
  ): Promise<UserRegistrationsCompletableResponse> {
    return new Promise((resolve, reject) => {
      if (UserRegistrationsCompletableResponse.isValid(response)) {
        resolve(response);
      } else {
        reject();
      }
    });
  }

  /**
   * Subscribes to capslock changes
   * @emits WINDOW_EVENTS.ON_CAPSLOCK_DETECTED
   * @emits WINDOW_EVENTS.OFF_CAPSLOCK_DETECTED
   * */
  public enableCapslockOnPasswordDetection(): void {
    this.capslockOnPasswordDetection = this.eventAggregator.subscribe(
      WINDOW_EVENTS.ON_CAPSLOCK_DETECTED,
      (data) => {
        // TODO this gets called for every key event - not safe
        this.eventAggregator.publish(
          COMPLETE_REGISTRATION_ACTIONS.CAPS_LOCK_ERROR,
          data.settings.message
        );
      }
    );
    this.capslockOffPasswordDetection = this.eventAggregator.subscribe(
      WINDOW_EVENTS.OFF_CAPSLOCK_DETECTED,
      (data) => {
        // TODO this gets called for every key event - not safe
        this.eventAggregator.publish(
          COMPLETE_REGISTRATION_ACTIONS.CAPS_LOCK_ERROR,
          null
        );
      }
    );
  }

  /**
   * Un-subscribes to capslock changes
   * */
  public disableCapslockOnPasswordDetection(): void {
    this.capslockOnPasswordDetection.dispose();
    this.capslockOffPasswordDetection.dispose();
  }
}

class UserRegistrationsCompletableResponse extends HttpResponseMessage {
  completable: boolean;

  static isValid(response: UserRegistrationsCompletableResponse): boolean {
    return Object.keys(response).length > 0 && response.completable === true;
  }
}
