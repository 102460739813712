// todo: flaap - temporary 

import {LogManager, computedFrom} from 'aurelia-framework';

const logger = LogManager.getLogger('CrmStore');

export class CrmStore {
  private _syncId: string;

  @computedFrom('_syncId')
  public get syncId(): string {
    return this._syncId;
  }
  public set syncId(_syncId: string) {
    this._syncId = _syncId;
  }

  public clearState(): void {
    this._syncId = null;
  }
}