import { bindable, customElement, LogManager } from "aurelia-framework";
import { LineModel } from "components/atoms/line/models/line-model";

const logger = LogManager.getLogger('ZGridNode');

@customElement('z-grid-node')
export class ZGridNode {

  @bindable
  public nodeName: string;

  @bindable
  public nodeHeight: string = '0';

  @bindable
  public displayContent: boolean = true;

  @bindable
  public defined: boolean;

  @bindable
  public coordinates: LineModel;

  private node: HTMLElement;

  public bind(): void {
    this.node.style.cursor = 'pointer';
  }
}