export class MillisecondsToTimeValueConverter {
  toView(ms, waitTime, customPlaceholder, maxHours) {

    if (ms === null) {
      return customPlaceholder;
    }

    if (ms !== 0 && !ms)
      return;

    if (ms && ms.toString().indexOf(':') > -1) // Check if time already formatted
      return ms;

    let x, days, hours, minutes, seconds;
    x = Math.floor(ms / 1000);
    seconds = Math.floor(x % 60);
    x /= 60;
    minutes = Math.floor(x % 60);
    x /= 60;
    hours = Math.floor(x % 24);
    x /= 24;
    days = Math.floor(x);

    // Handle maxHours condition
    if (maxHours !== undefined && maxHours !== null) {
      const totalHours = days * 24 + hours;
      if (totalHours >= maxHours) {
        return `${maxHours.toString().padStart(2, '0')}:00:00`;
      }
    }

    // Return formatted time
    if (waitTime) {
      return days > 0 ? `${days}d${hours}h${minutes}m${seconds}s` : `${hours}h${minutes}m${seconds}s`;
    } else {
      return days > 0 ? `${days}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  }
}
