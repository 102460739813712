import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { ArrayTools, SessionStore } from 'zailab.common';
import { PresenceService } from '../../../features/user/passport/presence/presence-service';
import { UserSessionModel } from '../../../_common/stores/sessionmodels/user-session-model';
import { SessionService } from '../../../_common/services/session-service';
import { HttpClient } from 'aurelia-http-client';
import { FeatureFlagService } from '../../../features/featureflags/feature-flag-service';
import { ApplicationProperties } from '../../../_config/application.properties';
import { AutoLogoutNotifierService } from '../../../features/user/passport/auto-logout-notification-service';
/* */
const logger = LogManager.getLogger('Hud Presence');

/* */
@autoinject()
export class HudPresence {

  private routingStatus: string;
  public selectedPresence: { presenceCodeName: string; color: string; };
  private presences: { presenceCodeName: string; color: string; }[] = [];
  private isPresenceOptionsOpened: boolean = false;
  private isPresenceOptionsAvailable: boolean = false;
  private isAgentRole: boolean = false;
  private userId: string;
  private oplog: any;
  private sharedLogOutEnabled: boolean;
  // private activity: string;

  constructor(
    private presenceService: PresenceService,
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private router: Router,
    private sessionService: SessionService,
    private featureFlagService: FeatureFlagService,
    private applicationProperties: ApplicationProperties,
    private autoLogoutNotifierService: AutoLogoutNotifierService
  ) { }

  activate(): void {
    this.enableLogOut();

    this.userId = this.sessionStore.get.user.userId;
    this.isAgentRole = this.sessionStore.get.user.hasAgentRole;

    // Subscribe to agent routing status updates
    this.eventAggregator.subscribe('current.agent.status', _routingStatus => {
      this.routingStatus = _routingStatus;
      if (_routingStatus === 'Ready') {
        this.isPresenceOptionsOpened = false;
        this.selectedPresence = {
          presenceCodeName: 'WORKING',
          color: null
        }
      } else {
        this.getSelectedPresence();
      }
    });

    this.presenceService.initialiseOplog(this.userId).then(_oplog => {
      this.oplog = _oplog;
      this.oplog.on('update', (_response) => this.handlePresenceChanged(_response));
      this.oplog.on('insert', (_response) => this.handlePresenceChanged(_response));
    }, _reject => {

    });

    this.retrievePresences();
  }

  private enableLogOut(): void {

    // this.eventAggregator.subscribe('member-activity:change', data => {
    //   this.activity = data;
    // });

    this.featureFlagService.isEnabled('autoLogOut')
      .then((autoLogOutEnabled) => {

        if (!autoLogOutEnabled) { return; }

        this.sessionService.startAutoLogoutTimer(() => {
          this.router.parent.navigate('logout');
        });

        this.eventAggregator.subscribe('member-activity:change', (event) => {
          if (!event || event.toLowerCase() === 'waiting') {
            this.sessionService.resumeAutoLogoutTimer();
          } else {
            this.sessionService.pauseAutoLogoutTimer();
          }
        });
      })
      .catch((e) => logger.error('autoLogOut :: e=', e));

    this.featureFlagService.isEnabled('sharedLogOut')
      .then((sharedLogOutEnabled) => {

        this.sharedLogOutEnabled = sharedLogOutEnabled;

        if (!sharedLogOutEnabled) { return; };

        // window.onpagehide = (e) => {
        //   if (!this.sessionStore.get.user || this.activity === 'Conversing') {
        //     return;
        //   }
        //   const passportId = this.sessionStore.get.user.passportId;
        //   const token = this.sessionStore.get.user.token;
    
        //   const host = this.applicationProperties.apiQueryEndpoint;
        //   const url = `${host}v1/user/passports/${passportId}/auto-logout?token=${token}`;
        //   const headers = {
        //     type: 'application/json'
        //   };
        //   const blob = new Blob([JSON.stringify({})], headers);
        //   navigator.sendBeacon(url, blob);
        // }
      })
      .catch((e) => logger.error('sharedLogOut :: e=', e));
  }

  private async retrievePresences(): Promise<void> {

    try {
      let presences: { presenceCodeName: string; color: string; }[] = await this.presenceService.retrievePresences();
      this.presences = ArrayTools.sort(presences, 'presenceCodeName');
    } catch(error) {
      logger.warn(`Could not retrieve presences for user ${this.sessionStore.get.user.email} >>`, error);
    }

    this.getSelectedPresence();
  }

  private async getSelectedPresence(): Promise<void> {
    try {
      let presenceView: { presence: string; } = await this.presenceService.retrieveSelectedPresence(this.userId)
       let existingPresence = this.presences.find(presence => presence.presenceCodeName.toLowerCase() === presenceView.presence.toLowerCase());
       if (existingPresence) {
         this.selectedPresence = existingPresence;
       } else if (presenceView.presence === 'DO NOT DISTURB') {
        this.selectedPresence = {
          presenceCodeName: presenceView.presence,
          color: 'var(--pending)'
        };
       } else {
         this.selectedPresence = {
           presenceCodeName: presenceView.presence,
           color: ''
         };
       }
     } catch(error) {
       logger.warn(`Could not retrieve selected presence for user ${this.sessionStore.get.user.email} >>`, error);
     }
  }

  private handlePresenceChanged(_presenceObject: any): void {
    if (_presenceObject.presence.toLowerCase() === 'offline') {
      if (this.sharedLogOutEnabled) {
        this.router.parent.navigate('logout');
      } else if (this.autoLogoutNotifierService.autoLogoutTimeInMinutes) {
        this.router.parent.navigate('logout?autoLogout=true');
      }
    } else if (_presenceObject.presence === 'DO NOT DISTURB') {
      this.selectedPresence = {
        presenceCodeName: _presenceObject.presence,
        color: 'var(--pending)'
      }
    }
    let existignPresence = this.presences.find(presence => presence.presenceCodeName.toLowerCase() === _presenceObject.presence.toLowerCase());
      if (existignPresence) {
        this.selectedPresence = existignPresence;
      }
  }

  private togglePresenceOption(): void {
    this.isPresenceOptionsOpened = !this.isPresenceOptionsOpened;
  }

  public changePresence(presence: { presenceCodeName: string, color: string; }): void {
    this.selectedPresence = { ...presence };
    this.isPresenceOptionsOpened = false;
    this.presenceService.changePresence(this.sessionStore.get.user.userId, presence.presenceCodeName);
  }

  @computedFrom('routingStatus')
  get presenceMsg(): string {
    if (this.routingStatus && this.routingStatus.toLowerCase() === 'ready') {
      this.isPresenceOptionsAvailable = true;
      return 'To change your presence, switch yourself to "Not Ready" for work.';
    }
    this.isPresenceOptionsAvailable = false;
    return 'Please select your presence.';
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  detached(): void {
    this.oplog.unsubscribe();
  }
}
