import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('ZProp');

@autoinject()
@customElement('z-proportional-container')

export class ZProportionalContainer {

  @bindable private delete;
  @bindable private candelete = true;
  @bindable private selected;
  @bindable private placeholder;
  @bindable private highlight;
  @bindable private isHovering: boolean;

  @bindable({attribute: 'proportion'}) private proportion: number;
  @bindable({attribute: 'layout-strategy'}) private layoutStrategy: string;
  @bindable({attribute: 'layout-definition'}) private layoutDefinition: string;
  @bindable({attribute: 'has-loader'}) private hasLoader: boolean = false;
  @bindable({attribute: 'show-hover-corners'}) private showHoverCorners: boolean = true;

  attached(): void {
  }

  public toggleHoverState(_newValue:boolean):void {
    this.isHovering = _newValue;
  }
}