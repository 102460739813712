import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('SurveyModel');

/*
 */
export class SurveyModel extends BaseModel {

  description = null;
  interactionFlows = [];
  name = null;
  questions = [];
  surveyId = null;
  type = null;
  icon = 'surveys';

  constructor(survey) {
    super();
    this.mapProperties(survey);
  }

  @computedFrom('type')
  get _type() {

    if (this.type === 'agentRating') {
      return 'agent rating';
    }
    return 'general';
  }

  @computedFrom('interactionFlows')
  get canDelete() {
    return !this.interactionFlows.length;
  }


  @computedFrom('interactionFlows')
  get descriptionText() {
    if(this.interactionFlows) {
      let _count = this.interactionFlows.length;
      let _text = this.interactionFlows.length === 1 ? 'flow' : 'flows';
      return `${_count} ${_text}`;
    }
  }

  @computedFrom('interactionFlows')
  get flows() {

    let flows = '';

    for (let i = 0; i < this.interactionFlows.length; i++) {

      if (i < this.interactionFlows.length - 1) {
        flows += this.interactionFlows[i] + ', ';
        continue;
      }
      flows += this.interactionFlows[i];
    }

    return flows;
  }
}
