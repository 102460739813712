import {inject, LogManager} from 'aurelia-framework';

import {CurrencyTools} from 'zailab.common';
import {BaseModel} from 'zailab.abstract';

export class AccountModel extends BaseModel {

  accountId = null;
  status = null;
  numberOfDaysRemaining = null;
  available = {
    amount: null,
    currency: null
  };

  constructor(balance) {
    super();
    this.mapProperties(balance);
  }

}
