import { HttpClient } from 'aurelia-http-client';
import { inject, LogManager, Factory } from 'aurelia-framework';
/* */
const logger = LogManager.getLogger('InteractionFlowService');
/* */
@inject(HttpClient)
export class CampaignInteractionFlowService {
  private memberId: string;

  constructor(private httpClient: HttpClient, ) {
  }

  public retrieveInteractionFlows(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`/v1/interaction/interaction-flows`)
        .asGet()
        .send()
        .then(
          httpResponse => {
            resolve(httpResponse.interactionFlows);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
