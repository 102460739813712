import {LogManager, autoinject, PLATFORM} from 'aurelia-framework';
import {ZIWizard} from '../../../../../../../../../typings/zai/zai.common';
/**/
const logger = LogManager.getLogger('Keypad');

/**/
@autoinject
export class Keypad {

  private wizard: ZIWizard;
  private keypadTabs: Array<object>;
  private digitType: string = null;
  private digits: any = null;

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.setupTabs();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
  }

  private extractWizardData(): void {
    this.digitType = this.wizard.data.digitType;
    this.digits = this.wizard.data.digits;
  }

  private setupTabs():void {
    this.keypadTabs = [
      {
        id: 'digit',
        name: 'Keypad Selections',
        viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/menu-options/steps/keypad/tabs/single-digit-tab'),
        icon: 'selectionmenu',
        selected: this.digitType === 'digit',
        data: {
          digits: this.wizard.data.digits,
          timeoutDestination: this.wizard.data.timeoutDestination,
          timeoutDuration: this.wizard.data.timeoutDuration,
          version: this.wizard.data.version
        }
      }
    ];
  }

  private handleTabData(_event: any): void {
    if (_event.detail.valid) {
      this.wizard.data.digitType = _event.detail.id;
      this.wizard.data.digits = _event.detail.value.digits;
      this.wizard.data.timeoutDestination = _event.detail.value.timeoutDestination;
      this.wizard.data.timeoutDuration = _event.detail.value.timeoutDuration;
      this.wizard.data.endsWith = '';
      this.wizard.data.inputMaxLength = 0;
      this.wizard.data.multiInput = false;
      // Signal wizard that we are ready to continue
      this.wizard.step.complete();
    } else {
      this.wizard.step.incomplete();
    }
  }

  private nextStep(): void {
    this.wizard.continue();
  }

}

