import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';

const logger = LogManager.getLogger('PlayTextMessageDialog');

@autoinject
export class ExternalReferenceDialog {

  public externalReference: string = '';

  private nodeData: NodeModel;
  private nodeConfiguration: ZNodeConfig;

  constructor(private dialogController: DialogController) {
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition));
    this.externalReference = this.nodeData.properties["externalReference"];
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }
    this.nodeData.properties["externalReference"] = this.externalReference;
    this.dialogController.ok(this.nodeData);
  }
}
