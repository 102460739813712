import {autoinject, LogManager, observable} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import { SearchTools } from 'zailab.common';

import {MemberModel} from "../../../../../organisation/conversation/member-model";
import {LiveDashboardService} from '../../live-dashboard-service';

const logger = LogManager.getLogger('SelectMemberDialog');

@autoinject
export class SelectMemberDialog {

  private autoCloseTimer: any;
  private jsSearchInput: HTMLElement;
  private displaySearchResults: boolean = false;
  @observable private searchCriteria: string = '';
  public organisationMembers: Array<MemberModel> = [];
  private selectedMember: MemberModel= null;
  private interactionId: string = null;

  constructor(private dialogController: DialogController, private liveDashboardService: LiveDashboardService) {
    this.dialogController = dialogController;

  }

  activate(interactionId: string): void {
    this.interactionId = interactionId;
  }

  cancel(): void {
    this.dialogController.cancel();
  }

  public retrieveOrganisationMembers(name: string, pageNumber: number, pageSize: number): void {
    this.liveDashboardService.retrieveOrganisationMembers(name, pageNumber, pageSize).then(
      members => {
        this.organisationMembers = [];
        members.forEach(member => {
          this.organisationMembers.push(member)
        })
      },
      error => {
        logger.info(' Failed to retrieve your organisation sites > error = ', error);
      }
    );
  }

  public sendDirectRoutingToAgent(interactionId: string, memberId: string): void {
    this.liveDashboardService.sendDirectRoutingToAgent(interactionId, memberId).then(
      members => {
        logger.info(' Sucessfully sent routing request ');
        this.dialogController.cancel();
      },
      error => {
        logger.info(' Failed to send routing request > error = ', error);
      }
    );
  }

  private done(): void {
    if(!this.interactionId || this.interactionId === '' || !this.selectedMember || !this.selectedMember.memberId || this.selectedMember.memberId === ''){
      return
    }
    this.sendDirectRoutingToAgent(this.interactionId, this.selectedMember.memberId);
  }

  private startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = window.setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      this.displaySearchResults = false;
    }, _delay);
  }

  private preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  private toggleSearchResults(event: Event): void {
    event.stopPropagation();
        this.displaySearchResults = !this.displaySearchResults;
        if (this.displaySearchResults) {
            this.retrieveOrganisationMembers(this.searchCriteria, 0, 5)
        }
  }

  private selectMember(event: Event, member: any): void {
    event.stopPropagation();
    this.searchCriteria = member.fullName;
    this.displaySearchResults = false;
    this.selectedMember = member
  }

  protected partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
    }
    
    private searchCriteriaChanged(): void {
        if (this.displaySearchResults) {
      this.retrieveOrganisationMembers(this.searchCriteria, 0, 5);
    }
  }

}

