import { ObserverLocator, LogManager, autoinject } from 'aurelia-framework';
/**/
import { DigitModel } from './models/digit-model';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
/**/
const logger = LogManager.getLogger('ExitDestination');

/**/
@autoinject
export class ExitDestination {

  public channel: string;
  private hasExitDestination: boolean;
  private hasAutoExit: boolean;
  private hasKeyPressExit: boolean;
  private waitTimeInSeconds: number;
  private waitTimeInMinutes: number = 0;
  private waitTimeInHours: number = 1;
  private exitDigits: DigitModel[] = [];
  private wizard: ZIWizard;
  private waitTimeObserver: any;
  private waitTimeMinutesObserver: any;
  private waitTimeHoursObserver: any;

  constructor(observerLocator: ObserverLocator) {
    this.waitTimeObserver = observerLocator
      .getObserver(this, 'waitTimeInSeconds');
    this.waitTimeMinutesObserver = observerLocator
      .getObserver(this, 'waitTimeInMinutes');
    this.waitTimeHoursObserver = observerLocator
      .getObserver(this, 'waitTimeInHours');
  }

  public activate(wizard: ZIWizard): void {
    this.init();
    this.initWizard(wizard);
    this.initDigits();
    this.extractWizardData();
    this.validate();
  }
  private init(): void {
    this.waitTimeObserver.subscribe(() => this.onWaitTimeChanged());
    this.waitTimeMinutesObserver.subscribe(() => this.onWaitTimeChanged());
    this.waitTimeHoursObserver.subscribe(() => this.onWaitTimeChanged());
  }

  private onWaitTimeChanged(): void {
    if (this.waitTimeInMinutes > 55) {
      this.waitTimeInHours++;
      this.waitTimeInMinutes = 0;
    } else if (this.waitTimeInMinutes < 0) {
      if (this.waitTimeInHours > 0) {
        this.waitTimeInMinutes = 55;
        this.waitTimeInHours--;
      } else {
        this.waitTimeInMinutes = 0;
      }
    }
    this.validate();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    this.channel = this.wizard.data.channel || this.wizard.data.flowChannel;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private initDigits(): void {

    this.exitDigits = [
      new DigitModel({ value: '1' }),
      new DigitModel({ value: '2' }),
      new DigitModel({ value: '3' }),
      new DigitModel({ value: '4' }),
      new DigitModel({ value: '5' }),
      new DigitModel({ value: '6' }),
      new DigitModel({ value: '7' }),
      new DigitModel({ value: '8' }),
      new DigitModel({ value: '9' }),
      new DigitModel({ value: '*' }),
      new DigitModel({ value: '0' }),
      new DigitModel({ value: '#', isSelected: true, disableSelection: true })
    ];
  }

  private updateWizardData(): void {
    this.wizard.data.digits = this.extractDigits();
    this.wizard.data.hasExitDestination = this.hasExitDestination;
    this.wizard.data.autoExit = this.hasAutoExit;
    this.wizard.data.keyPressExit = this.hasKeyPressExit;
    if (this.channel === 'Email') {
      let hoursToSeconds = this.waitTimeInHours * 3600;
      let minutesToSeconds = this.waitTimeInMinutes * 60;
      this.wizard.data.waitTime = (hoursToSeconds + minutesToSeconds).toString();
    } else {
      this.wizard.data.waitTime = this.waitTimeInSeconds.toString();
    }
  }

  public getInteractionString(): string {
    if (this.channel === 'Chat') {
      return 'chats';
    } else if (this.channel === 'SMS') {
      return 'SMS';
    } else if (this.channel === 'Ticket') {
      return 'ticket';
    }
    return 'calls';
  }

  private extractDigits(): string[] {
    let digits: string[] = [];
    if (!this.hasKeyPressExit) {
      return digits;
    }
    this.exitDigits.forEach(digit => {
      if (digit.isSelected) {
        digits.push(digit.value);
      }
    });
    return digits;
  }

  private nextStep(): void {
    this.waitTimeObserver.unsubscribe(() => this.onWaitTimeChanged());
    this.waitTimeMinutesObserver.unsubscribe(() => this.onWaitTimeChanged());
    this.waitTimeHoursObserver.unsubscribe(() => this.onWaitTimeChanged());
    this.wizard.continue();
  }

  private extractWizardData(): void {

    this.hasExitDestination = this.wizard.data.hasExitDestination;
    this.hasAutoExit = this.wizard.data.autoExit;
    this.hasKeyPressExit = this.wizard.data.keyPressExit;
    if (this.channel === 'Email') {
      let waitTimeInSeconds = parseInt(this.wizard.data.waitTime) || 0;
      if (waitTimeInSeconds > 0) {
        this.waitTimeInHours = Math.floor(waitTimeInSeconds / 3600);
        this.waitTimeInMinutes = Math.floor(waitTimeInSeconds % 3600 / 60);
      }
    } else {
      this.waitTimeInSeconds = parseInt(this.wizard.data.waitTime) || 0;
    }
    this.setSelectedDigits();
  }

  private setSelectedDigits(): void {
    let digits: string[] = this.wizard.data.digits;
    if (!digits) {
      return;
    }
    this.exitDigits.forEach(digit => {
      digits.forEach(_digit => {
        if (digit.value === _digit) {
          digit.isSelected = true;
        }
      });
    });
  }

  public toggleExitDestination(): void {

    this.hasExitDestination = !this.hasExitDestination;
    if (this.channel !== 'Email') {
      this.validate();
    }
  }

  public toggleAutoExit(): void {
    this.hasAutoExit = !this.hasAutoExit;
    this.validate();
  }

  public toggleKeyPressExit(): void {
    this.hasKeyPressExit = !this.hasKeyPressExit;
    this.validate();
  }

  public selectExitDigit(digit: DigitModel): void {
    if (digit.disableSelection) {
      return;
    }
    digit.isSelected = !digit.isSelected;
    this.validate();
  }

  private validate(): void {
    if (this.hasExitDestination && this.hasAutoExit && (
      (
        this.channel !== 'Email' &&
        (!this.waitTimeInSeconds || this.waitTimeInSeconds <= 0)
      ) ||
      this.channel === 'Email' && (
        (!this.waitTimeInHours || this.waitTimeInHours <= 0) &&
        (!this.waitTimeInMinutes || this.waitTimeInMinutes <= 0)
      )
    )) {
      this.wizard.step.incomplete();
    } else {
      this.wizard.step.complete();
    }
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }

  public doNothing(): void {}
}
