import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('InteractionServiceStats');

@autoinject
export class InteractionServiceStats {
  
  public columnConfig = {
    'Service': {
      tooltip: 'Service',
      value: (c) => c.serviceName,
      width: 0,
      // The field `disabled` is required for grouping.
      // This disables the ability to deselect the header.
      disabled: true
    }, 
    'Worktype': {
      tooltip: 'Worktype',
      value: (c) => c.name,
      align: 'left',
      width: 400,
      disabled: true
    },
    'BP': {
      tooltip: 'Business Partner',
      value: (c: { businessPartner: string, businessPartners?: string[] }) =>  {
        if (c.businessPartners && c.businessPartners.length > 0) {
          return c.businessPartners.join(', ');
        }
        return c.businessPartner || '--';
      },
      align: 'right',
      width: 200
    }
  };
}
