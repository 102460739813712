import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

const logger = LogManager.getLogger('WebchatService');

@autoinject
export class AgentMatchingService {
  constructor(private httpClient: HttpClient) {}

  public retrieveAgentMatchingScores(
    interactionId: string,
    memberId: string,
    from: number,
    to: number
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/task/task/scoring/searchScoring`)
      .asGet()
      .withParams({
        interactionId,
        memberId,
        from,
        to,
      })
      .send();
  }
}
