import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import TelephoneNumbersService from '../telephone-numbers-service';
import { AssignMembersDialog } from '../../../organisation/member/members/_assign-members/assign-member-dialog';
import { UnassignMemberDialog } from '../../../organisation/member/members/_unassign-members/unassign-member-dialog';
import DisplayNumbersModel from '../../telephonenumbers/telephone-numbers-model';
/*
 * */
const logger = LogManager.getLogger('NumberListView');

/*
 * */
@inject(Element, TelephoneNumbersService, Router, EventAggregator, SessionStore, DialogService)
export class NumberListView extends AbstractList {

  private assignActions = [
    {
      action: 'assign',
      label: 'Assign to Member',
      icon: 'member'
    }
  ];
  private unAssignActions = [
    {
      action: 'unassign',
      label: 'Unassign from Member',
      icon: 'member'
    }
  ];

  constructor(private element:Element, private telephoneNumbersService: TelephoneNumbersService, private router: Router, protected eventAggregator: EventAggregator, private sessionStore: SessionStore, private dialogService: DialogService) {
    super(eventAggregator);
  }

  protected activate(params?: { add: string }): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected deactivate(): void {
    super.deactivate();
  }

  protected subscribeToOplog(): void {
    this.telephoneNumbersService.initialiseNumbersOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.retrieveList();
      });
      this.oplog.on('update', (response) => {
        this.retrieveList();
      });
      this.oplog.on('delete', (response) => {
        this.retrieveList();
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.telephoneNumbersService.retrieveOrganisationNumbers().then((numberList) => {
      this.setupListConfig(numberList);
      }).catch((_error) => {
        logger.info('retrieve organisation numbers > error = ', _error);
      });
  }

  protected async numberActionsStrategy(_numbers: any[]): Promise<any[]> {

    for(let number of _numbers) {
      if (number.canAssign) {
        number.actions = this.assignActions;
      }
      if (number.canUnassign) {
        number.actions = this.unAssignActions;
      }
    }
    return _numbers;
  }

  protected async setupListConfig(_numbers: DisplayNumbersModel[]): Promise<void> {
    super.setupListConfig(_numbers);
    _numbers = await this.numberActionsStrategy(_numbers);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .items(_numbers)
    .icon('numberIcon')
    .uniqueIdentifier('telephoneNumberId')
    .displayId('telephoneNumber')
    .description('description')
    .descriptionTwo('descriptionTwo')
    .withProportion('medium')
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  private actionSelected(payload: {action: string, item: DisplayNumbersModel[]}): void {
    let actionStrategy = {
      assign: () => this.assignToMember(payload.item),
      unassign: () => this.unAssignFromMember(payload.item)
    };
    actionStrategy[payload.action] && actionStrategy[payload.action]();
  }

  public assignToMember(number: any): void {
    let _number = this.itemList.items.filter(_number => {
      return _number.telephoneNumberId === number.telephoneNumberId
    })[0];
    let organisationId = this.sessionStore.get.organisation.organisationId;
    this.telephoneNumbersService.displayMembers().then(
      members => {
        this.dialogService.open({viewModel: AssignMembersDialog, model: members}).whenClosed(response => {
          if (!response.wasCancelled) {
            let member = response['output'];
            this.telephoneNumbersService.assignNumberToMember(_number.telephoneNumberId, _number.telephoneNumber, member.memberId, member.userId, member.firstName, member.surname, member.email, organisationId);
          }
        });
      },
      error => {
        logger.info('retrieve organisation numbers > error = ', error);
      }
    );
  }

  public unAssignFromMember(number: any): void {
    this.dialogService.open({viewModel: UnassignMemberDialog, model: number}).whenClosed(response => {
      if (!response.wasCancelled) {
        number.assignee = {};
      }
    });
  }

  protected startsWithMatch(_searchExpression:any, _value:any):boolean {
    return _searchExpression && _value.telephoneNumber.toLowerCase().startsWith(_searchExpression.toLowerCase());
  }
}
