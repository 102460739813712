import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';
import {inject, LogManager, Factory} from 'aurelia-framework';
import {SessionStore, CommandFactory, OplogService} from 'zailab.common';
import { MemberSearchParams } from '../../features/interaction/dashboard/live-dashboard/live-dashboard-service';

const logger = LogManager.getLogger('MailboxService');

const getMembersForOrgEndpoint: string = 'v1/organisation/members/search';

@inject(HttpClient, SessionStore, Factory.of(CommandFactory), OplogService)
export class MailboxService {

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private commandFactory: any, private oplog: OplogService) {
  }

  public initialiseMailboxesOplog(): Promise<any> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('mailbox-projector.displayOrganisationMailboxesView'));
    });
  }

  public initialiseMailboxRecipientsOplog(): Promise<any> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('organisationId', organisationId).in('mailbox-projector.displayRecipientsView'));
    });
  }

  public getMailboxCount(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.httpClient.get('v1/organisation/mailboxes')
        .then(responseMessage => {
          resolve(responseMessage && responseMessage.mailboxes ? responseMessage.mailboxes.length : 0)
        }, (error) => {
        });
    });
  }

  public getAllMailboxes = (): Promise<Array<any>> => {

    return new Promise((resolve, reject) => {
      this.httpClient.get('v1/organisation/mailboxes')
        .then(
          (responseMessage: any) => {
            let _mailboxList: Array<ZIMailbox> = responseMessage.mailboxes ? responseMessage.mailboxes : [];

            if (_mailboxList.length === 0) {
              resolve(_mailboxList);
              return;
            }

            _mailboxList.forEach((_entry, _index) => {
              this.getRecipientsForMailbox(_entry.mailboxId).then(_recipients => {
                _entry.recipients = _recipients;
                if (_index === _mailboxList.length - 1) {
                  resolve(_mailboxList);
                }
              }).then(_value => {
                _entry.canDelete = (_entry.recipients.recipients.length === 0 && _entry.interactionFlowIds.length === 0 && (_entry.unplayedMessageIds === null || _entry.unplayedMessageIds.length === 0));
              });
            });
          },
          (error) => {
            reject(error);
          }
        );
    })
  };

  public getRecipientIdByMemberId(_memberId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/mailboxes/recipients')
        .asGet()
        .withParams({memberId: _memberId})
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            logger.info('WARN > Could not get recipientId from the memberId > error =', error);
          }
        )
    });
  }

  public getAllRecipientsForOrg(name: string, pageNumber: number, pageSize: number): Promise<any> {
    const memberSearchParams = new MemberSearchParams(undefined, name, undefined, pageNumber, pageSize);
    return new Promise((resolve, reject) => {
      this.httpClient.get(`v1/organisation/members/search2${memberSearchParams.requestParams}`)
        .then(
          (response: any) => {
            resolve(response.payload);
          },
          (error: string) => {
            reject(error);
          })
    })
  }

  public getRecipientsForMailbox(_mailboxId: string): Promise<Array<any>> {

    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/${_mailboxId}/mailbox-recipients`)
        .asGet()
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    })
  }

  // Rabbit Commands

  public createMailbox(payload: ZIMailbox): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/mailboxes')
        .asPost()
        .withContent(payload)
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public addMemberToMailbox(payload: ZIMailboxRecipientPayload): Promise<any> {
    let recipientId = payload.recipientId.recipientId ? payload.recipientId.recipientId : payload.recipientId;
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/${payload.mailboxId}/add-recipient`)
        .asPost()
        .withContent({recipientId: recipientId})
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public removeMemberFromMailbox(payload: ZIMailboxRecipientPayload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/${payload.mailboxId}/remove-recipient`)
        .asPost()
        .withContent(payload)
        .send()
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public addAssociatedAudio(_payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/${_payload.mailboxId}/add-audio`)
        .asPost()
        .withContent(_payload)
        .send();
    });
  }

  public addAssociatedTTS(_payload: any): void {
    this.httpClient
      .createRequest(`v1/organisation/mailboxes/${_payload.mailboxId}/add-text-to-speech`)
      .asPost()
      .withContent(_payload)
      .send();
  }

  public removeAssociatedAudio(_payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/${_payload.mailboxId}/remove-audio`)
        .asPost()
        .withContent(_payload)
        .send();
    });
  }

  public deleteMailbox(_payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/mailboxes/${_payload.mailboxId}`)
        .asDelete()
        .send();
    });
  }

}
