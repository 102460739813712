import {LogManager, inject, ObserverLocator} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 */
import {BootstrapFormRenderer} from 'zailab.common';
import ProspectListService from "../../prospect-list-service";


import {observable} from "aurelia-binding";
/*
 */
const logger = LogManager.getLogger('ScheduleUpload');
/*
 */
@inject(ObserverLocator, ProspectListService, ValidationControllerFactory)
export default class {

  @observable sftpHost = '';
  @observable sftpPort = '';
  @observable sftpUsername = '';
  @observable sftpPassword = '';
  @observable intervalUnit = 'DAY';

  sftpPath = '';
  intervalUnits = ['HOUR', 'DAY', 'WEEK', 'MONTH'];

  constructor(observerLocator, prospectListService, validationControllerFactory) {
    this.observerLocator = observerLocator;
    this.prospectListService = prospectListService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(wizard) {
    if(wizard) {
      this._activate(wizard);
    }

    this._initValidation();
    this.ready = true;
  }

  attached() {
    this._setDataAndValidate();
  }

  _activate(wizard) {
    this.wizard = wizard;

    if (!wizard.data.sftpConfiguration) {
      wizard.data.sftpConfiguration = {};
    }

    this.sftpHost = wizard.data.sftpConfiguration.host || '';
    this.sftpPort = wizard.data.sftpConfiguration.port || '22';
    this.sftpUsername = wizard.data.sftpConfiguration.username || '';
    this.sftpPassword = wizard.data.sftpConfiguration.password || '';
    this.sftpPath = wizard.data.sftpConfiguration.path || '';
    this.intervalUnit = wizard.data.intervalUnit || 'DAY';

    wizard.beforeNextStep(() => {
      this._continue();
    });

    this.observerLocator
      .getObserver(this, 'sftpHost')
      .subscribe(() => {
        this._setDataAndValidate();
      });
    this.observerLocator
      .getObserver(this, 'sftpPort')
      .subscribe(() => {
        this._setDataAndValidate();
      });
    this.observerLocator
      .getObserver(this, 'sftpUsername')
      .subscribe(() => {
        this._setDataAndValidate();
      });
    this.observerLocator
      .getObserver(this, 'sftpPassword')
      .subscribe(() => {
        this._setDataAndValidate();
      });
    this.observerLocator
      .getObserver(this, 'intervalUnit')
      .subscribe(() => {
        this._setDataAndValidate();
      });
  }

  _setDataAndValidate() {

    if (!this.sftpHost || !this.sftpPort || !this.sftpUsername || !this.sftpPassword) {
      this.wizard.step.incomplete(data);
      return;
    }

    let data = {
      sftpConfiguration: {
        host: this.sftpHost,
        port: this.sftpPort,
        username: this.sftpUsername,
        password: this.sftpPassword,
        path: this.sftpPath
      },
      intervalUnit: this.intervalUnit,
      businessPartnerId: this.wizard.data.businessPartnerId,
      contactCentreId: this.wizard.data.contactCentreId
    };

    this.validation.validate().then((validation) => {
      if (validation.valid) {
        this.wizard.step.complete(data);
      } else {
        this.wizard.step.incomplete(data);
      }
    });
  }

  _initValidation() {
    ValidationRules
      .customRule('sftpPortValid', () => {
        return this.sftpPort >= 0 && this.sftpPort <= 65535;
      });

    ValidationRules
      .ensure('sftpHost')
      .required().withMessage('Please enter an SFTP host.')
      .ensure('sftpPort')
      .required().withMessage('Please enter an SFTP port.')
      .satisfiesRule('sftpPortValid').withMessage('The port number must be a valid number between 0 - 65535')
      .ensure('sftpUsername')
      .required().withMessage('Please enter a username.')
      .ensure('sftpPassword')
      .required().withMessage('Please enter a password.')
      .ensure('intervalUnit')
      .required().withMessage("Please select an interval unit.")
      .on(this);
  }

  _continue() {
    this._setDataAndValidate();

    this.validation.validate().then((validation) => {
      if (validation.valid) {
        this.wizard.continue({});
      }
    });
  }
}