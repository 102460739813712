import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { OverviewService } from './overview-service';
import { SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('Overview');

@inject(DialogService, OverviewService, SessionStore, Element)
export class Overview {
  balanceInformation = null;
  isLoadingInformation = true;
  accountId = null;
  fixedBilling = false;
  memberIds = [];
  oplog;

  constructor(dialogService, overviewService, sessionStore, element) {
    this.dialogService = dialogService;
    this.overviewService = overviewService;
    this.sessionStore = sessionStore;
    this.element = element;
  }

  activate() {
    this.retrieveAccountOverviewInformation();
  }

  retrieveAccountOverviewInformation() {
    this.overviewService.retrieveAccountOverviewInformation().then(
      response => {
        this.isLoadingInformation = false;
        this.balanceInformation = response;
        this.accountId = response.accountId;
        this.fixedBilling = response.pricingModel === "FIXED";
        this.memberIds = response.memberIds;

        this.subscribeToOplog();
      },
      error => {
        logger.info('Retrieving account overview information > error = ', error);
      }
    );
  }

  subscribeToOplog() {
    this.overviewService.initialiseOplog(this.accountId).then(oplog => {
      this.oplog = oplog;

      this.oplog.on('insert', data => this.retrieveAccountOverviewInformation());
      this.oplog.on('update', data => this.retrieveAccountOverviewInformation());
      this.oplog.on('delete', data => this.retrieveAccountOverviewInformation());
    });
  }

  @computedFrom('sessionStore.get.account')
  get account() {
    return this.sessionStore.get.account;
  }

  @computedFrom('account.available')
  get isSouthAfricanOrg() {
    return this.account.available.currency === 'ZAR';
  }

  @computedFrom('account.currencySymbol')
  get currencySymbol() {
    return this.account ? this.account.currencySymbol : '';
  }

  @computedFrom('isSouthAfricanOrg')
  get isOrgSouthAfrican() {
    return this.isSouthAfricanOrg;
  }

  navigateToBuyCreditPage() {
    this.element.dispatchEvent(
      new CustomEvent('upgrade', {
        bubbles: true,
        detail: 'upgrade'
      })
    );
  }

  toggleInfo(type) {
    this.isVisible = type;
  }

  deactivate() {
    if (this.oplog) {
      this.oplog.unsub;
    }
  }
}
