import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { ZaiForm } from 'zailab.common';
import { InHouseWFMService } from '../in-house-wfm-service';

import './configure-in-house-wfm-dialog.scss';

const logger = LogManager.getLogger('ConfigureInhouseWFMDialog');

@autoinject()
export class ConfigureInhouseWFMDialog {
  private defaultSelectedDays = [
    { display: 'Sun', selected: false },
    { display: 'Mon', selected: true },
    { display: 'Tue', selected: true },
    { display: 'Wed', selected: true },
    { display: 'Thu', selected: true },
    { display: 'Fri', selected: true },
    { display: 'Sat', selected: false },
  ];
  private model = {
    workingDays: [],
    enablePublicHolidayShifts: false,
    enablePendingLeaveOverride: false,
    enableConfirmedLeaveOverride: false,
  };
  private action;
  public form: any[][];
  public formData: {
    workingDays: string[];
    enablePublicHolidayShifts: boolean;
    enablePendingLeaveOverride: boolean;
    enableConfirmedLeaveOverride: boolean;
    valid: boolean;
  };

  public error: string;
  public loading = false;
  public submitting = false;

  constructor(
    private dialogController: DialogController,
    private inHouseWFMService: InHouseWFMService
  ) {}

  public async activate(config?: {
    workingDays: string;
    enablePublicHolidayShifts: boolean;
    enablePendingLeaveOverride: boolean;
    enableConfirmedLeaveOverride: boolean;
  }): Promise<void> {
    if (config) {
      this.action = 'update';
      await this.retrieveconfig();
    } else {
      this.model.workingDays = JSON.parse(
        JSON.stringify(this.defaultSelectedDays)
      );
    }
    this.generateForm();
  }

  private async retrieveconfig(): Promise<void> {
    this.model = await this.inHouseWFMService
      .retrieveConfig()
      .catch((e) =>
        console.warn(' > Failed to get in house wfm config due to', e)
      );
    if (this.model.workingDays) {
      this.model.workingDays = this.defaultSelectedDays.map((day) => {
        day.selected = this.model.workingDays.includes(day.display);
        return day;
      });
    } else {
      this.model = {
        workingDays: this.defaultSelectedDays,
        enablePublicHolidayShifts: false,
        enablePendingLeaveOverride: false,
        enableConfirmedLeaveOverride: false,
      };
    }
  }

  private generateForm(): void {
    new ZaiForm()
      .newField()
      .asMultiSelector()
      .fullWidth()
      .withTitle('Working Days', '190px')
      .withIdentifier('daysOfWeek')
      .withItemsIdentifier('workingDays')
      .withValues(this.model.workingDays)
      .withItemWidth('50px')
      .insertField()

      .newRow()
      .newField()
      .asSliderToggle()
      .withIdentifier('enablePublicHolidayShifts')
      .withTitle('Work on Public Holiday', '190px')
      .addNegativeOption('Disabled')
      .addPositiveOption('Enabled')
      .withValue(this.model.enablePublicHolidayShifts)
      .insertField()

      .newRow()
      .newField()
      .asSliderToggle()
      .withIdentifier('enablePendingLeaveOverride')
      .withTitle('Override Pending Leave', '190px')
      .addNegativeOption('Disabled')
      .addPositiveOption('Enabled')
      .withValue(this.model.enablePendingLeaveOverride)
      .insertField()

      .newRow()
      .newField()
      .asSliderToggle()
      .withIdentifier('enableConfirmedLeaveOverride')
      .withTitle('Override Confirmed Leave', '190px')
      .addNegativeOption('Disabled')
      .addPositiveOption('Enabled')
      .withValue(this.model.enableConfirmedLeaveOverride)
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public formDataChanged(data: any): void {
    this.model = { ...data };
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public confirm(): void {
    this.error = '';
    if (this.formData.valid) {
      this.submitting = true;
      const payload = {
        workingDays: this.model.workingDays
          .filter((day) => day.selected)
          .map((day) => day.display),
        enablePublicHolidayShifts: this.model.enablePublicHolidayShifts,
        enablePendingLeaveOverride: this.model.enablePendingLeaveOverride,
        enableConfirmedLeaveOverride: this.model.enableConfirmedLeaveOverride,
      };
      if (this.action === 'update') {
        this.updateWFMConfig(payload);
      } else {
        this.setWFMConfig(payload);
      }
    }
  }

  private updateWFMConfig(payload: {
    workingDays: string[];
    enablePublicHolidayShifts: boolean;
    enablePendingLeaveOverride: boolean;
    enableConfirmedLeaveOverride: boolean;
  }): void {
    this.inHouseWFMService
      .updateConfig(
        payload.workingDays,
        payload.enablePublicHolidayShifts,
        payload.enablePendingLeaveOverride,
        payload.enableConfirmedLeaveOverride
      )
      .then(() => this.dialogController.ok(payload))
      .catch((e) => {
        console.warn('Failed to update WFM Config Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }

  private setWFMConfig(payload: {
    workingDays: string[];
    enablePublicHolidayShifts: boolean;
    enablePendingLeaveOverride: boolean;
    enableConfirmedLeaveOverride: boolean;
  }): void {
    this.inHouseWFMService
      .setConfig(
        payload.workingDays,
        payload.enablePublicHolidayShifts,
        payload.enablePendingLeaveOverride,
        payload.enableConfirmedLeaveOverride
      )
      .then(() => this.dialogController.ok(payload))
      .catch((e) => {
        console.warn('Failed to update WFM Config Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }
}
