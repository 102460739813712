import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, customElement, LogManager} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationRules, validateTrigger} from 'aurelia-validation';

import {BootstrapFormRenderer} from 'zailab.common';
import {ContactModel} from '../contact-model';
import {TelephoneNumberTypes} from '../../../../components/atoms/inputs/phonenumber/telephone-number-type';
import {ContactFactory} from '../contact-factory';
import {CONTACT_ACTIONS} from '../contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('CreateContact');

@customElement('z-create-contact')
@autoinject()
export class CreateContact {
  @bindable private contact: ContactModel;

  private tempContact: ContactModel;
  private error: string = '';
  private validation: any;
  private contactTitles: string[] = ['None', 'Mr', 'Miss', 'Mrs', 'Ms', 'Dr', 'Prof'];
  private telephoneNumberTypes;

  constructor(private contactFactory: ContactFactory, private eventAggregator: EventAggregator, private validationControllerFactory: ValidationControllerFactory) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    this.telephoneNumberTypes = TelephoneNumberTypes;
  }

  public attached(): void {
    this.tempContact = this.contact;
    this.contact = null;
    this.contact = this.contactFactory.build();
    this.contact.initNumbers();
    this.contact.initEmailAddress();

    this.initialiseValidation();
  }

  private initialiseValidation(): void {
    let maxNameLength = 30;
    let maxEmailLength = 50;
    ValidationRules
      .ensure('firstName')
      .required()
      // .matches(/^([a-zA-Z'\s-])+$/).withMessage('Must be a valid first name.')
      .matches(/^([a-zA-Z' \\-])+$/).withMessage('Must be a valid first name.')
      .maxLength(maxNameLength).withMessage('First name cannot exceed 30 characters.')
      .ensure('lastName')
      .required()
      // .matches(/^([a-zA-Z'\s-])+$/).withMessage('Must be a valid last name.')
      .matches(/^([a-zA-Z' \\-]+)+$/).withMessage('Must be a valid last name.')
      .maxLength(maxNameLength).withMessage('Last name cannot exceed 30 characters.')
      .on(this.contact);
  }

  private cancel(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW_SELECTED, {
      contactId: this.contact.contactId,
      correlationId: this.contact.correlationId,
      viewStrategy: this.tempContact.servedInteraction ? 'searchContactUnknownContact': 'searchContactEmpty'
    });
  }

  private createContact(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.contact.surname = this.contact.lastName;
      this.eventAggregator.publish(CONTACT_ACTIONS.CREATE, this.contact.editContactPayload);
    }, (error) => {
      logger.info('Error > Creating Contact >', error);
    });
  }

  public detached(): void {
    this.contact = this.tempContact;
  }
}
