import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';

import { RoutingGroupsService } from '../../../routing-groups-service';
import { ArrayTools } from 'zailab.common';


@autoinject()
export class Tab3Agents {
  private model: { routingGroupId: string, data: any };
  public agents: any;
  public ready: boolean = false;
  private pageNumber: number = 0;
  private pageSize: number = 20;

  constructor(
    private routingGroupsService: RoutingGroupsService
  ) {}

  public activate(bindingContext: { routingGroupId: string, data: any }): void {
    this.model = bindingContext;
    this.model.data = {
      selectedAgents: bindingContext.data.selectedAgents ? ArrayTools.sort(bindingContext.data.selectedAgents, 'name') : []
    };
    bindingContext.data.dispositions = undefined;
    this.retrieveAgents();
  }

  public retrieveAgents(): void {
    this.routingGroupsService
      .getRoutingGroupMembers(this.pageNumber, this.pageSize, this.model.routingGroupId)
      .then(data => {
        this.agents = ArrayTools.sort(data.payload, 'name');

        if (this.model.data.selectedAgents) {
          this.agents.forEach(agent => {
            let selectedAgent = this.model.data.selectedAgents.find(a => a.id === agent.id);
            if (selectedAgent) {
              agent.disabled = true;
            }
          });
        }

        this.ready = true;
      })
      .catch(e => {
        console.warn(' > Failed to get routing group members due to', e)
        this.ready = true;
      });
  }

  public showMore(): void {
    this.pageNumber++;
    this.retrieveAgents();
  }

  public agentSelected(item: any): void {
    if (!item) {
      return;
    }
    let team = this.model.data.selectedAgents.find(a => a.id === item.id); 
    if (!team) {
      this.model.data.selectedAgents.push(item);
      item.disabled = true;
    }
  }

  public removeAgent(item: any): void {
    if (!item) {
      return;
    }
    if (this.model.data.selectedAgents.indexOf(item) >= 0) {
      this.model.data.selectedAgents.splice(this.model.data.selectedAgents.indexOf(item), 1);
    }

    
    this.model.data.selectedAgents.splice(this.model.data.selectedAgents.indexOf(item), 1);
    let agent = this.agents.find(a => a.id === item.id);
    if (agent) {
      agent.disabled = false;
    }
  }
}
