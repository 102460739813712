import { bindable, customElement, LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('ZGridColumn');

@customElement('z-grid-column')
export class ZGridColumn {

  @bindable
  public width: string;

  private column: HTMLElement;

  public bind(): void {
    this.column.style.width = this.width;
  }
}