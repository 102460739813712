import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'zailab.common';

const logger = LogManager.getLogger('AddAudioGroupDialog');

@autoinject
export class AddAudioGroupDialog {

  private audioGroupList = [];
  private validation: any;
  public audioGroupName = '';
  public audioGroupFormGroup: any;
  public dialogHeader = "Add Audio Group";

  constructor(private dialogController: DialogController, validationControllerFactory: ValidationControllerFactory) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(audioGroupList: any[]): void {
    this.audioGroupList = audioGroupList;
    this.initValidation();
  }

  initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        if (this.audioGroupList.length > 0) {
          for (let audioGroup of this.audioGroupList) {
            if (audioGroup.name.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      }, 'Please enter a unique name.');

    ValidationRules
      .ensure('audioGroupName')
      .required().withMessage('Please enter a audio group name.')
      .satisfiesRule('uniqueName')
      .on(this);
  }

  cancel(): void {
    this.audioGroupFormGroup.reset();
    this.dialogController.cancel();
  }

  addAudioGroup(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.dialogController.ok(this.audioGroupName);
    }, error => {
      logger.info('addSkillGroup > validation failure', error);
    });
  }
}

