import {LogManager, inject} from 'aurelia-framework';
/**/
import {GridCellModel} from './models/grid-cell-model';
import {DraggableService} from '../../../_common/services/draggable.service';
/**/
const logger = LogManager.getLogger('ZGridDraggable');
/**/
export class ZGridDraggable {

  public draggableService: DraggableService;

  constructor(draggableService: DraggableService) {

    this.draggableService = draggableService;
  }

  private mouseMove(): void {

    // prevents highlighting of elements (throttled in order to avoid multiple handlers)
    window.getSelection().removeAllRanges()
  }

  private dragOver(event: DragEvent, cell: GridCellModel): DragEvent {

    let isConnector: boolean;

    if(!this.draggableService.draggingData) {
      return event;
    }

    event.dataTransfer.dropEffect = 'move';

    isConnector = this.draggableService.draggingData && this.draggableService.draggingData.isConnector;

    if(isConnector) {

      return event;
    }

    cell.hasDragOver = true;

    this.draggableService.draggingOverData = cell;

    return event;
  }

  private dragLeave(cell: GridCellModel): boolean {

    cell.hasDragOver = false;

    return true;
  }

  protected dragEnd(cell: GridCellModel): boolean {

    let isConnector: boolean = this.draggableService.draggingData && this.draggableService.draggingData.isConnector;
    let x: number;
    let y: number;

    cell.isDragging = false;

    if(isConnector) {

      return;
    }

    if(!this.draggableService.draggingOverData) {
      return true;
    }

    this.draggableService.draggingOverData.hasDragOver = false;

    if(this.draggableService.draggingOverData.cellTemplate) {
      return true;
    }

    x = this.draggableService.draggingOverData.x;
    y = this.draggableService.draggingOverData.y;

    this.removeCell(cell);

    cell.x = x;
    cell.y = y;

    this.setCellAt(x, y, cell);

    return true;
  }

  protected removeCell(cell: GridCellModel): void {}

  protected setCellAt(x: number, y: number, cell: GridCellModel): void {}

}