import {inject, LogManager} from 'aurelia-framework';
/*
 * */
const logger = LogManager.getLogger('LoaderCustomAttribute');
/**
 * @readonly
 * @enum {string}
 * */
const templates = {
  medium: '<div class="o-rotate-loader">' + '<div class="o-rotate-loader__inner">' + '<div class="o-rotate-loader__shape o-rotate-loader__shape--s1"><div class="o-rotate-loader__shape-inner"></div></div>' + '<div class="o-rotate-loader__shape o-rotate-loader__shape--s2"><div class="o-rotate-loader__shape-inner"></div></div>' + '<div class="o-rotate-loader__shape o-rotate-loader__shape--s3"><div class="o-rotate-loader__shape-inner"></div></div>' + '<div class="o-rotate-loader__shape o-rotate-loader__shape--s4"><div class="o-rotate-loader__shape-inner"></div></div>' + '<div class="o-rotate-loader__shape o-rotate-loader__shape--s5"><div class="o-rotate-loader__shape-inner"></div></div>' + '<div class="o-rotate-loader__shape o-rotate-loader__shape--s6"><div class="o-rotate-loader__shape-inner"></div></div>' + '</div></div>'
}, classNames = {
  disabled: 'u-faded-content', wrapper: 'o-loader__wrapper', positioner: 'o-loader__positioner'
}, ignore = {
  position: {
    tags: ['Z-', 'crud'], className: 'exclude_position'
  }, size: {
    tags: ['Z-PAGE-BODY', 'Z-LIST-CONTAINER', 'DASHBOARD-VESSEL', 'CRUD-PAGER']
  }
};

/*
 * */
@inject(Element)
export class LoaderCustomAttribute {

  element;
  loaderActive = false;
  loaderElement;
  loaderSize = 'medium';
  gotDimensions = false;
  isPlaceholder = false;
  dimensionsPromise = new DeferredPromise();
  forceLargeLoader = false;

  constructor(element) {
    this.element = element;
  }

  attached() {
    this.isPlaceholder = this.element.classList.contains('o-list-card--placeholder');
    this.forceLargeLoader = this.element.classList.contains('u-large-loader');
    if (!this.isPlaceholder) {
      this.getElementDimensions();
    }
  }

  valueChanged(isActive, oldValue) {
    if (isActive && !this.isPlaceholder) {
      this.show();
      return;
    }
    if (oldValue && !this.isPlaceholder) {
      this.hide();
    }
  }

  getElementDimensions() {
    this.gotDimensions = true;
    this.loaderSize = this.getLoaderSize(this.element.clientWidth, this.element.clientHeight);
    this.dimensionsPromise.resolve(this.loaderSize);
  }

  show() {
    this.loaderActive = true;
    if (!this.gotDimensions) {
      this.dimensionsPromise.then(() => {
        this.handleShow();
      })
        .catch(() => {});
    } else {
      this.handleShow();
    }
  }

  hide() {
    this.loaderActive = false;
    this.setChildrenActive();
    try {
      this.element.removeChild(this.loaderElement);
    } catch(e) {

    }
    if (this.tagName === 'BUTTON') {
      $(this).find('span').removeClass('u-text-style--clear');
    }
  }

  handleShow() {
    if(this.loaderActive) {
      this.setChildrenInactive();
      this.loaderElement = this.createLoader(this.loaderSize);
      this.element.appendChild(this.loaderElement);
    }
  }

  setChildrenInactive() {
    if(this.element && this.element.childNodes) {
      for (let item of this.element.childNodes) {
        try {
          item.classList.add(classNames.disabled);
        } catch (e) {
          // Item could not have a class assigned
        }
      }
    }
  }

  setChildrenActive() {
    for (let item of this.element.childNodes) {
      try {
        item.classList.remove(classNames.disabled);
      } catch (e) {
        // Item could not have a class assigned
      }
    }
  }

  createLoader(size) {
    let loaderWrap = document.createElement('div');
    loaderWrap.setAttribute('class', classNames.wrapper);
    loaderWrap.appendChild(this.createContent(size));
    return loaderWrap;
  }

  createContent(size) {
    let content = document.createElement('div');
    if (size === 'medium') {
      content.setAttribute('class', classNames.positioner);
      content.innerHTML = templates.medium;
    } else {
      content.setAttribute('class', 'o-button-loader-' + size);
    }
    return content;
  }

  getLoaderSize(h, w) {
    return (h < 150 || w < 150) && !this.forceLargeLoader ? 'small' : 'medium';
  }
}

class DeferredPromise {
  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.then = this._promise.then.bind(this._promise);
    this.catch = this._promise.catch.bind(this._promise);
    this[Symbol.toStringTag] = 'Promise';
  }
}
