import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

import {SessionStore, SortTools} from 'zailab.common';
import {LiveDashboardService} from './live-dashboard-service';
import {ServiceWithTaskTemplatesModel} from './models/service-with-task-template-model';
import {TaskTemplateModel} from './models/task-template-model';

// @ts-ignore
import timezone from 'countries-and-timezones';

const logger = LogManager.getLogger('AbstractLiveDashboardDrillDown');

@autoinject
export class AbstractLiveDashboardDrillDown {

  protected filters: string[] = [];
  protected resource: string = '';

  protected timezones: Array<ITimezone>;
  protected selectedTimezone: ITimezone;

  protected serviceList: ServiceWithTaskTemplatesModel[] = [];
  protected selectedService: ServiceWithTaskTemplatesModel = null;
  protected selectedServiceId: string = '';
  protected workTypesList: TaskTemplateModel[] = [];
  protected selectedWorkType: TaskTemplateModel = null;
  protected selectedWorkTypeId: string = '';
  private serviceDropdownVisible: boolean = false;
  private workTypeDropdownVisible: boolean = false;
  private timezoneDropdownVisible: boolean = false;
  private ready: boolean = false;

  constructor(protected eventAggregator: EventAggregator, protected liveDashboardService: LiveDashboardService, protected sessionStore: SessionStore) {
    this.liveDashboardService = liveDashboardService;
  }

  protected activate(params: any): void {
    this.selectedServiceId = params.serviceId;
    this.selectedWorkTypeId = params.workTypeId;
    this.retrieveServicesWithWorkTypes();
    this.setDefaultCountry();
  }

  protected showServiceDropdown(): void {
    this.serviceDropdownVisible = !this.serviceDropdownVisible;
  }

  protected showWorkTypeDropdown(): void {
    this.workTypeDropdownVisible = !this.workTypeDropdownVisible;
  }

  protected showTimezoneDropdown(): void {
    this.timezoneDropdownVisible = !this.timezoneDropdownVisible;
  }

  protected async retrieveServicesWithWorkTypes(): Promise<void> {
    this.eventAggregator.publish('app:loader:show');
    await this.liveDashboardService.retrieveAvailableServicesWithTaskTemplates().then(response => {
        this.eventAggregator.publish('app:loader:hide');
        this.serviceList = response.sort(SortTools.compareBy('serviceName'));
        if (this.selectedServiceId) {
          this.setupDefaultSelectedService(this.serviceList);
          return;
        }
        this.selectService(this.serviceList[0]);
      },
      error => {
        this.eventAggregator.publish('app:loader:hide');
        this.serviceList = [];
      }
    );
    this.ready = true;
  }

  protected setupDefaultSelectedService(serviceList: ServiceWithTaskTemplatesModel[]): void {
    this.selectedService = serviceList.find(service => {
      return service.serviceId === this.selectedServiceId;
    });
    this.selectService(this.selectedService);
  }

  protected selectService(service: ServiceWithTaskTemplatesModel): void {
    this.selectedService = service;
    for (let service of this.serviceList) {
      service.isSelected = service.serviceId === this.selectedService.serviceId;
    }
    this.setupWorkTypes();
    this.serviceDropdownVisible = false;
    this.workTypeDropdownVisible = false;
  }

  protected setupWorkTypes(): void {
    if(!this.selectedService) {
      return;
    }
    if (this.selectedService.taskTemplates && this.selectedService.taskTemplates.length === 0) {
      this.selectedWorkType = null;
      this.workTypesList = [];
    } else {

      this.workTypesList = this.selectedService.taskTemplates.sort(SortTools.compareBy('name'));

      let defaultTaskTemplate = {
        id: null,
        name: 'All Work Types',
        isSelected: false
      };

      let allWorkTypes: TaskTemplateModel = new TaskTemplateModel(defaultTaskTemplate);

      this.workTypesList.forEach((workType, index) => {
        workType.isSelected = false;
        if (!workType.id && workType.name === 'All Work Types') {
          this.workTypesList.splice(index, 1);
        }
      });

      this.workTypesList.unshift(allWorkTypes);
      if (!this.selectedWorkTypeId) {
        this.selectWorkType(this.selectedService.taskTemplates[0]);
        return;
      }

      this.selectedWorkType = this.workTypesList.filter((workType) => {
        if (workType.id === this.selectedWorkTypeId) {
          workType.isSelected = true;
          return true;
        }
        return false;
      })[0];
    }
  }

  protected selectWorkType(workType: TaskTemplateModel): void {
    if (!workType) {
      return;
    }
    this.selectedWorkType = workType;
    for (let workType of this.workTypesList) {
      workType.isSelected = workType.id === this.selectedWorkType.id;
    }
    this.serviceDropdownVisible = false;
    this.workTypeDropdownVisible = false;
  }

  private setDefaultCountry(): void {
    this.timezones = (() => {
      return this.setTimezones(this.region);
    })();
  }

  @computedFrom('sessionStore.get.organisation')
  get region(): string {
    if (this.sessionStore.get.organisation && this.sessionStore.get.organisation.country) {
      return this.sessionStore.get.organisation.country.code;
    }
    return '';
  }

  private setTimezones(selectedCountry: string): Array<ITimezone> {
    let timezones = timezone.getTimezonesForCountry(selectedCountry);
    timezones.forEach(zone => {
      let zoneName = zone.name.replace('_', ' ');
      zone.description = '(UTC' + zone.dstOffsetStr + ') ' + this.stripName(zoneName);
    });
    this.selectedTimezone = timezones[0];
    return timezones;
  }

  private stripName = name => {
    let startIndex = name.indexOf('/');
    name = name.replace(/\//g, ', ');
    return name.substring(startIndex + 1, name.length);
  };

  protected selectTimezone(timezone: ITimezone): void {
    this.selectedTimezone = timezone;
    this.timezoneDropdownVisible = false;
  }
}
