import { SegmentModel } from './segment-model';

export class StatsConfig {
  public segments: SegmentModel[] = [];
  public segmentsLabel: string = '';
  public title?: string = '';
  public isLive?: boolean = false;
  public id?: string = '';
  public view?: string = '';
  public paramData?: any = {};
  public isHidden?: boolean = false;

  constructor() {}

  public addSegment(segment: SegmentModel): void {
    this.segments.push(segment);
  }

  public setupParams(params: any[]): void {
    params.forEach((param, index) => {
      this.paramData[param] = (index === 0 ? '?' : '&') + `${param}=`;
    });
  }
}
