import { autoinject, LogManager } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import { ConversationService } from '../conversation-service';
import { ConversationModel } from '../conversation-model';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FeatureFlagService, SessionStore } from 'zailab.common';
import { RecentConversationModel } from './recent-conversation.model';
import { CONTACT_ACTIONS } from '../../contact/contactcontroller/contact-controller-actions';
import {InfiniteScrollService} from '../../../../_common/services/infinitescroll-service';
import {PlaceholderService} from '../../../../_common/services/placeholder-service';

let logger = LogManager.getLogger('ConversationHistory');

interface ConversationStatusOptions {
  display: string;
  checked: boolean;
}

interface ConversationHistoryResponse {
  recentConversations: RecentConversationModel[];
  total: number;
}

@autoinject
export class ConversationHistory {
  private ready: boolean = false;
  private total: number = 0;
  private itemList: RecentConversationModel[] = [];
  private searchParams: { page: number, size: number, status: string } = {
    page: 0,
    size: 28,
    status: ''
  };

  private options: ConversationStatusOptions[] = [
    {display: 'new', checked: true},
    {display: 'pending', checked: true},
    {display: 'successful', checked: true},
    {display: 'unsuccessful', checked: true}
  ];
  private maxOptionsLength: number = this.options.length;
  private selectedOptions: Array<object> = [];
  private selectedQueryOptions: string[] = [];
  private isAllChecked: boolean = true;
  private infiniteScroll: InfiniteScrollService;
  private placeholderService: PlaceholderService;
  private placeholders: number = 0;
  public container: Element;
  public embeddedWebphoneEnabled = null;

  constructor(
    private conversationService: ConversationService,
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private bindingSignaler: BindingSignaler,
    private featureFlagService: FeatureFlagService
  ) {}

  public activate(): void {
    this.subscribe();

    this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
      .then(async (embeddedWebphoneEnabled) => {
        this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
      });
  }

  private attached(): void {
    this.retrieveConversationHistory();
    this.infiniteScroll = new InfiniteScrollService(this.container,() => this.scrollEnd());
  }

  private subscribe(): void {
    let oplog = this.conversationService.subscribeToRecentConversations(this.sessionStore.get.user.memberId);
    oplog.on('insert', () => {
      this.retrieveConversationHistory(false, true);
    });

    oplog.on('update', () => {
      this.retrieveConversationHistory(false, true);
    });
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.itemList.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  private reGeneratePlaceholders(length: number): void {
    if (this.placeholderService) {
      this.placeholders = this.placeholderService.generatePlaceholders(length);
    }
  }

  private checkAll(): void {
    this.searchParams.page = 0;
    if (!this.isAllChecked) {
      this.selectedQueryOptions = [];
    }

    this.options.forEach((option) => {
      option.checked = this.isAllChecked;
    });
    this.retrieveConversationHistory();
  }

  private checkOptions(): void {
    this.searchParams.page = 0;
    this.selectedOptions = this.options.filter((option) => {
      return option.checked === true;
    });

    this.selectedQueryOptions = this.selectedOptions.map((option) => {
      return option.display;
    });

    this.isAllChecked = this.selectedOptions.length === this.maxOptionsLength;
    this.retrieveConversationHistory();
  }

  private retrieveConversationHistory(isScrolling?: boolean, isOplog?: boolean): void {

    if (this.isAllChecked) {
      this.searchParams.status = 'new,pending,successful,unsuccessful';
    }
    else {
      this.searchParams.status = this.selectedQueryOptions;
    }

    let encodedStatusParameters = encodeURIComponent(this.searchParams.status).replace(/%2C/g, ',');

    if (isScrolling) {
      this.searchParams.page++;
    } else {
      this.toggleReady(false);
    }
    this.conversationService.retrieveConversationHistory(this.sessionStore.get.user.memberId, encodedStatusParameters, this.searchParams.page, this.searchParams.size).then(
      (response) => {
        this.conversationHistoryRetrieveSuccessfully(response, isOplog);
        this.bindingSignaler.signal('recent-conversation-items');
      },
      (error) => {
        this.conversationHistoryRetrieveFailed(error);
      }
    );
  }

  private scrollEnd(): void {
    this.retrieveConversationHistory(true, false);
  }

  private conversationHistoryRetrieveSuccessfully(response: ConversationHistoryResponse, isOplog?: boolean): void {

    if (response && response.recentConversations) {
      this.itemList = this.searchParams.page === 0 ? response.recentConversations : this.itemList.concat(response.recentConversations);
      this.total = response.total;
    } else {
      this.itemList = [];
      this.total = 0;
    }

    this.getStatusColor();
    this.toggleScrollLoader();
    this.toggleReady(true);
    if(isOplog) {
      this.reGeneratePlaceholders(this.itemList.length);
    } else {
      this.generatePlaceholders();
    }
  }

  private conversationHistoryRetrieveFailed(error: string): void {
    this.toggleScrollLoader();
    this.toggleReady(true);
  }

  private getStatusColor(): void {
    let itemMap = { new: 'blue-highlight', pending: 'yellow', successful: 'green', unsuccessful: 'red' };
    this.itemList.forEach(item => {
      if (item && item.status) {
        item.statusColor = itemMap[item.status.toLowerCase()];
      }
    });
  }

  private toggleScrollLoader(): void {
    if(this.infiniteScroll) {
      this.infiniteScroll.loader = false;
    }
  }

  private toggleReady(value: boolean): void {
    this.ready = value;
  }

  private selectConversation(conversation: ConversationModel): void {
    if (conversation.isPlaceholder) {
      return;
    }
    if (conversation.contactId) {
      this.eventAggregator.publish(CONTACT_ACTIONS.SELECT_FROM_RECENT_CONVERSATION, { contactId: conversation.contactId, conversationId: conversation.id });
    }
  }
}
