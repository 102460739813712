import { inject, bindable, customElement, LogManager, observable } from 'aurelia-framework';
import {StatsConfig} from "../../../../../features/interaction/dashboard/live-dashboard/components/stats-config";

import moment from 'moment';

const logger = LogManager.getLogger('ZProportionalWidgetSimple');

@inject(Element)
@customElement('z-proportional-widget-simple')
export class ZProportionalWidgetSimple {

  @bindable({attribute: 'widget-data'}) @observable private widgetData: StatsConfig;
  @bindable({attribute: 'show-labels'}) private showLabels: boolean = true;

  constructor(private element: Element) {
  }

  private select(data: any): void {
    this.element.dispatchEvent(
      new CustomEvent('navigate', {
        bubbles: true,
        detail: data
      })
    );
  }
  
  private get currentTimestamp(): moment {
    return moment();
  }
}
