import { ArrayTools } from 'zailab.common';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';

import { RoutingGroupsService } from '../../../routing-groups-service';

@autoinject()
export class Tab1Worktypes {
  private model: { routingGroupId: string, data: any };
  public workTypeGroups: any[] = [{name: 'All'}, {name: 'Custom'}];
  public workTypes: { [key: string]: any[] } = { Custom: [] };

  constructor(
    private routingGroupsService: RoutingGroupsService
  ) {}

  public activate(bindingContext: { routingGroupId: string, data: any }): void {
    this.model = bindingContext;
    this.model.data = {
      selectedWorktypes: bindingContext.data.selectedWorktypes || [],
      workTypes: bindingContext.data.workTypes
    };
    bindingContext.data.dispositions = undefined;
    this.retrieveWorktyes();
  }

  private retrieveWorktyes(): void {
    this.routingGroupsService
      .getRoutingGroupWorkTypes(this.model.routingGroupId)
      .then(workTypes => {
        
        if(workTypes.length > 0) {
          workTypes = ArrayTools.sort(workTypes, 'name');

          for(let w of workTypes) {
            const it = {
              id: w.id,
              name: w.name,
              disabled: false
            }
            if (this.model.data.selectedWorktypes) {
              let workType = this.model.data.selectedWorktypes.find(wt => wt.id === w.id);
              if (workType) {
                it.disabled = true;
              }
            }
            this.workTypes.Custom.push(it);
          }
          console.log('Loading worktypes', JSON.parse(JSON.stringify(this.workTypes.Custom)));
          this.model.data.workTypes = [...this.workTypes.Custom];
        }
      })
  }

  public worktypeSelected(item: any): void {
    if (!item) {
      return;
    }
    switch(item.name) {
      case 'None':
        this.model.data.selectedWorktypes = [];
        return;
      case 'All':
        this.model.data.selectedWorktypes = [...this.workTypes.Custom];
        return;
      default:
        break;
    }

    let workType = this.model.data.selectedWorktypes.find(wt => wt.id === item.id); 
    if (!workType) {
      this.model.data.selectedWorktypes.push(item);
      item.disabled = true;
    }
  }

  public removeWorktype(item: any): void {
    if (!item) {
      return;
    }
    this.model.data.selectedWorktypes.splice(this.model.data.selectedWorktypes.indexOf(item), 1);
    let workType = this.workTypes.Custom.find(wt => wt.id === item.id);
    if (workType) {
      workType.disabled = false;
    }
  }
}
