import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager, Factory} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {CommandFactory} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('AssignNumberDialogService');

/*
 * */
@inject(HttpClient, EventAggregator, Factory.of(CommandFactory))
export class AssignEndpointDialogService {

  constructor(httpClient, eventAggregator, commandFactory) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
  }

  _retrieveAllEndpoints(params) {
    return new Promise((resolve, reject) => {
      this._createRequest('v1/interaction/imconfig', params, (response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  assignEndpointToInteractionFlow(interactionFlowId, configId) {
    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/imconfig/${configId}`)
      .asPut()
      .send();
  }

  unassignEndpointFromInteractionFlow(interactionFlowId, configId) {
    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/imconfig/${configId}`)
      .asDelete()
      .send();
  }

  /**
   * @private
   * */
  _createRequest(uri, params, callback, errorCallback) {
    this.httpClient.createRequest(uri)
      .asGet()
      .withParams(params)
      .send()
      .then(
        (response) => {
          callback(response);
        },
        (error) => {
          errorCallback(error);
        }
      );
  }
}
