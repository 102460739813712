import {autoinject, bindable, LogManager, customElement, computedFrom} from 'aurelia-framework';

import {ContactModel} from '../../contact/contact-model';
import {ConversationService} from "../conversation-service";
import {ZIOplog} from "../../../../../typings/zai/zai.common";
import {InteractionModel} from "../interaction-model";
import { WaypointModel } from '../waypoint-model';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';

const logger = LogManager.getLogger('ViewInteraction');

@customElement('z-view-unlinked-interaction')
@autoinject()
export class ViewInteraction {

  @bindable private contact: ContactModel;
  @bindable private onCall: ContactModel;

  private oplog: ZIOplog;
  private dataRequestedInteractionId: string;

  constructor(
    private conversationService: ConversationService,
    public zaiFileLoader: ZaiFileLoader
  ) {}

  public get selectedInteraction(): InteractionModel {
    let interaction;

    if(this.contact.servedUnlinkedInteraction) {
      interaction = this.contact.servedUnlinkedInteractions.find(interaction => interaction.isSelected);
      if (!interaction && this.contact.servedUnlinkedInteractions.length > 0) {
        interaction = this.contact.servedUnlinkedInteractions[0];
      }
    } else {
      interaction = this.contact.unlinkedInteractions.find(interaction => interaction.isSelected);
      if (!interaction && this.contact.unlinkedInteractions.length > 0) {
        interaction = this.contact.unlinkedInteractions[0];
      }
    }
    if (interaction && this.dataRequestedInteractionId !== interaction.interactionId) {
      this.dataRequestedInteractionId = null;
    }
    if (interaction && !this.dataRequestedInteractionId) {
      this.getInteractionData(interaction);
      this.dataRequestedInteractionId = interaction.interactionId;
    }
    return interaction;
  }

  private async getInteractionData(interaction: InteractionModel): Promise<void> {
    let data = await this.conversationService.retrieveInteraction(interaction.interactionId);
    interaction.mapInteraction(data);
    if (data.journey){
      interaction.journey = data.journey.waypoints.map(waypoint => new WaypointModel(waypoint));
    }
  }
}
