export class DataTools {
  public static getParsedValue(value: string | number): number {
    if (typeof value === 'string') {
      const parsedValue = Number(value);
      if (isNaN(parsedValue)) {
        console.warn('> Error parsing value');
      }
      return parsedValue;
    } else {
      return value;
    }
  }

  public static formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const value = (bytes / Math.pow(k, i)).toFixed(dm);
    return `${value} ${sizes[i]}`;
  }

  public static isValidJson(jsonString): boolean {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }
}
