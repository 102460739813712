export interface ZIVisibilityAware {
  becameVisible(): void;

  becameInvisible(): void;

}

export function visibilityAware(constructor: any): void {
  const originalAttached = constructor.prototype.attached;
  constructor.prototype.attached = function (): void {
    this.visibilitySubscription = this.eventAggregator.subscribe('document.visibility.changed', (isVisible) => {
      if (isVisible) {
        if (typeof(this.becameVisible) !== "undefined") {
          this.becameVisible();
        }
      } else {
        if (typeof(this.becameInvisible) !== "undefined") {
          this.becameInvisible();
        }
      }
    });
    if (typeof(originalAttached) !== "undefined") {
      return originalAttached.apply(this, arguments);
    }
  };

  const originalDetached = constructor.prototype.detached;
  constructor.prototype.detached = function (): void {
    this.visibilitySubscription.dispose();
    if (typeof(originalDetached) !== "undefined") {
      return originalDetached.apply(this, arguments);
    }
  };
}