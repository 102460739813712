import { HttpClient } from "aurelia-http-client";
import { mapMsTeamsDirectRoutingSettings, MsTeamsDirectRoutingSettings, MsTeamsDirectRoutingUpdateRequest } from "./ms-teams-direct-routing-model";
import { autoinject, LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('MsTeamsDirectRoutingService');

@autoinject
export class MsTeamsDirectRoutingService {

  private baseUri = 'v1/organisation/ms-teams-direct-routing';

  constructor(
    private httpClient: HttpClient
  ) { }

  public retrieve(): Promise<MsTeamsDirectRoutingSettings> {
    return this.httpClient.createRequest(this.baseUri)
      .asGet()
      .send()
      .then((response) => mapMsTeamsDirectRoutingSettings(response))
  }

  public update(data: MsTeamsDirectRoutingUpdateRequest): Promise<any> {
    return this.httpClient.createRequest(this.baseUri)
      .asPut()
      .withContent(data)
      .send()
      .catch((e) => this.handleError(e));
  }

  private handleError(e: any): void {
    // do nothing
  }
}