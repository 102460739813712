import { LogManager, autoinject, computedFrom, customElement, bindable } from 'aurelia-framework';
import { ValidationController, ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { Router } from 'aurelia-router';

import { Event } from 'zailab.common';

import { CurrentScopeValidation } from '../../../_common/validation/current-scope-validation';
import { ValueChangedEvent } from '../../../components/atoms/wysiwyg/z-quill-editor';
import { CannedResponseView, CannedResponseModel, EMAIL_EDITOR_OPTIONS, CHANNELS } from '../canned-responses-model';
import { CannedResponseService } from '../canned-responses-service';

const logger = LogManager.getLogger('CannedResponseForm');

@customElement('canned-responses-form')
@autoinject()
export class CannedResponseForm {

  @bindable({ attribute: 'canned-response' }) cannedResponse: CannedResponseView;
  @bindable({ attribute: 'page-header' }) public pageHeader: string;
  @bindable({ attribute: 'is-processing' }) isProcessing: boolean = false;

  public editorOptions: any = EMAIL_EDITOR_OPTIONS;
  public validation: ValidationController;
  public router: Router;
  private cannedResponseService: CannedResponseService;
  private element: Element;

  constructor(validate: CurrentScopeValidation, router: Router, cannedResponseService: CannedResponseService, element: Element) {
    this.validation = validate.getController();
    this.router = router;
    this.cannedResponseService = cannedResponseService;
    this.element = element;
    this.handleHtmlValueChanged = this.handleHtmlValueChanged.bind(this);
  }

  public attached(): void {
    this.initValidationRules();
  }

  @computedFrom('cannedResponse', 'cannedResponse.channel')
  public get isEmailChannelSelected(): boolean {
    return this.cannedResponse.channel === CHANNELS.EMAIL;
  }

  public handleHtmlValueChanged(event: ValueChangedEvent): void {
    this.cannedResponse.textBody = event.text;
  }

  public channelSelectionChanged(): void {
    this.initValidationRules();
    if (!this.cannedResponse.htmlBody) {
      this.cannedResponse.htmlBody = this.cannedResponse.textBody;
    }
  }

  public cancel(): void {
    this.cannedResponseService.findResponseTemplates()
      .then((cannedResponses) => this.navigateToList());
  }

  public save(): void {
    this.isProcessing = true;
    if (this.cannedResponse.id) {
      this.cannedResponseService.findCannedResponseById(this.cannedResponse.id)
        .then((currentCannedResponse: CannedResponseModel) => {
          if (this.cannedResponse.isValid() && this.cannedResponse.isEqual(CannedResponseView.from(currentCannedResponse))) {
            this.cancel();
          } else {
            this.validate();
          }
        });
    } else {
      this.validate();
    }
  }

  private validate(): void {
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      new Event(this.element, 'save');
    }
  }

  private navigateToList(): void {
    this.router.navigate('list');
  }

  private initValidationRules(): void {
    this.validation.reset();
    const rules = ValidationRules.ensure('name')
      .required()
      .maxLength(30).withMessage('Name cannot exceed 30 characters.');
    if (this.isEmailChannelSelected) {
      rules.ensure('htmlBody')
        .satisfies(() => !this.cannedResponse.isBodyTextBlank).withMessage('Body is required.');
    } else {
      rules.ensure('textBody')
        .required().withMessage('Body is required.');
    }
    rules.on(this.cannedResponse);
  }
}