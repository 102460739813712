import { LogManager, inject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore } from 'zailab.common';
import { OrganisationSessionModel } from '../../../../../_common/stores/sessionmodels/organisation-session-model';
import { DashboardWidget } from '../../../organisation/dashboard/dashboard-abstract';
import { SurveyService } from '../survey-service';

const logger = LogManager.getLogger('Surveys Widget');

@inject(Router, SurveyService, SessionStore)
export class SurveysDashboardWidget extends DashboardWidget {

  private oplog: any;

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }, {
    name: 'Add',
    event: 'add',
    icon: 'add'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private surveyService: SurveyService,
    private sessionStore: SessionStore
  ) {
    super();
  }

  public activate(): void {
    this.retrieveSurveysStats();
  }
  
  public detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  private retrieveSurveysStats(): void {
    if (!this.organisation || !this.organisation.organisationId) {
      setTimeout(() => {
        this.retrieveSurveysStats();
      }, 500);
      return;
    }

    this.surveyService.retrieveSurveysStats(this.organisation.organisationId).then((response) => {
      this.count = response;
      this.ready = true;
    }, (error) => {
      logger.info('Error > unable to retrieve Surveys Stats', error);
    });

    this.surveyService.initialiseStatsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.count = response.surveys.length;
      });
      this.oplog.on('update', (response) => {
        this.count = response.surveys.length;
      });
    });
  }

  // add() {
  //   this.router.navigate('surveys/wizard');
  // }


  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}