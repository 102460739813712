/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZImage');
/*
 */
@customElement('z-image')
export class ZImage {

  @bindable imagesize;
  @bindable marginless;
}
