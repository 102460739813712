import {LogManager, computedFrom} from 'aurelia-framework';

const logger = LogManager.getLogger('ChannelViewStrategyModel');

export class ChannelViewStrategyModel {

  public size: string = 'small';
  public channel: string;

  constructor() {
  }

  @computedFrom('size')
  get actions(): { grow: boolean, shrink: boolean } {
    switch (this.size) {
      case 'small':
        return {grow: true, shrink: false};
      case 'medium':
        return {grow: true, shrink: true};
      case 'large':
        return {grow: false, shrink: true};
      default:
        return {grow: true, shrink: false};
    }
  }

  @computedFrom('size')
  get canShrink(): boolean {
    switch (this.size) {
      case 'small':
        return false;
      case 'medium':
        return true;
      case 'large':
        return true;
      default:
        return false;
    }
  }

  @computedFrom('size')
  get canGrow(): boolean {
    switch (this.size) {
      case 'small':
        return true;
      case 'medium':
        return true;
      case 'large':
        return false;
      default:
        return true;
    }
  }

  public setSize(size: string): void {
    this.size = size;
  }

  public expand(): void {
    switch (this.size) {
      case 'small':
        this.size = 'medium';
        break;
      case 'medium':
        this.size = 'large';
        break;
      default:
        return;
    }
  }

  public collapse(): void {
    switch (this.size) {
      case 'medium':
        this.size = 'small';
        break;
      case 'large':
        this.size = 'medium';
        break;
      default:
        return;
    }
  }

  public setChannel(channel: string): void {
    this.channel = channel;
  }

}
