import moment from 'moment-timezone';
import 'moment-precise-range';
import { LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('TimeToValueConverter');

export class TimeToValueConverter {
  private toView(futureTimestamp: moment, unset: number, max: number): string {

    if (!futureTimestamp) {
      return futureTimestamp;
    }
    let diff = moment(futureTimestamp).diff(new moment(), 'seconds') + (unset > 0 ? unset : 0);
    if (max && diff > max) {
      diff = max;
    }
    return diff + 's';
  }
}