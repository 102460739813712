import { LogManager, autoinject } from "aurelia-framework";

import { LogoutService } from "../../logout/logout.service";

import { PassportStore } from "../stores/passport-store";

const logger = LogManager.getLogger('SessionService');

@autoinject
export class SessionService {

  private timer: SessionUserActivityTimer;

  constructor(
    private passportStore: PassportStore,
    private logoutService: LogoutService,
  ) {
    this.timer = new SessionUserActivityTimer();
  }

  public startAutoLogoutTimer(callback: Function): void {
    this.timer.start(callback);
  }

  public pauseAutoLogoutTimer(): void {
    this.timer.pause();
  }

  public resumeAutoLogoutTimer(): void {
    this.timer.resume();
  }

  public terminate(): Promise<void> {
    const passportId = this.passportStore.passportId;
    if (!passportId) {
      return;
    }
    return this.logoutService.logout(passportId);
  }
}

class SessionUserActivityTimer {

  private warningTimeout: number = 120 * 60 * 1000;
  private timeOutNow: number = 10;
  private warningTimerID: number;
  private timeoutTimerID: number;
  private callback: Function;

  public start(callback: Function): void {
    this.callback = callback;
    this.resume();
  }

  public resume(): void {
    document.addEventListener("mousemove", this.resetTimer);
    document.addEventListener("mousedown", this.resetTimer);
    document.addEventListener("keypress", this.resetTimer);
    document.addEventListener("touchmove", this.resetTimer);
    document.addEventListener("onscroll", this.resetTimer);
    this.clearTimers();
    this.startTimer();
  }

  public pause(): void {
    document.removeEventListener("mousemove", this.resetTimer);
    document.removeEventListener("mousedown", this.resetTimer);
    document.removeEventListener("keypress", this.resetTimer);
    document.removeEventListener("touchmove", this.resetTimer);
    document.removeEventListener("onscroll", this.resetTimer);
    this.clearTimers();
  }

  private clearTimers(): void {
    clearTimeout(this.timeoutTimerID);
    clearTimeout(this.warningTimerID);
  }

  private startTimer(): void {
    this.warningTimerID = setTimeout(this.warningInactive, this.warningTimeout);
  }

  private warningInactive = (): void => {
    clearTimeout(this.warningTimerID);
    this.timeoutTimerID = setTimeout(this.idleTimeout, this.timeOutNow);
  }

  private resetTimer = (): void => {
    clearTimeout(this.timeoutTimerID);
    clearTimeout(this.warningTimerID);
    this.startTimer();
  }

  private idleTimeout = (): void => {
    this.pause();
    this.callback && this.callback();
  }
}