import { autoinject } from 'aurelia-dependency-injection';
import { HttpClient } from 'aurelia-http-client';

@autoinject()
export class InAppAlertsService {
  constructor(private httpClient: HttpClient) {}

  public retrieveInAppAlerts(): Promise<any> {
    return this.httpClient
      .createRequest('v1/notifications/alerts/config/')
      .asGet()
      .send();
  }

  public retrieveInAppAlert(alertId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/notifications/alerts/config/${alertId}`)
      .asGet()
      .send();
  }

  public retrieveFields(source: string): Promise<any> {
    return this.httpClient
      .createRequest('v1/notifications/alerts/config/fields')
      .asGet()
      .withParams({ source })
      .send();
  }

  public createInAppAlert(
    alertId: string,
    name: string,
    description: string,
    message: string,
    severity: string,
    repeatIntervalEnabled: boolean,
    repeatIntervalInSeconds: number,
    inAppAlerts: boolean,
    audiences: { audience: string, audienceType: string }[],
    emailAlerts: boolean,
    emails: string[],
    source: string,
    conditions: any[],
    triggers: any[]
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/notifications/alerts/config/${alertId}`)
      .asPost()
      .withContent({
        name,
        description,
        message,
        severity,
        repeatIntervalEnabled,
        repeatIntervalInSeconds,
        inAppAlerts,
        audiences,
        emailAlerts,
        emails,
        source,
        conditions,
        triggers,
      })
      .send();
  }

  public updateInAppAlert(
    alertId: string,
    name: string,
    description: string,
    message: string,
    severity: string,
    repeatIntervalEnabled: boolean,
    repeatIntervalInSeconds: number,
    inAppAlerts: boolean,
    audiences: { audience: string, audienceType: string }[],
    emailAlerts: boolean,
    emails: string[],
    source: string,
    conditions: any[],
    triggers: any[]
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/notifications/alerts/config/${alertId}`)
      .asPut()
      .withContent({
        name,
        description,
        message,
        severity,
        repeatIntervalEnabled,
        repeatIntervalInSeconds,
        inAppAlerts,
        audiences,
        emailAlerts,
        emails,
        source,
        conditions,
        triggers,
      })
      .send();
  }

  public deleteInAppAlert(alertId: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/notifications/alerts/config/${alertId}`)
      .asDelete()
      .send();
  }

  public retrieveAuditTrail(
    pageSize: number,
    pageNumber: number
  ): Promise<any> {
    return this.httpClient
      .createRequest('v1/notifications/alerts/config/audit')
      .asGet()
      .withParams({ pageSize, pageNumber })
      .send();
  }
}
