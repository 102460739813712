import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Router} from 'aurelia-router';
import { v4 as uuidv4 } from 'uuid';
import {SessionStore} from 'zailab.common';

import InviteMemberCommandModel from './invite-member-command-model';
import {InviteService} from './invite-service';

const logger = LogManager.getLogger('Invite');

@inject(InviteService, Router, SessionStore, EventAggregator)
export class App {
  steps;
  ready = true;

  constructor(inviteService, router, sessionStore, eventAggregator) {
    this.inviteService = inviteService;
    this.router = router;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
  }

  activate() {
    this._initSteps();
  }

  _initSteps() {
    this.steps = [
      {
        name: 'Select Role',
        description: 'Please select a role for your new members.',
        view: PLATFORM.moduleName('features/user/invitation/invitations/invite/roles/roles'),
        active: true,
        hasAttributes: true,
        completed: false
      },
      {
        name: 'Define Properties',
        description: 'Please select the properties of your members in order to match the correct work items to them.',
        view: PLATFORM.moduleName('features/user/invitation/invitations/invite/attributes/attributes'),
        hasAttributes: true,
        completed: false
      },
      {
        name: 'Add Emails',
        description: 'Please add the emails of your new members.',
        view: PLATFORM.moduleName('features/user/invitation/invitations/invite/emails/emails'),
        hasAttributes: true,
        completed: false
      }
    ];
  }

  complete(data) {
    this.subscribeToValidationEvent();
    this.ready = false;
    const emails = data.detail.emails;
    const invitation = new InviteMemberCommandModel(data.detail);
    let eventsCount = 0;
    const inviterEmail = this.sessionStore.get.user.email;
    for (let email of emails) {
      if (email.sent) {
        continue;
      }
      let commandPayload = {
        channels: invitation.channels,
        classOfService: invitation.classOfService,
        email: email.email,
        invitationId: uuidv4(),
        inviter: inviterEmail,
        inviterEmail: inviterEmail,
        organisationId: this.sessionStore.get.organisation.organisationId,
        organisationName: this.sessionStore.get.organisation.organisationName,
        role: data.detail.role,
        services: invitation.services,
        site: invitation.site,
        skills: invitation.skills,
        proficiency: 0

      };
      this.inviteService.sendInvitation(commandPayload, (_email) => {
        this.eventAggregator.publish('invitation.send', _email);

        eventsCount++;
        if (eventsCount === emails.length) {
          this.inviteService.unSubscribeToInvitationIssuedEvent();
          this._invitesSent();
        }
      });
    }
  }

  subscribeToValidationEvent() {
    this.eventAggregator.subscribe('ValidationErrorsReceived', (event) => {
      if (event.state.allErrors[0].code === 'duplicate.invitation.email') {
        this.ready = true;
      }
    });
  }

  cancel() {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }

  _invitesSent() {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }
}
