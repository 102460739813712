import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import uploadRequestBody from '../../campaigns/campaign/prospectlist/upload-request-body';
import { ApplicationProperties, SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('WebchatService');

@autoinject
export class WebchatService {
  constructor(
    private httpClient: HttpClient,
    private applicationProperties: ApplicationProperties,
    private sessionStore: SessionStore
  ) {}

  public retrieveWebChatConfigs(): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/interaction/interactionflow/webchat-widget`)
      .asGet()
      .send();
  }

  public retrieveWebChatConfig(webchatWidgetId: string): Promise<any> {
    return this.httpClient
      .createRequest(
        `/v1/interaction/interactionflow/webchat-widget/${webchatWidgetId}`
      )
      .asGet()
      .send();
  }

  public retrieveAvailableWebchatWidgets(): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/interaction/interactionflow/webchat-widget/available`)
      .asGet()
      .send();
  }

  public retrieveAssignedWebchatWidgets(
    interactionFlowId: string,
    live: boolean
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `/v1/interaction/interaction-flows/${interactionFlowId}/assigned-target-resources`
      )
      .asGet()
      .withParams({ live })
      .send();
  }

  public createWebchatConfig(
    name: string,
    bannerName: string,
    bannerColor: string,
    customerTimeout: string,
    collectClientLocation: boolean,
    collectClientData: boolean,
    inactivityWarningMessage: string,
    file: { name: string; content: string }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let body = new uploadRequestBody();

      body.addDisposition({
        name: 'meta-data',
        contentType: 'application/json',
        content: JSON.stringify({
          name,
          bannerName,
          bannerColor,
          customerTimeout,
          inactivityWarningMessage,
          collectClientLocation,
          collectClientData,
        }),
      });

      if (file) {
        body.addDisposition({
          name: 'file',
          fileName: file.name,
          contentType: 'application/octet-stream',
          content: file.content,
        });
      }

      let host = this.applicationProperties.apiQueryEndpoint;
      let xhr = new XMLHttpRequest();
      xhr.open(
        'POST',
        host + `v1/interaction/interactionflow/webchat-widget`,
        true
      );
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + this.sessionStore.get.user.token
      );
      xhr.setRequestHeader(
        'Content-Type',
        'multipart/mixed; boundary=' + body.boundary
      );

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(xhr.response);
          } else if (xhr.status === 200) {
            reject(xhr.response);
          } else if (xhr.status === 500) {
            reject('Failed to upload image.');
          }
        }
      };
      xhr.send(body.getContent());
    });
  }

  public updateWebchatConfig(
    webchatWidgetId: string,
    name: string,
    bannerName: string,
    bannerColor: string,
    customerTimeout: string,
    collectClientLocation: boolean,
    collectClientData: boolean,
    inactivityWarningMessage: string,
    file: { name: string; content: string }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let body = new uploadRequestBody();

      body.addDisposition({
        name: 'meta-data',
        contentType: 'application/json',
        content: JSON.stringify({
          name,
          bannerName,
          bannerColor,
          customerTimeout,
          inactivityWarningMessage,
          collectClientLocation,
          collectClientData,
        }),
      });

      if (file) {
        body.addDisposition({
          name: 'file',
          fileName: file.name,
          contentType: 'application/octet-stream',
          content: file.content,
        });
      }

      let host = this.applicationProperties.apiQueryEndpoint;
      let xhr = new XMLHttpRequest();
      xhr.open(
        'PUT',
        host +
          `v1/interaction/interactionflow/webchat-widget/${webchatWidgetId}`,
        true
      );
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + this.sessionStore.get.user.token
      );
      xhr.setRequestHeader(
        'Content-Type',
        'multipart/mixed; boundary=' + body.boundary
      );

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve({});
          } else if (xhr.status === 500) {
            reject('Failed to upload image.');
          }
        }
      };
      xhr.send(body.getContent());
    });
  }

  public deleteWebchatConfig(webchatConfigId: string): Promise<any> {
    return this.httpClient
      .createRequest(
        `/v1/interaction/interactionflow/webchat-widget/${webchatConfigId}`
      )
      .asDelete()
      .send();
  }

  public addWidgetToInteractionFlow(
    interactionFlowId: string,
    webChatWidgetId: string,
    live?: boolean
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `/v1/interaction/interactionflow/webchat-widget/${webChatWidgetId}/assignToFlow`
      )
      .asPut()
      .withContent({
        interactionFlowId,
        live,
      })
      .send();
  }

  public removeWidgetToInteractionFlow(
    interactionFlowId: string,
    webChatWidgetId: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(
        `/v1/interaction/interactionflow/webchat-widget/${webChatWidgetId}/unassignFromFlow`
      )
      .asPut()
      .withContent({ interactionFlowId })
      .send();
  }
}
