export class ColourConfig {
  public static VIEWS = {
    LIVE_DASHBOARD_OVERVIEW: 'live-dashboard-overview',
    CONTACT_CENTER_MONITORING: 'contact-center-monitoring',
    CAMPAIGN_LIVE_DASHBOARD: 'campaign-live-dashboard',
    PROSPECT_LIST_LIVE_DASHBOARD: 'prospect-list-live-dashboard',
  };
  public static SCHEMES = {};

  public static setColourScheme(view: string, scheme: any[]): void {
    let schemeConfig: { [key: string]: { settings: { value: number; colour: string }[]; visible: boolean } } = {};
    scheme.forEach(cs => {
      schemeConfig[cs.title] = {
        settings: cs.settings,
        visible: cs.visible
      };
    })
    this.SCHEMES[view] = schemeConfig;
  }

  public static getColourScheme(view: string): any {
    return this.SCHEMES[view];
  }
}
