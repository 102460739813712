import moment from 'moment-timezone';
import 'moment-precise-range';
import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('TimeAgoValueConverter');
export class TimeAgoValueConverter {
  toView(timeFromView, currentTimeStamp, customTimeSeparator) {

    if(currentTimeStamp) {

      timeFromView = (timeFromView === -1) ? currentTimeStamp : timeFromView;

      let converted = moment.preciseDiff(timeFromView, currentTimeStamp, true);

      if(customTimeSeparator) {
        let customConverted = moment.preciseDiff(timeFromView, currentTimeStamp, true);
        let h = customConverted.hours ? `${customConverted.hours}${customTimeSeparator}` : '00:';
        let m = customConverted.minutes ? `${customConverted.minutes}${customTimeSeparator}` : '00:';
        let s = customConverted.seconds ? `${customConverted.seconds}` : '00';

        if(customConverted.hours) {
          h = this.convertToDoubleDigits(h);
        }
        if(customConverted.minutes) {
          m = this.convertToDoubleDigits(m);
        }
        if(customConverted.seconds) {
          s = this.convertToDoubleDigits(s);
        }

        return h + m + s;
      } else {
        let h = converted.hours ? `${converted.hours}h ` : '';
        let m = converted.minutes ? `${converted.minutes}m ` : '';
        let s = `${converted.seconds}s`;

        return h + m + s;
      }
    }

    return moment(timeFromView).fromNow();
  }

  convertToDoubleDigits(x) {

    let num = parseInt(x);

    if(num < 10) {
      x = `0${x}`;
    }
    return x;
  }
}