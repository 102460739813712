import { QUILL_IMAGE_UPLOAD_CONSTANTS } from './quill-image-upload-constants';

const ANIMATION_NAME = 'quill-plugin-image-upload-spinner';

const styleElement = document.createElement('style');

export class QuillImageUploadStyle {

  constructor() {
    styleElement.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(styleElement);

    styleElement.appendChild(document.createTextNode(`
  .${QUILL_IMAGE_UPLOAD_CONSTANTS.IMAGE_UPLOAD_PLACEHOLDER_CLASS_NAME} {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid var(--theme-border-gray-alt);
    border-top-color: var(--theme-border-primary-accent);
    animation: ${ANIMATION_NAME} 0.6s linear infinite;
  }
  @keyframes ${ANIMATION_NAME} {
    to {
      transform: rotate(360deg);
    }
  }
`));
  }
}
