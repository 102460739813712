import { ConnectorModel } from './../../../../../../../components/organisms/connector/models/connector-model';
import {LogManager, autoinject, bindable, PLATFORM} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NodeModel} from "../../../../../../../components/organisms/node/models/node-model";
import { CALLBACK, CRITERIA, CRITERIA_PABX, MAILBOX, PLAY_MESSAGE, SURVEY, CALL_FORWARD, PLAY_TEXT_MESSAGE, ON_CHAT, SET_VARIABLES, DECISION, ON_EMAIL, SEND_EMAIL } from '../../../interaction-config-strategy'
import { InteractionFlow } from '../../../interaction-flow';

const logger = LogManager.getLogger('WaitingRoomDialog');

@autoinject
export class NonRealTimeWaitingRoomWithStepsDialog {

  @bindable
  notRespondingEnabled : boolean;

  private nodeData: NodeModel;
  private nodeConfiguration: ZNodeConfig;
  public steps = [];

  constructor(
    private dialogController: DialogController,
    private interactionFlow: InteractionFlow
  ) { }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.init();
  }

  private init(): void {
    this.notRespondingEnabled = ((typeof(this.nodeData.properties["notRespondingEnabled"]) === 'undefined') || (this.nodeData.properties["notRespondingEnabled"] === null)) ? true : this.nodeData.properties["notRespondingEnabled"];

    const data = {
      ...this.nodeData.properties,
      flowChannel: this.nodeConfiguration.flowChannel,
      version: this.nodeData.version
    };

    this.steps = [{
      name: 'Name',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/number-of-rings/number-of-rings'),
      hasAttributes: true,
      completed: true,
      active: true,
      data
    }, {
      name: 'Advanced Routing',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/advanced-routing/advanced-routing'),
      hasAttributes: true,
      completed: true,
      data
    }, {
      name: 'Exit Destination',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/waiting-room/steps/exit-destination/exit-destination'),
      hasAttributes: true,
      completed: true,
      data
    }];
  }

  public complete(wizardData: any): void {
    let properties: any = wizardData.detail;
    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    this.dialogController.ok(updatedNode);
  }

  private mapNodeProperties(properties: any): NodeModel {

    let hasExitDestination: boolean = properties.hasExitDestination;
    let autoExit: boolean = properties.autoExit;
    let updatedConnections: ConnectorModel[] = this.mapDigitsToConnections(properties.digits, this.nodeData.connections, hasExitDestination, autoExit);

    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    this.nodeData.properties = properties;
    this.nodeData.properties.isDefined = true;
    return new NodeModel(this.nodeData);
  }

  private mapDigitsToConnections(digits: string[], existingConnections: ConnectorModel[], hasExitDestination: boolean, autoExit: boolean): ConnectorModel[] {

    let nodeID: string = this.nodeData.id;
    let connectorIndex: number = 0;
    let defaultConnection: ConnectorModel = new ConnectorModel({ name: 'default', source: { connectorIndex, nodeID } });
    let exitConnection: ConnectorModel;
    let connections: ConnectorModel[] = [defaultConnection];
    let criteria: string = this.interactionFlow.interactionFlowType === 'Office Flow' ? CRITERIA_PABX : CRITERIA;
    let customExtensions: string[] = [MAILBOX, CALLBACK, SURVEY, PLAY_MESSAGE, criteria, CALL_FORWARD, SEND_EMAIL];

    if (!hasExitDestination) {
      return this.mapExistingConnections(connections, existingConnections);
    }
    digits.forEach(digit => {
      if (digit !== '#') {
        connectorIndex++;

        let connection: ConnectorModel = new ConnectorModel({
          name: digit,
          source: { connectorIndex, nodeID },
          customExtensions
        });
        connections.push(connection);
      }
    });

    connectorIndex++;

    // Add the exit connection
    let callback: string = this.interactionFlow.interactionFlowType === 'Office Flow' ? '' : CALLBACK;
    let exitExtensions: string[] = [MAILBOX, PLAY_MESSAGE, callback, CALL_FORWARD];

    if (this.nodeConfiguration.flowChannel === 'Email') {
      exitExtensions = [PLAY_TEXT_MESSAGE, ON_EMAIL, SET_VARIABLES, DECISION, SEND_EMAIL];
    }

    exitConnection = new ConnectorModel({
      name: 'exit',
      source: { connectorIndex, nodeID },
      customExtensions: exitExtensions
    });
    connections.push(exitConnection);
    connections = this.mapExistingConnections(connections, existingConnections);
    return connections;
  }

  private mapExistingConnections(updatedConnections: ConnectorModel[], existingConnections: ConnectorModel[]): ConnectorModel[] {
    let connectorIndex: number = 0;
    updatedConnections.forEach(connection => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest = existingConnections[connectorIndex].dest;
      }
      connectorIndex++
    });
    return updatedConnections;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
