import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import moment from 'moment';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
/**/
import { Validation } from 'zailab.validation';
import {SessionStore, BootstrapFormRenderer} from 'zailab.common';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { TimezoneAbstract } from '../../timezone-abstract';
/**/
const logger = LogManager.getLogger('PublicHolidaysDialog');
/**/
@autoinject()
export class PublicHolidaysDialog extends TimezoneAbstract {

  public dialogHeader = 'Assign Public Holidays';
  public name: string = '';
  private validation: any;

  public nodeData: NodeModel;
  public holidayDates: Array<ZaiDate> = [];
  public connections: Array<ConnectorModel> = [];
  public existingConnections: Array<ConnectorModel> = [];
  public dateErrors: string = '';
  private nodeConfiguration: ZNodeConfig;
  public isValid: boolean = false;

  constructor(
    private dialogController: DialogController,
    protected sessionStore:SessionStore,
    validationControllerFactory: ValidationControllerFactory
  ) {
    super(sessionStore);
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    super.activate(_config);
    this.holidayDates = this.nodeData.properties['publicHolidays'] as Array<ZaiDate>;
    this.existingConnections = this.nodeData.connections;
    this.name = this.nodeData.properties.name || '';
    this.checkValid();
  }

  private checkValid(): void {
    this.isValid = this.name && this.holidayDates.length > 0;
  }

  public attached(): void {
    this.initialiseValidation();
  }

  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  private handleDatePicked(dateEvent: any): void {
    let momentRaw = moment(dateEvent.target.value);

    if (this.checkforDuplicates(momentRaw)) {
      this.dateErrors = 'Selected day already exists.';
      return;
    }

    if (this.checkForDatesInThePast(momentRaw)) {
      this.dateErrors = 'Please select a valid date';
      return;
    }

    this.holidayDates.push({
      momentRaw: momentRaw,
      readableDate: momentRaw.format('Do MMMM YYYY'),
      day: momentRaw.format('DD/MM/YYYY')
    });

    this.holidayDates.sort(this.compareDates);

    dateEvent.target.value = '';
    this.dateErrors = '';
    this.checkValid();
  }

  private compareDates(_firstDate: ZaiDate, _secondDate: ZaiDate): number {
    if (_firstDate.momentRaw.isBefore(_secondDate.momentRaw)) { return -1 }
    if (_firstDate.momentRaw.isAfter(_secondDate.momentRaw)) { return 1 }
    return 0
  }

  private checkforDuplicates(_momentRaw: moment): boolean {
    return this.holidayDates.some(_value => {
      return _value.day === _momentRaw.format('DD/MM/YYYY');
    });
  }

  private checkForDatesInThePast(_momentRaw: moment): boolean {
    let _today = moment();
    return _momentRaw.isBefore(_today, 'day');
  }

  private removeHolidayFromInteractionFlow(index: number, date: String): void {
    this.holidayDates.splice(index, 1);
    this.checkValid();
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {
    this.name = this.name.trim();
    if (!this.name) {
      return;
    }

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.properties['name'] = this.name;
    this.nodeData.properties['isDefined'] = this.holidayDates.length > 0;
    this.nodeData.properties['publicHolidays'] = this.holidayDates;
    this.nodeData.properties['timezone'] = this.selectedTimezone;
    this.dialogController.ok(this.nodeData);
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'yes',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: { connectorIndex: 0, nodeID: this.nodeData.id },
        customExtensions: null
      }),
      new ConnectorModel({
        name: 'no',
        dest: typeof _existingConnections[1] !== 'undefined' ? _existingConnections[1].dest : null,
        source: { connectorIndex: 1, nodeID: this.nodeData.id },
        customExtensions: null
      })
    ];
    return _connections;
  }
}
