import {LogManager} from 'aurelia-framework';
/**/
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('LineModel');
/**/
export class LineModel extends BaseModel {

    public startX: number = null;
    public startY: number = null;
    public endX: number = null;
    public endY: number = null;

    constructor(coordinates?: object) {

        super();
        super.mapProperties(coordinates);
    }
}