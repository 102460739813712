import {LogManager, inject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 */
import CampaignService from '../../campaign-service';
import {MESSAGE_EVENTS} from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('Schedule');
/*
 */
@inject(CampaignService, Router, EventAggregator)
export default class {

  steps = [{
    name: 'Define Properties',
    description: 'Please define the properties of the prospect list below.',
    view: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/defineproperties/define-properties'),
    active: true,
    completed: false,
    data: {
      nameRequired: false,
      sftpUpload: true
    }
  // }, {
  //   name: 'Define Work',
  //   description: 'Please select the properties of the work that needs to be performed.',
  //   view: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/definetasktemplate/define-task-template'),
  //   completed: false
  }, {
    name: 'Schedule Upload',
    description: 'Define how often you want to automatically update your list.',
    view: PLATFORM.moduleName('features/campaigns/campaign/prospectlist/upload/scheduleupload/schedule-upload'),
    completed: false
  }];

  ready = true;

  constructor(campaignService, router, eventAggregator) {
    this.campaignService = campaignService;
    this.router = router;
    this.eventAggregator = eventAggregator;
  }

  activate(params) {
    this.steps[0].data.campaignId = params.campaignId;

    return this.campaignService.retrieveUploadSchedule(params.campaignId).then(response => {
      if (!response){
        return;
      }

      this.steps[0].data.externalReference = response.externalReference;
      this.steps[0].data.country = response.country;
      this.steps[0].data.timezone = response.timezone;
      this.steps[0].data.startTimestamp = response.startTimestamp;
      this.steps[0].data.endTimestamp = response.endTimestamp;
      this.steps[0].data.headersIncluded = response.headersIncluded;

      this.steps[0].data.taskTemplate = response.taskTemplate;

      this.steps[0].data.intervalUnit = response.intervalUnit;
      this.steps[0].data.sftpConfiguration = response.sftpConfiguration;
    });
  }

  cancel() {
    this.router.navigateBack();
  }

  complete(event) {
    this.ready = false;
    let data = event.detail;

    this.campaignService.setSchedule(data).then(() => {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Thank you! <br><br> Prospects are being processed into contacts and work items are being created.');
      this.router.navigateBack(); // TODO: replace with actual route to navigate to
    }, (error) => {
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, error);
      this.ready = true;
    });
  }
}
