import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ContactCenter } from '../contact-center-model';
import { ContactCenterService } from '../contact-center-service';

const logger = LogManager.getLogger('ContactCenterAdd');

@autoinject()
export class ContactCenterAdd {

  public contactCenter: ContactCenter = new ContactCenter();
  public isProcessing: boolean = false;

  constructor(
    private contactCenterService: ContactCenterService,
    private router: Router
  ) { }

  public save(): void {
    this.isProcessing = true;
    this.contactCenterService.insert(this.contactCenter)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }
}