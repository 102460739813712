export class CalculatePlaceholdersValueConverter {
  toView(list, columns, rows) {

    if(!list) {
      list = [];
    }

    let currentRows = Math.ceil(list.length / columns);
    let placeholders = (currentRows * columns) - list.length + (rows - currentRows > 0 ? (rows - currentRows) * columns : 0);

    for(let i = 0; i < placeholders; i++) {
      list.push({
        isPlaceholder: true
      });
    }
    return list;
  }
}
