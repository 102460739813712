import {WidgetModel} from './widget-model';
import {DashboardModel} from './dashboard-model';

export class DashboardConfigBuilder {

  private widgets: WidgetModel[] = [];
  private dashboardModel: DashboardModel;


  constructor() {
    this.dashboardModel = new DashboardModel();
  }

  public withWidget(widget: WidgetModel): DashboardConfigBuilder {
    this.widgets.push(widget);
    return this;
  }

  public withViewModel(viewModel: string): DashboardConfigBuilder {
    this.dashboardModel.viewModel = viewModel;
    return this;
  }

  public withTitle(title: string): DashboardConfigBuilder {
    this.dashboardModel.title = title;
    return this;
  }

  public build(): DashboardModel {
    this.initialiseLayout();
    return this.dashboardModel;
  }

  private initialiseLayout(): void {

    let newItem: WidgetModel = new WidgetModel('','','',false);

    for(let item of this.widgets) {

      item.view = item.view;

      if(!item.isChild) {

        this.dashboardModel.widgets.push(item);
        continue;
      }

      newItem.childWidgets.push(item);

      if(newItem.childWidgets.length === 4) {
        this.dashboardModel.widgets.push(newItem);
        newItem.childWidgets = [];
      }
    }

    if(newItem.childWidgets.length) {
      this.dashboardModel.widgets.push(newItem);
    }
  }
}