import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {
  ValidationControllerFactory,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';

import { SessionStore, BootstrapFormRenderer, ArrayTools } from 'zailab.common';
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';
import { CampaignInteractionFlowService } from './campaign-interaction-flow-service';
import { MembersService } from '../../../organisation/member/members/members-service';
import { WorkTypesService } from '../../../organisation/conversation/worktypes/work-types-service';

import { v4 as uuidv4 } from 'uuid';

import './add-campaign-dialog.scss';

const logger = LogManager.getLogger('AddCampaignDialog');

@inject(
  DialogController,
  ValidationControllerFactory,
  FeatureFlagService,
  CampaignInteractionFlowService,
  SessionStore,
  MembersService,
  WorkTypesService
)
export class AddCampaignDialog {
  campaignId = uuidv4();
  name = '';
  dialerType = 'Preview';
  externalReference = '';
  type = 'Outbound';
  notRespondingEnabled = true;
  autoAcceptTaskEnabled = false;

  workTypes = [];
  workType;

  diallerTypes = ['Preview', 'Progressive', 'Power', 'Outbound IVR'];
  campaignDiallerTypeEnabled = false;

  voicemailDetectionEnabled = true;
  waitForVoicemailBeep = true;

  voicemailDetectionStrategies = ['Faster', 'More Accurate'];
  voicemailDetectionStrategy = 'Faster';

  onDetectVoicemailOptions = ['Hangup', 'Transfer To Flow', 'Connect To Agent'];
  outboundIVROnDetectVoicemailOptions = ['Hangup', 'Transfer To Flow'];
  onDetectVoicemailOption = 'Hangup';

  overflowCallOptions = ['Hangup', 'Transfer To Flow'];
  overflowCallOption = 'Hangup';
  overflowFlowId = '';

  availableWorkFlows = {};
  availableWorkFlowNames;
  selectedFlowName = '';

  availableOutboundFlows = {};
  availableOutboundFlowNames;
  selectedOutboundFlowName = '';

  businessPartners = [];
  selectedBusinessPartner = null;

  contactCentres = [];
  selectedContactCentre = null;

  powerDiallerConcurrentCalls = 3;

  outboundIVRMaxCalls = 10;
  outboundIVROverDialRatio = 3;
  outboundIVRFlowName = '';

  predictiveDiallerStrategies = ['Optimise For Agent'];

  predictiveDiallerCallPoolExpiry = 20;
  predictiveDiallerCallPoolAssessmentInterval = 10;
  predictiveDiallerShortCallThreshold = 15;
  predictiveDiallerMaxCallDurationThreshold = 3600;
  preferredAgentReschedulingEnabled = false;
  preferredAgentReschedulingTimeoutMinutes = 30;
  preferredAgentReschedulingTimeoutHours = 0;

  predictiveDiallerOptions = {
    diallerStrategy: 'Optimise For Agent',
  };

  ignoreDoNotContactList = false;
  doNotContactCampaignEnabled = true;

  constructor(
    dialogController,
    validationControllerFactory,
    featureFlagService,
    campaignInteractionFlowService,
    SessionStore,
    MembersService,
    workTypesService
  ) {
    this.dialogController = dialogController;
    this.sessionStore = SessionStore;
    this.membersService = MembersService;
    this.workTypesService = workTypesService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    featureFlagService.isEnabled('CampaignDiallerType').then((enabled) => {
      this.campaignDiallerTypeEnabled = enabled;
    });

    featureFlagService.isEnabled('predictive-dialler').then((enabled) => {
      if (enabled) {
        this.diallerTypes.push('Predictive');
      }
    });

    campaignInteractionFlowService.retrieveInteractionFlows().then(
      (_response) => {
        let availableWorkFlowNames = [];
        let availableOutboundFlowNames = [];
        _response.forEach((flow) => {
          if (flow.flowType === 'Contact Centre Flow') {
            if (flow.channel === 'Inbound Call') {
              if (flow.numbers.length > 0) {
                this.availableWorkFlows[flow.flowName] = flow.interactionFlowId;
                availableWorkFlowNames.push(flow.flowName);
              }
            } else if (flow.channel === 'Outbound Call') {
              this.availableOutboundFlows[flow.flowName] =
                flow.interactionFlowId;
              availableOutboundFlowNames.push(flow.flowName);
            }
          }
        });
        this.availableWorkFlowNames = availableWorkFlowNames;
        this.availableOutboundFlowNames = availableOutboundFlowNames;

        if (this.availableOutboundFlowNames.length > 0) {
          this.selectedOutboundFlowName = this.availableOutboundFlowNames[0];
        }
      },
      (_error) => {
        logger.error('Unable to retrieve interaction flows');
      }
    );
  }

  activate(campaignList) {
    this.getMember();
    this.getWorkTypes();
    this.setValidationRules(campaignList);
  }

  getMember() {
    this.membersService.retrieveMember(this.user.memberId).then((member) => {
      this.businessPartners = member.businessPartners;
      this.businessPartners = ArrayTools.sort(this.businessPartners, 'name');
      if (this.businessPartners.length > 0) {
        const contactCentres = this.businessPartners[0].contactCentres;
        this.contactCentres = contactCentres ? contactCentres : [];
        this.contactCentres = ArrayTools.sort(this.contactCentres, 'name');
      }
    });
  }

  getWorkTypes() {
    this.workTypesService.outboundWorkTypes().then((taskTemplates) => {
      this.workTypes = taskTemplates.map((tt) => {
        return {
          templateId: tt.templateId,
          templateName: tt.templateName,
        };
      });
    });
  }

  selectBusinessPartner() {
    let businessPartner = this.businessPartners.find(
      (bp) => bp.name === this.selectedBusinessPartner
    );
    const contactCentres = businessPartner.contactCentres;
    this.contactCentres = contactCentres ? contactCentres : [];
    this.contactCentres = ArrayTools.sort(this.contactCentres, 'name');
    this.selectedContactCentre =
      contactCentres && contactCentres.length
        ? contactCentres[0].name
        : undefined;
  }

  cancel() {
    this.dialogController.cancel();
  }

  add() {
    let preferredAgentReschedulingTimeoutHours =
      this.preferredAgentReschedulingTimeoutHours;
    try {
      preferredAgentReschedulingTimeoutHours = parseInt(
        preferredAgentReschedulingTimeoutHours
      );
    } catch {
      /**/
    }
    let preferredAgentReschedulingTimeoutMinutes =
      this.preferredAgentReschedulingTimeoutMinutes;
    try {
      preferredAgentReschedulingTimeoutMinutes = parseInt(
        preferredAgentReschedulingTimeoutMinutes
      );
    } catch {
      /**/
    }

    const preferredAgentReschedulingTimeout =
      preferredAgentReschedulingTimeoutHours * 60 +
      preferredAgentReschedulingTimeoutMinutes;

    const businessPartner = this.businessPartners.find(
      (item) => item.name === this.selectedBusinessPartner
    );
    const contactCentre = this.contactCentres.find(
      (item) => item.name === this.selectedContactCentre
    );

    this.validation.validate().then(
      (validation) => {
        if (!validation.valid) {
          return;
        }

        let campaignId = this.campaignId;
        let name = this.name;
        let taskTemplate = this.workType
          ? this.workTypes.find((wt) => wt.templateName === this.workType)
          : '';
        let dialerType = this.dialerType;
        let externalReference = this.externalReference;
        let type = this.type;
        let notRespondingEnabled = this.notRespondingEnabled;
        let autoAcceptTaskEnabled = this.autoAcceptTaskEnabled;
        this.predictiveDiallerOptions.defaultDialRatio =
          this.powerDiallerConcurrentCalls;
        this.predictiveDiallerOptions.callPoolExpiry =
          this.predictiveDiallerCallPoolExpiry;
        this.predictiveDiallerOptions.callPoolAssessmentInterval =
          this.predictiveDiallerCallPoolAssessmentInterval;
        this.predictiveDiallerOptions.shortCallThreshold =
          this.predictiveDiallerShortCallThreshold;
        this.predictiveDiallerOptions.maxCallDurationThreshold =
          this.predictiveDiallerMaxCallDurationThreshold;
        let diallerOptions = {
          voicemailDetectionEnabled: this.voicemailDetectionEnabled,
          waitForVoicemailBeep: this.waitForVoicemailBeep,
          voicemailDetectionStrategy: this.voicemailDetectionStrategy,
          onDetectVoicemailOption: this.onDetectVoicemailOption,
          targetInteractionFlowId:
            this.availableWorkFlows[this.selectedFlowName],
          outboundInteractionFlowId:
            this.availableOutboundFlows[this.selectedOutboundFlowName],
          powerDiallerConcurrentCalls: this.powerDiallerConcurrentCalls,
          overflowCallOption: this.overflowCallOption,
          overflowFlowId: this.overflowFlowId,
          outboundIVRFlowId: this.availableWorkFlows[this.outboundIVRFlowName],
          outboundIVRMaxCalls: this.outboundIVRMaxCalls,
          outboundIVROverDialRatio: this.outboundIVROverDialRatio,
          predictiveDiallerOptions: this.predictiveDiallerOptions,
        };
        let ignoreDoNotContactList = this.ignoreDoNotContactList;

        this.dialogController.ok({
          campaignId,
          name,
          dialerType,
          taskTemplateId: taskTemplate.templateId,
          externalReference,
          type,
          notRespondingEnabled,
          autoAcceptTaskEnabled,
          diallerOptions,
          businessPartnerId: businessPartner ? businessPartner.id : null,
          contactCentreId: contactCentre ? contactCentre.id : null,
          preferredAgentReschedulingEnabled:
            this.preferredAgentReschedulingEnabled,
          preferredAgentReschedulingTimeout: this
            .preferredAgentReschedulingEnabled
            ? preferredAgentReschedulingTimeout
            : 0,
          ignoreDoNotContactList,
        });
      },
      (error) => {
        logger.info(
          'Validation for creating campaign name unsuccessful.',
          error
        );
      }
    );
  }

  toggleNotRespondingEnabled() {
    this.notRespondingEnabled = !this.notRespondingEnabled;
  }

  toggleRescheduleLeadsEnabled() {
    this.preferredAgentReschedulingEnabled =
      !this.preferredAgentReschedulingEnabled;
  }

  toggleAutoAcceptTaskEnabled() {
    this.autoAcceptTaskEnabled = !this.autoAcceptTaskEnabled;
  }

  toggleVoicemailDetectionEnabled() {
    this.voicemailDetectionEnabled = !this.voicemailDetectionEnabled;
  }

  toggleWaitForVoicemailBeep() {
    this.waitForVoicemailBeep = !this.waitForVoicemailBeep;
  }

  toggleIgnoreDoNotContactList() {
    this.ignoreDoNotContactList = !this.ignoreDoNotContactList;
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  setValidationRules(campaignList) {
    ValidationRules.customRule('uniqueCampaignName', (name) => {
      for (let campaign of campaignList) {
        if (
          campaign.archived === false &&
          campaign.name.toLowerCase() === name.toLowerCase()
        ) {
          return false;
        }
      }
      return true;
    });

    ValidationRules.customRule('maxCallBounds', (maxCalls) => {
      if (maxCalls < 1 || maxCalls > 50) {
        return false;
      }
      return true;
    });

    ValidationRules.customRule('overDialRatio', (overDialRatio) => {
      if (overDialRatio < 0 || overDialRatio > 5) {
        return false;
      }
      return true;
    });

    ValidationRules.customRule(
      'predictiveDiallerCallPoolExpiry',
      (predictiveDiallerCallPoolExpiry) =>
        predictiveDiallerCallPoolExpiry > 0 &&
        predictiveDiallerCallPoolExpiry <= 30
    );

    ValidationRules.customRule(
      'predictiveDiallerCallPoolAssessmentInterval',
      (predictiveDiallerCallPoolAssessmentInterval) =>
        predictiveDiallerCallPoolAssessmentInterval >= 1 &&
        predictiveDiallerCallPoolAssessmentInterval <= 60
    );

    ValidationRules.customRule(
      'predictiveDiallerCallDurationThreshold',
      (predictiveDiallerCallDurationThreshold) =>
        predictiveDiallerCallDurationThreshold > 0 &&
        predictiveDiallerCallDurationThreshold <= 7200
    );

    ValidationRules.customRule(
      'predictiveDiallerShortCallThreshold',
      (predictiveDiallerShortCallThreshold) =>
        predictiveDiallerShortCallThreshold > 0 &&
        predictiveDiallerShortCallThreshold <= 60
    );

    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a campaign name.')
      .satisfiesRule('uniqueCampaignName')
      .withMessage('Please enter a unique campaign name.')

      .ensure('outboundIVRMaxCalls')
      .required()
      .withMessage('Max calls must be between 1 and 50.')
      .satisfiesRule('maxCallBounds')
      .withMessage('Max calls must be between 1 and 50.')

      .ensure('outboundIVROverDialRatio')
      .required()
      .withMessage('OverDial ratio must be between 0 and 5.')
      .satisfiesRule('overDialRatio')
      .withMessage('OverDial ratio must be between 0 and 5.')

      .ensure('predictiveDiallerCallPoolExpiry')
      .required()
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Call Pool Expiry must be between 1 and 30s')
      .then()
      .satisfiesRule('predictiveDiallerCallPoolExpiry')
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Call Pool Expiry must be between 1 and 30s')

      .ensure('predictiveDiallerCallPoolAssessmentInterval')
      .required()
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Interval must be between 1 and 60s')
      .satisfiesRule('predictiveDiallerCallPoolAssessmentInterval')
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Interval must be between 1 and 60s')

      .ensure('predictiveDiallerCallDurationThreshold')
      .required()
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Max call threshold must be between 1 and 7200s')
      .satisfiesRule('predictiveDiallerCallDurationThreshold')
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Max call threshold must be between 1 and 7200s')

      .ensure('predictiveDiallerShortCallThreshold')
      .required()
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Short call threshold must be between 1 and 60s')
      .satisfiesRule('predictiveDiallerShortCallThreshold')
      .when(() => this.dialerType === 'Predictive')
      .withMessage('Short call threshold  must be between 1 and 60s')

      .on(this);
  }
}
