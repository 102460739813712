import {HttpClient} from 'aurelia-http-client';
import {Factory, inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {CommandFactory, OplogService, SessionStore} from 'zailab.common';
import {SkillGroupModel} from './skill-group-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('SkillGroupsListService');

/*
 * */
@inject(HttpClient, OplogService, EventAggregator, Factory.of(CommandFactory), SessionStore)
export class SkillGroupsListService {

  constructor(httpClient, oplogService, eventAggregator, commandFactory, sessionStore) {
    this.httpClient = httpClient;
    this.oplog = oplogService;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.sessionStore = sessionStore;
  }

  retrieveOrganisationSkillGroups(organisationId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/me/skill-groups`)
          .asGet()
          .send()
          .then((response) => {
            resolve(this._modelCollection(response));
          }, (error) => {
            reject(error);
          });
    });
  }

  retrieveSkillGroup(skillGroupId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/me/skill-groups/${skillGroupId}`)
        .asGet()
        .send()
        .then((response) => {
          resolve(this._modelEntity(response));
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('organisation-projector.displaySkillGroupsView'));
    });
  }

  _modelCollection(skillGroupData) {
    let skillGroupList = [];
    skillGroupData.forEach(skillGroup => {
      skillGroupList.push(this._modelEntity(skillGroup));
    });
    return skillGroupList;
  }

  _modelEntity(skillGroup) {
    return new SkillGroupModel(skillGroup);
  }

  removeSkillGroup(organisationId, skillGroup) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/${organisationId}/skill-groups/${skillGroup.id}`)
          .asDelete()
          .send()
          .then((response) => {
            resolve(response);
          }, (error) => {
            reject(error);
          });
    });
  }

  addSkillGroup(organisationId, skillGroupName) {
    let skillId = uuidv4();
    let commandPayload = JSON.stringify({id: skillId, name: skillGroupName});
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/${organisationId}/skill-groups`)
          .asPost()
          .withHeader('Content-Type', 'application/json;charset=UTF-8')
          .withContent(commandPayload)
          .send()
          .then((response) => {
            resolve(response);
          }, (error) => {
            reject(error);
          });
    });
  }
}
