export class GoogleChartConfig {

  TOOLTIP_TEMPLATE =
    '<div class="tooltip-wrapper"><img src="target/_assets/img/icon_legend_agent-utilisation.png" class="tooltip-icon"><div class="tooltipText"><span>#agentUtilisation</span></div></div>' + 
    '<div class="tooltip-wrapper"><img src="target/_assets/img/icon_legend_number-agents.png" class="tooltip-icon"><div class="tooltipText"><span>#agentsRequired</span></div></div>' + 
    '<div class="tooltip-wrapper"><img src="target/_assets/img/icon_legend_call-time.png" class="tooltip-icon"><div class="tooltipText"><span>#agentWaitTime</span></div></div>' + 
    '<div class="tooltip-wrapper"><img src="target/_assets/img/icon_legend_call-volume.png" class="tooltip-icon"><div class="tooltipText"><span>#numberOfCalls</span></div></div>';

  defaults = {
    width: '100%',
    chartType: 'LineChart',
    //dataTable: //configured in viewModel
    options: {
      setOption: {
        tooltipWidth: 400
      },
      chartArea: {
          'width': '100%',
          'height': '90%',
          'top': 0,
          'left': 80
        },
        crosshair: {
          trigger: 'both',
          color: 'var(--gray-alt)'
        }, // Display crosshairs on focus and selection.
        annotations: {
          textStyle: {
            fontName: 'NeoTech',
            fontSize: 14,
            bold: false,
            italic: false,
            strokeWidth: 0,
            color: 'var(--pending)', // The color of the text.
            'fill-color': 'var(--pending)'
          },
          boxStyle: {
            // Color of the box outline.
            stroke: 'var(--color-gray--shade-65)',
            // Thickness of the box outline.
            strokeWidth: 0,
            // x-radius of the corner curvature.
            rx: 10,
            // y-radius of the corner curvature.
            ry: 10
          }
        },
        animation: {
          duration: 300,
          easing: 'out',
        },
        lineWidth: 1,
        backgroundColor: {
          fill: 'none'
        },
        titleTextStyle: {
          color: 'var(--pending)'
        },
        legendTextStyle: {
          color: 'var(--pending)'
        },
        colors: ['var(--primary-accent--shade-70)'],
        hAxis: {
          title: 'Time of Calls',
          titleTextStyle: {
            fontName: 'NeoTech',
            fontSize: 16,
            bold: false,
            italic: false,
            color: 'var(--theme-text-default)', // The color of the text.
          },
          slantedText: true,
          slantedTextAngle: 45,
          viewWindowMode: 'pretty',
          viewWindow: {
            //min: //configured in viewModel
            //max: //configured in viewModel
          },
          scaleType: 'fixed',
          gridlines: {
            color: 'var(--color-gray--shade-80)'
          },
          minValue: 0,
          textStyle: {
            color: 'var(--pending)',
            fontSize: 11,
            fontName: 'NeoTech'
          }
        },
        vAxis: {
          title: 'Number of Calls',
          titleTextStyle: {
            fontName: 'NeoTech',
            fontSize: 16,
            bold: false,
            italic: false,
            color: 'var(--theme-text-default)', // The color of the text.
          },
          viewWindowMode: 'explicit',
          viewWindow: {
            //max: //configured in viewModel
            min: 0
          },
          minValue: 0,
          gridlines: {
            color: 'var(--color-gray--shade-80)'
          },
          textStyle: {
            fontSize: 14,
            color: 'var(--pending)',
            fontName: 'NeoTech'
          }
        },
        explorer: {
          maxZoomOut: 1,
          keepInBounds: true
        },
        tooltip: {
          isHtml: true,
        },
        title: '',
        /*curveType: 'function',*/
        pointSize: 10,
        /*pointShape: { type: 'star', sides: 4 },*/
        legend: {
          position: 'none'
        }
    },
    containerId: 'workforceChart'
  };

}
