import { inject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore } from 'zailab.common';
import { WebSocketClient } from './websocket.client';
import { WebSocketKiosk } from './websocket.kiosk';
import { ApplicationProperties } from '../../_config/application.properties';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('WebSocket');

@inject(EventAggregator, WebSocketClient, ApplicationProperties, WebSocketKiosk, SessionStore)
export class WebSocket {
  reconnectCount = 0;
  delay = 0;
  canSend = true;
  reconnecting = false;
  timeout = {};
  hasPing = null;
  isOutOfSync;

  constructor(eventAggregator, socket, applicationProperties, webSocketKiosk, sessionStore) {
    this.eventAggregator = eventAggregator;
    this.applicationProperties = applicationProperties;
    this.sessionStore = sessionStore;
    this.socket = socket;
    this.webSocketKiosk = webSocketKiosk;
    this.subscribers = {};
    this.queue = [];
    this.open = false;

    const user = this.sessionStore.get.user;
    this.init(user ? user.userId : null);
  }

  showError(env) {
    if (this.reconnecting) {
      return;
    }

    this.timeout.notConnected = setInterval(() => {
      if (this.open) {
        clearInterval(this.timeout.notConnected);
        return;
      }

      if (this.canSend) {
        if (this.reconnectCount >= 4) {
          this.eventAggregator.publish('websocket.connection.reconnect', {id:'CLIENT_EXCHANGE',env});
          return;
        } else {
          this.eventAggregator.publish('websocket.connection.timeout', {id:'CLIENT_EXCHANGE',env});
        }

        this.isOutOfSync = true;
        this.canSend = false;
        this.reconnecting = true;
      }
    }, 2000);
  }

  init(userId, websocketOpenCallback) {
    if (this.socket.ws && this.socket.ws.readyState === 0) {
      return;
    }

    this.socket.io.uri = `${this.applicationProperties.apiCommandEndpoint}?appId=${this.sessionStore.get.application.appId}${userId ? '&userid=' + userId : ''}`;
    this.checkConnection();
    this.socket.connect((env) => {
      logger.info('Websocket closed >', this.reconnectCount);

      this.showError(env);

      this.checkConnection();
      this.open = false;

      if (this.timeout && this.timeout.reconnect) {
        clearTimeout(this.timeout.reconnect);
      }

      this.timeout.reconnect = setTimeout(() => {
        this.reconnectCount++;
        this.delay = Math.pow(2, this.reconnectCount) * 1000;

        logger.info('Attempting to reconnect in ' + this.delay + ' ms...');

        if (this.reconnectCount > 10) {
          this.delay = 0;
          return;
        }

        this.canSend = true;

        this.init(userId);
      }, this.delay);
    });

    this.subscribe({
      name: 'OPEN',
      callback: () => {
        this.onOpen();
        websocketOpenCallback && websocketOpenCallback();
      }
    });

    this.socket.on('message-SUB', emittedMessage => {
      this.checkConnection();

      if (emittedMessage.name && emittedMessage.state) {
        this.reconnectCount = 0;
        this.delay = 0;
        logger.info('incoming ', emittedMessage, ' on message-SUB');
      }

      let subscriber = this.subscribers[emittedMessage.name];
      if (subscriber && subscriber.callback) {
        if (emittedMessage.state) {

          try {
            let data = JSON.parse(emittedMessage.state);
            emittedMessage.state = data;
          }
          catch (e) {
            logger.debug('this.ws.onmessage parse error = ', { error: e, data: emittedMessage.state });
          }
        }
        subscriber.callback(emittedMessage);
      }
    });
  }

  resetWebsocket(userId) {
    return new Promise(resolve => {
      this.closeWebsocket();
      this.reconnectCount = 0;
      this.delay = 0;
      this.init(userId, resolve);
    });
  }

  closeWebsocket() {

    this.socket.close();
  }

  onOpen() {
    this.checkConnection();

    this.open = true;
    this.reconnecting = false;

    this.publish({ name: 'OPENED' });
    this.reconnectCount = 0;
    this.delay = 0;
    clearTimeout(this.timeout.reconnect);
    clearInterval(this.timeout.notConnected);

    setTimeout(() => {
      this.timeout = {};
    }, 100);

    this.eventAggregator.publish('websocket.connection.connected', {id:'CLIENT_EXCHANGE'});

    for (let message of this.queue) {
      this.publish(message);
    }
  }

  publish(message) {
    if (this.open) {
      message.trackingId = uuidv4();
      const user = this.sessionStore.get.user;
      message.Authorization = (user && user.token ? 'Bearer ' + user.token : '');

      logger.debug('outgoing message = ', message);

      this.socket.emit('message-PUB', message);
    } else {
      this.queue.push(message);
    }
  }

  subscribe(params) {
    this.subscribers[params.name] = {
      callback: params.callback
    };
  }

  unSubscribe(name) {
    delete this.subscribers[name];
  }

  checkConnection() {
    if (this.hasPing) {
      clearInterval(this.hasPing);
      this.hasPing = null;
    }

    // used for when wifi is disconnected
    this.hasPing = setInterval(() => {
      this.showError('WIFI_ERROR?');
      this.open = false;
      this.closeWebsocket();

      setTimeout(() => {
        const user = this.sessionStore.get.user;
        this.init(user ? user.userId : null, () => {
          this.eventAggregator.publish('TRIGGER-RECONNECT-REQUESTS');
        });
      }, 100);
      this.canSend = true;
    }, 32000);
  }
}
