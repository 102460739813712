import { inject, customElement, bindable, LogManager, containerless, observable } from 'aurelia-framework';
/*
 */
import { Event, SessionStore } from 'zailab.common';
import { List } from 'zailab.abstract';
import { ChannelsService } from '../channels-service';
/*
 */
let logger = LogManager.getLogger('CondensedChannelsList');
/*
 */
@containerless()
@customElement('z-condensed-channels-list')
@inject(Element, SessionStore, ChannelsService)
export default class {
  @bindable required;
  @bindable multiselect;
  @bindable nodefaultselected;
  @bindable
  @observable
  selected;
  @bindable isofficetype;
  @bindable canedit;
  @bindable titleoverride;
  @bindable
  @observable
  role;
  @bindable({ attribute: 'ignore-role' })
  ignoreRole;
  @bindable({ attribute: 'items-per-row' })
  itemsperrow;
  @bindable({ attribute: 'observe-channels-changes' })
  observeChannelsChanges;
  @bindable({ attribute: 'lock-channel-type' }) lockChannelType = null;
  list;
  channels = [];

  constructor(element, sessionStore, channelsService) {
    this.element = element;
    this.sessionStore = sessionStore;
    this.channelsService = channelsService;
  }

  bind() {
    this.channelsService.displayChannels(!this.multiselect).then(
      channels => {
        this.channels = channels;
        this.disableChannelsBasedOnRole();
        this._setupConfig();
      },
      error => {
        logger.info('channels query failed >> ', error);
      }
    );
  }

  _setupConfig() {
    if (!this.ignoreRole && (!this.role || !this.channels)) {
      return;
    }

    let defaultSelectionStrategy = (this.nodefaultselected) ? null : this._defaultSelectionStrategy;

    this.list = List.Builder(this.sessionStore) //
      .required(this.required) //
      .customEventElement(this.element) //
      .selectionType(this.multiselect ? List.MULTIPLE_SELECTION_TYPE : List.SINGLE_SELECTION_TYPE) //
      .title(this.title) //
      .items(this._orderChannelsByType(this.channels)) //
      .selectedItems(this.selected) //
      .icon('channels') //
      .uniqueIdentifier('channelName') //
      .displayId('displayName') //
      .selectionStrategy(defaultSelectionStrategy) //
      .disableOrderBy() //
      .enableReadOnly(this.canedit) //
      .build();
  }

  selectedChanged(newValue) {
    if (newValue && this.observeChannelsChanges) {
      this.disableChannelsBasedOnRole();
      this._setupConfig();
    }
  }

  roleChanged(newValue) {
    if (newValue) {
      this.disableChannelsBasedOnRole();
      this._setupConfig();
    }
  }

  disableChannelsBasedOnRole() {
    if (!this.channels) {
      return;
    }

    for (let item of this.channels) {
      item.isSelected = false;

      if (this._isAgent() || this.ignoreRole) {
        item.isDisabled = false;
        continue;
      }
      if (item.channelName === 'EMAIL' || item.channelName === 'SMS' || item.channelName === 'CHAT' || item.channelName === 'TICKET') {
        item.isDisabled = true;
      }
    }
  }

  _isAgent() {
    return this.role && this.role === 'AGENT' || this.role === 'Agent';
  }

  select(event) {
    new Event(this.element, 'select', event);
  }

  get title() {
    return (this.titleoverride) ? this.titleoverride : 'Select Channel' + (this.multiselect ? 's' : '');
  }

  get itemsPerRow() {
    return this.itemsperrow || 6;
  }

  _defaultSelectionStrategy(item) {
    let inTrial = false;
    const account = this.sessionStore.get.account;
    if (account) {
      inTrial = account.isTrialAccount;
    }

    if (!this.multiselect && item.channelName === 'INBOUND_CALL') {
      return true;
    } else if (this.multiselect) {
      if (!inTrial && (item.channelName === 'INBOUND_CALL' || item.channelName === 'OUTBOUND_CALL' || item.channelName === 'EMAIL')) {
        return true;
      } else if (inTrial && (item.channelName === 'INBOUND_CALL' || item.channelName === 'EMAIL')) {
        return true;
      }
    }
    return false;
  }

  _orderChannelsByType(channels) {
    let _channels = [];
    let hasChatChannel = false;

    for (let _channel of channels) {
      if (_channel.channelName === 'CHAT') {
        hasChatChannel = true;
      }
    }

    let sortStrategy = {
      'INBOUND_CALL': channel => {
        _channels[0] = channel;
      },
      'OUTBOUND_CALL': channel => {
        _channels[1] = channel;
      },
      EMAIL: channel => {
        _channels[2] = this.isofficetype ? _channels[2] : channel;
      },
      SMS: channel => {
        _channels[3] = this.isofficetype ? _channels[3] : channel;
      },
      'INSTANT_MESSAGE': channel => {
        _channels[4] = this.isofficetype ? _channels[4] : channel;
      },
      'CHAT': channel => {
        if (!this.isofficetype) {
          _channels[5] = channel;
        }
      },
      'TICKET': channel => {
        _channels[this.isofficetype ? 2 : (hasChatChannel ? 6 : 5)] = channel;
      }
    };

    channels.forEach(channel => {
      sortStrategy.hasOwnProperty(channel.channelName) && sortStrategy[channel.channelName](channel);
    });

    if (this.lockChannelType) {
      let _amendedChannel = null;
      channels.forEach(_channel => {
        if (_channel.channelName === this.lockChannelType) {
          _channel.isSelected = true;
          _amendedChannel = _channel;
        } else {
          _channel.isDisabled = true;
        }
      });
      new Event(this.element, 'select', {list: channels, item: _amendedChannel});
    }

    return _channels.filter((channel) => !!channel);
  }

  updateItems(data) {
    new Event(this.element, 'updateitems', data);
  }
}
