const PREFIXES = {
  TABS: 'tabs-',
  AUDIO: 'audio',
  INTERACTIONS: 'interactions-'
};

export const EVENT_ACTIONS = {
  INTERACTIONS_UPDATED: PREFIXES.INTERACTIONS + 'updated'
};

export const CUSTOM_EVENTS = {
  TAB_RESULT: PREFIXES.TABS + 'result',
  TAB_INCOMPLETE: PREFIXES.TABS + 'incomplete-state',
  TAB_COMPLETE: PREFIXES.TABS + 'complete-state',

  AUDIO_DRAGDROP_COMPLETE: PREFIXES.AUDIO + '-drag-drop-complete',
  AUDIO_SELECT_COMPLETE: PREFIXES.AUDIO + '-select-complete',
  AUDIO_FILE_VALID: PREFIXES.AUDIO + '-file-valid',
  AUDIO_PAYLOAD_AVAILABLE: PREFIXES.AUDIO + '-payload-available',
  TTS_PAYLOAD_AVAILABLE: PREFIXES.AUDIO + '-tts-payload-available'
};
