import {inject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {autoinject} from "aurelia-dependency-injection";
/*
 */
const logger = LogManager.getLogger('OrganisationInvitations');
/*
*/
@autoinject()
export class OrganisationInvitations {

  constructor(private router: Router) {
  }

  private configureRouter(config: any, router: Router): void {

    let routeMap = [
      {route: '',       name: 'listLdapGroups',   moduleId: PLATFORM.moduleName('./list/ldap-groups'), nav: false, title: 'LDAP Groups'},
      {route: 'create', name: 'createLdapGroup', moduleId: PLATFORM.moduleName('./create/ldap-group'),    nav: false, title: 'Create a new LDAP group'}
    ];

    config.map(routeMap);

    this.router = router;
  }

}
