import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

import { DataGridMapping } from '../../../../../../../components/templates/data-grid/data-grid';

const logger = LogManager.getLogger('InteractionTeamStats');
let viewElement = {};

@autoinject
export class InteractionAgentStats {

  public columnConfig = [
    DataGridMapping.from({
      'Agent Name': {
        value: (c) => c.name,
        align: 'left',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Date': {
        value: (c) => c.date,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Time': {
        value: (c) => c.time,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Quality Assessor Name': {
        value: (c) => c.assessor,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Form Name': {
        value: (c) => c.form,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Interaction ID': {
        value: (c) => c.interactionId,
        align: 'center',
        width: 220,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Channel': {
        value: (c) => c.channel,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Final Score': {
        value: (c) => `<span style="display: none!important;"><span class="">${c.score + '%'}</span></span>` + c.score + '%',
        align: 'center',
        width: 100,
        style: (c) => c.pass ? 'good-light' : 'bad-light',
        cssClass: 'u-position-relative square-component'
      }
    }),
    DataGridMapping.from({
      'Pass': {
        value: (c) => `<span style="display: none!important;"><span class="">${c.pass ? 'YES' : 'NO'}</span></span>` + (c.pass ? 'YES' : 'NO'),
        align: 'center',
        style: (c) => c.pass ? 'good-light' : 'bad-light',
        width: 100,
        cssClass: 'u-position-relative square-component'
      }
    }),
    DataGridMapping.from({
      'Group': {
        value: () => '',
        hidden: true
      }
    }),
    DataGridMapping.from({
      'View': {
        value: (c) => {
          return `
            <div
              class="list-item hover flex flex--justify-center"
              title="View"
              style="align-items: center;"
              onclick="this.dispatchEvent(new CustomEvent('view', { bubbles: true, detail: { id: '${c.assessmentId}', memberName: '${c.name}' } }))"
            >
              <div class="labels">
                <div class="o-z-icon o-z-icon--view o-z-icon--size-medium" style="margin-right: 0;"></div>
              </div>
            </div>
          `;
        },
        align: 'center',
        width: 50,
        cssClass: 'full-size',
        disableSorting: true
      }
    })
  ];
}
