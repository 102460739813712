import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';

import { RoutingGroupsService } from '../../../routing-groups-service';
import { DisplayTeamsModel } from '../../../../../../../organisation/member/teams/teams-model';
import { ArrayTools } from 'zailab.common';


@autoinject()
export class Tab2Teams {
  private model: { routingGroupId: string, data: any };
  public teams: DisplayTeamsModel[];

  constructor(
    private routingGroupsService: RoutingGroupsService
  ) {}

  public activate(bindingContext: { routingGroupId: string, data: any }): void {
    this.model = bindingContext;
    this.model.data = {
      selectedTeams: bindingContext.data.selectedTeams || []
    };
    this.retrieveTeams();
  }

  public retrieveTeams(): void {
    this.routingGroupsService
      .getRoutingGroupTeams(this.model.routingGroupId)
      .then((teams) => {
        this.teams = ArrayTools.sort(teams, 'name');

        if (this.model.data.selectedTeams) {
          this.teams.forEach(team => {
            let selectedTeam = this.model.data.selectedTeams.find(t => t.id === team.id);
            if (selectedTeam) {
              team.disabled = true;
            }
          });
        }
      });
  }

  public teamSelected(item: any): void {
    if (!item) {
      return;
    }
    let team = this.model.data.selectedTeams.find(t => t.id === item.id); 
    if (!team) {
      this.model.data.selectedTeams.push(item);
      item.disabled = true;
    }
  }

  public removeTeam(item: any): void {
    if (!item) {
      return;
    }
    this.model.data.selectedTeams.splice(this.model.data.selectedTeams.indexOf(item), 1);
    let team = this.teams.find(t => t.id === item.id);
    if (team) {
      team.disabled = false;
    }
  }
}
