import { computedFrom } from 'aurelia-binding';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { BusinessPartner } from '../../businesspartner/business-partner-model';
import { ContactCenter } from '../../contactcenter/contact-center-model';
import { BypassCos } from '../bypass-cos-model';
import { BypassCosService } from '../bypass-cos-service';

const logger = LogManager.getLogger('BypassBosAdd');

@autoinject()
export class BypassCosAdd {

  public bypassCos: BypassCos = new BypassCos();
  public businessPartners: BusinessPartner[] = [];
  public contactCenters: ContactCenter[] = [];
  public isProcessing: boolean = false;

  private validation: ValidationController;

  constructor(
    private bypassCosService: BypassCosService,
    private router: Router,
    readonly validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public attached(): void {
    this.initValidationRules();
  }

  public save(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  public cancel(): void {
    this.router.navigate('list');
  }

  @computedFrom('bypassCos.businessPartner')
  public get businessPartnerContactCenters(): ContactCenter[] {
    return this.contactCenters.filter((contactCenter) => contactCenter.businessPartnerId === this.bypassCos.businessPartner);
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((bypassCos: BypassCos) => bypassCos.name).required()
      .on(this.bypassCos);
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      this.persist();
    }
  }

  private persist(): void {
    this.isProcessing = true;
    this.bypassCosService.insert(this.bypassCos)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }
}