import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation, SearchTools } from 'zailab.common';
import { DoNotContactListImportDialog } from './donotcontact-list-import-dialog';
import { DoNotContactForm, DoNotContactListItem } from './donotcontact-model';
import { DoNotContactService } from './donotcontact-service';

const logger = LogManager.getLogger('DoNotContactList');

@autoinject
export class DoNotContactList {

  public ready: boolean = false;
  public removingAll: boolean = false;
  public doNotContactFormError = '';
  public doNotContactForm = new DoNotContactForm();
  public doNotContactList: DoNotContactListItem[] = [];
  public showSearch = false;
  public searchTerm = '';

  private validation: ValidationController;

  constructor(
    private dialogService: DialogService,
    private doNotContactService: DoNotContactService,
    private events: EventAggregator,
    validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public attached(): Promise<any> {
    this.initValidationRules();
    return this.retrieve();
  }

  private retrieve(): Promise<any> {
    this.ready = false;
    return this.doNotContactService.retrieveAll().then((data) => {
      this.doNotContactList = data;
      this.ready = true;
    }).catch(() => this.ready = true);
  }

  public cancel(): void {
    this.doNotContactFormError = '';
    this.doNotContactForm.reset();
    this.events.publish('PHONE.NUMBER.RESET');
  }

  public save(): void {
    this.doNotContactFormError = '';
    this.events.publish('PHONE.NUMBER.VALIDATE');
    this.validation.validate().then((results) => {
      if (results.valid && this.doNotContactForm.isValidNumber()) {
        const doNotContactValue = this.doNotContactForm.getDoNotContactValue();
        const temp = this.doNotContactList.filter((item) => item.doNotContactValue === doNotContactValue);
        if (temp.length > 0) {
          this.doNotContactFormError = `${this.doNotContactForm.isTypeNumber() ? 'Number' : 'Email'} already exists in the do not contact list`;
        } else {
          this.persist();
        }
      }
    });
  }

  public remove(doNotContactListItem: DoNotContactListItem): Promise<void> {
    if (!doNotContactListItem || !doNotContactListItem.doNotContactId) {
      return;
    }
    this.ready = false;
    return this.doNotContactService.delete(doNotContactListItem.doNotContactId)
      .then(() => this.handleRemoveSuccess(doNotContactListItem))
      .catch((error: Error) => this.handleError(error));
  }

  public import(): void {
    const model = {};
    this.dialogService.open({ viewModel: DoNotContactListImportDialog, model }).whenClosed((response: any) => {
      this.retrieve();
    });
  }

  public toggleSearch(): void {
    this.showSearch = !this.showSearch;
    this.searchTerm = '';
  }

  public partialMatch(searchExpression: any, value: any, searchParams: any): boolean {
    for (const searchParam of searchParams) {
      if (!value[searchParam]) {
        continue;
      }
      if (SearchTools.partialMatch(value[searchParam], searchExpression)) {
        return true;
      }
    }
    return false;
  }

  private persist(): void {
    const doNotContactListItem = this.doNotContactForm.toDoNotContactListItem();
    this.ready = false;
    this.doNotContactService.create(doNotContactListItem)
      .then(() => this.handleSaveSuccess(doNotContactListItem))
      .catch((error: Error) => this.handleError(error));
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  private handleRemoveSuccess(doNotContactListItem: DoNotContactListItem): void {
    this.ready = true;
    const index = this.doNotContactList.indexOf(doNotContactListItem);
    if (!this.removingAll) {
      this.doNotContactList.splice(index, 1);
    }
  }

  private handleSaveSuccess(doNotContactListItem: DoNotContactListItem): void {
    this.ready = true;
    this.doNotContactList.push(doNotContactListItem);
    this.cancel();
    this.smoothScrollToBottom();
  }

  private handleError(error: Error): void {
    this.ready = true;
    logger.error('handleError :: error=', error)
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((doNotContactForm: DoNotContactForm) => doNotContactForm.doNotContactName).matches(/^([a-zA-Z0-9'_ \\-]+)+$/).withMessage('Must only contain valid characters.')
      .ensure((doNotContactForm: DoNotContactForm) => doNotContactForm.doNotContactEmail).required().then().email()
      .on(this.doNotContactForm);
  }
}