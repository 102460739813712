import {LogManager} from 'aurelia-framework';
/**/
import {ServiceModel} from 'zailab.common';
import {BaseModel} from 'zailab.abstract';
/**/
import {GridCellModel} from '../../grid/models/grid-cell-model';
import {ConnectorModel} from '../../connector/models/connector-model';
import {BusinessPartnerModel} from "../../work-type/models/business-partner-model";
import {ContactCenterModel} from "../../work-type/models/contact-center-model";
/**/
const logger = LogManager.getLogger('WorkTypeModel');
/**/
export class WorkTypeModel extends BaseModel {

    id: string = null;
    templateId: string = null;
    name: string = null;
    assignedToFlow: boolean = null;
    businessValue: number = null;
    channel: string = null;
    roles: Array<any> = [];
    service: ServiceModel = null;
    sites: Array<any> = [];
    skillGroups: Array<any> = [];
    targetResponse: number = null;
    waitTime: number = null;
    isSelected: boolean = null;
    isPlaceholder: boolean = null;
    businessPartner: BusinessPartnerModel = null;
    contactCentre: ContactCenterModel = null;

    constructor(properties: object) {
        super();
        super.mapProperties(properties);
    }

    public resetSelection(): void {
        this.isSelected = false;
    }

    public toggleSelection(): void {
        this.isSelected = !this.isSelected;
    }
}