import { inject, LogManager, Factory } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-http-client';
/*
 */
import {
  EncryptTools,
  USER_SESSSION_ACTIONS,
  SESSION_EVENTS,
  SessionStore,
} from 'zailab.common';
import { CommandFactory, UrlFactory } from 'zailab.common';
import { UserRegistrationSettings } from '../../registration/user-registration-settings';
/*
 */
const logger = LogManager.getLogger('LoginService');

/*
 */
@inject(
  SessionStore,
  EventAggregator,
  HttpClient,
  Factory.of(CommandFactory),
  UrlFactory
)
export class LoginService {
  updateSubscription;

  constructor(
    sessionStore,
    eventAggregator,
    httpClient,
    commandFactory,
    urlFactory
  ) {
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
    this.httpClient = httpClient;
    this.commandFactory = commandFactory;
    this.urlFactory = urlFactory;
  }

  authenticateWithAccessCode(accessCode) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/user/passports/authenticate-with-access-code')
        .asPut()
        .withContent({ accessCode })
        .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
        .send()
        .then(
          (response) => resolve(response),
          (error) => reject(error)
        );
    });
  }

  /**
   * Retrieves login user
   * @returns Promise that resolves a user object.
   * from collection 'loginView'
   * from 'query-service-passport'
   * @param {string} identity the email of the user
   * @param {string} password the password of the user
   * @throws 503: Service Not Available
   * */
  submitLogin(identity, password) {
    const ENCRYPTED_PASSWORD = EncryptTools.encrypt(password);
    return new Promise((resolve, reject) => {
      let params = { email: identity, password: ENCRYPTED_PASSWORD };
      this.httpClient
        .createRequest('v1/user/passports/authenticate')
        .asPut()
        .withContent(params)
        .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
        .send()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  setAuthHeader() {
    this.httpClient.configure((req) => {
      req.withHeader(
        'Authorization',
        'Bearer ' + this.sessionStore.get.user.token
      );
    });
  }

  /**
   * Report that login has succeeded
   * @fires ReportLoginSucceededCommand
   * to 'command-service-passport'
   * @param {string} passportId of user logging in
   * */
  reportLogin(email, passportId, bearerToken) {
    let params = { email };
    this.httpClient
      .createRequest(`v1/user/passports/${passportId}/login`)
      .asPut()
      .withContent(params)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  /**
   * Retrieves a list of channels
   * @returns Promise that resolves a list of channel object.
   * from collection 'displayChannelsView'
   * from 'query-service-organisation'
   * @param {string} organisationId the organisationId of the organisation
   * @throws 503: Service Not Available
   * */
  displayChannelsView(organisationId) {
    const sendRequest = () => {
      this._createRequest(
        'v1/organisation/me/channels',
        null,
        (response) => {
          if (this.sessionStore.get.user) {
            this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, {
              hasSMSChannel: false,
              hasEmailChannel: false,
              hasOutboundChannel: false,
            });
          }

          if (response) {
            for (let channel of response) {
              if (channel === 'SMS') {
                this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, {
                  hasSMSChannel: true,
                });
              }
              if (channel === 'EMAIL' || channel === 'Email') {
                this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, {
                  hasEmailChannel: true,
                });
              }
              if (
                channel === 'OUTBOUND_CALL' ||
                channel === 'OUTBOUND CALL' ||
                channel === 'Outbound Call'
              ) {
                this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, {
                  hasOutboundChannel: true,
                });
              }
            }
          }
        },
        (error) => {
          logger.info(
            ' an error occurred on displayChannelsView query ',
            error
          );
        }
      );
    };

    if (
      this.sessionStore.get.organisation &&
      this.sessionStore.get.organisation.organisationId
    ) {
      sendRequest();
    } else if (!this.updateSubscription) {
      this.updateSubscription = this.eventAggregator.subscribe(
        SESSION_EVENTS.UPDATED.ORGANISATION,
        () => {
          this.updateSubscription.dispose();
          this.displayChannelsView(organisationId);
        }
      );
    }
  }

  getLoginPath(_path) {
    return new Promise((resolve, reject) => {
      let params = {
        path: _path,
        enabled: true,
      };
      this.httpClient
        .createRequest('v1/organisation/organisations/login')
        .asGet()
        .withParams(params)
        .withHeader('Authorization', UserRegistrationSettings.ANONYMOUS_TOKEN)
        .send()
        .then(
          (response) => {
            if (response.length === 0 || response.status === false) {
              reject();
            } else {
              resolve(response);
            }
          },
          (error) => {
            reject();
          }
        );
    });
  }

  /**
   * Retrieves the organisation`s information
   * @returns Promise that resolves am=n organisation object.
   * from collection 'displayOrganisationView'
   * from 'query-service-organisation'
   * @param {string} organisationId the organisationId of the organisation
   * @throws 503: Service Not Available
   * */
  displayOrganisationView(organisationId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/organisations/me/information')
        .asGet()
        .withHeader(
          'Authorization',
          'Bearer ' + this.sessionStore.get.user.token
        )
        .send()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * @private
   * */
  _createRequest(uri, params, callback, errorCallback) {
    this.httpClient
      .createRequest(uri)
      .asGet()
      .withParams(params)
      .withHeader('Authorization', 'Bearer ' + this.sessionStore.get.user.token)
      .send()
      .then(
        (response) => {
          callback(response);
        },
        (error) => {
          errorCallback(error);
        }
      );
  }

  retrieveAccessToken() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('/v1/user/passports/refresh-access-code')
        .asPut()
        .withContent({})
        .send()
        .then((response) => resolve(response.accessCode))
        .catch((error) => reject(error));
    });
  }
}
