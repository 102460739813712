import {Factory, inject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {CommandFactory, OplogService} from 'zailab.common';

import OrganisationModel from './organisation-model';

const logger = LogManager.getLogger('OrganisationService');

@inject(HttpClient, Factory.of(CommandFactory), OplogService)
export default class {
  constructor(httpClient, commandFactory, oplogService) {
    this.httpClient = httpClient;
    this.commandFactory = commandFactory;
    this.oplog = oplogService;
  }

  retrieveOrganisationInfo() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/organisations/me/information')
        .asGet()
        .send()
        .then(response => {
          resolve(_modelEntity(response.organisation));
        }, error => {
          reject(error);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  initialiseOrganisationInformationOplog(organisationId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('organisation-projector.displayOrganisationView'));
    });
  }

  updateProfilePicture(base64, organisationId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('media/images/' + organisationId)
        .asPut()
        .withContent(base64)
        .send()
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject();
        });
    });
  }

  updateLdapConnectionInfo(ldap, organisationId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/organisations/${organisationId}/ldapConnector`)
        .asPut()
        .withContent(ldap)
        .send()
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject();
        });
    });
  }



  getOrgNameByEmail(_email) {
    return new Promise((resolve, reject) => {

      let params = {email: _email};
      this.httpClient.createRequest('v1/user/organisation-registrations')
        .asGet()
        .withParams(params)
        .send()
        .then((response) => {

          if (Object.keys(response).length === 0 || response.status === false) {
            reject();
          } else {
            resolve(response);
          }
        }, (error) => {
          reject();
        });
    });
  }

  getLoginPath(_path) {
    return new Promise((resolve, reject) => {
      let params = {path: _path};
      this.httpClient.createRequest('v1/organisation/organisations/login')
        .asGet()
        .withParams(params)
        .send()
        .then((response) => {
          if (response.length === 0 || response.status === false) {
            reject();
          } else {
            resolve(response);
          }
        }, (error) => {
          reject();
        });
    });
  }

  changeOrganisationName(organisationId, organisationName) {
    let _putCommand = JSON.stringify({
      name: organisationName, website: '', description: ''
    });

    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/organisations/${organisationId}/information`)
        .withHeader('Content-Type', 'application/json;charset=UTF-8')
        .asPut()
        .withContent(_putCommand)
        .send()
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(new Error(error));
        });
    });
  }

  setAuthenticationConfiguration(organisationId, samlEnabled, path, metadataUrl, idPRedirectUrl) {
    let _putCommand = JSON.stringify({
      samlEnabled,
      path,
      metadataUrl,
      idPRedirectUrl
    });

    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/organisations/${organisationId}/authentication`)
        .withHeader('Content-Type', 'application/json;charset=UTF-8')
        .asPut()
        .withContent(_putCommand)
        .send()
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(new Error(error));
        });
    });
  }

  getWorkforceManagement() {
    return this.httpClient
      .createRequest(`v1/organisation/me/workforce-management`)
      .asGet()
      .send();
  }
  


  setWorkforceManagementPlatformApi(organisationId, workforceManagement) {
    let payload = {
      verintApiHost: workforceManagement.verintApiHost,
      verintApiToken: workforceManagement.verintApiToken,
      verintApiOrganisationId: workforceManagement.verintApiOrganisationId,
      verintRoleMappingAdministrator: workforceManagement.verintRoleMappingAdministrator,
      verintRoleMappingTeamLeader: workforceManagement.verintRoleMappingTeamLeader,
      verintRoleMappingAgent: workforceManagement.verintRoleMappingAgent,
      verintRoleMappingQA: workforceManagement.verintRoleMappingQA,
      verintRoleMappingQAManager: workforceManagement.verintRoleMappingQAManager,
      verintRoleMappingCampaignManager: workforceManagement.verintRoleMappingCampaignManager,
      verintRoleMappingOfficeEmployee: workforceManagement.verintRoleMappingOfficeEmployee,
      verintScopeMappingAdministrator: workforceManagement.verintScopeMappingAdministrator,
      verintScopeMappingTeamLeader: workforceManagement.verintScopeMappingTeamLeader,
      verintScopeMappingAgent: workforceManagement.verintScopeMappingAgent,
      verintScopeMappingQA: workforceManagement.verintScopeMappingQA,
      verintScopeMappingQAManager: workforceManagement.verintScopeMappingQAManager,
      verintScopeMappingCampaignManager: workforceManagement.verintScopeMappingCampaignManager,
      verintScopeMappingOfficeEmployee: workforceManagement.verintScopeMappingOfficeEmployee,
      verintOrganizationSuffix: workforceManagement.verintOrganizationSuffix,
      verintDataSourceMapping: workforceManagement.verintDataSourceMapping,
      verintEmployeeSyncEnabled: workforceManagement.verintEmployeeSyncEnabled
    }

    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/workforce-management/${workforceManagement.workforceManagementId}/settings/platform-api`)
      .asPut()
      .withContent(payload)
      .send();
  }

  setWorkforceManagementSFTPAdapter(organisationId, workforceManagement) {
    let payload = {
      verintEnabled: workforceManagement.verintEnabled,
      verintInboundCallStatsEnabled: workforceManagement.verintInboundCallStatsEnabled,
      verintIncludeDetailedHandleTime: workforceManagement.verintIncludeDetailedHandleTime,
      verintInboundChatStatsEnabled: workforceManagement.verintInboundChatStatsEnabled,
      verintOutboundCallStatsEnabled: workforceManagement.verintOutboundCallStatsEnabled,
      verintTimeMonitoringEnabled: workforceManagement.verintTimeMonitoringEnabled,
      verintRealtimeTimeMonitoringEnabled: workforceManagement.verintRealtimeTimeMonitoringEnabled,
      verintRealtimeTimeMonitoringApiUrl: workforceManagement.verintRealtimeTimeMonitoringApiUrl,
      verintRealtimeTimeMonitoringApiTenantId: workforceManagement.verintRealtimeTimeMonitoringApiTenantId,
      verintRealtimeTimeMonitoringApiClientId: workforceManagement.verintRealtimeTimeMonitoringApiClientId,
      verintRealtimeTimeMonitoringApiClientSecret: workforceManagement.verintRealtimeTimeMonitoringApiClientSecret,
      verintAbandonedOutcomes: workforceManagement.verintAbandonedOutcomes,
      verintSftpHost: workforceManagement.verintSftpHost,
      verintSftpPort: workforceManagement.verintSftpPort,
      verintSftpUsername: workforceManagement.verintSftpUsername,
      verintSftpPassword: workforceManagement.verintSftpPassword,
      verintSftpPublicKey: workforceManagement.verintSftpPublicKey,
      verintSftpPrivateKey: workforceManagement.verintSftpPrivateKey,
      verintSftpPassphrase: workforceManagement.verintSftpPassphrase,
      verintForecastingEnabled: workforceManagement.verintForecastingEnabled,
      verintForecastingRange: workforceManagement.verintForecastingRange,
      activityConfigurationMapping: workforceManagement.activityConfigurationMapping
    };

    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/workforce-management/${workforceManagement.workforceManagementId}/settings/sftp-adapter`)
      .asPut()
      .withContent(payload)
      .send();
  }

  setWorkforceManagementRealtimeAdherence(organisationId, workforceManagement) {
    let payload = {
      verintApiEnabled: workforceManagement.verintRealtimeTimeMonitoringEnabled,
      verintApiUrl: workforceManagement.verintRealtimeTimeMonitoringApiUrl,
      tenantId: workforceManagement.verintRealtimeTimeMonitoringApiTenantId,
      clientId: workforceManagement.verintRealtimeTimeMonitoringApiClientId,
      clientSecret: workforceManagement.verintRealtimeTimeMonitoringApiClientSecret,
      activityConfigurationMapping: workforceManagement.activityConfigurationMapping,
      scope: workforceManagement.verintRealtimeTimeMonitoringApiScope
    };

    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/workforce-management/${workforceManagement.workforceManagementId}/settings/realtime-adherence-adapter`)
      .asPut()
      .withContent(payload)
      .send();
  }




  
  getWorkforceManagementWorktypeConfig(workforceManagementId) {
    return this.httpClient
      .createRequest(`v1/organisation/me/workforce-management/${workforceManagementId}/settings/sftp-adapter/work-types`)
      .asGet()
      .send();
  }
  
  setWorkforceManagementWorktypeConfig(workforceManagementId, workforceManagement) {
    return this.httpClient
      .createRequest(`v1/organisation/me/workforce-management/${workforceManagementId}/settings/sftp-adapter/work-types`)
      .asPut()
      .withContent(workforceManagement)
      .send();
  }

  setSpeechAnalytics(organisationId, workforceManagementId, verintSpeechInteractionAnalyticsConfiguration) {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/workforce-management/${workforceManagementId}/settings/speech-analytics`)
      .asPut()
      .withContent(verintSpeechInteractionAnalyticsConfiguration)
      .send();
  }

  setTextAnalytics(organisationId, workforceManagementId, verintTextInteractionAnalyticsConfiguration) {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/workforce-management/${workforceManagementId}/settings/text-analytics`)
      .asPut()
      .withContent(verintTextInteractionAnalyticsConfiguration)
      .send();
  }


  setLocation(timezone) {

    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/organisation/location')
        .asPut()
        .withContent({timezone})
        .send()
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(new Error(error));
        });
    });
  }
}

function _modelCollection(response) {
  let list = [];
  response.forEach(item => {
    list.push(_modelEntity(item));
  });
  return list;
}

function _modelEntity(item) {
  return new OrganisationModel(item);
}
