import {inject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/*
 */
const logger = LogManager.getLogger('ExclusionListService');
/*
 */
@inject(HttpClient)
export class ExclusionListService {

  exclusionList = [];

  constructor(httpClient) {

    this.httpClient = httpClient;
  }

  /**
   * Retrieves the list of excluded numbers for the organisation.
   * */
  retrieveExclusionList() {

    createRequest(this.httpClient, 'v1/organisation/organisations/me/exclusions', {}, (response) => {

      if (response.exclusions && response.exclusions.constructor === Array) {
        this.exclusionList = response.exclusions;
      }

    }, (error) => {});

  }

  /**
   * Checks whether a given number is in the exclusion list.
   * */
  isExcluded(number) {

    if (_hasExclusions(this.exclusionList)) {

      for(let exclusion of this.exclusionList){

        if(exclusion.type !== null && exclusion.type === "PHONE_NUMBER") {

          if (number === exclusion.formattedItem) {
            return true;
          }

        }

      }
    }

    return false;
  }

}
/*
 */
function createRequest(httpClient, uri, params, callback, errorCallback) {

  httpClient.createRequest(uri)
    .asGet()
    .withParams(params)
    .send().then(
    (response) => callback(response),
    (error) => errorCallback(error)
  );
}

function _hasExclusions(exclusionList) {
  return exclusionList !== null && exclusionList.constructor === Array && exclusionList.length > 0;
}