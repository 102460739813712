import { autoinject, bindable, LogManager, PLATFORM } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
/**/
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
/**/
const logger = new LogManager.getLogger('ApiCallDialog');
/**/
@autoinject
export class ApiCallDialog {

  @bindable private model: any;
  private closeCallback: (data: any) => void;

  public steps: object[];
  public isAddingContent: boolean = false;

  private nodeData: any;
  private nodeConfiguration: ZNodeConfig;
  public isDialog: boolean;

  constructor(
    private dialogController: DialogController
  ) { }

  public activate(_config: ZNodeConfig): void {
    this.isDialog = _config.isDialog;
    if (this.isDialog === false) {
      this.model = _config;
      this.nodeData = { properties: _config.properties }
      this.closeCallback = _config.closeCallback
    } else {
      this.nodeConfiguration = _config;
      this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition));
    }
    this.initSteps();

  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public complete(wizardData: any): void {
    let properties: any = wizardData.detail;
    if (this.isDialog === false) {
      this.closeCallback && this.closeCallback({ ... properties });
    } else {
      let updatedNode: NodeModel = this.mapNodeProperties(properties);
      this.dialogController.ok(updatedNode);
    }
  }

  public log(...args: any): void {
    logger.debug('🔍', ...args);
  }

  private initSteps(): void {
    const data = {
      ...this.nodeData.properties,
      maxCallTimeout: this.isDialog === false,
      version: this.nodeData.version
    };

    this.steps = [{
      name: 'Name',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/api-call/steps/name/name'),
      active: true,
      hasAttributes: true,
      completed: true,
      data
    }, {
      name: 'Request',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/api-call/steps/request/request'),
      hasAttributes: true,
      completed: true,
      data
    }, {
      name: 'Response',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/api-call/steps/response/response'),
      hasAttributes: true,
      completed: true,
      data
    }];
  }

  private mapNodeProperties(properties: any): NodeModel {

    this.nodeData.properties.name = properties.name;
    this.nodeData.properties.timeout = `${properties.timeout}`;
    this.nodeData.properties.url = properties.url;
    this.nodeData.properties.method = properties.method;
    this.nodeData.properties.requestHeaders = properties.requestHeaders;
    this.nodeData.properties.bodyText = properties.bodyText;
    this.nodeData.properties.outputVariables = properties.outputVariables;
    this.nodeData.properties.responseType = properties.responseType;
    this.nodeData.properties.isFormData = properties.isFormData;
    this.nodeData.properties.formData = properties.formData;

    let finalConnections = this.mapConnectors(this.nodeData.connections);

    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.connections = finalConnections;

    this.nodeData.properties.isDefined = this.isDefined(properties);
    return new NodeModel(this.nodeData);
  }

  private isDefined(properties: any): boolean {
    if (!properties.name || (!properties.timeout && properties.timeout !== 0) || !properties.url || !properties.method) {
      return false;
    }
    return true;
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {

    let connections: Array<ConnectorModel> = [];
    let index = 0;
    let nodeID = this.nodeData.id;

    let existingDefaultConnection = this.findConnectionByName(_existingConnections, 'default');
    connections.push(new ConnectorModel({
      name: 'default',
      dest: typeof existingDefaultConnection !== 'undefined' ? existingDefaultConnection.dest : null,
      source: { connectorIndex: index, nodeID },
      customExtensions: null
    }));

    index++;

    let existingErrorConnection = this.findConnectionByName(_existingConnections, 'error');
    connections.push(new ConnectorModel({
      name: 'error',
      dest: typeof existingErrorConnection !== 'undefined' ? existingErrorConnection.dest : null,
      source: { connectorIndex: index, nodeID: this.nodeData.id },
      customExtensions: null
    }));

    return connections;
  }

  private findConnectionByName(existingConnections: Array<ConnectorModel>, name: string): ConnectorModel {
    for(const connection of existingConnections) {
      if (connection.name === name) {
        return connection;
      }
    }
  }
}

