import { LogManager, autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';
import { v4 as uuidv4 } from 'uuid';
/**/
import { InteractionFlowService } from './interaction-flow-service';
import { InteractionFlowModel } from './models/interaction-flow-model';
import { InteractionFlowDefinitionModel } from './models/interaction-flow-definition-model';
import {
  InteractionConfig,
  InteractionConfigStrategy,
} from './interaction-config-strategy';
import { NodeModel } from '../../../../components/organisms/node/models/node-model';
import { ConnectorModel } from '../../../../components/organisms/connector/models/connector-model';
import { SessionStore } from '../../../../zailab.common';
/**/
const logger = LogManager.getLogger('InteractionFlow');
const ERROR_MESSAGES = {
  NODE_POSITION_CONFLICT:
    'Please move existing nodes to create space for new ones. ',
};

/**/
@autoinject
export class InteractionFlow {
  private session: SessionStore;
  private interactionFlowService: InteractionFlowService;
  private eventAggregator: EventAggregator;
  private bindingSignaler: BindingSignaler;

  public isProcessing: boolean;
  public isDraft: boolean;
  public definition: InteractionFlowDefinitionModel;
  public interactionFlowName: string;
  public interactionFlowType: string;
  public interactionFlowChannel: string;
  public interactionFlowId: string;
  public interactionFlowConfig: InteractionConfig;
  public gridSize: { columns: number; rows: number };
  public additionalData = {};
  public lastAutoSaveTimestamp: number = 0;
  public version: number;

  private defaultGridSize: { columns: number; rows: number } = {
    columns: 13,
    rows: 5,
  };
  private nodesToShowAllOutputConnectors: Array<string> = [
    'onChat',
    'officeHours',
    'assistant',
  ];

  constructor(
    session: SessionStore,
    interactionFlowService: InteractionFlowService,
    eventAggregator: EventAggregator,
    bindingSignaler: BindingSignaler
  ) {
    this.session = session;
    this.interactionFlowService = interactionFlowService;
    this.eventAggregator = eventAggregator;
    this.bindingSignaler = bindingSignaler;
  }

  public retrieveInteractionFlow(flowId: string, version: number): void {
    this.isProcessing = true;

    this.interactionFlowService
      .retrieveInteractionFlow(flowId, version)
      .then(
        (interactionFlow) =>
          this.interactionFlowRetrieved(interactionFlow, flowId),
        this.reportError
      );
  }

  private interactionFlowRetrieved(
    interactionFlow: InteractionFlowModel,
    interactionFlowId: string
  ): void {
    this.definition = interactionFlow.definition;
    this.interactionFlowName = interactionFlow.interactionFlowName;
    this.interactionFlowType = interactionFlow.interactionFlowType;
    this.interactionFlowChannel = interactionFlow.channel;
    this.interactionFlowId = interactionFlowId;
    this.interactionFlowConfig = InteractionConfigStrategy.getConfigStrategy(
      interactionFlow.flowDescriptor
    );
    this.gridSize = interactionFlow.definition.gridSize || this.defaultGridSize;
    this.isProcessing = false;
    this.additionalData = interactionFlow.additionalData;

    let needsStartNode = interactionFlow.definition.nodes.length === 0;

    if (needsStartNode) {
      // Find start node from our config
      let startNode: string = this.interactionFlowConfig.defaultStartNode;
      interactionFlow.definition.nodes.push(this.generateNode(startNode));
    }

    this.isDraft = interactionFlow.definition.version
      ? interactionFlow.definition.version.startsWith('WIP')
      : false;
  }

  private generateNode(eventId: string): NodeModel {
    let config: InteractionConfig = this.interactionFlowConfig[eventId];
    let properties: object = config.defaultProperties;
    let name: string = config.name;
    let type: string = config.type;
    let id: string = uuidv4();
    let x: number = 0;
    let y: number = 0;

    let connections: Array<ConnectorModel> = [];

    const addConnection = (index: number) => {
      let defaultConnection: ConnectorModel = new ConnectorModel({
        source: { connectorIndex: index, nodeID: id },
      });
      defaultConnection.name =
        config.outputConnectors && config.outputConnectors[index]
          ? config.outputConnectors[index].name
          : '';
      connections.push(defaultConnection);
    };

    if (this.nodesToShowAllOutputConnectors.includes(eventId)) {
      config.outputConnectors.forEach((connector, index) => {
        addConnection(index);
      });
    } else {
      addConnection(0);
    }

    return new NodeModel({
      config,
      properties,
      name,
      id,
      x,
      y,
      eventId,
      connections,
      type,
    });
  }

  private reportError(msg: any): void {
    this.isProcessing = false;

    logger.error('errorMessage >', msg);
  }

  public addNode(
    parentNode: NodeModel,
    parentConnectorIndex: number,
    eventId: string
  ): Promise<any> {
    let newNode: NodeModel = this.generateNode(eventId);
    let isCoordinatesPopulated: boolean;

    newNode.x = parentNode.x + 1;
    newNode.y = parentNode.y + parentConnectorIndex;
    newNode.parentConnector = parentNode.connections[parentConnectorIndex];

    this.checkAndAddEmptyColumns(newNode);
    this.checkAndAddEmptyRows(newNode);

    isCoordinatesPopulated = this.isCoordinatesPopulated(newNode.x, newNode.y);

    return new Promise((resolve, reject) => {
      if (isCoordinatesPopulated) {
        reject(ERROR_MESSAGES.NODE_POSITION_CONFLICT);
        return;
      }

      this.definition.nodes.push(newNode);

      // TODO JE - find better fix for this timing issue
      setTimeout(
        () => this.addConnection(parentNode, parentConnectorIndex, newNode.id),
        50
      );

      resolve({});
    });
  }

  private checkAndAddEmptyColumns(newNode: NodeModel): void {
    if (newNode.x + 1 >= this.gridSize.columns) {
      this.gridSize.columns += 6;
    }
  }
  private checkAndAddEmptyRows(newNode: NodeModel): void {
    if (newNode.y + 1 >= this.gridSize.rows) {
      this.gridSize.rows += 6;
    }
  }

  private isCoordinatesPopulated(x: number, y: number): boolean {
    let nodeAtCoordinate: NodeModel = this.definition.nodes.find(
      (node) => node.x === x && node.y === y
    );
    let isPopulated: boolean = !!nodeAtCoordinate;

    return isPopulated;
  }

  private shiftNodes(columnIndex: number, rowIndex: number): void {
    while (this.definition.nodes.length > columnIndex) {
      this.definition.nodes[columnIndex].x++;
      columnIndex++;
    }
  }

  public addConnection(
    parentNode: NodeModel,
    parentConnectorIndex: number,
    destId: string
  ): void {
    let parentId: string = parentNode.id;
    let oldConnector: ConnectorModel =
      parentNode.connections[parentConnectorIndex];
    let newConnector: ConnectorModel = new ConnectorModel({
      name: oldConnector ? oldConnector.name : '',
      source: { connectorIndex: parentConnectorIndex, nodeID: parentId },
      dest: { connectorIndex: 0, nodeID: destId },
      customExtensions: oldConnector ? oldConnector.customExtensions : [],
    });

    let destNode: NodeModel = this.definition.nodes.find((node) => {
      return node.id === destId;
    });

    destNode.totalIncomingConnectors++;
    parentNode.connections.splice(parentConnectorIndex, 1, newConnector);

    this.updateNode(parentNode);
  }

  public updateNode(updatedNode: NodeModel): void {
    logger.info('InteractionFlow > updateNode: ', updatedNode);

    let nodeIndex: number = 0;

    this.definition.nodes.forEach((node) => {
      if (node.id === updatedNode.id) {
        let node: NodeModel = this.definition.nodes[nodeIndex];
        node.properties = updatedNode.properties;
        node.connections = updatedNode.connections;
        node.outputConnectors = updatedNode.outputConnectors;
        node.inputConnectors = updatedNode.inputConnectors;
        node.x = updatedNode.x;
        node.y = updatedNode.y;
        node.rowsToMerge = updatedNode.outputConnectors
          ? updatedNode.outputConnectors.length
          : 0;

        this.definition.nodes[nodeIndex] = node;
      }

      nodeIndex++;
    });

    this.saveFlow();
  }

  public deleteNode(nodeId: string): void {
    let nodes: Array<NodeModel> = this.definition.nodes || [];
    let nodeIndex: number = 0;

    nodes.forEach((node) => {
      if (node.id === nodeId) {
        this.definition.nodes.splice(nodeIndex, 1);
      }

      nodeIndex++;
    });

    this.saveFlow();
  }

  public publishFlow(
    organisationId: string,
    versionFlow?: boolean
  ): Promise<void> {
    this.definition.gridSize = this.gridSize;
    return this.interactionFlowService.publishInteractionFlow(
      organisationId,
      this.interactionFlowId,
      this.definition,
      versionFlow
    );
  }

  public saveFlow(): void {
    if (this.version) {
      return;
    }
    this.isDraft = true;
    this.definition.version = 'WIP-' + this.session.get.user.memberId;
    this.definition.gridSize = this.gridSize;
    this.lastAutoSaveTimestamp = -1;
    this.interactionFlowService
      .saveIncompleteFlow(this.interactionFlowId, this.definition)
      .then(() => {
        setTimeout(() => {
          this.lastAutoSaveTimestamp = Date.now();
        }, 500);
      });
  }

  public deleteSavedFlow(): Promise<any> {
    return this.interactionFlowService.deleteSavedFlow(this.interactionFlowId);
  }

  public deselectConnectors(): void {
    this.definition.nodes.filter((node) => {
      node.connections.filter((connection) => {
        connection.isSelected = false;
      });
    });
  }

  public keyPress_delete(): void {
    this.definition.nodes.filter((node, nodeIndex) => {
      node.connections.filter((connection, connectorIndex, connections) => {
        if (connection.isSelected) {
          this.deleteConnection(connection);
        }
      });
    });
  }

  private deleteConnection(connection: ConnectorModel): void {
    let destNode: NodeModel = this.definition.nodes.find(
      (node) => node.id === connection.dest.nodeID
    );

    destNode.totalIncomingConnectors--;
    connection.dest = null;
    connection.isSelected = false;
    connection.isConnected = false;

    this.bindingSignaler.signal('update-connector-line-coordinates');

    this.saveFlow();
  }

  public destroy(): void {
    this.definition = null;
    this.interactionFlowName = null;
    this.interactionFlowType = null;
    this.lastAutoSaveTimestamp = 0;
  }
}
