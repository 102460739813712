import {LogManager, customElement, bindable, autoinject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {BindingEngine, computedFrom, Disposable} from 'aurelia-binding';
/**/
import {ConnectorModel} from '../connector/models/connector-model';
import {NodeModel} from './models/node-model';
import {LineModel} from '../../atoms/line/models/line-model';
import {ZNodeDraggable} from './z-node-draggable';
import {DraggableService} from '../../../_common/services/draggable.service';
/**/
const logger = LogManager.getLogger('ZNode');
/**/
@autoinject
@customElement('z-node')
export class ZNode extends ZNodeDraggable {

  public draggableService: DraggableService;
  private eventAggregator: EventAggregator;
  private bindingSignaler: BindingSignaler;
  private bindingEngine: BindingEngine;
  private subscriptions: Subscription[] = [];
  private isAttached: boolean;
  private showMenu: boolean = false;
  private nodeData: NodeModel;
  private autoCloseTimer: number = 0;
  private deferAutoClose: boolean = false;

  constructor(bindingSignaler: BindingSignaler, eventAggregator: EventAggregator, bindingEngine: BindingEngine, draggableService: DraggableService) {

    super(bindingSignaler, draggableService, eventAggregator);

    this.isAttached = false;
    this.bindingSignaler = bindingSignaler;
    this.bindingEngine = bindingEngine;
    this.eventAggregator = eventAggregator;
    this.draggableService = draggableService;
  }

  public activate(data: NodeModel): void {

    this.nodeData = data;

    // logger.info(`InteractionFlow > activating: ____________${this.nodeData.name} (node)____________ `);
    // logger.info(`InteractionFlow > ${this.nodeData.name} data: > `, this.nodeData);

    this.registerCoordinateUpdates(data);
  }

  public attached(): void {

    this.isAttached = true;
  }

  private get displayContent(): boolean {

    return this.isAttached;
  }

  private getLineCoordinates(connector: ConnectorModel, startConnector: HTMLElement): object {

    let destId = connector.dest ? connector.dest.nodeID : '';
    let endConnector: HTMLElement = document.getElementById(`inConnector-${destId}`);

    let default_coords: any = {left: 0, top: 0};

    let startConnector_coords: ClientRect = startConnector ? startConnector.getBoundingClientRect() : default_coords;
    let endConnector_coords: ClientRect = endConnector ? endConnector.getBoundingClientRect() : startConnector_coords;
    let isDragging: boolean = false;

    if (this.isDraggingConnector(connector)) {

      isDragging = true;
      endConnector_coords = this.mousePosition;
    }

    connector.isConnected = startConnector_coords === endConnector_coords || isDragging ? false : true;
    return this.mapCoordinates(startConnector_coords, endConnector_coords, connector.isConnected, isDragging);
  }

  private isDraggingConnector(connector: ConnectorModel): boolean {
    if (this.draggingConnector && connector.source.nodeID === this.draggableService.draggingData.nodeData.id) {
      return true;
    }
    return false;
  }

  private mapCoordinates(startConnector_coords: ClientRect, endConnector_coords: ClientRect, isConnected: boolean, isDragging: boolean): object {
    let coordinates: LineModel = new LineModel();
    coordinates.startX = 0;
    coordinates.startY = 0;
    coordinates.endX = isConnected || isDragging ? endConnector_coords.left - startConnector_coords.left : 20;
    coordinates.endY = isConnected || isDragging ? endConnector_coords.top - startConnector_coords.top : 0;
    return coordinates;
  }

  private registerCoordinateUpdates(data: NodeModel): void {
    let subscription_x: Disposable = this.bindingEngine
      .propertyObserver(this.nodeData, 'x')
      .subscribe(() => this.signalCoordinateUpdate());

    let subscription_y: Disposable = this.bindingEngine
      .propertyObserver(this.nodeData, 'y')
      .subscribe(() => this.signalCoordinateUpdate());

    this.subscriptions.push(subscription_x, subscription_y)
  }

  private signalCoordinateUpdate(connectorIndex?: number): void {

    let signal: string = `update-connector-line-coordinates`;

    if(connectorIndex || connectorIndex === 0) {
      signal += `-${connectorIndex}`;
    }

    this.bindingSignaler.signal(signal);
  }

  private toggleNodeConnectorConnected(isConnected: boolean): void {
  }

  public addNode(connector: ConnectorModel): void {
    let connectorIndex: number = connector.connectorIndex;
    let customExtensions: string[] = connector.customExtensions;

    this.eventAggregator.publish('node.add', {nodeData: this.nodeData, connectorIndex, customExtensions});
  }

  private toggleNodeMenu(): void {
    this.showMenu = !this.showMenu;
  }

  public deleteNode(): void {
    this.toggleNodeMenu();
    this.eventAggregator.publish('node.delete', this.nodeData);
  }

  public editNode(): void {
    this.toggleNodeMenu();
    this.eventAggregator.publish('node.edit', this.nodeData);
  }

  public viewNode(): void {
    this.toggleNodeMenu();
    this.eventAggregator.publish('node.view', this.nodeData);
  }

  public extRefNode(): void {
    this.toggleNodeMenu();
    this.eventAggregator.publish('node.extref', this.nodeData);
  }

  private startAutoCloseMenu(): void {
    if (this.showMenu && this.autoCloseTimer === 0) {
      this.autoCloseTimer = window.setInterval(() => {
        this.closeMenu();
      }, 500);
    }
  }

  private closeMenu(): void {
    if (!this.deferAutoClose) {
      this.showMenu = false;
      window.clearInterval(this.autoCloseTimer);
      this.autoCloseTimer = 0;
    }
  }

  private preventAutoClose(): void {
    this.deferAutoClose = true;
  }

  private allowAutoClose(): void {
    this.deferAutoClose = false;
  }

  get name(): string {

    let name = (this.nodeData.properties && this.nodeData.properties.name) ? this.nodeData.properties.name : this.nodeData.name

    return name;
  }

  private selectConnectorLine(connector: ConnectorModel): void {

    connector.isSelected = true;
  }

  public unbind(): void {

    // TODO JE - find better solution for this
    setTimeout(() => {
      this.signalCoordinateUpdate();
    }, 50);

    this.subscriptions.forEach(subscription => subscription.dispose());
  }

  public hideName(nodeData: any): boolean {
    if (
      nodeData.name === 'Start' ||
      nodeData.name === 'On Call' ||
      nodeData.name === 'On Message' ||
      nodeData.name === 'On Email' ||
      nodeData.name === 'On SMS' ||
      nodeData.name === 'Start Call Recording' ||
      nodeData.name === 'Stop Call Recording'
    ) {
      return true;
    }
    return false;
  }

  public doNothing(): void {}

  @computedFrom('nodeData.name', 'nodeData.properties.isDefined')
  public get isDefined(): boolean {
    if (this.nodeData.name === 'Start') {
      return true;
    }
    return this.nodeData.properties.isDefined;
  }

}
