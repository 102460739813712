import { LogManager, customElement, bindable, inject } from 'aurelia-framework';
/**/
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
/**/
const logger = LogManager.getLogger('ZScrollContainer');
/**/
@inject(Element)
@customElement('z-scroll-container')
export class ZScrollContainer {
  @bindable size;
  containerId = uuidv4();

  constructor(element) {
    this.element = element;
  }

  attached() {
    this._locateScrollPosition();
  }

  _locateScrollPosition() {
    let runOnce = this._runCheckOnceOnly();
    let tableContainer = this;
    let scrollingContainer = $('#' + this.containerId);
    let currentScrollPosition;
    let maxScrollContent;
    scrollingContainer.scroll(() => {
      currentScrollPosition = scrollingContainer.scrollTop() + scrollingContainer.innerHeight();
      maxScrollContent = scrollingContainer[0].scrollHeight - 50;
      if (currentScrollPosition > maxScrollContent) {
        runOnce(() => {
          tableContainer._isBottom();
        }, 300);
      }

      if (scrollingContainer.scrollTop() === 0) {
        runOnce(() => {
          tableContainer._isTop();
        }, 300);
      }
    });
  }

  _runCheckOnceOnly() {
    let delay = (function() {
      let timer = 0;
      return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();
    return delay;
  }

  _isBottom() {
    this.dispatch('scrollend', '');
  }

  _isTop() {
    this.dispatch('scrollstart', '');
  }

  dispatch(name, data) {
    this.element.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail: data
      })
    );
  }
}
