import { autoinject, bindable, customElement, LogManager, observable } from 'aurelia-framework';
import {
  ValidationControllerFactory,
  ValidationController,
  ValidationRules,
  validateTrigger,
  StandardValidator
} from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';
/**/
import { SessionStore, BootstrapFormRenderer, MESSAGE_EVENTS } from 'zailab.common';
import { ReminderService } from '../reminder-service';
import { ContactModel } from '../../../contact/contact-model';
import { IValidationObject, IDatesObject, IValidateResponse } from '../interfaces';
/**/
import moment from 'moment-timezone';
import { CONTACT_ACTIONS } from '../../../contact/contactcontroller/contact-controller-actions';
/**/
const logger = LogManager.getLogger('CreateReminder');

/**/
@customElement('z-reminder')
@autoinject()
export class CreateReminder {
  @bindable private contact: ContactModel;
  @bindable private conversationid: string;
  @observable private date: string;
  @observable private time: string;
  private memberId: string = null;
  private validation: IValidationObject;
  private message: string;
  private radix: number = 10;
  private confirmDelete: boolean = false;
  private processing: boolean = false;

  constructor(private eventAggregator: EventAggregator, private sessionStore: SessionStore, private validationControllerFactory: ValidationControllerFactory, private reminderService: ReminderService) {
    this.memberId = sessionStore.get.user.memberId;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.setInitialData();
  }

  private setInitialData(): void {
    const currentDate = new Date();
    this.setCurrentDate(currentDate);
    this.setCurrentTime(currentDate);
    this.message = '';
    this.confirmDelete = false;
    this.processing = false;
  }

  private setCurrentDate(currentDate: Date): void {
    const tomorrow = moment(currentDate).add(1, 'day');
    this.date = tomorrow.format('YYYY-MM-DD');
  }

  private setCurrentTime(currentDate: Date): void {
    let hours = moment(currentDate).format('HH');
    const minutes = parseInt(moment(currentDate).format('mm'), this.radix);

    const roundValue = 10;
    const spliceIndex = -2;
    const minutesPerHour = 60;
    let roundedMinimutes = Math.ceil(minutes / roundValue) * roundValue;

    if (roundedMinimutes === minutes) {
      roundedMinimutes += roundValue;
    }

    if (roundedMinimutes === minutesPerHour) {
      hours++;
      roundedMinimutes = 0;
    }
    this.time = hours + ':' + ('00' + roundedMinimutes).slice(spliceIndex);
  }

  public showMediumChannel(): void {
    if (this.contact.channelViewStrategy.size === 'small') {
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    }
  }

  private timeChanged(): void {
    this.onlyValidate();
  }

  private dateChanged(): void {
    this.onlyValidate();
  }

  private onlyValidate(): void {
    this.validate()
      .then(() => {
      })
      .catch(() => {
      });
  }

  private validate(): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      this.validation.validate().then((validation: IValidateResponse) => {
        if (!validation.valid) {
          reject();
          return;
        }
        resolve();
      });
    });
  }

  private attached(): void {
    this.setupValidation();
    this.setInitialData();
  }

  private setupValidation(): void {
    ValidationRules.customRule('futureDate', () => {
      if (this.date) {
        if (this.isToday() || this.isFutureDate()) {
          return true;
        }
      }
      return false;
    });

    ValidationRules.customRule('futureTime', () => {
      if (this.date) {
        if (this.isFutureDate()) {
          return true;
        }
        if (this.isToday() && this.isFutureTime()) {
          return true;
        }
      }
      return false;
    });

    const minMessageLength = 1;

    ValidationRules.ensure('date')
      .satisfiesRule('futureDate')
      .withMessage('Must be the current or a future date.')
      .ensure('time')
      .satisfiesRule('futureTime')
      .withMessage('Must be a future time.')
      .ensure('message')
      .minLength(minMessageLength)
      .withMessage('Please enter a message.')
      .on(this);
  }

  private isToday(): boolean {
    const dates = this.getFormattedDates();
    return dates.selected === dates.current;
  }

  private isFutureDate(): boolean {
    const dates = this.getFormattedDates();
    return dates.selected > dates.current;
  }

  private getFormattedDates(): IDatesObject {
    const currentDate = moment(Date.now());
    const formattedDate = currentDate.format('YYYY-MM-DD');
    const selectedDateFormatted = parseInt(this.date.split('-').join(''), this.radix);
    const currentDateFormatted = parseInt(formattedDate.split('-').join(''), this.radix);
    return { selected: selectedDateFormatted, current: currentDateFormatted };
  }

  private isFutureTime(): boolean {
    const currentDate = new Date();
    const currentTime = parseInt(moment(currentDate).format('HHmm'), this.radix);
    const formattedTime = parseInt(this.time.replace(':', ''), this.radix);
    return formattedTime > currentTime;
  }

  private toggleDeleteState(): void {
    this.confirmDelete = !this.confirmDelete;
  }

  private discardReminder(): void {
    this.setInitialData();
  }

  private saveReminder(): void {
    this.validate()
      .then(() => {
        this.processing = true;
        this.submitReminder();
      })
      .catch(() => {
        this.processing = false;
      });
  }

  private submitReminder(): void {
    let dueTimestamp = moment(this.date);
    let minutes = parseInt(this.time.split(':')[1], this.radix);
    let hours = parseInt(this.time.split(':')[0], this.radix);

    dueTimestamp.minute(minutes);
    dueTimestamp.hour(hours);
    dueTimestamp = dueTimestamp.format('x');

    this.eventAggregator.publish(CONTACT_ACTIONS.SUBMIT_REMINDER, {
      memberId: this.memberId,
      dueTimestamp,
      message: this.message,
      contactId: this.contact.contactId,
      conversationId: this.conversationid,
      radix: this.radix
    });

    this.setInitialData();


  }
}
