/**/
import {LogManager, customElement, bindable, inject} from 'aurelia-framework';
/**/
const logger = LogManager.getLogger('ZFileUpload');
/**/
@inject(Element)
@customElement('z-file-upload')
export class ZFileUpload {

  @bindable({attribute: 'file-name'}) fileName: string;

  constructor(private element: Element) {}

  public fileChanged(event: Event): void {
    let file: File = event.target.files[0];
    if(file) {
      this.fileName = file.name;
    }

    this.element.dispatchEvent(
      new CustomEvent('file-selected', {
        bubbles: true,
        detail: {file: file, fileName: this.fileName}
      })
    );
  }

}
