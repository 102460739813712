import { bindable, customElement, LogManager } from "aurelia-framework";
import { Event } from "zailab.common";
import { AgentScriptConnection, AgentScriptNode } from "./agent-script-designer-model";

const logger = LogManager.getLogger('AgentScriptCCCurrentStep');

@customElement('z-agent-script-cc-current-step')
export class AgentScriptCCCurrentStep {

  @bindable
  public node: AgentScriptNode;

  @bindable
  public conns: AgentScriptConnection[];

  @bindable
  public height: number = 168;

  public selectedOption: AgentScriptConnection;
  public selectedOptionName: string;

  constructor(
    private element: Element,
  ) { }

  public select(event: any): void {
    this.selectedOption = event.detail;
  }

  public next(): void {
    this.selectedOptionName = '';
    if (!this.selectedOption) {
      return;
    }
    new Event(this.element, 'next', this.selectedOption);
  }

  public showHistory(): void {
    new Event(this.element, 'show-history', {});
  }
}
