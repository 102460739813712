import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager, Factory} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {CommandFactory} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('AssignNumberDialogService');

/*
 * */
@inject(HttpClient, EventAggregator, Factory.of(CommandFactory))
export class AssignNumberDialogService {

  constructor(httpClient, eventAggregator, commandFactory) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
  }

  _retrieveAssignedNumbers(interactionFlowId, params) {
    return new Promise((resolve, reject) => {
      this._createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/assigned-target-resources`, params, (response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  retrieveAvailableOrganisationNumbers(organisationId, flowType) {
    return new Promise((resolve, reject) => {

      let params = {numberType: flowType === 'SMS' ? 'SMS' : 'VOICE'};

      this._createRequest('v1/finance/telephonenumbers/available-numbers-view', params, (response) => {

          response = response.telephoneNumbers ? response.telephoneNumbers : [];

          if (flowType === 'SMS') {
            resolve(response);
            return
          }

          this._createRequest('v1/finance/telephonenumbers/available-numbers-view', {numberType: 'TOLL_FREE'}, (tollFreeResponse) => {
              tollFreeResponse = tollFreeResponse.telephoneNumbers ? tollFreeResponse.telephoneNumbers : [];
              resolve(response.concat(tollFreeResponse));
            }, error => reject(error));

        }, error => reject(error));

    });
  }

  removeNumberFromInteractionFlow(interactionFlowId, organisationId, telephoneNumber, flowName, telephoneNumberId) {
    let commandPayload = {
      interactionFlowId: interactionFlowId,
      organisationId: organisationId,
      telephoneNumber: telephoneNumber
    };

    this.httpClient.createRequest(`v1/finance/telephonenumbers/${telephoneNumberId}/unassignfromflow`)
      .asPut()
      .withContent(commandPayload)
      .send();
  }

  addNumberToInteractionFlow(interactionFlowId, organisationId, telephoneNumber, flowName, telephoneNumberId, live) {
    let commandPayload = {
      telephoneNumber: telephoneNumber,
      interactionFlowId: interactionFlowId,
      organisationId: organisationId,
      flowName: flowName,
      live
    };
    this.httpClient.createRequest(`v1/finance/telephonenumbers/${telephoneNumberId}/assigntoflow`)
      .asPut()
      .withContent(commandPayload)
      .send();
  }

  /**
   * @private
   * */
  _createRequest(uri, params, callback, errorCallback) {
    this.httpClient.createRequest(uri)
      .asGet()
      .withParams(params)
      .send()
      .then(
        (response) => {
          callback(response);
        },
        (error) => {
          errorCallback(error);
        }
      );
  }
}
