import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { TimeAgoValueConverter } from '../../converters/time-ago';
import { DiagnosticsState } from './diagnostics-state';
import { BazDialog } from './foo/bar';

const logger = LogManager.getLogger('Diagnostics');

@autoinject()
export class Diagnostics {

  public ready: boolean = true;

  constructor(
    public readonly timeAgo: TimeAgoValueConverter,
    public readonly diagnosticsState: DiagnosticsState,
    public readonly dialogService: DialogService
  ) { }

  public get lastFailedTime(): string {
    if (this.diagnosticsState && this.diagnosticsState.lastFailedHttp && this.diagnosticsState.lastFailedHttp.requestTimestamp) {
      return this.timeAgo.toView(this.diagnosticsState.lastFailedHttp.requestTimestamp);
    }
    return '';
  }

  public fooBarBaz(event: any): void {
    logger.info('fooBarBaz :: event=', event);
    if (event.ctrlKey) {
      const model = {};
      this.dialogService.open({ viewModel: BazDialog, model }).whenClosed(response => {
        if (response.wasCancelled) {
          return;
        }
      });
    }
  }
}