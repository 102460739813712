import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/**
 */
import { SessionStore } from 'zailab.common';
import { WorkTypesService } from '../work-types-service';
import WorkTypeStatsModel from '../work-types-stats-model';
/**
 */
const logger = LogManager.getLogger('Work Types Widget');
/**
 */
@inject(Router, SessionStore, WorkTypesService)
export class WorkTypesDashboardWidget {

  actions = ['view', 'add'];
  inbound = 0;
  outbound = 0;
  email = 0;
  sms = 0;
  chat = 0;
  kiosk = 0;
  im = 0;
  ticket = 0;
  hasChat = false;
  hasIM = false;
  ready = false;

  constructor(router, sessionStore, workTypesService) {

    this.router = router;
    this.sessionStore = sessionStore;
    this.workTypesService = workTypesService;
  }

  activate() {
    this.retrieveWorkTypesStats();
  }

  detached() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  retrieveWorkTypesStats() {

    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.retrieveWorkTypesStats();
      }, 500);
      return;
    }

    this.workTypesService.retrieveWorkTypesStats().then((response) => {
      this._mapModel(response);
      this.ready = true;
    }, (error) => {
      logger.info('Could not retrieve Work Types Stats.', error);
    });

    this.workTypesService.initialiseStatsOplog(organisation.organisationId).then((oplog) => {
      this.oplog = oplog;

      this.oplog.on('insert', (response) => {
        this._mapModel(new WorkTypeStatsModel(response.taskTemplates));
      });

      this.oplog.on('update', (response) => {
        for (let index in response) {
          if (response.hasOwnProperty(index) && index.includes('taskTemplates')) {
            let key = (index.split('.')[1]).toLowerCase().split(' ')[0];
            this[key] = response[index].length;
            return;
          }
        }
      });
    });
  }

  _mapModel(response) {
    this.inbound = response['Inbound Call'] ? response['Inbound Call'].length : 0;
    this.outbound = response['Outbound Call'] ? response['Outbound Call'].length : 0;
    this.email = response.Email ? response.Email.length : 0;
    this.sms = response.SMS ? response.SMS.length : 0;
    this.chat = response['Chat'] ? response['Chat'].length : 0;
    this.im = response['Instant Message'] ? response['Instant Message'].length : 0;
    this.ticket = response['Ticket'] ? response['Ticket'].length : 0;
    this.kiosk = response.Kiosk ? response.Kiosk.length : 0;
    if(this.chat > 0) {
      this.hasChat = true;
    }
    this.hasIM = this.im > 0;
  }

  view() {
    this.router.navigate('worktypes');
  }

  add() {
    this.router.navigate('worktypes/create');
  }

}