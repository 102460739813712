import { autoinject, LogManager } from 'aurelia-framework';
import { ArrayTools, FormatTools, SessionStore } from "zailab.common";
import { PresenceService } from '../../../../user/passport/presence/presence-service';

const logger = LogManager.getLogger('Tab2PresenceMapping');

interface ITab2PresenceMapping {
  presenceMappings: { [key: string]: any };
}

interface Mapping {
  msTeams: string;
  zailab: string;
}

@autoinject
export class Tab2PresenceMapping {

  public tableColumns = ['MS Teams Presences', 'Zailab Presences'];
  public tableTypes;
  public tableTypesDefault = [
    {
      type: 'dropdown',
      options: [],
      direction: 'bottom',
      validation: 'required',
      uniqueKey: true,
      displayField: 'label'
    },
    {
      type: 'dropdown',
      options: [],
      direction: 'bottom',
      validation: 'required',
      uniqueKey: false,
      displayField: 'label'
    },
  ];
  public tableData = [];
  public columnsRegex = [/^(?:(?![+{}()\\]).)*$/];

  public newPresence: Mapping = { msTeams: '', zailab: '' };
  public presenceMappings: Mapping[] = [];
  public zailabPresences: string[] = [];
  private model: { data: ITab2PresenceMapping };

  constructor(
    private presenceService: PresenceService,
    private sessionStore: SessionStore
  ) {
    this.retrievePresences();
    this.loadMSTeamsPresences();
  }

  public activate(bindingContext: { data: ITab2PresenceMapping }) {
    this.model = bindingContext;
    this.tableData = Object.keys(this.model.data.presenceMappings).map(key => ([
      this.formatPresenceForDisplay(key),
      this.model.data.presenceMappings[key]
    ]));
  }

  private async retrievePresences(): Promise<void> {
    try {
      let presences: { presenceCodeName: string; color: string; }[] = await this.presenceService.retrievePresences();
      let offlinePresence = presences.find((presence) => 'Offline' === presence.presenceCodeName);
      if (!offlinePresence) {
        presences.push({ presenceCodeName: 'Offline', color: 'gray' });
      }
      presences = ArrayTools.sort(presences, 'presenceCodeName');
      const formatedPresences = presences.map(presence => {
        return {
          label: presence.presenceCodeName.charAt(0).toUpperCase() + presence.presenceCodeName.slice(1).toLowerCase()
        };
      });
      this.tableTypesDefault[1].options = formatedPresences;
      this.tableTypes = this.tableTypesDefault;
    } catch (error) {
      logger.error('Could not retrieve presence code:', error);
    }
  }

  private loadMSTeamsPresences(): void {
    const msTeamsPresences = [
        'Available', 'Away', 'BeRightBack', 'Busy', 'DoNotDisturb', 
        'InACall', 'InAConferenceCall', 'Inactive', 'InAMeeting', 
        'Offline', 'OffWork', 'OutOfOffice', 'PresenceUnknown', 
        'Presenting', 'UrgentInterruptionsOnly'
    ];

    const formattedMSTeamsPresences = msTeamsPresences.map(msTeamsPresenceMap => {
        return {
            value: msTeamsPresenceMap,
            label: this.formatPresenceForDisplay(msTeamsPresenceMap)
        };
    });

    this.tableTypesDefault[0].options = formattedMSTeamsPresences;
    this.tableTypes = this.tableTypesDefault;
  }

  public tableDataUpdated(data: any[]): void {
  
    const presenceMappings = {
      'Available': 'Available',
      'Away': 'Away',
      'Be Right Back': 'BeRightBack',
      'Busy': 'Busy',
      'Do Not Disturb': 'DoNotDisturb',
      'In A Call': 'InACall',
      'In A Conference Call': 'InAConferenceCall',
      'Inactive': 'Inactive',
      'In A Meeting': 'InAMeeting',
      'Offline': 'Offline',
      'Off Work': 'OffWork',
      'Out Of Office': 'OutOfOffice',
      'Presence Unknown': 'PresenceUnknown',
      'Presenting': 'Presenting',
      'Urgent Interruptions Only': 'UrgentInterruptionsOnly'
    };
  
    const updatedMappings: { [key: string]: string } = {};
  
    data.forEach((item) => {
      if (item && item[0] && item[1]) {
        const msTeamsPresenceCamelCase = presenceMappings[item[0]];
        if (msTeamsPresenceCamelCase) {
          updatedMappings[msTeamsPresenceCamelCase] = item[1];
        } else {
          console.warn('Unknown MS Teams presence value: ', item[0]);
        }
      } else if (item && item[0] && !item[1]) {
        const msTeamsPresenceCamelCase = presenceMappings[item[0]];
        if (msTeamsPresenceCamelCase) {
          delete updatedMappings[msTeamsPresenceCamelCase];
        }
      } else {
        console.warn('Invalid data entry detected: ', item);
      }
    });
    this.model.data.presenceMappings = updatedMappings;
  }

  public formatPresenceForDisplay(presenceMappings: string): string {
    switch (presenceMappings) {
        case 'UrgentInterruptionsOnly':
            return 'Urgent Interruptions Only';
        case 'InACall':
            return 'In A Call';
        case 'InAMeeting':
            return 'In A Meeting';
        case 'InAConferenceCall':
            return 'In A Conference Call';
        case 'DoNotDisturb':
            return 'Do Not Disturb';
        case 'BeRightBack':
            return 'Be Right Back';
        case 'OffWork':
            return 'Off Work';
        case 'OutOfOffice':
            return 'Out Of Office';
        case 'PresenceUnknown':
            return 'Presence Unknown';
        default:
            return presenceMappings;
    }
  }
}

