import { autoinject, LogManager } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { v4 as uuidv4 } from 'uuid';

import { DispositionCodesService } from '../disposition-codes-service';
import { BootstrapFormRenderer } from 'zailab.common';
import { ServiceModel } from '../../../../../_common/models/service-model';
import { ChannelsService } from '../../channels/channels-service';
import { ServicesService } from '../../services/services-service';
import { DispositionModel } from '../disposition-codes-model';
import { ChannelModel } from '../../../../dashboard/teamleader/models/channel-model';

const logger = LogManager.getLogger('AddDispositionCodesList');


@autoinject
export class AddDispositionCodesList {

  private validation: ValidationController;
  private name: string;
  private channels: Array<ChannelModel> = [];
  private services: Array<ServiceModel> = [];
  private directions: Array<string> = [];
  private types: Array<string> = [];
  private selectedChannel: ChannelModel;
  private selectedService: ServiceModel;
  private selectedDirection: string;
  private selectedType: string;
  private dispositionCode: DispositionModel = {};
  private dispositionListUniqueName: boolean = true;

  constructor(private router: Router, private dispositionCodesService: DispositionCodesService, private validationControllerFactory: ValidationControllerFactory, private channelService: ChannelsService, private servicesService: ServicesService) {
    this.setupDispositionCodesProperties();
  }

  private setupDispositionCodesProperties(): void {
    this.initValidation(this.validationControllerFactory);
    this.retrieveChannels();
    this.retrieveSelectedServices();
    this.retrieveDirections();
    this.retrieveTypes();
  }

  private initValidation(validationControllerFactory: ValidationControllerFactory): void {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.dispositionCodesService.retrieveDispositions().then(dispositionList => {
      ValidationRules
        .customRule('uniqueName', (value) => {
          for (let list of dispositionList) {
            if (list.name.toLowerCase() === value.toLowerCase()) {
              this.dispositionListUniqueName = false;
              return false;
            }
          }
          this.dispositionListUniqueName = true;
          return true;
        }, null);

      ValidationRules
        .ensure('name')
        .required().withMessage('Please enter a list name.')
        .satisfiesRule('uniqueName').withMessage('Please enter a unique list name.')
        .on(this.dispositionCode);
    });

  }

  private retrieveChannels(): void {
    this.channelService.displayChannels().then(channels => {
      this.channels = this.formatChannelList(channels);
      if (!this.channels.length) {
        return;
      }
      this.dispositionCode.channel = this.channels[0].displayName;
    });
  }

  private formatChannelList(channels: ChannelModel[]): ChannelModel[] {
    let formattedList = [];
    channels.forEach(ch => {
      formattedList.push(ch);
    });
    return formattedList;
  }


  private retrieveServices(): void {
    this.servicesService.retrieveOrganisationServices().then(services => {
      this.services = services;

      if (!this.services.length) {
        return;
      }
      this.dispositionCode.service = this.services[0].serviceName;
    });
  }

  private retrieveSelectedServices(): void {
    this.servicesService.retrieveOrganisationSelectedServices().then(services => {
      this.services = services;
      if (!this.services.length) {
        return;
      }
      this.dispositionCode.service = this.services[0];
    });
  }

  private retrieveDirections(): void {
    this.directions = ['Inbound', 'Outbound'];
    this.dispositionCode.direction = this.directions[0];
  }

  private retrieveTypes(): void {
    this.types = ['1 Tier', '3 Tier'];
    this.dispositionCode.tiered = false;
  }

  private finish(): void {
    this.setSelectedDirection();
    this.dispositionCode.id = uuidv4();
    this.dispositionCode.direction = this.selectedDirection;
    this.dispositionCode.channel = this.selectedChannel.displayName;
    this.dispositionCode.service = this.selectedService;
    this.dispositionCode.tiered = this.selectedType !== this.types[0];
    this.dispositionCodesService.createDispositionCode(this.dispositionCode).then(response => {
      this.routeToDispositionList(this.dispositionCode.id);
    }, error => {
      logger.info('publishing error >>', error);
    });
  }

  private routeTo3TierDispositionList(dispositionListId: string): void {
    this.router.navigate(`edit-tier1-list/${dispositionListId}`);
  }

  private routeToDispositionList(dispositionListId: string): void {
    this.router.navigate(`edit-list/${dispositionListId}`);
  }

  private routeToDispositionLists(dispositionListId: string): void {
    this.router.navigate('');
  }

  private setSelectedDirection(): void {
    if (this.selectedChannel && this.selectedChannel.channelName === 'INBOUND_CALL') {
      this.selectedDirection = 'Inbound';
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'OUTBOUND_CALL') {
      this.selectedDirection = 'Outbound';
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'EMAIL') {
      this.selectedDirection = 'Inbound';
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'SMS') {
      this.selectedDirection = 'Inbound';
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'CHAT') {
      this.selectedDirection = 'Inbound';
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'INSTANT_MESSAGE') {
      this.selectedDirection = 'Inbound';
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'TICKET') {
      this.selectedDirection = 'Inbound';
    }
  }

  private get isComplete(): boolean {
    if (this.dispositionCode && this.dispositionCode.name && this.dispositionCode.name.length >= 0 && this.dispositionListUniqueName) {
      return true;
    }
  }

  private get isInboundOrOutboundCall(): boolean {
    if (this.selectedChannel && this.selectedChannel.channelName === 'INBOUND_CALL') {
      this.selectedDirection = 'Inbound';
      return true;
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'OUTBOUND_CALL') {
      this.selectedDirection = 'Outbound';
      return true;
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'EMAIL') {
      this.selectedDirection = 'Inbound';
      return true;
    }
    if (this.selectedChannel && this.selectedChannel.channelName === 'SMS') {
      this.selectedDirection = 'Inbound';
      return true;
    }
  }
}
