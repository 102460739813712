import { computedFrom } from 'aurelia-binding';

const statusMap = new Map([
  ['pending', 'Pending'],
  ['ready', 'Ready'],
  ['error', 'Error'],
  ['out_of_sync', 'Out of sync']
]);

export class AssistantModel {
  public isSelected?: boolean;
  public initial?: string;

  constructor(
    public assistantId: string,
    public name: string,
    public description: string,
    public greeting: string,
    public purpose: string,
    private status: string,
    public assignedToFlow?: boolean,
  ) {
    this.initial = this.name.charAt(0).toUpperCase();
  }

  @computedFrom('status')
  public get mappedStatus(): string {
    return statusMap.get(this.status);
  }
}
