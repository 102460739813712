import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';

import { SessionStore, WebSocket, SESSION_EVENTS, FeatureFlagService } from 'zailab.common';
import { UserPersonService } from './user-person-service';
import { ChangeProfilePicture } from '../../user/passport/changeprofilepicture/change-profile-picture-dialog';
import { ChangeProfileInformationDialog } from '../../user/person/changeprofileinformation/changeprofileinformation';
import { ChangePasswordDialog } from '../../user/person/changepassword/changepassword';
import { PasswordService } from '../../user/passport/password/password-service';
import { UserSessionModel } from '../../../_common/stores/sessionmodels/user-session-model';
import { PassportStore } from '../../../_common/stores/passport-store';
import { CallForwardModel } from './models/call-forward-model';
import { CallForwardDialog } from './changecallforward/change-call-forward';
import { EmailSignatureModel } from './models/email-signature-model';
import { EmailSignatureDialog } from './changeemailsignature/change-email-signature';
import { PersonalVoicemailDialog } from './personalvoicemail/personal-voicemail';
import { PersonalVoicemailModel } from './models/personal-voicemail-model';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';

const logger = LogManager.getLogger('UserProfile');

@autoinject
export class UserProfile {
  private userProfile: ZIUserProfile;
  private pictureId: string = null;
  private defaultPicture: string = this.zaiFileLoader.getFile('member-placeholder');
  private url: string = null;
  private ready: boolean = false;
  private callForward: CallForwardModel;
  private emailSignature: EmailSignatureModel;
  private personalVoicemail: PersonalVoicemailModel;
  private voicemailEnabled: boolean;
  public autoLogoutConfig: boolean;
  public callForwardingDisabled: boolean;

  constructor(
    private dialogService: DialogService,
    private sessionStore: SessionStore,
    private userPersonService: UserPersonService,
    private websocket: WebSocket,
    private passwordService: PasswordService,
    private eventAggregator: EventAggregator,
    private passportStore: PassportStore,
    private featureFlagService: FeatureFlagService,
    private zaiFileLoader: ZaiFileLoader
  ) {}

  public attached(): void {
    this.pictureId = this.user.personId;
    this.retrievePersonalInfo();
    this.featureFlagService
      .isEnabled('autoLogoutConfig')
      .then((autoLogoutConfig) => this.autoLogoutConfig = autoLogoutConfig);
    
    this.featureFlagService.isEnabled('DisableCallForwardForMember')
      .then((callForwardingDisabled) => {
        this.callForwardingDisabled = callForwardingDisabled;
      });
  }

  private async retrievePersonalInfo(): Promise<any> {
    let _personId = this.user.personId;
    let _memberId = this.user.memberId;
    let _response: any = await this.userPersonService.retrieveUserInfo(_personId);
    let _device: any = await this.userPersonService.retrieveDeviceInfo(_memberId);
    let organisation = this.sessionStore.get.organisation;
    this.userProfile = (<any>Object).assign({}, _response, _device, { orgName: organisation ? organisation.organisationName : '' });
    const time = this.userProfile.autoLogoutTimeout ? this.userProfile.autoLogoutTimeout / 60 / 1000 : 0;
    this.userProfile.autoLogoutTimeout = time === 0 ? '--' : time + ' Min'; 
    this.ready = true;
    this.callForward = new CallForwardModel(_device.callForward);
    this.voicemailEnabled = _device.voicemailEnabled;

    const { type, signature } = await this.userPersonService.retrieveEmailSignature(_memberId);
    this.emailSignature = new EmailSignatureModel(type, signature);

    const { voicemailEnabled, voicemailMessageId } = _device;
    this.personalVoicemail = new PersonalVoicemailModel(voicemailEnabled, voicemailMessageId);
  }

  private changeProfilePicture(): void {
    this.dialogService.open({
      viewModel: ChangeProfilePicture,
      model: { pictureId: this.pictureId, defaultPicture: this.defaultPicture }
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.url = (<any>response).output;
        let _splitBase64Data: string = this.url.split('base64,')[1];
        this.userPersonService.updateProfilePicture(_splitBase64Data, this.user.personId);
        this.eventAggregator.publish('change.picture', { id: this.user.personId, data: this.url });
      }
    });
  }

  private changeUserName(): void {
    this.dialogService.open({
      viewModel: ChangeProfileInformationDialog,
      model: { firstName: this.userProfile.firstName, surname: this.userProfile.surname }
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.userProfile.firstName = (<any>response).output.firstName;
        this.userProfile.surname = (<any>response).output.surname;
        let message = {
          firstName: this.userProfile.firstName,
          surname: this.userProfile.surname,
          personId: this.user.personId,
          gender: '',
          dateOfBirth: '',
          telephoneNumbers: ''
        };

        this.userPersonService.changeProfileInformation(message);
        this.eventAggregator.publish('update.user.name', {
          firstName: this.userProfile.firstName,
          surname: this.userProfile.surname
        });
        this.eventAggregator.publish(SESSION_EVENTS.USER.EVENT, {
          firstName: this.userProfile.firstName,
          surname: this.userProfile.surname
        });
      }
    });
  }

  private changePassword(): void {
    this.dialogService.open({
      viewModel: ChangePasswordDialog,
      model: {}
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        let _oldPassword = (<any>response).output.oldPassword;
        let _newPassword = (<any>response).output.newPassword;
        let _passportId = this.passportStore.passportId;
        this.passwordService.changePassword(_oldPassword, _newPassword, _passportId).then(_result => {
          logger.info('Change password success >>>', _result);
        }, _error => {
          logger.info('Change password failure >>>', _error);
        });
      }
    }
    )
  }

  private changeCallForward(): void {
    this.dialogService.open({
      viewModel: CallForwardDialog,
      model: this.callForward
    }).whenClosed((response: any) => {
      if (response.wasCancelled) {
        return;
      }
      this.callForward = response.output;
    });
  }

  public togglePersonalVoicemail(): void {
    this.voicemailEnabled = !this.voicemailEnabled;
    this.userPersonService.enablePersonalVoicemail(this.user.memberId, this.voicemailEnabled);
  }

  public changePersonalVoicemail(): void {
    this.dialogService.open({
      viewModel: PersonalVoicemailDialog,
      model: this.personalVoicemail
    }).whenClosed((response: any) => {
      if (response.wasCancelled) {
        return;
      }
      this.personalVoicemail.audioId = response.output.audioId;
      this.userPersonService.changePersonalVoicemail(this.user.memberId, this.personalVoicemail.audioId);
    });
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  public changeEmailSignature(): void {
    this.dialogService.open({
      viewModel: EmailSignatureDialog,
      model: this.emailSignature
    }).whenClosed((response: any) => {
      if (response.wasCancelled) {
        return;
      }
      this.emailSignature.type = response.output.type;
      this.emailSignature.signature = response.output.signature || '';
      this.userPersonService.changeEmailSignature(this.user.memberId, this.emailSignature);
    });
  }
}
