import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { SurveyService } from '../survey-service';
import { SurveyListModel } from '../survey-list-model'
/*
 * */
const logger = LogManager.getLogger('DisplaySurveys');

/*
 * */
@inject(Element, SurveyService, Router, EventAggregator, SessionStore)
export class DisplaySurveys extends AbstractList {

  constructor(private element: Element, private surveyService: SurveyService, private router: Router, protected eventAggregator: EventAggregator, private sessionStore: SessionStore) {
    super(eventAggregator);
  }

  protected activate(params: { _param: string }): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected subscribeToOplog(): void {
    this.surveyService.initialiseSurveysOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', response => {
        this.setupListConfig(this.surveyService._modelCollection(response.surveyTemplates));
      });
      this.oplog.on('update', response => {
        this.setupListConfig(this.surveyService._modelCollection(response.surveyTemplates));
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.surveyService.retrieveSurveyList().then(
      surveyList => {
        this.setupListConfig(surveyList);
      },
      error => {
        logger.info('Error > retrieve survey list =', error);
      }
    );
  }

  protected setupListConfig(_surveys: SurveyListModel[]): void {
    super.setupListConfig(_surveys);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .enableAdd()
    .enableDelete()
    .enableClick()
    .selectionType(List.SINGLE_SELECTION_TYPE)
    .items(_surveys)
    .icon('icon')
    .uniqueIdentifier('surveyId')
    .displayId('name')
    .description('descriptionText')
    .withProportion('medium')
    .hasRollover(false)
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected selectItem(_items: any): void {
    super.selectItem(_items);
    this.router.navigate(`survey/${_items.item.surveyId}`);
  }

  protected showAddItem(_item: any): void {
    this.router.navigate('wizard');
  }

  protected deleteItems(items: any[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} services deleted.` : `Survey <b>${items[0].name}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(_survey: SurveyListModel): void {
    super.delete(_survey);
    this.surveyService.deleteSurvey(_survey.surveyId, this.sessionStore.get.organisation.organisationId);
  }

}
