import {LogManager, autoinject, computedFrom, PLATFORM} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/**/
import {CUSTOM_EVENTS, BootstrapFormRenderer, SessionStore} from 'zailab.common';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {TimezoneAbstract} from '../../timezone-abstract';
/**/
const logger = LogManager.getLogger('CustomTimeRoutesDialog');

/**/
@autoinject()
export class CustomTimeRoutesDialog extends TimezoneAbstract {

  protected nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private keypadTabs: Array<ZTabModel>;
  private name: string = '';
  private isComplete: boolean = false;
  private payload: ZCustomTimeRoutesPayload;
  private nodeConfiguration: ZNodeConfig;

  constructor(private dialogController: DialogController, private validationControllerFactory: ValidationControllerFactory, private validationController: ValidationController, protected sessionStore:SessionStore) {
    super(sessionStore);
    this.validationController = validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.validationController.validateTrigger = validateTrigger.change;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    super.activate(_config);
    this.existingConnections = this.nodeData.connections;
    this.name = this.nodeData.properties['name'] || '';
    this.setupTabs();
    this.initialiseValidation();
  }

  private setupTabs(): void {
    this.keypadTabs = [
      {
        id: 'dow',
        name: 'Apply to days of the week',
        viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/custom-time-routes/steps/daysofweek/days-of-week'),
        icon: 'clock',
        selected: this.nodeData.properties && this.nodeData.properties['type'] === 'dow',
        data: this.nodeData.properties,
        version: this.nodeData.version
      },
      {
        id: 'dateRange',
        name: 'Apply to date',
        viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/custom-time-routes/steps/applytodate/apply-to-date'),
        icon: 'calendar',
        selected: this.nodeData.properties && this.nodeData.properties['type'] === 'dateRange',
        data: this.nodeData.properties,
        version: this.nodeData.version
      }
    ];
  }

  private initialiseValidation(): void {
    ValidationRules.customRule(
      'hasName',
      name => {
        return name && name.length;
      },
      'Please specify a name for your custom time route.'
    );

    ValidationRules.ensure('name')
      .satisfiesRule('hasName')
      .on(this);
  }

  get canProceed(): boolean {
    return !!this.name && this.isComplete;
  }

  private setIncomplete(): void {
    this.isComplete = false;
  }

  private handleTabData(_event: CustomEvent): void {
    this.payload = _event.detail;
    this.keypadTabs.filter(_tab => {
      return _tab['id'] === this.payload.type;
    })[0]['data'] = this.payload;
    this.isComplete = true;
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {
    this.validationController.validate().then(_result => {
      if (_result.valid) {
        if (!this.nodeData.properties) {
          this.nodeData.properties = {};
        }
        this.setupConnectors();
        this.nodeData.properties = Object.assign(this.payload, {timezone: this.selectedTimezone});
        this.nodeData.properties['name'] = this.name;
        this.nodeData.properties['isDefined'] = true;
        this.dialogController.ok(new NodeModel(this.nodeData));
      }
    });

  }

  private setupConnectors(): void {
    const updatedConnections = this.mapDigitsToConnections(['yes', 'no'], this.nodeData.connections);
    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
  }

  private mapDigitsToConnections(connectors: string[], existingConnections: ConnectorModel[]): ConnectorModel[] {
    let nodeID: string = this.nodeData.id;
    let connectorIndex: number = 0;
    let connections: ConnectorModel[] = [];

    connectors.forEach(value => {
      let connection: ConnectorModel = new ConnectorModel({name: value, source: {connectorIndex, nodeID}});
      connections.push(connection);
      connectorIndex++;
    });

    connections = this.mapExistingConnections(connections, existingConnections);

    return connections;
  }

  private mapExistingConnections(updatedConnections: ConnectorModel[], existingConnections: ConnectorModel[]): ConnectorModel[] {
    let connectorIndex: number = 0;

    updatedConnections.forEach(connection => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest = existingConnections[connectorIndex].dest;
      }
      connectorIndex++;
    });

    return updatedConnections;
  }
}


