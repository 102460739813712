import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
import { ActivityService } from './activity-service';
import { SessionStore } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('Activity');
/*
 */
@inject(ActivityService, EventAggregator, SessionStore)
export class Activity {
  activity = null;
  timer = null;
  wrapUp = null;
  subscriptions = [];

  constructor(activityService, eventAggregator, sessionStore) {
    this.activityService = activityService;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.timer = new Timer();
  }

  activate() {
    this.subscribe();
  }

  attached() {
    this.retrieveMemberStatusActivity();
    this.retrieveAgentActivity();
  }

  retrieveMemberStatusActivity() {
    const memberId = this.user.memberId;
    this.activityService.retrieveMemberActivity(memberId).then(
      response => {
        this.activity = response.activity;
      },
      error => {
        logger.info('retrieveMemberActivity > error = ', error);
      }
    );
  }

  retrieveAgentActivity() {
    let memberId = this.user.memberId;
    this.activityService.retrieveMemberStatusActivity(memberId).then(
      response => {
        this.wrapUp = response.wrapUp;
      },
      error => {
        logger.info('retrieveMemberStatusActivity > error = ', error);
      }
    );
  }

  subscribe() {
    let memberId = this.user.memberId;
    this.activityService.initialiseMemberActivityOplogWith(memberId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('update', response => {
        this.activity = response.activity;
        this.eventAggregator.publish('member-activity:change', response.activity);
      });
    });

    this.subscriptions.push(this.eventAggregator.subscribe('wrap.up', wrapUp => {
      if (wrapUp) {
        this.wrapupStarted();
      } else {
        this.wrapupEnded();
      }
    }));
    this.subscriptions.push(this.eventAggregator.subscribe('TRIGGER-RECONNECT-REQUESTS', () => {
      this.retrieveAgentActivity();
      this.retrieveMemberStatusActivity();
    }));
  }

  wrapupStarted() {
    this.wrapUp = true;
    this.timer.start();
  }

  wrapupEnded() {
    this.wrapUp = false;
    this.timer.reset();
  }

  deactivate() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }
}

class Timer {
  timerInterval;
  isActive = false;
  seconds = 0;
  minutes = 0;

  start() {
    this.isActive = true;
    this.timerInterval = setInterval(() => {
      this.incrementSeconds();
    }, 1000);
  }

  incrementSeconds() {
    if (this.seconds === 59) {
      this.seconds = 0;
      this.incrementMinutes();
      return;
    }
    this.seconds++;
  }

  incrementMinutes() {
    this.minutes++;
  }

  stop() {
    this.isActive = false;
    window.clearInterval(this.timerInterval);
  }

  reset() {
    this.stop();
    this.timerInterval = null;
    this.seconds = 0;
    this.minutes = 0;
  }

  detached() {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }

  @computedFrom('seconds', 'minutes')
  get currentTime() {
    return ('00' + this.minutes).slice(-2) + ':' + ('00' + this.seconds).slice(-2);
  }
}
