import { PLATFORM } from "aurelia-framework";

export const INTEGRATIONS_ROUTES = [{
  route: '',
  name: '',
  moduleId: PLATFORM.moduleName('features/integrations/list/integrations-list'),
  isTwelveColumnRoute: true,
  nav: false,
  title: 'Integrations',
  settings: {access: ['Administrator']}
}, {
  route: 'verint',
  name: 'verint',
  moduleId: PLATFORM.moduleName('features/integrations/verint/verint-providers-list'),
  isTwelveColumnRoute: true,
  nav: false,
  title: 'Verint Setup',
  settings: {access: ['Administrator']}
}];