import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

import { ChannelModel } from './channel-model';

const logger = LogManager.getLogger('ChannelsService');

@inject(HttpClient)
export class ChannelsService {

  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  displayChannels() {
    return this.httpClient.createRequest('v1/organisation/me/channels')
      .asGet()
      .send()
      .then((response) => {
        return response.map(_channel => {
          return new ChannelModel(_channel)
        });
      });
  }
}
/**
 * @param response
 * @returns {Array}
 * @private
 */
function _model(response) {
  let channels = [];
  if (response.displayChannelsView) {

    for (let item of response.displayChannelsView) {
      channels.push(new ChannelModel(item));
    }
  }
  return channels;
}