import {inject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';

/*
 */
@inject(Router)
export class ContactFields {

  constructor(private router: Router) {

    this.router = router;
  }

  public configureRouter(config: any, router: Router): void {

    let routeMap = [
      {route: '',       name: 'list',   moduleId: PLATFORM.moduleName('./list/contact-fields-list'),      nav: false, title: 'List',    isTwelveColumnRoute: true},
    ];

    config.map(routeMap);

    this.router = router;
  }
}
