import {LogManager, computedFrom} from 'aurelia-framework';

import {BaseModel} from 'zailab.abstract';

import moment from 'moment';

const logger = LogManager.getLogger('WaitingInteractionsMode');

export class WaitingInteractionsModel extends BaseModel {

  public interactionId: string = null;
  public businessValue: string = null;
  public channel: string = null;
  public startTimestamp: string = null;
  public outOfSLA: string = null;
  public taskTemplateName: string = null;
  public type: string = null;
  public source: string = null;
  public contactName: string = null;
  public subject: string = '';

  constructor(waitingInteractions: any) {
    super();
    this.mapProperties(waitingInteractions);
  }

  get currentTimeStamp(): Date {
    return moment();
  }

  @computedFrom('channel', 'type')
  public get channelName(): string {
    if(this.type === 'callback' && this.channel === 'Inbound Call') {
      return 'Callback';
    } else if(this.channel === 'Inbound Call') {
      return 'Call';
    }

    return this.channel;
  }

  @computedFrom('outOfSLA')
  public get formattedOutOfSLA(): string {
    return !this.outOfSLA ? 'No' : 'Yes';
  }

  @computedFrom('taskTemplateName')
  public get workType(): string {
    return this.taskTemplateName;
  }
}
