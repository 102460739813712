import {autoinject, LogManager} from "aurelia-framework";
import {Router, RouterConfiguration} from 'aurelia-router';

import {INTEGRATIONS_ROUTES} from './integrations-routes';

const logger = LogManager.getLogger('IntegrationsModule');

@autoinject
export class IntegrationsModule {
  private actions: string[] = ['authenticate','sync'];

  constructor(private router: Router) {
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    config.map(INTEGRATIONS_ROUTES);
    this.router = router;
  }

}
