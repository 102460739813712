import {LogManager} from 'aurelia-framework';
import {TotalCountsModel} from './total-counts-model';
import {DistributionModel} from './distribution-model';

const logger = LogManager.getLogger('TotalInteractionsModel');

export class TotalInteractionsModel {

  public distributions: DistributionModel[] = [];
  public counts: TotalCountsModel[] = [];

  constructor(data: any) {
    if(!data) {
      return;
    }
    Object.assign(this, data);
  }
}