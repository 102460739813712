import { inject, LogManager } from 'aurelia-framework';
import { AccountService } from './account-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
/*
 */
import { MESSAGE_EVENTS, SessionStore, SESSION_EVENTS } from 'zailab.common';
/*
 */
import { AccountState } from './account-state';
import { CURRENCY_TO_SYMBOL_MAP } from './currency-map';

const logger = LogManager.getLogger('Account');
/*
 */
@inject(AccountService, AccountState, EventAggregator, Router, SessionStore)
export class Account {
  accountStatus;
  currencySymbol;
  oplog;
  accountTabs = [
    { id: '1', name: 'Overview', viewModel: PLATFORM.moduleName('features/finance/account/overview/overview'), neverDisableTab: true },
    { id: '2', name: 'Buy Credit', viewModel: PLATFORM.moduleName('features/finance/account/buycredit/buycredit'), disableTab: true },
    { id: '7', name: 'Recharge Trigger', viewModel: PLATFORM.moduleName('features/finance/account/rechargetrigger/recharge-trigger'), disableTab: true },
    { id: '3', name: 'Usage', viewModel: PLATFORM.moduleName('features/finance/account/usage/usage'), neverDisableTab: true },
    { id: '4', name: 'Numbers', viewModel: PLATFORM.moduleName('features/finance/account/numbers/displaypurchasednumbers/display-purchased-numbers'), disableTab: true },
    { id: '5', name: 'Upgrade', viewModel: PLATFORM.moduleName('features/finance/account/upgradeaccount/upgrade-account') },
    { id: '6', name: 'Company Information', viewModel: PLATFORM.moduleName('features/finance/account/accountdetails/account-details'), disableTab: true },
  ];
  selectedTab = '';
  isTrial;
  updateSubscription;

  constructor(accountService, accountState, eventAggregator, router, sessionStore) {
    this.accountService = accountService;
    this.accountState = accountState;
    this.eventAggregator = eventAggregator;
    this.router = router;
    this.sessionStore = sessionStore;
  }

  activate(params) {
    this.tab = params.tab;
    this.retrieveAccountStatus();
  }

  retrieveAccountStatus() {
    const organisation = this.sessionStore.get.organisation;

    if (organisation && organisation.organisationId) {
      this.accountService.retrieveAccountStatus().then(
        response => {
          this.accountStatus = response;
          this.subscribeToOplog();
          this.subscribeToEvents();
          this._checkIfTrialActive();
          this.currencySymbol = CURRENCY_TO_SYMBOL_MAP[this.accountStatus.available.currency];
          if (this.accountStatus.accountId === null) {
            this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'There was a problem creating your account. Please try again.');
            this.router.parent.navigate('organisation');
          }
        },
        error => {
          logger.info('Retrieve Available Balance > error = ', error);
          setTimeout(() => {
            this.retrieveAccountStatus();
          }, 500);
        }
      );
    } else if (!this.updateSubscription) {
      this.updateSubscription = this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.ORGANISATION, () => {
        this.updateSubscription.dispose();
        this.retrieveAccountStatus();
      });
    }
  }

  subscribeToOplog() {
    this.accountService.subscribeToAccountStatus(this.accountStatus.accountId).then(oplog => {
      this.oplog = oplog;

      this.oplog.on('insert', data => this.handleOplogChanges(data));
      this.oplog.on('update', data => this.handleOplogChanges(data));
      this.oplog.on('delete', data => this.handleOplogChanges(data));
    });
  }

  attached() {
    if (this.tab) {
      this.setSelectedTabToShow(this.tab);
    }
  }

  handleOplogChanges(data) {
    if (this.accountStatus && this.accountStatus.available) {
      this.accountStatus.available.amount = data.amount;
    }
    if (this.accountStatus && this.accountStatus.status) {
      if (this.accountStatus.status.toLowerCase() !== data.status.toLowerCase()) {
        this.accountStatus.status = data.status;
        this._checkIfTrialActive();
      }
    }
  }

  subscribeToEvents() {
    this.eventAggregator.subscribe('account.tab.navigation', tabname => {
      this.setSelectedTabToShow(tabname);
    });
  }

  _checkIfTrialActive() {
    logger.info('this.accountStatus.status=', this.accountStatus.status);
    if (this.accountStatus.status.toLowerCase().indexOf('trial') > -1) {
      this.isTrial = true;
    } else {
      this.accountTabs.forEach((tab) => tab.disableTab = tab.neverDisableTab ? tab.disableTab : !tab.disableTab);
      this.isTrial = false;
    }
  }

  deactivate() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    this.accountState.lastUsageDateLoaded = null;
  }

  setSelectedTabToShow(pageTitle) {
    const matchingTabs = this.accountTabs.filter((tab) => tab.name.toLowerCase() === pageTitle.toLowerCase());
    if (matchingTabs.length === 1) {
      this.selectedTab = matchingTabs[0];
    }
  }
}
