import { LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('SearchFilterValueConverter');

export class SearchFilterValueConverter {
  toView(array, searchTerm, matchingFunction, compareParam, compareParam2, compareParam3, compareParam4, compareParam5) {
    if (!array || typeof searchTerm === 'number') {
      return array;
    }
    const searchText = searchTerm ? searchTerm.trim() : '';
    return array.filter(item => {
      if (!compareParam) {
        let matches = searchText && searchText.length ? matchingFunction(searchText || '', item, compareParam, compareParam2, compareParam3, compareParam4, compareParam5) : true;
        return matches;
      }
      let matches = matchingFunction(searchText || '', item, compareParam, compareParam2, compareParam3, compareParam4, compareParam5);
      return matches;
    });
  }
}
