import { bindable, customElement, LogManager } from "aurelia-framework";
import { Event } from "zailab.common";

const logger = LogManager.getLogger('AgentScriptCCHistory');

@customElement('z-agent-script-cc-history')
export class AgentScriptCCHistory {

  @bindable
  public history = [];
  
  @bindable
  public height: number = 145;

  public realHeight: number;

  constructor(
    private element: Element,
  ) { }

  public bind(): void {
    this.realHeight = this.height - 23;
  }

  public showCurrentStep(): void {
    new Event(this.element, 'show-current-step', {});
  }

  public select(history: any): void {
    new Event(this.element, 'show-step', history);
  }
}
