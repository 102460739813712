import {autoinject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

import {SessionStore} from 'zailab.common';
import {TelephonyService} from "../../../../telephony/telephony-service";

const logger = LogManager.getLogger('ConsultDialog');

@autoinject()
export class ConsultDialog {

  private dialogHeader: string = 'Consult';
  private member: any = null;
  private interactionId: string = '';
  private transferringMemberId: string = '';

  constructor(private sessionStore: SessionStore, private dialogController: DialogController, private telephonyService: TelephonyService) {
  }
  public activate(consultData: any): void {
    this.member = consultData.targetMember;
    this.interactionId = consultData.interactionId;
    this.transferringMemberId = consultData.transferringMemberId;
  }

  private connect(): void {
    if (this.member.memberId) {
      this.telephonyService.connect(this.interactionId, this.member.memberId, this.transferringMemberId);
    }
  }

  private cancelConsult(): void {
    if (this.member.memberId) {
      this.telephonyService.cancelConsult(this.interactionId, this.member.memberId, this.transferringMemberId);
    }
    this.cancelDialog();
  }

  private cancelDialog(): void {
    this.dialogController.cancel();
  }
}