import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('GridCellDragModel');
/**/
export class GridCellDragModel extends BaseModel {

  public hasDragOver: boolean = false;
  public isDragging: boolean = false;
  public cellTemplate: string = null;

  get isDraggable(): boolean {
    return this.cellTemplate && this.cellTemplate.length > 0;
  }

  constructor(cell: object) {

    super();
    super.mapProperties(cell);
  }
}
