
import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';

import { ZIWizard } from '../../../../../../../../typings/zai/zai.common';
import { BootstrapFormRenderer, Event } from '../../../../../../../zailab.common';
import { DataTools } from '../../../../../../../_common/tools/data-tools';
import { observable } from 'aurelia-binding';

const logger = LogManager.getLogger('Request');

@autoinject()
export class Page3EditAssistantApi {
  @observable public url: string;
  @observable public timeout: number;
  public contentType: string;
  private requestMethod: string[];
  private method: string;
  public body: string;
  public tableData;
  public tableColumns = ['Header Name', 'Header Value'];
  public columnsRegex = [null, /^(?:(?![]).)*$/];
  // @ts-ignore
  public headers: { key: string; value: string } = {};
  public data: {
    url: string;
    headers: { key: string; value: string };
    timeout: number;
    contentType: string;
    body: string;
  } = {
    url: '',
    // @ts-ignore
    headers: {},
    timeout: 0,
    contentType: 'JSON',
    body: ''
  };

  private bodyText: string;
  private requestHeaders: any;
  private wizard: ZIWizard;
  private maxCallTimeout: number;
  jsonError: string;
  validation: ValidationController;

  constructor(validationControllerFactory: ValidationControllerFactory) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.initHttpMethods();
    this.initiateValidation();
    this.extractWizardData();
    this.validate();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    this.wizard.beforeNextStep(() => {
      this.nextStep();
    });
  }

  private initHttpMethods(): void {
    this.requestMethod = ["GET", "POST", "PUT"];
    this.method = this.wizard.data.method || this.requestMethod[0];
}

  private nextStep(): void {
    this.wizard.continue();
  }

  private extractWizardData(): void {
    this.url = this.wizard.data.url;
    if (this.wizard.data.method) {
      this.method = this.wizard.data.method;
    }
    this.timeout = this.wizard.data.timeout || 0;
    this.bodyText = this.wizard.data.bodyText || this.wizard.data.body;
    if (this.wizard.data.requestHeaders) {
      this.tableData = this.wizard.data.requestHeaders
        ? Object.entries(this.wizard.data.requestHeaders)
        : {};
    } else if (this.wizard.data.headers) {
      this.tableData = this.wizard.data.headers
        ? Object.entries(this.wizard.data.headers)
        : {};
    } else {
      this.wizard.data.requestHeaders = {};
    }
  }

  private initiateValidation(): void {
    ValidationRules
      .ensure('url')
      .required()
      .withMessage('Please enter a URL.')
      .on(this);

    ValidationRules
      .ensure('timeout')
      .required()
      .withMessage('Please enter a timeout.')
      .on(this);
  }

  private validate(): void {
    if (!this.url || !this.timeout || !this.method) {
      this.wizard.step.incomplete();
      return;
    }
    if (this.timeout < 0 || (!this.maxCallTimeout && this.timeout > 10000) || (this.maxCallTimeout && this.timeout > 60000)) {
      this.wizard.step.incomplete();
    }
    this.wizard.step.complete();
  }

  public urlChanged(): void {
    this.wizard.data.url = this.url;
    this.validate();
  }

  public triggerMethodChanged(): void {
    this.wizard.data.method = this.method;
    this.validate();
  }

  public timeoutChanged(): void {
    this.wizard.data.timeout = this.timeout;
    this.validate();
  }

  public valueChanged(tableData: any): void {
    const reversedObject = {};

    tableData.forEach(([key, value]) => {
      reversedObject[key] = value;
    });
    this.wizard.data.requestHeaders = reversedObject;
  }

  public triggerBodyTextChanged(): void {
    this.wizard.data.bodyText = this.bodyText;
    this.jsonError = null;
    this.validate();
  }
}
