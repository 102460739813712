import { computedFrom } from 'aurelia-binding';
import { LogManager } from 'aurelia-framework';
import { HttpResponseMessage } from "aurelia-http-client";

const logger = LogManager.getLogger('AutoLogout');

export class AlertMessage {

  public alertId: string;
  public message: string;
  public name: string;
  public priority: string;
  public timezone: string;
  public time: string;
  public date: string;
}

export class AlertsMessagesResponse extends HttpResponseMessage {

  public memberId: string;
  public messages: AlertMessage[];
}

export interface Option {

  value: string;
  label: string;
}

export class AudienceTypeSelection {

  public static get options(): Option[] {
    return [
      { value: '', label: 'Select an audience type' },
      { value: AudienceType.ORGANISATION, label: 'Organisation' },
      { value: AudienceType.BUSINESS_PARTNER, label: 'Business Partner' },
      { value: AudienceType.CONTACT_CENTER, label: 'Contact Center' },
      { value: AudienceType.TEAM, label: 'Team' },
      { value: AudienceType.MEMBER, label: 'Member' }
    ];
  }

  public static get leaderOptions(): Option[] {
    return [
      { value: AudienceType.TEAM, label: 'Team' },
      { value: AudienceType.MEMBER, label: 'Member' }
    ];
  }
  public static labelFor(value: AudienceType): string {
    return this.options.find((option) => option.value === value).label;
  }
}

export enum AudienceType {

  ORGANISATION = 'ORGANISATION', //
  BUSINESS_PARTNER = 'BUSINESS_PARTNER', //
  CONTACT_CENTER = 'CONTACT_CENTER', //
  TEAM = 'TEAM', //
  MEMBER = 'MEMBER'
}

export enum Priority {

  HIGH = 'HIGH', //
  MEDIUM = 'MEDIUM', //
  LOW = 'LOW'
}
