import { autoinject, customElement, bindable} from 'aurelia-framework';
/**/
@customElement('z-hidden-dropdown')
@autoinject()
export class ZDropdown {
  @bindable private display: boolean = false;
  @bindable({ attribute: 'hide-delay' }) private hideDelay: number = 1000;
  @bindable({ attribute: 'pause-delay' }) private pauseDelay: number = 2000;
  private autoCloseTimer: number = 0;
  private deferAutoClose: boolean = false;

  constructor() {}

  private displayChanged(): void {
    if (this.display) {
      this.startAutoCloseMenu(this.pauseDelay);
    }
  }

  private startAutoCloseMenu(_delayTime:number = this.hideDelay): void {
    if (this.display && this.autoCloseTimer === 0) {
      this.autoCloseTimer = window.setInterval(() => {
        this.closeMenu();
      }, _delayTime);
    }
  }

  private closeMenu(): void {
    if (!this.deferAutoClose) {
      this.display = false;
      window.clearInterval(this.autoCloseTimer);
      this.autoCloseTimer = 0;
    }
  }

  private preventAutoClose(): void {
    this.deferAutoClose = true;
  }

  private allowAutoClose(): void {
    this.deferAutoClose = false;
  }

}

interface ISubscription {
  dispose: Function;
}
