import { inject, LogManager, customElement, containerless, observable, bindable } from 'aurelia-framework';
import { PlaceholderService } from '../../../_common/services/placeholder-service';
/*
 */
import { Event, SearchTools } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('ZBasicList');
/*
 */
@containerless()
@inject(Element)
@customElement('z-list-basic')
export class ListBasic {

  @bindable @observable list;
  @bindable({ attribute: 'placeholder-rows' }) placeholderRows = 0;
  @bindable({ attribute: 'radio-filters' }) radioFilters;
  ready = false;
  placeholders = 0;
  placeholderService;
  container;

  constructor(element) {
    this.element = element;
  }

  changeRadioFilterSelection(event) {
    new Event(this.element, 'radio-filter-change', event.srcElement.value);
  }

  listChanged(newValue) {
    if (newValue && newValue.items) {
      this.ready = true;
      try {
        if (this.placeholderRows !== 0) {
          this.placeholderService = new PlaceholderService(this.container, this.list.items.length, this.placeholderRows, (placeholders) => {
            this.placeholders = placeholders;
          });
        }
      } catch (e) {
        // Could not create placeholders
      }
    }
  }

  selectItem(item) {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.list.select && !this.list.isDeleting) {
      if (this.list.autoSelect) {
        this.list.select(item, this.list.items, this.list.required, this.element);
      }
      new Event(this.element, 'select', { data: this.list.items, item: item });
      return;
    }
    if (this.list.isDeleting && item.canDelete !== false) {
      this.list.selectToDelete(item);
    } else {
      // TODO: Add in a toastr
      logger.warn('Cannot select item >>>', item);
    }
  }

  deleteItems() {

    let itemsToDelete = [];

    for (let item of this.list.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      }
    }

    if (itemsToDelete.length) {
      new Event(this.element, 'delete', itemsToDelete);
    }
    this.list.isDeleting = false;
  }

  toggleDelete() {
    this.list.toggleDelete();
  }

  addItem() {
    new Event(this.element, 'add', {});
  }

  selectAction(action, item) {
    new Event(this.element, 'selectaction', { action, item });
  }

  startsWithMatch(searchExpression, value, searchParam) {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return searchExpression && value[searchParam].toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  partialMatch = (searchExpression, value, searchParam) => {
    if (this.list && this.list.items) {
      const items = this.list.items.filter((item) => SearchTools.partialMatch(item[searchParam], searchExpression));
      new PlaceholderService(this.container, items.length, 5, (placeholders) => {
        this.placeholders = placeholders;
      });
    }
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
