import { computedFrom, LogManager } from 'aurelia-framework';
import { MemberInteractionModel } from '../../../organisation/conversation/interaction/member-interaction-model';

let logger = LogManager.getLogger('ConnectedInteractionModel');

const outcomeColours = {
  answered: 'var(--successful)',
  abandoned: 'var(--unsuccessful)',
  missed: 'var(--unsuccessful)',
  forwarded: 'var(--pending)',
  callback: 'var(--pending)',
  mailbox: 'var(--pending)',
  sent: 'var(--successful)',
  received: 'var(--successful)'
};

export class ConnectedInteractionModel {

  public interactionId: string;
  public contactId: string;
  public conversationId: string;
  public channel: string;
  public state: string;
  public interactionType: string;
  public wrapUpChannelIds: Array<string>;
  public taskInformation: TaskInformation;
  public conversationInteraction: ConversationInteraction;
  public actions: any[];

  constructor(interaction: MemberInteractionModel) {
    this.interactionId = interaction.interactionId;
    this.contactId = interaction.contactId;
    this.conversationId = interaction.conversationId;
    this.channel = interaction.channel;
    this.state = interaction.state;
    this.interactionType = interaction.interactionType;
    this.wrapUpChannelIds = interaction.wrapUpChannelIds;
    this.taskInformation = interaction.payload;
  }

  private get callOutcome(): string {

    if (this.conversationInteraction && this.conversationInteraction.metaData && this.conversationInteraction.metaData.channel === 'VOICE') {
      return 'answered';
    }

    if (_case.lower(this.channel) === 'call' && !this.interactionType) {
      if (this.taskInformation && this.taskInformation.type === 'campaign') {
        return 'served';
      }
      return 'callback';
    }

    return null;
  }

  private get type(): string {

    if (this.conversationInteraction && this.conversationInteraction.metaData && this.conversationInteraction.metaData.direction) {
      return this.conversationInteraction.metaData.direction;
    }

    return null;
  }

  private get taskName(): string {

    return null;
  }

  private get flowName(): string {

    if (this.conversationInteraction && this.conversationInteraction.metaData && this.conversationInteraction.metaData.flowName) {
      return this.conversationInteraction.metaData.flowName;
    }

    return null;
  }

  public get from(): string {

    if (this.conversationInteraction && this.conversationInteraction.metaData && this.conversationInteraction.metaData.from) {
      return this.conversationInteraction.metaData.from;
    }

    if (this.taskInformation && this.taskInformation.metadata && this.taskInformation.metadata.prospectListName) {
      return this.taskInformation.metadata.prospectListName;
    }

    return null;
  }

  public get to(): string {

    if (this.conversationInteraction && this.conversationInteraction.metaData && this.conversationInteraction.metaData.to) {
      return this.conversationInteraction.metaData.to;
    }

    if (this.taskInformation && this.taskInformation.contact && this.taskInformation.contact.firstName && this.taskInformation.contact.surname) {
      return `${this.taskInformation.contact.firstName} ${this.taskInformation.contact.surname}`;
    }

    return null;
  }

  public get workType(): string {

    if (this.conversationInteraction && this.conversationInteraction.metaData && this.conversationInteraction.metaData.workType) {
      return this.conversationInteraction.metaData.workType;
    }

    if (this.taskInformation && this.taskInformation.metadata && this.taskInformation.metadata.campaignName) {
      return this.taskInformation.metadata.campaignName;
    }

    return null;
  }

  @computedFrom('channel', 'type', 'callOutcome')
  get formattedChannel(): string {
    if (this.callOutcome) {
      if (_case.lower(this.type) === 'outbound' && (_case.lower(this.channel) !== 'sms') && (_case.lower(this.channel) !== 'email')) {
        return 'Outbound Call';
      }
      if (_case.lower(this.type) === 'inbound' && (_case.lower(this.channel) !== 'sms') && (_case.lower(this.channel) !== 'email')) {
        return 'Inbound Call';
      }
    }

    if (_case.lower(this.type) === 'internal') {
      return 'Internal Call'
    }

    if (_case.lower(this.channel) === 'email') {
      return _case.lower(this.type) === 'outbound' ? 'Outbound Email' : 'Inbound Email';

    }

    if (_case.lower(this.channel) === 'sms') {
      return _case.lower(this.type) === 'outbound' ? 'Outbound SMS' : 'Inbound SMS';
    }

    if (_case.lower(this.channel) === 'chat') {
      return 'Chat';
    }

    if (_case.lower(this.channel) === 'call' && !this.interactionType) {
      if (this.taskInformation && this.taskInformation.type === 'campaign') {
        return 'Campaign';
      }
      return 'Inbound Call';
    }

    return this.channel;
  }

  @computedFrom('channel', 'type', 'direction', 'callOutcome')
  get channelIcon(): string {
    let _channel = this.channel ? this.channel.toLowerCase() : '';
    let _type = this.type ? this.type.toLowerCase() : '';
    let _isPrivateCall = !this.taskName;

    if (this.callOutcome === 'answered' && _type === 'internal') {
      return 'call-internal';
    } else if (this.callOutcome === 'answered' && (_channel === 'outbound call' || _channel === 'outbound flow call')) {
      return 'call-outbound';
    } else if (this.callOutcome === 'answered' && _isPrivateCall && _type === 'outbound' && _channel !== 'sms' && _channel !== 'email') {
      return 'call-outbound';
    } else if (this.callOutcome === 'answered' && _isPrivateCall && _type === 'inbound' && _channel !== 'sms' && _channel !== 'email') {
      return 'call-inbound';
    } else if (this.callOutcome === 'callback') {
      return 'call-inbound';
    }

    if (_channel) {

      if (_channel === 'email') {
        return 'envelope-closed';
      }

      if (_channel === 'sms') {
        return 'comments';
      }

      if (_channel === 'chat' || _channel === 'instant message') {
        return 'comments-square';
      }

      if (_channel === 'outbound call' || _channel === 'outbound flow call') {
        return 'call-outbound';
      }

      if (_channel === 'inbound call' || _channel === 'internal call' || _channel === 'flow call' || _channel === 'inbound flow call') {
        return 'call-inbound';
      }

    }

    if (this.taskInformation && this.taskInformation.type === 'campaign') {
      return 'call-outbound';
    }

    return _channel;
  }

  @computedFrom('callOutcome')
  get callOutcomeColor(): string {
    return outcomeColours[this.callOutcome ? this.outcome : 'received'];
  }

  @computedFrom('type', 'channel')
  get outcome(): string {
    let _outcome = _case.lower(this.callOutcome);
    if (_case.lower(this.channel) === 'email' || _case.lower(this.channel) === 'sms') {
      if (_case.lower(this.type) === 'outbound') {
        _outcome = 'sent';
      } else {
        _outcome = 'received';
      }
    }

    if (_case.lower(this.channel) === 'chat') {
      if (_case.lower(this.type) === 'inbound') {
        _outcome = 'answered';
      } else {
        _outcome = 'abandoned';
      }
    }

    if (!_outcome) {
      _outcome = 'no call outcome';
    }

    return _outcome;
  }

  @computedFrom('taskName', 'type', 'channel', 'flowName', 'callOutcome')
  get formattedWorkType(): string {
    if (this.type === 'Internal') {
      return 'Internal';
    } else if (this.taskInformation && this.taskInformation.type === 'campaign') {
      return this.workType;
    } else if (!this.taskName && !this.flowName) {
      return 'Private';
    } else if (this.flowName && this.callOutcome === 'mailbox') {
      return this.flowName;
    } else if (!this.taskName && this.flowName) {
      return this.workType;
    } else {
      return this.taskName;
    }
  }
}

export class ConversationInteraction {

  public metaData: MetaData;
  public agent: Agent
}

class MetaData {

  public workType: string;
  public flowName: string;
  public channel: string;
  public direction: string;
  public from: string;
  public to: string;
  public agentRatingSurveyPercentage: number;
  public date: number;
  public duration: number;
  public recordingActive: boolean;
  public recordingPaused: boolean;
  public connectedMemberIds: Array<string>;
}

class Agent {

  public memberId: string;
  public firstName: string;
  public surname: string;
}

class TaskInformation {

  public type: string;
  public contact: Contact;
  public metadata: TaskMetaData;
}

class Contact {

  public id: string;
  public contactNumber: string;
  public firstName: string;
  public surname: string;
}

class TaskMetaData {

  public prospectListName: string;
  public paused: boolean;
  public campaignId: string;
  public diallerType: string;
  public campaignExternalReference: string;
  public campaignName: string;
  public prospectListExternalReference: string;
  public prospectListId: string;
}

let _case = {
  lower: (string) => {
    return string ? string.toLowerCase() : false;
  },
  first: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};