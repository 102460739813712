import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('MembersModel');

export class MembersModel extends BaseModel {

  memberId = null;
  organisationId = null;
  userId = null;
  firstName = null;
  surname = null;
  email = null;

  constructor(member) {
    super();
    this.mapProperties(member);
  }

 }