import {autoinject, observable, LogManager} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationRules, validateTrigger} from 'aurelia-validation';

import moment from 'moment-timezone';

import { BootstrapFormRenderer } from 'zailab.common';

const logger = LogManager.getLogger('AbstractReportView');

@autoinject
export class AbstractReportView {

  // TODO: MS 17-08-2018 Change previousDate to currentDate because the data is mostly used to check current date
  protected previousDate: moment = null;
  @observable protected selectedDate: moment = null;
  @observable protected selectedStartDate: moment = null;
  @observable protected selectedEndDate: moment = null;
  protected canDownload: boolean = false;
  protected validation = null;
  protected reportName: string = '';
  protected dateFormat: string = 'DD MMM YYYY';
  protected formattedDate: moment;
  protected downloading: boolean = false;
  protected downloadStatusMessage: string = '';

  constructor(protected validationControllerFactory: ValidationControllerFactory) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    this.setPreviousDate();
  }

  protected setPreviousDate(): void {
    this.previousDate = moment.utc().subtract(1, 'days').format(this.dateFormat);
  }

  protected get fileName(): string {
    let _fileName = this.reportName +  '_' + this.reportName;
      return _fileName.replace(/ /g, '-');
  }

  protected selectedDateChanged(): void {
    this.validate();
  }

  protected selectedStartDateChanged(): void {
    this.validate();
  }

  protected selectedEndDateChanged(): void {
    this.validate();
  }

  protected validate(): void {
    if(!this.selectedDate) {
      this.formattedDate = moment.utc(this.previousDate);
    } else {
      this.formattedDate = moment.utc(this.selectedDate).format('x');
    }
    if(this.validation) {
      this.validation.validate().then(validation => {
        this.canDownload = validation.valid;
      });
    }
  }
}