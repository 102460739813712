import {autoinject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {SessionStore} from 'zailab.common';

import {LiveDashboardService} from '../live-dashboard-service';
import {AbstractLiveDashboardDrillDown} from '../abstract-live-dashboard-drilldown';

const logger = LogManager.getLogger('TotalReceived');

@autoinject
export class TotalReceived extends AbstractLiveDashboardDrillDown {

  constructor(protected liveDashboardService: LiveDashboardService, protected eventAggregator: EventAggregator, protected sessionStore: SessionStore) {
    super(eventAggregator, liveDashboardService, sessionStore);
    this.filters = ['Calls', 'SMS', 'Email','Chat', 'Instant Message', 'Ticket'];
    this.resource = 'total-received';
  }
}