import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
import { v4 as uuidv4 } from 'uuid';
/*
 */
const logger = LogManager.getLogger('InteractionFlowModel');
/*
 */
export class InteractionFlowModel extends BaseModel {

  interactionFlowId = uuidv4();
  flowName = null;
  flowType = 'Contact Centre';
  callerId = null;
  additionalData = [];
  channel = null;
  numbers = [];
  emailAddresses = [];
  webhooks = [];
  imConfigIds = [];
  defined = null;
  isSelected = false;
  isDeleting = false;
  versions = [];
  liveVersion = null;
  subflow = false;

  constructor(interaction) {
    super();
    if (interaction) {
      this.mapProperties(interaction);
      this._mapVersions(interaction.versions);
    }
  }

  _mapVersions(versions) {
    if (versions) {
      this.versions = Object.keys(versions).map(key => {
        const version = {
          version: key,
          isLive: versions[key],
          actions: this._defaultActions
        };
        if (versions[key] === true) {
          this.liveVersion = version;
        }
        return version;
      });
    }
  }

  @computedFrom('defined')
  get canDelete() {
    if(this.defined)
    return false;
  }

  @computedFrom('channel')
  get channelIcon() {
    let ChannelTypeIcon = {
      'Email'           : 'channel-email',
      'Inbound Call'    : 'channel-incoming',
      'Outbound Call'   : 'channel-outgoing',
      'SMS'             : 'channel-sms',
      'Chat'            : 'comments-square',
      'Instant Message' : 'comments-square',
      'Ticket' : 'channel-ticket'
    }
    return ChannelTypeIcon[this.channel];
  }

  @computedFrom('channel')
  get itemsAssignedLabel() {
    let channelType = 'Number';
    if (this.channel === 'Email') {
      channelType = 'Email';
    } else if (this.channel === 'Ticket') {
      channelType = 'Webhook';
    }
    if ((this.channel === 'Email' && this.emailAddresses.length !== 1) || (this.channel !== 'Email' && this.numbers.length !== 1)) {
      channelType = channelType + 's';
    }
    return channelType + ' Assigned';
  }

  @computedFrom('channel')
  get mediaAssignedToFlowIcon() {
    let setFlowIcon = false;
    if ((this.channel === 'Email' && this.emailAddresses.length > 0)
      || ((this.channel === 'SMS' || this.channel === 'Inbound Call' || this.channel === 'Outbound Call') && this.numbers.length > 0)
      || (this.channel === 'Instant Message' && this.imConfigIds.length > 0)
      || (this.channel === 'Ticket' && this.webhooks.length > 0)) {
      setFlowIcon = true;
    }
    return this.flowIcon(setFlowIcon);
  }

  @computedFrom('channel')
  get mediaAssignedToFlowIconColor() {
    let setFlowColor = false;
    if (this.emailAddresses.length > 0 || this.numbers.length > 0 || this.imConfigIds.length > 0 || this.webhooks.length > 0) {
      setFlowColor = true
    }
    return this.flowIconColor(setFlowColor);
  }

  @computedFrom('channel')
  get mediaType() {
    let mediaFlow = this.channel.toLowerCase().indexOf('call') > -1 ? 'Call Flow' : this.channel + ' Flow';
    return mediaFlow;
  }

  @computedFrom('channel')
  get communicationDirection() {
    let communicationFlow = this.channel.toLowerCase().indexOf('outbound') > -1 ? 'Outbound' : 'Inbound';
    return communicationFlow;
  }

  @computedFrom('emailAddresses', 'numbers')
  get mediaAssignedToFlow() {
    let getMediaCount = 0;
    if(this.channel === 'Email'){
      getMediaCount = this.emailAddresses.length;
    } else if(this.channel === 'Instant Message'){
      getMediaCount = this.imConfigIds.length;
    } else if(this.channel === 'Ticket'){
      getMediaCount = this.webhooks.length;
    } else {
      getMediaCount = this.numbers.length
    }
    return getMediaCount;
  }

  @computedFrom('defined')
  get workFlowIcon() {
    return this.flowIcon(this.defined);
  }

  @computedFrom('defined')
  get workFlowIconColor() {
    return this.flowIconColor(this.defined);
  }

  @computedFrom('defined')
  get workFlowMessage() {
    let workFlowStatus = this.defined ? 'Work Flow Defined' : 'No Work Flow Defined';
    return workFlowStatus;
  }

  @computedFrom('callerId')
  get callerIdIcon() {
    return this.flowIcon(this.callerId ? true : 'default');
  }

  @computedFrom('callerId')
  get callerIdIconColor() {
    return this.flowIconColor(this.callerId ? true : 'default');
  }

  @computedFrom('callerId')
  get callerIdMessage() {
    return this.callerId || 'No Caller ID Assigned';
  }

  @computedFrom('callerId')
  get callerIdAssignment() {
    let callerIdMessage = this.callerId ? 'Edit Caller ID' : 'Assign Caller ID'
    return callerIdMessage;
  }

  flowIcon(passed) {
    if (passed === 'default') {
      return 'dash';
    } else if (passed) {
      return 'confirm';
    } else {
      return 'close';
    }
  }

  flowIconColor(passed) {
    if (passed === 'default') {
      return 'white';
    } else if (passed) {
      return 'green';
    } else {
      return 'red';
    }
  }
}
