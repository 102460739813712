/* 
 * TODO: was unable to use sanitize-html with jspm based project
 * replace xss when project is migrated to webpack
 */
import xss from "xss";

const emailHtmlwhiteList = {};

/*
 * xss's css: false feaure is not working, so doing deep copy and 
 * manually adding style attribute to all whitelisted items
 */
for (let k in xss.whiteList) {
  emailHtmlwhiteList[k] = xss.whiteList[k].slice();
  emailHtmlwhiteList[k].push("style");
}

export class EmailHtmlToViewValueConverter {
  toView(html) {

    if(!html) {
      return '';
    }

    const xssFiler = new xss.FilterXSS({
      css: false,
      whiteList: emailHtmlwhiteList,
      stripIgnoreTag: true,
    });

    return xssFiler.process(html);
  }
}