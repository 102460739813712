import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { MailboxService } from 'zailab.common';
import { DashboardWidget } from '../../organisation/organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('Audio Widget');

@autoinject()
export class MailboxDashboardWidget extends DashboardWidget {

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private mailboxService: MailboxService
  ) {
    super();
  }

  public attached(): void {
    this.mailboxService.getMailboxCount().then((response: number) => {
      this.count = response;
      this.ready = true;
    }, (error) => {
      logger.info('Could not retrieve mailboxes for your organisation', error);
    });
  };
}
