import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('PlaceholderService');

export class PlaceholderService {

  private container: Element;
  private computedStyles: CSSStyleDeclaration;
  private numberOfPlaceholdersCalculated: (placeholders: number) => void;
  private resizeTimeout: number;
  private listLength: number = 0;
  private numberOfRowsInViewPort: number = 0;

  constructor(container: Element, listLength: number, numberOfRowsInViewPort: number, numberOfPlaceholdersCalculated: (placeholders: number) => void) {
    this.container = container;
    try {
      this.computedStyles = window.getComputedStyle(this.container, null);
    } catch(e) {}
    this.numberOfPlaceholdersCalculated = numberOfPlaceholdersCalculated;
    this.numberOfRowsInViewPort = numberOfRowsInViewPort;
    this.generatePlaceholders(listLength);

    // todo: Phillip - clean up event listener
    window.addEventListener('resize', () => {
      if(this.resizeTimeout) {
        window.clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.generatePlaceholders(this.listLength);
      }, 500);
    });
  }

  public generatePlaceholders(listLength: number): void {

    this.listLength = listLength;
    this.numberOfPlaceholdersCalculated(0);

    // setTimeout used to add placeholders after view has rendered content
    setTimeout(() => {
      let numberOfColumns = this.calculateNumberOfColumnsInView();
      let numberOfRows = this.calculateNumberOfRowsInView();

      let placeholders = (this.numberOfRowsInViewPort * numberOfColumns) - listLength + (numberOfRows - this.numberOfRowsInViewPort > 0 ? (numberOfRows - this.numberOfRowsInViewPort) * numberOfColumns : 0);
      this.numberOfPlaceholdersCalculated(placeholders);
    }, 50);
  }

  private calculateNumberOfColumnsInView(): number {
    try {
      return this.stringValueToArray(this.computedStyles.gridTemplateColumns).length;
    } catch(e) {
      return 0;
    }
  }
  private calculateNumberOfRowsInView(): number {
    try {
      return this.stringValueToArray(this.computedStyles.gridTemplateRows).length;
    } catch(e) {
      return 0;
    }
  }

  private stringValueToArray(stringValue: string): any[] {

    let _arrayOfStrings = [];

    if(stringValue) {
      _arrayOfStrings = stringValue.split(' ');
    }
    return _arrayOfStrings;
  }
}
