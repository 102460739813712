import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/*
 */
import Service from '../prospect-list-service';
/*
 */
const logger = LogManager.getLogger('UploadResultsDialog');
/*
 */
@inject(DialogController, Service)
export default class {

  results;

  constructor(dialogController, service) {
    this.dialogController = dialogController;
    this.service = service;
  }

  activate(item) {

    this.prospectList = item;

    this.service.retrieveUploadResults(this.prospectList.prospectListId).then((response) => {

      this.results = response;
    }, (error) => {
      logger.warn('Could not retrieve Upload Results', error);
    });
  }

  downloadProspectListResults() {

    this.service.downloadProspectListResults(this.prospectList).then((response) => {
    }, (error) => {
      logger.warn('Could not retrieve Uploaded Results To Download', error);
    });
  }

  close() {
    this.dialogController.cancel();
  }
}