import {inject, LogManager, computedFrom} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('BuyCreditModel');

export class BuyCreditModel extends BaseModel {

  accountId = null;
  organisationId = null;
  cards = [
    {
      cardId: null,
      sanitizedCardNumber: null,
      cardType: null,
      cardHolder: null,
      expiryMonth: null,
      expiryYear: null,
      token: null,
      metadata: {
        stripeCustomerId:null
      }
    }
  ];
  metadata = {
    stripeCustomerId: null
  }

  /**
   * @namespace
   * @property {String} email
   * @property {String} firstName
   * @property {String} surname
   * */
  contact = null;

  constructor(accountInformation) {
    super();
    this.mapProperties(accountInformation);
  }

}
