import { ObserverLocator, LogManager, autoinject, InternalPropertyObserver } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
/**/
import { UserPersonService } from '../user-person-service';
import { CallForwardModel } from '../models/call-forward-model';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { TimeRangeModel } from './models/time-range-model';
import { TimezoneService } from './services/timezone-service';
import { TelephoneNumberService } from './services/telephone-number-service';
/**/
const logger = LogManager.getLogger('CallForwardDialog');

interface INumberType {
  description: string;
  value: string;
}

@autoinject
export class CallForwardDialog {

  private validation: ValidationController;
  private validationMessage: string;
  private telephoneNumber: TelephoneNumberModel;
  private telephoneNumberTypes: INumberType[];
  private callForward: CallForwardModel;
  private timezones: any[];
  private selectedTimezone: ZITimezone;
  private selectedNumberType: INumberType;
  private timeRange: TimeRangeModel;
  private extensionNumber: string;
  private timeRangeConverter: TimeRangeConverter;

  constructor(private dialogController: DialogController,
    private timezoneService: TimezoneService,
    private telephoneNumberService: TelephoneNumberService,
    private validationControllerFactory: ValidationControllerFactory,
    private userPersonService: UserPersonService,
    private sessionStore: SessionStore,
    private observerLocator: ObserverLocator) {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(callForward: CallForwardModel): void {

    ValidationRules
      .ensure('extensionNumber').required().withMessage('Please enter a number.')
      .minLength(5).withMessage('Number is too short.')
      .maxLength(5).withMessage('Number is too long.')
      .on(this);

    this.extensionNumber = callForward.numberType === 'EXT' ? callForward.number : null;
    this.callForward = new CallForwardModel(callForward);
    this.telephoneNumber = this.telephoneNumberService.extractTelephoneNumber(callForward);
    this.telephoneNumberTypes = this.telephoneNumberService.getTelephoneNumberTypes();
    this.selectedNumberType = this.telephoneNumberService.getSelectedNumberType(callForward.numberType);
    this.timezones = this.timezoneService.getTimezones();
    this.selectedTimezone = this.timezoneService.getSelectedTimezone(callForward.timeZone);
    this.timeRange = new TimeRangeModel(callForward.fromTime, callForward.toTime);
    this.timeRangeConverter = new TimeRangeConverter(this.observerLocator, this.timeRange);
  }

  public deactivate(): void {
    this._log('deactivate');
    this.timeRangeConverter.close();
  }

  /*
   * Checks the validity of the dialog. */
  get isValid(): boolean {

    let isValid = true;

    if (this.selectedNumberType.value === 'NUMBER') {
      isValid = this.telephoneNumber.isNumberValid;
    } else {
      isValid = this.extensionNumber && this.extensionNumber.length === 5;
    }

    return isValid;
  }

  public ok(): void {

    let callForwardDetails = new CallForwardModel();

    callForwardDetails.enabled = this.callForward.enabled ? true : false;
    callForwardDetails.number = this.getNumber();
    callForwardDetails.fromTime = this.timeRange.fromTime;
    callForwardDetails.toTime = this.timeRange.toTime;
    callForwardDetails.timeZone = this.selectedTimezone.name;
    callForwardDetails.numberType = this.selectedNumberType.value;

    this.userPersonService.changeCallForwarding(this.sessionStore.get.user.memberId, callForwardDetails).then(
      success => this.dialogController.ok(callForwardDetails),
      failure => {
        this.validationMessage = 'Failed to update Call Forwarding, please try again.';
      });
  }

  private getNumber(): string {
    let isExtension = this.selectedNumberType.value === 'EXT';
    return isExtension ? this.extensionNumber : this.telephoneNumber.number;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }
}

class TimeRangeConverter {

  private timeRangeModel: TimeRangeModel;
  private startingHourObserver: InternalPropertyObserver;
  private startingMinuteObserver: InternalPropertyObserver;
  private endingHourObserver: InternalPropertyObserver;
  private endingMinuteObserver: InternalPropertyObserver;

  constructor(observerLocator: ObserverLocator, timeRangeModel: TimeRangeModel) {
    this.timeRangeModel = timeRangeModel;
    this.startingHourObserver = observerLocator.getObserver(timeRangeModel, 'startingHour');
    this.startingMinuteObserver = observerLocator.getObserver(timeRangeModel, 'startingMinute');
    this.endingHourObserver = observerLocator.getObserver(timeRangeModel, 'endingHour');
    this.endingMinuteObserver = observerLocator.getObserver(timeRangeModel, 'endingMinute');
    this.startingHourObserver.subscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'startingHour'));
    this.startingMinuteObserver.subscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'startingMinute'));
    this.endingHourObserver.subscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'endingHour'));
    this.endingMinuteObserver.subscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'endingMinute'));
  }

  private onTimeRangeChange(newValue: any, oldValue: any, fieldName: string): void {
    let value = 1 * newValue;
    if (fieldName.indexOf('Hour') >= 0 && value > 24) { value = 0; }
    else if (fieldName.indexOf('Minute') >= 0 && value > 59) { value = 0; }
    this.timeRangeModel[fieldName] = value;
  }

  public close(): void {
    this.startingHourObserver.unsubscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'startingHour'));
    this.startingMinuteObserver.unsubscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'startingMinute'));
    this.endingHourObserver.unsubscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'endingHour'));
    this.endingMinuteObserver.unsubscribe((newValue, oldValue) => this.onTimeRangeChange(newValue, oldValue, 'endingMinute'));
  }
}