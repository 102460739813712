import { DialogController, DialogService } from 'aurelia-dialog';
import { inject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';

import { v4 as uuidv4 } from 'uuid';

import { BootstrapFormRenderer, FeatureFlagService, SessionStore } from 'zailab.common';
import OrganisationService from '../organisation-service';
import { RolesService } from '../roles/roles-service';
import { WorkforceManagement } from './verint-configuration-model';
import { VerintService } from './verint-service';
import { ConfirmDialog } from 'features/organisation/conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';

const logger = LogManager.getLogger('VerintPlatformAPIDialog');

@inject(DialogController, ValidationControllerFactory, OrganisationService, VerintService, RolesService, FeatureFlagService, DialogService, SessionStore)
export class VerintPlatformAPIDialog {

  dialogHeader = "Platform API";
  workforceManagement = new WorkforceManagement();
  verintSftpHost;
  verintSftpPort;
  verintSftpUsername;
  verintSftpPassword;
  verintSftpPublicKey;
  verintSftpPrivateKey;
  verintSftpPassphrase;
  verintApiHost;
  verintApiToken;
  verintApiOrganisationId;
  verintOrganizationSuffix;
  verintRoleMappingAdministrator;
  verintRoleMappingTeamLeader;
  verintRoleMappingAgent;
  verintRoleMappingQA;
  verintRoleMappingQAManager;
  verintRoleMappingCampaignManager;
  verintRoleMappingOfficeEmployee;
  verintScopeMappingAdministrator;
  verintScopeMappingTeamLeader;
  verintScopeMappingAgent;
  verintScopeMappingQA;
  verintScopeMappingQAManager;
  verintScopeMappingCampaignManager;
  verintScopeMappingOfficeEmployee;
  verintAbandonedOutcomes = [
    { name: 'Callback', checked: false },
    { name: 'Mailbox', checked: false },
    { name: 'Missed', checked: false },
  ];
  verintDataSourceMapping;
  verintDataSourceMap = {
    'memberId': 'Member ID',
    'extension': 'Extension Number',
    'externalReference': 'External Reference'
  };
  reversedVerintDataSourceMap = {
    'Member ID': 'memberId',
    'Extension Number': 'extension',
    'External Reference': 'externalReference'
  };
  verintPublicPrivateKeyEnabled;
  verintRoles;
  verintScopes = [
    'Organisation',
    'Business Partner',
  ];
  roles;
  loading = false;

  constructor(
    dialogController,
    validationControllerFactory,
    organisationService,
    verintService,
    rolesService,
    featureFlagService,
    dialogService,
    sessionStore
  ) {
    this.dialogController = dialogController;
    this.organisationService = organisationService;
    this.verintService = verintService;
    this.rolesService = rolesService;
    this.featureFlagService = featureFlagService;
    this.dialogService = dialogService;
    this.sessionStore = sessionStore;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(data) {
    this.loading = true;
    this.workforceManagement = data;
    this.verintEmployeeSyncEnabled = data.verintEmployeeSyncEnabled || false;
    this.verintSftpHost = data.verintSftpHost;
    this.verintSftpPort = data.verintSftpPort;
    this.verintSftpUsername = data.verintSftpUsername;
    this.verintSftpPassword = data.verintSftpPassword;
    this.verintSftpPublicKey = data.verintSftpPublicKey;
    this.verintSftpPrivateKey = data.verintSftpPrivateKey;
    this.verintSftpPassphrase = data.verintSftpPassphrase;
    this.verintApiHost = data.verintApiHost;
    this.verintApiToken = data.verintApiToken;
    this.verintApiOrganisationId = data.verintApiOrganisationId;
    this.verintOrganizationSuffix = data.verintOrganizationSuffix;
    
    if (data.verintSftpHost) {
      this.verintDataSourceMapping = this.verintDataSourceMap[data.verintDataSourceMapping] || 'External Reference';
    } else {
      this.verintDataSourceMapping = this.verintDataSourceMap[data.verintDataSourceMapping] || 'Member ID';
    }

    this.verintScopeMappingAdministrator = data.verintScopeMappingAdministrator;
    this.verintScopeMappingTeamLeader = data.verintScopeMappingTeamLeader;
    this.verintScopeMappingAgent = data.verintScopeMappingAgent;
    this.verintScopeMappingQA = data.verintScopeMappingQA;
    this.verintScopeMappingQAManager = data.verintScopeMappingQAManager;
    this.verintScopeMappingCampaignManager = data.verintScopeMappingCampaignManager;
    this.verintScopeMappingOfficeEmployee = data.verintScopeMappingOfficeEmployee;
    if (data.verintRoleMappingAdministrator && data.verintRoleMappingAdministrator.length) {
      this.verintRoleMappingAdministrator = data.verintRoleMappingAdministrator[0];
    }
    if (data.verintRoleMappingTeamLeader && data.verintRoleMappingTeamLeader.length) {
      this.verintRoleMappingTeamLeader = data.verintRoleMappingTeamLeader[0];
    }
    if (data.verintRoleMappingAgent && data.verintRoleMappingAgent.length) {
      this.verintRoleMappingAgent = data.verintRoleMappingAgent[0];
    }
    if (data.verintRoleMappingQA && data.verintRoleMappingQA.length) {
      this.verintRoleMappingQA = data.verintRoleMappingQA[0];
    }
    if (data.verintRoleMappingQAManager && data.verintRoleMappingQAManager.length) {
      this.verintRoleMappingQAManager = data.verintRoleMappingQAManager[0];
    }
    if (data.verintRoleMappingCampaignManager && data.verintRoleMappingCampaignManager.length) {
      this.verintRoleMappingCampaignManager = data.verintRoleMappingCampaignManager[0];
    }
    if (data.verintRoleMappingOfficeEmployee && data.verintRoleMappingOfficeEmployee.length) {
      this.verintRoleMappingOfficeEmployee = data.verintRoleMappingOfficeEmployee[0];
    }
    if (data.verintAbandonedOutcomes && data.verintAbandonedOutcomes.length) {
      for (const outcome of data.verintAbandonedOutcomes) {
        const oc = this.verintAbandonedOutcomes.find((oc) => oc.name === outcome);
        if (!oc) {
          continue;
        }
        oc.checked = true;
      }
    }
    this.verintPublicPrivateKeyEnabled = !!data.verintSftpPublicKey;

    this.verintService.getRoles().then((roles) => {
      this.verintRoles = roles;
      this.loading = false;
    });

    this.rolesService.displayRoles().then((roles) => {
      this.roles = roles;
    });

    ValidationRules
      .ensure('verintApiHost').required()
      .ensure('verintApiToken').required()
      .ensure('verintApiOrganisationId').required()
      .ensure('verintOrganizationSuffix').required()
      .on(this);
  }

  cancel() {
    this.dialogController.cancel();
  }

  save() {
    this.loading = true;
    this.validateAndUpdate(() => {
      this.loading = false;
      this.dialogController.ok(this.workforceManagement);
    }, () => {
      this.loading = false;
    });
  }

  validateAndUpdate(resolve, reject) {

    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        reject();
        return;
      }
      if (!this.workforceManagement.workforceManagementId) {
        this.workforceManagement.workforceManagementId = uuidv4();
      }
      this.workforceManagement.verintEmployeeSyncEnabled = this.verintEmployeeSyncEnabled;
      this.workforceManagement.verintSftpHost = this.verintSftpHost;
      this.workforceManagement.verintSftpPort = this.verintSftpPort;
      this.workforceManagement.verintSftpUsername = this.verintSftpUsername;
      this.workforceManagement.verintSftpPassword = this.verintSftpPassword;
      this.workforceManagement.verintSftpPublicKey = this.verintSftpPublicKey;
      this.workforceManagement.verintSftpPrivateKey = this.verintSftpPrivateKey;
      this.workforceManagement.verintSftpPassphrase = this.verintSftpPassphrase;
      this.workforceManagement.verintApiHost = this.verintApiHost;
      this.workforceManagement.verintApiToken = this.verintApiToken;
      this.workforceManagement.verintApiOrganisationId = this.verintApiOrganisationId;
      this.workforceManagement.verintOrganizationSuffix = this.verintOrganizationSuffix;
      this.workforceManagement.verintDataSourceMapping = this.reversedVerintDataSourceMap[this.verintDataSourceMapping];
      this.workforceManagement.verintAbandonedOutcomes = this.verintAbandonedOutcomes.filter((outcome) => outcome.checked).map((outcome) => outcome.name);
      this.workforceManagement.verintScopeMappingAdministrator = this.verintScopeMappingAdministrator;
      this.workforceManagement.verintScopeMappingTeamLeader = this.verintScopeMappingTeamLeader;
      this.workforceManagement.verintScopeMappingAgent = this.verintScopeMappingAgent;
      this.workforceManagement.verintScopeMappingQA = this.verintScopeMappingQA;
      this.workforceManagement.verintScopeMappingQAManager = this.verintScopeMappingQAManager;
      this.workforceManagement.verintScopeMappingCampaignManager = this.verintScopeMappingCampaignManager;
      this.workforceManagement.verintScopeMappingOfficeEmployee = this.verintScopeMappingOfficeEmployee;
      if (this.verintRoleMappingAdministrator) {
        this.workforceManagement.verintRoleMappingAdministrator = [this.verintRoleMappingAdministrator];
      }
      if (this.verintRoleMappingTeamLeader) {
        this.workforceManagement.verintRoleMappingTeamLeader = [this.verintRoleMappingTeamLeader];
      }
      if (this.verintRoleMappingAgent) {
        this.workforceManagement.verintRoleMappingAgent = [this.verintRoleMappingAgent];
      }
      if (this.verintRoleMappingQA) {
        this.workforceManagement.verintRoleMappingQA = [this.verintRoleMappingQA];
      }
      if (this.verintRoleMappingQAManager) {
        this.workforceManagement.verintRoleMappingQAManager = [this.verintRoleMappingQAManager];
      }
      if (this.verintRoleMappingCampaignManager) {
        this.workforceManagement.verintRoleMappingCampaignManager = [this.verintRoleMappingCampaignManager];
      }
      if (this.verintRoleMappingOfficeEmployee) {
        this.workforceManagement.verintRoleMappingOfficeEmployee = [this.verintRoleMappingOfficeEmployee];
      }

      this.organisationService.setWorkforceManagementPlatformApi(this.sessionStore.get.organisation.organisationId, this.workforceManagement)
        .then(() => resolve());
    });
  }

  updateVerint() {
    this.loading = true;
    this.validateAndUpdate(() => {
      this.loading = false;
      this.dialogController.ok(this.workforceManagement);
    }, () => {
      this.loading = false;
    });
  }
}

