import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('DisplayNumbersModel');

export default class extends BaseModel {

  telephoneNumberId = null;
  telephoneNumber = null;
  status = null;
  numberType = null;
  assignee = {};
  actions = [];

  constructor(number) {
    super();
    this.mapProperties(number);
  }

  @computedFrom('assignee')
  get description() {
    if (this.assignee.id) {
      if (this.assignee.type === 'FLOW') {
        return this.flowName;
      }
      if (this.assignee.type === 'MEMBER') {
        return `${this.assignee.firstName} ${this.assignee.surname}`;
      }
    }
    return '';
  }

  @computedFrom('assignee')
  get descriptionTwo() {
    if (this.assignee.id) {
      if (this.assignee.type === 'MEMBER') {
        return `${this.assignee.email}`;
      }
    }
    return '';
  }


  @computedFrom('assignee')
  get flowName() {
    return this.assignee.flowName
  }

  @computedFrom('numberType', 'assignee')
  get canUnassign() {
    if (this.numberType === 'SMS') {
      return false;
    }
    if (!this.assignee.id) {
      return false;
    }
    if (this.assignee && this.assignee.type === 'FLOW') {
      return false;
    }
    return true;
  }

  @computedFrom('numberType', 'assignee')
  get canAssign() {
    if (this.numberType === 'SMS') {
      return false;
    }
    if (this.assignee.id) {
      return false;
    }
    return true;
  }

  @computedFrom('numberType', 'assignee')
  get numberIcon() {
    if (this.numberType === 'SMS' && this.assignee.flowName) {
      return 'flow';
    }
    if (this.numberType === 'SMS' && !this.assignee.flowName) {
      return 'sms';
    }

    if (this.assignee.id) {
      if (this.assignee.type === 'FLOW') {
        return 'flow';
      }
      if (this.assignee.type === 'MEMBER') {
        return 'member';
      }
    }
    return 'numbers';
  }

  set assignedMember(assignee) {
    this.assignee = {};
    this.assignee.firstName = assignee.firstName ? assignee.firstName : '';
    this.assignee.surname = assignee.surname;
    this.assignee.email = assignee.email;
    this.assignee.id = assignee.memberId;
    this.assignee.userId = assignee.userId;
    this.assignee.type = 'MEMBER';
  }
 }
