export class FormatTools {
  public static toTitleCase(value: string): string {
    return value.replace(
      /\w\S*/g,
      function(txt: string): string {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
  }

  public static convertToNameCase(inputString: string): string {
    if (!inputString) {
      return '';
    }
    const words = inputString.split(' ');
    const titleCaseWords = words.map(word => {
      if (!word) {
        return '';
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const titleCaseString = titleCaseWords.join(' ');
    return titleCaseString;
  }

  public static parseIntOrDefault(numberToParse: string, defaultValue: number): number {
    const value = parseInt(numberToParse, 10);

    return isNaN(value) ? defaultValue : value;
  }
}
