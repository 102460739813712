import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import {
  ArrayTools,
  DateTimeTools,
  Event,
  ZaiForm,
} from 'zailab.common';
import { InHouseWFMService } from '../../in-house-wfm-service';
import { PresenceService } from '../../../../../user/passport/presence/presence-service';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import './add-shift-template-dialog.scss';

const logger = LogManager.getLogger('AddShiftTemplateDialog');

@autoinject()
export class AddShiftTemplateDialog {
  private shiftTemplateId: string;
  private name = '';
  private startTime = '08:00';
  private duration = '08:00';
  private existingNames: string[] = [];

  public presences: { presenceCodeName: string }[];

  public form: any[][];
  public formData: {
    name: string;
    startTimeOfDay: string;
    shiftDurationInMin: string;
    valid: boolean;
  };
  private submitting: boolean;

  public breakForm: any[][];
  public breakFormData;

  public nonQueueWorkForm: any[][];
  public nonQueueWorkFormData;

  public tableColumns = ['Name', 'Time', 'Duration'];
  private editingBreakIndex: number;
  private breakNameSet: string;
  private break: {
    name: string;
    maxDurationInMin: string;
    minStartTimeOfDay: string;
    maxStartTimeOfDay: string;
    presence: string;
  };
  public breaksTable = [];
  public breaks = [];
  public editingBreak: any;

  public nonQueueWorkTableColumns = ['Presence', 'Duration'];
  private nonQueueWork: {
    maxDurationInMin: string;
    presence: string;
  };
  public nonQueueWorkTable = [];
  public nonQueueWorkList = [];

  private shiftTemplate: any;
  public error: string;
  public loading = false;

  constructor(
    private dialogController: DialogController,
    private inHouseWFMService: InHouseWFMService,
    private presenceService: PresenceService,
    private dateTimeTools: DateTimeTools
  ) {}

  public async activate(data: {
    existingNames: string[];
    shiftTemplate?: {
      id: string;
      name: string;
      startTime: string;
      duration: string;
      breaks: any[];
    };
  }): Promise<void> {
    this.existingNames = data.existingNames;

    if (data.shiftTemplate) {
      this.shiftTemplateId = data.shiftTemplate.id;
      this.getShiftTemplate();
    } else {
      this.setBreakState();
      this.setNonQueueWorkState();
      this.generateShiftTemplateForm();
      this.retrievePresences();
      this.populateBreaksTable();
    }
  }

  private async getShiftTemplate(): Promise<void> {
    this.loading = true;
    this.shiftTemplate = await this.inHouseWFMService
      .retrieveShiftTemplate(this.shiftTemplateId)
      .catch((e) =>
        console.warn(' > Failed to retrieve shift templates due to ', e)
      );

    if (this.shiftTemplate) {
      this.name = this.shiftTemplate.name;
      this.startTime = this.shiftTemplate.startTimeOfDay;
      this.duration = this.dateTimeTools.convertMinutesToTimeString(
        this.shiftTemplate.shiftDurationInMin
      );

      this.breaks = this.shiftTemplate.breaks.map((br) => {
        br.maxDurationInMin = this.dateTimeTools.convertMinutesToTimeString(
          br.maxDurationInMin
        );
        return br;
      });

      this.nonQueueWorkList = Object.entries(
        this.shiftTemplate.offDutyWork
      ).map(([presence, maxDurationInMin]) => {
        const data = {
          presence,
          maxDurationInMin,
        };

        const convertedTime =
          this.dateTimeTools.minutesToHoursAndMinutesString(maxDurationInMin);
        this.nonQueueWorkTable.push([presence, convertedTime]);
        return data;
      });
    }

    await this.setBreakState();
    await this.setNonQueueWorkState();
    await this.generateShiftTemplateForm();
    await this.retrievePresences();
    await this.populateBreaksTable();
    this.loading = false;
  }

  private generateShiftTemplateForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput()
      .fullWidth()
      .withFocus()
      .withTitle('Shift Template Name', 'calc(50% + 10px)')
      .withPlaceholder('Enter shift template name here.')
      .withIdentifier('name')
      .withValue(this.name)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        { validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 50 },
        {
          validationType: ZaiForm.VALIDATION_TYPES.UNIQUE_NAME,
          value: this.existingNames,
        },
      ])
      .insertField()

      .newRow()
      .newField()
      .asTimeInput()
      .withTitle('Start Time', '138px')
      .withIdentifier('startTimeOfDay')
      .withValue(this.startTime)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()
      .newField()
      .asTimeInput()
      .withTitle('Duration', '100px')
      .withIdentifier('shiftDurationInMin')
      .withValue(this.duration)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  private setBreakState(): void {
    this.break = {
      name: '',
      minStartTimeOfDay: '08:00',
      maxStartTimeOfDay: '21:00',
      maxDurationInMin: '01:00',
      presence: '',
    };
  }

  private async retrievePresences(): Promise<void> {
    this.presences = await this.presenceService
      .retrievePresences()
      .catch((e) => console.warn(' > Failed to retrieve presences due to', e));

    if (this.presences?.length > 0) {
      this.presences = ArrayTools.sort(this.presences, 'presenceCodeName');
    }

    this.generateBreaksForm();
    this.generateNonQueueWorkForm();
  }

  private generateBreaksForm(): void {
    this.breakForm = null;
    setTimeout(() => {
      new ZaiForm()
        .newField()
        .asDropdown()
        .withTitle('Presence', '138px')
        .withIdentifier('presence')
        .withInputWidth('138px')
        .withPlaceholder('Select a presence')
        .withDisplayField('presenceCodeName')
        .withOptions(this.presences)
        .withValue(this.break.presence)
        .insertField()

        .newField()
        .asTimeInput()
        .withTitle('Max Duration', '105px')
        .withIdentifier('maxDurationInMin')
        .withValue(this.break.maxDurationInMin)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .newRow()
        .newField()
        .asTextInput()
        .fullWidth()
        .withFocus()
        .withTitle('Custom Name', '138px')
        .withPlaceholder('Enter break name here.')
        .withIdentifier('name')
        .withValue(this.break.name)
        .withValidation([
          { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
        ])
        .insertField()

        .newRow()
        .newField()
        .asTimeInput()
        .withTitle('Earliest Time', '135px')
        .withIdentifier('minStartTimeOfDay')
        .withValue(this.break.minStartTimeOfDay)
        .openToTop()
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()
        .newField()
        .asTimeInput()
        .withTitle('Latest Time', '100px')
        .withIdentifier('maxStartTimeOfDay')
        .withValue(this.break.maxStartTimeOfDay)
        .openToTop()
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .finaliseForm((form) => {
          this.breakForm = form;
          if (this.shiftTemplate) {
            this.setFormDataValid();
          }
        });
    });
  }

  private setFormDataValid(): void {
    if (!this.formData) {
      setTimeout(() => {
        this.setFormDataValid();
      }, 50);
      return;
    }
    this.formData.valid = true;
  }

  private generateNonQueueWorkForm(): void {
    this.nonQueueWorkForm = null;
    setTimeout(() => {
      new ZaiForm()
        .newRow()
        .newField()
        .asDropdown()
        .withTitle('Presence', '138px')
        .withIdentifier('presence')
        .withInputWidth('138px')
        .withPlaceholder('Select a presence')
        .withDisplayField('presenceCodeName')
        .openToTop()
        .withOptions(this.presences)
        .withValue(this.nonQueueWork.presence)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .newField()
        .asTimeInput()
        .withTitle('Max Duration', '105px')
        .withIdentifier('maxDurationInMin')
        .withValue(this.nonQueueWork.maxDurationInMin)
        .openToTop()
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .insertField()

        .finaliseForm((form) => {
          this.nonQueueWorkForm = form;
        });
    });
  }

  private populateBreaksTable(): void {
    this.breaks.forEach((br) => {
      const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
        br.maxDurationInMin
      );
      this.breaksTable.push([
        br.name,
        br.minStartTimeOfDay + ` (${convertedTime})`,
      ]);
    });
  }

  public breakFormDataChanged(data: any): void {
    if (data.presence && data.presence !== this.breakNameSet) {
      this.break.presence = data.presence;
      this.break.minStartTimeOfDay = data.minStartTimeOfDay;
      this.break.maxStartTimeOfDay = data.maxStartTimeOfDay;
      this.break.maxDurationInMin = data.maxDurationInMin;
      this.break.name = data.presence;
      this.breakNameSet = data.presence;

      if (!this.breakFormData) {
        this.breakFormData = {
          name: data.presence,
          presence: data.presence,
          minStartTimeOfDay: data.minStartTimeOfDay,
          maxStartTimeOfDay: data.maxStartTimeOfDay,
          maxDurationInMin: data.maxDurationInMin,
        };
      }
      this.breakFormData.valid = true;

      this.generateBreaksForm();
    }
  }

  public addBreak(): void {
    const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
      this.breakFormData.maxDurationInMin
    );
    this.breaksTable.push([
      this.breakFormData.name,
      this.breakFormData.minStartTimeOfDay + ` (${convertedTime})`,
    ]);
    this.editingBreakIndex = null;
    this.breaks.push(this.breakFormData);
    this.breakFormData.valid = false;
    this.setBreakState();
    this.generateBreaksForm();
  }

  public editBreak(breakData: any): void {
    this.break = this.breaks.find((br) => br.name === breakData[0]);
    this.editingBreakIndex = this.breaks.indexOf(this.break);
    this.editingBreak = JSON.parse(JSON.stringify(this.break));
    this.generateBreaksForm();
  }

  public updateBreak(): void {
    const breakTableDataToUpdate = this.breaksTable.find(
      (brt) => brt[0] === this.editingBreak.name
    );
    const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
      this.breakFormData.maxDurationInMin
    );
    const index = this.breaksTable.indexOf(breakTableDataToUpdate);
    this.breaksTable.splice(index, 1, [
      this.breakFormData.name,
      this.breakFormData.minStartTimeOfDay + ` (${convertedTime})`,
    ]);

    this.breaks[this.editingBreakIndex] = { ...this.breakFormData };

    this.editingBreak = null;
    this.editingBreakIndex = null;
    this.setBreakState();
    this.generateBreaksForm();
  }

  public breakRemoved(data: Event): void {
    this.breaks = this.breaks.filter((br) => br.name !== data[0]);
  }

  public cancelEditBreak(): void {
    this.editingBreak = null;
    this.editingBreakIndex = null;
    this.setBreakState();
    this.generateBreaksForm();
  }

  public addNonQueueWork(): void {
    const convertedTime = this.dateTimeTools.convertTimeToHoursAndMinutes(
      this.nonQueueWorkFormData.maxDurationInMin
    );
    this.nonQueueWorkTable.push([
      this.nonQueueWorkFormData.presence,
      convertedTime,
    ]);
    this.nonQueueWorkList.push(this.nonQueueWorkFormData);
    this.setNonQueueWorkState();
    this.nonQueueWorkFormData.valid = false;
    this.generateNonQueueWorkForm();
  }

  public nonQueueWorkRemoved(data: Event): void {
    this.nonQueueWorkList = this.nonQueueWorkList.filter(
      (nqwl) => nqwl.presence !== data[0]
    );
  }

  private setNonQueueWorkState(): void {
    this.nonQueueWork = {
      maxDurationInMin: '01:00',
      presence: '',
    };
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public confirm(): void {
    this.error = '';
    if (this.formData.valid) {
      this.submitting = true;

      const payload = {
        name: this.formData.name,
        startTimeOfDay: this.formData.startTimeOfDay,
        shiftDurationInMin: moment
          .duration(this.formData.shiftDurationInMin)
          .asMinutes(),
        breaks: this.breaks.map((br) => {
          br.maxDurationInMin = moment
            .duration(br.maxDurationInMin)
            .asMinutes();
          delete br.valid;
          return br;
        }),
        offDutyWork: this.nonQueueWorkList.reduce((acc, item) => {
          acc[item.presence] = moment
            .duration(item.maxDurationInMin)
            .asMinutes();
          return acc;
        }, {}),
      };

      if (this.shiftTemplateId) {
        this.updateShiftTemplate(payload);
      } else {
        this.createShiftTemplate(payload);
      }
    }
  }

  private updateShiftTemplate(payload: {
    name: string;
    startTimeOfDay;
    shiftDurationInMin: number;
    breaks: any[];
    offDutyWork: { [key: string]: number };
  }): void {
    this.inHouseWFMService
      .updateShiftTemplate(
        this.shiftTemplateId,
        payload.name,
        payload.startTimeOfDay,
        payload.shiftDurationInMin,
        payload.breaks,
        payload.offDutyWork
      )
      .then(() => {
        this.dialogController.ok({
          updated: {
            id: this.shiftTemplateId,
            name: payload.name,
            startTimeOfDay: payload.startTimeOfDay,
            shiftDurationInMin: payload.shiftDurationInMin,
            breaks: payload.breaks,
            offDutyWork: payload.offDutyWork,
          },
        });
      })
      .catch((e) => {
        console.warn('Failed to update Agent Sampling Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }

  private createShiftTemplate(payload: {
    name: string;
    startTimeOfDay;
    shiftDurationInMin: number;
    breaks: any[];
    offDutyWork: { [key: string]: number };
  }): void {
    const shiftTemplateId = uuidv4();
    this.inHouseWFMService
      .createShiftTemplate(
        shiftTemplateId,
        payload.name,
        payload.startTimeOfDay,
        payload.shiftDurationInMin,
        payload.breaks,
        payload.offDutyWork
      )
      .then(() => {
        this.dialogController.ok({
          created: {
            id: shiftTemplateId,
            name: payload.name,
            startTimeOfDay: payload.startTimeOfDay,
            shiftDurationInMin: payload.shiftDurationInMin,
            breaks: payload.breaks,
            offDutyWork: payload.offDutyWork,
          },
        });
      })
      .catch((e) => {
        console.warn('Failed to update Agent Sampling Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }
}
