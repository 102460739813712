import {LogManager, Container} from 'aurelia-framework';
import { HttpClient, HttpRequestMessage, HttpResponseMessage } from 'aurelia-http-client';

import {SessionStore} from 'zailab.common';
import { DiagnosticsState } from '../features/diagnostics/diagnostics-state';

const logger = LogManager.getLogger('HttpInterceptor');
const codes: Array<number> = [500, 501, 502, 503, 504];


export default class HttpInterceptor {

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private  diagnosticsState: DiagnosticsState) {}

  public request(message: IHttpRequestMessage): IHttpRequestMessage {
    
    this.diagnosticsState.logHttpRequest(message);

    return message;
  }

  public requestError(error: HttpResponseMessage): HttpResponseMessage {
    this.diagnosticsState.logHttpRequestError(error);
    throw error;
  }

  public response(message: IHttpRequestMessage): IHttpRequestMessage {
    
    this.diagnosticsState.logHttpResponse(message);

    if (canIgnoreMessageStructure(message)) {
      return message;
    }

    let msg = parseMessage(message);
    let formattedResponse = msg._embedded || msg;

    if (msg instanceof Object && msg.page) {
      formattedResponse.page = msg.page;
    }
    return formattedResponse;
  }

  public responseError(error: HttpResponseMessage): any {
    this.diagnosticsState.logHttpResponseError(error);
    throw error;
  }
}

const canIgnoreMessageStructure = (message: IHttpRequestMessage): boolean => {
  if (!message.response && message.response !== '') {
    return true;
  }
  if (message.responseType === 'application/zip') {
    return true;
  }
  return false;
};

const parseMessage = (message: IHttpRequestMessage): any => {
  try {
    return typeof message.response === 'string' ? JSON.parse(message.response) : message.response;
  } catch (e) {
    return message.response;
  }
}

const isRetryStatus = (statusCode: number): boolean => {
  // @ts-ignore
  return codes.includes(statusCode);
}

interface IHttpRequestMessage {
  headers: Headers;
  isSuccess: boolean;
  mimeType: string;
  requestMessage: HttpRequestMessage;
  response: string;
  responseType: string;
  reviver: any; // HttpReviver
  statusCode: number;
  statusText: string;
  content: any;
}
