import {
  inject,
  bindable,
  customElement,
  LogManager,
  computedFrom
} from 'aurelia-framework';
import { observable } from 'aurelia-binding';
import { v4 as uuidv4 } from 'uuid';
import { Event } from 'zailab.common';

const logger = LogManager.getLogger('ZContextMenu');

@customElement('z-context-menu')
@inject(Element)
export class ZContextMenu {
  @bindable private actions: any[] = [];
  @bindable @observable private display: boolean = false;
  @bindable({ attribute: 'disable-actions' })
  private disableActions: boolean = false;
  @bindable({ attribute: 'bottom-text' }) private bottomText: string;
  @bindable({ attribute: 'hide-delay' }) private hideDelay: number = 500;
  @bindable({ attribute: 'autoclose-delay' })
  private autoCloseDelay: number = 5000;
  @bindable({ attribute: 'uniqueid' }) uniqueId;
  @bindable private actionClickHandler: Function;
  private autoCloseTimer: number = 0;
  private uniqueIdentifier: string;
  private element: Element;

  private static allDropdowns: ZContextMenu[] = [];

  constructor(element: Element) {
    this.uniqueIdentifier = uuidv4();
    this.element = element;
  }

  public attached(): void {
    ZContextMenu.allDropdowns.push(this);
  }

  private static closeAllExcept(_except: string): void {
    ZContextMenu.allDropdowns.forEach(_instance => {
      if (_instance.uniqueIdentifier !== _except) {
        _instance.closeMenu();
      }
    });
  }

  private displayChanged(): void {
    if (this.display) {
      ZContextMenu.closeAllExcept(this.uniqueIdentifier);
      this.startAutoCloseMenu(this.autoCloseDelay);
    }
  }

  private startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = window.setTimeout(() => this.closeMenu(), _delay);
  }

  private closeMenu(): void {
    this.display = false;
    this.autoCloseTimer = 0;
  }

  private preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  private allowAutoClose(): void {
    this.startAutoCloseMenu(this.hideDelay);
  }

  @computedFrom('actions')
  private get formattedActions(): any[] {
    if (!this.actions) {
      return [];
    }

    let _actions = [];

    for (let _item of this.actions) {
      _actions.push({
        event: _item.action,
        name: _case.first(_item.label) || _case.first(_item.action),
        icon: _item.icon || _item.action.replace(' ', ''),
        separated: _item.separated,
        warning: _item.warning,
        disabled: _item.disabled
      });
    }
    return _actions;
  }

  private dispatchEvent(eventName: string): void {
    if (
      this.actionClickHandler &&
      typeof this.actionClickHandler === 'function'
    ) {
      this.actionClickHandler();
    } else {
      this.element.dispatchEvent(
        new CustomEvent(eventName.replace(' ', ''), {
          bubbles: true,
          detail: { uniqueId: this.uniqueId }
        })
      );
      new Event(this.element, 'selectaction', eventName);
      this.display = false;
    }
  }

  private openMenu(): void {
    this.display = !this.display;
    if (this.display) {
      ZContextMenu.closeAllExcept(this.uniqueIdentifier);
    }
  }
}

let _case = {
  first: string => {
    let array = string.split(' ');
    let newString = '';

    for (let item of array) {
      newString += item.charAt(0).toUpperCase() + item.slice(1) + ' ';
    }
    return newString;
  }
};
