export class SegmentModel {
  public segmentLength: number;
  public segmentColor: string;
  public label: string;
  public segmentTooltip: any = {
    label: '',
    value: null
  };

  constructor(segmentLength: number, segmentColor: string, singularLabel: string, pluralLabel?: string, segmentTooltip?: any) {
    this.segmentLength = segmentLength;
    this.segmentColor = segmentColor;
    this.label = this.determinePluralText(segmentLength, singularLabel, pluralLabel);
    this.segmentTooltip = segmentTooltip || null;
  }

  private determinePluralText(valueCount: number, singularText: string, pluralText: string): string {
    if ((!valueCount || valueCount === 0 || valueCount > 1) && pluralText) {
      return pluralText;
    }
    return singularText;
  }
}