import { DialogController } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { v4 as uuidv4 } from 'uuid';
import { CurrentScopeValidation } from "zailab.common";
import { AgentScriptAdminConfigState } from "./agent-script-admin-config-state";

const logger = LogManager.getLogger('AgentScriptAdminConfigCreateDialog');

@autoinject
export class AgentScriptAdminConfigCreateDialog {

  public loading = false;
  public valid = false;
  public selectedWorkTypes = [];
  public selectedWorkType = null;
  public name = '';

  private validation: any;

  constructor(
    private dialog: DialogController,
    private state: AgentScriptAdminConfigState,
    validate: CurrentScopeValidation,
  ) {
    this.validation = validate.getController();
  }

  public activate(): void {
    this.initValidation();
  }

  private initValidation(): void {
    ValidationRules
      .customRule('agentScriptAdminConfigUniqueName', (value) => {
        if (this.state.agentScriptAdminConfigList.length > 0) {
          for (let item of this.state.agentScriptAdminConfigList) {
            if (item.name.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      }, 'Please enter a unique name.');
    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter the agent script name.')
      .satisfiesRule('agentScriptAdminConfigUniqueName')
      .on(this);
  }

  public select(event: any): void {
    const workType = event.detail;
    if (!workType) {
      return;
    }
    this.selectedWorkTypes.push(workType);
    this.state.addSelectedWorkType(workType);
  }

  public removeWorkType(workType: any): void {
    const index = this.selectedWorkTypes.indexOf(workType);
    this.selectedWorkTypes.splice(index, 1);
    this.state.removeSelectedWorkType(workType);
  }

  public close(): void {
    this.selectedWorkTypes.forEach((workType) => this.state.removeSelectedWorkType(workType));
    this.dialog.cancel();
  }

  public save(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.loading = true;
      const id = uuidv4();
      const name = this.name;
      const workTypeIds = this.selectedWorkTypes.map((workType) => workType.templateId);
      const agentScriptAdminConfig = { id, name, workTypeIds };
      this.state.addAgentScriptAdminConfig(agentScriptAdminConfig).then(() => {
        this.dialog.ok(agentScriptAdminConfig);
        this.loading = false;
      }).catch((error) => {
        if (error.responseType && error.responseType === 'json') {
          const message = JSON.parse(error.response);
          if (!message.details || !message.details.length) {
            logger.error(error);
          } else {
            message.details.forEach((detail) => {
              logger.error(detail);
            });
          }
        } else {
          logger.error(error);
        }
        this.loading = false;
      });
    });
  }
}