import {BaseModel} from 'zailab.abstract';

export class FilterByModel extends BaseModel {

  public name: string = null;
  public selected: boolean = false;
  public isDefault: boolean = false;

  constructor(filterOption: any){
    super();
    this.mapProperties(filterOption);
  }
}