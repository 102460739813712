import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ReportSchedule } from '../report-schedule-model';
import { ReportScheduleService } from '../report-schedule-service';

const logger = LogManager.getLogger('ReportScheduleAdd');

@autoinject()
export class ReportScheduleAdd {

  public reportSchedule: ReportSchedule = new ReportSchedule();
  public isProcessing: boolean = false;

  constructor(
    private reportScheduleService: ReportScheduleService,
    private router: Router
  ) { }

  public save(): void {
    this.isProcessing = true;
    this.reportScheduleService.insert(this.reportSchedule)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }
}