import { autoinject, bindable, customElement, LogManager } from "aurelia-framework";
import { Event } from "zailab.common";
import { ZGridBasicModel, ZGridNodesItem, ZGridNodesItemConnector } from "./z-grid-basic-model";
import { ZGridNodeConnector } from "./z-grid-node-connector";

const logger = LogManager.getLogger('ZGridBasic');

customElement('z-grid-basic')
@autoinject
export class ZGridBasic {

  @bindable
  public gridNodes: ZGridBasicModel;

  private dragging = false;
  private draggingConnector = false;
  private nodeX = -1;
  private nodeY = -1;
  private connX = -1;
  private connY = -1;
  private scrollLeft = 0;
  private scrollTop = 0;
  private selectedConnectionIds = [];

  private grid: HTMLElement;

  constructor(
    private element: Element,
  ) { }

  public bind(): void {
    this.grid.onscroll = () => {
      this.scrollLeft = this.grid.scrollLeft;
      this.scrollTop = this.grid.scrollTop;
    }
  }

  public addNode(ev: any, index: number, cell: ZGridNodesItem, conn: ZGridNodeConnector): void {
    const nextCell = Object.assign({}, cell);
    nextCell.posX = cell.posX + 1;
    nextCell.posY = cell.posY + index;
    new Event(this.element, 'add-grid-node', { index, cell: nextCell, conn });
    this.reset();
  }

  public editNode(ev: any, cell: ZGridNodesItem): void {
    new Event(this.element, 'edit-grid-node', { cell });
    this.reset();
  }

  public deleteNode(ev: any, cell: ZGridNodesItem): void {
    new Event(this.element, 'delete-grid-node', { cell });
    this.reset();
  }

  public addNodeConn(ev: any, index: number, cell: ZGridNodesItem, conn: ZGridNodeConnector): void {
    new Event(this.element, 'add-grid-node', { index, cell, conn });
    this.reset();
  }

  private deleteConn(connectorIds: string[]): void {
    new Event(this.element, 'delete-grid-conns', { connectorIds });
    this.reset();
  }

  public dragOver(ev: any, cell: ZGridNodesItem): void {
    if (!ev) {
      return;
    }
    if (this.isNodeDragged()) {
      if (cell.visible && !this.isDraggedNode(cell)) {
        ev.dataTransfer.dropEffect = 'none';
      } else {
        ev.dataTransfer.dropEffect = 'move';
      }
    } else if (this.isConnDragged()) {
      if (cell.visible) {
        ev.dataTransfer.dropEffect = 'link';
      } else {
        ev.dataTransfer.dropEffect = 'copy';
      }
    } else {
      ev.dataTransfer.dropEffect = 'none';
    }
  }

  private isDraggedNode(cell: ZGridNodesItem): boolean {
    return cell.posX === this.nodeX && cell.posY === this.nodeY;
  }

  public dragNode(ev: any, cell: ZGridNodesItem): void {
    if (!ev) {
      return;
    }
    if (this.draggingConnector) {
      return;
    }

    if (ev.x === 0 && ev.y === 0) {

      if (this.isNodeDragged()) {

        this.gridNodes.getNodeAt(this.nodeX, this.nodeY).dragging = false;
        this.nodeX = -1;
        this.nodeY = -1;
        this.connX = -1;
        this.connY = -1;
        this.dragging = false;

        new Event(this.element, 'move-grid-out', { cell });
      }

      return;
    }

    if (this.dragging) {
      return;
    }
    this.dragging = true;
    this.nodeX = cell.posX;
    this.nodeY = cell.posY;
    cell.dragging = true;
    this.reset();
  }

  public dragConnector(ev: any, index: number, cell: ZGridNodesItem, conn: ZGridNodesItemConnector): void {
    if (!ev) {
      return;
    }

    if (ev.x === 0 && ev.y === 0) {

      if (this.isConnDragged()) {
        
        this.nodeX = -1;
        this.nodeY = -1;
        this.connX = -1;
        this.connY = -1;
        this.draggingConnector = false;
        this.dragging = false;

        conn.coordinates.endX = 20;
        conn.coordinates.endY = 0;
        conn.dragging = false;

        cell.draggingConnector = false;
        cell.draggingConnectorIndex = -1;

        new Event(this.element, 'move-grid-out', { cell });
      }

      return;
    }

    conn.coordinates.endX = ev.x - 20 - (cell.posX * this.gridNodes.cellSize) + this.scrollLeft;
    conn.coordinates.endY = ev.y - this.gridNodes.cellSize - (this.gridNodes.cellSize / 2) - 20 - (cell.posY * this.gridNodes.cellSize) + this.scrollTop;

    if (this.dragging) {
      return;
    }
    this.dragging = true;
    this.draggingConnector = true;
    this.connX = cell.posX;
    this.connY = cell.posY;
    conn.dragging = true;
    cell.draggingConnector = true;
    cell.draggingConnectorIndex = index;
    this.reset();
  }

  public drop(ev: any, x: number, y: number): void {
    if (!ev) {
      return;
    }
    if (this.isNodeDragged()) {
      if (x === this.nodeX && y === this.nodeY) {
        new Event(this.element, 'move-grid-out', {});
        const node = this.gridNodes.getNodeAt(this.nodeX, this.nodeY);
        if (node) {
          node.dragging = false;
        }
      } else {
        const fromX = this.nodeX;
        const fromY = this.nodeY;
        const toX = x;
        const toY = y;
        const toNode = this.gridNodes.getNodeAt(toX, toY);
        if (toNode) {
          new Event(this.element, 'move-grid-out', {});
          const node = this.gridNodes.getNodeAt(this.nodeX, this.nodeY);
          if (node) {
            node.dragging = false;
          }
        } else {
          const node = this.gridNodes.getNodeAt(this.nodeX, this.nodeY);
          if (node) {
            node.dragging = false;
          }
          new Event(this.element, 'move-grid-node', { fromX, fromY, toX, toY });
        }
      }
    }
    if (this.isConnDragged()) {

      if (x === this.connX && y === this.connY) {
        new Event(this.element, 'move-grid-out', {});
        const node = this.gridNodes.getNodeAt(this.connX, this.connY);
        if (node) {
          node.draggingConnector = false;
        }
      } else {
        const fromX = this.connX;
        const fromY = this.connY;
        const toX = x;
        const toY = y;
        const node = this.gridNodes.getNodeAt(this.connX, this.connY);
        if (node) {
          node.draggingConnector = false;
        }
        const targetNode = this.gridNodes.getNodeAt(x, y);
        if (targetNode) {
          if (targetNode.startNode) {
            new Event(this.element, 'move-grid-out', {});
          } else {
            new Event(this.element, 'move-grid-conn', { fromX, fromY, toX, toY, conn: node.connectors[node.draggingConnectorIndex] });
          }
        } else {
          new Event(this.element, 'add-grid-node', { index: node.draggingConnectorIndex, cell: { id: node.id, posX: x, posY: y }, conn: node.connectors[node.draggingConnectorIndex] });
        }
      }
    }
    this.nodeX = -1;
    this.nodeY = -1;
    this.connX = -1;
    this.connY = -1;
    this.dragging = false;
    this.draggingConnector = false;
    this.reset();
  }

  private isNodeDragged(): boolean {
    return this.nodeX >= 0 && this.nodeY >= 0;
  }

  private isConnDragged(): boolean {
    return this.connX >= 0 && this.connY >= 0;
  }

  public clickLine(ev: any, conn: ZGridNodesItemConnector): void {
    setTimeout(() => {
      if (conn.selected) {
        this.selectedConnectionIds.push(conn.id);
      } else {
        this.selectedConnectionIds.splice(this.selectedConnectionIds.indexOf(conn.id), 1);
      }
      if (!this.selectedConnectionIds || !this.selectedConnectionIds.length) {
        this.ignoreKeyDown();
      } else {
        this.listenForKeyDown();
      }
    }, 100);
  }

  private listenForKeyDown(): void {
    window.addEventListener('keydown', this.keyPressed);
  }

  private ignoreKeyDown(): void {
    window.removeEventListener('keydown', this.keyPressed);
  }

  private keyPressed = (event: KeyboardEvent): void => {
    if (event.key === 'Delete') {
      if (!this.selectedConnectionIds || !this.selectedConnectionIds.length) {
        return;
      }
      this.deleteConn(this.selectedConnectionIds);
      this.reset();
    }
    this.ignoreKeyDown();
  }

  private reset() {
    for (const node of this.gridNodes.nodes) {
      node.connectors.filter((conn) => conn.selected).forEach((conn) => conn.selected = false);
    }
    this.selectedConnectionIds = [];
  }
}