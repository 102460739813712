import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { v4 as uuidv4 } from 'uuid';
import { Oplog } from '../../../_common/services/database-service';
import { SessionStore } from '../../../_common/stores/session-store';
import { BusinessPartner } from './business-partner-model';

const logger = LogManager.getLogger('BusinessPartnerService');

@autoinject()
export class BusinessPartnerService {

  private readonly baseUrl = 'v1/organisation/me/business-partners';

  constructor(
    private http: HttpClient,
    private oplog: Oplog,
    private sessionStore: SessionStore
  ) { }

  public findAll(): Promise<BusinessPartner[]> {
    return this.http.createRequest(`${this.baseUrl}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response) {
          return null;
        }
        else {
          return response.map((data: BusinessPartner) => {
            const businessPartner = new BusinessPartner();
            businessPartner.id = data.id;
            businessPartner.name = data.name;
            return businessPartner;
          });
        }
      });
  }

  public delete(id: any): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asDelete()
      .send();
  }

  public findById(id: any): Promise<BusinessPartner> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asGet()
      .send()
      .then((response: any) => {
        const businessPartner = new BusinessPartner();
        businessPartner.id = response.id;
        businessPartner.name = response.name;
        return businessPartner;
      });
  }

  public update(data: BusinessPartner): Promise<any> {

    const id = data.id;

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('organisation-projector.businessPartnerView')
      .withKeyField('_id')
      .withKeyValue(this.sessionStore.get.organisation.organisationId);

    this.http.createRequest(`${this.baseUrl}/${id}`)
      .asPut()
      .withContent(data)
      .send();

    return oplogSubscription.when('update').then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  public async insert(data: BusinessPartner): Promise<any> {

    const exitingData = await this.findAll();

    const operation = !exitingData ? 'insert' : 'update';

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('organisation-projector.businessPartnerView')
      .withKeyField('_id')
      .withKeyValue(this.sessionStore.get.organisation.organisationId);

    data.id = uuidv4();

    this.http.createRequest(`${this.baseUrl}`)
      .asPost()
      .withContent(data)
      .send();

    return oplogSubscription.when(operation).then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }
}
