import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {AgentModel} from './agent-model';
/*
 */
const logger = LogManager.getLogger('NotificationModel');
/*
 */
export class NotificationModel extends BaseModel {

    public missed: boolean = false;
    public transferred: boolean = false;
    public notAnswering: boolean = false;
    public failedAverageAgentRatingSurveyScore: boolean = false;

    constructor(agent: AgentModel) {

        super();
        this.mapProperties(agent);
    }

    private mapProperties(agent: AgentModel): void {

        let maxMissedCalls: number = 0;
        let maxTransferredCalls: number = 3;
        let minSurveyFailurePercentage: number = 80;

        this.missed = agent.totalCallsMissedForCurrentDay > maxMissedCalls ? true : false,
        this.transferred = agent.totalCallsTransferredForCurrentDay > maxTransferredCalls ? true : false,
        this.notAnswering = agent.currentActivityStatus === 'Not Responding' ? true : false,
        this.failedAverageAgentRatingSurveyScore = agent.averageSurveyScore && agent.averageSurveyScore < minSurveyFailurePercentage ? true : false
    }

}