import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { computedFrom, inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import moment from 'moment';
import { AbstractList } from 'zailab.abstract';
import FileSaver from 'file-saver';

import { MESSAGE_EVENTS, SessionStore, DownloadService } from 'zailab.common';
import { InfiniteScrollService } from '../../../../_common/services/infinitescroll-service';
import { PlaceholderService } from '../../../../_common/services/placeholder-service';
import { visibilityAware } from '../../../../_common/services/visibilityawareness/visibility-aware';
import { MembersService } from '../../../organisation/member/members/members-service';
import { CampaignActionMenuDialog } from '../action-menu/campaign-action-menu-dialog';
import { AddCampaignDialog } from '../addcampaign/add-campaign-dialog';
import { ViewCampaignAuditDialog } from '../audit/view-audit';
import CampaignModel from '../campaign-model';
import CampaignService from '../campaign-service';
import { ViewState } from '../campaign-view-state';
import { ConfirmArchiveDialog } from '../confirmarchive/confirm-archive';
import { ConfirmRemoveScheduleDialog } from '../confirmremoveschedule/confirm-remove-schedule';
import { EditCampaignDialog } from '../editcampaign/edit-campaign-dialog';
import { EditRulesDialog } from '../editrules/edit-rules-dialog';
import { ExportProspectsDialog } from '../exportprospects/export-prospects-dialog';
import { ScheduleDncListDialog } from '../schedule-dnc-list/schedule-dnc-list-dialog';
import { SetOperatingHoursDialog } from '../setoperatinghours/set-operating-hours-dialog';

const logger = LogManager.getLogger('Campaigns');
const FILTER_STATES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

@inject(
  Element,
  CampaignService,
  DialogService,
  SessionStore,
  EventAggregator,
  Router,
  BindingSignaler,
  MembersService,
  DownloadService
)
@visibilityAware
export default class extends AbstractList {
  defaultHeader = 'Campaigns';
  header = this.defaultHeader;
  itemList = [];
  itemListLength = 0;
  ready = false;
  task = new CampaignModel({});
  filter = FILTER_STATES.ACTIVE;
  page = 0;
  archiveAction = {
    action: 'archive',
    label: 'Archive Campaign',
    icon: 'folder',
  };

  rulesAction = {
    action: 'rules',
    label: 'Dialling Rules',
    icon: 'wrench-pencil',
  };

  exportProspectsAction = {
    action: 'exportprospects',
    label: 'Export Prospects',
    icon: 'wrench-pencil',
  };

  previewActions = [
    {
      action: 'view',
      label: 'View Prospects',
    },
    {
      action: 'edit',
      label: 'Edit Campaign',
    },
    this.archiveAction,
    {
      action: 'schedulednc',
      label: 'Schedule SFTP DNC',
      icon: 'schedule',
    },
    {
      action: 'schedule',
      label: 'Schedule SFTP Upload',
      icon: 'schedule',
    },
    {
      action: 'removeschedule',
      label: 'Remove SFTP Schedule',
      icon: 'remove',
    },
    {
      action: 'setoperatinghours',
      label: 'Set Operating Hours',
      icon: 'timer',
    },
    this.exportProspectsAction,
  ];
  predictiveActions = [
    {
      action: 'view',
      label: 'View Prospects',
    },
    {
      action: 'edit',
      label: 'Edit Campaign',
    },
    this.archiveAction,
    {
      action: 'schedulednc',
      label: 'Schedule SFTP DNC',
      icon: 'schedule',
    },
    {
      action: 'schedule',
      label: 'Schedule SFTP Upload',
      icon: 'schedule',
    },
    {
      action: 'removeschedule',
      label: 'Remove Schedule',
      icon: 'remove',
    },
    {
      action: 'setoperatinghours',
      label: 'Set Operating Hours',
      icon: 'timer',
    },
    this.rulesAction,
    this.exportProspectsAction,
  ];
  enableWebhookAction = {
    action: 'enable-webhook',
    label: 'Enable Webhook',
    icon: 'remove',
  };

  placeholders = 0;
  container;
  timeoutCount = 300000;
  lastRequestTimestamp;
  signallerInterval;
  enableSelectors = false;

  overviewState = new ViewState();
  showFilter = false;
  filterDialerType = 'all';
  filterStatus = 'all';
  filterBusinessPartner = '';
  filterContactCentre = '';

  constructor(
    element,
    campaignService,
    dialogService,
    sessionStore,
    eventAggregator,
    router,
    bindingSignaler,
    membersService,
    downloadService
  ) {
    super(eventAggregator);
    this.element = element;
    this.campaignService = campaignService;
    this.dialogService = dialogService;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
    this.router = router;
    this.bindingSignaler = bindingSignaler;
    this.membersService = membersService;
    this.downloadService = downloadService;
  }

  attached() {
    this.retrieveCampaigns(true);
    this.retrieveMember();
  }

  retrieveCampaigns(loaderEnabled) {
    if (loaderEnabled) {
      this.ready = false;
    }

    this.isRequesting = true;
    this.enableSelectors = false;
    this.campaignService.retrieveCampaigns().then(
      (response) => {
        response.campaigns.forEach((campaign) => {
          if (campaign.dialerType === 'Preview') {
            campaign.actions = [...this.previewActions];
          } else {
            campaign.actions = [...this.predictiveActions];
          }

          if (!campaign.webhookEnabled) {
            campaign.actions.push(this.enableWebhookAction);
          }
        });

        this.setStats(response);
        this.setupCampaignStatsConfig();
        this.ready = true;
        this.generatePlaceholders();
        this.enableSelectors = true;
        this.lastRequestTimestamp = new moment().add('minutes', 5);
        this.isRequesting = false;
        this.setupSignaller();
      },
      (error) => {
        logger.info('Error: Unable to retrieve list of campaigns.', error);
        this.setupCampaignStatsConfig();
        this.ready = true;
        this.generatePlaceholders();
        this.enableSelectors = true;
      }
    );
  }

  retrieveMember() {
    this.membersService.retrieveMember(this.user.memberId).then((member) => {
      this.businessPartners = member.businessPartners;
    });
  }

  setupSignaller() {
    let signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (
        moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0 &&
        !this.isRequesting
      ) {
        this.retrieveCampaigns(false);
      }
    }, signal);
  }

  generatePlaceholders() {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.getViewableItemListLength(),
      2,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }

  reGeneratePlaceholders(length) {
    if (this.placeholderService) {
      this.placeholders = this.placeholderService.generatePlaceholders(length);
    }
  }

  setStats(response) {
    this.itemList = response.campaigns;
    this.itemListLength = this.getViewableItemListLength();
    this.task = new CampaignModel(response);
  }

  setupCampaignStatsConfig() {
    this.campaignStats = [
      {
        header: this.task.numberOfTasksTotalUploaded,
        label: 'Total Prospects',
        borderColor: 'highlight',
        emptyState: '0',
      },
      {
        header:
          this.task.numberOfTasksRemaining +
          ` [${this.task.numberOfTasksRemainingReady}]`,
        label: 'Remaining [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
      },
      {
        header:
          this.task.numberOfTasksNotServed +
          ` [${this.task.numberOfTasksNotServedReady}]`,
        label: 'Not Served [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
        subStat: true,
      },
      {
        header:
          this.task.numberOfTasksRequeued +
          ` [${this.task.numberOfTasksRequeuedReady}]`,
        label: 'Requeued [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
        subStat: true,
      },
      {
        header: this.task.numberOfTasksCompleted,
        label: 'Completed',
        borderColor: 'green',
        emptyState: '0',
      },
      {
        header: this.task.numberOfTasksStale,
        label: 'Stale',
        borderColor: 'red',
        emptyState: '0',
      },
      {
        header: this.task.numberOfTasksCleared,
        label: 'Cleared',
        borderColor: 'red',
        emptyState: '0',
      },
    ];

    this.campaignTodayStats = [
      {
        header: this.task.todayStats.carriedOver || '0',
        label: 'Carried Over',
        borderColor: 'highlight',
        emptyState: '0',
      },
      {
        header: this.task.todayStats.newToday || '0',
        label: 'New Prospects',
        borderColor: 'highlight',
        emptyState: '0',
      },
      {
        header:
          (this.task.todayStats.remaining || '0') +
          ` [${this.task.todayStats.remainingReady || '0'}]`,
        label: 'Remaining [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
      },
      {
        header:
          (this.task.todayStats.notServed || '0') +
          ` [${this.task.todayStats.notServedReady || '0'}]`,
        label: 'Not Served [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
        subStat: true,
      },
      {
        header:
          (this.task.todayStats.requeuedToday || '0') +
          ` [${this.task.todayStats.requeuedTodayReady || '0'}]`,
        label: 'Requeued (Today) [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
        subStat: true,
      },
      {
        header:
          (this.task.todayStats.requeuedCarriedOver || '0') +
          ` [${this.task.todayStats.requeuedCarriedOverReady || '0'}]`,
        label: 'Requeued (Carried Over) [Ready]',
        borderColor: 'yellow',
        emptyState: '0',
        subStat: true,
      },
      {
        header: this.task.todayStats.completed || '0',
        label: 'Completed',
        borderColor: 'green',
        emptyState: '0',
      },
      {
        header: this.task.todayStats.stale || '0',
        label: 'Stale',
        borderColor: 'red',
        emptyState: '0',
      },
      {
        header: this.task.todayStats.cleared || '0',
        label: 'Cleared',
        borderColor: 'red',
        emptyState: '0',
      },
    ];
  }

  selectActiveFilter() {
    let list = [].concat(this.actions);
    this.actions = [];
    let hasArchived = list.filter((action) => {
      return action.action === 'archive';
    });

    if (!hasArchived.length) {
      list.push(this.archiveAction);
    }
    this.actions = list;
    this.itemListLength = this.getViewableItemListLength();
  }

  selectArchivedFilter() {
    let list = [].concat(this.actions);
    this.actions = [];
    this.actions = list.filter((action) => {
      if (!action) {
        return;
      }
      return action.action !== 'archive';
    });
    this.itemListLength = this.getViewableItemListLength();
  }

  add() {
    this.dialogService
      .open({ viewModel: AddCampaignDialog, model: this.itemList })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          let campaign = response.output;
          campaign.organisationId =
            this.sessionStore.get.organisation.organisationId;

          this.campaignService.create(campaign);
        }
      });
  }

  scheduleDncList(campaign) {
    this.dialogService
      .open({ viewModel: ScheduleDncListDialog, model: campaign })
      .whenClosed((response) => {
        // Do nothing
      });
  }

  actionMenu(campaign) {
    const model = { campaign };
    if (campaign.dialerType === 'Preview') {
      model.actions = this.previewActions;
    } else {
      model.actions = this.predictiveActions;
    }

    this.dialogService
      .open({ viewModel: CampaignActionMenuDialog, model })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          switch (response.output.action) {
            case 'view': {
              this.view(response.output.campaign);
              break;
            }
            case 'archive': {
              this.archive(response.output.campaign);
              break;
            }
            case 'edit': {
              this.edit(response.output.campaign);
              break;
            }
            case 'schedule': {
              this.schedule(response.output.campaign);
              break;
            }
            case 'removeschedule': {
              this.removeSchedule(response.output.campaign);
              break;
            }
            case 'enableWebhook': {
              this.enableWebhook(response.output.campaign);
              break;
            }
            case 'schedulednc': {
              this.scheduleDncList(response.output.campaign);
              break;
            }
            case 'rules': {
              this.editRules(response.output.campaign);
              break;
            }
            case 'setoperatinghours': {
              this.setOperatingHours(response.output.campaign);
              break;
            }
            case 'exportprospects': {
              this.exportProspects(response.output.campaign);
              break;
            }
            default: {
              break;
            }
          }
        }
      });
  }

  setOperatingHours(campaign) {
    this.dialogService
      .open({ viewModel: SetOperatingHoursDialog, model: campaign })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          let updatedCampaign = response.output;
          this.campaignService.setOperatingHours(
            updatedCampaign.campaignId,
            updatedCampaign.operatingHours
          );
        }
      });
  }

  exportProspects(item) {
    this.dialogService
      .open({
        viewModel: ExportProspectsDialog,
        model: { campaignId: item.campaignId },
      })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          this.downloadCampaignProspects(
            item.campaignId,
            item.name,
            response.output
          );
        }
      });
  }

  downloadCampaignProspects(campaignId, campaignName, data) {
    this.campaignService
      .downloadCampaignProspects(
        campaignId,
        campaignName,
        data.notServed,
        data.requeued,
        data.completed,
        data.stale,
        data.fromDate,
        data.toDate,
        data.prospectListId
      )
      .then((response) => {
        FileSaver.saveAs(response, campaignName + '.zip');
      });
  }

  edit(campaign) {
    this.dialogService
      .open({ viewModel: EditCampaignDialog, model: campaign })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          campaign.taskTemplateId = response.output.taskTemplateId;
          campaign.diallerOptions = response.output.diallerOptions;

          let updatedCampaign = response.output;
          this.campaignService.edit(
            updatedCampaign.campaignId,
            updatedCampaign.taskTemplateId,
            updatedCampaign.autoAcceptTaskEnabled,
            updatedCampaign.dialerType,
            updatedCampaign.diallerOptions,
            updatedCampaign.preferredAgentReschedulingEnabled,
            updatedCampaign.preferredAgentReschedulingTimeout,
            updatedCampaign.ignoreDoNotContactList
          );
        }
      });
  }

  view(campaign, event) {
    if (
      campaign.isPlaceholder ||
      campaign.showLoader ||
      (event && event.target.className.includes('o-switch-checkbox'))
    ) {
      return;
    }
    let campaignId = campaign.campaignId;

    const businessPartnerId = campaign.businessPartnerId;
    const contactCentreId = campaign.contactCentreId;

    let route = `${campaignId}/prospectlists`;
    if (businessPartnerId) {
      route += `?businessPartnerId=${businessPartnerId}`;
    }
    if (contactCentreId) {
      route += `&contactCentreId=${contactCentreId}`;
    }
    this.router.navigate(route);
  }

  archive(campaign) {
    this.dialogService
      .open({ viewModel: ConfirmArchiveDialog, model: {} })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          this.archiveConfirmed(campaign);
        }
      });
  }

  schedule(campaign) {
    if (!campaign || !campaign.campaignId) {
      return;
    }
    this.router.navigate(`${campaign.campaignId}/prospectlists/schedule`);
  }

  removeSchedule(campaign) {
    if (!campaign || !campaign.campaignId) {
      return;
    }

    this.dialogService
      .open({ viewModel: ConfirmRemoveScheduleDialog, model: {} })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          this.campaignService.removeSchedule(campaign.campaignId);
        }
      });
  }

  enableWebhook(campaign) {
    this.campaignService.configureWebhook(campaign.campaignId, {}).then(() => {
      let webhookAction = campaign.actions.find(
        (action) => action.action === 'enable-webhook'
      );
      if (webhookAction) {
        if (campaign.dialerType === 'Preview') {
          campaign.actions = [...this.previewActions];
        } else {
          campaign.actions = [...this.predictiveActions];
        }
      }

      let message = `Campaign ${campaign.name}'s webhook has been enabled.`;
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, message);
    });
  }

  editRules(campaign) {
    if (!campaign || !campaign.campaignId) {
      return;
    }

    this.dialogService
      .open({ viewModel: EditRulesDialog, model: campaign })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          const rules = response.output.rules.map((rule) => {
            return {
              outcome: rule.outcome,
              maxAttempts: rule.maxAttempts,
              attempts: rule.attempts,
            };
          });
          let diallingRules = {
            rules,
            maxDialAttempts: response.output.maxDialAttempts,
          };
          this.campaignService
            .setDiallingRules(campaign.campaignId, diallingRules)
            .then(() => {
              campaign.diallingRules = rules;
              campaign.maxDialAttempts = response.output.maxDialAttempts;
            });
        }
      });
  }

  archiveConfirmed(campaign) {
    const campaignId = campaign.campaignId;

    if (this.campaignIsEligibleToArchive(campaign)) {
      this.campaignService
        .archiveCampaign(campaignId)
        .then(() => {})
        .catch((error) => {
          this.showArchiveMessageEvent();
          logger.info(
            'Could not archive campaign' +
              JSON.stringify({ campaign: campaign, error: error })
          );
        });
    } else {
      this.showArchiveMessageEvent();
    }
  }

  campaignIsEligibleToArchive(campaign) {
    if (campaign.numberOfTasksRemaining === 0) {
      return true;
    }
    return false;
  }

  showArchiveMessageEvent() {
    const message =
      'There is still work in your campaign. You can only archive completed campaigns.';
    this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, message);
  }

  getViewableItemListLength() {
    let list = this.itemList.filter((item) => {
      return this.filter === FILTER_STATES.ARCHIVED
        ? item.archived
        : !item.archived;
    }).length;
    this.reGeneratePlaceholders(list);
    return list;
  }

  selectBusinessPartner() {
    const businessPartner = this.businessPartners.find(
      (bp) => bp.businessPartnerId === this.filterBusinessPartner
    );
    this.contactCentres = this.filterBusinessPartner
      ? businessPartner.contactCentres
      : [];
    this.filterContactCentre = '';
  }

  startsWithMatch(
    searchExpression,
    value,
    filter,
    filterDialerType,
    filterStatus,
    filterBusinessPartner,
    filterContactCentre
  ) {
    if (!searchExpression && searchExpression !== '') {
      return false;
    }
    if (
      valueMatchesExpression(searchExpression, value) &&
      valueMatchesFilter(filter, value) &&
      valueMatchesFilterDialerType(filterDialerType, value) &&
      valueMatchesFilterStatus(filterStatus, value) &&
      valueMatchesFilterBusinessPartner(filterBusinessPartner, value) &&
      valueMatchesFilterContactCentre(filterContactCentre, value)
    ) {
      return true;
    }
    return false;
  }

  unbind() {
    clearInterval(this.signallerInterval);
  }

  becameVisible() {
    this.retrieveCampaigns(false);
  }

  pauseResumeCampaign(campaign) {
    if (campaign.paused) {
      campaign.paused = false;
    } else {
      campaign.paused = true;
    }

    this.campaignService
      .setPaused(campaign.campaignId, campaign.paused)
      .then(() => {})
      .catch((error) => {
        let message = '';
        if (error && Array.isArray(error.details)) {
          error.details.forEach((errorMessage, index) => {
            if (index > 0) {
              message += '\n';
            }
            message += errorMessage;
          });
        }
        this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, message);
      });

    return true;
  }

  toggleSearchFilter() {
    this.showFilter = !this.showFilter;
  }

  viewAudit() {
    this.router.navigate('audit');
  }

  viewChange(auditRecord) {
    let model = auditRecord.responseInformation;
    let nestedDuplicateKey = 'Dialing Rules';
    if (
      model[nestedDuplicateKey] &&
      model[nestedDuplicateKey][nestedDuplicateKey]
    ) {
      model[nestedDuplicateKey] = model[nestedDuplicateKey][nestedDuplicateKey];
    }
    this.dialogService
      .open({
        viewModel: ViewCampaignAuditDialog,
        model: model,
      })
      .whenClosed(() => {});
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  @computedFrom('user.hasCampaignManagerRole')
  get isCampaignManager() {
    return this.user.hasCampaignManagerRole;
  }
}

function valueMatchesExpression(expression, value) {
  return value.name.toLowerCase().startsWith(expression.toLowerCase());
}

function valueMatchesFilter(filter, value) {
  if (filter === FILTER_STATES.ACTIVE && !value.archived) {
    return true;
  }

  if (filter === FILTER_STATES.ARCHIVED && value.archived) {
    return true;
  }

  return false;
}

function valueMatchesFilterDialerType(filterDialerType, value) {
  if (!filterDialerType || filterDialerType === 'all') {
    return true;
  }
  if (filterDialerType.toLowerCase() === value.dialerType.toLowerCase()) {
    return true;
  }
  return false;
}

function valueMatchesFilterStatus(filterStatus, value) {
  if (!filterStatus || filterStatus === 'all') {
    return true;
  }
  if (filterStatus.toLowerCase() === 'paused' && value.paused) {
    return true;
  }
  if (filterStatus.toLowerCase() === 'running' && !value.paused) {
    return true;
  }
  return false;
}

function valueMatchesFilterBusinessPartner(filterBusinessPartner, value) {
  if (!filterBusinessPartner) {
    return true;
  }
  if (value.businessPartnerId === filterBusinessPartner) {
    return true;
  }
  return false;
}

function valueMatchesFilterContactCentre(filterContactCentre, value) {
  if (!filterContactCentre) {
    return true;
  }
  if (value.contactCentreId === filterContactCentre) {
    return true;
  }
  return false;
}
