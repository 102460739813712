import {LogManager, customElement, bindable, inject} from 'aurelia-framework';

const logger = LogManager.getLogger('UtilityDeleteButton');

@inject(Element)
@customElement('utility-delete-button-v2')
export class UtilityDeleteButton {

  @bindable isdeleting;

  constructor(
    private element: Element
  ) {}

  toggle(){
    this.dispatch('toggle', '');
  }

  accept() {
    this.dispatch('accept', '');
  }

  dispatch(name, data) {
    this.element.dispatchEvent(
      new CustomEvent(name, {
        bubbles: true,
        detail: data
      })
    );
  }

}
