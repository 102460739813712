import { DialogController } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, LogManager } from "aurelia-framework";
import { ApplicationProperties } from "zailab.common";
import { MsTeamsDirectRoutingSettingsForm } from "./ms-teams-direct-routing-model";
import { MsTeamsDirectRoutingService } from "./ms-teams-direct-routing-service";

const logger = LogManager.getLogger('MsTeamsDirectRoutingDialog');

@autoinject
export class MsTeamsDirectRoutingDialog {

  public loading: boolean;
  public copiedFqdn: boolean;
  public copiedPort: boolean;
  public form: MsTeamsDirectRoutingSettingsForm

  constructor(
    private events: EventAggregator,
    private config: ApplicationProperties,
    private dialogController: DialogController,
    private service: MsTeamsDirectRoutingService
  ) { }

  public activate(): void {
    this.loading = true;
    this.service.retrieve().then((data) => {
      let enabled = false;
      let numbers = [];
      if (data) {
        enabled = data.enabled;
        numbers = data.numbers;
      }
      this.form = new MsTeamsDirectRoutingSettingsForm(enabled, numbers);
      this.form.fqdn = this.config.msTeamsDirectRouting.fqdn;
      this.form.port = this.config.msTeamsDirectRouting.port;
      this.loading = false;
    }).catch(() => {
      let enabled = false;
      let numbers = [];
      this.form = new MsTeamsDirectRoutingSettingsForm(enabled, numbers);
      this.form.fqdn = this.config.msTeamsDirectRouting.fqdn;
      this.form.port = this.config.msTeamsDirectRouting.port;
      this.loading = false;
    });
  }

  public done(): void {
    this.loading = true;
    this.service.update(this.form.toData()).then(() => {
      this.loading = false;
      this.dialogController.ok();
    });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public copyFqdn(): void {
    this.copiedFqdn = true;
    navigator.clipboard.writeText(this.form.fqdn);
    setTimeout(() => this.copiedFqdn = false, 500);
  }

  public copyPort(): void {
    this.copiedPort = true;
    navigator.clipboard.writeText(this.form.port);
    setTimeout(() => this.copiedPort = false, 500);
  }

  public addNumber(): void {
    this.events.publish('PHONE.NUMBER.VALIDATE');
    this.form.addNumber()
      .then(() => this.events.publish('PHONE.NUMBER.RESET'))
      .catch((validationMessage) => this.events.publish('PHONE.NUMBER.CUSTOM_VALIDATION', validationMessage));
  }

  public removeNumber(number: string): void {
    this.form.removeNumber(number);
  }
}