import {LogManager, inject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
/**/
import {GridCellModel} from './models/grid-cell-model';
import {ConnectorModel} from '../connector/models/connector-model';
import {DraggableService} from '../../../_common/services/draggable.service';
import {NodeModel} from './models/node-model';
/**/
const logger = LogManager.getLogger('ZNodeDraggable');

/**/
interface Coordinates {
  left: number,
  top: number
}

/**/
export class ZNodeDraggable {

  private bindingSignaler: BindingSignaler;
  private draggableService: DraggableService;
  private eventAggregator: EventAggregator;
  public mousePosition: Coordinates = {left: 0, top: 0};
  public inputConnector_isHovering: boolean = false;

  constructor(bindingSignaler: BindingSignaler, draggableService: DraggableService, eventAggregator: EventAggregator) {
    this.bindingSignaler = bindingSignaler;
    this.draggableService = draggableService;
  }

  private dragStart(event: DragEvent, connectorIndex: number, nodeData: NodeModel, connector: ConnectorModel): boolean {

    if (connector) {

      event.dataTransfer.setDragImage(new Image(), 0, 0);
      connector.isDragging = true;
    }

    this.draggableService.draggingData = {
      connectorIndex,
      nodeData,
      isConnector: connector ? true : false
    };

    return true;
  }

  private drag(event: DragEvent): DragEvent {

    this.mousePosition.left = event.clientX;
    this.mousePosition.top = event.clientY;

    this.signalCoordinateUpdate(this.draggableService.draggingData.connectorIndex);

    return event;
  }

  private dragOver(event: DragEvent): DragEvent {

    this.inputConnector_isHovering = true;

    return event;
  }

  private dragDrop(event: DragEvent, connectorId: string): DragEvent {

    this.eventAggregator.publish('node.connector.link', {src: this.draggableService.draggingData, destId: connectorId});

    this.inputConnector_isHovering = false;

    this.draggableService.draggingData = null;

    return event;
  }

  private dragLeave(event: DragEvent): DragEvent {

    this.inputConnector_isHovering = false;

    return event;
  }

  private connectorDragEnd(connector: ConnectorModel): boolean {

    this.draggableService.draggingData = null;

    this.signalCoordinateUpdate(connector.connectorIndex);

    connector.isDragging = false;

    return true;
  }

  private signalCoordinateUpdate(connectorIndex: number): void {
  }

  public get draggingConnector(): boolean {
    if (this.draggableService.draggingData && this.draggableService.draggingData.isConnector) {
      return true;
    }

    return false;
  }

}