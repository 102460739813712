import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore } from 'zailab.common';
import { DashboardWidget } from '../../../organisation/dashboard/dashboard-abstract';
import { TeamsService } from '../teams-service';

const logger = LogManager.getLogger('Teams Widget');

@inject(Router, TeamsService, SessionStore)
export class TeamsDashboardWidget extends DashboardWidget {

  private oplog: any;

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }, {
    name: 'Add',
    event: 'add',
    icon: 'add'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private teamsService: TeamsService,
    private sessionStore: SessionStore
  ) {
    super();
  }

  public activate(): void {
    this.retrieveTeamsStats();
  }

  public detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  private retrieveTeamsStats(): void {

    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.retrieveTeamsStats();
      }, 500);
      return;
    }

    this.teamsService.retrieveTeamsStats().then((response) => {
      this.count = response;
      this.ready = true;
    }, (error) => {
      logger.info('Could not retrieve Teams Stats', error);
    });

    this.teamsService.initialiseStatsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.count = response.teams.length;
      });
      this.oplog.on('update', (response) => {
        this.count = response.teams.length;
      });
    });
  }
}