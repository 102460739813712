import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { ArrayTools, SessionStore, ZaiForm } from 'zailab.common';
import { InHouseWFMService } from '../../in-house-wfm-service';
import { TeamsService } from '../../../../member/teams/teams-service';
import { MembersService } from '../../../../member/members/members-service';
import { WorkTypesService } from '../../../../conversation/worktypes/work-types-service';

import moment from 'moment';

const logger = LogManager.getLogger('AddShiftDialog');

@autoinject()
export class AddShiftDialog {
  public form: any[][];
  public formData: {
    selectedTeams: string[];
    selectedMembers: string[];
    selectedMemberTeams: string[];
    fromDate: string;
    toDate: string;
    shiftTemplate: string;
    selectedWorkTypes: string[];
    valid?: boolean;
  } = {
    selectedTeams: [],
    selectedMembers: [],
    selectedMemberTeams: [],
    fromDate: '',
    toDate: '',
    shiftTemplate: '',
    selectedWorkTypes: [],
  };
  private submitting: boolean;
  public error: string;
  public loading = false;

  private pageNumber = 0;
  private pageSize = 20;
  public showMoreEnabled = true;

  private teams: any[];
  private teamIds: string[] = [];
  private members: any[];
  private memberIds: string[] = [];
  private memberTeams: any[];
  private shiftTemplates: any[] = [];
  private workTypes: any[] = [];

  constructor(
    private dialogController: DialogController,
    private inHouseWFMService: InHouseWFMService,
    private teamService: TeamsService,
    private memberService: MembersService,
    private sessionStore: SessionStore,
    private workTypeService: WorkTypesService
  ) {}

  public async activate(): Promise<void> {
    await this.retrieveTeams();
    await this.retrieveMembers();
    await this.retrieveShiftTemplates();
    await this.retrieveWorkTypes();
    this.generateForm();
  }

  private async retrieveTeams(): Promise<void> {
    const teams = await this.teamService.retrieveOrganisationTeams(
      this.sessionStore.get.organisation.organisationId,
    );
    this.teams = ArrayTools.sort(teams, 'name');
  }

  private async retrieveMembers(searchText?: string): Promise<any[]> {
    let members = await this.memberService.retrieveOrganisationMembers(
      null,
      searchText || null,
      null,
      this.pageNumber,
      this.pageSize,
    );

    if (!members) {
      members = [];
    }

    const mappedMembers = members.map((member) => {
      return {
        value: member.id,
        label: `${member.firstName} ${member.surname}`,
        icon:
          member.roles.length > 0
            ? member.roles[0].toLowerCase().replace('_', '-')
            : 'user',
      };
    });

    if (this.pageNumber === 0) {
      this.members = mappedMembers;
      const mappedTeams = this.teams.map((team) => {
        return {
          value: team.id,
          label: team.name,
          icon: 'teams',
        };
      });
      this.memberTeams = [].concat(mappedTeams, mappedMembers);
    } else {
      this.members = [].concat(this.members, mappedMembers);
      this.memberTeams = [].concat(this.memberTeams, mappedMembers);
    }
    return mappedMembers;
  }

  private async retrieveShiftTemplates(): Promise<void> {
    const shiftTemplates = await this.inHouseWFMService
      .retrieveShiftTemplates()
      .catch((e) =>
        console.warn(' > Failed to retrieve shift templates due to ', e)
      );
    if (shiftTemplates) {
      this.shiftTemplates = ArrayTools.sort(shiftTemplates.templates, 'name');
    }
  }

  private async retrieveWorkTypes(): Promise<void> {
    const workTypes = await this.workTypeService
      .retrieveWorkTypes()
      .catch((e) => console.warn(' > Failed to retrieve worktypes due to', e));
    this.workTypes = ArrayTools.sort(workTypes, 'templateName');
  }

  private generateForm(): void {
    new ZaiForm()
      .newRow()
      .newField()
      .asSearchDropdown()
      .fullWidth()
      .withTitle('Member/Team', '110px')
      .withInputWidth('120px')
      .withClass('flex--basis')
      .withIdentifier('memberTeams')
      .withDisplayField('label')
      .withOptions(this.memberTeams)
      .withPlaceholder('Select an option')
      .withValue('')
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .multiSelect()
      .withItemsIdentifier('selectedMemberTeams')
      .withValues(this.formData.selectedMemberTeams)
      .enableShowMore(() => this.showMore(), this.pageSize)
      .insertField()

      .newRow()
      .newField()
      .asDateInput()
      .withMinValue(moment().format('DD/MM/YYYY'))
      .withTitle('From', '110px')
      .withIdentifier('fromDate')
      .withValue(this.formData.fromDate)
      .withValidation([
        {
          validationType: ZaiForm.VALIDATION_TYPES.REQUIRED,
          validationMessage: 'Select a date',
        },
      ])
      .insertField()
      .newField()
      .asDateInput()
      .withMinValue(moment().format('DD/MM/YYYY'))
      .withTitle('To', '70px')
      .withIdentifier('toDate')
      .withValue(this.formData.toDate)
      .withValidation([
        {
          validationType: ZaiForm.VALIDATION_TYPES.REQUIRED,
          validationMessage: 'Select a date',
        },
      ])
      .insertField()

      .newRow()
      .newField()
      .asDropdown()
      .withTitle('Shift Template', '110px')
      .withIdentifier('shiftTemplate')
      .withPlaceholder('Select a template')
      .withDisplayField('name')
      .openToTop()
      .withOptions(this.shiftTemplates)
      .withValue(this.formData.shiftTemplate)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .newRow()
      .newField()
      .asSearchDropdown()
      .fullWidth()
      .withTitle('Work Types', '110px')
      .withInputWidth('120px')
      .withClass('flex--basis')
      .withIdentifier('workTypes')
      .withDisplayField('templateName')
      .withOptions(this.workTypes)
      .withPlaceholder('Select a work type')
      .withValue('')
      .openToTop()
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .multiSelect()
      .withItemsIdentifier('selectedWorkTypes')
      .withValues(this.formData.selectedWorkTypes)
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  private showMore(): Promise<any[]> {
    this.pageNumber++;
    return this.retrieveMembers();
  }

  public formDataChanged = (data: any): void => {
    this.form = null;
    setTimeout(() => {
      this.formData.selectedMemberTeams = data.selectedMemberTeams;
      this.formData.fromDate = data.fromDate;
      this.formData.toDate = data.toDate;
      this.formData.shiftTemplate = data.shiftTemplate;
      this.formData.selectedWorkTypes = data.selectedWorkTypes;
      this.formData.valid = data.valid && data.selectedMemberTeams.length > 0 && data.shiftTemplate && data.selectedWorkTypes.length > 0;

      if (data.fromDate && data.toDate) {
        const fromDate = moment(data.fromDate, 'DD/MM/YYYY');
        const toDate = moment(data.toDate, 'DD/MM/YYYY');

        if (
          fromDate !== toDate &&
          moment(toDate).isBefore(fromDate)
        ) {
          this.error = 'To date should be after from date.';
        } else {
          if (toDate.diff(fromDate, 'days') + 1 > 30) {
            this.error = 'Please select at most 30 days.';
          } else {
            this.error = '';
          }
        }
      } else {
        this.error = '';
      }

      this.generateForm();
    });
  };

  private async getTeamIdsFromNames(selectedTeams: any[]): Promise<string[]> {
    const teamIds = selectedTeams.map((selectedTeam) => {
      const team = this.teams.find((_team) => {
        return _team.teamName === selectedTeam;
      });
      return team ? team.id : null;
    });
  
    return teamIds.filter((id) => id !== null);
  }

  private async getMemberIdsFromNames(
    selectedMembers: any[]
  ): Promise<string[]> {
    const memberIds = selectedMembers.map((selectedMember) => {
      const member = this.members.find((_member) => {
        return _member.label === selectedMember
      });
      return member ? member.value : null;
    });
    return memberIds.filter((id) => id !== null);
  }

  public getShiftTemplateId(shiftTemplateName: string): string {
    const shiftTemplate = this.shiftTemplates.find(
      (template) => template.name === shiftTemplateName
    );
    return shiftTemplate.id;
  }

  private async getWorkTypeIdsFromNames(
    selectedWorkTypes: any[]
  ): Promise<string[]> {
    const workTypeIds = selectedWorkTypes.map((selectedWorkType) => {
      const workType = this.workTypes.find((_workType) => {
        return _workType.templateName === selectedWorkType;
      });
      return workType ? workType.templateId : null;
    });
  
    return workTypeIds.filter((id) => id !== null);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public async confirm(): Promise<void> {
    this.error = '';
    if (this.formData.valid) {
      this.submitting = true;

      const teamIds = await this.getTeamIdsFromNames(
        this.formData.selectedMemberTeams
      );
      const memberIds = await this.getMemberIdsFromNames(
        this.formData.selectedMemberTeams
      );
      const shiftTemplateId = await this.getShiftTemplateId(
        this.formData.shiftTemplate
      );
      const taskTemplateIds = await this.getWorkTypeIdsFromNames(
        this.formData.selectedWorkTypes
      );
      const payload = {
        teamIds: teamIds.filter((id) => !!id),
        memberIds: memberIds.filter((id) => !!id),
        fromDate: this.formData.fromDate,
        toDate: this.formData.toDate,
        shiftTemplateId,
        taskTemplateIds: taskTemplateIds.filter((id) => !!id),
      };
      this.createShift(payload);
    }
  }

  private createShift(payload: {
    teamIds: string[];
    memberIds: string[];
    fromDate: string;
    toDate: string;
    shiftTemplateId: string;
    taskTemplateIds: string[];
  }): void {
    this.inHouseWFMService
      .createShift(
        payload.teamIds,
        payload.memberIds,
        payload.fromDate,
        payload.toDate,
        payload.shiftTemplateId,
        payload.taskTemplateIds
      )
      .then(() => {
        this.dialogController.ok({});
      })
      .catch((e) => {
        console.warn(' > Failed to update Agent Sampling Config due to:', e);
        this.submitting = false;
        this.error = e.response;
      });
  }
}
