import {LogManager, customElement, bindable} from 'aurelia-framework';
// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('ZTimer');

@customElement('z-timer')
export class ZTimer {

  @bindable({attribute: 'start-time'}) private startTime: boolean;
  @bindable({attribute: 'max-duration-in-milliseconds'}) private maxDurationInMilliseconds?: boolean;
  @bindable({attribute: 'duration-exceeded-text'}) public durationExceededText?: string;
  @bindable size?: string;
  public exceeded: boolean = false;

  private interval: number;
  public liveTimer: string;

  public attached(): void {
    if (!this.startTime) {
      return;
    }

    // Update the timer display every second
    this.interval = setInterval(() => this.updateTimer(), 150);
  }

  private updateTimer(): void {
    const diffDuration = moment.duration(moment.utc().diff(moment(this.startTime).utc()));

    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    const seconds = diffDuration.seconds();

    // Update the timer display
    this.liveTimer = `${hours < 10 ? '0' + hours: hours}h${minutes < 10 ? '0' + minutes: minutes}m:${seconds < 10 ? '0' + seconds : seconds}s`;

    if (this.maxDurationInMilliseconds) {
      if (diffDuration > this.maxDurationInMilliseconds) {
        window.clearInterval(this.interval);
        this.exceeded = true;
      }

    }
  }

  public detached(): void {
    this.interval && window.clearInterval(this.interval);
  }
}
