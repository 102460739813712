import { DialogController, DialogService } from 'aurelia-dialog';
import { computedFrom, inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { DownloadService, SessionStore } from 'zailab.common';
import { ViewInteractionTranscriptChatDialog } from '../../interactiontranscript/view-interaction-transcript-chat-dialog';
import { ViewInteractionTranscriptDialog } from "../../interactiontranscript/view-interaction-transcript-dialog";
import { ViewInteractionTranscriptIMDialog } from '../../interactiontranscript/view-interaction-transcript-im-dialog';
import InteractionLogService from "../interaction-log-service";
import { ViewInteractionPhraseAnalyticsDialog } from "../interactionlogphraseanalytics/view-interaction-phrase-analytics-dialog";
import {ViewInteractionDialog} from '../viewinteraction/view-interaction-dialog';
import {MembersService} from "../../../../organisation/member/members/members-service";
import {ConversationService} from "../../../../organisation/conversation/conversation-service";

const logger = LogManager.getLogger('ViewLinkedInteractionDialog');

@inject(SessionStore, DialogController, HttpClient, DownloadService, DialogService, InteractionLogService, MembersService, ConversationService)
export class ViewLinkedInteractionDialog {

    interaction = null;
    dialogHeader = '';
    retrievingRecording = false;
    showEmailRecipients = false;
    color;
    payloadDetail = null;
    additionalData = [];
    callTranscript = {};
    detectedPhrases = [];
    phrasesDetected = false;
    consultInteractions = [];

  ONE_DAY_IN_MS = 86400000;
  ONE_WEEK_IN_MS = 7 * this.ONE_DAY_IN_MS;

  constructor(
    sessionStore,
    dialogController,
    httpClient,
    downloadService,
    dialogService,
    interactionLogService,
    membersService,
    conversationService
  ) {
        this.sessionStore = sessionStore;
        this.dialogController = dialogController;
        this.dialogService = dialogService;
        this.httpClient = httpClient;
        this.downloadService = downloadService;
        this.interactionLogService = interactionLogService;
        this.membersService = membersService;
        this.conversationService = conversationService;
    }

    /**
     * gets called when the dialog view model is activated.
     * @param interaction
     * */
    activate(interaction) {
        this.interaction = interaction;
        if (interaction.additionalData) {
          for (const prop in interaction.additionalData) {
            this.additionalData.push({ name: prop, value: interaction.additionalData[prop] });
          }

          if (interaction.metaData && interaction.metaData.disposition && !this.interaction.disposition) {
            this.interaction.disposition = interaction.metaData.disposition;
          }
        }

        if (interaction.callSentiment) {
          this.interaction.callSentiment = interaction.callSentiment;
        }

        if (interaction.callTranscript) {
          this.hasTranscript = interaction.callTranscript.words && interaction.callTranscript.words.length > 0;
          this.callTranscript = interaction.callTranscript;
        }

        if (interaction.detectedPhrases && interaction.detectedPhrases.length > 0) {
          this.detectedPhrases = interaction.detectedPhrases;
          this.phrasesDetected = true;
        }
      interaction.members.forEach(member => {
        this.membersService.retrieveMember(member.memberId)
      .then(memberResult => {
          member.firstName = memberResult.firstName;
          member.surname = memberResult.surname;
        });
      })
    }

    attached() {
    this.dialogHeader = `${this.interaction.formattedChannel} ${this.interaction.formattedWorkType === '-' ? '' : ` - ${this.interaction.formattedWorkType}`}`;

    if (this.interaction.formattedOutcome === 'answered') {
      this.color = 'var(--successful)';
    } else if (this.interaction.formattedOutcome === 'abandoned') {
      this.color = 'var(--unsuccessful)';
    }else if (this.interaction.formattedOutcome === 'missed') {
      this.color = 'var(--unsuccessful)';
    }else if (this.interaction.formattedOutcome === 'forwarded') {
      this.color = 'var(--pending)';
    } else if (this.interaction.formattedOutcome === 'mailbox') {
      this.color = 'var(--pending)';
    } else if (this.interaction.formattedOutcome === 'callback') {
      this.color = 'var(--pending)';
    } else if (this.interaction.formattedOutcome === 'sent') {
      this.color = 'var(--successful)';
    } else if (this.interaction.formattedOutcome === 'received') {
      this.color = 'var(--successful)';
    }

  }
  viewAnalytics(phrase) {
    let toDate = new Date().getTime();
    let fromDate = toDate - this.ONE_WEEK_IN_MS;
    this.interactionLogService.retrieveAnalytics(phrase, fromDate, toDate).then(response => {
      let analytics = response;
      let analyticsPayload = {};
      analyticsPayload.phrase = phrase;
      analyticsPayload.results = analytics.results;

      this.dialogService
        .whenClosed({
          viewModel: ViewInteractionPhraseAnalyticsDialog,
          model: analyticsPayload
        })
        .whenClosed(response => {
          if (response.wasCancelled) {

          }
        });
    }, error => {
      logger.error('An error occurred fetching analytics ', error);
    });

  }

  viewTranscript() {
  this.dialogService
    .open({
      viewModel: ViewInteractionTranscriptDialog,
      model: {'transcript': this.callTranscript, 'interaction': this.interaction}
    })
    .whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  viewChatTranscript() {
  this.dialogService
    .open({
      viewModel: ViewInteractionTranscriptChatDialog,
      model: {'transcript': this.chatTranscript, 'interaction': this.interaction}
    })
    .whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  viewIMTranscript() {
  this.dialogService
    .open({
      viewModel: ViewInteractionTranscriptIMDialog,
      model: {'transcript': this.imTranscript, 'interaction': this.interaction}
    })
    .whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  retrieveRecording() {
    this.retrievingRecording = true;
    this.interactionLogService.retrieveRecording(this.interaction.recordingId).then(response => {
      this.retrievingRecording = false;
      this.interaction.recordingURL = response.recordingURL;
    }, error => {
      this.retrievingRecording = false;
    });

  }

  performQA() {
    let performQAResponse = {
      interactionId: this.interaction.interactionId,
      recordingId: this.interaction.recordingId ? this.interaction.recordingId : 'noRecording',
      memberId: this.interaction.members && this.interaction.members.length > 0 ? this.interaction.members[0].memberId : 'noMembers',
      callType: this.interaction.type,
      channel: this.interaction.channel,
      firstName: this.interaction.members[0].firstName,
      surname: this.interaction.members[0].surname,
      performQA: true
    };
    this.dialogController.ok(performQAResponse);
  }

  viewInteraction(interaction) {
      this.dialogService
        .open({
          viewModel: ViewInteractionDialog,
          model: interaction
        })
        .whenClosed(response => {
          if (response.wasCancelled) {

          }
        });
  }


  toggleRecipients(event) {
    event.stopPropagation();
    this.showEmailRecipients = !this.showEmailRecipients;
  }


  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.interaction = null;
    this.dialogController.cancel();
  }

  @computedFrom('interaction')
  get chatContact() {
    if(this.interaction) {
      return this.interaction.payload.source;
    }
  }

  @computedFrom('chatContact')
  get chatContactEmail() {
    return this.chatContact.email;
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  @computedFrom('user.hasQAManagerRole')
  get hasQAManagerRole() {
    return this.user.hasQAManagerRole;
  }

  @computedFrom('user.hasQARole')
  get hasQARole() {
    return this.user.hasQARole;
  }

  @computedFrom('interaction.recordingId', 'interaction.channel', 'interaction.type', 'hasQAManagerRole', 'hasQARole')
  get canPerformQA() {
    if (this.interaction) {
      return (this.interaction.recordingId || this.interaction.formattedChannel === 'Outbound Email' || this.interaction.channel === 'INSTANT_MESSAGE' || this.interaction.channel === 'CHAT') &&
        (this.hasQAManagerRole || this.hasQARole) &&
        this.interaction.type !== 'Office Flow';
    }
  }
}
