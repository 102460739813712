import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('WaitingRoomConfigurationWidget');

@autoinject
export class WaitingRoomConfigurationWidget {

  private actions = ['view'];

  constructor(private router: Router) { }

  private view(): void {
    this.router.navigate('waiting-room-configuration');
  }
}
