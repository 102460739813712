import {inject, BindingEngine, LogManager} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer} from 'zailab.common';
/*
 * */
let logger = LogManager.getLogger('SetPriority');
/*
 * */
@inject(BindingEngine, ValidationControllerFactory)
export default class {

  taskResponseTimeObserver;
  targetResponseObserver;
  taskResponseTime;
  targetResponse;

  constructor(bindingEngine, validationControllerFactory) {
    this.bindingEngine = bindingEngine;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(wizard) {

    this._initWizard(wizard);
    wizard.data.businessValue = wizard.data.businessValue || 1;
    this.targetResponse = wizard.data.targetResponse || '';
    this.taskResponseTime = wizard.data.taskResponseTime || '';
    this._initValidation(wizard);

    this.taskResponseTimeObserver = this.bindingEngine.propertyObserver(this, 'taskResponseTime').subscribe(() => {
      if(this.targetResponse) {
        this._validateState();
      }
    });

    this.targetResponseObserver = this.bindingEngine.propertyObserver(this, 'targetResponse').subscribe(() => {
      if(this.taskResponseTime) {
        this._validateState();
      }
    });
  }

  detached() {

    if(this.taskResponseTimeObserver) {
      this.taskResponseTimeObserver.dispose();
    }
    if(this.targetResponseObserver) {
      this.targetResponseObserver.dispose();
    }
  }

  _initValidation(wizard) {

    ValidationRules
      .customRule('validTaskResponseTimeHoursMaxValue', (value) => {
        if(value > 120) {
          this.wizard.step.incomplete();
          return false;
        }
        return true;
      });

    ValidationRules
      .customRule('validTaskResponseTimeSecondsMaxValue', (value) => {
        if(value > 3600) {
          this.wizard.step.incomplete();
          return false;
        }
        return true;
      });

    ValidationRules
      .customRule('checkTargetResponseMaxValue', (value) => {
        if(value > 100) {
          this.wizard.step.incomplete();
          return false;
        }
        return true;
      });

    let _targetValidation = (rule, message) => {
      ValidationRules
        .ensure('targetResponse')
        .required().withMessage('Please enter a target percentage.')
        .matches(/^([0-9])+$/).withMessage('Target percentage must be in numbers.')
        .satisfiesRule('checkTargetResponseMaxValue').withMessage('The target percentage cannot be more than 100.')

        .ensure('taskResponseTime')
        .required().withMessage('Please enter a response time.')
        .matches(/^([0-9]+\.?[0-9]*|\.[0-9]+)$/).withMessage('Response time must be in numbers.')
        .satisfiesRule(rule).withMessage(message)
        .on(this);
    };

    if(this.selectedChannel !== 'INBOUND_CALL') {
      _targetValidation('validTaskResponseTimeHoursMaxValue', 'The response time cannot be more than 120.');
    } else {
      _targetValidation('validTaskResponseTimeSecondsMaxValue', 'The response time cannot be more than 3600.');
    }
  }

  _validateState() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        this.wizard.step.incomplete();
        return;
      }
      this.wizard.step.complete({
        taskResponseTime: this.taskResponseTime,
        targetResponse: this.targetResponse
      });
    });
  }

  get selectedChannel() {
    for(let item of this.wizard.data.channels) {
      if(item.isSelected) {
        return item.channelName;
      }
    }
  }

  _initWizard(wizard) {
    this.wizard = wizard;

    wizard.beforeNextStep(() => {
      this.validateStep(this.wizard.continue);
    });
  }

  validateStep(callback) {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }
      callback();
    });
  }

  get responseInHours() {

    let channelName = '';
    for(let item of this.wizard.data.channels) {
      if(item.isSelected) {
        channelName = item.channelName;
      }
    }
    return channelName === 'EMAIL' || channelName === 'SMS' || channelName === 'OUTBOUND_CALL' || channelName === 'TICKET';
  };
}
