import {autoinject, PLATFORM} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
/**/
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
/**/
@autoinject
export class PatternMenuDialog {

  private nodeData: NodeModel;
  private steps: object[];
  private existingConnections: ConnectorModel[];
  private isAddingContent: boolean = false;
  private nodeConfiguration: ZNodeConfig;

  constructor(private dialogController: DialogController, private eventAggregator: EventAggregator) {
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.initSteps();
  }

  private initSteps(): void {
    const data = {
      ...this.nodeData.properties,
      version: this.nodeData.version
    }

    this.steps = [{
      name: 'Name',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/pattern-menu/steps/name/name'),
      active: true,
      hasAttributes: true,
      completed: false,
      data
    }, {
      name: 'Message',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/pattern-menu/steps/message/message'),
      hasAttributes: true,
      completed: false,
      data
    }, {
      name: 'Keypad Options',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/pattern-menu/steps/keypad/keypad'),
      hasAttributes: true,
      completed: false,
      data
    }];
  }

  private activateContentCreator(): void {
    this.isAddingContent = true;
  }

  private cancelContentCreator(): void {
    this.isAddingContent = false;
  }

  private complete(wizardData: any): void {
    let properties: object = wizardData.detail;

    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    updatedNode.config = null;
    this.dialogController.ok(updatedNode);
  }

  private mapNodeProperties(_properties: any): NodeModel {
    let updatedConnections: Array<ConnectorModel> = [];
    updatedConnections.push(new ConnectorModel({
      name: 'always',
      source: {connectorIndex: 0, nodeID: this.nodeData.id}
    }));

    if (_properties.timeoutDestination) {
      let connection: ConnectorModel = new ConnectorModel({
        name: 'Timeout', source: {
          connectorIndex: 1, nodeID: this.nodeData.id
        }
      });
      updatedConnections.push(connection);
    }

    updatedConnections = this.mapExistingConnections(updatedConnections, this.existingConnections);

    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    this.nodeData.properties = _properties;
    this.nodeData.properties.isDefined = true;

    return new NodeModel(this.nodeData);
  }

  private mapExistingConnections(updatedConnections: ConnectorModel[], existingConnections: ConnectorModel[]): ConnectorModel[] {

    let connectorIndex: number = 0;
    updatedConnections.forEach(connection => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest = existingConnections[connectorIndex].dest;
      }
      connectorIndex++
    });
    return updatedConnections;
  }

  private cancel(): void {
    this.dialogController.cancel();
  }
}
