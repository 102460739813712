import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { v4 as uuidv4 } from 'uuid';

import { EmailSignatureModel } from '../models/email-signature-model';
import { ChannelService } from '../../../organisation/conversation/channel/channel-service';
import { EmailImage } from '../../../organisation/conversation/channel/views/email/email-image';
/**/
const logger = LogManager.getLogger('EmailSignatureDialog');

@autoinject
export class EmailSignatureDialog {

  public signature: string;
  public type: string = 'text/html';

  public error: string = null;
  public isAddingImage: boolean = false;
  public images: EmailImage[] = [];

  public editorOptions = {
    formats: [
      'bold',
      'italic',
      'underline',
      'strike',
      'list',
      'font',
      'color',
      'background',
      'align',
      'image',
      'imageUpload'
    ],
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['image'],
        ['clean']
      ],
      imageUpload: {
        upload: (file: Object, imageId: string) => {
          this.error = '';
          //@ts-ignore
          let image: EmailImage = new EmailImage(imageId, file.name, file.size);
          const id = uuidv4();
          this.isAddingImage = true;

          return new Promise((resolve, reject) => {
            if (image.exceedsImageFileSize()) {
              this.error = image.maxImageSizeMessage;
              reject(new Error('Could not upload image to editor.'));
            } else {
              this.channelService
                .uploadImage(id, image.id, file, image.name)
                .then(imageUrl => {
                  image.url = imageUrl.url;
                  this.images.push(image);
                  resolve(image.url);
                })
                .catch(() => reject(new Error('Could not upload image to editor.')));
            }
            this.isAddingImage = false;
          });
        }
      }
    }
  };

  constructor(
    private dialogController: DialogController,
    private channelService: ChannelService
  ) { }

  public activate(emailSignature: EmailSignatureModel): void {
    this.signature = emailSignature.signature;
  }

  public ok(): void {
    let type = this.type;
    let signature = this.signature;
    if (signature === '<p><br></p>' || signature === '<p><br/></p>') {
      signature = null;
    }
    this.dialogController.ok({ type, signature });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }
}
