export class NumericStepperValueConverter {
  toView(_number, _type, _padcount) {
    return addLeadingZeros(sanitise(_type, _number), _padcount);
  }
}

function addLeadingZeros(_number, _padcount) {
  let padcount = 1 * _padcount;
  let number = '' + _number;
  if (padcount <= 0) return number;
  let padding = '';
  for (let i = 0; i < padcount; i++)  padding += '0';
  return (padding + number).slice(-1 * padcount);
}

function sanitise(_type, _number) {
  let number = 1 * _number;
  if (_type === 'hour' && number > 12) return number - 12;
  return number;
}