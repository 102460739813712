import { inject, customElement, bindable, LogManager, containerless } from 'aurelia-framework';
/*
 */
import { Event, SessionStore } from 'zailab.common';
import { List } from 'zailab.abstract';
import { ServicesService } from '../services-service';
/*
 */
let logger = LogManager.getLogger('ServicesWidget');
/*
 */
@containerless()
@customElement('z-condensed-services-list')
@inject(Element, SessionStore, ServicesService)
export default class {

  @bindable required;
  @bindable multiselect;
  @bindable nodefaultselected;
  @bindable selected;
  @bindable canedit;
  @bindable titleoverride;
  @bindable({ attribute: 'items-per-row' }) itemsperrow;
  list;

  constructor(element, sessionStore, servicesService) {
    this.element = element;
    this.sessionStore = sessionStore;
    this.servicesService = servicesService;
  }

  attached() {
    this.servicesService.displayServices().then((_services) => {
      this._setupConfig(_services);
    }, (error) => {
      logger.info('services query failed >> ', error);
    });
  }

  _setupConfig(services) {

    let defaultSelectionStrategy = (this.nodefaultselected) ? null : this._defaultSelectionStrategy;

    this.list = List.Builder() //
      .required(this.required) //
      .customEventElement(this.element)
      .selectionType(this.multiselect ? List.MULTIPLE_SELECTION_TYPE : List.SINGLE_SELECTION_TYPE) //
      .title(this.title) //
      .items(services) //
      .selectedItems(this.selected) //
      .icon('services') //
      .uniqueIdentifier('id') //
      .displayId('name') //
      .selectionStrategy(defaultSelectionStrategy) //
      .enableReadOnly(this.canedit)
      .build();
  }

  select(event) {
    new Event(this.element, 'select', event);
  }

  get title() {
    return (this.titleoverride) ? this.titleoverride : 'Select Service' + (this.multiselect ? 's' : '');
  }

  get itemsPerRow() {
    return this.itemsperrow || 6;
  }

  _defaultSelectionStrategy(item) {
    return item.serviceName.toLowerCase() === 'general';
  }

  updateItems(data) {
    new Event(this.element, 'updateitems', data);
  }
}