import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { WaitingRoomWidgetModel } from './waitingroomwidget/waiting-room-widget-model';

const logger = LogManager.getLogger('Team Leader Dashboard Service');

@autoinject
export class TeamLeaderDashboardService {
  constructor(
    private httpClient: HttpClient
  ) {}

  public retrieveOrganisationTeams(): Promise<ZIOrganisationTeam> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/me/teams')
        .asGet()
        .send()
        .then(
          _response => {
            resolve(_response as ZIOrganisationTeam);
          },
          error => {
            reject(new Error(error));
          }
        );
    });
  }

  public retrieveMyTeams(): Promise<ZIOrganisationTeam> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/teams/me')
        .asGet()
        .send()
        .then(
          _response => {
            resolve(_response as ZIOrganisationTeam);
          },
          error => {
            reject(new Error(error));
          }
        );
    });
  }

  public retrieveAgentsFromSelectedTeam(teamId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/performancemanagement/agentAvailability/${teamId}`)
        .asGet()
        .send()
        .then(
          response => {
            if (response.toString() !== '') {
              resolve(response);
            } else {
              reject('Could not load agent data');
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public retrieveMemberServices(memberId: string): Promise<Array<ZIService>> {
    return new Promise((resolve: any, reject: any) => {
      this.httpClient
        .createRequest(
          `v1/organisation/organisations/me/services-with-task-templates/members/${memberId}`
        )
        .asGet()
        .send()
        .then(
          response => {
            if (response && response['services']) {
              resolve(response['services']);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public retrieveWaitingRoomWidgetData(
    serviceId: string
  ): Promise<WaitingRoomWidgetModel> {
    return new Promise((resolve: any, reject: any) => {
      this.httpClient
        .createRequest(
          `/v1/interaction/live-dashboard/services/${serviceId}/widget`
        )
        .asGet()
        .send()
        .then(
          response => {
            if (response) {
              resolve(
                new WaitingRoomWidgetModel(response as WaitingRoomWidgetModel)
              );
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
