import { autoinject, bindable, containerless, customElement, LogManager } from 'aurelia-framework';
import { bindingMode } from 'aurelia-binding';
import { Router } from 'aurelia-router';
import { AlertService } from '../alert-service';

const logger = LogManager.getLogger('AlertCount');

@containerless
@customElement('z-alert-count')
@autoinject
export class AlertCount {

  @bindable({ defaultBindingMode: bindingMode.twoWay }) public count: number;

  constructor(
    private alertService: AlertService,
    private router: Router
  ) { }

  public bind(): void {
    this.alertService.onAlertMessage(() => this.count++);
    this.alertService.findAllMessagesForToday()
      .then((messages) => {
        this.count = messages.length;
    });
  }

  public view(): void {
    this.router.navigate('alert-messages');
  }
}
