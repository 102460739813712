import {LogManager} from 'aurelia-framework';

import {ChannelModel} from './channel-model';

const logger = LogManager.getLogger('SmsChannelModel');

export class SmsChannelModel extends ChannelModel {

  constructor(size?: string) {
    super('sms', 'comments');
    this.size = size ? size : 'small';
  }
}
