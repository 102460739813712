import { DialogService } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";
import { LineModel } from "components/atoms/line/models/line-model";
import { ZGridBasicModel } from "components/organisms/gridbasic/z-grid-basic-model";
import { ConfirmDialog } from "components_v2/confirm-dialog/confirm-dialog";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMessageService } from "zailab.common";
import { AgentScriptAdminConfigService } from "./agent-script-admin-config-service";
import { AgentScriptConnection, AgentScriptFlow, AgentScriptNode } from "./agent-script-designer-model";
import { AgentScriptNodeDialog } from "./agent-script-node-dialog";
import { AgentScriptPreviewDialog } from "./agent-script-preview-dialog";
import { AgentScriptDefinitionService } from "./agent-script-definition-service";

const logger = LogManager.getLogger('AgentScriptDesigner');

@autoinject
export class AgentScriptDesigner {

  public loading: boolean;
  public name: string;
  public flow: AgentScriptFlow;
  public grid = new ZGridBasicModel();
  public updated: boolean;

  private agentScriptId: string;

  constructor(
    private router: Router,
    private dialog: DialogService,
    private displayMessageService: DisplayMessageService,
    private agentScriptAdminConfigService: AgentScriptAdminConfigService,
    private agentScriptDefinitionService: AgentScriptDefinitionService,
  ) { }

  public activate(params: any): void {
    this.agentScriptId = params.id;
    this.loadScript();
  }

  public preview(): void {
    let error = this.validate();
    if (error) {
      return;
    }
    this.dialog.open({ viewModel: AgentScriptPreviewDialog, model: this.flow });
  }

  private loadScript(): void {
    this.loading = true;
    this.agentScriptAdminConfigService.retrieve(this.agentScriptId).then((data) => this.name = data.name);
    this.agentScriptDefinitionService.retrieve(this.agentScriptId).then((data) => {
      this.flow = new AgentScriptFlow(data);
      if (!this.flow.startNodeId) {
        this.makeStartNode();
      }
      this.loadGrid();
      setTimeout(() => this.loading = false, 500);
    });
  }

  public moveNode(event: any): void {
    const node = this.flow.getNodeAt(event.detail.fromX, event.detail.fromY);
    node.posX = event.detail.toX;
    node.posY = event.detail.toY;
    this.loadGrid();
    this.updated = true;
  }

  public deleteConns(event: any): void {
    const connectorIds = event.detail.connectorIds;
    this.dialog.open({ viewModel: ConfirmDialog, model: { header: 'Delete Connection', text: 'Are you sure?' } }).whenClosed((res: any) => {
      if (res.wasCancelled) {
        return;
      }
      this.flow.connections.filter((conn) => connectorIds.includes(conn.id)).forEach((conn) => conn.destinationNodeId = null);
      this.loadGrid();
      this.updated = true;
    });
  }

  public moveConn(event: any): void {
    const toX = event.detail.toX;
    const toY = event.detail.toY;
    const connector = event.detail.conn;
    this.flow.moveConnection(connector.id, toX, toY);
    this.loadGrid();
    this.updated = true;
  }

  public editNode(event: any): void {

    const model = event.detail;
    model.edit = true;
    model.names = this.flow.nodes.filter((node) => node.id !== model.cell.id).map((node) => {
      return node.name;
    });

    this.dialog.open({ viewModel: AgentScriptNodeDialog, model }).whenClosed((res: any) => {

      if (res.wasCancelled) {
        return;
      }

      const item = res.output;

      const node = this.flow.getNode(item.id);
      node.name = item.name;
      node.text = item.text;

      const updatedConnIds = item.connections.map((conn) => {
        return conn.id;
      });
      const existingConnIds = node.connectionIds;

      const addedConnections = item.connections.filter((conn) => !existingConnIds.includes(conn.id));
      const removedConnections = node.connectors.filter((conn) => !updatedConnIds.includes(conn.id));

      for (const conn of addedConnections) {
        const connection = new AgentScriptConnection();
        connection.id = conn.id;
        connection.name = conn.name;
        connection.sourceNodeId = item.id;
        this.flow.addConnection(connection);
      }

      for (const conn of removedConnections) {
        this.flow.removeConnection(conn.id);
      }

      node.connectionIds = updatedConnIds;

      this.loadGrid();
      this.updated = true;
    });
  }

  public deleteNode(event: any): void {
    this.dialog.open({ viewModel: ConfirmDialog, model: { header: 'Delete Node', text: 'Are you sure?' } }).whenClosed((res: any) => {
      if (res.wasCancelled) {
        return;
      }
      const nodeId = event.detail.cell.id;
      this.flow.removeNode(nodeId);
      this.flow.removeConnections(nodeId);
      this.flow.removeTargetConnections(nodeId);
      this.loadGrid();
      this.updated = true;
    });
  }

  public addNode(event: any): void {

    const config = event.detail;

    const model = event.detail;
    model.names = this.flow.nodes.map((node) => {
      return node.name;
    });

    this.dialog.open({ viewModel: AgentScriptNodeDialog, model }).whenClosed((res: any) => {

      if (res.wasCancelled) {
        this.loadGrid();
        return;
      }

      const item = res.output;

      const sourceConnections = item.connections.map((conn) => {
        const connection = new AgentScriptConnection();
        connection.id = conn.id;
        connection.name = conn.name;
        connection.sourceNodeId = item.id;
        return connection;
      });

      this.flow.addConnections(sourceConnections);

      const node = new AgentScriptNode();
      node.id = item.id;
      node.name = item.name;
      node.text = item.text;
      node.x = item.x;
      node.y = item.y;
      node.connectionIds = item.connections.map((conn) => conn.id);
      node.connections = sourceConnections;

      this.flow.addNode(node);

      if (config.conn) {

        const connection = new AgentScriptConnection();
        connection.id = config.conn.id;
        connection.name = config.conn.name;
        connection.sourceNodeId = config.cell.id;
        connection.destinationNodeId = item.id;

        this.flow.addConnection(connection);
      }

      this.loadGrid();
      this.updated = true;
    });
  }

  public back(): void {
    if (!this.updated) {
      this.router.navigateBack();
      return;
    }
    this.dialog.open({ viewModel: ConfirmDialog, model: { header: 'Unsaved changes will be lost', text: 'Are you sure?' } }).whenClosed((res: any) => {
      if (res.wasCancelled) {
        return;
      }
      this.router.navigateBack();
    });
  }

  public save(): void {

    let error = this.validate();
    if (error) {
      return;
    }

    this.loading = true;
    const updatedFlow = this.mapFlow();
    this.agentScriptDefinitionService.update(updatedFlow)
      .then(() => {
        this.router.navigateBack();
      })
      .catch((error) => {
        this.loading = false
        if (error.statusCode === 400) {
          const response = JSON.parse(error.response);
          for (const detail of response.details) {
            this.displayMessageService.showCustomWarning(`${response.message}: ${detail}`);
          }
        }
      });
  }

  private mapFlow() {
    const updatedFlow = { agentScriptId: this.agentScriptId, startNodeId: this.flow.startNodeId, nodes: [], connections: [] };
    updatedFlow.nodes = this.flow.nodes.map((node) => {
      const id = node.id;
      const name = node.name;
      const text = node.text;
      const connectionIds = node.connectionIds || [];
      const x = node.x;
      const y = node.y;
      return { id, name, text, connectionIds, coordinates: { x, y } };
    });
    updatedFlow.connections = this.flow.connections.map((conn) => {
      const id = conn.id;
      const name = conn.name;
      const sourceNodeId = conn.sourceNodeId;
      const destinationNodeId = conn.destinationNodeId;
      return { id, name, sourceNodeId, destinationNodeId };
    });
    return updatedFlow;
  }

  private validate() {
    let error = false;
    const endNode = this.flow.nodes.find((node) => !node.connectionIds || !node.connectionIds.length);
    if (!endNode) {
      this.displayMessageService.showCustomWarning('Must have an end node');
      error = true;
    }
    const unconnectedConnections = this.flow.connections.filter((conn) => !conn.destinationNodeId);
    if (unconnectedConnections && unconnectedConnections.length > 0) {
      this.displayMessageService.showCustomWarning('All nodes outputs must be connected');
      error = true;
    }
    const unconnectedNodes = this.flow.nodes.filter((node) => {
      if (node.startNode) {
        return false;
      }
      const connection = this.flow.connections.find((conn) => conn.destinationNodeId === node.id);
      return !connection;
    });
    if (unconnectedNodes && unconnectedNodes.length > 0) {
      this.displayMessageService.showCustomWarning('All nodes inputs must be connected');
      error = true;
    }
    return error;
  }

  public resetGrid(event: any): void {
    this.loadGrid();
  }

  private loadGrid(): void {

    this.grid.nodes = this.flow.nodes.map((node) => {
      const unconnectedConnections = this.flow.connections.filter((conn) => conn.sourceNodeId === node.id && !conn.destinationNodeId);
      const inputConnection = this.flow.connections.find((conn) => conn.destinationNodeId === node.id);
      const startNode = node.id === this.flow.startNodeId;
      const outputsConnected = !unconnectedConnections || !unconnectedConnections.length;
      const inputConnected = inputConnection || startNode ? true : false;
      node.defined = outputsConnected && inputConnected;
      node.startNode = startNode;
      return node;
    });

    const connections = this.flow.connections.map((conn) => {
      const sourceNode = this.flow.getNode(conn.sourceNodeId);
      const index = !sourceNode.connectionIds || !sourceNode.connectionIds.length ? 0 : sourceNode.connectionIds.findIndex((connectionId) => conn.id === connectionId);
      const id = conn.id;
      const name = conn.name;
      const sourceNodeId = conn.sourceNodeId;
      const targetNodeId = conn.destinationNodeId;
      const coordinates = new LineModel();
      const selected = false;
      const connected = false;
      const dragging = false;
      return { id, index, name, sourceNodeId, targetNodeId, coordinates, selected, connected, dragging };
    });

    this.grid.loadGrid(connections);
  }

  private makeStartNode(): void {

    const nodeId = uuidv4();
    const connectionId = uuidv4();
    const connectionName = 'always';

    const connection = new AgentScriptConnection();
    connection.id = connectionId;
    connection.name = connectionName;
    connection.sourceNodeId = nodeId;
    if (this.flow && this.flow.startNodeId) {
      connection.destinationNodeId = this.flow.startNodeId;
    }

    const node = new AgentScriptNode();
    node.id = nodeId;
    node.name = 'Start';
    node.x = 0;
    node.y = 0;
    node.startNode = true;
    node.defined = true;
    node.connectionIds = [connectionId];
    node.connections = [connection];

    this.flow.addNode(node);
    this.flow.addConnection(connection);
    this.flow.startNodeId = nodeId;
  }
}
