export class BalloonConfig {
  public adjustBorderColor: boolean = true;
  public borderThickness: number = 1;
  public color: string = 'var(--theme-text-default)';
  public cornerRadius: number = 4;
  public fillColor: string = 'var(--theme-background-default)';

  constructor() {

  }
}
