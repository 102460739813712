/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZUtilityButtonText');
/*
 */
@customElement('z-utility-button-text')
export class ZUtilityButtonText {

  @bindable label;
  @bindable position;
  @bindable spaced;
  @bindable loadcheck;

}
