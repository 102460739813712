/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZTabsContent');
/*
 */
@customElement('z-tabs-content')
export class ZTabsContent {

  @bindable header;
  @bindable utility;
  bodyHeight;
  bind() {
    let header = this.header;
    let utility = this.utility;
    let headerAndUtility = (this.header && this.utility);

    if (headerAndUtility) {
      this.bodyHeight = 'o-tabs-content__body--header-utility';
    } else if (utility) {
      this.bodyHeight = 'o-tabs-content__body--utility';
    } else if (header) {
      this.bodyHeight = 'o-tabs-content__body--header';
    }
  }
}