import {UserSessionModel} from "../stores/sessionmodels/user-session-model";


export class MockSessionStore {
  store = {};

  constructor() {
    this.store.organisation = {
      organisationId: 'MOCK_ID',
      country: {
        name: "marcel",
        code: "ZA"
      }
    };
    this.store.user = new UserSessionModel({
      integration: {
        name: 'mockProvider'
      },
      email: 'mock@email.com',
      memberId: 'MOCK_MEMBER_ID'
    });
    this.get = this.store;
  }
}

export class MockRouter {
  navigate = (route) => {};
  history = {
    previousLocation: 'integration-auth?code'
  };
}

export class MockEventAggregator {
  publish(eventName, payload) {}
}

export class MockApplicationProperties {
  mockId = 'mock';
  showForPostProdOnly = (mockId) => {
    return this.mockId === mockId || mockId === 'MOCK_ID';
  };
}

export class MockOplogService {
  subscribeOn = (key, value) => {};
  unsubscribe = () => {};
  unsubscribeAll = () => {};
}

export class MockValidationControllerFactory {
  createForCurrentScope() {
    return {
      addRenderer: () => {},
      validate: () => {
        return new Promise(resolve => {
          resolve({ valid: true });
        });
      }
    }
  }
}

export class MockDialogService {
  controllers = [];
  open = () => {};
  cancel = () => {};
}

export class MockInteractionService {}

export class MockTelephonyService {
  transfer = () => {};
  dial = () => {};
  consult = () => {};
  connect = () => {};
  cancelConsult = () => {};
}

export const MOCK_INTERACTIONS =  {
  connected: [{
    channel: 'Call',
    state: 'CONNECTED',
    interactionId: 'MOCK_ID',
    interactionType: 'CONTACT_CENTRE_FLOW'
  }],
  notConnected: [{
    channel: 'Call',
    state: 'WRAP_UP',
    interactionId: 'MOCK_ID',
    interactionType: 'inbound flow call'
  }]
};

export const MOCK_INTEGRATIONS =  {
  connections: [
    // {
    //   id: "589d504e-8cdf-4d00-928a-8830130b8747",
    //   name: "Salesforce",
    //   isAuthenticated: true,
    //   isSynced: true
    // }
    // ,
    // {
    //   id: "589d504e-8cdf-4d00-928a-8830130b9999",
    //   name: "Zendesk",
    //   isAuthenticated: true,
    //   isSynced: true
    // }
  ]
};

export const MOCK_MEMBER =  {
  memberId: 'MOCK_MEMEBERID',
  extension: 10000
};

export const MOUSE_EVENT = {
  stopPropagation: () => {}
};