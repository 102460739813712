import { TicketModel } from './../interaction/ticket-model';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable, customElement, autoinject, LogManager, TaskQueue, observable } from 'aurelia-framework';
import { FeatureFlagService } from 'zailab.common';

import { ConversationModel } from '../conversation-model';
import { InteractionModel } from '../interaction-model';
import {
  CONTACT_ACTIONS, CONVERSATION_ACTIONS,
  INTERACTION_ACTIONS
} from '../../contact/contactcontroller/contact-controller-actions';
import { ContactModel } from "../../contact/contact-model";

const logger = LogManager.getLogger('ConversationInteractions');

@customElement('z-conversation-interactions')
@autoinject()
export class ConversationInteractions {
  @bindable @observable private conversation: ConversationModel;
  @bindable private contact: ContactModel;
  public toggleEdit: boolean = false;
  public conversationCopy: ConversationModel = null;
  private subscribers: Array<Subscription> = [];
  public dispositionCodeSelectEnabled: boolean = false;
  public conversationStatusDisabled: boolean = false;

  private scrollAttempts = 0;
  private maxScrollAttempts = 10;

  public height: string = '100%';

  constructor(
    private eventAggregator: EventAggregator,
    private featureFlagService: FeatureFlagService
  ) { }

  public bind(): void {
    this.subscribeToEvents();
    this.checkFeatureFlags();
  }
  private subscribeToEvents(): void {
    this.subscribers.push(this.eventAggregator.subscribe('select-disposition-code:enabled' + this.conversation.conversationId, () => {
      this.conversation.dispositionCodeSelectEnabled = true;
    }));
  }

  private checkFeatureFlags(): void {
    
    this.featureFlagService.isEnabled('conversationStatusDisabled')
      .then((conversationStatusDisabled) => {
        this.conversationStatusDisabled = conversationStatusDisabled;
      });
  }

  public attached(): void {
    const contactCardBodyElement: any = document.querySelector('.c-contact-card-body');
    this.height = `${contactCardBodyElement.offsetHeight - 240}px`;

    this.scrollToLastItem();
  }

  private scrollToLastItem(): void {
    if (this.scrollAttempts >= this.maxScrollAttempts) {
      return;
    }
    this.scrollAttempts++;
    if (this.conversation) {
      if (this.conversation.interactions.length > 0) {
        this.scrollAttempts = 0;
        this.conversationChanged();
      } else if (this.conversation.latestInteraction) {
        setTimeout(() => {
          this.scrollToLastItem();
        }, 200);
      }
    }
  }

  public conversationChanged(): void {
    this.conversationCopy = this.conversation;
    if (this.conversation && this.conversation.interactions && this.conversation.interactions.length > 0) {
      let interactions = this.conversation.interactions;
      let lastInteraction = interactions[interactions.length - 1];
      this.selectInteraction(lastInteraction);
      this.jumpToInteraction(lastInteraction.interactionId);
    }
  }

  public selectInteraction(interaction: InteractionModel | TicketModel): boolean {
    this.eventAggregator.publish(INTERACTION_ACTIONS.SELECT, { interaction, contactId: this.contact.contactId });
    return true;
  }

  private jumpToInteraction(interactionId?: string): void {
    if (interactionId) {
      const element = document.getElementById('js-conversation-interaction-' + interactionId);
      if (element) {
        if (element.parentElement) {
          element.parentElement.scrollTop = element.offsetTop;
        }
      } else {
        setTimeout(() => {
          this.jumpToInteraction(interactionId);
        }, 100)
      }
    }
  }

  public editConversationName(): void {
    this.toggleEdit = true;
  }

  public updateConversationName(): void {
    this.toggleEdit = false;
    let conversationName = this.conversation.determineConversationName();

    this.eventAggregator.publish(CONVERSATION_ACTIONS.CHANGE_NAME, {
      newName: conversationName,
      conversationId: this.conversation.conversationId
    });
  }

  public endConversation(): void {
    if (!this.conversation) {
      return;
    }
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: 'conversationStatus',
      contactId: this.conversation.contactId
    });
  }

  public unbind(): void {
    this.subscribers.forEach(subscriber => subscriber.dispose());
  }
}
