import {inject, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('InvitationModel');
/*
 * */
export class InvitationModel extends BaseModel {

  invitationId = null;
  email = null;
  role = null;
  canDelete = true;

  constructor(invitation) {
    super();
    this.mapProperties(invitation);
  }

  get icon() {
    return this.role.toLowerCase().split(' ').join('-');
  }

  get description() {
    return this.role;
  }

}