
import {LogManager, customElement, bindable} from 'aurelia-framework';

import './utility-total.scss';

const logger = LogManager.getLogger('UtilityTotalV2');

@customElement('utility-total-v2')
export class ZUtilityCount {

  @bindable spaced = true;
  @bindable position;
  @bindable count;
  @bindable text;

}
