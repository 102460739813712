export class FileNameValueConverter {
  toView(filePath) {

    if(!filePath) {
      return;
    }

    let startOfName = filePath.lastIndexOf('\\') + 1;
    if (startOfName) {
      let fileName = filePath.substr(startOfName, filePath.length);
      return fileName;
    }
    return filePath;
  }
}
