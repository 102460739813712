import { inject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
/*
 */
import { SessionStore } from 'zailab.common';
import { List, AbstractList } from 'zailab.abstract';
import { InvitationsService } from '../invitations-service';
import { EventAggregator } from "aurelia-event-aggregator";
import { InvitationModel } from '../invitation-model';
/*
 */
let logger = LogManager.getLogger('InvitationsListView');
/*
 */
@inject(Element, DialogService, Router, SessionStore, InvitationsService, EventAggregator)
export class Invitations extends AbstractList {

  constructor(private element: Element, private dialogService: DialogService, private router: Router, private sessionStore: SessionStore, private invitationsService: InvitationsService, protected eventAggregator: EventAggregator) {
    super(eventAggregator)
  }

  public activate(params: any): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.invitationsService.retrieveInvitations().then((invitations) => {
      this.setupListConfig(invitations);
    }, (error) => {
      logger.info(' Failed to retrieve your organisations pending invitations > error = ', error);
    });
  }

  protected subscribeToOplog(): void {
    this.invitationsService.initialisePendingInvitationsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.setupListConfig(this.invitationsService._model(response.invitations));
      });
      this.oplog.on('update', (response) => {
        this.setupListConfig(this.invitationsService._model(response.invitations));
      });
    });
  }

  protected setupListConfig(invitations: InvitationModel[]): void {
    super.setupListConfig(invitations);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .enableAdd()
    .enableDelete()
    .items(invitations)
    .icon('icon')
    .uniqueIdentifier('invitationId')
    .displayId('email')
    .description('role')
    .withProportion('medium')
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected showAddItem(): void {
    super.showAddItem();
    this.router.navigate('invite');
  }

  protected deleteItems(items: InvitationModel[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} invitations deleted.` : `Invitation for <b>${items[0].email}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(item: InvitationModel): void {
    super.delete(item);
    this.invitationsService.revokeInvitation(item);
  }
}