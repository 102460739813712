import { bindable, customElement, LogManager } from "aurelia-framework";
import { Event } from "zailab.common";

const logger = LogManager.getLogger('AgentScriptCCStep');

@customElement('z-agent-script-cc-step')
export class AgentScriptCCStep {

  @bindable
  public name: string;

  @bindable
  public text: string;

  @bindable
  public option: string;
  
  @bindable
  public height: number = 168;

  constructor(
    private element: Element,
  ) { }

  public showHistory(): void {
    new Event(this.element, 'show-history', {});
  }

  public showCurrentStep(): void {
    new Event(this.element, 'show-current-step', {});
  }
}
