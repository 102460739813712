import { ZaiForm } from 'zailab.common';

export interface ITab1GeneralConfiguration {
  id: string;
  name: string;
  description: string;
  message: string;
  severity: string;
  repeatIntervalEnabled: boolean;
  repeatTimeout: string;
  valid?: boolean;
}

export class Tab1GeneralConfiguration {
  public form: any[][];
  public formData: { name: string; valid: boolean };
  private model: { data: ITab1GeneralConfiguration };

  public activate(bindingContext: { data: ITab1GeneralConfiguration }) {
    this.model = bindingContext;

    this.generateForm();
  }

  private generateForm(): void {
    this.form = null;

    setTimeout(() => {
      let formGenerator = new ZaiForm()
        .newField()
        .asTextInput()
        .fullWidth()
        .withFocus()
        .withTitle('Name', '160px')
        .withPlaceholder('Enter name here.')
        .withIdentifier('name')
        .withValue(this.model.data.name)
        .withValidation([
          { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN },
          {
            validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
            value: 50,
          },
        ])
        .insertField()

        .newRow()
        .newField()
        .asTextInput()
        .fullWidth()
        .withTitle('Description', '160px')
        .withPlaceholder('Enter description here.')
        .withIdentifier('description')
        .withValue(this.model.data.description)
        .withValidation([
          { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN },
          {
            validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
            value: 50,
          },
        ])
        .insertField()

        .newRow()
        .newField()
        .asTextInput()
        .fullWidth()
        .withTitle('Message', '160px')
        .withPlaceholder('Enter message here.')
        .withIdentifier('message')
        .withValue(this.model.data.message)
        .withValidation([
          { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          {
            validationType:
              ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN_ALLOW_CUSTOM_VAR,
          },
          {
            validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH,
            value: 200,
          },
        ])
        .insertField()

        .newRow()
        .newField()
        .asDropdown()
        .fullWidth()
        .withTitle('Severity', '160px')
        .withOptions(['Critical', 'Informational', 'Warning'])
        .withIdentifier('severity')
        .withValue(this.model.data.severity)
        .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
        .openToTop()
        .insertField()

        .newRow()
        .newField()
        .asSliderToggle()
        .withIdentifier('repeatIntervalEnabled')
        .withTitle('Repeat Configuration', '160px')
        .addNegativeOption('Disabled')
        .addPositiveOption('Enabled')
        .withValue(this.model.data.repeatIntervalEnabled)
        .insertField();

      if (this.model.data.repeatIntervalEnabled) {
        formGenerator = formGenerator
          .newRow()
          .newField()
          .asDropdown()
          .fullWidth()
          .withTitle('Repeat Timeout', '160px')
          .withOptions(['5 min', '10 min', '15 min', '20 min'])
          .openToTop()
          .withIdentifier('repeatTimeout')
          .withValue(this.model.data.repeatTimeout)
          .withValidation([
            { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
          ])
          .insertField();
      }

      formGenerator.finaliseForm((form) => {
        this.form = form;
      });
    }, 5);
  }

  public async formDataChanged(data: ITab1GeneralConfiguration): Promise<void> {
    if (!data) {
      return;
    }
    let forceReload;

    if (data.repeatIntervalEnabled !== this.model.data.repeatIntervalEnabled) {
      forceReload = true;
    }

    this.model.data.name = data.name;
    this.model.data.description = data.description;
    this.model.data.message = data.message;
    this.model.data.severity = data.severity;
    this.model.data.repeatIntervalEnabled = data.repeatIntervalEnabled;
    this.model.data.repeatTimeout = data.repeatTimeout;
    this.model.data.valid =
      data.severity &&
      data.valid &&
      (!data.repeatIntervalEnabled ||
        (data.repeatIntervalEnabled && !!data.repeatTimeout));

    if (forceReload) {
      this.generateForm();
    }
  }
}
