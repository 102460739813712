import { DashboardWidget } from './../../dashboard/dashboard-abstract';
  import {autoinject, LogManager} from 'aurelia-framework';
  import { Router } from 'aurelia-router';
  
  const logger = LogManager.getLogger('PostInteractionProcessingWidget');
  
  @autoinject
  export class PostInteractionProcessingWidget extends DashboardWidget {
  
    constructor(public router: Router) {
      super();
    }
  
    public view(): void {
      this.router.navigate('post-interaction-processing');
    }
  }
  