import { QUILL_IMAGE_UPLOAD_CONSTANTS } from '../../../../../../components/atoms/wysiwyg/quill-plugin-image-upload/quill-image-upload-constants';

export class EmailImage {
  public id: string;
  public url: string;
  public size: number;
  public name: string;
  public maxImageSize: number = QUILL_IMAGE_UPLOAD_CONSTANTS.MAX_IMAGE_SIZE;
  public maxImageSizeMessage: string = QUILL_IMAGE_UPLOAD_CONSTANTS.MAX_IMAGE_SIZE_MESSAGE;

  constructor(id: string, name: string, size: number) {
    this.id = id;
    this.name = name;
    this.size = size;
  }

  isPresent(): boolean {
    let element = document.getElementById(this.id);
    return element ? true : false;
  }

  exceedsImageFileSize(): boolean {
    return this.size > this.maxImageSize;
  }
}