import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { AudioAddService, BootstrapFormRenderer } from 'zailab.common';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';
import { v4 as uuidv4 } from 'uuid';
import { AudioGroupService } from '../group/audio-group-service';
/**/
const logger = LogManager.getLogger('AddAudioDialog');
const MEDIA_TYPES = { AUDIO: 'audio', TTS: 'tts' };

/**/
@autoinject
export class AddAudioDialog {

  public dialogHeader: string = 'Add Audio File';
  public isInterruptible: boolean = false;
  public audioReadableName: string = '';

  private uploadAudioPayload: ZIUploadAudioTabPayload = null;
  private ttsAudioPayload: ZITtsAudioTabPayload = null;
  private isValid: boolean = false;
  private isProcessing: boolean = false;
  private audioType: string = '';
  private audioId: string = '';
  private audioList: any[];
  private audioGroupList: any[];
  private selectedGroupId: string = '';
  public audioVariableName: string;
  public showVariableName: boolean = false;
  private subscription;

  constructor(
    private dialogController: DialogController,
    private audioService: AudioAddService,
    private audioGroupService: AudioGroupService,
    private validationController: ValidationController,
    private eventAggregator: EventAggregator,
    validationControllerFactory: ValidationControllerFactory,
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.validationController.validateTrigger = validateTrigger.change;
    this.initValidation();
  }

  public activate(model: { audioList: any[], audioGroupList: any[], selectedGroupId: string }): void {
    if (model) {
      if (model.audioGroupList) {
        this.audioGroupList = model.audioGroupList;
      } else {
        this.audioGroupService.getAudioGroups().then((groups) => this.audioGroupList = groups);
      }
      if (model.selectedGroupId) {
        this.selectedGroupId = model.selectedGroupId;
      }
    } else {
      this.audioGroupService.getAudioGroups().then((groups) => this.audioGroupList = groups);
    }
    this.audioService.getAudioFiles().then((audioList) => this.audioList = audioList);
    this.subscription = this.eventAggregator.subscribe('add-audio:retrieve-name', () => {
      this.validationController.validate().then(_result => {
        if (!_result.valid) {
          this.eventAggregator.publish('add-audio:name-retrieved', '');
        } else {
          this.eventAggregator.publish('add-audio:name-retrieved', this.audioReadableName);
        }
      });
    });
  }

  private initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        if (this.audioList.length > 0) {
          for (let audio of this.audioList) {
            if (audio.filename.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      }, 'Please enter a unique name.');

    ValidationRules
      .ensure('audioReadableName')
      .required()
      .withMessage('Please specify a name for your audio file.')
      .satisfiesRule('uniqueName')
      .on(this);
  }

  public handleAudioPayload(_payload: ZIUploadAudioTabPayload): void {
    this.uploadAudioPayload = _payload;
    this.audioType = MEDIA_TYPES.AUDIO;
    this.isValid = true;
  }

  public handleTtsPayload(_payload: ZITtsAudioTabPayload): void {
    this.ttsAudioPayload = _payload;
    this.audioType = MEDIA_TYPES.TTS;
    this.isValid = true;
  }

  public invalidateDialog(): void {
    this.isValid = false;
  }

  public cancelAudioDialog = () => {
    if (!this.isProcessing) {
      this.dialogController.cancel();
    }
  }

  public saveNode = (): void => {
    this.validationController.validate().then(_result => {

      let audioName: string = this.audioReadableName;

      if (!_result.valid || !this.isValid) {
        return;
      }

      if (this.audioType === MEDIA_TYPES.AUDIO) {
        this.uploadAudio(audioName).then(_callback => this.handleAudioCreated(_callback));
        return;
      }

      if (!this.ttsAudioPayload.ttsFileCreated) {
        this.uploadTTS(audioName).then(_callback => this.handleAudioCreated(_callback));
      } else {
        this.handleAudioCreated({});
      }
    });
  };

  private handleAudioCreated(_result: any): void {

    const payload = this.packagePayload();
    const audioId = payload.audioMessages.audioId;
    const selectedGroupId = payload.audioMessages.selectedGroupId;

    if (this.uploadAudioPayload && this.uploadAudioPayload.otherAudio && this.uploadAudioPayload.otherAudio.length) {
      this.audioService.putLingualAudioFile(this.uploadAudioPayload.audioId, this.uploadAudioPayload.audioId, this.uploadAudioPayload.audioLanguageId, this.audioVariableName, this.uploadAudioPayload.defaultSelection);
      for (const otherAudio of this.uploadAudioPayload.otherAudio) {
        const audioId = otherAudio.audioId;
        const language = otherAudio.audioLanguageId;
        const defaultSelection = otherAudio.defaultSelection;
        this.audioService.putLingualAudioFile(audioId, this.uploadAudioPayload.audioId, language, this.audioVariableName, defaultSelection);
      }
    }

    this.isProcessing = false;

    if (selectedGroupId && selectedGroupId !== 'No Group') {
      this.linkGroup({ id: audioId }, selectedGroupId).then(() => this.dialogController.ok(payload));
    } else {
      this.dialogController.ok(payload);
    }
  }

  private uploadAudio(audioName: string): Promise<any> {

    this.isProcessing = true;

    const promises = [this.audioService.postAudioFile(this.uploadAudioPayload, this.uploadAudioPayload.audioId)];
    for (const otherAudio of this.uploadAudioPayload.otherAudio) {
      promises.push(this.audioService.postLingualAudioFile(otherAudio, otherAudio.audioId));
    }

    return Promise.all(promises);
  }

  private uploadTTS(audioName: string): Promise<any> {
    this.isProcessing = true;

    let dto: any = {
      description: audioName,
      speechLanguage: this.ttsAudioPayload !== null ? this.ttsAudioPayload.speechLanguage : null,
      textMessage: this.ttsAudioPayload !== null ? this.ttsAudioPayload.textMessage : null,
      speechRate: this.ttsAudioPayload !== null ? this.ttsAudioPayload.speechRate : null,
    };

    this.audioId = uuidv4();

    return this.audioService.postTts(dto, this.audioId);
  }

  private packagePayload(): ZIAudioPayload {
    return {
      description: this.audioReadableName,
      speechLanguage: this.ttsAudioPayload !== null ? this.ttsAudioPayload.speechLanguage : null,
      textMessage: this.ttsAudioPayload !== null ? this.ttsAudioPayload.textMessage : null,
      speechRate: this.ttsAudioPayload !== null ? this.ttsAudioPayload.speechRate : null,
      name: this.audioReadableName,
      interruptible: null, // Interruptible will be set on a per-node basis
      audioMessages: {
        description: this.audioReadableName,
        audioId: this.ttsAudioPayload && this.ttsAudioPayload.audioId ? this.ttsAudioPayload.audioId : (this.uploadAudioPayload ? this.uploadAudioPayload.audioId : this.audioId),
        selectedGroupId: this.selectedGroupId
      }
    }
  }

  public detached(): void {
    this.subscription && this.subscription.dispose();
  }

  private linkGroup(item: any, audioGroupId: any): Promise<any> {
    return this.audioGroupService.allocateAudioGroup(audioGroupId, item.id);
  }
}

