import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('Webhooks');

@autoinject()
export class ViewCampaignAuditDialog {
  public dialogHeader: string = 'Audit Data';
  public size: string;
  public data: { key: string; value: string }[] = [];

  constructor(private dialogController: DialogController) {}

  public activate(data: IInteractionFlowAuditData): void {
    if (data && typeof data === 'object') {
      if (data.size) {
        this.size = data.size;
        delete data.size;
      }
      this.data = this.convertToArray(data);
    }
  }

  private convertToArray(data: any): { key: string; value: string }[] {
    if (!data && data !== '0') {
      return null;
    }
    if (this.isArray(data)) {
      return data;
    }
    let array = [];
    Object.keys(data).forEach((key) => {
      const item = {
        variableName: key,
        displayName:
          typeof data[key] === 'object' && !this.isArray(data[key])
            ? this.convertToArray(data[key])
            : this.isArray(data[key])
            ? data[key].map((item) =>
                typeof item === 'string' ? item : this.convertToArray(item)
              )
            : data[key],
      };
      if (key === 'Name') {
        array.unshift(item);
      } else {
        array.push(item);
      }
    });
    return array;
  }

  public hasSubItems(value: any): boolean {
    return typeof value === 'object' || this.isArray(value);
  }

  public confirm(): void {
    this.dialogController.ok();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public isObject(value: string | object): boolean {
    return typeof value === 'object';
  }

  public isString(value: string | object): boolean {
    return typeof value === 'string';
  }

  public isArray(value: string | object, num?): boolean {
    return Array.isArray(value);
  }
}
