import { ConfirmDialog } from './../../../conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';
import { computedFrom } from 'aurelia-binding';
//@ts-ignore
import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';

import { SessionStore } from 'zailab.common';
import { PostInteractionProcessingService } from '../post-interaction-processing-service';
import { OrganisationSessionModel } from './../../../../../_common/stores/sessionmodels/organisation-session-model';
//@ts-ignore
import toastr from 'toastr';

const logger = LogManager.getLogger('EditPostInteractionProcessor');

interface IDispositionPayload {
  name?: string;
  dispositionCodeId: string;
  dispositionCodeIds: string,
  category: string;
  subCategory: {
    name: string;
    dispositionCodeIds: string[]
  };
};

interface IDisposition {
  category: string,
  subCategory: string,
  dispositionCodeIds: string[]
}

interface ITab {
  id: string;
  name: string;
  viewModel: string;
  icon: string;
  data: {
    // Filters
    interactionPostProcessorId?: string
    selectedInteractionType?: string
    selectedInteractionOutcomes?: string[],
    selectedWorktypes?: {
      templateId: string
    }[],
    selectedDispositions?: IDispositionPayload[],

    // Delay
    delay?: number;

    // Actions
    assignedActions?: any[],
    temporaryAction?: any,

    // used for remapping data
    workTypes?: string[]
    dispositions?: IDisposition[],
    actions?: any[] // todo: add typings
    filterVariables?: any[] // todo: add typings
  }
}

@autoinject()
export class EditPostInteractionProcessor {

  public dialogHeader: string = 'View/Edit interaction Processor';
  public tabs: ITab[] = [
    { id: '1', name: 'Filters', viewModel: 'features/organisation/organisation/post-interaction-processing/add/add-steps/step1-filters/post-interaction-processing-filters', icon: 'filter', data: {} },
    { id: '2', name: 'Delay',   viewModel: 'features/organisation/organisation/post-interaction-processing/add/add-steps/step2-delay/post-interaction-processing-delay',     icon: 'timer', data: {} },
    { id: '3', name: 'Actions', viewModel: 'features/organisation/organisation/post-interaction-processing/add/add-steps/step3-actions/post-interaction-processing-actions', icon: 'cog', data: {} }
  ];
  public name: string = '';
  private postinteractionProcessors: any[]; // todo: implement correct typing
  private interactionPostProcessorId: string;

  constructor(
    private dialogController: DialogController,
    private postInteractionProcessingService: PostInteractionProcessingService,
    private sessionStore: SessionStore,
    private dialogService: DialogService
  ) {}

  public activate(data: { postInteractionProcessor: any, postinteractionProcessors: any[] }): void {
    this.postinteractionProcessors = data.postinteractionProcessors;
    this.loadData(data.postInteractionProcessor);
  }

  private loadData(postInteractionProcessor: any): void {
    let filterTab = this.tabs.find(tab => tab.name === 'Filters');
    let delayTab = this.tabs.find(tab => tab.name === 'Delay');
    let actionTab = this.tabs.find(tab => tab.name === 'Actions');

    this.interactionPostProcessorId = postInteractionProcessor.interactionPostProcessorId;
    this.name = postInteractionProcessor.name;

    filterTab.data.interactionPostProcessorId = this.interactionPostProcessorId;
    filterTab.data.selectedInteractionType = postInteractionProcessor.interactionType;
    filterTab.data.selectedInteractionOutcomes = postInteractionProcessor.outcomes;
    filterTab.data.workTypes = postInteractionProcessor.workTypes;
    filterTab.data.dispositions = postInteractionProcessor.dispositions;
    filterTab.data.filterVariables = postInteractionProcessor.filterVariables;

    delayTab.data.interactionPostProcessorId = this.interactionPostProcessorId;
    delayTab.data.delay = postInteractionProcessor.delay;

    actionTab.data.interactionPostProcessorId = this.interactionPostProcessorId;
    actionTab.data.actions = postInteractionProcessor.actions;
  }

  public updateOutcomes(data: { outcomes: any[] }): void {
    this.postInteractionProcessingService
      .setInteractionPostProsessorOutcomes(this.organisationId, this.interactionPostProcessorId, data.outcomes);
  }

  public updateWorktypes(data: { workTypes: any[] }): void {
    const workTypes = data.workTypes.map(wt => wt.templateId);
    this.postInteractionProcessingService
      .setInteractionPostProsessorWorktypes(this.organisationId, this.interactionPostProcessorId, workTypes);
  }

  public updateDispositions(data: { dispositions: any[] }): void {
    const dispositions = data.dispositions.map(d => {
      if (d.name === 'All' || d.name === 'NA') {
        return {
          description: d.name
        };
      } else if (d.name) {
        return {
          dispositionCodes: [{
            ids: d.dispositionCodeIds,
            name: d.name
          }]
        };
      } else if (d.category) {
        return {
          dispositionCodes: [{
            ids: d.subCategory.dispositionCodeIds || d.dispositionCodeIds
          }],
          category: d.category,
          subCategory: d.subCategory.name
        };
      }
    });
    this.postInteractionProcessingService
      .setInteractionPostProsessorDispositions(this.organisationId, this.
        interactionPostProcessorId, dispositions);
  }

  public updateDelay(data: { delay: number }): void {
    this.postInteractionProcessingService
      .setInteractionPostProsessorDelay(this.organisationId, this.interactionPostProcessorId, data.delay);
  }

  public addAction(data: { action: any }): void {
    let mappedAction = JSON.parse(JSON.stringify(data.action));

    mappedAction.formData = mappedAction.formData ? JSON.parse(JSON.stringify(mappedAction.formData)) : {};
    mappedAction.outputVariables = mappedAction.outputVariables ? JSON.parse(JSON.stringify(mappedAction.outputVariables)) : {};
    mappedAction.requestHeaders = mappedAction.requestHeaders ? JSON.parse(JSON.stringify(mappedAction.requestHeaders)) : {};

    if (mappedAction.formData && Array.isArray(mappedAction.formData)) {
      let mappedFormData = {};
      mappedAction.formData.forEach(data => {
        
        let keys = Object.keys(data);
        keys.forEach(key => {
          mappedFormData[key] = [mappedFormData[key]]
        });
        return mappedFormData;
      });
      mappedAction.formData = mappedFormData;
    }
    
    if (mappedAction.outputVariables && Array.isArray(mappedAction.outputVariables)) {
      let mappedOutputVariables = {};
      mappedAction.outputVariables.map(data => mappedOutputVariables = { ...mappedOutputVariables, ...data });
      mappedAction.outputVariables = mappedOutputVariables;
    }

    if (mappedAction.requestHeaders && Array.isArray(mappedAction.requestHeaders)) {
      let mappedRequestHeaders = {};
      mappedAction.requestHeaders.forEach(data => mappedRequestHeaders = { ...mappedRequestHeaders, ...data });
      mappedAction.requestHeaders = mappedRequestHeaders;
    }
    this.postInteractionProcessingService
      .addInteractionPostProsessorAction(this.organisationId, this.interactionPostProcessorId, mappedAction);
  }

  public changeActionPriorities(data: { actions : any[] }): void {
    this.postInteractionProcessingService
      .changeInteractionPostProsessorActionPriorities(this.organisationId, this.interactionPostProcessorId, data.actions);
  }

  public removeAction(data: { action: any }): void {
    this.postInteractionProcessingService
      .removeInteractionPostProsessorAction(this.organisationId, this.interactionPostProcessorId, data.action.name);
  }

  public setInteractionPostProcessorFilterVariables(data: {filterVariables: any[]}): void {
    this.postInteractionProcessingService
      .setInteractionPostProcessorFilterVariables(this.organisationId, this.interactionPostProcessorId, data.filterVariables);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public create(): void {
    let actionTab = this.tabs.find(tab => tab.name === 'Actions');
    if (actionTab.data.temporaryAction) {
      this.dialogService
        .open({
          viewModel: ConfirmDialog,
          model: {
            message: 'You have an unsaved action. Selecting yes will discard this action.'
          }
        })
        .whenClosed(dialog => {
          if (!dialog.wasCancelled) {
            delete actionTab.data.temporaryAction;
            this.done();
          }
        });
    } else {
      this.done();
    }
  }

  public done(): void {
    const filterTab = this.tabs.find(tab => tab.name === 'Filters');
    const delayTab = this.tabs.find(tab => tab.name === 'Delay');
    const actionTab = this.tabs.find(tab => tab.name === 'Actions');

    if (filterTab.data.selectedInteractionOutcomes.length === 0) {
      return toastr.error(`Please select at least 1 outcome.`);
    }

    if (filterTab.data.selectedWorktypes.length === 0) {
      return toastr.error(`Please select at least 1 worktype.`);
    }

    let actions = actionTab.data.actions || actionTab.data.assignedActions;
    if (actions.length === 0) {
      return toastr.error(`Please create at least 1 action.`);
    }

    let payload = {
      interactionPostProcessorId: filterTab.data.interactionPostProcessorId,
      name: this.name,
      interactionType: filterTab.data.selectedInteractionType,
      outcomes: filterTab.data.selectedInteractionOutcomes,
      workTypes: filterTab.data.selectedWorktypes.map(wt => wt.templateId),
      filterVariables: filterTab.data.filterVariables,
      dispositions: filterTab.data.selectedDispositions.map(d => {

        if (d.name === 'All' || d.name === 'NA') {
          return {
            description: d.name
          };
        } else if (d.name) {
          return {
            dispositionCodes: [{
              ids: d.dispositionCodeIds,
              name: d.name
            }]
          };
        } else if (d.category) {
          return {
            dispositionCodes: [{
              ids: d.subCategory.dispositionCodeIds
            }],
            category: d.category,
            subCategory: d.subCategory.name
          };
        }
      }),
      delay: delayTab.data.delay,
      actions: actions.map(aa => {
        aa.formData = aa.formData ? JSON.parse(JSON.stringify(aa.formData)) : {};
        aa.outputVariables = aa.outputVariables ? JSON.parse(JSON.stringify(aa.outputVariables)) : {};
        aa.requestHeaders = aa.requestHeaders ? JSON.parse(JSON.stringify(aa.requestHeaders)) : {};

        if (aa.formData && Array.isArray(aa.formData)) {
          let mappedFormData = {};
          aa.formData.forEach(data => {
            
            let keys = Object.keys(data);
            keys.forEach(key => {
              mappedFormData[key] = [mappedFormData[key]]
            });
            return mappedFormData;
          });
          aa.formData = mappedFormData;
        }
        
        if (aa.outputVariables && Array.isArray(aa.outputVariables)) {
          let mappedOutputVariables = {};
          aa.outputVariables.map(data => mappedOutputVariables = { ...mappedOutputVariables, ...data });
          aa.outputVariables = mappedOutputVariables;
        }

        if (aa.requestHeaders && Array.isArray(aa.requestHeaders)) {
          let mappedRequestHeaders = {};
          aa.requestHeaders.forEach(data => mappedRequestHeaders = { ...mappedRequestHeaders, ...data });
          aa.requestHeaders = mappedRequestHeaders;
        }
        return aa;
      })
    };

    this.dialogController.ok(payload);
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if(!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}
