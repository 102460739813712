export class FilterByPropertyValueConverter {
  public toView(items: any, searchProp: string, requiredSearchParameter: string, optionalSearchParameter: string): any {
    if (requiredSearchParameter === '' || !requiredSearchParameter || !items || !searchProp) {
      return items;
    }

    if (optionalSearchParameter) {
      return items.filter((item) => item[searchProp].toLowerCase().includes(requiredSearchParameter) || item[searchProp].toLowerCase().includes(optionalSearchParameter));
    } else {
      return items.filter((item) => item[searchProp].toLowerCase().includes(requiredSearchParameter));
    }
  }
}
