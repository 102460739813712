import {LogManager, computedFrom} from 'aurelia-framework';

const logger = LogManager.getLogger('RecordingModel');


export class RecordingModel {

  public recordingURL: string = '';
  public isPlaying: boolean = false;
  public playTime: number = 0;
  public audioLength: number = 0;
  public loader: boolean;
  public error: boolean = false;

  constructor() {
  }

  public play(): void {
    this.isPlaying = true;
  }

  public pause(): void {
    this.isPlaying = false;
  }

  public toggle(): void {
    this.isPlaying = !this.isPlaying;
  }

  @computedFrom('recordingURL', 'error', 'loader')
  get canPlay(): boolean {

    if (this.recordingURL && !this.error && !this.loader) {
      return true;
    } else {
      return false;
    }

  }
}