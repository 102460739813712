import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { List } from '../../../../abstract/list/list-builder';
import { BypassCos } from '../bypass-cos-model';
import { BypassCosService } from '../bypass-cos-service';

const logger = LogManager.getLogger('BypassCosList');

@autoinject()
export class BypassCosList {

  private itemList: List;
  private list: BypassCos[];

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator,
    private bypassCosService: BypassCosService,
  ) { }

  public attached(): void {
    this.retrieveList();
  }

  private retrieveList(): void {
    this.eventAggregator.publish('app:loader:show');
    this.bypassCosService.findAll()
      .then((list) => {
        this.list = list;
        this.setupListConfig();
      })
      .catch(error => this.handleFailure(error));
  }

  private setupListConfig(): void {
    this.itemList = List.Builder()
      .items(this.list)
      .icon('icon')
      .displayId('name')
      .description('description')
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .build();
    this.itemList.select = true;
    this.itemList.autoSelect = false;
    this.eventAggregator.publish('app:loader:hide');
  }

  private handleFailure(error: any): void {
    logger.error('retrieve list > error = ', error);
  }

  public add(): void {
    this.router.navigate('add');
  }

  public select(event: any): void {
    this.router.navigate(`edit/${event.detail.item.id}`);
  }

  public delete(event: any): void {
    let deletesLeft: number = event.detail.length;
    event.detail.forEach((item: BypassCos) => {
      this.bypassCosService.delete(item.id)
        .then(() => this.handleDeleted(item, --deletesLeft))
        .catch((error) => logger.error(`delete :: ${item.id} :: error=`, error));
    });
  }

  private handleDeleted(item: BypassCos, deletesLeft: number): void {
    this.list.splice(this.list.indexOf(item), 1);
    if (deletesLeft === 0) {
      this.setupListConfig();
    }
  }
}