import {inject, computedFrom, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('RoleModel');
/*
 * */
export class RoleModel extends BaseModel {

  roleValue = null;
  roleName = null;

  constructor(role) {
    super();
    this.mapProperties(role);
    this._setRoleName();
  }

  _setRoleName(){
    switch (this.roleValue) {
      case 'ADMINISTRATOR':
        this.roleName = 'Administrator';
        break;
      case 'AGENT':
        this.roleName = 'Agent';
        break;
      case 'OFFICE_EMPLOYEE':
        this.roleName = 'Office Employee';
        break;
      case 'QA':
        this.roleName = 'QA';
        break;
      case 'QA_MANAGER':
        this.roleName = 'QA Manager';
        break;
      case 'CAMPAIGN_MANAGER':
        this.roleName = 'Campaign Manager';
        break;
      case 'TEAM_LEADER':
        this.roleName = 'Team Leader';
        break;
      default:
        this.roleName = '';
    }
  }

  @computedFrom('roleName')
  get icon() {
    return this.roleName.replace(/\s+/g, '-').toLowerCase();
  }
}