import {LogManager, inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {BindingSignaler} from "aurelia-templating-resources";
/**/
import {CUSTOM_EVENTS, BootstrapFormRenderer, Event} from 'zailab.common';
/**/
import moment from 'moment';
/**/
const logger = LogManager.getLogger('ApplyToDatesController');

/**/
@inject(DialogController, Element, BindingSignaler)

export class ApplyToDate {
  private tabData: ZTabData;
  private dateRange: Array<ZDayTimeExpanded> = [];

  constructor(private dialogController: DialogController, private element: Element, private updateTimeSignal: BindingSignaler) {

  }

  private meridiem(hour: number): string {
    return hour < 12 ? 'AM' : 'PM'
  }

  public activate(tabData: ZTabData): void {
    this.setupStateData(tabData);
    this.setupDaysOfWeek();
    this.checkComplete();
  }

  public attached(): void {
  }

  private setupStateData(tabData: ZTabData): void {
    this.tabData = tabData;
  }

  private setupDaysOfWeek(): void {
    if (this.tabData.data && this.tabData.data["customTimeRouteDates"] && this.tabData.data.type === "dateRange") {
      let _rangeMap: Array<any> = this.tabData.data["customTimeRouteDates"] as Array<any>;
      _rangeMap.map(_day => {
        let _customTimeRouteDate: ZDayOfWeek = _day.customTimeRouteDate;
        let _momentRaw = moment(_customTimeRouteDate.date);
        let _dayObject: ZDayTimeExpanded = {
          date: _customTimeRouteDate.date,
          readableDate: _momentRaw.format('Do MMMM YYYY'),
          startingHour: parseInt(_customTimeRouteDate.startTime.substr(0, 2)),
          endingHour: parseInt(_customTimeRouteDate.endTime.substr(0, 2)),
          startingMinute: parseInt(_customTimeRouteDate.startTime.substr(2, 2)),
          endingMinute: parseInt(_customTimeRouteDate.endTime.substr(2, 2)),
          allDay: _customTimeRouteDate.allDay,
          errors: []
        };
        this.dateRange.push(_dayObject);
      });
    }
  }

  private passesValidation(_day: ZDayTimeExpanded): boolean {
    _day.errors = [];
    // Add extra validation here
    if (_day.startingHour > _day.endingHour) {
      _day.errors.push("Start time cannot be after end time");
    }
    return _day.errors.length === 0;
  }

  private updateTime(): void {
    this.updateTimeSignal.signal('update-time-signal');
  }

  private checkComplete(): void {
    if (!!this.dateRange.length) {
      this.signalComplete();
    } else {
      this.signalIncomplete();
    }
  }

  private signalComplete(): void {
    this.element.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TAB_COMPLETE, {
      bubbles: true,
      detail: this.packagePayload()
    }));
  }

  private signalIncomplete(): void {
    this.element.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TAB_INCOMPLETE, {
      bubbles: true,
      detail: {}
    }));
  }

  private convertTimeToString(_day: object, _type: string): string {
    return ('00' + _day[_type + "ingHour"]).slice(-2) + ('00' + _day[_type + "ingMinute"]).slice(-2) + "00"
  }

  private packagePayload(): ZCustomTimeRoutesPayload {
    return {
      name: "dateRangeTest",
      type: "dateRange",
      customTimeRouteDates: this.dateRange.map(_day => {
        let _dayObject: ZRangeOfDates = {
          allDay: _day.allDay,
          date: _day.date,
          startTime: this.convertTimeToString(_day, "start"),
          endTime: this.convertTimeToString(_day, "end")
        };
        return {customTimeRouteDate: _dayObject};
      })
    };
  }

  /* METHODS CALLED BY VIEW */

  private handleDatePicked(dateEvent: Event): void {
    let momentRaw = moment(dateEvent.target.value);
    this.dateRange.push({
      date: momentRaw.format('YYYYMMDD'),
      readableDate: momentRaw.format('Do MMMM YYYY'),
      startingHour: 9,
      endingHour: 17,
      startingMinute: 0,
      endingMinute: 0,
      allDay: true,
      isEditing: false
    });
    this.checkComplete();
    dateEvent.target.value = "";
  }

  private confirmHours(_day: ZDayTimeExpanded): void {
    if (this.passesValidation(_day) === true) {
      _day.isEditing = false;
      this.updateTime();
      this.signalComplete();
    }
  }

  private toggleAllDay(_day: ZDayTimeExpanded): void {
    _day.allDay = !_day.allDay;
    this.signalComplete();
  }

  private editHours(_day: ZDayTimeExpanded): void {
    _day.isEditing = true;
  }

  private removeDate(_index: number): void {
    this.dateRange.splice(_index, 1);
    this.checkComplete();
  }
}
