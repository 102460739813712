import {LogManager, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/**
 */
import {SessionStore} from 'zailab.common';
import {InteractionsService} from '../interaction-flow-service';
import InteractionFlowStatsModel from '../interaction-flow-stats-model';

/**
 */
const logger = LogManager.getLogger('Work Flows Widget');

/**
 */
@inject(Router, InteractionsService, SessionStore)
export class WorkFlowsDashboardWidget {

  actions = ['view', 'add'];
  inbound = 0;
  outbound = 0;
  email = 0;
  sms = 0;
  ticket = 0;
  chat = 0;
  kiosk = 0;
  im = 0;
  stats = {};
  hasChat = false;
  hasIM = false;
  ready = false;

  constructor(router, interactionsService, sessionStore) {
    this.router = router;
    this.interactionsService = interactionsService;
    this.sessionStore = sessionStore;
  }

  attached() {
    this.interactionsService.initialiseStatsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.stats = response.interactionFlows;
      });
      this.oplog.on('update', (response) => {
        for (let index in response) {
          if (response.hasOwnProperty(index) && index.includes('interactionFlows')) {
            let key = (index.split('.')[1]).toLowerCase().split(' ')[0];
            this[key] = response[index].length;
            return;
          }
        }
        this.stats = new InteractionFlowStatsModel(response);
      });
    });
  }

  detached() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }

  activate(model) {
    this.retrieveWorkFlowsStats();
  }

  retrieveWorkFlowsStats() {

    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.retrieveWorkFlowsStats();
      }, 500);
      return;
    }

    this.interactionsService.retrieveWorkFlowsStats().then((response) => {
      this._mapModel(response);
      this.ready = true;
    }, (error) => {
      logger.info('Could not retrieve Work Flows Stats.', error);
    });

  }

  _mapModel(response) {
    this.inbound = response.inboundCall;
    this.outbound = response.outboundCall;
    this.email = response.email;
    this.sms = response.sms;
    this.ticket = response.ticket;
    this.chat = response.chat;
    this.kiosk = response.kiosk;
    this.im = response.im;
    this.hasChat = this.chat > 0;
    this.hasIM = this.im > 0;
  }

  view() {
    this.router.navigate('interactionflows');
  }

  add() {
    this.router.navigate('interactionflows/create');
  }

}