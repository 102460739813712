import { inject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { SitesService } from '../sites-service';
import { AddSiteDialog } from '../addsite/add-site-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { DisplaySitesModel } from '../site-model';

let logger = LogManager.getLogger('SitesListView');

@inject(Element, DialogService, SessionStore, SitesService, EventAggregator)
export class Sites extends AbstractList {

  private dataLoadedCallback: (items: any[]) => void;

  constructor(private element: Element, private dialogService: DialogService, private sessionStore: SessionStore, private sitesService: SitesService, protected eventAggregator: EventAggregator) {
    super(eventAggregator);
  }

  public activate(params: any): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected subscribeToOplog(): void {
    this.sitesService.subscribeToOplogBasedOnCriteria({
      key: '_id',
      value: this.sessionStore.get.organisation.organisationId,
      location: 'organisation-projector.displaySitesView'
    }).then(_oplog => {
      this.oplog = _oplog;
      this.oplog.on('update', (response) => {
        this.eventAggregator.publish('app:loader:show');
        this.setupListConfig(this.sanitiseSiteData(response.sites));
      });
      this.oplog.on('insert', (response) => {
        this.eventAggregator.publish('app:loader:show');
        this.setupListConfig(this.sanitiseSiteData(response.sites));
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.sitesService.retrieveSites()
      .then((_response) => {
        this.setupListConfig(this.sanitiseSiteData(_response));
        this.dataLoadedCallback && this.dataLoadedCallback(_response);
        if (this.isAdding) {
          this.clearUrlParams();
          this.showAddItem();
        }
      }).catch((error) => {
        logger.warn(' Failed to retrieve your organisation sites > error = ', error);
        this.hideLoader();
      });
  }

  protected setupListConfig(sites: DisplaySitesModel[]): void {
    this._log('setupListConfig :: this.itemList=', this.itemList);
    super.setupListConfig(sites);
    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .items(sites)
      .icon('icon')
      .uniqueIdentifier('id')
      .displayId('name')
      .description('')
      .withProportion('medium')
      .hasRollover(true)
      .tilesPerRow(6)
      .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected createMetaData(_items: any[]): void {
    _items.forEach(_item => {
      _item.icon = 'sites';
      _item.description = `<b>Members: </b>${_item.memberCount}`;
      if (_items.length === 1) {
        _item.canDelete = false;
      }
    });
  }

  protected showAddItem(): void {
    this._log('showAddItem :: this.itemList=', this.itemList);
    super.showAddItem();
    this.dialogService.open({ viewModel: AddSiteDialog, model: this.itemList.items }).whenClosed(_response => {
      if (_response.wasCancelled) {
        this.hideLoader();
      } else {
        this.deferredMessage = `Site <b>${_response['output']}</b> added.`;
        this.sitesService.addSite(this.sessionStore.get.organisation.organisationId, _response['output']);
      }
    });
  }

  protected deleteItems(items: DisplaySitesModel[]): void {
    let maxLengthToDeleteCount = this.itemList.items.length - 1;

    if (items.length === this.itemList.items.length) {
      this.deferredMessage = `${maxLengthToDeleteCount} sites deleted.`;
    } else {
      this.deferredMessage = items.length > 1 ? `${items.length} sites deleted.` : `Site <b>${items[0].name}</b> deleted`;
    }

    super.deleteItems(items);
  }

  protected delete(item: any): void {
    this.sitesService.removeSite(item);
  }

  protected toggleDelete(): void {
    super.toggleDelete();
  }

  // Sanitise methods - we use this to mutate the data to the required format

  public sanitiseSiteData(siteData: Array<any>): Array<any> {
    return siteData.map(_site => {
      return new DisplaySitesModel(_site)
    });
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }
}

