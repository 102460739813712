import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, customElement, LogManager, observable, computedFrom } from 'aurelia-framework';
import {
  validateTrigger,
  ValidateResult,
  ValidationControllerFactory,
  ValidationRules,
  ValidationController,
  FluentRuleCustomizer,
  Rule,
  ControllerValidateResult
} from 'aurelia-validation';
import { v4 as uuidv4 } from 'uuid';
import { BootstrapFormRenderer, MESSAGE_EVENTS, SessionStore } from 'zailab.common';

import { InteractionModel } from '../../../interaction-model';
import { FlowsModel } from '../../../../contact/contactcontroller/flows-model';
import { ContactModel } from '../../../../contact/contact-model';
import { CONTROLLER_ACTIONS, LOADER_ACTIONS } from '../../../../contact/contactcontroller/contact-controller-actions';
import { ChannelService } from '../../channel-service';
import { EmailTag } from '../../../../../../components/molecules/inputs/z-email-tag-input';

import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { EmailImage } from './email-image';
import { QUILL_IMAGE_UPLOAD_CONSTANTS } from '../../../../../../components/atoms/wysiwyg/quill-plugin-image-upload/quill-image-upload-constants';
import { CannedResponseService } from '../../../../../cannedresponses/canned-responses-service';
import { CannedResponseModel } from '../../../../../cannedresponses/canned-responses-model';
import { Menu } from '../../../../../../components/molecules/dropdowns/z-dropdown-menu';
import { UserPersonService } from '../../../../../user/person/user-person-service';

const logger = LogManager.getLogger('Email');

// enable / disable VIEW MODE
const FEATURE_FLAG_ADDRESS_VIEW_MODE = false;

// OPTION for above feature. On load, default view for Address Block is EDIT
// User must focus on address inputs at least once before Block will collapse to View mode
const FEATURE_OPTION_1_INIT_MODE_IS_EDIT = false;
const FEATURE_OPTION_2_DISPLAY_EMAILS_COUNT = 3;

@customElement('z-email')
@autoinject()
export class Email {
  @bindable private contact: ContactModel;
  @bindable public flows: FlowsModel;
  @bindable public email: {
    message: string,
    newSubject: string,
    subject: string,
    newTo: string,
    to: string,
    from: string,
    attachmentDetails: [],
    relativeTime: string,
    ccs: string[],
    bccs: string[],
    replyToAll?: boolean
  };

  private subject: string = null;
  private html: string = '';
  private htmlDelta: any = null; // TODO: Add type when project migrated to Webpack
  private flowEmailAddress: string = null;
  private attachmentDetails: Object[] = [];
  private emailId: string = null;
  private attachmentId: string = null;
  private error: string = null;
  private isUploading: boolean = false;
  private isAddingImage: boolean = false;
  private showUploadInstructions: boolean = true;
  private confirmDelete: boolean = false;
  private validation: ValidationController;
  private uploadFile: Element;
  private emailImages: EmailImage[] = [];
  private emailSize: number = 0;
  private maxFileSize: number = 1048576;
  private maxEmailSize = 20971520;
  private noContactEmails: string = null;
  private noFlowEmails: string = null;

  private addressValidationResults: ValidateResult[];

  private formInitialized = false;
  private addressesInputInitialized = false;
  private emailsEditable = true;

  public ready = false;

  @observable
  private toValues: Array<EmailTag>;
  private toEmailsValid: boolean = true;

  @observable
  private ccValues: Array<EmailTag>;
  private ccEmailsValid: boolean = true;

  @observable
  private bccValues: Array<EmailTag>;
  private bccEmailsValid: boolean = true;

  private toLookupEmails: Array<any> = [];

  private ccVisible: boolean = false;
  private bccVisible: boolean = false;

  private validationRulesEmailAddresses: Rule<{}, any>[][];
  private validationRulesEmailBody: Rule<{}, any>[][];

  public responseTemplateMenu: Menu<ResponseTemplate> = null;

  // references to DOM elements
  private addressComponent: HTMLElement;
  private refToField: any;
  private refCcField: any;
  private refBccField: any;

  public height: string = '100%';
  private heightAmount: number = 100;

  private subscriptions = {
    expand: null,
    collapse: null,
    reset: null
  };

  private wysiwygOptions = {
    formats: [
      'bold',
      'italic',
      'underline',
      'strike',
      'list',
      'font',
      'color',
      'background',
      'align',
      'image',
      'imageUpload'
    ],
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['image'],
        ['clean']
      ],
      imageUpload: {
        upload: (file: Object, imageId: string) => {
          this.error = '';
          //@ts-ignore
          let image: EmailImage = new EmailImage(imageId, file.name, file.size);
          this.emailId = this.emailId || uuidv4();
          this.isAddingImage = true;

          return new Promise((resolve, reject) => {
            if (image.exceedsImageFileSize()) {
              this.error = image.maxImageSizeMessage;
              reject(new Error('Could not upload image to editor.'));
            } else {
              this.channelService
                .uploadImage(this.emailId, image.id, file, image.name)
                .then(imageUrl => {
                  image.url = imageUrl.url;
                  this.emailImages.push(image);
                  resolve(image.url);
                })
                .catch(() => reject(new Error('Could not upload image to editor.')));
            }
            this.isAddingImage = false;
          });
        }
      }
    }
  };

  constructor(
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private validationControllerFactory: ValidationControllerFactory,
    private channelService: ChannelService,
    private cannedResponseService: CannedResponseService,
    private userPersonService: UserPersonService
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.changeOrBlur;

    this.emailId = uuidv4();

    // ensure we have the correct context. alternative is to use class properties syntax, which is stage 2
    this.handleHtmlValueChanged = this.handleHtmlValueChanged.bind(this);

    this.initValidation();
  }

  public emailChanged(): void {
    this.overrideFormValues();
  }

  private handleHtmlValueChanged(data: any): void {
    this.htmlDelta = data.delta;
  }

  private resetAddressFieldsValidation(): void {
    this.validation.reset({ object: this, rules: this.validationRulesEmailAddresses })
  }

  private resetBodyFieldsValidation(): void {
    setTimeout(() => {
      this.validation.reset({ object: this, rules: this.validationRulesEmailBody })
    }, 50);
  }

  private resetValidation(): void {
    setTimeout(() => {
      this.validation.reset();
    }, 50);
  }

  private validateAddressFields(): void {
    if (this.formInitialized && this.validation) {
      this.validation.validate({ object: this, rules: this.validationRulesEmailAddresses })
        .then((validation: ControllerValidateResult) => {
          if (validation.valid) {
            this.addressValidationResults = [];
          } else {
            // copy so we only see / get address field validation errors
            this.addressValidationResults = this.validation.errors.filter((it) => {
              return (
                (it.propertyName === "toValues") ||
                (it.propertyName === "ccValues") ||
                (it.propertyName === "bccValues")
              );
            })
          }
        });
    }
  }

  private focusToField(): void {
    setTimeout(() => {
      this.refToField.focus();
    }, 50);
  }

  private focusCCField(): void {
    setTimeout(() => {
      this.refCcField.focus();
    }, 50);
  }

  private focusBCCField(): void {
    setTimeout(() => {
      this.refBccField.focus();
    }, 50);
  }

  /////////////////////////

  // USER ACTIONS

  // user action
  protected addCcField(evt: Event): boolean {
    this.validateAddressFields();
    this.ccVisible = !this.ccVisible;
    this.focusCCField();

    return false;
  }

  // user action
  protected addBccField(evt: Event): boolean {
    this.validateAddressFields();
    this.bccVisible = !this.bccVisible;
    this.focusBCCField();

    return false;
  }

  // user action
  protected editEmails(): void {
    this.collapseEmptyEmailFields();

    // enable email address edit
    this.emailsEditable = true;

    // select which field to focus
    this.selectEmailAddressFieldToFocus();
  }

  protected selectFile(event: Event): void {
    this.error = '';
    let files = event.target.files;

    if (!files || !files.length) {
      return;
    }
    for (let file of files) {
      this.uploadAttachment(file);
    }
    this.uploadFile.value = '';
  }

  // SYSTEM ACTIONS
  private collapseEmptyEmailFields(): void {
    // collapse cc / bcc fields if they are empty
    if (this.ccValues.length === 0) {
      this.ccVisible = false;
    }
    if (this.bccValues.length === 0) {
      this.bccVisible = false;
    }
  }

  private selectEmailAddressFieldToFocus(): void {
    if (this.toValues.length > 0) {
      this.focusToField();
    } else if (this.ccValues.length > 0) {
      this.focusCCField();
    } else if (this.bccValues.length > 0) {
      this.focusBCCField();
    } else {
      this.focusToField();
    }
  }

  // TODO: Refactor? Feature Flag perhaps?
  private initializeEmailAddressInput(): void {
    if (FEATURE_FLAG_ADDRESS_VIEW_MODE) {
      if (!this.addressesInputInitialized) {
        this.addressesInputInitialized = true;
      }
    }
  }

  // Observables
  protected toValuesChanged(): void {
    this.validateAddressFields();
  }

  protected ccValuesChanged(): void {
    this.validateAddressFields();
  }

  protected bccValuesChanged(): void {
    this.validateAddressFields();
  }

  // TODO: Optimize and fix (particularly doesn't work if you click outside Email (same as Gmail).
  protected inputFocusChanged(evt: Event): boolean {
    let target: HTMLElement = evt.target as HTMLElement;

    do {
      // focus change originated from inside the address block
      if (target === this.addressComponent) {
        if (FEATURE_FLAG_ADDRESS_VIEW_MODE) {
          // if we've focussed on an email address form field at least one, set this.addressesInputInitialized to true
          // this will ensure that the address form fields will not be collapsed until the user has at least focussed on it once.
          this.initializeEmailAddressInput();
        }

        // activity within Address block - keep editable
        this.emailsEditable = true;
        return false;
      }
      // Search up the DOM for addressComponent
      target = target.parentNode as HTMLElement;
    } while (target);

    // focus change originated from outside the address block
    if (FEATURE_FLAG_ADDRESS_VIEW_MODE) {
      if (!FEATURE_OPTION_1_INIT_MODE_IS_EDIT) {
        this.addressesInputInitialized = true;
      }
      if (this.addressesInputInitialized) {
        // only collapse email address input once the email address input block has been initialized.
        this.emailsEditable = false;
      }
    }
    return false;
  }

  private initValidation(): void {
    // all addresses must be valid
    const satisfiesAllToEmailsValid = (value: Array<EmailTag>, object?: any) => {
      return object.toEmailsValid;
    };
    const satisfiesAllCcEmailsValid = (value: Array<EmailTag>, object?: any) => {
      return object.ccEmailsValid;
    };
    const satisfiesAllBccEmailsValid = (value: Array<EmailTag>, object?: any) => {
      return object.bccEmailsValid;
    };


    const toRules = ValidationRules
      .ensure('toValues')
      // To: Rule 1 - toValues required
      .satisfies((value: Array<EmailTag>, object?: any) => (value.length !== 0))
      // when cc: and bcc: are empty
      // TODO: Note to Marcel - to ensure that a to address is always required, comment out the next line
      // .when((obj: Email) => ((obj.ccValues.length === 0) && (obj.bccValues.length === 0)))
      .withMessage("Please enter a To: recipient email address.")
      // To: Rule 2 - all addresses must be valid
      .then()
      .satisfies(satisfiesAllToEmailsValid)
      .withMessage("One or more email addresses are invalid.")
      .rules;

    const ccRules = ValidationRules
      .ensure('ccValues')
      .satisfies(satisfiesAllCcEmailsValid)
      .withMessage("One or more email addresses are invalid.")
      .rules;

    const bccRules = ValidationRules
      .ensure('bccValues')
      .satisfies(satisfiesAllBccEmailsValid)
      .withMessage("One or more email addresses are invalid.")
      .rules;

    this.validationRulesEmailAddresses = toRules.concat(ccRules).concat(bccRules);

    this.validationRulesEmailBody = ValidationRules
      .ensure('subject')
      .required()
      .withMessage('Please enter a subject.')
      .ensure('flowEmailAddress')
      .required()
      .withMessage('Please enter a flow email address.')
      .rules;

    this.validation.addObject(this, this.validationRulesEmailAddresses.concat(this.validationRulesEmailBody))
  }

  private initializeFormValues(): void {
    // initial state - undefined toValues
    if (!this.toValues) {
      // initialize - grab first contact if present and populate to toValues
      this.toValues = this.tmpMapToEmailInput(this.contact.emailAsStrings).slice(0, 1);
      this.ccValues = [];
      this.bccValues = [];

      this.formInitialized = true;
      this.validateAddressFields();
    } else {
      this.validation.validate({ object: this, rules: this.validationRulesEmailAddresses });
    }
  }

  private resetForm(): void {

    this.email = null;

    this.formInitialized = false;

    this.addressesInputInitialized = false;

    this.emailsEditable = true;
    // this.html = null;
    this.html = '';
    this.subject = null;
    this.attachmentDetails = [];
    this.emailImages = [];
    this.toValues = null;
    this.ccValues = null;
    this.bccValues = null;
    this.ccVisible = false;
    this.bccVisible = false;

    this.resetBodyFieldsValidation();
    this.initializeFormValues();
  }

  public bind(): void {
    this.initializeFormValues();
    this.overrideFormValues();
    this.populateIfReply();
  }

  public overrideFormValues(): void {
    if (this.email) {
      this.subject = this.email.newSubject;
      this.toValues = this.email.newTo ? [{ label: this.email.newTo }] : [];
      this.attachmentDetails = this.email.attachmentDetails;
    }
    this.userPersonService
      .retrieveEmailSignature(this.sessionStore.get.user.memberId)
      .then((res) => {
        if (res.signature) {
          this.html = `<br/><br/>${res.signature}`;
        }
      });
  }

  private populateIfReply(): void {
    if (this.email && this.email.replyToAll) {
      if (this.email.from) {
        this.toValues = [{ label: this.email.from }];
      }
      if (this.email.ccs) {
        this.ccVisible = true;
        this.email.ccs.forEach(cc => {
          this.ccValues.push({ label: cc });
        });
      }
      if (this.email.bccs) {
        this.bccVisible = true;
        this.email.bccs.forEach(bcc => {
          this.bccValues.push({ label: bcc });
        });
      }
    }
  }

  public attached(): void {
    this.noContactEmails = 'No email addresses.';
    this.noFlowEmails = 'No flow email addresses.';
    this.cannedResponseService.findResponseTemplates('EMAIL') //
      .then((data: CannedResponseModel[]) => this.handleResponseTemplatesResponse(data));
    this.subscribeToSizeChanges();
  }

  private handleResponseTemplatesResponse(cannedResponses: CannedResponseModel[]): void {
    this.responseTemplateMenu = new Menu();
    this.responseTemplateMenu.onchange = (responseTemplate: CannedResponseModel) => this.handleSelectedResponseTemplate(responseTemplate);
    cannedResponses.forEach((responseTemplate: CannedResponseModel) => this.responseTemplateMenu.options.push(responseTemplate));
  }

  private handleSelectedResponseTemplate(responseTemplate: CannedResponseModel): void {
    this.html = responseTemplate.body;
    this.subject = responseTemplate.subject;
  }

  public uploadAttachment(file: File): void {
    if (file.size > this.maxFileSize) {
      this.error = 'File size cannot exceed 1MB.';
      return;
    }

    const allowedTypes = ['text/csv', 'image/jpeg', 'image/png', 'image/gif', 'image/pdf','application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (allowedTypes.indexOf(file.type) === -1) {
      const message =
      'Invalid file type. Please upload a CSV, JPEG, PNG, GIF, PDF, DOC, or DOCX file.';
      this.eventAggregator.publish(MESSAGE_EVENTS.WARNING, message);
      return;
    }

    this.emailSize += file.size;
    this.emailId = this.emailId || uuidv4();
    this.showUploadInstructions = false;
    this.isUploading = true;
    this.attachmentId = uuidv4();

    this.channelService
      .uploadAttachment(this.attachmentId, this.emailId, file)
      .then(
        () => {
          this.isUploading = false;
          const attachment = {
            emailId: this.emailId,
            attachmentId: this.attachmentId,
            fileName: file.name,
            fileSize: file.size
          };
          this.attachmentDetails.push(attachment);
          if (this.attachmentDetails.length > 0) {
            this.showUploadInstructions = false;
            this.uploadFile.value = '';
          }
        },
        error => {
          logger.info('Uploading attachments error >', error);
          this.isUploading = false;
          this.uploadFile.value = '';
        }
      );
  }

  private showDeleteIcon(attachment: object): void {
    attachment.isVisible = true;
  }

  private hideDeleteIcon(attachment: object): void {
    attachment.isVisible = false;
  }

  private deleteAttachment(attachment: object): void {
    // TODO: GI 2017/11/20 >   Delete file

    for (let i = 0; i < this.attachmentDetails.length; i++) {
      let attached = this.attachmentDetails[i];
      if (attached.fileName === attachment.fileName) {
        this.attachmentDetails.splice(i, 1);
      }
    }
  }

  private discardEmail(): void {
    this.resetForm();

    this.eventAggregator.publish('change.channel.size.reset', this.contact.contactId);
    this.eventAggregator.publish('change.channel.view', { contactId: this.contact.contactId, channel: 'call' });
    this.confirmDelete = !this.confirmDelete;
    this.emailId = uuidv4();
  }

  private toggleConfirmDelete(): void {
    this.confirmDelete = !this.confirmDelete;
  }

  protected sendEmail(): void {
    this.validation.validate().then(
      (validation) => {
        if (!validation.valid) {
          return;
        }

        this.emailImages.forEach((image: EmailImage) => {
          if (!image.isPresent()) {
            this.channelService.deleteImage(this.emailId, image.id);
          } else {
            this.emailSize += image.size;
          }
        });

        if (this.emailSize > this.maxEmailSize) {
          this.error = 'File size cannot exceed 20MB.';
          return;
        }

        if (this.attachmentDetails && this.attachmentDetails.length > 0) {
          this.attachmentDetails.forEach(attachment => {
            delete attachment.fileSize;
            delete attachment.emailId;
          });
        }

        let conversation = this.contact.getSelectedConversation();
        if (conversation && conversation.status.toLowerCase() !== 'pending' && conversation.status.toLowerCase() !== 'new') {
          conversation = null;
        }

        const deltaCfg = {
          inlineStyles: true,
          // TODO: custom sanitize
          // urlSanitizer: () => {}
        };

        let emailFriendlyHtmlMessage = "";

        if (this.htmlDelta && this.htmlDelta.ops) {
          const deltaOps = this.htmlDelta.ops;
          const deltaToHtml = new QuillDeltaToHtmlConverter(deltaOps, deltaCfg);
          deltaToHtml.renderCustomWith(
            (customOp: any, contextOp: any): string => {
              if (customOp.insert.type === 'imageUpload') {
                return `<p><img src="${customOp.insert.value}"/></p>`;
              } else {
                return '';
              }
            }
          );
          emailFriendlyHtmlMessage = deltaToHtml.convert();
          // FIXME: Potentially run through sanitize filter at this point.
        }

        let completeMessage = emailFriendlyHtmlMessage;
        if (this.email) {
          completeMessage = `${emailFriendlyHtmlMessage}<br/>---------- Forwarded message ---------<br/><p>From: ${this.email.from}</p><p>Date: ${this.email.relativeTime}</p><p>Subject: ${this.email.subject}</p><p>To: ${this.email.to}</p><br/>${this.email.message}`;
        }

        let email = new InteractionModel({
          attachmentDetails: this.attachmentDetails,
          channel: 'EMAIL',
          contactId: this.contact.contactId,
          conversationId: conversation ? conversation.conversationId : null,
          correlationId: this.contact.correlationId,
          emailId: this.emailId,
          from: this.flowEmailAddress,
          message: completeMessage,
          subject: this.subject,
          tos: this.toValues.map(it => it.label),
          ccs: this.ccValues.map(it => it.label),
          bccs: this.bccValues.map(it => it.label),
          userId: this.sessionStore.get.user.userId,
          ignoreImageStripping: true
        });

        this.resetForm();

        this.eventAggregator.publish(CONTROLLER_ACTIONS.SEND_EMAIL, email);
        this.eventAggregator.publish(
          'change.channel.size.reset',
          this.contact.contactId
        );
        this.eventAggregator.publish(
          LOADER_ACTIONS.CHANGE_LOADER_INTERACTION_LIST,
          {
            contactId: this.contact.contactId,
            status: true
          }
        );

        // Later story: Either have controller interaction for pre-validation checks, or
        // otherwise implement the following event handlers, and only reset when sendMail successful.
        // 1. if sendEmail successful (no server side validation, then reset form)
        // - how to listen for success?
        // 2. if sendEmail error (server-side validation), update validation message
        // - can listen for MESSAGE_EVENTS.INTERACTION_BLOCKED
      },
      error => {
        logger.info('Email > validation failure', error);
      }
    );
  }

  private tmpMapToEmailInput = (arr: Array<string>) => {
    return arr.map(it => {
      return {
        label: it
      };
    });
  };

  @computedFrom('toValues', 'ccValues', 'bccValues')
  private get emailsAsView(): object {
    const emailCount = this.toValues.length + this.ccValues.length + this.bccValues.length;
    const splitLength = FEATURE_OPTION_2_DISPLAY_EMAILS_COUNT;
    const firstToValues = this.toValues.slice();
    const firstCcValues = this.ccValues.slice();
    const firstBccValues = this.bccValues.slice();

    let response;

    const displayEmails = [];

    if (emailCount > splitLength) {
      let displayDone = false;
      while (!displayDone) {
        if (displayEmails.length < splitLength) {
          // try to add one more
          // to
          const _to = firstToValues.shift();
          if (_to) {
            displayEmails.push(_to);
          } else {
            // cc
            const _cc = firstCcValues.shift();
            if (_cc) {
              displayEmails.push(_cc);
            } else {
              const _bcc = firstBccValues.shift();
              if (_bcc) {
                displayEmails.push(_bcc);
              } else {
                displayDone = true;
              }
            }
          }
        } else {
          displayDone = true;
        }
      }
      // no process summary

      // summary
      response = {
        displayEmails,
        summary: {
          totalRemaining: firstToValues.length + firstCcValues.length + firstBccValues.length,
          totalBcc: firstBccValues.length,
        }
      };
    } else {
      // no summary
      response = {
        displayEmails: firstToValues.concat(firstCcValues).concat(firstBccValues),
      }
    }

    return response;
  }

  // FIXME: map type properly
  @computedFrom('contact', 'contact.emailAsStrings')
  private get contactFirstEmail(): Array<any> {
    return this.tmpMapToEmailInput(this.contact.emailAsStrings).slice(0, 1);
  }

  @computedFrom('toValues', 'ccValues', 'bccValues')
  protected get emailAddressCount(): number {
    return this.toValues.length + this.ccValues.length + this.bccValues.length;
  }

  // FIXME: map type properly
  @computedFrom('contact', 'contact.emailAsStrings')
  private get emailAsStrings(): Array<any> {
    if (this.contact.contactId) {
      return this.tmpMapToEmailInput(this.contact.emailAsStrings);
    } else if (this.contact.servedInteractions.length > 0) {
      let from = this.contact.servedInteractions[0].from;
      if (this.contact.servedInteractions[0].formattedDirection === 'Outbound') {
        from = this.contact.servedInteractions[0].interactionTo;
      }
      if (from && !from.includes('@')) {
        return [];
      }
      return this.tmpMapToEmailInput([from]);
    }
    return [];
  }

  private showMediumChannel(): boolean {
    if (this.contact.channelViewStrategy.size === 'small') {
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    }
    return true; // allow default behaviour (needed for WYSIWYG editor popups etc to work)
  }

  private expandFully(): void {
    if (this.contact.channelViewStrategy.size === 'small') {
      // cheapest way to make large is to call twice
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    } else if (this.contact.channelViewStrategy.size === 'medium') {
      // it's half open, so make it fully open
      this.eventAggregator.publish('change.channel.size.expand', this.contact.contactId);
    }
  }

  private subscribeToSizeChanges(): void {

    const contactCardBodyElement = document.querySelector('.c-contact-card-body');
    const offset = (contactCardBodyElement.offsetHeight - 240) / 2;

    if (this.contact.channelViewStrategy.size === 'small') {
      this.heightAmount = 100;
    } else if (this.contact.channelViewStrategy.size === 'medium') {
      this.heightAmount = 100 + offset;
    } else {
      this.heightAmount = 100 + (offset * 2);
    }
    this.height = `${this.heightAmount}px`;

    this.subscriptions.expand = this.eventAggregator.subscribe('change.channel.size.expand', () => {
      this.heightAmount += offset;
      this.height = `${this.heightAmount}px`;
    });
    this.subscriptions.collapse = this.eventAggregator.subscribe('change.channel.size.collapse', () => {
      this.heightAmount -= offset;
      if (this.heightAmount < 100) {
        this.heightAmount = 100;
      }
      this.height = `${this.heightAmount}px`;
    });
    this.subscriptions.reset = this.eventAggregator.subscribe('change.channel.size.reset', () => {
      this.heightAmount = 100;
      this.height = `${this.heightAmount}px`;
    });
    this.subscriptions.reset = this.eventAggregator.subscribe('change.channel.email.reset', (contactId: string) => {
      if (this.contact.contactId === contactId) {
        this.resetForm();
      }
    });
  }

  private disposeSizeChangesSubscriptions(): void {

    this.subscriptions.expand.dispose();
    this.subscriptions.collapse.dispose();
    this.subscriptions.reset.dispose();
  }

  public detached(): void {
    
    this.disposeSizeChangesSubscriptions();
  }
}
