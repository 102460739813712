import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController} from 'aurelia-dialog';
import { FlowVersionDialogService } from '../flow-versions/flow-version-service';

const logger = LogManager.getLogger('FlowVersionDialog');

interface IVersion {
  version: number;
  isLive: boolean;
  actions?: any[];
}

interface IInteractionFlow {
  interactionFlow: {
    interactionFlowId: string;
    flowName: string;
    versions: IVersion[];
  };
  organisationId: string;
}

@autoinject
export class FlowsAssignedToSubflowDialog {

  private interactionFlowId: string;
  public flowName: string;
  public assignedFlows: { interactionFlowId: string; name: string; version?: string; }[] = null;

  constructor(
    private dialogController: DialogController,
    private flowVersionDialogService: FlowVersionDialogService
  ) {}

  activate(model: IInteractionFlow): void {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.flowName = model.interactionFlow.flowName;

    this.retrieveFlows();
  }

  private async retrieveFlows(): Promise<void> {
    try {
      let assignedFlowsData = await this.flowVersionDialogService.retrieveFlowsAssignedToSubflow(this.interactionFlowId);
      if (assignedFlowsData && assignedFlowsData.interactionFlows) {
        let flows = assignedFlowsData.interactionFlows;
        let flowMap = {};
        flows.forEach(flow => {
          if (!flowMap[flow.id]) {
            flowMap[flow.id] = {
              name: flow.name,
              versions: []
            };
          }
          flowMap[flow.id].versions.push(flow.version);
        });

        const keys = Object.keys(flowMap);
        let assignedFlows = [];

        keys.forEach(key => {
          let versions = flowMap[key].versions;

          assignedFlows.push({
            id: key,
            name: flowMap[key].name,
            version: versions
          })
        })
        this.assignedFlows = assignedFlows;
      }
    } catch(e) {
      logger.warn(' > Failed to retrieve list of assigned flows due to ', e);
    }
  }

  public view(interactionFlow: { id: string }, version: any): void {
    this.dialogController.ok({
      id: interactionFlow.id,
      version: version !== 'Latest' ? version : null
    });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
