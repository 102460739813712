import { bindable } from 'aurelia-templating';
import { OrganisationSessionModel } from './../../../../../../../_common/stores/sessionmodels/organisation-session-model';
import { SessionStore } from './../../../../../../../_common/stores/session-store';
import { computedFrom } from 'aurelia-binding';
import { ContactCenterService } from './../../../../../contactcenter/contact-center-service';
import { Subscription } from 'aurelia-event-aggregator';
import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { MembersService } from '../../../members-service';
import { ChangeMemberInformationDialog } from '../../../changememberinformation/changememberinformation';
import { Event } from '../../../../../../../zailab.common';
import MemberPropertiesModel from '../../../member-properties-model';


const logger = LogManager.getLogger('ViewMemberAttributes');

@inject(Element, EventAggregator, Router, MembersService, ContactCenterService, DialogService, SessionStore)
export class ViewMemberAttributes {

  @bindable private member: MemberPropertiesModel;
  private memberId: string;

  private validationErrorsReceivedSub: Subscription;
  public ready: boolean = false;
  private contactCenterSupport: ContactCenterSupport = new ContactCenterSupport();
  private updatedRoles: any;

  constructor(
    private element: Element,
    private eventAggregator: EventAggregator,
    private router: Router,
    private membersService: MembersService,
    private contactCenterService: ContactCenterService,
    private dialogService: DialogService,
    private sessionStore: SessionStore
  ) {}
  
  public activate(bindingContext: any): void {
    this.member = bindingContext.data.member;
    this.memberId = this.member.memberId;
    this.contactCenterSupport.setMember(this.member);
    this._registerEvents();
  }

  public attached(): void {
    this.retrieveContactCentres();
  }

  public unbind(): void {
    this._disposeEvents();
  }

  private _registerEvents(): void {
    this.validationErrorsReceivedSub = this.eventAggregator.subscribe('ValidationErrorsReceived',
      (error) => {
        if (error.state.globalError.code === 'last.organisation.admin') {
          this.member.roles = [this.updatedRoles.deallocate];
        }
      });
  }

  private _disposeEvents(): void {
    if (this.validationErrorsReceivedSub) {
      this.validationErrorsReceivedSub.dispose();
    }
  }

  public changeMemberExternalReference(): void {
    this.dialogService.open({
      viewModel: ChangeMemberInformationDialog,
      model: this.member
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
      }
    });
  }

  public retrieveContactCentres(): void {

    this.contactCenterService.findAll().then((contactCenters) => {
      this.contactCenterSupport.setContactCenters(contactCenters);
    });

  }

  public updateChannels(event: any): void {
    let channels = event.items.filter(channel => {return channel.isSelected}).map(channel => {return channel.channelName;});
    this.membersService.updateMemberChannels(this.memberId, channels)
    this.member._channels = channels.map(channelName => { return { channelName }; });
  }

  public updateCapacityRules(event: any): void {
    this.membersService.allocateCapacityRulesToMember(this.memberId, event.items);
    this.member.capacityRules = event.items;
  }

  public updateClassOfService(event: any): void {

    this._findChangedItems(event, 'name').then((_list: any[]) => {
      _list.filter(cos => {return cos.isSelected}).forEach(cos => {
        this.membersService.allocateClassOfServiceToMember(this.memberId, cos.name);
        this.member.classesOfService = cos.name;
      })
    });
  }

  public updateRole(event: any): void {

    this._findChangedItems(event, 'roleName').then((_list: any[]) => {
      let oldRoles = this.member.roles;
      let newRoles = _list.filter(role => {return role.isSelected});

      _list.filter(role => {
        return role.isSelected
      }).forEach(role => {
        this.membersService.updateMemberRole(this.memberId, role.roleValue)
          .then(() => this.member.roles = newRoles)
          .catch(() => this.member.roles = oldRoles);
        this.member._roles = [role];
      });
    });
  }

  public updateServices(event: any): void {
    let services = event.items.filter(service => {return service.isSelected}).map(service => {return service.id;});
    this.membersService.updateMemberServices(this.memberId, services)
    this.member.services = services.map((serviceId: string) => { return { id: serviceId }; });
  }

  public updateBusinessPartners(event: any): void {

    this.contactCenterSupport.selectedBusinessPartners = event.items.filter((item) => item.isSelected);
    this.contactCenterSupport.update();

    let contactCentreIds = this.contactCenterSupport.selectedContactCentres.map(cc => { return cc.id; });
    this.membersService.updateMemberContactCentres(this.memberId, contactCentreIds)

    let businessPartnerIds = event.items.filter(bp => {return bp.isSelected}).map(bp => { return bp.id; });
    this.membersService.updateMemberBusinessPartners(this.memberId, businessPartnerIds)
  }

  public updateContactCenters(event: any): void {
    this.contactCenterSupport.selectedContactCentres = event.items.filter((item) => item.isSelected);

    let contactCentreIds = this.contactCenterSupport.selectedContactCentres.map(cc => {return cc.contactCentreId});
    this.membersService.updateMemberContactCentres(this.memberId, contactCentreIds);
  }

  public updateSite(event: any): void {

    this._findChangedItems(event, 'name').then((_list: any[]) => {
      for (let item of _list) {
        if (item.isSelected) {
          this.allocateSite(item.id);
        }
      }
    });
  }

  public allocateSite(siteId: string): void {
    this.membersService.allocateMemberToSite(this.memberId, siteId);
    this.member.site = { id: siteId };
  }

  public updateTeams(event: any): void {
    let teams = event.items.filter(team => {return team.isSelected}).map(team => {return team.id;});
    this.membersService.updateMemberTeams(this.organisationId, this.memberId, teams)
    this.member.teams = teams.map(teamId => { return { id: teamId }; })
  }

  public updateSkills(event: any): void {
    let data = this._findChangedSkills(event.items);
    this.member.mapProperties({ skillGroups: data.skillGroups });

    let skillIds = [];
    event.items.map(_skillGroup => {
      _skillGroup.skills.filter(_skill => { return _skill.isSelected }).forEach(_skill => {
        skillIds.push(_skill.id);
      })
    })
    this.membersService.updateMemberSkills(this.memberId, skillIds);
  }

  public _findChangedSkills(skillGroups: any[]): any {

    skillGroups = JSON.parse(JSON.stringify(skillGroups));
    let _skillGroups = [];
    let _allSkills = [];

    for (let skillGroup of skillGroups) {

      let _skills = [];
      for (let skill of skillGroup.skills) {

        if (skill.isSelected) {
          skill.skillGroupId = skillGroup.skillGroupId;
          _allSkills.push(skill);
          _skills.push(skill);
        }
      }

      if (_skills.length) {
        skillGroup.skills = _skills;
        _skillGroups.push(skillGroup);
      }
    }
    return { skills: _allSkills, skillGroups: _skillGroups };
  }

  public _findChangedItems(event: any, displayKey: string): Promise<any> {

    return new Promise((resolve, reject) => {

      let _list = [];
      const changedItems = event.items;
      const originalItems = event.originalItems;

      for (let item of changedItems) {
        const originalItem = originalItems.find(oi => oi[displayKey] === item[displayKey]);

        let changedItemSelected = !!item.isSelected;
        let originalItemSelected = !!originalItem.isSelected;

        if (changedItemSelected !== originalItemSelected) {
          _list.push(item);
        }
      }
      resolve(_list);
    });
  }

  public back(): void {
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if(!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }
}

class ContactCenterSupport {

  public selectedBusinessPartners = [];
  public selectedContactCentres = [];
  public isContactCenterDisabled = true;
  public contactCenters = [];

  public setContactCenters(contactCenters: any): void {

    this.contactCenters = contactCenters;

    if (this.selectedBusinessPartners.length) {
      this.update();
    }
  }

  public setMember(member: MemberPropertiesModel): void {

    this.selectedBusinessPartners = member.businessPartners;
    this.selectedContactCentres = member.contactCentres;
    this.isContactCenterDisabled = !member.businessPartners.length;

    if (this.contactCenters.length) {
      this.update();
    }
  }

  public update(): any {

    const selectedContactCentres = [];
    const removedContactCentres = [];
    for (const contactCenter of this.selectedContactCentres) {
      const cc = this.contactCenters.find((cc) => cc.contactCentreId === contactCenter.id);
      const businessPartners = this.selectedBusinessPartners.filter((selectedBusinessPartner) => {
        return cc && cc.businessPartnerId === selectedBusinessPartner.id;
      });
      if (businessPartners.length > 0) {
        selectedContactCentres.push(contactCenter);
      } else {
        removedContactCentres.push(contactCenter);
      }
    }

    const businessPartners = this.selectedBusinessPartners.filter((selectedBusinessPartner) => {
      const cc = this.contactCenters.find((cc) => cc.businessPartnerId === selectedBusinessPartner.id);
      return !!cc;
    });

    this.isContactCenterDisabled = this.selectedBusinessPartners.length === 0 || businessPartners.length === 0;
    this.selectedContactCentres = selectedContactCentres;

    return removedContactCentres;
  }
}
