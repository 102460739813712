import { bindable, customElement } from "aurelia-framework";

@customElement('z-interaction-card-details')
export class InteractionCardDetails {

  public TYPES = INTERACTION_TYPES;

  @bindable
  public type: string; // one of INTERACTION_TYPES

  @bindable
  public interaction: any;
}

export const INTERACTION_TYPES = {
  call: 'call',
  chat: 'chat',
  email: 'email',
  ticket: 'ticket',
};
