import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {InteractionDetailModel} from './interaction-detail-model';
import {computedFrom} from 'aurelia-binding';

//@ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('CCMStatModel');

export class CCMStatModel extends BaseModel {

  // 🅶🅻🅾🅱🅰🅻 🅿🆁🅾🅿🅴🆁🆃🅸🅴🆂
  public teamId: string = null;
  public organisationId: string = null;
  public teamName: string = null;
  public currentAgentsIds: string[] = null;
  public presenceTotals: {
    totalAvailableTime: number,
    totalAwayTime: number;
    totalBreakTime: number;
    totalLunchTime: number;
    totalBusyTime: number;
    totalMeetingTime: number;
    totalTrainingTime: number;
    totalOffDutyTime: number;
  } = null;
  public callsAmounts: {
    inboundCallsConnected: number;
    outBoundCallsConnected: number;
    callsConnected: number;
  } = null;
  public times: {
    totalHandle: number;
    averageHandle: number;
    conversing: number;
    waiting: number;
    notResponding: number;
    wrapUp: number;
    talk: number;
    hold: number;
    consult: number;
  } = null;
  public connectedChannelsAmounts: {
    imsConnected: number;
    webChatsConnected: number;
    smsSConnected: number;
    emailsConnected: number;
    campaignTasksConnected: number;
    ticketsConnected: number;
  } = null;
  public interactionCounts: {
    noResponse: number;
    hold: number;
    consults: number;
    transfers: number;
  } = null;
  public dispositions: {
    total: number;
    selected: number;
    notSelected: number;
  } = null;
  public handledInteractionCounts: {
    inboundWorkCalls: number;
    outboundWorkCalls: number;
    inboundPrivateCalls: number;
    outboundPrivateCalls: number;
    internalCalls: number;
    emails: number;
    sms: number;
    webChats: number;
    instantMessages: number;
    tickets: number;
  } = null;
  public prospectCounts: {
    prospects: number;
    successfulProspects: number;
    pendingProspects: number;
    unsuccessfulProspects: number;
  } = null;
  public averageSurveyScore: number = null;
  public dispositionsPercentage: number = null;
  
  // 🅼🅴🅼🅱🅴🆁 🆂🅿🅴🅲🅸🅵🅸🅲 🅿🆁🅾🅿🅴🆁🆃🅸🅴🆂
  public memberId: string = null;
  public userId: string = null;
  public personId: string = null;
  public name: string = null;
  public surname: string = null;
  public routingStatus: string = null;
  public teams: { id: string, name: string }[] = null;
  public currentActivity: {
      name: string;
      startTimestamp: number;
  } = null;
  public webphoneStatus: string = null;
  public webphoneStatusTimestamp: number = null;
  public passportId: string = null;
  public online: boolean = null;
  public presenceInformation: {
      name: string;
      startTimestamp: number;
      presenceTotals:{
        totalAvailableTime: number;
        totalAwayTime: number;
        totalBreakTime: number;
        totalLunchTime: number;
        totalBusyTime: number;
        totalMeetingTime: number;
        totalTrainingTime: number;
        totalOffDutyTime: number;
      }
  } = null;
  public whisperLocked: boolean = null;
  public spyLocked: boolean = null;
  public callId: string = null;
  private intraInteractionDetails: any[] = null;
  public consultStartedTimestamp: number = null;
  public conferenceStartedTimestamp: number = null;
  public callerPutOnHoldTimestamp: number = null;

  // 🅼🅾🅳🅴🅻 🅶🅴🅽🅴🆁🅰🆃🅴🅳 🅿🆁🅾🅿🅴🆁🆃🅸🅴🆂
  public currentActivityStatus: string;
  public onWrapUp: boolean;

  constructor(data: CCMStatModel) {
    super();
    this.mapProperties(data);
  }


  public mapProperties(agent: CCMStatModel): void {

    super.mapProperties(agent);

    if(agent.currentActivity) {
      agent.currentActivity.name = this.convertStringCasingToNameCase(agent.currentActivity.name);
      this.currentActivityStatus = this.determineSubStatus(agent.currentActivity.name);
    }
    if (agent.presenceInformation) {
      agent.presenceInformation.name = this.convertStringCasingToNameCase(agent.presenceInformation.name);
    }
    if (agent.intraInteractionDetails) {
      this.setCallIdAndWrapUpStatus(agent.intraInteractionDetails);
    }
  }

  private convertStringCasingToNameCase(value: string): string {
    let split = value.toLowerCase().split('_');
    for (var i = 0; i < split.length; i++) {
      split[i] = split[i].charAt(0).toUpperCase() + split[i].substring(1);
    }
    return (split.join(' ')).replace('_', ' ');
  }

  private setCallIdAndWrapUpStatus(intraInteractionDetails: Array<InteractionDetailModel>): void {
    if (intraInteractionDetails) {
      for (let interactionDetail of intraInteractionDetails) {
        if (this.isCallInteraction(interactionDetail)) {
          this.callId = this.findFirstValidCallId(interactionDetail);
          this.onWrapUp = interactionDetail.latestInteractionState === 'Wrap Up';
        }
      }
    } else {
      this.onWrapUp = false;
    }
  }

  private isCallInteraction(interactionDetail: InteractionDetailModel): boolean {
    return interactionDetail.currentKnownInteractions
      && interactionDetail.interactionType === 'Call';
  }

  private findFirstValidCallId(interactionDetail: InteractionDetailModel): string {
    for (let interaction of interactionDetail.currentKnownInteractions) {
      if (interaction.channelStatus !== 'Missed') {
        return interaction.interactionId;
      }
    }
  }

  private determineSubStatus(subStatus: string): string {
    return subStatus === 'Not Answering' ? 'Not Responding' : subStatus;
  }

  @computedFrom('firstName', 'surname')
  get fullName(): string {
    if (!this.name && !this.surname) {
      return '(No Name)';
    }
    if (!this.name && this.surname) {
      return this.surname;
    }
    if (this.name && !this.surname) {
      return this.name;
    } else {
      return this.name + ' ' + this.surname;
    }
  }

  @computedFrom('currentActivityStatus')
  get isConversing(): boolean {
    return this.currentActivityStatus === 'Conversing';
  }

  @computedFrom('onWrapUp')
  get conversationStatusDescription(): string {
    return this.onWrapUp ? 'Wrap Up' : 'Conversing'
  }

  @computedFrom()
  public get onlineOffline(): string {
    return this.formattedCurrentPresence === 'Offline' ? 'Offline' : 'Online';
  }

  @computedFrom('presenceInformation')
  get formattedCurrentPresence(): string {
    if (!this.presenceInformation.name) {
      return 'On Duty';
    }
    return this.presenceInformation.name;
  }

  @computedFrom('webphoneStatusTimestamp')
  get formattedWebphoneTime(): string {
    if (this.webphoneStatusTimestamp) {
      return moment(this.webphoneStatusTimestamp).format('DD/MM/YYYY HH:mm:ss');
    }
    return '--';
  }
}
