import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { AudioAddService } from 'zailab.common';

const logger = LogManager.getLogger('View Audio');

@inject(DialogController, AudioAddService)
export class ViewAudio {

  private isBusy: boolean = true;
  private mailbox: ZIMailbox;
  private audioSource: URL;
  private fetchAudioId: string;
  private audioType: string;
  private audioRetrieved: boolean = false;
  private audioFailed: boolean = false;

  constructor(private dialogController: DialogController, private audioService: AudioAddService) {

  }

  public activate(_mailbox: ZIMailbox): void {
    this.mailbox = _mailbox;
    this.audioType = this.mailbox.textToSpeechAttributes ? 'Text-to-speech' : 'WAV upload';
    this.fetchAudioId = this.mailbox.textToSpeechAttributes ? this.mailbox.textToSpeechAttributes.textToSpeechId : this.mailbox.audioId;
    this.previewAudio();
  }

  attached(): void {
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private done(): void {
    this.dialogController.ok();
  }

  private previewAudio(): void {
    this.isBusy = true;
    this.audioFailed = false;
    this.audioRetrieved = false;
    this.audioService.retrieveAudioBinary(this.fetchAudioId, this.audioType).then((_audioSource) => {
      this.audioSource = _audioSource;
      this.isBusy = false;
      this.audioRetrieved = true;
      this.audioFailed = false;
    }, () => {
      this.isBusy = false;
      this.audioFailed = true;
    });
  }
}