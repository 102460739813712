import {inject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {handle, waitFor} from 'aurelia-flux';
import {DialogService} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 */
import {ViewSearchDialog} from '../../../../../features/organisation/qualityassurance/performedassessments/viewsearchdialog';
import {VIEW_ACTIONS} from './view.actions.js';
import {ViewService} from './view.service.js';
import {ViewStore} from './view.store.js';
import {SessionStore} from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('View');

/*
 */
@inject(DialogService, EventAggregator, Router, ViewService, ViewStore, SessionStore)
export class View {

  resolve;
  _pagesize = 18;
  currentPage;
  pages;
  hasSearched;
  searchText;
  allItems;
  showSearch;
  memberName;

  constructor(dialogService, eventAggregator, router, viewService, viewStore, sessionStore) {

    this.router = router;
    this.viewService = viewService;
    this.viewStore = viewStore;
    this.dialogService = dialogService;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
  }

  activate(params) {
    this.memberId = params.memberId;
    this.viewService.retrieveForms(this.memberId);

    if (params.memberName) {
      this.memberName = params.memberName;
    } else if (params.membername) {
      this.memberName = params.membername;
    } else {
      this.memberName = '';
    }

  }

  attached() {
    this.viewService.retrieveForms(this.memberId);
  }

  setFocus(field) {

    let firstElement = document.getElementById(field);
    if (firstElement !== null) {
      firstElement.focus();
    } else {

      setTimeout(() => {

        this.setFocus(field);
      }, 100);
    }
  }

  get pagedItems() {

    if (!this.pages) {
      return;
    }

    return this.pages[this.currentPage];
  }

  next() {

    this.currentPage++;
  }

  prev() {

    this.currentPage--;
  }

  get totalItems() {

    return this.allItems.length;
  }

  get showNext() {

    if (this.pages)
      return this.currentPage < this.pages.length - 1;
  }

  get showPrev() {

    return this.currentPage > 0;
  }

  initPages() {

    this.currentPage = 0;
    this.pages = [];

    if (!this.allItems) {
      this.pages.push([]);
      return;
    }

    let pageItems = null;

    for (let itemIndex = 0; itemIndex < this.allItems.length; itemIndex++) {
      if (itemIndex % this._pagesize === 0) {

        if (pageItems !== null) {
          this.pages.push(pageItems);
        }
        pageItems = [];
      }

      pageItems.push(this.allItems[itemIndex]);
    }

    if (pageItems !== null && pageItems.length > 0) {
      this.pages.push(pageItems);
    }
  }

  search() {

    if (!this.allItems || this.allItems.length === 0) {

      return;
    }

    if (!this.searchText || this.searchText.length === 0) {

      this.initPages();
      return;
    }

    let items = [];
    let startList = [];
    this.pages = [];

    for (let item of this.allItems) {

      if (this.startsWith(item, this.searchText)) {

        startList.push(item);
        item.added = true;
      } else {

        item.added = false;
      }
    }

    this.items = startList;

    let pageItems = null;

    for (let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {

      if (itemIndex % this._pagesize === 0) {

        if (pageItems !== null) {
          this.pages.push(pageItems);
        }
        pageItems = [];
      }

      pageItems.push(this.items[itemIndex]);
    }

    if (pageItems !== null && pageItems.length > 0) {
      this.pages.push(pageItems);
    }

  }

  startsWith(item, searchText) {

    let found = false;
    if (item['name'] && item['name'].toLowerCase().startsWith(searchText.toLowerCase())) {

      found = true;
    }

    return found;
  }

  toggleSearch() {

    this.showSearch = !this.showSearch;

    setTimeout(() => {

      this.setFocus('searchText');
    }, 500);
  }

  back() {
    if (this.sessionStore.get.user.hasCampaignManagerRole) {
      this.router.parent.navigate('qateams');
      return;
    }

    if (this.router.baseUrl === '/hud/qualityassessor/forms/') {
      this.router.parent.parent.navigate('dashboard');
    } else {
      this.router.parent.navigate('dashboard');
    }
  }

  selectForm(form) {

    let assessmentId = form.assessmentId;

    if (!assessmentId) {
      return;
    }

    this.router.navigate(`performedassessment/${assessmentId}`);
  }

  openSearch() {

    this.dialogService.open({
      viewModel: ViewSearchDialog,
      model: this.searchCriteria
    }).whenClosed(response => {
      if (!response.wasCancelled) {

        this.searchCriteria = response.output;

        this.viewService.searchPerformedAssessments(this.sessionStore.get.organisation.organisationId, this.memberId, this.searchCriteria.fromDate, this.searchCriteria.toDate, this.searchCriteria.channel, this.searchCriteria.formName, this.searchCriteria.firstName, this.searchCriteria.surname);
      } else {
      }
    });
  }

  @handle(VIEW_ACTIONS.RETRIEVE_FORMS)
  @waitFor(ViewStore)
  handleRetrieveForms() {

    this.allItems = this.viewStore.forms;
    this.initPages();
  }

}
