import { inject, LogManager, computedFrom } from 'aurelia-framework';
import {autoinject} from "aurelia-dependency-injection";
import {CallTranscriptModel} from "../../../organisation/conversation/call-transcript-model";
import {DialogController} from 'aurelia-dialog';
import {UtteranceModel} from "./utterance-model";
import InteractionLogModel from "../interactionlog/interaction-log-model";
import {CallTranscriptWordModel} from "../../../organisation/conversation/call-transcript-word-model";
const logger = LogManager.getLogger('ViewInteractionTranscript');

@autoinject
export class ViewInteractionTranscriptDialog {
  private dialogController: DialogController;
  private transcript: CallTranscriptModel;
  private interaction: InteractionLogModel;
  private utterances: UtteranceModel[];
  private dialogHeader = 'Interaction Transcript';

  constructor(dialogController: DialogController) {
    this.dialogController = dialogController;
  }

  public activate(config: ZaiInteractionTranscript): void {
    this.transcript = config.transcript;
    this.interaction = config.interaction;
    this.formatTranscript(this.transcript.phrases);
  }

  private formatTranscript(callTranscript: CallTranscriptWordModel[]): String {
    this.utterances = [];
    let text = '';
    callTranscript.sort((a,b) => a.startTime - b.startTime);
    callTranscript.forEach(transcriptItem => {
          this.utterances.push(new UtteranceModel(transcriptItem.speakerName, transcriptItem.utterance, transcriptItem.startTime));
      }
    );
    this.utterances.forEach(utterance => {
        text += utterance.speaker + ': ' + utterance.words;
      }
    );
    return text;
  }


  public replaceSpeaker(speaker: string): string {
    let channel = this.interaction.formattedChannel;
    let to = this.interaction.formattedTo;
    let from = this.interaction.formattedFrom;
    if (speaker.toLowerCase() === 'customer') {
      if (channel === 'Outbound Call') {
        return to;
      } else {
        return from;
      }
    } else {
      return speaker;
    }
  }
  
  public cancel(): void {
    this.transcript = null;
    this.dialogController.cancel();
  }
}