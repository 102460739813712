import { inject, bindable, customElement, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { BusinessPartner } from '../../businesspartner/business-partner-model';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenter } from '../contact-center-model';
import { ContactCenterService } from '../contact-center-service';

const logger = LogManager.getLogger('ContactCenterForm');

@customElement('contact-center-form')
@inject(Router, Element, ContactCenterService, BusinessPartnerService, CurrentScopeValidation)
export class ContactCenterForm {

  @bindable({ attribute: 'contact-center' }) public contactCenter: ContactCenter;
  @bindable({ attribute: 'page-header' }) public pageHeader: string;
  @bindable({ attribute: 'is-processing' }) public isProcessing: boolean = false;
  @bindable({ attribute: 'disable-business-partner' }) public disableBusinessPartner: boolean = false;

  public businessPartners: BusinessPartner[];
  public selectBusinessPartner: HTMLSelectElement;
  private validation: ValidationController;

  constructor(
    private router: Router,
    private element: Element,
    private contactCenterService: ContactCenterService,
    private businessPartnerService: BusinessPartnerService,
    public validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public attached(): void {
    this.initDisplay();
    this.initValidationRules();
    this.retrieveBusinessPartners();
  }

  public cancel(): void {
    this.router.navigate('list');
  }

  public save(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      this.contactCenter.businessPartnerName = this.businessPartners.find((businessPartner) => {
        return businessPartner.id === this.contactCenter.businessPartnerId;
      }).name;
      this.element.dispatchEvent(new CustomEvent('save', { bubbles: true, detail: this.contactCenter }));
    }
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((contactCenter: ContactCenter) => contactCenter.name).required().maxLength(30).then().satisfies((name) => {
        return this.contactCenterService.findAll()
          .then((list) => {
            list = list || [];
            const existing = list.find((contactCenter) => contactCenter.name === name && contactCenter.contactCentreId !== this.contactCenter.contactCentreId);
            return !existing;
          });
      })
      .on(this.contactCenter);
  }

  private retrieveBusinessPartners(): void {
    this.businessPartnerService.findAll()
      .then((businessPartners) => {
        this.businessPartners = businessPartners || [];
      });
  }

  private initDisplay(): void {
    if (this.disableBusinessPartner) {
      this.selectBusinessPartner.disabled = true;
    }
  }
}