import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';
import { Event, SearchTools } from 'zailab.common';
import { ZListViewSupport } from './z-view';

const logger = LogManager.getLogger('ZListView');

@autoinject()
@customElement('z-list-view')
export class ZListView {

  @bindable
  public impl: any;

  @bindable
  public support: ZListViewSupport;

  constructor(
    private element: Element,
  ) { }

  public create(event: any): void {
    event.stopPropagation();
    new Event(this.element, 'create', { event });
  }

  public delete(event: any): void {
    event.stopPropagation();
    this.support.isDeleting = false;
    new Event(this.element, 'delete', { event });
  }
}