export class ThemeService {
  private static themeLocalStorageKey = 'zai-theme';
  private static darkThemePath = 'themes/dark-palette.css';
  private static lightThemePath = 'themes/light-palette.css';
  private static darkTheme = 'dark';
  private static lightTheme = 'light';

  public static loadLightTheme(saveLocally?: boolean): void {
    const injectedThemeTag = document.querySelector('#theme-tag');
    injectedThemeTag.setAttribute('href', this.lightThemePath);
    if (saveLocally) {
      localStorage.setItem(this.themeLocalStorageKey, this.lightTheme);
    }
  }

  public static loadDarkTheme(saveLocally?: boolean): void {
    const injectedThemeTag = document.querySelector('#theme-tag');
    injectedThemeTag.setAttribute('href', this.darkThemePath);
    if (saveLocally) {
      localStorage.setItem(this.themeLocalStorageKey, this.darkTheme);
    }
  }

  public getTheme(): 'light' | 'dark' {
    const storedTheme = localStorage.getItem(ThemeService.themeLocalStorageKey);
    if (storedTheme === ThemeService.darkTheme) {
      return ThemeService.darkTheme;
    } else if (storedTheme === ThemeService.lightTheme) {
      return ThemeService.lightTheme;
    }
    return ThemeService.darkTheme;
  }
}
