// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { SMSGatewayService } from '../sms-gateway-service';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('SMSGatewayConfigDialog');

@autoinject()
export class SMSGatewayConfigDialog {

  public dialogHeader = "Define the properties of your SMS gateway";
  public loading: boolean = false;
  public error: string;

  public gatewayNames: string[] = [];
  public gatewayId: string;
  public name: string = '';
  public currentName: string;
  public host: string = '';
  public port: string = '';

  private validation: ValidationController;

  constructor(
    private dialogController: DialogController,
    private SMSGatewayService: SMSGatewayService,
    private sessionStore: SessionStore,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(data: { gatewayNames: string[], gateway?: ISMSGateway }): void {
    this.gatewayNames = data.gatewayNames;
    if (data.gateway) {
      this.gatewayId = data.gateway.id;
      this.name = data.gateway.name || '';
      this.currentName = `${data.gateway.name}`;
      this.host = data.gateway.host || '';
      this.port = data.gateway.port || '';
    }

    ValidationRules
      .customRule('uniqueName', (newName) => {
        if (newName === this.currentName) {
          return true;
        }
        for (let name of this.gatewayNames) {
          if (name.toLowerCase() === newName.toLowerCase()) {
            return false;
          }
        }
        return true;
      }, 'Please enter a unique name.');

    ValidationRules
      .customRule('validPort', (value) => {
        return /^\d+$/.test(value) && value >= 0 && value <= 65535;
      }, 'The port number must be a valid number between 0 - 65535.');

    ValidationRules
      .ensure('name').required().satisfiesRule('uniqueName')
      .ensure('host').required()
      .ensure('port').required().then().satisfiesRule('validPort')
      .on(this);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public save(): void {
    this.error = null;
    this.loading = true;
    this.validation
      .validate()
      .then((validation) => {
        if (!validation.valid) {
          this.loading = false;
          return;
        }
        if (this.gatewayId) {
          const gateway = {
            id: this.gatewayId,
            name: this.name,
            host: this.host,
            port: this.port
          };
          this.SMSGatewayService
            .updateGateway(
              this.sessionStore.get.organisation.organisationId,
              gateway.id,
              gateway.name,
              gateway.host,
              parseInt(this.port)
            )
            .then(() => this.dialogController.ok(gateway))
            .catch(() => this.loading = false);
        } else {
          const gateway = {
            id: uuidv4(),
            name: this.name,
            host: this.host,
            port: parseInt(this.port)
          }
          this.SMSGatewayService
            .createGateway(
              this.sessionStore.get.organisation.organisationId,
              gateway.id,
              gateway.name,
              gateway.host,
              gateway.port
            )
            .then(() => this.dialogController.ok(gateway))
            .catch(() => this.loading = false);
        }
      });
  }
}

