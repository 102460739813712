import {HttpClient} from 'aurelia-http-client';
import {autoinject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

const logger = LogManager.getLogger('FlowVersionDialogService');

@autoinject
export class FlowVersionDialogService {

  constructor(
    private httpClient: HttpClient,
    private eventAggregator: EventAggregator
  ) {}

  public setLive(interactionFlowId: string, version: number): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/${version}/setlive`)
        .asPut()
        .send()
        .then(
          (response) => {
            try {
              resolve(response);
            } catch (error) {
              reject(error)
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public retrieveFlowsAssignedToSubflow(subflowId: string): Promise<any> {
    return this.httpClient.createRequest(`v1/interaction/interaction-flows/search/bySubflowId`)
      .asGet()
      .withParams({
        subflowId
      })
      .send();
  }

  public deleteFlowVersions(interactionFlowId: string, versions: number[]): Promise<any> {
    return this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlowId}/versions`)
      .asDelete()
      .withContent({ versionsToDelete: versions })
      .send();
  }
}
