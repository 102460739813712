import {LogManager} from 'aurelia-framework';

import {InteractionModel} from "../interaction-model";
import { TicketModel } from './ticket-model';

const logger = LogManager.getLogger('ProcessingLayer');

export class ProcessingLayer {

  private static readonly CALL_EVENTS = [
    'MemberStartedOutboundFlowInteraction', 'MemberConnectedToOutboundFlowInteraction', 'MemberDisconnectedFromOutboundFlowCall',
    'MemberNotifiedOfInboundFlowInteraction', 'MemberConnectedToInboundFlowInteraction', 'MemberDisconnectedFromInboundFlowCall',
    'MemberMissedInboundFlowInteraction', 'OutboundFlowEndpointNotified'
  ];
  private static readonly EVENTS = ['MemberStartedInteraction'];

  public static processInteraction(rawData: any): Promise<InteractionModel | TicketModel> {
    const processingCallback = resolve => {
      if (rawData && rawData.channel && rawData.channel.toLowerCase() === 'ticket') {
        let ticketModel = new TicketModel(rawData, true);
        return resolve(ticketModel);
      }
      rawData = this.processDataTypes(rawData);
      let interactionModel = new InteractionModel(rawData);
      resolve(interactionModel);
    };
    return new Promise(processingCallback);
  }

  private static processDataTypes(rawData: any): any {
    rawData.channel = rawData.channel ? rawData.channel.toLowerCase() : rawData.channel;
    rawData.interactionId = rawData.id || rawData._id || rawData.interactionId;

    if(rawData.payload) {
      rawData = Object.assign(rawData, rawData.payload);
    }
    if((rawData.type && rawData.type.toLowerCase() === 'campaign') || rawData.waypointType === 'CAMPAIGN' || rawData.taskInformation) {
      rawData.channel = 'campaign';
      if(rawData.taskId) {
        rawData.interactionId = rawData.taskId;
      }
    }
    if(rawData.type === 'NOTE') {
      rawData.channel = 'note';
    }
    if(rawData.type === 'REMINDER' || rawData.waypointType === 'REMINDER') {
      rawData.channel = 'reminder';
    }
    return rawData;
  }

  public static processServedInteraction(rawData: any): any {
    if (rawData && rawData.src === 'Ticket') {
      return new TicketModel(rawData);
    }
    return new InteractionModel(this.restructureData(rawData));
  }

  private static restructureData(data: any): any {
    let payload: { [k: string]: any } = {};

    payload.isServed = data.isServed;
    payload.type = data.type;
    payload.channel = this.determineChannel(data);
    payload.src = data.src;
    payload.dst = data.dst;
    payload.isTask = data.isTask;
    payload.payload = typeof data.payload === "string" ? data.payload : null;

    if(data.metaData) {
      payload.metaData = data.metaData;
    }

    if(data.payload) {
      if(data.payload.type === 'campaign') {
        payload.channel = 'campaign';
      }
      if(data.payload.type === 'callback') {
        payload.channel = 'callback';
      }

      if(data.payload.timestamp) {
        if (!payload.metaData) {
          payload.metaData = {};
        }
        payload.metaData.timestamp = data.payload.timestamp;
      }

      if(data.payload.interactionId) {
        payload.interactionId = data.payload.interactionId;
      } else if(data.payload.linkingData) {

        if(data.payload.linkingData.interactionId) {
          payload.interactionId = data.payload.linkingData.interactionId;
        }
      }

      if(data.payload.taskName) {
        payload.taskName = data.payload.taskName;
      } else if(data.payload.linkingData) {

        if(data.payload.linkingData.taskName) {
          payload.taskName = data.payload.linkingData.taskName;
        }
      }

      if(data.payload.sourceNumber) {
        payload.from = data.payload.sourceNumber;
      } else if(data.payload.linkingData) {

        if(data.payload.linkingData.sourceNumber) {
          payload.from = data.payload.linkingData.sourceNumber;
        }
      }

      if(data.payload.contact) {
        payload.contactId = data.payload.contact.id;
        payload.firstName = data.payload.contact.firstName;
        payload.surname = data.payload.contact.surname;
        payload.externalReference = data.payload.contact.externalReference;
      } else if(data.payload.linkingData) {

        if(data.payload.linkingData.contact) {
          payload.contactId = data.payload.linkingData.contact.id;
          payload.firstName = data.payload.linkingData.contact.firstName;
          payload.surname = data.payload.linkingData.contact.surname;
        }
      }

      if(data.payload.conversation && data.payload.conversation.name) {
        payload.conversationName = data.payload.conversation.name;
      }

      if(data.payload.metadata) {
        payload.correlationId = data.payload.metadata.correlationId;
        payload.metaData = data.payload.metadata;
        payload.numberOfContacts = data.payload.metadata.number_of_contacts;
      }

      if (data.payload.additionalProperties) {
        payload.additionalProperties = data.payload.additionalProperties;
      }
    }
    return payload;
  }

  private static determineChannel(interaction: any): string {
    if(interaction.type) {
      if (this.CALL_EVENTS.indexOf(interaction.type) >= 0) {
        return 'call';
      } else if (this.EVENTS.indexOf(interaction.type) >= 0) {
        if(interaction.payload && interaction.payload.payload) {
          if(interaction.payload.payload.channel) {
            return interaction.payload.payload.channel.toLowerCase();
          }
        }
      }
    }
    return interaction.src || (interaction.payload ? interaction.payload.channel : null);
  }
}
