import {LogManager} from 'aurelia-framework';
import {ChartSettings} from './chart-settings';
import {GraphConfig} from './graph-config';
import {GroupByModel} from './models/group-by-model';

const logger = LogManager.getLogger('ChartSettingsBuilder');

export class ChartSettingsBuilder {
  private chartSettings: ChartSettings;

  constructor() {
    this.chartSettings = new ChartSettings();
  }

  async withDataPoint(dataPoint: any): Promise<ChartSettingsBuilder> {
    this.chartSettings.dataProvider.push(dataPoint);
    await this.withCalculatedYAxisHeight();
    return this;
  }

  withMinimumPeriod(period: string): ChartSettingsBuilder {
    this.chartSettings.categoryAxis.minPeriod = this.defineMinimumPeriod(period);
    if(this.valueDisplaysMoreThanDefault(period) === false) {
      this.chartSettings.chartScrollbar.backgroundColor = 'transparent';
      this.chartSettings.chartScrollbar.backgroundAlpha = 0;
      this.chartSettings.chartScrollbar.selectedBackgroundColor = 'transparent';
      this.chartSettings.chartScrollbar.selectedBackgroundAlpha = 0;
    }
    return this;
  }

  withDateFormats(period: string): ChartSettingsBuilder {
    this.chartSettings.categoryAxis.dateFormats = this.generateDateFormat(period);
    return this;
  }

  valueDisplaysMoreThanDefault(period: string): boolean {

    let periodConfig = [
      new GroupByModel('Hour', true, false, 40, 24),
      new GroupByModel('Day', false, true, 120, 7),
      new GroupByModel('Week', false, true, 100, 14),
      new GroupByModel('Month', false, true, 110, 12)
    ];

    let currentPeriod = periodConfig.find(_period => {
      return _period.name === period;
    });

    return currentPeriod.name && this.chartSettings.dataProvider.length > currentPeriod.zoom;
  }


  private withCalculatedYAxisHeight(): void {

    let unorderedList = [];
    this.chartSettings.dataProvider.forEach(item => {
      let tempCount = 0;

      for(let key in item) {
        if(item.hasOwnProperty(key) && key !== 'date') {
          tempCount = tempCount + item[key];
        }
      }
      unorderedList.push(tempCount);
    });

    let orderedList = unorderedList.sort((a,b) => {
      return a-b;
    });

    let highestValue = orderedList[orderedList.length - 1];

    if(highestValue <= 10) {
      this.chartSettings.valueAxes[0].maximum = 10;
    } else {
      this.chartSettings.valueAxes[0].maximum = Math.ceil(highestValue / 10) * 10;
    }
  }

  withDataProvider(dataProvider: any[]): ChartSettingsBuilder {
    if(this.chartSettings.dataProvider.length > 0) {
      logger.warn('DataProvider already has data in it, likely withDataPoint was called already.  This will overide that data, make sure this is what you want')
    }
    this.chartSettings.dataProvider = dataProvider;
    return this;
  }

  withGraph(graph: GraphConfig): ChartSettingsBuilder {
    if(this.graphForCategoryDoesntExist(graph)) {
      this.chartSettings.graphs.push(graph);
    }
    return this;
  }

  private graphForCategoryDoesntExist(graph: GraphConfig): boolean {
    return this.chartSettings.graphs.findIndex(existingGraph => existingGraph.valueField === graph.valueField) === -1;
  }

  private defineMinimumPeriod(period: string): string {
    let minPeriod = 'hh';
    if (period === 'Day') {
      minPeriod = 'DD';
    } else if (period === 'Week') {
      minPeriod = 'WW';
    } else if (period === 'Month') {
      minPeriod = 'MM';
    }
    return minPeriod;
  }

  private generateDateFormat(period: string): any[] {

    let hourFormat = {period: 'hh', format: 'JJ:NN'};
    let dayFormat = {period: 'DD', format: `${period === 'Week' ? `week W` : `EEE, DD MMM`}`};
    let weekFormat = {period: 'WW', format: 'week W'};
    let monthFormat = {period: 'MM', format: `${period === 'Week' ? 'week W' : 'MMM YYYY'}`};
    let yearFormat = {period: 'YYYY', format: `${period === 'Month' ? 'MMM YYYY' : 'YYYY'}`};

    if (period === 'Month') {
      return [dayFormat, monthFormat, yearFormat];
    } else if (period === 'Week') {
      return [hourFormat, dayFormat, weekFormat, monthFormat, yearFormat];
    } else if (period === 'Day') {
      return [dayFormat, weekFormat, monthFormat, yearFormat];
    } else {
      return [hourFormat, dayFormat, weekFormat, monthFormat];
    }
  }

  public build(): ChartSettings {
    return this.chartSettings;
  }

}