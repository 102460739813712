import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';
import { InteractionModel } from '../../interaction-model';
import { InteractionService } from '../../interaction/interaction-service';
import moment from 'moment';
import {
  ValidationControllerFactory,
  ValidationController,
  ValidationRules,
  validateTrigger,
} from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';

import { FeatureFlagService, MESSAGE_EVENTS, SessionStore } from 'zailab.common';
import { computedFrom } from 'aurelia-binding';
import { InteractionService as OrganisationInteractionService } from 'features/organisation/interactions/interaction-service';
import { MemberInteractionModel } from '../../interaction/member-interaction-model';

const logger = LogManager.getLogger('CampaignInteractionCard');

@customElement('z-campaign-interaction-card')
@autoinject
export class CampaignInteractionCard {
  @bindable private interaction: InteractionModel;

  public dateTime = {
    date: null,
    hour: 0,
    minute: 0,
  };

  public siyaCustom: boolean;
  private connected: boolean;

  constructor(
    validationControllerFactory: ValidationControllerFactory,
    private validationController: ValidationController,
    private eventAggregator: EventAggregator,
    private interactionService: InteractionService,
    private organisationInteractionService: OrganisationInteractionService,
    private featureFlagService: FeatureFlagService,
    private sessionStore: SessionStore,
  ) {
    this.interactionService = interactionService;

    this.validationController =
      validationControllerFactory.createForCurrentScope();
    this.validationController.validateTrigger = validateTrigger.changeOrBlur;
    this.initValidation();
  }

  public attached(): void {    
    this.featureFlagService.isEnabled('siyaCustom')
      .then(async (siyaCustom) => {
        this.siyaCustom = siyaCustom;
      });
    this.organisationInteractionService.retrieveConnectedInteractions(this.sessionStore.get.user.memberId)
      .then((memberInteractions: MemberInteractionModel[]) => {
        if (memberInteractions && memberInteractions.length) {
          const connectInteraction = memberInteractions.find((interaction) => interaction.interactionId === this.interaction.interactionId);
          if (connectInteraction) {
            this.connected = true;
          }
        }
      });
  }

  public reschedule(): void {
    let dateTime = moment(this.dateTime.date).toDate();
    dateTime.setHours(this.dateTime.hour);
    dateTime.setMinutes(this.dateTime.minute);

    this.interactionService.rescheduleTask(this.interaction, dateTime);
    this.eventAggregator.publish(
      MESSAGE_EVENTS.SUCCESS,
      'Task has been rescheduled to' + dateTime + '.'
    );
    this.dateTime.date = null;
    this.dateTime.hour = 0;
    this.dateTime.minute = 0;
  }

  public end(): void {
    if (this.connected) {
      this.interactionService.endTask(this.interaction);
    }
  }

  private initValidation(): void {
    const dateTimeRules = ValidationRules.ensure('date').required().rules;
    this.validationController.addObject(this.dateTime, dateTimeRules);
    this.validationController.validate();
  }

  @computedFrom('interaction.channelIcon')
  public get reschedulable(): boolean {
    return (
      this.interaction.channelIcon === 'campaign' && !this.interaction.taskEnded
    );
  }
}
