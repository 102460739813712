import {EventAggregator} from 'aurelia-event-aggregator';
import {inject, LogManager, Factory} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import { v4 as uuidv4 } from 'uuid';
/*
 */
import {
  CommandFactory,
  WebSocket,
  SessionStore,
  OplogService,
  DownloadService,
  ApplicationProperties
} from 'zailab.common';
import {SurveyListModel} from './survey-list-model';
import {SurveyModel} from './survey/survey-model';
/*
 */
const logger = LogManager.getLogger('SurveyService');

/*
 */
@inject(HttpClient, WebSocket, EventAggregator, Factory.of(CommandFactory), SessionStore, OplogService, DownloadService, ApplicationProperties)
export class SurveyService {

  constructor(httpClient, webSocket, eventAggregator, commandFactory, sessionStore, oplogService, downloadService, applicationProperties) {
    this.httpClient = httpClient;
    this.webSocket = webSocket;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
    this.downloadService = downloadService;
    this.applicationProperties = applicationProperties;
  }

  displaySurvey(surveyId) {

    return new Promise((resolve, reject) => {

      let params = {surveyId: surveyId};
      this.httpClient.createRequest(`/v1/organisation/surveys/${surveyId}`)
        .asGet()
        .withParams(params)
        .send()
        .then(response => {
          let survey = this._modelEntity(response);
          resolve(survey);
        }, error => {
          reject(error);
        });
    });
  }

  retrieveSurveysStats(organisationId) {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('/v1/organisation/surveys/widget')
        .asGet()
        .withParams({organisationId})
        .send()
        .then((response) => {
          let _count = 0;
          if (response.surveys) {
            _count = response.surveys;
          }
          resolve(_count);
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseStatsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('quality-assurance-projector.surveyWidgetView'));
    });
  }

  retrieveSurveyList() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('/v1/organisation/surveys')
        .asGet()
        .send()
        .then(response => {
          let surveyList = [];
          if (response.surveyTemplates) {
            surveyList = this._modelCollection(response.surveyTemplates);
          }
          resolve(surveyList);
        }, error => {
          reject(error);
        });
    });
  }

  initialiseSurveysOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('quality-assurance-projector.surveyTemplatesView'));
    });
  }

  retrieveExistingAudioFiles(organisationId) {
    return new Promise((resolve, reject) => {

      var uri = `/v1/media/audio/search?fileName=&audioGroupId=&workflowId=`;

      this.httpClient.createRequest(uri)
        .asGet()
        .withParams({organisationId: organisationId})
        .send()
        .then(response => {
          let audioFileList = [];
          if (!response) {
            resolve(audioFileList);
          } else {
            response.forEach(audioFile => {
              audioFileList.push(audioFile);
            });
            resolve(audioFileList);
          }
        }, error => {
          reject(error);
        });
    });
  }

  deleteSurvey(surveyId, organisationId) {
    this.httpClient.createRequest(`/v1/organisation/surveys/${surveyId}`)
      .asDelete()
      .send();
  }

  uploadAudioFile(file, audioId, organisationId) {

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('data', file);
      formData.append('filename', file.name);
      this.httpClient.createRequest(`v1/media/audio/${audioId}`)
        .asPost()
        .withContent(formData)
        .send()
        .then(response => {
          resolve(audioId);
        }, error => {
          reject(error);
        });

    });

  }

  publishSurvey(survey, organisationId) {
    let commandPayload = {
      surveyId: survey.surveyId,
      organisationId: organisationId,
      surveyName: survey.surveyName,
      serviceId: '',
      description: survey.description,
      type: survey.type,
      questions: survey.questions,
      outcome: survey.outcome
    };
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/surveys')
        .asPost()
        .withContent(commandPayload)
        .send()
        .then(resolve(true));
    });
  }

  retrieveTextToSpeech(text, speechLanguage, speechRate) {
    return new Promise((resolve, reject) => {

      let host = this.applicationProperties.apiQueryEndpoint;
      let textToSpeechId = uuidv4();
      let uri = `${host}v1/media/textToSpeech/${textToSpeechId}`;
      let auth = 'Bearer ' + this.sessionStore.get.user.token;
      let payload = {text, speechLanguage, speechRate};
      let xhr = new XMLHttpRequest();

      xhr.open('POST', uri, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', auth);
      xhr.responseType = 'blob';

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {

            let textToSpeechURI = URL.createObjectURL(xhr.response);

            resolve({textToSpeechURI, textToSpeechId});

          } else {
            reject(xhr.statusText);
          }
        }
      };

      xhr.send(JSON.stringify(payload));

    });
  }

  generateTextToSpeech(textMessage, languageCode, speechRate) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('audio/textToSpeech')
        .asPost()
        .withParams({text: textMessage, speechLanguage: languageCode, speechRate: speechRate})
        .send()
        .then(response => {
          resolve('Text to Speech generated successfully');
        }, error => {
          reject(error);
        });
    });
  }


  _modelCollection(list) {
    let _list = [];
    list.forEach(item => {
      _list.push(this._modelEntity(item));
    });
    return _list;
  }

  _modelEntity(item) {
    return new SurveyModel(item);
  }

  _convertToBlob(response) {
    var format = 'audio/wav';
    var binary = atob(response);
    var byteNumbers = new Array(binary.length);

    for (var i = 0; i < binary.length; i++) {
      byteNumbers[i] = binary.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: format
    });
    return blob;
  }

}
