import { LogManager, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {LdapSyncModel} from "../models/LdapSyncModel";
/**/
const logger = LogManager.getLogger('LdapSyncDialog');

@autoinject
export class LdapSyncDialog {
  private ldap: LdapSyncModel;

  constructor(private dialogController: DialogController) {
  }

  public async activate(ldap: LdapSyncModel): Promise<void> {
    if (ldap) {
      this.ldap = ldap;
    } else {
      this.ldap = new LdapSyncModel();
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public ok(): void {
    this.dialogController.ok(this.ldap)
  }

}