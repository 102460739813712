import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
//@ts-ignore
import { PLATFORM } from 'aurelia-pal';

import { SessionStore, SESSION_EVENTS } from 'zailab.common';
import { CreatedOrganisation } from '../../_manual/createdorganisation/created-organisation';
import OrganisationService from '../../organisation/organisation/organisation-service';
import { OrganisationSessionModel } from './../../../_common/stores/sessionmodels/organisation-session-model';
import { DashboardModel } from '../dashboard-model';
import { DashboardConfigBuilder } from '../dashboard-config-builder';
import { WidgetModel } from '../widget-model';
import { FeatureFlagService } from '../../featureflags/feature-flag-service';

declare const PLATFORM: any;

const logger = LogManager.getLogger('AdministratorDashboard');

interface PlaceHolder {
  default: number;
  crm: number;
  dispositions: number;
  contactFields: number;
  all: number;
}
const RESOLUTIONS: Array<{ value: number; placeholders: PlaceHolder }> = [
  {
    value: 0,
    placeholders: {
      default: 0,
      crm: 0,
      dispositions: 0,
      contactFields: 0,
      all: 0,
    },
  },
  {
    value: 768,
    placeholders: {
      default: 1,
      crm: 0,
      dispositions: 0,
      contactFields: 0,
      all: 3,
    },
  },
  {
    value: 1280,
    placeholders: {
      default: 1,
      crm: 0,
      dispositions: 0,
      contactFields: 0,
      all: 7,
    },
  },
];

@autoinject
export class AdministratorDashboard {
  private setupComplete = false;
  private dashboardModel: DashboardModel;
  private crmEnabled = false;
  private fixedPricing = false;
  private accountDisabled = false;
  private vodacomMonitoring = false;
  private autoLogoutEnabled = false;
  private presenceCodesConfigEnabled = false;
  private agentMatchingDashboard = false;
  private inAppAlerts = false;
  private inHouseWFMConfigEnabled = false;
  private agentScriptAdminConfigEnabled = false;
  private placeholderAmount: number;

  constructor(
    private dialogService: DialogService,
    private sessionStore: SessionStore,
    private featureFlagService: FeatureFlagService,
    private organisationService: OrganisationService,
    private eventAggregator: EventAggregator
  ) {}

  private async activate(): Promise<void> {
    await this.setupAdminDashboardConfig();
    if (this.organisation && this.organisation.isNew) {
      const organisationId = this.organisation.organisationId;
      this.setupComplete = organisationId ? true : false;
      this.showManual();
    }
    this.modifyAdminDashboardBodyClass();
  }

  public attached(): void {
    this.attachWindowResizeHandler();
    this.gridResized({ width: window.innerWidth });
  }

  private modifyAdminDashboardBodyClass(): void {
    const adminDashboardClass = 'admin-dashboard-sizing';
    const pageBody = document.querySelector('html');
    pageBody.classList.add(adminDashboardClass);
  }

  private hasUserJustCreatedAnOrganisation(): void {
    if (this.organisation) {
      if (!this.organisation.isNew) {
        return;
      }
      this.showManual();
    }
  }

  private showManual(): void {
    this.dialogService
      .open({
        viewModel: CreatedOrganisation,
        model: {},
      })
      .whenClosed(() => {
        this.closeAllDialogs();
        this.organisation.isNew = false;
        this.eventAggregator.publish(
          SESSION_EVENTS.ORGANISATION.EVENT,
          this.organisation
        );
        this.eventAggregator.publish('tutorials.activate.ready');
      });
  }

  private closeAllDialogs(): void {
    if (
      this.dialogService.controllers &&
      this.dialogService.controllers.length > 0
    ) {
      this.dialogService.controllers.forEach((controller) => {
        controller.cancel();
      });
    }
  }

  private activateTutorial(): void {
    this.setupComplete = true;
    this.hasUserJustCreatedAnOrganisation();
  }

  private async setupAdminDashboardConfig(): Promise<void> {
    await this.configureFeatureFlags();

    this.dashboardModel = new DashboardConfigBuilder()
      .withWidget(
        new WidgetModel(
          'Invitations',
          PLATFORM.moduleName(
            '../../user/invitation/invitations/dashboardwidget/invitations-dashboard-widget'
          ),
          'widget-invitations',
          false,
          this.fixedPricing,
          !this.fixedPricing,
          2,
          2
        )
      )
      .withWidget(
        new WidgetModel(
          'Work Types',
          PLATFORM.moduleName(
            '../../organisation/conversation/worktypes/dashboardwidget/work-types-dashboard-widget'
          ),
          'widget-worktypes',
          false,
          false,
          true,
          2,
          2
        )
      )
      .withWidget(
        new WidgetModel(
          'Sites',
          PLATFORM.moduleName(
            '../../organisation/organisation/sites/dashboardwidget/sites-dashboard-widget'
          ),
          'widget-sites',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Services',
          PLATFORM.moduleName(
            '../../organisation/organisation/services/dashboardwidget/services-dashboard-widget'
          ),
          'widget-services',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Account',
          PLATFORM.moduleName(
            '../../finance/account/dashboardwidget/account-dashboard-widget'
          ),
          'widget-account',
          false,
          this.fixedPricing,
          !this.fixedPricing && !this.accountDisabled,
          2,
          2
        )
      )
      .withWidget(
        new WidgetModel(
          'Skills',
          PLATFORM.moduleName(
            '../../organisation/organisation/skills/dashboardwidget/skills-dashboard-widget'
          ),
          'widget-skills',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Teams',
          PLATFORM.moduleName(
            '../../organisation/member/teams/dashboardwidget/teams-dashboard-widget'
          ),
          'widget-teams',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Members',
          PLATFORM.moduleName(
            '../../organisation/member/members/dashboardwidget/members-dashboard-widget'
          ),
          'widget-members',
          false,
          false,
          true,
          2,
          2
        )
      )
      .withWidget(
        new WidgetModel(
          'Work Flows',
          PLATFORM.moduleName(
            '../../interaction/interactionFlow/dashboardwidget/work-flows-dashboard-widget'
          ),
          'widget-workflows',
          false,
          false,
          true,
          2,
          2
        )
      )
      .withWidget(
        new WidgetModel(
          'Surveys',
          PLATFORM.moduleName(
            '../../organisation/qualityassurance/surveys/dashboardwidget/surveys-dashboard-widget'
          ),
          'widget-surveys',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Numbers',
          PLATFORM.moduleName(
            '../../finance/telephonenumbers/dashboardwidget/telephone-numbers-dashboard-widget'
          ),
          'widget-numbers',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Interaction Log',
          PLATFORM.moduleName(
            '../../interaction/call/interactionlog/dashboardwidget/interaction-logs-dashboard-widget'
          ),
          'widget-interactionlog',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Reports',
          PLATFORM.moduleName(
            '../../organisation/performancemanagement/reports/reports-widget'
          ),
          'widget-reports',
          false,
          false,
          true,
          this.fixedPricing ? 2 : 1,
          this.fixedPricing ? 2 : 1
        )
      )
      .withWidget(
        new WidgetModel(
          'Report Scheduler',
          PLATFORM.moduleName(
            '../../organisation/reportschedule/widget/report-schedule-widget'
          ),
          'widget-report-schedule',
          false,
          false,
          true,
          this.fixedPricing ? 2 : 1,
          this.fixedPricing ? 2 : 1
        )
      )
      .withWidget(
        new WidgetModel(
          'Analytics',
          PLATFORM.moduleName(
            '../../organisation/analytics/widget/analytics-widget'
          ),
          'analytics-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Business Partner',
          PLATFORM.moduleName(
            '../../organisation/businesspartner/widget/business-partner-widget'
          ),
          'business-partner-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Contact Center',
          PLATFORM.moduleName(
            '../../organisation/contactcenter/widget/contact-center-widget'
          ),
          'contact-center-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Alert',
          PLATFORM.moduleName('../../organisation/alert/widget/alert-widget'),
          'alert-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Audio',
          PLATFORM.moduleName(
            '../../media/media/audiofiles/dashboardwidget/audio-dashboard-widget'
          ),
          'widget-audio',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Mailbox',
          PLATFORM.moduleName(
            '../../mailbox/dashboardwidget/mailbox-dashboard-widget'
          ),
          'widget-mailbox',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Canned Responses',
          PLATFORM.moduleName(
            '../../cannedresponses/widget/canned-responses-widget'
          ),
          'widget-canned-responses',
          false,
          false,
          true,
          this.fixedPricing ? 2 : 1,
          this.fixedPricing ? 2 : 1
        )
      )
      .withWidget(
        new WidgetModel(
          'Dispositions',
          PLATFORM.moduleName(
            '../../organisation/organisation/dispositioncodes/dashboardwidget/disposition-codes-dashboard-widget'
          ),
          'widget-disposition-codes',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Contact Fields',
          PLATFORM.moduleName(
            '../../organisation/organisation/contactfields/dashboardwidget/contact-fields-dashboard-widget'
          ),
          'widget-custom-fields',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'CRM Integration',
          PLATFORM.moduleName('../../integrations/widget/integrations-widget'),
          'widget-integrations',
          false,
          !this.crmEnabled,
          this.crmEnabled,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Instant Messaging',
          PLATFORM.moduleName('../../im/widget/im-widget'),
          'widget-im',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Emergency Announcement',
          PLATFORM.moduleName(
            '../../interaction/emergencyAnnouncement/widget/emergency-announcement-widget'
          ),
          'widget-announcement',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Load Balancer',
          PLATFORM.moduleName(
            '../../interaction/loadbalancer/widget/load-balancer-widget'
          ),
          'widget-load-balance',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Transfer List',
          PLATFORM.moduleName(
            '../../organisation/transferlist/widget/transfer-list-widget'
          ),
          'transfer-list-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Safelist',
          PLATFORM.moduleName(
            '../../organisation/bypass-cos/widget/bypass-cos-widget'
          ),
          'bypass-cos-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Do Not Contact',
          PLATFORM.moduleName(
            '../../organisation/donotcontact/donotcontact-widget'
          ),
          'donotcontact-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'API Lookup Monitoring',
          PLATFORM.moduleName('../../monitoring/widget/monitoring-widget'),
          'monitoring-widget',
          false,
          false,
          this.vodacomMonitoring,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Post Interaction Processing',
          PLATFORM.moduleName(
            '../../organisation/organisation/post-interaction-processing/widget/post-interaction-processing-widget'
          ),
          'post-interaction-processing-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Interaction Log Filters',
          PLATFORM.moduleName(
            '../../interaction/call/interactionlog/searchablevariables/dashboardwidget/searchable-variables-dashboard-widget'
          ),
          'searchable-variables-dashboard-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'SMS Gateways',
          PLATFORM.moduleName(
            '../../organisation/organisation/sms-gateways/widget/sms-gateways-widget'
          ),
          'sms-gateways-dashboard-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Auto Logout',
          PLATFORM.moduleName(
            '../../organisation/organisation/auto-logout/widget/auto-logout-dashboard-widget'
          ),
          'auto-logout-dashboard-widget',
          false,
          false,
          this.autoLogoutEnabled,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Presence',
          PLATFORM.moduleName(
            '../../organisation/organisation/presence-codes/widget/presence-codes-widget'
          ),
          'presence-codes-dashboard-widget',
          false,
          false,
          this.presenceCodesConfigEnabled,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Waiting Room Configuration',
          PLATFORM.moduleName(
            '../../organisation/interactions/waiting-room-configuration/widget/waiting-room-configuration-widget'
          ),
          'waiting-room-configuration-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Webchat',
          PLATFORM.moduleName(
            '../../organisation/webchat/dashboard-widget/webchat-widget'
          ),
          'webchat-widget',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Agent Matching Dashboard',
          PLATFORM.moduleName(
            '../../task/agent-matching/widget/agent-matching-widget'
          ),
          'webchat-widget',
          false,
          false,
          this.agentMatchingDashboard,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'In App Alerts',
          PLATFORM.moduleName(
            '../../organisation/in-app-alerts/widget/in-app-alerts-widget'
          ),
          'in-app-alerts',
          false,
          false,
          this.inAppAlerts,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'WFM',
          PLATFORM.moduleName(
            '../../organisation/workforcemanagement/in-house-wfm/dashboard-widget/wfm-widget'
          ),
          'webchat-widget',
          false,
          false,
          this.inHouseWFMConfigEnabled,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Agent Scripts',
          PLATFORM.moduleName(
            '../../quality-assurance/agent-script/admin-config/agent-script-admin-config-widget'
          ),
          'agent-script-admin-config-widget',
          false,
          false,
          this.agentScriptAdminConfigEnabled,
          1,
          1
        )
      )
      .withWidget(
        new WidgetModel(
          'Assistant',
          PLATFORM.moduleName(
            '../../interaction/interactionFlow/assistants/assistant-dashboard-widget/assistant-widget'
          ),
          'assistant',
          false,
          false,
          true,
          1,
          1
        )
      )
      .withTitle('administration dashboard')
      .build();
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  private async configureFeatureFlags(): Promise<void> {
    this.crmEnabled = await this.featureFlagService.isEnabled('CRM');
    this.fixedPricing = await this.featureFlagService.isEnabled(
      'fixed-pricing'
    );
    this.accountDisabled = await this.featureFlagService.isEnabled(
      'AccountDisabled'
    );
    this.vodacomMonitoring = await this.featureFlagService.isEnabled(
      'vodacomMonitoring'
    );
    this.autoLogoutEnabled = await this.featureFlagService.isEnabled(
      'autoLogoutConfig'
    );
    this.presenceCodesConfigEnabled = await this.featureFlagService.isEnabled(
      'PresenceCodesConfig'
    );
    this.agentMatchingDashboard = await this.featureFlagService.isEnabled(
      'AgentMatchingDashboard'
    );
    this.inAppAlerts = await this.featureFlagService.isEnabled('InAppAlerts');
    this.inHouseWFMConfigEnabled = await this.featureFlagService.isEnabled(
      'InHouseWFM'
    );
    this.agentScriptAdminConfigEnabled = await this.featureFlagService.isEnabled('agentScriptAdminConfig');
  }

  private attachWindowResizeHandler(): void {
    window.addEventListener('resize', (e) =>
      this.gridResized({ width: window.innerWidth })
    );
  }

  private removeWindowResizeHandler(): void {
    window.removeEventListener('resize', (e) =>
      this.gridResized({ width: window.innerWidth })
    );
  }

  private gridResized = (_detail: any): void => {
    let placeholderCount: number = 0;
    RESOLUTIONS.forEach((_value, index) => {
      const width: number = _value.value;
      const placeHolders: PlaceHolder = _value.placeholders;

      if (_detail.width >= width) {
        placeholderCount = placeHolders.default;
        if (this.crmEnabled) {
          placeholderCount = placeHolders.all - 1;
        } else {
          placeholderCount = placeHolders.dispositions;
        }
      }
    });

    if (this.placeholderAmount === placeholderCount) {
      return;
    }
    this.placeholderAmount = placeholderCount;
  };

  public detached(): void {
    this.removeWindowResizeHandler();

    let adminDashboardClass = 'admin-dashboard-sizing';
    let pageBody = document.querySelector('html');
    pageBody.classList.remove(adminDashboardClass);
  }
}
