import { LogManager } from "aurelia-framework";
import { ZGridNodesItem, ZGridNodesItemConnector } from "components/organisms/gridbasic/z-grid-basic-model";

const logger = LogManager.getLogger('AgentScriptDesignerModel');

export class AgentScriptFlow {

  public id: string;
  public startNodeId: string;
  public nodes: AgentScriptNode[];
  public connections: AgentScriptConnection[];

  constructor(definition: any) {
    if (!definition) {
      return;
    }
    this.startNodeId = definition.startNodeId;
    this.nodes = definition.nodes.map((node) => {
      return new AgentScriptNode(node);
    });
    this.connections = definition.connections.map((conn) => {
      return new AgentScriptConnection(conn);
    });
  }

  public getStartNode(): AgentScriptNode {
    return this.nodes.find((node) => node.id === this.startNodeId);
  }

  public getNodeConnections(node: AgentScriptNode): AgentScriptConnection[] {
    return this.connections.filter((conn) => conn.sourceNodeId === node.id);
  }

  public getConnection(connId: string): AgentScriptConnection {
    return this.connections.find((conn) => conn.id === connId);
  }

  public addNode(node: AgentScriptNode): void {
    if (!this.nodes) {
      this.nodes = [];
    }
    this.nodes.push(node);
  }

  public removeNode(nodeId: string): void {
    if (!this.nodes) {
      this.nodes = [];
    }
    const nodeIndex = this.nodes.findIndex((node) => node.id === nodeId);
    this.nodes.splice(nodeIndex, 1);
  }

  public getNode(id: string): AgentScriptNode {
    if (!this.nodes || !this.nodes.length) {
      return null;
    }
    return this.nodes.find((node) => node.id === id);
  }

  public getNodeAt(x: number, y: number): AgentScriptNode {
    if (!this.nodes || !this.nodes.length) {
      return null;
    }
    return this.nodes.find((node) => node.posX === x && node.posY === y);
  }

  public moveConnection(id: string, x: number, y: number): void {
    const targetNode = this.getNodeAt(x, y);
    this.connections.find((conn) => conn.id === id).destinationNodeId = targetNode.id;
  }

  public addConnection(conn: AgentScriptConnection): void {
    if (!this.connections) {
      this.connections = [];
    }
    const index = this.connections.findIndex((connection) => connection.id === conn.id);
    if (index < 0) {
      this.connections.push(conn);
    } else {
      this.connections.splice(index, 1, conn);
    }
  }

  public addConnections(conns: AgentScriptConnection[]): void {
    if (!this.connections) {
      this.connections = [];
    }
    this.connections = this.connections.concat(conns);
  }

  public removeConnection(connId: string): void {
    const index = this.connections.findIndex((connection) => connection.id === connId);
    if (index >= 0) {
      this.connections.splice(index, 1);
    }
  }

  public removeConnections(nodeId: string): void {
    const conns = this.connections.filter((connection) => connection.sourceNodeId === nodeId);
    for (const conn of conns) {
      const index = this.connections.findIndex((connection) => connection.id === conn.id);
      if (index >= 0) {
        this.connections.splice(index, 1);
      }
    }
  }

  public removeTargetConnections(nodeId: string): void {
    const conns = this.connections.filter((connection) => connection.destinationNodeId === nodeId);
    for (const conn of conns) {
      conn.destinationNodeId = null;
    }
  }
}

export class AgentScriptNode implements ZGridNodesItem {

  public id: string;
  public name: string;
  public text: string;
  public connectionIds: string[];
  public x: number;
  public y: number;
  public draggingConnectorIndex: number;
  public startNode: boolean;
  public connections: AgentScriptConnection[];
  public connectors: ZGridNodesItemConnector[];

  public defined = true;
  public visible = true;
  public dragging = false;
  public draggingConnector = false;

  constructor(data?: any) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.name = data.name;
    this.text = data.text;
    this.connectionIds = data.connectionIds;
    this.x = data.coordinates.x;
    this.y = data.coordinates.y;
  }

  public get posX(): number {
    return this.x;
  }

  public set posX(x: number) {
    this.x = x;
  }

  public get posY(): number {
    return this.y;
  }

  public set posY(y: number) {
    this.y = y;
  }
}

export class AgentScriptConnection {

  public id: string;
  public name: string;
  public sourceNodeId: string;
  public destinationNodeId: string;

  constructor(data?: any) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.name = data.name;
    this.sourceNodeId = data.sourceNodeId;
    this.destinationNodeId = data.destinationNodeId;
  }
}
