import { LogManager, customElement, bindable } from 'aurelia-framework';

const logger = LogManager.getLogger('ZStatStacked');

@customElement('z-stat-stacked')
export class ZStatStacked {
  @bindable stats: string;
  @bindable label: string;
  @bindable icon: string;
  @bindable isbutton: boolean;
  @bindable imagesize: string;
  @bindable disable: boolean;
  @bindable inherit: boolean;
  @bindable({ attribute: 'has-counter' }) hascounter: boolean;
  @bindable({ attribute: 'simple-content' }) simplecontent: any;
  @bindable({ attribute: 'has-icon-content' }) hasiconcontent: boolean;
  @bindable({ attribute: 'has-spacing' }) hasspacing: boolean;
  @bindable({ attribute: 'icon-count' }) iconcount: string;
  @bindable({ attribute: 'divider-color' }) dividercolor: string;
  @bindable({ attribute: 'text-size' }) textsize = 'xlarge';
  @bindable({ attribute: 'padding-size' }) paddingsize = '';

  get simpleContent(): boolean {
    if (!this.simplecontent && this.simplecontent !== 0) {
      return false;
    }
    return true;
  }
}
