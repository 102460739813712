import { LogManager } from 'aurelia-framework';
import { computedFrom } from "aurelia-binding";
import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('DiallingRuleModel');

export class DiallingRuleModel extends BaseModel  {

  constructor(
    public outcome: string,
    public requeueTime: number,
    public maxAttempts: number,
    public attempts?: { requeueDelay: number, attempt: number }[]
  ){
    super();
    if (this.requeueTime) {
      this.setupAttempts();
    } else if(!this.requeueTime && !this.attempts) {
      this.requeueTime = 1;
      this.setupAttempts();
    }
  }

  private setupAttempts(): void {
    if (!this.attempts) {
      this.attempts = [];
    }
    for (let index = 0; index < this.maxAttempts; index++) {
      this.attempts.push({
        requeueDelay: this.requeueTime <= 8 ? this.requeueTime * 60 : this.requeueTime,
        attempt: index + 1
      });
    }
    delete this.requeueTime;
  }

  @computedFrom('attempts', 'attempts.length')
  get time(): string {
    if (!this.attempts || this.attempts.length === 0) {
      return '';
    }
    return this.timeConvert(this.attempts[0].requeueDelay);
  }

  private timeConvert(n: number): string {
    let num = n;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + 'h ' + rminutes + 'm';
  }
}