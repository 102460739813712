import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

import { ConfigMapper, CHANNELS } from './config-mapper';

const logger = LogManager.getLogger('InteractionTodayStats');

@autoinject
export class InteractionTodayStats {

  constructor(private configMapper: ConfigMapper) {}

  public columnConfig = {
    // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ｔｏｔａｌ Ｒｅｃｅｉｖｅｄ
    'Tot Rec': {
      tooltip: 'Total Received Interactions',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'received'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions',
        expandCollapseHeader: true
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Tot Rec', this.configMapper.getTotalTodayStats(c, 'received'))
    },
    'Rec Calls': {
      tooltip: 'Calls Received',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'received'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Rec Calls', this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'received'))
    },
    'Rec Chats': {
      tooltip: 'Chats Received',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'received'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Rec Chats', this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'received'))
    },
    'Rec Emails': {
      tooltip: 'Emails Received',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'received'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Rec Emails', this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'received'))
    },
    'Rec IMs': {
      tooltip: 'Instant Messages Received',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'received'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Rec IMs', this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'received'))
    },
    'Rec SMS': {
      tooltip: 'SMS Received',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.SMS, 'received'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Rec SMS', this.configMapper.getTodayStats(c, CHANNELS.SMS, 'received'))
    },
    'Rec Tickets': {
      tooltip: 'Tickets Received',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'received'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Total Received Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Rec Tickets', this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'received'))
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ｔｏｔａｌ Ｈａｎｄｌｅｄ
    'HDL Calls': {
      tooltip: 'Calls Handled',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'handled'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('HDL Calls', this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'handled'))
    },
    'HDL Chats': {
      tooltip: 'Chats Handled',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'handled'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('HDL Chats', this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'handled'))
    },
    'HDL Emails': {
      tooltip: 'Emails Handled',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'handled'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('HDL Emails', this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'handled'))
    },
    'HDL IMs': {
      tooltip: 'Instant Messages Handled',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'handled'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('HDL IMs', this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'handled'))
    },
    'HDL SMS': {
      tooltip: 'SMS Handled',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.SMS, 'handled'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('HDL SMS', this.configMapper.getTodayStats(c, CHANNELS.SMS, 'handled'))
    },
    'HDL Tickets': {
      tooltip: 'Tickets Handled',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'handled'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('HDL Tickets', this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'handled'))
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ｔｏｔａｌ Ａｂａｎｄｏｎｅｄ
    'ABND': {
      tooltip: 'Abandoned',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'abandoned'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Abandoned',
        expandCollapseHeader: true
      },
      style: c => '" style="' + this.configMapper.getColourConfig('ABND', this.configMapper.getTotalTodayStats(c, 'abandoned'))
    },
    'ABND Calls': {
      tooltip: 'Calls Abandoned',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'abandoned'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Abandoned'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('ABND Calls', this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'abandoned'))
    },
    'ABND Chats': {
      tooltip: 'Chats Abandoned',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'abandoned'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Abandoned'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('ABND Chats', this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'abandoned'))
    },
    'ABND Emails': {
      tooltip: 'Emails Abandoned',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'abandoned'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Abandoned'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('ABND Emails', this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'abandoned'))
    },
    'ABND IMs': {
      tooltip: 'Instant Messages Abandoned',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'abandoned'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Abandoned'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('ABND IMs', this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'abandoned'))
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ｔｏｔａｌ Ｍｉｓｓｅｄ
    'MIS': {
      tooltip: 'Missed',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'missed'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Missed',
        expandCollapseHeader: true
      },
      style: c => '" style="' + this.configMapper.getColourConfig('MIS', this.configMapper.getTotalTodayStats(c, 'missed'))
    },
    'MIS Calls': {
      tooltip: 'Calls Missed',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'missed'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Missed'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('MIS Calls', this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'missed'))
    },
    'MIS Chats': {
      tooltip: 'Chats Missed',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'missed'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Missed'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('MIS Chats', this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'missed'))
    },
    'MIS IMs': {
      tooltip: 'Instant Messages Missed',
      value: (c) => this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'missed'),
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Missed'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('MIS IMs', this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'missed'))
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ｃａｌｌｂａｃｋ | Ｆｏｒｗａｒｄｅｄ | Ｖｏｉｃｅｍａｉｌ
    'CB': {
      tooltip: 'Callback',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'callback'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('CB', this.configMapper.getTotalTodayStats(c, 'callback'))
    },
    'FW': {
      tooltip: 'Forwarded',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'forwarded'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('FW', this.configMapper.getTotalTodayStats(c, 'forwarded'))
    },
    'VM': {
      tooltip: 'Voicemail',
      value: (c) => this.configMapper.getTotalTodayStats(c, 'voicemail'),
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('VM', this.configMapper.getTotalTodayStats(c, 'voicemail'))
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ａｖｅｒａｇｅ Ｗａｉｔ Ｔｉｍｅ
    'AWT': {
      tooltip: 'Average wait time Interactions',
      value: (c) => {
        let value = this.configMapper.getTotalTodayStats(c, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions',
        expandCollapseHeader: true
      },
      style: c => {
        let value = this.configMapper.getTotalTodayStats(c, 'averageWaitTime'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT', value);
      }
    },
    'AWT Calls': {
      tooltip: 'Average wait time for Calls',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'averageWaitTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Calls', value);
      }
    },
    'AWT Chats': {
      tooltip: 'Average wait time for Chats',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'averageWaitTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Chats', value);
      }
    },
    'AWT Emails': {
      tooltip: 'Average wait time for Emails',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'averageWaitTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Emails', value);
      }
    },
    'AWT IMs': {
      tooltip: 'Average wait time for Instant Messages',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'averageWaitTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT IMs', value);
      }
    },
    'AWT SMS': {
      tooltip: 'Average wait time for SMS',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.SMS, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.SMS, 'averageWaitTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT SMS', value);
      }
    },
    'AWT Tickets': {
      tooltip: 'Average wait time for Tickets',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'averageWaitTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'averageWaitTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Tickets', value);
      }
    },
    'AWT 30 min': {
      tooltip: 'Average wait time Interactions 30 min',
      value: (c) => {
        let value = this.configMapper.getTotalTodayStats(c, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min',
        expandCollapseHeader: true
      },
      style: c => {
        let value = this.configMapper.getTotalTodayStats(c, 'averageWaitingTime30Min'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT', value);
      }
    },
    'AWT Calls 30 min': {
      tooltip: 'Average wait time for Calls 30 min',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'averageWaitingTime30Min');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Calls', value);
      }
    },
    'AWT Chats 30 min': {
      tooltip: 'Average wait time for Chats 30 min',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'averageWaitingTime30Min');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Chats', value);
      }
    },
    'AWT Emails 30 min': {
      tooltip: 'Average wait time for Emails 30 min',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'averageWaitingTime30Min');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Emails', value);
      }
    },
    'AWT IMs 30 min': {
      tooltip: 'Average wait time for Instant Messages 30 min',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'averageWaitingTime30Min');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT IMs', value);
      }
    },
    'AWT SMS 30 min': {
      tooltip: 'Average wait time for SMS 30 min',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.SMS, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.SMS, 'averageWaitingTime30Min');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT SMS', value);
      }
    },
    'AWT Tickets 30 min': {
      tooltip: 'Average wait time for Tickets 30 min',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'averageWaitingTime30Min');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average wait time Interactions 30 min'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'averageWaitingTime30Min');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AWT Tickets', value);
      }
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🆃🅾🅳🅰🆈  🆂🆃🅰🆃🆂 | Ａｖｅｒａｇｅ Ｈａｎｄｌｅ Ｔｉｍｅ
    'AHT': {
      tooltip: 'Average handle time Interactions',
      value: (c) => {
        let value = this.configMapper.getTotalTodayStats(c, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions',
        expandCollapseHeader: true
      },
      style: c => {
        let value = this.configMapper.getTotalTodayStats(c, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT', value);
      }
    },
    'AHT Calls': {
      tooltip: 'Average handle time for Calls',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INBOUND_CALL, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT Calls', value);
      }
    },
    'AHT Chats': {
      tooltip: 'Average handle time for Chats',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.CHAT, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT Chats', value);
      }
    },
    'AHT Emails': {
      tooltip: 'Average handle time for Emails',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.EMAIL, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT Emails', value);
      }
    },
    'AHT IMs': {
      tooltip: 'Average handle time for Instant Messages',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.INSTANT_MESSAGE, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT IMs', value);
      }
    },
    'AHT SMS': {
      tooltip: 'Average handle time for SMS',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.SMS, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.SMS, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT SMS', value);
      }
    },
    'AHT Tickets': {
      tooltip: 'Average handle time for Tickets',
      value: (c) => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'averageHandleTime');
        return this.configMapper.formatToDayTime(value);
      },
      align: 'right',
      width: 200,
      visible: false,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Average handle time Interactions'
      },
      style: c => {
        let value = this.configMapper.getTodayStats(c, CHANNELS.TICKET, 'averageHandleTime');
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('AHT Tickets', value);
      }
    }
  };
}
