import { inject, LogManager } from 'aurelia-framework';

import { LowCreditNotificationCtrl } from './lowcreditnotification/low-credit-notification-controller';
import { AutoRechargeCtrl } from './autorecharge/auto-recharge-controller';
import { SessionStore } from '../../../../_common/stores/session-store';

const logger = LogManager.getLogger('RechargeTrigger');

@inject(LowCreditNotificationCtrl, AutoRechargeCtrl, SessionStore)
export class RechargeTrigger {

  constructor(public lowCreditNotificationCtrl: LowCreditNotificationCtrl, public autoRechargeCtrl: AutoRechargeCtrl, private sessionStore: SessionStore) { }

  public attached(): void {
    this.lowCreditNotificationCtrl.attached();
    if (this.autoRechargeCtrl.isAutoRechargeVisible) {
      this.autoRechargeCtrl.attached();
    }
  }

  public detached(): void {
    this.lowCreditNotificationCtrl.detached();
    if (this.autoRechargeCtrl.isAutoRechargeVisible) {
      this.autoRechargeCtrl.detached();
    }
  }

  public get isLoading(): boolean {
    return !this.lowCreditNotificationCtrl.isNotificationReady ||
      (this.autoRechargeCtrl.isAutoRechargeVisible && !this.autoRechargeCtrl.isAutoRechargeReady);
  }

  public get currencySymbol(): string {
    return this.sessionStore.get.account.currencySymbol;
  }
}
