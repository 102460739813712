import { UserSessionModel } from '_common/stores/sessionmodels/user-session-model';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { LogManager, autoinject, bindable, computedFrom, customElement, observable } from 'aurelia-framework';
import { ContactModel } from 'features/organisation/contact/contact-model';
import { ContactService } from 'features/organisation/contact/contact-service';
import { INTERACTION_ACTIONS } from 'features/organisation/contact/contactcontroller/contact-controller-actions';
import { ConversationModel } from 'features/organisation/conversation/conversation-model';
import { InteractionModel } from 'features/organisation/conversation/interaction-model';
import { DefaultDispositionCode } from 'features/organisation/organisation/dispositioncodes/disposition-codes-model';
import { TelephonyService } from 'features/telephony/telephony-service';
import { FeatureFlagService, SessionStore } from 'zailab.common';
import { ZIOplog } from '../../../../../../../typings/zai/zai.common';
import { MemberInteractionModel } from '../../interaction/member-interaction-model';
import { InteractionService as OrganisationInteractionService } from 'features/organisation/interactions/interaction-service';
import { InteractionService } from '../../interaction/interaction-service';

const logger = LogManager.getLogger('CallInteractionCard');

@customElement('z-call-interaction-card')
@autoinject
export class CallInteractionCard {

  @bindable public interaction: InteractionModel;
  @bindable public contactid: string;
  @bindable public contact: ContactModel;
  @bindable public unlinked: boolean;
  @bindable public conversations: ConversationModel[];
  @bindable public onCall: boolean;

  @observable public searchCriteria: string = null;
  
  public dispositionCode: DefaultDispositionCode = null;
  public disposition: string = null;
  public hasAdditionalData: boolean = true;
  public additionalDataVisible: boolean = false;
  public additionalData = [];
  public voiceAuthenticatorEnabled: boolean;
  public viewRecordingsDisabled: boolean = true;
  public transferOptions = ['Member Transfer', 'Transfer List'];
  public selectedTransferOption: string;
  public memberTransferIsActive: boolean;
  public worktypeTransferIsActive: boolean;
  public transferListTransferIsActive: boolean;
  public displayTransfer: boolean;
  public displayDisposition: boolean;
  public dispositionError: string;
  public displayRecording: boolean;
  public siyaCustom: boolean;

  private toggleCallBoxView: boolean = false;
  private audioPlayer: HTMLAudioElement;
  private subscriptions: { key:string, subscription: Subscription }[] = [];
  private isOnWrapup: boolean = false;
  
  protected oplog: ZIOplog = null;
  protected oplogInteraction: ZIOplog = null;

  constructor(
    private element: Element,
    protected eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private contactService: ContactService,
    private telephonyService: TelephonyService,
    private organisationInteractionService: OrganisationInteractionService,
    private interactionService: InteractionService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public bind(): void {
    this.getFeatureFlags();
  }

  private getFeatureFlags(): void {
    this.featureFlagService.isEnabled('voiceAuthenticator')
      .then((enabled) => {
        this.voiceAuthenticatorEnabled = enabled;
      });
    this.featureFlagService.isEnabled('viewRecordingsDisabled')
      .then(async (viewRecordingsDisabled) => {
        this.viewRecordingsDisabled = viewRecordingsDisabled;
      });
    this.featureFlagService.isEnabled('siyaCustom')
      .then(async (siyaCustom) => {
        this.siyaCustom = siyaCustom;
      });
  }

  public async attached(): Promise<void> {
    this.subscribe();

    if (this.contact && this.contact.servedInteraction) {
      this.toggleCallBoxView = !this.toggleCallBoxView;
    }
  }

  public showAdditionalData(): void {
    this.additionalDataVisible = true;
  }

  public triggerAdditionalDataButton(data: any): void {
    this.contactService.triggerRequest(data.value, data.requestMethod);
  }

  public async getAdditionalDataDisplay(data: any): Promise<string> {
    return await this.contactService.fetchFromUrl(data.value, data.requestProperty);
  }

  public initialiseRecording(): void {
    // @ts-ignore
    if (!this.interaction.recordingId || this.interaction.recordingURL) {
      return;
    }
    this.eventAggregator.publish(INTERACTION_ACTIONS.GET_RECORDING_URL, this.interaction);
  }

  private subscribe(): void {

    if (!this.subscriptions.find(item => item.key ===  INTERACTION_ACTIONS.WRAP_UP_STARTED)) {
      this.subscriptions.push({ key: INTERACTION_ACTIONS.WRAP_UP_STARTED, subscription:  this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_STARTED, (data) => {
        if (!this.interaction) {
          return;
        }
        if (data.state === 'WRAP_UP' && !this.isOnWrapup && data.interactionId === this.interaction.interactionId) {
          this.interaction.isOnWrapup = true;
        } else {
          this.interaction.isOnWrapup = false;
        }
      })});
    }

    if (!this.subscriptions.find(item => item.key === INTERACTION_ACTIONS.WRAP_UP_ENDED)) {
      this.subscriptions.push({ key: INTERACTION_ACTIONS.WRAP_UP_ENDED, subscription: this.eventAggregator.subscribe(INTERACTION_ACTIONS.WRAP_UP_ENDED, (data) => {
        if (!this.interaction) {
          return;
        }
        if (this.isOnWrapup && data.interactionId === this.interaction.interactionId) {
          this.isOnWrapup = false;
        }
      })});
    }

    if (!this.subscriptions.find(item => item.key === 'end-interaction-wrap-up')) {
      this.subscriptions.push({ key: 'end-interaction-wrap-up', subscription: this.eventAggregator.subscribe('end-interaction-wrap-up', (interactionId) => {
        if (!this.interaction) {
          return;
        }
        if (this.isOnWrapup && interactionId === this.interaction.interactionId) {
          this.isOnWrapup = false;
        }
      })});
    }

    this.subscriptions.push({
      key: 'select.disposition',
      subscription: (
        this.eventAggregator.subscribe(
          'select.disposition',
          () => this.endCampaignTask()
        )
      )
    });

    this.subscriptions.push({
        key: INTERACTION_ACTIONS.MEMBER_DISCONNECTED_FROM_OUTBOUND_FLOW_CALL,
        subscription: (
          this.eventAggregator.subscribe(
            INTERACTION_ACTIONS.MEMBER_DISCONNECTED_FROM_OUTBOUND_FLOW_CALL,
            (data) => {
              if (!this.interaction) {
                return;
              }
              if (this.siyaCustom && this.interaction.showDispositionCodeSelectButton) {

                if (
                  this.displayTransfer ||
                  this.memberTransferIsActive ||
                  this.worktypeTransferIsActive ||
                  this.transferListTransferIsActive
                ) {
                  return;
                }

                this.displayDisposition = true;
              }
            }
          )
        )
    });
  }

  @computedFrom('dispositionCode')
  public get dispositionTitle(): string {
    if (!this.dispositionCode) {
      return '';
    }
    let title = `Outcome: ${this.dispositionCode.conversationOutcome}\nReason: ${this.dispositionCode.interactionOutcomeReason}`;
    return title;
  }

  @computedFrom('dispositionCode')
  public get dispositionColor(): string {
    if (!this.dispositionCode) {
      return '';
    }
    return this.dispositionCode.isNegative ? 'var(--unsuccessful)' : this.dispositionCode.isPositive ? 'var(--successful)' : '';
  }

  public togglePlayPause(): void {
    if (!this.interaction.recordingId || !this.canRetrieveRecording) {
      return;
    }
    if (this.interaction.recording.canPlay) {
      this.toggleRecordingPlayback();
    } else {
      this.interaction.retrieveRecordingUrl()
        .then(() => this.toggleRecordingPlayback())
        .catch((err) => logger.error('togglePlayPause :: err=', err));
    }
  }

  private toggleRecordingPlayback(): void {
    if (!this.canRetrieveRecording) {
      this.audioPlayer.pause();
      return;
    }
    if (this.interaction.recording.recordingURL) {
      this.interaction.recording.toggle();
      if (this.interaction.recording.isPlaying) {
        this.audioPlayer.play();
      } else {
        this.audioPlayer.pause();
      }
    }
  }

  public addAudioPlayerEventListeners(): void {
    this.audioPlayer.onloadeddata = () => {
      if (this.audioPlayer && this.audioPlayer.duration) {
        this.interaction.recording.audioLength = this.audioPlayer.duration;
      }
    };

    this.audioPlayer.ontimeupdate = () => {
      if (this.audioPlayer && this.audioPlayer.currentTime) {
        this.interaction.recording.playTime = this.audioPlayer.currentTime;
      }
    };

    this.audioPlayer.onended = () => {
      this.interaction.recording.isPlaying = false;
      this.audioPlayer.currentTime = 0;
    };
  }

  public seek(): void {
    this.audioPlayer.currentTime = this.interaction.recording.playTime;
  }

  private removeSubscriptions(): void {
    this.subscriptions.forEach((subscription) => subscription.subscription.dispose());
  }

  public endWrapUp(): void {
    this.isOnWrapup = false;
    const user = this.sessionStore.get.user;
    this.telephonyService.goOffWrapUp(user.memberId, this.interaction.interactionId, this.interaction.wrapUpChannelIds);
    this.endCampaignTask();
    if (this.siyaCustom) {
      this.eventAggregator.publish('close.all.contacts');
    }
  }

  public end(): void {
    this.telephonyService.disconnect(this.interaction.interactionId);
    this.endCampaignTask();
  }

  private endCampaignTask(): void {
    if (!this.siyaCustom) {
      return;
    }
    this.organisationInteractionService.retrieveConnectedInteractions(this.sessionStore.get.user.memberId)
      .then((memberInteractions: MemberInteractionModel[]) => {
        const relatedTasks = memberInteractions.filter((interaction) => this.interactionService.isTask(interaction));
        if (relatedTasks && relatedTasks.length) {
          for (const task of relatedTasks) {
            this.interactionService.endTask(task);
          }
        }
      });
  }

  public selectDispositionCode(): void {
    this.eventAggregator.publish('member.select.disposition.code', {
      interactionId: this.interaction.interactionId,
      correlationId: this.contact.correlationId,
      conversationId: this.interaction.conversationId
    });
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.direction === 'OUTBOUND' ? this.interaction.to : this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId
    });
    this.eventAggregator.publish('dock-cards');
  }

  @computedFrom('interaction', 'interaction.agentName')
  public get interactionAgentName(): string {
    return this.interaction ? this.interaction.agentName : null;
  }

  public detached(): void {
    this.removeSubscriptions();
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    if (this.oplogInteraction) {
      this.oplogInteraction.unsubscribe();
    }
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  @computedFrom('user')
  private get memberId(): string {
    return this.user.memberId;
  }

  @computedFrom('viewRecordingsDisabled', 'memberIsPartOfInteraction')
  public get canRetrieveRecording(): boolean {
    return !this.viewRecordingsDisabled && this.memberIsPartOfInteraction;
  }

  @computedFrom('interaction', 'interaction.journey', 'interaction.journey.length', 'memberId')
  public get memberIsPartOfInteraction(): boolean {
    
    if (!this.interaction || !this.interaction.journey) {
      return false;
    }
    let journeyStep = this.interaction.journey.find(item => {
      if (item.type === "CONNECT") { 
        return item._member.memberId === this.memberId;
      }
      return false;
    });
    return journeyStep ? true : false;
  }

  public selectTransferOption(event: any): void {
    this.setTransferOption(event.detail);
  }

  public setTransferOption(option: string): void {
    this.selectedTransferOption = option;
    this.displayTransfer = false;
    this.memberTransferIsActive = false;
    this.worktypeTransferIsActive = false;
    this.transferListTransferIsActive = false;
    if ('Member Transfer' === option) {
      this.memberTransferIsActive = true;
    }
    if ('Work Type Transfer' === option) {
      this.worktypeTransferIsActive = true;
    }
    if ('Transfer List' === option) {
      this.transferListTransferIsActive = true;
    }
  }

  public resetOptions(): void {
    this.memberTransferIsActive = false;
    this.worktypeTransferIsActive = false;
    this.transferListTransferIsActive = false;
    this.displayTransfer = true;
    this.selectedTransferOption = '';
  }

  public toggleTransfer(): void {
    this.displayTransfer = !this.displayTransfer;
    if (this.contact.servedInteraction && this.contact.servedInteraction.direction === 'INBOUND') {
      this.transferOptions = [
        'Member Transfer',
        'Work Type Transfer',
        'Transfer List',
      ];
    }
  }

  public toggleDisposition(): void {
    if (this.siyaCustom && this.displayDisposition && !this.interaction.disposition) {
      this.dispositionError = 'Please select a disposition before continuing.';
      setTimeout(() => this.dispositionError = null, 3000);
      return;
    }
    this.displayDisposition = !this.displayDisposition;
  }

  public closeDisposition(): void {
    this.displayDisposition = false;
  }

  public toggleRecording(): void {
    this.displayRecording = !this.displayRecording;
  }
}
