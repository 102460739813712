import { PLATFORM } from 'aurelia-pal';

export function configure(aurelia) {
  aurelia.globalResources(
    PLATFORM.moduleName('./filter'),
    PLATFORM.moduleName('./text-to-html'),
    PLATFORM.moduleName('./email-html-to-view'),
    PLATFORM.moduleName('./file-name'),
    PLATFORM.moduleName('./order-by'),
    PLATFORM.moduleName('./calculate-placeholders'),
    PLATFORM.moduleName('./ellipsify-string'),
    PLATFORM.moduleName('./strip-spaces'),
    PLATFORM.moduleName('./number-to-currency'),
    PLATFORM.moduleName('./milliseconds-to-time'),
    PLATFORM.moduleName('./milliseconds-to-seconds'),
    PLATFORM.moduleName('./check-empty-state'),
    PLATFORM.moduleName('./time-ago'),
    PLATFORM.moduleName('./time-to'),
    PLATFORM.moduleName('./display-time'),
    PLATFORM.moduleName('./propercase'),
    PLATFORM.moduleName('./start-time'),
    PLATFORM.moduleName('./end-time'),
    PLATFORM.moduleName('./short-time'),
    PLATFORM.moduleName('./filter-by-property'),
    PLATFORM.moduleName('./filter-by-custom-properties'),
    PLATFORM.moduleName('./full-name'),
    PLATFORM.moduleName('./numeric-stepper'),
    PLATFORM.moduleName('./start-time'),
    PLATFORM.moduleName('./end-time'),
    PLATFORM.moduleName('./order-by-timestamp'),
    PLATFORM.moduleName('./milliseconds-to-moment'),
    PLATFORM.moduleName('./order-phone-numbers'),
    PLATFORM.moduleName('./meridiem')
  );
}
