import {inject, LogManager, customElement, containerless, observable, bindable, computedFrom} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZCondensedListStatic');
/*
 */
@containerless()
@inject(Element)
@customElement('z-condensed-list-static')
export default class {

  @bindable @observable data;
  @bindable header;
  @bindable icon;
  @bindable({attribute: 'display-id'}) displayId;
  @bindable({attribute: 'value-divider'}) valueDivider;

  concatString = '';

  constructor(element) {
    this.element = element;
  }

  dataChanged(newValue) {

    if(newValue) {
      this.concatString = '';
      this.initDataStructure();
    }
  }

  initDataStructure() {

    if (this.data && this.data.constructor === Array) {
      this.validateItems();
      return;
    }

    if (this.data && this.data.constructor === String) {
      this.concatString = this.data;
      return;
    }
  }

  validateItems() {

    if (this.data[0] && (this.data[0].constructor === String || this.data[0].constructor === Number)) {
      this.concatStrings();
      return;
    }

    if (this.data[0] && this.data[0].constructor === Object) {
      this.concatObjectStrings();
    }
  }

  concatStrings() {

    for(let item of this.data) {
      this.concatString += item;
      this.addValueDivider();
    }
    this.concatString = this.concatString.substring(0, this.concatString.length - 2);
  }

  concatObjectStrings() {

    if(!this.displayId) {
      return;
    }

    for(let item of this.data) {
      this.concatString += item[this.displayId];
      this.addValueDivider();
    }

    this.concatString = this.concatString.substring(0, this.concatString.length - 2);
  }

  addValueDivider() {

    if(this.valueDivider) {
      this.concatString += this.valueDivider + ' ';
    } else {
      this.concatString += ', ';
    }
  }
}
