import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { SessionStore, CountriesTools, BootstrapFormRenderer, WebSocket } from 'zailab.common';
/**/
import { AccountDetailsService } from './account-details-service';
import { ACCOUNT_INFO_VALIDATION_MESSAGES } from './account-details-validation-messages';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { AccountDetailPayloadModel } from './account-detail-payload-model';
import { AccountAddressModel } from '../account-adress-model';
import { AddressErrorModel } from './address-error-model';
import { AccountSessionModel } from '../../../../_common/stores/sessionmodels/account-session-model';
import * as crs from '../../../../_assets/utils/crs.min.js';
/**/
const logger = LogManager.getLogger('AccountDetails');

@autoinject()
export class AccountDetails {
  private companyInformation: AccountDetailPayloadModel = new AccountDetailPayloadModel();
  private companyInformationCopy: AccountDetailPayloadModel = new AccountDetailPayloadModel();
  private addressError: AddressErrorModel = new AddressErrorModel();

  private updatedCountry: any = null;
  private updatedContactPhoneNumber: any = null;

  private countries: Array<{ name: string; id: string }> = [];
  private availableRegions: Array<{ name: string }> = [];
  private isEditing: boolean;
  private isProcessing: boolean;
  private validation: any;
  private addressValidation: any;
  private error: string;
  private ready:boolean = false;

  constructor(private validationFactory: ValidationControllerFactory, private accountDetailsService: AccountDetailsService, private sessionStore: SessionStore, private webSocket: WebSocket) {
    this.validation = validationFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    let region = this.isSouthAfricanOrg ? 'za' : 'us';
    this.updatedContactPhoneNumber = new TelephoneNumberModel('', '', region, true);
  }

  public activate(): void {
    this.initValidation();
  }

  private initValidation(): void {
    let POSTAL_CODE_MESSAGE = this.isSouthAfricanOrg ? ACCOUNT_INFO_VALIDATION_MESSAGES.POSTAL_CODE.NOT_EMPTY : ACCOUNT_INFO_VALIDATION_MESSAGES.ZIP_CODE.NOT_EMPTY;
    let CONTACT_LASTNAME_MESSAGE = this.isSouthAfricanOrg ? ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_SURNAME.NOT_EMPTY : ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_LASTNAME.NOT_EMPTY;

    ValidationRules.ensure('contactEmail')
      .required()
      .withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_EMAIL.NOT_EMPTY)
      .email()
      .withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_EMAIL.IS_VALID)
      .ensure('companyName')
      .required()
      .withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.COMPANY_NAME.NOT_EMPTY)
      .ensure('contactFirstName')
      .required()
      .withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_NAME.NOT_EMPTY)
      .matches(/^([a-zA-Z'\s-])+$/)
      .withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_NAME.IS_VALID)
      .ensure('contactSurname')
      .required()
      .withMessage(CONTACT_LASTNAME_MESSAGE)
      .matches(/^([a-zA-Z'\s-])+$/)
      .withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_SURNAME.IS_VALID)
      .ensure('phoneNumber')
      .minLength(5)
      .maxLength(20)
      .withMessage('Please enter a valid Phone Number')
      .on(AccountDetailPayloadModel);
  }

  public attached(): void {
    if (this.isSouthAfricanOrg) {
      this.countries = [{ name: 'South Africa', id: '01' }];
      this.availableRegions = CountriesTools.getCountryRegions('za');
    } else {
      this.countries = [{ name: 'United States', id: '02' }];
      this.availableRegions = CountriesTools.getCountryRegions('us');
    }

    this.retrieveCompanyInformation();
    this.subscribe();
    crs.init();
  }

  private subscribe(): void {

    let companyInformationCapturedEvent = 'com.zailab.finance.account.api.events.CompanyInformationCapturedEvent';

    this.webSocket.subscribe({
      name: companyInformationCapturedEvent, callback: response => this.companyInformationCaptured()
    });

  }

  private retrieveCompanyInformation(): void {
    this.ready = false;
    this.accountDetailsService.retrieveCompanyInformation().then(companyInformation => {
      this.companyInformation = companyInformation;
      this.updatedContactPhoneNumber.number = this.companyInformation.contactPhoneNumber;
      this.updatedCountry = {name: this.companyInformation.address.country};
      this.ready = true;
    }, error => {
      this.ready = true;
    })
  }

  private edit(): void {
    this.companyInformationCopy = JSON.parse(JSON.stringify(this.companyInformation));
    this.isEditing = true;
  }

  private done(): void {
    this.validation.validate().then(
      validation => {
        if (!validation.valid || this.validateAddress()) {
          return;
        }
        this.saveInfo();
      },
      error => {}
    );
  }

  private validateAddress(propertyToValidate?: string): boolean {
    let address: AccountAddressModel = this.companyInformation.address;

    // only validate a single property
    if (propertyToValidate) {
      this.addressError[propertyToValidate] = this.generateError(propertyToValidate, address[propertyToValidate]);
      return;
    }

    // validate all properties
    for (let prop in address) {
      if (this.addressError.hasOwnProperty(prop)) {
        this.addressError[prop] = this.generateError(prop, address[prop]);
      }
    }

    return this.addressError.hasError;
  }

  private generateError(propertyName: string, propertyValue: string): any {
    propertyName = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
    propertyName === 'AddressLine1' ? (propertyName = 'Address Line 1') : (propertyName = propertyName);

    if (!propertyValue) {
      let msg: string;

      if (propertyName === 'Address Line 1' || propertyName === 'AddressLine1') {
        msg = `Please enter an ${propertyName}.`;
      } else {
        msg = `Please enter a ${propertyName}.`;
      }

      logger.info('validateAddress > validation > fail > ', msg);

      return msg;
    }
  }

  private saveInfo(): void {

    this.isProcessing = true;
    let accountId: string = this.account.accountId;

    this.companyInformation.accountId = this.companyInformation.accountId ? this.companyInformation.accountId : accountId;
    this.companyInformation.contactPhoneNumber = this.updatedContactPhoneNumber.telephoneNumber;

    this.accountDetailsService.saveAccountInfo(this.companyInformation).then(success => {
      this.isProcessing = false;
      this.error = null;
    }, err => { });
  }

  private companyInformationCaptured(): void {
    this.isEditing = false;
  }

  private cancel(): void {
    this.revertChanges();
    this.isEditing = false;
  }

  private revertChanges(): void {
    this.companyInformation = this.companyInformationCopy;
    this.countries = CountriesTools.getAllCountriesList(this.companyInformation.address.country);
    this.updatedCountry = this.companyInformation.address.country;
  }

  @computedFrom('sessionStore.get.account')
  private get account(): AccountSessionModel {
    return this.sessionStore.get.account;
  }

  @computedFrom('account.available')
  private get isSouthAfricanOrg(): boolean {
    return this.account ? this.account.available.currency === 'ZAR' : null;
  }
}
