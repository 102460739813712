import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/**/
import { WebSocket } from 'zailab.common';
import { ReminderModel } from './reminder-model';
import { ReminderCommandModel } from './reminder-command-model';
import { ContactModel } from '../../contact/contact-model';
import { IExpectedData } from './interfaces';
/**/
import { v4 as uuidv4 } from 'uuid';
/**/
const logger = LogManager.getLogger('ReminderService');
/**/
@autoinject()
export class ReminderService {
  constructor(private httpClient: HttpClient, private webSocket: WebSocket) {}

  public retrieveReminders(memberId: string, timestamp: number, status: string): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/reminders`)
        .asGet()
        .withParams({ memberId, timestamp, status })
        .send()
        .then(
          (response: IExpectedData[]) => {
            if (response && response.reminders) {
              let list = response.reminders.map((item: IExpectedData) => {
                return new ReminderModel(item);
              });
              resolve(list);
            }
          },
          (error: string) => {
            reject(error);
          }
        );
    });
  }

  public saveReminder(memberId: string, dueTimestamp: number, message: string, contactId: string, conversationId: string): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      const model = new ReminderCommandModel({
        memberId,
        dueTimestamp,
        message,
        contactId
      });
      const reminderId = uuidv4();

      this.httpClient
        .createRequest(`v1/organisation/conversations/${conversationId}/reminders/${reminderId}`)
        .asPost()
        .withContent(model)
        .send()
        .then(
          () => {
            resolve();
          },
          (error: string) => {
            reject(error);
          }
        );
    });
  }

  public acknowledgeReminder(reminderId: string): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/reminders/${reminderId}/acknowledge`)
        .asPut()
        .withContent({})
        .send()
        .then(
          () => {
            resolve();
          },
          (error: string) => {
            reject(error);
          }
        );
    });
  }

  public sendReminderToAgent(reminderId: string, memberId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/reminders/${reminderId}/assign`)
        .asPut()
        .withParams({ memberId })
        .withContent({})
        .send()
        .then(
          () => resolve(),
          (error: string) => reject(error)
        );
    });
  }

  public onReminderReceived(callback: Function): void {
    const reminder = 'reminder';
    this.webSocket.subscribe({
      name: reminder,
      callback: data => {
        callback(data);
      }
    });
  }
}
