import { LogManager, customElement, bindable, inject } from 'aurelia-framework';
import moment from 'moment-timezone';
import 'moment-duration-format';
import { SessionStore } from "../../../_common/stores/session-store";
import { computedFrom } from "aurelia-binding";

const logger = LogManager.getLogger('MediaPlayer');

@customElement('z-media-player')
@inject(SessionStore)
export class ZMediaPlayer {

  @bindable mediafilesrc: string;
  @bindable enableDownload: boolean = false;
  @bindable autoPlay: boolean = true;

  private currentTime: string = '00:00';
  private totalTime: string = '00:00';
  private pauseAvailable: boolean = false;
  private progressPercentage: number = 0;
  private loadingMetadata: boolean = true;

  private controlButton: HTMLElement;
  private currentTimeProgress: HTMLElement;
  private currentTimeReadout: HTMLElement;
  private progressBar: HTMLElement;
  private hiddenAudioPlayer: HTMLAudioElement;
  private downloadButton: HTMLAudioElement;

  public constructor(private sessionStore: SessionStore) {

  }

  public attached(): void {
    this.hiddenAudioPlayer.addEventListener('pause', this.handleAudioEvents);
    this.hiddenAudioPlayer.addEventListener('play', this.handleAudioEvents);
    this.hiddenAudioPlayer.addEventListener('progress', this.handleAudioEvents);
    this.hiddenAudioPlayer.addEventListener('ended', this.handleAudioEvents);
    this.hiddenAudioPlayer.addEventListener('timeupdate', this.handleAudioEvents);
    this.hiddenAudioPlayer.addEventListener('durationchange', this.handleAudioEvents);
    this.progressBar.addEventListener('click', this.handleSeekAudio);
    this.currentTimeReadout.innerHTML = '<span class="current-time">' + this.currentTime + '&nbsp;/</span>&nbsp;' + this.totalTime;
    this.playAudio();
  };

  public detached(): void {

    this.hiddenAudioPlayer.removeEventListener('pause', this.handleAudioEvents);
    this.hiddenAudioPlayer.removeEventListener('play', this.handleAudioEvents);
    this.hiddenAudioPlayer.removeEventListener('progress', this.handleAudioEvents);
    this.hiddenAudioPlayer.removeEventListener('ended', this.handleAudioEvents);
    this.hiddenAudioPlayer.removeEventListener('timeupdate', this.handleAudioEvents);
    this.hiddenAudioPlayer.removeEventListener('durationchange', this.handleAudioEvents);
    this.hiddenAudioPlayer.pause();
    this.hiddenAudioPlayer.currentTime = 0;
  };

  private mediafilesrcChanged(_newValue: string, _oldValue: string): void {
    logger.info('Media file new value >>>', _newValue);
    this.loadingMetadata = true;
    if (this.hiddenAudioPlayer && _newValue && _newValue !== null) {
      this.hiddenAudioPlayer.src = _newValue;
      if (this.autoPlay) {
        this.pauseAvailable = false;
        let _playPromise: Promise<any> = this.hiddenAudioPlayer.play();
        if (_playPromise !== undefined) {
          _playPromise.then(_ => {
            this.pauseAvailable = true;
          }).catch(_result => {
            // Tried to play pause
          })
        }
      }
    }
  };

  private playAudio(): void {
    let oldVal;
    if (this.hiddenAudioPlayer) {
      oldVal = this.hiddenAudioPlayer.src;
    }
    this.mediafilesrcChanged(this.mediafilesrc, oldVal);
  }

  private handleAudioEvents = (_event: Event): void => {
    switch (_event.type) {
      case 'play':
        this.controlButton.getElementsByClassName('o-z-icon')[0].classList.remove('o-z-icon--play');
        this.controlButton.getElementsByClassName('o-z-icon')[0].classList.add('o-z-icon--pause');
        break;
      case 'pause':
        this.controlButton.getElementsByClassName('o-z-icon')[0].classList.remove('o-z-icon--pause');
        this.controlButton.getElementsByClassName('o-z-icon')[0].classList.add('o-z-icon--play');
        break;
      case 'ended':
        this.controlButton.getElementsByClassName('o-z-icon')[0].classList.remove('o-z-icon--pause');
        this.controlButton.getElementsByClassName('o-z-icon')[0].classList.add('o-z-icon--play');
        this.hiddenAudioPlayer.currentTime = 0;
        break;
      case 'progress':
        logger.info('Progress >>>> ', _event.target);
        break;
      case 'timeupdate':
        this.progressPercentage = (this.hiddenAudioPlayer.currentTime / this.hiddenAudioPlayer.duration) * 100;
        this.currentTime = moment.duration(this.hiddenAudioPlayer.currentTime * 1000).format('mm:ss', {trim: false});
        this.totalTime = moment.duration(this.hiddenAudioPlayer.duration * 1000).format('mm:ss', {trim: false});
        this.currentTimeReadout.innerHTML = '<span class="current-time">' + this.currentTime + '&nbsp;/</span>&nbsp;' + this.totalTime;
        break;
      case 'durationchange':
        this.loadingMetadata = false;
        this.currentTime = moment.duration(this.hiddenAudioPlayer.currentTime * 1000).format('mm:ss', {trim: false});
        this.totalTime = moment.duration(this.hiddenAudioPlayer.duration * 1000).format('mm:ss', {trim: false});
        this.currentTimeReadout.innerHTML = '<span class="current-time">' + this.currentTime + '&nbsp;/</span>&nbsp;' + this.totalTime;
        break;
    }
  };

  private handleSeekAudio = (event: MouseEvent): void => {

    if(!this.hiddenAudioPlayer.src) {
      return;
    }

    let seekPercentage = event.offsetX / this.progressBar.offsetWidth;
    this.hiddenAudioPlayer.currentTime = seekPercentage * this.hiddenAudioPlayer.duration;
  };

  playOrPause = (): void => {
    if (!this.hiddenAudioPlayer.paused && !this.hiddenAudioPlayer.ended) {
      this.hiddenAudioPlayer.pause();
    } else {
      this.hiddenAudioPlayer.play();
    }
  };

  @computedFrom('sessionStore.get.user')
  get showDownload(): boolean {
    return !this.sessionStore.get.user.hasAgentRole && this.enableDownload;
  }
}
