export const ASSESSMENT_ACTIONS = {
  
  RETRIEVE_RECORDING: 'quality.assessment.recording.retrieve',
  RETRIEVE_QUESTIONS: 'quality.assessment.questions.retrieve',
  FETCH_FORMATTED_SECTIONS: 'quality.assessment.formatted.sections.retrieve',
  SHOW_SUCCESS : 'quality.assessor.forms.quality.assessment.success',
  ASSESSMENT_SUCCESS: 'quality.assessment.success',
  CLEAR_STORE: 'quality.assessment.store.clear',
  HIDE_BUTTON: 'quality.assessment.hide.button',
  DEACTIVATE_VIEW: 'quality.assessment.view.deactivate'
};
