import { autoinject } from 'aurelia-dependency-injection';

import { ConfigMapper, CHANNELS } from './config-mapper';

@autoinject
export class InteractionLiveStats {

  constructor(private configMapper: ConfigMapper) {}

  public columnConfig = {
    // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🅻🅸🆅🅴  🆂🆃🅰🆃🆂 | Ｗａｉｔｉｎｇ Ｉｎｔｅｒａｃｔｉｏｎｓ
    'W': {
      tooltip: 'Waiting Interactions',
      value: (c) => this.configMapper.getTotalLiveStats(c, 'waiting'),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions',
        expandCollapseHeader: true
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W', this.configMapper.getTotalLiveStats(c, 'waiting'))
    },
    'W Calls': {
      tooltip: 'Waiting Calls',
      value: (c) => this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'waiting'),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W Calls', this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'waiting'))
    },
    'W Chats': {
      tooltip: 'Waiting Chats',
      value: (c) => this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'waiting'),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W Chats', this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'waiting'))
    },
    'W Emails': {
      tooltip: 'Waiting Emails',
      value: (c) => this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'waiting'),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W Emails', this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'waiting'))
    },
    'W IMs': {
      tooltip: 'Waiting Instant Messages',
      value: (c) => this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'waiting'),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W IMs', this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'waiting'))
    },
    'W SMS': {
      tooltip: 'Waiting SMS',
      value: (c) => this.configMapper.getLiveStats(c, CHANNELS.SMS, 'waiting'),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W SMS', this.configMapper.getLiveStats(c, CHANNELS.SMS, 'waiting'))
    },
    'W Tickets': {
      tooltip: 'Waiting Tickets',
      value: (c) => this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'waiting'),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Interactions',
        expandCollapseGroup: 'Waiting Interactions'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('W Tickets', this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'waiting'))
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🅻🅸🆅🅴  🆂🆃🅰🆃🆂 | Ｌｏｎｇｅｓｔ Ｗａｉｔｉｎｇ
    'L W': {
      tooltip: 'Longest Waiting',
      value: (c) => this.configMapper.longestWaitingTimer(c),
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting',
        expandCollapseHeader: true
      },
      style: c => {
        let value = this.configMapper.getTotalLiveStats(c, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W', value);
      }
    },
    'L W Call': {
      tooltip: 'Longest Waiting Call',
      value: (c) => this.configMapper.longestWaitingTimer(c, CHANNELS.INBOUND_CALL),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting'
      },
      style: c => {
        let value = this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W Call', value);
      }
    },
    'L W Chat': {
      tooltip: 'Longest Waiting Chat',
      value: (c) => this.configMapper.longestWaitingTimer(c, CHANNELS.CHAT),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting'
      },
      style: c => {
        let value = this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W Chat', value);
      }
    },
    'L W Email': {
      tooltip: 'Longest Waiting Email',
      value: (c) => this.configMapper.longestWaitingTimer(c, CHANNELS.EMAIL),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting'
      },
      style: c => {
        let value = this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W EMAIL', value);
      }
    },
    'L W IM': {
      tooltip: 'Longest Waiting Instant Message',
      value: (c) => this.configMapper.longestWaitingTimer(c, CHANNELS.INSTANT_MESSAGE),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting'
      },
      style: c => {
        let value = this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W IM', value);
      }
    },
    'L W SMS': {
      tooltip: 'Longest Waiting SMS',
      value: (c) => this.configMapper.longestWaitingTimer(c, CHANNELS.SMS),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting'
      },
      style: c => {
        let value = this.configMapper.getLiveStats(c, CHANNELS.SMS, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W SMS', value);
      }
    },
    'L W Ticket': {
      tooltip: 'Longest Waiting Ticket',
      value: (c) => this.configMapper.longestWaitingTimer(c, CHANNELS.TICKET),
      visible: false,
      align: 'right',
      width: 60,
      data: {
        unitOfMeasurement: 'Seconds',
        expandCollapseGroup: 'Longest Waiting'
      },
      style: c => {
        let value = this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'longestWaiting'); 
        if (value && value !== '--') {
          value = value / 1000;
        }
        return '" style="' + this.configMapper.getColourConfig('L W Ticket', value);
      }
    },
  // 🅸🅽🆃🅴🆁🅰🅲🆃🅸🅾🅽  🅻🅸🆅🅴  🆂🆃🅰🆃🆂 | In and Out of SLA
    'Out SLA': {
      tooltip: 'Out of SLA',
      value: (c) => {
        const value = this.configMapper.getTotalLiveStats(c, 'percentageOutSLA');
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA',
        expandCollapseHeader: true
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Out SLA', this.configMapper.getTotalLiveStats(c, 'percentageOutSLA'))
    },
    'Calls out SLA': {
      tooltip: 'Calls out of SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'percentageOutSLA');
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Calls out SLA', this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'percentageOutSLA'))
    },
    'Chats out SLA': {
      tooltip: 'Chats out of SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'percentageOutSLA');
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Chats out SLA', this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'percentageOutSLA'))
    },
    'Emails out SLA': {
      tooltip: 'Emails out of SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'percentageOutSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Emails out SLA', this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'percentageOutSLA'))
    },
    'IMs out SLA': {
      tooltip: 'Instant Messages out of SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'percentageOutSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('IMs out SLA', this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'percentageOutSLA'))
    },
    'SMS out SLA': {
      tooltip: 'SMS out of SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.SMS, 'percentageOutSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('SMS out SLA', this.configMapper.getLiveStats(c, CHANNELS.SMS, 'percentageOutSLA'))
    },
    'Tickets out SLA': {
      tooltip: 'Tickets out of SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'percentageOutSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'Out of SLA'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Tickets out SLA', this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'percentageOutSLA'))
    },
    'In SLA': {
      tooltip: 'In SLA',
      value: (c) => {
        const value = this.configMapper.getTotalLiveStats(c, 'percentageInSLA');
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla',
        expandCollapseHeader: true
      },
      style: c => '" style="' + this.configMapper.getColourConfig('In SLA', this.configMapper.getTotalLiveStats(c, 'percentageOutSLA'))
    },
    'Calls in SLA': {
      tooltip: 'Calls in SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'percentageInSLA');
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Calls in SLA', this.configMapper.getLiveStats(c, CHANNELS.INBOUND_CALL, 'percentageInSLA'))
    },
    'Chats in SLA': {
      tooltip: 'Chats in SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'percentageInSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Chats in SLA', this.configMapper.getLiveStats(c, CHANNELS.CHAT, 'percentageInSLA'))
    },
    'Emails in SLA': {
      tooltip: 'Emails in SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'percentageInSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Emails in SLA', this.configMapper.getLiveStats(c, CHANNELS.EMAIL, 'percentageInSLA'))
    },
    'IMs in SLA': {
      tooltip: 'Instant Messages in SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'percentageInSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('IMs in SLA', this.configMapper.getLiveStats(c, CHANNELS.INSTANT_MESSAGE, 'percentageInSLA'))
    },
    'SMS in SLA': {
      tooltip: 'SMS in SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.SMS, 'percentageInSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('SMS in SLA', this.configMapper.getLiveStats(c, CHANNELS.SMS, 'percentageInSLA'))
    },
    'Tickets in SLA': {
      tooltip: 'Tickets in SLA',
      value: (c) => {
        const value = this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'percentageInSLA')
        return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
      },
      visible: false,
      align: 'right',
      width: 200,
      data: {
        unitOfMeasurement: 'Percentage',
        expandCollapseGroup: 'In Sla'
      },
      style: c => '" style="' + this.configMapper.getColourConfig('Tickets in SLA', this.configMapper.getLiveStats(c, CHANNELS.TICKET, 'percentageInSLA'))
    }
  };
}
