import { PLATFORM } from "aurelia-pal";

export function configure(aurelia) {
  aurelia
    .globalResources(PLATFORM.moduleName('./autofocus/autofocus'))
    .globalResources(PLATFORM.moduleName('./loader/loader'))
    .globalResources(PLATFORM.moduleName('./hidewheninactive/hide-when-inactive'))
    .globalResources(PLATFORM.moduleName('./scrolltobottom/scroll-to-bottom'))
    .globalResources(PLATFORM.moduleName('./loaded/loaded'))
    .globalResources(PLATFORM.moduleName('./groupedlist/list-group'));
}
