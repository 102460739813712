import {bindable, inject, bindingMode, observable} from "aurelia-framework";

/**
 * TODO: Document passing in data attribute values
 */

@inject(Element)
export class ZTag {
  @bindable()
  isInvalid: boolean;
  @bindable()
  tooltip: string;
  @bindable()
  label: string;
  @bindable disabled: boolean;
  
  @bindable()
  onClose: Function;

  element: HTMLElement;

  constructor(element:Element) {
    this.element = element as HTMLElement;
  }

  onCloseHandler(event: Event): void {
    if ((this.onClose) && (typeof this.onClose === "function")) {
      this.onClose(this.element.dataset)
    }
  }
}
