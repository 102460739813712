import {inject, bindable, LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('ListGroupCustomAttribute');

interface ListGroupDetail {
  label: string;
  list: Array<string>;
}

@inject(Element)
export class ListGroupCustomAttribute {

  @bindable private list: Array<string>;
  @bindable private count: Array<string>;
  @bindable private label: string;
  @bindable private detail: Array<ListGroupDetail>;
  @bindable private styles: string = null;
  private elementWidth: number;
  private counter: number = 0;
  private listWidth: number = 0;

  constructor(private element: Element) {}

  private async listChanged(newValue: Array<string>, oldValue: Array<string>): Promise<void> {
    let hoverList = [];

    if (this.element && newValue) {
      this.elementWidth = await this.calculateElementWidth();

      newValue.forEach((val, index) => {
        if(!this.elementWidth && index < 1) {
          this.wrapValue(val);
        } else {
          if (this.listWidth < this.elementWidth) {
            this.wrapValue(val);
            this.listWidth += this.calculateItemWidth();
          } else {
            this.counter++;
          }
        }
         hoverList.push(val);
      });

    }
    if (this.counter > 0) {
      this.wrapValue(` ${this.counter} more`, 'label grey ' + this.styles);
    }
    if (this.detail) {

      for (let item of this.detail) {
        if(item.list && item.list.length !== 0) {
          this.wrapValue(`${item.label}: ${item.list.length}`, 'label grey ' + this.styles);
          hoverList.push(`\n${item.label}:`);
          hoverList.push(item.list.toString().replace(/,/g, ', '));
        }
      }
    }
    this.element.setAttribute('title', 'To: ' + hoverList.toString().replace(/,/g, ', '));
  }

  private async calculateElementWidth(): Promise<number> {
    let computedStyles = await window.getComputedStyle(this.element, null);
    let element = computedStyles.width;
    let elementWidth = element.slice(0, element.length - 2);
    return parseInt(elementWidth);
  }

  private async wrapValue(val: string, styleClass?: string): Promise<void> {
    let wrappedItem = await document.createElement('span');
    wrappedItem.setAttribute('class', `label ellipse ${this.styles}`);
    if(styleClass) {
      wrappedItem.setAttribute('class', `${styleClass}`);
    }
    wrappedItem.innerHTML = `${val}`;
    this.element.appendChild(wrappedItem);
  }

  private calculateItemWidth(): number {
    let listItem = document.querySelectorAll('.counted-list');
    let childWidth = 0;
    if (listItem) {
      [].forEach.call(listItem, child => {
        childWidth = child.offsetWidth;
      });
      return childWidth;
    }
  }
}
