/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZUtilityCount');
/*
 */
@customElement('z-utility-count')
export class ZUtilityCount {

  @bindable spaced = true;
  @bindable position;
  @bindable count;
  @bindable text;

}
