import {LogManager} from 'aurelia-framework';

import {BaseModel} from 'zailab.abstract';

const logger = LogManager.getLogger('ChannelModel');

export class ChannelModel extends BaseModel {

  public size: string = null;
  public name: string = null;
  public icon: string = null;
  public isActive: boolean = false;

  constructor(name: string, icon?: string) {
    super();
    this.name = name;
    this.icon = icon ? icon : '';
  }

  public select(): void {
    this.isActive = true;

  }

  public deselect(): void {
    this.isActive = false;
  }
}
