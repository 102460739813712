import {GRAPH_COLORS} from './graph-colors';

export class ChartScrollbarConfig {
  public enabled: boolean =  true;
  public oppositeAxis: boolean = false;
  public offset: number = 50;
  public scrollbarHeight: number = 20;
  public backgroundColor: string = 'var(--primary-accent)';
  public backgroundAlpha: number = 0.1;
  public selectedBackgroundColor: string = 'var(--primary-accent)';
  public selectedBackgroundAlpha: number = 0.3;
  public graphFillColor: string = GRAPH_COLORS.CALLS;
  public graphFillAlpha: number = 1;
  public graphLineColor: string = GRAPH_COLORS.CALLS;
  public graphLineAlpha: number = 0;
  public selectedGraphFillColor: GRAPH_COLORS = GRAPH_COLORS.CALLS;
  public selectedGraphLineAlpha: number = 0;
  public color: string = 'var(--gray-alt)';
  public resizeEnabled: boolean = false;

  constructor() {

  }
}
