import { AccountSessionModel } from './../../../../_common/stores/sessionmodels/account-session-model';
import { inject, LogManager, observable, computedFrom } from 'aurelia-framework';
import {
  ValidationControllerFactory,
  ValidationController,
  ValidationRules,
  validateTrigger
} from 'aurelia-validation';
/*
 */
import {
  SessionStore,
  DateTimeTools,
  MESSAGE_EVENTS,
  BootstrapFormRenderer,
  ArrayTools,
  CountriesTools
} from 'zailab.common';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { BuyCreditService } from './buycredit-service';
import { BuyCreditModel } from './buycredit-model';
import creditCardValidator from 'credit-card-validator';
import { ThreeDSecurePopUp } from './3d-secure-pop-up';
import toastr from 'toastr';

/*
 */
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { PAYGATE_VALIDATION_MESSAGE } from './paygate-validation-message';
import { CardModel } from './card-model';
import { ApplicationProperties } from './../../../../_config/application.properties';
import { ProfileStore } from '../../../../hud/_widgets/profile/profile.store';
import { DialogService } from 'aurelia-dialog';
import { ThreeDSecureDialog } from '../3d-secure-dialog/three-d-secure-dialog';
import { AddressInterface } from './buy-credit-interfaces/address-interface';
import { SourceInterface } from './buy-credit-interfaces/source-interface';
import { CustomerInterface } from './buy-credit-interfaces/customer-interface';

/*
 */
const logger = LogManager.getLogger('BuyCredit');
declare let Stripe: any;

@inject(BuyCreditService, ValidationControllerFactory, SessionStore, DateTimeTools, EventAggregator, DialogService)
export class BuyCredit {

  private showAddCard: boolean = false;
  private expirationMonth: string;
  private expirationYear: string;
  private cardNumber: string;
  private cardName: string;
  private cardType: string;
  private securePaymentButton: boolean;
  private selectedCard: CardModel;
  private availableYears: Array<{ year: string }> = [];
  private availableMonths: Array<{ month: string }> = [];
  cardTypes = [{ type: 'VISA' }, { type: 'MasterCard' }];

  private cvvNumber: string = '';
  private amount: string = '';
  private paymentId;

  private isProcessingPayment = false;
  private isProcessingCard = false;
  private securePaymentMessage = false;

  private cardDetailsForm;
  private cardInformationFormGroup;

  private paymentPopup: any;
  private showSecurePayment = false;
  private buyCreditModel: BuyCreditModel;
  private resetDropdown: boolean = false;

  private userProfile: {
    firstName: string,
    surname: string,
    email: string
  };

  private accountIsInTrialState;
  private oplog;
  private paymentOplog;
  private validation;
  private firstName: string = null;
  private surname: string = null;
  private secureUrl: string = null;
  private testValue = {
    value: 'test'
  };
  private fields: Array<any> = null;
  private country: string = null;
  private availableCountries: Array<{ name: string, id: string }> = [];
  private availableRegions: Array<{ name: string }> = [];

  private phoneNumber: TelephoneNumberModel;
  private cardInfo = {
    address1: null,
    address2: null,
    city: null,
    region: null,
    country: null,
    postalCode: null,
    companyName: null,
    cardholderName: null,
    contactPhoneNumber: new TelephoneNumberModel('', '', 'za', true, true),
  };

  private cardSubmittedSubscription: Subscription;
  private cardFailedSubscription: Subscription;

  constructor(private buyCreditService: BuyCreditService, private validationControllerFactory: ValidationControllerFactory,
    private sessionStore: SessionStore, private dateTimeTools: DateTimeTools,
    private eventAggregator: EventAggregator, private dialogService: DialogService) {

    this.availableYears = this.initializeYears();
    this.availableMonths = this.initializeMonths();
    let region = this.isSouthAfricanOrg ? 'za' : 'us';
    if (region === 'za') {
      this.availableCountries = [{ name: 'South Africa', id: '01' }];
      this.availableRegions = CountriesTools.getCountryRegions('za');
    }
    else {
      this.availableCountries = [{ name: 'United States', id: '02' }];
      this.availableRegions = CountriesTools.getCountryRegions('us');
    }

    this.phoneNumber = new TelephoneNumberModel('', '', region);
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  private attached(): void {
    this.buyCreditService.retrieveCards().then((response) => {
      if (response.cards.length > 0) {
        this.cvvValidation();
      } else {
        this.setupCreditCardValidations();
      }
      if (!this.isSouthAfricanOrg) {
        this.subscribeToStripeForm();
      }

      this.buyCreditModel = new BuyCreditModel(response);
      this.subscribeToAccountActivity();
      this._setNextAvailableItemAsSelectedWhenListUpdated();
    });

    this.buyCreditService.retrievePersonalInfo().then((response) => {
      this.userProfile = {
        firstName: response.firstName,
        surname: response.surname,
        email: response.emails[0],
      };
    },
      (error) => {
        logger.info('Retrieving personal information > error =', error);
      });
  }

  private addStripeCard(cardToken: any): void {
    this.isProcessingCard = true;
    let customer: CustomerInterface = {
      firstName: this.userProfile.firstName,
      lastName: this.userProfile.surname,
      mobile: this.cardInfo.contactPhoneNumber.formattedNumber,
      email: this.userProfile.email
    };

    let source: SourceInterface = {
      provider: "STRIPE",
      type: "TOKEN",
      token: {
        value: cardToken.id
      }
    };

    let address: AddressInterface = {
      addressLine1: cardToken.card.address_line1,
      addressLine2: cardToken.card.address_line2,
      city: cardToken.card.address_city,
      region: cardToken.card.address_state,
      country: cardToken.card.country,
      code: cardToken.card.address_zip
    };

    let payload = {
      id: uuidv4(),
      reference: this.buyCreditModel.accountId,
      source: source,
      customer: customer,
      address: address,
      metadata: {
        stripeCustomerId: this.buyCreditModel.metadata.stripeCustomerId
      }
    };
    this.buyCreditService.addCard(payload).then(() => {
      this.isProcessingCard = false;
      this.hideAddCardPanel();
    }).catch((error) => {
      toastr.options = {
        'positionClass': 'toast-top-right',
        'progressBar': true,
        'timeOut': 10000,
        'extendedTimeout': 20000,
        'closeButton': true
      };
      let response = JSON.parse(error.response);

      toastr.error(response.message, 'Error');

      this.isProcessingCard = false;
    });

  }

  @computedFrom('sessionStore.get.account')
  private get account(): AccountSessionModel {
    return this.sessionStore.get.account;
  }

  @computedFrom('account.available')
  private get isSouthAfricanOrg(): boolean {
    return this.account ? this.account.available.currency === 'ZAR' : null;
  }

  @computedFrom('account.currencySymbol')
  private get currencySymbol(): string {
    return this.account ? this.account.currencySymbol : '';
  }

  private subscribeToStripeForm(): void {
    this.cardSubmittedSubscription = this.eventAggregator.subscribe('submitted.stripe.card', (cardInfo) => {
      this.cardInfo.contactPhoneNumber = cardInfo.result.phoneNumber;
      this.addStripeCard(cardInfo.result.token);
    });

    this.cardFailedSubscription = this.eventAggregator.subscribe('failed.stripe.card', (error) => {
      this.isProcessingCard = false;
      if (error) {
        toastr.error(error.message, 'Error');
      }
    });
  }

  private subscribeToAccountActivity(): void {
    this.buyCreditService.subscribe(this.buyCreditModel.accountId).then(oplog => {
      this.oplog = oplog;

      this.oplog.on('insert', data => this.handleOplogChanges(data));
      this.oplog.on('update', data => this.handleOplogChanges(data));
      this.oplog.on('delete', data => this.handleOplogChanges(data));
    });
  }

  private handleOplogChanges(buyCredit: BuyCreditModel): void {
    this.buyCreditModel.updatePropreties(buyCredit);
    this._setNextAvailableItemAsSelectedWhenListUpdated();
  }

  private _setNextAvailableItemAsSelectedWhenListUpdated(): void {
    if (this.buyCreditModel.cards.length > 0) {
      this.selectCard(this.buyCreditModel.cards[0]);
    }
  }

  private selectCard(selectedCard: CardModel): void {
    this._markAllCardsUnselected();
    this.cvvValidation();
    selectedCard.selected = true;
    this.selectedCard = selectedCard;
  }

  private _markAllCardsUnselected(): void {
    this.buyCreditModel.cards.forEach(card => {
      card.expires = this._checkIfDateIsExpired(card.expiry.month, card.expiry.year);
      card.selected = false;
    });
  }

  private _checkIfDateIsExpired(cardMonth: string, cardYear: string): string {
    if (cardMonth.length === 1) {
      cardMonth = '0' + cardMonth;
    }
    let daysInMonth = moment(cardYear + '' + cardMonth, 'YYYYMM').daysInMonth();
    let getCardExpiryDate = moment(cardYear + '' + cardMonth + '' + daysInMonth, 'YYYYMMDD').fromNow();
    if (getCardExpiryDate.indexOf('ago') > -1) {
      return 'Expired';
    }
    return 'Expires';
  }

  private _handlePaymentOplog(data: object): void {

    if (this.paymentId !== data._id) {
      return;
    }

    switch (data.status) {
      case 'Successful':
        this.unsubscribePaymentOplog();
        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Payment successful, your account has been credited with ${this.currencySymbol} ${this._convertAmount(this.amount, 'toRand')}`);
        if (this.accountIsInTrialState) {
          this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Your account has now been upgraded`);
        }
        this.isProcessingPayment = false;
        this.securePaymentMessage = false;
        this.dialogService.controllers.forEach(controller => {
          controller.cancel();
        });
        break;
      case 'Failed':
        this.isProcessingPayment = false;
        this.securePaymentMessage = false;
        this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'Payment unsuccessful. Please try again.');
        this.dialogService.controllers.forEach(controller => {
          controller.cancel();
        });
        break;
      default:
        this.securePaymentMessage = false;
        return;
    }

    this.showSecurePayment = false;
    this.unsubscribePaymentOplog();
    this.amount = '';
    this.cardDetailsForm.reset();
  }

  private showAddCardPanel(): void {
    this.setupCreditCardValidations();
    this.showAddCard = true;
    this.toggleAddCardPanel();
  }

  private hideAddCardPanel(): void {
    this.cvvValidation();
    if (this.isSouthAfricanOrg) {
      this.resetPaygateCardForm();
    }

    if (this.isProcessingCard !== true) {
      this.showAddCard = false;
      this.toggleAddCardPanel();
    }
  }

  private toggleAddCardPanel(): void {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    this.cardName = '';
    this.cardNumber = '';
    this.resetCardInformationFormGroup();
    this.resetDropdown = true;
  }

  private addCard(): void {
    if (this.isProcessingCard !== true) {
      this.validation.validate().then((validation) => {
        if (!validation.valid) {
          return;
        }
        this.isProcessingCard = true;

        let customer: CustomerInterface = {
          firstName: this.userProfile.firstName,
          lastName: this.userProfile.surname,
          mobile: this.cardInfo.contactPhoneNumber.formattedNumber,
          email: this.userProfile.email
        };

        let source: SourceInterface = {
          provider: "PAYGATE",
          type: "CARD",
          card: {
            number: this.cardNumber,
            holder: this.cardInfo.cardholderName,
            type: creditCardValidator.getCardName(this.cardNumber),
            expiry: {
              month: this._formatExpirationMonthNumber(this.expirationMonth.month),
              year: this.expirationYear.year
            }
          }
        };

        let address: AddressInterface = {
          addressLine1: this.cardInfo.address1,
          addressLine2: this.cardInfo.address2,
          city: this.cardInfo.city,
          region: this.cardInfo.region,
          country: this.cardInfo.country.id,
          code: this.cardInfo.postalCode
        };

        let payload = {
          id: uuidv4(),
          reference: this.buyCreditModel.accountId,
          source: source,
          customer: customer,
          address: address
        };

        this.buyCreditService.addCard(payload).then(() => {
          this.isProcessingCard = false;
          this.hideAddCardPanel();
        }).catch((e) => {
          this.isProcessingCard = false;

        });

      }, error => {
        this.isProcessingCard = false;
      });
    }
  }


  private submitStripeCard(): void {
    this.isProcessingCard = true;
    this.validation.validate()
      .then((validation) => {
        this.eventAggregator.publish('submit.stripe.card');
        if (!validation.valid) {
          this.isProcessingCard = false;
          return;
        }
      })
      .catch(e => {
        this.isProcessingCard = false;
      });
  }

  private removeCard(selectedCard: CardModel): void {

    if (selectedCard.inUse) {
      return this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'This card cannot be removed because it is in use elsewhere');
    }

    let id = selectedCard.cardReference ? selectedCard.cardReference : selectedCard.id;

    this.buyCreditService.removeCard(id).then(data => {
      this._setNextAvailableItemAsSelectedWhenListUpdated();
    }, error => {
      logger.warn('Failed to remove card >', error);
    });
  }

  private resetCardInformationFormGroup(): void {
    this.cvvNumber = '';
    this.amount = '';
  }

  private resetPaygateCardForm(): void {
    this.cardInfo.address1 = '';
    this.cardInfo.address2 = '';
    this.cardInfo.city = '';
    this.cardInfo.region = '';
    this.cardInfo.country.id = '';
    this.cardInfo.postalCode = '';
    this.cardInfo.cardholderName = '';
    this.cardInfo.companyName = '';
    this.cardInfo.contactPhoneNumber = new TelephoneNumberModel('', '', 'za', true, true);
  }

  private securePayment(): void {
    this.isProcessingPayment = true;
    this.validation
      .validate()
      .then(validation => {
        if (!validation.valid) {
          return;
        }
        this.paymentId = uuidv4();
        this.buyCreditService.subscribeToPaymentStatus(this.paymentId).then(oplog => {
          this.paymentOplog = oplog;

          this.paymentOplog.on('insert', payment => this._handlePaymentOplog(payment));
          this.paymentOplog.on('update', payment => this._handlePaymentOplog(payment));
          this.paymentOplog.on('delete', payment => this._handlePaymentOplog(payment));
        });
        return this.postSecurePayment();
      })
      .then(response => {
        this.checkIf3DSecure(response);
      })
      .catch(error => {
        this.isProcessingPayment = false;
      });
  }

  private checkIf3DSecure(response: { redirectUri: string, params: Array<object> }): void {
    if (response.redirectUri) {
      this.secureUrl = response.redirectUri;

      let params = response.params;

      let arr = [];

      for (let entry in params) {
        arr.push({ [entry]: params[entry] });
      }

      this.fields = params;
      this.open3DSecurePopup();
      this.securePaymentMessage = true;
    }
  }

  private postSecurePayment(): void {

    let provider = this.selectedCard.provider ? this.selectedCard.provider : this.selectedCard.paymentProvider;

    let source: SourceInterface = {
      provider: provider,
      type: "TOKEN",
      token: {
        value: this.selectedCard.token
      }
    };

    let customer: CustomerInterface = {
      firstName: this.userProfile.firstName,
      lastName: this.userProfile.surname,
      mobile: this.cardInfo.contactPhoneNumber.formattedNumber,
      email: this.userProfile.email
    };

    let payload = {
      id: this.paymentId,
      chargeRequestType: "EXISTING_CARD",
      reference: this.buyCreditModel.accountId,
      source: source,
      address: this.selectedCard.address,
      customer: customer,
      value: {
        currency: this.account.available.currency,
        amount: this._convertAmount(this.amount, 'toRand')
      },
      metadata: this.buyCreditModel.metadata
    };

    if (provider === 'PAYGATE') {
      payload.securityCode = this.cvvNumber;
    }
    return this.buyCreditService.securePayment(payload);
  }

  private _convertAmount(amount: string, convertAmount: string): string {

    let amountWithoutCurrency = amount.replace(this.currencySymbol, '').trim();
    let fullAmount = amountWithoutCurrency.split('.');
    let randAmount = fullAmount[0] ? fullAmount[0] : '';
    let centAmount = fullAmount[1] ? fullAmount[1] : '';

    if (convertAmount === 'toCents') {
      if (centAmount === '' || centAmount.length > 1) {
        amountWithoutCurrency = amountWithoutCurrency * 100;
      }
      else {
        amountWithoutCurrency = parseFloat(randAmount + centAmount);
        amountWithoutCurrency = amountWithoutCurrency * 10;
      }
    }

    if (convertAmount === 'toRand') {
      if (centAmount.length === 0) {
        amountWithoutCurrency = amountWithoutCurrency + '.00';
      }
      else if (centAmount.length === 1) {
        amountWithoutCurrency = parseFloat(randAmount + centAmount);
        amountWithoutCurrency = (amountWithoutCurrency / 10) + '0';
      }
    }
    return amountWithoutCurrency;
  }

  private open3DSecurePopup(): void {

    this.dialogService.open({
      viewModel: ThreeDSecureDialog,
      model: { url: this.secureUrl, fields: this.fields }
    }).whenClosed(() => {
      this.isProcessingPayment = false;
      this.securePaymentMessage = false;
      this.showSecurePayment = false;
      this.unsubscribePaymentOplog();
      this.amount = '';
      this.cardDetailsForm.reset();
    });
  }

  private _formatExpirationMonthNumber(rawMonthValue: string): string {
    let convertedMonthValue = this.dateTimeTools.getMonthNumber(rawMonthValue);
    if (convertedMonthValue < 10) {
      return '0' + convertedMonthValue;
    } else {
      return convertedMonthValue;
    }
  }

  private setupCreditCardValidations(): void {
    let checkCardBrand = (cardNumber) => {
      let cardType = creditCardValidator.getCardName(cardNumber);
      if (cardType === '') {
        return true;
      }
      return cardType === 'visa' || cardType === 'mastercard';
    };

    ValidationRules
      .customRule('validCreditCardRule', (value) => {

        if (!value) {
          return true;
        }
        return creditCardValidator.validateCard(value) && /^\d+$/.test(value) && value.length > 13 && value.length < 19;
      });

    ValidationRules
      .customRule('validCreditCardBrandRule', (value) => {
        return checkCardBrand(value);
      });

    ValidationRules
      .ensure('expirationMonth')
      .required().withMessage('Please select expiration month.')
      .ensure('expirationYear')
      .required().withMessage('Please select expiration year.')
      .ensure('cardNumber')
      .required().withMessage(PAYGATE_VALIDATION_MESSAGE.cardNumber.empty)
      .satisfiesRule('validCreditCardBrandRule').withMessage(PAYGATE_VALIDATION_MESSAGE.cardNumber.noSupported)
      .satisfiesRule('validCreditCardRule').withMessage(PAYGATE_VALIDATION_MESSAGE.cardNumber.invalid)
      .on(this)
      .ensure('cardholderName')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.empty)
      .matches(/^([a-zA-Z'\s-])+$/)
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.invalid)
      .ensure('streetAddress')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.address.empty)
      .ensure('city')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('postalCode')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.postalCode.empty)
      .ensure('region')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.region.empty)
      .on(this.cardInfo);
  }

  private cvvValidation(): void {
    if (this.isSouthAfricanOrg) {
      ValidationRules
        .ensure('cvvNumber')
        .required()
        .withMessage('CVV is required.')
        .matches(/^\d{3}$/).withMessage('CVV must be 3 digits, with no hyphens or spaces.')
        .ensure('amount')
        .required().withMessage('Credit amount must be numeric and greater than zero.')
        .on(this);
    } else {
      ValidationRules
        .ensure('amount')
        .required().withMessage('Credit amount must be numeric and greater than zero.')
        .on(this);
    }

  }

  private keyPress(event: Event): boolean {
    let numbers = /^[0-9 R$.]+$/;
    if (event.key.match(numbers)) {
      return true;
    }
    return false;
  }

  private keyCheck(): void {
    this._currencyCheckOnAmount();
    this._showPaymentButton();
  }

  private _showPaymentButton(): void {
    if (this.isSouthAfricanOrg && this.cvvNumber.length > 0 && this.amount.length > 1) {
      this.showSecurePayment = true;
    } else if (!this.isSouthAfricanOrg && this.amount.length > 1) {
      this.showSecurePayment = true;
    } else {
      this.showSecurePayment = false;
    }
  }

  private _currencyCheckOnAmount(): void {
    if (this.amount.charAt(0) !== this.currencySymbol && this.amount.length > 0) {
      this.amount = this.currencySymbol + ' ' + this.amount;
    }

    if (this.amount.indexOf('.') > -1) {
      let currentAmount = this.amount.split('.');
      if (currentAmount[1].length > 2) {
        this.amount = currentAmount[0] + '.' + currentAmount[1].substring(0, 2);
      }
    }

    if (this.amount.indexOf(this.currencySymbol + ' 0') > -1 || this.amount.indexOf(this.currencySymbol + '0') > -1 || this.amount.indexOf('  ') > -1) {
      this.amount = '';
    }
  }

  private unsubscribePaymentOplog(): void {
    if (this.paymentOplog) {
      this.paymentOplog.unsubscribe();
    }
  }

  private initializeYears(): Array<{ year: string }> {
    let currentYear = moment().year();
    let maxYear = currentYear + 50;
    let yearsArray = [];

    while (currentYear < maxYear) {
      yearsArray.push({ year: currentYear.toString() });
      currentYear++;
    }
    return yearsArray;
  }

  private initializeMonths(): Array<{ month: string }> {
    return moment.months().map((month: string) => {
      return { month: month };
    });
  }

  private detached(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
    if (!this.isSouthAfricanOrg) {
      if (this.cardSubmittedSubscription) {
        this.cardSubmittedSubscription.dispose();
      }
      if (this.cardFailedSubscription) {
        this.cardFailedSubscription.dispose();
      }
    }
    this.unsubscribePaymentOplog();
  }
}
