import {inject, LogManager} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('ChannelModel');
/*
 */
export class ChannelModel extends BaseModel {

  channelName = null;
  icon = null;
  types = null;

  constructor(channel) {
    super();
    this.mapProperties(this.prepare(channel));
  }

  prepare(channel) {

    let _createdChannel = {
      channelValue: channel
    };

    switch (channel) {
      case 'INBOUND_CALL':
        _createdChannel.icon = 'channel-incoming';
        _createdChannel.channelName = 'Inbound Call';
        _createdChannel.types = ['Contact Centre', 'Office'];
        break;
      case 'OUTBOUND_CALL':
        _createdChannel.icon = 'channel-outgoing';
        _createdChannel.channelName = 'Outbound Call';
        _createdChannel.types = ['Contact Centre', 'Office'];
        break;
      case 'CHAT':
        _createdChannel.icon = 'comments-square';
        _createdChannel.channelName = 'Chat';
        _createdChannel.types = ['Contact Centre'];
        break;
      case 'SMS':
        _createdChannel.icon = 'channel-sms';
        _createdChannel.channelName = 'SMS';
        _createdChannel.types = ['Contact Centre'];
        break;
      case 'EMAIL':
        _createdChannel.icon = 'channel-email';
        _createdChannel.channelName = 'Email';
        _createdChannel.types = ['Contact Centre'];
        break;
      case 'TICKET':
        _createdChannel.icon = 'channel-ticket';
        _createdChannel.channelName = 'Ticket';
        _createdChannel.types = ['Contact Centre'];
        break;
      case 'INSTANT_MESSAGE':
        _createdChannel.icon = 'comments-square';
        _createdChannel.channelName = 'Instant Message';
        _createdChannel.types = ['Contact Centre'];
        break;
      default :
        _createdChannel.icon = '';
        _createdChannel.types = [];
        break;
    }

    return _createdChannel;
  }
}
