import { HttpClient } from 'aurelia-http-client';
import { inject, LogManager, Factory } from 'aurelia-framework';
import { CommandFactory, SessionStore, OplogService } from 'zailab.common';
import { EventAggregator } from 'aurelia-event-aggregator';
/* */
const logger = LogManager.getLogger('OutboundFlowService');
/* */
@inject(HttpClient, EventAggregator, Factory.of(CommandFactory), SessionStore, OplogService)
export class OutboundFlowService {
  private memberId: string;

  constructor(private httpClient: HttpClient, private eventAggregator: EventAggregator, private commandFactory: CommandFactory, private sessionStore: SessionStore, private oplogService: OplogService) {
    this.getMemberId();
    this.subscribeToWorkflowChanges();
  }

  private getMemberId(): void {
    this.memberId = this.sessionStore.get.user.memberId;
  }

  private subscribeToWorkflowChanges(): void {
    this.oplogService
      .subscribeOn('_id', this.memberId)
      .in('member-projector.displayMemberProfileView')
      .on('update', data => this.eventAggregator.publish('update.workflow', data));

    this.oplogService
      .subscribeOn('_id', this.memberId)
      .in('member-projector.displayMemberProfileView')
      .on('delete', data => this.eventAggregator.publish('delete.workflow', data));
  }

  public retrieveOrganisationInteractionFlows(organisationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = { organisationId };
      this.httpClient
        .createRequest(`v1/organisation/members/${this.memberId}/profiles`)
        .asGet()
        .withParams(params)
        .send()
        .then(
          httpResponse => {
            resolve(httpResponse.outboundFlowOptions);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public selectOutboundCallOption(flowId: string): void {
    let payload = {flowId};

    this.httpClient.createRequest(`v1/organisation/members/${this.memberId}/outbound-flow-options`)
      .asPost()
      .withContent(payload)
      .send()
      .then(response => {}, error => {});
  }
}
