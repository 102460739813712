import { autoinject, customElement, LogManager, bindable } from "aurelia-framework";
import { computedFrom } from "aurelia-binding";
import { EventAggregator } from "aurelia-event-aggregator";
import { DialogService } from 'aurelia-dialog';

import { SessionStore } from "zailab.common";
import { FeatureFlagService } from "../../../features/featureflags/feature-flag-service";
import { LoginService } from "../../../features/user/passport/login/login-service";
import { UserSessionModel } from "../../../_common/stores/sessionmodels/user-session-model";
import { ApplicationProperties } from "../../../_config/application.properties";
import { ConfirmDialog } from "../../../features/organisation/conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog";
import { RoutingStatusService } from '../../../features/organisation/member/routing-status/routing-status-service';
import { WebphoneService } from "./webphone-service";

const logger = LogManager.getLogger('EmbeddedWebphone');

@autoinject
@customElement('z-embedded-webphone')
export class EmbeddedWebphone {

  public static WARNING_MESSAGE = {
    ON_CALL: 'This will end your call and put you in Off Duty.',
    ON_DUTY: 'This will put you in Off Duty.'
  };

  @bindable({ attribute: 'is-on-call' }) private isOnCall: boolean;
  @bindable({ attribute: 'routing-status' }) private routingStatus: string = '';

  public isEnabled: boolean = false;
  public allowEmbeddedWebphoneRefresh: boolean = false;
  public embeddedWebphone: HTMLIFrameElement;
  public webphoneUrl: string;

  private webphoneSource: string;
  private subscriptions: any[] = [];

  constructor(
    private sessionStore: SessionStore,
    private featureFlagService: FeatureFlagService,
    private loginService: LoginService,
    private applicationProperties: ApplicationProperties,
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private routingStatusService: RoutingStatusService,
    webphoneService: WebphoneService
  ) {
    const source: string | LocalWebphoneConfig = webphoneService.getSource();
    if (typeof source === 'string') {
      this.webphoneSource = source;
    } else if ('local' in source) {
      // using local here due to CORS issue with phone.dev.zailab.com locally
      this.webphoneSource = source.local;
    }
  }

  public bind(): void {
    if (this.isAgent) {
      this.getFeatureFlags();
      this.subscribeToInternalEvents();
    }
  }
  
  private getFeatureFlags(): void {
    
    this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
      .then(async (embeddedWebphoneEnabled) => {
        if (embeddedWebphoneEnabled) {
          this.setUrl();
          this.subscribeToWebphoneEvents();
          this.registerBeforeUnload();
        }
        this.isEnabled = embeddedWebphoneEnabled;
      });
  
    this.featureFlagService.isEnabled('allowEmbeddedWebphoneRefresh')
      .then(async (allowEmbeddedWebphoneRefresh) => {
        this.allowEmbeddedWebphoneRefresh = allowEmbeddedWebphoneRefresh;
      });
  }

  private subscribeToInternalEvents(): void {
    this.subscriptions.push(this.eventAggregator.subscribe('current.agent.status', routingStatus => {
      this.routingStatus = routingStatus;
    }));
  }

  private async setUrl(): Promise<void> {
    const accessCode = await this.loginService.retrieveAccessToken();
    if (accessCode) {
      this.webphoneUrl = this.webphoneSource + `?accessCode=${accessCode}&embedded=isEmbedded`;
    }
  }

  private subscribeToWebphoneEvents(): void {

    window.addEventListener("message", (event) => {
      const validOrigin = event.origin === this.webphoneSource;
      if (!validOrigin) {
        logger.warn('Invalid message origin. Ignoring messages from origin ', event.origin);
        return;
      }
      logger.info('message received >>> handling > ', event);
      switch(event.data) {
        case 'webphone-auth-failed':
        case 'webphone-reload':
          // get latest token and reset iframe
          this.setUrl();
          break;
        case 'webphone-initialized':
          this.eventAggregator.publish('EMBEDDED-WEBPHONE-INITIALIZED');
          break;
        case 'webphone-new-session':
        case 'webphone-peerconnection':
        case 'webphone-connecting':
          logger.info('set embedded webphone on call ');
          this.isOnCall = true;
          break;
        case 'webphone-ended':
        case 'webphone-failed':
          logger.info('set embedded webphone off call ');
          this.isOnCall = false;
          break;
        case 'webphone-missing-device':
          this.eventAggregator.publish('EMBEDDED-WEBPHONE-MISSING-DEVICE');
          break;
        case 'webphone-devices-found':
          this.eventAggregator.publish('EMBEDDED-WEBPHONE-DEVICES-FOUND');
          break;
        default:
          break;
      }
    }, false);
  }

  private registerBeforeUnload(): void {
    window.onbeforeunload = (event) => {
      logger.info(' > trigger BeforeUnload >>>>> ');
      if (this.isOnCall || this.routingStatus && this.routingStatus.toLowerCase() === 'ready') {
        event.preventDefault();
        (event || window.event).returnValue = ``;
        return '';
      }
    };

    window.addEventListener('unload', () => {
      if (this.isOnCall || this.routingStatus && this.routingStatus.toLowerCase() === 'ready') {
        const memberId = this.sessionStore.get.user.memberId;
        const token = this.sessionStore.get.user.token;

        const host = this.applicationProperties.apiQueryEndpoint;
        const url = `${host}v1/organisation/members/${memberId}/auto-change-routing-status?token=${token}`;
        const headers = {
          type: 'application/json'
        };
        const blob = new Blob([JSON.stringify({routingStatus: 'NOT_READY'})], headers);
        navigator.sendBeacon(url, blob);
      }
    });
  }


  public reloadIframe(): void {
    if (this.embeddedWebphone) {
      const message = this.isOnCall ? EmbeddedWebphone.WARNING_MESSAGE.ON_CALL : EmbeddedWebphone.WARNING_MESSAGE.ON_DUTY;
      this.dialogService
        .open({ viewModel: ConfirmDialog, model: { message } })
        .whenClosed(dialog => {
          if (!dialog.wasCancelled) {
            this.setUrl();
            this.eventAggregator.publish('AWAIT-EMBEDDED-WEBPHONE-INITIALIZATION');
            if (this.routingStatus && this.routingStatus.toLowerCase() === 'ready') {
              this.routingStatusService.setRoutingStatusToNotReady(this.user.memberId);
            }
            this.isOnCall = false;
          }
        });
    }
  }

  @computedFrom('user.hasAgentRole')
  public get isAgent(): boolean {
    if (this.user) {
      return this.user.hasAgentRole;
    }
    return false;
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }

  public unbind(): void {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }
}