import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('WorkTypeStatsModel');
/*
 */
export default class extends BaseModel {

  'Inbound Call' = 0;
  'Outbound Call' = 0;
  'Instant Message' = 0;
  Email = 0;
  SMS = 0;
  'Chat' = 0;
  Kiosk = 0;
  Ticket = 0;

  constructor(stats) {
    super();
    this.mapProperties(stats);
  }
}