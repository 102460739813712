import {AccountAddressModel} from '../account-adress-model';
import {BaseModel} from 'zailab.abstract';

export class AccountDetailPayloadModel extends BaseModel{

  public accountId: string = null;
  public address: AccountAddressModel = new AccountAddressModel();
  public companyName: string = null;
  public contactEmail: string = null;
  public contactFirstName: string = null;
  public contactSurname: string = null;
  public contactPhoneNumber: string = null;
  public vatNumber: string = null;

  constructor(companyInfo: any){
    super();
    this.mapProperties(companyInfo);
  }
}