
import { Container } from "aurelia-dependency-injection";
import { LogManager } from "aurelia-framework";

import { InteractionServiceStats } from './column-config/interaction-service-stats';
import { InteractionLiveStats } from "./column-config/interaction-live-stats";
import { InteractionTodayStats } from "./column-config/interaction-today-stats";
import { InteractionAgentStats } from "./column-config/interaction-agent-stats";
import { InteractionTodaySLAStats } from "./column-config/interaction-today-sla-stats";
import { InteractionTodayTransferStats } from "./column-config/interaction-today-transfer-stats";
import { ConfigMapper } from "./column-config/config-mapper";
import { computedFrom } from "aurelia-binding";

const logger = LogManager.getLogger('LiveDashboardState');

export class LiveDashboardState {


  private readonly settingName: string = 'zailab_live_dashboard_settings';
  public state: {
    refreshTime: number,
    services: any[],
    settings: IDashboardColumnConfig,
  };
  private configMapper: ConfigMapper = Container.instance.get(ConfigMapper);
  public defaultSettings = {
    operator: '>=',
    value: '0',
    colour: null,
    enabled: false
  };

  public defaultMappings: IDashboardColumnConfig = {
    ...(new InteractionServiceStats().columnConfig),
    ...(new InteractionAgentStats(this.configMapper).columnConfig),
    ...(new InteractionLiveStats(this.configMapper).columnConfig),
    ...(new InteractionTodayStats(this.configMapper).columnConfig),
    ...(new InteractionTodaySLAStats(this.configMapper).columnConfig),
    ...(new InteractionTodayTransferStats(this.configMapper).columnConfig)
  };

  constructor() {
    this.init();
  }

  private init(): void {
    if (!localStorage.getItem(this.settingName)) {
      this.setDefaultStateData();
    }
  }

  private setDefaultStateData(): void {
    const keys = Object.keys(this.defaultMappings);
    const defaultWidth = 1800 / keys.length;
    let settingsMap = {};

    settingsMap = keys.map((key, index) => {
      return this.addDefaultCellValues(this.defaultMappings[key], defaultWidth, index);
    })

    this.state = {
      refreshTime: 30,
      services: [],
      settings: settingsMap
    };
  }

  private addDefaultCellValues(mapping: { [key: string]: any }, defaultWidth: number, index: number): any {
    mapping.width = defaultWidth;
    mapping.index = index;
    mapping.data = mapping.data || {};
    return mapping;
  }

  public async saveSettings(data: any): Promise<void> {
    let settingsToSave;

    if (data && Array.isArray(data)) {
      settingsToSave = data.map((setting, index) => {
        return {
          orderIndex: index,
          ...setting
        };
      });
    }
    
    const localStorageState = await JSON.parse(localStorage.getItem(this.settingName));
    if (localStorageState && localStorageState.settings) {
      localStorage.setItem(this.settingName, JSON.stringify({
        refreshTime: localStorageState.refreshTime,
        services: localStorageState.services,
        settings: settingsToSave
      }));
    } else {
      localStorage.setItem(this.settingName, JSON.stringify({
        settings: settingsToSave
      }));
    }
  }

  public clear(): void {
    localStorage.removeItem(this.settingName);
    this.init();
  }

  public clearTimers(): void {
    this.configMapper.clearTimers();
  }

  public setRefreshTime(time: any): void {
    this.state.refreshTime = time;
  }

  public removeService(serviceId: string): void {
    this.state.services.forEach(service => {
      if (service.serviceId === serviceId) {
        service.isAdded = false;
        service.isSelected = false;
      } 
    });
  }

  @computedFrom('state.refreshTime')
  public get refreshTime(): number {
    return this.state.refreshTime;
  }

  @computedFrom('services', 'services.length')
  public get services(): any[] {
    return Object.assign([], this.state.services);
  }

  public async getMappings(colorScheme: IDashboardColumnColorConfig[]): Promise<any> {
    colorScheme = this.orderColorScheme(colorScheme);
    
    const localStorageState = await JSON.parse(localStorage.getItem(this.settingName));
    const localSettings = localStorageState ? localStorageState.settings : [];
    let localSettingsMap = {};
    localSettings.forEach(setting => {
      localSettingsMap[setting.title] = setting;
    });
    
    let colorConfigSettingsMap = {};
    colorScheme.forEach(config => {
      colorConfigSettingsMap[config.title] = config;
    });

    let dataToMap = [];

    if (!this.state) {
      this.state = {
        refreshTime: 30,
        services: [],
        settings: {}
      }
    }

    try {

      let promises = [];

      const allKeys = Object.keys(this.defaultMappings);
      allKeys.forEach((key, index) => {

        promises.push(new Promise(async resolve => {
          const scheme = colorConfigSettingsMap[key];

          let mappedData = {
            title: key,
            field: {
              ...this.defaultMappings[key],
              visible:
                scheme && scheme.visible !== undefined && scheme.visible !== null ?
                  scheme.visible : (
                    this.defaultMappings[key].visible === undefined ? true : this.defaultMappings[key].visible
                  ),
              settings: scheme && scheme.settings.length > 0 ? scheme.settings : [{ ...this.defaultSettings }],
              disabled: this.defaultMappings[key].disabled
            }
          };
          if (localSettingsMap[key]) {
            mappedData.field.orderIndex = localSettingsMap[key].orderIndex;
            mappedData.field.width = localSettingsMap[key].width;
          }

          dataToMap.push(mappedData);
          resolve({});
        }));
      });

      await Promise.all(promises);
    } catch (e) {
      logger.warn('mappings :: error=', e);
    } finally {
      const columnConfig = dataToMap.sort((a, b) => a.field.orderIndex - b.field.orderIndex);
      return columnConfig;
    }
  }

  private orderColorScheme(configData: any[]): any[] {
    if (configData && Array.isArray(configData)) {
      return configData.map(config => {
        config.settings = config.settings.sort(function(a: { value: number, colour: string }, b: { value: number, colour: string }): number {
          if(a.value === Infinity) { 
            return 1; 
          } else if( isNaN(a.value)) {
            return -1;
          } else { 
            return a.value - b.value;
          }
        }).reverse()
        return config;
      });
    }
    return null;
  }
}

export const LIVE_DASHBOARD_STATE = new LiveDashboardState();
