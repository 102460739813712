import { LogManager, inject, customElement, bindable, observable, containerless } from 'aurelia-framework';
/*
 */
import { SessionStore } from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('ZAuth');
/*
 */
@containerless()
@customElement('z-auth')
@inject(SessionStore)
export class ZAuth {

  @bindable @observable roles;

  constructor(sessionStore) {
    this.sessionStore = sessionStore;
  }

  created(owningView, myView) {
    this.myView = myView;
  }

  rolesChanged(newValue) {
    if (newValue) {
      if (this._userHasAccess()) {
        return;
      }
      this._removeSlots();
    }
  }

  _userHasAccess() {
    const userRoles = this.sessionStore.get.user.userAccessRoles;

    for (let userRole of userRoles) {
      for (let role of this.roles) {

        if (userRole.role === role) {
          return true;
        }
      }
    }
    return false;
  }

  _removeSlots() {
    this.myView.slots = {};
  }
}