import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
import {SessionStore, OplogService} from 'zailab.common';
import {InvitationModel} from './invitation-model';
import OrganisationModel from "../../../organisation/organisation/organisation-model";
/*
 * */
const logger = LogManager.getLogger('InvitationsService');

/*
 * */
@inject(HttpClient, EventAggregator, SessionStore, OplogService)
export class InvitationsService {

  constructor(httpClient, eventAggregator, sessionStore, oplogService) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
  }

  initialisePendingInvitationsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('registration-projector.pendingInvitationView'));
    });
  }

  retrieveInvitations() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/user/invitations/pending')
        .asGet()
        .withParams({organisationId: organisationId})
        .send()
        .then(
          (response) => {
            let invitations = [];
            if (response && response.invitations && response.invitations[0]) {
              invitations = response.invitations;
            }
            resolve(this._model(invitations));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  retrievePendingInvitationsStats() {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/user/invitations/widget')
        .asGet()
        .send()
        .then((response) => {
          let _data = {
            count: 0,
            invitations: [],
            pricingModel: null
          };
          if (response && response.invitations) {
            _data.count = response.count;
            _data.invitations = response.invitations;
            _data.pricingModel = response.pricingModel;
          }
          resolve(_data);
        }, (error) => {
          reject(error);
        });
    });
  }

  retrievePendingInvitations() {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/user/invitations/pending')
        .asGet()
        .send()
        .then((response) => {

          let _data = {
            pendingInvitations: []
          };

          if (response && response.pendingInvitationIds) {
            _data.count = response.pendingInvitationIds.length;
          }

          if (response && response.invitations) {
            _data.pendingInvitations = response.invitations;
          }
          resolve(_data);
        }, (error) => {
          reject(error);
        });
    });
  }

  retrieveOrganisationInfo() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest('v1/organisation/organisations/me/information')
        .asGet()
        .send()
        .then(response => {
          resolve(this._modelEntity(response.organisation));
        }, error => {
          reject(error);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  _modelEntity(item) {
    return new OrganisationModel(item);
  }


  initialiseStatsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('registration-projector.invitationWidgetView'));
    });
  }

  _model(invitationData) {
    let invitationList = [];
    invitationData.forEach(invitation => {
      invitationList.push(new InvitationModel(invitation));
    });
    return invitationList;
  }

  revokeInvitation(invitation) {
    this.httpClient.createRequest(`v1/user/invitations/${invitation.invitationId}/revoke`)
      .asDelete()
      .withContent({invitationId: invitation.invitationId})
      .send();
  }
}
