import {LogManager, autoinject} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/**/
import {SessionStore} from 'zailab.common';
import {WorkTypeModel} from './models/work-type-model';
import {InteractionFlow} from '../../interaction-flow';
/**/
const logger = LogManager.getLogger('WorkTypeService');

/**/
@autoinject
export class WorkTypeDialogService {

  private httpClient: HttpClient;
  private sessionStore: SessionStore;
  private interactionFlow: InteractionFlow;

  constructor(httpClient: HttpClient, sessionStore: SessionStore, interactionFlow: InteractionFlow) {

    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.interactionFlow = interactionFlow;
  }

  public retrieveWorkTypes(name: string, isCallback: boolean): Promise<Array<WorkTypeModel>> {

    let flowType: string = this.interactionFlow.interactionFlowType;
    let channel: string = this.interactionFlow.interactionFlowChannel;

    let uri: string = `v1/organisation/task-templates/search?flowType=${flowType}&channel=${channel}&name=`;

    if (isCallback) {
      uri = `v1/organisation/task-templates/search?templateType=callback&name=`;
    }

    return new Promise((resolve: any, reject: any) => {
      this.retrieve(uri).then(
        (data: any) => {
          let workTypes = this.modelCollection(data.taskTemplates, WorkTypeModel);
          resolve(workTypes);
        }, reject);
    });
  }

  private retrieve(uri: string): Promise<Array<any>> {

    return new Promise((resolve: any, reject: any) => {

      this.httpClient.createRequest(uri)
        .asGet()
        .send()
        .then(resolve, reject);
    });
  }

  public modelCollection(items: any, Model: any): any {
    if (!items) {
      return;
    }

    if (items instanceof Array) {
      let mappedList: Array<any> = [];

      for (let item of items) {
        mappedList.push(new Model(item))
      }

      return mappedList;
    }

    return new Model(items);
  }
}