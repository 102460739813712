import { autoinject, LogManager } from 'aurelia-framework';
import { SessionStore } from '../../../../_common/stores/session-store';
import { AssistService } from '../assist-service';

const logger = LogManager.getLogger('AssistAction');

@autoinject
export class AssistAction {

  public loading: boolean = false;
  public handRaised: boolean = false;
  public assistId: string;
  public memberId: string;

  constructor(
    private assistService: AssistService,
    private session: SessionStore
  ) { }

  public activate(): void {
    if (!this.session.get.user.hasAgentRole) {
      return;
    }
    this.findMemberAssist();
    setInterval(() => {
      this.findMemberAssist();
    }, 10000);
  }

  public toggleHand(): void {
    this.loading = true;
    if (this.handRaised && this.assistId) {
      this.assistService.postLowerHand(this.assistId, this.memberId)
        .then(() => this.checkForUpdate(false))
        .catch((error) => logger.warn('postLowerHand :: error=', error));
    } else {
      this.assistService.postRaiseHand()
        .then(() => this.checkForUpdate(true))
        .catch((error) => logger.warn('postRaiseHand :: error=', error));
    }
  }

  private checkForUpdate(handRaised: boolean): void {
    setTimeout(() => {
      this.findMemberAssist()
        .then(() => {
          if (handRaised === this.handRaised) {
            this.loading = false;
          } else {
            this.checkForUpdate(handRaised);
          }
        });
    }, 500);
  }

  private findMemberAssist(): Promise<any> {
    return this.assistService.findAllMemberAssists()
      .then((response) => {
        if (!response || !response.length) {
          return;
        }
        this.handRaised = response[response.length - 1].handRaised;
        this.assistId = response[response.length - 1].assistId;
        this.memberId = response[response.length - 1].memberId;
        return true;
      })
      .catch((error) => logger.warn('findAllMemberAssists :: error=', error));
  }
}
