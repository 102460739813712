import { DialogController, DialogService } from 'aurelia-dialog';
import { computedFrom, autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { DownloadService, SessionStore, FeatureFlagService } from 'zailab.common';
import { ContactService } from '../../../../organisation/contact/contact-service';
import { ConversationService } from '../../../../organisation/conversation/conversation-service';
import { IMInteractionCardService } from '../../../../organisation/conversation/interactioncards/instantmessage/im-interaction-card-service';
import { ViewInteractionTranscriptChatDialog } from '../../interactiontranscript/view-interaction-transcript-chat-dialog';
import { ViewInteractionTranscriptDialog } from "../../interactiontranscript/view-interaction-transcript-dialog";
import { ViewInteractionTranscriptIMDialog } from '../../interactiontranscript/view-interaction-transcript-im-dialog';
import InteractionLogService from "../interaction-log-service";
import { ViewInteractionPhraseAnalyticsDialog } from "../interactionlogphraseanalytics/view-interaction-phrase-analytics-dialog";
import { ViewLinkedInteractionDialog } from '../viewlinkedinteraction/view-linked-interaction-dialog';

import './view-interaction-dialog.scss';

const logger = LogManager.getLogger('ViewInteractionDialog');

@autoinject()
export class ViewInteractionDialog {

    public interaction = null;
    public dialogHeader = '';
    public retrievingRecording = false;
    public showEmailRecipients = false;
    public color;
    public payloadDetail = null;
    public additionalData = [];
    public mappingAdditionalData;
    public formData = [];
    public comments = null;
    public callTranscript = {};
    public hasTranscript = false;
    public hasChatTranscript = false;
    public detectedPhrases = [];
    public phrasesDetected = false;
    public consultInteractions = [];
    public linkedConsultingInteraction = null;
    public chatTranscript;
    public hasIMTranscript;
    public imTranscript;
    public viewRecordingsDisabled = true;
    public recordingRemoved = false;

    ONE_DAY_IN_MS = 86400000;
    ONE_WEEK_IN_MS = 7 * this.ONE_DAY_IN_MS;

    constructor(
      private sessionStore: SessionStore,
      private dialogController: DialogController,
      private httpClient: HttpClient,
      private downloadService: DownloadService,
      private conversationService: ConversationService,
      private dialogService: DialogService,
      private interactionLogService: InteractionLogService,
      private imInteractionCardService: IMInteractionCardService,
      private contactService: ContactService,
      private featureFlagService: FeatureFlagService
    ) {}

    /**
     * gets called when the dialog view model is activated.
     * @param interaction
     * */
    public activate(interaction: any): void {
        this.interaction = interaction;
        if(this.interaction && this.interaction.payload) {
          this.payloadDetail = [{
            label: 'Cc',
            list: interaction.payload.ccs
          },{
            label: 'Bcc',
            list: interaction.payload.bccs
          }];
        }

        this.featureFlagService.isEnabled('viewRecordingsDisabled')
          .then(async (viewRecordingsDisabled) => {
            this.viewRecordingsDisabled = viewRecordingsDisabled;
          });

        this.conversationService.retrieveInteraction(interaction.interactionId)
          .then((interaction) => {
            if (interaction.additionalData) {

              this.additionalData = [];
              let promises = [];
              this.mappingAdditionalData = true;
    
              // @ts-ignore
              let additionalData = Object.entries(interaction.additionalData);
              additionalData.forEach(
                data => promises.push(
                  new Promise(async resolve => {
                    let prop = data[0];
                    let value: any = data[1];

                    if (prop.startsWith('vb_')) {
                      resolve(null);
                    }
    
                    let item: any = { name: prop, value };

                    if (prop.startsWith('Link_')) {
                      item.name = item.name.replace('Link_', '');
                      item.isLink = true;
                    } else if (prop.startsWith('IFrame_')) {
                      item.name = item.name.replace('IFrame_', '');
                      item.isIFrameLink = true;
                    } else if (prop.startsWith('Player_')) {
                      item.name = prop.replace('Player_', '');
                      item.url = value;
                      item.isPlayerLink = true;
                    } else if (prop.startsWith('ZailabRecording_')) {
                      const recordingUrl = await this.retrieveInteractionRecording(value);
                      item.name = prop.replace('ZailabRecording_', '');
                      item.url = recordingUrl;
                      item.isZailabRecording = true;
                    } else if (prop.startsWith('Display_')) {
                      const parts = item.name.split('_');
                      item.name = parts[2];
                      item.requestProperty = parts[1];
                      const value = await this.getAdditionalDataDisplay(item);
                      item.value = value;
                      item.isDisplay = true;
                    } else if (prop.startsWith('Button_')) {
                      const parts = item.name.split('_');
                      item.name = parts[2];
                      item.requestMethod = parts[1];
                      item.isButton = true;
                    }
                    resolve(item);
                  })
                )
              );
    
              Promise.all(promises).then((results) => {
                this.additionalData = results.filter(item => !!item);
                this.mappingAdditionalData = false;
              });

              if (interaction.metaData && interaction.metaData.disposition && !this.interaction.disposition) {
                this.interaction.disposition = interaction.metaData.disposition;
              }

              if (interaction.metaData && interaction.metaData.disposition ) {
                this.interaction.dispositionCategory = interaction.metaData.dispositionCategory;
              }

              if (interaction.metaData && interaction.metaData.dispositionSubcategory) {
                this.interaction.dispositionSubcategory = interaction.metaData.dispositionSubcategory;
              }
            }

            if (interaction.formData) {
              this.formData = interaction.formData;
            }

            if (interaction.comments) {
              this.comments = interaction.comments;
            }

            if (interaction.callSentiment) {
              this.interaction.callSentiment = interaction.callSentiment;
            }

            if (interaction.callTranscript) {
              this.hasTranscript = interaction.callTranscript.words && interaction.callTranscript.words.length > 0;
              this.callTranscript = interaction.callTranscript;
            }

            if (interaction.transcript) {
              this.hasChatTranscript = interaction.transcript.messages && interaction.transcript.messages.length > 0;
              this.chatTranscript = interaction.transcript;
            }

            if (interaction.detectedPhrases && interaction.detectedPhrases.length > 0) {
              this.detectedPhrases = interaction.detectedPhrases;
              this.phrasesDetected = true;
            }

            if (
              interaction.metaData && (
                interaction.metaData.channel === 'INSTANT_MESSAGE' ||
                interaction.metaData.channel === 'CHAT'
              )
            ) {
              this.imInteractionCardService.getTranscript(this.interaction.interactionId)
                .then((transcript) => {
                  this.hasIMTranscript = transcript.messages && transcript.messages.length > 0;
                  this.imTranscript = transcript;
                });
            }
        }, error => {
          logger.error('Unable to retrieve conversation interaction ', interaction.interactionId);
        });

      if (interaction.consultInteractionIds && interaction.consultInteractionIds.length > 0) {
        interaction.consultInteractionIds.forEach(interactionId => {
          this.interactionLogService.retrieveInteraction(interactionId)
            .then((result) => {
              if (interaction) {
                const consultInteraction = result.content[0];
                consultInteraction.interactionId = interactionId;
                this.consultInteractions.push(consultInteraction);
              }
            });
        });
      }

      if (interaction.linkedConsultingInteractionId) {
          this.interactionLogService.retrieveInteraction(interaction.linkedConsultingInteractionId)
            .then((result) => {
              if (interaction) {
                const consultingInteraction = result.content[0];
                consultingInteraction.interactionId = interaction.linkedConsultingInteractionId;
                this.linkedConsultingInteraction = consultingInteraction;
              }
            });

        }
    }

    public attached(): void {
            this.dialogHeader = `${this.interaction.formattedChannel} ${this.interaction.formattedWorkType === '-' ? '' : ` - ${this.interaction.formattedWorkType}`}`;

    if (this.interaction.formattedOutcome === 'answered') {
      this.color = 'green';
    } else if (this.interaction.formattedOutcome === 'abandoned') {
      this.color = 'red';
    }else if (this.interaction.formattedOutcome === 'missed') {
      this.color = 'red';
    }else if (this.interaction.formattedOutcome === 'forwarded') {
      this.color = 'yellow';
    } else if (this.interaction.formattedOutcome === 'mailbox') {
      this.color = 'yellow';
    } else if (this.interaction.formattedOutcome === 'callback') {
      this.color = 'yellow';
    } else if (this.interaction.formattedOutcome === 'sent') {
      this.color = 'green';
    } else if (this.interaction.formattedOutcome === 'received') {
      this.color = 'green';
    } else if (this.interaction.formattedOutcome === 'served') {
      this.color = 'green';
    }
  }

  public triggerAdditionalDataButton(data: any): void {
    this.contactService.triggerRequest(data.value, data.requestMethod);
  }

  private async getAdditionalDataDisplay(data: any): Promise<string> {
    return await this.contactService.fetchFromUrl(data.value, data.requestProperty);
  }

  public viewAnalytics(phrase: string): void {
    let toDate = new Date().getTime();
    let fromDate = toDate - this.ONE_WEEK_IN_MS;
    this.interactionLogService.retrieveAnalytics(phrase, fromDate, toDate).then(response => {
      let analytics = response;
      let analyticsPayload: any = {};
      analyticsPayload.phrase = phrase;
      analyticsPayload.results = analytics.results;

      this.dialogService
        .open({
          viewModel: ViewInteractionPhraseAnalyticsDialog,
          model: analyticsPayload
        })
        .whenClosed(response => {
          if (response.wasCancelled) {

          }
        });
    }, error => {
      logger.error('An error occurred fetching analytics ', error);
    });

  }

  public viewTranscript(): void {
  this.dialogService
    .open({
      viewModel: ViewInteractionTranscriptDialog,
      model: {'transcript': this.callTranscript, 'interaction': this.interaction}
    })
    .whenClosed(response => {
      if (!response.wasCancelled) {
      }
    });
  }

  public viewChatTranscript(): void {
    this.dialogService
      .open({
        viewModel: ViewInteractionTranscriptChatDialog,
        model: {'transcript': this.chatTranscript, 'interaction': this.interaction}
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
        }
      });
  }

  public viewIMTranscript(): void {
    this.dialogService
      .open({
        viewModel: ViewInteractionTranscriptIMDialog,
        model: {'transcript': this.imTranscript, 'interaction': this.interaction}
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
        }
      });
  }

  public retrieveRecording(): void {
    this.retrievingRecording = true;
    this.interactionLogService.retrieveRecording(this.interaction.recordingId).then(response => {
      this.retrievingRecording = false;
      this.interaction.recordingURL = response.recordingURL;
    }, error => {
      this.retrievingRecording = false;
    });

  }

  public async retrieveInteractionRecording(interactionId: string): Promise<string> {
    const response = await this.interactionLogService.retrieveRecording(interactionId);
    if (response) {
      return response.url || response.recordingURL;
    }
  }

  public performQA(): void {
    let performQAResponse = {
      interactionId: this.interaction.interactionId,
      recordingId: this.interaction.recordingId ? this.interaction.recordingId : 'noRecording',
      memberId: this.interaction.members && this.interaction.members.length > 0 ? this.interaction.members[0].memberId : 'noMembers',
      callType: this.interaction.type,
      channel: this.interaction.channel,
      firstName: this.interaction.members[0].firstName,
      surname: this.interaction.members[0].surname,
      performQA: true
    };
    this.dialogController.ok(performQAResponse);
  }

  public viewInteraction(interaction: any): void {
    this.dialogService
      .open({
        viewModel: ViewLinkedInteractionDialog,
        model: interaction
      })
      .whenClosed(response => {
        if (response.wasCancelled) {

        }
      });
  }

  public toggleRecipients(event: Event): void {
    event.stopPropagation();
    this.showEmailRecipients = !this.showEmailRecipients;
  }


  /**
   * Closes the Aurelia dialog
   */
   public cancel(): void {
    this.interaction = null;
    this.dialogController.cancel();
  }

  @computedFrom('sessionStore.get.user')
  public get user(): any {
    return this.sessionStore.get.user;
  }

  @computedFrom('user.hasQAManagerRole')
  public get hasQAManagerRole(): boolean {
    return this.user.hasQAManagerRole;
  }

  @computedFrom('user.hasQARole')
  public get hasQARole(): boolean {
    return this.user.hasQARole;
  }

  @computedFrom('user.hasAgentRole')
  public get hasAgentRole(): boolean {
    return this.user.hasAgentRole;
  }

  @computedFrom('interaction.recordingId', 'interaction.channel', 'interaction.type', 'hasQAManagerRole', 'hasQARole')
  public get canPerformQA(): boolean {
    if (this.interaction) {
      return (this.interaction.recordingId || this.interaction.formattedChannel === 'Outbound Email' || this.interaction.channel === 'INSTANT_MESSAGE' || this.interaction.channel === 'CHAT') &&
        (this.hasQAManagerRole || this.hasQARole) &&
        this.interaction.type !== 'Office Flow';
    }
  }

  @computedFrom('viewRecordingsDisabled', 'hasAgentRole')
  public get canRetrieveRecording(): boolean {
    return !(this.viewRecordingsDisabled && this.hasAgentRole);
  }
}
