import { OplogManager } from './oplog-manager';
import { ReminderService } from './../../conversation/reminder/reminder-service';
import { StatusModel } from './../../conversation/status/status-model';
import { ChannelService } from './../../conversation/channel/channel-service';
import { CONTACT_ACTIONS } from './contact-controller-actions';
import { InteractionService } from './../../interactions/interaction-service';
import { ConversationModel } from './../../conversation/conversation-model';
import { ConversationFactory } from './../../conversation/conversation-factory';
import { INTERACTION_ACTIONS } from './../../conversation/interaction/interaction-actions';
import { ContactService } from './../contact-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { LogManager } from 'aurelia-framework';
import { DispositionCodesService } from './../../organisation/dispositioncodes/disposition-codes-service';
import {
  Disposition,
  DispositionModel,
} from './../../organisation/dispositioncodes/disposition-codes-model';
import { SessionStore, MESSAGE_EVENTS } from 'zailab.common';
import { ConversationService } from './../../conversation/conversation-service';
import { ContactFactory } from './../contact-factory';
import { ContactModel } from './../contact-model';
import { ContactState } from './contact-state';
import { TicketModel } from './../../conversation/interaction/ticket-model';
import { autoinject, Container } from 'aurelia-framework';
import { InteractionModel } from '../../conversation/interaction-model';

// @ts-ignore
import moment from 'moment';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { ZIOplog } from '../../../../../typings/zai/zai.common';

const logger = LogManager.getLogger('ContactCardTools');

@autoinject
export class ContactCardTools {
  private contactService: ContactService =
    Container.instance.get(ContactService);
  private contactFactory: ContactFactory =
    Container.instance.get(ContactFactory);
  private conversationService: ConversationService =
    Container.instance.get(ConversationService);
  private sessionStore: SessionStore = Container.instance.get(SessionStore);
  private dispositionCodesService: DispositionCodesService =
    Container.instance.get(DispositionCodesService);
  private eventAggregator: EventAggregator =
    Container.instance.get(EventAggregator);
  private conversationFactory: ConversationFactory =
    Container.instance.get(ConversationFactory);
  private interactionService: InteractionService =
    Container.instance.get(InteractionService);
  private channelService: ChannelService =
    Container.instance.get(ChannelService);
  private reminderService: ReminderService =
    Container.instance.get(ReminderService);
  private oplogManager: OplogManager = Container.instance.get(OplogManager);

  private contactState: ContactState;

  private eaSubscriptions: { [k: string]: Subscription } = {};
  private validateInteractionExists: ValidateExistence;
  private validateContactExists: ValidateExistence;
  private validateConversationtExists: ValidateExistence;

  private dialingDebounced: boolean = false;

  constructor() {
    this.validateContactExists = new ValidateExistence(
      'CONTACT.EXISTS',
      this.contactService,
      'retrieveContact',
      ['contactId'],
      'getContactOplog',
      'contactId',
      this.eventAggregator,
      'insert'
    );
    this.validateInteractionExists = new ValidateExistence(
      'INTERACTION.EXISTS',
      this.conversationService,
      'retrieveInteraction',
      ['interactionId'],
      'getInteractionOplog',
      'interactionId',
      this.eventAggregator,
      'insert'
    );
    this.validateConversationtExists = new ValidateExistence(
      'CONVERSATION.EXISTS',
      this.conversationService,
      'retrieveInteractions',
      ['conversationId', 'contactId'],
      'getConversationOplog',
      'conversationId',
      this.eventAggregator,
      'insert'
    );
  }

  public defineContactState(contactState: ContactState): void {
    this.contactState = contactState;
  }

  public determineIfInteractionCreated(
    interaction: InteractionModel | TicketModel,
    callback: Function
  ): void {
    let eventName = this.validateInteractionExists.NOTIFY_EXISTENCE;
    if (this.eaSubscriptions[eventName]) {
      this.removeEASubscription(eventName);
    }
    this.eaSubscriptions[eventName] = this.eventAggregator.subscribe(
      eventName,
      (rawData) => {
        callback({ interaction, rawData });
        this.validateInteractionExists &&
          this.removeEASubscription(
            this.validateInteractionExists.NOTIFY_EXISTENCE
          );
      }
    );
    this.validateInteractionExists.verifyIfExists(interaction);
  }

  public subscribeToInteractionLinkedToConversation(callback: Function): void {
    this.eventAggregator.subscribe(
      INTERACTION_ACTIONS.INTERACTION_LINKED_TO_CONVERSATION,
      (data: { interactionId: string; contactId: string }) => callback(data)
    );
  }

  private removeEASubscription(event: string): void {
    this.eaSubscriptions[event] &&
      this.eaSubscriptions[event].dispose &&
      this.eaSubscriptions[event].dispose();
    delete this.eaSubscriptions[event];
  }

  public findOrCreateContactCard(
    interaction: InteractionModel | TicketModel
  ): ContactModel {
    if (interaction.recentActivity) {
      let contactCard = this.createContactCard(interaction.contactId);
      if (interaction.correlationId) {
        contactCard.correlationId = interaction.correlationId;
      }
      return contactCard;
    }

    if (
      interaction.contactId ||
      interaction.correlationId ||
      interaction.conversationId
    ) {
      let matchedByCorrelationIdContactCard = this.contactState.getContactCard(
        interaction.contactId,
        interaction.correlationId,
        interaction.conversationId
      );
      if (matchedByCorrelationIdContactCard) {
        return matchedByCorrelationIdContactCard;
      }
    }
    let contactCard: ContactModel = this.createContactCard(
      interaction.contactId
    );
    if (interaction.correlationId) {
      contactCard.correlationId = interaction.correlationId;
    }
    return contactCard;
  }

  private createContactCard(contactId?: string): ContactModel {
    return this.contactFactory.build(contactId);
  }

  public isExistingContactCard(
    contactId: string,
    correlationId: string
  ): ContactModel {
    return this.contactState.getContactCard(contactId, correlationId);
  }

  public handleSearchContact(searchText: any): void {
    let contactCard = this.contactState.selectedContactCard();
    let number = '';
    let email = '';
    if (!isNaN(searchText)) {
      number = searchText;
    }
    if (this.validateEmail(searchText)) {
      email = searchText;
    }
    contactCard.searchContacts(searchText, number, email, 0, true);
  }

  private validateEmail(email: string): boolean {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public selectedDispositionCode(data: {
    interactionId: string;
    correlationId: string;
    conversationId: string;
  }): void {
    this.conversationService
      .retrieveInteraction(data.interactionId)
      .then((interaction) => {
        let organisationId = this.sessionStore.get.organisation.organisationId;
        let serviceId = interaction.metaData.serviceId;
        let tiered = interaction.metaData.tiered;
        let direction =
          interaction.metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
        let channel;
        switch (interaction.metaData.channel) {
          case 'VOICE':
            channel = `${direction} Call`;
            break;
          case 'EMAIL':
            channel = 'Email';
            break;
          case 'CHAT':
            channel = 'Chat';
            break;
          case 'INSTANT_MESSAGE':
          case 'instant_message':
            channel = 'Instant Message';
            break;
          case 'TICKET':
            channel = 'Ticket';
            break;
          default:
            channel = interaction.metaData.channel;
            break;
        }
        this.dispositionCodesService
          .retrieveDispositionListByServiceChannelAndDirection(
            organisationId,
            serviceId,
            channel,
            direction,
            tiered
          )
          .then((dispositionList: DispositionModel) => {
            if (
              dispositionList &&
              dispositionList.dispositionCodes &&
              dispositionList.dispositionCodes.length > 0
            ) {
              this.contactState.showDispositionList(data, dispositionList);
            }
          });
      });
  }

  public memberDisconnectedFromOutboundFlowCall(data: any): void {
    let unansweredDiallerCall = data.isDiallerCall && !data.wasAnswered;
    this.conversationService
      .retrieveInteraction(data.interactionId)
      .then((interaction) => {
        let organisationId = this.sessionStore.get.organisation.organisationId;
        let serviceId = interaction.metaData.serviceId;
        let direction =
          interaction.metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
        let channel =
          interaction.metaData.channel === 'VOICE'
            ? `${direction} Call`
            : interaction.channel;
        if (!unansweredDiallerCall) {
          this.dispositionCodesService
            .retrieveDispositionListByServiceChannelAndDirection(
              organisationId,
              serviceId,
              channel,
              direction,
              interaction.metaData.tiered
            )
            .then((dispositionList: DispositionModel) => {
              if (
                !dispositionList ||
                !dispositionList.dispositionCodes ||
                !dispositionList.dispositionCodes.length
              ) {
                this.contactState.showDispositionCode(data);
              }
            });
        }
      });
    //TODO: Revisit this for power dialler
    // if (!unansweredDiallerCall) {
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | is on call event | setting off call '
    );
    // }
  }

  public callDisconnected(data: any): void {
    this.contactState.injectThirdPartyContactData(data);
    if (data.screenPopUrl) {
      window.open(data.screenPopUrl, '_blank');
    }
    if (data.numberOfContacts > 0) {
      this.eventAggregator.publish(CONTACT_ACTIONS.THIRD_PARTY_MATCHES, {
        interactionId: data.interactionId,
        numberOfContacts: data.numberOfContacts,
        view: 'unknownContact',
      });
    }
  }

  public createContact(contact: ContactModel): void {
    let selectedContactCard = this.contactState.selectedContactCard();
    contact.telephoneNumbers = selectedContactCard.telephoneNumbers;
    contact.emails = selectedContactCard.emails;

    contact.contactId = uuidv4();

    let eventName = this.validateContactExists.NOTIFY_EXISTENCE;
    if (this.eaSubscriptions[eventName]) {
      this.removeEASubscription(eventName);
    }
    this.eaSubscriptions[eventName] = this.eventAggregator.subscribe(
      eventName,
      (rawData) => {
        this.oplogManager.subscribeToContactInfoChanges(contact.contactId);
        this.oplogManager.subscribeToUnlinkedInteractionChanges(
          contact.contactId
        );
        this.oplogManager.subscribeToConversationChanges(contact.contactId);
        this.continueContactFlow(contact, rawData);
      }
    );
    this.validateContactExists.verifyIfExists(contact, () => {
      this.contactService.createContact(contact);
    });
  }

  public handleInteractionLinkedToConversation(
    data: { interactionId: string; contactId: string },
    populateCallback: Function
  ): void {
    let contactCard = this.contactState.getContactCard(data.contactId);
    if (contactCard) {
      let linkedInteraction = {} as InteractionModel | TicketModel;
      contactCard.unlinkedInteractions.forEach((interaction) => {
        if (interaction.interactionId === data.interactionId) {
          linkedInteraction = interaction;
        }
      });
      contactCard.unlinkedInteractions =
        contactCard.unlinkedInteractions.filter(
          (interaction) => interaction.interactionId !== data.interactionId
        );
      if (linkedInteraction.conversationId) {
        let unlinkedConversationInteractions =
          contactCard.unlinkedInteractions.filter(
            (interaction) =>
              interaction.conversationId === linkedInteraction.conversationId
          );
        unlinkedConversationInteractions.forEach((unlinkedInteraction) =>
          populateCallback(unlinkedInteraction)
        );
      }
    }
  }

  public selectDisposition(disposition: Disposition): void {
    const contact = this.contactState.contactTabs.find(
      (contact: ContactModel) => contact.isSelected
    );

    if (!disposition || !contact.dispositionInteractionId) {
      return;
    }

    const interactionId = contact.dispositionInteractionId;
    const memberId = this.sessionStore.get.user.memberId;
    const dispositionCodeId = disposition.dispositionCodeId;
    const comments = disposition.comments;

    this.dispositionCodesService.putDispositionCodeOnInteraction(
      interactionId,
      memberId,
      dispositionCodeId,
      comments
    );
  }

  public changeReminder(info: {
    contactId: string;
    conversationId: string;
    showReminder: boolean;
  }): void {
    let contactCard = this.contactState.getContactCard(info.contactId);
    let conversation = contactCard.getConversation(info.conversationId);
    conversation.showReminder = info.showReminder;
  }

  public setUnlinkedInteractionToSelect(interaction: InteractionModel): void {
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | Set unlinked interaction to select '
    );
    let counter = 0;

    let interval = window.setInterval(() => {
      counter++;
      if (counter > 5) {
        window.clearInterval(interval);
        interval = null;
      }
      let contactCard = this.contactState.selectedContactCard();
      if (contactCard) {
        window.clearInterval(interval);
        interval = null;
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | Set unlinked interaction to select | has contact '
        );
        contactCard.unlinkedInteractionToSelect = interaction;
      }
    }, 200);
  }

  private async continueContactFlow(
    contact: ContactModel,
    rawData: any
  ): Promise<void> {
    this.validateContactExists &&
      this.removeEASubscription(this.validateContactExists.NOTIFY_EXISTENCE);

    let contactCard = this.contactState.selectedContactCard();
    contactCard.contactId = contact.contactId;
    contactCard.subscribeToContactChanges();
    contactCard.mapContactInfo(contact);
    await contactCard.populateUnlinkedInteractions();
    await contactCard.populateConversations(
      false,
      !contactCard.servedInteraction
    );
    contactCard.retrieveOrganisationMemberTransferList();
    contactCard.retrieveOrganisationWorktypeColorConfig();
    contactCard.retrieveSMSFromNumbers();

    this.contactState.linkIfInteractionPresent(contactCard);
    this.contactState.changeView({
      viewStrategy: 'emptyConversation',
      contactId: contact.contactId,
    });
    this.contactState.saveState();
  }

  public createAndLinkConversation(
    data: InteractionModel | Array<InteractionModel>
  ): void {
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | create and link conversation ' +
        this.contactState.contactTabs.length,
      data
    );

    const interaction = Array.isArray(data) ? data[data.length - 1] : data;
    if (!interaction) {
      return;
    }
    let contactCard =
      this.contactState.getContactCard(
        interaction.contactId,
        interaction.correlationId
      ) || this.contactState.selectedContactCard();
    if (!contactCard && this.contactState.contactTabs.length === 1) {
      contactCard = this.contactState.contactTabs[0];
    }
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | create and link conversation | contactCard ',
      contactCard
    );
    if (contactCard) {
      let taskInteraction = this.contactState.isTask(contactCard);
      let hasConversationId =
        taskInteraction &&
        typeof taskInteraction.conversationId !== 'undefined' &&
        taskInteraction.conversationId !== null;
      if (hasConversationId) {
        return;
      }

      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | create and link conversation | creating '
      );
      let conversationId = null;
      const name = taskInteraction ? taskInteraction.taskName : null;

      if (hasConversationId) {
        conversationId = taskInteraction
          ? taskInteraction.conversationId
          : null;
      }

      let newConversation = this.conversationFactory.build(conversationId);
      newConversation.contactId = contactCard.contactId;
      newConversation.status = 'new';
      newConversation.conversationName = name || '(untitled)';
      newConversation.relativeTime = moment(Date.now());

      this.contactService.subscribeToContactAddedToConversationEvent(
        (rawData: any) => {
          logger.debug(
            this.getCurrentFormattedTime() +
              ' - CC | create conversation | conversation has been created with id ',
            rawData
          );
          this.oplogManager.subscribeToConversationChanges(
            rawData.state.conversationId
          );
          this.handleConversationInsert(
            contactCard,
            newConversation,
            data,
            rawData.state
          );
        }
      );
      this.oplogManager.subscribeToConversationListChanges(
        contactCard.contactId
      );
      this.oplogManager.subscribeToUnlinkedInteractionChanges(
        contactCard.contactId
      );

      this.conversationService.createNewConversation(newConversation);
    }
  }

  private handleConversationInsert(
    contactCard: ContactModel,
    conversation: ConversationModel,
    data: any,
    rawData: any
  ): void {
    if (!conversation) {
      conversation = rawData;
    }
    conversation.conversationId = rawData._id || rawData.conversationId;
    if (data) {
      if (Array.isArray(data)) {
        data.forEach((interaction) => {
          this.handleConversationInsertLinking(
            interaction,
            conversation,
            contactCard
          );
        });
      } else {
        this.handleConversationInsertLinking(data, conversation, contactCard);
      }
    } else {
      contactCard.servedInteractions.forEach((servedInteraction) => {
        servedInteraction.linkToConversation(conversation.conversationId);
      });
    }
    this.contactState.selectConversation(conversation);
  }

  private async handleConversationInsertLinking(
    interaction: InteractionModel | TicketModel,
    conversation: ConversationModel,
    contactCard: ContactModel
  ): Promise<void> {
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | handleConversationInsertLinking '
    );
    interaction.linkToConversation(conversation.conversationId);
    contactCard.conversationIdToSelect = conversation.conversationId;
    contactCard.unlinkedInteractions = contactCard.unlinkedInteractions.filter(
      (_interaction) => _interaction.interactionId !== interaction.interactionId
    );
    if (interaction instanceof InteractionModel && interaction.isDiallerCall) {
      await interaction.linkToContact(conversation.contactId);
    }
    contactCard.removeUnlinkedInteraction(interaction.interactionId);
    contactCard.removeServedUnlinkedInteraction(interaction.interactionId);
    if (interaction.channel === 'campaign') {
      let servedUnlinkedInteractions: Array<InteractionModel | TicketModel> =
        contactCard.servedUnlinkedInteractions.filter(
          (filterInteraction) =>
            filterInteraction.conversationId === conversation.conversationId &&
            filterInteraction.interactionId !== interaction.interactionId
        );
      if (servedUnlinkedInteractions.length > 0) {
        servedUnlinkedInteractions.forEach((unlinkedInteraction) => {
          unlinkedInteraction.contactId = contactCard.contactId;
          unlinkedInteraction.linkToConversation(interaction.conversationId);
          contactCard.removeUnlinkedInteraction(
            unlinkedInteraction.interactionId
          );
          contactCard.removeServedUnlinkedInteraction(
            unlinkedInteraction.interactionId
          );
        });
      }
    }
  }

  public wrapUpStart(data: any): void {
    if (data.state === 'WRAP_UP') {
      this.contactState.addWrapUpToContact(data);
    }
  }

  public wrapUpUpdated(data: any): void {
    if (data.state === 'WRAP_UP') {
      this.contactState.updateWrapupInteraction(data);
    }
  }

  public async isActiveInteraction(
    _interaction: InteractionModel | TicketModel
  ): Promise<any> {
    const response =
      await this.interactionService.retrieveConnectedInteractions(
        this.sessionStore.get.user.memberId
      );
    // @ts-ignore
    let interactions = response.memberInteractions
      ? response.memberInteractions
      : response;
    let servedInteraction = interactions.find(
      (interaction) => interaction.interactionId === _interaction.interactionId
    );
    if (servedInteraction) {
      return true;
    }
    return false;
  }

  private findOriginEmail(email: InteractionModel): string {
    const contactCard: ContactModel = this.contactState.getContactCard(
      email.contactId,
      email.correlationId
    );
    if (contactCard) {
      // known contact
      const conversation = contactCard.getConversation(email.conversationId);

      if (conversation) {
        // linked to conversation
        const interactions = [].concat(conversation.interactions).reverse();
        return this.findInboundInteraction(interactions);
      } else {
        // unlinked
        const unlinkedInteractions = []
          .concat(contactCard.unlinkedInteractions)
          .reverse();
        return this.findInboundInteraction(unlinkedInteractions);
      }
    } else {
      //unknown contact
      const servedUnlinkedInteractions = []
        .concat(contactCard.unlinkedInteractions)
        .reverse();
      return this.findInboundInteraction(servedUnlinkedInteractions);
    }
  }

  public sendEmail(email: InteractionModel): void {
    let metadata: object = {
      contactId: email.contactId,
      conversationId: email.conversationId,
      correlationId: email.correlationId,
      originEmailInteractionId: this.findOriginEmail(email),
    };
    this.channelService.sendEmail(email, metadata);
  }

  public sendSms(sms: InteractionModel): void {
    let metadata: object = {
      contactId: sms.contactId,
      conversationId: sms.conversationId,
      correlationId: sms.correlationId,
    };
    this.channelService.sendSms(sms, metadata);
  }

  public clickToDial(call: any): void {
    if (this.dialingDebounced) {
      return;
    }
    this.dialingDebounced = true;
    setTimeout(() => (this.dialingDebounced = false), 3000);
    const metadata: object = {
      contactId: call.contactId,
      conversationId: call.conversationId,
      campaignId: call.campaignId,
      campaignName: call.campaignName,
      prospectId: call.prospectId,
      prospectListId: call.prospectListId,
      correlationId: call.correlationId,
    };
    this.channelService.call(call, metadata, call.additionalProperties);
  }

  public submitReminder(reminder: {
    memberId: string;
    dueTimestamp: number;
    message: string;
    contactId: string;
    conversationId: string;
    radix: number;
  }): void {
    this.reminderService
      .saveReminder(
        reminder.memberId,
        reminder.dueTimestamp,
        reminder.message,
        reminder.contactId,
        reminder.conversationId
      )
      .then(() => {
        // @ts-ignore
        const dueDate = moment(parseInt(reminder.dueTimestamp, reminder.radix));
        const date = dueDate.format('DD/MM/YYYY');
        const time = dueDate.format('HH:mm');
        this.eventAggregator.publish(
          MESSAGE_EVENTS.SUCCESS,
          `Follow-up created successfully for ${date} at ${time}.`
        );
        this.eventAggregator.publish(
          'change.channel.size.reset',
          reminder.contactId
        );
      })
      .catch((error: string) => {
        logger.debug(' Unable to save reminder. error = ', error);
      });
  }

  public selectCallOutCome(info: {
    interactionId: string;
    category: string;
    outcomeReason: string;
  }): void {
    this.conversationService.selectOutcome(
      info.interactionId,
      info.category,
      info.outcomeReason
    );
  }

  public subscribeToCardChanges(): void {
    this.eventAggregator.subscribe(
      CONTACT_ACTIONS.DETECT_CHANGE,
      (correlationId: string) => {
        if (this.contactState.contactTabs.length > 1) {
          let cc = this.contactState.contactTabs.find(
            (cc) => cc.correlationId === correlationId
          );
          if (cc && !cc.isSelected) {
            cc.hasChange = true;
          }
        }
      }
    );
  }

  public retrieveConversationsFlows(): void {
    this.retrieveConversationsEmailFlows();
    this.retrieveConversationsOutboundFlows();
  }

  private retrieveConversationsEmailFlows(): void {
    this.channelService.retrieveFlowEmails().then((emails: string[]) => {
      this.contactState.flows.emailsFlows = emails;
    });
  }

  private retrieveConversationsOutboundFlows(): void {
    this.channelService
      .retrieveOutboundFlows(this.sessionStore.get.user.memberId)
      .then(
        (
          outboundFlows: Array<{
            flowId: string;
            flowName: string;
            selected: boolean;
          }>
        ) => this.contactState.mapFlow(outboundFlows)
      );
  }

  public retrieveReasonCodes(): void {
    this.conversationService
      .retrieveStatuses()
      .then((statusCodes: StatusModel[]) => {
        this.contactState.statusCodes = statusCodes;
      })
      .catch((error) => {});
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }

  private findInboundInteraction(interactions: InteractionModel[]): string {
    const interaction = interactions.find(
      (interaction) =>
        interaction.formattedDirection === 'Inbound' ||
        (interaction.direction &&
          interaction.direction.toLowerCase() === 'inbound')
    );
    if (interaction) {
      return interaction.interactionId;
    }
    return null;
  }
}

class ValidateExistence {
  public NOTIFY_EXISTENCE: string;

  private oplogSubscription: ZIOplog;
  private notifiedThatEntityExists: boolean = false;

  constructor(
    eventName: string,
    private service: any,
    private queryMethod: string,
    private queryParams: string[],
    private oplogMethod: string,
    private oplogParam: string,
    private eventAggregator: EventAggregator,
    private operation: string
  ) {
    this.NOTIFY_EXISTENCE = eventName;
  }

  public async verifyIfExists(
    entity: any,
    triggerRequestCallback?: Function
  ): Promise<void> {
    this.subscribeToCreated(entity);
    triggerRequestCallback && triggerRequestCallback();

    let args = [];
    await this.queryParams.forEach((param: string, index: number) => {
      args[index] = entity[param];
    });
    let rawQueryData = await this.service[this.queryMethod](...args).catch(
      (error) => {}
    );
    if (rawQueryData) {
      this.handleRawData(rawQueryData);
    } else {
      logger.warn('No data found for ', this.NOTIFY_EXISTENCE);
    }
  }

  private subscribeToCreated(entity: any): any {
    if (this.oplogSubscription) {
      this.oplogSubscription.unsubscribe &&
        this.oplogSubscription.unsubscribe();
    }
    let oplogParam = this.oplogParam;
    this.oplogSubscription = this.service[this.oplogMethod](entity[oplogParam]);
    this.oplogSubscription.on(this.operation, (rawOplogData) =>
      this.handleRawData(rawOplogData)
    );
  }

  private unsubscribeOplog(): void {
    this.oplogSubscription.unsubscribe();
    this.notifiedThatEntityExists = false;
  }

  private handleRawData(rawData: any): void {
    if (rawData && this.notifiedThatEntityExists === false) {
      this.notifiedThatEntityExists = true;
      this.unsubscribeOplog();
      this.eventAggregator.publish(this.NOTIFY_EXISTENCE, rawData);
    }
  }
}
