import {LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('WrapUpInteractionModel');

export class WrapUpInteractionModel extends BaseModel {

  public interactionId: string = null;
  public _id: string = null;
  public memberId: string = null;
  public contactId: string = null;
  public conversationId: string = null;
  public state: string = null;
  public channel: string = null;
  public wrapUpChannelIds: string[] = [];

  constructor(interaction: any) {
    super();
    super.mapProperties(interaction);
  }

  public equals(wrapUpToCompare: WrapUpInteractionModel): boolean {
    return this.interactionId && (this.interactionId === wrapUpToCompare.interactionId || this.interactionId === wrapUpToCompare._id);
  }

  @computedFrom('state')
  public get wrapUp(): boolean {
    return this.state === 'WRAP_UP';
  }
}
