import { LogManager, autoinject } from 'aurelia-framework';
import { CannedResponseService } from '../canned-responses-service';
import { CannedResponseView, CannedResponseModel } from '../canned-responses-model';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('CannedResponseEdit');

@autoinject()
export class CannedResponseEdit {

  public cannedResponse: CannedResponseView = new CannedResponseView();
  public pageHeader: string = 'Edit Canned Response';
  public isBusy: boolean = false;

  constructor(private cannedResponseService: CannedResponseService, private router: Router) { }

  public activate(params: any): Promise<any> {
    return this.cannedResponseService.findCannedResponseById(params.id)
      .then((cannedResponse: CannedResponseModel) => this.cannedResponse = CannedResponseView.from(cannedResponse));
  }

  public saveCannedResponse(): void {
    this.isBusy = true;
    this.cannedResponseService.editCannedResponse(this.cannedResponse.toPayload())
      .then(() => this.handleEditCannedResponseSuccess())
      .catch((error: Error) => this.handleEditCannedResponseError(error));
  }

  private handleEditCannedResponseSuccess(): void {
    this.isBusy = false;
    this.router.navigate('list');
  }

  private handleEditCannedResponseError(error: Error): void {
    this.isBusy = false;
    logger.error('saveCannedResponse :: error=', error)
  }
}