import {inject, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
 */
const logger = LogManager.getLogger('OrganisationInvitations');
/*
*/
@inject(Router)
export class OrganisationInvitations {

  constructor(router) {
    this.router = router;
  }

  configureRouter(config, router) {

    let routeMap = [
      {route: '',       name: 'list',   moduleId: PLATFORM.moduleName('./list/invitations'), nav: false, title: 'Invitations'},
      {route: 'invite', name: 'invite', moduleId: PLATFORM.moduleName('./invite/invite'),    nav: false, title: 'Invite a new user'}
    ];

    config.map(routeMap);

    this.router = router;
  }

}
