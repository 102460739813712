import { autoinject } from 'aurelia-framework';
import { OplogService, SessionStore } from 'zailab.common';

/* */
@autoinject
export class IdDialogService {

  constructor(private oplogService: OplogService, private sessionStore: SessionStore) {
  }

  public initialiseOplog(_field: string, _value: string, _path: string, _command: string): Promise<OplogService> {

    let _criteria: string = _value === 'organisationId' ? this.sessionStore.get.organisation.organisationId : '';

    return new Promise((resolve) => {
      resolve(this.oplogService.subscribeOn(_field, _criteria).in(_path + '.' + _command));
    });
  }
}