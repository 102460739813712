import {autoinject, LogManager} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {observable} from 'aurelia-binding';

const logger = LogManager.getLogger('EmptyStateAbstract');

@autoinject
export class EmptyStateAbstract {

  protected emptyStateConfig: ZIEmptyState;
  protected hasEntries: boolean = false;
  protected nodeDialogInitialised: boolean = false;
  protected nodeConfiguration: ZNodeConfig;
  protected createdContentResponse: any;
  protected contentCreatedFlag: boolean = false;
  @observable protected isAddingContent: boolean = false;

  constructor(protected dialogService: DialogService) {

  }

  protected activateContentCreator(_contentCreatorInitData: any): void {
    if (this.emptyStateConfig.hasExternalContentCreator) {
      this.isAddingContent = true;
      this.dialogService.open({
        viewModel: this.emptyStateConfig.externalContentCreatorURI,
        model: _contentCreatorInitData
      }).whenClosed((_contentCreatorResponse: any) => {
        if (!_contentCreatorResponse.wasCancelled) {
          this.handleContentCreated(_contentCreatorResponse);
        } else {
          this.cancelContentCreator();
        }
      })
    }
  }

  protected handleContentCreated(_contentCreatorResponse: any): void {
    this.createdContentResponse = _contentCreatorResponse.output;
    this.contentCreatedFlag = true;
    this.isAddingContent = false;
    this.hasEntries = true;
  }

  protected cancelContentCreator(): void {
    this.isAddingContent = false;
  }

  public findItemInCollection(_collection: Array<any>, _key: string, _value: string): any {
    return _collection.find(_item => _item[_key] === _value) || false;
  }

}
