import { LogManager, autoinject } from 'aurelia-framework';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';

const logger = LogManager.getLogger('Step2AssistantDialog');

@autoinject
export class Step2AssistantDialog {
  private wizard: any;
  public error: string;

  constructor() {}

  public activate(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => this.wizard.continue());
    this.wizard.step.complete();

    this.wizard.data.nudgeMessageEnabled = !!this.wizard.data.nudgeMessageEnabled;
    this.wizard.data.nudgeMessageInterval = this.wizard.data.nudgeMessageInterval || 8;
    this.wizard.data.nudgeMessageDisconnectTimeout = this.wizard.data.nudgeMessageDisconnectTimeout || 12;
  }

  public toggleNudgeEnabled(): void {
    this.wizard.data.nudgeMessageEnabled = !this.wizard.data.nudgeMessageEnabled;
    
    if (this.wizard.data.nudgeMessageEnabled) {
      this.wizard.step.incomplete();
    } else {
      this.wizard.data.nudgeMessage = '';
      this.wizard.step.complete();
    }
  }

  public nudgeMessageChanged(): void {
    if (this.wizard.data.nudgeMessage.length === 0) {
      this.wizard.step.incomplete();
    } else {
      this.wizard.step.complete();
    }
  }
}
