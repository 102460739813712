import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { TeamLeaderService } from '../../team-leader-service';
import { AgentDailyStatsModel } from './agent-stats-dialog-model';
import { FeatureFlagService } from 'zailab.common';

const logger = LogManager.getLogger('AgentStatsDialog');
@autoinject
export class AgentStatsDialog {
  public conferenceIsEnabled: boolean = null;

  public agentStats: { [key: string]: AgentDailyStatsModel };
  public allConversationStats: { [key: string]: { [key: string]: number } };
  public conversationStats: { [key: string]: number };

  public agentName: string;
  public averageSurveyScore: string;
  public dates: string[];
  public selectedDate: string;
  public initialised = false;
  public loading = true;

  constructor(
    private dialogController: DialogController,
    private teamLeaderService: TeamLeaderService,
    private featureFlagService: FeatureFlagService
  ) {}

  public activate(member: any): void {
    this.agentName = member.fullName;

    this.retrieveFeatureFlags();
    this.retrieveAgentDailyStats(member.memberId);
  }

  public attached(): void {
    setTimeout(() => this.initialised = true, 50);
  }

  private async retrieveFeatureFlags(): Promise<void> {
    this.conferenceIsEnabled = await this.featureFlagService.isEnabled(
      'ConferenceIsEnabled'
    );
  }

  private retrieveAgentDailyStats(memberId: string): void {
    this.teamLeaderService.retrieveAgentDailyStats(memberId).then(
      (agentStats) => this.agentDailyStatsRetrieved(agentStats),
      (error) => this.reportError(error)
    );
  }

  private agentDailyStatsRetrieved(agentStats: {
    [key: string]: AgentDailyStatsModel;
  }): void {
    this.agentStats = agentStats;
    this.getDates(agentStats);
  }

  private getDates(data) {
    const entries = Object.entries(data);
    entries
      .sort((a, b) => {
        const dateA = new Date(a[0].split(' - ')[1]);
        const dateB = new Date(b[0].split(' - ')[1]);
        return dateB.getTime() - dateA.getTime();
      })
      .reverse();
    this.dates = entries.map((entry) => entry[0]);
    this.selectedDate = this.dates[this.dates.length - 1];
    this.loading = false;
  }

  private reportError(msg: string): void {
    logger.warn('Error: ', msg);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}
