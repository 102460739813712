import {inject, LogManager} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
import {computedFrom} from "aurelia-binding";
/*
 * */
const logger = LogManager.getLogger('CosModel');
/*
 * */
export class CosModel extends BaseModel {

  cosValue = null;
  isSelected = false;

  constructor(service) {
    super();
    this.mapProperties(service);
  }

  @computedFrom('value')
  get name() {
    switch (this.cosValue) {
      case 'INTERNAL':
        return 'Internal Calls';
      case 'LOCAL_EXCLUDING_MOBILE':
        return 'Local (Excluding mobile)';
      case 'LOCAL_INCLUDING_MOBILE':
        return 'Local (Including Mobile)';
      case 'LOCAL_AND_INTERNATIONAL':
        return 'Local and International';
      default:
        return '';
    }
  }
}