import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { AllowedMatch, BypassCos, MATCHING_TYPES } from '../bypass-cos-model';
import { BypassCosService } from '../bypass-cos-service';

const logger = LogManager.getLogger('BypassCosEdit');

@autoinject()
export class BypassCosEdit {

  public bypassCos: BypassCos = new BypassCos();
  public allowedMatch: AllowedMatch = new AllowedMatch();
  public businessPartner: string;
  public contactCenter: string;
  public ready: boolean = false;
  public matchingTypes: Array<any> = MATCHING_TYPES;

  private validation: ValidationController;

  constructor(
    private bypassCosService: BypassCosService,
    private router: Router,
    readonly validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public activate(params: { id: string }): Promise<any> {
    this.initValidationRules();
    return this.bypassCosService.findById(params.id)
      .then((bypassCos: BypassCos) => {
        this.bypassCos = bypassCos;
        this.ready = true;
      });
  }

  public update(): void {
    this.bypassCos.allNumbersWhitelisted = this.bypassCos.allNumbersAllowed = this.bypassCos.state === 'DISABLED';
    this.bypassCosService.update(this.bypassCos);
  }

  public cancel(): void {
    this.allowedMatch.matchingTypeSelected = null;
    this.allowedMatch.number = null;
  }

  public save(): void {
    this.validation.validate().then((results) => {
      if (results.valid) {
        this.persist();
      }
    });
  }

  public remove(allowedMatch: AllowedMatch): void {
    this.ready = false;
    this.bypassCosService.removeAllowedMatch(this.bypassCos.id, allowedMatch)
      .then(() => this.handleRemoveSuccess(allowedMatch))
      .catch((error: Error) => this.handleError(error));
  }

  private persist(): void {
    this.ready = false;
    this.allowedMatch.matchType = this.allowedMatch.matchingTypeSelected.value;
    this.bypassCosService.addAllowedMatch(this.bypassCos.id, this.allowedMatch)
      .then(() => this.handleSaveSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  private handleRemoveSuccess(allowedMatch: AllowedMatch): void {
    this.ready = true;
    const index = this.bypassCos.whitelistNumbers.indexOf(allowedMatch);
    this.bypassCos.whitelistNumbers.splice(index, 1);
    this.smoothScrollToBottom();
  }

  private handleSaveSuccess(): void {
    this.ready = true;
    this.bypassCos.whitelistNumbers.push({
      id: this.allowedMatch.id,
      whitelistNumberId: this.allowedMatch.whitelistNumberId,
      matchingTypeSelected: this.allowedMatch.matchingTypeSelected,
      matchType: this.allowedMatch.matchType,
      number: this.allowedMatch.number,
    });
    this.cancel();
    this.smoothScrollToBottom();
    setTimeout(() => {
      this.validation.reset();
    }, 1);
  }

  public navigateBack(): void {
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.ready = true;
    logger.warn('handleError :: error=', error);
  }

  private initValidationRules(): void {
    ValidationRules
      .ensure((allowedMatch: AllowedMatch) => allowedMatch.matchingTypeSelected).required()
      .ensure((allowedMatch: AllowedMatch) => allowedMatch.number).required()
      .on(this.allowedMatch);
  }
}