import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { PresenceCodeService } from '../presence-code-service';

// @ts-ignore
import $ from 'jquery';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { ArrayTools, BootstrapFormRenderer, SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('PresenceCodes');

@autoinject
export class PresenceCodes {

  public presenceCode: { presenceCodeName: string, color: string } = {
    presenceCodeName: null,
    color: '#999'
  };
  public presenceCodes: any = [];
  public isInLodingState: boolean = false;
  public addState: boolean = false;
  public isAdding: boolean = false;

  private validation: ValidationController;

  constructor(
    private router: Router,
    private presenceCodeService: PresenceCodeService,
    private sessionStore: SessionStore,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(): void {
    this.retrieveData();
    this.initialiseValidations();
  }

  private initialiseValidations(): void {
    ValidationRules
      .ensure('presenceCodeName').required().withMessage('Please enter a name.')
      .ensure('color').required().withMessage('Please select a color.')
      .on(this.presenceCode);
  }

  private async retrieveData(): Promise<any> {
    this.isInLodingState = true;
    try {
      let response = await this.presenceCodeService.retrievePresenceCodes(this.sessionStore.get.organisation.organisationId);
      this.presenceCodes = ArrayTools.sort(response.presenceCodes, 'presenceCodeName');
    } catch (e) {
      logger.error(' > Failed to retrieve presenceCodes :: e=', e);
    } finally {
      this.isInLodingState = false;
    }
  }

  public enableAddState(): void {
    this.addState = true;
    this.focus('#js-presenceCodeName');
  }

  private focus(elementId: string): void {
    let element: HTMLElement = document.querySelector(elementId);
    if (!element) {
      setTimeout(() => this.focus(elementId), 250);
      return;
    }
    element.focus();
  }

  public async addPresenceCode(): Promise<void> {
    this.isAdding = true;

    try {
      const payload = {
        ...this.presenceCode
      };
      this.presenceCode = { presenceCodeName: null, color: '#999' };
      await this.presenceCodeService.createPresenceCode(this.sessionStore.get.organisation.organisationId, payload);
      this.presenceCodes.unshift({
        ...payload,
        editable: true

      });
    } catch(e) {
      logger.warn(' > Failed to create presence code due to', e);
    } finally {
      this.addState = false;
      this.isAdding = false;
    }

  }

  public async deletePresenceCode(presenceCode: { presenceCodeName: string, color: string, editable?: boolean }): Promise<void> {
    if (!presenceCode.editable) {
      return;
    }
    try {
      await this.presenceCodeService.deletePresenceCode(this.sessionStore.get.organisation.organisationId, presenceCode);
      this.presenceCodes = this.presenceCodes.filter(pc => pc.presenceCodeName !== presenceCode.presenceCodeName)
    } catch(e) {
      logger.warn(' > Failed to delete presence code due to', e);
    }
  }

  public disableAddState(): void {
    this.addState = false;
    this.presenceCode = { presenceCodeName: null, color: '#999' };
  }

  public navigateBack(): void {
    this.router.navigate('');
  }

  public deactivate(): void {
  }
}