import {LogManager, autoinject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

const logger = LogManager.getLogger('HudLoader');

@autoinject
export class HudLoader {
  private static EVENTS = {
    SHOW: 'app:loader:show',
    HIDE: 'app:loader:hide'
  };

  constructor(private eventAggregator: EventAggregator) {}

  public show(): void {
    this.eventAggregator.publish(HudLoader.EVENTS.SHOW);
  }

  public hide(): void {
    this.eventAggregator.publish(HudLoader.EVENTS.HIDE);
  }
}