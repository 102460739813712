import {LogManager, inject} from 'aurelia-framework';
//@ts-ignore
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';

import {BootstrapFormRenderer} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';

const logger = LogManager.getLogger('ScreenCallDialog');

@inject(DialogController, ValidationControllerFactory)
export class DecisionDialog {

  public dialogHeader = 'Decision';
  private variableName: string;
  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private decisionValidation: ValidationController;
  private newValueValidation: ValidationController;
  private values: Array<string> = [];
  private textValue: string;
  private canSave: boolean = false
  private decisionName: string = "";
  private nodeConfiguration: ZNodeConfig;
  public error: string;

  constructor(private dialogController: DialogController, private validationControllerFactory: ValidationControllerFactory) {
    this.decisionValidation = this.validationControllerFactory.createForCurrentScope();
    this.decisionValidation.addRenderer(new BootstrapFormRenderer());
    this.decisionValidation.validateTrigger = validateTrigger.changeOrBlur;
    
    this.newValueValidation = this.validationControllerFactory.createForCurrentScope();
    this.newValueValidation.addRenderer(new BootstrapFormRenderer());
    this.newValueValidation.validateTrigger = validateTrigger.changeOrBlur;
  }

  public activate(_config: ZNodeConfig): void {
    this.textValue = '';
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    if (this.nodeData.properties["values"]) {
      this.values = this.nodeData.properties["values"] as Array<string>;
    }
    this.decisionName = this.nodeData.properties["decisionName"];
    this.variableName = this.nodeData.properties["variableName"];
    this.existingConnections = this.nodeData.connections;
    this.setupValidation();
  }

  private setupValidation(): void {
    ValidationRules
      .ensure('decisionName').required()
      .ensure('variableName').required()
      .on(this);
  }

  public addValue(): void {
    if (!this.textValue || this.textValue.includes('.')) {
      this.error = `Please avoid using full stops (.).`;
      return;
    }
    this.values.push(this.textValue);
    this.textValue = '';
  }

  private resetUI(): void {
    // this.codeValue = DEFAULT_CODE;
    // this.numberValue = "";
    // this.numberErrors = "";
  }

  public clearError(): void {
    this.error = '';
  }

  public removeValue(index: number): void {
    this.values.splice(index, 1);
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.nodeData.outputConnectors = finalConnections;
    this.nodeData.properties['name'] = this.decisionName;
    this.nodeData.properties['isDefined'] = !!this.values.length;
    this.nodeData.properties['values'] = this.values;
    this.nodeData.properties['variableName'] = this.variableName;
    this.nodeData.properties['decisionName'] = this.decisionName;
    this.nodeData.properties['defaultOutcomeEnabled'] = true;
    this.dialogController.ok(this.nodeData);
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {

    let _connections: Array<ConnectorModel> = [];
    let index = 0;
    this.values.forEach(value => {
      let existingConnection = this.contains(_existingConnections, value);
      _connections.push(new ConnectorModel({
        name: value as string,
        dest: typeof existingConnection !== 'undefined' ? existingConnection.dest : null,
        source: {connectorIndex: index, nodeID: this.nodeData.id},
        customExtensions: null
      }));
      index++;
    })

    let existingConnection = this.contains(_existingConnections, 'default');
    _connections.push(new ConnectorModel({
      name: 'default',
      dest: typeof existingConnection !== 'undefined' ? existingConnection.dest : null,
      source: {connectorIndex: index, nodeID: this.nodeData.id},
      customExtensions: null
    }));

    return _connections;
  }

  private contains(_existingConnections: Array<ConnectorModel>, name: string): ConnectorModel{
    let existingConnection;
    _existingConnections.forEach(_connection => {
      if(_connection.name === name){
        existingConnection = _connection
        return;
      }
    });

    return existingConnection
  }

}
