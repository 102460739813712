import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {NodeModel} from '../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../components/organisms/connector/models/connector-model';
import {InteractionConfigStrategy} from '../interaction-config-strategy';
/**/
const logger = LogManager.getLogger('InteractionFlowDefinitionModel');
/**/
export class InteractionFlowDefinitionModel extends BaseModel {

  public version: string = null;
  public nodes: Array<NodeModel> = [];
  public connections: Array<ConnectorModel> = [];
  public flowId: string = '';
  public flowDescriptor: string = '';
  public organisationId: string = '';
  public gridSize: { columns: number; rows: number; };
  public additionalData: {};

  constructor(flowDefinition?: InteractionFlowDefinitionModel, flowDescriptor?: string) {

    super();
    super.mapProperties(flowDefinition);

    this.flowDescriptor = flowDescriptor;
    this.nodes = flowDefinition ? this.mapNodes(flowDefinition.connections, flowDefinition.nodes) : [];
  }

  private mapNodes(connections: Array<object>, nodes: Array<object>): Array<NodeModel> {

    let mappedNodes: Array<NodeModel> = [];

    nodes.forEach((node: NodeModel) => {

      node.config = InteractionConfigStrategy.getConfigStrategy(this.flowDescriptor)[node.eventId];

      if (connections) {

        node.connections = this.mapNodeConnections(connections, node.id);
      }

      mappedNodes.push(new NodeModel(node));
    });

    return mappedNodes;
  }

  private mapNodeConnections(connections: Array<object>, nodeId: string): Array<ConnectorModel> {

    let nodeConnectors: Array<ConnectorModel> = [];

    if (!connections) {
      return [];
    }

    connections.forEach((connector: ConnectorModel) => {

      let connectorNodeId = connector.source.nodeID || connector.source.nodeId; // caters for legacy data

      if (connectorNodeId === nodeId) {

        nodeConnectors.push(new ConnectorModel(connector));
      }

    });

    return nodeConnectors;
  }
}
