import { WebchatService } from './../../../organisation/webchat/webchat-service';
import { LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { autoinject } from 'aurelia-dependency-injection';

const logger = LogManager.getLogger('AssignChatWidgetDialog');

@autoinject()
export class AssignChatWidgetDialog {
  public header: string;
  private dialogHeader = 'Assign Chat Widget';
  private liveDialogHeader = 'Assign Live Chat Widget';

  private interactionFlowId: string;
  private live: boolean;
  public assignedChatWidgets = [];
  public availableChatWidgets = [];
  public liveChatWidgets = [];

  constructor(
    private dialogController: DialogController,
    private webchatService: WebchatService
  ) {}

  public activate(model): void {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.live = model.live;
    this.header = this.live ? this.liveDialogHeader : this.dialogHeader;

    this.retrieveAssignedWebchatWidgets();
    this.retrieveWebchatWidgets();
  }

  public retrieveAssignedWebchatWidgets(): void {
    this.webchatService.retrieveAssignedWebchatWidgets(this.interactionFlowId, this.live)
      .then(data => {
        this.assignedChatWidgets = data.webChatWidgets || [];
      },
      (error) => {
        logger.info(' > Failed retrieving assigned webchat widgets due to:', error);
      });
  }

  public retrieveWebchatWidgets(): void {
    this.webchatService.retrieveAvailableWebchatWidgets()
      .then(data => {
        this.availableChatWidgets = data || [];
      },
      (error) => {
        logger.info(' > Failed retrieving available webchat widgets due to:', error);
      });
  }

  public addWidgetToInteractionFlow(index, item): void {
    this.webchatService.addWidgetToInteractionFlow(this.interactionFlowId, item.webChatWidgetId, this.live);
    this.assignedChatWidgets.push(item);
    this.availableChatWidgets.splice(index, 1);
  }

  public removeWidgetFromInteractionFlow(index, item): void {
    this.webchatService.removeWidgetToInteractionFlow(this.interactionFlowId, item.webChatWidgetId);
    this.availableChatWidgets.push(item);
    this.assignedChatWidgets.splice(index, 1);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public done(): void {
    this.dialogController.ok();
  }

}
