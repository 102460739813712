import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { InfiniteScrollService } from '../../../../_common/services/infinitescroll-service';
import { InteractionsService } from '../interaction-flow-service';
import { ViewAuditDataDialog } from '../view-audit-data/view-audit-data';

// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('CampaignsAudit');
@autoinject
export class CampaignsAudit {
  public header: string = 'Work Flows Audit Trail';
  public searchTerm: string = '';
  public ready: boolean = false;
  public auditTrail: any[] = [];
  public total: number | string;
  public isSearching: boolean = false;
  public tableContainer: Element;
  public infiniteScroll: InfiniteScrollService;
  private page: number = 0;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private interactionsService: InteractionsService
  ) {}

  public activate(): void {
    this.retrieveAuditTrail();
  }

  public attached(): void {
    this.initialiseInfiniteScroll();
  }

  public retrieveAuditTrail(): void {
    this.interactionsService
      .retrieveAudit(this.page)
      .then((response: { auditFlows: IInteractionFlowAudit[], total: number }) => {

        if (response && Array.isArray(response.auditFlows)) {
          response.auditFlows.forEach((flow) => {
            flow.formattedTimestamp = moment(flow.lastUpdatedTimestamp).format('D MMM YY HH:mm:ss')
          })
          this.auditTrail = this.auditTrail.concat(response.auditFlows);
          this.total = response.total;
        }
      })
      .catch((error) => {
        logger.warn(' > failed to get interactionflow audits ', error);
        this.total = '--';
      })
      .finally(() => {
        this.ready = true;
      });
  }

  private initialiseInfiniteScroll(): void {
    this.infiniteScroll = new InfiniteScrollService(this.tableContainer, () => {
      this.page++;
      this.retrieveAuditTrail();
    });
  }
  
  public viewInteractionFlows(): void {
    this.router.navigate('');
  }

  public toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  public downloadFlow(auditRecord: IInteractionFlowAudit): void {
    this.interactionsService
      .retrieveAuditFlowDefinition(auditRecord.id, auditRecord.object)
      .then((response: { value: string }) => {
        try {
          const name = auditRecord.flowName;
          const definition = response.value;
          
          const dataStr = JSON.stringify(definition);
          const a = document.createElement('a');
          const file = new Blob([dataStr], { type: 'text/plain' });
          a.href = URL.createObjectURL(file);
          a.download = name + '.json';
          a.click();
        } catch(e) {
          logger.warn(' > failed to parse and download flow definition ', {
            error: e,
            response
          });
        }
      })
      .catch((error) => {
        logger.warn(' > failed to retrieve flow definition for download ', error);
      });
  }
  
  public viewChange(auditRecord: IInteractionFlowAudit): void {
    this.dialogService
      .open({
        viewModel: ViewAuditDataDialog,
        model: auditRecord.responseObject
      })
      .whenClosed(() => {});
  }

  public partialMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression.length === 0) {
      return true;
    }
    searchExpression = searchExpression.toLowerCase();
    let formattedTimestamp = value.formattedTimestamp ? value.formattedTimestamp.toLowerCase() : '';
    let channel = value.channel ? value.channel.toLowerCase() : '';
    let flowName = value.flowName ? value.flowName.toLowerCase() : '';
    let lastUpdatedBy = value.lastUpdatedBy ? value.lastUpdatedBy.toLowerCase() : '';
    let lastUpdatedByEmail = value.lastUpdatedByEmail ? value.lastUpdatedByEmail.toLowerCase() : '';
    let action = value.action ? value.action.toLowerCase() : '';
    let object = value.object ? value.object.toLowerCase() : '';
    return (
      formattedTimestamp.includes(searchExpression) ||
      channel.includes(searchExpression) ||
      flowName.includes(searchExpression) ||
      lastUpdatedBy.includes(searchExpression) ||
      lastUpdatedByEmail.includes(searchExpression) ||
      action.includes(searchExpression) ||
      object.includes(searchExpression)
    );
  }
}
