import { LogManager } from 'aurelia-framework';

import { BaseModel } from 'zailab.abstract';
import { ServiceModel } from '../../../../_common/models/service-model';

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('DispositionCodesModel');

export interface Disposition {

  dispositionCodeId?: string;
  name?: string;
  description?: string;
  comments: string;
  selected?: boolean;
}

export interface DispositionCode {

  dispositionCodeId: string;
  description: string;
  interactionOutcomeReason: string;
  conversationOutcome: string;
  learningContribution: string;
}

export class DefaultDispositionCode implements DispositionCode {

  public dispositionCodeId: string;
  public description: string;
  public interactionOutcomeReason: string;
  public conversationOutcome: string;
  public learningContribution: string;

  public get isPositive(): boolean {
    return this.learningContribution && this.learningContribution === LEARNING_CONTRIBUTION.POSITIVE;
  }

  public get isNegative(): boolean {
    return this.learningContribution && this.learningContribution === LEARNING_CONTRIBUTION.NEGATIVE;
  }

  public get isNeutral(): boolean {
    return this.learningContribution && this.learningContribution === LEARNING_CONTRIBUTION.NEUTRAL;
  }

  public static builder(): DefaultDispositionCodeBuilder {
    return new DefaultDispositionCodeBuilder();
  }
}

const LEARNING_CONTRIBUTION = {
  NEGATIVE: 'Negative',
  POSITIVE: 'Positive',
  NEUTRAL: 'Neutral'
};

class DefaultDispositionCodeBuilder {

  private dispositionCode: DispositionCode;

  public from(dispositionCode: DispositionCode): DefaultDispositionCodeBuilder {
    this.dispositionCode = dispositionCode;
    return this;
  }

  public build(): DefaultDispositionCode {
    const dispositionCode = new DefaultDispositionCode();
    dispositionCode.dispositionCodeId = this.dispositionCode.dispositionCodeId;
    dispositionCode.description = this.dispositionCode.description;
    dispositionCode.interactionOutcomeReason = this.dispositionCode.interactionOutcomeReason;
    dispositionCode.conversationOutcome = this.dispositionCode.conversationOutcome;
    dispositionCode.learningContribution = this.dispositionCode.learningContribution;
    return dispositionCode;
  }
}

export class DispositionModel extends BaseModel {

  public id: string = uuidv4();
  public dispositionListId: string;
  public channel: string = null;
  public direction: string = null;
  public name: string = null;
  public service: ServiceModel = null;
  public enabled: boolean = null;
  public dispositionCodes: Disposition[] = [];
  public organisationId: string = null;
  public isTiered: boolean = false;
  public tiered: boolean = false;
  public categories: Array<DispositionCategoryModel> = [];
  public comments: string;
  public selected?: boolean;
  public actions?: any[] = [];
  
  constructor(disposition: DispositionModel) {
    super();
    if (disposition) {
      super.mapProperties(disposition);
    }
  }
}

class DispositionCategoryModel {

  public name: string;
  public subcategories: Array<DispositionSubcategoryModel>;
}

class DispositionSubcategoryModel {

  public name: string;
  public dispositionCodeIds: Array<string>;
}