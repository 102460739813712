import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore } from 'zailab.common';
import { DashboardWidget } from '../../../organisation/organisation/dashboard/dashboard-abstract';
import TelephoneNumbersService from '../telephone-numbers-service';

const logger = LogManager.getLogger('TelephoneNumbers Widget');

@inject(Router, TelephoneNumbersService, SessionStore)
export class TelephoneNumbersDashboardWidget extends DashboardWidget {

  private oplog: any;

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private telephoneNumbersService: TelephoneNumbersService,
    private sessionStore: SessionStore
  ) {
    super();
  }

  public activate(): void {
    this.retrieveNumberStats();
  }

  private retrieveNumberStats(): void {
    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.retrieveNumberStats();
      }, 500);
      return;
    }

    this.subscribeToOplog();

    this.telephoneNumbersService.retrieveNumbersStats().then((response) => {
      this.count = response;
      this.ready = true;
    }, (error) => {
      logger.info('Could not retrieve Numbers Stats', error);
    });
  }

  private subscribeToOplog(): void {
    this.telephoneNumbersService.initialiseStatsOplog().then((oplog) => {
      this.oplog = oplog;
      this.oplog.on('insert', (response) => {
        this.count = response.numbers.length;
      });
      this.oplog.on('update', (response) => {
        this.count = response.numbers.length;
      });
    });
  }

  public unbind(): void {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }
}