/*
 */
import { inject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AureliaConfiguration } from 'aurelia-configuration';
/*
 */
import { MESSAGE_EVENTS } from 'zailab.common';
import { WebSocket } from './websocket';
/*
 */
import toastr from 'toastr';
import { ALERT_SOUND_EVENTS } from '../../features/media/alert-sound-config/alert-sound-events';
/*
 */
const logger = LogManager.getLogger('DisplayMessageService');
/*
 */

let messagesMap = {
  'last.organisation.admin': {
    message:
      'You cannot remove/change this member. You must have at least one administrator.',
  },
  'member.no.service': {
    message: 'You must have at least one service allocated.',
  },
  'member.only.service': {
    message: 'You must have at least one service allocated.',
  },
  'site.is.last.site': {
    message: 'Unable to remove last site.',
  },
  'site.allocated.to.members': {
    message: 'Unable to remove a site allocated to a member.',
  },
  'site.allocated.to.invitations': {
    message: 'Unable to remove a site allocated to an invitation.',
  },
  'site.allocated.to.flows': {
    message: 'Unable to remove a site allocated to a work type.',
  },
  'skillgroup.assigned.skills': {
    message: 'Unable to remove a skill group with allocated skills.',
  },
  'skill.assigned.invitations': {
    message: 'Unable to remove a skill allocated to an invitation.',
  },
  'skillgroup.no.skills': {
    message: 'No skills for this skill group.',
  },
  'skill.assigned.members': {
    message: 'Unable to remove a skill allocated to a member.',
  },
  'skill.assigned.flows': {
    message: 'Unable to remove a skill allocated to a work type.',
  },
  'service.allocated.to.dispositionlist': {
    message: 'Unable to remove a service allocated to a disposition list.',
  },
  'service.allocated.to.members': {
    message: 'Unable to remove a service allocated to a member.',
  },
  'service.allocated.to.flow': {
    message: 'Unable to remove a service allocated to a work type.',
  },
  'service.allocated.to.invitations': {
    message: 'Unable to remove a service allocated to an invitation.',
  },
  'service.is.selected': {
    message: 'Unable to remove an active service.',
  },
  'invitation.revoke.accepted': {
    message: 'Unable to remove invitation. Member has already accepted.',
  },
  'invitation.revoke.revoked': {
    message: 'This invitation has already been removed.',
  },
  'member.rolechange.lastadmin': {
    message:
      'Unable to change role. At least one member must be an administrator.',
  },
  'passport.password.not.correct': {
    message:
      'Unable to reset password. You entered your current password incorrectly.',
  },
  'passport.password.cannot.be.same.as.current.password': {
    message:
      'Unable to reset password. Your new password cannot match your current password.',
  },
  'duplicate.invitation.email': {
    message:
      'Could not invite member as they already belong to an organisation.',
  },
  'invitation.site.invalid': {
    message: 'Could not invite member as service was blank.',
  },
  'invitation.classofservice.invalid': {
    message: 'Could not invite member as service was blank.',
  },
  'invitation.service.invalid': {
    message: 'Could not invite member as class of service was blank.',
  },
  'invitation.cannot.invite.self': {
    message: 'You cannot invite yourself.',
  },
  'member.exist.in.multiple.teams': {
    message: 'Unable to change role.  Member belongs to multiple teams.',
  },
  'DispositionList.enabled': {
    message: 'An enabled disposition list cannot be modified.',
  },
  'DispositionCode.description.populated': {
    message: 'Description must be populated.',
  },
  'DispositionCode.description.notUnique': {
    message: 'Description must be unique.',
  },
  'DispositionCode.interactionOutcomeReason.populated': {
    message: 'Interaction outcome reason must be populated.',
  },
  'DispositionCode.conversationOutcome.populated': {
    message: 'Conversation outcome reason must be populated.',
  },
  'DispositionList.enabled.not.unique': {
    message:
      'Only one list may be enabled for a given channel, service, and direction.',
  },
  'DispositionList.name.empty': {
    message: 'Name must be populated.',
  },
  'DispositionList.name.unique': {
    message: 'Name must be unique.',
  },
  'DispositionList.channel.invalid': {
    message: 'Channel must be valid.',
  },
  'DispositionList.direction.invalid': {
    message: 'Direction must be valid.',
  },
  'DispositionList.service.not.selected': {
    messgae: 'Service must be serlected.',
  },
};

@inject(EventAggregator, WebSocket, AureliaConfiguration)
export class DisplayMessageService {
  closableOptions = {
    closeButton: true,
    positionClass: 'toast-bottom-center',
    onclick: null,
    timeOut: 0,
    extendedTimeOut: 0,
    tapToDismiss: false,
  };

  importantOptions = {
    closeButton: false,
    positionClass: 'toast-bottom-center',
    onclick: null,
    onclick: () => this.unlock(),
    timeOut: 0,
    extendedTimeOut: 0,
    tapToDismiss: true,
  };

  constructor(eventAggregator, webSocket, configure) {
    toastr.options = {
      positionClass: 'toast-top-center',
      progressBar: true,
    };

    this.eventAggregator = eventAggregator;
    this.webSocket = webSocket;
    this.configure = configure;
    this.locked = false;
    this.registerEvents();
  }

  unlock() {
    if (this.locked) {
      this.locked = false;
    }
  }

  registerEvents() {
    this.webSocket.subscribe({
      name: 'com.zailab.common.validation.ValidationErrors',
      callback: (response) => {
        this.showErrorMessage(response);
        this.eventAggregator.publish('ValidationErrorsReceived', response);
        this.eventAggregator.publish('app:loader:hide');
      },
    });

    this.eventAggregator.subscribe(MESSAGE_EVENTS.ERROR_IMPORTANT, (message) =>
      this.showClosableError(message, true)
    );

    this.eventAggregator.subscribe(MESSAGE_EVENTS.ERROR, (message) =>
      this.showCustomError(message)
    );
    this.eventAggregator.subscribe(MESSAGE_EVENTS.WARNING, (message) =>
      this.showCustomWarning(message)
    );
    this.eventAggregator.subscribe(MESSAGE_EVENTS.SUCCESS, (message) =>
      this.showCustomSuccess(message)
    );
    this.eventAggregator.subscribe(MESSAGE_EVENTS.INFO, (message) =>
      this.showCustomInformation(message)
    );
    this.eventAggregator.subscribe(
      MESSAGE_EVENTS.INTERACTION_BLOCKED,
      (message) => {
        this.showAccountThresholdBreachedMessage(message.payload);
      }
    );

    this.eventAggregator.subscribe(
      MESSAGE_EVENTS.ERROR_UNHANDLED,
      (message) => {
        if (
          this.configure.environment === 'demo' ||
          this.configure.environment === 'qa' ||
          this.configure.environment === 'prod'
        ) {
          return;
        }

        toastr.error(
          message,
          'Error (see console): <br/> <a href="/logout">LOGOUT IF THIS IS BUGGING YOU</a>'
        );
        this.playAlertSound();
      }
    );

    this.eventAggregator.subscribe(MESSAGE_EVENTS.NOTIFICATION, (data) => {
      this.showCustomMessage(data.src);
    });

    toastr.options = {
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      progressBar: true,
      escapeHtml: false,
    };
  }

  playAlertSound() {
    this.eventAggregator.publish(ALERT_SOUND_EVENTS.PLAY_SOUND_ALERT);
  }

  showErrorMessage(error) {
    let validationErrorMessages = error.state.allErrors;
    let formattedValidationMessage;
    let htmlFormattedValidationMessage = `<ul>`;

    if (error.state.objectName === 'changeInteractionFlowDefinitionCommand') {
      return;
    }

    for (let validationErrorMessage of validationErrorMessages) {
      let msgConfig = messagesMap[validationErrorMessage.code] || {};
      if (msgConfig && msgConfig.isDisabled) {
        continue;
      }
      htmlFormattedValidationMessage += `<li>${
        msgConfig.message || validationErrorMessage.defaultMessage
      }</li>`;
    }

    htmlFormattedValidationMessage += `</ul>`;
    toastr.error(htmlFormattedValidationMessage, 'Error');
    this.playAlertSound();

    if (error.state.objectName === 'resetPasswordCommand') {
      this.eventAggregator.publish('reset.password.failed');
    }
  }

  showAccountThresholdBreachedMessage(message) {
    toastr.error(message, 'Error');
    this.playAlertSound();
  }

  showCustomMessage(type) {
    toastr.info(
      'You have received 1 new ' + type.toLowerCase() + '.',
      'Incoming Message'
    );
    this.playAlertSound();
  }

  showCustomInformation(message) {
    toastr.info(message);
    this.playAlertSound();
  }

  showCustomError(message) {
    toastr.error(message);
    this.playAlertSound();
  }

  showCustomSuccess(message) {
    toastr.success(message);
    this.playAlertSound();
  }

  showCustomWarning(message) {
    toastr.warning(message);
    this.playAlertSound();
  }

  showClosableSuccess(message, important) {
    if (this.locked) {
      return;
    }
    this.locked = important;
    toastr.remove();
    toastr.success(
      message,
      '',
      important ? this.importantOptions : this.closableOptions
    );
    this.playAlertSound();
  }

  showClosableInfo(message, important) {
    if (this.locked) {
      return;
    }
    this.locked = important;
    toastr.remove();
    toastr.info(
      message,
      '',
      important ? this.importantOptions : this.closableOptions
    );
    this.playAlertSound();
  }

  showClosableWarning(message, important) {
    if (this.locked) {
      return;
    }
    this.locked = important;
    toastr.remove();
    toastr.warning(
      message,
      '',
      important ? this.importantOptions : this.closableOptions
    );
    this.playAlertSound();
  }

  showClosableError(message, important) {
    if (this.locked) {
      return;
    }
    this.locked = important;
    toastr.remove();
    toastr.error(
      message,
      '',
      important ? this.importantOptions : this.closableOptions
    );
    this.playAlertSound();
  }
}
