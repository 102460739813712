import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';

import {MESSAGE_EVENTS, SessionStore} from 'zailab.common';
import {AccountSessionModel} from "../../../_common/stores/sessionmodels/account-session-model";

//@ts-ignore
import toastr from 'toastr';
import { DashboardWidget } from '../../organisation/organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('IntegrationsWidget');

@autoinject
export class IntegrationsWidget extends DashboardWidget {

  // this resolves scope binding behavior
  protected istrialaccount: boolean;
  protected displaytoastr: any;
  protected eventaggregator: any;

  constructor(
    public router: Router,
    public eventAggregator: EventAggregator,
    private sessionStore: SessionStore
  ) {
    super();
  }

  public view(): void {
    if (this.istrialaccount) {
      this.displaytoastr(MESSAGE_EVENTS.INFO, 'Integrations are not available in trial mode.');
    } else {
      this.router.navigate('integrations');
    }
  }

  public displayToastr(eventName: string, message: string): void {
    let options = Object.assign({}, toastr.options);
    toastr.options = {
      positionClass: 'toast-top-right',
      progressBar: true
    };
    this.eventaggregator.publish(eventName, message);
    toastr.options = options;
  }

  @computedFrom('sessionStore.get.account')
  get account(): AccountSessionModel {
    return this.sessionStore.get.account;
  }

  @computedFrom('account')
  get isTrialAccount(): boolean {
    if (this.account && this.account.isTrialAccount) {
      return true;
    }
    return false;
  }
}
