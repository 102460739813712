declare const PLATFORM;

export function configure(aurelia: any): void {
  aurelia
    //Atoms
    .globalResources(
      PLATFORM.moduleName('./notifications/z-websocket-connection-state')
    )
    .globalResources(PLATFORM.moduleName('./atoms/auth/auth'))
    .globalResources(PLATFORM.moduleName('./atoms/bulb/z-bulb'))
    .globalResources(
      PLATFORM.moduleName('./atoms/buttons/z-button'),
      PLATFORM.moduleName('./atoms/buttons/z-button-hex'),
      PLATFORM.moduleName('./atoms/buttons/z-button-nav'),
      PLATFORM.moduleName('./atoms/buttons/z-icon-button'),
      PLATFORM.moduleName('./atoms/buttons/z-utility-button'),
      PLATFORM.moduleName('./atoms/buttons/z-utility-button-text')
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/containers/z-flex-container'),
      PLATFORM.moduleName('./atoms/containers/z-list-container'),
      PLATFORM.moduleName('./atoms/containers/z-scroll-container')
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/containers/z-proportional-container')
    )
    .globalResources(
      PLATFORM.moduleName(
        './atoms/containers/proportional-container-v2/z-proportional-container-v2'
      )
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/images/z-icon'),
      PLATFORM.moduleName('./atoms/images/z-image'),
      PLATFORM.moduleName('./atoms/images/z-profile-picture'),
      PLATFORM.moduleName('./molecules/images/z-text-icon')
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/inputs/z-text-input'),
      PLATFORM.moduleName('./atoms/inputs/z-utility-input'),
      PLATFORM.moduleName('./atoms/inputs/z-file-input')
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/textelements/z-label'),
      PLATFORM.moduleName('./atoms/textelements/z-utility-count'),
      PLATFORM.moduleName('./atoms/textelements/z-validation-text')
    )
    .globalResources(PLATFORM.moduleName('./atoms/graph/z-bar'))
    .globalResources(PLATFORM.moduleName('./atoms/line/z-line'))
    .globalResources(PLATFORM.moduleName('./atoms/inputs/z-switch-checkbox'))
    .globalResources(PLATFORM.moduleName('./atoms/inputs/z-toggle'))
    .globalResources(PLATFORM.moduleName('./atoms/inputs/z-switch-radio'))
    .globalResources(
      PLATFORM.moduleName('./atoms/images/z-icon'),
      PLATFORM.moduleName('./atoms/images/z-image'),
      PLATFORM.moduleName('./atoms/images/z-profile-picture')
    )
    .globalResources(PLATFORM.moduleName('./atoms/dropdown/dropdown'))
    .globalResources(
      PLATFORM.moduleName('./atoms/images/z-icon'),
      PLATFORM.moduleName('./atoms/images/z-image'),
      PLATFORM.moduleName('./atoms/images/z-profile-picture'),
      PLATFORM.moduleName('./atoms/images/z-utility-icon')
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/inputs/emailinput/z-email-input'),
      PLATFORM.moduleName('./atoms/inputs/phonenumber/z-phone-number-input')
    )
    .globalResources(PLATFORM.moduleName('./atoms/timeline/z-timeline'))
    .globalResources(PLATFORM.moduleName('./atoms/dropdown/dropdown'))
    .globalResources(PLATFORM.moduleName('./atoms/tooltip/z-tooltip'))
    .globalResources(PLATFORM.moduleName('./atoms/colored-timer/colored-timer'))
    .globalResources(PLATFORM.moduleName('./atoms/timer/timer'))
    .globalResources(
      PLATFORM.moduleName('./atoms/dropdown/z-autoclose-dropdown')
    )
    .globalResources(PLATFORM.moduleName('./atoms/navigation/z-breadcrumbs'))
    .globalResources(PLATFORM.moduleName('./atoms/tag/z-tag'))
    .globalResources(PLATFORM.moduleName('./atoms/wysiwyg/z-quill-editor'))
    // Molecules
    .globalResources(
      PLATFORM.moduleName('./molecules/buttons/z-button-group'),
      PLATFORM.moduleName('./molecules/buttons/z-utility-button-delete'),
      PLATFORM.moduleName('./molecules/buttons/z-utility-button-bulk-edit')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/card/listcardbody/z-list-card-body'),
      PLATFORM.moduleName('./molecules/card/listcardheader/z-list-card-header'),
      PLATFORM.moduleName('./molecules/card/widget/widget'),
      PLATFORM.moduleName('./molecules/card/widget/z-proportional-widget'),
      PLATFORM.moduleName(
        './molecules/card/widget/proportional-widget-v2/z-proportional-widget-v2'
      ),
      PLATFORM.moduleName(
        './molecules/card/widget/proportional-widget-donut/z-proportional-donut-widget'
      ),
      PLATFORM.moduleName(
        './molecules/card/widget/proportional-widget-simple/z-proportional-widget-simple'
      ),
      PLATFORM.moduleName(
        './molecules/card/widget/proportional-widget-custom/z-proportional-widget-custom.html'
      ),
      PLATFORM.moduleName('./molecules/card/z-card-body'),
      PLATFORM.moduleName('./molecules/card/z-card-header'),
      PLATFORM.moduleName('./molecules/card/z-card-stat'),
      PLATFORM.moduleName('./molecules/card/z-card-sub-header'),
      PLATFORM.moduleName('./molecules/card/z-list-card'),
      PLATFORM.moduleName(
        './molecules/card/proportional-tile/z-proportional-tile'
      ),
      PLATFORM.moduleName('./molecules/card/context-menu/z-context-menu'),
      PLATFORM.moduleName(
        './molecules/dropdowns/zdropdownlist/z-dropdown-list'
      ),
      PLATFORM.moduleName('./molecules/pill/z-pillbox'),
      PLATFORM.moduleName('./molecules/pill/z-pill'),
      PLATFORM.moduleName('./molecules/searchdropdown/search-dropdown'),
      PLATFORM.moduleName('./molecules/date-slider/date-slider')
    )
    .globalResources(PLATFORM.moduleName('./molecules/dialog/z-dialog'))
    .globalResources(PLATFORM.moduleName('./molecules/dropdowns/z-dropdown'))
    .globalResources(
      PLATFORM.moduleName('./molecules/dropdowns/z-dropdown-menu')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/dropdowns/z-dropdown-search')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/formelement/z-form-element'),
      PLATFORM.moduleName('./molecules/formelement/z-inline-form-element')
    )
    .globalResources(PLATFORM.moduleName('./molecules/inputs/z-file-upload'))
    .globalResources(
      PLATFORM.moduleName('./molecules/inputs/z-email-tag-input')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/inputs/z-email-tag-input-v2')
    )
    .globalResources(PLATFORM.moduleName('./molecules/inputs/z-number-input'))
    .globalResources(PLATFORM.moduleName('./molecules/inputs/z-currency-input'))
    .globalResources(
      PLATFORM.moduleName('./molecules/inputs/z-email-basic-input')
    )
    .globalResources(PLATFORM.moduleName('./molecules/inputs/z-select-input'))
    .globalResources(
      PLATFORM.moduleName('./molecules/mediaplayer/z-media-player')
    )
    .globalResources(PLATFORM.moduleName('./molecules/panel/z-panel'))
    .globalResources(
      PLATFORM.moduleName('./molecules/phoneticalphabet/z-phonetic-alphabet')
    )
    .globalResources(PLATFORM.moduleName('./molecules/presence/z-presence'))
    .globalResources(
      PLATFORM.moduleName('./molecules/listemptystate/z-list-empty-state')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/stats/z-stat-stacked'),
      PLATFORM.moduleName('./molecules/stats/z-stats'),
      PLATFORM.moduleName('./molecules/stats/z-stats-short'),
      PLATFORM.moduleName('./molecules/stats/z-stat-legend')
    )
    .globalResources(PLATFORM.moduleName('./molecules/table/z-table'))
    .globalResources(
      PLATFORM.moduleName('./molecules/tabs/z-tabs'),
      PLATFORM.moduleName('./molecules/tabs/z-tabs-content')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/uploadaudio/z-audio-manager')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/uploadaudio/z-file-dragdrop')
    )
    .globalResources(PLATFORM.moduleName('./molecules/textbulb/z-text-bulb'))
    .globalResources(
      PLATFORM.moduleName('./molecules/stats/donut-stat/z-donut')
    )
    .globalResources(
      PLATFORM.moduleName('./notifications/z-websocket-connection-state')
    )
    .globalResources(
      PLATFORM.moduleName('./notifications/z-refresh-notification')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/colorpicker/color-picker'),
      PLATFORM.moduleName('./molecules/date-slider/date-slider')
    )
    .globalResources(
      PLATFORM.moduleName('./molecules/inputs/z-date-picker'),
      PLATFORM.moduleName('./molecules/inputs/z-time-picker/z-time-picker'),
      PLATFORM.moduleName(
        './molecules/inputs/countrytimezone/z-timezone-selector'
      ),
      PLATFORM.moduleName(
        './molecules/inputs/countrytimezone/z-country-selector'
      )
    )
    // Organisms
    .globalResources(
      PLATFORM.moduleName('./organisms/listcondensed/condensed-list')
    )
    .globalResources(
      PLATFORM.moduleName('./organisms/listcondensed/condensed-list-static')
    )
    .globalResources(PLATFORM.moduleName('./organisms/listbasic/list-basic'))
    .globalResources(
      PLATFORM.moduleName('./organisms/listcondensed/condensed-list'),
      PLATFORM.moduleName('./organisms/listcondensed/condensed-list-static')
    )
    .globalResources(PLATFORM.moduleName('./organisms/listcustom/custom-list'))
    .globalResources(PLATFORM.moduleName('./organisms/grid/z-grid'))
    .globalResources(PLATFORM.moduleName('./organisms/node/z-node'))
    .globalResources(PLATFORM.moduleName('./organisms/connector/z-connector'))
    .globalResources(
      PLATFORM.moduleName('./organisms/statscollection/z-stats-stack')
    )
    .globalResources(PLATFORM.moduleName('./organisms/charts/z-chart'))
    .globalResources(
      PLATFORM.moduleName('./organisms/reportinputform/report-input-form')
    )
    .globalResources(
      PLATFORM.moduleName('./organisms/embeddedwebphone/embedded-webphone')
    )
    // Templates
    .globalResources(
      PLATFORM.moduleName('./templates/dialogwizard/z-dialog-wizard')
    )
    .globalResources(PLATFORM.moduleName('./templates/grid/z-css-grid'))
    .globalResources(PLATFORM.moduleName('./templates/pagebody/z-page-body'))
    .globalResources(
      PLATFORM.moduleName('./templates/pagecolumn/z-page-column')
    )
    .globalResources(
      PLATFORM.moduleName('./templates/pagefooter/z-page-footer')
    )
    .globalResources(
      PLATFORM.moduleName('./templates/pageheader/z-page-header')
    )
    .globalResources(PLATFORM.moduleName('./templates/pagelist/z-page-list'))
    .globalResources(PLATFORM.moduleName('./templates/pagetabs/z-page-tabs'))
    .globalResources(
      PLATFORM.moduleName(
        './templates/sidepanellisttemplate/z-side-panel-list-template'
      )
    )
    .globalResources(PLATFORM.moduleName('./templates/wizard/z-wizard'))
    .globalResources(PLATFORM.moduleName('./templates/wizard/z-wizard-header'))
    // Custom HTML Elements
    .globalResources(
      PLATFORM.moduleName('./atoms/textelements/z-single-text-line.html')
    )
    .globalResources(
      PLATFORM.moduleName('./organisms/utility/z-utility-bar.html')
    )
    // CUSTOM
    .globalResources(PLATFORM.moduleName('./custom/table-input'))

    // NEW
    .globalResources(
      PLATFORM.moduleName('../components_v2/dynamic-table/dynamic-table')
    )

    // VIEWS
    .globalResources(
      PLATFORM.moduleName('./views/z-list-view'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-basic'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-cell'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-column'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-node-connector'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-node-menu'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-node'),
      PLATFORM.moduleName('./organisms/gridbasic/z-grid-placeholder'),
    )
    .globalResources(
      PLATFORM.moduleName('./atoms/richtext/rich-text-editor'),
      PLATFORM.moduleName('./atoms/richtext/rich-text-viewer'),
    );
}
