import { LogManager } from 'aurelia-framework';
import { BaseModel } from 'zailab.abstract';

import moment from 'moment';

const logger = LogManager.getLogger('ProspectListModel');

export default class extends BaseModel {
  name = null;
  numberOfTasksTotalUploaded = 0;
  numberOfTasksRemaining = 0;
  numberOfTasksNotServed = 0;
  numberOfTasksRequeued = 0;
  numberOfTasksStale = 0;
  numberOfTasksServed = 0;
  numberOfTasksCleared = 0;
  numberOfTasksCompleted = 0;
  numberOfTasksPending = 0;
  numberOfTasksSuccessful = 0;
  numberOfTasksUnsuccessful = 0;
  numberOfTasksNotServedReady = 0;
  numberOfTasksRemainingReady = 0;
  numberOfTasksRequeuedReady = 0;
  tasksRemaining = 0;
  prospectListId = null;
  status = null;
  taskTemplateId = null;
  taskTemplateName = null;
  archived = false;
  fileName = null;

  percentageTasksRemaining = 0;
  percentageOfTasksServed = 0;
  percentageTasksSuccessful = 0;
  percentageTasksUnsuccessful = 0;
  startTimestamp = null;
  endTimestamp = null;
  timezone = null;
  dailyStats = null;
  todayStats = null;

  constructor(prospectList) {
    super();
    if (prospectList && prospectList._id) {
      this.prospectListId = prospectList._id;
    }
    this.modelEntity(prospectList);
  }

  modelEntity(prospectList) {
    super.mapProperties(prospectList);

    this.tasksRemaining = this.numberOfTasksRemaining + this.numberOfTasksPending;

    this.calculateTasksRemaining();
    this.calculatePercentageOfTasksServed();
    this.calculateTasksSuccessful();
    this.calculateTasksUnsuccessful();
  }

  calculatePercentageOfTasksServed() {
    if (!this.numberOfTasksTotal) {
      this.percentageOfTasksServed = 0;
    }
    if (!this.numberOfTasksServed || !this.numberOfTasksTotal) {
      this.percentageOfTasksServed = 0;
      return;
    }
    const num = this.numberOfTasksServed / this.numberOfTasksTotal;

    let percentage = Math.round(num * 100);
    this.percentageOfTasksServed = percentage > 100 ? 100 : percentage;
  }

  calculateTasksRemaining() {
    if (!this.numberOfTasksTotal) {
      this.percentageTasksRemaining = 0;
    }
    if (!this.tasksRemaining || !this.numberOfTasksTotal) {
      this.percentageTasksRemaining = 0;
      return;
    }
    const num = this.tasksRemaining / this.numberOfTasksTotal;

    let percentage = Math.round(num * 100);
    this.percentageTasksRemaining = percentage > 100 ? 100 : percentage;
  }

  calculateTasksSuccessful() {

    if (this.numberOfTasksSuccessful === 0) {
      this.percentageTasksSuccessful = 0;
      return;
    }

    const totalOfOutcomeTasks = this.numberOfTasksSuccessful + this.numberOfTasksUnsuccessful;
    const num = this.numberOfTasksSuccessful / totalOfOutcomeTasks;

    let percentage = Math.round(num * 100);
    this.percentageTasksSuccessful = percentage > 100 ? 100 : percentage;
  }

  calculateTasksUnsuccessful() {

    if (this.numberOfTasksUnsuccessful === 0) {
      this.percentageTasksUnsuccessful = 0;
      return;
    }

    const totalOfOutcomeTasks = this.numberOfTasksSuccessful + this.numberOfTasksUnsuccessful;
    const num = this.numberOfTasksUnsuccessful / totalOfOutcomeTasks;

    let percentage = Math.round(num * 100);
    this.percentageTasksUnsuccessful = percentage > 100 ? 100 : percentage;
  }

  getStartDate(){
    if(this.startTimestamp && this.timezone){
      return moment(this.startTimestamp).utcOffset(this.timezone).format('DD/MM/YYYY');
    }
    return 'N/A';
  }

  getEndDate(){
    if(this.endTimestamp && this.timezone) {
      return moment(this.endTimestamp).utcOffset(this.timezone).format('DD/MM/YYYY');
    }
    return 'N/A';
  }
}