import { FeatureFlagService } from 'zailab.common';
/**
 * Draw IO :: Feature model :: Update when making feature changes
 * https://www.draw.io/#G15PPElZ3HONpiWPLoQP-F0gIJrxCB-F39
 */
import {LogManager, autoinject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';

import {PlaceholderService} from "../../../_common/services/placeholder-service";
import {ZIOplog} from '../../../../typings/zai/zai.common';

//@ts-ignore
import organisationService from '../../organisation/organisation/organisation-service';
import { InteractionAnalyticsConfiguration } from '../../organisation/organisation/interaction-analytics-configuration-model';
import { VerintSpeechAnalyticsConfigurationDialog } from '../../organisation/organisation/verint-analytics/speech/verint-speech-analytics-configuration';
import { VerintTextAnalyticsConfigurationDialog } from '../../organisation/organisation/verint-analytics/text/verint-text-analytics-configuration';
import { VerintConfigurationDialog } from '../../organisation/organisation/verint/verint-configuration';
import { Router } from 'aurelia-router';
import { VerintWFMRealTimeSTCIDialog } from '../../organisation/organisation/verint/verint-realtime-configuration';
import { VerintTextAnalyticsDialog } from 'features/organisation/organisation/verint-analytics/text/verint-text-analytics';

const logger = LogManager.getLogger('Verint Providers List');

interface IIntegration {
  name: string;
  displayName: string;
  enabled?: boolean;
  hasIcon?: boolean;
}

@autoinject
export class IntegrationsList {
  private integrations: IIntegration[] = []; 
  private placeholderService: PlaceholderService;
  private placeholders: number = 0;
  private container: Element;
  private credentialsOplog: ZIOplog;
  private workforceManagementId;

  public interactionAnalyticsEnabled: boolean = false;
  public workforceManagementVerint: boolean = false;
  public verintEnabled: boolean = false;
  public actions = [{
    action: 'view',
    label: 'view',
  }];

  constructor(
    private organisationService: organisationService,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) {}

  public activate(): void {
    this.getIntegrationData();
  }

  private getIntegrationData(): void {
    this.getVerintData();
    
    this.featureFlagService.isEnabled('workforceManagementVerint')
    .then((workforceManagementVerint) => {
      this.workforceManagementVerint = workforceManagementVerint;
      if (workforceManagementVerint) {
        this.getWorkForceManagementData();
      } else {
        this.generatePlaceholders();
      }
    });
  }

  private getVerintData(): void {
    this.organisationService.retrieveOrganisationInfo().then((response) => {
      logger.info('Got analytics config ', response);
      this.verintEnabled = response.verintEnabled;
      const verintSpeechAnalyticsConfig = this.extractVerintSpeechAnalyticsConfig(response.interactionAnalyticsProviderConfig);

      logger.debug('Retrieved organisation verintSpeechAnalyticsConfig', verintSpeechAnalyticsConfig);
      this.integrations.push({
        ...verintSpeechAnalyticsConfig,
        name: 'Verint',
        displayName: 'Speech Analytics'
      });
      
      const verintTextAnalyticsConfig = this.extractVerintTextAnalyticsConfig(response.interactionAnalyticsProviderConfig);

      logger.debug('Retrieved organisation verintTextAnalyticsConfig', verintTextAnalyticsConfig);
      this.integrations.push({
        ...verintTextAnalyticsConfig,
        name: 'Verint',
        displayName: 'Text Analytics'
      });
      
    }, (error) => {
      logger.warn('Could not retrieve Organisation information.', error);
    });
  }

  public getWorkForceManagementData(): void {

    this.organisationService.getWorkforceManagement()
      .then((response) => {
        this.workforceManagementId = response.workforceManagementId;
        this.integrations.unshift({
          name: 'Realtime Adherence',
          displayName: 'Realtime Adherence',
          enabled: response.verintRealtimeTimeMonitoringEnabled,
          ...response
        });
        this.integrations.unshift({
          name: 'Workforce Management',
          displayName: 'Workforce Management',
          enabled: response.verintEnabled,
          ...response
        });
        this.generatePlaceholders();
      });
  }

  private extractVerintSpeechAnalyticsConfig(providerConfig: any): InteractionAnalyticsConfiguration {
    let verintConfig: any = new InteractionAnalyticsConfiguration();
    if (!providerConfig) {
      verintConfig.enabled = false;
      return verintConfig;
    }
    logger.info('Got provider config ', providerConfig);
    verintConfig = providerConfig.filter(config => config.name === 'Verint' && config.type === 'SPEECH');
    if (!verintConfig || verintConfig.length === 0) {
      verintConfig.enabled = false;
      return verintConfig;
    }
    return verintConfig[0];
  }

  private extractVerintTextAnalyticsConfig(providerConfig: any): InteractionAnalyticsConfiguration {
    let verintConfig: any = new InteractionAnalyticsConfiguration();
    if (!providerConfig) {
      verintConfig.enabled = false;
      return verintConfig;
    }

    verintConfig = providerConfig.filter(config => config.name === 'Verint' && config.type === 'TEXT');
    if (!verintConfig || verintConfig.length === 0) {
      verintConfig.enabled = false;
      return verintConfig;
    }
    return verintConfig[0];
  }

  public attached(): void {
    this.generatePlaceholders();
  }


  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.integrations.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
    if (this.placeholderService) {
      //@ts-ignore
      this.placeholders = this.placeholderService.generatePlaceholders(this.integrations.length);
    }
  }

  public selectIntegration(integration: { displayName: string }): void {
    switch(integration.displayName) {
      case 'Speech Analytics':
        this.selectVerintSpeechIntegration(integration);
        break;
      case 'Text Analytics':
          this.selectVerintTextIntegration(integration);
          break;  
      case 'Workforce Management':
        this.selectWFMIntegration(integration);
        break;
      case 'Realtime Adherence':
        this.selectRealtimeIntegration(integration);
        break;
      default:
        break;
    }
  }

  private selectVerintSpeechIntegration(integration: any): void {

    this.dialogService
      .open({ viewModel: VerintSpeechAnalyticsConfigurationDialog, model: { ...integration, workforceManagementId: this.workforceManagementId } })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _integration = response.output;
          this.integrations.splice(2, 1, {
            ...integration,
            ..._integration
          });
        }
      });
  }

  private selectVerintTextIntegration(integration: any): void {

    this.dialogService
      .open({ viewModel: VerintTextAnalyticsDialog, model: { ...integration, workforceManagementId: this.workforceManagementId }})
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _integration = response.output;
          this.integrations.splice(3, 1, {
            ...integration,
            ..._integration
          });
        }
      });
  }

  private selectWFMIntegration(integration: any): void {
    
    this.dialogService
      .open({ viewModel: VerintConfigurationDialog, model: integration })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _integration = response.output;
          this.integrations.splice(0, 1, {
            ...integration,
            ..._integration,
            enabled: _integration.verintEnabled
          });
        }
      });
  }

  private selectRealtimeIntegration(integration: any): void {
    
    this.dialogService
      .open({ viewModel: VerintWFMRealTimeSTCIDialog, model: JSON.parse(JSON.stringify(integration || {})) })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _integration = response.output;
          this.integrations.splice(1, 1, {
            ...integration,
            ..._integration,
            enabled: _integration.verintRealtimeTimeMonitoringEnabled
          });
        }
      });
  }

  public deactivate(): void {
    this.credentialsOplog && this.credentialsOplog.unsubscribe();
  }

  public back(): void {
    this.router.navigateBack();
  }
}
