import { LogManager, computedFrom } from 'aurelia-framework';
import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('MemberModel');

export class MemberModel extends BaseModel {

  public id: string = null;
  private _memberId: string = null;
  public firstName: string = null;
  public surname: string = null;
  public fName: string = null;
  public service: string = null;
  public services: any[] = [];
  public site: string = null;
  public extension: string = null;
  public presence: string = null;
  public email: string = null;
  private _roleName: string = null;
  public roles: string[] = [];
  public activity: string = null;
  public personId: string = null;
  public teams: any[] = [];
  public permissions: any[] = [];
  public originSystem: string = null;
  public businessPartners: Array<{ businessPartnerId: string, businessPartnerName: string }> = [];
  public contactCentres: Array<{ contactCentreId: string, contactCentreName: string }> = [];
  public externalPhoneNumbers: string[] = [];

  constructor(member?: MemberModel) {
    super();
    super.mapProperties(member);
    if (member && member.originSystem === "Zailab") {
      if (this.externalPhoneNumbers.length > 0) {
        this.externalPhoneNumbers.push(this.extension);
      }
    }
  }
	
	set memberId(memberId: string) {
    this._memberId = memberId;
	}
	
	get memberId(): string {
		if(this._memberId){
			return this._memberId;
		}
		return this.id;
	}

  set roleName(roleName: string) {
    this._roleName = roleName;
  }

  get roleName(): string {
    if (this._roleName) {
      return this._roleName;
    } else if (this.roles) {
      if(this.roles[0] === "ADMINISTRATOR") {
        return "Administrator";
      } else if(this.roles[0] === "AGENT") {
        return "Agent";
      } else if(this.roles[0] === "OFFICE_EMPLOYEE") {
        return "Office Employee";
      } else if(this.roles[0] === "QA") {
        return "QA";
      } else if(this.roles[0] === "QA_MANAGER") {
        return "QA Manager";
      } else if(this.roles[0] === "CAMPAIGN_MANAGER") {
        return "Campaign Manager";
      } else if(this.roles[0] === "TEAM_LEADER") {
        return "Team Leader";
      }
    }
  }

  @computedFrom('firstName', 'surname')
  get fullName(): string {
    return this.firstName + ' ' + this.surname;
  }

  @computedFrom('firstName', 'surname')
  get fullNameShort(): string {
    return this.firstName + ' ' + (this.surname ? this.surname.charAt(0) : '');
  }

  @computedFrom('memberId')
  get searchableText(): string {
    let teamList = [];
    if (this.teams) {
      this.teams.forEach(team => {
        let teamName = team.name.toLowerCase();
        teamList.push(teamName);
      });
      let teamString = teamList.map(teamName => { return teamName; }).join(' ');
      return this.concat(this.firstName, this.surname, this.email, this.extension, this.presence, this.roleName, teamString);
    }
    return this.concat(this.firstName, this.surname, this.email, this.extension, this.presence, this.roleName);
  }

  private concat(...toConcat: any[]): string {
    return toConcat.map((item) => {
      if (!item) {
        return "";
      }
      if (typeof item === "string") {
        return item.toLowerCase();
      }
      if (typeof item === "number") {
        return item.toString();
      }
      return "";
    }).join(' ');
  }

  @computedFrom('activity')
  get memberActivityColor(): string {
    if (this.activity === 'Wrap Up') {
      return 'var(--pending)';
    } else {
      return 'color-brilliant-rose';
    }
  }
}

