import { LogManager, autoinject } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';

import { WorkTypeModel } from './models/work-type-model';
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
import { EmptyStateAbstract } from '../abstracts/empty-state-abstract';
import { WorkTypeDialogService } from './work-type-dialog-service';
import { SearchTools } from 'zailab.common';

const logger = LogManager.getLogger('WorkTypeDialog');
declare const PLATFORM;

@autoinject
export class WorkTypeDialog extends EmptyStateAbstract {

  private existingProperties: any = {};
  private existingConnections: Array<ConnectorModel> = [];
  private nodeData: NodeModel;
  private searchCriteria: string;
  private workTypes: Array<WorkTypeModel> = [];
  private selectedWorkType: any;
  private isSearching: boolean;
  private isCallback: boolean;
  private isValid: boolean = false;
  private oplog: any;
  private searchTerm: string;

  protected nodeConfiguration: ZNodeConfig;

  protected emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-work-type.svg',
    heading: `No work type to display.`,
    description: `You haven't created any work types yet or all your work types are already assigned to a flow.<br>Please click the link below to create a work type for this flow.`,
    hasButton: true,
    buttonText: `Create Work Type`,
    hasTip: false,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName(`features/interaction/interactionFlow/interaction-designer/dialogs/work-type/work-type-creator-wrapper`)
  };

  constructor(public dialogController: DialogController, private workTypeService: WorkTypeDialogService, protected dialogService: DialogService) {
    super(dialogService);
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeConfiguration.mode = 'embedded';
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    if (this.nodeData.properties) {
      this.existingProperties = this.nodeData.properties;
      if (this.nodeData.properties.selectedWorkType) {
        this.selectedWorkType = this.nodeData.properties.selectedWorkType
      } else if (this.nodeData.properties.taskTemplates) {
        this.selectedWorkType = this.nodeData.properties.taskTemplates[0]
      }
    }
    this.isCallback = this.nodeData.eventId === 'callback';
  }

  public attached(): void {
    this.retrieveWorkTypes();
  }

  private retrieveWorkTypes(): void {
    this.nodeDialogInitialised = false;
    setTimeout(() => {
      this.workTypeService.retrieveWorkTypes(this.searchCriteria, this.isCallback).then(workTypes => this.handleWorkTypesRetrieved(workTypes), this.reportError);
    }, 1000);
  }

  private handleWorkTypesRetrieved(workTypes: Array<WorkTypeModel>): void {
    this.nodeDialogInitialised = true;
    this.workTypes = workTypes ? workTypes : [];

    this.hasEntries = !!this.workTypes.length;

    this.workTypes.forEach(_worktype => {
      _worktype.hoverText = this.createHoverText(_worktype);
    });

    if (this.workTypes.length === 1) {
      this.selectedWorkType = this.workTypes[0];
      this.selectedWorkType.isSelected = true;
      this.isValid = true;
    } else {
      this.preselectWorktypeFromNodeData();
    }
  }

  private preselectWorktypeFromNodeData(): void {
    let workTypeFound: boolean;

    if (this.nodeData.properties.taskTemplates && this.nodeData.properties.taskTemplates.length > 0) {
      this.selectedWorkType = this.nodeData.properties.taskTemplates[0];
    }

    this.workTypes.forEach(_workType => {
      if (this.selectedWorkType && this.selectedWorkType.id === _workType.id) {
        this.selectedWorkType = _workType;
        this.selectedWorkType.isSelected = true;
        workTypeFound = true;
        this.isValid = true;
      }
    });

    if (this.selectedWorkType && !workTypeFound) {
      this.selectedWorkType.hoverText = this.createHoverText(this.selectedWorkType);
      this.workTypes.unshift(this.selectedWorkType);
      this.isValid = true;
    }
  }

  private reportError(msg: any): void {
    logger.warn('Error retrieving Work Types', msg);
  }

  // Protected methods

  protected activateContentCreator(): void {
    super.activateContentCreator(this.nodeConfiguration);
  }

  protected handleContentCreated(_contentCreatorResponse: any): void {
    super.handleContentCreated(_contentCreatorResponse);
    this.retrieveWorkTypes();
  }

  protected cancelContentCreator(): void {
    super.cancelContentCreator();
  }

  // Methods called from View

  private viewWorktypeClicked(selectedWorkType: WorkTypeModel): void {

    this.workTypes.forEach(workType => {
      workType.isSelected = false;
    });

    this.selectedWorkType = selectedWorkType;
    this.selectedWorkType.isSelected = true;
    this.isValid = true;
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {
    if (!this.nodeData.properties) {
      this.nodeData.properties = this.existingProperties;
    }

    this.nodeData.properties.id = this.selectedWorkType.id;
    this.nodeData.properties.name = this.selectedWorkType.name;
    this.nodeData.properties.isDefined = true;
    this.nodeData.properties.businessPartnerId = this.selectedWorkType.businessPartner ? this.selectedWorkType.businessPartner.businessPartnerId : null;
    this.nodeData.properties.businessPartnerName = this.selectedWorkType.businessPartner ? this.selectedWorkType.businessPartner.businessPartnerName : null;
    this.nodeData.properties.contactCenterId = this.selectedWorkType.contactCentre ? this.selectedWorkType.contactCentre.contactCentreId : null;
    this.nodeData.properties.contactCenterName = this.selectedWorkType.contactCentre ? this.selectedWorkType.contactCentre.contactCentreName : null;
    this.nodeData.properties.businessValue = this.selectedWorkType.businessValue.toString();
    this.nodeData.properties.channel = this.selectedWorkType.channel;
    this.nodeData.properties.roles = this.selectedWorkType.roles;
    this.nodeData.properties.services = [this.selectedWorkType.service];
    this.nodeData.properties.sites = this.selectedWorkType.sites;
    this.nodeData.properties.skillGroups = this.selectedWorkType.skillGroups;
    this.nodeData.properties.targetResponse = this.selectedWorkType.targetResponse;
    this.nodeData.properties.waitTime = this.selectedWorkType.waitTime;
    this.nodeData.properties.taskTemplates = [this.selectedWorkType];
    this.nodeData.properties.sla = '';
    this.nodeData.properties.taskDataSLAs = [
      {
        waitTime: this.selectedWorkType.waitTime.toString(),
        answeredCalls: this.selectedWorkType.targetResponse.toString()
      }
    ];

    let finalConnections: Array<ConnectorModel> = this.mapConnectors(this.existingConnections);
    this.nodeData.connections = finalConnections;
    this.dialogController.ok(this.nodeData);
  }

  private toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  // Utility Functions

  private createHoverText(_worktype: WorkTypeModel): string {
    let _sitesConcatenated: string = _worktype.sites.map(_site => {
      return _site.siteName
    }).join(', ');
    
    if(_worktype.channel === 'Outbound Call' || _worktype.channel === 'SMS' || _worktype.channel === 'Email' || _worktype.channel === 'Ticket') {
      return `Service: ${_worktype.service.serviceName}<br>Sites: ${_sitesConcatenated}<br>Target: ${_worktype.waitTime / 3600}h / ${_worktype.targetResponse}%`;
    }
    return `Service: ${_worktype.service.serviceName}<br>Sites: ${_sitesConcatenated}<br>Target: ${_worktype.waitTime}s / ${_worktype.targetResponse}%`;
  }

  private mapConnectors(_existingConnections: Array<ConnectorModel>): Array<ConnectorModel> {
    let _connections: Array<ConnectorModel> = [
      new ConnectorModel({
        name: 'always',
        dest: typeof _existingConnections[0] !== 'undefined' ? _existingConnections[0].dest : null,
        source: { connectorIndex: 0, nodeID: this.nodeData.id },
        customExtensions: null
      })
    ];

    return _connections;
  }

  public doNothing(): void {}

  protected startsWithMatch(searchExpression: any, value: any): boolean {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value.name.toLowerCase().startsWith(searchExpression.toLowerCase());
  }

  protected partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

}
