import { autoinject, LogManager } from "aurelia-framework";
import { ValidationController, ValidationControllerFactory, validateTrigger, ValidationRules } from "aurelia-validation";
import { ObserverLocator } from 'aurelia-binding';

import { SearchableVariablesListModel } from "./searchable-variables-list-model";
import { BootstrapFormRenderer } from "../../../../../../_common/renderers/bootstrap-form-renderer";

const logger = LogManager.getLogger('SearchableVariablesListRules');

@autoinject
export class SearchableVariablesListRules {

  private searchableVariablesList: SearchableVariablesListModel;
  private validation: ValidationController;
  private searchableVariableUniqueName: boolean = false;

  constructor(
    private validationControllerFactory: ValidationControllerFactory,
    private observerLocator: ObserverLocator
  ) { }

  public init(searchableVariablesList: SearchableVariablesListModel): void {
    this.searchableVariablesList = searchableVariablesList;
    this.initValidation();
    this.initObservers();
  }

  public close(): void {
    this.observerLocator.getObserver(this, 'name').unsubscribe(() => { });
  }

  private initValidation(): void {
    this.validation = this.validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let field of this.searchableVariablesList.searchableVariables) {
          if (field.name.toLowerCase() === value.toLowerCase() && !field.deleted) {
            this.searchableVariableUniqueName = false;
            return false;
          }
        }
        this.searchableVariableUniqueName = true;
        return true;
      }, null);
    ValidationRules
      .customRule('maxLength', (value) => {
        if (this.searchableVariablesList.searchableVariables.length >= 10) {
          return false;
        }
        this.searchableVariableUniqueName = true;
        return true;
      }, null);
    ValidationRules
      .ensure('name')
      /**/.required().withMessage('Please enter field name.')
      /**/.then()
      /**//**/.satisfiesRule('uniqueName').withMessage('Please enter a unique variable name.')
      .then().satisfiesRule('maxLength').withMessage('No more than 10 searchable variables allowed')
      .on(this.searchableVariablesList);
  }

  private initObservers(): void {
    this.observerLocator
      .getObserver(this.searchableVariablesList, 'name')
      .subscribe(() => this.validateVariableChanges());
  }

  private validateVariableChanges(): void {
    this.validation.validate()
      .then(validation => {
        if (validation.valid && this.hasUniqueName()) {
          this.searchableVariablesList.isValid = true;
        } else {
          this.searchableVariablesList.isValid = false;
        }
      })
      .catch(e => logger.error('validateVariableChanges :: e=', e));
  }

  private hasUniqueName(): boolean {
    return this.searchableVariablesList.name && this.searchableVariableUniqueName;
  }
}