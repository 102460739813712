import { autoinject } from 'aurelia-framework';
import { AUDIO_ASSETS, AudioService } from '../../../zailab.common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ALERT_SOUND_EVENTS } from './alert-sound-events';

@autoinject
export class AlertSoundService {
  private localStorageKey = 'zailab_audio_sound_config';

  constructor(
    private audioService: AudioService,
    private eventAggregator: EventAggregator
  ) {}

  public init(): void {
    this.eventAggregator.subscribe(ALERT_SOUND_EVENTS.PLAY_SOUND_ALERT, () =>
      this.playSoundAlert()
    );
  }

  public playSoundAlert(): void {
    const data = this.retrieve();
    const soundConfig = data.soundConfig;
    const selectedAudio = data.selectedAudio;
    if (soundConfig === 'mute') {
      return;
    }
    this.audioService.play(AUDIO_ASSETS[selectedAudio]);
  }

  public testSoundAlert(selectedAudio: string): void {
    this.audioService.play(AUDIO_ASSETS[selectedAudio]);
  }

  public retrieve(): any {
    let soundConfig = '';
    let selectedAudio = '';
    const item = localStorage.getItem(this.localStorageKey);
    if (!item) {
      soundConfig = 'sound';
      selectedAudio = 'INDUSTRIAL_WARNING';
    } else {
      const data = JSON.parse(item);
      soundConfig = data.soundConfig;
      selectedAudio = data.selectedAudio;
    }
    return {
      soundConfig,
      selectedAudio,
    };
  }

  public update(soundConfig: string, selectedAudio: string): void {
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify({
        soundConfig,
        selectedAudio,
      })
    );
  }
}
