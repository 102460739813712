import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {Router} from 'aurelia-router';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer, SessionStore} from 'zailab.common';
import OrganisationService from '../organisation-service';
/*
 * */
const logger = LogManager.getLogger('SAMLConfigurationDialog');
/*
 * */
@inject(DialogController, ValidationControllerFactory, OrganisationService, Router)
export class SAMLConfigurationDialog {

  organisationFormGroup;
  dialogHeader = "SAML Configuration";
  samlEnabled = false;
  path = '';
  originalPath = '';
  metadataUrl = '';
  idPRedirectUrl = '';
  redirectUrl = '';
  entityId = '';
  origin = ''

  constructor(dialogController, validationControllerFactory, organisationService, router) {
    this.dialogController = dialogController;
    this.organisationService = organisationService;
    this.router = router;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param name
   */
  activate(data) {
    this.samlEnabled = data.samlEnabled;
    this.path = data.path;
    this.originalPath = this.path;
    if(this.path.length === 0){
      this.path = data.organisationName.replace(/[^a-zA-Z]/ig, "");;
    }
    this.metadataUrl = data.metadataUrl;
    this.idPRedirectUrl = data.idPRedirectUrl;
    this.origin = this.router.history.location.origin;
    this.pathChanged();

    this._initValidation();
  }

  /**
   * initialise the form validations
   * @param validation
   * @private
   */
  _initValidation() {


    ValidationRules
      .customRule('unique', (value) => {
        if(!value) {
          return false;
        }
        if(value === this.originalPath){
          return true;
        }
        return this.organisationService.getLoginPath(value).then((response) => {
          if(response !== null && response === false){
            return true;
          }
        return false;
        }, (error) => {
          return false;
        });

      });

    ValidationRules
      .customRule('validValue', (value) => {
        if(!value || value.length < 2) {
          return true;
        }
        let re = /^([a-zA-Z'])+$/;
        return re.test(value);
      });

    ValidationRules
      .ensure('path')
      .required().withMessage('URL path is required.')
      .satisfiesRule('validValue').withMessage('This is not a valid input.')
      .satisfiesRule('unique').withMessage('This path is already taken.')
      .ensure('metadataUrl')
      .required().withMessage('Metadata URL is required.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.organisationFormGroup.reset();
    this.dialogController.cancel();
  }

  /**
   * does the form validation
   * closes the dialog
   */
  rename() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }
      this.dialogController.ok({
        samlEnabled: this.samlEnabled,
        path: this.path,
        metadataUrl: this.metadataUrl,
        idPRedirectUrl: this.idPRedirectUrl
      });
    }, () => {});
  }

  pathChanged(){
    this.redirectUrl = this.origin + '/login/saml2/sso/' + this.path;
    this.entityId = this.origin + '/saml2/service-provider-metadata/' + this.path;
  }

  toggleSAMLEnabled(){
    if(this.samlEnabled === true){
      this.samlEnabled = false;
    } else {
      this.samlEnabled = true;
    }
  }
}

