import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/*
 * */
import { RolesService } from '../../../../../organisation/organisation/roles/roles-service';
import { SessionStore } from 'zailab.common';
import { AbstractList } from 'zailab.abstract';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 * */
const logger = LogManager.getLogger('Roles');
/*
 * */
@inject(RolesService, Router, SessionStore, EventAggregator)
export class Roles {

  ready = false;
  role = 'Agent';

  constructor(roleService, router, sessionStore, eventAggregator) {
    this.roleService = roleService;
    this.router = router;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
  }

  activate(wizard) {

    if (wizard) {
      this._initWizard(wizard);
    }

    this.roleService.displayRoles(this.sessionStore.get.organisation.organisationId).then(
      (itemList) => {
        this.itemList = itemList;
        this.ready = true;
        this._select({ roleName: wizard && wizard.data.role ? wizard.data.role : 'Agent' });
      },
      (error) => {
        logger.info('retrieve organisation roles > error = ', error);
        this.ready = true;
      });
  }

  /**
   * subscribe to next click in wizard to validate step
   * @param wizard
   * @private
   */
  _initWizard(wizard) {
    this.wizard = wizard;

    wizard.beforeNextStep(() => {
      this.wizard.continue();
    });
  }

  selectItem(_selectedItem) {
    this.wizard.resetCompletionStates(-1);
    this.wizard.data.channels = null;
    this._select(_selectedItem);
  }

  /**
   * used for both select and deselect for single selection
   * @param role
   * @private
   */
  _select(role) {

    this._setSelected(role);

    if (this.wizard) {
      this.wizard.step.complete({
        role: role.roleName
      });
    }
  }

  /**
   * @param role
   * @private
   */
  _setSelected(role) {

    for (let _role of this.itemList) {
      _role.isSelected = false;

      if (!role) {
        if (_role.roleName.toLowerCase() === 'agent') {
          _role.isSelected = true;
          this.wizard.data.role = _role.roleName;
        }
        continue;
      }

      if (role.roleName === _role.roleName) {
        _role.isSelected = true;
        this.wizard.data.role = _role.roleName;
      }
    }
  }

  /**
   * used for searchFilter
   * @param searchExpression
   * @param value
   * @returns {*|boolean}
   */
  startsWithMatch(searchExpression, value) {
    return searchExpression && value.roleName.toLowerCase().startsWith(searchExpression.toLowerCase());
  }
}

