import {LogManager, autoinject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';
/**/
const logger = LogManager.getLogger('ValidationErrorDialog');
/**/
interface Error {
  defaultMessage: string,
  code: string
}
/**/
@autoinject
export class ValidationErrorDialog {

    private dialogController: DialogController;
    private errors: Error[] = [];
    private channel: string;
    private channelImageString: string;

    constructor(dialogController: DialogController, private zaiFileLoader: ZaiFileLoader) {

        this.dialogController = dialogController;
    }

    public activate(errorMetadata: {errors: Error[], channel: string}): void {

        this.errors = errorMetadata.errors;
        this.channel = errorMetadata.channel;
        this.channelImageString = this.channel.toLocaleLowerCase().replace(' ', '_');
    }

    private cancel(): void {
        this.dialogController.cancel();
    }

    public getImage(image: string): string {
      const imageContent = this.zaiFileLoader.getFile(image);
      if (imageContent) {
        return imageContent;
      }
      return './_assets/img/svg/' + image + '.svg';
    }
}