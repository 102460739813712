import { CONVERSATION_ACTIONS } from './../../conversation/interaction/interaction-actions';
import { ConversationModel } from './../../conversation/conversation-model';
import { InteractionService } from './../../interactions/interaction-service';
import { SessionStore } from './../../../../_common/stores/session-store';
import { LogManager } from 'aurelia-framework';
import { ContactModel } from './../contact-model';
import { ContactService } from './../contact-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { InteractionModel } from './../../../dashboard/teamleader/models/interaction-model';
import { ConversationService } from './../../conversation/conversation-service';
import { autoinject } from "aurelia-dependency-injection";
import { ZIOplog } from "../../../../../typings/zai/zai.common";
import { MemberInteractionModel } from '../../conversation/interaction/member-interaction-model';

const logger = LogManager.getLogger('OplogManager');

@autoinject
export class OplogManager {
  
  public connectedMemberInteractionsOplog: ZIOplog;
  private connectedInteractionSubscriptions: Map<string, boolean> = new Map();
  private connectedInteractions: { interactions: any[] };

  private contactsOplog: { [key: string]: ZIOplog } = {};
  private unlinkedInteractionsOplog: { [key: string]: ZIOplog } = {};
  private conversationListOplog: { [key: string]: ZIOplog } = {};
  private conversationsOplog: { [key: string]: ZIOplog } = {};
  private interactionsOplog: { [key: string]: ZIOplog } = {};

  constructor(
    private contactService: ContactService,
    private conversationService: ConversationService,
    private interactionService: InteractionService,
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore
  ) {
    this.subscribeToOplogEvents();
    this.subscribeToConnectedMemberInteractions();
  }

  private subscribeToOplogEvents(): void {
    this.eventAggregator.subscribe('contact-oplog-subscribe', (contactId: string) => {
      this.subscribeToContactInfoChanges(contactId);
      this.subscribeToUnlinkedInteractionChanges(contactId);
      this.subscribeToConversationListChanges(contactId);
    });
    this.eventAggregator.subscribe('conversation-oplog-subscribe', (conversationId: string) => this.subscribeToConversationChanges(conversationId));

    this.eventAggregator.subscribe('contact-oplog-unsubscribe', (contactId: string) => this.unsubscribeFromContactInfoChanges(contactId));
    this.eventAggregator.subscribe('unlinked-interactions-oplog-unsubscribe', (contactId: string) => this.unsubscribeFromUnlinkedInteractionsChanges(contactId));
    this.eventAggregator.subscribe('conversations-list-oplog-unsubscribe', (contactId: string) => this.unsubscribeFromConversationListChanges(contactId));
    this.eventAggregator.subscribe('conversation-oplog-unsubscribe', (conversationId: string) => this.unsubscribeFromConversationChanges(conversationId));
    this.eventAggregator.subscribe('interaction-oplog-unsubscribe', (interactionId: string) => this.unsubscribeFromInteractionChanges(interactionId));
  }

  public async subscribeToConnectedMemberInteractions(): Promise<void> {

    this.interactionService
      .retrieveConnectedInteractions(this.sessionStore.get.user.memberId)
      .then((data: any) => {
        this.connectedInteractions = { interactions: data };
      })
      .catch((err: Error) => {
        logger.debug('attached :: retrieveConnectedInteractions :: err=', err);
      });

    this.eventAggregator.subscribe('request-connected-interactions', (interactionId: string) => {
      this.connectedInteractionSubscriptions.set(interactionId, true);
      this.distributeConnectedInteractions();
    })

    // This is a centralised oplog for connected interactions
    let oplog = await this.interactionService.getConnectedInteractionsOplog(this.sessionStore.get.user.memberId);
    oplog.on('update', (data) => {
      if (!data || !data.interactions) {
        return;
      }
      this.connectedInteractions = data;
      this.distributeConnectedInteractions();
    });

    this.connectedMemberInteractionsOplog = oplog
  }

  private distributeConnectedInteractions(): void {
    // trigger 'connection-interaction-updated' for all current subscriptions
    const data = this.connectedInteractions;
    if (!data) {
      return;
    }

    this.eventAggregator.publish('connection-interaction-updated', data);

    if (data.interactions && Array.isArray(data.interactions)) {
      data.interactions.forEach(interaction => {
        this.notifyInteractionOfConnectionStatus(interaction)
      });
    }
  }

  private notifyInteractionOfConnectionStatus(interaction: any): void {
    if (this.connectedInteractionSubscriptions.get(interaction.interactionId)) {
        this.eventAggregator.publish('connected-interaction-' + interaction.interactionId, interaction);
    } else {
      setTimeout(() => this.notifyInteractionOfConnectionStatus(interaction), 500);
    }
  }

  public subscribeToContactInfoChanges(contactId: string): void {
    let oplog: ZIOplog = this.contactService.getContactOplog(contactId);
    oplog.on('insert', (response: ContactModel) => 
      this.eventAggregator.publish('contact-info-oplog-update:' + contactId, response)
    );
    oplog.on('update', (response: ContactModel) => 
      this.eventAggregator.publish('contact-info-oplog-update:' + contactId, response)
    );
    this.contactsOplog[contactId] = oplog;
  }

  private unlinkedInteractionNotifyTimeout;
  public subscribeToUnlinkedInteractionChanges(contactId: string): void {
    if (this.unlinkedInteractionsOplog[contactId]) {
      return;
    }
    let oplog: ZIOplog = this.contactService.getUnlinkedInteractionsOplog(contactId);
    oplog.on('update', () => {
      if (this.unlinkedInteractionNotifyTimeout) {
        window.clearInterval(this.unlinkedInteractionNotifyTimeout);
        this.unlinkedInteractionNotifyTimeout = null;
      }

      this.unlinkedInteractionNotifyTimeout = setTimeout(() => {
        this.eventAggregator.publish('unlinked-interactions-oplog-update:' + contactId);
      }, 1000)
    });

    this.unlinkedInteractionsOplog[contactId] = oplog;
  }

  public subscribeToConversationListChanges(contactId: string): void {
    let oplog: ZIOplog = this.contactService.subscribeToConversationChanges(contactId);
    oplog.on('insert', (conversation: ConversationModel) => {
      let newConversation = new ConversationModel(this.conversationService, this.eventAggregator);
      newConversation.conversationId = conversation._id;
      newConversation.contactId = contactId;
      newConversation.firstName = conversation.firstName;
      newConversation.surname = conversation.surname;
      newConversation.mapConversationInfo(conversation);
      this.eventAggregator.publish(CONVERSATION_ACTIONS.LIST_INSERT_OPLOG, newConversation);
    });
    oplog.on('update', (conversation: ConversationModel) => {
      let newConversation = new ConversationModel(this.conversationService, this.eventAggregator);
      newConversation.conversationId = conversation._id;
      newConversation.contactId = contactId;
      newConversation.firstName = conversation.firstName;
      newConversation.surname = conversation.surname;
      newConversation.mapConversationInfo(conversation);
      this.eventAggregator.publish(CONVERSATION_ACTIONS.LIST_UPDATE_OPLOG, newConversation);
    });

    this.conversationListOplog[contactId] = oplog;
  }

  public subscribeToConversationChanges(conversationId: string): void {
    let oplog: ZIOplog = this.conversationService.getInteractionJourneyOplog(conversationId);
    oplog.on('insert', (response: any) => 
      this.eventAggregator.publish('conversation-oplog-update:' + conversationId, { conversationId, response }));
    oplog.on('update', (response: any) => 
      this.eventAggregator.publish('conversation-oplog-update:' + conversationId, { conversationId, response }));

    this.conversationsOplog[conversationId] = oplog;
  }

  public subscribeToInteractionChanges(interactionId: string): void {
    console.log(' CC | oplog subscribeToInteractionChanges >>>>> ', interactionId);
    let oplog: ZIOplog = this.conversationService.getInteractionOplog(interactionId);
    oplog.on('insert', (data) => {
      console.log(' CC | oplog | getInteractionOplog | insert | oplog >>>>>> ', data);
      this.getInteractionData(interactionId);
    });
    oplog.on('update', (data) => {
      console.log(' CC | oplog | getInteractionOplog | update | oplog >>>>>> ', data);
      this.getInteractionData(interactionId);
    });
    this.interactionsOplog[interactionId] = oplog;
  }

  private dataRequestTimeout: any;
  private async getInteractionData(interactionId: string): Promise<any> {
    this.dataRequestTimeout && window.clearTimeout(this.dataRequestTimeout);

    this.dataRequestTimeout = setTimeout(async () => {
      const rawData = await this.conversationService.retrieveInteraction(interactionId);
      logger.debug(' CC | oplog | getInteractionOplog | getInteractionData >>>>>> ', rawData);
      this.eventAggregator.publish('interaction-oplog-update:' + interactionId, rawData);
    }, 1000);
  }

  // ---- UNSUBSCRIBE FROM OPLOGS ----
  public unsubscribeFromContactInfoChanges(contactId: string): void {
    logger.debug(' CC | oplog | getInteractionOplog | unsubscribeFromInteractionChanges >>>>>> 1 ');
    if (this.contactsOplog[contactId]) {
      this.contactsOplog[contactId] && this.contactsOplog[contactId].unsubscribe();
      delete this.contactsOplog[contactId];
    }
  }

  public unsubscribeFromUnlinkedInteractionsChanges(contactId: string): void {
    logger.debug(' CC | oplog | getInteractionOplog | unsubscribeFromInteractionChanges >>>>>> 2 ');
    if (this.unlinkedInteractionsOplog[contactId]) {
      this.unlinkedInteractionsOplog[contactId] && this.unlinkedInteractionsOplog[contactId].unsubscribe();
      delete this.unlinkedInteractionsOplog[contactId];
    }
  }

  public unsubscribeFromConversationListChanges(contactId: string): void {
    logger.debug(' CC | oplog | getInteractionOplog | unsubscribeFromInteractionChanges >>>>>> 3 ');
    if (this.conversationListOplog[contactId]) {
      this.conversationListOplog[contactId] && this.conversationListOplog[contactId].unsubscribe();
      delete this.conversationListOplog[contactId];
    }
  }

  public unsubscribeFromConversationChanges(conversationId: string): void {
    logger.debug(' CC | oplog | getInteractionOplog | unsubscribeFromInteractionChanges >>>>>> 4 ');
    if (this.conversationsOplog[conversationId]) {
      this.conversationsOplog[conversationId] && this.conversationsOplog[conversationId].unsubscribe();
      delete this.conversationsOplog[conversationId];
    }
  }

  public unsubscribeFromInteractionChanges(interactionId: string): void {
    logger.debug(' CC | oplog | getInteractionOplog | unsubscribeFromInteractionChanges >>>>>> ', interactionId);
    if (this.interactionsOplog[interactionId]) {
      this.interactionsOplog[interactionId] && this.interactionsOplog[interactionId].unsubscribe();
      delete this.interactionsOplog[interactionId];
    }
  }

  public discardAllSubscriptions(): void {
    if (this.connectedMemberInteractionsOplog) {
      this.connectedMemberInteractionsOplog.unsubscribe();
      delete this.connectedMemberInteractionsOplog;
    }
    this.connectedInteractions = undefined;
    this.connectedInteractionSubscriptions = new Map();
    this.unsubscribeOplogs('contactsOplog');
    this.unsubscribeOplogs('unlinkedInteractionsOplog');
    this.unsubscribeOplogs('conversationListOplog');
    this.unsubscribeOplogs('conversationsOplog');
    this.unsubscribeOplogs('interactionsOplog');
  }

  private unsubscribeOplogs(variable: string): void {
    if (!variable) {
      return;
    }
    const subscriptionKeys = Object.keys(this[variable]);
    subscriptionKeys.forEach(key => {
      this[variable][key].unsubscribe();
      delete this[variable][key];
    });
  }
}
