import { computedFrom, observable } from 'aurelia-binding';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { DiallingRuleModel } from "./dialling-rule-model";
/*
 */
const logger = LogManager.getLogger('EditRulesDialog');
/*
 */
@autoinject()
export class EditRulesDialog {

  private CALL_OUTCOMES = ['Busy', 'No Answer', 'Voicemail', 'Failed', 'Overflow'];
  private diallingRules: DiallingRuleModel[] = [];
  @observable private maxDialAttempts: number = 0;
  public outcome: string;
  public requeueTime: number = 60;
  @observable public requeueTimes: number[] = [null].concat(Array.from(Array(10)).map(() => 60));
  @observable public maxAttempts: number = 1;

  public dialAttemptError: string;
  public error: string;

  constructor(private dialogController: DialogController) {}

  public activate(campaign: any): void {
    let diallingRules = JSON.parse(JSON.stringify(campaign.diallingRules));
    this.maxDialAttempts = campaign.maxDialAttempts || 10;

    if (campaign.dialerType === 'Progressive' || campaign.dialerType === 'Outbound IVR') {
      diallingRules = diallingRules.filter(outcome => outcome.outcome !== 'Overflow');
      this.CALL_OUTCOMES = this.CALL_OUTCOMES.filter(outcome => outcome !== 'Overflow');
    }
    if (diallingRules && diallingRules.length > 0) {
      this.diallingRules = diallingRules.map(rule => {
        let mappedRule = new DiallingRuleModel(rule.outcome, rule.requeueTime, rule.maxAttempts);
        if (rule.attempts && rule.attempts.length > 0) {
          mappedRule.attempts = rule.attempts;
        }
        return mappedRule;
      });
      this.diallingRules.forEach(rule => {
        this.CALL_OUTCOMES = this.CALL_OUTCOMES.filter(outcome => outcome !== rule.outcome);
      });
    }
  }

  public maxDialAttemptsChanged(newValue: string): void {
    if (parseInt(newValue) > 10 || parseInt(newValue) < 1) {
      this.dialAttemptError = 'Maximum dial attempts can only be from 1 to 10.';
    } else {
      this.dialAttemptError = null;
    }
  }

  public requeueTimeChanged(): void {
    this.validate();
  }

  public maxAttemptsChanged(): void {
    this.validate();
  }

  private validate(): boolean {
    this.error = null;

    if (!this.maxAttempts && this.maxAttempts !== 0) {
      this.error = 'Please enter a max attempts value.';
      return false;
    }

    this.requeueTimes.forEach(requeueTime => {
      requeueTime = typeof requeueTime === 'string' ? parseInt(requeueTime) : requeueTime;

      if (requeueTime === null ) {
        return true;
      }
      if (!requeueTime && requeueTime !== 0) {
        this.error = 'Please enter a requeue time value.';
        return;
      }
  
      if (requeueTime < 15 || requeueTime > 1440) {
        this.error = 'Requeue time can only be from 15 to 1440.';
      }
    });

    if (this.error) {
      return false;
    }


    let maxAttempts = typeof this.maxAttempts === 'string' ? parseInt(this.maxAttempts) : this.maxAttempts;

    if (maxAttempts < 1 || maxAttempts > 10) {
      this.error = 'Max attempts can only be from 1 to 10.';
      return false;
    }

    this.error = null;
    return true;
  }

  public async addDiallingRule(): Promise<void> {
    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const attempts = this.requeueTimes.filter(time => !!time).map((time: number, index: number) => {
      return {
        attempt: index,
        requeueDelay: time
      };
    });

    let diallingRule = new DiallingRuleModel(
      this.outcome,
      this.requeueTime,
      this.maxAttempts
    );
    diallingRule.attempts = attempts;
    
    this.diallingRules.push(diallingRule);
    this.CALL_OUTCOMES = this.CALL_OUTCOMES.filter(outcome => outcome !== diallingRule.outcome);

    setTimeout(() => {
      this.requeueTimes = [null].concat(Array.from(Array(10)).map(() => 60));
    }, 50);
  }

  public removeDiallingRule(diallingRule: DiallingRuleModel): void {
    this.diallingRules = this.diallingRules.filter(rule => rule.outcome !== diallingRule.outcome);
    this.CALL_OUTCOMES.push(diallingRule.outcome);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public submit(): void {
    this.dialogController.ok({
      rules: this.diallingRules,
      maxDialAttempts: this.maxDialAttempts
    });
  }

  @computedFrom('maxDialAttempts')
  public get parcedMaxAttempts(): number {
    if (this.maxDialAttempts) {

      let maxDialAttempts = typeof this.maxDialAttempts === 'string' ? parseInt(this.maxDialAttempts) : this.maxDialAttempts;

      if (maxDialAttempts > 10) {
        return 10;
      }
      return maxDialAttempts;
    }
  }
}

