import { LogManager } from "aurelia-framework";
import { autoinject } from "aurelia-dependency-injection";
// @ts-ignore
import { DialogController } from "aurelia-dialog";
import { PLATFORM } from "aurelia-pal";
import { v4 as uuidv4 } from 'uuid';

import { ArrayTools, FormatTools, SessionStore } from "zailab.common";
import { PresenceService } from "../../../user/passport/presence/presence-service";

import './verint-realtime-configuration.scss';
import { DEFAULT_DATA } from "./tabs/tab-2-activity-mapping/activity-mapping-default-values";
import organisationService from "../organisation-service";

const logger = LogManager.getLogger('VerintWFMRealTimeSTCIDialog');

@autoinject
export class VerintWFMRealTimeSTCIDialog {

  private workforceManagement: any;
  private workforceManagementId: string;
  private presences: { presenceCodeName: string; color: string; }[];
  private channels: string[] = ['Campaign Task', 'Voice', 'Email', 'SMS', 'Instant Message', 'Webchat', 'Ticket', 'N/A', 'Blended'];
  public loading;
  public changeTab: (index: number) => void;

  public tabs = [{
    id: '1',
    name: 'Connection Settings',
    // @ts-ignore
    viewModel: PLATFORM.moduleName('features/organisation/organisation/verint/tabs/tab-1-connection-settings/tab-1-connection-settings'),
    icon: 'services',
    selected: true,
    data: {}
  }, {
    id: '2',
    name: 'Activity Mapping',
    // @ts-ignore
    viewModel: PLATFORM.moduleName('features/organisation/organisation/verint/tabs/tab-2-activity-mapping/tab-2-activity-mapping'),
    icon: 'services',
    selected: false,
    data: {}
  }];
  public ready: boolean = false;

  constructor(
    private dialogController: DialogController,
    private presenceService: PresenceService,
    private organisationService: organisationService,
    private sessionStore: SessionStore
  ) {}
  
  public async activate(data): Promise<void> {
    await this.retrievePresences();

    this.workforceManagement = data;
    this.workforceManagementId = data.workforceManagementId;

    this.tabs[0].data = {
      verintRealtimeTimeMonitoringEnabled: !!data.verintRealtimeTimeMonitoringEnabled,
      verintRealtimeTimeMonitoringApiUrl: data.verintRealtimeTimeMonitoringApiUrl,
      verintRealtimeTimeMonitoringApiTenantId: data.verintRealtimeTimeMonitoringApiTenantId,
      verintRealtimeTimeMonitoringApiClientId: data.verintRealtimeTimeMonitoringApiClientId,
      verintRealtimeTimeMonitoringApiClientSecret: data.verintRealtimeTimeMonitoringApiClientSecret,
      verintRealtimeTimeMonitoringApiScope: data.verintRealtimeTimeMonitoringApiScope
    }
    this.tabs[1].data = {
      presences: this.presences,
      channels: this.channels,
      activityConfigurationMapping: data.activityConfigurationMapping ? ArrayTools.sortTextAndStringedNumbers(data.activityConfigurationMapping, 'eventReason') : [...DEFAULT_DATA]
    };
    this.ready = true;
  }
  
  private async retrievePresences(): Promise<void> {
    try {
      let presences: { presenceCodeName: string; color?: string; }[] = await this.presenceService.retrievePresences();
      presences.forEach(presence => presence.presenceCodeName = FormatTools.convertToNameCase(presence.presenceCodeName));
      this.presences = ArrayTools.sort([{ presenceCodeName: 'Working' }, { presenceCodeName: 'Offline' }, { presenceCodeName: 'N/A' }].concat(presences), 'presenceCodeName');
    } catch(error) {
      logger.warn(`Could not retrieve presences >>`, error);
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public async save(): Promise<void> {
    let tab1Data: any = this.tabs[0].data;
    let tab2Data: any = this.tabs[1].data;

    await this.checkForEmptyFields(tab2Data.activityConfigurationMapping);
    const hasValidationErrors = tab2Data.activityConfigurationMapping.some(item => item.fieldError);

    if (hasValidationErrors) {
      tab2Data.validationError = true;
      this.changeTab(1);
      return;
    }
    delete tab2Data.validationError;
    this.loading = true;

    const payload = {
      workforceManagementId: this.workforceManagementId || uuidv4(),
      verintRealtimeTimeMonitoringEnabled: tab1Data.verintRealtimeTimeMonitoringEnabled,
      verintRealtimeTimeMonitoringApiUrl: tab1Data.verintRealtimeTimeMonitoringApiUrl,
      verintRealtimeTimeMonitoringApiTenantId: tab1Data.verintRealtimeTimeMonitoringApiTenantId,
      verintRealtimeTimeMonitoringApiClientId: tab1Data.verintRealtimeTimeMonitoringApiClientId,
      verintRealtimeTimeMonitoringApiClientSecret: tab1Data.verintRealtimeTimeMonitoringApiClientSecret,
      verintRealtimeTimeMonitoringApiScope: tab1Data.verintRealtimeTimeMonitoringApiScope,
      activityConfigurationMapping: tab2Data.activityConfigurationMapping
    };

    this.workforceManagement.workforceManagementId = payload.workforceManagementId;
    this.workforceManagement.verintRealtimeTimeMonitoringEnabled = payload.verintRealtimeTimeMonitoringEnabled;
    this.workforceManagement.verintRealtimeTimeMonitoringApiUrl = payload.verintRealtimeTimeMonitoringApiUrl;
    this.workforceManagement.verintRealtimeTimeMonitoringApiTenantId = payload.verintRealtimeTimeMonitoringApiTenantId;
    this.workforceManagement.verintRealtimeTimeMonitoringApiClientId = payload.verintRealtimeTimeMonitoringApiClientId;
    this.workforceManagement.verintRealtimeTimeMonitoringApiClientSecret = payload.verintRealtimeTimeMonitoringApiClientSecret;
    this.workforceManagement.verintRealtimeTimeMonitoringApiScope = payload.verintRealtimeTimeMonitoringApiScope;
    this.workforceManagement.activityConfigurationMapping = payload.activityConfigurationMapping;
    
    this.organisationService.setWorkforceManagementRealtimeAdherence(this.sessionStore.get.organisation.organisationId, payload)
      .then(() => {
        this.loading = false;
        this.dialogController.ok(payload);
      })
      .catch(e => {
        this.loading = false;
        console.warn(' > Failed to update workforce management due to', e);
      });
  }

  private async checkForEmptyFields(items: { [key: string]: any }[]): Promise<void> {
    await Promise.all(items.map(async (item) => {
      const emptyFields = {};
  
      for (const key in item) {
        if (key === 'fieldError') {
          continue;
        }
        if (!item[key]) {
          emptyFields[key] = true;
        }
      }
  
      if (Object.keys(emptyFields).length > 0) {
        item.fieldError = emptyFields;
      } else {
        delete item.fieldError;
      }
    }));
  }
}
