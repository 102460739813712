/*
*/
import {LogManager} from 'aurelia-framework';
/*
*/
const logger = LogManager.getLogger('LoaderService');
/*
*/
let timeout = 0;
let timer;
export class LoaderService {

  show = false;

  constructor() {
    this.init();
  }

  get isLoading() {
    return this.show;
  }

  init() {
    window.viewResources.registerViewEngineHooks({
      beforeBind: () => {
        this.hideLoader();
      }
    });

  }

  showLoader() {

    if(timer) {
      resetTimer();
    }

    timer = setInterval(()=> {

      if(timeout === 10) {
        this.hideLoader();
        return;
      }

      timeout++;
    }, 1000);

    this.show = true;
  }

  hideLoader() {

    resetTimer();
    this.show = false;
  }

}
/*
*/
function resetTimer() {
  clearInterval(timer);
  timeout = 0;
}