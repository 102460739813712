import {LogManager, customElement, bindable} from 'aurelia-framework';
/**/
import {LineModel} from './models/line-model';
/**/
const logger = LogManager.getLogger('ZLine');
/**/
@customElement('z-line')
export class ZLine {

    @bindable id: string = null;
    @bindable stroke: string = 'red';
    @bindable({attribute: 'dash-length'}) dashLength: string = '0';
    @bindable coordinates: LineModel;
    @bindable selected: boolean;
    @bindable version: any;

    public selectLine(): void {
      if (this.version) {
        return;
      }
      this.selected = !this.selected;
    }

    public doNothing(): void {}
}
