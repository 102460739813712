import { LogManager, autoinject, computedFrom } from 'aurelia-framework';
import { Search } from 'components/molecules/dropdowns/z-dropdown-search';
import { VerintAnalyticsService } from '../verint-analytics-service';
import { TextIngestionField } from '../verint-analytics-model';
import { DialogController } from 'aurelia-dialog';
import { SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('VerintTextAnalyticsFieldMappings');

@autoinject
export class VerintTextAnalyticsFieldMappings {
  private workforceManagementId: string;
  public searchMappingWorkType: Search<any> = new Search();
  public searchCopyFromWorkType: Search<any> = new Search();
  public standardFields: TextIngestionField[]
  public fieldNamesStandard: string[];
  public fieldNamesOptional: string[];
  public fieldNamesUpdated = ['textStoreId', 'textProjectId'];
  public fieldNamesCustom: string[];
  public dataSources: string[];
  public fieldValuesForInteractionData: string[];
  public workTypeFieldMappings: {
    standardFields: TextIngestionField[],
    optionalFields: TextIngestionField[],
    customFields: TextIngestionField[],
  };
  public copyFromFieldMappings: {
    standardFields: TextIngestionField[],
    optionalFields: TextIngestionField[],
    customFields: TextIngestionField[],
  };
  public customFieldMapping = new TextIngestionField('', '', '');
  public customFieldMappingValid = true;
  public customFieldMappingError = '';
  public isProcessing = true;

  private selectedWorkTypeId = ''; 

  constructor(
    private dialogController: DialogController,
    private verintAnalyticsService: VerintAnalyticsService,
    private sessionStore: SessionStore
  ) { }

  public activate(model: { data: { workforceManagementId: string }}): void {
    this.workforceManagementId = model.data.workforceManagementId;
    this.isProcessing = true;
    this.searchMappingWorkType.displayParam = 'name';
    this.searchMappingWorkType.onchange = (data: any) => this.selectWorkType(data);
    this.searchCopyFromWorkType.displayParam = 'name';
    this.searchCopyFromWorkType.onchange = (data: any) => this.selectCopyFrom(data);
    Promise.all([
      this.verintAnalyticsService.findStandardFields().then((data) => {
        this.standardFields = data.map((item) => {
          let field = new TextIngestionField(item.fieldName, item.fieldSource, item.fieldValue);
          if (!field.fieldValue) {
            field.noLock = true;
          } else {
            field.lock(field);
          }
          return field;
        });
      }),
      this.verintAnalyticsService.findStandardFieldNames().then((data) => {
        this.fieldNamesStandard = data;
      }),
      this.verintAnalyticsService.findCustomFieldNames().then((data) => {
        this.fieldNamesOptional = data;
      }),
      this.verintAnalyticsService.findDataSources().then((data) => {
        this.dataSources = [''].concat(data);
      }),
      this.verintAnalyticsService.findFieldValuesForInteractionData().then((data) => {
        this.fieldValuesForInteractionData = [''].concat(data);
      }),
      this.verintAnalyticsService.findEnabledWorkTypes().then((data) => {
        this.searchMappingWorkType.options = data;
      }),
      this.verintAnalyticsService.findMappedWorkTypes().then((data) => {
        this.searchCopyFromWorkType.options = data;
      }),
    ]).then(() => this.isProcessing = false);
  }

  public save(): void {
    this.customFieldMappingError = '';
    this.isProcessing = true;
    let optionalFields = this.workTypeFieldMappings.optionalFields.filter((field) => field.enabled);
    let fieldMappings = {
      standardFields: this.workTypeFieldMappings.standardFields.concat(optionalFields),
      customFields: this.workTypeFieldMappings.customFields
    };
    this.verintAnalyticsService.setFieldMappings(this.sessionStore.get.organisation.organisationId, this.workforceManagementId, this.selectedWorkTypeId, fieldMappings).then(() => {
      const existing = this.searchCopyFromWorkType.options.filter((item) => this.selectedWorkTypeId === item.id);
      if (!existing || !existing.length) {
        this.searchCopyFromWorkType.options.push({id: this.selectedWorkTypeId, name: this.searchMappingWorkType.value});
      }
      this.searchMappingWorkType.value = '';
      this.searchCopyFromWorkType.value = '';
      this.workTypeFieldMappings = null;
      this.copyFromFieldMappings = null;
      this.customFieldMapping.clear();
      this.isProcessing = false;
    });
  }

  public addCustomFieldMapping(): void {
    if (!this.customFieldMapping.fieldName || !this.customFieldMapping.fieldSource || !this.customFieldMapping.fieldValue) {
      this.customFieldMappingError = 'Field name, data source and value are required';
      return;
    }
    if (!/^[a-zA-Z0-9_\-]+$/.test(this.customFieldMapping.fieldName)) {
      this.customFieldMappingError = 'Field name must only contain valid characters';
      return;
    }
    let duplicates = this.workTypeFieldMappings.standardFields.filter((item) => item.fieldName === this.customFieldMapping.fieldName);
    if (!duplicates || !duplicates.length) {
      duplicates = this.workTypeFieldMappings.optionalFields.filter((item) => item.fieldName === this.customFieldMapping.fieldName);
    }
    if (!duplicates || !duplicates.length) {
      duplicates = this.workTypeFieldMappings.customFields.filter((item) => item.fieldName === this.customFieldMapping.fieldName);
    }
    if (duplicates && duplicates.length > 0) {
      this.customFieldMappingError = 'Field name must be unique';
      return;
    }
    this.customFieldMappingError = '';
    this.workTypeFieldMappings.customFields.push(Object.assign({}, this.customFieldMapping));
    this.customFieldMapping.clear();
  }

  public removeCustomFieldMapping(customField: TextIngestionField): void {
    this.workTypeFieldMappings.customFields = this.workTypeFieldMappings.customFields.filter((existingCustomField) => existingCustomField !== customField);
  }

  public clearStandardFieldMapping(customField: TextIngestionField): void {
    customField.fieldSource = '';
    customField.fieldValue = '';
  }

  public copyFieldMappingStandard(copyFromField: TextIngestionField): void {
    const fieldMappings = this.workTypeFieldMappings.standardFields.filter((field) => field.fieldName === copyFromField.fieldName);
    if (!fieldMappings || !fieldMappings.length) {
      return;
    }
    const fieldMapping = fieldMappings[0];
    fieldMapping.lock(copyFromField);
    fieldMapping.fieldSource = copyFromField.fieldSource;
    fieldMapping.fieldValue = copyFromField.fieldValue;
    fieldMapping.noLock = false;
  }

  public copyFieldMappingOptional(copyFromField: TextIngestionField): void {
    const fieldMappings = this.workTypeFieldMappings.optionalFields.filter((field) => field.fieldName === copyFromField.fieldName);
    if (!fieldMappings || !fieldMappings.length) {
      return;
    }
    const fieldMapping = fieldMappings[0];
    fieldMapping.fieldSource = copyFromField.fieldSource;
    fieldMapping.fieldValue = copyFromField.fieldValue;
    fieldMapping.enabled = copyFromField.enabled;
  }

  public copyFieldMappingCustom(copyFromField: TextIngestionField): void {
    const fieldMappings = this.workTypeFieldMappings.customFields.filter((field) => field.fieldName === copyFromField.fieldName);
    if (!fieldMappings || !fieldMappings.length) {
      this.workTypeFieldMappings.customFields.push(new TextIngestionField(copyFromField.fieldName, copyFromField.fieldSource, copyFromField.fieldValue));
      return;
    }
    const fieldMapping = fieldMappings[0];
    fieldMapping.fieldSource = copyFromField.fieldSource;
    fieldMapping.fieldValue = copyFromField.fieldValue;
  }

  public copyAll(): void {
    this.copyFromFieldMappings.standardFields.forEach((field) => this.copyFieldMappingStandard(field));
    this.copyFromFieldMappings.optionalFields.forEach((field) => this.copyFieldMappingOptional(field));
    this.copyFromFieldMappings.customFields.forEach((field) => this.copyFieldMappingCustom(field));
  }

  public clear(): void {
    this.workTypeFieldMappings.standardFields.forEach((field) => this.clearStandardFieldMapping(field));
    this.workTypeFieldMappings.optionalFields.forEach((field) => field.disable());
    this.workTypeFieldMappings.customFields = [];
  }

  public close(): void {
    this.dialogController.cancel();
  }

  @computedFrom('searchMappingWorkType.value')
  public get isInvalidSelection(): boolean {
    return !this.searchMappingWorkType.value;
  }

  @computedFrom('searchMappingWorkType.value', 'searchCopyFromWorkType.value')
  public get isValidForCopy(): boolean {
    return !!this.searchMappingWorkType.value && !!this.searchCopyFromWorkType.value && this.searchMappingWorkType.value !== this.searchCopyFromWorkType.value;
  }

  public get isInvalidFields(): boolean {
    if (!this.workTypeFieldMappings || !this.workTypeFieldMappings.standardFields || !this.workTypeFieldMappings.standardFields.length) {
      return true;
    }
    let empties = this.workTypeFieldMappings.standardFields.filter((item) => !item.fieldSource || !item.fieldValue);
    if (empties && empties.length > 0) {
      return true;
    }
    empties = this.workTypeFieldMappings.optionalFields.filter((item) => item.enabled && (!item.fieldSource || !item.fieldValue));
    if (empties && empties.length > 0) {
      return true;
    }
    empties = this.workTypeFieldMappings.customFields.filter((item) => !item.fieldSource || !item.fieldValue);
    if (empties && empties.length > 0) {
      return true;
    }
    return false;
  }

  private selectWorkType(data: any): void {
    this.customFieldMapping.clear();
    this.selectedWorkTypeId = data.id;
    this.verintAnalyticsService.findFieldMappings(data.id).then((data) => {
      this.workTypeFieldMappings = {
        standardFields: this.computeStandardFields(data ? data.standardFields.filter((field) => this.isFieldStandard(field)) : []),
        optionalFields: this.computeOptionalFields(data ? data.standardFields.filter((field) => this.isFieldOptional(field)) : []),
        customFields: this.computeCustomFields(data ? data.customFields : [], data ? data.standardFields.filter((field) => this.isFieldUpdated(field)) : []),
      };
    });
  }

  private selectCopyFrom(data: any): void {
    this.verintAnalyticsService.findFieldMappings(data.id).then((data) => {
      this.copyFromFieldMappings = {
        standardFields: this.computeStandardFieldsCopyFrom(data ? data.standardFields.filter((field) => this.isFieldStandard(field)) : []),
        optionalFields: this.computeOptionalFields(data ? data.standardFields.filter((field) => this.isFieldOptional(field)) : []),
        customFields: this.computeCustomFields(data ? data.customFields : [], data ? data.standardFields.filter((field) => this.isFieldUpdated(field)) : []),
      };
    });
  }

  private computeStandardFieldsCopyFrom(existingFields: TextIngestionField[]): TextIngestionField[] {
    if (!existingFields || !existingFields.length) {
      return this.fieldNamesStandard.map((fieldName) => new TextIngestionField(fieldName, '', ''));
    }
    return existingFields.map((field) => new TextIngestionField(field.fieldName, field.fieldSource, field.fieldValue).lock());
  }

  private computeStandardFields(existingFields: TextIngestionField[]): TextIngestionField[] {
    return this.standardFields.map((standardField) => {
      const filteredFields = existingFields.filter((field) => field.fieldName === standardField.fieldName);
      if (!filteredFields || !filteredFields.length) {
        return standardField.clone();
      }
      const field = filteredFields[0];
      return new TextIngestionField(field.fieldName, field.fieldSource, field.fieldValue).lock();
    });
  }

  private computeOptionalFields(existingFields: TextIngestionField[]): TextIngestionField[] {
    return this.fieldNamesOptional.map((fieldName) => {
      const filteredFields = existingFields.filter((field) => field.fieldName === fieldName);
      if (!filteredFields || !filteredFields.length) {
        return new TextIngestionField(fieldName, '', '').disable();
      }
      return new TextIngestionField(fieldName, filteredFields[0].fieldSource, filteredFields[0].fieldValue);
    });
  }

  private computeCustomFields(existingFields: TextIngestionField[], updatedFields: TextIngestionField[]): TextIngestionField[] {
    return updatedFields.concat(existingFields);
  }

  private isFieldStandard(field: TextIngestionField): boolean {
    return this.fieldNamesStandard.includes(field.fieldName);
  }

  private isFieldOptional(field: TextIngestionField): boolean {
    return this.fieldNamesOptional.includes(field.fieldName);
  }

  private isFieldUpdated(field: TextIngestionField): boolean {
    return this.fieldNamesUpdated.includes(field.fieldName);
  }
}
