import { inject, LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore, SESSION_EVENTS } from 'zailab.common';
import OrganisationService from '../organisation-service';

const logger = LogManager.getLogger('OrganisationWidget');

@inject(EventAggregator, OrganisationService, SessionStore)
export class OrganisationWidget {
  organisationName;
  updateSubscription;

  constructor(eventAggregator, organisationService, sessionStore) {
    this.eventAggregator = eventAggregator;
    this.organisationService = organisationService;
    this.sessionStore = sessionStore;
  }

  activate() {
    this.subscribeToOplogChanges();
    this.retrieveOrganisationInfo();
  }

  subscribeToOplogChanges() {
    if (this.sessionStore.get.organisation && this.sessionStore.get.organisation.organisationId) {
      const organisationId = this.sessionStore.get.organisation.organisationId;

      this.organisationService.initialiseOrganisationInformationOplog(organisationId).then(oplog => {
        this.oplog = oplog;

        this.oplog.on('update', response => {
          this.organisationName = response.organisationName;
        });
      });
    } else if (!this.updateSubscription) {
      this.updateSubscription = this.eventAggregator.subscribe(SESSION_EVENTS.UPDATED.ORGANISATION, () => {
        this.updateSubscription.dispose();
        this.subscribeToOplogChanges();
        this.retrieveOrganisationInfo();
      });
    }
  }

  retrieveOrganisationInfo() {
    if (this.sessionStore.get.organisation && this.sessionStore.get.organisation.organisationId) {
      const organisationId = this.sessionStore.get.organisation.organisationId;

      this.organisationService.retrieveOrganisationInfo(organisationId).then(
        response => {
          this.organisationName = response.organisationName;
        },
        error => {
          logger.warn('Could not retrieve Organisation information.', error);
        }
      );
    }
  }

  deactivate() {
    if (this.oplog) {
      this.oplog.unsubscribe();
    }
  }
}
