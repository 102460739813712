export class SearchableVariableModel {

  public name: string;
  public deleted: boolean;

  constructor(searchableVariable: any) {
    this.name = searchableVariable.name;
    this.deleted = searchableVariable.deleted;
  }
}
