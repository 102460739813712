import { inject, LogManager, computedFrom } from 'aurelia-framework';
/*
 * */
import { BaseModel } from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('ActivityInteractionModel');
const ICONS_MAP = {
  'email': 'envelope-closed',
  'sms': 'comments',
  'chat': 'comments-square',
  'instant message': 'comments-square',
  'call': 'call',
  'outbound_call': 'call-outbound',
  'inbound_call': 'call-inbound',
  'ticket': 'channel-ticket'
};
/*
 * */
export class ActivityInteractionModel extends BaseModel {

  contactId: string = null;
  channel: string = null;
  direction: string = null;
  from: string = null;
  to: string = null;
  tos: Array<string> = null;
  id: string = null;
  interactionId: string = null;
  src: string = null;
  timestamp: string = null;
  resumeLinking: boolean = null;

  constructor(response: object) {
    super();
    this.mapProperties(response);
  }

  get interactionType(): string {

    if (this.channel === 'VOICE') {
      this.channel = 'call';
    }
    if (this.channel.toLowerCase() === 'instant_message') {
      this.channel = 'instant message';
    }
    return this.direction.toLowerCase() + ' ' + this.channel.toLowerCase();
  }

  @computedFrom('channel', 'direction')
  get interactionIcon(): string {

    let _channel = this.channel.toLowerCase();
    let _direction = this.direction.toLowerCase();
    let _callChannel = `${_direction}_${_channel}`;

    return ICONS_MAP[_callChannel] || ICONS_MAP[_channel];

  }

}
