import {autoinject, LogManager, customElement, bindable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

import {ConversationService} from '../conversation-service';
import {WaypointModel} from "../waypoint-model";
import { observable } from 'aurelia-binding';

const logger = LogManager.getLogger('InteractionJourney');

@customElement('z-interaction-journey')
@autoinject()
export class InteractionJourney {
  @bindable @observable waypoints: WaypointModel[] = [];

  public items: any[];

  public waypointsChanged(newValue: any[] | { waypoints: any[] }): void {
    let data = Array.isArray(newValue) ? newValue : newValue && newValue.waypoints ? newValue.waypoints : null;
    if (data) {
      this.items = data.map(waypoint => {
        return {
          label: waypoint.label,
          color: waypoint.color,
          duration: waypoint.duration,
          twentyFourHourFormat: waypoint.twentyFourHourFormat,
          waitTime: waypoint.waitTime,
        }
      });
    }
  }
}
