import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

// import { SessionStore } from 'zailab.common';
import { SearchableVariablesService } from '../searchable-variables-service';
import { SearchableVariableModel } from '../searchable-variable-model';

//@ts-ignore
import $ from 'jquery';

import { SearchableVariablesListModel } from './searchable-variables-list-model';
import { SearchableVariablesListRules } from './searchable-variables-list-rules';

const logger = LogManager.getLogger('SearchableVariablesList');

@autoinject
export class SearchableVariablesList {

  public searchableVariablesList: SearchableVariablesListModel = new SearchableVariablesListModel();

  constructor(
    private router: Router,
    private searchableVariablesService: SearchableVariablesService,
    private rules: SearchableVariablesListRules,
  ) { }

  public async attached(): Promise<void> {

    this.rules.init(this.searchableVariablesList);

    this.retrieveData();
  }

  public enableAddState(): void {
    this.searchableVariablesList.addState = true;
    this.focus('#js-searchableVariableName');
  }

  private async retrieveData(): Promise<any> {
    try {
      this.searchableVariablesList.searchableVariables = await this.searchableVariablesService.retrieveSearchableVariables();
    } catch (e) {
      logger.error('retrieveSearchableVariables :: e=', e);
    } finally {
      this.searchableVariablesList.ready = true;
    }
  }

  private focus(elementId: string): void {
    let element: HTMLElement = document.querySelector(elementId);
    if (!element) {
      setTimeout(() => this.focus(elementId), 250);
      return;
    }
    element.focus();
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  public addSearchableVariable(): void {
    const searchableVariable: SearchableVariableModel = {
      name: this.searchableVariablesList.name,
      deleted: false
    };
    this.searchableVariablesList.searchableVariables.push(searchableVariable);
    this.searchableVariablesList.disableAddState();
    let variables = this.searchableVariablesList.searchableVariables.map(variable => {return variable.name});
    this.searchableVariablesService.setSearchableVariables(variables);
    this.smoothScrollToBottom();
  }

  public deleteVariable(searchableVariable: SearchableVariableModel): void {
    if (searchableVariable) {
      this.removeSearchableVariableFromList(searchableVariable);
      let variables = this.searchableVariablesList.searchableVariables.map(variable => {return variable.name});
      this.searchableVariablesService.setSearchableVariables(variables);
    }
  }

  private removeSearchableVariableFromList(item: SearchableVariableModel): void {
    this.searchableVariablesList.searchableVariables = this.searchableVariablesList.searchableVariables.filter(variable => {
      return variable.name !== item.name;
    })
  }

  public cancel(): void {
    this.searchableVariablesList.disableAddState();
  }

  public navigateBack(): void {
    this.router.navigate('');
  }

  public deactivate(): void {
    this.rules.close();
  }
}