import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Oplog, OplogSubscription } from '../../../_common/services/database-service';
import { SessionStore } from '../../../_common/stores/session-store';
import { Alert, AlertsResponse, AlertResponse, AlertsMessagesResponse, AlertMessage } from './alert-model';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ApplicationProperties } from '../../../_config/application.properties';

const logger = LogManager.getLogger('AlertService');

@autoinject()
export class AlertService {

  private readonly baseUrl = 'v1/organisation/alerts';
  private oplogSubscription: OplogSubscription;

  constructor(
    private http: HttpClient,
    private events: EventAggregator,
    private oplog: Oplog,
    private sessionStore: SessionStore,
    private applicationProperties: ApplicationProperties
  ) { }

  public findAll(): Promise<Alert[]> {
    return this.http.createRequest(`${this.baseUrl}`)
      .asGet()
      .send()
      .then((response: AlertsResponse) => {
        if (!response) {
          return null;
        }
        if (response.alerts) {
          return response.alerts.map((data: Alert) => {
            const alert = new Alert();
            alert.active = data.active;
            alert.alertId = data.alertId;
            alert.audience = data.audience;
            alert.audienceType = data.audienceType;
            alert.message = data.message;
            alert.name = data.name;
            alert.organisationId = data.organisationId;
            alert.priority = data.priority;
            alert.trigger = data.trigger;
            return alert;
          });
        }
        return [];
      });
  }

  public delete(id: any): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asDelete()
      .send();
  }

  public async insert(data: Alert): Promise<any> {

    data.organisationId = this.sessionStore.get.organisation.organisationId;
    const id = uuidv4();

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('member-projector.alertView')
      .withKeyField('_id')
      .withKeyValue(id);

    this.http.createRequest(`${this.baseUrl}/${id}`)
      .asPost()
      .withContent(data)
      .send();

    return oplogSubscription.when('insert').then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  public activate(id: any): Promise<any> {

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('member-projector.alertView')
      .withKeyField('_id')
      .withKeyValue(id);

    this.http.createRequest(`${this.baseUrl}/${id}/activate`)
      .asPut()
      .send();

    return oplogSubscription.when('update').then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  public deactivate(id: any): Promise<any> {

    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('member-projector.alertView')
      .withKeyField('_id')
      .withKeyValue(id);

    this.http.createRequest(`${this.baseUrl}/${id}/deactivate`)
      .asPut()
      .send();

    return oplogSubscription.when('update').then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  public send(id: any): Promise<any> {

    return this.http.createRequest(`${this.baseUrl}/${id}/send`)
      .asPut()
      .send();
  }

  public onAlertMessage(callback: (data: any) => void): void {

    const memberId: string = this.sessionStore.get.user.memberId;

    if (!this.oplogSubscription) {

      this.oplogSubscription = this.oplog.subscribe()
        .withNameSpace('member-projector.alertMemberView')
        .withKeyField('_id')
        .withKeyValue(memberId);

      this.oplogSubscription.on('insert', (data) => this.events.publish('onAlertMessage', data));
      this.oplogSubscription.on('update', (data) => this.events.publish('onAlertMessage', data));
    }

    this.events.subscribe('onAlertMessage', callback);
  }

  public offAlertMessage(): void {

    this.oplogSubscription.unsubscribe();
  }

  public findAllMessages(): Promise<AlertMessage[]> {
    const memberId: string = this.sessionStore.get.user.memberId;
    return this.http.createRequest(`${this.baseUrl}/messages/${memberId}`)
      .asGet()
      .send()
      .then((response: AlertsMessagesResponse) => {
        if (!response) {
          return null;
        }
        if (response.messages) {

          const results = response.messages.map((data: AlertMessage) => {

            var utc = moment.tz(`${data.date} ${data.time}`, data.timezone).format();
            var local = moment(moment.utc(utc).toDate());
            var date = local.format('YYYY-MM-DD');
            var time = local.format('HH:mm:ss');

            const message = new AlertMessage();
            message.alertId = data.alertId;
            message.message = data.message;
            message.name = data.name;
            message.priority = data.priority;
            message.date = date;
            message.time = time;
            message.timezone = data.timezone;
            return message;
          });

          return results.reverse();
        }
        return [];
      });
  }

  public findAllMessagesForToday(): Promise<AlertMessage[]> {
    const today = moment().format('YYYY-MM-DD');
    return this.findAllMessages()
      .then((messages: AlertMessage[]) => {
        return messages && messages.filter ? messages.filter((data: AlertMessage) => {
          return data.date === today;
        }) : [];
      });
  }
}
