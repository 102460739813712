import { inject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';
/**/
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
import { TableInputModel } from "../../../../../../../../components/custom/table-input";
import { Event } from '../../../../../../../../zailab.common';
/**/
const logger = LogManager.getLogger('Response');
/**/
@inject(Element)
export class Response {

  private outputVariables: any;
  private tableOutputVariables: TableInputModel;
  private responseType: string = 'json';
  private wizard: ZIWizard;

  constructor(private element: Element) {}

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.initTableInputVariables()
    this.validate();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.populateVariablesFromModel();
      this.updateWizardData();
      this.nextStep();
    });
  }

  private updateWizardData(): void {
    this.wizard.data.outputVariables = this.outputVariables;
    this.wizard.data.responseType = this.responseType;
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  private extractWizardData(): void {
    this.outputVariables = this.wizard.data.outputVariables;
    if(this.wizard.data.responseType){
      this.responseType = this.wizard.data.responseType;
    }
  }

  private validate(): void {
  }

  public populateVariablesFromModel(): void {
    this.outputVariables = [];
    this.tableOutputVariables.tables[0].rows.forEach((row) => {
      const header = {};
      header[row.cells[0]] = row.cells[1];
      this.outputVariables.push(header);
    });
  }

  private initTableInputVariables(): void {
    if (this.outputVariables && this.outputVariables instanceof TableInputModel) {
      this.tableOutputVariables = this.outputVariables;
      return;
    }
    const builder = TableInputModel.builder
      .setHeaders('Variable Name', 'Variable JSON Mapping')
      .hideHeaders();
    if (this.outputVariables) {
      for (const variable of this.outputVariables) {
        for (const prop in variable) {
          builder.addRow(prop, variable[prop]);
        }
      }
    }
    this.tableOutputVariables = new TableInputModel([builder.build()]);
  }

  public triggerResponseTypeChanged(): void {
    new Event(this.element, 'apicallresponsetypechanged', this.responseType);
  }

  public triggerTableOutputVariablesChanged(): void {
    new Event(this.element, 'apicalltableoutputvariableschanged', this.tableOutputVariables);
  }
}
