/**
 * Draw IO :: Feature model :: Update when making feature changes
 * https://www.draw.io/#G15PPElZ3HONpiWPLoQP-F0gIJrxCB-F39
 */
import {LogManager, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { SMSGatewayService } from './../sms-gateway-service';
import { PlaceholderService } from './../../../../../_common/services/placeholder-service';
import { MESSAGE_EVENTS, SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SMSGatewayConfigDialog } from '../add-dialog/add-sms-gateway-dialog';

const logger = LogManager.getLogger('SMSGatewayList');

@autoinject
export class SMSGatewayList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders: number = 0;
  public container: any;
  public actions = [{
    action: 'addaccounts',
    label: 'Add Accounts',
    icon: 'add'
  }, {
    action: 'edit',
    label: 'Edit',
    icon: 'edit'
  }];

  public itemList: any;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private SMSGatewayService: SMSGatewayService,
    private sessionStore: SessionStore,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.retrieveSMSGateways();
  }

  private async retrieveSMSGateways(): Promise<void> {
    try {
      let gateways = await this.SMSGatewayService.retrieveSMSGateways(this.sessionStore.get.organisation.organisationId);
      gateways = gateways.map(gateway => {
        gateway.actions = this.actions;
        return gateway;
      });

      this.itemList = List.Builder() //
        .required(true) //
        .customEventElement(this.element)
        .enableClick()
        .enableAdd()
        .enableDelete()
        .selectionType(List.MULTIPLE_SELECTION_TYPE) //
        .autoSelect(false)
        .items(gateways) //
        .uniqueIdentifier('id') //
        .hasRollover(false)
        .tilesPerRow(5)
        .disableOrderBy()
        .build();

      this.generatePlaceholders();
    } catch(e) {
      logger.warn('Failed to retrieve sms gateways due to', e);
    }
  }

  public select(item: any): void {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.itemList.select && !this.itemList.isDeleting) {
      this.selectGateway(item);
      return;
    }
    if (this.itemList.isDeleting && item.canDelete !== false) {
      this.itemList.selectToDelete(item);
    } else {
      logger.warn('Cannot select item >>>', item);
    }
  }

  public deleteItems(): void {
    const itemsToDelete: ISMSGateway[] = [];
    let remainingItems: ISMSGateway[] = [];

    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      } else {
        remainingItems.push(item);
      }
    }
    super.deleteItems(itemsToDelete);
    this.itemList.items = remainingItems;
    this.itemList.isDeleting = false;
    this.hideLoader();
    this.generatePlaceholders();

    if (itemsToDelete.length === 1) {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `Gateway ${itemsToDelete[0].name} deleted.`);
    } else if (itemsToDelete.length > 1) {
      this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, `${itemsToDelete} Gateways deleted.`);
    }
  }
  
  protected delete(smsGateway: any): void {
    this.SMSGatewayService.deleteGateway(this.sessionStore.get.organisation.organisationId, smsGateway.id);
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(this.container, this.itemList.items.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  public selectGateway(gateway: ISMSGateway): void {
    this.router.navigate(gateway.id);
  }

  public openGatewayConfigDialog(gateway?: ISMSGateway): void {
    this.dialogService
      .open({
          viewModel: SMSGatewayConfigDialog,
          model: {
            gatewayNames: this.itemList.items.map(gw => { return gw.name; }),
            gateway
          }
        })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          let _gateway: ISMSGateway = response.output;
          if (gateway) {
            let gatewayToUpdate = this.itemList.items.find((gw: ISMSGateway) => {
              if (gw.id === _gateway.id) {
                return true;
              }
            });
            if (gatewayToUpdate) {
              gatewayToUpdate.name = _gateway.name;
              gatewayToUpdate.host = _gateway.host;
              gatewayToUpdate.port = _gateway.port;
            }
          } else {
            this.itemList.items.push({
              ..._gateway,
              actions: this.actions
            });
          }
        }
      });
  }

  public addAccount(gateway: ISMSGateway): void {
    this.router.navigate(gateway.id + '?add-account=true');
  }
}