import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
import {GridCellDragModel} from './grid-cell-drag-model';
/**/
const logger = LogManager.getLogger('GridCellModel');
/**/
export class GridCellModel extends GridCellDragModel {

  public id: string = null;
  public x: number = null;
  public y: number = null;
  public rowsToMerge: number = null;
  public cellTemplate: string = null;
  public version: any;

  constructor(cell: object) {

    super(cell);
    super.mapProperties(cell);
  }
}
