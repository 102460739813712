import {LogManager} from 'aurelia-framework';
import {ReportDownloader} from "./report-downloader";
import {inject} from "aurelia-dependency-injection";
import {ReportsService} from "./reports.service";

const logger = LogManager.getLogger('ReportBuilder');

@inject(ReportsService)
export class ReportBuilder {
  private url: string = '';
  private fileName: string = '';
  private reportDownloader: ReportDownloader;

  constructor(private reportsService: ReportsService) {
    this.reportDownloader = new ReportDownloader(this.reportsService);
  }


  public withUrl(url: string): ReportBuilder {
    this.reportDownloader.url = url;
    return this;
  }

  public withFileName(name: string): ReportBuilder {
    this.reportDownloader.fileName = name;
    return this;
  }

  public withParams(key: string, value: string): ReportBuilder {
    this.reportDownloader.addQueryParam(key,value);
    return this;
  }

  public removeParams(key: string): ReportBuilder {
    this.reportDownloader.removeQueryParam(key);
    return this;
  }

  public withFileType(type: string): ReportBuilder {
    this.reportDownloader.fileType = type;
    return this;
  }


  public build(): ReportDownloader {
    return this.reportDownloader;
  }
}
