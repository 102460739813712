export class Money {

  public amount: number;
  public currency: string;

  constructor(amount: string, currency: string) {
    this.amount = Number.parseFloat(amount);
    this.currency = currency;
  }
}
