import { AureliaConfiguration } from 'aurelia-configuration';
import { autoinject } from 'aurelia-dependency-injection';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { LogManager } from 'aurelia-framework';

import { ApplicationProperties } from 'zailab.common';

const logger = LogManager.getLogger('SnippetDialog');

@autoinject()
export class SnippetDialog {
  public placeholder = `
    <script
    \tsrc="{{widgetEndpoint}}"
    \twidget="{{widgetId}}"
    \ttoken="{{token}}"
    {{environment}}
    ></script>
  `;

  constructor(
    private applicationProperties: ApplicationProperties,
    private dialogController: DialogController,
    private aureliaConfiguration: AureliaConfiguration
  ) {}

  public activate(data: {
    webchatConfiguration: { webChatWidgetId: string; apiToken: string };
  }): void {
    this.placeholder = this.placeholder.replace(
      '{{environment}}',
      `${
        this.aureliaConfiguration.is('local') ||
        this.aureliaConfiguration.is('dev1')
          ? '\tdev="true"'
          : ''
      }`
    );
    this.placeholder = this.placeholder.replace(
      '{{widgetEndpoint}}',
      this.applicationProperties.widgetEndpoint
    );
    this.placeholder = this.placeholder.replace(
      '{{widgetId}}',
      data.webchatConfiguration.webChatWidgetId
    );
    this.placeholder = this.placeholder.replace(
      '{{token}}',
      data.webchatConfiguration.apiToken
    );
  }

  public close(): void {
    this.dialogController.cancel();
  }

  public copy(): void {
    const textarea: any = document.getElementById('codeSnippet');
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }
}
