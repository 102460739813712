import { HttpResponseMessage } from "aurelia-http-client";
import {BusinessPartner} from "../businesspartner/business-partner-model";

export class ContactCenter {

  public id: string;
  public contactCentreId: string;
  public name: string;
  public businessPartnerId: string;
  public businessPartnerName: string;
  public icon?: string = 'team-leader';
  public canDelete?: boolean = true;
}

export class ContactCenterResponse extends HttpResponseMessage {
  public id: string;
  public name: string;
  public businessPartner: BusinessPartner;
}