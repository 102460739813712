import {autoinject, LogManager, PLATFORM} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
/**/
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';

const logger = LogManager.getLogger('MenuOptionsDialog');

@autoinject
export class MenuOptionsDialog {

  private nodeData: NodeModel;
  private steps: object[];
  private existingConnections: ConnectorModel[];
  private isAddingContent: boolean = false;
  private nodeConfiguration: ZNodeConfig;

  constructor(private dialogController: DialogController, private eventAggregator: EventAggregator) {
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.existingConnections = this.nodeData.connections;
    this.initSteps();
  }

  private initSteps(): void {
    const version = this.nodeData.version;
    const data = {
      ...this.nodeData.properties,
      version
    };

    this.steps = [{
      name: 'Name',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/menu-options/steps/name/name'),
      active: true,
      hasAttributes: true,
      completed: false,
      data
    }, {
      name: 'Message',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/menu-options/steps/message/message'),
      hasAttributes: true,
      completed: false,
      data
    }, {
      name: 'Keypad Options',
      description: '',
      view: PLATFORM.moduleName('features/interaction/interactionFlow/interaction-designer/dialogs/menu-options/steps/keypad/keypad'),
      hasAttributes: true,
      completed: false,
      data
    }];
  }

  private activateContentCreator(): void {
    this.isAddingContent = true;
  }

  private cancelContentCreator(): void {
    this.isAddingContent = false;
  }

  private complete(wizardData: any): void {
    let properties: object = wizardData.detail;
    let updatedNode: NodeModel = this.mapNodeProperties(properties);
    updatedNode.config = null;
    this.dialogController.ok(updatedNode);
  }

  private mapNodeProperties(_properties: any): NodeModel {
    let updatedConnections: Array<ConnectorModel> = [];
    switch (_properties.digitType) {
      case 'range':
        updatedConnections.push(new ConnectorModel({
          name: 'always',
          source: {connectorIndex: 0, nodeID: this.nodeData.id}
        }));
        updatedConnections = this.mapExistingConnections(updatedConnections, this.existingConnections);
        break;
      case 'keypress':
        updatedConnections.push(new ConnectorModel({
          name: _properties.digits[0],
          source: {connectorIndex: 0, nodeID: this.nodeData.id}
        }));
        updatedConnections = this.mapExistingConnections(updatedConnections, this.existingConnections);
        break;
      case 'digit':
        updatedConnections = this.mapDigitsToConnections(_properties.digits, _properties.timeoutDestination, this.nodeData.connections);
        break;
    }

    this.nodeData.outputConnectors = updatedConnections;
    this.nodeData.connections = updatedConnections;
    this.nodeData.properties = _properties;
    this.nodeData.properties.isDefined = true;

    return new NodeModel(this.nodeData);
  }

  private mapDigitsToConnections(digits: number[], timeoutDestination: boolean, existingConnections: ConnectorModel[]): ConnectorModel[] {
    let nodeID: string = this.nodeData.id;
    let connectorIndex: number = 0;
    let connections: ConnectorModel[] = [];

    digits.forEach(digit => {
      let connection: ConnectorModel = new ConnectorModel({name: digit, source: {connectorIndex, nodeID}});
      connections.push(connection);
      connectorIndex++;
    });

    if (timeoutDestination) {
      let connection: ConnectorModel = new ConnectorModel({name: 'Timeout', source: {connectorIndex, nodeID}});
      connections.push(connection);
      connectorIndex++;
    }

    connections = this.mapExistingConnections(connections, existingConnections);

    return connections;
  }

  private mapExistingConnections(updatedConnections: ConnectorModel[], existingConnections: ConnectorModel[]): ConnectorModel[] {

    let connectorIndex: number = 0;

    updatedConnections.forEach(connection => {
      if (existingConnections[connectorIndex]) {
        updatedConnections[connectorIndex].dest = existingConnections[connectorIndex].dest;
      }
      connectorIndex++
    });

    return updatedConnections;
  }

  private cancel(): void {
    this.dialogController.cancel();
  }
}
