import { ConfirmDialog } from './../../../conversation/interactioncards/call/components/voiceauthenticator/confirm-dialog';
import { OrganisationSessionModel } from './../../../../../_common/stores/sessionmodels/organisation-session-model';
import { computedFrom } from 'aurelia-binding';
//@ts-ignore
import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';

import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { PostInteractionProcessingService } from '../post-interaction-processing-service';

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import toastr from 'toastr';

const logger = LogManager.getLogger('AddPostInteractionProcessor');

interface ITab {
  id: string;
  name: string;
  viewModel: string;
  icon: string;
  data: {
    // Filters
    selectedInteractionType?: string
    selectedInteractionOutcomes?: string[],
    selectedWorktypes?: {
      templateId: string
    }[],
    selectedDispositions?: {
      name?: string;
      dispositionCodeId: string;
      dispositionCodeIds: string[],
      category: string;
      subCategory: {
        name: string;
        dispositionCodeIds: string[]
      };
    }[],
    filterVariables?: any[],

    // Delay
    delay?: number;

    // Actions
    assignedActions?: any[],
    temporaryAction?: any
  }
}

@autoinject()
export class AddPostInteractionProcessor {

  public dialogHeader: string = 'Create interaction Processor';
  public tabs: ITab[] = [
    { id: '1', name: 'Filters', viewModel: PLATFORM.moduleName('features/organisation/organisation/post-interaction-processing/add/add-steps/step1-filters/post-interaction-processing-filters'), icon: 'filter', data: {} },
    { id: '2', name: 'Delay',   viewModel: PLATFORM.moduleName('features/organisation/organisation/post-interaction-processing/add/add-steps/step2-delay/post-interaction-processing-delay'),     icon: 'timer', data: {} },
    { id: '3', name: 'Actions', viewModel: PLATFORM.moduleName('features/organisation/organisation/post-interaction-processing/add/add-steps/step3-actions/post-interaction-processing-actions'), icon: 'cog', data: {} }
  ];
  public name: string = '';
  private postinteractionProcessors: any[];

  private validation: ValidationController;

  constructor(
    private dialogController: DialogController,
    private postInteractionProcessingService: PostInteractionProcessingService,
    private validationControllerFactory: ValidationControllerFactory,
    private sessionStore: SessionStore,
    private dialogService: DialogService
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.manual;
  }

  public activate(postinteractionProcessors: any[]): void {
    this.postinteractionProcessors = postinteractionProcessors;
    this.initValidation();
  }

  private initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        for (let item of this.postinteractionProcessors) {
          if (item.name.toLowerCase() === value.toLowerCase()) {
            return false;
          }
        }
        return true;
      }, 'Please enter a unique name.');

    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a name.')
      .satisfiesRule('uniqueName')
      .on(this);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public create(): void {
    let actionTab = this.tabs.find(tab => tab.name === 'Actions');
    if (actionTab.data.temporaryAction) {
      this.dialogService
        .open({
          viewModel: ConfirmDialog,
          model: {
            message: 'You have an unsaved action. Selecting yes will discard this action.'
          }
        })
        .whenClosed(dialog => {
          if (!dialog.wasCancelled) {
            delete actionTab.data.temporaryAction;
            this.confirmCreate();
          }
        });
    } else {
      this.confirmCreate();
    }
  }

  private confirmCreate(): void {
    this.validation.validate().then(validation => {
      if (!validation.valid) {
        return;
      }
      const filterTab = this.tabs.find(tab => tab.name === 'Filters');
      const delayTab = this.tabs.find(tab => tab.name === 'Delay');
      const actionTab = this.tabs.find(tab => tab.name === 'Actions');

      if (!filterTab.data.selectedInteractionOutcomes || filterTab.data.selectedInteractionOutcomes.length === 0) {
        return toastr.error(`Please select at least 1 outcome.`);
      }

      if (!filterTab.data.selectedWorktypes || filterTab.data.selectedWorktypes.length === 0) {
        return toastr.error(`Please select at least 1 worktype.`);
      }

      if (!actionTab.data.assignedActions || actionTab.data.assignedActions.length === 0) {
        return toastr.error(`Please create at least 1 action.`);
      }

      let payload = {
        interactionPostProcessorId: uuidv4(),
        name: this.name,
        interactionType: filterTab.data.selectedInteractionType,
        outcomes: filterTab.data.selectedInteractionOutcomes,
        workTypes: filterTab.data.selectedWorktypes.map(wt => wt.templateId),
        dispositions: filterTab.data.selectedDispositions.map(d => {

          if (d.name === 'All' || d.name === 'NA') {
            return {
              description: d.name
            };
          } else if (d.name) {
            return {
              dispositionCodes: [{
                ids: d.dispositionCodeIds,
                name: d.name
              }]
            };
          } else if (d.category) {
            return {
              dispositionCodes: [{
                ids: d.subCategory.dispositionCodeIds
              }],
              category: d.category,
              subCategory: d.subCategory.name
            };
          }
        }),
        filterVariables: filterTab.data.filterVariables,
        delay: delayTab.data.delay,
        actions: actionTab.data.assignedActions.map(aa => {
          aa.formData = aa.formData ? JSON.parse(JSON.stringify(aa.formData)) : {};
          aa.outputVariables = aa.outputVariables ? JSON.parse(JSON.stringify(aa.outputVariables)) : {};
          aa.requestHeaders = aa.requestHeaders ? JSON.parse(JSON.stringify(aa.requestHeaders)) : {};

          if (aa.formData && Array.isArray(aa.formData)) {
            let mappedFormData = {};
            aa.formData.forEach(data => {
              
              let keys = Object.keys(data);
              keys.forEach(key => {
                mappedFormData[key] = [mappedFormData[key]]
              });
              return mappedFormData;
            });
            aa.formData = mappedFormData;
          }
          
          if (aa.outputVariables && Array.isArray(aa.outputVariables)) {
            let mappedOutputVariables = {};
            aa.outputVariables.map(data => mappedOutputVariables = { ...mappedOutputVariables, ...data });
            aa.outputVariables = mappedOutputVariables;
          }

          if (aa.requestHeaders && Array.isArray(aa.requestHeaders)) {
            let mappedRequestHeaders = {};
            aa.requestHeaders.forEach(data => mappedRequestHeaders = { ...mappedRequestHeaders, ...data });
            aa.requestHeaders = mappedRequestHeaders;
          }
          return aa;
        })
      };

      this.postInteractionProcessingService
        .createInteractionPostProsessor(this.organisationId, payload)
        .then(() => this.done())
        .catch(error => {
          logger.warn('Failed to create post interaction processor due to', error);
        })
    });
  }

  public done(): void {
    this.dialogController.ok();
  }

  @computedFrom('organisation')
  private get organisationId(): string {
    if(!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}
