import {autoinject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

const logger = LogManager.getLogger('ConfirmMemberRemovalDialog');

@autoinject()
export class ConfirmMemberRemovalDialog {

  public dialogHeader: string = 'Confirm Member Removal';
  public member: any;

  constructor(private dialogController: DialogController) {}

  public activate(member: any): void {
    this.member = member;
  }

  public confirm(): void {
    this.dialogController.ok();
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}