import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SessionStore } from 'zailab.common';
import { ContactFieldsService } from '../contact-fields-service';
import { ContactFieldModel } from '../contact-fields-model';

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import $ from 'jquery';

import { ContactFieldsListModel } from './contact-fields-list-model';
import { ContactFieldsListEvents, IValidationError } from './contact-fields-list-events';
import { ContactFieldsListRules } from './contact-fields-list-rules';

const logger = LogManager.getLogger('ContactFieldsList');

@autoinject
export class ContactFieldsList {

  public contactFieldsList: ContactFieldsListModel = new ContactFieldsListModel();

  constructor(
    private router: Router,
    private sessionStore: SessionStore,
    private contactFieldsService: ContactFieldsService,
    private events: ContactFieldsListEvents,
    private rules: ContactFieldsListRules,
  ) { }

  public async attached(): Promise<void> {

    this.rules.init(this.contactFieldsList);

    await this.events.init(this.sessionStore.get.organisation.organisationId);
    this.events.onDataInserted(() => this.handleOplogChanged());
    this.events.onDataUpdated(() => this.handleOplogChanged());
    this.events.onDataDeleted(() => this.handleOplogChanged());

    this.retrieveData();

    this.events.onContactFieldDeletedEvent((data: any) => {
      this.removeContactFieldFromList(data.state)
    });
  }

  public enableAddState(): void {
    this.contactFieldsList.addState = true;
    this.focus('#js-contactFieldName');
  }

  public addChoice(): void {
    if (this.contactFieldsList.currentChoice) {
      this.contactFieldsList.dropdownChoices.push(this.contactFieldsList.currentChoice);
      this.contactFieldsList.currentChoice = null;
    }
  }

  public removeChoice(choice: string): void {
    if (choice && this.contactFieldsList.dropdownChoices.indexOf(choice) > -1) {
      this.contactFieldsList.dropdownChoices.splice(this.contactFieldsList.dropdownChoices.indexOf(choice), 1);
    }
  }

  private async retrieveData(): Promise<any> {
    try {
      this.contactFieldsList.contactFields = await this.contactFieldsService.retrieveContactFields();
    } catch (e) {
      logger.error('retrieveContactFields :: e=', e);
    } finally {
      this.contactFieldsList.ready = true;
    }
  }

  private focus(elementId: string): void {
    let element: HTMLElement = document.querySelector(elementId);
    if (!element) {
      setTimeout(() => this.focus(elementId), 250);
      return;
    }
    element.focus();
  }

  private smoothScrollToBottom(): void {
    let div: HTMLElement = document.getElementById('scroll-down');
    $('#' + div.id).animate({
      scrollTop: (div.scrollHeight + 150) - div.clientHeight
    }, 350);
  }

  public addContactField(): void {
    const contactField: ContactFieldModel = {
      id: uuidv4(),
      name: this.contactFieldsList.name,
      type: this.contactFieldsList.type.name,
      dropdownChoices: this.contactFieldsList.dropdownChoices,
      deleted: false
    };
    this.contactFieldsList.contactFields.push(contactField);
    this.contactFieldsList.disableAddState();
    this.events.onValidationError((message: IValidationError) => {
      if (this.contactFieldsList.contactFields.indexOf(contactField) > -1) {
        this.contactFieldsList.contactFields.splice(this.contactFieldsList.contactFields.indexOf(contactField), 1);
      }
    });
    this.contactFieldsService.createContactField(contactField);
    this.smoothScrollToBottom();
  }

  private removeContactFieldFromList(data: any): void {
    var indexToRemove = null;
    for (var index = 0; index < this.contactFieldsList.contactFields.length; index++) {
      if (this.contactFieldsList.contactFields[index].id === data.customFieldId) {
        indexToRemove = index;
      }
    }

    if (indexToRemove) {
      this.contactFieldsList.contactFields.splice(indexToRemove, 1);
    }
  }

  private handleOplogChanged(): void {
    this.retrieveData();
  }

  public cancel(): void {
    this.contactFieldsList.disableAddState();
  }

  public navigateBack(): void {
    this.router.navigate('');
  }

  public deleteContactField(contactField: ContactFieldModel): void {
    if (contactField) {
      this.contactFieldsService.deleteContactField(contactField);
    }
  }

  public deactivate(): void {
    this.rules.close();
    this.events.close();
  }
}