import { inject, LogManager, Factory } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore, CommandFactory, ApplicationProperties, WebSocket, MESSAGE_EVENTS, OplogService } from 'zailab.common';
import { AreaCodeModel } from './area-code-model';
import { OrganisationNumberModel } from './organisation-number-model';
import { AvailableNumberModel } from './available-number-model';

const logger = LogManager.getLogger('NumbersService');

@inject(SessionStore, WebSocket, HttpClient, ApplicationProperties, EventAggregator, Factory.of(CommandFactory), OplogService)
export class NumbersService {

  constructor(sessionStore, webSocket, httpClient, applicationProperties, eventAggregator, commandFactory, oplogService) {
    this.sessionStore = sessionStore;
    this.webSocket = webSocket;
    this.httpClient = httpClient;
    this.applicationProperties = applicationProperties;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.oplog = oplogService;
  }

  subscribeToOrganisationNumbersUpdates(organisationId) {
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('telephone-number-projector.organisationTelephoneNumbersView'));
    });
  }

  retrieveOrganisationNumbersList() {
    let url = `v1/finance/telephonenumbers/numbers-view`;
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(url)
        .asGet()
        .send()
        .then(
          response => {
            if (response && response.telephoneNumbers) {
              resolve(this._organisationNumbersFactory(response.telephoneNumbers));
            } else {
              // TO DO : check if response is a blank array
              resolve(this._organisationNumbersFactory([]));
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  _processOrganisationNumbersUpdate(numberData) {
    if (numberData.telephoneNumbers && numberData.telephoneNumbers.length) {
      this._processWholeNumberListUpdate(numberData);
    } else {
      this._processSingleNumberUpdate(numberData);
    }
  }

  _processSingleNumberUpdate(numberData) {
    for (let property in numberData) {
      if (property.startsWith('telephoneNumbers')) {
        this.eventAggregator.publish('organisation.account.number.updated', new OrganisationNumberModel(numberData[property]));
      }
    }
  }

  _processWholeNumberListUpdate(numberData) {
    this.eventAggregator.publish('organisation.account.numbers.list.updated', this._organisationNumbersFactory(numberData.telephoneNumbers));
  }

  _organisationNumbersFactory(numbers) {
    let numbersList = [];
    numbers
      .filter(number => number.provider !== 'msteams')
      .forEach(number => {
        numbersList.push(new OrganisationNumberModel(number));
      });
    return numbersList;
  }

  searchAvailableNumbers(country, type, prefix, contains) {
    prefix = prefix.replace('+', '%2b');
    type = type.replace('-', '_');
    let url = `v1/telephonenumber/telephonenumbers?contains=${contains}&type=${type}&country=${country}&prefix=${prefix}`;
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(url)
        .asGet()
        .send()
        .then(
          response => {
            try {
              let numbers = response.map(number => {
                return new AvailableNumberModel(number);
              });
              resolve(numbers);
            } catch (e) {
              reject(e);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  rentNumber(numberId, country, numberType, provider, externalReference, telephoneNumber) {
    return new Promise((resolve, reject) => {

      numberType = numberType.replace('-', '_');

      const commandPayload = { numberId, country, numberType, provider, externalReference, telephoneNumber };

      this.httpClient
        .createRequest('v1/telephonenumber/telephonenumbers/rent')
        .asPut()
        .withContent(commandPayload)
        .send()
        .then(
          response => {
            const telephoneNumber = response;
            let message = `${telephoneNumber.numberType.replace('_', '-')} number purchased.`;

            this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, message);
            resolve(telephoneNumber);
          },
          error => {
            this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'Number purchase failed.');
            reject(error);
          }
        );
    });
  }

  returnNumber(numberId, country, numberType, provider, externalReference, telephoneNumber) {
    return new Promise((resolve, reject) => {

      numberType = numberType.replace('-', '_');

      const commandPayload = { numberId, country, numberType, provider, externalReference, telephoneNumber };

      this.httpClient
        .createRequest('v1/telephonenumber/telephonenumbers/return')
        .asPut()
        .withContent(commandPayload)
        .send()
        .then(
          response => {
            if (response.result.includes('Failed')) {
              reject(new Error(response.result));
              return;
            }
            resolve(response);
          },
          error => {
            reject(new Error(error));
          }
        );
    });
  }
}
