import { LogManager } from 'aurelia-framework';
import { ProcessingLayer } from './../../conversation/interaction/processing-layer';
import { ActivityInteractionModel } from './../../interactions/activity-interaction-model';
import { INTERACTION_ACTIONS, CONTACT_ACTIONS } from './../../conversation/interaction/interaction-actions';
import { TicketModel } from './../../conversation/interaction/ticket-model';
import { InteractionModel } from './../../../dashboard/teamleader/models/interaction-model';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-dependency-injection';

const logger = LogManager.getLogger('ContactCardEntryPoint');

interface ISubscription2 {
  event: string,
  subscription: Subscription
}

@autoinject
export class ContactCardEntryPoint {
  private subscriptions: ISubscription2[] = [];

  constructor(
    private eventAggregator: EventAggregator
  ) {}

  public interactionConnected(callback: Function): void {
    this.subscriptions.push({
      event: INTERACTION_ACTIONS.CONNECTED_INTERACTION,
      subscription: this.eventAggregator
        .subscribe(
          INTERACTION_ACTIONS.CONNECTED_INTERACTION,
          (interaction: InteractionModel | TicketModel) => callback(interaction)
        )
    });
  }

  public selectedFromRecentActivity(callback: Function): void {
    this.subscriptions.push({
      event: CONTACT_ACTIONS.SELECT_FROM_RECENT_ACTIVITY,
      subscription: this.eventAggregator
        .subscribe(
          CONTACT_ACTIONS.SELECT_FROM_RECENT_ACTIVITY,
          async (interaction: ActivityInteractionModel) => {
            let modelledInteraction = await ProcessingLayer.processInteraction(interaction);
            callback(modelledInteraction);
          }
        )
    });
  }

  public resumeLinkingFromRecentActivity(callback: Function): void {
    this.subscriptions.push({
      event: CONTACT_ACTIONS.SELECT_FROM_RECENT_ACTIVITY,
      subscription: this.eventAggregator.subscribe(
        INTERACTION_ACTIONS.RESUME_INTERACTION_LINKING,
        async (interaction: ActivityInteractionModel) =>{
          let modelledInteraction = await ProcessingLayer.processInteraction(interaction);
          callback(modelledInteraction);
        }
      )
    });
  }

  public selectedFromRecentConversations(callback: Function): void {
    this.subscriptions.push({
      event: CONTACT_ACTIONS.SELECT_FROM_RECENT_ACTIVITY,
      subscription: this.eventAggregator.subscribe(
        CONTACT_ACTIONS.SELECT_FROM_RECENT_CONVERSATION,
        async (conversationInfo: { conversationId: string, contactId: string }) => {
          let interaction = await ProcessingLayer.processInteraction(conversationInfo);
          interaction.channel = 'reminder';
          callback(interaction);
        }
      )
    });
  }
  
  public disposeOfSubscriptions(): void {
    for (let subscription of this.subscriptions) {
      subscription && subscription.subscription && subscription.subscription.dispose()
    }
  }
}