import {LogManager, customElement, bindable, containerless} from 'aurelia-framework';
import { inject } from 'aurelia-dependency-injection';

import $ from 'jquery';

import { Event } from 'zailab.common';

import './z-utility-input-v2.scss';

const logger = LogManager.getLogger('UtilityInputV2');

@containerless
@inject(Element)
@customElement('utility-input-v2')
export class UtilityInputV2 {

  @bindable public position;
  @bindable public spaced = true;
  @bindable public placeholder;
  @bindable public value;
  @bindable public autofocus = true;
  @bindable public fullwidth;
  @bindable({ attribute: 'custom-classes' }) public customClasses: string;
  @bindable public validation;

  public input;

  constructor(
    private element: Element
  ) {}

  public attached(): void {
    if (this.autofocus) {
      $(this.input).focus();
    }
  }

  public onChange(): void {
    new Event(this.element, 'change')
  }
}
