import { autoinject, inject, LogManager, PLATFORM } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { TeamLeaderService } from '../../team-leader-service';
import { AgentModel } from '../../models/agent-model';
/*
* */
const logger = LogManager.getLogger('AgentOverviewDialog');
/*
 * */
@autoinject
export class AgentOverviewDialog {

  public agent: AgentModel;
  public disableWhisperEnabled: boolean;
  
  public presences = [
    'Available',
    'Away',
    'Break',
    'Lunch',
    'Busy',
    'Meeting',
    'Training'
  ];

  constructor(
    private dialogController: DialogController,
    private dialogService: DialogService,
    private teamLeaderService: TeamLeaderService
  ) { }

  public activate(model: {agent: AgentModel, disableWhisperEnabled:boolean}): void {
    this.agent = model.agent;
    this.disableWhisperEnabled = model.disableWhisperEnabled;
  }

  public close(): void {
    this.dialogController.cancel();
  }
  
  public toggleRoutingStatus(): void {
    const routingStatus = (this.agent.currentRoutingStatus.toLowerCase() === 'ready') ? 'NOT_READY' : 'READY';
    this.teamLeaderService.setRoutingStatus(this.agent.memberId, routingStatus);
  }

  public changePresence(): void {
    this.teamLeaderService.changePresence(this.agent.userId, this.agent.currentPresence.toUpperCase());
  }

  public viewAgentStats(): void {
    let agentMenu: string = PLATFORM.moduleName('features/dashboard/teamleader/components/agentstatsdialog/agent-stats-dialog');
    let memberId: string = this.agent.memberId;
    let fullName: string = this.agent.fullName;
    let averageSurveyScore: number = this.agent.averageSurveyScore;

    this.dialogService.open({ viewModel: agentMenu, model: { memberId, fullName, averageSurveyScore } }).whenClosed(dialog => {
      if (dialog.wasCancelled) {
        return;
      }
    });
  }
  
  public logout(): void {
    this.teamLeaderService.logout(this.agent.passportId);
  }
  
  public spy(): void {
    this.teamLeaderService.spyCall(this.agent.callId);
  }
  
  public whisper(): void {
    this.teamLeaderService.whisperCall(this.agent.callId);
  }
}
