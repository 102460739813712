import {HttpClient} from 'aurelia-http-client';
import {inject, LogManager, Factory} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
* */
import {CommandFactory, UrlFactory, ApplicationProperties, SessionStore, OplogService} from 'zailab.common';
import {InteractionFlowModel} from './interaction-flow-model';
import InteractionFlowStatsModel from './interaction-flow-stats-model';
import {ChannelModel} from './channel-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('InteractionsService');

/*
 * */
@inject(HttpClient, Factory.of(CommandFactory), UrlFactory, ApplicationProperties, SessionStore, OplogService, EventAggregator)
export class InteractionsService {

  constructor(httpClient, commandFactory, urlFactory, applicationProperties, sessionStore, oplogService, eventAggregator) {
    this.httpClient = httpClient;
    this.commandFactory = commandFactory;
    this.urlFactory = urlFactory;
    this.applicationProperties = applicationProperties;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
    this.eventAggregator = eventAggregator;
  }

  /**
   * Subscribes to changes on the service list of an organisation.
   * @param organisationId string the organisationId of the organisation
   * @emits service.list.updated
   * */
  subscribeToInteractionFlowListChanges(organisationId) {
    this.serviceDatabaseService.subscribeToDisplayInteractionFlowsUpdates(organisationId, data => {
      this.eventAggregator.publish('interactions.list.updated', this._interactionListFactory(data.interactionFlows));
    });
  }

  /**
   * Un-subscribes from operation logger that listens for service list changes
   * @param organisationId string the organisationId of the organisation
   * */
  unsubscribeFromInteractionFlowListChanges(organisationId) {
    this.serviceDatabaseService.unsubscribeFromDisplayInteractionFlowsUpdates(organisationId);
  }

  retrieveOrganisationInteractionFlows() {
    return new Promise((resolve, reject) => {

      this.httpClient.createRequest('v1/interaction/interaction-flows')
        .asGet()
        .send()
        .then((response) => {
          let interactionFlows = [];
          if (response && response.interactionFlows) {
            interactionFlows = response.interactionFlows;
          }

          resolve(this._interactionListFactory(interactionFlows));
        }, (error) => reject(error));

    });
  }

  initialiseInteractionFlowsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('interaction-flow-projector.displayInteractionFlowsView'));
    });
  }

  retrieveWorkFlowsStats() {

    let organisationId = this.sessionStore.get.organisation.organisationId;

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/interaction/interaction-flows/widget')
        .asGet()
        .send()
        .then((response) => {
          let _stats = {};
          if (response && response.interactionFlows) {
            _stats = response.interactionFlows;
          }
          resolve(new InteractionFlowStatsModel(_stats));
        }, (error) => {
          reject(error);
        });
    });
  }

  initialiseStatsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('interaction-flow-projector.interactionFlowWidgetView'));
    });
  }

  /**
   * Retrieves the list of channels for an organisation
   * @returns Promise that resolves to a list of service objects.
   * from collection 'displayChannelsView'
   * from 'query-service-organisation'
   * @param {string} organisationId the organisationId of the organisation
   * @throws 503: Service Not Available
   * */
  retrieveInteractionFlowSettings(organisationId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/channels')
        .asGet()
        .send()
        .then((response) => {
          resolve(this._channelListFactory(response));
        }, (error) => {
          reject(error);
        });
    });
  }

  /**
   * Creates an interactions flow
   * @fires CreateInteractionFlowCommand
   * to 'command-service-interactions-designer'
   * @param {object} interactionFlow the flow that is being created
   * @param {string} organisationId the organisation for which the flow is created
   * @emits InteractionFlowCreated on completion from the backend
   * */
  createInteractionFlow(interactionFlow, organisationId) {
    let payload = {
      interactionFlowId: uuidv4(),
      organisationId: organisationId,
      flowName: interactionFlow.flowName,
      flowType: `${interactionFlow.flowType} Flow`,
      channel: interactionFlow.channel,
      isCopiedFlow: false,
      isSubflow: interactionFlow.isSubflow
    };

    return this.httpClient.createRequest(`v1/interaction/interaction-flows`)
      .asPost()
      .withContent(payload)
      .send();
  }

  /**
   * Removes an interactions flow
   * @fires RemoveInteractionFlowCommand
   * to 'command-service-interactions-designer'
   * @param {object} interactionFlow the flow that is being created
   * @emits InteractionFlowRemoved on completion from the backend
   * */
  removeInteractionFlow(interactionFlow) {

    this.httpClient.createRequest(`v1/interaction/interaction-flows/${interactionFlow.interactionFlowId}`)
      .asDelete()
      .send().then(response => {
    }, error => {
    });
  }

  /** select a single interactions flow object this will result in the user being redirected to the
   * interactions designer for this flow
   * @param {string} organisationId the organisationId for the flow
   * @param {object} interactionFlow the selected interactions flow
   * */
  selectInteraction(interactionFlow, organisationId) {
    this._retrieveAccessCode(organisationId, interactionFlow.interactionFlowId, interactionFlow.channel, interactionFlow.flowName);
  }

  retrieveAudit(page) {
    return this.httpClient
      .createRequest('v1/interaction/interaction-flows/audit')
      .asGet()
      .withParams({ page, size: 40 })
      .send();
  }

  retrieveAuditFlowDefinition(id, object) {
    return this.httpClient
      .createRequest('v1/interaction/interaction-flows/audit/flow-definition')
      .withParams({ id })
      .asGet()
      .send();
  }

  retrieveAdditionalDataAudit(id) {
    return this.httpClient
      .createRequest('v1/interaction/interaction-flows/audit/additional-data')
      .withParams({ id })
      .asGet()
      .send();
  }

  _interactionListFactory(interactionsData) {
    let interactionsList = [];
    interactionsData.forEach(interaction => {
      interactionsList.push(new InteractionFlowModel(interaction));
    });
    return interactionsList;
  }

  /**
   * @private
   * */
  _channelListFactory(channels) {
    let channelsList = [];
    channels.forEach(channel => {
      channelsList.push(new ChannelModel(channel));
    });
    return channelsList;
  }
}
