import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { AgentScriptAdminConfigCreateDialog } from './agent-script-admin-config-create-dialog';
import * as impl from './agent-script-admin-config-impl.json';
import { AgentScriptAdminConfigState } from './agent-script-admin-config-state';
import { AgentScriptAdminConfigUpdateDialog } from './agent-script-admin-config-update-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ZListViewSupport, ZView } from 'components/views/z-view';
import { Router } from 'aurelia-router';

const logger = LogManager.getLogger('AgentScriptAdminConfigList');

@autoinject
export class AgentScriptAdminConfigList {

  public impl: ZView;
  public support: ZListViewSupport;

  constructor(
    private router: Router,
    private dialog: DialogService,
    private state: AgentScriptAdminConfigState,
    events: EventAggregator,
  ) { 
    this.impl = impl as ZView;
    this.support = new ZListViewSupport(events);
  }

  public async activate(): Promise<void> {
    await this.state.loadState();
    this.retrieve();
  }

  public create(): void {
    this.dialog.open({ viewModel: AgentScriptAdminConfigCreateDialog }).whenClosed((res: any) => {
      if (res.wasCancelled) {
        return;
      }
      const item = res.output;
      this.fillItem(item);
      this.support.items.push(item);
    });
  }

  public retrieve(): void {
    this.support.items = this.state.agentScriptAdminConfigList.map((item) => {
      this.fillItem(item);
      return item;
    });
  }

  public update(model: any): void {
    this.dialog.open({ viewModel: AgentScriptAdminConfigUpdateDialog, model }).whenClosed((res: any) => {
      if (res.wasCancelled) {
        return;
      }
      const item = this.support.items.find((i) => i.id === model.id);
      if (!item) {
        return;
      }
      item.id = res.output.id;
      item.name = res.output.name;
      item.workTypeIds = res.output.workTypeIds;
      this.fillItem(item);
    });
  }

  public delete(): void {
    this.support.isDeleting = false;
    const toDelete = this.support.items.filter((item) => item.isDeleting);
    if (!toDelete || !toDelete.length) {
      return;
    }
    this.support.showLoader();
    this.state.deleteAll(toDelete.map((item) => item.id)).then(() => {
      this.support.items = this.support.items.filter((item) => !item.isDeleting);
      if (!this.support.items || !this.support.items.length) {
        this.support.placeholders = 30;
      }
      this.support.hideLoader();
    }).catch((error) => {
      logger.error(error);
      this.support.hideLoader();
    });
  }

  public select(item: any): void {
    if (this.support.isDeleting) {
      item.isDeleting = !item.isDeleting;
    } else {
      this.router.navigate(`designer/${item.id}`);
    }
  }

  private fillItem(item: any): void {
    item.isDeleting = false;
    item.actions = [{ action: 'edit', label: 'edit' }];
    item.workTypes = item.workTypeIds.map((id) => this.state.workTypes.find((item) => item.templateId === id).templateName);
    if (!item.workTypes || !item.workTypes.length) {
      item.searchable = item.name;
    } else {
      item.searchable = [].concat([item.name]).concat(item.workTypes).join(' ');
    }
  }
}