import {LogManager, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

const logger = LogManager.getLogger('MonitoringDashboardWidget');

@autoinject()
export class MonitoringDashboardWidget {

  public actions = ['view'];
  constructor(private router: Router) { }

  public view(): void {
    this.router.navigate('apimonitoring');
  }
}