import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { ArrayTools, MESSAGE_EVENTS } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { EventAggregator } from 'aurelia-event-aggregator';
import { WebchatService } from '../webchat-service';
import { PlaceholderService } from './../../../../_common/services/placeholder-service';
import { WebchatConfigurationDialog } from '../add-dialog/add-webchat-configuration-dialog';
import { SnippetDialog } from '../snippet-dialog/html-snippet-dialog';

const logger = LogManager.getLogger('WebchatConfigurationList');

@autoinject()
export class WebchatConfigurationList extends AbstractList {
  public placeholderService: PlaceholderService;
  public placeholders: number = 0;
  public container: any;
  public actions = [
    {
      action: 'edit',
      label: 'View Configuration',
      icon: 'edit',
    },
    {
      action: 'viewsnippet',
      label: 'View HTML snippet',
      icon: 'search',
    },
  ];

  public itemList: any;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private webchatService: WebchatService,
    eventAggregator: EventAggregator
  ) {
    super(eventAggregator);
  }

  public attached(): void {
    this.retrieveWebchatConfigurations();
  }

  private async retrieveWebchatConfigurations(): Promise<void> {
    let webchatConfigs: any[] = [];
    try {
      webchatConfigs = await this.webchatService.retrieveWebChatConfigs();
      webchatConfigs = webchatConfigs.map((webchatConfig) => {
        webchatConfig.actions = this.actions;
        webchatConfig.canDelete = !webchatConfig.interactionFlowName;
        return webchatConfig;
      });
      webchatConfigs = ArrayTools.sort(webchatConfigs, 'name');
    } catch (e) {
      logger.warn('Failed to retrieve webchat configs due to', { e });
    }

    this.itemList = List.Builder()
      .required(true)
      .customEventElement(this.element)
      .enableClick()
      .enableAdd()
      .enableDelete()
      .selectionType(List.MULTIPLE_SELECTION_TYPE)
      .autoSelect(false)
      .items(webchatConfigs)
      .uniqueIdentifier('id')
      .hasRollover(false)
      .tilesPerRow(5)
      .disableOrderBy()
      .build();
    this.generatePlaceholders();
  }

  public select(item: any): void {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.itemList.select && !this.itemList.isDeleting) {
      this.openWebChatConfigurationDialog(item);
      return;
    }
    if (this.itemList.isDeleting && item.canDelete !== false) {
      this.itemList.selectToDelete(item);
    } else {
      logger.warn('Cannot select item >>>', item);
    }
  }

  public deleteItems(): void {
    const itemsToDelete: any[] = [];
    let remainingItems: any[] = [];

    for (let item of this.itemList.items) {
      if (item.isDeleting) {
        item.isDeleting = false;
        item.showLoader = true;
        itemsToDelete.push(item);
      } else {
        remainingItems.push(item);
      }
    }
    super.deleteItems(itemsToDelete);
    this.itemList.items = remainingItems;
    this.itemList.isDeleting = false;
    this.hideLoader();
    this.generatePlaceholders();

    if (itemsToDelete.length === 1) {
      this.eventAggregator.publish(
        MESSAGE_EVENTS.SUCCESS,
        `Webchat configuration ${itemsToDelete[0].name} deleted.`
      );
    } else if (itemsToDelete.length > 1) {
      this.eventAggregator.publish(
        MESSAGE_EVENTS.SUCCESS,
        `${itemsToDelete.map(
          (item) => item.name
        )} Webchat configurations deleted.`
      );
    }
  }

  protected delete(webchatConfig: any): void {
    this.webchatService.deleteWebchatConfig(webchatConfig.webChatWidgetId);
  }

  private generatePlaceholders(): void {
    this.placeholderService = new PlaceholderService(
      this.container,
      this.itemList.items.length,
      5,
      (placeholders) => {
        this.placeholders = placeholders;
      }
    );
  }

  public openWebChatConfigurationDialog(webchatConfiguration?: any): void {
    this.dialogService
      .open({
        viewModel: WebchatConfigurationDialog,
        model: {
          webchatConfigurationNames: this.itemList.items.map((widget) => {
            return widget.name;
          }),
          webchatConfiguration,
        },
      })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          setTimeout(() => this.retrieveWebchatConfigurations(), 1000);
        }
      });
  }

  public viewHTMLSnippet(webchatConfiguration: any): void {
    this.dialogService
      .open({
        viewModel: SnippetDialog,
        model: {
          webchatConfiguration,
        },
      })
      .whenClosed((response: any) => {});
  }
}
