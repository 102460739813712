import { autoinject } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";

// @ts-ignore
import moment from 'moment';

import { SessionStore } from "zailab.common";
import { UserPassportService } from "./user-passport-service";

const logger = LogManager.getLogger('AutoLogoutNotifierService');

@autoinject
export class AutoLogoutNotifierService {

  public logoutWarningActive: boolean = false;
  private timerInterval: number;
  private debounceTimer: number;

  private autoLogoutWarningTime: moment;

  public autoLogoutTimeInMinutes: number;
  private timeoutInMilliseconds = 3000;
  private tenSeconds = 10000;

  constructor(
    private eventAggregator: EventAggregator,
    private userPassportService: UserPassportService,
    private sessionStore: SessionStore,
    private router: Router
  ) {}

  public initialiseAutoLogout(): void {
    this.eventAggregator.subscribe('application.update.token', data => this.handleTokenUpdate(data));
    this.eventAggregator.subscribe('document.body.clicked', () => this.handleBodyClick());
  }

  private handleTokenUpdate(data: { autoLogoutTimeout?: number }): void {
    this.logoutWarningActive = false;
    if (data.autoLogoutTimeout > 0) {
      this.autoLogoutTimeInMinutes = data.autoLogoutTimeout / 60 / 1000;
      this.autoLogoutWarningTime = moment().add(this.autoLogoutTimeInMinutes, 'minutes');
      this.triggerTimer();
    }
  }

  private triggerTimer(): void {

    if (this.timerInterval) {
      return;
    }

    this.timerInterval = setInterval(() => {

      let currentTime = moment();

      let duration = moment.duration(this.autoLogoutWarningTime.diff(currentTime));
      let seconds = duration.asSeconds();
      logger.debug(' AutoLogout | seconds remaining:', seconds);
      if (seconds <= 60) {
        logger.debug(' AutoLogout | show warning');
        this.logoutWarningActive = true;

        if (seconds <= 0) {
          this.logoutWarningActive = false;
        }
      }

    }, this.timeoutInMilliseconds);
  }

  private handleBodyClick(): void {
    if (this.debounceTimer || !this.sessionStore.get.user) {
      return;
    }
    this.userPassportService._refreshToken();
    this.debounceTimer = setTimeout(() => {
      this.debounceTimer = null;
    }, this.tenSeconds);
  }
}
