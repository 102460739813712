import { computedFrom } from 'aurelia-binding';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerValidateResult, ValidationController, ValidationRules } from 'aurelia-validation';
import { CurrentScopeValidation } from '../../../../_common/validation/current-scope-validation';
import { BusinessPartner } from '../../businesspartner/business-partner-model';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenter } from '../../contactcenter/contact-center-model';
import { ContactCenterService } from '../../contactcenter/contact-center-service';
import { TransferList } from '../transfer-list-model';
import { TransferListService } from '../transfer-list-service';

const logger = LogManager.getLogger('TransferListAdd');

@autoinject()
export class TransferListAdd {

  public transferList: TransferList = new TransferList();
  public businessPartners: BusinessPartner[] = [];
  public contactCenters: ContactCenter[] = [];
  public isProcessing: boolean = false;

  private validation: ValidationController;

  constructor(
    private transferListService: TransferListService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private router: Router,
    private validate: CurrentScopeValidation
  ) {
    this.validation = validate.getController();
  }

  public attached(): void {
    this.initValidationRules();
    this.initDisplay();
  }

  public save(): void {
    this.isProcessing = true;
    this.validation.validate()
      .then((result: ControllerValidateResult) => this.handleValidationResult(result));
  }

  public cancel(): void {
    this.router.navigate('list');
  }

  @computedFrom('transferList.businessPartner')
  public get businessPartnerContactCenters(): ContactCenter[] {
    return this.contactCenters.filter((contactCenter) => contactCenter.businessPartnerId === this.transferList.businessPartner);
  }

  private initValidationRules(): void {
    this.validation.reset();
    ValidationRules
      .ensure((transferList: TransferList) => transferList.name).required()
      .on(this.transferList);
  }

  private initDisplay(): void {
    this.businessPartnerService.findAll()
      .then((businessPartners) => this.businessPartners = businessPartners);
    this.contactCenterService.findAll()
      .then((contactCenters) => this.contactCenters = contactCenters);
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }

  private handleValidationResult(result: ControllerValidateResult): void {
    this.isProcessing = false;
    if (result.valid) {
      this.persist();
    }
  }

  private persist(): void {
    this.isProcessing = true;
    this.transferListService.insert(this.transferList)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }
}