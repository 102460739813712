import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 * */
import {BootstrapFormRenderer} from 'zailab.common';
import {AssignCallerIdDialogService} from './assign-caller-id-dialog-service';
/*
 * */
const logger = LogManager.getLogger('AssignCallerIdDialog');
/*
 * */
@inject(DialogController, ValidationControllerFactory, AssignCallerIdDialogService)
export class AssignCallerIdDialog {

  dialogHeader = 'Assign Caller ID';
  interactionFlowId;
  interactionFlowCallerId = '';

  constructor(dialogController, validationControllerFactory, assignCallerIdDialogService) {
    this.dialogController = dialogController;
    this.assignCallerIdDialogService = assignCallerIdDialogService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(model) {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.organisationId = model.organisationId;
    this.interactionFlowCallerId = model.interactionFlow.callerId ? model.interactionFlow.callerId : '';


    ValidationRules
      .ensure('interactionFlowCallerId')
      .matches(/^(\+){0,1}([0-9]){9,15}$/).withMessage('Please enter a number between 9 and 15 digits.')
      .on(this);
  }

  cancel() {
    this.dialogController.cancel();
  }

  done() {
    if (this.interactionFlowCallerId === '') {
      this.assignCallerIdDialogService.unAssignCallerIdFromFlow(this.interactionFlowId, this.organisationId);
      this.dialogController.ok();
    } else {
      this.validation.validate().then((validation) => {
        if(!validation.valid) {
          return;
        }
        this.assignCallerIdDialogService.assignCallerIdToFlow(this.interactionFlowId, this.organisationId, this.interactionFlowCallerId);
        this.dialogController.ok();
      }, (error) => {
        logger.info('Validation failed > error = ', error);
      });
    }
  }
}
