import { autoinject, LogManager, computedFrom } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
/*
 */
import { SessionStore, BootstrapFormRenderer, CountriesTools } from 'zailab.common';
import { AccountDetailsService } from './../accountdetails/account-details-service';
import { ACCOUNT_INFO_VALIDATION_MESSAGES } from './../accountdetails/account-details-validation-messages';
import { AccountDetailPayloadModel } from '../accountdetails/account-detail-payload-model';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
/*
 */
const logger = LogManager.getLogger('AccountDetails');

@autoinject
export class AccountDetailDialog {

  private accountInformationFormGroup; // Reference to the <form ... DOM group
  private accountId: string;
  private companyAddress: string = '';
  private contactFirstName: string = '';
  private contactSurname: string = '';
  private contactEmail: string = '';
  private companyName: string = '';
  private contactPhone: TelephoneNumberModel = null;
  private postalCode: string = '';
  private vatNumber: string = '';
  private region: string = '';
  private country = { name: 'South Africa', code: 'ZA' };
  private city: string = '';
  private isProcessing;
  private isEditing;
  private formValid: boolean;
  private validation: any;
  private countries = [{ country: 'South Africa' }, { country: 'United States' }];
  private dialogHeader = 'Upgrade Successful';

  constructor(private validationControllerFactory: ValidationControllerFactory, private accountDetailsService: AccountDetailsService, private dialogController: DialogController, private sessionStore: SessionStore) {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.initValidation();

    this.contactPhone = new TelephoneNumberModel('', '', 'za')
  }

  private attached(): void {
    this._retrieveAccountStatus();
  }

  @computedFrom('contactFirstName', 'contactSurname', 'companyAddress', 'contactEmail', 'companyName', 'postalCode', 'country', 'city', 'vatNumber')
  get isFormValid(): boolean {

    let notNull = this.companyAddress !== '' &&
      this.contactFirstName !== '' &&
      this.contactSurname !== '' &&
      this.contactEmail !== '' &&
      this.companyName !== '' &&
      this.postalCode !== '' &&
      this.country.name !== '' &&
      this.city !== '';
    this.validation.finishValidating.then((value) => {
      this.formValid = (value && value.valid ? true : false) && notNull && this.validation.errors.length === 0;
    });
    return true
  }

  private _retrieveAccountStatus(): void {
    this.accountDetailsService.retrieveAccountStatus().then(response => {
      this.accountId = response.accountId;
      this.companyAddress = response.companyAddress;
      this.contactSurname = response.contactSurname;
      this.contactEmail = response.contactEmail;
      this.companyName = response.companyName;
      this.contactPhone = response.contactPhone;
      this.postalCode = response.postalCode;
      this.vatNumber = response.vatNumber;
      this.country = response.countries[0].country;
      this.city = response.city;
    }, error => {
      logger.warn('Unable to retrieve account status ', error);
    });
  }

  /**
   * Initiates aurelia validation
   * @param {object} validation Aurelia validation instance
   */
  private initValidation(): void {

    ValidationRules
      .ensure('companyAddress')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.COMPANY_ADDRESS.NOT_EMPTY)
      .ensure('contactEmail')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_EMAIL.NOT_EMPTY)
      .email().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_EMAIL.IS_VALID)
      .ensure('companyName')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.COMPANY_NAME.NOT_EMPTY)
      .ensure('contactName')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_NAME.NOT_EMPTY)
      .ensure('contactSurname')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CONTACT_SURNAME.NOT_EMPTY)
      .ensure('region')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.REGION.NOT_EMPTY)
      .ensure('postalCode')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.POSTAL_CODE.NOT_EMPTY)
      .matches(/^\d+$/).withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.POSTAL_CODE.IS_VALID)
      .ensure('country')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.COUNTRY.NOT_EMPTY)
      .ensure('city')
      .required().withMessage(ACCOUNT_INFO_VALIDATION_MESSAGES.CITY.NOT_EMPTY)
      .on(this);
  }

  /**
   * Shows editable form
   */
  private edit(): void {
    this.isEditing = true;
  }

  /**
   * Validates/Saves the form info
   */
  private done(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.saveInfo();
    }, error => {
    });
  }

  /**
   * Extracts user defined data from store in order to initiate the save process
   */
  private saveInfo(): void {

    this.accountDetailsService.saveAccountInfo(this.accountId, this.companyName, this.companyAddress, this.vatNumber, this.city, this.postalCode, this.contactFirstName, this.contactSurname, this.contactPhone, this.contactEmail, this.country)
      .then(
        success => {
          this.isProcessing = false;
          this.isEditing = false;
          this.dialogController.ok();
        }, error => {
          this.isProcessing = false;
          this.isEditing = false;
        }
      );
  }

  @computedFrom('sessionStore.get.account.available')
  private get currency(): string {
    const account = this.sessionStore.get.account;
    return account ? account.available.currency : '';
  }

  /**
   * Closes the editing panel
   */
  private cancel(): void {
    this.dialogController.cancel()
  }

}