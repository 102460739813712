import { autoinject } from 'aurelia-dependency-injection';
import { DialogService } from 'aurelia-dialog';
import { PLATFORM, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { FeatureFlagService, SessionStore } from 'zailab.common';

import './agent-nav-menu.scss';

@containerless()
@autoinject()
export class AgentNavMenu {
  public agentLiveDashboardAccess: boolean;
  public showOpenAgentSchedule: boolean;

  constructor(
    private router: Router,
    private sessionStore: SessionStore,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService
  ) {}

  public bind() {
    this.getFeatureFlags();
  }

  private getFeatureFlags(): void {
    this.featureFlagService
      .isEnabled('AgentLiveDashboardAccess')
      .then(async (agentLiveDashboardAccess) => {
        this.agentLiveDashboardAccess = agentLiveDashboardAccess;
      });

    this.featureFlagService
      .isEnabled('agent-schedule-calabrio')
      .then((enabled) => {
        this.showOpenAgentSchedule = enabled;
      });
  }

  public navigateToConnectedInteractions(): void {
    this.router.navigate('connectedinteractions');
  }

  public navigateToRecentActivity(): void {
    this.router.navigate('recentactivity');
  }

  public navigateToInteractionLog(): void {
    this.router.navigate('interactionlog');
  }

  public navigateToRecentConversations(): void {
    this.router.navigate('recentconversations');
  }

  public navigateToLiveInteraction(): void {
    this.router.navigate('liveinteraction');
  }

  public navigateToAlertMessages(): void {
    this.router.navigate('alert-messages');
  }

  public navigateToInteractionLinks(): void {
    this.router.navigate('web-content');
  }

  public navigateToLiveDashboard(): void {
    this.router.navigate('livedashboard');
  }

  public openAgentSchedule(): void {
    window.open('https://mtdemousce01.teleopticloud.com/web/mytime#');
  }

  public openAgentStats(): void {
    // @ts-ignore
    let agentMenu = PLATFORM.moduleName(
      'features/dashboard/teamleader/components/agentstatsdialog/agent-stats-dialog'
    );
    let fullName = null;

    this.dialogService
      .open({
        viewModel: agentMenu,
        model: {
          memberId: this.memberId,
          fullName,
        },
      })
      .whenClosed((dialog) => {
        if (dialog.wasCancelled) {
          return;
        }
      });
  }

  private get memberId(): string {
    return this.sessionStore.get.user.memberId;
  }
}
