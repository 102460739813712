import { HttpClient, HttpResponseMessage } from 'aurelia-http-client';
import { inject, LogManager } from 'aurelia-framework';
import { SessionStore, ApplicationProperties, DownloadService } from 'zailab.common';
import { v4 as uuidv4 } from 'uuid';
/**/
const logger = LogManager.getLogger('AudioAddService');
/**/
const AUDIO_API_PREFIX: string = '/v1/media/audio/';
const GET_AUDIO_API_PREFIX: string = 'v1/media/audio/';
const TTS_API_PREFIX: string = '/v1/media/textToSpeech/';
const GET_TTS_API_PREFIX: string = 'v1/media/textToSpeech/';
const GET_FILES_API: string = AUDIO_API_PREFIX + 'search?fileName=';
const GET_COUNT_API: string = AUDIO_API_PREFIX + 'count';
const AUDIO_FORMAT = 'audio/wav';
/**/
@inject(HttpClient, SessionStore, ApplicationProperties, DownloadService)
export class AudioAddService {

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private applicationProperties: ApplicationProperties, private downloadService: DownloadService) { }

  public getAudioFilesCount = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      this.httpClient.get(GET_COUNT_API)
        .then(
          (responseMessage: HttpResponseMessage) => {
            resolve(responseMessage['count'] as number);
          },
          (error) => {
            reject(error);
          }
        );
    })
  };

  public retrieveAudioBinary(_audioId: string, _type: string): Promise<any> {

    let retrieveUri: string = _type === 'WAV upload' ? `${GET_AUDIO_API_PREFIX}${_audioId}` : `${GET_TTS_API_PREFIX}${_audioId}`;

    return new Promise((resolve, reject) => {
      let auth = 'Bearer ' + this.sessionStore.get.user.token;
      this.downloadService.downloadWithAuth('audiofile', retrieveUri, auth).then(
        recordingBlob => {
          let recordingBlobURI = URL.createObjectURL(recordingBlob);
          resolve(recordingBlobURI);
        },
        error => {
          reject(error);
        });

    });
  }

  public getAudioFiles = (audioGroupId?: string, workflowId?: string): Promise<Array<ZIAudioFile>> => {
    return this.httpClient.get(`${GET_FILES_API}&audioGroupId=${audioGroupId ? audioGroupId : ''}&workflowId=${workflowId ? workflowId : ''}`)
      .then((responseMessage: any) => responseMessage as Array<ZIAudioFile>);
  };

  public deleteAudio = (audioId: string): Promise<HttpResponseMessage> => {
    return this.httpClient.delete(AUDIO_API_PREFIX + audioId);
  };

  public deleteTTS = (ttsId: string): Promise<HttpResponseMessage> => {
    return this.httpClient.delete(TTS_API_PREFIX + ttsId);
  };

  public createTts(_payload: ZITtsAudioTabPayload, isPreview?: boolean): Promise<any> {
    let textToSpeechId = uuidv4();
    return this.postTts(_payload, textToSpeechId, isPreview);
  }

  public postTts(_payload: ZITtsAudioTabPayload, textToSpeechId: string, isPreview?: boolean): Promise<any> {

    let host: string = this.applicationProperties.apiQueryEndpoint;
    let auth: string = 'Bearer ' + this.sessionStore.get.user.token;
    let uri: string = `${host}v1/media/textToSpeech/${textToSpeechId}`;
    let text: string = _payload.textMessage;
    let description: string = _payload.description || _payload.textMessage;
    let speechRate: string = _payload.speechRate;
    let languageCode: string = _payload.speechLanguage ? _payload.speechLanguage.languageCode : '';
    let languageName: string = _payload.speechLanguage ? _payload.speechLanguage.languageName : '';
    let payload: any = { text, languageCode, languageName, speechRate, description };
    let xhr: XMLHttpRequest = new XMLHttpRequest();

    return new Promise((resolve, reject) => {

      xhr.open('POST', uri, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', auth);
      xhr.responseType = 'blob';

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {

            let textToSpeechURI = URL.createObjectURL(xhr.response);

            resolve(textToSpeechURI);

          } else {
            reject(xhr.statusText);
          }
        }
      };

      xhr.send(JSON.stringify(payload));

    });

  }

  private convertToBlob(response: string): Blob {
    var format = 'audio/wav';
    var binary = atob(response);
    var byteNumbers = new Array(binary.length);

    for (var i = 0; i < binary.length; i++) {
      byteNumbers[i] = binary.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: format
    });
    return blob;
  }

  public postAudioFile = (_audioFile: ZIUploadAudioTabPayload, _uuid: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {

      if (!_audioFile) {
        return reject('No audio payload')
      }

      let _formData: FormData = new FormData();
      let _orgId: string = this.sessionStore.get.organisation.organisationId;

      _formData.append('data', _audioFile.uploadedFile);

      this.httpClient.createRequest(AUDIO_API_PREFIX + _uuid)
        .asPost()
        .withParams({ organisationId: _orgId, audioId: _uuid, filename: _audioFile.audioName })
        .withContent(_formData)
        .send()
        .then(
          (responseMessage: HttpResponseMessage) => {
            logger.info("Response Message >> ", responseMessage);
            resolve(true)
          },
          (error) => {
            reject(error)
          }
        )
    })
  };

  public postLingualAudioFile = (_audioFile: ZIUploadAudioTabPayload, _uuid: string): Promise<boolean> => {

    return new Promise((resolve, reject) => {

      if (!_audioFile) {
        return reject('No audio payload')
      }

      let _formData: FormData = new FormData();

      _formData.append('data', _audioFile.uploadedFile);

      const url = '/v1/media/audio-language/' + _uuid;

      this.httpClient.createRequest(url)
        .asPost()
        .withParams({ filename: _audioFile.audioName })
        .withContent(_formData)
        .send()
        .then(
          (responseMessage: HttpResponseMessage) => {
            logger.info("Response Message >> ", responseMessage);
            resolve(true)
          },
          (error) => {
            reject(error)
          }
        )
    })
  };

  public putLingualAudioFile = (_uuid: string, parentAudioId: string, language: string, variableName: string, defaultSelection: boolean): Promise<boolean> => {

    return new Promise((resolve, reject) => {

      const url = '/v1/media/audio-language/' + _uuid;

      this.httpClient.createRequest(url)
        .asPut()
        .withParams({ parentAudioId, language, variableName, defaultSelection })
        .send()
        .then(
          (responseMessage: HttpResponseMessage) => {
            logger.info("Response Message >> ", responseMessage);
            resolve(true)
          },
          (error) => {
            reject(error)
          }
        )
    })
  };

}
