import {
  inject,
  LogManager
} from 'aurelia-framework';

import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
const logger = LogManager.getLogger('HidewheninactiveCustomAttribute'),
  classNames = {
    default: 'js-component-visibility',
    visible: 'is-visible'
  };
/*
 */
@inject(Element, EventAggregator)
export class HidewheninactiveCustomAttribute {

  clickEventSubscription;

  constructor(element, eventAggregator) {
    this.element = element;
    this.eventAggregator = eventAggregator;
    this.addClass(classNames.default);
  }

  attached() {
    this.clickEventSubscription = this.eventAggregator.subscribe('document.body.clicked', (event) => {
      this.hideComponents(event);
    })
  }

  detached() {
    this.clickEventSubscription.dispose();
  }

  valueChanged(newValue, oldValue) {
    this.addClass(classNames.default + '-' + newValue);
  }

  addClass(string) {
    this.element.className += ' ' + string;
  }

  hideComponents(event) {

    let _els = document.getElementsByClassName(classNames.default);
    let className = this.clickedInComponent(event);

    for (let _el of _els) {

      if (className === _el.className) {
        continue;
      }

      let container = _el.querySelector('.js-component-container');
      $(container).removeClass('is-visible');
    }
  }

  clickedInComponent(event) {
    let _element = event.srcElement;

    if (_element && _element.className && typeof _element.className === 'string' && _element.className.includes('js-component-visibility')) {
      return _element.className;
    }
    let parent = $(_element).closest('.js-component-visibility');

    if (parent.length) {
      return parent[0].className;
    }
    return false;
  }
}
