import { inject, LogManager, Factory } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-http-client';
/*
 * */
import { CommandFactory, SessionStore, OplogService } from 'zailab.common';
import { MemberModel } from '../../conversation/member-model';
import MemberPropertiesModel from './member-properties-model';
/*
 * */
const logger = LogManager.getLogger('MemberListService');

/*
 * */
@inject(
  HttpClient,
  Factory.of(CommandFactory),
  EventAggregator,
  SessionStore,
  OplogService
)
export class MembersService {
  constructor(
    httpClient,
    commandFactory,
    eventAggregator,
    sessionStore,
    oplogService
  ) {
    this.httpClient = httpClient;
    this.eventAggregator = eventAggregator;
    this.commandFactory = commandFactory;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
  }

  findMemberChannelAvailability(memberId) {
    return this.httpClient
      .createRequest(`v1/organisation/members/${memberId}/channel-availability`)
      .asGet()
      .send();
  }

  changeMemberChannelAvailability(memberId, availability) {
    return this.httpClient
      .createRequest(`v1/organisation/members/${memberId}/channel-availability`)
      .asPut()
      .withContent(availability)
      .send();
  }

  listMembers() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/me/members/`)
        .asGet()
        .send()
        .then(
          (response) => {
            let members = [];
            if (response && response.length > 0) {
              response.forEach((member) => {
                members.append(new MemberPropertiesModel(member));
              });
            }
            resolve(members);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  retrieveOrganisationMembersAndTotalMemberCount(
    role,
    name,
    email,
    pageNumber,
    pageSize,
    type
  ) {
    const memberSearchParams = new MemberSearchParams(
      role,
      name,
      email,
      type,
      pageNumber,
      pageSize
    );
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/organisation/members/search2${memberSearchParams.requestParams}`
        )
        .asGet()
        .send()
        .then(
          (response) => {
            this.modelOrganisationMembers(response).then((memberList) => {
              resolve({
                members: memberList,
                totalMemberCount: response.totalElements,
              });
            });
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  retrieveOrganisationMembers(role, name, email, pageNumber, pageSize) {
    const memberSearchParams = new MemberSearchParams(
      role,
      name,
      email,
      undefined,
      pageNumber,
      pageSize
    );
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(
          `v1/organisation/members/search2${memberSearchParams.requestParams}`
        )
        .asGet()
        .send()
        .then(
          (response) => {
            this.modelOrganisationMembers(response).then((memberList) => {
              resolve(memberList);
            });
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  retrieveMemberStatuses(memberIds) {
    let params = '';
    if (memberIds && memberIds.length) {
      params = '?' + memberIds.map((i) => 'memberId=' + i).join('&');
    }
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/members/status${params}`)
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  modelOrganisationMembers(response) {
    return new Promise((resolve) => {
      if (!response.payload) {
        resolve([]);
      }

      let memberList = [];
      for (let member of response.payload) {
        memberList.push(new MemberModel(member));
      }
      resolve(memberList);
    });
  }

  subscribeToValidationError(item) {
    if (this.validationErrorSubscription) {
      this.validationErrorSubscription.dispose();
    }

    return new Promise((resolve) => {
      this.validationErrorSubscription = this.eventAggregator.subscribe(
        'ValidationErrorsReceived',
        () => {
          if (item) {
            item.showLoader = false;
          }
          resolve();
        }
      );
    });
  }

  retrieveMembersStats() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/members/member-widget-view`)
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  initialiseStatsOplog() {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      resolve(
        this.oplog
          .subscribeOn('_id', organisationId)
          .in('member-projector.memberWidgetView')
      );
    });
  }

  retrieveMember(memberId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/me/members/${memberId}`)
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(new MemberPropertiesModel(response));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  retrieveCampaigns(memberId) {
    return this.httpClient
      .createRequest(`v1/organisation/members/${memberId}/campaigns`)
      .asGet()
      .send();
  }

  updateMemberChannels(memberId, channels) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/channels`)
      .asPut()
      .withContent(channels)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  allocateCapacityRulesToMember(memberId, capacityRules) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/capacity-rules`)
      .asPut()
      .withContent(capacityRules)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  allocateClassOfServiceToMember(memberId, classOfService) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/class-of-service`)
      .asPut()
      .withHeader('Content-Type', 'application/json;charset=UTF-8')
      .withContent('"' + classOfService + '"')
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  updateMemberRole(memberId, role) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .createRequest(`v1/organisation/me/members/${memberId}/role`)
        .asPut()
        .withHeader('Content-Type', 'application/json;charset=UTF-8')
        .withContent('"' + role + '"')
        .send()
        .then(
          (success) => resolve(success),
          (failure) => reject(failure)
        );
    });
  }

  updateMemberServices(memberId, services) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/services`)
      .asPut()
      .withContent(services)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  allocateMemberToSite(memberId, siteId) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/site`)
      .asPut()
      .withContent(siteId)
      .withHeader('Content-Type', 'application/json')
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  updateMemberTeams(organisationId, memberId, teamIds) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/teams`)
      .asPut()
      .withContent(teamIds)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  updateMemberSkills(memberId, skillIds) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/skills`)
      .asPut()
      .withContent(skillIds)
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  /**
   * Removes a single Member
   * @fires RemoveMemberCommand
   * to 'command-service-registration'
   * @param {object} member the member to remove
   * @emits MemberRemovedEvent on completion from the backend
   * */
  deleteMember(member) {
    this.subscribeToValidationError(member).then(() => {});

    this.httpClient
      .createRequest(`v1/organisation/me/members/${member.memberId}`)
      .asDelete()
      .send()
      .then(
        (response) => {},
        (error) => {}
      );
  }

  updateMemberBusinessPartners(memberId, businessPartnerIds) {
    return this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/business-partners`)
      .asPut()
      .withContent(businessPartnerIds)
      .send();
  }

  updateMemberContactCentres(memberId, contactCentreIds) {
    return this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/contact-centres`)
      .asPut()
      .withContent(contactCentreIds)
      .send();
  }

  getPublishedPermissions() {
    return this.httpClient
      .createRequest(`v1/user/permissions/published`)
      .asGet()
      .send();
  }

  updateMemberPermissions(memberId, permissionId, value, applyDataRestriction) {
    this.httpClient
      .createRequest(`v1/organisation/me/members/${memberId}/permission`)
      .asPut()
      .withContent({
        permissionId,
        value,
        applyDataRestriction,
      })
      .send();
  }
}

class MemberSearchParams {
  constructor(role, name, email, type, pageNumber, pageSize) {
    const params = [];
    if (role) {
      params.push(`role=${role}`);
    }
    if (name) {
      params.push(`name=${encodeURIComponent(name)}`);
    }
    if (email) {
      params.push(`email=${encodeURIComponent(email)}`);
    }
    if (type) {
      params.push(`originSystem=${encodeURIComponent(type)}`);
    }
    if (Number.isInteger(pageNumber)) {
      params.push(`pageNumber=${pageNumber}`);
    }
    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }
    if (params.length > 0) {
      this.requestParams = '?' + params.join('&');
    } else {
      this.requestParams = '';
    }
  }
}
