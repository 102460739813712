import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, customElement, LogManager } from "aurelia-framework";
import { Disposition, DispositionModel } from "features/organisation/organisation/dispositioncodes/disposition-codes-model";
import { DispositionCodesService } from "features/organisation/organisation/dispositioncodes/disposition-codes-service";
import { Event as CustomEvent, SearchTools, SessionStore } from "zailab.common";
import { ConversationService } from "../conversation-service";
import { InteractionModel } from '../interaction-model';

const logger = LogManager.getLogger('InteractionCardDisposition');

@customElement('z-interaction-card-disposition')
@autoinject
export class InteractionCardDisposition {

  @bindable private interactionId: string;
  @bindable public interaction: InteractionModel;
  @bindable public error: string;

  public comments: string;
  public selectedDisposition: Disposition;
  public dispositionCodes: Disposition[];
  public displaySearchResults: boolean;
  public searchText: string;

  private autoCloseTimer: any;
  private jsSearchInput: HTMLElement;

  constructor(
    private element: Element,
    private eventAggregator: EventAggregator,
    private sessionStore: SessionStore,
    private conversationService: ConversationService,
    private dispositionCodesService: DispositionCodesService,
  ) { }

  public attached(): void {

    this.conversationService
      .retrieveInteraction(this.interactionId)
      .then((interaction) => {
        let organisationId = this.sessionStore.get.organisation.organisationId;
        let serviceId = interaction.metaData.serviceId;
        if (!serviceId) {
          setTimeout(() => this.attached(), 1000)
          return;
        }
        let tiered = interaction.metaData.tiered;
        let direction =
          interaction.metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
        let channel;
        switch (interaction.metaData.channel) {
          case 'VOICE':
            channel = `${direction} Call`;
            break;
          case 'EMAIL':
            channel = 'Email';
            break;
          case 'CHAT':
            channel = 'Chat';
            break;
          case 'INSTANT_MESSAGE':
          case 'instant_message':
            channel = 'Instant Message';
            break;
          case 'TICKET':
            channel = 'Ticket';
            break;
          default:
            channel = interaction.metaData.channel;
            break;
        }
        this.dispositionCodesService
          .retrieveDispositionListByServiceChannelAndDirection(
            organisationId,
            serviceId,
            channel,
            direction,
            tiered
          )
          .then((dispositionList: DispositionModel) => {
            if (
              dispositionList &&
              dispositionList.dispositionCodes &&
              dispositionList.dispositionCodes.length > 0
            ) {
              this.dispositionCodes = dispositionList.dispositionCodes;
            }
          });
      });
  }

  public selectDisposition(disposition: Disposition): void {
    if (!disposition) return;
    this.error = null;
    this.dispositionCodes.forEach(item => item.selected = false);
    const selectedDisposition = this.dispositionCodes.find(disp => disp.description === disposition.description);
    if (!selectedDisposition) return;
    selectedDisposition.selected = true;
    this.selectedDisposition = selectedDisposition;
    this.hideSearchResults();
  }

  public dispositionSelected(): void {
    if (this.interaction.siyaCustom && this.interaction.isOnWrapup) {
      this.interaction.waitForDispositionChange = true;
    }
    if (this.comments) {
      this.selectedDisposition.comments = this.comments;
    }
    this.dispositionCodesService.putDispositionCodeOnInteraction(
      this.interactionId,
      this.sessionStore.get.user.memberId,
      this.selectedDisposition.dispositionCodeId,
      ''
    ).then(() => {
      this.selectedDisposition = null;
      this.searchText = null;
      this.eventAggregator.publish('select.disposition', this.selectedDisposition);
      new CustomEvent(this.element, 'close', {});
    });
  }

  public hideSearchResults(): void {
    this.displaySearchResults = false;
  }

  public startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      this.displaySearchResults = false;
    }, _delay);
  }

  public preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  public toggleSearchResults(event: Event): void {
    event.stopPropagation();
    this.displaySearchResults = true;
  }

  public partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}
