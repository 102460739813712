import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';

import {SessionStore, OplogService} from 'zailab.common';
import {OrganisationSessionModel} from '../../_common/stores/sessionmodels/organisation-session-model';
import {SyncStatusModel} from './sync-status-model';
import {ZIOplog} from '../../../typings/zai/zai.common';
import {IntegrationModel} from "./integration-model";

import {MOCK_INTEGRATIONS} from 'zailab.tests';

const logger = LogManager.getLogger('IntegrationsService');

@autoinject
export class IntegrationsService {
  retrievePresences(): { presenceCodeName: string; color?: string; }[] | PromiseLike<{ presenceCodeName: string; color?: string; }[]> {
    throw new Error('Method not implemented.');
  }
  updateMicrosoftTeamsPresenceConfig(configId: string, clientId: string, tenantId: string, clientSecret: string) {
    throw new Error('Method not implemented.');
  }
  createMicrosoftTeamsPresenceConfig(clientId: string, tenantId: string, clientSecret: string) {
    throw new Error('Method not implemented.');
  }

  private baseUrl: string;

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private oplogService: OplogService) {
    this.baseUrl = `v1/crm/organisation/${this.organisation.organisationId}`;
  }

  public getOAuthUrl(name: string, subdomain?: string): Promise<Location> {
    return this.httpClient
      .createRequest(`v1/integration/crm/authenticate/${name.toLowerCase()}`)
      .asGet()
      .send()
      .then((response: any) => {
        return response && response.url ? response.url : null;
      });
  }

  public async postAccessCode(accessCode: string, name: string, provider: string, organisationName?: string): Promise<void> {
    let requestBody: any = {
      organisationName: organisationName ? organisationName : this.organisation.organisationName,
      authorizationCode: accessCode,
      name: name
    };
    return this.httpClient
      .createRequest(`v1/integration/crm/authorize/${name.toLowerCase()}`)
      .asPost()
      .withContent(requestBody)
      .send()
      .then(response => Promise.resolve())
      .catch(error => Promise.reject());

  }

  public retrieveIntegrationsList(): Promise<any> {
    return this.httpClient
      .createRequest(`v1/integration/crm/connections`)
      .asGet()
      .send()
      .then(response => {
        // @ts-ignore
        let connections = response ? response.connections : null;
        return connections ? connections.map(item => new IntegrationModel(item)) : [];
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  public retrieveIntegrationConnectors(): Promise<HttpResponseMessage> {
    return this.httpClient
      .createRequest(`v1/integration/crm/connectors`)
      .asGet()
      .send()
      .then((response: HttpResponseMessage) => {
        return response;
      });
  }

  public startSync(): Promise<string> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/sync`)
      .asPost()
      .send()
      .then(response => {
        //@ts-ignore
        return response.syncId;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  public getSyncStatus(syncId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/sync/${syncId}`)
      .asGet()
      .send()
      .then(response => {
        let syncStatus = new SyncStatusModel(response);
        return syncStatus;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  public getMicrosoft365Config(): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/me/email-integration-config`)
      .asGet()
      .send();
  }

  public createMicrosoft365Config(clientId: string, tenantId: string, clientSecret: string, userId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/me/email-integration-config`)
      .asPost()
      .withContent({
        clientId,
        tenantId,
        clientSecret,
        name: 'office 365',
        userId
      })
      .send();
  }

  public updateMicrosoft365Config(id: string, clientId: string, tenantId: string, clientSecret: string, userId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/me/email-integration-config/${id}`)
      .asPut()
      .withContent({
        clientId,
        tenantId,
        clientSecret,
        name: 'office 365',
        userId
      })
      .send();
  }

  public getMicrosoft365ConfigEmails(id: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/me/email-integration-config/${id}/email-addresses`)
      .asGet()
      .send();
  }

  public addMicrosoft365Email(id: string, email: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/me/email-integration-config/${id}/email-addresses`)
      .asPut()
      .withContent({ email })
      .send();
  }

  public removeMicrosoft365Email(id: string, email: string): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/me/email-integration-config/${id}/email-addresses`)
      .asDelete()
      .withContent({ email })
      .send();
  }

  public subscribeToCredentialsOplog(organisationId: string): ZIOplog {
    //@ts-ignore
    return this.oplogService.subscribeOn('organisationId', organisationId).in('integration-service.connection');
  }

  public subscribeToSyncOplog(syncId: string): ZIOplog {
    //@ts-ignore
    return this.oplogService.subscribeOn('_id', syncId).in('cloud-elements-service.sync');
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}
