import { LogManager, inject } from "aurelia-framework";
import { FeatureFlagService } from "../../../../../featureflags/feature-flag-service";

const logger = LogManager.getLogger('WorkTypeDefineProperties');

@inject(FeatureFlagService)
export default class {

  limitedRoles = ['ADMINISTRATOR', 'OFFICE_EMPLOYEE'];
  lockedFlowType;
  contactCenterSupport = new ContactCenterSupport();
  multiServiceBPWorkType;

  constructor(featureFlagService) {
    this.featureFlagService = featureFlagService;
  }

  activate(wizard) {
    this.featureFlagService.isEnabled('multiServiceBPWorkType')
    .then((multiServiceBPWorkType) => {
      this.multiServiceBPWorkType = multiServiceBPWorkType;
    });

    this._initWizard(wizard);

    if (this.isOfficeType) {
      delete wizard.data.skills;
    } else {
      delete wizard.data.roles;
    }
  }

  attached() {
    if (!this.wizard.data.config || !this.wizard.data.config.flowChannel) {
      return;
    }
    this.setSelectedChannel();
  }

  setSelectedChannel() {
    if (!this.wizard.data.channels) {
      return setTimeout(() => this.setSelectedChannel(), 500);
    }
    let selectedChannel = this.wizard.data.channels.find(channel => channel.displayName === this.wizard.data.config.flowChannel);
    selectedChannel.isSelected = true;
  }

  selectChannel(data) {
    this.wizard.data.channels = data.list;
  }

  selectCos(data) {
    this.wizard.data.classOfService = data.list;
  }

  selectService(data) {
    this.wizard.data.services = data.list;
  }

  selectSite(data) {
    this.wizard.data.sites = data.list;
  }

  selectRole(data) {
    if (data.item.isSelected) {
      this._addRole(data.item);
    } else {
      this._removeRole(data.item);
    }
  }

  _removeRole(item) {
    for (let index = 0; index < this.wizard.data.roles.length; index++) {
      let _role = this.wizard.data.roles[index];
      if (item.roleName === _role.roleName) {
        this.wizard.data.roles.splice(index, 1);
      }
    }
  }

  _addRole(item) {

    this.wizard.data.roles = this.wizard.data.roles || [];
    let found = false;

    for (let _item of this.wizard.data.roles) {
      if (item.roleName === _item.roleName) {
        found = true;
      }
    }
    if (!found) {
      this.wizard.data.roles.push(item);
    }
  }

  selectSkill(skillGroups) {

    for (let skillGroup of skillGroups) {
      for (let skill of skillGroup.skills) {

        if (skill.isSelected) {
          this._addSkillToSkillGroup(skill, skillGroup);
        } else {
          this._removeSkillFromSkillGroup(skill, skillGroup);
        }
      }
    }
  }

  _addSkillToSkillGroup(skill, skillGroup) {
    let skillGroupFound = false;
    for (let _skillGroup of this.wizard.data.skills) {
      if (_skillGroup.skillGroupId === skillGroup.skillGroupId) {
        skillGroupFound = true;
        _skillGroup.skills.push(skill);
      }
    }
    if (!skillGroupFound) {
      this.wizard.data.skills.push(skillGroup);
    }
  }

  _removeSkillFromSkillGroup({skillId}, {skillGroupId}) {
    for (let skillGroup of this.wizard.data.skills) {
      if (skillGroup.skillGroupId === skillGroupId) {

        let skills = skillGroup.skills;
        for (let index = skills.length - 1; index >= 0; index--) {

          if (skills[index].skillId === skillId) {
            skills.splice(index, 1);
          }
        }
      }
    }
  }

  selectBusinessPartners(data) {

    this.contactCenterSupport.selectedBusinessPartners = data.list.filter((item) => item.isSelected);

    this.wizard.data.businessPartners = data.list;
    this.wizard.data.contactCentres = [];
  }

  selectContactCenters(data) {
    
    this.contactCenterSupport.selectedContactCentres = data.list.filter((item) => item.isSelected);

    this.wizard.data.contactCentres = data.list;
  }

  _initWizard(wizard) {
    wizard.data.skills = wizard.data.skills || [];
    wizard.data.businessPartners = wizard.data.businessPartners  || [];
    wizard.data.contactCentres = wizard.data.contactCentres || [];

    this.wizard = wizard;
    this._validateSelected();

    if (wizard.data.config) {
      this.config = wizard.data.config;
      if (this.config.flowChannel) {
        this.lockedFlowType = this.config.flowChannel;
      }
    }

    this.wizard.step.complete();

    wizard.beforeNextStep(() => {
      this.wizard.continue({stepTwoCompleted: true});
    });
  }

  _validateSelected() {
    this._validateChannels();
    this._validateServices();
    this._validateSites();
    this._validateRoles();
    this._validateBusinessPartners();
    this._validateContactCenters();
  }

  _validateChannels() {
    let channels = this.wizard.data.channels;
    this.wizard.data.channels = this._getSelected(channels);
  }

  _validateServices() {
    let services = this.wizard.data.services;
    this.wizard.data.services = this._getSelected(services);
  }

  _validateSites() {
    let sites = this.wizard.data.sites;
    this.wizard.data.sites = this._getSelected(sites);
  }

  _validateRoles() {
    let roles = this.wizard.data.roles;
    this.wizard.data.roles = this._getSelected(roles);
  }

  _validateBusinessPartners() {
    let businessPartners = this.wizard.data.businessPartners;
    this.wizard.data.businessPartners = this._getSelected(businessPartners);
  }

  _validateContactCenters() {
    let contactCentres = this.wizard.data.contactCentres;
    this.wizard.data.contactCentres = this._getSelected(contactCentres);
  }

  _getSelected(list) {
    if (!list) {
      return list
    }
    ;
    return list.filter(_listItem => {
      return _listItem.isSelected;
    });
  }

  get isOfficeType() {
    return this.wizard.data.type === 'OFFICE_FLOW';
  }
}

class ContactCenterSupport {
  
  selectedBusinessPartners = [];
  selectedContactCentres = [];
  isContactCenterDisabled = true;
}