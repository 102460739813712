import { autoinject, LogManager } from "aurelia-framework";
import { SearchTools } from "../../../../../zailab.common";
import { BusinessPartnerService } from "../../../businesspartner/business-partner-service";
import { ContactCenterService } from "../../../contactcenter/contact-center-service";
import { ChannelsService } from "../../../organisation/channels/channels-service";
import { ServicesService } from "../../../organisation/services/services-service";
import { SitesService } from "../../../organisation/sites/sites-service";
import { SkillsService } from "../../../organisation/skills/skills-service";
import { WorkTypesService } from "../work-types-service";

const logger = LogManager.getLogger('WorkTypesSearchFilter');

@autoinject
export class WorkTypesSearchFilter {

  public listenerConfirm: () => void;
  public displayFilter: boolean = false;
  public allWorkTypeList: any[];
  public oldFilter: WorkTypesSearchFilter = null;

  public channelList: any[] = [];
  public displayChannelSearchResults: boolean = false;
  public channelSearchCriteria: string = '';
  public selectedChannelSearch: any[] = [];

  public serviceList: any[] = [];
  public displayServiceSearchResults: boolean = false;
  public serviceSearchCriteria: string = '';
  public selectedServiceSearch: any[] = [];

  public siteList: any[] = [];
  public displaySiteSearchResults: boolean = false;
  public siteSearchCriteria: string = '';
  public selectedSiteSearch: any[] = [];

  public skillGroupList: any[] = [];
  public displaySkillGroupSearchResults: boolean = false;
  public skillGroupSearchCriteria: string = '';
  public selectedSkillGroupSearch: any[] = [];

  public skillList: any[] = [];
  public displaySkillSearchResults: boolean = false;
  public skillSearchCriteria: string = '';
  public selectedSkillSearch: any[] = [];

  public businessPartnerList: any[] = [];
  public displayBusinessPartnerSearchResults: boolean = false;
  public businessPartnerSearchCriteria: string = '';
  public selectedBusinessPartnerSearch: any[] = [];

  public allContactCenterList: any[] = [];
  public contactCenterList: any[] = [];
  public displayContactCenterSearchResults: boolean = false;
  public contactCenterSearchCriteria: string = '';
  public selectedContactCenterSearch: any[] = [];

  constructor(
    private workTypesService: WorkTypesService,
    private channelsService: ChannelsService,
    private servicesService: ServicesService,
    private sitesService: SitesService,
    private skillsService: SkillsService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
  ) { }

  public async setAllWorkTypeList(allWorkTypeList: any[]): Promise<void> {
    this.allWorkTypeList = new Array(...allWorkTypeList);
    this.resetSearchFilter();
    this.displayFilter = false;
  }

  public onConfirmed(listener: () => void): void {
    this.listenerConfirm = listener;
  }

  public closeFilter(): void {
    this.displayFilter = false;
    this.skillList = [];
    this.contactCenterList = [];
    this.closeAllDropdowns();
  }

  private closeAllDropdowns(): void {
    this.displayChannelSearchResults = false;
    this.displayServiceSearchResults = false;
    this.displaySiteSearchResults = false;
    this.displaySkillGroupSearchResults = false;
    this.displaySkillSearchResults = false;
    this.displayBusinessPartnerSearchResults = false;
    this.displayContactCenterSearchResults = false;
  }

  public toggleFilterDisplay(): void {
    this.displayFilter = !this.displayFilter;
    if (this.displayFilter) {
      this.getData();
    }
  }

  private getData(): void {

    this.channelsService.displayChannels().then((channels) => {
      this.channelList = channels;
    });

    this.servicesService.retrieveOrganisationSelectedServices().then((services) => {
      this.serviceList = services;
    });

    this.sitesService.retrieveSites().then((sites) => {
      this.siteList = sites;
    });

    this.skillsService.retrieveSkillGroups().then((skillGroups) => {
      this.skillGroupList = skillGroups;
    });

    this.businessPartnerService.findAll().then((businessPartners) => {
      this.businessPartnerList = businessPartners;
    });

    this.contactCenterService.findAll().then((contactCenters) => {
      this.allContactCenterList = contactCenters;
    });
  }

  // Channel

  public showChannelSearchResults(event: any): void {
    event.stopPropagation();
    this.displayChannelSearchResults = true;
  }

  public closeChannelDropdown(): void {
    setTimeout(() => {
      this.displayChannelSearchResults = false;
    }, 250);
  }

  public selectChannelSearch(channel: any): void {
    if (!this.selectedChannelSearch.includes(channel)) {
      this.selectedChannelSearch.push(channel);
    }
    this.channelSearchCriteria = '';
  }

  public removeChannelSearch(channel: any): void {
    const selectedChannelSearchIndex = this.selectedChannelSearch.findIndex((c) => c.channelName === channel.channelName);
    this.selectedChannelSearch.splice(selectedChannelSearchIndex, 1);
  }

  // Service

  public showServiceSearchResults(event: any): void {
    event.stopPropagation();
    this.displayServiceSearchResults = true;
  }

  public closeServiceDropdown(): void {
    setTimeout(() => {
      this.displayServiceSearchResults = false;
    }, 250);
  }

  public selectServiceSearch(service: any): void {
    if (!this.selectedServiceSearch.includes(service)) {
      this.selectedServiceSearch.push(service);
    }
    this.serviceSearchCriteria = '';
  }

  public removeServiceSearch(service: any): void {
    const selectedServiceSearchIndex = this.selectedServiceSearch.findIndex((s) => s.id === service.id);
    this.selectedServiceSearch.splice(selectedServiceSearchIndex, 1);
  }

  // Site

  public showSiteSearchResults(event: any): void {
    event.stopPropagation();
    this.displaySiteSearchResults = true;
  }

  public closeSiteDropdown(): void {
    setTimeout(() => {
      this.displaySiteSearchResults = false;
    }, 250);
  }

  public selectSiteSearch(site: any): void {
    if (!this.selectedSiteSearch.includes(site)) {
      this.selectedSiteSearch.push(site);
    }
    this.siteSearchCriteria = '';
  }

  public removeSiteSearch(site: any): void {
    const selectedSiteSearchIndex = this.selectedSiteSearch.findIndex((s) => s.id === site.id);
    this.selectedSiteSearch.splice(selectedSiteSearchIndex, 1);
  }

  // Skill Group

  public showSkillGroupSearchResults(event: any): void {
    event.stopPropagation();
    this.displaySkillGroupSearchResults = true;
  }

  public closeSkillGroupDropdown(): void {
    setTimeout(() => {
      this.displaySkillGroupSearchResults = false;
    }, 250);
  }

  public selectSkillGroupSearch(skillGroup: any): void {
    if (!this.selectedSkillGroupSearch.includes(skillGroup)) {
      this.selectedSkillGroupSearch.push(skillGroup);
      if (skillGroup.skills || skillGroup.skills.length) {
        this.skillList = this.skillList.concat(skillGroup.skills);
      }
    }
    this.skillGroupSearchCriteria = '';
  }

  public removeSkillGroupSearch(skillGroup: any): void {
    const selectedSkillGroupSearchIndex = this.selectedSkillGroupSearch.findIndex((sG) => sG.skillGroupId === skillGroup.skillGroupId); 
    this.selectedSkillGroupSearch.splice(selectedSkillGroupSearchIndex, 1);
    if (skillGroup.skills || skillGroup.skills.length) {
      skillGroup.skills.forEach((skill) => {
        const skillListIndex = this.skillList.findIndex((s) => s.id === skill.id);
        this.skillList.splice(skillListIndex, 1);
        const selectedSkillSearchIndex = this.selectedSkillSearch.findIndex((s) => s.id === skill.id);
        this.selectedSkillSearch.splice(selectedSkillSearchIndex, 1);
      });
    }
  }

  // Skill

  public showSkillSearchResults(event: any): void {
    event.stopPropagation();
    this.displaySkillSearchResults = true;
  }

  public closeSkillDropdown(): void {
    setTimeout(() => {
      this.displaySkillSearchResults = false;
    }, 250);
  }

  public selectSkillSearch(skill: any): void {
    if (!this.selectedSkillSearch.includes(skill)) {
      this.selectedSkillSearch.push(skill);
    }
    this.skillSearchCriteria = '';
  }

  public removeSkillSearch(skill: any): void {
    const selectedSkillSearchIndex = this.selectedSkillSearch.findIndex((s) => s.id === skill.id);
    this.selectedSkillSearch.splice(selectedSkillSearchIndex, 1);
  }

  // Business Partner

  public showBusinessPartnerSearchResults(event: any): void {
    event.stopPropagation();
    this.displayBusinessPartnerSearchResults = true;
  }

  public closeBusinessPartnerDropdown(): void {
    setTimeout(() => {
      this.displayBusinessPartnerSearchResults = false;
    }, 250);
  }

  public selectBusinessPartnerSearch(businessPartner: any): void {
    if (!this.selectedBusinessPartnerSearch.includes(businessPartner)) {
      this.selectedBusinessPartnerSearch.push(businessPartner);
      const contactCenters = this.allContactCenterList.filter((contactCenter) => {
        return contactCenter.businessPartnerId === businessPartner.id;
      });
      this.contactCenterList = this.contactCenterList.concat(contactCenters);
    }
    this.businessPartnerSearchCriteria = '';
  }

  public removeBusinessPartnerSearch(businessPartner: any): void {
    const selectedBusinessPartnerSearchIndex = this.selectedBusinessPartnerSearch.findIndex((bP) => bP.id === businessPartner.id);
    this.selectedBusinessPartnerSearch.splice(selectedBusinessPartnerSearchIndex, 1);
    const contactCenters = this.allContactCenterList.filter((contactCenter) => {
      return contactCenter.businessPartnerId === businessPartner.id;
    });
    contactCenters.forEach((contactCenter) => {
      const contactCenterListIndex = this.contactCenterList.findIndex((cC) => cC.id === contactCenter.id);
      this.contactCenterList.splice(contactCenterListIndex, 1);
      const selectedContactCenterSearchIndex = this.selectedContactCenterSearch.findIndex((cC) => cC.id === contactCenter.id);
      this.selectedContactCenterSearch.splice(selectedContactCenterSearchIndex, 1);
    });
  }

  // Contact Center

  public showContactCenterSearchResults(event: any): void {
    event.stopPropagation();
    this.displayContactCenterSearchResults = true;
  }

  public closeContactCenterDropdown(): void {
    setTimeout(() => {
      this.displayContactCenterSearchResults = false;
    }, 250);
  }

  public selectContactCenterSearch(contactCenter: any): void {
    if (!this.selectedContactCenterSearch.includes(contactCenter)) {
      this.selectedContactCenterSearch.push(contactCenter);
    }
    this.contactCenterSearchCriteria = '';
  }

  public removeContactCenterSearch(contactCenter: any): void {
    const selectedContactCenterSearchIndex = this.selectedContactCenterSearch.findIndex((cC) => cC.id === contactCenter.id);
    this.selectedContactCenterSearch.splice(selectedContactCenterSearchIndex, 1);
  }

  // Filter

  public resetSearchFilter(): void {
    if (this.oldFilter === null) {
      this.oldFilter = Object.assign({}, this);
    }
    // Channel
    this.displayChannelSearchResults = false;
    this.channelSearchCriteria = '';
    this.selectedChannelSearch = [];
    // Service
    this.displayServiceSearchResults = false;
    this.serviceSearchCriteria = '';
    this.selectedServiceSearch = [];
    // Site
    this.displaySiteSearchResults = false;
    this.siteSearchCriteria = '';
    this.selectedSiteSearch = [];
    // Skill Group
    this.displaySkillGroupSearchResults = false;
    this.skillGroupSearchCriteria = '';
    this.selectedSkillGroupSearch = [];
    // Skill
    this.displaySkillSearchResults = false;
    this.skillSearchCriteria = '';
    this.selectedSkillSearch = [];
    this.skillList = [];
    // Business Partner
    this.displayBusinessPartnerSearchResults = false;
    this.businessPartnerSearchCriteria = '';
    this.selectedBusinessPartnerSearch = [];
    // Contact Center
    this.displayContactCenterSearchResults = false;
    this.contactCenterSearchCriteria = '';
    this.selectedContactCenterSearch = [];
    this.contactCenterList = [];
  }

  public confirmSearchFilter(): void {
    this.listenerConfirm && this.listenerConfirm();
    this.oldFilter = null;
    this.displayFilter = false;
  }

  public cancelSearchFilter(): void {
    if (this.oldFilter !== null) {

      this.displayChannelSearchResults = this.oldFilter.displayChannelSearchResults;
      this.channelSearchCriteria = this.oldFilter.channelSearchCriteria;
      this.selectedChannelSearch = this.oldFilter.selectedChannelSearch;

      this.displayServiceSearchResults = this.oldFilter.displayServiceSearchResults;
      this.serviceSearchCriteria = this.oldFilter.serviceSearchCriteria;
      this.selectedServiceSearch = this.oldFilter.selectedServiceSearch;

      this.displaySiteSearchResults = this.oldFilter.displaySiteSearchResults;
      this.siteSearchCriteria = this.oldFilter.siteSearchCriteria;
      this.selectedSiteSearch = this.oldFilter.selectedSiteSearch;

      this.displaySkillGroupSearchResults = this.oldFilter.displaySkillGroupSearchResults;
      this.skillGroupSearchCriteria = this.oldFilter.skillGroupSearchCriteria;
      this.selectedSkillGroupSearch = this.oldFilter.selectedSkillGroupSearch;

      this.displaySkillSearchResults = this.oldFilter.displaySkillSearchResults;
      this.skillSearchCriteria = this.oldFilter.skillSearchCriteria;
      this.selectedSkillSearch = this.oldFilter.selectedSkillSearch;
      this.skillList = this.oldFilter.skillList;

      this.displayBusinessPartnerSearchResults = this.oldFilter.displayBusinessPartnerSearchResults;
      this.businessPartnerSearchCriteria = this.oldFilter.businessPartnerSearchCriteria;
      this.selectedBusinessPartnerSearch = this.oldFilter.selectedBusinessPartnerSearch;

      this.displayContactCenterSearchResults = this.oldFilter.displayContactCenterSearchResults;
      this.contactCenterSearchCriteria = this.oldFilter.contactCenterSearchCriteria;
      this.selectedContactCenterSearch = this.oldFilter.selectedContactCenterSearch;
      this.contactCenterList = this.oldFilter.contactCenterList;

      this.oldFilter = null;
    }
    this.toggleFilterDisplay();
    this.closeAllDropdowns();
  }

  public filter(): any[] {
    let filtered = new Array(...this.allWorkTypeList);

    const channelNames: string[] = this.selectedChannelSearch.map((channel) => channel.displayName);
    const serviceIds: string[] = this.selectedServiceSearch.map((service) => service.serviceId);
    const siteIds: string[] = this.selectedSiteSearch.map((site) => site.id);
    const skillIds: string[] = this.selectedSkillSearch.map((skill) => skill.id);
    const businessPartnerIds: string[] = this.selectedBusinessPartnerSearch.map((businessPartner) => businessPartner.id);
    const contactCenterIds: string[] = this.selectedContactCenterSearch.map((contactCenter) => contactCenter.id);

    filtered = filtered.filter((workType) => {
      if (channelNames.length > 0 && !channelNames.includes(workType.channel)) {
        return false;
      }
      if (serviceIds.length > 0 && !serviceIds.includes(workType.service.serviceId)) {
        return false;
      }
      if (siteIds.length > 0) {
        if (!workType.sites || workType.sites.length === 0) {
          return false;
        }
        let found = false;
        for (const site of workType.sites) {
          if (siteIds.includes(site.siteId)) {
            found = true;
            break;
          }
        }
        if (!found) {
          return false;
        }
      }
      if (skillIds.length > 0) {
        let workTypeSkillIds = [];
        for (const skillGroup of workType.skillGroups) {
          workTypeSkillIds = workTypeSkillIds.concat(skillGroup.skills.map((skill) => skill.skillId));
        }
        if (workTypeSkillIds.length === 0) {
          return false;
        }
        let found = false;
        for (const skillId of workTypeSkillIds) {
          if (skillIds.includes(skillId)) {
            found = true;
            break;
          }
        }
        if (!found) {
          return false;
        }
      }
      if (businessPartnerIds.length > 0) {
        if (workType.businessPartners && workType.businessPartners.length) {
          const workTypeBusinessPartnerIds = workType.businessPartners.map((businessPartner) => businessPartner.businessPartnerId);
          if (workTypeBusinessPartnerIds.length === 0) {
            return false;
          }
          let found = false;
          for (const businessPartner of workTypeBusinessPartnerIds) {
            if (businessPartnerIds.includes(businessPartner)) {
              found = true;
              break;
            }
          }
          if (!found) {
            return false;
          }
        } else if (workType.businessPartner && workType.businessPartner.businessPartnerId) {
          if (!businessPartnerIds.includes(workType.businessPartner.businessPartnerId)) {
            return false;
          }
        } else {
          return false;
        }
      }
      if (contactCenterIds.length > 0) {
        if (workType.contactCentres && workType.contactCentres.length) {
          const workTypeContactCenterIds = workType.contactCentres.map((contactCentre) => contactCentre.contactCentreId);
          if (workTypeContactCenterIds.length === 0) {
            return false;
          }
          let found = false;
          for (const contactCentre of workTypeContactCenterIds) {
            if (contactCenterIds.includes(contactCentre)) {
              found = true;
              break;
            }
          }
          if (!found) {
            return false;
          }
        } else if (workType.contactCentre && workType.contactCentre.contactCentreId) {
          if (!contactCenterIds.includes(workType.contactCentre.contactCentreId)) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    });
    return filtered;
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}