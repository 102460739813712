export class BypassCos {
 
  public id: string;
  public transferWhitelistId: string;
  public name: string;
  public businessPartner: string;
  public contactCenter: string;
  public state: string = 'DISABLED';
  public allNumbersWhitelisted: boolean;
  public allNumbersAllowed: boolean;
  public whitelistNumbers: AllowedMatch[] = [];
  public canDelete: boolean = true;
  public icon: string = 'check';
}

export class AllowedMatch {
  
  public id: string;
  public whitelistNumberId: string;
  public matchingTypeSelected: { name: 'string', value: 'string' };
  public matchType: string;
  public number: string;
}

export const MATCHING_TYPES: Array<any> = [{ name: 'Starts With', value: 'STARTS_WITH' }, { name: 'Contains', value: 'CONTAINS' }, { name: 'Exact Match', value: 'EXACT_MATCH' }];
 