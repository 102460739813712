import { autoinject } from "aurelia-framework";

import { ApplicationProperties } from "zailab.common";

@autoinject
export class WebphoneService {

  constructor(
    private applicationProperties: ApplicationProperties
  ) {}

  public getSource(): string | LocalWebphoneConfig {
    
    const webphoneEndpoint = this.applicationProperties.webphoneEndpoint;
    if (typeof webphoneEndpoint === 'string') {
      return this.applicationProperties.webphoneEndpoint;
    } else if (typeof webphoneEndpoint === 'object') {
      return {
        local: this.applicationProperties.webphoneEndpoint.local,
        dev1: this.applicationProperties.webphoneEndpoint.dev1,
        prod: this.applicationProperties.webphoneEndpoint.prod,
      }
    }
  }
}