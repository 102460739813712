import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { LogService } from 'zailab.common';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('SomethingWentWrongDialog');

@autoinject
export class SomethingWentWrongDialog {

  public reference: string = uuidv4();
  public conversationId: string;
  public interactionIds: Array<string> = [];

  constructor(
    private dialogController: DialogController,
    private logService: LogService
  ) {}

  public activate(data: {
    action: string,
    conversationId: string,
    interactionIds: Array<string>,
    error: string
  }): void {
    if (data) {
      this.conversationId = data.conversationId;
      this.interactionIds = data.interactionIds;

      this.logService.log({
        error: data.error,
        conversationId: this.conversationId,
        interactionIds: this.interactionIds,
        reference: this.reference
      });
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}