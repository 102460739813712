import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { WrapUpInteractionModel } from '../wrap-up-interaction-model';
import { INTERACTION_ACTIONS } from '../../contact/contactcontroller/contact-controller-actions';

const logger = LogManager.getLogger('WrapUp');

@autoinject
export class WrapUp {

  private wrapUpInteractions: WrapUpInteractionModel[] = [];

  constructor(private eventAggregator: EventAggregator) {
  }

  public initialise(): void {
    this.eventAggregator.subscribe('connection-interaction-updated', (data) => {
      this.processWrapUpOplog(data);
    });
  }

  private processWrapUpOplog(data: any): void {

    if (!data.interactions) {
      return;
    }
    if (data.interactions.length === 0 && this.wrapUpInteractions.length === 1) {
      this.eventAggregator.publish(INTERACTION_ACTIONS.WRAP_UP_ENDED, this.wrapUpInteractions[0]);
      this.wrapUpInteractions.splice(0, 1);
      return;
    }
    for (let record of data.interactions) {

      let updatedInteraction: WrapUpInteractionModel = record as WrapUpInteractionModel;

      let found: boolean = this.wrapUpInteractions.some((wrapUpInteraction, index: number) => {
        if (wrapUpInteraction && wrapUpInteraction.interactionId === updatedInteraction.interactionId) {
          return this.determineWrapUpChanges(wrapUpInteraction, updatedInteraction, index);
        }
      });

      if (found) {
        this.eventAggregator.publish(INTERACTION_ACTIONS.WRAP_UP_INTERACTION_UPDATED, updatedInteraction);
      } else {
        this.wrapUpInteractions.push(new WrapUpInteractionModel(updatedInteraction));
        this.eventAggregator.publish(INTERACTION_ACTIONS.WRAP_UP_STARTED, updatedInteraction);
      }
    }
  }

  private isMemberWrapUpIsOnWrapUp(wrapUpInteraction: WrapUpInteractionModel): boolean {
    return wrapUpInteraction.state === 'WRAP_UP';
  }

  private determineWrapUpChanges(wrapUpInteraction: WrapUpInteractionModel, updatedInteraction: WrapUpInteractionModel, index: number): boolean {
    if (this.wrapUpStarted(wrapUpInteraction, updatedInteraction)) {
      this.wrapUpInteractions[index].mapProperties(updatedInteraction);
      this.eventAggregator.publish(INTERACTION_ACTIONS.WRAP_UP_STARTED, this.wrapUpInteractions[index]);
      return true;
    }
    if (this.wrapUpEnded(wrapUpInteraction, updatedInteraction)) {
      this.wrapUpInteractions.splice(index, 1);
      this.eventAggregator.publish(INTERACTION_ACTIONS.WRAP_UP_ENDED, updatedInteraction);
      return true;
    }
    this.wrapUpInteractions[index].mapProperties(updatedInteraction);
    return true;
  }

  private wrapUpStarted(wrapUpInteraction: WrapUpInteractionModel, updatedInteraction: WrapUpInteractionModel): boolean {
    return this.isMemberWrapUpIsOnWrapUp(updatedInteraction) === true && this.isMemberWrapUpIsOnWrapUp(wrapUpInteraction) === false;
  }

  private wrapUpEnded(wrapUpInteraction: WrapUpInteractionModel, updatedInteraction: WrapUpInteractionModel): boolean {
    return this.isMemberWrapUpIsOnWrapUp(updatedInteraction) === false && this.isMemberWrapUpIsOnWrapUp(wrapUpInteraction) === true;
  }
}
