/*
 */
import {LogManager, customElement, bindable, Containerless, computedFrom} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZTextIcon');
/*
 */
@customElement('z-text-icon')
export class ZTextIcon {

  @bindable size: string;
  @bindable({attribute: 'text-weight'})
  textWeight;
  @bindable({attribute: 'text-size'})
  textSize;
  @bindable({attribute: 'icon-size'})
  iconSize;
  @bindable icon: string;
  @bindable({attribute: 'icon-color'})
  iconColor;
  @bindable({attribute: 'text-color'})
  textColor;
  @bindable spacing: string;
  @bindable({attribute: 'has-hover'})
  hasHover;
  @bindable capitals: string = '';
  @bindable({attribute: 'reverse-order'})
  reverseOrder;
  @bindable link: boolean;
  @bindable underline: boolean;
  iconAlignment;

  public created(): void {
    this.textTransform();
  }

  private textTransform(): void {

    switch (this.capitals) {

      case 'all': this.capitals = 'uppercase';
        break;

      case 'true': this.capitals = 'uppercase';
        break;

      case 'first': this.capitals = 'capitalize'
        break;

      default: 'none';
    }

  }
}
