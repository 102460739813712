import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/**/
import {SessionStore, OplogService, WebSocket} from 'zailab.common';
import {ContactFieldModel} from './contact-fields-model';
import { ZIOplog } from '../../../../../typings/zai/zai.common';
/**/
const logger = LogManager.getLogger('DispositionCodesService');

@autoinject
export class ContactFieldsService {

  constructor(private httpClient: HttpClient, private oplogService: OplogService, private sessionStore: SessionStore, private webSocket: WebSocket) {
  }

  public retrieveContactFields(): Promise<ContactFieldModel[]> {

    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/contact/custom-fields`)
        .asGet().send()
        .then(response => resolve(this.modelCollection(response.customFields, ContactFieldModel)), error => reject(error));
    });
  }

  public createContactField(contactField: ContactFieldModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/contact/custom-fields`)
        .asPost().withContent({
        customFieldId: contactField.id,
        name: contactField.name,
        type: contactField.type,
        dropdownChoices: contactField.dropdownChoices
      })
        .send().then(success => resolve(success), error => reject(error))
    });
  }

  public deleteContactField(contactField: ContactFieldModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`v1/organisation/contact/custom-fields/${contactField.id}`)
        .asDelete()
        .send().then(success => resolve(success), error => reject(error))
    });
  }

  public modelCollection(items: any, Model: any): any {
    if (!items) {
      return [];
    }
    if (items instanceof Array) {
      let mappedList: Array<any> = [];
      for (let item of items) {
        mappedList.push(new Model(item))
      }
      return mappedList;
    }
    return new Model(items);
  }
 
  public initialiseOplog(organisationId: string): Promise<ZIOplog> {
    return new Promise(resolve => {
      resolve(this.oplogService.subscribeOn('organisationId', organisationId).in('contact-projector.customFieldsView'));
    });
  }

  public subscribeToContactFieldDeletedEvent(callback: (data: any) => void): void {
    this.webSocket.subscribe({
      name: 'com.zailab.organisation.customfield.api.events.CustomFieldDeletedEvent',
      callback
    });
  }



  public unsubscribeFromContactFieldDeletedEvent(): void {
    this.webSocket.unSubscribe('com.zailab.organisation.customfield.api.events.CustomFieldDeletedEvent');
  }

}