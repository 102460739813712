import {autoinject, LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController, DialogService} from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import {FlowVersionDialogService} from './flow-version-service';
import { MESSAGE_EVENTS } from 'zailab.common';

const logger = LogManager.getLogger('FlowVersionDialog');

interface IVersion {
  version: number;
  isLive: boolean;
  actions?: any[];
  selected?: boolean;
}

interface IInteractionFlow {
  interactionFlow: {
    interactionFlowId: string;
    versions: IVersion[];
  };
  organisationId: string;
}

@autoinject()
export class FlowVersionDialog {

  private interactionFlowId: string;
  public isDeleting: boolean = false;
  private versions: IVersion[] = [];
  private defaultActions = [{
    action: 'view',
    label: 'view'
  }];
  private setLiveAction = {
    action: 'setlive',
    label: 'set live',
    icon: 'live'
  };
  private deleteAction = {
    action: 'delete',
    label: 'delete',
    icon: 'remove'
  };

  constructor(
    private dialogController: DialogController,
    private flowVersionDialogService: FlowVersionDialogService,
    private eventAggregator: EventAggregator
  ) {}

  public activate(model: IInteractionFlow): void {
    this.interactionFlowId = model.interactionFlow.interactionFlowId;
    this.mapActions(model.interactionFlow.versions);
  }

  private mapActions(versions: IVersion[]): void {
    if (versions) {
      this.versions = versions.map(version => {
        if (version.isLive) {
          version.actions = [].concat(this.defaultActions);
        } else {
          version.actions = [].concat(this.defaultActions, [this.deleteAction, this.setLiveAction]);
        }
        return version;
      });
    }
  }

  public view(version: number): void {
    this.dialogController.ok({
      version,
      interactionFlowId: this.interactionFlowId
    });
  }

  public setLive(version: number): void {
    this.flowVersionDialogService
      .setLive(this.interactionFlowId, version)
      .then(() => this.mockLiveVersion(version));
  }

  public toggleDelete(): void {
    this.isDeleting = !this.isDeleting;

    if (!this.isDeleting) {
      this.versions.forEach(version => version.selected = false);
    }
  }

  public disableDelete(): void {
    this.isDeleting = false;

    if (!this.isDeleting) {
      this.versions.forEach(version => version.selected = false);
    }
  }

  public select(version: IVersion): void {
    if (!this.isDeleting) {
      return;
    }
    if (version.isLive) {
      return;
    }
    version.selected = !version.selected;
  }

  public delete(versions: number[]): void {

    this.flowVersionDialogService
      .deleteFlowVersions(
        this.interactionFlowId,
        versions.map((version: any) => {
          try {
            return parseInt(version);
          } catch (e) {
            return version;
          }
        })
      )
      .then(() => {
        this.versions = this.versions.filter(version => versions.indexOf(version.version) === -1)
        this.disableDelete();
      }).catch((e: any) => {
        logger.warn(' > Failed to delete flow versions due to', e);
        try {
          const error = JSON.parse(e.response);
          this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, error.details[0]);
        } catch(error) {}
      })
  }

  public confirmDelete(): void {
    let selectedVersions = this.versions.filter(version => version.selected).map(version => version.version);
    if (selectedVersions.length > 0) {
      this.delete(selectedVersions);
    }
  }

  private mockLiveVersion(version: number): void {
    this.versions.forEach(_version => {
      _version.isLive = _version.version === version;
    });
    this.mapActions(this.versions);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public done(): void {
    this.dialogController.ok();
  }

}
