import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ZProportionalTile');

@autoinject()
@customElement('z-proportional-tile')

export class ZProportionalTile {

  @bindable private deleting = false;
  @bindable private bulkediting = false;
  @bindable private selected;
  @bindable private highlight;
  @bindable private warning;
  @bindable private disable;
  @bindable private placeholders;

  @bindable({attribute: 'has-hover'}) private hasHover: boolean = true;
  @bindable({attribute: 'proportion'}) private proportion: number;
  @bindable({attribute: 'has-loader'}) private hasLoader: boolean = false;

  constructor() {

  }

  attached(): void {

  }
}
