import { PLATFORM } from "aurelia-pal";

export const AUDIO_ASSETS = {
  WHOOSH: PLATFORM.moduleName('../../_assets/audio/whoosh.wav'),
  RING1: PLATFORM.moduleName('../../_assets/audio/ring1.ogg'),
  INCOMING_MESSAGE_NOTIFICATION: PLATFORM.moduleName('../../_assets/audio/solo_beeps_06.ogg'),
  INCOMING_TASK_NOTIFICATION: PLATFORM.moduleName('../../_assets/audio/solo_beeps_06.ogg'),
  INCOMING_REMINDER_NOTIFICATION: PLATFORM.moduleName('../../_assets/audio/solo_beeps_92.ogg'),
  USER_NOT_RESPONDING: PLATFORM.moduleName('../../_assets/audio/solo_beeps_45.ogg'),
  TOGGLE_SOUND_ON: PLATFORM.moduleName('../../_assets/audio/toggle_sound_on.ogg'),
  CALLS_WAITING_INCREMENT: PLATFORM.moduleName('../../_assets/audio/increment_calls_waiting.ogg'),
  ALARMING_BEEP: PLATFORM.moduleName('../../_assets/audio/alarming_beep.ogg'),
  INDUSTRIAL_WARNING: PLATFORM.moduleName('../../_assets/audio/industrial_warning.ogg'),
  SPACESHIP_ALARM: PLATFORM.moduleName('../../_assets/audio/spaceship_alarm.ogg'),
};
