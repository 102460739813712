import {LogManager, observable} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController, ValidationRules} from 'aurelia-validation';
import { CUSTOM_EVENTS } from '../../../../../../../../../_common/actions/event-actions';
/**/
import {DigitModel} from '../models/digit-model';
import {TabBase} from "./tab-base";
/**/
const logger = LogManager.getLogger('SingleDigitTab');
/**/
export class SingleDigitTab extends TabBase {

  private exitDigits: Array<DigitModel>;
  private existingDigits:Array<string>;
  public timeoutDestination: boolean = false;
  @observable public timeoutDuration: number = 300;

  constructor(_validationControllerFactory: ValidationControllerFactory, _element:Element) {
    super(_validationControllerFactory, _element);
  }

  public activate(_tab: object): void {
    super.activate(_tab);
  }

  public attached(): void {
    this.initDigits();
    this.setExistingData();
  }

  public setExistingData():void {
    this.existingDigits = this.tabData.data.digits || [];
    this.timeoutDestination = this.tabData.data.timeoutDestination || false;
    this.timeoutDuration = this.tabData.data.timeoutDuration || 300;
    let _matches:Array<DigitModel> = this.exitDigits.filter((_digit:DigitModel) => this.existingDigits.includes(_digit.value));
    if (_matches.length) { // We've found previous data
      for (let _match of _matches) {
        _match.isSelected = true;
      }
      
      let duration;
      if (this.timeoutDuration) {
        if (typeof this.timeoutDuration === 'string') {
          duration = parseInt(this.timeoutDuration);
        } else {
          duration = this.timeoutDuration;
        }
      }
      this.timeoutDuration = duration || 300;
      this.sendTabData({
        digits: this.extractDigits(),
        timeoutDestination: this.timeoutDestination,
        timeoutDuration: this.timeoutDestination ? duration : undefined
      });
    } else {
      this.invalidate();
    }
  }

  private extractDigits(): Array<string> {
    return this.exitDigits.filter(_digit => {
      return (_digit.isSelected === true)
    }).map((_digit) => {
      return _digit.value
    });
  }

  private initDigits(): void {
    this.exitDigits = [
      new DigitModel({value: '1'}),
      new DigitModel({value: '2'}),
      new DigitModel({value: '3'}),
      new DigitModel({value: '4'}),
      new DigitModel({value: '5'}),
      new DigitModel({value: '6'}),
      new DigitModel({value: '7'}),
      new DigitModel({value: '8'}),
      new DigitModel({value: '9'}),
      new DigitModel({value: '*'}),
      new DigitModel({value: '0'}),
      new DigitModel({value: '#'})
    ];
  }

  public selectDigit(digit: DigitModel): void {
    digit.isSelected = !digit.isSelected;
    if (this.extractDigits().length > 0) {
      this.saveState();
    } else {
      this.invalidate();
    }
  }

  public deselectDigits(): void {
    this.exitDigits.forEach(digit => {
      digit.isSelected = false;
    });
  }

  public toggletimeoutDestination(): void {
    this.timeoutDestination = !this.timeoutDestination;
    this.saveState();
  }

  public timeoutDurationChanged(newValue: string, oldValue: string): void {
    this.saveState();
  }

  private saveState(): void {
    if (!this.tabData) {
      return;
    }
    let duration;
    if (this.timeoutDuration) {
      if (typeof this.timeoutDuration === 'string') {
        duration = parseInt(this.timeoutDuration);
      } else {
        duration = this.timeoutDuration;
      }
    }
    this.timeoutDuration = duration || 300;
    this.sendTabData({
      digits: this.extractDigits(),
      timeoutDestination: this.timeoutDestination,
      timeoutDuration: this.timeoutDestination ? duration : undefined
    });
  }

  public doNothing(): void {}
}
