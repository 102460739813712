import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { ReportScheduleService } from '../reportschedule/report-schedule-service';

import { IReminder } from './reminder-model';

const logger = LogManager.getLogger('RemindersService');

@autoinject
export class RemindersService {

  constructor(private httpClient: HttpClient) { }

  public retrieveReminders(teamId: string): Promise<IReminder[]> {

    return new Promise(resolve => {

      this.httpClient.createRequest(`/v1/organisation/reminders/unacknowledged`)
        .asGet()
        .withParams({ teamId })
        .send()
        .then((response: any) => {
          resolve(response.unacknowledgedReminders);
        })
        .catch(e => {
          if (e.statusCode === 400) {
            logger.warn('retrieveReminders failed e=', e);
          } else {
            return e;
          }
        });
    });
  }
}