import { DialogService } from 'aurelia-dialog';
import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AbstractList } from 'zailab.abstract';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';

import moment from 'moment';
/**/
import ProspectListModel from '../prospect-list-model';
import Service from '../prospect-list-service';
import UploadResultsDialog from '../uploadresultsdialog/upload-results-dialog';
import { ClearRemainingProspectsDialog } from '../clearremainingprospects/clear-remaining-prospects-dialog';
import { PlaceholderService } from "../../../../../_common/services/placeholder-service";
import {  ViewState } from '../../campaign-view-state';
// import { ProspectListDialog } from './prospect-list-dialog';
/**/
const logger = LogManager.getLogger('Prospects');
/**/
@inject(Element, Service, Router, DialogService, EventAggregator, BindingSignaler)
export default class extends AbstractList {

  campaignId = null;
  campaignName = null;
  itemList = [];
  allProspectListStats;
  todayProspectListStats;
  ready = false;
  actions = [
    {
      action: 'view',
      label: 'view upload results'
    },
    {
      action: 'download',
      label: 'download report'
    },
    {
      action: 'list',
      label: 'list prospects',
      icon: 'overview-dashboard'
    },
    {
      action: 'remove',
      label: 'clear remaining items',
      warning: true
    }
  ];
  placeholders = 0;
  container;
  timeoutCount = 300000;
  lastRequestTimestamp;
  signallerInterval;
  enableSelectors = false;

  viewState = new ViewState();

  constructor(element, service, router, dialogService, eventAggregator, bindingSignaler) {
    super(eventAggregator);
    this.element = element;
    this.service = service;
    this.router = router;
    this.dialogService = dialogService;
    this.task = new ProspectListModel();
    this.eventAggregator = eventAggregator;
    this.bindingSignaler = bindingSignaler;
  }

  activate(params, data) {
    this.campaignId = params.campaignId;
    this.businessPartnerId = params.businessPartnerId;
    this.contactCentreId = params.contactCentreId;
  }

  attached() {
    this.retrieveCampaign();
  }

  retrieveCampaign(isOplog) {

    this.enableSelectors = false;
    this.service.retrieveCampaign(this.campaignId).then(
      response => {
        let list = [];

        if (this.itemList.length) {
          for (let item of response.prospectLists) {
            let found = false;

            for (let _item of this.itemList) {
              if (item.prospectListId === _item.prospectListId) {
                if (_item.status !== item.status) {
                  if (_item.status === 'ACTIVE') {
                    list.push(_item);
                  } else {
                    list.push(item);
                  }
                  found = true;
                }
              }
            }

            if (!found) {
              list.push(item);
            }
          }
        } else {
          list = response.prospectLists;
        }

        this.itemList = list;
        this.setStats(response);
        this.setupProspectListStatsConfig();
        this.campaignName = response.campaignName;
        this.ready = true;
        this.generatePlaceholders();
        this.enableSelectors = true;
        this.lastRequestTimestamp = new moment().add('minutes', 5);
        this.setupSignaller();
      },
      error => {
        logger.info('Error > Unable to retrieve list of prospects =', error);
        this.ready = true;
        this.generatePlaceholders();
        this.enableSelectors = true;
      }
    );
  }

  setupSignaller() {
    let signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        this.retrieveCampaign(false);
      }
    }, signal);
  }

  generatePlaceholders() {
    this.placeholderService = new PlaceholderService(this.container, this.itemList.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
  }

  setStats(response) {
    this.task = new ProspectListModel(response);
  }

  setupProspectListStatsConfig() {

    this.allProspectListStats = [
      { header: this.task.numberOfTasksTotalUploaded, label: 'Total Prospects', borderColor: 'highlight', emptyState: '0' },
      { header: this.task.numberOfTasksRemaining + ` [${this.task.numberOfTasksRemainingReady}]`, label: 'Remaining [Ready]', borderColor: 'yellow', emptyState: '0' },
      { header: this.task.numberOfTasksNotServed + ` [${this.task.numberOfTasksNotServedReady}]`, label: 'Not Served [Ready]', borderColor: 'yellow', emptyState: '0', subStat: true },
      { header: this.task.numberOfTasksRequeued + ` [${this.task.numberOfTasksRequeuedReady}]`, label: 'Requeued [Ready]', borderColor: 'yellow', emptyState: '0', subStat: true },
      { header: this.task.numberOfTasksCompleted, label: 'Completed', borderColor: 'green', emptyState: '0' },
      { header: this.task.numberOfTasksSuccessful, label: 'Successful', borderColor: 'green', emptyState: '0', subStat: true },
      { header: this.task.numberOfTasksPending, label: 'Pending', borderColor: 'green', emptyState: '0', subStat: true },
      { header: this.task.numberOfTasksUnsuccessful, label: 'Unsuccessful', borderColor: 'red', emptyState: '0', subStat: true },
      { header: this.task.numberOfTasksStale, label: 'Stale', borderColor: 'red', emptyState: '0' },
      { header: this.task.numberOfTasksCleared, label: 'Cleared', borderColor: 'red', emptyState: '0' },
    ];

    this.todayProspectListStats = [
      { header: this.task.todayStats.carriedOver, label: 'Carried Over', borderColor: 'highlight', emptyState: '0' },
      { header: this.task.todayStats.newToday, label: 'New Prospects', borderColor: 'highlight', emptyState: '0' },
      { header: this.task.todayStats.remaining + ` [${this.task.todayStats.remainingReady}]`, label: 'Remaining [Ready]', borderColor: 'yellow', emptyState: '0' },
      { header: this.task.todayStats.notServed + ` [${this.task.todayStats.notServedReady}]`, label: 'Not served [Ready]', borderColor: 'yellow', emptyState: '0', subStat: true },
      { header: this.task.todayStats.requeuedToday + ` [${this.task.todayStats.requeuedTodayReady}]`, label: 'Requeued (Today) [Ready]', borderColor: 'yellow', emptyState: '0', subStat: true },
      { header: this.task.todayStats.requeuedCarriedOver + ` [${this.task.todayStats.requeuedCarriedOverReady}]`, label: 'Requeued (Carried Over) [Ready]', borderColor: 'yellow', emptyState: '0', subStat: true },
      { header: this.task.todayStats.completed, label: 'Completed', borderColor: 'green', emptyState: '0' },
      { header: this.task.todayStats.successful, label: 'Successful', borderColor: 'green', emptyState: '0', subStat: true },
      { header: this.task.todayStats.pending, label: 'Pending', borderColor: 'green', emptyState: '0', subStat: true },
      { header: this.task.todayStats.unsuccessful, label: 'Unsuccessful', borderColor: 'red', emptyState: '0', subStat: true },
      { header: this.task.todayStats.stale, label: 'Stale', borderColor: 'red', emptyState: '0' },
      { header: this.task.todayStats.cleared, label: 'Cleared', borderColor: 'red', emptyState: '0' },
    ];
  }

  deactivate() {
    clearInterval(this.signallerInterval);
  }

  view(prospectListId) {
    this.dialogService.open({ viewModel: UploadResultsDialog, model: prospectListId }).whenClosed(response => { });
  }

  list(prospectList) {
    const prospectListId = prospectList.prospectListId;
    let route = `${this.campaignId}/prospectlist/${prospectListId}`;
    this.router.navigate(route);
  }

  download(item) {

    const prospectList = {
      prospectListId: item.prospectListId,
      name: item.name
    };

    this.service.downloadProspectsReport(prospectList).then(
      response => {
        logger.info('Retrieve Prospect List Report To Download Successfull', response);
      },
      error => {
        logger.info('Could Not Retrieve Prospect List Report To Download', error);
      }
    );
  }

  remove(item) {

    const data = {
      campaignId: this.campaignId,
      prospectListId: item.prospectListId
    };

    this.dialogService.open({ viewModel: ClearRemainingProspectsDialog, model: data }).whenClosed(response => { });
  }

  add() {
    
    const businessPartnerId = this.businessPartnerId;
    const contactCentreId = this.contactCentreId;

    let route = `${this.campaignId}/prospectlist`;
    if (businessPartnerId) {
      route += `?businessPartnerId=${businessPartnerId}`;
    }
    if (contactCentreId) {
      route += `&contactCentreId=${contactCentreId}`;
    }

    this.router.navigate(route);
  }

  back() {
    this.router.parent.navigate('campaigns/list');
  }

  startsWithMatch(searchExpression, value)  {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return value['name'].toLowerCase().startsWith(searchExpression.toLowerCase());
  }
}