import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';

import { BootstrapFormRenderer, SessionStore } from 'zailab.common';
import { LoadBalancerService } from '../load-balancer-service';
import { LOAD_BALANCER_ACTIONS } from '../actions';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const logger = LogManager.getLogger('LoadBalancerDialog');

@autoinject
export class LoadBalancerDialog {

  private loadBalancer: ZLoadBalancer = {
    name: ''
  };
  private ratio: ZRatio;

  private loadBalancerGroup;
  private loadBalancerFormGroup;
  private validation;
  private loadBalancers: ZLoadBalancer[];

  private startDate: string = '';
  private startTime: string = '';
  private endDate: string = '';
  private endTime: string = '';
  
  private dialogHeader: string = 'Load Balancer';

  private action: string;
  private saving: boolean = false;
  private error: string;

  constructor(
    private loadBalancerService: LoadBalancerService,
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    private sessionStore: SessionStore
  ) {

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param object
   * */
  private activate(data: { action: string, loadBalancer?: ZLoadBalancer, loadBalancers?: ZLoadBalancer[] }): void {
    this.loadBalancers = data.loadBalancers || [];
    this.loadBalancer = data.loadBalancer || this.loadBalancer;
    this.loadBalancer.organisationId = this.sessionStore.get.organisation.organisationId;
    this.initialise(data.action);
    this.initValidation();
  }

  private initialise(action: string): void {
    this.action = action || LOAD_BALANCER_ACTIONS.CREATE;
    if (
      this.action === LOAD_BALANCER_ACTIONS.ADD_TEMP_RATIO ||
      this.action === LOAD_BALANCER_ACTIONS.ADD_FUTURE_DATED_RATIO ||
      this.action === LOAD_BALANCER_ACTIONS.VIEW
    ) {
      this.dialogHeader += `: ${this.loadBalancer.name}`;
    }
    this.ratio = {
      ratioId: uuidv4(),
      startTimestamp: null,
      endTimestamp: null,
      type: this.action === LOAD_BALANCER_ACTIONS.ADD_TEMP_RATIO ? 'temporary' : 'default',
      outputs: []
    }
    if (this.loadBalancer && this.action !== LOAD_BALANCER_ACTIONS.CREATE) {
      this.loadBalancer.defaultRatios[0].outputs.forEach(output => {
        this.ratio.outputs.push({ name: output.name, percentage: null });
      });
    }
  }

  private initValidation(): void {

    ValidationRules
      .customRule('uniqueName', (value) => {
        let loadBalancer = this.loadBalancers.find(item => item.name.toLowerCase() === value.toLowerCase());
        if (loadBalancer) {
          return false;
        }
        return true;
      }, 'Please enter a unique name.');
    
    ValidationRules
      .customRule('beforeTodayStartDate', () => {
        if (moment(this.startDate).isBefore(moment().startOf('day'))){
          return false
        }
        return true;
      }, 'The Start date cannot be before Today.');

    ValidationRules
      .customRule('beforeTodayEndDate', () => {
        if (moment(this.endDate).isBefore(moment().startOf('day'))){
          return false
        }
        return true;
      }, 'The End date cannot be before Today.');

    let rules: any = ValidationRules;

    switch(this.action) {
      case LOAD_BALANCER_ACTIONS.CREATE:
        rules
          .ensure('name')
          .required().withMessage('Please enter a load balancer name.')
          .satisfiesRule('uniqueName')
          .on(this.loadBalancer);
        break;
      case LOAD_BALANCER_ACTIONS.ADD_TEMP_RATIO:
        rules = rules
          .ensure('endDate').required().withMessage('Please select an end date.')
          .satisfiesRule('beforeTodayEndDate')
          .ensure('endTime').required().withMessage('Please select an end time.');
        // no break here so that the rules are also applied that are in case LOAD_BALANCER_ACTIONS.ADD_FUTURE_DATED_RATIO
      case LOAD_BALANCER_ACTIONS.ADD_FUTURE_DATED_RATIO:
        rules
          .ensure('startDate').required().withMessage('Please select a start date.')
          .satisfiesRule('beforeTodayStartDate')
          .ensure('startTime').required().withMessage('Please select a start time.')
          .on(this);
        break;
    }
  }

  private createOutput(): void {
    this.ratio.outputs.push({
      name: '',
      percentage: null
    });
    this.error = null;

    setTimeout(() => {
      this.loadBalancerFormGroup.scrollTo(0, this.loadBalancerGroup.offsetHeight);
    }, 50)
  }

  private removeOutput(index: number): void {
    this.ratio.outputs.splice(index, 1);
  }

  private handleStartDatePicked(event: any): void {
    this.startDate = event.target.value;
  }

  private handleEndDatePicked(event: any): void {
    this.endDate = event.target.value;
  }

  /**
   * Closes the Aurelia dialog
   */
  private cancel(): void {
    this.dialogController.cancel();
  }

  private getTimestamp(date: string, time: string): moment {
    let hours: number;
    let minutes: number;

    if (date) {
      let momentDate = moment(date);

      if (time) {
        hours = parseInt(time.split(':')[0]);
        minutes = parseInt(time.split(':')[1]);
        momentDate = momentDate.add(hours, 'hours').add(minutes, 'minutes');
      }

      return momentDate.format('x');
    }
    return null;
  }

  private hasDuplicateOutputName(): boolean {

    let map = {};
    this.ratio.outputs.forEach((output: ZOutput) => {
      map[output.name] = map[output.name] ? map[output.name] + 1 : 1; 
    });
    for(let key in map) {
      if (map[key] > 1) {
        this.error = `Duplicate output name ${key} found.`;
        return true;
      }
    }
    this.error = null;
    return false;
  }

  private outputsSetUp(): boolean {
    if (this.action === LOAD_BALANCER_ACTIONS.CREATE) {
      if (this.ratio.outputs.length < 2) {
        this.error = 'Please create a minimum of 2 outputs.';
        return false;
      }
    }
    return true;
  }

  private save(): void {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      if (
        this.isOutputsValid() === false ||
        this.hasCorrectDateSelection() === false ||
        this.hasDuplicateOutputName() ||
        this.outputsSetUp() === false
      ) {
        return;
      }

      this.saving = true;
      this.ratio.startTimestamp = this.getTimestamp(this.startDate, this.startTime);
      this.ratio.endTimestamp = this.getTimestamp(this.endDate, this.endTime);

      switch(this.action) {
        case LOAD_BALANCER_ACTIONS.CREATE:
          this.createLoadBalancer();
          break;
        case LOAD_BALANCER_ACTIONS.ADD_TEMP_RATIO:
        case LOAD_BALANCER_ACTIONS.ADD_FUTURE_DATED_RATIO:
          this.addRatio();
          break;
      }
    }, error => {
      logger.info('Add load balancer > validation failure', error);
    });
  }

  private isOutputsValid(): boolean {
    if (this.ratio.outputs.length === 0) {
      if (this.action === LOAD_BALANCER_ACTIONS.CREATE) {
        return true;
      } else {
        this.error = 'Please create an output.';
        return false;
      }
    }
    let total = 0;
    
    for (let output of this.ratio.outputs) {
      if (!output.name) {
        this.error = 'Please enter an output name.';
        return false;
      } else if (!output.percentage) {
        this.error = 'Please enter an output percentage.';
        return false;
      } else if (output.percentage < 0 || output.percentage > 100) {
        this.error = 'Please enter a percentage between 0 and 100.';
        return false;
        // @ts-ignore
      } else if (output.percentage.includes('.')) {
        this.error = 'Decimal values are not allowed.';
        return false;
      }
      // @ts-ignore
      output.percentage = JSON.parse(output.percentage);
      total += output.percentage;
    }

    if (total !== 100) {
      this.error = 'Totals must always add up to 100%';
      return false;
    }
    this.error = null;
    return true;
  }

  private hasCorrectDateSelection(): boolean {
    let toDate = moment(this.endDate);
    let fromDate = moment(this.startDate);
    let dateDifference = toDate.diff(fromDate, 'days');

    if (dateDifference < 0) {
      this.error = 'The End Date cannot be before the Start Date';
      return false;
    }
    return true;
  }

  private createLoadBalancer(): void {
    if (this.ratio && this.ratio.outputs.length > 0) {
      this.loadBalancer.ratios = [this.ratio];
    }
    this.loadBalancerService
      .createLoadBalancer(this.loadBalancer)
      .then(() => this.dialogController.ok(this.loadBalancer));
  }

  private addRatio(): void {
    // todo: add validations > has to match default names
    // possibly redesign popup
    this.loadBalancerService
      .addRatio(this.loadBalancer.id, this.ratio)
      .then(() => this.dialogController.ok());
  }

}

