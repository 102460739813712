import { bindingMode } from 'aurelia-binding';
import { inject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';
import { DateTimeTools, Event } from 'zailab.common';

@inject(Element)
export class ZTimePicker {
  @bindable({
    attributes: 'selected-value',
    defaultBindingMode: bindingMode.twoWay 
  }) selectedValue: string = '';
  @bindable width: string;
  @bindable direction: string;

  public options = DateTimeTools.generateTimeList();

  constructor(
    private element: Element
  ) {}

  public valueChanged(value): void {
    new Event(this.element, 'time-changed', value);
  }
}
