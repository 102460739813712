export class ReportConfig {
  reportType: string = '';
  datePeriod: string = '';
  selector: string;
  icon: string = 'agent';
  label: string;
  newReport: boolean = false;
  category: string;
  reportName: string;
  reportDescription: string;
  reportDescriptionVisible: boolean = false;
  inputFormConfig: IReportInputFormConfig = {
    showServices: true,
    showRoles: false,
    showTimeBuckets: false,
    showBusinessPartners: false,
    showTeams: false,
    showMembers: false,
    showTimezone: true,
    showDate: true,
    withChannel: '',
    requiredService: false
  };

  public toggleDescriptionVisibility(): void {
    this.reportDescriptionVisible = !this.reportDescriptionVisible;
  }
}