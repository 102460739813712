import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger } from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import moment from 'moment';
import 'moment-duration-format';

import { SessionStore, BootstrapFormRenderer, SearchTools, FeatureFlagService } from 'zailab.common';
import InteractionLogService from '../interaction-log-service';
import { ViewInteractionDialog } from '../viewinteraction/view-interaction-dialog';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { WorkTypesService } from '../../../../organisation/conversation/worktypes/work-types-service';
import { DispositionCodesService } from '../../../../organisation/organisation/dispositioncodes/disposition-codes-service';

let logger = LogManager.getLogger('InteractionLog');

@inject(Element, Router, SessionStore, InteractionLogService, BindingSignaler, EventAggregator, DialogService, ValidationControllerFactory, WorkTypesService, DispositionCodesService, FeatureFlagService)
export class InteractionLog {
  list;
  ready = false;
  scrollLoader = false;
  isListOver = false;
  isMemberSelectionDisabled = false;

  dateFormat = 'YYYY-MM-DD'

  defaultSearchParams = {
    organisationId: null,
    callType: '',
    channel: '',
    outcome: '',
    surveyType: 'na',
    surveyOutcome: '',
    selectedType: null,
    fromDate: moment().subtract(30, "days").format(this.dateFormat),
    toDate: moment().format(this.dateFormat),
    memberId: '',
    fromNumber: '',
    toNumber: '',
    workTypeName: '',
    dispositionId: '',
    dispositionCategory: '',
    dispositionSubCategory: '',
    hasRecording: 'na',

    size: 18,
    page: -1
  };

  memberId = '';
  flowType = '';
  callType = '';
  channel = '';
  outcome = '';
  surveyType = '';
  surveyOutcome = '';
  selectedType;
  fromDate = '';
  toDate = '';
  fromNumber = '';
  toNumber = '';
  fromOrTo = '';
  hasRecording = '';

  members = [];
  sourceTargetOptions = 'Source and Target';
  interactionSearchOptions = ['Source or Target', 'Source and Target'];

  defaultFlowTypes = [
    { type: '', name: 'All' },
    { type: 'contactCentre', name: 'Contact Centre Flow' },
    { type: 'office', name: 'Office Flow' }
  ];

  recordingOptions = [
    { name: 'N/A', value: 'na' },
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' }
  ];

  selectedChannels = {};

  defaultChannels = [
    { type: 'email', name: 'Email', filter: ['contactCentre'] },
    { type: 'inboundCall', name: 'Inbound Call', filter: ['contactCentre', 'office'] },
    { type: 'outboundCall', name: 'Outbound Call', filter: ['contactCentre', 'office'] },
    { type: 'sms', name: 'SMS', filter: ['contactCentre'] },
    { type: 'chat', name: 'Chat', filter: ['contactCentre'] },
    { type: 'INSTANT_MESSAGE', name: 'Instant Message', filter: ['contactCentre'] },
    { type: 'ticket', name: 'Ticket', filter: ['contactCentre'] }
  ];

  defaultSurveyTypes = [
    { type: 'na', name: 'N/A' },
    { type: 'all', name: 'All' },
    { type: 'agentRating', name: 'Agent Rating' },
    { type: 'general', name: 'General' }
  ];

  defaultScoreTypes = [
    { type: '', name: 'All' },
    { type: 'passed', name: 'Passed' },
    { type: 'failed', name: 'Failed' }
  ];

  defaultOutcomes = [
    { type: 'attempted', name: 'Attempted', filter: ['Inbound Call', 'Outbound Call', 'Chat'] },
    { type: 'answered', name: 'Answered', filter: ['Inbound Call', 'Outbound Call', 'Chat', 'Instant Message'] },
    { type: 'abandoned', name: 'Abandoned', filter: ['Chat', 'Inbound Call', 'Outbound Call', 'Email', 'Instant Message'] },
    { type: 'missed', name: 'Missed', filter: ['Chat', 'Inbound Call', 'Instant Message'] },
    { type: 'forwarded', name: 'Forwarded', filter: ['Inbound Call'] },
    { type: 'callback', name: 'Callback', filter: ['Inbound Call'] },
    { type: 'mailbox', name: 'Mailbox', filter: ['Inbound Call'] },
    { type: 'sent', name: 'Sent', filter: ['Email', 'SMS'] },
    { type: 'served', name: 'Served', filter: ['Email', 'SMS'] },
    { type: 'cleared', name: 'Cleared', filter: ['Email'] }
  ];

  container;
  itemList = [];
  showFilter;
  showAdvancedFilterOptions = false;
  showVariablesFilters = false;
  actions = [
    {
      action: 'view',
      label: 'view interaction'
    }
  ];

  viewInteractionId;
  workTypes = [];
  selectedWorkType;
  displayWorkTypesSearchResults = false;
  workTypeCriteria;

  variables = {};
  searchableVariables = [];

  selectedMember;
  displayMembersSearchResults = false;
  memberCriteria;

  dispositionList = [];
  selectedDisposition;
  displayDispositionSearchResults = false;
  dispositionCriteria;

  dispositionCodes = [];
  selectedDispositionCode;
  displayDispositionCodeSearchResults = false;
  dispositionCodeCriteria;

  dispositionCategoryList = [];
  selectedDispositionCategory;
  displayDispositionCategorySearchResults = false;
  dispositionCategoryCriteria;

  dispositionSubCategoryList = [];
  selectedDispositionSubCategory;
  displayDispositionSubCategorySearchResults = false;
  dispositionSubCategoryCriteria;
  embeddedWebphoneEnabled = null;



  constructor(element, router, sessionStore, interactionLogService, bindingSignaler, eventAggregator, dialogService, validationControllerFactory, workTypesService, dispositionCodesService, featureFlagService) {
    this.element = element;
    this.router = router;
    this.sessionStore = sessionStore;
    this.interactionLogService = interactionLogService;
    this.bindingSignaler = bindingSignaler;
    this.eventAggregator = eventAggregator;
    this.dialogService = dialogService;
    this.workTypesService = workTypesService;
    this.dispositionCodesService = dispositionCodesService;
    this.featureFlagService = featureFlagService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(params) {
    this.outcomes = this.defaultOutcomes;
    this.viewInteractionId = params.interactionId;

    if (this.isAgent) {
      this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
        .then(async (embeddedWebphoneEnabled) => {
          this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
        });
    }
  }

  attached() {
    this.setupFilter();
    this.retrieveInteractions();
    // this.infiniteScroll = new InfiniteScrollService(this.container, () => this.scrollEnd());
    this.placeholderService = new PlaceholderService(this.container, this.itemList.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
    this.retrieveWorkTypesForMember();
    this.retrieveDispositions();
  }

  setupFilter() {

    this.selectFlowType(this.defaultFlowTypes[0]);
    this.flowType = 'js-';
    this.hasRecording = (this.defaultSearchParams && this.defaultSearchParams.hasRecording ? this.defaultSearchParams.hasRecording : 'na')
    this.surveyType = (this.defaultSearchParams && this.defaultSearchParams.surveyType ? this.defaultSearchParams.surveyType : 'na');
    this.surveyOutcome = (this.defaultSearchParams && this.defaultSearchParams.surveyOutcome ? this.defaultSearchParams.surveyOutcome : '');

    this.callType = this.defaultSearchParams.callType;
    this.channel = this.defaultSearchParams.channel;
    this.outcome = this.defaultSearchParams.outcome;
    this.fromDate = this.defaultSearchParams.fromDate;
    this.toDate = this.defaultSearchParams.toDate;
    this.members = this._retrieveAllMembers(this.organisationId);
    this.memberId = this.defaultSearchParams.memberId;
    this.fromNumber = this.defaultSearchParams.fromNumber;
    this.toNumber = this.defaultSearchParams.toNumber;
    this.size = this.defaultSearchParams.size;
    this.workTypeName= this.defaultSearchParams.workTypeName ? this.defaultSearchParams.workTypeName : null;
    this.dispositionId= this.defaultSearchParams.dispositionId ? this.defaultSearchParams.dispositionId : null;
    this.dispositionCategory= this.defaultSearchParams.dispositionCategory ? this.defaultSearchParams.dispositionCategory : null;
    this.dispositionSubCategory= this.defaultSearchParams.dispositionSubCategory ? this.defaultSearchParams.dispositionSubCategory : null;
    this.searchableVariables = this._retrieveSearchabeVariables();


    ValidationRules
      .ensure('fromDate')
      .satisfies(() => {
        var startDate = moment(this.fromDate);
        var endDate = moment(this.toDate);
        return (startDate <= endDate);
      })
      .withMessage('The `Date From` cannot be after the `Date To`.')
      .ensure('toDate')
      .satisfies(() => {
        var startDate = moment(this.fromDate);
        var endDate = moment(this.toDate);
        return (startDate <= endDate);
      })
      .withMessage('The `Date From` cannot be after the `Date To`.')
      .on(this);
  }

  retrieveWorkTypesForMember() {

    let userRoles = this.sessionStore.get.user.userAccessRoles;
    const role = userRoles[1].role;

    if('Administrator'===role){
      this.workTypesService
      .retrieveWorkTypes()
      .then(workTypes => {
        if(workTypes.length > 0){
          for(let w of workTypes){
              const it = {
                "id": w.id,
                "name": w.templateName,
                "templateId": w.templateId,
                "templateName": w.templateName
              }
              this.workTypes.push(it);
          }
        }
        this.selectedWorkType = this.workTypes[0];
      })
      .catch(() => {});
  

    }else{
      this.workTypesService
      .retrieveWorkTypesForMember(this.sessionStore.get.user.memberId)
      .then(workTypes => {
        if(workTypes.length > 0){
          for(let w of workTypes){
              const it = {
                "id": w.id,
                "name": w.templateName,
                "templateId": w.templateId,
                "templateName": w.templateName
              }
              this.workTypes.push(it);
          }
        }
        this.selectedWorkType = this.workTypes[0];
      })
      .catch(() => {});
  }
}

  retrieveDispositions() {
    this.dispositionCodesService
      .retrieveDispositions()
      .then((dispositionList) => {
        this.dispositionList = dispositionList;
        this.selectedDisposition = dispositionList[0];
      });
  }

  retrieveDispositionCategories(dispositionListId, organisationId){
    this.dispositionCodesService
      .retrieveDispositionListByIdAndOrganisationId(dispositionListId,organisationId)
      .then((dispositionCategoryList) => {
        this.dispositionCategoryList = dispositionCategoryList.categories;
        this.selectedDispositionCategory = dispositionCategoryList.categories[0];
        this.displayDispositionCategorySearchResults = true;
      });   
  }

  retrieveDispositionCodes(organisationId, dispositionListId){

    this.dispositionCodesService
      .retrieveDispositionList(organisationId, dispositionListId)
      .then((disposition) => {
        this.dispositionCodes = disposition.dispositionCodes? disposition.dispositionCodes: [] ;
        this.selectedDispositionCode = this.dispositionCodes.length > 0? this.dispositionCodes[0] : '' ;
        this.displayDispositionCodeSearchResults = true;
      });
    }
  

  showWorkTypeSearchResults(event) {
    event.stopPropagation();
    this.displayWorkTypesSearchResults = true;
    this.displayDispositionSearchResults = false;
    this.displayDispositionCodeSearchResults = false;
    this.displayDispositionCategorySearchResults = false;
    this.displayDispositionSubCategorySearchResults = false;
    this.displayMembersSearchResults = false;
    this.displaySearchableVariablesSearchResults = false;
  }

  showMemberSearchResults(event) {
    event.stopPropagation();
    this.displayMembersSearchResults = true;
    this.displayWorkTypesSearchResults = false;
    this.displayDispositionSearchResults = false;
    this.displayDispositionCodeSearchResults = false;
    this.displayDispositionCategorySearchResults = false;
    this.displayDispositionSubCategorySearchResults = false;
    this.displaySearchableVariablesSearchResults = false;
  }

  showSearchableVariablesSearchResults(event) {
    event.stopPropagation();
    this.displayMembersSearchResults = false;
    this.displayWorkTypesSearchResults = false;
    this.displayDispositionSearchResults = false;
    this.displayDispositionCodeSearchResults = false;
    this.displayDispositionCategorySearchResults = false;
    this.displayDispositionSubCategorySearchResults = false;
    this.displaySearchableVariablesSearchResults = true;
  }

  showDispositionSearchResults(event) {
    event.stopPropagation();
    this.displayDispositionSearchResults = true;
    this.displayWorkTypesSearchResults = false;
    this.displayDispositionCodeSearchResults = false;
    this.displayDispositionCategorySearchResults = false;
    this.displayDispositionSubCategorySearchResults = false;
    this.displayMembersSearchResults = false;
    this.displaySearchableVariablesSearchResults = false;
  }

  showDispositionCodeSearchResults(event) {
    event.stopPropagation();
    this.displayDispositionSearchResults = false;
    this.displayDispositionCodeSearchResults = true;
    this.displayWorkTypesSearchResults = false;
    this.displayDispositionCategorySearchResults = false;
    this.displayDispositionSubCategorySearchResults = false;
    this.displayMembersSearchResults = false;
    this.displaySearchableVariablesSearchResults = false;
  }

  showDispositionCategorySearchResults(event) {
    event.stopPropagation();
    this.displayDispositionSearchResults = false;
    this.displayWorkTypesSearchResults = false;
    this.displayDispositionCodeSearchResults = false;
    this.displayDispositionCategorySearchResults = true;
    this.displayDispositionSubCategorySearchResults = false;
    this.displayMembersSearchResults = false;
    this.displaySearchableVariablesSearchResults = false;
  }

  showDispositionSubCategorySearchResults(event) {
    event.stopPropagation();
    this.displayDispositionSearchResults = false;
    this.displayWorkTypesSearchResults = false;
    this.displayDispositionCodeSearchResults = false;
    this.displayDispositionCategorySearchResults=false;
    this.displayDispositionSubCategorySearchResults=true;
    this.displayMembersSearchResults = false;
    this.displaySearchableVariablesSearchResults = false;
  }

  showSearchOptions(event) {
    event.stopPropagation();
    this.displaySearchOptions = true;
  }

  closeWorkTypeDropdown() {
    setTimeout(() => {
      this.displayWorkTypesSearchResults = false;
    }, 250);
  }

  closeMemberDropdown() {
    setTimeout(() => {
      // this.displayMembersSearchResults = false;
    }, 250);
  }

  closeSearchableVariablesDropdown() {
    setTimeout(() => {
      this.displaySearchableVariablesSearchResults = false;
    }, 250);
  }

  closeDispositionDropdown() {
    setTimeout(() => {
      this.displayDispositionSearchResults = false;
    }, 250);
  }

  closeDispositionCodeDropdown() {
    setTimeout(() => {
      this.displayDispositionCodeSearchResults = false;
    }, 250);
  }

  closeDispositionCategoryDropdown() {
    setTimeout(() => {
      this.displayDispositionCategorySearchResults = false;
    }, 250);
  }

  closeDispositionSubCategoryDropdown() {
    setTimeout(() => {
      this.displayDispositionSubCategorySearchResults = false;
    }, 250);
  }

  closeSearchOptionsDropdown() {
    this.closeSearchTimeout = setTimeout(() => {
      this.displaySearchOptions = false;
    }, 250);
  }

  disableCloseSearchOptionsDropdown() {
    try {
      window.clearTimeout(this.closeSearchTimeout);
    } catch(e) {}
  }

  partialMatch(searchExpression, value, searchParam) {
    if (!searchExpression || searchExpression.length === 0) {
      return true;
    }
    return value[searchParam].toLowerCase().indexOf(searchExpression.toLowerCase()) >= 0;
  }

  selectWorkType(event, workType) {
    event.stopPropagation();
    if (!workType) {
      return;
    }
    this.workTypeCriteria = workType.templateName;
    this.selectedWorkType = workType;
    for (let workType of this.workTypes) {
      workType.isSelected = false;
      workType.isSelected = workType.templateId === this.selectedWorkType.templateId;
    }
    this.displayWorkTypesSearchResults = false;
  }


  selectMember(event, member) {
    event.stopPropagation();
    if (!member) {
      return;
    }
    this.memberCriteria = member.memberName;
    this.memberId = member.memberId;
    this.displayMembersSearchResults = false;
  }

  selectDisposition(event, disposition) {
    event.stopPropagation();
    if (!disposition) {
      return;
    }
    this.dispositionCriteria = disposition.name;
    this.selectedDisposition = disposition;
    for (let disposition of this.dispositionList) {
      disposition.isSelected = false;
      disposition.isSelected = disposition.id === this.selectedDisposition.id;
    }
    this.displayDispositionSearchResults = false;
    
    this.dispositionCodes = [];
    this.displayDispositionCodeSearchResults = false;
    this.dispositionCodeCriteria = '';
    
    this.dispositionCategoryList = [];
    this.displayDispositionCategorySearchResults = true;
    this.dispositionCategoryCriteria = '';

    this.dispositionSubCategoryList = [];
    this.displayDispositionSubCategorySearchResults = true;
    this.dispositionSubCategoryCriteria = ''

    if(disposition.tiered){
        this.retrieveDispositionCategories(disposition.id, this.sessionStore.get.organisation.organisationId);
    }else{
        this.retrieveDispositionCodes(this.sessionStore.get.organisation.organisationId, disposition.id);
    }

  }

  selectDispositionCode(event, dispositionCode) {
    
    event.stopPropagation();
    if (!dispositionCode) {
      return;
    }
    this.dispositionCodeCriteria = dispositionCode.description;
    this.selectedDispositionCode = dispositionCode;
    for (let dispositionCode of this.dispositionCodes) {
      dispositionCode.isSelected = false;
      dispositionCode.isSelected = dispositionCode.id === this.selectedDispositionCode.dispositionCodeId;
    }
    this.displayDispositionCodeSearchResults = false;

  }


  selectDispositionCategory(event, category) {
    event.stopPropagation();
    if (!category && !category.subcategories) {
      return;
    }

    this.dispositionCategoryCriteria = category.name;
    this.selectedDispositionCategory = category;
    this.dispositionSubCategoryList = category.subcategories.length > 0 ?  category.subcategories: [];
      
    this.displayDispositionCategorySearchResults = false;
    this.displayDispositionSubCategorySearchResults = true;


  }

  selectDispositionSubCategory(event, subCategory) {
    event.stopPropagation();
    if (!subCategory) {
      return;
    }
    this.dispositionSubCategoryCriteria = subCategory.name;
    this.selectedDispositionSubCategory = subCategory;
    for (let subCategory of this.dispositionSubCategoryList) {
      subCategory.isSelected = false;
      subCategory.isSelected = subCategory.name === this.selectedDispositionSubCategory.name;
    }
    this.displayDispositionSubCategorySearchResults = false;

  }
  
  selectSearchOption(event, searchType) {
    event.stopPropagation();
    this.sourceTargetOptions = searchType;
  }

  initializeDispositionCategoriesAndSubCategories(){

    this.selectedWorkType = '';
    this.displayWorkTypesSearchResults = false;
    this.workTypeCriteria = '';

    this.memberId = '';
    this.displayMembersSearchResults = false;
    this.memberCriteria = '';

    this.selectedDisposition='';
    this.displayDispositionSearchResults = false;
    this.dispositionCriteria='';
    this.selectedDispositionCode = '';
  
    this.selectedDispositionCode ='';
    this.displayDispositionCodeSearchResults = false;
    this.dispositionCodeCriteria='';

    this.dispositionCategoryList = [];
    this.selectedDispositionCategory = '';
    this.displayDispositionCategorySearchResults = false;
    this.dispositionCategoryCriteria = '';

    this.dispositionSubCategoryList = [];
    this.selectedDispositionSubCategory = '';
    this.displayDispositionSubCategorySearchResults = false;
    this.dispositionSubCategoryCriteria = '';

  }

  selectFlowType(item) {

    if (item.name === 'Office Flow' && this.channel !== '' && this.channel !== 'inbound' && this.channel !== 'outbound') {
      this.channel = '';
    }

    for (let channel of this.defaultChannels) {
      channel.isHidden = false;
      if (!channel.filter.includes(item.type) && item.type !== '') {
        channel.isHidden = true;
      }
    }
    this.clearSelectedChannels();
    this.clearSelectedOutcomes();

    if (item.type === 'office') {
      this.outcomes = this.defaultOutcomes.filter(outcome => {
        return outcome.type !== 'sent' && outcome.type !== 'served';
      });
    } else {
      this.outcomes = this.defaultOutcomes;
    }

    this.selectedType = item;
    this.callType = item.name;
    this.flowType = 'js-' + item.type; //
  }

  selectHasRecording(item) {
    if (item.value === 'na') {
      this.hasRecording = '';
    }
    this.hasRecording = item.value;
  }

  channelChange() {
    let outcomeList = this.defaultOutcomes.filter(outcome => {

      let found = this.defaultChannels.find(channel => {
        if (channel.checked) {
          if (outcome.filter.includes(channel.name)) {
            return true;
          }
        }
        return false;
      });

      return !!found;
    });
    this.outcomes = outcomeList;
    
    let hasSelected = this.defaultChannels.find(channel => channel.checked);
    if (!hasSelected) {
      this.outcomes = this.defaultOutcomes;
    }
    
    if (this.selectedType.type === 'office') {
      this.outcomes = this.outcomes.filter(outcome => {
        return outcome.type !== 'sent' && outcome.type !== 'served';
      });
    }
  }

  disableMemberSelectionForAgent() {
    if (this.sessionStore.get.user.hasAgentRole) {
      this.defaultSearchParams.memberId = this.sessionStore.get.user.memberId;
      this.memberId = this.defaultSearchParams.memberId;
      this.isMemberSelectionDisabled = true;
    }
  }

  selectSurveyType(item) {
    if (item.type === 'na') {
      this.surveyOutcome = '';
    }
    this.surveyType = item.type;
  }

  retrieveInteractions(filterParams, isScrolling) {

    if ((isScrolling && this.isListOver && this.itemList) || this.scrollLoader) {
      return;
    }

    this.defaultSearchParams = filterParams || this.defaultSearchParams;
    this.defaultSearchParams.page++;
    if (isScrolling) {
      this._toggleScrollLoader(true);
    } else {
      this._toggleReady(false);
    }
    this._retrieveAllInteractions();
  }

  scrollEnd() {
    this.retrieveInteractions(null, true);
  }

  showMore() {
    this._toggleScrollLoader(false);
    this.retrieveInteractions(null, true);
  }

  _retrieveAllInteractions() {
    
    let channels = this.defaultChannels.filter(channel => channel.checked).map(channel => {
      if (channel.name.toLowerCase() === 'instant message') {
        return 'INSTANT_MESSAGE';
      }
      return channel.name;
    });
    let outcomes = this.outcomes.filter(outcome => outcome.checked).map(outcome => outcome.name);

    this.interactionLogService.retrieveAllInteractions(this.defaultSearchParams, channels, outcomes, this.variables).then(
      response => {
        this._interactionsRetrieved(response);
      },
      error => {
        this._interactionsRetrievalFailed(error);
      }
    );
  }

  _retrieveAllMembers() {
    this.interactionLogService.retrieveOrganisationMembers().then(
      response => {
        this.members = [{ memberId: '', memberName: 'All' }];
        if (response) {
          let tmpMembers = [];
          response.forEach(member => {
            tmpMembers.push({ memberId: member.id, memberName: member.firstName + ' ' + member.surname });
          });
          tmpMembers.sort(function (a, b) {
            if (a.memberName.toLowerCase() > b.memberName.toLowerCase()) {
              return 1;
            }
            if (b.memberName.toLowerCase() > a.memberName.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          this.members = this.members.concat(tmpMembers);

          this.disableMemberSelectionForAgent();
        }
      },
      error => {
        this.members = [{ memberId: '', memberName: 'All' }];
      }
    )
  }

  _retrieveSearchabeVariables() {
    this.interactionLogService.retrieveSearchableVariables().then(
      response => {
        this.searchableVariables = response;
      }
    );
  }

  _interactionsRetrieved(response) {
    this.itemList = this.defaultSearchParams.page === 0 ? response.content : this.itemList.concat(response.content);
    this.totalElements = response.totalElements;
    this._toggleScrollLoader(false);
    this._toggleReady(true);
    this.checkIfListIsOver(response.content);
    if (this.placeholderService) {
      this.placeholderService.generatePlaceholders(this.itemList.length);
    }
    this.bindingSignaler.signal('interaction-log-items');
    if (this.viewInteractionId) {
      this.interactionLogService.retrieveInteraction(this.viewInteractionId)
        .then((res) => {
          this.viewInteraction(res.content[0]);
        })
        .catch((err) => {
          logger.error('DEBUG_SHOW_REC :: activate :: err=', err);
        });
    }
  }

  checkIfListIsOver(itemList) {
    if (itemList.length < this.defaultSearchParams.size) {
      this.isListOver = true;
    }
  }

  _interactionsRetrievalFailed(error) {
    this.ready = true;
    this.isListOver = true;
    this._toggleScrollLoader(false);
    this._toggleReady(true);
  }

  _toggleScrollLoader(value) {
    this.scrollLoader = value;
  }

  _toggleReady(value) {
    this.ready = value;
  }

  resetSearchFilter() {
    this.initializeDispositionCategoriesAndSubCategories();
    this.defaultSearchParams = {
      organisationId: null,
      fromDate: moment().subtract(30, "days").format(this.dateFormat),
      toDate: moment().format(this.dateFormat),
      callType: '',
      memberId: '',
      fromNumber: '',
      toNumber: '',
      surveyType: 'na',
      surveyOutcome: '',
      workTypeName: ''  ,
      dispositionId: '',
      dispositionCategory: '',
      dispositionSubCategory: '',
      hasRecording: 'na',
      size: 18,
    };
    this.fromOrTo = '';
    this.outcomes = this.defaultOutcomes;
    this.clearSelectedChannels();
    this.clearSelectedOutcomes();
    this.setupFilter();
    this.showAdvancedFilterOptions = false;
    this.variables = {};
  }

  resetFilter() {
    this.container.scrollTop = 0;
    this.resetSearchFilter();
    this._toggleScrollLoader(false);
    this.resetPageToTop();
    this.retrieveInteractions();
  }

  resetPageToTop() {
    this.defaultSearchParams.page = -1;
    this.totalElements = 0;
    this.isListOver = false;
  }

  refresh() {
    document.body.querySelector('.u-scroll-bar').scrollTop = 0;
    this.resetPageToTop();
    this.retrieveInteractions();
  }

  openSearchFilter() {
    this.showFilter = !this.showFilter;
    this.showAdvancedFilterOptions = this.defaultSearchParams.fromNumber || this.defaultSearchParams.toNumber
  }

  searchByFilter() {

    this.validation.validate().then((validation) => {

      if (!validation.valid) {
        return;
      }

      // const workType = this.workTypes.find(workType => workType.templateName === this.workTypeCriteria);
      // const disposition = this.dispositionList.find(disposition => disposition.name === this.dispositionCriteria);
      let filterCriteria = {
        fromDate: this.fromDate,
        toDate: this.toDate,
        organisationId: this.sessionStore.get.organisation.organisationId,
        callType: this.callType === 'All' ? '' : this.callType,
        flowType: this.flowType.replace('js-', ''),
        surveyType: this.surveyType.replace('js-', '').replace('-survey', ''),
        hasRecording: this.hasRecording.replace('js-', '').replace('-recording', ''),
        surveyOutcome: this.surveyOutcome.replace('js-', '').replace('-score', ''),
        channel: this.channel,
        outcome: this.outcome,
        memberId: this.memberId,
        selectedType: this.selectedType,
        workTypeName: this.workTypeCriteria? this.workTypeCriteria : null,
        dispositionId: this.dispositionCriteria && this.selectedDispositionCode? this.selectedDispositionCode.dispositionCodeId : '',
        dispositionCategory: this.dispositionCategoryCriteria ? this.dispositionCategoryCriteria : null,
        dispositionSubCategory: this.dispositionSubCategoryCriteria ? this.dispositionSubCategoryCriteria : null,
        size: this.size,
        page: -1
      };
      if (this.sourceTargetOptions === 'Source or Target') {
        filterCriteria.fromOrTo = this.fromOrTo;
      } else {
        filterCriteria.fromNumber = this.fromNumber;
        filterCriteria.toNumber = this.toNumber;
      }
      

      document.body.querySelector('.u-scroll-bar').scrollTop = 0;
      this.resetPageToTop();
      this.retrieveInteractions(filterCriteria);
      this.showFilter = false;

    }, (error) => {
      logger.info('Error > search =', error)
    });
  }

  cancelSearch() {
    this.selectedType = this.defaultSearchParams.selectedType;
    this.channel = this.defaultSearchParams.channel;
    this.surveyType = this.defaultSearchParams.surveyType;
    this.surveyOutcome = this.defaultSearchParams.surveyOutcome;
    this.hasRecording = this.defaultSearchParams.hasRecording;
    this.showFilter = false;
  }

  toggleAdvancedFilterOptions() {
    this.showAdvancedFilterOptions = !this.showAdvancedFilterOptions;
  }

  toggleVariablesFilters() {
    this.showVariablesFilters = !this.showVariablesFilters;
  }

  viewInteractionById(interaction){
    if (!interaction && !interaction.interactionId) {
      return;
    }

    this.interactionLogService.retrieveInteraction(interaction.interactionId)
      .then((res) => {
        const existingInteraction = res.content[0];
        existingInteraction.interactionId = interaction.interactionId;
        existingInteraction.type = interaction.type;
        this.viewInteraction(existingInteraction);

      })
      .catch((err) => {
        logger.error('DEBUG_SHOW_REC :: activate :: err=', err);
      });
  }

  viewInteraction(interaction) {
    if (interaction.isPlaceholder) {
      return;
    }
    this.dialogService
      .open({
        viewModel: ViewInteractionDialog,
        model: interaction
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          this.routeToPerformQaIfRequested(response);
        }
      });
  }

  startAutoCloseMenu(_delay) {
    this.autoCloseTimer = window.setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      // this.displaySearchResults = false;
    }, _delay);
  }

   preventAutoClose() {
    window.clearTimeout(this.autoCloseTimer);
  }

  routeToPerformQaIfRequested(response) {
    let performQAData = response.output;
    if (performQAData.performQA) {
      this.router.parent.navigate(`hud/qualityassessor/assessment/${performQAData.interactionId}?recordingId=${performQAData.recordingId}&callType=${performQAData.callType}&memberId=${performQAData.memberId}&firstName=${performQAData.firstName}&surname=${performQAData.surname}&channel=${performQAData.channel}`);
    }
  }
  
  partialPropertyMatch(searchText, item) {
    let _searchText = searchText.toLowerCase();
    if (item.titlePrimary && item.titlePrimary.toLowerCase().startsWith(_searchText)) {
      return true;
    } else if (item.titleSecondary && item.titleSecondary.toLowerCase().startsWith(_searchText)) {
      return true;
    } else if (item.from && item.from.toLowerCase().startsWith(_searchText)) {
      return true;
    } else if (item.to && item.to.toLowerCase().startsWith(_searchText)) {
      return true;
    }
    return false;
  }

  selectAllChannels() {
    this.defaultChannels.forEach(channel => channel.checked = true);
  }

  clearSelectedChannels() {
    this.defaultChannels.forEach(channel => channel.checked = false);
  }

  selectAllOutcomes() {
    this.outcomes.forEach(outcome => outcome.checked = true);
  }

  clearSelectedOutcomes() {
    this.outcomes.forEach(outcome => outcome.checked = false);
  }
  

  @computedFrom('user.hasAgentRole')
  get isAgent() {
    if (this.user) {
      return this.user.hasAgentRole;
    }
    return false;
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }
}
