import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { validateTrigger, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
/**/
import { observable } from 'aurelia-binding';
import { DialogService } from 'aurelia-dialog';
import { AudioFileModel, BootstrapFormRenderer, FetchAudioService } from 'zailab.common';
import { Validation } from 'zailab.validation';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
import { EmptyAudioAbstract } from '../../../abstracts/empty-audio-abstract';
import { AudioSearchFilter } from '../../../audio-search-filter';
import { WaitingRoomDialogService } from '../../waiting-room-dialog-service';
import { AUDIO_TOOLS } from '../../../../../../../../_common/tools/audio-tools';

const logger = LogManager.getLogger('WaitSound');

/**/
@autoinject
export class WaitSound extends EmptyAudioAbstract {

  public name: string = '';
  public languageVariableName: string = '';
  private validation: any;

  private mediaTypes: Array<{ frontEnd: string, backEnd: string }>;
  private wizard: ZIWizard;
  private selectedMediaType: { frontEnd: string, backEnd: string };

  @observable protected isAddingContent: boolean = false;

  private optionList: Array<ZSwitchRadioInterface> = [
    { label: 'Music', isSelected: true },
    { label: 'Ringing', isSelected: false },
    { label: 'Audio', isSelected: false }
  ];

  protected emptyStateConfig: ZIEmptyState = {
    icon: 'empty-state-audio.svg',
    heading: `Your organisation does not yet have any audio messages.`,
    description: `You can create an audio message by uploading a WAV file or creating a text-to-speech audio message.`,
    hasButton: true,
    buttonText: `Create Audio`,
    hasExternalContentCreator: true,
    externalContentCreatorURI: PLATFORM.moduleName('features/media/media/audiofiles/addaudio/add-audio-dialog'),
    hasTip: true,
    tipText: `Clicking this button will show the audio content creator.`
  };

  constructor(
    private waitingRoomDialogService: WaitingRoomDialogService,
    private element: Element,
    protected dialogService: DialogService,
    protected fetchAudioService: FetchAudioService,
    validationControllerFactory: ValidationControllerFactory,
    public audioSearchFilter: AudioSearchFilter,
  ) {
    super(dialogService, fetchAudioService, audioSearchFilter);
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.initMediaTypes();
    this.mapWizardData();
  }

  public attached(): void {
    this.initialiseValidation();
    this.retrieveAudioFiles();
  }

  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required()
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => this.wizard.continue());
  }

  private initMediaTypes(): void {
    this.mediaTypes = [
      { frontEnd: 'Music', backEnd: 'MOH' },
      { frontEnd: 'Ringing', backEnd: 'RING' },
      { frontEnd: 'Audio', backEnd: 'SOUND' }
    ];
  }

  private mapWizardData(): void {
    this.selectedMediaType = this.mediaTypes.filter(_entry => {
      return _entry.backEnd === this.wizard.data.queueMediaType
    })[0] || this.mediaTypes[0];
    this.name = this.wizard.data.name || '';
    this.selectedAudioMessage = this.wizard.data.audioMessages;
    this.preselectedAudioMessage = AUDIO_TOOLS.getSelectedAudioFromWizard(this.wizard);
    this.languageVariableName = this.wizard.data.languageVariableName;
    this.validate();
  }

  // Overrides

  protected selectExistingAudioMessage(audioMessage: AudioFileModel): void {
    super.selectExistingAudioMessage(audioMessage);
    this.validate();
  }

  protected toggleSearch(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = '';
    }
  }

  // Observable

  private isAddingContentChanged = (_newValue: boolean): void => {
    this.element.dispatchEvent(new CustomEvent(_newValue ? 'show-content-creator' : 'hide-content-creator', {
      bubbles: true,
      detail: {}
    }));
  };

  private selectQueueMediaType(_event: CustomEvent): void {
    _event.stopPropagation();

    this.selectedMediaType = this.mediaTypes.filter(_entry => {
      return _entry.frontEnd === _event.detail.active
    })[0];

    this.validate();
  }

  public languageVariableNameChanged(): void {
    this.validate();
  }

  private validate(): void {

    if (!this.name || this.selectedMediaType.backEnd === 'SOUND' && !this.selectedAudioMessage && !this.preselectedAudioMessage) {
      if (!this.preselectedAudioMessage) {
        this.wizard.step.incomplete();
      }
      return;
    }

    let audioMessages = AUDIO_TOOLS.getAudioMessages(this.selectedAudioMessage ? this.selectedAudioMessage : this.preselectedAudioMessage);

    this.wizard.step.complete({
      languageVariableName: this.languageVariableName,
      queueMediaType: this.selectedMediaType.backEnd,
      audioMessages,
      name: this.name
    });
  }

  public doNothing(): void {}
}
