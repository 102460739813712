import { DialogController } from 'aurelia-dialog';
import { LogManager, autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';

const logger = LogManager.getLogger('VerintTextAnalyticsDialog');

@autoinject
export class VerintTextAnalyticsDialog {
  private workforceManagementId: string;

  public tabs;

  constructor(
    public dialog: DialogController
  ) { }

  public activate(model: any): void {
    this.workforceManagementId = model.workforceManagementId;
    this.tabs = [
      { id: '1', name: 'Api Settings', viewModel: PLATFORM.moduleName('features/organisation/organisation/verint-analytics/text/verint-text-analytics-configuration'), data: { workforceManagementId: this.workforceManagementId } },
      { id: '2', name: 'Work Types', viewModel: PLATFORM.moduleName('features/organisation/organisation/verint-analytics/text/verint-text-analytics-worktype-configuration'), data: { workforceManagementId: this.workforceManagementId } },
      { id: '3', name: 'Field Mappings', viewModel: PLATFORM.moduleName('features/organisation/organisation/verint-analytics/text/verint-text-analytics-field-mappings'), data: { workforceManagementId: this.workforceManagementId } }
    ];
  }
}
