import {inject, LogManager} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
* */
import {SkillGroupsListService} from '../skill-groups-service';
import {SessionStore, BootstrapFormRenderer} from 'zailab.common';
/*
 * */
const logger = LogManager.getLogger('AddSkillGroupDialog');

/*
 * */
@inject(SkillGroupsListService, SessionStore, DialogController, ValidationControllerFactory)
export class AddSkillGroupDialog {

  skillGroupsList = [];
  skillGroupName = '';
  skillGroupFormGroup;
  dialogHeader = "Add Skill Group";

  constructor(skillGroupsListService, sessionStore, dialogController, validationControllerFactory) {
    this.skillGroupsListService = skillGroupsListService;
    this.sessionStore = sessionStore;
    this.dialogController = dialogController;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  /**
   * gets called when the dialog view model is activated.
   * @param object
   * */
  activate(skillGroupList) {
    this.skillGroupList = skillGroupList;
    this.initValidation();
  }

  initValidation() {

    ValidationRules
      .customRule('uniqueName', (value) => {
        if (this.skillGroupList.length > 0) {
          for (let skillGroup of this.skillGroupList) {
            if (skillGroup.name.toLowerCase() === value.toLowerCase()) {
              return false;
            }
          }
        }
        return true;
      });

    ValidationRules
      .ensure('skillGroupName')
      .required().withMessage('Please enter a skill group name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .on(this);
  }

  /**
   * Closes the Aurelia dialog
   */
  cancel() {
    this.skillGroupFormGroup.reset();
    this.dialogController.cancel();
  }

  addSkillGroup() {
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        return;
      }
      this.dialogController.ok(this.skillGroupName);
    }, error => {
      logger.info('addSkillGroup > validation failure', error);
    });

  }

}

