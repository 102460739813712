import { LogManager, autoinject, computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { SearchTools } from '../../../../../../zailab.common';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';
/**/
const logger = LogManager.getLogger('AddNodeDialog');

/**/
@autoinject
export class AddNodeDialog {
  public unconnectedExistingNodes: Array<object> = [];
  public availableNodes: Array<object> = [];
  private flowConfig: object;
  public isHovering: boolean;
  public isSearching: boolean = true;
  public searchTerm: string = '';

  constructor(
    private dialogController: DialogController,
    private zaiFileLoader: ZaiFileLoader
  ) {}

  public activate(_dialogConfig: {
    flowConfig: object;
    extensions: string[];
    unconnectedNodes: ZNode[];
  }): void {
    this.flowConfig = _dialogConfig.flowConfig;

    this.unconnectedExistingNodes = this.mapUnconnectedExistingNodes(
      _dialogConfig.unconnectedNodes
    );
    this.availableNodes = this.mapAvailableNodes(_dialogConfig.extensions);
  }

  private mapUnconnectedExistingNodes(nodes: ZNode[]): Array<object> {
    return nodes.map((node) => {
      const description: string = this.flowConfig[node.eventId].description;
      const name: string = this.flowConfig[node.eventId].name;
      const icon: string = this.flowConfig[node.eventId].icon;
      const iconClass: string = this.flowConfig[node.eventId].iconClass;
      const image: string = this.flowConfig[node.eventId].image;
      const propertiesName: string = node.properties.name;
      const externalReference: string = (node.properties as any)
        .externalReference;
      const searchValue = `${name} ${propertiesName}`;

      return {
        eventId: node.eventId,
        id: node.id,
        name,
        description,
        icon,
        iconClass,
        image,
        propertiesName,
        externalReference,
        searchValue,
      };
    });
  }

  private mapAvailableNodes(availableNodes: Array<string>): Array<object> {
    let _availableNodes_: Array<object> = [];
    availableNodes.forEach((eventId) => {
      if (eventId && this.flowConfig[eventId]) {
        let description: string = this.flowConfig[eventId].description;
        let name: string = this.flowConfig[eventId].name;
        let icon: string = this.flowConfig[eventId].icon;
        let iconClass: string = this.flowConfig[eventId].iconClass;
        let image: string = this.flowConfig[eventId].image;

        _availableNodes_.push({
          eventId,
          name,
          description,
          icon,
          iconClass,
          image,
        });
      }
    });

    return this.sort(_availableNodes_);
  }

  private sort(_availableNodes_: Array<object>): Array<object> {
    _availableNodes_.sort(
      (_firstObject: { name: string }, _secondObject: { name: string }) => {
        let _compareOne = _firstObject.name.toUpperCase(); // ignore upper and lowercase
        let _compareTwo = _secondObject.name.toUpperCase(); // ignore upper and lowercase
        if (_compareOne < _compareTwo) {
          return -1;
        }
        if (_compareOne > _compareTwo) {
          return 1;
        }
        // names must be equal
        return 0;
      }
    );
    return _availableNodes_;
  }

  public selectNode(selectedNode: string): void {
    this.dialogController.ok(selectedNode);
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public toggleNodeInfo(node: { isHovering: boolean }): void {
    node.isHovering = !node.isHovering;
  }

  public toggleSearch(): void {
    // this.isSearching = !this.isSearching;
  }

  public getImage(image: string): string {
    const imageContent = this.zaiFileLoader.getFile(image);
    if (imageContent) {
      return imageContent;
    }
    return './_assets/img/svg/' + image + '.svg';
  }

  public partialMatch(
    searchExpression: string,
    value: any,
    searchParam: string
  ): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  @computedFrom('searchTerm')
  public get isNoResultsExisting(): boolean {
    const results = this.unconnectedExistingNodes.filter((node) =>
      this.partialMatch(this.searchTerm, node, 'searchValue')
    );
    if (!results || !results.length) {
      return true;
    }
    return false;
  }

  @computedFrom('searchTerm')
  public get isNoResultsNew(): boolean {
    const results = this.availableNodes.filter((node) =>
      this.partialMatch(this.searchTerm, node, 'name')
    );
    if (!results || !results.length) {
      return true;
    }
    return false;
  }
}
