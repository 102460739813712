import { autoinject, LogManager } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import { v4 as uuidv4 } from 'uuid';
import { Oplog } from "../../../_common/services/database-service";
import { AllowedMatch, BypassCos, MATCHING_TYPES } from "./bypass-cos-model";

const logger = LogManager.getLogger('BypassCosService');

@autoinject
export class BypassCosService {

  constructor(
    private readonly http: HttpClient,
    private readonly oplog: Oplog,
  ) { }

  private get baseUrl(): string {
    return `/v1/organisation/me/transfer-whitelists`;
  }

  private syncRequest(op: OPERATION, id: string, runnable: () => void): Promise<any> {
    const oplogSubscription = this.oplog.subscribe()
      .withNameSpace('organisation-projector.transferWhitelistView')
      .withKeyField('_id')
      .withKeyValue(id);
    runnable();
    return oplogSubscription.when(op).then((data) => {
      oplogSubscription.unsubscribe();
      return data;
    });
  }

  private mapBypassCos(data: BypassCos): BypassCos {
    const item = new BypassCos();
    item.id = item.transferWhitelistId = data.id;
    item.name = data.name;
    item.allNumbersWhitelisted = item.allNumbersAllowed = data.allNumbersWhitelisted;
    item.state = data.allNumbersWhitelisted ? 'DISABLED' : 'ENABLED';
    item.businessPartner = data.businessPartner;
    item.contactCenter = data.contactCenter;
    item.whitelistNumbers = data.whitelistNumbers ? data.whitelistNumbers.map((data: AllowedMatch) => {
      const number = new AllowedMatch();
      number.id = number.whitelistNumberId = data.whitelistNumberId || data.id;
      number.matchingTypeSelected = MATCHING_TYPES.find((type) => type.value === data.matchType);
      number.matchType = data.matchType;
      number.number = data.number;
      return number;
    }) : [];
    return item;
  }

  public findAll(): Promise<Array<BypassCos>> {
    return this.http.createRequest(`${this.baseUrl}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response) {
          return null;
        }
        return response.map((data: BypassCos) => this.mapBypassCos(data));
      })
      .catch((e) => {
        if (e.statusCode === 404) {
          // not found do nothing
        } else {
          return e;
        }
      });
  }

  public findById(id: string): Promise<BypassCos> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asGet()
      .send()
      .then((response: any) => {
        if (!response) {
          return null;
        }
        return this.mapBypassCos(response);
      })
      .catch((e) => {
        if (e.statusCode === 404) {
          // not found do nothing
        } else {
          return e;
        }
      });
  }

  public delete(id: any): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${id}`)
      .asDelete()
      .send();
  }

  public async insert(data: BypassCos): Promise<any> {
    data.id = data.transferWhitelistId = uuidv4();
    return this.syncRequest(OPERATION.INSERT, data.id, () => {
      this.http.createRequest(`${this.baseUrl}`)
      // this.http.createRequest(`${this.baseUrl}/${data.id}`)
        .asPost()
        .withContent(data)
        .send();
    });
  }

  public async update(data: BypassCos): Promise<any> {
    data.id = data.transferWhitelistId || data.id;
    return this.syncRequest(OPERATION.UPDATE, data.id, () => {
      this.http.createRequest(`${this.baseUrl}/${data.id}`)
        .asPut()
        .withContent(data)
        .send();
    });
  }

  public async addAllowedMatch(id: any, data: AllowedMatch): Promise<any> {
    data.id = data.whitelistNumberId = uuidv4();
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/whitelist-numbers`)
        .asPost()
        .withContent(data)
        .send();
    });
  }

  public async removeAllowedMatch(id: any, data: AllowedMatch): Promise<any> {
    data.id = data.whitelistNumberId || data.id;
    return this.syncRequest(OPERATION.UPDATE, id, () => {
      this.http.createRequest(`${this.baseUrl}/${id}/whitelist-numbers/${data.id}`)
        .asDelete()
        .send();
    });
  }
}

enum OPERATION {
  INSERT = 'insert',
  UPDATE = 'update',
}
