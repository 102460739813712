import { inject, LogManager, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
/*
 */
import { SessionStore, SortTools } from 'zailab.common';
import { TeamsService } from './teams.service';
import { MembersService } from '../../../../features/organisation/member/members/members-service';
/*
 */
const logger = LogManager.getLogger('Teams');
/*
 */
@inject(Router, TeamsService, SessionStore, MembersService)
export class Teams {

  resolve;
  _pagesize = 12;
  currentPage;
  pages;
  hasSearched;
  searchText;
  allItems;
  showSearch;
  isLoading;

  constructor(router, teamsService, sessionStore, membersService) {

    this.router = router;
    this.sessionStore = sessionStore;
    this.teamsService = teamsService;
    this.membersService = membersService;
  }

  activate() {
    this.getMember();
  }

  getMember() {
    this.isLoading = true;
    this.membersService
      .retrieveMember(this.user.memberId)
      .then((member) => {
        this.teams = member.teams;
        if (!member.teams || member.teams.length === 0) {
          this.retrieveTeamMembers();
        } else {
          let team = member.teams[0];
          team.isSelected = true;
          this.retrieveTeamMembers(team.id);
        }
      })
  }

  retrieveTeamMembers(teamId) {
    this.allItems = [];

    if (!teamId) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    this.teamsService
      .retrieveMembers(teamId, undefined, undefined)
      .then(members => this.membersRetrieved(members))
      .catch(error => logger.warn('Failed to retrieve members due to cause', error));
  }

  membersRetrieved(members) {
    this.isLoading = false;

    if (!members) members = [];

    for (let member of members) {
        member.fullName = member.firstName + ' ' + member.surname;
    }

    this.allItems = members;
    this.sortList();
    this.initPages();
  }

  toggleTeams() {
    this.showTeams = !this.showTeams;
  }

  selectTeam(team) {

    for (let _team of this.teams) {
      _team.isSelected = false;
    }
    team.isSelected = true;

    this.retrieveTeamMembers(team.id);

    this.toggleTeams();
    this.selectedTeam = team;

  }

  @computedFrom('user.hasQAManagerRole')
  get showManageQualityAssessment() {
    return this.user.hasQAManagerRole;
  }

  @computedFrom('user.hasCampaignManagerRole')
  get isCampaignManager() {
    return this.user.hasCampaignManagerRole;
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  performQualityAssessment() {

    this.router.navigate('interactionlog');
  }

  manageQualityAssessment() {

    this.router.navigate('forms');
  }

  chooseReport() {
    this.router.navigate('reports');
  }

  viewReportSchedule() {
    this.router.navigate('reportschedule');
  }

  viewAlert() {
    this.router.navigate('alert');
  }

  get pagedItems() {

    if (!this.pages) {
      return [];
    }

    return this.pages[this.currentPage];
  }

  next() {

    this.currentPage++;
  }

  prev() {

    this.currentPage--;
  }

  get totalItems() {

    return this.allItems.length;
  }

  get showNext() {

    if (this.pages)
      return this.currentPage < this.pages.length - 1;
  }

  get showPrev() {

    return this.currentPage > 0;
  }

  initPages() {

    this.currentPage = 0;
    this.pages = [];

    if (!this.allItems) {
      this.pages.push([]);
      return;
    }

    let pageItems = null;

    for (let itemIndex = 0; itemIndex < this.allItems.length; itemIndex++) {
      if (itemIndex % this._pagesize === 0) {

        if (pageItems !== null) {
          this.pages.push(pageItems);
        }
        pageItems = [];
      }

      pageItems.push(this.allItems[itemIndex]);
    }

    if (pageItems !== null && pageItems.length > 0) {
      this.pages.push(pageItems);
    }
  }

  search() {

    if (!this.allItems || this.allItems.length === 0) {

      return;
    }

    if (!this.searchText || this.searchText.length === 0) {

      this.initPages();
      return;
    }

    let items = [];
    let startList = [];
    this.pages = [];

    for (let item of this.allItems) {

      if (this.startsWith(item, this.searchText)) {

        startList.push(item);
        item.added = true;
      } else {

        item.added = false;
      }
    }

    this.items = startList;

    let pageItems = null;

    for (let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {

      if (itemIndex % this._pagesize === 0) {

        if (pageItems !== null) {
          this.pages.push(pageItems);
        }
        pageItems = [];
      }

      pageItems.push(this.items[itemIndex]);
    }

    if (pageItems !== null && pageItems.length > 0) {
      this.pages.push(pageItems);
    }

    this.currentPage = 0;

  }

  startsWith(item, searchText) {

    let found = false;

    if (item['firstName'] && item['firstName'].toLowerCase().startsWith(searchText.toLowerCase())) {

      found = true;
    } else if (item['surname'] && item['surname'].toLowerCase().startsWith(searchText.toLowerCase())) {
      found = true;
    } else if (item['fullName'] && item['fullName'].toLowerCase().startsWith(searchText.toLowerCase())) {
      found = true;
    }

    return found;
  }

  setFocus(field) {

    let firstElement = document.getElementById(field);
    if (firstElement !== null) {
      firstElement.focus();
    } else {

      setTimeout(() => {

        this.setFocus(field);
      }, 100);
    }
  }

  toggleSearch() {

    this.showSearch = !this.showSearch;

    setTimeout(() => {

      this.setFocus('searchText');
    }, 500);
  }

  sortList() {

    // Sorts list by QA roles first (alphabetical by fullName) and Agent roles last (alphabetical by fullName)

    let qaList = [];
    let agentList = [];

    for (let member of this.allItems) {

      member.fullName = `${member.firstName} ${member.surname}`;

      if (member.roleName === 'Quality Assessor') {
        qaList.push(member);
      } else {
        agentList.push(member);
      }
    }

    qaList.sort(SortTools.compareBy('fullName'));
    agentList.sort(SortTools.compareBy('fullName'));

    this.allItems = qaList.concat(agentList);
  }

  selectMember(member) {
    if (!member._roles || member._roles.length === 0 || member._roles.includes('Quality Assessor')) return;

    let memberId = member.memberId;

    if (!memberId) {
      return;
    }

    this.router.navigate(`forms/view/${memberId}?membername=${member.fullName}`);
  }

  viewAnalytics() {
    this.router.navigate('analytics');
  }
  
  @computedFrom('sessionStore.get.user')
  get isQAManager() {
    return this.sessionStore.get.user.hasQAManagerRole;
  }

}
