import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { computedFrom, LogManager } from 'aurelia-framework';
//@ts-ignore
import moment from 'moment-timezone';
//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import {
  ArrayTools,
  DownloadService,
  SessionStore,
  SortTools,
} from 'zailab.common';
import { TelephoneNumberTypes } from '../../../components/atoms/inputs/phonenumber/telephone-number-type';
import { TelephoneNumberModel } from '../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { ConversationFactory } from '../conversation/conversation-factory';
import { ConversationModel } from '../conversation/conversation-model';
import { ConversationService } from '../conversation/conversation-service';
import { InteractionModel } from '../conversation/interaction-model';
import { TicketModel } from '../conversation/interaction/ticket-model';
import { CallInteractionCardService } from '../conversation/interactioncards/call/call-interaction-card-service';
import { MemberModel } from '../conversation/member-model';
import { WaypointModel } from '../conversation/waypoint-model';
import { WorkTypesService } from '../conversation/worktypes/work-types-service';
import { WrapUpInteractionModel } from '../interactions/wrap-up-interaction-model';
import { TransferRecipient } from '../transferlist/transfer-list-model';
import { TransferListService } from '../transferlist/transfer-list-service';
import { ContactService } from './contact-service';
import { ChannelViewStrategyModel } from './contactcontroller/channel-view-strategy-model';
import {
  CONTACT_ACTIONS,
  CONVERSATION_ACTIONS,
  INTERACTION_ACTIONS,
  LOADER_ACTIONS,
} from './contactcontroller/contact-controller-actions';
import { ContactDirectorViewStrategies } from './contactcontroller/contact-director-view-strategies';
import { ContactDirectorViewStrategyModel } from './contactcontroller/contact-director-view-strategy-model';
import { CustomFieldModel } from './custom-field-model';
import { CustomFieldValueModel } from './custom-field-value-model';
import { LoadersModel } from './loaders-model';
import { SearchContactModel } from './searchcontact/search-contact-model';
import { TaskModel } from './task-model';
import { DoNotContactState } from '../donotcontact/donotcontact-state';

const logger = LogManager.getLogger('ContactModel');

interface ISubscription {
  dispose(): void;
  unsubscribe(): void;
  on(event: string, callBack: any): void;
}

interface IEventSubscriptions {
  contactInformationChanged: ISubscription;
  conversationListChanged: ISubscription;
  conversationListInsert: ISubscription;
  createConversation: ISubscription;
  unlinkedInteractionListChanged: ISubscription;
  unlinkedInteractionChanged: ISubscription;
}

export class ContactModel {
  private idToCheck: string = uuidv4();

  public _id: string = null;
  public title: string = null;
  public firstName: string = null;
  public surname: string = null;
  public lastName: string = null;
  public externalReference: string = null;
  public crmInformation: { name: string; externalId: string; url: string } =
    null;
  public conversationIsCreated = false;
  public emails: Array<{ type: string; email: string; isValid?: boolean, isDnc?:boolean }> = [];
  public telephoneNumbers: TelephoneNumberModel[] = [];
  public smsFromNumbers: TelephoneNumberModel[] = [];
  public conversations: ConversationModel[] = [];
  public openConversations: ConversationModel[] = [];
  public closedConversations: ConversationModel[] = [];
  public unmodelledOpenedConversations: ConversationModel[] = [];
  public unmodelledClosedConversations: ConversationModel[] = [];
  public unlinkedInteractions: Array<InteractionModel | TicketModel> = [];
  public unlinkedInteractionToSelect: InteractionModel | TicketModel = null;
  public wrapUpInteractions: WrapUpInteractionModel[] = [];
  public viewStrategy: ContactDirectorViewStrategyModel = null;
  public previousViewStrategy: ContactDirectorViewStrategyModel = null;
  public isSelected = false;
  public hasTask = false;
  public onWrapUp = false;
  public hasContactInfo = false;
  public loaders: LoadersModel;
  public taskInfo: TaskModel;
  public showDispositionCode = false;
  public showDispositionList = false;
  public interactionIdToSelect: string = null;
  public conversationIdToSelect: string = null;
  public editedContact: ContactModel = null;
  public isPresentOnBackEnd = true;
  public searchContactResults: ContactModel[] = [];
  public searchContactParams: SearchContactModel;
  public contactInfoDebounce: number = null;
  public correlationId: string = uuidv4();
  public organisationTransferMembers: Array<MemberModel> = [];
  public organisationWorktypeColorConfig: {
    [key: string]: { color: string; value: number }[];
  } = null;
  public organisationWorkTypes: Array<any> = [];
  public organisationTransferRecipients: any = [];
  public selectedUnlinkedInteraction: InteractionModel | TicketModel = null;
  public selectedConversation: ConversationModel = null;
  public dispositionList: any = null;
  public dispositionInteractionId: string = null;

  public servedInteraction: InteractionModel | TicketModel = null;
  public servedInteractions: Array<InteractionModel | TicketModel> = [];
  public servedUnlinkedInteraction: InteractionModel | TicketModel = null;
  public servedUnlinkedInteractions: Array<InteractionModel | TicketModel> = [];

  private recentlyLinkedInteractionIds = [];
  public customFieldsProperties: Array<CustomFieldModel> = [];
  public customFields: Array<CustomFieldValueModel> = [];

  public activeConversation: ConversationModel;
  public conversationsHistoryVisible = false;

  public unhandledActions: Array<any> = [];

  public conversationsMaxCount = 10;
  public openConversationsIncrementAmount = 10;
  public closedConversationsIncrementAmount = 10;

  private oplogSubscriptions: IEventSubscriptions = {
    contactInformationChanged: null,
    conversationListChanged: null,
    conversationListInsert: null,
    createConversation: null,
    unlinkedInteractionListChanged: null,
    unlinkedInteractionChanged: null,
  };
  private contactInfoOplogSubscription: Subscription;
  private unlinkedInteractionsOplogSubscription: Subscription;
  public hasChange = false;
  public longestActiveInteractionColor: string;
  public longestActiveInteractionTime: number;
  public longestActiveInteraction: string;

  public dncChecked = false;

  constructor(
    private contactService: ContactService,
    private conversationService: ConversationService,
    private conversationFactory: ConversationFactory,
    private eventAggregator: EventAggregator,
    public channelViewStrategy: ChannelViewStrategyModel,
    private downloadService: DownloadService,
    public contactId: string,
    private sessionStore: SessionStore,
    private callInteractionCardService: CallInteractionCardService,
    private workTypeService: WorkTypesService,
    private transferListService: TransferListService,
    private dncState: DoNotContactState,
  ) {
    this.loaders = new LoadersModel();
    this.taskInfo = new TaskModel();
    this.searchContactParams = new SearchContactModel();
  }

  public async populateContactCard(
    autoSelect?: boolean,
    ignoreLinking?: boolean
  ): Promise<void> {
    this.populateContactInformation();
    await this.populateUnlinkedInteractions(autoSelect);
    await this.populateConversations(autoSelect, ignoreLinking);
    this.retrieveOrganisationWorkTypes();
    this.retrieveCustomFields();
    this.retrieveOrganisationMemberTransferList();
    this.retrieveOrganisationWorktypeColorConfig();
    this.retrieveSMSFromNumbers();
  }

  public async populateContactInformation(): Promise<void> {
    if (this.oplogSubscriptions.contactInformationChanged) {
      return;
    }
    this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
      contactId: this.contactId,
      status: true,
      loader: 'contactInfo',
    });
    this.subscribeToContactChanges();

    let contact = await this.contactService.retrieveContact(this.contactId);
    this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
      contactId: this.contactId,
      status: false,
      loader: 'contactInfo',
    });
    if (contact) {
      this.mapContactInfo(contact);
    } else {
      if (this.servedInteraction.externalReference) {
        this.injectContactData(this.servedInteraction);
      }
      this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
        viewStrategy: 'searchContactUnknownContact',
        contactId: this.contactId,
        correlationId: this.correlationId,
      });
    }
  }

  public async populateConversations(
    autoSelect?: boolean,
    ignoreLinking?: boolean
  ): Promise<void> {
    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | populateConversations | ignoreLinking = ',
      ignoreLinking
    );

    return new Promise((resolve) => {
      let interactionLinkedToConversation =
        this.servedInteraction && this.servedInteraction.conversationId
          ? this.servedInteraction
          : null;
      let activeConversationId = this.servedInteraction
        ? interactionLinkedToConversation
          ? interactionLinkedToConversation.conversationId
          : this.conversationIdToSelect
        : null;

      if (
        this.servedInteractions &&
        this.servedInteractions.length > 0 &&
        interactionLinkedToConversation
      ) {
        activeConversationId = interactionLinkedToConversation.conversationId;
      }

      if (this.conversations && this.conversations.length > 0) {
        return resolve(
          this.conversationsRetrieved(
            activeConversationId,
            autoSelect,
            ignoreLinking
          )
        );
      }
      this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
        contactId: this.contactId,
        status: true,
        loader: 'conversationList',
      });

      this.conversationService
        .retrieveConversations(this.contactId)
        .then(async (conversations: ConversationModel[]) => {
          logger.debug(
            this.getCurrentFormattedTime() +
              ' - CC | populateConversations | conversations retrieved ',
            conversations.length
          );
          await this.mapConversations(conversations);

          this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
            contactId: this.contactId,
            status: false,
            loader: 'conversationList',
          });

          return resolve(
            this.conversationsRetrieved(
              activeConversationId,
              autoSelect,
              ignoreLinking
            )
          );
        })
        .catch((error) => {
          this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
            contactId: this.contactId,
            status: false,
            loader: 'conversationList',
          });

          logger.debug('Retrieving Contact Conversation > Error = ', error);
          this.selectionStrategy('conversations');
          resolve();
        });
    });
  }

  private conversationsRetrieved(
    activeConversationId: string,
    autoSelect?: boolean,
    ignoreLinking?: boolean
  ): Promise<void> {
    return new Promise((resolve) => {
      if (activeConversationId) {
        if (this.findTask()) {
          let convo =
            this.openConversations.find(
              (conversation) =>
                conversation.conversationId === activeConversationId
            ) || this.activeConversation;
          if (!convo) {
            convo =
              this.closedConversations.find(
                (conversation) =>
                  conversation.conversationId === activeConversationId
              ) || this.activeConversation;
          }
          if (convo) {
            this.activeConversation = convo;
          }
        }
        if (this.activeConversation) {
          this.openConversations = this.openConversations.filter(
            (convo) =>
              convo.conversationId !== this.activeConversation.conversationId
          );
          this.closedConversations = this.closedConversations.filter(
            (convo) =>
              convo.conversationId !== this.activeConversation.conversationId
          );
          this.selectConversation(this.activeConversation.conversationId);
          this.activeConversation.select();
          this.activeConversation.selectInteraction(
            this.servedInteractions[this.servedInteractions.length - 1]
              .interactionId
          );
        }
      } else if (autoSelect) {
        this.selectionStrategy('conversations');
      }
      if (ignoreLinking) {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | populateConversations | conversations retrieved | checking selection rules '
        );
        this.conversationItemsSelectionStrategy(activeConversationId);
      } else {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | populateConversations | conversations retrieved | checking linking rules '
        );
        this.conversationItemsLinkingStrategy();
      }
      resolve();
    });
  }

  private conversationItemsSelectionStrategy(
    conversationToSelect: string
  ): void {
    if (conversationToSelect) {
      this.selectConversation(conversationToSelect);
    } else if (this.activeConversation) {
      this.selectConversation(this.activeConversation.conversationId);
    } else if (this.openConversations.length === 1) {
      this.selectConversation(this.openConversations[0].conversationId);
    } else if (this.unlinkedInteractions.length > 0) {
      this.selectUnlinkedInteraction(
        this.unlinkedInteractions[0].interactionId
      );
    } else if (this.openConversations.length > 0) {
      this.selectConversation(this.openConversations[0].conversationId);
    } else if (this.closedConversationsLength > 0) {
      this.selectConversation(this.closedConversations[0].conversationId);
    } else {
      this.selectionStrategy('emptyContact');
    }
  }

  public findTask(): InteractionModel {
    for (let index = this.servedInteractions.length - 1; index >= 0; index--) {
      let interaction = this.servedInteractions[index];
      if (
        interaction instanceof InteractionModel &&
        (interaction.channel === 'campaign' || interaction.type === 'campaign')
      ) {
        return interaction;
      }
    }
    return null;
  }

  private fromSelectionStrategy = {
    conversations: false,
    unlinkedInteractions: false,
  };
  public static UNHANDLED_ACTIONS = {
    CONVERSATIONS: 'conversations', // conversation auto select,
    LOADER_CONVERSATIONS: 'conversationList',
  };
  public selectionStrategy(from: string): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | change selectionStrategy to',
      from
    );

    this.fromSelectionStrategy[from] = true;
    if (from === ContactModel.UNHANDLED_ACTIONS.CONVERSATIONS) {
      this.addUnhandledAction(from, () => this.selectionStrategy(from));
    }

    //@ts-ignore
    if (Object.values(this.fromSelectionStrategy).every(Boolean)) {
      this.eventAggregator.publish(CONTACT_ACTIONS.AUTOSELECT, {
        contactId: this.contactId,
        correlationId: this.correlationId,
      });
    }
  }

  public showConversationHistory(): void {
    this.conversationsHistoryVisible = true;
  }

  public hideConversationHistory(): void {
    this.conversationsHistoryVisible = false;
  }

  private addUnhandledAction(trigger: string, action: any): void {
    let item = this.unhandledActions.find((item) => item.trigger === trigger);
    if (!item) {
      this.unhandledActions.push({
        trigger,
        action,
      });
    }
  }

  public removeUnhandledAction(trigger: string): void {
    let item = this.unhandledActions.find((item) => item.trigger === trigger);
    if (item) {
      this.unhandledActions.splice(item, 1);
    }
  }

  public handleUnhandledActions(): void {
    let promises = [];
    this.unhandledActions.forEach((item) => promises.push(item.action()));
    Promise.all(promises).then(() => {
      this.unhandledActions = [];
    });
  }

  public conversationItemsLinkingStrategy(): void {
    let conversations = this.openConversations;
    if (conversations.length === 0) {
      let convo = this.activeConversation;
      if (
        convo &&
        (!convo.status ||
          convo.status.toLowerCase() === 'pending' ||
          convo.status.toLowerCase() === 'new')
      ) {
        conversations = [convo];
      }
    }
    if (
      this.servedInteractions[0] &&
      this.servedInteractions[this.servedInteractions.length - 1]
        .isLinkingToConversation
    ) {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | populateConversations | conversations retrieved | checking linking rules |  ',
        {
          hasAServedInteraction: !!this.servedInteractions[0],
          isAlreadyLinking:
            this.servedInteractions.length > 0
              ? this.servedInteractions[this.servedInteractions.length - 1]
              : false,
        }
      );
      return;
    }
    this.servedInteractions.forEach((_interaction) => {
      _interaction.isLinkingToConversation = true;
    });

    if (conversations.length === 0) {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | populateConversations | conversations retrieved | checking linking rules | no open conversations '
      );
      this.eventAggregator.publish(
        CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION,
        this.servedInteractions
      );
    } else if (conversations.length === 1) {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | populateConversations | conversations retrieved | checking linking rules | 1 open conversations '
      );
      this.servedInteractions.forEach((interaction) => {
        if (interaction.conversationId) {
          logger.debug(
            this.getCurrentFormattedTime() +
              ' - CC | populateConversations | conversations retrieved | checking linking rules | 1 open conversations | already has conversationId '
          );
          return;
        }
        if (
          interaction.channel === 'campaign' ||
          interaction.channel === 'chat' ||
          interaction.channel === 'instant_message'
        ) {
          this.eventAggregator.publish(
            CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION,
            interaction
          );
        } else {
          logger.debug(
            this.getCurrentFormattedTime() +
              ' - CC | populateConversations | conversations retrieved | checking linking rules | 1 open conversations | attempting selection and linking '
          );
          this.deselectAllUnlinkedInteractions();
          if (this.findTask()) {
            this.activeConversation = conversations[0];
            this.openConversations = this.openConversations.filter(
              (_conversation) =>
                _conversation.conversationId !==
                this.activeConversation.conversationId
            );
            this.closedConversations = this.closedConversations.filter(
              (_conversation) =>
                _conversation.conversationId !==
                this.activeConversation.conversationId
            );
          }
          this.selectConversation(conversations[0].conversationId);
          interaction.linkToConversation(conversations[0].conversationId);
          this.unlinkedInteractions = this.unlinkedInteractions.filter(
            (_interaction) =>
              _interaction.interactionId !==
              this.servedInteraction.interactionId
          );
          this.changeViewStrategy('interactions');
        }
      });
    } else {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | populateConversations | conversations retrieved | checking linking rules | multiple open conversations '
      );

      let campaignInteractions = this.servedInteractions.filter(
        (interaction) => interaction.channel === 'campaign'
      );
      if (campaignInteractions && campaignInteractions.length > 0) {
        campaignInteractions.forEach((interaction) =>
          this.eventAggregator.publish(
            CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION,
            interaction
          )
        );
        return;
      }

      this.servedInteractions.forEach((interaction) => {
        if (interaction.conversationId) {
          this.selectConversation(interaction.conversationId);
          return;
        }
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | populateConversations | conversations retrieved | checking linking rules | multiple open conversations | channel' +
            interaction.channel
        );
        if (
          interaction.channel === 'campaign' ||
          interaction.channel === 'chat' ||
          interaction.channel === 'instant_message'
        ) {
          this.eventAggregator.publish(
            CONVERSATION_ACTIONS.CREATE_AND_LINK_INTERACTION,
            interaction
          );
        } else {
          if (interaction.conversationId) {
            let conversationsToLinkTo = conversations.filter(
              (conversation) =>
                conversation.conversationId === interaction.conversationId
            );
            if (conversationsToLinkTo.length > 0) {
              let conversationToLinkTo = conversationsToLinkTo[0];
              interaction.linkToConversation(
                conversationToLinkTo.conversationId
              );
            }
          } else {
            this.servedInteractions.forEach((_interaction) => {
              _interaction.isLinkingToConversation = false;
            });
            let unlinkedInteraction = this.unlinkedInteractions.find(
              (_interaction) =>
                _interaction.interactionId === interaction.interactionId
            );
            if (unlinkedInteraction) {
              logger.debug(
                this.getCurrentFormattedTime() +
                  ' - CC | populateConversations | conversations retrieved | checking linking rules | multiple open conversations | selecting unlinked '
              );
              this.selectUnlinkedInteraction(unlinkedInteraction.interactionId);
              if (this.activeConversation) {
                this.activeConversation = null;
              }
            } else {
              logger.debug(
                this.getCurrentFormattedTime() +
                  ' - CC | populateConversations | conversations retrieved | checking linking rules | multiple open conversations | unlinked not yet existing '
              );
              this.unlinkedInteractionToSelect = unlinkedInteraction;
              this.eventAggregator.publish(
                CONVERSATION_ACTIONS.UNLINKED_INTERACTION_TO_SELECT,
                interaction
              );
              this.changeViewStrategy('interactions');

              setTimeout(() => {
                let unlinkedInteraction = this.unlinkedInteractions.find(
                  (_interaction) =>
                    _interaction.interactionId === interaction.interactionId
                );
                if (!unlinkedInteraction && this.conversations.length > 0) {
                  this.selectConversation(conversations[0].conversationId);
                }
              }, 2000);
            }
          }
        }
      });
    }
  }

  public addServedInteraction(
    interaction: InteractionModel | TicketModel
  ): void {
    if (!interaction) {
      return;
    }
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | addServedInteraction ',
      interaction
    );
    this.servedInteraction = interaction;
    if (!this.servedInteractions) {
      this.servedInteractions = [];
    }
    this.servedInteractions.push(interaction);
  }

  public async addServedUnlinkedInteraction(
    interaction: InteractionModel | TicketModel
  ): Promise<void> {
    if (
      !interaction ||
      this.servedUnlinkedInteractions.find(
        (_interaction) =>
          _interaction.interactionId === interaction.interactionId
      )
    ) {
      return;
    }
    interaction.correlationId = this.correlationId;
    this.servedUnlinkedInteraction = interaction;
    this.servedUnlinkedInteractions.unshift(interaction);
    let rawData = await this.conversationService.retrieveInteraction(
      interaction.interactionId
    );
    // @ts-ignore
    interaction.mapInteraction(rawData);
    if (rawData && rawData.journey && rawData.journey.waypoints) {
      interaction.journey = rawData.journey.waypoints.map(
        (waypoint) => new WaypointModel(waypoint)
      );
    }
  }

  public populateSearchParams(
    interaction: InteractionModel | TicketModel,
    autoLink?: boolean
  ): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | populateSearchParams ',
      interaction
    );
    let searchText = '';
    let email = '';
    let number = '';
    if (interaction.direction && interaction.direction === 'OUTBOUND') {
      searchText = interaction.to;
    } else {
      searchText = interaction.from;
    }

    let callSearchText = () => {
      this.addNewTelephoneNumber(searchText, TelephoneNumberTypes.WORK);
      number = searchText;
    };

    let contactStrategy = {
      call: callSearchText,
      voice: callSearchText,
      'inbound call': callSearchText,
      'outbound call': callSearchText,
      'flow call': callSearchText,
      'inbound flow call': callSearchText,
      'outbound flow call': callSearchText,
      'internal call': callSearchText,
      email: () => {
        if (!this.emails.some((email) => email.email === searchText)) {
          this.emails.push({ email: searchText, isValid: true, type: '' });
        }
        email = searchText;
      },
      sms: () =>
        this.addNewTelephoneNumber(searchText, TelephoneNumberTypes.MOBILE),
      callback: callSearchText,
      campaign: callSearchText,
      instant_message: callSearchText,
      chat: callSearchText,
    };
    if (contactStrategy[interaction.channel]) {
      contactStrategy[interaction.channel]();
    }
    this.searchContactParams.searchText = searchText;
    this.searchContactParams.unknownContactDetails = searchText;

    logger.debug(
      this.getCurrentFormattedTime() +
        ' - CC | populateSearchParams  | searchText',
      searchText
    );
    this.searchContacts(
      searchText,
      number,
      email,
      interaction.numberOfContacts === null ? 1 : interaction.numberOfContacts,
      autoLink
    );
  }

  private addNewTelephoneNumber(searchText: string, type: string): void {
    let number = new TelephoneNumberModel(searchText, type, '');
    if (!this.hasPhoneNumber(number)) {
      this.telephoneNumbers.push(number);
    }
  }

  private hasPhoneNumber(number: TelephoneNumberModel): boolean {
    return this.telephoneNumbers.some((telephoneNumber) =>
      telephoneNumber.equals(number)
    );
  }

  public populateUnlinkedInteractions(autoSelect?: boolean): Promise<void> {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | populateUnlinkedInteractions '
    );

    this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
      contactId: this.contactId,
      status: true,
      loader: 'unlinkedInteractionList',
    });
    this.loaders.unlinkedInteractionList = true;
    this.subscribeToUnlinkedInteractionChanges();

    return new Promise((resolve) => {
      this.queryUnlinkedInteractions(
        (interactions: Array<InteractionModel>) => {
          this.unlinkedInteractions = interactions.filter(
            (interaction) =>
              !this.recentlyLinkedInteractionIds.includes(
                interaction.interactionId
              )
          );
          this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
            contactId: this.contactId,
            status: false,
            loader: 'unlinkedInteractionList',
          });
          logger.debug(
            this.getCurrentFormattedTime() +
              ' - CC | populateUnlinkedInteractions | unlinked interactions retrieved ',
            this.unlinkedInteractions
          );

          if (autoSelect) {
            logger.debug(
              this.getCurrentFormattedTime() +
                ' - CC | populateUnlinkedInteractions | unlinked interactions retrieved | auto selecting '
            );
            if (this.servedInteraction) {
              let interactionToSelect = this.getUnlinkedInteractionWithId(
                this.servedInteraction.interactionId
              );

              if (interactionToSelect) {
                logger.debug(
                  this.getCurrentFormattedTime() +
                    ' - CC | populateUnlinkedInteractions | unlinked interactions retrieved | auto selecting | selecting '
                );
                this.eventAggregator.publish(INTERACTION_ACTIONS.SELECT, {
                  interaction: interactionToSelect,
                  contactId: this.contactId,
                });
              } else if (
                this.unlinkedInteractions &&
                this.unlinkedInteractions.length > 0
              ) {
                let selectedConversation = this.getSelectedConversation();
                if (!selectedConversation) {
                  logger.debug(
                    this.getCurrentFormattedTime() +
                      ' - CC | populateUnlinkedInteractions | unlinked interactions retrieved | auto selecting | selecting '
                  );
                  this.eventAggregator.publish(INTERACTION_ACTIONS.SELECT, {
                    interaction: this.unlinkedInteractions[0],
                    contactId: this.contactId,
                  });
                }
              }
            }
          }
          resolve();
        },
        (error: string) => {
          this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
            contactId: this.contactId,
            status: false,
            loader: 'unlinkedInteractionList',
          });
          this.selectionStrategy('unlinkedInteractions');
          resolve();
        }
      );
    });
  }

  public subscribeToUnlinkedInteractionChanges(): void {
    this.unlinkedInteractionsOplogSubscription &&
      this.unlinkedInteractionsOplogSubscription.dispose();

    this.unlinkedInteractionsOplogSubscription = this.eventAggregator.subscribe(
      'unlinked-interactions-oplog-update:' + this.contactId,
      () => this.handleUnlinkedInteraction()
    );
  }

  private handleUnlinkedInteraction(): void {
    this.queryUnlinkedInteractions(
      (interactions: Array<InteractionModel | TicketModel>) => {
        let newUnlinkedInteraction;
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | unlinkedInteractions | oplog update ',
          {
            interactions,
            unlinkedInteractions: this.unlinkedInteractions,
            newLength: interactions.length,
            oldLength: this.unlinkedInteractions.length,
            unlinkedInteractionToSelect: this.unlinkedInteractionToSelect,
          }
        );

        interactions.forEach((interaction) => {
          let existing = this.unlinkedInteractions.find(
            (unlinked) => interaction.interactionId === unlinked.interactionId
          );
          if (existing) {
            if (existing instanceof InteractionModel) {
              existing.mapRecording(existing);
            }
          } else {
            newUnlinkedInteraction = interaction;
            logger.debug(
              this.getCurrentFormattedTime() +
                ' - CC | unlinkedInteractions | oplog update | newUnlinkedInteraction ',
              newUnlinkedInteraction
            );
          }
        });
        let servedInteraction = this.servedInteractions.find((_interaction) => {
          return (
            (newUnlinkedInteraction &&
              _interaction.interactionId ===
                newUnlinkedInteraction.interactionId) ||
            (this.unlinkedInteractionToSelect &&
              _interaction.interactionId ===
                this.unlinkedInteractionToSelect.interactionId)
          );
        });
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | unlinkedInteractions | oplog update | checking if served ',
          {
            servedInteractions: this.servedInteractions,
            servedInteraction,
            unlinkedInteractionToSelect: this.unlinkedInteractionToSelect,
          }
        );
        if (
          newUnlinkedInteraction &&
          !servedInteraction.conversationId &&
          !servedInteraction.isLinkingToConversation &&
          !servedInteraction.isLinkedToConversation
        ) {
          logger.debug(
            this.getCurrentFormattedTime() +
              ' - CC | unlinkedInteractions | oplog update | adding unlinked interaction'
          );
          this.unlinkedInteractions.unshift(newUnlinkedInteraction);
          this.eventAggregator.publish(
            INTERACTION_ACTIONS.SELECT_UNLINKED,
            newUnlinkedInteraction
          );
        }

        if (
          this.unlinkedInteractionToSelect &&
          servedInteraction &&
          servedInteraction.interactionId ===
            this.unlinkedInteractionToSelect.interactionId
        ) {
          this.eventAggregator.publish(
            INTERACTION_ACTIONS.SELECT_UNLINKED,
            servedInteraction
          );
        }
        this.unlinkedInteractionToSelect = null;
        // }, 10);
      },
      (error: string) => {}
    );
  }

  private queryUnlinkedInteractions(
    successCallback: (interactions: Array<InteractionModel>) => void,
    rejectCallback: (error: string) => void
  ): void {
    this.conversationService
      .retrieveUnlinkedInteractions(this.contactId)
      .then((interactions) => successCallback(interactions))
      .catch((error) => rejectCallback(error));
  }

  public retrieveOrganisationMembers(
    name: string,
    pageNumber: number,
    pageSize: number,
    appendData?: boolean
  ): Promise<void> {
    return this.callInteractionCardService
      .retrieveOrganisationMembers(name, pageNumber, pageSize)
      .then(
        (members) => {
          const organisationTransferMembers = members.members.filter(
            (member) => {
              return member.memberId !== this.sessionStore.get.user.memberId;
            }
          );

          let memberIds = members.members.map(function (item: any): string[] {
            return item['memberId'];
          });
          this.callInteractionCardService
            .retrieveMemberStatuses(memberIds)
            .then(
              (result) => {
                this.organisationTransferMembers.forEach((item) => {
                  for (const status of result) {
                    if (item.memberId === status.memberId) {
                      item.presence = status.presence;
                      item.activity = status.activity;
                      break;
                    }
                  }
                });
              },
              (error) => {
                logger.info(
                  ' Failed to retrieve member statuses > error = ',
                  error
                );
              }
            );

          if (appendData) {
            this.organisationTransferMembers = [].concat(
              this.organisationTransferMembers,
              organisationTransferMembers
            );
          } else {
            this.organisationTransferMembers = organisationTransferMembers;
          }
        },
        (error) => {
          logger.info(
            ' Failed to retrieve your organisation members > error = ',
            error
          );
        }
      );
  }

  public async retrieveOrganisationWorktypeColorConfig(): Promise<void> {
    let config = await this.workTypeService.retrieveWorktypeColorConfig();
    this.organisationWorktypeColorConfig = config.colorFormats;
  }

  public retrieveOrganisationWorkTypes(): void {
    this.workTypeService.retrieveInboundWorkTypes().then(
      (workTypes) => {
        this.organisationWorkTypes = workTypes;
      },
      (error) => {
        logger.info(
          ' Failed to retrieve your organisation work types > error = ',
          error
        );
      }
    );
  }

  public async retrieveOrganisationMemberTransferList(): Promise<void> {
    this.transferListService.findMemberById().then(
      (transferLists) => {
        let result = {
          transferLists: [],
          transferRecipients: [],
        };
        if (transferLists && transferLists.length) {
          for (const list of transferLists) {
            let temp = [];
            for (const recipient of list.transferRecipients) {
              temp.push(recipient);
            }
            result.transferLists.push(list);
            result.transferRecipients[`${list.id}`] = temp;
          }
        }

        result.transferLists = ArrayTools.sort(result.transferLists, 'name');
        result.transferRecipients = ArrayTools.sort(
          result.transferRecipients,
          'title'
        );

        this.organisationTransferRecipients = result;
      },
      (error) => {
        logger.info(
          ' Failed to retrieve your organisation transfer lists > error = ',
          error
        );
      }
    );
  }

  public async retrieveSMSFromNumbers(): Promise<void> {
    this.contactService
      .getSMSFromNumbers()
      .then((response) => (this.smsFromNumbers = response.telephoneNumbers))
      .catch((e) => {
        logger.warn(' > Failed to retrieve SMS from number due to ', e);
      });
  }

  public retrieveCustomFields(): void {
    this.contactService.retrieveCustomFields().then(
      (customFields) => {
        this.customFieldsProperties = customFields;
        this.mapCustomFieldsValues();
      },
      (error) => {
        logger.info(
          ' Failed to retrieve your organisation custom fields > error = ',
          error
        );
      }
    );
  }

  public mapCustomFieldsValues(): void {
    if (!this.customFieldsProperties) {
      return;
    }
    this.customFieldsProperties.forEach((customFieldProperty) => {
      this.customFields.forEach((customFieldValue) => {
        if (customFieldProperty.customFieldId === customFieldValue.id) {
          customFieldProperty.value = customFieldValue.value;
        }
      });
    });
  }

  public subscribeToContactChanges(): void {
    this.contactInfoOplogSubscription &&
      this.contactInfoOplogSubscription.dispose();

    this.contactInfoOplogSubscription = this.eventAggregator.subscribe(
      'contact-info-oplog-update:' + this.contactId,
      (response) => {
        this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
          contactId: this.contactId,
          status: false,
          loader: 'contactInfo',
        });
        this.mapContactInfo(response, true);
      }
    );
  }

  public unsunbscribeFromSubscriptions(): void {
    if (this.contactId) {
      // remove event handlers
      this.unlinkedInteractionsOplogSubscription &&
        this.unlinkedInteractionsOplogSubscription.dispose();
      this.contactInfoOplogSubscription &&
        this.contactInfoOplogSubscription.dispose();

      // remove oplogs
      this.eventAggregator.publish('contact-oplog-unsubscribe', this.contactId);
      this.eventAggregator.publish(
        'unlinked-interactions-oplog-unsubscribe',
        this.contactId
      );
      this.eventAggregator.publish(
        'conversations-list-oplog-unsubscribe',
        this.contactId
      );
    }
    this.servedInteractions.forEach((interaction) =>
      this.eventAggregator.publish(
        'interaction-oplog-unsubscribe',
        interaction.interactionId
      )
    );

    // remove data instances
    let promises = [];
    promises.push(
      this.openConversations.forEach((conversation) =>
        conversation.disposeOfSubscriptions()
      )
    );
    promises.push(
      this.closedConversations.forEach((conversation) =>
        conversation.disposeOfSubscriptions()
      )
    );
    Promise.all(promises).then(() => {
      delete this.unlinkedInteractionToSelect;
      delete this.unlinkedInteractions;
      delete this.openConversations;
      delete this.closedConversations;
      delete this.conversations;
      delete this.servedInteraction;
      delete this.servedInteractions;
    });
  }

  public createConversation(
    name?: string,
    isProspect?: boolean
  ): ConversationModel {
    let newConversation = this.conversationFactory.build();
    newConversation.contactId = this.contactId;
    newConversation.status = 'new';
    newConversation.conversationName = name ? name : '(untitled)';
    newConversation.relativeTime = moment(Date.now());
    this.conversationService.createNewConversation(newConversation);
    return newConversation;
  }

  public createConversationWithId(
    id: string,
    name?: string,
    isProspect?: boolean
  ): ConversationModel {
    let newConversation = this.conversationFactory.build(id);
    newConversation.contactId = this.contactId;
    newConversation.status = 'new';
    newConversation.conversationName = name ? name : '(untitled)';
    newConversation.relativeTime = moment(Date.now());
    this.conversationService.createNewConversation(newConversation);
    return newConversation;
  }

  public closeContact(): void {
    this.contactService.unsubscribeFromContactChanges(this.contactId);
  }

  public deleteConversation(conversationId: string): void {
    this.conversationService.discardConversation(conversationId);
    this.discardConversation(conversationId);
  }

  private updateConversationName(conversationId: string, name: string): void {
    this.conversationService.renameConversation(conversationId, name);
  }

  public addUnlinkedInteraction(unlinkedInteraction: InteractionModel): void {
    let newInteraction = new InteractionModel();
    newInteraction.mapInteraction(unlinkedInteraction);
    if (
      this.servedInteraction &&
      this.servedInteraction.interactionId &&
      this.servedInteraction.conversationId &&
      this.servedInteraction.interactionId === newInteraction.interactionId
    ) {
      return;
    }

    if (
      this.unlinkedInteractions.filter(
        (interaction) =>
          interaction.interactionId === newInteraction.interactionId
      ).length === 0
    ) {
      this.unlinkedInteractions.unshift(newInteraction);
    }
  }

  public selectConversation(
    conversationId: string,
    autoSelect?: boolean
  ): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | selectConversation',
      conversationId
    );

    let conversationToSelect = this.openConversations.find(
      (conversation) => conversation.conversationId === conversationId
    );
    if (!conversationToSelect) {
      conversationToSelect = this.closedConversations.find(
        (conversation) => conversation.conversationId === conversationId
      );
    }
    if (!conversationToSelect) {
      this.unmodelledOpenedConversations =
        this.unmodelledOpenedConversations.filter((_conversation) => {
          if (_conversation.conversationId === conversationId) {
            const modelledConversation =
              this.mapConversationModel(_conversation);
            this.openConversations.push(modelledConversation);
            conversationToSelect = modelledConversation;
            return false;
          }
          return true;
        });
    }
    if (!conversationToSelect) {
      this.unmodelledClosedConversations =
        this.unmodelledClosedConversations.filter((_conversation) => {
          if (_conversation.conversationId === conversationId) {
            const modelledConversation =
              this.mapConversationModel(_conversation);
            this.closedConversations.push(modelledConversation);
            conversationToSelect = modelledConversation;
            return false;
          }
          return true;
        });
    }

    if (
      this.activeConversation &&
      this.activeConversation.conversationId === conversationId
    ) {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | selectConversation | activeConversation',
        this.activeConversation
      );
      conversationToSelect = this.activeConversation;
      this.selectedConversation = conversationToSelect;
      conversationId = this.activeConversation.conversationId;
    }

    if (conversationToSelect) {
      this.conversationIdToSelect = conversationId;
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | selectConversation | has a conversation to select '
      );
      this.deselectAllUnlinkedInteractions();
      this.deselectAllConversations();
      this.channelViewStrategy.setSize(
        this.showDispositionCode ? 'large' : 'small'
      );
      conversationToSelect.select(autoSelect);
      if (
        !conversationToSelect.status ||
        conversationToSelect.status.toLowerCase() === 'pending' ||
        conversationToSelect.status.toLowerCase() === 'new'
      ) {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | selectConversation | has a conversation to select | setting selected conversation '
        );
        this.selectedConversation = conversationToSelect;
      } else {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | selectConversation | has a conversation to select | no conversation to select '
        );
        this.selectedConversation = null;
      }
      this.eventAggregator.publish(
        CONVERSATION_ACTIONS.CONTACT_CONVERSATIONS_CHANGED
      );
      this.changeViewStrategy('interactions');
    } else if (conversationId) {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | selectConversation | conversation to select not found ',
        conversationId
      );
      if (this.conversations.length > 0) {
        if (this.openConversations.length > 0) {
          this.selectedConversation = this.openConversations[0];
        } else if (this.closedConversations.length > 0) {
          this.selectedConversation = this.closedConversations[0];
        }
        this.selectedConversation.select();
        this.changeViewStrategy('interactions');
      } else {
        this.changeViewStrategy('unlinked-interactions');
      }
    }
  }

  public searchContacts(
    contactMethod: string,
    number: string,
    email: string,
    numberOfContacts: number,
    autoLink?: boolean
  ): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | searchContacts  | searchText',
      {
        contactMethod,
        number,
        email,
        numberOfContacts,
      }
    );
    this.searchContactParams.page = 0;
    let region = this.sessionStore.get.organisation.country.code;

    this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
      contactId: this.contactId,
      status: true,
      loader: 'search',
    });
    this.contactService
      .searchContacts(contactMethod, region, number, email)
      .then((response: ContactModel[]) => {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | populateSearchParams  | contacts retrieved ',
          response
        );

        // @ts-ignore
        let contacts = response && response.contacts ? response.contacts : [];
        this.searchContactResults = [];
        this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
          contactId: this.contactId,
          correlationId: this.correlationId,
          status: false,
          loader: 'search',
        });
        this.eventAggregator.publish(CONTACT_ACTIONS.SEARCH_RESULT, {
          contactId: this.contactId,
          correlationId: this.correlationId,
          contacts: contacts.length === 0 ? null : contacts,
          isManualSearch: autoLink,
        });
        if (contacts.length === 0 && numberOfContacts > 0) {
          this.eventAggregator.publish(CONTACT_ACTIONS.THIRD_PARTY_MATCHES, {
            interactionId: this.contactId,
            numberOfContacts: numberOfContacts,
          });
        }
      })
      .catch((error) => {
        logger.warn('Retrieving Contacts > Error = ', error);
        this.eventAggregator.publish(LOADER_ACTIONS.TOGGLE_LOADER, {
          contactId: this.contactId,
          status: false,
          loader: 'search',
        });
      });
  }

  public selectInteraction(
    interactionId: string,
    conversationId: string
  ): void {
    this.changeViewStrategy('interactions');
    this.deselectAllUnlinkedInteractions();

    this.openConversations.forEach((conversation) => {
      if (conversation.conversationId === conversationId) {
        logger.debug(
          this.getCurrentFormattedTime() + ' - CC | selectInteraction '
        );
        conversation.selectInteraction(interactionId);
      } else {
        conversation.deselect();
      }
    });

    this.closedConversations.forEach((conversation) => {
      if (conversation.conversationId === conversationId) {
        logger.debug(
          this.getCurrentFormattedTime() + ' - CC | selectInteraction '
        );
        conversation.selectInteraction(interactionId);
      } else {
        conversation.deselect();
      }
    });
  }

  public selectUnlinkedInteraction(interactionId: string): void {
    this.changeViewStrategy('unlinkedInteractions');
    this.eventAggregator.publish(
      LOADER_ACTIONS.CHANGE_LOADER_UNLINKED_INTERACTIONS,
      {
        contactId: this.contactId,
        status: true,
      }
    );
    this.deselectAllUnlinkedInteractions(interactionId);
    this.deselectAllConversations();
  }

  public select(): void {
    this.isSelected = true;
    this.addFocus();
  }

  public deselect(): void {
    this.isSelected = false;
    this.removeFocus();
  }

  public addFocus(): void {
    this.servedInteractions.forEach((interaction) => {
      // @ts-ignore
      if (
        interaction instanceof InteractionModel &&
        (interaction.channel === 'instant_message' ||
          interaction.type === 'CHAT' ||
          interaction.type === 'Chat' ||
          interaction.channel === 'chat') &&
        interaction.sessionId
      ) {
        this.contactService.addFocusToContact(interaction.sessionId);
      }
    });
  }

  public removeFocus(): void {
    this.servedInteractions.forEach((interaction) => {
      // @ts-ignore
      if (
        interaction instanceof InteractionModel &&
        (interaction.channel === 'instant_message' ||
          interaction.type === 'CHAT' ||
          interaction.type === 'Chat' ||
          interaction.channel === 'chat') &&
        interaction.sessionId
      ) {
        this.contactService.removeFocusFromContact(interaction.sessionId);
      }
    });
  }

  public deselectAllConversations(): void {
    this.activeConversation && this.activeConversation.deselect();
    this.openConversations.forEach((conversation) => conversation.deselect());
    this.closedConversations.forEach((conversation) => conversation.deselect());
  }

  public deselectAllUnlinkedInteractions(interactionId?: string): void {
    if (this.unlinkedInteractions && this.unlinkedInteractions.length > 0) {
      this.unlinkedInteractions.forEach((interaction) => {
        if (interactionId && interaction.interactionId === interactionId) {
          setTimeout(() => {
            interaction.select();
          }, 1000);
        } else {
          interaction.deselect();
        }
      });
    }
    if (
      this.servedUnlinkedInteractions &&
      this.servedUnlinkedInteractions.length > 0
    ) {
      this.servedUnlinkedInteractions.forEach((interaction) => {
        if (interactionId && interaction.interactionId === interactionId) {
          setTimeout(() => {
            interaction.select();
          }, 1000);
        } else {
          interaction.deselect();
        }
      });
    }
  }

  public discardConversation(conversationId: string): void {
    this.conversations = this.conversations.filter(
      (conversation: ConversationModel) => {
        return conversation.conversationId !== conversationId;
      }
    );
  }

  public autoSelectConversation(): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | autoSelectConversation '
    );

    if (this.conversationIdToSelect) {
      this.selectConversation(this.conversationIdToSelect, true);
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | autoSelectConversation | select conversation'
      );
      this.conversationIdToSelect = null;
    } else if (this.interactionIdToSelect) {
      this.interactionIdToSelect = null;

      let unlinkedInteractionToSelect = this.unlinkedInteractions.find(
        (interaction) =>
          interaction.interactionId === this.interactionIdToSelect
      );
      if (unlinkedInteractionToSelect) {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | autoSelectConversation | select unlinked interaction with interactionIdToSelect '
        );
        this.eventAggregator.publish(
          INTERACTION_ACTIONS.SELECT_UNLINKED,
          unlinkedInteractionToSelect
        );
        return;
      }
    } else if (this.unlinkedInteractions.length > 0) {
      let unlinkedToSelect;

      if (this.servedInteractions.length) {
        unlinkedToSelect = this.unlinkedInteractions.find(
          (interaction) =>
            this.servedInteractions[0].interactionId ===
            interaction.interactionId
        );
      }

      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | autoSelectConversation | select from multiple unlinked interactions '
      );
      this.eventAggregator.publish(
        INTERACTION_ACTIONS.SELECT_UNLINKED,
        unlinkedToSelect || this.unlinkedInteractions[0]
      );
      this.changeViewStrategy('unlinkedInteractions');
    } else if (this.conversations.length > 0) {
      if (this.openConversations.length > 0) {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | autoSelectConversation | select from open conversations '
        );
        let _conversationId = this.hasTask
          ? this.taskInfo.conversationId
          : this.openConversations[0].conversationId;

        if (this.openConversations.length === 1) {
          this.servedInteractions.forEach((interaction) => {
            if (interaction.type === 'callback') {
              return;
            }
            interaction.linkToConversation(_conversationId);
            this.removeAllUnlinkedInteraction(interaction.interactionId);
          });
        }
        this.selectConversation(_conversationId, true);
      } else if (this.closedConversationsLength > 0) {
        logger.debug(
          this.getCurrentFormattedTime() +
            ' - CC | autoSelectConversation | select open conversation '
        );
        this.selectConversation(
          this.closedConversations[0].conversationId,
          true
        );
      }
    } else {
      logger.debug(
        this.getCurrentFormattedTime() +
          ' - CC | autoSelectConversation | no conversations to select '
      );
      this.changeViewStrategy('emptyConversation');
    }
  }

  public removeUnlinkedInteraction(interactionId: string): void {
    this.unlinkedInteractions = this.unlinkedInteractions.filter(
      (interaction) => {
        return interaction.interactionId !== interactionId;
      }
    );
  }

  public removeAllUnlinkedInteraction(interactionId: string): void {
    this.unlinkedInteractions = this.unlinkedInteractions.filter(
      (interaction) => interaction.interactionId !== interactionId
    );
    this.servedUnlinkedInteractions = this.servedUnlinkedInteractions.filter(
      (interaction) => interaction.interactionId !== interactionId
    );
  }

  public removeServedUnlinkedInteraction(interactionId: string): void {
    if (
      this.servedUnlinkedInteraction &&
      this.servedUnlinkedInteraction.interactionId === interactionId
    ) {
      this.servedUnlinkedInteraction = null;
      this.servedUnlinkedInteractions = this.servedUnlinkedInteractions.filter(
        (interaction) => interaction.interactionId !== interactionId
      );
    }
  }

  public async retrieveDnc(skipReload?:boolean): Promise<void> {
    this.dncChecked = false;
    for (const email of this.emails) {
      const dnc = await this.dncState.isDoNotContactEmail(email.email);
      email.isDnc = dnc ? true : false;
    }
    for (const number of this.telephoneNumbers) {
      const dnc = await this.dncState.isDoNotContactNumber(number.formattedNumber);
      number.isDnc = dnc ? true : false;
    }
    this.eventAggregator.publish('change.channel.size.reset', this.contactId);
    this.eventAggregator.publish('change.channel.email.reset', this.contactId);
    this.channelViewStrategy.channel = 'note';
    setTimeout(() => {
      this.channelViewStrategy.channel = 'call';
      this.dncChecked = true;
    }, 50);
  }

  public mapContactInfoFromSearch(contact?: ContactModel, fromOplog?: boolean): void {
    this.title = contact.title || this.title;
    this.firstName = contact.firstName || this.firstName;
    this.surname = contact.surname || this.surname;
    this.lastName = contact.surname || this.surname;
    this.emails = contact.emails || this.emails;
    this.crmInformation = contact.crmInformation || this.crmInformation;
    this.mapTelephoneNumbers(contact);
    this.hasContactInfo = true;
    if (!fromOplog && contact.customFields) {
      this.customFields = contact.customFields;
    }
    if (contact.customFieldsProperties) {
      this.customFieldsProperties = contact.customFieldsProperties;
    }
  }

  public mapContactInfo(contact?: ContactModel, fromOplog?: boolean): void {
    this.title = contact.title || this.title;
    this.firstName = contact.firstName || this.firstName;
    this.surname = contact.surname || this.surname;
    this.lastName = contact.surname || this.surname;
    this.emails = contact.emails || this.emails;
    this.crmInformation = contact.crmInformation || this.crmInformation;
    this.mapTelephoneNumbers(contact);
    this.hasContactInfo = true;
    if (!fromOplog && contact.customFields) {
      this.customFields = contact.customFields;
    }
    if (contact.customFieldsProperties) {
      this.customFieldsProperties = contact.customFieldsProperties;
    }
    this.retrieveDnc();
  }

  public mapConversations(conversations?: ConversationModel[]): Promise<void> {
    conversations.sort(SortTools.compareBy('timestampOpened'));
    conversations.reverse();

    this.conversations = conversations;
    let count = 0;
    return new Promise((parentResolve) => {
      let promises = [];
      conversations.forEach((conversation, index) =>
        promises.push(
          new Promise((resolve) => {
            if (
              this.unmodelledClosedConversations.find(conv => conv.conversationId === conversation.conversationId) ||
              this.closedConversations.find(conv => conv.conversationId === conversation.conversationId) ||
              this.unmodelledOpenedConversations.find(conv => conv.conversationId === conversation.conversationId) ||
              this.openConversations.find(conv => conv.conversationId === conversation.conversationId)
            ) {
              return resolve({});
            }
            if (
              conversation.status === 'successful' ||
              conversation.status === 'unsuccessful'
            ) {
              if (count >= this.conversationsMaxCount) {
                this.unmodelledClosedConversations.push(conversation);
                return resolve({});
              }
              this.closedConversations.push(
                this.mapConversationModel(conversation)
              );
            } else {
              if (count >= this.conversationsMaxCount) {
                this.unmodelledOpenedConversations.push(conversation);
                return resolve({});
              }
              this.openConversations.push(
                this.mapConversationModel(conversation)
              );
            }
            count++;

            resolve({});
          })
        )
      );
      Promise.all(promises).then(() => {
        parentResolve();
      });
    });
  }

  private mapConversationModel(conversation: any): ConversationModel {
    if (!conversation) {
      return null;
    }
    let tempConversation = new ConversationModel(
      this.conversationService,
      this.eventAggregator
    );
    tempConversation.conversationId = conversation.conversationId;
    tempConversation.contactId = this.contactId;
    tempConversation.firstName = this.firstName;
    tempConversation.surname = this.surname;
    tempConversation.mapConversationInfo(conversation, true);
    return tempConversation;
  }

  public openDispositionCode(): void {
    this.channelViewStrategy.setSize('large');
    this.showDispositionCode = true;
  }

  public injectContactData(data: any): void {
    if (!this.contactId || this.contactId === data.contactId) {
      this.firstName = data.firstName;
      this.surname = data.surname;
      this.externalReference = data.externalReference;
    }
  }

  public closeDispositionCode(): void {
    this.channelViewStrategy.setSize('small');
    this.showDispositionCode = false;
  }

  public openDispositionList(): void {
    this.channelViewStrategy.setSize('large');
    this.showDispositionList = true;
  }

  public closeDispositionList(): void {
    this.channelViewStrategy.setSize('small');
    this.showDispositionList = false;
  }

  public showWrapUp(): void {
    this.onWrapUp = true;
  }

  public removeWrapUp(): void {
    this.onWrapUp = false;
  }

  public mapTelephoneNumbers(contact?: any): void {
    if (contact.telephoneNumbers && contact.telephoneNumbers.length > 0) {
      this.telephoneNumbers = [];
      this.telephoneNumbers = contact.telephoneNumbers.map((telNumber) => {
        if (telNumber && telNumber.formattedNumber !== '') {
          return new TelephoneNumberModel(
            telNumber.formattedNumber,
            telNumber.type,
            telNumber.region
          );
        } else {
          return new TelephoneNumberModel('', '', '');
        }
      });
    }
  }

  @computedFrom('firstName', 'surname')
  get extractInitials(): string {
    if (this.firstName && !this.surname) {
      return this.firstName.substring(0, 1).toUpperCase();
    }
    if (!this.firstName && this.surname) {
      return this.surname.substring(0, 1).toUpperCase();
    }
    if (this.firstName && this.surname) {
      return (
        this.firstName.substring(0, 1).toUpperCase() +
        this.surname.substring(0, 1).toUpperCase()
      );
    } else {
      return '?';
    }
  }

  public initNumbers(phoneNumbers?: TelephoneNumberModel[]): void {
    this.telephoneNumbers = [];
    let workNumber, homeNumber, mobileNumber;
    let mobileNumbers: TelephoneNumberModel[] = [];

    if (phoneNumbers) {
      mobileNumbers = phoneNumbers
        .filter((phoneNumber) => phoneNumber.type === 'MOBILE')
        .map((phoneNumber) => {
          return new TelephoneNumberModel(
            phoneNumber.telephoneNumber.toLowerCase(),
            phoneNumber.type,
            phoneNumber.region ? phoneNumber.region.toLowerCase() : 'ZA',
            true,
            true
          );
        });

      phoneNumbers.forEach((number) => {
        if (number.type === TelephoneNumberTypes.WORK) {
          workNumber = new TelephoneNumberModel(
            number.telephoneNumber.toLowerCase(),
            number.type,
            number.region ? number.region.toLowerCase() : 'ZA',
            true,
            true
          );
        } else if (number.type === TelephoneNumberTypes.HOME) {
          homeNumber = new TelephoneNumberModel(
            number.telephoneNumber.toLowerCase(),
            number.type,
            number.region ? number.region.toLowerCase() : 'ZA',
            true,
            true
          );
        }
      });
    }

    if (!workNumber) {
      workNumber = new TelephoneNumberModel(
        '',
        TelephoneNumberTypes.WORK,
        '',
        true,
        true
      );
    }
    if (!homeNumber) {
      homeNumber = new TelephoneNumberModel(
        '',
        TelephoneNumberTypes.HOME,
        '',
        true,
        true
      );
    }
    if (!mobileNumber) {
      mobileNumber = new TelephoneNumberModel(
        '',
        TelephoneNumberTypes.MOBILE,
        '',
        true,
        true
      );
    }

    this.telephoneNumbers.push(workNumber, homeNumber);
    if (mobileNumbers.length > 0) {
      this.telephoneNumbers = this.telephoneNumbers.concat(mobileNumbers);
    } else {
      this.telephoneNumbers.push(mobileNumber);
    }
  }

  public equals(contact: ContactModel): boolean {
    let sameTitle: boolean = contact.title === this.title;
    let sameName: boolean =
      contact.firstName === this.firstName && this.surname === contact.surname;
    let sameEmail: boolean =
      contact.emailAsStrings.length === this.emailAsStrings.length &&
      contact.emailAsStrings.every((email) => {
        return this.emailAsStrings.some(
          (contactEmail) => contactEmail === email
        );
      });

    let sameMobileNumber: boolean =
      contact.mobileNumberAsStrings.length ===
        this.mobileNumberAsStrings.length &&
      contact.mobileNumberAsStrings.every((number) =>
        this.mobileNumberAsStrings.some(
          (mobileNumber) => mobileNumber === number
        )
      );
    let sameWorkNumber: boolean =
      contact.workNumberAsStrings.length === this.workNumberAsStrings.length &&
      contact.workNumberAsStrings.every((number) =>
        this.workNumberAsStrings.some((mobileNumber) => mobileNumber === number)
      );
    let sameHomeNumber: boolean =
      contact.homeNumberAsStrings.length === this.homeNumberAsStrings.length &&
      contact.homeNumberAsStrings.every((number) =>
        this.homeNumberAsStrings.some((mobileNumber) => mobileNumber === number)
      );
    let sameCustomFields: boolean =
      contact.customFieldsAsModel.length === this.customFieldsAsModel.length &&
      contact.customFieldsAsModel.every((editedContactCustomField) =>
        this.customFieldsAsModel.some(
          (contactCustomField) =>
            contactCustomField.customFieldId ===
              editedContactCustomField.customFieldId &&
            contactCustomField.value === editedContactCustomField.value
        )
      );
    return (
      sameTitle &&
      sameName &&
      sameEmail &&
      sameMobileNumber &&
      sameWorkNumber &&
      sameHomeNumber &&
      sameCustomFields
    );
  }

  public initEmailAddress(
    emails?: Array<{ type: string; email: string; isValid: boolean }>
  ): void {
    this.emails = [];

    if (emails && emails.length > 0) {
      this.emails = emails.map((_email) => {
        _email.isValid = true;
        return _email;
      });

      this.emails.forEach((email, idx) => {
        if (email.email.length === 0) {
          this.removeEmail(idx);
        }
      });
    }

    if (this.emails.length === 0) {
      this.emails.push({ type: '', email: '', isValid: true });
    }
  }

  public addEmail(): void {
    this.emails.push({ type: '', email: '', isValid: true });
  }

  public addPhoneNumber(): void {
    let mobileNumber = new TelephoneNumberModel(
      '',
      TelephoneNumberTypes.MOBILE,
      '',
      true,
      true
    );
    this.telephoneNumbers.push(mobileNumber);
  }

  public removeEmail(index: number): void {
    if (this.emails.length === 1) {
      return;
    }
    if (index > -1) {
      this.emails.splice(index, 1);
    }
  }

  public showMoreOpenConversations(): void {
    this.conversationsMaxCount += this.openConversationsIncrementAmount;

    for (
      let index = 0;
      index < this.openConversationsIncrementAmount;
      index++
    ) {
      const conversation = this.mapConversationModel(
        this.unmodelledOpenedConversations[index]
      );
      if (conversation) {
        this.openConversations.push(conversation);
      }
    }
    this.unmodelledOpenedConversations.splice(
      0,
      this.openConversationsIncrementAmount
    );
  }

  public showMoreClosedConversations(): void {
    this.conversationsMaxCount += this.closedConversationsIncrementAmount;

    for (
      let index = 0;
      index < this.closedConversationsIncrementAmount;
      index++
    ) {
      const conversation = this.mapConversationModel(
        this.unmodelledClosedConversations[index]
      );
      if (conversation) {
        this.closedConversations.push(conversation);
      }
    }
    this.unmodelledClosedConversations.splice(
      0,
      this.closedConversationsIncrementAmount
    );
  }

  public removePhoneNumber(deleteIndex: number): void {
    this.telephoneNumbers = this.telephoneNumbers.filter(
      (phoneNumber, index) => index !== deleteIndex
    );
  }

  @computedFrom('firstName', 'surname')
  get _fullName(): string {
    if (!this.firstName && !this.surname) {
      return '(No Name)';
    }
    if (!this.firstName && this.surname) {
      return this.surname;
    }
    if (this.firstName && !this.surname) {
      return this.firstName;
    } else {
      return this.firstName + ' ' + this.surname;
    }
  }

  @computedFrom(
    'conversations',
    'openConversations',
    'unmodelledOpenedConversations'
  )
  get openedConversationsLength(): number {
    return (
      this.openConversations.length + this.unmodelledOpenedConversations.length
    );
  }

  @computedFrom(
    'conversations',
    'closedConversations',
    'unmodelledClosedConversations'
  )
  get closedConversationsLength(): number {
    return (
      this.closedConversations.length +
      this.unmodelledClosedConversations.length
    );
  }

  @computedFrom('emails', 'emails.length')
  get emailAsStrings(): string[] {
    return this.emails
      .filter((email) => email.email !== '' && !email.isDnc)
      .map((email) => email.email);
  }
  get editContactPayload(): object {
    return {
      contactId: this.contactId,
      firstName: this.firstName,
      surname: this.surname,
      title: this.title,
      emails: this.emails,
      telephoneNumbers: this.nonEmptyNumbers,
      region: this.region,
      customFields: this.makeCustomFieldsPayload(),
    };
  }

  public makeCustomFieldsPayload(): Array<CustomFieldValueModel> {
    if (!this.customFieldsProperties) {
      return [];
    }
    return this.customFieldsProperties.map(({ customFieldId, name, value }) => {
      return { id: customFieldId, name, value };
    });
  }

  @computedFrom('emails', 'emails.length')
  get validEmails(): Array<{ type: string; email: string }> {
    return this.emails.filter((email) => email.email !== '');
  }

  @computedFrom('telephoneNumbers', 'telephoneNumbers.length')
  get nonEmptyNumbers(): TelephoneNumberModel[] {
    return this.telephoneNumbers.filter((number) => number.number !== '');
  }

  @computedFrom('telephoneNumbers', 'telephoneNumbers.length')
  get validNumbers(): TelephoneNumberModel[] {
    return this.telephoneNumbers.filter((number) => number.number !== '');
  }

  @computedFrom('telephoneNumbers', 'telephoneNumbers.length')
  get numberAsStrings(): string[] {
    return this.telephoneNumbers
      .filter((number) => number.number !== '' && !number.isDnc)
      .map((telephoneNumber) => telephoneNumber.formattedNumber);
  }

  @computedFrom('telephoneNumbers', 'telephoneNumbers.length')
  get mobileNumberAsStrings(): string[] {
    return this.telephoneNumbers
      .filter(
        (telephoneNumber) =>
          telephoneNumber.type === 'MOBILE' && telephoneNumber.number !== '' && !telephoneNumber.isDnc
      )
      .map((telNum) => telNum.formattedNumber);
  }

  @computedFrom('telephoneNumbers', 'telephoneNumbers.length')
  get workNumberAsStrings(): string[] {
    return this.telephoneNumbers
      .filter(
        (telephoneNumber) =>
          telephoneNumber.type === 'WORK' && telephoneNumber.number !== ''
      )
      .map((telNum) => telNum.formattedNumber);
  }

  @computedFrom('telephoneNumbers', 'telephoneNumbers.length')
  get homeNumberAsStrings(): string[] {
    return this.telephoneNumbers
      .filter(
        (telephoneNumber) =>
          telephoneNumber.type === 'HOME' && telephoneNumber.number !== ''
      )
      .map((telNum) => telNum.formattedNumber);
  }

  @computedFrom('customFieldsProperties')
  get customFieldsAsModel(): CustomFieldModel[] {
    if (!this.customFieldsProperties) {
      return [];
    }
    return this.customFieldsProperties
      .filter(
        (customFieldProperty) =>
          customFieldProperty.value && customFieldProperty.value !== ''
      )
      .map((customFieldProperty) => customFieldProperty);
  }

  @computedFrom('sessionStore.get.organisation')
  get region(): string {
    if (
      this.sessionStore.get.organisation &&
      this.sessionStore.get.organisation.country
    ) {
      return this.sessionStore.get.organisation.country.code;
    }
    return '';
  }

  public changeViewStrategy(strategy: string): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | changeViewStrategy ',
      strategy
    );
    this.previousViewStrategy = this.viewStrategy;
    this.viewStrategy = ContactDirectorViewStrategies.getStrategy(strategy);
    if (
      this.servedInteraction &&
      this.servedInteraction.screenPopUrl &&
      this.servedInteraction.useEmbeddedIFrame &&
      strategy === 'interactions' &&
      this.firstName
    ) {
      this.eventAggregator.publish('interaction-link-open', {
        firstName: this.firstName,
        surname: this.surname,
        remote:
          this.servedInteraction.direction === 'OUTBOUND'
            ? this.servedInteraction.to
            : this.servedInteraction.from,
        url: this.servedInteraction.screenPopUrl,
        interactionId: this.servedInteraction.interactionId,
      });
    }
  }

  public getSelectedConversation(): ConversationModel {
    if (this.activeConversation && this.activeConversation.isSelected) {
      return this.activeConversation;
    }
    let convo = this.openConversations.find(
      (conversation) => conversation.isSelected
    );
    if (!convo) {
      convo = this.closedConversations.find(
        (conversation) => conversation.isSelected
      );
    }
    if (convo) {
      return convo;
    }

    let linkingConvo = this.openConversations.find(
      (conversation) => conversation.awaitingLinking
    );
    if (linkingConvo) {
      linkingConvo = this.closedConversations.find(
        (conversation) => conversation.awaitingLinking
      );
    }
    if (linkingConvo) {
      return linkingConvo;
    }
  }

  public getConversation(conversationId: string): ConversationModel {
    if (
      this.activeConversation &&
      this.activeConversation.conversationId === conversationId
    ) {
      return this.activeConversation;
    }
    let convo = this.openConversations.find(
      (conversation) => conversation.conversationId === conversationId
    );
    if (convo) {
      return convo;
    }
    return this.closedConversations.find(
      (conversation) => conversation.conversationId === conversationId
    );
  }

  public getUnlinkedInteractionWithId(
    interactionId: string
  ): InteractionModel | TicketModel {
    return this.unlinkedInteractions.find(
      (interaction) => interaction.interactionId === interactionId
    );
  }

  public getServedUnlinkedInteractionWithId(
    interactionId: string
  ): InteractionModel | TicketModel {
    return this.servedUnlinkedInteractions.find(
      (interaction) => interaction.interactionId === interactionId
    );
  }

  public getServedInteractionWithId(
    interactionId: string
  ): InteractionModel | TicketModel {
    return this.servedInteractions.find(
      (interaction) => interaction.interactionId === interactionId
    );
  }

  public hasServedInteraction(): boolean {
    return (
      this.servedInteraction &&
      this.servedInteraction.interactionId &&
      this.servedInteraction.interactionId !== '' &&
      !this.servedInteraction.conversationId
    );
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }

  public moveConversationToOpen(conversation: ConversationModel): void {
    if (
      this.activeConversation &&
      this.activeConversation.conversationId === conversation.conversationId
    ) {
      return;
    }
    this.closedConversations = this.closedConversations.filter(
      (convo) => convo.conversationId !== conversation.conversationId
    );
    const alreadyOpenConversation = this.openConversations.find(
      (conv) => conv.conversationId === conversation.conversationId
    );
    if (alreadyOpenConversation) {
      return;
    }
    this.openConversations.unshift(conversation);
  }

  public moveConversationToClosed(conversation: ConversationModel): void {
    if (
      this.activeConversation &&
      this.activeConversation.conversationId === conversation.conversationId
    ) {
      return;
    }
    this.openConversations = this.openConversations.filter(
      (convo) => convo.conversationId !== conversation.conversationId
    );
    const alreadyClosedConversation = this.closedConversations.find(
      (conv) => conv.conversationId === conversation.conversationId
    );
    if (alreadyClosedConversation) {
      return;
    }
    this.closedConversations.unshift(conversation);
  }

  @computedFrom('emails', 'telephoneNumbers')
  get mainMethodOfContact(): string {
    let validEmails: string[] = this.emailAsStrings.filter(
      (email) => email !== ''
    );
    if (validEmails.length > 0) {
      return validEmails[0];
    }

    let validNumbers = this.numberAsStrings.filter((number) => number !== '');
    if (validNumbers.length > 0) {
      return validNumbers[0];
    }
  }
}
