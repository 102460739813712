import {
  autoinject,
  bindable,
  LogManager,
  PLATFORM,
  observable,
} from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/**/
import { ConnectorModel } from '../../../../../../components/organisms/connector/models/connector-model';
import { NodeModel } from '../../../../../../components/organisms/node/models/node-model';
/**/
const logger = new LogManager.getLogger('AssistantApiDialog');
/**/

interface Step {
  active: boolean;
  completed: boolean;
  name: string;
  view: string;
  data?: any;
}

@autoinject
export class AssistantApiDialog {
  @bindable private model: any;
  private closeCallback: (data: any) => void;
  public steps: Step[] = [];
  public isAddingContent: boolean = false;
  public loading: boolean = false;
  public currentStep: any;
  public currentStepIndex: number = 0;
  wizard: any;

  constructor(
    private dialogController: DialogController,
    private eventAggregator: EventAggregator
  ) {
    this.dialogController = dialogController;
    this.eventAggregator.subscribe('wizard.step.activate', () => {
      this.updateStepCompletion();
    });
  }

  public activate(model: any) {
    if (model?.wizard) {
      this.wizard = model.wizard;
    }
    this.initSteps(model.apiCallData);
    this.steps[0].active = true;
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public log(...args: any): void {
    logger.debug('🔍', ...args);
  }

  private initSteps(apiCallData: any = null): void {
    this.steps = [
      {
        name: 'General Information',
        // @ts-ignore
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/assistants/edit-assistant/assistant-api-dialog/page1-edit-assistant-api/page1-edit-assistant-api'
        ),
        active: true,
        completed: !!apiCallData.id,
        data: apiCallData,
      },
      {
        name: 'Properties',
        // @ts-ignore
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/assistants/edit-assistant/assistant-api-dialog/page2-edit-assistant-api/page2-edit-assistant-api'
        ),
        active: false,
        completed: !!apiCallData.id,
        data: apiCallData,
      },
      {
        name: 'Request',
        // @ts-ignore
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/assistants/edit-assistant/assistant-api-dialog/page3-edit-assistant-api/page3-edit-assistant-api'
        ),
        active: false,
        completed: !!apiCallData.id,
        data: {
          ...apiCallData,
          method: apiCallData?.method || 'GET',
        },
      },
      {
        name: 'Response',
        // @ts-ignore
        view: PLATFORM.moduleName(
          'features/interaction/interactionFlow/assistants/edit-assistant/assistant-api-dialog/page4-edit-assistant-api/page4-edit-assistant-api'
        ),
        active: false,
        completed: !!apiCallData.id,
        data: {
          responseMapping: apiCallData?.responseMapping || '',
        },
      },
    ];
  }

  private validateCurrentStep(): boolean {
    const stepData = this.steps[this.currentStepIndex].data;

    switch (this.currentStepIndex) {
      case 0: // General Info
        return stepData.name && stepData.description;
      case 1: // Properties
        return (
          stepData.properties &&
          stepData.properties.every((prop) => prop.name && prop.type)
        );
      case 2: // Request
        return stepData.method && stepData.url && stepData.contentType && stepData.body;
      case 3: // Response
        return !!stepData.responseMapping;
      default:
        return true;
    }
  }

  private updateStepCompletion(): void {
    if (this.currentStepIndex === 1) {
      this.steps[1].completed =
        this.steps[1].data.properties &&
        this.steps[1].data.properties.length > 0;
      this.currentStep = this.steps[this.currentStepIndex];
    }
  }

  propertiesChanged(newValue: any, oldValue: any) {
    this.updateStepCompletion();
  }

  public cancelDialog(): void {
    this.dialogController.cancel();
  }

  public completeDialog(data): void {
    this.validateCurrentStep();
    const payload = {
      type: 'Function',
      name: data.name,
      description: data.description || '',
      properties: data.properties,
      headers: data.requestHeaders || data.headers,
      method: data.method || 'GET',
      url: data.url,
      timeout: typeof data.timeout === 'string' ? parseInt(data.timeout) : data.timeout,
      body: data.body || data.bodyText || '',
      responseMapping: data.responseMapping,
      contentType: data.contentType || 'JSON',
    };
    this.dialogController.ok(payload);
  }
}
