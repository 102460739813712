import { bindable, customElement, LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('ZGridPlaceholder');

customElement('z-grid-placeholder')
export class ZGridPlaceholder {

  public defined = true;

  @bindable
  public name: string;
}