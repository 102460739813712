
import { LogManager } from "aurelia-framework";
import { Container } from "aurelia-dependency-injection";
import { computedFrom } from "aurelia-binding";

import { CampaignLiveDashboardStats } from './column-config/campaign-live-db-stats';
import { ConfigMapper } from "./column-config/config-mapper";

const logger = LogManager.getLogger('LiveDashboardState');

export class CampaignLiveDashboardState {

  private readonly settingName: string = 'zailab_campaign_live_dashboard_settings';
  public state: {
    refreshTime: number,
    teams: any[],
    settings: IDashboardColumnConfig
  };

  public defaultSettings = {
    operator: '>=',
    value: '0',
    color: null,
    enabled: false
  };
  
  private configMapper: ConfigMapper = Container.instance.get(ConfigMapper);

  // @ts-ignore
  public defaultMappings: IDashboardColumnConfig = {
    ...(new CampaignLiveDashboardStats(this.configMapper).columnConfig)
  };

  constructor() {
    this.init();
  }

  private init(): void {
    if (!localStorage.getItem(this.settingName)) {
      this.setDefaultStateData();
    }
  }

  private setDefaultStateData(): void {
    const keys = Object.keys(this.defaultMappings);
    const defaultWidth = 1800 / keys.length;
    let settingsMap = {};

    settingsMap = keys.map((key, index) => {
      return this.addDefaultCellValues(this.defaultMappings[key], defaultWidth, index);
    })

    this.state = {
      refreshTime: 30,
      teams: [],
      settings: settingsMap
    };
  }

  private addDefaultCellValues(mapping: { [key: string]: any }, defaultWidth: number, index: number): any {
    mapping.width = defaultWidth;
    mapping.index = index;
    mapping.data = mapping.data || {};
    return mapping;
  }

  public async saveSettings(data: any): Promise<void> {
    let settingsToSave;

    if (data && Array.isArray(data)) {
      settingsToSave = data.map((setting, index) => {
        let width = setting.width || 100;
        return {
          orderIndex: index,
          ...setting,
          width
        };
      });
    }
    
    const localStorageState = await JSON.parse(localStorage.getItem(this.settingName));
    if (localStorageState && localStorageState.settings) {
      localStorage.setItem(this.settingName, JSON.stringify({
        refreshTime: localStorageState.refreshTime,
        services: localStorageState.services,
        settings: settingsToSave
      }));
    } else {
      localStorage.setItem(this.settingName, JSON.stringify({
        settings: settingsToSave
      }));
    }
  }

  public clear(): void {
    localStorage.removeItem(this.settingName);
    this.init();
  }


  public setRefreshTime(time: any): void {
    this.state.refreshTime = time;
  }

  @computedFrom('state.refreshTime')
  public get refreshTime(): number {
    if (!this.state) {
      return 30;
    }
    return this.state.refreshTime;
  }

  public async getMappings(colorScheme: IDashboardColumnColorConfig[]): Promise<any> {
    colorScheme = this.orderColorScheme(colorScheme);
    
    const localStorageState = await JSON.parse(localStorage.getItem(this.settingName));
    const localSettings = localStorageState ? localStorageState.settings : [];
    let localSettingsMap = {};
    localSettings.forEach(setting => {
      localSettingsMap[setting.title] = setting;
    });
    
    let colorConfigSettingsMap = {};
    if (colorScheme) {
      colorScheme.forEach(config => {
        colorConfigSettingsMap[config.title] = config;
      });
    }
    let dataToMap = [];

    if (!this.state) {
      this.state = {
        refreshTime: 30,
        teams: [],
        settings: {}
      }
    }

    try {

      let promises = [];

      const allKeys = Object.keys(this.defaultMappings);
      allKeys.forEach((key, index) => {

        promises.push(new Promise(async resolve => {
          const scheme = colorConfigSettingsMap[key];

          let mappedData = {
            title: key,
            moddedTitle: key ? key.replace(/[ /()#%]/g, '_') : '',
            field: {
              ...this.defaultMappings[key],
              settings: scheme && scheme.settings.length > 0 ? scheme.settings : [{ ...this.defaultSettings }],
              disabled: this.defaultMappings[key].disabled
            }
          };
          if (localSettingsMap[key]) {
            mappedData.field.orderIndex = localSettingsMap[key].orderIndex;
            mappedData.field.width = localSettingsMap[key].width || 100;
            mappedData.field.visible = localSettingsMap[key].visible;
          } else {
            if (!mappedData.field.width || mappedData.field.width < 80) {
              mappedData.field.width = 100;
            }
          }

          dataToMap.push(mappedData);
          resolve({});
        }));
      });

      await Promise.all(promises);
    } catch (e) {
      logger.warn('mappings :: error=', e);
    } finally {
      const columnConfig = dataToMap.sort((a, b) => a.field.orderIndex - b.field.orderIndex);
      return columnConfig;
    }
  }

  private orderColorScheme(configData: any[]): any[] {
    if (configData && Array.isArray(configData)) {
      return configData.map(config => {
        config.settings = config.settings.sort(function(a: { value: number, color: string }, b: { value: number, color: string }): number {
          if(a.value === Infinity) { 
            return 1; 
          } else if( isNaN(a.value)) {
            return -1;
          } else { 
            return a.value - b.value;
          }
        }).reverse()
        return config;
      });
    }
    return null;
  }
}

export const CAMPAIGN_LIVE_DASHBOARD_STATE = new CampaignLiveDashboardState();
