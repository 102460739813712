export class WorkforceManagement {
  workforceManagementId;
  verintRealtimeTimeMonitoringEnabled;
  verintEnabled;
  verintInboundCallStatsEnabled;
  verintInboundChatStatsEnabled;
  verintOutboundCallStatsEnabled;
  verintTimeMonitoringEnabled;
  verintEmployeeSyncEnabled;
  verintAbandonedOutcomes;
	verintSftpHost;
	verintSftpPort;
	verintSftpUsername;
	verintSftpPassword;
	verintSftpPublicKey;
	verintSftpPrivateKey;
	verintSftpPassphrase;
	verintApiHost;
	verintApiToken;
	verintApiOrganisationId;
  verintRoleMappingAdministrator;
  verintRoleMappingTeamLeader;
  verintRoleMappingAgent;
  verintRoleMappingQA;
  verintRoleMappingQAManager;
  verintRoleMappingCampaignManager;
  verintRoleMappingOfficeEmployee;
  verintForecastingEnabled;
  verintForecastingRange;
  verintIncludeDetailedHandleTime;
  verintInboundWorkType;
  verintOutboundWorkType;
  verintExtraFields;
}
