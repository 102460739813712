import { autoinject, bindable, customElement, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ZPillbox');

@customElement('z-pillbox')
@autoinject
export class ZPillbox {

  @bindable
  public marginTop = '';

  @bindable
  public minWidth = '400px';

  @bindable
  public maxWidth = '400px';

  @bindable
  public maxHeight = '200px';

  private pillbox: HTMLElement;

  public bind(): void {
    this.pillbox.style.marginTop = this.marginTop;
    this.pillbox.style.minWidth = this.minWidth;
    this.pillbox.style.maxWidth = this.maxWidth;
    this.pillbox.style.maxHeight = this.maxHeight;
  }
}
