import {inject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
 */
import {SurveyService} from '../survey-service';
/*
 */
const logger = LogManager.getLogger('Survey');
/*
 */
@inject(SurveyService, Router)
export class Survey {

  survey;
  ready = false;

  constructor(surveyService, router) {

    this.surveyService = surveyService;
    this.router = router;
  }

  activate(params) {

    let surveyId = params.surveyId;
    this.surveyService.displaySurvey(surveyId)
      .then((survey) => {
        this.survey = survey;
        this.ready = true;
      })
      .catch(() => {
        this.ready = true;
    });
  }

  surveys() {

    this.router.navigate('');
  }
}