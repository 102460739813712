import { FeatureFlagService } from './../../../featureflags/feature-flag-service';
import { BindingSignaler } from 'aurelia-templating-resources';
import { ContactService } from './../../../organisation/contact/contact-service';
import { Router } from 'aurelia-router';
import { INTERACTION_ACTIONS } from './../../../organisation/conversation/interaction/interaction-actions';
import { LogManager, autoinject, PLATFORM } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore } from 'zailab.common';
import { PlaceholderService } from '../../../../_common/services/placeholder-service';
import { TelephonyService } from '../../../telephony/telephony-service';
import { InteractionService as OtherInteractionService } from '../../../organisation/interactions/interaction-service';
import { InteractionService } from '../../../organisation/conversation/interaction/interaction-service';
import { ConnectedInteractionModel } from './connected-interaction-model';
import { ConversationService } from '../../../organisation/conversation/conversation-service';
import { MemberInteractionModel } from '../../../organisation/conversation/interaction/member-interaction-model';
import { IMInteractionCardService } from '../../../organisation/conversation/interactioncards/instantmessage/im-interaction-card-service';
import { DialogService } from 'aurelia-dialog';

//@ts-ignore
import moment from 'moment';

let logger = LogManager.getLogger('ConnectedInteractions');

@autoinject()
export class ConnectedInteractions {

  public container: HTMLElement;
  public placeholders: number;
  public itemList: Array<ConnectedInteractionModel> = [];
  public embeddedWebphoneEnabled = null;
  private viewAction = {
    action: 'view',
    label: 'View'
  };
  private endInteractionAction = {
    action: 'end',
    label: 'End',
    icon: 'endcall'
  };
  private endWrapupAction = {
    action: 'endwrapup',
    label: 'End Wrapup',
    icon: 'endcall'
  };

  private placeholderService: PlaceholderService;
  
  private stateStorageKey = 'zai_state';
  private defaultRequestTimer = 30;
  private currentRequestTimer;
  public timerDropdownVisible = false;
  private lastDataRetrieveTime;
  private lastRequestTimestamp: number;
  private signallerInterval: number;

  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }
  ];

  constructor(
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    private interactionService: InteractionService,
    private otherInteractionService: OtherInteractionService,
    private telephonyService: TelephonyService,
    private conversationService: ConversationService,
    private imInteractionCardService: IMInteractionCardService,
    private bindingSignaler: BindingSignaler,
    private featureFlagService: FeatureFlagService
  ) { }

  public activate(): void {
    const state = this.getStateFromStorage();
    if (state && state.connectedInteractions) {
      this.currentRequestTimer = state.connectedInteractions.requestTimer;
    } else {
      this.currentRequestTimer = 30;
    }
    this.getFeatureFlags();
  }

  private getFeatureFlags(): void {
    this.featureFlagService.isEnabled('embeddedWebphoneEnabled')
      .then(async (embeddedWebphoneEnabled) => {
        this.embeddedWebphoneEnabled = embeddedWebphoneEnabled;
      });
  }

  public async attached(): Promise<void> {
    this.placeholderService = new PlaceholderService(this.container, this.itemList.length, 2, (placeholders) => this.placeholders = placeholders);

    this.getData();
  }

  private async getData(): Promise<void> {
    const interactions = await this.fetchConnectedInteractions();
    this.setItemList(interactions);
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        this.getData();
      }
    }, signal);
  }

  public endInteraction(interaction: any, event?: Event): void {
    event && event.stopPropagation();
    if (interaction.channel === 'Instant Message') {
      this.imInteractionCardService.getTranscript(interaction.interactionId)
        .then((transcript) => {
          this.imInteractionCardService.endInteraction(transcript.sessionId);
        });
    } else {
      this.interactionService.endInteraction(interaction);
    }
    this.eventAggregator.publish('end-interaction', interaction.interactionId);
  }

  public endWrapUp(interaction: any, event?: Event): void {
    event && event.stopPropagation();
    this.telephonyService.goOffWrapUp(this.sessionStore.get.user.memberId, interaction.interactionId, interaction.wrapUpChannelIds);
    this.eventAggregator.publish('end-interaction-wrap-up', interaction.interactionId);
  }

  public isInteractionInWrapUp(interaction: ConnectedInteractionModel): boolean {
    const channel = interaction.channel.toLowerCase();
    return interaction.interactionType && channel === 'call' && interaction.state === 'WRAP_UP';
  }

  private isConnectedFlowCall(interaction: any): boolean {
    const state = interaction.state.toLowerCase();
    const channel = interaction.channel.toLowerCase();
    return state === 'connected' && channel === 'call';
  }

  private mapModel(interaction: any): ConnectedInteractionModel {
    let model = new ConnectedInteractionModel(interaction);
    model.actions = [this.viewAction];
    if (interaction.state === 'WRAP_UP') {
      model.actions.push(this.endWrapupAction);
    } else if (interaction.channel !== 'Call' || model.outcome === 'callback') {
      model.actions.push(this.endInteractionAction);
    }
    return model;
  }

  private setItemList(interactions: ConnectedInteractionModel[]): void {
    this.itemList = interactions;
    if (this.placeholderService) {
      this.placeholderService.generatePlaceholders(this.itemList.length);
    }
  }

  private async fetchConnectedInteractions(): Promise<ConnectedInteractionModel[]> {
    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.setupSignaller();
    let memberInteractions: MemberInteractionModel[] = await this.otherInteractionService.retrieveConnectedInteractions(this.sessionStore.get.user.memberId);
    return this.fetchAndPopulateConversationInteraction(memberInteractions);
  }

  private async fetchAndPopulateConversationInteraction(memberInteractions: MemberInteractionModel[]): Promise<ConnectedInteractionModel[]> {
    let interactions = [];
    if (memberInteractions && memberInteractions.length) {
      interactions = memberInteractions
        .map((memberInteraction) => this.mapModel(memberInteraction));
    }
    return Promise.all(interactions.map((connectedInteraction) => {
      return this.conversationService.retrieveInteraction(connectedInteraction.interactionId)
        .then((conversationInteraction) => {
          connectedInteraction.conversationInteraction = conversationInteraction;
          return connectedInteraction;
        });
    }));
  }

  public viewInteraction(interaction: ConnectedInteractionModel): void {
    this.eventAggregator.publish(INTERACTION_ACTIONS.CONNECTED_INTERACTIONS_RECOVERED, { interactions: [interaction] });
  }

  
  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public refresh(): void {
    this.itemList = [];
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.getData();
  }

  public selectTime(value: number): void {
    this.currentRequestTimer = value;
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        parsedState.connectedInteractions = { requestTimer: value };
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ connectedInteractions: { requestTimer: value } }));
    }
    this.timerDropdownVisible = false;
  }

  private getStateFromStorage(): any {
    const state = localStorage.getItem(this.stateStorageKey);
    try {
      let parsedState = JSON.parse(state);
      return parsedState;
    } catch(e) {
      return null;
    }
  }

  public unbind(): void {
    this.signallerInterval && window.clearInterval(this.signallerInterval);
  }

  public doNothing(): void {}
}
