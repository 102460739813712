import { UserSessionModel } from './../../../../_common/stores/sessionmodels/user-session-model';
import {Aurelia, autoinject, FrameworkConfiguration, LogManager} from 'aurelia-framework';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {Router} from 'aurelia-router';
import {BindingSignaler} from 'aurelia-templating-resources';

import {ApplicationProperties, SessionStore, SortTools} from 'zailab.common';
import {LiveDashboardService} from './live-dashboard-service';
import {LiveDashboardModel} from "./models/live-dashboard-model";
// import {visibilityAware, ZIVisibilityAware} from '../../../../_common/services/visibilityawareness/visibility-aware';
import {FeatureFlagService} from "../../../featureflags/feature-flag-service";

import moment from 'moment';
import toastr from 'toastr';
import { computedFrom } from 'aurelia-binding';

const logger = LogManager.getLogger('LiveDashboard');

@autoinject
// @visibilityAware
// export class LiveDashboard implements ZIVisibilityAware {
export class LiveDashboard {

  private enableSelectors: boolean = false;

  private defaultServiceId: string = null;
  private defaultWorkTypeId: string = null;
  private timeoutCount: number = 30000;
  private lastRequestTimestamp: number;
  private signallerInterval: number;

  private serviceList: any[] = null;
  private selectedService: any = null;
  private serviceDropdownVisible: boolean = false;

  private workTypesList: any[] = null;
  private selectedWorkType: any = null;
  private workTypeDropdownVisible: boolean = false;
  private userBusinessPartners: string[] = [];
  private userContactCentres: string[] = [];
  private retrievingData = false;

  private previousDashboard = new LiveDashboardModel({}, this.user.hasAgentRole);
  private liveDashboard = new LiveDashboardModel({}, this.user.hasAgentRole);

  private oplog: any;

  public refreshedSubscription: Subscription;
  public refreshingSubscription: Subscription;

  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }
  ];
  private stateStorageKey = 'zai_state';
  private defaultRequestTimer = 30;
  private currentRequestTimer;
  public timerDropdownVisible = false;

  private lastDataRetrieveTime;

  constructor(
    private liveDashboardService: LiveDashboardService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private sessionStore: SessionStore,
    private applicationProperties: ApplicationProperties,
    private bindingSignaler: BindingSignaler,
    private featureFlagService: FeatureFlagService,
    private aurelia: Aurelia,
  ) {
    this.liveDashboardService = liveDashboardService;
    this.eventAggregator = eventAggregator;

    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        const parsedState = JSON.parse(state);
        this.currentRequestTimer = parsedState.requestTimer || this.defaultRequestTimer;

        const liveDashboard = parsedState.liveDashboard;
        this.selectedService = {
          serviceName: liveDashboard.serviceName
        };
        this.selectedWorkType = {
          name: liveDashboard.workTypeName
        };
      } catch(e) {}
    } else {
      this.currentRequestTimer = this.defaultRequestTimer;
    }
  }

  public activate(params: any): void {
    this.defaultServiceId = params.serviceId || null;
    if (params.workTypeId) {
      this.defaultWorkTypeId = params.workTypeId;
    }
  }

  public attached(): void {
    this.userBusinessPartners = this.sessionStore.get.user.userAccessRoles.filter(accessRole => accessRole.accountType === 'ORGANISATION')[0].businessPartnerIds;
    this.userContactCentres = this.sessionStore.get.user.userAccessRoles.filter(accessRole => accessRole.accountType === 'ORGANISATION')[0].contactCentreIds;
    this.modifyLiveDashboardBodyClass();
    this.retrieveServicesWithWorkTypes();
    this.refreshedSubscription = this.eventAggregator.subscribe('live-dashboard.refreshed.data', () => this.refreshedData());
    this.refreshingSubscription = this.eventAggregator.subscribe('live-dashboard.refreshing.data', () => this.refreshingData());
  }


  private modifyLiveDashboardBodyClass(): void {
    let liveDashboardClass = 'live-dashboard-sizing';
    let pageBody = document.querySelector('html');
    pageBody.classList.add(liveDashboardClass);
  }

  private retrieveServicesWithWorkTypes(): void {
    this.eventAggregator.publish('app:loader:show');
    const memberId = this.sessionStore.get.user.memberId;
    this.liveDashboardService.retrieveMyServices(memberId).then(response => {
      if (response && response.length) {
        this.eventAggregator.publish('app:loader:hide');
        this.enableSelectors = true;
      }
      this.serviceList = response.sort(SortTools.compareBy('serviceName'));

      if (this.defaultServiceId) {
        this.setupDefaultSelectedService(this.serviceList);
        return;
      }
      if (this.serviceList.length > 0) {
        let serviceToSelect = this.serviceList[0];
        if (this.selectedService) {
          serviceToSelect = this.serviceList.find(_service => this.selectedService.serviceName === _service.serviceName);
          if (!serviceToSelect) {
            serviceToSelect = this.serviceList[0];
          }
        }
        this.selectService(serviceToSelect);
      } else {
        this.eventAggregator.publish('app:loader:hide');
        // 
        
        toastr.error('You don’t currently have permission access  to view the Waiting Room. Speak to your administrator to grant you access.');
      }
      this.enableSelectors = true;
    },
      error => {
        this.serviceList = [];
      }
    );
  }
  
  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: number): void {
    this.currentRequestTimer = value;
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        parsedState.requestTimer = value;
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ requestTimer: value }));
    }
    this.timerDropdownVisible = false;
  }

  private saveState(data: {[key: string]: string}): void {
    const state = localStorage.getItem(this.stateStorageKey);
    if (state) {
      try {
        let parsedState = JSON.parse(state);
        parsedState.liveDashboard = {
          ...(parsedState.liveDashboard || {}),
          ...data
        };
        localStorage.setItem(this.stateStorageKey, JSON.stringify(parsedState));
      } catch(e) {}
    } else {
      localStorage.setItem(this.stateStorageKey, JSON.stringify({ liveDashboard: data }));
    }
  }

  public navigateToOverview(): void {
    this.router.parent.navigateToRoute('livedashboardoverview');
  }

  private refreshLiveDashboard(): void {
    const serviceId: string = this.selectedService.serviceId;
    const workTypeId: string = this.selectedWorkType ? this.selectedWorkType.id : null;
    this.retrieveLiveDashboard(serviceId, workTypeId);
  }

  private refreshingData(): void {
    this.eventAggregator.publish('app:loader:show');
    this.enableSelectors = false;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
  }

  private refreshedData(): void {
    this.eventAggregator.publish('app:loader:hide');
    this.enableSelectors = true;
    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.setupSignaller();
  }

  private async retrieveLiveDashboard(serviceId: any, workTypeId: any): Promise<void> {
    this.eventAggregator.publish('app:loader:show');
    this.enableSelectors = false;
    await this.retrieveData(serviceId, workTypeId);
    this.eventAggregator.publish('app:loader:hide');
    this.enableSelectors = true;
    this.lastRequestTimestamp = new moment().add('seconds', this.currentRequestTimer);
    this.setupSignaller();
  }

  private retrieveData(serviceId: any, workTypeId: any): Promise<any> {

    const now = Date.now();
    // if(this.lastDataRetrieveTime && (now - this.lastDataRetrieveTime < this.currentRequestTimer * 500)) {
    //   return;
    // }
    this.lastDataRetrieveTime = now;
    this.retrievingData = true;

    return this.liveDashboardService
      .retrieveNewLiveDashboard(serviceId, workTypeId, true)
      .then((response: any) => {
        let mappedData = this.mapNewDashboard(response, serviceId, workTypeId);

        const generateKeys = (key: string, count: number) => {
          for (let i = 0; i < count; i ++) {
            mappedData[key][i] = '';
          }
        }
        try {

          if (response.channel === 'INBOUND_CALL' || !response.channel && response.channelStats.INBOUND_CALL && response.channelStats.INBOUND_CALL.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.INBOUND_CALL.interactionLiveStats;
            generateKeys('waitingCalls', liveStats.waiting);
            generateKeys('outOfSLACalls', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);

            const todayStats = response.interactionTodayStats || response.channelStats.INBOUND_CALL.interactionTodayStats;
            mappedData.callsAnsweredOutOfSla = todayStats.handledOutOfSLAToday > 0 ? Math.round((todayStats.handledOutOfSLAToday / 100) * todayStats.handled) : 0;

          }

          if (response.channel === 'EMAIL' || !response.channel && response.channelStats.EMAIL && response.channelStats.EMAIL.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.EMAIL.interactionLiveStats;
            generateKeys('waitingEmails', liveStats.waiting);
            generateKeys('outOfSLAEmails', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);

            const todayStats = response.interactionTodayStats || response.channelStats.EMAIL.interactionTodayStats;
            mappedData.emailsServedOutOfSla = todayStats.handledOutOfSLAToday > 0 ? Math.round((todayStats.handledOutOfSLAToday / 100) * todayStats.handled) : 0;
          }

          if (response.channel === 'SMS' || !response.channel && response.channelStats.SMS && response.channelStats.SMS.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.SMS.interactionLiveStats;
            generateKeys('waitingSMSs', liveStats.waiting);
            generateKeys('outOfSLASMSs', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);

            const todayStats = response.interactionTodayStats || response.channelStats.SMS.interactionTodayStats;
            mappedData.smssServedOutOfSla = todayStats.handledOutOfSLAToday > 0 ? Math.round((todayStats.handledOutOfSLAToday / 100) * todayStats.handled) : 0;
          }

          if (response.channel === 'CHAT' || !response.channel && response.channelStats.CHAT && response.channelStats.CHAT.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.CHAT.interactionLiveStats;
            generateKeys('waitingWebchats', liveStats.waiting);
            generateKeys('outOfSLAWebchats', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);

            const todayStats = response.interactionTodayStats || response.channelStats.CHAT.interactionTodayStats;
            mappedData.webchatsServedOutOfSla = todayStats.handledOutOfSLAToday > 0 ? Math.round((todayStats.handledOutOfSLAToday / 100) * todayStats.handled) : 0;
          }

          if (response.channel === 'INSTANT_MESSAGE' || !response.channel && response.channelStats.INSTANT_MESSAGE && response.channelStats.INSTANT_MESSAGE.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.INSTANT_MESSAGE.interactionLiveStats;
            generateKeys('waitingIMs', liveStats.waiting);
            generateKeys('outOfSLAIMs', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);

            const todayStats = response.interactionTodayStats || response.channelStats.INSTANT_MESSAGE.interactionTodayStats;
            mappedData.imsServedOutOfSla = todayStats.handledOutOfSLAToday > 0 ? Math.round((todayStats.handledOutOfSLAToday / 100) * todayStats.handled) : 0;
          }

          if (response.channel === 'TICKET' || !response.channel && response.channelStats.TICKET && response.channelStats.TICKET.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.TICKET.interactionLiveStats;
            generateKeys('waitingTickets', liveStats.waiting);
            generateKeys('outOfSLATickets', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);

            const todayStats = response.interactionTodayStats || response.channelStats.TICKET.interactionTodayStats;
            mappedData.ticketsServedOutOfSla = todayStats.handledOutOfSLAToday > 0 ? Math.round((todayStats.handledOutOfSLAToday / 100) * todayStats.handled) : 0;
          }

          if (response.channel === 'CALLBACK' || !response.channel && response.channelStats.CALLBACK && response.channelStats.CALLBACK.interactionLiveStats) {
            const liveStats = response.interactionLiveStats || response.channelStats.CALLBACK.interactionLiveStats;
            generateKeys('waitingCallbacks', liveStats.waiting);
            generateKeys('outOfSlaCallbacks', liveStats.percentageOutSLA > 0 ? (liveStats.percentageOutSLA / 100) * liveStats.waiting : 0);
          }
        } catch (e) {
          logger.error(' > error ', e);
        }

        this.previousDashboard = new LiveDashboardModel(this.liveDashboard, this.user.hasAgentRole);
        this.liveDashboard = new LiveDashboardModel(mappedData, this.user.hasAgentRole);
        this.retrievingData = false;
      })
      .catch(e => {
        this.liveDashboard = new LiveDashboardModel(null, this.user.hasAgentRole);
        this.retrievingData = false;
      });
  }

  private setupDefaultSelectedService(selectedService: any): void {
    this.selectedService = selectedService.find(service => {
      return service.serviceId === this.defaultServiceId;
    });
    this.selectService(this.selectedService);
  }

  private setupWorkTypes(selectedService: any): void {
    if (!selectedService.taskTemplates.length) {
      this.selectedWorkType = {
        id: null,
        name: null,
        isSelected: false
      };
      this.workTypesList = [];
    } else {

      this.workTypesList = selectedService.taskTemplates.sort(SortTools.compareBy('name'));
      this.workTypesList = this.workTypesList.filter((workType) => {
        return !this.allWorkTypeOption(workType) && this.businessPartnerMatches(workType.businessPartnerId) && this.contactCentreMatches(workType.contactCentreId);
      });

      this.workTypesList.unshift({
        id: null,
        name: 'All Work Types',
        isSelected: false
      });

      let workTypeToSelect = this.workTypesList[0];
      if (this.selectedWorkType) {
        workTypeToSelect = this.workTypesList.find(wt => this.selectedWorkType.name === wt.name);
        if (!workTypeToSelect) {
          workTypeToSelect = this.workTypesList[0];
        }
      }
      this.selectWorkType(workTypeToSelect);
    }
  }

  private mapNewDashboard(response: any, serviceId: string, workTypeId: string): any {
    
    if (response.channel) {
      return {
        taskTemplateId: workTypeId,
        serviceId: serviceId,
        agentsReady: response.agentLiveStats.onDuty,
        agentsNotReady: response.agentLiveStats.offDuty,
        callsReceived: response.channel === 'INBOUND_CALL' ? response.interactionTodayStats.received : 0,
        emailsReceived: response.channel === 'EMAIL' ? response.interactionTodayStats.received : 0,
        smssReceived: response.channel === 'SMS' ? response.interactionTodayStats.received : 0,
        webchatsReceived: response.channel === 'CHAT' ? response.interactionTodayStats.received : 0,
        imsReceived: response.channel === 'INSTANT_MESSAGE' ? response.interactionTodayStats.received : 0,
        ticketsReceived: response.channel === 'TICKET' ? response.interactionTodayStats.received : 0,
        numberOfCallsHandled: response.channel === 'INBOUND_CALL' ? response.interactionTodayStats.handled : 0,
        numberOfEmailsHandled: response.channel === 'EMAIL' ? response.interactionTodayStats.handled : 0,
        numberOfSMSsHandled: response.channel === 'SMS' ? response.interactionTodayStats.handled : 0,
        numberOfWebchatsHandled: response.channel === 'CHAT' ? response.interactionTodayStats.handled : 0,
        numberOfIMsHandled: response.channel === 'INSTANT_MESSAGE' ? response.interactionTodayStats.handled : 0,
        numberOfTicketsHandled: response.channel === 'TICKET' ? response.interactionTodayStats.handled : 0,
        notHandledCount: {
          mailbox: response.interactionTodayStats.voicemail,
          missed: response.interactionTodayStats.missed,
          callback: response.interactionTodayStats.callback,
          abandoned: response.interactionTodayStats.abandoned,
          cleared: response.interactionTodayStats.cleared,
          forwarded: response.interactionTodayStats.forwarded
        },
        averageHandleTime: response.channel === 'INBOUND_CALL' ? response.interactionTodayStats.averageHandleTime : 0,
        averageEmailHandleTime: response.channel === 'EMAIL' ? response.interactionTodayStats.averageHandleTime : 0,
        averageSMSHandleTime: response.channel === 'SMS' ? response.interactionTodayStats.averageHandleTime : 0,
        averageWebchatHandleTime: response.channel === 'CHAT' ? response.interactionTodayStats.averageHandleTime : 0,
        averageIMHandleTime: response.channel === 'INSTANT_MESSAGE' ? response.interactionTodayStats.averageHandleTime : 0,
        averageTicketHandleTime: response.channel === 'TICKET' ? response.interactionTodayStats.averageHandleTime : 0,
        averageWaitTime: response.channel === 'INBOUND_CALL' ? response.interactionTodayStats.averageWaitTime : 0,
        averageEmailWaitTime: response.channel === 'EMAIL' ? response.interactionTodayStats.averageWaitTime : 0,
        averageSMSWaitTime: response.channel === 'SMS' ? response.interactionTodayStats.averageWaitTime : 0,
        averageWebchatWaitTime: response.channel === 'CHAT' ? response.interactionTodayStats.averageWaitTime : 0,
        averageIMWaitTime: response.channel === 'INSTANT_MESSAGE' ? response.interactionTodayStats.averageWaitTime : 0,
        averageTicketWaitTime: response.channel === 'TICKET' ? response.interactionTodayStats.averageWaitTime : 0,
        callsAnsweredOutOfSlaPercentage: response.channel === 'INBOUND_CALL' ? -Math.round(-response.interactionTodayStats.handledOutOfSLAToday * 10) / 10 : 0,
        emailsServedOutOfSlaPercentage: response.channel === 'EMAIL' ? -Math.round(-response.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
        smssServedOutOfSlaPercentage: response.channel === 'SMS' ? -Math.round(-response.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
        webchatsServedOutOfSlaPercentage: response.channel === 'CHAT' ? -Math.round(-response.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
        imsServedOutOfSlaPercentage: response.channel === 'INSTANT_MESSAE' ? -Math.round(-response.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
        ticketsServedOutOfSlaPercentage: response.channel === 'TICKET' ? -Math.round(-response.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0, // 
        waitingCalls: {},
        waitingEmails: {},
        waitingSMSs: {},
        waitingWebchats: {},
        waitingIMs: {},
        waitingTickets: {},
        waitingCallbacks: {},
        outOfSLACalls: {},
        outOfSLAEmails: {},
        outOfSLASMSs: {},
        outOfSLAWebchats: {},
        outOfSLAIMs: {},
        outOfSLATickets: {},
        outOfSlaCallbacks: [],
        longestWaitingCallTimestamp: response.channel === 'INBOUND_CALL' ? response.interactionLiveStats.longestWaiting : 0,
        longestWaitingEmailTimestamp: response.channel === 'EMAIL' ? response.interactionLiveStats.longestWaiting : 0,
        longestWaitingSMSTimestamp: response.channel === 'SMS' ? response.interactionLiveStats.longestWaiting : 0,
        longestWaitingWebchatTimestamp: response.channel === 'CHAT' ? response.interactionLiveStats.longestWaiting : 0,
        longestWaitingIMTimestamp: response.channel === 'INSTANT_MESSAGE' ? response.interactionLiveStats.longestWaiting : 0,
        longestWaitingTicketTimestamp: response.channel === 'TICKET' ? response.interactionLiveStats.longestWaiting : 0,
        longestWaitingCallback: response.channel === 'CALLBACK' ? response.interactionLiveStats.longestWaiting : 0,
      };
    }

    return {
      taskTemplateId: workTypeId,
      serviceId: serviceId,
      agentsReady: response.agentLiveStats.onDuty,
      agentsNotReady: response.agentLiveStats.offDuty,
      callsReceived: response.channelStats.INBOUND_CALL ? response.channelStats.INBOUND_CALL.interactionTodayStats.received : 0,
      emailsReceived: response.channelStats.EMAIL ? response.channelStats.EMAIL.interactionTodayStats.received : 0,
      smssReceived: response.channelStats.SMS ? response.channelStats.SMS.interactionTodayStats.received : 0,
      webchatsReceived: response.channelStats.CHAT ? response.channelStats.CHAT.interactionTodayStats.received : 0,
      imsReceived: response.channelStats.INSTANT_MESSAGE ? response.channelStats.INSTANT_MESSAGE.interactionTodayStats.received : 0,
      ticketsReceived: response.channelStats.TICKET ? response.channelStats.TICKET.interactionTodayStats.received : 0,
      numberOfCallsHandled: response.channelStats.INBOUND_CALL ? response.channelStats.INBOUND_CALL.interactionTodayStats.handled : 0,
      numberOfEmailsHandled: response.channelStats.EMAIL ? response.channelStats.EMAIL.interactionTodayStats.handled : 0,
      numberOfSMSsHandled: response.channelStats.SMS ? response.channelStats.SMS.interactionTodayStats.handled : 0,
      numberOfWebchatsHandled: response.channelStats.CHAT ? response.channelStats.CHAT.interactionTodayStats.handled : 0,
      numberOfIMsHandled: response.channelStats.INSTANT_MESSAGE ? response.channelStats.INSTANT_MESSAGE.interactionTodayStats.handled : 0,
      numberOfTicketsHandled: response.channelStats.TICKET ? response.channelStats.TICKET.interactionTodayStats.handled : 0,
      notHandledCount: {
        mailbox: response.totalChannelStats.interactionTodayStats.voicemail,
        missed: response.totalChannelStats.interactionTodayStats.missed,
        callback: response.totalChannelStats.interactionTodayStats.callback,
        abandoned: response.totalChannelStats.interactionTodayStats.abandoned,
        cleared: response.totalChannelStats.interactionTodayStats.cleared,
        forwarded: response.totalChannelStats.interactionTodayStats.forwarded
      },
      averageHandleTime: response.channelStats.INBOUND_CALL ? response.channelStats.INBOUND_CALL.interactionTodayStats.averageHandleTime : 0,
      averageEmailHandleTime: response.channelStats.EMAIL ? response.channelStats.EMAIL.interactionTodayStats.averageHandleTime : 0,
      averageSMSHandleTime: response.channelStats.SMS ? response.channelStats.SMS.interactionTodayStats.averageHandleTime : 0,
      averageWebchatHandleTime: response.channelStats.CHAT ? response.channelStats.CHAT.interactionTodayStats.averageHandleTime : 0,
      averageIMHandleTime: response.channelStats.INSTANT_MESSAGE ? response.channelStats.INSTANT_MESSAGE.interactionTodayStats.averageHandleTime : 0,
      averageTicketHandleTime: response.channelStats.TICKET ? response.channelStats.TICKET.interactionTodayStats.averageHandleTime : 0,
      averageWaitTime: response.channelStats.INBOUND_CALL ? response.channelStats.INBOUND_CALL.interactionTodayStats.averageWaitTime : 0,
      averageEmailWaitTime: response.channelStats.EMAIL ? response.channelStats.EMAIL.interactionTodayStats.averageWaitTime : 0,
      averageSMSWaitTime: response.channelStats.SMS ? response.channelStats.SMS.interactionTodayStats.averageWaitTime : 0,
      averageWebchatWaitTime: response.channelStats.CHAT ? response.channelStats.CHAT.interactionTodayStats.averageWaitTime : 0,
      averageIMWaitTime: response.channelStats.INSTANT_MESSAGE ? response.channelStats.INSTANT_MESSAGE.interactionTodayStats.averageWaitTime : 0,
      averageTicketWaitTime: response.channelStats.TICKET ? response.channelStats.TICKET.interactionTodayStats.averageWaitTime : 0,
      callsAnsweredOutOfSlaPercentage: response.channelStats.INBOUND_CALL ? -Math.round(-response.channelStats.INBOUND_CALL.interactionTodayStats.handledOutOfSLAToday * 10) / 10 : 0,
      emailsServedOutOfSlaPercentage: response.channelStats.EMAIL ? -Math.round(-response.channelStats.EMAIL.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
      smssServedOutOfSlaPercentage: response.channelStats.SMS ? -Math.round(-response.channelStats.SMS.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
      webchatsServedOutOfSlaPercentage: response.channelStats.CHAT ? -Math.round(-response.channelStats.CHAT.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
      imsServedOutOfSlaPercentage: response.channelStats.INSTANT_MESSAGE ? -Math.round(-response.channelStats.INSTANT_MESSAGE.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0,
      ticketsServedOutOfSlaPercentage: response.channelStats.TICKET ? -Math.round(-response.channelStats.TICKET.interactionTodayStats.handledOutOfSLAToday* 10) / 10 : 0, // 
      waitingCalls: {},
      waitingEmails: {},
      waitingSMSs: {},
      waitingWebchats: {},
      waitingIMs: {},
      waitingTickets: {},
      waitingCallbacks: {},
      outOfSLACalls: {},
      outOfSLAEmails: {},
      outOfSLASMSs: {},
      outOfSLAWebchats: {},
      outOfSLAIMs: {},
      outOfSLATickets: {},
      outOfSlaCallbacks: [],
      longestWaitingCallTimestamp: response.channelStats.INBOUND_CALL ? response.channelStats.INBOUND_CALL.interactionLiveStats.longestWaiting : 0,
      longestWaitingEmailTimestamp: response.channelStats.EMAIL ? response.channelStats.EMAIL.interactionLiveStats.longestWaiting : 0,
      longestWaitingSMSTimestamp: response.channelStats.SMS ? response.channelStats.SMS.interactionLiveStats.longestWaiting : 0,
      longestWaitingWebchatTimestamp: response.channelStats.CHAT ? response.channelStats.CHAT.interactionLiveStats.longestWaiting : 0,
      longestWaitingIMTimestamp: response.channelStats.INSTANT_MESSAGE ? response.channelStats.INSTANT_MESSAGE.interactionLiveStats.longestWaiting : 0,
      longestWaitingTicketTimestamp: response.channelStats.TICKET ? response.channelStats.TICKET.interactionLiveStats.longestWaiting : 0,
      longestWaitingCallback: response.channelStats.CALLBACK ? response.channelStats.CALLBACK.interactionLiveStats.longestWaiting : 0,
    };
  } 

  private allWorkTypeOption(workType: any): boolean {
    return !workType.id && workType.name === 'All Work Types';
  }

  private businessPartnerMatches(businessPartnerId: string): boolean {
    return (this.userBusinessPartners.length === 0 && !businessPartnerId) || !!(this.userBusinessPartners.find(id => id === businessPartnerId));
  }

  private contactCentreMatches(contactCentreId: string): boolean {
    return (this.userContactCentres.length === 0 && !contactCentreId) || !!(this.userContactCentres.find(id => id === contactCentreId));
  }

  private selectService(service: any): void {
    this.selectedService = {
      serviceId: service.serviceId,
      serviceName: service.serviceName,
      taskTemplates: service.taskTemplates && service.taskTemplates.length ? service.taskTemplates : []
    };
    
    this.saveState({ serviceName: service.serviceName });

    for (let service of this.serviceList) {
      service.isSelected = false;
      service.isAdded = false;
      service.isSelected = service.serviceId === this.selectedService.serviceId;
    }

    this.retrieveLiveDashboard(this.selectedService.serviceId, this.defaultWorkTypeId || null);
    this.setupWorkTypes(this.selectedService);
    this.serviceDropdownVisible = false;
    this.workTypeDropdownVisible = false;
  }

  private selectWorkType(workType: any): void {
    if (!workType) {
      return;
    }
    this.selectedWorkType = workType;
    for (let workType of this.workTypesList) {
      workType.isSelected = false;
      if (this.selectedWorkType) {
        workType.isSelected = workType.id === this.selectedWorkType.id;
      }
    }
    this.saveState({ workTypeName: workType.name });
    this.retrieveLiveDashboard(this.selectedService.serviceId, this.selectedWorkType ? this.selectedWorkType.id : null);
    this.serviceDropdownVisible = false;
    this.workTypeDropdownVisible = false;
  }

  public navigate(view: any, paramData?: any): void {
    if (!view || Object.keys(view).length === 0) {
      return;
    }
    let urlParams = '';
    urlParams += paramData.serviceId ? paramData.serviceId + this.selectedService.serviceId : '';
    urlParams += paramData.workTypeId ? paramData.workTypeId : (this.selectedWorkType ? this.selectedWorkType.id : '');
    urlParams += paramData.outOfSLA ? paramData.outOfSLA + true : '';
    urlParams += paramData.longestWaiting ? paramData.longestWaiting + true : '';

    this.router.navigate(`${view}${urlParams}`);
  }

  public showServiceDropdown(): void {
    this.serviceDropdownVisible = !this.serviceDropdownVisible;
  }

  public showWorkTypeDropdown(): void {
    this.workTypeDropdownVisible = !this.workTypeDropdownVisible;
  }

  public detached(): void {
    this.liveDashboard = new LiveDashboardModel({}, this.user.hasAgentRole);
    this.previousDashboard = new LiveDashboardModel({}, this.user.hasAgentRole);

    let liveDashboardClass = 'live-dashboard-sizing';
    let pageBody = document.querySelector('html');
    pageBody.classList.remove(liveDashboardClass);
    clearInterval(this.signallerInterval);
    this.refreshedSubscription.dispose();
    this.refreshingSubscription.dispose();
  }

  becameInvisible(): void {
  }

  becameVisible(): void {
    let workTypeId: string = this.selectedWorkType && this.selectedWorkType.id ? this.selectedWorkType.id : null;
    if (this.selectedService) {
      this.retrieveLiveDashboard(this.selectedService.serviceId, workTypeId);
    } else {
      this.eventAggregator.publish('app:loader:hide');
    }
  }

  private setupSignaller(): void {
    const signal = 300;
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
    this.signallerInterval = setInterval(() => {
      this.bindingSignaler.signal('myTimeToSignal');
      if (moment(this.lastRequestTimestamp).diff(new moment(), 'seconds') <= 0) {
        if (this.retrievingData) {
          return;
        }
        this.refreshLiveDashboard();
      }
    }, signal);
  }

  private get currentTimestamp(): moment {
    return moment();
  }

  public deactivate(): void {
    if (this.signallerInterval) {
      clearInterval(this.signallerInterval);
    }
  }

  @computedFrom('sessionStore.get.user')
  private get user(): UserSessionModel {
    return this.sessionStore.get.user;
  }
}
