import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

import { DataGridMapping } from './../../../../../../components/templates/data-grid/data-grid';
import { ConfigMapper, CHANNELS } from './config-mapper';

const logger = LogManager.getLogger('InteractionTodaySLAStats');

@autoinject
export class InteractionTodaySLAStats {

  constructor(private configMapper: ConfigMapper) {}

  public columnConfig = {
    // 🆃🅾🆃🅰🅻 🅸🅽 🅰🅽🅳 🅾🆄🆃 🅾🅵 🆂🅻🅰 30 🅼🅸🅽 🅼🅸🅽🆄🆃🅴🆂
      'Tot In SLA 30 min': {
        tooltip: 'Total In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min'))
      },
      'Tot Calls In SLA 30 min': {
        tooltip: 'Total Calls In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Calls In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.INBOUND_CALL))
      },
      'Tot Chats In SLA 30 min': {
        tooltip: 'Total Chats In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Chats In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.CHAT))
      },
      'Tot Emails In SLA 30 min': {
        tooltip: 'Total Emails In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Emails In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.EMAIL))
      },
      'Tot IMs In SLA 30 min': {
        tooltip: 'Total IM In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot IMs In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.INSTANT_MESSAGE))
      },
      'Tot SMS In SLA 30 min': {
        tooltip: 'Total SMS In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot SMS In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.SMS))
      },
      'Tot Tickets In SLA 30 min': {
        tooltip: 'Total Tickets In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Tickets In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalInSLA30Min', CHANNELS.TICKET))
      },
      'Tot Out SLA 30 min': {
        tooltip: 'Total Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min'))
      },
      'Tot Calls Out SLA 30 min': {
        tooltip: 'Total Calls Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Calls Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.INBOUND_CALL))
      },
      'Tot Chats Out SLA 30 min': {
        tooltip: 'Total Chats Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Chats Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.CHAT))
      },
      'Tot Emails Out SLA 30 min': {
        tooltip: 'Total Emails Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Emails Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.EMAIL))
      },
      'Tot IMs Out SLA 30 min': {
        tooltip: 'Total IM Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot IMs Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.INSTANT_MESSAGE))
      },
      'Tot SMS Out SLA 30 min': {
        tooltip: 'Total SMS Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot SMS Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.SMS))
      },
      'Tot Tickets Out SLA 30 min': {
        tooltip: 'Total Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Tickets Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'totalOutSLA30Min', CHANNELS.TICKET))
      },
    // 🅷🅰🅽🅳🅻🅴🅳 🅸🅽 🅰🅽🅳 🅾🆄🆃 🅾🅵 🆂🅻🅰 30 🅼🅸🅽 🅼🅸🅽🆄🆃🅴🆂
      'HDL Out SLA 30 min': {
        tooltip: 'Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min'))
      },
      'HDL Calls Out SLA 30 min': {
        tooltip: 'Calls Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Calls Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.INBOUND_CALL))
      },
      'HDL Chats Out SLA 30 min': {
        tooltip: 'Chats Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Chats Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.CHAT))
      },
      'HDL Emails Out SLA 30 min': {
        tooltip: 'Emails Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Emails Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.EMAIL))
      },
      'HDL IMs Out SLA 30 min': {
        tooltip: 'Instant Messages Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL IMs Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.INSTANT_MESSAGE))
      },
      'HDL SMS Out SLA 30 min': {
        tooltip: 'SMS Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL SMS Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.SMS))
      },
      'HDL Tickets Out SLA 30 min': {
        tooltip: 'Tickets Handled Out of SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL Out of SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Tickets Out SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledOutSLA30Min', CHANNELS.TICKET))
      },
      'HDL In SLA 30 min': {
        tooltip: 'Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min'))
      },
      'HDL Calls In SLA 30 min': {
        tooltip: 'Calls Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Calls In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.INBOUND_CALL))
      },
      'HDL Chats In SLA 30 min': {
        tooltip: 'Chats Total Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Chats In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.CHAT))
      },
      'HDL Emails In SLA 30 min': {
        tooltip: 'Emails Total Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Emails In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.EMAIL))
      },
      'HDL IMs In SLA 30 min': {
        tooltip: 'Instant Messages Total Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL IMs In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.INSTANT_MESSAGE))
      },
      'HDL SMS In SLA 30 min': {
        tooltip: 'SMS Total Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL SMS In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.SMS))
      },
      'HDL Tickets In SLA 30 min': {
        tooltip: 'Tickets Total Handled In SLA 30 min',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'HDL In SLA 30 min'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Tickets In SLA 30 min', this.configMapper.getTotalTodayStats(c, 'handledInSLA30Min', CHANNELS.TICKET))
      },
// 🆃🅾🆃🅰🅻 🅸🅽 🅰🅽🅳 🅾🆄🆃 🅾🅵 🆂🅻🅰 Day
      'Tot In SLA (day)': {
        tooltip: 'Total In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday'))
      },
      'Tot Calls In SLA (day)': {
        tooltip: 'Total Calls In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Calls In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.INBOUND_CALL))
      },
      'Tot Chats In SLA (day)': {
        tooltip: 'Total Chats In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Chats In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.CHAT))
      },
      'Tot Emails In SLA (day)': {
        tooltip: 'Total Emails In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Emails In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.EMAIL))
      },
      'Tot IMs In SLA (day)': {
        tooltip: 'Total IM In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot IMs In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.INSTANT_MESSAGE))
      },
      'Tot SMS In SLA (day)': {
        tooltip: 'Total SMS In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot SMS In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.SMS))
      },
      'Tot Tickets In SLA (day)': {
        tooltip: 'Total Tickets In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Tickets In SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalInSLAToday', CHANNELS.TICKET))
      },
      'Tot Out SLA (day)': {
        tooltip: 'Total Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday'))
      },
      'Tot Calls Out SLA (day)': {
        tooltip: 'Total Calls Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Calls Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.INBOUND_CALL))
      },
      'Tot Chats Out SLA (day)': {
        tooltip: 'Total Chats Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Chats Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.CHAT))
      },
      'Tot Emails Out SLA (day)': {
        tooltip: 'Total Emails Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Emails Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.EMAIL))
      },
      'Tot IM Out SLA (day)': {
        tooltip: 'Total IM Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot IM Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.INSTANT_MESSAGE))
      },
      'Tot SMS Out SLA (day)': {
        tooltip: 'Total SMS Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot SMS Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.SMS))
      },
      'Tot Tickets Out SLA (day)': {
        tooltip: 'Total Tickets Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Total Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('Tot Tickets Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'totalOutOfSLAToday', CHANNELS.TICKET))
      },
    // 🅷🅰🅽🅳🅻🅴🅳 🅸🅽 🅰🅽🅳 🅾🆄🆃 🅾🅵 🆂🅻🅰 Day
      'HDL Out SLA (day)': {
        tooltip: 'Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday'))
      },
      'HDL Calls Out SLA (day)': {
        tooltip: 'Calls Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Calls Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.INBOUND_CALL))
      },
      'HDL Chats Out SLA (day)': {
        tooltip: 'Chats Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Chats Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.CHAT))
      },
      'HDL Emails Out SLA (day)': {
        tooltip: 'Emails Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Emails Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.EMAIL))
      },
      'HDL IMs Out SLA (day)': {
        tooltip: 'Instant Messages Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL IMs Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.INSTANT_MESSAGE))
      },
      'HDL SMS Out SLA (day)': {
        tooltip: 'SMS Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL SMS Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.SMS))
      },
      'HDL Tickets Out SLA (day)': {
        tooltip: 'Tickets Handled Out of SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled Out of SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Tickets Out SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledOutOfSLAToday', CHANNELS.TICKET))
      },
      'HDL In SLA (day)': {
        tooltip: 'Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday');
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)',
          expandCollapseHeader: true
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday'))
      },
      'HDL Calls In SLA (day)': {
        tooltip: 'Calls Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.INBOUND_CALL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Calls In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.INBOUND_CALL))
      },
      'HDL Chats In SLA (day)': {
        tooltip: 'Chats Total Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.CHAT);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Chats In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.CHAT))
      },
      'HDL Emails In SLA (day)': {
        tooltip: 'Emails Total Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.EMAIL);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Emails In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.EMAIL))
      },
      'HDL IMs In SLA (day)': {
        tooltip: 'Instant Messages Total Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.INSTANT_MESSAGE);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL IMs In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.INSTANT_MESSAGE))
      },
      'HDL SMS In SLA (day)': {
        tooltip: 'SMS Total Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.SMS);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL SMS In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.SMS))
      },
      'HDL Tickets In SLA (day)': {
        tooltip: 'Tickets Total Handled In SLA (day)',
        value: (c) => {
          const value = this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.TICKET);
          return value && value !== '--' ? this.configMapper.roundData(value) + '%' : value;
        },
        visible: false,
        align: 'right',
        width: 200,
        data: {
          unitOfMeasurement: 'Percentage',
          expandCollapseGroup: 'Handled In SLA (day)'
        },
        style: c => '" style="' + this.configMapper.getColourConfig('HDL Tickets In SLA (day)', this.configMapper.getTotalTodayStats(c, 'handledInSLAToday', CHANNELS.TICKET))
      }
  };
}
