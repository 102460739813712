import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DashboardWidget } from '../../organisation/dashboard/dashboard-abstract';
import { FeatureFlagService } from '../../../featureflags/feature-flag-service';

const logger = LogManager.getLogger('ReportsWidget');

@autoinject()
export class ReportsWidget extends DashboardWidget {

  public analyticsEnabled: boolean;
  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }];

  constructor(
    public router: Router,
    private featureFlagService: FeatureFlagService,
  ) {
    super();
  }

  public async bind(): Promise<void> {
    this.analyticsEnabled = await this.featureFlagService.isEnabled(
      'AnalyticsEnabled'
    );
  }
}
