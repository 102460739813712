import { inject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";


@inject(HttpClient)
export class MicrosoftTeamsPresenceService {
  httpClient: any;
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  public getMSTeamsPresences(organisationId) {
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/ms-teams`)
      .asGet()
      .send()
  }

  public updateMSTeamsPresenceMapping(
    organisationId,
    enabled: boolean,
    properties: {userSyncInterval: string | number; clientId: string; tenantId: string; presenceMappings: { [key: string]: string } },
    encryptedProperties: { key: string; value: string | null; valueHash: string; }[]
  ) {
    const mappedPresences = this.convertToCamelCase(properties.presenceMappings);
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/ms-teams`)
      .asPut()
      .withContent({
        enabled,
        properties: {
          ...properties,
        },
        encryptedProperties,
      })
      .send()
  }

  public createMSTeamsPresenceMapping(
    organisationId,
    enabled: boolean,
    properties: {userSyncInterval: string | number; clientId: string; tenantId: string; presenceMappings: { [key: string]: string } },
    encryptedProperties: { key: string; value: string; valueHash: string; }[]
  ) {
    const mappedPresences = this.convertToCamelCase(properties.presenceMappings);
    return this.httpClient
      .createRequest(`/v1/organisation/${organisationId}/ms-teams`)
      .asPost()
      .withContent({
        enabled,
        properties: {
          ...properties,
        },
        encryptedProperties,
      })
      .send()
  }

  public convertToCamelCase(presenceMappings: { [key: string]: string }) {
    const camelCaseMapping = {
      'Available': 'Available',
      'Away': 'Away',
      'Be Right Back': 'BeRightBack',
      'Busy': 'Busy',
      'Do Not Disturb': 'DoNotDisturb',
      'In A Call': 'InACall',
      'In A Conference Call': 'InAConferenceCall',
      'Inactive': 'Inactive',
      'In A Meeting': 'InAMeeting',
      'Offline': 'Offline',
      'Off Work': 'OffWork',
      'Out Of Office': 'OutOfOffice',
      'Presence Unknown': 'PresenceUnknown',
      'Presenting': 'Presenting',
      'Urgent Interruptions Only': 'UrgentInterruptionsOnly'
    };
  
    let result = {};
    for (let key in presenceMappings) {
      result[key] = camelCaseMapping[presenceMappings[key]];
    }
  
    return result;
  }
}