import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { observable } from 'aurelia-binding';

import { ZaiForm } from 'zailab.common';

const logger = LogManager.getLogger('Message');

@autoinject
export class Message {
  private model: any;
  public message = '';
  public form: any[][];
  @observable public formData: {
    message: string;
    valid: boolean;
  };

  constructor(private eventAggregator: EventAggregator) {}

  public activate(wizard: any): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.setupForm();
  }

  private initWizard(bindingContext: any): void {
    this.model = bindingContext;
  }

  private extractWizardData(): void {
    this.message = this.model.data.message;
    if (this.message) {
      this.model.step.complete();
    }
  }

  private setupForm(): void {
    new ZaiForm()
      .newField()
      .asTextInput()
      .withFocus()
      .withIdentifier('message')
      .withTitle('Message', '160px')
      .withPlaceholder('Please enter a message.')
      .withValue(this.message)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.COMMON_CHARACTERS },
      ])
      .insertField()
      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public attached(): void {
    this.eventAggregator.publish('wizard.step.activate');
  }

  public formDataChanged(newValue: any): void {
    if (newValue.valid) {
      this.model.step.complete();
      this.model.data.message = newValue.message;
    } else {
      this.model.step.incomplete();
    }
  }
}
