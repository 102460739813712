import bowser from 'bowser';

export function BrowserDetectionService() {

  var self = this;

  var browserDetectionNotificationContainer = document.getElementById('js-detect-browser-notification');
  var browserIcon = document.getElementById('js-detect-icon');
  var browserTitle = document.getElementById('js-detect-title');
  var browserSubTitle = document.getElementById('js-detect-sub-title');
  var browserTitleName = document.getElementById('js-detect-browser-name');
  var browserButton = document.getElementById('js-detect-button');
  var appPreLoader = document.getElementById('js-pre-loader');

  self.isValidBrowser = function () {

    var isChromeBrowser = false;
    var isMobilePlatform = false;
    var browserName = 'Internet Explorer';
    try {
      var tech = bowser.getParser(window.navigator.userAgent).parse().parsedResult;
      isMobilePlatform = tech.platform.type === 'mobile';
      browserName = tech.browser.name;
    } catch (e) {
      console.error(' > error ', e);
    }

    browserDetectionNotificationContainer.style.display = 'none';
    appPreLoader.style.display = 'none';

    if (browserName === 'Chrome') {
      isChromeBrowser = true;
      appPreLoader.style.display = 'flex';
    } else {
      browserDetectionNotificationContainer.style.display = 'flex';
      browserTitleName.innerHTML = 'We\'ve noticed you are using ' + browserName + '.';
    }

    if (browserName === 'Internet Explorer') {
      browserDetectionNotificationContainer.classList.add('is-internet-explorer');
    }

    if (isMobilePlatform && !isChromeBrowser) {
      browserTitle.innerHTML = 'ZaiLab is not currently supported on mobile.';
      browserSubTitle.style.display = 'none';
      browserButton.style.display = 'none';
      browserIcon.classList.remove('o-z-icon--chrome');
      browserIcon.classList.add('o-z-icon--exclamation-triangle');
    }

    return isChromeBrowser;
  };
}