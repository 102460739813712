import { bindable, customElement } from "aurelia-framework";

@customElement('z-rich-text-viewer')
export class RichTextViewer {

  @bindable
  public value: string;

  public editorOptions = {
    readOnly: true,
    theme: 'snow',
    modules: {
      toolbar: false
    }
  }
}