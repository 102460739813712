/*
*/
import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
import { HttpClient } from 'aurelia-http-client';
/*
*/
import { PERFORMED_ASSESSMENT_ACTIONS } from './performedassessment.actions';
import { VIEW_ACTIONS } from "../view/view.actions";
/*
*/
const logger = LogManager.getLogger('PerformedAssessmentService');

/*
*/
@inject(Dispatcher, HttpClient)
export class PerformedAssessmentService {

  constructor(dispatcher, httpClient) {

    this.dispatcher = dispatcher;
    this.httpClient = httpClient;
  }

  retrieveAssessment(assessmentId) {

    this.httpClient.createRequest(`v1/organisation/assessments/${assessmentId}`)
      .asGet()
      .send()
      .then(response => {
        this.dispatcher.dispatch(PERFORMED_ASSESSMENT_ACTIONS.RETRIEVE_ASSESSMENT, response);
      }, error => {
        this.dispatcher.dispatch(PERFORMED_ASSESSMENT_ACTIONS.RETRIEVE_ASSESSMENT, {});
      });
  }

}
