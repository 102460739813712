import { computedFrom, inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AudioService, PushNotificationService } from 'zailab.common';
import { ZONE_MAP } from './incoming-interaction-constants';

const logger = LogManager.getLogger('IncomingInteractionDialog');

@inject(DialogController, EventAggregator, AudioService, PushNotificationService)
export class IncomingInteractionDialog {

  dialogHeader = 'Incoming Call';
  interaction;
  fullName;
  number;
  contactId;
  firstName;
  surname;
  taskName;
  zone;
  externalReference;
  numberOfContacts;
  showFullName = true;

  constructor(dialogController, EventAggregator, audioService, pushNotificationService) {
    this.dialogController = dialogController;
    this.eventAggregator = EventAggregator;
    this.audioService = audioService;
    this.pushNotificationService = pushNotificationService;
  }

  activate(interaction) {
    this.interaction = interaction;
    this.determinePopUpDetails();
    this.pushNotificationService.notify(this.dialogHeader, { body: this.fullName });
  }

  accept() {
    if (this.interaction && this.interaction.channel === 'chat') {
      this.eventAggregator.publish('existing.contact.for.chat');
    }
    this.pushNotificationService.close();
    this.dialogController.ok({ accepted: true });
  }

  reject() {
    this.pushNotificationService.close();
    this.dialogController.cancel({ rejected: true });
  }

  determinePopUpDetails() {
    if (this.interaction) {

      this.determineHeader();
      this.determineIcon();
      this.determineZone();

      if (this.interaction.numberOfContacts) {
        this.numberOfContacts = this.interaction.numberOfContacts;
      }

      if (this.numberOfContacts && Number(this.numberOfContacts) > 1) {
        this.showFullName = false;
      }

      if (!this.interaction.contactId || !this.interaction.firstName && !this.interaction.surname) {
        this.fullName = 'Unknown Contact';
      } else {
        this.firstName = this.interaction.firstName;
        this.surname = this.interaction.surname;
        this.fullName = this.firstName + ' ' + this.surname;
        this.externalReference = this.interaction.externalReference;
      }
    }
  }

  determineHeader() {
    if (this.interaction.channel === 'email') {
      this.dialogHeader = 'Incoming Email';
    } else if (this.interaction.channel === 'chat') {
      this.dialogHeader = 'Incoming Chat';
    } else if (this.interaction.channel === 'sms') {
      this.dialogHeader = 'Incoming SMS'
    } else if (this.interaction.channel === 'instant message') {
      this.dialogHeader = 'Instant Message'
    } else if (this.interaction.channel === 'Ticket') {
      this.dialogHeader = 'Incoming Ticket'
    }
  }

  determineIcon() {
    if (this.interaction.channel === 'sms') {
      this.icon = 'channel-sms';
    } else if (this.interaction.channel === 'Ticket') {
      this.icon = 'channel-ticket';
    } else if (this.interaction.channel === 'email') {
      this.icon = 'channel-email';
    } else if (this.interaction.channel === 'call') {
      this.icon = 'channel-incoming';
    } else if (this.interaction.channel === 'instant message') {
      this.icon = 'comments-square';
    } else {
      this.icon = this.interaction.src;
    }
  }

  determineZone() {
    const number = '' + this.interaction.from;
    if (number.startsWith('+1')) {
      const zoneCode = number.substring(2, 5);
      const zone = ZONE_MAP.get(zoneCode);
      this.zone = zone;
    }
  }

  detached() {
    this.audioService.stop();
  }

  @computedFrom('interaction', 'interaction.channel')
  get isEmail() {
    return this.interaction.channel === 'email';
  }
}
