

import { LogManager } from 'aurelia-framework';
import { ConsoleAppender } from 'aurelia-logging-console';
import { AureliaConfiguration } from 'aurelia-configuration';
import { ViewResources } from 'aurelia-templating';
import { BrowserDetectionService } from './browser-detection-service';
import {PLATFORM} from 'aurelia-pal';

import 'syncfusion-javascript/Scripts/ej/web/ej.grid.min';
import 'syncfusion-javascript/Scripts/ej/web/ej.colorpicker.min';

LogManager.addAppender(new ConsoleAppender());
LogManager.setLevel(LogManager.logLevel.debug);

const logger = LogManager.getLogger('Main');

export function configure(aurelia) {
  if (!(new BrowserDetectionService().isValidBrowser())) {
    return;
  }

  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('aurelia-syncfusion-bridge'), (syncfusion) => {
      syncfusion.ejGrid();
      syncfusion.ejColorPicker();
      return syncfusion;
    })
    .plugin(PLATFORM.moduleName('aurelia-flux'))
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
      config.useDefaults();
      config.settings.ignoreTransitions = true;
      config.settings.startingZIndex = 9999;
    })
    .plugin(PLATFORM.moduleName('aurelia-computed'))
    .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
    .plugin(PLATFORM.moduleName('aurelia-resize'))
    .plugin(PLATFORM.moduleName('aurelia-configuration'), config => {

      config.setEnvironments({
        vagrant: ['192.168.33.10'],
        'local': ['localhost:9000', 'localhost:9001', 'localhost:9002'],
        'dev1': ['dev1.zailab.com', 'dev1us.zailab.com', 'dev1.zailab.com:8000', 'dev1.zailab.com:9000', 'dev1.zailab.com:10000', 'dev1.zailab.com:11000', 'dev1.zailab.com:12000','beta.dev1.zailab.com'],
        'staging': ['staging.zailab.com', 'beta.staging.zailab.com'],
        'prod': ['conversations.zailab.com', 'beta.zailab.com', 'platform.zailab.com'],
      });
    })
    .feature(PLATFORM.moduleName('zailab.common'))
    .feature(PLATFORM.moduleName('zailab.abstract'))
    .feature(PLATFORM.moduleName('zailab.validation'))
    .feature(PLATFORM.moduleName('zailab.tests'))

    .feature(PLATFORM.moduleName('attributes/index'))
    .feature(PLATFORM.moduleName('components/index'))
    .feature(PLATFORM.moduleName('components_v2/index'))
    .feature(PLATFORM.moduleName('converters/index'))
    .feature(PLATFORM.moduleName('hud/index'))

    .globalResources(PLATFORM.moduleName('_custom/steps'));

  let configure = aurelia.container.get(AureliaConfiguration);
  if (configure.environment === 'prod' || configure.environment === 'staging') {
    LogManager.setLevel(LogManager.logLevel.debug);
  }

  const resources = aurelia.container.get(ViewResources);
  window.viewResources = resources;

  aurelia.start().then(a => {
    let root = document.querySelector('#app-main');
    a.setRoot(PLATFORM.moduleName('app'), root);
  });
}
