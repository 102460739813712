import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
* */
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { SkillGroupsListService } from '../skill-groups-service';
import { AddSkillGroupDialog } from '../addskillgroup/add-skill-group-dialog';
import { SkillGroupModel } from "../skill-group-model";
/*
 * */
const logger = LogManager.getLogger('SkillGroupsListView');

/*
 * */
@inject(Element, SkillGroupsListService, Router, EventAggregator, SessionStore, DialogService)
export class SkillGroupsListView extends AbstractList {

  constructor(private element: Element, private skillGroupsListService: SkillGroupsListService, private router: Router, protected eventAggregator: EventAggregator, private sessionStore: SessionStore, private dialogService: DialogService) {
    super(eventAggregator);
  }

  protected activate(params: { _param: string }): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected subscribeToOplog(): void {
    this.skillGroupsListService.initialiseOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', response => {
        this.setupListConfig(this.skillGroupsListService._modelCollection(response.skillGroups));
      });
      this.oplog.on('update', response => {
        if (response.skillGroups) {
          this.setupListConfig(this.skillGroupsListService._modelCollection(response.skillGroups));
          return;
        }

        let _newList = this.itemList;
        for (let index in response) {
          if (response.hasOwnProperty(index) && index.includes('skillGroups')) {
            _newList.items.push(this.skillGroupsListService._modelEntity(response[index]));
          }
        }
        this.setupListConfig(_newList.items);
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.skillGroupsListService.retrieveOrganisationSkillGroups(this.sessionStore.get.organisation.organisationId)
    .then(_response => {
      this.setupListConfig(_response);
      if (this.isAdding) {
        this.clearUrlParams();
        this.showAddItem();
      }
    })
  }

  protected deleteItems(items: SkillGroupModel[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} skill groups deleted.` : `Skill Group <b>${items[0].name}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(item: any): void {
    super.delete(item);
    this.skillGroupsListService.removeSkillGroup(this.sessionStore.get.organisation.organisationId, item);
  }

  protected selectItem(_items:any): void {
    this.router.navigateToRoute('skills', {skillGroupId: _items.item.id}, {replace: true});
  }

  protected showAddItem(): void {
    super.showAddItem();
    this.dialogService.open({viewModel: AddSkillGroupDialog, model: this.itemList.items}).whenClosed(_response => {
      if (_response.wasCancelled) {
        this.hideLoader();
      } else {
        this.deferredMessage = `Skill group <b>${_response['output']}</b> added.`;
        this.skillGroupsListService.addSkillGroup(this.sessionStore.get.organisation.organisationId, _response['output'])
      }
    });
  }

  protected setupListConfig(_skillGroups: SkillGroupModel[]): void {
    super.setupListConfig(_skillGroups);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .enableAdd()
    .enableDelete()
    .enableClick()
    .selectionType(List.SINGLE_SELECTION_TYPE)
    .items(_skillGroups)
    .icon('icon')
    .uniqueIdentifier('id')
    .displayId('name')
    .description('description')
    .withProportion('medium')
    .hasRollover(false)
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  private _log(...args: any): void {
    logger.debug('🔍', ...args);
  }
}
