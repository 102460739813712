import { LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('OrderByValueConverter');

export class OrderByValueConverter {
  toView(list, key, disableOrderBy) {
    if (disableOrderBy) {
      return list;
    }
    if (!list) return [];
    list = _removePlaceholders(list);

    let keys = [];
    if (Object.prototype.toString.call(key) === '[object Array]') {
      keys = key;
    } else {
      keys.push(key);
    }
    
    const sortParams = [];

    for (const k of keys) {

      let kSplit = k.split(':');
      let kValue = kSplit[0];
      let kDesc = false;
      if (kSplit.length > 1) {
        kDesc = kSplit[1] === 'desc';
      }

      key = kValue;
      let desc = kDesc;

      sortParams.push({key, desc});
    }

    const result = list.sort(function (a, b) {

      let c = 0;

      for (const param of sortParams) {

        c = compare(a, b, param.key);

        if (param.desc) {
          c = c * -1;
        }

        if (c !== 0) {
          break;
        }
      }

      return c;
    });
    return result;
  }
}

function compare(a, b, key) {
  let _a = Object.prototype.toString.call(a[key]) === '[object Array]' ? a[key][0] : a[key];
  let _b = Object.prototype.toString.call(b[key]) === '[object Array]' ? b[key][0] : b[key];

  var isNumber = _isNumber(_a) && _isNumber(_b);

  if (isNumber) {

    return (parseInt(_a) > parseInt(_b)) ? 1 : ((parseInt(_b) > parseInt(_a)) ? -1 : 0);
  }

  if (!_a || !_b) return 0;

  return (_a.toLowerCase() > _b.toLowerCase()) ? 1 : ((_b.toLowerCase() > _a.toLowerCase()) ? -1 : 0);
}

function _removePlaceholders(list) {
  let _list = [];
  for (let item of list) {
    if (!item.isPlaceholder) {
      _list.push(item);
    }
  }
  return _list;
}

function _isNumber(val) {
  return /^\d+$/.test(val);
}