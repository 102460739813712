/*
*/
/*
*/
import {inject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
/*
*/
import {UrlFactory} from '../factories/url.factory';
/*
*/
const logger = LogManager.getLogger('PictureService');
/*
*/
@inject(HttpClient, UrlFactory)
export class PictureService {

  constructor(httpClient, urlFactory) {
    this.httpClient = httpClient;
    this.urlFactory = urlFactory;
  }
  retrieveImage(id) {

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(`media/images/${id}`)
        .asGet()
        .withParams({})
        .send()
        .then((response) => {
          resolve('data:image/png;base64,' + response);
        }).catch((error) => {
          reject(error);
      });
    });
  }
}
