import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';

const logger = LogManager.getLogger('ContactControllerSubscriptions');

@autoinject
export class ContactControllerSubscriptions {
 //
  //Contact
  public selectContact: Subscription;
  public selectContactFromConversation: Subscription;
  public selectContactFromRecentActivity: Subscription;
  public selectOpenContact: Subscription;
  public autoSelect: Subscription;
  public editContact: Subscription;
  public contactIdentified: Subscription;
  public searchContact: Subscription;
  public searchContactResult: Subscription;
  public searchContactResultNextPage: Subscription;
  public searchContactNextPage: Subscription;
  public createContact: Subscription;
  public contactUpdated: Subscription;
  public updateContact: Subscription;
  public updateWorkflow: Subscription;
  public contactSearchResults: Subscription;
  public contactThirdPartyMatches: Subscription;

  //Conversation
  public conversationRetrievedForContact: Subscription;
  public conversationListInsert: Subscription;
  public conversationListChanged: Subscription;
  public conversationInteractionsChanged: Subscription;
  public conversationDiscard: Subscription;
  public selectConversation: Subscription;
  public unselectConversation: Subscription;
  public changeConversationName: Subscription;
  public reviewConversationStatus: Subscription;
  public createConversation: Subscription;
  public conversationInteractionUpdate: Subscription;
  public createAndLinkConversation: Subscription;
  public unlinkedInteractionToSelect: Subscription;
  public organisationsMemberUpdated: Subscription;
  public showReminder: Subscription;

  //Interaction
  public linkedUnlinkedInteraction: Subscription;
  public outboundInteractionEnded: Subscription;
  public linkInteractionToConversation: Subscription;
  public uploadAttachment: Subscription;
  public connectedInteractionCall: Subscription;
  public connectedToTask: Subscription;
  public memberDisconnectedFromCall: Subscription;
  public memberDisconnectedFromOutboundFlowCall: Subscription;
  public connectedInteractionEmail: Subscription;
  public connectedInteractionChat: Subscription;
  public connectedInteractionSms: Subscription;
  public outboundConnectedInteractionEmail: Subscription;
  public outboundConnectedInteractionSms: Subscription;
  public viewInteraction: Subscription;
  public unlinkedInteractionChanged: Subscription;
  public selectInteraction: Subscription;
  public selectUnlinkedInteraction: Subscription;
  public unselectInteractions: Subscription;
  public connectedInteraction: Subscription;
  public addSessionToInteraction: Subscription;
  public dockCards: Subscription;
  public interactionLiveStateEnded: Subscription;
  public retrieveRecordingUrl: Subscription;
  public connected: Subscription;
  public incomingCall: Subscription;
  public incomingTask: Subscription;
  public interactionBlocked: Subscription;
  public unknownContactInteraction: Subscription;
  public incomingInteraction: Subscription;
  public sendEmail: Subscription;
  public addNote: Subscription;
  public sendSms: Subscription;
  public wrapUpStart: Subscription;
  public wrapUpInteractionUpdated: Subscription;
  public wrapUpEnd: Subscription;
  public connectedInteractionsRecovered: Subscription;
  public interactionLinkedToConversation: Subscription;
  public callDisconnected: Subscription;
  public callAccepted: Subscription;
  public interactionsRetrieved: Subscription;
  public memberEndedInteraction: Subscription;
  public closeAllContacts: Subscription;

  //Other
  public routingStatusUpdated: Subscription;
  public changeView: Subscription;
  public previousView: Subscription;
  public memberActivityChanged: Subscription;
  public channelSizeExpand: Subscription;
  public channelSizeCollapse: Subscription;
  public channelSizeSmall: Subscription;
  public channelSizeHide: Subscription;
  public changeChanelView: Subscription;
  public resumeInteractionLinking: Subscription;
  public clickToDial: Subscription;
  public wrapUp: Subscription;
  public selectCallOutcome: Subscription;
  public selectDisposition: Subscription;
  public closeDispositionCode: Subscription;
  public details: Subscription;
  public endNow: Subscription;
  public clearDetails: Subscription;
  public callbackClickToDial: Subscription;
  public ended: Subscription;

  //Loaders
  public toggleLoader: Subscription;
  public changeContactInfoLoader: Subscription;
  public changeConversationListLoader: Subscription;
  public changeUnlinkedInteraction: Subscription;
  public changeInteractionList: Subscription;
  public changeSearchLoader: Subscription;
  public memberSelectDispositionCode: Subscription;

  // Oplog
  constructor(private eventAggregator: EventAggregator) {}

  public disposeOfSubscriptions(): void {
    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        this[prop] && this[prop].dispose && this[prop].dispose();
      }
    }
  }
}
