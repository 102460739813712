import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
// @ts-ignore
import { PLATFORM } from 'aurelia-framework';

import { InAppAlertsService } from '../../in-app-alerts-service';
import { ITab1GeneralConfiguration } from './tabs/tab-1-general-configuration/tab-1-general-configuration';
import { ITab2GeneralConfiguration } from './tabs/tab-2-audience-configuration/tab-2-audience-configuration';
import { ITab3Trigger } from './tabs/tab-3-triggers/tab-3-triggers';

import { v4 as uuidv4 } from 'uuid';

declare const PLATFORM: any;

const logger = LogManager.getLogger('DashboardAlertsConfig');
enum AudienceTypeMap {
  'Organisation' = 'ORGANISATION_ID',
  'Business Partner' = 'BUSINESS_PARTNER_ID',
  'Contact Center' = 'CONTACT_CENTER_ID',
  'Team' = 'TEAM_ID',
  'Member' = 'MEMBER_ID',
}
export enum AudienceTypeReverseMap {
  'ORGANISATION_ID' = 'Organisation',
  'BUSINESS_PARTNER_ID' = 'Business Partner',
  'CONTACT_CENTER_ID' = 'Contact Center',
  'TEAM_ID' = 'Team',
  'MEMBER_ID' = 'Member',
}
enum SourceMap {
  'Live Dashboard' = 'LIVE_DASHBOARD',
  'CC Monitoring' = 'CONTACT_CENTER_MONITORING_DASHBOARD',
}
enum SourceReverseMap {
  'LIVE_DASHBOARD' = 'Live Dashboard',
  'CONTACT_CENTER_MONITORING_DASHBOARD' = 'CC Monitoring',
}
export enum SeverityMap {
  'Critical' = 'CRITICAL',
  'Informational' = 'INFORMATIONAL',
  'Warning' = 'WARNING',
}
enum SeverityReverseMap {
  'CRITICAL' = 'Critical',
  'INFORMATIONAL' = 'Informational',
  'WARNING' = 'Warning',
}

export interface IInAppAlert {
  id: string;
  name: string;
  description: string;
  message: string;
  severity: SeverityMap;
  repeatIntervalEnabled: boolean;
  repeatIntervalInSeconds: number;
  emailAlert: boolean;
  emailAlerts: boolean;
  emails: string[];
  inAppAlerts: boolean;
  audienceType: AudienceTypeMap;
  audiences: { audienceType: string, audience: string }[];
  source: SourceMap;
  conditions: Condition[];
  triggers: Trigger[];
  fieldAudiences: string[];
}

interface Condition {
  type: string;
  value: string;
}

interface Trigger {
  field: string;
  operator: string;
  threshold: string | number;
}

@autoinject()
export class DashboardAlertsConfig {
  public changeTab: (index: number) => void;

  private alertId: string;
  public submitting: boolean;
  public tabs: any;
  public defaultTabs = [
    {
      id: '1',
      name: 'General Configuration',
      viewModel: PLATFORM.moduleName(
        'features/organisation/in-app-alerts/list/dashboard-alerts-dialog/tabs/tab-1-general-configuration/tab-1-general-configuration',
      ),
      icon: '',
      selected: true,
      data: {
        id: '',
        name: '',
        description: '',
        message:
          'Firing ${severity} Alert for ${field} with value: ${liveValue}.',
        severity: '',
        repeatIntervalEnabled: false,
        repeatTimeout: '',
        valid: undefined,
      },
    },
    {
      id: '2',
      name: 'Audience Configuration',
      viewModel: PLATFORM.moduleName(
        'features/organisation/in-app-alerts/list/dashboard-alerts-dialog/tabs/tab-2-audience-configuration/tab-2-audience-configuration',
      ),
      icon: '',
      selected: false,
      data: {
        id: '',
        inAppAlert: false,
        copyFrom: '',
        audienceType: null,
        audiences: [],
        audienceIds: [],
        emailAlert: false,
        emails: [],
        valid: undefined,
        inAppAlertList: [],
      },
    },
    {
      id: '3',
      name: 'Triggers',
      viewModel: PLATFORM.moduleName(
        'features/organisation/in-app-alerts/list/dashboard-alerts-dialog/tabs/tab-3-triggers/tab-3-triggers',
      ),
      icon: '',
      selected: false,
      data: {
        id: '',
        source: null,
        conditions: [],
        conditionsIds: [],
        triggers: [],
        mappedTriggers: [],
        fieldAudiences: [],
        valid: undefined,
      },
    },
  ];
  public ready: boolean = false;

  constructor(
    private dialogController: DialogController,
    private inAppAlertsService: InAppAlertsService,
  ) {}

  public async activate(model: {
    inAppAlert?: IInAppAlert;
    existingNames: string[];
    inAppAlertList: any[];
  }): Promise<void> {
    this.defaultTabs[1].data.inAppAlertList = model.inAppAlertList;
    if (model.inAppAlert) {
      this.alertId = model.inAppAlert.id;
      await this.retrieveInAppAlert(model.inAppAlert.id, model.inAppAlertList);
    } else {
      this.defaultTabs[1].data.inAppAlertList = model.inAppAlertList;
      this.tabs = this.defaultTabs;
    }
    this.ready = true;
  }

  private async retrieveInAppAlert(
    inAppAlertId: string,
    inAppAlertList: string[],
  ): Promise<void> {
    const inAppAlert: IInAppAlert = await this.inAppAlertsService
      .retrieveInAppAlert(inAppAlertId)
      .catch((e) =>
        console.warn(' > Failed to retrieve in app alert due to', e),
      );

    if (inAppAlert) {
      this.defaultTabs[0].data = {
        id: this.alertId,
        name: inAppAlert.name,
        description: inAppAlert.description,
        message: inAppAlert.message,
        severity: SeverityReverseMap[inAppAlert.severity],
        repeatIntervalEnabled: !!inAppAlert.repeatIntervalEnabled,
        repeatTimeout: inAppAlert.repeatIntervalInSeconds / 60 + ' min',
        valid: true,
      };

      this.defaultTabs[1].data = {
        id: this.alertId,
        inAppAlert: inAppAlert.inAppAlerts,
        copyFrom: '',
        audienceType: AudienceTypeReverseMap[inAppAlert.audienceType],
        audiences: [],
        audienceIds: inAppAlert.audiences || [],
        emailAlert: inAppAlert.emailAlerts || false,
        emails: inAppAlert.emails || [],
        valid: true,
        inAppAlertList,
      };

      this.defaultTabs[2].data = {
        id: this.alertId,
        source: SourceReverseMap[inAppAlert.source],
        conditions: [],
        conditionsIds: inAppAlert.conditions,
        triggers: [],
        mappedTriggers: inAppAlert.triggers,
        fieldAudiences: inAppAlert.fieldAudiences || [],
        valid: true,
      };

      this.tabs = this.defaultTabs;
    }
  }

  public async save(): Promise<void> {
    let tab1Data: ITab1GeneralConfiguration = this.tabs[0].data;
    let tab2Data: ITab2GeneralConfiguration = this.tabs[1].data;
    let tab3Data: ITab3Trigger = this.tabs[2].data;

    if (!tab1Data.valid) {
      this.changeTab(0);
      return;
    } else if (!tab1Data.valid) {
      this.changeTab(1);
      return;
    } else if (!tab1Data.valid) {
      this.changeTab(2);
      return;
    }

    const payload = {
      name: tab1Data.name,
      description: tab1Data.description,
      message: tab1Data.message,
      severity: SeverityMap[tab1Data.severity],
      repeatIntervalEnabled: !!tab1Data.repeatIntervalEnabled,
      repeatIntervalInSeconds: tab1Data.repeatTimeout
        ? parseInt(tab1Data.repeatTimeout.replace(' min', '')) * 60
        : 0,
      inAppAlerts: tab2Data.inAppAlert,
      audiences: tab2Data.audienceIds.map(audience => {
        return {
          audience: audience.value || audience.audience,
          audienceType: audience.group || audience.audienceType
        };
      }),
      emailAlert: tab2Data.emailAlert,
      emails: tab2Data.emails,
      source: SourceMap[tab3Data.source],
      conditions: tab3Data.conditionsIds,
      triggers: tab3Data.mappedTriggers,
      fieldAudiences: tab3Data.mappedTriggers,
    };

    this.submitting = true;

    if (this.alertId) {
      this.inAppAlertsService
        .updateInAppAlert(
          this.alertId,
          payload.name,
          payload.description,
          payload.message,
          payload.severity,
          payload.repeatIntervalEnabled,
          payload.repeatIntervalInSeconds,
          payload.inAppAlerts,
          payload.audiences,
          payload.emailAlert,
          payload.emails,
          payload.source,
          payload.conditions,
          payload.triggers,
        )
        .then(() => {
          this.dialogController.ok({
            id: this.alertId,
            ...payload,
          });
        })
        .catch((e) => {
          console.warn(' > Failed to create in app alert due to ', e);
          this.submitting = false;
        });
    } else {
      const alertId = uuidv4();
      this.inAppAlertsService
        .createInAppAlert(
          alertId,
          payload.name,
          payload.description,
          payload.message,
          payload.severity,
          payload.repeatIntervalEnabled,
          payload.repeatIntervalInSeconds,
          payload.inAppAlerts,
          payload.audiences,
          payload.emailAlert,
          payload.emails,
          payload.source,
          payload.conditions,
          payload.triggers,
        )
        .then(() => {
          this.dialogController.ok({
            id: alertId,
            ...payload,
          });
        })
        .catch((e) => {
          console.warn(' > Failed to create in app alert due to ', e);
          this.submitting = false;
        });
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public get isValid(): boolean {
    if (!this.tabs) {
      return false;
    }
    return (
      this.tabs[0].data.valid &&
      this.tabs[1].data.valid &&
      this.tabs[2].data.valid
    );
  }
}
