import { autoinject, LogManager } from 'aurelia-framework';

import { DownloadService, SessionStore } from 'zailab.common';
import { ConversationService } from '../conversation/conversation-service';
import { ContactService } from './contact-service';

import { EventAggregator } from 'aurelia-event-aggregator';
import { ConversationFactory } from '../conversation/conversation-factory';
import { CallInteractionCardService } from "../conversation/interactioncards/call/call-interaction-card-service";
import { WorkTypesService } from '../conversation/worktypes/work-types-service';
import { DoNotContactState } from '../donotcontact/donotcontact-state';
import { TransferListService } from '../transferlist/transfer-list-service';
import { ContactModel } from './contact-model';
import { ChannelViewStrategyModel } from './contactcontroller/channel-view-strategy-model';

const logger = LogManager.getLogger('ContactFactory');

@autoinject
export class ContactFactory {
  constructor(
    private contactService: ContactService,
    private conversationService: ConversationService,
    private conversationFactory: ConversationFactory,
    private eventAggregator: EventAggregator,
    private downloadService: DownloadService,
    private sessionStore: SessionStore,
    private callInteractionCardService: CallInteractionCardService,
    private workTypeService: WorkTypesService,
    private transferListService: TransferListService,
    private dncState: DoNotContactState,
  ) { }

  public build(contactId?: string): ContactModel {
    return new ContactModel(this.contactService, this.conversationService, this.conversationFactory, this.eventAggregator, new ChannelViewStrategyModel(), this.downloadService, contactId, this.sessionStore, this.callInteractionCardService, this.workTypeService, this.transferListService, this.dncState);
  }
}
