import { bindable, customElement, LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('ZGridCell');

@customElement('z-grid-cell')
export class ZGridCell {

  @bindable
  public id: string;

  @bindable
  public width: string;

  @bindable
  public height: string;

  private cell: HTMLElement;

  public bind(): void {
    this.cell.id = this.id;
    this.cell.style.width = this.width;
    this.cell.style.height = this.height;
    this.cell.style.pointerEvents = 'none';
  }
}