import {LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('OrganisationModel');
/*
 */
export default class extends BaseModel {

  isNew = null;
  activated = null;
  country = 'ZA';
  integrationToken = null;
  profilePicture = null;
  organisationId = null;
  organisationName = null;
  samlEnabled = false;
  verintEnabled = false;
  path = null;
  metadataUrl = null;
  idPRedirectUrl = null;
  ldapConnectorInformation = null;
  interactionAnalyticsProviderConfig = null;

  constructor(organisation) {

    super();
    super.mapProperties(organisation);
  }
}