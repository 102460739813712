import { inject, LogManager } from 'aurelia-framework';
import { Dispatcher } from 'aurelia-flux';
import { HttpClient } from 'aurelia-http-client';

import { SessionStore, OplogService } from 'zailab.common';
import { LIST_ACTIONS } from './list.actions';
import { CREATE_ACTIONS } from '../create/create.actions';
import { SELECT_FORM_ACTIONS } from "../../assessment/selectform/selectform.actions";

const logger = LogManager.getLogger('ListService');

@inject(Dispatcher, SessionStore, OplogService, HttpClient)
export class ListService {
  constructor(dispatcher, sessionStore, oplogService, httpClient) {
    this.dispatcher = dispatcher;
    this.sessionStore = sessionStore;
    this.oplog = oplogService;
    this.httpClient = httpClient
  }

  initialiseOplog(organisationId) {
    return new Promise(resolve => {
      resolve(this.oplog.subscribeOn('organisationId', organisationId).in('quality-assurance-projector.qAFormListView'));
    });
  }

  retrieveForms(organisationId) {
    this.httpClient.createRequest('v1/organisation/forms')
      .asGet()
      .send()
      .then(
        (response) => {
          if (response && response.forms && response.forms.length > 0) {
            this.dispatcher.dispatch(LIST_ACTIONS.RETRIEVE_FORMS, response.forms);
          } else {
            this.dispatcher.dispatch(LIST_ACTIONS.RETRIEVE_FORMS, []);
          }

        }, (error) => {
          this.dispatcher.dispatch(LIST_ACTIONS.RETRIEVE_FORMS, [])
        });
  }

  clearState() {
    this.dispatcher.dispatch(CREATE_ACTIONS.CLEAR_STATE);
  }
}
