import { HttpClient } from 'aurelia-http-client';
import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { TEAM_LEADER_EVENTS } from './performancemanagement-actions';
import { CommandFactory, SessionStore, OplogService } from 'zailab.common';

@inject(HttpClient, SessionStore, OplogService, EventAggregator)
export class WidgetServiceAbstract {

  protected organisationId: string;
  protected teamId: string;

  constructor(protected httpClient: HttpClient, protected sessionStore: SessionStore, protected oplogService: OplogService, protected eventAggregator: EventAggregator) {
    this.organisationId = this.sessionStore.get.organisation.organisationId;
  }

  protected initialiseOplog(_teamId: string, _path: string, _command: string): any {
    // Will override
  }
}