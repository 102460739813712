import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';
import {autoinject, LogManager, Factory} from 'aurelia-framework';

import {OplogService, SessionStore} from 'zailab.common';
import { ZIOplog } from '../../../../typings/zai/zai.common';

import { v4 as uuidv4 } from 'uuid';

const API_URLS = {
  LOAD_BALANCER: 'v1/interaction/load-balancers'
};

const logger = LogManager.getLogger('LoadBalancerService');

@autoinject
export class LoadBalancerService {

  constructor(private httpClient: HttpClient, private sessionStore: SessionStore, private oplog: OplogService) {}

  public initialiseOplog(): Promise<ZIOplog> {
    let organisationId = this.sessionStore.get.organisation.organisationId;
    return new Promise((resolve) => {
      // @ts-ignore
      resolve(this.oplog.subscribeOn('organisationId', organisationId).in('interaction-flow-projector.loadBalancerView'));
    });
  }

  public createLoadBalancer(loadBalancer: ZLoadBalancer): Promise<ZLoadBalancer> {
    return this.httpClient.createRequest(`${API_URLS.LOAD_BALANCER}/${uuidv4()}`)
      .asPost()
      .withContent(loadBalancer)
      .send()
      .catch((error) => logger.warn(`Failed to create load balancer with id ${loadBalancer.id} due to cause:`, error));
  }

  public addRatio(loadBalancerId: string, ratio: ZRatio): Promise<ZLoadBalancer> {
    return this.httpClient.createRequest(`${API_URLS.LOAD_BALANCER}/${loadBalancerId}/ratios`)
      .asPut()
      .withContent(ratio)
      .send()
      .catch((error) => logger.warn(`Failed to create load ratio with id ${loadBalancerId} due to cause:`, error));
  }

  public retrieveLoadBalancers(): Promise<{ loadBalancers: ZLoadBalancer[] }> {
    return this.httpClient.createRequest(API_URLS.LOAD_BALANCER)
      .asGet()
      .send()
      .catch((error) => logger.warn('Failed to retrieve load balancers due to cause:', error));
  }

  public retrieveLoadBalancer(loadBalancerId: string): Promise<ZLoadBalancer> {
    return this.httpClient.createRequest(`${API_URLS.LOAD_BALANCER}/${loadBalancerId}`)
      .asGet()
      .send()
      .catch((error) => logger.warn(`Failed to retrieve load balancer with id ${loadBalancerId} due to cause:`, error));
  }

  public deleteLoadBalancer(loadBalancerId: string): Promise<ZLoadBalancer> {
    return this.httpClient.createRequest(`${API_URLS.LOAD_BALANCER}/${loadBalancerId}`)
      .asDelete()
      .send()
      .catch((error) => logger.warn(`Failed to delete load balancer with id ${loadBalancerId} due to cause:`, error));
  }
}
