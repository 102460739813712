import {BaseModel} from "zailab.abstract";

export class IntegrationModel extends BaseModel {

  public id: string = null;
  public name: string = null;
  public isAuthenticated: boolean = null;
  public isSynced: boolean = null;

  constructor(integration?: IntegrationModel) {
    super();
    super.mapProperties(integration);
  }
}