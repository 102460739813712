import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { ApplicationProperties, OplogService, SessionStore } from 'zailab.common';

const logger = LogManager.getLogger('IMInteractionCardService');

@autoinject()
export class IMInteractionCardService {
  constructor(
    private httpClient: HttpClient,
    private oplog: OplogService,
    private applicationProperties: ApplicationProperties,
    private sessionStore: SessionStore
  ) {}

  public sendChatMessage(
    sessionId: string,
    messageId: string,
    message: InstantMessageBody,
    file?: { name: string; content: string; type: string }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if (file) {
        formData.append('file', file.content);
      }

      const url = `v1/organisation/interactions/sessions/${sessionId}/messages/${messageId}`;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', this.applicationProperties.apiQueryEndpoint + url, true);
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + this.sessionStore.get.user.token
      );
      formData.append(
        'message',
        new Blob([JSON.stringify(message)], {
          type: 'application/json',
        })
      );

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(xhr.response);
          } else if (xhr.status === 200) {
            console.log(' > message sent', xhr.response);
            resolve(xhr.response);
          } else {
            console.log('Failed to send message.');
            reject();
          }
        }
      };
      xhr.send(formData);
    });
  }

  public sendInteracomIOMessage(
    interactionId: string,
    message: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/intercom/chat/chats/${interactionId}/message`)
      .asPost()
      .withContent({ body: message, email: 'test@test.com', name: 'Agent' })
      .send();
  }

  public getTranscript(interactionId: string): Promise<any> {
    return this.httpClient
      .createRequest(
        `v1/organisation/interactions/${interactionId}/imtranscript`
      )
      .asGet()
      .send()
      .then((response: any) => {
        let noContent = 204;
        if (!response || response.statusCode === noContent) {
          return {};
        } else {
          let transcript = response.transcript;
          transcript.expired = response.expired;
          transcript.userTyping = response.userTyping;
          return transcript;
        }
      });
  }

  public onTranscriptChanged(interactionId: string): {
    unsubscribe: () => void;
    on: (op: string, callback: (data: any) => void) => void;
  } {
    // @ts-ignore
    return this.oplog
      .subscribeOn('interactionId', interactionId)
      .in('interaction-projector.imTranscriptView');
  }

  public endInteraction(sessionId: string): void {
    this.httpClient
      .createRequest(`v1/organisation/interactions/sessions/${sessionId}/end`)
      .asPut()
      .send();
  }
}

export class InstantMessageBody {
  public to: string;
  public text: string;
  public sender: {
    number: string;
    memberId: string;
  };
  public media: {
    type: string;
    url: string;
    title: string;
  };
}
