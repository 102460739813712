import { LogManager, computedFrom } from 'aurelia-framework';
import { InteractionModel } from '../conversation/interaction-model';
import { CustomFieldType } from './custom-field-type';

const logger = LogManager.getLogger('CustomFieldModel');

export class CustomFieldModel {

  public customFieldId: string = null;
  public name: string = null;
  private type: CustomFieldType = null;
  private deleted: boolean = false;
  private dropdownChoices: Array<string> = [];
  private dropItems: any = [];
  public value: string = null;


  constructor(customField: CustomFieldModel) {
    this.customFieldId = customField.customFieldId;
    this.name = customField.name;
    this.type = customField.type;
    this.deleted = customField.deleted;
    this.dropdownChoices = new Array<string>('None');
    this.dropdownChoices = this.dropdownChoices.concat(customField.dropdownChoices);
    if(this.dropdownChoices && this.dropdownChoices.length > 0){
      this.dropdownChoices.forEach(value => {
        this.dropItems.push({id:'',name:value});
      })
    }
  }
}
