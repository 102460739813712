import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { SessionStore } from 'zailab.common';
import { InteractionService } from '../interaction-service';
import { INTERACTION_ACTIONS } from '../../contact/contactcontroller/contact-controller-actions';
import { MemberInteractionModel } from '../../conversation/interaction/member-interaction-model';

const logger = LogManager.getLogger('ConnectedInteractions');

@autoinject()
export class ConnectedInteractions {

  private memberInteractions: MemberInteractionModel[] = [];

  constructor(private interactionService: InteractionService, private sessionStore: SessionStore, private eventAggregator: EventAggregator) {
  }

  public initialise(): void {
    this.interactionService.retrieveConnectedInteractions(this.sessionStore.get.user.memberId).then((response: any) => {

      if(response) {
        this.memberInteractions = response.activeInteractions ? response.activeInteractions : response;
        if (this.memberInteractions && this.memberInteractions.length > 0) {
          this.eventAggregator.publish(INTERACTION_ACTIONS.CONNECTED_INTERACTIONS_RECOVERED, { interactions: this.memberInteractions });
        }
      }
    }).catch((error) => {
      logger.info('Could not recover Connected Interactions', error);
    });
  }
}
