
import { autoinject, computedFrom } from 'aurelia-framework';
import { LogManager } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SESSION_EVENTS, SESSION_KEYS } from 'zailab.common';
import { ApplicationSessionModel } from './sessionmodels/application-session-model';
import { AccountSessionModel } from './sessionmodels/account-session-model';
import { OrganisationSessionModel } from './sessionmodels/organisation-session-model';
import { UserSessionModel } from './sessionmodels/user-session-model';
import { WeatherSessionModel } from './sessionmodels/weather-session-model';

import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('SessionStore');

class Store {

  constructor(private eventAggregator: EventAggregator) {
    this.getInitialSession();
    this.subscribeToDataUpdateRequests();
  }

  // -----------  set STATE data ------------
  private subscribeToDataUpdateRequests(): void {
    for (let key in SESSION_EVENTS) {
      if (SESSION_EVENTS.hasOwnProperty(key) && key !== 'UPDATED') {
        const event = SESSION_EVENTS[key];
        this.eventAggregator.subscribe(event.EVENT, (data) => this.handleEvent(data, event.NAME)); // the only class where EventAggregatorSubscription.dispose() isn't needed
      }
    }
  } 

  private handleEvent(data: any, eventName: string): void {
    if (SESSION_EVENTS[eventName]) {
      if (eventName === SESSION_EVENTS.RESET.NAME) {
        this.clearState();
        return;
      }
      this.validateStateData(eventName, data);
    }
  }

  private validateStateData(eventName: string, data: any): void {
    const key = SESSION_KEYS[eventName];
    if (data) {
      this.updateState(eventName, key, data);
    } else {
      this.removeState(key);
    }
    this.sendUpdateNotification(eventName);
  }

  private updateState(eventName: string, key: string, data: any): void {
    if (Array.isArray(data)) {
      this[key] = data;
    } else {

      if (this[key]) {
        this[key].mapEntity(data);
      } else {
        this[key] = (() => {
          if (eventName === SESSION_EVENTS.APPLICATION.NAME) {
            let model = new ApplicationSessionModel(data);
            return model;
          }
          if (eventName === SESSION_EVENTS.ACCOUNT.NAME) {
            let model = new AccountSessionModel(data);
            return model;
          }
          if (eventName === SESSION_EVENTS.ORGANISATION.NAME) {
            let model = new OrganisationSessionModel(data);
            return model;
          }
          if (eventName === SESSION_EVENTS.USER.NAME) {
            let model = new UserSessionModel(data);
            return model;
          }
          if (eventName === SESSION_EVENTS.WEATHER.NAME) {
            let model = new WeatherSessionModel(data);
            return model;
          }
        })();
      }
    }
    this.storeData();
  }

  private sendUpdateNotification(eventName: string): void {
    if (SESSION_EVENTS.UPDATED[eventName]) {
      this.eventAggregator.publish(SESSION_EVENTS.UPDATED[eventName]);
    }
  }

  private storeData(isClearingState?: boolean): void {

    let currentState = JSON.parse(sessionStorage.getItem(SESSION_KEYS.CONVERSATIONS));
    let account;
    let organisation;
    let user;
    let weather;

    if(currentState && !isClearingState) {
      account = currentState[SESSION_KEYS.ACCOUNT];
      organisation = currentState[SESSION_KEYS.ORGANISATION];
      user = currentState[SESSION_KEYS.USER];
      weather = currentState[SESSION_KEYS.WEATHER];
    }

    sessionStorage.setItem(SESSION_KEYS.CONVERSATIONS, JSON.stringify({
      [SESSION_KEYS.APPLICATION]: this[SESSION_KEYS.APPLICATION],
      [SESSION_KEYS.ACCOUNT]: this[SESSION_KEYS.ACCOUNT] || account,
      [SESSION_KEYS.ORGANISATION]: this[SESSION_KEYS.ORGANISATION] || organisation,
      [SESSION_KEYS.USER]: this[SESSION_KEYS.USER] || user,
      [SESSION_KEYS.WEATHER]: this[SESSION_KEYS.WEATHER] || weather
    }));
  }

  private removeState(key: string): void {
    if (this[key]) {
      this[key] = null;
    }
    this.storeData(true);
  }

  private clearState(): void {

    this.removeState(SESSION_KEYS.ACCOUNT);
    this.removeState(SESSION_KEYS.ORGANISATION);
    this.removeState(SESSION_KEYS.USER);

    // todo: (flaap) to be removed in a next deploy
    //                      |
    //                      v
    sessionStorage.removeItem(SESSION_KEYS.ACCOUNT);
    sessionStorage.removeItem(SESSION_KEYS.ORGANISATION);
    sessionStorage.removeItem(SESSION_KEYS.USER);
    //                      ^
    //                      |
  }
  // -----------  set STATE data end ------------

  // -----------  get STATE data ------------
  // get sessionStorage data
  private getInitialSession(): void {
    
    this.handleEvent({ appId: uuidv4()}, SESSION_EVENTS.APPLICATION.NAME);
    let data = JSON.parse(sessionStorage.getItem(SESSION_KEYS.CONVERSATIONS)) || {};
    Object.assign(this, this.bindToModels(data));
  }

  private bindToModels(data: ISession): ISession {
    data[SESSION_KEYS.APPLICATION] = data[SESSION_KEYS.APPLICATION] ? new ApplicationSessionModel(data[SESSION_KEYS.APPLICATION]) : null;
    data[SESSION_KEYS.ACCOUNT] = data[SESSION_KEYS.ACCOUNT] ? new AccountSessionModel(data[SESSION_KEYS.ACCOUNT]) : null;
    data[SESSION_KEYS.ORGANISATION] = data[SESSION_KEYS.ORGANISATION] ? new OrganisationSessionModel(data[SESSION_KEYS.ORGANISATION]) : new OrganisationSessionModel(null);
    data[SESSION_KEYS.USER] = data[SESSION_KEYS.USER] ? new UserSessionModel(data[SESSION_KEYS.USER]) : null;
    data[SESSION_KEYS.WEATHER] = data[SESSION_KEYS.WEATHER] ? new WeatherSessionModel(data[SESSION_KEYS.WEATHER]) : null;
    return data;
  }

  // - application
  @computedFrom(SESSION_KEYS.APPLICATION)
  public get application(): ApplicationSessionModel {
    return this[SESSION_KEYS.APPLICATION];
  }

  // - account
  @computedFrom(SESSION_KEYS.ACCOUNT)
  public get account(): AccountSessionModel {
    return this[SESSION_KEYS.ACCOUNT];
  }

  // - organisation
  @computedFrom(SESSION_KEYS.ORGANISATION)
  public get organisation(): OrganisationSessionModel {
    return this[SESSION_KEYS.ORGANISATION];
  }

  // - user
  @computedFrom(SESSION_KEYS.USER)
  public get user(): UserSessionModel {
    return this[SESSION_KEYS.USER];
  }

  // - weather
  @computedFrom(SESSION_KEYS.WEATHER)
  public get weather(): WeatherSessionModel {
    return this[SESSION_KEYS.WEATHER];
  }
  // -----------  get STATE data end ------------
}

@autoinject
export class SessionStore {

  public get: Store;

  constructor(eventAggregator: EventAggregator) {

    this.get = new Store(eventAggregator);

    // todo: (flaap) to be removed in a next deploy
    //                      |
    //                      v
    this.validateAndRestoreState(eventAggregator);
    //                      ^
    //                      
  }

  // todo: (flaap) to be removed in a next deploy
  //                      |
  //                      v
  private validateAndRestoreState(eventAggregator: EventAggregator): void {

    const accountData = sessionStorage.getItem(SESSION_KEYS.ACCOUNT);
    const organisationData = sessionStorage.getItem(SESSION_KEYS.ORGANISATION);
    const userData = sessionStorage.getItem(SESSION_KEYS.USER);
    const weatherData = sessionStorage.getItem(SESSION_KEYS.WEATHER);

    const oldData = (() => {
      if (accountData || organisationData || userData) {
        return true;
      }
      return false;
    })();
    const newData = this.get.user ? true : false;

    if (oldData && !newData) {
      eventAggregator.publish(SESSION_EVENTS.ACCOUNT.EVENT, (accountData && typeof accountData === 'string' ? JSON.parse(accountData) : accountData));
      eventAggregator.publish(SESSION_EVENTS.ORGANISATION.EVENT, (organisationData && typeof organisationData === 'string' ? JSON.parse(organisationData) : organisationData));
      eventAggregator.publish(SESSION_EVENTS.USER.EVENT, (userData && typeof userData === 'string' ? JSON.parse(userData) : userData));
      eventAggregator.publish(SESSION_EVENTS.WEATHER.EVENT, (weatherData && typeof weatherData === 'string' ? JSON.parse(weatherData) : weatherData));
    }

    if (accountData) {
      sessionStorage.removeItem(SESSION_KEYS.ACCOUNT);
    }
    if (organisationData) {
      sessionStorage.removeItem(SESSION_KEYS.ORGANISATION);
    }
    if (userData) {
      sessionStorage.removeItem(SESSION_KEYS.USER);
    }
    if (weatherData) {
      sessionStorage.removeItem(SESSION_KEYS.WEATHER);
    }
  }
  //                      ^
  //                      |
}

interface ISession {
  zai_acc: AccountSessionModel;
  zai_organisation: OrganisationSessionModel;
  zai_user: UserSessionModel;
  zai_temperature: WeatherSessionModel;
}

interface IRole {
  organisationId: string;
  memberId: string;
  accountType: string;
  role: string;
}

interface IChannels {
  hasOutboundChannel: boolean;
  hasSMSChannel: boolean;
  hasEmailChannel: boolean;
}

interface IOrganisation {
  organisationId: string;
}


interface ISubscription {
  dispose(): void;
}