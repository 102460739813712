/* */
import {LogManager, customElement, inject, bindable} from 'aurelia-framework';
import {CUSTOM_EVENTS} from 'zailab.common';
/* */
const logger = LogManager.getLogger('ZFileUpload');
/* */
@inject(Element)
@customElement('z-file-dragdrop')
export class ZFileDragdrop {

  @bindable filePrompt:string = "Drag and drop a file into this space.";
  private uploadedFile:File;
  private errorMapping: Array<ZIAudioFileError> = [
    {
      key: "File type does not match filter",
      customMessage: "File type does not match the requirements."
    },
    {
      key: "File exceeds file size limit",
      customMessage: "File exceeds file size limit of 10MB."
    }
  ];

  constructor(private element: Element) {

  }

  // All requirements have been met and the file is available
  public handleFileLoad = (file: File, fileData:any) => {
    this.uploadedFile = file;
    this.filePrompt = file.name;
    // Signal the parent that the file is valid
    this.element.dispatchEvent(
      new CustomEvent(CUSTOM_EVENTS.AUDIO_DRAGDROP_COMPLETE, {
        bubbles: true,
        detail: this.uploadedFile
      })
    );
  };

  // There was an error with the file upload
  // Map the aurelia-files error code to something more relevant to Zai
  public handleFileError = (file: File, error: string) => {
    let errorThrown = this.errorMapping.find(errorObj => errorObj.key === error).customMessage || "An unknown error occurred.";
    logger.info("Error thrown", errorThrown);
    this.filePrompt = errorThrown;
  };
}