import { FeatureFlagService } from './../../../../../../featureflags/feature-flag-service';
import { autoinject } from "aurelia-dependency-injection";
import { bindable, customElement, LogManager } from "aurelia-framework";
import { Event, SessionStore } from "zailab.common";
import { TelephonyService } from "../../../../../../telephony/telephony-service";
import { ContactModel } from "../../../../../contact/contact-model";
import { InteractionModel } from "../../../../interaction-model";
import { MemberModel } from "../../../../member-model";
import { computedFrom } from 'aurelia-binding';

const logger = LogManager.getLogger('TransferToMember');

@autoinject
@customElement('z-transfer-to-member')
export class TransferToMember {
  private consultStateKey = 'consultState';

  @bindable public interaction: InteractionModel;
  @bindable public contact: ContactModel;
  @bindable({ attribute: 'is-active' }) public isActive: boolean;
  @bindable({ attribute: 'consult-disabled' }) public consultDisabled: boolean;
  @bindable public channel?: string;

  public conferenceIsEnabled: boolean = null;

  public searchText: string;
  public selectedMember: MemberModel;
  public displaySearchResults: boolean;
  
  private autoCloseTimer: any;
  private jsSearchInput: HTMLElement;
  public attemptingTransfer: boolean;
  public attemptingConnectParties: boolean;
  public gettingMembers: boolean = false;
  
  public attemptingConsult: boolean;
  public consultAttempted: boolean;
  public attemptingCancelConsult: boolean;
  public isValidManualNumber: boolean = true;

  public attemptingAddToConference: boolean;
  
  private attemptTime: number = 5000;
  
  public showMoreEnabled: boolean = true;
  private pageNumber: number = 0;
  private pageSize: number = 20;

  constructor(
    private element: Element,
    private sessionStore: SessionStore,
    private telephonyService: TelephonyService,
    private featureFlagService: FeatureFlagService
  ) {}

  public async bind(): Promise<void> {
    this.conferenceIsEnabled = await this.featureFlagService.isEnabled('ConferenceIsEnabled');
    const consultState = this.getConsultState();
    this.selectedMember = consultState.selectedMember || null;
    this.searchText = consultState.searchText || '';
  }

  public showMemberAvailability(event: Event): void {
    event.stopPropagation();
    this.isActive = true;
  }
  
  public hideMemberAvailability(event: Event): void {
    event.stopPropagation();
    this.selectedMember = null;
    this.searchText = '';
    this.isActive = false;
    this.hideSearchResults();
    new Event(this.element, 'close', {});
  }

  public hideSearchResults(): void {
    this.displaySearchResults = false;
  }

  public startAutoCloseMenu(_delay: number): void {
    this.autoCloseTimer = setTimeout(() => {
      if (this.jsSearchInput === document.activeElement) {
        return;
      }
      this.displaySearchResults = false;
    }, _delay);
  }

  public preventAutoClose(): void {
    window.clearTimeout(this.autoCloseTimer);
  }

  public toggleSearchResults(event: Event): void {
    event.stopPropagation();

    if (this.gettingMembers) {
      return;
    }
    this.displaySearchResults = true;
    this.pageNumber = 0;

    if (this.displaySearchResults) {
      this.gettingMembers = true;
      let searchText = this.searchText || (this.selectedMember ? this.selectedMember.fullName : '');
      this.contact
        .retrieveOrganisationMembers(searchText, this.pageNumber, this.pageSize)
        .then(() => 
          setTimeout(() => {
            this.gettingMembers = false;
          }, 1000)
        );
    }
  }

  public showMore(): void {
    this.pageNumber++;
    let searchText = this.selectedMember ? this.selectedMember.fullName : '';
    this.contact.retrieveOrganisationMembers(searchText, this.pageNumber, this.pageSize, true);
  }
  
  public selectMemberToTransfer(member: MemberModel, event?: Event): void {
    event && event.stopPropagation();
    this.selectedMember = member;
    this.searchText = member.fullName;
    this.displaySearchResults = false;
    this.isValidManualNumber = true;
  }

  private debounceTimeout = null;
  public inputValueChanged(): void {
    this.selectedMember = null;
    
    if (this.debounceTimeout !== null) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    } 

    if (this.selectedMember) {
      return;
    }
    this.debounceTimeout = setTimeout(() => {
      this.debounceTimeout = null;
      
      if (this.searchText && this.isValidNumber(this.searchText)) {
        this.isValidManualNumber = true;
      } else {
        this.isValidManualNumber = false;
      }

      if (this.displaySearchResults) {
        this.contact.retrieveOrganisationMembers(this.searchText, this.pageNumber, this.pageSize);
      }
    }, 300);
  }
  
  public consult(event: Event): void {
    event.stopPropagation();

    if (this.attemptingConsult) {
      return;
    }

    if (this.selectedMember) {
      this.storeConsultState({ selectedMember: this.selectedMember });
      this.notifyConsultAttempt();
      this.setConsultMember(this.interaction.interactionId, 'member', this.selectedMember.extension);
      this.telephonyService
        .consult(
          this.interaction.interactionId,
          this.selectedMember.memberId,
          this.sessionStore.get.user.memberId
        );
    } else if (this.searchText) {
      if (this.isValidNumber(this.searchText)) {
        this.storeConsultState({ searchText: this.searchText });
        this.notifyConsultAttempt();
        this.setConsultMember(this.interaction.interactionId, 'searchText', this.searchText);
        this.telephonyService
          .consultDestination(
            this.interaction.interactionId,
            this.searchText,
            this.sessionStore.get.user.memberId
          );
      }
    }
  }
  private notifyConsultAttempt(): void {
    this.attemptingConsult = true;
    this.consultAttempted = true;
    setTimeout(() => {
      this.attemptingConsult = false;
    }, this.attemptTime);
  }

  private storeConsultState(state: any): void {
    const storedState = JSON.parse(localStorage.getItem(this.consultStateKey) || '{}');
    const newState = { ...storedState, ...state };
    localStorage.setItem(this.consultStateKey, JSON.stringify(newState));
  }

  private getConsultState(): any {
    return JSON.parse(localStorage.getItem(this.consultStateKey) || '{}');
  }

  private clearConsultState(): void {
    localStorage.removeItem(this.consultStateKey);
  }

  private setConsultMember(interactionId: string, destinationType: string, destination: string): void {
    const record = {
      [interactionId]: {
        transferType: 'MEMBER',
        destinationType,
        destination
      }
    };
    localStorage.setItem('zai-consult', JSON.stringify(record));
  }
  
  public transfer(event: Event): void {
    event.stopPropagation();
    
    if (this.attemptingTransfer) {
      return;
    }
    if (this.channel === 'chat' && this.selectedMember) {
      this.notifyTransferAttempt();
      this.telephonyService.transferChat(this.interaction.interactionId, this.selectedMember.memberId, this.sessionStore.get.user.memberId);
    } else if (this.selectedMember) {
      this.notifyTransferAttempt();
      this.telephonyService.transfer(this.interaction.interactionId, this.selectedMember.extension, this.sessionStore.get.user.memberId);
    } else if (this.searchText) {
      if (this.isValidNumber(this.searchText)) {
        this.notifyTransferAttempt();
        this.telephonyService.transfer(this.interaction.interactionId, this.searchText, this.sessionStore.get.user.memberId);
      }
    }
    this.searchText = '';
  }
  private notifyTransferAttempt(): void {
    this.attemptingTransfer = true;
    setTimeout(() => {
      this.attemptingTransfer = false;
    }, this.attemptTime);
  }
  
  
  public connectParties(event: Event): void {
    event.stopPropagation();
    
    if (this.attemptingConnectParties || this.interaction.conference) {
      return;
    }

    if (this.selectedMember) {
      this.notifyConnectPartiesAttempt();
      this.telephonyService.connect(this.interaction.interactionId, this.selectedMember.memberId, this.sessionStore.get.user.memberId);
      this.clearConsultState();
    } else if (this.searchText) {
      if (this.isValidNumber(this.searchText)) {
        this.notifyConnectPartiesAttempt();
        this.telephonyService.connectDestination(this.interaction.interactionId, this.searchText, this.sessionStore.get.user.memberId);
        this.clearConsultState();
      }
    }
  }
  private notifyConnectPartiesAttempt(): void {
    this.attemptingConnectParties = true;
    setTimeout(() => {
      this.attemptingConnectParties = false;
    }, this.attemptTime);
  }


  public addToConference(event: Event): void {
    event.stopPropagation();

    if (this.attemptingAddToConference) {
      return;
    }
    this.notifyAddToConference();
    if (this.selectedMember) {
      this.telephonyService
        .addToConference(this.sessionStore.get.organisation.organisationId, this.interaction.interactionId, this.sessionStore.get.user.memberId)
        .catch(error => logger.warn(' > Failed to add to conference due to', error));
    } else {
      this.telephonyService
        .addToConference(this.sessionStore.get.organisation.organisationId, this.interaction.interactionId, null, this.searchText)
        .catch(error => logger.warn(' > Failed to add to conference due to', error));
    }
    this.clearConsultState();
  }
  private notifyAddToConference(): void {
    this.attemptingAddToConference = true;
    this.consultAttempted = false;
    setTimeout(() => {
      this.attemptingAddToConference = false;
    }, this.attemptTime);
  }

  public cancelConsult(event: Event): void {
    event.stopPropagation();

    if (this.attemptingCancelConsult) {
      return;
    }
    
    if (this.selectedMember) {
      this.notifyCancelConsultAttempt();
      this.telephonyService.cancelConsult(this.interaction.interactionId, this.selectedMember.memberId, this.sessionStore.get.user.memberId);
      this.clearConsultState();
    } else if (this.searchText) {
      if (this.isValidNumber(this.searchText)) {
        this.notifyCancelConsultAttempt();
        this.telephonyService.cancelConsultDestination(this.interaction.interactionId, this.searchText, this.sessionStore.get.user.memberId);
        this.clearConsultState();
      }
    }
  }
  private notifyCancelConsultAttempt(): void {
    this.attemptingCancelConsult = true;
    this.consultAttempted = false;
    setTimeout(() => {
      this.attemptingCancelConsult = false;
    }, this.attemptTime);
  }
  
  
  private isValidNumber(searchCriteria: string): boolean {
    const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gm;
    const extensionRegex = /^[0-9]+$/;
    const pbxIntegrationRegex = /^\*9[0-9]+$/;
    if (
      phoneNumberRegex.test(searchCriteria.replace(/ /g,'')) || 
      extensionRegex.test(searchCriteria.replace(/ /g,'')) || 
      pbxIntegrationRegex.test(searchCriteria.replace(/ /g,''))) {
      return true;
    }
    return false
  }

  @computedFrom('contact.organisationTransferMembers', 'contact.organisationTransferMembers.length')
  public get members(): any[] {
    if (this.contact.organisationTransferMembers.length > 0) {
      this.preventAutoClose();
      if (this.channel === 'chat') {
        return this.contact.organisationTransferMembers.filter(member => member.roles.includes('AGENT'));
      } else {
        return this.contact.organisationTransferMembers;
      }
    }

    return [];
  }
}
