import { autoinject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { ZIWizard } from '../../../../../../../../typings/zai/zai.common';
import { observable } from 'aurelia-binding';
import { BootstrapFormRenderer } from '../../../../../../../zailab.common';

const logger = LogManager.getLogger('Response');

@autoinject
export class Page4EditAssistantApi {
  @observable public responseMapping: string;
  private wizard: ZIWizard;
  public validation: ValidationController;

  constructor(
    private validationControllerFactory: ValidationControllerFactory
  ) { 
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.initiateValidation();
    this.extractWizardData();
    this.validate();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.nextStep();
    });
  }


  private nextStep(): void {
    this.wizard.continue();
  }

  private extractWizardData(): void {
    this.responseMapping = this.wizard.data.responseMapping;
  }

  private initiateValidation(): void {
    ValidationRules
      .ensure('responseMapping')
      .required()
      .withMessage('Please enter a response mapping.')
      .on(this);
  }

  private validate(): boolean {
    if (!this.responseMapping || !this.responseMapping.trim()) {
      this.wizard.step.incomplete();
      return false;
    } 
    this.wizard.step.complete();
    return true;
  }

  public responseMappingChanged = (): void => {
    this.wizard.data.responseMapping = this.responseMapping.trim();
    this.validate();
  }


}
