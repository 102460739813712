import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BusinessPartner } from '../business-partner-model';
import { BusinessPartnerService } from '../business-partner-service';

const logger = LogManager.getLogger('BusinessPartnerAdd');

@autoinject()
export class BusinessPartnerAdd {

  public businessPartner: BusinessPartner = new BusinessPartner();
  public isProcessing: boolean = false;

  constructor(
    private businessPartnerService: BusinessPartnerService,
    private router: Router
  ) { }

  public save(): void {
    this.isProcessing = true;
    this.businessPartnerService.insert(this.businessPartner)
      .then(() => this.handleSuccess())
      .catch((error: Error) => this.handleError(error));
  }

  private handleSuccess(): void {
    this.isProcessing = false;
    this.router.navigate('list');
  }

  private handleError(error: Error): void {
    this.isProcessing = false;
    logger.error('save :: error=', error)
  }
}