import { LogManager, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DashboardWidget } from '../../../../organisation/organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('WebchatWidget');

@autoinject()
export class AssistantWidget extends DashboardWidget {
  constructor(public router: Router) {
    super();
  }
}
