import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

const logger = LogManager.getLogger('SMSGatewayService');

@autoinject
export class SMSGatewayService {
  constructor(
    private httpClient: HttpClient
  ) {}

  public retrieveSMSGateways(organisationId: string): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways`)
      .asGet()
      .send();
  }

  public createGateway(
    organisationId: string,
    smsGatewayId: string,
    name: string,
    host: string,
    port: number
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways`)
      .asPost()
      .withContent({
        id: smsGatewayId,
        name,
        host,
        port
      })
      .send();
  }

  public updateGateway(
    organisationId: string,
    smsGatewayId: string,
    name: string,
    host: string,
    port: number
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways/${smsGatewayId}`)
      .asPut()
      .withContent({
        id: smsGatewayId,
        name,
        host,
        port
      })
      .send();
  }

  public deleteGateway(
    organisationId: string,
    smsGatewayId: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways/${smsGatewayId}`)
      .asDelete()
      .send();
  }

  public retrieveSMSGatewayAccounts(
    organisationId: string,
    gatewayId: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/organisations/${organisationId}/smsgateways/${gatewayId}/accounts`)
      .asGet()
      .send();
  }

  public retrieveSMSGatewayAccount(
    organisationId: string,
    gatewayId: string,
    accountId: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`v1/organisation/organisations/${organisationId}/smsgateways/${gatewayId}/accounts/${accountId}`)
      .asGet()
      .send();
  }

  public createGatewayAccount(
    organisationId: string, 
    smsGatewayId: string,
    accountId: string,
    name: string,
    username: string,
    password: string,
    number: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways/${smsGatewayId}/accounts`)
      .asPost()
      .withContent({
        id: accountId,
        name,
        username,
        password,
        number
      })
      .send();
  }

  public updateGatewayAccount(
    organisationId: string,
    smsGatewayId: string,
    accountId: string,
    name: string,
    username: string,
    password: string,
    number: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways/${smsGatewayId}/accounts/${accountId}`)
      .asPut()
      .withContent({
        id: accountId,
        name,
        username,
        password,
        number
      })
      .send();
  }

  public deleteGatewayAccount(
    organisationId: string,
    smsGatewayId: string,
    accountId: string
  ): Promise<any> {
    return this.httpClient
      .createRequest(`/v1/organisation/organisations/${organisationId}/smsgateways/${smsGatewayId}/accounts/${accountId}`)
      .asDelete()
      .send();
  }
}
