import {LogManager, inject, ObserverLocator} from 'aurelia-framework';
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/*
 */
import {BootstrapFormRenderer} from 'zailab.common';
import ProspectListService from '../../prospect-list-service';

import moment from 'moment-timezone';
import {observable} from "aurelia-binding";
/*
 */
const logger = LogManager.getLogger('DefineProperties');
/*
 */
@inject(ObserverLocator, ValidationControllerFactory, ProspectListService)
export default class {

  name = '';
  externalReference = '';
  prospectList = [];
  definePropertiesValidationRule = null;
  ready = false;

  previousDate = null;
  @observable selectedStartDate = null;
  @observable selectedEndDate = null;
  dateFormat = 'DD MMM YYYY';

  @observable fromHour;
  @observable fromMinute;

  @observable toHour;
  @observable toMinute;

  @observable selectedCountry;
  @observable selectedTimezone;
  @observable headersIncluded;
  @observable clearLeadsOnNewList;

  nameRequired = true;
  sftpUpload = false;

  constructor(observerLocator, validationControllerFactory, prospectListService) {

    this.observerLocator = observerLocator;
    this.prospectListService = prospectListService;

    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  activate(wizard) {

    this.setDate();

    if(wizard) {
      this._activate(wizard);
    }

    this.prospectListService.retrieveCampaign(wizard.data.campaignId).then((response) => {

      this.prospectList = response.prospectLists;
      this._initValidation();
      this.ready = true;
    }, (error) => {

      this.ready = true;
    });
  }

  setDate() {
    if (this.selectedStartDate) {
      return;
    }

    this.previousDate = moment();
    this.fromHour = 0;
    this.fromMinute = 0;
    this.selectedStartDate = moment().format(this.dateFormat);
    this.toHour = 0;
    this.toMinute = 0;
  }

  _initValidation() {

    ValidationRules
      .customRule('uniqueName', (name) => {
        for (let list of this.prospectList) {
          if (list.name.toLowerCase() === name.toLowerCase()) {
            return false;
          }
        }
        return true;
      });

    ValidationRules
      .customRule('endDate', () => {
        if(this.getStartDateTime().isAfter(this.getEndDateTime())){
          return false
        }

        return true;
      });

    ValidationRules
      .customRule('beforeTodayStartDate', () => {
        if(this,this.getStartDateTime().isBefore(moment().startOf('day'))){
          return false
        }
        return true;
      });

    ValidationRules
      .customRule('beforeTodayEndDate', () => {
        if(this.getEndDateTime().isBefore(moment().startOf('day'))){
          return false
        }
        return true;
      });

    ValidationRules
      .ensure('name')
      .required().withMessage('Please enter a prospect list name.')
      .satisfiesRule('uniqueName').withMessage('Please enter a unique name.')
      .when(() => this.nameRequired)
      .ensure('selectedStartDate')
      .required().withMessage('Please select a start date for your prospect list')
      .satisfiesRule('endDate').withMessage('The To date must be after the From date.')
      .satisfiesRule('beforeTodayStartDate').withMessage('The From date cannot be before Today.')
      .ensure('selectedEndDate')
      .satisfiesRule('endDate').withMessage('The To date must be after the From date.')
      .satisfiesRule('beforeTodayEndDate').withMessage('The To date cannot be before Today.')
      .on(this);
  }

  _activate(wizard) {
    this.wizard = wizard;

    this.nameRequired = (wizard.data.nameRequired === undefined) ? true : wizard.data.nameRequired;
    
    if (!this.nameRequired) this.wizard.step.complete();

    this.sftpUpload = (wizard.data.sftpUpload === undefined) ? false : wizard.data.sftpUpload;

    this.name = wizard.data.name || '';
    this.externalReference = wizard.data.externalReference || '';
    this.selectedCountry = wizard.data.country || '';
    this.selectedTimezone = wizard.data.timezone || '';
    if (wizard.data.startTimestamp != null) {
      this.selectedStartDate = new Date(wizard.data.startTimestamp);
      this.fromHour = this.selectedStartDate.getHours() || 0;
      this.fromMinute = this.selectedStartDate.getMinutes() || 0;
    }
    if (wizard.data.endTimestamp != null) {
      this.selectedEndDate = new Date(wizard.data.endTimestamp);
      this.toHour = this.selectedEndDate.getHours() || 0;
      this.toMinute = this.selectedEndDate.getMinutes() || 0;
    }
    this.headersIncluded = wizard.data.headersIncluded || '';
    this.clearLeadsOnNewList = wizard.data.clearLeadsOnNewList || '';

    wizard.beforeNextStep(() => {
      this._continue();
    });

    this.observer = this.observerLocator
      .getObserver(this, 'name')
      .subscribe((newValue) => {

        let data = {
          name: this.name,
          externalReference: this.externalReference
        };

        if(!newValue || newValue.length === 0) {
          this.wizard.step.incomplete(data);
        } else {
          this.wizard.step.complete(data);
        }
      });
  }

  _continue() {

    this.validation.validate().then((validation) => {
      if(!validation.valid) {
        return;
      }

      let campaign = {
        name: this.name,
        externalReference: this.externalReference,
        startTimestamp: this.getStartDateTime().valueOf(),
        endTimestamp: this.getEndDateTime().valueOf(),
        timezone: this.selectedTimezone.dstOffsetStr,
        headersIncluded: this.headersIncluded,
        clearLeadsOnNewList: this.clearLeadsOnNewList
      };
      this.wizard.continue(campaign);
    }, () => {});
  }

  getStartDateTime(){
    return moment(this.selectedStartDate + " " + this.fromHour + ":" + this.fromMinute + ":00").utcOffset(this.selectedTimezone.utcOffset);
  }

  getEndDateTime(){
    return moment(this.selectedEndDate + " " + this.toHour + ":" + this.toMinute + ":00").utcOffset(this.selectedTimezone.utcOffset);
  }

}
