import {LogManager} from 'aurelia-framework';
/**/
import {ServiceModel} from 'zailab.common';
import {BaseModel} from 'zailab.abstract';
import {BusinessPartnerModel} from "./business-partner-model";
import {ContactCenterModel} from "./contact-center-model";
/**/
const logger = LogManager.getLogger('WorkTypeModel');

/**/
export class WorkTypeModel extends BaseModel {

  public id: string = null;
  public name: string = null;
  public assignedToFlow: boolean = null;
  public businessValue: number = null;
  public channel: string = null;
  public roles: Array<any> = [];
  public service: ServiceModel = null;
  public sites: Array<any> = [];
  public skillGroups: Array<any> = [];
  public targetResponse: number = null;
  public waitTime: number = null;
  public isSelected: boolean = null;
  public isPlaceholder: boolean = null;
  public hoverText: string = '';
  public businessPartner: BusinessPartnerModel = null;
  public contactCentre: ContactCenterModel = null;


  constructor(properties: object) {

    super();
    super.mapProperties(properties);
  }

  public resetSelection(): void {

    this.isSelected = false;
  }

  public toggleSelection(): void {

    this.isSelected = !this.isSelected;
  }
}