/**
 * Draw IO :: Feature model - WARM TRANSFER :: Update when making feature changes
 * https://www.draw.io/#G1CBqvrBjNAKZjX9exiLZU69W-yBX4waUH
 */

import { inject, LogManager, computedFrom, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';

import { SessionStore, ApplicationProperties } from 'zailab.common';
import { List } from 'zailab.abstract';
import { MembersService } from '../members-service';
import { PlaceholderService } from "../../../../../_common/services/placeholder-service";
import { InteractionService } from "../../../interactions/interaction-service";
import { TelephonyService } from "../../../../telephony/telephony-service";

import { ConsultDialog } from '../consult-dialog/consult-dialog';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { MemberState } from "../member-state";
import { ConfirmMemberRemovalDialog } from '../confirm-member-remove/confirm-member-remove';
import { RolesService } from '../../../organisation/roles/roles-service';
import { PresenceService } from '../../../../user/passport/presence/presence-service';

let logger = LogManager.getLogger('MembersListView');

@inject(Element, Router, SessionStore, MembersService, EventAggregator, DialogService, InteractionService, TelephonyService, ApplicationProperties, MemberState, RolesService, PresenceService)
export class MembersListView {
  list;
  oplog;
  interactionsOplog;
  actions = [{
    action: 'view',
    label: 'view member'
  }];
  placeholders = 0;
  container;
  subscription;
  activeFlowCall = null;
  transferring = false;
  consulting = false;
  pageNumber = 0;
  pageSize = 12;
  isListOver = false;
  @observable searchTerm = '';
  searchName = '';
  searchEmail = '';
  searchRole = '';
  selectedMemberType = '';
  roles = [];
  totalMemberCount = 0;
  presencesMap = {};

  constructor(element, router, sessionStore, membersService, eventAggregator, dialogService, interactionService, telephonyService, applicationProperties, memberState, rolesService, presenceService, selectedNumber) {
    this.element = element;
    this.router = router;
    this.sessionStore = sessionStore;
    this.membersService = membersService;
    this.eventAggregator = eventAggregator;
    this.dialogService = dialogService;
    this.interactionService = interactionService;
    this.telephonyService = telephonyService;
    this.applicationProperties = applicationProperties;
    this.memberState = memberState;
    this.rolesService = rolesService;
    this.presenceService = presenceService;
    this.selectedNumber = selectedNumber;
  }

  activate() {
    // OrgIds added: Future Learn & Zai Support
    const eligibleOrganisation = this.applicationProperties.showForPostProdOnly(this.organisationId) || this.organisationId === 'c865d18a-e269-42c8-b7e3-13ff9fe02c5e' || this.organisationId === '89707b92-dae5-4f27-92ca-ae13393ec6eb';
    if (eligibleOrganisation && this.user && !this.user.hasAgentRole) {
      this.getMemberInteractions();
    }
    this.getPresenceCodes();
  }

  getPresenceCodes() {
    this.presenceService
      .retrievePresences()
      .then(presences => {
        presences.forEach(presence => {
          this.presencesMap[presence.presenceCodeName.toLowerCase()] = presence.color;
        });
      });
  }

  getMemberInteractions() {
    this.eventAggregator.subscribe('connection-interaction-updated', interactions => this.handleConnectedInteractionState(interactions));
  }

  handleConnectedInteractionState(interactions) {
    if (interactions && interactions.length > 0) {
      this.activeFlowCall = interactions.find(interaction => this.isFlowCall(interaction));
    } else {
      this.activeFlowCall = null;
    }
    if (!this.activeFlowCall) {
      this.cancelDialog();
    }
    this.transferring = false;
  }

  isFlowCall(interaction) {
    if (interaction.channel === 'Call' && interaction.state === 'CONNECTED') {
      if (interaction.interactionType === 'CONTACT_CENTRE_FLOW') {
        return true;
      }
    }
    return false;
  }

  attached() {
    this.rolesService.displayRoles(this.sessionStore.get.organisation.organisationId, null).then((roles) => {
      this.roles = roles;
    }, (error) => {
      logger.info('roles query failed >> ', error);
    });
    this.getMembers();
  }

  getMembers(append) {
    this.membersService.retrieveOrganisationMembersAndTotalMemberCount(this.searchRole, this.searchName, this.searchEmail, this.pageNumber, this.pageSize, this.selectedMemberType).then(
      result => {
        this.totalMemberCount = result.totalMemberCount; 
        this._setupConfig(result.members, append);
        this.updateMemberStatuses();
        if (append) {
          this.smoothScrollToBottom();
        } else {
          this.list.searchTerm = '';
        }
        if (!this.isAdmin) {
          this.actions = null;
        }
      })
      .catch(error => {
        logger.info(' Failed to retrieve your organisation members > error = ', error);
        this.ready = true;
      }
    );
  }

  smoothScrollToBottom() {
    setTimeout(() => {
      let div = document.getElementById('scroll-down');
      $('#' + div.id).animate({
        scrollTop: (div.scrollHeight + 150) - div.clientHeight
      }, 350);
    }, 350);
  }

  reset() {
    this.pageNumber = 0;
    this.searchName = '';
    this.searchEmail = '';
    this.searchRole = '';
    this.selectedMemberType = '';
    this.getMembers();
  }

  search() {
    this.pageNumber = 0;
    this.getMembers();
  }

  showMore() {
    this.pageNumber++;
    this.getMembers(true);
  }

  detached() {
    this.oplog && this.oplog.unsubscribe();
    this.interactionsOplog && this.interactionsOplog.unsubscribe();
    this.subscription && this.subscription.dispose();
  }

  @computedFrom('sessionStore.get.organisation')
  get organisation() {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('organisation')
  get organisationId() {
    return this.organisation.organisationId;
  }

  @computedFrom('sessionStore.get.user')
  get user() {
    return this.sessionStore.get.user;
  }

  @computedFrom('user')
  get memberId() {
    return this.user.memberId;
  }

  @computedFrom('sessionStore.get.user')
  get isAdmin() {
    return this.sessionStore.get.user.hasAdministratorRole;
  }

  _setupConfig(members, append) {

    if (!this.list) {
      this.list = List.Builder() //
        .required(true) //
        .customEventElement(this.element)
        .enableDelete()
        .enableBulkEdit()
        .items(members) //
        .uniqueIdentifier('memberId') //
        .displayId('email') //
        .build();
    } else {
      if (!append) {
        this.updateExistingMembers(members);
        this.removeDeletedMembers(members);
      }
      this.addCreatedMembers(members);
    }

    this.placeholderService = new PlaceholderService(this.container, this.list.items.length, 2, (placeholders) => {
      this.placeholders = placeholders;
    });
    this.ready = true;
  }


  updateMemberStatuses() {
    let memberIds = this.list.items.map(function(item) {
      return item['memberId'];
    });

    if (memberIds.length === 0) {
      return;
    }

    this.membersService.retrieveMemberStatuses(memberIds).then(
      result => {
        this.list.items.forEach((item) => {
          for (const status of result) {
            if (item.memberId === status.memberId) {
              item.presence = status.presence;
              item.activity = status.activity;
              break;
            }
          }
        });
      },
      error => {
        logger.info(' Failed to retrieve member statuses > error = ', error);
      }
    );
  }

  updateExistingMembers(members) {
    this.list.items.forEach((item) => {
      for (const member of members) {
        if (item.memberId === member.memberId) {
          item.firstName = member.firstName;
          item.surname = member.surname;
          item.fName = member.fName;
          item.service = member.service;
          item.services = member.services;
          item.site = member.site;
          item.extension = member.extension;
          item.presence = member.presence;
          item.email = member.email;
          item.roleName = member.roleName;
          item.activity = member.activity;
          break;
        }
      }
    });
  }

  removeDeletedMembers(members) {
    this.list.items = this.list.items.filter((item) => {
      return members.find((member) => item.memberId === member.memberId)
    });
  }

  addCreatedMembers(members) {
    members.forEach((member) => {
      let found = false;
      for (const item of this.list.items) {
        if (item.memberId === member.memberId) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.list.items.push(member);
      }
    });
  }

  viewMember(item) {
    this.router.navigate(`member/${item.memberId}`);
  }

  selectItem(item) {
    if (item.isPlaceholder || item.showLoader) {
      return;
    }
    if (this.list.isDeleting) {
      this.list.items.map(item => item.isDeleting = false);
      this.list.selectToDelete(item);
    }
    if (this.list.isBulkEditing) {
      this.list.selectToBulkEdit(item);
    }
  }

  confirmDelete() {
    let member = this.list.items.find(item => item.isDeleting);

    this.dialogService
      .open({ viewModel: ConfirmMemberRemovalDialog, model: member })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          this.deleteItems(member);
        }
      });
  }

  deleteItems(member) {
    this._removeMember(member);
    this.list.toggleDelete();
  }

  bulkEditItems() {
    this.memberState.members = [];
    this.list.items.forEach(m => {
      if (m.isBulkEditing) {
        this.memberState.members.push(m);
      }
    });
    if (this.memberState.members.length > 0) {
      this.router.navigate("members/edit");
    }
    this.list.toggleBulkEdit();
  }

  transfer(event, member) {
    if (this.transferring) {
      return;
    }
    this.transferring = true;
    event.stopPropagation();
    if (member.memberId) {
      this.activeFlowCall.memberId = member.memberId;
      this.telephonyService.transfer(this.activeFlowCall.interactionId, member.extension, this.memberId);
    }
  }

  consult(event, member) {
    this.consulting = true;
    event.stopPropagation();
    if (member.memberId) {
      this.activeFlowCall.memberId = member.memberId;
      this.telephonyService.consult(this.activeFlowCall.interactionId, member.memberId, this.memberId);
    }
    this.displayConsultDialog(member);
  }

  displayConsultDialog(member) {
    this.dialogService
      .open({
        viewModel: ConsultDialog,
        model: {
          targetMember: member,
          interactionId: this.activeFlowCall.interactionId,
          transferringMemberId: this.memberId
        }
      })
      .whenClosed(response => {
        if (response.wasCancelled) {
          this.consulting = false;
        }
      });
  }

  cancelDialog() {
    this.dialogService.controllers.forEach(controller => controller.cancel({ rejected: false }));
  }

  dialMember(event, member, selectedNumber) {
    let from = this.sessionStore.get.user.email;
    let to = member.memberId;
    let callId = uuidv4();
    event.stopPropagation();

    if (member.originSystem === 'MSTeams' && selectedNumber) {
      this.telephonyService.dial(callId, from, selectedNumber, null);
      setTimeout(() => {
        this.selectedNumber = null;
      }, 5000);

    } else if (member.memberId) {
      this.telephonyService.dial(callId, from, member.memberId, null);
    }
  }

  _removeMember(member) {
    if (!member) {
      return;
    }
    member.showLoader = true;
    this.membersService.deleteMember(member);
  }

  startsWithMatch(searchExpression, item) {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return item.searchableText.contains(searchExpression.toLowerCase());

  }

  partialMatch(searchExpression, item) {
    if (!searchExpression || searchExpression === '') {
      return true;
    }
    return item.searchableText.includes(searchExpression.toLowerCase());
  }
  
  searchTermChanged() {
    this.searchName = this.searchTerm;
    this.getMembers();
  }
}
