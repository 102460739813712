import { autoinject, bindable, customElement, LogManager } from "aurelia-framework";
import { confetti } from "../../../../_common/gamify/confetti";
import { AgentScriptDefinitionService } from "./agent-script-definition-service";
import { AgentScriptConnection, AgentScriptFlow, AgentScriptNode } from "./agent-script-designer-model";
import { AgentScriptDefinition, AgentScriptExecution } from "./agent-script-execution-model";
import { AgentScriptExecutionService } from "./agent-script-execution-service";
import { v4 as uuidv4 } from 'uuid';

const logger = LogManager.getLogger('AgentScriptCC');

@customElement('z-agent-script-cc')
@autoinject
export class AgentScriptCC {

  @bindable
  public flow: AgentScriptFlow;

  @bindable
  public workTypeId: string;

  @bindable
  public interactionId: string;

  @bindable
  public showAgentScript: boolean;

  @bindable
  public height: number = 168;

  public loading: boolean;
  public node: AgentScriptNode;
  public conns: AgentScriptConnection[];
  public displayState = 'CURRENT_STEP';
  public history = [];
  public historyStepName: string;
  public historyStepText: string;
  public historyStepOption: string;

  private executionId: string;

  constructor(
    private agentScriptExecutionService: AgentScriptExecutionService,
    private agentScriptDefinitionService: AgentScriptDefinitionService,
  ) { }

  public bind(): void {
    if (this.flow) {
      this.showAgentScript = true;
      this.startFlow();
    } else {
      if (this.interactionId) {
        this.agentScriptExecutionService.retrieve(this.interactionId)
          .then((agentScriptExecutionList: AgentScriptExecution[]) => {
            if (agentScriptExecutionList && agentScriptExecutionList.length) {
              const agentScriptExecution = agentScriptExecutionList[0];
              this.flow = new AgentScriptFlow(agentScriptExecution.definition);
              this.executionId = agentScriptExecution.id;
              this.showAgentScript = true;
              this.loadNode(agentScriptExecution.currentNodeId);
              for (const connId of agentScriptExecution.selectedConnectionIds) {
                const connection = this.flow.getConnection(connId);
                const node = this.flow.getNode(connection.sourceNodeId);
                if (node.id === this.flow.startNodeId) {
                  continue;
                }
                this.history.push({ name: node.name, text: node.text, option: connection.name });
              }
            } else if (this.workTypeId) {
              this.agentScriptDefinitionService.retrieveByWorkTypeId(this.workTypeId)
                .then((agentScriptDefinition: AgentScriptDefinition) => {
                  if (!agentScriptDefinition) {
                    return;
                  }
                  this.flow = new AgentScriptFlow(agentScriptDefinition);
                  this.executionId = uuidv4();
                  this.showAgentScript = true;
                  this.agentScriptExecutionService.create(this.executionId, agentScriptDefinition.agentScriptId, this.interactionId)
                    .then((res) => {
                      const startNode = this.flow.getStartNode();
                      this.agentScriptExecutionService.update(this.executionId, startNode.id, startNode.connectionIds[0])
                        .then(() => this.startFlow());
                    });
                });
            }
          });
      }
    }
  }

  private startFlow(): void {
    const startNode = this.flow.getStartNode();
    const startConnectionId = startNode.connectionIds[0];
    const startConnection = this.flow.getConnection(startConnectionId);
    const nextNodeId = startConnection.destinationNodeId;
    this.loadNode(nextNodeId);
  }

  private loadNode(nodeId: string): void {
    this.loading = true;
    this.node = this.flow.getNode(nodeId);
    this.conns = this.node.connectionIds.map((connId) => this.flow.getConnection(connId));
    this.loading = false;
    if (!this.conns || !this.conns.length) {
      this.history.push({ name: this.node.name, text: this.node.text, option: 'End of Script' });
      confetti(36);
    }
  }

  public next(event: any): void {
    const selectedOption = event.detail;
    if (this.executionId) {
      this.agentScriptExecutionService.update(this.executionId, this.node.id, selectedOption.id);
    }
    this.history.push({ name: this.node.name, text: this.node.text, option: selectedOption.name });
    this.loadNode(selectedOption.destinationNodeId);
  }

  public setDisplayState(displayState: string): void {
    this.loading = true;
    this.loading = false;
    this.displayState = displayState;
  }

  public showHistoryStep(event: any): void {
    this.historyStepName = event.detail.name;
    this.historyStepText = event.detail.text;
    this.historyStepOption = event.detail.option;
    this.setDisplayState('STEP');
  }
}
