import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
import { NumbersService } from '../../numbers-service';
import { PurchaseNumbers } from '../purchase-numbers';
import { AbstractList, List } from 'zailab.abstract';
import { SessionStore } from "../../../../../../_common/stores/session-store";
import { AvailableNumberModel } from '../../available-number-model';
/*
 */
const logger = LogManager.getLogger('PurchaseNumbersConfirmNumbers');
/*
 */
@inject(Element, NumbersService, PurchaseNumbers, Router, EventAggregator, SessionStore)
export class PurchaseNumbersConfirmNumbers extends AbstractList {

  hasPurchaseNumbers = false;

  constructor(private element:Element, private numbersService:NumbersService, private purchaseNumbers:PurchaseNumbers, private router:Router, protected eventAggregator:EventAggregator, private sessionStore:SessionStore) {
    super(eventAggregator);
  }

  protected activate(params?: { add: string }): void {
    super.activate(params);
    this.subscribeToOplog();
  }

  protected attached(): void {
    super.attached();
  }

  private subscribeToOplog(): void {
    this.numbersService.subscribeToOrganisationNumbersUpdates(this.sessionStore.get.organisation.organisationId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', () => this.retrieveList());
      this.oplog.on('update', () => this.retrieveList());
      this.oplog.on('delete', () => this.retrieveList());
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    let country = this.purchaseNumbers.countryCode;
    let type = this.purchaseNumbers.selectedType.type.toUpperCase();
    let prefix = this.purchaseNumbers.numberPrefix || '';
    let contains = this.purchaseNumbers.numberStartsWith || '';

    if (type === 'SMS') {
      prefix = '';
    }

    this.numbersService.searchAvailableNumbers(country, type, prefix, contains).then(numbers => {
      if (numbers.length > 0) {
        this.hasPurchaseNumbers = true;
      }
      this.setupListConfig(numbers);
    });
  }

  protected setupListConfig(_numbers: AvailableNumberModel[]): void {
    super.setupListConfig(_numbers);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .items(_numbers)
    .icon('numberIcon')
    .enableClick()
    .selectionType(List.SINGLE_SELECTION_TYPE)
    .uniqueIdentifier('uniqueIdentifier')
    .displayId('displayId')
    .description('description')
    .descriptionTwo('descriptionTwo')
    .withProportion('medium')
    .tilesPerRow(6)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  public selectItem(_config:any):void {
    if (!_config.item.isPlaceholder) {
      this.markAllNumbersAsUnselected();
      _config.item.selected = true;
      this.purchaseNumbers.selectedNumber = _config.item;
    }
  }

  public markAllNumbersAsUnselected():void {
    this.itemList.items.forEach(item => {
      item.selected = false;
    });
  }
}
