/*
 */
import {inject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
/*
 */
import {FormService} from './form.service';
import {FormStore} from './form.store';
/*
 */
const logger = LogManager.getLogger('Form');
/*
 */
@inject(Router, FormService, FormStore)
export class Form {

  constructor(router, formService, formStore) {
    this.router = router;
    this.formService = formService;
    this.formStore = formStore;
  }

  activate(params) {

    this.formService.retrieveForm(params.formId);
  }

  deactivate() {

    this.formService.clearStore();
  }
  
  back(){
    this.router.navigateBack(); // TODO: replace with actual route to navigate to
  }


  toggleExpandSection(section, index) {

    let sectionEl = `#section_${index}`;
    if (!section.expanded) {

      toggleAccordion(sectionEl, true);
    } else {

      toggleAccordion(sectionEl, false);
    }

    section.expanded = !section.expanded;
  }
}

function toggleAccordion(elementId, expand){

  let childHeight = $(elementId).find("ul:first").height();

  if(expand){
    $(elementId).height(childHeight);

    setTimeout(()=>{
      $(elementId).height('auto');
    }, 200);

    return;
  }

  $(elementId).height(childHeight);
  $(elementId).height(0);

}