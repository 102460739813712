import { autoinject } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager } from "aurelia-framework";

const logger = LogManager.getLogger('DataGrid');

@autoinject
export class DataGridEvents {

  constructor(
    private readonly events: EventAggregator,
  ) {
    this.init();
  }

  private init(): void {
  }

  public onError(callback: () => void): void {
    if (!!callback) {
      this.events.subscribe('DataGridEvents_onerror', callback);
    }
  }

  public onDragStart(callback: () => void): void {
    if (!!callback) {
      this.events.subscribe('DataGridEvents_ondragstart', callback);
    }
  }

  public onDragEnd(callback: () => void): void {
    if (!!callback) {
      this.events.subscribe('DataGridEvents_ondragend', callback);
    }
  }
}

export class DataGrid {

  public ready: boolean = false;
  public dataSource: any[] = [];
  public columns: string[];
  public columnSettings: any[];

  constructor(
    public mappings: DataGridMapping[],
  ) {
    this.init();
  }

  private init(): void {
    this.columns = this.mappings.map((mapping) => mapping.title);
    this.columnSettings = this.mappings.map((mapping) => {
      return {
        title: mapping.title,
        align: mapping.field.align,
        hidden: mapping.field.hidden,
        width: mapping.field.width,
        tooltip: mapping.field.tooltip,
        disabled: mapping.field.disabled,
        visible: mapping.field.visible,
        cssClass: mapping.field.cssClass,
        filterType: mapping.field.filterType
      }
    });
  }

  public addData(campaign: any, tempList?: any[]): void {
    const data = {};
    for (const mapping of this.mappings) {
      const style = mapping.field.style ? mapping.field.style(campaign) : '';
      data[mapping.title] = `<span class="${style}">${mapping.field.value(campaign)}</span>`;
    }
    if (tempList) {
      tempList.push(data);
    } else {
      this.dataSource.push(data);
    }
  }

  public getColumnOrderAndWidths(): Promise<any> {
    return new Promise((r) => {
      this.dataSource = Object.assign([], this.dataSource);
      setTimeout(() => {
        let grid = document.querySelector('ej-grid');
        let gridColumnsOrder;
        let gridColumnsWidths;
        for (const key in grid) {
          if (!key.startsWith('jQuery')) {
            continue;
          }
          const value = grid[key];
          if (!value) {
            continue;
          }
          const ejGrid = value.ejGrid;
          if (ejGrid && ejGrid._visibleColumns) {
            gridColumnsOrder = ejGrid._visibleColumns;
            gridColumnsWidths = ejGrid.columnsWidthCollection;
            break;
          }
        }
        const columnOrderAndWidths = [];
        for (let i = 0; i < gridColumnsOrder.length; i++) {
          columnOrderAndWidths.push({
            title: gridColumnsOrder[i],
            width: gridColumnsWidths[i],
          });
        }
        r(columnOrderAndWidths);
      }, 100);
    });
  }
}

export class DataGridMapping {

  public title: string;
  public field: any;

  public static from(ketValuePair: any): DataGridMapping {
    const instance = new DataGridMapping();
    for (const key in ketValuePair) {
      instance.title = key;
      instance.field = ketValuePair[key];
    }
    return instance;
  }
}

export class DataRefresher {

  public lastRequestTimestamp: number = Date.now() + 30000;
  public enableSelectors: boolean = false;
  public timerDropdownVisible: boolean = false;
  public paused: boolean = false;
  public currentRequestTimer: any = 30;
  public requestTimerValues: any[] = [
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
    { value: 30 },
  ];

  constructor(customTimeInSeconds?: number) {
    if (customTimeInSeconds) {
      this.lastRequestTimestamp = Date.now() + (customTimeInSeconds * 1000);
      this.currentRequestTimer = customTimeInSeconds;
    }
    this.reset();
  }

  public showTimerDropdown(): void {
    this.timerDropdownVisible = !this.timerDropdownVisible;
  }

  public selectTime(value: any): void {
    this.currentRequestTimer = value;
    this.timerDropdownVisible = false;
    this.reset();
  }

  public isDone(): boolean {
    return Date.now() >= this.lastRequestTimestamp;
  }

  public reset(): void {
    this.lastRequestTimestamp = Date.now() + (this.currentRequestTimer * 1000);
  }
}

export class NavigatingBreadcrumb {

  constructor(
    public route: string,
    public name: string,
    public active: boolean,
  ) { }
}

export class DataGridSetting {

  constructor(
    public title: string,
    public visible: boolean,
    public width: number,
    public orderIndex: number,
    public tooltip?: string,
    public disabled?: boolean,
    public data?: {[key: string]: any},
    public customAttributes?: {[key: string]: string},
    public cssClass?: string,
    public filterType?: string,
    public disableSorting?: boolean,
    public value?: any
  ) { }
}
