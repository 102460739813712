import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { TelephoneNumberModel } from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import { SearchTools } from '../../../../_common/tools/search-tools';
import { BusinessPartnerService } from '../../businesspartner/business-partner-service';
import { ContactCenterService } from '../../contactcenter/contact-center-service';
import { MemberModel } from '../../conversation/member-model';
import { MembersService } from '../../member/members/members-service';
import { TransferList, TransferRecipient } from '../transfer-list-model';
import { TransferListService } from '../transfer-list-service';

const logger = LogManager.getLogger('TransferListMember');

@autoinject()
export class TransferListMember {

  public transferList: TransferList;
  public businessPartner: string;
  public contactCenter: string;
  public members: MemberModel[];
  public filteredMembers: MemberModel[];
  public ready: boolean = false;
  public showSearch: boolean = false;
  public searchTerm: string = '';
  public memberBusinessPartnerContactCentres;
  public currentSelectedMemberFilter: string = 'ALL';

  public showMoreEnabled: boolean = true;
  private pageNumber: number = 0;
  private pageSize: number = 20;

  constructor(
    private transferListService: TransferListService,
    private businessPartnerService: BusinessPartnerService,
    private contactCenterService: ContactCenterService,
    private membersService: MembersService,
    private router: Router
  ) { }

  public activate(params: { id: string }): Promise<void> {
    return this.retrieveTransferList(params.id);
  }

  public toggleMemberSelection(member: any): void {
    if (member.inactive) {
      return;
    }
    member.inactive = true;
    if (member.selected) {
      this.transferListService.removeTransferMember(this.transferList.id, member.memberId)
        .then(() => {
          member.selected = false;
          member.inactive = false;
        });
    } else {
      this.transferListService.addTransferMember(this.transferList.id, member.memberId)
        .then(() => {
          member.selected = true;
          member.inactive = false;
        });
    }
    member.selected = !member.selected;
  }

  public get heading(): string {
    let heading = `Members: ${this.transferList.name}`;
    if (this.businessPartner) {
      heading += ` - Business Partner: ${this.businessPartner}`;
    }
    if (this.contactCenter) {
      heading += ` - Contact Center: ${this.contactCenter}`;
    }
    return heading;
  }

  public navigateBack(): void {
    this.router.navigate('list');
  }

  public navigateContacts(): void {
    this.router.navigate(`edit/${this.transferList.id}`);
  }

  public navigateTeams(): void {
    this.router.navigate(`team/${this.transferList.id}`);
  }

  public toggleSearch(): void {
    this.showSearch = !this.showSearch;
    this.searchTerm = '';
  }

  public showMore(): void {
    this.pageNumber++;
    this.retrieveMembers(this.memberBusinessPartnerContactCentres, this.pageNumber, this.pageSize);
  }

  public updateMemberList(): void {
    if (this.currentSelectedMemberFilter === 'ALL') {
      this.filteredMembers = this.members;
    }
    if (this.currentSelectedMemberFilter === 'ENABLED') {
      this.filteredMembers = this.members.filter((member: any) => member.selected);
    }
    if (this.currentSelectedMemberFilter === 'DISABLED') {
      this.filteredMembers = this.members.filter((member: any) => !member.selected);
    }
  }

  public partialMatch(searchExpression: any, value: any, searchParam: any): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }

  private retrieveTransferList(transferListId: string): Promise<void> {
    return this.transferListService.findById(transferListId)
      .then((transferList: TransferList) => {
        this.transferList = transferList;
        this.retrieveBusinessPartnerAndContactCenterNames();
        if (this.transferList.businessPartner) {
          return this.contactCenterService.findAll().then((contactCenters) => {
            let memberBusinessPartnerContactCentres = contactCenters.filter((contactCenter) => {
              return contactCenter.businessPartnerId === this.transferList.businessPartner;
            });
            this.memberBusinessPartnerContactCentres = memberBusinessPartnerContactCentres.filter((contactCenter) => {
              return contactCenter.contactCentreId === this.transferList.contactCenter;
            });
            return this.retrieveMembers(this.memberBusinessPartnerContactCentres, this.pageNumber, this.pageSize);
          });
        } else {
          return this.retrieveMembers(null, this.pageNumber, this.pageSize);
        }
      });
  }

  private retrieveBusinessPartnerAndContactCenterNames(): void {
    if (this.transferList.businessPartner) {
      this.businessPartnerService.findById(this.transferList.businessPartner)
        .then((businessPartner) => this.businessPartner = businessPartner.name);
    }
    if (this.transferList.contactCenter) {
      this.contactCenterService.findById(this.transferList.contactCenter)
        .then((contactCenter) => this.contactCenter = contactCenter.name);
    }
  }

  private retrieveMembers(memberBusinessPartnerContactCentres: any[], pageNumber: number, pageSize: number): Promise<void> {
    return this.membersService.retrieveOrganisationMembers(null, null, null, pageNumber, pageSize)
      .then((members) => {
        if (members.length < this.pageSize) {
          this.showMoreEnabled = false;
        }

        members = members.map((member) => {
          member.selected = this.transferList.transferMembers ? this.transferList.transferMembers.indexOf(member.memberId) !== -1 : false;
          return member;
        });
        if (this.transferList.transferTeams && this.transferList.transferTeams.length) {
          members = members.filter((member) => {
            if (member.selected) {
              return true;
            }
            const teams = member.teams.filter((team) => {
              return this.transferList.transferTeams.indexOf(team.teamId) !== -1;
            });
            return !teams || !teams.length;
          });
        }
        if (this.transferList.businessPartner && !this.transferList.contactCenter) {
          members = members.filter((member) => {
            if (member.selected) {
              return true;
            }
            if (memberBusinessPartnerContactCentres && memberBusinessPartnerContactCentres.length > 0) {
              return false;
            }
            return member.businessPartners.find((businessPartner) => businessPartner.businessPartnerId === this.transferList.businessPartner);
          });
        }
        if (this.transferList.businessPartner && this.transferList.contactCenter) {
          members = members.filter((member) => {
            if (member.selected) {
              return true;
            }
            if (!memberBusinessPartnerContactCentres || !memberBusinessPartnerContactCentres.length || !memberBusinessPartnerContactCentres.find((contactCenter) => contactCenter.contactCentreId === this.transferList.contactCenter)) {
              return false;
            }
            return member.businessPartners.find((businessPartner) => businessPartner.businessPartnerId === this.transferList.businessPartner);
          });
        }
        if (pageNumber === 0) {
          this.filteredMembers = this.members = members;
        } else {
          this.filteredMembers = this.filteredMembers.concat(members);
          this.members = [...this.filteredMembers];
        }
        this.ready = true;
      });
  }
}
