import {LogManager, inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
/**/
import {observable} from "aurelia-binding";
import {ValidationControllerFactory, ValidationController, ValidationRules, validateTrigger} from 'aurelia-validation';
/**/
import {BootstrapFormRenderer} from 'zailab.common';
import {NodeModel} from '../../../../../../components/organisms/node/models/node-model';
import {ConnectorModel} from '../../../../../../components/organisms/connector/models/connector-model';
/**/
const logger = LogManager.getLogger('InputDialog');

/**/
@inject(DialogController, ValidationControllerFactory)
export class InputDialog {

  private dialogHeader = 'Input Dialog';
  private inputName: string;
  private variableName: string;
  private label: string;
  private timeout: number;
  private type: string;
  private validationRegex: string;
  private types: Array<string> = ['TEXTBOX', 'CONFIRMATION', 'DROPDOWN'];
  private dropDownOptions: Array<string> = [];
  private option: string;
  private nodeData: NodeModel;
  private existingConnections: Array<ConnectorModel> = [];
  private decisionValidatorController: ValidationController;
  private canSave: boolean = false
  private nodeConfiguration: ZNodeConfig;

  constructor(private dialogController: DialogController, private validationControllerFactory: ValidationControllerFactory) {
    this.decisionValidatorController = this.validationControllerFactory.createForCurrentScope();
    this.decisionValidatorController.addRenderer(new BootstrapFormRenderer());
    this.decisionValidatorController.validateTrigger = validateTrigger.changeOrBlur;
  }

  public activate(_config: ZNodeConfig): void {
    this.nodeConfiguration = _config;
    this.nodeData = JSON.parse(JSON.stringify(this.nodeConfiguration.nodeDefinition)); // Prevent deep copy
    this.inputName = this.nodeData.properties["name"];
    this.variableName = this.nodeData.properties["variableName"];
    this.label = this.nodeData.properties["label"];
    this.timeout = this.nodeData.properties["timeout"];
    this.validationRegex = this.nodeData.properties["validationRegex"];
    let tempOptions = this.nodeData.properties["dropDownOptions"];
    if(tempOptions){
      this.dropDownOptions = tempOptions;
    }
    this.type = this.nodeData.properties["type"];
    if('TEXT' === this.type){
      this.type = 'TEXTBOX';
    }
    this.existingConnections = this.nodeData.connections;
    this.checkValid();
    this.setupValidation();
  }

  private setupValidation(): void {
    ValidationRules
      .ensure((_scope: any) => _scope.inputName).minLength(2).required()
      .on(this)
  }

  private checkValid(): void {
    if(this.inputName && this.inputName.length > 0 && this.variableName && this.variableName.length > 0 && this.label && this.label.length > 0){
      this.canSave = true;
    }
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private ok(): void {

    if (!this.nodeData.properties) {
      this.nodeData.properties = {};
    }

    this.nodeData.properties['name'] = this.inputName;
    this.nodeData.properties['variableName'] = this.variableName;
    this.nodeData.properties['label'] = this.label;
    this.nodeData.properties['type'] = this.type;
    this.nodeData.properties['timeout'] = Number(this.timeout);
    this.nodeData.properties['defaultOutcomeEnabled'] = true;
    this.nodeData.properties['isDefined'] = this.canSave;
    this.nodeData.properties['dropDownOptions'] = this.dropDownOptions;
    this.nodeData.properties['validationRegex'] = this.validationRegex;
    this.dialogController.ok(this.nodeData);
  }

  addOption(): void{
    if(this.option && this.option.length > 0){
      this.dropDownOptions.push(this.option);
      this.option = null;
    }
  }

  removeOption(option: string): void {
    if(option && option.length > 0){
      this.dropDownOptions.splice(this.dropDownOptions.indexOf(option),1);
    }
  }

}
