import { bindable, computedFrom, inject, LogManager } from 'aurelia-framework';

import { Event } from 'zailab.common';

import toastr from 'toastr';

const logger = LogManager.getLogger('PostInteractionProcessingDelay');

@inject(Element)
export class PostInteractionProcessingDelay {

  @bindable private model: any;

  public delayItems: any = Array.from({length: 25}, (x, i) => i);
  public isEditing = {
    delay: false
  };

  constructor(private element: Element) {}

  public activate(bindingContext: any): void {
    this.model = bindingContext;
    this.model.data = {
      interactionPostProcessorId: bindingContext.data.interactionPostProcessorId || undefined,
      delay: bindingContext.data.delay || 0
    };
  }

  // Delay
  private previousDelay = [];
  public editDelay(): void {
    this.toggleEditDelay(true);
    this.previousDelay = this.model.data.delay;
  }

  public updateDelay(): void {
    new Event(this.element, 'update-delay', { delay: this.model.data.delay });
    this.toggleEditDelay(false);
  }

  public cancelDelayUpdate(): void {
    this.model.data.selectedDelay = this.previousDelay;
    this.previousDelay = [];
    this.toggleEditDelay(false);
  }

  private toggleEditDelay(value: boolean): void {
    this.isEditing.delay = value;
  }

  @computedFrom('model')
  public get hasProcessor(): boolean {
    if (!this.model) {
      return
    }
    return typeof this.model.data.interactionPostProcessorId === 'string';
  }

}