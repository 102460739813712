import {autoinject, LogManager} from 'aurelia-framework';
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('AgentDailyStatsModel');
/**/
interface CurrentState {
    state: string,
    timestamp: number
}

const CHANNELS: object = {
    INBOUND_WORK_CALLS: 'inboundWorkCallsCount',
    OUTBOUND_WORK_CALLS: 'outboundWorkCallsCount',
    INBOUND_PRIVATE_CALLS: 'inboundPrivateCallsCount',
    OUTBOUND_PRIVATE_CALLS: 'outboundPrivateCallsCount',
    INTERNAL_CALLS: 'internalCallsCount',
    EMAILS: 'emailsCount',
    SMS: 'smssCount',
    WEBCHATS: 'webchatsCount',
    INSTANT_CHATS: 'imCount',
    NO_RESPONSE: 'missedCount',
    TRANSFERS: 'transferredCount',
    CONFERENCE: 'conferenceCount'
};
const ON_DUTY_STATES: object = {
  WAITING: 'waitingDuration',
  TRUE_WORK: 'trueWorkDuration',
  TRUE_NOT_RESPONDING: 'trueNotRespondingDuration',
  NOT_RESPONDING: 'notRespondingDuration',
};
const HANDLING_STATES: object = {
  DIAL_TIME: 'dialTimeDuration',
  TALK_TIME: 'talkTimeDuration',
  HOLD_TIME: 'holdTimeDuration',
  WRAPUP_TIME: 'wrapUpDuration',
};
const PRESENCES: object = {
  AVAILABLE: 'availableDuration',
  AWAY: 'awayDuration',
  BREAK: 'breakDuration',
  BUSY: 'busyDuration',
  LUNCH: 'lunchDuration',
  MEETING: 'meetingDuration',
  TRAINING: 'trainingDuration',
};
/**/
@autoinject
export class AgentDailyStatsModel extends BaseModel {

    public averageAgentRatingSurveyScore: number = 0;

    // PRESENCES
    private availableDuration: number = 0;
    private awayDuration: number = 0;
    private busyDuration: number = 0;
    private lunchDuration: number = 0;
    private breakDuration: number = 0;
    private meetingDuration: number = 0;
    private trainingDuration: number = 0;

    // ON_DUTY_STATES
    private waitingDuration: number = 0;
    private trueWorkDuration: number = 0;
    private trueNotRespondingDuration: number = 0;
    private notRespondingDuration: number = 0;

    // OFF_DUTY_STATES
    private dialTimeDuration: number = 0;
    private talkTimeDuration: number = 0;
    private holdTimeDuration: number = 0;
    private wrapUpDuration: number = 0;

    // CHANNELS
    private inboundWorkCallsCount: number = 0;
    private outboundWorkCallsCount: number = 0;
    private inboundPrivateCallsCount: number = 0;
    private outboundPrivateCallsCount: number = 0;
    private internalCallsCount: number = 0;
    private emailsCount: number = 0;
    private smssCount: number = 0;
    private webchatsCount: number = 0;
    private imCount: number = 0;
    private missedCount: number = 0;
    private transferredCount: number = 0;
    private totalHandledProspects: number = 0;
    private interactionCountsMap: number = 0;

    constructor(agentStats: any) {
        super();
        super.mapProperties(agentStats);

        this.mapInteractionCounts(agentStats.interactionCounts);
        this.interactionCountsMap = agentStats.interactionCountsMap;
        this.mapTimes(agentStats.onDutyTimes, ON_DUTY_STATES);
        this.mapTimes(agentStats.handleTimes, HANDLING_STATES);
        this.mapTimes(agentStats.offDutyTimes, PRESENCES);
    }

    private mapTimes(times: any, map: any): void {
      if (!times){
        return;
      }
      times.forEach(time => {
        this[map[time.type]] = time.duration;
      });
    }

    private mapInteractionCounts(channels: any): void {
      if (!channels) {
        return;
      }
      channels.forEach(channel => {
        this[CHANNELS[channel.type]] = channel.count;
      });
    }
}
