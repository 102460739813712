import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('AssignWebhookDialog');
declare const PLATFORM;

@autoinject()
export class AssignWebhookDialog {

  public dialogHeader: string = 'Webhooks';
  public webhookTabs: any[];
  public interactionFlowId: string;
  public live: boolean;

  constructor(private dialogController: DialogController) {}

  public activate(data: { interactionFlowId: string; live: boolean }): void {
    if (data.live) {
      this.dialogHeader = 'Live Webhooks';
    }

    this.webhookTabs = [
      { id: '1', name: 'Assigned Webhooks', viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/assign-webhook/webhooks/webhooks'), icon: 'channel-ticket', data },
      { id: '2', name: 'Create Webhook', viewModel: PLATFORM.moduleName('features/interaction/interactionFlow/assign-webhook/add-webhook/add-webhook'), icon: 'plus', data },
    ];
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

}
