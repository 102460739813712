/*
 */
import {LogManager, customElement, bindable, containerless} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZFlexContainer');
/*
 */
@containerless()
@customElement('z-flex-container')
export class ZListContainer {

  @bindable direction;
  @bindable wrap;
  @bindable justifycontent;
  @bindable aligncontent;
  @bindable alignitems;
  @bindable height;

}
