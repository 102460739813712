import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 */
import {BaseModel} from 'zailab.abstract';
/*
 */
const logger = LogManager.getLogger('InteractionFlowStatsModel');
/*
 */
const FLOW_NAMES = {
  'Inbound Call': 'inboundCall',
  'Outbound Call': 'outboundCall',
  'Email': 'email',
  'SMS': 'sms',
  'Chat': 'chat',
  'Kiosk': 'kiosk',
  'Instant Message': 'im',
  'Ticket': 'ticket'
};

export default class extends BaseModel {

  inboundCall = 0;
  outboundCall = 0;
  email = 0;
  sms = 0;
  chat = 0;
  kiosk = 0;
  im = 0;
  ticket = 0;

  constructor(stats) {
    super();
    this.mapProperties(stats);
    this._mapProperties(stats);
  }

  _mapProperties(stats) {

    if(!stats || !stats.length) {
      return;
    }

    stats.forEach(stat => {

      let transposedProperty = FLOW_NAMES[stat.name];

      this[transposedProperty] = stat.flows ? stat.flows.length : 0;

    });

  }
}

