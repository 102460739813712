import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';

import { DataGridMapping } from './../../../../../../../components/templates/data-grid/data-grid';

const logger = LogManager.getLogger('InteractionTeamStats');
let viewElement = {};

@autoinject
export class InteractionTeamAgentsStats {

  public columnConfig = [
    DataGridMapping.from({
      'Agent': {
        value: (c) => c.name,
        align: 'left',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Interactions Handled': {
        value: (c) => c.handledInteractions,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Interactions Assessed': {
        value: (c) => c.interactionsAssessed,
        align: 'center',
        width: 150,
        filterType: 'input',
        disabled: true
      }
    }),
    DataGridMapping.from({
      'Assessments Passed': {
        value: (c) => `<span style="display: none!important;"><span class="">${c.assessmentsPassed}</span></span>` + c.assessmentsPassed,
        align: 'center',
        width: 150,
        style: (c) => 'good-light',
        cssClass: 'u-position-relative square-component',
        filterType: 'input'
      }
    }),
    DataGridMapping.from({
      'Assessments Failed': {
        value: (c) => `<span style="display: none!important;"><span class="">${c.assessmentsFailed}</span></span>` + c.assessmentsFailed,
        align: 'center',
        style: () => 'bad-light',
        width: 150,
        cssClass: 'u-position-relative square-component',
        filterType: 'input'
      }
    }),
    DataGridMapping.from({
      'Group': {
        value: () => '',
        hidden: true
      }
    }),
    DataGridMapping.from({
      'View': {
        value: (c) => {
          return `
            <div
              class="list-item hover flex flex--justify-center"
              title="View"
              style="align-items: center;"
              onclick="this.dispatchEvent(new CustomEvent('view', { bubbles: true, detail: { id: '${c.id}' } }))"
            >
              <div class="labels">
                <div class="o-z-icon o-z-icon--view o-z-icon--size-medium" style="margin-right: 0;"></div>
              </div>
            </div>
          `;
        },
        align: 'center',
        width: 50,
        cssClass: 'full-size',
        disableSorting: true
      }
    }),

  ];
}
