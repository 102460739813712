import {bindable, customElement, LogManager, containerless, computedFrom} from 'aurelia-framework';

const logger = LogManager.getLogger('ZStats');
/**/
@customElement('z-stats')
export class ZStats {
  showNumberStat;
  showTextStat;
  showGraphStat;

  @bindable header: string = null;
  @bindable label: string = null;
  @bindable suffix: string;
  @bindable emptyState: string;
  @bindable size: string;
  @bindable type: string;
  @bindable data: Data;
  @bindable ({attribute: 'text-transform-override'}) textTransformOverride: string;
  @bindable({attribute: 'justify-content'}) justifyContent: string;
  items = [{
    header: null,
    label: null,
    icon: null,
    size: null,
    statPosition: null,
    type: null,
    borderColor: null,
    suffix: null,
    emptyState: null,
    graphValue: null,
    correlationGraphValue: null,
    graphColor: null
  }];
  /*
  // Icon - Basic form as bindable
  */
  @bindable icon: string;
  @bindable({ attribute: 'icon-color' })
  iconColor: string;
  /*
  // Bar Graph - Basic form as bindable
  */
  @bindable({ attribute: 'correlation-graph-value' })
  correlationGraphValue: string = null;
  @bindable({ attribute: 'basic-graph-value' })
  graphValue: string = null;
  @bindable({ attribute: 'basic-graph-color' })
  graphColor: string;
  @bindable({ attribute: 'has-correlation' })
  hasCorrelation: boolean = false;
  @bindable({ attribute: 'stat-position' })
  statPosition: string = 'left';
  @bindable({ attribute: 'has-border' })
  hasBorder: boolean = true;
  @bindable({ attribute: 'border-color' })
  borderColor: string = 'highlight';
  @bindable({ attribute: 'manual-border-color' })
  manualBorderColor: string = 'highlight';
  @bindable({ attribute: 'border-position' })
  borderPosition: string = 'left';
  @bindable({ attribute: 'list-direction' })
  listDirection: string = 'column';
  @bindable({ attribute: 'align-list-items' })
  alignListItems: string = 'stretch';
  @bindable({ attribute: 'justify-list-content' })
  justifyListContent: string = 'flex-start';
  @bindable({ attribute: 'align-list-content' })
  alignListContent: string = 'space-between';

  public attached(): void {
    if (!this.data) {
      this.setDefaultStringData();
    }
  }

  private headerChanged(): void {
    if (this.header || this.header === 0) {
      this.setDefaultStringData();
    }
  }

  private labelChanged(): void {
    if (this.label || this.label === 0) {
      this.setDefaultStringData();
    }
  }

  private graphValueChanged(): void {
    if (this.graphValue || this.graphValue === 0) {
      this.setDefaultStringData();
    }
  }

  private iconChanged(): void {
    if (this.icon) {
      this.setDefaultStringData();
    }
  }

  private dataChanged(): void {
    if (this.data) {
      if (this.isArray(this.data)) {
        let _list = [];

        for (let _item of this.data) {
          _list.push({
            header: _item.header,
            label: _item.label,
            icon: _item.icon,
            size: _item.size || 'large',
            statPosition: _item.statPosition || 'left',
            type: _item.type || 'number',
            borderColor: _item.borderColor,
            suffix: _item.suffix,
            emptyState: _item.emptyState,
            graphValue: _item.graphValue,
            graphColor: _item.graphColor
          });
        }
        this.items = _list;
        return;
      }

      if (this.isData(this.data)) {
        this.items = [
          {
            header: this.data.header,
            label: this.data.label,
            icon: this.data.icon,
            size: this.data.size || 'large',
            statPosition: this.data.statPosition || 'left',
            type: this.data.type || 'number',
            borderColor: this.data.borderColor,
            suffix: this.data.suffix,
            emptyState: this.data.emptyState,
            graphValue: this.data.graphValue,
            graphColor: this.data.graphColor
          }
        ];
      }
    }
  }

  private typeChanged(): void {
    if (this.type === 'number') {
      this.showNumberStat = true;
    }
    if (this.type === 'graph' || this.type === 'basic-graph') {
      this.showGraphStat = true;
    }
    if (this.type === 'text') {
      this.showTextStat = true;
    }
    this.showTextStat = false;
  }

  private setDefaultStringData(): void {
    this.items = [
      {
        header: this.header,
        label: this.label,
        icon: this.icon,
        size: this.size || 'medium',
        statPosition: this.statPosition || 'center',
        type: this.type || 'text',
        borderColor: this.borderColor,
        suffix: this.suffix,
        emptyState: this.emptyState,
        graphValue: this.graphValue,
        correlationGraphValue: this.correlationGraphValue,
        graphColor: this.graphColor
      }
    ];
  }

  private isArray(data: Data | Data[]): data is Array<Data> {
    if (data instanceof Array) {
      return true;
    }
    return false;
  }

  private isData(data: Data | Data[]): data is Data {
    let _data = <Data>data;

    if (_data.header !== undefined && _data.label !== undefined) {
      return true;
    }
    return false;
  }
}
