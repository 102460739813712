import { autoinject, LogManager } from "aurelia-framework";
import { SearchTools } from "../../../../../zailab.common";
import { AudioGroupService } from "../../../../media/media/audiofiles/group/audio-group-service";
import { InteractionsService } from "../../interaction-flow-service";

const logger = LogManager.getLogger('AudioSearchFilter');

@autoinject
export class AudioSearchFilter {

  public listenerConfirm: () => void;
  public displayFilter: boolean = false;
  public allAudioList: any[];

  public searchTerm: string = '';

  public audioGroupId: string = '';
  public audioGroupList: any[] = [];
  public displayAudioGroupSearchResults: boolean = false;
  public audioGroupSearchCriteria: string = '';
  public selectedAudioGroupSearch: any;
  public selectedNoGroup: boolean;

  public workflowId: string = '';
  public workflowList: any[] = [];
  public workflowSearchCriteria: string = '';
  public displayWorkflowSearchResults: boolean = false;
  public selectedWorkflowSearch: any;
  public selectedNoWorkflow: boolean;

  constructor(
    private audioGroupService: AudioGroupService,
    private interactionsService: InteractionsService,
  ) { }

  public onConfirmed(listener: () => void): void {
    this.listenerConfirm = listener;
  }

  public toggleFilterDisplay(): void {
    this.displayFilter = !this.displayFilter;
    if (this.displayFilter) {
      this.getData();
    }
  }

  private getData(): void {

    this.audioGroupService.getAudioGroups().then((groups) => {
      this.audioGroupList = groups;
      if (this.audioGroupId) {
        this.selectedAudioGroupSearch = { id: this.audioGroupId };
        this.audioGroupSearchCriteria = this.audioGroupList.find((group) => group.id === this.audioGroupId).name;
      }
      if (this.selectedNoGroup) {
        this.selectNoAudioGroupSearch();
      }
    });

    this.interactionsService.retrieveOrganisationInteractionFlows().then((interactionsList) => {
      this.workflowList = interactionsList;
      if (this.workflowId) {
        this.selectedWorkflowSearch = { interactionFlowId: this.workflowId };
        this.workflowSearchCriteria = this.workflowList.find((workflow) => workflow.interactionFlowId === this.workflowId).flowName;
      }
      if (this.selectedNoWorkflow) {
        this.selectNoWorkflowSearch();
      }
    });
  }

  public showAudioGroupSearchResults(event: any): void {
    event.stopPropagation();
    this.displayAudioGroupSearchResults = true;
  }

  public closeAudioGroupDropdown(): void {
    setTimeout(() => {
      this.displayAudioGroupSearchResults = false;
    }, 250);
  }

  public selectAudioGroupSearch(audioGroup: any): void {
    this.audioGroupSearchCriteria = audioGroup.name;
    this.selectedAudioGroupSearch = audioGroup;
  }

  public showWorkflowSearchResults(event: any): void {
    event.stopPropagation();
    this.displayWorkflowSearchResults = true;
  }

  public closeWorkflowDropdown(): void {
    setTimeout(() => {
      this.displayWorkflowSearchResults = false;
    }, 250);
  }

  public selectWorkflowSearch(workflow: any): void {
    this.workflowSearchCriteria = workflow.flowName;
    this.selectedWorkflowSearch = workflow;
  }

  public resetSearchFilter(): void {
    this.searchTerm = '';
    this.displayAudioGroupSearchResults = false;
    this.audioGroupSearchCriteria = '';
    this.selectedAudioGroupSearch = null;
    this.displayWorkflowSearchResults = false;
    this.workflowSearchCriteria = '';
    this.selectedWorkflowSearch = null;
    this.workflowId = null;
    this.audioGroupId = null;
    this.selectedNoGroup = false;
    this.selectedNoWorkflow = false;
  }

  public confirmSearchFilter(): void {
    this.workflowId = null;
    this.audioGroupId = null;
    this.selectedNoGroup = false;
    this.selectedNoWorkflow = false;
    const selectedWorkflowId = this.workflowSearchCriteria && this.selectedWorkflowSearch && this.selectedWorkflowSearch.interactionFlowId;
    const selectedGroupId = this.audioGroupSearchCriteria && this.selectedAudioGroupSearch && this.selectedAudioGroupSearch.id;
    if (selectedGroupId && selectedGroupId === '___NO_GROUP___') {
      this.selectedNoGroup = true;
    }
    if (selectedWorkflowId && selectedWorkflowId === '___NO_WF___') {
      this.selectedNoWorkflow = true;
    }
    const params = [];
    if (this.searchTerm) {
      params.push(`searchTerm=${this.searchTerm}`);
    }
    if (this.selectedNoWorkflow) {
      params.push(`noWorkflow=true`);
    } else if (selectedWorkflowId) {
      params.push(`workflowId=${selectedWorkflowId}`);
      this.workflowId = selectedWorkflowId;
    }
    if (this.selectedNoGroup) {
      params.push(`noGroup=true`);
    } else if (selectedGroupId) {
      params.push(`audioGroupId=${selectedGroupId}`);
      this.audioGroupId = selectedGroupId;
    }
    this.listenerConfirm && this.listenerConfirm();
    this.displayFilter = false;
  }

  public selectAllAudioSearch(): void {
    this.audioGroupSearchCriteria = 'All Audio';
  }

  public selectNoAudioGroupSearch(): void {
    this.audioGroupSearchCriteria = 'No Group';
    this.selectedAudioGroupSearch = { id: '___NO_GROUP___' };
  }

  public selectNoWorkflowSearch(): void {
    this.workflowSearchCriteria = 'No Workflow';
    this.selectedWorkflowSearch = { interactionFlowId: '___NO_WF___' };
  }

  public setAllAudioList(allAudioList: any[]): void {
    this.allAudioList = new Array(...allAudioList);
    this.resetSearchFilter();
    this.displayFilter = false;
  }

  public filter(): any[] {
    let filtered = new Array(...this.allAudioList);
    if (this.selectedNoGroup) {
      filtered = filtered.filter((audio) => {
        return !audio.groupId;
      });
    } else if (this.audioGroupId) {
      filtered = filtered.filter((audio) => {
        if (!audio.groupId) {
          return false;
        }
        return audio.groupId === this.audioGroupId;
      });
    }
    if (this.selectedNoWorkflow) {
      filtered = filtered.filter((audio) => {
        return audio.canDelete;
      });
    } else if (this.workflowId) {
      filtered = filtered.filter((audio) => {
        if (!audio.links) {
          return false;
        }
        return audio.links.filter((link) => link === this.workflowId).length === 1;
      });
    }
    if (this.searchTerm) {
      filtered = filtered.filter((audio) => {
        return SearchTools.partialMatch(audio.filename, this.searchTerm);
      });
    }
    return filtered;
  }

  public partialMatch(searchExpression: string, value: string, searchParam: string): boolean {
    return SearchTools.partialMatch(value[searchParam], searchExpression);
  }
}