import { autoinject, LogManager } from 'aurelia-framework';
import { ZIWizard } from '../../../../../../../../../typings/zai/zai.common';
import { InteractionFlow } from '../../../../interaction-flow';
import { EmptyAudioAbstract } from '../../../abstracts/empty-audio-abstract';
import { DialogService } from 'aurelia-dialog';
import { FetchAudioService } from '../../../../../../../../_common/services/fetch-audio-service';
import { AudioFileModel } from '../../../../../../../../_common/models/audio-file-model';
import { AudioSearchFilter } from '../../../audio-search-filter';
import { AUDIO_TOOLS } from '../../../../../../../../_common/tools/audio-tools';

const logger = LogManager.getLogger('AnnouncementsDialog');

@autoinject
export class Announcements extends EmptyAudioAbstract {
  public channel: string;
  public message: string = '';
  public languageVariableName: string = '';
  public announcementsEnabled: boolean = true;
  public assignedAnnouncements: Array<AudioFileModel | string> = [];
  public assignedAnnouncementsExisting: Array<AudioFileModel | string> = [];
  public announcementInterval: number = 20;
  private wizard: ZIWizard;

  constructor(
    private interactionFlow: InteractionFlow,
    protected dialogService: DialogService,
    protected fetchAudioService: FetchAudioService,
    public audioSearchFilter: AudioSearchFilter
  ) {
    super(dialogService, fetchAudioService, audioSearchFilter);
  }

  public activate(wizard: ZIWizard): void {
    this.retrieveAudioFiles();
    this.initWizard(wizard);
    this.extractWizardData();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    this.channel = this.wizard.data.channel;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.announcementsEnabled = this.getAnnouncementsEnabled();
    this.announcementInterval = this.getAnnouncementInterval();
    this.assignedAnnouncements = this.wizard.data.assignedAnnouncements
      ? this.wizard.data.assignedAnnouncements
          .filter((audio) => {
            if (!audio) {
              return false;
            }
            return true;
          })
          .map((audio) => {
            return AUDIO_TOOLS.getSelected(audio);
          })
      : [];
    this.assignedAnnouncementsExisting = this.wizard.data.assignedAnnouncements
      ? this.wizard.data.assignedAnnouncements
      : [];
    this.languageVariableName = this.wizard.data.languageVariableName;
  }

  private getAnnouncementsEnabled(): boolean {
    return typeof this.wizard.data.announcementsEnabled === 'undefined' ||
      this.wizard.data.announcementsEnabled === null
      ? false
      : this.wizard.data.announcementsEnabled;
  }

  private getAnnouncementInterval(): number {
    return typeof this.wizard.data.announcementInterval === 'undefined' ||
      this.wizard.data.announcementInterval === null
      ? 20
      : this.wizard.data.announcementInterval;
  }

  private toggleAnnouncementsEnabled(): void {
    this.announcementsEnabled = !this.announcementsEnabled;
  }

  public removeAnnouncementFromFlow(
    index: number,
    announcement: AudioFileModel | string
  ): void {
    this.assignedAnnouncements.splice(index, 1);
  }

  public addAnnouncementToFlow(announcement: AudioFileModel | string): void {
    if (this.channel === 'Chat' || this.channel === 'Instant Message') {
      if (this.message.length === 0) {
        return;
      }
      this.message = '';
    }
    this.assignedAnnouncements.push(announcement);
  }

  private deprioritiseAnnouncement(
    index: number,
    announcement: AudioFileModel | string
  ): void {
    let newIndex = index + 1;
    if (newIndex >= this.assignedAnnouncements.length) {
      return;
    }
    this.array_move(this.assignedAnnouncements, index, newIndex);
  }

  private prioritiseAnnouncement(
    index: number,
    announcement: AudioFileModel | string
  ): void {
    let newIndex = index - 1;
    if (newIndex < 0) {
      return;
    }
    this.array_move(this.assignedAnnouncements, newIndex, index);
  }

  private array_move(
    arr: Array<AudioFileModel | string>,
    old_index: number,
    new_index: number
  ): void {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  }

  private updateWizardData(): void {
    this.wizard.data.announcementsEnabled = this.announcementsEnabled;
    if (this.channel === 'Chat' || this.channel === 'Instant Message') {
      this.wizard.data.assignedAnnouncements = this.assignedAnnouncements;
    } else {
      this.wizard.data.assignedAnnouncements = this.assignedAnnouncements.map(
        (announcement: AudioFileModel) => {
          if (!announcement.otherAudio || !announcement.otherAudio.length) {
            for (const existingAnnouncement of this
              .assignedAnnouncementsExisting) {
              const id = existingAnnouncement['_id'];
              if (id && id.audioId === announcement.audioId) {
                return existingAnnouncement;
              }
            }
          }

          return AUDIO_TOOLS.getAudioAnnouncement(announcement);
        }
      );
    }
    this.wizard.data.announcementInterval = Number(this.announcementInterval);
    this.wizard.data.languageVariableName = this.languageVariableName;
  }

  private nextStep(): void {
    this.wizard.continue();
  }
}
