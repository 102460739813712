import { inject, LogManager } from 'aurelia-framework';
import { HttpClient, HttpResponseMessage, RequestBuilder } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';

import { Oplog, SessionStore, MESSAGE_EVENTS } from 'zailab.common';

import { AutoRechargeDetails, PaymentDetails, CardInfo, Expiry } from './auto-recharge-model';

const logger = LogManager.getLogger('AutoRechargeService');

@inject(HttpClient, Oplog, SessionStore, EventAggregator)
export class AutoRechargeService {

  private httpClient: HttpClient;
  private oplog: Oplog;
  private sessionStore: SessionStore;
  private eventAggregator: EventAggregator;
  private oplogSubscription: any;
  private eventSubscription: any;
  private timeoutSubscription: any;

  constructor(httpClient: HttpClient, oplog: Oplog, sessionStore: SessionStore, eventAggregator: EventAggregator) {
    this.httpClient = httpClient;
    this.oplog = oplog;
    this.sessionStore = sessionStore;
    this.eventAggregator = eventAggregator;
  }

  public get countryCode(): string {
    return this.sessionStore.get.organisation.country.code;
  }

  public get currencySymbol(): string {
    return this.sessionStore.get.account.currencySymbol;
  }

  public findAutoRecharge(): Promise<AutoRechargeDetails> {
    return this.createRequest().asGet().send()
      .then((response: AutoRechargeResponse) => this.mapResponseAsAutoRechargeDetails(response));
  }

  public findCards(): Promise<PaymentDetails> {
    return this.httpClient
      .createRequest('v1/finance/accounts/me/cards').asGet().send()
      .then((response: CardsResponse) => this.mapResponseAsPaymentDetails(response));
  }

  public createAutoRechargeDetails(data: AutoRechargeDetails): Promise<HttpResponseMessage> {
    return new Promise((resolve, reject) => {
      try {
        this.handlePromise(data, resolve, reject);
        this.createRequest(data).asPost().send();
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAutoRechargeDetails(data: AutoRechargeDetails): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.handlePromise(data, resolve, reject);
        this.createRequest(data).asPut().send();
      } catch (error) {
        reject(error);
      }
    });
  }

  public enableAutoRechargeDetails(data: AutoRechargeDetails): Promise<HttpResponseMessage> {
    return new Promise((resolve, reject) => {
      try {
        this.handlePromise(data, resolve, reject);
        this.createRequest(data, 'enable').asPut().send();
      } catch (error) {
        reject(error);
      }
    });
  }

  public disableAutoRechargeDetails(data: AutoRechargeDetails): Promise<HttpResponseMessage> {
    return new Promise((resolve, reject) => {
      try {
        this.handlePromise(data, resolve, reject);
        this.createRequest(data, 'disable').asPut().send();
      } catch (error) {
        reject(error);
      }
    });
  }

  public navigateToBuyCreditTab(): void {
    this.eventAggregator.publish('account.tab.navigation', 'Buy Credit');
  }

  public close(): void {
    this.oplogSubscription && this.oplogSubscription.unsubscribe();
    this.eventSubscription && this.eventSubscription.dispose();
    this.timeoutSubscription && clearTimeout(this.timeoutSubscription);
  }

  private mapResponseAsAutoRechargeDetails(response: AutoRechargeResponse): AutoRechargeDetails {
    const data: AutoRechargeDetails = new AutoRechargeDetails();
    data.account = this.sessionStore.get.account;
    if (response) {
      data.recharge = response.recharge ? response.recharge + '' : null;
      data.threshold = response.recharge ? response.threshold + '' : null;
      data.cardId = response.cardId ? response.cardId + '' : null;
      data.enabled = response.enabled;
    }
    return data;
  }

  private mapResponseAsPaymentDetails(response: CardsResponse): PaymentDetails {
    const data: PaymentDetails = new PaymentDetails();
    if (response && response.cards) {
      data.cards = response.cards.map((card: CardsResponseCardInfo) => new CardInfo(card.cardId, card.number, card.type, card.expiry));
    }
    return data;
  }

  private onAutoRechargeViewChange(accountId: string): Promise<any> {
    this.oplogSubscription = this.oplog
      .subscribe()
      .withKeyField('_id')
      .withKeyValue(accountId)
      .withNameSpace('account-projector.autoRechargeDetailsView');
    return this.oplogSubscription.when('insert', 'update');
  }

  private handlePromise(data: AutoRechargeDetails, resolve: any, reject: any): void {
    this.timeoutSubscription = setTimeout(() => {
      this.close();
      this.eventAggregator.publish(MESSAGE_EVENTS.ERROR, 'Your changes have timed out and may not have been saved');
      reject();
    }, 5000);
    this.onAutoRechargeViewChange(data.account.accountId).then(() => {
      this.close();
      this.handleAutoRechargeViewChange();
      resolve();
    });
    this.eventSubscription = this.eventAggregator.subscribeOnce('ValidationErrorsReceived', (data) => {
      this.close();
      if (data.state.entity === 'configureAutoRecharge') {
        reject();
      } else {
        logger.info('ValidationErrorsReceived :: data=', data);
      }
    });
  }

  private handleAutoRechargeViewChange(): void {
    this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Your changes have been saved');
  }

  private createRequest(data?: AutoRechargeDetails, path?: string): RequestBuilder {
    const request: RequestBuilder = this.httpClient.createRequest(this.getBaseUri(!!data ? data.account.accountId : 'me', path));
    if (!path && data) {
      request.withContent(data.toPayload());
    }
    return request;
  }

  private getBaseUri(accountId: string, path: string): string {
    return `v1/finance/accounts/${accountId}/auto-recharge${path ? `/${path}` : ''}`;
  }
}

class AutoRechargeResponse extends HttpResponseMessage {

  public threshold: number;
  public recharge: number;
  public cardId: string;
  public enabled: boolean;
}

class CardsResponse extends HttpResponseMessage {

  public cards: CardsResponseCardInfo[];
}

class CardsResponseCardInfo {

  public cardId: string;
  public number: string;
  public type: string;
  public expiry: Expiry;
}