import { LogManager, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { AudioAddService, SessionStore } from 'zailab.common';
import { DashboardWidget } from '../../../../organisation/organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('Audio Widget');

@inject(Router, AudioAddService, SessionStore)
export class AudioDashboardWidget extends DashboardWidget {

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }];
  public count = 0;
  public ready = false;

  constructor(
    public router: Router,
    private audioService: AudioAddService,
    private sessionStore: SessionStore
  ) {
    super();
  }

  public activate(): void {
    this.initialiseAudioData();
  }

  public initialiseAudioData(): void {

    const organisation = this.sessionStore.get.organisation;

    if (!organisation || !organisation.organisationId) {
      setTimeout(() => {
        this.initialiseAudioData();
      }, 500);
      return;
    }

    this.getAudioFilesCount().then(_count => {
      this.count = _count;
      this.ready = true;
    })
    .catch(() => {
      this.ready = true;
    });
  };

  private async getAudioFilesCount(): Promise<number> {
    try {
      return await this.audioService.getAudioFilesCount();
    } catch (error) {
      logger.info('Could not retrieve Audio Files', error);
      return 0;
    }
  };

  public unbind = (): void => { }
}
