import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';

import { SessionStore, ZaiForm } from 'zailab.common';
import { RoutingGroupsService } from '../routing-groups-service';

const logger = LogManager.getLogger('MemberListView');

@autoinject()
export class AddRoutingGroupDialog {

  private existingRoutingGroupNames: string[];
  private name: string;

  public form: any[][];
  public formData: { name: string; valid: boolean };
  private submitting: boolean;

  constructor(
    private dialogController: DialogController,
    private routingGroupsService: RoutingGroupsService
  ) {}

  public activate(data: { name: string; existingRoutingGroupNames: string[] }): void {
    if (data.name) {
      this.name = data.name;
    }
    this.existingRoutingGroupNames = data.existingRoutingGroupNames || [];

    
    new ZaiForm()
      .newField()
      .asTextInput()
      .withFocus()
      .withTitle('Routing Group Name', '160px')
      .withPlaceholder('Enter text here.')
      .withIdentifier('name')
      .withValue(this.name)
      .withValidation([
        { validationType: ZaiForm.VALIDATION_TYPES.REQUIRED },
        { validationType: ZaiForm.VALIDATION_TYPES.TEXT_WHITESPACE_HYPHEN },
        { validationType: ZaiForm.VALIDATION_TYPES.UNIQUE_NAME, value: this.existingRoutingGroupNames }
      ])
      .insertField()
      .finaliseForm(form => {
        this.form = form;
      })
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public confirm(): void {
    if (this.formData.valid) {
      this.submitting = true;

      this.routingGroupsService
        .createRoutingGroup(this.formData.name)
        .then(response => {
          setTimeout(() => {
            this.dialogController.ok(response);
          }, 1500);
        })
        .catch(e => {
          console.warn('Failed to update Agent Sampling Config due to:', e);
          this.submitting = false;
        })
    }
  }

}

