export class TransferList {
 
  public id: string;
  public name: string;
  public businessPartner: string;
  public contactCenter: string;
  public transferRecipients: TransferRecipient[];
  public transferTeams: string[];
  public transferMembers: string[];
  public canDelete: boolean = true;
  public icon: string = 'channel-outgoing';
  public descriptionTwo: string = '-';

  public get description(): string {
    return `Contacts: ${this.transferRecipients.length}`;
  }
}

export class TransferRecipient {
  
  public id: string;
  public name: string;
  public surname: string;
  public contactNumber: string;
  public transferRecipientId: string;
  public category: string;
  public type: string;

  public get title(): string {
    return `${this.name} ${this.surname} ${this.contactNumber}`;
  }
}
