export enum GRAPH_COLORS {
  CALLS = '#51dfef',
  ABANDONED  = '#a4f3fc',
  VOICEMAIL  = '#ffcb51',
  ANSWERED  = '#fcada4',
  EMAIL = '#fd328d',
  SMS = '#c18ffd',
  CHATS = '#633ebb',
  CALLBACK = '#0067cc',
  CALLBACKS_REQUESTED = '#0067cc',
  WEBSITE_CALL = '#00baff',
  MISSED = '#fd7557',
  FORWARDED = '#4992ff',
  CLEARED = '#FD348D',
}

export enum GRAPH_COLOR_NAMES {
  CALLS = 'color-anakiwa',
  VOICE = 'color-anakiwa',
  ABANDONED  = 'color-celeste',
  ANSWERED  = 'color-light-pink',
  VOICEMAIL  = 'color-marigold-yellow',
  MAILBOX  = 'color-marigold-yellow',
  EMAIL = 'color-brilliant-rose',
  EMAILS = 'color-brilliant-rose',
  SMS = 'color-bright-lilac',
  CHATS = 'color-electric-violet',
  TICKET = 'color-yellow',
  INSTANT_MESSAGE = 'color-bright-green',
  CALLBACK = 'color-cornflower-blue',
  CALLBACKS_REQUESTED = 'color-cornflower-blue',
  WEBSITE_CALL = 'color-bright-turquoise',
  MISSED = 'color-salmon',
  CLEARED = 'color-brilliant-rose',
  FORWARDED = 'color-azure-radiance'
}