import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { List } from '../../../../abstract/list/list-builder';
import { ContactCenter } from '../contact-center-model';
import { ContactCenterService } from '../contact-center-service';

const logger = LogManager.getLogger('ContactCenterList');

@autoinject()
export class ContactCenterList {

  private itemList: List;
  private list: ContactCenter[];

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator,
    private contactCenterService: ContactCenterService
  ) { }

  public attached(): void {
    this.retrieveList();
  }

  private retrieveList(): void {
    this.eventAggregator.publish('app:loader:show');
    this.contactCenterService.findAll()
      .then((list) => {
        this.list = list || [];
        this.setupListConfig();
      })
      .catch(error => this.handleFailure(error));
  }

  private setupListConfig(): void {
    this.itemList = List.Builder()
      .items(this.list)
      .icon('icon')
      .displayId('name')
      .enableAdd()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .build();
    this.itemList.select = true;
    this.itemList.autoSelect = false;
    this.eventAggregator.publish('app:loader:hide');
  }

  private handleFailure(error: any): void {
    logger.error('retrieve list > error = ', error);
  }

  public add(): void {
    this.router.navigate('add');
  }

  public select(event: any): void {
    this.router.navigate(`edit/${event.detail.item.contactCentreId}`);
  }

  public delete(event: any): void {
    let deletesLeft: number = event.detail.length;
    event.detail.forEach((item: ContactCenter) => {
      this.contactCenterService.delete(item.contactCentreId)
        .then(() => this.handleDeleted(item, --deletesLeft))
        .catch((error) => logger.error(`delete :: ${item.contactCentreId} :: error=`, error));
    });
  }

  private handleDeleted(item: ContactCenter, deletesLeft: number): void {
    this.list.splice(this.list.indexOf(item), 1);
    if (deletesLeft === 0) {
      this.setupListConfig();
    }
  }
}