import { autoinject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { SessionStore } from '../../../_common/stores/session-store';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const logger = LogManager.getLogger('AssistService');

@autoinject()
export class AssistService {

  private readonly baseUrl = 'v1/organisation/assist';

  constructor(
    private http: HttpClient,
    private sessionStore: SessionStore
  ) { }

  private get memberId(): string {
    if (!this.sessionStore.get.user) {
      return null;
    }
    return this.sessionStore.get.user.memberId;
  }

  public findAllMemberAssists(): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${this.memberId}`)
      .asGet()
      .send()
      .then((response: any) => {
        return response;
      });
  }

  public findAllAssists(memberId: string): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${memberId}`)
      .asGet()
      .send()
      .then((response: any) => {
        return response;
      });
  }

  public findAllTeamLeaderAssists(): Promise<any> {
    return this.http.createRequest(`${this.baseUrl}/${this.memberId}/team-leader`)
      .asGet()
      .send()
      .then((response: any) => {
        response = response.map((item) => {
          item.name = `${item.firstName} ${item.surname}`;
          return item;
        });
        return response;
      });
  }

  public postRaiseHand(): Promise<any> {
    const assistId = uuidv4();
    const timestamp = Date.now();
    const timezone = moment.tz.guess();
    return this.http.createRequest(`${this.baseUrl}/${this.memberId}/raise-hand`)
      .asPut()
      .withContent({ assistId, timestamp, timezone })
      .send();
  }

  public postLowerHand(assistId: string, memberId: string): Promise<any> {
    const timestamp = Date.now();
    const timezone = moment.tz.guess();
    return this.http.createRequest(`${this.baseUrl}/${memberId}/lower-hand`)
      .asPut()
      .withContent({ assistId, timestamp, timezone, loweredByMemberId: this.memberId })
      .send();
  }
}
