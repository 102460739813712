import { inject, bindable, containerless, customElement, LogManager } from 'aurelia-framework';
import { List } from 'zailab.abstract';
import { Event } from 'zailab.common';
import { BusinessPartner } from '../business-partner-model';
import { BusinessPartnerService } from '../business-partner-service';
/*
 */
let logger = LogManager.getLogger('CondensedBusinessPartnerList');
/*
 */
@containerless()
@customElement('z-condensed-business-partner-list')
@inject(Element, BusinessPartnerService)
export class CondensedBusinessPartnerList {

  @bindable public required: boolean = false;
  @bindable public multiselect: boolean = false;
  @bindable public nodefaultselected: boolean = false;
  @bindable public selected: BusinessPartner[] = [];
  @bindable public canedit: boolean = false;
  @bindable public titleoverride: string = '';
  @bindable({ attribute: 'items-per-row' }) public itemsperrow: number = 6;

  public list: List;

  constructor(
    private element: Element,
    private businessPartnerService: BusinessPartnerService
  ) { }

  public attached(): void {
    this.businessPartnerService.findAll().then((items: BusinessPartner[]) => {
      this._setupConfig(items);
    });
  }

  private _setupConfig(items: BusinessPartner[]): void {
    this.list = List.Builder() //
      .required(this.required) //
      .selectionType(this.multiselect ? List.MULTIPLE_SELECTION_TYPE : List.SINGLE_SELECTION_TYPE) //
      .items(items) //
      .title(this.titleoverride ? this.titleoverride : 'Select Business Partner') //
      .selectedItems(this.selected ? Object.assign([], this.selected) : []) //
      .icon('worktypes') //
      .uniqueIdentifier('id') //
      .displayId('name') //
      .selectionStrategy(null) //
      .enableReadOnly(this.canedit) //
      .autoSelect(false) //
      .build();
  }

  public select(event: Event): void {
    new Event(this.element, 'select', event);
  }

  public get itemsPerRow(): number {
    return this.itemsperrow || 6;
  }

  public updateItems(data: BusinessPartner[]): void {
    new Event(this.element, 'updateitems', data);
  }
}