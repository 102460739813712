export class CheckEmptyStateValueConverter {
  toView(value, stringToAttach, customEmptyString) {

    if((value && value !== 'null') || value === 0) {
      return value + (stringToAttach || '');
    }

    if(customEmptyString) {
      return customEmptyString;
    }

    return '--';
  }
}
