import {inject, bindable, containerless, customElement} from 'aurelia-framework';
import { ZaiFileLoader } from '_assets/utils/zai-file-loader';

@inject(Element, ZaiFileLoader)
@containerless()
@customElement('z-list-empty-state')
export class ZListEmptyState {

  @bindable private state: ZIEmptyState;

  constructor(private element: Element, private zaiFileLoader: ZaiFileLoader) {

  }

  private navigateToRoute(_mouseEvent: MouseEvent): void {
    this.element.dispatchEvent(new CustomEvent('viewsetup', {
      bubbles: true,
      detail: {ctrlClicked: _mouseEvent.ctrlKey}
    }))
  }

  public getImage(image: string): string {
    const imageContent = this.zaiFileLoader.getFile(image);
    if (imageContent) {
      return imageContent;
    }
    return './_assets/img/svg/' + image + '.svg';
  }

}