import {autoinject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

import {SESSION_EVENTS} from 'zailab.common';
import { UserSessionModel } from './sessionmodels/user-session-model';

const PASSPORT = 'zai_conversations_passport';

@autoinject
export class PassportStore {
  public passportId: string;

  constructor(private eventAggregator: EventAggregator) {
    this.restorePassport();
    this.subscribeToUserEvents();
  }

  private restorePassport(): void {
    let passportId = sessionStorage.getItem(PASSPORT);
    if(passportId) {
      this.passportId = passportId;
    }
  }

  private subscribeToUserEvents(): void {
    this.eventAggregator.subscribe(SESSION_EVENTS.USER.EVENT, (user: UserSessionModel) => this.storePassport(user.passportId));
    this.eventAggregator.subscribe(SESSION_EVENTS.RESET.EVENT, () => this.clearPassport());
  }

  private storePassport(passportId: string): void {
    if(passportId) {
      this.passportId = passportId;
      sessionStorage.setItem(PASSPORT, passportId);
    }
  }

  private clearPassport(): void {
    this.passportId = null;
    sessionStorage.setItem(PASSPORT, '');
  }
}