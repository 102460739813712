import { LogManager } from "aurelia-framework";
import { DispositionModel } from "../disposition-codes-model";

const logger = LogManager.getLogger('EditTieredDispositionListState');

export class EditTieredDispositionListState {

  private state: Map<string, Map<string, Array<string>>> = new Map();
  private models: Map<string, DispositionModel> = new Map();

  public saveState(id: string, categoryMap: Map<string, Array<string>>): void {
    this.state.set(id, categoryMap);
  }

  public loadState(id: string): Map<string, Array<string>> {
    return this.state.get(id);
  }

  public isState(id: string): boolean {
    return this.state.has(id);
  }

  public saveModel(id: string, model: DispositionModel): void {
    this.models.set(id, model);
  }

  public loadModel(id: string): DispositionModel {
    return this.models.get(id);
  }

  public isModel(id: string): boolean {
    return this.models.has(id);
  }
}

export const state = new EditTieredDispositionListState();