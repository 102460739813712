import { inject, customElement, bindable, LogManager, computedFrom } from 'aurelia-framework';
/*
 */
import { SessionStore, Clone, ArrayTools, Event } from 'zailab.common';
import { SkillsService } from '../skills-service';
/*
 */
let logger = LogManager.getLogger('SkillsWidget');

/*
 */
@customElement('z-condensed-skills-list')
@inject(Element, SessionStore, SkillsService)
export class SkillsWidget {

  @bindable multiselect;
  @bindable selectedskills;
  @bindable mandatory;
  @bindable canedit;
  @bindable titleoverride;
  @bindable({ attribute: 'items-per-row' }) itemsperrow;
  ready = false;
  isEditing = false;
  skillGroups = [];
  skills = [];

  constructor(element, sessionStore, skillsService) {

    this.element = element;
    this.sessionStore = sessionStore;
    this.skillsService = skillsService;
  }

  bind() {

    this.skillsService.retrieveSkillGroups().then((skillGroups) => {

      let skillGroupWithSkills = [];
      let skillGroupWithoutSkills = [];

      for (let _item of skillGroups) {

        for (let skill of _item.skills) {
          skill.skillGroupId = _item.skillGroupId;
          skill.skillGroupName = _item.skillGroupName;
          skill.skillId = skill.id;
          skill.skillName = skill.name;
        }
        _item.hasSkills = _item.skills.length;
        if (!_item.skills.length) {
          _item.disabled = true;
          skillGroupWithoutSkills.push(_item);
          continue;
        }
        skillGroupWithSkills.push(_item);
      }

      skillGroupWithoutSkills = ArrayTools.sort(skillGroupWithoutSkills, 'skillGroupName');
      skillGroupWithSkills = ArrayTools.sort(skillGroupWithSkills, 'skillGroupName');
      this.skillGroups = skillGroupWithSkills.concat(skillGroupWithoutSkills);
      this._selectDefaultSkillGroup();
      this._highlightSelectedSkills();
    }, (error) => {
      logger.info('Error > Unable to retrieve organisation skills > ', error);
    });
  }

  get itemsPerRow() {
    return this.itemsperrow || 4;
  }

  get title() {
    return (this.titleoverride) ? this.titleoverride : 'Select Skills';
  }

  @computedFrom('selectedskills')
  get selectedItemsConcatenated() {

    if (!this.selectedskills) {
      return 'No items selected.';
    }
    if (!this.skillGroups || !this.skillGroups.length) {
      return '';
    }

    let _skills = this._getSelectedSkills();
    _skills = _clone(_skills);
    _sort(_skills, 'name');

    if (_skills.length) {
      return this._concatenateSkills(_skills);
    }
    return 'No items selected.';
  }

  _concatenateSkills(_skills) {

    let _itemsString = '';
    for (let index = 0; index < _skills.length; index++) {
      _itemsString += _skills[index].name;
      if (index === _skills.length - 1) {
        continue;
      }
      _itemsString += ', ';
    }
    return _itemsString;
  }

  _getSelectedSkills() {
    let items = [];
    this._iterateOverSelectedSkills((skills) => {
      items = items.concat(skills);
    });
    return items;
  }

  _highlightSelectedSkills() {

    this._iterateOverSkillGroups((skillGroup) => {

      let skills = skillGroup.skills;
      this._iterateOverSkills(skills, (skill) => {

        let selectedSkills = this._getSelectedSkills();
        this._iterateOverList(selectedSkills, (_skill) => {

          if (skill.id === _skill.id) {
            this._setSelected(skill, true);
            this._setMandatory(skill, _skill.mandatory);
          }
        });
      });
    });
  }

  _iterateOverSelectedSkills(callback) {
    this._iterateOverList(this.selectedskills, (skillGroup) => {
      callback && callback(skillGroup.skills);
    });
  }

  _iterateOverSkillGroups(callback) {
    this._iterateOverList(this.skillGroups, (skillGroup) => {
      callback && callback(skillGroup);
    });
  }

  _iterateOverSkills(skills, callback) {
    this._iterateOverList(skills, (skill) => {
      callback && callback(skill);
    });
  }

  _iterateOverList(items, callback) {
    if (items && items.length) {
      for (let item of items) {
        callback && callback(item);
      }
    }
  }

  _setSelected(item, value) {
    item.isSelected = value;
  }

  _setMandatory(item, value) {
    item.mandatory = value;
  }

  toggleEdit(value) {
    this.isEditing = value || !this.isEditing;

    if (this.isEditing) {
      this._selectDefaultSkillGroup();
      this._setClonedItems(_clone(this.skillGroups));
    } else {
      this._setClonedItems(null);
    }
  }

  _selectDefaultSkillGroup() {
    if (this.skillGroups && this.skillGroups.length) {
      this.selectSkillGroup(this.skillGroups[0]);
    }
  }

  selectSkillGroup(skillGroup) {
    this._clearSkillGroupsSelectedValues();
    skillGroup.isSelected = true;
    this._setSkills(skillGroup);
  }

  _clearSkillGroupsSelectedValues() {
    for (let _skillGroup of this.skillGroups) {
      _skillGroup.isSelected = false;
    }
  }

  _setSkills({ skills }) {
    this.skills = skills;
  }

  _setClonedItems(data) {
    this.clonedItems = data;
  }

  selectSkill(skill, event) {
    if (_isClickingInCheckbox(event) || isInvalidSkill(skill)) {
      return;
    }
    skill.isSelected = !skill.isSelected;
    if (!skill.isSelected) {
      this._setMandatory(skill, false);
    }
    new Event(this.element, 'select', [this._buildSkillGroup(skill)]);
  }

  _buildSkillGroup(skill) {
    let skillGroup = {};
    skillGroup.skillGroupId = skill.skillGroupId;
    skillGroup.skillGroupName = skill.skillGroupName;
    skillGroup.skills = [skill];
    return skillGroup;
  }

  toggleMandatory(skill) {

    if (!skill) {
      return false;
    }

    setTimeout(() => {

      if (!skill.isSelected && !skill.mandatory) {

        skill.mandatory = true;
        this.selectSkill(skill);

      } else if (skill.isSelected && !skill.mandatory) {
        skill.mandatory = true;

      } else if (skill.isSelected && skill.mandatory) {
        skill.mandatory = false;
      }
    });
  }


  acceptEdit() {
    new Event(this.element, 'updateitems', { items: _clone(this.skillGroups), originalItems: this.clonedItems });
    this.toggleEdit();
  }

  cancelEdit() {
    this._revertSelection();
    this.toggleEdit();
  }

  _revertSelection() {
    if (this.clonedItems) {
      this.skillGroups = JSON.parse(JSON.stringify(this.clonedItems));
    }
  }
}
/*
* */
function _clone(data) {
  return JSON.parse(JSON.stringify(data));
}
/*
 * */
function _sort(list, key) {
  return ArrayTools.sort(list, key);
}
/**
 * @param {Event} event
 * @returns {boolean}
 * @private
 */
function _isClickingInCheckbox(event) {
  if (event) {
    return _isCheckbox(event.srcElement) || _isCheckbox(event.srcElement.parentNode) || _isCheckbox(event.srcElement.parentNode.parentNode);
  }
  return false;
}
/*
* */
function _isCheckbox(node) {
  return node.className.indexOf('checkbox') !== -1;
}
/*
* */
function isInvalidSkill(skill) {
  return skill.isDisabled || !skill.name;
}