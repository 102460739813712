import { LogManager, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/**/
import { SessionStore } from 'zailab.common';
import { AudioFileModel } from 'zailab.common';
/**/
const logger = LogManager.getLogger('WaitingRoomDialogService');
/**/
@autoinject
export class WaitingRoomDialogService {

  private httpClient: HttpClient;
  private sessionStore: SessionStore;

  constructor(httpClient: HttpClient, sessionStore: SessionStore) {

    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
  }

  public searchAudioFiles(searchText: string): Promise<AudioFileModel[]> {

    let uri: string = `/v1/media/audio/search/?fileName=${searchText}&audioGroupId=&workflowId=`;

    return new Promise((resolve, reject) => {

      this.httpClient.get(uri)
        .then(response => {

          let audioFiles: AudioFileModel[] = this.modelCollection(response, AudioFileModel);

          resolve(audioFiles);

        }, reject);
    });
  }

  public modelCollection(items: any, Model: any): any {

    if (!items) {
      return;
    }

    if (items instanceof Array) {

      let mappedList: Array<any> = [];

      for (let item of items) {

        mappedList.push(new Model(item))
      }

      return mappedList;
    }

    let mappedObject: object = new Model(items);

    return mappedObject;
  }

}
