import { initPlaceHolder } from './quill-image-upload-placeholder'
import { QuillImageUploadStyle } from './quill-image-upload-style'
import { QUILL_IMAGE_UPLOAD_CONSTANTS } from './quill-image-upload-constants'
import { v4 as uuidv4 } from 'uuid';

export class QuillImageUpload {
  constructor(quill, options) {
    this.style = new QuillImageUploadStyle();
    this.quill = quill;
    this.options = options;
    this.range = null;

    if (typeof (this.options.upload) !== "function")
      console.warn('[Missing config] upload function that returns a promise is required');

    var toolbar = this.quill.getModule("toolbar");
    toolbar.addHandler("image", this.selectLocalImage.bind(this));
  }

  selectLocalImage() {
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement("input");
    this.fileHolder.setAttribute("type", "file");
    this.fileHolder.setAttribute('accept', 'image/*');
    this.fileHolder.onchange = this.fileChanged.bind(this);
    this.fileHolder.click();
  }

  fileChanged() {
    const file = this.fileHolder.files[0];
    const imageId = uuidv4();

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      let base64ImageSrc = fileReader.result;
      this.insertBase64Image(base64ImageSrc, imageId);
    }, false);
    if (file) {
      fileReader.readAsDataURL(file);
    }

    this.options.upload(file, imageId)
      .then((imageUrl) => {
        this.insertToEditor(imageUrl, imageId);
      },
        (error) => {
          if (file && file.size > QUILL_IMAGE_UPLOAD_CONSTANTS.MAX_IMAGE_SIZE) {
            let selection = this.quill.getSelection();
            setTimeout(() => {
              this.quill.deleteText(selection.index, selection.length + 1);
            }, 100);
          }
          console.warn(error.message);
        }
      )
  }

  insertBase64Image(url, imageId) {
    const range = this.range;
    this.quill.insertEmbed(range.index, "imageUpload", `${imageId}${QUILL_IMAGE_UPLOAD_CONSTANTS.ID_SPLIT_FLAG}${url}`);
  }

  insertToEditor(url, imageId) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
      imageElement.setAttribute('src', url);
      imageElement.classList.remove(QUILL_IMAGE_UPLOAD_CONSTANTS.IMAGE_UPLOAD_PLACEHOLDER_CLASS_NAME);
    }
  }
}