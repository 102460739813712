import { autoinject, LogManager, customElement, bindable } from "aurelia-framework";

import { Event } from 'zailab.common';

const logger = LogManager.getLogger('TableInput');

@autoinject(Element)
@customElement('z-table-input')
export class TableInput {

  @bindable model: TableInputModel;
  @bindable disabled: boolean;

  private inputValues: Array<InputValue>;
  private tableMap = {};
  
  constructor(private element: Element) {}

  public bind(): void {
    this.inputValues = this.model.table.headers.map(() => new InputValue(''));
  }

  public attached(): void {
    let els = document.querySelectorAll('.o-ztext-input__input-field.js-input');
    els.forEach(el => {
      document.getElementById(el.id)
        .addEventListener('input', (event: InputEvent) => this.valueChanged(event));
    });
  }

  public addInputValues(): void {
    const row = [...this.inputValues.map((inputValue: InputValue) => inputValue.value)];
    TableInputModel.builder.useTable(this.model.table).addRow(
      ...this.inputValues.map((inputValue: InputValue) => inputValue.value)
    );
    this.inputValues.forEach((inputValue: InputValue) => inputValue.value = '');
    new Event(this.element, 'rowadded', row);
  }

  public removeInputValues(index: number): void {
    const row = this.model.table.rows[index];
    TableInputModel.builder.useTable(this.model.table).removeRow(index);
    new Event(this.element, 'rowremoved', row.cells);
  }

  public valueChanged(event: InputEvent): void {
    //@ts-ignore
    const id = event.target.id;
    //@ts-ignore
    const value = event.target.value;
    const splitId = id.split('-');
    const row = splitId[2];
    const col = splitId[3];

    if (!this.tableMap[row]) {
      this.tableMap[row] = {};
    }
    this.tableMap[row][col] = value;
    new Event(this.element, 'change', this.tableMap);

  }
}

export class TableInputModel {

  constructor(
    public tables: Array<Table>
  ) { }

  public get table(): Table {
    return this.tables[0];
  }

  public static get builder(): TableBuilder {
    return new TableBuilder();
  }
}

export class TableBuilder {

  private table: Table = new Table();

  public useTable(table: Table): TableBuilder {
    this.table = table;
    return this;
  }

  public hideHeaders(): TableBuilder {
    this.table.hideHeaders = true;
    return this;
  }

  public showHeaders(): TableBuilder {
    this.table.hideHeaders = false;
    return this;
  }

  public setHeaders(...headers: Array<string>): TableBuilder {
    this.table.headers = headers;
    return this;
  }

  public addRow(...cells: Array<string>): TableBuilder {
    this.table.addRow(cells);
    return this;
  }

  public removeRow(index: number): TableBuilder {
    this.table.rows.splice(index, 1);
    return this;
  }

  public build(): Table {
    return this.table;
  }
}

class Table {

  public hideHeaders: boolean;
  public headers: Array<string>;
  public rows: Array<Row> = new Array();

  public addRow(cells: Array<string>): void {
    this.rows.push(new Row(cells));
  }

  public clone(): Table {
    return Object.assign({}, this);
  }
}

class Row {

  constructor(
    public cells: Array<string>
  ) { }
}

class InputValue {

  constructor(
    public value: string
  ) { }
}

