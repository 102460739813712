import { TelephoneNumberModel } from "components/atoms/inputs/phonenumber/telephone-number.model";

export interface MsTeamsDirectRoutingUpdateRequest {

  enabled: boolean;
  numbers: string[];
}

export interface MsTeamsDirectRoutingSettings {

  enabled: boolean;
  numbers: MsTeamsDirectRoutingSettingsNumber[];
}

export interface MsTeamsDirectRoutingSettingsNumber {

  number: string;
  flowName: string;
}

export class MsTeamsDirectRoutingSettingsForm implements MsTeamsDirectRoutingSettings {

  public fqdn = '';
  public port = '';
  public number = new TelephoneNumberModel('', '', '', true);

  constructor(
    public enabled: boolean,
    public numbers: MsTeamsDirectRoutingSettingsNumber[]
  ) { }

  public addNumber(): Promise<void> {
    return new Promise((resolve, reject) => {
      const existingNumber = this.numbers.find((n) => n.number === this.number.formattedNumber);
      if (existingNumber) {
        reject('Number already assigned');
        return;
      }
      const number = this.number.formattedNumber;
      const flowName = '';
      this.numbers.push({ number, flowName });
      this.number = new TelephoneNumberModel('', '', '', true);
      resolve();
    });
  }

  public removeNumber(number: string): void {
    this.numbers = this.numbers.filter((n) => n.number !== number);
  }

  public toData(): MsTeamsDirectRoutingUpdateRequest {
    return {
      enabled: this.enabled,
      numbers: this.numbers.map((n) => n.number)
    }
  }
}

export function mapMsTeamsDirectRoutingSettings(data: any): MsTeamsDirectRoutingSettings {
  return {
    enabled: data.enabled,
    numbers: data.numbers
  };
}