import { LogManager, inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/*
 */
import { SessionStore } from 'zailab.common';
import InteractionLogModel from './interaction-log-model';
/*
 */
const logger = LogManager.getLogger('InteractionLogService');
/*
 */
@inject(HttpClient, SessionStore)
export default class {

  constructor(httpClient, sessionStore) {
    this.sessionStore = sessionStore;
    this.httpClient = httpClient;
  }

  retrieveSearchableVariables() {
    return this.httpClient.createRequest(`/v1/organisation/me/searchable-interaction-variables`)
      .asGet()
      .send()
      .then((response) => {
        return response;
      });
  }

  retrieveInteraction(interactionId) {
    return this.httpClient.createRequest(`v1/organisation/interactions/history/${interactionId}`)
      .asGet()
      .send()
      .then((response) => {
        return { content: _modelV1Collection(response.calls || []), totalElements: response.totalElements };
      });
  }

  retrieveAllInteractions(searchParams, channels, outcomes, variables) {

    let params = {
      organisationId: this.sessionStore.get.organisation.organisationId,
      fromDate: searchParams.fromDate,
      toDate: searchParams.toDate,
      interactionType: searchParams.callType,
      memberId: searchParams.memberId,
      fromNumber: searchParams.fromNumber,
      toNumber: searchParams.toNumber,
      surveyType: searchParams.surveyType,
      surveyOutcome: searchParams.surveyOutcome,
      workTypeName: searchParams.workTypeName,
      dispositionId: searchParams.dispositionId,
      dispositionCategory: searchParams.dispositionCategory,
      dispositionSubCategory: searchParams.dispositionSubCategory,
      fromOrTo: searchParams.fromOrTo,
      hasRecording: ( searchParams.hasRecording === 'na' ? null : searchParams.hasRecording),
      page: searchParams.page,
      size: searchParams.size
    };

    let uri = 'v2/organisation/interactions/history';
    if (channels.length > 0 || outcomes.length > 0) {
      uri += '?';

      channels.forEach(channel => {
        uri += `channel=${channel}&`
      });

      outcomes.forEach(outcome => {
        uri += `outcome=${outcome}&`
      });
    }

    if (variables) {
      for (const [key, value] of Object.entries(variables)) {
        if (key && value) {
          params["variables." + key] = value;
        }
      }
    }

    return new Promise((resolve, reject) => {

      this.httpClient.createRequest(uri)
        .asGet()
        .withParams(params)
        .send()
        .then((response) => {
          resolve({ content: _modelCollection(response.interactions || []), totalElements: response.totalElements, totalPages: response.totalPages });
        }, (error) => {
          reject(error);
        });
    });
  }

  retrieveOrganisationMembers() {
    return this.httpClient
      .createRequest(`v1/organisation/me/members`)
      .asGet()
      .send()
      .then(
        response => {
          if (response) {
            return response;
          }
        })
      .catch(error => {
        return [];
      });
  }
  retrieveRecording(recordingId) {
    return new Promise((resolve,reject) => {
      this.httpClient.createRequest(`v1/media/recording/${recordingId}/url`)
        .asGet()
        .send()
        .then(response => {
          resolve({
            recordingURL: response
          });
        }, error => {
          reject(error);
        });
    });
  }

  retrieveAnalytics(phrase, fromDate, toDate) {
    return new Promise((resolve,reject) => {
      this.httpClient.createRequest(`v1/organisation/interactions/key-phrase?fromDate=${fromDate}&toDate=${toDate}&phrase=${phrase}`)
        .asGet()
        .send()
        .then(response => {
          resolve({
            results: response
          });
        }, error => {
          reject(error);
        });
    });
    return "";
  }
}


function _modelV1Collection(response) {
  let list = [];
  response.forEach(item => {
    let members = [];
    item.members.forEach(member => {
      members.push({
        memberId: member.id,
        personId: member.personId,
        firstName: member.fullName,
        surname: ''
      })
    });

    let variables = [];
    if (item.variables) {
      Object.keys(item.variables).forEach(variable => {
        variables.push({
          name: variable,
          value: item.variables[variable]
        })
      });
    }

    list.push(new InteractionLogModel(
      {
        agentRatingSurveys: item.agentRatingSurveys,
        agentRatingSurveyTotal: item.agentRatingSurveyTotal,
        averageGeneralSurveyPercentage: item.averageGeneralSurveyPercentage,
        outcome: item.callOutcome,
        callTranscript: item.callTranscript,
        channel: item.channel,
        duration: item.duration,
        from: item.fromNumber,
        generalSurveys: item.generalSurveys,
        members: members,
        assistants: item.assistants || [],
        payload: item.payload,
        recordingId: item.recordingId,
        startTimestamp: item.startTimestamp,
        surveyType: item.surveyType,
        taskName: item.taskName,
        to: item.toNumber,
        tos: (item.payload) ? item.payload.tos: [],
        type: item.type,
        linkedConsultingInteractionId: item.linkedConsultingInteractionId,
        consultInteractionIds: item.consultInteractionIds,
        sourceDisconnected: item.sourceDisconnected,
        targetDisconnected: item.targetDisconnected,
        variables: variables,
        recordingRemoved: item.recordingRemoved,
        conference: item.conference,
        conferenceInitiator: item.conferenceInitiator
      }
    ));
  });
  return list;
}

function _modelCollection(response) {
  let list = [];
  response.forEach(item => {
    list.push(_modelEntity(item));
  });
  return list;
}

function _modelEntity(item) {
  return new InteractionLogModel(item);
}
