import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { FeatureFlagService, SessionStore } from 'zailab.common';
import { DialogService } from 'aurelia-dialog';
import { ClickToDialService } from '../../../../_common/services/click-to-dial-service';
import CampaignModel from '../../../campaigns/campaign/campaign-model';
import CampaignService from '../../../campaigns/campaign/campaign-service';
import { SomethingWentWrongDialog } from '../../../dialogs/somethingwentwrong/something-went-wrong';
import { ContactService } from '../../../organisation/contact/contact-service';
import { ConversationService } from '../../../organisation/conversation/conversation-service';
import { INTERACTION_ACTIONS } from '../../../organisation/conversation/interaction/interaction-actions';
import { InteractionService } from '../../../organisation/conversation/interaction/interaction-service';
import { MemberInteractionModel } from '../../../organisation/conversation/interaction/member-interaction-model';
import { InteractionService as OtherInteractionService } from '../../../organisation/interactions/interaction-service';
import { OutboundFlowService } from '../../../organisation/outboundflow/outbound-flow-service';
import { DiallerEvent, DiallerState } from './dialler-state';

let logger = LogManager.getLogger('LiveInteraction');

@autoinject()
export class LiveInteraction {

  public loading: boolean;
  public contact: LiveInteractionContact;
  public interaction: LiveInteractionInteraction;
  public task: LiveInteractionTask;
  public campaign: CampaignModel;

  private memberInteractions: MemberInteractionModel[];
  private selectedFlow: any;
  private autoOpenCCConversationId: string;
  public siyaCustom: boolean;

  private subscriptions = [];

  constructor(
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    private interactionService: InteractionService,
    private otherInteractionService: OtherInteractionService,
    private conversationService: ConversationService,
    private contactService: ContactService,
    private campaignService: CampaignService,
    private outboundService: OutboundFlowService,
    private clickToDialService: ClickToDialService,
    public diallerState: DiallerState,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService
  ) { }

  public async attached(): Promise<void> {
    this.featureFlagService.isEnabled('siyaCustom')
      .then(async (siyaCustom) => {
        this.siyaCustom = siyaCustom;
      });

    this.subscriptions.push(this.eventAggregator.subscribe('TRIGGER-RECONNECT-REQUESTS', () => {
      this.fetchConnectedInteractions();
    }));
    this.subscriptions.push(this.eventAggregator.subscribe('connection-interaction-updated', async (data) => {
      this.fetchConnectedInteractions();
    }));
    this.fetchConnectedInteractions();
  }

  public deactivate(): void {
    this.subscriptions.forEach(subscription => subscription && subscription.dispose());
  }

  public call = (number: string): void => {
    if (this.selectedFlow) {
      this.interaction = new LiveInteractionInteraction(null, number, 'Outbound Call', null);
      let content = {
        from: this.sessionStore.get.user.email,
        to: number,
        metadata: {
          isClickToDial: true,
          correlationId: '',
          prospectId: '',
          outboundInteractionFlowId: this.selectedFlow.flowId,
          conversationId: this.memberInteractions[0].conversationId,
          contactId: this.memberInteractions[0].contactId,
          flowId: this.selectedFlow.flowId,
        },
        webhookAdditionalProperties: this.memberInteractions[0].payload ? this.memberInteractions[0].payload.webhookAdditionalProperties : {}
      };
      this.clickToDialService.call(content);
    }
  }

  public endInteraction(interaction: any): void {
    this.interactionService.endInteraction(interaction);
    this.eventAggregator.publish('end-interaction', interaction.interactionId);
  }

  public end(): void {
    this.interaction = null;
    this.task = null;
    this.contact = null;
    this.campaign = null;
    this.memberInteractions.forEach((memberInteraction) => {
      this.endInteraction(memberInteraction);
    });
  }

  public async open(autoOpenCC?: boolean): Promise<void> {
    let conversation = await this.findConversation();
    if (conversation) {
      if (autoOpenCC && conversation[0]) {
        if (
          this.autoOpenCCConversationId &&
          this.autoOpenCCConversationId === conversation[0].taskId
        ) {
          return;
        }
        this.autoOpenCCConversationId = conversation[0].taskId;
      }
      this.openInteraction();
    } else {
      const convoInteraction = this.memberInteractions.find(interaction => !!interaction.conversationId);
      const conversationId = convoInteraction ? convoInteraction.conversationId : null;
      const interactionIds = this.memberInteractions.map(interaction => {
        return interaction.interactionId;
      });

      this.dialogService
        .open({
          viewModel: SomethingWentWrongDialog,
          model: {
            action: 'Dialler Status | open button clicked',
            conversationId,
            interactionIds,
            error: 'Conversation not found.'
          }
        });
    }
  }

  private async findConversation(): Promise<any> {
    const interactionWithContact = this.memberInteractions.find(interaction => !!interaction.contactId);
    if (!interactionWithContact || !interactionWithContact.conversationId || !interactionWithContact.contactId) {
      return null;
    }
    this.memberInteractions.forEach(interaction => {
      interaction.contactId = interactionWithContact.contactId;
    });
    const journey = await this.conversationService.retrieveInteractions(interactionWithContact.conversationId, interactionWithContact.contactId);
    return journey;
  }

  public openInteraction(): void {
    if (this.task) {
      let task = this.memberInteractions.find(interaction => interaction.interactionId === this.task.taskId);
      if (task) {
        task.type = 'campaign';
      }
    }
    const interactions = JSON.parse(JSON.stringify(this.memberInteractions));
    this.eventAggregator.publish(INTERACTION_ACTIONS.CONNECTED_INTERACTIONS_RECOVERED, { interactions, initiateLinking: true });
  }

  public refresh(): void {
    this.loading = true;
    this.triggerRequests()
      .then(() => this.loading = false);
  }

  private requestsTimeout;
  private fetchConnectedInteractions(): any {
    if (this.requestsTimeout) {
      window.clearTimeout(this.requestsTimeout);
      this.requestsTimeout = null;
    }

    this.requestsTimeout = setTimeout(() => {
      this.triggerRequests();
    }, 1000)
  }

  private triggerRequests(): Promise<any> {
    this.outboundService.retrieveOrganisationInteractionFlows(this.sessionStore.get.organisation.organisationId)
      .then((res) => {
        this.selectedFlow = res.find((item) => item.selected);
      });
    return new Promise(resolve => this.retrieveConnectedInteractions(resolve))
  }

  private retrieveConnectedInteractions(resolve: (value: any) => void): void {
    this.otherInteractionService
      .retrieveConnectedInteractions(this.sessionStore.get.user.memberId)
      .then((memberInteractions: MemberInteractionModel[]) => {
        this.memberInteractions = memberInteractions;
        if (memberInteractions && memberInteractions.length) {

          const interactionWithContact = this.memberInteractions.find(interaction => !!interaction.contactId);

          if (interactionWithContact && interactionWithContact.contactId) {
            this.retrieveContact(interactionWithContact.contactId);
            if (interactionWithContact.conversationId) {
              return this.retrieveConversationWaypoints(memberInteractions);
            } else {
              return false;
            }
          } else if (memberInteractions.length === 1 && memberInteractions[0].interactionId) {
            return this.retrieveInteractionWorkType(memberInteractions[0].interactionId);
          }
        } else {
          this.interaction = null;
          this.task = null;
          this.contact = null;
          this.campaign = null;
        }
        resolve({});
      });
  }

  private retrieveContact(contactId: string): void {
    this.contactService.retrieveContact(contactId)
      .then((contact) => {
        this.contact = new LiveInteractionContact(
          `${contact.title} ${contact.firstName} ${contact.surname}`,
          contact.telephoneNumbers.filter((number) => !!number.formattedNumber).map((number) => number.formattedNumber),
          contact.emails.filter((email) => !!email.email).map((email) => email.email)
        );
      });
  }

  private retrieveConversationWaypoints(memberInteractions: MemberInteractionModel[]): Promise<any> {
    return this.conversationService.retrieveConversationWaypoints(memberInteractions[0].conversationId, memberInteractions[0].contactId)
      .then((conversation) => {
        if (!conversation) {
          return;
        }
        for (const waypoint of conversation.waypoints) {
          if (waypoint.taskId) {
            this.task = new LiveInteractionTask(waypoint.taskId, waypoint.campaignName, waypoint.prospectListName);
            this.retrieveCampaign(waypoint.campaignName);
          }
          if (memberInteractions.length === 1) {
            if (!this.interaction) {
              this.interaction = new LiveInteractionInteraction(null, null, null, null);
              if (!this.disableOpenButton && this.siyaCustom) {
                this.open(true);
              }
            }
            if (memberInteractions[0].state === 'WRAP_UP') {
              this.interaction.message = 'Waiting for you to end wrap up before serving the next task';
            } else {
              this.interaction.message = 'Waiting for you to end before serving the next task';
            }
          } else if (waypoint.interactionId) {
            this.interaction = new LiveInteractionInteraction(null, waypoint.to, waypoint.channel, null);
            if (!this.disableOpenButton && this.siyaCustom) {
              this.open(true);
            }
            return this.retrieveInteractionWorkType(waypoint.interactionId);
          }
        }
      });
  }

  private retrieveCampaign(campaignName: string): void {
    this.campaignService.retrieveCampaigns()
      .then((campaigns) => {
        this.campaign = campaigns.campaigns.find((campaign) => campaign.name === campaignName);
      });
  }

  private retrieveInteractionWorkType(interactionId: string): Promise<any> {
    return this.conversationService.retrieveInteraction(interactionId)
      .then((conversationInteraction) => {
        if (this.interaction) {
          this.interaction.workType = conversationInteraction.metaData.workType;
        } else {
          if (conversationInteraction.metaData) {
            const metaData = conversationInteraction.metaData;
            if (metaData.direction === 'INBOUND') {
              this.interaction = new LiveInteractionInteraction(null, metaData.from, 'Inbound Call', metaData.workType);
              this.contact = new LiveInteractionContact('Unknown', [metaData.from], []);
              if (!this.disableOpenButton && this.siyaCustom) {
                this.open(true);
              }
            }
          }
        }
        return true;
      });
  }

  public get disableOpenButton(): boolean {
    return !this.memberInteractions || !this.memberInteractions.length;
  }

  public get disableEndButton(): boolean {
    return !this.memberInteractions || !this.memberInteractions.length;
  }

  public get events(): DiallerEvent[] {
    return this.diallerState.events;
  }
}

class LiveInteractionContact {

  constructor(
    public name: string,
    public numbers: string[],
    public emails: string[],
  ) { }
}

class LiveInteractionInteraction {

  constructor(
    public message: string,
    public number: string,
    public channel: string,
    public workType: string,
  ) { }
}

class LiveInteractionTask {

  constructor(
    public taskId: string,
    public campaignName: string,
    public prospectListName: string,
  ) { }
}
