import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { List } from '../../../../abstract/list/list-builder';
import { Alert } from '../alert-model';
import { AlertService } from '../alert-service';
import { AlertDialog } from '../dialog/alert-dialog';

const logger = LogManager.getLogger('AlertList');

@autoinject()
export class AlertList {

  private itemList: List;
  private list: Alert[];

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private alertService: AlertService
  ) { }

  public attached(): void {
    this.retrieveList();
  }

  private retrieveList(): void {
    this.eventAggregator.publish('app:loader:show');
    this.alertService.findAll()
      .then((list) => {
        this.list = list || [];
        this.setupListConfig();
      })
      .catch(error => this.handleFailure(error));
  }

  private setupListConfig(): void {
    this.itemList = List.Builder()
      .items(this.list)
      .icon('icon')
      .displayId('name')
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.SINGLE_SELECTION_TYPE)
      .build();
    this.itemList.select = true;
    this.itemList.autoSelect = false;
    this.eventAggregator.publish('app:loader:hide');
  }

  private handleFailure(error: any): void {
    logger.error('retrieve list > error = ', error);
  }

  public add(): void {
    this.router.navigate('add');
  }

  public select(event: any): void {
    this.dialogService.open({ viewModel: AlertDialog, model: event.detail.item }).whenClosed(dialog => {
      if (dialog.wasCancelled) {
        return;
      }
      this.retrieveList();
    });
  }

  public delete(event: any): void {
    let deletesLeft: number = event.detail.length;
    if (deletesLeft > 0) {
      this.eventAggregator.publish('app:loader:show');
    }
    event.detail.forEach((item: Alert) => {
      this.alertService.delete(item.alertId)
        .then(() => this.handleDeleted(item, --deletesLeft))
        .catch((error) => logger.error(`delete :: ${item.alertId} :: error=`, error));
    });
  }

  private handleDeleted(item: Alert, deletesLeft: number): void {
    this.list.splice(this.list.indexOf(item), 1);
    if (deletesLeft === 0) {
      this.setupListConfig();
    }
  }
}