import {computedFrom, customElement, bindable, LogManager} from "aurelia-framework";

const logger = LogManager.getLogger('DropdownMenu');

@customElement('z-dropdown-menu')
export class DropdownMenu {

  @bindable public menu: Menu<any> = new Menu();
}

export class Menu<T> {

  public isVisible: boolean = false;
  public options: Array<T> = new Array();
  public onchange: (option: T) => void;

  public toggleVisibility(): void {
    if (this.isEnabled) {
      this.isVisible = !this.isVisible;
    }
  }

  public select(option: T): void {
    this.onchange(option);
    this.isVisible = false;
  }

  @computedFrom('options', 'options.length')
  public get isEnabled(): boolean {
    return this.options.length > 0;
  }
}