import {autoinject, LogManager} from 'aurelia-framework';
import {ZIWizard} from '../../../../../../../../../typings/zai/zai.common';
import {InteractionFlow} from "../../../../interaction-flow";
import {DialogService} from 'aurelia-dialog';
import { computedFrom, ObserverLocator } from 'aurelia-binding';
/**/
const logger = LogManager.getLogger('AdvancedRouting');
/**/
@autoinject
export class AdvancedRouting {

  private lastAgentRoutingEnabled: boolean = null;
  private lastAgentRoutingInteractionSearchType: string = null;
  private wizard: ZIWizard;
  
  public lastAgentRoutingmultiplier:  number = 1;
  public waitTimeInMinutes: number = 0;
  public waitTimeInHours: number = 1;
  
  private waitTimeMinutesObserver: any;
  private waitTimeHoursObserver: any;

  constructor(
    private interactionFlow: InteractionFlow,
    protected dialogService: DialogService,
    observerLocator: ObserverLocator
  ) {
    this.waitTimeMinutesObserver = observerLocator
      .getObserver(this, 'waitTimeInMinutes');
    this.waitTimeHoursObserver = observerLocator
      .getObserver(this, 'waitTimeInHours');
  }

  public activate(wizard: ZIWizard): void {
    this.initObservers();
    this.initWizard(wizard);
    this.extractWizardData();
    this.wizard.step.complete();
  }

  private initObservers(): void {
    this.waitTimeMinutesObserver.subscribe(() => this.onWaitTimeChanged());
    this.waitTimeHoursObserver.subscribe(() => this.onWaitTimeChanged());
  }

  private onWaitTimeChanged(): void {
    if (this.waitTimeInMinutes > 55) {
      this.waitTimeInHours++;
      this.waitTimeInMinutes = 0;
    } else if (this.waitTimeInMinutes < 0) {
      if (this.waitTimeInHours > 0) {
        this.waitTimeInMinutes = 55;
        this.waitTimeInHours--;
      } else {
        this.waitTimeInMinutes = 0;
      }
    }
    this.validate();
  }

  private validate(): void {
    if (
      this.lastAgentRoutingEnabled && (
        this.isEmailChannel || this.isSMSChannel
      ) && (
        (!this.waitTimeInHours || this.waitTimeInHours <= 0) &&
        (!this.waitTimeInMinutes || this.waitTimeInMinutes <= 0)
      )
    ) {
      this.wizard.step.incomplete();
    } else {
      this.wizard.step.complete();
    }
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    wizard.beforeNextStep(() => {
      this.updateWizardData();
      this.nextStep();
    });
  }

  private updateWizardData(): void {
    this.wizard.data.lastAgentRoutingEnabled = this.lastAgentRoutingEnabled;
    if (this.lastAgentRoutingEnabled) {
      this.wizard.data.lastAgentRoutingInteractionSearchType = this.lastAgentRoutingInteractionSearchType;
      
      let hoursToSeconds = this.waitTimeInHours * 3600;
      let minutesToSeconds = this.waitTimeInMinutes * 60;
      this.wizard.data.lastAgentRoutingWaitTime = hoursToSeconds + minutesToSeconds;
      this.wizard.data.lastAgentRoutingmultiplier = this.lastAgentRoutingmultiplier;
    } else {
      delete this.wizard.data.lastAgentRoutingInteractionSearchType;
      delete this.wizard.data.lastAgentRoutingWaitTime;
    }
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  private extractWizardData(): void {
    this.lastAgentRoutingEnabled = this.getAdvancedRoutingEnabled();
    this.lastAgentRoutingInteractionSearchType = this.wizard.data.lastAgentRoutingInteractionSearchType;
    this.lastAgentRoutingmultiplier = this.wizard.data.lastAgentRoutingmultiplier || this.lastAgentRoutingmultiplier;
    
    let waitTimeInSeconds = this.wizard.data.lastAgentRoutingWaitTime || 0;
    if (waitTimeInSeconds > 0) {
      this.waitTimeInHours = Math.floor(waitTimeInSeconds / 3600);
      this.waitTimeInMinutes = Math.floor(waitTimeInSeconds % 3600 / 60);
    }
  }

  private getAdvancedRoutingEnabled(): boolean {
    return ((typeof (this.wizard.data.lastAgentRoutingEnabled) === 'undefined') || (this.wizard.data.lastAgentRoutingEnabled === null)) ?
      false : this.wizard.data.lastAgentRoutingEnabled;
  }

  public toggleAdvancedRoutingEnabled(): void {
    this.lastAgentRoutingEnabled = !this.lastAgentRoutingEnabled;
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isEmailChannel(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'Email';
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isSMSChannel(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'SMS';
  }
}