import {LogManager} from 'aurelia-framework';
/**/
import {BaseModel} from 'zailab.abstract';
/**/
const logger = LogManager.getLogger('ConnectorModel');
/**/
export class ConnectorModel extends BaseModel {

  public name: string = null;
  public source: any = null;
  public dest: any = null;
  public src: any = null;
  public isConnected: boolean = false;
  public isSelected: boolean = false;
  public isDragging: boolean = false;
  public customExtensions: string[] = null;
  public destId: string = null;
  public connectorIndex: string = null;

  public parentConnector:ConnectorModel = null;

  constructor(connector: object) {

    super();
    super.mapProperties(connector);
    this.mapProperties();
  }

  private mapProperties(): void {
    this.connectorIndex = this.source.connectorIndex.toString();
  }
}