import {autoinject, bindable, computedFrom, customElement, LogManager} from 'aurelia-framework';
import {TelephoneNumberModel} from '../../../../components/atoms/inputs/phonenumber/telephone-number.model';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {
  ValidationControllerFactory,
  ValidationController,
  ValidationRules,
  validateTrigger
} from 'aurelia-validation';
import {BootstrapFormRenderer, ArrayTools, CountriesTools, SessionStore} from 'zailab.common';
import {ApplicationProperties} from './../../../../_config/application.properties';
import {StripeService} from './stripe-service';
import {PAYGATE_VALIDATION_MESSAGE} from '../buycredit/paygate-validation-message';
import {OrganisationSessionModel} from "../../../../_common/stores/sessionmodels/organisation-session-model";
import {AddressInterface} from "../buycredit/buy-credit-interfaces/address-interface";
import * as crs from '../../../../_assets/utils/crs.min.js';
import { ThemeService } from '../../../../_common/services/theme-service';

declare let Stripe: any;
const logger = LogManager.getLogger('StripeCard');

const FONT_URL = 'https://fonts.googleapis.com/css?family=Nunito+Sans';

const ELEMENT_CLASSES = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
};

const STRIPE_STYLE = {
  base: {
    color: null,
    fontWeight: 300,
    fontFamily: 'Nunito Sans, Open Sans, Segoe UI, sans-serif',
    fontSize: '.8571428571rem',
    fontSmoothing: 'antialiased',

    ':focus': {
      color: null,
    },

    '::placeholder': {
      color: null,
    },

    ':focus::placeholder': {
      color: null,
    },
  },
  invalid: {
    color: null,
    ':focus': {
      color: null,
    },
    '::placeholder': {
      color: null,
    },
  },
};

const colors = {
  light: {
    default: '#000000',
    placeholder: '#999',
    placeholderFocus: '#4d5257',
    invalid: '#ffa800'
  },
  dark: {
    default: '#ffffff',
    placeholder: '#999',
    placeholderFocus: '#d1dfed',
    invalid: '#ffa800'
  }
};

const STRIPE_BRAND_MAP = {
  visa: true,
  mastercard: true,
  unknown: true,
  amex: true,
};

const CARD_NOT_SUPPORTED_MESSAGE = 'VISA, Mastercard and American express cards only.';

@customElement('z-stripe-card')
@autoinject()
export class StripeCard {
  @bindable
  private isProcessingCard: boolean;
  @bindable private addcard: boolean;

  private stripe: any;
  private loading: boolean = true;
  private elements: any;
  private billingInfo = {
    CardholderName: ''
  };
  private cardInfo = {
    country: '',
    CardholderName: '',
    postalCode: '',
    city: '',
    address1: '',
    address2: '',
    region: '',
    phoneNumber: null
  };

  private serviceAddress: AddressInterface = {
    addressLine1: '',
    addressLine2: '',
    code: '',
    country: '',
    city: '',
    region: ''
  };

  private cardInformationCountry: any = '';
  private CardholderName: string = '';
  private billingAddressValidation: any;
  private serviceAddressValidation: any;
  private stripeErrorMessage: string = '';
  private cardElement: any;
  private availableCountries = [];
  private availableRegions = [];
  private country: any = '';
  private region: any = '';
  private additionalAddress: boolean = false;
  private upgradeIsValid: boolean = false;

  private submitStripeCardSubscription: Subscription = null;

  constructor(
    private eventAggregator: EventAggregator,
    private validationControllerFactory: ValidationControllerFactory,
    private applicationProperties: ApplicationProperties,
    private stripeService: StripeService,
    private sessionStore: SessionStore,
    private themeService: ThemeService
  ) {
    const theme = this.themeService.getTheme();
    STRIPE_STYLE.base.color = colors[theme].default;
    STRIPE_STYLE.base[':focus'].color = colors[theme].default;
    STRIPE_STYLE.base['::placeholder'].color = colors[theme].placeholder;
    STRIPE_STYLE.base[':focus::placeholder'].color = colors[theme].placeholderFocus;
    STRIPE_STYLE.invalid.color = colors[theme].invalid;
    STRIPE_STYLE.invalid[':focus'].color = colors[theme].invalid;
    STRIPE_STYLE.invalid['::placeholder'].color = colors[theme].invalid;

    this.cardInfo.phoneNumber = new TelephoneNumberModel('', '', 'US', true, true);

    this.billingAddressValidation = validationControllerFactory.create();
    this.billingAddressValidation.addRenderer(new BootstrapFormRenderer());
    this.billingAddressValidation.validateTrigger = validateTrigger.change;

    this.serviceAddressValidation = validationControllerFactory.create();
    this.serviceAddressValidation.addRenderer(new BootstrapFormRenderer());
    this.serviceAddressValidation.validateTrigger = validateTrigger.change;

    this.availableCountries = [{name: 'United States', id: '01'}];
  }

  private attached(): void {
    this.availableRegions = CountriesTools.getCountryRegions('us');

    this.subscribeToAccountUpgradeSelected();
    if (!this.addcard) {
      this.validateCreditCardInformationAndBillingAddress();
    } else {
      this.setupCardValidationOnlyForAdditionalCard();
      this.setupCreditCardValidations();
    }
    crs.init();
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }

  @computedFrom('organisation')
  private get organisationCountry(): string {
    if (this.organisation.country) {
      return this.organisation.country.name;
    }
  }

  @computedFrom('cardInfo.country')
  public get canUseSameBillingAddress(): boolean {
    if (this.organisation.country){
      if(this.organisation.country.name === 'United States'){
        if (this.cardInfo.country === 'United States') {
          return true;
        }
        return false;
      } else {
        return true
      }
    }
  }

  private additionalAddressChanged(): void {
    this.billingAddressValidation.validate().then((response) => {
      if (response.valid) {
        this.additionalAddress = !this.additionalAddress;
        this.setServiceAddressToBillingAddress();
      } else {
        this.additionalAddress = false;
      }
    });
  }

  private setServiceAddressToBillingAddress(): void {
    this.serviceAddress.country = 'United States';
    this.serviceAddress.region = this.cardInfo.region;
    this.serviceAddress.code = this.cardInfo.postalCode;
    this.serviceAddress.addressLine1 = this.cardInfo.address1;
    this.serviceAddress.addressLine2 = this.cardInfo.address2;
    this.serviceAddress.city = this.cardInfo.city;
  }

  private validateCreditCardInformationAndBillingAddress(): void {
    this.setupCreditCardValidations();
    this.setupCreditCardBillingInformationValidations();
    this.setupServiceAddressValidations();
  }

  private setupCreditCardValidations(): void {
    ValidationRules
      .ensure('CardholderName')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.empty)
      .matches(/^([a-zA-Z'\s-])+$/)
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.invalid)
      .on(this.billingInfo);
  }

  private setupCreditCardBillingInformationValidations(): void {
    ValidationRules
      .ensure('address1')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.address.empty)
      .ensure('city')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('postalCode')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.postalCode.empty)
      .ensure('country')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.country.empty)
      .ensure('region')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.region.empty)
      .on(this.cardInfo)
  }

  private setupServiceAddressValidations(): void {
    ValidationRules
      .ensure('addressLine1')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.address.empty)
      .ensure('city')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('code')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('country')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('region')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.postalCode.empty)
      .on(this.serviceAddress);
  }

  private setupCardValidationOnlyForAdditionalCard(): void {
    ValidationRules
      .ensure('CardholderName')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.empty)
      .matches(/^([a-zA-Z'\s-])+$/)
      .withMessage(PAYGATE_VALIDATION_MESSAGE.cardholderName.invalid)
      .ensure('address1')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.address.empty)
      .ensure('city')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.city.empty)
      .ensure('postalCode')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.postalCode.empty)
      .ensure('country')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.region.empty)
      .ensure('region')
      .required()
      .withMessage(PAYGATE_VALIDATION_MESSAGE.region.empty)
      .on(this.cardInfo)
  }

  private subscribeToAccountUpgradeSelected(): void {
    this.submitStripeCardSubscription = this.eventAggregator.subscribe('submit.stripe.card', () => {
      this.submitCard();
    });
  }

  private countryDropdownChanged(country: any): void {
    if (country !== 'United States') {
      this.additionalAddress = false;
      this.serviceAddress = {
        addressLine1: '',
        addressLine2: '',
        code: '',
        country: '',
        city: '',
        region: ''
      };
      this.setupServiceAddressValidations();
    }
    this.cardInfo.country = country;
  }

  private regionDropdownChanged(region: any): void {
    this.cardInfo.region = region;
  }

  private serviceAddressCountryDropdownChanged(country: any): void {
    this.serviceAddress.country = country;
  }

  private serviceAddressRegionDropdownChanged(region: any): void {
    this.serviceAddress.region = region;
  }

  private submitCard(): void {
    this.billingAddressValidation.validate().then((billingValidation) => {
      if (!billingValidation.valid || !this.cardInfo.phoneNumber.isNumberValid) {
        this.eventAggregator.publish('failed.stripe.card');
        this.upgradeIsValid = false;
        return;
      }
      if (billingValidation.valid) {
        this.checkServiceAddressValidation();
      }
    });

  }

  private checkServiceAddressValidation(): void {
    this.serviceAddressValidation.validate().then((serviceValidation) => {
      if (!serviceValidation.valid) {
        this.upgradeIsValid = false;
        return;
      }
      if (serviceValidation.valid) {
        this.upgradeIsValid = true;
        this.eventAggregator.publish('show.loader.upgrade');
        let cardData = {
          name: this.billingInfo.CardholderName,
          address_line1: this.cardInfo.address1,
          address_line2: this.cardInfo.address2,
          address_city: this.cardInfo.city,
          address_zip: this.cardInfo.postalCode,
          address_country: this.cardInfo.country,
          address_state: this.cardInfo.region,
        };

        if (this.organisationCountry === 'United States') {
          this.serviceAddress.country = 'United States';
        }

        if (this.additionalAddress) {
          this.serviceAddress.country = 'United States';
          this.serviceAddress.addressLine1 = this.cardInfo.address1;
          this.serviceAddress.addressLine2 = this.cardInfo.address2;
          this.serviceAddress.code = this.cardInfo.postalCode;
          this.serviceAddress.country = this.cardInfo.country;
          this.serviceAddress.city = this.cardInfo.city;
          this.serviceAddress.region = this.cardInfo.region;
        }

        this.stripe.createToken(this.cardElement, cardData).then((result) => {
          if (result.error) {
            this.eventAggregator.publish('failed.stripe.card', result.error);
          } else {
            result.phoneNumber = this.cardInfo.phoneNumber;
            this.eventAggregator.publish('submitted.stripe.card', {
              result: result,
              serviceAddress: this.serviceAddress,
              address: cardData
            });
          }
        });
      }
    });
  }


  private initStripe(): void {

    this.stripeService.checkStripe().then(() => {
      this.loading = false;

      this.stripe = Stripe(this.applicationProperties.stripeToken);

      this.elements = this.stripe.elements({
        fonts: [
          {
            cssSrc: FONT_URL
          }
        ],
      });

      this.cardElement = this.elements.create('cardNumber', {
        style: STRIPE_STYLE,
        classes: ELEMENT_CLASSES,
      });
      this.cardElement.mount('#stripe-card-number');

      this.cardElement.addEventListener('change', event => {
        this.handleStripeNumberChanged(event);
      });

      let cardExpiry = this.elements.create('cardExpiry', {
        style: STRIPE_STYLE,
        classes: ELEMENT_CLASSES,
      });
      cardExpiry.mount('#stripe-card-expiry');

      let cardCvc = this.elements.create('cardCvc', {
        style: STRIPE_STYLE,
        classes: ELEMENT_CLASSES,
      });
      cardCvc.mount('#stripe-card-cvc');
    });
  }

  private handleStripeNumberChanged(event: { brand: string }): void {
    if (!STRIPE_BRAND_MAP[event.brand]) {
      this.stripeErrorMessage = CARD_NOT_SUPPORTED_MESSAGE;
    } else {
      this.stripeErrorMessage = '';
    }
  }

  private resetForm(): void {
    this.cardInfo = {
      country: null,
      CardholderName: null,
      postalCode: '',
      city: '',
      address1: '',
      address2: '',
      region: '',
      phoneNumber: new TelephoneNumberModel('', '', 'US', true, true)
    };
    this.additionalAddress = false;
    this.serviceAddress = {
      addressLine1: '',
      addressLine2: '',
      code: '',
      country: '',
      city: '',
      region: ''
    };
  }

  private detached(): void {
    this.submitStripeCardSubscription.dispose();
    if (!this.upgradeIsValid) {
      this.resetForm();
    }
  }
}
