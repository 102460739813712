import { inject, LogManager, Factory } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 * */
import { SessionStore, WebSocket, CommandFactory, OplogService } from 'zailab.common';
import WorkTypeStatsModel from './work-types-stats-model';
import WorkTypesListModel from './work-types-list-model';
/*
 * */
import { v4 as uuidv4 } from 'uuid';
/*
 * */
const logger = LogManager.getLogger('WorkTypesService');

/*
 * */
@inject(HttpClient, SessionStore, WebSocket, Factory.of(CommandFactory), OplogService, EventAggregator)
export class WorkTypesService {

  constructor(httpClient, sessionStore, webSocket, commandFactory, oplogService, eventAggregator) {
    this.httpClient = httpClient;
    this.sessionStore = sessionStore;
    this.webSocket = webSocket;
    this.commandFactory = commandFactory;
    this.oplog = oplogService;
    this.eventAggregator = eventAggregator;
  }

  createWorkType(payload) {
    payload.taskTemplateId = uuidv4();
    this.httpClient.createRequest(`v1/organisation/task-templates`)
      .asPost()
      .withContent(payload)
      .send();
  }

  retrieveWorkTypes(ignoreModel) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/task-templates')
        .asGet()
        .send()
        .then(response => {
          let workTypes = [];
          if (response && response.taskTemplates) {
            workTypes = response.taskTemplates;
            if (ignoreModel) {
              return resolve(workTypes);
            }
          }
          resolve(this._taskTemplateListFactory(workTypes));
        }, (error) => {
          reject(error);
        });
    });
  }

  retrieveVerintWorkTypes() {
    return this.httpClient.createRequest('v1/organisation/task-templates')
      .asGet()
      .send();
  }

  retrieveWorkTypesForMember(memberId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest(`/v1/organisation/organisations/me/task-templates/members/${memberId}`)
        .asGet()
        .send()
        .then(response => {
          let workTypes = [];
          if (response && response.taskTemplates) {
            workTypes = response.taskTemplates;
          }
          resolve(this._taskTemplateListFactory(workTypes));
        }, (error) => {
          reject(error);
        });
    });
  }

  retrieveWorkType(taskTemplateId, version) {
    let url = `v1/organisation/task-templates/${taskTemplateId}`;
    if (version) {
      url += `/versions/${version}`
    }
    return this.httpClient.createRequest(url)
      .asGet()
      .withParams({})
      .send();
  }

  retrieveWorkTypesStats() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/task-templates/widget')
        .asGet()
        .send()
        .then((response) => {
          let _templates = {};
          if (response) {
            _templates = response.taskTemplates;
          }
          resolve(new WorkTypeStatsModel(_templates));
        }, (error) => {
          reject(error);
        });
    });
  }

  retrieveWorkTypeAudits(page) {
    return this.httpClient.createRequest('v1/organisation/task-templates/audit-trails')
      .asGet()
      .withParams({ page, size: 40 })
      .send()
      .catch(error => logger.warn(' > Failed to retrieve worktype audits', error));
  }

  updateWorkType(taskTemplateId, businessValue, slaWaitTimeInSeconds, targetResponse) {
    return this.httpClient.createRequest(`/v1/organisation/task-templates/${taskTemplateId}/update`)
      .asPut()
      .withContent({
        businessValue,
        slaWaitTimeInSeconds,
        targetResponse
      })
      .send();
  }

  versionWorkType(taskTemplateId) {
    return this.httpClient.createRequest(`/v1/organisation/task-templates/${taskTemplateId}/versions`)
      .asPut()
      .send();
  }

  getWorkTypeVersions(taskTemplateId) {
    return this.httpClient.createRequest(`/v1/organisation/task-templates/${taskTemplateId}/versions`)
      .asGet()
      .send();
  }

  initialiseListOplog(organisationId) {
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('organisationId', organisationId).in('task-template-projector.displayTaskTemplateListView'));
    });
  }

  initialiseStatsOplog(organisationId) {
    return new Promise((resolve) => {
      resolve(this.oplog.subscribeOn('_id', organisationId).in('task-template-projector.taskTemplateWidgetView'));
    });
  }

  retrieveInboundWorkTypes() {
    return this.retrieveWorkTypes().then((workTypes) => {
      return workTypes.filter((workType) => workType.channel === 'Inbound Call' && workType.flowType === 'Contact Centre Flow')
    });
  }

  outboundWorkTypes(businessPartnerId, contactCentreId) {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/task-templates/search')
        .asGet()
        .withParams({
          templateType: 'outbound',
          businessPartnerId,
          contactCentreId
        })
        .send()
        .then(response => {
          let taskTemplates = [];
          if (response && response.taskTemplates) {
            taskTemplates = response.taskTemplates;
          }
          resolve(this._taskTemplateListFactory(taskTemplates));
        }, (error) => {
          reject(error);
        });
    });
  }

  removeWorkType(workType) {

    return new Promise((resolve, reject) => {
      let id = workType.templateId ? workType.templateId : workType._id;
      this.httpClient.createRequest(`v1/organisation/task-templates/${id}`)
        .asDelete()
        .send();
    });

  }

  setLive(taskTemplateId, version) {
    return this.httpClient
      .createRequest(`/v1/organisation/task-templates/${taskTemplateId}/versions/${version}/setlive`)
      .asPut()
      .send();
  }

  retrieveWorktypeColorConfig() {
    return this.httpClient
      .createRequest(`v1/organisation/me/color-format-config`)
      .asGet()
      .withParams({
        type: 'WORKTYPE'
      })
      .send();
  }

  addWorktypeColorConfig(configId, payload) {
    delete payload.id;
    return this.httpClient
      .createRequest(`v1/organisation/me/color-format-config/${configId}`)
      .asPost()
      .withContent(payload)
      .send();
  }

  updateWorktypeColorConfig(configId, payload) {
    return this.httpClient
      .createRequest(`v1/organisation/me/color-format-config/${configId}`)
      .asPut()
      .withContent(payload)
      .send();
  }

  _taskTemplateListFactory(taskTemplateData) {
    let taskTemplateList = [];
    taskTemplateData.forEach(taskTemplate => {
      taskTemplateList.push(new WorkTypesListModel(taskTemplate));
    });
    return taskTemplateList;
  }
}
