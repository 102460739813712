import {LogManager} from 'aurelia-framework';
// @ts-ignore
import {DialogController} from 'aurelia-dialog';
import { autoinject } from 'aurelia-dependency-injection';

const logger = LogManager.getLogger('AssignedFlowsDialog');

interface IAssignedFlows {
  name: string;
  versions: string[];
}

@autoinject()
export class AssignedFlowsDialog {

  public dialogHeader: string = 'Assigned Flows';
  public assignedFlows: IAssignedFlows[];

  constructor(
    private dialogController: DialogController
  ) {}

  public activate(model: any): void {
    this.assignedFlows = model || [];
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public done(): void {
    this.dialogController.ok();
  }

}
