import { inject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
/*
 */
import { SessionStore } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { ServicesService } from '../services-service';
import { AddServiceDialog } from '../addservice/add-service-dialog';
import { DisplayServiceModel } from '../service-model';
import { AddTeamDialog } from "../../../member/teams/addteam/add-team-dialog";
import { DisplaySitesModel } from "../../sites/site-model";
/*
 */
let logger = LogManager.getLogger('ServicesListView');

/*
 */
@inject(Element, DialogService, SessionStore, ServicesService, EventAggregator)
export class Service extends AbstractList {

  constructor(private element: Element, private dialogService: DialogService, private sessionStore: SessionStore, private servicesService: ServicesService, protected eventAggregator: EventAggregator) {
    super(eventAggregator);
  }

  protected activate(params?: { add: string }): void {
    super.activate(params);
  }

  protected attached(): void {
    super.attached();
  }

  protected subscribeToOplog(): void {
    this.servicesService.initialiseServicesOplog().then(oplog => {
      this.oplog = oplog;
      this.oplog.on('update', response => {
        if (response) {
          this.setupListConfig(this.sanitiseData(response.services));
        }
      });
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.servicesService.retrieveOrganisationServices().then(
      services => {
        this.setupListConfig(this.sanitiseData(services));
        if (this.isAdding) {
          this.clearUrlParams();
          this.showAddItem();
        }
      },
      error => {
        logger.info('Failed to retrieve your organisation services > error = ', error);
        this.hideLoader();
      }
    );
  }

  protected setupListConfig(services: DisplayServiceModel[]): void {
    super.setupListConfig(services);
    this.itemList = List.Builder() //
      .required(true) //
      .customEventElement(this.element)
      .enableAdd()
      .enableDelete()
      .enableClick()
      .selectionType(List.MULTIPLE_SELECTION_TYPE) //
      .selectedItems(services.filter(_service => {
        return _service.selected
      }))
      .autoSelect(false)
      .items(services) //
      .icon('icon') //
      .uniqueIdentifier('id') //
      .displayId('name') //
      .withProportion('medium')
      .description('')
      .hasRollover(false)
      .tilesPerRow(6)
      .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  protected sanitiseData(_data: any[]): any[] {
    return _data.map(_service => {
      return new DisplayServiceModel(_service)
    });
  }


  protected selectService(_items: any): void {
    this.eventAggregator.publish('app:loader:show');
    if (_items.item.selected) {
      if (_items.item.memberCount === 0 && _items.item.subscriptionCount === 0 && _items.item.invitationCount === 0) {
        this._deselect(_items.item);
      } else {
        this.eventAggregator.publish('app:loader:hide', {
          message: `Service <b>${_items.item.serviceName}</b> is assigned to a member or subscription.`,
          toastrType: 'warning'
        });
      }
    } else {
      this._select(_items.item)
    }
  }

  _select(service: DisplayServiceModel): void {
    this.servicesService.subscribeToValidationError().then(() => {
      service.selected = false;
      service.showLoader = false;
    });
    this.servicesService.selectService(this.sessionStore.get.organisation.organisationId, service).then((_response) => {
      this.deferredMessage = `Service <b>${service.name}</b> is now active.`;
    });
  }

  _deselect(service: DisplayServiceModel): void {
    this.servicesService.subscribeToValidationError().then(() => {
      service.selected = true;
      service.showLoader = false;
    });
    this.servicesService.deselectService(this.sessionStore.get.organisation.organisationId, service).then((_response) => {
      this.deferredMessage = `Service <b>${service.name}</b> is now inactive.`;
    });
  }

  protected showAddItem(): void {
    super.showAddItem();
    this.dialogService.open({ viewModel: AddServiceDialog, model: this.itemList.items }).whenClosed(_response => {
      if (!_response.wasCancelled) {
        this.deferredMessage = `Service <b>${_response['output']}</b> added.`;
        this.servicesService.addService(this.sessionStore.get.organisation.organisationId, _response['output']).then(_servicePayload => {
          this.itemList.items.push(new DisplayServiceModel({
            id: _servicePayload.id,
            name: _servicePayload.name
          }));
        });
      } else {
        this.hideLoader();
      }
    });
  }

  protected deleteItems(items: DisplayServiceModel[]): void {
    this.deferredMessage = items.length > 1 ? `${items.length} services deleted.` : `Service <b>${items[0].serviceName}</b> deleted`;
    super.deleteItems(items);
  }

  protected delete(item: any): void {
    this.servicesService.removeService(this.sessionStore.get.organisation.organisationId, item).then(_serviceId => {
      this.removeServiceFromList(_serviceId);
    });
  }

  private removeServiceFromList(_id: string): void {
    this.itemList.items = this.itemList.items.filter(_item => {
      return _item.id !== _id
    });
  }

  protected deactivate(): void {
    super.deactivate();
    this.servicesService.unsubscribeFromValidationError();
  }

  protected detached(): void {
    super.detached();
  }
}
