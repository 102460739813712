import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, computedFrom, inject, LogManager } from 'aurelia-framework';

import { MESSAGE_EVENTS, SessionStore, FeatureFlagService } from 'zailab.common';
import { WaitingRoomConfigService } from '../waiting-room-configuration-service';
import { OrganisationSessionModel } from '../../../../../_common/stores/sessionmodels/organisation-session-model';
import { PlaceholderService } from '../../../../../_common/services/placeholder-service';
import { EditAgentSampling } from '../edit-agent-sampling/edit-agent-sampling';

const logger = LogManager.getLogger('WaitingRoomConfigurationList');


@autoinject()
export class WaitingRoomConfigurationList {
  public header: string = 'Waiting Room Configuration';
  public ready: boolean = false;
  public placeholders: number = 0;
  public container: HTMLElement;
  public placeholderService: PlaceholderService;

  private workTypeConfigs: any[] = [{
    name: 'Agent Sampling',
    actions: [{
      action: 'view',
      label: 'Edit'
    }]
  }];

  private routingGroupConfig = {
    name: 'Routing Groups',
    actions: [{
      action: 'view',
      label: 'Edit'
    }]
  };

  constructor(
    private dialogService: DialogService,
    private sessionStore: SessionStore,
    private featureFlagService: FeatureFlagService,
    protected eventAggregator: EventAggregator,
    private router: Router
  ) {}

  public activate(): void {
    this.featureFlagService
      .isEnabled('RoutingGroups')
      .then(routingGroupsEnabled => {
        if (routingGroupsEnabled) {
          this.workTypeConfigs.push(this.routingGroupConfig);
        }
      })
  }

  public attached(): void {
    this.generatePlaceholders();
    this.setReady();
  }

  private generatePlaceholders(): void {
    if (!this.container) {
      return;
    }
    this.placeholders = 0;
    this.placeholderService = new PlaceholderService(this.container, this.workTypeConfigs.length, 5, (placeholders) => {
      this.placeholders = placeholders;
    });
  }
  
  public view(item: any): void {
    if (item.name === 'Agent Sampling') {
      this.editAgentSampling();
    } else if (item.name === 'Routing Groups') {
      this.navigateToRoutingGroups();
    }
  }

  private editAgentSampling(): void {
    this.dialogService
      .open({ viewModel: EditAgentSampling, model: {}});
  }

  public navigateToRoutingGroups(): void {
    this.router.navigate('routing-groups');
  }

  private setReady(): void {
    this.ready = true;
  }
  

  @computedFrom('organisation')
  private get organisationId(): string {
    if(!this.organisation) {
      return null;
    }
    return this.organisation.organisationId;
  }

  @computedFrom('sessionStore.get.organisation')
  private get organisation(): OrganisationSessionModel {
    return this.sessionStore.get.organisation;
  }
}
