export class ZaiForm {
  public static VALIDATION_TYPES = {
    REQUIRED: 'required',
    EMAIL: 'email',
    NUMBER: 'numbers-only',
    TEXT_WHITESPACE_HYPHEN: 'text-whitespace-hyphen',
    TEXT_WHITESPACE_HYPHEN_ALLOW_CUSTOM_VAR:
      'text-whitespace-hyphen-custom-var',
    COMMON_CHARACTERS:
      'text-whitespace-hyphen-fullstop-comma-question-exclamation',
    MIN_VALUE: 'min-value',
    MAX_VALUE: 'max-value',
    CUSTOM_SMALLER_THAN_SIBLING: 'custom-smaller-than-sibling',
    UNIQUE_NAME: 'unique-name',
    MAX_CHARACTER_LENGTH: 'max-character-length',
  };
  public static FIELD_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    DROPDOWN: 'basic-dropdown',
    SLIDER_TOGGLE: 'slider-toggle',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio',
  };

  public data: any = [];

  public newRow(): ZaiForm {
    this.data.push([]);
    return this;
  }

  public newField(): IFieldTypes {
    return new FieldConfig(this).init();
  }

  public finaliseForm(callback: (data: any[][]) => void): void {
    callback(this.data);
  }
}

class FieldConfig {
  constructor(private form: ZaiForm) {}

  public init(): IFieldTypes {
    let formField: { [key: string]: any } = {};

    const insertField = () => {
      if (!formField.type) {
        throw new Error('No type specified for form field: ' + formField.title);
      }

      let row = this.form.data[this.form.data.length - 1];
      if (row) {
        row.push(formField);
      } else {
        this.form.data.push([formField]);
      }
      return this.form;
    };

    const formTypesChain = {
      asHeader: () => {
        formField.type = 'header';
        return formHeaderChain;
      },
      asLabel: () => {
        formField.type = 'label';
        return formLabelChain;
      },
      asStaticText: () => {
        formField.type = 'static-text';
        return formStaticTextChain;
      },
      asHorizontalLine: () => {
        formField.type = 'horizontalRule';
        return formHRChain;
      },
      asCheckbox: () => {
        formField.type = 'checkbox';
        return formCheckboxChain;
      },
      asTextInput: (textType?: string) => {
        formField.type = textType || 'text';
        return formTextInputChain;
      },
      asTextArea: (textType?: string) => {
        formField.type = textType || 'textarea';
        return formTextInputChain;
      },
      asNumberInput: () => {
        formField.type = 'number';
        return formNumberInputChain;
      },
      asTimeInput: () => {
        formField.type = 'time';
        return formTimeInputChain;
      },
      asDateInput: () => {
        formField.type = 'date';
        return formDateInputChain;
      },
      asDropdown: () => {
        formField.type = 'basic-dropdown';
        return formDropdownChain;
      },
      asSearchDropdown: () => {
        formField.type = 'search-dropdown';
        return formSearchDropdownChain;
      },
      asSliderToggle: () => {
        formField.type = 'slider-toggle';
        return formSliderToggleChain;
      },
      asList: () => {
        formField.type = 'list';
        return formListChain;
      },
      asMultiSelector: () => {
        formField.type = 'multi-selector';
        return formMultiSelectorChain;
      },
    };

    const formHRChain: IHRBuilder = {
      fullWidth: () => {
        formField.fullWidth = true;
        return formHRChain;
      },
      insertField,
    };

    const formHeaderChain: IHeaderBuilder = {
      withTitle: (title: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        return formHeaderChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formHeaderChain;
      },
      insertField,
    };

    const formLabelChain: ILabelBuilder = {
      withTitle: (title: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No label specified.');
        }
        formField.title = title;
        return formLabelChain;
      },
      withRequiredIndicator: () => {
        formField.required = true;
        return formLabelChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formLabelChain;
      },
      insertField,
    };
    const formStaticTextChain: IStaticTextBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formStaticTextChain;
      },
      withTitle: (title: string, titleWidth?: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formStaticTextChain;
      },
      withValue: (value: string) => {
        formField.value = value;
        return formStaticTextChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formStaticTextChain;
      },
      insertField,
    };

    const formCheckboxChain: ICheckboxFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formCheckboxChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formCheckboxChain;
      },
      checkboxOnTheRight: () => {
        formField.checkboxOnTheRight = true;
        return formCheckboxChain;
      },
      withFieldWidth: (fieldWidth: string) => {
        formField.fieldWidth = fieldWidth;
        return formCheckboxChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formCheckboxChain;
      },
      withValue: (value: boolean) => {
        formField.value = value;
        return formCheckboxChain;
      },
      insertField,
    };

    const formTextInputChain: ITextInputFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formTextInputChain;
      },
      withFocus: () => {
        formField.focus = true;
        return formTextInputChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formTextInputChain;
      },
      withFieldWidth: (fieldWidth: string) => {
        formField.fieldWidth = fieldWidth;
        return formTextInputChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formTextInputChain;
      },
      enableSecure: () => {
        formField.secure = true;
        return formTextInputChain;
      },
      withPlaceholder: (placeholder: string) => {
        if (!placeholder || placeholder.length === 0) {
          console.warn('FormBuilder | No placeholder specified.');
        }
        formField.placeholder = placeholder;
        return formTextInputChain;
      },
      withValidation: (
        validations: {
          validationType: string | RegExp;
          validationMessage?: string;
          value?: string | number;
        }[]
      ) => {
        if (!validations) {
          return;
        }
        validations.forEach((validation) => {
          if (!validation) {
            console.warn('FormBuilder | No validation specified.');
          }
          const regexp = typeof validation.validationType !== 'string';

          if (!formField.validations) {
            formField.validations = {
              regular: [],
              regex: [],
            };
          }

          if (regexp) {
            formField.validations.regex.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
            });
          } else {
            formField.validations.regular.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
            });
          }
        });
        return formTextInputChain;
      },
      withValue: (value: string | number) => {
        formField.value = value;
        return formTextInputChain;
      },
      insertField,
    };

    const formNumberInputChain: INumberInputFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formNumberInputChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formNumberInputChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formNumberInputChain;
      },
      withPlaceholder: (placeholder: string) => {
        if (!placeholder || placeholder.length === 0) {
          console.warn('FormBuilder | No placeholder specified.');
        }
        formField.placeholder = placeholder;
        return formNumberInputChain;
      },
      withValidation: (
        validations: {
          validationType: string | RegExp;
          validationMessage?: string;
          value: string | number;
          field?: string;
        }[]
      ) => {
        if (!validations) {
          return;
        }
        validations.forEach((validation) => {
          if (!validation) {
            console.warn('FormBuilder | No validation specified.');
          }
          const regexp = typeof validation.validationType !== 'string';

          if (!formField.validations) {
            formField.validations = {
              regular: [],
              regex: [],
            };
          }

          if (regexp) {
            formField.validations.regex.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          } else {
            formField.validations.regular.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          }
        });
        return formNumberInputChain;
      },
      withMinValue: (minValue: number) => {
        formNumberInputChain.withValidation([
          {
            validationType: ZaiForm.VALIDATION_TYPES.MIN_VALUE,
            value: minValue,
          },
        ]);
        formField.minValue = minValue;
        return formNumberInputChain;
      },
      withMaxValue: (maxValue: number) => {
        formNumberInputChain.withValidation([
          {
            validationType: ZaiForm.VALIDATION_TYPES.MAX_VALUE,
            value: maxValue,
          },
        ]);
        formField.maxValue = maxValue;
        return formNumberInputChain;
      },
      withValue: (value: string | number) => {
        formField.value = value;
        return formNumberInputChain;
      },
      insertField,
    };

    const formTimeInputChain: ITimeInputFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formTimeInputChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formTimeInputChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formTimeInputChain;
      },
      withValidation: (
        validations: {
          validationType: string | RegExp;
          validationMessage?: string;
          value: string | number;
          field?: string;
        }[]
      ) => {
        if (!validations) {
          return;
        }
        validations.forEach((validation) => {
          if (!validation) {
            console.warn('FormBuilder | No validation specified.');
          }
          const regexp = typeof validation.validationType !== 'string';

          if (!formField.validations) {
            formField.validations = {
              regular: [],
              regex: [],
            };
          }

          if (regexp) {
            formField.validations.regex.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          } else {
            formField.validations.regular.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          }
        });
        return formTimeInputChain;
      },
      openToTop: () => {
        formField.direction = 'top';
        return formTimeInputChain;
      },
      withMinValue: (minValue: number) => {
        formTimeInputChain.withValidation([
          {
            validationType: ZaiForm.VALIDATION_TYPES.MIN_VALUE,
            value: minValue,
          },
        ]);
        formField.minValue = minValue;
        return formTimeInputChain;
      },
      withMaxValue: (maxValue: number) => {
        formTimeInputChain.withValidation([
          {
            validationType: ZaiForm.VALIDATION_TYPES.MAX_VALUE,
            value: maxValue,
          },
        ]);
        formField.maxValue = maxValue;
        return formTimeInputChain;
      },
      withValue: (value: string | number) => {
        formField.value = value;
        return formTimeInputChain;
      },
      withInputWidth: (inputWidth: string) => {
        formField.inputWidth = inputWidth;
        return formTimeInputChain;
      },
      insertField,
    };

    const formDateInputChain: IDateInputFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formDateInputChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formDateInputChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formDateInputChain;
      },
      withValidation: (
        validations: {
          validationType: string | RegExp;
          validationMessage?: string;
          value: string | number;
          field?: string;
        }[]
      ) => {
        if (!validations) {
          return;
        }
        validations.forEach((validation) => {
          if (!validation) {
            console.warn('FormBuilder | No validation specified.');
          }
          const regexp = typeof validation.validationType !== 'string';

          if (!formField.validations) {
            formField.validations = {
              regular: [],
              regex: [],
            };
          }

          if (regexp) {
            formField.validations.regex.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          } else {
            formField.validations.regular.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          }
        });
        return formDateInputChain;
      },
      withMinValue: (minValue: string) => {
        formDateInputChain.withValidation([
          {
            validationType: ZaiForm.VALIDATION_TYPES.MIN_VALUE,
            value: minValue,
          },
        ]);
        formField.minValue = minValue;
        return formDateInputChain;
      },
      withMaxValue: (maxValue: string) => {
        formDateInputChain.withValidation([
          {
            validationType: ZaiForm.VALIDATION_TYPES.MAX_VALUE,
            value: maxValue,
          },
        ]);
        formField.maxValue = maxValue;
        return formDateInputChain;
      },
      withValue: (value: string | number) => {
        formField.value = value;
        return formDateInputChain;
      },
      withInputWidth: (inputWidth: string) => {
        formField.inputWidth = inputWidth;
        return formDateInputChain;
      },
      insertField,
    };

    const formDropdownChain: IDropdownFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formDropdownChain;
      },
      withItemsIdentifier: (id: string) => {
        formField.itemsId = id;
        return formDropdownChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formDropdownChain;
      },
      withClass: (className: string) => {
        formField.className = className;
        return formDropdownChain;
      },
      withFieldWidth: (fieldWidth: string) => {
        formField.fieldWidth = fieldWidth;
        return formDropdownChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formDropdownChain;
      },
      withInputWidth: (inputWidth: string) => {
        formField.inputWidth = inputWidth;
        return formDropdownChain;
      },
      withPlaceholder: (placeholder: string) => {
        if (!placeholder || placeholder.length === 0) {
          console.warn('FormBuilder | No placeholder specified.');
        }
        formField.placeholder = placeholder;
        return formDropdownChain;
      },
      withValidation: (
        validations: {
          validationType: string | RegExp;
          validationMessage?: string;
          value: string | number;
          field?: string;
        }[]
      ) => {
        if (!validations) {
          return;
        }
        validations.forEach((validation) => {
          if (!validation) {
            console.warn('FormBuilder | No validation specified.');
          }
          const regexp = typeof validation.validationType !== 'string';

          if (!formField.validations) {
            formField.validations = {
              regular: [],
              regex: [],
            };
          }

          if (regexp) {
            formField.validations.regex.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          } else {
            formField.validations.regular.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          }
        });
        return formDropdownChain;
      },
      withValue: (value: string | number) => {
        formField.value = value;
        return formDropdownChain;
      },
      isDisabledWhen: (disabled: boolean) => {
        formField.disabled = disabled;
        return formDropdownChain;
      },
      withOptions: (options: any[]) => {
        formField.originalOptions = options;
        formField.options = options;
        return formDropdownChain;
      },
      withDisplayField: (displayField?: string) => {
        formField.displayField = displayField;
        return formDropdownChain;
      },
      openToTop: () => {
        formField.direction = 'top';
        return formDropdownChain;
      },
      multiSelect: () => {
        formField.multiSelect = true;
        return formDropdownChain;
      },
      withValues: (values: string[]) => {
        formField.values = values;
        return formDropdownChain;
      },
      insertField,
    };

    const formSearchDropdownChain: ISearchDropdownFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formSearchDropdownChain;
      },
      withItemsIdentifier: (id: string) => {
        formField.itemsId = id;
        return formSearchDropdownChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formSearchDropdownChain;
      },
      withClass: (className: string) => {
        formField.className = className;
        return formSearchDropdownChain;
      },
      withFieldWidth: (fieldWidth: string) => {
        formField.fieldWidth = fieldWidth;
        return formSearchDropdownChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formSearchDropdownChain;
      },
      withInputWidth: (inputWidth: string) => {
        formField.inputWidth = inputWidth;
        return formSearchDropdownChain;
      },
      withPlaceholder: (placeholder: string) => {
        if (!placeholder || placeholder.length === 0) {
          console.warn('FormBuilder | No placeholder specified.');
        }
        formField.placeholder = placeholder;
        return formSearchDropdownChain;
      },
      withValidation: (
        validations: {
          validationType: string | RegExp;
          validationMessage?: string;
          value: string | number;
          field?: string;
        }[]
      ) => {
        if (!validations) {
          return;
        }
        validations.forEach((validation) => {
          if (!validation) {
            console.warn('FormBuilder | No validation specified.');
          }
          const regexp = typeof validation.validationType !== 'string';

          if (!formField.validations) {
            formField.validations = {
              regular: [],
              regex: [],
            };
          }

          if (regexp) {
            formField.validations.regex.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          } else {
            formField.validations.regular.push({
              validation: validation.validationType,
              validationMessage: validation.validationMessage,
              value: validation.value,
              field: validation.field,
            });
          }
        });
        return formSearchDropdownChain;
      },
      withValue: (value: string | number) => {
        formField.value = value;
        return formSearchDropdownChain;
      },
      isDisabledWhen: (disabled: boolean) => {
        formField.disabled = disabled;
        return formSearchDropdownChain;
      },
      withOptions: (options: any[]) => {
        formField.options = options;
        return formSearchDropdownChain;
      },
      withDisplayField: (displayField?: string) => {
        formField.displayField = displayField;
        return formSearchDropdownChain;
      },
      openToTop: () => {
        formField.direction = 'top';
        return formSearchDropdownChain;
      },
      multiSelect: (iconsEnabled?: boolean) => {
        formField.multiSelect = true;
        formField.iconsEnabled = !!iconsEnabled;
        return formSearchDropdownChain;
      },
      withValues: (values: string[]) => {
        formField.values = values;
        return formSearchDropdownChain;
      },
      enableShowMore: (apiCall: () => Promise<any[]>, pageSize: number) => {
        formField.showMore = true;
        formField.showMoreApiCall = apiCall;
        formField.pageSize = pageSize;
        return formSearchDropdownChain;
      },
      insertField,
    };

    const formSliderToggleChain: ISliderSoggleBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formSliderToggleChain;
      },
      addPositiveOption: (title: string) => {
        formField.options = formField.options || {
          positive: null,
          negative: null,
        };
        formField.options.positive = title;
        return formSliderToggleChain;
      },
      addNegativeOption: (title: string) => {
        formField.options = formField.options || {
          positive: null,
          negative: null,
        };
        formField.options.negative = title;
        return formSliderToggleChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formSliderToggleChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formSliderToggleChain;
      },
      withValue: (value: boolean) => {
        formField.value = value;
        return formSliderToggleChain;
      },
      insertField,
    };

    const formListChain: IListBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formListChain;
      },
      withTitle: (title?) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        return formListChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formListChain;
      },
      withValues: (values: any[]) => {
        formField.values = values;
        return formListChain;
      },
      insertField,
    };

    const formMultiSelectorChain: IMultiSelectorFieldBuilder = {
      withIdentifier: (id: string) => {
        formField.id = id;
        return formMultiSelectorChain;
      },
      withItemsIdentifier: (id: string) => {
        formField.itemsId = id;
        return formMultiSelectorChain;
      },
      withTitle: (title: string, titleWidth: string) => {
        if (!title || title.length === 0) {
          console.warn('FormBuilder | No title specified.');
        }
        formField.title = title;
        formField.titleWidth = titleWidth;
        return formMultiSelectorChain;
      },
      fullWidth: () => {
        formField.fullWidth = true;
        return formMultiSelectorChain;
      },
      withItemWidth: (width: string) => {
        formField.itemWidth = width;
        return formMultiSelectorChain;
      },
      withValues: (values: { display: string; selected?: boolean }[]) => {
        formField.values = values;
        return formMultiSelectorChain;
      },
      insertField,
    };
    return formTypesChain;
  }
}

interface IFieldTypes {
  asHeader: () => IHeaderBuilder;
  asLabel: () => ILabelBuilder;
  asHorizontalLine: () => IHRBuilder;
  asCheckbox: () => ICheckboxFieldBuilder;
  asTextInput: (textType?: string) => ITextInputFieldBuilder;
  asTextArea: (textType?: string) => ITextInputFieldBuilder;
  asNumberInput: () => INumberInputFieldBuilder;
  asTimeInput: () => ITimeInputFieldBuilder;
  asDateInput: () => IDateInputFieldBuilder;
  asDropdown: () => IDropdownFieldBuilder;
  asSearchDropdown: () => ISearchDropdownFieldBuilder;
  asSliderToggle: () => ISliderSoggleBuilder;
  asList: () => IListBuilder;
  asMultiSelector: () => IMultiSelectorFieldBuilder;
}

interface IHeaderBuilder {
  withTitle: (title: string) => IHeaderBuilder;
  fullWidth: () => IHeaderBuilder;
  insertField: () => ZaiForm;
}

interface ILabelBuilder {
  withTitle: (title: string) => ILabelBuilder;
  withRequiredIndicator: () => ILabelBuilder;
  fullWidth: () => ILabelBuilder;
  insertField: () => ZaiForm;
}

interface IStaticTextBuilder {
  withIdentifier: (id: string) => IStaticTextBuilder;
  withTitle: (title: string, titleWidth?: string) => IStaticTextBuilder;
  withValue: (value: string) => IStaticTextBuilder;
  fullWidth: () => IStaticTextBuilder;
  insertField: () => ZaiForm;
}

interface IHRBuilder {
  fullWidth: () => IHRBuilder;
  insertField: () => ZaiForm;
}

interface ICheckboxFieldBuilder {
  withIdentifier: (id: string) => ICheckboxFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => ICheckboxFieldBuilder;
  checkboxOnTheRight: () => ICheckboxFieldBuilder;
  withFieldWidth: (fieldWidth: string) => ICheckboxFieldBuilder;
  fullWidth: () => ICheckboxFieldBuilder;
  withValue: (value: boolean) => ICheckboxFieldBuilder;
  insertField: () => ZaiForm;
}

interface ITextInputFieldBuilder {
  withIdentifier: (id: string) => ITextInputFieldBuilder;
  withFocus: () => ITextInputFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => ITextInputFieldBuilder;
  withFieldWidth: (fieldWidth: string) => ITextInputFieldBuilder;
  fullWidth: () => ITextInputFieldBuilder;
  enableSecure: () => ITextInputFieldBuilder;
  withPlaceholder: (placeholder: string) => ITextInputFieldBuilder;
  withValidation: (
    validations: {
      validationType: string | RegExp;
      validationMessage?: string;
      value?: string | number | string[];
    }[]
  ) => ITextInputFieldBuilder;
  withValue: (value: string | number) => ITextInputFieldBuilder;
  insertField: () => ZaiForm;
}

interface INumberInputFieldBuilder {
  withIdentifier: (id: string) => INumberInputFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => INumberInputFieldBuilder;
  fullWidth: () => INumberInputFieldBuilder;
  withPlaceholder: (placeholder: string) => INumberInputFieldBuilder;
  withValidation: (
    validations: {
      validationType: string | RegExp;
      validationMessage?: string;
      value?: string | number;
      field?: string;
    }[]
  ) => INumberInputFieldBuilder;
  withMinValue: (minValue: number) => INumberInputFieldBuilder;
  withMaxValue: (maxValue: number) => INumberInputFieldBuilder;
  withValue: (value: string | number) => INumberInputFieldBuilder;
  insertField: () => ZaiForm;
}

interface ITimeInputFieldBuilder {
  withIdentifier: (id: string) => ITimeInputFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => ITimeInputFieldBuilder;
  fullWidth: () => ITimeInputFieldBuilder;
  withValidation: (
    validations: {
      validationType: string | RegExp;
      validationMessage?: string;
      value?: string | number;
      field?: string;
    }[]
  ) => ITimeInputFieldBuilder;
  withMinValue: (minValue: number) => ITimeInputFieldBuilder;
  withMaxValue: (maxValue: number) => ITimeInputFieldBuilder;
  withValue: (value: string | number) => ITimeInputFieldBuilder;
  withInputWidth: (inputWidth: string) => ITimeInputFieldBuilder;
  openToTop: () => ITimeInputFieldBuilder,
  insertField: () => ZaiForm;
}
interface IDateInputFieldBuilder {
  withIdentifier: (id: string) => IDateInputFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => IDateInputFieldBuilder;
  fullWidth: () => IDateInputFieldBuilder;
  withValidation: (
    validations: {
      validationType: string | RegExp;
      validationMessage?: string;
      value?: string | number;
      field?: string;
    }[]
  ) => IDateInputFieldBuilder;
  withMinValue: (minValue: string) => IDateInputFieldBuilder;
  withMaxValue: (maxValue: string) => IDateInputFieldBuilder;
  withValue: (value: string | number) => IDateInputFieldBuilder;
  withInputWidth: (inputWidth: string) => IDateInputFieldBuilder;
  insertField: () => ZaiForm;
}

interface IDropdownFieldBuilder {
  withIdentifier: (id: string) => IDropdownFieldBuilder;
  withItemsIdentifier: (id: string) => IDropdownFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => IDropdownFieldBuilder;
  withFieldWidth: (fieldWidth: string) => IDropdownFieldBuilder;
  withClass: (className: string) => IDropdownFieldBuilder;
  fullWidth: () => IDropdownFieldBuilder;
  withInputWidth: (inputWidth: string) => IDropdownFieldBuilder;
  withPlaceholder: (placeholder: string) => IDropdownFieldBuilder;
  withValidation: (
    validations: {
      validationType: string | RegExp;
      validationMessage?: string;
      value?: string | number;
      field?: string;
    }[]
  ) => IDropdownFieldBuilder;
  withValue: (value: string | number) => IDropdownFieldBuilder;
  isDisabledWhen: (disabled: boolean) => IDropdownFieldBuilder;
  withOptions: (options: any[]) => IDropdownFieldBuilder;
  withDisplayField: (displayField?: string) => IDropdownFieldBuilder;
  multiSelect: () => IDropdownFieldBuilder;
  withValues: (values: string[]) => IDropdownFieldBuilder;
  openToTop: () => IDropdownFieldBuilder;
  insertField: () => ZaiForm;
}

interface ISearchDropdownFieldBuilder {
  withIdentifier: (id: string) => ISearchDropdownFieldBuilder;
  withItemsIdentifier: (id: string) => ISearchDropdownFieldBuilder;
  withTitle: (
    title: string,
    titleWidth?: string
  ) => ISearchDropdownFieldBuilder;
  withFieldWidth: (fieldWidth: string) => ISearchDropdownFieldBuilder;
  withClass: (className: string) => ISearchDropdownFieldBuilder;
  fullWidth: () => ISearchDropdownFieldBuilder;
  withInputWidth: (inputWidth: string) => ISearchDropdownFieldBuilder;
  withPlaceholder: (placeholder: string) => ISearchDropdownFieldBuilder;
  withValidation: (
    validations: {
      validationType: string | RegExp;
      validationMessage?: string;
      value?: string | number;
      field?: string;
    }[]
  ) => ISearchDropdownFieldBuilder;
  withValue: (value: string | number) => ISearchDropdownFieldBuilder;
  isDisabledWhen: (disabled: boolean) => ISearchDropdownFieldBuilder;
  withOptions: (options: any[]) => ISearchDropdownFieldBuilder;
  withDisplayField: (displayField?: string) => ISearchDropdownFieldBuilder;
  multiSelect: (iconsEnabled?: boolean) => ISearchDropdownFieldBuilder;
  withValues: (values: string[] | { value: string, label: string, icon?: string }[]) => ISearchDropdownFieldBuilder;
  enableShowMore: (
    apiCall: () => Promise<any[]>,
    pageSize: number
  ) => ISearchDropdownFieldBuilder;
  openToTop: () => ISearchDropdownFieldBuilder;
  insertField: () => ZaiForm;
}

interface ISliderSoggleBuilder {
  withIdentifier: (id: string) => ISliderSoggleBuilder;
  withTitle: (title: string, titleWidth?: string) => ISliderSoggleBuilder;
  addPositiveOption: (title: string) => ISliderSoggleBuilder;
  addNegativeOption: (title: string) => ISliderSoggleBuilder;
  withValue: (value: boolean) => ISliderSoggleBuilder;
  fullWidth: () => ISliderSoggleBuilder;
  insertField: () => ZaiForm;
}

interface IListBuilder {
  withIdentifier: (id: string) => IListBuilder;
  withTitle: (title: string) => IListBuilder;
  withValues: (values: any[]) => IListBuilder;
  fullWidth: () => IListBuilder;
  insertField: () => ZaiForm;
}

interface IMultiSelectorFieldBuilder {
  withIdentifier: (id: string) => IMultiSelectorFieldBuilder;
  withItemsIdentifier: (id: string) => IMultiSelectorFieldBuilder;
  withTitle: (title: string, titleWidth?: string) => IMultiSelectorFieldBuilder;
  withValues: (
    values: { display: string; selected?: boolean }[]
  ) => IMultiSelectorFieldBuilder;
  fullWidth: () => IMultiSelectorFieldBuilder;
  withItemWidth: (width: string) => IMultiSelectorFieldBuilder;
  insertField: () => ZaiForm;
}
