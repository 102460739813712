import { LogManager, bindable, autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
/* */
import { CUSTOM_EVENTS } from 'zailab.common';
import { v4 as uuidv4 } from 'uuid';
import { languageList } from '../../../../../../_common/tools/language-tools';
import { AudioAddService } from '../../../../../../zailab.common';
import { EventAggregator } from 'aurelia-event-aggregator';
/**/
const logger = LogManager.getLogger('UploadAudioViewModel');

@autoinject
export class UploadAudio {

  public languageList = languageList;
  public audioLanguageList = [];
  public audioFileName: string = null;
  private audioFileLanguageId: string = null;
  private audioName: string = null;
  private audioFile: File = null;

  @bindable dragDropActive: boolean = false;
  @bindable fileErrors: Array<string> = [];

  constructor(private element: Element, private dialogController: DialogController, private audioAddService: AudioAddService, private events: EventAggregator) {

  }

  public attached(): void {
    this.invalidate();
  }

  public fileDragDropHandler = (_data: CustomEvent) => {
    this.audioFile = _data.detail as File;
  };

  public fileUploadHandler = (_data: CustomEvent) => {
    if (!_data || !_data.detail.file) {
      this.invalidate();
      return;
    }
    this.audioFile = _data.detail.file as File;
    // Check type and size
    this.validate(true);
  };

  private signalComplete(): void {
    this.fileErrors = [];
    this.element.dispatchEvent(
      new CustomEvent(CUSTOM_EVENTS.AUDIO_PAYLOAD_AVAILABLE, {
        bubbles: true,
        detail: this.createLanguagePayload()
      })
    );
  }

  private invalidate(): void {
    this.element.dispatchEvent(
      new CustomEvent(CUSTOM_EVENTS.TAB_INCOMPLETE, {
        bubbles: true,
        detail: {}
      })
    );
  }

  private createLanguagePayload(): any {
    let payload = this.audioLanguageList.find((audioLang) => audioLang.defaultSelection);
    if (!payload) {
      this.audioLanguageList[0].defaultSelection = true;
      payload = this.audioLanguageList[0];
    }
    let otherAudio = this.audioLanguageList.filter((audioLang) => !audioLang.defaultSelection);
    payload.otherAudio = otherAudio;
    return payload;
  }

  private createPayload(): ZIUploadAudioTabPayload {
    const defaultSelection = !this.audioLanguageList || !this.audioLanguageList.length;
    return {
      audioName: this.audioName,
      uploadedFile: this.audioFile,
      uploadedFileName: this.audioFile.name,
      audioId: uuidv4(),
      audioLanguageId: this.audioFileLanguageId,
      defaultSelection,
      otherAudio: [],
      uploaded: false,
    }
  }

  private validateName(): Promise<boolean> {
    return this.getFileName()
      .then((name) => {
        this.audioName = name;
        this.fileErrors = [];
        if (!this.audioName) {
          this.invalidate();
          this.fileErrors.push("Please choose a name.");
          return false;
        }
        return true;
      });
  }

  private validate(fileOnly?: boolean): boolean {
    if (!this.audioFile || !this.audioFile.type || !this.audioFile.size) {
      this.invalidate();
      this.fileErrors.push("Please choose a file.");
      return false;
    }
    if (this.audioFile.type !== "audio/wav") {
      this.fileErrors.push("Please upload a WAV file.");
    } else if (this.audioFile.size > 10485760) {
      this.fileErrors.push("The file is too big. Max size is 10Mb.");
    } else if (!fileOnly && this.audioLanguageList.length > 0) {
      const audioList = this.audioLanguageList.filter((audioLang) => audioLang.audioLanguageId === this.audioFileLanguageId);
      if (audioList.length > 0) {
        this.fileErrors.push("The selected language already in use.");
      } else {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  private getFileName(): Promise<string> {
    return new Promise((resolve) => {
      const subscription = this.events.subscribe('add-audio:name-retrieved', (name: string) => {
        subscription.dispose();
        resolve(name);
      });
      this.events.publish('add-audio:retrieve-name');
    });
  }

  public addLanguageAudioFile(): void {
    this.validateName().then((isValid) => {
      if (isValid) {
        isValid = this.validate();
        if (isValid) {
          this.invalidate();
          const payload = this.createPayload();
          this.audioLanguageList.push(payload);
          this.audioFile = null;
          this.audioFileName = '';
          this.audioFileLanguageId = 'en';
          this.signalComplete();
        }
      }
    });
  }

  public removeLanguageAudioFile(lang: any): void {
    this.fileErrors = [];
    const index = this.audioLanguageList.indexOf(lang);
    if (index > -1) {
      this.audioLanguageList.splice(index, 1);
    }
    if (this.audioLanguageList.length > 0) {
      const defaultSelection = this.audioLanguageList.find((lang) => lang.defaultSelection);
      if (!defaultSelection) {
        this.audioLanguageList[0].defaultSelection = true;
      }
      this.signalComplete();
    } else {
      this.invalidate();
      this.events.publish('audio-upload-last-removed');
    }
  }

  public setLanguageAudioFileAsDefault(lang: any): boolean {
    this.fileErrors = [];
    for (const audioLanguage of this.audioLanguageList) {
      if (audioLanguage !== lang) {
        audioLanguage.defaultSelection = false;
      }
    }
    const index = this.audioLanguageList.indexOf(lang);
    if (index > -1) {
      this.audioLanguageList[index].defaultSelection = !this.audioLanguageList[index].defaultSelection;
    }
    let payload = this.audioLanguageList.find((audioLang) => audioLang.defaultSelection);
    if (!payload) {
      this.invalidate();
      this.fileErrors.push("Please set a default audio.");
    } else {
      this.signalComplete();
    }
    return true;
  }

  public getLanguageName(languageId: string): string {
    return languageList.find((lang) => lang.id === languageId).name;
  }
}
