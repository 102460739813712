import { ValidationController } from '~aurelia-validation/dist/amd/validation-controller';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from 'aurelia-framework';
import { validateTrigger, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';

import { v4 as uuidv4 } from 'uuid';

import { BootstrapFormRenderer, ArrayTools, SessionStore } from 'zailab.common';
import OrganisationService from '../organisation-service';
import { WorkforceManagement } from './verint-configuration-model';
import { WorkTypesService } from 'features/organisation/conversation/worktypes/work-types-service';

const logger = LogManager.getLogger('VerintConfigurationDialog');

interface IVerintWorkTypes {
  _id: string;
  tasktemplateName: string;
  outboundWorktypes: {
    taskTemplateId: string;
    taskTemplateName: string;
  }[]
}[]

@autoinject
export class VerintConfigurationDialog {

  public dialogHeader = "Verint WFM (Forecasting&Scheduling) configuration";
  public dialogHeaderWorktypes = "Verint WFM (Forecasting&Scheduling) worktype configuration";
  public workforceManagement = new WorkforceManagement();
  public verintSftpHost;
  public verintSftpPort;
  public verintSftpUsername;
  public verintSftpPassword;
  public verintSftpPublicKey;
  public verintSftpPrivateKey;
  public verintSftpPassphrase;
  public verintApiHost;
  public verintApiToken;
  public verintApiOrganisationId;
  public verintAbandonedOutcomes = [
    { name: 'Callback', checked: false },
    { name: 'Mailbox', checked: false },
    { name: 'Missed', checked: false },
  ];
  public verintPublicPrivateKeyEnabled;
  public loading = false;
  public verintForecastingRanges = [{name: "14 Days", value: "_14_DAYS"}, {name: "30 Days", value: "_30_DAYS"}];
  public verintForecastingSelectedRange;

  private validation: ValidationController;
  
  public editWorktypes: boolean = false;
  public verintIncludeDetailedHandleTime;
  private worktypeConfig;
  public verintInboundWorkTypes = [];
  public verintoutboundWorktypes = [];

  public inboundWorkTypes = [];
  public outboundWorktypes = [];

  constructor(
    private dialogController: DialogController,
    private organisationService: OrganisationService,
    private workTypesService: WorkTypesService,
    private sessionStore: SessionStore,
    validationControllerFactory: ValidationControllerFactory,
  ) {
    this.dialogController = dialogController;
    this.organisationService = organisationService;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public activate(data: WorkforceManagement): void {
    this.workforceManagement = data;
    this.verintSftpHost = data.verintSftpHost;
    this.verintSftpPort = data.verintSftpPort;
    this.verintSftpUsername = data.verintSftpUsername;
    this.verintSftpPassword = data.verintSftpPassword;
    this.verintSftpPublicKey = data.verintSftpPublicKey;
    this.verintSftpPrivateKey = data.verintSftpPrivateKey;
    this.verintSftpPassphrase = data.verintSftpPassphrase;
    this.verintApiHost = data.verintApiHost;
    this.verintApiToken = data.verintApiToken;
    this.verintApiOrganisationId = data.verintApiOrganisationId;
    if (data.verintAbandonedOutcomes && data.verintAbandonedOutcomes.length) {
      for (const outcome of data.verintAbandonedOutcomes) {
        const oc = this.verintAbandonedOutcomes.find((oc) => oc.name === outcome);
        if (!oc) {
          continue;
        }
        oc.checked = true;
      }
    }
    if (this.workforceManagement.verintForecastingRange) {
      this.verintForecastingRanges.forEach(range => {
        if (this.workforceManagement.verintForecastingRange === range.value) {
          this.verintForecastingSelectedRange = range;
        }
      })
    }
    this.verintPublicPrivateKeyEnabled = !!data.verintSftpPublicKey;
    
    this.verintIncludeDetailedHandleTime = data.verintIncludeDetailedHandleTime;
    this.retrieveWorkTypes();

    ValidationRules
      .ensure('verintSftpHost').required()
      .ensure('verintSftpPort').required().then().matches(/^([0-9])+$/).withMessage('Must be a number')
      .ensure('verintSftpUsername').required()
      .ensure('verintSftpPassword').required()
      .ensure('verintSftpPublicKey').required()
      .ensure('verintSftpPrivateKey').required()
      .ensure('verintInboundWorkType').required()
      .ensure('verintOutboundWorkType').required()
      .on(this);
  }

  public async retrieveWorkTypes(): Promise<void> {
    try {
      const workTypes = await this.workTypesService.retrieveWorkTypes();
      
      this.inboundWorkTypes = ArrayTools.sort(workTypes.filter(wt => wt.channel === 'Inbound Call'), 'templateName');
      this.outboundWorktypes = ArrayTools.sort(workTypes.filter(wt => wt.channel === 'Outbound Call'), 'templateName');
    } catch(e) {
      logger.warn(' > Failed to setup worktypes due to', e);
    }
  }

  public cancel(): void {
    this.dialogController.cancel();
  }

  public save(): void {
    this.loading = true;
    this.validation.validate().then((validation) => {
      if (!validation.valid) {
        this.loading = false;
        return;
      }
      if (!this.workforceManagement.workforceManagementId) {
        this.workforceManagement.workforceManagementId = uuidv4();
      }
      this.workforceManagement.verintSftpHost = this.verintSftpHost;
      this.workforceManagement.verintSftpPort = this.verintSftpPort;
      this.workforceManagement.verintSftpUsername = this.verintSftpUsername;
      this.workforceManagement.verintSftpPassword = this.verintSftpPassword;
      this.workforceManagement.verintSftpPublicKey = this.verintSftpPublicKey;
      this.workforceManagement.verintSftpPrivateKey = this.verintSftpPrivateKey;
      this.workforceManagement.verintSftpPassphrase = this.verintSftpPassphrase;
      this.workforceManagement.verintApiHost = this.verintApiHost;
      this.workforceManagement.verintApiToken = this.verintApiToken;
      this.workforceManagement.verintApiOrganisationId = this.verintApiOrganisationId;
      this.workforceManagement.verintAbandonedOutcomes = this.verintAbandonedOutcomes.filter((outcome) => outcome.checked).map((outcome) => outcome.name);
      this.workforceManagement.verintIncludeDetailedHandleTime = this.verintIncludeDetailedHandleTime;
      
      this.organisationService.setWorkforceManagementSFTPAdapter(this.sessionStore.get.organisation.organisationId, this.workforceManagement)
        .then(() => {
        this.loading = false;
          this.dialogController.ok(this.workforceManagement);
        });
    });
  }

  public toggleEnabled(): void {
    this.workforceManagement.verintEnabled = !this.workforceManagement.verintEnabled;
  }

  public toggleInboundCallStatsEnabled(): void {
    this.workforceManagement.verintInboundCallStatsEnabled = !this.workforceManagement.verintInboundCallStatsEnabled;
  }

  public toggleInboundChatStatsEnabled(): void {
    this.workforceManagement.verintInboundChatStatsEnabled = !this.workforceManagement.verintInboundChatStatsEnabled;
  }

  public toggleOutboundCallStatsEnabled(): void {
    this.workforceManagement.verintOutboundCallStatsEnabled = !this.workforceManagement.verintOutboundCallStatsEnabled;
  }

  public toggleTimeMonitoringEnabled(): void {
    this.workforceManagement.verintTimeMonitoringEnabled = !this.workforceManagement.verintTimeMonitoringEnabled;
  }

  public toggleAuthenticationMethod(): void {
    this.verintPublicPrivateKeyEnabled = !this.verintPublicPrivateKeyEnabled;
  }

  public toggleForecastingEnabled(): void {
    this.workforceManagement.verintForecastingEnabled = !this.workforceManagement.verintForecastingEnabled;
  }

  public selectForecastingRange(data: any): void {
    this.workforceManagement.verintForecastingRange = data.value;
    this.verintForecastingSelectedRange = data;
  }

  public async openConfigureWorktypes(): Promise<void> {
    this.editWorktypes = true;
    let config = await this.organisationService.getWorkforceManagementWorktypeConfig(this.workforceManagement.workforceManagementId);

    this.worktypeConfig = config?.worktypeConfiguration?.worktypes;
    if (this.worktypeConfig) {
      this.worktypeConfig.forEach(wt => {
        let item = this.inboundWorkTypes.find(iwt => iwt.templateId === wt.taskTemplateId);
        item.outboundWorktypes = wt.outboundWorktypes;
      });
    }
  }

  public cancelWorktyepeConfig(): void {
    this.editWorktypes = false;
    this.inboundWorkTypes.forEach(wt => {
      wt.outboundWorktypes = [];
    })
  }

  public selectOutboundWorktype(outboundWorkType: { templateId: string, templateName: string }, inboundWorkType: any): void {
    if (!inboundWorkType.outboundWorktypes) {
      inboundWorkType.outboundWorktypes = [];
    }

    inboundWorkType.outboundWorktypes.push({
			taskTemplateId: outboundWorkType.templateId,
			taskTemplateName: outboundWorkType.templateName
    });
  }

  public removeOutboundWorktype(outboundWorkTypeId: string, inboundWorkType: any): void {
    inboundWorkType.outboundWorktypes = inboundWorkType.outboundWorktypes.filter(wt => wt.taskTemplateId !== outboundWorkTypeId);
  }

  public setWorktypeConfig(): void {
    let filteredWorktypes: any = this.inboundWorkTypes
      .filter(wt => !!wt.outboundWorktypes)
      .map(wt => {
        return {
          taskTemplateId: wt.templateId,
          taskTemplateName: wt.templateName,
          outboundWorktypes: wt.outboundWorktypes
        }
      })

    this.organisationService
      .setWorkforceManagementWorktypeConfig(this.workforceManagement.workforceManagementId, {
        worktypes: filteredWorktypes
      })
      .then(() => this.cancelWorktyepeConfig());
  }
}

