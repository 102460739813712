import { autoinject, bindable, LogManager } from 'aurelia-framework';

import { ZaiForm } from 'zailab.common';

const logger = LogManager.getLogger('ConnectionSettingsTab');

interface IWorkforceManagement {
  verintRealtimeTimeMonitoringEnabled: boolean;
  verintRealtimeTimeMonitoringApiUrl: string;
  verintRealtimeTimeMonitoringApiTenantId: string;
  verintRealtimeTimeMonitoringApiClientId: string;
  verintRealtimeTimeMonitoringApiClientSecret: string;
  verintRealtimeTimeMonitoringApiScope: string;
}

@autoinject()
export class ConnectionSettingsTab {
  public model;
  public form: any[][];

  public activate(bindingContext: { data: IWorkforceManagement }): void {
    this.model = bindingContext;
    const workforceManagement: IWorkforceManagement = bindingContext.data;

    new ZaiForm()
      .newField()
      .asSliderToggle()
      .withIdentifier('verintRealtimeTimeMonitoringEnabled')
      .withTitle('Enabled', '200px')
      .addNegativeOption('Disabled')
      .addPositiveOption('Enabled')
      .withValue(workforceManagement.verintRealtimeTimeMonitoringEnabled)
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('verintRealtimeTimeMonitoringApiUrl')
      .withTitle('Api Url', '200px')
      .withValidation(
        [{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }]
      )
      .withValue(workforceManagement.verintRealtimeTimeMonitoringApiUrl)
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('verintRealtimeTimeMonitoringApiTenantId')
      .withTitle('Tenant ID', '200px')
      .withValidation(
        [{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }]
      )
      .withValue(workforceManagement.verintRealtimeTimeMonitoringApiTenantId)
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('verintRealtimeTimeMonitoringApiClientId')
      .withTitle('Client ID', '200px')
      .withValidation(
        [{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }]
      )
      .withValue(workforceManagement.verintRealtimeTimeMonitoringApiClientId)
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('verintRealtimeTimeMonitoringApiClientSecret')
      .withTitle('Client Secret', '200px')
      .enableSecure()
      .withValidation(
        [{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }]
      )
      .withValue(workforceManagement.verintRealtimeTimeMonitoringApiClientSecret)
      .insertField()
      
      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('verintRealtimeTimeMonitoringApiScope')
      .withTitle('Scope', '200px')
      .withValidation(
        [{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }]
      )
      .withValue(workforceManagement.verintRealtimeTimeMonitoringApiScope)
      .insertField()

      .finaliseForm(form => this.form = form);
  }
}

