import { autoinject, LogManager } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import * as impl from './agent-script-admin-config-impl.json';
import { AgentScriptExecution, AgentScriptExecutionStatus, mapAgentScriptExecution } from "./agent-script-execution-model";

const logger = LogManager.getLogger('AgentScriptExecutionService');

@autoinject
export class AgentScriptExecutionService {

  public impl = impl;

  private baseUrl = `${this.impl.baseUrl}executions`;

  constructor(
    private httpClient: HttpClient
  ) { }

  public async create(executionId: string, agentScriptId: string, interactionId: string): Promise<any> {
    return this.httpClient
      .createRequest(this.baseUrl)
      .asPost()
      .withContent({ executionId, agentScriptId, interactionId })
      .send();
  }

  public async retrieve(interactionId: string): Promise<AgentScriptExecution[]> {
    const status = AgentScriptExecutionStatus.IN_PROGRESS;
    return this.httpClient.createRequest(this.baseUrl)
      .withParams({ interactionId, status })
      .asGet()
      .send()
      .then((res: any) => res && res.length ? res.map((data) => mapAgentScriptExecution(data)) : []);
  }

  public async update(executionId: string, nodeId: string, selectedConnectionId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${executionId}/${nodeId}`)
      .asPut()
      .withContent({ selectedConnectionId })
      .send();
  }

  public async delete(executionId: string): Promise<any> {
    return this.httpClient
      .createRequest(`${this.baseUrl}/${executionId}`)
      .asDelete()
      .send();
  }
}