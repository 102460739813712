import { autoinject, LogManager } from 'aurelia-framework';
// @ts-ignore
import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { MESSAGE_EVENTS } from 'zailab.common';

import * as XLSX from 'xlsx';
import { mkConfig, generateCsv, download } from "export-to-csv";


const logger = LogManager.getLogger('ExportReportDialog');

@autoinject()
export class ExportReportDialog {
  
  public exportTypes: string[] = ['CSV', 'EXCEL'];
  public selectedType: string = 'CSV';

  private reportData: {[key: string]: any}[];
  private fileName: string;
  private headers: string[];
  private view: string;

  constructor(
    private dialogController: DialogController,
    private eventAggregator: EventAggregator
  ) {}

  public activate(data: { reportData, fileName: string, headers: string[], view: string }): void {
    this.reportData = data.reportData;
    this.fileName = data.fileName;
    this.headers = data.headers;
    this.view = data.view;
  }

  public export(): void {
    if (this.selectedType === 'CSV') {
      this.confirmCSVExport();
    } else if (this.selectedType === 'EXCEL') {
      this.confirmExcelExport();
    }
  }

  private async confirmCSVExport(): Promise<void> {
    const promises = this.reportData.map(async (item) => {
      if (this.view === 'teams' || this.view === 'business partners' || this.view === 'agents') {
        return {
          [this.headers[0]]: item.name,
          [this.headers[1]]: item.handledInteractions,
          [this.headers[2]]: item.interactionsAssessed,
          [this.headers[3]]: item.assessmentsPassed,
          [this.headers[4]]: item.assessmentsFailed
        };
      } else if (this.view === 'agent') {
        return {
          [this.headers[0]]: item.name,
          [this.headers[1]]: item.date,
          [this.headers[2]]: item.time,
          [this.headers[3]]: item.assessor,
          [this.headers[4]]: item.form,
          [this.headers[5]]: item.interactionId,
          [this.headers[6]]: item.channel,
          [this.headers[7]]: item.score,
          [this.headers[8]]: item.pass
        };
      }
    });
  
    Promise.all(promises)
      .then((result) => {
        const fileName = this.fileName;
        const csvConfig = mkConfig({
          useKeysAsHeaders: false,
          columnHeaders: this.headers,
          filename: fileName
        });
        const csv = generateCsv(csvConfig)(result);
        
        download(csvConfig)(csv);

        this.dialogController.ok();
      })
      .catch((error) => {
        logger.warn(' > Error occurred:', error);
      });
  }

  private async confirmExcelExport(): Promise<void> {
    const promises = this.reportData.map(async (item) => {
      let row;

      if (this.view === 'teams' || this.view === 'business partners' || this.view === 'agents') {
        row = [
          item.name,
          item.handledInteractions,
          item.interactionsAssessed,
          item.assessmentsPassed,
          item.assessmentsFailed
        ];
      } else if (this.view === 'agent') {
        row = [
          item.name,
          item.date,
          item.time,
          item.assessor,
          item.form,
          item.interactionId,
          item.channel,
          item.score,
          item.pass
        ];
      }
      return row;
    });
  
    Promise.all(promises)
      .then((result) => {
        const data: (string | number)[][] = [this.headers, ...result];
        console.log(data);
        
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

        XLSX.writeFile(wb, this.fileName + '.xlsx');

        this.eventAggregator.publish(MESSAGE_EVENTS.SUCCESS, 'Successfully downloaded QA report.');
        this.dialogController.ok();
      })
      .catch((error) => {
        logger.warn(' > Error occurred:', error);
      });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}

