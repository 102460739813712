/*
 */
import {inject, LogManager} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
/*
 */
import {MESSAGE_EVENTS} from 'zailab.common';
/*
 */
const logger = LogManager.getLogger('WebSocketKiosk');

@inject(EventAggregator)
export class WebSocketKiosk {

  io = {};
  wsState = {
    0: 'CONNECTING',
    1: 'OPEN',
    2: 'CLOSING',
    3: 'CLOSED'
  };
  subscribers = [];
  count = 0;
  delay = 0;
  reconnecting = false;
  timeout = {};
  isOpen = false;
  heartBeatInterval;
  heartBeatCounter = 3;
  heartBeatReceived = false;

  constructor(eventAggregator) {

    this.io.uri = ''; // mimic socket.io
    this.eventAggregator = eventAggregator;
  }

  showError(env) {

    if (this.reconnecting) {
      return;
    }

    this.timeout.notConnected = setInterval(() => {
      if (this.isOpen) {
        clearInterval(this.timeout.notConnected);
        return;
      }

      if (this.canSend) {
        if (this.count >= 4) {
          this.eventAggregator.publish('websocket.connection.reconnect', {id:'AGENT_ENDPOINT',env});
          return;
        } else {
          this.eventAggregator.publish('websocket.connection.timeout', {id:'AGENT_ENDPOINT',env});
        }

        this.canSend = false;
        this.reconnecting = true;
      }
    }, 2000);
  }

  logWebSocketState() {

    if (this.ws) {

      this.log(`${this.wsState[this.ws.readyState]} - this.ws = `, this.ws);
    } else {

      this.log('UNDEFINED - this.ws = ', this.ws);
    }

    setTimeout(() => {
      this.logWebSocketState();
    }, 10000);
  }

  log(note, obj) {

    logger.info(`[${new Date()}] ${note}`, obj);
  }

  connect(callback) {

    if(callback && typeof callback === 'function') {
      this.connectedCallback = callback;
    }

    this.ws = new WebSocket(this.io.uri);

    this.ws.onopen = env => {
      this.isOpen = true;
      this.reconnecting = false;
      this.count = 0;
      this.delay = 0;
      clearTimeout(this.timeout.reconnect);
      clearInterval(this.timeout.notConnected);
      clearInterval(this.heartBeatInterval);
      this.heartBeatCounter = 3;
      this.heartBeatReceived = false;
      this.eventAggregator.publish('websocket.connection.connected', {id:'AGENT_ENDPOINT'});
      this.setupHeartBeatInterval();
      this.connectedCallback();
    };

    this.ws.onclose = env => {
      this.webSocketClosed(env);
    };

    this.ws.onmessage = env => {

      logger.info('Incoming env = ' + JSON.stringify(env));
      let data = JSON.parse(env.data);

      this.count = 0;
      this.delay = 0;

      if (data && data.payload && data.payload === '--HEARTBEAT--') {
        this.heartBeatReceived = true;
        return;
      }

      let type = data.type ? data.type : (data.name ? data.name : data.event);
      if (!type) {
        return;
      }

      var subscriber = this.subscribers[type];

      if (subscriber && subscriber.callback) {
        subscriber.callback(data);
      }
    };

    this.ws.direct = this.direct;
  }

  webSocketClosed(env) {

    this.isOpen = false;
    this.showError(env);
    this.timeout.reconnect = setTimeout(() => {
      this.count++;
      this.delay = Math.pow(2, this.count) * 1000;
      if (this.count >= 10) {
        this.count = 0;
        this.delay = 0;
      }
      this.canSend = true;
      this.connect();
    }, this.delay);
  }

  close() {
    if (this.ws) {
      this.ws.close();
      this.callback = null;
    }
  }

  emit(topic, env) {
    this.ws.send(JSON.stringify({
      authorization: env.Authorization,
      root: env.feature,
      type: env.name,
      payload: env.state,
      requestId: env.trackingId,
    }));
  }

  emitInteraction(env) {
    this.ws.send(JSON.stringify(env));
  }

  on(topic, callback) {

    this.callback = callback;
  }

  direct(envelope) { // TODO maybe move this to the peer service

    var message = JSON.stringify({
      event: 'DIRECT',
      data: envelope
    });

    this.ws.send(message);
  }

  subscribe(type, callback) {
    this.subscribers[type] = {callback: callback};
  }

  pingEndpoint = (data) => {
    const env = {
      "target": "message.ALL",
      "type": "Message",
      "payload": {
          "src": null,
          "dst": "CHAT",
          "type": "PONG",
          "payload": data.payload.payload,
          "callType": null
      }
    };
    this.ws.send(JSON.stringify(env));
  };

  setupHeartBeatInterval(){
    this.heartBeatInterval = setInterval(() => {
      if(this.heartBeatReceived){
        this.heartBeatReceived = false;
        this.heartBeatCounter = 3;
      } else {
        this.heartBeatCounter--;
      }

      if(this.heartBeatCounter === 0){
        this.close();
      }
    }, 10000)
  }
}
