import {LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('DisplayServiceModel');

/*
 * */
export class DisplayServiceModel extends BaseModel {

  public id: string = null;
  public name: string = null;

  public serviceId: string = this.id;
  public serviceName: string = this.name;

  public selected: boolean = null;
  public taskTemplates: Array<any> = null;
  public icon: string = 'cog';
  public rolloverTextContent: any = null;
  public subscriptionCount: number = 0;
  public invitationCount: number = 0;
  public memberCount: number = 0;
  public dispositionListCount: number = 0;

  public showLoader: boolean;

  constructor(service?: DisplayServiceModel) {
    super();
    this.mapProperties(service);
  }

  @computedFrom('invitationCount', 'memberCount', 'subscriptionCount')
  public get canDelete(): boolean {
    return this.invitationCount === 0 && this.memberCount === 0 && this.subscriptionCount === 0 && !this.selected;
  }

  @computedFrom('memberCount', 'invitationCount')
  public get description(): string {
    return `Members: ${this.memberCount}<br>Invitations: ${this.invitationCount}`
  }

  @computedFrom('name')
  public get type(): string {
      return this.name ? this.name.toUpperCase().replace(' ', '_') : '';
  }

  private mapProperties(object: any): void {
    if (!object) {
      return;
    }
    if (object.serviceId) {
      object.id = object.serviceId;
      object.name = object.serviceName;
    } else if (object && object.id) {
      object.serviceId = object.id;
      object.serviceName = object.name;
    }
    super.mapProperties(object);
  }
}