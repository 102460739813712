import { LogManager, customElement, bindable } from 'aurelia-framework';
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

import { BootstrapFormRenderer } from 'zailab.common';

const logger = LogManager.getLogger('ZEmailInput');

@customElement('z-email-input')
export class ZEmailInput {
  @bindable({ attribute: 'input-value' })
  inputValue;
  @bindable placeholder: string;
  @bindable({ attribute: 'unique-identifier' })
  uniqueIdentifier: string;
  @bindable({ attribute: 'long-validation' })
  longValidation: boolean;
  @bindable({ attribute: 'has-no-validation' })
  hasNoValidation: boolean;
  @bindable({ attribute: 'email' })
  email: any;
  private validation: any;
  private eventSubscription: Subscription;

  constructor(private validationControllerFactory: ValidationControllerFactory, private eventAggregator: EventAggregator) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public attached(): void {
    this.eventSubscription = this.eventAggregator.subscribe('EMAIL.VALIDATE', () => this.validation.validate());
    this.initialiseValidation();
  }

  public detached(): void {
    this.eventSubscription.dispose();
  }

  private initialiseValidation(): void {
    let maxLength = 50;
    ValidationRules.ensure('email')
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/)
      .withMessage('Please enter a valid email.')
      .maxLength(maxLength)
      .withMessage('Email cannot exceed 50 characters.')
      .on(this.email);
  }

  private keyPressed(): void {
    if (this.validation.errors && this.validation.errors.length === 0) {
      this.email.isValid = true;
    } else {
      this.email.isValid = false;
    }
  }
}
