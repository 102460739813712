import { BootstrapFormRenderer } from 'zailab.common';
import { computedFrom } from 'aurelia-binding';
import {LogManager, autoinject} from 'aurelia-framework';
import {ZIWizard} from '../../../../../../../../../typings/zai/zai.common';
import {InteractionFlow} from "../../../../interaction-flow";
import { ValidationControllerFactory, ValidationRules, validateTrigger } from 'aurelia-validation';
import { Container } from 'aurelia-dependency-injection';
import { Validation } from 'zailab.validation';
/**/
const logger = LogManager.getLogger('NumberOfRings');
/**/
@autoinject
export class NumberOfRings {

  private validationControllerFactory: ValidationControllerFactory = Container.instance.get(ValidationControllerFactory);
  private validation: any;

  public name: string = '';
  public isValid: boolean;
  public channel: string;
  private notRespondingEnabled: boolean = true;
  private autoAnswer: boolean = false;
  private queueAgentRingCount: string = '1';
  private wizard: ZIWizard;
  private isCCFlow : boolean;

  constructor(private interactionFlow: InteractionFlow) {
    this.validation = this.validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;

    this.isCCFlow = this.interactionFlow.interactionFlowType === 'Contact Centre Flow';
  }

  private get queueAgentRingDuration(): number {
    let numberOfSecondsPerRing: number = 4;

    return parseInt(this.queueAgentRingCount) * numberOfSecondsPerRing;
  }

  private set queueAgentRingDuration(duration: number) {
    let numberOfSecondsPerRing: number = 4;
    let _queueAgentRingCount: number = duration / numberOfSecondsPerRing;

    this.queueAgentRingCount = _queueAgentRingCount.toString();
  }

  public activate(wizard: ZIWizard): void {
    this.initWizard(wizard);
    this.extractWizardData();
    this.initialiseValidation();
  }

  private initWizard(wizard: ZIWizard): void {
    this.wizard = wizard;
    this.channel = this.wizard.data.channel;
    this.name = this.wizard.data.name || '';
    this.validate();

    wizard.beforeNextStep(() => {
      this.validate();
      this.updateWizardData();
      this.nextStep();
    });
  }

  private extractWizardData(): void {
    this.queueAgentRingDuration = this.wizard.data.queueAgentRingDuration;
    this.notRespondingEnabled = ((typeof(this.wizard.data.notRespondingEnabled) === 'undefined') || (this.wizard.data.notRespondingEnabled === null)) ? true : this.wizard.data.notRespondingEnabled;
    this.autoAnswer = this.wizard.data.autoAnswer === true;
  }
    
  private initialiseValidation(): void {
    ValidationRules.ensure('name')
      .required().when(() => this.isEmailChannel || this.isSMSChannel || this.isTicketChannel)
      .withMessage('Please enter a name.')
      .maxLength(Validation.LENGTH.NAME).when(() => this.isEmailChannel || this.isSMSChannel || this.isTicketChannel)
      .withMessage('Name cannot exceed 30 characters.')
      .on(this);
  }

  public toggleNotRespondingEnabled(): void {
    this.notRespondingEnabled = !this.notRespondingEnabled;
  }

  public toggleAutoAnswer(): void {
    this.autoAnswer = !this.autoAnswer;
  }


  private updateWizardData(): void {
    this.wizard.data.queueAgentRingDuration = this.queueAgentRingDuration.toString();
    this.wizard.data.notRespondingEnabled = this.notRespondingEnabled;
    this.wizard.data.autoAnswer = this.autoAnswer;
    this.wizard.data.name = this.name;
  }

  private nextStep(): void {
    this.wizard.continue();
  }

  public validate(): void {
    if (!this.name || this.name.length === 0) {
      this.isValid = false;
      return;
    }
    this.isValid = true;
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isEmailChannel(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'Email';
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isSMSChannel(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'SMS';
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isChatChannel(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'Chat';
  }

  @computedFrom('interactionFlow', 'interactionFlow.interactionFlowChannel')
  public get isTicketChannel(): boolean {
    return this.interactionFlow && this.interactionFlow.interactionFlowChannel === 'Ticket';
  }
}
