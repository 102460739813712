import { inject, LogManager } from 'aurelia-framework';

import { AutoRechargeDetails, PaymentDetails, CardInfo } from './auto-recharge-model';
import { AutoRechargeService } from './auto-recharge-service';
import { NumberToCurrencyValueConverter } from '../../../../../converters/number-to-currency';
import { ZSelectInputOption } from '../../../../../_common/models/input-model';

const logger = LogManager.getLogger('AutoRecharge');
const settings = {
  INCREMENT: 10,
  MIN_THRESHOLD: 10,
  MIN_RECHARGE: 20,
  MAX_THRESHOLD: 1000,
  MAX_RECHARGE: 2000
};

@inject(AutoRechargeService, NumberToCurrencyValueConverter)
export class AutoRechargeCtrl {

  public payment: PaymentDetails = new PaymentDetails();
  public autoRecharge: AutoRechargeDetails = new AutoRechargeDetails();
  public isAutoRechargeConfigured: boolean = false;
  public isAutoRechargeEditSelected: boolean = false;
  public isAutoRechargeSaving: boolean = false;
  public isAutoRechargeEnabledToggling: boolean = false;
  public isAutoRechargeReady: boolean = false;

  private tempAutoRecharge: AutoRechargeDetails;
  private autoRechargeService: AutoRechargeService;
  private numberToCurrencyValueConverter: NumberToCurrencyValueConverter;

  constructor(autoRechargeService: AutoRechargeService, numberToCurrencyValueConverter: NumberToCurrencyValueConverter) {
    this.autoRechargeService = autoRechargeService;
    this.numberToCurrencyValueConverter = numberToCurrencyValueConverter;
  }

  public get isAutoRechargeConfigurable(): boolean {
    return this.payment.containsCards;
  }

  public get isAutoRechargeVisible(): boolean {
    return this.autoRechargeService.countryCode === 'US';
  }

  public get isAutoRechargeEnabled(): boolean {
    return this.autoRecharge.enabled;
  }

  public get selectedThreshold(): ZSelectInputOption<number> {
    return this.autoRecharge.selectedThreshold;
  }

  public set selectedThreshold(selectedThreshold: ZSelectInputOption<number>) {
    this.autoRecharge.selectedThreshold = selectedThreshold;
    this.autoRecharge.rechargeOptions = this.makeAmountOptions(selectedThreshold.value + settings.INCREMENT, settings.MAX_RECHARGE);
    if (this.autoRecharge.selectedRecharge.value <= this.autoRecharge.selectedThreshold.value) {
      this.selectedRecharge = this.makeAmountOption(selectedThreshold.value + settings.INCREMENT);
    }
  }

  public get selectedRecharge(): ZSelectInputOption<number> {
    return this.autoRecharge.selectedRecharge;
  }

  public set selectedRecharge(selectedRecharge: ZSelectInputOption<number>) {
    this.autoRecharge.selectedRecharge = selectedRecharge;
  }

  public attached(): void {
    this.isAutoRechargeConfigured = false;
    this.isAutoRechargeEditSelected = false;
    this.autoRechargeService.findCards()
      .then((data: PaymentDetails) => {
        this.handlePaymentResponse(data);
        this.autoRechargeService.findAutoRecharge()
          .then((data: AutoRechargeDetails) => this.handleAutoRechargeResponse(data));
      });
  }

  public detached(): void {
    this.autoRechargeService.close();
    this.isAutoRechargeReady = false;
  }

  public configureAutoRechargeDetails(): void {
    if (!this.isAutoRechargeConfigurable) {
      return;
    }
    this.isAutoRechargeConfigured = true;
    this.editAutoRechargeDetails();
  }

  public editAutoRechargeDetails(): void {
    this.tempAutoRecharge = this.autoRecharge.clone();
    this.isAutoRechargeEditSelected = true;
  }

  public saveAutoRechargeDetails(): void {
    if (this.isAutoRechargeSaving) {
      return;
    }
    this.autoRecharge.populatePayloadValues();
    if (!this.tempAutoRecharge.isEmpty() && this.tempAutoRecharge.equals(this.autoRecharge)) {
      return this.cancelAutoRechargeDetails();
    }
    this.isAutoRechargeSaving = true;
    this.applyAutoRechargeDetails();
  }

  public cancelAutoRechargeDetails(): void {
    if (this.isAutoRechargeSaving) {
      return;
    }
    this.commitAutoRechargeDetails();
    if (this.tempAutoRecharge.isEmpty()) {
      this.isAutoRechargeConfigured = false;
      this.autoRecharge.clear();
      this.initAutoRecharge();
    } else {
      this.autoRecharge.update(this.tempAutoRecharge);
    }
  }

  public toggleAutoRechargeEnabled(): void {
    if (this.isAutoRechargeEnabledToggling) {
      return;
    }
    this.isAutoRechargeEnabledToggling = true;
    this.commitAutoRechargeEnabledToggle();
    if (this.autoRecharge.enabled) {
      this.autoRechargeService.enableAutoRechargeDetails(this.autoRecharge)
        .then(() => this.isAutoRechargeEnabledToggling = false)
        .catch(() => this.revertAutoRechargeEnabledToggle());
    } else {
      this.autoRechargeService.disableAutoRechargeDetails(this.autoRecharge)
        .then(() => this.isAutoRechargeEnabledToggling = false)
        .catch(() => this.revertAutoRechargeEnabledToggle());
    }
  }

  public addCard(): void {
    this.autoRechargeService.navigateToBuyCreditTab();
  }

  private applyAutoRechargeDetails(): void {
    if (this.tempAutoRecharge.isEmpty()) {
      this.autoRechargeService.createAutoRechargeDetails(this.autoRecharge)
        .then(() => this.commitAutoRechargeDetails(true))
        .catch(() => this.revertAutoRechargeDetails());
    } else {
      this.autoRechargeService.updateAutoRechargeDetails(this.autoRecharge)
        .then(() => this.commitAutoRechargeDetails())
        .catch(() => this.revertAutoRechargeDetails());
    }
  }

  private commitAutoRechargeDetails(create?: boolean): void {
    this.isAutoRechargeEditSelected = false;
    this.isAutoRechargeSaving = false;
    if (create) {
      this.autoRecharge.enabled = true;
    }
  }

  private revertAutoRechargeDetails(): void {
    this.isAutoRechargeSaving = false;
  }

  private commitAutoRechargeEnabledToggle(): void {
    this.autoRecharge.enabled = !this.autoRecharge.enabled;
  }

  private revertAutoRechargeEnabledToggle(): void {
    this.autoRecharge.enabled = !this.autoRecharge.enabled;
    this.isAutoRechargeEnabledToggling = false;
  }

  private handleAutoRechargeResponse(data: AutoRechargeDetails): void {
    this.autoRecharge = data.clone();
    if (!this.autoRecharge.isEmpty()) {
      this.isAutoRechargeConfigured = true;
    }
    this.initAutoRecharge();
    this.isAutoRechargeReady = true;
  }

  private handlePaymentResponse(data: PaymentDetails): void {
    this.payment = data.clone();
  }

  private initAutoRecharge(): void {
    this.autoRecharge.thresholdOptions = this.makeAmountOptions(settings.MIN_THRESHOLD, settings.MAX_THRESHOLD);
    this.autoRecharge.selectedThreshold = this.makeAmountOption(this.getSelectedThreshold());
    this.autoRecharge.rechargeOptions = this.makeAmountOptions(this.getMinRecharge(), settings.MAX_RECHARGE);
    if (!this.autoRecharge.selectedRecharge) {
      this.autoRecharge.selectedRecharge = this.makeAmountOption(this.getMinRecharge());
    }
    this.autoRecharge.cardOptions = this.makeCardOptions(this.payment.cards);
    if (!this.autoRecharge.selectedCard) {
      let selectedCard = this.payment.cards[0];
      if (this.autoRecharge.cardId) {
        selectedCard = this.payment.cards.filter((card) => card.id === this.autoRecharge.cardId)[0];
      }
      if (selectedCard) {
        this.autoRecharge.selectedCard = this.makeCardOption(selectedCard);
      }
    }
  }

  private getSelectedThreshold(): number {
    return this.autoRecharge.threshold ? Number.parseFloat(this.autoRecharge.threshold) : settings.MIN_THRESHOLD;
  }

  private getMinRecharge(): number {
    return this.autoRecharge.recharge ? Number.parseFloat(this.autoRecharge.recharge) : settings.MIN_RECHARGE;
  }

  private makeAmountOption(amount: number): ZSelectInputOption<number> {
    return new ZSelectInputOption(amount, this.numberToCurrencyValueConverter.toView(amount, this.autoRechargeService.currencySymbol))
  }

  private makeCardOption(card: CardInfo): ZSelectInputOption<string> {
    return new ZSelectInputOption(card.id, card.label);
  }

  private makeAmountOptions(fromAmount: number, toAmount: number): ZSelectInputOption<number>[] {
    const options = [];
    for (let amount = fromAmount; amount <= toAmount; amount += settings.INCREMENT) {
      options.push(this.makeAmountOption(amount));
    }
    return options;
  }

  private makeCardOptions(cards: CardInfo[]): ZSelectInputOption<string>[] {
    return cards.map((card) => this.makeCardOption(card));
  }

  private handleError(error: any): void {
    logger.error('handleError :: error=', error);
  }
}
