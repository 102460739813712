import {inject, LogManager, computedFrom} from 'aurelia-framework';
/*
 * */
import {BaseModel} from 'zailab.abstract';
/*
 * */
const logger = LogManager.getLogger('MailboxModel');
/*
 * */
export class MailboxModel extends BaseModel {

  duration = null;
  from = null;
  mailboxId = null;
  mailboxMessageId = null;
  mailboxName = null;
  played = null;
  date = null;
  time = null;

  constructor(mailboxItem, dateTimeTools) {
    super();
    this.dateTimeTools = dateTimeTools;
    this.mapProperties(mailboxItem);
  }

  /**
   * @returns {{date, time, dateObj}|*}
   * @private
   */
  get _localTime() {
    return this.dateTimeTools.convertToLocalTime(this.date, 'UTC');
  }

  get formattedDate() {
    return this._localTime.date;
  }

  get formattedTime() {
    return this._localTime.time;
  }

  //           let formattedDateTime = this.dateTimeTools.convertToLocalTime(response[i].date, 'UTC');
  //
  //           response[i].date = formattedDateTime.date;
  //           response[i].time = formattedDateTime.time;
}