import { BootstrapFormRenderer } from 'zailab.common';
import { ValidationControllerFactory, validateTrigger, ValidationRules, ValidationController } from 'aurelia-validation';
import { autoinject, LogManager } from 'aurelia-framework';
import { IntegrationsService } from './../integrations-service';

// @ts-ignore
import { DialogController } from 'aurelia-dialog';

const logger = LogManager.getLogger('Microsoft365PlatformAPIDialog');

@autoinject
export class Microsoft365PlatformAPIDialog {
  
  private validation: ValidationController;
  private hasContent: boolean = false;

  public dialogHeader: string = 'Microsoft 365 Exchange';
  public configId: string = '';
  public clientId: string = '';
  public tenantId: string = '';
  public clientSecret: string = '';
  public userId: string = '';

  constructor(
    private dialogController: DialogController,
    private integrationsService: IntegrationsService,
    public validationControllerFactory: ValidationControllerFactory
  ) {
    this.dialogController = dialogController;
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }
  
  public activate(config?: { id: string, clientId: string, tenantId: string, clientSecret: string, userId: string }): void {
    if (config) {
      this.hasContent = true;
      this.configId = config.id;
      this.clientId = config.clientId;
      this.tenantId = config.tenantId;
      this.userId = config.userId;
      this.clientSecret = config.clientSecret;
    }
    this.initialiseValidations();
  }

  private initialiseValidations(): void {
    ValidationRules
      .ensure('clientId').required().withMessage('Please enter a client ID.')
      .ensure('tenantId').required().withMessage('Please enter a tenand ID.')
      .ensure('userId').required().withMessage('Please enter a user ID.')
      .ensure('clientSecret').required().withMessage('Please enter a client secret.')
      .on(this);
  }

  public update(): void {
    this.validation
      .validate()
      .then(validation => {
        if (!validation.valid) {
          return;
        }
        if (this.hasContent) {
          this.integrationsService
            .updateMicrosoft365Config(this.configId, this.clientId, this.tenantId, this.clientSecret, this.userId)
            .then(response => {
              this.dialogController.ok({
                clientId: this.clientId,
                tenantId: this.tenantId,
                clientSecret: this.clientSecret
              })
            });
        } else {
          this.integrationsService
            .createMicrosoft365Config(this.clientId, this.tenantId, this.clientSecret, this.userId)
            .then(response => {
              this.dialogController.ok({
                clientId: this.clientId,
                tenantId: this.tenantId,
                clientSecret: this.clientSecret
              })
            });
        }
      });
  }

  public cancel(): void {
    this.dialogController.cancel();
  }
}