import { TelephoneNumberModel } from "components/atoms/inputs/phonenumber/telephone-number.model";
import { v4 as uuidv4 } from 'uuid';

export enum DoNotContactType {
  EMAIL = 'EMAIL', NUMBER = 'NUMBER'
}

export class DoNotContactForm {

  public doNotContactName = '';
  public doNotContactType = 'NUMBER';
  public doNotContactNumber = new TelephoneNumberModel('', '', '', true);
  public doNotContactEmail = '';

  public reset(): void {
    this.doNotContactName = '';
    this.doNotContactType = 'NUMBER';
    this.doNotContactNumber = new TelephoneNumberModel('', '', '', true);
    this.doNotContactEmail = '';
  }

  public isTypeNumber(): boolean {
    return this.doNotContactType === 'NUMBER';
  }

  public isValidNumber(): boolean {
    return this.doNotContactType === 'NUMBER' ? this.doNotContactNumber.isNumberValid : true;
  }

  public getDoNotContactValue(): string {
    return this.isTypeNumber() ? this.doNotContactNumber.number : this.doNotContactEmail;
  }

  public toDoNotContactListItem(): DoNotContactListItem {
    return {
      doNotContactId: uuidv4(),
      doNotContactName: this.doNotContactName,
      doNotContactType: this.doNotContactType,
      doNotContactValue: this.getDoNotContactValue()
    };
  }
}

export interface DoNotContactListItem {

  doNotContactId: string;
  doNotContactName: string;
  doNotContactType: string;
  doNotContactValue: string;
}

export interface DoNotContactListImport {

  fileName: string;
  totalCount: number;
  processedCount: number;
  successCount: number;
  done: boolean;
}

let br = '\r\n';

export class DoNotContactListImportRequest {

  boundary;
  data = [];

  constructor() {
    this._generateBoundary();
  }

  _generateBoundary() {

    this.boundary = '';
    this.boundary += Math.floor(Math.random() * 32768);
    this.boundary += Math.floor(Math.random() * 32768);
    this.boundary += Math.floor(Math.random() * 32768);
  }

  _createDisposition(item) {

    let _disposition = '--' + this.boundary + br;
    _disposition += 'Content-Disposition: form-data; name="' + item.name + '";';
    if (item.fileName) {
      _disposition += 'filename=' + item.fileName;
    }
    _disposition += br;
    _disposition += 'Content-Type: ' + item.contentType + br;
    _disposition += br;
    _disposition += item.content;
    _disposition += br;

    return _disposition;
  }

  addDisposition(item) {
    this.data.push(item);
  }

  getContent() {
    let body = br;

    for (let item of this.data) {
      body += this._createDisposition(item);
    }

    body += '--' + this.boundary + '--';
    return body;
  }
}
