import { inject, LogManager } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
/*
 * */
import { SessionStore } from 'zailab.common';
import { CosModel } from './cos-model';
/*
 * */
const logger = LogManager.getLogger('CosService');
/*
 * */
@inject(SessionStore, HttpClient)
export class CosService {

  constructor(sessionStore, httpClient) {
    this.sessionStore = sessionStore;
    this.httpClient = httpClient;
  }

  /**
   * @returns classesOfServices {Array[CosModel]}
   * @returns {Promise}
   */
  displayClassesOfService() {
    return new Promise((resolve, reject) => {
      this.httpClient.createRequest('v1/organisation/me/classes-of-service')
        .asGet()
        .send()
        .then(
          (response) => {
            resolve(response.map(_service => {
              return new CosModel({ cosValue: _service })
            }));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}