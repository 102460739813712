import { inject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { AudioAddService } from 'zailab.common';
import { getLanguage } from '../../../../../_common/tools/language-tools';
/*
* */
const logger = LogManager.getLogger('AudioListDialog');
/*
 * */
@inject(DialogController, AudioAddService)
export class AudioListDialog {

  public item: any;
  public isLoading: boolean = false;
  public audioRetrieved: boolean = false;
  public getLanguage = getLanguage;
  public audioMediaList = [];
  public showVariableName: boolean = false;

  constructor(private dialogController: DialogController, private audioService: AudioAddService) {
  }

  public activate(_item: any): void {
    this.item = _item;
    this.previewAudio();
  }

  private cancel(): void {
    this.dialogController.cancel();
  }

  private previewAudio(): void {
    this.audioMediaList = [];
    if (this.item.otherAudio && this.item.otherAudio.length) {
      for (const otherAudio of this.item.otherAudio) {
        if (!this.item.variableName) {
          this.item.variableName = otherAudio.variableName;
        }
        this.audioMediaList.push({
          id: otherAudio.id,
          language: otherAudio.language,
          defaultSelection: otherAudio.defaultSelection
        });
      }
      for (const audioMediaItem of this.audioMediaList) {
        this.audioService.retrieveAudioBinary(audioMediaItem.id, this.item.audioType).then((_audioSource) => {
          audioMediaItem.audioSource = _audioSource;
        });
      }
    } else {
      this.isLoading = true;
      this.audioService.retrieveAudioBinary(this.item.id, this.item.audioType).then((_audioSource) => {
        this.item.audioSource = _audioSource;
        this.isLoading = false;
        this.audioRetrieved = true;
      }, () => {
        this.isLoading = false;
        this.audioRetrieved = false;
      });
    }
  }
}

