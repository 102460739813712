/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZIconButton');
/*
 */
@customElement('z-icon-button')
export class ZIconButton {

  @bindable size;
  @bindable icon;
  @bindable color;
  @bindable title;
  @bindable box;
  @bindable bordercolor;
  @bindable hashover;
  @bindable flipx;
}
