import { inject, LogManager, computedFrom } from 'aurelia-framework';
import moment from 'moment';

import { BaseModel } from 'zailab.abstract';

const logger = LogManager.getLogger('MemberPropertiesModel');

export default class extends BaseModel {

  id = null;
  firstName = null;
  surname = null;
  fullName = null;
  email = null;
  telephoneNumber = null;
  extension = null;
  externalReference = null;
  devices = null;
  classOfService = null;
  services = null;
  sites = null;
  teams = null;
  capacityRules = null;
  personId = null;
  skillGroups = null;
  businessPartners = null;
  searchableText = null;
  permissions = null;
  routingGroup = null;
  _roles = null;
  _channels = null;
  _classesOfService = null;
  autoLogoutEnabled = null;
  autoLogoutTimeout = null;

  constructor(member) {

    super();
    this.mapProperties(member);
    this.memberId = member.id;
    this.channels = member.channels;
    this.roles = member.roles;
    this.site = member.site;
    this.classesOfService = member.classOfService;
    if (this.businessPartners && this.businessPartners.length) {
      this.businessPartners.forEach((businessPartner) => {
        businessPartner.businessPartnerName = businessPartner.name;
      });
    }
    if (member.autoLogoutTimeout && member.autoLogoutTimeout > 0) {
      this.autoLogoutTimeout = (member.autoLogoutTimeout / 60 / 1000) + ' Min';
    }
    this.searchableText = `${member.firstName} ${member.surname}`;
    this.fullName = `${member.firstName} ${member.surname}`;
  }

  get deviceName() {
    if (this.devices.length > 0) {
      return this.devices[0].name;
    }
    return null;
  }

  get devicePin() {
    if (this.devices.length > 0) {
      return this.devices[0].pin;
    }
    return null;
  }

  @computedFrom('_classesOfService')
  get classesOfService() {
    return this._classesOfService;
  }

  @computedFrom('businessPartners')
  get contactCentres() {
    let contactCentres = [];
    if (this.businessPartners && this.businessPartners.length) {
      this.businessPartners.forEach((businessPartner) => {
        if (businessPartner.contactCentres && businessPartner.contactCentres.length) {
          businessPartner.contactCentres.forEach(cc => {
            contactCentres.push(cc);
          });
        }
      });
    }
    return contactCentres;
  }

  set classesOfService(classOfService) {
    this._classesOfService = [{ name: classOfService }];
  }

  set site(site) {
    site.siteId = site.id;
    this.sites = [site];
  }

  @computedFrom('_roles')
  get role() {

    if (this._roles && this._roles.length) {
      return this._roles[0].roleValue;
    }
    return '';
  }

  @computedFrom('_roles')
  get roleName() {
    if (this._roles && this._roles.length) {
      switch (this._roles[0].roleName) {
        case 'ADMINISTRATOR':
          return 'Administrator';
        case 'AGENT':
          return 'Agent';
        case 'OFFICE_EMPLOYEE':
          return 'Office Employee';
        case 'QA':
          return 'Quality Assessor';
        case 'QA_MANAGER':
          return 'QA Manager';
        case 'CAMPAIGN_MANAGER':
          return 'Campaign Manager';
        case 'TEAM_LEADER':
          return 'Team Leader';
      }
    }
    return '';
  }

  @computedFrom('_roles')
  get roles() {
    return this._roles;
  }

  set roles(roles) {

    this._roles = [];
    let _list = [];

    if (roles && Array.isArray(roles)) {
      for (let role of roles) {

        let _roleObj = {};

        if (role.roleValue) {
          _roleObj = role;
        } else {
          _roleObj = {
            roleValue: role
          };
        }
        _list.push(_roleObj);
      }
    }

    this._roles = _list;
    this.disableChannelsBasedOnRoles();
  }

  disableChannelsBasedOnRoles() {

    if (this._roles && this._roles[0] && this._roles[0].roleValue === 'AGENT') {
      return;
    }

    let _channels = [];

    for (let item of this._channels) {
      if (item.channelName === 'EMAIL' || item.channelName === 'SMS') {
        continue;
      }
      _channels.push(item);
    }
    this.channels = _channels;
  }

  get channels() {
    return this._channels;
  }

  set channels(channels) {

    let _list = [];

    if (channels && Array.isArray(channels)) {
      for (let channel of channels) {

        let _channelObj = {};

        if (channel.channelName) {
          _channelObj = channel;
        } else {
          _channelObj = {
            channelName: channel
          };
        }
        _list.push(_channelObj);
      }
    }
    this._channels = _list;
  }
}
