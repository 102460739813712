/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZStatLegend');
/*
 */
@customElement('z-stat-legend')
export class ZStatLengend {
    
    @bindable header: string;
    @bindable label: string;
    @bindable({attribute: 'legend-icon'}) legendIcon: string;
    @bindable({attribute: 'key-color'}) keyColor: string;
    @bindable({attribute: 'is-selected'}) isSelected: boolean = false;
}