
import { LogManager } from 'aurelia-framework';

import { ColourConfig } from "../../../../interaction/dashboard/live-dashboard/overview/colour-config";
import { TimeAgoValueConverter } from '../../../../../converters/time-ago';

import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import moment from 'moment';

const logger = LogManager.getLogger('ConfigMapper');

export class ConfigMapper {
  private timeAgoValueConverter: TimeAgoValueConverter = new TimeAgoValueConverter();
  private timers: any = {};
  
  public createTimer(c: any, key: string): string {
    const id = uuidv4();
    const getTimerValue = value => {
      if (value) {
        if (value === '--') {
          return value;
        }
        value = this.timeAgoValueConverter.toView(value, new Date, ':');
        return value === '00:00:00' ? '--' : value;
      }
      return '--'
    }
    const calculateValue = () => getTimerValue(c[key]);
    let value = calculateValue();

    const incrementFunction = () => {
      value = calculateValue();

      if (value && value !== '--') {
        this.timers[id] && window.clearInterval(this.timers[id])
        this.timers[id] = null;
        this.timers[id] = setInterval(() => {
          let newValue = calculateValue();
          let element = document.querySelector(`#js-${id}`);
          if (element) {
            element.innerHTML = newValue;
            element.className = ' square-component';
          }
        }, 300);
      }
    };

    return `<div id="js-${id}" class="square-component" onload="${incrementFunction()}">${value}</div>`
  }

  public formatToDayTime(value: string): string {
    if (value && value !== '--') {
      return moment(Date.parse(new Date(value).toUTCString())).utc().format('HH:mm:ss')
    }
    return '--';
  }
  
  // COLOR RULES

  public getColourConfig(title: string, value: any, zeroAllowed?: boolean): string {
    if ((!value || value === '--') && value !== 0) {
      return;
    }
    const colourScheme = ColourConfig.getColourScheme(ColourConfig.VIEWS.CAMPAIGN_LIVE_DASHBOARD);

    if (colourScheme[title]) {
      const config = colourScheme[title].settings;
      for (let index = 0; index < config.length; index++) {
        let setting = config[index];
        if (value >= setting.value) {
          let styleString = 'background: ' + setting.color + '!important;';
          if (setting.color === 'var(--successful)') {
            styleString += 'color: black!important;';
          }
          return styleString;
        }
      }
    } else {
      return '';
    }
  }

  public getColourConfigProspectList(title: string, value: any, zeroAllowed?: boolean): string {
    if ((!value || value === '--') && value !== 0) {
      return;
    }
    const colourScheme = ColourConfig.getColourScheme(ColourConfig.VIEWS.PROSPECT_LIST_LIVE_DASHBOARD);

    if (colourScheme[title]) {
      const config = colourScheme[title].settings;
      for (let index = 0; index < config.length; index++) {
        let setting = config[index];
        if (value >= setting.value) {
          let styleString = 'background: ' + setting.color + '!important;';
          if (setting.color === 'var(--successful)') {
            styleString += 'color: black!important;';
          }
          return styleString;
        }
      }
    } else {
      return '';
    }
  }
}
