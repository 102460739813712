import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';

import { DashboardWidget } from '../../organisation/dashboard/dashboard-abstract';

const logger = LogManager.getLogger('ReportsWidget');

@inject(Router, EventAggregator)
export class ReportsWidget extends DashboardWidget {

  public actions = [{
    name: 'View',
    event: 'view',
    icon: 'view'
  }];

  constructor(public router: Router) {
    super();
  }
}
