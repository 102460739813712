/*
 */
import {LogManager, customElement, bindable} from 'aurelia-framework';
/*
 */
const logger = LogManager.getLogger('ZPageColumn');
/*
 */
@customElement('z-page-column')
export class ZPageColumn {

  @bindable position;
  @bindable stats;
  @bindable navigation;
  @bindable ({attribute: 'full-width'}) fullWidth = false;

}
