import {inject, LogManager, customElement, bindable, observable} from 'aurelia-framework';

const logger = LogManager.getLogger('ZTabs');

@customElement('z-tabs')
export class ZTabs {

  @bindable tabs;
  @bindable @observable selectedtab;
  @bindable hasinfo;
  @bindable icon;
  @bindable size;
  selectedtabView;
  @bindable changetab;

  attached() {
    this.selectedtab = this.getSelectedTab() || this.tabs[0];
    this.changetab = index => this.selectedtab = this.tabs[index];
  }

  click(clickedTab) {
    this.selectedtab = clickedTab;
  }

  selectedtabChanged() {
      this._select();
  }

  getSelectedTab() {
    return this.tabs.filter((_tab) => {
      return _tab.selected;
    })[0]
  }

  _select() {
    this.tabs.forEach(tab => {
      if (tab.id === this.selectedtab.id) {
        tab.selected = true;
        this.selectedtabView = tab.viewModel;
        this.selectedTabId = tab.id;
      } else {
        tab.selected = false;
      }
    });
  }

}
