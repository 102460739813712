import { inject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SessionStore, SortTools } from 'zailab.common';
import { AbstractList, List } from 'zailab.abstract';
import { NumbersService } from '../numbers-service';
import { ReturnNumberDialog } from './return-number-dialog';
import { PurchaseNumbers } from '../purchasenumbers/purchase-numbers';
import { TelephoneNumberModel } from "../../../../../components/atoms/inputs/phonenumber/telephone-number.model";


const logger = LogManager.getLogger('DisplayPurchasedNumbers');

@inject(Element, Router, NumbersService, SessionStore, DialogService, EventAggregator)
export class DisplayPurchasedNumbers extends AbstractList {
  pages;
  numberObject = {};

  private returnNumberAction = [
    {
      action: 'numberreturn',
      label: 'Return Number',
      icon: 'numbers'
    }
  ];

  constructor(private element: Element, private router: Router, private numbersService: NumbersService, private sessionStore: SessionStore, private dialogService: DialogService, protected eventAggregator: EventAggregator) {
    super(eventAggregator);
  }

  protected activate(params?: { add: string }): void {
    super.activate(params);
    this.subscribeToOplog();
  }

  protected attached(): void {
    super.attached();
  }

  protected detached(): void {
    super.detached();
  }

  protected deactivate(): void {
    super.deactivate();
  }

  private subscribeToOplog(): void {
    this.numbersService.subscribeToOrganisationNumbersUpdates(this.sessionStore.get.organisation.organisationId).then(oplog => {
      this.oplog = oplog;
      this.oplog.on('insert', () => this.retrieveList());
      this.oplog.on('update', () => this.retrieveList());
      this.oplog.on('delete', () => this.retrieveList());
    });
  }

  protected retrieveList(): void {
    super.retrieveList();
    this.numbersService.retrieveOrganisationNumbersList().then(numbers => {
      this.sortNumberList(numbers);
      this.setupListConfig(numbers);
    });
  }

  protected async numberActionsStrategy(_numbers: any[]): Promise<any[]> {

    for(let number of _numbers) {
      if (number.status !== 'ASSIGNED' && !number.assigned) {
        number.actions = this.returnNumberAction;
      }
    }
    return _numbers;
  }

  protected async setupListConfig(_numbers: any[]): Promise<void> {
    super.setupListConfig(_numbers);
    _numbers = await this.numberActionsStrategy(_numbers);
    this.itemList = List.Builder()
    .required(true)
    .customEventElement(this.element)
    .items(_numbers)
    .icon('numberIcon')
    .enableAdd()
    .uniqueIdentifier('numberId')
    .displayId('number')
    .description('numberType')
    .descriptionTwo('descriptionTwo')
    .withProportion('medium')
    .tilesPerRow(7)
    .build();

    if (!this.isAdding) {
      this.hideLoader(this.deferredMessage);
    }
  }

  private sortNumberList(numbers: any[]): any[] {
    numbers.sort(SortTools.compareBy('numberType'));
    numbers.reverse();
    return numbers;
  }

  private navigateToPurchaseNumbers():void {
    this.dialogService.open({viewModel: PurchaseNumbers}).whenClosed(response => {
      if (!response.wasCancelled) {
      }
      this.subscribeToOplog();
    });
  }

  private actionSelected(payload: {action: string, item: TelephoneNumberModel[]}): void {
    let actionStrategy = {
      numberreturn: () => this.returnNumber(payload.item)
    };
    actionStrategy[payload.action] && actionStrategy[payload.action]();
  }

  private returnNumber(numberObject: any): void {
    this.numberObject = numberObject;
    this.dialogService.open({viewModel: ReturnNumberDialog, model: this.numberObject}).whenClosed(response => {
      if (!response.wasCancelled) {
        this.itemList.items = this.itemList.items.filter(item => {
          return item.number !== numberObject.number;
        });
      }
    });
  }
}
