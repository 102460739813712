import {autoinject, bindable, LogManager, customElement} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {CONTACT_ACTIONS, CONVERSATION_ACTIONS} from '../contactcontroller/contact-controller-actions';
import {ContactModel} from '../contact-model';

const logger = LogManager.getLogger('EmptyState');

@autoinject()
@customElement('z-empty-state')
export class EmptyConversation {

  @bindable private contact: ContactModel;
  @bindable private view: string;

  constructor(private eventAggregator: EventAggregator) {
    this.eventAggregator = eventAggregator;
  }

  private createNewConversation(): void {
    this.eventAggregator.publish(CONVERSATION_ACTIONS.CREATE, this.contact.contactId);
  }

  private showNewConversationView(): void {
    this.eventAggregator.publish(CONTACT_ACTIONS.CHANGE_VIEW, {
      viewStrategy: 'interactions',
      correlationId: this.contact.correlationId
    });
  }
}
